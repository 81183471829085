import { Button, Checkbox, Form, Grid, Icon, Input, Label as SemanticLabel, Table } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import TestEditModal, { STATUS_OPTIONS } from './TestEditModal';

import EnrichedText from '../SweetWork/components/EnrichedText';
import { EnrichedText as EnrichedText_ } from '../SweetWork/profileData';
import FunctionParametersSelector from './ParameterSelector';
import FunctionResult from './FunctionResult';
import { Label } from 'konva';
import MissionProfileCollectionModal from './MissionProfileCollectionModal.js';
import PropertyModal from './PropertyModal';
import TextFunctionInput from './TextFunctionInput';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { loadLinkedinFromProfileCollection } from '../SweetComponents/LinkedinProfile/Editor/loadLinkedinProfile';
import moment from 'moment';
import queryString from 'query-string';

const getItemColor = (success) => {
  if (success) {
    return '#ddffdd';
  }
  return '#ffdddd';
};

class TechAssetSandBox extends Component {
  constructor(props) {
    super(props);
    this.state = { statusFilter: 'all', isSelected: {}, autoRun: true };
    this.handleFunctionChange = this.handleFunctionChange.bind(this);
    this.handleInputDataChange = this.handleInputDataChange.bind(this);
    this.getFunctionResult = this.getFunctionResult.bind(this);
    this.getTagIds = this.getTagIds.bind(this);
    this.openInput = this.openInput.bind(this);
  }

  componentDidMount = async () => {
    const { initialFunctionId, initialProfileCollectionId, initialJobCollectionId, initialMissionId } = this.props;
    await this.loadData();
    await this.changeFunction(initialFunctionId);
    const missionPath = await this.getMissionPath(initialJobCollectionId, initialMissionId);
    const tagIds = await this.getTagIds();
    this.setState({
      profileCollectionId: initialProfileCollectionId,
      jobCollectionId: initialJobCollectionId,
      missionId: initialMissionId,
      missionPath,
      tagIds,
    });
    await this.loadInputFromMission(initialProfileCollectionId, initialMissionId, missionPath);
  };

  loadData = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/functions';
    const data = (await axios.get(url, customHeaders)).data;
    this.setState({ sweetfunctions: data.data.sweetfunctions });
  };

  getMissionPath = async (jobCollectionId, missionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobPath/' + jobCollectionId + '/' + missionId;
    const data = (await axios.get(url, customHeaders)).data;
    const missionPath = (data || {}).results;
    return missionPath;
  };

  async getTagIds() {
    const url = baseUrl + '/cerejobsV2/getTagIds';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  changeFunction = async (value) => {
    const func = ((this.state || {}).sweetfunctions || []).find((e) => e.id == value);
    const argnames = _.map((func || {}).args || [], (arg) => arg.argname);
    const inputData = _.map(argnames, (argname) => {
      const alreadyComputedValue = ((this.state || {}).inputData || {}).argname;
      return alreadyComputedValue ? { argname, alreadyComputedValue } : { argname };
    });
    const tests = await this.getFunctionsTests((func || {}).id);
    const types = _.map((func || {}).args, (arg) => arg.type);
    const autoRun = (types || []).includes('profiles') ? false : true;

    await this.setState({
      function: func,
      inputData: inputData,
      sweetfunctionResult: null,
      tests: tests.tests,
      autoRun,
    });
  };

  async handleFunctionChange(e, { value }) {
    await this.changeFunction(value);
  }

  async handleFunctionParamsChange({ value, fieldName }) {
    const newParam = {
      fieldName: fieldName,
      value: value,
    };
    const newParams = [..._.filter(this.state.functionParams, (elt) => elt.fieldName != fieldName), newParam];
    await this.setState({ functionParams: newParams });
  }

  async handleInputDataChange(argname, value) {
    const newInputData = _.map(this.state.inputData, (elt) => (elt.argname === argname ? { argname, value } : elt));
    const { autoRun } = this.state || {};
    this.setState({
      inputData: newInputData,
    });
    if (autoRun) {
      await this.getFunctionResult(newInputData);
    }
  }

  loadInputFromMission = async (profileCollectionId, missionId, missionPath) => {
    const args = (this.state.function || {}).args || [];

    const profilesArgs = _.filter(args, (arg) => arg.type === 'profiles');
    await Promise.all(
      _.map(profilesArgs, async (arg) => {
        await this.loadProfilesFromMission(arg.argname, profileCollectionId, missionId);
      }),
    );

    const offerArgs = _.filter(args, (arg) => arg.type === 'offer');
    await Promise.all(
      _.map(offerArgs, async (arg) => {
        await this.loadOfferFromMission(arg.argname, missionId, missionPath);
      }),
    );
  };

  loadProfilesFromMission = async (argname, profileCollectionId, missionId) => {
    const url = baseUrl + '/sweetchain/sampleProfilesFromMission';

    const payload = {
      missionId,
      profileCollectionId,
      mode: argname,
    };
    const profileIds = (await axios.post(url, payload)).data.results;

    const rawEntities = await Promise.all(
      _.map(profileIds, async (profileId) => {
        const profile = await loadLinkedinFromProfileCollection({ profileCollectionId, profileId: profileId });
        return {
          sourceProfiles: {
            linkedin: profile,
          },
        };
      }),
    );
    this.handleInputDataChange(argname, rawEntities);
  };

  loadOfferFromMission = async (argname, missionId, missionPath) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const fetchUrl = baseUrl + '/sweetchain/getJobV5';
    const result = (await axios.post(fetchUrl, { jobPath: missionPath }, customHeaders)).data;
    const offer = {
      id: missionId,
      title: missionId,
      ...result.search,
    };
    this.handleInputDataChange(argname, offer);
  };

  getParamsFromFunctionId(functionId) {
    return (this.state.sweetfunctions.find((e) => e.id == functionId) || {}).args;
  }
  getFunctionFromId(functionId) {
    return this.state.sweetfunctions.find((e) => e.id == functionId) || {};
  }

  async getFunctionResult(inputData) {
    const func = (this.state || {}).function;
    const argIsOptional = (func || {}).argIsOptional;

    let allInputAreLoaded = true;
    for (let i = 0; i < inputData.length; i++) {
      const item = inputData[i];
      const isOptional = (argIsOptional || {})[item.argname] || false;
      allInputAreLoaded = allInputAreLoaded && (!_.isUndefined(item.value) || isOptional);
    }

    if (!allInputAreLoaded) {
      alert('Need more input to call the function');
      return;
    }
    await this.setState({ loadingSweetfunctionResult: true });
    const payload = {
      sweetfunctionId: (this.state.function || {}).id,
      data: inputData,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/getResults';
    const data = (await axios.post(url, payload, customHeaders)).data;
    await this.setState({ sweetfunctionResult: (data || {}).sweetfunctionResult, loadingSweetfunctionResult: false });
  }

  async getFunctionsTests(sweetfunctionId) {
    const payload = { sweetfunctionId };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/tests';
    const data = (await axios.post(url, payload, customHeaders)).data;
    return data;
  }

  async getTestData(testId) {
    const payload = { testId };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/testData';
    const data = (await axios.post(url, payload, customHeaders)).data;
    return data.test.property;
  }

  async getFunctions() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/functions';
    const data = (await axios.get(url, customHeaders)).data;
    return ((data || {}).data || {}).sweetfunctions;
  }

  isNormalInteger = (str) => {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  extractData = (path, inputData, type) => {
    if (path.length === 0) {
      return inputData;
    }
    if (this.isNormalInteger(path[0])) {
      return this.extractData(_.tail(path), inputData[parseInt(path[0])], type);
    }
    if (type === 'profile') {
      return this.extractData(['sourceProfiles', 'linkedin', ...path], inputData);
    }
    if (type === 'experience') {
      if (path.length === 1 && path[0] != 'workplace') {
        return this.extractData([...path], inputData);
      }
    }
    return this.extractData(_.tail(path), inputData[path[0]]);
  };

  buildInputData = (path, argname, parentFunctionId) => {
    const parentArgs = this.getParamsFromFunctionId(parentFunctionId) || [];
    const parentType = (parentArgs[parseInt((path.split('.') || [])[0]) || 0] || {}).type;
    return {
      argname: argname,
      value: this.extractData(path.split('.'), _.pluck(this.state.inputData, 'value'), parentType),
    };
  };

  handleClickSubResult = async (subResult) => {
    const functionId = (subResult || {}).function;
    const functionArgs = this.getParamsFromFunctionId(functionId) || [];
    const newInputData = _.map(_.zip((subResult || {}).paths || [], functionArgs), ([path, arg]) => {
      return this.buildInputData(path, arg.argname, this.state.function.id);
    });
    await this.setState({
      function: this.getFunctionFromId(functionId),
      inputData: newInputData,
    });
    await this.getFunctionResult(newInputData);
  };

  handleOpenCreateTest = () => {
    this.setState({ testModalVisible: true });
  };

  handleOpenCreateSamplingProperty = () => {
    this.setState({ samplePropertyModalVisible: true });
  };

  handleSetMissionProfileCollectionModalVisible = () => {
    this.setState({ missionProfileCollectionModalVisible: true });
  };

  handleOpenEditTest = (test) => {
    this.setState({ testModalVisible: true, editedTest: test });
  };

  handleCloseTestModal = () => {
    this.setState({ testModalVisible: false, editedTest: null });
  };

  randomStr = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  handleEditTest = async ({ testId, constraints, comment, name, status, techComment, tags }) => {
    const url = baseUrl + '/techAsset/editTest/' + testId;
    const updateData = {
      'property.comment': comment,
      'property.constraints': constraints,
      name,
      status,
      techComment,
      tags: tags ? tags.split(';') : [],
    };
    const updatedTest = (await axios.post(url, updateData)).data;
    let tests = _.map(this.state.tests, (test) => (test.id == testId ? updatedTest : test));
    this.setState({ tests, editedTest: null, testModalVisible: false });
  };

  handleCreateTest = async ({ constraints, comment, name, tags }) => {
    const sweetfunctionId = (this.state.function || {}).id;
    const payload = {
      property: {
        constraints: constraints,
        sweetfunctionId: sweetfunctionId,
        inputData: this.state.inputData,
        lastResult: {
          item: this.state.sweetfunctionResult.item,
          subResults: [],
          function: sweetfunctionId,
          enrichedEntities: this.state.sweetfunctionResult.enrichedEntities,
        },
      },
      status: 'pending',
      name: name,
      success: false,
      comment: comment,
      creationTimestamp: Date.now(),
      lastCompute: null,
      id: (this.state.function || {}).id + '-' + this.randomStr(9),
      tags: tags ? tags.split(';') : [],
    };
    if (!constraints && !comment) {
      alert('You must specify at least one constraint or comment');
      return;
    }
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/postTest';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (result.success) {
      alert('Test Saved !');
    }
    const tests = await this.getFunctionsTests(sweetfunctionId);
    await this.setState({ tests: tests.tests, testModalVisible: false });
  };

  openComment = (comment) => {
    if (comment) {
      alert(comment);
    }
  };

  handleTextChange = (argname, value) => {
    const newInputData = _.map(this.state.inputData, (elt) => (elt.argname === argname ? { argname, value } : elt));
    this.setState({
      inputData: newInputData,
    });
  };

  openProperties = (property) => {
    if (property.constraints) {
      alert(JSON.stringify(property.constraints));
    }
  };

  reloadOntologies = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/reloadOntologies';
    const data = (await axios.get(url, customHeaders)).data;
    alert('Ontologies Reloaded');
  };

  runTest = async (testId) => {
    const payload = { testId };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/runTest';
    const result = (await axios.post(url, payload, customHeaders)).data;
    const sweetfunctionId = ((this.state || {}).function || {}).id;
    const tests = await this.getFunctionsTests(sweetfunctionId);
    await this.setState({ tests: tests.tests });
  };

  runAllTest = async (tests) => {
    const url = baseUrl + '/techAsset/runTest';
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    for (let iTest = 0; iTest < tests.length; iTest++) {
      await axios.post(url, { testId: tests[iTest].id }, customHeaders);
    }
    const sweetfunctionId = ((this.state || {}).function || {}).id;
    const t = await this.getFunctionsTests(sweetfunctionId);
    await this.setState({ tests: t.tests });
  };

  runSelectedTests = async (tests) => {
    const { isSelected } = this.state || {};
    const filteredTests = _.filter(tests, (test) => isSelected[test.id]);
    await this.runAllTest(filteredTests);
    this.setState({
      isSelected: {},
    });
  };

  openInput = async (testId) => {
    const { inputData, lastResult } = await this.getTestData(testId);
    this.setState({ inputData: inputData, sweetfunctionResult: lastResult });
    this.getFunctionResult(inputData);
  };

  deleteTest = async (testId) => {
    const payload = { testId };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/deleteTest';
    await axios.post(url, payload, customHeaders);
    const sweetfunctionId = ((this.state || {}).function || {}).id;
    const tests = await this.getFunctionsTests(sweetfunctionId);
    await this.setState({ tests: tests.tests });
  };

  handleSetStatusFilter = (statusFilter) => {
    this.setState({ statusFilter });
  };

  handleToggle = () => {
    const previousFilter = (this.state || {}).filterFalse || false;
    this.setState({ filterFalse: !previousFilter });
  };

  handleCreateProperty = ({ constraints, samplingBase }) => {
    const sweetfunctionId = (this.state.function || {}).id;
    const test = {
      property: {
        inputData: this.state.inputData,
        constraints: constraints,
        sweetfunctionId: sweetfunctionId,
      },
      status: 'pending',
      success: false,
      id: (this.state.function || {}).id + '-' + this.randomStr(9),
    };
    this.setState({
      propertyTest: !_.isEmpty(constraints) ? test : null,
      samplingBase,
      samplePropertyModalVisible: false,
    });
  };

  handleClosePropertyModal = () => {
    this.setState({ samplePropertyModalVisible: false });
  };

  handleChangeEntityIdFilter = (value) => {
    this.setState({ entityIdFilter: value });
  };

  handleSetMissionProfileCollection = async (params) => {
    this.setState({
      missionId: params.missionId,
      missionPath: params.missionPath,
      profileCollectionId: params.profileCollectionId,
      projectId: params.projectId,
      jobCollectionId: params.jobCollectionId,
      missionProfileCollectionModalVisible: false,
    });
    await this.loadInputFromMission(params.profileCollectionId, params.missionId, params.missionPath);
  };

  handleCloseMissionProfileCollectionModal = () => {
    this.setState({ missionProfileCollectionModalVisible: false });
  };

  handleSelectTest = ({ testId }) => {
    this.setState({
      isSelected: {
        ...this.state.isSelected,
        [testId]: !this.state.isSelected[testId],
      },
    });
  };

  renderCell = (name, key, test) => {
    const { isSelected } = this.state || {};
    const testId = test.id;
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Select' ? (
          <Checkbox checked={isSelected[testId]} onChange={() => this.handleSelectTest({ testId })} />
        ) : name == 'Run' ? (
          <Icon link color={'black'} name='play' onClick={() => this.runTest(testId)} />
        ) : name == 'Success' && test.success ? (
          <Icon color={'green'} name='check' />
        ) : name == 'Success' && !test.success ? (
          <Icon color={'red'} name='close' />
        ) : name == 'Last Compute' ? (
          <span> {test.lastCompute} </span>
        ) : name == 'Comment' ? (
          <span>{test.comment && test.comment}</span>
        ) : name == 'Tech Comment' ? (
          <span>{test.techComment && test.techComment}</span>
        ) : name == 'Properties' ? (
          test.property ? (
            <Icon link color={'grey'} name='file outline' onClick={() => this.openProperties(test.property)} />
          ) : null
        ) : name == 'Creator' ? (
          <span> {test.username} </span>
        ) : name == 'Creation' ? (
          <span> {moment(test.creationTimestamp).format('MM/DD/YYYY')} </span>
        ) : name == ' ' ? (
          <Icon link color={'red'} name='delete' onClick={() => this.deleteTest(testId)} />
        ) : name == 'Edit' ? (
          <Icon link color={'blue'} name='pencil' onClick={() => this.handleOpenEditTest(test)} />
        ) : name == 'Go To Input' ? (
          <Icon link color={'grey'} name='eye' onClick={() => this.openInput(testId)} />
        ) : name == 'Status' ? (
          test.status ? (
            <SemanticLabel>{test.status}</SemanticLabel>
          ) : null
        ) : name == 'name' ? (
          <span> {test.name ? test.name : testId} </span>
        ) : name == 'Id' ? (
          <span>
            <small>{testId} </small>
          </span>
        ) : name == 'Tags' ? (
          _.map(test.tags || [], (tag) => <SemanticLabel>{tag}</SemanticLabel>)
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const sweetfunctionId = ((this.state || {}).function || {}).id;

    const outputType = ((this.state || {}).function || {}).outputType;
    const description = ((this.state || {}).function || {}).description;
    const {
      inputData,
      missionId,
      missionPath,
      profileCollectionId,
      projectId,
      jobCollectionId,
      sweetfunctionResult,
      tests,
      editedTest,
      statusFilter,
      propertyTest,
      samplingBase,
    } = this.state || {};

    const enrichedEntities = (sweetfunctionResult || {}).enrichedEntities;

    const testModalInitialData = {
      ...editedTest,
      ...(((editedTest || {}).property || {}).constraints && { constraints: editedTest.property.constraints }),
      ...((editedTest || {}).tags && { tags: ((editedTest || {}).tags || []).join(';') }),
    };
    const propertyInitialData = {
      ...propertyTest,
      ...(((propertyTest || {}).property || {}).constraints && { constraints: propertyTest.property.constraints }),
      ...(samplingBase && { samplingBase }),
    };
    const missionProfileCollectionInitialData = {
      projectId,
      profileCollectionId,
      jobCollectionId,
      missionId,
      missionPath,
    };

    const filteredTests = (this.state || {}).filterFalse
      ? _.filter(
          _.filter(tests, ({ status }) => statusFilter == 'all' || statusFilter == status),
          ({ success }) => !success,
        )
      : _.filter(tests, ({ status }) => statusFilter == 'all' || statusFilter == status);
    const columnNames = [
      'Select',
      'Id',
      'Creator',
      'Creation',
      'name',
      'Run',
      'Success',
      'Last Compute',
      'Properties',
      'Comment',
      'Tech Comment',
      'Status',
      'Tags',
      'Go To Input',
      'Edit',
      ' ',
    ];
    const filterStatusOptions = ['all'].concat(_.map(STATUS_OPTIONS, ({ value }) => value));

    return (
      <Grid rows={3} columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            <Form.Select
              search
              placeholder='Function Id'
              options={_.map((this.state || {}).sweetfunctions || [], (sweetfunction, key) => ({
                value: sweetfunction.id,
                key: key,
                text: sweetfunction.name,
              }))}
              onChange={this.handleFunctionChange}
              value={sweetfunctionId}
            />
            {description ? <div> {description} </div> : outputType ? <div> {' return ' + outputType} </div> : null}
          </Grid.Column>
          <Grid.Column columns={2}>
            <span>
              {sweetfunctionId ? (
                <Button color='orange' onClick={() => this.runAllTest(tests)}>
                  Run All Tests
                </Button>
              ) : null}
              {sweetfunctionId ? (
                <Button color='yellow' onClick={() => this.runSelectedTests(tests)}>
                  Run Selected Tests
                </Button>
              ) : null}
              {sweetfunctionId ? (
                <Button color='grey' onClick={() => this.reloadOntologies()}>
                  Reload Ontologies
                </Button>
              ) : null}
              {sweetfunctionId ? (
                <Button toggle active={(this.state || {}).filterFalse} onClick={() => this.handleToggle()}>
                  Filter Successful Tests
                </Button>
              ) : null}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {_.map(filterStatusOptions, (value) => (
            <Button color={value === statusFilter ? 'green' : 'grey'} onClick={() => this.handleSetStatusFilter(value)}>
              {value}
            </Button>
          ))}
        </Grid.Row>
        {sweetfunctionId ? (
          <Grid.Row
            style={{
              display: 'inline-block',
              maxHeight: '400px',
              width: '100%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <Table>
              <Table.Header>
                <Table.Row>
                  {columnNames.map((name, index) => {
                    return (
                      <Table.HeaderCell key={index} textAlign='center'>
                        {name}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredTests.map((test, key) => {
                  return (
                    <Table.Row key={key} style={{ backgroundColor: getItemColor(test.success) }}>
                      {columnNames.map((name, index2) => {
                        return this.renderCell(name, index2 + key, test);
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Row>
        ) : null}
        <Grid.Row>
          <Grid.Column width={10}>
            <span>
              {(this.state || {}).function && (this.state || {}).inputData && (
                <Button
                  icon
                  onClick={() => this.getFunctionResult(this.state.inputData)}
                  color={this.state.loadingSweetfunctionResult ? 'grey' : 'blue'}
                >
                  <Icon name='play' />
                </Button>
              )}
              {sweetfunctionId ? (
                <Button
                  color={this.state.propertyTest || this.state.samplingBase ? 'grey' : 'green'}
                  onClick={this.handleOpenCreateSamplingProperty}
                >
                  {this.state.propertyTest || this.state.samplingBase
                    ? 'Edit Sampling Property'
                    : 'Set Sampling Property'}
                </Button>
              ) : null}
              {sweetfunctionId ? (
                <Button color={'olive'} onClick={this.handleSetMissionProfileCollectionModalVisible}>
                  {'Set Mission and Profile Collection'}
                </Button>
              ) : null}
            </span>
            {(this.state || {}).function ? (
              <div>
                <FunctionParametersSelector
                  args={this.state.function.args}
                  enrichedEntities={enrichedEntities}
                  inputData={inputData}
                  onSubmit={this.handleInputDataChange}
                  handleTextChange={this.handleTextChange}
                  propertyTest={(this.state || {}).propertyTest}
                  samplingBase={samplingBase}
                  missionId={missionId}
                  missionPath={missionPath}
                  profileCollectionId={profileCollectionId}
                />
              </div>
            ) : null}
          </Grid.Column>

          <Grid.Column width={6}>
            {((this.state || {}).sweetfunctionResult || {}).item !== undefined ? (
              <div>
                <Grid.Row>
                  <Button color='green' onClick={this.handleOpenCreateTest}>
                    Create Test
                  </Button>
                </Grid.Row>
                {['score-dict', 'normalized-score-dict'].indexOf(outputType) >= 0 && (
                  <Input
                    onChange={(e) => this.handleChangeEntityIdFilter(e.target.value)}
                    placeholder='Filter entities...'
                  />
                )}
                <Grid.Row>
                  <FunctionResult
                    item={(this.state.sweetfunctionResult || {}).item}
                    subResults={(this.state.sweetfunctionResult || {}).subResults}
                    functionId={sweetfunctionId}
                    explanation={(this.state.sweetfunctionResult || {}).explanation}
                    functionMapper={(functionId) =>
                      (
                        this.state.sweetfunctions.find(function(element) {
                          return element.id === functionId;
                        }) || {}
                      ).outputType
                    }
                    paths={this.state.sweetfunctionResult.paths}
                    handleClickSubResult={this.handleClickSubResult}
                    isFirst={true}
                    entityIdFilter={this.state.entityIdFilter}
                    inputData={this.state.inputData}
                  />
                </Grid.Row>
              </div>
            ) : null}
          </Grid.Column>
          {(this.state || {}).testModalVisible ? (
            <TestEditModal
              resultType={this.state.function.outputType}
              sweetfunctionId={(this.state.function || {}).id}
              onCreate={this.handleCreateTest}
              onEdit={this.handleEditTest}
              onClose={this.handleCloseTestModal}
              initialValue={testModalInitialData}
              creationMode={_.isEmpty(testModalInitialData)}
              tagIds={this.state.tagIds}
            />
          ) : null}
          {(this.state || {}).samplePropertyModalVisible ? (
            <PropertyModal
              resultType={this.state.function.outputType}
              sweetfunctionId={(this.state.function || {}).id}
              onCreate={this.handleCreateProperty}
              onClose={this.handleClosePropertyModal}
              initialValue={propertyInitialData}
              creationMode={_.isEmpty(propertyInitialData)}
            />
          ) : null}
          {(this.state || {}).missionProfileCollectionModalVisible ? (
            <MissionProfileCollectionModal
              initialData={missionProfileCollectionInitialData}
              onSubmit={this.handleSetMissionProfileCollection}
              onClose={this.handleCloseMissionProfileCollectionModal}
            />
          ) : null}
        </Grid.Row>
      </Grid>
    );
  }
}

const withInitialQuery = (WrappedComponent) =>
  class extends React.Component {
    render() {
      return (
        <Switch>
          <Route exact path='/assets-explorer/all-functions'>
            {({ location }) => {
              const queryValues = queryString.parse(location.search);

              const functionId = queryValues.functionId;
              const profileCollectionId = queryValues.profileCollectionId;
              const jobCollectionId = queryValues.jobCollectionId;
              const missionId = queryValues.missionId;

              return (
                <WrappedComponent
                  initialFunctionId={functionId}
                  initialProfileCollectionId={profileCollectionId}
                  initialJobCollectionId={jobCollectionId}
                  initialMissionId={missionId}
                />
              );
            }}
          </Route>
        </Switch>
      );
    }
  };

//export default TechAssetSandBox;
export default withInitialQuery(TechAssetSandBox);
