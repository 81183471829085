import React, { Component } from 'react';
import { Button, Dropdown, Form, Grid, Modal } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';
import baseUrl from '../baseUrl.js';

class CreateClientModal extends Component {
  async componentDidMount() {
    const projects = await this.getProjects();
    const projectIdOptions = await this.getProjectIdOptions(projects);
    this.setState({ projects, projectIdOptions });
  }

  async getProjects() {
    const url = baseUrl + '/sweetchain/getProjects';
    const projects = (await axios.get(url)).data.data;
    return projects;
  }

  async getProjectIdOptions(projects) {
    const filteredProjects = _.filter(
      projects,
      (project) =>
        !_.isUndefined(project.userId) &&
        project.jobCollectionIds.length === 1 &&
        project.profileCollectionIds.length === 1,
    );
    const projectIdOptions = _.map(filteredProjects, (project) => ({
      value: project.id,
      label: project.userId,
    }));
    return projectIdOptions;
  }

  handleChange(params) {
    const { projects } = this.state || {};
    const filteredProject = _.find(projects, (project) => project.id === params.projectId);
    const selectedProjectInfo = {
      name: filteredProject.id,
      id: filteredProject.id,
      userId: filteredProject.userId,
    };
    this.setState({
      selectedProjectInfo,
    });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const { projectIdOptions } = this.state || {};
    return (
      <Modal closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Project Name</label>
              <Select field='projectId' options={projectIdOptions} />
            </Form.Field>
            <br />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit((this.state || {}).selectedProjectInfo)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CreateClientModal;
