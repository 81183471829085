import _ from 'underscore';
import { combineReducers } from 'redux';

// Actions
const SET_OFFERS_LIST = 'SET_OFFERS_LIST';
const SET_COUNTS = 'SET_COUNTS';
const SET_SEARCH = 'SET_SEARCH';
const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
const SET_EDIT_MODE = 'SET_EDIT_MODE';
const SET_EDIT = 'SET_EDIT';
const SET_EDIT_MODE_AND_EDIT = 'SET_EDIT_MODE_AND_EDIT';
const SET_ACTION = 'SET_ACTION';
const DELETE_OFFER = 'DELETE_OFFER';
const TOGGLE_ACTIVE = 'TOGGLE_ACTIVE';
const SET_SELECT_OPTS = 'SET_SELECT_OPTS';
const SET_WORKPIPES = 'SET_WORKPIPES';
const SET_SELECTED_WORKPIPE = 'SET_SELECTED_WORKPIPE';
const SET_SELECTED_INPUT = 'SET_SELECTED_INPUT';
const UPDATE_CURRENT_PROFILE = 'UPDATE_CURRENT_PROFILE';
const SET_PROFILES = 'SET_PROFILES';
const TOGGLE_MORE = 'TOGGLE_MORE';
const SET_GLOBAL_HINT = 'SET_GLOBAL_HINT';
const SET_EXPERIENCE_HINT = 'SET_EXPERIENCE_HINT';
const SET_EXTRA_FIELDS = 'SET_EXTRA_FIELDS';
const SET_EXTRA_IDS = 'SET_EXTRA_IDS';
const TOGGLE_STATE_FILTER = 'TOGGLE_STATE_FILTER';
const SET_UPLOAD_EMAIL = 'SET_UPLOAD_EMAIL';
const REMOVE_SOURCE = 'REMOVE_SOURCE';
const TOGGLE_PENDING_UPLOAD = 'TOGGLE_PENDING_UPLOAD';
const SET_PENDING_UPLOAD = 'SET_PENDING_UPLOAD';
const SET_ACTIONS_PANE = 'SET_ACTIONS_PANE';
const SET_FULL_NEXT_PROFILES = 'SET_FULL_NEXT_PROFILES';
const SET_FULL_NEXT_STATE = 'SET_FULL_NEXT_STATE';
const UPDATE_SWEETYNOTE = 'UPDATE_SWEETYNOTE';
const SET_SWEETYNOTE = 'SET_SWEETYNOTE';
const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS';

const actions = {
  setCounts: counts => ({ type: SET_COUNTS, counts }),
  setOffersList: offersList => ({ type: SET_OFFERS_LIST, offersList }),
  setSearch: search => ({ type: SET_SEARCH, search }),
  setSelectedOffer: (offer, updated = false) => ({ type: SET_SELECTED_OFFER, offer, updated }),
  setEditMode: mode => ({ type: SET_EDIT_MODE, mode }),
  setEdit: edit => ({ type: SET_EDIT, edit }),
  setEditModeAndEdit: (mode, edit) => ({ type: SET_EDIT_MODE_AND_EDIT, mode, edit }),
  setAction: action => ({ type: SET_ACTION, action }),
  deleteOffer: id => ({ type: DELETE_OFFER, id }),
  toggleOfferIsActive: id => ({type: TOGGLE_ACTIVE, id}),
  setSelectOpts: (select, opts) => ({ type: SET_SELECT_OPTS, select, opts }),
  setWorkPipes: (workPipes) => ({ type: SET_WORKPIPES, workPipes }),
  setSelectedWorkPipe: (workPipe) => ({
    type: SET_SELECTED_WORKPIPE,
    workPipe,
  }),
  setSelectedInput: (input) => ({ type: SET_SELECTED_INPUT, input }),
  setProfiles: (current, next) => ({ type: SET_PROFILES, current, next }),
  toggleMore: (id) => ({ type: TOGGLE_MORE, id }),
  setGlobalHint: (id) => ({ type: SET_GLOBAL_HINT, id }),
  setExperienceHint: (i, hint) => ({ type: SET_EXPERIENCE_HINT, i, hint }),
  toggleStateFilter: (state) => ({ type: TOGGLE_STATE_FILTER, state }),
  removeSource: (source) => ({ type: REMOVE_SOURCE, source }),
  togglePendingUpload: () => ({ type: TOGGLE_PENDING_UPLOAD }),
  setPendingUpload: (value) => ({ type: SET_PENDING_UPLOAD, value }),
  setActionsPane: (id, i) => ({ type: SET_ACTIONS_PANE, id, i }),
  setFullNextProfiles: (profiles) => ({
    type: SET_FULL_NEXT_PROFILES,
    profiles,
  }),
  setFullNextState: (state) => ({ type: SET_FULL_NEXT_STATE, state }),
  setSearchStatus: (searchStatus) => ({
    type: SET_SEARCH_STATUS,
    searchStatus,
  }),

  updateCurrentProfile: (frontendId, current) => ({
    type: UPDATE_CURRENT_PROFILE,
    frontendId,
    current,
  }),
  setSweetynote: (frontendId, sweetynote) => ({
    type: SET_SWEETYNOTE,
    frontendId,
    sweetynote,
  }),
  updateSweetynote: (frontendId, update) => ({
    type: UPDATE_SWEETYNOTE,
    frontendId,
    update,
  }),
  setExtraFields: (frontendId, fields) => ({
    type: SET_EXTRA_FIELDS,
    frontendId,
    fields,
  }),
  setExtraIds: (frontendId, ids) => ({ type: SET_EXTRA_IDS, frontendId, ids }),
  setUploadEmail: (frontendId, email) => ({
    type: SET_UPLOAD_EMAIL,
    frontendId,
    email,
  }),
};

// Reducers
const searchStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_STATUS:
      return action.searchStatus;
    default:
      return state;
  }
};

const offersList = (state = [], action) => {
  switch (action.type) {
    case SET_OFFERS_LIST:
      return action.offersList;
    case SET_SELECTED_OFFER:
      if (action.updated) {
        const i = _.findIndex(state, (o) =>
          o.id
            ? o.id === action.offer.id
            : o.sourceType === action.offer.sourceType &&
              o.sourceId === action.offer.sourceId,
        );
        return i < 0
          ? [...state, action.offer] // append or replace
          : [...state.slice(0, i), action.offer, ...state.slice(i + 1)];
      }
      return state;
    case DELETE_OFFER:
      return _.filter(state, o => o.id !== action.id);
    case TOGGLE_ACTIVE:
      return _.map(state, o => {
      	if (o.id === action.id){
            return {
                ...o,
                isActive:!o.isActive
            }
        } else {
            return o
        }
      });
    default:
      return state;
  }
};

const counts = (state = {}, action) => {
  switch (action.type) {
    case SET_COUNTS:
      return action.counts;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const selectedOffer = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_OFFER:
      return action.offer;
    default:
      return state;
  }
};

const editMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_EDIT_MODE_AND_EDIT:
      return action.mode;
    case SET_EDIT_MODE:
      return action.mode;
    default:
      return state;
  }
};

const edit = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_MODE_AND_EDIT:
      return action.edit;
    case SET_EDIT_MODE:
      return {};
    case SET_EDIT:
      return action.edit;
    default:
      return state;
  }
};

const currentActions = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
    case SET_OFFERS_LIST:
    case SET_SELECTED_OFFER:
      return {};
    case SET_ACTION:
      return {
        ...state,
        [action.action]: true,
      };
    default:
      return state;
  }
};

const selectOpts = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECT_OPTS:
      return {
        ...state,
        [action.select]: action.opts,
      };
    default:
      return state;
  }
};

const workPipes = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_OFFER:
      return action.offer ? state : [];
    case SET_WORKPIPES:
      return action.workPipes;
    default:
      return state;
  }
};

const selectedWorkPipe = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_OFFER:
      return action.offer ? state : null;
    case SET_SELECTED_WORKPIPE:
      return action.workPipe;
    default:
      return state;
  }
};

const selectedInput = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_INPUT:
      return action.input;
    default:
      return state;
  }
};

const currentProfile = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_INPUT:
    case SET_SELECTED_WORKPIPE:
    case SET_SELECTED_OFFER:
      return null;
    case SET_GLOBAL_HINT:
      if (!state || !state.sourceProfiles || !state.sourceProfiles.linkedin) {
        return state;
      }
      const newState = _.clone(state);
      newState.sourceProfiles.linkedin.data.experiences = _.map(
        newState.sourceProfiles.linkedin.data.experiences,
        (exp) => ({
          ...exp,
          hint: '',
        }),
      );
      return newState;
    case SET_EXPERIENCE_HINT:
      if (!state || !state.sourceProfiles || !state.sourceProfiles.linkedin) {
        return state;
      }
      const newState2 = _.clone(state);
      newState2.sourceProfiles.linkedin.data.experiences[action.i].hint =
        newState2.sourceProfiles.linkedin.data.experiences[action.i].hint !==
        action.hint
          ? action.hint
          : '';
      return newState2;
    case SET_PROFILES:
      return action.current;
    case UPDATE_CURRENT_PROFILE:
      if (
        !state ||
        !state.frontendId ||
        state.frontendId !== action.frontendId
      ) {
        console.log(
          'unmatched  action UPDATE_CURRENT_PROFILE' + action.frontendId,
        );
        return state;
      }
      return {
        ...state,
        ...action.current,
      };
    case SET_EXTRA_FIELDS:
      if (
        !state ||
        !state.frontendId ||
        state.frontendId !== action.frontendId
      ) {
        console.log('unmatched SET_EXTRA_FIELDS action ' + action.frontendId);
        return state;
      }
      return {
        ...state,
        extraFields: action.fields,
      };
    case SET_EXTRA_IDS:
      if (
        !state ||
        !state.frontendId ||
        state.frontendId !== action.frontendId
      ) {
        console.log('unmatched SET_EXTRA_IDS action ' + action.frontendId);
        return state;
      }
      return {
        ...state,
        idFields: {
          ...state.idFields,
          ...action.ids,
        },
      };
    case SET_UPLOAD_EMAIL:
      if (
        !state ||
        !state.frontendId ||
        state.frontendId !== action.frontendId
      ) {
        console.log('unmatched SET_UPLOAD_EMAIL action ' + action.frontendId);
        return state;
      }
      return {
        ...state,
        uploadEmail: action.email,
      };
    case SET_SWEETYNOTE:
      if (
        !state ||
        !state.frontendId ||
        state.frontendId !== action.frontendId
      ) {
        console.log('unmatched SET_SWEETYNOTE action ' + action.frontendId);
        return state;
      }
      return {
        ...state,
        sweetynote: action.sweetynote,
      };
    case UPDATE_SWEETYNOTE:
      if (
        !state ||
        !state.frontendId ||
        state.frontendId !== action.frontendId
      ) {
        console.log('unmatched UPDATE_SWEETYNOTE action ' + action.frontendId);
        return state;
      }
      return {
        ...state,
        sweetynote: {
          ...((state || {}).sweetynote || {}),
          ..._.mapObject(action.update, (value, key) => [
            ...(state && _.isArray(state[key]) ? state[key] : []),
            value,
          ]),
        },
      };
    case REMOVE_SOURCE:
      return {
        ...state,
        idFields: _.omit(state.idFields, action.source),
        sourceProfiles: _.omit(state.sourceProfiles, action.source),
      };
    default:
      return state;
  }
};

const nextProfiles = (state = null, action) => {
  switch (action.type) {
    case SET_PROFILES:
      return action.next;
    default:
      return state;
  }
};

const more = (state = {}, action) => {
  switch (action.type) {
    case SET_PROFILES:
      return {};
    case TOGGLE_MORE:
      return {
        ...state,
        [action.id]: !state[action.id],
      };
    default:
      return state;
  }
};

const globalHint = (state = '', action) => {
  switch (action.type) {
    case SET_PROFILES:
      return '';
    case SET_GLOBAL_HINT:
      return action.id;
    default:
      return state;
  }
};

const stateFilter = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_WORKPIPE:
      return {};
    case TOGGLE_STATE_FILTER:
      return {
        ...state,
        // 0: disabled, 1: positive, 2: negative
        [action.state]: ((state[action.state] || 0) + 1) % 3,
      };
    default:
      return state;
  }
};

const pendingUpload = (state = false, action) => {
  switch (action.type) {
    case SET_PENDING_UPLOAD:
      return action.value;
    case TOGGLE_PENDING_UPLOAD:
      return !state;
    default:
      return state;
  }
};

const actionsPane = (state = {}, action) => {
  switch (action.type) {
    case SET_PROFILES:
      return {
        left: 0,
        right: 0,
      };
    case SET_ACTIONS_PANE:
      return {
        ...state,
        [action.id]: action.i,
      };
    default:
      return state;
  }
};

const fullNextProfiles = (state = { state: 'closed' }, action) => {
  switch (action.type) {
    case SET_FULL_NEXT_STATE:
      return { state: action.state, profiles: state.profiles };
    case SET_FULL_NEXT_PROFILES:
      return { state: 'ready', profiles: action.profiles };
    case SET_SELECTED_INPUT:
      return { state: 'closed' };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  actions: currentActions,
  actionsPane,
  offersList,
  counts,
  search,
  searchStatus,
  selectedOffer,
  pendingUpload,
  editMode,
  edit,
  more,
  selectOpts,
  workPipes,
  selectedWorkPipe,
  selectedInput,
  nextProfiles,
  fullNextProfiles,
  stateFilter,

  currentProfile,
  globalHint,
});

export { actions, rootReducer };
