import {
    Bar,
    BarChart,
    CartesianGrid,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Loader, Modal } from 'semantic-ui-react';

import { CustomTable } from './TableWidget';
import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';

const getDataFromDescriptor = async ({dataDescriptor}) => {
    const BIG_QUERY = "bigquery"
    const BIG_QUERY_COUNT_RANGE = "bigquery-count-range"
    if (dataDescriptor.type === BIG_QUERY) {
      const { data } = await axios.post(`${baseUrl}/bigQueryStats/timeAggregation`, {
        ...dataDescriptor,
      });
      return data
    }
    if (dataDescriptor.type === BIG_QUERY_COUNT_RANGE) {
      const { data } = await axios.post(`${baseUrl}/bigQueryStats/timeAggregationCountRange`, {
        ...dataDescriptor,
      });
      return data
    }
    throw Error(`Unrecognized data type : ${dataDescriptor.type}, should be in ${BIG_QUERY}, ${BIG_QUERY_COUNT_RANGE}`)
  }
  class BarChartWidget extends React.Component {
    state = {
      loading: true,
      initialDataDescriptor: undefined,
      dataDescriptor: undefined,
      toDisplay: {
        dataDescriptor: undefined,
        results: undefined,
      }
    };
  
    constructor(props) {
      super(props);
      this.state.initialDataDescriptor = this.props.data;
    }
  
    handleLoad = async ({ dataDescriptor }) => {
  
      try {
        const data = await getDataFromDescriptor({ dataDescriptor });
  
        if (data.error) {
          throw Error(data.error);
        }
  
        this.setState({
          loading: false,
          toDisplay: {
            dataDescriptor,
            results: data.results,
          }
        });
        console.log(data);
      } catch (e) {
        return alert(e);
      }
      //this.setState(data);
    };
  
    handleDisplaySelection = ({selection}) => {
      this.setState({selection})
    }
  
    handleCloseSelection = async () => {
      this.setState({selection: null})
    }
  
    componentDidMount() {
      if (this.state.initialDataDescriptor) {
        this.handleLoad({ dataDescriptor: this.state.initialDataDescriptor });
      }
    }
  
    renderChart({ toDisplay }) {
  
      console.log({ toDisplay });
  
      if (!toDisplay) {
        return <div />;
      }
  
      const { dataDescriptor, results } = toDisplay;
  
      const data = results;
      //const data = [];
  
      const firstGroup = (dataDescriptor.groups || [])[0];
  
      if (dataDescriptor.percentMode) {
        _.each(results, (result) => {
          const nbTarget = result[firstGroup.id] || 0;
          let total = 0;
          _.each(dataDescriptor.groups, ({ id }) => {
            total += result[id] || 0;
          });
          result.__percent = nbTarget / Math.max(total, 0.000001);
        });
      }
  
      /*
      {dataDescriptor.percentMode ? (
        <Bar dataKey={'ratio'} fill="#8E44AD"/>
      ) : [
        <Bar key='send-charts' dataKey={'sends'} stackId='a' fill="#3498DB"/>,
        <Bar key='skip-charts' dataKey={'skips'} stackId='a' fill="#D35400"/>
      ]}
      */
  
      return (
        <BarChart data={data} width={500} height={300}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          {dataDescriptor.percentMode ? (
            <Bar
              key={firstGroup.id}
              dataKey={'__percent'}
              fill={firstGroup.color}
              stackId={1}
            />
          ) : (
            _.map(dataDescriptor.groups, ({ id, color }) => (
              <Bar
                key={id}
                dataKey={id}
                fill={color}
                stackId={1}
                onClick={(e) => this.handleDisplaySelection({selection: e.selection[id]})}
              />
            ))
          )}
        </BarChart>
      );
    }
  
    renderSelection = ({selection}) => {
      return (
        <Modal
          open={true}
          onClose={() => this.handleCloseSelection()}
          closeIcon
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            {(selection || {}).title}
          </Modal.Header>
          <Modal.Content style={{ overflowY: 'scroll', height: '700px' }}>
            <center>
              {selection.type == "table" ? (<CustomTable data={selection.data} />) : null}
            </center>
          </Modal.Content>
        </Modal>
      )
    }
  
    render() {
  
      if (this.state.loading) {
        return (
          <center>
            <br/><br/><br/>
            Loading...{' '}<Loader inline active />
            <br/><br/><br/>
          </center>
        );
      }
  
      return (
        <div>
          <h4>{this.props.name}</h4>
          {this.renderChart({ toDisplay: this.state.toDisplay })}
          {this.state.selection && this.renderSelection({ selection: this.state.selection })}
        </div>
      );
    }
  }
  
  export default BarChartWidget;
  