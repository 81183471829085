import React from 'react';
import {
  Container,
  Card,
  Grid,
  Segment,
  Label,
  Button,
  Message
} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import { SweetForm, Input } from 'sweetform';
import { fitIsValid, JobPositionFitEditor } from './EducationFields';

class SchoolTypesOverview extends React.Component {
  state = {
    search: '',
  };

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  render() {

    const {
      schoolTypes,
      onSelectSchoolType,
      selectedSchoolType,
    } = this.props;
    const { search } = this.state;
    const filteredSchoolTypes = (search && schoolTypes) ?
       _.filter(schoolTypes, ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      : schoolTypes
    const sortedSchoolTypes =  _.sortBy(filteredSchoolTypes, ({name}) => name)

    return (
      <Container>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <h3>
                      School Types
                    </h3>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <SweetForm onChange={this.onChangeSearch}>
                      <Input field="search" />
                    </SweetForm>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
            <Card.Description>
              <Grid>
                {sortedSchoolTypes.map((schoolType) => {
                  return (
                    <Grid.Row key={schoolType.id}>
                      <Grid.Column
                        width={16}
                        onClick={() => onSelectSchoolType(schoolType)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          style={
                            selectedSchoolType && selectedSchoolType.id === schoolType.id
                              ? {
                                  fontWeight: 'bold',
                                  color: 'green',
                                  fontSize: '13px',
                                }
                              : {}
                          }
                        >
                          {`${schoolType.name} (missing ${schoolType.missingLinks} links)`}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}


const SchoolType = ({schoolType}) => (
  schoolType ?
    <div>
      <h2>{schoolType.name}</h2>
      <Label>id: {schoolType.id}</Label>
    </div>
  : null
)


const SchoolTypeEditor = ({schoolType, jobPositionIds, onUpdate, onSave, saved}) => {
  return (
  <div>
    <Button positive={!saved} onClick={()=>onSave()} disabled={saved}>Save</Button>
    <SweetForm
      key={schoolType.id}
      initialValues={schoolType}
      onChange={onUpdate}
    >
      <JobPositionFitEditor 
        field="jobPositionFit" 
        jobPositionIds={jobPositionIds}
      />
    </SweetForm>
  </div>
)}




class SchoolTypes extends React.Component {
  state = {
    schoolTypes:null,
    jobPositionIds:null,
    selectedSchoolType:null,
    saved: true
  };

  componentWillMount() {
    this.loadData();
  }

  loadData = async () => {
    const schoolTypes = await this.getSchoolTypes();
    const jobPositionIds = await this.getJobPositionIds();
    this.computeMissingLinks({schoolTypes, jobPositionIds});
    this.setState({schoolTypes, jobPositionIds})
  }


  getSchoolTypes = async () => {
    //Todo
    try {
      const { data } = await axios.get(`${baseUrl}/workplaces/listSchoolTypes`);
      return data
    } catch (e) {
      alert(e);
    }
  };

  getJobPositionIds = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/tags/list?type=job`);
      const jobPositionIds = _.pluck(data, 'id')
      return jobPositionIds
    } catch (e) {
      alert(e);
    }
  };

  computeMissingLinks = ({schoolTypes, jobPositionIds}) => {
    _.each(schoolTypes, (schoolType)=>{
      const jobPositionFit = (schoolType.jobPositionFit || {})
      const missingJobs = _.filter(jobPositionIds, (jobId)=>(!(jobId in jobPositionFit) || !fitIsValid(jobPositionFit[jobId])))
      schoolType.missingLinks = (missingJobs || []).length
    })
  }

  onSelectSchoolType = (schoolType) => {
    const {jobPositionIds} = this.state;
    const sortedJobPositionIds = _.sortBy(jobPositionIds, (jobId)=>{
      const fit = (schoolType.jobPositionFit || {})[jobId]
      if(fitIsValid(fit)) {
        return -fit
      }
      return -1000
    })
    this.setState({
      selectedSchoolType: schoolType,
      jobPositionIds: sortedJobPositionIds,
      saved: true
    })
  }

  onUpdate = (schoolType) => {
    this.setState({
      selectedSchoolType:schoolType,
      saved:false
    });
  };

  onSave = async () => {
    const cleanJobPositionFit = (jobPositionFit) => {
      return _.mapObject(jobPositionFit, (value, key) => {
        if (!fitIsValid(value)){
          throw Error('Invalid value entered for '+key+' : '+value+'. Should be a number between 0 and 1.')
        }
        return parseFloat(value)
      })
    }

    try {
      const { selectedSchoolType, schoolTypes } = this.state;
      const { id } = selectedSchoolType;
      selectedSchoolType.jobPositionFit = cleanJobPositionFit(selectedSchoolType.jobPositionFit)
      //todo : modify
      const { data } = await axios.put(`${baseUrl}/workplaces/schoolTypes/${id}`, _.omit(selectedSchoolType, 'id'));
      if (!data.success){
        console.log(data)
        throw Error(data.error)
      }
      const newSchoolTypes = _.map(schoolTypes, (otherSchoolType)=>(
        otherSchoolType.id===id ? selectedSchoolType : otherSchoolType
      ))
      this.setState({
        saved:true,
        schoolTypes:newSchoolTypes
      })
      console.log('saved')
    } catch (e) {
      console.log(e)
      alert(e)
    }
    
  }

  render () {
    const { 
      schoolTypes, 
      selectedSchoolType, 
      jobPositionIds, 
      saved 
    } = this.state;
    const totalMissingLinks = _.pluck(schoolTypes, 'missingLinks').reduce(function(pv, cv) { return pv + cv; }, 0);
    return (

      <div>
      {
        totalMissingLinks ? (
          <Message error>
          {`Missing ${totalMissingLinks} link.`}
          </Message>
        ) : null
      }
      <Grid columns={2}>
        <Grid.Column width={4}>
          <SchoolTypesOverview
            schoolTypes={schoolTypes}
            onSelectSchoolType={this.onSelectSchoolType}
            selectedSchoolType={selectedSchoolType}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedSchoolType && (
            <div>
              <Segment key={selectedSchoolType.id}>
                <SchoolType schoolType={selectedSchoolType} />
              </Segment>
            </div>
          )}
          {selectedSchoolType && (
            <Segment>
              <SchoolTypeEditor
                schoolType={selectedSchoolType}
                jobPositionIds={jobPositionIds}
                onUpdate={this.onUpdate}
                onSave={this.onSave}
                saved={saved}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid>
      </div>


    )

  }


}

export default SchoolTypes;
