import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Icon, Statistic, Grid, Label } from 'semantic-ui-react';

import sortSheetItems from '../../sortSheetItems';
import filterSheetItems from '../../filterSheetItems';
import getMatchesStatsAndScore from '../../getMatchesStatsAndScore';
import SheetItemCard from './SheetItemCard';



const FOLLOWUP_DISPLAY_MODE = "followUp"
const UPLOAD_DISPLAY_MODE = "upload"
const TO_REVIEW_DISPLAY_MODE = "toReview"

class SheetItemsOverview extends Component {
  state = {
    displayMode: FOLLOWUP_DISPLAY_MODE
  };

  handleChangeSort = ({ sortBy, sortAscending }) => {
    if (sortBy !== this.state.sortBy || sortAscending !== this.state.sortAscending) {
      this.setState({
        sortBy,
        sortAscending,
      });
    }
  };

  handleChangeDisplayMode = (displayMode) => {
    this.setState({ displayMode })
  }

  render() {
    const {
      sheetItems,
      targetSheetItemId,
      handleLoadProfilesSelection,
      handleLoadClientsSelection,
      handleSelectTargetSheetItem,
      handleRecomputeReverseSearch,
      handleUpdateProfile,
      mode,
    } = this.props;

    const { displayMode } = this.state;
    const filteredSheetItems = (
      displayMode == UPLOAD_DISPLAY_MODE ? filterSheetItems({sheetItems, filterBy:"noProcess"}) 
      : displayMode == TO_REVIEW_DISPLAY_MODE ? filterSheetItems({sheetItems, filterBy: "toReview"})
      : sheetItems
    )
    const statsAndScoreFromId = {};
    _.each(filteredSheetItems, (sheetItem) => {
      statsAndScoreFromId[sheetItem.id] = getMatchesStatsAndScore(sheetItem.matches);
    });
    const sortedSheetItems = displayMode == UPLOAD_DISPLAY_MODE ? sortSheetItems({
      sheetItems: filteredSheetItems,
      sortBy : 'toUpload',
    }) : sortSheetItems({
      sheetItems: filteredSheetItems,
      sortBy : 'nextContact',
      sortAscending: true,
      statsAndScoreFromId,
    });

    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: -40,
            right: '0%',
          }}
        >
          <b>Mode </b>
          <Button.Group>
            <Button positive={displayMode==FOLLOWUP_DISPLAY_MODE} onClick={()=>this.handleChangeDisplayMode(FOLLOWUP_DISPLAY_MODE)}>Follow Up</Button>
            <Button positive={displayMode==UPLOAD_DISPLAY_MODE} onClick={()=>this.handleChangeDisplayMode(UPLOAD_DISPLAY_MODE)}>Uploads</Button>
            <Button positive={displayMode==TO_REVIEW_DISPLAY_MODE} onClick={()=>this.handleChangeDisplayMode(TO_REVIEW_DISPLAY_MODE)}>To Review</Button>
          </Button.Group>
        </div>
        {_.map(
          sortedSheetItems,
          (sheetItem) =>
            (!targetSheetItemId || targetSheetItemId === sheetItem.id) && (
              <div key={sheetItem.id}>
                <SheetItemCard
                  sheetItem={sheetItem}
                  stats={statsAndScoreFromId[sheetItem.id].stats}
                  targetSheetItemId={targetSheetItemId}
                  handleLoadProfilesSelection={handleLoadProfilesSelection}
                  handleLoadClientsSelection={handleLoadClientsSelection}
                  handleSelectTargetSheetItem={handleSelectTargetSheetItem}
                  handleRecomputeReverseSearch={handleRecomputeReverseSearch}
                  handleUpdateProfile={handleUpdateProfile}
                  mode={mode}
                  displayMode={displayMode}
                />
              </div>
            ),
        )}
      </div>
    );
  }
}

export default SheetItemsOverview;
