import { Loader } from 'semantic-ui-react';
import React from 'react';
import { Table } from 'semantic-ui-react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';

/*
{
    header : [
      {
        label: ,
        value: 
      }...
    ],
    rows: [ {
      "a": 1,
      "b"
    }]

}
*/


const getDataFromDescriptor = async ({ dataDescriptor }) => {
  const BIG_QUERY_ACTIVITY = "bigquery-activity"
  if (dataDescriptor.type === BIG_QUERY_ACTIVITY) {
    const { data } = await axios.post(`${baseUrl}/bigQueryStats/activityTable`, {
      ...dataDescriptor,
    });
    return data
  }
  throw Error(`Unrecognized data type : ${dataDescriptor.type}, should be in ${BIG_QUERY_ACTIVITY}`)
}

export const CustomTable = ({ data }) => {
  const { header, rows } = data;
  const headerValues = _.pluck(header, "value")
  return (
    <div style={{
      display: 'inline-block',
      width: '100%',
      height: '700px',
      overflowY: 'scroll',
    }}>
      <b style={{fontSize: 10}}>{`Count : ${rows.length}`}</b>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {_.map(header, ({ label, value }) => (
              <Table.HeaderCell key={value}>{label}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(rows, (row) => (
            <Table.Row key={Math.random()}>
              {_.map(headerValues, (value) => (
                <Table.Cell key={Math.random()}>{row[value]} </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>

  )
}


class TableWidget extends React.Component {
  state = {
    loading: true,
    initialDataDescriptor: undefined,
    dataDescriptor: undefined,
    toDisplay: {
      dataDescriptor: undefined,
      results: undefined,
    }
  };

  constructor(props) {
    super(props);
    this.state.initialDataDescriptor = this.props.data;
  }

  handleLoad = async ({ dataDescriptor }) => {

    try {
      const data = await getDataFromDescriptor({ dataDescriptor });

      if (data.error) {
        throw Error(data.error);
      }

      this.setState({
        loading: false,
        toDisplay: {
          dataDescriptor,
          results: data.results,
        }
      });
      console.log(data);
    } catch (e) {
      return alert(e);
    }
    //this.setState(data);
  };


  componentDidMount() {
    if (this.state.initialDataDescriptor) {
      this.handleLoad({ dataDescriptor: this.state.initialDataDescriptor });
    }
  }

  renderChart({ toDisplay }) {

    console.log({ toDisplay });

    if (!toDisplay) {
      return <div />;
    }

    const { results } = toDisplay;
    return (
      <CustomTable data={results} />
    );
  }

  render() {

    if (this.state.loading) {
      return (
        <center>
          <br /><br /><br />
          Loading...{' '}<Loader inline active />
          <br /><br /><br />
        </center>
      );
    }

    return (
      <div>
        <h4>{this.props.name}</h4>
        {this.renderChart({ toDisplay: this.state.toDisplay })}
      </div>
    );
  }
}

export default TableWidget;


