import React from 'react';
import { Icon, Select, Grid, Table, Button, Statistic } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import moment from 'moment';

const capitalizeFirstLetter = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

const Filters = ({
  users,
  selectedAccountStrategist,
  onChangeAccountStrategist,
  selectedCompletionMode,
  onChangeCompletionMode,
  selectedPrio,
  onChangeSelectedPrio
}) => {
  const userOptions = [
    { value: 'none', text: 'All' },
    ..._.map(users, ({ id, name }) => ({ value: id, text: capitalizeFirstLetter(name || '') }))
  ];

  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        <b>Completion: </b>
        <Select
          options={[
            { value: 'hide-completed', text: 'Hide completed' },
            { value: 'show-completed', text: 'Show completed' },
          ]}
          value={selectedCompletionMode || 'hide-completed'}
          onChange={(e, { value }) => {
            onChangeCompletionMode({ completionMode: value })
          }}
        />
      </div>
      <div style={{ display: 'inline-block', marginLeft: 20 }}>
        <b>Owner: </b>
        <Select
          search
          options={userOptions}
          value={selectedAccountStrategist || 'none'}
          onChange={(e, { value }) => {
            onChangeAccountStrategist({ user: value === 'none' ? null : value })
          }}
        />
      </div>
      <div style={{ display: 'inline-block' }}>
        <b>Prio: </b>
        <Select
          options={[
            { value: 'all', text: 'All' },
            { value: 5, text: '5' },
            { value: 4, text: '4' },
            { value: 3, text: '3' },
            { value: 2, text: '2' },
            { value: 1, text: '1' },
            { value: 0, text: '0' },
          ]}
          value={selectedPrio || 'all'}
          onChange={(e, { value }) => {
            onChangeSelectedPrio({ prio: value })
          }}
        />
      </div>
    </div>
  );
}

const prioToColor = {
  5: "red",
  4: "orange",
  3: "yellow",
  2: "green",
  1: "teal",
  0: "blue",
}

const prioToBackgroundColor = {
  5: "#ffcccc",
  4: "#ffe0cc",
  3: "#fffccc",
  2: "#e6ffcc",
  1: "#ccffec",
  0: "#ccf7ff",
}

const ShortlistClientTasksTable = ({ clientTasks, markCompleted }) => {


  const getBackgroundColor = ({ clientTask }) => (
    prioToBackgroundColor[clientTask.prio] || '#c7c9c9'
  )

  const weeksFromNow = (timestamp) => {
    if (!timestamp) {
      return 'never'
    }
    const currentStartOfWeek = moment().startOf('isoWeek');
    const targetStartOfWeek = moment.unix(timestamp / 1000).startOf('isoWeek');
    return `${Math.floor((currentStartOfWeek.diff(targetStartOfWeek, 'days') / 7))} weeks`
  }


  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Mark</Table.HeaderCell>
          <Table.HeaderCell>Client</Table.HeaderCell>
          <Table.HeaderCell>Sales</Table.HeaderCell>
          <Table.HeaderCell>Pendings</Table.HeaderCell>
          <Table.HeaderCell>Last Send Skip Weeks</Table.HeaderCell>
          <Table.HeaderCell>Last Send Weeks</Table.HeaderCell>
          <Table.HeaderCell>Sends Current Week</Table.HeaderCell>
          <Table.HeaderCell>Sends 30 Days</Table.HeaderCell>
          <Table.HeaderCell>Sends Ever</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(clientTasks, (clientTask) => {
          const sortedJobsStats = _.sortBy(clientTask.jobsStats, 'nbPendings').reverse();
          const { lastSendTimestamp, lastSendSkipTimestamp, nbSendsCurrentWeek, nbSendsThirtyDays, nbSendsEver } = clientTask.sweetappActionStats || {};

          return (
            <Table.Row
              key={clientTask.clientId}
              style={{ backgroundColor: getBackgroundColor({ clientTask }) }}
            >
              <Table.Cell>
                <Button style={{ backgroundColor: 'whitesmoke !important' }} onClick={() => markCompleted({ clientTask })}>
                  Mark completed
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Link to={`/sweetapp/shortlists/${clientTask.clientId}`} target='_blank'>
                {clientTask.clientId}
                </Link>
              </Table.Cell>
              <Table.Cell>{clientTask.accountStrategistId}</Table.Cell>
              <Table.Cell>
                {_.map(sortedJobsStats, ({ jobOfferId, nbPendings, nbPendingsTopMatch }) => (
                  <span key={jobOfferId}>
                    {jobOfferId}: {nbPendings} pendings - {nbPendingsTopMatch} top <br />
                  </span>
                ))
                }
              </Table.Cell>
              <Table.Cell>{weeksFromNow(lastSendSkipTimestamp)}</Table.Cell>
              <Table.Cell>{weeksFromNow(lastSendTimestamp)}</Table.Cell>
              <Table.Cell>{nbSendsCurrentWeek || 0}</Table.Cell>
              <Table.Cell>{nbSendsThirtyDays || 0}</Table.Cell>
              <Table.Cell>{nbSendsEver || 0}</Table.Cell>
            </Table.Row>
          )
        })
        }

      </Table.Body>
    </Table>
  );

}

class ShortlistsDashboard extends React.Component {

  state = {
    selectedAccountStrategist: null,
    selectedCompletionMode: null,
    selectedPrio: null
  };

  componentWillMount() {
    this.loadShortlistClientTasks();
    this.loadUsers();
  }

  loadUsers = async () => {
    const { data: users } = await axios.get(`${baseUrl}/users`);
    this.setState({ users });
  }

  loadShortlistClientTasks = async () => {
    try {
      let shortlistClientTasks = ((await axios.get(`${baseUrl}/salesOptimisationApi/shortlists`)).data).results;
      const getValue = ({ prio, sweetappActionStats }) => {
        const timestamp = (sweetappActionStats || {}).lastSendSkipTimestamp || 100000000000;
        return 1000000000 * (prio || 0) - timestamp / 1000000;
      }
      shortlistClientTasks = _.sortBy(
        shortlistClientTasks,
        ({ prio, sweetappActionStats }) => -getValue({ prio, sweetappActionStats })
      )
      this.setState({ shortlistClientTasks });
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  }

  handleChangeSelectedAccountStrategist = ({ user }) => {
    this.setState({
      selectedAccountStrategist: user
    });
  }


  handleChangeCompletionMode = ({ completionMode }) => {
    this.setState({
      selectedCompletionMode: completionMode
    });
  }
  handleChangeSelectedPrio = ({ prio }) => {
    this.setState({
      selectedPrio: prio
    });
  }

  handleMarkCompleted = async ({ clientTask }) => {
    await axios.post(`${baseUrl}/salesOptimisation/action`, {
      type: "mark-shortlist-completed",
      clientTask,
    })
    this.setState({
      shortlistClientTasks: _.map(this.state.shortlistClientTasks, (task) => {
        if (task.clientId == clientTask.clientId) {
          return { ...task, completedReason: 'marked' };
        }
        return task;
      })
    })
  };

  render() {
    const { shortlistClientTasks, selectedAccountStrategist, selectedCompletionMode, selectedPrio, users } = this.state;
    if (!shortlistClientTasks) {
      return <Icon name='circle notched' loading />
    }

    const filteredShortlistClientTasks = _.compact(_.map(shortlistClientTasks, (task) => {
      if (selectedAccountStrategist && task.accountStrategistId !== selectedAccountStrategist) {
        return null;
      }
      if (selectedCompletionMode !== 'show-completed' && task.completedReason) {
        return null;
      }
      if (_.isNumber(selectedPrio) && task.prio !== selectedPrio) {
        return null;
      }
      return task;
    }));

    const prioCount = _.countBy(filteredShortlistClientTasks, 'prio');

    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={10}>
            <Filters
              selectedAccountStrategist={selectedAccountStrategist}
              selectedCompletionMode={selectedCompletionMode}
              selectedPrio={selectedPrio}
              onChangeAccountStrategist={this.handleChangeSelectedAccountStrategist}
              onChangeCompletionMode={this.handleChangeCompletionMode}
              onChangeSelectedPrio={this.handleChangeSelectedPrio}
              users={users}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Statistic.Group size={'mini'}>
              {_.map([5, 4, 3, 2, 1, 0], (prio) => (
                <Statistic color={prioToColor[prio]}>
                  <Statistic.Value>{prioCount[prio] || 0}</Statistic.Value>
                  <Statistic.Label>prio {prio}</Statistic.Label>
                </Statistic>
              ))
              }

            </Statistic.Group>
          </Grid.Column>

        </Grid.Row>
        <Grid.Row>
          <ShortlistClientTasksTable
            clientTasks={filteredShortlistClientTasks}
            markCompleted={this.handleMarkCompleted}
          />
        </Grid.Row>
      </Grid>



    );
  }
}
export default ShortlistsDashboard;
