import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Header, Label, Button, Grid, Icon, Input } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';

import moment from 'moment';

class HubspotLinkEditor extends React.Component {
  state = { editing: false };

  componentDidMount = async () => {
    const { hubspotCompanyId, sources } = this.props;
    this.setState({ editing: !hubspotCompanyId && sources, editable: !!sources });
  };

  onClickEdit = () => {
    const { hubspotCompanyId } = this.props;
    this.setState({
      editing: true,
      editedHubspotCompanyId: hubspotCompanyId
    })
  }

  onChangeEditedHubspotCompanyId = (value) => {
    this.setState({ editedHubspotCompanyId: value })
  }

  onSubmit = async () => {
    try {
      const { hubspotCompanyId, sources } = this.props;
      const { editedHubspotCompanyId } = this.state;
      if (!editedHubspotCompanyId) {
        throw Error('Empty input');
      }
      const payload = {
        hubspotCompanyId,
        sources,
        ...(editedHubspotCompanyId && { newHubspotCompanyId: editedHubspotCompanyId })
      }
      console.log(payload)
      const { success, error } = (await axios.post(`${baseUrl}/salesOptimisationApi/prospects/annotateHubspotLink`, payload)).data;
      if (error) {
        throw Error(error)
      }
      if (this.props.onSubmitNewHubspotCompanyId){
        await this.props.onSubmitNewHubspotCompanyId(editedHubspotCompanyId);
      }
      this.setState({ saved: true });
    } catch (e) {
      alert(e.message)
    }
  }

  render() {
    const { editing, editable, saved, editedHubspotCompanyId } = this.state;
    const { hubspotCompanyId } = this.props;
    if (editing) {
      return (
        <div>
          <Input
            size='mini'
            placeholder="157XXXXXXX"
            value={editedHubspotCompanyId}
            onChange={(e, { value }) => this.onChangeEditedHubspotCompanyId(value)}
          />
          {!saved && <Button size='mini' icon onClick={() => this.onSubmit()}><Icon name="save" /></Button>}
        </div>
      )
    }
    return (
      <div>
        { hubspotCompanyId && <a href={`https://app.hubspot.com/contacts/1768483/company/${hubspotCompanyId}`} target="_blank"><Label basic>{hubspotCompanyId}</Label></a> }
        { editable && <Button size='mini' icon onClick={() => this.onClickEdit()}><Icon name="pencil" /></Button>}
      </div>
    );
  }
};


export default HubspotLinkEditor;
