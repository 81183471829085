import React, { Component } from 'react';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';

import _ from 'underscore';

export const EditTranslations = enhance((props) => (
  <div>
    <label>Default</label>
    <Input field="default" />
    <label>English</label>
    <Input field="en" />
    <label>French</label>
    <Input field="fr" />
  </div>
));

export const hasEmptyPattern = ({ patterns }) => {
  let hasEmpty = false;
  _.each(patterns, (pattern) => {
    if (!pattern || !pattern.trim()) {
      hasEmpty = true;
    }
  });
  return hasEmpty;
};

export const ensureDefaultTranslation = ({ translations }) => {
  if (_.isEmpty(translations)) {
    return translations;
  }
  if (translations.default) {
    return translations;
  }
  if (translations.en) {
    return { ...translations, default: translations.en };
  }
  if (translations.fr) {
    return { ...translations, default: translations.fr };
  }
  alert('Could not ensure default translation, setting empty object');
  return {};
};

class EditJobModal extends Component {
  handleChange(params) {
    console.log({
      params,
    });
    this.setState({
      params,
    });
  }

  render() {
    const {
      onSubmit,
      onCancel,
      jobs,
      job,
      collectionName,
      tagIds,
      mainJobsCategoryIds,
      level2CategoryIds,
    } = this.props;
    const jobGroups = _.filter(jobs, (jobItem) => !_.isUndefined(jobItem.group_configuration));

    const jobOptions = _.map(jobs, (jobItem) => ({
      value: jobItem.id,
      label: jobItem.id,
    }));

    const tagOptions = _.map(tagIds, (tagId) => ({
      value: tagId,
      label: tagId,
    }));

    const allCategoryIds = (mainJobsCategoryIds || []).concat(level2CategoryIds || []);
    const jobGroupsWithoutCategories = _.filter(jobGroups, (jobGroup) => !_.contains(allCategoryIds, jobGroup.id));

    const jobGroupOptions = _.map(jobGroupsWithoutCategories, (jobGroup) => ({
      value: jobGroup.id,
      label: jobGroup.id,
    }));

    const jobCategoryOptions = _.map(level2CategoryIds, (level2CategoryId) => ({
      value: level2CategoryId,
      label: level2CategoryId,
    }));

    const strengthOptions = [
      { value: 'strong', label: 'Strong' },
      { value: 'medium', label: 'Medium' },
      { value: 'weak', label: 'Weak' },
    ];
    const typeOptions = [
      { value: 'job', label: 'Job' },
      { value: 'job-modality', label: 'Job Modality' },
      { value: 'job-responsibility', label: 'Job Responsibility' },
      { value: 'job-seniority', label: 'Job Seniority' },
      { value: 'education-field', label: 'Education Field' },
      { value: 'skill', label: 'Skill' },
      { value: 'industry', label: 'Industry' },
      { value: 'interest', label: 'Interest' },
      { value: 'study-level', label: 'Study Level' },
      { value: 'hierarchical-rank', label: 'Hierarchical Rank' },
    ];
    const groupIdToDiffusionToGroup = {};
    const groupIdToDiffusionToElement = {};
    _.each(jobGroups, (jobGroup) => {
      if ((jobGroup.group_configuration || {}).default_diffusion_to_group) {
        groupIdToDiffusionToGroup[jobGroup.id] = jobGroup.group_configuration.default_diffusion_to_group;
      }
      if ((jobGroup.group_configuration || {}).default_diffusion_to_element) {
        groupIdToDiffusionToElement[jobGroup.id] = jobGroup.group_configuration.default_diffusion_to_element;
      }
    });

    const SelectGroup = enhance((props) => (
      <Grid columns={4}>
        <Grid.Column>
          <label>Group Id</label>
          <Select field='groupId' options={jobGroupOptions} />
        </Grid.Column>
        <Grid.Column>
          <label>Diffusion to group</label>
          <Select
            field='diffusionToGroup'
            options={strengthOptions}
            placeholder={
              'default ' +
              (_.isNull(this.state)
                ? groupIdToDiffusionToGroup[(props.placeholders[props.field] || {}).groupId] || 'None'
                : _.isUndefined(this.state.params)
                ? groupIdToDiffusionToGroup[(props.placeholders[props.field] || {}).groupId] || 'None'
                : _.isUndefined(this.state.params.groups)
                ? 'None'
                : _.isUndefined(this.state.params.groups[props.field])
                ? 'None'
                : _.isUndefined(groupIdToDiffusionToGroup[this.state.params.groups[props.field].groupId])
                ? 'None'
                : groupIdToDiffusionToGroup[this.state.params.groups[props.field].groupId])
            }
          />
        </Grid.Column>
        <Grid.Column>
          <label>Diffusion to element</label>
          <Select
            field='diffusionToElement'
            options={strengthOptions}
            placeholder={
              'default ' +
              (_.isNull(this.state)
                ? groupIdToDiffusionToElement[(props.placeholders[props.field] || {}).groupId] || 'None'
                : _.isUndefined(this.state.params)
                ? groupIdToDiffusionToElement[(props.placeholders[props.field] || {}).groupId] || 'None'
                : _.isUndefined(this.state.params.groups)
                ? 'None'
                : _.isUndefined(this.state.params.groups[props.field])
                ? 'None'
                : _.isUndefined(groupIdToDiffusionToElement[this.state.params.groups[props.field].groupId])
                ? 'None'
                : groupIdToDiffusionToElement[this.state.params.groups[props.field].groupId])
            }
          />
        </Grid.Column>
      </Grid>
    ));

    const SelectJobCategory = enhance((props) => (
      <Grid>
        <Grid.Column>
          <label>Job Category Id</label>
          <Select field='groupId' options={jobCategoryOptions} />
          <br />
          <Checkbox slider defaultValue={false} field='isMinorCategory' label='is Minor Category' />
        </Grid.Column>
      </Grid>
    ));

    const fullJobsInGroup = job
      ? _.filter(jobs, (jobItem) => (jobItem.groups || []).some((item) => item.id === job.id))
      : [];

    const initialValues = job
      ? {
          id: job.id,
          name: job.name,
          types: (job.types || []).join(';'),
          description: job.description || '',
          strongDependencies: _.map(
            _.filter(job.strong_dependencies_origin || [], (dep) => dep.origin === 'direct-diffusion') || [],
            (dep) => dep.id,
          ).join(';'),
          mediumDependencies: _.map(
            _.filter(job.medium_dependencies_origin || [], (dep) => dep.origin === 'direct-diffusion') || [],
            (dep) => dep.id,
          ).join(';'),
          weakDependencies: _.map(
            _.filter(job.weak_dependencies_origin || [], (dep) => dep.origin === 'direct-diffusion') || [],
            (dep) => dep.id,
          ).join(';'),
          inRevealColumn: !_.isUndefined(job.inRevealColumn) ? job.inRevealColumn : true,
          translations: job.translations || {},
          patterns: _.map(job.patterns, (jobPatterns) => jobPatterns.join(';')),
          isGroup: job ? !_.isUndefined(job.group_configuration) : false,
          groups: _.map(
            _.filter(job.groups, (groupItem) => !_.contains(allCategoryIds, groupItem.id)) || [],
            (group) => {
              const formattedGroup = {
                groupId: group.id,
              };
              if (group.overwritten_diffusion_to_group) {
                formattedGroup.diffusionToGroup = group.overwritten_diffusion_to_group;
              }
              if (group.overwritten_diffusion_to_element) {
                formattedGroup.diffusionToElement = group.overwritten_diffusion_to_element;
              }
              return formattedGroup;
            },
          ),
          jobCategories: _.map(
            _.filter(job.groups, (groupItem) => _.contains(allCategoryIds, groupItem.id)) || [],
            (group) => {
              const formattedGroup = {
                groupId: group.id,
              };
              if (group.type == 'minor') {
                formattedGroup.isMinorCategory = true;
              }
              return formattedGroup;
            },
          ),
          defaultDiffusionToGroup: (job.group_configuration || {}).default_diffusion_to_group,
          defaultDiffusionToElement: (job.group_configuration || {}).default_diffusion_to_element,
          jobsInGroup: _.map(fullJobsInGroup, (jobItem) => jobItem.id).join(';'),
        }
      : { types: 'job', inRevealColumn: false };

    const currentIsGroup = !_.isUndefined(((this.state || {}).params || {}).isGroup)
      ? ((this.state || {}).params || {}).isGroup
      : initialValues.isGroup;

    return (
      <Modal closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)} initialValues={initialValues}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Form.Field>
                    <label>Name</label>
                    <Input autoFocus field='name' />
                  </Form.Field>
                  <Form.Field>
                    <label>Identifier</label>
                    <Input autoFocus field='id' />
                  </Form.Field>
                  <Form.Field>
                    <label>Description</label>
                    <Input autoFocus field='description' />
                  </Form.Field>
                  <Form.Field>
                    <label>Types</label>
                    <Select multi search field='types' options={typeOptions} />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      <b>Translations</b>
                    </label>
                    <EditTranslations field='translations' />
                  </Form.Field>
                  <br />
                  In Reveal Column : <Checkbox toggle field='inRevealColumn' />
                  <br />
                  <br />
                  <Form.Field>
                    <label>Patterns</label>
                    <List field='patterns' component={Input} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={10}>
                  <h3>Direct dependencies</h3>
                  <Form.Field>
                    <label>Strong dependencies</label>
                    <Select multi search field='strongDependencies' options={tagOptions} />
                  </Form.Field>
                  <Form.Field>
                    <label>Medium dependencies</label>
                    <Select multi search field='mediumDependencies' options={tagOptions} />
                  </Form.Field>
                  <Form.Field>
                    <label>Weak dependencies</label>
                    <Select multi search field='weakDependencies' options={tagOptions} />
                  </Form.Field>
                  <br />
                  <h3>Groups</h3>
                  <Form.Field>
                    <List component={SelectGroup} field='groups' placeholders={(initialValues || {}).groups || []} />
                  </Form.Field>
                  <br />
                  <h3>Job Category Level 2</h3>
                  <Form.Field>
                    <List
                      component={SelectJobCategory}
                      field='jobCategories'
                      placeholders={(initialValues || {}).groups || []}
                    />
                  </Form.Field>
                  <br />
                  Is group : <Checkbox toggle field='isGroup' />
                  {currentIsGroup ? (
                    <div>
                      <Form.Field>
                        <label>Jobs in group</label>
                        <Select multi search field='jobsInGroup' options={jobOptions} />
                      </Form.Field>
                      <Form.Field>
                        <label>Default diffusion to group</label>
                        <Select search field='defaultDiffusionToGroup' options={strengthOptions} />
                      </Form.Field>
                      <Form.Field>
                        <label>Default diffusion to element</label>
                        <Select search field='defaultDiffusionToElement' options={strengthOptions} />
                      </Form.Field>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() =>
              onSubmit(
                (this.state || {}).params,
                jobs,
                groupIdToDiffusionToGroup,
                groupIdToDiffusionToElement,
                _.map(fullJobsInGroup, (fullJob) => ({
                  id: fullJob.id,
                  groups: _.filter(fullJob.groups, (group) => group.id === job.id),
                })),
                collectionName,
              )
            }
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditJobModal;
