import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import baseUrl from '../../../baseUrl.js';
import { SweetForm, Select } from 'sweetform';
import { Grid, Button, Icon, Label, Modal as SModal } from 'semantic-ui-react';
import Modal from '../../../Modal';
import { DetailedOffer } from './MatchMiniCard';
import ClientProfile from '../../../components/ClientProfile';
import OfferDetails from '../../../Matching/OfferDetails.js';
import WatchHiresweetData from '../../../Watch/Candidate/WatchHiresweetData';
import { MATCHING_LEVELS } from '../../../Watch/Candidate/utils';

const getClientOfferIds = async (clientId) => {
  const clientOffers = (await axios.get(`${baseUrl}/station/clients/${clientId}/offers`)).data;
  console.log('clientOffers', clientOffers);
  return _.pluck(clientOffers, 'id');
};

const getSweethubOffers = async (clientId) => {
  try {
    const { data: sweethubOffers } = await axios.get(`${baseUrl}/hiresweetwatch/offers/${clientId}/sweethubOffers`);
    return sweethubOffers;
  } catch (error) {
    alert(error + `\non client ${clientId}`);
    return [];
  }
};

class UploadActions extends React.PureComponent {
  state = {
    modalOpen: false,
    previewModalOpen: false,
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };
  handleClosePreviewModal = () => {
    this.setState({ previewModalOpen: false });
  };
  handleOpenPreviewModal = () => {
    this.setState({ previewModalOpen: true });
  };
  uploadToBestOffer = (matchingLevel) => {
    const { handleUploadProfile, match } = this.props;
    const { jobOfferId, id } = ((match || {}).reason || {}).bestOffer || {};
    if (!jobOfferId) {
      return alert('No best offer found.');
    }
    handleUploadProfile({
      match,
      jobOfferId,
      sweethubOfferId: id,
      matchingLevel,
    });
  };
  closePreviewModalAndUploadToBestOffer = (matchingLevel) => {
    this.handleClosePreviewModal();
    this.uploadToBestOffer(matchingLevel);
  };
  closeModalAndUploadToOffer = ({ jobOfferId, sweethubOfferId, noEnrichments, matchingLevel }) => {
    const { match, handleUploadProfile } = this.props;
    if (!jobOfferId) {
      return alert('No selected offer.');
    }
    this.handleCloseModal();
    handleUploadProfile({
      match,
      jobOfferId,
      sweethubOfferId,
      noEnrichments,
      matchingLevel,
    });
  };
  render() {
    const { modalOpen, previewModalOpen } = this.state;
    const { match } = this.props;
    if (match.state !== 'pool' || match.profileId) {
      return <div> Profile already uploaded </div>;
    }
    const bestJobOfferId = (((match || {}).reason || {}).bestOffer || {}).jobOfferId;
    const bestOfferId = (((match || {}).reason || {}).bestOffer || {}).id;
    const { sheetItemId, clientId, sheetItem } = match;
    return (
      <center>
        <Grid columns={3}>
          <Grid.Column width={7}>
            <Button color='blue' onClick={() => this.handleOpenModal()}>
              Push to other offer
            </Button>
          </Grid.Column>
          {bestJobOfferId && (
            <Grid.Column width={7}>
              <Button.Group>
                <Button color='blue' style={{ cursor: 'auto', 'pointer-events': 'none' }}>
                  Push to best
                </Button>
                {_.map(MATCHING_LEVELS, ({ value, color, number }) => (
                  <Button
                    onClick={() => {
                      this.uploadToBestOffer(value);
                    }}
                    color={color}
                    basic
                  >
                    {number}
                  </Button>
                ))}
              </Button.Group>
            </Grid.Column>
          )}
          {bestJobOfferId && (
            <Grid.Column width={2}>
              <Icon
                name='eye'
                link
                onClick={() => {
                  this.handleOpenPreviewModal();
                }}
              />
            </Grid.Column>
          )}
        </Grid>

        {/*
        <Button color='blue' onClick={async ()=> await this.closeModalAndUploadToWatchOffer()}>Push to watch offer</Button>
        */}
        {modalOpen && (
          <Modal
            active={true}
            headerText={`Upload ${sheetItemId} to ${clientId}`}
            cancelText=''
            onCancel={this.handleCloseModal}
          >
            <div style={{ height: '150px' }}>
              <UploadClientOffer
                key={sheetItemId + clientId}
                match={match}
                uploadToOffer={this.closeModalAndUploadToOffer}
              />
            </div>
          </Modal>
        )}
        {previewModalOpen && bestOfferId && (
          <SModal open={true} onClose={this.handleClosePreviewModal} size='fullscreen'>
            <SModal.Header>{`Preview upload ${sheetItemId} to ${bestOfferId}`}</SModal.Header>
            <SModal.Content>
              <PreviewSweetappProfile sheetItemId={sheetItemId} offerId={bestOfferId} sheetItem={sheetItem} />
            </SModal.Content>
            <SModal.Actions>
              <Button onClick={() => this.handleClosePreviewModal()} color='red'>
                Cancel
              </Button>
              <Button color='blue' style={{ cursor: 'auto', 'pointer-events': 'none' }}>
                Upload
              </Button>
              {_.map(MATCHING_LEVELS, ({ value, color, number }) => (
                <Button
                  onClick={() => {
                    this.closePreviewModalAndUploadToBestOffer(value);
                  }}
                  basic
                  color={color}
                >
                  {number}
                </Button>
              ))}
            </SModal.Actions>
          </SModal>
        )}
      </center>
    );
  }
}

class UploadClientOffer extends React.PureComponent {
  state = { modalOpen: false };
  componentWillMount() {
    this.handleLoadOffers();
  }

  handleChange = ({ selectedJobOfferId, noEnrichments }) => {
    this.setState({ selectedJobOfferId, noEnrichments });
  };
  handleLoadOffers = async () => {
    const { match } = this.props;
    const { clientId } = match || {};
    if (!clientId) {
      return alert('No client id in match.');
    }
    const jobOfferIds = await getClientOfferIds(clientId);
    console.log('jobOfferIds', jobOfferIds);
    const sweethubOffers = await getSweethubOffers(clientId);
    console.log('sweethubOffers', sweethubOffers);
    let jobOfferIdToOffer = _.indexBy(sweethubOffers, (offer) => offer.platformId);
    const bestOffer = (match.reason || {}).bestOffer;
    if (bestOffer) {
      jobOfferIdToOffer[bestOffer.jobOfferId] = bestOffer;
    }

    // add scoring
    let sortedJobOfferIds = jobOfferIds;
    if (match.reason) {
      let jobOfferIdToScore = _.mapObject(
        _.indexBy(match.reason.otherOffers || [], ({ jobOfferId }) => jobOfferId),
        ({ score }) => score,
      );
      if (match.reason.bestOffer && match.reason.bestOffer.platformId && match.reason.score) {
        jobOfferIdToScore[match.reason.bestOffer.platformId] = match.reason.score;
      }
      jobOfferIdToOffer = _.mapObject(jobOfferIdToOffer, (offer, jobOfferId) => ({
        ...offer,
        ...(jobOfferId && jobOfferIdToScore[jobOfferId] && { score: jobOfferIdToScore[jobOfferId] }),
      }));

      sortedJobOfferIds = _.sortBy(jobOfferIds, (jobOfferId) => {
        const score = (jobOfferIdToOffer[jobOfferId] && -jobOfferIdToOffer[jobOfferId].score) || 0;
        return score;
      });
    }

    this.setState({
      sortedJobOfferIds,
      jobOfferIdToOffer,
      ...(bestOffer && {
        bestOfferId: bestOffer.jobOfferId,
        selectedJobOfferId: bestOffer.jobOfferId,
      }),
    });
  };
  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  render() {
    const { uploadToOffer, match } = this.props;
    const {
      sortedJobOfferIds,
      jobOfferIdToOffer,
      selectedJobOfferId,
      noEnrichments,
      bestOfferId,
      modalOpen,
    } = this.state;
    if (!sortedJobOfferIds) {
      return <div />;
    }
    const { sheetItem } = match || {};
    const selectedOfferDetailed = jobOfferIdToOffer && selectedJobOfferId && jobOfferIdToOffer[selectedJobOfferId];
    console.log('selectedOfferDetailed', selectedOfferDetailed);
    return (
      <div>
        <SweetForm
          initialValues={{ selectedJobOfferId: bestOfferId, noEnrichments: false }}
          onChange={this.handleChange}
        >
          <Grid>
            <Grid.Column width={6}>
              <Select
                field='selectedJobOfferId'
                placeHolder='offer'
                options={_.map(sortedJobOfferIds, (jobOfferId) => ({ label: jobOfferId, value: jobOfferId }))}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              {selectedJobOfferId && !selectedOfferDetailed && (
                <div>
                  Did not find a matching sweethub offer. Profile cannot be enriched. Upload without enrichments ?
                  <Select
                    field='noEnrichments'
                    options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                  />
                </div>
              )}
              {selectedJobOfferId && selectedOfferDetailed && (
                <div>
                  <DetailedOffer offer={selectedOfferDetailed} />
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={5}>
              {selectedJobOfferId && selectedOfferDetailed && (
                <div>
                  <Button onClick={() => this.handleOpenModal()} icon labelPosition='left'>
                    <Icon name='eye' />
                    Preview
                  </Button>
                </div>
              )}
            </Grid.Column>
          </Grid>
          <Button.Group>
            <Button color='blue' style={{ cursor: 'auto', 'pointer-events': 'none' }}>
              Upload
            </Button>
            {_.map(MATCHING_LEVELS, ({ value, color, number }) => (
              <Button
                onClick={() => {
                  uploadToOffer({
                    jobOfferId: selectedJobOfferId,
                    ...(selectedOfferDetailed && { sweethubOfferId: selectedOfferDetailed.id }),
                    noEnrichments,
                    matchingLevel: value,
                  });
                }}
                basic
                color={color}
                disabled={_.isEmpty(selectedJobOfferId)}
              >
                {number}
              </Button>
            ))}
          </Button.Group>
        </SweetForm>
        {modalOpen && (
          <Modal active={true} cancelText='' onCancel={this.handleCloseModal} size={'fullscreen'}>
            <PreviewSweetappProfile
              sheetItemId={match.sheetItemId}
              offerId={selectedOfferDetailed.id}
              sheetItem={sheetItem}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const getSweetappProfileData = async ({ sheetItemId, offerId }) => {
  const { data } = await axios.get(`${baseUrl}/candidates/${sheetItemId}/enrichedProfileForOffer/${offerId}/`);
  return data;
};
const getOfferData = async ({ offerId }) => {
  const { data } = await axios.get(`${baseUrl}/offers/${offerId}`);
  return data;
};

class PreviewSweetappProfile extends React.PureComponent {
  state = {};
  componentWillMount() {
    this.handleLoadSweetappProfileData();
    this.handleLoadOffer();
  }
  handleLoadSweetappProfileData = async () => {
    const { sheetItemId, offerId } = this.props;
    try {
      const sweetappProfileData = await getSweetappProfileData({ sheetItemId, offerId });
      if (!sweetappProfileData || sweetappProfileData.error) {
        throw Error('Error during enrichment \n' + (sweetappProfileData || {}).error);
      }
      console.log('sweetappProfileData', sweetappProfileData);
      this.setState({ sweetappProfileData });
    } catch (e) {
      alert(e);
    }
  };
  handleLoadOffer = async () => {
    const { offerId } = this.props;
    try {
      const offerData = await getOfferData({ offerId });
      this.setState({ offerData });
    } catch (e) {
      alert(e);
    }
  };

  render() {
    const { sweetappProfileData, offerData } = this.state;
    const { sheetItem } = this.props;
    const hiresweetData = (((sheetItem || {}).data || {}).sourceData || {}).hiresweet;
    if (!sweetappProfileData) {
      return <div>Loading</div>;
    }
    return (
      <Grid>
        <Grid.Column width={8} style={{ overflowY: 'auto', overflowX: 'auto', height: '85vh' }}>
          <ClientProfile profile={{ data: sweetappProfileData }} />
        </Grid.Column>
        <Grid.Column width={8} style={{ overflowY: 'auto', overflowX: 'auto', height: '85vh' }}>
          {offerData && (
            <Grid.Row>
              <OfferDetails offer={offerData} />
            </Grid.Row>
          )}
          {hiresweetData && (
            <Grid.Row>
              <WatchHiresweetData hiresweetData={hiresweetData} noEdit={true} />
            </Grid.Row>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default UploadActions;
