import React from 'react';
import _ from 'underscore';
import LoadingComponent from './LoadingComponent';
import { Grid, List, Image } from 'semantic-ui-react';
import { isFemaleName } from '../firstnames';
import moment from 'moment';


const listTypes = [
  'linkedin',
  'viadeo',
  'github',
  'stackoverflow',
  'coderWall',
  'codePen',
  'bitBucket',
  'reddit',
  'twitter',
  'angelList',
  'meetup',
  'googlePlus',
  'dribbble',
  'behance',
  'pinterest',
  'drupal',
  'joomla',
  'aboutMe',
  'medium',
  'quora',
  'speakerDeck',
  'githubIo',
  'website',
];

const cleanLink = (url) => (url.match(/^https?:\/\//i) ? url : `http://${url}`);

const getContactLinkBlock = ({ sourceId, url, t }) => {
  if (sourceId === 'remixJobs' || sourceId === 'doYouBuzz') {
    return sourceId;
  }
  if (sourceId === 'website' || sourceId === 'githubIo' || sourceId === 'aboutMe') {
    return (
      <div className="contact-link-inner">
        <span>{t('profile.resume.website')}</span>
      </div>
    );
  }
  if (sourceId !== 'githubIo') {
    return (
      <div className="contact-link-inner">
        <img src={`/images/contact_logos/logolink_${sourceId}.svg`} alt={url} />
      </div>
    );
  }

  return '';
};

const ContactLink = ({ sourceId, url, profileId, t }) => (
  <List.Item className="contact-link">
    <a href={cleanLink(url)} className="source-link" target="_blank" rel="noopener noreferrer">
      {getContactLinkBlock({ sourceId, url, t })}
    </a>
  </List.Item>
);

const replacePhotoLink = (link) => (link || '').replace('shrinknp_400_400', 'shrinknp_75_75');

class ProfileHeader extends React.PureComponent {
  render() {
    const { profileId, t } = this.props;
    const resumeData = this.props.resumeData || {};
    const { sources, mainEducation } = resumeData;
    // const currentMonth = (new Date()).getMonth();
    const defaultEducationMonth = 6;
    const endDate = ((mainEducation || {}).endDate || {}).year
      ? moment([
          mainEducation.endDate.year,
          _.isNumber(mainEducation.endDate.month) ? mainEducation.endDate.month : defaultEducationMonth,
          1,
        ])
      : null;
    const studiedAt =
      resumeData.firstname && isFemaleName(resumeData.firstname)
        ? t('profile.resume.femaleStudiedAt')
        : t('profile.resume.studiedAt');
    const mainEducationText =
      mainEducation && mainEducation.schoolName
        ? `${studiedAt} ${mainEducation.schoolName} ${endDate ? `(${endDate.fromNow()})` : ''}`
        : '';
    const avatarImageUrl = resumeData.photoLink ? replacePhotoLink(resumeData.photoLink) : '/images/default-avatar-1.svg';

    const headline = _.isObject(resumeData.headline) ? (resumeData.headline.content || {}).text : resumeData.headline;
    const location = _.isObject(resumeData.location) ? (resumeData.location.name || {}).default : resumeData.location;

    return (
      <div>
        <Grid className="profile-header">
          <Grid.Row>
            <Grid.Column textAlign="right" style={{ paddingRight: 0 }}>
              <List horizontal className="contacts">
                {_.map(listTypes, (sourceId) =>
                  sources && sources[sourceId] ? (
                    <ContactLink
                      t={t}
                      profileId={profileId}
                      key={sourceId}
                      sourceId={sourceId}
                      url={sources[sourceId]}
                    />
                  ) : null,
                )}
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className="avatar-and-infos">
                <div className="avatar">
                  <Image
                    className="card-company-image"
                    src={avatarImageUrl}
                    circular
                    onError={(e) => {
                      e.target.src = '/images/default-avatar-1.svg';
                    }}
                  />
                </div>

                <div className="infos">
                  <LoadingComponent as="div" loading={_.isEmpty(resumeData)} length={30} margin={0.6}>
                    <h1 className="fullname">
                      {resumeData.firstname} {resumeData.lastname}
                    </h1>
                  </LoadingComponent>
                  <LoadingComponent as="div" loading={_.isEmpty(resumeData)} length={50} margin={0.6}>
                    <h2 className="headline">
                      <span className="headline">{headline}</span>
                      {headline && location ? <span> • </span> : null}
                      <span className="location">{location}</span>
                    </h2>
                  </LoadingComponent>
                  <LoadingComponent as="div" loading={_.isEmpty(resumeData)} length={50} margin={0.6}>
                    <h3 className="main-education">{mainEducationText}</h3>
                  </LoadingComponent>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}


export default ProfileHeader;
