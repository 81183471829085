import _ from 'underscore';
import axios from 'axios';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid, Loader, Popup } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import LinkedinEditorModal from './LinkedinEditorModal';
import GithubEditorModal from './GithubEditorModal';
import HiresweetEditorModal from './HiresweetEditorModal';
import ImportOfferModal from './ImportOfferModal';
import ImportCandidateModal, { sourceProfilesToSandboxSourceProfiles } from './ImportCandidateModal';
import ScoringDetails from './ScoringDetails';
import EditOfferModal from './EditOfferModal';
import EditCriterionModal from './EditCriterionModal';
import ProfileView from '../SweetComponents/Profile/View';
import Offer from '../components/Offer';
import exampleOffer from './exampleOffer';
import { SweetForm } from 'sweetform';
import { Input as SInput, Button, Icon } from 'semantic-ui-react';
import EnrichedProfileView from '../LabelizedData/DataPointView/EnrichedProfileView.js';
import ShapImportances from './ShapImportances.js';

const PROFILE_OFFER_DATA_POINT_TYPE = 'profile-offer';
const PROFILE_CRITERION_DATA_POINT_TYPE = 'profile-criterion';
const SCORER_IDS = ["S12Model_wrapped", "WS3Model", "WS5Model", "WS4Model", "WS6Model", "WS6SRModel", "WS7Model"]
class DataPointSandbox extends Component {
  state = {
    edition: null,
    scorerId: "S12Model_wrapped",
    displayScoringDetails: false,
    scoringUrl: null,
    dataPointType: PROFILE_OFFER_DATA_POINT_TYPE,
    dataPoint: {
      sourceProfiles: {
        linkedin: null,
        github: null,
        hiresweet: null
      },
      enrichedProfile: {},
      scoreWithDetails: null,
      offer: exampleOffer,
      criterion: null,
    },
  };

  getLinkedinProfileFromId = async ({ id }) => {
    const url = baseUrl + '/sourceData/linkedin/' + id;
    const { data } = await axios.get(url);
    return (data || {}).data || null;
  };

  getGithubProfileFromLogin = async ({ login }) => {
    const url = baseUrl + '/sourceData/github/' + login.toLowerCase();
    const { data } = await axios.get(url);
    return data || null;
  };
  getSourceProfilesFromHiresweetId = async ({ id }) => {
    const { data } = await axios.get(`${baseUrl}/candidates/${id}/sourceData`);
    return _.isEmpty(data) ? {} : sourceProfilesToSandboxSourceProfiles({ sourceProfiles: data.sourceProfiles })
  };

  getSourceProfilesFromProfileCollection = async ({ profileId, profileCollectionId, date }) => {
    let url = `${baseUrl}/sweetchain/getProfileSourceData/${profileCollectionId}/${profileId}`
    if (date) {
      url += `?date=${date}`;
    }
    const { data } = await axios.get(url);
    console.log("getSourceProfilesFromProfileCollection")
    console.log(data.cloudStorageSource)
    console.log(data)
    return data;
  };

  getOfferFromId = async ({ id }) => {
    const { data } = await axios.get(`${baseUrl}/offers/${id}`);
    return data;
  };

  getOfferFromJobCollection = async ({ jobId, jobCollectionId, date }) => {
    let url = `${baseUrl}/sweetchain/getJobSourceData/${jobCollectionId}/${jobId}`;
    if (date) {
      url += `?date=${date}`;
    }
    const { data } = await axios.get(url);
    console.log("getOfferFromJobCollection")
    console.log(data.cloudStorageSource)
    console.log(data)
    return data;
  };

  componentDidMount = async () => {
    const {
      initialOfferId,
      initialLinkedinId,
      initialGithubLogin,
      initialDataPointsSetId,
      initialDataPointId,
      initialHiresweetId,
      initialProfileCollection,
      initialProfile,
      initialProfileDate,
      initialJobCollection,
      initialJob,
      initialJobDate,
    } = this.props;

    const getInitialData = async () => {
      let initialOffer, initialLinkedin, initialGithub, initialHiresweet, initialCriterion, profileSource, offerSource;

      if (initialDataPointsSetId && initialDataPointId) {
        const { data } = await axios(
          `${baseUrl}/dataPointsSets/${initialDataPointsSetId}/dataPoint/${initialDataPointId}`,
        );
        const { offer, criterion, sourceProfiles } = data;
        initialOffer = offer;
        initialCriterion = criterion;
        initialLinkedin = ((sourceProfiles || {}).linkedinProfile || {}).data || {};
        initialGithub = ((sourceProfiles || {}).githubProfile || {}).data || {};
        initialHiresweet = (sourceProfiles || {}).hiresweetProfile || {};
      }

      if (initialProfileCollection && initialProfile) {
        const sourceProfiles = await this.getSourceProfilesFromProfileCollection({
          profileId: initialProfile,
          profileCollectionId: initialProfileCollection,
          date: initialProfileDate,
        });
        if (_.isEmpty(sourceProfiles)) {
          throw Error(`no profile found for id ${initialProfile} in ${initialProfileCollection}`);
        }
        if (!_.isEmpty(sourceProfiles.hiresweet)) {
          initialHiresweet = sourceProfiles.hiresweet
        }
        if (!_.isEmpty((sourceProfiles.linkedin || {}).data)) {
          initialLinkedin = sourceProfiles.linkedin.data;
        }
        if (!_.isEmpty(sourceProfiles.github)) {
          initialGithub = sourceProfiles.github
        }
        profileSource = { type: "cloud_storage", source: sourceProfiles.cloudStorageSource };
      }

      if (initialJobCollection && initialJob) {
        const offer = await this.getOfferFromJobCollection({
          jobId: initialJob,
          jobCollectionId: initialJobCollection,
          date: initialJobDate,
        });
        if (_.isEmpty(offer)) {
          throw Error(`no job found for id ${initialJob} in ${initialJobCollection}`);
        }
        initialOffer = offer;
        offerSource = { type: "cloud_storage", source: offer.cloudStorageSource };
      }

      if (initialOfferId) {
        const offer = await this.getOfferFromId({ id: initialOfferId });
        if (!offer) {
          throw Error('no offer found for id ' + initialOfferId);
        }
        initialOffer = offer;
      }

      if (initialLinkedinId) {
        const linkedinProfile = await this.getLinkedinProfileFromId({ id: initialLinkedinId });
        if (!linkedinProfile) {
          throw Error('no linkedin found for id ' + initialLinkedinId);
        }
        initialLinkedin = linkedinProfile;
      }

      if (initialGithubLogin) {
        const githubProfile = await this.getGithubProfileFromLogin({ login: initialGithubLogin });
        if (!githubProfile) {
          throw Error('no github found for login ' + initialGithubLogin);
        }
        initialGithub = githubProfile;
      }

      if (initialHiresweetId) {
        const sourceProfiles = await this.getSourceProfilesFromHiresweetId({ id: initialHiresweetId });
        if (_.isEmpty(sourceProfiles)) {
          throw Error('no candidate found for id ' + initialHiresweetId);
        }
        if (!_.isEmpty(sourceProfiles.hiresweet)) {
          initialHiresweet = {
            id: initialHiresweetId,
            ...sourceProfiles.hiresweet
          }
        }
        if (!_.isEmpty(sourceProfiles.linkedin)) {
          initialLinkedin = sourceProfiles.linkedin
        }
        if (!_.isEmpty(sourceProfiles.github)) {
          initialGithub = sourceProfiles.github
        }
      }

      const initialDataPointType = initialCriterion ? PROFILE_CRITERION_DATA_POINT_TYPE : PROFILE_OFFER_DATA_POINT_TYPE;

      return { initialOffer, initialLinkedin, initialGithub, initialHiresweet, initialDataPointType, initialCriterion, profileSource, offerSource };
    };

    try {
      const {
        initialOffer,
        initialLinkedin,
        initialGithub,
        initialHiresweet,
        initialCriterion,
        initialDataPointType,
        profileSource,
        offerSource,
      } = await getInitialData();
      if (profileSource) {
        this.setState({ profileSource });
      }
      if (offerSource) {
        this.setState({ offerSource });
      }
      if (initialOffer) {
        await new Promise((resolve) => {
          this.setState(
            {
              dataPointType: initialDataPointType,
              dataPoint: {
                ...this.state.dataPoint,
                offer: initialOffer,
                criterion: initialCriterion,
                enrichedProfile: {},
                scoreWithDetails: null,
              },
            },
            () => {
              resolve();
            },
          );
        });
      }

      if (initialGithub) {
        await new Promise((resolve) => {
          this.setState(
            {
              dataPoint: {
                ...this.state.dataPoint,
                sourceProfiles: {
                  ...this.state.dataPoint.sourceProfiles,
                  github: initialGithub,
                },
                enrichedProfile: {},
                scoreWithDetails: null,
              },
            },
            () => {
              resolve();
            },
          );
        });
      }

      if (initialHiresweet) {
        await new Promise((resolve) => {
          this.setState(
            {
              dataPoint: {
                ...this.state.dataPoint,
                sourceProfiles: {
                  ...this.state.dataPoint.sourceProfiles,
                  hiresweet: initialHiresweet,
                },
                enrichedProfile: {},
                scoreWithDetails: null,
              },
              scorerId: "WS3Model"
            },
            () => {
              resolve();
            },
          );
        });
      }

      if (initialLinkedin) {
        await this.handleSaveLinkedinProfile({ data: initialLinkedin });
      }
    } catch (e) {
      return alert(e.message);
    }
  };

  getEnrichedProfile = async ({ sourceProfiles, offer }) => {
    const url = baseUrl + '/sweetwork2/item';
    const linkedinData = sourceProfiles.linkedin;
    const githubData = sourceProfiles.github;
    const hiresweetProfile = sourceProfiles.hiresweet;

    const { data } = await axios.post(url, {
      sourceProfiles: {
        ...(linkedinData && {
          linkedinProfile: {
            id: '__no___id',
            data: linkedinData,
            date: new Date().toISOString().slice(2, 10),
          },
        }),
        ...(!_.isEmpty(githubData) && {
          githubProfile: {
            ...githubData,
            id: '__no___id',
            login: '__no__login',
            lowercaes_login: '__no__lowercase_login',
            date: new Date().toISOString().slice(2, 10),
          },
        }),
        ...(!_.isEmpty(hiresweetProfile) && {
          hiresweetProfile
        })
      },
      explicitOffer: offer,
      skipComputeWarnings: true,
    });
    if (data.success === false) {
      throw Error('enrichment failed');
    }
    return data;
  };

  getMatchScore = async ({ sourceProfiles, offer, criterion, dataPointType, scorerId }) => {
    if (dataPointType === PROFILE_OFFER_DATA_POINT_TYPE) {
      const isAdaptive = ["WS4Model", "WS6Model", "WS6SRModel"].indexOf(scorerId) >= 0

      const offerSource = isAdaptive && offer.id ? "cloud_storage" : "payload";
      const offerType = isAdaptive && offer.id ? "offer_v5" : "old";
      const offerJson = isAdaptive && offer.id ? {
        bucket: "data-build-hiresweet",
        path: "raw_jobs/synchronizer-service-BB81C06808/" + offer.id
      } : offer

      const linkedinData = sourceProfiles.linkedin;
      const githubData = sourceProfiles.github;
      const hiresweetId = (sourceProfiles.hiresweet || {}).id
      const profileSource = isAdaptive && hiresweetId ? 'cloud_storage' : 'idFields_and_sourceProfiles'
      const profileType = isAdaptive && hiresweetId ? 'profile_v5' : 'raw';
      const profileJson = isAdaptive && hiresweetId ? {
        bucket: "data-build-hiresweet",
        path: "raw_profiles/synchronizer-service-362C0E8B90/" + hiresweetId
      } : {
        idFields: {},
        sourceProfiles: {
          linkedin: {
            id: '__no___id',
            data: linkedinData,
            date: new Date().toISOString().slice(2, 10),
          },
          ...(!_.isEmpty(githubData) && {
            github: {
              ...githubData,
              id: '__no___id',
              login: '__no__login',
              lowercaes_login: '__no__lowercase_login',
              date: new Date().toISOString().slice(2, 10),
            },
          }),
          ...(!_.isEmpty(sourceProfiles.hiresweet) && { hiresweet: sourceProfiles.hiresweet })
        },
      }
      const url = baseUrl + '/sandbox/scoreItem';
      const body = {
        timestamp: Date.now(),
        offerSource,
        offerType,
        offer: offerJson,
        scorerId,
        profileSource,
        profileType,
        profile: profileJson,
        ...(this.state.scoringUrl && { scoringUrl: this.state.scoringUrl }),
      };
      const { data } = await axios.post(url, body);
      return data;
    } else if (dataPointType === PROFILE_CRITERION_DATA_POINT_TYPE) {
      const url = baseUrl + '/monitoring/evaluateProfileOnCriterion';
      const body = {
        profile: { sourceProfiles },
        criterion,
      };
      const { data } = await axios.post(url, body);
      return data;
    } else {
      alert('Unknown data point type :' + dataPointType);
    }
  };

  handleToggleScoringDetails = () => {
    this.setState({
      displayScoringDetails: !this.state.displayScoringDetails,
    });
  };

  handleToggleShapImportances = () => {
    this.setState({
      displayShapImportances: !this.state.displayShapImportances,
    });
  };

  handleOpenLinkedinEditionModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'linkedin-profile',
        initialValue: this.state.dataPoint.sourceProfiles.linkedin,
      },
    });
  };

  handleOpenGithubEditionModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'github-profile',
        initialValue: this.state.dataPoint.sourceProfiles.github,
      },
    });
  };

  handleOpenHiresweetEditionModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'hiresweet-profile',
        initialValue: this.state.dataPoint.sourceProfiles.hiresweet,
      },
    });
  };

  handleOpenImportCandidateModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'import-candidate',
        initialValue: this.state.dataPoint.sourceProfiles,
      },
    });
  }

  handleSaveSourceProfile = (sourceIdToData) => {
    const newDataPoint = {
      ...this.state.dataPoint,
      sourceProfiles: {
        ...this.state.dataPoint.sourceProfiles,
        ..._.mapObject(sourceIdToData, (data) => _.isEmpty(data) ? null : data)
      },
      enrichedProfile: null,
      scoreWithDetails: null,
    };

    this.setState(
      {
        edition: null,
        dataPoint: newDataPoint,
      },
      async () => {
        const [enrichedProfile, scoreResult] = await Promise.all([
          this.getEnrichedProfile({
            sourceProfiles: newDataPoint.sourceProfiles,
            offer: this.state.dataPoint.offer,
          }),
          this.getMatchScore({
            sourceProfiles: newDataPoint.sourceProfiles,
            offer: this.state.dataPoint.offer,
            criterion: this.state.dataPoint.criterion,
            dataPointType: this.state.dataPointType,
            scorerId: this.state.scorerId
          }),
        ]);

        this.setState({
          dataPoint: {
            ...this.state.dataPoint,
            enrichedProfile,
            scoreWithDetails: scoreResult,
          },
        });
      },
    );
  };

  handleSaveLinkedinProfile = ({ data }) => {
    this.handleSaveSourceProfile({ 'linkedin': data });
  };

  handleSaveGithubProfile = ({ data }) => {
    this.handleSaveSourceProfile({ 'github': data });
  };

  handleSaveHiresweetProfile = ({ data }) => {
    this.handleSaveSourceProfile({ 'hiresweet': data });
  };

  handleSaveSourceProfiles = ({ sourceProfiles }) => {
    this.handleSaveSourceProfile(sourceProfiles);
  };

  handleOpenImportOfferModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'import-offer',
      },
    });
  };

  handleOpenEditOfferModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'edit-offer',
        initialValue: this.state.dataPoint.offer,
      },
    });
  };

  handleSaveOffer = ({ data }) => {
    const newDataPoint = {
      ...this.state.dataPoint,
      offer: data,
      enrichedProfile: null,
      scoreWithDetails: null,
    };

    this.setState(
      {
        edition: null,
        dataPoint: newDataPoint,
      },
      async () => {
        const [enrichedProfile, scoreResult] = await Promise.all([
          this.getEnrichedProfile({
            sourceProfiles: newDataPoint.sourceProfiles,
            offer: newDataPoint.offer,
          }),
          this.getMatchScore({
            sourceProfiles: newDataPoint.sourceProfiles,
            offer: newDataPoint.offer,
            dataPointType: this.state.dataPointType,
            scorerId: this.state.scorerId
          }),
        ]);
        this.setState({
          dataPoint: {
            ...this.state.dataPoint,
            enrichedProfile,
            scoreWithDetails: scoreResult,
          },
        });
      },
    );
  };

  handleOpenEditCriterionModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'edit-criterion',
        initialValue: this.state.dataPoint.criterion,
      },
    });
  };

  handleSaveCriterion = ({ criterion }) => {
    const newDataPoint = {
      ...this.state.dataPoint,
      criterion: criterion,
      scoreWithDetails: null,
    };

    this.setState(
      {
        edition: null,
        dataPoint: newDataPoint,
      },
      async () => {
        const scoreResult = await this.getMatchScore({
          sourceProfiles: newDataPoint.sourceProfiles,
          criterion: newDataPoint.criterion,
          dataPointType: this.state.dataPointType,
          scorerId: this.state.scorerId
        });
        this.setState({
          dataPoint: {
            ...this.state.dataPoint,
            scoreWithDetails: scoreResult,
          },
        });
      },
    );
  };

  handleCloseEdition = () => {
    this.setState({ edition: null });
  };

  handleSetDataPointType = (value) => {
    this.setState({ dataPointType: value });
  };

  handleSetScorerId = async (scorerId) => {
    const scoreResult = await this.getMatchScore({
      sourceProfiles: this.state.dataPoint.sourceProfiles,
      offer: this.state.dataPoint.offer,
      dataPointType: this.state.dataPointType,
      scorerId: scorerId
    })
    this.setState({
      scorerId, dataPoint: {
        ...this.state.dataPoint,
        scoreWithDetails: scoreResult,
      }
    });
  }
  handleRecomputeScore = async () => {
    this.setState({
      dataPoint: {
        ...this.state.dataPoint,
        scoreWithDetails: null,
      }
    });
    const scoreResult = await this.getMatchScore({
      sourceProfiles: this.state.dataPoint.sourceProfiles,
      offer: this.state.dataPoint.offer,
      dataPointType: this.state.dataPointType,
      scorerId: this.state.scorerId
    })
    this.setState({
      dataPoint: {
        ...this.state.dataPoint,
        scoreWithDetails: scoreResult,
      }
    });
  }

  handleChangeScoringUrl = (e, { value }) => {
    this.setState({ scoringUrl: value });
  }

  renderProfileDisplay() {
    if (!this.state.dataPoint.enrichedProfile) {
      return <Loader active inline />
    }
    return <EnrichedProfileView enrichedProfile={this.state.dataPoint.enrichedProfile} sourceData={this.state.dataPoint.sourceData} />
  }

  render() {
    const { edition, displayScoringDetails, displayShapImportances, dataPoint, dataPointType, scoringUrl, offerSource, profileSource } = this.state;
    console.log(dataPoint)
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4} >
            <SInput value={scoringUrl} onChange={this.handleChangeScoringUrl} placeholder="http://..." size="mini" />
            <Button icon size='mini' onClick={this.handleRecomputeScore}><Icon name='refresh' /></Button>
            <a style={{ cursor: 'pointer' }} onClick={() => this.handleChangeScoringUrl(null, { value: "http://172.31.0.89:8234" })}>rd</a>
            <br />
            {this.props.label && `Label : ${this.props.label}`}

          </Grid.Column>
          <Grid.Column width={8}>
            <center>
              {dataPoint.scoreWithDetails !== null && (
                <h2>{Math.round(dataPoint.scoreWithDetails.score * 10000) / 100}%</h2>
              )}
              <p>
                {_.map(SCORER_IDS, (scorerId, i) => (
                  <a style={{
                    fontSize: 10,
                    cursor: 'pointer',
                    fontWeight: scorerId === this.state.scorerId ? 'bold' : 'normal',
                  }} onClick={() => this.handleSetScorerId(scorerId)}>
                    {i != 0 && ' | '}{scorerId}
                  </a>))

                }
              </p>
            </center>
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              style={{
                fontSize: 10,
                cursor: 'pointer',
                fontWeight: dataPointType === PROFILE_OFFER_DATA_POINT_TYPE ? 'bold' : 'normal',
              }}
              onClick={() => this.handleSetDataPointType(PROFILE_OFFER_DATA_POINT_TYPE)}
            >
              Profile - Offer
            </a>{' '}
            | {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              style={{
                fontSize: 10,
                cursor: 'pointer',
                fontWeight: dataPointType === PROFILE_CRITERION_DATA_POINT_TYPE ? 'bold' : 'normal',
              }}
              onClick={() => this.handleSetDataPointType(PROFILE_CRITERION_DATA_POINT_TYPE)}
            >
              Profile - Criterion
            </a>
            <br />
            <br />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={this.handleToggleScoringDetails}>
              {displayScoringDetails ? 'Hide scoring details' : 'Display scoring details'}
            </a>
            {dataPoint.scoreWithDetails && dataPoint.scoreWithDetails.shapImportances && (
              <span>
                <br />
                <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={this.handleToggleShapImportances}>
                  {displayShapImportances ? 'Hide shap importances' : 'Display shap importances'}
                </a>
              </span>
            )}

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            {profileSource && (
              <Popup
                hoverable
                trigger={
                  <Button
                    size="tiny"
                    color="blue"
                    style={{ cursor: 'pointer' }}
                  > {profileSource.type} </Button>
                }
                content={<pre>{profileSource.source.path}</pre>}
              />
            )}
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={this.handleOpenLinkedinEditionModal}>
                Edit Linkedin
              </a>
              {(edition || {}).type === 'linkedin-profile' && (
                <LinkedinEditorModal
                  key={edition.key}
                  onClose={this.handleCloseEdition}
                  onSave={this.handleSaveLinkedinProfile}
                  initialValue={edition.initialValue}
                />
              )}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }}
                onClick={this.handleOpenGithubEditionModal}
              >
                Edit Github
              </a>
              {(edition || {}).type === 'github-profile' && (
                <GithubEditorModal
                  key={edition.key}
                  onClose={this.handleCloseEdition}
                  onSave={this.handleSaveGithubProfile}
                  initialValue={edition.initialValue}
                />
              )}

              <a
                style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }}
                onClick={this.handleOpenHiresweetEditionModal}
              >
                Edit Hiresweet
              </a>
              {(edition || {}).type === 'hiresweet-profile' && (
                <HiresweetEditorModal
                  key={edition.key}
                  onClose={this.handleCloseEdition}
                  onSave={this.handleSaveHiresweetProfile}
                  initialValue={edition.initialValue}
                />
              )}

              <a
                style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }}
                onClick={this.handleOpenImportCandidateModal}
              >
                Import Candidate
              </a>
              {(edition || {}).type === 'import-candidate' && (
                <ImportCandidateModal
                  key={edition.key}
                  onClose={this.handleCloseEdition}
                  onSave={this.handleSaveSourceProfiles}
                  initialValue={edition.initialValue}
                />
              )}
            </div>
            {this.renderProfileDisplay()}
          </Grid.Column>
          <Grid.Column width={8}>
            {dataPoint.scoreWithDetails !== null && dataPoint.scoreWithDetails.shapImportances !== null && displayShapImportances && (
              <ShapImportances shapImportances={dataPoint.scoreWithDetails.shapImportances} />
            )}
            {dataPoint.scoreWithDetails !== null && displayScoringDetails && (
              <ScoringDetails scoringDetails={dataPoint.scoreWithDetails} />
            )}
            {offerSource && (
              <Popup
                hoverable
                trigger={
                  <Button
                    size="tiny"
                    color="blue"
                    style={{ cursor: 'pointer' }}
                  > {offerSource.type} </Button>
                }
                content={<pre>{offerSource.source.path}</pre>}
              />
            )}
            {dataPointType === PROFILE_OFFER_DATA_POINT_TYPE && (
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={this.handleOpenImportOfferModal}>
                  Import Offer
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }} onClick={this.handleOpenEditOfferModal}>
                  Edit Offer
                </a>
                <Offer offer={this.state.dataPoint.offer} />
              </div>
            )}
            {dataPointType === PROFILE_CRITERION_DATA_POINT_TYPE && (
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }}
                  onClick={this.handleOpenEditCriterionModal}
                >
                  Edit Criterion
                </a>
                <pre>{JSON.stringify(this.state.dataPoint.criterion || {}, null, 4)}</pre>
              </div>
            )}
            {(edition || {}).type === 'import-offer' && (
              <ImportOfferModal key={edition.key} onClose={this.handleCloseEdition} onSave={this.handleSaveOffer} />
            )}
            {(edition || {}).type === 'edit-offer' && (
              <EditOfferModal
                key={edition.key}
                initialValue={edition.initialValue}
                onClose={this.handleCloseEdition}
                onSave={this.handleSaveOffer}
              />
            )}
            {(edition || {}).type === 'edit-criterion' && (
              <EditCriterionModal
                key={edition.key}
                initialValue={edition.initialValue}
                onClose={this.handleCloseEdition}
                onSave={this.handleSaveCriterion}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const withInitialQuery = (WrappedComponent) =>
  class extends React.Component {
    render() {
      return (
        <Switch>
          <Route exact path='/ml/sandbox'>
            {({ location }) => {
              const queryValues = queryString.parse(location.search);
              const label = queryValues.label;

              const initialOfferId = queryValues.offer;
              const initialLinkedinId = queryValues.linkedin;
              const initialGithubLogin = queryValues.github;
              const initialHiresweetId = queryValues.hiresweet;

              const initialDataPointsSetId = queryValues.dataPointsSet;
              const initialDataPointId = queryValues.dataPoint;

              const initialProfileCollection = queryValues.profileCollection;
              const initialProfile = queryValues.profile;
              const initialProfileDate = queryValues.profileDate;
              const initialJobCollection = queryValues.jobCollection;
              const initialJob = queryValues.job;
              const initialJobDate = queryValues.jobDate;

              return (
                <WrappedComponent
                  label={label}
                  initialOfferId={initialOfferId}
                  initialLinkedinId={initialLinkedinId}
                  initialGithubLogin={initialGithubLogin}
                  initialHiresweetId={initialHiresweetId}
                  initialDataPointsSetId={initialDataPointsSetId}
                  initialDataPointId={initialDataPointId}
                  initialProfileCollection={initialProfileCollection}
                  initialProfile={initialProfile}
                  initialProfileDate={initialProfileDate}
                  initialJobCollection={initialJobCollection}
                  initialJob={initialJob}
                  initialJobDate={initialJobDate}
                />
              );
            }}
          </Route>
        </Switch>
      );
    }
  };

export default withInitialQuery(DataPointSandbox);
