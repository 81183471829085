import axios from 'axios';
import React, { Component } from 'react';
import { Button, Checkbox, Table } from 'semantic-ui-react';
import _ from 'underscore';
import CustomModal from '../Modal';
import baseUrl from '../baseUrl.js';

class ProfileCollectionsView extends Component {
  componentDidMount() {
    this.handleLoadProfileCollections();
  }

  loadProfileCollectionIds = async () => {
    const url = baseUrl + '/sweetchain/getProfileCollectionIds';
    const profilesCollectionIds = (await axios.get(url)).data;
    return profilesCollectionIds;
  };

  handleLoadProfileCollections = async () => {
    console.log('loading');
    const profilesCollectionIds = await this.loadProfileCollectionIds();
    const url = baseUrl + '/sweetchain/getProfileCollectionsData';
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    let results = [];
    const nbChunks = 10;
    let tmpChunkNumber = 1;
    const chunkSize = Math.ceil(profilesCollectionIds.length / nbChunks);
    for (let i = 0; i < profilesCollectionIds.length; i += chunkSize) {
      const chunk = profilesCollectionIds.slice(i, i + chunkSize);
      const payload = { profileCollectionIds: chunk };
      const chunkResults = (await axios.post(url, payload, customHeaders)).data;
      results = results.concat(chunkResults);
      console.log('loaded chunk ' + tmpChunkNumber + '/' + nbChunks);
      tmpChunkNumber += 1;
    }
    this.setState({
      modalDeleteProfileCollectionVisible: false,
      profileCollections: results,
      selectedProfileCollectionIds: [],
      modalUpgradeProfileCollectionVisible: false,
      modalUpgradeProfileCollectionPostGreSqlVisible: false,
      modalSetLatestActiveVisible: false,
      modalSetLatestActiveVisiblePostGreSql: false,
      modalDeleteNotActiveCollectionVisible: false,
      modalMergeFlushVisible: false,
    });
    console.log('loaded');
  };

  handleClickOnField = (fieldName) => {
    const { profileCollections, previousSort } = this.state || {};
    if (fieldName == previousSort) {
      this.setState({
        previousSort: null,
        profileCollections: _.sortBy(profileCollections, (profileCollection) => profileCollection[fieldName]).reverse(),
      });
    } else {
      this.setState({
        previousSort: fieldName,
        profileCollections: _.sortBy(profileCollections, (profileCollection) => profileCollection[fieldName]),
      });
    }
  };

  onChangeCheckbox = (profileCollectionId) => {
    const { selectedProfileCollectionIds } = this.state;
    if (selectedProfileCollectionIds.includes(profileCollectionId)) {
      this.setState({
        selectedProfileCollectionIds: _.filter(
          selectedProfileCollectionIds,
          (selectedProfileCollectionId) => selectedProfileCollectionId != profileCollectionId,
        ),
      });
    } else {
      selectedProfileCollectionIds.push(profileCollectionId);
      this.setState({ selectedProfileCollectionIds });
    }
  };

  onChangeGlobalCheckbox = () => {
    const { selectedProfileCollectionIds, profileCollections } = this.state;
    const allSelected = (selectedProfileCollectionIds || []).length == (profileCollections || []).length;
    if (allSelected) {
      this.setState({
        selectedProfileCollectionIds: [],
      });
    } else {
      this.setState({
        selectedProfileCollectionIds: _.map(profileCollections, (profileCollection) => profileCollection.id),
      });
    }
  };

  onSelectProfileCollectionSameCurrentAndLatestSize = () => {
    const { profileCollections } = this.state;
    const selectedProfileCollectionIds = _.map(
      _.filter(
        profileCollections,
        (profileCollection) => profileCollection.currentSizeSet == profileCollection.latestSizeSet,
      ),
      (item) => item.id,
    );
    this.setState({ selectedProfileCollectionIds });
  };

  onSelectProfileCollectionSameCurrentAndLatestSizePostGreSql = () => {
    const { profileCollections } = this.state;
    const selectedProfileCollectionIds = _.map(
      _.filter(
        profileCollections,
        (profileCollection) => profileCollection.currentSizeSet == profileCollection.latestSizeSetPostGreSql,
      ),
      (item) => item.id,
    );
    this.setState({ selectedProfileCollectionIds });
  };

  onDeleteProfileCollections = async () => {
    const { selectedProfileCollectionIds } = this.state || {};
    _.each(selectedProfileCollectionIds, async (selectedProfileCollectionId) => {
      if (selectedProfileCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/deleteProfilesCollectionAndWorkflow';
        const payload = {
          profileCollectionId: selectedProfileCollectionId,
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadProfileCollections();
  };

  onUpgradeProfileCollection = async () => {
    const { selectedProfileCollectionIds } = this.state || {};
    _.each(selectedProfileCollectionIds, async (selectedProfileCollectionId) => {
      if (selectedProfileCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/upgradeProfileCollection';
        const payload = {
          profileCollectionId: selectedProfileCollectionId,
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadProfileCollections();
  };

  onUpgradeProfileCollectionPostGreSql = async () => {
    const { selectedProfileCollectionIds } = this.state || {};
    _.each(selectedProfileCollectionIds, async (selectedProfileCollectionId) => {
      if (selectedProfileCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/upgradeProfileCollectionPostGreSQL';
        const payload = {
          profileCollectionId: selectedProfileCollectionId,
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadProfileCollections();
  };

  onSetLatestActive = async () => {
    const { selectedProfileCollectionIds } = this.state || {};
    _.each(selectedProfileCollectionIds, async (selectedProfileCollectionId) => {
      if (selectedProfileCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/setActiveLatestSearchableSet';
        const payload = {
          collectionId: selectedProfileCollectionId,
          type: 'profile',
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadProfileCollections();
  };

  onSetLatestActivePostGreSql = async () => {
    const { selectedProfileCollectionIds } = this.state || {};
    _.each(selectedProfileCollectionIds, async (selectedProfileCollectionId) => {
      if (selectedProfileCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/setActiveLatestSearchableSetPostGreSql';
        const payload = {
          collectionId: selectedProfileCollectionId,
          type: 'profile',
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadProfileCollections();
  };

  onDeleteNotActive = async () => {
    const { selectedProfileCollectionIds } = this.state || {};
    _.each(selectedProfileCollectionIds, async (selectedProfileCollectionId) => {
      if (selectedProfileCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/deleteAllExceptActiveVersion';
        const payload = {
          collectionId: selectedProfileCollectionId,
          type: 'profile',
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadProfileCollections();
  };

  onMergeAndFlush = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/mergeAndFlushAllMiniProfiles';
    await axios.get(url, customHeaders);
    this.setState({ modalMergeFlushVisible: false });
  };

  onCancel = () => {
    this.setState({
      modalDeleteProfileCollectionVisible: false,
      modalUpgradeProfileCollectionVisible: false,
      modalUpgradeProfileCollectionPostGreSqlVisible: false,
      modalSetLatestActiveVisible: false,
      modalSetLatestActiveVisiblePostGreSql: false,
      modalDeleteNotActiveCollectionVisible: false,
      modalMergeFlushVisible: false,
    });
  };

  handleClickOnDelete = () => {
    this.setState({ modalDeleteProfileCollectionVisible: true });
  };

  handleClickOnUpgrade = () => {
    this.setState({ modalUpgradeProfileCollectionVisible: true });
  };

  handleClickOnUpgradePostGreSql = () => {
    this.setState({ modalUpgradeProfileCollectionPostGreSqlVisible: true });
  };

  handleClickOnSetLatestActive = () => {
    this.setState({ modalSetLatestActiveVisible: true });
  };

  handleClickOnSetLatestActivePostGreSql = () => {
    this.setState({ modalSetLatestActiveVisiblePostGreSql: true });
  };

  handleClickOnDeleteNotActive = () => {
    this.setState({ modalDeleteNotActiveCollectionVisible: true });
  };

  handleClickOnMergeAndFlush = () => {
    this.setState({ modalMergeFlushVisible: true });
  };

  getItemColor = (row) => {
    if (
      (row.activeContextVersion <= 0 && row.activePostGreSqlContextVersion <= 0) ||
      (row.activeContextVersion > 0 && row.latestContextVersion <= 0) ||
      (row.activePostGreSqlContextVersion > 0 && row.latestContextVersionPostGreSql <= 0)
    ) {
      return '#cfa1a1';
    }
    if (row.currentSizeSet === 0 && row.nbProfileNotDeleted > 0) {
      return '#ffdddd';
    }
    if (
      (row.latestContextVersion > 0 && row.latestContextVersionPostGreSql > 0) ||
      (row.activeContextVersion > 0 && row.activePostGreSqlContextVersion > 0)
    ) {
      return '#e0dea4';
    }
    if (
      (row.latestContextVersion > 0 && row.latestContextVersion !== row.activeContextVersion) ||
      (row.latestContextVersionPostGreSql > 0 &&
        row.latestContextVersionPostGreSql !== row.activePostGreSqlContextVersion)
    ) {
      return '#ffb74d';
    }
    if (row.nbExistingVersions > 1) {
      return '#ffe0b3';
    }
    return '#ddffdd';
  };

  render() {
    const {
      profileCollections,
      selectedProfileCollectionIds,
      modalDeleteProfileCollectionVisible,
      modalUpgradeProfileCollectionVisible,
      modalUpgradeProfileCollectionPostGreSqlVisible,
      modalSetLatestActiveVisible,
      modalDeleteNotActiveCollectionVisible,
      modalSetLatestActiveVisiblePostGreSql,
      modalMergeFlushVisible,
    } = this.state || {};
    return (
      <div>
        {modalDeleteProfileCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Profile Collections`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteProfileCollections}
          >
            <p>
              Are you sure ? This operation cannot be reverted. It will also delete all the services and sets related to
              the selected profile collection.
            </p>
          </CustomModal>
        ) : null}
        {modalUpgradeProfileCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Upgrade Profile Collections`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onUpgradeProfileCollection}
          >
            <p>Are you sure ? This operation can be heavy if many profile collections are selected.</p>
          </CustomModal>
        ) : null}
        {modalUpgradeProfileCollectionPostGreSqlVisible ? (
          <CustomModal
            active={true}
            headerText={`Upgrade Profile Collections post gre sql`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onUpgradeProfileCollectionPostGreSql}
          >
            <p>Are you sure ? This operation can be heavy if many profile collections are selected.</p>
          </CustomModal>
        ) : null}
        {modalSetLatestActiveVisible ? (
          <CustomModal
            active={true}
            headerText={`Set Latest Active`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onSetLatestActive}
          >
            <p>Are you sure ? Be sure to have checked the size of latest sets before migrating</p>
          </CustomModal>
        ) : null}
        {modalSetLatestActiveVisiblePostGreSql ? (
          <CustomModal
            active={true}
            headerText={`Set Latest Active PostGreSql`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onSetLatestActivePostGreSql}
          >
            <p>Are you sure ? Be sure to have checked the size of latest sets before migrating</p>
          </CustomModal>
        ) : null}
        {modalDeleteNotActiveCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Not Active Sets & Services`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteNotActive}
          >
            <p>
              Are you sure ? This operation cannot be revert, it will delete all the sets and services except the
              current active one
            </p>
          </CustomModal>
        ) : null}
        {modalMergeFlushVisible ? (
          <CustomModal
            active={true}
            headerText={`Merge And Flush all mini profiles`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onMergeAndFlush}
          >
            <p>Are you sure ? This operation is heavy but recommended before starting a migration to a new version</p>
          </CustomModal>
        ) : null}
        <Button onClick={() => this.handleClickOnMergeAndFlush()} color='yellow'>
          Merge And Flush All Mini Items
        </Button>
        <Button onClick={() => this.onSelectProfileCollectionSameCurrentAndLatestSize()}>
          Select Same Active and Latest Set Size
        </Button>
        <Button onClick={() => this.onSelectProfileCollectionSameCurrentAndLatestSizePostGreSql()}>
          Select Same Active and Latest Set Size (PostGre)
        </Button>
        {selectedProfileCollectionIds && selectedProfileCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnUpgrade()} color='green'>
            Upgrade Batch
          </Button>
        ) : null}
        {selectedProfileCollectionIds && selectedProfileCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnUpgradePostGreSql()} color='green'>
            Upgrade Batch (PostGre)
          </Button>
        ) : null}
        {selectedProfileCollectionIds && selectedProfileCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnSetLatestActive()} color='green'>
            Set Latest Active
          </Button>
        ) : null}
        {selectedProfileCollectionIds && selectedProfileCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnSetLatestActivePostGreSql()} color='green'>
            Set Latest Active (PostGre)
          </Button>
        ) : null}

        {selectedProfileCollectionIds && selectedProfileCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnDeleteNotActive()} color='orange'>
            Delete Not Active Versions
          </Button>
        ) : null}
        {selectedProfileCollectionIds && selectedProfileCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnDelete()} color='red'>
            Delete Batch
          </Button>
        ) : null}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>
                <Checkbox
                  checked={(selectedProfileCollectionIds || []).length == (profileCollections || []).length}
                  onChange={() => this.onChangeGlobalCheckbox()}
                />
              </Table.HeaderCell>
              <Table.HeaderCell singleLine>
                <a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleClickOnField('id')}>
                  Id
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('latestContextVersion')}
                >
                  Most Recent Version (BGQ | PostGre)
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('activeContextVersion')}
                >
                  Active Versions (BGQ | PostGre)
                </a>
              </Table.HeaderCell>

              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('lastModificationOfProfiles')}
                >
                  Last Modification
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('nbExistingVersions')}
                >
                  Nb Existing Versions
                </a>
              </Table.HeaderCell>

              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('creationTimestamp')}
                >
                  Creation Date
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('lastCursorUpdateDate')}
                >
                  Last Cursor Use
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('nbProfileNotDeleted')}
                >
                  Nb Profiles
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('currentSizeSet')}
                >
                  Active Set Size
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('latestSizeSet')}
                >
                  Latest Set Size (BGQ | PostGre)
                </a>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(profileCollections || [], (profileCollection, key) => (
              <Table.Row key={key} style={{ backgroundColor: this.getItemColor(profileCollection) }}>
                <Table.Cell>
                  <Checkbox
                    checked={(selectedProfileCollectionIds || []).includes(profileCollection.id)}
                    onChange={() => this.onChangeCheckbox(profileCollection.id)}
                  />
                </Table.Cell>
                <Table.Cell>{profileCollection.id}</Table.Cell>
                <Table.Cell>
                  ({profileCollection.latestContextVersion} | {profileCollection.latestContextVersionPostGreSql} )
                </Table.Cell>
                <Table.Cell>
                  ({profileCollection.activeContextVersion} | {profileCollection.activePostGreSqlContextVersion})
                </Table.Cell>
                <Table.Cell>
                  {new Date(profileCollection.lastModificationOfProfiles || 0).toISOString().substring(0, 10)}
                </Table.Cell>
                <Table.Cell>{profileCollection.nbExistingVersions}</Table.Cell>
                <Table.Cell>
                  {new Date(profileCollection.creationTimestamp || 0).toISOString().substring(0, 10)}
                </Table.Cell>
                <Table.Cell>
                  {new Date(profileCollection.lastCursorUpdateDate || 0).toISOString().substring(0, 10)}
                </Table.Cell>
                <Table.Cell>{profileCollection.nbProfileNotDeleted}</Table.Cell>
                <Table.Cell>{profileCollection.currentSizeSet}</Table.Cell>
                <Table.Cell>
                  ({profileCollection.latestSizeSet} | {profileCollection.latestSizeSetPostGreSql})
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default ProfileCollectionsView;
