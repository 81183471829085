import React from 'react';
import CandidatesOverview from './CandidatesOverview';
import Candidate from '../Candidate';
import AddCandidateModal from './AddCandidateModal';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import { Button, Modal, Grid} from 'semantic-ui-react';
import moment from 'moment';
import { Input, SweetForm } from 'sweetform';

//const WATCH_SHEET_ID = 'watch-sheet-1';

const getThursdayOfCurrentWeekTimestamp = (timestamp) => {
  return (
    1000 *
    moment(timestamp)
      .day(4)
      .hour(1)
      .unix()
  );
};
const getThursdayOfLastWeekTimestamp = (timestamp) => {
  return (
    1000 *
    moment(timestamp)
      .subtract(1, 'weeks')
      .day(4)
      .hour(1)
      .unix()
  );
};
const getLastThursdayTimestamp = (timestamp) => {
  const isAfterThursday = moment(timestamp).day() >= 4;
  if (isAfterThursday) {
    return getThursdayOfCurrentWeekTimestamp(timestamp);
  }
  return getThursdayOfLastWeekTimestamp(timestamp);
};

const EditCandidateModal = ({ id, candidateIsInternal, onCloseEditionAndRefreshCandidate }) => (
  <Modal
    onClose={() => onCloseEditionAndRefreshCandidate()}
    open
    closeIcon
    size='fullscreen'
    closeOnDimmerClick={false}
  >
    <Modal.Header>Editing Profile {id}</Modal.Header>
    <Modal.Content style={{ minHeight: 300 }}>
      <Candidate
        id={id}
        isInternal={candidateIsInternal}
        deleteProfileCallback={() => onCloseEditionAndRefreshCandidate()}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={() => onCloseEditionAndRefreshCandidate()}>
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

const getCandidates = async ({ minCreationDate, maxCreationDate }) => {
  try {
    const { candidates } = (await axios.get(`${baseUrl}/candidates?miniForSheet=1&minCreationDate=${minCreationDate}&maxCreationDate=${maxCreationDate}`)).data;
    return candidates;
  } catch (e) {
    alert(e.message);
  }
};

const getSources = async () => {
  try {
    const { sources } = (await axios.get(`${baseUrl}/candidates/sources`)).data;
    return sources;
  } catch (e) {
    alert(e.message);
  }
};

class WatchSheet extends React.Component {
  state = {
    candidates: [],
    editedCandidate: null,
    editMode: null,
    query: {
      minCreationDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      maxCreationDate: moment().format('YYYY-MM-DD'),
    },
  };

  componentWillMount() {
    this.load();
  }

  handleChangeQuery = (value) => {
    this.setState({
      query: value
    });
  };

  load = async () => {
    const { query } = this.state;
    const sources = await getSources();
    const candidates = await getCandidates(query);
    this.enrichCandidates({ candidates });
    this.setState({
      candidates: candidates,
      sources: sources,
    });
  };

  getCollectInfo = ({ success, error, nowPresent, wasPresent, reason, searchPoolCreationTimestamp }) => {
    const ERROR_COLOR = "dark"
    const ABSENT_COLOR = "red"
    const PRESENT_COLOR = "green"
    if (!success) {
      return {
        label: "Error",
        color: ERROR_COLOR,
        message: error
      }
    }
    if (nowPresent) {
      const color = PRESENT_COLOR
      const message = searchPoolCreationTimestamp && ((new Date(searchPoolCreationTimestamp)).toISOString()).slice(0, 10)
      let label = wasPresent ? "Edited" : "Created"
      const lastThursdayTimestamp = getLastThursdayTimestamp(new Date().getTime());
      if (searchPoolCreationTimestamp > lastThursdayTimestamp) {
        label += " (new)"
      }
      return {
        label,
        color,
        message
      }
    } else {
      return {
        color: ABSENT_COLOR,
        message: reason,
        label: wasPresent ? "Deleted" : "Already Absent"
      }
    }
  }

  enrichCandidates = ({ candidates }) => {
    _.each(candidates, (candidate) => {
      const sheetStatus = (candidate.status || {}).type;
      candidate.category = sheetStatus;
      candidate.collectInfo = this.getCollectInfo(candidate.lastCollectUpdate || {})
    });
  };

  loadOneCandidate = async (id) => {
    try {
      if (_.filter(this.state.candidates, (candidate) => candidate.id === id).length === 0) {
        const data = (await axios.get(`${baseUrl}/candidates/${id}?withData=1`)).data;
        console.log('data', data);
        let candidate = data.candidate;
        this.enrichCandidates({ candidates: [candidate] });
        console.log('candidate', candidate);
        const newCandidates = this.state.candidates.concat([candidate]);
        this.setState({
          candidates: newCandidates,
        });
        return candidate;
      }
    } catch (e) {
      alert(e.message);
    }
  };

  refreshOneCandidate = async (id) => {
    try {
      const { candidate } = (await axios.get(`${baseUrl}/candidates/${id}?withData=1`)).data;
      const profileIsDisplayed = _.filter(this.state.candidates, (candidate) => candidate.id === id).length === 1;
      if (_.isEmpty(candidate)) {
        if (profileIsDisplayed) {
          const newCandidates = _.filter(this.state.candidates, (candidate) => candidate.id !== id);
          this.enrichCandidates({ profiles: newCandidates });
          this.setState({
            candidates: newCandidates,
          });
        }
      } else {
        if (profileIsDisplayed) {
          const newCandidates = _.map(this.state.candidates, (otherCandidate) =>
            otherCandidate.id !== id ? otherCandidate : candidate,
          );
          this.enrichCandidates({ candidates: newCandidates });
          this.setState({
            candidates: newCandidates,
          });
        }
      }
    } catch (e) {
      alert(e);
    }
  };

  onOpenAddCandidate = () => {
    this.setState({
      editMode: 'addCandidate',
    });
  };

  onOpenEditCandidate = (candidate) => {
    this.setState({
      editMode: 'editCandidate',
      editedCandidate: candidate,
    });
  };

  onSubmitAddCandidate = async ({ linkedinId, firstname, lastname, source, grade }) => {
    try {
      const input = {
        linkedinId,
        firstname,
        lastname,
        source,
        grade,
      };
      const { data } = await axios.post(`${baseUrl}/candidates`, input);
      if (data && !data.error && !_.isEmpty(data)) {
        const { id } = data;
        await this.loadOneCandidate(id);
        return id;
      } else {
        throw Error(data.error ? data.error : `Oups ! Something went wrong for linkedinId : ${input.linkedinId}`);
      }
    } catch (e) {
      return { success: false, error: e.message };
    }
  };

  onCloseEdition = () => {
    this.setState({
      editMode: 'none',
      editedCandidate: null,
    });
  };

  onCloseEditionAndRefreshCandidate = (id) => {
    this.onCloseEdition();
    this.refreshOneCandidate(id);
  };

  onPerformStatusAction = async ({ actionId, id }) => {
    // TODO : refacto
    const action = {
      type: 'execute',
      operation: actionId,
    };
    try {
      const { data } = await axios.post(`${baseUrl}/candidates/${id}/status`, {
        action,
      });
      if (!data.success) {
        throw Error(data.error);
      } else {
        this.refreshOneCandidate(id);
      }
    } catch (e) {
      alert(e);
    }
  };

  render() {
    const { editMode, editedCandidate, candidates, sources, query } = this.state;
    return (
      <div
        style={{
          display: 'inline-block',
          paddingTop: '5px',
          paddingBottom: '20px',
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {/*<Button onClick={this.onOpenAddCandidate}>Add candidate</Button>*/}
        <div>
          <SweetForm initialValues={query} onChange={this.handleChangeQuery}>
            <Grid columns={3}>
              <Grid.Column width={3}>
                <b>Min creation date:</b>
                <Input type='date' field='minCreationDate' />
              </Grid.Column>
              <Grid.Column width={3}>
                <b>Max creation date:</b>
                <Input type='date' field='maxCreationDate' />
              </Grid.Column>
              <Grid.Column width={3}>
                <Button color='green' onClick={() => this.load()}>
                  Find
                </Button>
              </Grid.Column>
            </Grid>
          </SweetForm>
        </div>
        <CandidatesOverview
          candidates={candidates}
          onOpenEditCandidate={this.onOpenEditCandidate}
          onPerformStatusAction={this.onPerformStatusAction}
          sources={sources}
        />

        {editMode === 'addCandidate' ? (
          <AddCandidateModal
            sources={sources}
            onSubmitAddCandidate={this.onSubmitAddCandidate}
            onCloseEdition={this.onCloseEdition}
          />
        ) : null}
        {editMode === 'editCandidate' ? (
          <EditCandidateModal
            onCloseEditionAndRefreshCandidate={() =>
              this.onCloseEditionAndRefreshCandidate(editedCandidate.id)
            }
            id={editedCandidate.id}
            candidateIsInternal={editedCandidate.isInternal}
          />
        ) : null}
      </div>
    );
  }
}
export default WatchSheet;
