import React from 'react';
import { 
  Container,
  Card,
  Grid,
  Segment,
  Label,
  List,
  Table,
  Button,
  Message
} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import { SweetForm, Input, enhance } from 'sweetform';

export const fitIsValid = (value) => {
  return !isNaN(parseFloat(value))
}

export const JobPositionFitEditor = enhance(({value, jobPositionIds})=>{
  const colorFromFit = (fit) => {
    if (!fitIsValid(fit)){
      return '#f7bfb5'
    } else {
      // eslint-disable-next-line
      if (fit==0){
        return '#e3e3e3'
      } else {
        return '#daffd9'
      }
    }
  }
  return (
    <Table compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Job Position</Table.HeaderCell>
          <Table.HeaderCell>Fit</Table.HeaderCell>
          <Table.HeaderCell>Edit</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(jobPositionIds, (jobPosition)=>{
          const fit = value ? value.get(jobPosition) : null;
          const color = colorFromFit(fit)
          return (
            <Table.Row key={jobPosition} style={{backgroundColor:color}}>
              <Table.Cell>
                {jobPosition}
              </Table.Cell>
              <Table.Cell>
                {fit}
              </Table.Cell>
              <Table.Cell>
                <Input field={jobPosition}/>
              </Table.Cell>
            </Table.Row>
          )
        })
        }
      </Table.Body>
    </Table>
  )
})

export const EducationFieldPrestigeEditor = enhance(({value, fieldIds})=>{
  const colorFromPrestige = (prestige) => {
    if (!fitIsValid(prestige)){
      return '#f7bfb5'
    } else {
      // eslint-disable-next-line
      if (prestige==0){
        return '#e3e3e3'
      } else {
        return '#daffd9'
      }
    }
  }
  return (
    <Table compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Education Field</Table.HeaderCell>
          <Table.HeaderCell>Prestige</Table.HeaderCell>
          <Table.HeaderCell>Edit</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(fieldIds, (field)=>{
          const prestige = value ? value.get(field) : null;
          const color = colorFromPrestige(prestige)
          return (
            <Table.Row key={field} style={{backgroundColor:color}}>
              <Table.Cell>
                {field}
              </Table.Cell>
              <Table.Cell>
                {prestige}
              </Table.Cell>
              <Table.Cell>
                <Input field={field}/>
              </Table.Cell>
            </Table.Row>
          )
        })
        }
      </Table.Body>
    </Table>
  )
})

class EducationFieldsOverview extends React.Component {
  state = {
    search: '',
  };

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  render() {

    const {
      educationFields,
      onSelectEducationField,
      selectedEducationField,
    } = this.props;
    const { search } = this.state;
    const filteredEducationFields = (search && educationFields) ?
       _.filter(educationFields, ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      : educationFields
    const sortedEducationFields =  _.sortBy(filteredEducationFields, ({name}) => name)

    return (
      <Container>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <h3>
                      Education Fields
                    </h3>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <SweetForm onChange={this.onChangeSearch}>
                      <Input field="search" />
                    </SweetForm>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
            <Card.Description>
              <Grid>
                {sortedEducationFields.map((educationField) => {
                  return (
                    <Grid.Row key={educationField.id}>
                      <Grid.Column
                        width={16}
                        onClick={() => onSelectEducationField(educationField)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          style={
                            selectedEducationField && selectedEducationField.id === educationField.id
                              ? {
                                  fontWeight: 'bold',
                                  color: 'green',
                                  fontSize: '13px',
                                }
                              : {}
                          }
                        >
                          {`${educationField.name} (missing ${educationField.missingLinks} links)`}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}


const EducationField = ({educationField}) => (
  educationField ?
    <div>
      <h2>{educationField.name}</h2>
      <Label>id: {educationField.id}</Label>
      <Label>type: {(educationField.info || {}).fieldType}</Label>
      <Grid>
        <Grid.Column width={8}>
          <h3>Patterns</h3>
          <List>
            {
              _.map(educationField.patterns, (patternList, index) => (
                <List.Item key={index}>{patternList.join(';')}</List.Item>
              ))
            }
          </List>
        </Grid.Column>
        <Grid.Column width={8}>
          <h3>Dependencies</h3>
          <List>
            {
              _.map(educationField.strong_dependencies, (skillId, index) => (
                <List.Item key={index} ><b>{skillId}</b></List.Item>
              ))
            }
            {
              _.map(educationField.medium_dependencies, (skillId, index) => (
                <List.Item key={index}>{skillId}</List.Item>
              ))
            }
          </List>
        </Grid.Column>
      </Grid>
    </div>
  : null
)


const EducationFieldInfoEditor = ({educationField, jobPositionIds, onUpdateInfo, onSave, saved}) => {
  const { info } = educationField || {};
  return (
  <div>
    <Button positive={!saved} onClick={()=>onSave()} disabled={saved}>Save</Button>
    <SweetForm
      key={educationField.id}
      initialValues={info}
      onChange={onUpdateInfo}
    >
      <JobPositionFitEditor 
        field="jobPositionFit" 
        jobPositionIds={jobPositionIds}
      />
      <Input field="fieldType"/>
    </SweetForm>
  </div>
)}




class EducationFields extends React.Component {
  state = {
    educationFields:null,
    jobPositionIds:null,
    selectedEducationField:null,
    saved: true
  };

  componentWillMount() {
    this.loadData();
  }

  loadData = async () => {
    const educationFields = await this.getEducationFields();
    const jobPositionIds = await this.getJobPositionIds();
    this.computeMissingLinks({educationFields, jobPositionIds});
    this.setState({educationFields, jobPositionIds})
  }

  getEducationFields = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/tags/educationFields`);
      return data.educationFields
    } catch (e) {
      alert(e);
    }
  };

  getJobPositionIds = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/tags/list?type=job`);
      const jobPositionIds = _.pluck(data, 'id')
      return jobPositionIds
    } catch (e) {
      alert(e);
    }
  };

  computeMissingLinks = ({educationFields, jobPositionIds}) => {
    _.each(educationFields, (educationField)=>{
      const jobPositionFit = ((educationField.info || {}).jobPositionFit || {})
      const missingJobs = _.filter(jobPositionIds, (jobId)=>(!(jobId in jobPositionFit) || !fitIsValid(jobPositionFit[jobId])))
      educationField.missingLinks = (missingJobs || []).length
    })
  }

  onSelectEducationField = (educationField) => {
    const {jobPositionIds} = this.state;
    const sortedJobPositionIds = _.sortBy(jobPositionIds, (jobId)=>{
      const fit = ((educationField.info || {}).jobPositionFit || {})[jobId]
      if(fitIsValid(fit)) {
        return -fit
      }
      return -1000
    })
    this.setState({
      selectedEducationField: educationField,
      jobPositionIds: sortedJobPositionIds,
      saved: true
    })
  }

  onUpdateInfo = ({ jobPositionFit }) => {
    const currentEducationField = this.state.selectedEducationField || {}
    this.setState({
      selectedEducationField:{
        ...currentEducationField, 
        info:{
          ...(currentEducationField.info || {}),
          jobPositionFit
        }
      },
      saved:false
    });
  };

  onSave = async () => {
    const cleanJobPositionFit = (jobPositionFit) => {
      return _.mapObject(jobPositionFit, (value, key) => {
        if (!fitIsValid(value)){
          throw Error('Invalid value entered for '+key+' : '+value+'. Should be a number between 0 and 1.')
        }
        return parseFloat(value)
      })
    }
    try {
      const { selectedEducationField, educationFields } = this.state;
      const { id, info } = selectedEducationField;
      info.jobPositionFit = cleanJobPositionFit(info.jobPositionFit)
      const { data } = await axios.post(`${baseUrl}/tags/educationFieldsInfos/${id}`, info);
      if (!data.success){
        console.log(data)
        throw Error(data.error)
      }
      const newEducationFields = _.map(educationFields, (otherField)=>(
        otherField.id===id ? selectedEducationField : otherField
      ))
      this.setState({
        saved:true,
        educationFields:newEducationFields
      })
      console.log('saved')
    } catch (e) {
      console.log(e)
      alert(e)
    }
    
  }

  render () {
    const { 
      educationFields, 
      selectedEducationField, 
      jobPositionIds, 
      saved 
    } = this.state;
    console.log(educationFields)
    const totalMissingLinks = _.pluck(educationFields, 'missingLinks').reduce(function(pv, cv) { return pv + cv; }, 0);
    return (

      <div>
      {
        totalMissingLinks && (
          <Message error>
          {`Missing ${totalMissingLinks} link.`}
          </Message>
        )
      }
      <Grid columns={2}>
        <Grid.Column width={4}>
          <EducationFieldsOverview
            educationFields={educationFields}
            onSelectEducationField={this.onSelectEducationField}
            selectedEducationField={selectedEducationField}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedEducationField && (
            <div>
              <Segment key={selectedEducationField.id}>
                <EducationField educationField={selectedEducationField} />
              </Segment>
            </div>
          )}
          {selectedEducationField && (
            <Segment>
              <EducationFieldInfoEditor
                educationField={selectedEducationField}
                jobPositionIds={jobPositionIds}
                onUpdateInfo={this.onUpdateInfo}
                onSave={this.onSave}
                saved={saved}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid>
      </div>


    )

  }


}

export default EducationFields;
