import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../../baseUrl';
import { Grid, Form, Divider, Header, Icon, Button } from 'semantic-ui-react';
import { SweetForm, Select, SelectInt, List, Input, enhance, customOperator, Range, Checkbox } from 'sweetform';
import { Textarea } from '../../common';
import { getRandomString } from './WatchEditData';
import appJobs from './data/jobs';
import appSkills from './data/skills';
import appLocations from './data/locations';
import appResponsibilities from './data/responsibilities';
import appLanguages from './data/languages';

// FORMAT
const addElementIds = (prefix, items) => {
  return _.map(items, (item) => ({
    ...item,
    elementId: prefix + '-' + getRandomString(6),
  }));
};
export const formWishesToSheetWishes = (wishes) => {
  const formatOkTargetMulti = (wish) => {
    return {
      target: addElementIds('wish', _.map(_.compact(((wish || {}).target || '').split(';')), (id) => ({ id }))),
      okWith: addElementIds('wish', _.map(_.compact(((wish || {}).okWith || '').split(';')), (id) => ({ id }))),
    };
  };
  const formatLocations = (locations) => {
    return {
      ...locations,
      target: addElementIds(
        'wish-',
        _.map((locations || {}).target || [], (element) => ({ ...element, location: { id: element.location } })),
      ),
      okWith: addElementIds(
        'wish-',
        _.map((locations || {}).okWith || [], (element) => ({ ...element, location: { id: element.location } })),
      ),
    };
  };
  const resWishes = {
    ...wishes,
    jobs: formatOkTargetMulti((wishes || {}).jobs),
    skills: formatOkTargetMulti((wishes || {}).skills),
    contracts: formatOkTargetMulti((wishes || {}).contracts),
    responsibilities: formatOkTargetMulti((wishes || {}).responsibilities),
    locations: formatLocations((wishes || {}).locations),
  };
  return resWishes;
};

export const formHiresweetDataToSheetHiresweetData = (hiresweetData) => {
  return {
    ...hiresweetData,
    ...(hiresweetData.languages && { languages: addElementIds('language', hiresweetData.languages) }),
    ...(hiresweetData.wishes && { wishes: formWishesToSheetWishes(hiresweetData.wishes) }),
    ...(hiresweetData.extraInfo && { extraInfo: { text: hiresweetData.extraInfo } }),
  };
};

export const sheetWishesToFormWishes = (wishes) => {
  const formatOkTargetMulti = (wish) => {
    return {
      target: _.pluck((wish || {}).target || [], 'id').join(';'),
      okWith: _.pluck((wish || {}).okWith || [], 'id').join(';'),
    };
  };
  const formatLocations = (locations) => {
    return {
      ...locations,
      target: _.map((locations || {}).target || [], (element) => ({ ...element, location: element.location.id })),
      okWith: _.map((locations || {}).okWith || [], (element) => ({ ...element, location: element.location.id })),
    };
  };
  // TODO : Extra criteria
  // split multi selects for industry, jobTitle, all skills
  //
  return {
    ...wishes,
    jobs: formatOkTargetMulti((wishes || {}).jobs),
    skills: formatOkTargetMulti((wishes || {}).skills),
    contracts: formatOkTargetMulti((wishes || {}).contracts),
    responsibilities: formatOkTargetMulti((wishes || {}).responsibilities),
    locations: formatLocations((wishes || {}).locations),
  };
};

export const sheetHiresweetDataToFormHiresweetData = (hiresweetData) => {
  return {
    ...hiresweetData,
    ...(!_.isEmpty(hiresweetData.wishes) && { wishes: sheetWishesToFormWishes(hiresweetData.wishes) }),
    ...(hiresweetData.extraInfo && { extraInfo: hiresweetData.extraInfo.text || '' }),
  };
};

// OPTIONS
export const toFormatedOptions = (loadOptions) => {
  return async () => {
    const options = await loadOptions();
    return { options, complete: true };
  };
};

export const loadSkills = async () => {
  const skills = (await axios.get(`${baseUrl}/tags/list?type=skill`)).data;
  const appSkillIds = _.pluck(appSkills, 'value');
  const filteredSkills = _.filter(skills, (skill) => appSkillIds.indexOf(skill.id) >= 0);
  return _.map(filteredSkills, ({ id, name }) => ({ value: id, label: name }));
};

export const loadJobTitles = async () => {
  const jobTitles = (await axios.get(`${baseUrl}/tags/list?type=job`)).data;
  const appJobIds = _.pluck(appJobs, 'value');
  const filteredJobs = _.filter(jobTitles, (job) => appJobIds.indexOf(job.id) >= 0);
  return _.map(filteredJobs, ({ id, name }) => ({ value: id, label: name }));
};

const loadResponsibilities = async () => {
  let responsibilities = (await axios.get(`${baseUrl}/tags/list?type=job-responsibility`)).data;
  responsibilities = [{ id: 'normal', name: 'Individual Contributor' }].concat(responsibilities);
  const appResponsibilityIds = _.pluck(appResponsibilities, 'value');
  const filteredResponsibilities = _.filter(responsibilities, (resp) => appResponsibilityIds.indexOf(resp.id) >= 0);
  return _.map(filteredResponsibilities, ({ id, name }) => ({ value: id, label: name }));
};

export const loadLocations = async (input) => {
  let { locations } = (await axios.get(`${baseUrl}/locations/translatedLocations`)).data;
  locations = _.sortBy(locations, (location) => {
    if (location.value === 'paris-region') {
      return 0;
    }
    return 1;
  });
  const appLocationIds = _.pluck(appLocations, 'value');
  const filteredLocations = _.filter(locations, (loc) => appLocationIds.indexOf(loc.value) >= 0);
  return filteredLocations;
};

const loadIndustries = async () => {
  const industries = (await axios.get(`${baseUrl}/tags/list?type=industry`)).data;
  return _.map(industries, ({ id, name }) => ({ value: id, label: name }));
};

const CONTRACT_TYPES = [
  { value: 'CDI', label: 'CDI' },
  { value: 'freelance', label: 'Freelance' },
  { value: 'apprenticeship', label: 'Apprentissage' },
  { value: 'internship', label: 'Stage' },
  // { value: 'CDD', label: 'CDD' },
  // { value: 'block-release', label: 'Alternance' },
];

export const REMOTE_OPTIONS = [
  { value: 'mainly-on-site', label: 'Punctual Remote' },
  { value: 'hybrid-remote', label: 'Hybrid Remote' },
  { value: 'near-full-remote', label: 'Majority Remote' },
  { value: 'full-remote', label: 'Full Remote' },
  { value: 'on-site', label: '_os_' },
  { value: 'remote-if-possible', label: '_or_' },
  { value: 'partial-remote', label: '_pr_' },
];


const MODALITIES = [{ value: 'research', label: 'Recherche' }, { value: 'consulting', label: 'Consultant' }];

const COMPANY_TYPES = [
  { label: 'Start Up', value: 'startup' },
  { label: 'SSII', value: 'ssii' },
  { label: 'Big', value: 'big' },
];
export const COMPANY_SIZES = [..._.map(_.range(1, 500), (value) => ({ value, label: `${value} employés` }))];

const LANGUAGE_LEVEL_OPTIONS = [
  { label: 'Fluent', value: 'fluent' },
  { label: 'Intermediate', value: 'intermediate' },
  { label: 'Basics', value: 'basics' },
];

const loadLanguages = async () => {
  const data = (await axios.get(`${baseUrl}/languages`)).data;
  const languages = _.map(data, ({ name, code }) => ({ label: name, value: code }));
  const filteredLanguages = _.filter(languages, (lang) => appLanguages.indexOf(lang.value) >= 0);
  return filteredLanguages;
};

// MAIN COMPONENTS

class WithLoading extends React.Component {
  state = {
    options: [],
  };
  componentDidMount = async () => {
    const { loadOptions } = this.props;
    try {
      const options = await loadOptions();
      console.log('loaded options', options);
      this.setState({ options });
    } catch (e) {
      console.log('OkTargetEditorWithLoading failed to load options ', e.message);
    }
  };
  render() {
    const { BaseComponent } = this.props;
    return <BaseComponent {...this.props} options={this.state.options} />;
  }
}
const okTarget = (Component) => {
  return (props) => (
    <Grid columns={2}>
      <Grid.Column>
        <Form.Field>
          <label>Target</label>
          <Component {...props} field='target' />
        </Form.Field>
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <label>Ok</label>
          <Component {...props} field='okWith' />
        </Form.Field>
      </Grid.Column>
    </Grid>
  );
};

const OkTargetSelectSkills = enhance(() => (
  <WithLoading BaseComponent={okTarget(Select)} loadOptions={loadSkills} multi />
));

const OkTargetSelectJobs = enhance(() => (
  <WithLoading BaseComponent={okTarget(Select)} loadOptions={loadJobTitles} multi />
));

const OkTargetSelectLocations = enhance(() => (
  <WithLoading BaseComponent={OkTargetSelectLocationsRaw} loadOptions={loadLocations} />
));

const OkTargetSelectResponsibilities = enhance(() => (
  <WithLoading BaseComponent={okTarget(Select)} loadOptions={loadResponsibilities} multi />
));

const OkTargetSelectContracts = enhance(() => {
  const OkTargetSelect = okTarget(Select);
  return <OkTargetSelect options={CONTRACT_TYPES} multi />;
});

const OkTargetSelectSalary = enhance(() => {
  const OkTargetSelect = okTarget(SelectInt);
  return <OkTargetSelect min={0} max={200} />;
});

const SelectLocation = enhance((props) => {
  return (
    <Grid columns={2}>
      <Grid.Column>
        <Select field='remoteWish' options={REMOTE_OPTIONS} defaultValue='full-time' />
      </Grid.Column>
      <Grid.Column>
        <Select field='location' options={props.options} />
      </Grid.Column>
    </Grid>
  );
});

const OkTargetSelectLocationsRaw = (props) => (
  <Grid columns={2}>
    <Grid.Row>
      <Grid.Column>Open to remote ?</Grid.Column>
      <Grid.Column>
        <Checkbox field='openToRemote' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Form.Field>
          <label>Target</label>
          <List {...props} field='target' component={SelectLocation} />
        </Form.Field>
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <label>Ok</label>
          <List {...props} field='okWith' component={SelectLocation} />
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

// EXTRA CRITERIA

const SelectCompanyType = (props) => <Select {...props} options={COMPANY_TYPES} />;

const SelectCompanySizeRange = (props) => <Range component={SelectCompanySize} field='companySize' />;

const SelectCompanySize = (props) => <Select {...props} options={COMPANY_SIZES} placeholder='Pas de limite' />;

const SelectModalities = (props) => <Select {...props} options={MODALITIES} />;

const SelectIndustriesAsync = (props) => (
  <Select {...props} async={true} loadOptions={toFormatedOptions(loadIndustries)} />
);

const SelectJobsAsync = (props) => <Select {...props} async={true} loadOptions={toFormatedOptions(loadJobTitles)} />;

const SelectSkillsAsync = (props) => <Select {...props} async={true} loadOptions={toFormatedOptions(loadSkills)} />;

const IMPORTANCE_OPTIONS = [
  { value: 'required', label: 'Required' },
  { value: 'important', label: 'Important' },
  { value: 'bonus', label: 'Bonus' },
  { value: 'bigBonus', label: 'Big Bonus' },
];

const withImportance = (Component) => {
  return enhance((props) => (
    <Grid columns={2}>
      <Grid.Column width={4} textAlign='right'>
        <Select field='importance' options={IMPORTANCE_OPTIONS} defaultValue='required' />
      </Grid.Column>
      <Grid.Column width={12}>
        <Component field='criterion' />
      </Grid.Column>
    </Grid>
  ));
};

const ExtraCriteria = withImportance(
  customOperator(
    {
      not: { label: '# NOT', children: 1 },
      and: { label: '# AND', children: 'n' },
      or: { label: '# OR', children: 'n' },
      companyType: {
        label: 'Company Type',
        children: 1,
        component: SelectCompanyType,
        nested: true,
      },
      companySize: {
        label: 'Company Size',
        children: 1,
        component: SelectCompanySizeRange,
        nested: true,
      },
      jobModality: {
        label: 'Modalities',
        children: 1,
        component: SelectModalities,
        nested: true,
      },
      industry: {
        label: 'Industries',
        children: 1,
        component: SelectIndustriesAsync,
        nested: true,
      },
      skillsAnys: {
        label: 'Has Skill',
        children: 1,
        component: SelectSkillsAsync,
      },
      skillsRequired: {
        label: 'Has Required Skill',
        children: 1,
        component: SelectSkillsAsync,
      },
      skillsImportant: {
        label: 'Has Important Skill',
        children: 1,
        component: SelectSkillsAsync,
      },
      skillsBonus: {
        label: 'Has Bonus Skill',
        children: 1,
        component: SelectSkillsAsync,
      },
      remote: { label: 'Accepts Remote' },
      jobTitle: { label: 'Has Job Title', children: 1, component: SelectJobsAsync },
      matchAny: { label: 'Match Anywhere', children: 1, component: Input },
    },
    false,
  ),
);

// WISHES

export const WishesEditor = enhance((props) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={4} textAlign='right'>
        <h3>Job</h3>
      </Grid.Column>
      <Grid.Column width={12}>
        <OkTargetSelectJobs field='jobs' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={4} textAlign='right'>
        <h3>Skills</h3>
      </Grid.Column>
      <Grid.Column width={12}>
        <OkTargetSelectSkills field='skills' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={4} textAlign='right'>
        <h3>Locations</h3>
      </Grid.Column>
      <Grid.Column width={12}>
        <OkTargetSelectLocations field='locations' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={4} textAlign='right'>
        <h3>Responsibilities</h3>
      </Grid.Column>
      <Grid.Column width={12}>
        <OkTargetSelectResponsibilities field='responsibilities' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={4} textAlign='right'>
        <h3>Contract</h3>
      </Grid.Column>
      <Grid.Column width={12}>
        <OkTargetSelectContracts field='contracts' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={4} textAlign='right'>
        <h3>Annual Salary (k€)</h3>
      </Grid.Column>
      <Grid.Column width={12}>
        <OkTargetSelectSalary field='salary' />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <h3>Extra Criteria</h3>
        <List field='extraCriteria' component={ExtraCriteria} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
));

const SelectLanguageLevel = enhance((props) => (
  <Grid columns={2}>
    <Grid.Column>
      <Form.Field>
        <Select field='id' async={true} loadOptions={toFormatedOptions(loadLanguages)} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column>
      <Form.Field>
        <Select field='level' options={LANGUAGE_LEVEL_OPTIONS} />
      </Form.Field>
    </Grid.Column>
  </Grid>
));

const SelectLanguages = (props) => <List component={SelectLanguageLevel} {...props} />;

const SelectAvailability = enhance((props) => (
  <Grid columns={2}>
    <Grid.Column>
      <Form.Field>
        <label>Start Date</label>
        <Input type='date' field='targetDate' />
      </Form.Field>
    </Grid.Column>
    <Grid.Column>
      <Form.Field>
        <label>Transition Delay (months)</label>
        <SelectInt min={0} max={12} field='transitionDelayInMonths' />
      </Form.Field>
    </Grid.Column>
  </Grid>
));

const SEEKING_LEVEL_OPTIONS = [
  { label: 'Open', value: 'open' },
  //{ label: 'Seeking', value: 'seeking' },
  { label: 'Seeking interviewed', value: 'seeking-interviewed' },
  { label: 'Not looking', value: 'not-looking' },
];

const SelectSeekingLevel = (props) => <Select options={SEEKING_LEVEL_OPTIONS} {...props} />;

export const SelectExperienceYearsAtDate = enhance((props) => {
  const options = _.map(_.range(-2, 100, 0.5), (years) => ({ label: years, value: years }));
  return (
    <Grid columns={2}>
      <Grid.Column>
        <Select options={options} field='years' />
      </Grid.Column>
      <Grid.Column>
        <Input type='date' field='date' />
      </Grid.Column>
    </Grid>
  );
});

const getSchoolTypes = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/workplaces/listSchoolTypes`);
    return { options: data.error ? [] : data };
  } catch (e) {
    alert(e);
  }
};
const SelectSchoolFields = enhance((props) => {
  return <div>
    <Select placeholder="School Type" field='schoolType' async loadOptions={getSchoolTypes} labelKey='name' valueKey='id' />
  </div>
})
const TextAnnotationEditor = enhance((props) => {
  const { value, textOptions } = props;
  const type = value ? value.get('type') : '';
  return (
    <Grid columns={3}>
      <Grid.Column width={4}>
        <Select field='type' default='school' options={[{ label: 'School', value: 'school' }]} />
      </Grid.Column>
      <Grid.Column width={6}>
        <Select field='text' options={textOptions} />
      </Grid.Column>
      <Grid.Column width={6}>
        {(type == 'school') ? (
          <SelectSchoolFields field='fields' />
        ) : null}
      </Grid.Column>
    </Grid>
  )
})

export const HiresweetDataForm = ({textOptions}) => (
  <div>
    <Divider horizontal>
      <Header as='h4'>
        <Icon name='heart' />
        Wishes
      </Header>
    </Divider>
    <WishesEditor field='wishes' />
    <Divider horizontal>
      <Header as='h4'>
        <Icon name='info' />
        Other Info
      </Header>
    </Divider>
    <Grid>
      <Grid.Row>
        <Grid.Column width={4} textAlign='right'>
          <h3>Languages</h3>
        </Grid.Column>
        <Grid.Column width={12}>
          <SelectLanguages field='languages' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4} textAlign='right'>
          <h3>Availability</h3>
        </Grid.Column>
        <Grid.Column width={12}>
          <SelectAvailability field='availability' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4} textAlign='right'>
          <h3>Seeking Level</h3>
        </Grid.Column>
        <Grid.Column width={12}>
          <SelectSeekingLevel field='seekingLevel' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4} textAlign='right'>
          <h3>Experience Years At Date</h3>
        </Grid.Column>
        <Grid.Column width={12}>
          <SelectExperienceYearsAtDate field='experienceYearsAtDate' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4} textAlign='right'>
          <h3>Annotations</h3>
        </Grid.Column>
        <Grid.Column width={12}>
          <List field='textAnnotations' component={TextAnnotationEditor} textOptions={textOptions} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4} textAlign='right'>
          <h3>Extra Info</h3>
        </Grid.Column>
        <Grid.Column width={12}>
          <Textarea field='extraInfo' placeholder='Extra Info' rows='30' cols='60' />;
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

class WatchHiresweetData extends Component {
  handleChange = (value) => {
    this.setState({ value });
  };

  handleSave = async () => {
    try {
      await this.props.onSubmit(formHiresweetDataToSheetHiresweetData(this.state.value));
      alert('Saved.');
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    const { hiresweetData, noEdit, textOptions } = this.props;
    const formattedHiresweetData = sheetHiresweetDataToFormHiresweetData(hiresweetData || {});
    return (
      <div>
        {noEdit ? null : (
          <Button onClick={() => this.handleSave()} color='green'>
            <Icon name='save' /> Save
          </Button>
        )}
        <SweetForm initialValues={formattedHiresweetData} onChange={noEdit ? () => { } : this.handleChange}>
          <HiresweetDataForm textOptions={textOptions} />
        </SweetForm>
      </div>
    );
  }
}

export default WatchHiresweetData;
