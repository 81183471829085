import _ from 'underscore';

const getStringFromEnrichedTextAndStack = (enrichedTextAndStack) => ((enrichedTextAndStack || {}).content || {}).text;

const getStringFromEnrichedText = (enrichedText) => (enrichedText || {}).text;

const getStringFromLocation = (location) => ((location || {}).name || {}).default;

const getStackV4FromStackV5 = (stackV5) => {
  return _.map(stackV5, (stackItem) => {
    const detailsURL = ((stackItem || {}).details || {}).url;
    return {
      ..._.omit(stackItem, ['detailsURL']),
      ...(detailsURL !== undefined && { detailsURL }),
    };
  });
};

const getSummaryFromEnrichedTextAndStack = (summaryV5) => {
  const content = getStringFromEnrichedText((summaryV5 || {}).content);
  const stack = getStackV4FromStackV5((summaryV5 || {}).stack);
  return {
    ...(content !== undefined && { content }),
    ...(!_.isEmpty(stack) && { stack }),
  };
};

const getTranslatableTextFromEnrichedTranslatableText = (enrichedTranslatableText) => {
  const type = (enrichedTranslatableText || {}).type
  const defaultTranslation = getStringFromEnrichedText((enrichedTranslatableText || {}).default);
  const fr = getStringFromEnrichedText((enrichedTranslatableText || {}).fr);
  const en = getStringFromEnrichedText((enrichedTranslatableText || {}).en);
  return {
    ...(type !== undefined && { type }),
    ...(defaultTranslation !== undefined && { default: defaultTranslation }),
    ...(fr !== undefined && { fr }),
    ...(en !== undefined && { en }),
  };
};
const getWorkplaceDataV4FromWorkplaceDataV5 = (workplaceDataV5) => {
  const stack = getStackV4FromStackV5(workplaceDataV5.stack);
  const mainLocationName = ((workplaceDataV5 || {}).mainLocation || {}).name;
  const description = getTranslatableTextFromEnrichedTranslatableText(workplaceDataV5.description);
  return {
    ..._.omit(workplaceDataV5, ['stack', 'mainLocationName', 'description']),
    ...(!_.isEmpty(stack) && { stack }),
    ...((mainLocationName || {}).default !== undefined && {
      mainLocation: {
        name: mainLocationName,
      },
    }),
    ...(!_.isEmpty(description) && { description }),
  };
};
const getWorkplaceV4FromWorkplaceV5 = (workplaceV5) => {
  const data = getWorkplaceDataV4FromWorkplaceDataV5((workplaceV5 || {}).data || {});
  return {
    ..._.omit(workplaceV5, ['data']),
    ...(!_.isEmpty(data) && { data }),
  };
};

const getExperienceV4fromExperienceV5 = (experienceV5) => {
  const location = getStringFromLocation((experienceV5 || {}).location);
  const title = getStringFromEnrichedText((experienceV5 || {}).title);
  const description = getStringFromEnrichedText((experienceV5 || {}).description);
  const stack = getStackV4FromStackV5((experienceV5 || {}).stack);
  const workplace = getWorkplaceV4FromWorkplaceV5((experienceV5 || {}).workplace);
  return {
    ..._.omit(experienceV5, ['location', 'title', 'description', 'stack', 'workplace']),
    ...(location !== undefined && { location }),
    ...(title !== undefined && { title }),
    ...(description !== undefined && { description }),
    ...(!_.isEmpty(stack) && { stack }),
    ...(!_.isEmpty(workplace) && { workplace }),
  };
};

const getEducationItemFromProfileV5EducationItem = (educationV5) => {
  const degree = getStringFromEnrichedText((educationV5 || {}).degree);
  const description = getStringFromEnrichedText((educationV5 || {}).description);
  const stack = getStackV4FromStackV5((educationV5 || {}).stack);
  const workplace = getWorkplaceV4FromWorkplaceV5((educationV5 || {}).workplace);
  return {
    ..._.omit(educationV5, ['degree', 'description', 'stack', 'workplace']),
    ...(degree !== undefined && { degree }),
    ...(description !== undefined && { description }),
    ...(!_.isEmpty(stack) && { stack }),
    ...(!_.isEmpty(workplace) && { workplace }),
  };
};

// Look for an educationItem with isMain true
const getMainEducationFromProfileV5 = (profileEducationV5) => {
  const mainEducationItemV5 = _.findWhere(profileEducationV5, { isMain: true });
  if (_.isEmpty(mainEducationItemV5)) {
    return null;
  }
  const workplace = getWorkplaceV4FromWorkplaceV5(mainEducationItemV5.workplace);
  return {
    ...(mainEducationItemV5.schoolName && { schoolName: mainEducationItemV5.schoolName }),
    ...(mainEducationItemV5.startDate && { startDate: mainEducationItemV5.startDate }),
    ...(mainEducationItemV5.endDate && { endDate: mainEducationItemV5.endDate }),
    ...(!_.isEmpty(workplace) && { workplace }),
  };
};

const getSourceDataFromProfileV5 = (sourceDataV5) => {
  if (_.isEmpty(sourceDataV5)) {
    return null;
  }
  const repositories = _.map(_.first(((sourceDataV5 || {}).github || {}).repositories, 6), (repository) => {
    const name = getStringFromEnrichedText(repository.name);
    const fullname = getStringFromEnrichedText(repository.fullname);
    const description = getStringFromEnrichedText(repository.description);
    return {
      ..._.omit(repository, ['name', 'fullname', 'description']),
      ...(name !== undefined && { name }),
      ...(fullname !== undefined && { fullname }),
      ...(description !== undefined && { description }),
    };
  });
  const github = {
    ..._.omit(sourceDataV5.github, ['repositories']),
    ...(!_.isEmpty(repositories) && { repositories }),
  };
  return {
    ..._.omit(sourceDataV5, ['github']),
    ...(!_.isEmpty(github) && { github }),
  };
};

export const getProfileV4fromV5 = (profileV5) => {
  if (!profileV5) {
    return null;
  }

  const headline = getStringFromEnrichedTextAndStack(profileV5.headline);
  const location = getStringFromLocation(profileV5.location);

  const summary = getSummaryFromEnrichedTextAndStack(profileV5.summary);
  const experiences = _.map(profileV5.experiences, (experienceV5) => getExperienceV4fromExperienceV5(experienceV5));
  const education = _.map(profileV5.education, (educationV5) =>
    getEducationItemFromProfileV5EducationItem(educationV5),
  );
  const mainEducation = getMainEducationFromProfileV5(profileV5.education);
  const sourceData = getSourceDataFromProfileV5(profileV5.sourceData);

  const sanitizableProfileV4 = {
    ..._.omit(profileV5, ['headline', 'location', 'summary', 'experiences', 'education', 'mainEducation', 'sourceData']),
    ...(headline !== undefined && { headline }),
    ...(location !== undefined && { location }),
    ...(!_.isEmpty(summary) && { summary }),
    ...(!_.isEmpty(experiences) && { experiences }),
    ...(!_.isEmpty(education) && { education }),
    ...(!_.isEmpty(mainEducation) && { mainEducation }),
    ...(!_.isEmpty(sourceData) && { sourceData }),
  };
  return sanitizableProfileV4;
};

export default getProfileV4fromV5;
