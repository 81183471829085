import React from 'react';
import _ from 'underscore';
import {
  Accordion,
  Button,
  Checkbox,
  Confirm,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Label,
  Loader,
  Message,
  Progress,
  Statistic,
  Segment,
} from 'semantic-ui-react';

import orderTypes from './orderTypes';
import OrderMonitoring from './OrderMonitoring';
import sourceIcons from './sourceIcons';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteOpen: false,
      confirmFlushOpen: false,
      confirmUnlockOpen: false,
      editName: false,
      newId: '',
      isActive: props.isNew,
    };

    this.onEnabledChange = this.onEnabledChange.bind(this);
    this.onPriorityChange = this.onPriorityChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onObjectiveChange = this.onObjectiveChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleNameEdit = this.handleNameEdit.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onShowFutureTask = this.onShowFutureTask.bind(this);
    this.onShowPreviousTask = this.onShowPreviousTask.bind(this);
  }

  onEnabledChange(e, data) {
    this.props.onEnabledChange(data.checked);
  }

  onPriorityChange(e) {
    this.props.onPriorityChange(e.target.value);
  }

  onTypeChange(e, data) {
    this.props.onTypeChange(data.value);
  }

  onObjectiveChange(e, data) {
    this.props.onObjectiveChange(data.value);
  }

  onShowPreviousTask() {
    this.props.onShowPreviousTask();
  }

  onShowFutureTask() {
    this.props.onShowFutureTask();
  }

  handleNameEdit(e) {
    this.setState({ newName: e.target.value });
    if (this.props.isNew) {
      this.setState({ newId: this.toId(e.target.value) });
    }
  }

  handleToggle(e) {
    this.setState((prevState) => ({ isActive: !prevState.isActive }));
  }

  onSave() {
    if (this.props.type) {
      this.props.onSave();
    } else {
      this.props.showMessage({ message: 'You need to specify an order type', severity: 'warning' }, 5);
    }
  }

  toId(name) {
    return name.toLowerCase().replace(/[\W_]+/g, '_');
  }

  render() {
    let SettingsComponent;
    if (this.props.type) {
      try {
        SettingsComponent = orderTypes[this.props.type].settingsComponent;
      } catch (e) {
        this.props.message.status = 'error';
        this.props.message.message = `Order type ${this.props.type} is not defined`;
        SettingsComponent = () => <div />;
      }
    } else {
      SettingsComponent = () => <div />;
    }
    return (
      <Accordion fluid styled>
        <Dimmer inverted active={this.props.loading}>
          <Loader inverted />
        </Dimmer>
        <Accordion.Title active={this.state.isActive || this.props.isNew} style={{ padding: 10 }}>
          <Header>
            <span style={{ float: 'right', marginRight: 20 }}>
              {this.props.objective && (
                <Progress
                  indicating
                  disabled={!this.props.enabled}
                  style={{ display: 'inline-block', width: 200, margin: 0, marginRight: 10 }}
                  value={this.props.nbSuccessfulTasks}
                  total={this.props.objective}
                  warning={(this.props.message || {}).status === 'warning'}
                  error={(this.props.message || {}).status === 'error'}
                  success={
                    (this.props.message || {}).status === 'success' || (this.props.message || {}).status === 'positive'
                  }
                />
              )}
              <Statistic color={this.props.enabled ? 'green' : 'grey'} size='tiny' style={{ marginRight: 0 }}>
                <Statistic.Value>{this.props.nbSuccessfulTasks}</Statistic.Value>
              </Statistic>{' '}
              {this.props.objective && (
                <Statistic size='tiny' color='grey' style={{ marginLeft: 0 }}>
                  <Statistic.Value>/{this.props.objective}</Statistic.Value>
                </Statistic>
              )}
              {this.props.message && (
                <Message
                  style={{ padding: 5, marginRight: 10, marginLeft: 5 }}
                  compact
                  warning={this.props.message.status === 'warning'}
                  info={this.props.message.status === 'info'}
                  positive={this.props.message.status === 'positive'}
                  error={this.props.message.status === 'error'}
                  success={this.props.message.status === 'success'}
                >
                  {this.props.message.message}
                </Message>
              )}
              <strong style={{ color: this.props.enabled ? 'black' : 'grey' }}>Priority</strong>{' '}
              <Statistic
                color={this.props.enabled ? 'black' : 'grey'}
                size='tiny'
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <Statistic.Value>{this.props.priority}</Statistic.Value>
              </Statistic>{' '}
              <input type='range' min={0} max={10} value={this.props.priority} onChange={this.onPriorityChange} />{' '}
              <Checkbox
                style={{ marginLeft: 5, marginRight: 50 }}
                toggle
                onChange={this.onEnabledChange}
                checked={this.props.enabled === true}
              />
              <Button icon negative onClick={() => this.setState({ confirmDeleteOpen: true })}>
                <Icon name='trash' />
              </Button>
              <Button icon positive disabled={!this.props.changed} onClick={this.onSave}>
                <Icon name='save' />
              </Button>
              <Confirm
                open={this.state.confirmDeleteOpen}
                onCancel={() => this.setState({ confirmDeleteOpen: false })}
                onConfirm={() => {
                  this.setState({ confirmDeleteOpen: false });
                  this.props.onDelete();
                }}
              />
            </span>
            <Icon
              name={this.state.isActive || this.props.isNew ? 'angle down' : 'angle right'}
              onClick={this.handleToggle}
              size='small'
            />
            <Header.Content>
              {this.state.editName ? (
                <span>
                  <Input size='mini' value={this.state.newName} onChange={this.handleNameEdit} />
                  <Button
                    basic
                    positive
                    size='mini'
                    icon='check'
                    onClick={() => {
                      this.setState({ editName: false });
                      this.props.onNameChange(this.state.newName);
                      if (this.props.isNew) {
                        this.props.onIDChange(this.state.newId);
                      }
                    }}
                  />
                </span>
              ) : (
                <span>
                  {this.props.name}{' '}
                  <Button
                    basic
                    icon='pencil'
                    onClick={() => this.setState({ editName: true, newName: this.props.name })}
                  />
                </span>
              )}
            </Header.Content>
          </Header>
        </Accordion.Title>
        {(this.state.isActive || this.props.isNew) && (
          <Accordion.Content active={true}>
            <Grid divided relaxed style={{ marginTop: 5 }}>
              <Grid.Row>
                <Grid.Column width={4}>
                  ID: {this.state.newId || this.props.id}
                  <br />
                  <br />
                  <Dropdown
                    search
                    disabled={!this.props.isNew}
                    placeholder='Select order type'
                    options={_.map(orderTypes, (type, id) => ({
                      key: id,
                      value: id,
                      text: (
                        <div>
                          {type.name}{' '}
                          {_.map(type.sources, (source) => (
                            <Icon key={source} name={sourceIcons[source].icon} color={sourceIcons[source].color} />
                          ))}
                          {_.map(type.tags, (tag) => (
                            <Label key={tag} size='tiny'>
                              {tag}
                            </Label>
                          ))}
                        </div>
                      ),
                    }))}
                    value={this.props.type}
                    onChange={this.onTypeChange}
                  />
                  <div>
                    <strong style={{ marginRight: 10 }}>Objectif</strong>
                    <Input onChange={this.onObjectiveChange} value={this.props.objective} />
                  </div>
                  <Item>
                    <SettingsComponent
                      options={this.props.settingsOptions}
                      settings={this.props.settings}
                      onSettingsChange={this.props.onSettingsChange}
                    />
                  </Item>
                </Grid.Column>
                <Grid.Column width={12}>
                  <OrderMonitoring id={this.props.id} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Item style={{ float: 'right' }}>
                    <Button
                      icon
                      labelPosition='left'
                      basic
                      color='grey'
                      onClick={() =>
                        this.props.archived
                          ? this.setState({ confirmUnarchiveOpen: true })
                          : this.setState({ confirmArchiveOpen: true })
                      }
                    >
                      <Icon name='archive' />
                      {this.props.archived ? 'Unarchive order' : 'Archive order'}
                    </Button>
                    <Button icon labelPosition='left' basic color='purple' onClick={() => this.onShowPreviousTask()}>
                      <Icon name='play' />
                      Sample previous task
                    </Button>
                    <Button icon labelPosition='left' basic color='blue' onClick={() => this.onShowFutureTask()}>
                      <Icon name='play' />
                      Sample future task
                    </Button>
                    <Button
                      icon
                      labelPosition='left'
                      basic
                      color='orange'
                      onClick={() => this.setState({ confirmFlushOpen: true })}
                    >
                      <Icon name='eraser' />
                      Flush queue
                    </Button>

                    <Confirm
                      open={this.state.confirmFlushOpen}
                      onCancel={() => this.setState({ confirmFlushOpen: false })}
                      onConfirm={() => {
                        this.setState({ confirmFlushOpen: false });
                        this.props.onFlush();
                      }}
                    />

                    <Button
                      icon
                      labelPosition='left'
                      basic
                      color='green'
                      onClick={() => this.setState({ confirmUnlockOpen: true })}
                    >
                      <Icon name='lock open' />
                      Unlock Order
                    </Button>

                    <Confirm
                      open={this.state.confirmUnlockOpen}
                      onCancel={() => this.setState({ confirmUnlockOpen: false })}
                      onConfirm={() => {
                        this.setState({ confirmUnlockOpen: false });
                        this.props.onUnlock();
                      }}
                    />

                    <Confirm
                      open={this.state.confirmArchiveOpen}
                      onCancel={() => this.setState({ confirmArchiveOpen: false })}
                      onConfirm={() => {
                        this.setState({ confirmArchiveOpen: false });
                        this.props.onArchive();
                      }}
                    />
                    <Confirm
                      open={this.state.confirmUnarchiveOpen}
                      onCancel={() => this.setState({ confirmUnarchiveOpen: false })}
                      onConfirm={() => {
                        this.setState({ confirmUnarchiveOpen: false });
                        this.props.onUnarchive();
                      }}
                    />
                  </Item>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        )}
      </Accordion>
    );
  }
}

export default Order;
