import React from 'react';
import _ from 'underscore';
import { Popup } from 'semantic-ui-react';
import MiniButton from './MiniButton';

const EmailFailButton = ({ setEmailAsNotFound, emailRetrievalFails, process }) => {
  const onClick = async () => {
    await setEmailAsNotFound();
    process('backlog;no_email;fail');
  };
  return (
    <div>
      {_.isEmpty(emailRetrievalFails) ? (
        <MiniButton onClick={onClick} text={'NoMail'} />
      ) : (
        <Popup
          on='hover'
          inverted
          trigger={
            <div>
              <MiniButton onClick={onClick} text={'NoMail'} color={'teal'} />
            </div>
          }
        >
          <Popup.Content>
            <div>
              <div>Failed {emailRetrievalFails.length} times</div>
              <div>
                {_.map(
                  _.countBy(emailRetrievalFails, (fail) => fail.username),
                  (count, user) => `${user} (${count})`,
                ).join(', ')}
              </div>
            </div>
          </Popup.Content>
        </Popup>
      )}
    </div>
  );
};

const EmailNoTimeButton = ({ process }) => {
  const onClick = async () => {
    process('backlog;no_email;no_time');
  };
  return <MiniButton onClick={onClick} text={'NoTime'} />;
};

export { EmailFailButton, EmailNoTimeButton };
