import React from 'react';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import _ from 'underscore';

import { Button, Checkbox, Label, Form, Grid, Table } from 'semantic-ui-react';
import { List, Select, SweetForm, enhance } from 'sweetform';

class FindSkillEntities extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSelected: {} };
  }

  componentDidMount() {
    this.getSelectedCandidateSkillEntities();
    this.getNormalizedEntityNames();
  }

  getSelectedCandidateSkillEntities = async () => {
    const url = baseUrl + '/extendSkills/getSelectedCandidateSkillEntities';
    const preSelectedCandidateSkillEntities = (await axios.get(url)).data;
    const preSelectedCandidateSkillEntityIds = _.map(preSelectedCandidateSkillEntities, (item) => item.entityId);
    this.setState({ preSelectedCandidateSkillEntityIds });
  };

  getNormalizedEntityNames = async () => {
    const { profilesCollectionId } = this.props;
    const url = baseUrl + '/extendSkills/getNormalizedEntityNames/' + profilesCollectionId;
    const result = (await axios.get(url)).data;
    if (!result.success) {
      alert(result.message);
      return;
    }
    const { normalizedEntityNames } = result;
    this.setState({ normalizedEntityNames });
  };

  handleChange(params) {
    this.setState({ baseSkills: params.baseSkills || [] });
  }

  findCandidateSkillEntities = async () => {
    this.setState({ computingSkillEntities: true });
    const { baseSkills } = this.state || {};
    const { profilesCollectionId } = this.props;
    const params = {
      profilesCollectionId: profilesCollectionId,
      baseSkills,
    };

    const url = baseUrl + '/extendSkills/findCandidateSkillEntities';
    const result = (await axios.post(url, params)).data;
    if (!result.success) {
      alert(result.message);
    }
    const candidateSkillEntities = result.candidateSkillEntities;
    this.setState({ candidateSkillEntities, computingSkillEntities: false });
  };

  handleSelectSkillEntity = ({ entityId }) => {
    this.setState({
      isSelected: {
        ...this.state.isSelected,
        [entityId]: !this.state.isSelected[entityId],
      },
    });
  };

  renderCell = (name, key, entity) => {
    const { isSelected } = this.state || {};
    const entityId = entity.normalizedName;
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Select' ? (
          <Checkbox checked={isSelected[entityId]} onChange={() => this.handleSelectSkillEntity({ entityId })} />
        ) : name == 'Normalized Name' ? (
          entityId
        ) : name == 'Raw Names' ? (
          _.map((entity || {}).rawNames || [], (rawName) => {
            return <Label>{rawName.text}</Label>;
          })
        ) : name == 'Occurrence' ? (
          entity.occurrence
        ) : null}
      </Table.Cell>
    );
  };

  addSelectedCandidateSkillEntities = async () => {
    const { isSelected, candidateSkillEntities } = this.state || {};
    const selectedCandidateSkillEntityIds = _.filter(
      candidateSkillEntities,
      (entity) => isSelected[entity.normalizedName],
    );
    const selectedCandidateSkillEntities = _.map(selectedCandidateSkillEntityIds, (entity) => ({
      entityId: entity.normalizedName,
      patterns: _.map(entity.rawNames || [], (rawName) => ({ text: rawName.text })),
    }));
    const params = {
      candidateSkillEntities: selectedCandidateSkillEntities,
    };
    const url = baseUrl + '/extendSkills/addSelectedCandidateSkillEntities';
    const result = (await axios.post(url, params)).data;
    if (!result.success) {
      alert(result.message);
    }
    this.getSelectedCandidateSkillEntities();
  };

  getItemColor = (entityId) => {
    const { preSelectedCandidateSkillEntityIds } = this.state || {};
    const color = preSelectedCandidateSkillEntityIds.includes(entityId) ? 'orange' : 'white';
    return color;
  };

  render() {
    const { candidateSkillEntities, computingSkillEntities, normalizedEntityNames } = this.state || {};

    const columnNames = ['Select', 'Normalized Name', 'Raw Names', 'Occurrence'];
    const computeSkillEntityColor = _.isUndefined(computingSkillEntities)
      ? 'green'
      : computingSkillEntities
      ? 'grey'
      : 'green';

    const normalizedEntityNameOptions = _.map(normalizedEntityNames, (name) => ({
      label: name,
      value: name,
    }));

    const SelectNormalizedEntity = enhance((props) => (
      <Select searchable field='id' options={normalizedEntityNameOptions} />
    ));

    return (
      <Grid>
        <Grid.Row>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Base Skills</label>
              <List field='baseSkills' component={SelectNormalizedEntity} />
            </Form.Field>
          </SweetForm>
        </Grid.Row>
        <Grid.Row>
          <Button onClick={() => this.findCandidateSkillEntities()} color={computeSkillEntityColor}>
            Compute Candidate Skills
          </Button>
          <Button onClick={() => this.addSelectedCandidateSkillEntities()} color='green'>
            Add Selected Entity In Mongo
          </Button>
        </Grid.Row>
        <Grid.Row>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Select</Table.HeaderCell>
              <Table.HeaderCell>Normalized Name</Table.HeaderCell>
              <Table.HeaderCell>Raw Names</Table.HeaderCell>
              <Table.HeaderCell>Occurrence</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {(candidateSkillEntities || []).map((entity, key) => {
                return (
                  <Table.Row key={key} style={{ backgroundColor: this.getItemColor(entity.normalizedName) }}>
                    {columnNames.map((name, index2) => {
                      return this.renderCell(name, index2 + key, entity);
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
    );
  }
}

export default FindSkillEntities;
