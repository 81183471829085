import React from 'react';
import humanize from 'humanize-duration';

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      increment: 0,
    };

    this.tick = this.tick.bind(this);
  }

  tick() {
    this.setState((prevState) => ({
      increment: prevState.increment + 1,
      ...prevState,
    }));
  }

  componentDidMount() {
    this.setState({
      increment: 0,
      ticker: setInterval(this.tick, 1000),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.ticker);
  }

  render() {
    return (
      <span>
        {humanize(Date.now() - this.props.time + this.state.increment, {
          round: true,
        })}
      </span>
    );
  }
}
