import React from 'react';
import _ from 'underscore';
import { Grid, Header, Label, Icon } from 'semantic-ui-react';
import { List as SweetList, Select } from 'sweetform';
import FilterDetails from '../FilterDetails';

const FilterForm = ({ Filter }) => {
  return (
    <div>
      <SweetList field="filters" component={Filter} />
    </div>
  );
};

const SearchForm = ({
  filterDetails,
  filterDetailedMode,
  warnings,
  handleToggleFilterDetails,
  Filter,
  Scorer,
}) => {
  const columnWidths = filterDetailedMode ? [8, 4, 4] : [9, 5, 2];
  const nbResults = ((filterDetails || {}).details || {}).size;

  return (
    <div>
      <Grid>
        <Grid.Column width={columnWidths[0]}>
          <Header as="h2" dividing>
            <span>Filters </span>
            {_.map(warnings, (warning, index) => (
              <Label color="red" key={index}>
                {warning.message}
              </Label>
            ))}
          </Header>
          <FilterForm Filter={Filter} />
        </Grid.Column>
        <Grid.Column width={columnWidths[1]}>
          <Header as="h2" dividing>
            Scorer
          </Header>
          <Scorer field="scorer" />
        </Grid.Column>
        <Grid.Column width={columnWidths[2]}>
          {filterDetails && (
            <div>
              <div>
                <span>
                  {nbResults} result{nbResults !== 1 ? 's' : ''}{' '}
                </span>
                <Icon
                  link
                  name={filterDetailedMode ? 'minus' : 'plus'}
                  onClick={handleToggleFilterDetails}
                />
              </div>
              {filterDetailedMode && (
                <FilterDetails filterDetails={filterDetails} />
              )}
            </div>
          )}
          <Header as="h2" dividing>
            Output format
          </Header>
          <Select
            field="outputFormat"
            placeholder="outputFormat"
            options={[
              { value: 'idFields', label: 'idFields (default)' },
              { value: 'v4', label: 'v4' },
            ]}
            defaultValue={'idFields'}
          />
        </Grid.Column>
      </Grid>
      <div style={{ height: 200 }} />
    </div>
  );
};

export { SearchForm, FilterForm };
