import BarChartWidget from '../Widgets/BarChartWidget'; 
import TableWidget from '../Widgets/TableWidget'; 
import LineChartWidget from '../Widgets/LineChartWidget'; 
import React from 'react';


const GenericWidget = ({ widget }) => {
  if ((widget || {}).type === 'bar-chart') {
    return (
      <div>
        <h4>{widget.title}</h4>
        <BarChartWidget name={widget.name} data={widget.data} />
      </div>
    );
  }

  if ((widget || {}).type === 'table') {
    return (
      <div>
        <h4>{widget.title}</h4>
        <TableWidget name={widget.name} data={widget.data} />
      </div>
    );
  }

  if ((widget || {}).type === 'line-chart') {
    return (
      <div>
        <h4>{widget.title}</h4>
        <LineChartWidget name={widget.name} data={widget.data} />
      </div>
    );
  }

  return (
    <div>
      <h3>Error - not recognized : {(widget || {}).type}</h3>
    </div>
  );

}

export default GenericWidget;
