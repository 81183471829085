import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Modal, Header } from 'semantic-ui-react';
import baseUrl from '../../baseUrl';
import SegmentationEditor from './SegmentationEditor';
import SegmentationDisplayer from './SegmentationDisplayer';
import ItemsDisplayer from './ItemsDisplayer';

const replaceWithOfferIds = async ({ items }) => {
  const platformIds = _.pluck(items, "id");
  const { offerIdsFromPlatformIds } = (await axios.post(`${baseUrl}/offers/offerIdsFromPlatformIds`, { platformIds })).data;
  return _.map(items, (item) => ({
    ...item,
    id: offerIdsFromPlatformIds[item.id]
  }))
}

class TablesSegmentation extends Component {
  state = {
    segmentation: null,
    selectedItems: null,
  };

  onSubmit = async (payload) => {
    try {
      let { segmentation: candidatesSegmentation, success: s1, error: e1 } = (await axios.post(`${baseUrl}/watchTables/actions/segmentationFromCandidates`, payload)).data;
      console.log(payload)
      if (!s1) {
        throw Error(e1);
      }
      this.setState({ candidatesSegmentation, actionsName: (payload.actionFilter || {}).name });

      let { segmentation: jobsSegmentation, success: s2, error: e2 } = (await axios.post(`${baseUrl}/watchTables/actions/segmentationFromJobs`, payload)).data;
      if (!s2) {
        throw Error(e2);
      }
      this.setState({ jobsSegmentation });
    } catch (e) {
      return alert(JSON.stringify(e));
    }
  }

  onSelectItems = async (items, type) => {
    if (type == "offer") {
      items = await replaceWithOfferIds({ items });
    }
    this.setState({ selection: { items, type } });
  }

  onCloseSelection = () => {
    this.setState({ selection: null });
  }


  render() {

    const { candidatesSegmentation, jobsSegmentation, actionsName, selection } = this.state;
    console.log({ jobsSegmentation })
    return (
      <Grid>
        <Grid.Row>
          <SegmentationEditor onSubmit={this.onSubmit} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            {candidatesSegmentation && <SegmentationDisplayer actionsName={actionsName} title="Candidates" key={Math.random()} segmentation={candidatesSegmentation} onSelectItems={(items) => this.onSelectItems(items, "candidate")} />}
          </Grid.Column>
          <Grid.Column width={8}>
            {jobsSegmentation && <SegmentationDisplayer actionsName={actionsName} title="Jobs" key={Math.random()} segmentation={jobsSegmentation} onSelectItems={(items) => this.onSelectItems(items, "offer")} />}
          </Grid.Column>
          {selection && (
            <Modal
              onClose={() => this.onCloseSelection()}
              open
              closeIcon
            >
              <Modal.Content>
                <ItemsDisplayer key={Math.random()} items={selection.items} type={selection.type} actionsName={actionsName}/>
              </Modal.Content>
            </Modal >
          )}
        </Grid.Row>
      </Grid>
    );
  }
}
export default TablesSegmentation;
