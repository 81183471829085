import { actions } from '../reducers';
import { 
  computeGlobalHints,
  treatLoadError,
  loadFullVersion,
  getExtraDataFromOfferAndIdFields,
  getSweetynote
} from './utils';

  

const buildCacheMachine = () => {

  const isItemAlreadyAsked = {};
  let memoizedResults = [];

  const wasAlreadyAsked = (itemId) => {
    return !!(itemId && isItemAlreadyAsked[itemId]);
  }

  const markAsAsked = (itemId) => {
    if (itemId) {
      isItemAlreadyAsked[itemId] = true;
    }
  }

  const memoize = (itemId, result) => {
    memoizedResults.push({ itemId, result });
    const maxLength = 3;
    if (memoizedResults.length > maxLength) {
      memoizedResults = memoizedResults.slice(memoizedResults.length - maxLength);
    }
  }

  const getPrefetchedResult = (itemId) => {
    for (let i = 0; i < memoizedResults.length; i++) {
      if (memoizedResults[i].itemId === itemId) {
        return memoizedResults[i].result;
      }
    }
  }

  return { wasAlreadyAsked, markAsAsked, memoize, getPrefetchedResult };
}

const cacheMachine = buildCacheMachine();

const prefetchingLoadProfile = async ({
  dispatch,
  // time tracking
  tBeg,
  executionId,
  // base info
  input, 
  offer,
  //nth,
  step,
  // compute targets
  current,
  next,
  error,
  // to know if this is a prefetching call or not
  isPrefetching,
  noCache
}) => {

  if (error) {
    return treatLoadError({ dispatch, input, error });
  }

  const currentId = 'front_id_' + ('' + Math.random()).slice(2);
  if (current) {
    current.frontendId = currentId;
  }

  if (!current) {
    dispatch(actions.setPendingUpload(false));
    dispatch(actions.setProfiles(current, next));
    dispatch(actions.setEditMode('none'));
    return;
  }

  const itemId = current.id;
  if (isPrefetching) {
    if (!itemId) {
      return console.log('skip because no item id');
    }
    if (cacheMachine.wasAlreadyAsked(itemId)) {
      return console.log('skip because ' + itemId + ' already asked');     
    }
  }
  if (itemId) {
    cacheMachine.markAsAsked(itemId);
  }

  const rawPrefetchedResult = !isPrefetching && !noCache && cacheMachine.getPrefetchedResult(itemId);
  const prefetchedResult = rawPrefetchedResult && {
    ...rawPrefetchedResult,
    loadedProfile: {
      ...rawPrefetchedResult.loadedProfile,
      _id: current._id, 
      frontendId: current.frontendId
    }
  }

  if (prefetchedResult) {
    console.log('prefetched ' + itemId);
  }
  const fullUploadPromise = prefetchedResult ? (
    prefetchedResult
  ) : loadFullVersion({ current, executionId, offer });

  if (!prefetchedResult) {
    console.log('compute ' + current.id);
  }

  dispatch(actions.setPendingUpload(false));
  dispatch(actions.setProfiles(current, next));
  dispatch(actions.setEditMode('none'));

  const { loadedProfile, sweetynote } = await fullUploadPromise;
  if (isPrefetching && itemId) {
    cacheMachine.memoize(itemId, { loadedProfile, sweetynote });
  }
  if (sweetynote) {
    console.log('SSW', currentId, sweetynote);
    dispatch(actions.setSweetynote(currentId, sweetynote));
  }
  if (step) {
    dispatch(actions.setPendingUpload(true));
  }
  dispatch(actions.updateCurrentProfile(currentId, loadedProfile));

  const tEnd = Date.now();
  console.log('total load time', tEnd - tBeg, loadedProfile);
}

export { 
  computeGlobalHints, 
  getSweetynote, 
  getExtraDataFromOfferAndIdFields, 
  prefetchingLoadProfile 
};



