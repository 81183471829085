import React from 'react';
import { Image, Input, Grid, Loader } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../../baseUrl';

/*
   props: { 
     mode: 'sweethub' || 'sweetapp',
     onSelect : ({ id }) -> void
   }
 */

class WorkplaceSelector extends React.Component {
  state = {
    workplaces: [],
    selectedWorkplaceId: null,
    search: '',
  };

  componentDidMount() {
    this.loadWorkplaces();
    this.loadOffersCount();
  }

  loadWorkplaces = async () => {
    if (this.props.mode === 'sweetapp') {
      return alert('mode "sweetapp" not implemented');
    }
    if (this.props.mode === 'sweethub') {
      const { data } = await axios.get(`${baseUrl}/salesboard/workplaces`);
      this.setState({
        workplaces: data.workplaces,
      });
      return data.workplaces;
    }
  };

  loadOffersCount = async () => {
    if (this.props.mode === 'sweetapp') {
      return alert('mode "sweetapp" not implemented');
    }
    if (this.props.mode === 'sweethub') {
      try {
        const { data } = await axios.get(`${baseUrl}/salesboard/offersCountByWorkplace`);
        const offersCountByWorkplace = data.offersCountByWorkplace;
        this.setState({
          offersCount: offersCountByWorkplace,
        });
      } catch (e) {
        console.log('Failed loadOffersCount ', e.message);
      }
    }
  };

  handleChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  handleSelectWorkplace = async (workplace) => {
    this.setState({
      selectedWorkplaceId: workplace.id,
    });
    this.props.onSelect({
      id: workplace.id,
    });
  };

  render() {
    const { search, workplaces, offersCount, selectedWorkplaceId } = this.state;

    const indexOfSearch = ({ name }, search) => (name || '').toLowerCase().indexOf(search.toLowerCase());

    const filteredWorkplaces = !search
      ? _.filter(workplaces, ({ networkLevel }) => networkLevel === 0 || networkLevel === 1)
      : _.sortBy(_.filter(workplaces, (workplace) => indexOfSearch(workplace, search) >= 0), (workplace) =>
          indexOfSearch(workplace, search),
        );

    const isLoading = _.isEmpty(workplaces);

    return (
      <div>
        <div>
          <Input
            fluid
            type='text'
            field='search'
            onChange={({ target }) => {
              this.handleChangeSearch({ search: target.value });
            }}
          />
        </div>
        <div>
          {isLoading ? (
            <Loader active inline />
          ) : (
            <Grid style={{ padding: 0, margin: 0 }}>
              {filteredWorkplaces.map((workplace) => (
                <Grid.Row key={workplace.id} style={{ padding: 0, margin: 0 }}>
                  <Grid.Column
                    width={14}
                    onClick={() => this.handleSelectWorkplace(workplace)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        ...(selectedWorkplaceId === workplace.id && {
                          fontWeight: 'bold',
                          color: 'green',
                          fontSize: '13px',
                        }),
                      }}
                    >
                      {(workplace.name || workplace.id) +
                        ((offersCount[workplace.id] && ` (${offersCount[workplace.id]})`) || '')}
                    </span>
                  </Grid.Column>
                  <Grid.Column width={2} floated='right' textAlign='right'>
                    {workplace.platformId ? (
                      <a
                        href={`https://app.hiresweet.com/client/${workplace.platformId}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Image
                          src='/images/sweetapp-logo.png'
                          style={{ width: '16px', height: '16px', marginRight: '8px' }}
                        />
                      </a>
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default WorkplaceSelector;
