import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Label, Popup, Table } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import { CommunicationsSummary } from "./CommunicationLine.js";


import moment from 'moment';

const LinkedinStatusTag = ({ status }) => {
  const statusToEmojiText = {
    accurate_contact: {
      emoji: "🙋‍♀️",
      text: "Linkedin profile still in company",
    },
    contact_has_moved: {
      emoji: "🙅‍♀️",
      text: "Linkedin profile not in company anymore",
    },
    potential_linkedin_profile_found_but_scraped_too_old_if_recent_xp: {
      emoji: "🤷‍♀️",
      text: "Linkedin profile not found",
    },
    accurate_contact_but_scraped_profile_is_aging: {
      emoji: "🤷‍♀️",
      text: "Linkedin profile still in company but not scraped recently",
    },
    linkedin_profile_missing: {
      emoji: "🤷‍♀️",
      text: "Linkedin profile not found",
    },
  }
  const { emoji, text } = statusToEmojiText[status] || statusToEmojiText["linkedin_profile_missing"];
  return <Popup
    trigger={<div>{emoji}</div>}
    content={<div>{text}</div>}
  />
}

const CommunicationsStatusTag = ({ status }) => {

  const statusToTextExplaination = {
    never_contacted: {
      text: "Never Contacted",
    },
    never_answered: {
      text: "Never Answered",
    },
    has_not_answered_for_a_while: {
      text: "No more answering",
      explaination: "Last answer is more than 6 months before last contact",
    },
    actual_contact: {
      text: "Last active",
      explaination: "Last active contact",
    },
    aging_contact: {
      text: "Outdated",
      explaination: "Last communication was more than 1 year before company last communication"
    },
    other_contact: {
      text: "Active",
      explaination: "Other active contact",
    },
  }
  const { text, explaination } = statusToTextExplaination[status] || statusToTextExplaination["other_contact"];
  if (!explaination){
    return <Label>{text}</Label>;
  }
  return <Popup
    trigger={<Label>{text}</Label>}
    content={<div>{explaination}</div>}
  />
}


class ContactStatuses extends React.Component {
  state = {};

  componentDidMount = async () => {
    try {
      const { hubspotCompanyId } = this.props;
      const contactStatuses = ((await axios.post(`${baseUrl}/salesOptimisationApi/companyContactStatuses`, { hubspotCompanyId })).data || {}).results;
      console.log({ contactStatuses });
      this.setState({
        contactStatuses,
      })
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  };


  render() {
    const { contactStatuses } = this.state;
    if (!contactStatuses) {
      return <div>Loading...</div>
    }
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Last Company XP</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Communications</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(contactStatuses, (contactStatus) => {
            const {
              firstname,
              lastname,
              email,
              hubspotContactId,
              status,
              linkedinProfileId,
              lastExperienceInCompany,
              communicationsStatus,
              communicationsSummary
            } = contactStatus;
            return (
              <Table.Row
                key={hubspotContactId}
              >

                <Table.Cell>
                  <a href={`https://app.hubspot.com/contacts/1768483/contact/${hubspotContactId}`} target="_blank">
                    {firstname && (<span>{firstname} {lastname}<br/></span>)}
                    { email ? email : 'no email'}

                  </a>
                </Table.Cell>
          
                <Table.Cell>
                  <LinkedinStatusTag status={status} />
                  {lastExperienceInCompany && (
                    <a href={`http://www.linkedin.com/in/${linkedinProfileId}`} target="_blank">
                      {lastExperienceInCompany.xpString}
                    </a>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <CommunicationsStatusTag status={communicationsStatus} />
                </Table.Cell>
                <Table.Cell>
                  <CommunicationsSummary communicationsSummary={communicationsSummary} />
                </Table.Cell>
                  
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    );
  }
}


export default ContactStatuses;
