/* eslint no-useless-escape: 0 */
import _ from 'underscore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Message, Grid, List, Segment, Button } from 'semantic-ui-react';
import moment from 'moment';
import baseUrl from '../baseUrl.js';
import withOfferDetailsModal from '../hocs/withOfferDetailsModal.js';
import { actions } from './reducers';
import ClientProfile from '../components/ClientProfile';
import { ExternalPushAndRefetchButtons } from '../components/ExternalPushAndRefetchButtons';
import { LinkedinProfile } from './WorkPipeInputComponents/LinkedinProfile';
import { Analysis } from './WorkPipeInputComponents/Analysis';
// import { GithubProfile } from './WorkPipeInputComponents/GithubProfile';
// import { StackOverflowProfile } from './WorkPipeInputComponents/StackoverflowProfile';
import { Upload } from './WorkPipeInputComponents/Upload';
// import { MiscProfile } from './WorkPipeInputComponents/MiscProfile';
import { AnnotationButtons } from './WorkPipeInputComponents/AnnotationButtons';
import { LabelingButtons } from './WorkPipeInputComponents/LabelingButtons';
import { ExternalPushModal } from './WorkPipeInputComponents/ExternalPushModal';

import { 
  prefetchingLoadProfile, 
  computeGlobalHints,
  getExtraDataFromOfferAndIdFields,
  getSweetynote
} from './ProfileLoading';

import './WorkPipeInput.css';

const ShowOfferDetailsButton = withOfferDetailsModal(
  ({ offerId, showOfferDetails }) => (
    <Button
      className="nearly-transparent-button"
      style={{ cursor: 'pointer' }}
      color="purple"
      circular
      icon="address book"
      onClick={() => showOfferDetails(offerId)}
    />
  ),
);

class WorkPipeInput extends Component {
  componentWillMount() {
    const {
      match,
      offer,
      workPipe,
      input,
      onLoad,
      onLoadOffer,
      onReset,
    } = this.props;

    onReset();

    const { id, wid, inputId } = match.params;
    if (
      !input ||
      (input && input.id !== inputId) ||
      !workPipe ||
      (workPipe && workPipe.id !== wid)
    ) {
      onLoad(wid, inputId);
    }

    if (!offer) {
      onLoadOffer(id);
    }
  }

  componentWillReceiveProps({
    match,
    input,
    offer,
    profile,
    nextProfiles,
    searchStatus,
  }) {
    const { inputId, nth, wid } = match.params;

    if (
      offer &&
      input &&
      input.id === inputId &&
      !profile &&
      !nextProfiles &&
      (((searchStatus || {}).search || {}).status || {}).type !== 'error' &&
      (((searchStatus || {}).search || {}).status || {}).type !== 'pending'
    ) {
      this.props.onLoadProfile({ ...input, wid }, offer, nth || 0, 0);
    }

    // to go back to first view if profile changes
    const idFromProfile = (p) =>
      p.id ||
      p._id ||
      (p.idFields || {}).linkedin ||
      (p.idFields || {}).github ||
      JSON.stringify(p.idFields);
    if (
      idFromProfile(this.props.profile || {}) !== idFromProfile(profile || {})
    ) {
      if (this.props.setPendingUpload) {
        this.props.setPendingUpload(false);
      }
    }
  }

  render() {
    const {
      match,
      history,
      profile,
      workPipe,
      input,
      offer,
      nextProfiles,
      fullNextProfiles,
      more,
      editMode,
      globalHint,
      edit,
      uploadEmail,
      pendingUpload,
      onMore,
      onAnnotate,
      onChangeGlobalHint,
      onChangeExperienceHint,
      onUpdateParameters,
      onChangeComment,
      onClickEmail,
      onReloadProfile,
      onRemoveSource,
      togglePendingUpload,
      onTabChange,
      onCloseFullNextProfiles,
      onReset,
      actionsPane,
      username,
      onSetEmailAsNotFound,
      onSaveItemComment,
      onSaveItemDuplicate,
      onNotifyItemDuplicate,
      onDeleteItemComment,
      sweetynote,
      onMarkAsMatch,
      onMarkAsMismatch,
      // searchStatus,
    } = this.props;

    const { wid } = match.params;
    const nth = match.params.nth || 0;
    const togglePendingUploadHistory = () => {
      togglePendingUpload();
      const ith = nth;
      history.push(
        `/offers/${offer.id}/workpipe/${workPipe.id}/${input.id}/${ith}/${
          pendingUpload ? 0 : 1
        }`,
      );
    };

    const sweetsheetUrl =
      offer && offer.sweetsheetId
        ? `/flowboard/sweetsheets/id/${offer.sweetsheetId}`
        : '';

    const annotate = (type) => () => {
      const ith = nth;
      onAnnotate({
        type,
        profile,
        workPipe,
        input,
        offer,
        comment: edit.comment,
        uploadEmail,
        username,
      });
      history.push(
        `/offers/${offer.id}/workpipe/${workPipe.id}/${input.id}/${ith}`,
      );
    };

    const emailRetrievalFailsUsernames = _.compact(
      _.pluck((sweetynote || {}).emailRetrievalFails, 'username'),
    );
    const mismatchLinks = (sweetynote || {}).mismatches;
    const matchLinks = (sweetynote || {}).matches;

    const setEmailAsNotFound = async () =>
      onSetEmailAsNotFound(profile, workPipe.id, username);

    const saveItemDuplicate = async (duplicate) =>
      onSaveItemDuplicate(duplicate, profile, workPipe.id, username);
    const notifyItemDuplicate = async () =>
      onNotifyItemDuplicate(profile, workPipe.id);
    const saveItemComment = async (comment) =>
      onSaveItemComment(comment, profile, workPipe.id, username);
    const deleteItemComment = async (commentDate) =>
      onDeleteItemComment(commentDate, profile, workPipe.id, username);

    const markAsMatch = async (sourceId, id) =>
      onMarkAsMatch(sourceId, id, profile, workPipe.id, username);
    const markAsMismatch = async (sourceId, id) =>
      onMarkAsMismatch(sourceId, id, profile, workPipe.id, username);

    const events = ((this.props.searchStatus || {}).search || {}).events || [];
    const creationDate =
      events.length > 0 ? moment(_.first(events).timestamp, 'x') : moment();
    let currentSearchStatus = (
      ((this.props.searchStatus || {}).search || {}).status || {}
    ).type;

    if (!nextProfiles || (nextProfiles.length > 0 && !profile)) {
      if (currentSearchStatus === 'success') {
        return <p>Loading...</p>;
      } else {
        return (
          <div>
            <div>
              <List relaxed>
                {_.map(
                  ((this.props.searchStatus || {}).search || {}).events,
                  (event) => {
                    return (
                      <List.Item>
                        <List.Icon
                          name={
                            (event || {}).type === 'uncatched-error'
                              ? 'bomb'
                              : (event || {}).type === 'filters-too-tight'
                              ? 'delete'
                              : (event || {}).type === 'error'
                              ? 'delete'
                              : 'check'
                          }
                          size="large"
                          verticalAlign="middle"
                          color={
                            (event || {}).type === 'uncatched-error'
                              ? 'black'
                              : (event || {}).type === 'filters-too-tight'
                              ? 'black'
                              : (event || {}).type === 'error'
                              ? 'red'
                              : 'green'
                          }
                        />
                        <List.Content>
                          <List.Header>{(event || {}).type}</List.Header>
                          <List.Description>
                            {(event || {}).message} -{' '}
                            {moment((event || {}).timestamp, 'x').fromNow()}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    );
                  },
                )}
                {currentSearchStatus === 'pending' ? (
                  <List.Item>
                    <List.Icon
                      name="spinner"
                      size="large"
                      verticalAlign="middle"
                      loading
                      style={{ padding: 0 }}
                      color="grey"
                    />
                    <List.Content>
                      <List.Header>Search computing...</List.Header>
                      <List.Description>
                        Creation: {creationDate.fromNow()}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : null}
              </List>
            </div>
            <ExternalPushAndRefetchButtons
              onLoadProfile={this.props.onLoadProfile}
              input={{ ...input, wid: (workPipe || {}).id || wid }}
              offer={offer}
              nth={nth}
            />
          </div>
        );
      }
    }

    if (nextProfiles.length === 0) {
      return (
        <div>
          <Message negative>
            <p>No profile available.</p>
          </Message>
          <ExternalPushAndRefetchButtons
            onLoadProfile={this.props.onLoadProfile}
            input={{ ...input, wid: (workPipe || {}).id || wid }}
            offer={offer}
            nth={nth}
          />
        </div>
      );
    }
    const disabled = editMode === 'lock';
    const { linkedin } = (profile || {}).sourceProfiles || {};

    return (
      <div>
        {fullNextProfiles.state !== 'closed' ? (
          <div className="nextProfilesContainer">
            <Segment clearing loading={fullNextProfiles.state === 'loading'}>
              <Button
                floated="right"
                circular
                icon="close"
                onClick={onCloseFullNextProfiles}
              />
              <List style={{ height: '70vh', overflow: 'auto' }}>
                {_.map(fullNextProfiles.profiles, (nextProfile, nth) => (
                  <List.Item key={nth}>
                    <Link
                      onClick={onReset}
                      to={`/offers/${offer.id}/workpipe/${workPipe.id}/${
                        input.id
                      }/${nth}`}
                    >
                      {(nextProfile.idFields || {}).linkedin}
                    </Link>
                  </List.Item>
                ))}
              </List>
            </Segment>
          </div>
        ) : null}

        {!((workPipe || {}).actionsSetDescriptor || {}).type ||
        ((workPipe || {}).actionsSetDescriptor || {}).type === 'default' ? (
          <AnnotationButtons
            disabled={disabled}
            pendingUpload={pendingUpload}
            togglePendingUploadHistory={togglePendingUploadHistory}
            annotate={annotate}
            username={username}
            edit={edit}
            profile={profile}
            input={input}
            onChangeComment={onChangeComment}
            offer={offer}
            setEmailAsNotFound={setEmailAsNotFound}
            onSaveItemComment={saveItemComment}
            onSaveItemDuplicate={saveItemDuplicate}
            onNotifyItemDuplicate={notifyItemDuplicate}
            emailRetrievalFailsUsernames={emailRetrievalFailsUsernames}
            mismatchLinks={mismatchLinks}
            matchLinks={matchLinks}
            markAsMatch={markAsMatch}
            markAsMismatch={markAsMismatch}
          />
        ) : ((workPipe || {}).actionsSetDescriptor || {}).type ===
          'labeling' ? (
          <LabelingButtons
            disabled={disabled}
            annotate={annotate}
            actions={((workPipe || {}).actionsSetDescriptor || {}).actions}
          />
        ) : (
          <div>Unknown actionsSetDescriptor type</div>
        )}
        {!pendingUpload ? (
          <Grid>
            <Grid.Column style={{ overflow: 'auto', height: '95vh' }} width={9}>
              <LinkedinProfile
                source={linkedin}
                mainEducation={((profile || {}).data || {}).mainEducation}
                augmentedExperiencesAndEducation={
                  (profile || {}).augmentedExperiencesAndEducation
                }
                more={more}
                globalHint={globalHint}
                globalHints={profile.globalHints}
                onMore={onMore}
                onChangeGlobalHint={onChangeGlobalHint}
                onChangeExperienceHint={onChangeExperienceHint}
                offerSkills={(offer || {}).skills || {}}
              />
            </Grid.Column>
            <Grid.Column style={{ overflow: 'auto', height: '95vh' }} width={7}>
              <div
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '5px',
                  zIndex: 1,
                }}
              >
                {offer && <ShowOfferDetailsButton offerId={offer.id} />}
                {sweetsheetUrl && (
                  <Link to={sweetsheetUrl} target="_blank">
                    <Button
                      className="sweetsheet-button"
                      style={{ cursor: 'pointer' }}
                      color="blue"
                      circular
                      icon="address book"
                    />
                  </Link>
                )}
                <ExternalPushModal workPipe={workPipe} profile={profile} />
              </div>
              <div>
                <Analysis
                  canShowHisto={false}
                  workPipe={workPipe}
                  profile={profile}
                  onTabChange={onTabChange}
                  actionsPane={actionsPane}
                  sweetynote={sweetynote}
                  onChangeExperienceHint={onChangeExperienceHint}
                  globalHint={globalHint}
                  onMore={onMore}
                  more={more}
                  markAsMatch={markAsMatch}
                  deleteItemComment={deleteItemComment}
                />
              </div>
            </Grid.Column>
          </Grid>
        ) : (
          <Grid columns={2}>
            <Grid.Column style={{ overflow: 'auto', height: '95vh' }}>
              <ClientProfile
                profile={{
                  ...profile,
                  data: {
                    ...(profile && profile.data),
                    ...(profile.extraFields && profile.extraFields.data),
                  },
                }}
              />
            </Grid.Column>
            <Grid.Column style={{ overflow: 'auto', height: '95vh' }}>
              <div
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '5px',
                  zIndex: 1,
                }}
              >
                {offer && <ShowOfferDetailsButton offerId={offer.id} />}
                {sweetsheetUrl && (
                  <Link to={sweetsheetUrl} target="_blank">
                    <Button
                      className="sweetsheet-button"
                      style={{ cursor: 'pointer' }}
                      color="blue"
                      circular
                      icon="address book"
                    />
                  </Link>
                )}
                <ExternalPushModal workPipe={workPipe} profile={profile} />
              </div>
              <Upload
                key={(profile || {}).frontendId}
                profile={profile}
                uploadEmail={uploadEmail}
                onReloadProfile={() =>
                  onReloadProfile(profile, nextProfiles, offer)
                }
                onClickEmail={(email) =>
                  onClickEmail((profile || {}).frontendId, email)
                }
                onUpdateParameters={(form) =>

                                    { return onUpdateParameters(
                    (profile || {}).frontendId,
                    form,
                    profile,
                    onRemoveSource
                                    )}
                }
              />
            </Grid.Column>
          </Grid>
        )}
        <ExternalPushAndRefetchButtons
          onLoadProfile={this.props.onLoadProfile}
          input={{ ...input, wid: (workPipe || {}).id || wid }}
          offer={offer}
          nth={nth}
        />
        <div
          id="profileItemId"
          style={{
            visibility: 'hidden',
            fontSize: '0px',
            padding: '0px',
            width: '1px',
            height: '1px',
          }}
        >
          {profile._id}
        </div>
      </div>
    );
  }
}

const mapSWorkPipeInput = (state) => ({
  offer: state.selectedOffer,
  workPipe: state.selectedWorkPipe,
  input: state.selectedInput,
  profile: state.currentProfile,
  nextProfiles: state.nextProfiles,
  fullNextProfiles: state.fullNextProfiles,
  more: state.more,
  editMode: state.editMode,
  edit: state.edit,
  globalHint: state.globalHint,
  pane: state.pane,
  uploadEmail: (state.currentProfile || {}).uploadEmail,
  pendingUpload: state.pendingUpload,
  actionsPane: state.actionsPane,
  sweetynote: (state.currentProfile || {}).sweetynote,
  searchStatus: state.searchStatus,
});


const onLoadProfile = (dispatch) => async (input, offer, nth = 0, step = 0, isPrefetching, noCache) => {

  if (!isPrefetching) {
    try {
      const ith = parseInt(nth || 0)
      const fakeDispatch = (x) => {};
      if (ith < 5) {
        setTimeout(() => {
          onLoadProfile(fakeDispatch)(input, offer, ith + 1, 0, true);
        }, 2000);
      }
    } catch (e) {
      alert('nth should be a number');
    }
  }

  const executionId = 'sw_' + ('' + Math.random()).slice(2, 12);

  const tBeg = Date.now();

  // first step current profile without enrichments
  const body = {
    ...input,
    offer,
    projectionParam: {},
    nth: nth || 0,
    executionId,
  };
  const getItemData = (await axios.post(
    `${baseUrl}/sweetwork/getItem?version=1`,
    body,
  )).data; 
  const { current, next, error } = getItemData;

  await prefetchingLoadProfile({
    dispatch,
    tBeg,
    executionId,
    input, 
    offer,
    nth,
    step,
    current,
    next,
    error,
    isPrefetching,
    noCache
  });

};


const onReloadProfile = (dispatch) => async (
  currentProfile,
  nextProfiles,
  offer,
) => {

  const {
    relevantActivities,
    tags,
    offerMatch,
    warnings,
  } = await getExtraDataFromOfferAndIdFields({
    offer,
    idFields: currentProfile.idFields,
  });
  const reloadedProfile = {
    ...currentProfile,
    data: {
      ...(currentProfile && currentProfile.data),
      ...offerMatch,
    },
    tags, 
    relevantActivities,
    ...(warnings && { warnings }),

  };
  dispatch(actions.setProfiles(reloadedProfile, nextProfiles));
};

const mapDWorkPipeInput = (dispatch) => ({
  onReset: () => {
    dispatch(actions.setProfiles(null, null));
    dispatch(actions.setSearchStatus(null));
    dispatch(actions.setFullNextState('closed'));
  },
  onTabChange: (id, i) => dispatch(actions.setActionsPane(id, i)),
  onLoad: async (workPipeId, inputId) => {
    const workPipe = (await axios.get(
      `${baseUrl}/sweetwork/workPipes/${workPipeId}`,
    )).data;
    dispatch(actions.setSelectedWorkPipe(workPipe));
    const input = _.findWhere(workPipe.inputs, { id: inputId });
    if (input) {
      dispatch(actions.setSelectedInput(input));
    } else {
      alert('Error: input with id ' + inputId + ' is not found');
    }
  },
  onLoadOffer: async (offerId) => {
    const offer = (await axios.get(`${baseUrl}/offers/${offerId}`)).data;
    dispatch(actions.setSelectedOffer(offer));
  },
  onLoadProfile: onLoadProfile(dispatch),
  onReloadProfile: onReloadProfile(dispatch),
  onToggle: (i) => dispatch(actions.toggleSource(i)),
  onMore: (i) => dispatch(actions.toggleMore(i)),
  onChangeGlobalHint: (id) => dispatch(actions.setGlobalHint(id)),
  onChangeExperienceHint: (i, id) => dispatch(actions.setExperienceHint(i, id)),
  onUpdateParameters: (
    frontendId,
    form,
    { idFields: oldIdFields, data },
    onRemoveSource,
  ) => {
    const {
      headline,
      summary,
      summaryStack,
      location,
      mainEducation,
      photoLink,
      relevantTags,
      sources,
      linkedinSkills,
      githubRepositories,
      stackoverflowRelevantFacts,
      tags,
      experiences,
      education,
    } = form;

    const linkedinSkillsData = linkedinSkills
      ? _.compact(
          _.map(linkedinSkills, ({ skill, edit }) =>
            edit.removed
              ? null
              : {
                  ...skill,
                  relevance: edit.isTarget ? 10 : 0,
                },
          ),
        )
      : [];

    const githubRepoData = _.compact(
      _.map(githubRepositories, ({ selected, repo }) =>
        selected ? repo : null,
      ),
    );

    const stackoverflowRelevantFactsData = _.compact(
      _.map(stackoverflowRelevantFacts, ({ selected, relevantFact }) =>
        selected ? relevantFact : null,
      ),
    );

    const sourceData = {
      ...(linkedinSkillsData && { linkedin: { skills: linkedinSkillsData } }),
      ...(githubRepoData && { github: { repositories: githubRepoData } }),
      ...(stackoverflowRelevantFactsData && {
        stackoverflow: { relevantFacts: stackoverflowRelevantFactsData },
      }),
    };
    const newSources = _.object(
      _.compact(
        _.map(sources, ({ value, key }) =>
          _.isEmpty(value) || _.isEmpty(key) ? null : [key, value],
        ),
      ),
    );
    const experiencesData = _.compact(
      _.map(experiences, ({ selected, exp }) => (selected ? exp : null)),
    );
    const educationData = _.compact(
      _.map(education, ({ selected, edu }) => (selected ? edu : null)),
    );

    const normalizeMainEducation = (baseMainEducation) => {
      if (_.isEmpty(baseMainEducation)) {
        return baseMainEducation;
      }
      const normalizeDate = (baseDate, overwritingRaw) => {
        if ((overwritingRaw || '').trim()) {
          try {
            const year = parseInt(overwritingRaw.trim(), 10);
            if (year >= 1950 && year <= 2030) {
              return { raw: overwritingRaw, year };
            } else {
              throw Error('invalid year');
            }
          } catch (e) {
            console.error('ERROR', e);
            return { raw: overwritingRaw };
          }
        }
        return baseDate;
      };
      return baseMainEducation.displayMainEducation === false
        ? {
            schoolName: null,
            startDate: { raw: null },
            endDate: { raw: null },
          }
        : {
            schoolName:
              (baseMainEducation.overwritingSchoolName || '').trim() ||
              baseMainEducation.schoolName,
            startDate: normalizeDate(
              baseMainEducation.startDate,
              baseMainEducation.overwritingRawStartYear,
            ),
            endDate: normalizeDate(
              baseMainEducation.endDate,
              baseMainEducation.overwritingRawEndYear,
            ),
          };
    };
    const normalizedMainEducation = normalizeMainEducation(mainEducation);
    const newInfo = {
      data: {
        headline,
        summary: {
          content: summary,
          stack: summaryStack
        },
        location,
        photoLink: photoLink.value,
        sources: newSources,
        experiences: experiencesData,
        education: educationData,
        mainEducation: normalizedMainEducation,
        ...(relevantTags && { relevantTags }),
        ...(sourceData && { sourceData }),
      },
      headline,
      lineDescription: headline,
      summary,
      location,
      experiences: experiencesData,
      education: educationData,
      photoLink: photoLink.value,
      ...newSources,
      tags,
    };

    console.log('new info', newInfo);

    dispatch(actions.setExtraFields(frontendId, { ...newInfo }));
  },
  onRemoveSource: (source) => dispatch(actions.removeSource(source)),
  onChangeComment: (comment) => dispatch(actions.setEdit({ comment })),
  togglePendingUpload: () => dispatch(actions.togglePendingUpload()),
  onSetEmailAsNotFound: async (profile, workPipeId) => {
    // const body = {
    //   reference: {
    //     sourceId: 'linkedin',
    //     id: (profile.idFields || {}).linkedin
    //   },
    //   emailNotFound: true
    // }
    const sourceId = 'linkedin';
    const id = (profile.idFields || {}).linkedin;
    try {
      await axios.post(
        `${baseUrl}/sweetynotes/${sourceId}/${id}/email_fails`,
        {},
      );
      const sweetynote = await getSweetynote(profile.idFields);
      dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
    } catch (e) {
      alert(e.message);
    }
  },
  onSaveItemComment: async (comment, profile, workPipeId) => {
    const sourceId = 'linkedin';
    const id = (profile.idFields || {}).linkedin;
    const body = { comment };
    try {
      await axios.post(
        `${baseUrl}/sweetynotes/${sourceId}/${id}/comments`,
        body,
      );
      const sweetynote = await getSweetynote(profile.idFields);
      dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  },
  onNotifyItemDuplicate: async (profile, workPipeId) => {
    const sourceId = 'linkedin';
    const id = (profile.idFields || {}).linkedin;
    try {
      await axios.post(
        `${baseUrl}/sweetynotes/${sourceId}/${id}/notify_as_alias`,
      );
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  },
  onSaveItemDuplicate: async (duplicate, profile, workPipeId) => {
    if (_.isString(duplicate) && !_.isEmpty(duplicate)) {
      const tryFindLinkedinId = (link) => {
        if (link.indexOf('linkedin.com/in') >= 0) {
          const sides = link.split('?');
          const parts = (sides[0] || '').split('/');
          const id = _.last(parts);
          if (id.length > 5) {
            return id;
          }
        } else {
          const sides = link.split('?');
          const parts = (sides[0] || '').split('/');
          const ids = _.compact(
            _.map(parts, (part) => (part.length > 1 ? part : null)),
          );
          if (ids.length === 1) {
            return ids[0];
          }
        }
        return null;
      };
      const aliasId =
        duplicate.indexOf('/') >= 0 ? tryFindLinkedinId(duplicate) : duplicate;
      if (!aliasId) {
        alert('duplicate has a wrong format');
        return;
      }
      const body = { aliasId };
      const sourceId = 'linkedin';
      const id = (profile.idFields || {}).linkedin;
      try {
        await axios.post(
          `${baseUrl}/sweetynotes/${sourceId}/${id}/aliases`,
          body,
        );
        const sweetynote = await getSweetynote(profile.idFields);
        dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    } else {
      alert('duplicate is empty or not a string');
    }
  },
  onDeleteItemComment: async (commentDate, profile, workPipeId) => {
    const sourceId = 'linkedin';
    const id = (profile.idFields || {}).linkedin;
    try {
      await axios.delete(
        `${baseUrl}/sweetynotes/${sourceId}/${id}/comments/${commentDate}`,
      );
      const sweetynote = await getSweetynote(profile.idFields);
      dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  },
  onMarkAsMatch: async (sourceId, id, profile, workPipeId) => {
    const body = { match: { sourceId, id } };
    const baseSourceId = 'linkedin';
    const baseId = (profile.idFields || {}).linkedin;
    try {
      await axios.post(
        `${baseUrl}/sweetynotes/${baseSourceId}/${baseId}/matches`,
        body,
      );
      const sweetynote = await getSweetynote(profile.idFields);
      dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  },
  onMarkAsMismatch: async (sourceId, id, profile, workPipeId) => {
    const body = { mismatch: { sourceId, id } };
    const baseSourceId = 'linkedin';
    const baseId = (profile.idFields || {}).linkedin;
    try {
      await axios.post(
        `${baseUrl}/sweetynotes/${baseSourceId}/${baseId}/mismatches`,
        body,
      );
      const sweetynote = await getSweetynote(profile.idFields);
      dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  },
  onAnnotate: async ({
    type,
    profile,
    workPipe,
    input,
    offer,
    comment,
    uploadEmail,
    username,
  }) => {
    if (!profile) {
      alert('profile undefined');
      return;
    }
    const types = type.split(';');
    const isTypeASelect = _.indexOf(types, 'selected') >= 0;
    if (isTypeASelect && !profile.extraFields) {
      alert('Profile not ready for upload, wait few seconds');
      return;
    }
    if (isTypeASelect && !uploadEmail) {
      alert('Please select a user email');
      return;
    }
    if (
      isTypeASelect &&
      profile.bounceEmails &&
      _.indexOf(profile.bounceEmails, uploadEmail) > -1
    ) {
      alert('Bounce email selected');
      return;
    }
    if (isTypeASelect && uploadEmail.indexOf(' ') > -1) {
      alert('The selected email contains spaces');
      return;
    }

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (isTypeASelect && !uploadEmail.match(emailRegex)) {
      alert('Invalid characters found in the selected email');
      return;
    }
    dispatch(actions.setEditMode('lock'));

    dispatch(actions.setSweetynote(profile.frontendId, null));
    const sourceDescriptor = ((profile || {}).innerData || {}).sourceDescriptor;
    const body = {
      item: {
        ...profile,
        annotations: [
          ...(profile.annotations || []),
          {
            date: new Date(),
            state: type,
            username,
            ...(!_.isEmpty(sourceDescriptor) && { sourceDescriptor }),
            extraFields: {
              ...profile.extraFields,
              email: uploadEmail,
            },
            comment,
          },
        ],
      },
      overwrite: /backlog/i.test(input.title),
    };

    dispatch(actions.setEditMode('lock'));
    dispatch(actions.setSweetynote(null));

    const response = (await axios.post(
      `${baseUrl}/sweetwork/workPipes/${workPipe.id}/item`,
      body,
    )).data;

    // not awaited on purpose
    if (
      input &&
      input.pipeDescriptor &&
      input.pipeDescriptor.type === 'search-v3'
    ) {
      axios.post(`${baseUrl}/sweetwork/signalAnnotation`, {
        annotation: type,
        pipeDescriptor: input.pipeDescriptor,
      });
    }

    if (response.error) {
      console.error('annotation error', response.error);
      alert(response.error);
    }
    try {
      await onLoadProfile(dispatch)(input, offer);
    } catch (e) {
      console.error('error on load profile', e);
    }
    dispatch(actions.setEditMode('none'));
  },
  onSetEditMode: (editMode) => dispatch(actions.setEditMode(editMode)),
  onClickEmail: (frontendId, email) =>
    dispatch(actions.setUploadEmail(frontendId, email)),
  onCloseFullNextProfiles: () => dispatch(actions.setFullNextState('closed')),
});

export default connect(
  mapSWorkPipeInput,
  mapDWorkPipeInput,
)(WorkPipeInput);

const ClientProfileComponent = connect((state) => ({
  profile: state.currentProfile,
}))(ClientProfile);

export { ClientProfileComponent, computeGlobalHints };
