import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import baseUrl from '../../../baseUrl.js';
import { Grid } from 'semantic-ui-react';
import MiniButton from './MiniButton';
import BigButton from './BigButton';
import DuplicateButton from './DuplicateButton';
import FeedbackModal from './FeedbackModal';
import './annotationsButton.css';
import { EmailFailButton, EmailNoTimeButton } from './EmailButtons.jsx';

class AnnotationsButton extends Component {
  state = { feedbackModal: undefined };

  handleOpenFeedbackModal = ({ presetAnnotation, noDetails }) => {
    this.setState({ feedbackModal: {
      ...(presetAnnotation !== undefined) && { presetAnnotation },
      ...(noDetails !== undefined) && { noDetails }
    }});
  };

  handleCloseFeedbackModal = () => {
    this.setState({ feedbackModal: undefined });
  };

  componentWillMount() {
    console.log('mounting AnnotationsButton')
  }

  handleSubmitFeedbackModal = async ({ comment, annotationType, shouldBeProcessed }) => {
    const { idFields, scorerDetails, process } = this.props;
    const response = await this.props.sendSearchFeedback({ comment, annotationType, idFields, scorerDetails });
    if (response.error) {
      alert(response.error);
    } else {
      //alert('reported');
      if (shouldBeProcessed) {
        process(annotationType);
      }
      this.setState({ feedbackModal: undefined });
    }
  };

  renderTopBarMiniButtons() {
    const feedback = false;

    const { feedbackModeEnabled, toggleFeedbackMode } = this.props;

    return (
      <Grid.Row columns={6} style={{ padding: '0px', margin: '-8px 8%' }}>
        {this.state.feedbackModal && (
          <FeedbackModal
            onClose={this.handleCloseFeedbackModal}
            onSubmit={this.handleSubmitFeedbackModal}
            scorerDetails={this.props.scorerDetails}
            {...this.state.feedbackModal}
          />
        )}

        {feedback ? (
          <Grid.Column style={{ padding: '0px', paddingLeft: 13 }}>
            <MiniButton text='FB' color={feedbackModeEnabled ? 'purple' : 'grey' } onClick={toggleFeedbackMode} />
          </Grid.Column>
        ) : (
          <Grid.Column style={{ padding: '0px' }} />
        )}
      </Grid.Row>
    );
  }

  renderMiddleBarBigButtons() {
    const { process, annotationActions, feedbackModeEnabled } = this.props;

    const annotationButtons = !_.isEmpty(annotationActions) ? (
      annotationActions
    ) : ([
			{
				'title' : '0',
				'annotation' : 'disqualified;awful',
				'color' : 'brown',
        'hoverText': 'Has nothing to do with this list'
			},
		  {
				'title' : '1',
				'annotation' : 'disqualified;bad',
				'color' : 'red',
        'hoverText': 'Does not match the offer'
			},      
		  {
				'title' : '2',
				'annotation' : 'backlog;weak',
				'color' : 'orange',
        'hoverText': 'Maybe as a last resort'
			},      
		  {
				'title' : '3',
				'annotation' : 'backlog;normal',
				'color' : 'yellow',
        'hoverText': 'Maybe later'
			},      
		  {
				'title' : '4',
				'annotation' : 'selected;normal',
				'color' : 'olive',
        'hoverText': 'Match most criteria'
			},
		  {
				'title' : '5',
				'annotation' : 'selected;strong',
				'color' : 'green',
        'hoverText': 'Match all criteria'
      },
      {
				'title' : '6',
				'annotation' : 'selected;very-strong',
				'color' : 'teal',
        'hoverText': 'Match perfectly all criteria'
			}
    ]);

    return (
      <Grid.Row columns={7} style={{ padding: '0px' }}>
        {_.map(annotationButtons, ({ title, annotation, color, hoverText }, index) => (
          <Grid.Column key={index} style={{ padding: '0px' }}>
            <BigButton
              text={title}
              onClick={() => { 
                if (feedbackModeEnabled && annotation && annotation.indexOf('select') < 0) {
                  this.handleOpenFeedbackModal({ 
                    presetAnnotation: annotation, 
                    noDetails: true 
                  })
                } else {
                  process(annotation);
                }
              }}
              color={color}
              hoverText={hoverText || ''}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    );
  }

  renderBottomBarMiniButtons() {
    const { process, idFields, sweetynote } = this.props;
    return (
      <Grid.Row columns={6} style={{ padding: '0px', margin: '-8px 8%' }}>
        <Grid.Column style={{ padding: '0px', paddingLeft: 13 }}>
          <MiniButton text='Forget' onClick={() => process('forgotten')} />
        </Grid.Column>
        <Grid.Column style={{ padding: '0px', paddingLeft: 13 }}>
          <DuplicateButton idFields={idFields} forget={() => process('forgotten')} />
        </Grid.Column>
        <Grid.Column style={{ padding: '0px', paddingLeft: 13 }}>
          <EmailFailButton
            setEmailAsNotFound={() =>
              axios.post(`${baseUrl}/sweetynotes/linkedin/${(idFields || {}).linkedin}/email_fails`, {})
            }
            emailRetrievalFails={(sweetynote || {}).emailRetrievalFails}
            process={process}
          />
        </Grid.Column>
        <Grid.Column style={{ padding: '0px', paddingLeft: 13 }}>
          <EmailNoTimeButton process={process} />
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    return (
      <div className='annotationsButton'>
        <Grid style={{ margin: '0px', width: '440px' }}>
          {this.renderTopBarMiniButtons()}
          {this.renderMiddleBarBigButtons()}
          {this.renderBottomBarMiniButtons()}
        </Grid>
      </div>
    );
  }
}

export default AnnotationsButton;
