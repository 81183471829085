const notes = `Poste : Back-End, Fullstack
Goot :
Back-End
Node.js, TypeScript, Firebase
Équipes : 7 à 8 devs 
Projets : App web logistique pour les commandes/entrepots
Infra, API, reconstruction de code

Freelance :Projets perso / projet 42
Node.js, React.js, Docker, Rust

Pq partir ? Retrouver un mode de dev

Technos : Node.js, React.js, Vue.js

Conditions :
Veut du clean code
Dominante Back-End
Île-De-France ou full remote
Immédiatement`;

const blurb = `Back-End Developer diplômé de l'école 42 avec 2 ans d'expérience, à la recherche d'un poste en Node.js, TypeScript.

**Son expérience chez Goot et Revenue Studio :**

Teddy travaille depuis un an et demi chez Goot en tant que Back-End Developer en Node.js, TypeScript. Il développe une application web pour du suivi logistique, il travaille aussi sur des enjeux d'infrastructure et API.
Il a également réalisé un stage de 6 mois chez Revenue Studio où il codait essentiellement en Vue.js et ElasticSearch.

**Que recherche-t-il aujourd'hui ?**

Après un an passé sur des projets personnels, Teddy souhaite retrouver un poste de développeur. Il cherche un poste à dominante Back-End. Il est disponible immédiatement pour un poste en Île-de-France.
`;
const firstname = "Teddy";
const mainEducationSchoolName = "École 42";
const experienceYears = "2 ans d'expérience";

const data = {
  notes,
  blurb,
  candidateFields: {
    firstname: { value: firstname, isActive: true },
    mainEducationSchoolName: { value: mainEducationSchoolName, isActive: true },
    experienceYears: { value: experienceYears, isActive: true },
  },
}
export default data;
