import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Table, Popup, Header, Icon, Segment, Checkbox } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';
import { LinkedinProfile } from './Offers/WorkPipeInputComponents/LinkedinProfile';
import ProfileView from './SweetComponents/Profile/View';

const getIdToResult = async ({ minTimestamp, groupByEmail, groupBySlave }) => {
  const result = (await axios.post(`${baseUrl}/scrapers/pythonSlaves/stats`, {
    minTimestamp,
    groupByEmail,
    groupBySlave
  })).data;
  const idToResult = _.indexBy(
    result,
    ({ slave, email }) => `${groupBySlave ? slave : ''}${groupByEmail ? email : ''}`
  );
  return idToResult;
}

const getMinTimestamps = () => {
  return {
    onHour: 1000 * moment().subtract(1, 'hour').unix(),
    currentDay: 1000 * moment().startOf('day').unix(),
    sevenDays: 1000 * moment().subtract(7, 'day').unix(),
  }
}


const getStatsBySlaveEmail = async () => {
  const { onHour, currentDay, sevenDays } = await getMinTimestamps();
  const [resOneHour, resCurrentDay, resSevenDays] = await Promise.all([
    getIdToResult({ minTimestamp: onHour, groupBySlave: true, groupByEmail: true }),
    getIdToResult({ minTimestamp: currentDay, groupBySlave: true, groupByEmail: true }),
    getIdToResult({ minTimestamp: sevenDays, groupBySlave: true, groupByEmail: true })
  ])
  return _.map(_.keys(resSevenDays), (key) => ({
    slave: resSevenDays[key].slave,
    email: resSevenDays[key].email,
    sevenDays: resSevenDays[key] || {},
    currentDay: resCurrentDay[key] || {},
    oneHour: resOneHour[key] || {},
  }))
}

const getStatsBySlave = async () => {
  const { onHour, currentDay, sevenDays } = await getMinTimestamps();
  const [resOneHour, resCurrentDay, resSevenDays] = await Promise.all([
    getIdToResult({ minTimestamp: onHour, groupBySlave: true, groupByEmail: false }),
    getIdToResult({ minTimestamp: currentDay, groupBySlave: true, groupByEmail: false }),
    getIdToResult({ minTimestamp: sevenDays, groupBySlave: true, groupByEmail: false })
  ])
  return _.map(_.keys(resSevenDays), (key) => ({
    slave: resSevenDays[key].slave,
    sevenDays: resSevenDays[key] || {},
    currentDay: resCurrentDay[key] || {},
    oneHour: resOneHour[key] || {},
  }))
}

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

const taskResult = ({ success, errorType, traceback, scrapResult }) => {
  if (!_.isBoolean(success)) {
    return <div></div>
  }
  return (
    <Popup
      trigger={
        <div>
          <Icon
            size="big"
            color={success ? "green" : "red"}
            name={success ? "check" : "warning"}
            style={{ cursor: 'pointer' }}
          />
          {errorType || ''}
        </div>
      }
      content={
        success ? (
          <pre> {JSON.stringify(scrapResult, null, 4)}</pre>
        ) : (
          <div dangerouslySetInnerHTML={getRawMarkup(traceback)} />
        )

      }
    />
  )

}
const SlaveEmailTable = ({ statsBySlaveEmail }) => (
  <Table celled structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell rowSpan='2'>Email</Table.HeaderCell>
        <Table.HeaderCell colSpan='3'>One Hour</Table.HeaderCell>
        <Table.HeaderCell colSpan='3'>Current day</Table.HeaderCell>
        <Table.HeaderCell colSpan='3'>Seven Days</Table.HeaderCell>

      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>success</Table.HeaderCell>
        <Table.HeaderCell>errors</Table.HeaderCell>
        <Table.HeaderCell>last</Table.HeaderCell>
        <Table.HeaderCell>success</Table.HeaderCell>
        <Table.HeaderCell>errors</Table.HeaderCell>
        <Table.HeaderCell>last</Table.HeaderCell>
        <Table.HeaderCell>success</Table.HeaderCell>
        <Table.HeaderCell>errors</Table.HeaderCell>
        <Table.HeaderCell>last</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(statsBySlaveEmail, ({ slave, email, oneHour, currentDay, sevenDays }) => (
        <Table.Row key={`${slave}${email}`}>
          <Table.Cell>{email} ({slave})</Table.Cell>
          <Table.Cell>{oneHour.nbSuccess || 0}</Table.Cell>
          <Table.Cell>{oneHour.nbErrors || 0}</Table.Cell>
          <Table.Cell>{taskResult(oneHour.lastTaskResult || {})}</Table.Cell>
          <Table.Cell>{currentDay.nbSuccess || 0}</Table.Cell>
          <Table.Cell>{currentDay.nbErrors || 0}</Table.Cell>
          <Table.Cell>{taskResult(currentDay.lastTaskResult || {})}</Table.Cell>
          <Table.Cell>{sevenDays.nbSuccess || 0}</Table.Cell>
          <Table.Cell>{sevenDays.nbErrors || 0}</Table.Cell>
          <Table.Cell>{taskResult(sevenDays.lastTaskResult || {})}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)


const SlaveTable = ({ statsBySlave }) => (
  <Table celled structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell rowSpan='2'>Slave</Table.HeaderCell>
        <Table.HeaderCell colSpan='3'>One Hour</Table.HeaderCell>
        <Table.HeaderCell colSpan='3'>Current day</Table.HeaderCell>
        <Table.HeaderCell colSpan='3'>Seven Days</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>success</Table.HeaderCell>
        <Table.HeaderCell>errors</Table.HeaderCell>
        <Table.HeaderCell>accounts</Table.HeaderCell>
        <Table.HeaderCell>success</Table.HeaderCell>
        <Table.HeaderCell>errors</Table.HeaderCell>
        <Table.HeaderCell>accounts</Table.HeaderCell>
        <Table.HeaderCell>success</Table.HeaderCell>
        <Table.HeaderCell>errors</Table.HeaderCell>
        <Table.HeaderCell>accounts</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(statsBySlave, ({ slave, oneHour, currentDay, sevenDays }) => (
        <Table.Row key={`${slave}}`}>
          <Table.Cell>{slave}</Table.Cell>
          <Table.Cell>{oneHour.nbSuccess || 0}</Table.Cell>
          <Table.Cell>{oneHour.nbErrors || 0}</Table.Cell>
          <Table.Cell>{(oneHour.emails || []).length}</Table.Cell>
          <Table.Cell>{currentDay.nbSuccess || 0}</Table.Cell>
          <Table.Cell>{currentDay.nbErrors || 0}</Table.Cell>
          <Table.Cell>{(currentDay.emails || []).length}</Table.Cell>
          <Table.Cell>{sevenDays.nbSuccess || 0}</Table.Cell>
          <Table.Cell>{sevenDays.nbErrors || 0}</Table.Cell>
          <Table.Cell>{(sevenDays.emails || []).length}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

class PythonSlavesStats extends React.Component {
  state = {};

  componentDidMount = () => {
    this.handleLoad()
  };

  handleLoad = async () => {
    const [statsBySlave, statsBySlaveEmail] = await Promise.all([
      await getStatsBySlave(),
      await getStatsBySlaveEmail()
    ])
    this.setState({ statsBySlave, statsBySlaveEmail });
  }


  render() {
    const { statsBySlaveEmail, statsBySlave } = this.state;
    return (
      <div>
        <Header as="h1">Slaves</Header>
        {statsBySlave && (
          <SlaveTable statsBySlave={statsBySlave} />
        )}
        <Header as="h1">Accounts</Header>

        {statsBySlaveEmail && (
          <SlaveEmailTable statsBySlaveEmail={statsBySlaveEmail} />
        )}
      </div>
    );
  }
}

export default PythonSlavesStats;
