import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';

import { Button, Grid, Icon } from 'semantic-ui-react';
import { Select, SweetForm } from 'sweetform';
import baseUrl from '../baseUrl.js';

const buildBinaryOptions = (text) => [
  {
    value: true,
    label: (
      <div>
        <Icon color='green' name='check' />
        {text}
      </div>
    ),
  },
  {
    value: false,
    label: (
      <div>
        <Icon color='red' name='remove' />
        {text}
      </div>
    ),
  },
];

class MagicFilterMenu extends Component {
  async componentDidMount() {
    await this.setSavedMagicFilterOptions();
  }

  setSavedMagicFilterOptions = async () => {
    const initialMagicFilterOptions = await this.getSavedMagicFilterOptions();
    const relaxed = !_.isUndefined((initialMagicFilterOptions || {}).relaxed)
      ? (initialMagicFilterOptions || {}).relaxed
      : true;
    const useLocation = !_.isUndefined((initialMagicFilterOptions || {}).useLocation)
      ? (initialMagicFilterOptions || {}).useLocation
      : true;
    const useFreelance = !_.isUndefined((initialMagicFilterOptions || {}).useFreelance)
      ? (initialMagicFilterOptions || {}).useFreelance
      : true;
    const useExperienceRange = !_.isUndefined((initialMagicFilterOptions || {}).useExperienceRange)
      ? (initialMagicFilterOptions || {}).useExperienceRange
      : true;
    const useJobSkills = !_.isUndefined((initialMagicFilterOptions || {}).useJobSkills)
      ? (initialMagicFilterOptions || {}).useJobSkills
      : true;
    const useJobExperience = !_.isUndefined((initialMagicFilterOptions || {}).useJobExperience)
      ? (initialMagicFilterOptions || {}).useJobExperience
      : false;
    const useFounderFilter = !_.isUndefined((initialMagicFilterOptions || {}).useFounderFilter)
      ? (initialMagicFilterOptions || {}).useFounderFilter
      : false;
    const useDoNotContact = !_.isUndefined((initialMagicFilterOptions || {}).useDoNotContact)
      ? (initialMagicFilterOptions || {}).useDoNotContact
      : true;
    const usePrestige = !_.isUndefined((initialMagicFilterOptions || {}).usePrestige)
      ? (initialMagicFilterOptions || {}).usePrestige
      : false;
    const formKey = '' + Math.random();
    this.setState({
      formKey,
      relaxed,
      useLocation,
      useFreelance,
      useExperienceRange,
      useJobSkills,
      useJobExperience,
      useFounderFilter,
      useDoNotContact,
      usePrestige,
    });
  };

  getSavedMagicFilterOptions = async () => {
    const { selectedClient, selectedMissionId, selectedRecommenderSystemId } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: selectedRecommenderSystemId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getRecommenderSystemMagicFilterOptions';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      return result.data;
    }
  };

  render() {
    const { onChange, updateMagicFilterOptionsInMongo } = this.props;
    const {
      formKey,
      relaxed,
      useLocation,
      useFreelance,
      useExperienceRange,
      useJobSkills,
      useJobExperience,
      useFounderFilter,
      useDoNotContact,
      usePrestige,
    } = this.state || {};
    return (
      <div>
        <SweetForm onChange={(params) => onChange(params)} key={formKey}>
          <Select
            field='relaxed'
            defaultValue={relaxed}
            options={[{ value: true, label: 'Relaxed' }, { value: false, label: 'Not Relaxed' }]}
          />
          <h5>Sub-typologies</h5>
          <Grid>
            <Grid.Column width={8}>
              <Select field='useLocation' defaultValue={useLocation} options={buildBinaryOptions('Location')} />
              <Select
                field='useExperienceRange'
                defaultValue={useExperienceRange}
                options={buildBinaryOptions('Experience Range')}
              />
              <Select
                field='useJobExperience'
                defaultValue={useJobExperience}
                options={buildBinaryOptions('Use Job experience Year')}
              />
              <Select field='useJobSkills' defaultValue={useJobSkills} options={buildBinaryOptions('Job Skills')} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Select
                field='useFounderFilter'
                defaultValue={useFounderFilter}
                options={buildBinaryOptions('No Founders')}
              />
              <Select
                field='useDoNotContact'
                defaultValue={useDoNotContact}
                options={buildBinaryOptions('Ask Not To Be Contacted')}
              />
              <Select field='usePrestige' defaultValue={usePrestige} options={buildBinaryOptions('Prestige')} />
            </Grid.Column>
          </Grid>
        </SweetForm>
        <br />
        <Button color='green' onClick={() => updateMagicFilterOptionsInMongo()}>
          Save Magic Filter Options In Mongo
        </Button>
        <Button color='green' onClick={() => this.setSavedMagicFilterOptions()}>
          Set Saved settings as current settings
        </Button>
      </div>
    );
  }
}

export default MagicFilterMenu;
