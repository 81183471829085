import React from 'react';
import _ from 'underscore';

import { Label, Segment } from 'semantic-ui-react';

export default class WorkplaceTags extends React.Component {
  render() {
    const stackWorkplace =
      this.props.workplace.stack['skill_parsed_in_workplace'] || [];
    const stackOffer = _.sortBy(
      _.map(
        this.props.workplace.stack['skill_count_in_offers'],
        (count, skill) => {
          return {
            skillId: skill,
            count: count,
          };
        },
      ),
      (item) => -item['count'],
    );
    const stackProfile = _.sortBy(
      _.map(
        this.props.workplace.stack['skill_count_in_profiles'],
        (count, skill) => {
          return {
            skillId: skill,
            count: count,
          };
        },
      ),
      (item) => -item['count'],
    );
    const locationsProfile = _.map(
      this.props.workplace.locations['location_in_profiles'],
      (count, loc) => {
        return {
          locationId: loc,
          count: count,
        };
      },
    );
    const limit =
      locationsProfile.reduce((prev, next) => prev + next.count, 0) / 10;
    const locationsProfileFiltered = _.map(
      locationsProfile.filter(
        (item) => item['count'] >= 5 || item['count'] > limit,
      ),
      (item) => {
        return item['locationId'];
      },
    );
    const locations = _.union(
      this.props.workplace.locations.safe_locations,
      locationsProfileFiltered,
    );
    const stack = _.filter(
      _.map(
        _.union(
          stackWorkplace,
          stackProfile.slice(0, 10),
          stackOffer.slice(0, 10),
        ),
        (item) => {
          return item['skillId'];
        },
      ),
      (item) => item,
    );
    const industries = this.props.workplace.industries;
    return (
      <div>
        <Segment>
          <List listOfTags={industries} tagName="Industries">
            {' '}
          </List>
        </Segment>
        <Segment>
          <List listOfTags={stack} tagName="Stack">
            {' '}
          </List>
        </Segment>
        <Segment>
          <List listOfTags={locations} tagName="Locations">
            {' '}
          </List>
        </Segment>
      </div>
    );
  }
}

const List = ({ listOfTags, tagName }) => {
  return (
    <div>
      <h2>{tagName}</h2>
      {_.map(listOfTags, (tag) => (
        <Label as="a" key={tag}>
          {tag}
        </Label>
      ))}
    </div>
  );
};
