import React from 'react';
import { getRawMarkup } from '../../common';

class TextInput extends React.Component {
  state = {
    isEditing: false,
  };

  enterEditMode = () => {
    this.setState({
      isEditing: true,
    });
  };

  exitEditMode = () => {
    this.setState({ isEditing: false });
  };

  componentDidUpdate() {
    if (this.state.isEditing) {
      this.nameInput.focus();
    }
  }

  renderInput = () => {
    const { name, placeholder, value, onChange, style, mode } = this.props;
    return mode === 'textarea' ? (
      <textarea
        ref={(input) => {
          this.nameInput = input;
        }}
        name={name}
        placeholder={placeholder}
        value={value}
        onBlur={this.exitEditMode}
        autoComplete='off'
        onChange={(event) => onChange({ name: event.target.name, value: event.target.value })}
        style={{
          outline: 'None',
          borderRadius: '2px',
          border: 'solid #0087d5 1px',
          padding: '1px',
          fontFamily: 'sourceSansPro',
          resize: 'vertical',
          minHeight: '100px',
          ...style,
        }}
      />
    ) : (
      <input
        ref={(input) => {
          this.nameInput = input;
        }}
        name={name}
        placeholder={placeholder}
        value={value}
        onBlur={this.exitEditMode}
        autoComplete='off'
        onChange={(event) => onChange({ name: event.target.name, value: event.target.value })}
        style={style}
      />
    );
  };

  renderText = () => {
    const { style, mode } = this.props;
    if (!this.props.value) {
      return (
        <span className='text-input' onClick={this.enterEditMode} style={{ ...style, opacity: 0.35 }}>
          {this.props.placeholder}
        </span>
      );
    }

    return mode === 'textarea' ? (
      <span
        className='text-input'
        style={style}
        onClick={this.enterEditMode}
        dangerouslySetInnerHTML={getRawMarkup(this.props.value)}
      />
    ) : (
      <span className='text-input' onClick={this.enterEditMode} style={style}>
        {this.props.value}
      </span>
    );
  };

  render() {
    if (this.state.isEditing) {
      return this.renderInput();
    } else {
      return this.renderText();
    }
  }
}

export default TextInput;
