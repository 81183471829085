import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import uuid from 'uuid/v4';

import { Grid, Button, Table, Form, Segment } from 'semantic-ui-react';

import {
  Select,
  SelectInt,
  Input,
  SweetForm,
  List,
  customOperator,
  enhance,
} from 'sweetform';

import Modal from './Modal';
import baseUrl from './baseUrl';

const TARGET_COLLECTIONS = [
  'githubTargetProfiles',
  'stackOverflowTargetProfiles',
  'linkedinTargetCompanies',
  'linkedinTargetProfiles',
  'crunchbaseTargetCompanies',
  'hiredTargetCompanies',
  'meetupTargetGroups',
  'wttjTargetCompanies',
  'wttjTargetOffers',
  'workableTargetCompanies',
];

const targetCollections = _.map(TARGET_COLLECTIONS, (c) => ({
  label: c,
  value: c,
}));

// Actions
const SET_ORDER = 'SET_ORDER';
const setOrder = (order) => ({ type: SET_ORDER, order });

const SET_RESULTS = 'SET_RESULTS';
const setResults = (results) => ({ type: SET_RESULTS, results });

const SET_EDIT = 'SET_EDIT';
const setEdit = (edit) => ({ type: SET_EDIT, edit });

const UPDATE_RESULT = 'UPDATE_RESULT';
const updateResult = (result) => ({ type: UPDATE_RESULT, result });

const DELETE_RESULT = 'DELETE_RESULT';
const deleteResult = (id) => ({ type: DELETE_RESULT, id });

const SET_DRY_RUN = 'SET_DRY_RUN';
const setDryRun = (dryRun) => ({ type: SET_DRY_RUN, dryRun });

const SET_RUNNING = 'SET_RUNNING';
const setRunning = (running) => ({ type: SET_RUNNING, running });

// Reducers
const results = (state = [], action) => {
  switch (action.type) {
    case SET_RESULTS:
      return action.results;
    case UPDATE_RESULT:
      const i = _.findIndex(state, ({ id }) => id === action.result.id);
      return i > -1
        ? [...state.slice(0, i), action.result, ...state.slice(i + 1)]
        : [...state, action.result];
    case DELETE_RESULT:
      return _.filter(state, ({ id }) => id !== action.id);
    case SET_ORDER:
      return _.sortBy(state, action.order);
    default:
      return state;
  }
};

const edit = (state = null, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return null;
    case SET_EDIT:
      return action.edit;
    default:
      return state;
  }
};

const dryRun = (state = null, action) => {
  switch (action.type) {
    case SET_DRY_RUN:
      return action.dryRun;
    default:
      return state;
  }
};

const running = (state = false, action) => {
  switch (action.type) {
    case SET_EDIT:
      return false;
    case SET_RUNNING:
      return action.running;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  results,
  edit,
  dryRun,
  running,
});

// Store
const store = createStore(rootReducer);

// Components
const TargetDefinitionsTable = ({ results, onEdit, onDelete, onSort }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell onClick={() => onSort('name')}>Name</Table.HeaderCell>
        <Table.HeaderCell onClick={() => onSort('targetCollection')}>
          Target collection
        </Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(results, (result) => (
        <Table.Row key={result.id}>
          <Table.Cell onClick={() => onEdit(result)}>{result.name}</Table.Cell>
          <Table.Cell onClick={() => onEdit(result)}>
            {result.targetCollection}
          </Table.Cell>
          <Table.Cell>
            <Button
              size="small"
              color="yellow"
              icon="pencil"
              onClick={() => onEdit(result)}
            />
            <Button
              size="small"
              color="blue"
              icon="copy"
              onClick={() =>
                onEdit({ ...result, id: uuid(), name: `${result.name} (copy)` })
              }
            />
            <Button
              size="small"
              color="red"
              icon="trash"
              onClick={() => onDelete(result.id)}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
const ImportFromCollection = enhance(() => (
  <Grid columns={2}>
    <Grid.Column>
      <Input field="db" placeholder="database" />
    </Grid.Column>
    <Grid.Column>
      <Input field="collection" placeholder="collection" />
    </Grid.Column>
  </Grid>
));

const FieldFilter = enhance(({ type }) => (
  <Grid columns={2}>
    <Grid.Column>
      <Input field="field" placeholder="Field" />
    </Grid.Column>
    <Grid.Column>
      <Input field="value" placeholder="Value" type={type} />
    </Grid.Column>
  </Grid>
));

const FieldName = ({ Op, ...props }) => (
  <Input {...props} autoFocus placeholder="Field" />
);
const FieldAge = ({ Op, ...props }) => (
  <Input
    {...props}
    autoFocus
    placeholder="Days"
    type="number"
    min="0"
    max="365"
  />
);

const filters = {
  equals: {
    label: 'Equals',
    children: 1,
    nested: true,
    component: FieldFilter,
  },
  regex: { label: 'Regex', children: 1, nested: true, component: FieldFilter },
  true: { label: 'Is True', children: 1, nested: true, component: FieldName },
  set: { label: 'Is Set', children: 1, nested: true, component: FieldName },
  age: { label: 'Older than', children: 1, nested: true, component: FieldAge },
};

const TargetDefinition = customOperator(filters, false);

const TargetDefinitionRoot = customOperator(
  {
    ...filters,
    load: {
      label: 'Load collection',
      children: 1,
      nested: true,
      component: ImportFromCollection,
    },
    not: { label: '$not', children: 1, component: TargetDefinition },
    or: { label: '$or', children: 'n', component: TargetDefinition },
  },
  false,
);

const TargetView = ({
  initialValues,
  running,
  onChange,
  onSave,
  onDryRun,
  onRun,
}) => (
  <Form>
    <div style={{ float: 'right' }}>
      <Button color="blue" onClick={onDryRun}>
        Dry-run
      </Button>
      {running ? (
        <Button color="green" loading>
          Running...
        </Button>
      ) : (
        <Button color="green" onClick={onRun}>
          Apply
        </Button>
      )}
    </div>

    <SweetForm initialValues={initialValues} onChange={onChange}>
      <Form.Field>
        <label>Name</label>
        <Input field="name" autoFocus />
      </Form.Field>
      <Grid columns={2}>
        <Grid.Column>
          <Form.Field>
            <label>Target collection</label>
            <Select
              clearable={false}
              field="targetCollection"
              options={targetCollections}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <label>Priority</label>
            <SelectInt
              clearable={false}
              defaultValue={1}
              field="priority"
              min={0}
              max={2}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
      <Form.Field>
        <label>Definition pipeline</label>
        <List compact component={TargetDefinitionRoot} field="definition" />
      </Form.Field>
    </SweetForm>

    <div style={{ float: 'right' }}>
      <Button size="large" color="green" onClick={onSave}>
        Save
      </Button>
    </div>
    <div style={{ clear: 'right' }} />
  </Form>
);
class TargetDefinitions extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const {
      results,
      edit,
      dryRun,
      running,
      onEdit,
      onSave,
      onDelete,
      onDryRun,
      onRun,
      onClose,
      onSort,
    } = this.props;

    return (
      <div>
        {dryRun ? (
          <Modal
            headerText={`Dry run result (${dryRun.duration} ms)`}
            submitText="Apply on live collection"
            onCancel={onClose}
            onSubmit={() => onRun(edit)}
          >
            <p>
              {dryRun.size} documents matched, {dryRun.size - dryRun.populated}{' '}
              ({Math.round(100 * (1 - dryRun.populated / dryRun.size))} %) not
              populated.
            </p>

            <p>Id samples:</p>
            <pre>{JSON.stringify(dryRun.idSamples)}</pre>

            <p>Source samples:</p>
            <pre>{JSON.stringify(dryRun.sourceSamples, null, '  ')}</pre>
          </Modal>
        ) : null}
        <Grid>
          <Grid.Column width={9}>
            <TargetDefinitionsTable
              results={results}
              onEdit={onEdit}
              onDelete={onDelete}
              onSort={onSort}
            />
            <Button color="green" onClick={() => onEdit({ id: uuid() })}>
              Add target definition
            </Button>
          </Grid.Column>
          <Grid.Column width={7}>
            {edit ? (
              <Segment secondary key={edit.id}>
                <TargetView
                  initialValues={edit}
                  onChange={onEdit}
                  onSave={() => onSave(edit)}
                  onDryRun={() => onDryRun(edit)}
                  onRun={() => onRun(edit)}
                  running={running}
                />
              </Segment>
            ) : null}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapSTargetDefinitions = (state) => ({
  results: state.results,
  edit: state.edit,
  dryRun: state.dryRun,
  running: state.running,
});

const saveTargetDefinition = async (edit) => {
  const targetDefinition = edit;
  return axios.put(
    `${baseUrl}/targets/${targetDefinition.id}`,
    targetDefinition,
  );
};

const mapDTargetDefinitions = (dispatch) => ({
  onLoad: async () => {
    const results = (await axios.get(`${baseUrl}/targets`)).data;
    dispatch(setResults(results));
  },
  onSort: (order) => dispatch(setOrder(order)),
  onEdit: (edit) => dispatch(setEdit(edit)),
  onSave: async (edit) => {
    await saveTargetDefinition(edit);
    dispatch(updateResult(edit));
  },
  onDelete: async (id) => {
    await axios.delete(`${baseUrl}/targets/${id}`);
    dispatch(deleteResult(id));
  },
  onDryRun: async (edit) => {
    await saveTargetDefinition(edit);
    dispatch(updateResult(edit));

    const result = (await axios.get(`${baseUrl}/targets/${edit.id}/dry-run`))
      .data;
    dispatch(setDryRun(result));
  },
  onRun: async (edit) => {
    dispatch(setDryRun(null));
    await saveTargetDefinition(edit);
    dispatch(updateResult(edit));
    dispatch(setRunning(true));
    await axios.get(`${baseUrl}/targets/${edit.id}/run`).data;
  },
  onClose: () => dispatch(setDryRun(null)),
});

const TargetDefinitionsContainer = connect(
  mapSTargetDefinitions,
  mapDTargetDefinitions,
)(TargetDefinitions);

export default () => (
  <Provider store={store}>
    <TargetDefinitionsContainer />
  </Provider>
);
export { TARGET_COLLECTIONS };
