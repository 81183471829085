import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MorningNotifications from './MorningNotifications';
import RepliesNudgeNotifications from './RepliesNudgeNotifications';
import WeeklyStatsNotifications from './WeeklyStatsNotifications';
import CustomNotifications from './CustomNotifications';


const SlackView = () => {
  return (
    <Tabs>
      <TabList>
        <Tab>Morning</Tab>
        <Tab>Nudges</Tab>
        <Tab>Weekly stats</Tab>
        <Tab>Custom</Tab>
      </TabList>
      <TabPanel key="morning">
        <MorningNotifications/>
      </TabPanel>
      <TabPanel key="replies-nudge">
        <RepliesNudgeNotifications/>
      </TabPanel>
      <TabPanel key="weekly-stats">
        <WeeklyStatsNotifications/>
      </TabPanel>
      <TabPanel key="custom">
        <CustomNotifications/>
      </TabPanel>
    </Tabs>
  );
};

export default SlackView;
