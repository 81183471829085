import _ from 'underscore';
import React from 'react';
import { Popup, Icon, Card, Button } from 'semantic-ui-react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const SearchDistributionTree = ({ tree }) => {
  if (!_.isObject(tree)) {
    return null;
  }
  const params = tree.params || {};

  const sons = params.son
    ? [params.son]
    : params.son1 && params.son2
    ? [params.son1, params.son2]
    : params.sons
    ? params.sons
    : params.weightedSons
    ? _.map(params.weightedSons, ({ son }) => son)
    : [];

  const baseParamsString = _.reduce(
    params,
    (memo, val, key) => {
      if (['son', 'sons', 'son1', 'son2', 'weightedSons'].indexOf(key) >= 0) {
        return memo;
      }
      return memo + (memo ? ',' : '') + key + ':' + val;
    },
    '',
  );

  const paramsString = baseParamsString ? '[' + baseParamsString + ']' : '';

  const chartData = tree.stats
    ? _.map(tree.stats, (value, index) => ({
        key: 10 * index + '%',
        value,
      }))
    : [];

  return (
    <div style={{ paddingLeft: '10px', paddinTop: '2px' }}>
      <span>
        {tree.stats ? (
          <Popup
            trigger={<Icon name="bar chart" style={{ cursor: 'pointer' }} />}
            content={
              <LineChart width={300} height={300} data={chartData}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  animationDuration={0}
                />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="key" />
                <YAxis />
              </LineChart>
            }
          />
        ) : null}
        <strong>{tree.id}</strong> {paramsString}
      </span>
      {_.map(sons, (son, index) => (
        <SearchDistributionTree key={index} tree={son} />
      ))}
    </div>
  );
};

const SearchDistribution = ({ scorerStatistics, messageHTML }) => {
  if (!scorerStatistics) {
    return '';
  }
  return (
    <Card fluid>
      <Card.Content>
        <SearchDistributionTree tree={scorerStatistics} />
        <center>
          <div>
            <div dangerouslySetInnerHTML={{ __html: messageHTML }} />
          </div>
        </center>
        <br />
        <center>
          <Button
            onClick={() => {
              console.log(JSON.stringify(scorerStatistics, null, 2));
              console.log(scorerStatistics);
            }}
          >
            See in Console
          </Button>
          <br />
          <br />
        </center>
        <br />
      </Card.Content>
    </Card>
  );
};

export default SearchDistribution;
