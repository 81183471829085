import _ from 'underscore';
import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, Line, CartesianGrid, Tooltip } from 'recharts';
import moment from 'moment/moment';

export default class DashBoardSearchLinkedinSize extends Component {
  render() {
    const timeFormatter = (tick) => {
      return moment(new Date('20' + tick)).format('YY-MM-DD');
    };
    const data = _.sortBy(
      _.map(this.props.stats, (stat) => ({
        x: timeFormatter(stat.date),
        y: stat.count / 1000,
      })),
      'x',
    );
    console.log(data);
    const formattedData = _.map(data, (da) => timeFormatter(da.x));

    console.log(formattedData);

    return (
      <Segment>
        <Header as='h3' textAlign='center'>
          Profils en base (milliers)
        </Header>
        <LineChart width={310} height={150} type='line' data={data} margin={{ top: 0, right: 5, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <Line type='monotone' dataKey='y' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <Tooltip />
          <YAxis
            label={{
              value: 'Nombre de profils (en milliers)',
              angle: -90,
              position: 'insideLeft',
              dy: 70,
              dx: 20,
            }}
          />
        </LineChart>
      </Segment>
    );
  }
}
