import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../../baseUrl';
import WatchHiresweetData from './WatchHiresweetData';
import WatchAdmin from './WatchAdmin';
import WatchStatus from './WatchStatus';
import WatchUpload from './WatchUpload';
import WatchAccount from './WatchAccount';
import WatchEditData from './WatchEditData';
import WatchManualData from './WatchManualData';
import WatchEnrichedProfile from './WatchEnrichedProfile';
import WatchEditAccount from './WatchEditAccount';
import WatchNotifications from './WatchNotifications';
import { SendNotificationModal } from './SendNotificationsModal';
import BlurbGenerator from '../BlurbGenerator/index';
import { Grid, Button, Icon, Input, Tab, Message, Label, Checkbox, Popup } from 'semantic-ui-react';
import Select from 'react-select';
import moment from 'moment';

const transformData = (data) => {
  const cleanedData = {
    ...data,
    ...(data.headline && _.isObject(data.headline)
      ? { headline: data.headline }
      : { headline: { data: { text: '' } } }),
    ...(data.summary && _.isObject(data.summary)
      ? { summary: data.summary }
      : { summary: { contdataent: { text: '' } } }),
    ...(data.location && _.isObject(data.location)
      ? { location: data.location }
      : { location: { name: { default: '' } } }),
  };
  return cleanedData;
};

const getEnrichedProfile = async ({ id, offerId }) => {
  let enrichedProfile;
  if (offerId) {
    enrichedProfile = (await axios.get(`${baseUrl}/candidates/${id}/enrichedProfileForOffer/${offerId}`)).data;
  } else {
    enrichedProfile = (await axios.get(`${baseUrl}/candidates/${id}/enrichedProfileForDefaultOffer`)).data;
  }
  if (enrichedProfile.error) {
    throw Error(JSON.stringify(enrichedProfile.error, null, 4));
  }
  return enrichedProfile;
};

const putPayload = async ({ id, route, payload }) => {
  const result = (await axios.put(`${baseUrl}/candidates/${id}/${route}`, payload)).data;
  if (!result.success || result.error) {
    throw Error(result.error);
  }
};

const addMainEducation = ({ enrichedProfile }) => {
  let mainEducation = null;
  _.each((enrichedProfile || {}).education, (education) => {
    if (education.isMain) {
      mainEducation = education;
    }
  });
  return {
    ...enrichedProfile,
    ...(mainEducation && { mainEducation }),
  };
};

const buildTextOptions = (enrichedProfile) => {
  const schoolNames = _.pluck((enrichedProfile || {}).education, 'schoolName');
  return _.map(schoolNames, (name) => ({ label: name, value: name }));
};

class Candidate extends Component {
  state = {
    candidate: null,
    saved: true,
  };
  componentWillMount() {
    this.handleLoad();
    this.handleLoadOfferIds();
  }

  handleLoad = async () => {
    const { id } = this.props;
    try {
      let responseData = (await axios.get(`${baseUrl}/candidates/${id}?withData=1&withAccount=1`)).data;
      if (!responseData || responseData.error) {
        throw Error('Error loading candidate: ' + JSON.stringify(responseData));
      }
      const candidate = responseData.candidate;
      this.setState({ candidate });
    } catch (e) {
      alert(e);
      this.setState({ candidateError: e.message });
    }
    try {
      let enrichedProfile = await getEnrichedProfile({ id, offerId: this.props.offerId });
      enrichedProfile = addMainEducation({ enrichedProfile }); // for display in ClientProfile component
      this.setState({ enrichedProfile });
    } catch (e) {
      alert(e);
      this.setState({ enrichedProfileError: e.message });
    }
  };

  handleLoadOfferIds = async () => {
    const offers = (await axios.get(`${baseUrl}/offers/list`)).data;
    this.setState({ offerIds: _.pluck(offers, 'id') });
  };

  handleCallbackIfExists = () => {
    const { saveProfileCallback } = this.props;
    if (saveProfileCallback) {
      this.props.saveProfileCallback();
    }
  };

  onPerformStatusAction = async ({ actionId, notificationId }) => {
    const { id } = this.props;
    const action = {
      type: 'execute',
      operation: actionId,
    };
    try {
      const { data } = await axios.post(`${baseUrl}/candidates/${id}/status`, { action });
      if (!data.success) {
        throw Error(data.error);
      } else {
        this.handleLoad();
      }
      const { statusActionCallback } = this.props;
      if (statusActionCallback) {
        this.props.statusActionCallback();
      }
      if (notificationId){
        this.setState({ modalNotificationId: notificationId });
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  onCloseNotificationModal = async () => {
    this.setState({ modalNotificationId: null });
  };

  onSetExtendedEnabledTimestamp = async (timestamp) => {
    const { id } = this.props;
    const action = {
      type: 'extend-enabled',
      extendedEnabledTimestamp: timestamp,
    };
    try {
      const { data } = await axios.post(`${baseUrl}/candidates/${id}/status`, { action });
      if (!data.success) {
        throw Error(data.error);
      } else {
        this.handleLoad();
      }
      const { statusActionCallback } = this.props;
      if (statusActionCallback) {
        this.props.statusActionCallback();
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  onSubmitEmail = async (address) => {
    if (_.isEmpty(address)) {
      throw Error('Empty email');
    }
    const payload = { address };
    await putPayload({
      id: this.props.id,
      route: 'email',
      payload,
    });
    this.handleLoad();
    this.handleCallbackIfExists();
  };
  onReload = async () => {
    await this.handleLoad();
    //this.handleCallbackIfExists();
  };
  onSubmitData = async ({
    firstname,
    lastname,
    photoLink,
    location,
    headline,
    summary,
    hiresweetDescriptions,
    sources,
    education,
  }) => {
    this.setState({ enrichedProfile: null });
    const payload = {
      ...(_.isString(firstname) && { firstname }),
      ...(_.isString(lastname) && { lastname }),
      ...(_.isString(photoLink) && { photoLink }),
      ...(_.isObject(location) && { location }),
      ...(_.isObject(headline) && { headline }),
      ...(_.isObject(summary) && { summary }),
      ...(!_.isEmpty(hiresweetDescriptions) && { hiresweetDescriptions }),
      ...(!_.isEmpty(sources) && { sources }),
      ...(!_.isEmpty(education) && { education }),
    };

    await putPayload({
      id: this.props.id,
      route: 'data',
      payload,
    });
    await this.handleLoad();
    this.handleCallbackIfExists();
  };

  onSubmitHiresweetData = async (hiresweetData) => {
    if (_.isEmpty(hiresweetData)) {
      throw Error('Empty hiresweetData');
    }
    this.setState({ enrichedProfile: null });
    const payload = { 'sourceData.hiresweet': hiresweetData };
    await putPayload({
      id: this.props.id,
      route: 'data',
      payload,
    });
    await this.handleLoad();
    this.handleCallbackIfExists();
  };

  onSubmitAccount = async ({ contactPreferences, onboarding, phoneNumber }) => {
    const payload = {
      ...(_.isObject(contactPreferences) && { contactPreferences }),
      ...(_.isObject(onboarding) && { onboarding }),
      ...(_.isString(phoneNumber) && { phoneNumber }),
    };
    await putPayload({
      id: this.props.id,
      route: 'account',
      payload,
    });
    this.handleLoad();
    this.handleCallbackIfExists();
  };

  onSubmitAdmin = async (admin) => {
    if (_.isEmpty(admin)) {
      throw Error('Empty admin');
    }
    await putPayload({
      id: this.props.id,
      route: 'admin',
      payload: admin,
    });
    this.handleLoad();
    this.handleCallbackIfExists();
  };

  onSubmitManualData = async (manualData) => {
    if (_.isEmpty(manualData)) {
      throw Error('Empty manualData');
    }
    await putPayload({
      id: this.props.id,
      route: 'manualData',
      payload: manualData,
    });
    this.handleLoad();
    this.handleCallbackIfExists();
  };

  handleChangeDelete = (value) => {
    this.setState({ deleteValue: value });
  };
  handleDelete = async () => {
    try {
      const { id } = this.props;
      const result = (await axios.delete(`${baseUrl}/candidates/${id}`)).data;
      if (!result || result.error) {
        throw Error(result.error ? result.error : `Failed to delete item ${id}.`);
      } else {
        alert('Profile deleted !');
        const { deleteProfileCallback } = this.props;
        if (deleteProfileCallback) {
          this.props.deleteProfileCallback();
        }
      }
    } catch (e) {
      console.log(e);
      alert(e.message || `Failed to delete item.`);
    }
  };

  handleToggleTopCandidate = async () => {
    if (!this.state.candidate) {
      return alert('not loaded, wait...');
    }
    await putPayload({
      id: this.props.id,
      route: 'admin',
      payload: { isTopCandidate: !((this.state.candidate || {}).admin || {}).isTopCandidate },
    });
    await this.handleLoad();
    this.handleCallbackIfExists();
  };

  handleMarkUploadsCompleted = async () => {
    if (!this.state.candidate) {
      return alert('not loaded, wait...');
    }
    await putPayload({
      id: this.props.id,
      route: 'admin',
      payload: { lastUploadCompletionTimestamp: new Date().getTime() },
    });
    await this.handleLoad();
    this.handleCallbackIfExists();
  };

  renderLeftPane() {
    const { enrichedProfile, offerIds, candidate, enrichedProfileError, candidateError } = this.state;
    if (enrichedProfileError) {
      return (
        <Message error>
          <Message.Content>{enrichedProfileError}</Message.Content>
        </Message>
      );
    }
    if (candidateError) {
      return <Message error>{candidateError}</Message>;
    }
    if (!enrichedProfile || !candidate) {
      return (
        <Message icon>
          <Icon name='circle notched' loading />
          <Message.Content>
            <Message.Header>Loading...</Message.Header>
          </Message.Content>
        </Message>
      );
    }

    const { account } = candidate;
    const panes = [
      {
        menuItem: 'Profile',
        render: () => (
          <Tab.Pane>
            <WatchEnrichedProfile enrichedProfile={enrichedProfile} offerIds={offerIds} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Account',
        render: () => (
          <Tab.Pane>
            <WatchAccount account={account} />
          </Tab.Pane>
        ),
      },
    ];
    return <Tab panes={panes} />;
  }

  renderRightPane() {
    const { id } = this.props;
    const { candidate, candidateError, enrichedProfile } = this.state;
    if (candidateError) {
      return <Message error>{candidateError}</Message>;
    }
    if (!candidate) {
      return <div>Loading...</div>;
    }
    const { data, manualData, admin, sweetapp, account } = candidate;
    const hiresweetData = ((data || {}).sourceData || {}).hiresweet;
    const appWishes = (hiresweetData || {}).wishes;
    const { sources } = data || {};
    console.log({
      enrichedProfile,
      candidate,
    });
    const panes = [
      {
        menuItem: 'Admin',
        render: () => (
          <Tab.Pane>
            <WatchAdmin admin={admin} onSubmit={this.onSubmitAdmin} id={id} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Edit Profile',
        render: () => {
          return (
            <Tab.Pane>
              <WatchEditData data={data} id={id} onSubmit={this.onSubmitData} onReload={this.onReload} />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: 'Edit Account',
        render: () => {
          return (
            <Tab.Pane>
              <WatchEditAccount
                sources={sources}
                account={account}
                onSubmitAccount={this.onSubmitAccount}
                onSubmitEmail={this.onSubmitEmail}
              />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: 'Hiresweet Data',
        render: () => (
          <Tab.Pane>
            <WatchHiresweetData
              hiresweetData={hiresweetData}
              onSubmit={this.onSubmitHiresweetData}
              textOptions={buildTextOptions(enrichedProfile)}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Generate Blurb',
        render: () => (
          <Tab.Pane>
            <BlurbGenerator notes={(admin || {}).comment} enrichedProfile={enrichedProfile} />
          </Tab.Pane>
        ),
      },
      /*{
        menuItem: 'Manual Data',
        render: () => (
          <Tab.Pane>
            <WatchManualData manualData={manualData} onSubmit={this.onSubmitManualData} appWishes={appWishes} />
          </Tab.Pane>
        ),
      },*/
      {
        menuItem: 'Uploads',
        render: () => (
          <Tab.Pane>
            <WatchUpload sweetapp={sweetapp} id={id} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Notifications',
        render: () => (
          <Tab.Pane>
            <WatchNotifications id={id} />
          </Tab.Pane>
        ),
      },
    ];

    return <Tab panes={panes} />;
  }

  render() {
    const { deleteValue, candidate, modalNotificationId } = this.state;
    const { status, linkedinId, creationTimestamp } = candidate || {};
    const { isTopCandidate } = (candidate || {}).admin || {};
    return (
      <Grid columns={2}>
        <Grid.Column
          style={{
            display: 'inline-block',
            paddingTop: '5px',
            paddingBottom: '20px',
            height: '700px',
            width: '50%',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {this.renderLeftPane()}
        </Grid.Column>
        <Grid.Column
          style={{
            display: 'inline-block',
            paddingTop: '5px',
            paddingBottom: '20px',
            height: '700px',
            width: '50%',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <Grid.Row
            style={{
              marginBottom: '5px',
            }}
          >
            <WatchStatus
              creationTimestamp={creationTimestamp}
              status={status}
              onPerformAction={this.onPerformStatusAction}
              onSetExtendedEnabledTimestamp={this.onSetExtendedEnabledTimestamp}
            />
          </Grid.Row>
          <Grid.Row
            columns={3}
            style={{
              marginBottom: '5px',
            }}
          >
            {!_.isEmpty((candidate || {}).admin) && (
              <Grid.Column width={3}>
                <Checkbox
                  size='tiny'
                  toggle
                  onChange={() => this.handleToggleTopCandidate()}
                  checked={isTopCandidate}
                />
                {isTopCandidate ? <Label color='green'>To review</Label> : <Label color='red'>Not to review</Label>}
              </Grid.Column>
            )}
            {!_.isEmpty((candidate || {}).admin) && (
              <Grid.Column width={3}>
                <Popup
                  content='Mark uploads completed'
                  trigger={
                    <Button size='tiny' color='blue' onClick={() => this.handleMarkUploadsCompleted()}>
                      <Icon name='check' /> Uploads
                      {candidate.admin.lastUploadCompletionTimestamp &&
                        ` ${moment(candidate.admin.lastUploadCompletionTimestamp).format('YYYY-MM-DD')}`}
                    </Button>
                  }
                />
              </Grid.Column>
            )}

            {/*
              <Grid.Column width={3}>
              {deleteValue === 'DELETE' && (
                <Button negative onClick={this.handleDelete}>
                  DELETE
                </Button>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              <Input
                placeholder='Delete ?'
                value={deleteValue}
                onChange={(e, { value }) => this.handleChangeDelete(value)}
              />
            </Grid.Column>
              */}

            <Grid.Column width={3}>
              <Label as='a' target='__blank' href={`https://linkedin.com/in/${linkedinId}`}>
                <Icon name='linkedin' />
                <Label.Detail>{linkedinId}</Label.Detail>
              </Label>
            </Grid.Column>
          </Grid.Row>
          {this.renderRightPane()}
          {modalNotificationId && (
            <SendNotificationModal
              id={this.props.id}
              notificationId={modalNotificationId}
              onClose={this.onCloseNotificationModal}
            />
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export const getCandidatesOptions = async () => {
  const { candidates } = (await axios.get(`${baseUrl}/candidates/miniCandidates`)).data;
  return _.map(_.sortBy(candidates, ({ creationTimestamp }) => creationTimestamp), ({ id, fullname }) => {
    return {
      label: fullname,
      value: id,
    };
  });
};
export class CandidateViewer extends Component {
  state = { offerId: null };

  componentDidMount = async () => {
    try {
      const candidateOptions = await getCandidatesOptions();
      this.setState({ candidateOptions });
    } catch (e) {
      alert(JSON.stringify(e, null, 4));
    }
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  toggleTestOfferId = () => {
    if (this.state.offerId) {
      this.setState({ offerId: null });
    } else {
      this.setState({ offerId: 'test-wif5-demo_integrator_VVYWZ4' });
    }
  };
  render() {
    const { selectedOption, candidateOptions, offerId } = this.state || {};
    const targetId = (selectedOption || {}).value || this.props.match.params.id;
    return (
      <Grid>
        <Grid.Row>
          <Select
            style={{ width: '200px' }}
            options={candidateOptions}
            onChange={this.handleChange}
            value={selectedOption}
          />
          {targetId ? <Label>{targetId}</Label> : null}
        </Grid.Row>
        <Button
          size='tiny'
          onClick={() => {
            this.toggleTestOfferId();
          }}
        >
          {offerId || 'test-offer'}
        </Button>
        <Grid.Row>
          {targetId ? (
            <div>
              <Candidate offerId={offerId} key={`${targetId}${offerId}`} id={targetId} />
            </div>
          ) : (
            <div>No id selected</div>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}
export default Candidate;
