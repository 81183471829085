import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl';
import { Grid, Segment, Popup, Icon, Button } from 'semantic-ui-react';
import { SweetForm, Select } from 'sweetform';

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

const MODEL_IDS = ['gpt-3.5-turbo', 'gpt-4'];

class OpenAiGenerator extends Component {
  state = {
    temperature: 0.1,
    maxTokens: 3000,
    model: 'gpt-4',
  };
  handleChangeParams = ({ temperature, maxTokens, model }) => {
    this.setState({
      temperature,
      maxTokens,
      model,
    });
  };

  handleGenerate = async () => {
    const { prompt } = this.props;
    const { temperature, maxTokens, model } = this.state;
    const payload = { temperature, maxTokens, model, prompt };
    const checkPayload = ({ temperature, maxTokens, prompt }) => {
      if (!prompt) {
        throw Error('Empty prompt');
      }
      if (!_.isNumber(temperature) || temperature > 1 || temperature < 0) {
        throw Error('Invalid temperature, should be in [0, 1]');
      }
      if (!_.isNumber(maxTokens) || maxTokens > 10000 || maxTokens < 0) {
        throw Error('Invalid maxTokens, should be in [0, 10000]');
      }
    };
    try {
      this.setState({ computing: true, result: '' });
      checkPayload(payload);
      console.log(JSON.stringify(payload, null, 4));
      const { result, success, error } = (await axios.post(`${baseUrl}/openAi/completions`, payload)).data;
      console.log(JSON.stringify({ result, success, error }, null, 4));
      if (!success) {
        throw Error(error);
      }
      this.setState({ computing: false, result });
    } catch (e) {
      alert(e.message);
      this.setState({ computing: false });
    }
  };
  render() {
    const { isOk, prompt } = this.props;
    const { result, computing } = this.state;
    return (
      <SweetForm
        style={{ width: '400px' }}
        initialValues={{
          temperature: this.state.temperature,
          maxTokens: this.state.maxTokens,
          model: this.state.model,
        }}
        onChange={this.handleChangeParams}
      >
        <Grid style={{ width: '400px' }}>
          <Grid.Row columns={4}>
            <Grid.Column width={3}>
              <b>Temperature</b>
            </Grid.Column>
            <Grid.Column width={3}>
              <Select
                field='temperature'
                options={_.map([0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1], (v) => ({
                  label: v.toString(),
                  value: v,
                }))}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <b>Tokens</b>
            </Grid.Column>
            <Grid.Column width={3}>
              <Select
                field='maxTokens'
                options={_.map([0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 3001], (v) => ({
                  label: v.toString(),
                  value: v,
                }))}
              />
            </Grid.Column>
          </Grid.Row>

          {this.state.maxTokens == 3001 && (
            <Grid.Row>
              <Grid.Column width={8}>
                <Select field='model' options={_.map(MODEL_IDS, (id)=>({label: id, value: id}))} />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={3}>
              <Popup
                wide='very'
                trigger={
                  <Button color='purple' disabled={!isOk || computing} onClick={this.handleGenerate}>
                    Generate
                  </Button>
                }
                content={
                  <div
                    dangerouslySetInnerHTML={getRawMarkup(prompt)}
                    style={{
                      display: 'inline-block',
                      height: '300px',
                      width: '100%',
                      overflowY: 'scroll',
                      overflowX: 'scroll',
                    }}
                  />
                }
                hoverable
                position='top right'
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2} />
            <Grid.Column width={14}>
              {!_.isEmpty(result) && <Segment dangerouslySetInnerHTML={getRawMarkup(result)} />}
              {computing && <Icon name='circle notched' loading />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SweetForm>
    );
  }
}

export default OpenAiGenerator;
