import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { Card, Container, Form, Grid, Label, Tab, Table } from 'semantic-ui-react';
import { Input, SweetForm } from 'sweetform';

class ParsingResultTable extends Component {
  componentDidMount() {
    this.computeParsingResults();
  }

  computeParsingResults = async () => {
    const { jobId } = this.props;
    const url = baseUrl + '/recognition/getJobsHeadlinesAnalysis/' + jobId;
    const results = (await axios.get(url)).data;
    if (!results.success) {
      alert(results.error);
      return;
    }
    const parsingResults = results.results;
    this.setState({ parsingResults });
  };

  getItemColor = (label) => {
    if (label === 'truePositive') {
      return '#ddffdd';
    }
    if (label === 'falsePositive') {
      return '#ffdddd';
    }
    if (label === 'falseNegative') {
      return '#ffdddd';
    }
    return '#ffffff';
  };

  handleLabelChange = async ({ text, label }) => {
    const url = baseUrl + '/recognition/updateParsingLabel';
    const { jobId } = this.props;
    const params = {
      jobId: jobId,
      text,
      label,
    };
    const result = (await axios.post(url, params)).data;
    if (!result.success) {
      alert(result.error);
    }
  };

  renderCell = (name, key, parsingResult, jobId) => {
    const labelOptions = [
      { value: 'truePositive', key: 'truePositive', text: 'True Positive' },
      { value: 'falsePositive', key: 'falsePositive', text: 'False Positive' },
      { value: 'falseNegative', key: 'falseNegative', text: 'False Negative' },
    ];
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Text' ? (
          <span> {(parsingResult || {}).text} </span>
        ) : name == 'Occurrence' ? (
          <span> {(parsingResult || {}).occ} </span>
        ) : name == 'Ontology Parsing' ? (
          _.map((parsingResult || {}).all_ontology_parse || [], (entityId) => {
            return <Label>{entityId}</Label>;
          })
        ) : name == 'New Parsing' ? (
          _.map((parsingResult || {}).new_system_recognition || [], (result) => {
            return (
              <Label>
                {result.entity} <br /> <small>{result.recognition_type}</small>
              </Label>
            );
          })
        ) : name == 'Label' ? (
          <Form.Select
            options={labelOptions}
            defaultValue={(parsingResult || {}).label}
            key={key}
            onChange={(e, { value }) => {
              this.handleLabelChange({ text: (parsingResult || {}).text, label: value });
            }}
          />
        ) : name == 'Job Confidence' ? (
          parseInt(
            (_.find((parsingResult || {}).new_system_recognition || [], (result) => result.entity === jobId) || {})
              .confidence_job,
          )
        ) : name == 'Global Confidence' ? (
          parseInt(parsingResult.confidence_global)
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const { jobId } = this.props;
    const { parsingResults } = this.state || {};
    const columnNames = [
      'Text',
      'Occurrence',
      'Ontology Parsing',
      'New Parsing',
      'Job Confidence',
      'Global Confidence',
      'Label',
    ];
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>Text</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Occurrence</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Ontology Parsing</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>New Parsing</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Job Confidence</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Global Confidence</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Label</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(parsingResults || []).map((parsingResult, key) => {
            const jobCellKey = 'parsing' + jobId + key;
            return (
              <Table.Row key={jobCellKey} style={{ backgroundColor: this.getItemColor((parsingResult || {}).label) }}>
                {columnNames.map((name, index2) => {
                  return this.renderCell(name, index2 + jobCellKey, parsingResult, jobId);
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

class PivotResultTable extends Component {
  componentDidMount() {
    this.computePivotResults();
  }

  computePivotResults = async () => {
    const { jobId } = this.props;
    const url = baseUrl + '/recognition/getJobsPivotParsingHeadlinesAnalysis/' + jobId;
    const results = (await axios.get(url)).data;
    if (!results.success) {
      alert(results.error);
      return;
    }
    const pivotResults = results.results;
    this.setState({ pivotResults });
  };

  getItemColor = (label) => {
    if (label === 'good') {
      return '#ddffdd';
    }
    if (label === 'missingPatterns') {
      return '#e1c2f2';
    }
    if (label === 'wrongPivot') {
      return '#edcfb2';
    }
    return '#ffffff';
  };

  handleLabelChange = async ({ text, label }) => {
    const url = baseUrl + '/recognition/updatePivotParsingLabel';
    const { jobId } = this.props;
    const params = {
      jobId: jobId,
      text,
      label,
    };
    const result = (await axios.post(url, params)).data;
    if (!result.success) {
      alert(result.error);
    }
  };

  renderCell = (name, key, parsingResult) => {
    const labelOptions = [
      { value: 'missingPatterns', key: 'missingPatterns', text: 'Missing Patterns' },
      { value: 'wrongPivot', key: 'wrongPivot', text: 'Wrong Pivot' },
      { value: 'good', key: 'good', text: 'Good' },
    ];
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Text' ? (
          <span> {(parsingResult || {}).text} </span>
        ) : name == 'Occurrence' ? (
          <span> {(parsingResult || {}).occurrence} </span>
        ) : name == 'Ontology Parsing' ? (
          _.map((parsingResult || {}).ontologyParsing || [], (entityId) => {
            return <Label>{entityId}</Label>;
          })
        ) : name == 'Pivots' ? (
          _.map((parsingResult || {}).pivotParsing || [], (entityId) => {
            return <Label>{entityId}</Label>;
          })
        ) : name == 'Label' ? (
          <Form.Select
            options={labelOptions}
            defaultValue={(parsingResult || {}).label}
            key={key}
            onChange={(e, { value }) => {
              this.handleLabelChange({ text: (parsingResult || {}).text, label: value });
            }}
          />
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const { jobId } = this.props;
    const { pivotResults } = this.state || {};
    const columnNames = ['Text', 'Occurrence', 'Ontology Parsing', 'Pivots', 'Label'];
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>Text</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Occurrence</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Ontology Parsing</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Pivots</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Label</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(pivotResults || []).map((parsingResult, key) => {
            const jobCellKey = 'pivot' + jobId + key;
            return (
              <Table.Row key={jobCellKey} style={{ backgroundColor: this.getItemColor((parsingResult || {}).label) }}>
                {columnNames.map((name, index2) => {
                  return this.renderCell(name, index2 + jobCellKey, parsingResult);
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

class ParsingJobAnalysis extends Component {
  componentDidMount() {
    this.loadJobs();
  }

  async loadJobs() {
    const jobs = await this.getJobs();
    this.setState({ jobs });
  }

  async getJobs() {
    const url = baseUrl + '/recognition/getJobs';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  handleSelectJob = async (jobId) => {
    this.setState({ selectedJobId: jobId });
  };

  handleClickOnSortImplications = () => {
    const { tagScores } = this.state || {};
    const sortedTagScores = _.sortBy(tagScores, 'implicationScore').reverse();
    this.setState({ tagScores: sortedTagScores });
  };

  handleClickOnSortSources = () => {
    const { tagScores } = this.state || {};
    const sortedTagScores = _.sortBy(tagScores, 'sourceScore').reverse();
    this.setState({ tagScores: sortedTagScores });
  };

  render() {
    const { jobs, search, selectedJobId } = this.state || {};
    const indexOfSearch = ({ normalizedForm, id }, search) =>
      (normalizedForm || id || '').toLowerCase().indexOf(search.toLowerCase());
    const filteredJobs = !search
      ? jobs
      : _.sortBy(_.filter(jobs, (job) => indexOfSearch(job, search) >= 0), (job) => indexOfSearch(job, search));
    return (
      <Grid columns={2}>
        <Grid.Column width={3}>
          <Container>
            <Card fuild>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <h3>Jobs</h3>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <SweetForm onChange={this.onChangeSearch}>
                          <Form.Field>
                            <Input field='search' />
                          </Form.Field>
                        </SweetForm>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Header>
                <br />
                <div
                  style={{
                    overflowY: 'scroll',
                    height: 'calc(100vh - 100px)',
                  }}
                >
                  <Card.Description>
                    {(filteredJobs || []).map((job) => {
                      return (
                        <Grid.Row
                          key={job.id}
                          onClick={() => this.handleSelectJob(job.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          <span
                            style={
                              selectedJobId && selectedJobId === job.id
                                ? {
                                    fontWeight: 'bold',
                                    color: 'green',
                                    fontSize: '13px',
                                  }
                                : {}
                            }
                          >
                            {job.normalizedForm || job.id}
                          </span>
                        </Grid.Row>
                      );
                    })}
                  </Card.Description>
                </div>
              </Card.Content>
            </Card>
          </Container>
        </Grid.Column>
        {selectedJobId ? (
          <Grid.Column width={13}>
            {' '}
            <Tab
              panes={[
                {
                  menuItem: 'Parsing Results',
                  render: () => (
                    <Tab.Pane>
                      <div key={'parsing' + selectedJobId}>
                        <ParsingResultTable jobId={selectedJobId} />
                      </div>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: 'Pivot Results',
                  render: () => (
                    <Tab.Pane>
                      <div key={'pivot' + selectedJobId}>
                        <PivotResultTable jobId={selectedJobId} />
                      </div>
                    </Tab.Pane>
                  ),
                },
              ]}
              defaultActiveIndex={0}
            />
          </Grid.Column>
        ) : null}
      </Grid>
    );
  }
}

export default ParsingJobAnalysis;
