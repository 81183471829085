import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Grid, Button, Loader } from 'semantic-ui-react';
import baseUrl from '../../baseUrl';
import EditWorkplaceModal from '../../Sales/SalesBoard/EditWorkplaceModal';
import WorkplaceList from './WorkplaceList';
import WorkplacesSearch, { filterWorkplaces, getPageWorkplacesAndCounts } from './WorkplaceSearch';

class WorkplacesManagement extends Component {
  state = { pageOffset: 0, nbPerPage: 30 };

  async componentWillMount() {
    this.loadWorkplaces();
  }

  loadWorkplaces = async () => {
    let filter = {};
    if (this.props.type) {
      filter = { type: this.props.type };
    }
    const { data } = await axios.post(`${baseUrl}/workplaces`, {
      filter: filter,
    });
    const allWorkplaces = _.sortBy(data || [], ({ id }) => id);
    this.setState({
      allWorkplaces,
    });
  };

  onChangePage = (page) => {
    this.setState({ pageOffset: page });
  };

  onSubmitQuery = (query) => {
    this.setState({
      pageOffset: 0,
      query,
    });
  };

  onOpenAdd = () => {
    this.setState({
      editMode: 'add',
    });
  };

  onOpenEdit = (workplace) => {
    this.setState({
      editMode: 'edit',
      editedWorkplace: workplace,
    });
  };

  onCloseEdit = () => {
    this.setState({
      editMode: null,
      editedWorkplace: null,
    });
  };
  updateOne = async (workplaceId) => {
    let newWorkplace;
    try {
      const { data } = await axios.get(`${baseUrl}/workplaces/${workplaceId}`);
      if (!(data || {}).id) {
        throw Error('not found');
      }
      newWorkplace = data;
    } catch (e) {
      newWorkplace = null;
    }
    let allWorkplaces = [];
    let updated = false;
    _.each(this.state.allWorkplaces, (workplace) => {
      if (workplace.id === workplaceId) {
        if (newWorkplace) {
          updated = true;
          allWorkplaces.push(newWorkplace);
        }
      } else {
        allWorkplaces.push(workplace);
      }
    });
    if (newWorkplace && !updated) {
      allWorkplaces.unshift(newWorkplace);
    }
    this.setState({ allWorkplaces });
  };
  onSubmitAdd = async (workplace) => {
    try {
      const { data } = await axios.post(`${baseUrl}/salesboard/workplaces`, workplace);
      if (data && data.success) {
        this.updateOne(workplace.id);
      }
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  };
  onSubmitEdit = async (input) => {
    try {
      const { data } = await axios.put(`${baseUrl}/salesboard/workplaces/${input.id}`, _.omit(input, 'id'));
      if (data && data.success) {
        this.updateOne(input.id);
      }
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  };
  onDelete = async (workplace) => {
    const workplaceId = (workplace || {}).id;
    if (!workplaceId) return { success: false, error: 'No workplace id provided' };
    try {
      const { data } = await axios.delete(`${baseUrl}/salesboard/workplaces/${workplaceId}`);
      if (data && data.success) {
        this.updateOne(workplace.id);
        return { success: true };
      } else {
        return { success: false, error: (data || {}).error || 'unknown error' };
      }
    } catch (error) {
      return { success: false, error };
    }
  };
  onShuffle = () => {
    this.setState({ allWorkplaces: _.shuffle(this.state.allWorkplaces) });
  };
  onSort = () => {
    this.setState({ allWorkplaces: _.sortBy(this.state.allWorkplaces, (workplace) => workplace.id) });
  };
  render() {
    const { allWorkplaces, editMode, editedWorkplace, query, pageOffset, nbPerPage } = this.state;
    let pageWorkplaces, counts;

    if (allWorkplaces) {
      let filteredWorkplaces = filterWorkplaces(allWorkplaces, query);
      const res = getPageWorkplacesAndCounts(filteredWorkplaces, pageOffset, nbPerPage);
      pageWorkplaces = res.pageWorkplaces;
      counts = res.counts;
    }

    return (
      <div>
        <Grid>
          <Grid.Column width={4}>
            <WorkplacesSearch onSearch={this.onSubmitQuery} />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid.Row>
              <Button onClick={() => this.onOpenAdd()} positive>
                Create
              </Button>
              <Button onClick={() => this.onShuffle()} basic>
                Shuffle
              </Button>
              <Button onClick={() => this.onSort()} basic>
                Sort
              </Button>
            </Grid.Row>
            <Grid.Row>
              {pageWorkplaces ? (
                <WorkplaceList
                  onChangePage={this.onChangePage}
                  workplaces={pageWorkplaces}
                  counts={counts}
                  onSelect={this.onOpenEdit}
                  workplaceType={this.props.type}
                />
              ) : (
                <Loader active inline />
              )}
            </Grid.Row>
          </Grid.Column>
        </Grid>
        {editMode === 'edit' && editedWorkplace ? (
          <EditWorkplaceModal
            initialValues={editedWorkplace}
            onCancel={this.onCloseEdit}
            onSubmit={this.onSubmitEdit}
            onDelete={this.onDelete}
          />
        ) : null}
        {editMode === 'add' && !editedWorkplace ? (
          <EditWorkplaceModal onCancel={this.onCloseEdit} onSubmit={this.onSubmitAdd} creationMode={true} />
        ) : null}
      </div>
    );
  }
}

export default WorkplacesManagement;
