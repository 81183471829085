import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import axios from 'axios';
import { SweetForm, Input, Select } from 'sweetform';

import { Label, LabelGroup, Button, Form, Icon, Modal } from 'semantic-ui-react';
import { Grid } from 'semantic-ui-react';

const generateId = () => {
  var letters = '0123456789AZERTYUIOPQSDFGHJKLMWXCVBN';
  var id = '';
  for (var i = 0; i < 15; i++) {
    id += letters[Math.floor(Math.random() * 30)];
  }
  return id;
};

const languageOptions = [{ label: 'french', value: 'fr' }, { label: 'english', value: 'en' }];

class AddPatternModal extends Component {
  onChange = (params) => {
    this.setState({ ...params });
  };

  render() {
    const { onClose, onSubmit } = this.props;
    return (
      <Modal onClose={onClose} open size='small'>
        <Modal.Content>
          <SweetForm onChange={this.onChange} initialValues={{ ...(this.props.selectedPattern || {}) }}>
            <Input field='text' autoFocus />
            <Select multi fluid field='language' label='Cluster' options={languageOptions} />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() =>
              onSubmit({ ...(this.state || {}), id: (this.props.selectedPattern || {}).id || generateId() })
            }
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class AddWordModal extends Component {
  onChange = (params) => {
    this.setState({ ...params });
  };

  render() {
    const { onClose, onSubmit } = this.props;
    return (
      <Modal onClose={onClose} open size='small'>
        <Modal.Content>
          <SweetForm onChange={this.onChange}>
            <Input field='text' autoFocus />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit({ ...(this.state || {}) })}>
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class PatternsGroupIngestionModal extends Component {
  componentDidMount() {
    const { text } = this.props;
    this.loadData(text);
  }

  loadData = async (text) => {
    const { isLoading } = this.state || {};
    if (isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/knowledgeIngestion/getPatternGroupSuggestion';
    const payload = {
      text: text,
    };
    const data = (await axios.post(url, payload, customHeaders)).data;
    console.log(data);
    this.setState({ ...data, isLoading: false });
  };

  handleChange = async (params) => {
    this.setState({ ...params });
  };

  deleteExtendedPattern = (pattern) => {
    const { extendedPatterns } = this.state || {};
    const filteredRelatedPatterns = _.filter(extendedPatterns, (entity) => {
      return entity !== pattern;
    });
    this.setState({
      extendedPatterns: filteredRelatedPatterns,
    });
  };

  onClickOnPattern = (targetPattern) => {
    this.setState({
      selectedPattern: targetPattern,
      AddPatternModalVisible: true,
    });
  };

  onSubmitAddPattern = (newPattern) => {
    const { extendedPatterns } = this.state || {};
    const newExtendedPatterns = _.map(extendedPatterns, (pattern) => {
      return pattern.id !== newPattern.id ? pattern : newPattern;
    });
    if (!_.find(extendedPatterns, (pattern) => pattern.id == newPattern.id)) {
      newExtendedPatterns.push(newPattern);
    }
    this.setState({
      extendedPatterns: newExtendedPatterns,
      selectedPattern: null,
      AddPatternModalVisible: false,
    });
  };

  onSubmitAddTag = (params) => {
    const { entityThatImplyModalVisible } = this.state || {};
    if (!(params || {}).id || !(params || {}).importance) {
      return;
    }
    if (entityThatImplyModalVisible) {
      const newEntities = this.state.entitiesThatImply || [];
      newEntities.push(params);
      this.setState({
        entityThatImplyModalVisible: false,
        impliedEntityModalVisible: false,
        entitiesThatImply: newEntities,
      });
    } else {
      const newEntities = this.state.impliedEntities || [];
      newEntities.push(params);
      this.setState({
        entityThatImplyModalVisible: false,
        impliedEntityModalVisible: false,
        impliedEntities: newEntities,
      });
    }
  };

  onSubmitAddWord = (params) => {
    const newWord = params.text;
    const { selectedEquivalentClass, equivalentPatterns } = this.state || {};
    const newEquivalentPatterns = _.map(equivalentPatterns, (pattern) => {
      return pattern.id !== selectedEquivalentClass.id
        ? pattern
        : {
            id: pattern.id,
            words: pattern.words.concat(newWord),
          };
    });
    this.setState({
      equivalentPatterns: newEquivalentPatterns,
      AddWordToEquivalentClassVisible: false,
    });
  };

  deleteEquivalentWord = (word, equivalentPattern) => {
    const { equivalentPatterns } = this.state || {};
    const newEquivalentPatterns = _.map(equivalentPatterns, (pattern) => {
      return pattern.id !== equivalentPattern.id
        ? pattern
        : {
            id: pattern.id,
            words: _.filter(pattern.words, (w) => w !== word),
          };
    });
    this.setState({
      equivalentPatterns: newEquivalentPatterns,
    });
  };

  render() {
    const { text, onSubmit, onSkip, onSkipForever } = this.props;
    const { extendedPatterns, selectedPattern, isLoading, equivalentPatterns, AddWordToEquivalentClassVisible } =
      this.state || {};
    const { AddPatternModalVisible } = this.state || {};
    const onClose = () => {
      this.setState({
        entityThatImplyModalVisible: false,
        impliedEntityModalVisible: false,
        AddPatternModalVisible: false,
        selectedPattern: null,
        AddWordToEquivalentClassVisible: false,
      });
    };
    return (
      <div>
        {AddPatternModalVisible ? (
          <AddPatternModal onClose={onClose} onSubmit={this.onSubmitAddPattern} selectedPattern={selectedPattern} />
        ) : null}

        {AddWordToEquivalentClassVisible ? <AddWordModal onClose={onClose} onSubmit={this.onSubmitAddWord} /> : null}

        <SweetForm
          key={Math.random()}
          initialValues={{ text: text, ...(this.state || {}) }}
          onChange={(params) => this.handleChange(params)}
        >
          <Form.Field style={{ width: '100%', height: '100%' }}>
            <h3>Source Text :</h3>

            <Input field='text' autoFocus />
            <a
              style={{ cursor: 'pointer', color: isLoading ? 'grey' : 'orange' }}
              onClick={() => this.loadData((this.state || {}).text || this.props.text)}
            >
              <b>
                <Icon name='undo' /> Refresh Suggestions
              </b>
            </a>
          </Form.Field>
          <Grid columns={2}>
            <Grid.Column>
              <Form.Field style={{ width: '100%', height: '100%' }}>
                {' '}
                <h3>Pattern Group :</h3>
                <LabelGroup>
                  {_.map(extendedPatterns, (pattern, index) => {
                    return (
                      <Label
                        key={index}
                        style={{
                          backgroundColor: '#3EF005',
                        }}
                      >
                        <a
                          style={{ cursor: 'pointer', color: 'black' }}
                          onClick={() => this.deleteExtendedPattern(pattern)}
                        >
                          <Icon name='close' />
                        </a>{' '}
                        <a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.onClickOnPattern(pattern)}>
                          {pattern.text} {' <' + pattern.language + '>'}
                        </a>
                      </Label>
                    );
                  })}
                  <a
                    style={{ cursor: 'pointer', color: 'black' }}
                    onClick={() => this.setState({ AddPatternModalVisible: true })}
                  >
                    <Label> + Add </Label>
                  </a>
                </LabelGroup>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field style={{ width: '100%', height: '100%' }}>
                {' '}
                <h3>Equivalent Class Patterns :</h3>
                <div>
                  {_.map(equivalentPatterns, (equivalentPattern, index) => {
                    return (
                      <LabelGroup key={index}>
                        <h4>{equivalentPattern.id} : </h4>
                        {_.map(equivalentPattern.words, (word, index2) => {
                          return (
                            <Label
                              key={index2}
                              style={{
                                backgroundColor: equivalentPattern.isValidated ? '#3EF005' : 'orange',
                              }}
                            >
                              <a
                                style={{ cursor: 'pointer', color: 'black' }}
                                onClick={() => this.deleteEquivalentWord(word, equivalentPattern)}
                              >
                                <Icon name='close' />
                              </a>{' '}
                              {word}
                            </Label>
                          );
                        })}
                        <a
                          style={{ cursor: 'pointer', color: 'black' }}
                          onClick={() =>
                            this.setState({
                              AddWordToEquivalentClassVisible: true,
                              selectedEquivalentClass: equivalentPattern,
                            })
                          }
                        >
                          <Label> + Add </Label>
                        </a>
                      </LabelGroup>
                    );
                  })}
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid>
          <Button
            color='green'
            onClick={() => onSubmit({ id: this.props.id, text: this.props.text, ...(this.state || {}) })}
          >
            Submit
          </Button>
          <Button
            color='orange'
            onClick={() => onSkip({ id: this.props.id, text: this.props.text, ...(this.state || {}) })}
          >
            Skip
          </Button>
        </SweetForm>
      </div>
    );
  }
}

export default PatternsGroupIngestionModal;
