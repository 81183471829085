import axios from 'axios';
import React from 'react';
import _ from 'underscore';
import GMaps from './GMaps';
import { Grid, Button, Form, Input, Checkbox, Select, Dropdown } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import RSelect from 'react-select';

export default class Location extends React.Component {
  async updateNeighbours() {
    const parentIds = this.state.displayAllParents
      ? this.props.location.belongsTo
      : _.first(this.props.location.belongsTo, 5);
    const childIds = this.state.displayAllChildren
      ? this.props.location.members
      : _.first(this.props.location.members, 5);
    const responseParents = await axios
      .post(`${baseUrl}/locationsV2/locations`, { ids: parentIds })
      .catch(function(error) {
        console.log(error);
      });
    this.setState({ parentLocations: responseParents.data.locations });
    const responseChildren = await axios
      .post(`${baseUrl}/locationsV2/locations`, { ids: childIds })
      .catch(function(error) {
        console.log(error);
      });
    this.setState({ childLocations: responseChildren.data.locations });
  }

  async componentDidMount() {
    await this.updateNeighbours();
    const countries = await axios.get(`${baseUrl}/locationsV2/countries`).catch(function(error) {
      console.log(error);
    });
    const countryOptions = [{ key: 'null', text: 'Null', value: null }].concat(
      _.map(countries.data.results, (c) => ({
        key: c.countryId,
        value: c.countryId,
        text: c.countryName,
      })).sort(function(a, b) {
        if (a.text > b.text) return 1;
        else return -1;
      }),
    );
    let countryName = null
    if (this.props.location.country){
      const response = await axios
      .get(`${baseUrl}/locationsV2/location/${this.props.location.country}`)
      .catch(function(error) {
        console.log(error);
      });
      countryName = (response.data.location || {}).rawName;
    }
    
    await this.setState({ countryOptions, countryName });
  }

  constructor(props) {
    super(props);
    this.state = {
      displayAllChildren: false,
      displayAllParents: false,
      parentLocations: [],
      childLocations: [],
      patternOptions: this.props.patternOptions,
      addedOptions: [],
    };
  }

  updatePatterns = async () => {
    await this.setState({ patternOptions: this.props.patternOptions });
  };

  displayAllNeighbors = async () => {
    await this.setState({
      displayAllChildren: true,
      displayAllParents: true,
    });
    await this.updateNeighbours();
  };
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      addedOptions: [{ text: value, value }, ...prevState.addedOptions],
    }));
  };
  render() {
    const patternOptions = this.state.addedOptions.concat(this.props.patternOptions);
    const countryMemberOptions = _.map(this.state.countryOptions || [], ({ value, text }) => ({ value, label: text }));
    const countryValueToCountry = _.indexBy(countryMemberOptions, 'value');
    const countryMemberValue = _.map(this.props.location.countryMembers, (value) => ({
      value,
      label: (countryValueToCountry[value] || {}).label,
    }));
    return (
      <div>
        <Grid rows={1}>
          <Grid.Column width={6}>
            <GMaps zoom={10} box={this.props.location.shape.boxes[0]} />
          </Grid.Column>
          <Grid.Column width={10}>
            <h1>{this.props.location.rawName}</h1>
            {this.props.mini ? (
              <div>{this.state.countryName}</div>
            ) : (
              <Form onSubmit={this.props.handleSubmit}>
                <Form.Field inline>
                  <label>Id</label>
                  <Input
                    name='id'
                    value={this.props.location.id}
                    onChange={this.props.handleInputChange}
                    readOnly={!this.props.editableId}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label>Location Name</label>
                  <Input
                    name='rawName'
                    value={this.props.location.rawName}
                    readOnly={!this.props.editable}
                    onChange={this.props.handleInputChange}
                  />
                </Form.Field>
                <Form.Group inline>
                  <label>Bounding Box:</label>
                  {_.map(this.props.location.shape.boxes[0], (v, i) => (
                    <Form.Field inline>
                      <label>{i}:</label>
                      <Input
                        value={this.props.location.shape.boxes[0][i]}
                        name={i}
                        readOnly={!this.props.editable}
                        onChange={this.props.handleBoundingBoxChange}
                      />
                    </Form.Field>
                  ))}
                </Form.Group>
                <Form.Field inline>
                  <label>Type</label>
                  <Input
                    value={this.props.location.type}
                    name='type'
                    readOnly={!this.props.editable}
                    onChange={this.props.handleInputChange}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label>Country</label>
                  <Select
                    options={this.state.countryOptions}
                    value={this.props.location.country}
                    name='country'
                    readOnly={!this.props.editable}
                    onChange={this.props.handleInputChange}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label>Country Members</label>

                  <RSelect
                    name='countryMembers'
                    multi
                    options={countryMemberOptions}
                    value={countryMemberValue}
                    onChange={this.props.handleChangeCountryMembers}
                    placeholder='Only for type "country-group"'
                    //readOnly={!this.props.editable}
                  />
                </Form.Field>

                <Form.Field inline>
                  <label>Displayable:</label>
                  <Checkbox
                    name='displayable'
                    checked={this.props.location.displayable}
                    readOnly={!this.props.editable}
                    onChange={this.props.handleCheckboxChange}
                  />
                </Form.Field>
                {this.props.location.displayable ? (
                  <Form.Field inline>
                    <label>Display Name</label>
                    <Input
                      value={this.props.location.name}
                      name='name'
                      readOnly={!this.props.editable}
                      onChange={this.props.handleInputChange}
                    />
                  </Form.Field>
                ) : null}
                <Form.Field inline>
                  <label> Patterns: </label>
                  <Dropdown
                    options={patternOptions}
                    placeholder='Choose patterns'
                    search
                    selection
                    fluid
                    multiple
                    allowAdditions
                    value={this.props.patternValue}
                    onAddItem={this.handleAddition}
                    onChange={this.props.handlePatterns}
                  />
                </Form.Field>
                <Form.Button content='Submit' />
              </Form>
            )}
            <Grid rows={1}>
              {this.props.mini ? null : (
                <Grid.Column width={8}>
                  <h2>Contains</h2>
                  <ul>
                    {_.map(this.state.childLocations, (child) => (
                      <li>
                        <a href={`/monitoring/knowledge/locations_v2/${child.id}`}> {child.rawName}</a>
                      </li>
                    ))}
                  </ul>
                  <Button onClick={this.displayAllNeighbors}>Show more</Button>
                </Grid.Column>
              )}
              {this.props.mini ? null : (
                <Grid.Column width={8}>
                  <h2>Belongs to</h2>
                  <ul>
                    {_.map(this.state.parentLocations, (parent) => (
                      <li>
                        <a href={`/monitoring/knowledge/locations_v2/${parent.id}`}> {parent.rawName}</a>
                      </li>
                    ))}
                  </ul>
                  <Button onClick={this.displayAllNeighbors}>Show more</Button>
                </Grid.Column>
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
