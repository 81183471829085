import _ from 'underscore';
import React from 'react';
import {
  Grid,
} from 'semantic-ui-react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

class SendGraph extends React.PureComponent {

  state = {}

  handleSetCountMode = () => {
    this.setState({ dailyMode: false });
  }

  handleSetDailyMode = () => {
    this.setState({ dailyMode: true });
  }

  render() {
    const { offers, offerEvents, stats } = this.props;

    if (!offers || !offerEvents || !stats) {
      return <div />;
    }

    const slots = _.last(
      stats[this.props.splitMode].slots,
      this.props.nbSplits,
    );

    const clientIdFromOfferId = {};
    _.each(offers, ({ platformId, platformClientId }) => {
      clientIdFromOfferId[platformId] = platformClientId;
    });

    const data = _.map(slots, ({ title, byUser, total, beginDay, endDay, nbWeekDays }) => {
      const result = {
        name: title,
      };
      const seenClientIds = {};
      _.each(offerEvents, ({ type, count, day, itemId, salesOwner }) => {
        if (day >= beginDay && day < endDay && clientIdFromOfferId[itemId]) {
          const clientId = clientIdFromOfferId[itemId];
          if (!seenClientIds['total']) {
            seenClientIds['total'] = {};
          }
          seenClientIds['total'][clientId] = true;
          if (salesOwner) {
            if (!seenClientIds[salesOwner]) {
              seenClientIds[salesOwner] = {};
            }
            seenClientIds[salesOwner][clientId] = true;
          }
        }
      });

      const round = (x) => Math.round(100 * x) / 100;

      result['total'] = (total['sends'] || {}).count || 0;
      result['total_by_client'] = round(result['total'] / (
        Math.max(1, _.keys(seenClientIds['total'] || {}).length)
      ));

      _.each(byUser, (userStats, user) => {
        result[user] = (userStats['sends'] || {}).count || 0;
        result[user + '_by_client'] = round(result[user] / (
          Math.max(1, _.keys(seenClientIds[user] || {}).length)
        ));
      });

      if (this.state.dailyMode) {
        _.each(_.keys(result), (key) => {
          if (key !== 'name') {
            result[key] = round(result[key] / (nbWeekDays || 1));
          }
        });
      }

      return result;
    });

    const userKey = this.props.targetUser || 'total';

    const style = (key) => {
      const selected = (
        this.state.target === key
      ) || (
        key === 'daily' && this.state.dailyMode      
      ) || (
        key === 'count' && !this.state.dailyMode
      );
      return {
        cursor: 'pointer',
        color: selected ? 'blue': 'lightgray',
      };
    }

    return (
      <Grid><Grid.Row>
        <Grid.Column width={12}>
          <ComposedChart
            data={data}
            width={500} height={200} 
            margin={{ left: -10 }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip formatter={(value, name) => {
              if (name.indexOf('by_client') >= 0) {
                return [value, 'byClient'];
              }
              return value;
            }}/>
            <Bar yAxisId='left' dataKey={userKey} fill={this.props.fillColor} />,
            <Line yAxisId='right' dataKey={userKey + '_by_client'} stroke='blue' />,
          </ComposedChart>
        </Grid.Column>
        <Grid.Column width={4}>
          <h3>{this.props.title}</h3>        
          <br/>
          <div>
            <span style={style('count')} onClick={this.handleSetCountMode}>Count</span><span> | </span>
            <span style={style('daily')} onClick={this.handleSetDailyMode}>Daily</span>
          </div>
        </Grid.Column>
      </Grid.Row></Grid>
    );
  }
}

export default SendGraph;
