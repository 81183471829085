import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import axios from 'axios';
import { Progress } from 'semantic-ui-react';
import PatternsGroupIngestionModal from './PatternsGroupIngestionModal';

class LabelingPipe extends Component {
  componentDidMount() {
    this.handleLoadData();
  }

  async loadData() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { pipeId } = this.props;
    const url = baseUrl + '/knowledgeIngestion/getMiniTasks/' + pipeId;
    const data = (await axios.get(url, customHeaders)).data;
    return (data || {}).data || [];
    //return [{ text: 'deep learning', status: 'pending' }, { text: 'cardiologist', status: 'done' }];
  }

  handleLoadData = async () => {
    const items = await this.loadData();
    this.setState({ items });
  };

  setAsDone = async (targetItem) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { pipeId } = this.props;

    const payload = {
      taskPipeId: pipeId,
      item: { id: targetItem.id, text: targetItem.text },
    };
    const url = baseUrl + '/knowledgeIngestion/submitItem';
    await axios.post(url, payload, customHeaders);
    const items = (this.state || {}).items || [];
    const newItems = _.map(items, (item) => {
      return item.id == targetItem.id ? { ...item, status: 'done' } : item;
    });
    this.setState({ items: newItems });
  };

  onSubmit = async (targetItem) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { pipeId } = this.props;

    const payload = {
      taskPipeId: pipeId,
      item: targetItem,
    };
    const url = baseUrl + '/knowledgeIngestion/submitItem';
    await axios.post(url, payload, customHeaders);
    const items = (this.state || {}).items || [];
    const newItems = _.map(items, (item) => {
      return item.id == targetItem.id ? { ...item, status: 'done' } : item;
    });
    this.setState({ items: newItems });
  };

  render() {
    const items = (this.state || {}).items || [];
    const pendingItems = _.sortBy(_.filter(items, (item) => item.status == 'pending'), (item) => item.priority || 0);
    const currentItem = (this.state || {}).currentItem || pendingItems[0];
    const percent = ((items.length - pendingItems.length) * 100) / items.length;
    return (
      <div>
        <Progress percent={percent}>
          {items.length - pendingItems.length} / {items.length}{' '}
        </Progress>
        {currentItem ? (
          <PatternsGroupIngestionModal
            {...currentItem}
            onSubmit={this.onSubmit}
            onSkip={this.setAsDone}
            key={currentItem.id}
          />
        ) : (
          'No More Tasks In Pipe'
        )}
      </div>
    );
  }
}

export default LabelingPipe;
