import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import baseUrl from './baseUrl.js';
import { Grid, Header, Segment } from 'semantic-ui-react';

class SearchConfig extends Component {
  componentDidMount() {
    this.loadConfig();
  }
  loadConfig() {
    this.setState(() => {
      axios
        .get(baseUrl + '/sweetsearch/ABTests')
        .then(({ data }) => {
          if (!data) {
            throw Error('no data for /search/config');
          }
          if (data.error) {
            throw Error(data.error);
          }
          this.setState({
            ABTests: data.ABTests,
          });
        })
        .catch((e) => {
          alert(e.message);
        });
    });
    this.render();
  }
  processText(text) {
    return (
      <Segment secondary>
        <Grid>
          {(text || '').split('\n').map((item, i) => (
            <Grid.Row key={i}>
              <code>{item}</code>
            </Grid.Row>
          ))}
        </Grid>
      </Segment>
    );
  }

  render() {
    return (
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Header as="h1">AB Tests</Header>
          </Grid.Row>
          {_.map((this.state || {}).ABTests, (ABTest) => (
            <Segment color="grey" padded>
              <Grid.Row padded>
                <Header as="h2">{(ABTest || {}).experimentId}</Header>
              </Grid.Row>
              <Grid.Row padded>{this.processText((ABTest || {}).results)}</Grid.Row>
            </Segment>
          ))}
        </Grid.Column>
      </Grid>
    );
  }
}

export default SearchConfig;
