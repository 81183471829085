import _ from 'underscore';
import { normalizeLinkedin, GRADES } from '../Candidate/utils';
import React, { Component } from 'react';
import { Grid, Button, Form, Input, Modal, Divider, Header, Select } from 'semantic-ui-react';

class AddProfileModal extends Component {
  state = {};

  changeLinkedinId(linkedinId) {
    this.setState({ linkedinId });
  }

  changeFirstname(firstname) {
    this.setState({ firstname });
  }

  changeLastname(lastname) {
    this.setState({ lastname });
  }

  changeSource(source) {
    this.setState({ source });
  }
  changeGrade(grade) {
    this.setState({ grade });
  }

  onClickSubmit = async () => {
    const { firstname, lastname, linkedinId, source, grade } = this.state;
    const cleanedLinkedinId = normalizeLinkedin({ linkedin: linkedinId });
    const result = await this.props.onSubmitAddCandidate({
      firstname,
      lastname,
      linkedinId: cleanedLinkedinId,
      source,
      grade,
    });
    if (result.error) {
      alert(JSON.stringify(result.error));
    } else {
      alert('Candidate successfully added', linkedinId);
      this.props.onCloseEdition();
    }
  };

  render() {
    const { sources } = this.props;
    const sourcesOptions = _.map(sources, ({ id, internalName }) => ({ value: id, text: internalName, key: id }));
    const gradeOptions = _.map(GRADES, ({ value }) => ({ value, text: value, key: value }));
    console.log('sourcesOptions', sourcesOptions);
    const { linkedinId, firstname, lastname, source, grade } = this.state;
    return (
      <Modal onClose={this.props.onCloseEdition} open closeIcon>
        <Modal.Header>Add profile</Modal.Header>
        <Modal.Content>
          <center>
            <Grid columns={2}>
              <Grid.Column width={8}>
                <Form.Field>
                  <label>
                    <b> Source </b>
                  </label>
                  <Select
                    options={sourcesOptions}
                    value={source}
                    onChange={(e, { value }) => this.changeSource(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <b> Grade </b>
                  </label>
                  <Select options={gradeOptions} value={grade} onChange={(e, { value }) => this.changeGrade(value)} />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Field>
                  <label>
                    <b> Linkedin </b>
                  </label>
                  <Input value={linkedinId} onChange={(e, { value }) => this.changeLinkedinId(value)} />
                </Form.Field>
                <Divider horizontal>
                  <Header as='h4'>OR</Header>
                </Divider>
                <Form.Field>
                  <label>
                    {' '}
                    <b> Firstname </b>{' '}
                  </label>
                  <Input value={firstname} onChange={(e, { value }) => this.changeFirstname(value)} />
                </Form.Field>
                <Form.Field>
                  <label>
                    {' '}
                    <b> Lastname </b>{' '}
                  </label>
                  <Input value={lastname} onChange={(e, { value }) => this.changeLastname(value)} />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </center>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => this.onClickSubmit(linkedinId)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default AddProfileModal;
