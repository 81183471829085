import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { SweetForm, Select, List, enhance, Checkbox } from 'sweetform';
import { Textarea } from '../common';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { Segment } from 'semantic-ui-react';
import { EditOmegaCriteria, formCriteriaToSchemaCriteria } from '../Sales/SalesBoard/EditOmegaCriteria';
import { buildFilter, formScorerToQuery } from '../Offers/SearchModal';

const getQueryOperatorOptions = () => {
  return [
    {
      label: 'First X',
      value: 'first',
    },
    {
      label: 'Count',
      value: 'count',
    },
    {
      label: 'Count Distinct',
      value: 'count-distinct',
    },
    {
      label: 'Sum',
      value: 'sum',
    },
    {
      label: 'Max',
      value: 'max',
    },
    {
      label: 'Min',
      value: 'min',
    },
  ];
};

const getLimitOptions = () => {
  return [
    {
      label: '10',
      value: 10,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
    {
      label: '500',
      value: 500,
    },
    {
      label: '1000',
      value: 1000,
    },
    {
      label: '5000',
      value: 5000,
    },
    {
      label: '10000',
      value: 10000,
    },
  ];
};

const getQueryOptions = (keyType) => {
  const result =
    keyType == 'searchable-profiles'
      ? [
          {
            label: 'Precomputed',
            value: 'precomputed',
          },
          {
            label: 'Raw',
            value: 'raw',
          },
          {
            label: 'Search Params',
            value: 'search-params',
          },
        ]
      : [
          {
            label: 'Precomputed',
            value: 'precomputed',
          },
          {
            label: 'Raw',
            value: 'raw',
          },
        ];
  return result;
};

const getOutputOptions = () => {
  return [
    {
      label: 'CSV',
      value: 'csv',
    },
    {
      label: 'Inline',
      value: 'inline',
    },
  ];
};

const getOperators = () => {
  return [
    {
      label: '>=',
      value: '>=',
    },
    {
      label: '>',
      value: '>',
    },
    {
      label: '<=',
      value: '<=',
    },
    {
      label: '<',
      value: '<',
    },
    {
      label: '=',
      value: '=',
    },
    {
      label: 'Included In',
      value: 'IN UNNEST',
    },
  ];
};

const schemaToOption = (schema) => {
  return _.map(schema, (row) => ({
    label: row.name,
    value: row.name,
  }));
};

const schemaToSortKeyOption = (schema, queryType) => {
  const basicKeys = _.map(_.filter(schema, (row) => row.fieldType == 'INTEGER'), (row) => ({
    label: row.name,
    value: row.name,
  }));
  const extendedKeys =
    queryType == 'search-params' ? basicKeys.concat({ label: ' 🔥Prescoring🔥', value: 'prescorer' }) : basicKeys;
  return extendedKeys;
};
const downloadResult = (lastQueryResult) => {
  // Convert to csv
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = _.uniq(_.flatten(_.map(lastQueryResult.results, (result) => _.keys(result)))).sort();
  let csv = lastQueryResult.results.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','),
  );
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');
  // Download csv
  var csvData = new Blob([csv], { type: 'text/csv' });
  var csvUrl = URL.createObjectURL(csvData);
  var hiddenElement = document.createElement('a');
  const filename = `export.csv`;
  hiddenElement.href = csvUrl;
  hiddenElement.download = filename;
  hiddenElement.click();
};
const prettyDisplayResult = (lastQueryResult, outputType) => {
  const columnNames = outputType == 'float' ? null : Object.keys(lastQueryResult.results[0]);
  return (
    <Segment>
      {outputType == 'float' ? (
        lastQueryResult.results
      ) : (
        <div
          style={{
            display: 'inline-block',
            paddingTop: '5px',
            paddingBottom: '20px',
            height: '300px',
            width: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <Table
            style={{
              width: '50%',
            }}
          >
            <Table.Header>
              <Table.Row>
                {_.map(columnNames, (item, index) => {
                  return (
                    <Table.HeaderCell key={index} textAlign='left'>
                      {item}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(lastQueryResult.results, (row, index) => (
                <Table.Row key={index}>
                  {_.map(row, (value, key) => (
                    <Table.Cell key={key} textAlign='left'>
                      {value == undefined || value == null ? 'NULL' : value}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </Segment>
  );
};
const PrecomputedFilterQuery = enhance((props) => (
  <Grid columns={3}>
    <Grid.Column>
      <Select field='field' fluid options={schemaToOption(props.schema)} placeholder='skill__python' />
    </Grid.Column>
    <Grid.Column>
      <Select field='operator' fluid options={props.operators} placeholder='=' />
    </Grid.Column>
    <Grid.Column>
      <Textarea
        field='comparedValue'
        style={{ display: 'table-cell', verticalAlign: 'middle', resize: 'none', height: '35px', overflow: 'hidden' }}
        placeholder=''
      />
    </Grid.Column>
  </Grid>
));
class GBQQueryCollectionView extends Component {
  async onSubmit(sqlQuery, outputType, outputFormat) {
    const payload = {
      sqlQuery,
      outputType,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/searchBigQuery';
    const data = (await axios.post(url, payload, customHeaders)).data;
    if (data.success == false) {
      alert('INVALID QUERY');
      return;
    }
    if (data && outputFormat == 'csv' && outputType == 'jsonArray') {
      downloadResult(data);
    }
    this.setState({
      lastQueryResult: data,
    });
    await this.handleEstimation(sqlQuery);
    await this.logQueryUser(sqlQuery, this.state.estimatedPrice);
    return;
  }
  async logQueryUser(sqlQuery, estimatedPrice) {
    const payload = {
      sqlQuery,
      estimatedPrice,
      timestamp: Date.now(),
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/logQueryUser';
    await axios.post(url, payload, customHeaders);
    return;
  }
  filterToQuery(filter) {
    return (
      (filter.field == undefined ? '' : filter.field) +
      ' ' +
      (filter.operator == undefined ? '' : filter.operator) +
      ' ' +
      (filter.comparedValue == undefined ? '' : filter.comparedValue)
    );
  }
  async handleChange(params) {
    const { tableId, datasetId, projectId } = this.props || {};
    const projKeys =
      params.operator == 'count'
        ? 'COUNT(*)'
        : params.operator == 'count-distinct'
        ? 'COUNT(DISTINCT(' + params.operatorParam + '))'
        : params.operator == 'sum'
        ? 'SUM(' + params.operatorParam + ')'
        : params.operator == 'max'
        ? 'MAX(' + params.operatorParam + ')'
        : params.operator == 'min'
        ? 'MIN(' + params.operatorParam + ')'
        : '' + (params.projectionKeys || 'linkedin_id').replaceAll(';', ', ') + '';
    const tableDefinition = '`' + projectId + '.' + datasetId + '.' + tableId + '`';
    const whereQuery =
      params.query == 'raw'
        ? params.rawQuery
        : params.query == 'precomputed'
        ? _.map(params.extraFields, (filter) => this.filterToQuery(filter)).join(' AND ')
        : 'True';
    const increasingQuery = params.increasing ? 'ASC' : 'DESC';
    const sortByQuery =
      params.sortKey && params.sortKey != null && params.sortKey != undefined
        ? ' ORDER BY ' + params.sortKey + ' ' + increasingQuery
        : '';
    const suffix = params.operator == 'first' ? ' LIMIT ' + params.limit : '';
    const newSQLQuery =
      params.query == 'search-params'
        ? 'Dynamic Query Preview Not Available In Search Params Mode'
        : 'SELECT ' + projKeys + ' FROM ' + tableDefinition + ' WHERE ' + whereQuery + ' ' + sortByQuery + suffix;
    this.setState({
      queryOperator: params.operator,
      queryType: params.query,
      currentSQLQuery: newSQLQuery,
      lastQueryResult: null,
      outputFormat: params.outputFormat,
      projectionKeys: params.projectionKeys,
      increasing: params.increasing == undefined ? false : params.increasing,
      criteria: params.criteria == undefined ? {} : formCriteriaToSchemaCriteria(params.criteria),
      filters: params.filters == undefined ? [] : formScorerToQuery(params.filters),
      scorer: { id: params.sortKey },
    });
  }
  async handleEstimation(sqlQuery) {
    const payload = {
      query: sqlQuery,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/queryCostEstimator';
    const data = (await axios.post(url, payload, customHeaders)).data;
    if (data.success == false) {
      alert('INVALID QUERY');
      return;
    }
    this.setState({
      estimatedPrice: data.cost,
    });
  }
  async handleRefreshQuery() {
    const { criteria, filters, scorer, projectionKeys, increasing } = this.state || {};
    const { projectId, datasetId, tableId } = this.props;
    const payload = {
      criteria,
      filters,
      scorer,
      projectId,
      datasetId,
      tableId,
      limit: 1000,
      projectionKeys: projectionKeys.split(';'),
      increasing,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/computeSqlQuery';
    const data = (await axios.post(url, payload, customHeaders)).data;
    if (data.success == false) {
      alert('Error happened');
      return;
    }
    this.setState({
      currentSQLQuery: data.sqlQuery,
    });
  }
  getInitialSqlQuery = () => {
    const { tableId, datasetId, projectId } = this.props || {};
    const tableDefinition = '`' + projectId + '.' + datasetId + '.' + tableId + '`';
    const initalQuery = 'SELECT COUNT(*) FROM ' + tableDefinition + ' WHERE True';
    return initalQuery;
  };
  render() {
    const { keyType, schema, displayEstimatedCost } = this.props;
    const queryOperatorOptions = getQueryOperatorOptions();
    const queryOptions = getQueryOptions(keyType);
    const queryType = (this.state || {}).queryType;
    const initalQuery = this.getInitialSqlQuery();
    const currentSQLQuery = (this.state || {}).currentSQLQuery || initalQuery;
    const queryOperator = (this.state || {}).queryOperator || '';
    const outputOptions = getOutputOptions();
    const limitOptions = getLimitOptions();
    const lastQueryResult = (this.state || {}).lastQueryResult;
    var outputFormat = (this.state || {}).outputFormat;
    const estimatedPrice = (this.state || {}).estimatedPrice == undefined ? 0 : (this.state || {}).estimatedPrice;
    const operators = getOperators();
    const colorStyle = estimatedPrice < 0.1 ? 'green' : estimatedPrice < 0.5 ? 'orange' : 'red';
    const outputType = currentSQLQuery.includes('COUNT') ? 'float' : 'jsonArray';
    return (
      <div>
        <SweetForm
          initialValues={{ outputFormat: 'inline', operator: 'count' }}
          onChange={(params) => this.handleChange(params)}
        >
          <Grid columns={2}>
            <Grid.Column>
              <Form.Field>
                {' '}
                Query Type :
                <div>
                  <Grid.Column>
                    <Select
                      fluid
                      field='operator'
                      label='Operator'
                      options={queryOperatorOptions}
                      placeholder='Query Operator'
                    />
                  </Grid.Column>
                  {queryOperator == 'first' ? (
                    <Grid.Column>
                      <Select fluid field='limit' label='Limit' options={limitOptions} placeholder='10' />
                    </Grid.Column>
                  ) : null}
                  {['count-distinct', 'sum', 'max', 'min'].includes(queryOperator) ? (
                    <Grid.Column>
                      <Select
                        fluid
                        field='operatorParam'
                        label='OperatorParam'
                        options={
                          queryOperator == 'count-distinct'
                            ? schemaToOption(schema)
                            : schemaToSortKeyOption(schema, queryType)
                        }
                        placeholder='skill__python'
                      />
                    </Grid.Column>
                  ) : null}
                </div>
              </Form.Field>
              <Form.Field>
                Query Format :
                <Select fluid field='query' label='Query' options={queryOptions} placeholder='Query Format' />
                {queryType == 'raw' ? (
                  <div>
                    <br />
                    <Textarea
                      style={{ width: '100%' }}
                      field='rawQuery'
                      label='Raw Query Editor'
                      placeholder='Write filter query - ex: job__data_science >= 45'
                    />
                  </div>
                ) : null}
                {queryType == 'precomputed' ? (
                  <List
                    schema={schema}
                    operators={operators}
                    component={PrecomputedFilterQuery}
                    field='extraFields'
                    compact
                  />
                ) : null}
              </Form.Field>
              <Form.Field>
                {' '}
                Sorting Key :
                <Select
                  fluid
                  field='sortKey'
                  label='Type'
                  options={schemaToSortKeyOption(schema, queryType)}
                  placeholder='Sort Key'
                />
                <Grid.Column width={5} verticalAlign='middle'>
                  <strong>Increasing</strong>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Checkbox field='increasing' />
                </Grid.Column>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                {' '}
                Projection Keys :
                <Select
                  field='projectionKeys'
                  multi={true}
                  fluid
                  label='Projection Keys'
                  options={schemaToOption(schema)}
                  placeholder='Projection Keys'
                />
              </Form.Field>
              <Form.Field>
                {' '}
                Output Format :
                <Select field='outputFormat' fluid label='Output' options={outputOptions} placeholder='Output Format' />
              </Form.Field>
            </Grid.Column>
            <Grid.Row>
              {queryType == 'search-params' ? (
                <div>
                  <Segment>
                    <Form.Field>
                      <h1>Criteria :</h1>
                      <EditOmegaCriteria field='criteria' />
                    </Form.Field>
                    <Form.Field>
                      <h1>Filters :</h1>
                      <List field='filters' component={buildFilter(false, true)} />
                    </Form.Field>
                  </Segment>
                </div>
              ) : null}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button color='green' onClick={() => this.onSubmit(currentSQLQuery, outputType, outputFormat)}>
                  Submit
                </Button>
              </Grid.Column>
              <Grid.Column>
                {queryType == 'search-params' ? (
                  <div style={{ cursor: 'pointer' }} onClick={() => this.handleRefreshQuery()}>
                    <Icon name='refresh' />
                    <a> compute preview </a>
                  </div>
                ) : null}
                <b>Preview :</b> {currentSQLQuery}
              </Grid.Column>
              {displayEstimatedCost ? (
                <Grid.Column>
                  <Icon
                    name='refresh'
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.handleEstimation(currentSQLQuery)}
                  />
                  <b style={{ color: colorStyle }}>
                    Estimated Cost : {estimatedPrice}
                    {'$'}
                  </b>
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        </SweetForm>
        {lastQueryResult
          ? outputFormat == 'inline' || outputType == 'float'
            ? prettyDisplayResult(lastQueryResult, outputType)
            : null
          : null}
      </div>
    );
  }
}
export default GBQQueryCollectionView;
