import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import {
  Statistic,
  Icon,
  Dropdown,
  Grid,
} from 'semantic-ui-react';

import OnboardingLevel from './OnboardingLevel';
import SendsGraph from './SendsGraph';
import SyntheticGraph from './SyntheticGraph';
import ActivityGraph from './ActivityGraph';
import SendsSkipsGraph from './SendsSkipsGraph';
import SalesWarnings from './SalesWarnings';

import baseUrl from '../baseUrl.js';


const buildSlotStats = (events, slot) => {
  const byItemId = {};
  _.each(events, ({ day, type, itemId, salesOwner }) => {
    if (type === 'onboarding' && day >= slot.beginDay && day < slot.endDay) {
      byItemId[itemId] = {
        onboardingDay: day,
        salesOwner,
      };
    }
  });

  const byType = {};
  const byUserAndType = {};
  _.each(events, ({ day, type, itemId, salesOwner, count, synthetic }) => {
    const isUnattachedType = ['sends', 'skips'].indexOf(type) >= 0;

    if (!byItemId[itemId] && !isUnattachedType) {
      return;
    }

    if (isUnattachedType && (day < slot.beginDay || day >= slot.endDay)) {
      return;
    }

    let delayInDays = 0;
    if (byItemId[itemId]) {
      const delayInMs =
        new Date('20' + day).getTime() -
        new Date('20' + byItemId[itemId].onboardingDay).getTime();
      if (delayInMs < 0) {
        console.log('ERROR');
        console.log({ day, type, itemId, salesOwner });
        console.log(byItemId[itemId].onboardingDay);
      }
      delayInDays = Math.round(delayInMs / (24 * 3600 * 1000));
    }

    // total stat
    if (!byType[type]) {
      byType[type] = {
        count: 0,
        synthetic: 0,
        delaysInDays: [],
      };
    }
    byType[type].count += count || 1;
    byType[type].synthetic += synthetic || 0;

    byType[type].delaysInDays.push(delayInDays);

    // salesOwner stat
    const user = salesOwner || 'undefined';
    if (!byUserAndType[user]) {
      byUserAndType[user] = {};
    }
    if (!byUserAndType[user][type]) {
      byUserAndType[user][type] = {
        count: 0,
        synthetic: 0,
        delaysInDays: [],
      };
    }
    byUserAndType[user][type].count += count || 1;
    byUserAndType[user][type].synthetic += synthetic || 0;
    byUserAndType[user][type].delaysInDays.push(delayInDays);
  });

  // build stats
  const median = (values, defaultValue) => {
    if (_.isEmpty(values)) {
      return defaultValue;
    }
    const sortedValues = _.sortBy(values);
    const N = sortedValues.length;
    if (N % 2 === 0) {
      return 0.5 * (sortedValues[(N - 2) / 2] + sortedValues[N / 2]);
    } else {
      return sortedValues[(N - 1) / 2];
    }
  };

  const percent = (ratio) => {
    return Math.round(ratio * 10000) / 100;
  };

  const total = {};
  _.each(byType, (typeStats, type) => {
    total[type] = {
      count: typeStats.count,
      synthetic: typeStats.synthetic,
      percent: percent(
        typeStats.count / ((byType['onboarding'] || {}).count || 1),
      ),
      medianDelay: median(typeStats.delaysInDays, undefined),
    };
  });

  const byUser = {};
  _.each(byUserAndType, (statsByType, user) => {
    byUser[user] = {};
    _.each(statsByType, (typeStats, type) => {
      byUser[user][type] = {
        count: typeStats.count,
        synthetic: typeStats.synthetic,
        percent: percent(
          typeStats.count / ((statsByType['onboarding'] || {}).count || 1),
        ),
        medianDelay: median(typeStats.delaysInDays, undefined),
      };
    });
  });

  return {
    ...slot,
    byUser,
    total,
  };
};

const buildStats = (events) => {
  const today = (new Date()).toISOString().slice(2,10);
  const tomorrowDate = new Date(Date.now() + (24*3600*1000));
  const slotFilter = (slots) => (
    _.filter(slots, ({ beginDay }) => (
      beginDay <= today
    ))
  );

  const enrichSlots = (slots) => _.map(slots, (slot) => {
    const beginDate = new Date('20' + slot.beginDay);

    const endDate_ = new Date('20' + slot.endDay);
    const endDate = endDate_ >= tomorrowDate ? tomorrowDate : endDate_;

    const nbMsInADay = 24*3600*1000;
    const nbDays = Math.floor((endDate.getTime() - beginDate.getTime() + 1000) / nbMsInADay);

    const beginWeekDay = beginDate.getDay();
    let nbWeekDays = 0;
    _.each(_.range(nbDays), (delta) => {
      const weekDay = (beginWeekDay + delta) % 7;
      if (weekDay >= 1 && weekDay <= 5) {
        nbWeekDays++;
      }
    });

    return {
      ...slot,
      endDay: endDate.toISOString().slice(2,10),
      nbDays,
      nbWeekDays
    }
  });

  const daySlots = enrichSlots(_.last(slotFilter(
    _.map(_.range(3000), (iDay) => {
      const monday4June2018Timestamp = 1528074024000;
      const nbMsInADay = 24*3600*1000;
      const timestamp = monday4June2018Timestamp + iDay*nbMsInADay;
      const beginDay = new Date(timestamp).toISOString().slice(2,10);
      const endDay = new Date(timestamp + nbMsInADay).toISOString().slice(2,10);
      return { beginDay, endDay, title: beginDay };      
    })
  ), 50));

  const weekSlots = enrichSlots(slotFilter(
    _.map(_.range(200), (iWeek) => {
      const monday4June2018Timestamp = 1528074024000;
      const nbMsInADay = 24 * 3600 * 1000;
      const mondayTimestamp = monday4June2018Timestamp + iWeek * 7 * nbMsInADay;
      const beginDay = new Date(mondayTimestamp).toISOString().slice(2, 10);
      const endDay = new Date(mondayTimestamp + 7 * nbMsInADay)
        .toISOString()
        .slice(2, 10);
      return { beginDay, endDay, title: 'W: ' + beginDay };
    })
  ));

  const quarterSlots = enrichSlots(slotFilter([
    { beginDay: '17-01-01', endDay: '17-04-01', title: 'Q1 2017' },
    { beginDay: '17-04-01', endDay: '17-07-01', title: 'Q2 2017' },
    { beginDay: '17-07-01', endDay: '17-10-01', title: 'Q3 2017' },
    { beginDay: '17-10-01', endDay: '18-01-01', title: 'Q4 2017' },

    { beginDay: '18-01-01', endDay: '18-04-01', title: 'Q1 2018' },
    { beginDay: '18-04-01', endDay: '18-07-01', title: 'Q2 2018' },
    { beginDay: '18-07-01', endDay: '18-10-01', title: 'Q3 2018' },
    { beginDay: '18-10-01', endDay: '19-01-01', title: 'Q4 2018' },

    { beginDay: '19-01-01', endDay: '19-04-01', title: 'Q1 2019' },
    { beginDay: '19-04-01', endDay: '19-07-01', title: 'Q2 2019' },
    { beginDay: '19-07-01', endDay: '19-10-01', title: 'Q3 2019' },
    { beginDay: '19-10-01', endDay: '20-01-01', title: 'Q4 2019' },

    { beginDay: '20-01-01', endDay: '20-04-01', title: 'Q1 2020' },
    { beginDay: '20-04-01', endDay: '20-07-01', title: 'Q2 2020' },
    { beginDay: '20-07-01', endDay: '20-10-01', title: 'Q3 2020' },
    { beginDay: '20-10-01', endDay: '21-01-01', title: 'Q4 2020' },

    { beginDay: '21-01-01', endDay: '21-04-01', title: 'Q1 2021' },
    { beginDay: '21-04-01', endDay: '21-07-01', title: 'Q2 2021' },
    { beginDay: '21-07-01', endDay: '21-10-01', title: 'Q3 2021' },
    { beginDay: '21-10-01', endDay: '22-01-01', title: 'Q4 2021' },

    { beginDay: '22-01-01', endDay: '22-04-01', title: 'Q1 2022' },
    { beginDay: '22-04-01', endDay: '22-07-01', title: 'Q2 2022' },
    { beginDay: '22-07-01', endDay: '22-10-01', title: 'Q3 2022' },
    { beginDay: '22-10-01', endDay: '23-01-01', title: 'Q4 2022' },
  ]));


  const nextMonthIds = ['02','03','04','05','06','07','08','09','10', '11', '12']
  const monthSlots = enrichSlots(slotFilter([
    ..._.flatten(_.map(
      [17, 18, 19, 20, 21, 22],
      year => [
        ..._.map(['01','02','03','04','05','06','07','08','09','10', '11'], (monthId,index) => ({
          beginDay: year + '-' + monthId + '-01', endDay: year +'-' + (nextMonthIds[index]) +'-01', title: monthId + '-20' + year
        })),
        { beginDay: year + '-12-01', endDay: (year + 1) + '-01-01', title: '12-20' + year },
      ]
    ))
  ]));

  const byDay = {
    slots: _.map(daySlots, (slot) => (
      buildSlotStats(events, slot)
    ))
  };

  const byQuarter = {
    slots: _.map(quarterSlots, (slot) => buildSlotStats(events, slot)),
  };

  const byMonth = {
    slots: _.map(monthSlots, (slot) => buildSlotStats(events, slot)),
  };

  const byWeek = {
    slots: _.map(weekSlots, (slot) => buildSlotStats(events, slot)),
  };

  const result = {
    byDay,
    byQuarter,
    byMonth,
    byWeek,
  };

  return result;
};

const Placeholder = ({ height }) => (
  <div
    style={{ height: height || 180, width: '100%', background: 'whitesmoke' }}
  />
);

class SalesOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splitMode: 'byMonth',
      nbSplits: 6,
    };
  }

  componentWillMount() {
    this.loadData({ initial: true });
  }

  loadData = async ({ initial }) => {
    this.setState({
      clients: undefined,
      offers: undefined,
      clientEvents: undefined,
      offerEvents: undefined,
      clientStats: undefined,
      offerStats: undefined,
      watchOfferEvents: undefined,
      watchOfferStats: undefined,
      leadgenOfferEvents: undefined,
      leadgenOfferStats: undefined,
    });

    const data =
      this.state.data ||
      (await axios.get(`${baseUrl}/strat/sends/clientsAndOffersEvents`)).data;

    console.log(data);

    const clients = _.filter(data.clients, ({ platformId }) => (
      (
        !this.state.targetClient || platformId === this.state.targetClient
      ) && (
        !this.state.excludedClient || platformId !== this.state.excludedClient
      )
    ));

    const offers = _.filter(data.offers, ({ platformId, platformClientId, tags, salesOwner }) => (
      (
        !!salesOwner
      ) && (
        !this.state.targetOffer || platformId === this.state.targetOffer
      ) && (
        !this.state.excludedOffer || platformId !== this.state.excludedOffer
      ) && (
        !this.state.targetClient || platformClientId === this.state.targetClient
      ) && (
        !this.state.excludedClient || platformClientId !== this.state.excludedClient
      ) && (
        !this.state.targetTag || (tags || []).indexOf(this.state.targetTag) >= 0
      )
    ));


    const tagsSet = {};
    _.each(offers, (offer) => {
      _.each(offer.tags, (tag) => {
        tagsSet[tag] = true;
      });
    });
    const allTags = _.keys(tagsSet);

    let clientEvents = [];
    _.each(clients, ({ platformId, salesOwner, events, dailyEvents }) => {
      if (this.state.targetClient && this.state.targetClient !== platformId) {
        return;
      }
      if (this.state.excludedClient && this.state.excludedClient === platformId) {
        return
      }

      _.each(events, (event, type) => {
        clientEvents.push({
          type,
          itemId: platformId,
          salesOwner,
          date: event.date,
          day: event.day,
        });
      });
      _.each(dailyEvents, (events, type) => {
        _.each(events, (event) => {
          clientEvents.push({
            type,
            itemId: platformId,
            salesOwner,
            date: event.date,
            day: event.day,
            count: event.count,
            watchCount: event.watchCount,
            leadgenCount: event.leadgenCount,
            synthetic: event.synthetic,
            watchSynthetic: event.watchSynthetic,
            leadgenSynthetic: event.leadgenSynthetic
          });
        });
      });
    });
    clientEvents = _.sortBy(clientEvents, 'date');

    let offerEvents = [];
    let categorizedOfferEvents = [];
    _.each(offers, ({ platformId, salesOwner, events, dailyEvents, category }) => {
      if (this.state.targetOffer && this.state.targetOffer !== platformId) {
        return;
      }
      if (this.state.excludedOffer && this.state.excludedOffer === platformId) {
        return
      }

      _.each(events, (event, type) => {
        const offerEvent = {
          type,
          itemId: platformId,
          salesOwner,
          date: event.date,
          day: event.day,
        };
        offerEvents.push(offerEvent);
        if (category) {
          categorizedOfferEvents.push(offerEvent);
        }
      });
      _.each(dailyEvents, (events, type) => {
        _.each(events, (event) => {
          const offerEvent = {
            type,
            itemId: platformId,
            salesOwner,
            date: event.date,
            day: event.day,
            count: event.count,
            watchCount: event.watchCount,
            leadgenCount: event.leadgenCount,
            synthetic: event.synthetic,
            watchSynthetic: event.watchSynthetic,
            leadgenSynthetic: event.leadgenSynthetic
          };
          offerEvents.push(offerEvent);
          if (category) {
            categorizedOfferEvents.push(offerEvent);
          }
        });
      });
    });
    offerEvents = _.sortBy(offerEvents, 'date');
    categorizedOfferEvents = _.sortBy(categorizedOfferEvents, 'date');

    const watchOfferEvents = _.compact(_.map(offerEvents, offerEvent => (
      offerEvent.watchCount && {
        ...offerEvent,
        leadgenCount: 0,
        count: offerEvent.watchCount,
        leadgenSynthetic: 0,
        synthetic: offerEvent.watchSynthetic,
      }
    )));
    const leadgenOfferEvents = _.compact(_.map(offerEvents, offerEvent => (
      offerEvent.leadgenCount && {
        ...offerEvent,
        watchCount: 0,
        count: offerEvent.leadgenCount,
        watchSynthetic: 0,
        synthetic: offerEvent.leadgenSynthetic,
      }
    )));


    const clientStats = buildStats(clientEvents);
    const offerStats = buildStats(offerEvents);
    const categorizedOfferStats = buildStats(categorizedOfferEvents);
    const watchOfferStats = buildStats(watchOfferEvents);
    const leadgenOfferStats = buildStats(leadgenOfferEvents);

    const getKeyMetrics = () => {
      const currentMonthFirstDay =
        (_.last(((clientStats || {}).byMonth || {}).slots) || {}).beginDay ||
        '99-01-01';

      const leadgenSyntheticFlow = {};
      const watchSyntheticFlow = {};
      _.each(offerEvents, ({ itemId, type, synthetic, watchSynthetic, leadgenSynthetic, day, salesOwner }) => {
        if (day >= currentMonthFirstDay && synthetic) {
          if (watchSynthetic) {
            watchSyntheticFlow['total'] =
              watchSynthetic + (watchSyntheticFlow['total'] || 0);
            if (salesOwner) {
              watchSyntheticFlow[salesOwner] =
                watchSynthetic + (watchSyntheticFlow[salesOwner] || 0);
            }
          } 
          if (leadgenSynthetic) {
            leadgenSyntheticFlow['total'] =
              leadgenSynthetic + (leadgenSyntheticFlow['total'] || 0);
            if (salesOwner) {
              leadgenSyntheticFlow[salesOwner] =
                leadgenSynthetic + (leadgenSyntheticFlow[salesOwner] || 0);
            }
          }
        }
      });

      const nbActiveClients = {};
      const seenClientId = {};
      _.each(clientEvents, ({ itemId, type, day, salesOwner }) => {
        if (
          day >= currentMonthFirstDay &&
          ['sends', 'skips'].indexOf(type) >= 0
        ) {
          if (!seenClientId[itemId]) {
            nbActiveClients['total'] = 1 + (nbActiveClients['total'] || 0);
            if (salesOwner) {
              nbActiveClients[salesOwner] =
                1 + (nbActiveClients[salesOwner] || 0);
            }
            seenClientId[itemId] = true;
          }
        }
      });

      const nbActiveOffers = {};
      const seenOfferId = {};
      _.each(offerEvents, ({ itemId, type, day, salesOwner }) => {
        if (
          day >= currentMonthFirstDay &&
          ['sends', 'skips'].indexOf(type) >= 0
        ) {
          if (!seenOfferId[itemId]) {
            nbActiveOffers['total'] = 1 + (nbActiveOffers['total'] || 0);
            if (salesOwner) {
              nbActiveOffers[salesOwner] =
                1 + (nbActiveOffers[salesOwner] || 0);
            }
            seenOfferId[itemId] = true;
          }
        }
      });

      const sendRatio = {};
      const currentMonthClientSlot =
        _.last(((clientStats || {}).byMonth || {}).slots) || {};
      _.each(
        {
          ...currentMonthClientSlot.byUser,
          total: currentMonthClientSlot.total,
        },
        (stats, user) => {
          const nbSends = ((stats || {}).sends || {}).count || 0;
          const nbSkips = ((stats || {}).skips || {}).count || 0;
          sendRatio[user] = nbSends / Math.max(1, nbSends + nbSkips);
        },
      );

      return {
        leadgenSyntheticFlow,
        watchSyntheticFlow,
        nbActiveClients,
        nbActiveOffers,
        sendRatio,
      };
    };

    const {
      leadgenSyntheticFlow,
      watchSyntheticFlow,
      nbActiveClients,
      nbActiveOffers,
      sendRatio,
    } = getKeyMetrics();

    const allClientIds = _.sortBy(
      _.uniq(_.pluck(clientEvents, 'itemId')),
      (str) => (str || '').toLowerCase(),
    );
    const allOfferIds = _.sortBy(
      _.uniq(_.pluck(offerEvents, 'itemId')),
      (str) => (str || '').toLowerCase(),
      (str) => (str || '').toLowerCase(),
    );

    /*
       console.log('COMPUTED');
       console.log({
       clients,
       offers,
       clientEvents,
       offerEvents,
       clientStats,
       offerStats,
       watchOfferEvents,
       watchOfferStats,
       leadgenOfferEvents,
       leadgenOfferStats,
       });
     */

    this.setState({
      clients,
      offers,
      clientEvents,
      offerEvents,
      clientStats,
      offerStats,
      categorizedOfferStats,
      watchOfferEvents,
      watchOfferStats,
      leadgenOfferEvents,
      leadgenOfferStats,
      allTags,
      ...(initial && {
        data,
        allClientIds,
        allOfferIds,
        leadgenSyntheticFlow,
        watchSyntheticFlow,
        nbActiveClients,
        nbActiveOffers,
        sendRatio,
      }),
    });
  };

  handleSelectTargetUser = (targetUser) => {
    this.setState({ targetUser });
  };

  handleSelectSplitMode = (splitMode) => {
    this.setState({ splitMode });
  };

  handleSelectNbSplits = (nbSplits) => {
    this.setState({ nbSplits });
  };

  handleSelectTargetTag = (targetTag) => {
    this.setState({ targetTag }, () => {
      this.loadData({});
    });
  };

  handleSelectTargetClient = (targetClient) => {
    this.setState(
      {
        targetClient,
        targetOffer: null,
      },
      () => {
        this.loadData({});
      },
    );
  };
  handleSelectTargetOffer = (targetOffer) => {
    this.setState(
      {
        targetClient: null,
        targetOffer,
      },
      () => {
        this.loadData({});
      },
    );
  };

  handleSelectExcludedClient = (excludedClient) => {
    this.setState({ 
      excludedClient,
    }, () => {
      this.loadData({});
    })
  }
  handleSelectExcludedOffer = (excludedOffer) => {
    this.setState({ 
      excludedOffer,
    }, () => {
      this.loadData({});
    });
  }

  renderHeader() {
    const style = (key) => {
      const selected =
        this.state.targetUser === key ||
        this.state.targetTag === key ||
        (!this.state.targetUser && key === 'all') ||
        (!this.state.targetTag && key === 'all-tags') 
        ;
      return {
        fontSize: 20,
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    const clientOptions = _.map(this.state.allClientIds, (clientId, index) => ({
      key: index,
      value: clientId,
      text: clientId,
    }));

    const offerOptions = _.map(this.state.allOfferIds, (offerId, index) => ({
      key: index,
      value: offerId,
      text: offerId,
    }));

    const getPercentStr = (ratio) => Math.round(ratio * 1000) / 10 + '%';

    const leadgenSyntheticFlow =
      (this.state.leadgenSyntheticFlow || {})[
        this.state.targetUser || 'total'
      ] || 0;
    const watchSyntheticFlow =
      (this.state.watchSyntheticFlow || {})[this.state.targetUser || 'total'] ||
      0;
    const syntheticFlow = leadgenSyntheticFlow + watchSyntheticFlow;
    const syntheticLeadGenPercent =
      Math.round((100 * leadgenSyntheticFlow) / Math.max(1, syntheticFlow)) +
      '%';

    const sendRatio =
      (this.state.sendRatio || {})[this.state.targetUser || 'total'] || 0;
    const nbActiveClients =
      (this.state.nbActiveClients || {})[this.state.targetUser || 'total'] || 0;
    const nbActiveOffers =
      (this.state.nbActiveOffers || {})[this.state.targetUser || 'total'] || 0;

    const allTags = this.state.allTags || [];

    return (
      <div>
        <Grid>
          <Grid.Row style={{ paddingTop: 40, paddingBottom: 40 }}>
            <Grid.Column width={4}>
              <div>
                <span
                  style={style('all')}
                  onClick={() => this.handleSelectTargetUser(null)}
                >
                  Team |{' '}
                </span>
                <span
                  style={style('robin')}
                  onClick={() => this.handleSelectTargetUser('robin')}
                >
                  Robin |{' '}
                </span>
                <span
                  style={style('mathieu')}
                  onClick={() => this.handleSelectTargetUser('mathieu')}
                >
                  Mathieu |{' '}
                </span>
                <span
                  style={style('laurene')}
                  onClick={() => this.handleSelectTargetUser('laurene')}
                >
                  Laurène |{' '}
                </span>
                <span
                  style={style('tugdual')}
                  onClick={() => this.handleSelectTargetUser('tugdual')}
                >
                  Tugdual |{' '}
                </span>
                <span
                  style={style('camillee')}
                  onClick={() => this.handleSelectTargetUser('camillee')}
                >
                  Camille |{' '}
                </span>
                <span
                  style={style('victorro')}
                  onClick={() => this.handleSelectTargetUser('victorro')}
                >
                  Victor
                </span>
                <span
                  style={style('cloe')}
                  onClick={() => this.handleSelectTargetUser('cloe')}
                >
                  Cloé
                </span>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <Statistic>
                <Statistic.Value>
                  {Math.round(syntheticFlow / 100) / 10} K€
                </Statistic.Value>
                <Statistic.Label style={{ position: 'relative' }}>
                  {syntheticFlow && [
                    <div
                      key="1"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: 4,
                        top: -3,
                        background: '#F9CE89',
                      }}
                    />,
                    <div
                      key="2"
                      style={{
                        position: 'absolute',
                        width: syntheticLeadGenPercent,
                        height: 4,
                        top: -3,
                        background: '#9ACCED',
                      }}
                    />,
                  ]}
                  <span>Synthetic Flow</span>
                </Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column width={2}>
              <Statistic>
                <Statistic.Value>{nbActiveClients}</Statistic.Value>
                <Statistic.Label>Active clients</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column width={2}>
              <Statistic>
                <Statistic.Value>{nbActiveOffers}</Statistic.Value>
                <Statistic.Label>Active offers</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column width={2}>
              <Statistic>
                <Statistic.Value>{sendRatio && getPercentStr(sendRatio)}</Statistic.Value>
                <Statistic.Label>Send Rate</Statistic.Label>

              </Statistic>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <div>
                {_.map(allTags, (tag, index) => (
                  <span
                    key={index}
                    style={style(tag)}
                    onClick={() => this.handleSelectTargetTag(tag)}
                  >
                    {tag} |{' '}
                  </span>                  
                ))}
                <span
                  style={style('all-tags')}
                  onClick={() => this.handleSelectTargetTag(null)}
                >
                  All
                </span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
          <Dropdown
            style={{ marginRight: 70 }}
            selection
            options={[
              { key: '0', value: 6, text: '6' },
              { key: '1', value: 12, text: '12' },
              { key: '2', value: 24, text: '24' },
              { key: '3', value: 48, text: '48' },
              { key: '4', value: 96, text: '96' },
            ]}
            value={this.state.nbSplits}
            onChange={(e, { value }) => this.handleSelectNbSplits(value)}
          />
          <Dropdown
            style={{ marginRight: 70 }}
            selection
            options={[
              { key: '0', value: 'byQuarter', text: 'Quarter' },
              { key: '1', value: 'byMonth', text: 'Month' },
              { key: '2', value: 'byWeek', text: 'Week' },
              { key: '3', value: 'byDay', text: 'Day' },
            ]}
            value={this.state.splitMode}
            onChange={(e, { value }) => this.handleSelectSplitMode(value)}
          />

          <div style={{ display: 'inline-block', marginRight: 70, position: 'relative' }}>
            <Dropdown               
              placeholder='Client'
              selection
              search
              defaultValue={null}
              options={clientOptions}
              value={this.state.targetClient}
              onChange={(e, { value }) => this.handleSelectTargetClient(value)}
            />
            {this.state.targetClient && (
              <div 
                style={{ cursor: 'pointer', position: 'absolute', right: -20, top: 7 }}
                onClick={() => this.handleSelectTargetClient(null)}
              >
                <Icon name='close' />
              </div>
            )}
          </div>

          <div style={{ display: 'inline-block', marginRight: 70, position: 'relative' }}>
            <Dropdown 
              placeholder='Offer'
              selection
              search
              options={offerOptions}
              value={this.state.targetOffer}
              onChange={(e, { value }) => this.handleSelectTargetOffer(value)}
            />
            {this.state.targetOffer && (
              <div 
                style={{ cursor: 'pointer', position: 'absolute', right: -20, top: 7 }}
                onClick={() => this.handleSelectTargetOffer(null)}
              >
                <Icon name='close' />
              </div>
            )}
          </div>

          <div style={{ display: 'inline-block', marginRight: 70, position: 'relative' }}>
            <Dropdown 
              placeholder='Excluded Client'
              selection
              search
              defaultValue={null}
              options={clientOptions}
              value={this.state.excludedClient}
              onChange={(e, { value }) => this.handleSelectExcludedClient(value)}
            />
            {this.state.excludedClient && (
              <div 
                style={{ cursor: 'pointer', position: 'absolute', right: -20, top: 7 }}
                onClick={() => this.handleSelectExcludedClient(null)}
              >
                <Icon name='close' />
              </div>
            )}        
          </div>
          <div style={{ display: 'inline-block', marginRight: 70, position: 'relative' }}>
            <Dropdown 
              placeholder='Excluded Offer'
              selection
              search
              options={offerOptions}
              value={this.state.excludedOffer}
              onChange={(e, { value }) => this.handleSelectExcludedOffer(value)}
            />
            {this.state.excludedOffer && (
              <div 
                style={{ cursor: 'pointer', position: 'absolute', right: -20, top: 7 }}
                onClick={() => this.handleSelectExcludedOffer(null)}
              >
                <Icon name='close' />
              </div>
            )}  
          </div>
        </div>
        <br />
      </div>
    );
  }

  renderKPIGrid() {
    return (
      <Grid>
        <Grid.Row columns={2} style={{ padding: 0, margin: 0 }}>
          <Grid.Column>
            <SyntheticGraph
              leadgenStats={this.state.leadgenOfferStats}
              watchStats={this.state.watchOfferStats}
              splitMode={this.state.splitMode}
              nbSplits={this.state.nbSplits}
              targetUser={this.state.targetUser}
              title={'Synthetic Flow (Leadgen & Watch) in K€'}
            />
          </Grid.Column>
          <Grid.Column>
            <ActivityGraph
              offers={this.state.offers}
              offerEvents={this.state.offerEvents}
              offerStats={this.state.offerStats}
              splitMode={this.state.splitMode}
              nbSplits={this.state.nbSplits}
              targetUser={this.state.targetUser}
              title={'Activity'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} style={{ padding: 0, margin: 0 }}>
          <Grid.Column>
            <SendsGraph
              stats={this.state.leadgenOfferStats}
              offers={this.state.offers}
              offerEvents={this.state.leadgenOfferEvents}
              splitMode={this.state.splitMode}
              nbSplits={this.state.nbSplits}
              targetUser={this.state.targetUser}
              fillColor="#3498DB"
              title={'LeadGen Sends'}
            />
          </Grid.Column>
          <Grid.Column>
            <SendsGraph
              stats={this.state.watchOfferStats}
              offers={this.state.offers}
              offerEvents={this.state.watchOfferEvents}
              splitMode={this.state.splitMode}
              nbSplits={this.state.nbSplits}
              targetUser={this.state.targetUser}
              fillColor="#F39C12"
              title={'Watch Sends'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} style={{ padding: 0, margin: 0 }}>
          <Grid.Column>
            <SendsSkipsGraph
              offerStats={this.state.offerStats}
              leadgenOfferStats={this.state.leadgenOfferStats}
              watchOfferStats={this.state.watchOfferStats}
              splitMode={this.state.splitMode}
              nbSplits={this.state.nbSplits}
              targetUser={this.state.targetUser}
              title={'Send Rate'}
            />
          </Grid.Column>
          <Grid.Column>
            <OnboardingLevel
              offerStats={this.state.categorizedOfferStats}
              clientStats={this.state.clientStats}
              splitMode={this.state.splitMode}
              nbSplits={this.state.nbSplits}
              targetUser={this.state.targetUser}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderStatsTable() {
    return <Placeholder height={200} />;
  }

  renderKPIAndStatsTable() {
    return (
      <div>
        {this.renderKPIGrid()}
        <br />
        {/*(this.renderStatsTable()*/}
      </div>
    );
  }

  renderWarnings() {
    return (
      <div>
        <SalesWarnings
          clientEvents={this.state.clientEvents}
          offerEvents={this.state.offerEvents}
          targetUser={this.state.targetUser}
        />
      </div>
    );
  }

  render() {
    return (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>{this.renderHeader()}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>{this.renderKPIAndStatsTable()}</Grid.Column>
          <Grid.Column width={4}>{this.renderWarnings()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default SalesOverview;
