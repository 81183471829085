import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Button, Label, Input, Grid, Tab, Segment, Checkbox } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';
import { LinkedinProfile } from './Offers/WorkPipeInputComponents/LinkedinProfile';
import ProfileView from './SweetComponents/Profile/View';


class ResumeParser extends React.Component {
  state = {};

  onChangeHandler = (event) => {
    const selectedFile = event.target.files[0];
    var self = this;
    var reader = new FileReader();

    reader.onload = function (upload) {
      self.setState({
        binaryString: upload.target.result,
        base64String: btoa(upload.target.result),
      });
    };
    reader.readAsBinaryString(selectedFile);
  };

  handleParseBase64 = async () => {
    const { base64String } = this.state;
    const parsedResumeUrl = (this.state || {}).useRd ? `${baseUrl}/resumeParser/parseResumeRd` : `${baseUrl}/resumeParser/parseResume`
    try {

      const result = (await axios.post(parsedResumeUrl, {
        base64String: JSON.stringify(base64String),
      })).data;
      const { linkedinProfileData, parsedFields } = result;
      const url = baseUrl + '/sweetchain/getProfileV5';
      const { data } = await axios.post(url, {
        profile: {
          data: {
            linkedin: {
              data: linkedinProfileData,
            },
          },
        },
        offer: {
          companyId: 'hiresweet',
        },
      });
      this.setState({ profileV5: data, linkedinProfileData, parsedFields });
    } catch (e) {
      alert(JSON.stringify(e));
    }
  };

  handleSampleStaticResume = async () => {
    const { samplingCategory } = this.state;
    const result = (await axios.post(`${baseUrl}/resumeParser/sampleStaticResume`, {
      samplingCategory
    })).data;
    const { base64String, id, error } = result;
    if (_.isEmpty(base64String) || error) {
      return alert("Error sampling satic resume : " + JSON.stringify(error, null, 4))
    }
    this.setState({ base64String, staticResumeId: id });
    this.handleParseBase64();
  }

  handleChangeSamplingCategory = (e, { value }) => {
    this.setState({
      samplingCategory: value,
    });
  }

  handleChangeStaticResumeId = (e, { value }) => {
    this.setState({
      staticResumeId: value,
    });
  }

  handleParseStaticResume = async () => {
    const { staticResumeId } = this.state;
    const result = (await axios.get(`${baseUrl}/resumeParser/staticResume/${staticResumeId}`)).data;
    const { base64String, id, error } = result;
    if (_.isEmpty(base64String) || error) {
      return alert("Error getting satic resume : " + JSON.stringify(error, null, 4))
    }
    this.setState({ base64String, staticResumeId });
    this.handleParseBase64();
  }

  renderRightPane() {
    const { profileV5, linkedinProfileData, parsedFields, staticResumeId, base64String } = this.state;
    const panes = [
      ...(profileV5
        ? [
          {
            menuItem: 'Enriched Profile',
            render: () => (
              <Tab.Pane>
                <ProfileView data={profileV5.profile} />{' '}
              </Tab.Pane>
            ),
          },
        ]
        : []),
      ...(parsedFields
        ? [
          {
            menuItem: 'Parsed Data',
            render: () => (
              <Tab.Pane>
                <div style={{ overflowX: 'scroll', width: '100%' }}>
                  <pre>{JSON.stringify(parsedFields, null, 4)} </pre>
                </div>
              </Tab.Pane>
            ),
          },
        ]
        : []),
      ...(linkedinProfileData
        ? [
          {
            menuItem: 'Linkedin Profile',
            render: () => (
              <Tab.Pane>
                <LinkedinProfile source={{ data: linkedinProfileData }} more={{}} />
              </Tab.Pane>
            ),
          },
        ]
        : []),
    ];
    return (
      <div>
        {staticResumeId && (<Label>{staticResumeId}</Label>)}
        <Tab panes={panes} />
      </div>
    )
  }

  renderLeftPane() {
    return (
      <div>
        <Segment>
          <Input type='file' name='file' onChange={this.onChangeHandler} />
          <Button positive onClick={() => this.handleParseBase64()}>
            Parse
          </Button>
          <Input placeholder="Static Resume Category" onChange={this.handleChangeSamplingCategory} />
          <Button positive onClick={() => this.handleSampleStaticResume()}>
            Sample Static Resume
          </Button>
          <Input placeholder="Static Resume Id" onChange={this.handleChangeStaticResumeId} />
          <Button positive onClick={() => this.handleParseStaticResume()}>
            Parse Static Resume
          </Button>
        </Segment>
      </div>
    )

  }

  render() {
    const onToggle = () => {
      this.setState({
        useRd: this.state.useRd ? false : true
      })
    }
    return (
      <Grid>
        Use RD :
        <Checkbox
          slider
          checked={(this.state || {}).useRd}
          onChange={onToggle}
        />
        <Grid.Row>
          {this.renderLeftPane()}
        </Grid.Row>
        <Grid.Row>
          {this.renderRightPane()}
        </Grid.Row>
      </Grid>
    );
  }
}

export default ResumeParser;
