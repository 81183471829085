import React from 'react';
import { Switch, Route } from 'react-router-dom';
import JobsCategoriesDashboard from './JobsCategoriesDashboard';
import JobsCategory from './JobsCategory';
import JobsWithoutCategory from './JobsWithoutCategory';

class JobsCategoriesRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/assets-explorer/jobs-categories'>
          <JobsCategoriesDashboard />
        </Route>
        <Route exact path='/assets-explorer/jobs-categories/no-category'>
          {() => <JobsWithoutCategory />}
        </Route>
        <Route exact path='/assets-explorer/jobs-categories/:jobsCategoryId'>
          {({ match }) => <JobsCategory jobsCategoryId={((match || {}).params || {}).jobsCategoryId} />}
        </Route>
      </Switch>
    );
  }
}

export default JobsCategoriesRouter;
