import _ from 'underscore';
import React from 'react';

import ClientCard from './ClientCard';
import getMatchesStatsAndScore from '../../getMatchesStatsAndScore'


const ClientsOverview = ({
  clients,
  targetClientId,
  handleSelectTargetClient,
  handleLoadProfilesSelection,
  handleLoadClientsSelection,
  mode
}) => {
  const statsAndScoreFromId = {};
  _.each(clients, (client) => {
    statsAndScoreFromId[client.id] = getMatchesStatsAndScore(
      client.matches,
    );
  });
  return (
    <div>
      <br />
      {_.map(clients,(client, index) => (
        (!targetClientId ||targetClientId === client.id) && (
          <div key={index}>
            <ClientCard
              client={client}
              stats={statsAndScoreFromId[client.id].stats}
              targetClientId={targetClientId}
              handleSelectTargetClient={handleSelectTargetClient}
              handleLoadProfilesSelection={handleLoadProfilesSelection}
              handleLoadClientsSelection={handleLoadClientsSelection}
              mode={mode}
            />
          </div>
        )
      ))}
    </div>
  );
}

export default ClientsOverview;
