import React from 'react';
import _ from 'underscore';
import { Card, Grid, Icon, Segment, Label } from 'semantic-ui-react';

import WatchCandidate from '../../../../Watch/Candidate';
import StatsTable from '../StatsTable';
import ContactsTable from './ContactsTable';
import { GRADES } from '../../../../Watch/Candidate/utils';

const SheetItemCard = ({
  sheetItem,
  stats,
  targetSheetItemId,
  handleLoadProfilesSelection,
  handleLoadClientsSelection,
  handleSelectTargetSheetItem,
  handleUpdateProfile,
  handleRecomputeReverseSearch,
  mode,
  displayMode
}) => {
  const cardStyle =
    sheetItem.extraTags.indexOf('holded') >= 0
      ? {
        background: '#fff4d4',
      }
      : sheetItem.extraTags.indexOf('toUnupload') >= 0
        ? {
          background: 'rgba(252, 3, 3, 0.2)',
        }
        : sheetItem.extraTags.indexOf('noEmail') >= 0
          ? {
            background: 'rgba(252, 3, 3, 0.2)',
          }
          : sheetItem.extraTags.indexOf('deleted') >= 0
            ? {
              background: 'rgba(252, 3, 3, 0.2)',
            }
            : sheetItem.extraTags.indexOf('unavailable') >= 0
              ? {
                background: 'rgba(0, 0, 0, 0.2)',
                opacity: '0.5',
              }
              : sheetItem.extraTags.indexOf('available') >= 0
                ? {
                  background: 'rgba(16, 209, 6, 0.2)',
                }
                : {};
  const { fullname, admin, extraTags } = sheetItem || {};
  const category = (admin || {}).category;
  const grade = (admin || {}).grade;
  const gradeColor = (_.find(GRADES, ({ value }) => value === grade) || {}).color;
  return (
    <Card fluid style={cardStyle}>
      <Card.Content>
        <Grid>
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column width={3} verticalAlign='middle'>
              <h3
                style={{
                  color: targetSheetItemId && targetSheetItemId === sheetItem.id ? 'blue' : 'black',
                }}
              >
                {(extraTags || []).indexOf('holded') >= 0 && <Icon name='pause' color='red' />}
                {(extraTags || []).indexOf('noHiresweetDescription') >= 0 && <Icon name='file text' color='red' />}
                {(extraTags || []).indexOf('toUnupload') >= 0 && <Icon name='warning' color='red' />}
                {(extraTags || []).indexOf('noEmail') >= 0 && <Icon name='mail' color='red' />}
                {(extraTags || []).indexOf('deleted') >= 0 && <Icon name='user delete' color='red' />}
                {(extraTags || []).indexOf('topCandidate') >= 0 && <Icon name='certificate' color='green' />}
                {(extraTags || []).indexOf('noPhoneNumber') >= 0 ? (
                  <Icon.Group color='red'>
                    <Icon name='phone' color='red' />
                    <Icon corner='top right' name='close' color='red' />
                  </Icon.Group>
                ) : null}
                {(extraTags || []).indexOf('enabledNoReviewSummaryEmail') >= 0 && (
                  <Icon.Group color='red'>
                    <Icon name='mail' color='red' />
                    <Icon corner='top right' name='file text' color='red' />
                  </Icon.Group>
                )}
                {grade && (
                  <Label circular color={gradeColor}>
                    {grade}
                  </Label>
                )}
                <span style={{ color: sheetItem.hasMoreOpportunities ? 'green' : 'black' }}>
                  {`${fullname}`}
                  {sheetItem.hasMoreOpportunities ? ' !!!' : ''}
                </span>

                {targetSheetItemId && targetSheetItemId === sheetItem.id ? (
                  <Icon name='bookmark' link onClick={() => handleSelectTargetSheetItem(null)} />
                ) : (
                  <Icon name='bookmark outline' link onClick={() => handleSelectTargetSheetItem(sheetItem.id)} />
                )}
                {sheetItem.extraTags.indexOf('toContact') >= 0 ? <Icon name='phone' /> : null}
                <Icon name='refresh' link onClick={() => handleRecomputeReverseSearch({ sheetItemId: sheetItem.id })} />
                {category ? <p style={{ color: 'grey' }}>{category}</p> : null}
              </h3>
            </Grid.Column>
            <Grid.Column width={4} verticalAlign='middle'>
              <ContactsTable
                lastContactDay={(admin || {}).lastContactDay}
                lastContactState={(admin || {}).lastContactState}
                nextContactDay={(admin || {}).nextContactDay}
                lastUploadCompletionTimestamp={(admin || {}).lastUploadCompletionTimestamp}
                displayMode={displayMode}
              />
            </Grid.Column>
            <Grid.Column width={9} verticalAlign='right'>
              <StatsTable
                id={sheetItem.id}
                stats={stats}
                handleLoadProfilesSelection={handleLoadProfilesSelection}
                handleLoadClientsSelection={handleLoadClientsSelection}
                mode={mode}
              />
            </Grid.Column>
          </Grid.Row>
          {targetSheetItemId && targetSheetItemId === sheetItem.id && (
            <Grid.Row>
              <Segment>
                {sheetItem.id ? (
                  <WatchCandidate
                    id={sheetItem.id}
                    isInternal={sheetItem.isInternal}
                    saveProfileCallback={() =>
                      handleUpdateProfile({
                        sheetItemId: sheetItem.id,
                      })
                    }
                    statusActionCallback={() =>
                      handleUpdateProfile({
                        sheetItemId: sheetItem.id,
                      })
                    }
                  />
                ) : (
                  <div>No watchsheet item</div>
                )}
              </Segment>
            </Grid.Row>
          )}
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default SheetItemCard;
