import React from 'react';

export function hasRegression(regressions, key) {
  return regressions.some((reg) => {
    return reg.message.indexOf(key) !== -1;
  });
}

export function getRegressionCount(profile, key) {
  try {
    if (key === 'endorsements') {
      return profile.data.skills.reduce((sum, s) => sum + s.num, 0);
    }

    return profile.data[key] && profile.data[key].length;
  } catch (e) {
    console.error(e, profile, key);
  }
}

export function regressionDiff(oldProfile, newProfile, key) {
  if (!oldProfile) {
    return key !== 'summary' ? getRegressionCount(newProfile, key) : null;
  }

  const oldCount = getRegressionCount(oldProfile, key);
  const newCount = getRegressionCount(newProfile, key);
  const diff = newCount - oldCount;
  const symbol = diff > 0 ? '+' : '-';
  const color = diff > 0 ? '#21ba45' : '#db2828';

  if (key === 'summary' && (newCount !== 0 || diff === 0)) {
    return null;
  }
  return (
    <span>
      {newCount}{' '}
      {diff !== 0 ? (
        <span style={{ color }}>
          {symbol}
          {Math.abs(diff)}
        </span>
      ) : null}
    </span>
  );
}
