import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Loader, Card, Image, Table, Popup } from 'semantic-ui-react';
import skillDataFromId from './common/skillDataFromId';
import baseUrl from './baseUrl.js';

class SkillDescriptionPopup extends Component {
  componentWillMount() {
    this.props.loadDetails();
  }
  render() {
    const { data, language, details } = this.props;
    return (
       <div>
         <div>
           <h4>{(data.name || {}).default}</h4>
         </div>
         {data.detailsURL && (
           details && details.description ? (
             <span>{details.description[language]}</span>
           ) : (
             <center>
               <Loader inline active />
             </center>
           )
         )}
       </div>
    )
  }
}

export class SkillDescription extends Component {
  state = {
  };

  loadDetailsCalled = false;

  loadDetails = async () => {
    if (this.loadDetailsCalled) {
      return;
    }
    this.loadDetailsCalled = true;

    const { data } = this.props;
    if ((data || {}).detailsURL){
      try {
        const result = (await axios.get(data.detailsURL, {
          withCredentials: false
        })).data;
        this.setState({ details: result });
      } catch(e) {
        console.log(e);
      }    
    }
  }
  render() {
    const { data, language } = this.props;
    return (
      <Popup
        hoverable
        trigger={
          <Image
            inline
            src={data.icon}
            style={{
              marginLeft: 5,
              cursor: 'pointer',
              maxWidth: 19,
              height: 18
            }}
          />
        }
        content={
          <SkillDescriptionPopup 
            data={data} 
            language={language} 
            details={this.state.details} 
            loadDetails={this.loadDetails}
          />
        }
      />
    );
  }
}

class SkillsAndIcons extends Component {
  state = {
    language: 'en'
  };

  handleChangeLanguage = (language) => {
    this.setState({ language });
  }

  async componentDidMount(){
    const skills = (await axios.get(`${baseUrl}/tags/list?type=skill`)).data
    const skillsWithIcon = _.keys(skillDataFromId)
    const missingSkills = _.reduce(skills, (acc, skill) => {
      if (skillsWithIcon.indexOf(skill.id) === -1){
        acc.push(skill)
      }
      return acc;
    }, [])
    this.setState({missingSkills})
  }


  render() {
    const { language } = this.state;
    const missingSkills = _.uniq(this.state.missingSkills, skill => skill.id);
    const sortedMissingSkills = missingSkills.sort((skillA, skillB) => skillA.id < skillB.id ? -1 : 1);
    const skillIds = _.keys(skillDataFromId).sort();
    return (
      <div>
        <div>
          <span>
            {/* eslint-disable-next-line */}
            <a style={{ cursor: 'pointer', ...language !== 'en' && { color: 'gray' }  }} onClick={() => this.handleChangeLanguage('en')}>EN</a>
            <span> | </span>
            {/* eslint-disable-next-line */}
            <a style={{ cursor: 'pointer', ...language !== 'fr' && { color: 'gray' }  }} onClick={() => this.handleChangeLanguage('fr')}>FR</a>
          </span>
          <br/>
        </div>

        <Card.Group itemsPerRow={8}>
          {_.map(skillIds, (id) => (
            <Card key={id}>
              <Image src={skillDataFromId[id].icon} style={{ paddingTop: 5, paddingBottom: 5, maxHeight: 100 }}/>
              <Card.Content>
                <Card.Header>{id}
                  <SkillDescription data={skillDataFromId[id]} language={language}/>
                </Card.Header>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <br/><br/>
        <h2>Skills without icon</h2>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(sortedMissingSkills, (skill) => {
              return (
                <Table.Row key={skill.id}>
                  <Table.Cell>{skill.id}</Table.Cell>
                  <Table.Cell>{skill.name}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default SkillsAndIcons;
