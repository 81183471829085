import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import baseUrl from '../../baseUrl.js';

import { Breadcrumb, Grid, Tab, Table } from 'semantic-ui-react';

import CereJobsV2 from '../../Monitoring/Cerejobsv2/index.js';
import JobDiffusion from '../../Monitoring/JobDiffusion/index.js';

class JobsCategory extends React.Component {
  componentDidMount() {
    this.loadJobCategoryLevel2();
    this.computeStats();
  }

  async getAllJobs() {
    const url = baseUrl + '/cerejobsV2/getJobs';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  async loadJobCategoryLevel2() {
    const { jobsCategoryId } = this.props;
    const jobs = await this.getAllJobs();

    const jobCategoriesLevel2 = _.filter(
      jobs,
      (job) =>
        (job.groups || []).some((e) => e.id === jobsCategoryId) &&
        (job.categorizations || []).some((e) => e.id === 'main-jobs-categories'),
    );
    this.setState({ jobCategoriesLevel2 });

    const jobsCategory = _.filter(jobs, (job) => job.id === jobsCategoryId)[0];
    this.setState({ jobs, jobCategoriesLevel2, jobsCategoryName: jobsCategory.name });
  }

  getJobsInCategory(jobsCategoryId, jobs) {
    const level2JobsInCategoryIds = _.map(
      _.filter(jobs, (job) => (job.groups || []).some((e) => e.id === jobsCategoryId)),
      (level2JobsInCategory) => level2JobsInCategory.id,
    );
    const jobsInCategory = _.filter(jobs, (job) =>
      (job.groups || []).some((e) => level2JobsInCategoryIds.includes(e.id)),
    );
    return jobsInCategory;
  }

  async computeStats() {
    const { jobsCategoryId } = this.props;
    const jobs = await this.getAllJobs();
    const jobsInCategory = this.getJobsInCategory(jobsCategoryId, jobs);
    const numberOfJobs = jobsInCategory.length;
    let numberOfPatterns = 0;
    jobsInCategory.forEach((job) => {
      numberOfPatterns += (job.patterns || []).length;
    });
    const stats = [
      { title: 'Number of jobs', value: numberOfJobs },
      { title: 'Number of patterns', value: numberOfPatterns },
      { title: 'Average number of patterns', value: Math.round((10 * numberOfPatterns) / numberOfJobs) / 10 },
    ];
    this.setState({ stats });
  }

  render() {
    const { jobCategoriesLevel2, jobsCategoryName, stats } = this.state || {};

    return (
      <Grid>
        <Grid.Row>
          <Breadcrumb size='big'>
            <a href={'/assets-explorer/jobs-categories'}>
              <Breadcrumb.Section>Level 1 Categories</Breadcrumb.Section>
            </a>
            <Breadcrumb.Divider icon='right chevron' />
            <Breadcrumb.Section>{jobsCategoryName}</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Table>
              <Table.Body>
                {_.map(stats, (stat, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{stat.title}</Table.Cell>
                    <Table.Cell>{stat.value}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Tab
            panes={_.map(jobCategoriesLevel2, (pane) => ({
              menuItem: pane.name,
              render: () => <Level2JobsCategory level2Category={pane.id} />,
            }))}
            defaultActiveIndex={0}
            menu={{ fluid: true, vertical: false }}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

class Level2JobsCategory extends React.Component {
  render() {
    const { level2Category } = this.props;
    return (
      <Tab
        panes={[
          {
            menuItem: 'Job Admin',
            render: () => (
              <Tab.Pane>
                <div key={'admin' + level2Category}>
                  <CereJobsV2 initialInGroupsFilter={[level2Category]} />
                </div>
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Job Diffusion',
            render: () => (
              <Tab.Pane>
                <div key={'diffusion' + level2Category}>
                  <JobDiffusion initialInCategoryFilter={level2Category} />
                </div>
              </Tab.Pane>
            ),
          },
        ]}
      />
    );
  }
}

export default JobsCategory;
