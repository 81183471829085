import _ from 'underscore';
import React from 'react';


const GridClusters = ({ 
  totalWidth, 
  predictions, 
  labelClusters,
  clusterFromLabel,
  scoreClusters,
  clusterFromScore,
  colorFromPosition,
  onClickPrediction
}) => {
  const nbRows = labelClusters.length;
  const nbCols = scoreClusters.length;

  const cellWidth = Math.min((totalWidth / Math.max(1, nbRows)) - 2, 35);
  const cellHeight = cellWidth;

  const inCell = _.map(_.range(nbRows), () => (
    _.map(_.range(nbCols), () => [])
  ));

  _.each(predictions, (prediction) => {
    const { letter, score } = prediction
    const iRow = clusterFromLabel(letter.toLowerCase());
    const iCol = clusterFromScore(score);
    if (iRow >= 0 && iCol >= 0) {
      inCell[iRow][iCol].push(prediction);
    }
  });

  return (
    <div style={{ position: 'relative', width: 1+nbCols*cellWidth, height: 1+nbRows*(cellHeight+1) }}>
      {_.map(inCell, (rowCells, iRow) => (
        <div key={'row_' + iRow} style={{ height: cellHeight}}>
          {_.map(rowCells, (predictionsInCell, iCol) => (
            <div key={'c_' + iRow + '_' + iCol} style={{ 
              display: 'inline-block',
              cursor: 'pointer', 
              width: cellWidth, 
              height: cellHeight, 
              borderRight: '1px solid white',
              borderBottom: '1px solid white',
              background: 'whitesmoke',
              ...!_.isEmpty(predictionsInCell) && {
                background: colorFromPosition(iRow, iCol),
                opacity: 1 - 1/(1+predictionsInCell.length)
              }
            }} onClick={() => {
              _.each(predictionsInCell, (prediction) => {
                onClickPrediction && onClickPrediction(prediction);
              });
            }} />
          ))}
        </div>
      ))}
    </div>
  );
}

export default GridClusters;
