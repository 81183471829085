import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  Header,
  Grid,
  Table,
  Button,
  Checkbox,
  Divider,
  Popup,
  Icon,
  Segment,
  Label,
} from 'semantic-ui-react';
import { isSummarySuspicious } from '../common';
import withSheetsManager from './withSheetsManager';

const ColorTag = ({ label, color, detail, size }) => (
  <Label size={size} color={color}>
    {label}
    {detail ? <Label.Detail>{detail}</Label.Detail> : null}
  </Label>
);

const getSummaryStr = (summary) => (
  _.isString(summary) ? (
    summary
  ) : _.isObject(summary) && _.isString(summary.content) ? (
    summary.content
  ) : _.isObject(summary) && _.isString((summary.content || {}).text) ? (
    summary.content.text
  ) : ''
);

const getHeadlineStr = (headline) => (
  _.isString(headline) ? (
    headline
  ) : _.isObject(headline) && _.isString(headline.content) ? (
    headline.content
  ) : _.isObject(headline) && _.isString((headline.content || {}).text) ? (
    headline.content.text
  ) : ''
)


const initiateState = ({ dockerGroupId, docker }) => {
  if (dockerGroupId !== 'done') {
    const offer = (docker || {}).offer || {};
    const sweetsheet = (docker || {}).sweetsheet || {};
    const sweetsheetData = sweetsheet.data || {};

    const todayDate = new Date().toISOString().slice(2, 10);
    const todayUploadCount =
      (sweetsheetData.upload || {}).lastDate === todayDate
        ? (sweetsheetData.upload || {}).count
        : 0;

    const nbStackedProfiles = offer.nbStackedProfiles || 0;
    const nbProfilesleftToUpload =
      ((offer.pushFlowPolicy || {}).nbPerDay || 0) - todayUploadCount;
    const maxStackSize = (offer.pushFlowPolicy || {}).maxStackSize || 0;
    const nbProfilesToUploadToday = Math.max(
      Math.min(
        nbProfilesleftToUpload,
        maxStackSize - nbStackedProfiles - todayUploadCount,
      ),
      0,
    );

    const pendingItems = _.reduce(
      docker.items,
      (memo, item, index) =>
        ((item || {}).state || {}).status !== 'uploaded'
          ? [...memo, { item, index }]
          : memo,
      [],
    );

    const itemFromIndex = {};
    _.each(pendingItems, ({ item, index }) => {
      itemFromIndex[index] = item;
    });

    const sortedPendingItemIds = _.pluck(
      _.sortBy(pendingItems, ({ item, index }) => (item.preselected ? 0 : 1)),
      'index',
    );

    let nbNotWatch = 0;
    const profilesToUpload = [];
    _.each(sortedPendingItemIds, (index) => {
      const item = itemFromIndex[index];
      const sourceType = (((item || {}).history || {}).source || {}).type || '';
      if (sourceType === 'watch') {
        profilesToUpload.push(index);
        return;
      } else {
        if (nbNotWatch >= nbProfilesToUploadToday) {
          return;
        }
        nbNotWatch++;
        profilesToUpload.push(index);
      }
    });

    /*_.first(sortedPendingItemIds, nbProfilesToUploadToday);*/

    return {
      profilesToUpload,
      nbProfilesleftToUpload: nbProfilesToUploadToday,
    };
  }
};

// Components
class DockerView extends React.PureComponent {
  state = {
    profilesToUpload: [],
  };
  componentWillMount() {
    if ((this.props.docker || {}).items) {
      const { dockerGroupId, docker } = this.props;
      const newState = initiateState({ dockerGroupId, docker });
      if (newState) {
        this.setState(newState);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.docker || {}).items && !(this.props.docker || {}).items) {
      const { dockerGroupId, docker } = nextProps;
      const newState = initiateState({ dockerGroupId, docker });
      if (newState) {
        this.setState(newState);
      }
    }
  }
  toggle = (index) => {
    if (_.indexOf(this.state.profilesToUpload, index) > -1) {
      const newProfilesToUpload = _.without(this.state.profilesToUpload, index);
      this.setState({ profilesToUpload: newProfilesToUpload });
    } else {
      this.setState({
        profilesToUpload: [...this.state.profilesToUpload, index],
      });
    }
  };
  toggleSetForce = (itemId) => {
    const current = this.state.setForce;
    this.setState({
      setForce: current === itemId ? null : itemId,
    });
  };
  toggleUploadForce = (itemId) => {
    const current = this.state.uploadForcedIds || {};
    this.setState({
      uploadForcedIds: {
        ...current,
        [itemId]: !current[itemId]
      }
    });
  }
  toggleUploadForceAll = () => {
    const current = this.state.uploadForcedIds || {};
    const itemIds = _.pluck((this.props.docker || {}).items, 'id');

    const areAllForced = _.every(itemIds, itemId => current[itemId]);

    if (areAllForced) {
      this.setState({
        uploadForcedIds: {}
      });
    } else {
      _.each((this.props.docker || {}).items, (item) => {
        if (((item || {}).state || {}).lockedFor) {
          this.unlockItem(item);
        }
      });

      this.setState({
        uploadForcedIds: _.object(
          _.map(itemIds, itemId => [itemId, true])
        )
      });
    }
  }
  togglePrependingMode = (itemId) => {
    const current = this.state.prependingModeIds || {};
    this.setState({
      prependingModeIds: {
        ...current,
        [itemId]: !current[itemId]
      }
    });
  }
  togglePrependingModeAll = () => {
    const current = this.state.prependingModeIds || {};
    const itemIds = _.pluck((this.props.docker || {}).items, 'id');

    const areAllPrepending = _.every(itemIds, itemId => current[itemId]);

    if (areAllPrepending) {
      this.setState({
        prependingModeIds: {}
      });
    } else {
      this.setState({
        prependingModeIds: _.object(
          _.map(itemIds, itemId => [itemId, true])
        )
      });
    }
  }
  uploadItems = () => {
    const items = _.compact(_.map(this.state.profilesToUpload, (index) => {
      if (_.isEmpty(this.props.docker.items) || this.props.docker.items.length <= index) {
        throw Error('invalid index in uploadItems')
      }
      if ((this.props.docker.items[index].state || {}).status === 'pending') {
        return this.props.docker.items[index]
      }
    }));
    this.props.uploadItems({ 
      items, 
      docker: this.props.docker, 
      force: false,
      forcedIds: this.state.uploadForcedIds,
      prependingModeIds: this.state.prependingModeIds,
    });
  }
  unlockItem = (item) => {
    this.props.unlockItem({ item, docker: this.props.docker });
  };
  deleteItem = (item) => {
    this.props.deleteItem({
      sheetId: item.sweetsheetId,
      itemId: item.id,
    });
  };
  markAsConflicting = async (item) => {
    const result = (await axios.post(
      `${baseUrl}/uploadView/markAsConflictingInSpreadsheet`,
      {
        sweetsheetId: item.sweetsheetId,
        emails: _.compact([(item.content || {}).email]),
      },
    )).data;
    alert(JSON.stringify(result, null, 4));
  };

  forceSetItemStatus = (item, status) => {
    this.props.forceSetItemStatus({ item, status, docker: this.props.docker });
  };
  unUploadItem = (item) => {
    this.props.unUploadItem({ item, docker: this.props.docker });
  };
  renderProfileRow = ({ sheet, item, fullnamesCount, index, inUploadList }) => {
    const profileData = (item || {}).content || {};
    const isPreselected = false; // TODO: migrate (item || {}).preselected;

    const fullname = profileData.firstname + ' ' + profileData.lastname;

    const fullnameCount = fullnamesCount[fullname] || 1;

    const editFieldFirstname = {
      label: 'firstname',
      key: 'firstname',
      type: 'input',
      initialValue: profileData.firstname,
    };
    const editFieldLastname = {
      label: 'lastname',
      key: 'lastname',
      type: 'input',
      initialValue: profileData.lastname,
    };
    const editFieldEmail = {
      label: 'email',
      key: 'email',
      type: 'input',
      initialValue: profileData.email,
    };
    const editFieldLinkedin = {
      label: 'linkedin',
      key: 'sources.linkedin',
      type: 'input',
      initialValue:
        (profileData.sources && profileData.sources.linkedin) || null,
    };
    const editFieldGithub = {
      label: 'github',
      key: 'sources.github',
      type: 'input',
      initialValue: (profileData.sources && profileData.sources.github) || null,
    };
    const editFieldStackoverflow = {
      label: 'stackoverflow',
      key: 'sources.stackoverflow',
      type: 'input',
      initialValue:
        (profileData.sources && profileData.sources.stackoverflow) || null,
    };
    const editFieldTwitter = {
      label: 'twitter',
      key: 'sources.twitter',
      type: 'input',
      initialValue:
        (profileData.sources && profileData.sources.twitter) || null,
    };
    const editFieldWebsite = {
      label: 'website',
      key: 'sources.website',
      type: 'input',
      initialValue:
        (profileData.sources && profileData.sources.website) || null,
    };

    const editFieldHeadline = {
      label: 'headline',
      key: 'headline',
      type: 'input',
      initialValue: getHeadlineStr(profileData.headline),
    };
    const editFieldComment = {
      label: 'comment',
      key: 'comment',
      type: 'input',
      initialValue:
        (((item || {}).annotations || {}).hiresweet || {}).message || null,
    };
    const editFieldSummary = {
      label: 'summary',
      key: 'summary',
      type: 'textarea',
      initialValue: getSummaryStr(profileData.summary),
    };

    const unitaryEdition = ({ label, key, type, initialValue }) => ({
      onClick: () => {
        this.props.askEditItemModal({
          sheetId: sheet.id,
          itemId: item.id,
          editFields: [{ label, key, type, initialValue }],
          withDeleteMode: false,
        });
      },
    });

    const fullEdition = () => ({
      onClick: () => {
        this.props.askEditItemModal({
          sheetId: sheet.id,
          itemId: item.id,
          editFields: [
            editFieldFirstname,
            editFieldLastname,
            editFieldEmail,
            editFieldLinkedin,
            editFieldGithub,
            editFieldStackoverflow,
            editFieldTwitter,
            editFieldWebsite,
            editFieldComment,
            //editFieldHeadline,
            //editFieldSummary,
          ],
          withDeleteMode: true,
        });
      },
    });

    const summaryString = getSummaryStr(profileData.summary);

    return (
      <Table.Row
        error={(item.uploadabilityStatus || {}).isConflicting}
        warning={
        !(item.uploadabilityStatus || {}).isConflicting &&
        !!(item.uploadabilityStatus || {}).lock
        }
        positive={['opportunities','max-matching', 'watch'].indexOf(((item.history || {}).source || {}).type) >= 0}
        textAlign="center"
        key={index}
        {...((item.state || {}).status === 'error'
           ? { error: true }
           : (item.state || {}).status === 'uploaded'
           ? { style: { color: '#aaaaaaaa' } }
           : {})}
      >

        <Table.Cell style={{ cursor: 'pointer' }} >
          <Icon name='pencil' {...fullEdition()} />
          <Icon name='setting' style={{ cursor: 'pointer' }} onClick={() => this.toggleSetForce(item.id)} />
          <Icon name='shield alternate'
                color={(this.state.uploadForcedIds || {})[item.id] ? 'green' : ''}
                style={{ cursor: 'pointer' }} onClick={() => {              
                  this.toggleUploadForce(item.id);
                  if (!(this.state.uploadForcedIds || {})[item.id] && (item.state || {}).lockedFor) {
                    this.unlockItem(item);
                  }
                }}
          />
          <Icon name='hourglass half'
                color={(this.state.prependingModeIds || {})[item.id] ? 'green' : ''}
                style={{ cursor: 'pointer' }} onClick={() => {              
                  this.togglePrependingMode(item.id);
                }}
          />
        </Table.Cell>
        <Table.Cell
          style={{ 
            cursor: 'pointer', 
            ...fullnameCount >= 2 && {
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'red'
            }
          }}
          {...unitaryEdition(editFieldFirstname)}
        >
          {profileData.firstname}
        </Table.Cell>
        <Table.Cell
          style={{ 
            cursor: 'pointer' ,
            ...fullnameCount >= 2 && {
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'red'
            }
          }}
          {...unitaryEdition(editFieldLastname)}
        >
          {profileData.lastname}
        </Table.Cell>
        <Table.Cell
          {...((profileData.email || '').indexOf(' ') >= 0 ||
               (profileData.email || '').indexOf('@') < 0
                                                      ? {
                                                        style: { cursor: 'pointer', backgroundColor: 'orange' },
                                                      }
                                                      : {
                                                        style: { cursor: 'pointer' },
                                                      })}
          {...unitaryEdition(editFieldEmail)}
          >
          {profileData.email}
        </Table.Cell>
        <Table.Cell>
          {_.map(profileData.sources, (sourceId, sourceType) => (
            <span key={sourceType + index} style={{ marginRight: '5px' }}>
              <a href={sourceId} target="_blank" rel='noopener noreferrer'>
                {sourceType}
              </a>
            </span>
          ))}
        </Table.Cell>
        <Table.Cell
          style={{ cursor: 'pointer' }}
          {...unitaryEdition(editFieldHeadline)}
        >
          {getHeadlineStr(profileData.headline)}
        </Table.Cell>
        <Table.Cell
          style={{ cursor: 'pointer' }}
          {...unitaryEdition(editFieldComment)}
        >
          {['opportunities'].indexOf(((item.history || {}).source || {}).type) >= 0 && (
            <Icon name="certificate" color="blue" />
          )}
          {['max-matching'].indexOf(((item.history || {}).source || {}).type) >= 0 && (
            <Icon name="star" color="purple" />
          )}
          {['watch'].indexOf(((item.history || {}).source || {}).type) >= 0 && (
            <Icon name="eye" color="orange" />
          )}
          {(((item || {}).annotations || {}).hiresweet || {}).message}
        </Table.Cell>
        <Table.Cell
          style={{
            backgroundColor: isSummarySuspicious(summaryString)
                           ? 'orange'
                           : 'none',
          }}
        >
          {summaryString && (
            <Popup
              wide="very"
              trigger={<Icon name="add" />}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: summaryString.replace(/\n/g, '<br/>'),
                  }}
                />
              }
            />
          )}
          <Icon
            name="pencil"
            style={{ cursor: 'pointer' }}
            {...unitaryEdition(editFieldSummary)}
          />
        </Table.Cell>
        <Table.Cell>
          {(item.state || {}).status}
          {(item.state || {}).lockedFor ? (
            <span>
              <Popup
                wide="very"
                trigger={
                  <Icon name="unlock" onClick={() => this.unlockItem(item)} />
                }
                content={(item.state || {}).error || 'unknown reason'}
              />
              {((item.state || {}).error || '').indexOf('conflict') >= 0 && (
                <span>
                  <Popup
                    trigger={
                      <Icon
                        name="remove"
                              style={{ cursor: 'pointer' }}
                              color="red"
                        onClick={() => this.deleteItem(item)}
                      />
                    }
                    content={'remove the profile from sweetsheet'}
                  />
                </span>
              )}
            </span>
          ) : (item.uploadabilityStatus || {}).isConflicting ? (
            <span>
              <Icon name="tags" color="red" />
              <Icon
                name="random"
                style={{ cursor: 'pointer' }}
                color="purple"
                onClick={() => this.markAsConflicting(item)}
              />
              <span>
                <Popup
                  trigger={
                    <Icon
                      name="remove"
                            style={{ cursor: 'pointer' }}
                            color="red"
                      onClick={() => this.deleteItem(item)}
                    />
                  }
                  content={'remove the profile from sweetsheet'}
                />
              </span>
            </span>
          ) : (item.uploadabilityStatus || {}).lock ? (
            <span>
              <Popup
                wide="very"
                trigger={<Icon name="clock" color="red" />}
                content={JSON.stringify(item.uploadabilityStatus.lock)}
              />
            </span>
          ) : null}
          {this.state.setForce === item.id && (
            <span>
              <Button
                color="orange"
                onClick={() => this.forceSetItemStatus(item, 'pending')}
              >
                Mark as pending
              </Button>
              <Button
                color="blue"
                onClick={() => this.forceSetItemStatus(item, 'uploaded')}
              >
                Mark as uploaded
              </Button>
            </span>
          )}
        </Table.Cell>
        <Table.Cell>
          {(item.state || {}).status === 'uploaded' ? (
            <Popup
              trigger={
                <Icon
                  link
                  name="minus"
                        color="red"
                  onClick={() => this.unUploadItem(item)}
                />
              }
              content={'unupload the profile from the client platform'}
            />
          ) : (
            <span>
              <Checkbox
                checked={inUploadList}
                onChange={() => this.toggle(index)}
              />
            </span>
          )}
        </Table.Cell>
        <Table.Cell>
          {(item.state || {}).status !== 'uploaded' && (
            <Icon
              onClick={() =>
                this.props.changeItemPreselection({
                  sheetId: sheet.id,
                  itemId: item.id,
                  preselection: !isPreselected,
                })
              }
              name="pin"
              size="large"
              color={isPreselected ? 'green' : 'grey'}
              style={{
                cursor: 'pointer',
                ...(!isPreselected && { opacity: 0.5 }),
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };
  render() {
    const { profilesToUpload, nbProfilesleftToUpload } = this.state;
    const docker = this.props.docker || {};
    const offer = docker.offer || {};
    const sweetsheet = docker.sweetsheet || {};
    const sweetsheetData = sweetsheet.data || {};
    const { platformId, nbStackedProfiles } = offer;
    const { items } = docker;
    const lastUpload = sweetsheetData.upload || {};
    const lastUploadDate = lastUpload.lastDate;
    const lastUploadCount = lastUpload.count;

    const todayDate = new Date().toISOString().slice(2, 10);
    const isMarkedAsCompleted = sweetsheetData.lastCompletionDate >= todayDate;

    const { maxStackSize } = offer.pushFlowPolicy || {};
    const title = offer.title || 'Unnamed';
    const platformLink =
      offer.platformId && offer.platformClientId
        ? `https://app.hiresweet.com/client/${offer.platformClientId}/offers/${
            offer.platformId
          }`
        : null;

    const { pushFlowPolicy } = offer;
    const pushFlowPolicyText = pushFlowPolicy
      ? pushFlowPolicy.type === 'daily-regular'
        ? pushFlowPolicy.nbPerDay +
          '/d' +
          (pushFlowPolicy.maxStackSize
            ? ', stacking at ' + pushFlowPolicy.maxStackSize
            : '')
        : JSON.stringify(pushFlowPolicy)
      : '';

    const fullnamesCount = {};
    _.each(items, item => {
      const profileData = (item || {}).content || {};
      const fullname = profileData.firstname + ' ' + profileData.lastname;
      fullnamesCount[fullname] = 1 + (fullnamesCount[fullname] || 0);
    });

    return (
      <Segment>
        <Label color="green" size="huge" ribbon>
          {platformId || offer.id || 'no-id'}
        </Label>

        <ColorTag
          size="huge"
          color="blue"
          label="Left To Upload Today"
          detail={nbProfilesleftToUpload + ''}
        />
        {maxStackSize && (
          <ColorTag
            size="huge"
            color="blue"
            label="Stacked"
            detail={nbStackedProfiles + '/' + maxStackSize}
          />
        )}
        {pushFlowPolicyText ? (
          <ColorTag
            size="huge"
            color="blue"
            label="Push Flow Policy"
            detail={pushFlowPolicyText}
          />
        ) : (
          <ColorTag
            size="huge"
            color="red"
            label="Push Flow Policy"
            detail={'NOT DEFINED'}
          />
        )}
        {lastUploadDate && (
          <ColorTag
            size="huge"
            color="blue"
            label="Last Upload Date"
            detail={lastUploadCount}
          />
        )}
        {lastUploadCount && (
          <ColorTag
            size="huge"
            color="blue"
            label="Last Upload Count"
            detail={lastUploadDate}
          />
        )}
        <div style={{ display: 'inline-block', float: 'right' }}>
          <Button
            color="blue"
            onClick={() =>
              sweetsheet.id && this.props.reloadDocker(sweetsheet.id)
            }
          >
            Reload Sheet
          </Button>
          {!isMarkedAsCompleted && (
            <Button
              onClick={() =>
                this.props.markSheetAsCompleted({
                  sheetId: sweetsheet.id,
                  noReload: true,
                })
              }
              color={nbProfilesleftToUpload > 0 ? 'orange' : 'green'}
            >
              Mark As Completed {sweetsheetData.lastCompletionDate}
            </Button>
          )}
        </div>
        <Header as="h2">
          {platformLink ? (
            <a href={platformLink} target="__blank" rel='noopener noreferrer'>
              {title}
            </a>
          ) : (
            title
          )}
          <a
            href={`/flowboard/sweetsheets/id/${
              sweetsheet.id
            }?withAdminActions=1`}
            target="__blank"
            rel='noopener noreferrer'
          >
            {' '}
            <Icon color="black" name="external square" />
          </a>
          {offer.id && (
            <a href={`/offers/${offer.id}`} target="_blank" rel='noopener noreferrer'>
              [SH]
            </a>
          )}
        </Header>
        {
          items && _.isArray(items) && items.length > 0 ? (
            <Table celled compact='very' sortable>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell width={2}>
                    <Icon name='shield' link onClick={this.toggleUploadForceAll}/>
                    <Icon name='hourglass half' link onClick={this.togglePrependingModeAll}/>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>Firstname</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Lastname</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Email</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Sources</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Headline</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Comment</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Summary</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={1} colSpan={2}>
                    <Button
                      {...(profilesToUpload || []).length === nbProfilesleftToUpload ? {
                        positive: true
                      } : {
                          style: { background: 'orange', color: 'white' }
                      }}
                      onClick={this.uploadItems}
                  >
                    Upload {(profilesToUpload || []).length}/
                    {nbProfilesleftToUpload}
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(items, (item, index) =>
                this.renderProfileRow({
                  sheet: sweetsheet,
                  item,
                  fullnamesCount,
                  index,
                  inUploadList: _.indexOf(profilesToUpload, index) > -1,
                }),
              )}
            </Table.Body>
          </Table>
        ) : null}
      </Segment>
    );
  }
}

class DockersGroup extends React.PureComponent {
  letterRanges = [
    'a-a',
    'b-b',
    'c-c',
    'd-d',
    'e-f',
    'g-i',
    'j-l',
    'm-n',
    'o-o',
    'p-p',
    'q-r',
    's-s',
    't-t',
    'u-v',
    'w-z',
    '1-9',
  ];
  state = {
    filteredSheetIdsSet: {},
    letterRange: '',
  };
  
  isInRange = (offer, letterRange) => {
    const companyId = (
      (offer && offer.companyId) ||
      'no-companyId'
    ).toLowerCase();
    const firstLetter = companyId.length > 0 ? companyId.charAt(0) : '';
    return firstLetter >= letterRange[0] && firstLetter <= letterRange[2];
  };
  loadLetterRange = (letterRange) => {
    let sheetIds = [];
    _.each(this.props.dockers, ({ sweetsheet, offer }) => {
      if (this.isInRange(offer, letterRange)) {
        sheetIds.push(sweetsheet.id);
      }
    });
    this.props.loadItems(sheetIds);
    this.setState({ letterRange });
  };

  render() {
    const groupId = this.props.dockerGroupId;
    const { dockers } = this.props;

    const companyIdFromDocker = (docker) =>
      (docker.offer && docker.offer.companyId) || 'no-companyId';

    const sortedDockers = _.sortBy(
      _.filter(
        dockers,
        (docker) =>
          this.isInRange(docker.offer || {}, this.state.letterRange) &&
          !(this.props.markedAsCompleted || {})[(docker.sweetsheet || {}).id],
      ),
      companyIdFromDocker,
    );

    const nbDockersInLetterRange = {};
    _.each(dockers, (docker) => {
      _.each(this.letterRanges, (letterRange) => {
        if (this.isInRange(docker.offer || {}, letterRange)) {
          nbDockersInLetterRange[letterRange] =
            (nbDockersInLetterRange[letterRange] || 0) + 1;
        }
      });
    });

    return (
      <div>
        <center>
          {_.map(this.letterRanges, (letterRange, index) => (
            <Button
              color={letterRange === this.state.letterRange ? 'green' : null}
              key={index}
              onClick={() => this.loadLetterRange(letterRange)}
            >
              {letterRange.toUpperCase()} (
              {nbDockersInLetterRange[letterRange] || '0'})
            </Button>
          ))}
        </center>
        {_.map(sortedDockers, (docker, index) => [
          index === 0 ||
          companyIdFromDocker(docker) !==
            companyIdFromDocker(sortedDockers[index - 1]) ? (
            <div key={'divider_' + index}>
              <br />
              <Divider horizontal>
                <span style={{ fontSize: 20 }}>
                  {companyIdFromDocker(docker)}
                </span>
              </Divider>
            </div>
          ) : null,
          <DockerView
            key={'docker_' + (docker.sweetsheet || {}).id}
            docker={docker}
            dockerGroupId={groupId}
            reloadDocker={this.props.reloadDocker}
            askEditItemModal={this.props.askEditItemModal}
            changeItemPreselection={this.props.changeItemPreselection}
            uploadItems={this.props.uploadItems}
            unUploadItem={this.props.unUploadItem}
            unlockItem={this.props.unlockItem}
            deleteItem={this.props.deleteItem}
            forceSetItemStatus={this.props.forceSetItemStatus}
            performActions={this.props.performActions}
            markSheetAsCompleted={this.props.markSheetAsCompleted}
          />,
        ])}
      </div>
    );
  }
}

class SheetsBoard extends React.PureComponent {
  state = {
    filterPaneDeployed: false,
  };
  componentWillMount() {
    const url = `${baseUrl}/uploadView/listSweetappActiveOffers`;
    axios.get(url).then(({ data }) => {
      if (data.results) {
        this.setState({
          sweetappActiveOffers: data.results,
        });
      }
    });
    this.props.loadDockers({ flyweight: true });
  }
  handleToggleFilterPane = () => {
    const current = this.state.filterPaneDeployed;
    this.setState({
      filterPaneDeployed: !current,
    });
  };
  showMissingOfferIds = () => {};
  renderDockersGroup(dockers, groupId) {
    return (
      <DockersGroup
        dockers={dockers}
        dockerGroupId={groupId}
        loadItems={this.props.loadItems}
        reloadDocker={this.props.reloadDocker}
        askEditItemModal={this.props.askEditItemModal}
        changeItemPreselection={this.props.changeItemPreselection}
        performActions={this.props.performActions}
        markedAsCompleted={this.props.markedAsCompleted}
        uploadItems={this.props.uploadItems}
        unUploadItem={this.props.unUploadItem}
        unlockItem={this.props.unlockItem}
        deleteItem={this.props.deleteItem}
        forceSetItemStatus={this.props.forceSetItemStatus}
        markSheetAsCompleted={this.props.markSheetAsCompleted}
      />
    );
  }
  render() {
    const { dockers } = this.props;
    const { filterPaneDeployed } = this.state;

    if (!this.state.sweetappActiveOffers) {
      return <div/>;
    }

    let dockersGroups = {
      ready: [],
      stacked: [],
      'action-required': [],
      done: [],
    };
    const todayDate = new Date().toISOString().slice(2, 10);
    _.each(dockers, (docker) => {
      const sweetsheetData = ((docker || {}).sweetsheet || {}).data || {};
      const todayUploadCount =
        (sweetsheetData.upload || {}).lastDate === todayDate
          ? (sweetsheetData.upload || {}).count
          : 0;
      const offer = (docker || {}).offer || {};
      const nbWaitingItems = ((docker || {}).sweetsheet || {}).itemStatuses[
        'pending'
      ];
      const nbProfilesleftToUpload =
        ((offer.pushFlowPolicy || {}).nbPerDay || 0) - todayUploadCount;

      const nbStackedProfiles = offer.nbStackedProfiles || 0;
      const maxStackSize = (offer.pushFlowPolicy || {}).maxStackSize || 0;
      // const nbProfilesToUploadToday = Math.max(Math.min(nbProfilesleftToUpload, maxStackSize - nbStackedProfiles - todayUploadCount), 0)

      const type =
        sweetsheetData.lastCompletionDate === todayDate
          ? 'done'
          : nbStackedProfiles - todayUploadCount >= maxStackSize
          ? 'stacked'
          : nbProfilesleftToUpload > nbWaitingItems
          ? 'ready' //'action-required'
          : 'ready';
      dockersGroups[type].push(docker);
    });

    const treatedDockers = _.filter(
      dockers,
      (docker) =>
        ((docker.sweetsheet || {}).data || {}).lastCompletionDate === todayDate,
    );
    const treatedSweetappOfferIds = _.compact(
      _.pluck(_.compact(_.pluck(treatedDockers, 'offer')), 'platformId'),
    );
    const sweetappActiveOfferIds = _.compact(
      _.pluck(this.state.sweetappActiveOffers || [], 'id'),
    );
    const leftActiveOffers = _.difference(
      sweetappActiveOfferIds,
      treatedSweetappOfferIds,
    );
    const leftActiveOffersStr = leftActiveOffers.join('\n');

    return (
      <Grid>
        <span>{(dockers || []).length} sheets ; </span>
        <span>
          <span
            title={leftActiveOffersStr}
            style={{ cursor: 'pointer', color: 'blue' }}
          >
            {leftActiveOffers.length}
          </span>{' '}
          active offers left
        </span>
        <Grid.Column width={filterPaneDeployed ? 12 : 15}>
          <Tabs>
            <TabList>
              <Tab>Ready ({dockersGroups['ready'].length})</Tab>
              <Tab>Stacked ({dockersGroups['stacked'].length})</Tab>
              <Tab>
                Action Required ({dockersGroups['action-required'].length})
              </Tab>
              <Tab>Done ({dockersGroups['done'].length})</Tab>
            </TabList>
            {_.map(
              ['ready', 'stacked', 'action-required', 'done'],
              (groupId, index) => (
                <TabPanel key={index}>
                  {this.renderDockersGroup(dockersGroups[groupId], groupId)}
                </TabPanel>
              ),
            )}
          </Tabs>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withSheetsManager(SheetsBoard, {
  filter: { rule: 'todays' },
  items: {
    filter: { rule: 'todays' },
    projection: {
      'content.experiences': 0,
      'content.education': 0,
      'content.relevantTags': 0,
      'content.sourceData': 0,
    },
    enrichments: {
      uploadabilityStatus: 1,
    },
  },
  offer: {
    enrichments: {
      nbStackedProfiles: 1,
      platformClientId: 1,
    },
  },
  sweetsheet: {
    enrichments: {
      itemStatuses: 1,
    },
  },
});
