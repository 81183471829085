import _ from 'underscore';
import React from 'react';
import {
  Grid,
} from 'semantic-ui-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

class SendsSkipsGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      target: 'lead-gen',
      percentMode: true,
    };
  }

  handleSetPercentMode = () => {
    this.setState({ percentMode: true });
  };
  handleSetCountMode = () => {
    this.setState({ percentMode: false });
  };
  handleSetTargetAll = () => {
    this.setState({ target: 'all' });
  };
  handleSetTargetLeadGen = () => {
    this.setState({ target: 'lead-gen' });
  };
  handleSetTargetWatch = () => {
    this.setState({ target: 'watch' });
  };

  render() {
    const { offerStats, leadgenOfferStats, watchOfferStats } = this.props;

    if (!offerStats || !leadgenOfferStats || !watchOfferStats) {
      return <div />;
    }

    const userKey = this.props.targetUser || 'total';

    const stats =
      this.state.target === 'lead-gen'
        ? leadgenOfferStats
        : this.state.target === 'watch'
        ? watchOfferStats
        : offerStats;

    const slots = _.last(
      stats[this.props.splitMode].slots,
      this.props.nbSplits,
    );

    const round = (x) => Math.round(100 * x) / 100


    const data = _.map(slots, ({ title, byUser, total }) => {
      const result = {
        name: title,
      };
      if (userKey === 'total') {
        const nbSends = (total['sends'] || {}).count || 0;
        const nbSkips = (total['skips'] || {}).count || 0;
        result['sends'] = nbSends;
        result['skips'] = nbSkips
        result['ratio'] = round(100 * nbSends / (Math.max(nbSends + nbSkips, 1)));
      } else {
        _.each(byUser, (userStats, user) => {
          if (user === userKey) {
            const nbSends = (userStats['sends'] || {}).count || 0;
            const nbSkips = (userStats['skips'] || {}).count || 0;
            result['sends'] = nbSends;
            result['skips'] = nbSkips
            result['ratio'] = round(100 * nbSends / (Math.max(nbSends + nbSkips, 1)));
          }
        });
      }
      return result;
    });

    const style = (key) => {
      const selected =
        this.state.target === key ||
        (key === 'percent' && this.state.percentMode) ||
        (key === 'count' && !this.state.percentMode);
      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    return (
      <Grid><Grid.Row>
        <Grid.Column width={12}>
          <BarChart 
            data={data}
            width={450} height={200} 
            margin={{ left: -10 }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip formatter={(value, name) => {
              if (name === 'ratio') {
                return (Math.round(value*100)/100) + '%';
              }
              return value;
            }}/>
            {this.state.percentMode ? (
              <Bar dataKey={'ratio'} fill="#8E44AD"/>
            ) : [
              <Bar key='send-charts' dataKey={'sends'} stackId='a' fill="#3498DB"/>,
              <Bar key='skip-charts' dataKey={'skips'} stackId='a' fill="#D35400"/>
            ]}

          </BarChart>
        </Grid.Column>
        <Grid.Column width={4}>
          <h3>{this.props.title}</h3>
          <br/>
          <div>
            <span style={style('all')} onClick={this.handleSetTargetAll}>All</span><span> | </span>
            <span style={style('lead-gen')} onClick={this.handleSetTargetLeadGen}>Lead Gen</span><span> | </span>
            <span style={style('watch')} onClick={this.handleSetTargetWatch}>Watch</span>
          </div>
          <br/>
          <div>
            <span style={style('count')} onClick={this.handleSetCountMode}>Count</span><span> | </span>
            <span style={style('percent')} onClick={this.handleSetPercentMode}>Percent</span>
          </div>
        
        </Grid.Column>

      </Grid.Row></Grid>
    );
  }
}

export default SendsSkipsGraph;
