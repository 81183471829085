import _ from 'underscore';
import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default ({ watcher, onClose, onDelete }) => (
  <Modal open={true} onClose={onClose} closeIcon closeOnDimmerClick={false}>
    <Modal.Header>
      Delete Watcher "{(watcher || {}).name}" ({(watcher || {}).id})
    </Modal.Header>
    <Modal.Content>Are you sure you want to delete this Watcher ?</Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>Cancel</Button>
      <Button color='red' onClick={()=>onDelete({ watcher })}>
        Delete
      </Button>
    </Modal.Actions>
  </Modal>
);
