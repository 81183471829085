import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Table, Grid, } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class AllThreads extends Component {
  constructor(props) {
    super(props);
    const timestamp = Date.now();
    const nbMsInAMinute = 60 * 1000;
    const nbMsInAnHour = 3600 * 1000;
    this.timestampSplits = [
      timestamp - 48 * nbMsInAnHour,
      timestamp - 24 * nbMsInAnHour,
      timestamp - 12 * nbMsInAnHour,
      timestamp - 4 * nbMsInAnHour,
      timestamp - nbMsInAnHour,
      timestamp - 30 * nbMsInAMinute,
    ];
    console.log(this.timestampSplits);
    this.bucketNames = [
      '>= 48h',
      '24h-48h',
      '12h-24h',
      '4h-12h',
      '1h-4h',
      '30min-1h',
      '0-30min',
    ].reverse();
    this.state = {};
    this.handleLoadData();
  }
  handleLoadData = async () => {
    const { data } = await axios.get(baseUrl + '/station/clients');
    const allClients = _.filter(
      _.sortBy(
        data,
        ({ adminState, id }) =>
          ({ active: '0', passive: '1', backlog: '2', undefined: '3' }[
            adminState
          ] + (id || '').toLowerCase()),
      ),
      ({ isFake }) => !isFake,
    );
    const allOffers = (await axios.get(baseUrl + '/station/offers')).data;
    console.log(allOffers);
    this.setState({
      allClients,
      allOffers,
    });

    const threadsStats = (await axios.post(baseUrl + '/station/threads', {
      minSentDateTimestamp: Date.now() - 60 * 24 * 3600 * 1000,
      timestampSplits: this.timestampSplits,
    })).data;
    console.log(threadsStats);
    this.setState({
      threadsStats,
    });
  };
  handleSelectClient = async (clientId) => {
    this.setState({
      selectedClient: {
        id: clientId,
      },
    });

    const clientOffers = (await axios.get(
      baseUrl + '/station/clients/' + clientId + '/offers',
    )).data;

    const offers = _.filter(
      _.sortBy(
        clientOffers,
        ({ adminState, creationDate }) =>
          ({ active: '3', passive: '2', backlog: '1', undefined: '0' }[
            adminState
          ] + creationDate),
      ).reverse(),
      ({ isFake }) => !isFake,
    );

    const profiles = [];
    for (let iOffer = 0; iOffer < offers.length; iOffer++) {
      const offerProfiles = (await axios.post(baseUrl + '/station/profiles', {
        clientIds: clientId,
        withLastThreadRefreshTimestamp: true,
        maxNbResults: 5000,
      })).data;
      _.each((offerProfiles || {}).results, (profile) => {
        profiles.push(profile);
      });
    }

    this.setState({
      selectedClient: {
        id: clientId,
        offers,
        profiles,
      },
    });
  };
  handleSelectOffer = async (offerId) => {
    const { selectedClient } = this.state;
    if (selectedClient) {
      const profiles = _.sortBy(
        _.filter(
          selectedClient.profiles,
          ({ jobOfferId, sentDate }) => sentDate && jobOfferId === offerId,
        ),
        'sendDate',
      ).reverse();

      this.setState({
        selectedClient: {
          ...selectedClient,
          selectedOffer: {
            id: offerId,
            profiles,
          },
        },
      });
    }
  };
  renderOverview() {
    const { threadsStats } = this.state;
    if (!threadsStats || !threadsStats.buckets) {
      return;
    }

    let totalCount = _.reduce(
      threadsStats.buckets,
      (memo, { count }) => memo + count,
      threadsStats.nbWithoutRefreshTimestamp,
    );

    const percent = (x) => Math.round(x * 10000) / 100;

    const counts = [threadsStats.nbWithoutRefreshTimestamp];
    _.each(threadsStats.buckets, ({ count }) => {
      counts.push(count);
    });

    let score = 0;
    const coefs = [0, 2, 3, 4, 5, 6, 16, 25];

    _.each(counts, (count, index) => {
      score += ((count / totalCount) * coefs[index]) / coefs[7];
    });

    const scoreColor =
      score > 0.8
        ? 'lime'
        : score > 0.6
        ? 'green'
        : score > 0.5
        ? 'olive'
        : score > 0.4
        ? 'orange'
        : 'red';

    return (
      <div>
        <center>
          <h1 style={{ color: scoreColor }}>Score of {percent(score)} %</h1>
        </center>
        <br />
        <br />

        <h2>
          Overview of profiles sent in the last 60 days ({totalCount} sends)
        </h2>
        <Table>
          <Table.Header>
            {_.map(this.bucketNames, (bucketName, index) => (
              <Table.HeaderCell key={index}>{bucketName}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>Never Refreshed</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {_.map(
                _.sortBy(threadsStats.buckets, (x, i) => -i),
                (bucket, index) => (
                  <Table.Cell key={index}>
                    <span>{bucket.count}</span>
                  </Table.Cell>
                ),
              )}
              <Table.Cell>{threadsStats.nbWithoutRefreshTimestamp}</Table.Cell>
            </Table.Row>
            <Table.Row>
              {_.map(
                _.sortBy(threadsStats.buckets, (x, i) => -i),
                (bucket, index) => (
                  <Table.Cell key={index}>
                    <span>{percent(bucket.count / (totalCount || 1))}%</span>
                  </Table.Cell>
                ),
              )}
              <Table.Cell>
                {percent(
                  threadsStats.nbWithoutRefreshTimestamp / (totalCount || 1),
                )}
                %
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
        <br />
      </div>
    );
  }
  renderClientSelector() {
    const selectedClientId = (this.state.selectedClient || {}).id;
    return (
      <div>
        <h2>Clients</h2>
        {_.map(this.state.allClients, ({ id, adminState, name }) => (
          <div key={id} onClick={() => this.handleSelectClient(id)}>
            <span
              style={{
                cursor: 'pointer',
                color:
                  selectedClientId && selectedClientId === id ? 'blue' : 'gray',
              }}
            >
              [{adminState}] {name}
            </span>
          </div>
        ))}
      </div>
    );
  }
  renderOfferSelector() {
    const selectedClient = this.state.selectedClient || {};
    const offers = selectedClient.offers || [];
    const selectedOfferId = (selectedClient.selectedOffer || {}).id;
    return (
      <div>
        <h2>Offers Selector</h2>
        {_.map(offers, ({ id, adminState, title }) => (
          <div key={id} onClick={() => this.handleSelectOffer(id)}>
            <span
              style={{
                cursor: 'pointer',
                color:
                  selectedOfferId && selectedOfferId === id ? 'blue' : 'gray',
              }}
            >
              [{adminState}] {title}
            </span>
          </div>
        ))}
      </div>
    );
  }
  renderProfiles() {
    const selectedClient = this.state.selectedClient || {};
    const profiles = (selectedClient.selectedOffer || {}).profiles;
    console.log('profiles');
    console.log(profiles);
    const getRefreshDateFromThreads = (threads) => {
      let timestamp = 0;
      _.each(threads, ({ lastRefreshTimestamp }) => {
        if (lastRefreshTimestamp > timestamp) {
          timestamp = lastRefreshTimestamp;
        }
      });
      return timestamp;
    };
    return (
      <div>
        <h2>Profiles</h2>
        {_.map(profiles, ({ id, sentDate, threads }, index) => (
          <div key={index}>
            <span
              style={{
                color: 'gray',
              }}
            >
              [{new Date(sentDate).toISOString().slice(2, 16)}] - [
              {new Date(getRefreshDateFromThreads(threads))
                .toISOString()
                .slice(2, 16)}
              ] {id}
            </span>
          </div>
        ))}
      </div>
    );
  }
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>{this.renderOverview()}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>{this.renderClientSelector()}</Grid.Column>
          <Grid.Column width={5}>{this.renderOfferSelector()}</Grid.Column>
          <Grid.Column width={8}>{this.renderProfiles()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default AllThreads;
