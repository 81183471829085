import { Checkbox, List, Select, SelectInt, enhance } from 'sweetform';
import { Form, Grid, Table } from 'semantic-ui-react';
import { buildFilter, formScorerToQuery } from '../../Offers/SearchModal';

import React from 'react';
import { SelectJobTitles } from './EditOfferModalComponents';
import { Textarea } from '../../common';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl';

// EXPERIENCE

const noLateralPadding = { paddingRight: 0, paddingLeft: 0, margin: 0 };

const OkExperienceEditor = enhance((props) => (
  <Grid.Row columns={4}>
    <Grid.Column style={noLateralPadding}>
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', top: -58 }}>
          <SelectInt {...props} min={-2} max={50} field='min' placeholder='Relaxed Min' />
        </div>
      </div>
    </Grid.Column>
    <Grid.Column style={noLateralPadding} />
    <Grid.Column style={noLateralPadding} />
    <Grid.Column style={noLateralPadding}>
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', top: -58 }}>
          <SelectInt {...props} min={-2} max={50} field='max' placeholder='Relaxed Max' />
        </div>
      </div>
    </Grid.Column>
  </Grid.Row>
));

const TargetExperienceEditor = enhance((props) => (
  <Grid.Row columns={4}>
    <Grid.Column style={noLateralPadding} />
    <Grid.Column style={noLateralPadding}>
      <SelectInt {...props} min={-2} max={50} field='min' placeholder='Min' />
    </Grid.Column>
    <Grid.Column style={noLateralPadding}>
      <SelectInt {...props} min={-2} max={50} field='max' placeholder='Max' />
    </Grid.Column>
    <Grid.Column style={noLateralPadding} />
  </Grid.Row>
));

const OkTargetExperienceEditor = enhance((props) => (
  <Grid>
    <TargetExperienceEditor field='target' />
    <OkExperienceEditor field='okWith' />
  </Grid>
));

// LOCATION
export const loadLocations = async (input) => {
  const { locations } = (await axios.get(`${baseUrl}/locations/sweethubShowableLocations`)).data;
  return { options: _.sortBy(locations, 'label'), complete: true };
};

const REMOTE_OPTIONS = [
  { value: 'mainly-on-site', label: 'Punctual Remote' },
  { value: 'hybrid-remote', label: 'Hybrib Remote' },
  { value: 'near-full-remote', label: 'Majority Remote' },
  { value: 'full-remote', label: 'Full Remote' },
  { value: 'full-time', label: '_ft_' },
  { value: 'partial-remote', label: '_par_' },
  { value: 'remote', label: '_r_' },
];

export const SelectLocation = enhance((props) => (
  <Grid columns={2}>
    <Grid.Column>
      <Select field='remoteWish' options={REMOTE_OPTIONS} defaultValue='partial-remote' />
    </Grid.Column>
    <Grid.Column>
      <Select async={true} field='location' loadOptions={loadLocations} labelKey='label' valueKey='value' />
    </Grid.Column>
  </Grid>
));
const OkTargetSelectLocationEditor = enhance((props) => (
  <Grid columns={2}>
    <Grid.Column>
      <Form.Field>
        <label>Target</label>
        <List {...props} field='target' component={SelectLocation} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column>
      <Form.Field>
        <label>Ok</label>
        <List {...props} field='okWith' component={SelectLocation} />
      </Form.Field>
    </Grid.Column>
  </Grid>
));

// BACKGROUND

const SOLIDITIES = [1, 2, 3, 4, 5];
const OK_TARGET_IMPORTANCES = [
  { label: '∅', value: 'none' },
  { label: 'Ok', value: 'okWith' },
  { label: 'Target', value: 'target' },
];
const BackgroundSolidityEditor = enhance((props) => {
  const { value } = props;
  const disabled = value ? value.get('disabled') : false;
  return (
    <div>
      {!disabled ? (
        <Table basic='very'>
          <Table.Header>
            <Table.Row textAlign='center'>
              {_.map(SOLIDITIES, (solidity) => (
                <Table.HeaderCell key={solidity}> {solidity}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Row>
            {_.map(SOLIDITIES, (solidity) => (
              <Table.Cell key={solidity}>
                {<Select field={solidity.toString()} options={OK_TARGET_IMPORTANCES} />}
              </Table.Cell>
            ))}
          </Table.Row>
        </Table>
      ) : null}
      <Checkbox
        style={{
          zIndex: 0,
        }}
        slider
        defaultValue={true}
        field='disabled'
        label='No Background Solidity'
      />
    </div>
  );
});
/*     
      <Grid.Column>
      <Form.Field>
        <label>Target</label>
        <SelectBackgroundSolidity field="target"/>
      </Form.Field>
      </Grid.Column>
      <Grid.Column>
      <Form.Field>
        <label>Ok</label>
        <SelectBackgroundSolidity field="okWith"/>
      </Form.Field>
      </Grid.Column>
      </Grid> */

const PRESTIGE_TYPES = [
  { id: 'bootcamp', name: 'Bootcamp' },
  { id: 'low', name: 'Low' },
  { id: 'medium', name: 'Medium' },
  { id: 'top', name: 'Top' },
  { id: 'very-top', name: 'Very Top' },
];

const SchoolPrestigeEditor = enhance((props) => {
  const { value } = props;
  const disabled = value ? value.get('disabled') : false;
  return (
    <div>
      {!disabled ? (
        <Table basic='very'>
          <Table.Header>
            <Table.Row textAlign='center'>
              {_.map(PRESTIGE_TYPES, (type) => (
                <Table.HeaderCell key={type.id}> {type.name}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Row>
            {_.map(PRESTIGE_TYPES, (type) => (
              <Table.Cell key={type.id}>{<Select field={type.id} options={OK_TARGET_IMPORTANCES} />}</Table.Cell>
            ))}
          </Table.Row>
        </Table>
      ) : null}
      <Checkbox slider defaultValue={true} field='disabled' label='No School Prestige' />
    </div>
  );
});

const STARTUPNESS_IMPORTANCES = [
  { id: 'high', name: 'High' },
  { id: 'medium', name: 'Medium' },
  { id: 'low', name: 'Low' },
];

// JOBS
const JobPositionEditor = enhance((props) => {
  const { value } = props;
  const entries = ((value || {})._root || {}).entries;
  const isMajorFreeTextTab = _.find(entries, (entry) => entry[0] == 'isMajorFreeText');
  const isMinorFreeTextTab = _.find(entries, (entry) => entry[0] == 'isMinorFreeText');
  const isMajorFreeText = isMajorFreeTextTab ? isMajorFreeTextTab[1] : false;
  const isMinorFreeText = isMinorFreeTextTab ? isMinorFreeTextTab[1] : false;

  return (
    <div>
      <label>Major:</label>
      <Checkbox
        style={{
          zIndex: 0,
        }}
        slider
        defaultValue={false}
        field='isMajorFreeText'
        label='Free'
      />
      <SelectJobTitles field='major' isFreeText={isMajorFreeText} cerejobsV2={props.cerejobsV2} />
      <label>Minors:</label>
      <Checkbox
        style={{
          zIndex: 0,
        }}
        slider
        defaultValue={false}
        field='isMinorFreeText'
        label='Free'
      />
      <SelectJobTitles field='minors' multi isFreeText={isMinorFreeText} cerejobsV2={props.cerejobsV2} />
    </div>
  );
});

// SKILLS
const OUTLIER_SKILLS = [
  '3d-studio-max',
  'antitag',
  'bootcamp',
  'ceo',
  'computer-programming',
  'computer-science',
  'consulting',
  'data-mining',
  'entrepreneurship',
  'job-seeking',
  'lead-dev',
  'management',
  'microsoft-excel',
  'not-job-seeking',
  'open-source',
  'pair-programming',
  'phd',
  'physics',
  'problem-solving',
  'programmable-editor',
  'public-speaking',
  'quantified-self',
  'r-and-d',
  'top-engineering-school',
  'top-management',
  'top-school',
  'web',
  'web-frontend-js',
];

export const loadSkills = async (input) => {
  const skills = (await axios.get(`${baseUrl}/tags/list?type=skill`)).data;
  const filteredSkills = _.filter(skills, (skill) => OUTLIER_SKILLS.indexOf(skill.id) < 0);
  return { options: filteredSkills, complete: true };
};
const MultiSelectSkill = enhance((props) => {
  const { value } = props;
  const entries = ((value || {})._root || {}).entries;
  const isFreeTextTab = _.find(entries, (entry) => entry[0] == 'isFreeText');
  const isFreeText = isFreeTextTab ? isFreeTextTab[1] : false;
  return (
    <div>
      <Checkbox
        style={{
          zIndex: 0,
        }}
        slider
        defaultValue={false}
        field='isFreeText'
        label='Free'
      />
      {isFreeText ? (
        <Textarea
          style={{
            width: '100%',
            resize: 'none',
            height: '35px',
            overflow: 'hidden',
            display: 'table-cell',
          }}
          field='skills'
          placeholder='separate your key words with ; (ex : data-science;python)'
        />
      ) : (
        <Select async={true} multi field='skills' loadOptions={loadSkills} labelKey='name' valueKey='id' creatable />
      )}
    </div>
  );
});

const SkillsEditor = enhance((props) => (
  <Grid>
    <Grid columns={3}>
      <Grid.Column>
        Main
        <List component={MultiSelectSkill} field='main' />
      </Grid.Column>
      <Grid.Column>
        Important
        <List component={MultiSelectSkill} field='important' />
      </Grid.Column>
      <Grid.Column>
        Bonus
        <List component={MultiSelectSkill} field='bonus' />
      </Grid.Column>
    </Grid>
  </Grid>
));

// EXTRA
// const MODALITIES = [{ value: 'research', label: 'Recherche' }, { value: 'consulting', label: 'Consultant' }];
// const SelectModalities = () => <Select field="modalities" multi options={MODALITIES} />;

// const ListOfSons = enhance(({ Op }) => <List field="sons" component={Op} />);

const deprecatedFilterIdsInExtraCriteria = [
  '#kAmongN',
  'targetLocation',
  'targetLocationRelaxed',
  'everBeenToRelaxed',
  'targetJobPosition',
  'targetJobPositionRelaxed',
  'targetResponsibilityRelaxed',
  'targetModality',
  'experienceTechYearRelaxedRange',
  'nbAnswersRange',
  'nbPositiveAnswersRange',
  'nbAnyAnswersRange',
  'schoolCategoryRange',
  'solicitationRange',
  'hasSkill',
  'isFreelance',
  'watchFilter',
  'baseFilter',
  'githubRepository',
  'githubFollowers',
  'matchSheetsSet',
  'schoolNamesScorer',
  'currentLinkedinCompanyScorer',
  'currentCompanyScorer',
  'instantFlowMatchOffer',
  'isInstantFlow',
  'everAnsweredPositively',
  'everPushedToClient',
  'hasEmail',
  'hasGithubEmail',
  'worksForClient',
  'disqualifyRate',
  'profileLastUpdate',
  'companyType',
  'companyIndustry',
  'companyPrestige',
  'jobScoresS6',
  'skillScoresS6',
  'sample',
  'magicFilter',
  'background',
];

const IMPORTANCE_OPTIONS = [
  { value: 'required', label: 'Required' },
  { value: 'important', label: 'Important' },
  { value: 'bonus', label: 'Bonus' },
  { value: 'bigBonus', label: 'Big Bonus' },
  { value: 'absolute', label: 'Absolute' },
];

const withImportance = (Component) => {
  return enhance((props) => (
    <Grid columns={2}>
      <Grid.Column width={2} textAlign='right'>
        <Select field='importance' options={IMPORTANCE_OPTIONS} defaultValue='required' />
      </Grid.Column>
      <Grid.Column width={14}>
        <Component field='criterion' />
      </Grid.Column>
    </Grid>
  ));
};

export const ExtraCriteria = withImportance(buildFilter(true, false, deprecatedFilterIdsInExtraCriteria));

const ImportanceWeight = (props) => <SelectInt {...props} min={0} max={5} placeholder='Importance' />;

export const EditOmegaCriteria = enhance((props) => (
  <div>
    {!props.onlyExtraCriteria && (
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <h3> Experience </h3>
          </Grid.Column>
          <Grid.Column width={14}>
            <OkTargetExperienceEditor field='experience' />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}>
            <h3> Location </h3>
          </Grid.Column>
          <Grid.Column width={14}>
            <OkTargetSelectLocationEditor field='locations' />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}>
            <Grid.Row>
              <h3> Background </h3>
            </Grid.Row>
            <Grid.Row>
              <ImportanceWeight field='background#importance' />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={14}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>Background Solidity</Grid.Column>
                <Grid.Column width={14}>
                  <BackgroundSolidityEditor field='backgroundSolidity' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={2}>School Prestige</Grid.Column>
                <Grid.Column width={14}>
                  <SchoolPrestigeEditor field='schoolPrestige' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={2}>Startupness Importance</Grid.Column>
                <Grid.Column width={14}>
                  <Select field='startupnessImportance' options={STARTUPNESS_IMPORTANCES} labelKey='name' valueKey='id' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}>
            <Grid.Row>
              <h3> Skills </h3>
            </Grid.Row>
            <Grid.Row>
              <ImportanceWeight field='skills#importance' />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={14}>
            <SkillsEditor field='skills' />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}>
            <Grid.Row>
              <h3> Job </h3>
            </Grid.Row>
            <Grid.Row>
              <ImportanceWeight field='jobPosition#importance' />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={14}>
            <JobPositionEditor field='jobPosition' cerejobsV2={props.cerejobsV2} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}

    <Grid>
      <Grid.Row>
        <Grid.Column width={2}>
          <h3> Extra Criteria </h3>
        </Grid.Column>
        <Grid.Column width={14}>
          <List field='extraCriteriaRaw' component={ExtraCriteria} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>

));

const formatSingleEntity = (entity, isFreeText) => {
  if (isFreeText) {
    return { text: entity };
  }
  return { id: entity };
};

export const formCriteriaToSchemaCriteria = (criteria) => {
  console.log(criteria);
  const formatMultiSelect = (multiselect, isFreeText) => {
    return _.map(_.compact((multiselect || '').split(';')), (id) => formatSingleEntity(id, isFreeText));
  };
  const formatLocations = (locations) => {
    return {
      target: _.map((locations || {}).target || [], (element) => ({ ...element, location: { id: element.location } })),
      okWith: _.map((locations || {}).okWith || [], (element) => ({ ...element, location: { id: element.location } })),
    };
  };
  const formatJobPosition = (jobPosition) => {
    return {
      ...(jobPosition.major ? { major: formatSingleEntity(jobPosition.major, jobPosition.isMajorFreeText) } : {}),
      ...(jobPosition.minors ? { minors: formatMultiSelect(jobPosition.minors, jobPosition.isMinorFreeText) } : {}),
    };
  };
  const formatSkills = (skillsCriterion) => {
    return {
      ...(skillsCriterion.main
        ? {
          main: _.map(skillsCriterion.main, ({ skills, isFreeText }) => ({
            skills: formatMultiSelect(skills, isFreeText),
          })),
        }
        : {}),
      ...(skillsCriterion.important
        ? {
          important: _.map(skillsCriterion.important, ({ skills, isFreeText }) => ({
            skills: formatMultiSelect(skills, isFreeText),
          })),
        }
        : {}),
      ...(skillsCriterion.bonus
        ? {
          bonus: _.map(skillsCriterion.bonus, ({ skills, isFreeText }) => ({
            skills: formatMultiSelect(skills, isFreeText),
          })),
        }
        : {}),
    };
  };
  // const formatOkTarget = (okTarget) => {
  //   return {
  //     target: formatMultiSelect((okTarget || {}).target),
  //     okWith: formatMultiSelect((okTarget || {}).okWith),
  //   };
  // };
  const formatBackgroundSolidity = (backgroundSolidity) => {
    if (!(backgroundSolidity || {}).disabled) {
      if (!_.every(SOLIDITIES, (solidity) => backgroundSolidity[solidity])) {
        throw Error('If not disabled, all background solidities should be defined');
      }
    }
    let targetMinValue = 10;
    let okMinValue = 10;
    _.each(backgroundSolidity, (importance, solidity) => {
      if (importance === 'target') {
        targetMinValue = Math.min(targetMinValue, parseInt(solidity));
      } else if (importance === 'okWith') {
        okMinValue = Math.min(okMinValue, parseInt(solidity));
      }
    });
    return {
      ...(targetMinValue !== 10 ? { target: { min: targetMinValue } } : {}),
      ...(okMinValue !== 10 ? { okWith: { min: okMinValue } } : {}),
      disabled: (backgroundSolidity || {}).disabled,
    };
  };
  const formatSchoolPrestige = (schoolPrestige) => {
    if (!(schoolPrestige || {}).disabled) {
      if (!_.every(PRESTIGE_TYPES, (type) => schoolPrestige[type.id])) {
        throw Error('If not disabled, all school prestiges should be defined');
      }
    }
    const targetValues = [];
    const okValues = [];
    _.each(schoolPrestige, (importance, categoryId) => {
      if (importance === 'target') {
        targetValues.push({ id: categoryId });
      }
      if (importance === 'okWith') {
        okValues.push({ id: categoryId });
      }
    });
    return {
      ...(targetValues.length > 0 ? { target: targetValues } : {}),
      ...(okValues.length > 0 ? { okWith: okValues } : {}),
      disabled: (schoolPrestige || {}).disabled,
    };
  };
  const getImportanceWeights = (criteria) => {
    const importanceWeights = {};
    _.each(criteria, (value, key) => {
      if (key.indexOf('#') >= 0) {
        importanceWeights[key.split('#')[0]] = value;
      }
    });
    return importanceWeights;
  };
  const formatExtraCriteriaRaw = (extraCriteriaRaw) => {
    return _.map(extraCriteriaRaw, (extraCriterionRaw) => {
      return {
        ...extraCriterionRaw,
        criterion: formScorerToQuery(extraCriterionRaw.criterion),
      };
    });
  };
  const {
    jobPosition,
    locations,
    experience,
    startupnessImportance,
    backgroundSolidity,
    schoolPrestige,
    extraCriteriaRaw,
    skills,
    seekingCandidatesOnly,
  } = criteria || {};
  const resCriteria = {
    ...(experience ? { experience } : {}),
    ...(locations ? { locations: formatLocations(locations) } : {}),
    ...(jobPosition ? { jobPosition: formatJobPosition(jobPosition) } : {}),
    ...(backgroundSolidity ? { backgroundSolidity: formatBackgroundSolidity(backgroundSolidity) } : {}),
    ...(schoolPrestige ? { schoolPrestige: formatSchoolPrestige(schoolPrestige) } : {}),
    ...(startupnessImportance ? { startupnessImportance } : {}),
    ...(criteria && !_.isEmpty(getImportanceWeights(criteria))
      ? { importanceWeights: getImportanceWeights(criteria) }
      : {}),
    ...(skills ? { skills: formatSkills(skills) } : {}),
    ...(extraCriteriaRaw
      ? {
        extraCriteriaRaw: extraCriteriaRaw,
        extraCriteria: formatExtraCriteriaRaw(extraCriteriaRaw),
      }
      : {}),
    ...(seekingCandidatesOnly && { seekingCandidatesOnly }),
  };
  console.log('formCriteriaToSchemaCriteria');
  console.log('criteria', criteria);
  console.log('resCriteria', resCriteria);
  return resCriteria;
};

export const schemaCriteriaToFormCriteria = (criteria) => {
  const formatMultiSelect = (multiselect) => {
    return _.map(multiselect || [], (item) => {
      return item.id ? item.id : item.text;
    }).join(';');
  };
  const formatLocationsList = (locationsList) =>
    _.map(locationsList || [], (item) => ({ ...item, location: item.location.id }));
  const formatLocations = (locations) => {
    return {
      ...(locations.target ? { target: formatLocationsList(locations.target) } : {}),
      ...(locations.okWith ? { okWith: formatLocationsList(locations.okWith) } : {}),
    };
  };
  const formatJobPosition = (jobPosition) => {
    return {
      ...(jobPosition.major && (jobPosition.major.id || jobPosition.major.text)
        ? { major: jobPosition.major.id || jobPosition.major.text }
        : {}),
      ...(jobPosition.minors ? { minors: formatMultiSelect(jobPosition.minors) } : {}),
      isMinorFreeText: _.some(jobPosition.minors || [], (job) => job.text != undefined) ? true : false,
      isMajorFreeText: (jobPosition.major || {}).text ? true : false,
    };
  };
  const formatSkills = (skillsCriterion) => {
    return {
      ...(skillsCriterion.main
        ? {
          main: _.map(skillsCriterion.main, ({ skills }) => ({
            skills: formatMultiSelect(skills),
            isFreeText: _.some(skills || [], (skill) => skill.text != undefined) ? true : false,
          })),
        }
        : {}),
      ...(skillsCriterion.important
        ? {
          important: _.map(skillsCriterion.important, ({ skills }) => ({
            skills: formatMultiSelect(skills),
            isFreeText: _.some(skills || [], (skill) => skill.text != undefined) ? true : false,
          })),
        }
        : {}),
      ...(skillsCriterion.bonus
        ? {
          bonus: _.map(skillsCriterion.bonus, ({ skills }) => ({
            skills: formatMultiSelect(skills),
            isFreeText: _.some(skills || [], (skill) => skill.text != undefined) ? true : false,
          })),
        }
        : {}),
    };
  };
  // const formatOkTarget = (okTarget) => {
  //   return {
  //     ...(okTarget.target ? { target: formatMultiSelect(okTarget.target) } : {}),
  //     ...(okTarget.okWith ? { okWith: formatMultiSelect(okTarget.okWith) } : {}),
  //   };
  // };
  const addHashtagImportance = (importanceWeights) => {
    return _.object(
      _.map(_.keys(importanceWeights || {}), (key) => key + '#importance'),
      _.values(importanceWeights || {}),
    );
  };
  const formatBackgroundSolidity = (backgroundSolidity) => {
    const values = {};
    _.each(SOLIDITIES, (solidity) => {
      values[solidity] = 'none';
    });
    const maxSolidity = Math.max(...SOLIDITIES);
    let minValue = ((backgroundSolidity || {}).okWith || {}).min;
    if (minValue) {
      _.each(_.range(minValue, maxSolidity + 1), (solidity) => {
        values[solidity.toString()] = 'okWith';
      });
    }
    minValue = ((backgroundSolidity || {}).target || {}).min;
    if (minValue) {
      _.each(_.range(minValue, maxSolidity + 1), (solidity) => {
        values[solidity.toString()] = 'target';
      });
    }
    values.disabled = (backgroundSolidity || {}).disabled;
    return values;
  };

  const formatSchoolPrestige = (schoolPrestige) => {
    const values = {};
    _.each(PRESTIGE_TYPES, (type) => {
      values[type.id] = 'none';
    });
    if (((schoolPrestige || {}).target || []).length > 0) {
      _.each(schoolPrestige.target, (element) => {
        values[element.id] = 'target';
      });
    }
    if (((schoolPrestige || {}).okWith || []).length > 0) {
      _.each(schoolPrestige.okWith, (element) => {
        values[element.id] = 'okWith';
      });
    }
    values.disabled = (schoolPrestige || {}).disabled;
    return values;
  };

  const {
    jobPosition,
    locations,
    experience,
    startupnessImportance,
    backgroundSolidity,
    schoolPrestige,
    extraCriteriaRaw,
    importanceWeights,
    skills,
  } = criteria || {};

  const formattedCriteria = {
    ...(experience ? { experience } : {}),
    ...(locations ? { locations: formatLocations(locations) } : {}),
    ...(jobPosition ? { jobPosition: formatJobPosition(jobPosition) } : {}),
    ...(backgroundSolidity ? { backgroundSolidity: formatBackgroundSolidity(backgroundSolidity) } : {}),
    ...(schoolPrestige ? { schoolPrestige: formatSchoolPrestige(schoolPrestige) } : {}),
    ...(startupnessImportance ? { startupnessImportance } : {}),
    ...(importanceWeights ? addHashtagImportance(importanceWeights) : {}),
    ...(skills ? { skills: formatSkills(skills) } : {}),
    ...(extraCriteriaRaw ? { extraCriteriaRaw } : {}),
  };
  console.log('schemaCriteriaToFormCriteria');
  console.log('criteria', criteria);
  console.log('formattedCriteria', formattedCriteria);
  return formattedCriteria;
};
