import _ from 'underscore';
import React, { Component } from 'react';
import ImportOfferModal from '../DataPointSandbox/ImportOfferModal';
import { Grid } from 'semantic-ui-react';
import EditOfferModal from '../DataPointSandbox/EditOfferModal';
import EditCriterionModal from '../DataPointSandbox/EditCriterionModal';
import Offer from '../components/Offer';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class OfferFunctionInput extends Component {
  handleClickOnEdit = async () => {
    this.setState({ editable: true });
  };

  onClose = async () => {
    this.setState({ editable: false });
  };

  customHandleSubmit = async (offerJSON) => {
    this.handleSubmit(offerJSON.data);
  };

  handleOpenEditOfferModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'edit-offer',
      },
    });
  };

  handleClickOnUnset = () => {
    this.handleSubmit({});
  };

  handleOpenImportOfferModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'import-offer',
      },
    });
  };

  handleCloseEdition = () => {
    this.setState({ edition: null });
  };

  handleSubmit = (e) => {
    this.setState({ edition: null });
    this.props.onSubmit(e);
  };

  handleClickOnSample = async () => {
    let url = baseUrl + '/techAsset/randomOffer';
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const randomOffer = (await axios.get(url, customHeaders)).data;
    this.handleSubmit(randomOffer);
  };

  render() {
    const { rawEntity } = this.props;
    const { edition } = this.state || {};

    return (
      <div>
        <Grid columns={4}>
          <Grid.Column>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={this.handleOpenImportOfferModal}>
              Import Offer
            </a>
          </Grid.Column>
          <Grid.Column>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }} onClick={this.handleOpenEditOfferModal}>
              Edit Offer
            </a>
          </Grid.Column>
          <Grid.Column>
            <a style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }} onClick={this.handleClickOnSample}>
              Sample Offer
            </a>
          </Grid.Column>
          <Grid.Column>
            <a style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }} onClick={this.handleClickOnUnset}>
              Unset Offer
            </a>
          </Grid.Column>
        </Grid>

        {rawEntity && !_.isEmpty(rawEntity) && <Offer offer={rawEntity} />}
        {(edition || {}).type === 'import-offer' && (
          <ImportOfferModal key={edition.key} onClose={this.handleCloseEdition} onSave={this.customHandleSubmit} />
        )}
        {(edition || {}).type === 'edit-offer' && (
          <EditOfferModal
            key={edition.key}
            initialValue={rawEntity}
            onClose={this.handleCloseEdition}
            onSave={this.customHandleSubmit}
          />
        )}
        {(edition || {}).type === 'edit-criterion' && (
          <EditCriterionModal
            key={edition.key}
            initialValue={rawEntity}
            onClose={this.handleCloseEdition}
            onSave={this.customHandleSubmit}
          />
        )}
      </div>
    );
  }
}

export default OfferFunctionInput;
