import React, { Component } from 'react';
import { Button, Modal, Grid } from 'semantic-ui-react';
import _ from 'underscore';
import {
  SelectSkillAndRange,
  SelectJobAndRange,
  SelectEntities,
  SelectJobPosition,
  SelectPositiveOrNegative,
  SelectBoolean,
  SelectRange,
  SelectYear,
  SelectXpYear,
  SelectXpYearAndJob,
  SelectExtraCriteriaAndRange,
  SelectTypeToConstraints,
} from './TestEditModal';
import baseUrl from '../baseUrl.js';
import axios from 'axios';
import { SweetForm, List, Select, enhance } from 'sweetform';

const getHiresweetOfferIds = async () => {
  const url = `${baseUrl}/offers/list`;
  const offersList = (await axios.get(url)).data;
  return { options: _.map(offersList, ({ id, title }) => ({ label: id, value: id })) };
};

const SAMPLING_MODE_OPTIONS = [
  { label: 'All Profiles', value: 'all-profiles' },
  { label: 'Client Actions', value: 'client-actions' },
  { label: 'Manual Profiles', value: 'manual-profiles' },
];

const OffersSelector = enhance((props) => (
  <div>
    <Select field='ids' async={true} loadOptions={getHiresweetOfferIds} multi={true} />
  </div>
));

const ACTION_TYPE_OPTIONS = [
  { label: 'Send', value: 'send' },
  { label: 'Answer', value: 'answer-detected' },
  { label: 'Skip', value: 'skipProfile' },
];

const ActionsSelector = enhance((props) => (
  <div>
    <Select field='types' options={ACTION_TYPE_OPTIONS} multi={true} />
  </div>
));

const ClientActionsSelector = (props) => {
  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>
            <b>Offers</b>
          </label>
        </Grid.Column>
        <Grid.Column width={8}>
          <OffersSelector field='offers' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>
            <b>Actions</b>
          </label>
        </Grid.Column>
        <Grid.Column width={8}>
          <ActionsSelector field='actions' />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const MANUAL_PROFILES_CAT_OPTIONS = [
  { label: 'null', value: null },
  { label: 'it', value: 'it' },
  { label: 'social-care', value: 'social-care' },
  { label: 'nurses', value: 'nurses' },
  { label: 'old', value: 'old' },
];
const SelectSamplingBase = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('samplingMode') : '';
  return (
    <div>
      <Select field='samplingMode' defaultValue='all-profiles' options={SAMPLING_MODE_OPTIONS} />
      {mode === 'client-actions' ? <ClientActionsSelector /> : null}
      {mode === 'manual-profiles' ? <Select field='cat' options={MANUAL_PROFILES_CAT_OPTIONS} /> : null}
    </div>
  );
});

class PropertyModal extends Component {
  async componentWillMount() {
    const { initialValue } = this.props;
    let currentValue = _.pick(initialValue, 'constraints', 'comment', 'name', 'status', 'techComment');
    this.setState({
      currentValue: currentValue,
    });
  }
  handleChangeForm = (value) => {
    this.setState({ currentValue: value });
  };

  render() {
    const { resultType, onClose, onCreate, sweetfunctionId, initialValue } = this.props;
    const { currentValue } = this.state || {};

    const { constraints, samplingBase } = currentValue || {};
    return (
      <Modal onClose={onClose} open size='fullscreen' closeIcon>
        <Modal.Content size='fullscreen'>
          <SweetForm initialValues={initialValue} onChange={this.handleChangeForm}>
            <Grid columns={2}>
              <Grid.Column>
                <h3>Properties : </h3>
                {resultType === 'score' || resultType === 'score-prestige' ? (
                  <List field='constraints' component={SelectRange} />
                ) : resultType === 'score-dict' || resultType === 'normalized-score-dict' ? (
                  (sweetfunctionId || '').includes('skill') ? (
                    <List field='constraints' component={SelectSkillAndRange} />
                  ) : (sweetfunctionId || '').includes('absolute') || sweetfunctionId == 'magic_filter_bigquery' ? (
                    <List field='constraints' component={SelectExtraCriteriaAndRange} />
                  ) : (sweetfunctionId || '').includes('job') ? (
                    <List field='constraints' component={SelectJobAndRange} />
                  ) : sweetfunctionId || '' === 'profile_education_prestige' ? (
                    <List field='constraints' component={SelectJobAndRange} />
                  ) : null
                ) : resultType === 'parsed-entities' ? (
                  (sweetfunctionId || '').includes('propagated') ? (
                    <List field='constraints' component={SelectJobPosition} />
                  ) : (sweetfunctionId || '').includes('signals') ? (
                    <List field='constraints' component={SelectPositiveOrNegative} />
                  ) : (
                    <List field='constraints' component={SelectEntities} />
                  )
                ) : resultType === 'dict-score-dict' ? (
                  <List field='constraints' component={SelectTypeToConstraints} />
                ) : resultType === 'boolean' ? (
                  <List field='constraints' component={SelectBoolean} />
                ) : resultType === 'year' ? (
                  <List field='constraints' component={SelectYear} />
                ) : resultType === 'xp-year' ? (
                  <List field='constraints' component={SelectXpYear} />
                ) : resultType === 'xp-dict' ? (
                  <List field='constraints' component={SelectXpYearAndJob} />
                ) : null}
              </Grid.Column>
              <Grid.Column>
                <h3>Profiles Sampling Base : </h3>
                <SelectSamplingBase field='samplingBase' />
              </Grid.Column>
            </Grid>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => onCreate({ constraints, samplingBase })}>
            Save Property
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default PropertyModal;
