const femaleNames = [
  'Elodie',
  'Laura',
  'Julie',
  'Marine',
  'Marie',
  'Marion',
  'Pauline',
  'Aurélie',
  'Mélanie',
  'Audrey',
  'Emilie',
  'Sarah',
  'Anaïs',
  'Anais',
  'Amandine',
  'Céline',
  'Sophie',
  'Charlotte',
  'Mathilde',
  'Jennifer',
  'Jessica',
  'Laëtitia',
  'Laetitia',
  'Lucie',
  'Cindy',
  'Mélissa',
  'Melissa',
  'Caroline',
  'Claire',
  'Stéphanie',
  'Fanny',
  'Morgane',
  'Justine',
  'Amélie',
  'Manon',
  'Coralie',
  'Adeline',
  'Alexandra',
  'Chloé',
  'Hélène',
  'Aurore',
  'Sabrina',
  'Clémence',
  'Charlène',
  'Léa',
  'Sandra',
  'Angélique',
  'Virginie',
  'Cécile',
  'Estelle',
  'Vanessa',
  'Marina',
  'Margaux',
  'Delphine',
  'Alice',
  'Laurie',
  'Elisé',
  'Ludivine',
  'Gwendoline',
  'Laure',
  'Emeline',
  'Emmanuelle',
  'Sonia',
  'Noémie',
  'Anne',
  'Gaëlle',
  'Gaelle',
  'Déborah',
  'Alexia',
  'Johanna',
  'Lucile',
  'Juliette',
  'Maeva',
  'Cynthia',
  'Anne-Sophie',
  'Ophélie',
  'Christelle',
  'Myriam',
  'Célia',
  'Kelly',
  'Marjorie',
  'Charline',
  'Samantha',
  'Maud',
  'Floriane',
  'Sandrine',
  'Elsa',
  'Mélodie',
  'Alicia',
  'Aline',
  'Mylène',
  'Clémentine',
  'Océane',
  'Julia',
  'Lisa',
  'Tiffany',
  'Aude',
  'Nathalie',
  'Carole',
  'Isabelle',
  'Solène',
  'Florence',
  'Eva',
  'Leslie',
  'Margot',
  'Mélody',
  'Priscilla',
  'Agathe',
  'Louise',
  'Alison',
  'Inès',
  'Sandy',
  'Cécilia',
  'Tiphaine',
  'Magali',
  'Barbara',
  'Marlène',
  'Natacha',
  'Nadia',
  'Perrine',
  'Anne-Laure',
  'Karine',
  'Annabelle',
  'Clara',
  'Ingrid',
  'Séverine',
  'Olivia',
  'Victoria',
  'Lauriane',
  'Laurine',
  'Anissa',
  'Tatiana',
  'Blandine',
  'Gwenaëlle',
  'Gwenaelle',
  'Priscillia',
  'Diane',
  'Lise',
  'Leïla',
  'Leila',
  'Nina',
  'Lydie',
  'Nadège',
  'Valérie',
  'Roxane',
  'Cassandra',
  'Aurélia',
  'Valentine',
  'Flora',
  'Jeanne',
  'Anna',
  'Fatima',
  'Marianne',
  'Rachel',
  'Emma',
  'Linda',
  'Andréa',
  'Lucille',
  'Constance',
  'Fiona',
  'Anaëlle',
  'Anaelle',
  'Coraline',
  'Elisa',
  'Héloïse',
  'Coline',
  'Allison',
  'Elisabeth',
  'Nelly',
  'Florine',
  'Alizée',
  'Eloïse',
  'Clarisse',
  'Marjolaine',
  'Catherine',
  'Axelle',
  'Katia',
  'Sophia',
  'Carine',
  'Magalie',
  'Maëlle',
  'Maelle',
  'Alix',
  'Lolita',
  'Sara',
  'Gabrielle',
  'Karen',
  'Nolwenn',
  'Astrid',
  'Ornella',
  'Doriane',
  'Laurène',
  'Agnès',
  'Agnes',
  'Oriane',
  'Sabine',
  'Laurence',
  'Wendy',
  'Prescillia',
  'Sylvie',
  'Flavie',
  'Lydia',
  'Ambre',
  'Lola',
  'Mégane',
  'Eugénie',
  'Rebecca',
  'Malika',
  'Zoé',
  'Joanna',
  'Samira',
  'Samia',
  'Adèle',
  'Eléonore',
  'Candice',
  'Cathy',
  'Géraldine',
  'Betty',
  'Tiphanie',
  'Bénédicte',
  'Clotilde',
  'Esther',
  'Salomé',
  'Nora',
  'Kathleen',
  'Sofia',
  'Marie-Laure',
  'Béatrice',
  'Lindsay',
  'Yasmina',
  'Karima',
  'Maureen',
  'Angelina',
  'Maryline',
  'Dounia',
  'Anastasia',
  'Erika',
  'Violaine',
  'Flore',
  'Nancy',
  'Claudia',
  'Marie-Charlotte',
  'Mélina',
  'Faustine',
  'Léna',
  'Angéline',
  'Gwladys',
  'Cassandre',
  'Adélaïde',
  'Soraya',
  'Mélinda',
  'Raphaëlle',
  'Anne-Lise',
  'Bérénice',
  'Harmonie',
  'Christina',
  'Laureen',
  'Pamela',
  'Bérengère',
  'Alisson',
  'Edwige',
  'Maïté',
  'Maité',
  'Anne-Charlotte',
  'Jessie',
  'Fatoumata',
  'Farah',
  'Christine',
  'Véronique',
  'Laurianne',
  'Patricia',
  'Amanda',
  'Gladys',
  'Lauren',
  'Eve',
  'Helena',
  'Lou',
  'Amina',
  'Laury',
  'Diana',
  'Pascaline',
  'Capucine',
  'Jenny',
  'Anne-Claire',
  'Maïlys',
  'Mailys',
  'Maria',
  'Tania',
  'Ariane',
  'Dorine',
  'Joséphine',
  'Madeline',
  'Lorène',
  'Muriel',
  'Aminata',
  'Daphné',
  'Solenne',
  'Stella',
  'Typhaine',
  'Iris',
  'Angela',
  'Graziella',
  'Lara',
  'Sylvia',
  'Marielle',
  'Khadija',
  'Prisca',
  'Victoire',
  'Philippine',
  'Maude',
  'Mariam',
  'Shirley',
  'Kim',
  'Amélia',
  'Lorraine',
  'Cyndie',
  'Harmony',
  'Clothilde',
  'Bérangère',
  'Aïcha',
  'Aicha',
  'Corinne',
  'Asma',
  'Maÿlis',
  'Naïma',
  'Suzanne',
  'Armelle',
  'Malvina',
  'Léonie',
  'Jennyfer',
  'Auriane',
  'Noëlla',
  'Noella',
  'Yasmine',
  'Manuella',
  'Orlane',
  'Elena',
  'Florie',
  'Violette',
  'Orianne',
  'Safia',
  'Mériem',
  'Marilyne',
  'Méryl',
  'Alyssa',
  'Fabienne',
  'Johana',
  'Salima',
  'Alexandrine',
  'Cloé',
  'Judith',
  'Roxanne',
  'Monia',
  'Apolline',
  'Joëlle',
  'Joelle',
  'Nassima',
  'Annaëlle',
  'Annaelle',
  'Carole-Anne',
  'Assia',
  'Fatma',
  'Nabila',
  'Manuela',
  'Fatiha',
  'Lauréline',
  'Hortense',
  'Nawel',
  'Dalila',
  'Tamara',
  'Bélinda',
  'Prescilla',
  'Albane',
  'Sandie',
  'Yamina',
  'Edith',
  'Marguerite',
  'Murielle',
  'Carla',
  'Paola',
  'Emily',
  'Laïla',
  'Laula',
  'Loubna',
  'Mona',
  'Hawa',
  'Evelyne',
  'Sana',
  'Maryne',
  'Latifa',
  'Marie-Sophie',
  'Nadine',
  'Christel',
  'Lina',
  'Françoise',
  'Halima',
  'Joy',
  'Marilyn',
  'Joana',
  'Jenna',
  'Ameline',
  'Soïzic',
  'Mary',
  'Lucy',
  'Tiffanie',
  'Emelyne',
  'Steffie',
  'Bertille',
  'Enora',
  'Charlyne',
  'Louisa',
  'Jérômine',
  'Jéromine',
  'Donia',
  'Juliana',
  'Lilia',
  'Kathy',
  'France',
  'Jamila',
  'Larissa',
  'Mounia',
  'Irène',
  'Fleur',
  'Lila',
  'Marie-Anne',
  'Katy',
  'Annie',
  'Rachida',
  'Nastasia',
  'Claudine',
  'Joyce',
  'Imen',
  'Marie-Ange',
  'Sindy',
  'Mina',
  'Loriane',
  'Chrystelle',
  'Marie-Hélène',
  'Anne-Marie',
  'Anita',
  'Brenda',
  'Odile',
  'Hanna',
  'Lauranne',
  'Daisy',
  'Zohra',
  'Amira',
  'Kahïna',
  'Alissa',
  'Valériane',
  'Lamia',
  'Joanne',
  'Megan',
  'Domitille',
  'Anouk',
  'Sephora',
  'Marie-Pierre',
  'Perle',
  'Vicky',
  'Soumia',
  'Bettina',
  'Djamila',
  'Soline',
  'Marylène',
  'Sabah',
  'Stacy',
  'Madeleine',
  'Michèle',
  'Sahra',
  'Liza',
  'Selma',
  'Alizé',
  'Rose',
  'Aziza',
  'Mathilda',
  'Ninon',
  'Solange',
  'Martine',
  'Miléna',
  'Saïda',
  'Saida',
  'Faïza',
  'Faiza',
  'Marie-Caroline',
  'Lison',
  'Vinciane',
  'Séréna',
  'Carolane',
  'Tifany',
  'Mouna',
  'Eléa',
  'Alyson',
  'Angie',
  'Davina',
  'Mélaine',
  'Suzy',
  'Typhanie',
  'Vanina',
  'Sabrine',
  'Camélia',
  'Viviane',
  'Dorothée',
  'Juliane',
  'Noëlie',
  'Ana',
  'Laurette',
  'Mélany',
  'Steffy',
  'Hafida',
  'Prescilia',
  'Malorie',
  'Eglantine',
  'Line',
  'Virginia',
  'Lynda',
  'Priscille',
  'Magdaléna',
  'Wassila',
  'Ombeline',
  'Solenn',
  'Kassandra',
  'Gwennaëlle',
  'Mallory',
  'Alexiane',
  'Eline',
  'Orane',
  'Dolorès',
  'Nesrine',
  'Cléa',
  'Dominique',
  'Claudie',
  'Rosalie',
  'Jodie',
  'Carmen',
  'Lesly',
  'Zineb',
  'Karina',
  'Emmeline',
  'Marylou',
  'Kimberley',
  'Ketty',
  'Milène',
  'Alexie',
  'Anne-Gaëlle',
  'Farida',
  'Maëlys',
  'Pascale',
  'Aliénor',
  'Maya',
  'Awa',
  'Lucia',
  'Maïmouna',
  'Servane',
  'Honorine',
  'Loreleï',
  'Maryse',
  'Thérèse',
  'Célina',
  'Ashley',
  'Rozenn',
  'Marie-Claire',
  'Yolaine',
  'Pénélope',
  'Hannah',
  'Florianne',
  'Kenza',
  'Laëticia',
  'Maurine',
  'Charlie',
  'Lysiane',
  'Wafa',
  'Elody',
  'Maryam',
  'Bouchra',
  'Bintou',
  'Imène',
  'Hajar',
  'Hülya',
  'Arielle',
  'Soumaya',
  'Livia',
  'Laure-Anne',
  'Noura',
  'Liliane',
  'Priscilia',
  'Grâce',
  'Emine',
  'Mariama',
  'Rachelle',
  'Laurane',
  'Marthe',
  'Elvina',
  'Ella',
  'Garance',
  'Fatou',
  'Aïssata',
  'Leyla',
  'Tina',
  'Tiphany',
  'Calypso',
  'Naoual',
  'Jacqueline',
  'Mélisande',
  'Thi',
  'Sarra',
  'Loren',
  'Adélie',
  'Hakima',
  'Iman',
  'Marjory',
  'Guillemette',
  'Doris',
  'Anabelle',
  'Alexane',
  'Meryem',
  'Manël',
  'Carol-Anne',
  'Marie-France',
  'Cléo',
  'Sibylle',
  'Emilia',
  'Marie-Lou',
  'Lindsey',
  'Adrienne',
  'Gina',
  'Gloria',
  'Ghislaine',
  'Elina',
  'Bernadette',
  'Sidonie',
  'Marie-Astrid',
  'Lorine',
  'Fabiola',
  'Aïssatou',
  'Ghizlane',
  'Nathanaëlle',
  'Sixtine',
  'Magaly',
  'Débora',
  'Naomi',
  'Brigitte',
  'Joannie',
  'Aïda',
  'Yaël',
  'Marie-Noëlle',
  'Adriane',
  'Candy',
  'Francesca',
  'Sanaa',
  'Marika',
  'Tressy',
  'Marilou',
  'Paula',
  'Suzie',
  'Mélissandre',
  'Geneviève',
  'Hind',
  'Tess',
  'Kristell',
  'Tifenn',
  'Sybille',
  'Michelle',
  'Anouck',
  'Ophélia',
  'Fatimata',
  'Elizabeth',
  'Prune',
  'Noëllie',
  'Jocelyne',
  'Maëliss',
  'Marie-Julie',
  'Meghann',
  'Nawelle',
  'Najat',
  'Meggy',
  'Saliha',
  'Roselyne',
  'Célie',
  'Edwina',
  'Blanche',
  'Zahra',
  'Rima',
  'Marwa',
  'Ikram',
  'Houria',
  'Marie-Christine',
  'Josépha',
  'Méline',
  'Miriam',
  'Tara',
  'Stephy',
  'Fany',
  'Roseline',
  'Peggy',
  'Nicole',
  'Nour',
  'Marie-Line',
  'Hana',
  'Nassera',
  'Danielle',
  'Isaure',
  'Yousra',
  'Flavia',
  'Whitney',
  'Olympe',
  'Daphnée',
  'Rita',
  'Ericka',
  'Maïwenn',
  'Rose-Marie',
  'Elvire',
  'Eden',
  'Alexa',
  'Fanta',
  'Ludmilla',
  'Noria',
  'Cannelle',
  'Salma',
  'Mariette',
  'Zeynep',
  'Eulalie',
  'Fadila',
  'Claire-Marie',
  'Laurie-Anne',
  'Ouarda',
  'Sharon',
  'Madison',
  'Alysson',
  'Shérazade',
  'Tuba',
  'Hermine',
  'Dina',
  'Habiba',
  'Marylin',
  'Mireille',
  'Mariane',
  'Colette',
  'Mirella',
  'Antoinette',
  'Paloma',
  'Julianne',
  'Raja',
  'Loréna',
  'Cinthia',
  'Christie',
  'Annabel',
  'Fannie',
  'Marie-Eve',
  'Valène',
  'Daniella',
  'Gwénola',
  'Maëla',
  'Hasna',
  'Juline',
  'Monique',
  'Marylise',
  'Allyson',
  'Yolène',
  'Angelica',
  'Malory',
  'Meryll',
  'Djemila',
];

export function isFemaleName(name) {
  return femaleNames.indexOf(name) >= 0;
}
