import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Form, Divider, Icon, Table, Message } from 'semantic-ui-react';
import baseUrl from '../baseUrl';

const validIdRegex = /[a-z0-9-]+-demo$/;

class DemoView extends React.Component {
  state = {
    language: 'en',
    id: '-demo',
    name: '',
    demoClients: [],
  };
  componentDidMount() {
    this.onLoadDemoClients();
  }
  onLoadDemoClients = () => {
    return axios
      .get(`${baseUrl}/station/demo`)
      .then(({ data }) => {
        if (data.error) {
          this.setState({ getError: data.error });
        } else if (!data.success || !data.demoClients) {
          this.setState({ getError: 'unknown error' });
        } else {
          this.setState({ demoClients: data.demoClients });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ getError: error.message });
      });
  };
  handleChange = (e, { value, name }) => {
    if (name === 'id' && !value.endsWith('-demo')) {
      return;
    }
    this.setState({ [name]: value });
  };
  handleCreateDemo = () => {
    this.setState({ createdClient: null, disableCreate: true });
    const { id, name, language } = this.state;
    if (!validIdRegex.test(id)) {
      return this.setState({
        createError: 'id can only contain lowercase letters, "-" and must end with "-demo"',
        disableCreate: false,
      });
    }
    if (!name || !language) {
      return this.setState({ createError: 'all fields are required', disableCreate: false });
    }
    return axios
      .post(`${baseUrl}/station/demo/create`, { id, name, language })
      .then(({ data }) => {
        if (data.error) {
          this.setState({ createError: data.error, disableCreate: false });
        } else if (!data.success) {
          this.setState({ createError: 'unknown error', disableCreate: false });
        } else if (data.id) {
          this.setState({ createdClient: data.id, createError: null, disableCreate: false });
        } else {
          this.setState({ createError: 'unknown error', disableCreate: false });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ createError: error.message, disableCreate: false });
      })
      .then(() => {
        return this.onLoadDemoClients();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ getError: error.message });
      });
  };
  handleDeleteDemo = (id) => {
    if (!id) {
      return this.setState({ deleteError: 'no id provided', deleteErrorClientid: '' });
    } else {
      this.setState({ deleteError: null, deleteErrorClientid: null });
    }

    return axios
      .post(`${baseUrl}/station/demo/delete`, { id })
      .then(({ data }) => {
        if (data.error) {
          this.setState({ deleteError: data.error, deleteErrorClientid: id });
        } else if (!data.success) {
          this.setState({ deleteError: 'unknown error', deleteErrorClientid: id });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ deleteError: error.message, deleteErrorClientid: id });
      })
      .then(() => {
        return this.onLoadDemoClients();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ getError: error.message });
      });
  };
  render() {
    const {
      language,
      id,
      name,
      disableCreate,
      createError,
      createdClient,
      demoClients,
      getError,
      deleteError,
      deleteErrorClientid,
    } = this.state;

    return (
      <div
        style={{
          position: 'fixed',
          top: '68px',
          left: 0,
          height: 'calc(100vh - 68px)',
          width: '100%',
          overflowY: 'auto'
        }}
      >
        <div style={{ width: '100%', marginBottom: '30px', padding: 12 }}>
          <h2>New demo client</h2>
          <Form>
            <Form.Group inline>
              <Form.Input label='id' placeholder='acme-demo' value={id} name='id' onChange={this.handleChange} />
              <Form.Input label='name' placeholder='Acme' value={name} name='name' onChange={this.handleChange} />
              <Form.Select
                label='language'
                options={[{ text: 'English', value: 'en' }, { text: 'French', value: 'fr' }]}
                value={language}
                name='language'
                onChange={this.handleChange}
              />
              <Form.Button positive onClick={this.handleCreateDemo} disabled={!!disableCreate}>
                Submit
              </Form.Button>
              {createError && <div style={{ color: 'red' }}>{`Error: ${createError}`}</div>}
              {createdClient && (
                <div style={{ color: 'green' }}>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href={`https://app.hiresweet.com/client/${createdClient}`}
                  >
                    <Icon name='external' color='blue' size='big' title='See on app' />
                  </a>
                  {`client ${createdClient} created with one offer and some profiles`}
                </div>
              )}
            </Form.Group>
          </Form>
        </div>
        <Divider />
        <div style={{ width: '100%', padding: 12 }}>
          <h2>Current demo clients</h2>

          {getError && <Message negative title='Error' content={getError} />}
          {deleteError && (
            <Message
              negative
              title='Error'
              content={deleteError + (deleteErrorClientid ? ` on client ${deleteErrorClientid}` : '')}
            />
          )}
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Language</Table.HeaderCell>
                <Table.HeaderCell>Creation Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(demoClients, (client, index) => {
                const { id, name, creationDate, language } = client;
                return (
                  <Table.Row key={index + client.id}>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>
                      <a href={`https://app.hiresweet.com/client/${id}`} target='_blank' rel='noopener noreferrer'>
                        <Icon name='external' color='blue' title='See on App' size='large' />
                      </a>
                      <Icon
                        name='trash'
                        color='red'
                        style={{ cursor: 'pointer', marginLeft: 12, marginBottom: 2 }}
                        onClick={() => {
                          this.handleDeleteDemo(id);
                        }}
                        size='large'
                        title='Delete client, offers, profiles'
                      />
                    </Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{language}</Table.Cell>
                    <Table.Cell>{moment(creationDate).format('YY-MM-DD HH:mm')}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}
export default DemoView;
