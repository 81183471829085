import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import { Segment, Button, Icon, List, Input, Popup } from 'semantic-ui-react';
import { removeSpacesAndDash } from '../../common';
import Copy from '../../components/Copy';

class Emails extends React.Component {
  state = {
    displayAll: false,
  };

  onTriggerExpandOrCollapse = () => {
    this.setState(({ displayAll }) => ({ displayAll: !displayAll }));
  };

  onClickEmail = (email) => {
    const uploadEmail = this.props.email;
    uploadEmail.set(email);
  };

  onRemoveEmail = async (email) => {
    await axios.post(`${baseUrl}/sweetynotes/linkedin/${this.props.linkedinId}/mismatchEmails`, {
      mismatchEmail: email,
    });
    const { emailHints } = this.props;
    emailHints.set(_.reject(emailHints.get(), (emailHint) => emailHint.email === email));
  };

  render() {
    const { emailHints, email, firstname, lastname, sweetynote } = this.props;
    const uploadEmail = email.get();
    const mismatchEmails = _.pluck((sweetynote || {}).mismatchEmails || [], 'email');
    const rawEmailHints = emailHints.get();
    const emailHintsWithoutMismatch = _.reject(
      rawEmailHints,
      (emailHint) => mismatchEmails.indexOf(emailHint.email) > -1,
    );
    let displayedEmails = _.reject(
      emailHintsWithoutMismatch,
      ({ sources }) => (sources || []).length === 1 && sources[0] === 'failedReverseLinkedinEmails',
    );

    if (uploadEmail && _.every(_.compact(displayedEmails), ({ email }) => uploadEmail !== email)) {
      const selectedEmailHint = _.findWhere(emailHintsWithoutMismatch, { email: uploadEmail });
      if (selectedEmailHint) {
        displayedEmails.push(selectedEmailHint);
      } else {
        displayedEmails.push({ email: uploadEmail, sources: ['custom'] }); // IS it ok ?
      }
    }

    const successReverseEmails = _.pluck(
      _.filter(rawEmailHints, ({ sources }) => (sources || []).indexOf('reverseLinkedinEmails') >= 0),
      'email',
    );

    const failedReverseEmails = _.pluck(
      _.filter(rawEmailHints, ({ sources }) => (sources || []).indexOf('failedReverseLinkedinEmails') >= 0),
      'email',
    );

    if (!this.state.displayAll) {
      displayedEmails = displayedEmails.slice(0, 3);
    }

    return (
      <Segment color='green'>
        <h2>
          Emails
          <Copy text={`${removeSpacesAndDash(firstname)} ${removeSpacesAndDash(lastname)}@gmail.com`} />
          {!_.isEmpty([...failedReverseEmails, ...successReverseEmails]) && (
            <Popup
              trigger={
                <Icon
                  style={{ marginLeft: 10 }}
                  name='mail'
                  link
                  color={_.isEmpty(successReverseEmails) ? 'orange' : 'green'}
                />
              }
              content={
                <div>
                  {_.map(successReverseEmails, (email) => (
                    <div key={email} style={{ color: 'lime' }}>
                      {email}
                    </div>
                  ))}
                  {_.map(failedReverseEmails, (email) => (
                    <div key={email} style={{ color: 'orange' }}>
                      {email}
                    </div>
                  ))}
                </div>
              }
            />
          )}
        </h2>
        <List selection>
          {_.map(
            _.compact(displayedEmails),
            (emailHint, index) =>
              emailHint.email && (
                <List.Item
                  key={index}
                  active={uploadEmail === emailHint.email}
                  onClick={() => this.onClickEmail(emailHint.email)}
                >
                  <div>
                    <Icon name={uploadEmail === emailHint.email ? 'check square' : 'mail outline'} />
                    {emailHint.bouncing ? (
                      <Popup trigger={<span style={{ color: 'red' }}>{emailHint.email}</span>} content={'Bouncing'} />
                    ) : (
                      <span>{emailHint.email}</span>
                    )}
                    <Copy text={emailHint.email} />
                    <span> (</span>
                    {emailHint &&
                      emailHint.sources &&
                      _.map(emailHint.sources, (source, sourceIndex) => (
                        <span key={'source' + sourceIndex}>
                          {
                            <span>
                              {{
                                hiresweetEmails: 'hiresweet',
                                reverseLinkedinEmails: 'reverse-linkedin',
                                failedReverseLinkedinEmails: 'failed-reverse',
                                linkedinSummaryEmails: 'summary',
                                githubEmails: 'github',
                                githubPushEmails: 'push',
                                githubCommitEmails: 'commit',
                                outSourcedEmails: 'outsourced',
                                websiteEmails: 'website',
                              }[source] || source}
                              {sourceIndex + 1 < emailHint.sources.length ? <span>; </span> : ''}
                            </span>
                          }
                        </span>
                      ))}
                    <span>)</span>
                    <i>#send: {emailHint.nbSends || 0}, </i>
                    <i {...emailHint.nbAnswers > 0 && { style: { color: 'green' } }}>
                      {' '}
                      #answer: {emailHint.nbAnswers || 0}
                      {emailHint.nbAnswers > 0 && <Icon name='check circle' color='green' />}
                    </i>
                    {uploadEmail !== emailHint.email && (
                      <span>
                        <Icon
                          name='remove'
                          color='red'
                          link
                          onClick={(e) => {
                            this.onRemoveEmail(emailHint.email);
                            e.stopPropagation();
                          }}
                        />
                      </span>
                    )}
                  </div>
                </List.Item>
              ),
          )}
          {(displayedEmails.length !== emailHints.length || this.state.displayAll) && (
            <List.Item style={{ marginTop: '5px' }}>
              <Button onClick={this.onTriggerExpandOrCollapse} size='tiny'>
                {this.state.displayAll ? 'Show less' : 'Show more'}
              </Button>
            </List.Item>
          )}
          <List.Item style={{ marginTop: '5px' }}>
            <Input
              size='small'
              placeholder='Custom email'
              fluid
              onChange={(e) => this.onClickEmail(e.target.value.toLowerCase())}
            />
          </List.Item>
        </List>
      </Segment>
    );
  }
}

export default Emails;
