import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Grid,
  Table,
  Form,
  Popup,
  Icon,
  Input as InputS,
  Button,
  Modal,
  Confirm,
} from 'semantic-ui-react';
import { SweetForm, Select, Input } from 'sweetform';
import { permissionsDependencies, allPermissions } from '../common';
import baseUrl from '../baseUrl';

// Actions

const SET_USERS = 'SET_USERS';
const setUsers = (users) => ({ type: SET_USERS, users });

// Reducers

const users = (state = [], action) => {
  switch (action.type) {
    case SET_USERS:
      return action.users;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  users,
});

// Store
const store = createStore(rootReducer);

// Components

// const KPIs = ({ users }) => {
//   return (
//     <div>stats</div>
//   )
// }

class UserRow extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '', error: null };
    this.handleChange = this.handleChange.bind(this);
    this.handleSavePassword = this.handleSavePassword.bind(this);
  }
  handleSavePassword() {
    const { password } = this.state;
    const { user, onChangePassword } = this.props;
    const { username } = user;
    if (this.state.password.length > 6) {
      onChangePassword({ username, password });
      this.setState({ password: '', error: null });
    } else {
      this.setState({ error: 'password must be at least 6 characters long' });
    }
  }
  handleChange(e, { value }) {
    const newState = {
      password: value,
      ...(this.state.error && { error: null }),
    };
    this.setState(newState);
  }
  render() {
    const { user, onRemoveUser, onEditUser, onLoadUsers } = this.props;
    const { username, role, permissions } = user;
    const permissionsString =
      (permissions && _.isArray(permissions) && permissions.join(', ')) || '';
    return (
      <Table.Row textAlign="center">
        <Table.Cell>{username}</Table.Cell>
        <Table.Cell>{role}</Table.Cell>
        <Table.Cell>
          {permissionsString.slice(0, 50)}
          {permissionsString.length > 50 && (
            <Popup
              wide="very"
              trigger={<Icon name="add" size="small" />}
              content={permissionsString}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          <InputS
            type='password'
            placeholder='New password'
            action={{ content: 'Save', onClick: this.handleSavePassword }}
            value={this.state.password}
            onChange={this.handleChange}
          />
          {this.state.error && (
            <div style={{ color: 'red' }}>{this.state.error}</div>
          )}
        </Table.Cell>
        <Table.Cell>
          <UserModal
            users={users}
            onEditUser={onEditUser}
            onLoadUsers={onLoadUsers}
            user={user}
            trigger={
              <Icon name="pencil" size="large" style={{ cursor: 'pointer' }} />
            }
          />
          <RemoveUserConfirm onRemoveUser={onRemoveUser} username={username} />
        </Table.Cell>
      </Table.Row>
    );
  }
}

class RemoveUserConfirm extends Component {
  state = { open: false };

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  removeUser = async () => {
    const { onRemoveUser, username } = this.props;
    await onRemoveUser({ username });
    this.setState({ open: false });
  };

  render() {
    const { username } = this.props;
    return (
      <span>
        <Icon
          name="delete"
          color="red"
          size="large"
          style={{ cursor: 'pointer' }}
          onClick={this.open}
        />
        <Confirm
          open={this.state.open}
          content={`Êtes-vous sûr de vouloir supprimer le user ${username} ?`}
          onCancel={this.close}
          onConfirm={this.removeUser}
        />
      </span>
    );
  }
}

const UsersTable = ({
  users,
  onChangePassword,
  onRemoveUser,
  onEditUser,
  onLoadUsers,
}) => {
  return (
    <Table celled compact="very" sortable>
      <Table.Header>
        <Table.Row textAlign="center">
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Permissions</Table.HeaderCell>
          <Table.HeaderCell>Change Password</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(users, (user) => (
          <UserRow
            key={user.username}
            user={user}
            onChangePassword={onChangePassword}
            onRemoveUser={onRemoveUser}
            onEditUser={onEditUser}
            onLoadUsers={onLoadUsers}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const Permissions = () => (
  <pre>{JSON.stringify(permissionsDependencies, null, '   ')}</pre>
);

class UserModal extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      open: false,
      error: null,
      user: user
        ? {
            ...user,
            ...(user.permissions && {
              permissions: user.permissions.join(';'),
            }),
          }
        : {},
      update: user ? true : false,
    };
    this.show.bind(this);
    this.close.bind(this);
    this.handleChangeUser.bind(this);
    this.onSaveUser.bind(this);
  }

  show = () => () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  handleChangeUser = (user) => this.setState({ user, error: null });
  onSaveUser = async () => {
    const { user, update } = this.state;
    const { onAddUser, onEditUser, users, onLoadUsers } = this.props;
    if (!user || !user.username) {
      this.setState({ error: 'You should at least give a username' });
    } else if (
      _.find(
        _.pluck(users, 'username'),
        (username) => username === user.username,
      )
    ) {
      this.setState({ error: 'Username already taken' });
    } else {
      const newUser = {
        ...user,
        ...(update && { username: this.props.user.username }),
        ...(_.isString(user.permissions) && {
          permissions: user.permissions.split(';'),
        }),
      };
      if (update && onEditUser) {
        await onEditUser(newUser);
      } else if (!update && onAddUser) {
        await onAddUser(newUser);
      }
      await onLoadUsers();
      this.close();
    }
  };

  render() {
    const { open, error, update } = this.state;
    const { trigger } = this.props;
    const roleOptions = [
      { label: 'ops', value: 'ops' },
      { label: 'rd', value: 'rd' },
    ];
    const permissionsOptions = _.map(allPermissions, (value, key) => ({
      label: value,
      value: value,
    }));
    return (
      <span>
        <span onClick={this.show()}>
          {trigger ? trigger : <Button icon="add" color="green" circular />}
        </span>
        <Modal open={open} onClose={this.close}>
          <Modal.Header>Create a user</Modal.Header>
          <Modal.Content>
            <Grid columns={2}>
              <Grid.Column>
                <SweetForm
                  onChange={this.handleChangeUser}
                  initialValues={this.state.user}
                >
                  <Form>
                    <Form.Field>
                      <label>Username</label>
                      <Input
                        field="username"
                        placeholder="Username"
                        {...update && { disabled: true }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Role</label>
                      <Select
                        field="role"
                        placeholder="Role"
                        options={roleOptions}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Permissions</label>
                      <Select
                        field="permissions"
                        placeholder="Permissions"
                        options={permissionsOptions}
                        multi={true}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Password</label>
                      <Input field='password' type="password" placeholder='Password' />
                    </Form.Field>
                  </Form>
                </SweetForm>
                {error && <span style={{ color: 'red' }}>{error}</span>}
              </Grid.Column>
              <Permissions />
              <Grid.Column />
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Save"
              onClick={this.onSaveUser}
            />
          </Modal.Actions>
        </Modal>
      </span>
    );
  }
}

class Users extends Component {
  componentDidMount() {
    this.props.onLoadUsers();
  }

  render() {
    const {
      users,
      onChangePassword,
      onRemoveUser,
      onAddUser,
      onLoadUsers,
      onEditUser,
    } = this.props;
    return (
      <div>
        {/* <KPIs users={users} /> */}
        <UserModal
          users={users}
          onAddUser={onAddUser}
          onLoadUsers={onLoadUsers}
        />
        <UsersTable
          users={users}
          onChangePassword={onChangePassword}
          onRemoveUser={onRemoveUser}
          onEditUser={onEditUser}
          onLoadUsers={onLoadUsers}
        />
      </div>
    );
  }
}

// Containers
const mapSUsers = (state) => ({
  users: state.users,
});

const loadUsers = async (dispatch) => {
  const { data } = await axios.get(`${baseUrl}/admin/users`);
  const sortedData = _.sortBy(data, (user) => user.username);
  dispatch(setUsers(sortedData));
};

const mapDUsers = (dispatch) => ({
  onLoadUsers: async () => {
    await loadUsers(dispatch);
  },
  onAddUser: async ({ username, role, password, permissions }) => {
    try {
      await axios.post(`${baseUrl}/admin/users`, {
        username,
        role,
        password,
        permissions,
      });
      await loadUsers(dispatch);
    } catch (e) {
      alert(e.message);
    }
  },
  onRemoveUser: async ({ username }) => {
    try {
      await axios.delete(`${baseUrl}/admin/users/${username}`);
      await loadUsers(dispatch);
    } catch (e) {
      alert(e.message);
    }
  },
  onEditUser: async (user) => {
    try {
      await axios.put(`${baseUrl}/admin/users/${user.username}`, user);
    } catch (e) {
      alert(e.message);
    }
  },
  onChangePassword: async ({ username, password }) => {
    try {
      await axios.put(`${baseUrl}/admin/users/${username}`, { password });
    } catch (e) {
      alert(e.message);
    }
  },
});

const UsersContainer = connect(
  mapSUsers,
  mapDUsers,
)(Users);

export default ({ match, history }) => (
  <Provider store={store}>
    <UsersContainer match={match} history={history} />
  </Provider>
);
