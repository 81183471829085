import React from 'react';
import { Form } from 'semantic-ui-react';

export default class SearchWithOptions extends React.Component {
  state = { search: '', options: [] };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  componentDidMount = async () => {
    const options = await this.props.loadOptions();
    this.setState({ options });
  };

  render() {
    return (
      <div>
        <Form onSubmit={() => this.props.handleSubmit(this.state.search)}>
          <Form.Dropdown
            value={this.state.search}
            name="search"
            allowAdditions
            onAddItem={this.handleAddition}
            search
            selection
            options={this.state.options}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
          />
        </Form>
      </div>
    );
  }
}
