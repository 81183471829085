import _ from 'underscore';
import React, { Component } from 'react';
import { Container, Button, Form, Modal } from 'semantic-ui-react';
import { SweetForm, Input } from 'sweetform';

export default class UpsertRecipientModal extends Component {
  state = {
    currentValue: this.props.initialValues || {},
  };
  handleEdit = (newValue) => {
    this.setState({ currentValue: newValue });
  };
  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { currentValue } = this.state;
    if (
      !currentValue ||
      _.isEmpty(currentValue.firstName) ||
      _.isEmpty(currentValue.email)
    ) {
      return alert('fields firstName and email are required !');
    }
    onSubmit(currentValue);
  };
  render() {
    const { headerText, onClose } = this.props;

    const { currentValue } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>{headerText}</Modal.Header>
        <Modal.Content scrolling>
          <Container>
            <SweetForm initialValues={currentValue} onChange={this.handleEdit}>
              <Form>
                <Form.Field>
                  <label>Email</label>
                  <Input field="email" placeholder="alpha@beta.com" />
                </Form.Field>
                <Form.Field>
                  <label>Firstname</label>
                  <Input field="firstName" placeholder="Benoît" />
                </Form.Field>
                <Form.Field>
                  <label>Fullname</label>
                  <Input field="alias" placeholder="Benoît Roche" />
                </Form.Field>
                <Form.Field>
                  <label>Role</label>
                  <Input field="role" placeholder="CTO" />
                </Form.Field>
                <Form.Field>
                  <label>PhotoLink</label>
                  <Input field="photoLink" />
                </Form.Field>
              </Form>
            </SweetForm>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={this.handleSubmit}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
