import React from 'react';
import _ from 'underscore';
import { Segment } from 'semantic-ui-react';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { PlatformDataPane } from '../Offers/WorkPipeInputComponents/Analysis';

export const PlatformStats = ({ platformStats }) => {
  const sendData = _.compact(
    _.flatten(
      _.map(platformStats.results, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date }) =>
          type === 'send' ? { date, title: jobOfferId } : null,
        ),
      ),
    ),
  );
  const skipData = _.compact(
    _.flatten(
      _.map(platformStats.results, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date, reason }) =>
          type === 'skipProfile' ? { date, title: jobOfferId, reason } : null,
        ),
      ),
    ),
  );
  const answerData = _.compact(
    _.flatten(
      _.map(platformStats.results, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date, label, labels }) => {
          return type === 'answer-detected'
            ? {
                date,
                title: jobOfferId,
                label,
                ...(labels &&
                  labels.length > 0 && { reason: labels.join('; ') }),
              }
            : null;
        }),
      ),
    ),
  );

  const uploadData = _.compact(
    _.flatten(
      _.map(
        platformStats.results,
        ({ actions, jobOfferId, creationTimestamp }) => ({
          date: creationTimestamp,
          title: jobOfferId,
          color: _.find(sendData, (s) => s.title === jobOfferId)
            ? 'green'
            : _.find(skipData, (s) => s.title === jobOfferId)
            ? 'red'
            : null,
        }),
      ),
    ),
  );

  const sendRate = Math.floor(
    (sendData.length * 100) / (sendData.length + skipData.length),
  );
  const answerRate = Math.floor((answerData.length * 100) / sendData.length);

  return (
    <Segment color="green">
      <Tabs>
        <TabList>
          <Tab>
            {`Uploads (${uploadData.length} : ${
              sendRate ? sendRate : '0'
            }% send)`}
          </Tab>
          <Tab>
            {`Answers (${answerData.length} : ${
              answerRate ? answerRate : '0'
            }%)`}
          </Tab>
        </TabList>
        <TabPanel>
          <PlatformDataPane data={uploadData} />
        </TabPanel>
        <TabPanel>
          <PlatformDataPane data={answerData} />
        </TabPanel>
      </Tabs>
    </Segment>
  );
};
