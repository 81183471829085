import React from 'react';

import { Label } from 'semantic-ui-react';

/**
 * A linkedin skill that gets highlighted when you click on it.
 * @prop onClick : function to toggle highlight
 * @prop isHighlighted: bool When true, the button is blue, when false, it's grey
 * @prop text : string to display
 * @prop count : number to display next to the text
 */
const SkillsHighligthToggle = ({ onClick, isHighlighted, text, count }) => {
  const className = `skill-tag${isHighlighted ? ' important' : ' normal'}`;
  
  return (
    <Label
      className={className}
      detail={count}
      content={text}
      onClick={onClick}
      style={{marginBottom: '2px', fontSize: 11, fontFamily: 'SourceSansProSemiBold'}}
    />
  );
};

export default SkillsHighligthToggle;
