import React, { Component, Text } from 'react';
import { Button, Card, Dropdown, Form, Grid, Modal, Table } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';
import baseUrl from '../baseUrl.js';

const appBaseUrl = 'https://app.hiresweet.com/client/';

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
function findProp(obj, search) {
  if (search in obj) return obj[search];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const res = findProp(obj[key], search);
      if (res) return res;
    }
  }
}
class ProfileActivityDisplay extends Component {
  constructor(props) {
    super(props);
    this.getProfileTimeline();
    this.getProfileActivitiesFeed();
    this.getProfileSource();
    this.state = { activityFeed: {}, profileTimeline: [] };
  }

  getProfileTimeline = async () => {
    const { selectedProfileId, selectedClient } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;

    const params = {
      clientId,
      projectId,
      profileId: selectedProfileId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileTimeline';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const profileTimeline = result.data || {};
      this.setState({
        profileTimeline,
      });
    }
  };

  getProfileActivitiesFeed = async () => {
    const { selectedProfileId, selectedClient } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const params = {
      clientId,
      projectId,
      profileId: selectedProfileId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileActivitiesFeed';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const activityFeed = result.data || {};
      this.setState({
        activityFeed,
      });
    }
  };

  getProfileSource = async () => {
    const { selectedProfileId, selectedClient } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const params = {
      clientId,
      projectId,
      profileId: selectedProfileId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileSource';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const profileSource = result.data || {};
      this.setState({
        profileSource,
      });
    }
  };

  handleChange(params) {
    this.setState({
      params,
    });
  }

  render() {
    const { selectedProfileId, selectedClient } = this.props;
    const { activityFeed, profileSource, profileTimeline } = this.state || undefined;
    const ATSName = Object.keys(activityFeed)[0];
    const ATSActivityFeed = (activityFeed || {})[ATSName];
    const sortedATSActivityFeed = _.sortBy(ATSActivityFeed || {}, 'creationDate').reverse();
    const sortedProfileTimeline = _.sortBy(profileTimeline || {}, 'date').reverse();
    const profileSourceType = (profileSource || {}).type || 'No Source Found';
    let profileLink = undefined;
    if (selectedProfileId) {
      profileLink = appBaseUrl + selectedClient.id + '/reveal/search?profileId=' + selectedProfileId;
    }
    return (
      <div>
        <h3>Profile Source</h3>
        {profileSource ? ' Source Type : ' + profileSourceType : null}
        <br />
        Link :
        <a href={profileLink} target='_blank' rel='noopener noreferrer'>
          {profileLink}
        </a>
        <h3> Profile Activity Feed</h3>
        {!_.isEmpty(activityFeed) ? (
          _.map(sortedATSActivityFeed, (item, index) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const creationDate = new Date(item.creationDate);
            const optWeekday = { weekday: 'long' };
            const weekday = toTitleCase(creationDate.toLocaleDateString('fr-FR', optWeekday));
            const actionDate = weekday + ', ' + creationDate.toLocaleDateString('fr-FR', options);
            const user = item.user;
            const username = user ? (item.user.firstname || '') + ' ' + (item.user.lastname || '') : 'No user provided';
            return (
              <Card
                style={{
                  width: '100%',
                }}
              >
                <Card.Content>
                  <Card.Header>
                    {' '}
                    {ATSName} : {item.type} by {username} - {actionDate}{' '}
                  </Card.Header>
                  <Card.Description>{item.body}</Card.Description>
                </Card.Content>
              </Card>
            );
          })
        ) : (
          <div> No activity Feed</div>
        )}
        <h3> Profile Timeline</h3>
        {!_.isEmpty(profileTimeline) ? (
          _.map(sortedProfileTimeline, (item, index) => {
            var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const itemDate = new Date(item.date);
            const optWeekday = { weekday: 'long' };
            const weekday = toTitleCase(itemDate.toLocaleDateString('fr-FR', optWeekday));
            const actionDate = weekday + ', ' + itemDate.toLocaleDateString('fr-FR', options);
            var detailedInfo = '';
            const authorFirstname = findProp(item, 'firstname');
            const authorLastname = findProp(item, 'lastname');
            const authorName = authorFirstname ? authorFirstname + ' ' + authorLastname : (item.author || {}).id;
            var taskType = undefined;
            var reason = undefined;
            var interruptionReason = undefined;
            var missionId = item.missionId || undefined;
            var sequenceId = item.sequenceId || undefined;
            var subType = undefined;
            var actionInputType = undefined;
            var trigger = undefined;
            var comment = undefined;
            var messageSubject = undefined;
            var messageBody = undefined;
            var labels = undefined;
            switch (item.type) {
              case 'added-to-mission':
                detailedInfo =
                  detailedInfo + 'Added from Recommendation : ' + (item.isAddedFromRecommendation || 'false');
                break;
              case 'removed-from-mission':
                labels = item.answerLabels || undefined;
                break;
              case 'mission-stage-updated':
                detailedInfo =
                  detailedInfo +
                  'Moved from ' +
                  (item.oldStage || 'no old stage found') +
                  ' to ' +
                  (item.newStage || 'no new stage found');
                break;
              case 'task-completed':
                taskType = 'Task type : ' + ((item.task || {}).type || 'no type found');
                if (item.task) {
                  if (item.task.type) {
                    taskType = item.task.type;
                    if (taskType === 'linkedin-send-message' || taskType === 'linkedin-send-request') {
                      detailedInfo = detailedInfo + 'Message : ' + (item.task.message || {}).body || '';
                    }
                  }
                }
                if (item.taskType) {
                  taskType = item.taskType;
                }
                break;
              case 'skipped-from-mission-recommendations':
                reason = item.reason || 'no reason filled';
                break;
              case 'unenrolled-from-sequence':
                interruptionReason = item.interruptionReason || 'no reason filled';
                break;
              case 'logged-activity':
                subType = item.subtype || 'no subtype found';
                comment = (item.comment || {}).text || '';
                labels = item.labels || undefined;
                break;
              case 'comment':
                comment = (item.comment || {}).text || '';
                break;
              case 'task-snoozed':
                actionInputType = (item.actionInput || {}).type || 'no type found';
                var triggerType = ((item.actionInput || {}).trigger || {}).type || 'no type found';
                var triggerDate = ((item.actionInput || {}).trigger || {}).date || 'no date found';
                trigger = triggerType + ' ' + triggerDate;
                messageSubject = ((item.actionInput || {}).message || {}).subject || 'no subject found';
                messageBody = ((item.actionInput || {}).message || {}).body || 'no body found';
                break;
            }

            return (
              <Card
                style={{
                  width: '100%',
                }}
              >
                <Card.Content>
                  <Card.Header>
                    {' '}
                    {item.type} by {authorName} - {actionDate}
                  </Card.Header>
                  <Card.Description>
                    {missionId ? (
                      <div>
                        <b>
                          Mission Id : {missionId} <br />
                        </b>
                      </div>
                    ) : null}
                    {sequenceId ? (
                      <div>
                        Sequence Id : {sequenceId} <br />
                      </div>
                    ) : null}
                    {subType ? (
                      <div>
                        Subtype : {subType} <br />
                      </div>
                    ) : null}
                    {actionInputType ? (
                      <div>
                        actionInputType : {actionInputType} <br />
                      </div>
                    ) : null}
                    {trigger ? (
                      <div>
                        trigger : {trigger} <br />
                      </div>
                    ) : null}
                    {messageSubject ? (
                      <div>
                        Message Subject : {messageSubject} <br />
                      </div>
                    ) : null}
                    {messageBody ? (
                      <div>
                        Message : <br />
                      </div>
                    ) : null}
                    {messageBody ? <div className='content' dangerouslySetInnerHTML={{ __html: messageBody }} /> : null}
                    {comment ? <div>Message : </div> : null}
                    {comment ? <div className='content' dangerouslySetInnerHTML={{ __html: comment }} /> : null}
                    {comment ? <br /> : null}
                    {labels ? (
                      <div>
                        Labels : {labels.join(', ')} <br />
                      </div>
                    ) : null}
                    {taskType ? (
                      <div>
                        Task Type : {taskType} <br />
                      </div>
                    ) : null}
                    {reason ? (
                      <div>
                        Reason : {reason} <br />
                      </div>
                    ) : null}
                    {interruptionReason ? (
                      <div>
                        Reason : {interruptionReason} <br />
                      </div>
                    ) : null}
                    {detailedInfo}
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })
        ) : (
          <div> No activity Feed</div>
        )}
      </div>
    );
  }
}

export default ProfileActivityDisplay;
