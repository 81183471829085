import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Button, Table, Grid, Tab, Segment, Label, List, Header } from 'semantic-ui-react';
import { Input, Select, SweetForm, Checkbox, enhance } from 'sweetform';
import baseUrl from './baseUrl.js';
import { Textarea } from './common';
import ProfileView from './SweetComponents/Profile/View';
import OfferV5 from './components/OfferV5';
import EnrichedText from './SweetWork/components/EnrichedText';
import { EnrichedText as EnrichedText_ } from './SweetWork/profileData';

const INPUT_TYPES = [
  {
    name: 'Green House API',
    id: 'apiDataGreenhouse',
    jsonType: 'apiDataGreenhouse',
    sourceType: 'api',
    editor: 'url',
  },
  {
    name: 'Title Content',
    id: 'titleContent',
    jsonType: 'titleContent',
    sourceType: 'data',
    editor: 'titleContent',
  },
  {
    name: 'XG1',
    id: 'xg1',
    jsonType: 'xg1',
    sourceType: 'data',
    editor: 'xg1',
  },
  {
    name: 'Source Data Greenhouse',
    id: 'sourceDataGreenhouse',
    jsonType: 'sourceDataGreenhouse',
    sourceType: 'data',
    editor: 'json',
  },
  {
    name: 'Source Data WTTJ',
    id: 'sourceDataWelcometothejungle',
    jsonType: 'sourceDataWelcometothejungle',
    sourceType: 'data',
    editor: 'json',
  },
  {
    name: 'Green House API JSON',
    id: 'apiDataGreenhouseJson',
    jsonType: 'apiDataGreenhouse',
    sourceType: 'data',
    editor: 'json',
  },
];

const SAMPLE_TYPES = [
  {
    name: 'Green House Company',
    id: 'greenhouseCompany',
  },
  {
    name: 'Scraped Jobs',
    id: 'scrapedJobs',
  },
];

const ID_TO_INPUT_TYPE = _.indexBy(INPUT_TYPES, ({ id }) => id);

const SelectJobInput = enhance((props) => {
  const { value } = props;
  const inputId = value ? value.get('inputId') : '';
  const inputType = ID_TO_INPUT_TYPE[inputId];
  return (
    <div>
      <Select options={_.map(INPUT_TYPES, ({ name, id }) => ({ label: name, value: id }))} field='inputId' />
      {inputType.editor == 'json' ? <Textarea field='jsonStr' placeholder='{}' rows='10' cols='60' /> : null}
      {inputType.editor == 'url' ? <Input field='url' placeholder='https://api.greenhouse.com/....' /> : null}
      {inputType.editor == 'titleContent' ? (
        <div>
          <Input field='title' placeholder='Développeur Fullstack JS' />
          <Textarea
            field='content'
            placeholder='Passionné par les nouvelles technologies, vous maîtrisez les technos JS et notamment NodeJS/React/Vue Js.'
            rows='10'
            cols='60'
          />
        </div>
      ) : null}
      {inputType.editor == 'xg1' ? (
        <div>
          <Input field='title' placeholder='Data Scientist' />
          <Input field='location' placeholder='Paris' />
          <Input field='company' placeholder='HireSweet' />
          <Textarea
            field='description'
            placeholder='Passionné par les nouvelles technologies, vous maîtrisez les technos JS et notamment NodeJS/React/Vue Js.'
            rows='10'
            cols='60'
          />{' '}
        </div>
      ) : null}
    </div>
  );
});

const SelectSampleInput = enhance((props) => {
  const { value } = props;
  const sampleSource = value ? value.get('sampleSource') : '';
  return (
    <div>
      <Select options={_.map(SAMPLE_TYPES, ({ name, id }) => ({ label: name, value: id }))} field='sampleSource' />
      {sampleSource == 'greenhouseCompany' ? <Input field='companyId' placeholder='zapier...' /> : null}
      {sampleSource == 'scrapedJobs' ? (
        <div>
          <Input field='title' placeholder='title regex...' />
          <Input field='id' placeholder='id regex...' />
        </div>
      ) : null}
    </div>
  );
});

const formatQuery = (formValue) => {
  if (_.isEmpty(formValue)) {
    throw Error('Empty query');
  }
  const { inputId, title, content, jsonStr, url, description, company, location } = formValue;
  const { jsonType, sourceType, editor } = ID_TO_INPUT_TYPE[inputId];
  let input = null;
  if (sourceType == 'data') {
    if (editor == 'titleContent') {
      input = { title, content };
    } else if (editor == 'xg1') {
      input = { title, description, company, location };
    } else {
      input = JSON.parse(jsonStr);
    }
  }
  if (sourceType == 'api') {
    input = { url };
  }
  return {
    jsonType,
    sourceType,
    input,
    withDetails: true,
  };
};

const EnrichedTextBloc = ({ type, title, text, sectionType }) => {
  if (type == 'header-1') {
    return (
      <Header as='h2'>
        {sectionType ? <Label color='green'>{sectionType} </Label> : null}
        <EnrichedText text={text.text} enrichments={EnrichedText_(text, () => null).getEnrichments()} />
      </Header>
    );
  }
  if (type == 'header-2') {
    return (
      <Header as='h3'>
        {sectionType ? <Label color='green'>{sectionType} </Label> : null}

        <EnrichedText text={text.text} enrichments={EnrichedText_(text, () => null).getEnrichments()} />
      </Header>
    );
  }
  return (
    <Segment>
      {sectionType ? <Label color='green'>{sectionType} </Label> : null}
      {title && (<Header as='h3'><EnrichedText text={title.text} enrichments={EnrichedText_(title, () => null).getEnrichments()} /></Header>)}
      <EnrichedText text={text.text} enrichments={EnrichedText_(text, () => null).getEnrichments()} />
    </Segment>
  );
};

export const EnrichedJobPosting = ({ enrichedJobPosting }) => {
  const { title, textBlocs, extraData } = enrichedJobPosting;
  return (
    <div>
      <Header as='h1'>
        <EnrichedText text={(title || {}).text || ''} enrichments={EnrichedText_(title, () => null).getEnrichments()} />
      </Header>
      {_.map(textBlocs, ({ type, text, title, sectionType }) => (
        <EnrichedTextBloc type={type} title={title} text={text} sectionType={sectionType} />
      ))}
      {extraData && extraData.length >= 1 ? (
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Extra Field</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(extraData, (value, key) => (
              <Table.Row key={key}>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell>{JSON.stringify(value)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : null}
    </div>
  );
};
class JobParser extends React.Component {
  state = {};

  handleChangeQueryForm = ({ value }) => {
    this.setState({ queryValue: value });
  };

  handleChangeSampleForm = ({ value }) => {
    this.setState({ sampleValue: value });
  };

  handleSubmitQuery = async () => {
    const { queryValue } = this.state;
    try {
      const query = formatQuery(queryValue);
      console.log('query');
      console.log(query);
      const result = (await axios.post(`${baseUrl}/jobParser/parseData`, query)).data;
      if (result.error) {
        throw Error(result.error);
      }
      let { offerV5, enrichedJobPosting, rawJson } = result;
      console.log(result);
      if (_.isString(enrichedJobPosting)) {
        enrichedJobPosting = JSON.parse(enrichedJobPosting);
      }
      this.setState({ offerV5, enrichedJobPosting, rawJson });
    } catch (e) {
      alert(JSON.stringify(e.message));
    }
  };

  handleSubmitSample = async () => {
    const { sampleValue } = this.state;
    try {
      console.log('sampleValue', sampleValue);
      const result = (await axios.post(`${baseUrl}/jobParser/sampleAndParseData`, sampleValue)).data;
      if (result.error) {
        throw Error(result.error);
      }
      let { offerV5, enrichedJobPosting, rawJson } = result;
      console.log(result);
      if (_.isString(enrichedJobPosting)) {
        enrichedJobPosting = JSON.parse(enrichedJobPosting);
      }
      this.setState({ offerV5, enrichedJobPosting, rawJson });
    } catch (e) {
      alert(JSON.stringify(e.message));
    }
  };

  render() {
    const { offerV5, enrichedJobPosting, rawJson } = this.state;
    console.log('enrichedJobPosting', enrichedJobPosting);
    const leftPanes = [
      ...(enrichedJobPosting
        ? [
            {
              menuItem: 'Job Posting',
              render: () => (
                <Tab.Pane>
                  <div style={{ overflowX: 'scroll', width: '100%', overflowY: 'scroll', height: '700px' }}>
                    <EnrichedJobPosting enrichedJobPosting={enrichedJobPosting} />
                  </div>
                </Tab.Pane>
              ),
            },
          ]
        : []),
      ...(rawJson
        ? [
            {
              menuItem: 'Raw JSON',
              render: () => (
                <Tab.Pane>
                  <div style={{ overflowX: 'scroll', width: '100%' }}>
                    <pre>{JSON.stringify(rawJson, null, 4)} </pre>
                  </div>
                </Tab.Pane>
              ),
            },
          ]
        : []),
    ];
    const queryPanes = [
      {
        menuItem: 'Job',
        render: () => (
          <Tab.Pane>
            <SweetForm
              key={'query'}
              onChange={this.handleChangeQueryForm}
              initialValues={{ value: { inputId: 'titleContent' } }}
            >
              <SelectJobInput field='value' />
            </SweetForm>
            <Button positive onClick={() => this.handleSubmitQuery()}>
              Parse
            </Button>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Sample',
        render: () => (
          <Tab.Pane>
            <SweetForm key={'sample'} onChange={this.handleChangeSampleForm}>
              <SelectSampleInput field='value' />
            </SweetForm>
            <Button positive onClick={() => this.handleSubmitSample()}>
              Sample
            </Button>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Grid columns={3}>
        <Grid.Column width={4}>
          <Tab panes={queryPanes} />
        </Grid.Column>
        <Grid.Column width={6}>
          <Tab panes={leftPanes} />
        </Grid.Column>
        <Grid.Column width={6}>{offerV5 && <OfferV5 key={JSON.stringify(offerV5)} offer={offerV5} />}</Grid.Column>
      </Grid>
    );
  }
}

export default JobParser;
