import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Grid, Table, Button,
  Dropdown, Select,
  Popup, Icon, Image
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

const INFINITY = 1000 * 1000 * 1000;

const capitalizeFirstLetter = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

const Filters = ({
  users,
  selectedOps, onChangeOps,
  selectedSales, onChangeSales,
  selectedCompletionMode, onChangeCompletionMode
}) => {

  const userOptions = [
    { value: 'none', text: 'All' },
    ..._.map(users, ({ id, name }) => ({ value: id, text: capitalizeFirstLetter(name || '') }))
  ];

  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        <b>Completion: </b>
        <Select
          options={[
            { value: 'show-review-required', text: 'Show review required' },
            { value: 'show-missing-criteria', text: 'Show missing criteria' },
            { value: 'show-all', text: 'Show all' },
          ]}
          value={selectedCompletionMode || 'show-review-required'}
          onChange={(e, { value }) => {
            onChangeCompletionMode({ completionMode: value })
          }}
        />
      </div>
      <div style={{ display: 'inline-block', marginLeft: 20 }}>
        <b>Owner: </b>
        <Select
          search
          options={userOptions}
          value={selectedOps || 'none'}
          onChange={(e, { value }) => {
            onChangeOps({ user: value === 'none' ? null : value })
          }}
        />
      </div>
      <div style={{ display: 'inline-block', marginLeft: 20 }}>
        <b>Sales: </b>
        <Select
          search
          options={userOptions}
          value={selectedSales || 'none'}
          onChange={(e, { value }) => {
            onChangeSales({ user: value === 'none' ? null : value })
          }}
        />
      </div>
    </div>
  );
}


const CriteriaBoardTable = ({ targetJobs, markJobReviewed, markJobHasMissingCriteria }) => {

  const markButton = (job) => {
    const { hasMissingCriteria } = job;
    return (
      <Button.Group style={{ backgroundColor: 'whitesmoke !important' }}>
        {!hasMissingCriteria && (
          <Button onClick={() => markJobReviewed({ jobId: job.id })}>
            Reviewed
          </Button>
        )}
        <Button onClick={() => markJobHasMissingCriteria({ jobId: job.id, hasMissingCriteria: !hasMissingCriteria })}>
          {hasMissingCriteria ? 'Completed' : 'Missing'}
        </Button>
      </Button.Group>
    );
  }

  const getBackgroundColor = ({ job }) => (
    job.hasMissingCriteria ? (
      '#fdebd0'
    ) : job.toReview == 0 ? (
      '#ddffdd'
    ) : '#ddf9ff'
  )

  const jobLink = ({ job }) => (
    <Grid style={{ margin: 0 }}>
      <Grid.Column width={11} style={{ padding: '5px' }}>
        <a href={`/offers/${job.id}`} target='_blank' rel='noopener noreferrer'>
          {job.title && job.title.length > 20 ? (
            <div>{job.title.slice(0, 20)}</div>
          ) : (
            <div>{job.title}</div>
          )}
        </a>
      </Grid.Column>
      <Grid.Column width={1} style={{ padding: '5px' }}>
        {job.title && job.title.length > 20 && (
          <Popup wide='very' trigger={<Icon name='add' size='small' />} content={job.title} />
        )}
      </Grid.Column>
      <Grid.Column width={2} style={{ padding: '5px' }} textAlign='right'>
        {job.sweetsheetId && (
          <a href={`/flowboard/sweetsheets/id/${job.sweetsheetId}`} target='_blank' rel='noopener noreferrer'>
            <Image src='/images/google-sheet.png' style={{ width: '15px', height: '15px' }} />
          </a>
        )}
      </Grid.Column>
      <Grid.Column width={2} style={{ padding: '5px' }}>
        {job.platformId && (
          <a
            href={`https://app.hiresweet.com/client/${job.companyId}/jobs/${job.platformId}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image src='/images/sweetapp-logo.png' style={{ width: '15px', height: '15px' }} />
          </a>
        )}
      </Grid.Column>
    </Grid>
  );

  const jobSkipReasons = ({ job }) => {
    if (_.isEmpty(job.skipReasonsAfterReview)) {
      return ""
    }
    const sortedReasonCount = _.map(_.sortBy(_.pairs(job.skipReasonsAfterReview), (i) => -i[1]), (i) => `${i[0]} (${i[1]})`)
    return <div>
      {sortedReasonCount[0]}
      <Popup wide='very' trigger={<Icon name='add' size='small' />} content={
        <div>
          {_.map(sortedReasonCount, (e) => (<div>{e}<br /></div>))}
        </div>}
      />
    </div>
  }

  const jobRatings = ({ job }) => (
    <div>{_.pluck(job.ratingsAfterReview, 'rating').join(' / ')}</div>
  )

  const weeksFromNow = (date) => {
    if (!date) {
      return ''
    }
    return moment().startOf('isoWeek').diff(moment(date).startOf('isoWeek'), 'days') / 7
  }


  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Mark</Table.HeaderCell>
          <Table.HeaderCell>Client</Table.HeaderCell>
          <Table.HeaderCell>Job</Table.HeaderCell>
          <Table.HeaderCell>Sales</Table.HeaderCell>
          <Table.HeaderCell>Ops</Table.HeaderCell>
          <Table.HeaderCell>Creation Weeks</Table.HeaderCell>
          <Table.HeaderCell>Last Action Weeks</Table.HeaderCell>
          <Table.HeaderCell>Last Review</Table.HeaderCell>
          <Table.HeaderCell>Last client edit</Table.HeaderCell>
          <Table.HeaderCell>Skip reasons</Table.HeaderCell>
          <Table.HeaderCell>Ratings</Table.HeaderCell>
          <Table.HeaderCell>Reason</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(targetJobs, (job) => (
          <Table.Row
            key={job.id}
            style={{ backgroundColor: getBackgroundColor({ job }) }}
          >
            <Table.Cell>{markButton(job)}</Table.Cell>
            <Table.Cell>{job.companyId}</Table.Cell>
            <Table.Cell>{jobLink({ job })}</Table.Cell>
            <Table.Cell>{capitalizeFirstLetter(job.salesPerson || '')}</Table.Cell>
            <Table.Cell>{capitalizeFirstLetter(job.owner || '')}</Table.Cell>
            <Table.Cell>{weeksFromNow(job.creation)}</Table.Cell>
            <Table.Cell>{weeksFromNow(job.lastActivity)}</Table.Cell>
            <Table.Cell>{job.lastReview && moment(job.lastReview).format('YYYY-MM-DD')}</Table.Cell>
            <Table.Cell>{job.lastEditCriteria && moment(job.lastEditCriteria).format('YYYY-MM-DD')}</Table.Cell>
            <Table.Cell>{jobSkipReasons({ job })}</Table.Cell>
            <Table.Cell>{jobRatings({ job })}</Table.Cell>
            <Table.Cell>{job.toReviewReason}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

}

const getToReviewReasonPrio = ({ job }) => {

  // base reasons
  if (job.hasMissingCriteria) {
    return { toReview: false }
  }

  const maxSkipReasonCount = _.max(_.values(job.skipReasonsAfterReview));
  if (maxSkipReasonCount && maxSkipReasonCount >= 3) {
    return { toReview: true, reason: `may skips of same reason`, prio: 3 }
  }
  if (job.lastEditCriteria > job.lastReview) {
    return { toReview: true, reason: `edited after review`, prio: 2 }
  }

  const minRatingAfterReview = _.min(_.pluck(job.ratingsAfterReview, 'rating'));
  if (_.isNumber(minRatingAfterReview) && minRatingAfterReview < 5) {
    return { toReview: true, reason: `low rating`, prio: 1 }
  }
  const minDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30)
  if (minDate > new Date(job.lastReview)) {
    return { toReview: true, reason: `review more than 30 days ago`, prio: 0.1 }
  }


  return {
    toReview: false,
  }
}

class CriteriaBoard extends React.Component {
  state = {
    users: null,
    jobs: null,
    selectedOps: null,
    selectedSales: null,
    selectedBucketId: null,
    selectedCompletionMode: 'show-review-required'
  }

  nbWeeksAgo = {};


  loadData = async () => {

    const { data: users } = await axios.get(`${baseUrl}/users`);
    this.setState({ users });

    const { data } = await axios.get(`${baseUrl}/criteriaBoard`);
    let jobs = _.map(data, (job) => {
      const { toReview, reason, prio } = getToReviewReasonPrio({ job });
      return {
        ...job,
        toReview,
        toReviewReason: reason,
        score: -prio || 0,
      }
    })
    jobs = _.sortBy(jobs, 'score')
    setTimeout(() => {
      this.setState({ jobs });
    }, 500);
  }

  componentDidMount() {
    this.loadData();
  }

  handleChangeSelectedOps = ({ user }) => {
    this.setState({
      selectedOps: user
    });
  }

  handleChangeSelectedSales = ({ user }) => {
    this.setState({
      selectedSales: user
    });
  }

  handleChangeCompletionMode = ({ completionMode }) => {
    this.setState({
      selectedCompletionMode: completionMode
    });
  }


  handleMarkJobReviewed = ({ jobId }) => {
    axios.post(`${baseUrl}/offers/${jobId}/markAsReviewed`);
    this.setState({
      jobs: _.map(this.state.jobs, (job) => (
        job.id === jobId ? ({
          ...job,
          toReview: false,
          lastRewiew: new Date().toISOString()
        }) : (
          job
        )
      ))
    });
  }
  handleMarkJobHasMissingCriteria = ({ jobId, hasMissingCriteria }) => {
    axios.post(`${baseUrl}/offers/${jobId}/markHasMissingCriteria`, { hasMissingCriteria });
    this.setState({
      jobs: _.map(this.state.jobs, (job) => (
        job.id === jobId ? ({
          ...job,
          ...(hasMissingCriteria && { toReview: false }),
          hasMissingCriteria
        }) : (
          job
        )
      ))
    });
  }

  render() {

    const {
      jobs,
      users,
      selectedOps,
      selectedSales,
      selectedCompletionMode
    } = this.state;

    if (!jobs) {
      return (
        <div>Loading...</div>
      );
    }

    if (!users) {
      return (
        <div>Loading...</div>
      );
    }

    const targetJobs = _.filter(jobs, (job) => {
      if (selectedOps && job.owner !== selectedOps) {
        return false;
      }
      if (selectedSales && job.salesPerson !== selectedSales) {
        return false;
      }
      if (selectedCompletionMode == 'show-missing-criteria' && !job.hasMissingCriteria) {
        return false;
      }
      if (selectedCompletionMode == 'show-review-required' && !job.toReview) {
        return false;
      }
      return true;
    });

    return (
      <div>
        <Filters
          users={users}
          selectedOps={selectedOps}
          onChangeOps={this.handleChangeSelectedOps}
          selectedSales={selectedSales}
          onChangeSales={this.handleChangeSelectedSales}
          selectedCompletionMode={selectedCompletionMode}
          onChangeCompletionMode={this.handleChangeCompletionMode}
        />
        <br />
        <b>{`${targetJobs.length} jobs`}</b>
        <br />
        <CriteriaBoardTable
          targetJobs={targetJobs}
          markJobHasMissingCriteria={this.handleMarkJobHasMissingCriteria}
          markJobReviewed={this.handleMarkJobReviewed}
        />
      </div>
    );
  }
}

export default CriteriaBoard;
