import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { parseDate, durationBetween, durationIsOverAYear } from '../common/dates';

export const renderDates = ({ startDate, endDate }) => {
  const fullStartDate = {
    raw: startDate.getRaw().get(),
    month: startDate.getMonth().get(),
    year: startDate.getYear().get(),
  };
  const parsedStartDate = parseDate(fullStartDate);
  const displayedStartDate = parsedStartDate ? parsedStartDate.format('MMM YYYY') : startDate.getRaw().get();

  if (endDate && endDate.getRaw().get()) {
    const fullEndDate = {
      raw: endDate.getRaw().get(),
      month: endDate.getMonth().get(),
      year: endDate.getYear().get(),
    };
    const parsedEndDate = parseDate(fullEndDate);
    const displayedEndDate = parsedEndDate ? parsedEndDate.format('MMM YYYY') : endDate.getRaw().get();
    return (
      <span>
        {displayedStartDate} - {displayedEndDate}
      </span>
    );
  }
  return <span>{displayedStartDate}</span>;
};

export const renderDurationFromDates = ({ startDate, endDate, firstExperience }) => {
  const parsedStartDate = startDate && startDate.getRawValue() && parseDate(startDate.getRawValue());
  const parsedEndDate = endDate && endDate.getRawValue() && parseDate(endDate.getRawValue());
  const duration = parsedStartDate && durationBetween(parsedStartDate, parsedEndDate);
  const formattedDuration = duration ? `${duration}` : '';

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentMomentDate = moment([currentYear, currentMonth, 1]);
  const endYear = (endDate && endDate.getYear().get()) || null;
  const endMonth = (endDate && endDate.getMonth().get()) || null;
  const xpEndMomentDate = endYear && moment([endYear, _.isNumber(endMonth) ? endMonth : currentMonth, 1]);

  const durationYearsSinceHasNoCurrentXp =
    firstExperience && xpEndMomentDate && endDate.getYear().get() && currentMomentDate.diff(xpEndMomentDate, 'years');
  const durationMonthsSinceHasNoCurrentXp =
    firstExperience &&
    xpEndMomentDate &&
    endDate.getYear().get() &&
    currentMomentDate.diff(xpEndMomentDate.add(durationYearsSinceHasNoCurrentXp, 'years'), 'months');

  const durationSinceHasNoCurrentXp =
    _.isNumber(durationYearsSinceHasNoCurrentXp) &&
    (durationYearsSinceHasNoCurrentXp > 1
      ? durationYearsSinceHasNoCurrentXp + ' years '
      : durationYearsSinceHasNoCurrentXp
      ? durationYearsSinceHasNoCurrentXp + ' year '
      : '') + (durationMonthsSinceHasNoCurrentXp ? durationMonthsSinceHasNoCurrentXp + ' months' : '');
  return (
    <span>
      <span
        style={{
          color: durationIsOverAYear(parsedStartDate, parsedEndDate) ? 'green' : 'orange',
          marginLeft: '5px',
          fontWeight: 'bold',
        }}
      >
        {formattedDuration}
      </span>
      {durationSinceHasNoCurrentXp && (
        <span style={{ marginLeft: '5px', color: 'blue' }}>Has been available for {durationSinceHasNoCurrentXp}</span>
      )}
    </span>
  );
};

export const getLastScrapText = (lastScrapDay) => {
  const date = moment(lastScrapDay, 'YY-MM-DD');
  const today = moment();
  if (date.isSame(today, 'day')) {
    return 'today';
  }
  return date.fromNow();
};
