import _ from 'underscore';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';

import { Tab, Grid, Segment, Button } from 'semantic-ui-react';
import { SweetForm, Select, Checkbox, Input, enhance } from 'sweetform';
import baseUrl from '../../baseUrl';


const FUNCTION_ID_OPTIONS = [
  { label: "Clients Send Stats", value: "clientSendStats" },
  { label: "Clients Export", value: "clientExport" }
]
const TIMESLOT_OPTIONS = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" }
]
const TIMESLOT_MODE_OPTIONS = [
  { label: "Current", value: "current" },
  { label: "Previous", value: "previous" },
  { label: "Custom", value: "custom" }
]

const downloadAsCsv = ({ items, prefix }) => {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  // Download csv
  var csvData = new Blob([csv], { type: 'text/csv' });
  var csvUrl = URL.createObjectURL(csvData);
  var now = moment().format('YYMMDDHHmmss');
  var hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.download = `${prefix}_${now}.csv`;
  hiddenElement.click();
};
const CSVDownloader = ({ data, functionId }) => {
  return (
    <Segment>
    {data.length} results.
    <Button onClick={()=>downloadAsCsv({items: data, prefix: functionId})}>Download as CSV</Button>
  </Segment>
  )
}

const ClientSendStatsForm = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('timeslotMode') : '';
  return (
    <div>
      <Select field='timeslot' options={TIMESLOT_OPTIONS} defaultValue={"week"} />
      <Select field='timeslotMode' options={TIMESLOT_MODE_OPTIONS} defaultValue={"current"} />
      {mode == "custom" ? <Input type="date" field="targetDay" /> : null}
      <b>With Records ?</b><Checkbox field='withRecords' />
    </div>
  )
})

const SelectFunctionAndParams = enhance((props) => {
  const { value } = props;
  const functionId = value ? value.get('functionId') : '';
  return (
    <div>
      <Select field='functionId' options={FUNCTION_ID_OPTIONS} defaultValue={"clientSendStats"} />
      {functionId == "clientSendStats" ? <ClientSendStatsForm field="params" /> : null}
      {functionId == "clientExport" ? <ClientSendStatsForm field="params" /> : null}
    </div>
  )
})

class SlackStats extends Component {
  state = {
    functionId: null,
    params: null,
    mainText: null,
    detailsText: null
  };

  handleChangeForm = async ({ value }) => {
    const { functionId, params } = value;
    this.setState({ functionId, params })
  }

  handleSubmit = async ({ postToSlack }) => {
    const { functionId, params } = this.state;
    if (params.timeslotMode == "previous") {
      params.previous = true
    }
    const payload = { functionId, params, postToSlack };
    try {
      const result = (await axios.post(`${baseUrl}/discoverStats`, payload)).data;
      console.log(result)
      if (!result.success) {
        throw Error(result.error)
      }
      const { mainText, detailsTexts, data } = result;
      console.log({ mainText, detailsTexts, data })
      this.setState({ mainText, detailsTexts, data })
    } catch (e) {
      alert(JSON.stringify(e))
    }
  }

  render() {
    const { mainText, detailsTexts, data, functionId } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <SweetForm onChange={this.handleChangeForm}>
              <SelectFunctionAndParams field="value" />
            </SweetForm>
            <Button onClick={() => this.handleSubmit({ postToSlack: false })}>Query</Button>
            {/*<Button onClick={()=>this.handleSubmit({postToSlack: true})}>Display And Post To Slack</Button>*/}
          </Grid.Column>
          <Grid.Column width={8}>
            {mainText && (
              <Segment>
                <p style={{ whiteSpace: "pre-line" }}>{mainText}</p>
              </Segment>
            )}
            {detailsTexts && (
              _.map(detailsTexts, ({ name, text }) => (
                <Segment>
                  <b>{name}</b>
                  <p style={{ whiteSpace: "pre-line" }}>{text}</p>
                </Segment>
              ))
            )}
            {data && (
              <CSVDownloader data={data} functionId={functionId}/>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default SlackStats;
