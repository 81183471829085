import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Form, Header, Table, Button, Grid, Label } from 'semantic-ui-react';
import { SweetForm, Input, Select, enhance } from 'sweetform';

import baseUrl from './baseUrl.js';
import Modal from './Modal';

const SOURCES = {
  github: 'Github',
  so: 'StackOverflow',
  go: 'Google',
  lever: 'Lever',
  jobvite: 'Jobvite',
  li: 'LinkedIn',
  cb: 'Crunchbase',
  hired: 'Hired',
  meetup: 'Meetup',
  wttj: 'W.T.T.J',
  workable: 'Workable',
  jobijoba: 'JobiJoba',
  sheets: 'Hiresweet Sheets',
  station: 'Hiresweet Station',
  email: 'Email',
};
const SOURCES_FIELDS = {
  github: ['token'],
  meetup: ['token'],
  so: ['token'],
  li: ['emails', 'passwords'],
};

const sourcesOptions = _.map(SOURCES, (label, value) => ({ value, label }));

// Actions
const SET_IDENTITIES = 'SET_IDENTITIES';
const setIdentities = (identities) => ({ type: SET_IDENTITIES, identities });

const SET_IDENTITY = 'SET_IDENTITY';
const setIdentity = (identity) => ({ type: SET_IDENTITY, identity });

const SET_WORKERS = 'SET_WORKERS';
const setWorkers = (workers) => ({ type: SET_WORKERS, workers });

const SET_WORKER = 'SET_WORKER';
const setWorker = (worker) => ({ type: SET_WORKER, worker });

const SET_INITIAL_VALUES = 'SET_IV';
const setInitialValues = (values) => ({ type: SET_INITIAL_VALUES, values });

const SET_EDIT_MODE = 'SET_EDIT_MODE';
const setEditMode = (mode) => ({ type: SET_EDIT_MODE, mode });

const SORT_IDENTITIES = 'SORT_IDENTITIES';
const sortIdentities = (by) => ({ type: SORT_IDENTITIES, by });

const SORT_WORKERS = 'SORT_WORKERS';
const sortWorkers = (by) => ({ type: SORT_WORKERS, by });

const SET_EDIT = 'SET_EDIT';
const setEdit = (edit) => ({ type: SET_EDIT, edit });

// Reducers
const identities = (state = [], action) => {
  switch (action.type) {
    case SET_IDENTITIES:
      return _.sortBy(action.identities, 'id');
    case SET_IDENTITY:
      const target = _.findIndex(
        state,
        (identity) => identity.id === action.identity.id,
      );
      if (target < 0) {
        if (!action.identity.delete) {
          return [...state, action.identity];
        } else {
          return state;
        }
      } else {
        if (!action.identity.delete) {
          return _.map(state, (identity, i) =>
            i === target ? action.identity : identity,
          );
        } else {
          return _.filter(state, (identity, i) => i !== target);
        }
      }
    case SORT_IDENTITIES:
      return _.sortBy(state, action.by);
    default:
      return state;
  }
};

const getWorkerStatus = (worker) => (worker.enabled ? 'enabled' : 'disabled');

const getWorkerIdentity = (worker) =>
  worker.identity ? worker.identity.id : 'no';

const workers = (state = [], action) => {
  switch (action.type) {
    case SET_WORKERS:
      const populatedWorkers = _.map(action.workers, (worker) => ({
        ...worker,
        status: getWorkerStatus(worker),
        idOk: getWorkerIdentity(worker),
      }));
      return _.sortBy(populatedWorkers, 'hostname');
    case SET_WORKER:
      const target = _.findIndex(
        state,
        (worker) =>
          worker.hostname === action.worker.hostname &&
          worker.source === action.worker.source,
      );
      const newWorker = {
        ...action.worker,
        status: getWorkerStatus(action.worker),
        idOk: getWorkerIdentity(action.worker),
      };

      if (target < 0) {
        if (!action.worker.delete) {
          return [...state, newWorker];
        } else {
          return state;
        }
      } else {
        if (!action.worker.delete) {
          return _.map(state, (worker, i) =>
            i === target ? newWorker : worker,
          );
        } else {
          return _.filter(state, (worker, i) => i !== target);
        }
      }
    case SORT_WORKERS:
      return _.sortBy(state, action.by);
    default:
      return state;
  }
};

const initialValues = (state = {}, action) => {
  switch (action.type) {
    case SET_INITIAL_VALUES:
      return action.values;
    default:
      return state;
  }
};

const editMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.mode;
    default:
      return state;
  }
};

const edit = (state = {}, action) => {
  switch (action.type) {
    case SET_INITIAL_VALUES:
      return action.values;
    case SET_EDIT:
      return action.edit;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  identities,
  workers,
  initialValues,
  editMode,
  edit,
});

// Store
const store = createStore(rootReducer);

// Components
const statusTags = {
  ok: { c: 'green' },
  no: { c: 'red', hidden: true },
  disabled: { c: 'red', hidden: true },
  enabled: { c: 'green', hidden: true },
  available: { c: 'green' },
  used: { c: 'yellow' },
};

const statusTagsComponents = _.mapObject(
  statusTags,
  ({ c, hidden }, status) => <Label color={c}>{!hidden ? status : null}</Label>,
);

const ConfigArray = ({
  children,
  columns, // [ [ref, name] ... ]
  entities,
  editCondition,
  deleteCondition,
  onEdit,
  onToggle,
  onDelete,
  onSort,
}) => (
  <Table celled compact="very">
    <Table.Header>
      <Table.Row>
        {columns.map((column) => (
          <Table.HeaderCell key={column[0]}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onSort(column[0])}
            >
              {column[1]}
            </span>
          </Table.HeaderCell>
        ))}
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {entities.map((entity) => (
        <Table.Row key={entity.id || `${entity.hostname}_${entity.source}`}>
          {columns.map(([ref]) => (
            <Table.Cell key={`${ref}_${entity.id}`}>
              {(ref === 'status' || ref === 'idOk') &&
              statusTagsComponents[entity[ref]] ? (
                statusTagsComponents[entity[ref]]
              ) : (
                <span>
                  {ref === 'source' ? SOURCES[entity[ref]] : entity[ref]}
                </span>
              )}
            </Table.Cell>
          ))}
          <Table.Cell>
            {editCondition(entity) ? (
              <Button size="mini" color="yellow" onClick={() => onEdit(entity)}>
                Edit
              </Button>
            ) : null}
            <Button size="mini" color="brown" onClick={() => onToggle(entity)}>
              Toggle
            </Button>
            {deleteCondition(entity) ? (
              <Button size="mini" color="red" onClick={() => onDelete(entity)}>
                Delete
              </Button>
            ) : null}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

const EditWorkerModal = ({
  workerSource,
  identities,
  initialValues,
  onChange,
  onSubmit,
  onCancel,
}) => (
  <Modal
    headerText={`Edit worker ${initialValues.hostname ||
      ''} ${initialValues.source || ''}`}
    submitText="Save"
    onSubmit={onSubmit}
    onCancel={onCancel}
  >
    <Form>
      <SweetForm initialValues={initialValues} onChange={onChange}>
        <Form.Field>
          <label>Name</label>
          <Input autoFocus field="name" />
        </Form.Field>
        <Form.Field>
          <label>Hostname</label>
          <Input field="hostname" disabled={!!initialValues.hostname} />
        </Form.Field>
        <Form.Field>
          <label>Source</label>
          <Select
            field="source"
            disabled={!!initialValues.source}
            options={sourcesOptions}
          />
        </Form.Field>
        <Form.Field>
          <label>Identity</label>
          <Select
            field="identity"
            labelKey="id"
            valueKey="id"
            options={identities}
          />
        </Form.Field>
      </SweetForm>
    </Form>
    <div style={{ height: '200px' }} />
  </Modal>
);
const IdentityParameters = enhance(({ identitySource }) => (
  <div>
    {_.map(SOURCES_FIELDS[identitySource], (field) => (
      <Form.Field key={field}>
        <label>{field}</label>
        <Input field={field} />
      </Form.Field>
    ))}
  </div>
));

const EditIdentityModal = ({
  initialValues,
  identitySource,
  onChange,
  onSubmit,
  onCancel,
}) => (
  <Modal
    active={true}
    headerText={`Edit identity ${initialValues.id || ''}`}
    submitText="Save"
    onSubmit={onSubmit}
    onCancel={onCancel}
  >
    <Form>
      <SweetForm initialValues={initialValues} onChange={onChange}>
        <Form.Field>
          <label>Identifier</label>
          <Input autoFocus field="id" disabled={!!initialValues.id} />
        </Form.Field>
        <Form.Field>
          <label>Source</label>
          <Select
            field="source"
            disabled={!!initialValues.source}
            options={sourcesOptions}
          />
        </Form.Field>
        <IdentityParameters field="identity" identitySource={identitySource} />
      </SweetForm>
    </Form>
    <div style={{ height: '200px' }} />
  </Modal>
);
class Config extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const {
      edit,
      editMode,
      editSource,
      initialValues,
      workers,
      identities,
      onCancel,
      onEdit,
      onEditWorker,
      onToggleWorker,
      onDeleteWorker,
      onSubmitWorker,
      onEditIdentity,
      onToggleIdentity,
      onDeleteIdentity,
      onSubmitIdentity,
      onSortWorkers,
      onSortIdentities,
    } = this.props;

    return (
      <Grid>
        {editMode === 'identity' ? (
          <EditIdentityModal
            identitySource={editSource}
            initialValues={initialValues}
            onChange={onEdit}
            onSubmit={() => onSubmitIdentity(edit)}
            onCancel={onCancel}
          />
        ) : null}
        {editMode === 'worker' ? (
          <EditWorkerModal
            identities={_.filter(
              identities,
              (identity) =>
                identity.source === editSource &&
                // Get available identity or currently affected identity (if any)
                (identity.status === 'available' ||
                  (initialValues.identity &&
                    initialValues.identity.id === identity.id)),
            )}
            initialValues={initialValues}
            onChange={onEdit}
            onSubmit={() => onSubmitWorker(workers, identities, edit)}
            onCancel={onCancel}
          />
        ) : null}
        <Grid.Column width={10}>
          <Header as="h3">
            Workers
            <Button
              color="green"
              style={{ marginLeft: '30px' }}
              onClick={() => onEditWorker({})}
            >
              Add
            </Button>
          </Header>
          <ConfigArray
            columns={[
              ['name', 'Name'],
              ['hostname', 'Host'],
              ['source', 'Source'],
              ['status', 'R.'],
              ['idOk', 'Identity'],
            ]}
            entities={workers}
            editCondition={(worker) => true}
            deleteCondition={(worker) => true}
            onEdit={onEditWorker}
            onToggle={onToggleWorker}
            onDelete={onDeleteWorker}
            onSort={onSortWorkers}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Header as="h3">
            Identities
            <Button
              color="green"
              style={{ marginLeft: '30px' }}
              onClick={() => onEditIdentity({})}
            >
              Add
            </Button>
          </Header>
          <ConfigArray
            columns={[['id', 'Id'], ['source', 'Source'], ['status', 'Status']]}
            entities={identities}
            editCondition={(identity) => identity.status !== 'used'}
            deleteCondition={(identity) => identity.status !== 'used'}
            onEdit={onEditIdentity}
            onToggle={(identity) => onToggleIdentity(workers, identity)}
            onDelete={onDeleteIdentity}
            onSort={onSortIdentities}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers
const mapSConfig = (state) => ({
  edit: state.edit,
  editSource: state.edit.source,
  editMode: state.editMode,
  initialValues: state.initialValues,
  workers: state.workers,
  identities: state.identities,
});

const mapDConfig = (dispatch) => ({
  onEdit: (edit) => dispatch(setEdit(edit)),
  onLoad: async () => {
    const [workers, identities] = await Promise.all([
      axios.get(baseUrl + '/workers'),
      axios.get(baseUrl + '/identities'),
    ]);
    dispatch(setWorkers(workers.data));
    dispatch(setIdentities(identities.data));
  },
  onCancel: () => dispatch(setEditMode('none')),
  onToggleWorker: async (worker) => {
    const { hostname, source } = worker;
    const enabled = !worker.enabled;
    await axios.put(baseUrl + '/workers', {
      hostname,
      source,
      enabled,
    });
    dispatch(setWorker({ ...worker, enabled }));
  },
  onToggleIdentity: async (workers, identity) => {
    if (identity.status === 'used') {
      // Find the worker
      const worker = _.find(
        workers,
        (worker) => worker.identity && worker.identity.id === identity.id,
      );
      if (worker) {
        const newWorker = { ...worker, identity: null, enabled: false };
        await axios.put(baseUrl + '/workers', newWorker);
        dispatch(setWorker(newWorker));
        dispatch(setIdentity({ ...identity, status: 'available' }));
      }
      return;
    }

    const status = identity.status === 'disabled' ? 'available' : 'disabled';
    await axios.put(`${baseUrl}/identities/${identity.id}`, { status });
    dispatch(setIdentity({ ...identity, status }));
  },
  onEditWorker: (worker = {}) => {
    dispatch(setInitialValues(worker));
    dispatch(setEditMode('worker'));
  },
  onEditIdentity: (identity = {}) => {
    dispatch(setInitialValues(identity));
    dispatch(setEditMode('identity'));
  },
  onDeleteWorker: async (worker) => {
    await axios.delete(
      `${baseUrl}/workers/${worker.hostname}/${worker.source}`,
    );
    dispatch(setWorker({ ...worker, delete: true }));
    if (worker.identity) {
      dispatch(setIdentity({ ...worker.identity, status: 'available' }));
    }
  },
  onDeleteIdentity: async (identity) => {
    await axios.delete(`${baseUrl}/identities/${identity.id}`);
    dispatch(setIdentity({ ...identity, delete: true }));
  },
  onSubmitWorker: async (workers, identities, values) => {
    if (!values.hostname || !values.source) {
      return;
    }

    // Populate values.identity
    values.identity = values.identity
      ? _.findWhere(identities, { id: values.identity })
      : null;

    if (values.identity && values.identity.source !== values.source) {
      values.identity = null;
    }

    await axios.put(`${baseUrl}/workers`, _.omit(values, 'status', 'idOk'));

    // Release previous identity client side, if any
    const oldWorker = _.findWhere(workers, {
      hostname: values.hostname,
      source: values.source,
    });
    if (oldWorker && oldWorker.identity) {
      dispatch(setIdentity({ ...oldWorker.identity, status: 'available' }));
    }

    // Lock current identity client side, if any
    if (values.identity) {
      dispatch(setIdentity({ ...values.identity, status: 'used' }));
    }

    dispatch(setEditMode('none'));
    dispatch(setWorker(values));
  },
  onSubmitIdentity: async (values) => {
    if (!values.id) {
      return;
    }

    if (!values.status) {
      values.status = 'available';
    }
    await axios.put(`/identities/${values.id}`, values);
    dispatch(setEditMode('none'));
    dispatch(setIdentity(values));
  },
  onSortIdentities: (by) => dispatch(sortIdentities(by)),
  onSortWorkers: (by) => dispatch(sortWorkers(by)),
});

const ConfigContainer = connect(
  mapSConfig,
  mapDConfig,
)(Config);

export default ({ mode, types, match, history }) => (
  <Provider store={store}>
    <ConfigContainer />
  </Provider>
);
export { sourcesOptions };
