import React from 'react';
import _ from 'underscore';

class GenericMarkdownView extends React.Component {
  render() {
    const { content } = this.props;
    return (
      <div>
        {_.map(content, (line) => (
          <div style={{ 'white-space': 'pre-wrap' }}>{line}</div>
        ))}
      </div>
    );
  }
}

export default GenericMarkdownView;
