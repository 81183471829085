import React, { Component } from 'react';
import _ from 'underscore';
import {
  Icon,
  List,
  Grid,
  Input as SInput,
  Header,
  Dropdown,
  Checkbox as SCheckbox,
  Popup,
} from 'semantic-ui-react';
import { List as IList, Map as IMap, fromJS } from 'immutable';
import {
  SweetForm,
  enhance,
  Input,
  TextArea,
  List as SweetList,
  Select,
  Checkbox,
} from 'sweetform';
import externalSources from '../sources';
import { removeSpacesAndDash } from '../../common';
import skillDataFromId from '../../common/skillDataFromId';

import Copy from '../../components/Copy';
import defaultFields from '../fields';
import { MiscProfile } from './MiscProfile';
import { RepositoryCard, RelevantFact } from '../../components/ClientProfile';
import { extractSkillsFrontErichedText } from '../../components/EnrichedText';


export const getSourceLink = (value, key) =>
  value.match(/^(http|https):\/\//i)
    ? value
    : (_.findWhere(externalSources, { id: key }) || { url: ':id' }).url.replace(
        ':id',
        value,
      );

const getLabelPriority = (label) =>
      label === 'required'
      ? 3
      : label === 'important'
      ? 2
      : label === 'bonus'
      ? 1
      : 0;

const getUniqId = (skills) => {
  const skillsMap = _.reduce(
    skills,
    (memo, { label, id }) => {
      if (!memo[id]) {
        memo[id] = label;
      } else if (getLabelPriority(memo[id]) < getLabelPriority(label)) {
        memo[id] = label;
      }
      return memo;
    },
    {},
  );
  return _.map(skillsMap, (label, id) => ({ label, id }));
};


const EmailHeader = ({ profile }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={1}>
        <Header as="h2">Emails</Header>
      </Grid.Column>
      <Grid.Column width={15}>
        <Copy
          text={`${removeSpacesAndDash(
            ((profile && profile.data) || {}).firstname,
          )} ${removeSpacesAndDash(
            ((profile && profile.data) || {}).lastname,
          )}@gmail.com`}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export const getPhotoLinkBySourceFromProfile = (profile) => {
  const defaultAvatar =
    'https://s3.amazonaws.com/37assets/svn/765-default-avatar.png';
  const sources = (profile && profile.sourceProfiles) || {};
  const linkedinData = (sources.linkedin && sources.linkedin.data) || {};
  const linkedinLink =
    linkedinData.photoLink &&
    linkedinData.photoLink.indexOf('s3.amazonaws.com') < 0 &&
    linkedinData.photoLink;
  const githubData = sources.github || {};
  const githubLink =
    githubData.id &&
    'https://avatars3.githubusercontent.com/u/' + githubData.id + '?s=460&v=4';
  const extraData = (profile.innerData && profile.innerData.extraData) || {};
  const extraLink = extraData.photoLink;

  return {
    default: defaultAvatar,
    ...(linkedinLink && { linkedin: linkedinLink }),
    ...(githubLink && { github: githubLink }),
    ...(extraLink && { extra: extraLink }),
  };
};

const ProfileInfoHeader = ({ profile }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={2}>
        <Header as="h2">Profile Info</Header>
      </Grid.Column>
      <Grid.Column width={14}>
        <MiscProfile
          idFields={(profile || {}).idFields}
          expanded={true}
          sources={(profile || {}).sourceProfiles}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const CustomInputField = (props) => (
  <Grid>
    <Grid.Column width={4}>
      <div>{props.fieldName || props.field}</div>
    </Grid.Column>
    <Grid.Column width={12}>
      <Input field={props.field} />
    </Grid.Column>
  </Grid>
);

const CustomTextAreaField = (props) => (
  <Grid>
    <Grid.Column width={4}>
      <div>{props.fieldName || props.field}</div>
    </Grid.Column>
    <Grid.Column width={12}>
      <TextArea
        field={props.field}
        style={{
          padding: '0.78571429em 1em',
          background: '#fff',
          border: '1px solid rgba(34,36,38,.15)',
          outline: '0',
          color: 'rgba(0,0,0,.87)',
          borderRadius: '0.28571429rem',
          boxShadow: '0 0 0 0 transparent inset',
          transition: 'color .1s ease,border-color .1s ease',
          fontSize: '1em',
          lineHeight: '1.2857',
          resize: 'vertical',
          width: '100%',
          height: '100px',
        }}
      />
    </Grid.Column>
  </Grid>
);

export const CustomSelectField = enhance((props) => {
  const fieldName = props.value.get('field');
  const defaultValues = _.map(
    (props.value.get('options') || IMap()).toJS(),
    (o, i) => ({
      value: fieldName ? o[fieldName] : o,
      label: `${_.isString(i) ? `(${i})` : ''} ${fieldName ? o[fieldName] : o}`,
    }),
  );
  return (
    <Grid>
      <Grid.Column width={4}>
        <div>{props.field}</div>
      </Grid.Column>
      <Grid.Column width={12}>
        <Select autoFocus field="value" creatable options={defaultValues} />
      </Grid.Column>
    </Grid>
  );
});

const CustomRelevantTagsField = enhance(() => (
  <Grid style={{ border: '1px solid rgba(34,36,38,.15)', margin: '0' }}>
    <Grid.Row>
      <Grid.Column width={1}>
        <div>label</div>
      </Grid.Column>
      <Grid.Column width={15}>
        <CustomTranslatableTextInputField field="label" />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={1}>
        <div>content</div>
      </Grid.Column>
      <Grid.Column width={15}>
        <CustomTranslatableTextInputField field="content" />
      </Grid.Column>
    </Grid.Row>
  </Grid>
));

const CustomTranslatableTextInputField = enhance((props) => (
  <Grid columns="equal">
    <Grid.Column>
      <Input field="default" />
    </Grid.Column>
    <Grid.Column>
      <Input field="fr" />
    </Grid.Column>
    <Grid.Column>
      <Input field="en" />
    </Grid.Column>
  </Grid>
));

const RelevantTagsLegend = () => (
  <Grid style={{ margin: '0' }}>
    <Grid.Row>
      <Grid.Column width={1}>
        <div />
      </Grid.Column>
      <Grid.Column width={1}>
        <div>Champs</div>
      </Grid.Column>
      <Grid.Column width={14}>
        <Grid columns="equal">
          <Grid.Column>
            <div>default</div>
          </Grid.Column>
          <Grid.Column>
            <div>fr</div>
          </Grid.Column>
          <Grid.Column>
            <div>en</div>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export const CustomSourceField = enhance(() => (
  <Grid>
    <Grid.Column width={4}>
      <Select
        autoFocus
        field="key"
        creatable
        options={defaultFields}
        placeholder="key"
      />
    </Grid.Column>
    <Grid.Column width={12}>
      <Input field="value" placeholder="value" />
    </Grid.Column>
  </Grid>
));

const LinkedinSkillLegend = () => (
  <Grid>
    <Grid.Column width={4}>
      <div>Suppression</div>
    </Grid.Column>
    <Grid.Column width={4}>
      <div>Surbrillance</div>
    </Grid.Column>
    <Grid.Column width={8}>
      <div>Skill</div>
    </Grid.Column>
  </Grid>
);

const EditLinkedinSkill = enhance(() => (
  <Grid>
    <Grid.Column width={8}>
      <Checkbox toggle field="removed" style={{ marginRight: '10px' }} />
    </Grid.Column>
    <Grid.Column width={8}>
      <Checkbox field="isTarget" />
    </Grid.Column>
  </Grid>
));

const LinkedinSkillEdit = enhance((props) => {
  const skill = props.value.get('skill') || IMap();
  const label = skill.get('label') || IMap();
  const defaultTranslation = label.get('default') || '';
  return (
    <Grid>
      <Grid.Column width={8}>
        <EditLinkedinSkill field="edit" />
      </Grid.Column>
      <Grid.Column width={8}>
        <span>{defaultTranslation}</span>
      </Grid.Column>
    </Grid>
  );
});

const GithubRepositories = enhance((props) => {
  const repo = (props.value.get('repo') || IList()).toJS();
  return (
    <Grid>
      <Grid.Column width={3}>
        <Checkbox toggle field="selected" style={{ marginRight: '10px' }} />
      </Grid.Column>
      <Grid.Column width={13}>
        <RepositoryCard {...repo} />
      </Grid.Column>
    </Grid>
  );
});

const StackoverflowRelevantFacts = enhance((props) => {
  const relevantFact = (props.value.get('relevantFact') || IList()).toJS();
  return (
    <Grid>
      <Grid.Column width={3}>
        <Checkbox toggle field="selected" style={{ marginRight: '10px' }} />
      </Grid.Column>
      <Grid.Column width={13} className="relevant-facts">
        <RelevantFact
          label={relevantFact.title}
          content={relevantFact.description}
        />
      </Grid.Column>
    </Grid>
  );
});

const EditMainEducation = enhance(
  class extends React.Component {
    state = {
      overwritingSchoolName: '',
      overwritingRawStartYear: '',
      overwritingRawEndYear: '',
      displayMainEducation: false,
    };
    
    handleOverwritingFieldChange = () => {
      const { value, setValue } = this.props;
      const {
        overwritingSchoolName,
        overwritingRawStartYear,
        overwritingRawEndYear,
        displayMainEducation,
      } = this.state;
      setValue(
        fromJS({
          ...(value && value.toJS()),
          overwritingSchoolName,
          overwritingRawStartYear,
          overwritingRawEndYear,
          displayMainEducation,
        }),
      );
    };
    handleChangeSchoolName = ({ target }) => {
      this.setState(
        { overwritingSchoolName: target.value },
        this.handleOverwritingFieldChange,
      );
    };
    handleChangeRawStartYear = ({ target }) => {
      this.setState(
        { overwritingRawStartYear: target.value },
        this.handleOverwritingFieldChange,
      );
    };
    handleChangeRawEndYear = ({ target }) => {
      this.setState(
        { overwritingRawEndYear: target.value },
        this.handleOverwritingFieldChange,
      );
    };
    render() {
      const { value, setValue } = this.props;
      const ivalue = value;
      const mainEducationOptions = this.props.mainEducationOptions || [];

      const onChangeChoice = (e, { value }) => {
        if (value >= mainEducationOptions.length) {
          alert('invalid index in main education dropdown');
          return;
        }
        const newValue = mainEducationOptions[value];
        this.setState(
          {
            overwritingSchoolName: '',
            overwritingRawStartYear: '',
            overwritingRawEndYear: '',
          },
          () => {
            setValue(fromJS(newValue));
          },
        );
      };

      const onToggleMainEducation = (e, { value }) => {
        const {
          overwritingSchoolName,
          overwritingRawStartYear,
          overwritingRawEndYear,
        } = this.state;
        const displayMainEducation = !value;
        this.setState({ displayMainEducation }, () => {
          setValue(
            fromJS(
              ...(ivalue && ivalue.toJS()),
              overwritingSchoolName,
              overwritingRawStartYear,
              overwritingRawEndYear,
              displayMainEducation,
            ),
          );
        });
      };

      const options = _.map(
        mainEducationOptions,
        ({ schoolName, startDate, endDate }, index) => ({
          value: index,
          text:
            schoolName +
            ' ' +
            (startDate ? startDate.year || startDate.raw : '?') +
            ' - ' +
            (endDate ? endDate.year || endDate.raw : '?'),
        }),
      );

      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <div>{this.props.field}</div>
            </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown options={options} selection onChange={onChangeChoice} />
            </Grid.Column>
            <Grid.Column width={6}>
              {/* value={this.state.displayMainEducation}  */}
              <SCheckbox toggle onChange={onToggleMainEducation} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              Overwrite selected Main Education
            </Grid.Column>
            <Grid.Column width={12}>
              <SInput
                style={{ width: '50%' }}
                label="SchoolName"
                value={this.state.overwritingSchoolName}
                onChange={this.handleChangeSchoolName}
              />
              <SInput
                style={{ width: '20%' }}
                label="StartYear"
                value={this.state.overwritingRawStartYear}
                onChange={this.handleChangeRawStartYear}
              />
              <SInput
                style={{ width: '20%' }}
                label="EndYear"
                value={this.state.overwritingRawEndYear}
                onChange={this.handleChangeRawEndYear}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  },
);

// headline sweetform components
const HeadlineContentEditor = enhance((props) => (
  <CustomInputField fieldName='headline' field="text" />
));

export const HeadlineEditor = enhance((props) => (
  <HeadlineContentEditor field="content" />
));

// summary sweetform components
const SummaryContentEditor = enhance((props) => (
  <CustomTextAreaField fieldName='summary' field="text" />
));

export const SummaryEditor = enhance((props) => (
  <SummaryContentEditor field="content" />
));

// location sweetform components
const LocationDefaultTextEditor = enhance((props) => (
  <CustomInputField fieldName='location' field="default" />
));

export const LocationEditor = enhance((props) => (
  <LocationDefaultTextEditor field="name" />
));


export class ExtraFields extends Component {
  componentWillMount() {
    const { initialValues, onUpdateParameters } = this.props;
    onUpdateParameters(initialValues);
  }
  render() {
    const {
      initialValues,
      onUpdateParameters,
      profile,
      mainEducationOptions,
    } = this.props;

    return (
      <SweetForm onChange={onUpdateParameters} initialValues={initialValues}>
        <ProfileInfoHeader profile={profile} />

        <HeadlineEditor field='headline'/>
        <SummaryEditor field="summary" />
        <LocationEditor field="location" />

        <EditMainEducation
          field="mainEducation"
          mainEducationOptions={mainEducationOptions}
        />

        <CustomSelectField field="photoLink" />
        <Header as="h2">Relevant Tags</Header>
        <RelevantTagsLegend />
        <SweetList
          component={CustomRelevantTagsField}
          field="relevantTags"
          compact
        />
        <Header as="h2">Sources</Header>
        <SweetList component={CustomSourceField} field="sources" compact />
        <Header as="h2">Linkedin Skills</Header>
        <LinkedinSkillLegend />
        <SweetList
          component={LinkedinSkillEdit}
          field="linkedinSkills"
          compact
          displayAddButton={false}
        />
        <Header as="h2">Github Repositories</Header>

        <SweetList
          component={GithubRepositories}
          field="githubRepositories"
          compact
          displayAddButton={false}
        />

        <Header as="h2">Stackoverflow Relevant Facts</Header>
        <SweetList
          component={StackoverflowRelevantFacts}
          field="stackoverflowRelevantFacts"
          compact
          displayAddButton={false}
        />

        <div style={{ height: 200 }} />
      </SweetForm>
    );
  }
}

const wrapWorkplace = (workplace) => {
  const data = {
    name: workplace.name,
    types: _.compact(_.map(workplace.workplaceTypes, ({ id }) => (
      (id === 'startup') ? (
        { id: "startup", name: { "default": "Startup", "fr": "Start-up" } }
      ) : (id === 'ssii') ? (
        { "id": "ssii", "name": { "default": "IT services", "fr": "ESN" } }
      ) : (id === 'big') ? (
        { "id": "big", "name": {  "default": "Large Company", "fr": "Grande Entreprise" } }
      ) : null
    ))),
    ...workplace.description && workplace.description.default && {
      description: workplace.description
    },
    foundedYear: workplace.foundedYear,
    ...!_.isEmpty(workplace.staffCount) && { staffCount: workplace.staffCount },
    mainLocation: workplace.mainLocation,
    stack: _.map(workplace.stack, ({ identifier, ...skill }) => {
      const skillData = identifier && skillDataFromId[identifier]
      return {
        ...skill,
        ...skillData,
        id: identifier
      };
    }), 
    industries: workplace.industries,
    sources: _.compact(_.map(workplace.sources, (url, sourceId) => (
      sourceId === 'linkedin' ? (
        {
          sourceId: "linkedin",
          url: url,
          name: { "default": "LinkedIn" }
        }
      ) : sourceId === 'wttj' ? (
        {
          sourceId: "wttj",
          url: url,
          name: { "default": "WTTJ" }
        }
      ) : sourceId === 'crunchbase' ? (
        {
          sourceId: "crunchbase",
          url: url,
          name: { "default": "Crunchbase" }
        }        
      ) : sourceId === 'website' ? (
        {
          sourceId: "website",
          url: url,
          name: { "default": "Website" }
        }        
      ) : null
    )))
  };

  if (!_.isEmpty(workplace.id) || !_.isEmpty(workplace.idFields) || !_.isEmpty(data)) {
    return {
      ...workplace.id && { id: workplace.id },
      ...workplace.idFields && { idFields: workplace.idFields },
      ...data && { data }
    };
  }
  
}

const getAugmentedElementHash = (baseHash) => {
  const text = (baseHash || '').toLowerCase().slice(0, 100);
  let result = '';
  _.each(text, (c) => {
    result += c >= 'a' && c <= 'z' ? c : '_';
  });
  return result;
};


export const Upload = ({
  profile,
  uploadEmail,
  onClickEmail,
  onUpdateParameters,
  onReloadProfile,
}) => {
  const sourcesOrder = [
    'linkedin',
    'github',
    'stackoverflow',
    'twitter',
    'website',
  ];
  const emptySources = _.object(_.map(sourcesOrder, (s) => [[s], '']));
  const sourcesBeforeSort = _.map(
    profile &&
      profile.data &&
      profile.data.sources && {
        ...emptySources,
        ..._.omit(profile.data.sources, 'id', 'stackOverflow'),
      },
    (value, key) => ({
      key,
      value: _.isEmpty(value) ? '' : getSourceLink(value, key),
    }),
  );
  const sources = _.sortBy(sourcesBeforeSort, (source, index) => {
    const indexInSortedList = _.indexOf(sourcesOrder, source.key);
    return indexInSortedList > -1 ? indexInSortedList : 10 + index;
  });
  const { tags, relevantActivities } = profile;
  const {
    headline,
    summary,
    location,
    mainEducation,
    photoLink,
    relevantTags,
    sourceData,
    education,
    experiences,
  } = (profile && profile.data) || {};


  const linkedinProfile = ((profile || {}).sourceProfiles || {}).linkedin
  const linkedinExperiences = ((linkedinProfile || {}).data || {}).experiences;
  const enrichedExperienceFromHash = {};
  _.each(linkedinExperiences, (exp) => {
    if (exp.experienceName || exp.companyName) {
      const hash = getAugmentedElementHash(
        (exp.experienceName || '') +
          (exp.companyName || '') +
          (exp.descriptionHTML || ''),
      );
      enrichedExperienceFromHash[hash] = exp;
    }
  });

  const { augmentedExperiencesAndEducation } = profile || {};

  const mainEducationOptions = _.map(
    education,
    ({ schoolName, startDate, endDate }) => ({
      schoolName,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    }),
  );
  const photoLinkOptions = getPhotoLinkBySourceFromProfile(profile);
  const currentCompanies = _.compact(
    _.map(experiences, (xp) => !xp.endDate && xp.companyName),
  );
  const lastCompanyNames = _.compact(
    _.map(
      experiences,
      (xp) =>
        xp.endDate &&
        xp.companyName &&
        !_.contains(currentCompanies, xp.companyName) &&
        xp.companyName.toLowerCase().replace(' ', '-'),
    ),
  );
  const suspiciousEmails = _.compact(
    _.flatten(
      _.map(lastCompanyNames, (name) =>
        _.map(
          profile.listEmails,
          (e) =>
            (e.sanitized || e.email).indexOf(name) >= 0 &&
            (e.sanitized || e.email),
        ),
      ),
    ),
  );

  const getSummaryStack = () => {
    const summary = ((linkedinProfile || {}).data || {}).summaryEnriched || null;
    if (!summary) {
      return [];
    }
    const summarySkills = extractSkillsFrontErichedText(summary);
    const skillsList = _.isArray(summarySkills)
          ? _.map(getUniqId(summarySkills), ({ label, id }) => {
            return {
              id: id,
              label,
            };
          })
          : [];
    const sortedSkillsList = _.sortBy(
      skillsList,
      ({ label }) => getLabelPriority(label) * -1,
    );

    const stack = _.map(sortedSkillsList, ({ id }) => {
      const data = skillDataFromId[id];
      const name = (data || {}).name ? data.name : { default: id };
      return {
        name: name,
        ...data && data.icon && { icon: data.icon },
        ...data && data.detailsURL && { detailsURL: data.detailsURL }
      }
    });
    return stack;
  }

  const summaryStack = getSummaryStack();

  const augmentExperience = (exp) => {
    const hash = getAugmentedElementHash(
      (exp.title || '') +
        (exp.companyName || '') +
        (exp.description || ''),
    );

    // with enriched text
    const getSkillsFromEnrichedExperience = (enrichedXP) => {
      const skills = [
          ...extractSkillsFrontErichedText(enrichedXP.descriptionHTMLEnriched),
          ...extractSkillsFrontErichedText(enrichedXP.experienceNameEnriched),
      ];

      const skillsList = _.isArray(skills)
            ? _.map(getUniqId(skills), ({ label, id }) => {
              return {
                skill: id,
                //icon: findIconLink(id),
                label,
                //bgColor: findColorForSkillRelevance(label),
              };
            })
            : [];
      const sortedSkillsList = _.sortBy(
        skillsList,
        ({ label }) => getLabelPriority(label) * -1,
      );
      return sortedSkillsList;
    }
    const enrichedExperience = enrichedExperienceFromHash[hash];
    const skills = enrichedExperience && getSkillsFromEnrichedExperience(enrichedExperience);

    const stack = _.map(skills, ({ skill }) => {
      const data = skillDataFromId[skill];
      const name = (data || {}).name ? data.name : { default: skill };
      return {
        name: name,
        ...data && data.icon && { icon: data.icon },
        ...data && data.detailsURL && { detailsURL: data.detailsURL }
      }
    });

    // with mergedWorkplace
    const augmentedExperience =
          hash &&
          augmentedExperiencesAndEducation &&
          (augmentedExperiencesAndEducation.experiences || {})[hash];

    const workplace = (augmentedExperience || {}).mergedWorkplace && (
      wrapWorkplace(augmentedExperience.mergedWorkplace)
    );

    return {
      ...exp,
      ...workplace && { workplace },
      ...!_.isEmpty(stack) && { stack }
    }
  }

  const initialValues = {
    // ...profile && profile.data,
    headline: headline || '',
    summary: summary || '',
    summaryStack: summaryStack,
    location: location || '',
    mainEducation,
    photoLink: {
      value: photoLink || '',
      options: photoLinkOptions,
    },
    relevantTags,
    sources,
    linkedinSkills: [
        ...(sourceData && sourceData.linkedin && sourceData.linkedin.skills
            ? _.compact(
              _.map(
                sourceData.linkedin.skills,
                (skill) =>
                  skill &&
                  skill.label &&
                  skill.label.default && {
                    skill,
                    edit: {
                      removed: false,
                      isTarget: (skill.relevance || 0) > 0,
                    },
                  },
              ),
            )
            : []),
    ],
    experiences: _.map(experiences, (e, i) => ({
      exp: augmentExperience(e),
      selected: true,
    })),
    education: _.map(education, (e, i) => ({
      edu: e,
      selected: true,
    })),
    githubRepositories: _.map(
      sourceData && sourceData.github && sourceData.github.repositories,
      (r, i) => ({
        repo: r,
        selected: i < 4,
      }),
    ),
    stackoverflowRelevantFacts: _.map(
      sourceData &&
        sourceData.stackoverflow &&
        sourceData.stackoverflow.relevantFacts,
      (r, i) => ({
        relevantFact: r,
        selected: i < 2,
      }),
    ),
    tags,
    relevantActivities,
  }

  return (
    <div>
      <EmailHeader profile={profile} />
      <List selection>
        {_.map(_.compact(profile.listEmails), (e, i) => (
          <List.Item
            key={i}
            active={uploadEmail === (e.sanitized || e.email)}
            onClick={() => onClickEmail(e.sanitized || e.email)}
          >
            <p>
              <Icon
                name={
                  uploadEmail === (e.sanitized || e.email)
                    ? 'check square'
                    : 'mail outline'
                }
              />
              {!e.sanitized && <span>[no data from API]</span>}
              {_.contains(profile.bounceEmails, e.email) ? (
                <Popup
                  trigger={
                    <span style={{ color: 'red' }}>
                      {e.sanitized || e.email}{' '}
                    </span>
                  }
                  content={'bounce'}
                />
              ) : _.contains(suspiciousEmails, e.email) ? (
                <Popup
                  trigger={
                    <span style={{ color: 'orange' }}>
                      {e.sanitized || e.email}{' '}
                    </span>
                  }
                  content={'professional email'}
                />
              ) : (
                <span>{e.sanitized || e.email} </span>
              )}{' '}
              {e.sanitized && e.sanitized !== e.email && `[from ${e.email}]`} (
              {e.source}) <Copy text={e.sanitized || e.email} />
              <i>#send:{e.nbSends},</i>
              {!_.contains(profile.bounceEmails, e.email) && (
                <i {...e.nbAnswers > 0 && { style: { color: 'green' } }}>
                  {' '}
                  #answer:{e.nbAnswers}
                </i>
              )}
            </p>
          </List.Item>
        ))}
        {uploadEmail &&
        uploadEmail &&
        _.every(
          _.compact(profile.listEmails),
          ({ email, sanitized }) =>
            uploadEmail !== email && (!sanitized || uploadEmail !== sanitized),
        ) ? (
          <List.Item description={uploadEmail} active icon="check square" />
        ) : null}
        <List.Item>
          <SInput
            size="small"
            placeholder="Custom email"
            fluid
            onChange={(e) => onClickEmail(e.target.value.toLowerCase())}
          />
        </List.Item>
      </List>

      <ExtraFields
        mainEducationOptions={mainEducationOptions}
        profile={profile}
        onUpdateParameters={onUpdateParameters}
        initialValues={initialValues}
      />
    </div>
  );
};
