import _ from 'underscore';

export const getDateObjFromRaw = (raw) => {
  const [yearString, monthString] = getYearMonthFromRawDate(raw);
  const year = (yearString && parseInt(yearString, 10)) || null;
  const month = (monthString && parseInt(monthString, 10)) || null;
  return {
    raw,
    month,
    year,
  };
};

export const getYearMonthFromRawDate = (raw) => {
  if (!raw) {
    return [];
  } else if (raw === '?') {
    return [];
  } else if (_.isString(raw)) {
    return raw.split('-');
  } else {
    return [];
  }
};
