import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Grid, Table, Header, List, Button } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';
import { TARGET_COLLECTIONS } from './TargetDefinitions';
import Modal from './Modal';

// Actions
const SET_COLLECTION = 'SET_COLLECTION';
const setCollection = (collection) => ({ type: SET_COLLECTION, collection });

const SET_SAMPLE = 'SET_SAMPLE';
const setSample = (sample) => ({ type: SET_SAMPLE, sample });

// Reducers
const collection = (state = null, action) => {
  switch (action.type) {
    case SET_COLLECTION:
      return action.collection;
    default:
      return state;
  }
};

const sample = (state = null, action) => {
  switch (action.type) {
    case SET_COLLECTION:
      return null;
    case SET_SAMPLE:
      return action.sample;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  collection,
  sample,
});

// Store
const store = createStore(rootReducer);

// Components
class Targets extends Component {
  render() {
    const {
      collection,
      sample,
      setCollection,
      onDrop,
      onSample,
      onClose,
    } = this.props;

    return (
      <div>
        {sample ? (
          <Modal
            headerText="Target sample"
            submitText="Close"
            cancelText=""
            onSubmit={onClose}
            onCancel={onClose}
          >
            <pre>{JSON.stringify(sample, null, '  ')}</pre>
          </Modal>
        ) : null}
        <Grid>
          <Grid.Column width={4}>
            <List bulleted>
              {_.map(TARGET_COLLECTIONS, (collectionName, i) => (
                <List.Item key={i}>
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setCollection(collectionName);
                    }}
                  >
                    {collectionName}
                  </a>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column width={12}>
            {collection ? (
              <div>
                <Header as="h2">
                  {collection.collectionName}
                  &nbsp; &nbsp;
                  <Button
                    size="tiny"
                    color="red"
                    onClick={() => onDrop(collection.collectionName)}
                  >
                    Drop
                  </Button>
                </Header>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Definition name</Table.HeaderCell>
                      <Table.HeaderCell>Total loaded</Table.HeaderCell>
                      <Table.HeaderCell>&lt; 1 day</Table.HeaderCell>
                      <Table.HeaderCell>1 - 5 days</Table.HeaderCell>
                      <Table.HeaderCell>5 - 15 days</Table.HeaderCell>
                      <Table.HeaderCell>15 - 30 days</Table.HeaderCell>
                      <Table.HeaderCell>30 - 60 days</Table.HeaderCell>
                      <Table.HeaderCell>> 60 days</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.map(collection.results, (result, i) => (
                      <Table.Row
                        key={i}
                        onClick={() =>
                          onSample(collection.collectionName, result.id)
                        }
                      >
                        <Table.Cell>{result.name}</Table.Cell>
                        <Table.Cell>{result.total}</Table.Cell>
                        <Table.Cell>{result.counts[0]}</Table.Cell>
                        <Table.Cell>{result.counts[1]}</Table.Cell>
                        <Table.Cell>{result.counts[2]}</Table.Cell>
                        <Table.Cell>{result.counts[3]}</Table.Cell>
                        <Table.Cell>{result.counts[4]}</Table.Cell>
                        <Table.Cell>{result.counts[5]}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <p>Select a collection</p>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

// Containers
const mapSTargets = (state) => ({
  collection: state.collection,
  sample: state.sample,
});

const mapDTargets = (dispatch) => ({
  setCollection: async (collectionName) => {
    const results = (await axios.get(
      `${baseUrl}/targets/stats/${collectionName}`,
    )).data;
    dispatch(setCollection({ collectionName, results }));
  },
  onDrop: async (collectionName) => {
    await axios.get(`${baseUrl}/targets/drop/${collectionName}`);
    const results = (await axios.get(
      `${baseUrl}/targets/stats/${collectionName}`,
    )).data;
    dispatch(setCollection({ collectionName, results }));
  },
  onSample: async (collectionName, definitionId) => {
    const sample = (await axios.get(
      `${baseUrl}/targets/sample/${collectionName}/${definitionId}`,
    )).data;
    dispatch(setSample(sample));
  },
  onClose: () => dispatch(setSample(null)),
});

const TargetsContainer = connect(
  mapSTargets,
  mapDTargets,
)(Targets);

export default () => (
  <Provider store={store}>
    <TargetsContainer />
  </Provider>
);
