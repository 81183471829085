import _ from 'underscore';
import React from 'react';
import { Container, Header, Label, Segment, Tab } from 'semantic-ui-react';
import TagsView from '../TagsView';
import { Link } from 'react-router-dom';
import { EditOmegaCriteria, schemaCriteriaToFormCriteria } from '../Sales/SalesBoard/EditOmegaCriteria';
import { SweetForm } from 'sweetform';

const OmegaCriteria = ({ hiresweetOffer }) => {
  const criteria = hiresweetOffer.criteria;
  if (!criteria) {
    return <div>No criteria</div>;
  }
  const formatedCriteria = schemaCriteriaToFormCriteria(criteria);
  return (
    <SweetForm initialValues={{ criteria: formatedCriteria }}>
      <EditOmegaCriteria field="criteria" />
    </SweetForm>
  );
};

const CurrentCriteria = ({ hiresweetOffer }) => {
  const offer = hiresweetOffer;
  return (
    <div>
      <TagsView tags={[['location', offer.location]]} />
      <TagsView tags={[['job position', offer.jobId]]} />
      <TagsView
        tags={[
          ['experience', offer.experience ? `${offer.experience.min} - ${offer.experience.max}` : null],
          ['top school', offer.topSchool ? 'True' : 'False'],
        ]}
      />
      <Container>
        {_.map(['required', 'important', 'bonus'], (key) => {
          if (offer.skills[key] && offer.skills[key].length > 0) {
            return (
              <div key={key}>
                <b>{key}</b>
                <TagsView tags={offer.skills[key]} />
              </div>
            );
          }
        })}
      </Container>
    </div>
  );
};

const OfferInformations = ({ hiresweetOffer }) => {
  return (
    <div>
      <TagsView
        tags={[
          ['users', hiresweetOffer.users && hiresweetOffer.users.join(', ')],
          ['platform id', hiresweetOffer.platformId],
        ]}
      />
      <TagsView
        tags={[
          ['contract', hiresweetOffer.contractType],
          ['responsibilities', hiresweetOffer.responsibilities],
          [
            'salary',
            hiresweetOffer.salaryRange && `${hiresweetOffer.salaryRange.min} - ${hiresweetOffer.salaryRange.max}`,
          ],
        ]}
      />
    </div>
  );
};

const OfferHeader = ({ hiresweetOffer }) => (
  <div>
    <Header as="h2">
      <Link to={`/offers/${hiresweetOffer.id}`}>
        {hiresweetOffer.title} @ {hiresweetOffer.companyId}
      </Link>
    </Header>
  </div>
);

class HiresweetOffer extends React.PureComponent {
  render() {
    const { hiresweetOffer } = this.props;

    const panes = [
      {
        menuItem: 'Omega',
        render: () => (
          <Tab.Pane>
            <OmegaCriteria hiresweetOffer={hiresweetOffer} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Old',
        render: () => (
          <Tab.Pane>
            <CurrentCriteria hiresweetOffer={hiresweetOffer} />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Segment>
        <Label attached="top">{hiresweetOffer.id}</Label>
        <OfferHeader hiresweetOffer={hiresweetOffer} />
        <OfferInformations hiresweetOffer={hiresweetOffer} />
        <Tab panes={panes} />
      </Segment>
    );
  }
}

export default HiresweetOffer;
