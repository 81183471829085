import React, { Component } from 'react';
import ActionsView from '../Station/actions';

// Components
class MyActionView extends Component {
  render() {
    return (
      <ActionsView
        restrictedUsers={this.props.username ? [this.props.username] : []}
      />
    );
  }
}

export default MyActionView;
