import { Route, Switch } from 'react-router-dom';

import PoolDashboard from './PoolDashboard';
import PoolManager from './PoolManager';
import PoolSearch from './PoolSearch';
import React from 'react';

class FastSearchRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/ml/old/fast-search'>
          <PoolDashboard />
        </Route>
        <Route exact path='/ml/old/fast-search/search/:poolId'>
          {({ match }) => <PoolSearch poolId={((match || {}).params || {}).poolId} />}
        </Route>
        <Route exact path='/ml/old/fast-search/manage/:poolId'>
          {({ match }) => <PoolManager poolId={((match || {}).params || {}).poolId} />}
        </Route>
      </Switch>
    );
  }
}

export default FastSearchRouter;
