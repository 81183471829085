import _ from 'underscore';
import React from 'react';
import { Divider, Segment, Label, Icon, Header, Tab } from 'semantic-ui-react';

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

const Experience = ({ experience }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Experience
    </Divider>

    <div style={rowStyle}>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div>
          Target:{' '}
          <Label color='blue'>
            {(experience.target || {}).min} - {(experience.target || {}).max}{' '}
          </Label>
        </div>
      </div>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div>
          Ok with:{' '}
          <Label color='blue'>
            {(experience.okWith || {}).min} - {(experience.okWith || {}).max}
          </Label>
        </div>
      </div>
    </div>
  </div>
);

const Locations = ({ locations }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Locations
    </Divider>
    <div style={rowStyle}>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div style={{ marginRight: '2px' }}>Target:</div>
        {_.map(locations.target, ({ location, remoteWish }, i) => (
          <div key={i}>
            <Label color='blue'>{remoteWish} </Label>
            <Label color='blue'>{location.id}</Label>
          </div>
        ))}
      </div>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div style={{ marginRight: '2px' }}>Ok with: </div>
        {_.map(locations.okWith, ({ location, remoteWish }, i) => (
          <div key={i}>
            <Label color='blue'>{remoteWish}</Label>
            <Label color='blue'>{location.id}</Label>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Background = ({ backgroundSolidity, importance, startupnessImportance, schoolPrestige }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Background (Importance: {importance})
    </Divider>
    <div style={rowStyle}>
      <div style={columnStyle}>
        <div style={{ marginBottom: '1em' }}>
          <div style={{ fontWeight: 'bold' }}>Solidity</div>
          {backgroundSolidity.disabled ? (
            <div>
              <Label color='red'>Disabled</Label>
            </div>
          ) : (
            <div style={rowStyle}>
              <div style={{ width: '50%' }}>
                Target: <Label color='blue'>{(backgroundSolidity.target || {}).min}</Label>
              </div>
              {(backgroundSolidity.okWith || {}).min !== undefined && (
                <div style={{ width: '50%' }}>
                  Ok with: <Label color='blue'>{(backgroundSolidity.okWith || {}).min}</Label>
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '1em' }}>
          <div style={{ fontWeight: 'bold' }}>SchoolPrestige</div>
          {schoolPrestige.disabled ? (
            <div>
              <Label color='red'>Disabled</Label>
            </div>
          ) : (
            <div style={rowStyle}>
              <div style={{ width: '50%' }}>
                Target:{' '}
                {_.map(schoolPrestige.target, ({ id }, i) => (
                  <Label key={i} color='blue'>
                    {id}{' '}
                  </Label>
                ))}
              </div>
              <div style={{ width: '50%' }}>
                Ok with:{' '}
                {_.map(schoolPrestige.okWith, ({ id }, i) => (
                  <Label key={i} color='blue'>
                    {id}{' '}
                  </Label>
                ))}
              </div>
            </div>
          )}
        </div>
        <div>
          <div style={{ fontWeight: 'bold' }}>Startupness Importance</div>
          {startupnessImportance && <Label color='blue'>{startupnessImportance}</Label>}
        </div>
      </div>
    </div>
  </div>
);

const SkillsContainer = ({ skills }) => (
  <Label.Group
    color='blue'
    style={{
      border: '#dddddd solid 1px',
      borderRadius: '2px',
      marginRight: '2px',
      marginBottom: '2px',
      padding: '4px 0px 0px 4px',
    }}
  >
    {_.map(skills, ({ id }, i) => (
      <Label key={i} color='blue'>
        {id}
      </Label>
    ))}
  </Label.Group>
);

const Skills = ({ skills, importance }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Skills (Importance: {importance})
    </Divider>
    <div style={rowStyle}>
      <div style={{ ...columnStyle, width: '33%' }}>
        <div style={{ fontWeight: 'bold' }}>Main</div>
        {_.map(skills.main, ({ skills }, i) => (
          <SkillsContainer key={i} skills={skills} />
        ))}
      </div>
      <div style={{ ...columnStyle, width: '33%' }}>
        <div style={{ fontWeight: 'bold' }}>Important</div>
        {_.map(skills.important, ({ skills }, i) => (
          <SkillsContainer key={i} skills={skills} />
        ))}
      </div>
      <div style={{ ...columnStyle, width: '33%' }}>
        <div style={{ fontWeight: 'bold' }}>Bonus</div>
        {_.map(skills.bonus, ({ skills }, i) => (
          <SkillsContainer key={i} skills={skills} />
        ))}
      </div>
    </div>
  </div>
);

const Job = ({ jobPosition, importance }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Job Position (Importance: {importance})
    </Divider>
    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Major</div>
        <Label color='blue'>{(jobPosition.major || {}).id}</Label>
      </div>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Minors</div>
        {_.map(jobPosition.minors, ({ id }, i) => (
          <Label color='blue' key={i}>
            {id}
          </Label>
        ))}
      </div>
    </div>
  </div>
);

const Criterion = ({ criterion }) => {
  if (!criterion || !criterion.id) {
    return null;
  }
  const selection = criterion.id;
  const value = criterion.params;
  if (value && value.sons) {
    return (
      <div style={rowStyle}>
        <Label color='green' style={{ marginBottom: '2px' }}>
          {selection}
        </Label>
        <div>
          {_.map(value.sons, (criterion, i) => (
            <Criterion key={i} criterion={criterion} />
          ))}
        </div>
      </div>
    );
  }
  if (value && value.son) {
    return (
      <div style={rowStyle}>
        <Label color='green' style={{ marginBottom: '2px' }}>
          {selection}
        </Label>
        <div>
          <Criterion criterion={value.son} />
        </div>
      </div>
    );
  }
  const displayedValue = value && (_.map(value, (val, key) => `${key}: ${val}`) || []).join(', ');
  return (
    <div>
      <Label color='blue' style={{ marginBottom: '2px' }}>
        {selection}
        {displayedValue && `: (${displayedValue})`}
      </Label>
    </div>
  );
};

const ExtraCriteria = ({ extraCriteria }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Extra Criteria
    </Divider>
    <div>
      {_.map(extraCriteria, ({ importance, criterion }, i) => (
        <div key={i} style={{ ...rowStyle, marginBottom: '1em' }}>
          <div style={{ width: '20%', fontWeight: 'bold' }}>{importance}</div>
          <Criterion criterion={criterion} />
        </div>
      ))}
    </div>
  </div>
);

export const Criteria = ({ criteria }) => (
  <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
    <div>
      <Experience experience={criteria.experience || {}} />
    </div>
    <div>
      <Locations locations={criteria.locations || {}} />
    </div>
    <div>
      <Background
        backgroundSolidity={criteria.backgroundSolidity || {}}
        importance={(criteria.importanceWeights || {}).background}
        startupnessImportance={criteria.startupnessImportance || ''}
        schoolPrestige={criteria.schoolPrestige || {}}
      />
    </div>
    <div>
      <Skills skills={criteria.skills || {}} importance={(criteria.importanceWeights || {}).skills} />
    </div>
    <div>
      <Job jobPosition={criteria.jobPosition || {}} importance={(criteria.importanceWeights || {}).jobPosition} />
    </div>
    <div>
      <ExtraCriteria extraCriteria={criteria.extraCriteria || []} />
    </div>
    {criteria.extraCriteria ? (
      <div>
        <pre>{JSON.stringify(criteria.extraCriteria, null, 4)}</pre>
      </div>
    ) : null}
  </div>
);

export const WorkDescription = ({ description }) => (
  <div>
    <div>
      <Role role={(description || {}).role || {}} />
    </div>
    <div>
      <ManagementLevel managementLevel={(description || {}).managementLevel || {}} />
    </div>
    <div>
      <Stack stack={(description || {}).stack || {}} />
    </div>
    <div>
      <Offices offices={(description || {}).offices || {}} />
    </div>
    <div>
      <RemotePolicy remotePolicy={(description || {}).remotePolicy || {}} />
    </div>
    <div>
      <Contract contract={(description || {}).contract || {}} />
    </div>
    <div>
      <Salary salaryRange={(description || {}).salaryRange || {}} />
    </div>
  </div>
);

const Role = ({ role }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Role
    </Divider>
    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Major</div>
        <Label color='blue'>{(role.major || {}).id}</Label>
      </div>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Minors</div>
        {_.map(role.minors, ({ id }, i) => (
          <Label color='blue' key={i}>
            {id}
          </Label>
        ))}
      </div>
    </div>
  </div>
);

const ManagementLevel = ({ managementLevel }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Management Level
    </Divider>
    <Label color='blue'>{(managementLevel || {}).id}</Label>
  </div>
);
const Stack = ({ stack }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Stack
    </Divider>
    <div style={rowStyle}>
      <div style={{ ...columnStyle, width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Main</div>
        <SkillsContainer skills={stack.main} />
      </div>
      <div style={{ ...columnStyle, width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Secondary</div>
        <SkillsContainer skills={stack.secondary} />
      </div>
    </div>
  </div>
);
const Offices = ({ offices }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Offices
    </Divider>
    {_.map(offices, ({ location }, i) => (
      <Label color='blue' key={i}>
        {(location || {}).id}
      </Label>
    ))}
  </div>
);
const RemotePolicy = ({ remotePolicy }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Remote Policy
    </Divider>
    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Target</div>
        {_.map(remotePolicy.target, ({ id }, i) => (
          <Label color='blue' key={i}>
            {id}
          </Label>
        ))}
      </div>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Ok With</div>
        {_.map(remotePolicy.okWith, ({ id }, i) => (
          <Label color='blue' key={i}>
            {id}
          </Label>
        ))}
      </div>
    </div>
  </div>
);
const Contract = ({ contract }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Contract
    </Divider>
    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Target</div>
        {_.map(contract.target, ({ id }, i) => (
          <Label color='blue' key={i}>
            {id}
          </Label>
        ))}
      </div>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Ok With</div>
        {_.map(contract.okWith, ({ id }, i) => (
          <Label color='blue' key={i}>
            {id}
          </Label>
        ))}
      </div>
    </div>
  </div>
);
const Salary = ({ salaryRange }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Salary
    </Divider>
    <Label color='blue'>
      {salaryRange.min || ''} - {salaryRange.max || ''} ({salaryRange.currency || ''})
    </Label>
  </div>
);
const Offer = ({ offer }) => {
  const { admin } = offer;
  const { criteria, description } = offer.search || {};
  const panes = [
    {
      menuItem: { key: 'criteria', icon: 'search', content: 'Criteria' },
      render: () => (
        <Tab.Pane>
          <Criteria criteria={criteria || {}} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: 'description', icon: 'file', content: 'Description' },
      render: () => (
        <Tab.Pane>
          <WorkDescription description={description} />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Segment color='blue'>
      <h2>
        {(((description || {}).title || {}).default || {}).text} @{(admin || {}).companyId}
      </h2>
      <Tab panes={panes} />
    </Segment>
  );
};

export default Offer;
