import { formatDisciplines, formatEntities } from './utils';

const formatContent = (listOfStrings) => {
  return listOfStrings.join(" ")
}

const formatJob = ({id, data}) => ({
  id,
  creation: data['listing_created'],
  meta: {
    nbSkills: data['Number_Skills_Listing'],
    atsStatus: data['ATS_Status'],
    longitude: data['Listing_Longitude'],
    latitude: data['Listing_Latitude'],
    function: data['Listing_Function'],
    category: data['Listing_Category'],
    sector: data['Listing_Sector'],
  },
  company: data['Listing_Company'],
  title: {
    text: data['Listing_Jobtitle_Raw'],
    entities: formatEntities({
      norms: data['Listing_Jobtitle_Norm'],
      conceptIds: data['Listing_Jobtitle_Concept ID'],
      lemmaIds: data['Listing_Jobtitle_Lemma ID'],
    }),
  },
  disciplines: formatDisciplines({
    ids: data['Listing_Disc_ID'],
    norms: data['Listing_Disc_Norm'],
  }),
  subDisciplines: formatDisciplines({
    ids: data['Listing_Subdisc_ID'],
    norms: data['Listing_Subdisc_Norm'],
  }),
  skills: {
    texts: data['Listing_Skills_Raw'],
    entities: formatEntities({
      norms: data['Listing_Skills_Norm'],
      conceptIds: data['Listing_Skills_Concept_ID'],
      lemmaIds: data['Listing_Skills_Lemma_ID'],
    }),
  },
  content: formatContent(data['Listing_Content']),
  contentHtml: data['listing_description'],
  formattedContent: data.formattedContent,
})

export default formatJob;