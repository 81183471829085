import _ from 'underscore';
import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

import TalentSearch from './TalentSearch';
import JobSearch from './JobSearch';

class Search extends Component {
  componentDidMount() {}

  handleChangeActiveMenu(activeMenu) {
    this.setState({ activeMenu: activeMenu });
  }

  render() {
    const activeMenu = (this.state || {}).activeMenu || 'Talent Search';
    const { profileCollections, jobCollections } = this.props;
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            key={1}
            name='Talent Search'
            active={activeMenu == 'Talent Search'}
            onClick={() => {
              this.handleChangeActiveMenu('Talent Search');
            }}
          >
            Talent Search
          </Menu.Item>
          <Menu.Item
            key={2}
            name='Job Search'
            active={activeMenu == 'Job Search'}
            onClick={() => {
              this.handleChangeActiveMenu('Job Search');
            }}
          >
            Job Search
          </Menu.Item>
        </Menu>
        {activeMenu == 'Talent Search' ? (
          <TalentSearch profileCollections={profileCollections} jobCollections={jobCollections} />
        ) : activeMenu == 'Job Search' ? (
          <JobSearch profileCollections={profileCollections} jobCollections={jobCollections} />
        ) : null}
      </div>
    );
  }
}

export default Search;
