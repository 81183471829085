import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { Table } from 'semantic-ui-react';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

// Reducers
const instanceData = (state = [], action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  instanceData,
});

// Store
const store = createStore(rootReducer);

const SuspiciousThreadRow = ({ version, nbThreads, index }) => {
  return (
    <Table.Row {...(!version.profileId ? { negative: true } : {})}>
      {index === 0 && <Table.Cell rowSpan={nbThreads}>{version.id}</Table.Cell>}

      <Table.Cell>{version.mailAccountId}</Table.Cell>
      <Table.Cell>{version.profileId || ''}</Table.Cell>
    </Table.Row>
  );
};

class SuspiciousThreads extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { instanceData } = this.props;

    return (
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ThreadId</Table.HeaderCell>
            <Table.HeaderCell>MailAccountId</Table.HeaderCell>
            <Table.HeaderCell>ProfileId</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.flatten(
            _.map(instanceData, (thread) =>
              _.map(thread.versions, (version, index) => (
                <SuspiciousThreadRow
                  key={`${thread.threadId}-${version.mailAccountId}`}
                  version={version}
                  index={index}
                  nbThreads={thread.versions.length}
                />
              )),
            ),
          )}
        </Table.Body>
      </Table>
    );
  }
}

const mapSSuspiciousThreads = (state) => ({
  instanceData: state.instanceData,
});

const loadSuspiciousThreads = async (dispatch) => {
  // const { data } = await axios.get(`${baseUrl}/monitoring/suspiciousthreads`);
  // console.log('data', data);

  const instances = [
    {
      threadId: '1234567890',
      versions: [
        {
          id: '1234567890',
          profileId: 'ben-gi-123456',
          mailAccountId: 'benedicte_hiresweet_com',
        },
        {
          id: '1234567890',
          profileId: null,
          mailAccountId: 'ismael_hiresweet_com',
        },
      ],
    },
  ];
  // const instances = _.sortBy(data, thread => -1 * profile.sentDate)
  dispatch(setInstanceData(instances));
};

const mapDSuspiciousThreads = (dispatch) => ({
  onLoad: async () => {
    await loadSuspiciousThreads(dispatch);
  },
});

const SuspiciousThreadsContainer = connect(
  mapSSuspiciousThreads,
  mapDSuspiciousThreads,
)(SuspiciousThreads);

export default () => (
  <Provider store={store}>
    <SuspiciousThreadsContainer />
  </Provider>
);
