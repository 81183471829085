import React from 'react';
import { Popup, Button, Icon, Input } from 'semantic-ui-react';
import TextInput from './TextInput';
import { TranslatableText } from './TranslatableText';

const RelevantTagRaw = ({ label, content }) => (
  <div className='relevant-tag'>
    <span className='tag-label'>
      <TranslatableText translatableText={label} />
    </span>
    {label && content && <span> • </span>}
    <span className='tag-content'>
      <TranslatableText translatableText={content} />
    </span>
  </div>
);

const RelevantTagEditor = ({ label, content, onRemove }) => (
  <div>
    <div>
      <h3 style={{ marginBottom: '2px' }}>Label</h3>
      <div>
        <span>Default: </span>
        <TextInput
          placeholder='Default'
          name='default'
          value={label.getDefault().get() || ''}
          onChange={({ value }) => label.getDefault().set(value)}
        />
      </div>
      <div>
        <span>English: </span>
        <TextInput
          placeholder='English'
          name='en'
          value={label.getEn().get() || ''}
          onChange={({ value }) => label.getEn().set(value)}
        />
      </div>
      <div>
        <span>French: </span>
        <TextInput
          placeholder='French'
          name='french'
          value={label.getFr().get() || ''}
          onChange={({ value }) => label.getFr().set(value)}
        />
      </div>
    </div>
    <div>
      <h3 style={{ marginBottom: '2px', marginTop: '6px' }}>Content</h3>
      <div>
        <span>Default: </span>
        <TextInput
          placeholder='Default'
          name='default'
          value={content.getDefault().get() || ''}
          onChange={({ value }) => content.getDefault().set(value)}
        />
      </div>
      <div>
        <span>English: </span>
        <TextInput
          placeholder='English'
          name='en'
          value={content.getEn().get() || ''}
          onChange={({ value }) => content.getEn().set(value)}
        />
      </div>
      <div>
        <span>French: </span>
        <TextInput
          placeholder='French'
          name='french'
          value={content.getFr().get() || ''}
          onChange={({ value }) => content.getFr().set(value)}
        />
      </div>
    </div>
    <Button onClick={onRemove}>Remove Tag</Button>
  </div>
);

const RelevantTag = ({ label, content, onRemove }) => (
  <Popup
    on='click'
    wide
    trigger={
      <span>
        <RelevantTagRaw label={label} content={content} />
      </span>
    }
    content={<RelevantTagEditor label={label} content={content} onRemove={onRemove} />}
  />
);

class RelevantTagAdder extends React.Component {
  state = {
    labelDefault: '',
    labelEn: '',
    labelFr: '',
    contentDefault: '',
    contentEn: '',
    contentFr: '',
  };
  onUpdate = (field) => (e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  onSubmit = () => {
    const { onAdd, onClosePopup } = this.props;
    const { labelDefault, labelEn, labelFr, contentDefault, contentEn, contentFr } = this.state;
    onAdd({
      label: {
        default: labelDefault,
        en: labelEn,
        fr: labelFr,
      },
      content: {
        default: contentDefault,
        en: contentEn,
        fr: contentFr,
      },
    });
    onClosePopup();
  };
  render() {
    const { labelDefault, labelEn, labelFr, contentDefault, contentEn, contentFr } = this.state;
    return (
      <div>
        <div>
          <h3 style={{ marginBottom: '2px' }}>Label</h3>
          <div>
            <span>Default: </span>
            <Input
              placeholder='Default'
              autoComplete='off'
              name='default'
              value={labelDefault}
              onChange={this.onUpdate('labelDefault')}
            />
          </div>
          <div>
            <span>English: </span>
            <Input
              placeholder='English'
              name='en'
              autoComplete='off'
              value={labelEn}
              onChange={this.onUpdate('labelEn')}
            />
          </div>
          <div>
            <span>French: </span>
            <Input
              placeholder='French'
              name='french'
              autoComplete='off'
              value={labelFr}
              onChange={this.onUpdate('labelFr')}
            />
          </div>
        </div>
        <div>
          <div>
            <h3 style={{ marginBottom: '2px', marginTop: '6px' }}>Content</h3>
            <span>Default: </span>
            <Input
              placeholder='Default'
              name='default'
              autoComplete='off'
              value={contentDefault}
              onChange={this.onUpdate('contentDefault')}
            />
          </div>
          <div>
            <span>English: </span>
            <Input
              placeholder='English'
              name='en'
              autoComplete='off'
              value={contentEn}
              onChange={this.onUpdate('contentEn')}
            />
          </div>
          <div>
            <span>French: </span>
            <Input
              placeholder='French'
              name='french'
              autoComplete='off'
              value={contentFr}
              onChange={this.onUpdate('contentFr')}
            />
          </div>
        </div>
        <Button positive onClick={this.onSubmit}>
          Add tag
        </Button>
      </div>
    );
  }
}

export class AddRelevantTag extends React.Component {
  state = { open: false };
  onOpenPopup = () => {
    this.setState({ open: true });
  };
  onClosePopup = () => {
    this.setState({ open: false });
  };
  render() {
    const { onAdd } = this.props;
    const { open } = this.state;
    return (
      <Popup
        open={open}
        on='click'
        trigger={
          <div className='relevant-tag' onClick={this.onOpenPopup} style={{ cursor: 'pointer' }}>
            <span className='tag-content'>
              <Icon name='plus' />
            </span>
          </div>
        }
        onClose={this.onClosePopup}
        content={<RelevantTagAdder onAdd={onAdd} onClosePopup={this.onClosePopup} />}
      />
    );
  }
}

export default RelevantTag;
