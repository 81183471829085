import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Container, Button, Form, Modal, Accordion } from 'semantic-ui-react';
import { SweetForm, Input, Select, Checkbox, TextArea } from 'sweetform';
import Copy from '../components/Copy';
import baseUrl from '../baseUrl';

const loadWorkplaces = async (input) => {
  const search = JSON.stringify({ ref: input });
  const { workplaces } = (await axios.get(`${baseUrl}/workplaces?search=${search}`)).data;
  return { options: workplaces };
};

const SelectWorkplaces = (props) => (
  <Select {...props} async={true} loadOptions={loadWorkplaces} labelKey='name' valueKey='id' />
);

class EditClientModal extends Component {
  state = {
    currentValue: this.props.initialValues || {},
    extraTerms: this.props.extraTermsOfServiceConditions,
  };
  handleChangeEdit = (newValue) => {
    this.setState({ currentValue: { ...newValue, maxNbFollowups: +newValue.maxNbFollowups } });
  };
  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { currentValue } = this.state;
    if (!currentValue.id || _.isEmpty(currentValue.name)) {
      return alert('fields id and name are required !');
    }
    if (currentValue.id.indexOf(' ') >= 0) {
      return alert('white spaces are not allowed in ids');
    }
    if (!this.props.edition && currentValue.id.toLowerCase() !== currentValue.id) {
      return alert('upper case characters are not allowed in ids');
    }
    if (currentValue.id.indexOf('/') >= 0) {
      return alert('slashes are not allowed in ids');
    }
    if (currentValue.id.indexOf('/') >= 0) {
      return alert('slashes are not allowed in ids');
    }
    if (!currentValue.id.match(/^[a-z0-9\-_]+$/i)) {
      return alert('only letters, numbers, "-" and "_" are allowed in ids');
    }
    onSubmit(currentValue);
  };
  handleDelete = () => {
    const { onDelete } = this.props;
    const { currentValue } = this.state;
    onDelete(currentValue);
  };
  handleChangeExtraTerms = (value) => {
    this.setState({ extraTerms: value });
  };
  handleToggleAdvancedSettings = () => {
    this.setState({ showAdvancedSettings: !this.state.showAdvancedSettings })
  }
  render() {
    const { initialValues, creationMode = false, onClose } = this.props;
    const { currentValue, showAdvancedSettings } = this.state;

    const exampleTerms =
      'Partenariat X\n\n<b>Article 5</b> Blabla ... seulement 10% \n\n' +
      '<b>Article 6</b> En fonction, blabla: <br/>• hello<br/> • world<br/> donc blabla ';

    const showAdvanced = false;

    return (
      <Modal onClose={onClose} open closeIcon size='large'>
        <Modal.Header>{creationMode ? 'Create Client' : 'Edit Client ' + initialValues.id}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Container>
            <SweetForm initialValues={initialValues} onChange={this.handleChangeEdit}>
              <Form>
                <Form.Field>
                  <label>Identifier</label>
                  {!creationMode ? <Input disabled field='id' /> : <Input field='id' />}
                </Form.Field>
                <Form.Field>
                  <label>Workplace</label>
                  <SelectWorkplaces
                    field='workplaceId'
                    placeholder={
                      currentValue && currentValue.workplaceId
                        ? currentValue.workplaceId
                        : initialValues
                        ? initialValues.workplaceId
                        : 'none'
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Name</label>
                  <Input autoFocus field='name' />
                </Form.Field>
                <Form.Field>
                  <label>Is Fake</label>
                  <Checkbox field='isFake' />
                </Form.Field>
                <Form.Field>
                  <label>Language (fr or en)</label>
                  <Input placeholder='fr' field='language' />
                </Form.Field>

                <Form.Field>
                  <label>Watch Collect Enabled</label>
                  <Checkbox field='watchCollect' />
                </Form.Field>

                <Form.Field>
                  <label>Watch Unified View Enabled</label>
                  <Checkbox field='marketplaceUnifiedView' />
                </Form.Field>

                <Form.Field>
                  <label>Watch Split Pending Enabled</label>
                  <Checkbox field='marketplaceSplitPending' />
                </Form.Field>

                <Form.Field>
                  <label>Activate Reveal</label>
                  <Checkbox field='reveal' />
                </Form.Field>
                {_.isEmpty(this.state.currentValue.revealProjects) ? (
                  this.state.currentValue.reveal ? (
                    <div>
                        <Form.Field>
                        <label>Init with an empty reveal setup (recommended)</label>
                              <Checkbox field='createRevealProject' />
                          </Form.Field>
                    </div>
                  ) : ''
                ) : ''}

                <Form.Field>
                  <label>Diversity analytics</label>
                  <Checkbox field='diversityAnalytics' />
                </Form.Field>
                <Form.Field>
                  <label>Advanced templating</label>
                  <Checkbox field='advancedTemplating' />
                </Form.Field>

                <Form.Field>
                  <label>Can Ask Enrichment Credits</label>
                  <Checkbox field='canAskEmailEnrichmentCredits' />
                </Form.Field>

                {this.state.currentValue.canAskEmailEnrichmentCredits && (
                  <div style={{ paddingLeft: 10 }}>
                    <span> Define the number of credits to allow. It will be the min of the first field and the nbPerUser x the second field. For an easy use, just set the first one.</span>
                    <Form.Field>
                      <label>Total max for client (default is 250)</label>
                      <Input type='number' field='maxEnrichmentMonthlyAmountGivenForClient' />
                    </Form.Field>
                    <Form.Field>
                      <label>Additionnal limit per user (default is 50)</label>
                      <Input type='number' field='defaultEnrichmentMonthlyAmountGivenPerUser' />
                    </Form.Field>
                  </div>
                )}

                <Accordion>
                  <Accordion.Title
                    active={showAdvancedSettings}
                    content='Advanced settings'
                    index={0}
                    onClick={() => { this.handleToggleAdvancedSettings() }}
                  />
                  <Accordion.Content active={showAdvancedSettings}>
                    <Form.Field>
                      <label>Max NB Followups</label>
                      <Input type='number' field='maxNbFollowups' />
                    </Form.Field>
                    <Form.Field>
                      <label>Second Follow-up Enabled</label>
                      <Checkbox field='secondFollowup' />
                    </Form.Field>
                  
                    <Form.Field>
                      <label>Display Terms of Service page</label>
                      <Checkbox field='termsOfService' />
                    </Form.Field>
                    <Form.Field>
                      <label>Friend Companies</label>
                      <Input placeholder='Blablacar, Meero, 360Learning, 360 Learning' field='blacklistedCompanies' />
                    </Form.Field>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>
                              Extra Terms Of Service
                              {/* eslint-disable-next-line */}
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  console.log(exampleTerms);
                                  alert('(also displayed in console)\n\n' + exampleTerms);
                                }}
                              >
                                {' '}
                                (See Example)
                              </a>
                              <Copy text={exampleTerms} style={{ marginLeft: 5 }} />
                            </label>
                            <TextArea field='extraTermsOfServiceConditions' rows={10} />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          {_.map(
                            (this.state.currentValue.extraTermsOfServiceConditions || '').split('\n\n'),
                            (block, i) => (
                              <span
                                style={{ display: 'block', marginTop: '10px' }}
                                key={i + '_extra'}
                                dangerouslySetInnerHTML={{
                                  __html: block + '<br/>',
                                }}
                              />
                            ),
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    {!creationMode && (
                      <Form.Field>
                        <label>Write "DELETE" to enable the delete button</label>
                        <Input field='deleteField' />
                      </Form.Field>
                    )}
                  </Accordion.Content>
                </Accordion>  
              </Form>
            </SweetForm>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          {!creationMode && currentValue && currentValue.deleteField === 'DELETE' && (
            <Button negative onClick={this.handleDelete}>
              DELETE
            </Button>
          )}
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={this.handleSubmit}>
            {creationMode ? 'Create' : 'Edit'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditClientModal;
