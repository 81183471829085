import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Rectangle } from 'react-google-maps';

export default class GMaps extends React.Component {

  render() {
    const bounds = {
      north: +this.props.box['minLatitude'],
      south: +this.props.box['maxLatitude'],
      west: +this.props.box['minLongitude'],
      east: +this.props.box['maxLongitude'],
    };
    // const { center, zoom } = fitBounds(bounds, size);
    const Map = compose(
      withProps({
        googleMapURL:
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAmlwt_tI6GkJTPB13r3hB3h1WlMGKdzSA&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%`, minHeight: `100px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap,
    )(() => (
      <GoogleMap defaultZoom={10} defaultCenter={{ lat: +this.props.box['minLatitude'], lng: +this.props.box['minLongitude'] }}>
        {this.props.box ? <Rectangle bounds={bounds} /> : ''}
      </GoogleMap>
    ));
    return <Map />;
  }
}
