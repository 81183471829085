import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Icon,
  List,
  Button,
  Table,
  Grid,
  Modal,
  Segment,
  Header,
  Loader,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import { withExternalPush } from './Offers/WorkPipeInputComponents/ExternalPushModal';
import { LinkedinProfile } from './Offers/WorkPipeInputComponents/LinkedinProfile';
import baseUrl from './baseUrl.js';

const getSignalContent = (type, signal) => {
  const { pattern, reference, updates, sentence } = signal;
  if ((type === "summary-patterns") || (type === "headline-patterns") ){
    return <p> {pattern} </p>
  } else if (type === "xp-length") {
    return <p> {reference} </p>
  } else if (type === "linkedin-update") {
    return (
    <List>{
      _.map(updates, (update, index) => <List.Item key={index}> {update.type} </List.Item>)
    }
    </List>
    )
  } else if (type === "sentence") {
    return (
    <div>
      <p> {sentence} </p>
    </div>
    )
  }
}

const SignalsBox = ({ signalAndType, reportSentence}) => {
  const { type, signal, date } = signalAndType || {};
  return (
    <Segment>
      <Grid>
        <Grid.Column width={14}>
          <Header>
            {new Date(date).toISOString().slice(2, 10)} {type}
          </Header>
        </Grid.Column>
        {
          (type === 'sentence') ? (
            <Grid.Column width={2}>
              <Button size='mini' icon='bell' secondary onClick={() => reportSentence(signal)}/>
            </Grid.Column>
          ) : null
        }
      </Grid>
      {getSignalContent(type, signal)}
    </Segment>
  );
};

class CategoryField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [...this.props.options],
    };
  }

  onChange = (checked, option) => {
    const { values } = this.state;
    const { onChange, name } = this.props;
    const newValues = checked ? [...values, option] : _.without(values, option);
    this.setState({ values: newValues });
    onChange({ [name]: newValues });
  };

  onChangeAll = (all) => {
    const { onChange, name, options } = this.props;
    const newValues = all ? [...options] : [];
    this.setState({ values: newValues });
    onChange({ [name]: newValues });
  };

  render() {
    const { name, options } = this.props;
    const { values } = this.state;
    return (
      <div style={{ marginBottom: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>{name}</span>
        <span
          style={{ color: 'blue', cursor: 'pointer', margin: '3px' }}
          onClick={() => this.onChangeAll(true)}
        >
          all
        </span>
        <span
          style={{ color: 'blue', cursor: 'pointer', margin: '3px' }}
          onClick={() => this.onChangeAll(false)}
        >
          no
        </span>
        {_.map(options, (option, index) => (
          <Button
            key={index}
            {..._.contains(values, option) && { primary: true }}
            onClick={() => this.onChange(!_.contains(values, option), option)}
          >
            {option + ''}
          </Button>
        ))}
      </div>
    );
  }
}
/**
 * <Checkbox
    key={index}
    label={option + ''}
    style={{ margin: '3px' }}
    checked={_.contains(values, option)}
    onChange={(e, { checked }) => this.onChange(checked, option)}
  />
 */

class FormFilters extends Component {
  render() {
    const { profiles, fields, onChange } = this.props;
    const fieldsOptions = _.map(fields, (field) => ({
      name: field,
      options: _.sortBy(_.uniq(_.flatten(_.pluck(profiles, field))), (o) => o),
    }));
    return (
      <Segment>
        {_.map(
          fieldsOptions,
          (field, index) =>
            !_.isEmpty(field.options) && (
              <CategoryField
                key={index}
                name={field.name}
                options={field.options}
                onChange={onChange}
              />
            ),
        )}
      </Segment>
    );
  }
}

const getHistoryLabel = (offersPartition) => {
  const nbSentOffers = ((offersPartition || {}).sentOfferIds || []).length;
  const allOffers = [
    ...((offersPartition || {}).pendingOfferIds || []),
    ...((offersPartition || {}).sentOfferIds || []),
    ...((offersPartition || {}).skippedOfferIds || []),
  ];
  let tags = [];
  if (allOffers.length === 0) {
    tags.push('never-pushed');
  } else {
    if (nbSentOffers === 0) {
      tags.push('always-skipped');
    } else {
      const sent2weeksDates = _.filter(
        (offersPartition || {}).sentOfferIds || [],
        ({ sentDate }) =>
          Date.now() - new Date(sentDate).getTime() <
          2 * 7 * 24 * 60 * 60 * 1000,
      );
      if (sent2weeksDates.length > 0) {
        tags.push('sent-in-the-last-2-weeks');
      }
      if (
        _.uniq(
          _.compact(
            _.pluck(
              (offersPartition || {}).sentOfferIds || [],
              'answerDetected',
            ),
          ),
        ).length === 0
      ) {
        tags.push('never-answered');
      } else {
        tags.push('already-answered');
      }
    }
    const pushed3MonthsDates = _.filter(
      allOffers,
      ({ creationTimestamp }) =>
        Date.now() - creationTimestamp < 3 * 30 * 24 * 60 * 60 * 1000,
    );
    if (allOffers.length > 5 && pushed3MonthsDates.length === 0) {
      tags.push('not-pushed-in-the-last-3-months-but-pushed-more-than-5-times');
    }
  }
  return tags;
};

class Opportunities extends Component {
  state = { filters: {} };
  componentWillMount() {
    this.searchOffersFromProfile = {};
    try {
      // load all offers
      axios.get(`${baseUrl}/offers/list/hiresweet`).then(({ data }) => {
        this.setState({ offers: data });
      });

      axios
        .get(baseUrl + '/opportunities', {
          params: { userCategory: this.props.userCategory },
        })
        .then(({ data }) => {
          if (data.error) {
            return alert(data.error);
          }
          const profiles = _.map(data.profiles || [], (profile) => ({
            ...profile,
            maherCategories: _.uniq([
              ..._.pluck(
                (profile.offersPartition || {}).pendingOfferIds || [],
                'category',
              ),
              ..._.pluck(
                (profile.offersPartition || {}).sentOfferIds || [],
                'category',
              ),
              ..._.pluck(
                (profile.offersPartition || {}).skippedOfferIds || [],
                'category',
              ),
            ]),
            historyLabel: getHistoryLabel(profile.offersPartition),
          }));
          this.setState({ profiles });
        })
        .catch((e) => console.error(e));
    } catch (e) {
      console.error('e2', e);
    }
  }

  postpone = async (linkedinId, nbWeeks, userCategory) => {
    try {
      const nowDate = Date.now();
      const lockedUntil = new Date(nowDate + nbWeeks * 604800000);
      await axios.post(baseUrl + '/opportunities/postpone', {
        linkedinId: linkedinId,
        lockedUntil: lockedUntil,
        userCategory: userCategory,
      });
      const filteredProfiles = _.filter(
        this.state.profiles,
        (profile) => profile.linkedin !== linkedinId,
      );
      this.setState({
        profiles: filteredProfiles,
      });
    } catch (e) {
      console.error(e);
    }
  };

  selectProfile = async (
    linkedinId,
    githubId,
    signals,
    maherTags,
    offersPartition,
    linkedinData,
  ) => {
    this.setState({
      selectedProfiles: {
        ...this.state.selectedProfiles,
        [linkedinId]: true,
      },
      selectedOfferId: null,
      selectedProfile: {
        linkedinId: linkedinId,
        githubId: githubId,
        linkedinData: linkedinData,
        signals: signals,
        maherTags: maherTags,
        offersPartition: offersPartition,
      },
    });

    if (!this.searchOffersFromProfile[linkedinId]) {
      const data = (await axios.post(
        baseUrl + '/reverseSearch/searchActiveOffersFromProfile',
        {
          query: {
            profile: {
              idFields: {
                linkedin: linkedinId,
              },
              maherTags: maherTags,
            },
            onlyRecentOffers: true,
            onlyUserOffers: true,
          },
        },
      )).data;
      const { offers } = data;

      const alreadyPushedPlatformOfferIds = _.flatten([
        _.map(offersPartition.pendingOfferIds, (offer) => offer.id),
        _.map(offersPartition.sentOfferIds, (offer) => offer.id),
        _.map(offersPartition.skippedOfferIds, (offer) => offer.id),
      ]);
      const filteredOffers = _.filter(
        offers,
        (offer) =>
          !(alreadyPushedPlatformOfferIds.indexOf(offer.platformId) > -1),
      );
      this.searchOffersFromProfile[linkedinId] = {
        offers: filteredOffers,
        counts: filteredOffers.length,
      };
    }

    const offers = this.searchOffersFromProfile[linkedinId]['offers'];
    const counts = this.searchOffersFromProfile[linkedinId]['counts'];

    this.setState({
      selectedProfile: {
        linkedinId: linkedinId,
        githubId: githubId,
        linkedinData: linkedinData,
        offers: offers,
        offersCount: counts.offers,
        signals: signals,
        offersPartition: offersPartition,
      },
    });
  };

  closeProfileModal = () => {
    this.setState({
      selectedProfile: null,
    });
  };

  handleExternalPush = async () => {
    try {
      const profile = {
        type: 'profile',
        idFields: {
          linkedin: this.state.selectedProfile.linkedinId,
        },
      };
      await this.props.externalPush.pushProfile(profile, {
        type: 'opportunities',
      });
      alert('profile pushed');
    } catch (e) {
      alert(e.message);
    }
  };

  handleSelectOfferId = async (e, { value }) => {
    const selectedOfferId = value;
    this.setState({ selectedOfferId });
    if (this.state.selectedProfile) {
      const linkedin = (this.state.selectedProfile || {}).linkedinId;
      const github = (this.state.selectedProfile || {}).githubId;

      const [extraDataResult, platformDataResult] = await Promise.all([
        axios.post(`${baseUrl}/profiles/extra-data`, {
          offer: {
            id: selectedOfferId,
          },
          idFields: {
            ...(linkedin && { linkedin }),
            ...(github && { github }),
          },
        }),
        axios.post(`${baseUrl}/stats/profile`, {
          linkedin,
          withImportantActionData: true,
        }),
      ]);
      const extraData = extraDataResult.data;
      const platformData = (platformDataResult.data || {}).results || [];

      const { bounceEmails } = _.reduce(
        platformData,
        (memo, profile) =>
          _.find(
            profile.actions,
            (a) => a.type === 'answer-detected' && a.label === 'bounce',
          )
            ? {
                ...memo,
                bounceEmails: [...memo.bounceEmails, profile.email],
              }
            : memo,
        {
          bounceEmails: [],
        },
      );

      if (linkedin) {
        const emails = _.uniq([
          ...(extraData.hireSweetEmails || []),
          ...(extraData.githubEmail ? [extraData.githubEmail] : []),
        ]);
        const email = _.isEmpty(emails) ? '' : emails[0];
        this.setState({
          uploadProfile: {
            [linkedin]: {
              [selectedOfferId]: {
                extraData,
                platformData,
                ...(email && { email }),
                emails: _.difference(emails, bounceEmails),
              },
            },
          },
        });
      }
    }
  };

  handlePushProfileToSweetsheet = async () => {
    const { selectedOfferId } = this.state;
    const { selectedProfile } = this.state;
    const linkedin = selectedProfile.linkedinId;
    if (!selectedOfferId || !linkedin) {
      return alert('nedd selectedOfferId and linkedin');
    }
    const uploadProfile = ((this.state.uploadProfile || {})[linkedin] || {})[
      selectedOfferId
    ];
    if (!uploadProfile) {
      return alert('no uploadProfile in react state');
    }
    const offerMatch = (uploadProfile.extraData || {}).offerMatch || {};
    const { location, mainEducation, relevantTags, sourceData } = offerMatch;
    const email = uploadProfile.email || '';
    if (!email || email.indexOf('@') < 0) {
      return alert('need a valid email');
    }
    const profileToUpload = {
      source: {
        type: 'opportunities',
      },
      idFields: {
        ...(selectedProfile.linkedinId && {
          linkedin: selectedProfile.linkedinId,
        }),
        ...(selectedProfile.githubId && { github: selectedProfile.githubId }),
        ...(selectedProfile.stackoverflowId && {
          stackoverflow: selectedProfile.stackoverflowId,
        }),
      },
      extraData: {
        email,
        location,
        mainEducation,
        relevantTags,
        sourceData: _.pick(sourceData, 'github', 'linkedin', 'stackoverflow'),
      },
    };
    alert('will upload to ' + selectedOfferId);
    axios
      .post(baseUrl + '/sweetwork/uploadInOfferSweetsheet/' + selectedOfferId, {
        profile: profileToUpload,
      })
      .then(({ data }) => {
        if (data && data.error) {
          return alert(data.error);
        }
        this.setState({
          uploadProfile: {
            [linkedin]: {
              [selectedOfferId]: {
                ...uploadProfile,
                uploadResult: {
                  sweetsheetId: (data.sweetsheetItem || {}).sweetsheetId,
                },
              },
            },
          },
        });
        alert(
          'pushed in sweetsheet ' + (data.sweetsheetItem || {}).sweetsheetId,
        );
      })
      .catch((e) => alert(e));
  };

  reportSentence = async (signal) => {
    const {sentence, label} = signal;
    try {
      const input = {
        sentence,
        predictedLabel:label
      }
      const result = (await axios.post(`${baseUrl}/opportunities/reportSentence`, input)).data;
      if (result.success){
        alert('Sentence reported !')
      } else {
        alert(result)
      }
    } catch (e) {
      alert({succes:false, error:e})
    }
  }

  renderOfferSelector = () => {
    const { offers, selectedOfferId } = this.state;
    if (!offers) {
      return <Loader active inline="centered" />;
    }
    const offerOptions = _.map(offers, (offer) => ({
      text: offer.companyId + '-' + offer.title,
      value: offer.id,
    }));
    return (
      <Dropdown
        fluid
        options={offerOptions}
        placeholder="Offer"
        value={selectedOfferId}
        selection
        search
        onChange={this.handleSelectOfferId}
      />
    );
  };

  renderProfileEditor() {
    const { selectedOfferId } = this.state;
    const { selectedProfile } = this.state;
    const linkedin = selectedProfile.linkedinId;
    if (!selectedOfferId || !linkedin) {
      return;
    }
    const uploadProfile = ((this.state.uploadProfile || {})[linkedin] || {})[
      selectedOfferId
    ];
    if (!uploadProfile) {
      return;
    }
    const email = uploadProfile.email;
    const offerMatch = (uploadProfile.extraData || {}).offerMatch || {};
    const emails = uploadProfile.emails || [];
    const otherEmails = _.filter(
      emails,
      (hiresweetEmail) => hiresweetEmail !== email,
    );

    const mainEducation = offerMatch.mainEducation || {};

    return (
      <div key={linkedin + '_' + selectedOfferId}>
        <h3>{selectedOfferId}</h3>
        <span>
          <b>location: </b> {offerMatch.location}
        </span>
        <br />
        <span>
          <b>mainEducation: </b>
          {mainEducation.schoolName} {(mainEducation.startDate || {}).year}-
          {(mainEducation.endDate || {}).year}
        </span>
        <br />
        <div>
          {_.map(offerMatch.relevantTags, (tag, index) => (
            <div
              key={'tag_' + index}
              style={{
                display: 'inline-block',
                background: 'lightgrey',
                padding: 3,
                borderRadius: 2,
                marginLeft: 4,
                marginTop: 4,
              }}
            >
              {tag.label['default']} - {tag.content['default']}
            </div>
          ))}
          <br />
          {_.map(
            ((offerMatch.sourceData || {}).linkedin || {}).skills,
            (skill, index) => (
              <div
                key={'skill_' + index}
                style={{
                  display: 'inline-block',
                  background: 'whitesmoke',
                  padding: 3,
                  borderRadius: 2,
                  marginLeft: 4,
                  marginTop: 4,
                }}
              >
                {skill.label['default']} {skill.endorsementsCount}
              </div>
            ),
          )}
          <br />
          <span>
            <b>email: </b> {email}
          </span>
          <br />
          {!_.isEmpty(otherEmails) && (
            <span>
              <b>other: </b> {otherEmails.join(', ')}
              <br />
            </span>
          )}
          <Input
            value={email}
            onChange={(e, { value }) => {
              this.setState({
                uploadProfile: {
                  [linkedin]: {
                    [selectedOfferId]: {
                      ...uploadProfile,
                      email: value,
                    },
                  },
                },
              });
            }}
          />
          <center>
            {uploadProfile.locked ? (
              <Button disabled>Push</Button>
            ) : uploadProfile.uploadResult ? (
              <a
                target="_blank"
                href={
                  '/flowboard/sweetsheets/id/' +
                  uploadProfile.uploadResult.sweetsheetId
                }
                rel='noopener noreferrer'
              >
                sweetsheet
              </a>
            ) : (email || '').indexOf('.') >= 0 &&
              (email || '').indexOf('@') >= 0 ? (
              <Button
                color="green"
                onClick={() => this.handlePushProfileToSweetsheet()}
              >
                Push
              </Button>
            ) : null}
          </center>
        </div>
      </div>
    );
  }

  renderProfileModal() {
    if (!this.state.selectedProfile) {
      return;
    }
    const profile = this.state.selectedProfile;
    const offers = profile.offers || [];
    const offersCount = profile.offersCount;

    // const selection = this.props.externalPush.selection;

    return (
      <Modal size="fullscreen" onClose={this.closeProfileModal} open closeIcon>
        <Modal.Header>
          <a
            href={`https://linkedin.com/in/${profile.linkedinId}`}
            target="_blank"
            rel='noopener noreferrer'
          >
            {profile.linkedinId}
          </a>
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid columns={3}>
            <Grid.Column width={4}>
              <h2>Signals</h2>
              {_.map(profile['signals'], (signal, index) => {
                return <SignalsBox key={index} signalAndType={signal} reportSentence={this.reportSentence}/>
              })}
              <h2>Offers</h2>
              <Segment>
                {offersCount !== null && offersCount === 0 ? (
                  <span>No offers found.</span>
                ) : null}
                <List divided relaxed>
                  {_.map(offers, (offer, index) => {
                    return (
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        key={index}
                        onClick={() =>
                          this.handleSelectOfferId({}, { value: offer.id })
                        }
                      >
                        <List.Content>
                          <List.Header>
                            [{offer.workplaceId}] {offer.title}
                          </List.Header>
                          <List.List bulleted>
                            {(((offer.skills || {}).required || {}).length ||
                              0) > 0 ? (
                              <List.Item>
                                <b>Required : </b>
                                {((offer.skills || {}).required || []).join(
                                  ', ',
                                )}
                              </List.Item>
                            ) : null}
                            {(((offer.skills || {}).important || {}).length ||
                              0) > 0 ? (
                              <List.Item>
                                <b>Important : </b>
                                {((offer.skills || {}).important || []).join(
                                  ', ',
                                )}
                              </List.Item>
                            ) : null}
                            {offer.location ? (
                              <List.Item>
                                <b>Location : </b>
                                {offer.location}
                              </List.Item>
                            ) : null}
                            {offer.experience ? (
                              <List.Item>
                                <b>Experience : </b>
                                {`${offer.experience.min} - ${
                                  offer.experience.max
                                }`}
                              </List.Item>
                            ) : null}
                          </List.List>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column width={4}>
              <h2>Push</h2>
              {/*     
              {this.props.externalPush.renderSelector()}
              <Button color='green' disabled={!selection || !selection.inputId}
                onClick={this.handleExternalPush}
              >
                Share
              </Button>
              */}
              {this.renderOfferSelector()}
              <br />
              <hr />
              <br />
              {this.renderProfileEditor()}
            </Grid.Column>
            <Grid.Column width={8}>
              {(profile || {}).linkedinData && (
                <LinkedinProfile
                  source={(profile || {}).linkedinData}
                  more={{}}
                  globalHint={null}
                  globalHints={[]}
                  onMore={() => {}}
                  onChangeExperienceHint={() => {}}
                  onChangeGlobalHint={() => {}}
                />
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={this.closeProfileModal}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  scoreProfile(profile) {
    let signalScores = [];
    let date, nDays, daysWeight, updateTypes;
    const today = Date.now();
    const scrapDate = Date.parse('20' + profile['lastScrapDate']);
    _.map(profile.signals, (signal) => {
      if (signal.type === 'linkedin-update') {
        // dynamic signals
        date = Date.parse(signal['new_date']);
        nDays = (today - date) / (1000 * 60 * 60 * 24);
        daysWeight = Math.max((30 - nDays) / 30, 0);
        if (isNaN(daysWeight)) {
          daysWeight = 0;
        }
        updateTypes = _.uniq(
          _.map((signal.signal || {}).updates, (update) => update.type),
        );
        if (_.contains(updateTypes, 'change_summary')) {
          signalScores.push(daysWeight * 3);
        } else if (_.contains(updateTypes, 'change_description')) {
          signalScores.push(daysWeight * 1);
        } else if (_.contains(updateTypes, 'change_company_name')) {
          signalScores.push(daysWeight * 1);
        } else if (_.contains(updateTypes, 'change_headline')) {
          signalScores.push(daysWeight * 1);
        }
      } else {
        // static signals
        nDays = (today - scrapDate) / (1000 * 60 * 60 * 24);
        daysWeight = Math.max((30 - nDays) / 30, 0);
        if (isNaN(daysWeight)) {
          daysWeight = 0;
        }
        if (signal.type === 'sentence') {
          if (signal.signal.reference === 'headline_positive') {
            signalScores.push(daysWeight * 10)
          } else if (signal.signal.reference === 'summary_positive'){
            signalScores.push(daysWeight * 6)
          }
        } else if (signal.type === 'xp-length') {
          if (signal.signal.reference === 'no-open-experience-recent') {
            signalScores.push(daysWeight * 10);
          } else if (signal.signal.reference === 'no-open-experience-medium') {
            signalScores.push(daysWeight * 6);
          } else if (signal.signal.reference === 'internship') {
            signalScores.push(3);
          }
        }
      }
    });

    if (!isNaN(profile.prestige)) {
      signalScores.push(2 * profile.prestige);
    }

    if (signalScores.length === 0) {
      return 0;
    }
    return signalScores.reduce(function(a, b) {
      return a + b;
    }); // somme des éléments
  }

  render() {
    const { userCategory } = this.props;
    const { filters, profiles } = this.state;

    /*** filter ***/
    const filteredProfiles = _.isEmpty(filters)
      ? profiles
      : _.compact(
          _.map(profiles || [], (profile) => {
            let goodProfile = true;
            _.each(filters, (value, key) => {
              if (
                _.intersection(
                  value,
                  _.isArray(profile[key]) ? [...profile[key]] : [profile[key]],
                ).length === 0
              ) {
                goodProfile = false;
              }
            });
            return goodProfile ? profile : null;
          }),
        );
    const sortedProfiles = _.sortBy(
      filteredProfiles,
      (profile) => -this.scoreProfile(profile),
    );
    // const profile_scores = _.map(filteredProfiles, profile => -this.scoreProfile(profile));

    const maherTagsFromProfile = (profile) => {
      const offerIds = profile.offersPartition.sentOfferIds.concat(
        profile.offersPartition.skippedOfferIds.concat(
          profile.offersPartition.pendingOfferIds,
        ),
      );
      const offerIdsWithCategory = _.filter(offerIds, (offerId) => {
        return offerId['category'];
      });
      let categoryCounts = _.countBy(offerIdsWithCategory, (offerId) => {
        return offerId['category'];
      });
      categoryCounts = _.map(categoryCounts, (count, category) => ({
        category: category,
        count: count,
      }));
      return categoryCounts;
    };

    return (
      <div>
        {this.renderProfileModal()}
        <h1>Opportunities ({userCategory}) </h1>
        <div>{sortedProfiles.length} profiles to proceed</div>
        <Grid>
          <Grid.Column width={4}>
            <FormFilters
              profiles={profiles}
              fields={[
                'isFounder',
                'isFreelance',
                'location',
                'experienceYears',
                'jobPositions',
                'maherCategories',
                'historyLabel',
              ]}
              onChange={(filter) =>
                this.setState({ filters: { ...filters, ...filter } })
              }
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Fullname</Table.HeaderCell>
                  <Table.HeaderCell>Headline</Table.HeaderCell>
                  <Table.HeaderCell>Links</Table.HeaderCell>
                  <Table.HeaderCell>Maher</Table.HeaderCell>
                  <Table.HeaderCell>Send/Skip</Table.HeaderCell>
                  <Table.HeaderCell>Last Scrap Date</Table.HeaderCell>
                  <Table.HeaderCell>Signals</Table.HeaderCell>
                  <Table.HeaderCell>Postpone (weeks)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{sortedProfiles.map((profile, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                  <Icon name='eye' size='big' link color={(this.state.selectedProfiles || {})[profile.linkedin] ? 'green' : 'black'} onClick={() => {
                    this.selectProfile(profile.linkedin, profile.github, profile.signals, maherTagsFromProfile(profile), profile.offersPartition, profile.linkedinData)
                  }} />
                    <a
                      target='_blank'
                      style={{ color: 'black' }}
                      href={`/profiles/${profile.linkedin}`}
                      rel='noopener noreferrer'
                    >
                      <Icon name={'spy'} size='big' />
                    </a>
                    {profile['firstname']}
                    {' '}
                    {profile['lastname']}
                  </Table.Cell>
                  <Table.Cell>
                    {profile['headline']}
                  </Table.Cell>
                  <Table.Cell>
                    {profile['linkedin'] && (
                      <Icon size="large" name="linkedin" onClick={() => window.open("https://www.linkedin.com/in/" + profile['linkedin'] + "/", "_blank")} />
                    )}
                    {profile['github'] && (
                      <Icon size="large" name="github" onClick={() => window.open("https://www.github.com/" + profile['github'] + "/", "_blank")} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {_.map(maherTagsFromProfile(profile), (maherTag, index) => (
                      <span key={index}> <b>{maherTag['category']}</b> : {maherTag['count']} <br /> </span>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {profile.offersPartition.sentOfferIds.length}/{profile.offersPartition.skippedOfferIds.length}
                  </Table.Cell>
                  <Table.Cell>
                    {profile['lastSelectDate']}
                  </Table.Cell>
                  <Table.Cell>
                    <div>
                    {_.values(_.mapObject(_.groupBy(profile['signals'], (signal)=>signal.type), (signals, type) => {
                      console.log(type, signals)
                      return (<span> {type} ({signals.length}) <br /></span>)
                    }))}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                      <Button
                        onClick={() => {
                          this.postpone(profile['linkedin'], 1);
                        }}
                      >
                        1
                      </Button>
                      <Button
                        onClick={() => {
                          this.postpone(profile['linkedin'], 2);
                        }}
                      >
                        2
                      </Button>
                      <Button
                        onClick={() => {
                          this.postpone(profile['linkedin'], 4);
                        }}
                      >
                        4
                      </Button>
                      <Button
                        onClick={() => {
                          this.postpone(profile['linkedin'], 8);
                        }}
                      >
                        8
                      </Button>
                      <Button
                        onClick={() => {
                          this.postpone(profile['linkedin'], 100000);
                        }}
                      >
                        Ban
                      </Button>
                      {['florent', 'paul', 'benedicte', 'jerome'].indexOf(
                        this.props.username,
                      ) >= 0 && (
                        <div>
                          <br />
                          <Button
                            onClick={() => {
                              this.postpone(
                                profile['linkedin'],
                                1,
                                userCategory,
                              );
                            }}
                          >
                            1 ({userCategory})
                          </Button>
                          <Button
                            onClick={() => {
                              this.postpone(
                                profile['linkedin'],
                                2,
                                userCategory,
                              );
                            }}
                          >
                            2 ({userCategory})
                          </Button>
                          <Button
                            onClick={() => {
                              this.postpone(
                                profile['linkedin'],
                                4,
                                userCategory,
                              );
                            }}
                          >
                            4 ({userCategory})
                          </Button>
                          <Button
                            onClick={() => {
                              this.postpone(
                                profile['linkedin'],
                                8,
                                userCategory,
                              );
                            }}
                          >
                            8 ({userCategory})
                          </Button>
                          <Button
                            onClick={() => {
                              this.postpone(
                                profile['linkedin'],
                                100000,
                                userCategory,
                              );
                            }}
                          >
                            Ban ({userCategory})
                          </Button>
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer />
            </Table>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

class OpportunitiesDoor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSelectCategory = (userCategory) => {
    this.setState({
      userCategory,
    });
  };
  render() {
    if (!this.state.userCategory) {
      return (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <center>
            <Button
              size={'huge'}
              color="olive"
              onClick={() => this.handleSelectCategory('ops')}
            >
              Ops
            </Button>
            <span style={{ marginRight: 30 }}> </span>
            <Button
              size={'huge'}
              color="blue"
              onClick={() => this.handleSelectCategory('watch')}
            >
              Watch
            </Button>
          </center>
        </div>
      );
    }
    return (
      <Opportunities {...this.props} userCategory={this.state.userCategory} />
    );
  }
}

export default withExternalPush(OpportunitiesDoor);
