import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Segment, Icon } from 'semantic-ui-react';
import { Skills, JobPositions } from '../SweetWork/containers/ProfileScores';

const ExtraCriterion = ({ criterion }) => {
  const getString = (subtree) => {
    const id = subtree.id;
    const params = (subtree.params || {}).params || subtree.params || {};
    const sons = params.son
      ? [params.son]
      : params.son1 && params.son2
      ? [params.son1, params.son2]
      : params.sons
      ? params.sons
      : params.weightedSons
      ? _.map(params.weightedSons, ({ son }) => son)
      : [];

    const baseParamsString = _.reduce(
      params,
      (memo, val, key) => {
        if (['id', 'son', 'sons', 'son1', 'son2', 'weightedSons'].indexOf(key) >= 0) {
          return memo;
        }
        return memo + (memo ? ',' : '') + key + ':' + val;
      },
      '',
    );

    if (id === '#or' && sons.length === 1) {
      return getString(sons[0]);
    }

    return (
      id +
      (baseParamsString ? '(' + baseParamsString + ')' : '') +
      (!_.isEmpty(sons) ? '[' + _.map(sons, getString).join(',') + ']' : '')
    );
  };

  return <span>extra: {getString(criterion)}</span>;
};

class ScoringDetails extends Component {
  render() {
    const scoringDetails = this.props.scoringDetails || {};
    const groupedMagicFilters = [[]];
    _.each(scoringDetails.magicFilters, (value, key) => {
      if (_.last(groupedMagicFilters).length === 3) {
        groupedMagicFilters.push([]);
      }
      _.last(groupedMagicFilters).push({ key, value });
    });

    const groupedExtraCriteria = [[]];
    _.each((scoringDetails.extraCriteria || {}).extraCriteria, (criterion, isValidated) => {
      if (_.last(groupedExtraCriteria).length === 1) {
        groupedExtraCriteria.push([]);
      }
      _.last(groupedExtraCriteria).push({
        criterion: criterion.criterion || criterion,
        isValidated,
      });
    });

    const groupedBricks = [[]];
    _.each(scoringDetails.bricks, (value, key) => {
      if (_.last(groupedBricks).length === 2) {
        groupedBricks.push([]);
      }
      _.last(groupedBricks).push({ key, value });
    });

    const groupedVectors = [[]];
    _.each(scoringDetails.vectors, (value, key) => {
      if (_.last(groupedVectors).length === 2) {
        groupedVectors.push([]);
      }
      _.last(groupedVectors).push({ key, value });
    });

    const profileScores = this.props.profileScores || scoringDetails.profileScores || {};

    return (
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column textAlign='center'>
              <h3>
                Before extra criteria: <span> </span>
                {Math.round(scoringDetails.score_before_extra_criteria * 10000) / 100}%
              </h3>
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <h3>
                Before Rescaling: <span> </span>
                {Math.round(scoringDetails.score_before_rescaling * 10000) / 100}%
              </h3>
            </Grid.Column>
          </Grid.Row>
          {_.map(groupedMagicFilters, (group, iRow) => (
            <Grid.Row
              columns={3}
              key={'mg_' + iRow}
              style={{
                paddingTop: 3,
                paddingBottom: 3,
                ...(iRow === 0 && {
                  borderTop: '1px solid whitesmoke',
                  paddingTop: 10,
                }),
                ...(iRow === groupedMagicFilters.length - 1 && {
                  paddingBottom: 10,
                }),
              }}
            >
              {_.map(group, ({ value, key }, iCol) => (
                <Grid.Column key={'mg_col_' + iCol}>
                  {value ? <Icon name='check' color='green' /> : <Icon name='remove' color='red' />}
                  {' ' + key}
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
          {_.map(groupedExtraCriteria, (group, iRow) => (
            <Grid.Row
              columns={1}
              key={'ec_' + iRow}
              style={{
                paddingTop: 3,
                paddingBottom: 3,
                ...(iRow === 0 && {
                  borderTop: '1px solid whitesmoke',
                  paddingTop: 10,
                }),
                ...(iRow === groupedExtraCriteria.length - 1 && {
                  paddingBottom: 10,
                }),
              }}
            >
              {_.map(group, ({ isValidated, criterion }, iCol) => (
                <Grid.Column key={'ec_col_' + iCol}>
                  {isValidated ? <Icon name='check' color='green' /> : <Icon name='remove' color='red' />}
                  {' (' + isValidated + ') '}
                  <ExtraCriterion criterion={criterion} />
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
          {_.map(groupedBricks, (group, iRow) => (
            <Grid.Row
              columns={2}
              key={'br_' + iRow}
              style={{
                paddingTop: 3,
                paddingBottom: 3,
                ...(iRow === 0 && {
                  borderTop: '1px solid whitesmoke',
                  paddingTop: 10,
                }),
                ...(iRow === groupedBricks.length - 1 && {
                  paddingBottom: 10,
                }),
              }}
            >
              {_.map(group, ({ value, key }, iCol) => (
                <Grid.Column key={'br_col_' + iCol}>
                  {key} : {Math.round(value * 10000) / 100}%
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
          {_.map(groupedVectors, (group, iRow) => (
            <Grid.Row
              columns={2}
              key={'Vc_' + iRow}
              style={{
                paddingTop: 3,
                paddingBottom: 3,
                ...(iRow === 0 && {
                  borderTop: '1px solid whitesmoke',
                  paddingTop: 10,
                }),
                ...(iRow === groupedVectors.length - 1 && {
                  paddingBottom: 10,
                }),
              }}
            >
              {_.map(group, ({ value, key }, iCol) => (
                <Grid.Column key={'br_col_' + iCol}>
                  {key} : {Math.round(value * 10000) / 10000}
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
          {profileScores ? (
            <Grid.Row
              columns={2}
              key={'sd_'}
              style={{
                borderTop: '1px solid whitesmoke',
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Grid.Column>
                <JobPositions jobPositions={profileScores.jobPositions || {}} />
              </Grid.Column>
              <Grid.Column>
                <Skills skills={profileScores.skills || {}} />
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
      </Segment>
    );
  }
}

export default ScoringDetails;
