import { Divider, Label } from 'semantic-ui-react';
import React from 'react';
import _ from 'underscore';
import { Disciplines, Entities, ListProperties, rowStyle, columnStyle } from './utils';


// COMPONENT


const Meta = ({meta}) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Meta
    </Divider>
      <div style={rowStyle}>
        <div style={{ ...columnStyle, width: '33%' }}>
          <p><b>latitude, longitude : </b>{meta.latitude}, {meta.longitude}</p>
         <ListProperties 
          object={meta} 
          properties={["monthsOfExperience", "monthsUnemployed", "averageDurationPerJob"]}
        />
        </div>
        <div style={{ ...columnStyle, width: '33%' }}>
        <ListProperties 
          object={meta} 
          properties={["language","dateOfBirth", "gender", "race", "disability", "educationLevel"]}
          />
        </div>
        <div style={{ ...columnStyle, width: '33%' }}>
        <ListProperties 
          object={meta} 
          properties={["category", "subcategory", "sector"]}
          />
        </div>
    </div>
  </div>
)
const Dates = ({dates}) => {
  const {startDate, endDate, duration, current} = dates;
  return (
    <span>
    <span style={{color: 'grey'}}>{`${(startDate || "").slice(0, 10)} - ${(endDate || "").slice(0, 10)}`}</span>
    •
    <span style={{color: 'green'}}>{`${duration} months`}</span>
    { current && <span>(CURRENT)</span>}
  </span>
  )
}

export const formatBullets = (text) =>((text||"").replaceAll("*", "\n•"))

const Experiences = ({experiences, nbExperiences}) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Experiences ({nbExperiences})
    </Divider>
      <div style={columnStyle}>
        {_.map(experiences, ({title, rawDates, dates, disciplines, subDisciplines, description}, i) => (
          <div key={i}>
            <br />
            <hr />
            <br />
            <b style={{fontSize: 16}}>{(title || {}).text || "Undefined"}</b>
            <p><Dates dates={dates}/> || <Dates dates={rawDates}/> [raw]</p>
            <div style={rowStyle}>
              <div style={{ ...columnStyle, width: '33%' }}>
                <div style={{ fontWeight: 'bold' }}>Title Entities</div>
                <Entities entities={(title || {}).entities || []}/>
              </div>
              <div style={{ ...columnStyle, width: '33%' }}>
                <div style={{ fontWeight: 'bold' }}>Disciplines</div>
                <Disciplines disciplines={disciplines}/>
              </div>
              <div style={{ ...columnStyle, width: '33%' }}>
                <div style={{ fontWeight: 'bold' }}>Sub-disciplines</div>
                <Disciplines disciplines={subDisciplines}/>
              </div>
            </div>
            <span style={{whiteSpace: "pre-line"}}>{formatBullets(description)}</span>
          </div>
        ))}
    </div>
  </div>
)

const Education = ({education, nbEducations}) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Education ({nbEducations})
    </Divider>
    <div style={columnStyle}>
        {_.map(education, ({subject, item, schoolName, year, level}, i) => (
          <div key={i} style={{ marginBottom: '1em' }}>
            <br />
            <hr />
            <br />
            <b style={{fontSize: 16}}>{subject} • {schoolName || "?"}</b>
            <p style={{color:'grey'}}>{level} [{year || "?"}]</p>
            <span style={{whiteSpace: "pre-line"}}>{formatBullets(item)}</span>
          </div>
        ))}
    </div>
  </div>
)
const Skills = ({skills, nbSkills}) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Skills ({nbSkills})
    </Divider>
    <div>
      <Entities entities={skills.entities}/>
    </div>
    <p>{JSON.stringify(skills.texts, null, 4)}</p>
  </div>
)


const XG1FormattedProfile = ({formattedProfile}) => {
  console.log(formattedProfile)
  return (
    <div>
      <Label>{formattedProfile.id}</Label>
      <div>
        <Experiences 
          experiences={formattedProfile.experiences || []} 
          nbExperiences={formattedProfile.meta.nbExperiences}
        />
      </div>
      <div>
        <Education
          education={formattedProfile.education || []} 
          nbEducations={formattedProfile.meta.nbEducations}
        />
      </div>
      <div>
        <Skills 
          skills={formattedProfile.skills || {}}
          nbSkills={formattedProfile.meta.nbSkills}
        />
      </div>
      <div>
        <Meta meta={formattedProfile.meta || {}} />
      </div>
    </div>
  )
}
export default XG1FormattedProfile