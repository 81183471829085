export default [
  {
    'value': 'account-executive',
    'text': 'Account Executive',
    'category': 'other',
  },
  {
    'value': 'account-management',
    'text': 'Account Manager',
    'category': 'other',
  },
  {
    'value': 'artistic-management',
    'text': 'Artistic Director',
    'category': 'design',
  },
  {
    'value': 'web-backend',
    'text': 'Back-End',
    'category': 'software',
  },
  {
    'value': 'business-developer',
    'text': 'Business Developer',
    'category': 'other',
  },
  {
    'value': 'business-engineer',
    'text': 'Business Engineer',
    'category': 'other',
  },
  {
    'value': 'business-intelligence',
    'text': 'Business Intelligence',
    'category': 'other',
  },
  {
    'value': 'crm-manager',
    'text': 'CRM Manager',
    'category': 'other',
  },
  {
    'value': 'cto',
    'text': 'CTO / VP Engineering',
    'category': 'software',
  },
  {
    'value': 'cloud-developer',
    'text': 'Cloud Developer',
    'category': 'software',
  },
  {
    'value': 'commercial',
    'text': 'Commercial',
    'category': 'other',
  },
  {
    'value': 'communication',
    'text': 'Communication',
    'category': 'other',
  },
  {
    'value': 'consultant',
    'text': 'Consultant',
    'category': 'other',
  },
  {
    'value': 'content-manager',
    'text': 'Content Manager',
    'category': 'other',
  },
  {
    'value': 'customer-care',
    'text': 'Customer Care',
    'category': 'other',
  },
  {
    'value': 'customer-success',
    'text': 'Customer Success',
    'category': 'other',
  },
  {
    'value': 'data-analysis',
    'text': 'Data Analyst',
    'category': 'data',
  },
  {
    'value': 'data-engineering',
    'text': 'Data Engineer',
    'category': 'data',
  },
  {
    'value': 'data-science',
    'text': 'Data Scientist',
    'category': 'data',
  },
  {
    'value': 'devops',
    'text': 'DevOps',
    'category': 'software',
  },
  {
    'value': 'developer-advocate',
    'text': 'Developer Advocate',
    'category': 'other',
  },
  {
    'value': 'embedded-systems',
    'text': 'Embedded Systems Engineer',
    'category': 'software',
  },
  {
    'value': 'finance',
    'text': 'Finance',
    'category': 'other',
  },
  {
    'value': 'web-frontend',
    'text': 'Front-End',
    'category': 'software',
  },
  {
    'value': 'full-stack',
    'text': 'Fullstack',
    'category': 'software',
  },
  {
    'value': 'growth_acquisition',
    'text': 'Growth/Acquisition',
    'category': 'other',
  },
  {
    'value': 'hardware-engineer',
    'text': 'Hardware Engineer',
    'category': 'software',
  },
  {
    'value': 'head-of-customer-success',
    'text': 'Head of Customer Success',
    'category': 'other',
  },
  {
    'value': 'head-of-data',
    'text': 'Head of Data',
    'category': 'software',
  },
  {
    'value': 'head-of-finance',
    'text': 'Head of Finance',
    'category': 'other',
  },
  {
    'value': 'head-of-growth',
    'text': 'Head of Growth',
    'category': 'other',
  },
  {
    'value': 'head-of-marketing',
    'text': 'Head of Marketing',
    'category': 'other',
  },
  {
    'value': 'head-of-operations',
    'text': 'Head of Operations',
    'category': 'other',
  },
  {
    'value': 'head-of-people',
    'text': 'Head of People',
    'category': 'other',
  },
  {
    'value': 'head-of-product',
    'text': 'Head of Product',
    'category': 'product',
  },
  {
    'value': 'head-of-sales',
    'text': 'Head of Sales',
    'category': 'other',
  },
  {
    'value': 'human-ressources',
    'text': 'Human Resources',
    'category': 'other',
  },
  {
    'value': 'inside-sales',
    'text': 'Inside Sales',
    'category': 'other',
  },
  {
    'value': 'integrator',
    'text': 'Integrator',
    'category': 'software',
  },
  {
    'value': 'lead-dev',
    'text': 'Lead Dev',
    'category': 'software',
  },
  {
    'value': 'marketer',
    'text': 'Marketing',
    'category': 'other',
  },
  {
    'value': 'mobile-developer',
    'text': 'Mobile Developer',
    'category': 'software',
  },
  {
    'value': 'office-manager',
    'text': 'Office Manager',
    'category': 'other',
  },
  {
    'value': 'operations',
    'text': 'Operations',
    'category': 'other',
  },
  {
    'value': 'partnership-management',
    'text': 'Partnership Manager',
    'category': 'other',
  },
  {
    'value': 'pre-sales',
    'text': 'Pre Sales',
    'category': 'other',
  },
  {
    'value': 'product-design',
    'text': 'Product Designer',
    'category': 'design',
  },
  {
    'value': 'product-engineer',
    'text': 'Product Engineer',
    'category': 'other',
  },
  {
    'value': 'product-management',
    'text': 'Product Manager',
    'category': 'product',
  },
  {
    'value': 'product-marketing-manager',
    'text': 'Product Marketing Manager',
    'category': 'other',
  },
  {
    'value': 'product-owner',
    'text': 'Product Owner',
    'category': 'product',
  },
  {
    'value': 'project-management',
    'text': 'Project Management',
    'category': 'other',
  },
  {
    'value': 'quality-assurance',
    'text': 'QA',
    'category': 'software',
  },
  {
    'value': 'quality-engineer',
    'text': 'Quality Engineer',
    'category': 'software',
  },
  {
    'value': 'quality-tester',
    'text': 'Quality Tester',
    'category': 'software',
  },
  {
    'value': 'recruitment',
    'text': 'Recruitment',
    'category': 'other',
  },
  {
    'value': 'sales',
    'text': 'Sales',
    'category': 'other',
  },
  {
    'value': 'sales-development-representative',
    'text': 'Sales Development Representative',
    'category': 'other',
  },
  {
    'value': 'sales-ops',
    'text': 'Sales Ops',
    'category': 'other',
  },
  {
    'value': 'salesforce-developer',
    'text': 'Salesforce Developer',
    'category': 'other',
  },
  {
    'value': 'scrum-master-_-coach',
    'text': 'Scrum Master / Coach',
    'category': 'other',
  },
  {
    'value': 'secops',
    'text': 'SecOps',
    'category': 'other',
  },
  {
    'value': 'security',
    'text': 'Security Engineer',
    'category': 'software',
  },
  {
    'value': 'site-reliability-engineering',
    'text': 'Site Reliability Engineering',
    'category': 'software',
  },
  {
    'value': 'software-architect',
    'text': 'Software Architect',
    'category': 'software',
  },
  {
    'value': 'software-engineering',
    'text': 'Software Engineer',
    'category': 'software',
  },
  {
    'value': 'solutions-engineer',
    'text': 'Solutions Engineer',
    'category': 'software',
  },
  {
    'value': 'system-administration',
    'text': 'System Administrator',
    'category': 'software',
  },
  {
    'value': 'system-architect',
    'text': 'System Architect',
    'category': 'software',
  },
  {
    'value': 'user-interface',
    'text': 'UI',
    'category': 'design',
  },
  {
    'value': 'user-experience',
    'text': 'UX',
    'category': 'design',
  },
  {
    'value': 'video-games',
    'text': 'Video Games Developer',
    'category': 'software',
  },
  {
    'value': 'web-design',
    'text': 'Web Design',
    'category': 'design',
  },
  {
    'value': 'web-developer',
    'text': 'Web Developer',
    'category': 'software',
  },
];
