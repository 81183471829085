import _ from 'underscore';
import React, { Component } from 'react';

import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Table, Icon, Button, Input } from 'semantic-ui-react';

import baseUrl from '../baseUrl';

// AutoSelect

const SET_AUTO_SELECT_INPUTS = 'SET_AUTO_SELECT_INPUTS';
const setAutoSelectInputs = (inputs) => ({
  type: SET_AUTO_SELECT_INPUTS,
  inputs,
});

// Reducers
const inputs = (state = [], action) => {
  switch (action.type) {
    case SET_AUTO_SELECT_INPUTS:
      return action.inputs;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  inputs,
});

// Store
const store = createStore(rootReducer);

// Components

class AutoSelect extends Component {
  state = {
    column: null,
    direction: null,
    nbItems: null,
    loading: {},
  };
  componentDidMount() {
    this.props.onLoadAutoSelectInputs();
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    this.setState({
      column: clickedColumn,
      direction:
        column !== clickedColumn
          ? 'ascending'
          : direction === 'ascending'
          ? 'descending'
          : 'ascending',
    });
  };

  onSelectItems = async (workPipeId, inputId, nbItems) => {
    this.setState({ loading: { ...this.state.loading, [inputId]: true } });
    await this.props.onAutoSelectItems(workPipeId, inputId, nbItems);
    await this.props.onLoadAutoSelectInputs();
    this.setState({ loading: { ...this.state.loading, [inputId]: false } });
  };

  render() {
    const { inputs } = this.props;
    const { column, direction, loading } = this.state;
    const sortedAcsInputs = _.sortBy(
      inputs,
      (input) => input[column || 'offerId'],
    );
    const sortedInputs =
      direction === 'descending' ? sortedAcsInputs.reverse() : sortedAcsInputs;
    return (
      <div>
        <h1>Auto Select Inputs</h1>
        <Table basic="very" celled collapsing sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'offerId' ? direction : null}
                onClick={this.handleSort('offerId')}
              >
                OfferId
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'workPipeTitle' ? direction : null}
                onClick={this.handleSort('workPipeTitle')}
              >
                Workpipe Title
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'autoSelectInputTitle' ? direction : null}
                onClick={this.handleSort('autoSelectInputTitle')}
              >
                Input Title
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === 'nbProfilesAfterFiltering' ? direction : null
                }
                onClick={this.handleSort('nbProfilesAfterFiltering')}
              >
                Nb profiles in pipe
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === 'nbSweetsheetPendingItems' ? direction : null
                }
                onClick={this.handleSort('nbSweetsheetPendingItems')}
              >
                Sweetsheet pending profiles
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  column === 'nbPendingPlatformProfiles' ? direction : null
                }
                onClick={this.handleSort('nbPendingPlatformProfiles')}
              >
                Sweetapp pending profiles
              </Table.HeaderCell>
              <Table.HeaderCell>Consume Profiles</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(
              sortedInputs,
              (
                {
                  workPipeId,
                  workPipeTitle,
                  autoSelectInputId,
                  autoSelectInputTitle,
                  offerId,
                  nbSweetsheetPendingItems,
                  nbPendingPlatformProfiles,
                  nbProfilesAfterFiltering,
                  sweetsheetId,
                },
                index,
              ) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    {offerId}
                    <Link
                      to={`/offers/${offerId}`}
                      style={{ marginLeft: '5px' }}
                    >
                      <Icon name="external" />
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {workPipeTitle}
                    <Link
                      to={`/offers/${offerId}/workpipe/${workPipeId}`}
                      style={{ marginLeft: '5px' }}
                    >
                      <Icon name="external" />
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {autoSelectInputTitle}
                    <Link
                      to={`/offers/${offerId}/workpipe/${workPipeId}/${autoSelectInputId}`}
                      style={{ marginLeft: '5px' }}
                    >
                      <Icon name="external" />
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{nbProfilesAfterFiltering}</Table.Cell>
                  <Table.Cell>
                    {nbSweetsheetPendingItems}
                    <Link
                      to={`/flowboard/sweetsheets/id/${sweetsheetId}`}
                      style={{ marginLeft: '5px' }}
                    >
                      <Icon name="external" />
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{nbPendingPlatformProfiles}</Table.Cell>
                  <Table.Cell>
                    <Input
                      type="number"
                      placeholder="10"
                      {...(loading || {})[autoSelectInputId] && {
                        loading: true,
                      }}
                      {...(loading || {})[autoSelectInputId] && {
                        disabled: true,
                      }}
                      action={
                        <Button
                          content="Go"
                          onClick={() =>
                            this.onSelectItems(
                              workPipeId,
                              autoSelectInputId,
                              this.state.nbItems,
                            )
                          }
                        />
                      }
                      onChange={(e, { value }) =>
                        this.setState({ nbItems: parseInt(value, 10) })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ),
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

// Containers
const mapSAutoSelect = (state) => ({
  inputs: state.inputs,
});

const mapDAutoSelect = (dispatch) => ({
  // TODO paginate: see action view
  onLoadAutoSelectInputs: async () => {
    const { data } = await axios.get(
      `${baseUrl}/sweetwork/auto_select_inputs_with_offers`,
    );
    dispatch(setAutoSelectInputs(data));
  },
  onAutoSelectItems: async (workPipeId, inputId, nbItems) => {
    try {
      const body = { workPipeId, inputId, nbItems };
      const { data } = await axios.post(
        `${baseUrl}/sweetwork/autoselect`,
        body,
      );
      if (data.error) {
        alert(data.error);
      }
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  },
});

const AutoSelectContainer = connect(
  mapSAutoSelect,
  mapDAutoSelect,
)(AutoSelect);

export default ({ match, history }) => (
  <Provider store={store}>
    <AutoSelectContainer match={match} history={history} />
  </Provider>
);
