import _, { random } from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import { Button, Card, Form, Grid, Icon, List, Popup, Message, MessageHeader, ButtonGroup } from 'semantic-ui-react';
import CustomModal from '../Modal';

class LinkedinStats extends Component {
  render() {
    const { nbSampledSources, nbExistingSources, sampledDiff } = this.props;

    return (
      <div>
        Among {nbSampledSources} linkedin profiles, {nbExistingSources} were found in DB.
        {sampledDiff.length > 0 ? (
          <a
            target='_blank'
            href={'https://www.linkedin.com/in/' + sampledDiff[Math.floor(Math.random() * sampledDiff.length)]}
            rel='noopener noreferrer'
          >
            Sample In Diff
          </a>
        ) : null}
      </div>
    );
  }
}

const ConnectionSettings = ({ connectionSettings }) => {
  return (
    <Popup
      trigger={<Icon name='plug' color='blue' />}
      content={
        <div>
          <h4>ConnectionSettings</h4>
          <ul>
            {_.map(connectionSettings, (value, key) => (
              <li>
                <b>{key}:</b>
                {value}
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
};
class Node extends Component {
  setActiveSearchableSet = async () => {
    this.setState({ awaitWorkflow: true });
    const { id, profileCollectionId, jobCollectionId, matchCollectionId, metaType, dbType } = this.props || {};
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    if (metaType === 'searchable-profiles') {
      const url =
        dbType === 'bigquery'
          ? baseUrl + '/sweetchain/setActiveSearchableProfileSet'
          : baseUrl + '/sweetchain/setActiveSearchableProfileSetPostGreSql';
      const payload = {
        profileCollectionId: profileCollectionId,
        setId: id,
      };
      await axios.post(url, payload, customHeaders);
    }
    if (metaType === 'searchable-jobs') {
      const url = baseUrl + '/sweetchain/setActiveSearchableJobSet';
      const payload = {
        jobCollectionId: jobCollectionId,
        setId: id,
      };
      await axios.post(url, payload, customHeaders);
    }
    if (metaType === 'match') {
      const url = baseUrl + '/sweetchain/setActiveSearchableMatchSet';
      const payload = {
        matchCollectionId: matchCollectionId,
        setId: id,
      };
      await axios.post(url, payload, customHeaders);
    }
  };

  unsetActiveSearchableSet = async () => {
    this.setState({ awaitWorkflow: true });
    const { id, profileCollectionId, jobCollectionId, matchCollectionId, metaType, dbType } = this.props || {};
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    if (metaType === 'searchable-profiles') {
      const url =
        dbType === 'bigquery'
          ? baseUrl + '/sweetchain/unsetActiveSearchableProfileSet'
          : baseUrl + '/sweetchain/unsetActiveSearchableProfileSetPostGreSql';
      const payload = {
        profileCollectionId: profileCollectionId,
      };
      await axios.post(url, payload, customHeaders);
    }
  };

  handleClickOnDelete = () => {
    this.setState({ onDeleteModalVisible: true });
  };

  onDeleteSetConfirm = async (set) => {
    const payload = {
      setId: set.id,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteSet';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ onDeleteModalVisible: false });
    this.handleLoadSets();
  };

  onDeleteConfirm = async () => {
    const { id, type } = this.props;
    const payload = {
      serviceId: id,
      setId: id,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = type === 'set' ? baseUrl + '/sweetchain/deleteSet' : baseUrl + '/sweetchain/deleteService';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ onDeleteModalVisible: false });
  };

  flushCursor = async () => {
    const { id } = this.props;
    if (id) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/flushCursor';
      const payload = {
        serviceId: id,
      };
      await axios.post(url, payload, customHeaders);
    }
  };

  flushTable = async () => {
    const { id } = this.props;
    if (id) {
      this.setState({ onFlushModalVisible: true });
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/flush/' + id;
      await axios.get(url, customHeaders);
      this.setState({ onFlushModalVisible: false });
    }
  };

  mergeTable = async () => {
    const { id } = this.props;
    if (id) {
      this.setState({ onMergeModalVisible: true });
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/merge/' + id;
      await axios.get(url, customHeaders);
      this.setState({ onMergeModalVisible: false });
    }
  };

  onCancel = () => {
    this.setState({ onDeleteModalVisible: false });
  };

  render() {
    const {
      type,
      dbType,
      connectionSettings,
      id,
      transformerId,
      metaType,
      path,
      count,
      cursorValue,
      streamCount,
      contextVersion,
      isSearchable,
    } = this.props;
    const { onDeleteModalVisible, onFlushModalVisible, onMergeModalVisible } = this.state || {};
    return (
      <div>
        {onDeleteModalVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete ${type} ${id}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={() => this.onDeleteConfirm()}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </CustomModal>
        ) : null}
        {onMergeModalVisible ? (
          <CustomModal>
            <p>Merge In Process</p>
          </CustomModal>
        ) : null}
        {onFlushModalVisible ? (
          <CustomModal>
            <p>Flush In Process</p>
          </CustomModal>
        ) : null}

        <Card>
          <Card.Header textAlign='center'>
            {type == 'set' ? (
              <span>
                <Icon name={'database'} color={'blue'} />
                {connectionSettings && <ConnectionSettings connectionSettings={connectionSettings} />}
                {dbType && dbType == 'postgresql' ? 'PostGreSql - ' : 'BigQuery - '}
              </span>
            ) : (
              <Icon name={'arrow circle outline right'} color={'blue'} />
            )}
            {id}
          </Card.Header>
          <Card.Content>
            <List>
              {metaType ? (
                <List.Item>
                  {' '}
                  <b>Item Type</b> : {metaType}{' '}
                </List.Item>
              ) : null}
              {path ? (
                <List.Item>
                  {' '}
                  <b>Path</b> : {path ? _.last(path.split(':27017')) : null}
                </List.Item>
              ) : null}
              {count !== undefined ? (
                <List.Item>
                  {' '}
                  <b>Count Items</b> : {count}{' '}
                </List.Item>
              ) : null}
              {streamCount !== undefined ? (
                <List.Item>
                  {' '}
                  <b>Count Stream</b> : {streamCount}{' '}
                </List.Item>
              ) : null}
              {cursorValue !== undefined ? (
                <List.Item>
                  {' '}
                  <b>Cursor Value</b> : {cursorValue}{' '}
                </List.Item>
              ) : null}
              {transformerId ? (
                <List.Item>
                  {' '}
                  <b>Transformer Id</b> : {transformerId}{' '}
                </List.Item>
              ) : null}
              {contextVersion ? (
                <List.Item>
                  {' '}
                  <b>Context Version</b> : {contextVersion}{' '}
                </List.Item>
              ) : null}
              {isSearchable ? (
                <List.Item>
                  <Icon name='search' color={'blue'} size='huge' />
                  <a style={{ cursor: 'pointer', color: 'red' }} onClick={() => this.unsetActiveSearchableSet()}>
                    Unset active searchable table
                  </a>
                </List.Item>
              ) : null}
              {metaType &&
              (metaType === 'searchable-profiles' || metaType === 'searchable-jobs' || metaType === 'match') &&
              !isSearchable ? (
                <List.Item>
                  <a style={{ cursor: 'pointer' }} onClick={() => this.setActiveSearchableSet()}>
                    Set Active Searchable Table
                  </a>
                </List.Item>
              ) : null}
              {type === 'service' && transformerId === 'merger-flusher' ? (
                <div>
                  <List.Item>
                    <a style={{ cursor: 'pointer', color: 'green' }} onClick={() => this.mergeTable()}>
                      <b>
                        <Icon name='fork' /> Merge Table
                      </b>
                    </a>
                  </List.Item>
                  <List.Item>
                    <a style={{ cursor: 'pointer', color: 'orange' }} onClick={() => this.flushTable()}>
                      <b>
                        <Icon name='cut' /> Flush Table
                      </b>
                    </a>
                  </List.Item>
                </div>
              ) : null}
              <List.Item>
                <a style={{ cursor: 'pointer', color: 'red' }} onClick={() => this.handleClickOnDelete()}>
                  <b>
                    <Icon color={'red'} name='close' /> Delete {type}
                  </b>
                </a>
              </List.Item>
              {type === 'service' &&
              (transformerId === 'profiles-sqlizer-poc' ||
                transformerId === 'profiles-sqlizer-postgresql' ||
                transformerId === 'jobs-sqlizer' ||
                transformerId === 'watch-profile-job-matcher' ||
                transformerId === 'profile-job-matcher') ? (
                <List.Item>
                  <a style={{ cursor: 'pointer', color: 'orange' }} onClick={() => this.flushCursor()}>
                    <b>
                      <Icon name='undo' /> Flush Cursor
                    </b>
                  </a>
                </List.Item>
              ) : null}
            </List>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

class Workflow extends Component {
  componentDidMount() {
    this.handleLoadProfileCollections();
    this.handleLoadJobCollections();
    this.handleLoadMatchCollections();
  }

  async getWorkflowInformations(type_, collectionId) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getWorkflowInformation/' + type_ + '/' + collectionId;
    const results = (await axios.get(url, customHeaders)).data;
    console.log(type_);
    if (type_ === 'profile') {
      const url2 = baseUrl + '/sweetchain/getMissingLinkedinStats';
      const payload = {
        profileCollectionId: collectionId,
      };
      const r = (await axios.post(url2, payload, customHeaders)).data;
      this.setState({ linkedinStats: (r || {}).data, workflowInformations: (results || {}).data });
    } else {
      this.setState({ workflowInformations: (results || {}).data, linkedinStats: null });
    }
  }

  async getProfileCollections() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileCollections';
    const results = (await axios.get(url, customHeaders)).data;
    return results.data;
  }

  async getJobCollections() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobCollections';
    const results = (await axios.get(url, customHeaders)).data;
    return results;
  }

  async getMatchCollections() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getMatchCollections';
    const results = (await axios.get(url, customHeaders)).data;
    return results;
  }

  handleLoadProfileCollections = async () => {
    const profileCollections = await this.getProfileCollections();
    this.setState({ profileCollections });
  };

  handleSelectProfileCollection = async (e, { value }) => {
    this.setState({
      selectedProfileCollectionId: value,
      selectedJobCollectionId: null,
      selectedMatchCollectionId: null,
    });
    await this.getWorkflowInformations('profile', value);
  };

  handleLoadJobCollections = async () => {
    const jobCollections = await this.getJobCollections();
    this.setState({ jobCollections });
  };

  handleLoadMatchCollections = async () => {
    const matchCollections = await this.getMatchCollections();
    this.setState({ matchCollections });
  };

  handleSelectJobCollection = async (e, { value }) => {
    this.setState({
      selectedJobCollectionId: value,
      selectedProfileCollectionId: null,
      selectedMatchCollectionId: null,
    });
    await this.getWorkflowInformations('job', value);
  };

  handleSelectMatchCollection = async (e, { value }) => {
    this.setState({
      selectedMatchCollectionId: value,
      selectedProfileCollectionId: null,
      selectedJobCollectionId: null,
    });
    await this.getWorkflowInformations('match', value);
  };

  refreshWorflow = async () => {
    this.setState({ awaitWorkflow: true });
    const { selectedProfileCollectionId, selectedJobCollectionId, selectedMatchCollectionId } = this.state || {};
    if (selectedProfileCollectionId) {
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
      this.setState({ awaitWorkflow: false });
    }
    if (selectedJobCollectionId) {
      await this.getWorkflowInformations('job', selectedJobCollectionId);
      this.setState({ awaitWorkflow: false });
    }
    if (selectedMatchCollectionId) {
      await this.getWorkflowInformations('match', selectedMatchCollectionId);
      this.setState({ awaitWorkflow: false });
    }
  };

  handleClickOnDeleteJobCollection = () => {
    this.setState({ modalDeleteJobCollectionVisible: true });
  };

  handleClickOnDeleteProfileCollection = () => {
    this.setState({ modalDeleteProfileCollectionVisible: true });
  };

  handleClickOnDeleteMatchCollection = () => {
    this.setState({ modalDeleteMatchCollectionVisible: true });
  };

  addLinkedinTasks = async () => {
    const { selectedProfileCollectionId } = this.state || {};
    this.setState({ awaitWorkflow: true });
    if (selectedProfileCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/addLinkedinTasksFromProfileCollection';
      const payload = {
        profileCollectionId: selectedProfileCollectionId,
      };
      const r = await axios.post(url, payload, customHeaders);
      this.setState({ awaitWorkflow: false });
    }
  };

  upgradeProfileCollectionVersion = async () => {
    this.setState({ awaitWorkflow: true });
    const { selectedProfileCollectionId } = this.state || {};

    if (selectedProfileCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/upgradeProfileCollection';
      const payload = {
        profileCollectionId: selectedProfileCollectionId,
      };
      const r = await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
      this.setState({ awaitWorkflow: false });
    }
  };

  upgradeProfileCollectionVersionPostGreSql = async ({ connectionSettingsId }) => {
    this.setState({ awaitWorkflow: true });
    const { selectedProfileCollectionId } = this.state || {};

    const CONNECTION_SETTINGS = {
      aws: {
        cloudProvider: 'aws',
        host: 'reveal-instance-1.cgyu5onbhjvm.eu-west-2.rds.amazonaws.com',
      },
      gcp: {
        cloudProvider: 'gcp',
        gcpConnectionName: 'trim-cistern-262208:europe-west9:reveal-prod',
      },
    };

    if (selectedProfileCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/upgradeProfileCollectionPostGreSQL';
      const payload = {
        profileCollectionId: selectedProfileCollectionId,
        connectionSettings: CONNECTION_SETTINGS[connectionSettingsId],
      };
      const r = await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
      this.setState({ awaitWorkflow: false });
    }
  };

  upgradeJobCollectionVersion = async () => {
    this.setState({ awaitWorkflow: true });
    const { selectedJobCollectionId } = this.state || {};

    if (selectedJobCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/upgradeJobCollection';
      const payload = {
        jobCollectionId: selectedJobCollectionId,
      };
      const r = await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('job', selectedJobCollectionId);
      this.setState({ awaitWorkflow: false });
    }
  };

  upgradeMatchCollectionVersion = async () => {
    this.setState({ awaitWorkflow: true });
    const { selectedMatchCollectionId } = this.state || {};

    if (selectedMatchCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/upgradeMatchCollection';
      const payload = {
        matchCollectionId: selectedMatchCollectionId,
      };
      const r = await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('match', selectedMatchCollectionId);
      this.setState({ awaitWorkflow: false });
    }
  };

  onDeleteJobCollection = async () => {
    const { selectedJobCollectionId } = this.state || {};

    if (selectedJobCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteJobsCollectionAndWorkflow';
      const payload = {
        jobCollectionId: selectedJobCollectionId,
      };
      await axios.post(url, payload, customHeaders);
      this.handleLoadJobCollections();
      this.setState({
        workflowInformations: null,
        selectedJobCollectionId: null,
        modalDeleteJobCollectionVisible: false,
      });
    }
  };

  onDeleteNotActiveProfileCollectionVersions = async () => {
    const { selectedProfileCollectionId } = this.state || {};
    if (selectedProfileCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteAllExceptActiveVersion';
      const payload = {
        collectionId: selectedProfileCollectionId,
        type: 'profile',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
      this.setState({ awaitWorkflow: false, modalDeleteNotActiveProfileCollectionVersionsVisible: false });
    }
  };

  handleClickOnDeleteNotActiveProfileCollectionVersions = () => {
    this.setState({ modalDeleteNotActiveProfileCollectionVersionsVisible: true });
  };

  onDeleteNotActiveJobCollectionVersions = async () => {
    const { selectedJobCollectionId } = this.state || {};
    if (selectedJobCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteAllExceptActiveVersion';
      const payload = {
        collectionId: selectedJobCollectionId,
        type: 'job',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('job', selectedJobCollectionId);
      this.setState({ awaitWorkflow: false, modalDeleteNotActiveJobCollectionVersionsVisible: false });
    }
  };

  onDeleteAllJobCollectionVersions = async () => {
    const { selectedJobCollectionId } = this.state || {};
    if (selectedJobCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteAllVersion';
      const payload = {
        collectionId: selectedJobCollectionId,
        type: 'job',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('job', selectedJobCollectionId);
      this.setState({ awaitWorkflow: false, modalDeleteNotActiveJobCollectionVersionsVisible: false });
    }
  };
  onDeleteAllProfileCollectionVersions = async () => {
    const { selectedProfileCollectionId } = this.state || {};
    if (selectedProfileCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteAllVersion';
      const payload = {
        collectionId: selectedProfileCollectionId,
        type: 'profile',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
      this.setState({ awaitWorkflow: false, modalDeleteNotActiveProfileCollectionVersionsVisible: false });
    }
  };

  handleClickOnDeleteNotActiveJobCollectionVersions = () => {
    this.setState({ modalDeleteNotActiveJobCollectionVersionsVisible: true });
  };

  handleClickOnDeleteAllProfileCollectionVersions = () => {
    this.setState({ modalDeleteAllProfileCollectionVersionsVisible: true });
  };

  handleClickOnDeleteAllJobCollectionVersions = () => {
    this.setState({ modalDeleteAllJobCollectionVersionsVisible: true });
  };

  handleClickReactivateCollection = () => {
    this.setState({ modalReactivateCollectionVisible: true });
  };

  handleClickDeactivateCollection = () => {
    this.setState({ modalDeactivateCollectionVisible: true });
  };

  reactivateCollection = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/reactivateCollection';
    const { selectedJobCollectionId, selectedProfileCollectionId } = this.state || {};
    if (selectedJobCollectionId) {
      const payload = {
        collectionId: selectedJobCollectionId,
        type: 'job',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('job', selectedJobCollectionId);
    } else if (selectedProfileCollectionId) {
      const payload = {
        collectionId: selectedProfileCollectionId,
        type: 'profile',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
    }
    this.setState({ awaitWorkflow: false, modalReactivateCollectionVisible: false });
  };
  deactivateCollection = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deactivateCollection';
    const { selectedJobCollectionId, selectedProfileCollectionId } = this.state || {};
    if (selectedJobCollectionId) {
      const payload = {
        collectionId: selectedJobCollectionId,
        type: 'job',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('job', selectedJobCollectionId);
    } else if (selectedProfileCollectionId) {
      const payload = {
        collectionId: selectedProfileCollectionId,
        type: 'profile',
      };
      await axios.post(url, payload, customHeaders);
      await this.getWorkflowInformations('profile', selectedProfileCollectionId);
    }
    this.setState({ awaitWorkflow: false, modalReactivateCollectionVisible: false });
  };

  onDeleteProfileCollection = async () => {
    const { selectedProfileCollectionId } = this.state || {};

    if (selectedProfileCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteProfilesCollectionAndWorkflow';
      const payload = {
        profileCollectionId: selectedProfileCollectionId,
      };
      await axios.post(url, payload, customHeaders);
      this.handleLoadProfileCollections();
      this.setState({
        workflowInformations: null,
        selectedProfileCollectionId: null,
        modalDeleteProfileCollectionVisible: false,
      });
    }
  };

  onDeleteMatchCollection = async () => {
    const { selectedMatchCollectionId } = this.state || {};

    if (selectedMatchCollectionId) {
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/deleteMatchesCollectionAndWorkflow';
      const payload = {
        matchCollectionId: selectedMatchCollectionId,
      };
      await axios.post(url, payload, customHeaders);
      this.handleLoadProfileCollections();
      this.setState({
        workflowInformations: null,
        selectedMatchCollectionId: null,
        modalDeleteMatchCollectionVisible: false,
      });
    }
  };

  onCancel = async () => {
    this.setState({
      modalDeleteNotActiveProfileCollectionVersionsVisible: false,
      modalDeleteNotActiveJobCollectionVersionsVisible: false,
      modalDeleteJobCollectionVisible: false,
      modalDeleteProfileCollectionVisible: false,
      modalDeleteMatchCollectionVisible: false,
      modalReactivateCollectionVisible: false,
      modalDeactivateCollectionVisible: false,
      modalDeleteAllJobCollectionVersionsVisible: false,
      modalDeleteAllProfileCollectionVersionsVisible: false,
    });
  };

  render() {
    const {
      workflowInformations,
      profileCollections,
      jobCollections,
      matchCollections,
      selectedJobCollectionId,
      selectedProfileCollectionId,
      selectedMatchCollectionId,
      awaitWorkflow,
      modalDeleteNotActiveProfileCollectionVersionsVisible,
      modalDeleteNotActiveJobCollectionVersionsVisible,
      modalDeleteJobCollectionVisible,
      modalDeleteProfileCollectionVisible,
      modalDeleteMatchCollectionVisible,
      linkedinStats,
      modalReactivateCollectionVisible,
      modalDeactivateCollectionVisible,
      modalDeleteAllJobCollectionVersionsVisible,
      modalDeleteAllProfileCollectionVersionsVisible,
    } = this.state || {};
    const profileCollectionOptions = _.map(profileCollections || [], (profileCollection, key) => ({
      value: profileCollection.id,
      key: key,
      //text: (profileCollection.name ? profileCollection.name : profileCollection.title) + ' (' + profileCollection.id + ')',
      text: profileCollection.id,
    }));
    const jobCollectionOptions = _.map(jobCollections || [], (jobCollection, key) => ({
      value: jobCollection.id,
      key: key,
      //text: (jobCollection.name ? jobCollection.name : jobCollection.title) + ' (' + jobCollection.id + ')',
      text: jobCollection.id,
    }));
    const matchCollectionOptions = _.map(matchCollections || [], (matchCollection, key) => ({
      value: matchCollection.id,
      key: key,
      text: matchCollection.name ? matchCollection.name : matchCollection.title,
    }));

    const searchableSetId = selectedProfileCollectionId
      ? _.find(profileCollections, (profileCollection) => profileCollection.id === selectedProfileCollectionId)
          .activeSearchableProfileSet
      : selectedJobCollectionId
      ? _.find(jobCollections, (jobCollection) => jobCollection.id === selectedJobCollectionId).activeSearchableJobSet
      : selectedMatchCollectionId
      ? _.find(matchCollections, (matchCollection) => matchCollection.id === selectedMatchCollectionId)
          .activeSearchableMatchSet
      : null;

    const searchablePostgreSqlSetId = selectedProfileCollectionId
      ? _.find(profileCollections, (profileCollection) => profileCollection.id === selectedProfileCollectionId)
          .activeSearchableProfileSetPostGreSql
      : selectedJobCollectionId
      ? _.find(jobCollections, (jobCollection) => jobCollection.id === selectedJobCollectionId)
          .activeSearchableJobSetPostGreSql
      : selectedMatchCollectionId
      ? _.find(matchCollections, (matchCollection) => matchCollection.id === selectedMatchCollectionId)
          .activeSearchableMatchSetPostGreSql
      : null;

    const color = awaitWorkflow ? 'grey' : 'green';

    const collectionIsDeactivated = ((workflowInformations || {}).collection || {}).isDeactivated;
    const selectedCollectionId = selectedProfileCollectionId || selectedJobCollectionId || selectedMatchCollectionId;
    return (
      <div>
        <Form.Select
          search
          placeholder='Profile Collection'
          options={profileCollectionOptions}
          onChange={this.handleSelectProfileCollection}
          value={selectedProfileCollectionId}
        />
        <Form.Select
          search
          placeholder='Job Collection'
          options={jobCollectionOptions}
          onChange={this.handleSelectJobCollection}
          value={selectedJobCollectionId}
        />
        <Form.Select
          search
          placeholder='Match Collection'
          options={matchCollectionOptions}
          onChange={this.handleSelectMatchCollection}
          value={selectedMatchCollectionId}
        />
        {modalDeleteNotActiveProfileCollectionVersionsVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Not Active Sets & Services`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteNotActiveProfileCollectionVersions}
          >
            <p>
              Are you sure ? This operation cannot be revert, it will delete all the sets and services except the
              current active one.
            </p>
          </CustomModal>
        ) : null}
        {modalDeleteNotActiveJobCollectionVersionsVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Not Active Sets & Services`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteNotActiveJobCollectionVersions}
          >
            <p>
              Are you sure ? This operation cannot be revert, it will delete all the sets and services except the
              current active one.
            </p>
          </CustomModal>
        ) : null}

        {modalDeleteAllJobCollectionVersionsVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete All Searchable Sets & Services For Collection ${selectedJobCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteAllJobCollectionVersions}
          >
            <p>Are you sure ? This operation cannot be revert, it will delete all the searchable sets and services</p>
          </CustomModal>
        ) : null}
        {modalDeleteAllProfileCollectionVersionsVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete All Searchable Sets & Services For Collection ${selectedProfileCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteAllProfileCollectionVersions}
          >
            <p>Are you sure ? This operation cannot be revert, it will delete all the searchable sets and services</p>
          </CustomModal>
        ) : null}

        {modalDeleteJobCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Job Collection ${selectedJobCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteJobCollection}
          >
            <p>
              Are you sure ? This operation cannot be reverted. It will delete all the services and sets related to this
              job collection.
            </p>
          </CustomModal>
        ) : null}
        {modalDeleteProfileCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Profile Collection ${selectedProfileCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteProfileCollection}
          >
            <p>
              Are you sure ? This operation cannot be reverted. It will delete all the services and sets related to this
              profile collection.
            </p>
          </CustomModal>
        ) : null}
        {modalDeleteMatchCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Match Collection ${selectedMatchCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteMatchCollection}
          >
            <p>
              Are you sure ? This operation cannot be reverted. It will delete all the services and sets related to this
              match collection.
            </p>
          </CustomModal>
        ) : null}
        {modalDeactivateCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Deactivate Collection ${selectedCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.deactivateCollection}
          >
            <p>
              Disable /notifyUpdate so that nothing is computed anymore <br />
              Doesnt delete anything
            </p>
          </CustomModal>
        ) : null}
        {modalReactivateCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Reactivate Collection ${selectedCollectionId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.reactivateCollection}
          >
            <p>Enable /notifyUpdate so that workflow is triggered</p>
          </CustomModal>
        ) : null}

        <div>
          <Grid>
            {selectedCollectionId && (
              <Grid.Row>
                <Grid.Column width={4}>
                  <b>{'Collection Id : ' + selectedCollectionId}</b>
                </Grid.Column>
              </Grid.Row>
            )}

            {(selectedJobCollectionId || selectedProfileCollectionId) && workflowInformations && (
              <Grid.Row>
                {collectionIsDeactivated ? (
                  <Grid.Column>
                    <Message color='brown'>
                      <MessageHeader>Collection Deactivated</MessageHeader>
                      <p>Calling /notifyUpdate doesnt trigger anything</p>
                    </Message>
                    <Button inverted onClick={() => this.handleClickReactivateCollection()} color={'green'}>
                      Reactivate
                    </Button>
                  </Grid.Column>
                ) : (
                  <Popup
                    content={<div>Disable /notifyUpdate so that nothing is computed anymore</div>}
                    trigger={
                      <Button inverted onClick={() => this.handleClickDeactivateCollection()} color={'red'}>
                        Deactivate
                      </Button>
                    }
                  />
                )}
              </Grid.Row>
            )}
            {selectedProfileCollectionId && workflowInformations && (
              <Grid.Row>
                <Grid.Column width={2}>
                  <Button onClick={() => this.refreshWorflow()} color={color}>
                    Refresh Workflow
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.addLinkedinTasks()} color={color}>
                    Add Linkedin Tasks
                  </Button>
                </Grid.Column>

                <Grid.Column width={2}>
                  <Button onClick={() => this.upgradeProfileCollectionVersion()} color={color}>
                    Upgrade BigQuery Version
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    onClick={() => this.upgradeProfileCollectionVersionPostGreSql({ connectionSettingsId: 'aws' })}
                    color={color}
                  >
                    Upgrade PostGreSQL Version (AWS)
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    onClick={() => this.upgradeProfileCollectionVersionPostGreSql({ connectionSettingsId: 'gcp' })}
                    color={color}
                  >
                    Upgrade PostGreSQL Version (GCP)
                  </Button>
                </Grid.Column>

                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteNotActiveProfileCollectionVersions()} color='orange'>
                    Delete not active versions
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteAllProfileCollectionVersions()} color='red'>
                    Delete all versions
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteProfileCollection()} color='red'>
                    Delete Profile Collection
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}
            {selectedJobCollectionId && workflowInformations && (
              <Grid.Row>
                <Grid.Column width={2}>
                  <Button onClick={() => this.refreshWorflow()} color={color}>
                    Refresh Workflow
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.upgradeJobCollectionVersion()} color={color}>
                    Upgrade Version
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteNotActiveJobCollectionVersions()} color='orange'>
                    Delete not active versions
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteAllJobCollectionVersions()} color='red'>
                    Delete all versions
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteJobCollection()} color='red'>
                    Delete Job Collection
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}
            {selectedMatchCollectionId && workflowInformations && (
              <Grid.Row>
                <Grid.Column width={2}>
                  <Button onClick={() => this.refreshWorflow()} color={color}>
                    Refresh Workflow
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.upgradeMatchCollectionVersion()} color={color}>
                    Upgrade Version
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button onClick={() => this.handleClickOnDeleteMatchCollection()} color='red'>
                    Delete Match Collection
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>

        <div>
          {linkedinStats ? <LinkedinStats {...linkedinStats} /> : null}
          <br />
          <br />
          <br />
        </div>
        <div>
          {workflowInformations ? (
            <Card.Group textAlign='center'>
              {_.map(workflowInformations.nodes || [], (node, index) => (
                <Node
                  {...node}
                  key={index}
                  isSearchable={node.id === searchableSetId || node.id === searchablePostgreSqlSetId}
                  profileCollectionId={selectedProfileCollectionId}
                  jobCollectionId={selectedJobCollectionId}
                  matchCollectionId={selectedMatchCollectionId}
                />
              ))}
            </Card.Group>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Workflow;
