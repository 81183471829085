import _ from 'underscore';
import React from 'react';
import { Modal, Grid, TextArea, Loader, Button, Icon } from 'semantic-ui-react';

class WatcherEditionModal extends React.Component {
  state = {
    previewKey: '',
    initialWatcher: {},
    currentJSON: '{}',
    isValidJSON: true,
  };

  handleChangeJSON = ({ value }) => {
    let isValidJSON = true;
    try {
      JSON.parse(value);
    } catch (e) {
      isValidJSON = false;
    }

    this.setState({
      currentJSON: value,
      isValidJSON,
    });
  };

  handleSubmit = () => {
    const { initialWatcher, currentJSON, isValidJSON } = this.state;
    let newWatcher = {};
    try {
      if (!isValidJSON) {
        throw Error('Erro: invalid JSON');
      }
      newWatcher = JSON.parse(currentJSON);
      if (initialWatcher.id && (newWatcher || {}).id !== initialWatcher.id) {
        throw Error('Error: id should be ' + initialWatcher.id);
      }
    } catch (e) {
      return alert(e.message);
    }
    this.props.onSave({ watcher: newWatcher });
  };

  componentDidMount = () => {
    const watcher = this.props.watcher || {};
    const initialWatcher = {
      ...watcher,
    };

    this.setState({
      initialWatcher,
      currentJSON: JSON.stringify(initialWatcher || {}, null, 4),
      isValidJSON: true,
    });
  };

  render() {
    const { onClose, watcher } = this.props;
    const { mode, currentJSON, isValidJSON } = this.state;

    return (
      <Modal open={true} onClose={onClose} closeIcon closeOnDimmerClick={false}>
        <Modal.Header>
          {(watcher || {}).name} ({(watcher || {}).id})
        </Modal.Header>
        <Modal.Content style={{ minHeight: 500 }}>
          <div>
            <TextArea
              style={{
                outline: 'none',
                border: isValidJSON ? '1px solid lime' : '1px solid red',
              }}
              rows={50}
              cols={140}
              value={currentJSON}
              onChange={(e, { value }) => this.handleChangeJSON({ value })}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleSubmit}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default WatcherEditionModal;
