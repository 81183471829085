import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';

import { Grid, List, Header, Table, Checkbox } from 'semantic-ui-react';

import { Select, SweetForm } from 'sweetform';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import baseUrl from './baseUrl';
import COLLECTIONS from './collections';

// Actions
const SET_PARAMS = 'SET_PARAMS';
const setParams = (params) => ({ type: SET_PARAMS, params });

const SET_ROWS = 'SET_ROWS';
const setRows = (rows, q) => ({ type: SET_ROWS, rows, q });

const SET_QUERY = 'SET_QUERY';
const setQuery = (q) => ({ type: SET_QUERY, q });

const SET_SAMPLE = 'SET_SAMPLE';
const setSample = (sample) => ({ type: SET_SAMPLE, sample });

const TOGGLE_CHART_MODE = 'TOGGLE_CHART_MODE';
const toggleChartMode = () => ({ type: TOGGLE_CHART_MODE });

// Reducers
const params = (state = {}, action) => {
  switch (action.type) {
    case SET_PARAMS:
      return action.params;
    default:
      return state;
  }
};

const results = (state = { rows: null, q: 0 }, action) => {
  switch (action.type) {
    case SET_QUERY:
      return {
        ...state,
        q: action.q,
      };
    case SET_ROWS:
      if (action.q !== state.q) {
        return state;
      }

      return {
        ...state,
        rows: _.map(action.rows, (result, i, l) => {
          const difference = (result.size || 0) - ((l[i + 1] || {}).size || 0);
          return { ...result, difference };
        }),
      };
    default:
      return state;
  }
};

const sample = (state = null, action) => {
  switch (action.type) {
    case SET_ROWS:
      return null;
    case SET_SAMPLE:
      return action.sample;
    default:
      return state;
  }
};

const chartModeCumulative = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CHART_MODE:
      return !state;
    case SET_ROWS:
      return false;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  params,
  results,
  sample,
  chartModeCumulative,
});

// Store
const store = createStore(rootReducer);

// Components
const ReportTable = ({ results, onClick }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Time</Table.HeaderCell>
        <Table.HeaderCell>Collection Size</Table.HeaderCell>
        <Table.HeaderCell>Difference</Table.HeaderCell>
        <Table.HeaderCell>Documents scraped</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(results, ({ key, date, size, daily, difference }, i, l) => (
        <Table.Row
          key={i}
          onClick={() => onClick(date)}
          warning={difference >= 0 && !daily}
          negative={difference < 0}
        >
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{size || 0}</Table.Cell>
          <Table.Cell>{difference}</Table.Cell>
          <Table.Cell>{daily || 0}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
const gradientOffset = (results) => {
  const values = _.pluck(results, 'difference');

  const dataMax = Math.max(...values);
  const dataMin = Math.min(...values);

  if (dataMax <= 0) {
    return 0;
  } else if (dataMin >= 0) {
    return 1;
  } else {
    return dataMax / (dataMax - dataMin);
  }
};

const ReportChart = ({ results }) => (
  <ResponsiveContainer width="100%" height={200}>
    <ComposedChart
      data={results.slice(0, Math.min(results.length - 1, 30)).reverse()}
    >
      <XAxis dataKey="key" />
      <YAxis />
      <CartesianGrid />
      <Tooltip />
      <Legend />
      <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
          <stop
            offset={gradientOffset(results)}
            stopColor="green"
            stopOpacity={1}
          />
          <stop
            offset={gradientOffset(results)}
            stopColor="red"
            stopOpacity={1}
          />
        </linearGradient>
      </defs>
      <Line
        isAnimationActive={false}
        type="monotone"
        dataKey="daily"
        stroke="black"
      />
      <Area
        isAnimationActive={false}
        type="monotone"
        dataKey="difference"
        fill="url(#splitColor)"
      />
    </ComposedChart>
  </ResponsiveContainer>
);
const ReportChartCumulative = ({ results }) => (
  <ResponsiveContainer width="100%" height={200}>
    <ComposedChart
      data={results.slice(0, Math.min(results.length - 1, 30)).reverse()}
    >
      <XAxis dataKey="key" />
      <YAxis />
      <CartesianGrid />
      <Tooltip />
      <Legend />
      <Area isAnimationActive={false} type="monotone" dataKey="size" />
    </ComposedChart>
  </ResponsiveContainer>
);
class Reports extends Component {
  render() {
    const {
      params,
      results,
      sample,
      chartModeCumulative,
      onToggleChartMode,
      onLoadReport,
      onLoadSample,
    } = this.props;

    return (
      <Grid>
        <Grid.Column width={3}>
          <SweetForm
            initialValues={{ group: 'day' }}
            onChange={({ group }) =>
              onLoadReport(results.q, params.collection, group)
            }
          >
            <Select
              field="group"
              clearable={false}
              options={[
                { label: 'Hour', value: 'hour' },
                { label: 'Day', value: 'day' },
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' },
              ]}
            />
          </SweetForm>
          <List bulleted>
            {_.map(COLLECTIONS, (collectionName, i) => (
              <List.Item key={i}>
                {/* eslint-disable-next-line */}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    onLoadReport(results.q, collectionName, params.group);
                  }}
                >
                  {collectionName}
                </a>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          {results.rows ? (
            <div>
              <Header as="h2">{params.collection}</Header>
              {chartModeCumulative ? (
                <ReportChartCumulative results={results.rows} />
              ) : (
                <ReportChart results={results.rows} />
              )}
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Checkbox
                  label="Cumulative"
                  slider
                  checked={chartModeCumulative}
                  onChange={onToggleChartMode}
                />
              </div>
              <ReportTable
                results={results.rows}
                onClick={(date) => onLoadSample(params.collection, date)}
              />
            </div>
          ) : (
            <p>Select a collection</p>
          )}
        </Grid.Column>
        <Grid.Column width={7}>
          {sample ? <pre>{JSON.stringify(sample, null, '  ')}</pre> : null}
        </Grid.Column>
      </Grid>
    );
  }
}

const mapSReports = (state) => ({
  params: state.params,
  results: state.results,
  sample: state.sample,
  chartModeCumulative: state.chartModeCumulative,
});

const mapDReports = (dispatch) => ({
  onLoadReport: async (q, collection, group = 'day') => {
    dispatch(setParams({ collection, group }));

    if (!collection) {
      return;
    }

    dispatch(setQuery(q + 1));
    const results = (await axios.get(
      `${baseUrl}/scrapers/reports/${collection}/${group}`,
    )).data;
    dispatch(setRows(results.reverse(), q + 1));
  },
  onLoadSample: async (collection, date) => {
    const result = (await axios.get(
      `${baseUrl}/instance/${collection}?populated=1&date=${date}`,
    )).data || { noSample: true };
    dispatch(setSample(result));
  },
  onToggleChartMode: () => dispatch(toggleChartMode()),
});

const ReportsContainer = connect(
  mapSReports,
  mapDReports,
)(Reports);

export default () => (
  <Provider store={store}>
    <ReportsContainer />
  </Provider>
);
