import _ from 'underscore';
import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import MongoInformationCollectionView from './MongoInformationCollectionView';
import MongoQueryCollectionView from './MongoQueryCollectionView';
import MongoAdminCollectionView from './MongoAdminCollectionView';

class MongoCollectionView extends Component {
  handleChangeActiveMenu(activeMenu) {
    this.setState({ activeMenu: activeMenu });
  }

  render() {
    const { collection, database, mongoHost, keyType } = this.props.data || {};
    const type = 'mongo';
    const activeMenu = (this.state || {}).activeMenu || 'Admin';
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            key={1}
            name='Admin'
            active={activeMenu == 'Admin'}
            onClick={() => {
              this.handleChangeActiveMenu('Admin');
            }}
          >
            Admin
          </Menu.Item>
          <Menu.Item
            key={2}
            name='Information'
            active={activeMenu == 'Information'}
            onClick={() => {
              this.handleChangeActiveMenu('Information');
            }}
          >
            Information
          </Menu.Item>
          <Menu.Item
            key={3}
            name='Query Playground'
            active={activeMenu == 'Query Playground'}
            onClick={() => {
              this.handleChangeActiveMenu('Query Playground');
            }}
          >
            Query Playground
          </Menu.Item>
        </Menu>
        {activeMenu == 'Query Playground' ? (
          <MongoQueryCollectionView
            collection={collection}
            database={database}
            mongoHost={mongoHost}
            type={type}
            keyType={keyType}
          />
        ) : activeMenu == 'Information' ? (
          <MongoInformationCollectionView
            collection={collection}
            database={database}
            mongoHost={mongoHost}
            type={type}
            keyType={keyType}
          />
        ) : (
          <MongoAdminCollectionView data={this.props.data} />
        )}
      </div>
    );
  }
}
export default MongoCollectionView;
