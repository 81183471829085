import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import React, { Component } from 'react';

import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class JobFunctionInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadJobs();
  }

  loadJobs = async () => {
    const url = baseUrl + '/techAsset/getJobs';
    const jobs = (await axios.get(url)).data;
    this.setState({ jobs });
  };

  async handleSubmit(value) {
    this.props.onSubmit(value);
  }

  render() {
    const { value } = this.props;
    const { jobs } = this.state || {};
    const jobsOptions = _.map(jobs, (job) => ({
      value: job.id,
      text: job.name,
      key: job.id,
    }));
    return (
      <div>
        <Form.Select
          search
          placeholder='Select Job'
          options={jobsOptions}
          onChange={(e, { value }) => {
            this.handleSubmit(value);
          }}
          value={value}
        />
      </div>
    );
  }
}

export default JobFunctionInput;
