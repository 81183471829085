import React, { Component } from 'react';
import axios from 'axios';
import { Modal, Button, Header } from 'semantic-ui-react';
import DisplaySearchResult from '../../SearchResultView.js';
import baseUrl from '../../baseUrl.js';

export class SearchDetailsModalAndButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { input, profile, trigger } = this.props;
    const onClick = async () => {
      try {
        const pipeDescriptor = input ? input.pipeDescriptor : null;
        if (!pipeDescriptor) {
          throw Error('pipeDescriptor needed');
        }
        if (pipeDescriptor.type !== 'search-v3') {
          throw Error('only for search-v3');
        }
        const searchId = pipeDescriptor.searchId;
        const searchIdFields = {
          ...profile.idFields,
          ...(profile.idFields &&
            profile.idFields.stackOverflow && {
              stackoverflow: profile.idFields.stackOverflow,
            }),
        };
        const searchOneParam = {
          searchId,
          idFields: searchIdFields,
        };

        const url = baseUrl + '/sweetwork/searchOneV3';

        axios.post(url, searchOneParam).then(({ data }) => {
          if (data.error) {
            return alert(data.error);
          }
          if (!data.resultingProfile || !data.resultingProfile.innerData) {
            return alert('invalid searchOne result');
          }
          const {
            messageHTML,
            searchParamsWithScore,
          } = data.resultingProfile.innerData;

          this.setState({
            searchResult: {
              messageHTML,
              searchParamsWithScore,
            },
            open: true,
          });
        });
      } catch (e) {
        alert(e.message);
      }
    };
    return (
      <span>
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          closeIcon
          trigger={
            trigger ? (
              <div onClick={onClick}>{trigger}</div>
            ) : (
              <Button
                circular
                color="blue"
                size="massive"
                icon="browser"
                onClick={onClick}
              />
            )
          }
        >
          <Header icon="browser" content="Search Details" />
          <Modal.Content>
            <DisplaySearchResult searchResult={this.state.searchResult} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="green"
              onClick={() => this.setState({ open: false })}
            >
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      </span>
    );
  }
}
