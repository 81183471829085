import React, { Component } from 'react';
import { Button, Dropdown, Form, Grid, Modal } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';
import baseUrl from '../baseUrl.js';

class RecommenderSystemCreationModal extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const defaultName = 'Simple search - ' + date.toISOString();
    this.state = { defaultName };
  }

  handleChange(params) {
    this.setState({
      params,
    });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const { defaultName } = this.state;
    return (
      <Modal closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>New Recommender System Name</label>
              <Input autoFocus field='name' defaultValue={defaultName} />
            </Form.Field>
            <br />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit((this.state || {}).params)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RecommenderSystemCreationModal;
