import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import axios from 'axios';
import { Icon, Table, Label, Select } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

const loadProfilesForYYMMDD = async (yymmdd) => {
  const minTimeStamp = moment(yymmdd, 'YY-MM-DD')
    .toDate()
    .getTime();
  const maxTimeStamp =
    moment(yymmdd, 'YY-MM-DD')
      .toDate()
      .getTime() +
    24 * 3600 * 1000;
  const { data } = await axios.post(`${baseUrl}/station/actions`, {
    types: 'send',
    maxNbResults: 5000,
    minTimeStamp,
    maxTimeStamp,
  });

  const profileIds = _.compact(
    _.map(data.results, (action) => {
      if (action.profile && action.profile.id) {
        return action.profile.id;
      }
    }),
  );

  const profiles = (await axios.post(`${baseUrl}/station/profiles`, {
    maxNbResults: 5000,
    ids: profileIds.join(';'),
    withThreadData: 1,
    withImportantActionData: 1,
  })).data.results;

  let profileFromId = {};
  _.each(profiles, (profile) => {
    if (profile && profile.id) {
      profileFromId[profile.id] = profile;
    }
  });

  return {
    data,
    yymmdd: yymmdd,
    results: _.sortBy(
      _.map(data.results, (action) => {
        const profileId = action.profile && action.profile.id;
        const profile = profileId && profileFromId[profileId];
        return {
          date: action.date,
          clientId: action.clientId,
          jobOfferId: action.jobOfferId,
          profileId,
          profile,
        };
      }),
      'date',
    ),
  };
};

const ActionsDisplay = ({ actions }) => {
  return (
    <div>
      {_.map(_.sortBy(actions, 'date'), (action, index) => {
        const color =
          action.type === 'send'
            ? 'blue'
            : action.type === 'send-follow-up'
            ? 'violet'
            : action.type === 'answer-detected'
            ? 'green'
            : action.type === 'disable-follow-up'
            ? 'red'
            : null;
        const type =
          action.type === 'answer-detected'
            ? 'answer'
            : action.type === 'send-follow-up'
            ? 'followup'
            : action.type === 'disable-follow-up'
            ? 'stopFollowup'
            : action.type === 'disqualifyProfile'
            ? 'disqualify'
            : action.type;
        return (
          <Label horizontal color={color} key={index}>
            [{moment(action.date).format('YY-MM-DD')}] {type}
            {type === 'stopFollowup' && action.reason
              ? ' [' + action.reason + ']'
              : ''}
          </Label>
        );
      })}
    </div>
  );
};

const getItemReliability = (item) => {
  const profile = item.profile;
  if (!profile) {
    return 'unmatched';
  }
  const actions = profile.actions;
  if (_.isEmpty(actions)) {
    return 'suspicious';
  }
  let sendDate = null;
  _.each(actions, ({ type, date }) => {
    if (type === 'send') {
      sendDate = date;
    }
  });
  if (!sendDate) {
    return 'suspicious';
  }
  const timestamp = new Date(sendDate).getTime();
  if (Date.now() - timestamp > 7.6 * (24 * 3600 * 1000)) {
    const threadActions = _.filter(
      actions,
      ({ type }) => ['answer-detected', 'send-follow-up'].indexOf(type) >= 0,
    );
    if (_.isEmpty(threadActions)) {
      return 'suspicious';
    }
  }
  const messages = profile.messages;
  if (_.isEmpty(messages)) {
    return 'suspicious';
  }
  const nbMessages = messages.length;
  const nbSendActions = _.filter(
    profile.actions,
    ({ type }) =>
      ['send', 'send-follow-up', 'answer-detected'].indexOf(type) >= 0,
  ).length;
  if (nbSendActions > nbMessages) {
    return 'suspicious';
  }
  let lastRefreshTimestamps = [];
  _.each(messages, ({ lastRefreshTime }) => {
    if (lastRefreshTime && lastRefreshTimestamps.indexOf(lastRefreshTime) < 0) {
      lastRefreshTimestamps.push(lastRefreshTime);
    }
  });
  if (lastRefreshTimestamps.length >= 2) {
    return 'supsicious';
  }
  const letters = _.map(messages, (message) =>
    (message.from || '?').toUpperCase().charAt(0),
  ).join('');
  if (letters.indexOf('RRR') === 0) {
    return 'suspicious';
  }
  return 'normal';
};

class SweetappThreadsMonitoring extends React.Component {
  state = {};
  
  handleChangeDate = async (event, { value }) => {
    this.setState(
      {
        currentDate: null,
        data: null,
      },
      async () => {
        const data = await loadProfilesForYYMMDD(value);
        this.setState({
          currentDate: data.yymmdd,
          data,
        });
      },
    );
  };
  renderDataGroup(items) {
    if (_.isEmpty(items)) {
      return null;
    }
    const renderThreadInfosOfItem = (item) => {
      const profile = item.profile || {};
      const messages = profile.messages || [];
      const nbMessages = messages.length;
      const nbSendActions = _.filter(
        profile.actions,
        ({ type }) =>
          ['send', 'send-follow-up', 'answer-detected'].indexOf(type) >= 0,
      ).length;
      let lastRefreshTimestamps = [];
      _.each(messages, ({ lastRefreshTime }) => {
        if (
          lastRefreshTime &&
          lastRefreshTimestamps.indexOf(lastRefreshTime) < 0
        ) {
          lastRefreshTimestamps.push(lastRefreshTime);
        }
      });
      const refreshDates = _.map(lastRefreshTimestamps, (timestamp) =>
        new Date(timestamp).toISOString().slice(2, 16),
      );
      return (
        <span>
          {refreshDates.join(';')}
          <Label>
            {(nbMessages === 0 || nbSendActions > nbMessages) && (
              <Icon name="warning circle" color="red" />
            )}
            {nbMessages} message{nbMessages !== 1 ? 's' : ''}
          </Label>
          {_.map(messages, (message, index) => {
            const letter = (message.from || '?').toUpperCase().charAt(0);
            return (
              <span
                key={index}
                style={{
                  fontWeight: 'bold',
                  color: letter === 'R' ? 'blue' : 'orange',
                }}
              >
                {letter}
              </span>
            );
          })}
        </span>
      );
    };
    return (
      <Table>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={1}>ith</Table.HeaderCell>
            <Table.HeaderCell width={2}>sendDate</Table.HeaderCell>
            <Table.HeaderCell width={1}>clientId</Table.HeaderCell>
            <Table.HeaderCell width={2}>jobOfferId</Table.HeaderCell>
            <Table.HeaderCell width={2}>profileId</Table.HeaderCell>
            <Table.HeaderCell width={6}>actions</Table.HeaderCell>
            <Table.HeaderCell width={2}>thread</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(items, (item, index) => (
            <Table.Row
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => console.log(item.profile)}
              error={getItemReliability(item) === 'suspicious'}
            >
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>
                {new Date(item.date).toISOString().slice(2, 16)}
              </Table.Cell>
              <Table.Cell>{item.clientId}</Table.Cell>
              <Table.Cell>{item.jobOfferId}</Table.Cell>
              <Table.Cell>{item.profileId}</Table.Cell>
              <Table.Cell>
                <ActionsDisplay actions={(item.profile || {}).actions} />
              </Table.Cell>
              <Table.Cell width={3}>{renderThreadInfosOfItem(item)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
  renderData() {
    const { data, currentDate } = this.state;
    if (!data) {
      return null;
    }
    let groups = [[], []];
    _.each(data.results, (item) => {
      if (getItemReliability(item) === 'suspicious') {
        groups[0].push(item);
      } else {
        groups[1].push(item);
      }
    });

    return (
      <div>
        <h1>{currentDate}</h1>
        {this.renderDataGroup(groups[0])}
        {this.renderDataGroup(groups[1])}
      </div>
    );
  }
  render() {
    const baseTimestamp = Date.now();
    const nbDays = 400;
    const dates = _.map(
      _.range(nbDays),
      (delayInDays) => new Date(baseTimestamp - delayInDays * 24 * 3600 * 1000),
    );
    const dateOptions = _.map(dates, (date) => {
      const yymmdd = moment(date).format('YY-MM-DD');
      return {
        text: yymmdd,
        value: yymmdd,
        key: yymmdd,
      };
    });

    return (
      <div>
        <center>
          <Select options={dateOptions} onChange={this.handleChangeDate} />
        </center>
        {this.renderData()}
      </div>
    );
  }
}

export default SweetappThreadsMonitoring;
