import React, { Component } from 'react';
import ProfileViewer from '../../Profile/View';
import getProfileV5FromLinkedinV4 from '../profileV5FromLinkedinV4';

/*
   props: { 
     id, 
     data, 
     isV4, // default: false 
   }
*/
class LinkedinViewer extends Component {
  render() {
    const linkedinData = this.props.data || {};
    const linkedinId = this.props.id || 'undefined___id';
    const isV4 = this.props.isV4;

    console.log(linkedinData);

    const profileV5 = isV4
      ? getProfileV5FromLinkedinV4({ linkedinId, linkedinData })
      : {
          ...linkedinData,
          sourceData: {
            linkedin: linkedinData,
          },
        };

    return (
      <div>
        <ProfileViewer data={profileV5} />
      </div>
    );
  }
}

export default LinkedinViewer;
