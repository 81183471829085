function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import _ from 'underscore';
import React from 'react';
import { Checkbox as UCheckbox, Form } from 'semantic-ui-react';
import enhance from './enhance';
var Radio = enhance(function (_ref) {
  var setValue = _ref.setValue,
      value = _ref.value,
      options = _ref.options,
      field = _ref.field,
      props = _objectWithoutProperties(_ref, ['setValue', 'value', 'options', 'field']);

  return React.createElement('div', null, _.map(options, function (option, i) {
    return React.createElement(Form.Field, {
      key: i
    }, React.createElement(UCheckbox, Object.assign({
      radio: true,
      key: option.value,
      label: option.label,
      value: option.value,
      checked: value === option.value,
      onChange: function onChange() {
        return setValue(option.value);
      }
    }, props)));
  }));
});
export default Radio;