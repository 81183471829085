import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { SweetForm, List as SweetList } from 'sweetform';
import {
  TypologyFilter,
  ActivityFilter,
  ActionFilter,
  SelectSegmentationFields,
  wrapActionFilterToIdParams,
  wrapFilterToIdParams
} from './common';

const INITIAL_VALUES = {
  actionFilter: { actionType: "sends" },
  activityFilter: { selection: "isActive" },
  segmentationFields: "categories"
}

const stateFromForm = (form) => {
  let { typologyFilters, activityFilter, segmentationFields, actionFilter } = form || {};
  segmentationFields = segmentationFields ? segmentationFields.split(";") : []
  typologyFilters = _.map(typologyFilters, (filt) => wrapFilterToIdParams(filt))
  activityFilter = wrapFilterToIdParams(activityFilter);
  
  let join = "LEFT"
  let hideCountBy = false;
  if (activityFilter.id == "actionBetween") {
    join = "INNER"
    actionFilter = activityFilter.params;
    activityFilter = null;
    hideCountBy = true;
  } else {
    actionFilter = wrapActionFilterToIdParams(actionFilter);
  }
  return {
    segmentationFields,
    typologyFilters,
    hideCountBy,
    activityFilter,
    actionFilter,
    join,
  }
}
class SegmentationEditor extends Component {
  state = {
    activityFilter: null,
    typologyFilters: null,
    segmentationFields: null,
    actionFilter: null,
    hideCountBy: false,
  };
  componentDidMount = () => {
    const state = stateFromForm(INITIAL_VALUES);
    this.setState(state);
  }

  handleChange = (form) => {
    const state = stateFromForm(form);
    console.log({ state })
    this.setState(state);
  }

  onSubmit = () => {
    const { typologyFilters, activityFilter, segmentationFields, actionFilter, join } = this.state;

    if (!segmentationFields) {
      return alert("No segmentation fields")
    }
    if (!actionFilter) {
      return alert("No action filter")
    }
    this.props.onSubmit({
      segmentationDefinition: {
        type: "fields",
        mode: "exhaustive",
        fields: segmentationFields,
        filters: [...(activityFilter ? [activityFilter] : []), ...typologyFilters],
      },
      actionFilter,
      join,
    })
  }

  render() {

    return (
      <SweetForm onChange={this.handleChange} initialValues={INITIAL_VALUES}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={4}>
              <h3> Base Filters</h3>
            </Grid.Column>
            <Grid.Column width={12}>
              <ActivityFilter field="activityFilter" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <h3> Additional Filters </h3>
            </Grid.Column>
            <Grid.Column width={14}>
              <SweetList component={TypologyFilter} field="typologyFilters" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <h3> Segment by </h3>
            </Grid.Column>
            <Grid.Column width={14}>
              <SelectSegmentationFields />
            </Grid.Column>
          </Grid.Row>
          {!this.state.hideCountBy && (
            <Grid.Row>
              <Grid.Column width={2}>
                <h3> Count by </h3>
              </Grid.Column>
              <Grid.Column width={14}>
                <ActionFilter field="actionFilter" />
              </Grid.Column>
            </Grid.Row>
          )}

        </Grid>
        <Button onClick={() => this.onSubmit()}>Submit</Button>
      </SweetForm>
    );
  }
}

export default SegmentationEditor;