import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import React from 'react';
import { Modal, Grid, Table, Loader, Icon } from 'semantic-ui-react';
import DashboardPreviewModal from './DashboardPreviewModal';
import DashboardEditionModal from './DashboardEditionModal';

const getRandomString = (size) => {
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let text = "";
  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};


class StatsBoards extends React.Component {
  state = {
    loading: true,
    dashboards: undefined,
  }

  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/stats/dashboards`);
    if (data.error) {
      return alert(data.error);
    }

    console.log(data);

    this.setState({
      dashboards: data
    });
  }

  handleOpenDashboardCreationModal = ({ dashboard }) => {
    this.setState({
      modal: {
        type: 'dashboard-creation',
        dashboard: {
          ...dashboard,
          id: getRandomString(6),
          title: 'Untitled',
          creationTimestamp: Date.now()
        }
      }
    });
  }

  handleOpenDashboardEditionModal = ({ dashboard }) => {
    this.setState({
      modal: {
        type: 'dashboard-edition',
        dashboard: dashboard
      }
    });
  }

  handleOpenDashboardPreviewModal = ({ dashboard }) => {
    this.setState({
      modal: {
        type: 'dashboard-preview',
        dashboard: dashboard
      }
    });
  }

  handleCloseModal = () => {
    this.setState({
      modal: undefined
    });
  }

  handleSaveEditedDashboard = async ({ dashboard }) => {
    try {
      if (!dashboard.id) {
        throw Error('need dashboard id');
      }
      const { data } = await axios.put(`${baseUrl}/stats/dashboards/${dashboard.id}`, { dashboard });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  }

  handleSaveCreatedDashboard = async ({ dashboard }) => {
    try {
      if (!dashboard.id) {
        throw Error('need dashboard id');
      }
      const { data } = await axios.post(`${baseUrl}/stats/dashboards`, { dashboard });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  }


  componentDidMount() {
    this.handleLoad();
  }

  render() {

    const { dashboards, modal } = this.state;

    if (dashboards === undefined) {
      return <span>Loading...</span>
    }

    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}> 
              <h3>Dashboards</h3>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right"> 
              <a 
                style={{ cursor: 'pointer' }} 
                onClick={() => this.handleOpenDashboardCreationModal({ dashboard: {} }) }
              >
                Add dashboard
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>owner</Table.HeaderCell>
              <Table.HeaderCell>type</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>title</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>edit</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>preview</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>fork</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(dashboards, (dashboard, index) => (
              <Table.Row key={index + '_' + dashboard.id}>
                <Table.Cell>{dashboard.username}</Table.Cell>
                <Table.Cell>{dashboard.type}</Table.Cell>
                <Table.Cell>{dashboard.id}</Table.Cell>
                <Table.Cell>{dashboard.title}</Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon 
                      link
                      name="pencil" 
                      onClick={() => this.handleOpenDashboardEditionModal({ dashboard }) } 
                    />
                  </center>
                </Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon 
                      link
                      name="eye" 
                      onClick={() => this.handleOpenDashboardPreviewModal({ dashboard })} 
                    />
                  </center>
                </Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon 
                      link
                      name="fork" 
                      onClick={() => this.handleOpenDashboardCreationModal({ dashboard }) } 
                    />
                  </center>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>


        {(modal || {}).type === 'dashboard-preview' && (
          <DashboardPreviewModal 
            onClose={this.handleCloseModal}
            dashboard={modal.dashboard}
          />
        )}

        {(modal || {}).type === 'dashboard-edition' && (
          <DashboardEditionModal
            onClose={this.handleCloseModal}
            dashboard={modal.dashboard}
            onSave={this.handleSaveEditedDashboard}
          />
        )}

        {(modal || {}).type === 'dashboard-creation' && (
          <DashboardEditionModal
            onClose={this.handleCloseModal}
            dashboard={modal.dashboard}
            onSave={this.handleSaveCreatedDashboard}
          />
        )}

      </div>
    );
  }
}

export default StatsBoards; 
