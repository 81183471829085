import _ from 'underscore';
import React from 'react';
import {
  Message,
} from 'semantic-ui-react';

const Warnings = ({ warnings }) => (
  <div>
    {_.map(warnings, (warning, index) => (
      <Message error key={index}>
        {warning.title && <Message.Header>{warning.title}</Message.Header>}
        {warning.text && <p>{warning.text}</p>}
      </Message>
    ))}
  </div>
);

export default Warnings;
