export default
[
    {
      "text":"Paris",
      "value":"paris-region"
    },
    {
      "text":"France",
      "value":"198319371"
    },
    {
      "text":"Europe",
      "value":"europe"
    },
    {
      "text":"Etats-Unis",
      "value":"197597979"
    },
    {
      "text":"New York",
      "value":"new-york-region"
    },
    {
      "text":"San Francisco",
      "value":"san-francisco-region"
    },
    {
      "text":"Nantes",
      "value":"nantes-region"
    },
    {
      "text":"Bordeaux",
      "value":"bordeaux-region"
    },
    {
      "text":"Marseille",
      "value":"marseille-region"
    },
    {
      "text":"Lille",
      "value":"lille-region"
    },
    {
      "text":"Lyon",
      "value":"lyon-region"
    },
    {
      "text":"Angers",
      "value":"angers-region"
    },
    {
      "text":"Strasbourg",
      "value":"strasbourg-region"
    },
    {
      "text":"Nice",
      "value":"nice-region"
    },
    {
      "text":"Londres",
      "value":"londres-region"
    },
    {
      "text":"Royaume-Uni",
      "value":"198730323"
    },
    {
      "text":"Montpellier",
      "value":"montpellier-region"
    },
    {
      "text":"Bruxelles",
      "value":"bruxelles-region"
    },
    {
      "text":"Bristol",
      "value":"bristol-region"
    },
    {
      "text":"Toronto",
      "value":"toronto-region"
    },
    {
      "text":"Berlin",
      "value":"berlin-region"
    },
    {
      "text":"Allemagne",
      "value":"198664929"
    },
    {
      "text":"Clermont-Ferrand",
      "value":"clermont-ferrand-region"
    },
    {
      "text":"Barcelone",
      "value":"barcelone-region"
    },
    {
      "text":"Caen",
      "value":"caen-region"
    },
    {
      "text":"Belgique",
      "value":"198019243"
    },
    {
      "text":"Portugal",
      "value":"198200685"
    },
    {
      "text":"Los Angeles",
      "value":"los-angeles-region"
    },
    {
      "text":"Toulouse",
      "value":"toulouse-region"
    },
    {
      "text":"Biarritz",
      "value":"biarritz-region"
    },
    {
      "text":"Lisbonne",
      "value":"lisbonne-region"
    },
    {
      "text":"Rennes",
      "value":"rennes-region"
    },
    {
      "text":"Cambridge, Angleterre",
      "value":"cambdrige-region"
    },
    {
      "text":"Ontario",
      "value":"198735576"
    },
    {
      "text":"Suisse",
      "value":"198667942"
    },
    {
      "text":"Genève",
      "value":"geneve-region"
    },
    {
      "text":"Porto",
      "value":"porto-region"
    },
    {
      "text":"Lausanne",
      "value":"lausanne-region"
    },
    {
      "text":"San Diego",
      "value":"san-diego-region"
    },
    {
      "text":"Montréal",
      "value":"montreal-region"
    },
    {
      "text":"Chicago",
      "value":"chicago-region"
    },
    {
      "text":"Washington DC",
      "value":"washington-dc-region"
    },
    {
      "text":"Grenoble",
      "value":"grenoble-region"
    },
    {
      "text":"Houston",
      "value":"houston-region"
    },
    {
      "text":"Philadelphie",
      "value":"philadelphia-region"
    },
    {
      "text":"Boston",
      "value":"boston-region"
    },
    {
      "text":"Dallas",
      "value":"dallas-region"
    },
    {
      "text":"Madrid",
      "value":"madrid-region"
    },
    {
      "text":"Vancouver",
      "value":"vancouver-region"
    },
    {
      "text":"Canada",
      "value":"198904141"
    },
    {
      "text":"Espagne",
      "value":"198974448"
    },
    {
      "text":"Partout",
      "value":"everywhere"
    }
  ]
  