import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { Confirm } from 'semantic-ui-react';
import { normalizeStackoverflow, normalizeGithub } from '../Watch/Candidate/utils';

import ProfilesPipeView from './ProfilesPipeView';
import NoProfilesView from './NoProfilesView';
import ExternalProfileReceiver from './ExternalProfileReceiver';

const TARGET_MIN_NB_PENDING = 3;
const TARGET_NB_PENDING = 6;
const CRON_INTERVAL = 300;

const sleep = async (milis = 1000) => new Promise((resolve) => setTimeout(resolve, milis));
class ProfilesPipeManager extends React.Component {
  state = {
    items: [],
    currentItemFrontId: null,
    noMoreItems: false,
    progress: {},
  };

  lastProcessTimestamp = Date.now();
  lastRefreshTimestamp = Date.now();
  lockRefetchItems = false;
  lockProcessItem = false;

  initItem = ({ item, type = 'pre-pending' }) => {
    return {
      ...item,
      frontId: 'item_' + ('' + Math.random()).slice(3, 12),
      state: {
        pipeStatus: {
          type,
        },
        cacheState: {
          loading: false,
          data: null,
        },
      },
    };
  };

  refreshSearchStatus = async () => {
    const { searchId } = (this.props.currentInput || {}).pipeDescriptor || {};
    if (!searchId) {
      return;
    }

    let maxNbFastRequests = 20;
    let maxNbSlowRequests = 20;
    let nbRequests = 0;
    let searchStatus = 'pending';
    while (nbRequests < maxNbFastRequests + maxNbSlowRequests && searchStatus === 'pending') {
      nbRequests++;
      const searchStatusResponse = (await axios.get(`${baseUrl}/sweetsearch/searches/id/${searchId}/status`)).data;
      const currentSearchStatus = searchStatusResponse.status;
      searchStatus = currentSearchStatus;
      const searchStatusEvents = ((searchStatusResponse || {}).search || {}).events;
      if (searchStatus !== 'success') {
        const search = (await axios.get(`${baseUrl}/sweetsearch/searches/id/${searchId}`)).data;
        const searchStatusEvents = ((search || {}).search || {}).events;
        this.setState({ currentSearchStatus, searchStatusEvents });
      } else {
        this.setState({ currentSearchStatus, searchStatusEvents: [] });
      }
      await sleep(nbRequests < maxNbFastRequests ? 3000 : 10000);
    }
    if (nbRequests > 1) {
      this.refetchItems();
    }
  };

  componentDidMount() {
    this.refreshSearchStatus();
    this.props
        .fetchItems()
      .then((items) => {
        const newItems = _.map(items, (item, index) =>
          this.initItem({ item, type: index < TARGET_NB_PENDING ? 'pending' : 'pre-pending' }),
        );
        this.setState({ items: newItems }, () => {
          if (!_.isEmpty(newItems)) {
            this.selectItem({ itemFrontId: newItems[0].frontId });
          }
        });
      })
      .catch((e) => {
        if ((e.message || '').indexOf('pending') < 0) {
          alert(e.message);
        }
      });
    this.props.getProgress().then((progress) => {
      this.setState({ progress });
    });

    this.cronInterval = setInterval(this.cacheCron, CRON_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.cronInterval);
  }

  updateItemState = ({ itemFrontId, newState }, cb) => {
    this.setState(
      {
        items: _.map(this.state.items, (currentItem) =>
          (currentItem || {}).frontId === itemFrontId
            ? {
                ...currentItem,
                state: {
                  ...currentItem.state,
                  ...newState,
                },
              }
            : currentItem,
        ),
      },
      cb,
    );
  };

  refetchItems = async () => {
    const newItems = await this.props.fetchItems();
    this.setState({ noMoreItems: !newItems || newItems.length === 0 ? true : false });
    this.setState(
      (prevState) => {
        const previousItems = prevState.items;
        const remainingItems = _.filter(
          previousItems,
          (item) => (((item || {}).state || {}).pipeStatus || {}).type !== 'pre-pending',
        );
        const pendingItems = _.filter(
          remainingItems,
          (item) => (((item || {}).state || {}).pipeStatus || {}).type === 'pending',
        );
        const missingPendingItems = TARGET_NB_PENDING - pendingItems.length;

        const seenIdFields = {};
        _.each(remainingItems, (item) => {
          _.each((item || {}).idFields, (value, sourceId) => {
            if (!seenIdFields[sourceId]) {
              seenIdFields[sourceId] = {};
            }
            seenIdFields[sourceId][value] = true;
          });
        });

        const newItemsToAdd = _.filter(
          newItems,
          (item) => !_.some((item || {}).idFields, (val, sourceId) => (seenIdFields[sourceId] || {})[val]),
        ).slice(0, 10);

        const newInitializedItems = _.map(newItemsToAdd, (item) => this.initItem({ item }));
        if (missingPendingItems > 0) {
          for (let i = 0; i < missingPendingItems && i < newInitializedItems.length; i++) {
            newInitializedItems[i] = {
              ...newInitializedItems[i],
              state: {
                ...newInitializedItems[i].state,
                pipeStatus: {
                  type: 'pending',
                },
              },
            };
          }
        }
        return { items: [...remainingItems, ...newInitializedItems] };
      },
      () => {
        const items = this.state.items;
        if (items && items.length > 0 && items[0].frontId && !this.state.currentItemFrontId) {
          this.selectItem({ itemFrontId: items[0].frontId });
        }
      },
    );
    const progress = await this.props.getProgress();
    this.setState({ progress });
  };

  loadItem = async ({ itemFrontId, idFields, sourceProfiles }) => {
    try {
      this.updateItemState({
        itemFrontId,
        newState: {
          pipeStatus: { type: 'pending' },
          cacheState: {
            loading: true,
            data: null,
          },
        },
      });
      const startFetchItem = Date.now();
      const data = await this.props.fetchItem({
        idFields: idFields,
        sourceProfiles: sourceProfiles,
      });
      const fetchDuration = Date.now() - startFetchItem;

      this.updateItemState({
        itemFrontId,
        newState: {
          cacheState: {
            loading: false,
            data: data,
            error: null,
            lastLoadedDuration: fetchDuration,
            lastLoadedTimestamp: Date.now(),
          },
        },
      });
    } catch (e) {
      this.updateItemState({
        itemFrontId,
        newState: {
          cacheState: {
            loading: false,
            data: null,
            error: e,
          },
        },
      });
      return alert(e.message);
    }
  };

  mayLoadItem = async ({ itemFrontId }) => {
    const item = _.findWhere(this.state.items, { frontId: itemFrontId });
    if (!item) {
      return alert('no item found with frontId ' + itemFrontId);
    }
    const cacheState = (item.state || {}).cacheState || {};
    const { data, loading, error } = cacheState;
    if (!data && !loading && !error && item.idFields) {
      await this.loadItem({
        itemFrontId,
        idFields: item.idFields,
        sourceProfiles: item.sourceProfiles,
      });
    }
  };

  updateItemData = ({ itemFrontId, itemData }, callback) => {
    if (_.isEmpty(itemData)) {
      return alert('No item Data provided for update');
    }
    this.setState(
      {
        items: _.map(this.state.items, (currentItem) =>
          (currentItem || {}).frontId === itemFrontId
            ? {
                ...currentItem,
                state: {
                  ...currentItem.state,
                  cacheState: {
                    ...(currentItem.state || {}).cacheState,
                    data: itemData,
                  },
                },
              }
            : currentItem,
        ),
      },
      callback,
    );
  };

  processItem = async ({ itemFrontId, annotationType, force }) => {
    if (this.lockProcessItem) {
      return alert('item ' + itemFrontId + ' is already being processed');
    }
    this.lockProcessItem = true;
    const item = _.findWhere(this.state.items, { frontId: itemFrontId });
    this.lastProcessTimestamp = Date.now();
    if (!item || !item.state || !item.state.cacheState || !item.state.cacheState.data) {
      this.lockProcessItem = false;
      return alert('item ' + itemFrontId + ' not found in cache');
    }
    try {
      if (
        annotationType.indexOf('select') > -1 &&
        item.state.cacheState.data.education &&
        !_.findWhere(item.state.cacheState.data.education, { isMain: true }) &&
        !force
      ) {
        this.setState({
          needForceItemFrontId: itemFrontId,
          needForceAnnotationType: annotationType,
          confirmationModal: true,
        });
        this.lockProcessItem = false;
        return;
      }
      if (annotationType.indexOf('select') > -1 && this.state.progress && this.state.progress.itemsStatusCount) {
        this.setState({
          progress: {
            ...this.state.progress,
            itemsStatusCount: {
              ...this.state.progress.itemsStatusCount,
              pending: (this.state.progress.itemsStatusCount.pending || 0) + 1,
            },
          },
        });
      }
      await this.props.annotateItem({ item, annotationType });
      this.updateItemState({
        itemFrontId,
        newState: {
          pipeStatus: { type: 'processed' },
          cacheState: null,
        },
      });
      const pendingItems = _.filter(
        this.state.items,
        (item) =>
          !(((item || {}).state || {}).cacheState || {}).error &&
          (((item || {}).state || {}).pipeStatus || {}).type === 'pending' &&
          (item || {}).frontId !== itemFrontId,
      );
      if (!_.isEmpty(pendingItems)) {
        this.selectItem({ itemFrontId: _.first(pendingItems).frontId });
      } else {
        const prePendingItems = _.filter(
          this.state.items,
          (item) =>
            !(((item || {}).state || {}).cacheState || {}).error &&
            (((item || {}).state || {}).pipeStatus || {}).type === 'pre-pending' &&
            (item || {}).frontId !== itemFrontId,
        );
        if (!_.isEmpty(prePendingItems)) {
          this.selectItem({ itemFrontId: _.first(prePendingItems).frontId });
        }
      }
      this.lockProcessItem = false;
    } catch (e) {
      this.lockProcessItem = false;
      this.updateItemState({
        itemFrontId,
        newState: {
          pipeStatus: { type: 'pending' },
        },
      });
      return alert('Annotattion error: \n' + e.message);
    }
  };

  selectItem = ({ itemFrontId }) => {
    const item = _.findWhere(this.state.items, { frontId: itemFrontId });
    if (!item) {
      return alert('no item with id ' + itemFrontId);
    }
    if (((item.state || {}).pipeStatus || {}).type === 'processed') {
      return alert('item is already processed');
    }
    this.setState({
      currentItemFrontId: itemFrontId,
    });
    this.mayLoadItem({ itemFrontId });
  };

  removeItem = ({ itemFrontId }) => {
    const item = _.findWhere(this.state.items, { frontId: itemFrontId });
    if (!item) {
      return alert('no item with id ' + itemFrontId);
    }
    const { items, currentItemFrontId } = this.state;
    const newItems = _.filter(items, ({ frontId }) => frontId !== itemFrontId);
    const firstItem = _.first(newItems);
    this.setState({
      ...(currentItemFrontId &&
        currentItemFrontId === itemFrontId && {
          currentItemFrontId: (firstItem || {}).frontId,
        }),
      items: newItems,
    });
  };

  /**
   * refetch items if needed,
   * load items if needed,
   *
   * @memberof ProfilesPipeManager
   */
  cacheCron = async () => {
    // load More Profiles
    const nbLoading = _.filter(this.state.items, (item) => (((item || {}).state || {}).cacheState || {}).loading)
      .length;
    if (nbLoading === 0) {
      const loadableItems = _.filter(this.state.items, (item) => {
        const cacheState = ((item || {}).state || {}).cacheState || {};
        const pipeStatus = ((item || {}).state || {}).pipeStatus || {};
        return pipeStatus.type === 'pending' && !cacheState.loading && !cacheState.data && !cacheState.error;
      });
      if (!_.isEmpty(loadableItems)) {
        try {
          this.loadItem({ itemFrontId: loadableItems[0].frontId, idFields: loadableItems[0].idFields });
        } catch (error) {
          console.error(error.message);
        }
      }
    }
    // refresh / fill pre-pending
    const nbPending = _.filter(
      this.state.items,
      (item) => (((item || {}).state || {}).pipeStatus || {}).type === 'pending',
    ).length;

    const nbMsSinceLastRefresh = Date.now() - this.lastRefreshTimestamp;
    const nbMsSinceLastProcessAction = Date.now() - this.lastProcessTimestamp;
    if (
      (nbPending <= TARGET_MIN_NB_PENDING && nbMsSinceLastProcessAction < 30 * 1000) ||
      nbMsSinceLastRefresh > 60 * 1000
    ) {
      if (!this.lockRefetchItems && nbMsSinceLastRefresh > 3 * 1000) {
        try {
          this.lockRefetchItems = true;
          await this.refetchItems();
        } catch (error) {
          console.error(error.message);
        }
        this.lastRefreshTimestamp = Date.now();
        this.lockRefetchItems = false;
      }
    }
  };

  isAlreadyTaken = async ({ idFields }) => {
    try {
      const { workpipeId } = this.props;
      if (!workpipeId) {
        alert('no workpipeId');
        return true;
      }

      const { data } = await axios.post(`${baseUrl}/sweetwork/isItemNotAlreadyInPipe`, {
        idFields: idFields,
        workPipeId: workpipeId,
      });

      return data.response !== false;
    } catch (e) {
      console.log(e);
      return true;
    }
  };

  onAddProfile = async ({ idFields, sourceProfiles }) => {
    const isAlreadyTaken = await this.isAlreadyTaken({ idFields });
    if (isAlreadyTaken) {
      return alert('already taken !');
    }

    const currentItems = this.state.items;
    const newItem = this.initItem({
      item: { idFields, sourceProfiles, removable: true },
      type: 'pending',
    });
    this.setState(
      {
        items: [newItem, ...currentItems],
      },
      () => {
        this.selectItem({ itemFrontId: newItem.frontId });
      },
    );
  };

  onUpdateProfile = async ({ idFields, sourceProfiles }) => {
    const isAlreadyTaken = await this.isAlreadyTaken({ idFields });
    if (isAlreadyTaken) {
      return alert('already taken !');
    }
    const { items, currentItemFrontId } = this.state;
    if (!currentItemFrontId) {
      return alert('no item selected');
    }
    const currentItem = _.findWhere(items, { frontId: currentItemFrontId });
    if (!currentItem) {
      return alert('current item not found');
    }
    const newItem = this.initItem({
      item: {
        idFields: {
          ...currentItem.idFields,
          ...idFields,
        },
        sourceProfiles,
        removable: currentItem.removable,
      },
      type: 'pending',
    });
    this.setState(
      {
        items: _.map(items, (item) => (item.frontId !== currentItemFrontId ? item : newItem)),
      },
      () => {
        this.selectItem({ itemFrontId: newItem.frontId });
      },
    );
  };

  onRemoveSource = async ({ sourceId, id, sourceIdToRemove, idToRemove }) => {
    let normalizedIdToRemove;
    if (sourceIdToRemove === 'stackoverflow') {
      normalizedIdToRemove = normalizeStackoverflow(idToRemove);
    } else if (sourceIdToRemove === 'github') {
      normalizedIdToRemove = normalizeGithub(idToRemove);
    } else {
      normalizedIdToRemove = encodeURIComponent(idToRemove);
    }
    await axios.delete(`${baseUrl}/sweetynotes/${sourceId}/${id}/matches/${sourceIdToRemove}/${normalizedIdToRemove}`);
  };

  render() {
    if (
      _.isEmpty(this.state.items) ||
      !this.state.currentItemFrontId ||
      _.every(this.state.items, (item) => (((item || {}).state || {}).pipeStatus || {}).type === 'processed')
    ) {
      return (
        <div>
          <ExternalProfileReceiver onAddProfile={this.onAddProfile} onUpdateProfile={this.onUpdateProfile} />
          <NoProfilesView
            currentSearchStatus={this.state.currentSearchStatus}
            searchStatusEvents={this.state.searchStatusEvents}
            noMoreItems={this.state.noMoreItems}
          />
        </div>
      );
    }

    if (this.state.confirmationModal) {
      return (
        <Confirm
          open
          content='This profile has no main education. Are you sure you want to continue ?'
          onCancel={() =>
            this.setState({ needForceItemFrontId: null, needForceAnnotationType: null, confirmationModal: false })
          }
          onConfirm={() => {
            const { needForceItemFrontId, needForceAnnotationType } = this.state;
            this.setState({ needForceItemFrontId: null, needForceAnnotationType: null, confirmationModal: false });
            this.processItem({
              itemFrontId: needForceItemFrontId,
              annotationType: needForceAnnotationType,
              force: true,
            });
          }}
        />
      );
    }

    return (
      <div style={{ marginTop: '-20px', marginRight: '-20px' }}>
        <ExternalProfileReceiver onAddProfile={this.onAddProfile} onUpdateProfile={this.onUpdateProfile} />
        <ProfilesPipeView
          currentItemFrontId={this.state.currentItemFrontId}
          items={this.state.items}
          annotationActions={this.props.annotationActions}
          processItem={this.processItem}      
          selectItem={this.selectItem}
          removeItem={this.removeItem}
          updateItemData={this.updateItemData}
          jobOfferId={this.props.jobOfferId}
          offer={this.props.offer}
          sweetappOffer={this.props.sweetappOffer}
          mergedOffer={this.props.mergedOffer}
          workpipeId={this.props.workpipeId}
          feedbackModeEnabled={this.props.feedbackModeEnabled}
          toggleFeedbackMode={this.props.toggleFeedbackMode}
          sendSearchFeedback={this.props.sendSearchFeedback}
          progress={this.state.progress}
          onRemoveSource={this.onRemoveSource}
        />
      </div>
    );
  }
}

export default ProfilesPipeManager;
