import React from 'react';
import { Provider, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { matchRoutes } from 'react-router-config';

import './Regressions.css';

import { routes, buildRoutes } from './routes';
import { store } from './redux';

class _Regressions extends React.Component {
  reloadFromUrl = () => {
    const { dispatch, history } = this.props;
    matchRoutes(routes, history.location.pathname).map(({ route, match }) => {
      return route.onLoad
        ? route.onLoad(match, dispatch)
        : Promise.resolve(null);
    });
  };

  componentDidMount() {
    this.reloadFromUrl();

    this.props.history.listen(this.reloadFromUrl);
  }
  render() {
    const Routes = buildRoutes(routes);
    return <Switch>{Routes}</Switch>;
  }
}

const Regressions = withRouter(connect()(_Regressions));

export default function() {
  return (
    <Provider store={store}>
      <Regressions />
    </Provider>
  );
}
