import React from 'react';
import { Grid, Icon, Button, Form } from 'semantic-ui-react';
import _ from 'underscore';

import WeightedLocations from './WeightedLocations';

export default class Matches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e, { name, value }, match) => {
    this.setState({ [name]: value });

    name === 'begin' ? this.props.onSubmitBegin(match, value) : this.props.onSubmitEnd(match, value);
  };

  render() {
    return (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            {this.props.matches
              ? _.map(this.props.matches, (match) => (
                  <Grid key={match.key} divided>
                    <Grid.Row columns={3} centered>
                      <Grid.Column>
                        {this.props.editable ? (
                          <Button icon negative onClick={() => this.props.deleteMatch(match)}>
                            <Icon name="close" />
                          </Button>
                        ) : null}
                      </Grid.Column>
                      <Grid.Column>
                        Begin: {match.begin}{' '}
                        {this.props.editable ? (
                          <Form onSubmit={() => this.props.onSubmitBegin(match, this.state.begin)}>
                            <Form.Input
                              name="begin"
                              onChange={(e, v) => this.handleChange(e, v, match)}
                              defaultValue={match.begin}
                            />
                          </Form>
                        ) : null}
                      </Grid.Column>
                      <Grid.Column>
                        End: {match.end}{' '}
                        {this.props.editable ? (
                          <Form onSubmit={() => this.props.onSubmitEnd(match, this.state.end)}>
                            <Form.Input
                              name="end"
                              onChange={(e, v) => this.handleChange(e, v, match)}
                              defaultValue={match.end}
                            />
                          </Form>
                        ) : null}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} centered>
                      <Grid.Column>
                        <WeightedLocations
                          match={match}
                          addNewWeightedLocation={this.props.addNewWeightedLocation}
                          deleteWeightedLocation={this.props.deleteWeightedLocation}
                          handleLocationSearchSubmit={this.props.handleLocationSearchSubmit}
                          editable={this.props.editable}
                          loadOptions={this.props.loadOptions}
                          weightedLocations={match.weightedLocations}
                          onSubmitProba={this.props.onSubmitProba}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ))
              : null}
          </Grid.Column>
        </Grid.Row>
        {this.props.editable ? (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Button icon onClick={this.props.addNewMatch}>
                <Icon name="plus" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    );
  }
}
