import React from 'react';
import _ from 'underscore';
import { Button, Grid, Icon } from 'semantic-ui-react';

export const LabelingButtons = ({ disabled, annotate, actions }) => (
  <div className="actionsContainer">
    <Grid style={{ margin: '0px', width: '380px' }}>
      <Grid.Row columns={6} style={{ padding: '0px' }}>
        {_.map(actions, ({ backgroundColor, icon, name, targetState }, index) => (
          <Grid.Column style={{ padding: '0px' }} key={index}>
            <Button
              disabled={disabled}
              circular
              color={backgroundColor || 'green'}
              size="massive"
              style={{ padding: '.78571429em .78571429em .78571429em' }}
              onClick={annotate(targetState)}
            >
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  fontSize: '16px',
                  lineHeight: '30px',
                  verticalAlign: 'middle',
                }}
              >
                {!name ? <Icon name={icon} /> : name || ''}
              </div>
            </Button>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  </div>
);
