import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Label, LabelGroup, Grid, Icon, Modal, Card, Divider } from 'semantic-ui-react';
import { Accordion, Input } from 'semantic-ui-react';
import Offer from '../components/Offer';
import OfferCriteriaDiff from '../components/OfferCriteriaDiff';

export const sweethubToSweetappCriteria = (criteria) => {
  const locationItems = (((criteria || {}).locations || {}).target || []).concat(
    ((criteria || {}).locations || {}).okWith || [],
  );
  const locationCriteria = _.map(locationItems, (item) => ({ identifier: item.location.id }));

  const targetExperience = ((criteria || {}).experience || {}).target;
  const experienceCriteria = {
    years: {
      min: (targetExperience || {}).min,
      max: (targetExperience || {}).max,
    },
  };

  const majorJobPostion = (((criteria || {}).jobPosition || {}).major || {}).id;
  const majorList = majorJobPostion ? [majorJobPostion] : [];
  const minorJobPositions = _.map(((criteria || {}).jobPosition || {}).minors || [], (jobPos) => jobPos.id);
  const allJobPositions = majorList.concat(minorJobPositions);
  const jobPositionCriteria = {
    required: _.map(allJobPositions, (jobPos) => ({ identifier: jobPos })),
  };

  const allRequiredSkills = _.map(((criteria || {}).skills || {}).main || [], (skillGroup) => {
    return _.map((skillGroup || {}).skills || [], (skill) => skill.id);
  });
  const allImportantSkills = _.map(((criteria || {}).skills || {}).important || [], (skillGroup) => {
    return _.map((skillGroup || {}).skills || [], (skill) => skill.id);
  });
  const allBonusSkills = _.map(((criteria || {}).skills || {}).bonus || [], (skillGroup) => {
    return _.map((skillGroup || {}).skills || [], (skill) => skill.id);
  });

  const requiredSkillsCriteria = _.map(_.filter(allRequiredSkills, (s) => !_.isUndefined(s)), (skillId) => ({
    identifier: skillId,
  }));
  const importantSkillsCriteria = _.map(_.filter(allImportantSkills, (s) => !_.isUndefined(s)), (skillId) => ({
    identifier: skillId,
  }));
  const bonusSkillsCriteria = _.map(_.filter(allBonusSkills, (s) => !_.isUndefined(s)), (skillId) => ({
    identifier: skillId,
  }));

  const sweetappCriteria = {
    locations: locationCriteria,
    experience: experienceCriteria,
    jobPositions: jobPositionCriteria,
    skills: {
      required: requiredSkillsCriteria,
      important: importantSkillsCriteria,
      bonus: bonusSkillsCriteria,
    },
  };
  return sweetappCriteria;
};

class CriteriaModificationModal extends Component {
  render() {
    const { oldCriteria, newCriteria, onClose } = this.props;

    const oldCriteriaSweetapp = sweethubToSweetappCriteria(oldCriteria);
    const newCriteriaSweetapp = sweethubToSweetappCriteria(newCriteria);

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header />
        <Modal.Content>
          <OfferCriteriaDiff
            oldCriteria={oldCriteriaSweetapp}
            newCriteria={newCriteriaSweetapp}
            noOldCriteria={_.isEmpty(oldCriteriaSweetapp)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const scoreToBackgroundColor = (score) => {
  if (score / 100 > 0.9) {
    return '#21BA45';
  }
  if (score / 100 > 0.75) {
    return 'green';
  }
  if (score / 100 > 0.6) {
    return 'olive';
  }
  if (score / 100 > 0.4) {
    return 'yellow';
  }
  if (score / 100 > 0.2) {
    return 'orange';
  }
  return 'red';
};

const cleanPath = (path) => {
  if (path === '0') {
    return 'this';
  }
  return path.replace('0.data', 'profile').replace('descriptionHTML', 'description');
};

class TextBlock extends Component {
  render() {
    const { type, operator, content, rawContent } = this.props || {};
    return (
      <div>
        {operator ? <div> Operator : {operator} </div> : null}
        {type ? <div> Type : {type} </div> : null}
        {rawContent ? <div>{rawContent} </div> : null}
        {_.map(content || [], (sentence, key) => (
          <div>
            <Divider horizontal />
            <Sentence {...sentence} key={key} />
          </div>
        ))}
      </div>
    );
  }
}

class JobPostingTechAsset extends Component {
  render() {
    const { content, rawContent } = this.props || {};
    return (
      <div>
        {_.map(content || [], (textBlock, key) => (
          <Card>
            <TextBlock
              {...textBlock}
              rawContent={
                textBlock.location && textBlock.location.length == 2
                  ? rawContent.slice(textBlock.location[0], textBlock.location[1])
                  : null
              }
              key={key}
            />
          </Card>
        ))}
      </div>
    );
  }
}

class Info extends Component {
  render() {
    const { rawContent, perkType, start, end, salaryInterval, phoneNumber, email, address, perkInfo } =
      this.props || {};
    return (
      <div>
        {perkType ? (
          <Label
            style={{
              backgroundColor: '#21BA45',
            }}
          >
            {' '}
            {perkType}
          </Label>
        ) : null}
        {perkInfo && perkInfo.salary ? <div> Salary : {perkInfo.salary} </div> : null}
        {perkInfo && perkInfo.percentage ? <div> Percentage : {perkInfo.percentage}</div> : null}
        {perkInfo && perkInfo.foodTicketsType ? <div> Food Tickets : {perkInfo.foodTicketsType}</div> : null}
        {rawContent ? <div> Applied on : {rawContent.join(' | ')}</div> : null}
        {start ? (
          <div> Start {(start.day || '?') + '/' + (start.month || '?') + '/' + (start.year || '?')} </div>
        ) : null}
        {end ? <div> End {(end.day || '?') + '/' + (end.month || '?') + '/' + (end.year || '?')} </div> : null}
        {salaryInterval ? (
          <div>
            Salary : + [{salaryInterval[0] || '?'}, {salaryInterval[1] || '?'}]
          </div>
        ) : null}
        {phoneNumber ? <div>Phone Number : {phoneNumber}</div> : null}
        {email ? <div>Email : {email}</div> : null}
        {address ? <div>Address : {address}</div> : null}
      </div>
    );
  }
}

class Modality extends Component {
  render() {
    const { importance, numberOfMonths } = this.props || {};
    return (
      <div>
        {importance ? <div>Importance : {importance}</div> : null}
        {numberOfMonths ? <div>{numberOfMonths} months</div> : null}
      </div>
    );
  }
}

class Sentence extends Component {
  render() {
    const { type, info, rawContent, modality } = this.props || {};
    return (
      <div>
        {' '}
        {type ? (
          <Label
            style={{
              backgroundColor: '#21BA45',
            }}
          >
            {' '}
            {type}
          </Label>
        ) : null}
        {rawContent}
        {info ? (
          <div>
            <b>Parsed Info :</b>
            <Info {...info} />
          </div>
        ) : null}
        {modality ? (
          <div>
            {' '}
            <b>Modality : </b>
            <Modality {...modality} />{' '}
          </div>
        ) : null}
      </div>
    );
  }
}

class FunctionResult extends Component {
  renderScore(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        <b>{item !== null ? (100 * item).toPrecision(3) : null} </b> - {functionId}({cleanedPaths})
      </span>
    );
  }
  renderXpYear(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        <b>{item !== null ? item.toPrecision(2) : null} </b> - {functionId}({cleanedPaths})
      </span>
    );
  }
  renderYear(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        <b>{item !== null ? item : null} </b> - {functionId}({cleanedPaths})
      </span>
    );
  }
  renderScoreList(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        <b>{item !== null ? _.map(item, (i) => i.toPrecision(3)).join(', ') : null} </b> - {functionId}({cleanedPaths})
      </span>
    );
  }
  renderSentenceList(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span>
        {functionId}({cleanedPaths})
        <div>
          {_.map(item, (sentence, key) => (
            <Sentence {...sentence} key={key} />
          ))}
        </div>
      </span>
    );
  }
  renderJobPosting(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span>
        {functionId}({cleanedPaths})
        <div>
          <JobPostingTechAsset {...item} />
        </div>
      </span>
    );
  }

  displayCriteriaModifications(oldCriteria, newCriteria) {
    this.setState({ oldCriteria, newCriteria, criteriaModificationModalVisible: true });
  }

  onCloseCriteriaModificationModal = () => {
    this.setState({ criteriaModificationModalVisible: false });
  };

  renderCriteria(functionId, item, paths, explanation) {
    const { inputData } = this.props;
    const inputOffer = (_.find(inputData, (data) => data.argname === 'offer') || {}).value;
    const inputCriteria = (inputOffer || {}).criteria;

    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    const rawOffer = { criteria: item };
    return (
      <span>
        {functionId}({cleanedPaths})
        <div>
          {inputCriteria ? (
            <div style={{ textAlign: 'right' }}>
              <a style={{ cursor: 'pointer' }} onClick={() => this.displayCriteriaModifications(inputCriteria, item)}>
                <Icon name='eye' color='blue' /> <b style={{ color: 'blue' }}> Display modifications</b>
              </a>
            </div>
          ) : null}
          <Offer offer={rawOffer} />
        </div>
      </span>
    );
  }

  renderJobPosition(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span>
        {functionId}({cleanedPaths})
        <div>
          <h5>
            {' '}
            fields :
            {_.map(item.fields, (field, key) => (
              <Label
                key={key}
                style={{
                  backgroundColor: '#21BA45',
                }}
              >
                {' '}
                {field}
              </Label>
            ))}
          </h5>
          {item.responsibility ? <h5> Responsibility : {item.responsibility} </h5> : null}
          {item.tags && item.tags.length > 3 ? <h5> Tags : {item.tags} </h5> : null}
          {item.seniority ? <h5> Seniority : {item.seniority} </h5> : null}
        </div>
      </span>
    );
  }

  renderBoolean(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        <b>{item + ''} </b> - {functionId}({cleanedPaths})
      </span>
    );
  }

  renderScoreDict(functionId, item, paths, explanation, coef = 100) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    const entityIdFilter = this.props.entityIdFilter;
    let scoredEntities = _.sortBy(item, ({ score }) => -score);
    if (!_.isEmpty(entityIdFilter)) {
      scoredEntities = _.filter(scoredEntities, ({ entityId }) => {
        return entityId && entityId.includes(entityIdFilter);
      });
    }
    return (
      <span title={explanation}>
        {functionId}({cleanedPaths})
        {_.map(scoredEntities, (i, key) => (
          <Label
            key={key}
            style={{
              backgroundColor: scoreToBackgroundColor(i.score * coef),
            }}
            title={Math.round(i.score * coef, 2)}
          >
            {i.entityId + ' (' + Math.round(i.score * coef, 2) + ')'}
          </Label>
        ))}
      </span>
    );
  }

  renderScoreXp(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        {functionId}({cleanedPaths})
        {_.map(item, (i, key) => (
          <Label
            key={key}
            style={{
              backgroundColor: scoreToBackgroundColor(i.score * 20),
            }}
            title={Math.round(10 * i.score, 2) / 10}
          >
            {i.entityId + ' (' + Math.round(10 * i.score, 2) / 10 + ')'}
          </Label>
        ))}
      </span>
    );
  }

  defaultRender(functionId, item, paths, explanation) {
    const cleanedPaths = _.map(paths || [], (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation || ''}>
        {functionId}({cleanedPaths})
      </span>
    );
  }

  renderParsedEntities(functionId, item, paths) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span>
        {_.map(item, (entity, i) => (
          <Label
            key={i}
            style={{
              backgroundColor: '#21BA45',
            }}
          >
            {entity}
          </Label>
        ))}
        {' - '} {functionId}({cleanedPaths})
      </span>
    );
  }

  renderDictList(functionId, item, paths) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    const itemList = _.sortBy(
      _.mapObject(item, (value, key) => ({ job: key, timeline: value })),
      (item) => -item.timeline.reduce((s, f) => s + f, 0),
    );
    return (
      <span>
        {' - '} {functionId}({cleanedPaths})
        {_.map(itemList, (item, i) => (
          <div>
            <h3> {item.job} </h3>
            <LabelGroup key={i}>
              {_.map(item.timeline, (v, key) => (
                <Label
                  key={key + '-' + i}
                  style={{
                    backgroundColor: scoreToBackgroundColor(v * 100),
                  }}
                >
                  {Math.round(v * 100, 2)}
                </Label>
              ))}
            </LabelGroup>
          </div>
        ))}
      </span>
    );
  }

  renderDictScoreDict(functionId, item, paths, explanation, coef = 100) {
    const cleanedPaths = _.map(paths, (path) => cleanPath(path)).join(', ');
    return (
      <span title={explanation}>
        {' - '} {functionId}({cleanedPaths})
        {_.map(item, (typeResults) => (
          <div>
            <h4> {typeResults.type} </h4>
            {_.map(typeResults.results, (i, key) => (
              <Label
                key={key}
                style={{
                  backgroundColor: scoreToBackgroundColor(i.score * coef),
                }}
                title={Math.round(i.score * coef, 2)}
              >
                {i.entityId + ' (' + Math.round(i.score * coef, 2) + ')'}
              </Label>
            ))}
            <br />
            <br />
          </div>
        ))}
      </span>
    );
  }

  getResultComponent(functionId, item, paths, explanation) {
    let typeResult = functionId ? this.props.functionMapper(functionId) : null;
    let processedItem = item;

    if (!typeResult) {
      if (item && _.isObject(item) && _.isNumber(_.values(item)[0])) {
        processedItem = _.map(item, (value, key) => ({ entityId: key, score: value }));
        typeResult = 'score-dict';
      }
    }

    if (typeResult == 'parsed-entities') {
      return this.renderParsedEntities(functionId, processedItem, paths);
    }
    if (typeResult == 'score') {
      return this.renderScore(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'score-prestige') {
      return this.renderScore(functionId, processedItem / 5, paths, explanation);
    }
    if (typeResult == 'json') {
      return (
        <div>
          <h3>
            <pre> {JSON.stringify(processedItem, null, 4)} </pre>
          </h3>
        </div>
      );
    }
    if (typeResult == 'score-dict') {
      return this.renderScoreDict(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'year') {
      return this.renderYear(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'xp-dict') {
      return this.renderScoreXp(functionId, processedItem, paths, explanation, 1);
    }
    if (typeResult == 'xp-year') {
      return this.renderXpYear(functionId, processedItem, paths, explanation, 1);
    }
    if (typeResult == 'dict-list') {
      return this.renderDictList(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'job-position') {
      return this.renderJobPosition(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'normalized-score-dict') {
      return this.renderScoreDict(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'score-list') {
      return this.renderScoreList(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'job-posting') {
      return this.renderJobPosting(functionId, processedItem, paths, explanation);
    }
    if (typeResult === 'criteria') {
      return this.renderCriteria(functionId, processedItem, paths, explanation);
    }
    if (typeResult === 'sentence-list') {
      return this.renderSentenceList(functionId, processedItem, paths, explanation);
    }
    if (typeof item === 'number') {
      return this.renderScore(functionId, processedItem, paths, explanation);
    }
    if (typeof item === 'boolean') {
      return this.renderBoolean(functionId, processedItem, paths, explanation);
    }
    if (typeResult == 'dict-score-dict') {
      return this.renderDictScoreDict(functionId, processedItem, paths, explanation);
    }
    return this.defaultRender(functionId, processedItem, paths, explanation);
  }

  async handleClickExpandDetails() {
    const subResultExpanded = (this.state || {}).subResultExpanded === undefined ? true : !this.state.subResultExpanded;
    this.setState({ subResultExpanded: subResultExpanded });
  }

  render() {
    const { explanation, handleClickSubResult, functionId, subResults, item, paths, isFirst } = this.props;
    const key = '' + Math.random();
    const resultComponent = item !== null ? this.getResultComponent(functionId, item, paths, explanation) : null;
    const { criteriaModificationModalVisible } = this.state || {};
    return (
      <div style={{ width: '100%' }}>
        {criteriaModificationModalVisible ? (
          <CriteriaModificationModal
            oldCriteria={(this.state || {}).oldCriteria}
            newCriteria={(this.state || {}).newCriteria}
            onClose={this.onCloseCriteriaModificationModal}
          />
        ) : null}
        <Accordion key={key} rows={subResults.length || 1} style={{ padding: '0px' }}>
          <Accordion.Title active onClick={() => this.handleClickExpandDetails()} style={{ padding: '0px' }}>
            {subResults.length >= 1 ? (
              (this.state || {}).subResultExpanded ? (
                <Icon name='caret down' />
              ) : (
                <Icon name='caret right' />
              )
            ) : null}
            {resultComponent}
            {paths && !isFirst && this.props.functionMapper(functionId) ? (
              <a
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleClickSubResult({
                    paths,
                    function: functionId,
                  })
                }
              >
                {' - Try It'}
              </a>
            ) : null}
          </Accordion.Title>
          {subResults && subResults.length > 0 && (this.state || {}).subResultExpanded ? (
            <div>
              {_.map(subResults || [], (subResult, key) => {
                return (
                  <span key={key}>
                    <Accordion.Content active style={{ marginLeft: '1.5rem', padding: '0px' }}>
                      <FunctionResult
                        item={subResult.item}
                        subResults={subResult.subResults}
                        functionId={subResult.function}
                        explanation={subResult.explanation}
                        functionMapper={this.props.functionMapper}
                        handleClickSubResult={handleClickSubResult}
                        paths={subResult.paths}
                        isFirst={false}
                        entityIdFilter={this.props.entityIdFilter}
                      />
                    </Accordion.Content>
                  </span>
                );
              })}
            </div>
          ) : null}
        </Accordion>
      </div>
    );
  }
}

export default FunctionResult;
