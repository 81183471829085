import React from 'react';
import { Icon } from 'semantic-ui-react';

class Collapsible extends React.Component {
  constructor() {
    super();
    this.state = { height: 0, collapsed: true, isOverflown: false };
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  updateHeight = () => {
    if (this.div && this.state.height !== this.div.clientHeight) {
      this.setState({
        height: this.div.clientHeight,
        isOverflown: this.parentDiv.scrollHeight > this.parentDiv.clientHeight,
      });
    }
  };
  render() {
    const { children, maxHeight } = this.props;
    const { collapsed, height, isOverflown } = this.state;
    const style = collapsed ? { maxHeight } : {};
    return (
      <div onClick={this.toggleCollapse} style={{position: 'relative'}}>
        <div className='collapsible-content' style={style} ref={(div) => (this.parentDiv = div)}>
          <div ref={(div) => (this.div = div)}>{children}</div>
          {collapsed && !(height <= maxHeight) ? (
            <div>
              <div className='collapsible-bottom-gradient' style={{ top: maxHeight - 15 }} />
              <Icon name='caret down' />
            </div>
          ) : null}
        </div>
        {isOverflown && collapsed && (
          <div style={{ position: 'absolute', bottom: 3, right: -22 }}>
            <Icon name='caret down' />
          </div>
        )}
      </div>
    );
  }
}

export default Collapsible;
