import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import FolderedSubmissionsSelector from './FolderedSubmissionsSelector';
import ChunkSubmissionsDashboard from './ChunkSubmissionsDashboard';

class ChallengeDataset extends React.Component {
  state = {};

  componentDidMount() {
    this.handleLoadMetrics().then(() => {
      this.handleLoadChunks().then(() => {
        this.handleLoadFolderedSubmissions();
      });
    })
  }

  handleLoadMetrics = async () => {
    const { challengeId, datasetId } = this.props;
    const url = `${baseUrl}/challenges/${challengeId}/datasets/${datasetId}/metrics`;
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const metrics = data.metrics;
    return new Promise((resolve) => {
      this.setState({ metrics }, () => resolve());
    });
  };

  handleLoadChunks = async () => {
    const { challengeId, datasetId } = this.props;
    const url = `${baseUrl}/challenges/${challengeId}/datasets/${datasetId}/chunks`;
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const chunks = data.results;
    return new Promise((resolve) => {
      this.setState({ chunks }, () => resolve());
    });
  };

  handleLoadFolderedSubmissions = async () => {
    const { challengeId, datasetId } = this.props;
    const { chunks, metrics } = this.state;

    const url = `${baseUrl}/challenges/${challengeId}/datasets/${datasetId}/submissionsFolders`;
    const { data } = await axios.post(url, { metrics });
    if (data.error) {
      return alert(data.error);
    }

    const getScoreFromExecutions = ({ chunks, executions, isOptimized }) => {
      const scoreByChunkId = {};
      _.each(executions, (execution) => {
        const score = isOptimized ? execution.optimizedScore : execution.score;
        if (score !== undefined) {
          scoreByChunkId[execution.chunkId] = score;
        }
      });
      return (
        _.reduce(
          chunks,
          (memo, chunk) => memo + (scoreByChunkId[chunk.id] !== undefined ? scoreByChunkId[chunk.id] : 0),
          0,
        ) / Math.max(1, chunks.length)
      );
    };

    const submissionsFolders = _.map(data.results, (folder) => ({
      ...folder,
      submissions: _.map(folder.submissions, (submission) => ({
        ...submission,
        score: getScoreFromExecutions({ chunks, executions: submission.executions, isOptimized: false }),
        optimizedScore: getScoreFromExecutions({ chunks, executions: submission.executions, isOptimized: true }),
        selected: true,
        superSelected: false,
        isInSelection: true,
      })),
      selected: true,
      superSelected: false,
    }));

    _.each(submissionsFolders, (folder) => {
      const bestScore = _.reduce(
        folder.submissions,
        (memo, { score }) => (score !== undefined && (memo === undefined || score > memo) ? score : memo),
        undefined,
      );
      const bestOptimizedScore = _.reduce(
        folder.submissions,
        (memo, { optimizedScore }) => (optimizedScore !== undefined && (memo === undefined || optimizedScore > memo) ? optimizedScore : memo),
        undefined,
      );

      if (bestScore) {
        folder.score = bestScore;
      }
      if (bestOptimizedScore) {
        folder.optimizedScore = bestOptimizedScore;
      }
    });

    const selectedSubmissions = _.flatten(_.pluck(submissionsFolders, 'submissions'));

    this.setState({ submissionsFolders, selectedSubmissions });
  };

  handleChangeSubmissionsFoldersSelection = ({ submissionsFolders }) => {
    const selectedSubmissions = _.where(_.flatten(_.pluck(submissionsFolders, 'submissions')), { isInSelection: true });

    this.setState({ submissionsFolders, selectedSubmissions });
  };

  handleChangeMetricsSelection = ({ metrics }) => {
    this.setState({ metrics, submissionsFolders: [], selectedSubmissions: [] }, () => {
      this.handleLoadFolderedSubmissions()
    })
  }

  onDeleteSubmission = async ({ submissionId }) => {
    const { challengeId } = this.props;
    const url = `${baseUrl}/challenges/${challengeId}/submissions/${submissionId}`;
    await axios.delete(url);
    return this.handleLoadChunks().then(() => {
      return this.handleLoadFolderedSubmissions();
    });
  };

  render() {
    const { challengeId, datasetId } = this.props;
    const { metrics, chunks, submissionsFolders, selectedSubmissions } = this.state;

    if (!submissionsFolders || !chunks) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: '120px',
          left: 0,
          height: 'calc(100vh - 120px)',
          width: '100vw',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '20%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <FolderedSubmissionsSelector
              submissionsFolders={submissionsFolders}
              onChangeSubmissionsFoldersSelection={this.handleChangeSubmissionsFoldersSelection}
              onDeleteSubmission={this.onDeleteSubmission}
            />
          </div>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '80%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <ChunkSubmissionsDashboard
              challengeId={challengeId}
              datasetId={datasetId}
              chunks={chunks}
              submissions={selectedSubmissions}
              onChangeMetricsSelection={this.handleChangeMetricsSelection}
              metrics={metrics}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ChallengeDataset;
