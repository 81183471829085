import React from 'react';
import _ from 'underscore';
import { Grid, Label } from 'semantic-ui-react';

import { Stack } from '../Stack';
import TranslatableText from '../TranslatableText';
import { getTranslatedText } from '../../common';
import skillOptions from '../../common/options/skillOptions.json';

const colorStyles = {
  neutral: { backgroundColor: '#E6F5F9', color: '#2EA4CC' },
  validated: { backgroundColor: '#E6F9EE', color: '#2ECC71' },
  warning: { backgroundColor: '#FBEAD0', color: '#F2994A' },
  bonus: { backgroundColor: '#E6F5F9', color: '#2EA4CC' },
};

const iconNames = {
  neutral: 'star-ticket',
  validated: 'green-check',
  warning: 'eye',
  bonus: 'star-ticket',
};

const upperCaseTitleStyle = {
  textTransform: 'uppercase',
  fontFamily: 'SourceSansPro',
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '16px',
  color: '#BDBDBD',
};
const UpperCaseTitle = ({ children, style, className }) => (
  <h3 className={className} style={{ ...upperCaseTitleStyle, ...style }}>
    {children}
  </h3>
);

const Review = ({ content, stack, title, t }) =>
  (content && content.default && (
    <div className='summary-tag-stack-container'>
      <div className='stack-container'>
        <div className='summary-title'>
          <UpperCaseTitle style={{ marginBottom: 8, marginTop: 4 }}>
            {(title || {}).default ? <TranslatableText text={title} /> : t('profile.resume.summary-watch')}
          </UpperCaseTitle>
        </div>
        {stack && (
          <div className='stack'>
            <Stack stack={stack} displaySoftSkills={true} context='summary' />
          </div>
        )}
      </div>
      <div className='profile-summary'>
        <TranslatableText text={content} useBold={true} />
      </div>
    </div>
  )) ||
  null;

const SmartSummary = ({ argumentation, neutralMode, skills, t }) => {
  if (_.isEmpty(argumentation)) {
    return null;
  }
  const review = _.find(argumentation, { type: 'review' }) || {};
  const smartTags = _.find(argumentation, { type: 'grouped-key-points' }) || {};

  return (
    <Grid style={{ marginTop: 16, marginBottom: 0 }}>
      <Grid.Row>
        <Grid.Column>
          <SmartTags smartTags={smartTags} neutralMode={neutralMode} t={t} /> {/* neutralMode ? */}
          {!_.isEmpty(skills) && (
            <div style={{ marginTop: 12 }}>
              <SkillsLabels skills={skills} />
            </div>
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Review {...review} t={t} stack={review.stack} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const SmartTags = ({ smartTags, neutralMode, t }) => {
  const tags = getTags({ smartTags, neutralMode });

  return (
    <div className='smart-tags-container'>
      <LineTag neutralMode={neutralMode} t={t} {...tags.jobPosition} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.experience} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.management} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.location} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.salary} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.looking} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.availability} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.contract} />
      <LineTag neutralMode={neutralMode} t={t} {...tags.language} />
      {_.map(tags.unCategorized, (tag, i) => (
        <LineTag neutralMode={neutralMode} t={t} key={i} {...tag} />
      ))}
    </div>
  );
};

const LineTag = ({ neutralMode, label, content, type, icon, t }) => {
  if (!label) {
    return null;
  }

  return (
    <div
      className='smart-tag-line expanded'
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: '4px',
      }}
    >
      <div
        className='smart-tag-line-prefix'
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '10px',
          width: '16px',
        }}
      >
        <i className={`${icon}`} />
      </div>
      <TranslatableText text={label} />
      <span>&nbsp;·&nbsp;</span>

      <span className={'smart-tag-line-value'} style={{ fontFamily: 'SourceSansProSemiBold' }}>
        <TranslatableText text={content} />
      </span>

      {!neutralMode && (
        <span
          style={{
            flexShrink: 0,
            padding: '4.5px 8px',
            marginLeft: '12px',
            borderRadius: '22px',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '13px',
            ...colorStyles[type],
          }}
        >
          <img
            src={`/images/${iconNames[type]}.svg`}
            style={{ height: 10, width: 10, marginRight: 4 }}
            alt={type + 'tag'}
          />
          {t(`profile.resume.smartTags.pill.${type}`, '')}
        </span>
      )}
    </div>
  );
};

const categories = [
  'jobPosition',
  'experience',
  'management',
  'location',
  'salary',
  'looking',
  'availability',
  'contract',
  'language',
];

const tagCategoryToIcon = {
  jobPosition: 'ri-briefcase-4-line',
  experience: 'ri-time-line',
  management: 'ri-user-2-line',
  location: 'ri-map-pin-line',
  salary: 'ri-wallet-line',
  looking: 'ri-search-line',
  availability: 'ri-calendar-check-line',
  contract: 'ri-article-line',
  language: 'ri-flag-line',
};

const defaultCategoryIcon = 'ri-focus-line';

export const tagIconFromCategory = (category) => tagCategoryToIcon[category] || defaultCategoryIcon;

// TODO: improve
const isSkillTag = (tag) => {
  const regex = new RegExp('.*echnolog.*');
  return !!regex.test(getTranslatedText((tag || {}).label));
};

export const getTags = ({ smartTags, neutralMode }) => {
  const tags = {
    unCategorized: [],
  };
  _.each(smartTags.groups, ({ category: type, keyPoints = [] }) => {
    // neutral tags are redundant with the other ones
    if (neutralMode && type !== 'neutral') {
      return;
    }
    if (!neutralMode && type === 'neutral') {
      return;
    }
    _.each(keyPoints, (relevantTag) => {
      if (isSkillTag(relevantTag)) {
        return;
      }
      const tag = { ...relevantTag, type, icon: tagIconFromCategory(relevantTag.category) };
      if (categories.includes(tag.category)) {
        tags[tag.category] = tag;
      } else {
        tags.unCategorized.push(tag);
      }
    });
  });
  return tags;
};

const SkillsLabels = ({ skills }) => {
  const displayedSkills = _.compact(
    _.map(skills, (skill) => {
      const label = getOptionText({ value: (skill || {}).id });
      if (!label) {
        return null;
      }
      return { ...skill, label };
    }),
  );

  return (
    <div className='skills-labels'>
      {_.map(displayedSkills, (skill, index) => {
        return (
          <Label
            size='large'
            key={`${index}-${(skill || {}).skillId}`}
            className={getSkillTagClassName({ index, relevance: (skill || {}).relevance })}
          >
            <span>{(skill || {}).label}</span>
          </Label>
        );
      })}
    </div>
  );
};

export const getSortedSkillsWishes = ({ hiresweetData }) => {
  const skills = hiresweetData.getWishes().getSkills();
  const target = skills.getTarget().getRawValue();
  const okWith = skills.getOkWith().getRawValue();
  const mergedWishes = [...(target || []), ...(okWith || [])];
  return _.sortBy(mergedWishes, ({ relevance }) => -relevance);
};

export const getSkillTagClassName = ({ relevance }) => {
  if (relevance >= 10) {
    return 'skill-tag important';
  }
  if (relevance >= 6) {
    return 'skill-tag medium';
  }
  return 'skill-tag normal';
};

const getOptionText = ({ value }) => {
  return (_.findWhere(skillOptions, { value }) || {}).text;
};

export default SmartSummary;
