import React from 'react';
import _ from 'underscore';
import { Form, Dropdown } from 'semantic-ui-react';

class RefreshMoveDetectorSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addedOptions: [] };
    this.handleDelayChange = this.handleDelayChange.bind(this);
    this.handlePeremptionChange = this.handlePeremptionChange.bind(this);  }

  handleDelayChange(e, data) {
    this.props.onSettingsChange({
      nb_weeks: data.value,
      peremption_days: this.props.settings.peremption_days,
    });
  }

  handlePeremptionChange(e, data) {
    this.props.onSettingsChange({
      nb_weeks: this.props.settings.nb_weeks,
      peremption_days: data.value,
    });
  }

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      addedOptions: [{ text: value, value }, ...prevState.addedOptions],
    }));
  };

  render() {
    const settings = this.props.settings || { nb_weeks: [], peremption_days: []};
    const options_nb_weeks = this.state.addedOptions.concat(
      _.map(settings.nb_weeks, (week) => ({
        key: week,
        value: week,
        text: week,
      })),
    );
    const options_peremption_days = this.state.addedOptions.concat(
      _.map(settings.peremption_days, (day) => ({
        key: day,
        value: day,
        text: day,
      })),
    );
    
    return (
      <Form>
        <Form.Field>
          <label>Dernière réponse positive (semaines)</label>
          <Dropdown
            fluid
            search
            selection
            multiple
            allowAdditions
            placeholder="Select locations"
            value={settings.nb_weeks}
            onChange={this.handleDelayChange}
            onAddItem={this.handleAddition}
            options={options_nb_weeks}
          />
        </Form.Field>

        <Form.Field>
          <label>Profil scrapé il y a plus de (jours)</label>
          <Dropdown
            fluid
            search
            selection
            multiple
            allowAdditions
            placeholder="Select skills"
            value={settings.peremption_days}
            onChange={this.handlePeremptionChange}
            onAddItem={this.handleAddition}
            options={options_peremption_days}
          />
        </Form.Field>

      </Form>
    );
  }
}

export default RefreshMoveDetectorSettings;
