import React from 'react';
import _ from 'underscore';
import { Segment, Label, Popup, Grid } from 'semantic-ui-react';

const getColorFromScore = (score) => {
  if (score === undefined || score === null) {
    return `red`;
  }
  const red = [208, 25, 25];
  const yellow = [251, 189, 8];
  const green = [33, 186, 69];
  let fade = score * 2;

  let color1 = red;
  let color2 = yellow;
  if (fade >= 1) {
    fade -= 1;
    color1 = yellow;
    color2 = green;
  }
  const rgbColor = [
    Math.floor(color1[0] * (1 - fade) + color2[0] * fade),
    Math.floor(color1[1] * (1 - fade) + color2[1] * fade),
    Math.floor(color1[2] * (1 - fade) + color2[2] * fade),
  ];
  return `rgb(${rgbColor[0]},${rgbColor[1]},${rgbColor[2]})`;
};

const CustomLabel = ({ id, score }) => (
  <Popup
    on='hover'
    content={Math.floor(score * 100) / 100}
    trigger={
      <Label size='large' style={{ backgroundColor: getColorFromScore(score), cursor: 'pointer', color: 'black' }}>
        {id}
      </Label>
    }
    size='mini'
  />
);

export const JobPositions = ({ jobPositions }) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <div style={{ fontFamily: 'SourceSansProSemiBold', lineHeight: '20px', marginRight: 6, minWidth: 70 }}>
      Job Positions:
    </div>
    <div>
      {((jobPositions || {}).major || {}).id && (
        <div style={{ marginBottom: 4 }}>
          <CustomLabel id={jobPositions.major.id} score={jobPositions.major.score} />
        </div>
      )}

      {!_.isEmpty((jobPositions || {}).minors || []) && (
        <div>
          {_.map(jobPositions.minors, (minorJobPosition, index) => (
            <CustomLabel key={index} id={minorJobPosition.id} score={minorJobPosition.score} />
          ))}
        </div>
      )}
    </div>
  </div>
);

export const Skills = ({ skills }) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <div style={{ fontFamily: 'SourceSansProSemiBold', lineHeight: '20px', marginRight: 6 }}>Skills:</div>
    <div>
      {!_.isEmpty((skills || {}).required || []) && (
        <div style={{ marginBottom: 4 }}>
          {_.map(skills.required, (requiredSkill, index) => (
            <CustomLabel key={index} id={requiredSkill.id} score={requiredSkill.score} />
          ))}
        </div>
      )}
      {!_.isEmpty((skills || {}).important || []) && (
        <div style={{ marginBottom: 4 }}>
          {_.map(skills.important, (importantSkill, index) => (
            <CustomLabel key={index} id={importantSkill.id} score={importantSkill.score} />
          ))}
        </div>
      )}
      {!_.isEmpty((skills || {}).bonus || []) && (
        <div>
          {_.map(skills.bonus, (bonusSkill, index) => (
            <CustomLabel key={index} id={bonusSkill.id} score={bonusSkill.score} />
          ))}
        </div>
      )}
    </div>
  </div>
);

const Criterion = ({ criterion, score }) => {
  if (!criterion.id || !criterion.params) {
    return null;
  }

  const params = criterion.params || {};
  const sons = params.son
    ? [params.son]
    : params.son1 && params.son2
    ? [params.son1, params.son2]
    : params.sons
    ? params.sons
    : params.weightedSons
    ? _.map(params.weightedSons, ({ son }) => son)
    : [];
  const getBaseParamsString = (params) =>
    _.reduce(
      params,
      (memo, val, key) => {
        if (['son', 'sons', 'son1', 'son2', 'weightedSons'].indexOf(key) >= 0) {
          return memo;
        }
        return memo + (memo ? ', ' : '') + key + ':' + JSON.stringify(val);
      },
      '',
    );

  const getLabelContent = (criterion) => {
    const concatenateObjectValues = (item) => {
      return _.isObject(item) ? _.map(_.values(item), (val) => JSON.stringify(val)).join(', ') : ''
    }
    
    if (criterion.id === "globalBackground" && _.isObject((criterion.params || {}).params)){
      let text = criterion.params.params.scorerId || ''
      if (_.omit(criterion.params.params, 'scorerId')){
        text += " " + concatenateObjectValues(_.omit(criterion.params.params, 'scorerId'))
      }
      return text
    }
    if (criterion.id === "advancedBackground" && _.isObject(((criterion.params || {}).params || {}).typology)){
      let text = (criterion.params.params.typology.id || '')
      if (criterion.params.params.typology.params){
        text += " " + concatenateObjectValues(criterion.params.params.typology.params)
      }
      if (text.length > 100){
        text = text.slice(0, 100) + " ..."
      }
      return text
    }
    return criterion.id
  }

  return (
    <div style={{ marginBottom: 4 }}>
      {!_.isEmpty(sons) ? (
        <Popup
          size='small'
          trigger={
            <Label
              size='large'
              content={
                criterion.id +
                ' (' +
                _.reduce(_.uniq(_.pluck(sons, 'id')), (memo, sonId) => memo + (memo ? ', ' : '') + sonId, '') +
                ')'
              }
              style={{ cursor: 'pointer', color: 'black', backgroundColor: getColorFromScore(score) }}
            />
          }
          content={
            <div>
              {_.map(sons, (sonCriterion, index) => (
                <div key={index}>
                  {sonCriterion.id}
                  {_.isEmpty(sonCriterion.params) ? '' : ': (' + getBaseParamsString(sonCriterion.params) + ')'}
                </div>
              ))}
            </div>
          }
        />
      ) : _.isEmpty(criterion.params) ? (
        <Label
          content={criterion.id}
          style={{ cursor: 'default', color: 'black', backgroundColor: getColorFromScore(score) }}
        />
      ) : (
        <Popup
          size='small'
          trigger={
            <Label
              size='large'
              content={getLabelContent(criterion)}
              style={{ cursor: 'pointer', color: 'black', backgroundColor: getColorFromScore(score) }}
            />
          }
          content={getBaseParamsString(criterion.params)}
        />
      )}
    </div>
  );
};

const ExtraCriteria = ({ extraCriteria }) => {
  if (_.isEmpty(extraCriteria)) {
    return null;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6 }}>
      <div style={{ fontFamily: 'SourceSansProSemiBold', lineHeight: '20px', marginRight: 6, minWidth: 70 }}>
        Extra:
      </div>
      <div>
        {_.map(extraCriteria, (criterion, index) => (
          <Criterion criterion={criterion.criterion} score={criterion.score} key={index} />
        ))}
      </div>
    </div>
  );
};

const GlobalExperience = ({ globalExperience }) => {
  if (_.isEmpty(globalExperience) || !_.isNumber(globalExperience.value)) {
    return null;
  }
  const backgroundColor = globalExperience.isBelowTargetMax
    ? '#21ba45'
    : globalExperience.isBelowOkMax
    ? '#fbbd08'
    : '#F33800';
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6 }}>
      <div style={{ fontFamily: 'SourceSansProSemiBold', lineHeight: '20px', marginRight: 6, minWidth: 70 }}>
        Global Experience:
      </div>
      <Label size='large' style={{ backgroundColor, cursor: 'default', color: 'black' }}>
        {Math.round(globalExperience.value * 10) / 10}
      </Label>
    </div>
  );
};

const JobExperience = ({ jobExperience }) => {
  if (_.isEmpty(jobExperience) || !_.isNumber(jobExperience.value)) {
    return null;
  }
  const backgroundColor = jobExperience.isAboveTargetMin
    ? '#21ba45'
    : jobExperience.isAboveOkMin
    ? '#fbbd08'
    : '#F33800';
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6 }}>
      <div style={{ fontFamily: 'SourceSansProSemiBold', lineHeight: '20px', marginRight: 6, minWidth: 70 }}>
        Job Experience:
      </div>
      <Label size='large' style={{ backgroundColor, cursor: 'default', color: 'black' }}>
        {Math.round(jobExperience.value * 10) / 10}
      </Label>
    </div>
  );
};

const ProfileScores = ({ profileScores }) => {
  if (!profileScores || _.isEmpty(profileScores)) {
    return null;
  }
  return (
    <Segment color='green'>
      <h2>Scores</h2>
      {(!_.isEmpty(profileScores.jobPositions) || !_.isEmpty(profileScores.skills)) && (
        <Grid columns={2}>
          <Grid.Column>
            <JobPositions jobPositions={profileScores.jobPositions || {}} />
            <JobExperience jobExperience={profileScores.jobExperience || {}} />
            <GlobalExperience globalExperience={profileScores.globalExperience || {}} />
          </Grid.Column>
          <Grid.Column>
            <Skills skills={profileScores.skills || {}} />
          </Grid.Column>
        </Grid>
      )}
      <ExtraCriteria extraCriteria={profileScores.extraCriteria || []} />
    </Segment>
  );
};

export default ProfileScores;
