import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { CustomTable } from './TableWidget';

const getDataFromDescriptor = async ({ dataDescriptor }) => {
  const BIG_QUERY_TIMESLOT_PERCENTILE = "bigquery-timeslot-percentile"
  if (dataDescriptor.type === BIG_QUERY_TIMESLOT_PERCENTILE) {
    const { data } = await axios.post(`${baseUrl}/bigQueryStats/timeAggregationPercentile`, {
      ...dataDescriptor,
    });
    console.log(data)
    return data
  }
  throw Error(`Unrecognized data type : ${dataDescriptor.type}, should be in ${BIG_QUERY_TIMESLOT_PERCENTILE}`)
}
class LineChartWidget extends React.Component {
  state = {
    loading: true,
    initialDataDescriptor: undefined,
    dataDescriptor: undefined,
    toDisplay: {
      dataDescriptor: undefined,
      results: undefined,
    }
  };

  constructor(props) {
    super(props);
    this.state.initialDataDescriptor = this.props.data;
  }

  handleLoad = async ({ dataDescriptor }) => {

    try {
      const data = await getDataFromDescriptor({ dataDescriptor });
      if (data.error) {
        throw Error(data.error);
      }

      this.setState({
        loading: false,
        toDisplay: {
          dataDescriptor,
          results: data.results,
        }
      });
      console.log(data);
    } catch (e) {
      return alert(e);
    }
  };

  handleDisplaySelection = ({ selection }) => {
    this.setState({ selection });
  }

  handleCloseSelection = async () => {
    this.setState({ selection: null });
  }

  componentDidMount() {
    if (this.state.initialDataDescriptor) {
      this.handleLoad({ dataDescriptor: this.state.initialDataDescriptor });
    }
  }

  renderChart({ toDisplay }) {

    console.log({ toDisplay });

    if (!toDisplay) {
      return <div />;
    }

    const { dataDescriptor, results } = toDisplay;

    const data = results;

    const firstGroup = (dataDescriptor.groups || [])[0];

    return (
      <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" />
      <YAxis />
      <Tooltip />
      <Legend />
      {(_.map(dataDescriptor.percentiles, ({ value, color }) => (
                <Line
                  type="monotone"
                  dataKey={`percentile_${value}`}
                  stroke= {color}
                />
        ))
      )}
      </LineChart>
    );
  }

  renderSelection = ({ selection }) => {
    return (
      <Modal
        open={true}
        onClose={() => this.handleCloseSelection()}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {(selection || {}).title}
        </Modal.Header>
        <Modal.Content style={{ overflowY: 'scroll', height: '700px' }}>
          <center>
            {selection.type == "table" ? (<CustomTable data={selection.data} />) : null}
          </center>
        </Modal.Content>
      </Modal>
    )
  }

  render() {

    if (this.state.loading) {
      return (
        <center>
          <br /><br /><br />
          Loading...{' '}<Loader inline active />
          <br /><br /><br />
        </center>
      );
    }

    return (
      <div>
        <h4>{this.props.name}</h4>
        {this.renderChart({ toDisplay: this.state.toDisplay })}
      </div>
    );
  }
}

export default LineChartWidget;
