import _ from 'underscore';


const filterHasTag = ({sheetItems, tag}) => {
    return _.filter(sheetItems, ({extraTags})=>{
        return (extraTags || []).indexOf(tag) >= 0
    })
}


const filterSheetItems = ({ sheetItems, filterBy }) => {
    if (filterBy=='noProcess'){
        return _.filter(sheetItems, ({matches, id})=>{
            const nbProcess = _.filter(matches, ({state, disqualified})=>{
                if (!disqualified && ["contacted", "answered","in-process", "hired"].indexOf(state) >= 0){
                    console.log(state, disqualified)
                    return true
                }
                return false
            }).length
            return nbProcess == 0
        })
    }
    if (filterBy=='toReview'){
        return filterHasTag({sheetItems, tag: 'topCandidate'})
    }
    return sheetItems
}
export default filterSheetItems