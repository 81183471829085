import React from 'react';
import { Icon, Grid, Header, Table, Label, Button, Modal, Segment } from 'semantic-ui-react';
import SweetappProfile from '../../components/SweetappProfile';
import axios from 'axios';
import moment from 'moment';
import _, { each } from 'underscore';
import baseUrl from '../../baseUrl';
import OfferCriteria from '../../Offers/OfferCriteria';
import OfferActionView from '../../Offers/OfferActionView';

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};


const MatchingLevel = ({ matchingInfo }) => {
  const level = (matchingInfo || {}).level || 0
  const color = {
    5: 'green',
    4: 'olive',
    3: 'yellow',
    2: 'orange',
    1: 'red',
    0: 'brown',
  }[level];
  return <Label color={color}>{level}</Label>
}

const NotPassingFilter = ({ matchingInfo }) => {
  const filterIds = []
  _.each((matchingInfo || {}).hardMfResult, (passes, filter) => {
    if (!passes) {
      filterIds.push(filter)
    }
  })
  return (
    <div>
      {_.map(_.sortBy(filterIds), (filterId) => (
        <Label basic color='orange' key={Math.random()}>{filterId}</Label>
      ))}
    </div>
  )
}

const ProfileNameAndTags = ({ onClick, profile, displayedProfileId, clientId }) => {
  const { firstname, lastname, creationTimestamp, interestClientIds, globalFavorite, id } = profile;
  const isNew = creationTimestamp && creationTimestamp > 1000 * moment().startOf('isoWeek').unix();
  const showedInterest = (interestClientIds || []).indexOf(clientId) >= 0;
  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      <span style={id == displayedProfileId ? { fontWeight: 'bold' } : {}}>{firstname}{' '}{lastname}{' '}</span>
      {isNew && <Label basic size='mini' color='green'>New</Label>}
      {globalFavorite && <Label basic size='mini' color='yellow'>Star</Label>}
      {showedInterest && <Label basic size='mini' color='blue'>Showed Interest</Label>}
    </div>
  )
}

const ShortlistProfileButton = ({ shortlistNumber, onClick }) => {
  if (_.isNumber(shortlistNumber)) {
    return <Button onClick={onClick}>{shortlistNumber}</Button>
  }
  return <Button icon onClick={onClick}><Icon name='square outline' /></Button>
}


const ActionButtons = ({ onClickLabel }) => {
  return (
    <div>
      <Button onClick={() => onClickLabel('Skip')} color={'red'}>Skip</Button>
      <Button.Group>
        <Button onClick={() => onClickLabel('D')} color={'orange'}>D</Button>
        <Button onClick={() => onClickLabel('C')} color={'yellow'}>C</Button>
        <Button onClick={() => onClickLabel('B')} color={'olive'}>B</Button>
        <Button onClick={() => onClickLabel('A')} color={'green'}>A</Button>
      </Button.Group>
    </div>

  )

}

const profilesToHtml = ({ profiles, clientId, jobOfferId }) => {
  return (
    <div>
      {_.map(profiles, (profile, index) => {
        const { id, firstname, blurbFirstLine } = profile;
        const encodedId = encodeURIComponent(btoa(id))
        const profileInOfferLink = `https://app.hiresweet.com/client/${clientId}/jobs/${jobOfferId}/profiles?pid=${encodedId}`;
        return (
          <div key={id}>- <a href={profileInOfferLink}>{firstname}</a>: {blurbFirstLine}<br/></div>
        )
      })}
    </div>
  )
}

const skipProfile = async ({ jobOfferId, profileId }) => {
  await axios.put(
    `${baseUrl}/station/offers/${jobOfferId}/profiles/${profileId}/state`,
    { state: 'skipped' },
  );
}

class JobShortlist extends React.Component {

  state = {
    selectedModal: null,
    displayedProfileIndex: 0,
    profiles: [],
  };

  componentDidMount() {
    const { jobShortlist } = this.props;
    const { pendingProfiles } = jobShortlist;
    this.setState({
      profiles: _.sortBy(pendingProfiles, ({ matchingInfo }) => -(matchingInfo || {}).level)
    });
  }

  labelProfile = ({ id, label }) => {
    let { profiles } = this.state;
    profiles = _.map(profiles, (p) => {
      if (p.id == id) {
        return { ...p, label };
      } else {
        return p;
      }
    })
    if (label == 'Skip') {
      const { id: jobOfferId } = (this.props.jobShortlist || {}).jobOffer;
      skipProfile({ profileId: id, jobOfferId });
    }
    this.setState({ profiles });
    this.displayNextProfile();
  }

  displayProfile = ({ index }) => {
    this.setState({ displayedProfileIndex: index });
  };

  displayNextProfile = () => {
    const { profiles, displayedProfileIndex } = this.state;
    if (displayedProfileIndex + 1 < profiles.length) {
      this.setState({ displayedProfileIndex: displayedProfileIndex + 1 });
    } else {
      this.setState({ displayedProfileIndex: null });
    }
  };

  toggleProfileShortlist = ({ targetId }) => {
    const { profiles } = this.state;
    const shortlistedProfiles = _.filter(profiles, ({ shortlistNumber }) => _.isNumber(shortlistNumber))
    const profileIdToShortlistNumber = _.object(_.pluck(shortlistedProfiles, 'id'), _.pluck(shortlistedProfiles, 'shortlistNumber'));
    const wasShortlisted = !!profileIdToShortlistNumber[targetId];
    let newProfileIdToShortlistNumber = {};
    if (!wasShortlisted) {
      const maxShortlistNumber = _.isEmpty(shortlistedProfiles) ? 0 : Math.max(..._.pluck(shortlistedProfiles, 'shortlistNumber'));


      newProfileIdToShortlistNumber = { ...profileIdToShortlistNumber, [targetId]: maxShortlistNumber + 1 };
    } else {
      const remainingProfiles = _.filter(shortlistedProfiles, ({ id }) => (id != targetId))
      const newShortlistedProfiles = _.sortBy(remainingProfiles, 'shortlistNumber');
      _.each(newShortlistedProfiles, ({ id }, i) => {
        newProfileIdToShortlistNumber[id] = i + 1;
      });
    };
    const newProfiles = _.map(profiles, (p) => ({
      ...p,
      shortlistNumber: newProfileIdToShortlistNumber[p.id],
    }));

    this.setState({ profiles: newProfiles });

  }

  onCopyShortlistedProfiles = () => {
    const { id: jobOfferId } = (this.props.jobShortlist || {}).jobOffer;
    copyToClip(document.getElementById(`shortlistedProfiles${jobOfferId}`).innerHTML);
  }

  onCopyTopProfiles = () => {
    const { id: jobOfferId } = (this.props.jobShortlist || {}).jobOffer;
    copyToClip(document.getElementById(`topProfiles${jobOfferId}`).innerHTML);
  }

  onOpenModal = (modalId) => {
    this.setState({ selectedModal: modalId });
  }

  onCloseModal = () => {
    this.setState({ selectedModal: null });
  }

  render() {
    const { profiles, displayedProfileIndex, selectedModal } = this.state;
    const displayedProfileId = _.isNumber(displayedProfileIndex) && displayedProfileIndex < profiles.length && profiles[displayedProfileIndex].id;

    const { title, id: jobOfferId, offerId, clientId } = (this.props.jobShortlist || {}).jobOffer;
    //const { lastSendSkipTimestamp, nbSendsCurrentWeek, nbSendsThirtyDays} = ((this.props.jobShortlist || {}).jobStats || {}).sweetappActionStats || {};

    const shortlistedProfiles = _.sortBy(
      _.filter(this.state.profiles, ({ shortlistNumber }) => _.isNumber(shortlistNumber)),
      ({ shortlistNumber }) => shortlistNumber,
    );
    const topProfiles = _.first(profiles, 3);



    return (
      <Segment>
        <Grid columns={3}>
          <Grid.Column width={4}>
            <Header as='h2'><a href={`https://app.hiresweet.com/client/${clientId}/jobs/${jobOfferId}`} target="_blank">{title}</a></Header>
          </Grid.Column>
          <Grid.Column width={5}>
            <Button onClick={() => this.onOpenModal("offer-criteria")}>Criteria</Button>
            <Button onClick={() => this.onOpenModal("offer-actions")}>Last Actions</Button>
          </Grid.Column>
          <Grid.Column width={5}>
            <Button size='big' color='blue' icon onClick={this.onCopyTopProfiles}>
              <Icon name='copy' />3 Top Profiles
            </Button>
            <Button size='big' disabled={shortlistedProfiles.length == 0} onClick={this.onCopyShortlistedProfiles} color='blue' icon>
              <Icon name='copy' />{shortlistedProfiles.length} Shortlisted Profiles
            </Button>
            <div id={`shortlistedProfiles${jobOfferId}`} style={{ display: 'none' }}>
              {profilesToHtml({ profiles: shortlistedProfiles, clientId, jobOfferId })}
            </div>
            <div id={`topProfiles${jobOfferId}`} style={{ display: 'none' }}>
              {profilesToHtml({ profiles: topProfiles, clientId, jobOfferId })}
            </div>
          </Grid.Column>


        </Grid>
        <Grid columns={2}>
          <Grid.Column width={6}>
            <b>{profiles.length} profiles</b>
            <div style={{ overflowY: 'scroll', height: '600px' }}>
              <Table basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Score</Table.HeaderCell>
                    <Table.HeaderCell>To Check</Table.HeaderCell>
                    <Table.HeaderCell>Profile</Table.HeaderCell>
                    <Table.HeaderCell>Label</Table.HeaderCell>
                    <Table.HeaderCell>Shortlist</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(profiles, (profile, index) => (
                    <Table.Row key={Math.random()}>
                      <Table.Cell>
                        <span>{profile.subStep ? 'extended' : (profile.step == 'pre-pending') ? 'pre-pending' : ''}</span>
                      </Table.Cell>
                      <Table.Cell>
                        <MatchingLevel matchingInfo={profile.matchingInfo} />
                      </Table.Cell>
                      <Table.Cell>
                        <NotPassingFilter matchingInfo={profile.matchingInfo} />
                      </Table.Cell>
                      <Table.Cell>
                        <ProfileNameAndTags profile={profile} onClick={() => this.displayProfile({ index })} displayedProfileId={displayedProfileId} clientId={clientId}/>
                      </Table.Cell>
                      <Table.Cell>
                        {profile.label}
                      </Table.Cell>
                      <Table.Cell>
                        <ShortlistProfileButton shortlistNumber={profile.shortlistNumber} onClick={() => this.toggleProfileShortlist({ targetId: profile.id })} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            {displayedProfileId ? (
              <div>
                <div style={{ overflowY: 'scroll', height: '600px' }}>
                  <SweetappProfile key={displayedProfileId} profileId={displayedProfileId} />
                </div>
                <div style={{textAlign: 'center'}}>
                  <ActionButtons onClickLabel={(label) => this.labelProfile({ id: displayedProfileId, label })} />

                </div>
              </div>
            ) : (
              <div>No profile selected</div>
            )}
          </Grid.Column>
        </Grid>
        {selectedModal == "offer-criteria" && (
          <Modal closeIcon open={true} onClose={this.onCloseModal}>
            <Modal.Content>
              <OfferCriteria jobOfferId={jobOfferId} />

            </Modal.Content>
          </Modal>
        )}
        {selectedModal == "offer-actions" && (
          <Modal closeIcon open={true} onClose={this.onCloseModal}>
            <Modal.Content>
              <OfferActionView offerId={offerId} />
            </Modal.Content>
          </Modal>
        )}
      </Segment>


    );
  }
}

class ClientShortlists extends React.Component {

  state = {
    loading: true
  };

  componentWillMount() {
    this.loadJobShortlists();
  }

  loadJobShortlists = async () => {
    try {
      const jobShortlists = ((await axios.get(`${baseUrl}/salesOptimisationApi/shortlists/${this.props.clientId}`)).data).results;
      const sortedJobShortlists = _.sortBy(jobShortlists, ({ jobStats }) => -(jobStats.nbPendingsTopMatch || 0))
      this.setState({ jobShortlists: sortedJobShortlists, loading: false });
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  }

  /*handleCopySelectionToClipBoard = () => {
    //copyToClip(document.getElementById('foo').innerHTML)
    const list = (
      <ul id="foo" style={{ 'display': 'none' }}>
        <li><a href="http://google.com">Profile 1</a></li>
        <li><a href="http://google.com">Profile 1</a></li>
        <li><a href="http://google.com">Profile 1</a></li>
      </ul>
    )
    copyToClip(list.innerHTML)
  }*/

  render() {
    const { jobShortlists, loading } = this.state;
    if (loading) {
      return <Icon name='circle notched' loading />
    }
    return (
      <div>
        <Header as="h2">{this.props.clientId}</Header>

        {_.map(jobShortlists, (jobShortlist) => (
          <JobShortlist jobShortlist={jobShortlist} />
        ))}
      </div>
    );
  }
}
export default ClientShortlists;
