import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Table, Card, Button } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class AnswerStats extends Component {
  state = {};
  loadMode = async ({ mode }) => {
    this.setState({ mode }, async () => {
      const { data } = await axios.get(`${baseUrl}/strat/answers/stats?` + (mode==='leadgen' ? 'onlyLeadgen': 'onlyWatch') + `=true`);
      this.setState(data);
    });
  };
  renderTable(category) {
    const { byDays } = this.state;
    const groupingFunc = (day) => day.slice(0, 5);
    let groups = [];
    _.each(byDays, (dayStats) => {
      const day = dayStats.day;
      const groupId = groupingFunc(day);
      if (_.isEmpty(groups) || _.last(groups).id !== groupId) {
        const newGroup = {
          id: groupId,
          nbSent: 0,
          nbCounted: 0,
          positive: 0,
          medium: 0,
          negative: 0,
          bug: 0,
          bounce: 0,
          unknown: 0,
          none: 0,
        };
        groups.push(newGroup);
      }
      const curStats = dayStats[category] || {};
      _.last(groups).nbSent += curStats.nbSent || 0;
      _.last(groups).nbCounted += curStats.nbCounted || 0;
      _.last(groups).positive += curStats.positive || 0;
      _.last(groups).medium += curStats.medium || 0;
      _.last(groups).negative += curStats.negative || 0;
      _.last(groups).bug += curStats.bug || 0;
      _.last(groups).bounce += curStats.bounce || 0;
      _.last(groups).unknown += curStats.unknown || 0;
      _.last(groups).none += curStats.none || 0;
    });
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{category}</Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              <Table.HeaderCell>#Sends</Table.HeaderCell>
              <Table.HeaderCell>#Counted</Table.HeaderCell>
              <Table.HeaderCell>#Positive</Table.HeaderCell>
              <Table.HeaderCell>#Medium</Table.HeaderCell>
              <Table.HeaderCell>#Negative</Table.HeaderCell>
              <Table.HeaderCell>#None</Table.HeaderCell>
              <Table.HeaderCell>#Unknown</Table.HeaderCell>
              <Table.HeaderCell>#Bug</Table.HeaderCell>
              <Table.HeaderCell>#Bounce</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(groups, (group, index) => (
                <Table.Row key={index}>
                  <Table.Cell key={'id_' + index}>
                    {group.id}
                  </Table.Cell>
                  <Table.Cell key={'nb_sent_' + index}>
                    {group.nbSent}
                  </Table.Cell>
                  <Table.Cell key={'nb_counted_' + index}>
                    {group.nbCounted}
                  </Table.Cell>
                  <Table.Cell key={'nb_pos_' + index}>
                    {group.positive}
                    <br /> (
                    {Math.round(
                      (group.positive / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                  <Table.Cell key={'nb_medium_' + index}>
                    {group.medium} (
                    {Math.round(
                      (group.medium / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                  <Table.Cell key={'nb_negative_' + index}>
                    {group.negative} (
                    {Math.round(
                      (group.negative / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                  <Table.Cell key={'nb_none_' + index}>
                    {group.none} (
                    {Math.round(
                      (group.none / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                  <Table.Cell key={'nb_unknown_' + index}>
                    {group.unknown}(
                    {Math.round(
                      (group.unknown / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                  <Table.Cell key={'nb_bounce_' + index}>
                    {group.bounce}(
                    {Math.round(
                      (group.bounce / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                  <Table.Cell key={'nb_bugs_' + index}>
                    {group.bug} (
                    {Math.round(
                      (group.bug / Math.max(1, group.nbCounted)) * 10000,
                    ) / 100}
                    %)
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

        </Card.Content>
      </Card>
    );
  }
  render() {

    if (!this.state.mode) {
      return (
        <div>
          <Button color="blue" onClick={() => this.loadMode({ mode: 'leadgen' }) }>Leadgen</Button>
          <Button color="orange" onClick={() => this.loadMode({ mode: 'watch' }) }>Watch</Button>
        </div>
      )
    }

    return (
      <div>
        {this.renderTable('total')}
        <br />
        <br />
        {_.map(this.state.categories, (category, index) => (
          <div key={index}>
            {this.renderTable(category)}
            <br />
          </div>
        ))}
      </div>
    );
  }
}

export default AnswerStats;
