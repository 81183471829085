import _ from 'underscore';
import React from 'react';
import {
  Grid,
} from 'semantic-ui-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList
} from 'recharts'

class SyntheticGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      target: 'all',
    };
  }

  handleSetCountMode = () => {
    this.setState({ percentMode: false, dailyMode: false });
  }
  handleSetPercentMode = () => {
    this.setState({ percentMode: true, dailyMode: false });
  }
  handleSetDailyMode = () => {
    this.setState({ percentMode: false, dailyMode: true });
  }

  handleSetTargetAll = () => {
    this.setState({ target: 'all' });
  };
  handleSetTargetLeadGen = () => {
    this.setState({ target: 'lead-gen' });
  };
  handleSetTargetWatch = () => {
    this.setState({ target: 'watch' });
  };
  render() {
    const { leadgenStats, watchStats } = this.props;

    if (!leadgenStats || !watchStats) {
      return <div />;
    }

    const dataByName = {};

    _.each(
      [
        { stats: leadgenStats, source: 'lead-gen' }, 
        { stats: watchStats, source: 'watch' }
      ], 
      ({ stats, source }, sourceIndex) => {
        const slots = _.last(stats[this.props.splitMode].slots, this.props.nbSplits);
        _.each(slots, ({ title, byUser, total, nbWeekDays }) => {
          if (!dataByName[title]) {
            dataByName[title] = {
              name: title,
            };
          }
          dataByName[title]['total_' + source ] = (total['sends'] || {}).synthetic || 0;
          dataByName[title]['total_all-sources'] = dataByName[title]['total_' + source ] + (
            dataByName[title]['total_all-sources'] || 0
          );
    
          _.each(byUser, (userStats, user) => {
            dataByName[title][user + '_' + source] = (userStats['sends'] || {}).synthetic || 0;
            dataByName[title][user + '_all-sources'] = ((userStats['sends'] || {}).synthetic || 0) + (
              dataByName[title][user + '_all-sources'] || 0
            );
          });

          if (this.state.dailyMode && sourceIndex === 1) {
            _.each(_.keys(dataByName[title]), (key) => {
              if (key !== 'name') {
                dataByName[title][key] /= (nbWeekDays || 1);
              }
            });
          }
        });
      },
    );

    const data = _.values(dataByName);
    const userKey = this.props.targetUser || 'total';
    const round = (x) => Math.round(100 * x) / 100

    if (this.state.percentMode) {
      _.each(data, (dataPart) => {
        dataPart[userKey + '_lead-gen'] =
          (100 * dataPart[userKey + '_lead-gen']) /
          (dataPart[userKey + '_all-sources'] || 1);
        dataPart[userKey + '_watch'] =
          (100 * dataPart[userKey + '_watch']) /
          (dataPart[userKey + '_all-sources'] || 1);
      });
    }

    const style = (key) => {
      const selected = (
        this.state.target === key
      ) || (
        key === 'percent' && this.state.percentMode
      ) || (
        key === 'daily' && this.state.dailyMode      
      ) || (
        key === 'count' && !this.state.percentMode && !this.state.dailyMode
      );
      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    return (
      <Grid><Grid.Row>
        <Grid.Column width={12}>
          <BarChart 
            data={data}
            width={450} height={200} 
            margin={{ left: -10 }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis padding={{ top: 20 }} />

            <Tooltip formatter={(value, name, props) => {
              if (!this.state.percentMode) {
                return [
                  round(value / 1000),
                  name.indexOf('watch') >= 0 ? (
                    'watch'
                  ) : name.indexOf('lead') >= 0 ? (
                    'leadgen'
                  ) : name
                ];
              } else {
                return (Math.round(100*value)/100) + '%';
              }
            }}/>
            {['lead-gen', 'all'].indexOf(this.state.target) >= 0 && (
              <Bar dataKey={userKey + '_lead-gen'} stackId='a' fill="#3498DB"/>
            )}
            {['watch', 'all'].indexOf(this.state.target) >= 0 && (
              <Bar dataKey={userKey + '_watch'} stackId='a' fill="#F39C12">
                {this.state.target.indexOf('all') >= 0 && (
                  <LabelList 
                    dataKey={userKey + '_all-sources'} 
                    position="top" 
                    formatter={(value) => (Math.round(value/100)/10)}
                  />
                )}
              </Bar>
            )}
          </BarChart>
        </Grid.Column>
        <Grid.Column width={4}>
          <h3>{this.props.title}</h3>
          <br/>
          <div>
            <span style={style('all')} onClick={this.handleSetTargetAll}>All</span><span> | </span>
            <span style={style('lead-gen')} onClick={this.handleSetTargetLeadGen}>Lead Gen</span><span> | </span>
            <span style={style('watch')} onClick={this.handleSetTargetWatch}>Watch</span>
          </div>
          <br/>
          <div>
            <span style={style('count')} onClick={this.handleSetCountMode}>Count</span><span> | </span>
            <span style={style('daily')} onClick={this.handleSetDailyMode}>Daily</span><span> | </span>
            <span style={style('percent')} onClick={this.handleSetPercentMode}>Percent</span>
          </div>
        
        </Grid.Column>

      </Grid.Row></Grid>
    );
  }
}

export default SyntheticGraph;
