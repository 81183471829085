import _ from 'underscore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';
import {
  Divider,
  Container,
  Header,
  Table,
  Grid,
  Button,
  Label,
  Form,
  Segment,
  List as SList,
  Icon,
  Statistic,
} from 'semantic-ui-react';
import {
  SweetForm,
  Select,
  //SelectInt,
  Input,
  Range,
  List,
  Radio,
  Clearable,
  Weighted,
  enhance,
  customOperator,
} from 'sweetform';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import EditOfferModal from '../Sales/SalesBoard/EditOfferModal';
import EditCriteriaModal from '../Sales/SalesBoard/EditCriteriaModal';
import EditTargetedHuntCriteriaModal from "../Sales/SalesBoard/EditTargetedHuntCriteriaModal";

import { Textarea } from '../common';
import baseUrl from '../baseUrl';
import { actions } from './reducers';
import Modal from '../Modal';
import TagsView from '../TagsView';
import OfferActionView from './OfferActionView';
import OfferCriteria from './OfferCriteria';
import OfferTemplates from './OfferTemplates';
import ClientFriendCompanies from './ClientFriendCompanies';
import Pagination from '../Pagination';
import OfferLastRating from './OfferLastRating';
import OfferMoreInfo from './OfferMoreInfo';

const CONTRACT_TYPES = [
  { label: 'CDI', value: 'CDI' },
  { label: 'CDD', value: 'CDD' },
  { label: 'internship', value: 'Stage' },
];

const SOURCE_TYPES = [
  { label: 'Welcome To The Jungle', value: 'wttjOffers' },
  { label: 'Workable', value: 'workableOffers' },
];

const sourceTypeLabels = {
  wttjOffers: 'Welcome To The Jungle',
  workableOffers: 'Workable',
};

// const RESPONSIBILITIES = [
//   { label: 'Directeur (CTO, VP ...)', value: 'director' },
//   { label: 'Lead', value: 'lead' },
//   { label: 'Standard', value: 'normal' },
//   { label: 'Stagiaire', value: 'intern' },
// ];

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

// Components
const ColorTag = ({ label, value }) => <Label color={value ? 'green' : 'red'}>{label}</Label>;
const getBeautifulColor = (color) =>
  color === 'red' ? '#FA7676' : color === 'green' ? '#82CA9D' : color === 'yellow' ? '#FCC35E' : color;

const SendSkipReason = ({ reason }) => {
  return (
    <Segment>
      <h3>
        {reason.strength ? <span>[{reason.strength}] </span> : ''} {reason.humanReadableReason}
      </h3>
      {(reason.stats || []).length > 0 && (
        <div>
          {_.map(reason.stats, (stat, index) => {
            const data = _.map(stat.values, ({ columnName, heights }) => ({
              columnName,
              ..._.object(_.pluck(heights, 'name'), _.pluck(heights, 'height')),
            }));
            const columns = _.values(
              _.reduce(
                stat.values,
                (memo, { heights }) => {
                  _.each(heights, ({ color, name }) => {
                    if (!memo[name]) {
                      memo[name] = { key: name, name, color };
                    }
                  });
                  return memo;
                },
                {},
              ),
            );
            return (
              <div key={index}>
                <h4>{stat.title}</h4>
                {stat.type === 'multivalueHistogram' && (
                  <BarChart width={300} height={250} data={data} margin={{ top: 5, right: 0, left: -20, bottom: 3 }}>
                    <XAxis
                      dataKey='columnName'
                      angle={45}
                      textAnchor='start'
                      interval={0}
                      style={{ fontSize: '10px' }}
                    />
                    <YAxis />
                    <CartesianGrid strokeDasharray='3 3' vertical={false} />
                    <Tooltip />
                    {_.map(columns, ({ key, name, color }, index) => (
                      <Bar key={index} dataKey={key} name={name} stackId='a' fill={getBeautifulColor(color)} />
                    ))}
                  </BarChart>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Segment>
  );
};

const OfferDetails = ({ ops, offer, workPipes, updating, onAddWorkPipe, onEdit, onEditTargetedHuntCriteria, onEditCriteria, onMarkAsCompleted, onMarkAsNotCompleted, onUpdateOne, onMarkCriteriaReviewed, onMarkCriteriaMissing, onMarkCriteriaCompleted, onUpdatePendingProfiles }) => (
  <div>
    <Grid columns={2}>
      <Grid.Column>
        <Button color='yellow' onClick={onEdit}>
          Edit Admin
        </Button>
        <Button color='orange' onClick={onEditCriteria}>
          Edit Criteria
        </Button>
        <Button color='purple' onClick={onEditTargetedHuntCriteria}>
          Edit Targeted Hunt Criteria
        </Button>
        {/*updating ? (
          <Button style={{ marginLeft: 10 }} color='blue' loading>
            Recreating...
          </Button>
        ) : (
          <Button style={{ marginLeft: 10 }} color='blue' onClick={() => onUpdateOne(offer.id)}>
            Update
          </Button>
        )*/}
        {(!offer.markedCompletedUntil || offer.markedCompletedUntil < new Date().toISOString()) ? (
          <Button style={{ marginLeft: 10 }} color='olive' onClick={() => {
            onMarkAsCompleted(offer)
          }}>
            Mark as completed
          </Button>
        ) : (
          <span>
            <Button style={{ marginLeft: 10, marginRight: 10 }} color='orange' onClick={() => {
              onMarkAsNotCompleted(offer)
            }}>
              Mark as not completed
            </Button>
            <i>
              (marked completed until {offer.markedCompletedUntil.slice(0, 16)})
            </i>
          </span>
        )}
        {/*
        <Button inverted style={{ marginLeft: 30 }} color='green' onClick={() => {
          onMarkCriteriaReviewed(offer)
        }}>
          Criteria reviewed
        </Button>
        {(offer.hasMissingCriteria) ? (
          <Button inverted style={{ marginLeft: 10 }} color='teal' onClick={() => {
            onMarkCriteriaCompleted(offer)
          }}>
            Criteria completed
          </Button>
        ) : (
          <Button inverted style={{ marginLeft: 10 }} color='orange' onClick={() => {
            onMarkCriteriaMissing(offer)
          }}>
            Criteria missing
          </Button>
        )}
      */}
        
        <Button inverted style={{ marginLeft: 10 }} color='blue' onClick={() => {
          onUpdatePendingProfiles(offer)
        }}>
          Update pendings
        </Button>
        {/*
        <Button color="red" onClick={onDelete} style={{ marginLeft: 10 }}>
          Delete
        </Button>
        */}
      </Grid.Column>
      <Grid.Column textAlign='right'>
        {offer.priority ? (
          <Label color='orange' style={{ marginRight: '3rem' }}>
            <Icon name='star' />
            Priority
          </Label>
        ) : null}
        {offer.sweetsheetId && (
          <Link to={`/flowboard/sweetsheets/id/${offer.sweetsheetId}`} style={{ marginRight: '3rem' }}>
            SweetSheet
          </Link>
        )}
        <ColorTag label='Watch' value={offer.isWatch} />
        <ColorTag label='Active' value={offer.isActive} />
        <ColorTag label='HireSweet' value={offer.isHiresweet} />
        <ColorTag label='Complete' value={offer.isComplete} />
        {ops && offer.status === 'current' ? <Label color='green'>Current</Label> : null}
        {ops && offer.status === 'hold' ? <Label color='yellow'>Hold</Label> : null}
        {ops && offer.status === 'finished' ? <Label color='grey'>Finished</Label> : null}
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column width={4}>
        <Segment>
          <Header as='h2'>{offer.title}</Header>
          <Header as='h3'>
            {offer.jobId ? `${offer.jobId} at ` : ''}
            {offer.companyId}
          </Header>

          <Container>
            <div>
              <b>Work pipes:</b> <Link to={`/actions/offer/${offer.id}`}>(see actions)</Link>
            </div>
            {workPipes && workPipes.length > 0 ? (
              _.map(workPipes, (wp) => (
                <div key={wp.id}>
                  <span style={{ color: 'grey' }}>
                    {wp.lastActionTimestamp
                      ? '[' + new Date(wp.lastActionTimestamp).toISOString().slice(2, 10) + '] '
                      : '[XX-XX-XX] '}
                  </span>
                  <Link to={`/offers/${offer.id}/workpipe/${wp.id}`}>{wp.title}</Link>
                </div>
              ))
            ) : (
              <div>(none)</div>
            )}
            {offer.comments ? <Segment secondary dangerouslySetInnerHTML={getRawMarkup(offer.comments)} /> : null}
            <dl>
              <div>
                <b>Users: </b>
                {offer.users && offer.users.length > 0 ? offer.users.join(', ') : '(nobody)'}
              </div>
              <div>
                <b>Platform Id: </b>
                {(
                  <a
                    target='_blank'
                    href={getPlatformBaseUrl(offer.clientId, offer.platformId)}
                    rel='noopener noreferrer'
                  >
                    {offer.platformId}
                  </a>
                ) || '(none)'}
              </div>
              {offer.uploadRule && [
                <div key={1}>
                  <b>Custom Upload Rule:</b>
                </div>,
                <div key={2}>{offer.uploadRule}</div>,
              ]}
              <dd>
                <Button color='green' size='mini' onClick={onAddWorkPipe}>
                  Add
                </Button>
              </dd>
            </dl>
          </Container>
          <Divider />
          <TagsView tags={[['ref', offer.id], ['id', offer.hiresweetId]]} />
          <TagsView
            tags={[
              ['type', offer.contractType],
              ['responsibilities', offer.responsibilities],
              ['location', offer.location],
              ['remote', offer.remote ? 'yes' : ''],
            ]}
          />
          <TagsView
            tags={[
              ['experience', offer.experience ? `${offer.experience.min} - ${offer.experience.max}` : null],
              offer.topSchool ? 'Top School' : null,
            ]}
          />

          {offer.salaryRange ? (
            <p>
              Salary: {offer.salaryRange.min} - {offer.salaryRange.max}
            </p>
          ) : null}

          {offer.skills ? (
            <Grid columns={3}>
              <Grid.Column>
                <b>Required</b>
                <SList>
                  {_.map(
                    _.sortBy(offer.skills.required || [], function (x) {
                      return x;
                    }),
                    (skill) => (
                      <SList.Item key={skill}>{skill}</SList.Item>
                    ),
                  )}
                </SList>
              </Grid.Column>
              <Grid.Column>
                <b>Important</b>
                <SList>
                  {_.map(
                    _.sortBy(offer.skills.important || [], function (x) {
                      return x;
                    }),
                    (skill) => (
                      <SList.Item key={skill}>{skill}</SList.Item>
                    ),
                  )}
                </SList>
              </Grid.Column>
              <Grid.Column>
                <b>Bonus</b>
                <SList>
                  {_.map(
                    _.sortBy(offer.skills.bonus || [], function (x) {
                      return x;
                    }),
                    (skill) => (
                      <SList.Item key={skill}>{skill}</SList.Item>
                    ),
                  )}
                </SList>
              </Grid.Column>
            </Grid>
          ) : null}
        </Segment>
        {offer.platformId && (
          <Segment>
            <OfferTemplates jobOfferId={offer.platformId} />
          </Segment>
        )}
        {offer.platformId && (
          <Segment>
            <ClientFriendCompanies jobOfferId={offer.platformId} />
          </Segment>
        )}
      </Grid.Column>
      <Grid.Column width={5}>
        {offer.platformId && (
          <OfferLastRating jobOfferId={offer.platformId} />
        )}
        {offer.platformId && (
          <OfferMoreInfo jobOfferId={offer.platformId} />
        )}
        {offer.platformId && (
          <Segment>
            <OfferCriteria jobOfferId={offer.platformId} />
          </Segment>
        )}
        {ops && offer.callReport ? (
          <Segment>
            <Icon name='phone' /> <span dangerouslySetInnerHTML={getRawMarkup(offer.callReport)} />
          </Segment>
        ) : null}
        <Segment>
          <Label color='blue' ribbon>
            {moment(offer.creationDate).format('YYYY-MM-DD')}
          </Label>
          {offer.link ? (
            <a
              href={offer.link.slice(0, 4) === 'http' ? offer.link : `https://${offer.link}`}
              target='__blank'
              rel='noopener noreferrer'
            >
              Source: {sourceTypeLabels[offer.sourceType]}
            </a>
          ) : null}
          <Segment style={{ marginTop: 20 }} secondary dangerouslySetInnerHTML={getRawMarkup(offer.description)} />
        </Segment>
      </Grid.Column>
      <Grid.Column width={4}>
        {!offer.sendSkip ? (
          <Segment color='red'>No SendSkip data, please check if the search v3 api is up</Segment>
        ) : !offer.sendSkip.computed ? (
          <Segment color='red'>No SendSkip data, {offer.sendSkip.notComputedReason}</Segment>
        ) : (
          <div>
            <div>
              <Segment>
                <Statistic.Group widths={3} size='mini'>
                  <Statistic>
                    <Statistic.Label>Last Update</Statistic.Label>
                    <Statistic.Value>{moment(offer.sendSkip.computationTimestamp).fromNow(true)}</Statistic.Value>
                  </Statistic>
                  <Statistic>
                    <Statistic.Label>Nb Send</Statistic.Label>
                    <Statistic.Value>{offer.sendSkip.nbPositives}</Statistic.Value>
                  </Statistic>
                  <Statistic>
                    <Statistic.Label>Nb Skip</Statistic.Label>
                    <Statistic.Value>{offer.sendSkip.nbNegatives}</Statistic.Value>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </div>
            <br />
            {_.map(offer.sendSkip.inferredReasons, (reason, index) => (
              <SendSkipReason key={index} reason={reason} />
            ))}
          </div>
        )}
      </Grid.Column>
      <Grid.Column width={3}>
        <OfferActionView offerId={offer.id} />
      </Grid.Column>
    </Grid>
  </div>
);
const getPlatformBaseUrl = (clientId, offerId) => `https://app.hiresweet.com/client/${clientId}/offers/${offerId}`;

const statusToTagColor = {
  current: 'green',
  hold: 'yellow',
  finished: 'grey',
};

const OfferRow = ({ ops, offer, onClick, onClickActive }) => (
  <Table.Row style={{ cursor: 'pointer' }}>
    <Table.Cell onClick={onClick}>{offer.companyId}</Table.Cell>
    <Table.Cell onClick={onClick}>{offer.title}</Table.Cell>
    {!ops ? <Table.Cell onClick={onClick}>{offer.jobId}</Table.Cell> : null}
    <Table.Cell onClick={onClick}>
      {offer.skills && offer.skills.required ? offer.skills.required.join(', ') : '?'}
    </Table.Cell>
    <Table.Cell onClick={onClick}>
      {offer.experience ? `${offer.experience.min} - ${offer.experience.max}` : '?'}
    </Table.Cell>
    <Table.Cell onClick={onClick}>
      {offer.salaryRange ? `${offer.salaryRange.min} - ${offer.salaryRange.max}` : '?'}
    </Table.Cell>
    <Table.Cell>
      <Label color={offer.isHiresweet ? 'green' : 'red'} />
    </Table.Cell>
    <Table.Cell>
      <Label color={offer.isWatch ? 'green' : 'red'} />
    </Table.Cell>
    <Table.Cell onClick={onClickActive}>
      <Label color={offer.isActive ? 'green' : 'red'} />
    </Table.Cell>
    <Table.Cell>
      <Label color={offer.isComplete ? 'green' : 'red'} />
    </Table.Cell>
    {ops ? <Table.Cell>{offer.status ? <Label color={statusToTagColor[offer.status]} /> : null}</Table.Cell> : null}
  </Table.Row>
);
const OfferList = ({ ops, counts, offers, updatingAll, onPage, onSelect, onAdd, onUpdateAll, onToggleActive }) => (
  <div>
    <Grid>
      <Grid.Column width={ops ? 6 : 3}>
        <strong>Results: {counts.results}</strong>
      </Grid.Column>
      {!ops ? (
        <Grid.Column width={3}>
          Active: {counts.active}
          <br />
          HireSweet: {counts.hiresweet}
        </Grid.Column>
      ) : null}
      <Grid.Column textAlign='right' width={10}>
        {updatingAll ? (
          <Button color='blue' loading>
            Recreating...
          </Button>
        ) : (
          <Button color='blue' onClick={onUpdateAll}>
            Update All
          </Button>
        )}
        {/*
        <Button color="green" onClick={onAdd} style={{ marginLeft: 10 }}>
          Add offer
        </Button>
        */}
      </Grid.Column>
    </Grid>

    <Divider />

    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Title</Table.HeaderCell>
          {!ops ? <Table.HeaderCell>Job Id</Table.HeaderCell> : null}
          <Table.HeaderCell>Skills Req.</Table.HeaderCell>
          <Table.HeaderCell>
            <abbr title='Experience'>Exp</abbr>
          </Table.HeaderCell>
          <Table.HeaderCell>Salary</Table.HeaderCell>
          <Table.HeaderCell>HS</Table.HeaderCell>
          <Table.HeaderCell>
            <abbr title='Active'>Wat</abbr>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <abbr title='HireSweet'>Act</abbr>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <abbr title='Complete'>Cp</abbr>
          </Table.HeaderCell>
          {ops ? (
            <Table.HeaderCell>
              <abbr title='Status'>St</abbr>
            </Table.HeaderCell>
          ) : null}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {offers.map((offer) => (
          <OfferRow
            key={offer.id}
            ops={ops}
            offer={offer}
            onClick={() => onSelect(offer)}
            onClickActive={() => onToggleActive(offer.id)}
          />
        ))}
      </Table.Body>
      {counts.pages ? (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={10}>
              <Pagination floated='right' current={counts.pageOffset} total={counts.pages} onPage={onPage} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      ) : null}
    </Table>
  </div>
);

const loadSkills = async (input) => {
  try {
    const skills = (await axios.get(`${baseUrl}/tags/list?type=skill`)).data;
    return { options: skills, complete: true };
  } catch (e) {
    console.log(e);
    alert(e.message);
  }
};
const SelectSkill = (props) => (
  <Select {...props} async={true} loadOptions={loadSkills} labelKey='name' valueKey='id' />
);
// const SelectCategory = (props) => {
//   const options = [
//     { id: 'fullstack-js', name: 'Fullstack JS' },
//     { id: 'frontend-js', name: 'Frontend JS' },
//     { id: 'backend-js', name: 'Backend JS' },
//     { id: 'ios', name: 'iOS' },
//     { id: 'android', name: 'Android' },
//     { id: 'mobile-other', name: 'Mobile-Other' },
//     { id: 'csharp-dotnet', name: 'C#/.NET' },
//     { id: 'python', name: 'Python' },
//     { id: 'ruby', name: 'Ruby' },
//     { id: 'go', name: 'Go' },
//     { id: 'java', name: 'Java' },
//     { id: 'php', name: 'PHP' },
//     { id: 'c-cpp', name: 'C/C++' },
//     { id: 'lead-or-cto', name: 'Lead/CTO' },
//     { id: 'ui-ux', name: 'UI/UX' },
//     { id: 'devops', name: 'DevOps' },
//     { id: 'data', name: 'Data' },
//     { id: 'data-science', name: 'Data Science' },
//     { id: 'quality-assurance', name: 'QA' },
//     { id: 'business', name: 'Business' },
//     { id: 'product-manager', name: 'Product Manager' },
//     { id: 'other', name: 'Other' },
//     { id: 'undefined', name: 'Undefined' },
//   ];
//   return <Select {...props} options={options} labelKey="name" valueKey="id" />;
// };

const loadJobTitles = async (input) => {
  try {
    const jobTitles = (await axios.get(`${baseUrl}/tags/list?type=job`)).data;
    return { options: jobTitles, complete: true };
  } catch (e) {
    console.log(e);
    alert(e.message);
  }
};
const SelectJobTitles = (props) => (
  <Select {...props} async={true} loadOptions={loadJobTitles} labelKey='name' valueKey='id' />
);

const loadCompanyIds = async (input) => {
  try {
    const companyIds = (await axios.get(`${baseUrl}/offers/companyId`)).data;
    return { options: companyIds, complete: true };
  } catch (e) {
    console.log(e);
    alert(e.message);
  }
};
const SelectCompanyIds = (props) => <Select {...props} async={true} loadOptions={loadCompanyIds} />;
// const SelectWorkplacesClient = ({ clients, field }) => (
//   <Select
//     field={field}
//     creatable
//     options={clients}
//     labelKey="name"
//     valueKey="id"
//   />
// );

// const SelectPlatformId = ({ platformOffers, field }) => (
//   <Select field={field} options={platformOffers} labelKey="id" valueKey="id" />
// );

// const loadUsers = async (input) => {
//   try {
//     const users = (await axios.get(`${baseUrl}/users`)).data;
//     return { options: users, complete: true };
//   } catch (e) {
//     console.log(e);
//     alert(e.message)
//   }
// };

// const SelectUsers = (props) =>
//   <Select {...props} async={true} loadOptions={loadUsers} labelKey='name' valueKey='id' />;

const SelectStatus = (props) => (
  <Select
    {...props}
    options={[
      { value: 'current', label: 'Current' },
      { value: 'hold', label: 'Hold' },
      { value: 'finished', label: 'Finished' },
    ]}
  />
);
// const SelectExperience = (props) =>
//   <Select {...props} options={[
//     { value: 0, label: '0' },
//     { value: 0.5, label: '6 mois' },
//     { value: 1, label: '1 an' },
//     ..._.map(_.range(2, 16), value => ({ value, label: `${value} ans` })),
//   ]} placeholder='Pas de limite' />
//   ;
const loadEducations = async (input) => {
  const getSchools = async () => {
    try {
      const url = `${baseUrl}/workplaces/listSchools`;
      const schoolsList = (await axios.get(url)).data;
      console.log('schoolsList', schoolsList);
      return schoolsList;
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };
  const getSchoolGroups = async () => {
    try {
      const url = `${baseUrl}/workplaces/listSchoolGroups`;
      const schoolGroups = (await axios.get(url)).data;
      return schoolGroups;
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };
  const schools = await getSchools();
  const schoolGroups = await getSchoolGroups();
  const other = [
    { id: 'phd', name: 'PhD' },
    { id: 'master', name: 'Master' },
    { id: 'bachelor', name: 'Bachelor' },
    { id: 'self-taught', name: 'Self Taught' },
    { id: 'university', name: 'University' },
    { id: 'prepa', name: 'Prepa' },
    { id: 'reorientation', name: 'Reorientation' },
  ];
  const options = schoolGroups.concat(other).concat(schools);
  return { options: options };
};

const SelectEducation = (props) => (
  <Select {...props} async={true} loadOptions={loadEducations} labelKey='name' valueKey='id' />
);
export const EducationEditor = enhance(() => (
  <Grid columns={3}>
    <Grid.Column width={6}>
      <Form.Field>
        <label>Educations to target</label>
        <List field='target' component={SelectEducation} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width={6}>
      <Form.Field>
        <label>Educations to avoid</label>
        <List field='avoid' component={SelectEducation} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width={4}>
      <Link target='_blank' to='/monitoring/knowledge/schoolsGroups'>
        <Icon name='question circle' />
        Schools groups
      </Link>
    </Grid.Column>
  </Grid>
));

// const SkillsEditor = enhance(() => (
//   <Grid columns={3}>
//     <Grid.Column>
//       <Form.Field>
//         <label>Required skills</label>
//         <List field="required" component={SelectSkill} />
//       </Form.Field>
//     </Grid.Column>
//     <Grid.Column>
//       <Form.Field>
//         <label>Important skills</label>
//         <List field="important" component={SelectSkill} />
//       </Form.Field>
//     </Grid.Column>
//     <Grid.Column>
//       <Form.Field>
//         <label>Bonus skills</label>
//         <List field="bonus" component={SelectSkill} />
//       </Form.Field>
//     </Grid.Column>
//   </Grid>
// ));

//const PUSH_FLOW_POLICIES = [{ label: 'daily-regular', value: 'daily-regular' }];

// const PushFlowPolicy = enhance(() => (
//   <Grid columns={3}>
//     <Grid.Column>
//       <Select
//         options={PUSH_FLOW_POLICIES}
//         field="type"
//         placeholder="Type"
//         defaultValue="daily-regular"
//       />
//     </Grid.Column>
//     <Grid.Column>
//       <SelectInt
//         field="nbPerDay"
//         placeholder="Number per day"
//         min={0}
//         max={1000}
//       />
//     </Grid.Column>
//     <Grid.Column>
//       <SelectInt
//         field="maxStackSize"
//         placeholder="Max stack size"
//         min={0}
//         max={1000}
//       />
//     </Grid.Column>
//   </Grid>
// ));

const AddWorkPipeModal = ({ onCancel, onChangeEdit, onSubmit }) => (
  <Modal active={true} headerText='Add new Work Pipe' onCancel={onCancel} onSubmit={onSubmit}>
    <SweetForm onChange={onChangeEdit}>
      <Form>
        <Form.Field>
          <label>Identifier</label>
          <Clearable component={Input} field='id' nullLabel='Auto' />
        </Form.Field>
        <Form.Field>
          <label>Title</label>
          <Input autoFocus field='title' />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <Textarea field='description' />
        </Form.Field>
      </Form>
    </SweetForm>
  </Modal>
);

const scorersDefinition = {
  jobIdDistance: {
    label: 'Job Titles Proximity',
    nested: true,
    component: SelectJobTitles,
  },
  skillsDistance: {
    label: 'Skills Distance',
    children: 'n',
    component: SelectSkill,
  },
  salaryScorer: {
    label: 'Salary Scorer',
    children: 1,
    component: Input,
  },
  experienceScorer: {
    label: 'Experience Scorer',
    children: 1,
    component: Input,
  },
};

const Scorer = customOperator(scorersDefinition, false);
const Scorers = customOperator(
  {
    ...scorersDefinition,
    mean: { label: 'Mean', children: 'n', component: Scorer },
    geometricMean: {
      label: 'Geometric Mean',
      children: 'n',
      component: Scorer,
    },
    max: { label: 'Max', children: 'n', component: Scorer },
    min: { label: 'Min', children: 'n', component: Scorer },
    weightedMean: {
      label: 'Weighted Mean',
      children: 'n',
      component: enhance(({ field }) => (
        <Weighted field={field}>
          <Scorer field='value' />
        </Weighted>
      )),
    },
  },
  false,
);

const Filters = customOperator(
  {
    not: { label: 'NOT', children: 1 },
    and: { label: 'AND', children: 'n' },
    or: { label: 'OR', children: 'n' },
    company: { label: 'Company', children: 1, component: SelectCompanyIds },
    title: { label: 'Title', children: 1, component: Input },
    skillsAnys: {
      label: 'Skills (Any)',
      children: 'n',
      component: SelectSkill,
    },
    skillsRequired: {
      label: 'Skills (Required)',
      children: 'n',
      component: SelectSkill,
    },
    skillsImportant: {
      label: 'Skills (Important)',
      children: 'n',
      component: SelectSkill,
    },
    skillsBonus: {
      label: 'Skills (Bonus)',
      children: 'n',
      component: SelectSkill,
    },
    contractType: {
      label: 'Contract Type',
      children: 1,
      component: Select,
      props: { options: CONTRACT_TYPES },
    },
    location: { label: 'Location', children: 1, component: Input },
    remote: { label: 'Remote' },
    isActive: { label: 'Active' },
    isWatch: { label: 'Watch' },
    isHiresweet: { label: 'HireSweet' },
    jobTitle: { label: 'Job Title', children: 1, component: SelectJobTitles },
    sourceType: {
      label: 'Source Type',
      children: 1,
      component: Select,
      props: { options: SOURCE_TYPES },
    },
    isComplete: { label: 'Complete' },
    topSchool: { label: 'Top School' },
    matchAny: { label: 'Match Anywhere', children: 1, component: Input },
    experience: {
      label: 'Experience',
      children: 1,
      component: Range,
      props: {
        min: 0,
        max: 5,
      },
    },
    salary: {
      label: 'Salary',
      children: 1,
      component: Range,
      props: {
        min: 0,
        max: 1000000,
        step: 500,
      },
    },
    status: { label: 'Status', children: 1, component: SelectStatus },
    atLeast: {
      label: 'At Least',
      children: 1,
      component: enhance(({ field }) => (
        <Weighted field={field} fieldName='threshold'>
          <Scorer field='scorer' />
        </Weighted>
      )),
    },
  },
  false,
);

const CategorySelector = enhance(({ setValue, value, onSelect }) => (
  <Button.Group fluid>
    <Button
      color={value === 'all' ? 'teal' : null}
      onClick={() => {
        setValue('all');
        onSelect('all');
      }}
    >
      All
    </Button>
    <Button
      color={value === 'clients' ? 'teal' : null}
      onClick={() => {
        setValue('clients');
        onSelect('clients');
      }}
    >
      Clients
    </Button>
    <Button
      color={value === 'me' ? 'teal' : null}
      onClick={() => {
        setValue('me');
        onSelect('me');
      }}
    >
      Me
    </Button>
  </Button.Group>
));

class Offers extends Component {
  componentDidMount() {
    const defaultSearch = { type: 'current', category: 'me' };
    if (!this.props.match.params.id) {
      this.props.onSearch(this.props.username, defaultSearch);
    }
    this.props.onLoad(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    const { selectedOffer } = this.props;

    // Shall we reset screen?
    if (selectedOffer && location.pathname === '/offers') {
      this.props.onBack();
    }
  }

  render() {
    const {
      location,
      history,
      ops,
      offersList,
      counts,
      search,
      selectedOffer,
      editMode,
      edit,
      actions,
      username,
      //selectOpts,
      workPipes,
      onChangeSearch,
      onChangeEdit,
      onSearch,
      onClickOffer,
      onAdd,
      //onAddSubmit,
      onAddWorkPipe,
      onAddWorkPipeSubmit,
      onEdit,
      onEditCriteria,
      onEditTargetedHuntCriteria,
      onCancel,
      onSubmit,
      onMarkAsCompleted,
      onMarkAsNotCompleted,
      //onLogVersion,
      onUpdateAll,
      onUpdateOne,
      onDelete,
      onDeleteConfirm,
      onToggleActive,
      onMarkCriteriaMissing,
      onMarkCriteriaCompleted,
      onMarkCriteriaReviewed,
      onUpdatePendingProfiles
    } = this.props;

    const offerId = this.props.match.params.id;

    const isAdvanced = location.search.includes('advanced');
    const onClickOfferHistory = (offer) => {
      onClickOffer(offer);
      history.push(`/offers/${offer.id}`);
    };

    // const onAddSubmitHistory = (offer) => {
    //   onAddSubmit(offer);
    //   history.push(`/offers/${offer.id}`);
    // };

    return (
      <Grid>
        {!selectedOffer && !offerId ? (
          <Grid.Column width={4}>
            <SweetForm onChange={onChangeSearch}>
              {ops ? (
                <CategorySelector
                  field='category'
                  defaultValue='me'
                  onSelect={(c) => onSearch(username, { ...search, category: c })}
                />
              ) : null}
              <Header as='h2'>Reference</Header>
              <Input field='ref' autoFocus />

              {ops ? (
                <div>
                  <Header as='h2' style={{ marginTop: 20 }}>
                    Company
                  </Header>
                  <SelectCompanyIds field='client' />

                  <div style={{ margin: 20 }}>
                    <Radio
                      field='type'
                      defaultValue='current'
                      options={[
                        { value: 'any', label: 'Any' },
                        { value: 'current', label: 'Current' },
                        { value: 'hold', label: 'Hold' },
                        { value: 'finished', label: 'Finished' },
                      ]}
                    />
                  </div>
                </div>
              ) : null}
              {!ops || isAdvanced ? (
                <div>
                  <Header as='h2' style={{ marginTop: 20 }}>
                    Filters
                  </Header>
                  <Filters field='filters' />
                  <Header as='h2' style={{ marginTop: 20 }}>
                    Scorers
                  </Header>
                  <Scorers field='scorers' />
                </div>
              ) : (
                <Container textAlign='center'>
                  <a href='?advanced'>Advanced mode</a>
                </Container>
              )}
              {ops && isAdvanced ? (
                <Container textAlign='center'>
                  <a href='/offers'>Simple mode</a>
                </Container>
              ) : null}
            </SweetForm>

            <Button fluid color='teal' onClick={() => onSearch(username, search)}>
              Search
            </Button>
          </Grid.Column>
        ) : null}
        <Grid.Column width={selectedOffer ? 16 : 12}>
          {selectedOffer ? (
            <OfferDetails
              ops={ops}
              offer={selectedOffer}
              workPipes={workPipes}
              onAddWorkPipe={onAddWorkPipe}
              onEdit={onEdit}
              onEditCriteria={onEditCriteria}
              onEditTargetedHuntCriteria={onEditTargetedHuntCriteria}
              updating={actions['updateOne']}
              onUpdateOne={onUpdateOne}
              onMarkAsCompleted={onMarkAsCompleted}
              onMarkAsNotCompleted={onMarkAsNotCompleted}
              onDelete={onDelete}
              onMarkCriteriaMissing={onMarkCriteriaMissing}
              onMarkCriteriaCompleted={onMarkCriteriaCompleted}
              onMarkCriteriaReviewed={onMarkCriteriaReviewed}
              onUpdatePendingProfiles={onUpdatePendingProfiles}

            />
          ) : !offerId ? (
            <OfferList
              ops={ops}
              counts={counts}
              offers={offersList}
              updatingAll={actions['updateAll']}
              onUpdateAll={onUpdateAll}
              onAdd={onAdd}
              onSelect={onClickOfferHistory}
              onPage={(i) => onSearch(username, search, i)}
              onToggleActive={onToggleActive}
            />
          ) : null}
        </Grid.Column>
        {/*editMode === 'add' ? (
            <AddModal
            clients={selectOpts['workplacesClients']}
            ops={ops}
            onCancel={onCancel}
            onChangeEdit={onChangeEdit}
            onSubmit={() => onAddSubmitHistory(edit)}
            />
            ) : null*/}
        {editMode === 'edit' ? (
          <EditOfferModal
            initialValues={selectedOffer}
            onClose={onCancel}
            onSubmit={onSubmit}
            onDelete={onDeleteConfirm}
            onOpenPushCriteria={undefined}
          />
        ) : null}
        {editMode === 'addWorkPipe' ? (
          <AddWorkPipeModal
            onCancel={onCancel}
            onChangeEdit={onChangeEdit}
            onSubmit={() => onAddWorkPipeSubmit(selectedOffer, workPipes, edit)}
          />
        ) : null}
        {editMode === 'editCriteria' ? (
          <EditCriteriaModal onClose={onCancel} offerId={offerId} />
        ) : null}
        {editMode === 'editTargetedHuntCriteria' ? (
          <EditTargetedHuntCriteriaModal onClose={onCancel} offerId={offerId} />
        ) : null}
      </Grid>
    );
  }
}

// Containers
const mapSOffers = (state) => ({
  offersList: state.offersList,
  counts: state.counts,
  search: state.search,
  selectedOffer: state.selectedOffer,
  editMode: state.editMode,
  edit: state.edit,
  actions: state.actions,
  selectOpts: state.selectOpts,
  workPipes: state.workPipes,
});

const mapDOffers = (dispatch) => ({
  onLoad: async (id) => {
    if (id) {
      try {
        const [offer, workPipesTmp, sendSkip] = await Promise.all([
          axios.get(`${baseUrl}/offers/${id}`),
          axios.get(`${baseUrl}/sweetwork/workPipesOfJobOffer/${id}`),
          axios.get(`${baseUrl}/offers/send-skip/${id}`),
        ]);
        const workPipes = _.sortBy(workPipesTmp.data, ({ lastActionTimestamp }) => -(lastActionTimestamp || 0));

        if (offer.data) {
          dispatch(actions.setSelectedOffer({ ...offer.data, sendSkip: sendSkip.data }));
          dispatch(actions.setWorkPipes(workPipes));
        }
      } catch (e) {
        console.log(e);
        alert(e.message);
      }
    } else {
      try {
        const clients = (await axios.get(`${baseUrl}/workplaces/clients`)).data;
        dispatch(actions.setSelectOpts('workplacesClients', clients));

        const platformOffers = (await axios.get(`${baseUrl}/station/clients/all/offers`)).data;
        if (!platformOffers.error) {
          dispatch(actions.setSelectOpts('platformOffers', platformOffers));
        }
      } catch (e) {
        console.log(e);
        alert(e.message);
      }
    }
  },
  onChangeSearch: (s) => dispatch(actions.setSearch(s)),
  onChangeEdit: (e) => dispatch(actions.setEdit(e)),
  onSearch: async (username, search, page = 0) => {
    // Cleanup search to remove unwanted fields
    if (search) {
      search.filters = search.filters ? _.pick(search.filters, 'selection', search.filters['selection']) : undefined;
      search.scorers = search.scorers ? _.pick(search.scorers, 'selection', search.scorers['selection']) : undefined;

      if (search.scorers && search.scorers.selection === 'weightedMean') {
        search.scorers.weightedMean = _.map(search.scorers.weightedMean, ({ value, weight }) => ({
          selection: value.selection,
          [value.selection]: value[value.selection],
          weight,
        }));
      }
    }
    try {
      const result = await axios.get(`${baseUrl}/offers`, {
        params: { username, search, page },
      });
      const { offers, counts } = result.data;
      if (offers && counts !== undefined) {
        dispatch(actions.setCounts(counts));
        dispatch(actions.setOffersList(offers));
      }
    } catch (e) {
      console.error(e);
      //alert(e.message)
    }
  },
  onClickOffer: async (offer) => {
    const { id } = offer;
    if (id) {
      try {
        const [offer, workPipesTmp, sendSkip] = await Promise.all([
          axios.get(`${baseUrl}/offers/${id}`),
          axios.get(`${baseUrl}/sweetwork/workPipesOfJobOffer/${id}`),
          axios.get(`${baseUrl}/offers/send-skip/${id}`),
        ]);
        const workPipes = _.sortBy(workPipesTmp.data, ({ lastActionTimestamp }) => -(lastActionTimestamp || 0));
        if (offer.data) {
          dispatch(actions.setSelectedOffer({ ...offer.data, sendSkip: sendSkip.data }));
          dispatch(actions.setWorkPipes(workPipes));
        }
      } catch (e) {
        console.log(e);
        alert(e.message);
      }
    }
    try {
      const clients = (await axios.get(`${baseUrl}/workplaces/clients`)).data;
      dispatch(actions.setSelectOpts('workplacesClients', clients));

      const platformOffers = (await axios.get(`${baseUrl}/station/clients/all/offers`)).data;
      if (!platformOffers.error) {
        dispatch(actions.setSelectOpts('platformOffers', platformOffers));
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  },
  onBack: () => dispatch(actions.setSelectedOffer(null)),
  /*
  onAdd: () => dispatch(actions.setEditMode('add')),
  onAddSubmit: (offer) => {
    if (!offer.title) {
      return alert('missing title');
    }

    const newId = (offer.id || offer.companyId + '-' + offer.title)
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-');

    const offerToSelect = {
      ...offer,
      id: newId,
    };

    dispatch(actions.setEditMode('edit'));
    dispatch(actions.setSelectedOffer(offerToSelect));
  }, */
  onAddWorkPipe: () => dispatch(actions.setEditMode('addWorkPipe')),
  onAddWorkPipeSubmit: async (offer, workPipes, workPipe) => {
    if (!workPipe.title) {
      return;
    }

    const enrichedWorkPipe = {
      ...workPipe,
      id: workPipe.id ? workPipe.id : workPipe.title.toLowerCase().replace(/[^a-z0-9]/g, '-') + uuid().slice(-6),
      jobOfferId: offer.id,
    };
    try {
      await axios.post(`${baseUrl}/sweetwork/workPipes`, enrichedWorkPipe);
      dispatch(actions.setEditMode('none'));
      dispatch(actions.setWorkPipes([...workPipes, enrichedWorkPipe]));
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  },
  onEdit: () => dispatch(actions.setEditMode('edit')),
  onEditCriteria: () => dispatch(actions.setEditMode('editCriteria')),
  onEditTargetedHuntCriteria: () => dispatch(actions.setEditMode('editTargetedHuntCriteria')),
  onCancel: () => dispatch(actions.setEditMode('none')),
  /*onSubmit: async (offerRaw, disableClose) => {
    if (!disableClose) {
      dispatch(actions.setEditMode('none'));
    }
    if (!offerRaw) {
      return;
    }

    const offer = {
      ...offerRaw,
      skills: _.mapObject(offerRaw.skills, (skills) =>
        _.filter(skills, _.isString),
      ),
    };
    try {
      const result = await axios.put(`${baseUrl}/offers`, offer);
      dispatch(actions.setSelectedOffer(result.data, true));
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  },*/
  onSubmit: async (input) => {
    if (!input) {
      return;
    }
    //console.log('submitting');
    //console.log(input);
    try {
      const { data } = await axios.put(`${baseUrl}/salesboard/offers/${input.id}`, _.omit(input, 'id', '_id'));
      console.log(data);
      if (data.warnings && data.warnings.length != 0) {
        alert(data.warnings.join('\n'));
      }
      dispatch(actions.setSelectedOffer(input, true));
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  },
  onMarkAsCompleted: async (offer) => {
    await axios.post(`${baseUrl}/offers/${offer.id}/markAsCompleted`)
    const refetchedOffer = (await axios.get(`${baseUrl}/offers/${offer.id}`)).data;
    dispatch(actions.setSelectedOffer({
      ...offer,
      markedCompletedUntil: refetchedOffer.markedCompletedUntil
    }));
  },
  onMarkAsNotCompleted: async (offer) => {
    await axios.post(`${baseUrl}/offers/${offer.id}/markAsNotCompleted`)
    const refetchedOffer = (await axios.get(`${baseUrl}/offers/${offer.id}`)).data;
    dispatch(actions.setSelectedOffer({
      ...offer,
      markedCompletedUntil: refetchedOffer.markedCompletedUntil
    }));
  },
  onMarkCriteriaCompleted: async (offer) => {
    await axios.post(`${baseUrl}/offers/${offer.id}/markHasMissingCriteria`, { hasMissingCriteria: false })
    const refetchedOffer = (await axios.get(`${baseUrl}/offers/${offer.id}`)).data;
    dispatch(actions.setSelectedOffer({
      ...offer,
      hasMissingCriteria: refetchedOffer.hasMissingCriteria
    }));
  },
  onMarkCriteriaMissing: async (offer) => {
    await axios.post(`${baseUrl}/offers/${offer.id}/markHasMissingCriteria`, { hasMissingCriteria: true })
    const refetchedOffer = (await axios.get(`${baseUrl}/offers/${offer.id}`)).data;
    dispatch(actions.setSelectedOffer({
      ...offer,
      hasMissingCriteria: refetchedOffer.hasMissingCriteria
    }));
  },
  onUpdatePendingProfiles: async (offer) => {
    try {
      const res = (await axios.post(`${baseUrl}/offers/${offer.id}/updatePendings`, { hasMissingCriteria: true })).data;
      alert(JSON.stringify(res))
    } catch(e){
      alert(JSON.stringify(e))
    }
  },
  onMarkCriteriaReviewed: async (offer) => {
    await axios.post(`${baseUrl}/offers/${offer.id}/markAsReviewed`)
  },
  onUpdateAll: async () => {
    dispatch(actions.setAction('updateAll'));
    try {
      await axios.get(`${baseUrl}/cerejobs/offers/updateAll`);
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  },
  onUpdateOne: async (id) => {
    dispatch(actions.setAction('updateOne'));
    try {
      await axios.get(`${baseUrl}/cerejobs/offers/updateOne/${id}`);
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  },
  /*
  onDelete: () => dispatch(actions.setEditMode('delete')),
  */
  onDeleteConfirm: async ({ id }) => {
    try {
      const { data } = await axios.delete(`${baseUrl}/salesboard/offers/${id}`);
      if (!data || !data.success) {
        console.log(data);
        alert(data);
      } else {
        dispatch(actions.setEditMode('none'));
        dispatch(actions.deleteOffer(id));
        dispatch(actions.setSelectedOffer(null));
        return data;
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
      return { success: false, error: e.message };
    }
  },
  onToggleActive: async (id) => {
    try {
      await axios.get(`${baseUrl}/offers/toggleIsActive/${id}`);
      dispatch(actions.toggleOfferIsActive(id));
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  },
});

export default connect(
  mapSOffers,
  mapDOffers,
)(Offers);
