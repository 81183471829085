import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Header, Label, Popup, Grid, Icon, Segment } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';

import moment from 'moment';

class SaleCycle extends React.Component {
  state = { collapsed: true };

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    const { dateDebCycle, dateEndCycle, withAnswers, contacts } = this.props.saleCycle || {};
    return (
      <div>
        <div style={{ cursor: 'pointer' }} onClick={this.toggleCollapse}>
          {withAnswers ? (
            <Popup
              trigger={<Icon name="check" color="green" />}
              content={<div>Connected calls or incomming emails</div>}
            />) : (
            <Popup
              trigger={<Icon name="close" color="red" />}
              content={<div>No connected calls and no incomming emails</div>}
            />
          )}
          {moment(dateDebCycle).format('DD/MM/YYYY')} - {dateEndCycle ? moment(dateEndCycle).format('DD/MM/YYYY') : 'Today'} <br />
        </div>
        {!this.state.collapsed && (
          <div>{_.pluck(contacts, 'email').join(' | ')}</div>
        )}
      </div>

    )
  }
}

const SaleLifeCycle = ({ lifeCycle }) => {
  const { dateDebStep, dateEndStep, saleCycles } = lifeCycle || {};
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Popup
            trigger={
              <Label color="purple" floated='right'>
                Sale
              </Label>
            }
            content={<div>Not in pipe 3</div>}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h4' color="purple">
            {moment(dateDebStep).format('DD/MM/YYYY')} - {dateEndStep ? moment(dateEndStep).format('DD/MM/YYYY') : 'Today'}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const ActiveLifeCycle = ({ lifeCycle }) => {
  const { dateDebStep, dateEndStep, actionStats } = lifeCycle || {};
  const { sends, skips, actors } = actionStats || {};
  return (
    <Grid>
      <Grid.Row>

        <Grid.Column width={8}>
          <Popup
            trigger={
              <Label color="blue" floated='right'>
                Active
              </Label>
            }
            content={<div>In pipe 3</div>}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h4' color="blue">
            {moment(dateDebStep).format('DD/MM/YYYY')} - {moment(dateEndStep).format('DD/MM/YYYY')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <div>
          <b>Sends: </b> {sends} <br />
          <b>Skips: </b> {skips} <br />
          <b>Users: </b> {_.pluck(actors, 'email').join(' | ')} <br />
        </div>
      </Grid.Row>
    </Grid>
  );
};

const LifeCycle = ({ lifeCycle }) => {
  if (lifeCycle.isSale) {

    return <Segment color="purple"><SaleLifeCycle lifeCycle={lifeCycle} /></Segment>;
  }
  return <Segment color="blue"><ActiveLifeCycle lifeCycle={lifeCycle} /></Segment>;
};

class DealLifeCycles extends React.Component {
  state = {};

  componentDidMount = async () => {
    try {
      const { dealId, clientId } = this.props;
      console.log({ dealId, clientId })
      const res = (await axios.post(`${baseUrl}/salesOptimisationApi/dealLifeCycles`, { dealId, clientId })).data;
      const lifeCycles = res.results;
      console.log({ lifeCycles })
      this.setState({
        lifeCycles,
      })
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  };


  render() {
    const { lifeCycles } = this.state;
    if (!lifeCycles) {
      return <div>Loading...</div>
    }
    console.log({ lifeCycles })
    return (
      <div>
        {_.map(lifeCycles.reverse(), (lifeCycle, i) => {
          return (
            <div key={i}>
              <LifeCycle lifeCycle={lifeCycle} key={Math.random()} />
            </div>
          );
        })}
      </div>
    );
  }
};


export default DealLifeCycles;
