import _ from 'underscore';
import React, { Component } from 'react';
import { Icon, Segment, List } from 'semantic-ui-react';
import RequirementStatusView from './RequirementStatusView.js';
import { Grid } from 'semantic-ui-react';

class GBQAdminCollectionView extends Component {
  render() {
    const { datasetId, projectId, table, type } = (this.props || {}).data || {};
    return (
      <div>
        {datasetId}, {projectId}, {table}, {type}
      </div>
    );
  }
}
export default GBQAdminCollectionView;
