import _ from 'underscore';
import { listHintsInEnrichedText } from '../enrichment';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { actions } from '../reducers';


export const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const sleep = async (milis = 1000) =>
  new Promise((resolve) => setTimeout(resolve, milis));

export const getSweetynote = async (idFields) => {
  // TODO only the linkedin sweetynote for now
  const { linkedin } = idFields;
  if (linkedin) {
    try {
      const sweetynote = (await axios.get(
        `${baseUrl}/sweetynotes/linkedin/${linkedin}`,
        { timeout: 20000 },
      )).data;
      return sweetynote;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
};


export const computeGlobalHints = (current) => {
  const listFields = [
    // Linkedin fields and experience
    ...(current &&
    current.sourceProfiles &&
    current.sourceProfiles.linkedin &&
    current.sourceProfiles.linkedin.data &&
    current.sourceProfiles.linkedin.data.enrichedData
      ? [current.sourceProfiles.linkedin.data.enrichedData]
      : []),
    ...(current &&
    current.sourceProfiles &&
    current.sourceProfiles.linkedin &&
    current.sourceProfiles.linkedin.data &&
    current.sourceProfiles.linkedin.data.experiences
      ? _.compact(
          _.map(
            current.sourceProfiles.linkedin.data.experiences,
            (e) => e.enrichedExperience,
          ),
        )
      : []),
    // Github fields and repositories
    ...(current &&
    current.sourceProfiles &&
    current.sourceProfiles.github &&
    current.sourceProfiles.github.enrichedData
      ? [current.sourceProfiles.github.enrichedData]
      : []),
    ...(current &&
    current.sourceProfiles &&
    current.sourceProfiles.github &&
    current.sourceProfiles.github.repos
      ? _.compact(
          _.map(current.sourceProfiles.github.repos, (r) => r.enrichedRepo),
        )
      : []),
    // StackOverflow fields
    ...(current &&
    current.sourceProfiles &&
    current.sourceProfiles.stackOverflow &&
    current.sourceProfiles.stackOverflow.enrichedData
      ? [current.sourceProfiles.stackOverflow.enrichedData]
      : []),
  ];
  const enrichments =
    current && listFields ? _.filter(listFields, (e) => !!e) : [];

  return _.union(..._.map(enrichments, listHintsInEnrichedText));
};


export const refreshSearchStatus = async (dispatch, searchId) => {
  let IsSearchStatusError = false;
  let maxNbRequests = 20;
  let nbRequests = 0;
  while (nbRequests < maxNbRequests && !IsSearchStatusError) {
    nbRequests++;
    const searchStatusResponse = (await axios.get(
      `${baseUrl}/sweetsearch/searches/id/${searchId}`,
      { timeout: 20000 },
    )).data;
    const searchStatus = (
      ((searchStatusResponse || {}).search || {}).status || {}
    ).type;
    dispatch(actions.setSearchStatus(searchStatusResponse));
    if (searchStatus !== 'pending') {
      IsSearchStatusError = true;
    }
    await sleep(3000);
  }
  let maxNbSlowRequests = 20;
  while (nbRequests < maxNbSlowRequests && !IsSearchStatusError) {
    nbRequests++;
    const searchStatusResponse = (await axios.get(
      `${baseUrl}/sweetsearch/searches/id/${searchId}`,
      { timeout: 20000 },
    )).data;
    const searchStatus = (
      ((searchStatusResponse || {}).search || {}).status || {}
    ).type;
    dispatch(actions.setSearchStatus(searchStatusResponse));
    if (searchStatus !== 'pending') {
      IsSearchStatusError = true;
    }
    await sleep(10000);
  }
};


export const treatLoadError = async ({ dispatch, input, error }) => {
  const { searchId } = input.pipeDescriptor;
  if (!searchId) {
    alert('no search Id in pipeDescriptor');
  }
  try {
    const searchStatusResponse = (await axios.get(
      `${baseUrl}/sweetsearch/searches/id/${searchId}`,
      { timeout: 20000 },
    )).data;
    const searchStatus = (
      ((searchStatusResponse || {}).search || {}).status || {}
    ).type;
    if (searchStatus === 'error' || searchStatus === 'pending') {
      dispatch(actions.setSearchStatus(searchStatusResponse));
      if (searchStatus === 'pending') {
        await refreshSearchStatus(dispatch, searchId);
      }
    } else {
      alert(error);
    }
  } catch (e) {
    console.error('search status');
    console.error(e);
    alert(error);
  }
}

const getDefaultAndBounceEmail = ({
  listEmailsWithoutSources,
  platformData,
}) => {
  // const forbiddenLabels = ['bounce']

  const listEmails = _.reduce(
    platformData,
    (memo, profile) =>
      _.find(
        profile.actions,
        (a) => a.type === 'answer-detected' && a.label === 'bounce',
      )
        ? {
            ...memo,
            bounceEmails: [...memo.bounceEmails, profile.email],
          }
        : _.find(profile.actions, (a) => a.type === 'answer-detected')
        ? {
            // && a.label !== 'negative'
            ...memo,
            defaultEmail: [...memo.defaultEmail, profile.email],
          }
        : memo,
    {
      defaultEmail: [],
      bounceEmails: [],
    },
  );
  const defaultEmails = _.difference(
    listEmails.defaultEmail,
    listEmails.bounceEmails,
  );
  return {
    bounceEmails: listEmails.bounceEmails,
    defaultEmail: defaultEmails.length > 0 ? defaultEmails[0] : null,
  };
};

export const getExtraDataFromOfferAndIdFields = async ({
  offer,
  idFields,
  executionId,
}) => {
  if (!offer || !offer.id || !idFields) {
    return {};
  }
  const reqBody = {
    offer: { id: offer.id },
    idFields,
    executionId
  }
  const extraData = (await axios.post(`${baseUrl}/profiles/extra-data`, reqBody)).data;

  return extraData;
};


const getProfilePlatformData = async (profile, executionId) => {
  const { idFields } = profile;
  if (!idFields || (!idFields.linkedin && !idFields.github)) {
    return [];
  }
  const param =
    idFields && idFields.linkedin
      ? {
          linkedin: idFields.linkedin,
          withImportantActionData: true,
          executionId,
        }
      : {
          github: idFields.github,
          withImportantActionData: true,
          executionId,
        };
  try {
    const response = await axios.post(baseUrl + '/stats/profile', param);
    return response && response.data && response.data.results
      ? response.data.results
      : [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getListEmailsFromSheetProfilesAndExtraData = ({
  sheetProfiles,
  extraData,
  platformData,
  sourceProfiles,
  sweetynote,
}) => {
  const listEmailsSources = [
    ...((((sourceProfiles || {}).linkedin || {}).data || {}).email
      ? [
          {
            email: (
              ((sourceProfiles || {}).linkedin || {}).data || {}
            ).email.toLowerCase(),
            source: 'linkedin',
          },
        ]
      : []),
    ...(((sourceProfiles || {}).github || {}).email
      ? [
          {
            email: ((sourceProfiles || {}).github || {}).email.toLowerCase(),
            source: 'github',
          },
        ]
      : []),
    ..._.map(sheetProfiles, ({ email }) =>
      email ? { email: email.toLowerCase(), source: 'sheet' } : null,
    ),
    ..._.map(
      (extraData || {}).hireSweetEmails ? extraData.hireSweetEmails : [],
      (email) =>
        email ? { email: email.toLowerCase(), source: 'sheet' } : null,
    ),
    ..._.map(
      (extraData || {}).reverseLinkedinEmails ? extraData.reverseLinkedinEmails : [],
      (email) =>
        email ? { email: email.toLowerCase(), source: 'reverse-linkedin' } : null,
    ),
    ...((extraData || {}).githubEmail
      ? [{ email: extraData.githubEmail.toLowerCase(), source: 'github' }]
      : []),
    ..._.map(
      (extraData || {}).githubPushEmails ? extraData.githubPushEmails : [],
      (email) =>
        email ? { email: email.toLowerCase(), source: 'push' } : null,
    ),
    ..._.map(
      (extraData || {}).githubCommitEmails ? extraData.githubCommitEmails : [],
      (email) =>
        email ? { email: email.toLowerCase(), source: 'commit' } : null,
    ),
    ..._.map(
      (extraData || {}).websiteEmails ? extraData.websiteEmails : [],
      (email) =>
        email ? { email: email.toLowerCase(), source: 'website' } : null,
    ),
    ..._.map(
      (extraData || {}).outSourcedEmails ? extraData.outSourcedEmails : [],
      (email) =>
        email ? { email: email.toLowerCase(), source: 'outsourced' } : null,
    ),
    ..._.map((sweetynote || {}).foundEmails || [], ({ email, source }) =>
      email
        ? { email: email.toLowerCase(), source: (source || {}).type || '' }
        : null,
    ),
  ];

  const obj = _.reduce(
    _.compact(listEmailsSources),
    (memo, e) =>
      memo[e.email]
        ? _.indexOf(memo[e.email], e.source) < 0
          ? {
              ...memo,
              [e.email]: [...memo[e.email], e.source],
            }
          : memo
        : { ...memo, [e.email]: [e.source] },
    {},
  );

  const nbSendAndAnswersByEmail = _.reduce(
    platformData,
    (memo, profile) => {
      const actionsNbSendsAndAnswers = _.reduce(
        profile.actions,
        (memo2, action) =>
          action.type === 'answer-detected'
            ? {
                ...memo2,
                nbAnswers: memo2.nbAnswers + 1,
              }
            : action.type === 'send'
            ? {
                ...memo2,
                nbSends: memo2.nbSends + 1,
              }
            : memo2,
        { nbSends: 0, nbAnswers: 0 },
      );
      return memo[profile.email]
        ? {
            ...memo,
            [profile.email]: {
              nbSends:
                memo[profile.email].nbSends + actionsNbSendsAndAnswers.nbSends,
              nbAnswers:
                memo[profile.email].nbAnswers +
                actionsNbSendsAndAnswers.nbAnswers,
            },
          }
        : {
            ...memo,
            [profile.email]: actionsNbSendsAndAnswers,
          };
    },
    {},
  );
  return _.compact(
    _.map(obj, (source, email) =>
      _.isString(email) &&
      !_.isEmpty(email) &&
      email.indexOf('@hiresweet.com') < 0 &&
      email.indexOf('isma.belghiti@gmail.com') < 0 &&
      email.indexOf('@users.noreply.github.com') < 0
        ? {
            email,
            source: _.reduce(
              source,
              (memo, s) => (memo === '' ? s : memo + ', ' + s),
              '',
            ),
            ...(nbSendAndAnswersByEmail[email]
              ? nbSendAndAnswersByEmail[email]
              : { nbSends: 0, nbAnswers: 0 }),
          }
        : null,
    ),
  );
};



export const loadFullVersion = async ({ current, executionId, offer }) => {
  const { enrichmentId, idFields } = current;

  let unCompleteProfile = { ...current };
  let capturedSweetynote = null;

  const getEnrichedProfile = async () => {
    // We need to wait otherwise the enrichment isn't computed yet in the backend
    await timeout(1000); 
    const body = {
      params: {
        executionId,
        tryIndex: 0,
      },
    };

    // possible to optimize
    const currentProfileEnriched = (await axios.get(
      `${baseUrl}/sweetwork/getItem/enriched/${enrichmentId}`,
      body,
    )).data;

    if (currentProfileEnriched && !_.isEmpty(currentProfileEnriched)) {
      unCompleteProfile = {
          ...unCompleteProfile,
        currentProfileEnriched,
        globalHints: computeGlobalHints(currentProfileEnriched), 
      };
    } else {
      // second attempt
      await timeout(1000);
      const currentProfileEnriched = (await axios.get(
        `${baseUrl}/sweetwork/getItem/enriched/${enrichmentId}`,
        {
          params: {
            executionId,
            tryIndex: 1,
          },
        },
      )).data;
      if (currentProfileEnriched && !_.isEmpty(currentProfileEnriched)) {
        unCompleteProfile = {
            ...unCompleteProfile,
          currentProfileEnriched,
          globalHints: computeGlobalHints(currentProfileEnriched), 
        };
      }
    }
  };

  const getSheetProfiles = async () => {
    const body = { params: { ...idFields, executionId } };
    const sheetProfiles = (await axios.get(
      `${baseUrl}/profiles/sheets`,
      body,
    )).data;
    unCompleteProfile = {
        ...unCompleteProfile,
        ...(sheetProfiles && { sheetProfiles }),
    };
    return sheetProfiles;
  };

  const getExtraData = async () => {
    const extraData = await getExtraDataFromOfferAndIdFields({ 
      offer,
      idFields,
      executionId,
    }); // 300ms

    const {
      relevantActivities,
      tags,
      offerMatch,
      warnings,
      augmentedExperiencesAndEducation,
    } = extraData;
    unCompleteProfile = {
        ...unCompleteProfile,
      data: {
          ...(unCompleteProfile && unCompleteProfile.data),
          ...offerMatch,
        sourceData: {
            ...(unCompleteProfile &&
                unCompleteProfile.data &&
                unCompleteProfile.data.sourceData),
            ...offerMatch.sourceData,
        },
      },
        ...(augmentedExperiencesAndEducation && {
          augmentedExperiencesAndEducation,
        }),
        ...(relevantActivities && {
          relevantActivities: relevantActivities.join(';'),
        }),
        ...(warnings && { warnings }),
        ...(tags && { tags: tags.join(';') }),
    };
    return extraData;
  };

  const getPlatformData = async () => {
    const platformData = await getProfilePlatformData(current, executionId); 
    unCompleteProfile = {
        ...unCompleteProfile,
        ...(platformData && { platformData }),
    };
    return platformData;
  };

  const getSanitizedEmails = async (emails) => {
    let mappings = {};
    try {
      const body = { emails: _.pluck(emails, 'email') };
      const sanitized = (await axios.post(
        `${baseUrl}/emails/sanitized`,
        body,
        { timeout: 2000 },
      )).data;
      if (sanitized.results) {
        _.each(sanitized.results, ({ input, sanitized, verified }) => {
          mappings[input] = { sanitized, verified };
        });
      }
    } catch (e) {
      console.error(e);
    }
    const newEmails = _.map(emails, (emailData) => {
      if (
        !emailData.email ||
          !mappings[emailData.email] ||
          !mappings[emailData.email].sanitized
      ) {
        return emailData;
      }
      return {
          ...emailData,
          ...mappings[emailData.email],
      };
    });
    return newEmails;
  };

  const getSweetynoteData = async () => {
    capturedSweetynote = await getSweetynote(idFields); 
    return capturedSweetynote;
  };

  const getSheetProfilesAndExtraData = async () => {
    const [
      sheetProfiles,
      extraData,
      platformData,
      sweetynote,
    ] = await Promise.all([
      getSheetProfiles(),
      getExtraData(),
      getPlatformData(),
      getSweetynoteData(),
    ]);

    const listEmails = await getSanitizedEmails( 
      getListEmailsFromSheetProfilesAndExtraData({ 
        sheetProfiles,
        extraData,
        platformData,
        sourceProfiles: current.sourceProfiles,
        sweetynote,
      }),
    );

    const listEmailsWithoutSources = _.map(listEmails, (e) => e.email);
    const { defaultEmail, bounceEmails } = getDefaultAndBounceEmail({ 
      listEmailsWithoutSources,
      platformData,
    });

    unCompleteProfile = {
        ...unCompleteProfile,
        ...(listEmails && { listEmails }),
        ...(bounceEmails && { bounceEmails }),
        ...(defaultEmail && { uploadEmail: defaultEmail }),
    };
  };

  await Promise.all([getSheetProfilesAndExtraData(), getEnrichedProfile()]); 

  return { 
    loadedProfile: unCompleteProfile, 
    sweetynote: capturedSweetynote 
  };
}
