import React from 'react';

import { Icon, Card } from 'semantic-ui-react';

import Timer from './Timer';

export default ({ check }) => {
  if (!check) {
    return <div />;
  }

  const ok = ((check.status || {}).lastCheck || {}).severity === 'ok';

  return (
    <Card color={ok ? 'green' : 'red'}>
      <Card.Content>
        <Card.Header>
          {check.name}
          <Icon name={ok ? 'check circle' : 'warning circle'} color={ok ? 'green' : 'red'} size='large' />
        </Card.Header>
        {(check.status || {}).lastCheck ? (
          <div>
            <Card.Meta>
              Last check <Timer time={check.status.lastCheck.timestamp} /> ago
            </Card.Meta>
            <Card.Description>{check.status.lastCheck.message}</Card.Description>
          </div>
        ) : (
          <Card.Meta>Not checked yet</Card.Meta>
        )}
      </Card.Content>
      {(check.status || {}).lastFailedCheck ? (
        <Card.Content>
          <Card.Meta>
            Last failure <Timer time={check.status.lastFailedCheck.timestamp} /> ago
          </Card.Meta>
          <Card.Description>{check.status.lastFailedCheck.message}</Card.Description>
        </Card.Content>
      ) : (
        <Card.Content>
          <Card.Meta>No failure yet</Card.Meta>
        </Card.Content>
      )}
    </Card>
  );
};

// class LastFailedCheck extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { active: false };

//     this.handleClick = this.handleClick.bind(this);
//   }

//   handleClick() {
//     this.setState((prevState) => ({ active: !prevState.active }));
//   }

//   render() {
//     return (
//       <Accordion>
//         <Accordion.Title onClick={this.handleClick} active={this.state.active}>
//           Last failure
//           <Icon name="dropdown" />
//         </Accordion.Title>
//         <Accordion.Content active={this.state.active || true} />
//       </Accordion>
//     );
//   }
// }
