import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Loader,
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class OfferTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.jobOfferId) {
      this.handleLoadOffer(props.jobOfferId);
    }
  }
  handleLoadOffer = async (jobOfferId) => {
    const { data } = await axios.post(baseUrl + '/station/offers', {
      ids: jobOfferId,
      withTemplates: true,
    });
    if (_.isEmpty(data.results)) {
      return alert('no results retrieved');
    }

    const offer = data.results[0];

    this.setState({
      templates: offer.templates || [],
    });
  };
  render() {
    if (!this.state.templates) {
      return (
        <center>
          <Loader />
        </center>
      );
    }
    return (
      <div>
        {_.map(this.state.templates, (template) => (
          <div key={template.id}>
            <h3>{template.title}</h3>
            <h4>{template.subject}</h4>
            <span
              style={{ display: 'block', marginTop: '10px' }}
              dangerouslySetInnerHTML={{ __html: template.body + '<br/>' }}
            />
            {template !== _.last(this.state.templates) && (
              <div>
                <hr />
                <br />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default OfferTemplates;
