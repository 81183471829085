import axios from 'axios';
import React from 'react';
import baseUrl from '../../baseUrl.js';
import { Button, Grid } from 'semantic-ui-react';
import _ from 'underscore';

import Matches from './Matches';
import MappingEditor from './MappingEditor';
import SearchWithOptions from './SearchWithOptions';

export default class LocationMappings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { manuallyValidated: false, candidateLocations: [], matches: [] };
  }

  componentDidMount() {
    const searchQuery = this.props.match.params.text;
    this.handleGeneralSearchSubmit(searchQuery);
  }

  loadOptions = async () => {
    if (this.state.options) {
      return this.state.options;
    } else {
      const response = await axios.get(`${baseUrl}/locationsV2/texts`).catch((error) => alert(error));
      const options = _.map(response.data, (text) => ({ key: text, value: text, text: text }));
      this.setState({ options });
      return options;
    }
  };

  fetchLocation = async (locationId) => {
    const response = await axios.get(`${baseUrl}/locationsV2/location/${locationId}`);
    return response.data.location;
  };

  handleGeneralSearchSubmit = async (search) => {
    this.setState({ search: search });
    const response = await axios
      .post(`${baseUrl}/locationsV2/extractMatches`, { text: search })
      .catch((error) => alert(error));
    const initialMatches = await Promise.all(
      _.map(response.data.matches, async (match, matchIndex) => ({
        begin: match.begin,
        end: match.end,
        weightedLocations: await Promise.all(
          _.map(match.weightedLocations, async (weightedLocation, weightedLocationIndex) => ({
            ...weightedLocation,
            location: await this.fetchLocation(weightedLocation.location),
            key: weightedLocationIndex,
          })),
        ),
        key: matchIndex,
      })),
    );
    this.setState({
      manuallyValidated: response.data.manuallyValidated,
      initialMatches: initialMatches,
      matches: initialMatches,
    });
  };

  handleLocationSearchSubmit = async (match, weightedLocation, search) => {
    const response = await axios.post(`${baseUrl}/locationsV2/safelyExtractLocations`, { text: search });
    const newLocation = response.data.locations.length > 0 ? response.data.locations[0] : {};
    const newWeightedLocation = { ...weightedLocation };
    newWeightedLocation.location = newLocation;
    const newWeightedLocations = _.map(match.weightedLocations, (weightedLocation_) =>
      weightedLocation_.key === weightedLocation.key ? newWeightedLocation : weightedLocation_,
    );

    const newMatch = {
      begin: match.begin,
      end: match.end,
      key: match.key,
      weightedLocations: newWeightedLocations,
    };

    const newMatches = _.map(this.state.matches, (match_) => (match_.key !== match.key ? match_ : newMatch));

    await this.setState({
      matches: newMatches,
    });
  };

  addNewMatch = async () => {
    const newKey = this.state.matches.length > 0 ? _.max(_.map(this.state.matches, (match) => match.key)) + 1 : 0;
    const newMatches = [...this.state.matches, { key: newKey, begin: -1, end: -1, weightedLocations: [] }];
    this.setState({
      matches: newMatches,
    });
  };

  addNewWeightedLocation = async (match) => {
    const currentWeightedLocations = match.weightedLocations.slice();
    const newKey =
      currentWeightedLocations.length > 0
        ? _.max(_.map(currentWeightedLocations, (weightedLocation_) => weightedLocation_.key)) + 1
        : 0;
    const newWeightedLocations = currentWeightedLocations.concat([{ key: newKey }]) || [];
    const newMatches = _.map(this.state.matches, (match_) =>
      match_.key !== match.key
        ? match_
        : {
            key: match_.key,
            begin: match_.begin,
            end: match_.end,
            weightedLocations: newWeightedLocations,
          },
    );
    this.setState({ matches: newMatches });
  };

  deleteMatch = async (match) => {
    const newMatches = _.filter(this.state.matches, function(match_) {
      return match_.key !== match.key;
    });

    await this.setState({
      matches: newMatches,
    });
  };

  onModalClose = async () => {
    this.setState({ matches: this.state.initialMatches });
  };

  deleteWeightedLocation = async (match, weightedLocation) => {
    const newWeightedLocations = _.filter(match.weightedLocations, function(weightedLocation_) {
      return weightedLocation_.key !== weightedLocation.key;
    });

    const newMatch = {
      begin: match.begin,
      end: match.end,
      key: match.key,
      weightedLocations: newWeightedLocations,
    };

    const newMatches = _.map(this.state.matches, (match_) => (match_.key !== match.key ? match_ : newMatch));

    this.setState({
      matches: newMatches,
    });
  };

  onSubmitProba = async (match, weightedLocation, proba) => {
    const newWeightedLocation = { ...weightedLocation, proba };

    const newWeightedLocations = _.map(match.weightedLocations, (weightedLocation_) =>
      weightedLocation_.key === weightedLocation.key ? newWeightedLocation : weightedLocation_,
    );

    const newMatch = {
      begin: match.begin,
      end: match.end,
      key: match.key,
      weightedLocations: newWeightedLocations,
    };

    const newMatches = _.map(this.state.matches, (match_) => (match_.key !== match.key ? match_ : newMatch));

    await this.setState({
      matches: newMatches,
    });
  };

  onSubmitBegin = async (match, begin) => {
    const newMatch = { ...match, begin };

    const newMatches = _.map(this.state.matches, (match_) => (match_.key !== match.key ? match_ : newMatch));

    await this.setState({
      matches: newMatches,
    });
  };

  onSubmitEnd = async (match, end) => {
    const newMatch = { ...match, end };

    const newMatches = _.map(this.state.matches, (match_) => (match_.key !== match.key ? match_ : newMatch));

    await this.setState({
      matches: newMatches,
    });
  };

  submitMapping = async () => {
    const text = this.state.search;
    const newMapping = {
      text,
      matches: _.map(this.state.matches, (match) => ({
        ...match,
        weightedLocations: _.map(match.weightedLocations, (weightedLocation) => ({
          ...weightedLocation,
          location: weightedLocation.location.id,
        })),
      })),
      source: 'manual',
    };
    const response = this.state.manuallyValidated
      ? await axios
          .post(`${baseUrl}/locationsV2/mapping/update`, { textLocationMapping: newMapping })
          .catch((error) => alert(error))
      : await axios
          .post(`${baseUrl}/locationsV2/mapping`, { textLocationMapping: newMapping })
          .catch((error) => alert(error));
    if (!response.data.ok) {
      alert(response.data.errorMessage);
    } else if (response && response.data.ok) {
      alert('Mapping succesfully updated');
    }
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <SearchWithOptions
                handleSubmit={this.handleGeneralSearchSubmit}
                placeholder={'Search mapping...'}
                loadOptions={this.loadOptions}
              />
            </Grid.Column>
            <Grid.Column>{this.state.search ? <Button>{this.state.search}</Button> : null}</Grid.Column>
            <Grid.Column>
              {this.state.search ? (
                this.state.manuallyValidated ? (
                  <Button basic color="green">
                    Manually validated
                  </Button>
                ) : (
                  <Button basic>Unknown pattern</Button>
                )
              ) : null}
            </Grid.Column>
            <Grid.Column>
              {this.state.search ? (
                <MappingEditor
                  textToEdit={this.state.search}
                  matches={this.state.matches || []}
                  addNewMatch={this.addNewMatch}
                  addNewWeightedLocation={this.addNewWeightedLocation}
                  deleteMatch={this.deleteMatch}
                  deleteWeightedLocation={this.deleteWeightedLocation}
                  onModalClose={this.onModalClose}
                  handleLocationSearchSubmit={this.handleLocationSearchSubmit}
                  loadOptions={this.loadOptions}
                  onSubmitProba={this.onSubmitProba}
                  onSubmitBegin={this.onSubmitBegin}
                  onSubmitEnd={this.onSubmitEnd}
                  onSubmit={this.submitMapping}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>{this.state.matches ? <Matches matches={this.state.matches} /> : null}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
