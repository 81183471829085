import _ from 'underscore';
import React from 'react';
import { Grid, Button, Label, Statistic } from 'semantic-ui-react';

import ClientsOverview from './ClientsOverview';
import SheetItemsOverview from './SheetItemsOverview';
import computeSheetItemsStats from '../sheetItemsStats';

const SheetItemsStats = ({sheetItemsStats}) => {
  const labelValues = [
    {label: "all", value: "nbCandidates"},
    {label: "contacted < 1m", value: "nbRecentContactCandidates"},
    {label: "answered < 1m", value: "nbRecentAnswerCandidates"},
    {label: "process < 1m", value: "nbRecentProcessCandidates"},
    {label: "other", value: "nbOtherCandidates"},
  ]
  return (
    <Statistic.Group size='mini'>
      {_.map(labelValues, ({label, value})=> (
        <Statistic key={value}>
          <Statistic.Label>{label}</Statistic.Label>
          <Statistic.Value>{sheetItemsStats[value]}</Statistic.Value>
        </Statistic>
      ))}
    </Statistic.Group>
  )
}

const DualOverviewHeader = ({
  mode,
  handleChangeMode,
  nbOtherMatchOpportunies,
  selectedUser,
  sheetItems
}) => {
  const sheetItemsStats = computeSheetItemsStats({sheetItems});
  return (
    <Grid columns={2} style={{ marginBottom: 5 }}>
      <Grid.Column width={4}>
        <Button color={mode === 'clients' ? 'green' : 'grey'} onClick={() => handleChangeMode('clients')}>
          Clients
        </Button>
        <Button color={mode === 'profiles' ? 'green' : 'grey'} onClick={() => handleChangeMode('profiles')}>
          Profiles
        </Button>
        {nbOtherMatchOpportunies !== undefined && (
          <Button color={nbOtherMatchOpportunies > 0 ? 'purple' : 'teal'}>{nbOtherMatchOpportunies}</Button>
        )}
        <Label>User: {selectedUser}</Label>
      </Grid.Column>
      <Grid.Column width={12}>
      <SheetItemsStats sheetItemsStats={sheetItemsStats}/>
      </Grid.Column>
    </Grid>
  );
}

const DualOverview = ({
  mode,
  handleChangeMode,

  nbOtherMatchOpportunies,

  clients,
  targetClientId,
  handleSelectTargetClient,
  handleLoadClientsSelection,

  sheetItems,
  targetSheetItemId,
  handleLoadProfilesSelection,
  handleSelectTargetSheetItem,

  handleUpdateProfile,
  handleRecomputeReverseSearch,

  handleChangeSelectedUser,
  usersOptions,
  selectedUser,
}) => (
  <div>
    <div>
      <DualOverviewHeader
        mode={mode}
        handleChangeMode={handleChangeMode}
        nbOtherMatchOpportunies={nbOtherMatchOpportunies}
        handleChangeSelectedUser={handleChangeSelectedUser}
        usersOptions={usersOptions}
        selectedUser={selectedUser}
        sheetItems={sheetItems}
      />
    </div>
    <div>
      {mode === 'clients' ? (
        <ClientsOverview
          mode={mode}
          clients={clients}
          targetClientId={targetClientId}
          handleSelectTargetClient={handleSelectTargetClient}
          handleLoadProfilesSelection={handleLoadProfilesSelection}
          handleLoadClientsSelection={handleLoadClientsSelection}
        />
      ) : mode === 'profiles' ? (
        <SheetItemsOverview
          mode={mode}
          sheetItems={sheetItems}
          targetSheetItemId={targetSheetItemId}
          handleSelectTargetSheetItem={handleSelectTargetSheetItem}
          handleLoadProfilesSelection={handleLoadProfilesSelection}
          handleLoadClientsSelection={handleLoadClientsSelection}
          handleRecomputeReverseSearch={handleRecomputeReverseSearch}
          handleUpdateProfile={handleUpdateProfile}
          selectedUser={selectedUser}
        />
      ) : null}
    </div>
  </div>
);

export default DualOverview;
