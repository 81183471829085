import React from 'react';
import _ from 'underscore';
import { Popup, Icon, Label } from 'semantic-ui-react';

const getWorkplaceTypeIcon = (type) =>
  type === 'ssii'
    ? 'desktop'
    : type === 'st' || type === 'startup'
    ? 'lightbulb'
    : type === 'big'
    ? 'industry'
    : 'question';

const WorkplaceIcon = ({ type }) => {
  return (
    <Popup
      trigger={<Icon size="large" name={getWorkplaceTypeIcon(type)} />}
      content={type}
    />
  );
};

const WorkplacePrestige = ({ prestige }) => {
  return (
    <Popup
      trigger={
        <Label size="large" color={prestige > 3 ? 'green' : 'orange'}>
          {prestige}
        </Label>
      }
      content="Workplace Prestige"
    />
  );
};

export const Workplace = ({ data }) => {
  const stack = (data || {}).stack || []
  // for test const stack = [{"name": {"default": "tos"}}, {"name": {"default": "tos"}}]
  const uniqueStack = _.unique(_.map(stack, (techno) => (
    (techno || {}).name || {}).default))

  return !data ? (
    <div />
  ) : (
    <div>
      <div>
        <h4>
          {_.isNumber(data.prestige) && (
            <WorkplacePrestige prestige={data.prestige} />
          )}
          {_.map(data.workplaceTypes, (type, index) => (
            <WorkplaceIcon key={index} type={type.id} />
          ))}
          {data.name}
        </h4>
        {_.isNumber(data.foundedYear) && (
          <h5>
            Founded in {data.foundedYear}
          </h5>
        )}
        {
          data.mainLocation && data.mainLocation.name && data.mainLocation.name.default ?
          <Label>{data.mainLocation.name.default}</Label> : <div/>
        }
        {_.map(data.industries, (industry, index) => (
          <Label key={index}>{((industry || {}).name || {}).default}</Label>
        ))}
      </div>
      {((data.description || {}).default || "").slice(0, 250) + '...'}
      {uniqueStack && (
        <div>
          Stack:{' '}
          {_.map(uniqueStack, (techno, index) => (
            <Label key={index}>{techno}</Label>
          ))}
        </div>
      )}
    </div>
  );
};
