import _ from 'underscore';

const maxRelevanceInEnrichedText = ({ content, entities }) => {
  const directMaxRelevance = _.reduce(
    entities,
    (memo, { relevance }) => Math.max(memo, relevance ? relevance : 0),
    0,
  );
  const childMaxRelevance = _.isArray(content)
    ? _.reduce(
        content,
        (memo, part) => Math.max(maxRelevanceInEnrichedText(part), memo),
        0,
      )
    : 0;
  return Math.max(directMaxRelevance, childMaxRelevance);
};

// this function is used to sort hints by maximum relevance across a whole enrichedText
const _mergeMemoList = (memo, [head, ...rest]) => {
  if (!head) {
    return memo;
  }

  const { id, relevance } = head;
  return _mergeMemoList(
    {
      ...memo,
      [id]: Math.max(memo[id] || 0, relevance),
    },
    rest,
  );
};

const _listHintsInEnrichedText = ({ content, entities }) => {
  const directHints = _.reduce(
    entities,
    (memo, { hints }) => _mergeMemoList(memo, hints || []),
    {},
  );
  return _.isArray(content)
    ? _.reduce(
        content,
        (memo, part) => {
          const child = _listHintsInEnrichedText(part);
          const keys = _.union(_.keys(memo), _.keys(child));
          return _.object(
            _.map(keys, (key) => [
              key,
              Math.max(memo[key] || 0, child[key] || 0),
            ]),
          );
        },
        directHints,
      )
    : {};
};

const listHintsInEnrichedText = ({ content, entities }) => {
  const result = _listHintsInEnrichedText({ content, entities });
  return _.map(
    _.sortBy(_.pairs(result), ([id, relevance]) => 100 - relevance),
    _.first,
  );
};

const listEntitiesInEnrichedText = (hint, { content, entities }) => {
  const directEntities = _.reduce(
    entities,
    (memo, { hints, id }) =>
      _.some(hints, ({ id: idHint }) => idHint === hint)
        ? _.union(memo, [id])
        : memo,
    [],
  );
  return _.isArray(content)
    ? _.reduce(
        content,
        (memo, part) => _.union(listEntitiesInEnrichedText(hint, part), memo),
        directEntities,
      )
    : [];
};

const transformEnrichmentByHint = (id, enrichment) => {
  if (_.isString(enrichment)) {
    return enrichment;
  }

  const { content, entities } = enrichment;

  return id
    ? {
        content: _.map(content, (c) => transformEnrichmentByHint(id, c)),
        entities: _.map(entities, ({ hints, ...entity }) => {
          const matchedHint = _.findWhere(hints, { id });
          return {
            ...entity,
            relevance: matchedHint ? matchedHint.relevance : 0,
          };
        }),
      }
    : enrichment;
};

const transformEnrichmentByEntity = (entityId, enrichment) => {
  if (_.isString(enrichment)) {
    return enrichment;
  }

  const { content, entities } = enrichment;
  return entityId
    ? {
        content: _.map(content, (c) =>
          transformEnrichmentByEntity(entityId, c),
        ),
        entities: _.filter(entities, ({ id }) => id === entityId),
      }
    : enrichment;
};

const getEnrichedTextByType = (enrichedData, type) =>
  _.findWhere(
    _.isObject(enrichedData) && _.isArray(enrichedData.content)
      ? enrichedData.content
      : [],
    { type },
  );

export {
  maxRelevanceInEnrichedText,
  listHintsInEnrichedText,
  listEntitiesInEnrichedText,
  transformEnrichmentByHint,
  transformEnrichmentByEntity,
  getEnrichedTextByType,
};
