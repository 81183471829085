//const WATCH_SHEET_ID = 'watch-sheet-1';

import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, List } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import baseUrl from '../../baseUrl';

const getAcquisitionProfilesLinkedinIds = async () => {
  try {
    const { linkedinIds } = (await axios.get(`${baseUrl}/talentAcquisition/profiles/linkedinIds`)).data;
    return linkedinIds;
  } catch (e) {
    alert(e.message);
  }
};

const getAcquisitionProfile = async ({ linkedinId }) => {
  try {
    const result = (await axios.get(`${baseUrl}/talentAcquisition/profiles/linkedinId/${linkedinId}`)).data;
    console.log(linkedinId, result)
    return result;
  } catch (e) {
    return { success: false, error: e.message };
  }
};

class AcquisitionProfiles extends React.Component {
  state = {
    linkedinIds: [],
    selectedProfile: null,
  };

  componentWillMount() {
    this.load();
  }

  load = async () => {
    const linkedinIds = await getAcquisitionProfilesLinkedinIds();
    this.setState({
      linkedinIds,
    });
  };

  onSelectProfile = async (linkedinId) => {
    const profile = await getAcquisitionProfile({ linkedinId });
    this.setState({
      selectedProfile: profile,
    });
  };

  render() {
    const { linkedinIds, selectedProfile } = this.state;
    return (
      <Grid columns={2}>
        <Grid.Column width={8}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '600px',
              width: '100%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <List>
              {_.map(linkedinIds, (linkedinId) => {
                const selected = ((selectedProfile || {}).idFields || {}).linkedin === linkedinId;
                return (
                  <List.Item as='a' fontWeight={selected ? 'bold' : 'normal'} onClick={()=>this.onSelectProfile(linkedinId)}>
                    {linkedinId}
                  </List.Item>
                );
              })}
            </List>
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '600px',
              width: '100%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {selectedProfile && <pre>{JSON.stringify(selectedProfile, null, 4)}</pre>}
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}
export default AcquisitionProfiles;
