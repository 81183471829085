import React from 'react';
import { Card, Segment, Header } from 'semantic-ui-react';
import _ from 'underscore';

export default class ClosestWorkplaces extends React.Component {
  render() {
    const sortedClosestWorkplaces = _.sortBy(this.props.workplace.closestWorkplaces, (wp) => -wp.score).slice(0, 20);
    const items = sortedClosestWorkplaces.map((workplace, index) => {
      return (
        <Card fluid key={index}>
          <Card.Content>
            <Card.Header>
              <a
                target='_blank'
                href={'/monitoring/enrichedWorkplace/' + workplace.workplaceId}
                rel='noopener noreferrer'
              >
                {' '}
                {workplace.workplaceName}
              </a>
            </Card.Header>
            <Card.Meta>{'Similarity : ' + Math.round(workplace.score * 100) / 100}</Card.Meta>
          </Card.Content>
        </Card>
      );
    });

    return (
      <Segment>
        <Header as='h2'>Similar Workplaces</Header>
        <Card.Group>{items}</Card.Group>
      </Segment>
    );
  }
}
