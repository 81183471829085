import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Form, Header, Icon, List, Segment, Table, Modal } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, Line, CartesianGrid, Tooltip } from 'recharts';
import { Grid, Checkbox } from 'semantic-ui-react';
import { throws } from 'assert';
import moment from 'moment/moment';
import { SweetForm, Select, Input } from 'sweetform';
import { Textarea } from '../common';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

const policyOptions = [
  {
    label: 'Hourly',
    value: 'hourly',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
];

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

class AddTrackerModal extends Component {
  async handleChange(params) {
    const { database, collection, mongoHost } = this.props || {};
    const isCorrectFilterParams = IsJsonString(params.rawQuery != undefined ? params.rawQuery : '{}');
    this.setState({
      mongoQuery: {
        mongoHost,
        collection,
        database,
        filterParams: params.rawQuery,
      },
      params,
      lastQueryResult: null,
      isCorrectFilterParams,
    });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const isCorrectFilterParams = (this.state || {}).isCorrectFilterParams != false;

    return (
      <Modal open closeIcon open={true} headerText={`Create tracker for collection`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Name</label>
              <Input autoFocus field='name' />
            </Form.Field>
            <Form.Field>
              <label>Policy</label>
              <Select field='policy' options={policyOptions} />
            </Form.Field>
            <Form.Field>
              <label>Filter</label>
              <Textarea
                style={{
                  background: isCorrectFilterParams ? '#e9ffd5' : '#ffeee6',
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='rawQuery'
                label='Raw Query Editor'
                placeholder='Write filter query - ex: {"status": "pending"}'
              />
            </Form.Field>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state.params)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class MongoInformationCollectionView extends Component {
  componentDidMount() {
    this.getTrackerInformation();
  }

  getRelevantTrackerIds = async () => {
    const { database, collection, mongoHost } = this.props || {};
    const payload = {
      mongoHost,
      database,
      collection,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getMongoTrackerIds';
    const data = (await axios.post(url, payload, customHeaders)).data;
    return data;
  };

  getSingleTrackerInformation = async (trackerId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getTrackerPoints/' + trackerId;
    const result = (await axios.get(url, customHeaders)).data;
    return result;
  };

  getTrackerInformation = async () => {
    const trackerIds = await this.getRelevantTrackerIds();
    var trackers = [];
    var trackerIdToDifferenceMode = {};
    for (var i = 0; i < trackerIds.length; i++) {
      const trackerData = await this.getSingleTrackerInformation(trackerIds[i].id);
      trackers.push({
        trackerId: trackerIds[i].id,
        trackerData: trackerData,
        name: trackerIds[i].name,
        mode: 'absolute',
      });
      trackerIdToDifferenceMode[trackerIds[i].id] = false;
    }
    this.setState({
      allTrackerInformation: trackers,
    });
  };

  deleteTracker = async (trackerId) => {
    const payload = {
      trackerId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteTracker';
    const data = (await axios.post(url, payload, customHeaders)).data;
    this.getTrackerInformation();
  };

  async handleToggleModeDifference(trackerId) {
    const oldTrackers = this.state.allTrackerInformation;
    const tracker = _.filter(oldTrackers, (tracker) => tracker.trackerId == trackerId)[0];
    const newMode = tracker.mode == 'absolute' ? 'diff' : 'absolute';
    const newTrackers = _.map(oldTrackers, (tracker) => {
      if (tracker.trackerId == trackerId) {
        return {
          ...tracker,
          mode: newMode,
        };
      } else {
        return tracker;
      }
    });
    await this.setState({ allTrackerInformation: newTrackers });
  }

  plotTracker(trackerId, trackerName, trackerMode) {
    const difference = trackerMode == 'diff';
    const timeFormatter = (tick) => {
      return moment(new Date(tick)).format('YY-MM-DD');
    };
    const trackerInformation = _.find((this.state || {}).allTrackerInformation || [], { trackerId: trackerId });
    const trackerData = (trackerInformation || {}).trackerData;
    var plotData = [];

    if (difference) {
      const sortedTrackerData = _.sortBy(trackerData, 'timestamp');
      const indexedTrackerData = {};
      for (var idx = 0; idx < sortedTrackerData.length; idx++) {
        indexedTrackerData[idx] = sortedTrackerData[idx];
      }
      plotData = [];
      for (var i = 0; i < sortedTrackerData.length - 1; i++) {
        plotData.push({
          x: timeFormatter(sortedTrackerData[i + 1].timestamp),
          y: sortedTrackerData[i + 1].value - sortedTrackerData[i].value,
        });
      }
    } else {
      plotData = _.sortBy(
        _.map(trackerData, (stat) => ({
          x: timeFormatter(stat.timestamp),
          y: stat.value,
        })),
        'x',
      );
    }

    return (
      <Segment>
        <Header as='h3' textAlign='left'>
          {trackerName}
        </Header>
        <Checkbox slider onChange={() => this.handleToggleModeDifference(trackerId)} checked={difference} />
        <LineChart
          width={500}
          height={250}
          type='line'
          data={plotData}
          margin={{ top: 0, right: 5, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <Line type='monotone' dataKey='y' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <Tooltip />
          <YAxis
            label={{
              angle: -90,
              position: 'insideLeft',
              dy: 70,
              dx: 20,
            }}
          />
        </LineChart>
        <Icon style={{ cursor: 'pointer' }} name='delete' color='red' onClick={() => this.deleteTracker(trackerId)} />
        <b style={{ color: 'red' }}> Delete Tracker </b>
      </Segment>
    );
  }

  async handleSampleOne() {
    const payload = {
      mongoQuery: {
        queryType: 'sample',
        mongoHost: this.props.mongoHost,
        database: this.props.database,
        collection: this.props.collection,
      },
      outputType: 'float',
    };

    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/searchMongo';
    const data = (await axios.post(url, payload, customHeaders)).data;
    if (data.success == false) {
      alert('INVALID QUERY');
      return;
    }
    this.setState({ sampleOneData: data.results });
    return;
  }

  handleClickOnAddTracker() {
    this.setState({ trackerModalVisible: true });
  }

  onCancel = () => {
    this.setState({ trackerModalVisible: false });
  };

  onSubmit = async (params) => {
    if (!params.name) {
      alert('You Must Specify a name');
      return;
    }
    if (!params.rawQuery) {
      alert('You Must Specify a query');
      return;
    }
    if (!params.policy) {
      alert('You Must Specify a policy');
      return;
    }
    const { database, collection, mongoHost } = this.props || {};
    const payload = {
      mongoHost,
      database,
      collection,
      policy: params.policy,
      name: params.name,
      filterParams: JSON.parse(params.rawQuery != undefined ? params.rawQuery : '{}'),
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createTrackerMongo';
    const result = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ trackerModalVisible: false });
    this.getTrackerInformation();
  };

  render() {
    const { collection, database, mongoHost, type, keyType } = this.props;
    const { trackerModalVisible } = this.state || {};
    const allTrackerInformation = (this.state || {}).allTrackerInformation || [];
    const sampleOneData = (this.state || {}).sampleOneData;

    return (
      <div>
        {trackerModalVisible ? (
          <AddTrackerModal
            mongoHost={mongoHost}
            database={database}
            collection={collection}
            onSubmit={(params) => this.onSubmit(params)}
            onCancel={this.onCancel}
          />
        ) : null}
        <List>
          {mongoHost ? (
            <List.Item>
              <b>{'Mongo Host : ' + mongoHost} </b>
            </List.Item>
          ) : null}
          {database ? (
            <List.Item>
              <b>{'Database : ' + database} </b>
            </List.Item>
          ) : null}
          {collection ? (
            <List.Item>
              <b>{'Collection: ' + collection} </b>
            </List.Item>
          ) : null}
          <List.Item>
            <b>{'Type : ' + type} </b>
          </List.Item>
          <List.Item>
            <b>{'Object Type : ' + keyType} </b>
          </List.Item>
          <List.Item>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => this.handleSampleOne()}>
                {'Sample One'}
              </a>
              {sampleOneData ? <Segment>{JSON.stringify(sampleOneData, null, '\t')}</Segment> : null}
            </div>
          </List.Item>
        </List>
        <Header as='h2'>Trackers :</Header>
        <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnAddTracker()}>
          <Icon name='add' size='big' color='green' /> <b style={{ color: 'green' }}> Add Tracker</b>
        </a>
        <br /> <br />
        <Grid columns={2}>
          {_.map(allTrackerInformation, (trackerInfo) => (
            <Grid.Column>{this.plotTracker(trackerInfo.trackerId, trackerInfo.name, trackerInfo.mode)}</Grid.Column>
          ))}
        </Grid>
      </div>
    );
  }
}
export default MongoInformationCollectionView;
