import _ from 'underscore';
import React from 'react';
import { Menu } from 'semantic-ui-react';

export default ({ current, total, onPage, floated }) => (
  <Menu pagination floated={floated}>
    <Menu.Item active={current === 0} onClick={() => onPage(0)}>
      1
    </Menu.Item>
    {total > 15 && current > 4 ? <Menu.Item>&hellip;</Menu.Item> : null}
    {total > 2
      ? _.map(
          total <= 15
            ? _.range(1, total - 1)
            : _.range(
                Math.max(1, current - 4),
                Math.min(total - 1, current + 5),
              ),
          (i) => (
            <Menu.Item key={i} active={current === i} onClick={() => onPage(i)}>
              {i + 1}
            </Menu.Item>
          ),
        )
      : null}
    {total > 15 && current < total - 5 ? <Menu.Item>&hellip;</Menu.Item> : null}
    {total > 1 ? (
      <Menu.Item
        active={current === total - 1}
        onClick={() => onPage(total - 1)}
      >
        {total}
      </Menu.Item>
    ) : null}
  </Menu>
);
