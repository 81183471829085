var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import Immutable from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';

var SweetForm = function (_React$Component) {
  _inherits(SweetForm, _React$Component);

  function SweetForm(props) {
    _classCallCheck(this, SweetForm);

    var _this = _possibleConstructorReturn(this, (SweetForm.__proto__ || Object.getPrototypeOf(SweetForm)).call(this, props));

    _this.state = {
      data: props.initialValues ? Immutable.fromJS(props.initialValues) : Immutable.Map()
    };
    return _this;
  }

  _createClass(SweetForm, [{
    key: 'getChildContext',
    value: function getChildContext() {
      var _this2 = this;

      return {
        path: [],
        getState: function getState() {
          return _this2.state.data;
        },
        setState: function setState(path, value) {
          _this2.setState(function (prevState) {
            return {
              data: prevState.data.setIn(path, value)
            };
          }, function () {
            if (_this2.props.onChange) {
              _this2.props.onChange(_this2.state.data.toJS());
            }
          });
        }
      };
    }
  }, {
    key: 'render',
    value: function render() {
      return React.createElement('div', {
        className: 'sweetform'
      }, this.props.children);
    }
  }]);

  return SweetForm;
}(React.Component);

SweetForm.childContextTypes = {
  path: PropTypes.array,
  getState: PropTypes.func,
  setState: PropTypes.func
};
export default SweetForm;