import React from 'react';
import _ from 'underscore';


class LoadingComponent extends React.PureComponent {
  render() {
    const { as = 'span', loading, length = 10, rows = 1, randomCoef = 0, margin = 0, ...props } = this.props;

    if (loading) {
      return (
        <div style={{ margin: `${margin}rem 0` }}>
          {_.times(rows, (i) => (
            <div
              key={i}
              className="loading-component"
              style={{
                width: `${length * 0.4 + _.random(-randomCoef, randomCoef)}rem`,
              }}
            />
          ))}
        </div>
      );
    }
    return React.createElement(as, props);
  }
}


export default LoadingComponent;