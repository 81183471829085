import React from 'react';
import _ from 'underscore';
import { List, Icon } from 'semantic-ui-react';
import Copy from '../../components/Copy';
import { getFullUrlWithHttp } from '../../common';
import externalSources from '../sources';

const getSearchSourceLink = ({ site, firstname, lastname }) => {
  const link = 'https://google.com/search?q=';
  const paramsSite = site && site !== 'name' ? `site:${site}.com ` : '';
  const paramsName = `"${firstname || ''} ${lastname || ''}"`;
  return `${link}${encodeURIComponent(paramsSite + paramsName)}`;
};

const MiscProfileItem = ({
  id,
  link,
  icon,
  label,
  missing,
  expanded,
  onRemoveSource,
  extraLinks,
}) => (
  <span>
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(getFullUrlWithHttp(link), '_blank');
        for (let i = 0; i < (extraLinks || []).length; i++) {
          window.open(getFullUrlWithHttp(extraLinks[i]), '_blank');
        }
      }}
    >
      <a
        target="_blank"
        href={getFullUrlWithHttp(link)}
        style={{ color: missing ? '#a5a3a3' : null }}
        className={missing ? 'source-search' : 'source-link'}
        rel='noopener noreferrer'
      >
        {expanded ? label : ''} <Icon name={icon} />
      </a>
      {_.map(extraLinks, (extraLink, index) => (
        // eslint-disable-next-line
        <a
          key={index}
          target="_blank"
          href={getFullUrlWithHttp(extraLink)}
          // style={{ color: missing ? '#a5a3a3' : null }}
          className={missing ? 'source-search' : 'source-link'}
          rel='noopener noreferrer'
        />
      ))}
    </span>
    {!missing && onRemoveSource ? (
      <Icon
        link
        name="close"
        size="small"
        color="red"
        style={{ marginLeft: -6 }}
        onClick={onRemoveSource}
      />
    ) : null}
    {id === 'linkedin' ? <Copy text={link} style={{ marginLeft: 0 }} /> : null}
  </span>
);

export const MiscProfile = ({
  idFields: ids,
  expanded,
  sources,
  onRemoveSource,
}) => (
  <List horizontal={true} className="sources-link-list">
    {_.map(externalSources, ({ id, icon, label, url, searchSite }) =>
      ids[id] ? (
        <List.Item key={id}>
          <MiscProfileItem
            id={id}
            link={url.replace(':id', ids[id])}
            icon={icon}
            expanded={expanded}
            label={label}
            onRemoveSource={
              onRemoveSource ? () => onRemoveSource(id) : undefined
            }
          />
        </List.Item>
      ) : searchSite && sources ? (
        <List.Item key={id}>
          <MiscProfileItem
            id={id}
            link={getSearchSourceLink({
              site: searchSite,
              firstname: (((sources || {}).linkedin || {}).data || {})
                .firstname,
              lastname: (((sources || {}).linkedin || {}).data || {}).lastname,
            })}
            extraLinks={[
              ...(id === 'github' &&
              (((sources || {}).linkedin || {}).data || {}).firstname &&
              (((sources || {}).linkedin || {}).data || {}).lastname
                ? [
                    `https://github.com/search?q=${(
                      ((sources || {}).linkedin || {}).data || {}
                    ).firstname.toLowerCase()}+${(
                      ((sources || {}).linkedin || {}).data || {}
                    ).lastname.toLowerCase()}&type=Users`,
                  ]
                : []),
            ]}
            icon={icon}
            expanded={expanded}
            label={label}
            missing={true}
          />
        </List.Item>
      ) : null,
    )}
  </List>
);
