import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GenericDashboard from '../Boards/GenericDashboard'; 

class DashboardContainer extends React.Component {

  state = { 
    dashboard : undefined
  }

  handleLoad = async () => {
    const { dashboardId } = this.props;
    const { data } = await axios.get(`${baseUrl}/stats/dashboards/${dashboardId}`);
    console.log('Dashboard', data);
    if (data.error) {
      return alert(data.error);
    }
    this.setState({ dashboard: data });
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    const { dashboard } = this.state;

    if (!dashboard) {
      return (
        <div>
          <br/><br/><br/>
          <center> <Loader active inline /> </center>
          <br/><br/><br/>
        </div>
      );
    }

    return (
      <center>
        <GenericDashboard dashboard={dashboard} />
      </center>
    );
  }
}

class StatsDashboards extends React.Component {

  state = {
    dashboards: undefined,
  }

  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/stats/dashboards`);
    if (data.error) {
      return alert(data.error);
    }
    this.setState({
      dashboards: data
    });
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {

    const { dashboards } = this.state;

    if (dashboards === undefined) {
      return <span>Loading...</span>
    }

    return (
      <div>
        <Tabs>
          <TabList>
            {_.map(dashboards, ({ id, title }, index) => (
              <Tab key={id}>{title}</Tab>
            ))}
          </TabList>
          {_.map(dashboards, ({ id, title }, index) => (
            <TabPanel key={id}>
              <DashboardContainer dashboardId={id} />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default StatsDashboards;
