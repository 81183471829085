import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Menu, Label, Button } from 'semantic-ui-react';

import { buildRoutes, url } from './routes';

import RegressionsSummary from './RegressionsSummary.component';

class RegressionsSelection extends React.Component {
  handleChangeDate = (date) => {
    const urlName = this.props.worker
      ? 'regressions:workers:failures'
      : 'regressions:workers';
    this.props.history.push(
      url(urlName, {
        workerId: this.props.worker,
        date: date.format('YY-MM-DD'),
      }),
    );
  };

  render() {
    const datesDiffs = [-7, -3, -2, -1, 0, +1, +2, +3, +7];
    const dates = computeDates(this.props.currentDate, datesDiffs);
    const current = moment(this.props.currentDate, 'YY-MM-DD');

    return (
      <div className="regressions-selection">
        {this.props.worker ? (
          <Menu className="dates">
            {dates.map((date) => (
              <Menu.Item
                key={date.toString()}
                active={date.isSame(current, 'day')}
                onClick={() => this.handleChangeDate(date)}
              >
                {date.format('ddd D MMM')}
                <Label color="teal">
                  {date.isSame(moment(), 'day')
                    ? 'Today'
                    : date.endOf('day').fromNow()}
                </Label>
              </Menu.Item>
            ))}
          </Menu>
        ) : null}
        <RegressionsSummary />
        <Button
          onClick={() =>
            this.props.history.push(this.props.history.location.pathname)
          }
          primary
        >
          Refresh
        </Button>
        <h2>
          {this.props.worker} on {current.format('ddd D MMM')}
          &nbsp;
          <Label color="teal" size="big">
            {current.isSame(moment(), 'day')
              ? 'Today'
              : current.endOf('day').fromNow()}
          </Label>
        </h2>
        <Switch>{buildRoutes(this.props.routes)}</Switch>
      </div>
    );
  }
}

function computeDates(date, diffs) {
  const currentDate = moment(date, 'YY-MM-DD');
  const now = moment().add(1, 'day');

  return diffs
    .map((d) => currentDate.clone().add(d, 'days'))
    .filter((d) => d.isBefore(now, 'day'));
}

function mapStateToProps(state) {
  return {
    currentDate: state.failuresByUser.get('date', moment()),
    worker: state.failuresByUser.get('selectedWorker'),
  };
}

export default withRouter(connect(mapStateToProps)(RegressionsSelection));
