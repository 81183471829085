import axios from 'axios';
import _ from 'underscore';

import React, { Component } from 'react';

import { Button, Form, Grid } from 'semantic-ui-react';
import { Checkbox, Input, Select, SweetForm } from 'sweetform';
import baseUrl from '../baseUrl.js';
export const archiveTypesMap = [
  { value: 'profile-not-interested', id: 'profile-not-interested' },
  { value: 'profile-interested', id: 'profile-interested' },
  { value: 'profile-interested_they-rejected-us', id: 'profile-interested;they-rejected-us' },
  { value: 'profile-interested_we-rejected-them', id: 'profile-interested;we-rejected-them' },
  { value: 'profile-interested_other-archive-reason', id: 'profile-interested;other-archive-reason' },
  { value: 'no-answer', id: 'no-answer' },
  { value: 'not-contacted', id: 'not-contacted' },
];

const archiveTypeOptions = [
  { value: 'profile-not-interested', label: 'Profile not interested' },
  { value: 'profile-interested', label: 'Profile interested' },
  { value: 'profile-interested_they-rejected-us', label: 'They rejected us (profile interested)' },
  { value: 'profile-interested_we-rejected-them', label: 'We rejected them (profile interested)' },
  { value: 'profile-interested_other-archive-reason', label: 'Other archive reason (profile interested)' },
  { value: 'no-answer', label: 'No answer' },
  { value: 'not-contacted', label: 'Not contacted' },
];

const workingForClientOptions = [{ value: 'currently ', label: 'Currently' }, { value: 'ever', label: 'Ever' }];

const monthsOptions = [
  {
    value: undefined,
    label: 'no min nb months',
  },
].concat(
  _.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 42, 48, 60], (nb) => ({
    value: nb,
    label: nb + ' month(s)',
  })),
);

class AdministrativeFiltersMenu extends Component {
  async componentDidMount() {
    await this.setSavedAdministrativeSetting();
  }

  setSavedAdministrativeSetting = async () => {
    const savedAdministrativeSettings = (await this.getSavedAdministrativeSettings()) || {};
    const useFreelance = !_.isUndefined(savedAdministrativeSettings.useFreelance)
      ? !!savedAdministrativeSettings.useFreelance
      : true;
    const savedMinMonthsSinceLastHiring = !_.isUndefined(savedAdministrativeSettings.minMonthsSinceLastHiring)
      ? savedAdministrativeSettings.minMonthsSinceLastHiring
      : 6;
    const savedMinMonthsSinceLastInteraction = !_.isUndefined(savedAdministrativeSettings.minMonthsSinceLastInteraction)
      ? savedAdministrativeSettings.minMonthsSinceLastInteraction
      : 8;
    const savedMinMonthsSinceLastAddingToMission = !_.isUndefined(
      savedAdministrativeSettings.minMonthsSinceLastAddingToMission,
    )
      ? savedAdministrativeSettings.minMonthsSinceLastAddingToMission
      : 8;
    const savedMinMonthsSinceRemoveFromMission = !_.isUndefined(
      savedAdministrativeSettings.minMonthsSinceRemoveFromMission,
    )
      ? savedAdministrativeSettings.minMonthsSinceRemoveFromMission
      : 8;
    const savedExcludedArchiveTypes = _.map(
      savedAdministrativeSettings.excludedArchiveTypes || [],
      (archiveType) => (_.find(archiveTypesMap, (item) => item.id === archiveType) || {}).value,
    ).join(';');
    const savedExcludedStageIds = (savedAdministrativeSettings.excludedStageIds || []).join(';');
    const savedAllKeywords = (savedAdministrativeSettings.allKeywords || []).join(';');
    const savedAnyKeywords = (savedAdministrativeSettings.anyKeywords || []).join(';');
    const savedFriendCompanies = (savedAdministrativeSettings.friendCompanies || []).join(';');
    const savedWorkingForClientMode = !_.isUndefined(savedAdministrativeSettings.workingForClientMode)
      ? savedAdministrativeSettings.workingForClientMode
      : 'ever';
    const formKey = '' + Math.random();
    this.setState({
      formKey,
      friendCompaniesKey: formKey,
      useFreelance,
      savedExcludedArchiveTypes,
      savedExcludedStageIds,
      savedAllKeywords,
      savedAnyKeywords,
      savedFriendCompanies,
      savedMinMonthsSinceLastHiring,
      savedMinMonthsSinceLastInteraction,
      savedMinMonthsSinceLastAddingToMission,
      savedMinMonthsSinceRemoveFromMission,
      savedWorkingForClientMode,
    });
  };

  getSavedAdministrativeSettings = async () => {
    const { selectedClient, selectedMissionId, selectedRecommenderSystemId } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: selectedRecommenderSystemId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getRecommenderSystemAdministrativeCriteria';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      return result.data;
    }
  };

  render() {
    const { onChangeAdministrativeSettings, updateAdministrativeCriteriaInMongo } = this.props;
    const {
      formKey,
      friendCompaniesKey,
      useFreelance,
      savedExcludedArchiveTypes,
      savedExcludedStageIds,
      savedAllKeywords,
      savedAnyKeywords,
      savedFriendCompanies,
      savedMinMonthsSinceLastHiring,
      savedMinMonthsSinceLastInteraction,
      savedMinMonthsSinceLastAddingToMission,
      savedMinMonthsSinceRemoveFromMission,
      savedWorkingForClientMode,
    } = this.state || {};

    return (
      <div>
        <h4> Administrative Settings</h4>
        <SweetForm onChange={(params) => onChangeAdministrativeSettings(params)} key={formKey}>
          <Grid>
            <Grid.Column width={2}>
              <Form.Field>
                <Checkbox defaultValue={useFreelance} field='useFreelance' label='Filter Freelance' />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Field>
                <label>Time Elapsed Since Last Hiring</label>
                <Select
                  placeholder='min nb months'
                  field='minMonthsSinceLastHiring'
                  options={monthsOptions}
                  defaultValue={savedMinMonthsSinceLastHiring}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Time Since Last Interaction</label>
                <Select
                  placeholder='min nb months'
                  field='minMonthsSinceLastInteraction'
                  options={monthsOptions}
                  defaultValue={savedMinMonthsSinceLastInteraction}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Time Since Last Adding to Mission</label>
                <Select
                  placeholder='min nb months'
                  field='minMonthsSinceLastAddingToMission'
                  options={monthsOptions}
                  defaultValue={savedMinMonthsSinceLastAddingToMission}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Time Since Remove from Mission</label>
                <Select
                  placeholder='min nb months'
                  field='minMonthsSinceRemoveFromMission'
                  options={monthsOptions}
                  defaultValue={savedMinMonthsSinceRemoveFromMission}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Field>
                <label>Excluded Archive Types</label>
                <Select
                  multi
                  field='excludedArchiveTypes'
                  options={archiveTypeOptions}
                  defaultValue={savedExcludedArchiveTypes}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Excluded Stages</label>
                <Select multi field='excludedStageIds' defaultValue={savedExcludedStageIds} />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Exclude profiles working for client</label>
                <Select
                  field='workingForClientMode'
                  options={workingForClientOptions}
                  defaultValue={savedWorkingForClientMode}
                />
              </Form.Field>
              <br />
              <SweetForm onChange={(params) => onChangeAdministrativeSettings(params)} key={friendCompaniesKey}>
                <Form.Field>
                  <label>Companies to Exclude</label>
                  <Input
                    field='friendCompanies'
                    placeholder='separate names with ;'
                    defaultValue={savedFriendCompanies}
                  />
                </Form.Field>
              </SweetForm>
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Field>
                <label>All keywords</label>
                <br />
                <Input
                  fluid
                  field='allKeywords'
                  placeholder='separate keywords with ;'
                  defaultValue={savedAllKeywords}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <label>Any keywords</label>
                <br />
                <Input
                  fluid
                  field='anyKeywords'
                  placeholder='separate keywords with ;'
                  defaultValue={savedAnyKeywords}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
          <br />
        </SweetForm>
        <Button color='green' onClick={() => updateAdministrativeCriteriaInMongo()}>
          Save Administrative Settings In Mongo
        </Button>
        <Button color='green' onClick={() => this.setSavedAdministrativeSetting()}>
          Set Saved settings as current settings
        </Button>
      </div>
    );
  }
}

export default AdministrativeFiltersMenu;
