import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button, Image, Modal, Header, Icon } from 'semantic-ui-react';

export default class EditOfferRecipientsModal extends Component {
  state = {
    clientRecipients: this.props.clientRecipients || [],
    selectedRecipients: this.props.selectedRecipients || [],
  };
  handleSave = () => {
    this.props.onSubmit(this.state.selectedRecipients);
  };
  handleAddRecipient = (recipient) => {
    const { selectedRecipients } = this.state;
    this.setState({
      selectedRecipients: [...selectedRecipients, recipient],
    });
  };
  handleRemoveRecipient = (recipient) => {
    const { selectedRecipients } = this.state;
    this.setState({
      selectedRecipients: _.filter(
        selectedRecipients,
        (currentRecipient) => currentRecipient.email !== recipient.email,
      ),
    });
  };
  handleEnableRecipient = (recipient) => {
    this.setState((prevState) => ({
      selectedRecipients: _.map(prevState.selectedRecipients, (currentRecipient) => {
        if (currentRecipient.email !== recipient.email) {
          return currentRecipient;
        } else {
          return {
            ...currentRecipient,
            disabled: false,
          };
        }
      }),
    }));
  };
  renderRecipient = (recipient, onClick) => {
    if (recipient.disabled) {
      return null;
    }
    return (
      <Header onClick={onClick} style={pointerStyle}>
        <Image
          src={recipient.photoLink || '/images/defaultPhoto.png'}
          circular
          style={{ maxWidth: '60px' }}
          onError={(e) => {
            e.target.src = '/images/defaultPhoto.png';
          }}
        />
        <Header.Content>
          {recipient.firstName} ({recipient.alias})<Header.Subheader>{recipient.email}</Header.Subheader>
        </Header.Content>
      </Header>
    );
  };
  render() {
    const { onClose } = this.props;
    const { clientRecipients, selectedRecipients } = this.state;
    const selectableRecipients = _.filter(
      clientRecipients,
      (recipient) => !_.findWhere(selectedRecipients, { email: recipient.email }),
    );
    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>Edit Recipients</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Selected Recipients</strong>
                {_.map(selectedRecipients, (recipient) => (
                  <div key={recipient.email}>
                    {this.renderRecipient(recipient, () => {
                      this.handleRemoveRecipient(recipient);
                    })}
                  </div>
                ))}
              </Grid.Column>
              <Grid.Column width={8}>
                <strong>Available Recipients</strong>
                {_.map(selectableRecipients, (recipient) => (
                  <div key={recipient.email}>
                    {this.renderRecipient(recipient, () => {
                      this.handleAddRecipient(recipient);
                    })}
                  </div>
                ))}
              </Grid.Column>
              <Grid.Column width={8}>
                <strong>Disabled Recipients</strong>
                {_.map(selectedRecipients, (recipient) => {
                  return (
                    <DisabledRecipient
                      key={recipient.email}
                      recipient={recipient}
                      onEnable={() => {
                        this.handleEnableRecipient(recipient);
                      }}
                    />
                  );
                })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class DisabledRecipient extends React.Component {
  state = { canEnable: false };
  render() {
    const { recipient, onEnable } = this.props;
    const { canEnable } = this.state;
    const { disabled } = recipient;
    if (!disabled) {
      return null;
    }

    const onClickLockIcon = () => {
      this.setState((prevState) => ({
        canEnable: !prevState.canEnable,
      }));
    };

    return (
      <div>
        <Header>
          <Icon name={canEnable ? 'unlock' : 'lock'} color='red' onClick={onClickLockIcon} />
          <Header.Content>
            {recipient.firstName} ({recipient.alias})<Header.Subheader>{recipient.email}</Header.Subheader>
            {disabled && <Header.Subheader>Disabled by user</Header.Subheader>}
            {canEnable && (
              <Button onClick={onEnable} color='orange'>
                Enable recipient
              </Button>
            )}
          </Header.Content>
        </Header>
      </div>
    );
  }
}

const pointerStyle = {
  cursor: 'pointer',
};
