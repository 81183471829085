import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

const BigButton = ({ text, color, onClick, hoverText }) => (
  <Popup
    inverted
    content={hoverText}
    trigger={
      <Button
        circular
        color={color}
        size='massive'
        style={{ padding: '.78571429em .78571429em .78571429em' }}
        onClick={onClick}
      >
        <div
          style={{
            width: '30px',
            height: '30px',
            fontSize: '16px',
            lineHeight: '30px',
            verticalAlign: 'middle',
          }}
        >
          {text}
        </div>
      </Button>
    }
  />
);

export default BigButton;
