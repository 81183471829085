import React from 'react';
import _ from 'underscore';

import { Grid, Menu } from 'semantic-ui-react';

import FindSkills from './FindSkills';
import ReviewSkills from './ReviewSkills';

class ExtendSkills extends React.Component {
  handleChangeActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  render() {
    const tabNames = ['Find Skills', 'Review Skills'];
    const activeMenu = (this.state || {}).activeMenu;
    return (
      <Grid columns={2}>
        <Grid.Column width={2}>
          <Menu pointing secondary fluid vertical tabular>
            {_.map(tabNames, (tabName, index) => (
              <Menu.Item
                key={index}
                name={tabName}
                active={activeMenu === tabName}
                onClick={() => {
                  this.handleChangeActiveMenu(tabName);
                }}
              >
                {tabName}
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
        <Grid.Column width={14}>
          {activeMenu == 'Find Skills' ? <FindSkills /> : activeMenu == 'Review Skills' ? <ReviewSkills /> : null}
        </Grid.Column>
      </Grid>
    );
  }
}

export default ExtendSkills;
