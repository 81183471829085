import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import humanize from 'humanize-duration';

import { Menu, Tab, Segment, Header, Card, Accordion, Icon, Button, Input } from 'semantic-ui-react';
import Timer from './Timer';

import { DEFAULT_CATEGORY, DEFAULT_SERVICE } from './Dashboard.js';
import baseUrl from '../../baseUrl.js';

const unpackData = (data) => {
  let categories = [];

  const categoryToChecks = _.groupBy(data, ({ category }) => category || DEFAULT_CATEGORY);
  _.each(categoryToChecks, (categoryChecks, categoryName) => {
    let category = { name: categoryName, okCount: 0, alertCount: 0, services: [] };
    const serviceToChecks = _.groupBy(categoryChecks, ({ service }) => service || DEFAULT_SERVICE);
    _.each(serviceToChecks, (serviceChecks, serviceName) => {
      let service = { name: serviceName, okCount: 0, alertCount: 0, checks: [] };
      _.each(serviceChecks, (check) => {
        const checkIsOk = ((check.status || {}).lastCheck || {}).severity === 'ok';
        if (checkIsOk) {
          service.okCount++;
          category.okCount++;
        } else {
          service.alertCount++;
          category.alertCount++;
        }
        service.checks.push(check);
      });
      category.services.push(service);
    });
    categories.push(category);
  });

  return categories;
};

class ManualCheckHistory extends React.Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { history } = this.props;
    return (
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
          <Icon name='dropdown' />
          History
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          {_.map(history, ({ username, timestamp, message }) => {
            return (
              <div>
                <b>{username}</b> - <Timer time={timestamp} /> ago {message && `- ${message}`}
              </div>
            );
          })}
        </Accordion.Content>
      </Accordion>
    );
  }
}

class ManualCheck extends React.Component {
  state = { message: '' };

  handleChangeMessage = (e, { value }) => {
    this.setState({ message: value });
  };

  handleSubmit = () => {
    const { manualCheckId } = this.props.check;
    const { message } = this.state || {};
    this.props.handleSubmitManualCheck({ manualCheckId, message });
  };

  render() {
    const { name, state, schedule, checker } = this.props.check;
    const { dayIntervalStr, hourIntervalStr } = schedule || {};
    const scheduleStr = (dayIntervalStr || hourIntervalStr) && `Check on ${dayIntervalStr} (days) at ${hourIntervalStr} (hours)`;
    const { maxDelay } = checker || {};
    const { current, history } = state || {};

    return (
      <Card>
        <Card.Content>
          <Card.Header>{name}</Card.Header>
          <Card.Meta>
            { scheduleStr ? <span>{scheduleStr}<br/></span> : null }
            {`Should be less than ${humanize(maxDelay)}`}
          </Card.Meta>

          <Card.Description>
            {current ? (
              <div>
                <b>{current.username}</b> - <Timer time={current.timestamp} /> ago{' '}
                {current.message && `- ${current.message}`}
              </div>
            ) : (
              <div>No manual checks yet</div>
            )}
            {history && <ManualCheckHistory history={history} />}
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            <Input value={this.state.message} onChange={this.handleChangeMessage} placeholder='message' />
            <Button secondary color='green' icon onClick={this.handleSubmit}>
              <Icon name='check' />
            </Button>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

const ManualCheckService = ({ service, handleSubmitManualCheck }) => {
  return (
    <div>
      <Header as='h2'>{service.name}</Header>
      <Card.Group>
        {_.map(service.checks, (check, index) => (
          <ManualCheck key={index} check={check} handleSubmitManualCheck={handleSubmitManualCheck} />
        ))}
      </Card.Group>
    </div>
  );
};
export default class ManualChecksDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      loading: false,
      error: false,
    };
  }

  async loadData() {
    try {
      this.setState({ loading: true });
      const result = await axios.get(`${baseUrl}/sentry/manualChecks`);
      const categories = unpackData(result.data);
      this.setState({
        loading: false,
        error: false,
        categories,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  handleSubmitManualCheck = async ({ manualCheckId, message }) => {
    try {
      const result = (await axios.put(`${baseUrl}/sentry/manualChecks/${manualCheckId}`, message && { message })).data;
      if (result.error) {
        throw Error(result.error);
      }
      this.loadData();
    } catch (e) {
      return alert(e);
    }
  };

  async componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    if (this.state.error) {
      return <div>Loading failed.</div>;
    }
    const categories = this.state.categories;

    const panes = _.map(categories, ({ name, services, okCount, alertCount }) => {
      return {
        menuItem: <Menu.Item key={name}>{`${name} (${(okCount || 0) + (alertCount || 0)})`}</Menu.Item>,
        render: () => (
          <Tab.Pane>
            {_.map(services, (service) => (
              <ManualCheckService
                service={service}
                key={service.name}
                handleSubmitManualCheck={this.handleSubmitManualCheck}
              />
            ))}
          </Tab.Pane>
        ),
      };
    });

    return (
      <div>
        <Tab panes={panes} />
      </div>
    );
  }
}
