import React from 'react';
import {
  Card,
  Grid,
  Icon,
} from 'semantic-ui-react';
import StatsTable from '../StatsTable';


const ClientCard = ({ 
  client, 
  stats,
  targetClientId,
  handleSelectTargetClient,
  handleLoadProfilesSelection,
  handleLoadClientsSelection,
  mode
}) => {
  return (
    <Card fluid>
      <Card.Content>
        <Grid>
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column width={4} verticalAlign="middle">
              <h3
                style={{
                  color:
                      targetClientId && targetClientId === client.id
                      ? 'blue'
                      : 'black',
                }}
              >
                <span>{client.id} </span>
                {targetClientId && targetClientId === client.id ? (
                  <Icon
                    name="bookmark"
                    link
                    onClick={() => handleSelectTargetClient(null)}
                  />
                ) : client.id ? (
                  <Icon
                    name="bookmark outline"
                    link
                    onClick={() => handleSelectTargetClient(client.id)}
                  />
                ) : null}
              </h3>
            </Grid.Column>
            <Grid.Column width={12} verticalAlign="middle">
              <StatsTable
                id={client.id}
                stats={stats}
                handleLoadProfilesSelection={handleLoadProfilesSelection}
                handleLoadClientsSelection={handleLoadClientsSelection}
                mode={mode}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
}

export default ClientCard;
