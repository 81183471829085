import _ from 'underscore';
import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import {
  Table,
  Grid,
  Icon,
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import Modal from '../Modal';
import OfferCriteriaDiff from '../components/OfferCriteriaDiff';

const FormatValue = ({ value, path, color }) => {
  if (_.isArray(value)) {
    return (
      <span>
        {_.map(value, (subVal, index) => (
          <FormatValue key={index} path={path} color={color} value={subVal} />
        ))}
      </span>
    );
  }
  if (_.isString(value) || _.isNumber(value)) {
    return <span style={{ color: color || 'black' }}>{value} </span>;
  }
  if (_.isBoolean(value)) {
    return <span style={{ color: color || 'black' }}>{value ? 'True' : 'False'}</span>;
  }
  if (_.isObject(value) && (value.min || value.max)) {
    return (
      <span>
        <FormatValue path={path} color={color} value={value.min} />-
        <FormatValue path={path} color={color} value={value.max} />
      </span>
    );
  }
  if (_.isObject(value) && ((value.location || {}).id || value.remoteWish)) {
    return (
      <span>
        <FormatValue path={path} color={color} value={(value.location || {}).id} />-
        <FormatValue path={path} color={color} value={value.remoteWish} />
      </span>
    );
  }
  if (_.isObject(value) && value.identifier) {
    return <FormatValue value={value.identifier} path={path} color={color} />;
  }
  return <Icon color={color} name="close" />;
};

const CompactOfferCriteria = ({ criteria, actions }) => {
  const paths = [
    'jobPositions.required',
    'locationsAndRemote.target',
    'locationsAndRemote.okWith',
    'remote',
    'freelance',
    'education.important',
    'experience.years',
    'salary',
    'skills.required',
    'skills.important',
    'skills.bonus',
    'languages.important',
    'collaboratorsInCharge',
    'blacklistedCompanies',
    'relevantCompanies',
    'onlineLinks',
    'exampleProfiles',
    'extraKeyWords',
    'seekingCandidatesOnly',
    'moreInfo'
  ];

  const getValue = (obj, path) => {
    const parts = path.split('.');
    let result = obj;
    for (let iPart = 0; iPart < parts.length; iPart++) {
      result = (result || {})[parts[iPart]];
    }
    return result;
  };

  const lastSetDateFromPath = {};
  const seenDiffForPath = {};
  _.each(_.sortBy(actions, 'date').reverse(), (action) => {
    _.each(paths, (path) => {
      const val = getValue(criteria, path);
      const oldVal = getValue(action.newCriteria, path);
      const isDiff = JSON.stringify(val) !== JSON.stringify(oldVal);
      if (isDiff) {
        seenDiffForPath[path] = true;
      }
      if (!seenDiffForPath[path]) {
        lastSetDateFromPath[path] = action.date;
      }
    });
  });

  const formatPath = (path) => {
    if (path === 'skills.required') {
      return 'required skills';
    } else if (path === 'skills.important') {
      return 'important skills';
    } else if (path === 'skills.bonus') {
      return 'bonus skills';
    } else if (path === 'jobPositions.required') {
      return 'job position';
    } else if (path === 'education.important') {
      return 'education';
    } else if (path === 'experience.years') {
      return 'xp';
    } else if (path === 'languages.important') {
      return 'languages';
    } else if (path === 'locationsAndRemote.target') {
      return 'locations target';
    } else if (path === 'locationsAndRemote.okWith') {
      return 'locations ok';
    } else {
      return path;
    }
  };

  const colorFromDate = (date) => {
    if (!date) {
      return 'grey';
    }
    const diffTs = Date.now() - new Date(date).getTime();
    const nbMsInADay = 24 * 3600 * 1000;
    if (diffTs <= 3 * nbMsInADay) {
      return 'red';
    }
    if (diffTs <= 10 * nbMsInADay) {
      return 'orange';
    }
    return 'black';
  };

  return (
    <Table basic="very" celled={false}>
      <Table.Body>
        {_.compact(
          _.map(
            paths,
            (path, index) =>
              getValue(criteria, path) && (
                <Table.Row key={index} style={{ whiteSpace: 'no-wrap' }}>
                  <Table.Cell style={{ padding: 0 }}>
                    <span
                      style={{
                        color: colorFromDate(lastSetDateFromPath[path]),
                      }}
                    >
                      {formatPath(path)}
                    </span>
                  </Table.Cell>
                  <Table.Cell style={{ padding: 0 }}>
                    <FormatValue
                      path={path}
                      value={getValue(criteria, path)}
                      color={colorFromDate(lastSetDateFromPath[path])}
                    />
                  </Table.Cell>
                </Table.Row>
              ),
          ),
        )}
      </Table.Body>
    </Table>
  );
};

class OfferCriteria extends Component {
  state = {};
  componentWillMount() {
    this.handleLoadOfferCriteria(this.props.jobOfferId);
  }
  handleLoadOfferCriteria = async (jobOfferId) => {
    const offerData = (await axios.post(baseUrl + '/station/offers', {
      ids: jobOfferId,
    })).data;
    if (_.isEmpty(offerData.results)) {
      return alert('no results retrieved');
    }

    const actionData = (await axios.post(`${baseUrl}/station/actions`, {
      jobOfferIds: jobOfferId,
      types: 'edit_criteria',
    })).data;

    const actions = (actionData || {}).results;

    const offer = offerData.results[0];
    this.setState({
      offer,
      actions,
    });
  };
  handleToggleExpand = () => {
    this.setState({
      timelineExpanded: !this.state.timelineExpanded,
    });
  };
  showDiffModal = (diff) => {
    this.setState({
      shownDiff: diff,
    });
  };
  closeDiffModal = () => {
    this.setState({
      shownDiff: null,
    });
  };
  renderCompactCriteria(criteria, actions) {
    return <div>{JSON.stringify(criteria.blacklistedCompanies)}</div>;
  }
  renderActionRow(action, criteria) {
    const date = action.date || new Date(0);
    const author = action.author;
    return (
      <Grid style={{ margin: 0, padding: 0 }}>
        <Grid.Row style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column width={6} style={{ padding: 0 }}>
            <span style={{ fontSize: 10 }}>
              {new Date(date).toDateString()} :{' '}
              {new Date(date).toLocaleTimeString()}
            </span>
          </Grid.Column>
          <Grid.Column width={4}>
            <span>{(author || '').split(' ')[0]}</span>
          </Grid.Column>
          <Grid.Column width={3}>
            {/* eslint-disable-next-line */}
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDiffModal(action)}
            >
              local diff
            </a>
          </Grid.Column>
          <Grid.Column width={3}>
            {/* eslint-disable-next-line */}
            <a
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.showDiffModal({
                  oldCriteria: action.newCriteria,
                  newCriteria: criteria,
                })
              }
            >
              global diff
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderTimeline(criteria, actions) {
    if (!this.state.timelineExpanded) {
      return;
    }
    return (
      <div>
        <h4 style={{ marginTop: 10, marginBottom: 5 }}>Updates</h4>
        {_.map(actions, (action, index) => (
          <div key={index}>{this.renderActionRow(action, criteria)}</div>
        ))}
      </div>
    );
  }
  renderTimelineToggle() {
    const { actions, timelineExpanded } = this.state;
    if (_.isEmpty(actions)) {
      return;
    }
    return (
      <center style={{ paddingTop: 5 }}>
        {/* eslint-disable-next-line */}
        <a
          style={{ cursor: 'pointer' }}
          basic
          onClick={() => this.handleToggleExpand()}
        >
          {timelineExpanded
            ? 'collapse'
            : 'see ' +
              actions.length +
              ' update' +
              (actions.length > 1 ? 's' : '')}
        </a>
      </center>
    );
  }
  render() {
    const { offer, actions, shownDiff } = this.state;

    if (!offer && !actions) {
      return <div />;
    }

    let lastActionDate = undefined;
    _.each(actions, ({ date }) => {
      if (new Date(date).getTime() > new Date(lastActionDate || 0).getTime()) {
        lastActionDate = new Date(date);
      }
    });

    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7}>
              <h3>
                Criteria{' '}
                <Icon
                  name="eye"
                  link
                  onClick={() => {
                    this.showDiffModal({
                      newCriteria: offer.criteria || {},
                      noOldCriteria: true,
                    });
                  }}
                />
              </h3>
            </Grid.Column>
            <Grid.Column width={9} textAlign="right">
              {lastActionDate && (
                <em>Edition: {moment(lastActionDate).fromNow()}</em>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <CompactOfferCriteria
          criteria={(offer || {}).criteria || {}}
          actions={actions}
        />
        {this.renderTimeline((offer || {}).criteria || {}, actions)}
        {this.renderTimelineToggle()}
        {shownDiff && (
          <Modal
            active={true}
            headerText="Criteria"
            submitText="OK"
            cancelText=""
            onSubmit={this.closeDiffModal}
            onCancel={this.closeDiffModal}
            size="fullscreen"
          >
            <OfferCriteriaDiff {...shownDiff} />
          </Modal>
        )}
      </div>
    );
  }
}

export default OfferCriteria;
