import _ from 'underscore';
import React from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';

const items = [
  {
    text: 'Ever Been To (Location)',
    value: 'ever-been-to-location',
  },
  {
    text: 'Ever Been To Relaxed(Location)',
    value: 'ever-been-to-relaxed-location',
  },
  {
    text: 'Experience Year',
    value: 'experience-year',
  },
  {
    text: 'Experience Tech Year',
    value: 'experience-tech-year',
  },
  {
    text: 'Freelance',
    value: 'freelance',
  },
  {
    text: 'Has Direct Skill',
    value: 'has-direct-skill',
  },
  {
    text: 'Has Skill',
    value: 'has-skill',
  },
  {
    text: 'Main Skill',
    value: 'main-skill',
  },
  {
    text: 'Main Skill Relaxed',
    value: 'main-skill-relaxed',
  },
  {
    text: 'Main Skill (new gen)',
    value: 'main-skill-new-gen',
  },
  {
    text: 'Is Ok With',
    value: 'is-ok-with',
  },
  {
    text: 'Match Anywhere',
    value: 'match-anywhere',
  },
  {
    text: 'Match Client Pipe',
    value: 'match-client-pipe',
  },
  {
    text: 'Min Nb Gihub Followers',
    value: 'min-nb-github-followers',
  },
  {
    text: 'Min Nb Gihub Repos',
    value: 'min-nb-github-repos',
  },
  {
    text: 'Min Skill Score',
    value: 'min-skill-score',
  },
  {
    text: 'Skill Range',
    value: 'skill-range',
  },
  {
    text: 'Solicitation Range',
    value: 'solicitation-range',
  },
  {
    text: 'Target Job Position',
    value: 'target-job-position',
  },
  {
    text: 'Target Job Position Relaxed',
    value: 'target-job-position-relaxed',
  },
  {
    text: 'Target Location',
    value: 'target-location',
  },
  {
    text: 'Target Location Relaxed',
    value: 'target-location-relaxed',
  },
  {
    text: 'Target Modality',
    value: 'target-modality',
  },
  {
    text: 'Target Responsability',
    value: 'target-responsability',
  },
  {
    text: 'Target Responsability Relaxed',
    value: 'target-responsability-relaxed',
  },
  {
    text: 'Time Elapsed Since Last Hiring',
    value: 'time-elapsed-since-last-hiring',
  },
  {
    text: 'Has Source',
    value: 'has-source',
  },
  {
    text: 'Is Female',
    value: 'is-female',
  },
  {
    text: 'Ever Answered Positively',
    value: 'ever-answered-positively',
  },
  {
    text: 'Nb Answers Range',
    value: 'nb-any-answers-range',
  },
  {
    text: 'Nb Positive Answers Range',
    value: 'nb-positive-answers-range',
  },
  {
    text: 'Disqualify Rate',
    value: 'disqualify-rate',
  },
  {
    text: 'Ever Pushed To Client',
    value: 'ever-pushed-to-client',
  },
  {
    text: 'Has Email',
    value: 'has-email',
  },
  {
    text: 'Has Been In Prepa',
    value: 'has-been-in-prepa',
  },
  {
    text: 'Target Schools',
    value: 'target-schools',
  },
  {
    text: 'School Category Range',
    value: 'school-category-range',
  },
  {
    text: 'Company Type',
    value: 'company-type',
  },
  {
    text: 'Company Prestige',
    value: 'company-prestige',
  },
  {
    text: 'Profile Last Update',
    value: 'profile-last-update',
  },
  {
    text: 'Super Match Sheets',
    value: 'super-match-sheets',
  },
  {
    text: 'Base Filter',
    value: 'base-filter',
  },
  {
    text: 'Temporal Answer Model (TAM)',
    value: 'tam',
  },
  {
    text: 'Profile Through Offer Model (PTO)',
    value: 'pto',
  },
  {
    text: 'Prestige Model',
    value: 'prestige-model',
  },
  {
    text: 'Multi Job Typo Model',
    value: 'multi-job-typo',
  },
  {
    text: 'Skill Match Model (Lo(u)cef)',
    value: 'skill-match',
  },
  {
    text: 'Funnel Regression Merger',
    value: 'funnel-regression-merger',
  },
  {
    text: 'Job Position Range',
    value: 'job-scores-s6',
  },
];

const docFromItemId = {
  'ever-been-to-location': (
    <div>
      <h1>Ever Been To (Location)</h1>
      <p>
        Renvoie True si on détecte la Location dans une expérience linkedin.
      </p>
      <p>
        On ne regarde que dans le champ propre à la location (pas de parse de la
        description ni de la workplace)
      </p>
    </div>
  ),
  'has-been-in-prepa': (
    <div>
      <h1>Has Been In Prepa</h1>
      <p>
        Renvoie True si on détecte l' un des patterns suivants dans les
        educations: classe preparatoire, classes preparatoires, preparatory
        course, preparatory courses, preparatory class, preparatory classes, pt,
        psi, mp, mpsi, pc, pcsi, prepa ingenieur, psi, tsi, cpge
      </p>
    </div>
  ),
  'target-schools': (
    <div>
      <h1>Target Schools</h1>
      <p>
        Renvoie True si on détecte une target schools dans une education qui
        dure plus de 24 mois
      </p>
    </div>
  ),
  'ever-been-to-relaxed-location': (
    <div>
      <h1>Ever Been To Relaxed (Location)</h1>
      <p>
        Renvoie True si on détecte la Location dans une expérience linkedin.
      </p>
      <p>
        On regarde dans le champ propre à la location. Si la location n’est pas
        présente, on regarde la location de la workplace associée et on ne
        valide la location que si l’entreprise compte moins de 100 employés
      </p>
    </div>
  ),
  'experience-year': (
    <div>
      <h1>Experience Year</h1>
      <p>
        Renvoie le nombre d’années d’expérience après la date de fin d’étude.
      </p>
      <p>
        Cette dernière est calculée comme étant un max de dates théoriques
        (début de licence + 3, début d’école + 3 ou 5, fin de master, fin
        d’école, fin du ‘bloc de formation’) en ignorant les petites expériences
        ayant lieu bien après les études (Si la formation est 2 ans après le
        ‘bloc d’étude’ et dure un an ou moins, elle ne compte pas)
      </p>
    </div>
  ),
  'experience-tech-year': (
    <div>
      <h1>Experience Tech Year</h1>
      <p>Renvoie le nombre d’années d’expérience tech.</p>
      <p>
        On cherche d'abord la première experience tech, puis on calcule le
        nombre d'années écoulées depuis la fin de l'education qui la précède du
        plus près.
      </p>
    </div>
  ),
  freelance: (
    <div>
      <h1>Freelance</h1>
      <p>
        Renvoie True si freelance est catché dans au moins une des expériences
        linkedin du profil (parsing des descriptions, des headlines et de la
        headline globale de linkedin).
      </p>
      <p>
        Les patterns sont :
        <ul>
          <li>'self-employed'</li>
          <li>'free-lance'</li>
          <li>'freelancer'</li>
          <li>'freelance'</li>
          <li>'indépendant'</li>
          <li>'independent'</li>
          <li>'self-entrepreneur'</li>
          <li>'freelancing'</li>
        </ul>
      </p>
    </div>
  ),
  'has-direct-skill': (
    <div>
      <h1>Has Direct Skill</h1>
      <p>
        Renvoie True si un des patterns du skill cible est catché dans le
        linkedin, le github ou le stackoverflow du profil
      </p>
    </div>
  ),
  'has-skill': (
    <div>
      <h1>Has Skill</h1>
      <p>
        Renvoie True si il y a une mention directe ou indirecte du skill cible
        quelque part dans les données du profil (pour l’instant GH-LNK-SO).
      </p>
      <p>
        La différence avec le has direct skill est qu’il peut ne pas y avoir de
        mention directe d’un pattern du skill cible, mais une mention d’un
        pattern d’un skill voisin dans l’ontologie des skills (par exemple, php
        en has-skill si symfony est catché quelque part dans le profil)
      </p>
    </div>
  ),
  'main-skill': (
    <div>
      <h1>Main Skill</h1>
      <p>
        Renvoie True si le skill cible est considéré comme un des skills
        principaux du profils.
      </p>
      <p>
        La sémantique exacte est: Le profil doit passer le filtre si il est
        raisonnable de considérer qu’un client recherchant cette compétence soit
        intéressé par ce profil.
      </p>
      <p>
        Quelques rules:
        <ul>
          <li>
            les job-positions: "doctor", "recruitment", "marketing", "sales",
            "military", "technician", "communication" ne peuvent pas avoir de
            main skill
          </li>
          <li>
            les job-position “project-management” ne peut avoir de main skill
            autre que product-management
          </li>
          <li>
            la liste des main_skills potentiels: 'ruby', 'design', 'react.js',
            'android-development', 'angular.js', 'scala', 'csharp', 'symfony',
            'data-science', 'cpp', 'devops', 'product-management', 'node.js',
            'ios-development', 'go', 'django', 'java’, 'php', 'python', 'swift',
            'objective-c', 'kotlin', 'computer-vision',
            'natural-language-processing', 'ruby-on-rails', 'data-engineering',
            'flask', 'elixir', 'react-native', 'R', 'c', 'dot-net', 'drupal',
            'laravel', 'express.js', 'ember.js', 'hibernate', 'spring',
            'machine-learning', 'javascript', 'rust', 'embedded-systems',
            'data-visualization', 'data-mining', 'spark', 'hadoop',
            'cryptocurrency', 'amazon-web-services', 'webrtc',
            'image-processing', 'robotics', 'unity3d', 'html', 'css'
          </li>
        </ul>
      </p>
    </div>
  ),
  'main-skill-new-gen': (
    <div>
      <h1>Main Skill (new gen)</h1>
      <p>
        La sémantique est la même que pour le main skill. Cependant, dans ce
        cas, le nombre de profils qui passent est prédéfini, en fonction du
        skill sélectionné. Il y a 3 niveaux de relaxation possibles.
      </p>
    </div>
  ),
  'is-ok-with': (
    <div>
      <h1>Is Ok With</h1>
      <p>
        Renvoie True si il est raisonnable de penser que le profil serait
        intéressé par une offre qui fait intervenir le skill cible.
      </p>
    </div>
  ),
  'match-anywhere': (
    <div>
      <h1>Match Anywhere</h1>
      <p>
        Renvoie True si le pattern est catché quelque part dans la headline LNK,
        le summary LNK, ou une expérience LNK
      </p>
    </div>
  ),
  'match-client-pipe': (
    <div>
      <h1>Match Client Pipe</h1>
      <p>
        Renvoie True si le profil a déjà été pushé au client (utilise les
        station pipes).
      </p>
    </div>
  ),
  'min-nb-github-followers': (
    <div>
      <h1>Min Nb Github Followers</h1>
      <p>
        Renvoie True si le nombre de followers du profil est au moins égal au
        nombre de followers cible
      </p>
    </div>
  ),
  'min-nb-github-repos': (
    <div>
      <h1>Min Nb Github Repos</h1>
      <p>
        Renvoie True si le nombre de repositories du profil est au moins égal au
        nombre de repositories cible
      </p>
    </div>
  ),
  'min-skill-score': (
    <div>
      <h1>Min Skill Score</h1>
      <p>
        Renvoie True si le profil a au moins un score de “min score” dans le
        skill cible.
      </p>
      <p>
        Attention, ce filtre devrait être assez peu utilisé, il devrait servir
        de dépannage dans un cas désespéré. En effet, les scores sont calculés
        sans sémantique précise.
      </p>
    </div>
  ),
  'school-category-range': (
    <div>
      <h1>School Category Range</h1>
      <p>
        Renvoie True si le profile a un score de school prestige compris dans le
        range.
      </p>
      <p>
        Le school prestige est calculé à partir des educations. La règle est la
        suivante :
        <ul>
          <li>
            Si le profile a passé 2 ans ou plus dans son education de prestige
            max, c’est ce prestige qui est choisi.
          </li>
          <li>
            Sinon, le prestige renvoyé est 0,8*prestige max + 0,2*second
            prestige.
          </li>
        </ul>
      </p>
      <p>
        On filtre d’abord les educations sur lesquelles on détecte les patterns
        :<br />
        ['online', 'continuing studies', 'auditeur libre', 'free listener',
        'webacademie', 'webacademy', 'campus'].
      </p>
      <p>
        Pour éditer le prestige d’une école, c’est possible de le faire
        directement sur le sweethub (les modifications dans la search seront
        appliquées quelques jours plus tard). Dans monitoring/workplace. =>
        Demandez à Luc si vous avez un doute là dessus
      </p>
    </div>
  ),
  'solicitation-range': (
    <div>
      <h1>Solicitation Range</h1>
      <p>
        Renvoie True si le profil a été contacté par un client moins de “max nb
        solicitations” dans les “nb weeks” dernières semaines.
      </p>
    </div>
  ),
  'target-job-position': (
    <div>
      <h1>Target Job Position</h1>
      <h2>Currently</h2>
      <p>
        On détecte les job position avec des patterns, ou sinon avec des skills
        les impliquant directement (ontologie différente de cereskills).
      </p>
      <p>
        On utilise la headline, le summary, la dernière expérience (headline +
        description), ainsi que les endorsements.
      </p>
      <p>
        Une job position est finalement retenue si :
        <ul>
          <li>elle est présente dans la headline globale </li>
          <li>
            ou bien si summary et headline ne donnent pas d’information et
            qu’elle est catchée dans la dernière xp
          </li>
          <li>
            ou bien si elle présente dans au moins 2 sources parmis [headline,
            summary, last xp, endorsements]
          </li>
        </ul>
      </p>
      <p>
        Remarques supplémentaires : <br />
        <ul>
          <li>
            Après qu’une job position a été retenue, on ajoute finalement les
            job positions qui sont directement impliquées par cette position
            avec une ontologie manuelle : exemple “full-stack” -> “front-end” et
            “back-end”
          </li>
          <li>
            Si une job position de “recruteur”/”medecin”/”military”/"ceo" est
            retenue, on retire toutes les autres
          </li>
        </ul>
      </p>
      <h2>Explicitly</h2>
      <p>
        On détecte les job positions uniquement avec des patterns. On ne parse
        que dans la headline globale et le titre de l'expérience actuelle
      </p>

      <h2>During</h2>
      <p>
        On utilise les job positions calculées pour chaque expérience (utilisées
        aussi dans le mode Currently). Un profile passe le filtre si la somme
        cumulée des durées des expériences taggées avec la job position demandée
        est dans l'interval demandé.
      </p>
    </div>
  ),
  'target-job-position-relaxed': (
    <div>
      <h1>Target Job Position (Relaxed)</h1>
      <p>
        Même méthode que Target Job Position, excepté qu’on utilise toutes les
        expériences et pas seulement la dernière, et que toutes les job
        positions sont conservées (pas de rules pour filtrer à la fin).
      </p>
    </div>
  ),
  'target-location': (
    <div>
      <h1>Target Location</h1>
      <p>
        Dans l’ordre va chercher :
        <ul>
          <li>Dans la dernière xp</li>
          <li>Dans la headline linkedin</li>
          <li>Dans le github</li>
          <li>Dans le stackoverflow</li>
        </ul>
      </p>
      <p>
        Si le premier catch match, renvoie True. Si 0 catch ou si mauvais
        premier catch : renvoie False
      </p>
    </div>
  ),
  'target-location-relaxed': (
    <div>
      <h1>Target Location (Relaxed)</h1>
      <p>
        Dans l’ordre va chercher :
        <ul>
          <li>
            Dans la dernière xp (cette fois ci, va aussi taper dans la workplace
            associé si celle ci contient moins de 100 employés)
          </li>
          <li>Dans la headline linkedin</li>
          <li>Dans le github</li>
          <li>Dans le stackoverflow</li>
          <li>Dans les autres xp</li>
        </ul>
      </p>
      <p>
        Si le premier catch match, renvoie True. Si 0 catch ou si mauvais
        premier catch : renvoie False
      </p>
    </div>
  ),
  'skill-range': (
    <div>
      <h1>Skill range</h1>
      <p>
        Renvoie True si le skill score s6 (construit &agrave; partir des
        datasets ABCDE) du skill demand&eacute; est bien dans l'intervalle
        demand&eacute;.
        <br />
        Si on choisit le mode <strong>letters</strong> :<br />
        Le label du skill (calcul&eacute; &agrave; partir de son score) doit
        &ecirc;tre compris entre le min et le max au sens large (i.e.
        min&lt;=S&lt;=max)
        <br />
        Si on choisit le mode <strong>ranking</strong> :<br />
        Le rang du skill doit &ecirc;tre compris entre le max et le min{' '}
        <strong>au sens large</strong> (i.e. max&lt;=S&lt;=min). A not&eacute;
        que le rang minimal est toujours <strong>sup&eacute;rieur</strong> au
        rang maximal !
      </p>
    </div>
  ),
  'target-modality': (
    <div>
      <h1>Target Modality</h1>
      <p>
        On détecte les tags grâce à un ensemble de patterns :
        <ul>
          <li>'consulting'</li>
          <ul>
            <li>'consultant'</li>
            <li>'consulting'</li>
            <li>'consultante'</li>
          </ul>
          <li>'research'</li>
          <ul>
            <li>'research'</li>
            <li>'phd'</li>
            <li>'r&d'</li>
            <li>'thesis'</li>
            <li>'postgraduate'</li>
            <li>'researcher'</li>
            <li>'recherche'</li>
            <li>'post-doc'</li>
          </ul>
          <li>'remote'</li>
          <ul>
            <li>'remote'</li>
            <li>'working from home'</li>
            <li>'teletravail'</li>
            <li>'travail à distance'</li>
            <li>'working remotely'</li>
            <li>'work remotely'</li>
            <li>'work remotly'</li>
            <li>'working remotly'</li>
          </ul>
          <li>'freelance'</li>
          <ul>
            <li>'self-employed'</li>
            <li>'free-lance'</li>
            <li>'freelancer'</li>
            <li>'freelance'</li>
            <li>'indépendant'</li>
            <li>'indépendent'</li>
            <li>'self-entrepreneur'</li>
            <li>'freelancing'</li>
          </ul>
        </ul>
      </p>
      <p>
        On parse la headline, le summary et toutes les expériences (headline +
        description).
      </p>
    </div>
  ),
  'target-responsability': (
    <div>
      <h1>Target Responsability</h1>
      <p>
        On détecte le niveau de responsabilité grâce à un ensemble de patterns.
      </p>
      <p>
        On parse la headline, le summary, et la dernière expérience (headline +
        description). Si plusieurs indices contradictoires, le niveau de
        responsabilité le plus haut est conservé.
      </p>
    </div>
  ),
  'target-responsability-relaxed': (
    <div>
      <h1>Target Responsability (Relaxed)</h1>
      <p>
        Idem Target Responsibility, mais en utilisant toutes les expériences au
        lieu de la première seulement.
      </p>
    </div>
  ),
  'time-elapsed-since-last-hiring': (
    <div>
      <h1>Time Elapsed Since Last Hiring</h1>
      <p>
        Renvoie True si le nombre de mois écoulés depuis le dernier début
        d’expérience du profil est supérieur à “min nb months”
      </p>
    </div>
  ),
  'has-source': (
    <div>
      <h1>Has Source</h1>
      <p>
        Renvoie True si le profil a déjà été placé dans une sheet avec une
        source du type renseigné.
      </p>
    </div>
  ),
  'is-female': (
    <div>
      <h1>Is Female</h1>
      <p>
        Renvoie True si le prénom du profil est dans une liste prédéfinie de
        prénoms féminins. La version relaxed cherche dans une liste de prénoms
        très relâchée.
      </p>
    </div>
  ),
  'ever-answered-positively': (
    <div>
      <h1>Ever Answered Positively</h1>
      <p>
        Renvoie True si le profil a répondu positivement au moins une fois à un
        client (NB: toutes les données ne sont pas dispo)
      </p>
    </div>
  ),
  'nb-positive-answers-range': (
    <div>
      <h1>Nb Positive Answers Range</h1>
      <p>
        Renvoie True si le profil a répondu positivement entre 'min Nb Answers'
        et 'max Nb Answers' fois à un client au cours des 'Nb weeks' dernières
        semaines. Attention, comme tous les filtres prenant en compte les
        données de pipes clients ou ops, seules sont considérées les données qui
        étaient présentes au dernier refresh de la données (date de last build
        renseingée dans la modal de search).
      </p>
    </div>
  ),
  'nb-any-answers-range': (
    <div>
      <h1>Nb Answers Range</h1>
      <p>
        Renvoie True si le profil a répondu entre 'min Nb Answers' et 'max Nb
        Answers' fois à un client au cours des 'Nb weeks' dernières semaines.
        Attention, comme tous les filtres prenant en compte les données de pipes
        clients ou ops, seules sont considérées les données qui étaient
        présentes au dernier refresh de la données (date de last build
        renseingée dans la modal de search).
      </p>
    </div>
  ),
  'disqualify-rate': (
    <div>
      <h1>Disqualify Rate</h1>
      <p>
        Renvoie True si le profil a au moins été concerné par “min nb actions”
        actions ops, et si parmi ces actions, il a été disqualifié moins de “max
        disqualify rate” pourcent des fois.
      </p>
    </div>
  ),
  'ever-pushed-to-client': (
    <div>
      <h1>Ever Pushed To Client</h1>
      <p>Renvoie True si le profil a déjà été selected dans un workpipe.</p>
    </div>
  ),
  'has-email': (
    <div>
      <h1>Has Email</h1>
      <p>Renvoie True si le profil a été mis dans une sheet avec un email.</p>
    </div>
  ),
  'super-match-sheets': (
    <div>
      <h1>Super Match Sheets</h1>
      <p>
        Renvoie True si le profil a été mis dans au moins "min occurrence"
        listes correspondant à une offre dont les skills d'importance supérieure
        à "min importance" contiennent le skill "skill".
      </p>
    </div>
  ),
  'base-filter': (
    <div>
      <h1>Base Filter</h1>
      <p>
        C'est un soliticitation scorer avec les arguments (maxNbSolicitations:
        4, nbWeeks: 8).
      </p>
    </div>
  ),
  tam: (
    <div>
      <h1>Temporal Answer Model (TAM)</h1>
      <p>
        Renvoie un score concernant la probabilité de réponse positive. L’output
        du TAM est calculée à partir de l’historique des contacts avec le
        candidats ainsi que la typologie de ses réponses
        (positive/négative/médium/abstention). Il prend également très
        légèrement en compte la temporalité des expériences.
      </p>
    </div>
  ),
  pto: (
    <div>
      <h1>Profile Through Offer Model (PTO)</h1>
      <p>
        Ce modèle utilise une vectorisation des skills d’un profil projetés sur
        l’offre courante. Cette vectorisation sert d’input à un classifier qui
        essaye de séparer au mieux les selected/disqualified.
      </p>
    </div>
  ),
  'multi-job-typo': (
    <div>
      <h1>Multi Job Typo Model</h1>
      <p>
        Ce modèle utilise une vectorisation de la job position d’un profil vu au
        travers d’une offre. Cette vectorisation sert d’input à un classifier
        qui essaye de séparer au mieux les selected/disqualified.
      </p>
    </div>
  ),
  'prestige-model': (
    <div>
      <h1>Prestige Model</h1>
      <p>
        Ce modèle utilise une vectorisation du prestige du profile (school,
        workplace, xp years) ainsi que du prestige de l’offre (ou de la
        workplace associée). Cette vectorisation sert d’input à un classifier
        qui sépare les selected des disqualified.
      </p>
    </div>
  ),
  'advanced-skills-match': (
    <div>
      <h1>Advanced Skills Match Model</h1>
      <p>
        Ce modèle utilise les mêmes vectorisations intermédiaires servant à
        calculer le main skill et le is ok with sert d’input à un classifier qui
        sépare les selected des disqualified.
      </p>
    </div>
  ),
  'positive-answer-hints': (
    <div>
      <h1>Positive Answer Hints Model</h1>
      <p>
        Ce modèle utilise un ensemble assez exhaustif de signaux présents sur
        les profils linkedin ou github permettant de prédire une réponse
        positive ou négative. Ces signaux forment une vectorisation qui sert
        d’input à un classifier qui sépare les réponses positives du reste. Les
        signaux:
        <ul>
          <li> Mention de la workplace du profil dans S ou H</li>
          <li> Mention d'un mail dans S ou H </li>
          <li> Mention d'un site web dans S ou H </li>
          <li> Nombre de stars sur GH </li>
          <li> Nombre de followers sur GH </li>
          <li>
            {' '}
            Mention explicite dans S ou H du fait que le profil est en recherche{' '}
          </li>
          <li>
            {' '}
            Mention explicite dans S ou H du fait que le profil n'est pas en
            recherche{' '}
          </li>
          <li> Le profil semble né à l'étranger </li>
          <li> Longueur du summary </li>
          <li> Longueur (en nombre de caractères) de la dernière xp </li>
          <li> Présence d'un emoji dans S ou H </li>
          <li> Nombre d'années d'xp </li>
          <li> A un email issu de GH </li>
          <li>
            {' '}
            A ajouté récemment une nouvelle xp, une xp récente, a modifié son
            summary, a modifié un xp name, une xp description{' '}
          </li>
          <li> Niveau de responsabilité actuel </li>
          <li>
            {' '}
            Longueur de l'xp courante (donc temps depuis lequel le profil est en
            poste){' '}
          </li>
        </ul>
      </p>
    </div>
  ),
  'skill-match': (
    <div>
      <h1>Skill Match Model (Lo(u)cef)</h1>
      <p>
        Ce modèle utilise plusieurs vectorisations très exhaustives des skills
        ainsi qu’une vectorisation des skills de l’offre (qu’on nomme O). On
        construit la meilleure combinaison linéaire des vectorisations de skills
        du profil (qui donne un vecteur X) pour renvoyer finalement un score
        proportionnel au produit scalaire entre O et X.
      </p>
    </div>
  ),
  'funnel-regression-merger': (
    <div>
      <h1>Funnel Regression Merger</h1>
      <p>
        Ce modèle utilise l’ensemble des sous-briques décrites plus haut. Il
        mélange les scores obtenus en fonction de l’offre de manière à bien
        performer sur la métrique “max cash” qui optimise le funnel dans son
        ensemble. Plus précisément, il fait une sorte de moyenne pondérée des
        scores unitaires. Ce sont les poids de cette moyenne qui sont optimisés
        pour maximiser la performance.
      </p>
    </div>
  ),
  'company-type': (
    <div>
      <h1>Company Type</h1>
      <p>
        Ce filtre utilise la labélisation des entreprises de Florent et Paul.
        Chaque entreprise est taggée avec l'union des types qui lui ont été
        affectés. Deux options sont proposées :
        <ul>
          <li>
            Current : renvoie True si l'entreprise de la dernière expérience du
            profile a le tag demmandé
          </li>
          <li>
            Ever : renvoie True si parmis toutes les entreprises des expériences
            du profile, une a le tag demmandé
          </li>
        </ul>
      </p>
    </div>
  ),
  'company-prestige': (
    <div>
      <h1>Company Prestige</h1>
      <p>
        Ce filtre utilise la labélisation des entreprises de Florent et Paul.
        Chaque entreprise est taggée avec la moyenne des prestiges qui lui ont
        été affectés. Deux options sont proposées :
        <ul>
          <li>
            Current : renvoie True si l'entreprise de la dernière expérience du
            profile a un prestige dans l'intervalle demmandé
          </li>
          <li>
            Ever : renvoie True si parmis toutes les entreprises des expériences
            du profile, une a un prestige dans l'intervalle demmandé
          </li>
        </ul>
      </p>
    </div>
  ),
  'profile-last-update': (
    <div>
      <h1>Profile Last Update</h1>
      <p>
        Ce filtre utilise les mises à jour identifiées dans les profils linkedin
        lors du scrap. Donne une petite indication sur le fait que le profil est
        en recherche de poste. Renvoie True si une mise à jour a été détectée
        dans les "max nb months" précédents.
      </p>
    </div>
  ),
  'job-scores-s6': (
    <div>
      <h1>Job Position Range</h1>
      <p>
        Renvoie True si le "job score s6" (score construit &agrave; partir des
        datasets ABCDE) du profil dans le poste renseign&eacute; est dans
        l'intervalle demand&eacute;. Si plusieurs postes sont renseign&eacute;s,
        on effectue un "OU".
      </p>
      <h2>Mode Letters</h2>
      <p>
        Le label ABCDE (calcul&eacute; &agrave; partir de son score) doit
        &ecirc;tre compris entre le min et le max au sens large (i.e.
        min&lt;=S&lt;=max).
      </p>
      <h2>Mode Ranking</h2>
      <p>
        Le rang doit &ecirc;tre compris entre le max et le min&nbsp;
        <strong>au sens large</strong>&nbsp;(i.e. max&lt;=S&lt;=min). A
        not&eacute; que le rang minimal est toujours&nbsp;
        <strong>sup&eacute;rieur</strong>&nbsp;au rang maximal !
      </p>
    </div>
  ),
};

export default class extends React.PureComponent {
  state = {
    selectedItemId: _.shuffle(_.keys(docFromItemId))[0],
  };
  handleSelectItem = (e, { value }) => {
    this.setState({ selectedItemId: value });
  };
  renderItem(selectedItemId) {
    return docFromItemId[selectedItemId];
  }
  render() {
    const { selectedItemId } = this.state;
    return (
      <div style={{ width: '100%', minHeight: '400px' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Dropdown
                onChange={this.handleSelectItem}
                options={items}
                placeholder="Choose an item"
                selection
                value={selectedItemId}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              {this.renderItem(selectedItemId)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
