import { Button, Grid, Icon, Loader, Modal, TextArea } from 'semantic-ui-react';

import GenericWidget from './GenericWidget';
import React from 'react';
import _ from 'underscore';

class WidgetEditionModal extends React.Component {
  state = {
    mode: 'edit',
    previewKey: '',
    initialWidget: {},
    currentJSON: '{}',
    isValidJSON: true,
  };

  handleSelectEditMode = () => {
    this.setState({ mode: 'edit' });
  };

  handleSelectPreviewMode = () => {
    this.setState({
      mode: 'preview',
      previewKey: '' + Math.random(),
    });
  };

  handleChangeJSON = ({ value }) => {
    let isValidJSON = true;
    try {
      JSON.parse(value);
    } catch (e) {
      isValidJSON = false;
    }

    this.setState({
      currentJSON: value,
      isValidJSON,
    });
  };

  handleSubmit = () => {
    const { initialWidget, currentJSON, isValidJSON } = this.state;
    let newWidget = {};
    try {
      if (!isValidJSON) {
        throw Error('Error: invalid JSON');
      }
      newWidget = JSON.parse(currentJSON);
    } catch (e) {
      return alert(e.message);
    }
    this.props.onSave({ widget: newWidget, initialId: initialWidget.id });
  };

  componentDidMount = () => {
    const widget = this.props.widget || {};
    const initialWidget = {
      ...(widget.id && { id: widget.id }),
      ...(widget.title && { title: widget.title }),
      ...(widget.type && { type: widget.type }),
      ...(widget.username && { username: widget.username }),
      ...(widget.creationTimestamp && { creationTimestamp: widget.creationTimestamp }),
      ...(widget.data && { data: widget.data }),
      ...widget,
    };

    this.setState({
      initialWidget,
      currentJSON: JSON.stringify(initialWidget || {}, null, 4),
      isValidJSON: true,
    });
  };

  render() {
    const { onClose, widget, creationMode, widgetIdToWidget } = this.props;
    const { mode, currentJSON, isValidJSON } = this.state;

    return (
      <Modal open={true} onClose={onClose} closeIcon closeOnDimmerClick={false}>
        <Modal.Header>
          {(widget || {}).title} ({(widget || {}).id})
        </Modal.Header>
        <Modal.Content style={{ minHeight: 500 }}>
          <Button.Group>
            <Button color={mode === 'edit' ? 'blue' : 'grey'} onClick={this.handleSelectEditMode}>
              Edit
            </Button>
            <Button color={mode === 'preview' ? 'blue' : 'grey'} onClick={this.handleSelectPreviewMode}>
              Preview
            </Button>
          </Button.Group>
          <br />

          {mode === 'edit' && (
            <div>
              <TextArea
                style={{
                  outline: 'none',
                  border: isValidJSON ? '1px solid lime' : '1px solid red',
                }}
                rows={50}
                cols={140}
                value={currentJSON}
                onChange={(e, { value }) => this.handleChangeJSON({ value })}
              />
            </div>
          )}

          {mode === 'preview' && (
            <div key={this.state.previewKey}>
              {!isValidJSON ? (
                <center>
                  <br />
                  <br />
                  <br />
                  <span style={{ color: 'red' }}>Invalid JSON</span>
                  <br />
                  <br />
                  <br />
                </center>
              ) : (
                // eslint-disable-next-line react/jsx-no-comment-textnodes
                <center>
                  // eslint-disable-next-line no-undef
                  <GenericWidget widget={JSON.parse(currentJSON)} widgetIdToWidget={widgetIdToWidget} />
                </center>
              )}
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleSubmit}>
            Save
          </Button>
          <Button color='red' onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default WidgetEditionModal;
