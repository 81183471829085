import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Icon, Button, Segment, Header } from 'semantic-ui-react';
import { Input, SweetForm, Select, Checkbox } from 'sweetform';
import baseUrl from '../baseUrl.js';
import moment from 'moment';
import { loadLocations } from '../Sales/SalesBoard/EditOmegaCriteria';
import { getSkillIds, getJobPositions } from '../Offers/SearchModal';

const downloadAsCsv = async ({ items, prefix }) => {
  const replacer = (key, value) => (value === null ? '' : value);
  let header = []
  _.each(items, (item) => {
    header = _.uniq(_.union(header, _.keys(item)))
  })
  let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');
  var csvData = new Blob([csv], { type: 'text/csv' });
  var csvUrl = URL.createObjectURL(csvData);
  var now = moment().format('YYMMDDHHmmss');
  var hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.download = `${prefix}_${now}.csv`;
  hiddenElement.click();
};


class GenericJobsExport extends React.Component {
  state = {
    computing: false,
    count: null,
    query: {
      minPublicationDate: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      maxPublicationDate: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
    }
  };

  handleChangeQuery = (value) => {
    this.setState({
      query: value
    });
  };

  handleCount = async () => {
    try {
      this.setState({ count: null, computing: true })
      const response = (await axios.post(`${baseUrl}/sourceJobsExport/count/${this.props.sourceId}`, this.state.query)).data;
      if (!response.success) {
        throw Error(JSON.stringify(response.error));
      }
      this.setState({ count: response.count, computing: false });
    } catch (e) {
      alert('Error getting query results : ' + JSON.stringify(e.message));
      this.setState({ count: null, computing: false });
    }
  }

  handleDownloadCsv = async () => {
    try {
      this.setState({ computing: true })
      const response = (await axios.post(`${baseUrl}/sourceJobsExport/fetch/${this.props.sourceId}`, this.state.query)).data;
      if (!response.success) {
        throw Error(JSON.stringify(response.error));
      }
      await downloadAsCsv({
        items: response.jobs,
        prefix: `${this.props.sourceId}_jobs_export`,
      })
      this.setState({ computing: false });
    } catch (e) {
      alert('Error getting query results : ' + JSON.stringify(e.message));
    }
  }

  render() {
    const { computing, count, query } = this.state;
    return (
      <Grid>
        <Grid.Column width={4}>
          <Header as="h2">{this.props.sourceName}</Header>
          <Segment>
            <SweetForm initialValues={query} onChange={this.handleChangeQuery}>
              <b>Min publication date (included)</b>
              <Input type='date' field='minPublicationDate' />
              <b>Max publication date (included)</b>
              <Input type='date' field='maxPublicationDate' />
              <b>Skills</b>
              <Select field='skillIds' async loadOptions={getSkillIds} labelKey='name' valueKey='id' multi={true} />
              <b>search also in description ?</b>{' '}<Checkbox field='includeDescriptionSkills' /><br/><br/>

              <b>Jobs</b>
              <Select field='jobIds' async loadOptions={getJobPositions} labelKey='name' valueKey='id' multi={true} />
              <b>search also in description ?</b>{' '}<Checkbox field='includeDescriptionJobs' /><br/><br/>
              <b>Locations</b>
              <Select field='locationIds' loadOptions={loadLocations} labelKey='label' valueKey='value' async={true} multi={true} />


              {computing ? (
                <Icon name='circle notched' loading />
              ) : (
                <div>
                  <Button color='green' onClick={() => this.handleCount()}>
                    Count {_.isNumber(count) ? `: ${count} jobs` : ''}
                  </Button>
                  <Button color='orange' onClick={() => this.handleDownloadCsv()}>
                    Download CSV
                  </Button>
                </div>
              )}
            </SweetForm>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default GenericJobsExport;
