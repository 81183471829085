import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import baseUrl from '../baseUrl';
import { getCandidatesOptions } from '../Watch/Candidate';
import Select from 'react-select';


export const sourceProfilesToSandboxSourceProfiles = ({sourceProfiles})=>{
    let result  = {}
    if (!_.isEmpty(sourceProfiles.linkedinProfile)){
        result['linkedin'] = sourceProfiles.linkedinProfile.data
    }
    if (!_.isEmpty(sourceProfiles.hiresweetProfile)){
        result['hiresweet'] = sourceProfiles.hiresweetProfile
    }
    if (!_.isEmpty(sourceProfiles.githubProfile)){
        result['github'] = sourceProfiles.githubProfile
    }
    return result
}

class ImportCandidateModal extends Component {

  state = {
    hiresweetId: null,
    sourceProfiles: null,
  }

  componentDidMount = async () => {
    try {
      const candidateOptions = await getCandidatesOptions();
      console.log("candidateOptions", candidateOptions)
      this.setState({ candidateOptions });
    } catch (e) {
      alert(JSON.stringify(e, null, 4));
    }
  };


  getCandidateById = async ({ id }) => {
    try {
      if (!id) {
        throw Error('No hiresweetId provided.');
      }
      const { data } = await axios.get(`${baseUrl}/candidates/${id}/sourceData`);
      return data;
    } catch (e) {
      console.log('Failed to load workplace ', e.message);
      return {};
    }
  }

  handleSelectCandidate = async (selectedOption) => {
    let { sourceProfiles } = await this.getCandidateById({id: selectedOption.value})
    this.setState({ selectedOption, sourceProfiles:sourceProfilesToSandboxSourceProfiles({sourceProfiles}) });
  };

  handleSave = () => {
    this.props.onSave({ 
        sourceProfiles: this.state.sourceProfiles
    });
  }

  render() {
    const { onClose } = this.props;
    const { sourceProfiles, selectedOption, candidateOptions } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon size="fullscreen">
        <Modal.Header>
          Import Candidate Data
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
            <Select style={{ width: '200px' }} options={candidateOptions} onChange={this.handleSelectCandidate} value={selectedOption} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' disabled={_.isEmpty(sourceProfiles)} onClick={this.handleSave}>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}


export default ImportCandidateModal;
