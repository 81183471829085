import _ from 'underscore';

const getMatchesStatsAndScore = (matches) => {
  let stats = {
    '#skipped': 0,
    '#contacted': 0,
    '#in-process': 0,
    '#hired': 0,
    '#disqualified': 0,
    '#answered': 0,
    '#pending': 0,
    '#pool': 0,
  };
  _.each(stats, (key, val) => {
    stats[key+'#disqualified']=0
  })
  _.each(stats, (key, val) => {
    stats[key+'#recently']=0
  })
  let score = 0;
  _.each(matches, ({ state, disqualified, recentlyInState }) => {
    stats['#' + state] = (stats['#' + state] || 0) + 1;
    if (disqualified){
      stats['#' + state + '#disqualified'] = (stats['#' + state +  '#disqualified'] || 0) + 1;
    }
    if (recentlyInState){
      stats['#' + state + '#recently'] = (stats['#' + state +  '#recently'] || 0) + 1;
    }
    score +=
      { 'in-process': 20, answered: 10, contacted: 5, pending: 1 }[state] ||
      0;
  });
  return { stats, score };
};

export default getMatchesStatsAndScore;
