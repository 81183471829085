import React from 'react';
import { Grid } from 'semantic-ui-react';
import SelectInt from './SelectInt';

var Weighted = function Weighted(_ref) {
  var min = _ref.min,
      max = _ref.max,
      percent = _ref.percent,
      defaultWeightValue = _ref.defaultWeightValue,
      fieldName = _ref.fieldName,
      children = _ref.children;
  var step = percent ? 10 : 1;
  var suffix = percent ? '%' : '';
  return React.createElement(Grid, null, React.createElement(Grid.Column, {
    width: 4
  }, React.createElement(SelectInt, {
    field: fieldName || 'weight',
    defaultValue: defaultWeightValue,
    min: min,
    max: max || 100,
    step: step,
    suffix: suffix,
    style: {
      width: 100
    }
  })), React.createElement(Grid.Column, {
    width: 12
  }, children));
};

export default Weighted;