import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Grid,
  Label,
  Icon,
  Popup,
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import Modal from '../Modal';
import SweetappProfile from '../components/SweetappProfile';

const getBeautifulColor = (color) =>
  color === 'red'
    ? '#FA7676'
    : color === 'green'
    ? '#82CA9D'
    : color === 'yellow'
    ? '#FCC35E'
    : color;

class OfferActionView extends Component {
  state = {
    actionType: 'all',
    timeFilter: '25last',
  };
  componentWillMount() {
    this.handleLoadOffer(this.props.offerId);
  }
  handleLoadOffer = async (offerId) => {
    const offer = (await axios.get(`${baseUrl}/offers/${offerId}`)).data;
    if (offer.platformId) {
      const actionStats = (await axios.post(`${baseUrl}/station/actions`, {
        jobOfferIds: offer.platformId,
      })).data;
      if (actionStats.results) {
        offer.actions = actionStats.results;
      }
    }
    this.setState({
      offer,
    });
  };

  handleSelectActionType = (actionType) => {
    this.setState({
      actionType: actionType,
    });
  };
  handleSelectTimeFilter = (timeFilter) => {
    this.setState({
      timeFilter: timeFilter,
    });
  };
  handleLoadProfile = async (profileId) => {
    this.setState({
      selectedProfileId: profileId,
    });
  };
  handleClose = async () => {
    this.setState({
      selectedProfileId: null,
    });
  };

  renderSelectors() {
    const buttonStyle = {
      border: 'none', //'1px solid lightgrey',
      display: 'inline-block',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '6px',
      paddingRight: '6px',
      cursor: 'pointer',
    };
    const { offer } = this.state;
    const actions = offer.actions;
    if (!actions) {
      return null;
    }
    const actionsFromType = _.groupBy(actions, 'type');
    const nbSends =
      (actionsFromType['send'] || []).length +
      (actionsFromType['move-to-contacted'] || []).length;
    const nbSkips = (actionsFromType['skipProfile'] || []).length;

    const ratings = _.compact(
      _.pluck(actionsFromType['rate-selection'] || [], 'rating'),
    );
    const sumRatings = _.reduce(ratings, (memo, rating) => memo + rating, 0);
    const meanRatings = _.isEmpty(ratings)
      ? undefined
      : Math.round((sumRatings / Math.max(ratings.length, 1)) * 100) / 100;

    return (
      <div style={{ position: 'relative' }}>
        <div>
          {_.map(['send', 'skip', 'rate', 'all'], (type, index) => (
            <div
              key={type}
              style={{
                ...buttonStyle,
                backgroundColor:
                  this.state.actionType === type
                    ? getBeautifulColor('green')
                    : 'whitesmoke',
                color: this.state.actionType === type ? 'white' : 'black',
              }}
              onClick={() => this.handleSelectActionType(type)}
            >
              {{ skip: 'Skip', send: 'Send', rate: 'Rate', all: 'All' }[type] ||
                type}
            </div>
          ))}
          <br />
          {_.map(['50last', '25last', '7days'], (timeFilter, index) => (
            <div
              key={index}
              style={{
                ...buttonStyle,
                backgroundColor:
                  this.state.timeFilter === timeFilter
                    ? getBeautifulColor('green')
                    : 'whitesmoke',
                color: this.state.timeFilter === timeFilter ? 'white' : 'black',
              }}
              onClick={() => this.handleSelectTimeFilter(timeFilter)}
            >
              {{
                '50last': 'Last 50',
                '25last': 'Last 25',
                '7days': 'Last 7 days',
              }[timeFilter] || timeFilter}
            </div>
          ))}
        </div>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <Label
            style={{ background: getBeautifulColor('green'), color: 'white' }}
          >
            {nbSends} send{nbSends !== 1 ? 's' : ''}
          </Label>
          <Label
            style={{ background: getBeautifulColor('red'), color: 'white' }}
          >
            {nbSkips} skip{nbSkips !== 1 ? 's' : ''}
          </Label>
        </div>
        {meanRatings !== undefined && (
          <div style={{ position: 'absolute', top: 20, right: 0 }}>
            <Label style={{ background: 'lightgray', color: 'black' }}>
              <span style={{}}>Avg. {meanRatings}</span>
            </Label>
          </div>
        )}
      </div>
    );
  }
  renderActions() {
    const { offer } = this.state;
    const actions = offer.actions;
    if (!actions) {
      return null;
    }
    const actionsFromType = _.groupBy(actions, 'type');
    // compute the top 3 skip reasons among the 25 last skips
    const last25Skips = _.last(actionsFromType['skipProfile'], 25);
    const lastUnitaryReasons = _.compact(
      _.flatten(
        _.map(last25Skips, ({ reason }) => (reason || '').split(/;|#/)),
      ),
    );
    const skipReasonCounts = _.reduce(
      lastUnitaryReasons,
      (memo, reason) => ({
        ...memo,
        [reason]: (memo[reason] || 0) + 1,
      }),
      {},
    );
    const reasonsWithCount = _.map(skipReasonCounts, (count, reason) => ({
      count,
      reason,
    }));
    const top3Reasons = _.first(
      _.sortBy(reasonsWithCount, 'count').reverse(),
      3,
    );

    const lastActions = _.filter(
      actions,
      ({ type }) =>
        ['send', 'skipProfile', 'rate-selection', 'move-to-contacted'].indexOf(
          type,
        ) >= 0,
    );

    const filteredActions1 = _.filter(
      lastActions,
      ({ type }) =>
        this.state.actionType === 'all' ||
        (this.state.actionType === 'send' &&
          (type === 'send' || type === 'move-to-contacted')) ||
        (this.state.actionType === 'skip' && type === 'skipProfile') ||
        (this.state.actionType === 'rate' && type === 'rate-selection'),
    );

    const filteredActions2 =
      this.state.timeFilter === '7days'
        ? _.filter(
            filteredActions1,
            ({ date }) =>
              Date.now() - new Date(date).getTime() <= 7 * 24 * 3600 * 1000,
          )
        : this.state.timeFilter === '25last'
        ? filteredActions1.slice(0, 25)
        : filteredActions1.slice(0, 50);

    const targetActions = filteredActions2;

    let nextProfileId = undefined;
    let previousProfileId = undefined;
    let currentProfileIndex = undefined;

    _.each(targetActions, (action, index) => {
      if (
        this.state.selectedProfileId &&
        this.state.selectedProfileId === (action.profile || {}).id
      ) {
        currentProfileIndex = index;
      }
    });
    if (currentProfileIndex !== undefined && currentProfileIndex > 0) {
      previousProfileId = (targetActions[currentProfileIndex - 1].profile || {})
        .id;
    }
    if (
      currentProfileIndex !== undefined &&
      currentProfileIndex + 1 < targetActions.length
    ) {
      nextProfileId = (targetActions[currentProfileIndex + 1].profile || {}).id;
    }

    return (
      <div>
        <br />
        {!_.isEmpty(top3Reasons) && (
          <div style={{ paddingLeft: 11 }}>
            <h4 style={{ marginBottom: 3 }}>Top skip reasons</h4>
            {_.map(top3Reasons, ({ reason, count }, index) => (
              <div key={reason}>
                {index + 1}. {reason} ({count})
              </div>
            ))}
            <br />
          </div>
        )}
        <Grid padded>
          {_.map(targetActions, (action, index) => (
            <Grid.Row key={index} style={{ padding: 0 }}>
              <Grid.Column width={9}>
                <span style={{ fontSize: 11, fontFamily: 'monospace' }}>
                  {action.date && new Date(action.date).toDateString() // + ' - ' + new Date(action.date).toLocaleTimeString()
                  }
                </span>
              </Grid.Column>
              <Grid.Column width={3}>
                <div
                  style={{
                    fontWeight: 'bold',
                    color:
                      action.type === 'skipProfile'
                        ? 'red'
                        : action.type === 'rate-selection'
                        ? getBeautifulColor('yellow')
                        : 'green',
                  }}
                >
                  {{
                    skipProfile: 'skip',
                    'rate-selection': 'rate',
                    'move-to-contacted': 'contacted',
                  }[action.type] || action.type}
                  {action.type === 'rate-selection' && (
                    <span style={{ whiteSpace: 'nowrap' }}>
                      : {action.rating}
                    </span>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={1}>
                {action.reason && (
                  <Popup
                    trigger={<Icon name="add" link />}
                    content={action.reason}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={2} textAlign="right">
                {action.profile && (
                  <Icon
                    name="eye"
                    link
                    onClick={() => {
                      this.handleLoadProfile((action.profile || {}).id);
                    }}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        {this.state.selectedProfileId && (
          <Modal
            active={true}
            headerText="Sweetapp Profile"
            onCancel={this.handleClose}
            extraActions={[
              ...(previousProfileId
                ? [
                    {
                      onClick: () => {
                        this.handleLoadProfile(previousProfileId);
                      },
                      text: '‹',
                      color: 'whitesmoke',
                    },
                  ]
                : []),
              ...(nextProfileId
                ? [
                    {
                      onClick: () => {
                        this.handleLoadProfile(nextProfileId);
                      },
                      text: '›',
                      color: 'whitesmoke',
                    },
                  ]
                : []),
            ]}
          >
            {_.map(actions, ({ type, profile, reason }, index) =>
              profile && profile.id === this.state.selectedProfileId ? (
                type === 'skipProfile' ? (
                  <center>
                    <h3 style={{ color: 'red' }}>{reason || 'no reason'}</h3>
                  </center>
                ) : type === 'send' ? (
                  <center>
                    <h3 style={{ color: 'lime' }}>send</h3>
                  </center>
                ) : null
              ) : null,
            )}
            <div style={{ minHeight: 500 }}>
              <SweetappProfile
                key={this.state.selectedProfileId}
                profileId={this.state.selectedProfileId}
                anonymised
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
  render() {
    if (!this.state.offer) {
      return <div />;
    }
    return (
      <div>
        {this.renderSelectors()}
        {this.renderActions()}
      </div>
    );
  }
}

export default OfferActionView;
