import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import { Image, Segment, Header, Button, Modal, Table, Icon } from 'semantic-ui-react';
import { SweetForm, Input, Select, enhance, SelectInt } from 'sweetform';
import moment from 'moment';
import PhotoLinkModal from '../../components/PhotoLinkModal';

//const WATCH_SHEET_ID = 'watch-sheet-1';
const loadUsers = async () => {
  const users = (await axios.get(`${baseUrl}/users`)).data;
  return { options: users, complete: true };
};

const AccountStrategistEditor = enhance(() => (
  <div>
    <Select
      field='status'
      options={[
        {
          value: "active",
          label: (
            <div>
              <Icon color='green' name='check' />
              Active
            </div>
          ),
        },
        {
          value: "archived",
          label: (
            <div>
              <Icon color='red' name='remove' />
              Archived
            </div>
          ),
        }
      ]}
      defaultValue={"active"}
    />
    <Select
      field='userId'
      async={true}
      loadOptions={loadUsers}
      labelKey='name'
      valueKey='id'
    />
    <Input placeholder='firstname' field='firstname' />
    <Input placeholder='lastname' field='lastname' />
    <Input placeholder='email' field='email' />
    <Input placeholder='calendlyUrl' field='calendlyUrl' />
    <Input placeholder='phoneNumber' field='phoneNumber' />
    <Input placeholder='slackId' field='slackId' />
    <Input placeholder='notionId' field='notionId' />
    <SelectInt placeholder='offersAffectationWeightAS' field='offersAffectationWeightAS' min={0} max={5}/>
  </div>
));


const CreateAccountStrategistModal = ({ onClose, onChange, onSubmit }) => {
  return (
    <div>
      <Modal
        onClose={() => onClose()}
        open
        closeIcon
        size='fullscreen'
      >
        <Modal.Header>Create Account Strategist</Modal.Header>
        <Modal.Content style={{ minHeight: 300 }}>
          <SweetForm onChange={onChange}>
            <AccountStrategistEditor field="accountStrategist" />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit()}>
            Create
          </Button>
        </Modal.Actions>
      </Modal >
    </div>

  )
}

const EditAccountStrategistModal = ({ onClose, onChange, onSubmit, onDelete, accountStrategist }) => {
  return (
    <div>
      <Modal
        onClose={() => onClose()}
        open
        closeIcon
        size='fullscreen'
      >
        <Modal.Header>Edit Account Strategist</Modal.Header>
        <Modal.Content style={{ minHeight: 300 }}>
          <SweetForm initialValues={{ accountStrategist }} onChange={onChange}>
            <AccountStrategistEditor field="accountStrategist" />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onDelete()}>
            Delete
          </Button>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal >
    </div>

  )
}


class AccountStrategists extends React.Component {
  state = {
    accountStrategists: null,
    editedAccountStrategist: null,
    editMode: null,
  };

  componentWillMount() {
    this.load();
  }

  load = async () => {
    try {
      console.log("loadig...")
      const hiresweetEmployees = (await axios.get(`${baseUrl}/hiresweetEmployees`)).data;
      const accountStrategists = _.filter(hiresweetEmployees, ({ isAccountStrategist }) => isAccountStrategist);
      console.log(accountStrategists)

      this.setState({
        accountStrategists,
      });
    } catch (e) {
      alert(e.message)
    }
  };

  onOpenAddAccountStrategist = () => {
    this.setState({
      editMode: 'addAccountStrategist',
      editedAccountStrategist: null,
    });
  };

  onOpenEditAccountStrategist = (accountStrategist) => {
    this.setState({
      editMode: 'editAccountStrategist',
      editedAccountStrategist: accountStrategist,
    });
  };


  onCloseEdition = () => {
    this.setState({
      editMode: null,
      editedAccountStrategist: null,
    });
  };

  onSubmitAddAccountStrategist = async () => {
    try {
      const { editedAccountStrategist: accountStrategist } = this.state;
      const { userId } = accountStrategist;
      if (!userId) {
        throw Error("should provide userId")
      }
      const { data } = await axios.post(`${baseUrl}/hiresweetEmployees`, { ...accountStrategist, isAccountStrategist: true });
      if (data.error) {
        throw Error(data.error);
      }
      await this.load();
      await this.onCloseEdition();
    } catch (e) {
      alert(e.message)
    }
  };

  onSubmitEditAccountStrategist = async () => {
    try {
      const { editedAccountStrategist: accountStrategist } = this.state;
      const { userId } = accountStrategist;
      if (!userId) {
        throw Error("should provide userId")
      }
      const { data } = await axios.put(`${baseUrl}/hiresweetEmployees/${userId}`, accountStrategist);
      if (data.error) {
        throw Error(data.error);
      }
      await this.load();
      await this.onCloseEdition();
    } catch (e) {
      alert(e.message)
    }
  };

  onChangeAccountStrategist = async ({ accountStrategist }) => {
    this.setState({ editedAccountStrategist: accountStrategist });
  };

  onDeleteAccountStrategist = async ({ userId }) => {
    try {
      if (!userId) {
        throw Error("should provide userId")
      }
      const { data } = await axios.delete(`${baseUrl}/hiresweetEmployees/${userId}`);
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
      await this.load();
      await this.onCloseEdition();
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
  };

  onAffectWorstOffersToAccountStrategists = async () => {
    this.setState({ affectingOffers: true });
    try {
      const { data } = await axios.get(`${baseUrl}/hiresweetEmployees/affectTargetOffersToAccountStrategists`);
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
    this.setState({ affectingOffers: false });
  }

  onResetAccountStrategistOffers = async () => {
    this.setState({ resetingOffers: true });
    try {
      const { data } = await axios.get(`${baseUrl}/hiresweetEmployees/resetAccountStrategistOffersAffectations`);
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
    this.setState({ resetingOffers: false });
  }

  onOpenEditPhotoLink = (accountStrategist) => {
    this.setState({
      editMode: 'editPhotoLink',
      editedAccountStrategist: accountStrategist,
    });
  };
  onSubmitPhotoLink = async ({ userId, photoLink }) => {
    try {
      const { data } = await axios.put(`${baseUrl}/hiresweetEmployees/${userId}`, { photoLink });
      if (data.error) {
        throw Error(data.error);
      }
      await this.load();
    } catch (e) {
      alert(e.message);
    }
  }
  render() {
    const { editMode, editedAccountStrategist, accountStrategists } = this.state;
    return (
      <div>

        <Button onClick={() => this.onOpenAddAccountStrategist()}>Add Account Strategist</Button>

        {!_.isEmpty(accountStrategists) && (
          <Table>
            <Table.Header>
              <Table.HeaderCell>
              </Table.HeaderCell>
              <Table.HeaderCell>
              </Table.HeaderCell>
              <Table.HeaderCell>
                Status
              </Table.HeaderCell>
              <Table.HeaderCell>
                User
              </Table.HeaderCell>
              <Table.HeaderCell>
                Firstname
              </Table.HeaderCell>
              <Table.HeaderCell>
                Lastname
              </Table.HeaderCell>
              <Table.HeaderCell>
                Email
              </Table.HeaderCell>
              <Table.HeaderCell>
                Calendly
              </Table.HeaderCell>
              <Table.HeaderCell>
                Phone
              </Table.HeaderCell>
              <Table.HeaderCell>
                Slack Id
              </Table.HeaderCell>
              <Table.HeaderCell>
                Notion Id
              </Table.HeaderCell>
              <Table.HeaderCell>
                Offers Affectation Weight
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {(_.map(accountStrategists, (accountStrategist, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Button icon onClick={() => this.onOpenEditAccountStrategist(accountStrategist)}>
                      <Icon name='pencil' />
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Image
                      src={accountStrategist.photoLink || '/images/default-avatar-1.svg'}
                      circular
                      style={{
                        width: '36px',
                        height: '36px',
                        cursor: 'pointer'
                      }}
                      onError={(e) => {
                        e.target.src = '/images/default-avatar-1.svg';
                      }}
                      onClick={() => this.onOpenEditPhotoLink(accountStrategist)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {accountStrategist.status == 'active' ? (
                      <Icon color='green' name='check' />
                    ) : (
                      <Icon color='red' name='remove' />
                    )}
                  </Table.Cell>
                  <Table.Cell>{accountStrategist.userId}</Table.Cell>
                  <Table.Cell>{accountStrategist.firstname}</Table.Cell>
                  <Table.Cell>{accountStrategist.lastname}</Table.Cell>
                  <Table.Cell>{accountStrategist.email}</Table.Cell>
                  <Table.Cell>{accountStrategist.calendlyUrl}</Table.Cell>
                  <Table.Cell>{accountStrategist.phoneNumber}</Table.Cell>
                  <Table.Cell>{accountStrategist.slackId}</Table.Cell>
                  <Table.Cell>{accountStrategist.notionId}</Table.Cell>
                  <Table.Cell>{accountStrategist.offersAffectationWeightAS || 0}</Table.Cell>
                </Table.Row>
              )))}
            </Table.Body>
          </Table>
        )
        }
        <Segment>
          <Header as='h1'>Affect Worst Offers to Account Strategists</Header>
          {this.state.affectingOffers ? (
            <div>Affecting...</div>
          ) : (
            <Button onClick={() => this.onAffectWorstOffersToAccountStrategists()}>
              Affect Worst Offers
            </Button>
          )
          }
          <Header as='h1'>Reset Account Strategists Offers</Header>
          {this.state.resetingOffers ? (
            <div>Reseting...</div>
          ) : (
            <Button onClick={() => this.onResetAccountStrategistOffers()}>
              Reset Offers
            </Button>
          )
          }
        </Segment>


        {
          editMode === 'addAccountStrategist' ? (
            <CreateAccountStrategistModal
              onSubmit={this.onSubmitAddAccountStrategist}
              onChange={this.onChangeAccountStrategist}
              onClose={this.onCloseEdition}
            />
          ) : null
        }
        {
          editMode === 'editAccountStrategist' ? (
            <EditAccountStrategistModal
              onSubmit={this.onSubmitEditAccountStrategist}
              onChange={this.onChangeAccountStrategist}
              onClose={this.onCloseEdition}
              onDelete={() => this.onDeleteAccountStrategist({ userId: editedAccountStrategist.userId })}
              accountStrategist={editedAccountStrategist}
            />
          ) : null
        }
        {
          editMode === 'editPhotoLink' ? (
            <PhotoLinkModal
              onClose={this.onCloseEdition}
              photoLink={(editedAccountStrategist || {}).photoLink}
              setPhotoLink={({ photoLink }) => this.onSubmitPhotoLink({ userId: editedAccountStrategist.userId, photoLink })}
            />
          ) : null
        }

      </div >
    );
  }
}
export default AccountStrategists;
