import './i18n';
import 'react-select/dist/react-select.css';
import 'semantic-ui-css/semantic.min.css';
import './custom.css';
import 'remixicon/fonts/remixicon.css';

import { Button, Container, Dimmer, Form, Grid, Input, Menu, Message } from 'semantic-ui-react';
import { Link, Route, BrowserRouter as Router, Switch, matchPath, withRouter } from 'react-router-dom';
import Offers, { OffersNavBar } from './Offers';
import { Provider, connect } from 'react-redux';
import React, { Component } from 'react';
import { combineReducers, createStore } from 'redux';
import { getDecodedJsonWebToken, getEncodedJsonWebToken, removeJsonWebToken, saveJsonWebToken } from './authJWT';

import AcquisitionCampaigns from './Watch/Acquisition/AcquisitionCampaigns';
import AcquisitionProfiles from './Watch/Acquisition/AcquisitionProfiles';
import Actions from './Actions';
import AddLocations from './Monitoring/Locations/AddLocations';
import AdminUsers from './Admin/Users';
import AllThreads from './Monitoring/AllThreads';
import AnswerStats from './Strat/AnswerStats';
import AreaCoverage from './Monitoring/Locations/AreaCoverage';
import AssetsExplorer from './AssetsExplorer/Explorer';
import AssetsExplorerConfiguration from './AssetsExplorer/Configuration';
import AssetsExplorerViews from './AssetsExplorer/Views';
import AutoSelect from './FlowBoard/AutoSelect';
import AutoShoot from './FlowBoard/AutoShoot';
import { CandidateViewer } from './Watch/Candidate';
import CereJobsV2 from './Monitoring/Cerejobsv2';
import CodinGameMonitoring from './CodinGameMonitoring';
import Config from './Config';
import DashBoardSearch from './DashBoardSearch/DashBoardSearch';
import DataPointSandbox from './DataPointSandbox';
import DatasetsMonitoring from './DatasetsMonitoring';
import DynamicSelectionStats from './Strat/DynamicSelectionStats';
import EducationMonitoring from './Monitoring/Education';
import EnrichedWorkplace from './Monitoring/EnrichedWorkplace/EnrichedWorkplace.js';
import Explorer from './Explorer';
import ExtendSkills from './AssetsExplorer/ExtendSkills';
import ExtensionMonitoring from './ExtensionMonitoring';
import FastSearch from './FastSearch';
import Favorites from './Favorites.js';
import FlowBoard from './FlowBoard/FlowBoard';
import CriteriaBoard from './FlowBoard/CriteriaBoard';
import GradesDistributionsMonitoring from './GradesDistributionsMonitoring';
import ItemTimelineRouter from './SweetChain/ItemTimeline';
import JobCollectionsView from './SweetChain/JobCollectionsView';
import JobParser from './JobParser';
// import Issues from './Matching/Issues';
import Jobs from './Jobs';
import JobsCategories from './AssetsExplorer/JobsCategories';
import KnowledgeIngestion from './KnowledgeIngestion';
import LabelizedData from './LabelizedData';
import Languages from './Languages';
import LocationMappings from './Monitoring/Locations/LocationMappings';
import Locations from './Locations';
import LocationsV2 from './Monitoring/Locations';
import MachineLearningChallenges from './MachineLearningChallenges';
import MailAccounts from './Monitoring/MailAccounts';
import MaxMatching from './Matching/MaxMatching';
import MegaMigration from './MegaMigration';
import MissingPatternsExplorator from './Monitoring/Locations/MissingPatternsExplorator';
import ModelsMonitoring from './ModelsMonitoring';
import Monitoring from './Monitoring';
import Moves from './Moves';
import MultiSearch from './MultiSearch';
import MultiUsersStats from './FlowBoard/MultiUsersStats';
import MyActions from './MySpace/Actions';
import MyOffers from './MySpace/Offers';
import MyStats from './MySpace/Stats';
import OfferCheckerMonitoring from './OfferChecker.js';
import OffersDetector from './Monitoring/OffersDetector';
import Ontologies from './Monitoring/Ontologies';
import Opportunities from './Opportunities';
import OpsDailySends from './FlowBoard/OpsDailySends.js';
import OpsRangeSends from './FlowBoard/OpsRangeSends.js';
import OpsSyntheticFlow from './FlowBoard/OpsSyntheticFlow.js';
import OutliersMonitoring from './OutliersMonitoring';
import PatternGroupIngestion from './PatternGroupIngestion';
import PostponeView from './Strat/PostponeView';
import ProfileCollectionsExternalDataStats from './SweetChain/ProfileCollectionsExternalDataStats';
import ProfileCollectionsView from './SweetChain/ProfileCollectionsView';
import Profiles from './Profiles';
import QRCode from 'qrcode.react';
import Recognition from './AssetsExplorer/Recognition';
import Regressions from './Regressions';
import Reports from './Reports';
import ResumeParser from './ResumeParser';
import RevealRecommendations from './RevealRecommendations';
import ReverseSearch from './ReverseSearch';
import SalesBoard from './Sales/SalesBoard/index.js';
import SalesDailySends from './Sales/SalesDailySends.js';
import SalesDashboard from './Sales/SalesDashboard.js';
import SalesOverview from './Sales/SalesOverview.js';
import SalesRangeSends from './Sales/SalesRangeSends.js';
import SalesSyntheticFlow from './Sales/SalesSyntheticFlow.js';
import SchemaEditionView from './HiresweetComprehend';
import SchoolParsing from './SchoolParsing';
import Screening from './Watch/Screening';
import SearchConfig from './SearchConfig';
import SearchFeedbacks from './Monitoring/SearchFeedbacks';
import SearchesMonitoring from './SearchesMonitoring';
import SelectionStats from './Strat/SelectionStats';
import SendStats from './Strat/SendStats';
import SenderAddresses from './Monitoring/SenderAddresses';
import SentryDashboard from './Monitoring/Sentry/Dashboard.js';
import SentryManualChecks from './Monitoring/Sentry/ManualChecks.js';
import SentryAdministrationBoard from './Monitoring/Sentry/Administration/Board.js';
import ServiceManager from './SweetChain/ServiceManager';
import Services from './Monitoring/Services';
import SetManager from './SweetChain/SetManager';
import Sheetsboard from './FlowBoard/Sheetsboard';
import SkillsAndIcons from './SkillsAndIcons';
import SlackStats from './Stats/Slack';
import ASDashboard from './Stats/ASDashboard';
import StationActions from './Station/actions';
import StationActionsExternal from './Station/actionsExternal';
import StationClients from './Station/clients';
import StationDashboard from './Station/dashboard';
import StationDemo from './Station/demo';
import StationJourneys from './Station/journeys';
import StationMailing from './Station/mailing';
import StationRevealActions from './Station/actionsReveal';
import StationRevealActionsStream from './Station/actionsStreamReveal';
import StationSlack from './Station/slack';
//import StationStats from './Station/stats';
import Stats from './Stats';
import StatsBoards from './Stats/Boards';
// import Labelizing from './Matching/Labelizing';
import StatsDashboards from './Stats/Dashboards';
import StatsWidgets from './Stats/Widgets';
import SuspiciousProfilesThreads from './Monitoring/SuspiciousProfilesThreads';
import SuspiciousThreads from './Monitoring/SuspiciousThreads';
import SweetSheets from './FlowBoard/SweetSheets.js';
import SweetWork from './SweetWork';
import SweetappThreadsMonitoring from './SweetappThreadsMonitoring';
import SweetminerOrdersEditor from './Sweetminer/OrdersEditor';
import Sweetynotes from './Monitoring/Sweetynotes';
import SynchroSpreadsheets from './SynchroSpreadsheets';
import SyntheticFlow from './Strat/SyntheticFlow.js';
import Tags from './Tags';
import TalentReveal from './TalentReveal';
import TalentStrategist from './Watch/TalentStrategists';
import AccountStrategist from './Watch/AccountStrategists';
import TargetDefinitions from './TargetDefinitions';
import Targets from './Targets';
import TechAssetDashboard from './TechAssetDashboard';
import TechAssetSandBox from './TechAssetSandBox';
import Threads from './Monitoring/Threads';
import ThreadsRefreshTasks from './Monitoring/ThreadsRefreshTasks';
import Warnings from './Warnings';
import WatchBoard from './FlowBoard/WatchBoard';
import WatchExportCSV from './Watch/WatchExportCSV';
import WatchProcesses from './Watch/Processes/index';
import WatchRecruitments from './Watch/Recruitments';
import WatchSheet from './Watch/Sheet';
import WatchStats from './Station/WatchStats';
import Workers from './Workers';
import PythonSlavesStats from './PythonSlavesStats';
import Workflow from './SweetChain/Workflow';
import WorkplaceGroups from './Monitoring/WorkplacesManagement/WorkplacesGroups';
import { WorkplaceLinksEditor } from './Monitoring/WorkplaceLinks';
import { WorkplacePatternsEditor } from './Monitoring/WorkplacePatterns';
import Workplaces from './Workplaces';
import WorkplacesManagement from './Monitoring/WorkplacesManagement';
import XG1Sandbox from './XG1Sandbox';
import { InstantScrap } from './InstantScrap';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from './baseUrl';
import collections from './collections';
import { getAllPermissions } from './common';
import { render } from 'react-dom';
import PieChartsViewer from './Watch/BaseAnalysis/PieChartsSegmentation';
import TablesSegmentation from './Watch/BaseAnalysis/TablesSegmentation';
import HistorySegmentation from './Watch/BaseAnalysis/HistorySegmentation';
import ClientsMarketplaceDescriptor from './ClientsMarketplaceDescriptors';
import LinkedinJobsExport from './SourceJobsExport/LinkedinJobsExport';
import WttjJobsExport from './SourceJobsExport/WttjJobsExport';
import IndeedJobsExport from './SourceJobsExport/IndeedJobsExport';
import Shortlists from './SalesOptimisation/Shortlists/index';
import Prospects from './SalesOptimisation/Prospects';

axios.defaults.withCredentials = true;
const encodedJsonWebToken = getEncodedJsonWebToken();
if (encodedJsonWebToken) {
  axios.defaults.headers.common['Authorization'] = encodedJsonWebToken;
}

// Actions
const SET_IDENTITY = 'SET_IDENTITY';
const setIdentity = (identity) => ({ type: SET_IDENTITY, identity });

const SET_FORM = 'SET_FORM';
const setForm = (field, value) => ({ type: SET_FORM, field, value });

// Reducers
const loading = (state = true, action) => {
  switch (action.type) {
    case SET_IDENTITY:
      return false;
    default:
      return state;
  }
};

const identity = (state = {}, action) => {
  switch (action.type) {
    case SET_IDENTITY:
      return action.identity;
    default:
      return state;
  }
};

const form = (state = {}, action) => {
  switch (action.type) {
    case SET_FORM:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  loading,
  identity,
  form,
});

// Persistent store
const store = createStore(rootReducer);

// Components
const Login = ({ loading, form, onChange, onSubmit, error, mfaUrl }) => (
  <Dimmer inverted active page>
    <Container text>
      <Form
        error={!!error}
        warning={!!mfaUrl}
        loading={loading}
        size='huge'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(form);
        }}
      >
        <Form.Field>
          <label>SweetHub - Restricted Access</label>
          <Input autoFocus onChange={(e) => onChange('username', e.target.value)} placeholder='Username' />
          <Input type='password' onChange={(e) => onChange('password', e.target.value)} placeholder='Password' />
          <Input onChange={(e) => onChange('mfaTOTP', e.target.value)} placeholder='One-time password' />
          <Button type='submit'>Go</Button>
        </Form.Field>
        {error ? <Message error header='Unable to authenticate' content={error} size='tiny' /> : null}
        {mfaUrl ? (
          <Message warning>
            <Message.Header>Multi-factor authentication initial setup</Message.Header>
            <Message.Content>
              <QRCode value={mfaUrl} />
            </Message.Content>
          </Message>
        ) : null}
      </Form>
    </Container>
  </Dimmer>
);

const AuthRoute = ({ username, innerProps, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} {...innerProps} username={username} />} />
);
const AuthLink = ({ location, permissions, to, label, exact, index }) => {
  // const regex = new RegExp('^'+location.pathname);
  // const firstElementOfSubMenu = regex.test(to) && index === 0;
  const active = !!matchPath(location.pathname, { path: to, exact }); // || firstElementOfSubMenu;
  return true || !permissions || _.some(permissions, (p) => `/${p}` === to) ? (
    <Link to={to}>
      <Menu.Item link active={active} name={label} />
    </Link>
  ) : null;
};

const CustomMenu = ({ children }) => (
  <Menu inverted secondary color='teal' style={{ borderRadius: 0, margin: 0 }} size='large'>
    {children}
  </Menu>
);

const rootLinks = [
  { label: '⭐️', route: '', component: Favorites },
  {
    label: 'Perso',
    route: 'myspace',
    children: [
      { label: 'Offers', route: 'offers', component: MyOffers },
      { label: 'Stats', route: 'stats', component: MyStats },
      { label: 'Actions', route: 'actions', component: MyActions },
    ],
  },
  {
    label: 'Monitoring',
    route: 'monitoring',
    permission: 'monitoring',
    children: [
      {
        label: 'Knowledge',
        route: 'knowledge',
        permission: 'knowledge',
        children: [
          { label: 'Ontologies', route: 'ontologies', component: Ontologies },
          { label: 'Dashboard', route: '', component: Monitoring },
          { label: 'Locations', route: 'locations', component: Locations },
          { label: 'LocationsV2', route: 'locations_v2', component: LocationsV2, alts: [':locationId'] },
          { label: 'Location mappings', route: 'location_mappings', component: LocationMappings, alts: [':text'] },
          { label: 'Location coverage', route: 'location_coverage', component: MissingPatternsExplorator },
          { label: 'AddLocations', route: 'add_locations', component: AddLocations },
          { label: 'AreaCoverage', route: 'area_coverage', component: AreaCoverage, alts: [':locationId'] },
          {
            label: 'Workplaces',
            route: 'workplaces',
            children: [
              { label: 'Workplaces', route: 'workplaces' },
              { label: 'Schools', route: 'schools', component: SchoolParsing },
              { label: 'Companies', route: 'companies' },
              {
                label: 'Sources',
                route: 'sources',
                component: () => <Explorer collections={_.filter(collections, (c) => c.includes('Companies'))} />,
              },
            ],
          },
          { label: 'Companies', route: 'companies', component: () => <WorkplacesManagement type='company' /> },
          { label: 'Company Groups', route: 'companyGroups', component: () => <WorkplaceGroups type='company' /> },
          {
            label: 'Workplaces Patterns',
            route: 'workplacePatterns',
            component: WorkplacePatternsEditor,
          },
          {
            label: 'Workplaces Links',
            route: 'workplaceLinks',
            component: WorkplaceLinksEditor,
          },
          {
            label: 'Offers',
            route: 'offers',
            children: [
              {
                label: 'Merged',
                route: 'merged',
                component: () => <Explorer collections={['offers']} />,
              },
              {
                label: 'Sources',
                route: 'sources',
                component: () => <Explorer collections={_.filter(collections, (c) => c.includes('Offers'))} />,
              },
            ],
          },
          {
            label: 'Profiles',
            route: 'profiles',
            children: [
              {
                label: 'Merged',
                route: 'merged',
                component: () => <Explorer collections={['mergedProfiles']} />,
              },
              {
                label: 'Sources',
                route: 'sources',
                component: () => <Explorer collections={_.filter(collections, (c) => c.includes('Profiles'))} />,
              },
            ],
          },
          { label: 'Educations', route: 'educations', component: EducationMonitoring, alts: [':tab'] },
          { label: 'SkillIcons', route: 'skills-and-icons', component: SkillsAndIcons },
          { label: 'Tags', route: 'tags', component: Tags, alts: [':id'] },
          { label: 'CerejobsV2', route: 'cerejobsv2', component: CereJobsV2 },
          { label: 'Languages', route: 'languages', component: Languages },
        ],
      },
      { 
        label: 'Sentry',
        route: 'sentry',
        children: [
          { label: 'Dashboard', route: 'dashboard', component: SentryDashboard },
          { label: 'Manual Checks', route: 'manualChecks', component: SentryManualChecks },
          { label: 'Administration', route: 'administration', component: SentryAdministrationBoard },
        ],
      },
      {
        label: 'Scrap',
        route: 'scrap',
        permission: 'scrap',
        children: [
          { label: 'Dashboard', route: '' },
          { label: 'Instant Scrap', route: 'instantscrap', component: InstantScrap },
          { label: 'Config', route: 'config', component: Config },
          { label: 'Workers', route: 'workers', component: Workers },
          { label: 'Collections', route: 'collections', component: Stats },
          { label: 'Python Slaves', route: 'pythonSlaves', component: PythonSlavesStats },
          { label: 'Stats', route: 'stats', component: Reports },
          {
            label: 'Targets',
            route: 'targets',
            children: [
              { label: 'Definitions', route: '', component: TargetDefinitions },
              { label: 'Stats', route: 'stats', component: Targets },
            ],
          },
          { label: 'Jobs', route: 'jobs', component: Jobs },
          { label: 'Warnings', route: 'warnings', component: Warnings },
          {
            label: 'Regressions',
            route: 'regressions',
            component: Regressions,
          },
        ],
      },
      {
        label: 'SweetSearch',
        route: 'sweetsearch',
        permission: 'sweetsearch',
        children: [
          { label: 'Dashboard', route: '', component: SearchConfig },
          {
            label: 'Searches',
            route: 'searches',
            component: SearchesMonitoring,
          },
          {
            label: 'Outliers',
            route: 'outliers',
            permission: 'outliers',
            component: OutliersMonitoring,
            alts: [':id'],
          },
          {
            label: 'Grades Distributions',
            route: 'grades',
            component: GradesDistributionsMonitoring,
          },
          {
            label: 'Search Feedbacks',
            route: 'searchFeedbacks',
            component: SearchFeedbacks,
          },
          { label: 'Performances', route: 'performances' },
          { label: 'Warnings', route: 'warnings' },
        ],
      },
      {
        label: 'Enriched Workplace',
        route: 'enrichedWorkplace',
        component: EnrichedWorkplace,
        alts: [':workplaceId'],
      },
      { label: 'Moves', route: 'moves', component: Moves },
      { label: 'Multi Search', route: 'multiSearch', component: MultiSearch },
      {
        label: 'Source Jobs Export',
        route: 'sourceJobsExport',
        children: [
          { label: 'WTTJ', route: 'wttj', component: WttjJobsExport },
          { label: 'Linkedin', route: 'linkedin', component: LinkedinJobsExport },
          { label: 'Indeed', route: 'indeed', component: IndeedJobsExport },
        ],
      },
      {
        label: 'Prospects',
        route: 'prospects',
        component: Prospects,
        alts: [':dumpId'],
      },
      {
        label: 'Old',
        route: 'old',
        children: [
          { label: 'Services', route: 'services', component: Services },
          {
            label: 'SweetSnake',
            route: 'sweetsnake',
            children: [
              { label: 'Dashboard', route: '' },
              { label: 'Pools', route: 'pools' },
              { label: 'Skeleton builders', route: 'skeleton-builder' },
              { label: 'Filters', route: 'filters' },
              { label: 'Enrichers', route: 'enrichers' },
              { label: 'Models', route: 'models', component: ModelsMonitoring },
              { label: 'warnings', route: 'warnings' },
            ],
          },
          {
            label: 'Reverse Search',
            route: 'reverse-search',
            component: ReverseSearch,
          },
          {
            label: 'DashBoard Search',
            route: 'dashboard-search',
            component: DashBoardSearch,
          },
          {
            label: 'Offer Checker',
            route: 'issues',
            component: OfferCheckerMonitoring,
          },
          {
            label: 'Extension',
            route: 'extension',
            component: ExtensionMonitoring,
          },
          { label: 'Sweetynotes', route: 'sweetynotes', component: Sweetynotes },
          {
            label: 'SweetApp',
            route: 'sweetapp',
            children: [
              { label: 'Dashboard', route: '' },
              { label: 'Threads', route: 'threads', component: Threads },
              {
                label: 'Theads Details',
                route: 'threads-details',
                component: AllThreads,
              },
              {
                label: 'Threads Tasks',
                route: 'threads-tasks',
                component: ThreadsRefreshTasks,
              },
              {
                label: 'Daily Actions',
                route: 'daily',
                component: SweetappThreadsMonitoring,
              },
              {
                label: 'MailAccounts',
                route: 'mailaccount',
                component: MailAccounts,
              },
              {
                label: 'SenderAddresses',
                route: 'senderaddresses',
                component: SenderAddresses,
              },
              {
                label: 'SuspiciousProfilesThreads',
                route: 'suspiciousprofilesthreads',
                component: SuspiciousProfilesThreads,
              },
              {
                label: 'SuspiciousThreads',
                route: 'suspiciousthreads',
                component: SuspiciousThreads,
              },
              { label: 'Warnings', route: 'warnings' },
            ],
          },
          { label: 'Offers Detector', route: 'offersDetector', component: OffersDetector },
          {
            label: 'CodinGame',
            route: 'codingame',
            permission: 'codingame',
            component: CodinGameMonitoring,
            alts: [':id'],
          },
          {
            label: 'Opportunities',
            route: 'opportunities',
            component: Opportunities,
          },
          { label: 'Datasets', route: 'dataset', component: DatasetsMonitoring },
          {
            label: 'Matching',
            route: 'matching',
            component: MaxMatching,
          },
        ],
      },
    ],
  },
  {
    label: 'ML',
    route: 'ml',
    permission: 'sweetsearch',
    children: [
      { label: 'LabelizedData', route: 'labelized-data', component: LabelizedData },
      { label: 'Sandbox', route: 'sandbox', component: DataPointSandbox },
      { label: 'Challenges', route: 'challenges', component: MachineLearningChallenges },
      { label: 'Tech Assets Dashboard', route: 'tech-assets-dashboard', component: TechAssetDashboard },
      { label: 'Resume Parser', route: 'resume-parser', component: ResumeParser },
      { label: 'Job Parser', route: 'job-parser', component: JobParser },
      {
        label: 'SweetChain',
        route: 'sweetchain',
        children: [
          { label: 'Workflow', route: 'workflow', component: Workflow },
          { label: 'ItemTimeline', route: 'item-timeline', component: ItemTimelineRouter },
          { label: 'Service Manager', route: 'service-manager', component: ServiceManager },
          { label: 'Set Manager', route: 'set-manager', component: SetManager },
          { label: 'Profile Collections Admin', route: 'profile-collection-admin', component: ProfileCollectionsView },
          { label: 'Job Collections Admin', route: 'job-collection-admin', component: JobCollectionsView },
          {
            label: 'Profile Collections External Data Stats',
            route: 'profile-collection-external-data-stats',
            component: ProfileCollectionsExternalDataStats,
          },
        ],
      },
      { label: 'Talent Reveal', route: 'talent-reveal', component: TalentReveal },
      { label: 'Recos CRM', route: 'recos-crm', component: RevealRecommendations },
      {
        label: 'Old',
        route: 'old',
        children: [
          { label: 'FastSearch', route: 'fast-search', component: FastSearch },
          { label: 'Knowledge Ingestion', route: 'knowedge-ingestion', component: KnowledgeIngestion },
          { label: 'Patterns Group Ingestion', route: 'pattern-group-ingestion', component: PatternGroupIngestion },
          { label: 'Viewer', route: 'xg1poc', component: XG1Sandbox },
          { label: 'Hiresweet Comprehend', route: 'hiresweet-comprehend', component: SchemaEditionView },
        ],
      },
    ],
  },
  {
    label: 'FlowBoard',
    route: 'flowboard',
    permission: 'flowboard',
    children: [
      {
        label: 'FlowBoard',
        route: 'dashboard',
        permission: 'flowboard-dashboard',
        component: FlowBoard,
        active: true,
      },
      {
        label: 'WatchFlowBoard',
        route: 'dashboard-watch',
        permission: 'flowboard-dashboard',
        component: () => <FlowBoard watchMode={true} />,
        active: true,
      },
      //      { label: 'Monitoring', route: 'monitoring', permission: 'flowboard-dashboard', component: FlowBoardUsers },
      {
        label: 'WatchBoard',
        route: 'watchboard',
        permission: 'flowboard-dashboard',
        component: () => <WatchBoard />,
        active: true,
      },
      {
        label: 'CriteriaBoard',
        route: 'criteriaboard',
        permission: 'flowboard-dashboard',
        component: () => <CriteriaBoard />,
        active: true,
      },
      {
        label: 'Sheetsboard',
        route: 'sheetsboard',
        permission: 'sheetsboard',
        component: Sheetsboard,
      },
      {
        label: 'Sweetsheets',
        route: 'sweetsheets',
        permission: 'sweetsheets',
        component: SweetSheets,
      },
      {
        label: 'AutoShoot',
        route: 'autoshoot',
        permission: 'sheetsboard',
        component: AutoShoot,
      },
      {
        label: 'AutoSelect',
        route: 'autoselect',
        permission: 'autoselect',
        component: AutoSelect,
      },
      {
        label: 'Migration',
        route: 'migration',
        permission: 'flowboard-dashboard',
        component: MegaMigration,
      },
      {
        label: 'SynchroUpload',
        route: 'synchro',
        permission: 'flowboard-dashboard',
        component: SynchroSpreadsheets,
      },
      {
        label: 'Synthetic',
        route: 'synthetic',
        permission: 'flowboard-dashboard',
        component: OpsSyntheticFlow,
      },
      {
        label: 'Sends',
        route: 'sends',
        permission: 'flowboard-dashboard',
        component: OpsRangeSends,
      },
      {
        label: 'DoD',
        route: 'dailysends',
        permission: 'flowboard-dashboard',
        component: OpsDailySends,
      },
      {
        label: 'Stats',
        route: 'multi-users-stats',
        permission: 'flowboard-dashboard',
        component: () => <MultiUsersStats />,
      },
    ],
  },
  {
    label: 'SweetApp',
    route: 'sweetapp',
    permission: 'sweetapp',
    children: [
      {
        label: 'Dashboard',
        route: 'dashboard',
        permission: 'sweetapp-dashboard',
        component: StationDashboard,
      },
      {
        label: 'Mailing',
        route: 'mailing',
        permission: 'sweetapp-dashboard',
        component: StationMailing,
      },
      {
        label: 'Slack',
        route: 'slack',
        permission: 'sweetapp-dashboard',
        component: StationSlack,
      },
      {
        label: 'Shortlists',
        route: 'shortlists',
        permission: 'sweetapp-dashboard',
        component: Shortlists,
        alts: [':clientId'],
      },
      {
        label: 'Client Actions',
        route: 'actions',
        permission: 'sweetapp-actions',
        component: StationActions,
        alts: [':search'],
      },
      {
        label: 'Client Reveal Actions',
        route: 'revealActions',
        permission: 'sweetapp-actions',
        component: StationRevealActions,
        alts: [':search'],
      },
      {
        label: 'Reveal Actions Stream',
        route: 'revealActionsStream',
        permission: 'sweetapp-actions',
        component: StationRevealActionsStream,
      },
      {
        label: 'Client Journeys',
        route: 'journeys',
        permission: 'sweetapp-actions',
        component: StationJourneys,
      },
      {
        label: 'External Actions',
        route: 'labels',
        permission: 'sweetapp-external-actions',
        lock: 'ea',
        component: StationActionsExternal,
      },
      {
        label: 'Clients',
        route: 'clients',
        permission: 'sweetapp-clients',
        component: StationClients,
      },
      {
        label: 'Watch Stats',
        route: 'watch-stats',
        permission: 'sweetapp-actions',
        component: WatchStats,
      },
      /*{
        label: 'Statistics',
        route: 'stats',
        permission: 'sweetapp-stats',
        component: StationStats,
      },*/
      {
        label: 'Demo Accounts',
        route: 'demo',
        permission: 'sweetapp-dashboard',
        component: StationDemo,
      },
      {
        label: 'Marketplace Clients Info',
        route: 'marketplaceClientsInfo',
        permission: 'sweetapp-dashboard',
        component: ClientsMarketplaceDescriptor,
      },
    ],
  },
  {
    label: 'Stats',
    route: 'stats',
    permission: 'flowboard',
    children: [
      { label: 'Dashboards', route: 'dashboards', component: StatsDashboards },
      { label: 'Boards', route: 'boards', component: StatsBoards },
      { label: 'Widgets', route: 'widgets', component: StatsWidgets },
      { label: 'Slack', route: 'slack', component: SlackStats },
      { label: 'Marketplace AS', route: 'marketplaceAccountStrategist', component: ASDashboard },
    ],
  },
  {
    label: 'Profiles',
    route: 'profiles',
    permission: 'profiles',
    component: Profiles,
    alts: [':linkedinId', ':linkedinId/:static'],
  },
  /* DEPRECATED VIEW, USE SalesBoard INSTEAD TO MANAGE WORKPLACES
  {
    label: 'Workplaces',
    route: 'workplaces',
    permission: 'workplaces',
    component: Workplaces,
  },
  */
  { label: 'Offers', route: 'salesboard', permission: 'offers', component: SalesBoard, alts: [':id'] },
  {
    label: 'SweetWork',
    route: 'sweetwork/:offerId/workpipe/:workpipeId/:inputId',
    noMenu: true,
    lock: 'sweetwork',
    component: SweetWork,
  },
  {
    label: 'Actions',
    route: 'actions',
    permission: 'actions',
    component: Actions,
    alts: ['offer/:offerId'],
  },
  {
    label: 'Sweetminer',
    route: 'sweetminer',
    component: SweetminerOrdersEditor,
  },
  {
    // TODO: better permissions
    label: 'Sales',
    route: 'sales',
    permission: 'codingame',
    children: [
      { label: 'Dashboard', route: 'dashboard', component: SalesDashboard },
      { label: 'Overview', route: 'overview', component: SalesOverview },
      {
        label: 'SyntheticFlow',
        route: 'synthetic',
        component: SalesSyntheticFlow,
      },
      { label: 'Sends', route: 'sends', component: SalesRangeSends },
      { label: 'DoD', route: 'dailysends', component: SalesDailySends },
      {
        label: 'Stats',
        route: 'multi-users-stats',
        permission: 'flowboard-dashboard',
        component: () => (
          <MultiUsersStats explicitUsers={['robin', 'mathieu', 'lamia', 'yannis', 'laurene', 'jerome']} />
        ),
      },
    ],
  },
  {
    label: 'Watch',
    route: 'watch',
    permission: 'codingame',
    children: [
      { label: 'Candidate', route: 'candidate', component: CandidateViewer, alts: [':id'] },
      {
        label: 'Acquisition',
        route: 'acquisition',
        children: [
          { label: 'Campaigns', route: 'campaigns', component: AcquisitionCampaigns },
          { label: 'Profiles', route: 'profiles', component: AcquisitionProfiles },
        ],
      },
      { label: 'Sheet', route: 'sheet', component: WatchSheet },
      { label: 'Screening', route: 'screening', component: Screening },
      {
        label: 'Processes',
        route: 'processes',
        component: WatchProcesses,
      },
      { label: 'Export CSV', route: 'exportCsv', component: WatchExportCSV },
      { label: 'Talent Strategists', route: 'talentStrategists', component: TalentStrategist },
      { label: 'Account Strategists', route: 'accountStrategists', component: AccountStrategist },

      {
        label: 'Segmentation',
        route: 'segmentation',
        children: [
          { label: 'Pie Charts', route: 'pieCharts', component: PieChartsViewer },
          { label: 'Tables', route: 'tables', component: TablesSegmentation },
          { label: 'History', route: 'history', component: HistorySegmentation },
        ],
      },
      {
        label: 'Recruitments',
        route: 'recruitments',
        component: WatchRecruitments,
      }
    ],
  },
  {
    label: 'Strat',
    route: 'strat',
    permission: 'flowboard',
    children: [
      // TODO: better permissions
      { label: 'SyntheticFlow', route: 'synthetic', component: SyntheticFlow },
      { label: 'PostponedView', route: 'postponed', component: PostponeView },
      { label: 'Sends', route: 'sends', component: SendStats },
      { label: 'Answers', route: 'answers', component: AnswerStats },
      { label: 'Selection', route: 'selection', component: SelectionStats },
      {
        label: 'DynSelection',
        route: 'dynamic-selection',
        component: DynamicSelectionStats,
      },
    ],
  },
  {
    label: 'Admin',
    route: 'admin',
    permission: 'admin',
    children: [{ label: 'Users', route: 'users', component: AdminUsers }],
  },
  {
    label: 'Assets Explorer',
    route: 'assets-explorer',
    permission: 'sweetsearch',
    children: [
      { label: 'Explorer', route: 'explorer', component: AssetsExplorer },
      { label: 'Views', route: 'views', component: AssetsExplorerViews },
      { label: 'All Functions', route: 'all-functions', component: TechAssetSandBox },
      { label: 'Configuration', route: 'configuration', component: AssetsExplorerConfiguration },
      { label: 'Jobs Categories', route: 'jobs-categories', component: JobsCategories },
      { label: 'Extend Skills', route: 'extend-skills', component: ExtendSkills },
      { label: 'Recognition', route: 'recognition', component: Recognition },
    ],
  },
];

const SweetRouter = withRouter(({ links, depth, base, children, permissions, username, ...p }) => {
  const search = (p.location || {}).search || '';

  const level = depth || 0;
  const MenuComponent = (
    <Menu.Menu>
      {_.map(links, ({ label, route, permission, lock }, i) => {
        const authorized = !(permission && permissions.indexOf(permission) < 0) && (!lock || search.indexOf(lock) >= 0);
        const path = `${base || ''}/${route}`;

        return authorized ? <AuthLink key={i} index={i} {...p} label={label} to={path} exact={!route} /> : null;
      })}
    </Menu.Menu>
  );

  const SubMenuComponent = _.map(
    _.filter(links, ({ children: nodes }) => nodes && nodes.length > 0),
    ({ route, children: nodes, permission, lock }, i) => {
      const authorized = !(permission && permissions.indexOf(permission) < 0) && (!lock || search.indexOf('lock') >= 0);
      const path = `${base || ''}/${route}`;

      return authorized ? (
        <Route
          key={i}
          path={path}
          render={(p) => (
            <SweetRouter
              {...p}
              links={nodes}
              depth={level + 1}
              base={path}
              permissions={permissions}
              username={username}
            />
          )}
        />
      ) : null;
    },
  );

  const ContentComponent = (
    <div style={{ margin: 20 }}>
      {_.map(_.filter(links, ({ component }) => component), ({ route, component: Component, alts }) => [
        <Route
          key={-1}
          path={`${base || ''}/${route}`}
          exact={!route || !!alts}
          render={(p) => <Component {...p} username={username} />}
        />,
        ..._.map(alts, (alt, i) => (
          <Route
            key={i}
            exact
            path={`${base || ''}/${route}/${alt}`}
            render={(p) => <Component {...p} username={username} />}
          />
        )),
      ])}
    </div>
  );

  if (level < 2) {
    return (
      <div>
        <CustomMenu>
          {MenuComponent}
          {children}
        </CustomMenu>
        {SubMenuComponent}
        {ContentComponent}
      </div>
    );
  }

  return (
    <Grid>
      <Grid.Column width={2}>
        <Menu fluid vertical pointing secondary size='large'>
          {MenuComponent}
          {children}
        </Menu>
      </Grid.Column>
      <Grid.Column width={14}>
        {SubMenuComponent}
        {ContentComponent}
      </Grid.Column>
    </Grid>
  );
});

const Auth = ({ onLogout, role, username, permissions }) => (
  <Router>
    <div>
      <Switch>
        <Route
          path='/offers/:id'
          render={(p) => (
            <CustomMenu>
              <OffersNavBar {...p} />
            </CustomMenu>
          )}
        />
        <Route
          render={(p) => (
            <SweetRouter {...p} permissions={permissions} links={rootLinks} username={username}>
              <Menu.Menu position='right'>
                <Menu.Item onClick={onLogout} name={`Logout from ${username}`} />
              </Menu.Menu>
            </SweetRouter>
          )}
        />
      </Switch>

      <div style={{ margin: 10 }}>
        {role === 'ops' ? (
          <AuthRoute username={username} path='/offers' component={Offers} innerProps={{ ops: true }} />
        ) : (
          <AuthRoute username={username} path='/offers' component={Offers} />
        )}
      </div>
    </div>
  </Router>
);

class Index extends Component {
  componentWillMount() {
    this.props.onLoad();
  }

  render() {
    const { identity, loading, form, onChange, onSubmit, onLogout } = this.props;

    const { role, username, permissions, error, mfaUrl } = identity;
    const props = { role, username, onLogout };
    const allPermissions = getAllPermissions(permissions);
    return !username ? (
      <Login loading={loading} form={form} error={error} mfaUrl={mfaUrl} onChange={onChange} onSubmit={onSubmit} />
    ) : (
      <Auth {...props} permissions={allPermissions} username={username} />
    );
  }
}

const mapS = (state) => ({
  loading: state.loading,
  identity: state.identity,
  form: state.form,
});
const mapD = (dispatch) => ({
  onLoad: async () => {
    const identity = getDecodedJsonWebToken();
    dispatch(setIdentity(identity || {}));
  },
  onLogout: () => {
    removeJsonWebToken();
    window.location.reload();
  },
  onChange: (field, value) => dispatch(setForm(field, value)),
  onSubmit: async (form) => {
    const { username, password, mfaTOTP } = form;
    try {
      const res = await axios.post(`${baseUrl}/auth`, { username, password, mfaTOTP });
      if (res.headers.authorization) {
        saveJsonWebToken(res.headers.authorization);
        window.location.reload();
      } else if (res.data.mfaUrl) {
        dispatch(setIdentity({ mfaUrl: res.data.mfaUrl }));
      }
    } catch (e) {
      dispatch(setIdentity({ error: e.message }));
    }
  },
});

const IndexContainer = connect(
  mapS,
  mapD,
)(Index);

render(
  <Provider store={store}>
    <IndexContainer />
  </Provider>,
  document.getElementById('root'),
);
