import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import baseUrl from '../../baseUrl.js';
import sortSheetItems from './sortSheetItems';
import getMatchesStatsAndScore from './getMatchesStatsAndScore';
import cleanJobOfferId from './cleanJobOfferId';
import GlobalView from './GlobalView';
import moment from 'moment';
import { isRecentlyInState } from './sheetItemsStats.js';
import { Button, Icon } from 'semantic-ui-react';

export const WATCH_SHEET_ID = 'watch-sheet-1';

/*
Shape of a state

{
  matches: [
    { 
      sheetItemId, 
      clientId,
      state, 
      profileId, 
      jobOfferId, 
      sentDate,
      creationTimestamp,
      skipped,
      skipReason,
      sheetItem: {
        id
        sweetsheetId
        creationTimestamp
        creationDate
        content
        state
        history
      }
    },
    ...
  ],
  sheetItems : {
    [sheetItemId]: {
      ...sheetItem, (see above)
      matches :  [(see above)]
    },
    ...
  },
  jobOffers: {
    [id]: {
      id,
      matches : [(see above)]
    } 
  },
  clients: {
    [id]: {
      id,
      matches : [(see above)]
    } 
  },
  selection : { // displayed on right side
    type: 'profile' | 'offer',
    descriptor: {
      title: String,
    },
    matches: [(see above)]
  }
}
*/
const goodForUpload = (candidate) => {
  const { status, account } = candidate;
  if ((status || {}).type == 'disabled') {
    return false;
  }
  if ((status || {}).type == 'hold' && !_.isNumber((status || {}).holdUntilTimestamp)) {
    return false;
  }
  if (!((account || {}).contactPreferences || {}).active) {
    return false;
  }
  return true;
};
const goodForDisplay = (candidate) => {
  const { status } = candidate;
  if ((status || {}).type === 'disabled') {
    return false;
  }
  return true;
};

class WatchProcesses extends Component {
  state = { useNewScores: true };
  componentWillMount() {
    this.moreOpportunities = {};
    this.handleLoadUsers();
  }

  handleLoadForUser = async (user) => {
    this.setState({ loading: true });
    this.handleLoad(user);
    console.log('will mount handleLoad');
    this.intervalId = setInterval(this.cronMoreOpportunities, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  cronMoreOpportunities = async () => {
    if (this.cronLocked) {
      return;
    }
    this.cronLocked = true;
    try {
      const { sheetItems } = this.state;
      const statsAndScoreFromId = {};
      _.each(sheetItems, (sheetItem) => {
        statsAndScoreFromId[sheetItem.id] = getMatchesStatsAndScore(sheetItem.matches);
      });
      const sortedSheetItems = sortSheetItems({
        sheetItems,
        sortBy: 'nextContact',
        sortAscending: true,
        statsAndScoreFromId,
      });
      const notLoadedIds = _.difference(_.pluck(sortedSheetItems, 'id'), _.keys(this.moreOpportunities));

      if (!_.isEmpty(notLoadedIds)) {
        const matches = [];

        const toUpdate = {};
        _.each(notLoadedIds, (sheetItemId) => {
          if (!sheetItems[sheetItemId]) {
            return;
          }
          if (matches.length >= 3000) {
            return;
          }
          toUpdate[sheetItemId] = sheetItems[sheetItemId];
          _.each(sheetItems[sheetItemId].matches, (match) => {
            if (match.state === 'pool' && !match.disqualified) {
              matches.push(match);
            }
          });
        });

        await this.enrichMatchesWithScores(matches, true);

        const improvableSheetItemIds = _.uniq(
          _.pluck(
            _.filter(
              matches,
              ({ score, bestOfferActivity }) =>
                ['high', 'medium'].indexOf(bestOfferActivity) >= 0 && (score || 0) > 0.6,
            ),
            'sheetItemId',
          ),
        );

        _.each(toUpdate, (p, sheetItemId) => {
          this.moreOpportunities[sheetItemId] = improvableSheetItemIds.indexOf(sheetItemId) >= 0 ? true : false;
        });
      } else if (sortedSheetItems.length > 0) {
        clearInterval(this.intervalId);
        const allImprovableItemIds = _.filter(
          _.pluck(sortedSheetItems, 'id'),
          (sheetItemId) => this.moreOpportunities[sheetItemId],
        );
        const isImprovable = _.object(_.map(allImprovableItemIds, (x) => [x, true]));

        console.log('improvable', isImprovable);

        this.setState({
          nbOtherMatchOpportunies: allImprovableItemIds.length,
          sheetItems: _.map(this.state.sheetItems, (sheetItem, id) => ({
            ...sheetItem,
            hasMoreOpportunities: !!isImprovable[id],
          })),
        });
      }
    } catch (e) {
      console.error(e.message);
    }
    this.cronLocked = false;
  };

  handleLoadUsers = async () => {
    const usersOptions = _.pluck((await axios.get(`${baseUrl}/hiresweetEmployees`)).data, 'userId');
    this.setState({
      usersOptions: [...usersOptions, ...['nathan', 'leo']],
    });
  };

  handleChangeSelectedUser = (user) => {
    alert('Reload view and select ' + user);
  };

  updateStateForMatches = (matches, candidates) => {
    const sheetItems = {};
    const clients = {};
    const jobOffers = {};

    const matchesFromClientIdAndSheetItemId = {};
    _.each(matches, (match) => {
      const { sheetItemId, clientId, jobOfferId } = match;
      if (!matchesFromClientIdAndSheetItemId[clientId]) {
        matchesFromClientIdAndSheetItemId[clientId] = {};
      }
      if (!matchesFromClientIdAndSheetItemId[clientId][sheetItemId]) {
        matchesFromClientIdAndSheetItemId[clientId][sheetItemId] = [];
      }
      matchesFromClientIdAndSheetItemId[clientId][sheetItemId].push(match);
      // add to watchProfiles
      if (!sheetItems[sheetItemId]) {
        sheetItems[sheetItemId] = {
          id: sheetItemId,
          ...match.sheetItem,
          hasMoreOpportunities: !!this.moreOpportunities[sheetItemId],
          matches: [],
        };
      }
      sheetItems[sheetItemId].matches.push(match);
      // add to clients
      if (!clients[clientId]) {
        clients[clientId] = {
          id: clientId,
          matches: [],
        };
      }
      clients[clientId].matches.push(match);

      // add to job offers
      if (jobOfferId) {
        if (!jobOffers[jobOfferId]) {
          jobOffers[jobOfferId] = {
            id: jobOfferId,
            clientId: clientId,
            matches: [],
          };
        }
        jobOffers[jobOfferId].matches.push(match);
      }
    });

    const selectionMatches = _.compact(
      [].concat.apply([], _.map(
        (this.state.selection || {}).matches,
        ({ clientId, sheetItemId }) => (matchesFromClientIdAndSheetItemId[clientId] || {})[sheetItemId],
      )),
    );

    const computeWarnings = () => {
      const warnings = [];
      const countFromSheetItemIdAndClientId = {};
      _.each(matches, ({ sheetItemId, clientId }) => {
        if (!countFromSheetItemIdAndClientId[sheetItemId]) {
          countFromSheetItemIdAndClientId[sheetItemId] = {};
        }
        countFromSheetItemIdAndClientId[sheetItemId][clientId] =
          1 + (countFromSheetItemIdAndClientId[sheetItemId][clientId] || 0);
      });
      _.each(countFromSheetItemIdAndClientId, (countFromClientId, sheetItemId) => {
        _.each(countFromClientId, (count, clientId) => {
          if (count >= 2) {
            warnings.push({
              title: 'duplicate',
              text: sheetItemId + ' is ' + count + ' times in ' + clientId,
            });
          }
        });
      });
      return warnings;
    };

    const addMatchIsDuplicated = ({ sheetItems }) => {
      _.each(sheetItems, ({ matches, id }) => {
        const clientIdToMatches = _.groupBy(matches, ({ clientId }) => (clientId))
        _.each(clientIdToMatches, (clientMatches, clientId) => {
          if (clientMatches.length > 1) {
            const value = _.map(clientMatches, ({ jobOfferId, state }) => `${jobOfferId} - ${state}`).join(", ")
            _.each(clientMatches, (match) => {
              match.isDuplicated = value;
            })
          }
        })
      })
    };

    // to color the profiles
    const addExtraTags = ({ sheetItems, matches }) => {
      const matchInStates = ({ match, states }) => {
        if (match.disqualified) {
          return false;
        }
        return states.indexOf(match.state) >= 0;
      };
      const hasMatchInStates = ({ matches, states }) => {
        return _.some(matches, (match) => matchInStates({ match, states }));
      };
      const extraTagsMap = {
        unavailable: ({ matches }) =>
          !hasMatchInStates({
            matches,
            states: ['pool', 'pending', 'contacted', 'answered', 'in-process'],
          }),
        available: ({ matches }) =>
          hasMatchInStates({
            matches,
            states: ['pool', 'pending'],
          }) &&
          !hasMatchInStates({
            matches,
            states: ['contacted', 'answered', 'in-process'],
          }),
        toContact: ({ matches, admin }) =>
          _.isEmpty((admin || {}).lastContactDay) &&
          hasMatchInStates({
            matches,
            states: ['contacted', 'answered', 'in-process'],
          }),
        topCandidate: ({ admin }) => (admin || {}).isTopCandidate,
        holded: ({ status }) => (status || {}).type === 'hold',
        toUnupload: ({ matches, account, isInternal }) =>
          !isInternal && !((account || {}).contactPreferences || {}).active,
        noEmail: ({ account, isInternal, data }) => {
          if (isInternal) {
            return _.isEmpty((data || {}).email);
          } else {
            const selectedEmail = _.find((account || {}).emails, { selected: true });
            if (!selectedEmail || (selectedEmail && !selectedEmail.confirmed)) {
              return true;
            }
            return false;
          }
        },
        noPhoneNumber: ({ account }) => {
          return !((account || {}).phoneNumber);
        },
        enabledNoReviewSummaryEmail: ({ notifications, status }) => {
          const isEnabled = (status || {}).type == 'enabled';
          const wasCreatedAfterNotifications = moment(status.lastEnabledTimestamp) > moment("2021-01-07", "YYYY-MM-DD");
          const hadNoReviewSummaryNotification = _.isEmpty((notifications || {})['reviewSummary'])
          if (isEnabled && wasCreatedAfterNotifications && hadNoReviewSummaryNotification) {
            return true;
          }
          return false;
        },
        noHiresweetDescription: ({ hiresweetDescriptions }) => {

          const hiresweetDescription = _.first(hiresweetDescriptions || [])
          if (_.isEmpty((((hiresweetDescription || {}).description || {}).content || {}).text)) {
            return true;
          }
          return false;
        },
        deleted: ({ isInternal, deleted }) => {
          if (isInternal) {
            return false;
          }
          return deleted;
        },
      };
      _.each(sheetItems, (sheetItem) => {
        sheetItem.extraTags = [];
        _.each(extraTagsMap, (isExtraTag, extraTag) => {
          if (isExtraTag(sheetItem)) {
            sheetItem.extraTags.push(extraTag);
          }
        });
      });
      _.each(matches, (match) => {
        match.sheetItem.extraTags = sheetItems[match.sheetItemId].extraTags;
      });
    };

    const addSkipCount = (jobOffers) => {
      _.each(jobOffers, (jobOffer) => {
        jobOffer.skipCount = _.filter(jobOffer.matches, ({ creationTimestamp, skipped }) => {
          const limitDaysToCountSkip = 30;
          const recentlyUploaded =
            creationTimestamp && moment().diff(moment(creationTimestamp), 'days') < limitDaysToCountSkip;
          return recentlyUploaded && skipped;
        }).length;
      });
    };

    const warnings = []//computeWarnings();
    addMatchIsDuplicated({ sheetItems });
    addExtraTags({ sheetItems, matches });
    addSkipCount(jobOffers);
    this.setState(
      {
        warnings,
        sheetItems,
        clients,
        jobOffers,
        matches,
        ...(this.state.selection && {
          selection: {
            ...this.state.selection,
            matches: selectionMatches,
          },
        }),
      },
      () => {
        console.log(this.state);
      },
    );
  };

  handleLoad = async (user) => {
    const dataWatchMatches = (await axios.get(`${baseUrl}/hiresweetwatch/watchMatches`)).data;

    const params = {
      users: user,
      status: 'hold;enabled',
      withAccount: 1,
      withSweetappProfile: 1,
      withSweetappClient: 1,
      withoutAdminClientUploads: 1,
      withSweetappState: 1,
      withEmail: 1,
      withHiresweetDescriptions: 1,
      withNotifications: 1,
      //withData: 1,
    };
    const reqOptions = { params };
    const dataCandidates = (await axios.get(`${baseUrl}/candidates`, reqOptions)).data;
    console.log('dataCandidates', dataCandidates);

    const dataActiveJobOffers = (await axios.post(`${baseUrl}/station/offers`, {
      adminStates: "active",
      withBasicActionStats: true,
      maxNbResults: 1000,
    })).data;
    if (dataActiveJobOffers.incomplete) {
      throw Error('Incomplete active job offers data : increase maxNbResults !');
    }
    const activeJobOffers = dataActiveJobOffers.results;
    const activeClientIds = _.compact(_.pluck(activeJobOffers, 'clientId'));
    this.setState({ idToSweetappOffer: _.indexBy(activeJobOffers, 'id') });

    // TODO : loadActive offers with basic action stats => enrich with that in enrichMatchesWithScores
    // deduce clients

    const ADMIN_CLIENT_IDS = ['codingame-contact', 'intro'];

    const createMatchesFromRawData = ({ dataCandidates, activeClientIds }) => {
      const matches = [];
      const clientProfiles = {};
      const { candidates } = dataCandidates || {};
      const addMatch = (sheetItem, clientId, state, sweetappProfile) => {
        const sheetItemId = sheetItem.id;
        if (!clientProfiles[clientId]) {
          clientProfiles[clientId] = {};
        }
        if (clientProfiles[clientId][sheetItemId]) {
          if (state !== 'pool') {
            console.log('Warning : duplicated match', sheetItemId, clientId);
          } else {
            return;
          }
        }
        const { id, jobOfferId, sentDate, creationTimestamp, skipped, skipReason } = sweetappProfile || {};
        const recentlyInState = isRecentlyInState(sweetappProfile, state, 4);
        const oneMonthInState = isRecentlyInState(sweetappProfile, state, 30);
        clientProfiles[clientId][sheetItemId] = true;
        matches.push({
          sheetItemId,
          clientId,
          state,
          sheetItem,
          profileId: id,
          jobOfferId,
          sentDate,
          creationTimestamp,
          skipped,
          skipReason,
          recentlyInState,
          oneMonthInState,
        });
      };

      // ajout des matches sweetapp
      _.each(candidates, (candidate) => {
        _.map(candidate.sweetapp || {}, (offerState) => {
          if (offerState.sweetappProfile) {
            const { clientId, sweetappProfile } = offerState;
            const { state } = sweetappProfile;
            addMatch(candidate, clientId, state, sweetappProfile);
          }
        });
      });

      // ajout des matches "pool"
      const allClientIds = _.union(
        _.keys(clientProfiles),
        _.filter(
          activeClientIds,
          (id) => ADMIN_CLIENT_IDS.indexOf(id) < 0,
        ),
      );

      _.each(dataCandidates.candidates, (candidate) => {
        if (goodForUpload(candidate)) {
          _.each(allClientIds, (clientId) => {
            addMatch(candidate, clientId, 'pool', null);
          });
        } else if (goodForDisplay(candidate)) {
          addMatch(candidate, 'default', 'pool', null);
        }
      });
      return matches;
    };

    const enrichMatches = ({ matches, dataWatchMatches }) => {
      // update matches with data stored in watchMatches
      const clientIdSheetItemIdToMatch = _.mapObject(
        _.groupBy(dataWatchMatches.matches, (match) => match.clientId),
        (matches) => _.groupBy(matches, (match) => match.sheetItemId),
      );

      const enrichedMatches = _.map(matches, (match) => {
        const { clientId, sheetItemId } = match;
        const existingMatches = (clientIdSheetItemIdToMatch[clientId] || {})[sheetItemId];
        if (!_.isEmpty(existingMatches)) {
          const existingMatch = existingMatches[0];
          const enrichedMatch = {
            ...match,
            ...existingMatch,
          };
          return enrichedMatch;
        }
        return match;
      });

      return enrichedMatches;
    };

    const matches = createMatchesFromRawData({
      dataCandidates,
      activeClientIds,
    });
    console.log('matches', matches);

    const enrichedMatches = enrichMatches({
      matches,
      dataWatchMatches,
    });
    console.log('enrichedMatches', enrichedMatches);
    this.updateStateForMatches(enrichedMatches, dataCandidates.candidates);
    this.setState({ loaded: true, selectedUser: user, loading: false });
  };
  handleRecomputeReverseSearch = async ({ sheetItemId }) => {
    try {
      const httpClient = axios.create();
      httpClient.defaults.timeout = 60000;
      let data;
      this.setState({ recomputingScores: true })
      if (this.state.useNewScores) {
        data = (await httpClient.get(`${baseUrl}/reverseSearch/refreshMatchesScores/${sheetItemId}`)).data;
      } else {
        const content = {
          params: {
            idFields: {
              hiresweet: sheetItemId,
            },
          },
        };
        data = (await httpClient.post(`${baseUrl}/reverseSearch/searchOffers`, content)).data;
      }

      this.setState({ recomputingScores: false })
      if (!data || data.error) {
        throw Error(data.error ? data.error : `Failed to recompute reverse search for item ${sheetItemId}.`);
      } else {
        console.log(data)
        alert('Scores recomputed !');
      }
    } catch (e) {
      console.log(e);
      alert(e.message || `Failed to recompute scores.`);
    }
  };
  getSweetappOffers = async ({ ids, withBasicActionStats }) => {
    const chunk = (items, maxChunkSize) => {
      const chunks = [[]];
      let chunckId = 0;
      _.each(items, (item) => {
        if (chunks[chunckId].length >= maxChunkSize) {
          chunks.push([]);
          chunckId += 1;
        }
        _.last(chunks).push(item);
      });
      return chunks;
    };
    try {
      const idsChunks = chunk(ids, 100);
      const sweetappOffers = [];
      for (let iChunck = 0; iChunck < idsChunks.length; iChunck++) {
        const idsChunk = idsChunks[iChunck];
        if (_.isEmpty(idsChunk)) {
          continue;
        }
        const data = (await axios.post(baseUrl + '/station/offers', {
          ids: idsChunk.join(';'),
          withBasicActionStats,
        })).data;
        if (data.error) {
          throw Error(data.error);
        }
        if (!data.results) {
          throw Error('no results');
        }
        _.each(data.results, (result) => {
          sweetappOffers.push(result);
        });
      }
      return sweetappOffers;
    } catch (e) {
      return alert('error requesting sweetapp offers :' + JSON.stringify(e.message));
    }
  };

  enrichMatchesWithScores = async (matches, withInfoForUpload) => {
    try {
      // get scores
      const httpClient = axios.create();
      httpClient.defaults.timeout = 20000;
      const formattedMatches = _.map(matches, ({ sheetItemId, clientId }) => ({
        watchProfileId: sheetItemId,
        clientId,
      }));
      console.log("matching score...")
      let data;

      if (this.state.useNewScores) {
        console.log('/reverseSearch/matchingScoresBQ...')
        data = (await httpClient.post(baseUrl + '/reverseSearch/matchingScoresBQ', {
          matches: formattedMatches,
        })).data;
      } else {
        console.log('/reverseSearch/matchingScores...')

        data = (await httpClient.post(baseUrl + '/reverseSearch/matchingScores', {
          matches: formattedMatches,
        })).data;
      }

      if (data.error) {
        throw Error(data.error);
      }
      const scoreFromMatch = {};
      const scoredMatches = (data || {}).matches;
      _.each(scoredMatches, ({ watchProfileId, clientId, score, reason }) => {
        if (watchProfileId && clientId) {
          scoreFromMatch[watchProfileId + '_' + clientId] = {
            score,
            reason,
          };
        }
      });

      // retrieve info for upload
      let bestOfferActivityFromMatch = {};
      let skipCountFromMatch = {};
      let bestOfferSweetappSalaryFromMatch = {};
      if (withInfoForUpload) {
        const jobOfferIdFromMatch = {};
        _.each(scoredMatches, ({ reason, watchProfileId, score, clientId }) => {
          if ((score || 0) > 0.4) {
            const jobOfferId = ((reason || {}).bestOffer || {}).jobOfferId;
            if (jobOfferId) {
              const matchId = watchProfileId + '_' + clientId;
              jobOfferIdFromMatch[matchId] = jobOfferId;
              skipCountFromMatch[matchId] = ((this.state.jobOffers || {})[jobOfferId] || {}).skipCount;
            }
          }
        });
        console.log("sweetappOffers...")

        /*const sweetappOffers = await this.getSweetappOffers({
          ids: _.values(jobOfferIdFromMatch),
          withBasicActionStats: true,
        });
        console.log({ sweetappOffers })*/
        const sweetappOfferFromJobOfferId = this.state.idToSweetappOffer;
        bestOfferActivityFromMatch = _.mapObject(jobOfferIdFromMatch, (jobOfferId) =>
          this.getSweetappOfferActivity(sweetappOfferFromJobOfferId[jobOfferId]),
        );
        bestOfferSweetappSalaryFromMatch = _.mapObject(jobOfferIdFromMatch, (jobOfferId) => {
          const salary = ((sweetappOfferFromJobOfferId[jobOfferId] || {}).criteria || {}).salary
          if (!_.isEmpty(salary) && (salary.min || salary.max)) {
            return salary
          }
          return null
        }
        );
      }
      console.log("enrich matches...")

      // enrich matches
      for (let iMatch = 0; iMatch < matches.length; iMatch++) {
        const match = matches[iMatch];
        if (match.sheetItemId && match.clientId) {
          const key = match.sheetItemId + '_' + match.clientId;
          if (scoreFromMatch[key]) {
            match.score = scoreFromMatch[key].score;
            match.reason = scoreFromMatch[key].reason;
          }
          if (withInfoForUpload) {
            match.bestOfferActivity = bestOfferActivityFromMatch[key];
            match.skipCount = skipCountFromMatch[key];
            if (((match || {}).reason || {}).bestOffer && bestOfferSweetappSalaryFromMatch[key]) {
              match.reason.bestOffer.salary = bestOfferSweetappSalaryFromMatch[key]
            }

          }
        }
      }
      console.log("done.")

    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  };
  getSweetappOfferActivity = (sweetappOffer) => {
    if (sweetappOffer) {
      const { adminState, lastSendDate, lastSkipDate, creationDate } = sweetappOffer;
      if (adminState === 'active') {
        const lastSendDaysFromNow = lastSendDate && moment().diff(moment(lastSendDate, 'YY-MM-DD'), 'days');
        const lastSkipDaysFromNow = lastSkipDate && moment().diff(moment(lastSkipDate, 'YY-MM-DD'), 'days');
        const creationDateDaysFromNow = creationDate && moment().diff(moment(creationDate), 'days');
        if (lastSendDaysFromNow < 15 || creationDateDaysFromNow < 15 || lastSkipDaysFromNow < 15) {
          return 'high';
        }
        if (lastSendDaysFromNow < 30 || lastSkipDaysFromNow < 30) {
          return 'medium';
        }
      }
      return 'low';
    }
  };
  handleLoadProfilesSelection = async ({ clientId, state }) => {
    const client = _.findWhere(this.state.clients, { id: clientId });
    if (!client) {
      return alert('client ' + clientId + ' not found');
    }
    const matches = _.where(client.matches, { state });
    await this.enrichMatchesWithScores(matches, true);
    this.setState({
      selection: {
        type: 'profile',
        descriptor: {
          title: clientId + ' [' + state + ']',
        },
        matches,
      },
    });
  };
  handleLoadClientsSelection = async ({ sheetItemId, state }) => {
    const sheetItem = _.findWhere(this.state.sheetItems, {
      id: sheetItemId,
    });
    if (!sheetItem) {
      return alert('sheetItem ' + sheetItemId + ' not found');
    }

    let matches = _.where(sheetItem.matches, { state });
    const withInfoForUpload = state === 'pool';
    await this.enrichMatchesWithScores(matches, withInfoForUpload);
    const fullname = sheetItem.firstname && sheetItem.lastname && `${sheetItem.firstname} ${sheetItem.lastname}`;
    console.log('handleLoadClientsSelection matches', matches);
    this.setState({
      selection: {
        type: 'client',
        descriptor: {
          title: (fullname || sheetItemId) + ' [' + state + ']',
        },
        matches,
      },
    });
  };

  handleClearSelection = () => {
    this.setState({ selection: null });
  };
  handleUpdateMatch = async ({ match, update }) => {
    const { sheetItemId, clientId } = match;
    const data = (await axios.post(baseUrl + '/hiresweetwatch/watchMatches', {
      sheetItemId,
      clientId,
      notifyReverseSearch: match.state === 'pool',
      ...update,
    })).data;
    if (data.error) {
      return alert(data.error);
    }
    const newMatches = _.map(this.state.matches, (otherMatch) =>
      otherMatch.clientId !== match.clientId || otherMatch.sheetItemId !== match.sheetItemId
        ? otherMatch
        : {
          ...otherMatch,
          ...update,
        },
    );
    this.updateStateForMatches(newMatches);
  };

  handleUpdateProfile = async ({ sheetItemId }) => {
    try {
      const reqOptions = {
        params: {
          withAccount: 1,
          withSweetappProfile: 1,
          withSweetappClient: 1,
          withoutAdminClientUploads: 1,
          withSweetappState: 1,
          withHiresweetDescriptions: 1,
          withNotifications: 1,
        },
      };
      const response = (await axios.get(`${baseUrl}/candidates/${sheetItemId}`, reqOptions)).data;
      console.log('handleUpdateProfile', response);
      if (response.error) {
        throw Error(`Error reloading candidate ${sheetItemId} : ${response.error}`);
      }
      const { candidate } = response;
      const sheetItem = candidate;
      // update sheet item
      let newMatches = _.map(this.state.matches, (otherMatch) =>
        otherMatch.sheetItemId !== sheetItemId
          ? otherMatch
          : {
            ...otherMatch,
            sheetItem,
          },
      );
      // update matches
      const sweetappState = sheetItem.sweetapp;
      const statusState = sheetItem.status.type;
      const clientIdToMatchUpdate = {};
      _.each(sweetappState, (upload) => {
        const { clientId, sweetappProfile, status, jobOfferId, profileId } = upload;
        if (status === 'uploaded' && sweetappProfile) {
          const { state, sentDate, creationTimestamp, skipped } = sweetappProfile;
          clientIdToMatchUpdate[clientId] = {
            state,
            jobOfferId,
            profileId,
            sentDate,
            creationTimestamp,
            skipped,
          };
        } else {
          clientIdToMatchUpdate[clientId] = {
            state: 'pool',
            sentDate: null,
            jobOfferId: null,
            profileId: null,
            creationTimestamp: null,
            skipped: null,
          };
        }
      });
      // remove pending
      newMatches = _.map(newMatches, (otherMatch) => {
        if (otherMatch.sheetItemId === sheetItemId) {
          if (clientIdToMatchUpdate[otherMatch.clientId]) {
            return {
              ...otherMatch,
              ...clientIdToMatchUpdate[otherMatch.clientId],
            };
          }
        }
        return otherMatch;
      });
      // remove pool if passed hold / disabled
      newMatches = _.compact(
        _.map(newMatches, (otherMatch) => {
          if (otherMatch.sheetItemId === sheetItemId) {
            if (statusState === 'hold' || statusState === 'disabled') {
              if (otherMatch.state === 'pool') {
                return null;
              }
            }
          }
          return otherMatch;
        }),
      );
      if (statusState === 'hold' || statusState === 'disabled') {
        newMatches.push(
          {
            sheetItemId: sheetItem.id,
            clientId: 'default',
            state: 'pool',
            sheetItem,
          },
        );
      }

      console.log('newMatches', newMatches);
      this.updateStateForMatches(newMatches);
      return { success: true };
    } catch (e) {
      alert(e);
    }
  };

  // mutations
  handleChangeMatchState = async ({ match, newState }) => {
    const { jobOfferId, profileId } = match || {};
    if (!jobOfferId || !profileId) {
      return alert('jobOfferId and profileId should be defined for match. Probably not uploaded yet.');
    }

    const data = (await axios.put(baseUrl + '/station/offers/' + jobOfferId + '/profiles/' + profileId + '/state', {
      state: newState,
    })).data;
    if (data.error) {
      return alert(data.error);
    }

    const newMatches = _.map(this.state.matches, (otherMatch) =>
      otherMatch.clientId !== match.clientId || otherMatch.sheetItemId !== match.sheetItemId
        ? otherMatch
        : {
          ...otherMatch,
          state: newState,
        },
    );
    this.updateStateForMatches(newMatches);
  };

  handleUploadProfile = async ({ match, jobOfferId, sweethubOfferId, matchingLevel, noEnrichments }) => {
    try {
      const { sheetItemId } = match;
      if (!sheetItemId) {
        throw Error('No watch sheet item found in match.');
      }
      if (!jobOfferId) {
        throw Error('Should provide jobOfferId.');
      }
      const url = `${baseUrl}/candidates/${sheetItemId}/sweetapp`;
      console.log('sweethubOfferId', sweethubOfferId);
      const action = {
        type: 'upload',
        target: 'sweetapp',
        jobOfferId,
        matchingLevel,
        sweethubOfferId,
        noEnrichments,
        prependingMode: true
      };
      const { data } = await axios.post(url, { action });
      if ('error' in data) {
        throw Error(data.error);
      }
      const newCandidate = data.candidate || {};
      const upload = (newCandidate.sweetapp || {})[cleanJobOfferId(jobOfferId)];
      const { profileId } = upload;
      const newState = 'pending';
      const newMatches = _.map(this.state.matches, (otherMatch) =>
        otherMatch.clientId !== match.clientId || otherMatch.sheetItemId !== match.sheetItemId
          ? otherMatch
          : {
            ...otherMatch,
            ...{
              state: newState,
              jobOfferId,
              profileId,
            },
          },
      );
      this.updateStateForMatches(newMatches);
      return { succes: true };
    } catch (e) {
      alert(e);
      return { success: false, error: e.message };
    }
  };

  handleUnuploadFromPendings = async ({ sheetItemId }) => {
    try {
      if (!sheetItemId) {
        throw Error('No sweetapp jobOfferId found in match.');
      }
      const url = `${baseUrl}/candidates/${sheetItemId}/unuploadFromPendings`;
      const { data } = await axios.get(url);
      if (data.error) {
        throw Error(data.error);
      }

      const newMatches = _.map(this.state.matches, (otherMatch) =>
        otherMatch.state !== 'pending' || otherMatch.sheetItemId !== sheetItemId
          ? otherMatch
          : {
            ...otherMatch,
            profileId: null,
            jobOfferId: null,
            sentDate: null,
            creationTimestamp: null,
            skipped: null,
            state: 'pool',
          },
      );
      this.updateStateForMatches(newMatches);
      return { succes: true };
    } catch (e) {
      alert(e);
      return { success: false, error: e.message };
    }
  };

  handleDeleteMatch = async ({ match }) => {
    try {
      const { sheetItemId, jobOfferId } = match;
      if (!jobOfferId) {
        throw Error('No sweetapp jobOfferId found in match.');
      }
      const url = `${baseUrl}/candidates/${sheetItemId}/sweetapp`;
      const action = {
        type: 'unupload',
        target: 'sweetapp',
        jobOfferId: jobOfferId,
      };
      const { data } = await axios.post(url, { action });
      if (data.error) {
        throw Error(data.error);
      }

      const newMatches = _.map(this.state.matches, (otherMatch) =>
        otherMatch.clientId !== match.clientId || otherMatch.sheetItemId !== match.sheetItemId
          ? otherMatch
          : {
            ...otherMatch,
            profileId: null,
            jobOfferId: null,
            sentDate: null,
            creationTimestamp: null,
            skipped: null,
            state: 'pool',
          },
      );
      this.updateStateForMatches(newMatches);
      return { succes: true };
    } catch (e) {
      alert(e);
      return { success: false, error: e.message };
    }
  };

  render() {
    if (this.state.loaded) {
      return (
        <GlobalView
          nbOtherMatchOpportunies={this.state.nbOtherMatchOpportunies}
          warnings={this.state.warnings}
          clients={this.state.clients}
          handleLoadClientsSelection={this.handleLoadClientsSelection}
          sheetItems={this.state.sheetItems}
          handleLoadProfilesSelection={this.handleLoadProfilesSelection}
          jobOffers={this.state.jobOffers}
          selection={this.state.selection}
          handleRecomputeReverseSearch={this.handleRecomputeReverseSearch}
          handleClearSelection={this.handleClearSelection}
          handleChangeMatchState={this.handleChangeMatchState}
          handleUpdateMatch={this.handleUpdateMatch}
          handleDeleteMatch={this.handleDeleteMatch}
          handleUploadProfile={this.handleUploadProfile}
          handleUpdateProfile={this.handleUpdateProfile}
          handleChangeSelectedUser={this.handleChangeSelectedUser}
          selectedUser={this.state.selectedUser}
          usersOptions={this.state.usersOptions}
          recomputingScores={this.state.recomputingScores}
        />
      );
    } else {
      if (this.state.loading) {
        return <Icon size='large' name='circle notched' loading />;
      } else {
        return (
          <div>
            <Button.Group size='large' vertical>
              {_.map(this.state.usersOptions, (user) => (
                <Button onClick={() => this.handleLoadForUser(user)}>{user}</Button>
              ))}
            </Button.Group>
          </div>
        );
      }
    }
  }
}

export default WatchProcesses;
