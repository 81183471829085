import _ from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Table, Menu, Icon, Button, Grid } from 'semantic-ui-react';

import Modal from '../Modal';
import baseUrl from '../baseUrl.js';
import { actions } from './reducers';
import { ClientProfileComponent, computeGlobalHints } from './WorkPipeInput';
import { ExtraFields } from './WorkPipeInputComponents/Upload';

const filterColor = (state) =>
  state === 1 ? 'green' : state === 2 ? 'red' : undefined;

const filterItems = (items = [], filters = {}) =>
  _.filter(items, (item) => {
    const { uploaded } = filters;
    const states = ['selected', 'backlog', 'disqualified'];

    if (
      (uploaded === 1 && !item.uploaded) ||
      (uploaded === 2 && item.uploaded)
    ) {
      return false;
    }

    const itemState = (_.last(item.annotations) || {}).state;

    for (let i in states) {
      const state = states[i];
      if (
        (filters[state] === 1 && itemState !== state) ||
        (filters[state] === 2 && itemState === state)
      ) {
        return false;
      }
    }

    return true;
  });

const ItemModal = ({ item, more, onChangeState, onCancel, onSubmit }) => {
  const state = (_.last(item.annotations) || {}).state;

  const { relevantActivities, ...rawParams } = item.extraFields || {};
  const parameters = _.map(rawParams, (value, key) => ({ key, value }));
  const initialValues = { parameters, relevantActivities };

  const getButtonProps = (type) => ({
    active: state === type,
    onClick: () => onChangeState(item, type),
  });

  return (
    <Modal
      active={true}
      size="fullscreen"
      headerText="Profile details"
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitText="Update"
    >
      <Grid columns={2}>
        <Grid.Column>
          <ClientProfileComponent profile={item} />
        </Grid.Column>
        <Grid.Column>
          <Button.Group fluid style={{ marginBottom: 40 }}>
            <Button {...getButtonProps('selected')}>Select</Button>
            <Button {...getButtonProps('backlog')}>Backlog</Button>
            <Button {...getButtonProps('disqualified')}>Disqualify</Button>
          </Button.Group>
          <ExtraFields initialValues={initialValues} />
        </Grid.Column>
      </Grid>
    </Modal>
  );
};

class WorkPipeItems extends Component {
  componentWillMount() {
    const { match, offer, onLoad, onLoadOffer } = this.props;

    const { id, wid } = match.params;
    onLoad(wid);

    if (!offer) {
      onLoadOffer(id);
    }
  }

  render() {
    const {
      workPipe,
      stateFilter,
      more,
      profile,
      globalHint,
      onMore,
      onToggleStateFilter,
      onUploadProfile,
      onCancel,
      onLoadItem,
      onChangeState,
      onSubmit,
    } = this.props;

    if (!workPipe) {
      return <p>Loading...</p>;
    }

    const getFilterProps = (type) => ({
      color: filterColor(stateFilter[type]),
      onClick: () => onToggleStateFilter(type),
    });

    return (
      <div>
        <Menu secondary>
          {more.states ? (
            <Menu.Item>
              <Button {...getFilterProps('selected')}>Selected</Button>
            </Menu.Item>
          ) : null}
          {more.states ? (
            <Menu.Item>
              <Button {...getFilterProps('backlog')}>Backlog</Button>
            </Menu.Item>
          ) : null}
          {more.states ? (
            <Menu.Item>
              <Button {...getFilterProps('disqualified')}>Disqualified</Button>
            </Menu.Item>
          ) : null}
          <Menu.Menu position="right">
            {more.states ? (
              <Menu.Item>
                <Button {...getFilterProps('uploaded')}>Uploaded</Button>
              </Menu.Item>
            ) : null}
            <Menu.Item onClick={() => onMore('states')}>
              <Icon name="content" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(filterItems(workPipe.items, stateFilter), (item, i) => {
              const { comment, state, date } = _.last(item.annotations) || {};
              return (
                <Table.Row
                  positive={state === 'selected'}
                  negative={state === 'disqualified'}
                  key={i}
                >
                  <Table.Cell>
                    <Icon
                      link
                      name="eye"
                      onClick={() => onLoadItem(workPipe.id, item._id)}
                    />{' '}
                    {item.idFields ? item.idFields.linkedin : null}
                  </Table.Cell>
                  <Table.Cell title={moment(date).format('L LTS')}>
                    {moment(date).fromNow()}
                  </Table.Cell>
                  <Table.Cell>
                    {state} {comment ? `(${comment})` : ''}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {item.annotations ? (
                      _.last(item.annotations).uploaded ? (
                        'Uploaded'
                      ) : (
                        <Button
                          size="mini"
                          color="yellow"
                          onClick={() => onUploadProfile({ item, workPipe })}
                        >
                          Upload
                        </Button>
                      )
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {profile ? (
          <ItemModal
            globalHint={globalHint}
            item={profile}
            more={more}
            onCancel={onCancel}
            onChangeState={onChangeState}
            onSubmit={() => onSubmit(workPipe, profile)}
          />
        ) : null}
      </div>
    );
  }
}

const mapSItems = (state) => ({
  offer: state.selectedOffer,
  workPipe: state.selectedWorkPipe,
  stateFilter: state.stateFilter,
  more: state.more,
  profile: state.currentProfile,
  globalHint: state.globalHint,
});

const mapDItems = (dispatch) => ({
  onLoad: async (id) => {
    dispatch(actions.setSelectedWorkPipe(null));
    const [workPipe, items] = await Promise.all([
      axios.get(`${baseUrl}/sweetwork/workPipes/${id}`),
      axios.get(`${baseUrl}/sweetwork/workPipes/${id}/items`),
    ]);
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe.data,
        items: items ? items.data.reverse() : [],
      }),
    );
  },
  onLoadOffer: async (offerId) => {
    const offer = (await axios.get(`${baseUrl}/offers/${offerId}`)).data;
    dispatch(actions.setSelectedOffer(offer));
  },
  onToggleStateFilter: (state) => dispatch(actions.toggleStateFilter(state)),
  onMore: (id) => dispatch(actions.toggleMore(id)),
  onUploadProfile: async ({ item, workPipe }) => {
    try {
      const response = (await axios.put(
        `${baseUrl}/sweetwork/workPipes/${workPipe.id}/items/upload`,
        { item },
      )).data;
      if (response.error) {
        alert(response.error);
      }
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
    // TODO refresh view and state
    dispatch(actions.setSelectedWorkPipe(null));
    const [newWorkPipe, items] = await Promise.all([
      axios.get(`${baseUrl}/sweetwork/workPipes/${workPipe.id}`),
      axios.get(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/items`),
    ]);
    dispatch(
      actions.setSelectedWorkPipe({
        ...newWorkPipe.data,
        items: items ? items.data : [],
      }),
    );
  },
  onLoadItem: async (workpipeId, itemId) => {
    const item = (await axios.get(
      `${baseUrl}/sweetwork/workPipes/${workpipeId}/items/${itemId}`,
    )).data;
    item.globalHints = computeGlobalHints(item);
    dispatch(actions.setProfiles(item, []));
  },
  onCancel: () => dispatch(actions.setProfiles(null, [])),
  onChangeState: (item, state) => {
    _.last(item.annotations).state = state;
    dispatch(actions.setProfiles({ ...item }, []));
  },
  onSubmit: async (workPipe, item) => {
    const update = _.pick(item, '_id', 'annotations', 'extraFields');
    await axios.put(
      `${baseUrl}/sweetwork/workPipes/${workPipe.id}/item`,
      update,
    );
    dispatch(actions.setProfiles(null, []));

    const newItem = _.pick(item, '_id', 'idFields', 'annotations');
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        items: _.map(workPipe.items, (old) =>
          old._id === newItem._id ? newItem : old,
        ),
      }),
    );
  },
});

export default connect(
  mapSItems,
  mapDItems,
)(WorkPipeItems);
