import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import { Segment, Icon, List, Card, Grid, Button, Form, Modal, Header, Table } from 'semantic-ui-react';
import CustomModal from '../Modal';
import axios from 'axios';
import ProfileView from '../SweetComponents/Profile/View';
import { SweetForm, Select } from 'sweetform';
import { Textarea } from '../common';
import OfferEditor from '../SweetComponents/Offer/Editor';
import EditOfferModal from '../Sales/SalesBoard/EditOfferModal.js';
import OfferV5 from '../components/OfferV5';

const generateId = () => {
  var letters = 'azertyuiopqsdfghjklmwxcvbn';
  var id = '';
  for (var i = 0; i < 15; i++) {
    id += letters[Math.floor(Math.random() * 20)];
  }
  return id;
};

class CreateJobCollectionModal extends Component {
  handleChange(params) {
    this.setState({
      ...params,
    });
  }
  render() {
    const { onCancel, onSubmit } = this.props;
    return (
      <Modal open closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Title</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='title'
                label='title'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Id</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='id'
                label='id'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '200px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='description'
                label='description'
                placeholder=''
              />
            </Form.Field>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export class JobCollectionView extends Component {
  onClickOnJob = (job) => {
    this.loadJob(job.job_path);
  };

  loadJob = async (job_path) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const fetchUrl = baseUrl + '/sweetchain/getJobV5';
    const result = (await axios.post(fetchUrl, { jobPath: job_path }, customHeaders)).data;
    this.setState({ selectedJob: result });
  };

  render() {
    const { jobs } = this.props;
    const { selectedJob } = this.state || {};
    return (
      <Grid columns={2}>
        <Grid.Column width={2}>
          <Table
            style={{
              width: '100%',
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='left'>Jobs</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(jobs, (job, index) => (
                <Table.Row style={{ cursor: 'pointer' }} onClick={() => this.onClickOnJob(job)} key={index}>
                  <Table.Cell textAlign='left'>
                    {job.id ? job.id : job.identifier}
                    {(job.innerData || {}).score ? (
                      <div>
                        <br />
                        {((job.innerData || {}).score * 100).toFixed(2)}
                      </div>
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column width={12}>{selectedJob ? <OfferV5 offer={selectedJob} /> : null}</Grid.Column>
      </Grid>
    );
  }
}

class Jobs extends Component {
  componentDidMount() {
    const { projectId } = this.props;
    this.loadJobCollections(projectId);
  }

  loadJobs = async (jobCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobs/' + jobCollectionId;
    const jobs = (await axios.get(url, customHeaders)).data;
    this.setState({ jobs: jobs.results });
  };

  loadJobCollections = async (projectId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobCollections/' + projectId;
    const jobCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ jobCollections });
  };

  handleClickOnCollection = (e, { value }) => {
    this.setState({ selectedJobCollectionId: value });
    this.loadJobs(value);
  };

  submitJobCollection = async (params) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { projectId, userId } = this.props;
    const payload = {
      ...params,
      projectId,
      userId,
    };
    const createUrl = baseUrl + '/sweetchain/createJobCollection';
    const result = (await axios.post(createUrl, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.loadJobCollections(projectId);
    this.setState({ selectedJobCollectionId: null });
  };

  handleClickDeleteJobCollectionOpenModal = async () => {
    this.setState({ onDeleteModalVisible: true });
  };

  handleClickDeleteJobCollection = async (jobCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { projectId } = this.props;
    const deleteUrl = baseUrl + '/sweetchain/deleteJobsCollectionAndWorkflow';
    const result = (await axios.post(deleteUrl, { jobCollectionId }, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    const deleteLinkUrl = baseUrl + '/sweetchain/removeJobCollectionToProject';
    const payload = {
      projectId,
      jobCollectionId,
    };
    await axios.post(deleteLinkUrl, payload, customHeaders);
    this.loadJobCollections(projectId);
    this.setState({ onDeleteModalVisible: false });
  };

  cancelCreateJobCollectionModal = () => {
    this.setState({ selectedJobCollectionId: null });
  };

  onSubmit = async (params) => {
    if (_.isEmpty(((((params || {}).criteria || {}).jobPosition || {}).major || {}).id)) {
      alert('field major Job Position is required !');
      return { success: false };
    }

    const backgroundToken = (((params || {}).criteria || {}).importanceWeights || {}).background;
    const skillsTokens = (((params || {}).criteria || {}).importanceWeights || {}).skills;
    const jobToken = (((params || {}).criteria || {}).importanceWeights || {}).jobPosition;
    if (
      backgroundToken === undefined ||
      skillsTokens === undefined ||
      jobToken === undefined ||
      backgroundToken + skillsTokens + jobToken != 5
    ) {
      alert('Background, skills and job tokens should be given and sum to 5 !');
      return { success: false };
    }

    params.id = Math.random()
      .toString(36)
      .substr(2, 9);

    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { selectedJobCollectionId } = this.state || {};
    if (!selectedJobCollectionId || selectedJobCollectionId === undefined) {
      this.setState({ uploadTalentModalVisible: false });
      return;
    }
    const fetchSetUrl = baseUrl + '/sweetchain/getSetsFromJobCollection/' + selectedJobCollectionId;
    const results = (await axios.get(fetchSetUrl, customHeaders)).data;
    const jobSet = _.find(results, (set) => set.metaType === 'job') || {};
    const data = [params];
    const payload = {
      ...((jobSet.definition || {}).params || {}),
      data,
    };
    const url = baseUrl + '/sweetchain/uploadData';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ uploadTalentModalVisible: false });
    this.loadJobs(selectedJobCollectionId);
    return { success: true };
  };

  render() {
    const { jobs, jobCollections, createJobModalVisible, selectedJobCollectionId, onDeleteModalVisible } =
      this.state || {};
    const jobCollectionsOptions = _.map(jobCollections, (jobCollection) => ({
      value: jobCollection.id,
      text: jobCollection.name ? jobCollection.name : jobCollection.title,
      key: jobCollection.id,
    })).concat({
      value: 'new-job-collection',
      key: 'new-job-collection',
      text: '+ New Job Collection',
    });

    const newJobCollectionModalVisible = selectedJobCollectionId === 'new-job-collection';

    const { projectId } = this.props;
    return (
      <div>
        {newJobCollectionModalVisible ? (
          <CreateJobCollectionModal
            projectId={projectId}
            onCancel={this.cancelCreateJobCollectionModal}
            onSubmit={this.submitJobCollection}
          />
        ) : (
          <div>
            {onDeleteModalVisible ? (
              <CustomModal
                active={true}
                headerText={`Delete Job Collection ${selectedJobCollectionId}`}
                submitText='Confirm'
                onCancel={this.cancelCreateJobCollectionModal}
                onSubmit={() => this.handleClickDeleteJobCollection(selectedJobCollectionId)}
              >
                <p>Are you sure? This operation cannot be reverted.</p>
              </CustomModal>
            ) : null}
            <Grid rows={3}>
              <Grid.Row>
                <Form.Select
                  search
                  placeholder='Select Job Collections'
                  options={jobCollectionsOptions}
                  onChange={this.handleClickOnCollection}
                  value={selectedJobCollectionId}
                />
              </Grid.Row>
              {selectedJobCollectionId ? (
                <div>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Button onClick={() => this.handleClickDeleteJobCollectionOpenModal()} color='red'>
                        Delete Collection
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </div>
              ) : null}
            </Grid>
            {selectedJobCollectionId ? (
              <JobCollectionView
                jobs={jobs}
                jobCollectionId={selectedJobCollectionId}
                isJobEditable={true}
                key={selectedJobCollectionId}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Jobs;
