import { Icon, Grid, List, Button, Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import axios from 'axios';
import ServiceView from './ServiceView';
import CustomModal from '../Modal';

import { SweetForm, Select, SelectInt } from 'sweetform';
import { Textarea } from '../common';

const listToOptionizable = (itemList) => {
  return _.map(itemList, (item) => ({
    label: item.id,
    value: item.id,
  }));
};

class AddServiceModal extends Component {
  componentDidMount() {
    this.handleLoadSets();
    this.handleLoadTransformerFactory();
  }

  async handleChange(params) {
    this.setState({
      ...params,
    });
  }

  async getSets() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getSets';
    const data = (await axios.get(url, customHeaders)).data;
    return data || [];
  }

  getTransformers = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getAvailableTransformers';
    const data = (await axios.get(url, customHeaders)).data;
    return data.data || [];
  };

  handleLoadTransformerFactory = async () => {
    const transformers = await this.getTransformers();

    this.setState({ transformers });
  };

  handleLoadSets = async () => {
    const sets = await this.getSets();
    this.setState({ sets });
  };

  render() {
    const { onSubmit, onCancel } = this.props;
    const { transformers, sets, transformerId, contextVersion } = this.state || {};
    const selectedTransformer = _.find(transformers, (transformer) => transformer.id === transformerId) || {};
    const inputSetOptions = listToOptionizable(
      _.filter(
        _.filter(
          sets,
          (set) => set.metaType == selectedTransformer.inputObjectType || !selectedTransformer.inputObjectType,
        ),
        (set) => (set.params || {}).contextVersion == contextVersion || set.metaType != 'searchable-profiles',
      ),
    );
    const outputSetOptions = listToOptionizable(
      _.filter(
        _.filter(
          sets,
          (set) => set.metaType == selectedTransformer.outputObjectType || !selectedTransformer.outputObjectType,
        ),
        (set) => (set.params || {}).contextVersion == contextVersion || set.metaType != 'searchable-profiles',
      ),
    );

    const TransformerIdOptions = _.map(transformers || [], (transformer, index) => ({
      label: transformer.id,
      value: transformer.id,
    }));
    return (
      <Modal open closeIcon open={true} headerText={`Create Service`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Service Id</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'Set-cell',
                }}
                field='id'
                label='id'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Transformer Id</label>
              <Select
                fluid
                field='transformerId'
                label='Transformer Id'
                options={TransformerIdOptions}
                placeholder='profile-source-and-history-fetcher'
              />
            </Form.Field>
            {transformerId == 'profiles-sqlizer-poc' ? (
              <Form.Field>
                <label>Context Version</label>
                <SelectInt min={7} max={20} field='contextVersion' label='Context Version' />
              </Form.Field>
            ) : transformerId == 'filter' ? (
              <Form.Field>
                <label>Filter</label>
                <Textarea
                  style={{
                    width: '100%',
                    resize: 'none',
                    height: '35px',
                    overflow: 'hidden',
                    display: 'Set-cell',
                  }}
                  field='filterParams'
                  label='Filter Params'
                  placeholder='Write filter query - ex: {"status" : "pending"}'
                />
              </Form.Field>
            ) : null}
            <Form.Field>
              <label>Input Set Id</label>
              <Select
                fluid
                field='inputSetId'
                label='Input Set Id'
                options={inputSetOptions}
                placeholder='mini-profiles-set'
              />
            </Form.Field>
            <Form.Field>
              <label>Output Set Id</label>
              <Select
                fluid
                field='outputSetId'
                label='Output Set Id'
                options={outputSetOptions}
                placeholder='mini-profiles-set'
              />
            </Form.Field>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const cleanId = (rawId) => rawId.replaceAll(' ', '-').toLowerCase();

class ServiceManager extends Component {
  componentDidMount() {
    const { projectId } = this.props;
    this.handleLoadServiceState(projectId);
  }

  handleLoadServiceState = async (projectId) => {
    const serviceIds = await this.getServiceIds(projectId);
    this.setState({ serviceIds, openServiceInfo: null });
  };

  getServiceIds = async (projectId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getServiceIds/' + projectId;
    const data = (await axios.get(url, customHeaders)).data;
    return (data || {}).data || [];
  };

  async getServiceInformation(serviceId) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getServiceInformation/' + serviceId;
    const data = (await axios.get(url, customHeaders)).data.data;
    const { input, output } = data;
    var newState = {};
    if (input.smartCollectionId) {
      newState['input'] = await this.fetchSmartCollectionTableDefinition(input.smartCollectionId);
    } else {
      newState['input'] = input;
    }
    if (output.smartCollectionId) {
      newState['output'] = await this.fetchSmartCollectionTableDefinition(output.smartCollectionId);
    } else {
      newState['output'] = output;
    }
    const updatedData = {
      ...data,
      ...newState,
    };
    return updatedData || {};
  }

  async fetchSmartCollectionTableDefinition(smartCollectionId) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/fetchSmartCollectionTableDefinition/' + smartCollectionId;
    const data = (await axios.get(url, customHeaders)).data;
    return { ...data, type: 'bigquery' };
  }

  handleClickOnCreateService = () => {
    this.setState({ addServiceModalVisible: true });
  };

  handleClickOnService = async (service) => {
    const serviceInformation = await this.getServiceInformation(service.id);
    this.setState({ openServiceInfo: serviceInformation });
  };

  onCancel = () => {
    this.setState({ addServiceModalVisible: false, onDeleteModalVisible: false });
  };

  handleClickOnDelete = (serviceId) => {
    this.setState({ onDeleteModalVisible: true, serviceId: serviceId });
  };

  onDeleteConfirm = async (serviceId) => {
    const payload = {
      serviceId: serviceId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteService';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ onDeleteModalVisible: false });
    this.handleLoadServiceState();
  };

  onSubmit = async (params) => {
    const serviceParams =
      params.transformerId == 'profiles-sqlizer-poc'
        ? { workerRequirements: [{ contextVersion: params.contextVersion, type: 'sweetsnake-context' }] }
        : params.transformerId == 'filter'
        ? JSON.parse(params.filterParams != undefined ? params.filterParams : '{}')
        : {};
    const payload = {
      id: cleanId(params.id),
      transformerId: params.transformerId,
      inputSetId: params.inputSetId,
      outputSetId: params.outputSetId,
      params: serviceParams,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createService';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ addServiceModalVisible: false });
    const { projectId } = this.props;
    this.handleLoadServiceState(projectId);
    return;
  };

  render() {
    const { serviceIds, serviceId, openServiceInfo, addServiceModalVisible, onDeleteModalVisible } = this.state || {};
    return (
      <div>
        {onDeleteModalVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Service ${serviceId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={() => this.onDeleteConfirm(serviceId)}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </CustomModal>
        ) : null}

        {addServiceModalVisible ? <AddServiceModal onSubmit={this.onSubmit} onCancel={this.onCancel} /> : null}
        <Grid columns={2}>
          <Grid.Column style={{ width: '10%' }}>
            {serviceIds ? (
              <div>
                {
                  <List>
                    {_.map(serviceIds, (service, index) => (
                      <List.Item key={index}>
                        <List.Content>
                          <List.Description>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnDelete(service.id)}>
                              <Icon name='delete' size='small' color='red' />
                            </a>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnService(service)}>
                              {service.id == (openServiceInfo || {}).id ? <b>{service.id}</b> : service.id}
                            </a>
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                    <List.Item>
                      <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnCreateService()}>
                        <Icon name='add' size='small' color='green' /> <b style={{ color: 'green' }}> Create Service</b>
                      </a>
                    </List.Item>
                  </List>
                }
              </div>
            ) : null}
          </Grid.Column>
          {openServiceInfo ? (
            <Grid.Column style={{ width: '90%' }}>
              <ServiceView data={openServiceInfo} />
            </Grid.Column>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default ServiceManager;
