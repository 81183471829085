import { Breadcrumb, Card, Grid, Tab } from 'semantic-ui-react';

import BarChartWidget from './BarChartWidget';
import React from 'react';
import TableWidget from './TableWidget';
import _ from 'underscore';

const GenericWidget = ({ widget, widgetIdToWidget }) => {
  if ((widget || {}).type === 'bar-chart') {
    return (
      <div>
        <h4>{widget.title}</h4>
        <BarChartWidget name={widget.name} data={widget.data} />
      </div>
    );
  }

  if ((widget || {}).type === 'table') {
    return (
      <div>
        <h4>{widget.title}</h4>
        <TableWidget name={widget.name} data={widget.data} />
      </div>
    );
  }

  if ((widget || {}).type === 'navigation') {
    return (
      <Grid>
        {_.map(widget.childrenIds, (childId) => (
          <Card fluid onClick={() => this.handleCardClick(childId)}>
            <Card.Content>
              <Card.Header>{(widgetIdToWidget[childId] || {}).title || 'Child does not exist'}</Card.Header>
              <Card.Meta>
                <span>{childId}</span>
              </Card.Meta>
            </Card.Content>
          </Card>
        ))}
      </Grid>
    );
  }

  return (
    <div>
      <h3>Error - not recognized : {(widget || {}).type}</h3>
    </div>
  );
};

export default GenericWidget;
