import React from 'react';

export default class MiniLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>{this.props.location ? <div>{this.props.location.rawName}</div> : null}</div>;
  }
}
