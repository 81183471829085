import React from 'react';
import _ from 'underscore';
import axios from 'axios';

import baseUrl from '../../baseUrl.js';

import { Icon, Table, Label } from 'semantic-ui-react';

class ReviewSkills extends React.Component {
  componentDidMount() {
    this.getSelectedCandidateSkillEntities();
  }

  getSelectedCandidateSkillEntities = async () => {
    const url = baseUrl + '/extendSkills/getSelectedCandidateSkillEntities';
    const candidateSkillEntities = (await axios.get(url)).data;
    this.setState({ candidateSkillEntities });
  };

  onClickDeleteEntity = async (entityId) => {
    const params = { entityId };
    const url = baseUrl + '/extendSkills/deleteCandidateSkillEntity';
    const result = (await axios.post(url, params)).data;
    if (!result.success) {
      alert(result.message);
    }
  };

  renderCell = (name, key, item) => {
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Suggested Entity Id' ? (
          item.entityId
        ) : name == 'Suggested Patterns' ? (
          _.map((item || {}).patterns || [], (pattern) => {
            return <Label>{pattern.text}</Label>;
          })
        ) : name == 'Delete Entity' ? (
          <Icon color={'red'} name='close' onClick={() => this.onClickDeleteEntity(item.entityId)} />
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const { candidateSkillEntities } = this.state || {};
    const columnNames = ['Suggested Entity Id', 'Suggested Patterns', 'Delete Entity'];
    return (
      <Table>
        <Table.Header>
          <Table.HeaderCell>Suggested Entity Id</Table.HeaderCell>
          <Table.HeaderCell>Suggested Patterns</Table.HeaderCell>
          <Table.HeaderCell>Delete Entity</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {(candidateSkillEntities || []).map((item, key) => {
            return (
              <Table.Row key={key}>
                {columnNames.map((name, index2) => {
                  return this.renderCell(name, index2 + key, item);
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export default ReviewSkills;
