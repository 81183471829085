import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { 
  Grid, Button, Loader, 
  Divider, Icon, Segment, 
  Modal, Table, Popup,
  Input as SInput, Checkbox
} from 'semantic-ui-react';
import { SweetForm, Select, Input, TextArea } from 'sweetform'
import baseUrl from '../baseUrl';

const getClients = async () => {
  const url = `${baseUrl}/station/clients`;
  return axios.get(url).then(({ data }) => {
    return {
      options: _.map(data, ({ id, name }) => ({
        value: id,
        label: '[' + id + '] ' + name,
      })),
    };
  });
};

const getStationOffersOfClient = (clientId) => (
  async () => {
    const url = `${baseUrl}/station/clients/` + clientId + '/offers';
    const result = (await axios.get(url)).data;
    if (result.error) {
      alert(result.error);
      throw Error(result.error);
    }
    return {
      options: _.map(result, ({ id, title }) => ({
        value: id,
        label: title + ' (' + id + ')'
      })),
    };
  }
)

class SignatureSelectorModal extends Component {
  state = { 
    loading: true 
  }

  async componentDidMount() {
    const { clientId } = this.props;

    const recruiters = (await axios.get(
      `${baseUrl}/station/clients/${clientId}/recruiters`,
    )).data;

    const clientSignatures = [];
    _.each(recruiters, ({ email, clients }) => {
      _.each(clients, ({ signature }) => {
        if (signature) {
          clientSignatures.push({ value: signature, recruiterEmail: email })
        }
      });
    });

    this.setState({
      loading: false,
      signatures: [
        ...clientSignatures,
        { value: '', recruiterEmail: 'Void Signature' }
      ]
    });
  }

  render() {
    const { onClose, onSelectSignature } = this.props;
    const { loading, signatures } = this.state;
    return (
      <Modal
        open={true}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>
          Select a signature
        </Modal.Header>
        <Modal.Content>
          <div style={{ height: 500, overflowY: 'scroll' }}>
            {loading ? (
              <div>
                <br/><br/><br/>
                <center>
                  Loading...
                </center>
              </div>
            ) : (
              <Grid style={{ padding: 0, margin: 0}}>
                {_.map(signatures, (signature, index) => (
                  <Grid.Row key={index} style={{ paddingTop: 20,   margin: 0}} >
                    <Grid.Column width={12}>
                      {signature.recruiterEmail}
                      <Segment>
                        <div dangerouslySetInnerHTML={{ __html: signature.value}} />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                      <Button color='blue' onClick={() => onSelectSignature(signature.value)}>
                        Select
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
        </Modal.Actions>
      </Modal>
    );
  }
}

class SignatureSelector extends Component {

  state = {}

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  }

  handleChangeSignature = (signature) => {
    this.props.onChangeSignature(signature);
    this.setState({ isModalOpen: false });
  }

  render() {
    const { clientId, signature, onChangeSignature } = this.props;
    const { isModalOpen } = this.state;
    return (
      <div style={{ paddingTop: 15 }}>
        <Grid style={{ padding: 0, margin: 0}}><Grid.Row style={{ margin: 0, padding: 0}}>
          <Grid.Column width={8} style={{ padding: 0 }}>
            <h4>Signature:</h4>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <a style={{ cursor: 'pointer' }} onClick={this.handleOpenModal}>Select signature</a>
          </Grid.Column>
        </Grid.Row></Grid>
        <Segment fluid>
          <div dangerouslySetInnerHTML={{ __html: signature || '' }} />
        </Segment>
        {isModalOpen && (
          <SignatureSelectorModal 
            clientId={clientId}
            onClose={this.handleCloseModal}
            onSelectSignature={this.handleChangeSignature}
          />
        )}
      </div>
    );
  }
}

class ConfigForm extends Component {
  state = {};

  constructor(props) {
    super(props);
    if (this.props.baseConfig) {
      this.state.clientId = (this.props.baseConfig || {}).clientId;
      this.state.signature = (this.props.baseConfig || {}).signature;
    }
  }

  handleChangeClientId = ({ clientId }) => {
    this.setState({
      preselectedClientId: clientId
    });
  }

  handleFormEdit = (value) => {
    this.setState({ formValue: value });
  }

  handleChangeSignature = (value) => {
    this.setState({ signature: value });
  }

  onSubmit = () => {
    try {
      const { mode, baseConfig } = this.props;

      const config = {
        ...baseConfig,
        ...this.state.formValue
      }
      const sanitizedConfig = {
        ...config,
        ..._.isString(config.jobOfferIds) && {
          jobOfferIds: config.jobOfferIds === '' ? [] : config.jobOfferIds.split(';')
        },
      };

      if (!sanitizedConfig.clientId) {
        throw Error('clientId needed')
      }
      if (!sanitizedConfig.email) {
        throw Error('email needed')
      }
      if (!sanitizedConfig.alias) {
        throw Error('alias needed')
      }

      if (sanitizedConfig.email.indexOf('@') < 0) {
        throw Error('email needs a @');
      }
      if (sanitizedConfig.email.indexOf(' ') >= 0) {
        throw Error('email should not have spaces');
      }
      if (sanitizedConfig.alias.trim() !== sanitizedConfig.alias) {
        throw Error('alias should not have spaces at begin or end');
      }

      if (this.state.signature !== undefined) {
        sanitizedConfig.signature = this.state.signature;
      }

      if (mode === 'add') {
        this.props.onAddConfig(sanitizedConfig);
      } else {
        this.props.onUpdateConfig(sanitizedConfig);
      }
    } catch (e) {
      console.log(e);
      return alert(e.message);
    }
  }

  renderStepOne = () => {
    return (
      <div>
        <h3>Select Client</h3>
        <SweetForm key='step-one' onChange={this.handleChangeClientId}>
          <Select 
            field="clientId" 
            async 
            loadOptions={getClients} 
            labelKey="label" 
            valueKey="value" 
          />
        </SweetForm>
        {this.state.preselectedClientId ? (
          <Button color='green' onClick={() => {
            this.setState({ clientId: this.state.preselectedClientId })
          }}>
            Next
          </Button>
        ): null}
      </div>
    );
  }

  renderStepTwo = () => {
    const { mode, baseConfig } = this.props;
    const { clientId, signature } = this.state;

    const initialValues = {
      ...baseConfig,
      ...(baseConfig || {}).jobOfferIds !== undefined && {
        jobOfferIds: _.isArray(baseConfig.jobOfferIds) ? (
          baseConfig.jobOfferIds.join(';')
        ) : baseConfig.jobOfferIds
      },
      clientId
    };

    return (
      <div>
        <SweetForm 
          key={'step-two-'+clientId} 
          initialValues={initialValues} 
          onChange={this.handleFormEdit}
        >
          <h4>Client:</h4>
          <b>{clientId}</b>
          <h4>Alias:</h4>
          <Input field="alias" placeholder="Alias" />
          <h4>Email:</h4>
          <Input field="email" placeholder="Email" />
          <SignatureSelector 
            clientId={clientId} 
            signature={signature || ''}
            onChangeSignature={this.handleChangeSignature} 
          />
          <h4>JobOffers:</h4>
          <Select 
            field="jobOfferIds" 
            multi
            async 
            loadOptions={getStationOffersOfClient(clientId)} 
            labelKey="label" 
            valueKey="value"
          />
        </SweetForm>
        <Button color="green" onClick={this.onSubmit}>{mode === 'add' ? 'Add' : 'Edit'}</Button>
      </div>
    );
  }

  render() {
    const { mode, baseConfig, onClose } = this.props;
    const { clientId } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: 5, right: 10 }}>
          <Icon name='close' inline onClick={onClose} />
        </div>
        <h2 style={{ marginTop: 0 }}>{mode === 'add' ? 'Create config' : ('Edit ' + (baseConfig || {}).id)}</h2>
        {!clientId ? this.renderStepOne() : this.renderStepTwo()}
      </div>
    );
  }
}

class ConfigEditor extends Component {
  deletionStamp = 0;
  state = {};

  handleAskDelete = () => {
    try {
      const stamp = Date.now();
      this.deletionStamp = stamp;
      this.setState({ deletionEnabled: true });
      setTimeout(() => {
        if (this.deletionStamp === stamp) {
          this.setState({
            deletionEnabled: null
          });
        }
      }, 5000);
    } catch (e) {
      alert(e.message);
    }
  }

  render() {
    const { config, onAskUpdateConfig, onDeleteConfig } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4} verticalAlign="middle" >
            <span><b>Client:</b> {config.clientId}</span>
            <br />
            <span><b>Email:</b> {config.email}</span>
            <br />
            <span><b>Alias:</b> {config.alias}</span>
          </Grid.Column>
          <Grid.Column width={4}>
            <ul>
              {_.map(config.jobOfferIds, (jobOfferId, index) => (
                <li key={index}>{jobOfferId}</li>
              ))}
            </ul>
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle">
            <Button color="blue" onClick={() => { 
              onAskUpdateConfig(config) }}
            >
              Edit
            </Button>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign="middle">
            <Button color="orange" onClick={this.handleAskDelete}>Delete</Button>
            {this.state.deletionEnabled ? (
              <Button color="red" style={{ marginLeft: 3 }} onClick={() => {
                onDeleteConfig(config);
              }}>
                Confirm
              </Button>
            ): null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12} >
            {config.signature && (
              <Segment>
                <div dangerouslySetInnerHTML={{ __html: (config.signature || '') }} />
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


const ConfigsEditor = ({ configs, onAskAddConfig, onAskUpdateConfig, onDeleteConfig }) => {
  if (!configs) {
    return (
      <center>
        <br/><br/><br/>
        <Loader active inline />
      </center>
    );
  }
  return (
    <div>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>  
            <h2>Configs</h2>
          </Grid.Column>
          <Grid.Column textAlign="right">  
            <Button color='blue' onClick={() => onAskAddConfig()}>Add</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      {_.map(configs, (config) => (
        <div key={config.id}>
          <ConfigEditor config={config} onAskUpdateConfig={onAskUpdateConfig} onDeleteConfig={onDeleteConfig} />
          <Divider />
        </div>
      ))}
      <br/>
    </div>
  );
};


class ConfiguredOffersReviers extends Component {
  render() {
    const configs = _.sortBy(this.props.configs || [], 'clientId');
    return (
      <Table>
        <Table.Header>
          <Table.HeaderCell>
            Client
          </Table.HeaderCell>
          <Table.HeaderCell>
            Email
          </Table.HeaderCell>
          <Table.HeaderCell>
            Alias
          </Table.HeaderCell>
          <Table.HeaderCell>
            Offer
          </Table.HeaderCell>
          <Table.HeaderCell>
            Signature
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {_.flatten(_.map(configs, (config, index1) => (
            _.map(config.jobOfferIds, (jobOfferId, index2) => (
              <Table.Row key={index1 + '_' + index2}>
                <Table.Cell>{config.clientId}</Table.Cell>
                <Table.Cell>{config.email}</Table.Cell>
                <Table.Cell>{config.alias}</Table.Cell>
                <Table.Cell>{jobOfferId}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Popup 
                    content={
                      <div dangerouslySetInnerHTML={{ __html: config.signature || ''}} />
                    } 
                    trigger={<Icon name="add" />} 
                  />
                </Table.Cell>
              </Table.Row>
            ))
          )))}
        </Table.Body>
      </Table>
    );
  }
}

class ShootModal extends Component {
  
  state = { isSelected: { } };

  handleInputChange = (e, { value }) => {
    this.setState({ triggerText: value });
  }

  handleSelectAll = () => {
    const configs = this.props.configs || [];
    const toSelect = {};
    _.each(configs, (config) => {
      _.each(config.jobOfferIds, (jobOfferId) => {
        toSelect[jobOfferId] = 1
      })
    });
    console.log(toSelect);
    this.setState({
      isSelected: toSelect      
    });
  }

  handleToggleOffer = ({ jobOfferId }) => {
    this.setState({
      isSelected: {
        ...this.state.isSelected,
        [jobOfferId]: !this.state.isSelected[jobOfferId]
      }
    });
  }

  handleSubmit = async () => {
    const selectedOfferIds = [];
    _.each(this.state.isSelected, (value, jobOfferId) => {
      if (value) {
        selectedOfferIds.push(jobOfferId);
      }
    });

    const { data } = await axios.post(
      `${baseUrl}/station/prepareAutoshootProfiles`,
      {
        jobOfferIds: selectedOfferIds
      }
    );
    if (data.error) {
      alert('ERROR\n' + data.error);
    }
    if (data.log) {
      alert('LOG\n' + data.log);
    }

    this.props.onClose();
  }

  render() {
    const { onClose } = this.props;
    const configs = _.sortBy(this.props.configs || [], 'clientId');
    const { triggerText, isSelected } = this.state;

    return (
      <Modal
        open={true}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>
          ShootBoard
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <SInput onChange={this.handleInputChange} />
              </Grid.Column>
              <Grid.Column textAlign='right'>
                {triggerText === 'selectall' && (
                  <Button color="blue" onClick={this.handleSelectAll}>
                    Select All
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table>
            <Table.Header>
              <Table.HeaderCell>
                Client
              </Table.HeaderCell>
              <Table.HeaderCell>
                Email
              </Table.HeaderCell>
              <Table.HeaderCell>
                Alias
              </Table.HeaderCell>
              <Table.HeaderCell>
                Offer
              </Table.HeaderCell>
              <Table.HeaderCell>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.flatten(_.map(configs, (config, index1) => (
                _.map(config.jobOfferIds, (jobOfferId, index2) => (
                  <Table.Row key={index1 + '_' + index2}>
                    <Table.Cell>{config.clientId}</Table.Cell>
                    <Table.Cell>{config.email}</Table.Cell>
                    <Table.Cell>{config.alias}</Table.Cell>
                    <Table.Cell>{jobOfferId}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox 
                        checked={isSelected[jobOfferId]} 
                        onChange={() => this.handleToggleOffer({ jobOfferId })}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              )))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.handleSubmit() }>Submit</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class AutoShoot extends Component {
  state = {};

  loadConfigs = async () => {
    const result = (await axios.get(
      `${baseUrl}/station/autoshoot/configs`,
    )).data;
    console.log(result);
    if (result.error) {
      return alert(result.error);
    }
    this.setState({ configs: _.sortBy(result, 'id') });
  }

  handleAskAddConfig = () => {
    this.setState({ edition: { mode: 'add' } });
  }

  handleAskUpdateConfig = async (config) => {
    this.setState({ 
      edition: {  mode: 'update', baseConfig: config }
    });
  }

  handleCloseConfigForm = () => {
    this.setState({ edition: null });
  }

  handleAddConfig = async (config) => {
    try {
      const result = (await axios.post(
        `${baseUrl}/station/autoshoot/configs`,
        config
      )).data;
      if (result.error) {
        throw Error(result.error);
      }
      this.setState({
        configs: [
          ...(this.state.configs || []),
          result
        ],
        edition: null
      });
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }

  handleUpdateConfig = async (config) => {
    try {
      if (!(config || {}).id) {
        throw Error('invalid config in DELETE');
      }      
      const id = config.id;
      const result = (await axios.put(
        `${baseUrl}/station/autoshoot/configs/${id}`,
        config
      )).data;
      if (result.error) {
        throw Error(result.error);
      }
      this.setState({
        configs: _.map(this.state.configs, (config2) => (
          config2.id === id ? result : config2
        ))
      });
      alert('Saved!');
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }

  handleDeleteConfig = async (config) => {
    try {
      if (!(config || {}).id) {
        throw Error('invalid config in DELETE');
      }      
      const id = config.id;
      const result = (await axios.delete(
        `${baseUrl}/station/autoshoot/configs/${id}`,
      )).data;
      if (result.error) {
        return alert(result.error);
      }
      this.setState({
        configs: _.filter(this.state.configs, (config2) => config2.id !== id)
      });
    } catch (e) {
      alert(e.message);
    }
  }  

  handleOpenShootModal = () => {
    this.setState({ shootModalIsOpen: true });
  }

  handleCloseShootModal = () => {
    this.setState({ shootModalIsOpen: false });
  }

  componentWillMount() {
    this.loadConfigs();
  }

  render() {
    const { edition } = this.state;

    return (
      <div
        style={{
          position: 'fixed',
          top: '120px',
          left: 0,
          height: 'calc(100vh - 120px)',
          width: '100vw',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: 5,
              paddingBottom: 20,
              paddingLeft: 20, 
              paddingRight: 20,
              height: '100%',
              width: '50%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <div>
              <ConfigsEditor
                configs={this.state.configs}
                onAskAddConfig={this.handleAskAddConfig}
                onAskUpdateConfig={this.handleAskUpdateConfig}
                onDeleteConfig={this.handleDeleteConfig}
              />
            </div>
          </div>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              paddingLeft: 20,
              paddingRight: 20,
              height: '100%',
              width: '50%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {(edition || {}).mode === 'add' ? (
              <ConfigForm 
                mode='add' 
                onAddConfig={this.handleAddConfig}
                onClose={this.handleCloseConfigForm}
              />
            ) : (edition || {}).mode === 'update' ? (
              <ConfigForm 
                key={"form-" + edition.baseConfig.id}
                mode='update' 
                baseConfig={edition.baseConfig} 
                onUpdateConfig={this.handleUpdateConfig}
                onClose={this.handleCloseConfigForm}
              />
            ) : (
              <div>
                <Grid><Grid.Row columns={1}><Grid.Column textAlign='right'>
                  <Button 
                    color="green" 
                    onClick={this.handleOpenShootModal}
                  >
                    Launch
                  </Button>
                  <br/>
                </Grid.Column></Grid.Row></Grid>
                <ConfiguredOffersReviers
                  configs={this.state.configs}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.shootModalIsOpen && (
          <ShootModal 
            onClose={this.handleCloseShootModal} 
            configs={this.state.configs}
          />
        )}
      </div>
    );
  }
}

export default AutoShoot;
