import React from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Loader, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { regressionDiff, hasRegression } from './utils';
import { url } from './routes';

class RegressionsList extends React.Component {
  handleSelectProfile = (failureId) => {
    this.props.history.push(url('regressions:failure', { failureId }));
  };

  renderRow = (failure) => {
    const { regressions } = failure;
    const rows = [
      <Table.Row>
        <Table.Cell>{failure.dateScrap.format('HH:mm:ss')}</Table.Cell>
        <Table.Cell>
          <Button
            basic
            color="blue"
            onClick={() => this.handleSelectProfile(failure._id)}
          >
            {failure.itemId}
          </Button>
        </Table.Cell>
        <Table.Cell>{failure.nbAttempts}</Table.Cell>
        <Table.Cell>{regressions && regressions.length}</Table.Cell>
        <Table.Cell>
          {hasRegression(regressions, 'Personal') ? (
            <Icon color="red" name="checkmark" />
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {hasRegression(regressions, 'summary') ? (
            <Icon color="red" name="checkmark" />
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {hasRegression(regressions, 'experiences') ? (
            <Icon color="red" name="checkmark" />
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {hasRegression(regressions, 'education') ? (
            <Icon color="red" name="checkmark" />
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {hasRegression(regressions, 'skills') ? (
            <Icon color="red" name="checkmark" />
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {hasRegression(regressions, 'endorsements') ? (
            <Icon color="red" name="checkmark" />
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {regressionDiff(failure.oldProfile, failure.newProfile, 'summary')}
        </Table.Cell>
        <Table.Cell>
          {regressionDiff(
            failure.oldProfile,
            failure.newProfile,
            'experiences',
          )}
        </Table.Cell>
        <Table.Cell>
          {regressionDiff(failure.oldProfile, failure.newProfile, 'education')}
        </Table.Cell>
        <Table.Cell>
          {regressionDiff(failure.oldProfile, failure.newProfile, 'skills')}
        </Table.Cell>
        <Table.Cell>
          {regressionDiff(
            failure.oldProfile,
            failure.newProfile,
            'endorsements',
          )}
        </Table.Cell>
      </Table.Row>,
    ];

    return rows;
  };
  render() {
    if (this.props.isLoading) {
      return <Loader active inline="centered" />;
    }
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>#Attempts</Table.HeaderCell>
            <Table.HeaderCell>#Regs</Table.HeaderCell>
            <Table.HeaderCell>Per</Table.HeaderCell>
            <Table.HeaderCell>Sum</Table.HeaderCell>
            <Table.HeaderCell>Exp</Table.HeaderCell>
            <Table.HeaderCell>Ed</Table.HeaderCell>
            <Table.HeaderCell>Ski</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>

            <Table.HeaderCell>#Sum</Table.HeaderCell>
            <Table.HeaderCell>#Exp</Table.HeaderCell>
            <Table.HeaderCell>#Ed</Table.HeaderCell>
            <Table.HeaderCell>#Sk</Table.HeaderCell>
            <Table.HeaderCell>#End</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {[].concat(this.props.failures.map(this.renderRow))}
        </Table.Body>
      </Table>
    );
  }
}

function mapStateToPropsRegressionsList(state) {
  return {
    failures: state.failuresByUser.failures.toJS(),
    isLoading: state.failuresByUser.isLoadingFailures,
    selected: state.failuresByUser.failureId,
  };
}

export default connect(mapStateToPropsRegressionsList)(
  withRouter(RegressionsList),
);
