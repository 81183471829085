import { MATCHING_LEVELS } from '../../Watch/Candidate/utils';
import React from 'react';
import _ from 'underscore';

export const getColorFromEntity = ({ label }) => {
  const val = parseFloat(label);
  if (!isNaN(val)) {
    if (val > 0.9) {
      return '#55c329';
    }
    if (val > 0.8) {
      return '#68cc40';
    }
    if (val > 0.7) {
      return '#76c756';
    }
    if (val > 0.6) {
      return '#87cc6c';
    }
    if (val > 0.5) {
      return '#9bd285';
    }
    if (val > 0.4) {
      return '#a4d093';
    }
    if (val > 0.3) {
      return '#b0d0a3';
    }
    if (val > 0.2) {
      return '#b9d2af';
    }
    if (val > 0.1) {
      return '#bdc7b9';
    }
    if (val > 0) {
      return '#c7c7c7';
    }
    if (val > -0.1) {
      return '#dfcdc9';
    }
    if (val > -0.2) {
      return '#dbbcb4';
    }
    if (val > -0.3) {
      return '#d4aba1';
    }
    if (val > -0.4) {
      return '#d29d8f';
    }
    if (val > -0.5) {
      return '#ce9080';
    }
    if (val > -0.6) {
      return '#c98270';
    }
    if (val > -0.7) {
      return '#c7725c';
    }
    if (val > -0.8) {
      return '#c9664d';
    }
    if (val > -0.9) {
      return '#cc583b';
    }
    return '#c94422';
  }

  return label
    ? label === 'required' || label === 'required__strong-implication' || label === 'required__medium-implication'
      ? '#21BA45'
      : label === 'important' || label === 'important__strong-implication' || label === 'important__medium-implication'
      ? '#B5CC18'
      : label === 'bonus' || label === 'bonus__strong-implication' || label === 'bonus__medium-implication'
      ? '#FBBD08'
      : label === 'cardinal'
      ? 'yellow'
      : label === 'positive'
      ? '#21BA45'
      : 'orange'
    : '#eee';
};

const EntityBlock = ({ text, entity }) => {
  const label = entity.getLabel().get();
  const color = getColorFromEntity({ label: label });
  const fontStyle = typeof label === 'string' ? (label.indexOf('italic') !== -1 ? 'italic' : 'normal') : 'normal';
  return <span style={{ backgroundColor: color, fontStyle: fontStyle }}>{text}</span>;
};

const FormattedParagraphFromString = ({ text }) => {
  if (text) {
    const paragraphs = text.split(/\n\n/g);
    return (
      <span>
        {_.map(paragraphs, (paragraph, index) => {
          const lines = paragraph.split(/\n/g);
          return (
            <span key={index}>
              {_.map(lines, (line, lineIndex) => (
                <span key={lineIndex}>
                  {line}
                  {lineIndex < lines.length - 1 ? <br /> : ''}
                </span>
              ))}
              {index < paragraphs.length - 1 ? <div style={{ marginBottom: 6 }} /> : ''}
            </span>
          );
        })}
      </span>
    );
  }
  return null;
};

const EnrichedText = ({ text, enrichments }) => {
  if (!text) {
    return null;
  }
  const enrichmentsArray = enrichments ? enrichments.get() : [];
  const sortedEnrichments = _.sortBy(
    _.filter(
      enrichmentsArray,
      ({ getBegin, getEnd }) => _.isNumber(getBegin().get()) && !isNaN(getBegin().get()) && getEnd().get(),
    ),
    ({ getBegin, getEnd }) => {
      return 1000 * getBegin().get() + (1000 - getEnd().get());
    },
  );

  let items = [];
  let curPos = 0;
  _.each(sortedEnrichments, (enrichment) => {
    const beg = enrichment.getBegin().get();
    const end = enrichment.getEnd().get();
    if (beg < curPos) {
      return;
    }
    if (beg > curPos) {
      items.push(
        <span key={items.length}>
          <FormattedParagraphFromString text={text.slice(curPos, beg)} />
        </span>,
      );
    }
    items.push(
      <span key={items.length}>{EntityBlock({ text: text.slice(beg, end), entity: enrichment.getEntity() })}</span>,
    );
    curPos = end;
  });
  if (text.length > curPos) {
    items.push(
      <span key={items.length}>
        <FormattedParagraphFromString text={text.slice(curPos, text.length)} />
      </span>,
    );
  }
  return <span>{items}</span>;
};

export default EnrichedText;
