import React from 'react';
import _ from 'underscore';
import {
  Segment,
  Label,
  Header,
  Grid,
  Statistic,
  Container,
  Icon,
  List,
} from 'semantic-ui-react';
import ExtLink from '../../components/ExtLink';
import { getColorFromScrapDate } from '../../common'; 
import { getLastScrapText } from '../utils';
import EnrichedText from '../components/EnrichedText';

const StackoverflowProfileHeader = ({ stackoverflowData }) => {
  const id = stackoverflowData.getId().get();
  const lastScrapDay = stackoverflowData.getLastScrapDay().get();
  const fullname = stackoverflowData.getFullname().get();
  const website = stackoverflowData.getWebsite().get();
  return (
    <Header size="medium">
      <div style={{ float: 'right' }}>
        <Label
          style={{ marginRight: 20 }}
          color={getColorFromScrapDate(lastScrapDay)}
        >
          {getLastScrapText(lastScrapDay)}
        </Label>
        <ExtLink
          classes="source-link"
          to={'https://stackoverflow.com/users/' + id}
        >
          <Icon name="stack overflow" />
        </ExtLink>
        {website ? (
          <ExtLink to={website}>
            <Icon name="external" />
          </ExtLink>
        ) : null}
      </div>
      <Header.Content>{fullname}</Header.Content>
    </Header>
  )
}

const StackoverflowProfileSubHeader = ({ stackoverflowData }) => {
  const location = stackoverflowData.getLocation();
  const age = stackoverflowData.getAge().get();
  const summary = stackoverflowData.getSummary();
  return (
    <Grid style={{ clear: 'right' }}>
      <Grid.Column width={6}>
        <List>
          {location ? (
            <List.Item
              icon="marker"
              content={
                location.getName().getDefault().get()
              }
            />
          ) : null}
          {age ? (
            <List.Item icon="time" content={age} /> 
          ) : null}
        </List>
      </Grid.Column>
      <Grid.Column width={10}>
        {summary.getContent().getText().get() ? (
          <Segment>
             <EnrichedText
                text={summary.getContent().getText().get()}
                enrichments={summary.getContent().getEnrichments()}
              />
          </Segment>
        ) : null}
      </Grid.Column>
    </Grid>
  );
}

const StackoverflowProfileStatistics = ({ stackoverflowData }) => (
  <Statistic.Group widths={4} size="mini" style={{ marginTop: 20 }}>
    <Statistic label="Questions" value={stackoverflowData.getQuestionsCount().get()} />
    <Statistic label="Answers" value={stackoverflowData.getAnswersCount().get()} />
    <Statistic label="Reputation" value={stackoverflowData.getReputation().get()} />
    <Statistic label="Accept Rate" value={(stackoverflowData.getAcceptRate().get() || '?') + '%'} />
  </Statistic.Group>
);

const StackoverflowProfileTag = ({ tag }) => (
  <Label size='big' style={{ backgroundColor: '#e1e1e1' }}>
    <EnrichedText
      text={tag.getName().getText().get()}
      enrichments={tag.getName().getEnrichments()}
    />
    <span> </span>
    <span style={{ fontSize: '0.7em' }}>{tag.getAnswersScore().get()}</span>
  </Label>
);

const StackoverflowProfileTags = ({ stackoverflowData }) => (
  <Container fluid textAlign="center">
    <Label.Group style={{ marginTop: 20 }}>
      {_.map(stackoverflowData.getTags().get(), (tag, index) => (
        <StackoverflowProfileTag key={index} tag={tag} />
      ))}
    </Label.Group>
  </Container>
);

const StackoverflowProfile = ({ stackoverflowData }) => (
  <Segment color="orange">
    <StackoverflowProfileHeader stackoverflowData={stackoverflowData} />
    <StackoverflowProfileSubHeader stackoverflowData={stackoverflowData} />
    <StackoverflowProfileStatistics stackoverflowData={stackoverflowData} />
    <StackoverflowProfileTags stackoverflowData={stackoverflowData} />
  </Segment>
);

export default StackoverflowProfile;
