import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Tab } from 'semantic-ui-react';
import UserStats from '../MySpace/Stats';
import baseUrl from '../baseUrl';

class MultiUsersStats extends Component {
  componentWillMount() {
    this.load();
  }
  load = async () => {
    this.setState({
      loading: true,
    });
    const clients = (await axios.get(baseUrl + '/station/clients')).data;
    let isActiveClient = {};
    _.each(clients, ({ id, adminState }) => {
      if (adminState === 'active') {
        isActiveClient[id] = true;
      }
    });
    const offers = (await axios.get(baseUrl + '/station/offers')).data;
    let usersSet = {};
    _.each(offers, ({ ownerOps, adminState, clientId }) => {
      if (adminState === 'active' && isActiveClient[clientId]) {
        _.each(ownerOps, (user) => {
          if (user !== 'ops') {
            usersSet[user] = 1;
          }
        });
      }
    });
    const users = this.props.explicitUsers || _.keys(usersSet);

    this.setState({
      loading: false,
      users,
    });
  };
  render() {
    if (this.state.loading) {
      return <div />;
    }

    const panes = _.map(this.state.users, (user) => ({
      menuItem: user,
      //pane: <Tab.Pane><UserStats key={user} username={user}/></Tab.Pane>
      render: () => (
        <Tab.Pane>
          <UserStats key={user} username={user} memoize={true} />
        </Tab.Pane>
      ),
    }));

    return (
      <div>
        <Tab
          renderActiveOnly={true}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </div>
    );
  }
}

export default MultiUsersStats;
