import _ from 'underscore';
import React from 'react';
import { Modal, Grid, TextArea, Loader, Button, Icon } from 'semantic-ui-react';
import GenericDashboard from './GenericDashboard';

class DashboardEditionModal extends React.Component {
  state = {
    mode: 'edit',
    previewKey: '',
    initialDashboard: {},
    currentJSON: '{}',
    isValidJSON: true,
  }

  handleSelectEditMode = () => {
    this.setState({ mode: 'edit' });
  }

  handleSelectPreviewMode = () => {
    this.setState({ 
      mode: 'preview',
      previewKey: '' + Math.random()
    });
  }

  handleChangeJSON = ({ value }) => {
    let isValidJSON = true;
    try {
      JSON.parse(value);
    } catch (e) {
      isValidJSON = false
    }

    this.setState({
      currentJSON: value,
      isValidJSON
    });
  }

  handleSubmit = () => {
    const { initialDashboard, currentJSON, isValidJSON } = this.state;
    let newDashboard = {};
    try {
      if (!isValidJSON) {
        throw Error('Erro: invalid JSON')
      }
      newDashboard = JSON.parse(currentJSON);
      if (initialDashboard.id && (newDashboard || {}).id !== initialDashboard.id) {
        throw Error('Error: id should be ' + initialDashboard.id);
      }
    } catch (e) {
      return alert(e.message);
    }
    this.props.onSave({ dashboard: newDashboard });
  }

  componentDidMount = () => {
    const dashboard = this.props.dashboard || {};
    const initialDashboard = {
      ...dashboard.id && { id: dashboard.id },
      ...dashboard.title && { title: dashboard.title },
      ...dashboard.type && { type: dashboard.type },
      ...dashboard.username && { username: dashboard.username },
      ...dashboard.creationTimestamp && { creationTimestamp : dashboard.creationTimestamp },
      ...dashboard.layout && { layout: dashboard.layout },
      ...dashboard
    };

    this.setState({ 
      initialDashboard,
      currentJSON: JSON.stringify(initialDashboard || {}, null, 4),
      isValidJSON: true
    });
  }  

  render() {
    const { onClose, dashboard, creationMode } = this.props;
    const { mode, currentJSON, isValidJSON } = this.state;

    return (
      <Modal
        open={true}
        onClose={onClose}
        closeIcon
        size="fullscreen"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {(dashboard || {}).title} ({(dashboard || {}).id})
        </Modal.Header>
        <Modal.Content style={{ minHeight: 500 }}>
          <Button.Group>
            
            <Button
              color={mode === "edit" ? 'blue' : 'grey'}
              onClick={this.handleSelectEditMode}
            >
              Edit
            </Button>
            <Button
              color={mode === "preview" ? 'blue' : 'grey'}
              onClick={this.handleSelectPreviewMode}
            >
              Preview
            </Button>
          </Button.Group>
          <br/><br/>
          
          {mode === 'edit' && (
            <div>
              <TextArea 
                style={{ 
                  outline: 'none',
                  border: (isValidJSON ? '1px solid lime' : '1px solid red') 
                }}
                rows={50}
                cols={140}
                value={currentJSON}
                onChange={(e, { value }) => this.handleChangeJSON({ value })}
              />
            </div>
          )}

          {mode === 'preview' && (
            <div key={this.state.previewKey}>
              {!isValidJSON ? (
                <center>
                  <br/><br/><br/>
                  <span style={{ color: 'red' }}>Invalid JSON</span>
                  <br/><br/><br/>
                </center>
              ) : (
                <center>
                  <GenericDashboard dashboard={JSON.parse(currentJSON)} />
                </center>
              )}
            </div>
          )}

        </Modal.Content>
        <Modal.Actions>
          <Button 
            color='green'
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DashboardEditionModal;
