import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Card, Grid, Button, Checkbox, Loader, Modal } from 'semantic-ui-react';
import baseUrl from '../../baseUrl';

class WeeklyStatsNotifications extends React.Component {
  state = {
    clientsData: null,
    loadingData: false,
    modalOpen: false,
  };

  async loadData() {
    this.setState({ loadingData: true });
    let clientsData;
    try {
      const { data } = await axios.get(baseUrl + '/station/slack/weekly-stats/clients');
      clientsData = data.clients;
    } catch (e) {
      console.log(e.message);
    }
    this.setState({ clientsData, loadingData: false });
  }

  componentDidMount() {
    this.loadData();
  }

  toggleClient = async ({ clientId }) => {
    const { clientsData } = this.state;
    const newClientState = _.map(clientsData, (client) => {
      if (client.id === clientId) {
        return {
          ...client,
          checked: !client.checked,
        };
      }
      return client;
    });
    this.setState({ clientsData: newClientState });
  };

  handleSendNotifications = async () => {
    const { clientsData } = this.state;
    const selectedClients = _.filter(clientsData, ({ checked }) => !!checked);
    try {
      const body = { clients: selectedClients };
      const { data } = await axios.post(baseUrl + '/station/slack/weekly-stats/send-notification', body);
    } catch (e) {
      console.log(e.message);
    }
    this.closeModal();
  };

  onSelectAll = async () => {
    const { clientsData } = this.state;
    const newClientState = _.map(clientsData, (client) => ({ ...client, checked: true }));
    this.setState({ clientsData: newClientState });
  };

  onSelectNone = async () => {
    const { clientsData } = this.state;
    const newClientState = _.map(clientsData, (client) => ({ ...client, checked: false }));
    this.setState({ clientsData: newClientState });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { clientsData, loadingData, modalOpen } = this.state;

    if (loadingData) {
      return <Loader active size='large'/>;
    }

    if (_.isEmpty(clientsData)) {
      return (
        <Grid columns='sixteen'>
          <Grid.Row>
            <Grid.Column width={12}>
              <Card style={{ padding: 20 }}>
                There are no active Slack clients with this notification
                turned on and with data to send
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }

    console.log('clientsData', clientsData);
    const selectedClientIds = _.compact(_.map(clientsData, ({ id, checked }) => (
      checked ? id : null
    )));

    const allSelected = _.reduce(clientsData, (memo, { checked }) => memo && checked, true);

    const modalTrigger = (
      <Button primary disabled={_.isEmpty(selectedClientIds)} onClick={this.openModal}>
        Send Notifications
      </Button>
    );

    return (
      <Grid columns='sixteen'>
        <Grid.Row>
          <Grid.Column width={12}>
            <div>
              <Button onClick={allSelected ? this.onSelectNone : this.onSelectAll} primary>
                {allSelected ? 'Select none' : 'Select all'}
              </Button>
            </div>
            {_.map(clientsData, (client) => {
              const { id, name, checked, data } = client || {};
              const { totalSent, totalReviewed, offers } = data || {};
              return (
                <Card key={id} className='client-card' fluid>
                  <Card.Content>
                    <Grid columns='sixteen'>
                      <Grid.Row>
                        <Grid.Column width={4} verticalAlign="middle">
                          <Checkbox
                            checked={!!checked}
                            label={`${name} [${id}]`}
                            onChange={() => this.toggleClient({ clientId: id })}
                          />
                        </Grid.Column>
                        <Grid.Column width={12} verticalAlign="middle">
                          <div>Total contacted: {totalSent}/{totalReviewed}</div>
                          {
                            _.map(offers, (offer) => (
                              <OfferDisplay offer={offer}/>
                            ))
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Card.Content>
                </Card>
              );
            })}
          </Grid.Column>
          <Grid.Column width={4}>
            <Modal trigger={modalTrigger} open={modalOpen} onClose={this.closeModal} size='tiny'>
              <Modal.Header>
                Are you sure you want to Send ?
              </Modal.Header>
              <Modal.Actions>
                <Button basic color='grey' content='Cancel' onClick={this.closeModal}/>
                <Button basic color='blue' content='Send' onClick={this.handleSendNotifications}/>
              </Modal.Actions>
            </Modal>
            {
              !_.isEmpty(selectedClientIds) && (
                <div>
                  <span>to</span>
                  <ul>
                    {_.map(selectedClientIds, (id) => (
                      <li key={id}>{id}</li>
                    ))}
                  </ul>
                </div>
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}

const OfferDisplay = ({ offer }) => {
  let {
    id,
    title,
    nbSent,
    nbReviewed,
    positiveAnswerProfiles,
  } = offer || {};
  // defaults
  id = id || '';
  title = title || '';
  nbSent = nbSent || 0;
  nbReviewed = nbReviewed || 0;
  positiveAnswerProfiles = positiveAnswerProfiles || [];
  return (
    <div>
      <span>{title} ({id})</span>
      <ul>
        <li>
          {`${nbSent}/${nbReviewed} profiles contacted (${strPercentage(nbSent, nbReviewed)}%)`}
        </li>
        <li>
          {`${positiveAnswerProfiles.length} positive replies`}
        </li>
      </ul>
    </div>
  );
};

const strPercentage = (a, b) => {
  if (!b || b === 0) {
    return 0;
  }
  return ((a / b) * 100).toFixed(0);
};

export default WeeklyStatsNotifications;
