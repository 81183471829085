import React, { Component } from 'react';
import FlowBoard from '../FlowBoard/FlowBoard';

// Components
class MyOffers extends Component {
  render() {
    return (
      <FlowBoard
        restrictedUsers={this.props.username ? [this.props.username] : []}
      />
    );
  }
}

export default MyOffers;
