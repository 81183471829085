import _ from 'underscore';
import React from 'react';
import { Divider, Segment, Label } from 'semantic-ui-react';

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

const Experience = ({ experience }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Experience
    </Divider>

    <div style={rowStyle}>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div>
          Target:{' '}
          <Label color="blue">
            {(experience.target || {}).min} - {(experience.target || {}).max}{' '}
          </Label>
        </div>
      </div>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div>
          Ok with:{' '}
          <Label color="blue">
            {(experience.okWith || {}).min} - {(experience.okWith || {}).max}
          </Label>
        </div>
      </div>
    </div>
  </div>
);

const Locations = ({ locations }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Locations
    </Divider>
    <div style={rowStyle}>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div style={{ marginRight: '2px' }}>Target:</div>
        {_.map(locations.target, ({ location, remoteWish }, i) => (
          <div key={i}>
            <Label color="blue">{remoteWish} </Label>
            <Label color="blue">{location.id}</Label>
          </div>
        ))}
      </div>
      <div style={{ ...rowStyle, width: '50%' }}>
        <div style={{ marginRight: '2px' }}>Ok with: </div>
        {_.map(locations.okWith, ({ location, remoteWish }, i) => (
          <div key={i}>
            <Label color="blue">{remoteWish}</Label>
            <Label color="blue">{location.id}</Label>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Background = ({ backgroundSolidity, importance, startupnessImportance, schoolPrestige }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Background (Importance: {importance})
    </Divider>
    <div style={rowStyle}>
      <div style={columnStyle}>
        <div style={{ marginBottom: '1em' }}>
          <div style={{ fontWeight: 'bold' }}>Solidity</div>
          {backgroundSolidity.disabled ? (
            <div>
              <Label color="red">Disabled</Label>
            </div>
          ) : (
            <div style={rowStyle}>
              <div style={{ width: '50%' }}>
                Target: <Label color="blue">{(backgroundSolidity.target || {}).min}</Label>
              </div>
              {(backgroundSolidity.okWith || {}).min !== undefined && (
                <div style={{ width: '50%' }}>
                  Ok with: <Label color="blue">{(backgroundSolidity.okWith || {}).min}</Label>
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ marginBottom: '1em' }}>
          <div style={{ fontWeight: 'bold' }}>SchoolPrestige</div>
          {schoolPrestige.disabled ? (
            <div>
              <Label color="red">Disabled</Label>
            </div>
          ) : (
            <div style={rowStyle}>
              <div style={{ width: '50%' }}>
                Target:{' '}
                {_.map(schoolPrestige.target, ({ id }, i) => (
                  <Label key={i} color="blue">
                    {id}{' '}
                  </Label>
                ))}
              </div>
              <div style={{ width: '50%' }}>
                Ok with:{' '}
                {_.map(schoolPrestige.okWith, ({ id }, i) => (
                  <Label key={i} color="blue">
                    {id}{' '}
                  </Label>
                ))}
              </div>
            </div>
          )}
        </div>
        <div>
          <div style={{ fontWeight: 'bold' }}>Startupness Importance</div>
          {startupnessImportance && <Label color="blue">{startupnessImportance}</Label>}
        </div>
      </div>
    </div>
  </div>
);

const SkillsContainer = ({ skills }) => (
  <Label.Group
    color="blue"
    style={{
      border: '#dddddd solid 1px',
      borderRadius: '2px',
      marginRight: '2px',
      marginBottom: '2px',
      padding: '4px 0px 0px 4px',
    }}
  >
    {_.map(skills, ({ id }, i) => (
      <Label key={i} color="blue">
        {id}
      </Label>
    ))}
  </Label.Group>
);

const Skills = ({ skills, importance }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Skills (Importance: {importance})
    </Divider>
    <div style={rowStyle}>
      <div style={{ ...columnStyle, width: '33%' }}>
        <div style={{ fontWeight: 'bold' }}>Main</div>
        {_.map(skills.main, ({ skills }, i) => (
          <SkillsContainer key={i} skills={skills} />
        ))}
      </div>
      <div style={{ ...columnStyle, width: '33%' }}>
        <div style={{ fontWeight: 'bold' }}>Important</div>
        {_.map(skills.important, ({ skills }, i) => (
          <SkillsContainer key={i} skills={skills} />
        ))}
      </div>
      <div style={{ ...columnStyle, width: '33%' }}>
        <div style={{ fontWeight: 'bold' }}>Bonus</div>
        {_.map(skills.bonus, ({ skills }, i) => (
          <SkillsContainer key={i} skills={skills} />
        ))}
      </div>
    </div>
  </div>
);

const Job = ({ jobPosition, importance }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Job Position (Importance: {importance})
    </Divider>
    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Major</div>
        <Label color="blue">{(jobPosition.major || {}).id}</Label>
      </div>
      <div style={{ width: '50%' }}>
        <div style={{ fontWeight: 'bold' }}>Minors</div>
        {_.map(jobPosition.minors, ({ id }, i) => (
          <Label color="blue" key={i}>
            {id}
          </Label>
        ))}
      </div>
    </div>
  </div>
);

const Criterion = ({ criterion }) => {
  if (!criterion || !criterion.id) {
    return null;
  }
  const selection = criterion.id;
  const value = criterion.params;
  if (value && value.sons) {
    return (
      <div style={rowStyle}>
        <Label color="green" style={{ marginBottom: '2px' }}>
          {selection}
        </Label>
        <div>
          {_.map(value.sons, (criterion, i) => (
            <Criterion key={i} criterion={criterion} />
          ))}
        </div>
      </div>
    );
  }
  if (value && value.son) {
    return (
      <div style={rowStyle}>
        <Label color="green" style={{ marginBottom: '2px' }}>
          {selection}
        </Label>
        <div>
          <Criterion criterion={value.son} />
        </div>
      </div>
    );
  }
  const displayedValue = value && (_.map(value, (val, key) => `${key}: ${val}`) || []).join(', ');
  return (
    <div>
      <Label color="blue" style={{ marginBottom: '2px' }}>
        {selection}
        {displayedValue && `: (${displayedValue})`}
      </Label>
    </div>
  );
};

const ExtraCriteria = ({ extraCriteria }) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Extra Criteria
    </Divider>
    <div>
      {_.map(extraCriteria, ({ importance, criterion }, i) => (
        <div key={i} style={{ ...rowStyle, marginBottom: '1em' }}>
          <div style={{ width: '20%', fontWeight: 'bold' }}>{importance}</div>
          <Criterion criterion={criterion} />
        </div>
      ))}
    </div>
  </div>
);

const Offer = ({ offer }) => {
  const criteria = offer.criteria || {};
  const {min: minSalary, max: maxSalary} = offer.salaryRange || {};
  return (
    <Segment color="blue">
      <h2>
        {offer.title} @{offer.clientId}
      </h2>
      <div>
        <span style={{ fontWeight: 'bold' }}>Salary range:</span> 
        {` min: ${_.isNumber(minSalary) ? minSalary : '?'} max: ${_.isNumber(maxSalary) ? maxSalary : '?'}`}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Contract type:</span> {offer.contractType}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Responsibilities:</span> {offer.responsibilities}
      </div>
      <div>
        <Experience experience={criteria.experience || {}} />
      </div>
      <div>
        <Locations locations={criteria.locations || {}} />
      </div>
      <div>
        <Background
          backgroundSolidity={criteria.backgroundSolidity || {}}
          importance={(criteria.importanceWeights || {}).background}
          startupnessImportance={criteria.startupnessImportance || ''}
          schoolPrestige={criteria.schoolPrestige || {}}
        />
      </div>
      <div>
        <Skills skills={criteria.skills || {}} importance={(criteria.importanceWeights || {}).skills} />
      </div>
      <div>
        <Job jobPosition={criteria.jobPosition || {}} importance={(criteria.importanceWeights || {}).jobPosition} />
      </div>
      <div>
        <ExtraCriteria extraCriteria={criteria.extraCriteria || []} />
      </div>
      {criteria.extraCriteria ? (
        <div>
          <pre>{JSON.stringify(criteria.extraCriteria, null, 4)}</pre>
        </div>
      ) : null}
    </Segment>
  );
};

export default Offer;
