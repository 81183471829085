import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import {
  Checkbox,
  Button,
  Icon,
  Loader,
  Grid,
  Segment,
} from 'semantic-ui-react';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import baseUrl from '../baseUrl.js';

const minDay = '18-06-01';

const getColorFromUsername = (username) => {
  if (!_.isString(username)) {
    return 'black';
  }
  const colors = {
    mathieu: 'orange',
    robin: 'purple',
    lamia: 'lime',
    yannis: 'olive',
    lina: 'aqua',
    laurene: 'darkorange',
    undefined: 'grey',
  };

  const otherColors = [
    'salmon',
    'violet',
    'indigo',
    'gold',
    'lavender',
    'magenta',
    'orangered',
    'palegreen',
    'ṕeru',
    'rosybrown',
    'mediumturquoise',
  ];

  if (colors[username]) {
    return colors[username];
  }
  let hash = 0;
  for (let i = 0; i < username.length; i++) {
    const charCode = username.charCodeAt(i);
    if (_.isNumber(charCode)) {
      hash += charCode;
    }
  }
  return otherColors[hash % otherColors.length];
};

const PERMISSION_CATEGORIES = ["only-marketplace", "only-crm", "marketplace-and-crm"];

const hasValidPermissions = ({ activatedPermissions, rejectedPermissionCategories }) => {
  if (rejectedPermissionCategories["only-marketplace"] && (activatedPermissions || []).indexOf("watchCollect") >= 0 && (activatedPermissions || []).indexOf("reveal") < 0){
    return false;
  }
  if (rejectedPermissionCategories["only-crm"]  && (activatedPermissions || []).indexOf("watchCollect") < 0 && (activatedPermissions || []).indexOf("reveal") >= 0){
    return false;
  }
  if (rejectedPermissionCategories["marketplace-and-crm"]  && (activatedPermissions || []).indexOf("watchCollect") >= 0 && (activatedPermissions || []).indexOf("reveal") >= 0){
    return false;
  }
  return true;
};

/*
 * props:
 * - title
 * - events
 * - defaultMaxNbResults (default 200)
 */
class CustomerEvents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxNbResults: this.props.defaultMaxNbResults || 200,
    };
  }
  handleToggleFilterMode() {
    const currentFilter = this.state.filter;
    if (currentFilter) {
      this.setState({ filter: null });
    } else {
      this.setState({ filter: {} });
    }
  }
  handleAugmentNbResults() {
    const maxNbResults = this.state.maxNbResults;
    this.setState({
      maxNbResults: 2 * maxNbResults + 1,
    });
  }
  handleUpdateFilter(toggle) {
    const currentFilter = this.state.filter || {};
    if (toggle.type === 'type') {
      this.setState({
        filter: {
          ...currentFilter,
          rejectTypes: {
            ...currentFilter.rejectTypes,
            [toggle.actionType]: !(currentFilter.rejectTypes || {})[
              toggle.actionType
            ],
          },
        },
      });
    }
    if (toggle.type === 'user') {
      this.setState({
        filter: {
          ...currentFilter,
          rejectUsers: {
            ...currentFilter.rejectUsers,
            [toggle.user]: !(currentFilter.rejectUsers || {})[toggle.user],
          },
        },
      });
    }
    if (toggle.type === 'permission') {
      this.setState({
        filter: {
          ...currentFilter,
          rejectPermissions: {
            ...currentFilter.rejectPermissions,
            [toggle.permission]: !(currentFilter.rejectPermissions || {})[toggle.permission],
          },
        },
      });
    }
  }
  renderEventsTable(events) {
    const getWeekDay = (date) => {
      const weekDay = new Date(date).toDateString().slice(0, 3);
      return weekDay + '. ';
    };
    const getFormattedDate = (date) => {
      return date.slice(2, 10) + ' - ' + date.slice(11, 16);
    };

    return (
      <Grid>
        {_.map(events, ({ type, itemId, salesOwner, date }, index) => (
          <Grid.Row
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 4,
            }}
          >
            <Grid.Column width={1} textAlign="right" style={{ padding: 0 }}>
              <span style={{ fontFamily: 'monospace' }}>
                {getWeekDay(date)}
              </span>
            </Grid.Column>
            <Grid.Column width={3} style={{ padding: 0 }}>
              <span>{getFormattedDate(date)}</span>
            </Grid.Column>
            <Grid.Column width={2} style={{ padding: 0 }}>
              <b>
                <span style={{ color: getColorFromUsername(salesOwner) }}>
                  {salesOwner || 'undefined'}
                </span>
              </b>
            </Grid.Column>
            <Grid.Column width={2} style={{ padding: 0 }}>
              <b>{type}</b>
            </Grid.Column>
            <Grid.Column width={6}>
              <span style={{ color: 'blue' }}>{itemId}</span>
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    );
  }
  renderFiltersSelector() {
    const allTypes = _.compact(_.uniq(_.pluck(this.props.events, 'type')));
    const allSalesOwner = _.compact(
      _.uniq(_.pluck(this.props.events, 'salesOwner')),
    );
    const rejectPermissions = (this.state.filter || {}).rejectPermissions || {};
    const rejectTypes = (this.state.filter || {}).rejectTypes || {};
    const rejectUsers = (this.state.filter || {}).rejectUsers || {};
    const buttonStyle = {
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 3,
      marginRight: 5,
    };
    return (
      <div>
        {_.map(PERMISSION_CATEGORIES, (permission) => (
          <Button
            style={buttonStyle}
            color={rejectPermissions[permission] ? 'silver' : 'olive'}
            onClick={() =>
              this.handleUpdateFilter({ type: 'permission', permission })
            }
          >
            {permission}
          </Button>
        ))}
        <br />
        {_.map(allTypes, (type) => (
          <Button
            style={buttonStyle}
            color={rejectTypes[type] ? 'silver' : 'olive'}
            onClick={() =>
              this.handleUpdateFilter({ type: 'type', actionType: type })
            }
          >
            {type}
          </Button>
        ))}
        <br />
        {_.map(allSalesOwner, (user) => (
          <Button
            style={buttonStyle}
            color={rejectUsers[user] ? 'silver' : 'olive'}
            onClick={() => this.handleUpdateFilter({ type: 'user', user })}
          >
            {user}
          </Button>
        ))}
      </div>
    );
  }
  render() {
    const filter = this.state.filter || {};
    const filteredEvents = _.filter(
      this.props.events,
      ({ salesOwner, type, activatedPermissions }) => {
        return (
          !(filter.rejectTypes || {})[type] &&
          !(filter.rejectUsers || {})[salesOwner] &&
          hasValidPermissions({ activatedPermissions, rejectedPermissionCategories: (filter.rejectPermissions || {}) })
        );
      },
    );
    const eventsToDisplay = filteredEvents.slice(0, this.state.maxNbResults);
    const canSeeMore = filteredEvents.length > this.state.maxNbResults;
    return (
      <div>
        <h3>
          {this.props.title}
          <span> </span>
          <Icon
            name="filter"
            link
            color={this.state.filter ? 'green' : 'black'}
            onClick={() => this.handleToggleFilterMode()}
          />
        </h3>
        {this.state.filter && this.renderFiltersSelector()}
        <Segment
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            maxHeight: 500,
            overflowY: 'auto',
          }}
        >
          {this.renderEventsTable(eventsToDisplay)}
          {canSeeMore && (
            <div>
              <br />
              <center>
                {/* eslint-disable-next-line */}
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleAugmentNbResults()}
                >
                  See more
                </a>
              </center>
            </div>
          )}
        </Segment>
      </div>
    );
  }
}

class TimeStatsOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      percentMode: false,
      splitMode: 'byMonth',
      itemType: 'client',
      filter: {},
    };
  }
  handleToggleItemType = () => {
    const itemType = this.state.itemType;
    this.setState({
      itemType: itemType === 'client' ? 'offer' : 'client',
    });
  };
  handleToggleMedianDelayMode = () => {
    const medianDelayMode = this.state.medianDelayMode;
    this.setState({
      medianDelayMode: !medianDelayMode,
    });
  };
  handleTogglePercentMode = () => {
    const percentMode = this.state.percentMode;
    this.setState({
      percentMode: !percentMode,
    });
  };
  handleToggleSplitMode = () => {
    const splitMode = this.state.splitMode;
    this.setState({
      splitMode: splitMode === 'byQuarter' ? 'byMonth' : 'byQuarter',
    });
  };
  handleUpdateFilter(toggle) {
    const currentFilter = this.state.filter || {};
    if (toggle.type === 'type') {
      this.setState({
        filter: {
          ...currentFilter,
          rejectTypes: {
            ...currentFilter.rejectTypes,
            [toggle.actionType]: !(currentFilter.rejectTypes || {})[
              toggle.actionType
            ],
          },
        },
      });
    }
    if (toggle.type === 'user') {
      this.setState({
        filter: {
          ...currentFilter,
          rejectUsers: {
            ...currentFilter.rejectUsers,
            [toggle.user]: !(currentFilter.rejectUsers || {})[toggle.user],
          },
        },
      });
    }
  }
  renderFiltersSelector(allTypes, allUsers) {
    const rejectTypes = (this.state.filter || {}).rejectTypes || {};
    const rejectUsers = (this.state.filter || {}).rejectUsers || {};
    const buttonStyle = {
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 3,
      marginRight: 5,
    };
    return (
      <div>
        {_.map(allTypes, (type) => (
          <Button
            style={buttonStyle}
            color={rejectTypes[type] ? 'silver' : 'olive'}
            onClick={() =>
              this.handleUpdateFilter({ type: 'type', actionType: type })
            }
          >
            {type}
          </Button>
        ))}
        <br />
        {_.map(allUsers, (user) => (
          <Button
            style={buttonStyle}
            color={rejectUsers[user] ? 'silver' : 'olive'}
            onClick={() => this.handleUpdateFilter({ type: 'user', user })}
          >
            {user}
          </Button>
        ))}
      </div>
    );
  }
  render() {
    const { offerStats, clientStats } = this.props;
    const { filter } = this.state;

    if (!offerStats || !clientStats) {
      return <h1>No stats</h1>;
    }

    const stats = this.state.itemType === 'offer' ? offerStats : clientStats;

    console.log(stats);

    const { slots } = stats[this.state.splitMode];

    const funnelEventTypes = _.filter(
      ['onboarding', '1st-send', '10th-send', '20th-send', '50th-send', 'none'],
      (type) => !(filter.rejectTypes || {})[type],
    );

    const data = _.map(slots, ({ title, byUser, total }) => {
      const result = {
        name: title,
      };
      for (let iType = funnelEventTypes.length - 2; iType >= 0; iType--) {
        const type = funnelEventTypes[iType];
        const nextType = funnelEventTypes[iType + 1];
        const nbEvents = (total[type] || {}).count || 0;
        const nbNextEvents = (total[nextType] || {}).count || 0;
        const key = '[total] ' + type;
        if (this.state.medianDelayMode) {
          result[key] = (total[type] || {}).medianDelay || 0;
        } else {
          result[key] = nbEvents - nbNextEvents;
          if (this.state.percentMode) {
            result[key] =
              (100 * result[key]) / ((total['onboarding'] || {}).count || 1);
          }
        }
      }
      _.each(byUser, (userStats, user) => {
        for (let iType = funnelEventTypes.length - 2; iType >= 0; iType--) {
          const type = funnelEventTypes[iType];
          const nextType = funnelEventTypes[iType + 1];
          const nbEvents = (userStats[type] || {}).count || 0;
          const nbNextEvents = (userStats[nextType] || {}).count || 0;
          const key = '[' + user + '] ' + type;
          if (this.state.medianDelayMode) {
            result[key] = (userStats[type] || {}).medianDelay || 0;
          } else {
            result[key] = nbEvents - nbNextEvents;
            if (this.state.percentMode) {
              result[key] =
                (100 * result[key]) /
                ((userStats['onboarding'] || {}).count || 1);
            }
          }
        }
      });

      return result;
    });

    const userKeys = [
      'total',
      ..._.uniq(_.flatten(_.map(slots, ({ byUser }) => _.keys(byUser)))),
    ];

    const allTypes = ['1st-send', '10th-send', '20th-send', '50th-send'];
    const selectedUserKeys = _.filter(
      userKeys,
      (userKey) => !(filter.rejectUsers || {})[userKey],
    );

    return (
      <center>
        <h2>Overview</h2>
        <div style={{ width: 800 }}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={1} textAlign="right" style={{ padding: 0 }}>
                Client
              </Grid.Column>
              <Grid.Column width={1} textAlign="center" style={{ padding: 0 }}>
                <Checkbox toggle onChange={this.handleToggleItemType} />
              </Grid.Column>
              <Grid.Column width={1} textAlign="left" style={{ padding: 0 }}>
                Offer
              </Grid.Column>

              <Grid.Column width={1} textAlign="right" style={{ padding: 0 }}>
                MoM
              </Grid.Column>
              <Grid.Column width={1} textAlign="center" style={{ padding: 0 }}>
                <Checkbox toggle onChange={this.handleToggleSplitMode} />
              </Grid.Column>
              <Grid.Column width={2} textAlign="left" style={{ padding: 0 }}>
                QoQ
              </Grid.Column>

              <Grid.Column width={1} textAlign="center" style={{ padding: 0 }}>
                <Checkbox toggle onChange={this.handleTogglePercentMode} />
              </Grid.Column>
              <Grid.Column width={2} textAlign="left" style={{ padding: 0 }}>
                Percent
              </Grid.Column>

              <Grid.Column width={1} textAlign="center" style={{ padding: 0 }}>
                <Checkbox toggle onChange={this.handleToggleMedianDelayMode} />
              </Grid.Column>
              <Grid.Column width={2} textAlign="left" style={{ padding: 0 }}>
                Median Delay
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {this.renderFiltersSelector(allTypes, userKeys)}
        <BarChart
          data={data}
          width={600}
          height={300}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {_.map(selectedUserKeys, (userKey) => [
            <Bar
              dataKey={'[' + userKey + '] onboarding'}
              stackId={userKey}
              fill="blue"
            />,
            <Bar
              dataKey={'[' + userKey + '] 1st-send'}
              stackId={userKey}
              fill="olive"
            />,
            <Bar
              dataKey={'[' + userKey + '] 10th-send'}
              stackId={userKey}
              fill="green"
            />,
            <Bar
              dataKey={'[' + userKey + '] 20th-send'}
              stackId={userKey}
              fill="lime"
            />,
            <Bar
              dataKey={'[' + userKey + '] 50th-send'}
              stackId={userKey}
              fill="yellow"
            />,
          ])}
        </BarChart>
      </center>
    );
  }
}

class TimeStatsKPIView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      percentMode: false,
      timeSplit: 'byMonth',
      itemType: 'leadgen-offers',
      filter: {},
      targetKPI: 'onboarding',
    };
  }
  handleChangeItemType = (newItemType) => {
    this.setState({
      itemType: newItemType,
    });
  };
  handleChangeTimeSplit = (newTimeSplit) => {
    this.setState({
      timeSplit: newTimeSplit,
    });
  };
  handleChangeTargetKPI = (newTargetKPI) => {
    this.setState({
      targetKPI: newTargetKPI,
    });
  };
  handleChangePercentMode = (newValue) => {
    this.setState({
      percentMode: newValue,
    });
  };
  handleUpdateFilter(toggle) {
    const currentFilter = this.state.filter || {};
    if (toggle.type === 'user') {
      this.setState({
        filter: {
          ...currentFilter,
          rejectUsers: {
            ...currentFilter.rejectUsers,
            [toggle.user]: !(currentFilter.rejectUsers || {})[toggle.user],
          },
        },
      });
    }
  }
  renderItemTypeSelector() {
    const itemType = this.state.itemType;
    const color = (id) => (id === itemType ? 'green' : 'grey');
    const onClick = (id) => () => this.handleChangeItemType(id);
    return (
      <Button.Group>
        <Button onClick={onClick('client')} color={color('client')}>
          Client
        </Button>
        <Button onClick={onClick('all-offers')} color={color('all-offers')}>
          All Offers
        </Button>
        <Button onClick={onClick('watch-offers')} color={color('watch-offers')}>
          Watch Offers
        </Button>
        <Button
          onClick={onClick('leadgen-offers')}
          color={color('leadgen-offers')}
        >
          Lead-Gen Offers
        </Button>
      </Button.Group>
    );
  }
  renderTimeSplitSelector() {
    const timeSplit = this.state.timeSplit;
    const color = (id) => (id === timeSplit ? 'green' : 'grey');
    const onClick = (id) => () => this.handleChangeTimeSplit(id);
    return (
      <Button.Group>
        <Button onClick={onClick('byWeek')} color={color('byWeek')}>
          Week
        </Button>
        <Button onClick={onClick('byMonth')} color={color('byMonth')}>
          Month
        </Button>
        <Button onClick={onClick('byQuarter')} color={color('byQuarter')}>
          Quarter
        </Button>
      </Button.Group>
    );
  }
  renderKPISelector() {
    const targetKPI = this.state.targetKPI;
    const color = (id) => (id === targetKPI ? 'green' : 'silver');
    const onClick = (id) => () => this.handleChangeTargetKPI(id);
    return (
      <div>
        <div>
          <Button.Group>
            <Button onClick={onClick('onboarding')} color={color('onboarding')}>
              #onboarding
            </Button>
            <Button onClick={onClick('1st-send')} color={color('1st-send')}>
              #1st-send
            </Button>
            <Button onClick={onClick('10th-send')} color={color('10th-send')}>
              #10th-send
            </Button>
            <Button onClick={onClick('20th-send')} color={color('20th-send')}>
              #20th-send
            </Button>
            <Button onClick={onClick('50th-send')} color={color('50th-send')}>
              #50th-send
            </Button>
          </Button.Group>
        </div>
        <div style={{ marginTop: 2 }}>
          <Button.Group>
            <Button
              onClick={onClick('md__1st-send')}
              color={color('md__1st-send')}
            >
              [median-delay]1st-send
            </Button>
            <Button
              onClick={onClick('md__10th-send')}
              color={color('md__10th-send')}
            >
              [median-delay]10th-send
            </Button>
            <Button
              onClick={onClick('md__20th-send')}
              color={color('md__20th-send')}
            >
              [median-delay]20th-send
            </Button>
            <Button
              onClick={onClick('md__50th-send')}
              color={color('md__50th-send')}
            >
              [median-delay]50th-send
            </Button>
          </Button.Group>
        </div>
        <div style={{ marginTop: 2 }}>
          <Button.Group>
            <Button
              onClick={onClick('conv__1st-send')}
              color={color('conv__1st-send')}
            >
              [conv]1st-send
            </Button>
            <Button
              onClick={onClick('conv__10th-send')}
              color={color('conv__10th-send')}
            >
              [conv]10th-send
            </Button>
            <Button
              onClick={onClick('conv__20th-send')}
              color={color('conv__20th-send')}
            >
              [conv]20th-send
            </Button>
            <Button
              onClick={onClick('conv__50th-send')}
              color={color('conv__50th-send')}
            >
              [conv]50th-send
            </Button>
          </Button.Group>
        </div>
      </div>
    );
  }
  renderPercentModeSelector() {
    const percentMode = this.state.percentMode;
    const color = (id) => (id === percentMode ? 'green' : 'gey');
    const onClick = (id) => () => this.handleChangePercentMode(id);
    return (
      <div style={{ marginTop: 3 }}>
        <Button.Group>
          <Button onClick={onClick(false)} color={color(false)}>
            Count
          </Button>
          <Button onClick={onClick(true)} color={color(true)}>
            Percent
          </Button>
        </Button.Group>
      </div>
    );
  }
  renderFiltersSelector(allUsers) {
    const rejectUsers = (this.state.filter || {}).rejectUsers || {};
    const buttonStyle = {
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 3,
      marginRight: 5,
    };
    return (
      <div style={{ marginTop: 3 }}>
        {_.map(allUsers, (user) => (
          <Button
            style={buttonStyle}
            color={rejectUsers[user] ? 'silver' : 'olive'}
            onClick={() => this.handleUpdateFilter({ type: 'user', user })}
          >
            {user}
          </Button>
        ))}
      </div>
    );
  }
  render() {
    const {
      clientStats,
      offerStats,
      watchOfferStats,
      leadgenOfferStats,
    } = this.props;
    const { filter } = this.state;

    const stats =
      this.state.itemType === 'client'
        ? clientStats
        : this.state.itemType === 'all-offers'
        ? offerStats
        : this.state.itemType === 'watch-offers'
        ? watchOfferStats
        : this.state.itemType === 'leadgen-offers'
        ? leadgenOfferStats
        : null;

    if (!stats) {
      return <h1>No stats</h1>;
    }

    const { slots } = stats[this.state.timeSplit];

    const targetKPIs = {
      onboarding: {
        type: 'count',
      },
      '1st-send': {
        type: 'count',
      },
      '10th-send': {
        type: 'count',
      },
      '20th-send': {
        type: 'count',
      },
      '30th-send': {
        type: 'count',
      },
      '50th-send': {
        type: 'count',
      },
      'md__1st-send': {
        type: 'median-delay',
      },
      'md__10th-send': {
        type: 'median-delay',
      },
      'md__20th-send': {
        type: 'median-delay',
      },
      'md__30th-send': {
        type: 'median-delay',
      },
      'md__50th-send': {
        type: 'median-delay',
      },
      'conv__1st-send': {
        type: 'conversion',
      },
      'conv__10th-send': {
        type: 'conversion',
      },
      'conv__20th-send': {
        type: 'conversion',
      },
      'conv__30th-send': {
        type: 'conversion',
      },
      'conv__50th-send': {
        type: 'conversion',
      },
    };

    const KPI = targetKPIs[this.state.targetKPI];

    const userKeys = [
      ..._.uniq([
        'undefined',
        ..._.flatten(_.map(slots, ({ byUser }) => _.keys(byUser))),
      ]).reverse(),
    ];
    const selectedUserKeys = _.filter(
      userKeys,
      (userKey) => !(filter.rejectUsers || {})[userKey],
    );

    const data = _.map(slots, ({ title, byUser }) => {
      const result = {
        name: title,
      };
      if (KPI.type === 'count') {
        let total = 0;
        _.each(byUser, (userStats, user) => {
          const nbEvents = (userStats[this.state.targetKPI] || {}).count || 0;
          const key = selectedUserKeys.indexOf(user) >= 0 ? user : 'other';
          result[key] = (result[key] || 0) + nbEvents;
          total += nbEvents;
        });
        if (this.state.percentMode) {
          let setToPercentMode = {};
          _.each(byUser, (userStats, user) => {
            const key = selectedUserKeys.indexOf(user) >= 0 ? user : 'other';
            if (!setToPercentMode[key]) {
              setToPercentMode[key] = true;
              const ratio = result[key] / (total || 1);
              result[key] = Math.round(10000 * ratio) / 100;
            }
          });
        }
      } else if (KPI.type === 'median-delay') {
        const stepKey = this.state.targetKPI.replace('md__', '');
        _.each(byUser, (userStats, user) => {
          const medianDelay = (userStats[stepKey] || {}).medianDelay || 0;
          const key = selectedUserKeys.indexOf(user) >= 0 ? user : 'other';
          if (key !== 'other') {
            result[key] = medianDelay;
          }
        });
        _.each(selectedUserKeys, (user) => {
          if (!result[user]) {
            result[user] = 0;
          }
        });
      } else if (KPI.type === 'conversion') {
        const stepKey = this.state.targetKPI.replace('conv__', '');
        _.each(byUser, (userStats, user) => {
          const nbEvents = (userStats[stepKey] || {}).count || 0;
          const nbOnboardingEvents = (userStats['onboarding'] || {}).count || 0;
          const ratio = nbEvents / (nbOnboardingEvents || 1);
          const key = selectedUserKeys.indexOf(user) >= 0 ? user : 'other';
          if (key !== 'other') {
            result[key] = Math.round(10000 * ratio) / 100;
          }
        });
        _.each(selectedUserKeys, (user) => {
          if (!result[user]) {
            result[user] = 0;
          }
        });
      }
      return result;
    });

    return (
      <center>
        <h2>KPI</h2>
        <div>
          {this.renderItemTypeSelector()}
          <div style={{ display: 'inline-block', width: 50 }} />
          {this.renderTimeSplitSelector()}
        </div>
        {this.renderFiltersSelector(userKeys)}
        {this.renderKPISelector()}
        {KPI && KPI.type === 'count' && this.renderPercentModeSelector()}
        {KPI &&
          KPI.type === 'count' &&
          (this.state.percentMode ? (
            <BarChart
              data={data}
              width={600}
              height={300}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              interval={0}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 100]} allowDataOverflow={true} />
              <Tooltip />
              {_.map(selectedUserKeys, (userKey, index) => [
                <Bar
                  dataKey={userKey}
                  stackId="0"
                  fill={getColorFromUsername(userKey)}
                />,
              ])}
              <Bar dataKey="other" stackId="0" fill="black" />,
            </BarChart>
          ) : (
            <BarChart
              data={data}
              width={600}
              height={300}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              interval={0}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {_.map(selectedUserKeys, (userKey, index) => [
                <Bar
                  dataKey={userKey}
                  stackId="0"
                  fill={getColorFromUsername(userKey)}
                />,
              ])}
              <Bar dataKey="other" stackId="0" fill="black" />,
            </BarChart>
          ))}
        {KPI && KPI.type === 'median-delay' && (
          <LineChart
            data={data}
            width={600}
            height={300}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            interval={0}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, 30]} allowDataOverflow={true} />
            <Tooltip />
            {_.map(selectedUserKeys, (userKey, index) => [
              <Line
                type="monotone"
                dataKey={userKey}
                stackId="0"
                stroke={getColorFromUsername(userKey)}
              />,
            ])}
          </LineChart>
        )}
        {KPI && KPI.type === 'conversion' && (
          <LineChart
            data={data}
            width={600}
            height={300}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, 100]} allowDataOverflow={true} />
            <Tooltip />
            {_.map(selectedUserKeys, (userKey, index) => [
              <Line
                key={index}
                type="monotone"
                dataKey={userKey}
                stackId="0"
                stroke={getColorFromUsername(userKey)}
              />,
            ])}
          </LineChart>
        )}
      </center>
    );
  }
}

const buildSlotStats = (events, slot) => {
  const byItemId = {};
  _.each(events, ({ day, type, itemId, salesOwner }) => {
    if (type === 'onboarding' && day >= slot.beginDay && day < slot.endDay) {
      byItemId[itemId] = {
        onboardingDay: day,
        salesOwner,
      };
    }
  });

  const byType = {};
  const byUserAndType = {};
  _.each(events, ({ day, type, itemId, salesOwner }) => {
    if (!byItemId[itemId]) {
      return;
    }
    const delayInMs =
      new Date('20' + day).getTime() -
      new Date('20' + byItemId[itemId].onboardingDay).getTime();
    if (delayInMs < 0) {
      console.log('ERROR');
      console.log({ day, type, itemId, salesOwner });
      console.log(byItemId[itemId].onboardingDay);
    }
    const delayInDays = Math.round(delayInMs / (24 * 3600 * 1000));

    // total stat
    if (!byType[type]) {
      byType[type] = {
        count: 0,
        delaysInDays: [],
      };
    }
    byType[type].count += 1;
    byType[type].delaysInDays.push(delayInDays);

    // salesOwner stat
    const user = salesOwner || 'undefined';
    if (!byUserAndType[user]) {
      byUserAndType[user] = {};
    }
    if (!byUserAndType[user][type]) {
      byUserAndType[user][type] = {
        count: 0,
        delaysInDays: [],
      };
    }
    byUserAndType[user][type].count += 1;
    byUserAndType[user][type].delaysInDays.push(delayInDays);
  });

  // build stats
  const median = (values, defaultValue) => {
    if (_.isEmpty(values)) {
      return defaultValue;
    }
    const sortedValues = _.sortBy(values);
    const N = sortedValues.length;
    if (N % 2 === 0) {
      return 0.5 * (sortedValues[(N - 2) / 2] + sortedValues[N / 2]);
    } else {
      return sortedValues[(N - 1) / 2];
    }
  };

  const percent = (ratio) => {
    return Math.round(ratio * 10000) / 100;
  };

  const total = {};
  _.each(byType, (typeStats, type) => {
    total[type] = {
      count: typeStats.count,
      percent: percent(
        typeStats.count / ((byType['onboarding'] || {}).count || 1),
      ),
      medianDelay: median(typeStats.delaysInDays, undefined),
    };
  });

  const byUser = {};
  _.each(byUserAndType, (statsByType, user) => {
    byUser[user] = {};
    _.each(statsByType, (typeStats, type) => {
      byUser[user][type] = {
        count: typeStats.count,
        percent: percent(
          typeStats.count / ((statsByType['onboarding'] || {}).count || 1),
        ),
        medianDelay: median(typeStats.delaysInDays, undefined),
      };
    });
  });

  return {
    ...slot,
    byUser,
    total,
  };
};

const buildStats = (events) => {
  const today = new Date().toISOString().slice(2, 10);
  const slotFilter = (slots) =>
    _.filter(slots, ({ beginDay }) => beginDay <= today);

  const weekSlots = slotFilter(
    _.map(_.range(200), (iWeek) => {
      const monday4June2018Timestamp = 1528074024000;
      const nbMsInADay = 24 * 3600 * 1000;
      const mondayTimestamp = monday4June2018Timestamp + iWeek * 7 * nbMsInADay;
      const beginDay = new Date(mondayTimestamp).toISOString().slice(2, 10);
      const endDay = new Date(mondayTimestamp + 7 * nbMsInADay)
        .toISOString()
        .slice(2, 10);
      return { beginDay, endDay, title: 'W: ' + beginDay };
    }),
  );

  const quarterSlots = slotFilter([
    { beginDay: '18-07-01', endDay: '18-10-01', title: 'Q3 2018' },
    { beginDay: '18-10-01', endDay: '19-01-01', title: 'Q4 2018' },

    { beginDay: '19-01-01', endDay: '19-04-01', title: 'Q1 2019' },
    { beginDay: '19-04-01', endDay: '19-07-01', title: 'Q2 2019' },
    { beginDay: '19-07-01', endDay: '19-10-01', title: 'Q3 2019' },
    { beginDay: '19-10-01', endDay: '20-01-01', title: 'Q4 2019' },

    { beginDay: '20-01-01', endDay: '20-04-01', title: 'Q1 2020' },
    { beginDay: '20-04-01', endDay: '20-07-01', title: 'Q2 2020' },
    { beginDay: '20-07-01', endDay: '20-10-01', title: 'Q3 2020' },
    { beginDay: '20-10-01', endDay: '21-01-01', title: 'Q4 2020' },

    { beginDay: '21-01-01', endDay: '21-04-01', title: 'Q1 2021' },
    { beginDay: '21-04-01', endDay: '21-07-01', title: 'Q2 2021' },
    { beginDay: '21-07-01', endDay: '21-10-01', title: 'Q3 2021' },
    { beginDay: '21-10-01', endDay: '22-01-01', title: 'Q4 2021' },

    { beginDay: '22-01-01', endDay: '22-04-01', title: 'Q1 2022' },
    { beginDay: '22-04-01', endDay: '22-07-01', title: 'Q2 2022' },
    { beginDay: '22-07-01', endDay: '22-10-01', title: 'Q3 2022' },
    { beginDay: '22-10-01', endDay: '23-01-01', title: 'Q4 2022' },
  ]);

  const nextMonthIds = [
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const monthSlots = slotFilter([
    { beginDay: '18-10-01', endDay: '18-11-01', title: '10-2018' },
    { beginDay: '18-11-01', endDay: '18-12-01', title: '11-2018' },
    { beginDay: '18-12-01', endDay: '19-01-01', title: '12-2018' },
    ..._.flatten(
      _.map([19, 20, 21, 22], (year) => [
        ..._.map(
          ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
          (monthId, index) => ({
            beginDay: year + '-' + monthId + '-01',
            endDay: year + '-' + nextMonthIds[index] + '-01',
            title: monthId + '-20' + year,
          }),
        ),
        {
          beginDay: year + '-12-01',
          endDay: year + 1 + '-01-01',
          title: '12-20' + year,
        },
      ]),
    ),
  ]);

  const byQuarter = {
    slots: _.map(quarterSlots, (slot) => buildSlotStats(events, slot)),
  };

  const byMonth = {
    slots: _.map(monthSlots, (slot) => buildSlotStats(events, slot)),
  };

  const byWeek = {
    slots: _.map(weekSlots, (slot) => buildSlotStats(events, slot)),
  };

  const result = {
    byQuarter,
    byMonth,
    byWeek,
  };

  return result;
};

class SalesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadData();
  }
  loadData = async () => {
    const { data } = await axios.get(
      `${baseUrl}/strat/sends/clientsAndOffersEvents`,
    );
    const { clients, offers } = data;

    let clientEvents = [];
    _.each(clients, ({ platformId, salesOwner, events, activatedPermissions }) => {
      _.each(events, (event, type) => {
        clientEvents.push({
          type,
          itemId: platformId,
          salesOwner,
          date: event.date,
          day: event.day,
          activatedPermissions,
        });
      });
    });
    clientEvents = _.sortBy(clientEvents, 'date');

    let offerEvents = [];
    _.each(offers, ({ platformId, salesOwner, events, activatedPermissions }) => {
      _.each(events, (event, type) => {
        offerEvents.push({
          type,
          itemId: platformId,
          salesOwner,
          date: event.date,
          day: event.day,
          activatedPermissions,
        });
      });
    });
    offerEvents = _.sortBy(offerEvents, 'date');

    const watchOfferEvents = _.filter(
      offerEvents,
      ({ itemId }) => itemId.indexOf('hiresweet-watch') >= 0,
    );
    const leadgenOfferEvents = _.filter(
      offerEvents,
      ({ itemId }) => itemId.indexOf('hiresweet-watch') < 0,
    );

    const clientStats = buildStats(clientEvents);
    const offerStats = buildStats(offerEvents);
    const watchOfferStats = buildStats(watchOfferEvents);
    const leadgenOfferStats = buildStats(leadgenOfferEvents);

    console.log('data');
    console.log({
      clients,
      clientEvents,
      clientStats,
      offers,
      offerEvents,
      offerStats,
      watchOfferEvents,
      watchOfferStats,
      leadgenOfferEvents,
      leadgenOfferStats,
    });

    this.setState({
      clients,
      offers,
      clientEvents,
      offerEvents,
      clientStats,
      offerStats,
      watchOfferEvents,
      watchOfferStats,
      leadgenOfferEvents,
      leadgenOfferStats,
    });
  };
  renderClientEvents() {
    const clientEvents = this.state.clientEvents || [];
    console.log('nb', clientEvents.length);
    const eventsToDisplay = _.filter(
      clientEvents,
      ({ day }) => day > minDay,
    ).reverse();
    return <CustomerEvents title={'Client Events'} events={eventsToDisplay} />;
  }
  renderOfferEvents() {
    const offerEvents = this.state.offerEvents || [];
    const eventsToDisplay = _.filter(
      offerEvents,
      ({ day }) => day > minDay,
    ).reverse();
    return <CustomerEvents title={'Offer Events'} events={eventsToDisplay} />;
  }
  render() {
    const { clients, offers } = this.state;
    if (!clients || !offers) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <h1>Sales Dashboard</h1>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <TimeStatsKPIView
                clientStats={this.state.clientStats}
                offerStats={this.state.offerStats}
                watchOfferStats={this.state.watchOfferStats}
                leadgenOfferStats={this.state.leadgenOfferStats}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <TimeStatsOverview
                clientStats={this.state.clientStats}
                offerStats={this.state.offerStats}
                watchOfferStats={this.state.watchOfferStats}
                leadgenOfferStats={this.state.leadgenOfferStats}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>{this.renderClientEvents()}</Grid.Column>
            <Grid.Column>{this.renderOfferEvents()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default SalesDashboard;
