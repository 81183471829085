import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import React, { Component } from 'react';

import EnrichedText from '../SweetWork/components/EnrichedText';
import { EnrichedText as EnrichedText_ } from '../SweetWork/profileData';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class TextFunctionInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
    };
    this.handleClickOnEdit = this.handleClickOnEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  async handleClickOnEdit() {
    this.setState({ editable: !this.state.editable });
  }
  async handleCancel() {
    this.setState({ editable: false });
  }

  async handleSubmit() {
    this.setState({ editable: false });
    const { value } = this.props;
    this.props.onSubmit(value);
  }

  async customHandleSubmit(value) {
    this.setState({ editable: false });
    //this.state.value = value
    this.props.onSubmit(value);
  }

  async handleTextChange(value) {
    this.props.handleTextChange(value);
  }

  handleClickOnSample = async (textType) => {
    const { propertyTest, samplingBase } = this.props;

    let url = baseUrl + '/techAsset/randomText';
    if (textType === 'headline') {
      url = baseUrl + '/techAsset/randomHeadline';
    }
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const sampleWithProperty =
      ((propertyTest || {}).property || {}).constraints ||
      (samplingBase && samplingBase.samplingMode !== 'all-profiles');
    if (!sampleWithProperty) {
      const randomText = (await axios.get(url, customHeaders)).data;
      this.customHandleSubmit(randomText);
    } else {
      const payload = { test: propertyTest, samplingBase };
      const randomText = (await axios.post(url + 'WithProperty', payload, customHeaders)).data;
      if (!randomText || randomText.success === false) {
        alert('No Text found with this property while sampling 1000');
      }
      console.log(randomText);
      this.customHandleSubmit(randomText);
    }
  };

  handlePatternChange = async (value) => {
    this.setState({
      pattern: value,
    });
  };

  handleClickOnSampleMatchAnywhere = async () => {
    const { propertyTest, samplingBase } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/matchAnywhereSummary';
    const pattern = (this.state || {}).pattern || 'coffee';
    const payload = { pattern: pattern, test: propertyTest, samplingBase };
    const randomSummary = (await axios.post(url, payload, customHeaders)).data;
    if (!randomSummary || randomSummary.success === false) {
      alert('No Summary found with pattern ' + pattern);
    }
    console.log(randomSummary);
    this.customHandleSubmit(randomSummary);
  };

  render() {
    const { value } = this.props;
    return (
      <div>
        {this.state.editable ? null : (
          <div>
            <Grid columns={4}>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={this.handleClickOnEdit}>
                  Edit Text
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSample('summary')}>
                  Sample Text (summary)
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSample('headline')}>
                  Sample Text (headline)
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSampleMatchAnywhere()}>
                  Match Anywhere (summary)
                </a>
                <Form.TextArea
                  style={{ width: '140px', height: '15px' }}
                  value={(this.state || {}).pattern || ''}
                  onChange={(e, { value }) => {
                    this.handlePatternChange(value);
                  }}
                />
              </Grid.Column>
            </Grid>
            <br />
            <EnrichedText
              text={(this.props.enrichedEntity || {}).text}
              enrichments={EnrichedText_(this.props.enrichedEntity, () => null).getEnrichments()}
            />
          </div>
        )}
        {this.state.editable ? (
          <Modal
            onClose={this.handleCancel}
            open
            closeIcon
            size='fullscreen'
            headerText={`Edit text`}
            submitText='Save'
          >
            <Modal.Content>
              <Form.TextArea
                style={{ width: '90%', height: '100%' }}
                value={value}
                rows={20}
                onChange={(e, { value }) => {
                  this.handleTextChange(value);
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' onClick={this.handleSubmit}>
                Save
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default TextFunctionInput;
