import axios from 'axios';
import React from 'react';
import _ from 'underscore';

import { Card, Grid } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

export const getJobs = async () => {
  const url = baseUrl + '/cerejobsV2/getJobs';
  const jobs = (await axios.get(url)).data;
  return jobs;
};

export const getMainJobsCategories = async () => {
  const url = baseUrl + '/cerejobsV2/getMainJobsCategories';
  const jobsCategories = (await axios.get(url)).data;
  return jobsCategories;
};

export const getLevel2CategoryIds = (mainJobsCategoryIds, jobs) => {
  const level2Categories = _.filter(jobs, (job) => (job.groups || []).some((e) => mainJobsCategoryIds.includes(e.id)));
  const level2CategoryIds = _.map(level2Categories, (level2Category) => level2Category.id);
  return level2CategoryIds;
};

class JobsCategoriesDashboard extends React.Component {
  componentDidMount() {
    this.handleJobsCategories();
  }

  getNumberOfJobsPerCategory(jobsCategoryId, jobs) {
    const level2JobsInCategoryIds = _.map(
      _.filter(jobs, (job) => (job.groups || []).some((e) => e.id === jobsCategoryId)),
      (level2JobsInCategory) => level2JobsInCategory.id,
    );
    const jobsInCategory = _.filter(jobs, (job) =>
      (job.groups || []).some((e) => level2JobsInCategoryIds.includes(e.id)),
    );
    return jobsInCategory.length;
  }

  getNumberOfJobsWithoutCategory = (jobs, mainJobsCategoryIds) => {
    const level2CategoryIds = getLevel2CategoryIds(mainJobsCategoryIds, jobs);
    const jobsFilteredMainCategories = _.filter(jobs, (job) => !mainJobsCategoryIds.includes(job.id));
    const jobsFilteredAllCategories = _.filter(
      jobsFilteredMainCategories,
      (job) => !level2CategoryIds.includes(job.id),
    );
    const filteredJobs = _.filter(
      jobsFilteredAllCategories,
      (job) => !(job.groups || []).some((e) => level2CategoryIds.includes(e.id)),
    );
    return (filteredJobs || []).length;
  };

  async handleJobsCategories() {
    const jobs = await getJobs();
    const mainJobsCategories = await getMainJobsCategories();
    const mainJobsCategoryIds = _.map(mainJobsCategories, (jobsCategory) => jobsCategory.id);
    const jobsCategories = _.filter(jobs, (job) => mainJobsCategoryIds.includes(job.id));
    const jobsCategoryToNumberOfJobs = {};
    mainJobsCategoryIds.forEach((catId) => {
      jobsCategoryToNumberOfJobs[catId] = this.getNumberOfJobsPerCategory(catId, jobs);
    });
    const numberOfJobsWithoutCategory = this.getNumberOfJobsWithoutCategory(jobs, mainJobsCategoryIds);
    this.setState({ jobsCategories, jobsCategoryToNumberOfJobs, numberOfJobsWithoutCategory });
  }

  render() {
    const { jobsCategories, jobsCategoryToNumberOfJobs, numberOfJobsWithoutCategory } = this.state || {};

    if (!jobsCategories) {
      return <div />;
    }

    const nbColumns = 2;
    const jobsCategoriesRows = [[]];
    _.each(jobsCategories, (jobsCategory) => {
      if (_.last(jobsCategoriesRows).length === nbColumns) {
        jobsCategoriesRows.push([]);
      }
      _.last(jobsCategoriesRows).push(jobsCategory);
    });

    return (
      <Grid>
        {_.map(jobsCategoriesRows, (jobCategoriesRow, index) => (
          <Grid.Row key={index} columns={nbColumns}>
            {_.map(jobCategoriesRow, (jobCategory, index) => (
              <Grid.Column key={index}>
                <a href={'/assets-explorer/jobs-categories/' + jobCategory.id}>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>{jobCategory.name}</Card.Header>
                      <Card.Meta>
                        <span>{jobCategory.id}</span>
                      </Card.Meta>
                      <Card.Meta>
                        <span>Number of jobs: {jobsCategoryToNumberOfJobs[jobCategory.id]}</span>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </a>
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
        <ColoredLine color='grey' />
        <Grid.Row columns={2}>
          <Grid.Column>
            <a href={'/assets-explorer/jobs-categories/no-category'}>
              <Card fluid>
                <Card.Content>
                  <Card.Header>{'Non-categorized jobs'}</Card.Header>
                  <Card.Meta>
                    <span>Number of jobs: {numberOfJobsWithoutCategory}</span>
                  </Card.Meta>
                </Card.Content>
              </Card>
            </a>
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>
    );
  }
}

export default JobsCategoriesDashboard;
