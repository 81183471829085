import jwtDecode from 'jwt-decode';

const decodeToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (e) {
    return null;
  }
};

export const saveJsonWebToken = (jwtoken) => {
  localStorage.setItem('token', jwtoken);
};

export const getEncodedJsonWebToken = () => {
  return localStorage.getItem('token');
};

export const getDecodedJsonWebToken = () => {
  const token = localStorage.getItem('token');
  return token ? decodeToken(token) : token;
};

export const removeJsonWebToken = () => {
  localStorage.removeItem('token');
};
