import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import {
  Segment,
  Header,
  Message
} from 'semantic-ui-react';
import baseUrl from '../baseUrl';

class AllWarnings extends React.PureComponent {
  state = {}
  componentWillMount() {
    axios.get(baseUrl + '/station/getAutomaticMailsWarnings').then(({ data }) => {
      this.setState({
        warnings: data.warnings,
      });
    });
  }
  render() {
    const { warnings } = this.state;
    if (!warnings) {
      return <div />
    }
    const byCategory = {}
    _.each(warnings, (warning) => {
      const category = (warning || {}).category
      if (byCategory[category] === undefined) {
        byCategory[category] = []
      }
      byCategory[category].push(warning);
    });

    return (
      <div>
        {_.map(byCategory, (warnings, category) => (
          <Message error key={category}>
            <Message.Header>{category}:</Message.Header>
            {_.map(_.sortBy(warnings, 'title').reverse(), ({ title }) => (
              <Message.Content><span style={{ marginLeft: 5}}></span>- {title}</Message.Content>
            ))}
          </Message>
          
        ))}
      </div>
    )
  }
}

class SalesWarnings extends React.PureComponent {
  state = {};

  computeWarnings = () => {
    const { clientEvents, targetUser } = this.props;

    const hasOnboardingInRange = {};
    const has1stSendInRange = {};
    const has1stSend = {};
    const has10thSend = {};

    const fourWeeksAgoDay = new Date(Date.now() - 28 * 24 * 3600 * 1000)
      .toISOString()
      .slice(2, 10);
    // const twoWeeksAgoDay = new Date(Date.now() - 14 * 24 * 3600 * 1000)
    //   .toISOString()
    //   .slice(2, 10);
    const oneWeekAgoDay = new Date(Date.now() - 7 * 24 * 3600 * 1000)
      .toISOString()
      .slice(2, 10);

    _.each(clientEvents, ({ type, itemId, day, salesOwner }) => {
      if (targetUser && salesOwner !== targetUser) {
        return;
      }
      if (type === 'onboarding') {
        if (day >= fourWeeksAgoDay && day <= oneWeekAgoDay) {
          hasOnboardingInRange[itemId] = true;
        }
      }
      if (type === '1st-send') {
        has1stSend[itemId] = true;
        if (day >= fourWeeksAgoDay && day <= oneWeekAgoDay) {
          has1stSendInRange[itemId] = true;
        }
      }
      if (type === '10th-send') {
        has10thSend[itemId] = true;
      }
    });

    const warnings = [];

    const clientNo1stSend = [];
    _.each(_.keys(hasOnboardingInRange), (itemId) => {
      if (!has1stSend[itemId]) {
        clientNo1stSend.push({ clientId: itemId });
      }
    });
    if (!_.isEmpty(clientNo1stSend)) {
      warnings.push({
        title: 'Onboarding -> 1st send (' + clientNo1stSend.length + ')',
        text: _.map(clientNo1stSend, ({ clientId }) => clientId).join('; '),
      });
    }

    const clientNo10thSend = [];
    _.each(_.keys(has1stSendInRange), (itemId) => {
      if (!has10thSend[itemId]) {
        clientNo10thSend.push({ clientId: itemId });
      }
    });
    if (!_.isEmpty(clientNo10thSend)) {
      warnings.push({
        title: '1st send -> 10th send (' + clientNo10thSend.length + ')',
        text: _.map(clientNo10thSend, ({ clientId }) => clientId).join('; '),
      });
    }

    return warnings;
  };

  render() {
    const { clientEvents, offerEvents } = this.props;

    if (!clientEvents || !offerEvents) {
      return <div />;
    }

    const warnings = this.computeWarnings().slice(0, 20);

    return (
      <div>
        {_.map(warnings, ({ title, text }, index) => (
          <Segment key={index}>
            {title && (
              <Header as='h4' style={{ margin: '2px 0px' }}>
                {title}
              </Header>
            )}
            {text && <p>{text}</p>}
          </Segment>
        ))}
        <AllWarnings />
      </div>
    );
  }
}

export default SalesWarnings;
