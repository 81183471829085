import React from 'react';
import ClientShortlists from './ClientShortlists';
import ShortlistsDashboard from './ShortlistsDashboard';

class Shortlists extends React.Component {
  render() {
    if (this.props.match.params.clientId) {
      return <ClientShortlists clientId={this.props.match.params.clientId} />
    } else {
      return <ShortlistsDashboard />
    }

  }
}
export default Shortlists;
