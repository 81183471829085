import React from 'react';
import { Card, Popup, Icon } from 'semantic-ui-react';
import EnrichedText from '../components/EnrichedText';

const GithubRepoCard = (props) => {
  const { repository } = props;
  const name = repository.getName();
  const description = repository.getDescription();
  const stargazersCount = repository.getStargazersCount().get();
  const language = repository.getLanguage().get();
  return (
    <Card>
      <Card.Content style={{ wordWrap: 'break-word' }}>
        <Card.Header>
          <EnrichedText text={name.getText().get()} enrichments={name.getEnrichments()} />
        </Card.Header>
        <Card.Description>
          {description.getText().get() && description.getText().get().length < 60 ? (
            <EnrichedText text={description.getText().get()} enrichments={description.getEnrichments()} />
          ) : description.getText().get() ? (
            <Popup
              trigger={
                <div style={{cursor: 'pointer'}}>
                  <EnrichedText
                    text={
                      description
                        .getText()
                        .get()
                        .slice(0, 60) + '...'
                    }
                    enrichments={description.getEnrichments()}
                  />
                </div>
              }
              content={<EnrichedText text={description.getText().get()} enrichments={description.getEnrichments()} />}
            />
          ) : null}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <GithubRepoExtra stargazersCount={stargazersCount} language={language} />
      </Card.Content>
    </Card>
  );
};

const GithubRepoExtra = ({ globalHint, stargazersCount, language, languageEnriched }) => {
  // const enrichedLang = enrichedRepo ? getEnrichedTextByType(enrichedRepo, 'language') : null;
  return (
    <span>
      {language && !languageEnriched ? language : null}
      {/* {languageEnriched ? (
        <EnrichedText
          {...transformEnrichmentByHint(globalHint, languageEnriched)}
        />
      ) : null}{' '} */}
      <Icon name='star' />
      {stargazersCount || 0}
    </span>
  );
};

export default GithubRepoCard;
