import React, { Component } from 'react';
import { Grid, Button, Divider, Header, Icon } from 'semantic-ui-react';
import { SweetForm, Checkbox, enhance, List as SweetList, Input } from 'sweetform';
import {
  formWishesToSheetWishes,
  sheetWishesToFormWishes,
  WishesEditor,
  SelectExperienceYearsAtDate,
} from './WatchHiresweetData';
import {
  CustomSourceField,
  HeadlineEditor,
  SummaryEditor,
  LocationEditor,
  getPhotoLinkBySourceFromProfile,
} from '../../Offers/WorkPipeInputComponents/Upload';

const formManualDataToSheetManualData = ({ manualData }) => {
  return {
    ...manualData,
    ...(manualData.wishesForMatching && {
      wishesForMatching: formWishesToSheetWishes(manualData.wishesForMatching),
    }),
  };
};

const sheetManualDataToFormManualData = (manualData) => {
  return {
    manualData: {
      ...manualData,
      ...(manualData.wishesForMatching && {
        wishesForMatching: sheetWishesToFormWishes(manualData.wishesForMatching),
      }),
    },
  };
};

const ManualDataEditor = enhance((props) => {
  const { value } = props;
  const useWishesForMatching = value ? value.get('useWishesForMatching') : false;
  const useExperienceYearsAtDate = value ? value.get('useExperienceYearsAtDate') : false;
  const useHeadline = value ? value.get('useHeadline') : false;
  const useSummary = value ? value.get('useSummary') : false;
  const useLocation = value ? value.get('useLocation') : false;
  const usePhotoLink = value ? value.get('usePhotoLink') : false;
  const useAdditionalSources = value ? value.get('useAdditionalSources') : false;

  return (
    <div>
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='heart' />
          Wishes
        </Header>
      </Divider>

      <Grid>
        <Grid.Row>
          <p>Use Wishes (matching only)</p>
          <Checkbox field='useWishesForMatching' toggle />
        </Grid.Row>
        {useWishesForMatching && (
          <Grid.Row>
            <Button style={{ margin: '20px' }} onClick={() => props.handleCopyFromAppWishes()}>
              Copy from app
            </Button>
            <WishesEditor field='wishesForMatching' />
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
});

class WatchManualData extends Component {
  state = {};
  
  handleChange = (value) => {
    this.setState({ value });
  };

  handleSave = async () => {
    try {
      await this.props.onSubmit(formManualDataToSheetManualData(this.state.value));
      alert('Saved.');
    } catch (e) {
      alert(e.message);
    }
  };

  handleCopyFromAppWishes = async () => {
    const { appWishes } = this.props;
    const manualData = this.state.value;
    const newManualData = {
      useWishesForMatching: true,
      wishesForMatching: appWishes,
      ...(manualData && { manualData }),
    };
    await this.props.onSubmit(newManualData);
  };

  render() {
    const { manualData } = this.props;
    const formattedManualData = sheetManualDataToFormManualData(manualData || {});
    console.log('appWishes', this.props.appWishes)
    return (
      <div>
        <Button onClick={() => this.handleSave()} color='green'>
          <Icon name='save' /> Save
        </Button>
        <SweetForm initialValues={formattedManualData} value={this.state.value} onChange={this.handleChange}>
          <ManualDataEditor field='manualData' handleCopyFromAppWishes={this.handleCopyFromAppWishes} />
        </SweetForm>
      </div>
    );
  }
}

export default WatchManualData;
