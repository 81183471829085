import React from 'react';
import _ from 'underscore';
import { LineChart, Line, XAxis, YAxis, Legend, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const timestampToHuman = (ts) => {
  const d = new Date(ts * 1000);
  return d.toLocaleDateString('fr-FR', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
};

class QueueGraph extends React.PureComponent {
  render() {
    if (!this.props.queueData) {
      return <div />;
    }

    const metrics = {};

    _.forEach(this.props.queueData, (value, timestamp) => {
      if (!metrics[timestamp]) {
        metrics[timestamp] = {
          queue: 0,
        };
      }
      metrics[timestamp].queue = value;
    });

    const data = _.map(metrics, (values, timestamp) => ({
      name: timestampToHuman(timestamp),
      queue: values.queue,
    }));
    return (
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <Line type="monotone" dataKey="queue" stroke="#0000FF" dot={false} />
          <XAxis dataKey="name" />
          <Tooltip />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default QueueGraph;
