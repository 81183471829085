import { Grid, Menu } from 'semantic-ui-react';
import _ from 'underscore';
import React, { Component } from 'react';
import Playground from './Playground';
import Talents from './Talents';
import Jobs from './Jobs';
import Insights from './Insights';
import Search from './Search';
import Matches from './Matches';
import baseUrl from '../baseUrl.js';

import axios from 'axios';

class ProjectView extends Component {
  componentDidMount() {
    this.getProfileCollectionsInformation();
    this.getJobCollectionsInformation();
    this.getMatchCollectionsInformation();
  }

  handleChangeActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  getProfileCollectionsInformation = async () => {
    const { projectId } = this.props || {};
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileCollections/' + projectId;
    const profileCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ profileCollections });
  };

  getJobCollectionsInformation = async () => {
    const { projectId } = this.props || {};
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobCollections/' + projectId;
    const jobCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ jobCollections });
  };

  getMatchCollectionsInformation = async () => {
    const { projectId } = this.props || {};
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getMatchCollections/' + projectId;
    const matchCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ matchCollections });
  };

  render() {
    const { projectId, userId } = this.props;
    const tabNames = ['Talent', 'Jobs', 'Matches', 'Playground', 'Insights', '(Reverse) Search'];
    const { profileCollections, jobCollections, matchCollections } = this.state || {};
    const activeMenu = (this.state || {}).activeMenu || 'Talent';
    return (
      <Grid columns={2}>
        <Grid.Column width={2}>
          <Menu pointing secondary fluid vertical tabular>
            {_.map(tabNames, (tabName, index) => (
              <Menu.Item
                key={index}
                name={tabName}
                active={activeMenu === tabName}
                onClick={() => {
                  this.handleChangeActiveMenu(tabName);
                }}
              >
                {tabName}
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
        <Grid.Column width={14}>
          {activeMenu == 'Talent' ? (
            <Talents projectId={projectId} userId={userId} />
          ) : activeMenu == 'Jobs' ? (
            <Jobs projectId={projectId} userId={userId} />
          ) : activeMenu == 'Matches' ? (
            <Matches
              projectId={projectId}
              matchCollections={matchCollections}
              profileCollections={profileCollections}
              jobCollections={jobCollections}
              userId={userId}
            />
          ) : activeMenu == 'Insights' ? (
            <Insights projectId={projectId} profileCollections={profileCollections} userId={userId} />
          ) : activeMenu == '(Reverse) Search' ? (
            <Search
              projectId={projectId}
              profileCollections={profileCollections}
              jobCollections={jobCollections}
              userId={userId}
            />
          ) : activeMenu == 'Playground' ? (
            <Playground profileCollections={profileCollections} jobCollections={jobCollections} />
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

export default ProjectView;
