import { SingleClickButton } from '../../common';
import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import baseUrl from '../../baseUrl';
import moment from 'moment';


const getCandidateNotifications = async (id) => {
  const result = (await axios.get(`${baseUrl}/candidates/${id}/notifications`)).data;
  if (result.error) {
    throw Error(result.error);
  }
  return (result || {}).notifications
};

const getCandidateNotificationParams = async ({ id, notificationId }) => {
  const result = (await axios.get(`${baseUrl}/candidates/${id}/notificationParams/${notificationId}`)).data;
  return result;
};

const sendNotification = async ({ id, notificationId, force }) => {
  const payload = { notificationId, force };
  const result = (await axios.post(`${baseUrl}/candidates/${id}/notifications`, payload)).data;
  if (result.error) {
    throw Error(result.error);
  }
  return result;
};

export class SendNotificationModal extends Component {
  state = {};

  handleLoadCandidateNotifications = async () => {
    try {
      const notifications = await getCandidateNotifications(this.props.id);
      const notificationParams = await getCandidateNotificationParams({
        id: this.props.id,
        notificationId: this.props.notificationId
      })
      if (notificationParams.type == "ignore"){
        // no email will be sent in backend
        await sendNotification({ 
          id: this.props.id,
          notificationId: this.props.notificationId
         });
        this.props.onClose();
      } else {
        this.setState({ notifications, notificationParams, loaded: true });
      }
    } catch (e) {
      alert(e);
      this.props.onClose();
    }
  };
  componentWillMount() {
    this.handleLoadCandidateNotifications();
  }
  handleSend = async ({ force }) => {
    const { id, notificationId, onClose } = this.props;
    try {
      const result = await sendNotification({ id, notificationId, force });
      alert("Email sent ! \n")
      onClose();
    } catch (e) {
      alert(e);
    }
  };
  render() {
    const { id, notificationId, onClose } = this.props;
    const { notifications, notificationParams, loaded } = this.state;
    const { lastNotification } = (notifications || {})[notificationId] || {};
    const text = !_.isEmpty(lastNotification)
      ? `Notification ${notificationId} already sent to ${id} on ${moment(lastNotification.timestamp)} : send again ?`
      : `Send mail ${notificationId} to ${id} ?`;

    let content = `NOTIFICATION PARAMS : \n${JSON.stringify(notificationParams, null, 4)}`
    if (!_.isEmpty(lastNotification)) {
      content += `\n\nPREVIOUS NOTIFICATION : \n${JSON.stringify(lastNotification, null, 4)}`
    }
    if (!loaded) {
      return (
        <Modal onClose={onClose} open closeIcon ><Modal.Content><Icon name='circle notched' loading /></Modal.Content></Modal>
      )
    }
    return (
      <Modal onClose={onClose} open closeIcon >
        <Modal.Header>{text}</Modal.Header>
        <Modal.Content><pre>{content}</pre></Modal.Content>
        <Modal.Actions>
          <SingleClickButton basic positive onClick={() => this.handleSend({ force: true })}>
            Send Force
          </SingleClickButton>
          <SingleClickButton positive onClick={() => this.handleSend({ force: false })}>
            Send
          </SingleClickButton>
          <Button negative onClick={() => onClose()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
