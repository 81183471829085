import React from 'react';
import _ from 'underscore';
import { Segment, Header, Label, List, Container, Statistic, Icon, Image, Card, Button } from 'semantic-ui-react';

import { getColorFromScrapDate } from '../../common';
import ExtLink from '../../components/ExtLink';
import { getColorFromEnrichedText } from '../../components/EnrichedText';
import GithubRepoCard from '../components/GithubRepoCard';
import { TranslatableText } from '../components/TranslatableText';
import { getLastScrapText } from '../utils';
import EnrichedText from '../components/EnrichedText';

class GithubProfile extends React.PureComponent {
  state = { moreRepos: false };
  onShowMoreRepos = () => {
    this.setState({
      moreRepos: !this.state.moreRepos,
    });
  };
  render() {
    const { profile } = this.props;
    const id = profile.getId().get();
    const login = profile.getLogin().get();
    const lastScrapDay = profile.getLastScrapDay().get();
    const fullname = profile.getFullname().get();
    const headline = profile.getHeadline().getContent(); // enrichedTextAndStack
    const companyName = profile.getCompanyName().get();
    const location = profile.getLocation().getName(); // type location
    const email = profile.getEmail().get();
    // const photoLink = profile.getPhotoLink().get();
    const repositories = profile.getRepositories().get();
    const nbFollowing = profile.getNbFollowing().get();
    const nbFollowers = profile.getNbFollowers().get();
    const sortedRepos = _.sortBy(repositories, (repository) => repository.getStargazersCount().get()).reverse();
    const nbPublicRepos = repositories.length;
    let repoLanguages = [];
    _.each(sortedRepos, (repository) => {
      if (!repository.getLanguage().get()) {
        return;
      }
      const index = _.findIndex(repoLanguages, { language: repository.getLanguage().get() });
      if (index < 0) {
        repoLanguages.push({ language: repository.getLanguage().get(), count: 1 });
      } else {
        repoLanguages[index].count++;
      }
    });
    repoLanguages = _.sortBy(repoLanguages, 'count');
    const repositoriesToShow = this.state.moreRepos ? sortedRepos : sortedRepos.slice(0, 10);

    return (
      <Segment color='green'>
        <Header size='medium' style={{ margin: 0 }}>
          <div style={{ float: 'right' }}>
            <Label style={{ marginRight: 20 }} color={getColorFromScrapDate(lastScrapDay)}>
              {getLastScrapText(lastScrapDay)}
            </Label>
            {login && (
              <ExtLink classes='source-link' to={`https://github.com/${login}`}>
                <Icon name='github' />
              </ExtLink>
            )}
            {/* {blog ? (
              <ExtLink to={blog}>
                <Icon name='external' />
              </ExtLink>
            ) : null} */}
          </div>
          {id && (
            <Image
              circular
              src={`https://avatars3.githubusercontent.com/u/${id}?s=460&v=4`}
              style={{ fontSize: '3rem' }}
            />
          )}
          <Header.Content>
            {fullname}{' '}
            <span
              style={{
                fontWeight: 'normal',
                fontSize: '11px',
                color: '#7D7D7D',
              }}
            >
              {login ? `(${login})` : null}
            </span>
            <Header.Subheader>
              <List>
                {companyName ? (
                  <List.Item>
                    <List.Icon name='group' />
                    <List.Content>
                      <span>{companyName + ' '}</span>{' '}
                      {(location || {}).default ? (
                        <span>
                          <span>{' -- '}</span>
                          <TranslatableText translatableText={location} placeholder='' />
                        </span>
                      ) : null}
                    </List.Content>{' '}
                  </List.Item>
                ) : null}
                {!companyName && (location || {}).default ? (
                  <List.Item icon='marker' content={<TranslatableText translatableText={location} placeholder='' />} />
                ) : null}
                {email ? <List.Item icon='mail' content={email} /> : null}
              </List>
              {headline.getText().get() ? (
                <Segment secondary>
                  <EnrichedText text={headline.getText().get()} enrichments={headline.getEnrichments()} />
                </Segment>
              ) : null}
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Statistic.Group widths={3} size='mini' style={{ marginTop: '10px', fontSize: '9px' }}>
          <Statistic label='Following' value={nbFollowing || 0} />
          <Statistic label='Followers' value={nbFollowers || 0} />
          <Statistic label='Repositories' value={nbPublicRepos || 0} />
        </Statistic.Group>
        <Container fluid textAlign='center'>
          <Label.Group style={{ marginTop: 5 }}>
            {_.map(_.sortBy(repoLanguages, 'count').reverse(), ({ count, language, languageEnriched }) => (
              <Label
                size={'large'}
                key={language}
                style={{
                  backgroundColor: getColorFromEnrichedText(languageEnriched),
                }}
              >
                {language}
                <Label.Detail>{count}</Label.Detail>
              </Label>
            ))}
          </Label.Group>
        </Container>
        <Card.Group itemsPerRow={5}>
          {_.map(repositoriesToShow, (repository, index) => (
            <GithubRepoCard repository={repository} key={index} />
          ))}
        </Card.Group>
        {sortedRepos.length > 10 ? (
          <Button onClick={this.onShowMoreRepos} style={{ marginTop: '10px' }}>
            {!this.state.moreRepos ? 'Show more' : 'Show less'}
          </Button>
        ) : null}
      </Segment>
    );
  }
}

export default GithubProfile;
