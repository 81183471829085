import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { SweetForm, TextArea } from 'sweetform';

class EditDataPointAdminModal extends Component {
  state = {
    admin: null,
  };

  constructor(props) {
    super(props);
    this.state.admin = props.initialValue;
  }

  handleChange = (admin) => {
    this.setState({ admin });
  };

  handleSave = () => {
    this.props.onSave({
      admin: this.state.admin,
    });
  };

  render() {
    const { initialValue, onClose } = this.props;
    console.log("initialValue", initialValue)
    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>Edit DataPoint Admin</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
            <SweetForm
              onChange={this.handleChange}
              initialValues={initialValue}
            >
              <b>Comment</b><br/>
              <TextArea
                field="comment"
                cols={50}
              />
            </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => this.handleSave()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditDataPointAdminModal;
