import _ from 'underscore';
import React, { Component } from 'react';
import { Table, Segment, Header } from 'semantic-ui-react';

export default class DashBoardSearchChart extends Component {
  render() {
    const categoryMaherPerformanceRate = _.map(this.props.stats, (stat) => ({
      ...stat,
      colour: stat.selectRate * 100 >= 50 ? '#ddffdd' : stat.selectRate * 100 >= 30 ? '#ffffdd' : '#ffdddd',
    }));
    return (
      <Segment compact>
        <Header as='h3' textAlign='center'>
          Monitoring catégories Maher
        </Header>
        <Table celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Catégorie Maher</Table.HeaderCell>
              <Table.HeaderCell>Taux de select</Table.HeaderCell>
              <Table.HeaderCell>Evolution</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(categoryMaherPerformanceRate, (rate) => (
              <Table.Row style={{ backgroundColor: rate.colour }}>
                <Table.Cell>{rate.maherCategory}</Table.Cell>
                <Table.Cell>{Math.round(rate.selectRate * 100) + ' %'}</Table.Cell>
                <Table.Cell>
                  {rate.evolution > 0
                    ? '+' + Math.round(rate.evolution * 100) + ' %'
                    : Math.round(rate.evolution * 100) + ' %'}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
