import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import { createStore } from 'redux';
import { Provider, connect } from 'react-redux';
import { Link, Switch, Route } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';

import { rootReducer, actions } from './reducers';
import Offers from './Offers';
import WorkPipe from './WorkPipe';
import WorkPipeInput from './WorkPipeInput';
import WorkPipeItems from './WorkPipeItems';
import baseUrl from '../baseUrl.js';

const store = createStore(rootReducer);

/**
 * Main Screen
 */

export default ({ match, ...props }) => (
  <Provider store={store}>
    <Switch>
      <Route
        path={`${match.path}/:id/workpipe/:wid/items`}
        render={(p) => <WorkPipeItems {...props} {...p} />}
      />
      <Route
        exact
        path={`${match.path}/:id/workpipe/:wid/:inputId`}
        render={(p) => <WorkPipeInput {...props} {...p} />}
      />
      <Route
        exact
        path={`${match.path}/:id/workpipe/:wid/:inputId/:nth`}
        render={(p) => <WorkPipeInput {...props} {...p} />}
      />
      <Route
        path={`${match.path}/:id/workpipe/:wid/:inputId/:nth/:step`}
        render={(p) => <WorkPipeInput {...props} {...p} />}
      />
      <Route
        path={`${match.path}/:id/workpipe/:wid`}
        render={(p) => <WorkPipe {...props} {...p} />}
      />
      <Route
        path={`${match.path}/:id`}
        render={(p) => <Offers {...props} {...p} />}
      />
      <Route render={(p) => <Offers {...props} {...p} />} />
    </Switch>
  </Provider>
);

/**
 * Navigation Bar
 */

const prune = (text, max = 50) =>
  !text || text.length <= max ? text : `${text.slice(0, max - 3)}...`;

const NavBar = ({
  history,
  match,
  offer,
  workPipe,
  input,
  nextProfiles,
  fullNextProfiles,
  onReset,
  onOpenFullNextProfiles,
}) => (
  <Menu.Menu>
    <Link to={`/salesboard`}>
      <Menu.Item link name="&larr; Back" />
    </Link>
    {offer ? (
      <Link to={`/offers/${offer.id}`}>
        <Menu.Item link>
          <b>{prune(offer.title)}</b>
        </Menu.Item>
      </Link>
    ) : (
      <Menu.Item>Loading ...</Menu.Item>
    )}
    {offer && workPipe ? (
      <Link to={`/offers/${offer.id}/workpipe/${workPipe.id}`}>
        <Menu.Item link>› {workPipe.title}</Menu.Item>
      </Link>
    ) : null}
    {offer && workPipe && input ? (
      <Dropdown item text={`› ${input.title}`}>
        <Dropdown.Menu>
          {_.map(nextProfiles, (nextProfile, nth) => (
            <Dropdown.Item
              key={nth}
              onClick={() => {
                onReset();
                history.push(
                  `/offers/${offer.id}/workpipe/${workPipe.id}/${
                    input.id
                  }/${nth}`,
                );
              }}
            >
              {nextProfile.idFields.linkedin}
            </Dropdown.Item>
          ))}
          {nextProfiles && nextProfiles.length > 9
            ? [
                <Dropdown.Divider key={'a'} />,
                <Dropdown.Item
                  key={'b'}
                  onClick={() =>
                    onOpenFullNextProfiles(fullNextProfiles, input, offer)
                  }
                >
                  See All
                </Dropdown.Item>,
              ]
            : null}
        </Dropdown.Menu>
      </Dropdown>
    ) : null}
  </Menu.Menu>
);
const mapStateBar = (state) => ({
  offer: state.selectedOffer,
  workPipe: state.selectedWorkPipe,
  input: state.selectedInput,
  nextProfiles: state.nextProfiles,
  fullNextProfiles: state.fullNextProfiles,
});

const mapDispatchBar = (dispatch) => ({
  onReset: () => dispatch(actions.setProfiles(null, null)),
  onOpenFullNextProfiles: async (fullNextProfiles, input, offer) => {
    dispatch(
      actions.setFullNextState(fullNextProfiles.profiles ? 'ready' : 'loading'),
    );
    if (!fullNextProfiles.profiles) {
      const { data } = await axios.post(
        `${baseUrl}/sweetwork/getItem?nbNext=-1`,
        {
          ...input,
          offer,
          projectionParam: {},
          nth: 0,
        },
      );
      dispatch(actions.setFullNextProfiles(data.next));
    }
  },
});

const NavBarContainer = connect(
  mapStateBar,
  mapDispatchBar,
)(NavBar);
const OffersNavBar = (p) => (
  <Provider store={store}>
    <NavBarContainer {...p} />
  </Provider>
);

export { OffersNavBar };
