import React from 'react';
import _ from 'underscore';
import { Segment, Header, Icon } from 'semantic-ui-react';

const WarningsPanel = ({ warnings }) => {
  if (_.isEmpty(warnings)) {
    return null;
  }

  if (_.uniq(_.pluck(warnings, 'isSoft')).length >= 2) {
    return (
      <div>
        {_.map(warnings, ({ title, text, isSoft }, index) => (
        <Segment inverted color={isSoft ? 'orange' : 'red'} key={index} secondary
          style={{ 
            ...(index<warnings.length-1) && { marginBottom: 1 },
            ...(index> 0) && { marginTop: 1 } 
          }}
        > 
          <div style={{ margin: '5px 0px' }} >
            {title && (
              <Header inverted as='h4' style={{ margin: '2px 0px' }}>
                {(title || '').indexOf('ready worked at') >= 0 && <Icon name='warning sign' />}
                {(title || '').indexOf('have a look') >= 0 && <Icon name='eye' />}
                {title}
              </Header>
            )}
            {text && <p>{text}</p>}
          </div>
        </Segment>
        ))}
      </div>
    );
  }

  return (
    <Segment inverted color={warnings[0].isSoft ? 'orange' : 'red'} secondary>
      {_.map(warnings, ({ title, text }, index) => (
        <div style={{ margin: '5px 0px' }} key={index}>
          {title && (
            <Header inverted as='h4' style={{ margin: '2px 0px' }}>
              {(title || '').indexOf('ready worked at') >= 0 && <Icon name='warning sign' />}
              {(title || '').indexOf('have a look') >= 0 && <Icon name='eye' />}
              {title}
            </Header>
          )}
          {text && <p>{text}</p>}
        </div>
      ))}
    </Segment>
  );
};

export default WarningsPanel;
