import _ from "underscore";
import React, { Component } from "react";
import { Table, Checkbox, Button, Form, Segment, Header } from "semantic-ui-react";
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import CustomModal from '../Modal';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area } from 'recharts';



class ProfileCollectionsExternalDataStats extends Component {
    componentDidMount() {
        this.handleLoadProfileCollections();
    }

    handleLoadProfileCollections = async () => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getProfileCollectionsData';
        const results = (await axios.get(url, customHeaders)).data;
        this.setState({ profileCollections: results, selectedProfileCollectionId: null });
    }

    handleSelectProfileCollection = async (e, { value }) => {
        this.setState({
            selectedProfileCollectionId: value,
        });
        await this.getLinkedinStats(value);

    };

    getLinkedinStats = async (selectedProfileCollectionId) => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getLinkedinProfileDistribution';

        const payload = {
            profileCollectionId: selectedProfileCollectionId,
        };
        const results = (await axios.post(url, payload, customHeaders)).data;
        this.setState({ linkedinStats: results });
    }

    render() {
        const { profileCollections, selectedProfileCollectionId, linkedinStats } = this.state || {}
        const profileCollectionOptions = _.map(profileCollections || [], (profileCollection, key) => ({
            value: profileCollection.id,
            key: key,
            text: profileCollection.id
        }));

        const data = linkedinStats ? _.map(linkedinStats.data.monthStats, (item) => ({
            key: item.date,
            value: item.count,
        }))
            : [];
        const nbFound = linkedinStats ? linkedinStats.data.nbFound : 0
        const nbSampled = linkedinStats ? linkedinStats.data.nbSampled : 0

        return (
            <div>
                <Form.Select
                    search
                    placeholder='Profile Collection'
                    options={profileCollectionOptions}
                    onChange={this.handleSelectProfileCollection}
                    value={selectedProfileCollectionId}
                />

                {linkedinStats ?
                    <div>
                        Among {nbSampled} linkedin profiles, {nbFound} where found in DB.

                        <Segment>
                            <Header as='h3' textAlign='left'>
                                Linkedin Last Scrap Date
                            </Header>
                            <AreaChart
                                width={1000}
                                height={800}
                                data={data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray='3 3' />
                                <XAxis dataKey='key' />
                                <YAxis />
                                <Tooltip />
                                <Area type='monotone' dataKey='value' stroke='#8884d8' fill='#8884d8' />
                            </AreaChart>
                        </Segment>
                    </div>
                    : null}
            </div>
        )
    }
}


export default ProfileCollectionsExternalDataStats