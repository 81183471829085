import React, { Component } from 'react';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { SweetForm, Select, List, enhance, Checkbox } from 'sweetform';
import { Textarea } from '../common';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { Segment } from 'semantic-ui-react';
import _ from 'underscore';

const getQueryOperatorOptions = () => {
  return [
    {
      label: 'First X',
      value: 'first',
    },
    {
      label: 'Count',
      value: 'count',
    },
    {
      label: 'Sample',
      value: 'sample',
    },
  ];
};

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getLimitOptions = () => {
  return [
    {
      label: '1',
      value: 1,
    },
    {
      label: '10',
      value: 10,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
    {
      label: '500',
      value: 500,
    },
    {
      label: '1000',
      value: 1000,
    },
    {
      label: '5000',
      value: 5000,
    },
    {
      label: '10000',
      value: 10000,
    },
  ];
};

const getOutputOptions = () => {
  return [
    {
      label: 'JSONArray',
      value: 'json',
    },
    {
      label: 'Inline',
      value: 'inline',
    },
  ];
};

const downloadResult = (lastQueryResult) => {
  const objectData = lastQueryResult;
  let filename = 'export.json';
  let contentType = 'application/json;charset=utf-8;';
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement('a');
    a.download = filename;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
const prettyDisplayResult = (lastQueryResult, outputType) => {
  return <Segment>{outputType == 'float' ? lastQueryResult.results : JSON.stringify(lastQueryResult.results)}</Segment>;
};

class MongoQueryCollectionView extends Component {
  async onSubmit(mongoQuery, outputFormat) {
    const outputType = mongoQuery.operator == 'count' ? 'float' : 'jsonArray';
    try {
      const payload = {
        mongoQuery: {
          mongoHost: mongoQuery.mongoHost,
          collection: mongoQuery.collection,
          database: mongoQuery.database,
          queryType: mongoQuery.queryType,
          filterParams: JSON.parse(
            mongoQuery.filterParams != undefined && mongoQuery.filterParams != '' ? mongoQuery.filterParams : '{}',
          ),
          projParams: JSON.parse(
            mongoQuery.projParams != undefined && mongoQuery.projParams != '' ? mongoQuery.projParams : '{}',
          ),
          sortParams: JSON.parse(
            mongoQuery.sortParams != undefined && mongoQuery.sortParams != '' ? mongoQuery.sortParams : '{}',
          ),
          limit: mongoQuery.limit,
        },
        outputType,
      };
      const customHeaders = {
        'content-type': 'application/x-www-form-urlencoded',
      };
      const url = baseUrl + '/sweetchain/searchMongo';
      const data = (await axios.post(url, payload, customHeaders)).data;
      if (data.success == false) {
        alert('INVALID QUERY');
        return;
      }
      if (data && outputFormat == 'json' && outputType == 'jsonArray') {
        downloadResult(data);
      }
      this.setState({
        lastQueryResult: data,
      });
    } catch (e) {
      console.log(e);
      alert(e.message || `Invalid Query Params`);
      return;
    }
  }
  filterToQuery(filter) {
    return (
      (filter.field == undefined ? '' : filter.field) +
      ' ' +
      (filter.operator == undefined ? '' : filter.operator) +
      ' ' +
      (filter.comparedValue == undefined ? '' : filter.comparedValue)
    );
  }
  async handleChange(params) {
    const { database, collection, mongoHost } = this.props || {};
    const operator = params.operator == 'count' ? 'count' : 'find';
    const suffix = params.operator == 'first' ? '.limit(' + params.limit + ')' : '';
    const sortKeySuffix = params.sortKey ? '.sort(' + params.sortKey + ')' : '';
    const queryPreview =
      params.operator == 'count'
        ? 'client.' +
          database +
          '.' +
          collection +
          '.' +
          operator +
          '(' +
          (params.rawQuery == undefined ? '{}' : params.rawQuery) +
          ')'
        : params.operator == 'first'
        ? 'client.' +
          database +
          '.' +
          collection +
          '.' +
          operator +
          '(' +
          (params.rawQuery == undefined ? '{}' : params.rawQuery) +
          ',' +
          (params.projectionKeys == undefined ? '{}' : params.projectionKeys) +
          ')' +
          sortKeySuffix +
          suffix
        : 'sorry, we did not code the preview for aggregations... but trust us, it works !';

    const isCorrectFilterParams = IsJsonString(
      params.rawQuery != undefined && params.rawQuery != '' ? params.rawQuery : '{}',
    );
    const isCorrectProjParams = IsJsonString(
      params.projectionKeys != undefined && params.projectionKeys != '' ? params.projectionKeys : '{}',
    );
    const isCorrectSortParams = IsJsonString(
      params.sortKey != undefined && params.sortKey != '' ? params.sortKey : '{}',
    );
    this.setState({
      mongoQuery: {
        mongoHost,
        collection,
        database,
        queryType: params.operator,
        filterParams: params.rawQuery,
        projParams: params.projectionKeys,
        sortParams: params.sortKey,
        limit: params.limit,
      },
      lastQueryResult: null,
      outputFormat: params.outputFormat,
      queryPreview,
      isCorrectFilterParams,
      isCorrectProjParams,
      isCorrectSortParams,
    });
  }

  render() {
    const queryOperatorOptions = getQueryOperatorOptions();
    const outputOptions = getOutputOptions();
    const limitOptions = getLimitOptions();
    const lastQueryResult = (this.state || {}).lastQueryResult;
    const queryPreview = (this.state || {}).queryPreview;
    const outputFormat = (this.state || {}).outputFormat;
    const mongoQuery = (this.state || {}).mongoQuery;
    const outputType = (mongoQuery || {}).queryType == 'count' ? 'float' : 'jsonArray';
    const queryOperator = (mongoQuery || {}).queryType;
    const isCorrectFilterParams = (this.state || {}).isCorrectFilterParams != false;
    const isCorrectProjParams = (this.state || {}).isCorrectProjParams != false;
    const isCorrectSortParams = (this.state || {}).isCorrectSortParams != false;
    return (
      <div>
        <SweetForm onChange={(params) => this.handleChange(params)}>
          <Grid columns={2}>
            <Grid.Column>
              <Form.Field>
                {' '}
                Query Type :
                <div>
                  <Grid.Column>
                    <Select
                      fluid
                      field='operator'
                      label='Operator'
                      options={queryOperatorOptions}
                      placeholder='Query Operator'
                    />
                  </Grid.Column>
                  {queryOperator == 'first' || queryOperator == 'sample' ? (
                    <Grid.Column>
                      <Select fluid field='limit' label='Limit' options={limitOptions} placeholder='1' />
                    </Grid.Column>
                  ) : null}
                </div>
              </Form.Field>
              <Form.Field>
                Filter :
                <div>
                  <Textarea
                    style={{
                      background: isCorrectFilterParams ? '#e9ffd5' : '#ffeee6',
                      width: '100%',
                      resize: 'none',
                      height: '35px',
                      overflow: 'hidden',
                      display: 'table-cell',
                    }}
                    field='rawQuery'
                    label='Raw Query Editor'
                    placeholder='Write filter query - ex: {"linkedin_id": "jebarjonet"}'
                  />
                </div>
              </Form.Field>
              <Form.Field>
                {' '}
                Sorting Key :
                <Textarea
                  style={{
                    background: isCorrectSortParams ? '#e9ffd5' : '#ffeee6',
                    width: '100%',
                    resize: 'none',
                    height: '35px',
                    overflow: 'hidden',
                    display: 'table-cell',
                  }}
                  field='sortKey'
                  label='Raw Query Editor'
                  placeholder='Write sort query - ex: {"timestamp": 1}'
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                {' '}
                Projection Keys :
                <Textarea
                  style={{
                    background: isCorrectProjParams ? '#e9ffd5' : '#ffeee6',
                    width: '100%',
                    resize: 'none',
                    height: '35px',
                    overflow: 'hidden',
                    display: 'table-cell',
                  }}
                  field='projectionKeys'
                  label='Projection Keys'
                  placeholder='Write projectionKeys query - ex: {"linkedin_id": 1}'
                />
              </Form.Field>
              <Form.Field>
                {' '}
                Output Format :
                <Select field='outputFormat' fluid label='Output' options={outputOptions} placeholder='Output Format' />
              </Form.Field>
              <b>Preview :</b> {queryPreview}
            </Grid.Column>
            <Grid.Row />
            <Grid.Row>
              <Grid.Column>
                <Button color='green' onClick={() => this.onSubmit(this.state.mongoQuery, outputFormat)}>
                  Submit
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SweetForm>
        {lastQueryResult
          ? outputFormat == 'inline' || outputType == 'float'
            ? prettyDisplayResult(lastQueryResult, outputType)
            : null
          : null}
      </div>
    );
  }
}
export default MongoQueryCollectionView;
