import _ from 'underscore';
import React from 'react';
import { Icon, Card, Button, Grid } from 'semantic-ui-react';

export const DisplaySearchResultTree = ({ tree }) => {
  if (!_.isObject(tree)) {
    return null;
  }
  const params = tree.params || {};

  const sons = params.son
    ? [params.son]
    : params.son1 && params.son2
    ? [params.son1, params.son2]
    : params.sons
    ? params.sons
    : params.weightedSons
    ? _.map(params.weightedSons, ({ son }) => son)
    : [];

  const isOk = tree.score && tree.score > 0;

  const baseParamsString = _.reduce(
    params,
    (memo, val, key) => {
      if (['son', 'sons', 'son1', 'son2', 'weightedSons'].indexOf(key) >= 0) {
        return memo;
      }
      return memo + (memo ? ',' : '') + key + ':' + val;
    },
    '',
  );

  const paramsString = baseParamsString ? '[' + baseParamsString + ']' : '';

  return (
    <div style={{ paddingLeft: '10px', paddinTop: '2px' }}>
      <span>
        {isOk ? (
          <Icon name="check" color="green" />
        ) : (
          <Icon name="cancel" color="red" />
        )}
        <strong>{tree.id}</strong> {paramsString} {tree.score}
      </span>
      {_.map(sons, (son, index) => (
        <DisplaySearchResultTree key={index} tree={son} />
      ))}
    </div>
  );
};

const DisplaySearchResult = ({ searchResult }) => {
  const { searchParamsWithScore, messageHTML } = searchResult;

  if (!searchResult) {
    return '';
  }
  return (
    <div>
      <Card fluid>
      <Card.Content>
      
      <Grid>
      <Grid.Row>

      <Grid.Column width={8}>
        <h1>Filters</h1>
        { searchParamsWithScore.filter && (
          <DisplaySearchResultTree tree={searchParamsWithScore.filter} />
        )}
        {_.map(searchParamsWithScore.filters, (filter, index) => (
          <DisplaySearchResultTree key={index} tree={filter} />
        ))}
      </Grid.Column>
      
      <Grid.Column width={8}>
      <h1>Scorer</h1>
      <DisplaySearchResultTree tree={searchParamsWithScore.scorer} />
      </Grid.Column>
      </Grid.Row> 
            <Grid.Row>
              <Grid.Column width={16}>
                <br />
                <center>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: messageHTML }} />
                  </div>
                </center>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <center>
            <Button
              onClick={() => {
                console.log(JSON.stringify(searchResult, null, 2));
                console.log(searchResult);
              }}
            >
              See in Console
            </Button>
            <br />
            <br />
          </center>
        </Card.Content>
      </Card>
      <br />
    </div>
  );
};

export default DisplaySearchResult;
