import _ from 'underscore';
import React from 'react';
import { Card } from 'semantic-ui-react';

export const FilterDetailsTree = ({ tree }) => {
  if (!_.isObject(tree)) {
    return null;
  }
  const params = tree.params || {};

  const sons = params.son
    ? [params.son]
    : params.son1 && params.son2
    ? [params.son1, params.son2]
    : params.sons
    ? params.sons
    : params.weightedSons
    ? _.map(params.weightedSons, ({ son }) => son)
    : [];

  const baseParamsString = _.reduce(
    params,
    (memo, val, key) => {
      if (['son', 'sons', 'son1', 'son2', 'weightedSons'].indexOf(key) >= 0) {
        return memo;
      }
      return memo + (memo ? ',' : '') + key + ':' + val;
    },
    '',
  );

  const paramsString = baseParamsString ? '[' + baseParamsString + ']' : '';

  const size = (tree.details && tree.details.size) || -1;

  const color =
    size < 0
      ? 'grey'
      : size < 100
      ? 'purple'
      : size < 1000
      ? 'deeppink'
      : size < 10000
      ? 'blue'
      : 'black';

  return (
    <div style={{ paddingLeft: '10px', paddinTop: '2px' }}>
      <span style={{ color }}>
        <strong>
          [{size}] {tree.id}
        </strong>{' '}
        {paramsString}
      </span>
      {_.map(sons, (son, index) => (
        <FilterDetailsTree key={index} tree={son} />
      ))}
    </div>
  );
};

const FilterDetails = ({ filterDetails }) => {
  if (!filterDetails) {
    return '';
  }
  return (
    <Card fluid>
      <Card.Content>
        <FilterDetailsTree tree={filterDetails} />
      </Card.Content>
    </Card>
  );
};

export default FilterDetails;
