import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class ChallengesDashboard extends React.Component {

  state = {}

  componentDidMount() {
    this.handleLoadChallenges();
  }

  handleLoadChallenges = async () => {
    const url = baseUrl + '/challenges';
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const challenges = data.results;
    this.setState({ challenges });
  }

  render() {
    const { challenges } = this.state;
    if (!challenges) {
      return <div />
    }

    const nbColumns = 2;
    const challengesRows = [[]];
    _.each(challenges, (challenge) => {
      if (_.last(challengesRows).length === nbColumns) {
        challengesRows.push([]);
      }
      _.last(challengesRows).push(challenge);
    });

    return (
      <Grid>
        {_.map(challengesRows, (challengeRow, index) => (
          <Grid.Row key={index} columns={nbColumns}>
            {_.map(challengeRow, (challenge, index) => (
              <Grid.Column key={index}>
                <a href={'/ml/challenges/' + challenge.id}>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>{challenge.name}</Card.Header>
                      <Card.Meta>
                        <span>{challenge.id}</span>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </a>
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </Grid>
    );
  }
}

export default ChallengesDashboard;
