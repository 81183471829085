import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Segment, Loader, Dimmer, Button } from 'semantic-ui-react';
import { Link } from './Link';
import baseUrl from '../../baseUrl';

import { WorkplaceSearch } from './WorkplaceSearch';

export class WorkplaceFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      sourceType: '',
      loading: false,
      component: {},
    };

    this.onWorkplaceSelect = this.onWorkplaceSelect.bind(this);
    this.onWorkplaceClear = this.onWorkplaceClear.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  async getComponent() {
    this.setState({ loading: true });
    const response = await axios.post(`${baseUrl}/workplaceAnnotations/getComponent`, {
      sourceType: this.state.sourceType,
      sourceId: this.state.id,
    });
    this.setState({
      component: response.data,
    });
    this.setState({ loading: false });
  }

  async onWorkplaceSelect(workplace) {
    await this.setState({
      id: workplace.id,
      sourceType: workplace.sourceId,
    });

    this.getComponent();
    this.props.onWorkplaceSelect(workplace);
  }

  onWorkplaceClear() {
    this.setState({ component: {} });
  }

  onRefresh() {
    this.getComponent();
  }

  async onLinkDelete(link) {
    const formattedLink = _.map(link, (source, id) => ({
      sourceId: source,
      sourceType: id,
    }));
    await axios.post(`${baseUrl}/workplaceAnnotations/removeLink`, {
      firstSource: formattedLink[0],
      secondSource: formattedLink[1],
    });
    this.getComponent();
  }

  render() {
    return (
      <Segment>
        <WorkplaceSearch onSelect={this.onWorkplaceSelect} onClear={this.onWorkplaceClear} />
        <Segment>
          <Dimmer active={this.state.loading} inverted>
            <Loader inverted />
          </Dimmer>

          <div>{JSON.stringify(this.state.component)}</div>

          {this.state.component.links && (
            <Button color="blue" onClick={this.onRefresh}>
              Refresh
            </Button>
          )}

          {this.state.component.links &&
            _.map(this.state.component.links, (link) => {
              return <Link link={link} onDelete={() => this.onLinkDelete(link)} />;
            })}
        </Segment>
      </Segment>
    );
  }
}
