import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Table, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class DynamicSelectionStats extends Component {
  state = {};
  componentWillMount() {
    this.handleLoad();
  }
  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/strat/selection/activeOffers`);
    this.setState(data);
  };
  renderOffer({ allRanges, offer, index }) {
    const ranges = offer.ranges;
    return (
      <Table.Row key={index}>
        <Table.Cell>{offer.id}</Table.Cell>
        <Table.Cell>
          {Math.round((offer.selected / offer.all) * 100)}%
        </Table.Cell>
        <Table.Cell>
          {offer.selected} / {offer.all}
        </Table.Cell>
        {_.map(allRanges, (tmp, iRange) =>
          iRange < ranges.length ? (
            <Table.Cell>
              {Math.round((ranges[iRange].selected / ranges[iRange].all) * 100)}
              %
            </Table.Cell>
          ) : (
            <Table.Cell>-</Table.Cell>
          ),
        )}
      </Table.Row>
    );
  }
  renderTable(category) {
    const offers = this.state;
    if (!offers) {
      return;
    }

    let allRanges = [];
    _.each(offers, (offer) => {
      if (offer.ranges.length > allRanges.length) {
        allRanges = _.map(offer.ranges, ({ begin, end }) => ({
          begin,
          end,
        }));
      }
    });

    console.log(allRanges);

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Dynamic Selection</Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              <Table.HeaderCell>%select</Table.HeaderCell>
              <Table.HeaderCell>fraction</Table.HeaderCell>
              {_.map(allRanges, (range, index) => (
                <Table.HeaderCell key={index}>
                  {range.begin}-{range.end}
                </Table.HeaderCell>
              ))}
            </Table.Header>
            <Table.Body>
              {_.map(_.sortBy(offers, 'all').reverse(), (offer, index) =>
                this.renderOffer({ allRanges, offer, index }),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    return <div>{this.renderTable('total')}</div>;
  }
}

export default DynamicSelectionStats;
