import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Message
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';


class OfferMoreInfo extends Component {
  state = {};
  componentWillMount() {
    this.handleLoadOffer(this.props.jobOfferId);
  }
  handleLoadOffer = async (jobOfferId) => {
    const offerData = (await axios.post(baseUrl + '/station/offers', {
      ids: jobOfferId,
    })).data;
    if (_.isEmpty(offerData.results)) {
      console.log("offer not found")
      this.setState({
        loaded: true,
      })
      return;
    }
    const offer = offerData.results[0];

    this.setState({
      loaded: true,
      offer,
    })
  };
  render() {
    if (!this.state.loaded) {
      return <div></div>
    }
    if (this.state.loaded && _.isEmpty(this.state.offer)) {
      return (
        <div>Sweetapp offer load</div>
      )
    }
    if (_.isEmpty(((this.state.offer || {}).criteria || {}).moreInfo)) {
      return (
        <div>No comments in sweetapp offer</div>
      )
    }
    return (
      <Message color={'green'}>
        <Message.Header>
        Comments
        </Message.Header>
        <div style={{"white-space": "pre-wrap"}}>
        {((this.state.offer || {}).criteria || {}).moreInfo}
        </div>

      </Message>
    );
  }
}

export default OfferMoreInfo;
