import React, { Component } from 'react';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { Grid } from 'semantic-ui-react';
import _ from 'underscore';
import WorkplaceHeader from './WorkplaceHeader';
import WorkplaceFlow from './WorkplaceFlow';
import ClosestWorkplaces from './ClosestWorkplaces';
import WorkplaceTags from './WorkplaceTags';
import EmployeeStats from './EmployeeStats';
import { Button, Search } from 'semantic-ui-react';
import './style.css';

class EnrichedWorkplace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workplaceId: this.props.match.params.workplaceId || 'hiresweet',
      searchTimeout: false,
    };
  }

  componentWillMount() {
    this.loadConfig();
  }

  loadConfig() {
    axios
      .get(baseUrl + `/techAsset/getEnrichedWorkplace/${this.state.workplaceId}`)
      .then((result) => {
        if (!result.data) {
          throw Error('no data for /search/config');
        }
        if (result.data.error) {
          throw Error(result.data.error);
        }
        this.setState({ data: result.data });
      })
      .catch((e) => {
        alert(e.message);
      });
  }

  handlePatternsChange = async (e) => {
    const workplaceName = e.target.value;
    await this.setState({
      workplaceName,
    });
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    await this.setState({
      searchTimeout: setTimeout(async () => await this.updateSearch(), 100),
    });
  };

  async updateSearch() {
    const response = await axios.get(`${baseUrl}/techAsset/workplaceCandidates/${this.state.workplaceName}`);
    this.setState({
      searchResults: _.map(response.data, (c) => ({
        title: c.name,
        description: c.id,
      })),
    });
  }

  onCompanySelect = async (e, result) => {
    await this.setState({
      workplaceName: result.result.title,
      workplaceId: result.result.description,
    });
  };

  render() {
    if (!this.state.data) {
      return <div />;
    }

    return (
      <Grid>
        <Grid.Row>
          <Search
            selectFirstResult={true}
            onResultSelect={this.onCompanySelect}
            onSearchChange={this.handlePatternsChange}
            results={this.state.searchResults}
            value={this.state.workplaceName}
          />
          <Button color='green' onClick={() => this.loadConfig()}>
            {' '}
            Search{' '}
          </Button>
        </Grid.Row>
        <Grid.Row>
          <WorkplaceHeader workplace={this.state.data} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <WorkplaceTags workplace={this.state.data} />
          </Grid.Column>
          <Grid.Column width={8}>
            <EmployeeStats workplace={this.state.data} />
            <WorkplaceFlow workplace={this.state.data} />
          </Grid.Column>
          <Grid.Column width={4}>
            <ClosestWorkplaces workplace={this.state.data} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default EnrichedWorkplace;
