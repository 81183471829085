import React, { Component } from 'react';
import axios from 'axios';
import { Button, Modal } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class ThreadContentModal extends Component {
  render() {
    const { threads, onClose } = this.props;
    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>Thread Details</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <pre>{JSON.stringify(threads, null, 4)}</pre>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={onClose}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const withThreadContentModal = (WrappedComponent) =>
  class extends React.PureComponent {
    state = {};
    handleOpenThread = async (threadId) => {
      const threads = (await axios.get(
        `${baseUrl}/monitoring/threads/${threadId}`,
      )).data;
      this.setState({
        threads,
        showModal: true,
      });
    };
    handleClose = () => {
      this.setState({
        showModal: false,
      });
    };
    render() {
      const { threads, showModal } = this.state;
      return (
        <span>
          {showModal && (
            <ThreadContentModal threads={threads} onClose={this.handleClose} />
          )}
          <WrappedComponent
            {...this.props}
            showThreadContent={this.handleOpenThread}
          />
        </span>
      );
    }
  };

export default withThreadContentModal;
