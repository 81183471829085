import moment from 'moment';
import { createStore, combineReducers } from 'redux';
import { Record, List, Map } from 'immutable';

// Actions
const LOAD_DATES = 'LOAD_DATES';
const SELECT_DATE = 'SELECT_DATE';
const LOAD_USERS_FOR_DATE = 'LOAD_USERS_FOR_DATE';
const SELECT_WORKER = 'SELECT_WORKER';
const SET_FAILURES_FOR_DAY = 'SET_FAILURES_FOR_DAY';
const SELECT_SCRAP_FAILURE = 'SELECT_SCRAP_FAILURE';
const SET_SCRAP_FAILURE = 'SET_SCRAP_FAILURE';

function wrapLine(line, maxChars) {
  let str = '';
  const words = line.split(/ +/gi);

  let currentSize = 0;
  while (words.length > 0) {
    const word = words.shift();
    str += word;
    currentSize += word.length;
    if (currentSize > maxChars) {
      str += '\n';
      currentSize = 0;
    } else {
      str += ' ';
      ++currentSize;
    }
  }

  return str;
}

function wrap(text, maxChars) {
  return text
    .replace(/<br>/gi, '\n')
    .split('\n')
    .map((line) => wrapLine(line, maxChars))
    .join('\n');
}

function sortKeys(o) {
  const o2 = {};
  Object.keys(o)
    .sort()
    .forEach((k) => {
      const value = o[k];
      o2[k] = typeof value === 'string' ? wrap(value, 80) : value;
    });
  return o2;
}

function cleanData(data) {
  if (!data) {
    return data;
  }
  data.experiences = (data.experiences || []).map(sortKeys);
  data.education = (data.education || []).map(sortKeys);
  data.skills = (data.skills || []).map(sortKeys);

  data.skills.sort((a, b) => a.tag - b.tag);

  return data;
}

export function cleanDataProfile(profile) {
  if (!profile) {
    return profile;
  }
  const profileClean = sortKeys(profile);

  profileClean.data = cleanData(profile.data);

  return profileClean;
}

export const actionCreators = {
  loadDates(dates) {
    return { type: LOAD_DATES, dates };
  },
  selectDate(date) {
    return { type: SELECT_DATE, date };
  },
  loadUsersForDates(users) {
    return { type: LOAD_USERS_FOR_DATE, users };
  },
  selectWorker(workerId) {
    return { type: SELECT_WORKER, worker: workerId };
  },
  setFailuresForDay(failures) {
    failures.forEach((f) => {
      f.dateScrap = moment(f.timestamp);
    });
    return { type: SET_FAILURES_FOR_DAY, failures };
  },
  selectScrapFailure(objectId) {
    return { type: SELECT_SCRAP_FAILURE, failureId: objectId };
  },
  setScrapFailure(detail) {
    return { type: SET_SCRAP_FAILURE, failure: detail };
  },
};

// Reducers
const DatesRecord = new Record({ dates: new List() });
const defaultStateDates = new DatesRecord();
function datesReducer(state = defaultStateDates, action) {
  switch (action.type) {
    case LOAD_DATES:
      return state.merge({ dates: action.dates });
    default:
      return state;
  }
}

const FailureRecord = new Record({
  failure: new Map(),
  history: new List(),
});
const UsersRecord = new Record({
  users: new List(),
  date: null,
  selectedWorker: null,
  isLoadingFailures: false,
  failures: new List(),
  failureId: null,
  failure: new FailureRecord(),
  isLoadingFailure: false,
});
const defaultStateUsers = new UsersRecord();
function usersReducer(state = defaultStateUsers, action) {
  switch (action.type) {
    case SELECT_WORKER:
      return state.merge({
        selectedWorker: action.worker,
        isLoadingFailures: true,
      });
    case SELECT_DATE:
      return state.merge({ date: action.date });
    case LOAD_USERS_FOR_DATE:
      return state.merge({ users: action.users });
    case SET_FAILURES_FOR_DAY:
      return state.merge({
        failures: action.failures,
        isLoadingFailures: false,
      });
    case SELECT_SCRAP_FAILURE:
      return state.merge({
        failureId: action.failureId,
        isLoadingFailure: true,
      });
    case SET_SCRAP_FAILURE:
      return state.merge({ failure: action.failure, isLoadingFailure: false });
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  failuresByUser: usersReducer,
  dates: datesReducer,
});

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
