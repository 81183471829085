import { Button, Grid, Modal, Form, TextArea } from 'semantic-ui-react';
import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

import { SweetForm, Select, Input } from 'sweetform';




const generateRandomId = (size) => {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let text = "";
    for (let i = 0; i < size; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};


class EditSchemaModal extends Component {
    state = {
        data: null,
        jsonStr: null,
        jsonIsCorrect: true
    };

    componentDidMount() {
        this.setState({
            data: this.props.initialValue,
            jsonStr: JSON.stringify(this.props.initialValue, null, 2)
        })
    }

    onUpdate = () => {
        this.props.onUpdate(
            this.state.data
        );
    };

    handleChangeJsonStr = ({ jsonStr }) => {
        let data = null;
        let jsonIsCorrect = true;
        try {
            data = JSON.parse(jsonStr);
        } catch (e) {
            jsonIsCorrect = false;
        }
        this.setState({
            jsonStr,
            jsonIsCorrect,
            data
        });
    };

    onDelete = () => {
        this.props.onDelete(this.state.data)
    }

    render() {
        const { onClose } = this.props;
        return (
            <Modal onClose={onClose} open closeIcon size='fullscreen'>
                <Modal.Header>Edit Schema</Modal.Header>
                <Modal.Content scrolling style={{ minHeight: 300 }}>
                    <Grid>
                        <Grid.Column width={8}>
                            <Form>
                                <TextArea
                                    style={{ borderColor: this.state.jsonIsCorrect ? 'lime' : 'orange' }}
                                    value={this.state.jsonStr}
                                    rows='36'
                                    onChange={({ target }) => {
                                        this.handleChangeJsonStr({ jsonStr: target.value });
                                    }}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={() => this.onUpdate()}>
                        Save
                    </Button>
                    <Button color='red' onClick={() => this.onDelete()}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


class SchemaEditionView extends Component {
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/hiresweetComprehend/getSchemaIds';
        const data = (await axios.get(url, customHeaders)).data;
        this.setState({ schemaIds: data.data });
    };

    handleSchemaChange = async (params) => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/hiresweetComprehend/getSchemaData/' + params.selectedSchemaId;
        const data = (await axios.get(url, customHeaders)).data;
        this.setState({ currentSchemaData: data.data, selectedSchemaId: params.selectedSchemaId });
    }

    onClose = () => {
        this.setState({
            selectedSchemaId: null,
            currentSchemaData: null,
            newSchemaModalOpen: false
        })
    }

    onAddSchema = () => {
        const newSchemaId = generateRandomId(10);
        this.setState({
            newSchemaModalOpen: true,
            selectedSchemaId: newSchemaId,
            currentSchemaData: { "id": newSchemaId }
        })
    }

    onUpdate = async (data) => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const { newSchemaModalOpen } = this.state
        if (newSchemaModalOpen) {
            const url = baseUrl + '/hiresweetComprehend/insertSchema';
            const res = await axios.post(url, data, customHeaders)
            console.log(data)
            if (res.reason) {
                alert(res.reason)
            }
        }
        else {
            const url = baseUrl + '/hiresweetComprehend/updateSchema';
            const res = await axios.post(url, data, customHeaders)
            if (res.reason) {
                alert(res.reason)
            }
        }
        this.onClose()
    }

    onDelete = async (data) => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };

        const url = baseUrl + '/hiresweetComprehend/deleteSchema';
        const res = await axios.post(url, { id: data.id }, customHeaders)
        if (res.reason) {
            alert(res.reason)
        }
        this.onClose()
    }

    render() {
        const { currentSchemaData, schemaIds, selectedSchemaId, newSchemaModalOpen } = this.state || {};
        console.log(currentSchemaData)
        return (
            <div>
                <SweetForm
                    initialValues={{ selectedSchemaId }}
                    onChange={(params) => this.handleSchemaChange(params)}
                >
                    <Select
                        fluid
                        field='selectedSchemaId'
                        label='Schema Id'
                        options={_.map(schemaIds || [], (schema, key) => ({
                            value: schema.id,
                            label: schema.id,
                        }))}
                        placeholder='Schema Id'
                    />
                </SweetForm>
                <Button color='green' onClick={() => this.onAddSchema()}>
                    Create Schema
                </Button>
                {currentSchemaData ? <EditSchemaModal initialValue={currentSchemaData} onClose={this.onClose} onUpdate={this.onUpdate} onDelete={this.onDelete} /> : null}
            </div>
        )
    }
}

export default SchemaEditionView;
