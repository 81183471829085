import React from 'react';
import _ from 'underscore';
import { Segment, Grid, Label } from 'semantic-ui-react';

const SweetappCriteriaBanner = ({ criteria }) => {
  if (!criteria || _.isEmpty(criteria)) {
    return null;
  }
  const { remote, salary } = criteria;
  if (_.isEmpty(remote) && _.isEmpty(salary)) {
    return null;
  }
  const { min, max } = salary || {};
  let remoteText = ((remote || {}).label || {}).fr;
  if ((remote || {}).identifier == "full-time"){
    remoteText = "Télétravail complet"
  }
  return (
    <Segment color='green'>
      <Grid columns={2} textAlign='center'>
        <Grid.Column width={8}>
          {(_.isNumber(min) || _.isNumber(max)) && (
              <h3>
                {_.isNumber(min) ? `${Math.floor(min / 1000)}k€ ` : '? '}
                -
                {_.isNumber(max) ? `${Math.floor(max / 1000)}k€` : '?'}
              </h3>
          )}
        </Grid.Column>
        <Grid.Column width={8}>
          {_.isString(remoteText) && (
            <h3>
              {remoteText}
            </h3>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default SweetappCriteriaBanner;
