import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Segment, Card, Header } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom';
import baseUrl from '../../baseUrl.js';
import LastEngagementsContext from './LastEngagementsContext';
import DealLifeCycles from './DealLifeCycles.js';
import ContactsStatuses from './ContactsStatuses.js';
import queryString from 'query-string';


class ProspectContextView extends React.Component {
  state = {};

  componentDidMount = async () => {
    try {
      const { hubspotCompanyId } = this.props;
      if (!hubspotCompanyId) {
        return;
      }
      const hubspotData = (await axios.post(`${baseUrl}/salesOptimisationApi/getHubspotData`, { hubspotCompanyId })).data;
      const { deals } = hubspotData || {};
      const selectedDeal = _.first(deals);


      this.setState({
        hubspotData,
        selectedDeal
      })
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  };

  handleSelectDeal = ({ deal }) => {
    this.setState({ selectedDeal: deal })
  }


  render() {
    const { hubspotCompanyId, clientId } = this.props;
    const { selectedDeal, hubspotData } = this.state;
    if (!hubspotCompanyId) {
      return <div>Should provide ?hubspotCompanyId=XXX in url</div>;
    }
    if (!hubspotData) {
      return <div>Loading...</div>
    }
    const { companyName, lastContact, lastAnswer, deals, state } = hubspotData;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Header as="h2">{companyName}</Header>
            <b>State:</b> {state}<br/>
            <b>Last contact:</b> {lastContact}<br/>
            <b>Last answer:</b> {lastAnswer}<br/>
          </Grid.Column>

          <Grid.Column width={10}>
            <b>Deals</b>
            <Card.Group>
              {_.map(deals, (deal) => {
                const { name, ownerEmail, stage, pipeline, deal_id } = deal
                return (
                  <Card color={deal_id==selectedDeal.deal_id && "blue"} onClick={() => this.handleSelectDeal(deal)}>
                    <Card.Content>
                      <Card.Header>{name}</Card.Header>
                      <Card.Description>
                        {ownerEmail && <span><b>Owner:</b> {ownerEmail}<br /></span>}
                        {pipeline && <span><b>Pipeline:</b> {pipeline}<br /></span>}
                        {stage && <span><b>Stage:</b> {stage}</span>}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                )
              })}
            </Card.Group>
          </Grid.Column>

        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Segment style={{ overflowY: 'scroll', height: '700px' }}>
              <Header as="h3"> Company Contacts </Header>
              <ContactsStatuses hubspotCompanyId={hubspotCompanyId} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment style={{ overflowY: 'scroll', height: '700px' }}>
              <Header as="h3"> Deal Context </Header>
              <LastEngagementsContext dealId={(selectedDeal || {}).deal_id} hubspotCompanyId={hubspotCompanyId} />
            </Segment>
          </Grid.Column>
          {selectedDeal && (
            <Grid.Column width={4}>
              <Segment style={{ overflowY: 'scroll', height: '700px' }}>
                <Header as="h3"> Deal Life Cycles </Header>
                <DealLifeCycles dealId={selectedDeal.deal_id} clientId={clientId} />
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

{/*
const withInitialQuery = (WrappedComponent) =>
  class extends React.Component {
    render() {
      return (
        <Switch>
          <Route exact path='/monitoring/sourceJobsExport/prospectContext'>
            {({ location }) => {
              const queryValues = queryString.parse(location.search);
              const label = queryValues.label;

              const hubspotCompanyId = queryValues.hubspotCompanyId;
              const clientId = queryValues.clientId;

              return (
                <WrappedComponent
                  label={label}
                  hubspotCompanyId={hubspotCompanyId}
                  clientId={clientId}
                />
              );
            }}
          </Route>
        </Switch>
      );
    }
  };
*/}


export default ProspectContextView;
