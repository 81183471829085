import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Form, Button, Grid } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

class LinkedinExtensionMonitoring extends Component {
  state = {};
  handleRun = () => {
    const query = {
      schools: _.compact((this.state.schools || '').split(';')),
      skills: _.compact((this.state.skills || '').split(';')),
      companies: _.compact((this.state.companies || '').split(';')),
      positive_filters: _.compact(
        (this.state.positive_filters || '').split(';'),
      ),
      negative_filters: _.compact(
        (this.state.negative_filters || '').split(';'),
      ),
    };
    this.setState({ result: 'computing' });
    axios.post(baseUrl + '/extension/linkedin', query).then(({ data }) => {
      this.setState({ result: data });
    });
  };
  handleClear = () => {
    this.setState({ result: null });
  };
  renderResult() {
    const { result } = this.state;
    if (result) {
      return <pre>{JSON.stringify(result, null, 4)}</pre>;
    }
  }
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <h1>Linkedin Extension</h1>
            <Form reply>
              {_.map(
                [
                  'schools',
                  'skills',
                  'companies',
                  'positive_filters',
                  'negative_filters',
                ],
                (key, index) => (
                  <div key={index}>
                    <strong>{key}</strong>
                    <Form.Input
                      value={this.state[key]}
                      onChange={(event, { value }) => {
                        this.setState({ [key]: value });
                      }}
                    />
                    <br />
                    <br />
                  </div>
                ),
              )}
            </Form>
            <Button onClick={() => this.handleRun()}>Run</Button>
            <Button onClick={() => this.handleClear()}>Clear</Button>
          </Grid.Column>
          <Grid.Column width={8}>{this.renderResult()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

class ExtensionMonitoring extends Component {
  render() {
    return <LinkedinExtensionMonitoring />;
  }
}

export default ExtensionMonitoring;
