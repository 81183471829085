export default {
  "skills": {
    "required": [],
    "important": [],
    "bonus": []
  },
  "description": "",
  "title": "Fake Offer",
  "location": "san francisco",
  "experience": {
    "min": 2,
    "max": 8
  },
  "responsibilities": "normal",
  "companyId": "hiresweet",
  "contractType": "CDI",
  "jobId": "software-engineering",
  "remote": false,
  "topSchool": true,
  "isActive": true,
  "isComplete": false,
  "isHiresweet": true,
  "isWatch": true,
  "id": "fake-offer",
  "status": "current",
  "users": [
    "ismael",
    "remils"
  ],
  "lastEditDate": 1560499634173,
  "comments": "test\nalpha",
  "callReport": "test\ntest",
  "platformId": "hiresweet-demo",
  "category": "ios",
  "education": {
    "target": [
      "best-engineering-school",
      null
    ],
    "avoid": []
  },
  "criteria": {
    "experience": {
      "target": {
        "min": 3,
        "max": 5
      },
      "okWith": {
        "min": 2,
        "max": 7
      }
    },
    "locations": {
      "target": [
        {
          "remoteWish": "full-time",
          "location": {
            "id": "bay-area-region"
          }
        }
      ],
      "okWith": []
    },
    "jobPosition": {
      "major": {
        "id": "data-science"
      }
    },
    "skills": {
      "main": [
        {
          "skills": [
            {
              "id": "data-science"
            }
          ]
        },
        {
          "skills": [
            {
              "id": "python"
            }
          ]
        },
        {
          "skills": [
            {
              "id": "R"
            },
            {
              "id": "mongodb"
            }
          ]
        }
      ],
      "important": [
        {
          "skills": [
            {
              "id": "natural-language-processing"
            }
          ]
        },
        {
          "skills": [
            {
              "id": "computer-vision"
            }
          ]
        },
        {
          "skills": [
            {
              "id": "nosql"
            }
          ]
        }
      ],
      "bonus": []
    },
    "backgroundSolidity": {
      "disabled": true
    },
    "schoolPrestige": {
      "disabled": true
    },
    "startupnessImportance": "low",
    "importanceWeights": {
      "background": 1,
      "skills": 2,
      "jobPosition": 2
    },
    "extraCriteria": [
      {
        "importance": "absolute",
        "criterion": {
          "id": "#not",
          "params": {
            "son": {
              "id": "isFreelance",
              "params": {}
            }
          }
        }
      },
      {
        "importance": "required",
        "criterion": {
          "id": "background",
          "params": {
            "emplacement": "educations",
            "timeSelector": {
              "id": "ever"
            },
            "experienceSelector": {
              "id": "studyLevel",
              "params": {
                "studyLevel": "master"
              }
            }
          }
        }
      }
    ],
    "extraCriteriaRaw": [
      {
        "importance": "absolute",
        "criterion": {
          "selection": "#not",
          "#not": {
            "son": {
              "selection": "isFreelance"
            }
          }
        }
      },
      {
        "importance": "required",
        "criterion": {
          "selection": "background",
          "background": {
            "emplacement": "educations",
            "timeSelector": {
              "selection": "ever"
            },
            "experienceSelector": {
              "selection": "studyLevel",
              "studyLevel": "master"
            }
          }
        }
      }
    ]
  },
  "pushFlowPolicy": {
    "type": "daily-regular",
    "nbPerDay": 5,
    "maxStackSize": 15
  },
  "sweetsheetId": "fake-offer-crdajq",
  "clientId": "hiresweet2"
}
