import _ from 'underscore';
import moment from 'moment';

const sortSheetItems = ({ sheetItems, sortBy, sortAscending, statsAndScoreFromId }) => {
  const getProfilesGroups = (sheetItems, tags) => {
    return _.groupBy(sheetItems, (sheetItem) => {
      let group = 'default';
      let reversedTags = tags.slice().reverse();
      _.each(reversedTags, (tag) => {
        if (sheetItem.extraTags.indexOf(tag) >= 0) {
          group = tag;
        }
      });
      return group;
    });
  };
  if (sortBy === 'lastContact') {
    const tags = [ 'noEmail', 'default', 'holded', 'available', 'toUnupload', 'deleted','unavailable'];
    const sheetItemsGroups = getProfilesGroups(sheetItems, tags);
    _.each(sheetItemsGroups, (sheetItems, group) => {
      sheetItemsGroups[group] = _.sortBy(_.values(sheetItems), (sheetItem) => {
        const admin = (sheetItem || {}).admin || {};
        const date = moment(admin.lastContactDay || '2015-01-01', 'YYYY-MM-DD');
        return (sortAscending ? 1 : -1) * date.unix();
      });
    });
    return [].concat.apply([], _.map(tags, (tag) => sheetItemsGroups[tag] || []));
  }
  if (sortBy == 'toUpload'){
    return _.sortBy(sheetItems, ({admin, matches})=>{
      const hasLastContact = !_.isEmpty(admin.lastContactDay);
      const nbDisqualify = _.filter(matches, ({state})=>( state == "disqualified")).length
      const lastUploadCompletionTimestamp = admin.lastUploadCompletionTimestamp || 0
      return lastUploadCompletionTimestamp + (hasLastContact ? -1000 : 0) - nbDisqualify
    })
  }
  if (sortBy === 'nextContact') {
    const tags = ['noEmail', 'default', 'holded', 'available', 'toUnupload', 'deleted', 'unavailable'];
    const sheetItemsGroups = getProfilesGroups(sheetItems, tags);
    _.each(sheetItemsGroups, (sheetItems, group) => {
      sheetItemsGroups[group] = _.sortBy(_.values(sheetItems), (sheetItem) => {
        const admin = (sheetItem || {}).admin || {};
        const date = moment(admin.nextContactDay || '2015-01-01', 'YYYY-MM-DD');
        return (sortAscending ? 1 : -1) * date.unix();
      });
    });
    const groups = _.map(tags, (tag) => sheetItemsGroups[tag] || []);
    return [].concat.apply([], groups);
  } else {
    const tags = ['noEmail', 'toUnupload', 'deleted', 'toContact', 'default'];
    const sheetItemsGroups = getProfilesGroups(sheetItems, tags);
    _.each(sheetItemsGroups, (sheetItems, group) => {
      sheetItemsGroups[group] = _.sortBy(
        _.values(sheetItems),
        (sheetItem) => (sortAscending ? 1 : -1) * (statsAndScoreFromId[sheetItem.id].score || 0),
      );
    });
    return [].concat.apply([], _.map(tags, (tag) => sheetItemsGroups[tag] || []));
  }
};

export default sortSheetItems;
