function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

import React from 'react';
import { Grid, Checkbox as UCheckbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

var Clearable = function Clearable(props, ctx) {
  var InnerComponent = props.component;
  var field = props.field,
      innerProps = props.innerProps,
      nullValue = props.nullValue,
      nullLabel = props.nullLabel,
      compact = props.compact;
  var getState = ctx.getState,
      setState = ctx.setState;
  var path = [].concat(_toConsumableArray(ctx.path), [field]);
  var value = getState().getIn(path);
  var enabled = value !== undefined;

  var onChange = function onChange() {
    if (enabled) {
      setState(path, undefined);
    } else {
      setState(path, nullValue || null);
    }
  };

  return React.createElement(Grid, null, React.createElement(Grid.Row, null, React.createElement(Grid.Column, {
    width: 2
  }, React.createElement(UCheckbox, {
    slider: !compact,
    checked: enabled,
    onChange: onChange
  })), React.createElement(Grid.Column, {
    width: 14
  }, enabled ? React.createElement(InnerComponent, Object.assign({}, innerProps, {
    compact: compact,
    field: field
  })) : React.createElement('p', null, nullLabel ? nullLabel : '(Not set)'))));
};

Clearable.contextTypes = {
  path: PropTypes.array,
  getState: PropTypes.func,
  setState: PropTypes.func
};
export default Clearable;