import _ from 'underscore';
import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import GBQInformationCollectionView from './GBQInformationCollectionView';
import GBQQueryCollectionView from './GBQQueryCollectionView';
import GBQAdminCollectionView from './GBQAdminCollectionView';

// keyType = MetaType

class GBQCollectionView extends Component {
  componentDidMount() {
    this.loadSchema();
  }

  async loadSchema() {
    const { datasetId, projectId, table } = this.props.data || {};
    const tableId = table;
    const payload = {
      projectId,
      datasetId,
      tableId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getSchema';
    const schema = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ schema });
  }

  handleChangeActiveMenu(activeMenu) {
    this.setState({ activeMenu: activeMenu });
  }

  render() {
    const { datasetId, projectId, table, keyType, setId } = this.props.data || {};
    const type = 'bigquery';
    const activeMenu = (this.state || {}).activeMenu || 'Admin';
    const { schema } = this.state || {};
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            key={1}
            name='Admin'
            active={activeMenu == 'Admin'}
            onClick={() => {
              this.handleChangeActiveMenu('Admin');
            }}
          >
            Admin
          </Menu.Item>
          <Menu.Item
            key={2}
            name='Information'
            active={activeMenu == 'Information'}
            onClick={() => {
              this.handleChangeActiveMenu('Information');
            }}
          >
            Information
          </Menu.Item>
          <Menu.Item
            key={3}
            name='Query Playground'
            active={activeMenu == 'Query Playground'}
            onClick={() => {
              this.handleChangeActiveMenu('Query Playground');
            }}
          >
            Query Playground
          </Menu.Item>
        </Menu>
        {activeMenu == 'Query Playground' ? (
          <GBQQueryCollectionView
            schema={schema}
            datasetId={datasetId}
            tableId={table}
            projectId={projectId}
            type={type}
            keyType={keyType}
            displayEstimatedCost={true}
          />
        ) : activeMenu == 'Information' ? (
          <GBQInformationCollectionView
            setId={setId}
            schema={schema}
            datasetId={datasetId}
            tableId={table}
            projectId={projectId}
            type={type}
            keyType={keyType}
          />
        ) : (
          <GBQAdminCollectionView data={this.props.data} />
        )}
      </div>
    );
  }
}
export default GBQCollectionView;
