import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Form, Container } from 'semantic-ui-react';
import { SweetForm, Select, Input } from 'sweetform';

import baseUrl from './baseUrl.js';

// Actions
const SET_FORM = 'SET_FORM';
const setForm = (form) => ({ type: SET_FORM, form });

const SET_INSTANCE = 'SET_INSTANCE';
const setInstance = (instance) => ({ type: SET_INSTANCE, instance });

// Reducers
const form = (state = {}, action) => {
  switch (action.type) {
    case SET_FORM:
      return action.form;
    default:
      return state;
  }
};

const instance = (state = null, action) => {
  switch (action.type) {
    case SET_INSTANCE:
      return action.instance;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form,
  instance,
});

// Store
const store = createStore(rootReducer);

// Components
class Explorer extends Component {
  constructor(props) {
    super(props);
    this.collections = _.map(props.collections, (c) => ({
      label: c,
      value: c,
    }));
    this.defaultCollection = (props.collections || [])[0];
  }

  componentWillMount() {
    if (this.defaultCollection) {
      this.props.onSearch({ collection: this.defaultCollection });
    }
  }

  render() {
    const { form, instance, onChange, onSearch } = this.props;

    return (
      <Container fluid>
        <Form>
          <SweetForm onChange={onChange}>
            <Form.Group>
              <Form.Field width={4}>
                <Select
                  field="collection"
                  defaultValue={this.defaultCollection}
                  options={this.collections}
                  disabled={this.collections.length <= 1}
                />
              </Form.Field>
              <Form.Field width={10}>
                <Input field="id" placeholder="identifier" autoFocus />
              </Form.Field>
              <Form.Field width={2}>
                <Form.Button
                  fluid
                  content="Search"
                  color="teal"
                  onClick={() => onSearch(form)}
                />
              </Form.Field>
            </Form.Group>
          </SweetForm>
        </Form>
        <pre>
          {instance ? JSON.stringify(instance, null, '  ') : 'No result'}
        </pre>
      </Container>
    );
  }
}

// Containers
const mapSExplorer = (state) => ({
  form: state.form,
  instance: state.instance,
});

const mapDExplorer = (dispatch) => ({
  onChange: (form) => dispatch(setForm(form)),
  onSearch: async ({ collection, id }) => {
    const { data } = await axios.get(
      `${baseUrl}/instance/${collection}/${id || ''}`,
    );
    dispatch(setInstance(data));
  },
});

const ExplorerContainer = connect(
  mapSExplorer,
  mapDExplorer,
)(Explorer);

export default ({ collections }) => (
  <Provider store={store}>
    <ExplorerContainer collections={collections} />
  </Provider>
);
