import _ from 'underscore';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

class RequirementStatusView extends Component {
  render() {
    const { type, status } = this.props.data;
    return (
      <div>
        <Icon
          name={status == 'up' ? 'check circle' : status == 'error' ? 'warning circle' : 'refresh'}
          color={status == 'up' ? 'green' : status == 'error' ? 'red' : 'grey'}
          size='large'
        />
        <b style={{ color: status == 'up' ? 'green' : status == 'error' ? 'red' : 'grey' }}>{type}</b>
      </div>
    );
  }
}
export default RequirementStatusView;
