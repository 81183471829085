import React from 'react';
import _ from 'underscore';

import { Grid, Menu } from 'semantic-ui-react';

import SkillRecognition from './SkillRecognition';
import JobRecognition from './JobRecognition';
import ParsingJobAnalysis from './ParsingJobAnalysis';

class Recognition extends React.Component {
  handleChangeActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  render() {
    const tabNames = ['Skills', 'Jobs', 'Parsing Job Analysis'];
    const activeMenu = (this.state || {}).activeMenu;
    return (
      <Grid columns={2}>
        <Grid.Column width={1}>
          <Menu pointing secondary fluid vertical tabular>
            {_.map(tabNames, (tabName, index) => (
              <Menu.Item
                key={index}
                name={tabName}
                active={activeMenu === tabName}
                onClick={() => {
                  this.handleChangeActiveMenu(tabName);
                }}
              >
                {tabName}
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
        <Grid.Column width={15}>
          {activeMenu == 'Skills' ? (
            <SkillRecognition />
          ) : activeMenu == 'Jobs' ? (
            <JobRecognition />
          ) : activeMenu == 'Parsing Job Analysis' ? (
            <ParsingJobAnalysis />
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

export default Recognition;
