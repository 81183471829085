import React from 'react';
import axios from 'axios';
import { Button } from 'semantic-ui-react';

import baseUrl from '../../baseUrl';
import { WorkplaceFinder } from './WorkplaceFinder';

export class WorkplaceLinksEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onFirstWorkplaceSelect = this.onFirstWorkplaceSelect.bind(this);
    this.onSecondWorkplaceSelect = this.onSecondWorkplaceSelect.bind(this);
    this.onCreateLink = this.onCreateLink.bind(this);
  }

  onFirstWorkplaceSelect(workplace) {
    this.setState({ firstWorkplace: workplace });
  }

  onSecondWorkplaceSelect(workplace) {
    this.setState({ secondWorkplace: workplace });
  }

  onCreateLink() {
    axios.post(`${baseUrl}/workplaceAnnotations/annotateWorkplace`, {
      note: [
        {
          source: this.state.firstWorkplace.sourceId,
          id: this.state.firstWorkplace.id,
        },
        {
          source: this.state.secondWorkplace.sourceId,
          id: this.state.secondWorkplace.id,
        },
      ],
      type: 'match',
      user: 'sweethub',
    });
  }

  render() {
    return (
      <div>
        <WorkplaceFinder onWorkplaceSelect={this.onFirstWorkplaceSelect} />
        <Button
          color="green"
          onClick={this.onCreateLink}
          disabled={!(this.state.firstWorkplace && this.state.secondWorkplace)}
        >
          Create Link
        </Button>
        <WorkplaceFinder onWorkplaceSelect={this.onSecondWorkplaceSelect} />
      </div>
    );
  }
}
