const COLLECTIONS = [
  'githubProfiles',
  'stackOverflowProfiles',
  'linkedinCompanies',
  'linkedinProfiles',
  'crunchbaseCompanies',
  'hiredCompanies',
  'meetupGroups',
  'meetupProfiles',
  'wttjCompanies',
  'wttjOffers',
  'workableCompanies',
  'workableOffers',
  'jobijobaOffers',
  'hiresweetSheets',
  'hiresweetSheetsProfiles',
  'hiresweetStationClients',
  'hiresweetStationOffers',
  'hiresweetStationProfiles',
].sort();

export default COLLECTIONS;
