/* eslint react/jsx-no-target-blank: 0 */
import _ from 'underscore';
import React from 'react';

import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Container,
  Dropdown,
  Card,
  Grid,
  Header,
  Button,
  Icon,
  Image,
  Tab,
  Message,
  Accordion
} from 'semantic-ui-react';
import { SweetForm, Input } from 'sweetform';
import EditClientModal from './EditClientModal';
import EditOfferModal from './EditOfferModal';
import UpsertRecipientModal from './UpsertRecipientModal';
import EditOfferRecipientsModal from './EditOfferRecipientsModal';
import baseUrl from '../baseUrl';

// Actions
const SET_CLIENT_SEARCH = 'SET_CLIENT_SEARCH';
const setClientSearch = (clientSearch) => ({
  type: SET_CLIENT_SEARCH,
  clientSearch,
});

const SET_OFFER_SEARCH = 'SET_OFFER_SEARCH';
const setOfferSearch = (offerSearch) => ({
  type: SET_OFFER_SEARCH,
  offerSearch,
});

const SET_CLIENTS = 'SET_CLIENTS';
const setClients = (clients) => ({ type: SET_CLIENTS, clients });

const SET_CLIENT = 'SET_CLIENT';
const setClient = (client) => ({ type: SET_CLIENT, client });

const SET_OFFERS = 'SET_OFFERS';
const setOffers = (offers) => ({ type: SET_OFFERS, offers });

const SET_EDIT_MODE = 'SET_EDIT_MODE';
const setEditMode = (mode, initial = {}) => ({
  type: SET_EDIT_MODE,
  mode,
  initial,
});

// Reducers

const clientSearch = (state = {}, action) => {
  switch (action.type) {
    case SET_CLIENT_SEARCH:
      return action.clientSearch;
    default:
      return state;
  }
};

const offerSearch = (state = {}, action) => {
  switch (action.type) {
    case SET_OFFER_SEARCH:
      return action.offerSearch;
    default:
      return state;
  }
};

const clients = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return action.clients;
    default:
      return state;
  }
};

const client = (state = null, action) => {
  switch (action.type) {
    case SET_CLIENT:
      return action.client;
    default:
      return state;
  }
};

const offers = (state = null, action) => {
  switch (action.type) {
    case SET_OFFERS:
      return action.offers;
    default:
      return state;
  }
};

const editMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.mode;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  clientSearch,
  offerSearch,
  clients,
  client,
  offers,
  editMode,
});

// Store
const store = createStore(rootReducer);

class ClientsOverview extends React.PureComponent {
  state = {};

  handleSelectClient = (client) => {
    this.setState({ selectedClient: client }, () => {
      this.props.onOpenEditClient();
    });
  };
  handleAddClient = (newClient) => {
    const addMode = true;
    this.props.onSubmitClient(newClient, addMode);
    this.props.onCloseEdition();
    this.setState({ selectedClient: null });
  };
  handleEditClient = (newClient) => {
    const addMode = false;
    this.props.onSubmitClient(newClient, addMode);
    this.props.onCloseEdition();
    this.setState({ selectedClient: null });
  };
  handleDeleteClient = (newClient) => {
    this.props.onDeleteClient(newClient);
    this.props.onCloseEdition();
    this.setState({ selectedClient: null });
  };
  renderClientsGroup(clients, adminState) {
    const { onChangeClientAdminState, offers } = this.props;
    const selectedClientId = this.props.client ? this.props.client.id : '';

    let nbActiveOffersFromClientId = {};
    _.each(offers, (offer) => {
      if (offer.clientId && offer.adminState === 'active') {
        if (nbActiveOffersFromClientId[offer.clientId] === undefined) {
          nbActiveOffersFromClientId[offer.clientId] = 0;
        }
        nbActiveOffersFromClientId[offer.clientId]++;
      }
    });

    return (
      <Grid>
        {_.map(clients, (client, index) => {
          const folderLink = client.folderId
            ? 'https://drive.google.com/drive/u/0/folders/' + client.folderId
            : null;
          return (
            <Grid.Row key={index}>
              <Grid.Column
                width={10}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (client.id === selectedClientId) {
                    this.props.onSelectClient(null);
                  } else {
                    this.props.onSelectClient(client.id);
                  }
                }}
              >
                <Icon
                  name="write"
                  style={{ cursor: 'pointer' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleSelectClient(client);
                  }}
                />
                <span
                  style={
                    client.id === selectedClientId
                      ? {
                          fontWeight: 'bold',
                          color: 'green',
                          fontSize: '13px',
                        }
                      : {}
                  }
                >
                  {client.name} ({nbActiveOffersFromClientId[client.id] || 0})
                </span>
              </Grid.Column>
              <Grid.Column width={1} style={{ padding: 0 }}>
                {folderLink ? (
                  <a href={folderLink} target="_blank">
                    <Image
                      style={{ width: '15px', height: '15px' }}
                      src="/images/google-drive.png"
                    />
                  </a>
                ) : null}
              </Grid.Column>
              <Grid.Column width={2} style={{ padding: 0 }}>
                {client.workplaceId ? (
                  <a
                    href={`https://app.hiresweet.com/client/${client.id}`}
                    target="_blank"
                  >
                    <Image
                      src="/images/sweetapp-logo.png"
                      style={{ width: '15px', height: '15px' }}
                    />
                  </a>
                ) : null}
              </Grid.Column>
              <Grid.Column width={3}>
                {_.map(
                  ['active', 'passive', 'backlog'],
                  (adminStateChoice, index) =>
                    adminStateChoice !== adminState && (
                      // eslint-disable-next-line
                      <a
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onChangeClientAdminState(client.id, adminStateChoice);
                        }}
                      >
                        {' '}
                        {adminStateChoice[0].toUpperCase()}{' '}
                      </a>
                    ),
                )}
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    );
  }
  renderClientsGroups() {
    let clientsFromAdminState = {
      active: [],
      passive: [],
      backlog: [],
    };
    const search =
      this.props.clientSearch && this.props.clientSearch.value
        ? this.props.clientSearch.value.trim().toLowerCase()
        : null;
    _.each(this.props.clients, (client) => {
      const adminState =
        _.isString(client.adminState) && !_.isEmpty(client.adminState)
          ? client.adminState.toLowerCase()
          : 'passive';
      if (!search || client.name.toLowerCase().indexOf(search) >= 0 || client.id.toLowerCase().indexOf(search) >= 0) {
        if (clientsFromAdminState[adminState] === undefined) {
          clientsFromAdminState[adminState] = [];
        }
        clientsFromAdminState[adminState].push(client);
      }
    });
    const panes = _.map(clientsFromAdminState, (targetClients, adminState) => ({
      menuItem:
        adminState[0].toUpperCase() +
        adminState.slice(1) +
        ' (' +
        targetClients.length +
        ')',
      render: () => (
        <Tab.Pane>
          {this.renderClientsGroup(targetClients, adminState)}
        </Tab.Pane>
      ),
    }));
    return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
  }
  renderWarnings() {
    let warnings = [];
    const clients = this.props.clients || [];
    const warningClients = _.filter(
      clients,
      ({ isFake, workplaceId }) => !isFake && !workplaceId,
    );
    if (!_.isEmpty(warningClients)) {
      warnings.push({
        title:
          warningClients.length +
          ' unmapped client' +
          (warningClients.length !== 1 ? 's' : '') +
          ' !',
        text:
          'Examples: ' +
          _.reduce(
            _.shuffle(warningClients).slice(0, 10),
            (memo, { id }) => memo + (memo !== '' ? ', ' : '') + id,
            '',
          ),
      });
    }

    return (
      <div>
        {_.map(warnings, (warning, index) => (
          <Message error key={index}>
            {warning.title && <Message.Header>{warning.title}</Message.Header>}
            {warning.text && <p>{warning.text}</p>}
          </Message>
        ))}
      </div>
    );
  }
  render() {
    const {
      onChangeClientSearch,
      // onPrepareEmails,
      // onEraseEmails,
      // onSendEmails,
      editMode,
      onOpenAddClient,
      onCloseEdition,
      // clientSearch,
    } = this.props;
    const { selectedClient } = this.state;
    return (
      <Container>
        {/*this.renderWarnings()*/}
        <Grid>
          <Grid.Row>
            <Grid.Column width={5} verticalAlign="middle">
              <h3>
                <span>Clients </span>
                <Icon
                  name="add user"
                  color="blue"
                  style={{ cursor: 'pointer' }}
                  onClick={onOpenAddClient}
                />
              </h3>
              {editMode === 'addClient' ? (
                <EditClientModal
                  onClose={onCloseEdition}
                  onSubmit={this.handleAddClient}
                  initialValues={{
                    watchCollect: true,
                    termsOfService: true,
                    language: 'fr',
                    diversityAnalytics: true,
                    advancedTemplating: true,
                    canAskEmailEnrichmentCredits: false,
                  }}
                  creationMode
                />
              ) : null}
              {editMode === 'editClient' && selectedClient ? (
                <EditClientModal
                  onClose={onCloseEdition}
                  onSubmit={this.handleEditClient}
                  onDelete={this.handleDeleteClient}
                  edition={true}
                  initialValues={{
                    ...selectedClient,
                    ...(selectedClient || {}).permissions,
                  }}
                />
              ) : null}
            </Grid.Column>
            <Grid.Column width={11}>
              <SweetForm onChange={onChangeClientSearch}>
                <Input field="value" />
              </SweetForm>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderClientsGroups()}
      </Container>
    );
  }
}

class ClientSection extends React.PureComponent {
  state = {};
  handleSaveRecipient = (recipient) => {
    const newRecipient = {
      clientId: this.props.client ? this.props.client.id : null,
      ...recipient,
    };
    this.props.onAddRecipient(newRecipient);
    this.setState({ selectedRecipient: null });
    this.props.onCloseEdition();
  };
  handleSelectRecipient = (recipient) => {
    this.setState({ selectedRecipient: recipient }, () => {
      this.props.onOpenEditRecipient();
    });
  };
  handleToggleShowRecipients = () => {
    this.setState({ showRecipients: !this.state.showRecipients })
  }
  renderRecipients() {
    const {
      client,
      onRemoveRecipient,
      editMode,
      onOpenUpsertRecipient,
      onCloseEdition,
    } = this.props;
    const recipients = this.props.client.recipients || [];
    return (
      <Container>
        <Grid padded>
          {_.map(recipients, (recipient, index) => (
            <Grid.Row key={index}>
              <Grid.Column width={8}>
                <Header>
                  <Image
                    src={recipient.photoLink || '/images/defaultPhoto.png'}
                    circular
                    style={{ maxWidth: '60px' }}
                    onError={(e) => {
                      e.target.src = '/images/defaultPhoto.png';
                    }}
                  />
                  <Header.Content>
                    {recipient.firstName} ({recipient.alias})
                    <Header.Subheader>{recipient.email}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column verticalAlign="middle" textAlign="right" width={8}>
                <Icon
                  name="edit"
                  size="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.handleSelectRecipient(recipient);
                  }}
                />
                <Icon
                  name="delete"
                  size="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onRemoveRecipient(recipient);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        <Button
          style={{ marginTop: '10px' }}
          color="green"
          onClick={onOpenUpsertRecipient}
        >
          Add Recipient
        </Button>
        {editMode === 'upsertRecipient' && (
          <UpsertRecipientModal
            headerText={'Recipient (' + client.name + ')'}
            initialValues={{}}
            onSubmit={this.handleSaveRecipient}
            onClose={onCloseEdition}
          />
        )}
        {editMode === 'editRecipient' && this.state.selectedRecipient && (
          <UpsertRecipientModal
            headerText={'Recipient (' + client.name + ')'}
            initialValues={this.state.selectedRecipient}
            onSubmit={this.handleSaveRecipient}
            onClose={onCloseEdition}
          />
        )}
      </Container>
    );
  }
  renderSelectAdminState() {
    const { client, onChangeClientAdminState } = this.props;
    const adminState = client.adminState || 'passive';
    const options = [
      { value: 'active', text: 'Active' },
      { value: 'passive', text: 'Passive' },
      { value: 'backlog', text: 'Backlog' },
    ];
    return (
      <Dropdown
        options={options}
        placeholder={adminState}
        value={adminState}
        onChange={(e, { value }) => {
          onChangeClientAdminState(client.id, value);
        }}
      />
    );
  }
  renderCard() {
    const { client } = this.props;
    const { showRecipients } = this.state;

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14}>
                  {client.name}
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {' '}
                    -{' '}
                  </span>
                  {this.renderSelectAdminState()}
                </Grid.Column>
                <Grid.Column width={2} textAlign="right">
                  <Icon
                    color="grey"
                    name="close"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.props.onSelectClient(null);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Header>
          <Card.Description>
            <Accordion>
              <Accordion.Title
               content={'Show recipients (' + (this.props.client.recipients || []).length + ')'}
               index={0}
               onClick={() => { this.handleToggleShowRecipients() }} 
              >
              </Accordion.Title>
              <Accordion.Content active={showRecipients}>
                {this.renderRecipients()}
              </Accordion.Content>
            </Accordion>
            
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
  render() {
    const { client } = this.props;
    if (!client) {
      return null;
    }
    return <Container fluid>{this.renderCard()}</Container>;
  }
}

class OffersOverview extends React.PureComponent {
  state = {};
  handleSelectOffer = (offer) => {
    this.setState({ selectedOffer: offer }, () => {
      this.props.onOpenEditOffer();
    });
  };
  wrapOffer = (offer) => ({
    ...offer,
    ...offer.adminTags !== undefined && {
      adminTags: _.isString(offer.adminTags) ? (
        _.compact(offer.adminTags.split(';'))
      ) : _.isArray(offer.adminTags) ? ( //should not happen
        offer.adminTags
      ) : []
    }
  });
  unwrapOffer = (offer) => ({
    ...offer,
    ..._.isArray(offer.adminTags) && {
      adminTags: offer.adminTags.join(';')
    }
  });
  handleAddOffer = (newOffer) => {
    const addMode = true;
    this.props.onSubmitOffer(this.wrapOffer(newOffer), addMode);
    this.props.onCloseEdition();
    this.setState({ selectedOffer: null });
  };
  handleEditOffer = (newOffer) => {
    const addMode = false;
    this.props.onSubmitOffer(this.wrapOffer(newOffer), addMode);
    this.props.onCloseEdition();
    this.setState({ selectedOffer: null });
  };
  handleDeleteOffer = (newOffer) => {
    this.props.onDeleteOffer(newOffer);
    this.props.onCloseEdition();
    this.setState({ selectedOffer: null });
  };
  handleEditOfferRecipients = (offer) => {
    const clientId = offer.clientId;
    if (!clientId) {
      return alert('no clientId for this offer');
    }
    this.props.onFetchClient(clientId).then((client) => {
      this.setState(
        {
          offerRecipientsEdition: {
            clientId: offer.clientId,
            offerId: offer.id,
            selectedRecipients: offer.automaticMailRecipients || [],
            clientRecipients: client.recipients || [],
          },
        },
        () => this.props.onOpenEditOfferRecipients(),
      );
    });
  };
  handleSaveRecipients = (recipients) => {
    const { clientId, offerId } = this.state.offerRecipientsEdition || {};
    if (!offerId) {
      return alert('offerId no retrived in edition');
    }
    this.props.onUpdateOfferRecipients(clientId, offerId, recipients);
    this.props.onCloseEdition();
    this.setState({ offerRecipientsEdition: null });
  };
  handleCloseRecipientsEdition = () => {
    this.setState({ offerRecipientsEdition: null });
    this.props.onCloseEdition();
  };
  renderMailRecipients(offer) {
    const recipients = offer.automaticMailRecipients || [];
    const detailText = _.reduce(
      recipients,
      (memo, { email, alias }) =>
        memo + (memo !== '' ? '\n' : '') + (alias ? alias : '') + ' ' + email,
      '',
    );

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <span title={detailText}>
              {recipients.length} recipient{recipients.length !== 1 ? 's' : ''}
            </span>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right" verticalAlign="middle">
            <Icon
              name="edit"
              size="large"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.handleEditOfferRecipients(offer);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderOffersGroup(offers, adminState) {
    const { clients, client, onChangeOfferAdminState } = this.props;
    let clientUrlCode = {};
    _.each(clients, ({ id, urlcode }) => {
      clientUrlCode[id] = urlcode;
    });
    const sortedOffers = _.sortBy(
      offers,
      (offer) =>
        (client ? '' : '[' + (offer.clientId || '').toLowerCase() + '] ') +
        (offer.title || '').toLowerCase(),
    );
    return (
      <Grid>
        {_.map(sortedOffers, (offer, index) => {
          return (
            <Grid.Row key={index}>
              <Grid.Column width={5}>
                <Icon
                  name="write"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleSelectOffer(offer)}
                />
                <span>
                  {(client ? '' : '[' + offer.clientId + '] ') + offer.title}{' '}
                </span>
              </Grid.Column>
              <Grid.Column
                width={1}
                textAlign="right"
                style={{ paddingRight: 2 }}
              >
                {offer.sheetId && (
                  <a
                    href={
                      'https://docs.google.com/spreadsheets/d/' + offer.sheetId
                    }
                    target="_blank"
                  >
                    <Image
                      src="/images/google-sheet.png"
                      style={{ width: '15px', height: '15px' }}
                    />
                  </a>
                )}
                {offer.sweetsheetId && (
                  <a
                    href={'flowboard/sweetsheets/id/' + offer.sweetsheetId}
                    target="_blank"
                  >
                    <Icon
                      style={{ marginLeft: 3 }}
                      link
                      size="large"
                      name="list"
                      color="blue"
                    />
                  </a>
                )}
              </Grid.Column>
              <Grid.Column width={1} style={{ paddingLeft: 0 }} />
              <Grid.Column width={6}>
                {this.renderMailRecipients(offer)}
              </Grid.Column>
              <Grid.Column width={3}>
                {_.map(
                  ['active', 'passive', 'backlog'],
                  (adminStateChoice, index) =>
                    adminState.indexOf(adminStateChoice) < 0 && (
                      // eslint-disable-next-line
                      <a
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onChangeOfferAdminState(offer.id, adminStateChoice);
                        }}
                      >
                        {' '}
                        {adminStateChoice[0].toUpperCase() +
                          adminStateChoice.slice(1)}{' '}
                      </a>
                    ),
                )}
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    );
  }
  renderOffersGroups() {
    const { clients, client } = this.props;
    const search =
      this.props.offerSearch && this.props.offerSearch.value
        ? this.props.offerSearch.value.trim().toLowerCase()
        : null;

    let offersFromAdminState = {
      active: [],
      'semi-active': [],
      passive: [],
      backlog: [],
    };

    const offers = _.filter(
      this.props.offers,
      (offer) => !client || offer.clientId === client.id,
    );

    const activeClients = {};
    _.each(clients, (curClient) => {
      if (curClient.id && curClient.adminState === 'active') {
        activeClients[curClient.id] = true;
      }
    });

    _.each(offers, (offer) => {
      const baseAdminState =
        _.isString(offer.adminState) && !_.isEmpty(offer.adminState)
          ? offer.adminState.toLowerCase()
          : 'passive';
      const isClientActive = activeClients[offer.clientId];
      const adminState =
        baseAdminState === 'active' && !isClientActive
          ? 'semi-active'
          : baseAdminState;

      if (
        !search ||
        offer.title.toLowerCase().indexOf(search) >= 0 ||
        (offer.clientId || '').toLowerCase().indexOf(search) >= 0 ||
        (offer.id || '').toLowerCase().indexOf(search) >= 0
      ) {
        if (offersFromAdminState[adminState] === undefined) {
          offersFromAdminState[adminState] = [];
        }
        offersFromAdminState[adminState].push(offer);
      }
    });
    const panes = _.map(offersFromAdminState, (targetOffers, adminState) => ({
      menuItem:
        adminState[0].toUpperCase() +
        adminState.slice(1) +
        ' (' +
        targetOffers.length +
        ')',
      render: () => (
        <Tab.Pane>{this.renderOffersGroup(targetOffers, adminState)}</Tab.Pane>
      ),
    }));
    return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
  }
  renderWarnings() {
    let warnings = [];
    const offers = this.props.offers || [];
    const warningOffers = _.filter(
      offers,
      ({ isFake, sweethubId }) => !isFake && !sweethubId,
    );
    if (!_.isEmpty(warningOffers)) {
      warnings.push({
        title:
          warningOffers.length +
          ' unmapped offer' +
          (warningOffers.length !== 1 ? 's' : '') +
          ' !',
        text:
          'Examples: ' +
          _.reduce(
            _.shuffle(warningOffers).slice(0, 10),
            (memo, { id }) => memo + (memo !== '' ? ', ' : '') + id,
            '',
          ),
      });
    }

    return (
      <div>
        {_.map(warnings, (warning, index) => (
          <Message error key={index}>
            {warning.title && <Message.Header>{warning.title}</Message.Header>}
            {warning.text && <p>{warning.text}</p>}
          </Message>
        ))}
      </div>
    );
  }
  render() {
    const {
      client,
      onChangeOfferSearch,
      editMode,
      onCloseEdition,
      onOpenAddOffer,
    } = this.props;

    const clientOffers = _.filter(
      this.props.offers,
      (offer) => !client || client.id === offer.clientId,
    );
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            {/*this.renderWarnings()*/}
            <Grid>
              <Grid.Row>
                <Grid.Column width={3} verticalAlign="middle">
                  <h3>
                    Offers
                    {client ? ' (' + client.name + ') ' : ' '}
                    {client && (
                      <Icon
                        name="add circle"
                        color="blue"
                        style={{ cursor: 'pointer' }}
                        onClick={onOpenAddOffer}
                      />
                    )}
                  </h3>
                </Grid.Column>
                <Grid.Column width={13}>
                  <SweetForm onChange={onChangeOfferSearch}>
                    <Input field="value" />
                  </SweetForm>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Header>
          <Card.Meta>
            <span>{clientOffers.length} offers</span>
          </Card.Meta>
          <Card.Description>
            {this.renderOffersGroups()}
            {editMode === 'editOfferRecipients' &&
              this.state.offerRecipientsEdition && (
                <EditOfferRecipientsModal
                  clientRecipients={
                    this.state.offerRecipientsEdition.clientRecipients
                  }
                  selectedRecipients={
                    this.state.offerRecipientsEdition.selectedRecipients
                  }
                  onSubmit={this.handleSaveRecipients}
                  onClose={this.handleCloseRecipientsEdition}
                />
              )}
            {editMode === 'addOffer' && client ? (
              <EditOfferModal
                onClose={onCloseEdition}
                onSubmit={this.handleAddOffer}
                initialValues={{ clientId: client.id }}
              />
            ) : null}
            {editMode === 'editOffer' && this.state.selectedOffer ? (
              <EditOfferModal
                edition={true}
                onClose={onCloseEdition}
                onSubmit={this.handleEditOffer}
                onDelete={this.handleDeleteOffer}
                initialValues={this.unwrapOffer(this.state.selectedOffer)}
              />
            ) : null}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

class Dashboard extends React.PureComponent {
  componentDidMount() {
    this.props.onLoadClients();
    this.props.onLoadOffers();
  }
  render() {
    const clientId = this.props.client ? this.props.clientId : '__no-client';
    const { clientSearch, ...offersViewProps } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          top: '68px',
          left: 0,
          height: 'calc(100vh - 68px)',
          width: '100wh',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '25%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <ClientsOverview {...this.props} />
          </div>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '75%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <ClientSection {...this.props} />
            <OffersOverview key={clientId} {...offersViewProps} />
          </div>
        </div>
      </div>
    );
  }
}

// Containers
const mapSStation = (state) => ({
  clientSearch: state.clientSearch,
  offerSearch: state.offerSearch,
  clients: state.clients,
  client: state.client,
  offers: state.offers,
  editMode: state.editMode,
});

const loadClients = async (dispatch) => {
  dispatch(setClients([]));
  const { data } = await axios.get(baseUrl + '/station/clients');
  const clients = _.sortBy(data, ({ id }) => (id || '').toLowerCase());
  dispatch(setClients(clients));
};

const loadOffers = async (dispatch) => {
  dispatch(setOffers([]));
  const result = await axios.get(baseUrl + '/station/offers');
  dispatch(setOffers(result.data));
};

const loadClientInstant = async (dispatch, clientId) => {
  if (clientId) {
    const result = await axios.get(baseUrl + '/station/clients/' + clientId);
    dispatch(setClient(result.data));
  } else {
    dispatch(setClient(null));
  }
};

const loadClient = async (dispatch, clientId) => {
  dispatch(setClient(null));
  loadClientInstant(dispatch, clientId);
};

const reloadOfferInList = async (dispatch, offerId) => {
  const result = await axios.get(baseUrl + '/station/offers/' + offerId);
  const offer = result.data;
  if (!offer) {
    return;
  }
  const offers = store.getState().offers;
  const newOffers = _.map(offers, (currentOffer) =>
    currentOffer.id === offer.id ? offer : currentOffer,
  );
  dispatch(setOffers(newOffers));
};

const mapDStation = (dispatch) => ({
  onChangeClientSearch: (s) => {
    dispatch(setClientSearch(s));
  },
  onChangeOfferSearch: (s) => {
    dispatch(setOfferSearch(s));
  },
  onLoadClients: async () => {
    await loadClients(dispatch);
  },
  onSelectClient: async (clientId) => {
    await loadClientInstant(dispatch, clientId);
  },
  onLoadOffers: async () => {
    await loadOffers(dispatch);
  },
  onRemoveRecipient: async (recipient) => {
    if (!recipient.clientId) {
      alert('recipient without clientId');
      return;
    }
    await axios.delete(
      baseUrl + '/station/clients/' + recipient.clientId + '/recipients',
      {
        data: recipient,
      },
    );
    await loadClientInstant(dispatch, recipient.clientId);
  },
  onAddRecipient: async (recipient) => {
    if (!recipient.clientId) {
      alert('recipient without clientId');
      return;
    }
    if (!recipient.email) {
      alert('recipient without email');
      return;
    }
    await axios.post(
      baseUrl + '/station/clients/' + recipient.clientId + '/recipients',
      recipient,
    );
    await loadClientInstant(dispatch, recipient.clientId);
  },
  onChangeClientAdminState: async (clientId, adminState) => {
    await axios.put(baseUrl + '/station/clients/' + clientId + '/adminState', {
      adminState,
    });
    await loadClients(dispatch);
    const currentClient = store.getState().client;
    if (currentClient) {
      await loadClient(dispatch, currentClient.id);
    }
  },
  onChangeOfferAdminState: async (offerId, adminState) => {
    await axios.put(baseUrl + '/station/offers/' + offerId + '/adminState', {
      adminState,
    });
    await loadOffers(dispatch);
  },
  onFetchClient: async (clientId) => {
    const result = await axios.get(baseUrl + '/station/clients/' + clientId);
    return result.data;
  },
  onUpdateOfferRecipients: async (clientId, offerId, recipients) => {
    await axios.put(
      `${baseUrl}/station/clients/${clientId}/offers/${offerId}/recipients`,
      { recipients },
    );
    reloadOfferInList(dispatch, offerId);
  },
  onPrepareEmails: async () => {
    alert('Mail generation will begin when you close this alert');
    const { data } = await axios.get(
      `${baseUrl}/station/prepareAutomaticMails`,
    );
    if (data.error) {
      return alert(data.error);
    }
    alert('Morning mails generated');
  },
  onEraseEmails: async () => {
    const { data } = await axios.get(`${baseUrl}/station/deleteAutomaticMails`);
    if (data.error) {
      alert(data.error);
    }
    alert('Morning mails cleared');
  },
  onSendEmails: async () => {
    alert(
      'The emails will be sent, (you can still close your browser to cancel)',
    );
    const { data } = await axios.get(`${baseUrl}/station/sendAutomaticMails`);
    if (data.error) {
      return alert(data.error);
    }
    alert('The emails will be sent');
  },
  onSubmitClient: async (values, add = false) => {
    if (!values.name || !values.id) {
      return alert('name and id needed');
    }
    if (add) {
      console.log('create client form values', values);
      const { data } = await axios.post(
        `${baseUrl}/station/clients`,
        _.pick(
          values,
          'id',
          'name',
          'language',
          'isFake',
          'adminTags',
          'blacklistedCompanies',
          'watchCollect',
          'reveal',
          'secondFollowup',
          'maxNbFollowups',
          'emailEnrichment',
          'termsOfService',
          'extraTermsOfServiceConditions',
          'csvImportAndExport',
          'diversityAnalytics',
          'advancedTemplating',
          'canAskEmailEnrichmentCredits',
          'maxEnrichmentMonthlyAmountGivenForClient',
          'defaultEnrichmentMonthlyAmountGivenPerUser',
          'marketplaceSplitPending',
          'marketplaceUnifiedView',
        ),
      );

      if (data.error) {
        return alert(data.error);
      }
    } else {
      console.log(values);
      const { data } = await axios.put(
        `${baseUrl}/station/clients/${values.id}`,
        _.pick(
          values,
          'name',
          'language',
          'isFake',
          'adminTags',
          'watchCollect',
          'reveal',
          'termsOfService',
          'secondFollowup',
          'maxNbFollowups',
          'emailEnrichment',
          'blacklistedCompanies',
          'extraTermsOfServiceConditions',
          'csvImportAndExport',
          'diversityAnalytics',
          'advancedTemplating',
          'canAskEmailEnrichmentCredits',
          'maxEnrichmentMonthlyAmountGivenForClient',
          'defaultEnrichmentMonthlyAmountGivenPerUser',
          'marketplaceSplitPending',
          'marketplaceUnifiedView',
        ),
      );
      if (data.error) {
        return alert(data.error);
      }
    }

    if (values.workplaceId) {
      await axios.put(`${baseUrl}/workplaces/${values.workplaceId}`, {
        id: values.workplaceId,
        platformId: values.id,
      });
    }

    if (values.createRevealProject === true) {
      await axios.post(`${baseUrl}/station/clients/${values.id}/revealProject`);
    }

    dispatch(setEditMode('none'));
    await loadClients(dispatch);
  },
  onOpenAddClient: () => {
    dispatch(setEditMode('addClient'));
  },
  onOpenEditClient: () => {
    dispatch(setEditMode('editClient'));
  },
  onDeleteClient: async (values) => {
    if (!values.id) {
      return alert('id needed');
    }
    const id = values.id;
    await axios.delete(`${baseUrl}/station/clients/${id}`);
    dispatch(setEditMode('none'));
    await loadOffers(dispatch);
  },
  onOpenAddOffer: () => {
    dispatch(setEditMode('addOffer'));
  },
  onOpenEditOffer: (offer) => {
    dispatch(setEditMode('editOffer', offer));
  },

  onOpenEditOffer2: (offer) => {
    dispatch(setEditMode('editOffer2', offer));
  },

  onSubmitOffer: async (values, add = false) => {
    if (!values.id || !values.clientId || !values.title) {
      return alert('id, clientId and title needed');
    }
    if (add) {
      const { data } = await axios.post(
        `${baseUrl}/station/clients/${values.clientId}/offers`,
        values,
      );
      if (data.error) {
        return alert(data.error);
      }
    } else {
      const { data } = await axios.put(
        `${baseUrl}/station/clients/${values.clientId}/offers/${values.id}`,
        values,
      );
      if (data.error) {
        return alert(data.error);
      }
    }
    dispatch(setEditMode('none'));
    await loadOffers(dispatch);
  },
  onDeleteOffer: async (values) => {
    if (!values.id || !values.clientId || !values.title) {
      return alert('id, clientId and title needed');
    }
    await axios.delete(
      `${baseUrl}/station/clients/${values.clientId}/offers/${values.id}`,
    );
    dispatch(setEditMode('none'));
    await loadOffers(dispatch);
  },
  onOpenUpsertRecipient: () => {
    dispatch(setEditMode('upsertRecipient'));
  },
  onOpenEditRecipient: () => {
    dispatch(setEditMode('editRecipient'));
  },
  onOpenEditOfferRecipients: () => {
    dispatch(setEditMode('editOfferRecipients'));
  },
  onCloseEdition: () => {
    dispatch(setEditMode('none'));
  },
});

// Wrap
const DashboardContainer = connect(
  mapSStation,
  mapDStation,
)(Dashboard);

export default ({ match, history }) => (
  <Provider store={store}>
    <DashboardContainer match={match} history={history} />
  </Provider>
);
