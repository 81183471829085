import React, { Component } from 'react';

export default class DashBoardSearchHeader extends Component {
  render() {
    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: 16 }}>
        {this.props.stats}
      </div>
    );
  }
}
