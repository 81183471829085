import _ from 'underscore';
import React from 'react';
import { Popup, Label, Divider } from 'semantic-ui-react';

export const formatEntities = ({norms, conceptIds, lemmaIds}) => {
  return _.map(norms, (norm, i)=>({
    norm,
    conceptId: (conceptIds || [])[i],
    lemmaId: (lemmaIds || [])[i]
  }))
}

export const formatDisciplines = ({ids, norms}) => {
  return _.map(norms, (norm, i)=>({
    norm,
    id: ids[i],
  }))
}

export const Disciplines = ({disciplines}) => (
  <span>
    {_.map(disciplines, ({ norm, id }, i) => (
      <div key={i}>
        <Popup
          hoverable
          content={<p>{id}</p>}
          trigger={ <Label color='blue'>{norm} </Label>}
        />
      </div>
    ))}
  </span>
)

export const Entities = ({entities}) => (
  <span style={{lineHeight: "2em"}}>
    {_.map(entities, ({ norm, conceptId, lemmaId }, i) => (
        <Popup
          key={i}
          hoverable
          content={<p>{`Concept: ${conceptId}`}<br/>{`Lemma: ${lemmaId}`}</p>}
          trigger={ <Label color='blue'>{norm} </Label>}
        />
    ))}
  </span>
)

export const ListProperties = ({object, properties}) => (
  <div>
    {_.map(properties, (property, i)=>(<p key={i}><b>{property} : </b>{object[property]}</p>))}
  </div>
)

export const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
};

export const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};