import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import React from 'react';
import { Modal, Grid, Icon, List, Header, Button, Form, TextArea } from 'semantic-ui-react';
import Select from 'react-select';

class MetricEditor extends React.Component {

  state = {
    jsonStr: null,
    jsonIsCorrect: true,
    metricOptions: null,
    selectedOption: null,

  };

  componentDidMount() {
    this.handleLoadMetricOptions();
    this.handleChangeJsonStr({ jsonStr: JSON.stringify(this.props.initialValue, null, 4) });
    const { id, name } = this.props.initialValue || {}
    this.setState({ selectedOption: { value: id, label: name } });
  }

  handleSelectMetric = (option) => {
    const metric = _.findWhere(this.state.metrics, { id: option.value });
    this.setState({ selectedOption: option });
    this.handleChangeJsonStr({ jsonStr: JSON.stringify(metric, null, 4) });
  };

  handleLoadMetricOptions = async () => {
    const url = `${baseUrl}/challenges/metrics`;
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const metrics = data.results;
    this.setState({ metrics });
  };

  handleChangeJsonStr = ({ jsonStr }) => {
    let data = null;
    let jsonIsCorrect = true;
    try {
      data = JSON.parse(jsonStr);
    } catch (e) {
      jsonIsCorrect = false;
    }
    this.setState({
      jsonStr,
      jsonIsCorrect,
    });
    if (jsonIsCorrect) {
      this.props.onUpdate({ metric: data });
    }
  };

  render() {
    const metricOptions = _.map(this.state.metrics, ({ id, name }) => ({ value: id, label: name }));
    return (
      <div>
        <Select
            options={metricOptions}
            onChange={this.handleSelectMetric}
            value={this.state.selectedOption}
        />
        <Form>
          <TextArea
            style={{ borderColor: this.state.jsonIsCorrect ? 'lime' : 'orange' }}
            value={this.state.jsonStr}
            rows='25'
            onChange={({ target }) => {
              this.handleChangeJsonStr({ jsonStr: target.value });
            }}
          />
        </Form>
      </div>
    )
  }
}

class EditMetricsModal extends React.Component {
  state = {
    primary: null,
    secondary: null,
  };

  constructor(props) {
    super(props);
    this.state.primary = (props.metrics || {}).primary;
    this.state.secondary = (props.metrics || {}).secondary;
  };
  handleUpdatePrimaryMetric = ({ metric }) => {
    this.setState({ primary: metric });
  };
  handleUpdateSecondaryMetric = ({ metric }) => {
    this.setState({ secondary: metric });
  };
  handleSubmit = () => {
    if (!this.state.primary || !this.state.primary.params || !this.state.primary.params.type) {
      return alert('Invalid primary metric');
    }
    if (!this.state.secondary || !this.state.secondary.params.type || !this.state.secondary.params.type) {
      return alert('Invalid secondary metric');
    }
    this.props.onSubmit({
      metrics: {
        primary: this.state.primary,
        secondary: this.state.secondary,
      },
    });
    this.props.onClose();
  };
  render() {
    const { metrics, onClose } = this.props;
    const { primary, secondary } = metrics || {};


    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit Metrics</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid columns={2}>
            <Grid.Column width={8}>
              <Header as="h2">Primary</Header>
              <MetricEditor initialValue={primary} onUpdate={this.handleUpdatePrimaryMetric} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h2">Secondary</Header>
              <MetricEditor initialValue={secondary} onUpdate={this.handleUpdateSecondaryMetric} />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => this.handleSubmit()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );

  }
}

class MetricsSelector extends React.Component {

  state = {
    editMetricsModalOpen: false,
  }

  handleOpenEditMetricsModal = () => {
    this.setState({ editMetricsModalOpen: true })
  }
  handleCloseEditMetricsModal = () => {
    this.setState({ editMetricsModalOpen: false })
  }
  render() {
    const { metrics, onChangeMetricsSelection } = this.props;
    const { editMetricsModalOpen } = this.state;
    const { primary, secondary } = metrics || {};
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => { this.handleOpenEditMetricsModal() }}>
        <b><Icon name='pencil' /> Metrics</b>

        <List bulleted>
          <List.Item>Primary: <b>{primary.name || 'Custom'}</b></List.Item>
          <List.Item>Secondary: <b>{secondary.name || 'Custom'}</b></List.Item>
        </List>
        {editMetricsModalOpen && (
          <EditMetricsModal
            onClose={this.handleCloseEditMetricsModal}
            onSubmit={onChangeMetricsSelection}
            metrics={metrics}
          />
        )}
      </div>
    );
  }
}

export default MetricsSelector;
