import { Form, Grid, Icon } from 'semantic-ui-react';
import { List, Select, SelectInt, enhance } from 'sweetform';

import { Link } from 'react-router-dom';
import React from 'react';
import { Textarea } from '../../common';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl';

export const CONTRACT_TYPES = [
  { label: 'CDI', value: 'CDI' },
  { label: 'CDD', value: 'CDD' },
  { label: 'internship', value: 'Stage' },
];
export const SOURCE_TYPES = [
  { label: 'Welcome To The Jungle', value: 'wttjOffers' },
  { label: 'Workable', value: 'workableOffers' },
];
export const sourceTypeLabels = {
  wttjOffers: 'Welcome To The Jungle',
  workableOffers: 'Workable',
};
export const MAHER_CATEGORIES = [
  { id: "backend-js", name: "Backend JS" },
  { id: "frontend-js", name: "Frontend JS" },
  { id: "fullstack-js", name: "Fullstack JS" },
  { id: "integrator", name: "Integrator" },
  { id: "c-cpp", name: "C/C++" },
  { id: "csharp-dotnet", name: "C#/.NET" },
  { id: "go", name: "Go" },
  { id: "java", name: "Java" },
  { id: "php", name: "PHP" },
  { id: "python", name: "Python" },
  { id: "ruby", name: "Ruby" },
  { id: "data-engineer", name: "Data Engineer" },
  { id: "data-science", name: "Data Science" },
  { id: "business-intelligence", name: "Business Intelligence" },
  { id: "data-analyst", name: "Data Analyst" },
  { id: "security", name: "Security" },
  { id: "mobile-developer", name: "Mobile Developer" },
  { id: "devops-sysadmin", name: "Devops / Sysadmin" },
  { id: "quality-assurance", name: "Quality Assurance" },
  { id: "hardware-engineer", name: "Hardware Engineer" },
  { id: "lead-ceo-cto", name: "Lead / CEO / CTO" },
  { id: "sales", name: "Sales" },
  { id: "communication", name: "Communication" },
  { id: "operations", name: "Operations" },
  { id: "customer-success", name: "Customer Success" },
  { id: "human-ressources", name: "Human Ressources" },
  { id: "crm-manager", name: "CRM Manager" },
  { id: "product-designer", name: "Product Designer" },
  { id: "product-manager", name: "Product Manager" },
  { id: 'project-manager', name: 'Project Manager' },
  { id: 'other', name: 'Other' },
];
export const PUSH_FLOW_POLICIES = [{ label: 'daily-regular', value: 'daily-regular' }];
export const OFFER_STATUS = [
  { value: 'current', label: 'Current' },
  { value: 'hold', label: 'Hold' },
  { value: 'finished', label: 'Finished' },
];
export const EXPERIENCE = [
  { value: 0, label: '0' },
  { value: 0.5, label: '6 mois' },
  { value: 1, label: '1 an' },
  ..._.map(_.range(2, 16), (value) => ({ value, label: `${value} ans` })),
];
export const SelectExperience = (props) => <Select {...props} options={EXPERIENCE} placeholder='Pas de limite' />;
const loadJobTitles = async (cerejobsV2) => {
  const jobTitles =
    cerejobsV2 === true
      ? (await axios.get(`${baseUrl}/tags/list?type=job&collectionName=tagsV2`)).data
      : (await axios.get(`${baseUrl}/tags/list?type=job`)).data;
  return { options: jobTitles, complete: true };
};
export const SelectJobTitles = (props) => {
  const { isFreeText, field, multi, cerejobsV2 } = props || {};
  if (isFreeText) {
    return (
      <Textarea
        style={{
          width: '100%',
          resize: 'none',
          height: '35px',
          overflow: 'hidden',
          display: 'table-cell',
        }}
        field={field}
        placeholder={multi ? 'separate your key words with ; (ex : cardiologist;doctor)' : ''}
      />
    );
  }
  return <Select {...props} async={true} loadOptions={() => loadJobTitles(cerejobsV2)} labelKey='name' valueKey='id' />;
};
const loadEducations = async () => {
  const getSchools = async () => {
    const url = `${baseUrl}/workplaces/listSchools`;
    const schoolsList = (await axios.get(url)).data;
    return schoolsList;
  };
  const getSchoolGroups = async () => {
    const url = `${baseUrl}/workplaces/listSchoolGroups`;
    const schoolGroups = (await axios.get(url)).data;
    return schoolGroups;
  };
  try {
    const schools = await getSchools();
    const schoolGroups = await getSchoolGroups();

    const other = [
      { id: 'phd', name: 'PhD' },
      { id: 'master', name: 'Master' },
      { id: 'bachelor', name: 'Bachelor' },
      { id: 'self-taught', name: 'Self Taught' },
      { id: 'university', name: 'University' },
      { id: 'prepa', name: 'Prepa' },
      { id: 'reorientation', name: 'Reorientation' },
    ];
    return schoolGroups.concat(other).concat(schools);
  } catch (e) {
    console.log('EditOfferModalComponents loadEducations failed ', e.message);
    return [];
  }
};
class SelectEducation extends React.Component {
  state = {
    educations: [],
  };
  componentDidMount = async () => {
    const educations = await loadEducations();
    this.setState({ educations });
  };
  render() {
    return <Select {...this.props} options={this.state.educations} labelKey='name' valueKey='id' />;
  }
}

export const EducationEditor = enhance(() => (
  <Grid columns={3}>
    <Grid.Column width={6}>
      <Form.Field>
        <label>Educations to target</label>
        <List field='target' component={SelectEducation} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width={6}>
      <Form.Field>
        <label>Educations to avoid</label>
        <List field='avoid' component={SelectEducation} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column width={4}>
      <Link target='_blank' to='/monitoring/knowledge/schoolsGroups'>
        <Icon name='question circle' />
        Schools groups
      </Link>
    </Grid.Column>
  </Grid>
));
const loadResponsibilities = async () => {
  const responsibilities = (await axios.get(`${baseUrl}/tags/list?type=job-responsibility`)).data;
  return { options: responsibilities, complete: true };
};
export const SelectResponsibilities = (props) => (
  <Select {...props} async={true} loadOptions={loadResponsibilities} labelKey='name' valueKey='id' />
);

const loadPlatformOffers = async () => {
  try {
    const platformOffers = (await axios.post(`${baseUrl}/station/miniOffers`)).data;
    return platformOffers;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export class SelectPlatformOffers extends React.Component {
  state = {
    platformOffers: [],
  };
  componentDidMount = async () => {
    const platformOffers = await loadPlatformOffers();
    const formattedPlatformOffers = _.map(
      _.sortBy(platformOffers, ({ adminState, archived })=> archived ? 2 : (adminState!='active' ? 1 : 0)), 
      ({id, title, adminState, archived})=> {
      const stateEmoji = archived ? "📦" : (adminState == 'passive' ? "⏸️" : (adminState=='active' ? "✅" : "❓"));
      return {
        label: `${stateEmoji} ${id} (${title})`,
        value: id
      }
    })
    this.setState({ platformOffers: formattedPlatformOffers });
  };

  render() {
    return <Select {...this.props} options={this.state.platformOffers} />;
  }
}

const loadUsers = async (input) => {
  const users = (await axios.get(`${baseUrl}/users`)).data;
  return { options: users, complete: true };
};
export const SelectUsers = (props) => (
  <Select {...props} async={true} loadOptions={loadUsers} labelKey='name' valueKey='id' />
);

export const SelectTags = (props) => (
  <Select {...props} options={[{ id: 'us', name: 'us' }]} labelKey='name' valueKey='id' creatable={true} />
);

export const PushFlowPolicy = enhance(() => (
  <Grid columns={3}>
    <Grid.Column>
      <Select options={PUSH_FLOW_POLICIES} field='type' placeholder='Type' defaultValue='daily-regular' />
    </Grid.Column>
    <Grid.Column>
      <SelectInt field='nbPerDay' placeholder='Number per day' min={0} max={1000} />
    </Grid.Column>
    <Grid.Column>
      <SelectInt field='maxStackSize' placeholder='Max stack size' min={0} max={1000} />
    </Grid.Column>
  </Grid>
));

const SelectSkill = (props) => <Select {...props} labelKey='name' valueKey='id' />;

const SkillsEditorRaw = ({ skills }) => (
  <Grid columns={3}>
    <Grid.Column>
      <Form.Field>
        <label>Required skills</label>
        <List field='required' component={SelectSkill} options={skills} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column>
      <Form.Field>
        <label>Important skills</label>
        <List field='important' component={SelectSkill} options={skills} />
      </Form.Field>
    </Grid.Column>
    <Grid.Column>
      <Form.Field>
        <label>Bonus skills</label>
        <List field='bonus' component={SelectSkill} options={skills} />
      </Form.Field>
    </Grid.Column>
  </Grid>
);

class SkillsEditorWithSkills extends React.Component {
  state = {
    skills: [],
  };
  componentDidMount = async () => {
    try {
      const skills = (await axios.get(`${baseUrl}/tags/list?type=skill`)).data;
      this.setState({ skills });
    } catch (e) {
      console.log('SkillsLoader failed to load skills ', e.message);
    }
  };
  render() {
    return <SkillsEditorRaw {...this.props} skills={this.state.skills} />;
  }
}

export const SkillsEditor = enhance(() => <SkillsEditorWithSkills />);
