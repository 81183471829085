import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import OfferCriteriaDiff from '../../components/OfferCriteriaDiff';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import _ from 'underscore';

class PushCriteriaModal extends React.Component {
  state = {};

  componentWillMount = async () => {
    const { platformId, sweethubOffer } = this.props;
    const oldCriteria = await this.loadPlatformCriteria(platformId);
    const sweethubCriteria = this.offerToCriteria(sweethubOffer);

    console.log(oldCriteria);
    console.log(sweethubOffer);
    console.log(sweethubCriteria);

    const mergedCriteria = { ...oldCriteria, ...sweethubCriteria };

    this.setState({ oldCriteria, newCriteria: mergedCriteria });
  };

  loadPlatformCriteria = async (platformId) => {
    const loadPlatformOffer = async (platformId) => {
      try {
        return (await axios.get(`${baseUrl}/station/offers/${platformId}`))
          .data;
      } catch (e) {
        console.log(e);
        return {};
      }
    };

    const platformOffer = await loadPlatformOffer(platformId);
    const criteria = (platformOffer || {}).criteria;
    if (_.isEmpty(criteria)) {
      console.log(`No criteria found for offer ${platformId}.`);
      return {};
    }

    const removeLabelField = (listOfDicts) => {
      return _.map(listOfDicts, (dict) => {
        return dict && _.omit(dict, 'label');
      });
    };

    const cirteriaWithoutLabel = {
      ...criteria,
      experience: criteria.experience,
      salary: criteria.salary,
      onlineLinks: criteria.onlineLinks,
      seekingCandidatesOnly: criteria.seekingCandidatesOnly,
      jobPositions: criteria.jobPositions && {
        required:
          criteria.jobPositions.required &&
          removeLabelField(criteria.jobPositions.required),
      },
      locations: criteria.locations && removeLabelField(criteria.locations),
      remote: criteria.remote && _.omit(criteria.remote, 'label'),
      skills: {
        required: removeLabelField((criteria.skills || {}).required),
        important: removeLabelField((criteria.skills || {}).important),
        bonus: removeLabelField((criteria.skills || {}).bonus),
      },
    };

    return cirteriaWithoutLabel;
  };

  offerToCriteria = (sweethubOffer) => {
    // Ignore diffs
    const toIdentifierDicts = (values) => {
      return _.map(values, (value) => ({ identifier: value }));
    };

    const filterEducation = educationList => {
      const mapping = {
        'self-taught': 'self-taught',
        'bootcamp': 'bootcamp',
        'bachelor': 'bachelor',
        'master': 'master',
        'phd': 'phd',
        'software-engineering-school': 'software-engineering-school',
        'all-engineering-school': 'engineering-school',
        'medium-engineering-school': 'engineering-school',
        'best-engineering-school': 'engineering-school',
        'all-business-school': 'business-school',
        'medium-business-school': 'business-school',
        'best-business-school': 'business-school',
      }
      return _.map( _.filter(educationList, item => mapping[item] !== undefined), item => ({identifier: mapping[item]}))
    }
    
    const criteria = {
      education: {
        important: filterEducation((sweethubOffer.education || {}).target),
      },
      experience: {
        years: sweethubOffer.experience,
      },
      jobPositions: sweethubOffer.jobId && {
        required: toIdentifierDicts([sweethubOffer.jobId]),
      },
      locations: sweethubOffer.location && [
        { identifier: sweethubOffer.location },
      ],
      onlineLinks: sweethubOffer.link,
      seekingCandidatesOnly: sweethubOffer.seekingCandidatesOnly,
      remote: { identifier: sweethubOffer.remote ? 'yes' : 'no' },
      salary: sweethubOffer.salaryRange,
      skills: sweethubOffer.skills && {
        required: toIdentifierDicts(sweethubOffer.skills.required),
        important: toIdentifierDicts(sweethubOffer.skills.important),
        bonus: toIdentifierDicts(sweethubOffer.skills.bonus),
      },

    };
    return criteria;
  };

  handleSubmit = async () => {
    const { onSubmit, platformId, onClose } = this.props;
    const { newCriteria } = this.state;
    try {
      const result = await onSubmit(newCriteria, platformId);
      if (!result.error) {
        onClose();
      } else {
        alert(result.error);
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  render() {
    const { onClose, platformId, onOpenEditOffer, sweethubOffer } = this.props;
    const { oldCriteria, newCriteria } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon size="fullscreen">
        <Modal.Header />
        <Modal.Content>
          <OfferCriteriaDiff
            jobOfferId={platformId}
            oldCriteria={oldCriteria}
            newCriteria={newCriteria}
            noOldCriteria={_.isEmpty(oldCriteria)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="orange" onClick={() => onOpenEditOffer(sweethubOffer)}>
            Edit Sweethub Offer
          </Button>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={this.handleSubmit}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default PushCriteriaModal;
