import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Button, Segment, Input, Modal, Icon } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';

import ProspectParamsSelector from './ProspectParamsSelector.js';
import ProspectsTable from './ProspectsTable.js';
import { ProspectQuickFilters, filterProspects } from "./ProspectQuickFilters.js";

import moment from 'moment';


class ProspectsSearch extends React.Component {
  state = {
    loading: true,
    filters: {
      clientsMode: "no-filter",
      contactsMode: "no-filter",
    },
  };

  componentDidMount = async () => {
    try {
      const offerRelevanceOptions = (await axios.get(`${baseUrl}/salesOptimisationApi/prospects/offerRelevanceOptions`)).data;
      const defaultRelevanceOption = _.find(offerRelevanceOptions, ({ id }) => (id == "default"));
      if (!defaultRelevanceOption) {
        throw Error("No offerRelevanceOptions found with id 'default'");
      }
      const prospectParams = {
        offerRecency: {
          dateUsed: "publication",
          minDate: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
          excludeContactedAfter: true,
        },
        offerRelevance: {
          mode: "predefined",
          predefinedId: "default",
          ...defaultRelevanceOption.offerRelevance,
        },
        offerSources: ["linkedin", "wttj", "indeed"],
        prospectFilters: {
          maxTreatmentDate: moment().subtract(2, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
          excludeSkippedProspects: true,
          excludeRecruitingCompanies: true,
        },
      }
      const { data: users } = await axios.get(`${baseUrl}/users`);
      this.setState({
        offerRelevanceOptions,
        prospectParams,
        loading: false,
        users,
      })
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  };
  handleChangeFilters = (filters) => {
    this.setState({ filters });
  }
  handleChangeProspectParams = (prospectParams) => {
    this.setState({ prospectParams });
  }

  handleSubmitSearch = async () => {
    try {
      const { prospectParams } = this.state;
      console.log("SEARCH")
      console.log({ prospectParams })
      this.setState({ computing: true });
      const { results } = (await axios.post(`${baseUrl}/salesOptimisationApi/prospects/search`, prospectParams)).data;
      console.log({ prospects: results, prospects: null })
      this.setState({ prospects: results, computing: false });
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
      this.setState({ computing: false });
    }
  }

  handleOpenDumpModal = async () => {
    this.setState({ dumpModalOpen: true });
  }

  handleCloseDumpModal = () => {
    this.setState({ dumpModalOpen: false });
  }

  handleChangeDumpName = async ({ value }) => {
    this.setState({ dumpName: value });
  }

  handleSubmitDump = async () => {
    try {
      const { prospectParams, dumpName } = this.state;
      this.setState({ computing: true, dumpModalOpen: false });
      const { success, dumpId } = (await axios.post(`${baseUrl}/salesOptimisationApi/prospects/dump`, { params: prospectParams, name: dumpName })).data;
      alert("Dumped !")
      this.setState({ computing: false });
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
      this.setState({ computing: false });
    }
  }


  render() {
    const { loading, computing, prospects, filters, prospectParams, offerRelevanceOptions } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }
    const filteredProspects = filterProspects({ prospects, filters });

    return (
      <Grid>
        <Grid.Row>
          <Segment>
            <ProspectParamsSelector handleChange={this.handleChangeProspectParams} prospectParams={prospectParams} offerRelevanceOptions={offerRelevanceOptions} />
            <Button icon disabled={computing} onClick={this.handleSubmitSearch} color="blue"><Icon name="search" />Search</Button>
            <Button icon disabled={computing} onClick={this.handleOpenDumpModal} color="green"><Icon name="save" />Dump</Button>
          </Segment>
        </Grid.Row>
        <Grid.Row>
          {!_.isEmpty(filteredProspects) && (
            <Grid.Column>
              <ProspectQuickFilters
                handleChange={this.handleChangeFilters}
                initialValues={this.state.filters}
                users={this.state.users}
              />
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          {!_.isEmpty(filteredProspects) && (
            <ProspectsTable prospects={filteredProspects} />
          )}
        </Grid.Row>
        {this.state.dumpModalOpen && (
          <Modal closeIcon open={true} onClose={this.handleCloseDumpModal}>
            <Modal.Content>
              <Input placeholder="name" onChange={(e, { value }) => this.handleChangeDumpName({ value })} />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleSubmitDump}>Submit</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Grid>
    );
  }
}

export default ProspectsSearch;
