import _ from 'underscore';
import React, { Component } from 'react';
import { Form, TextArea } from 'semantic-ui-react';

class JsonEditor extends Component {
  state = {
    jsonStr: '',
    jsonIsCorrect: true,
  };

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.state.jsonStr = props.stringify(props.initialValue);
    }
  }

  handleChangeJsonStr = ({ jsonStr }) => {
    let data = null;
    let jsonIsCorrect = true;
    try {
      data = JSON.parse(jsonStr);
    } catch (e) {
      jsonIsCorrect = false;
    }
    this.setState({
      jsonStr,
      jsonIsCorrect,
    });
    if (jsonIsCorrect) {
      this.props.onUpdate({ data });
    }
  };

  render() {
    return (
      <Form>
        <TextArea
          style={{ borderColor: this.state.jsonIsCorrect ? 'lime' : 'orange' }}
          value={this.state.jsonStr}
          rows='36'
          onChange={({ target }) => {
            this.handleChangeJsonStr({ jsonStr: target.value });
          }}
        />
      </Form>
    );
  }
}

export default JsonEditor;
