import _ from 'underscore';
import React, { Component } from 'react';

import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Grid,
  Header,
  Button,
  Table,
  Form,
  List,
  Icon,
  Image,
} from 'semantic-ui-react';
import { SweetForm, Input, Clearable, Select, Checkbox } from 'sweetform';

import { textToId, Textarea } from '../common';
import baseUrl from '../baseUrl';
import Modal from '../Modal';
import Pagination from '../Pagination';

const PER_PAGE = 10;

// Actions
const SET_EDIT = 'SET_EDIT';
const setEdit = (edit) => ({ type: SET_EDIT, edit });

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_CLIENTS = 'SET_CLIENTS';
const setClients = (clients, page) => ({ type: SET_CLIENTS, clients, page });

const SET_OFFERS = 'SET_OFFERS';
const setOffers = (offers, page) => ({ type: SET_OFFERS, offers, page });

const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
const setSelectedClient = (data) => ({ type: SET_SELECTED_CLIENT, data });

const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
const setSelectedOffer = (data) => ({ type: SET_SELECTED_OFFER, data });

const SET_EDIT_MODE = 'SET_EDIT_MODE';
const setEditMode = (mode, initial = {}) => ({
  type: SET_EDIT_MODE,
  mode,
  initial,
});

const UPDATE_OFFER = 'UPDATE_OFFER';
const updateOffer = (offer) => ({ type: UPDATE_OFFER, offer });

// Reducers
const clients = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return action.clients;
    case SET_SELECTED_CLIENT:
      if (!action.data) {
        return state;
      }
      const i = _.findIndex(state, (c) => c.id === action.data.id);
      return [...state.slice(0, i), action.data, ...state.slice(i + 1)];
    default:
      return state;
  }
};

const offers = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_CLIENT:
      return [];
    case SET_OFFERS:
      return action.offers;
    case UPDATE_OFFER:
      const i = _.findIndex(state, (o) => o.id === action.offer.id);
      return [...state.slice(0, i), action.offer, ...state.slice(i + i)];
    default:
      return state;
  }
};

const page = (state = 0, action) => {
  switch (action.type) {
    case SET_CLIENTS:
    case SET_OFFERS:
      return action.page || 0;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const selectedClient = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_CLIENT:
      return action.data;
    default:
      return state;
  }
};

const editMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.mode;
    default:
      return state;
  }
};

const edit = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.initial;
    case SET_EDIT:
      return action.edit;
    default:
      return state;
  }
};

const selectedOffer = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.initial;
    case SET_SELECTED_OFFER:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  search,
  clients,
  offers,
  selectedClient,
  selectedOffer,
  editMode,
  edit,
  page,
});

// Store
const store = createStore(rootReducer);

// Components
const bc = { style: { marginLeft: 10 } }; // button class
const ClientDetails = ({
  client,
  offers,
  page,
  onBack,
  onEditClient,
  onAddOffer,
  onEditOffer,
  onEditRecipients,
  onPage,
}) => {
  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={7}>
            <Button onClick={onBack}>Back to clients</Button>
          </Grid.Column>
          <Grid.Column width={9} textAlign="right">
            <Button {...bc} onClick={onEditClient} color="yellow">
              Edit {client.id}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <h1>Offers</h1>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button {...bc} onClick={onAddOffer} color="green">
              Add offer
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Recipients</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(offers, (offer) => (
            <Table.Row key={offer.id}>
              <Table.Cell onClick={() => onEditOffer(offer)}>
                {offer.id}
              </Table.Cell>
              <Table.Cell onClick={() => onEditOffer(offer)}>
                {offer.title}
              </Table.Cell>
              <Table.Cell onClick={() => onEditRecipients(offer)}>
                {_.map(offer.recipients, (r) => (
                  <p>{r.email}</p>
                ))}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={3}>
              <Pagination
                floated="right"
                current={page}
                total={100}
                onPage={onPage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

const ClientsList = ({ clients, page, onSelect, onAdd, onPage }) => (
  <div>
    <Grid columns={2}>
      <Grid.Column>
        <h1>Clients</h1>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button color="green" onClick={onAdd}>
          Add client
        </Button>
      </Grid.Column>
    </Grid>
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Reference</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Workplace</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(clients, (client) => (
          <Table.Row key={client.id} onClick={() => onSelect(client)}>
            <Table.Cell>{client.id}</Table.Cell>
            <Table.Cell>{client.name}</Table.Cell>
            <Table.Cell>{client.wpid}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>
            <Pagination
              floated="right"
              current={page}
              total={100}
              onPage={onPage}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  </div>
);
const loadWorkplaces = async (input) => {
  const search = JSON.stringify({ ref: input });
  const { workplaces } = (await axios.get(
    `${baseUrl}/workplaces?search=${search}`,
  )).data;
  return { options: workplaces };
};
const SelectWorkplaces = (props) => (
  <Select
    {...props}
    async={true}
    loadOptions={loadWorkplaces}
    labelKey="name"
    valueKey="id"
  />
);

const EditClientModal = ({
  edit,
  headerText,
  initialValues,
  onChangeEdit,
  onSubmit,
  onCancel,
}) => (
  <Modal
    active={true}
    headerText={headerText}
    submitText="Submit"
    onSubmit={onSubmit}
    onCancel={onCancel}
  >
    <SweetForm onChange={onChangeEdit} initialValues={initialValues}>
      <Form>
        <Form.Field>
          <label>Identifier</label>
          {initialValues ? (
            <Input disabled field="id" />
          ) : (
            <Clearable component={Input} field="id" nullLabel="Auto" />
          )}
        </Form.Field>
        <Form.Field>
          <label>Name</label>
          <Input autoFocus field="name" />
        </Form.Field>
        <Form.Field>
          <label>Folder Identifier</label>
          <Input field="folderId" />
        </Form.Field>
        <Form.Field>
          <label>Workplace</label>
          <SelectWorkplaces
            field="wpid"
            placeholder={
              edit && edit.wpid
                ? edit.wpid
                : initialValues
                ? initialValues.wpid
                : 'none'
            }
          />
        </Form.Field>
      </Form>
    </SweetForm>
    <div style={{ height: 200 }} />
  </Modal>
);
const EditRecipientsModal = ({
  edit,
  onChangeEdit,
  selectedOffer,
  onSaveNewRecipient,
  onRemoveRecipient,
  onCancel,
}) => {
  return (
    <Modal
      active={true}
      headerText="Edit recipients"
      submitText="Ok"
      onSubmit={onCancel}
      onCancel={onCancel}
    >
      <Grid columns="2">
        <Grid.Row>
          <Grid.Column>
            <List>
              {_.map(selectedOffer.recipients, (recipient) => (
                <List.Item>
                  <Image avatar src={recipient.photoLink} />
                  <List.Content>
                    <List.Header>{recipient.firstName}</List.Header>
                    <List.Description>
                      {recipient.alias} <b>{recipient.email}</b>
                    </List.Description>
                  </List.Content>
                  <List.Content floated="right">
                    <Icon
                      bordered
                      color="red"
                      name="delete"
                      size="large"
                      inverted
                      onClick={() => onRemoveRecipient(recipient)}
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">New Recipient</Header>
            <SweetForm onChange={onChangeEdit}>
              <Form>
                <Form.Field>
                  <label>email</label>
                  <Input field="email" />
                </Form.Field>
                <Form.Field>
                  <label>firstName</label>
                  <Input field="firstName" />
                </Form.Field>
                <Form.Field>
                  <label>alias</label>
                  <Input field="alias" />
                </Form.Field>
                <Form.Field>
                  <label>photoLink</label>
                  <Input field="photoLink" />
                </Form.Field>
              </Form>
            </SweetForm>
            <Button
              positive
              style={{ marginTop: '10px' }}
              onClick={onSaveNewRecipient}
            >
              Add
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  );
};

const EditOfferModal = ({
  edit,
  initialValues,
  onChangeEdit,
  headerText,
  onSubmit,
  onCancel,
}) => (
  <Modal
    active={true}
    headerText={headerText}
    submitText="Submit"
    onSubmit={onSubmit}
    onCancel={onCancel}
  >
    <SweetForm onChange={onChangeEdit} initialValues={initialValues}>
      <Form>
        <Form.Field>
          <label>Identifier</label>
          {initialValues ? (
            <Input disabled field="id" />
          ) : (
            <Clearable component={Input} field="id" nullLabel="Auto" />
          )}
        </Form.Field>
        <Form.Field>
          <label>Title</label>
          <Input autoFocus field="title" />
        </Form.Field>
        <Form.Field>
          <label>SheetId</label>
          <Input field="sheetId" />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <Textarea field="description" />
        </Form.Field>
        <Form.Field>
          <Checkbox
            slider
            defaultValue={false}
            field="manualStepsAllowed"
            label="Manual Steps Allowed"
          />
        </Form.Field>
        <Form.Field>
          <Checkbox slider defaultValue={false} field="hide" label="Hide" />
        </Form.Field>
      </Form>
    </SweetForm>
  </Modal>
);

class Station extends Component {
  componentDidMount() {
    this.props.onSearch();
  }

  render() {
    const {
      clients,
      offers,
      selectedClient,
      selectedOffer,
      search,
      edit,
      editMode,
      page,
      onSearch,
      onChangeSearch,
      onClickClient,
      onBack,
      onAddClient,
      onEditClient,
      onSubmitClient,
      onAddOffer,
      onEditOffer,
      onEditRecipients,
      onSaveNewRecipient,
      onRemoveRecipient,
      onSubmitOffer,
      onCancel,
      onChangeEdit,
    } = this.props;
    return (
      <Grid>
        <Grid.Column width={4}>
          {!selectedClient ? (
            <SweetForm onChange={onChangeSearch}>
              <Header as="h2">Reference</Header>
              <Form onSubmit={() => onSearch(search)}>
                <Input field="ref" />
              </Form>
            </SweetForm>
          ) : (
            <div>
              <h1>{selectedClient.name}</h1>
            </div>
          )}
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedClient ? (
            <ClientDetails
              client={selectedClient}
              offers={offers}
              page={page}
              onPage={(i) => onClickClient(selectedClient, i)}
              onBack={onBack}
              onEditClient={onEditClient}
              onAddOffer={onAddOffer}
              onEditOffer={onEditOffer}
              selectedOffer={selectedOffer}
              onEditRecipients={onEditRecipients}
            />
          ) : (
            <ClientsList
              page={page}
              clients={clients}
              onAdd={onAddClient}
              onSelect={onClickClient}
              onPage={(i) => onSearch(search, i)}
            />
          )}
        </Grid.Column>
        {editMode === 'addClient' ? (
          <EditClientModal
            headerText={'Create a station client'}
            edit={edit}
            onChangeEdit={onChangeEdit}
            onCancel={onCancel}
            onSubmit={(v) => onSubmitClient(edit, true)}
            creationMode
          />
        ) : null}
        {editMode === 'editClient' ? (
          <EditClientModal
            headerText={'Edit ' + selectedClient.id}
            edit={edit}
            initialValues={selectedClient}
            onChangeEdit={onChangeEdit}
            onCancel={onCancel}
            onSubmit={(v) => onSubmitClient(edit, false)}
          />
        ) : null}
        {editMode === 'addOffer' ? (
          <EditOfferModal
            headerText={'Create a station offer for ' + selectedClient.id}
            edit={edit}
            onChangeEdit={onChangeEdit}
            onCancel={onCancel}
            onSubmit={(v) => onSubmitOffer(selectedClient.id, edit, true)}
          />
        ) : null}
        {editMode === 'editOffer' ? (
          <EditOfferModal
            headerText={'Edit offer '}
            edit={edit}
            initialValues={edit}
            onChangeEdit={onChangeEdit}
            onCancel={onCancel}
            onSubmit={(v) => onSubmitOffer(selectedClient.id, edit, false)}
          />
        ) : null}
        {editMode === 'editRecipients' ? (
          <EditRecipientsModal
            edit={edit}
            selectedOffer={selectedOffer}
            onChangeEdit={onChangeEdit}
            onCancel={onCancel}
            onSaveNewRecipient={(v) =>
              onSaveNewRecipient(
                selectedClient.id,
                selectedOffer.id,
                edit,
                true,
              )
            }
            onRemoveRecipient={(recipient) =>
              onRemoveRecipient(selectedClient.id, selectedOffer.id, recipient)
            }
          />
        ) : null}
      </Grid>
    );
  }
}

// Containers
const mapSStation = (state) => ({
  search: state.search,
  clients: state.clients,
  offers: state.offers,
  selectedClient: state.selectedClient,
  selectedOffer: state.selectedOffer,
  editMode: state.editMode,
  edit: state.edit,
  page: state.page,
});

const mapDStation = (dispatch) => ({
  onChangeEdit: (e) => dispatch(setEdit(e)),
  onChangeSearch: (s) => dispatch(setSearch(s)),
  onSearch: async (search, page = 0) => {
    const filter = search && search.ref ? `&filter=${search.ref}` : '';
    const result = await axios.get(
      `${baseUrl}/station/clients?limit=${PER_PAGE}&skip=${PER_PAGE *
        page}${filter}`,
    );
    dispatch(setClients(result.data, page));
  },
  onClickClient: async (client, page = 0) => {
    if (!page) {
      dispatch(setSelectedClient(client));
    }
    const result = await axios.get(
      `${baseUrl}/station/clients/${
        client.id
      }/offers?limit=${PER_PAGE}&skip=${PER_PAGE * page}`,
    );
    dispatch(setOffers(result.data, page));
  },
  onBack: () => dispatch(setSelectedClient(null)),
  onAddClient: () => dispatch(setEditMode('addClient')),
  onEditClient: () => dispatch(setEditMode('editClient')),
  onAddOffer: () => dispatch(setEditMode('addOffer')),
  onEditOffer: (offer) => {
    dispatch(setEditMode('editOffer', offer));
  },
  onEditRecipients: (offer) => {
    dispatch(setEditMode('editRecipients', offer));
  },
  onSaveNewRecipient: async (clientId, offerId, values, add = false) => {
    await axios.post(
      `${baseUrl}/station/clients/${clientId}/offers/${offerId}/recipients`,
      values,
    );
    const offers = (await axios.get(
      `${baseUrl}/station/clients/${clientId}/offers`,
    )).data;
    dispatch(setOffers(offers));
    const offer = _.find(offers, (o) => o.id === offerId);
    dispatch(setSelectedOffer(offer));
  },
  onRemoveRecipient: async (clientId, offerId, recipient) => {
    await axios.delete(
      `${baseUrl}/station/clients/${clientId}/offers/${offerId}/recipients/${
        recipient._id
      }`,
    );
    const offers = (await axios.get(
      `${baseUrl}/station/clients/${clientId}/offers`,
    )).data;
    dispatch(setOffers(offers));
    const offer = _.find(offers, (o) => o.id === offerId);
    dispatch(setSelectedOffer(offer));
  },
  onSubmitClient: async (values, add = false) => {
    if (!values.name) {
      return;
    }

    if (add) {
      values.id = values.id || textToId(values.name);
      await axios.post(
        `${baseUrl}/station/clients`,
        _.pick(values, 'id', 'name', 'folderId'),
      );
    } else {
      await axios.put(
        `${baseUrl}/station/clients/${values.id}`,
        _.pick(values, 'name', 'folderId'),
      );
    }

    if (values.wpid) {
      await axios.put(`${baseUrl}/workplaces/${values.wpid}`, {
        id: values.wpid,
        client: values.id,
      });
    }

    dispatch(setEditMode('none'));
    dispatch(setSelectedClient(values));
  },
  onSubmitOffer: async (clientId, values, add = false) => {
    if (!values.title) {
      return;
    }

    values.status = values.hide ? 'hide' : undefined;
    const fields = [
      'title',
      'description',
      'sheetId',
      'status',
      'manualStepsAllowed',
    ];

    if (add) {
      values.id = values.id || textToId(values.title);
      await axios.post(
        `${baseUrl}/station/clients/${clientId}/offers`,
        _.pick(values, 'id', ...fields),
      );
    } else {
      await axios.put(
        `${baseUrl}/station/clients/${clientId}/offers/${values.id}`,
        _.pick(values, ...fields),
      );
    }

    dispatch(setEditMode('none'));
    dispatch(updateOffer(values));
  },
  onCancel: () => dispatch(setEditMode('none')),
});

const StationContainer = connect(
  mapSStation,
  mapDStation,
)(Station);

export default ({ match, history }) => (
  <Provider store={store}>
    <StationContainer match={match} history={history} />
  </Provider>
);
