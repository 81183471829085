import _ from 'underscore';
import React, { Component } from 'react';
import { Icon, Segment, List, Checkbox, Grid } from 'semantic-ui-react';
import RequirementStatusView from './RequirementStatusView.js';
import GBQCollectionView from './GBQCollectionView';
import MongoCollectionView from './MongoCollectionView';
import baseUrl from '../baseUrl.js';
import axios from 'axios';

class ServiceView extends Component {
  componentDidMount() {
    const { id } = this.props.data;
    this.handleLoadServiceState(id);
  }

  handleLoadServiceState = async (id) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getServiceInformation/' + id;
    const data = (await axios.get(url, customHeaders)).data;
    const active = ((data || {}).data || {}).active;
    this.setState({ active });
  };

  handleSelectTable = async (selectedTable) => {
    this.setState({ selectedTable: selectedTable });
  };

  async handleToggleActivity() {
    const { active } = this.state;
    const { id } = this.props.data;
    const payload = {
      serviceId: id,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    if (active) {
      const url = baseUrl + '/sweetchain/setServiceInactive';
      await axios.post(url, payload, customHeaders);
    } else {
      const url = baseUrl + '/sweetchain/setServiceActive';
      await axios.post(url, payload, customHeaders);
    }
    await this.setState({ active: !active });
  }

  render() {
    const { id, requirementStatus, type, input, output } = this.props.data;
    const active = (this.state || {}).active || false;
    const selectedTable = (this.state || {}).selectedTable;
    return (
      <div>
        <Segment height='100%' width='100%'>
          <Grid columns={2}>
            <Grid.Column>
              <List>
                <List.Item>
                  <b>{'ID : ' + id} </b>
                </List.Item>
                <List.Item>
                  <b>{'TYPE : ' + type} </b>
                </List.Item>
                <List.Item>
                  <span>
                    <Icon name={'eye'} size='large' />
                    <a style={{ cursor: 'pointer' }} onClick={() => this.handleSelectTable('input')}>
                      {'VIEW INPUT TABLE'}{' '}
                    </a>
                  </span>
                </List.Item>
                <List.Item>
                  <span>
                    <Icon name={'eye'} size='large' />
                    <a style={{ cursor: 'pointer' }} onClick={() => this.handleSelectTable('output')}>
                      {'VIEW OUTPUT TABLE'}{' '}
                    </a>
                  </span>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <b>SERVICE STATUS : </b>
                  <Checkbox slider onChange={() => this.handleToggleActivity()} checked={active} />
                </List.Item>
                <List.Item>
                  <span>
                    <Icon
                      name={active ? 'check circle' : 'warning circle'}
                      color={active ? 'green' : 'red'}
                      size='large'
                    />
                    <b style={{ color: active ? 'green' : 'red' }}>{active ? 'active' : 'inactive'}</b>
                  </span>
                </List.Item>
                {requirementStatus && requirementStatus !== undefined && requirementStatus.length > 0 ? (
                  <List.Item>
                    <b>REQUIREMENTS : </b>
                    {_.map(requirementStatus, (requirement, index) => (
                      <RequirementStatusView data={requirement} key={index} />
                    ))}
                  </List.Item>
                ) : null}
              </List>
            </Grid.Column>
          </Grid>
        </Segment>
        {selectedTable == 'input' ? (
          <Segment height='100%' width='100%'>
            {input.type == 'bigquery' || input.type == 'bigquery-stream' ? (
              <GBQCollectionView key={id + '0'} data={input} />
            ) : (
              <MongoCollectionView key={id + '0'} data={input} />
            )}
          </Segment>
        ) : selectedTable == 'output' ? (
          <Segment height='100%' width='100%'>
            {output.type == 'bigquery' || output.type == 'bigquery-stream' ? (
              <GBQCollectionView
                key={id + '1'}
                data={
                  output.items
                    ? { ...output.items, keyType: output.keyType }
                    : { ...output.stream, keyType: output.keyType }
                }
              />
            ) : (
              <MongoCollectionView
                key={id + '1'}
                data={
                  output.items
                    ? { ...output.items, keyType: output.keyType }
                    : { ...output.stream, keyType: output.keyType }
                }
              />
            )}
          </Segment>
        ) : null}
      </div>
    );
  }
}

export default ServiceView;
