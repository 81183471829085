import _ from 'underscore';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GridClusters from './GridClusters';
import { Segment, Divider, Grid, Icon, Button, Modal  } from 'semantic-ui-react';

const fakePredictions = _.map(_.range(80), () => (
  { 
    letter: _.shuffle(['a+', 'a', 'ab', 'b', 'bc', 'c', 'cd', 'd', 'de', 'e', 'e-'])[0],
    score: Math.random()
  }
));

const GridSample = ({ displayMode }) => {
  return (
    <GridClusters
      predictions={fakePredictions} 
      totalWidth={300} 
      onClickPrediction={() => {}}
      labelClusters={displayMode.labelClusters}
      clusterFromLabel={displayMode.clusterFromLabel}
      scoreClusters={displayMode.scoreClusters}
      clusterFromScore={displayMode.clusterFromScore}
      colorFromPosition={displayMode.colorFromPosition}
    />

  );
}


const GridDisplaySelector = ({ onSubmit }) => {

  const gridModes = [
    {
      type: 'grid',
      name: '11-grades',
      labelClusters: [
        'A+', 'A', 'AB', 'B', 'BC', 'C', 
        'CD', 'D', 'DE', 'E', 'E-'
      ],
      clusterFromLabel: (label) => (
        ['a+','a', 'ab', 'b', 'bc', 'c', 'cd', 'd', 'de', 'e', 'e-'].indexOf(label)
      ),
      scoreClusters: [
        'A+', 'A', 'AB', 'B', 'BC', 'C', 
        'CD', 'D', 'DE', 'E', 'E-'
      ],
      clusterFromScore: (score) => {
        if (score > 0.95) {
          return 0;
        } else if (score > 0.85) {
          return 1;
        } else if (score > 0.75) {
          return 2;
        } else if (score > 0.65) {
          return 3;
        } else if (score > 0.55) {
          return 4;
        } else if (score > 0.45) {
          return 5;
        } else if (score > 0.35) {
          return 6;
        } else if (score > 0.25) {
          return 7;
        } else if (score > 0.15) {
          return 8;
        } else if (score > 0.05) {
          return 9;
        } else {
          return 10;
        }
      },
      colorFromPosition: (iRow, iCol) => {
        const diff = Math.max(iRow-iCol, iCol-iRow);
        return {
          0: '#3EF005',
          1: '#5FC104',
          2: '#809303',
          3: '#A26402',
          4: '#C33601',
          5: '#E50800',
          6: '#E50800',
          7: '#E50800',
          8: '#E50800',
          9: '#E50800',
          10:'#E50800',
        }[diff] || 'grey';        
      }
    },
    {
      type: 'grid',
      name: '5-clusters',
      labelClusters: [
        'A', 'B', 'C', 'D', 'E'
      ],
      clusterFromLabel: (label) => (
        {
          'a+': 0,'a': 0, 'ab': 0, 
          'b': 1, 'bc': 1, 
          'c': 2, 'cd': 2, 
          'd': 3, 'de': 3, 
          'e': 4, 'e-': 4
        }[label] || 0
      ),
      scoreClusters: [
        'A', 'B', 'C', 'D', 'E'
      ],
      clusterFromScore: (score) => {
        if (score > 0.75) {
          return 0;
        } else if (score > 0.55) {
          return 1;
        } else if (score > 0.35) {
          return 2;
        } else if (score > 0.15) {
          return 3;
        } else {
          return 4;
        }
      },
      colorFromPosition: (iRow, iCol) => {
        const diff = Math.max(iRow-iCol, iCol-iRow);
        return {
          0: '#3EF005',
          1: '#809303',
          2: '#C33601',
          3: '#E50800',
          4: '#E50800',
          5: '#E50800',
        }[diff] || 'grey';        
      }
    }  
  ];

  const nbColumns = 2;
  const displayModesGroups = [ [] ];
  _.each(gridModes, displayMode => {
    if (_.last(displayModesGroups).length === nbColumns) {
      displayModesGroups.push([]);
    }
    _.last(displayModesGroups).push(displayMode);
  });

  return (
    <Grid>
    {_.map(displayModesGroups, (displayModesGroup, iRow) => (
      <Grid.Row columns={nbColumns} key={iRow}>
        {_.map(displayModesGroup, (displayMode, iCol) => (
          <Grid.Column key={iCol}>
            <Segment>
              <Grid>
                <Grid.Row columns={2} style={{  }}>
                  <Grid.Column verticalAlign="middle" style={{ paddingLeft: 20 }}>
                    <h4>{displayMode.name}</h4>
                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                    <Button color="blue" onClick={() => onSubmit({ displayMode })}>Select</Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column style={{ minHeight: 320 }}>
                    <center>
                      <GridSample displayMode={displayMode} />
                    </center>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        ))}
      </Grid.Row>
    ))}
    </Grid>
  );
}


const ListDisplayModePreview = ({ displayMode }) => {
  const colorFromLetter = (letter) => {
    console.log(letter);
    if (displayMode.categories && !displayMode.categories[letter]) {
      return 'black';
    }
    return (
        (displayMode.colorMap || {})[letter] || {
        'a+': '#3EF005',
        'a': '#4ED804',
        'ab': '#5FC104',
        'b': '#70AA03',
        'bc': '#809303',
        'c': '#917C02',
        'cd': '#A26402',
        'd': '#B24D01',
        'de': '#C33601',
        'e': '#D41F00',
        'e-': '#E50800',

        'currently-freelance': '#3EF005',
        'currently-fulltime-and-freelance': '#67B603',
        'has-been-freelance': '#BB4201',
        'never-freelance': '#E50800',
      }[letter] || 'grey'
    );
  };

  const width = '9%'

  return (
    <div style={{ width: '100%' }}>
      {_.map(['a+', 'a', 'ab', 'b', 'bc', 'c', 'cd', 'd', 'de', 'e', 'e-'], (letter, index) => (
        <div
          key={index}
          style={{ 
            display: 'inline-block',
            width,
            borderRight: '1px solid whitesmoke',
            height: '40px',
          }}
        >
        <div style={{ width: '100%' }}>
          <center>{letter.toUpperCase()}</center>
        </div>
        <div
          style={{
            width: '100%',
            height: '20px',
            backgroundColor: colorFromLetter(letter)
          }}
        />
    </div>
      ))}
    </div>
  );
}

const ExplicitListDisplayModeSelector = ({ displayMode, onSubmit }) => {
  return (
    <Grid>
      <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
        <Grid.Column verticalAlign="middle" style={{ paddingLeft: 20 }}>
          <h4>{displayMode.name}</h4>
        </Grid.Column>
        <Grid.Column textAlign='right'>
          <Button color="blue" onClick={() => onSubmit({ displayMode })}>Select</Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <ListDisplayModePreview displayMode={displayMode} />
      </Grid.Row>
    </Grid>
  );
}


const PresetDisplaySelector = ({ onSubmit }) => {

  const green = '#3EF005';
  const olive = '#5FC104'
  const brown = '#809303';
  const darkBrown = '#C33601';
  const red = '#E50800';

  const presetDisplayModes = [
    {
      type: 'list',
      name: '[A+,A] | [E,E-]',
      categories: { 'a+': 1, 'a': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': green, 'a': green,
        'e': red, 'e-': red 
      },
    },  
    {
      type: 'list',
      name: '[A+,AB] | [DE,E-]',
      categories: { 'a+': 1, 'a': 1, 'ab': 1, 'de': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': green, 'a': green, 'ab': green, 
        'de': red, 'e': red, 'e-': red 
      },
    },
    {
      type: 'list',
      name: '[A+,B] | [D,E-]',
      categories: { 'a+': 1, 'a': 1, 'ab': 1, 'b': 1, 'd': 1, 'de': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': green, 'a': green, 'ab': green, 'b': green, 
        'd': red, 'de': red, 'e': red, 'e-': red 
      },
    },
    {
      type: 'list',
      name: '[A+,BC] | [CD,E-]',
      categories: { 
        'a+': 1, 'a': 1, 'ab': 1, 'b': 1, 'bc': 1, 
        'cd': 1, 'd': 1, 'de': 1, 'e': 1, 'e-': 1 
      },
      colorMap: { 
        'a+': olive, 'a': olive, 'ab': olive, 'b': olive, 'bc': olive, 
        'cd': darkBrown, 'd': darkBrown, 'de': darkBrown, 'e': darkBrown, 'e-': darkBrown 
      },
    },
    {
      type: 'list',
      name: '[A+,A] | [C,E-]',
      categories: { 'a+': 1, 'a': 1, 'c': 1, 'cd': 1, 'd': 1, 'de': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': green, 'a': green, 
        'c': darkBrown, 'cd': darkBrown, 'd': darkBrown, 
        'de': darkBrown, 'e': darkBrown, 'e-': darkBrown 
      },
    },
    {
      type: 'list',
      name: '[A+,C] | [E,E-]',
      categories: { 'a+': 1, 'a': 1, 'ab': 1, 'b': 1, 'bc': 1, 'c': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': olive, 'a': olive, 'ab': olive, 'b': olive, 'bc': olive, 'c': olive, 
        'e': red, 'e-': red 
      },
    },    
    {
      type: 'list',
      name: '[A+,B] | [BC,CD] | [D,E-]',
      colorMap: { 
        'a+': green, 'a': green, 'ab': green, 'b': green, 
        'bc': brown, 'c': brown, 'cd': brown, 
        'd': red, 'de': red, 'e': red, 'e-': red 
      },
    },    
    {
      type: 'list',
      name: '[A+,A] | [BC,CD] | [E,E-]',
      categories: { 'a+': 1, 'a': 1, 'bc': 1, 'c': 1, 'cd': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': green, 'a': green, 
        'bc': brown, 'c': brown, 'cd': brown, 
        'e': red, 'e-': red 
      },
    },
    {
      type: 'list',
      name: '[A+,AB] | [BC,C] | [CD-D] | [E,E-]',
      colorMap: { 
        'a+': green, 'a': green, 'ab': green,
        'b': olive, 'bc': olive, 
        'c': brown, 'cd': brown, 
        'd': red, 'de': red, 'e': red, 'e-': red 
      },
    },    
    { 
      type: 'list',
      name: '[A+,A] | [B, BC] | [CD,D] | [E,E-]',
      categories: { 'a+': 1, 'a': 1, 'b': 1, 'bc': 1, 'cd': 1, 'd': 1, 'e': 1, 'e-': 1 },
      colorMap: { 
        'a+': green, 'a': green, 
        'b': olive, 'bc': olive, 
        'cd': brown, 'd': brown,
        'e': red, 'e-': red 
      },
    },    
    { 
      type: 'list',
      name: '[A+,A] [AB,B], [BC, C] | [CD, D] | [DE, E,E-]',
      colorMap: { 
        'a+': green, 'a': green, 
        'ab': olive, 'b': olive, 
        'bc': brown, 'c': brown, 
        'cd': darkBrown, 'd': darkBrown,
        'de': red, 'e': red, 'e-': red 
      },
    },
    { 
      type: 'list',
      name: '[A+,BC] [BC,E-]',
      colorMap: { 
        'a+': green, 'a': green, 
        'ab': green, 'b': green, 
        'bc': green, 'c': red, 
        'cd': red, 'd': red,
        'de': red, 'e': red, 'e-': red 
      },
    },      
    { 
      type: 'list',
      name: 'Explicit',
    },
  ];
  
  const nbColumns = 2;

  const displayModesGroups = [ [] ];
  _.each(presetDisplayModes, displayMode => {
    if (_.last(displayModesGroups).length === nbColumns) {
      displayModesGroups.push([]);
    }
    _.last(displayModesGroups).push(displayMode);
  });

  return (
    <Grid>
    {_.map(displayModesGroups, (displayModesGroup, iRow) => (
      <Grid.Row columns={nbColumns} key={iRow}>
        {_.map(displayModesGroup, (displayMode, iCol) => (
          <Grid.Column key={iCol}>
            <Segment>
              <ExplicitListDisplayModeSelector displayMode={displayMode} onSubmit={onSubmit} />
            </Segment>
          </Grid.Column>
        ))}
      </Grid.Row>
    ))}
    </Grid>
  );
}

class ChunkDisplayModalSelector extends React.Component {
  render() {
    const { onClose } = this.props;

    const handleSubmit = ({ displayMode }) => {
      this.props.onSubmit({ displayMode });
      onClose();
    };

    return (
      <Modal
        open
        onClose={onClose}
        closeIcon
        size="large"
      >
        <Modal.Header>
          Select a display
        </Modal.Header>
        <Modal.Content style={{ minHeight: 500 }}>
          <Tabs>
            <TabList>
              <Tab>Preset splits</Tab>
              <Tab>Grids</Tab>
            </TabList>
            <TabPanel key="preset-split">
              <PresetDisplaySelector onSubmit={handleSubmit}/>
            </TabPanel>
            <TabPanel key="grids">
              <GridDisplaySelector onSubmit={handleSubmit}/>
            </TabPanel>
          </Tabs>          
        </Modal.Content>
      </Modal>
    )
    
  }
}

export default ChunkDisplayModalSelector;
