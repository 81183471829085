import _ from 'underscore';
import moment from 'moment';

import React from 'react';

import {
  Card,
  Grid,
  Image,
  Icon,
  Button,
  Popup,
  List,
  Label,
} from 'semantic-ui-react';

import Modal from '../../../Modal';
import SweetappProfile from '../../../components/SweetappProfile';
import UploadActions from './UploadActions'
import { Link } from 'react-router-dom';



const DisqualifyButton = ({ match, onChangeDisqualify }) => (
  match.disqualified ? (
    <div>
      <Label>Disqualified</Label>
      <Button circular basic size='mini' icon='arrow up' color='green' onClick={() => onChangeDisqualify(false)}/>
    </div>
  ) : (
    <Button circular basic size='mini' icon='arrow down' color='red' onClick={() => onChangeDisqualify(true)}/>
  )
);

export const DetailedOffer = ({offer}) => {
  return (
  <div>
    <b>
      {getColoredGradePercent(offer.score)}{' '}
      {offer.title}
    </b>
    <List>
      {(((offer.skills || {}).required || {}).length ||
        0) > 0 ? (
          <List.Item>
            <b>Required : </b>
            {((offer.skills || {}).required || []).join(
              ', ',
            )}{' '}
          </List.Item>
        ) : null}
      {(((offer.skills || {}).important || {}).length ||
        0) > 0 ? (
          <List.Item>
            <b>Important : </b>
            {((offer.skills || {}).important || []).join(
              ', ',
            )}{' '}
          </List.Item>
        ) : null}
      {offer.location ? (
        <List.Item>
          <b>Location : </b>
          {offer.location}{' '}
        </List.Item>
      ) : null}
      {offer.experience ? (
        <List.Item>
          <b>Experience : </b>
          {`${offer.experience.min} - ${
              offer.experience.max
            }`}{' '}
        </List.Item>
      ) : null}
      {offer.salary ? (
        <List.Item>
          <b>Salary : </b>

          {`${offer.salary.min || '?'} - ${
              offer.salary.max || '?'
            }`}{' '}
        </List.Item>
      ) : null}
    </List>
  </div>
  )
}

const MatchScorePopup = ({ match, getColoredGradePercent }) => {
  const { bestOffer, otherOffers } = match.reason || {};
  const { bestOfferActivity } = match;
  return (
    <Popup
      trigger={
        <div>
          {getColoredGradePercent(match.score)}
          {bestOfferActivity && getSweetappOfferActivityLabel(bestOfferActivity)}
        </div> 
      }
      onClick={() => {
        console.log(match.reason);
      }}
      flowing
      hoverable
    >
      <Grid centered divided columns={otherOffers ? 2 : 1}>
        {bestOffer ? (
          <Grid.Column>
            <DetailedOffer offer={bestOffer} />
          </Grid.Column>
        ) : null}
        {otherOffers ? (
          <Grid.Column>
            <List>
              {_.map(otherOffers.slice(0, 5), (offer) => (
                <List.Item>
                  <b>
                    {getColoredGradePercent(offer.score)}{' '}
                    {offer.title}
                  </b>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        ) : null}
      </Grid>
    </Popup>
  );
}

const SkipsWarning = ({jobOfferId, skipCount}) => {
  const search = `{"onlyWatch":"true", "jobOfferIds":"${jobOfferId}"}`
  console.log('search', search)
  return (
    <Link to={`/sweetapp/actions/${search}`} color='red' target='_blank'>
      <Label color='red' pointing='right'>
        <Icon name='warning' /> {skipCount}
      </Label>
    </Link>

  )
}

const StateManagementActions = ({onChangeState, deletionEnabled, handleEnableDeletion, onDelete}) => (
  <div className="ui nine buttons">
    <Button
      basic
      color="orange"
      onClick={() => {
        onChangeState('pending');
      }}
    >
      P
    </Button>
    <Button
      basic
      color="blue"
      onClick={() => {
        onChangeState('contacted');
      }}
    >
      C
    </Button>
    <Button
      basic
      color="olive"
      onClick={() => {
        onChangeState('answered');
      }}
    >
      A
    </Button>
    <Button
      basic
      color="green"
      onClick={() => {
        onChangeState('in-process');
      }}
    >
      IP
    </Button>
    <Button
      basic
      color="blue"
      onClick={() => {
        onChangeState('hired');
      }}
    >
      H
    </Button>
    <Button
      basic
      color="orange"
      onClick={() => {
        onChangeState('disqualified');
      }}
    >
      D
    </Button>
    <Button
      basic
      color="red"
      onClick={() => {
        onChangeState('skipped');
      }}
    >
      S
    </Button>
    <Button
      basic
      color="brown"
      onClick={() => {
        if (!deletionEnabled) {
          handleEnableDeletion();
        } else {
          onDelete();
        }
      }}
    >
      <Icon
        name="delete"
        link
        color={deletionEnabled ? 'red' : 'grey'}
      />
    </Button>
  </div>
)

const getGradeFromScore = (score) =>
score > 0.8
  ? 'A'
  : score > 0.6
  ? 'B'
  : score > 0.4
  ? 'C'
  : score > 0.2
  ? 'D'
  : 'E';

const getPercentFromScore = (score) => Math.round(score * 100) + '%';

const getColoredGradePercent = (score) => (
<span
  style={{
    cursor: 'pointer',
    color: {
      A: 'lime',
      B: 'green',
      C: 'olive',
    }[getGradeFromScore(score)],
  }}
>
  {getGradeFromScore(score)} ({getPercentFromScore(score)})
</span>
);

const getSweetappOfferActivityLabel = (offerActivity) => {
  const colorFromActivity = {
    'high': 'green',
    'medium': 'orange',
    'no-send': 'orange',
    'low': 'dark'
  }
  const iconFromActivity = {
    'high': 'play circle outline',
    'medium': 'pause circle outline',
    'no-send': 'question circle outline',
    'low': 'stop circle outline',
  }
  return (
    <span
        style={{
          cursor: 'pointer',
          color: colorFromActivity[offerActivity]
        }}
      >
      <Icon name={iconFromActivity[offerActivity]}/>
    </span>
  )
}

class MatchMiniCard extends React.PureComponent {
  state = {};
  handleToggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode,
      deletionEnabled: false,
    });
  };
  handleEnableDeletion = () => {
    this.setState({ deletionEnabled: true });
    setTimeout(() => {
      this.setState({ deletionEnabled: false });
    }, 3000);
  };
  handleDisplayProfileModal = ({displayedProfileId}) => {
    if (displayedProfileId) {
      this.setState({ displayedProfileId });
    } else {
      return alert('invalide profile id');
    }
  };
  handleCloseProfilModal = () => {
    this.setState({ displayedProfileId: null});
  };
  handleOpenUploadModal = () => {
    this.setState({ uploadModalOpened:true })
  }
  render() {
    const { match, type, onSelect, onChangeState, onDelete, onChangeDisqualify, handleUploadProfile } = this.props;
    if (!match){
      return <div>No match.</div>
    }
    const getTitles = () => {
      if (type === 'profile') {
        const { fullname } = (match.sheetItem || {});
        return { title: fullname };
      } else if (type === 'client') {
        return { title: match.clientId, subtitle: match.jobOfferId };
      } else {
        return {};
      }
    };
    const { title, subtitle } = getTitles();
    const { reason, skipCount } = match || {};
    const bestJobOfferId = ((reason || {}).bestOffer || {}).jobOfferId

    const colorFromTimestamp = (timestamp) => {
      const now = Date.now();
      const nbDays = (now - timestamp)/(24*3600*1000);
      return nbDays >= 30 ? (
        'red'
      ) : nbDays >= 20 ? (
        'purple'
      ) : nbDays >= 12 ? (
        'orange'
      ) : nbDays >= 6 ? (
        'green'
      ) : 'lime'
    };

    return (
      <Card fluid>
        <Card.Content>
          {type === 'profile' && (
            <Image
              floated="left"
              size="mini"
              circular
              src={
                ((match.sheetItem || {}).content || {}).photoLink ||
                'https://s3.amazonaws.com/37assets/svn/765-default-avatar.png'
              }
              onError={(e) => {
                e.target.src = '/images/defaultPhoto.png';
              }}
            />
          )}
          <Card.Header>
            {match.profileId && (
              <Icon
                name="eye"
                link
                onClick={() => {
                  this.handleDisplayProfileModal({displayedProfileId:match.profileId});
                }}
              />
            )}

            {(match.skipCount && match.skipCount > 4) ? (
              <SkipsWarning
                jobOfferId={bestJobOfferId}
                skipCount={skipCount}
              />
            ):null}
            <span style={{ cursor: 'pointer' }} onClick={onSelect}>
              {title} [{match.state}]<span> </span> 
              {(match.sheetItem || {}).creationTimestamp && (
                <span style={{ color: colorFromTimestamp(match.sheetItem.creationTimestamp) }}>[{new Date(match.sheetItem.creationTimestamp).toISOString().slice(2,10)}]</span>
              )}
            </span>
            <div style={{ position: 'absolute', top: 5, right: 90 }}>
              <DisqualifyButton match={match} onChangeDisqualify={onChangeDisqualify} />
            </div>
            {match.score &&(
              <div style={{ position: 'absolute', top: 5, right: 5 }}>
                <MatchScorePopup match={match} getColoredGradePercent={getColoredGradePercent} />
              </div>
            )}
          </Card.Header>
          {subtitle && <Card.Meta>{subtitle}</Card.Meta>}
          {match.isDuplicated && <Popup trigger={<div style={{fontWeight:'bold', fontSize:12}}><Icon name='warning sign'/> Other matches for this client</div>} content={<div>{match.isDuplicated}</div>} /> }
          {match.skipReason && <Card.Meta><span style={{color:'red'}}><b>{match.skipReason}</b></span></Card.Meta>}
          <Card.Description>
            {match.sentDate && (
              <div style={{color:'green'}}> 
                <b>Sent : {moment(match.sentDate).format('YY-MM-DD')}</b>
              </div>
            )}
            {/*match.profileId && (
              <StateManagementActions
                onChangeState={onChangeState}
                deletionEnabled={this.state.deletionEnabled}
                handleEnableDeletion={this.handleEnableDeletion}
                onDelete={onDelete}
              />
            )*/}
            { !match.profileId  && (
              <UploadActions
                key={match.sheetItemId+match.clientId}
                handleUploadProfile={handleUploadProfile}
                match={match}
              />
            )}

          </Card.Description>
        </Card.Content>
        {this.state.displayedProfileId && (
          <Modal
            active={true}
            headerText="Profile"
            submitText="OK"
            cancelText=""
            onSubmit={this.handleCloseProfilModal}
            onCancel={this.handleCloseProfilModal}
          >
            <SweetappProfile profileId={this.state.displayedProfileId} />
          </Modal>
        )}
      </Card>
    );
  }
}


export default MatchMiniCard;
