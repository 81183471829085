import _ from 'underscore';
import React, { Component } from 'react';

import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Grid,
  Table,
  Form,
  Popup,
  Icon,
  Modal as SModal,
  Input as SInput,
  Button,
  Header,
} from 'semantic-ui-react';
import { SweetForm, Select, Input, Checkbox } from 'sweetform';

import baseUrl from '../baseUrl';
import SweetappProfile from '../components/SweetappProfile';
import Modal from '../Modal';
import FocusModal from './FocusModal';
import Pagination from '../Pagination';

const PER_PAGE = 100;

// Actions

const SET_ACTIONS = 'SET_ACTIONS';
const setActions = (actions, page) => ({ type: SET_ACTIONS, actions, page });

const SET_ACTIONS_FILTER_OPTIONS = 'SET_ACTIONS_FILTER_OPTIONS';
const setActionsFilterOptions = (options) => ({
  type: SET_ACTIONS_FILTER_OPTIONS,
  options,
});

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_MODAL_MODE = 'SET_MODAL_MODE';
const setModalMode = (mode, initial = {}) => ({
  type: SET_MODAL_MODE,
  mode,
  initial,
});

const UPDATE_ACTION = 'UPDATE_ACTION';
const updateAction = (action) => ({ type: UPDATE_ACTION, action });

// Reducers

const actions = (state = [], action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return action.actions;
    case UPDATE_ACTION:
      const index = _.findIndex(
        state.results,
        (a) => a._id === action.action._id,
      );
      return index > -1
                   ? {
                     ...state,
                     results: [
                       ...state.results.slice(0, index),
                       action.action,
                       ...state.results.slice(index + 1),
                     ],
                   }
                   : state;
    default:
      return state;
  }
};

const page = (state = 0, action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return action.page || 0;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const modalMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.mode;
    default:
      return state;
  }
};

const selectedProfile = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.initial;
    default:
      return state;
  }
};

const selectedAction = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.initial;
    default:
      return state;
  }
};

const actionsOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIONS_FILTER_OPTIONS:
      return action.options;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  actions,
  page,
  search,
  modalMode,
  selectedProfile,
  selectedAction,
  actionsOptions,
});

// Store
const store = createStore(rootReducer);

// Components

const getFormattedDate = (date) => {
  const dateObj = new Date(date);
  return date && dateObj
       ? dateObj.toDateString() + ' - ' + dateObj.toLocaleTimeString()
       : null;
};

const typeStyles = {
  send: {
    color: 'blue',
    fontWeight: 'bold',
  },
  'move-to-hired': {
    color: 'orange',
    fontWeight: 'bold',
  },
  'move-to-process': {
    color: 'green',
    fontWeight: 'bold',
  },
  'send-follow-up': {
    color: 'cyan',
    fontWeight: 'bold',
  },
  'blocked-send-follow-up': {
    color: 'gray',
    fontWeight: 'bold',
  },
  'answer-detected': {
    color: 'blue',
  },
  bounce: {
    color: 'black',
    fontWeight: 'bold',
  },
  'answer-detected-positive': {
    color: 'green',
    fontWeight: 'bold',
  },
  'answer-detected-medium': {
    color: 'orange',
    fontWeight: 'bold',
  },
  'answer-detected-negative': {
    color: 'red',
    fontWeight: 'bold',
  },
};

const typeTexts = {
  disqualifyProfile: 'DISQUALIFIED',
  skipProfile: 'skip',
  'move-to-hired': 'HIRED',
  'select-profile': 'see',
};

const getPlatformBaseUrl = (clientId) =>
  `https://app.hiresweet.com/client/${clientId}`;

const getEquivalentLabel = (labels) => {
  // action.type+(action.label?'-'+action.label:'')
  const scoreTable = {
    'wants-call': 3,
    continue: 1,
    'wants-more-info': 1,
    'salary-mismatch': 0,
    referral: 0,
    'already-satisfied': 0,
    'geography-mismatch': 0,
    'techno-mismatch': 0,
    'wants-remote': 0,
    'wants-partial-time': 0,
    'just-begun-new-job': -1,
    hold: -1,
    'wants-freelance': -1,
    'decline-offer': -2,
    break: -3,
  };
  if (_.indexOf(labels, 'bounce') > -1) {
    return 'bounce';
  }
  const score = _.reduce(
    labels,
    (memo, label) => (scoreTable[label] ? memo + scoreTable[label] : memo),
    0,
  );
  return score < -1
               ? 'answer-detected-negative'
               : score > 1
                       ? 'answer-detected-positive'
                       : 'answer-detected-medium';
};

const Action = ({
  key,
  action,
  onProfileModal,
  onMessageModal,
  onSetMessageLabel,
  onHardPostpone,
  toggleFocusMode
}) => {
  const localeDate = getFormattedDate(action.date);
  return (
    <Table.Row key={key}>
      <Table.Cell onClick={toggleFocusMode}>
        <span>&nbsp;{localeDate}&nbsp;</span>
      </Table.Cell>
      <Table.Cell>
        <span className="show-offer">
          <a
            target="_blank"
            href={`${getPlatformBaseUrl(action.clientId)}/offers/${
              action.jobOfferId
            }`}
            rel='noopener noreferrer'
          >
            {action.jobOfferId}&nbsp;
          </a>
        </span>
        {action.senderAddress ? (
          <span>{action.senderAddress}&nbsp;</span>
        ) : null}
        {action.author ? (
          <span>
            <a target="_blank" href={`${getPlatformBaseUrl(action.clientId)}`} rel='noopener noreferrer'>
              {action.clientId}
            </a>
          &nbsp;({action.author})&nbsp;
          </span>
        ) : null}
      </Table.Cell>
      <Table.Cell>
        <span
          style={
          typeStyles[action.type + (action.label ? '-' + action.label : '')]
          }
        >
          {action.reliable === false ? '!' : ''}
          {typeTexts[action.type] ? typeTexts[action.type] : action.type}&nbsp;
        </span>
        {action.label ? (
          <span
            style={
            typeStyles[action.type + (action.label ? '-' + action.label : '')]
            }
          >
            [{action.label}]&nbsp;
            <br />
          </span>
        ) : null}
        {action.reason ? (
          <span style={typeStyles[action.type]}>({action.reason})&nbsp;</span>
        ) : null}
        {action.target ? (
          <span style={typeStyles[action.type]}>({action.target})&nbsp;</span>
        ) : null}
        {action.annotation ? (
          <span style={typeStyles[action.type]}>
            ({action.annotation})&nbsp;
          </span>
        ) : null}
      </Table.Cell>
      <Table.Cell>
        {action.labels ? (
          <span style={typeStyles[getEquivalentLabel(action.labels)]}>
            {_.indexOf(action.labels, 'bounce') > -1
                                                ? 'bounce'
                                                : action.labels.join(', ')}
          </span>
        ) : null}
      </Table.Cell>
      <Table.Cell>
        { action.message ? (
            <Popup
              trigger={<Icon name="add" />}
              position="left center"
              wide="very"
              hoverable
            >
              <div style={{maxHeight: '80vh', overflow: 'auto', padding: '8px'}}>
                {_.isString(action.message) ? (
                  <div dangerouslySetInnerHTML={{ __html: action.message }} />
                ) : _.isString(action.message.snippet) ? (
                  <div>{action.message.snippet}</div>
                ) : null}
              </div>
            </Popup>
        ): (
            ''
        )}
        {action.profile ? (
          <span className="show-details">
            {/* eslint-disable-next-line */}
            <a
              style={{ cursor: 'pointer' }}
              className="disable-select with-cursor-pointer"
              onClick={() => onProfileModal(action.profile.id)}
            >
              {action.profile.firstName} {action.profile.lastName}&nbsp;
            </a>
          </span>
        ) : (
          ''
        )}
        {action.message ? (
          <span className="show-message">
            {/* <a className="disable-select with-cursor-pointer" onClick={() => onMessageModal(action)}>
                see message&nbsp;
                </a> */}
            [
            <Popup
              trigger={
              // eslint-disable-next-line
              <a
                className="disable-select with-cursor-pointer"
                  onClick={() => onSetMessageLabel(action, 'clearLabel')}
              >
                {' '}
                O&nbsp;
              </a>
              }
              content={'clear label'}
            />
            <Popup
              trigger={
              // eslint-disable-next-line
              <a
                className="disable-select with-cursor-pointer"
                  onClick={() => onSetMessageLabel(action, 'bug')}
              >
                {' '}
                X&nbsp;
              </a>
              }
              content={'bug'}
            />
            <Popup
              trigger={
              // eslint-disable-next-line
              <a
                className="disable-select with-cursor-pointer"
                  onClick={() => onSetMessageLabel(action, 'bounce')}
              >
                {' '}
                B&nbsp;
              </a>
              }
              content={'bounce'}
            />
            <Popup
              trigger={
              // eslint-disable-next-line
              <a
                className="disable-select with-cursor-pointer"
                  onClick={() => onSetMessageLabel(action, 'negative')}
              >
                {' '}
                N&nbsp;
              </a>
              }
              content={'negative'}
            />
            <Popup
              trigger={
              // eslint-disable-next-line
              <a
                className="disable-select with-cursor-pointer"
                  onClick={() => onSetMessageLabel(action, 'medium')}
              >
                {' '}
                M&nbsp;
              </a>
              }
              content={'medium'}
            />
            <Popup
              trigger={
              // eslint-disable-next-line
              <a
                className="disable-select with-cursor-pointer"
                  onClick={() => onSetMessageLabel(action, 'positive')}
              >
                P
              </a>
              }
              content={'positive'}
            />
            ]
          <span style={{ marginRight: 6 }}> </span>
          <Popup
            trigger={
            // eslint-disable-next-line
            <a
              className="disable-select with-cursor-pointer"
                  onClick={() => onHardPostpone(action.linkedin, 1)}
            >
              Y
            </a>
            }
            content={'Y'}
          />
          <span style={{ marginRight: 6 }}> </span>
          <Popup
            trigger={
            // eslint-disable-next-line
            <a
              className="disable-select with-cursor-pointer"
                  onClick={() => onHardPostpone(action.linkedin, 2)}
            >
              R
            </a>
            }
            content={'R'}
          />
          </span>
        ) : (
          ''
        )}
        {action.from && action.to ? (
          <span style={{ color: 'red' }} className="show-details">
            from {action.from} to {action.to}&nbsp;
          </span>
        ) : (
          ''
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const ActionsList = ({
  toggleFocusMode,
  actions,
  page,
  onPage,
  onProfileModal,
  onMessageModal,
  onSetMessageLabel,
  onHardPostpone,
}) => (
  <div
    style={{
      position: 'relative',
      height: '80vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    }}
  >
    {/* <ul> */}
    <Table compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}
                            onClick={toggleFocusMode}
          >Date</Table.HeaderCell>
          <Table.HeaderCell width={3}>
            OfferId or ClientId/Recruiter
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>Action</Table.HeaderCell>
          <Table.HeaderCell width={4}>Labels</Table.HeaderCell>
          <Table.HeaderCell width={4}>Profile</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(actions.results, (action, key) => (
          <Action
            key={key}
            action={action}
            toggleFocusMode={() => toggleFocusMode(key) }
            onProfileModal={onProfileModal}
            onMessageModal={onMessageModal}
            onSetMessageLabel={onSetMessageLabel}
            onHardPostpone={onHardPostpone}
          />
        ))}
      </Table.Body>
    </Table>
    {/* </ul> */}
    <Pagination floated="right" current={page} total={100} onPage={onPage} />
  </div>
);


class RegexModal extends Component {
  state = { modalOpen: false, search: '' };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = (saveSearch) => {
    if (saveSearch) {
      this.props.searchRegex(this.state.search);
    }
    this.setState({ modalOpen: false });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ search: value });
  };

  render() {
    return (
      <SModal
        trigger={
          <Button size="mini" secondary onClick={this.handleOpen}>
            Regex
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Header icon="search" content="Regex search" />
        <SModal.Content>
          <h3>Entrez un mot à rechercher dans {"l'id"} ou le nom des offres</h3>
          <SInput onChange={this.handleSearchChange} />
        </SModal.Content>
        <SModal.Actions>
          <Button color="red" onClick={() => this.handleClose(false)} inverted>
            <Icon name="cancel" /> cancel
          </Button>
          <Button color="green" onClick={() => this.handleClose(true)} inverted>
            <Icon name="checkmark" /> OK
          </Button>
        </SModal.Actions>
      </SModal>
    );
  }
}

class ActionsSearchForm extends Component {
  state = {};
  componentWillReceiveProps = (props) => {
    const { actionsOptions, search } = props;
    const actionsTypeOptions = _.map(
      actionsOptions.actionsList || ['alpha', 'beta'],
      (a) => ({ value: a, label: a }),
    );
    const clientsOptions = _.map(
      actionsOptions.clientsList,
      ({ id, name }) => ({ value: id, label: `${id} (${name})` }),
    );
    const selectedClients = search.clientIds
                          ? search.clientIds.split(';')
                          : null;
    const offersFiltered = selectedClients
                         ? _.filter(
                           actionsOptions.offersList,
                           (o) => _.indexOf(selectedClients, o.clientId) > -1,
                         )
                         : actionsOptions.offersList;
    const offersOptions = _.map(offersFiltered, ({ id, title }) => ({
      value: id,
      label: `${id} (${title})`,
    }));
    const answersLabelsOptions = _.map(actionsOptions.labelsList, (label) => ({
      value: label ? label : 'undefined',
      label: label ? label : 'undefined',
    }));
    this.setState({
      actionsTypeOptions,
      clientsOptions,
      offersOptions,
      answersLabelsOptions,
    });
  };
  searchRegex = (text) => {
    if (text) {
      const { search, onChange } = this.props;
      const { offersOptions } = this.state;
      const offersSelected = _.reduce(
        offersOptions,
        (memo, { value, label }) =>
          label.indexOf(text) >= 0 ? (memo += ';' + value) : memo,
        '',
      );
      onChange({ ...search, jobOfferIds: offersSelected });
    }
  };
  render() {
    const { onChange, search } = this.props;
    const {
      actionsTypeOptions,
      clientsOptions,
      offersOptions,
      answersLabelsOptions,
    } = this.state;
    return (
      <SweetForm
        onChange={onChange}
        initialValues={search}
        key={JSON.stringify(search).length}
      >
        <Form>
          <Form.Field>
            <label>Types</label>
            <Select
              field="types"
              placeholder="Types"
              options={actionsTypeOptions}
              multi={true}
            />
          </Form.Field>
          {search.types &&
           _.indexOf(search.types.split(';'), 'answer-detected') > -1 && (
             <Form.Field>
               <label>Labels</label>
               <Select
                 field="labels"
                 placeholder="Labels"
                 options={answersLabelsOptions}
                 multi={true}
               />
             </Form.Field>
           )}
          <Form.Field>
            <label>Clients</label>
            <Select
              field="clientIds"
              placeholder="Clients"
              options={clientsOptions}
              multi={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Offers</label>
            <Select
              field="jobOfferIds"
              placeholder="Offers"
              options={offersOptions}
              multi={true}
            />
            <RegexModal searchRegex={this.searchRegex} />
            <Button size="mini" onClick={() => this.searchRegex('watch')}>
              /watch/
            </Button>
            <Button size="mini" onClick={() => this.searchRegex('instantflow')}>
              /instantflow/
            </Button>
          </Form.Field>
          <Form.Field>
            <label>Actions comprises entre le</label>
            <Input field="minDate" type="date" placeholder="Date min" />
          </Form.Field>
          <Form.Field>
            <label>et le</label>
            <Input field="maxDate" type="date" placeholder="Date max" />
          </Form.Field>
          <Form.Field>
            <label>WatchOnly</label>
            <Checkbox field="onlyWatch" />
          </Form.Field>
        </Form>
      </SweetForm>
    );
  }
}

class Actions extends Component {

  state = { focusMode: null };

  componentDidMount() {
    this.props.onLoadActions();
    this.props.onLoadFilterOptions();
  }

  handleToggleFocusMode = (index) => {
    this.setState({ 
      focusMode: !this.state.focusMode ? ({
        key: '' + Math.random,
        defaultIndex: index || 0
      }) : null
    });
  }

  render() {
    const {
      actions,
      page,
      search,
      onLoadActions,
      onProfileModal,
      onMessageModal,
      onSetMessageLabel,
      onHardPostpone,
      modalMode,
      selectedProfile,
      selectedAction,
      onCancel,
      actionsOptions,
      onSearch,
    } = this.props;

    const { focusMode } = this.state;

    return (
      <div>
      {focusMode ? (
        <FocusModal
          key={focusMode.key}
          actions={(actions || {}).results || []} 
          defaultIndex={focusMode.defaultIndex}
          onSetMessageLabel={onSetMessageLabel}
          onHardPostpone={onHardPostpone}
          onClose={this.handleToggleFocusMode}
        />
      ) : null}
      {modalMode === 'profile' ? (
        <Modal
          active={true}
          headerText="Profile"
          submitText="OK"
          cancelText=""
          onSubmit={onCancel}
          onCancel={onCancel}
        >
          <SweetappProfile profileId={selectedProfile.id} />
        </Modal>
      ) : null}

      {modalMode === 'message' ? (
        <Modal
          active={true}
          headerText="Message"
          submitText="OK"
          cancelText=""
          onSubmit={onCancel}
          onCancel={onCancel}
        >
          {_.isString(selectedAction.message) ? (
            <div
              dangerouslySetInnerHTML={{ __html: selectedAction.message }}
            />
          ) : _.isString(selectedAction.message.snippet) ? (
            <div>{selectedAction.message.snippet}</div>
          ) : null}
        </Modal>
      ) : null}
      <Grid columns={2}>
        <Grid.Column width={3}>
          <ActionsSearchForm
            actionsOptions={actionsOptions}
            onChange={(s) => {
              onSearch(s);
              onLoadActions(s, 0);
            }}
            search={search}
          />
        </Grid.Column>
        <Grid.Column width={13}>
          <ActionsList
            actions={actions}
            page={page}
            toggleFocusMode={this.handleToggleFocusMode}
            onPage={(i) => onLoadActions(search, i)}
            onProfileModal={onProfileModal}
            onMessageModal={onMessageModal}
            onSetMessageLabel={onSetMessageLabel}
            onHardPostpone={onHardPostpone}            
          />
        </Grid.Column>
      </Grid>
      </div>
    );
  }
}

// Containers
const mapSAction = (state) => ({
  page: state.page,
  search: state.search,
  actions: state.actions,
  modalMode: state.modalMode,
  selectedProfile: state.selectedProfile,
  selectedAction: state.selectedAction,
  actionsOptions: state.actionsOptions,
});

const mapDAction = (dispatch) => ({
  onLoadFilterOptions: async () => {
    const clients = (await axios.get(`${baseUrl}/station/clients`)).data;
    const clientsList = _.map(clients, ({ id, name }) => ({ id, name }));
    const offers = (await axios.get(`${baseUrl}/station/clients/all/offers`))
      .data;
    const offersList = _.map(offers, ({ id, title, clientId }) => ({
      id,
      title,
      clientId,
    }));
    const actionsList = [
      'answer-detected',
      'thread-detected',
      'send-follow-up',
      'disable-follow-up',
      'blocked-send-follow-up',
    ];
    const labelsList = (await axios.get(`${baseUrl}/station/actions/labels`))
      .data;
    dispatch(
      setActionsFilterOptions({
        clientsList,
        offersList,
        actionsList,
        labelsList,
      }),
    );
  },
  onLoadActions: async (search = {}, page = 0) => {
    const minTimeStamp = search.minDate && new Date(search.minDate).getTime();
    const maxTimeStamp = search.maxDate && new Date(search.maxDate).getTime();
    const externalActionsTypes = ['answer-detected', 'thread-detected'];
    const body = {
      ...search,
      ...(search && !search.types && { types: externalActionsTypes.join(';') }),
      
      ...(minTimeStamp && { minTimeStamp }),
      ...(maxTimeStamp && { maxTimeStamp }),
    };
    const { data } = await axios.post(
      `${baseUrl}/station/actions?limit=${PER_PAGE}&skip=${PER_PAGE * page}`,
      { 
        minTimeStamp: Date.now() - 90*24*3600*1000,
        ...body,
      }
    );
    dispatch(setActions(data, page));
  },
  onProfileModal: async (profileId) => {
    const body = {
      ids: profileId,
    };
    const result = await axios.post(
      `${baseUrl}/station/profiles?limit=10`,
      body,
    );
    dispatch(setModalMode('profile', result.data.results[0]));
  },
  onMessageModal: async (action) => {
    dispatch(setModalMode('message', action));
  },
  onSetMessageLabel: async (action, label) => {
    const body = {
      actionId: action._id,
      label,
    };
    const result = await axios.post(`${baseUrl}/station/actions/label`, body);
    if (result.data && result.data.action) {
      const newAction = {
        ...action,
        ...result.data.action
      }
      dispatch(updateAction(newAction));
    }
  },
  onHardPostpone: async (linkedinUrl, count) => {
    try {
      const linkedinId = (linkedinUrl || '').replace('https://linkedin.com/in/', '');
      if (!linkedinId) {
        throw Error('invalid url: ' + linkedinUrl);
      }
      const result = (await axios.post(`${baseUrl}/station/hardPostpone`, {
        linkedinId,
        count
      })).data;
      if (result.error) {
        alert(result.error);
      } else {
        alert(linkedinId + ' marked ' + count + ' (total ' + result.totalCount + ')');
      }
    } catch (e) {
      alert(e.message);
    }
  },
  onSearch: async (search) => dispatch(setSearch(search)),
  onCancel: () => dispatch(setModalMode('none')),
});

const ActionContainer = connect(
  mapSAction,
  mapDAction,
)(Actions);

export default ({ match, history }) => (
  <Provider store={store}>
    <ActionContainer match={match} history={history} />
  </Provider>
);
