export default
[
    {
      "value":"normal",
      "label": "Contributeur Individuel"
    },
    {
      "value":"lead",
      "label": "Lead Developer"
    },
    {
      "value":"director",
      "label": "Directeur"
    }
  ]
  