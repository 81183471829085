import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  Icon,
} from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

class OfferCheckerMonitoring extends Component {
  state = {
    filters: {
      targetLevel: 'all',
      targetType: 'all',
      targetField: 'all',
      targetStatus: 'not-ignored',
      targetActivity: 'all',
      targetUser: 'all',
    }
  };
  componentWillMount() {
    this.query = {};
    this.loadData();
  }

  loadData = async () => {
    // TODO : add a route
    const data = (await axios.post(
      baseUrl + '/reverseSearch/offerIssues',
      this.query,
    )).data;
    const issues = (data || {}).issues;
    this.setState({ issues: issues });
  };

  getItemColor(item) {
    if (item['level'] && item['level'] ==='error') {
      return '#ffdddd';
    } else {
      if (item['type'] && item['type'] === 'suggestion') {
        return '#ddffdd';
      }
    }
    return '#ffffdd';
  }

  renderItemFilters(label, fieldType, choices) {
    const status = this.state['filters'][fieldType];
    return (
      <div style={{ marginTop: '5px' }}>
        <h2>
          {' '}
          {label + ' : '}
          {_.map(choices, (choice, index) => (
            <Button
              color={
                (choice === 'all' && !status) || choice === status
                  ? 'green'
                  : 'grey'
              }
              key={index}
              onClick={() => {
                var newFilters = this.state['filters'];
                newFilters[fieldType] = choice;
                this.setState({ filters: newFilters });
              }}
            >
              {choice}
            </Button>
          ))}
        </h2>
      </div>
    );
  }

  renderLevelFilters() {
    const label = 'Level';
    const fieldType = 'targetLevel';
    const choices = ['all', 'error', 'warning'];
    return this.renderItemFilters(label, fieldType, choices);
  }

  renderTypeFilters() {
    const label = 'Type';
    const fieldType = 'targetType';
    const choices = [
      'all',
      'not-defined',
      'not-valid',
      'suspicious',
      'suggestion',
    ];
    return this.renderItemFilters(label, fieldType, choices);
  }

  renderUsersFilters() {
    const label = 'Users';
    const fieldType = 'targetUser';
    const choices = [
      'all',
      'bastien',
      'corentin',
      'florent',
      'mathieu',
      'laurene',
      'jerome',
      'lamia',
      'ismael',
      'louise',
      'luc',
      'paul',
      'remidbs',
      'remils',
      'robin',
      'rodolphe',
      'valentin',
      'warda',
      'yannis',
    ];
    return this.renderItemFilters(label, fieldType, choices);
  }

  renderFieldFilters() {
    const label = 'Field';
    const fieldType = 'targetField';
    const choices = [
      'all',
      'location',
      'jobId',
      'contractType',
      'experience',
      'skills',
      'salary',
      'responsibilities',
      'topSchool',
      'education'
    ];
    return this.renderItemFilters(label, fieldType, choices);
  }

  renderStatusFilters() {
    const label = 'Status';
    const fieldType = 'targetStatus';
    const choices = ['all', 'ignored', 'not-ignored'];
    return this.renderItemFilters(label, fieldType, choices);
  }

  renderActiveFilters() {
    const label = 'Activity';
    const fieldType = 'targetActivity';
    const choices = ['all', 'active', 'not-active'];
    return this.renderItemFilters(label, fieldType, choices);
  }

  renderFilters() {
    return (
      <div>
        {this.renderLevelFilters()}
        {this.renderTypeFilters()}
        {this.renderFieldFilters()}
        {this.renderStatusFilters()}
        {this.renderActiveFilters()}
        {this.renderUsersFilters()}
      </div>
    );
  }

  setStatus = async (offerIssue, status) => {
    const query = {
      id: offerIssue['identifier'],
      status: status,
    };
    await axios.post(baseUrl + '/reverseSearch/offerIssues/setStatus', query);
    offerIssue['status'] = status;
    var issues = this.state['issues'];
    this.setState({
      issues: _.each(issues, (issue) => {
        if (issue['identifer'] === offerIssue['identifier']) {
          return offerIssue;
        }
        return issue;
      }),
    });
  };

  renderOfferIssues(offerIssues) {
    if (!offerIssues) {
      return;
    }
    const columnNames = [
      'offerId',
      'level',
      'field',
      'type',
      'suggestion',
      'reasons',
      'ignore',
    ];
    // const levelToIndex = {
    //   error: 0,
    //   warning: 1,
    // };
    offerIssues = _.sortBy(offerIssues, (offerIssue) => offerIssue['level']);
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            {columnNames.map((name, index) => {
              return (
                <Table.HeaderCell key={index} textAlign="center">
                  {name}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {offerIssues.map((offerIssue, index) => {
            return (
              <Table.Row
                key={index}
                style={{ backgroundColor: this.getItemColor(offerIssue) }}
              >
                {columnNames.map((name, index2) => {
                  if (name === 'offerId') {
                    return (
                      <Table.Cell key={index2} textAlign="center">
                        <span>
                          <a
                            target="_blank"
                            href={'/offers/' + offerIssue[name]}
                            rel='noopener noreferrer'
                          >
                            {offerIssue[name] || ''}
                          </a>
                        </span>
                      </Table.Cell>
                    );
                  } else {
                    if (name === 'ignore') {
                      return (
                        <Table.Cell key={index2} textAlign="center">
                          <Icon
                            link
                            color={'black'}
                            name="thumbs down"
                            onClick={() =>
                              this.setStatus(offerIssue, 'ignored')
                            }
                          />
                          <Icon
                            link
                            color={'black'}
                            name="thumbs up"
                            onClick={() =>
                              this.setStatus(offerIssue, 'not-ignored')
                            }
                          />
                        </Table.Cell>
                      );
                    } else {
                      return (
                        <Table.Cell key={index2} textAlign="center">
                          <span>{offerIssue[name] || ''}</span>
                        </Table.Cell>
                      );
                    }
                  }
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  filterIssues(offerIssues) {
    const filters = this.state['filters'] || {};
    if (filters['targetLevel'] !== 'all') {
      offerIssues = _.filter(
        offerIssues,
        (offerIssue) => offerIssue['level'] === filters['targetLevel'],
      );
    }
    if (filters['targetField'] !== 'all') {
      offerIssues = _.filter(
        offerIssues,
        (offerIssue) => offerIssue['field'] === filters['targetField'],
      );
    }
    if (filters['targetType'] !== 'all') {
      offerIssues = _.filter(
        offerIssues,
        (offerIssue) => offerIssue['type'] === filters['targetType'],
      );
    }
    if (filters['targetStatus'] !== 'all') {
      offerIssues = _.filter(
        offerIssues,
        (offerIssue) => offerIssue['status'] === filters['targetStatus'],
      );
    }
    if (filters['targetActivity'] !== 'all') {
      offerIssues = _.filter(
        offerIssues,
        (offerIssue) => offerIssue['activity'] === filters['targetActivity'],
      );
    }
    if (filters['targetUser'] !== 'all') {
      offerIssues = _.filter(
        offerIssues,
        (offerIssue) => offerIssue['users'].indexOf(filters['targetUser']) >= 0,
      );
    }
    return offerIssues;
  }

  renderNumber(issues) {
    if ((issues || []).length >= 2) {
      return <h2> {(issues || []).length + ' ISSUES'}</h2>;
    }
    return <h2> {(issues || []).length + ' ISSUE'}</h2>;
  }

  render() {
    const issues = this.filterIssues(this.state['issues']);
    return (
      <div>
        {this.renderFilters()}
        {this.renderNumber(issues)}
        {this.renderOfferIssues(issues)}
      </div>
    );
  }
}

export default OfferCheckerMonitoring;
