
import _ from 'underscore';
import React, { Component } from 'react';
import {
  Button,
  Grid,
  Modal,
  Icon,
  Checkbox,
  Input,
  Form
} from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

const REASONS = [
  {id:'skills', 'label':'Skills'},
  {id:'job', 'label':'Job'},
  {id: 'xp', 'label':'Experience'},
  {id: 'prestige', 'label':'Prestige'},
  {id:'responsibilities', 'label':'Responsibilities'},
]

class ReasonAnnotationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }
  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })
  toggle = (reason) => this.setState({ [reason]: !this.state[reason] })
  handleChangeCustom = (event) => this.setState({custom: event.target.value})
  getRawReasons = () => {
    const fixedFieldnames = _.pluck(REASONS, 'id');
    const fixedReasons = _.map(fixedFieldnames, (fieldname)=>{
      if(this.state[fieldname]){
        return fieldname
      }
    });
    return fixedReasons.concat([this.state.custom])
  }
  hasEnteredReason = () => {
    const rawReasons = this.getRawReasons();
    return _.some(rawReasons)
  }
  annotateAndCloseIfReason = async () => {
    const {
      idFields,
      offer,
      annotate,
      annotationTags,
      selectNextMatch,
      matchingId
    } = this.props;
    const rawReasons = this.getRawReasons();
    if (_.some(rawReasons)){
      const reasons = _.compact(rawReasons) 
      const result = await annotate({idFields, offer, reasons, grade:annotationTags, matchingId});
      if (result.success) {
        await selectNextMatch(1);
        this.handleClose();
      } else {
        console.log(result)
      }
    } else {
      console.log('No reason entered')
    }
  }
  render() {
    const {
      color,
      displayText,
    } = this.props;

    return (
      <Modal 
        trigger={
          <Button
            circular
            color={color}
            onClick={this.handleOpen}
            style={{ padding: '.78571429em .78571429em .78571429em' }}>
              <div style={{
                width: '30px',
                height: '30px',
                fontSize: '20px',
                lineHeight: '30px',
                verticalAlign: 'middle',
              }}>{displayText}</div>
          </Button>} 
        size='small'
        open={this.state.modalOpen}
        onClose={this.handleClose}>
        <Modal.Header>{`${displayText} : reason needed.`}</Modal.Header>
        <Modal.Content>
        <Form>
          <Form.Field>
            <Input label='Custom' value={this.state.custom} onChange={this.handleChangeCustom}/>
          </Form.Field>
          {
            _.map(REASONS, (reason)=>{
              return (
                <Form.Field>
                  <Checkbox 
                    label={reason.label}
                    onChange={()=>this.toggle(reason.id)} 
                    checked={this.state[reason.id] || false} 
                  />
                </Form.Field>
              )
            })
          }
        </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={!this.hasEnteredReason()} onClick={()=>{this.annotateAndCloseIfReason()}} color='green' inverted>
            <Icon name='checkmark' /> Sumbit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const NoReasonAnnotationButton = ({
    selectNextMatch,
    annotate,
    color,
    annotationTags,
    idFields,
    offer,
    displayText,
    matchingId
  }) => {
  const onClick = async () => {
    const result = await annotate({grade:annotationTags, idFields, offer, matchingId});
    if (result.success) {
      await selectNextMatch(1);
    } else {
      console.log('Annotation failed :', result)
    }
  }
  return (
    <Button
        circular
        color={color}
        style={{ padding: '.78571429em .78571429em .78571429em' }}
        onClick={onClick}>
          <div style={{
            width: '30px',
            height: '30px',
            fontSize: '20px',
            lineHeight: '30px',
            verticalAlign: 'middle',
          }}>{displayText}</div>
    </Button>
  )
}


class Annotation extends Component {

    state = { activeIndex: 1 }

    async annotate({grade, idFields, offer, reasons, matchingId}) {
        if (!idFields) {
            console.log("No idFields Selected");
            return;
        }
        if (!offer) {
            console.log("No Offer Selected")
            return;
        }
        if (!grade) {
            console.log("No Grade Selected")
            return;
        }

        const data = {
            "profile": {idFields},
            "offer": offer,
            "grade": grade,
            "reasons": reasons,
            "matchingId": matchingId
        }

        const result = (await axios.post(`${baseUrl}/reverseSearch/annotateMatch`, data)).data;
        return result
    }

    renderButton () {
        const {
          idFields,
          offer,
          selectNextMatch,
          matchingId,
          buttonsList
        } = (this.props || {});
        console.log(buttonsList)

        if (!idFields || !offer){
            return (<div></div>)
        }
        const gridStyle = { margin: '0px', width: `${70*buttonsList.length}px` };
        return (
        <Grid style={gridStyle}>
          <Grid.Row columns={buttonsList.length} style={{ padding: '0px' }}>
            {
              _.map(buttonsList, (button)=>{
                if (button.type === 'selectNext'){
                    return (
                      <Grid.Column style={{ padding: '0px' }}>
                        <Button icon={button.icon} onClick={()=>(selectNextMatch(button.indexIncrease))} />
                      </Grid.Column>
                    )
                } else if (button.type === 'noReason'){
                    return (
                      <Grid.Column style={{ padding: '0px' }}>
                        <NoReasonAnnotationButton
                          matchingId={matchingId}
                          selectNextMatch={selectNextMatch}
                          annotate={this.annotate}
                          color={button.color}
                          displayText={button.grade}
                          annotationTags={button.grade}
                          idFields={idFields}
                          offer={offer}
                          hoverText={button.hoverText}
                        />
                      </Grid.Column>
                    )
                } else if (button.type === 'reason'){
                    return (
                      <Grid.Column style={{ padding: '0px' }}>
                        <ReasonAnnotationButton
                          matchingId={matchingId}
                          selectNextMatch={selectNextMatch}
                          annotate={this.annotate}
                          color={button.color}
                          displayText={button.grade}
                          annotationTags={button.grade}
                          idFields={idFields}
                          offer={offer}
                          hoverText={button.hoverText}
                        />
                      </Grid.Column>
                    )
                }
              })
            }
          </Grid.Row>
        </Grid>
        )
    }
      
    render () {
        return (this.renderButton())
      }
    }
    
    export default Annotation;