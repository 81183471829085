import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { reducer as formReducer, reduxForm, Field } from 'redux-form';
import Select from 'react-select';
import { compose, withProps } from 'recompose';
import axios from 'axios';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Rectangle,
  Circle,
} from 'react-google-maps';
import {
  Grid,
  Button,
  Label,
  Header,
  Divider,
  Form,
  Input,
  Message,
  Loader,
} from 'semantic-ui-react';
// See https://developers.google.com/maps/documentation/javascript/overlays
import baseUrl from './baseUrl.js';

// Actions
const WAIT = 'WAIT';
const setWait = () => ({ type: WAIT });

const SET_LOCATION_DATA = 'SET_LOCATION_DATA';
const setLocationData = (data) => ({ type: SET_LOCATION_DATA, data });

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
const toggleEditMode = () => ({ type: TOGGLE_EDIT_MODE });

const TOGGLE_WARN_MODE = 'TOGGLE_WARN_MODE';
const toggleWarnMode = () => ({ type: TOGGLE_WARN_MODE });

// Reducers
const locationData = (state = {}, action) => {
  switch (action.type) {
    case SET_LOCATION_DATA:
      return action.data;
    case WAIT:
      return {};
    default:
      return state;
  }
};

const search = (state = '', action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case SET_LOCATION_DATA:
      return false;
    case WAIT:
      return true;
    default:
      return state;
  }
};

const editMode = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MODE:
      return !state;
    case SET_LOCATION_DATA:
      return false;
    default:
      return state;
  }
};

const warnMode = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_WARN_MODE:
      return !state;
    case SET_LOCATION_DATA:
      return false;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  locationData,
  search,
  loading,
  editMode,
  warnMode,
  form: formReducer,
});

// Store
const store = createStore(rootReducer);

// Components

// See https://stackoverflow.com/questions/31197596/google-map-api-marker-icon-url
const mapTypeToColor = {
  big: 'blue',
  middle: 'green',
  small: 'yellow',
};

const mapTypeToRadius = {
  big: 30000,
  middle: 10000,
  small: 2000,
};

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAmlwt_tI6GkJTPB13r3hB3h1WlMGKdzSA&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ zoom, lat, lng, cities, box }) => (
  <GoogleMap defaultZoom={zoom} defaultCenter={{ lat, lng }}>
    {lat && lng ? <Marker key="geopy" position={{ lat, lng }} /> : ''}
    {cities.map((c) => (
      <Marker
        key={c.id}
        position={{ lat: c.latitude, lng: c.longitude }}
        icon={`https://maps.google.com/mapfiles/ms/icons/${
          mapTypeToColor[c.category]
        }.png`}
        title={c.name}
      />
    ))}
    {cities.map((c) => (
      <Circle
        key={`${c.id}_circle`}
        center={{ lat: c.latitude, lng: c.longitude }}
        radius={mapTypeToRadius[c.category]}
        options={{ fillColor: mapTypeToColor[c.category], strokeWeight: 0 }}
      />
    ))}
    {box ? <Rectangle bounds={box} /> : ''}
  </GoogleMap>
));

const getCitiesOptions = async (input) => {
  const towns = (await axios.get(`${baseUrl}/towns/${input}`)).data;
  return {
    options: _.map(towns, ({ name, id }) => ({ value: id, label: name })),
  };
};

const CitySelect = ({ name, input }) => (
  <Select.Async
    name={name}
    value={input.value}
    onChange={input.onChange}
    inputProps={{ type: 'react-type' }}
    loadOptions={_.throttle(getCitiesOptions, 1000)}
  />
);

const EditForm = reduxForm({
  form: 'edit',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(({ handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Form.Group widths="equal">
      <Form.Field>
        <label>BigCity</label>
        <Field name="bigCity" component={CitySelect} />
      </Form.Field>
      <Form.Field>
        <label>Distance</label>
        <Field
          name="bigCityDistance"
          component="input"
          type="text"
          placeholder="Distance"
        />
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <label>MiddleCity</label>
        <Field name="middleCity" component={CitySelect} />
      </Form.Field>
      <Form.Field>
        <label>Distance</label>
        <Field
          name="middleCityDistance"
          component="input"
          type="text"
          placeholder="Distance"
        />
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <label>SmallCity</label>
        <Field name="smallCity" component={CitySelect} />
      </Form.Field>
      <Form.Field>
        <label>Distance</label>
        <Field
          name="smallCityDistance"
          component="input"
          type="text"
          placeholder="Distance"
        />
      </Form.Field>
    </Form.Group>
  </Form>
));

const EditFormContainer = connect((state) => ({
  initialValues: {
    bigCityDistance: 0,
    middleCityDistance: 0,
    smallCityDistance: 0,
  },
}))(EditForm);

class Location extends Component {
  componentDidMount() {
    this.props.onSubmitSearch('');
  }

  render() {
    return (
      <Grid columns={2}>
        <Grid.Column>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.onSubmitSearch(this.props.search);
            }}
          >
            <Form.Field>
              <Input
                autoFocus
                placeholder="rawLocation"
                onChange={this.props.onChangeSearch}
              />
            </Form.Field>
          </Form>

          <Divider />
          <Header as="h3">{this.props.rawLocation}</Header>

          {this.props.editMode ? (
            <Message onDismiss={this.props.onToggleEdit}>
              <EditFormContainer
                onSubmit={(v) =>
                  this.props.onEditSubmit(this.props.rawLocation, v)
                }
              />
            </Message>
          ) : null}

          {this.props.rawLocation ? (
            <div>
              <Button color="blue" onClick={this.props.onToggleEdit}>
                Edit
              </Button>
              <Button color="yellow" onClick={this.props.onToggleWarn}>
                {this.props.warnMode ? 'Cancel' : 'Warn'}
              </Button>
              {this.props.warnMode ? (
                <Button
                  color="red"
                  onClick={() =>
                    this.props.onConfirmWarn(this.props.rawLocation)
                  }
                >
                  Click here to confirm warn operation
                </Button>
              ) : null}

              <pre>{JSON.stringify(this.props.closestCities, null, '  ')}</pre>
            </div>
          ) : null}
        </Grid.Column>
        <Grid.Column>
          {this.props.rawLocation ? (
            <Map
              zoom={this.props.zoom}
              lat={this.props.lat}
              lng={this.props.lng}
              cities={this.props.closestCities}
              box={this.props.box}
            />
          ) : (
            <div>
              {this.props.loading ? (
                <Loader active inline />
              ) : (
                <Label color="red">No match</Label>
              )}
            </div>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers
const mapSLocation = (state) => {
  const geopy = state.locationData.geopyInfo;
  const box = geopy ? geopy.boundingbox : undefined;

  return {
    search: state.search,
    loading: state.loading,
    ...state.locationData,
    editMode: state.editMode,
    warnMode: state.warnMode,
    zoom: geopy ? 9 : 2,
    lat: geopy ? +geopy.lat : 0,
    lng: geopy ? +geopy.lon : 0,
    box: box
      ? {
          north: +box[0],
          south: +box[1],
          west: +box[2],
          east: +box[3],
        }
      : null,
  };
};

const onSubmitSearch = async (dispatch, s) => {
  dispatch(setWait());
  const result = await axios.get(`${baseUrl}/locations/${s}`);
  dispatch(setLocationData(result.data));
};

const mapDLocation = (dispatch) => ({
  onChangeSearch: (e) => dispatch(setSearch(e.target.value)),
  onSubmitSearch: (s) => onSubmitSearch(dispatch, s),
  onToggleEdit: () => dispatch(toggleEditMode()),
  onToggleWarn: () => dispatch(toggleWarnMode()),
  onEditSubmit: async (rawLocation, values) => {
    await axios.put(`${baseUrl}/locations/${rawLocation}`, values);
    return onSubmitSearch(dispatch, rawLocation);
  },
  onConfirmWarn: async (rawLocation) => {
    await axios.delete(`${baseUrl}/locations/${rawLocation}`);
    return onSubmitSearch(dispatch, '');
  },
});

const LocationContainer = connect(
  mapSLocation,
  mapDLocation,
)(Location);

export default () => (
  <Provider store={store}>
    <LocationContainer />
  </Provider>
);
