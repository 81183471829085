import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';
import React, { Component } from 'react';
import { Card, Segment, Accordion, Header, Button, Icon, Label as SemanticLabel } from 'semantic-ui-react';
import { Cell } from 'recharts';
import { STATUS_OPTIONS } from '../TechAssetSandBox/TestEditModal';

class SingleTestDashboard extends Component {
  render() {
    const { tests } = this.props;
    const { sweetfunctionId } = this.props;
    const successfulTests = _.filter(tests, (test) => test.success);
    const successRatio = Math.round((100 * successfulTests.length) / tests.length);
    const ok = successRatio >= 80;
    const perfecto = successRatio == 100;
    const star = successRatio >= 80 && tests.length >= 50;
    return (
      <Card color={ok ? 'green' : 'red'}>
        <Card.Header textAlign='center'>
          {star ? <Icon name={'star'} color={'yellow'} /> : null}
          {sweetfunctionId}
          {star ? <Icon name={'star'} color={'yellow'} /> : null}
        </Card.Header>
        <Card.Content>
          <Icon
            name={ok ? 'check circle' : 'warning circle'}
            color={perfecto ? 'green' : ok ? 'orange' : 'red'}
            size='large'
          />
          <b style={{ color: perfecto ? 'green' : ok ? 'orange' : 'red' }}>
            {successRatio} % ({successfulTests.length} / {tests.length})
          </b>
        </Card.Content>
      </Card>
    );
  }
}

class TechAssetDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { statusFilter: 'pending' };
    this.loadData();
  }

  loadData = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/dashboardStats';
    const data = (await axios.get(url, customHeaders)).data;
    this.setState({ tests: data.data.tests });
  };

  handleSetStatusFilter = (statusFilter) => {
    this.setState({ statusFilter });
  };

  render() {
    const tests = (this.state || {}).tests || [];
    const { statusFilter } = this.state || {};
    const filteredTest = _.filter(
      tests,
      (test) => this.state.statusFilter == 'all' || this.state.statusFilter == test.status,
    );
    const subDashboardNames = _.unique(_.map(filteredTest, (test) => test.sweetfunctionId));
    const successfulTests = _.filter(filteredTest, (test) => test.success);
    const successRatio = Math.round((100 * successfulTests.length) / filteredTest.length);
    const ok = successRatio >= 80;
    const perfecto = successRatio == 100;
    const filterStatusOptions = ['all'].concat(_.map(STATUS_OPTIONS, ({ value }) => value));
    return (
      <div>
        <Segment color={perfecto ? 'green' : ok ? 'orange' : 'red'}>
          {_.map(filterStatusOptions, (value) => (
            <Button color={value === statusFilter ? 'green' : 'grey'} onClick={() => this.handleSetStatusFilter(value)}>
              {value}
            </Button>
          ))}
          <Header as='h2' style={{ color: perfecto ? 'green' : ok ? 'orange' : 'red' }}>
            <Icon
              name={ok ? 'check circle' : 'warning circle'}
              color={perfecto ? 'green' : ok ? 'orange' : 'red'}
              size='large'
            />
            GLOBAL TEST RATIO : {successRatio} %
          </Header>
          <Accordion.Content active={this.state.active}>
            <Card.Group textAlign='center'>
              {_.map(subDashboardNames, (sweetfunctionId) => (
                <SingleTestDashboard
                  tests={_.filter(filteredTest, (test) => test.sweetfunctionId == sweetfunctionId)}
                  sweetfunctionId={sweetfunctionId}
                />
              ))}
            </Card.Group>
          </Accordion.Content>
        </Segment>
      </div>
    );
  }
}

export default TechAssetDashboard;
