import React, { Component } from 'react';
import _ from 'underscore';
import { Form, Grid, Accordion, Icon } from 'semantic-ui-react';
import Modal from '../../Modal';
import { SweetForm, Clearable, Input, Select, SelectInt, Range, List, enhance } from 'sweetform';
import { Textarea } from '../../common';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import { EducationFieldPrestigeEditor, fitIsValid } from '../../Monitoring/Education/EducationFields';
import { SelectUsers } from './EditOfferModalComponents';
import { loadSkills } from './EditOmegaCriteria';

export const COMPANY_TYPES_OPTIONS = [
  { name: 'Start Up', id: 'startup' },
  { name: 'Big', id: 'big' },
  { name: 'SSII', id: 'ssii' },
];

export const TYPE_OPTIONS = [
  { value: 'freelance', label: 'Freelance' },
  { value: 'school', label: 'School' },
  { value: 'research', label: 'Research' },
  { value: 'company', label: 'Company' },
];

export const NETWORK_LEVEL_OPTIONS = [
  { value: 0, label: '0 (active client)' },
  { value: 1, label: '1 (inactive client)' },
  { value: 2, label: '2 (in touch)' },
  { value: 3, label: '3 (close network)' },
  { value: 4, label: '4 (far network)' },
  { value: 5, label: '5 (not network)' },
];

export const STATUS_OPTIONS = [
  { value: 'current', label: 'Current' },
  { value: 'hold', label: 'Hold' },
  { value: 'finished', label: 'Finished' },
];

const getClients = async (input) => {
  const result = await axios.get(`${baseUrl}/station/clients?limit=100&filter=${input}`);
  return { options: result.data.error ? [] : result.data };
};

const getSchoolTypes = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/workplaces/listSchoolTypes`);
    return { options: data.error ? [] : data };
  } catch (e) {
    alert(e);
  }
};

export const SelectPrestige = (props) => <SelectInt {...props} min={0} max={5} />;

export const SelectSchoolType = (props) => (
  <Select {...props} async loadOptions={getSchoolTypes} labelKey='name' valueKey='id' />
);

const SelectType = (props) => <Select {...props} options={TYPE_OPTIONS} />;

const SelectStatus = (props) => <Select {...props} options={STATUS_OPTIONS} />;
const SelectNetworkLevel = (props) => <Select {...props} options={NETWORK_LEVEL_OPTIONS} />;

const SelectStationClient =  (props) => <Select {...props} async loadOptions={getClients} labelKey='name' valueKey='id' />;


const PRICING_OPTIONS = [
  { label: "Success Fee 18%", value:"successFee18"},
  { label: "Success Fee 12%", value:"successFee12"},
  { label: "Success Fee 15%", value:"successFee15"},
  { label: "Pack", value:"pack"},
  { label: "1500€ + Success Fee 10%", value:"successFee10Upfront1500"},
  { label: "Autre", value:"other"},
]
const SelectPricingAndDate = enhance((props) => (
    <div>
      <Grid columns={2}>
        <Grid.Column>
          <Select field="pricing" options={PRICING_OPTIONS} placeholder="Pricing option"/>
        </Grid.Column>
        <Grid.Column>
          <Input field="startDate" type="date" placeholder="Start date"/>
        </Grid.Column>
      </Grid>
    </div>
));


// const InputLinkedinLink = (props) => (
//   <Input
//     type="text"
//     placeholder="https://..."
//   />
// )

class CollapsibleList extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { title, elements } = this.props;

    return (
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
          <Icon name='dropdown' />
          {title}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <ul>
            {_.map(elements, (element) => (
              <li>{element}</li>
            ))}
          </ul>
        </Accordion.Content>
      </Accordion>
    );
  }
}

class EditWorkplaceModal extends React.Component {
  state = {
    industries: [],
  };
  async componentWillMount() {
    const { initialValues } = this.props;
    // load industries
    const { data: industries } = await axios.get(`${baseUrl}/tags/list?type=industry`);
    // load education fields
    const { data: educationFields } = await axios.get(`${baseUrl}/tags/list?type=education-field`);

    const { data: adminTags } = await axios.get(`${baseUrl}/workplaces/adminTags`);
    const educationFieldIds = _.pluck(educationFields, 'id');
    const currentValues = initialValues
      ? {
          ...initialValues,
          industriesLabeled: (initialValues.industriesLabeled || []).join(';'),
          companyTypes: (initialValues.companyTypes || []).join(';'),
        }
      : {};
    this.setState({
      industries,
      educationFieldIds,
      adminTags,
      currentValue: currentValues,
    });
  }

  handleChange = (newValue) => {
    this.setState({ currentValue: newValue });
  };

  handleSubmit = async () => {
    const { currentValue } = this.state;
    const { onSubmit, onCancel, creationMode } = this.props;
    if (!currentValue.name) {
      return { success: false, error: 'Name is required' };
    }
    if (creationMode) {
      currentValue.id = (currentValue.id || currentValue.name).toLowerCase().replace(/[^a-z0-9]/g, '-');
    }
    currentValue.industriesLabeled = currentValue.industriesLabeled ? currentValue.industriesLabeled.split(';') : [];
    currentValue.companyTypes = currentValue.companyTypes ? currentValue.companyTypes.split(';') : [];
    currentValue.stackLabeled = currentValue.stackLabeled ? currentValue.stackLabeled.split(';') : [];
    currentValue.tags = currentValue.tags || [];
    currentValue.source = currentValue.source || 'hiresweetWorkplaces';

    const result = await onSubmit(_.omit(currentValue, 'deleteField'));
    if (result && result.success) {
      onCancel();
    } else {
      return alert((result || {}).error);
    }
  };

  handleDelete = async () => {
    const { onDelete, onCancel, initialValues } = this.props;
    try {
      const result = await onDelete(initialValues);
      if (result && result.success) {
        onCancel();
      } else {
        return alert((result || {}).error);
      }
    } catch (error) {
      alert(error);
    }
  };

  render() {
    const { initialValues, onCancel } = this.props;
    const { industries, educationFieldIds, currentValue, adminTags } = this.state;
    const sortedFieldIds = _.sortBy(educationFieldIds, (fieldId) => {
      const fit = ((initialValues || {}).educationFieldPrestige || {})[fieldId];
      if (fitIsValid(fit)) {
        return -fit;
      }
      return -1000;
    });
    const editMode = _.isEmpty(initialValues) ? 'create' : 'edit';

    const deleteAction = {
      text: 'DELETE',
      color: 'red',
      onClick: this.handleDelete,
    };

    return (
      <Modal
        active={true}
        headerText={
          editMode === 'edit' ? `Edit workplace ${initialValues.name || initialValues.id}` : 'Create new workplace'
        }
        submitText='Submit'
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        extraActions={(this.state.currentValue || {}).deleteField === 'DELETE' ? [deleteAction] : []}
      >
        <Form>
          <SweetForm
            onChange={this.handleChange}
            initialValues={
              initialValues
                ? {
                    ...initialValues,
                    stackLabeled: (initialValues.stackLabeled || []).join(';'),
                    industriesLabeled: (initialValues.industriesLabeled || []).join(';'),
                    companyTypes: (initialValues.companyTypes || []).join(';'),
                  }
                : undefined
            }
          >
            <Grid columns={2}>
              <Grid.Column>
                {editMode === 'create' ? (
                  <div>
                    <Form.Field>
                      <label>Id</label>
                      <Clearable
                        component={Input}
                        field='id'
                        innerProps={{ placeholder: 'workplace-id' }}
                        nullLabel='Auto'
                      />
                    </Form.Field>
                  </div>
                ) : null}
                <Form.Field>
                  <label>Name</label>
                  <Input autoFocus field='name' />
                </Form.Field>
                <Form.Field>
                  <label>Type</label>
                  <SelectType field='type' />
                </Form.Field>
                {currentValue && currentValue.type === 'school' && (
                  <Form.Field>
                    <label>School Type</label>
                    <SelectSchoolType field='schoolType' />
                  </Form.Field>
                )}
                {currentValue && currentValue.type === 'company' && (
                  <Form.Field>
                    <label>Company Types</label>
                    <Select
                      multi
                      field='companyTypes'
                      value
                      options={COMPANY_TYPES_OPTIONS}
                      labelKey='name'
                      valueKey='id'
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <label>Industries</label>
                  <Select multi field='industriesLabeled' value options={industries} labelKey='name' valueKey='id' />
                </Form.Field>
                <Form.Field>
                  <label>Stack</label>
                  <Select multi field='stackLabeled' async loadOptions={loadSkills} labelKey='name' valueKey='id' />
                </Form.Field>
                <Form.Field>
                  <label>Creation date</label>
                  <Input field='creationDate' type='date' placeholder='yyyy-mm-dd' />
                </Form.Field>
                <Form.Field>
                  <label>Website</label>
                  <Input field='website' type='text' placeholder='https://...' />
                </Form.Field>
                <Form.Field>
                  <label>Crunchbase page</label>
                  <Input field='crunchbase' type='text' placeholder='https://...' />
                </Form.Field>
                <Form.Field>
                  <label>Linkedin page</label>
                  <Input field='linkedin' type='text' placeholder='https://...' />
                </Form.Field>
                <Form.Field>
                  <label>Related linkedin pages</label>
                  <List field='relatedLinkedinPages' component={Input} />
                </Form.Field>
                <Form.Field>
                  <label>Sales</label>
                  <List field='sales' component={SelectUsers} />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Station Client</label>
                  <Select async field='platformId' loadOptions={getClients} labelKey='name' valueKey='id' />
                </Form.Field>
                <Form.Field>
                  <label>Pricing</label>
                  <List field='princingUpdates' component={SelectPricingAndDate} />
                </Form.Field>
                <Form.Field>
                  <label>Related station clients</label>
                  <List field='relatedPlatformIds' component={SelectStationClient} />
                </Form.Field>
                <Form.Field>
                  <label>Staff count range</label>
                  <Range field='staffCountRange' min={0} max={10000} step={10} />
                </Form.Field>
                <Form.Field>
                  <label>Location</label>
                  <Input field='location' />
                </Form.Field>
                <Form.Field>
                  <label>Status</label>
                  <SelectStatus field='status' />
                </Form.Field>
                <Form.Field>
                  <label>Prestige</label>
                  <SelectPrestige field='prestige' />
                </Form.Field>
                <Form.Field>
                  <label>Network Level</label>
                  <SelectNetworkLevel field='networkLevel' />
                </Form.Field>
                <Form.Field>
                  <label>Admin #Tags</label>
                  <Input field='adminTagsText' placeholder='A #gfc #tech company' />
                  <CollapsibleList title={'Existing tags'} elements={adminTags} />
                </Form.Field>
                <Form.Field>
                  <label>Grade</label>
                  <Select field='grade' options={_.map(['A', 'B', 'C', 'D', 'E'], (g) => ({ label: g, value: g }))} />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Form.Field>
              <label>Blurb</label>
              <Textarea field='shortDescription' />
            </Form.Field>
            <Form.Field>
              <label>Description (old)</label>
              <Textarea field='description' />
            </Form.Field>
            {currentValue && currentValue.type === 'school' && (
              <EducationFieldPrestigeEditor field='educationFieldPrestige' fieldIds={sortedFieldIds} />
            )}
            {editMode === 'edit' && (
              <Form.Field>
                <label>Write "DELETE" to enable the delete button</label>
                <Input field='deleteField' />
              </Form.Field>
            )}
          </SweetForm>
        </Form>
      </Modal>
    );
  }
}
export default EditWorkplaceModal;
