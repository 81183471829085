import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Grid,
  Divider,
  Feed,
  Icon,
  Button,
  Statistic,
  Form,
} from 'semantic-ui-react';
import { Select, SweetForm, enhance } from 'sweetform';
import baseUrl from './baseUrl.js';

const ACTION_TYPES = [
  { value: 'updateOffer', label: 'Create/Update offer' },
  { value: 'createWorkPipe', label: 'Create WorkPipe' },
  { value: 'updateWorkPipe', label: 'Update WorkPipe' },
  { value: 'deleteWorkPipe', label: 'Delete WorkPipe' },
  { value: 'addSearchPipe', label: 'Add Search Pipe' },
  { value: 'addWorkPipeItem', label: 'Add WorkPipe Item' },
];

const getChunk = (action, daily) =>
  action ? moment(action.date).format(daily ? 'YYYYMMDD' : 'YYYYMMDDHH') : '';

// Actions
const ADD_ACTIONS = 'ADD_ACTIONS';
const addActions = (actions, daily = false, reset = false) => ({
  type: ADD_ACTIONS,
  actions,
  reset,
  daily,
});

const SET_PARAMS = 'SET_PARAMS';
const setParams = (params) => ({ type: SET_PARAMS, params });

const SET_USERS = 'SET_USERS';
const setUsers = (users) => ({ type: SET_USERS, users });

const SET_STATS = 'SET_STATS';
const setStats = (stats) => ({ type: SET_STATS, stats });

const SET_OFFERS = 'SET_OFFERS';
const setOffers = (offers) => ({ type: SET_OFFERS, offers });

const SET_WORKPIPES = 'SET_WORKPIPES';
const setWorkPipes = (workPipes) => ({ type: SET_WORKPIPES, workPipes });

// Reducers
const actions = (state = [], action) => {
  switch (action.type) {
    case ADD_ACTIONS:
      const currentState = action.reset ? [] : state;

      return _.reduce(
        action.actions,
        (memo, act) => {
          const previousChunks = memo.slice(0, memo.length - 1);
          const lastChunk = _.last(memo);
          if (!lastChunk) {
            // First action to be inserted
            return [[act]];
          }

          const lastAction = _.last(lastChunk);
          return getChunk(lastAction, action.daily) ===
            getChunk(act, action.daily)
            ? [...previousChunks, [...lastChunk, act]]
            : [...previousChunks, lastChunk, [act]];
        },
        currentState,
      );
    default:
      return state;
  }
};

const lastDate = (state = 0, action) => {
  switch (action.type) {
    case ADD_ACTIONS:
      const last = _.last(action.actions);
      return last ? +moment(last.date) : state;
    default:
      return state;
  }
};

const params = (state = {}, action) => {
  switch (action.type) {
    case SET_PARAMS:
      return action.params;
    default:
      return state;
  }
};

const users = (state = [], action) => {
  switch (action.type) {
    case SET_USERS:
      return action.users;
    default:
      return state;
  }
};

const stats = (state = {}, action) => {
  switch (action.type) {
    case SET_STATS:
      return action.stats;
    default:
      return state;
  }
};

const offers = (state = [], action) => {
  switch (action.type) {
    case SET_OFFERS:
      return action.offers;
    default:
      return state;
  }
};

const indexedOffers = (state = {}, action) => {
  switch (action.type) {
    case SET_OFFERS:
      return _.mapObject(
        _.indexBy(action.offers, 'value'),
        ({ label }) => label,
      );
    default:
      return state;
  }
};

const workPipes = (state = [], action) => {
  switch (action.type) {
    case SET_WORKPIPES:
      return action.workPipes;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  actions,
  params,
  lastDate,
  users,
  stats,
  offers,
  indexedOffers,
  workPipes,
});

// Store
const store = createStore(rootReducer);

// Components
const WorkPipeStats = ({ stats }) => (
  <Statistic.Group widths={4} style={{ marginTop: 40 }}>
    <Statistic color="green" label="Selected" value={stats.selected || 0} />
    <Statistic color="teal" label="Backlog" value={stats.backlog || 0} />
    <Statistic
      color="red"
      label="Disqualified"
      value={stats.disqualified || 0}
    />
    <Statistic size="huge" label="Total" value={stats.addWorkPipeItem || 0} />
  </Statistic.Group>
);
const Action = ({ action, indexedOffers }) => {
  const { username, type, body, params } = action;
  switch (type) {
    case 'updateOffer':
      return (
        <span>
          <Icon name="pencil" />
          {username} updated offer{' '}
          <Link to={`/offers/${body.id}`}>{body.title}</Link>
        </span>
      );
    case 'createWorkPipe':
      return (
        <span>
          <Icon name="add" />
          {username} created workPipe{' '}
          <Link to={`/offers/${body.jobOfferId}/workpipe/${body.id}`}>
            {body.title}
          </Link>
        </span>
      );
    case 'updateWorkPipe':
      return (
        <span>
          <Icon name="pencil" />
          {username} updated workPipe {params.id}
        </span>
      );
    case 'deleteWorkPipe':
      return (
        <span>
          <Icon name="trash" />
          {username} removed workPipe {params.id}
        </span>
      );
    case 'addSearchPipe':
      return (
        <span>
          <Icon name="add" />
          {username} created search {body.baseId}
        </span>
      );
    case 'createWorkPipeInput':
      return (
        <span>
          <Icon name="add" />
          {username} created workPipe input {body.title}
        </span>
      );
    case 'addWorkPipeItem':
      const { offer, workPipe, profile, state } = action.body;
      const workPipeUrl = `/offers/${offer.id}/workpipe/${workPipe.id}`;
      return (
        <span>
          <Icon
            name={
              state === 'selected'
                ? 'smile'
                : state === 'backlog'
                ? 'meh'
                : 'frown'
            }
          />
          {username} {state}{' '}
          <Link to={`${workPipeUrl}/items`}>{profile.fullname}</Link> on
          workPipe&nbsp;
          <Link to={workPipeUrl}>
            {indexedOffers[offer.id] || '?'} - {workPipe.title}
          </Link>
        </span>
      );
    case 'runTargetDefinition':
      return (
        <span>
          {username} applied target definition {params.id}
        </span>
      );
    case 'dropTargetCollection':
      return (
        <span>
          {username} droped collection {params.collection}
        </span>
      );
    default:
      return (
        <span>
          {action.username} {action.type}
        </span>
      );
  }
};

const PeriodSelector = enhance(({ setValue, value }) => (
  <Button.Group fluid>
    <Button active={value === 'daily'} onClick={() => setValue('daily')}>
      Daily
    </Button>
    <Button active={value === 'weekly'} onClick={() => setValue('weekly')}>
      Weekly
    </Button>
    <Button active={value === 'monthly'} onClick={() => setValue('monthly')}>
      Monthly
    </Button>
  </Button.Group>
));

class Actions extends Component {
  componentDidMount() {
    const q = this.initialParams();
    if (_.size(q) > 0) {
      this.props.onSetParams({}, q);
    }

    this.props.onLoad(0, q, true);
    this.props.onLoadStats(q);
    this.props.onLoadUsers();
    this.props.onLoadOffers();
  }

  initialParams() {
    return this.props.match.params.offerId
      ? { offerId: this.props.match.params.offerId }
      : {};
  }

  render() {
    const {
      actions,
      lastDate,
      params,
      users,
      stats,
      offers,
      indexedOffers,
      workPipes,
      onLoad,
      onLoadStats,
      onSetParams,
    } = this.props;

    return (
      <Grid columns={2}>
        <Grid.Column>
          <SweetForm
            onChange={(newParams) => onSetParams(params, newParams)}
            initialValues={this.initialParams()}
          >
            <Form>
              <Grid columns={2}>
                <Grid.Column>
                  <Form.Field>
                    <label>Username</label>
                    <Select
                      field="username"
                      options={users}
                      labelKey="name"
                      valueKey="id"
                      placeholder="(all)"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Offer</label>
                    <Select
                      field="offerId"
                      options={offers}
                      placeholder="(all)"
                    />
                  </Form.Field>
                  <PeriodSelector field="period" defaultValue="daily" />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Action type</label>
                    <Select
                      field="type"
                      options={ACTION_TYPES}
                      placeholder="(all)"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>WorkPipe</label>
                    <Select
                      field="workPipeId"
                      options={workPipes}
                      placeholder="(all)"
                    />
                  </Form.Field>
                  <Button
                    fluid
                    color="green"
                    onClick={() => {
                      onLoad(0, params, true);
                      onLoadStats(params);
                    }}
                  >
                    Filter
                  </Button>
                </Grid.Column>
              </Grid>
              <WorkPipeStats stats={stats} />
            </Form>
          </SweetForm>
        </Grid.Column>

        <Grid.Column>
          {_.map(actions, (chunk, i) => (
            <div key={i}>
              <Divider horizontal>
                {moment(chunk[0].date)
                  .startOf('hour')
                  .calendar()}
              </Divider>
              <Feed size="small">
                {_.map(chunk, (action, j) => (
                  <Feed.Event key={j}>
                    <Feed.Content>
                      <Feed.Summary>
                        <Action action={action} indexedOffers={indexedOffers} />
                        <Feed.Date title={moment(action.date).format('L LTS')}>
                          {moment(action.date).fromNow()}
                        </Feed.Date>
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                ))}
              </Feed>
            </div>
          ))}
          <Button
            fluid
            style={{ marginTop: 20 }}
            onClick={() => onLoad(lastDate, params)}
          >
            Load more
          </Button>
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers
const mapSActions = (state) => ({
  actions: state.actions,
  lastDate: state.lastDate,
  params: state.params,
  users: state.users,
  stats: state.stats,
  offers: state.offers,
  indexedOffers: state.indexedOffers,
  workPipes: state.workPipes,
});

const mapDActions = (dispatch) => ({
  onLoad: async (lastDate, params = {}, reset = false) => {
    const query = {
      ...params,
      to: lastDate,
    };
    const results = (await axios.get(`${baseUrl}/actions`, { params: query }))
      .data;
    const daily = params.period && params.period !== 'daily';
    dispatch(addActions(results, daily, reset));
  },
  onLoadUsers: async () => {
    const results = (await axios.get(`${baseUrl}/users`)).data;
    dispatch(setUsers(results));
  },
  onLoadStats: async (params) => {
    const results = (await axios.get(`${baseUrl}/actions/stats`, { params }))
      .data;
    dispatch(setStats(results));
  },
  onLoadOffers: async () => {
    const results = (await axios.get(`${baseUrl}/offers/list/hiresweet`)).data;
    dispatch(
      setOffers(
        _.map(results, ({ id, title, companyId }) => ({
          label: `${companyId} - ${title}`,
          value: id,
        })),
      ),
    );
  },
  onSetParams: async (oldParams, newParams) => {
    dispatch(setParams(newParams));

    if (oldParams.offerId !== newParams.offerId) {
      const workPipes = newParams.offerId
        ? (await axios.get(
            `${baseUrl}/sweetwork/workPipesOfJobOffer/${newParams.offerId}`,
          )).data
        : [];
      dispatch(
        setWorkPipes(
          _.map(workPipes, ({ id, title }) => ({
            value: id,
            label: `${title} (${newParams.offerId})`,
          })),
        ),
      );
    }
  },
});

const ActionsContainer = connect(
  mapSActions,
  mapDActions,
)(Actions);

export default ({ match }) => (
  <Provider store={store}>
    <ActionsContainer match={match} />
  </Provider>
);
export { WorkPipeStats };
