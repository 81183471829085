import _ from 'underscore';
import baseUrl from '../../baseUrl';
import axios from 'axios';
import { getIdFieldsFromSources } from './utils';
import React, { Component } from 'react';
import { Grid, Button, Divider, Header, Icon, List } from 'semantic-ui-react';
import { SweetForm, Checkbox, enhance, List as SweetList, Input } from 'sweetform';

const formAccountToSheetAccount = (account) => {
  const { onboardingFinished, contactPreferencesActive, phoneNumber } = account || {};
  return {
    ...(_.isBoolean(onboardingFinished) && { onboarding: { finished: onboardingFinished } }),
    ...(_.isBoolean(contactPreferencesActive) && { contactPreferences: { active: contactPreferencesActive } }),
    phoneNumber,
  };
};

const sheetAccountToFormAccount = (account) => {
  const { onboarding, contactPreferences, phoneNumber } = account || {};
  return {
    onboardingFinished: (onboarding || {}).finished,
    contactPreferencesActive: (contactPreferences || {}).active,
    phoneNumber,
  };
};

class WatchEditAccount extends Component {
  state = {
    emails: null,
  };

  componentWillMount() {
    this.loadEmails();
  }

  loadEmails = async () => {
    const { sources, account } = this.props;
    console.log('sources', sources);
    const idFields = getIdFieldsFromSources(sources);
    const dataEmails = (await axios.post(`${baseUrl}/profiles/getEmailsFromIdFields`, { idFields })).data;
    if (!dataEmails || dataEmails.error) {
      this.setState({ emails: [] });
    } else {
      const confirmedAppEmails = _.pluck(_.filter((account || {}).emails, { confirmed: true }), 'address');
      const notConfirmedAppEmails = _.pluck(_.filter((account || {}).emails, { confirmed: false }), 'address');

      let { emails } = dataEmails;
      emails.confirmedAppEmails = confirmedAppEmails;
      emails.notConfirmedAppEmails = notConfirmedAppEmails;
      this.setState({ emails });
    }
  };

  handleChangeAccount = (value) => {
    this.setState({ value });
  };

  handleChangeEmail = ({ email }) => {
    this.setState({ email });
  };

  handleSaveAccount = async () => {
    try {
      await this.props.onSubmitAccount(formAccountToSheetAccount(this.state.value));
      alert('Saved.');
    } catch (e) {
      alert(e.message);
    }
  };

  handleSaveEmail = async () => {
    try {
      await this.props.onSubmitEmail(this.state.email);
      alert('Saved.');
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    const { account } = this.props;
    const { emails } = this.state;

    const formattedAccount = sheetAccountToFormAccount(account);
    const email = (_.findWhere((account || {}).emails, { selected: true }) || {}).address;
    return (
      <div>
        <Divider horizontal>
          <Header as='h4'>
            <Icon name='mail' />
            Email
          </Header>
        </Divider>
        <List>
          {_.map(emails, (sourceEmails, source) => {
            return (
              <div>
                {_.map(sourceEmails, (mail) => (
                  <List.Item>
                    {mail} ({source})
                  </List.Item>
                ))}
              </div>
            );
          })}
          <List.Item />
        </List>

        <SweetForm initialValues={{ email }} onChange={this.handleChangeEmail}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={6}>
                <Input field='email' />
              </Grid.Column>
              <Grid.Column width={4}>
                <Button onClick={() => this.handleSaveEmail()} color='green'>
                  <Icon name='save' /> Save Email
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SweetForm>

        <Divider horizontal>
          <Header as='h4'>
            <Icon name='info' />
            Other
          </Header>
        </Divider>
        <Button onClick={() => this.handleSaveAccount()} color='green'>
          <Icon name='save' /> Save
        </Button>
        <SweetForm initialValues={formattedAccount} onChange={this.handleChangeAccount}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Contact Preferences Active ?</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Checkbox field='contactPreferencesActive' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Onboarding Finished ?</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Checkbox field='onboardingFinished' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Phone Number</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='phoneNumber' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SweetForm>
      </div>
    );
  }
}

export default WatchEditAccount;
