import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Table, Icon, Label, Grid, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { STATUS_TYPES_PARAMS } from '../Candidate/WatchStatus';
import WatchStatus from '../Candidate/WatchStatus';
import { GRADES } from '../Candidate/utils';
import { Input, Select, List, SweetForm } from 'sweetform';
import { loadJobTitles, loadSkills, loadLocations, toFormatedOptions } from '../Candidate/WatchHiresweetData';

const CollectInfo = ({collectInfo}) => {
  const {message, label, color} = collectInfo
  return <Popup content={message} trigger={<Label color={color}>{label}</Label>} />
}

const filterCandidates = (candidates, selectedCategory, candidateFilters) => {

  const okTargetItems = (okTarget) => [...((okTarget || {}).target || []), ...((okTarget || {}).okWith || [])]

  return _.filter(candidates, (candidate) => {
    if (selectedCategory && selectedCategory !== 'all') {
      if (!selectedCategory === candidate.category){
        return false
      }
    }
    const { jobs, skills, locations } = candidateFilters;
    const wishes = ((((candidate.data || {}).sourceData || {}).hiresweet || {}).wishes || {});
    const candidateJobs = _.pluck(okTargetItems(wishes.jobs), 'id')
    if (jobs){
      if(_.isEmpty(_.intersection(candidateJobs, jobs.split(';')))){
        return false
      }
    }
    const candidateSkills = _.pluck(okTargetItems(wishes.skills), 'id')
    if (skills){
      if(_.isEmpty(_.intersection(candidateSkills, skills.split(";")))){
        return false
      }
    }
    const candidatLocations = _.pluck(_.pluck(okTargetItems(wishes.locations), 'location'), 'id');
    if (locations){
      if(_.isEmpty(_.intersection(candidatLocations, locations.split(';')))){
        return false
      }
    }
    return true;
  });
}

const CATEGORIES = [{ id: 'all', name: 'All' }].concat(STATUS_TYPES_PARAMS);

class CandidatesOverview extends Component {
  state = {
    selectedCategory: 'all',
    candidateFilters: {},
    appliedCandidateFilters: {}
  };
  getItemColor(profileCategory) {
    const categoryToColor = _.indexBy(CATEGORIES, 'id');
    return (categoryToColor[profileCategory] || {}).color || '#ffffdd';
  }

  handleChangeCandidateFilters = (candidateFilters) => {
    this.setState({candidateFilters})
  }

  handleApplyCandidateFilters = () => {
    this.setState({appliedCandidateFilters: this.state.candidateFilters})
  }

  render() {
    const { candidates } = this.props;
    const { selectedCategory, appliedCandidateFilters } = this.state;
    if (!candidates) {
      return <div>LOADING</div>;
    }
    const filteredCandidates = filterCandidates(candidates, selectedCategory, appliedCandidateFilters)
    const sortedCandidates = _.sortBy(filteredCandidates, ({ creationTimestamp }) => -creationTimestamp);
    const categoryCount = _.mapObject(
      _.groupBy(candidates, (candidate) => candidate.category),
      (categoryCandidates) => categoryCandidates.length,
    );
    categoryCount['all'] = candidates.length;
    return (
      <div>
        <div>
          {_.map(CATEGORIES, (category) => {
            return (
              <Button
                color={selectedCategory === category.id ? 'green' : null}
                onClick={() => {
                  this.setState({ selectedCategory: category.id });
                }}
              >
                {category.name} ({categoryCount[category.id] || 0})
              </Button>
            );
          })}
        </div>
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign='center'>Grade</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Collect</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Id</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Linkedin</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>User</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Description Reviewed</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Creation</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Source</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(sortedCandidates, (candidate, i) => {
              if (_.isEmpty(candidate)) {
                return <Table.Row />;
              }
              const { sources, onPerformStatusAction } = this.props;
              const { id, fullname, status, creationDate, collectInfo, admin, category, linkedinId } = candidate;
              const sourceId = (admin || {}).source;
              const sourceIdToName = _.mapObject(_.indexBy(sources, 'id'), ({ internalName }) => internalName);
              const sourceName = sourceIdToName[sourceId];
              const grade = (admin || {}).grade;
              const gradeColor = (_.find(GRADES, ({ value }) => value === grade) || {}).color;
              const linkedinUrl = `https://linkedin.com/in/${linkedinId}`
              return (
                <Table.Row key={i} style={{ backgroundColor: this.getItemColor(category) }}>
                  <Table.Cell textAlign='center'>
                    <Icon
                      name='write'
                      size='large'
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.props.onOpenEditCandidate(candidate)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    {grade && (
                      <Label circular color={gradeColor}>
                        {grade}
                      </Label>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    {status && (
                      <WatchStatus
                        status={status}
                        onPerformAction={(actionId) => onPerformStatusAction({ id, actionId })}
                        mini={true}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    {collectInfo && (
                      <CollectInfo collectInfo={collectInfo} />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>{id}</Table.Cell>

                  <Table.Cell textAlign='center'>{fullname}</Table.Cell>
                  <Table.Cell textAlign='center'><a href={linkedinUrl} target={'__blank'}>{linkedinUrl}</a></Table.Cell>
                  <Table.Cell textAlign='center'>{admin && admin.users && admin.users.join(', ')}</Table.Cell>
                  <Table.Cell textAlign='center'>{(admin && admin.descriptionReviewed) ? 'YES' : 'NO'}</Table.Cell>
                  <Table.Cell textAlign='center'>{moment(creationDate).format('DD-MM-YYYY')}</Table.Cell>
                  <Table.Cell textAlign='center'>{sourceName}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default CandidatesOverview;
