import React from 'react';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import _ from 'underscore';

import { Button, Card, Form, Grid, Label, Table } from 'semantic-ui-react';
import { Input, List, SweetForm } from 'sweetform';

class JobRecognition extends React.Component {
  handleTextChange = (value) => {
    this.setState({ textValue: value, resultComputed: false });
  };

  run = async () => {
    this.setState({ isRunning: true });
    const { textValue, targetLanguages } = this.state || {};
    const targetLanguageIds = _.filter(targetLanguages || [], (targetLanguage) => !_.isEmpty(targetLanguage.id));
    if (_.isEmpty(targetLanguageIds)) {
      alert('You should add one target language.');
    }
    const params = {
      text: textValue,
      targetLanguages,
      types: ['job'],
    };
    const url = baseUrl + '/recognition/recognizeFromText';
    const result = (await axios.post(url, params)).data;
    const { recognizedSkill } = result || {};
    const { suggestions } = result || {};
    this.setState({ recognizedSkill, suggestions, isRunning: false, resultComputed: true });
  };

  handleChangeLanguages = (params) => {
    const targetLanguages = _.map((params || {}).targetLanguages, (language) => ({ id: language }));
    this.setState({ targetLanguages });
  };

  renderCell = (name, key, suggestion) => {
    return (
      <Table.Cell key={key}>
        {name == 'Id'
          ? suggestion.id
          : name == 'Name'
          ? suggestion.name
          : name == 'Confidence'
          ? suggestion.confidence
          : name == 'Translatable'
          ? suggestion.translatable
          : name == 'Recognition Type'
          ? suggestion.recognitionType
          : null}
      </Table.Cell>
    );
  };

  render() {
    const { textValue, isRunning, recognizedSkill, suggestions, resultComputed } = this.state || {};
    const runButtonColor = isRunning ? 'grey' : 'green';
    const columnNames = ['Id', 'Name', 'Confidence', 'Translatable', 'Recognition Type'];
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <h3>Text</h3>
            <Form.TextArea
              style={{
                width: '100%',
                height: '35px',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
              value={textValue}
              rows={20}
              onChange={(e, { value }) => {
                this.handleTextChange(value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={2} />
          <Grid.Column width={6}>
            <h3>Target Languages</h3>
            <SweetForm onChange={(params) => this.handleChangeLanguages(params)}>
              <Form.Field>
                <List field='targetLanguages' component={Input} />
              </Form.Field>
            </SweetForm>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Button onClick={() => this.run()} color={runButtonColor}>
            Run
          </Button>
        </Grid.Row>
        <Grid.Row>
          {resultComputed ? (
            <Card
              style={{
                width: '100%',
              }}
            >
              <Card.Content>
                <Card.Header>
                  <h2> Results </h2>
                </Card.Header>
                <br />
                <Card.Description>
                  {recognizedSkill || suggestions ? (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          {recognizedSkill ? (
                            <div>
                              <h3>Recognized Job</h3>
                              <Label>id:</Label> {recognizedSkill.id}
                              <br />
                              <Label>name:</Label> {recognizedSkill.name}
                              <br />
                              <Label>confidence:</Label> {recognizedSkill.confidence}
                            </div>
                          ) : null}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          {!_.isUndefined(suggestions) && !_.isEmpty(suggestions) ? (
                            <div>
                              <h3>Suggestions</h3>
                              <Table>
                                <Table.Header>
                                  <Table.HeaderCell>Id</Table.HeaderCell>
                                  <Table.HeaderCell>Name</Table.HeaderCell>
                                  <Table.HeaderCell>Confidence</Table.HeaderCell>
                                  <Table.HeaderCell>Translatable</Table.HeaderCell>
                                  <Table.HeaderCell>Recognition Type</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                  {(suggestions || []).map((suggestion, key) => {
                                    return (
                                      <Table.Row key={key}>
                                        {columnNames.map((name, index2) => {
                                          return this.renderCell(name, index2 + key, suggestion);
                                        })}
                                      </Table.Row>
                                    );
                                  })}
                                </Table.Body>
                              </Table>
                            </div>
                          ) : null}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : (
                    'No job found.'
                  )}
                </Card.Description>
              </Card.Content>
            </Card>
          ) : null}
        </Grid.Row>
      </Grid>
    );
  }
}

export default JobRecognition;
