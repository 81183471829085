import React from 'react';
import { Feed } from 'semantic-ui-react';

export const Education = ({ edu }) => (
  <Feed.Event>
    <Feed.Label icon="map pin" />
    <Feed.Content
      meta={`${edu.degree} · ${(edu.rawDates || []).join(' - ')}`}
      summary={edu.schoolName}
    />
  </Feed.Event>
);
