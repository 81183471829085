import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Table, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

const getDays = () => {
  const date = new Date();
  const lastMidnightTimestamp = date.setHours(3, 0, 0, 0);
  let days = [];
  let nbDays = 200;
  const iWeekDay = date.getDay();
  for (let iDay = 0; iDay < nbDays; iDay++) {
    const timestamp = lastMidnightTimestamp - iDay * 24 * 3600 * 1000;
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    days.push({
      timestamp,
      date: new Date(timestamp).toISOString().slice(2, 10),
      weekDay: weekDays[(iWeekDay - (iDay % 7) + 7) % 7],
    });
  }
  return days;
};

// const getNbWorkingDays = ({ days, minDate, maxDate }) => {
//   let result = 0;
//   _.each(days, ({ date, weekDay }) => {
//     if (date >= minDate && date <= maxDate && weekDay !== 'Sun' && weekDay !== 'Sat') {
//       result++;
//     }
//   });
//   return result;
// }

class SalesDailySends extends Component {
  days = getDays(200);
  state = {
    minDay: this.days[30],
    maxDay: this.days[0],
    offers: [],
    total: { byDays: {} },
  };
  componentWillMount() {
    this.handleReloadQuery();
  }
  handleReloadQuery = async () => {
    const { data } = await axios.get(`${baseUrl}/strat/sends/salesstats`);
    console.log(data);
    this.setState(data);
  };
  renderOverview() {
    const { minDay, maxDay, offers } = this.state;
    if (!offers || !offers.length) {
      return;
    }

    const targetDates = [];
    _.each(this.days, (day) => {
      if (day.date >= minDay.date && day.date <= maxDay.date) {
        targetDates.push(day.date);
      }
    });
    targetDates.reverse();

    const getStats = (targetOffers) => {
      const nbSendsByDay = {};
      _.each(targetOffers, (targetOffer) => {
        _.each(targetDates, (date) => {
          nbSendsByDay[date] =
            (nbSendsByDay[date] || 0) +
            ((targetOffer.byDays[date] || {}).nbSent || 0);
        });
      });

      return {
        sends: _.map(targetDates, (date) => nbSendsByDay[date]),
      };
    };
    const salesOwners = _.compact(_.uniq(_.pluck(offers, 'salesOwner')));
    let salesStats = [];
    _.each(salesOwners, (salesOwner) => {
      salesStats.push({
        salesOwner,
        ...getStats(
          _.filter(offers, (offer) => offer.salesOwner === salesOwner),
        ),
      });
    });
    salesStats = _.sortBy(salesStats, ({ salesOwner, sends }) => {
      const nbSends = _.reduce(sends, (memo, x) => memo + x, 0);
      return salesOwner === 'undefined' ? 1 : -nbSends;
    });

    const totalStats = getStats(offers);
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Overview (from {minDay.date} morning to {maxDay.date} evening)
          </Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              {_.map(targetDates, (date) => (
                <Table.HeaderCell key={date}>{date.slice(3)}</Table.HeaderCell>
              ))}
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>total</Table.Cell>
                {_.map(totalStats.sends, (nbSends, index) => (
                  <Table.Cell key={index}>{nbSends}</Table.Cell>
                ))}
              </Table.Row>
              {_.map(salesStats, ({ salesOwner, sends }, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{salesOwner}</Table.Cell>
                  {_.map(sends, (nbSends, index) => (
                    <Table.Cell key={index}>{nbSends}</Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  renderSalesOwnerOffers(salesOwner) {
    const { minDay, maxDay, offers } = this.state;
    if (!offers || !offers.length) {
      return;
    }
    const targetDates = [];
    _.each(this.days, (day) => {
      if (day.date >= minDay.date && day.date <= maxDay.date) {
        targetDates.push(day.date);
      }
    });
    targetDates.reverse();

    const salesOffers = _.where(offers, { salesOwner });
    const enrichedOffers = _.sortBy(
      _.map(salesOffers, (offer) => {
        const sends = [];
        let maxSendDate = '';
        let minSendDate = '';
        _.each(targetDates, (date) => {
          const nbSends = (offer.byDays[date] || {}).nbSent || 0;
          if (nbSends > 0) {
            maxSendDate = date;
            if (!minSendDate) {
              minSendDate = date;
            }
          }
          sends.push({ count: nbSends });
        });
        _.each(targetDates, (date, index) => {
          if (date > maxSendDate) {
            sends[index].noMoreAfter = true;
          }
          if (date < minSendDate) {
            sends[index].noOneBefore = true;
          }
        });

        return {
          ...offer,
          maxSendDate,
          sends,
        };
      }),
      'maxSendDate',
    ).reverse();

    const capitalize = (str) => {
      if (str.length < 1) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.substr(1);
    };

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{capitalize(salesOwner)}</Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              {_.map(targetDates, (date) => (
                <Table.HeaderCell key={date}>{date.slice(3)}</Table.HeaderCell>
              ))}
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              {_.map(
                enrichedOffers,
                ({ clientId, title, salesOwner, sends }, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {clientId} - {title}
                    </Table.Cell>
                    {_.map(sends, ({ count, noMoreAfter, noOneBefore }, index) => (
                      <Table.Cell
                        style={{
                          ...(noMoreAfter && { background: '#FFE4E1' }),
                          ...(noOneBefore && { background: 'whitesmoke' }),
                          ...(count > 0 && { background: '#F0FFF0' }),
                        }}
                        key={index}
                      >
                        {count}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    const offers = this.state.offers || [];
    const salesOwners = _.sortBy(
      _.compact(_.uniq(_.pluck(offers, 'salesOwner'))),
      (salesOwner) => (salesOwner !== 'undefined' ? salesOwner : 'zzz'),
    );

    return (
      <div>
        {this.renderOverview()}
        {_.map(salesOwners, (salesOwner, index) => (
          <div key={index} style={{ marginTop: 20, marginBottom: 20 }}>
            {this.renderSalesOwnerOffers(salesOwner)}
          </div>
        ))}
      </div>
    );
  }
}

export default SalesDailySends;
