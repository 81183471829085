import React from 'react';
import _ from 'underscore';
import axios from 'axios';

import baseUrl from '../../baseUrl.js';
import { Button, Form, Grid, Tab } from 'semantic-ui-react';

import FindSkillEntities from './FindSkillEntities.js';
import FindSkillPatterns from './FindSkillPatterns.js';
import CreateSkillExtenderSystemModal from './CreateSkillExtenderSystemModal.js';

class FindSkills extends React.Component {
  componentDidMount() {
    this.getSkillExtenderSystems();
  }

  getSkillExtenderSystems = async () => {
    const url = baseUrl + '/extendSkills/getSkillExtenderSystems';
    const skillExtenderSystems = (await axios.get(url)).data;
    this.setState({ skillExtenderSystems });
  };

  handleSelectSkillExtenderSystem = (selectedSkillExtenderSystemId) => {
    const { skillExtenderSystems } = this.state || {};
    const selectedSkillExtenderSystem = _.find(
      skillExtenderSystems,
      (skillExtenderSystem) => skillExtenderSystem.id === selectedSkillExtenderSystemId,
    );
    const dataStatus = this.getDataStatus(selectedSkillExtenderSystem);
    const selectedProfilesCollectionId = ((selectedSkillExtenderSystem || {}).profilesSetDescriptor || {})
      .profilesCollectionId;
    this.setState({ selectedSkillExtenderSystem, dataStatus, selectedProfilesCollectionId });
  };

  onClickSelectSkillExtenderSystem = (e, { value }) => {
    if (value === 'new-skill-extender-system') {
      this.setState({ createSkillExtenderSystemModalVisible: true });
      return;
    }
    this.handleSelectSkillExtenderSystem(value);
  };

  getDataStatus = (selectedSkillExtenderSystem) => {
    const dataStorage = (selectedSkillExtenderSystem || {}).dataStorage;
    const dataStatus =
      (dataStorage || {}).type === 'pickle'
        ? 'computed'
        : (dataStorage || {}).type === 'computing'
        ? 'computing'
        : undefined;
    return dataStatus;
  };

  computeEmbedding = async (selectedProfilesCollectionId) => {
    if (!_.isUndefined(selectedProfilesCollectionId)) {
      this.setState({ dataStatus: 'computing' });
      const params = { profilesCollectionId: selectedProfilesCollectionId };
      const url = baseUrl + '/extendSkills/computeEmbeddingFromProfilesCollection';
      const result = (await axios.post(url, params)).data;
      if (!result.success) {
        alert(result.message);
      }
    }
  };

  onCancelCreateSkillExtenderSystem = () => {
    this.setState({ createSkillExtenderSystemModalVisible: false });
  };

  onSubmitCreateSkillExtenderSystem = async (params) => {
    if (_.isUndefined(params.name) || _.isEmpty(params.name)) {
      alert('name should be defined');
      return;
    }
    if (_.isUndefined(params.profilesCollectionId) || _.isEmpty(params.profilesCollectionId)) {
      alert('profilesCollectionId should be defined');
      return;
    }
    const id = params.name
      .replaceAll(' ', '_')
      .replaceAll('/', '_')
      .toLowerCase();
    const payload = {
      ...params,
      id,
    };
    const url = baseUrl + '/extendSkills/createSkillExtenderSystem';
    const result = (await axios.post(url, payload)).data;
    if (!result.success) {
      alert(result.message);
      return;
    }
    await this.getSkillExtenderSystems();
    this.handleSelectSkillExtenderSystem(id);
    this.setState({ createSkillExtenderSystemModalVisible: false });
  };

  getComputeEmbeddingColor = (dataStatus) => {
    const color = dataStatus === 'computed' ? 'yellow' : dataStatus === 'computing' ? 'grey' : 'green';
    return color;
  };

  render() {
    const {
      createSkillExtenderSystemModalVisible,
      skillExtenderSystems,
      selectedSkillExtenderSystem,
      dataStatus,
      selectedProfilesCollectionId,
    } = this.state || {};

    const skillExtenderSystemOptions = _.map(skillExtenderSystems || [], (skillExtenderSystem, key) => ({
      value: skillExtenderSystem.id,
      key: key,
      text: skillExtenderSystem.name || skillExtenderSystem.id,
    })).concat([
      {
        value: 'new-skill-extender-system',
        key: 'new-skill-extender-system',
        text: '+ Create Skill Extender System',
      },
    ]);

    return (
      <div>
        {createSkillExtenderSystemModalVisible ? (
          <CreateSkillExtenderSystemModal
            skillExtenderSystems={skillExtenderSystems}
            onSubmit={this.onSubmitCreateSkillExtenderSystem}
            onCancel={this.onCancelCreateSkillExtenderSystem}
          />
        ) : null}
        <Grid>
          <Grid.Row>
            <Form.Select
              search
              placeholder='Profiles Collection Id'
              options={skillExtenderSystemOptions}
              onChange={this.onClickSelectSkillExtenderSystem}
              value={(selectedSkillExtenderSystem || {}).id}
            />
          </Grid.Row>
          {!_.isUndefined(selectedSkillExtenderSystem) ? (
            <div>
              <Grid.Row>
                <Button
                  onClick={() => this.computeEmbedding(selectedProfilesCollectionId)}
                  color={this.getComputeEmbeddingColor(dataStatus)}
                >
                  {dataStatus === 'computed'
                    ? 'Recompute Embedding'
                    : dataStatus === 'computing'
                    ? 'Computing'
                    : 'Compute Embedding'}
                </Button>
              </Grid.Row>
              <br />
              <br />
              <Grid.Row>
                <Grid.Column width={16}>
                  {dataStatus === 'computed' ? (
                    <Tab
                      panes={[
                        {
                          menuItem: 'Find Skill Entities',
                          render: () => (
                            <Tab.Pane>
                              <div key={'findSkillEntities'}>
                                <FindSkillEntities profilesCollectionId={selectedProfilesCollectionId} />
                              </div>
                            </Tab.Pane>
                          ),
                        },
                        {
                          menuItem: 'Find Skill Patterns',
                          render: () => (
                            <Tab.Pane>
                              <div key={'findSkillPattern'} />
                              <FindSkillPatterns profilesCollectionId={selectedProfilesCollectionId} />
                            </Tab.Pane>
                          ),
                        },
                      ]}
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </div>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default FindSkills;
