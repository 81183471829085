import React from 'react';
import i18n from 'i18next';
import { Popup } from 'semantic-ui-react';
import TextInput from './TextInput';


const getTranslatedText = (translatableText) => {
  const { language } = i18n;
  const languageWithoutCountry = language ? language.split('-')[0] : null;
  const text = translatableText || {};
  if(text.getDefault){
    if (languageWithoutCountry === 'fr') {
      return text.getFr().get() || text.getDefault().get() || ''
    } else if (languageWithoutCountry === 'en') {
      return text.getEn().get() || text.getDefault().get() || ''
    }
    return text.getDefault().get() || '';
  } else {
    return ''
  }
};

export const TranslatableText = ({ translatableText, placeholder }) => {
  return <span>{getTranslatedText(translatableText) || placeholder}</span>;
};

export const EditableTranslatableText = ({ translatableText, placeholder }) => (
  <Popup
    on='click'
    wide
    trigger={
      <span style={{cursor: 'pointer'}}>
        <TranslatableText translatableText={translatableText} placeholder={placeholder} />
      </span>
    }
    content={
      <div>
        <div style={{fontWeight: 'bold', fontSize: '1.2em'}}>Edit Translatable Text</div>
        <div>
          <span>Default: </span>
          <TextInput
            placeholder='Default'
            name='default'
            value={translatableText.getDefault().get() || ''}
            onChange={({ value }) => translatableText.getDefault().set(value)}
          />
        </div>
        <div>
          <span>English: </span>
          <TextInput
            placeholder='English'
            name='en'
            value={translatableText.getEn().get() || ''}
            onChange={({ value }) => translatableText.getEn().set(value)}
          />
        </div>
        <div>
          <span>French: </span>
          <TextInput
            placeholder='French'
            name='french'
            value={translatableText.getFr().get() || ''}
            onChange={({ value }) => translatableText.getFr().set(value)}
          />
        </div>
      </div>
    }
  />
);
