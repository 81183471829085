import React from 'react';
import _ from 'underscore';

import { Menu, Segment } from 'semantic-ui-react';

export default class Selector extends React.Component {
  state = { activeItem: 0 };
  handleClick = (e, { name }) => {
    this.setState({ activeItem: parseInt(name) });
    this.props.handleClick(name);
  };

  render() {
    return (
      <Segment>
        <Menu pointing secondary>
          {_.map(this.props.items, (item, index) => (
            <Menu.Item
              name={`${index}`}
              key={index}
              active={this.state.activeItem === index}
              onClick={this.handleClick}
            >
              {item.id}
            </Menu.Item>))}
        </Menu>
      </Segment>
    )
  }
}