import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { Label, Item, Icon, Popup } from 'semantic-ui-react';

import { transformEnrichmentByHint } from '../../Offers/enrichment';
import EnrichedText from '../../components/EnrichedText';
import ParsedLocations from '../../Monitoring/Locations/ParsedLocations';
import { getDateObjFromRaw } from './utils';
import { Workplace } from '../Workplace';

const LinkedinExperienceDescription = ({
  i,
  raw,
  details,
  enrichedExperience,
}) => {
  const style = details
    ? {}
    : {
      maxHeight: i < 2 ? '6em' : '3em',
      overflow: 'hidden',
    };
  return (
    <div style={style}>
      {enrichedExperience ? (
        <EnrichedText key="3" {...enrichedExperience} />
      ) : (
          <Item.Description key="1">{raw}</Item.Description>
        )}
    </div>
  );
};

export const Experience = ({ exp, i }) => {
  const currentMonth = new Date().getMonth();
  const enrichedExperience = exp.hint
    ? transformEnrichmentByHint(exp.hint, exp.enrichedExperience)
    : exp.enrichedExperience;
  const xpStartDate =
    ((exp || {}).rawDates || []).length > 0 &&
    getDateObjFromRaw(exp.rawDates[0]);
  const xpEndDate =
    ((exp || {}).rawDates || []).length > 1 &&
    getDateObjFromRaw(exp.rawDates[1]);
  const currentYear = new Date().getFullYear();
  const currentMomentDate = moment([currentYear, currentMonth, 1]);
  const xpStartMomentDate =
    (xpStartDate || {}).year &&
    moment([
      xpStartDate.year,
      _.isNumber(xpStartDate.month) ? xpStartDate.month : currentMonth,
      1,
    ]);
  const xpEndMomentDate =
    (xpEndDate || {}).year &&
    moment([
      xpEndDate.year,
      _.isNumber(xpEndDate.month) ? xpEndDate.month : currentMonth,
      1,
    ]);
  const xpDurationYears = xpEndMomentDate
    ? xpEndMomentDate.diff(xpStartMomentDate, 'years')
    : currentMomentDate.diff(xpStartMomentDate, 'years');
  const xpDurationMonths =
    _.isNumber(xpStartDate.month) && xpEndMomentDate
      ? xpEndMomentDate.diff(
        xpStartMomentDate.add(xpDurationYears, 'years'),
        'months',
      )
      : _.isNumber(xpStartDate.month) && !xpEndDate.year
        ? currentMomentDate.diff(
          xpStartMomentDate.add(xpDurationYears, 'years'),
          'months',
        )
        : 0;
  const xpDuration =
    (xpDurationYears > 1
      ? xpDurationYears + ' ans '
      : xpDurationYears
        ? xpDurationYears + ' an '
        : '') + (xpDurationMonths ? xpDurationMonths + ' mois' : '');
  const durationYearsSinceHasNoCurrentXp =
    i === 0 &&
    xpEndMomentDate &&
    xpEndDate &&
    currentMomentDate.diff(xpEndMomentDate, 'years');
  const durationMonthsSinceHasNoCurrentXp =
    i === 0 &&
    xpEndMomentDate &&
    xpEndDate &&
    currentMomentDate.diff(
      xpEndMomentDate.add(durationYearsSinceHasNoCurrentXp, 'years'),
      'months',
    );

  const durationSinceHasNoCurrentXp =
    _.isNumber(durationYearsSinceHasNoCurrentXp) &&
    (durationYearsSinceHasNoCurrentXp > 1
      ? durationYearsSinceHasNoCurrentXp + ' ans '
      : durationYearsSinceHasNoCurrentXp
        ? durationYearsSinceHasNoCurrentXp + ' an '
        : '') +
    (durationMonthsSinceHasNoCurrentXp
      ? durationMonthsSinceHasNoCurrentXp + ' mois'
      : '');
  const getWorkplaceTypeIcon = (
    type, //handshake
  ) =>
    type === 'ssii'
      ? 'desktop'
      : type === 'st'
        ? 'lightbulb'
        : type === 'big'
          ? 'industry'
          : 'question';
  return (
    <Item key={i}>
      <Item.Content>
        <Item.Header style={{ width: '100%' }}>
          {exp.experienceName}
          {exp &&
            exp.enriched &&
            _.isArray(exp.enriched.jobPosition.fields) &&
            _.map(exp.enriched.jobPosition.fields, (jobPos, index) => (
              <Popup
                key={index}
                trigger={
                  <Label
                    color="grey"
                    style={{ marginLeft: '5px', float: 'right' }}
                  >
                    {jobPos}
                  </Label>
                }
                content={'job position'}
              />
            ))}
        </Item.Header>
        <br />
        <Item.Header style={{ width: '100%' }}>
          <Popup
            trigger={
              ((exp.enriched || {}).workplace || {}).website ? (
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={((exp.enriched || {}).workplace || {}).website}
                  >
                    {(exp || {}).companyName}
                  </a>
                </div>
              ) : (
                  <div>{(exp || {}).companyName}</div>
                )
            }
            content={<Workplace data={(exp.enriched || {}).workplace} />}
            header={(exp || {}).companyName}
          />
          {((exp || {}).enriched || {}).workplaceId && (
            <Popup
              trigger={
                <Label
                  color="grey"
                  style={{
                    marginLeft: '5px',
                    marginTop: '5px',
                    float: 'right',
                  }}
                >
                  {exp.enriched.workplaceId}
                </Label>
              }
              content={'workplace'}
            />
          )}
          {!((exp || {}).enriched || {}).workplaceId && (
            <Popup
              trigger={
                <Label
                  style={{
                    marginLeft: '5px',
                    marginTop: '5px',
                    float: 'right',
                  }}
                >
                  no workplace
                </Label>
              }
              content={'add a workplace'}
            />
          )}
          {exp.enriched
            ? _.isNumber(exp.enriched.prestige) && (
              <Popup
                trigger={
                  <Label
                    color={exp.enriched.prestige > 3 ? 'green' : 'orange'}
                    style={{ marginLeft: '5px', float: 'right' }}
                  >
                    {exp.enriched.prestige}
                  </Label>
                }
                content={'workplace prestige'}
              />
            )
            : null}
          {exp.enriched &&
            _.isArray(exp.enriched.workplaceType) &&
            _.map(_.uniq(_.compact(exp.enriched.workplaceType)), (type) => (
              <Popup
                key={type}
                trigger={
                  <Icon
                    name={getWorkplaceTypeIcon(type)}
                    style={{ marginLeft: '5px', float: 'right' }}
                  />
                }
                content={
                  type === 'st'
                    ? 'startup'
                    : type === 'big'
                      ? 'grand groupe'
                      : type
                }
              />
            ))}
        </Item.Header>
        <Item.Meta>
          {(exp.rawDates || []).join(' - ')}
          <Label
            color={xpDurationYears > 0 ? 'green' : 'orange'}
            style={{ marginLeft: '5px' }}
          >
            {xpDuration}
          </Label>
          {durationSinceHasNoCurrentXp && (
            <Label color="blue" style={{ marginLeft: '5px' }}>
              Disponible depuis {durationSinceHasNoCurrentXp}
            </Label>
          )}
          {exp.enriched && exp.enriched.jobPosition.responsibilities && (
            <Popup
              trigger={
                <Label
                  style={{ marginLeft: '5px', float: 'right' }}
                  color="grey"
                  basic
                >
                  {exp.enriched.jobPosition.responsibilities}
                </Label>
              }
              content={'responsibilities'}
            />
          )}
          {exp.enriched &&
            exp.enriched.jobPosition.tags &&
            exp.enriched.jobPosition.tags.length > 0 && (
              <Popup
                trigger={
                  <Label
                    style={{ marginLeft: '5px', float: 'right' }}
                    color="grey"
                    basic
                  >
                    {exp.enriched.jobPosition.tags.join(', ')}
                  </Label>
                }
                content={'tags'}
              />
            )}
        </Item.Meta>
        {exp.enriched ? (
          <Item.Meta>
            {exp.location}{' '}
            <ParsedLocations text={exp.location} />
          </Item.Meta>
        ) : null}
        <LinkedinExperienceDescription
          i={i}
          details={{}}
          raw={exp.descriptionHTML || ''}
          enrichedExperience={enrichedExperience}
        />
      </Item.Content>
    </Item>
  );
};
