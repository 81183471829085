import React, { Component } from 'react';
import { Segment, Icon, Button, Grid } from 'semantic-ui-react';

class MatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: 'quick_wins',
      },
    };
  }

  render() {
    const {
      selectedOffer,
      selectedProfile,
      sortType,
      setSortType,
      matches,
      disableSortOptions,
      displayAnnotation,
    } = this.props;
    const items = matches.map((match, index) => {
      const offerId = (match.offer || {}).id || '';
      const profileLinkedinId = ((match.profile || {}).idFields || {}).linkedin || '';
      const matchIsSelected =
        selectedOffer && selectedOffer.id === offerId && selectedProfile && selectedProfile.id === profileLinkedinId;
      const details = match.details || {};
      const getStyle = (label) => {
        const labelToColor = {
          A: 'green',
          B: 'orange',
          C: 'orange',
          D: 'red',
          E: 'red',
        };
        return { color: labelToColor[label] || 'blue' };
      };
      return (
        <Segment
          key={index}
          color={matchIsSelected ? 'black' : null}
          onClick={() => {
            this.props.setSelectedMatch(match);
          }}
          style={{ cursor: 'pointer' }}
        >
          {details.label && details.pred_label ? (
            <div>
              <b>
                label : <span style={getStyle(details.label)}>{details.label}</span>- pred :{' '}
                <span style={getStyle(details.pred_label)}>{details.pred_label}</span>
              </b>
            </div>
          ) : null}
          {match.push ? (
            <div style={{ color: 'blue' }}>
              <Icon name='external' />
              <b>{`pushed (${match.push.user})`} </b>
              <br />
            </div>
          ) : null}
          {match.disqualify ? (
            <div style={{ color: 'red' }}>
              <Icon name='close' />
              <b>{`disqualified (${match.disqualify.user})`} </b>
              <br />
            </div>
          ) : null}

          {displayAnnotation && match.annotation ? (
            <div style={{ color: 'blue' }}>
              <Icon name='tag' />
              <b>{`${match.annotation.user} : ${match.annotation.grade}`} </b>
              <br />
            </div>
          ) : null}
          <b>{`${index} - ${Math.round(100 * match.score) / 100}`}</b>
          <br />
          <b>
            <Icon name={matchIsSelected ? 'user' : 'user outline'} />
            {`${match.profile.fullname || profileLinkedinId.slice(0, 10)}`}
          </b>
          <br />
          <b>
            <Icon name={matchIsSelected ? 'file' : 'file outline'} />
            {`${match.offer.title}@${match.offer.companyId}`}
          </b>
        </Segment>
      );
    });
    return (
      <Segment>
        <Grid>
          <Grid.Row>
            {!disableSortOptions ? (
              <Button.Group>
                <Button positive={sortType === 'profile' ? true : false} onClick={() => setSortType('profile')}>
                  <Icon name='sort' />P
                </Button>
                <Button positive={sortType === 'offer' ? true : false} onClick={() => setSortType('offer')}>
                  <Icon name='sort' />O
                </Button>
                <Button positive={sortType === 'score' ? true : false} onClick={() => setSortType('score')}>
                  <Icon name='sort' />S
                </Button>
              </Button.Group>
            ) : null}
          </Grid.Row>
          <Grid.Row style={{ overflowY: 'auto', overflowX: 'auto', height: '75vh' }}>
            <Segment.Group>{items}</Segment.Group>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default MatchList;
