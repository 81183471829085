import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Table, Segment, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import baseUrl from '../baseUrl.js';
import { FeedbackModal } from '../Offers/WorkPipeInputComponents/AnnotationButtons';
import moment from 'moment';

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

// Components
class SearchFeedbacks extends Component {
  state = {};
  
  async componentWillMount () {
    const { feedbacks } = (await axios.get(`${baseUrl}/monitoring/searchFeedbacks`)).data;
    this.setState({feedbacks})
  }
  render() {
    const { selectedFeedback, feedbacks } = this.state;
    const profileForModal = {innerData:{scorerDetails:(selectedFeedback || {}).scorerDetails}};
    return (
      <Grid>
        <Grid.Row>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>User</Table.HeaderCell>
              <Table.HeaderCell>Profile</Table.HeaderCell>
              <Table.HeaderCell>Offer</Table.HeaderCell>
              <Table.HeaderCell>Scorer Details</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
              {_.map(feedbacks, (feedback, index) => {
                const linkedinData = ((((feedback || {}).data || {}).linkedin || {}).data || {})
                const offer = ((feedback || {}).offer || {})
                const date = moment((feedback || {}).timestamp).format('YYYY-MM-DD')
                //const line = `${date} | ${linkedinData.firstname} ${linkedinData.lastname} | ${offer.title} @ ${offer.companyId}`
                const reason = feedback.reason
                return (
                  <Table.Row key={feedback.id}>
                    <Table.Cell>{index}</Table.Cell>
                    <Table.Cell>{date}</Table.Cell>
                    <Table.Cell>{feedback.username}</Table.Cell>
                    <Table.Cell>
                      <Link
                      to={`/profiles/${feedback.idFields.linkedin}`}
                      target='_blank'
                      >
                      {`${linkedinData.firstname} ${linkedinData.lastname}`}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                      to={`http://sweethub-beta.hiresweet.com/offers/${offer.id}`}
                      target='_blank'
                      >
                      {`${offer.title} @ ${offer.companyId}`}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Button onClick={()=>(this.setState({selectedFeedback:feedback}))}>Score Details</Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Segment
                        style={{ marginTop: 20 }}
                        secondary
                        dangerouslySetInnerHTML={getRawMarkup(reason)}
                      />
                    </Table.Cell>

                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
        {
                selectedFeedback ? (
                  <FeedbackModal 
                    onClose={()=>(this.setState({selectedFeedback:null}))}
                    onSubmit={()=>(alert('cant submit'))}
                    profile={profileForModal}
                  />
                ) : null
        }
        </Grid.Row>
      </Grid>
    );
  }
}


export default SearchFeedbacks;