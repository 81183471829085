import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import LabelingPipe from './LabelingPipe';
import axios from 'axios';
import { Form } from 'semantic-ui-react';

class KnowledgeIngestion extends Component {
  componentDidMount() {
    this.handleLoadLabelingPipes();
  }

  async getLabelingPipes() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/knowledgeIngestion/getLabelingPipes';
    const data = (await axios.get(url, customHeaders)).data;
    return _.map((data || {}).data || [], (item) => ({
      value: item.id,
      key: item.id,
      text: item.name,
    }));
  }

  handleLoadLabelingPipes = async () => {
    const pipes = await this.getLabelingPipes();
    this.setState({ pipes });
  };

  handleSelectPipe = async (e, { value }) => {
    this.setState({ selectedPipeId: value });
  };

  onCancel = () => {
    this.setState({ selectedPipeId: null });
  };

  render() {
    const { pipes, selectedPipeId } = this.state || {};
    return (
      <div>
        <Form.Select
          search
          placeholder='Pipes'
          options={pipes || []}
          onChange={this.handleSelectPipe}
          value={selectedPipeId}
        />
        {selectedPipeId && selectedPipeId !== undefined ? <LabelingPipe pipeId={selectedPipeId} /> : null}
      </div>
    );
  }
}

export default KnowledgeIngestion;
