import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import {
  Container,
  Header,
  Table,
  Grid,
  Button,
  Label,
  Form,
  Segment,
  List as SList,
  Icon,
  Accordion,
} from 'semantic-ui-react';
import { SweetForm, Select, Checkbox } from 'sweetform';
import TagsView from './TagsView';

import baseUrl from './baseUrl';
import Pagination from './Pagination';

// Actions

const SET_POOL_CREATION_TIMESTAMP = 'SET_POOL_CREATION_TIMESTAMP';
const setPoolCreationTimestamp = (timestamp) => ({
  type: SET_POOL_CREATION_TIMESTAMP,
  timestamp,
});

const SET_COUNTS = 'SET_COUNTS';
const setCounts = (counts) => ({ type: SET_COUNTS, counts });

const SET_OFFERS_LIST = 'SET_OFFERS_LIST';
const setOffersList = (offersList) => ({ type: SET_OFFERS_LIST, offersList });

const SET_LOCATION_OPTIONS = 'SET_LOCATION_OPTIONS';
const setLocationOptions = (locationOptions) => ({
  type: SET_LOCATION_OPTIONS,
  locationOptions,
});

const SET_WORKPLACES_LIST = 'SET_WORKPLACES_LIST';
const setWorkplacesList = (workplacesList) => ({
  type: SET_WORKPLACES_LIST,
  workplacesList,
});

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_SEARCH_VERSION = 'SET_SEARCH_VERSION';
const setSearchVersion = (searchVersion) => ({
  type: SET_SEARCH_VERSION,
  searchVersion,
});

const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
const setSelectedOffer = (offer) => ({ type: SET_SELECTED_OFFER, offer });

const SET_SELECTED_WORKPLACE = 'SET_SELECTED_WORKPLACE';
const setSelectedWorkplace = (workplace) => ({
  type: SET_SELECTED_WORKPLACE,
  workplace,
});

const SET_DISPLAY_MODE = 'SET_DISPLAY_MODE';
const setDisplayMode = (mode) => ({ type: SET_DISPLAY_MODE, mode });

const TOGGLE_OFFER_DESCRIPTION_DISPLAY = 'TOGGLE_OFFER_DESCRIPTION_DISPLAY';
export const toggleOfferDescriptionDisplay = () => ({
  type: TOGGLE_OFFER_DESCRIPTION_DISPLAY,
});

const TOGGLE_WORKPLACE_DESCRIPTION_DISPLAY =
  'TOGGLE_WORKPLACE_DESCRIPTION_DISPLAY';
const toggleWorkplaceDescriptionDisplay = () => ({
  type: TOGGLE_WORKPLACE_DESCRIPTION_DISPLAY,
});

// Reducers
const poolCreationTimestamp = (state = [], action) => {
  switch (action.type) {
    case SET_POOL_CREATION_TIMESTAMP:
      return action.timestamp;
    default:
      return state;
  }
};

const offersList = (state = [], action) => {
  switch (action.type) {
    case SET_OFFERS_LIST:
      return action.offersList;
    default:
      return state;
  }
};

const workplacesList = (state = [], action) => {
  switch (action.type) {
    case SET_WORKPLACES_LIST:
      return action.workplacesList;
    default:
      return state;
  }
};

const counts = (state = {}, action) => {
  switch (action.type) {
    case SET_COUNTS:
      return action.counts;
    default:
      return state;
  }
};

const locationOptions = (state = [], action) => {
  switch (action.type) {
    case SET_LOCATION_OPTIONS:
      return action.locationOptions;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const searchVersion = (state = 0, action) => {
  switch (action.type) {
    case SET_SEARCH_VERSION:
      return action.searchVersion;
    default:
      return state;
  }
};

const selectedOffer = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_OFFER:
      return action.offer;
    default:
      return state;
  }
};

const selectedWorkplace = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_WORKPLACE:
      return action.workplace;
    default:
      return state;
  }
};

const displayMode = (state = 'aggregate', action) => {
  switch (action.type) {
    case SET_DISPLAY_MODE:
      return action.mode;
    default:
      return state;
  }
};

export const offerDescriptionDisplay = (state = -1, action) => {
  switch (action.type) {
    case TOGGLE_OFFER_DESCRIPTION_DISPLAY:
      return -state;
    default:
      return state;
  }
};

const workplaceDescriptionDisplay = (state = -1, action) => {
  switch (action.type) {
    case TOGGLE_WORKPLACE_DESCRIPTION_DISPLAY:
      return -state;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  offersList,
  workplacesList,
  counts,
  search,
  searchVersion,
  selectedOffer,
  selectedWorkplace,
  displayMode,
  offerDescriptionDisplay,
  workplaceDescriptionDisplay,
  locationOptions,
  poolCreationTimestamp,
});

// Store
const store = createStore(rootReducer);

// Components
const LOCATION_OPTIONS = [
  { label: 'Remote', value: 'remote' },
  { label: 'Paris', value: 'paris' },
  { label: 'Lyon', value: 'lyon' },
  { label: 'Bordeaux', value: 'bordeaux' },
  { label: 'Lille', value: 'lille' },
  { label: 'Marseille', value: 'marseille' },
  { label: 'Clermont-Ferrand', value: 'clermont-ferrand' },
  { label: 'Dijon', value: 'dijon' },
  { label: 'Rennes', value: 'rennes' },
  { label: 'Orléans', value: 'orléans' },
  { label: 'Strasbourg', value: 'strasbourg' },
  { label: 'Rouen', value: 'rouen' },
  { label: 'Toulouse', value: 'toulouse' },
  { label: 'Nantes', value: 'nantes' },
  { label: 'Reims', value: 'reims' },
  { label: 'Montpellier', value: 'montpellier' },
];

const NETWORK_OPTIONS = [
  { label: 'Clients', value: 'clients' },
  { label: 'In Touch', value: 'inTouch' },
  { label: 'Not Client', value: 'notClient' },
];

const SENIORITY_OPTIONS = [
  { label: 'Junior', value: 'junior' },
  { label: 'Experimented', value: 'experimented' },
  { label: 'Senior', value: 'senior' },
];

const sourceTypeLabels = {
  wttjOffers: 'Welcome To The Jungle',
  workableOffers: 'Workable',
};

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

const loadIndustries = async (input) => {
  const industries = (await axios.get(`${baseUrl}/tags/list?type=industry`))
    .data;
  return { options: industries, complete: true };
};
const SelectIndustries = (props) => (
  <Select
    multi
    {...props}
    async={true}
    loadOptions={loadIndustries}
    labelKey="name"
    valueKey="name"
  />
);

const loadSkills = async (input) => {
  const skills = (await axios.get(`${baseUrl}/tags/list?type=skill`)).data;
  return { options: _.sortBy(skills, 'name'), complete: true };
};
const SelectSkill = (props) => (
  <Select
    multi
    {...props}
    async={true}
    loadOptions={loadSkills}
    labelKey="name"
    valueKey="id"
    matchPos="start"
    matchProp="label"
  />
);
const loadJobTitles = async (input) => {
  const jobTitles = (await axios.get(`${baseUrl}/tags/list?type=job`)).data;
  return { options: jobTitles, complete: true };
};
const SelectJobTitles = (props) => (
  <Select
    multi
    {...props}
    async={true}
    loadOptions={loadJobTitles}
    labelKey="name"
    valueKey="id"
  />
);

const loadCompanyIds = async (input) => {
  const companyIds = (await axios.get(`${baseUrl}/offers/companyId`)).data;
  return { options: _.sortBy(companyIds, 'value'), complete: true };
};
const SelectCompanyIds = (props) => (
  <Select
    {...props}
    async={true}
    loadOptions={loadCompanyIds}
    multi={true}
    creatable
  />
);
// Components
const ColorTag = ({ label, color, detail }) => (
  <Label color={color}>
    {label}
    {detail ? <Label.Detail>{detail}</Label.Detail> : null}
  </Label>
);
class WorkplaceEditableComment extends Component {
  state = {
    editable: false,
  };
  handleToggleEditMode = () => {
    const current = this.state.editable;
    this.setState({ editable: !current });
  };
  handleSave = async () => {
    const comment = this.input.value;
    const { workplace } = this.props;
    const workplaceId = (workplace || {}).id;
    if (workplaceId) {
      await axios.put(`${baseUrl}/workplaces/${workplaceId}/annotation`, {
        comment,
      });
      await axios.get(
        `${baseUrl}/reverseSearch/getEnrichedWorkplace/${workplaceId}`,
      );
    } else {
      alert('error during annotation => not saved');
    }
    this.handleToggleEditMode();
  };
  render() {
    const { workplace } = this.props;
    const { editable } = this.state;
    if (_.isEmpty(workplace) || !workplace.id) {
      return null;
    }
    const comment = workplace.hiresweetComment || '';
    return (
      <div key={workplace.id}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <textarea
                cols={60}
                disabled={!editable ? 'disabled' : ''}
                ref={(el) => (this.input = el)}
                defaultValue={comment}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              {!editable ? (
                <Button onClick={this.handleToggleEditMode}>Edit</Button>
              ) : (
                <Button onClick={this.handleSave}>Save</Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const WorkplaceDetails = ({
  workplace,
  descriptionDisplay,
  toggleDescriptionDisplay,
}) => (
  <Segment>
    {workplace.client ? (
      <Label color="green" ribbon>
        Client
      </Label>
    ) : null}
    <Header as="h2">{workplace.name ? workplace.name : workplace.id}</Header>
    {workplace.website ? (
      <a href={workplace.website} target="__blank">
        Website
      </a>
    ) : null}
    {workplace.networkLevel ? (
      <TagsView tags={[['network level', workplace.networkLevel.toString()]]} />
    ) : null}
    <TagsView tags={[['location', workplace.location]]} />
    {workplace.industries ? (
      <p>
        <b>Industries :</b>
        <TagsView tags={workplace.industries} />
      </p>
    ) : null}
    {workplace.specialities ? (
      <p>
        <b>Specialities :</b>
        <TagsView tags={workplace.specialities} />
      </p>
    ) : null}
    <WorkplaceEditableComment workplace={workplace} />

    {workplace.description ? (
      <Accordion fluid styled>
        <Accordion.Title
          active={descriptionDisplay === 1}
          onClick={toggleDescriptionDisplay}
        >
          <Icon name="dropdown" />
          Description
        </Accordion.Title>
        <Accordion.Content active={descriptionDisplay === 1}>
          <Segment
            secondary
            dangerouslySetInnerHTML={getRawMarkup(workplace.description)}
          />
        </Accordion.Content>
      </Accordion>
    ) : null}
  </Segment>
);
export const OfferDetails = ({
  offer,
  descriptionDisplay,
  toggleDescriptionDisplay,
}) => (
  <Segment>
    {offer.isHiresweet ? (
      <Label color="green" ribbon>
        Hiresweet Offer
      </Label>
    ) : null}
    {offer.creationDate ? (
      <ColorTag
        color="blue"
        label="Creation"
        detail={moment(offer.creationDate).fromNow()}
      />
    ) : null}
    {offer.lastSentDate ? (
      <ColorTag
        color="blue"
        label="Last Send"
        detail={moment(offer.lastSentDate).fromNow()}
      />
    ) : null}
    {offer.expired ? <ColorTag label="Expired" color="red" /> : null}
    {offer.isHot ? (
      <Label color="orange">
        <Icon name="warning" />
        Hot
      </Label>
    ) : null}
    {offer.isWatch ? (
      <Label color="orange">
        <Icon name="eye" />
        Watch
      </Label>
    ) : null}
    {offer.priority ? (
      <Label color="orange">
        <Icon name="star" />
        Priority
      </Label>
    ) : null}

    <Header as="h2">
      <Link to={`/offers/${offer.id}`}>{offer.title}</Link>
    </Header>
    {offer.link ? (
      <a
        href={
          offer.link.slice(0, 4) === 'http'
            ? offer.link
            : `https://${offer.link}`
        }
        target="__blank"
      >
        {offer.sourceType
          ? `Link : ${sourceTypeLabels[offer.sourceType]}`
          : 'Link'}
      </a>
    ) : null}
    <TagsView tags={[['location', offer.location]]} />
    <TagsView tags={[['headline', offer.jobId]]} />
    <TagsView
      tags={[
        ['type', offer.contractType],
        ['responsibilities', offer.responsibilities],
        ['remote', offer.remote ? 'yes' : ''],
      ]}
    />
    <TagsView
      tags={[
        [
          'experience',
          offer.experience
            ? `${offer.experience.min} - ${offer.experience.max}`
            : null,
        ],
        ['seniority', offer.seniority ? offer.seniority : null],
      ]}
    />
    {offer.salaryRange ? (
      <p>
        Salary: {offer.salaryRange.min} - {offer.salaryRange.max}
      </p>
    ) : null}

    {offer.skills ? (
      <Container>
        {_.map(offer.skills, (skills, key) => (
          <div key={key}>
            <b>{key}</b>
            <TagsView tags={skills} />
          </div>
        ))}
      </Container>
    ) : null}
    {offer.sourceText ? (
      <Accordion fluid styled>
        <Accordion.Title
          active={descriptionDisplay === 1}
          onClick={toggleDescriptionDisplay}
        >
          <Icon name="dropdown" />
          Description
        </Accordion.Title>
        <Accordion.Content active={descriptionDisplay === 1}>
          <Segment
            secondary
            dangerouslySetInnerHTML={getRawMarkup(offer.sourceText)}
          />
        </Accordion.Content>
      </Accordion>
    ) : null}
    <TagsView tags={[['id', offer.id]]} />
  </Segment>
);

const expiredStyle = { cursor: 'pointer', color: 'gray', opacity: '0.5' };
const OfferRow = ({ offer, onClick }) => (
  <Table.Row
    style={offer.expired ? expiredStyle : { cursor: 'pointer' }}
    color="blue"
    onClick={onClick}
  >
    <Table.Cell>
      {offer.workplace.name ? offer.workplace.name : offer.workplace.id}
    </Table.Cell>
    <Table.Cell>{offer.title}</Table.Cell>
    <Table.Cell>
      {offer.creationDate ? moment(offer.creationDate).fromNow() : ''}
    </Table.Cell>
  </Table.Row>
);
const OfferTable = ({ offers, counts, onSelect, onPage }) => (
  <div>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Creation Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {offers.map((offer) => (
          <OfferRow
            key={offer.id}
            offer={offer}
            onClick={() => onSelect(offer.id, offer.workplace.id)}
          />
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={10}>
            <Pagination
              floated="right"
              current={counts.pageOffset}
              total={counts.pages}
              onPage={onPage}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  </div>
);
const OfferLine = ({ offer, onSelect }) => (
  <SList.Item
    onClick={() => onSelect(offer.id)}
    style={offer.expired ? expiredStyle : { cursor: 'pointer' }}
  >
    <div
      style={{
        margin: '0',
        padding: '0',
        display: 'inline-block',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        width: '80%',
      }}
    >
      {offer.priority ? (
        <Icon
          name="star"
          color="orange"
          style={{ width: '15px', height: '15px', 'margin-right': '1px' }}
        />
      ) : null}
      {offer.isHot ? (
        <Icon
          name="warning"
          color="orange"
          style={{ width: '15px', height: '15px', 'margin-right': '1px' }}
        />
      ) : null}
      {offer.isWatch ? (
        <Icon
          name="eye"
          color="orange"
          style={{ width: '15px', height: '15px', 'margin-right': '1px' }}
        />
      ) : null}
      {offer.isHiresweet ? (
        <img
          alt="sweetapp logo"
          src="/images/sweetapp-logo.png"
          style={{ width: '15px', height: '15px', 'margin-right': '3px' }}
        />
      ) : null}
      <b>{offer.title}</b>
      <i style={{ 'margin-left': '3px', color: 'gray' }}>
        {_.union(
          offer.skills.required,
          offer.skills.important,
          offer.skills.bonus,
        ).length > 0
          ? `- ${_.first(
              _.union(
                offer.skills.required,
                offer.skills.important,
                offer.skills.bonus,
              ),
              3,
            ).join(', ')}`
          : null}
      </i>
    </div>
    <div
      style={{
        margin: '0',
        padding: '0',
        float: 'right',
        display: 'inline-block',
        width: '20%',
        'text-align': 'right',
      }}
    >
      <i>{offer.creationDate ? moment(offer.creationDate).fromNow() : null}</i>
    </div>
  </SList.Item>
);

const OfferList = ({ offers, onSelect }) => (
  <Segment basic>
    <SList>
      <SList.Item>
        <i
          style={{
            float: 'right',
            'font-weight': 'normal',
            'font-size': 'x-small',
          }}
        >
          Creation Date
        </i>
      </SList.Item>
      {offers.map((offer) => (
        <OfferLine offer={offer} onSelect={onSelect} />
      ))}
    </SList>
  </Segment>
);

const WorkplaceList = ({ workplaces, counts, onPage, onSelect }) => (
  <Segment.Group>
    {workplaces.map((workplace) => (
      <Segment key={workplace.id}>
        <Header> {workplace.name ? workplace.name : workplace.id}</Header>
        <OfferList
          offers={workplace.offers}
          onSelect={(offerId) => onSelect(offerId, workplace.id)}
        />
      </Segment>
    ))}
    <Pagination
      floated="right"
      current={counts.pageOffset}
      total={counts.pages}
      onPage={onPage}
    />
  </Segment.Group>
);

const cbStyle = { display: 'block', marginTop: 9 };
class ReverseSearch extends Component {
  state = {
    version: 0,
  };
  componentDidMount() {
    const defaultSearch = { aggregateByWorkplace: true };
    this.props.onSearch(defaultSearch);
    this.props.onLoad(this.props.match.params.id);
    this.props.onChangeLocationOptions(LOCATION_OPTIONS);
  }

  setInputRef = (ref) => {
    this.inputRef = ref;
  };

  onChangeSearchFromValue = () => {
    const value = this.inputRef.value;
    try {
      const newSearch = JSON.parse(value);
      if (newSearch.locations) {
        const locationOptions = LOCATION_OPTIONS.concat(
          _.map(newSearch.locations.split(';'), (loc) => ({
            label: loc,
            value: loc,
          })),
        );
        this.props.onChangeLocationOptions(locationOptions);
      }
      this.props.onChangeSearch(newSearch);
      this.props.onChangeSearchVersion(Date.now());
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      offersList,
      workplacesList,
      selectedOffer,
      selectedWorkplace,
      locationOptions,
      counts,
      search,
      offerDescriptionDisplay,
      workplaceDescriptionDisplay,
      onSearch,
      onChangeSearch,
      onSelect,
      displayMode,
      toggleDescriptionDisplay,
      onDownload,
      poolCreationTimestamp,
    } = this.props;

    const dateStr =
      poolCreationTimestamp === null
        ? 'unknown'
        : poolCreationTimestamp
        ? moment(new Date(poolCreationTimestamp)).format('YY-MM-DD')
        : '';
    const nbDays = poolCreationTimestamp
      ? Math.floor((Date.now() - poolCreationTimestamp) / (24 * 3600 * 1000))
      : 1000;
    const dateColor =
      nbDays <= 1
        ? 'green'
        : nbDays <= 2
        ? 'olive'
        : nbDays <= 3
        ? 'orange'
        : 'red';

    return (
      <Grid>
        <Grid.Column width={3}>
          {dateStr && (
            <h3 style={{ color: dateColor }}>Last build: {dateStr}</h3>
          )}
          <Header as="h2">Reverse Search</Header>
          <div style={{ position: 'fixed', top: -1000, left: -1000 }}>
            <h1>Hidden</h1>
            <input id="hacky-search-input" ref={this.setInputRef} />
            <Button
              id="hacky-search-submit"
              onClick={this.onChangeSearchFromValue}
            >
              From Value
            </Button>
          </div>
          <SweetForm
            key={this.props.searchVersion}
            onChange={onChangeSearch}
            initialValues={this.props.search}
          >
            <Form>
              <Form.Field>
                <Checkbox
                  slider
                  defaultValue={true}
                  field="aggregateByWorkplace"
                  style={cbStyle}
                  label="Aggregate by workplace"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  slider
                  defaultValue={false}
                  field="isHot"
                  style={cbStyle}
                  label="Hot offers"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  slider
                  defaultValue={false}
                  field="isWatch"
                  style={cbStyle}
                  label="Watch offers"
                />
              </Form.Field>
              <Form.Field>
                <Select
                  field="network"
                  placeholder="Network"
                  options={NETWORK_OPTIONS}
                  multi={true}
                />
              </Form.Field>
              <Form.Field>
                <SelectIndustries
                  field="industries"
                  multi
                  placeholder="Industry"
                />
              </Form.Field>
              <Form.Field>
                <Select
                  field="locations"
                  placeholder="Location"
                  options={locationOptions}
                  multi={true}
                />
              </Form.Field>
              <Form.Field>
                <SelectCompanyIds field="companies" placeholder="Company" />
              </Form.Field>
              <Form.Field>
                <SelectJobTitles field="headlines" placeholder="Headline" />
              </Form.Field>
              <Form.Field>
                <SelectSkill field="skills" placeholder="Skills" multi={true} />
              </Form.Field>
              <Form.Field>
                <Select
                  field="seniorities"
                  placeholder="Seniority"
                  options={SENIORITY_OPTIONS}
                  multi={true}
                />
              </Form.Field>
            </Form>
          </SweetForm>
          <Button fluid color="teal" onClick={() => onSearch(search)}>
            Search
          </Button>
          <Button fluid color="orange" onClick={() => onDownload(search)}>
            Download as CSV
          </Button>
        </Grid.Column>
        <Grid.Column width={6} style={{ overflow: 'auto', height: '90vh' }}>
          {counts.offers ? <h3> Offers : {counts.offers}</h3> : null}
          {displayMode === 'aggregated' ? (
            <WorkplaceList
              workplaces={workplacesList}
              onSelect={onSelect}
              onPage={(i) => onSearch(search, i)}
              counts={counts}
            />
          ) : (
            <OfferTable
              offers={offersList}
              onSelect={onSelect}
              onPage={(i) => onSearch(search, i)}
              counts={counts}
            />
          )}
        </Grid.Column>
        <Grid.Column width={7}>
          <WorkplaceDetails
            workplace={selectedWorkplace}
            descriptionDisplay={workplaceDescriptionDisplay}
            toggleDescriptionDisplay={() =>
              toggleDescriptionDisplay('workplace')
            }
          />
          <OfferDetails
            offer={selectedOffer}
            descriptionDisplay={offerDescriptionDisplay}
            toggleDescriptionDisplay={() => toggleDescriptionDisplay('offer')}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers
const mapSReverse = (state) => ({
  offersList: state.offersList,
  workplacesList: state.workplacesList,
  counts: state.counts,
  search: state.search,
  searchVersion: state.searchVersion,
  aggregateByWorkplace: state.aggregateByWorkplace,
  selectedOffer: state.selectedOffer,
  selectedWorkplace: state.selectedWorkplace,
  displayMode: state.displayMode,
  offerDescriptionDisplay: state.offerDescriptionDisplay,
  workplaceDescriptionDisplay: state.workplaceDescriptionDisplay,
  locationOptions: state.locationOptions,
  poolCreationTimestamp: state.poolCreationTimestamp,
});

const mapDReverse = (dispatch) => ({
  onLoad: async () => {
    const result = await axios.get(
      `${baseUrl}/reverseSearch/poolCreationTimestamp`,
    );
    const { timestamp } = result.data;
    dispatch(setPoolCreationTimestamp(timestamp));
  },
  onChangeSearch: (s) => dispatch(setSearch(s)),
  onChangeSearchVersion: (version) => dispatch(setSearchVersion(version)),
  onChangeLocationOptions: (locationOptions) =>
    dispatch(setLocationOptions(locationOptions)),
  onSearch: async (search, page = 0) => {
    const maxResults = 20;
    if (search.aggregateByWorkplace) {
      dispatch(setDisplayMode('aggregated'));
      const result = await axios.get(
        `${baseUrl}/reverseSearch/workplaceAggregated`,
        { params: { search, page, maxResults } },
      );
      const { workplaces, counts } = result.data;
      dispatch(setCounts(counts));
      dispatch(setWorkplacesList(workplaces));
      if (workplaces.length > 0) {
        dispatch(setSelectedWorkplace(workplaces[0]));
        dispatch(setSelectedOffer(workplaces[0].offers[0]));
      } else {
        dispatch(setSelectedWorkplace({}));
        dispatch(setSelectedOffer({}));
      }
    } else {
      dispatch(setDisplayMode('basic'));
      const result = await axios.get(`${baseUrl}/reverseSearch`, {
        params: { search, page, maxResults },
      });
      const { offers, counts } = result.data;
      dispatch(setCounts(counts));
      dispatch(setOffersList(offers));
      if (offers.length > 0) {
        dispatch(setSelectedOffer(offers[0]));
        dispatch(setSelectedWorkplace(offers[0].workplace));
      } else {
        dispatch(setSelectedOffer({}));
        dispatch(setSelectedWorkplace({}));
      }
    }
  },
  onDownload: async (search) => {
    const result = await axios.get(`${baseUrl}/reverseSearch/allResults`, {
      params: { search },
    });
    const jsonResults = result.data;

    // Convert to csv
    const items = jsonResults.offers;
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    );
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');

    // Download csv
    var csvData = new Blob([csv], { type: 'text/csv' });
    var csvUrl = URL.createObjectURL(csvData);
    var hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.download = 'offers.csv';
    hiddenElement.click();
  },
  onSelect: async (offerId, workplaceId) => {
    const workplaceResult = await axios.get(
      `${baseUrl}/reverseSearch/getEnrichedWorkplace/${workplaceId}`,
    );
    const { workplace } = workplaceResult.data;
    dispatch(setSelectedWorkplace(workplace));

    const offerResult = await axios.get(
      `${baseUrl}/reverseSearch/getEnrichedOffer/${offerId}`,
    );
    const { offer } = offerResult.data;
    dispatch(setSelectedOffer(offer));
  },
  toggleDescriptionDisplay: (type) => {
    if (type === 'workplace') {
      dispatch(toggleWorkplaceDescriptionDisplay());
    } else if (type === 'offer') {
      dispatch(toggleOfferDescriptionDisplay());
    }
  },
});

const ReverseContainer = connect(
  mapSReverse,
  mapDReverse,
)(ReverseSearch);

export default ({ match, history }) => (
  <Provider store={store}>
    <ReverseContainer match={match} history={history} />
  </Provider>
);
