import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { Table } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

// Reducers
const instanceData = (state = [], action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  instanceData,
});

// Store
const store = createStore(rootReducer);

const SenderAddressesRow = ({ senderAddressStatus, index }) => {
  const senderAddressStatusCode =
    senderAddressStatus.sanityCheck.status === 'error' ? 1 : 0;
  return (
    <Table.Row {...(senderAddressStatusCode === 1 ? { negative: true } : {})}>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>{senderAddressStatus.senderAddress}</Table.Cell>
      <Table.Cell>
        {(senderAddressStatus.sanityCheck || {}).mailAccountId ||
          (senderAddressStatus.sanityCheck || {}).error}
      </Table.Cell>
      <Table.Cell>
        {(senderAddressStatus.sanityCheck || {}).lastCheckTimestamp
          ? moment(
              (senderAddressStatus.sanityCheck || {}).lastCheckTimestamp,
            ).fromNow()
          : 'jamais'}
      </Table.Cell>
      <Table.Cell>
        {(senderAddressStatus.sanityCheck || {}).nbSentProfiles}
      </Table.Cell>
      <Table.Cell>
        {(senderAddressStatus.sanityCheck || {}).lastSendTimestamp
          ? moment(
              (senderAddressStatus.sanityCheck || {}).lastSendTimestamp,
            ).fromNow()
          : 'jamais'}
      </Table.Cell>
      <Table.Cell>
        <span
          title={(
            (senderAddressStatus.sanityCheck || {}).jobOfferIds || []
          ).join(';')}
        >
          {((senderAddressStatus.sanityCheck || {}).jobOfferIds || []).length}
        </span>
      </Table.Cell>
    </Table.Row>
  );
};

class SenderAddresses extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { instanceData } = this.props;

    return (
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>N°</Table.HeaderCell>
            <Table.HeaderCell>SenderAddress</Table.HeaderCell>
            <Table.HeaderCell>MailAccountId / Error</Table.HeaderCell>
            <Table.HeaderCell>Last Sanity Check Date</Table.HeaderCell>
            <Table.HeaderCell>Nb Sent Profiles</Table.HeaderCell>
            <Table.HeaderCell>Last Sent Date</Table.HeaderCell>
            <Table.HeaderCell>Nb Job Offers</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(instanceData, (senderAddressStatus, index) => (
            <SenderAddressesRow
              key={index}
              senderAddressStatus={senderAddressStatus}
              index={index}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

const mapSSenderAddresses = (state) => ({
  instanceData: state.instanceData,
});

const loadSenderAddresses = async (dispatch) => {
  const { data } = await axios.get(`${baseUrl}/monitoring/senderaddresses`);
  const instances = _.sortBy(
    data,
    (senderAddress) =>
      ((senderAddress.sanityCheck || {}).status === 'error' ? '1' : '0') +
      ((senderAddress.sanityCheck || {}).lastSendTimestamp || 0),
  ).reverse();
  dispatch(setInstanceData(instances));
};

const mapDSenderAddresses = (dispatch) => ({
  onLoad: async () => {
    await loadSenderAddresses(dispatch);
  },
});

const SenderAddressesContainer = connect(
  mapSSenderAddresses,
  mapDSenderAddresses,
)(SenderAddresses);

export default () => (
  <Provider store={store}>
    <SenderAddressesContainer />
  </Provider>
);
