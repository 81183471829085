import React, { Component } from 'react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../../baseUrl.js';
import { Modal, Form, Input, Button } from 'semantic-ui-react';
import MiniButton from './MiniButton';

const onSaveItemDuplicate = async (duplicate, idFields) => {
  if (_.isString(duplicate) && !_.isEmpty(duplicate)) {
    const tryFindLinkedinId = (link) => {
      if (link.indexOf('linkedin.com/in') >= 0) {
        const sides = link.split('?');
        const parts = (sides[0] || '').split('/');
        const id = _.last(parts);
        if (id.length > 5) {
          return id;
        }
      } else {
        const sides = link.split('?');
        const parts = (sides[0] || '').split('/');
        const ids = _.compact(_.map(parts, (part) => (part.length > 1 ? part : null)));
        if (ids.length === 1) {
          return ids[0];
        }
      }
      return null;
    };
    const aliasId = duplicate.indexOf('/') >= 0 ? tryFindLinkedinId(duplicate) : duplicate;
    if (!aliasId) {
      alert('duplicate has a wrong format');
      return;
    }
    const body = { aliasId };
    const id = (idFields || {}).linkedin;
    try {
      await axios.post(`${baseUrl}/sweetynotes/linkedin/${id}/aliases`, body);
      // const sweetynote = await getSweetynote(profile.idFields);
      // dispatch(actions.setSweetynote(profile.frontendId, sweetynote));
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  } else {
    alert('duplicate is empty or not a string');
  }
};

const onNotifyItemDuplicate = (idFields) => axios.post(`${baseUrl}/sweetynotes/linkedin/${(idFields || {}).linkedin}/notify_as_alias`)

class DuplicateModal extends Component {
  state = { open: false, duplicate: null };
  onChangeDuplicate = (duplicate) => {
    this.setState({ duplicate });
  };

  onSaveDuplicate = async () => {
    await onSaveItemDuplicate(this.state.duplicate, this.props.idFields);
    await this.props.forget();
    this.setState({ open: false, duplicate: null });
  };

  onNotifyDuplicate = async () => {
    await onNotifyItemDuplicate(this.props.idFields);
    await this.props.forget();
    this.setState({ open: false, duplicate: null });
  };

  render() {
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <MiniButton
            onClick={() => this.setState({ open: true })}
            text={'Double'}
          />
        }
      >
        <Modal.Header icon="pencil" content="Duplicate" />
        <Modal.Content>
          <Form>
            <Input
              onChange={(e) => this.onChangeDuplicate(e.target.value)}
              value={this.state.duplicate || ''}
              placeholder="Duplicate"
              label="https://linkedin.com/in/"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={() => this.onNotifyDuplicate()}>
            I know it's a duplicate but I don't know its id
          </Button>
          <Button color="green" onClick={() => this.onSaveDuplicate()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DuplicateModal;