import React from 'react';
import { Header, Label, Segment, List } from 'semantic-ui-react';

import TagsView from './TagsView';

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

const StaffCountView = ({ staffCountRange }) =>
  staffCountRange ? (
    <span>
      {staffCountRange.min || 0}
      {' - '}
      {staffCountRange.max || staffCountRange.min || 1} employees
    </span>
  ) : null;
const HiresweetWorkplaceView = ({ company }) => (
  <div>
    <Header as="h2">{company.name}</Header>
    {company.isClient ? (
      <Label as="a" color="orange" ribbon="right">
        Client
      </Label>
    ) : null}
    <TagsView
      tags={[
        ['id', company.id],
        ['type', company.type],
        ['prestige', company.prestige],
        ['stationId', company.client],
        ['creation', company.creationDate],
        company.location,
      ]}
    />
    <TagsView tags={company.industriesLabeled || []} />
    <TagsView tags={company.tags || []} />
    <StaffCountView {...company} />

    {company.description ? (
      <Segment
        secondary
        dangerouslySetInnerHTML={getRawMarkup(company.description)}
      />
    ) : null}

    {company.patterns ? (
      <List bulleted>
        {company.patterns.split(';').map((pattern) => (
          <List.Item key={pattern}>{pattern}</List.Item>
        ))}
      </List>
    ) : null}
    <p>
      {company.website ? <a href={company.website}>Website</a> : null}{' '}
      {company.linkedin ? <a href={company.linkedin}>LinkedIn</a> : null}
    </p>
  </div>
);
const WttjWorkplaceView = ({ company }) => (
  <div>
    <Header as="h2">{company.name}</Header>
    <TagsView tags={[['id', company.id], ['date', company.date]]} />
    <TagsView tags={company.industriesRaw || []} />
    <StaffCountView {...company} />
    <List bulleted>
      {company.offices.sort().map((office) => (
        <List.Item key={office}>{office}</List.Item>
      ))}
    </List>

    {company.description ? (
      <Segment
        secondary
        dangerouslySetInnerHTML={getRawMarkup(company.description)}
      />
    ) : null}

    <p>
      {company.website ? <a href={company.website}>Website</a> : null}&nbsp;
      {company.linkedin ? <a href={company.linkedin}>LinkedIn</a> : null}&nbsp;
      {company.twitter ? <a href={company.twitter}>Twitter</a> : null}&nbsp;
      {company.facebook ? <a href={company.facebook}>Facebook</a> : null}
    </p>
  </div>
);
const HiredWorkplaceView = ({ company }) => (
  <div>
    <Header as="h2">{company.name}</Header>
    <TagsView
      tags={[['id', company.id], ['date', company.date], company.location]}
    />
    {company.populated ? (
      <div>
        <TagsView tags={(company.tags || '').split(',')} />
        <p>
          {company.industry} / <StaffCountView {...company} />
        </p>

        {company.description ? (
          <Segment
            secondary
            dangerouslySetInnerHTML={getRawMarkup(company.description)}
          />
        ) : null}

        <p>
          {company.website ? <a href={company.website}>Website</a> : null}{' '}
          {company.linkedin ? <a href={company.linkedin}>LinkedIn</a> : null}
        </p>
      </div>
    ) : null}
  </div>
);
const CrunchbaseWorkplaceView = ({ company }) => (
  <div>
    <Header as="h2">{company.name}</Header>
    <TagsView
      tags={[['id', company.id], ['date', company.date], company.location]}
    />
    <TagsView tags={company.industries || []} />
    {company.populated ? (
      <div>
        <p>
          Founded in {company.foundedYear} / <StaffCountView {...company} />
        </p>

        {company.description ? (
          <Segment
            secondary
            dangerouslySetInnerHTML={getRawMarkup(company.description)}
          />
        ) : null}

        <p>{company.website ? <a href={company.website}>Website</a> : null}</p>
      </div>
    ) : null}
  </div>
);
const WorkableWorkplaceView = ({ company }) => (
  <div>
    <Header as="h2">{company.name}</Header>
    <TagsView tags={[['id', company.id], ['date', company.date]]} />

    {company.description ? (
      <Segment
        secondary
        dangerouslySetInnerHTML={getRawMarkup(company.description)}
      />
    ) : null}

    <p>{company.website ? <a href={company.website}>Website</a> : null}</p>
  </div>
);
const DefaultView = ({ workplace }) => (
  <pre>{JSON.stringify(workplace, null, '  ')}</pre>
);

export default ({ workplace }) => (
  <div>
    {workplace.source === 'hiresweetWorkplaces' || !workplace.source ? (
      <HiresweetWorkplaceView company={workplace} />
    ) : workplace.source === 'wttjCompanies' ? (
      <WttjWorkplaceView company={workplace} />
    ) : workplace.source === 'hiredCompanies' ? (
      <HiredWorkplaceView company={workplace} />
    ) : workplace.source === 'crunchbaseCompanies' ? (
      <CrunchbaseWorkplaceView company={workplace} />
    ) : workplace.source === 'workableCompanies' ? (
      <WorkableWorkplaceView company={workplace} />
    ) : (
      <DefaultView workplace={workplace} />
    )}
  </div>
);
