import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import moment from 'moment';

require('moment/min/locales.min');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    load: 'unspecific',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      // (value, formatting, lng)
      format: (value, formatting) => {
        if (value instanceof Date) {
          if (formatting === 'relative') {
            return moment(value).fromNow();
          }
          return moment(value).format(formatting);
        }
        return value.toString();
      },
    },
    returnObjects: true,

    react: {
      wait: true,
      defaultTransParent: 'span',
    },
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

// i18n.changeLanguage('en');

export default i18n;
