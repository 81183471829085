import { Button, Card, Form, Grid } from 'semantic-ui-react';
import { Input, Select, SweetForm, List as SweetList } from 'sweetform';
import React, { Component } from 'react';

import _ from 'underscore';
import ProfilesViewModal from './ProfilesViewModal.js';

function getStartTimeOptions() {
  const timestampNow = new Date().getTime();
  return [
    { value: undefined, label: 'No Start Time Filter' },
    { value: timestampNow - 7 * 24 * 3600 * 1000, label: 'Last 7 days' },
    { value: timestampNow - 14 * 24 * 3600 * 1000, label: 'Last 14 days' },
    { value: timestampNow - 30 * 24 * 3600 * 1000, label: 'Last 30 days' },
    { value: timestampNow - 60 * 24 * 3600 * 1000, label: 'Last 60 days' },
    { value: timestampNow - 90 * 24 * 3600 * 1000, label: 'Last 90 days' },
  ];
}

const sampleSizeOptions = [
  { key: false, text: 'No sampling', value: undefined },
  { key: 10, text: 10, value: 10 },
  { key: 20, text: 20, value: 20 },
  { key: 50, text: 50, value: 50 },
  { key: 100, text: 100, value: 100 },
];

class ProfilesSampleSelection extends Component {
  async componentDidMount() {
    const { getProfileIdsInMission, getSkippedProfileIds } = this.props;
    const profileIdsInMission = await getProfileIdsInMission();
    const profileIdsSkippedFromRecommendations = await getSkippedProfileIds();
    const totalNumberOfProfilesInMission = (profileIdsInMission || []).length;
    const totalNumberOfSkippedProfiles = (profileIdsSkippedFromRecommendations || []).length;
    this.setState({
      positiveProfileIdsStartTimeFilter: profileIdsInMission,
      negativeProfileIdsStartTimeFilter: profileIdsSkippedFromRecommendations,
      totalNumberOfProfilesInMission,
      totalNumberOfSkippedProfiles,
    });
  }

  handleChangeNegativeStartTime = async (params) => {
    const { getSkippedProfileIds, updateNegativeProfileIds } = this.props;
    const negativeStartTimestamp = params.negativeProfilesSelectStartTime;
    const negativeProfileIdsStartTimeFilter = await getSkippedProfileIds(negativeStartTimestamp);
    updateNegativeProfileIds(negativeProfileIdsStartTimeFilter);
    this.setState({
      negativeProfileIdsStartTimeFilter,
      sampleNegativeProfileIds: undefined,
      negativeSampleSize: undefined,
    });
  };

  handleChangePositiveStartTime = async (params) => {
    const { getProfileIdsInMission, updatePositiveProfileIds } = this.props;
    const positiveStartTimestamp = params.positiveProfilesSelectStartTime;
    const positiveProfileIdsStartTimeFilter = await getProfileIdsInMission(positiveStartTimestamp);
    updatePositiveProfileIds(positiveProfileIdsStartTimeFilter);
    this.setState({
      positiveProfileIdsStartTimeFilter,
      samplePositiveProfileIds: undefined,
      positiveSampleSize: undefined,
    });
  };

  samplePositiveProfiles = (positiveSampleSize) => {
    const { updatePositiveProfileIds } = this.props;
    const { positiveProfileIdsStartTimeFilter } = this.state;
    const samplePositiveProfileIds = !_.isNull(positiveSampleSize)
      ? _.sample(positiveProfileIdsStartTimeFilter, positiveSampleSize)
      : positiveProfileIdsStartTimeFilter;
    updatePositiveProfileIds(samplePositiveProfileIds);
    this.setState({ samplePositiveProfileIds });
  };

  handleChangePositiveSampleSize = async (e, { value }) => {
    this.setState({ positiveSampleSize: value });
    this.samplePositiveProfiles(value);
  };

  sampleNegativeProfiles = (negativeSampleSize) => {
    const { updateNegativeProfileIds } = this.props;
    const { negativeProfileIdsStartTimeFilter } = this.state;
    const negativeProfileIds = !_.isNull(negativeSampleSize)
      ? _.sample(negativeProfileIdsStartTimeFilter, negativeSampleSize)
      : negativeProfileIdsStartTimeFilter;
    updateNegativeProfileIds(negativeProfileIds);
    this.setState({ sampleNegativeProfileIds: negativeProfileIds });
  };

  handleChangeNegativeSampleSize = async (e, { value }) => {
    this.setState({ negativeSampleSize: value });
    this.sampleNegativeProfiles(value);
  };

  onShowProfiles = async () => {
    this.setState({ showProfilesModalVisible: true });
  };

  onCloseShowProfiles = () => {
    this.setState({ showProfilesModalVisible: false });
  };

  render() {
    const { selectedClient, selectedMissionId } = this.props;
    const {
      negativeProfileIds,
      negativeProfileIdsStartTimeFilter,
      positiveProfileIdsStartTimeFilter,
      showProfilesModalVisible,
      samplePositiveProfileIds,
      positiveSampleSize,
      negativeSampleSize,
      totalNumberOfProfilesInMission,
      totalNumberOfSkippedProfiles,
    } = this.state || {};
    const positiveProfileIds = !_.isUndefined(samplePositiveProfileIds)
      ? samplePositiveProfileIds
      : positiveProfileIdsStartTimeFilter;

    return (
      <div>
        {showProfilesModalVisible ? (
          <ProfilesViewModal
            positiveProfileIds={positiveProfileIds}
            onClose={this.onCloseShowProfiles}
            selectedClient={selectedClient}
            selectedMissionId={selectedMissionId}
          />
        ) : null}
        <Card
          style={{
            width: '100%',
          }}
        >
          <Card.Content>
            <Card.Header>
              <h3> Profiles Selection</h3>
            </Card.Header>
            <br />
            <Card.Description>
              <Grid>
                <Grid.Column width={8}>
                  Total number of profiles added to mission : {totalNumberOfProfilesInMission}
                  <br />
                  <br />
                  <SweetForm onChange={(params) => this.handleChangePositiveStartTime(params)}>
                    <Form.Field>
                      <label>Filter Start Time</label>
                      <Select
                        style={{
                          width: '20%',
                        }}
                        field='positiveProfilesSelectStartTime'
                        placeholder='Filter Start Time'
                        options={getStartTimeOptions()}
                      />
                    </Form.Field>
                    <br />
                  </SweetForm>
                  Total number of profiles after filter :{' '}
                  {positiveProfileIdsStartTimeFilter
                    ? positiveProfileIdsStartTimeFilter.length
                    : 'Waiting for response'}
                  <br />
                  <br />
                  <Form.Select
                    // clearable={true}
                    placeholder='Choose Sample Size'
                    options={sampleSizeOptions}
                    onChange={this.handleChangePositiveSampleSize}
                    value={positiveSampleSize}
                  />
                  <br />
                  {positiveProfileIds
                    ? 'Total number of positive profiles selected :' + (positiveProfileIds || {}).length
                    : null}
                  <br />
                  <Button color='blue' onClick={() => this.onShowProfiles()}>
                    Display Positive Profiles
                  </Button>
                </Grid.Column>

                <Grid.Column width={8}>
                  Total number of profiles skipped from recommendations : {totalNumberOfSkippedProfiles}
                  <br />
                  <br />
                  <SweetForm onChange={(params) => this.handleChangeNegativeStartTime(params)}>
                    <Form.Field>
                      <label>Filter Start Time</label>
                      <Select
                        style={{
                          width: '20%',
                        }}
                        field='negativeProfilesSelectStartTime'
                        placeholder='Filter Start Time'
                        options={getStartTimeOptions()}
                      />
                    </Form.Field>
                    <br />
                  </SweetForm>
                  Total number of profiles after filter :{' '}
                  {negativeProfileIdsStartTimeFilter
                    ? negativeProfileIdsStartTimeFilter.length
                    : 'Waiting for response'}
                  <br />
                  <br />
                  <Form.Select
                    // clearable={true}
                    placeholder='Choose Sample Size'
                    options={sampleSizeOptions}
                    onChange={this.handleChangeNegativeSampleSize}
                    value={negativeSampleSize}
                  />
                  <br />
                  {negativeProfileIds
                    ? 'Total number of negative profiles selected :' + (negativeProfileIds || {}).length
                    : null}
                </Grid.Column>
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

export default ProfilesSampleSelection;
