import { Segment, Divider, Label, Tab } from 'semantic-ui-react';
import React from 'react';
import { formatDisciplines, formatEntities, Entities, Disciplines, ListProperties, rowStyle, columnStyle } from './utils';
import { formatBullets } from './XG1FormattedProfile'

const Meta = ({meta}) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Meta
    </Divider>
      <div style={rowStyle}>
        <div style={{ ...columnStyle, width: '50%' }}>
          <p><b>latitude, longitude : </b>{meta.latitude}, {meta.longitude}</p>
        </div>
        <div style={{ ...columnStyle, width: '50%' }}>
        <ListProperties 
          object={meta} 
          properties={["function", "category", "sector"]}
          />
        </div>
    </div>
  </div>
)

const Skills = ({skills, nbSkills}) => (
  <div>
    <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      Skills ({nbSkills})
    </Divider>
    <div>
      <Entities entities={skills.entities}/>
    </div>
    <p>{JSON.stringify(skills.texts, null, 4)}</p>
  </div>
)

const Content = ({content, formattedContent, contentHtml}) => {
  const panes = [
    ...(contentHtml ? [{
      menuItem: 'HTML',
      render: () => <Tab.Pane attached={false}><div dangerouslySetInnerHTML={{ __html: contentHtml || '' }} /></Tab.Pane>,
    }] : []),
    ...(formattedContent ? [{
      menuItem: 'Manual',
      render: () => <Tab.Pane attached={false}><p style={{whiteSpace: "pre-line"}}>{formattedContent}</p></Tab.Pane>,
    }] : []),
    ...(content ? [{
      menuItem: 'Raw',
      render: () => <Tab.Pane attached={false}><p style={{whiteSpace: "pre-line"}}>{formatBullets(content)}</p></Tab.Pane>,
    }] : []),
  ]
  return (
    <div>
        <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
          Content
        </Divider>
        <Tab menu={{ secondary: true }} panes={panes} />
      </div>
  )
}
const XG1FormattedJob = ({formattedJob}) => {
  return (
    <div>
      <Label>{formattedJob.id}</Label>
      <p style={{fontSize:20, fontWeight: 'bold'}}>{(formattedJob.title || {}).text} @ {formattedJob.company}</p>
      <b>Creation : {formattedJob.creation}</b>
      <div style={rowStyle}>
        <div style={{ ...columnStyle, width: '33%' }}>
          <div style={{ fontWeight: 'bold' }}>Title Entities</div>
          <Entities entities={(formattedJob.title || {}).entities}/>
        </div>
        <div style={{ ...columnStyle, width: '33%' }}>
          <div style={{ fontWeight: 'bold' }}>Disciplines</div>
          <Disciplines disciplines={formattedJob.disciplines}/>
        </div>
        <div style={{ ...columnStyle, width: '33%' }}>
          <div style={{ fontWeight: 'bold' }}>Sub-disciplines</div>
          <Disciplines disciplines={formattedJob.subDisciplines}/>
        </div>
      </div>
      <div>
        <Skills 
          skills={formattedJob.skills || []} 
          nbSkills={formattedJob.meta.nbSkills}
        />
      </div>
      <div>
        <Content
          content={formattedJob.content}
          contentHtml={formattedJob.contentHtml}
          formattedContent={formattedJob.formattedContent}
        />
      </div>
      <div>
        <Meta meta={formattedJob.meta || {}} />
      </div>
    </div>
  )
}
export default XG1FormattedJob