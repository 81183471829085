import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import React, { Component } from 'react';

import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class EntityFunctionInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadEntities();
  }

  loadEntities = async () => {
    const url = baseUrl + '/techAsset/getEntities';
    const entities = (await axios.get(url)).data;
    this.setState({ entities });
  };

  async handleSubmit(value) {
    this.props.onSubmit(value);
  }

  render() {
    const { value } = this.props;
    const { entities } = this.state || {};
    const entitiesOptions = _.map(entities, (entity) => ({
      value: entity.id,
      text: entity.name,
      key: entity.id,
    }));
    return (
      <div>
        <Form.Select
          search
          placeholder='Select entity id'
          options={entitiesOptions}
          onChange={(e, { value }) => {
            this.handleSubmit(value);
          }}
          value={value}
        />
      </div>
    );
  }
}

export default EntityFunctionInput;
