import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Card, Icon, Button, Form } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class PoolDashboard extends React.Component {
  state = {};

  componentDidMount() {
    this.handleLoadPools();
  }

  handleLoadPools = async () => {
    const url = baseUrl + '/fastSearch/pools';
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const pools = data.pools;
    this.setState({ pools });
  };

  handlePoolNameChange = async (value) => {
    this.setState({ newPoolName: value });
  };

  handleNewPool = async () => {
    if (this.state.newPoolName) {
      const url = baseUrl + '/fastSearch/pools';
      const { data } = await axios.post(url, { name: this.state.newPoolName });
      if (data.error) {
        return alert(data.error);
      }
      this.handleLoadPools();
    }
  };

  render() {
    const { pools } = this.state;
    if (!pools) {
      return <div />;
    }

    const nbColumns = 2;
    const poolsRows = [[]];
    _.each(pools, (pool) => {
      if (_.last(poolsRows).length === nbColumns) {
        poolsRows.push([]);
      }
      _.last(poolsRows).push(pool);
    });

    return (
      <Grid>
        {_.map(poolsRows, (poolRow, index) => (
          <Grid.Row key={index} columns={nbColumns}>
            {_.map(poolRow, (pool, index) => (
              <Grid.Column key={index}>
                <Card fluid>
                  <Card.Content>
                    <Card.Header>
                      {pool.name}{' '}
                      <Icon
                        name={pool.status === 'SEARCHABLE' ? 'check circle' : 'warning clock'}
                        color={pool.status === 'SEARCHABLE' ? 'green' : 'grey'}
                        size='large'
                      />
                    </Card.Header>
                    <Card.Meta>
                      <span>{pool.type}</span>
                    </Card.Meta>
                    <Card.Description>
                      <a href={'/ml/fast-search/manage/' + pool.id}>
                        <Button>Manage</Button>
                      </a>
                      <a href={'/ml/fast-search/search/' + pool.id}>
                        <Button>Search</Button>
                      </a>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
        <Grid.Row key={poolsRows.length} columns={nbColumns}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Description>
                  <Form>
                    <Form.Group inline>
                      <Form.Input onChange={(e, { value }) => this.handlePoolNameChange(value)} width={10} />
                      <Button onClick={this.handleNewPool}>New pool</Button>
                    </Form.Group>
                  </Form>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default PoolDashboard;
