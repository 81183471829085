import axios from 'axios';
import React from 'react';
import baseUrl from '../../baseUrl.js';
import _ from 'underscore';
import { List, Icon } from 'semantic-ui-react';

export default class ParsedLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enrichedMatches: [], manuallyValidated: false };
  }

  componentDidMount = async () => {
    if (this.props.text) {
      const response = await axios
        .post(`${baseUrl}/locationsV2/extractMatches`, { text: this.props.text })
        .catch((error) => alert(error));
      if (response) {
        const { matches, manuallyValidated } = response.data;
        const enrichedMatches = await this.enrichMatches(matches);
        this.setState({ enrichedMatches: enrichedMatches, manuallyValidated });
      }
    }
  };

  fetchLocation = async (locationId) => {
    const response = await axios.get(`${baseUrl}/locationsV2/location/${locationId}`);
    return response.data.location;
  };

  enrichMatches = async (matches) => {
    const enrichedMatches = await Promise.all(
      _.map(matches, async (match) => ({
        ...match,
        weightedLocations: await Promise.all(
          _.map(match.weightedLocations, async (weightedLocation) => ({
            ...weightedLocation,
            location: await this.fetchLocation(weightedLocation.location),
          })),
        ),
      })),
    );
    return enrichedMatches;
  };

  render() {
    console.log(this.state.enrichedMatches);
    return (
      <List>
        {_.map(this.state.enrichedMatches, (match, matchIndex) => (
          <List.Item key={matchIndex}>
            <List>
              {_.map(match.weightedLocations, (weightedLocation, weightedLocationIndex) => (
                <List.Item key={weightedLocationIndex}>
                  <a href={`/monitoring/knowledge/locations_v2/${weightedLocation.location.id}`}>
                    <Icon name="check circle outline" color={this.state.manuallyValidated ? 'green' : 'orange'} />
                    {weightedLocation.location.rawName}
                  </a>
                </List.Item>
              ))}
            </List>
          </List.Item>
        ))}
        {this.state.enrichedMatches.length === 0 ? (
          <List.Item>
            <Icon name="close" color="red" />
            No mapped location
          </List.Item>
        ) : null}
        {!this.state.manuallyValidated && this.state.enrichedMatches.length > 0 ? (
          <List.Item>
            <a href={`/monitoring/knowledge/location_mappings/${this.props.text}`}> Fix this mapping</a>
          </List.Item>
        ) : null}
      </List>
    );
  }
}
