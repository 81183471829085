import React, { Component } from 'react';
import { Grid, Form } from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import ProfileView from '../SweetComponents/Profile/View';
import HiresweetProfile from '../components/HiresweetProfile';
import LinkedinEditorModal from '../DataPointSandbox/LinkedinEditorModal';
import HiresweetEditorModal from '../DataPointSandbox/HiresweetEditorModal';
import _ from 'underscore';

class ProfileFunctionInput extends Component {
  handleClickOnEdit = async (editMode) => {
    this.setState({ editMode, key: '' + Math.random() });
  };

  handleClickOnSample = async () => {
    const { propertyTest, samplingBase } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/randomProfile';
    const sampleWithProperty =
      ((propertyTest || {}).property || {}).constraints ||
      (samplingBase && samplingBase.samplingMode !== 'all-profiles');
    if (!sampleWithProperty) {
      const randomProfileJSON = (await axios.get(url, customHeaders)).data;
      this.handleSubmitLinkedin(randomProfileJSON);
    } else {
      const payload = { test: propertyTest, samplingBase };
      const randomProfileJSON = (await axios.post(url + 'WithProperty', payload, customHeaders)).data;
      if (!randomProfileJSON || randomProfileJSON.success === false) {
        alert('No Profile found with this property while sampling 1000');
      }
      this.handleSubmitLinkedin(randomProfileJSON);
    }
  };

  handleClickOnSampleMatchAnywhere = async () => {
    const { propertyTest, samplingBase } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/matchAnywhereProfile';
    const pattern = (this.state || {}).pattern || 'coffee';
    const payload = { pattern: pattern, test: propertyTest, samplingBase };
    const randomProfileJSON = (await axios.post(url, payload, customHeaders)).data;
    if (!randomProfileJSON || randomProfileJSON.success === false) {
      alert('No profile found with pattern ' + pattern);
    }
    this.handleSubmitLinkedin(randomProfileJSON.data);
  };

  handleClickOnLoadFromSource = async () => {
    this.setState({ loadFromSourceModalOpen: true });
  };

  onClose = async () => {
    this.setState({
      editMode: null,
    });
  };

  rawDateToScore(rawDate) {
    return 100;
  }

  priorityEducation(education) {
    if (!education.RawDates) {
      return 0;
    }
  }

  priorityExperience(experience) {
    // case no info : should be display in first position
    if (!experience.RawDates || experience.RawDates.length == 0) {
      return 0;
    }
    // case xp closed : should be display in second position
    if (experience.RawDates.length == 1) {
      return this.rawDateToScore(experience.RawDates[0]) + 100000;
    }
    // case xp open : should be display in last position
    if (experience.RawDates.length == 1) {
      return this.rawDateToScore(experience.RawDates[0]) + 100000;
    }
    return 0;
  }

  handleSubmitLinkedin = async (profileJSON) => {
    this.setState({
      editMode: null,
    });
    const hiresweet = (((this.props || {}).rawEntity || {}).sourceProfiles || {}).hiresweet;
    const requestEntity = {
      sourceProfiles: {
        linkedin: profileJSON,
        ...(hiresweet && { hiresweet }),
      },
    };
    this.props.onSubmit(requestEntity);
  };
  handleSubmitHiresweet = async (profileJSON) => {
    this.setState({
      editMode: null,
    });
    const linkedin = (((this.props || {}).rawEntity || {}).sourceProfiles || {}).linkedin || {};
    const requestEntity = {
      sourceProfiles: {
        hiresweet: (profileJSON || {}).data,
        ...(linkedin && { linkedin }),
      },
    };
    this.props.onSubmit(requestEntity);
  };

  handlePatternChange = async (value) => {
    this.setState({
      pattern: value,
    });
  };

  render() {
    const { enrichedEntity } = this.props;
    const linkedinData = ((((this.props || {}).rawEntity || {}).sourceProfiles || {}).linkedin || {}).data || {};
    const hiresweetProfile = (((this.props || {}).rawEntity || {}).sourceProfiles || {}).hiresweet;
    const key = (this.state || {}).key || '' + Math.random();
    var linkedinId = ((enrichedEntity || {}).sources || {}).linkedin
      ? ((enrichedEntity || {}).sources || {}).linkedin
      : '';
    linkedinId = _.last(linkedinId.split('/'));

    return (
      <div>
        {linkedinId ? <h3>{'Linkedin Id : ' + linkedinId}</h3> : null}
        {(this.state || {}).editMode == 'linkedin' ? (
          <LinkedinEditorModal
            key={key}
            onClose={this.onClose}
            onSave={this.handleSubmitLinkedin}
            initialValue={linkedinData}
          />
        ) : (this.state || {}).editMode == 'hiresweet' ? (
          <HiresweetEditorModal
            key={key}
            onClose={this.onClose}
            onSave={this.handleSubmitHiresweet}
            initialValue={hiresweetProfile}
          />
        ) : (
          <div>
            <Grid columns={4}>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnEdit('linkedin')}>
                  Edit Linkedin
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnEdit('hiresweet')}>
                  Edit Hiresweet
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSample()}>
                  Sample Profile
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSampleMatchAnywhere()}>
                  Sample Profile (Match Anywhere)
                </a>
                <Form.TextArea
                  style={{ width: '160px', height: '15px' }}
                  value={(this.state || {}).pattern || ''}
                  onChange={(e, { value }) => {
                    this.handlePatternChange(value);
                  }}
                />
              </Grid.Column>
            </Grid>
            {hiresweetProfile && <HiresweetProfile hiresweetProfile={hiresweetProfile} />}
            <ProfileView data={enrichedEntity} />
          </div>
        )}
      </div>
    );
  }
}

export default ProfileFunctionInput;
