import React, { Component } from 'react';
import { Table, Segment } from 'semantic-ui-react';
import _ from 'underscore';

class ShapImportances extends Component {
  state = {
    ascending: false,
  };

  setAscending = ({ value }) => {
    this.setState({ ascending: value });
  };


  render() {
    const { shapImportances } = this.props;
    const ascending = !!this.state.ascending;
    const sortedShapImportances = _.sortBy(shapImportances || [], ({ shapValue }) => (ascending ? shapValue : -shapValue))
    if (_.isEmpty(sortedShapImportances)) {
      return <div />
    }
    return (
      <Segment>
        <div
        style={{
          display: 'inline-block',
          height: '300px',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'scroll',
        }}
        >
        <p style={{textAlign: 'right'}}>
          <span style={{ cursor: 'pointer' }} onClick={() => this.setAscending({ value: true })}>
            <span style={ascending ? { color: 'blue' } : {}}>
              Ascending
            </span>
          </span>
          {' - '}
          <span style={{ cursor: 'pointer' }} onClick={() => this.setAscending({ value: false })}>
            <span style={!ascending ? { color: 'blue' } : {}}>
              Descending
            </span>
          </span>

        </p>
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Feature</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>Shap</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(sortedShapImportances, ({ featureName, featureValue, shapValue }, key) => (
              <Table.Row key={key}>
                <Table.Cell>{featureName}</Table.Cell>
                <Table.Cell>{Math.round(featureValue * 100) / 100}</Table.Cell>
                <Table.Cell>{Math.round(shapValue * 100) / 100}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      </Segment>
      
    );
  }
}

export default ShapImportances;
