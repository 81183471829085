import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Button, Segment, Card } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import { CommunicationLine } from "./CommunicationLine.js"

import moment from 'moment';

const NotConnectedEngagementContext = ({ engagementContext }) => {
  const { attempsDetails } = engagementContext || {};
  console.log("not connected")
  console.log({ engagementContext })
  return (
    <Segment color='red'>
      {_.map(attempsDetails, (communication) => {
        return <CommunicationLine communication={communication} />;
      })}
    </Segment>
  )
}


const ConnectedEngagementContext = ({ engagementContext }) => {
  const { details } = engagementContext || {};
  const { body, subject } = details || {};
  return (
    <Segment color='green'>
      <CommunicationLine communication={details} />
      <div>
        {subject && (<b>{subject}<br /></b>)}
        {body}
      </div>
    </Segment>
  )
}





class LastEngagementsContext extends React.Component {
  state = {};

  componentDidMount = async () => {
    try {
      const { dealId, hubspotCompanyId } = this.props;
      let engagementsContext;
      if (dealId) {
        engagementsContext = ((await axios.post(`${baseUrl}/salesOptimisationApi/dealEngagementsContext`, { dealId })).data || {}).results;
      } else if (hubspotCompanyId) {
        engagementsContext = ((await axios.post(`${baseUrl}/salesOptimisationApi/companyEngagementsContext`, { hubspotCompanyId })).data || {}).results;
      }
      console.log({ engagementsContext })
      this.setState({
        engagementsContext,
      })
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  };


  render() {
    const { engagementsContext } = this.state;
    if (!engagementsContext) {
      return <div>Loading...</div>
    }
    return (
      <div>
        {_.map(engagementsContext, (engagementContext) => {

          if (engagementContext.type == "not_connected") {
            return <NotConnectedEngagementContext engagementContext={engagementContext} key={Math.random()} />
          } else {
            return <ConnectedEngagementContext engagementContext={engagementContext} key={Math.random()} />
          }
        })
        }
      </div>
    );
  }
}


export default LastEngagementsContext;
