import React from 'react';
import { getFullUrlWithHttp } from '../../common';

export const ExtLink = ({ to, children, classes }) => (
  <a
    className={classes ? classes : ''}
    target='_blank'
    onClick={(e) => e.stopPropagation()}
    href={getFullUrlWithHttp(to)}
    rel='noopener noreferrer'
  >
    {children}
  </a>
);
