
export const getPercent = (x) => {
  const percent = '' + (Math.round(x*10000)/100);

  return percent.indexOf('.') > 0 ? (
    percent.split('.')[1].length === 1 ? (
      percent + '0'
    ) : (
      percent 
    )
  ) : (
    percent + '.00'
  );
};
