import React from 'react';
import { Criteria } from '../../components/OfferV5';
import _ from 'underscore';
import { Segment } from 'semantic-ui-react';

const OfferCriteria = ({ criteria }) => {
  if (!criteria || _.isEmpty(criteria)) {
    return null;
  }
  return (
    <Segment color='green'>
      <h2>Criteria</h2>
      <Criteria criteria={criteria} />
    </Segment>
  );
};

export default OfferCriteria;
