import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

import { Button, Form, Grid } from 'semantic-ui-react';

import CreateMissionModal from './CreateMissionModal.js';
import AutoRecommendationsTable from './AutoRecommendationsTable.js';
import ProfileActivityDisplay from './ProfileActivityDisplay';
import { DetailedProfileView } from '../TalentReveal/Talents.js';

class AutoRecommendations extends Component {
  componentDidMount() {
    this.setTargetMissions();
  }

  setTargetMissions = async () => {
    const { selectedClient } = this.props || {};
    const url = baseUrl + '/revealRecommendations/getTargetMissions/' + selectedClient.id;
    const targetMissions = (await axios.get(url)).data;
    this.setState({ targetMissions });
  };

  createTargetMission = async (params) => {
    const url = baseUrl + '/revealRecommendations/createTargetMission';
    const { data } = await axios.post(url, params);
    return data;
  };

  selectMission = async (missionId) => {
    const { targetMissions } = this.state || {};
    const selectedMission = _.find(targetMissions, (mission) => mission.id === missionId);
    await this.getRecommendations(missionId);
    this.setState({ selectedMission });
  };

  onSubmitCreateMission = async (params) => {
    const { selectedClient } = this.props || {};
    params.clientId = selectedClient.id;
    params.isStarred = false;
    if (_.isUndefined(params)) {
      this.setState({ createMissionModalVisible: false });
      return;
    }
    if (_.isUndefined(params.id) || _.isEmpty(params.id)) {
      alert('Mission id should be specified.');
      return;
    }
    const data = await this.createTargetMission(params);
    if (data.error) {
      alert(data.error);
      return;
    }
    this.setTargetMissions(selectedClient.id);
    await this.selectMission(params.id);
    this.setState({ createMissionModalVisible: false });
  };

  onCancelCreateMission = () => {
    this.setState({ createMissionModalVisible: false });
  };

  handleSelectMission = async (e, { value }) => {
    if (value === 'new-mission') {
      this.setState({ createMissionModalVisible: true });
      return;
    }
    await this.selectMission(value);
  };

  getRecommendationsByStatus = async (missionId, status) => {
    const { selectedClient } = this.props || {};
    const profilesCollectionId = (selectedClient || {}).profilesCollectionId;
    const url =
      baseUrl + '/revealRecommendations/getRecommendations/' + profilesCollectionId + '/' + missionId + '/' + status;
    const recommendationsByStatus = (await axios.get(url)).data;
    const recommendations = _.map(recommendationsByStatus, (recommendation) => ({
      id: recommendation.id,
      profileId: recommendation.profileId,
      status,
    }));
    return recommendations;
  };

  getRecommendations = async (missionId) => {
    const activeRecommendations = await this.getRecommendationsByStatus(missionId, 'active');
    const pendingRecommendations = await this.getRecommendationsByStatus(missionId, 'pending');
    const prePendingRecommendations = await this.getRecommendationsByStatus(missionId, 'pre-pending');
    const recommendations = activeRecommendations.concat(pendingRecommendations).concat(prePendingRecommendations);
    this.setState({ recommendations });
  };

  updateRecommendationStatus = async (recommendation, newStatus) => {
    const { selectedClient } = this.props || {};
    const { selectedMission } = this.state || {};
    const params = {
      profilesCollectionId: (selectedClient || {}).profilesCollectionId,
      missionId: selectedMission.id,
      profileId: recommendation.profileId,
      recommendationId: recommendation.id,
      newStatus,
    };
    const url = baseUrl + '/revealRecommendations/updateRecommendationStatus';
    const { data } = await axios.post(url, params);
    if (!data.success) {
      alert(data.error);
      return;
    }
    await this.getRecommendations(selectedMission.id);
  };

  clickOnUpdateStatus = async (recommendation) => {
    const oldStatus = recommendation.status;
    const newStatus = oldStatus === 'pre-pending' ? 'pending' : 'pre-pending';
    await this.updateRecommendationStatus(recommendation, newStatus);
  };

  setPendingRecommendationsAsActive = async () => {
    const { recommendations } = this.state || {};
    const pendingRecommendations = _.filter(recommendations, (reco) => reco.status === 'pending');
    await Promise.all(
      _.map(pendingRecommendations, (recommendation) => {
        this.updateRecommendationStatus(recommendation, 'active');
        return;
      }),
    );
  };

  onClickOnSelectProfile = async (profileId) => {
    const { selectedClient } = this.props;
    const profilesCollectionId = (selectedClient || {}).profilesCollectionId;
    const url = baseUrl + '/sweetchain/getProfilePath/' + profilesCollectionId + '/' + profileId;
    const selectedProfilePath = (await axios.get(url)).data;
    this.setState({ selectedProfileId: profileId, selectedProfilePath });
  };

  removeRecommendation = async (profileId, recommendationId, selectedClient) => {
    const profilesCollectionId = (selectedClient || {}).profilesCollectionId;
    const params = {
      profilesCollectionId,
      profileId,
      recommendationId,
    };
    const url = baseUrl + '/revealRecommendations/removeRecommendation';
    const { data } = await axios.post(url, params);
    if (data.error) {
      alert(data.error);
    }
  };

  hideProfileForMission = async (profileId, selectedClient, selectedMission) => {
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMission.id,
      profileId,
      snoozedUntilTimestamp: undefined,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/hideProfileForMission';
    const { data } = await axios.post(url, params);
    if (data.error) {
      alert(data.error);
    }
  };

  removeRecommendationAndHideForMission = async (recommendation) => {
    const { selectedClient } = this.props;
    const { selectedMission } = this.state || {};
    const profileId = recommendation.profileId;
    const recommendationId = recommendation.id;
    await this.hideProfileForMission(profileId, selectedClient, selectedMission);
    await this.removeRecommendation(profileId, recommendationId, selectedClient);
    await this.getRecommendations(selectedMission.id);
  };

  render() {
    const { selectedClient } = this.props || {};
    const {
      createMissionModalVisible,
      targetMissions,
      selectedMission,
      recommendations,
      selectedProfileId,
      selectedProfilePath,
    } = this.state || {};

    const sortedTargetMissions = _.sortBy(targetMissions, (mission) => mission.name.toLowerCase());
    const missionOptions = _.map(sortedTargetMissions || [], (mission, key) => ({
      value: mission.id,
      key: key,
      text: mission.name ? mission.name : mission.title,
    })).concat([
      {
        value: 'new-mission',
        key: 'new-mission',
        text: '+ Create Mission',
      },
    ]);
    return (
      <div>
        {createMissionModalVisible ? (
          <CreateMissionModal
            onSubmit={this.onSubmitCreateMission}
            onCancel={this.onCancelCreateMission}
            selectedClientJobsCollectionId={selectedClient.jobsCollectionId}
          />
        ) : null}
        <Grid>
          <Grid.Row>
            <Form.Select
              search
              placeholder='Missions'
              options={missionOptions}
              onChange={this.handleSelectMission}
              value={(selectedMission || {}).id}
            />
          </Grid.Row>
          {!_.isEmpty(recommendations) ? (
            <Button color='green' onClick={() => this.setPendingRecommendationsAsActive()}>
              Set Pending as Active
            </Button>
          ) : null}
          <Grid.Row>
            {!_.isEmpty(recommendations) ? (
              <Grid.Column width={5}>
                <AutoRecommendationsTable
                  recommendations={recommendations}
                  selectedProfileId={selectedProfileId}
                  clickOnUpdateStatus={this.clickOnUpdateStatus}
                  onClickOnSelectProfile={this.onClickOnSelectProfile}
                  removeRecommendationAndHideForMission={this.removeRecommendationAndHideForMission}
                />
              </Grid.Column>
            ) : null}
            <Grid.Column
              width={5}
              style={{
                maxHeight: '600px',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {!_.isEmpty(recommendations) && selectedProfileId ? (
                <ProfileActivityDisplay
                  key={selectedProfileId}
                  selectedProfileId={selectedProfileId}
                  selectedClient={selectedClient}
                  selectedMissionId={selectedMission.id}
                />
              ) : null}
            </Grid.Column>
            <Grid.Column width={6}>
              {!_.isEmpty(recommendations) && selectedProfileId ? (
                <div>
                  <h3> Detailed Profile View</h3>
                  <DetailedProfileView key={selectedProfilePath} profilePath={selectedProfilePath} />
                </div>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default AutoRecommendations;
