import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../../baseUrl.js';
import React from 'react';
import { Grid, Table, Icon } from 'semantic-ui-react';
import WatcherEditionModal from './WatcherEditionModal';
import ManualWatcherEditionModal from './ManualWatcherEditionModal.js';
import WatcherDeletionModal from './WatcherDeletionModal';
import { DEFAULT_CATEGORY, DEFAULT_SERVICE } from '../Dashboard.js';
import humanize from 'humanize-duration';

const getRandomString = (size) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let text = '';
  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const WatchersTable = ({
  watchers,
  editionEnabled,
  handleOpenWatcherEditionModal,
  handleOpenWatcherCreationModal,
  handleOpenWatcherDeletionModal,
}) => {
  if (!watchers) {
    return <div>Loading...</div>;
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>owner</Table.HeaderCell>
          <Table.HeaderCell>id</Table.HeaderCell>
          <Table.HeaderCell>category</Table.HeaderCell>
          <Table.HeaderCell>service</Table.HeaderCell>
          <Table.HeaderCell>name</Table.HeaderCell>
          <Table.HeaderCell>checker</Table.HeaderCell>
          <Table.HeaderCell>schedule</Table.HeaderCell>
          <Table.HeaderCell>dispatchers</Table.HeaderCell>
          {editionEnabled && <Table.HeaderCell textAlign='center'>edit</Table.HeaderCell>}
          {editionEnabled && <Table.HeaderCell textAlign='center'>delete</Table.HeaderCell>}
          <Table.HeaderCell textAlign='center'>fork</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(watchers, (watcher, index) => (
          <Table.Row key={index + '_' + watcher.id}>
            <Table.Cell>{watcher.username}</Table.Cell>
            <Table.Cell>{watcher.id}</Table.Cell>
            <Table.Cell>{watcher.category || DEFAULT_CATEGORY}</Table.Cell>
            <Table.Cell>{watcher.service || DEFAULT_SERVICE}</Table.Cell>
            <Table.Cell>{watcher.name}</Table.Cell>
            <Table.Cell>
              {(watcher.checker || {}).type}
              {(watcher.checker || {}).maxDelay && (
                <span>
                  <br />
                  less than {(watcher.checker || {}).maxDelay && humanize(watcher.checker.maxDelay)}
                </span>
              )}
            </Table.Cell>
            <Table.Cell>
              run every {(watcher.schedule || {}).interval && humanize((watcher.schedule || {}).interval)}
              {((watcher.schedule || {}).dayIntervalStr || (watcher.schedule || {}).hourIntervalStr) && (
                <span>
                  <br />
                  {(watcher.schedule || {}).dayIntervalStr &&
                    `days: ${(watcher.schedule || {}).dayIntervalStr} - hours: ${
                      (watcher.schedule || {}).hourIntervalStr
                    }`}
                </span>
              )}
            </Table.Cell>

            <Table.Cell>{(watcher.messageDispatcherIds || []).join('/')}</Table.Cell>
            {editionEnabled && (
              <Table.Cell>
                <Icon link name='pencil' onClick={() => handleOpenWatcherEditionModal({ watcher })} />
                <center />
              </Table.Cell>
            )}
            {editionEnabled && (
              <Table.Cell>
                <center>
                  <Icon link name='close' onClick={() => handleOpenWatcherDeletionModal({ watcher })} />
                </center>
              </Table.Cell>
            )}
            <Table.Cell>
              <center>
                <Icon link name='fork' onClick={() => handleOpenWatcherCreationModal({ watcher })} />
              </center>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

class WatchersAdministrationBoard extends React.Component {
  state = {
    loading: true,
    customWatchers: undefined,
    baseWatchers: undefined,
  };

  handleLoadCustomWatchers = async () => {
    const { data } = await axios.get(`${baseUrl}/sentry/customWatchers`);
    if (data.error) {
      return alert(data.error);
    }
    this.setState({
      customWatchers: data,
    });
  };

  handleLoadBaseWatchers = async () => {
    const { data } = await axios.get(`${baseUrl}/sentry/baseWatchers`);
    if (data.error) {
      return alert(data.error);
    }
    this.setState({
      baseWatchers: data,
    });
  };

  handleOpenWatcherCreationModal = ({ watcher, checkerType }) => {
    this.setState({
      modal: {
        type: 'watcher-creation',
        checkerType: checkerType || ((watcher || {}).checker || {}).type,
        watcher: {
          ...watcher,
          id: getRandomString(6),
          name: `Copy of ${watcher.name}`,
          creationTimestamp: Date.now(),
        },
      },
    });
  };

  handleOpenWatcherEditionModal = ({ watcher }) => {
    this.setState({
      modal: {
        checkerType: ((watcher || {}).checker || {}).type,
        type: 'watcher-edition',
        watcher: watcher,
      },
    });
  };

  handleOpenWatcherDeletionModal = ({ watcher }) => {
    this.setState({
      modal: {
        type: 'watcher-deletion',
        watcher: watcher,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({
      modal: undefined,
    });
  };

  handleSaveEditedWatcher = async ({ watcher }) => {
    try {
      if (!watcher.id) {
        throw Error('need watcher id');
      }
      const { data } = await axios.put(`${baseUrl}/sentry/customWatchers/${watcher.id}`, { watcher });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoadCustomWatchers();
    } catch (e) {
      return alert(e.message);
    }
  };

  handleSaveCreatedWatcher = async ({ watcher }) => {
    try {
      if (!watcher.id) {
        throw Error('need watcher id');
      }
      const { data } = await axios.post(`${baseUrl}/sentry/customWatchers`, { watcher });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoadCustomWatchers();
    } catch (e) {
      return alert(e.message);
    }
  };

  handleDeleteWatcher = async ({ watcher }) => {
    try {
      if (!watcher.id) {
        throw Error('need watcher id');
      }
      const { data } = await axios.delete(`${baseUrl}/sentry/customWatchers/${watcher.id}`);
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoadCustomWatchers();
    } catch (e) {
      return alert(e.message);
    }
  };

  componentDidMount() {
    this.handleLoadBaseWatchers();
    this.handleLoadCustomWatchers();
  }

  render() {
    const { customWatchers, baseWatchers, modal } = this.state;
    const existingCategories = _.uniq(_.compact(_.pluck(_.union(customWatchers, baseWatchers), 'category')));
    const existingServices = _.uniq(_.compact(_.pluck(_.union(customWatchers, baseWatchers), 'service')));
    const existingDispatchers = _.uniq(
      _.compact(_.flatten(_.pluck(_.union(customWatchers, baseWatchers), 'messageDispatcherIds'))),
    );

    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <h3>Custom Watchers</h3>
            </Grid.Column>
            <Grid.Column width={4} textAlign='right'>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => this.handleOpenWatcherCreationModal({ watcher: {}, checkerType: 'manual-check-delay' })}
              >
                Add manual watcher
              </a>
            </Grid.Column>
            <Grid.Column width={4} textAlign='right'>
              <a style={{ cursor: 'pointer' }} onClick={() => this.handleOpenWatcherCreationModal({ watcher: {} })}>
                Add custom watcher
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <WatchersTable
              watchers={customWatchers}
              editionEnabled={true}
              handleOpenWatcherEditionModal={this.handleOpenWatcherEditionModal}
              handleOpenWatcherCreationModal={this.handleOpenWatcherCreationModal}
              handleOpenWatcherDeletionModal={this.handleOpenWatcherDeletionModal}
            />
          </Grid.Row>
          <Grid.Row>
            <h3>Base Watchers</h3>
          </Grid.Row>
          <Grid.Row>
            <WatchersTable
              watchers={baseWatchers}
              editionEnabled={false}
              handleOpenWatcherCreationModal={this.handleOpenWatcherCreationModal}
            />
          </Grid.Row>
        </Grid>

        {(modal || {}).type === 'watcher-edition' &&
          ((modal || {}).checkerType === 'manual-check-delay' ? (
            <ManualWatcherEditionModal
              onClose={this.handleCloseModal}
              watcher={modal.watcher}
              onSave={this.handleSaveEditedWatcher}
              existingCategories={existingCategories}
              existingServices={existingServices}
              existingDispatchers={existingDispatchers}
            />
          ) : (
            <WatcherEditionModal
              onClose={this.handleCloseModal}
              watcher={modal.watcher}
              onSave={this.handleSaveEditedWatcher}
            />
          ))}

        {(modal || {}).type === 'watcher-creation' &&
          ((modal || {}).checkerType === 'manual-check-delay' ? (
            <ManualWatcherEditionModal
              onClose={this.handleCloseModal}
              watcher={modal.watcher}
              onSave={this.handleSaveCreatedWatcher}
              existingCategories={existingCategories}
              existingServices={existingServices}
              existingDispatchers={existingDispatchers}
            />
          ) : (
            <WatcherEditionModal
              onClose={this.handleCloseModal}
              watcher={modal.watcher}
              onSave={this.handleSaveCreatedWatcher}
            />
          ))}

        {(modal || {}).type === 'watcher-deletion' && (
          <WatcherDeletionModal
            onClose={this.handleCloseModal}
            watcher={modal.watcher}
            onDelete={this.handleDeleteWatcher}
          />
        )}
      </div>
    );
  }
}

export default WatchersAdministrationBoard;
