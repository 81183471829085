import React from 'react';
import _ from 'underscore';
import { Grid, Card, Icon, Table, Label, Image } from 'semantic-ui-react';
import EditOfferModal from './EditOfferModal';
import PushCriteriaModal from './PushCriteriaModal';
import { Link } from 'react-router-dom';

class OffersOverview extends React.Component {
  renderWarnings() {
    return null;
  }
  render() {
    const {
      offers,
      workplace,
      editMode,
      onOpenAddOffer,
      onOpenDuplicateOffer,
      onOpenEditOffer,
      onClose,
      onSubmitAddOffer,
      onSubmitEditOffer,
      editedOffer,
      onDeleteOffer,
      onSubmitPushCriteria,
      onOpenPushCriteriaModal,
      onSetIsActive,
    } = this.props;

    // filter offers to show only offers for the selected workplace
    const filteredOffers = workplace
      ? _.filter(offers, (offer) => offer.companyId === workplace.id)
      : [];
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            {this.renderWarnings()}
            <Grid>
              <Grid.Row>
                <Grid.Column width={3} verticalAlign="middle">
                  <h3>
                    Offers
                    {workplace ? ' (' + workplace.name + ') ' : ' '}
                    {workplace && (
                      <Icon
                        name="add circle"
                        color="blue"
                        style={{ cursor: 'pointer' }}
                        onClick={onOpenAddOffer}
                      />
                    )}
                  </h3>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {editMode === 'addOffer' ? (
              <EditOfferModal
                initialValues={{ companyId: workplace.id, sales: workplace.sales }}
                onClose={onClose}
                onSubmit={onSubmitAddOffer}
              />
            ) : null}
            {editMode === 'duplicateOffer' ? (
              <EditOfferModal
                initialValues={_.omit(editedOffer, 'id', 'platformId', 'title', 'sweetsheetId')}
                onClose={onClose}
                onSubmit={onSubmitAddOffer}
              />
            ): null}
            {editMode === 'editOffer' ? (
              <EditOfferModal
                initialValues={editedOffer}
                onClose={onClose}
                onSubmit={onSubmitEditOffer}
                onDelete={onDeleteOffer}
                onOpenPushCriteria={onOpenPushCriteriaModal}
              />
            ) : null}
            {editMode === 'pushCriteria' ? (
              <PushCriteriaModal
                onClose={onClose}
                onSubmit={onSubmitPushCriteria}
                onOpenEditOffer={onOpenEditOffer}
                platformId={editedOffer.platformId}
                sweethubOffer={editedOffer}
              />
            ) : null}
          </Card.Header>
          <Card.Description>
            {filteredOffers && filteredOffers.length > 0 ? (
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Required Skills</Table.HeaderCell>
                    <Table.HeaderCell>Experience</Table.HeaderCell>
                    <Table.HeaderCell>Users</Table.HeaderCell>
                    <Table.HeaderCell>Sales</Table.HeaderCell>
                    <Table.HeaderCell>Active</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {filteredOffers.map((offer) => {
                    return (
                      <Table.Row key={offer.id}>
                        <Table.Cell>
                          <Icon
                            name="write"
                            size="large"
                            style={{ cursor: 'pointer' }}
                            onClick={() => onOpenEditOffer(offer)}
                            />
                          <Icon
                            name='copy'
                            size='large'
                            style={{cursor: 'pointer'}}
                            onClick={() => onOpenDuplicateOffer(offer)}
                          />
                          {offer.platformId ? (
                            <span>
                              <Icon
                                name="external"
                                size="large"
                                style={{ cursor: 'pointer' }}
                                onClick={() => onOpenPushCriteriaModal(offer)}
                              />
                              <a
                                href={`https://app.hiresweet.com/client/${workplace.platformId}/offers/${offer.platformId}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <Image
                                  src="/images/sweetapp-logo.png"
                                  style={{ width: '16px', height: '16px' }}
                                />
                              </a>
                            </span>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell>
                          <Link to={`/offers/${offer.id}`}>{offer.title}</Link>
                        </Table.Cell>
                        <Table.Cell>
                          {((offer.skills || {}).required || []).join(' ')}
                        </Table.Cell>
                        <Table.Cell>
                          {offer.experience
                            ? `${offer.experience.min}-${offer.experience.max}`
                            : null}
                        </Table.Cell>
                        <Table.Cell>{(offer.users || []).join(' ')}</Table.Cell>
                        <Table.Cell>{(offer.sales || []).join(' ')}</Table.Cell>
                        <Table.Cell
                          onClick={() =>
                            onSetIsActive(offer.id, !offer.isActive)
                          }
                        >
                          <Label color={offer.isActive ? 'green' : 'red'} />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            ) : (
              `No offers.`
            )}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default OffersOverview;
