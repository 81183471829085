import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Modal, Button, Grid, Segment, Loader, Icon, Table } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import ReactJson from 'react-json-view';
import OfferEditor from '../SweetComponents/Offer/Editor';
import exampleOffer from '../DataPointSandbox/exampleOffer';
import Offer from '../components/Offer';

class EditOfferModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.state.data = props.initialValue;
    } else {
      this.state.data = exampleOffer;
    }
  }

  handleUpdate = ({ data }) => {
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({ data: this.state.data });
  };

  render() {
    const { onClose } = this.props;
    const { data } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon closeOnDimmerClick={false} closeOnEscape={false} size='fullscreen'>
        <Modal.Header>Edit Offer</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <OfferEditor initialValue={data} onUpdate={this.handleUpdate} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' disabled={_.isEmpty(data)} onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const SearchResults = ({ searchMode, count, scoredItems, durationSeconds }) => (
  <div>
    <div>Duration: {Math.round(durationSeconds * 100) / 100}s</div>
    {searchMode === 'COUNT' ? (
      <p>{count} results</p>
    ) : (
      <Table basic='very' selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Firstname</Table.HeaderCell>
            <Table.HeaderCell>Lastname</Table.HeaderCell>
            <Table.HeaderCell>Headline</Table.HeaderCell>
            <Table.HeaderCell>Pre Score</Table.HeaderCell>
            {searchMode === 'SCORE' ? <Table.HeaderCell>Score</Table.HeaderCell> : null}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(scoredItems, ({ firstname, lastname, headline, prescore, score }, key) => (
            <Table.Row key={key}>
              <Table.Cell>{firstname}</Table.Cell>
              <Table.Cell>{lastname}</Table.Cell>
              <Table.Cell>{headline}</Table.Cell>
              <Table.Cell>{prescore}</Table.Cell>
              {searchMode === 'SCORE' ? <Table.Cell>{score}</Table.Cell> : null}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )}
  </div>
);

const SearchMode = (setSearchMode, searchMode) => (
  <Button.Group>
    <Button onClick={() => setSearchMode('COUNT')} active={searchMode === 'COUNT'}>
      Count
    </Button>
    <Button onClick={() => setSearchMode('PRESCORE')} active={searchMode === 'PRESCORE'}>
      Prescore
    </Button>
    <Button onClick={() => setSearchMode('SCORE')} active={searchMode === 'SCORE'}>
      Score
    </Button>
  </Button.Group>
);

class PoolSearch extends React.Component {
  state = { offer: exampleOffer, search: { waitingResults: false }, searchMode: 'SCORE' };
  componentDidMount() {
    // this.handleLoadJobs();
  }

  openOfferModal = async () => {
    this.setState({ editingOffer: true });
  };

  handleModalClose = async () => {
    this.setState({ editingOffer: false });
  };

  search = async () => {
    this.setState({ search: { waitingResults: true, results: null, error: null } });
    const { poolId } = this.props;
    const { offer, searchMode } = this.state;
    const url = baseUrl + '/fastSearch/search';
    const { data } = await axios.post(url, { offer, poolId, limit: 100, searchMode });
    if (data.error) {
      this.setState({ search: { error: data.error, waitingResults: false } });
    } else {
      const results = data;
      this.setState({ search: { waitingResults: false, results } });
    }
  };

  handleModalSave = async ({ data }) => {
    this.setState({ editingOffer: false, offer: data }, () => this.search());
  };

  // handleLoadDatasets = async () => {
  //   const { challengeId } = this.props;
  //   const url = baseUrl + '/challenges/' + challengeId + '/datasets';
  //   const { data } = await axios.get(url);
  //   if (data.error) {
  //     return alert(data.error);
  //   }
  //   const datasets = data.results;
  //   this.setState({ datasets });
  // };

  setSearchMode = async (mode) => {
    this.setState({ searchMode: mode });
  };

  render() {
    const { poolId } = this.props;

    return (
      <div>
        <h1>
          {' '}
          <a href={'/ml/fast-search'}>
            <Button icon='arrow left' size='small' />
          </a>{' '}
          <a href={'/ml/fast-search/manage/' + poolId}>
            <Button icon='pencil alternate' size='small' />
          </a>{' '}
          Pool {poolId}{' '}
        </h1>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Segment>
                <h2>
                  Mode <div style={{ float: 'right' }}>{SearchMode(this.setSearchMode, this.state.searchMode)}</div>
                </h2>
                <h2>
                  Offer
                  <Button floated='right' onClick={this.openOfferModal}>
                    Edit offer
                  </Button>
                </h2>
                {this.state.editingOffer ? (
                  <EditOfferModal onSave={this.handleModalSave} onClose={this.handleModalClose} />
                ) : (
                  <Offer offer={this.state.offer} />
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column width={11}>
              <Segment>
                <h2>
                  Results <Icon onClick={this.search} name='repeat' style={{ float: 'right', cursor: 'pointer' }} />
                </h2>
                {this.state.search.waitingResults ? (
                  <Loader active inline='centered' />
                ) : this.state.search.error ? (
                  <Icon negative big name='cross' />
                ) : this.state.search.results ? (
                  SearchResults(this.state.search.results)
                ) : // <ReactJson src={this.state.search.results} />
                null}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default PoolSearch;
