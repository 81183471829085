import axios from 'axios';
import baseUrl from '../../../baseUrl.js';

const loadGithubFromLogin = async ({ login }) => {
  const url = baseUrl + '/sourceData/github/' + login.toLowerCase();
  const { data } = await axios.get(url);
  return data;
}

export default loadGithubFromLogin;
