import React from 'react';
import { Segment, Divider, Grid, Modal, Icon } from 'semantic-ui-react';
import ClientProfile from '../components/ClientProfile';
import profileDataManager from './profileData';
import getProfileV4FromProfileV5 from './profileV5toV4.js';
import ProfileHeader from './containers/ProfileHeader';
import './components/componentsStyle.css';
import './profileView.css';
import ProfileExperiences from './containers/ProfileExperiences';
import ProfileEducations from './containers/ProfileEducations';
import ProfileSkills from './containers/ProfileSkills';
import GithubProfile from './containers/GithubProfile';
import StackoverflowProfile from './containers/StackoverflowProfile';
import AnnotationButtons from './containers/AnnotationButtons';
import Emails from './containers/Emails';
import Warnings from './containers/Warnings';
import ProgressBar from './components/ProgressBar';
import ProfileScores from './containers/ProfileScores';
import OfferCriteria from './containers/OfferCriteria';
import SweetappCriteriaBanner from './containers/SweetappCriteriaBanner';
import OfferMoreInfo from '../Offers/OfferMoreInfo';
import _ from 'underscore';

class ProfileView extends React.Component {
  state = {};
  handleToggleClientModal = () => {
    this.setState({
      clientModalIsOpen: !this.state.clientModalIsOpen,
    });
  };
  handleUpload = () => {
    const { profile, process } = this.props;
    if (!(((profile || {}).state || {}).cacheState || {}).data) {
      return alert('No data for this profile');
    }
    process('selected');
  };
  render() {
    const { profile, process, annotationActions, onRemoveSource } = this.props;
    const offerId = this.props.jobOfferId;
    if (!profile) {
      return null;
    }
    const { cacheState } = profile.state || {};

    const style = { paddingTop: 20, paddingLeft: 10, margin: 0 };

    if (!cacheState) {
      return <h2 style={style}>Not Loaded</h2>;
    }
    if (cacheState.loading) {
      return <h2 style={style}>Loading</h2>;
    }
    if (cacheState.error) {
      return (
        <div>
          <h2 style={style}>Error</h2>
          <pre>{(cacheState.error || {}).message}</pre>
        </div>
      );
    }
    const { data } = cacheState;
    if (!data) {
      return (
        <div>
          <h2 style={style}>Not Loaded</h2>
        </div>
      );
    }

    try {
      // TO REMOVE (Here for test purpose)
      // if(!data.warnings) {
      //   data.warnings = [
      //     {text: 'aight', title: 'already worked at hiresweet' },
      //     {text: '', title: 'have a look here' },
      //     {text: 'este es el nuevo sweetwork weon', title: 'hola3' },
      //   ]
      // }

      const profileData = profileDataManager(data, (newValue) => {
        this.props.updateItemData(newValue);
      });

      const githubSourceData = profileData.getSourceData().getGithub();
      // console.log(profile);

      const clientProfileData = getProfileV4FromProfileV5(data);
      const clientProfile = {
        id: profile.id,
        idFields: profile.idFields,
        data: clientProfileData,
      };
      const isWatchProfile = !_.isEmpty((clientProfileData.sourceData || {}).hiresweet)

      return (
        <div style={{ position: 'relative', marginBottom: '15vh', fontFamily: 'SourceSansPro', fontSize: 12 }}>
          <div
            style={{
              position: 'fixed',
              bottom: '5vh',
              left: '35vw',
              zIndex: 100,
            }}
          >
            <AnnotationButtons
              process={process}
              annotationActions={annotationActions}
              idFields={clientProfile.idFields}
              scorerDetails={((data || {}).tmp || {}).scorerDetails}
              sweetynote={((data || {}).tmp || {}).sweetynote}
              feedbackModeEnabled={this.props.feedbackModeEnabled}
              toggleFeedbackMode={this.props.toggleFeedbackMode}
              sendSearchFeedback={this.props.sendSearchFeedback}
            />
          </div>

          {((data || {}).tmp || {}).rank !== undefined && ((data || {}).tmp || {}).pipeLength !== undefined && (
            <div
              style={{
                position: 'absolute',
                top: '35px',
                right: '20px',
                zIndex: 100,
              }}
            >
              <span>
                {((data || {}).tmp || {}).rank} / {((data || {}).tmp || {}).pipeLength}
              </span>
            </div>
          )}

          <Grid style={{ padding: 0, margin: 0 }}>
            <Grid.Row>
              <Grid.Column width={9}>
                <Segment color='blue'>
                  <ProfileHeader
                    offerId={offerId}
                    firstname={profileData.getFirstname()}
                    lastname={profileData.getLastname()}
                    headline={profileData.getHeadline()}
                    location={profileData.getLocation()}
                    photoLink={profileData.getPhotoLink()}
                    summary={profileData.getSummary()}
                    smartSummary={profileData.getSmartSummary()}
                    sources={profileData.getSources()}
                    relevantTags={profileData.getRelevantTags()}
                    education={profileData.getEducation()}
                    lastScrapDay={profileData
                      .getSourceData()
                      .getLinkedin()
                      .getLastScrapDay()}
                    onToggleClientView={this.handleToggleClientModal}
                    summaryLinkedRessources={((data || {}).tmp || {}).summaryLinkedRessources}
                    onRemoveSource={({ sourceIdToRemove, idToRemove }) =>
                      onRemoveSource({
                        sourceId: 'linkedin',
                        id: ((clientProfile || {}).idFields || {}).linkedin,
                        sourceIdToRemove,
                        idToRemove,
                      })
                    }
                    hiresweetData={profileData.getSourceData().getHiresweet()}
                  />
                  <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
                    Experiences
                  </Divider>
                  <ProfileExperiences experiences={profileData.getExperiences()} />
                  <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
                    Education
                  </Divider>
                  <ProfileEducations education={profileData.getEducation()} />
                  <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
                    Skills
                  </Divider>
                  <ProfileSkills
                    skills={profileData
                      .getSourceData()
                      .getLinkedin()
                      .getSkills()}
                  />
                </Segment>

                {this.state.clientModalIsOpen && (
                  <Modal open closeIcon onClose={() => this.handleToggleClientModal()}>
                    <Modal.Content>
                      <div style={{ position: 'relative' }}>
                        <ClientProfile profile={clientProfile} />
                      </div>
                    </Modal.Content>
                  </Modal>
                )}
              </Grid.Column>
              <Grid.Column width={7}>
                <ProgressBar progress={this.props.progress} />
                <ProfileScores profileScores={(data || {}).profileScores} />
                <Warnings warnings={profileData.getWarnings().getRawValue()} />
                <SweetappCriteriaBanner criteria={(this.props.sweetappOffer || {}).criteria} />
                {!isWatchProfile && (<Emails
                  emailHints={profileData.getEmailHints()}
                  email={profileData.getEmail()}
                  firstname={profileData.getFirstname().get()}
                  lastname={profileData.getLastname().get()}
                  linkedinId={profileData
                    .getSourceData()
                    .getLinkedin()
                    .getId()
                    .get()}
                  sweetynote={((data || {}).tmp || {}).sweetynote}
                  />)}
                <OfferCriteria criteria={this.props.mergedOffer.criteria} />
                <OfferMoreInfo jobOfferId={this.props.offer.platformId} />
                {profileData
                  .getSourceData()
                  .getGithub()
                  .getId()
                  .get() && (
                    <div style={{ position: 'relative' }}>
                      <GithubProfile profile={githubSourceData} />
                      <div style={{ opacity: 0.5, position: 'absolute', bottom: -13, right: -15 }}>
                        <Icon
                          link
                          color='red'
                          name='remove'
                          onClick={() => {
                            profileData
                              .getSourceData()
                              .getGithub()
                              .remove();
                          }}
                        />
                      </div>
                    </div>
                  )}

                {profileData
                  .getSourceData()
                  .getStackoverflow()
                  .getId()
                  .get() && (
                    <div style={{ position: 'relative' }}>
                      <Divider horizontal>Stackoverflow</Divider>
                      <StackoverflowProfile stackoverflowData={profileData.getSourceData().getStackoverflow()} />
                      <div style={{ opacity: 0.5, position: 'absolute', bottom: -13, right: -15 }}>
                        <Icon
                          link
                          color='red'
                          name='remove'
                          onClick={() => {
                            profileData
                              .getSourceData()
                              .getStackoverflow()
                              .remove();
                          }}
                        />
                      </div>
                    </div>
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    } catch (error) {
      console.log(error);
      return <h1>Error: {error.message}</h1>;
    }
  }
}

export default ProfileView;
