import _ from 'underscore';
import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const getColor = (letter, score, colorMap, labelOrScoreColor) => {
  if (labelOrScoreColor == 'score') {
    return scoreToColor(score);
  } else {
    return letterToColor(letter, colorMap);
  }
};

const letterToColor = (letter, colorMap) => {
  return (
    (colorMap || {})[letter.toLowerCase()] ||
    {
      'a+': '#3EF005',
      a: '#4ED804',
      ab: '#5FC104',
      b: '#70AA03',
      bc: '#809303',
      c: '#917C02',
      cd: '#A26402',
      d: '#B24D01',
      de: '#C33601',
      e: '#D41F00',
      'e-': '#E50800',

      'currently-freelance': '#3EF005',
      'currently-fulltime-and-freelance': '#67B603',
      'has-been-freelance': '#BB4201',
      'never-freelance': '#E50800',
    }[letter.toLowerCase()] ||
    'grey'
  );
};

const scoreToColor = (score) => {
  if (score > 0.9) {
    return '#3EF005';
  } else if (score > 0.8) {
    return '#4ED804';
  } else if (score > 0.7) {
    return '#5FC104';
  } else if (score > 0.6) {
    return '#70AA03';
  } else if (score > 0.5) {
    return '#809303';
  } else if (score > 0.4) {
    return '#917C02';
  } else if (score > 0.3) {
    return '#B24D01';
  } else if (score > 0.2) {
    return '#C33601';
  } else if (score > 0.1) {
    return '#D41F00';
  } else {
    return '#E50800';
  }
};

export const RankingABCDE = ({
  predictions,
  totalWidth,
  categories,
  colorMap,
  withUnitaryCosts,
  onClickPrediction,
  labelOrScoreColor,
}) => {
  const ranking = _.pluck(predictions, 'letter');
  const scores = _.pluck(predictions, 'score');
  const optimizedScores = _.pluck(predictions, 'optimizedScore');
  const unitaryCosts = _.pluck(predictions, 'unitaryCost');

  const cellWidth = Math.round(totalWidth / Math.max(ranking.length, 1));

  const chartData = _.map(scores, (value, index) => ({
    key: index,
    value,
    optimized: optimizedScores[index],
  }));

  const totalCost = Math.max(0.0000001, _.reduce(unitaryCosts, (memo, x) => memo + x, 0));
  const maxUnitaryCost = _.reduce(unitaryCosts, (memo, x) => Math.max(memo, x), 0.0000001);

  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        {_.map(
          ranking,
          (letter, index) =>
            (!categories || categories[letter.toLowerCase()]) && (
              <div
                key={index}
                title={
                  letter +
                  '\n' +
                  (scores && scores.length > index ? '' + scores[index] : '') +
                  '\n' +
                  (unitaryCosts && unitaryCosts.length > index
                    ? '' + Math.round((10000 * unitaryCosts[index]) / totalCost) / 100
                    : '')
                }
                onClick={() => {
                  onClickPrediction && onClickPrediction(predictions[index]);
                }}
                style={{
                  display: 'inline-block',
                  height: 20,
                  width: cellWidth,
                  borderRight: '1px solid whitesmoke',
                  background: getColor(letter, scores[index], colorMap, labelOrScoreColor),
                }}
              />
            ),
        )}
        <br />
        {withUnitaryCosts &&
          _.map(ranking, (letter, index) => {
            const opacity = unitaryCosts && unitaryCosts.length > index ? unitaryCosts[index] / maxUnitaryCost : 0;
            return (
              <div
                key={'__' + index}
                style={{
                  display: 'inline-block',
                  height: 20,
                  width: cellWidth,
                  background: 'black',
                  opacity: opacity,
                }}
              />
            );
          })}
      </div>
      <div
        style={{
          display: 'inline-block',
          marginLeft: 10,
          verticalAlign: 'top',
        }}
      >
        <Popup
          trigger={<Icon size='big' color='blue' name='bar chart' style={{ cursor: 'pointer' }} />}
          content={
            <LineChart width={800} height={300} data={chartData}>
              <Line type='monotone' dataKey='value' stroke='#8884d8' animationDuration={0} />
              <Line type='monotone' dataKey='optimized' stroke='orange' animationDuration={0} />
              <CartesianGrid stroke='#ccc' />
              <XAxis dataKey='key' />
              <YAxis />
            </LineChart>
          }
        />
      </div>
    </div>
  );
};
