import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Form } from 'semantic-ui-react';
import { SweetForm, Clearable, Input, Select } from 'sweetform';
import Modal from '../../Modal';

import { WorkplaceFilters, sweetformFilterToMongoQuery, mongoQueryToSweetformFilter } from './WorkplaceSearch';

const GROUP_TYPE_OPTIONS = [{ value: 'school', label: 'School' }, { value: 'company', label: 'Company' }];

class EditWorkplaceGroupModal extends Component {
  async componentWillMount() {
    const { initialValues } = this.props;
    const currentValue = {
      ...initialValues,
      ...((initialValues || {}).filter && { filter: mongoQueryToSweetformFilter(initialValues.filter) }),
    };

    this.setState({
      currentValue,
    });
  }
  handleChange = (newValue) => {
    this.setState({ currentValue: newValue });
  };
  handleSubmit = async () => {
    const { currentValue } = this.state;
    const { onSubmit, onCancel, creationMode } = this.props;
    if (!currentValue.name) {
      return { success: false, error: 'Name is required' };
    }
    if (creationMode) {
      currentValue.id = (currentValue.id || currentValue.name).toLowerCase().replace(/[^a-z0-9]/g, '-');
    }
    let formatedValue = _.omit(currentValue, 'deleteField');
    if (currentValue.filter) {
      formatedValue.filter = sweetformFilterToMongoQuery(currentValue.filter);
    }
    console.log('currentValue.filter', currentValue.filter)
    console.log('formatedValue.filter', formatedValue.filter)
    const result = await onSubmit(formatedValue);
    if ((result || {}).error) {
      return alert((result || {}).error);
    } else {
      onCancel();
    }
  };

  handleDelete = async () => {
    const { onDelete, onCancel, initialValues } = this.props;
    try {
      const result = await onDelete(initialValues.id);
      if (result && result.success) {
        onCancel();
      } else {
        return alert((result || {}).error);
      }
    } catch (error) {
      alert(error);
    }
  };

  render() {
    const { onCancel, initialValues, type } = this.props;
    const editMode = _.isEmpty(initialValues) ? 'create' : 'edit';
    const transformedInitialValue = {
      ...(type && { type }),
      ...initialValues,
      ...((initialValues || {}).filter && { filter: mongoQueryToSweetformFilter(initialValues.filter) }),
    };
    const deleteAction = {
      text: 'DELETE',
      color: 'red',
      onClick: this.handleDelete,
    };
    return (
      <div>
        <Modal
          active={true}
          headerText={
            editMode === 'edit'
              ? `Edit group ${transformedInitialValue.name || transformedInitialValue.id}`
              : 'Create new group'
          }
          submitText='Submit'
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          extraActions={(this.state.currentValue || {}).deleteField === 'DELETE' ? [deleteAction] : []}
        >
          <Form>
            <SweetForm onChange={this.handleChange} initialValues={transformedInitialValue}>
              <Grid columns={1}>
                <Grid.Column>
                  {editMode === 'create' ? (
                    <div>
                      <Form.Field>
                        <label>Id</label>
                        <Clearable
                          component={Input}
                          field='id'
                          innerProps={{ placeholder: 'group-id' }}
                          nullLabel='Auto'
                        />
                      </Form.Field>
                    </div>
                  ) : null}
                  <Form.Field>
                    <label>Name</label>
                    <Input autoFocus field='name' />
                  </Form.Field>
                  <Form.Field>
                    <label>Type</label>
                    <Select options={GROUP_TYPE_OPTIONS} field='type' />
                  </Form.Field>
                  <Form.Field>
                    <label>Filter</label>
                    <WorkplaceFilters field='filter' />
                  </Form.Field>

                  {editMode === 'edit' && (
                    <Form.Field>
                      <label>Write "DELETE" to enable the delete button</label>
                      <Input field='deleteField' />
                    </Form.Field>
                  )}
                </Grid.Column>
              </Grid>
            </SweetForm>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default EditWorkplaceGroupModal;
