import React, { Component } from 'react';
import { Form, Button, Modal, Grid } from 'semantic-ui-react';
import { Education as Education_ } from '../SweetWork/profileData';
import { Education } from '../SweetWork/containers/ProfileEducations';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class EducationModalEditor extends Component {
  handleTextChange(value) {
    this.setState({ rawText: value });
  }
  render() {
    const { onClose } = this.props;
    const { rawEntity } = this.props;
    const handleSubmit = () => {
      this.props.onSubmit(JSON.parse((this.state || {}).rawText || ''));
      onClose();
    };
    if (!this.state) {
      this.setState({ rawText: JSON.stringify(rawEntity, null, 2) });
    }
    return (
      <Modal open onClose={onClose} onSubmit={handleSubmit} closeIcon size='large'>
        <Modal.Header>Edit Education </Modal.Header>
        <Modal.Content style={{ minHeight: 500, minWidth: 300 }}>
          <Form.TextArea
            style={{ minHeight: 500, minWidth: 300 }}
            value={(this.state || {}).rawText || JSON.stringify(rawEntity, null, 2)}
            onChange={(e, { value }) => {
              this.handleTextChange(value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Close
          </Button>
          <Button positive onClick={handleSubmit}>
            Save changes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class EducationFunctionInput extends Component {
  getEmptyEducation() {
    return {};
  }

  getRawEntity() {
    return {
      schoolId: '20041/?legacySchoolId=20041',
      schoolName: 'ESIEE PARIS',
      degree: 'Master of Engineering - MEng, Information Systems',
      rawDates: ['2011', '2016'],
      descriptionHTML:
        'ESIEE Paris is a school of higher education and research focused on all aspects of technological innovation',
    };
  }

  handleClickOnEdit = async () => {
    this.setState({ editable: true });
  };

  onClose = async () => {
    this.setState({ editable: false });
  };

  handleSubmit = async (xpJSON) => {
    this.props.onSubmit(xpJSON);
  };

  handleClickOnSample = async () => {
    const { propertyTest, samplingBase } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/randomEducation';
    const sampleWithProperty = ((propertyTest || {}).property || {}).constraints || (samplingBase && samplingBase.samplingMode !== 'all-profiles')
    if (!sampleWithProperty) {
      const randomEducationJSON = (await axios.get(url, customHeaders)).data;
      console.log(randomEducationJSON);
      this.handleSubmit(randomEducationJSON);
    } else {
      const payload = { test: propertyTest, samplingBase };
      const randomEducationJSON = (await axios.post(url + 'WithProperty', payload, customHeaders)).data;
      if (!randomEducationJSON || randomEducationJSON.success === false) {
        alert('No Education found with this property while sampling 1000');
      }
      this.handleSubmit(randomEducationJSON);
    }
  };

  render() {
    const enrichedEntity = this.props.enrichedEntity || this.getEmptyEducation();
    const rawEntity = (this.props || {}).rawEntity || this.getRawEntity();
    const edu = Education_(enrichedEntity, () => null);
    return (
      <div>
        {(this.state || {}).editable || false ? (
          <EducationModalEditor onClose={this.onClose} rawEntity={rawEntity} onSubmit={this.handleSubmit} />
        ) : (
          <div>
            <Grid columns={2}>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={this.handleClickOnEdit}>
                  Edit Education
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSample()}>
                  Sample Education
                </a>
              </Grid.Column>
            </Grid>
            <Education
              key={0}
              elementId={edu.getElementId()}
              degree={edu.getDegree()}
              description={edu.getDescription()}
              schoolName={edu.getSchoolName()}
              schoolWebsite={edu.getSchoolWebsite()}
              startDate={edu.getStartDate()}
              endDate={edu.getEndDate()}
              photoLink={edu.getPhotoLink()}
              stack={edu.getStack()}
              workplace={edu.getWorkplace()}
              onRemove={() => null}
            />
          </div>
        )}
      </div>
    );
  }
}

export default EducationFunctionInput;
