import _ from 'underscore';
import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, Legend } from 'recharts';
import moment from 'moment/moment';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const maherCategoriesMap = [
  'android',
  'backend-js',
  'business',
  'c-cpp',
  'csharp-dotnet',
  'data',
  'data-science',
  'devops',
  'frontend-js',
  'fullstack-js',
  'go',
  'ios',
  'java',
  'lead-or-cto',
  'mobile-other',
  'other',
  'php',
  'product-manager',
  'python',
  'quality-assurance',
  'ruby',
  'ui-ux',
];

export default class DashBoardCategoryMaherHistory extends Component {
  render() {
    const timeFormatter = (tick) => {
      return moment(new Date('20' + tick)).format('YY-MM-DD');
    };
    console.log('A');
    console.log(this.props.stats);
    const data = _.sortBy(
      _.map(this.props.stats, (stat) => ({
        ...stat,
        x: timeFormatter(stat.date),
      })),
      'x',
    );
    return (
      <Segment>
        <Header as='h3' textAlign='center'>
          Evolution of select rate by maher category
        </Header>
        <LineChart width={1500} height={500} data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <YAxis
            label={{
              value: 'Select Rate',
              angle: -90,
              position: 'insideLeft',
              dy: 40,
              dx: 20,
            }}
          />
          <Tooltip />
          <Legend />
          {_.map(maherCategoriesMap, (category) => {
            return <Line type='monotone' dataKey={category} stroke={getRandomColor()} />;
          })}
        </LineChart>
      </Segment>
    );
  }
}
