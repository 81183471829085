import React, { Component } from 'react';
import _ from 'underscore';
import {
  transformEnrichmentByHint,
  // getEnrichedTextByType,
} from '../enrichment';
import {
  Segment,
  Header,
  Label,
  List,
  Container,
  Statistic,
  Icon,
  Image,
  Card,
  Button,
  Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import { getColorFromScrapDate } from '../../common';
import EnrichedText, {
  getColorFromEnrichedText,
} from '../../components/EnrichedText';
import { ExtLink } from './ExtLink';

const GithubRepoExtra = ({
  globalHint,
  stargazersCount,
  language,
  languageEnriched,
}) => {
  // const enrichedLang = enrichedRepo ? getEnrichedTextByType(enrichedRepo, 'language') : null;
  return (
    <span>
      {language && !languageEnriched ? language : null}
      {languageEnriched ? (
        <EnrichedText
          {...transformEnrichmentByHint(globalHint, languageEnriched)}
        />
      ) : null}{' '}
      <Icon name="star" />
      {stargazersCount || 0}
    </span>
  );
};

class GithubProfile extends Component {
  state = { open: false };
  onGithubRepoClick = () => this.setState({ open: !this.state.open });
  render() {
    const {
      source,
      globalHint,
      more,
      onMore,
      sweetynote,
      markAsMatch,
    } = this.props;
    const { open } = this.state;
    const {
      id,
      login,
      fullname,
      blog,
      location,
      email,
      nbFollowing,
      nbFollowers,
      nbPublicRepos,
      repos,
      headline,
      company,
      // enrichedData,
      headlineEnriched,
    } = source || {};
    // console.log('sweetynote', sweetynote);
    // console.log('github', source)
    // const enrichedHeadline = getEnrichedTextByType(enrichedData, 'headline');
    // const enrichedCompany = company;//getEnrichedTextByType(enrichedData, 'company');
    let repoLanguages = [];
    _.each(repos, (repo) => {
      const index = _.findIndex(repoLanguages, { language: repo.language });
      if (index < 0) {
        repoLanguages.push({ ...repo, count: 1 });
      } else {
        repoLanguages[index].count++;
      }
    });
    return (
      <Segment color="green">
        <Header size="medium" style={{ margin: 0 }}>
          <div style={{ float: 'right' }}>
            {!(((sweetynote || {}).matches || {}).github || {}).id && (
              <Popup
                inverted
                content="Link this github to the linkedin"
                trigger={
                  <Icon
                    color="red"
                    name="lock"
                    size="large"
                    onClick={() => markAsMatch('github', id)}
                  />
                }
              />
            )}
            <Label
              style={{ marginRight: 20 }}
              color={getColorFromScrapDate(source.date)}
            >
              {moment(source.date, 'YY-MM-DD').fromNow()}
            </Label>
            <ExtLink classes="source-link" to={`https://github.com/${login}`}>
              <Icon name="github" />
            </ExtLink>
            {blog ? (
              <ExtLink to={blog}>
                <Icon name="external" />
              </ExtLink>
            ) : null}
          </div>
          <Image
            circular
            src={`https://avatars3.githubusercontent.com/u/${id}?s=460&v=4`}
            style={{ fontSize: '3rem' }}
          />
          <Header.Content>
            {fullname}{' '}
            <span
              style={{
                fontWeight: 'normal',
                fontSize: '11px',
                color: '#7D7D7D',
              }}
            >
              {login ? `(${login})` : null}
            </span>
            <Header.Subheader>
              <List>
                {company ? (
                  <List.Item
                    icon="group"
                    content={`${company} ${location ? `-- ${location}` : ''}`}
                  />
                ) : null}
                {!company && location ? (
                  <List.Item icon="marker" content={location} />
                ) : null}
                {email ? <List.Item icon="mail" content={email} /> : null}
              </List>
              {headline ? (
                <Segment secondary>
                  {headlineEnriched ? (
                    <EnrichedText
                      {...transformEnrichmentByHint(
                        globalHint,
                        headlineEnriched,
                      )}
                    />
                  ) : (
                    headline
                  )}
                </Segment>
              ) : null}
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Statistic.Group
          widths={3}
          size="mini"
          style={{ marginTop: 0, fontSize: '9px' }}
        >
          <Statistic label="Following" value={nbFollowing} />
          <Statistic label="Followers" value={nbFollowers} />
          <Statistic label="Repositories" value={nbPublicRepos} />
        </Statistic.Group>
        <Container fluid textAlign="center">
          <Label.Group style={{ marginTop: 5 }}>
            {_.map(
              _.sortBy(repoLanguages, 'count').reverse(),
              ({ count, language, languageEnriched }) => (
                <Label
                  size={'large'}
                  key={language}
                  style={{
                    backgroundColor: getColorFromEnrichedText(languageEnriched),
                  }}
                >
                  {language}
                  <Label.Detail>{count}</Label.Detail>
                </Label>
              ),
            )}
          </Label.Group>
        </Container>

        <Card.Group itemsPerRow={5}>
          {_.map(
            _.sortBy(repos, (x) => 1 / (x.stargazersCount || 1)),
            (repo, i) =>
              i < 5 ? (
                <Card key={i}>
                  <Card.Content>
                    <Card.Header>
                      {repo.nameEnriched ? (
                        <EnrichedText {...repo.nameEnriched} />
                      ) : (
                        <span>{repo.name}</span>
                      )}
                    </Card.Header>
                    <Card.Description
                      onClick={() => this.onGithubRepoClick()}
                      style={
                        open
                          ? {}
                          : {
                              maxHeight: '50px',
                              overflow: 'hidden',
                            }
                      }
                    >
                      {/* {repo.enrichedRepo && getEnrichedTextByType(repo.enrichedRepo, 'description') ? (
                      <EnrichedText {...transformEnrichmentByHint(globalHint, getEnrichedTextByType(repo.enrichedRepo, 'description'))} />
                    ) : repo.description && repo.description.length > 60 ? (
                      <Popup trigger={<span>{repo.description.slice(0, 60)}...</span>} content={repo.description} />
                    ) : repo.description} */}
                      {!_.isEmpty(repo.descriptionEnriched) ? (
                        <EnrichedText {...repo.descriptionEnriched} />
                      ) : repo.description && repo.description.length > 60 ? (
                        <Popup
                          trigger={
                            <span>{repo.description.slice(0, 60)}...</span>
                          }
                          content={repo.description}
                        />
                      ) : (
                        repo.description
                      )}
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <GithubRepoExtra {...repo} />
                  </Card.Content>
                </Card>
              ) : null,
          )}
        </Card.Group>
        <Card.Group itemsPerRow={5}>
          {_.map(
            _.sortBy(repos, (x) => 1 / (x.stargazersCount || 1)),
            (repo, i) =>
              (more['githubRepos'] && i >= 5) || (i >= 5 && i < 10) ? (
                <Card key={i}>
                  <Card.Content>
                    <Card.Header>
                      {repo.nameEnriched ? (
                        <EnrichedText {...repo.nameEnriched} />
                      ) : (
                        <span>{repo.name}</span>
                      )}
                    </Card.Header>
                    <Card.Description
                      onClick={() => this.onGithubRepoClick()}
                      style={
                        open
                          ? {}
                          : {
                              maxHeight: '50px',
                              overflow: 'hidden',
                            }
                      }
                    >
                      {/* {repo.enrichedRepo && getEnrichedTextByType(repo.enrichedRepo, 'description') ? (
                      <EnrichedText {...transformEnrichmentByHint(globalHint, getEnrichedTextByType(repo.enrichedRepo, 'description'))} />
                    ) : repo.description && repo.description.length > 60 ? (
                      <Popup trigger={<span>{repo.description.slice(0, 60)}...</span>} content={repo.description} />
                    ) : repo.description} */}
                      {!_.isEmpty(repo.descriptionEnriched) ? (
                        <EnrichedText {...repo.descriptionEnriched} />
                      ) : repo.description && repo.description.length > 60 ? (
                        <Popup
                          trigger={
                            <span>{repo.description.slice(0, 60)}...</span>
                          }
                          content={repo.description}
                        />
                      ) : (
                        repo.description
                      )}
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <GithubRepoExtra {...repo} />
                  </Card.Content>
                </Card>
              ) : null,
          )}
        </Card.Group>
        {(repos || []).length > 10 && !more['githubRepos'] && (
          <Button size="small" onClick={() => onMore('githubRepos')}>
            Load more
          </Button>
        )}
      </Segment>
    );
  }
}

export { GithubProfile };
