import { SingleClickButton } from '../../common';
import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Header, Table, Label, Button, Icon } from 'semantic-ui-react';
import { SendNotificationModal } from './SendNotificationsModal';
import baseUrl from '../../baseUrl';
import moment from 'moment';


const getCandidateNotifications = async (id) => {
  const result = (await axios.get(`${baseUrl}/candidates/${id}/notifications`)).data;
  if (result.error) {
    throw Error(result.error);
  }
  return (result || {}).notifications
};

const getCandidateScheduledNotifications = async (id) => {
  const result = (await axios.get(`${baseUrl}/candidates/${id}/scheduledNotifications`)).data;
  if (result.error) {
    throw Error(result.error);
  }
  return (result || {}).scheduledNotifications
};

const removeScheduledNotification = async ({ id, notificationId, minTimestamp }) => {
  const result = (await axios.delete(`${baseUrl}/candidates/${id}/scheduledNotifications`, { data: { notificationId, minTimestamp } }));
  if (result.error) {
    throw Error(result.error);
  }
}

const scheduleSequence = async ({ id, sequenceId }) => {
  const result = (await axios.post(`${baseUrl}/candidates/${id}/sequences`, { sequenceId })).data;
  if (result.error) {
    throw Error(result.error);
  }
  return result
}

const getNotificationIds = async () => {
  const result = (await axios.get(`${baseUrl}/candidates/notificationIds`)).data;
  if (result.error) {
    throw Error(result.error);
  }
  return (result || {}).notificationIds;
}

const getSequenceIds = async () => {
  const result = (await axios.get(`${baseUrl}/candidates/sequenceIds`)).data;
  if (result.error) {
    throw Error(result.error);
  }
  return (result || {}).sequenceIds;
}

class WatchNotifications extends Component {
  state = {};

  handleLoadNotificationIds = async () => {
    try {
      const notificationIds = await getNotificationIds();
      const sequenceIds = await getSequenceIds();
      this.setState({ notificationIds, sequenceIds });
    } catch (e) {
      alert(e);
    }
  }

  handleLoadCandidateNotifications = async () => {
    try {
      const notifications = await getCandidateNotifications(this.props.id);
      const scheduledNotifications = await getCandidateScheduledNotifications(this.props.id);
      this.setState({ notifications, scheduledNotifications });
    } catch (e) {
      alert(e);
      this.props.onClose();
    }
  };
  componentWillMount() {
    this.handleLoadNotificationIds();
    this.handleLoadCandidateNotifications();
  }
  handleScheduleSequence = async ({ sequenceId }) => {
    const { id } = this.props;
    try {
      const result = await scheduleSequence({ id, sequenceId });
      alert(JSON.stringify(result))
      this.handleLoadCandidateNotifications();
    } catch (e) {
      alert(e);
    }
  };
  handleRemoveScheduledNotification = async ({ notificationId, minTimestamp }) => {
    const { id } = this.props;
    try {
      await removeScheduledNotification({ id, notificationId, minTimestamp });
      this.handleLoadCandidateNotifications();
    } catch (e) {
      alert(e);
    }
  };
  handleOpenSendNotificationModal = ({ notificationId }) => {
    this.setState({ selectedNotificationId: notificationId })
  }
  handleCloseNotificationModal = () => {
    this.handleLoadCandidateNotifications();
    this.setState({ selectedNotificationId: null });
  }
  render() {
    const { notificationIds, sequenceIds, notifications, scheduledNotifications, selectedNotificationId } = this.state;
    const flattenNotification = ({ lastNotification, history }) => {
      return (lastNotification ? [lastNotification] : []).concat(history || [])
    }
    if (!notificationIds) {
      return <div>Loading...</div>
    }
    return (
      <div>
        <Header as="h2">Notifications</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Notification</Table.HeaderCell>
              <Table.HeaderCell>History</Table.HeaderCell>
              <Table.HeaderCell>Send</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>

            {_.map(notificationIds, (notificationId) => (
              <Table.Row>
                <Table.Cell>{notificationId}</Table.Cell>
                <Table.Cell>
                  {_.map(flattenNotification((notifications || {})[notificationId] || {}), ({ timestamp, params, error }) => (
                    <p>
                      <Label
                        basic
                        color={!_.isEmpty(error) ? 'red' : ((params || {}).type == "ignore") ? 'blue' : 'green'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => (alert(JSON.stringify({ params, error }, null, 4)))}
                      >
                        {moment(timestamp).format("YYYY-MM-DD HH:mm:ss")} {(params || {}).type == "ignore" && "- ignored"}
                      </Label>
                    </p>

                  ))}
                </Table.Cell>
                <Table.Cell>
                  <Button color="blue" basic icon onClick={() => this.handleOpenSendNotificationModal({ notificationId })}>
                    <Icon name='send' />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>


        </Table>
        <Header as="h2">Scheduled Notifications</Header>
        <Table celled>
          <Table.Header>

            <Table.Row>
              <Table.HeaderCell>Notification</Table.HeaderCell>
              <Table.HeaderCell>Scheduled At</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>

            {_.map(scheduledNotifications, ({ notificationId, minTimestamp, status }) => (
              <Table.Row>
                <Table.Cell>{notificationId}</Table.Cell>
                <Table.Cell>{moment(minTimestamp).format("YYYY-MM-DD HH:mm:ss")}</Table.Cell>
                <Table.Cell>{status}</Table.Cell>
                <Table.Cell>
                  <Button icon basic color="red" onClick={() => this.handleRemoveScheduledNotification({ notificationId, minTimestamp })}>
                    <Icon name='close' />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

        </Table>
        <Header as="h2">Sequences</Header>
        <Table celled>
          <Table.Header>

            <Table.Row>
              <Table.HeaderCell>Sequence</Table.HeaderCell>
              <Table.HeaderCell>Schedule</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(sequenceIds, (sequenceId) => (
              <Table.Row>
                <Table.Cell>{sequenceId}</Table.Cell>
                <Table.Cell>
                  <Button color="blue" basic icon onClick={() => this.handleScheduleSequence({ sequenceId })}>
                    <Icon name='time' />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

        </Table>
        {selectedNotificationId && (
          <SendNotificationModal id={this.props.id} notificationId={selectedNotificationId} onClose={this.handleCloseNotificationModal} />
        )}
      </div>

    );
  }
}
export default WatchNotifications;
