import React from 'react';
import _ from 'underscore';
import { Image, Icon, Button, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ProfileView from './ProfileView';
import ExternalPushModal from '../Offers/WorkPipeInputComponents/ExternalPushModal';

const MiniProfileCard = ({ item, isSelected, onSelect, onRemove }) => {
  const cacheState = (item.state || {}).cacheState || {};
  const pipeStatus = ((item.state || {}).pipeStatus || {}).type;

  if (pipeStatus === 'processed') {
  return null;
  }

  const borderColor = cacheState.error ? 'red' : isSelected ? 'lime' : 'lightgray';

  let letters = '??';
  if (item.idFields.linkedin) {
  const str = item.idFields.linkedin;
  const iCut = str.indexOf('-');
  if (iCut > 0 && iCut + 1 < str.length) {
  letters = str.charAt(0) + str.charAt(iCut + 1);
  } else {
  letters = str.slice(0, 2);
  }
  }

  const photoLink = ((cacheState || {}).data || {}).photoLink;

  return (
  // eslint-disable-next-line
  <a
  style={{ cursor: 'pointer', position: 'relative' }}
  onClick={() => {
  if (!(item || {}).frontId) {
  return alert('Error: no frontId in item');
  }
  onSelect({ itemFrontId: item.frontId });
  }}
  >
  <div
  style={{
  position: 'absolute',
  left: 27,
  top: 27,
  zIndex: 1000,
  }}
  >
  {item.removable && (
  <Icon
  name='remove circle'
  size='large'
  link
  onClick={(e) => {
  e.stopPropagation();
  if (!(item || {}).frontId) {
  return alert('Error: no frontId in item');
  }
  onRemove({ itemFrontId: item.frontId });
  }}
  />
  )}
  </div>
  <div
  style={{
  position: 'absolute',
  left: 27,
  top: 1,
  zIndex: 1000,
  }}
  >
  {item.icon && <Icon name={item.icon.name} color={item.icon.color} size='large' link />}
  </div>
  <div
  style={{
  marginBottom: 5,
  width: 40,
  height: 40,
  borderRadius: 20,
  border: '1px solid ' + borderColor,
  background: '#EDF3F7',
  }}
  >
  {photoLink ? (
  <Image
  src={photoLink}
  circular
  onError={(e) => {
  e.target.src = '/images/default-avatar-1.svg';
  }}
  />
  ) : (
  <div
  style={{
  width: '100%',
  fontSize: 20,
  marginTop: 10,
  fontWeight: 'bold',
  color: 'white',
  }}
  >
  <center>{letters.toUpperCase()}</center>
  </div>
  )}
  </div>
  </a>
  );
};

class ProfilesPipeView extends React.Component {
  renderItemsList() {
    const { items, selectItem, removeItem, currentItemFrontId } = this.props;

    return (
      <div style={{ marginTop: 10 }}>
      {_.map(items, (item, index) => (
        <MiniProfileCard
        key={index}
        item={item}
        isSelected={item.frontId && item.frontId === currentItemFrontId}
        onSelect={() => {
          if (!(item || {}).frontId) {
            return alert('Error: no frontId in item');
          }
          selectItem({ itemFrontId: item.frontId });
        }}
        onRemove={() => {
          if (!(item || {}).frontId) {
            return alert('Error: no frontId in item');
          }
          removeItem({ itemFrontId: item.frontId });
        }}
        />
      ))}
      </div>
    );
  }
  renderCurrentItem() {
    const { jobOfferId, currentItemFrontId, items, annotationActions, processItem, updateItemData, progress, onRemoveSource } = this.props;
    if (!currentItemFrontId) {
      return;
    }
    const item = _.findWhere(items, { frontId: currentItemFrontId });
    if (!item) {
      return (
        <div>
        <h2>Item with id {currentItemFrontId} not found</h2>
        </div>
      );
    }

    return (
      <ProfileView
      key={currentItemFrontId}
      jobOfferId={jobOfferId}
      offer={this.props.offer}
      sweetappOffer={this.props.sweetappOffer}
      mergedOffer={this.props.mergedOffer}
      profile={item}
      process={(annotationType) => processItem({ itemFrontId: currentItemFrontId, annotationType })}
      annotationActions={annotationActions}
      updateItemData={(itemData, callback) => updateItemData({ itemFrontId: currentItemFrontId, itemData }, callback)}
      feedbackModeEnabled={this.props.feedbackModeEnabled}
      toggleFeedbackMode={this.props.toggleFeedbackMode}
      sendSearchFeedback={this.props.sendSearchFeedback}
      progress={progress}
      onRemoveSource={onRemoveSource}
      />
    );
  }

  renderNavigationButtons() {
    const { jobOfferId, workpipeId, items, currentItemFrontId } = this.props;
    const currentProfileIdFields = (_.findWhere(items, { frontId: currentItemFrontId }) || {}).idFields;
    return (
      <div>
      <Popup
      inverted
      trigger={
        <Link to={`/offers/${jobOfferId}`}>
        <Button
        className='nearly-transparent-button'
        style={{ cursor: 'pointer' }}
        color='purple'
        circular
        icon='address book'
        />
        </Link>
      }
      >
      Back to offer
      </Popup>

      <Popup
      inverted
      trigger={
        <Link to={`/offers/${jobOfferId}/workpipe/${workpipeId}`}>
        <Button
        alt='lalal'
        className='nearly-transparent-button'
        style={{ cursor: 'pointer' }}
        color='violet'
        circular
        icon='arrow left'
        />
        </Link>
      }
      >
      Back to workpipe
      </Popup>
      <ExternalPushModal profile={{ idFields: currentProfileIdFields }} />
      </div>
    );
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: -16 }}>{this.renderItemsList()}</div>
      <div style={{ position: 'absolute', top: 5, right: 2, zIndex: 10 }}>{this.renderNavigationButtons()}</div>
      <div style={{ marginLeft: 20 }}>{this.renderCurrentItem()}</div>
      </div>
    );
  }
}

export default ProfilesPipeView;
