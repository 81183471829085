import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Header, Label, Popup, Grid, Icon, Divider } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import moment from 'moment';


const getCommunicationCategory = ({ engagementType, emailIsInbound, callIsConnected }) => {
  if (engagementType == "email") {
    if (emailIsInbound) {
      return "inboundEmails";
    } else {
      return "outboundEmails";
    }
  }
  if (engagementType == "call") {
    if (callIsConnected) {
      return "connectedCalls";
    } else {
      return "notConnectedCalls";
    }
  }
  return engagementType;
};

const getCommunicationLink = ({ contacts, engagementId }) => {
  const firstContactId = (_.first(contacts) || {}).id;
  return `https://app.hubspot.com/contacts/1768483/contact/${firstContactId}/view/1?engagement=${engagementId}`;
};

const CommunicationIcon = ({ communicationCategory, props }) => {
  if (communicationCategory == 'inboundEmails') {
    return (
      <Icon.Group {...props}>
        <Icon name='mail' color='green'/>
        <Icon corner='top left' name='arrow down' color='green' />
      </Icon.Group>
    );
  }
  if (communicationCategory == 'outboundEmails') {
    return (
      <Icon.Group {...props}>
        <Icon name='mail' color='red'/>
        <Icon corner='top left' name='arrow up' color='red' />
      </Icon.Group>
    );
  }
  if (communicationCategory == 'connectedCalls') {
    return (
      <Icon.Group {...props}>
        <Icon name='call' color='green'/>
        <Icon corner='top left' name='check' color='green' />
      </Icon.Group>
    );
  }
  if (communicationCategory == 'notConnectedCalls') {
    return (
      <Icon.Group {...props}>
        <Icon name='call' color='red' />
        <Icon corner='top left' name='close' color='red' />
      </Icon.Group>
    );
  }
  if (communicationCategory == "note") {
    return (
      <Icon name='file' color='grey'/>
    );
  }
  if (communicationCategory == "task") {
    return (
      <Icon name='tasks' color='grey'/>
    );
  }
  return <Icon name='question' />
};

const HubspotContactLabel = ({ contact }) => {
  const { firstname, lastname, id, email } = contact || {};
  return (
    <a href={`https://app.hubspot.com/contacts/1768483/contact/${id}`} target="_blank">
      <Label basic>{firstname ? `${firstname} ${ lastname }` : email} </Label>
    </a>
  )
};

const OwnerLabel = ({ owner }) => {
  const { firstname, lastname, email } = owner || {};
  if (!owner){
    return <Label>?</Label>
  }
  return (
    <Label>{firstname ? `${firstname} ${ lastname }` : email} </Label>
  );
};

export const CommunicationLine = ({ communication }) => {
  const communicationCategory = getCommunicationCategory(communication || {});
  const communicationLink = getCommunicationLink(communication || {});
  const { contacts, creationDate, owner, body, subject } = communication || {};
  return (
    <div>
        <CommunicationIcon communicationCategory={communicationCategory} />
        <a href={communicationLink} target="_blank">
          {moment(creationDate).format("YYYY-MM-DD")}
        </a>

        <OwnerLabel owner={owner} /> 
        {_.map(contacts, (contact) => (<HubspotContactLabel key={Math.random()} contact={contact} />))}
        { (body || subject) && (
          <Popup
            trigger={<Icon name='file'/>}
            content={(
              <div>
                {subject && (<b>{subject}<br /></b>)}
                {body}
              </div>
            )}
          />
        )}
    </div>
  )
}

export const CommunicationsSummary = ({ communicationsSummary }) => {
  const categories = [
    "connectedCalls",
    "inboundEmails",
    "outboundEmails",
    "notConnectedCalls",
  ];
  console.log("communicationsSummary", {communicationsSummary})
  return (
    <div>
      {_.map(categories, (category) => {
        const { nb, lastDate } = (communicationsSummary || {})[category] || {};
        if (!nb){
          return <div></div>
        }
        return (
          <Label>
            <CommunicationIcon communicationCategory={category}/> {nb || ''} |
            {lastDate ? moment(lastDate).format('YYYY-MM-DD') : ''}
          </Label>
        );
      })}
    </div>
  )
}

