import _ from 'underscore';

const getUpScores = (tags, parents) => {
  return _.countBy(
    _.difference(
      _.flatten(
        _.map(parents, (id) =>
          _.pluck(
            _.filter(tags, (s) => _.contains(s.strong_dependencies, id)),
            'strong_dependencies',
          ),
        ),
      ),
      parents,
    ),
    (i) => i,
  );
};

const getDownScores = (tags, children) => {
  return _.countBy(
    _.difference(
      _.flatten(
        _.map(children, (id) =>
          _.map(tags[id].strong_dependencies, (pId) =>
            _.pluck(
              _.filter(tags, (s) => _.contains(s.strong_dependencies, pId)),
              'id',
            ),
          ),
        ),
      ),
      children,
    ),
    (i) => i,
  );
};

class TagSort {
  constructor() {
    this.tags = [];
    this.scores = undefined;
  }

  updateTags(tags) {
    this.tags = _.indexBy(tags, 'id');
  }

  updateRelatives(parents, children) {
    const cleanParents = _.filter(parents, (s) => s && _.isString(s));
    const cleanChildren = _.filter(children, (c) => c && _.isString(c));

    this.upScores = getUpScores(this.tags, cleanParents);
    this.downScores = getDownScores(this.tags, cleanChildren);
  }

  getSort(mode) {
    if (!mode) {
      return undefined; // default behavior
    }

    const scores = mode === 'up' ? this.upScores : this.downScores;

    return (rawOptions, filter, currentValues) => {
      const options = _.filter(rawOptions, ({ name }) => _.isString(name));

      // Inject scores into options
      const scoredOptions = _.map(options, (o) => ({
        ...o,
        score: -1 * scores[o.id] || 0, // negative for descending order
      }));

      const f = filter.toLowerCase();
      return _.sortBy(
        _.filter(
          scoredOptions,
          (o) =>
            f.length === 0 || o.name.toLowerCase().slice(0, f.length) === f,
        ),
        'score',
      );
    };
  }
}

export default TagSort;
