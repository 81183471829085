function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import React from 'react';
import { TextArea as UTextArea } from 'semantic-ui-react';
import enhance from './enhance';
var TextArea = enhance(function (_ref) {
  var setValue = _ref.setValue,
      value = _ref.value,
      defaultValue = _ref.defaultValue,
      field = _ref.field,
      props = _objectWithoutProperties(_ref, ['setValue', 'value', 'defaultValue', 'field']);

  return React.createElement(UTextArea, Object.assign({
    value: value || '',
    onChange: function onChange(event) {
      return setValue(event.target.value);
    }
  }, props));
});
export default TextArea;