import React from 'react';
import enhance from './enhance';
import Select from './Select';

var switchSelect = function switchSelect(options, WrappedComponent) {
  return enhance(function (_ref) {
    var value = _ref.value;
    var selection = value ? value.get('selection') : undefined;
    var selector = React.createElement(Select, {
      options: options,
      field: 'selection'
    });
    var nested = options.find(function (e) {
      return e.value === selection && e.nested;
    });
    return React.createElement('div', null, nested === undefined ? selector : null, React.createElement(WrappedComponent, {
      selector: selector,
      selection: selection
    }));
  });
};

export default switchSelect;