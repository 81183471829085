import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import React, { Component } from 'react';

class MissionProfileCollectionModal extends Component {
  componentDidMount() {
    this.handleLoadProjectsState();
    this.setInitialData();
  }

  setInitialData() {
    const { initialData } = this.props;
    const selectedProjectId = initialData.projectId;
    const selectedJobCollectionId = initialData.jobCollectionId;
    this.setState({
      selectedProjectId,
      selectedProfileCollectionId: initialData.profileCollectionId,
      selectedJobCollectionId,
      selectedMission: {
        identifier: initialData.missionId,
        job_path: initialData.missionPath,
      },
    });
    if (!_.isUndefined(selectedProjectId)) {
      this.loadProfileCollections(selectedProjectId);
      this.loadJobCollections(selectedProjectId);
    }
    if (!_.isUndefined(selectedJobCollectionId)) {
      this.loadMissions(selectedJobCollectionId);
    }
  }

  async getProjects() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProjects';
    const data = (await axios.get(url, customHeaders)).data;
    return (data || {}).data || [];
  }

  handleLoadProjectsState = async () => {
    const projects = await this.getProjects();
    this.setState({ projects });
  };

  loadProfileCollections = async (projectId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileCollections/' + projectId;
    const profileCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ profileCollections });
  };

  loadJobCollections = async (projectId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobCollections/' + projectId;
    const jobCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ jobCollections });
  };

  loadMissions = async (jobCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobs/' + jobCollectionId;
    const jobs = (await axios.get(url, customHeaders)).data;
    this.setState({ missions: jobs.results });
  };

  handleSelectProject = async (e, { value }) => {
    this.setState({ selectedProjectId: value });
    this.loadProfileCollections(value);
    this.loadJobCollections(value);
  };

  handleSelectProfileCollection = async (e, { value }) => {
    this.setState({ selectedProfileCollectionId: value });
  };

  handleSelectJobCollection = async (e, { value }) => {
    this.setState({ selectedJobCollectionId: value });
    this.loadMissions(value);
  };

  handleSelectMission = async (e, { value }) => {
    this.setState({ selectedMission: value });
  };

  handleOnSubmit = () => {
    const { onSubmit } = this.props;
    const { selectedProjectId, selectedProfileCollectionId, selectedJobCollectionId, selectedMission } =
      this.state || {};
    onSubmit({
      missionId: selectedMission.identifier,
      missionPath: selectedMission.job_path,
      profileCollectionId: selectedProfileCollectionId,
      projectId: selectedProjectId,
      jobCollectionId: selectedJobCollectionId,
    });
  };

  render() {
    const { onClose } = this.props;
    const {
      projects,
      selectedProjectId,
      profileCollections,
      selectedProfileCollectionId,
      jobCollections,
      selectedJobCollectionId,
      missions,
      selectedMission,
    } = this.state || {};
    const projectOptions = _.map(projects || [], (project, key) => ({
      value: project.id,
      key: key,
      text: project.name ? project.name : project.title ? project.title : project.id,
    }));
    const profileCollectionsOptions = _.map(profileCollections, (profileCollection) => ({
      value: profileCollection.id,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
      key: profileCollection.id,
    }));
    const jobCollectionsOptions = _.map(jobCollections, (jobCollection) => ({
      value: jobCollection.id,
      text: jobCollection.name ? jobCollection.name : jobCollection.title,
      key: jobCollection.id,
    }));
    const missionOptions = _.map(missions, (mission) => ({
      value: mission,
      text: mission.identifier,
      key: mission.identifier,
    }));

    return (
      <Modal open closeIcon open={true} onClose={onClose}>
        <Modal.Content size='fullscreen'>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={8}>
                <label>
                  <b>Project Id</b>
                </label>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Select
                  search
                  options={projectOptions}
                  onChange={this.handleSelectProject}
                  value={selectedProjectId}
                  placeholder=''
                />
              </Grid.Column>
            </Grid.Row>

            {selectedProjectId ? (
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label>
                      <b>Profile Collection Id</b>
                    </label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Select
                      search
                      options={profileCollectionsOptions}
                      onChange={this.handleSelectProfileCollection}
                      value={selectedProfileCollectionId}
                      placeholder=''
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            ) : null}

            {selectedProjectId ? (
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label>
                      <b>Job Collection Id</b>
                    </label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Select
                      search
                      options={jobCollectionsOptions}
                      onChange={this.handleSelectJobCollection}
                      value={selectedJobCollectionId}
                      placeholder=''
                    />
                  </Grid.Column>
                </Grid.Row>

                {selectedJobCollectionId ? (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>
                        <b>Mission Id</b>
                      </label>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Select
                        search
                        options={missionOptions}
                        onChange={this.handleSelectMission}
                        value={selectedMission}
                        placeholder=''
                      />
                    </Grid.Column>
                  </Grid.Row>
                ) : null}
              </Grid.Column>
            ) : null}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onClose()}>
            Cancel
          </Button>
          <Button positive onClick={() => this.handleOnSubmit()}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default MissionProfileCollectionModal;
