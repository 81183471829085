import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button, Icon, Tab, Message, Label, Checkbox, Header } from 'semantic-ui-react';
import { Input, Range, Select, SelectInt, SweetForm, List as SweetList, customOperator, enhance } from 'sweetform';
import { ItemFilter, wrapFilterToIdParams, colorFromName } from './common';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import baseUrl from '../../baseUrl';

const SelectSegment = enhance(() => (
  <div>
    <Input field='name' placeholder="segment name" />
    <span>filters :</span>
    <SweetList field="filters" component={ItemFilter} />
  </div>
))
const TYPE_OPTIONS = [
  { value: 'field', label: 'From field' },
  { value: 'segments', label: 'From segments' },
]
const FIELD_OPTIONS = [
  { label: "Categories", value: "categories" },
  { label: "Jobs", value: "job_ids" },
  { label: "Skills", value: "skill_ids" },
  { label: "Seniority", value: "seniority_ids" },
  { label: "Location", value: "location_ids" },
]
const MODE_OPTIONS = [
  { label: "Exclusive", value: "exclusive" },
  { label: "Exhaustive", value: "exhaustive" },
]
const SelectSegmentationDefinition = enhance((props) => {
  const { value } = props;
  const type = value ? value.get('type') : '';
  return (
    <div>
      <b>Base Filter</b>
      <SweetList field="filters" component={ItemFilter} />
      <b>Mode</b>
      <Select field="mode" defaultValue='exclusive' options={MODE_OPTIONS} />
      <b>Type</b>
      <Select field='type' defaultValue='field' options={TYPE_OPTIONS} />
      {type === 'field' ? (
        <div>
          <b>Field</b>
          <Select field='field' options={FIELD_OPTIONS} defaultValue='categories' />
        </div>
      ) : null}
      {type === 'segments' ? (
        <div>
          <b>Segments</b>
          <SweetList field="segments" component={SelectSegment} />
        </div>
      ) : null}
    </div>
  );
});



class SegmentationDefinitionEditor extends Component {
  state = {
    segmentationDefinition: null
  }
  handleSave = () => {
    this.props.handleSave({ segmentationDefinition: this.state.segmentationDefinition })
  }

  handleChange = ({ params }) => {
    const { filters, type, mode, segments, field } = params;
    const segmentationDefinition = {
      filters: _.map(filters, (filter) => wrapFilterToIdParams(filter)),
      type,
      mode,
      ...field && type == "field" && { field },
      ...segments && type == "segments" && {
        segments: _.map(segments, ({ name, filters }) => ({
          name,
          filters: _.map(filters, (filter) => wrapFilterToIdParams(filter)),
        }))
      }
    }
    this.setState({ segmentationDefinition })
  }

  render() {
    return (
      <div>
        <SweetForm onChange={this.handleChange} initialValues={{ params: { filters: [{ "selection": "isActive" }] } }}>
          <SelectSegmentationDefinition field="params" />
        </SweetForm>
        <Button color='blue' onClick={() => this.handleSave()} > Save </Button>
      </div>
    )
  }
}

const keepMostOccurentSegments = ({ segments, maxNb }) => {
  if (segments.length <= maxNb) {
    return segments;
  }
  const sortedSegments = _.sortBy(segments, "count").reverse();
  const otherSegment = {
    segment: "other",
    count: _.pluck(_.last(sortedSegments, sortedSegments.length - maxNb), "count").reduce((partialSum, a) => partialSum + a, 0)
  }
  const newSegments = _.first(sortedSegments, maxNb).concat([otherSegment])
  console.log(segments)
  console.log(newSegments)
  return newSegments
}

const makePieChart = ({ segments }) => {
  const segmentsToDisplay = keepMostOccurentSegments({
    segments: _.filter(segments, ({ segment }) => !!segment),
    maxNb: 50,
  })
  const data = _.map(segmentsToDisplay, ({ segment, count }) => ({
    name: segment,
    value: count,
    fill: colorFromName(segment)
  }))

  return (
    <div>
      <PieChart width={600} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={120}
          dataKey="value"
          label={({ name, value }) => (`${name} (${value})`)}
        >
        </Pie>
      </PieChart>
    </div>
  )
}

const getCandidatesSegments = async ({ segmentationDefinition }) => {
  const { data } = await axios.post(`${baseUrl}/watchTables/candidates/segmentation`, { segmentationDefinition });
  if (data.error) {
    throw Error(data.error)
  }
  return data.segmentation
}

const getJobsSegments = async ({ segmentationDefinition }) => {
  const { data } = await axios.post(`${baseUrl}/watchTables/jobOffers/segmentation`, { segmentationDefinition });
  if (data.error) {
    throw Error(data.error);
  }
  return data.segmentation;
}

const getSendsSegments = async ({ segmentationDefinition }) => {
  const actionFilter = { "types": ["send", "move-to-contacted"] }
  const { data } = await axios.post(`${baseUrl}/watchTables/actions/segmentationFromCandidates`, { segmentationDefinition, actionFilter });
  if (data.error) {
    throw Error(data.error);
  }
  return data.segmentation;
}

const getPositiveAnswersSegments = async ({ segmentationDefinition }) => {
  const actionFilter = { "types": ["answer-detected"], "labels": ["positive"] }
  const { data } = await axios.post(`${baseUrl}/watchTables/actions/segmentationFromCandidates`, { segmentationDefinition, actionFilter });
  if (data.error) {
    throw Error(data.error);
  }
  return data.segmentation;
}
class PieChartsViewer extends Component {
  state = {
    segmentationDefinition: null,
    candidatesSegments: null,
    jobsSegments: null,
  };

  handleChangeSegmentationDefinition = async ({ segmentationDefinition }) => {
    try {
      const [candidatesSegments, jobsSegments, sendsSegments, positiveAnswerSegments] = await Promise.all([
        getCandidatesSegments({ segmentationDefinition }),
        getJobsSegments({ segmentationDefinition }),
        getSendsSegments({ segmentationDefinition }),
        getPositiveAnswersSegments({ segmentationDefinition })
      ]);
      this.setState({
        segmentationDefinition,
        candidatesSegments,
        jobsSegments,
        sendsSegments,
        positiveAnswerSegments
      });
    } catch (e) {
      console.log(e)
      alert(e)
    }
  }

  render() {
    const { jobsSegments, candidatesSegments, sendsSegments, positiveAnswerSegments } = this.state;
    return (
      <Grid columns={2}>
        <Grid.Column width={4}>
          <SegmentationDefinitionEditor handleSave={this.handleChangeSegmentationDefinition} />
        </Grid.Column>
        <Grid.Column width={12} centered aligned >
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Header as='h2'>Candidates</Header>
              {candidatesSegments && makePieChart({ segments: candidatesSegments })}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h2'>Jobs</Header>
              {jobsSegments && makePieChart({ segments: jobsSegments })}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Header as='h2'>Sends</Header>
              {sendsSegments && makePieChart({ segments: sendsSegments })}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h2'>Positive Answers</Header>
              {positiveAnswerSegments && makePieChart({ segments: positiveAnswerSegments })}
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }
}
export default PieChartsViewer;
