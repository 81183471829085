
import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl';
import moment from "moment";
import { SweetForm, Select, SelectInt, Checkbox } from 'sweetform';
import {
  Grid,
  Table,
  Form,
  Segment,
  Button,
  Icon
} from 'semantic-ui-react';
import Pagination from '../Pagination';
import { typeStyles } from './actionsReveal';


const DEFAULT_LAST_NB_HOURS = 1;
const PER_PAGE = 100;

const ActionsSearchForm = ({
  clientOptions,
  onChange,
  search,
}) => {
  const sinceOptions = _.map(_.range(1, 100), (num) => ({
    value: num,
    label: `Last ${num} hours`,
  }));
  const clientsFormattedOptions = _.map(
    clientOptions,
    ({ id, name }) => ({ value: id, label: `${id} (${name})` }),
  );
  return (
    <SweetForm
      onChange={onChange}
      initialValues={search}
      key={JSON.stringify(search || {}).length}
    >
      <Form>
        <Form.Field>
          <Select
            field="clientIds"
            placeholder="Client"
            options={clientsFormattedOptions}
          />
        </Form.Field>
        <Form.Field>
          <Select field="lastNbHours" options={sinceOptions} defaultValue={1} />
        </Form.Field>
      </Form>
    </SweetForm>
  )
}

const getFormattedDate = (date) => {
  const dateObj = new Date(date);
  return date && dateObj
    ? dateObj.toDateString() + ' - ' + dateObj.toLocaleTimeString()
    : null;
};

const ActionsList = ({
  actions,
  page,
  onPage,
  selectedAction,
  onSelectAction,
}) => {
  return (
    <div>
      <div style={{ position: 'relative', height: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Date</Table.HeaderCell>
              <Table.HeaderCell width={3}>Client</Table.HeaderCell>
              <Table.HeaderCell width={5}>Action</Table.HeaderCell>
              <Table.HeaderCell width={4}>Author</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              _.map(actions.results, (action, key) => {
                const { date, clientId, author, type, task, actionId } = action || {};
                const isSelected = selectedAction && actionId == selectedAction.actionId;
                return (
                  <Table.Row
                    key={key}
                    style={{
                      cursor: "pointer",
                      ...isSelected && { backgroundColor: "#b9d0dd" }
                    }}
                    onClick={() => onSelectAction(action)}
                  >
                    <Table.Cell>
                      {date && getFormattedDate(date)}
                    </Table.Cell>
                    <Table.Cell>
                      {clientId}
                    </Table.Cell>
                    <Table.Cell>
                      <span style={{ ...typeStyles[action.type] }}>
                        {type}
                      </span>
                      {(task && task.type) && (
                        <span>({task.type})</span>
                      )}

                    </Table.Cell>
                    <Table.Cell>
                      {author && author.email}
                    </Table.Cell>
                  </Table.Row>
                )
              }
              )
            }
          </Table.Body>
        </Table>
      </div>
      <Pagination floated='right' current={page} total={100} onPage={onPage} />
    </div>
  )
}


const ActionDetails = ({ action }) => (
  <Segment secondary> <pre>{JSON.stringify(action, null, '  ')}</pre> </Segment>
)

class ActionsStream extends Component {
  state = {
    page: 0,
    search: {},
  };

  componentDidMount = async () => {
    await this.onLoadFilterOptions();
    await this.onLoadActions({}, 0);
    this.intervalId = setInterval(this.refreshActions, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  refreshActions = async () => {
    if (this.state.loading) {
      return;
    }
    const { search, page } = this.state;
    await this.onLoadActions(search, page);
  }

  onLoadFilterOptions = async () => {
    const clients = (await axios.get(`${baseUrl}/station/clients`)).data;
    const clientOptions = _.map(clients, ({ id, name }) => ({ id, name }));
    this.setState({ clientOptions });
  }

  onLoadActions = async (search, page) => {
    this.setState({ loading: true })
    const { lastNbHours, clientIds } = search || {};
    const minTimeStamp = 1000 * moment().subtract(lastNbHours || DEFAULT_LAST_NB_HOURS, 'hours').unix();
    const body = {
      onlyReveal: true,
      noTypeFilter: "true",
      withFullData: true,
      minTimeStamp,
      ...(!_.isEmpty(clientIds) && { clientIds }),
    };
    const { data } = await axios.post(
      `${baseUrl}/station/actions?limit=${PER_PAGE}&skip=${PER_PAGE * (page || 0)}`,
      body,
    );
    this.setState({ actions: data, search, page, loading: false })
  }

  onSelectAction = (action) => {
    this.setState({ selectedAction: action })
  }

  render() {
    const {
      actions,
      page,
      search,
      clientOptions,
      selectedAction,
      loading
    } = this.state;
    return (
      <div>
        <Grid columns={3}>
          <Grid.Column width={2}>
            <ActionsSearchForm
              clientOptions={clientOptions}
              onChange={(s) => this.onLoadActions(s, page)}
              search={search}
            />
            <Button onClick={() => this.onLoadActions(search, page)}>Refresh</Button>
            {' '}
            {loading && <Icon name='circle notched' loading />}
          </Grid.Column>
          <Grid.Column width={9}>
            {actions && (
              <ActionsList
                actions={actions}
                page={page}
                onPage={(i) => this.onLoadActions(search, i)}
                selectedAction={selectedAction}
                onSelectAction={(action) => this.onSelectAction(action)}
              />
            )}
          </Grid.Column>
          <Grid.Column width={5}>
            {selectedAction && (
              <ActionDetails
                action={selectedAction}
              />
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}


export default ActionsStream;