import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';

import { Form, Grid } from 'semantic-ui-react';

import { Input, SweetForm, Select, Range } from 'sweetform';

import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import baseUrl from './baseUrl';

// Actions
const SET_PARAMS = 'SET_PARAMS';
const setParams = (params) => ({ type: SET_PARAMS, params });

const SET_RESULTS = 'SET_RESULTS';
const setResults = (results, params) => ({
  type: SET_RESULTS,
  results,
  params,
});

// Reducers
const params = (state = {}, action) => {
  switch (action.type) {
    case SET_PARAMS:
      return {
        ...state,
        ...action.params,
      };
    case SET_RESULTS:
      return {
        ...state,
        ...action.params,
      };
    default:
      return state;
  }
};

const results = (state = [], action) => {
  switch (action.type) {
    case SET_RESULTS:
      return action.results;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  params,
  results,
});

// Store
const store = createStore(rootReducer);

const ColorHash = require('color-hash');
const colorHash = new ColorHash({ saturation: 0.8 });

const ReportChart = ({ results, selectedJobPositions, scoreField, domain }) => (
  <ResponsiveContainer width="100%" height={600}>
    <ScatterChart>
      <CartesianGrid />
      <YAxis
        type="number"
        dataKey={'score'}
        name={scoreField}
        domain={domain}
        label={{ value: 'Score', angle: -90, position: 'insideLeft' }}
      />
      <XAxis
        type="number"
        dataKey={'timestampDays'}
        name="timestamp"
        domain={['auto', 'auto']}
        label={{ value: 'Date', offset: 0, position: 'insideBottom' }}
      />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      <Legend />
      {_.map(selectedJobPositions, (jobPosition) => (
        <Scatter
          key={jobPosition.id}
          name={jobPosition.id}
          data={_.filter(
            results,
            (x) =>
              (typeof x.entityName === 'undefined') |
              (x.entityName === jobPosition.id),
          )}
          fill={colorHash.hex(jobPosition.id)}
          line
        />
      ))}
    </ScatterChart>
  </ResponsiveContainer>
);

const SelectJobPositions = (props) => {
  const options = [
    { id: 'full-stack', name: 'Fullstack' },
    { id: 'web-frontend', name: 'Web Frontend' },
    { id: 'web-backend', name: 'Web Backend' },
    { id: 'mobile-developer', name: 'Mobile Developer' },
    { id: 'devops', name: 'Devops' },
    { id: 'data-science', name: 'Data Science' },
    { id: 'data-engineering', name: 'Data Engineering' },
    { id: 's6_merger', name: 'S6Merger' },
    { id: 's6_merger_neg', name: 'S6MergerNeg' },
    { id: 's7_merger', name: 'S7Merger' },
    { id: 'ruby', name: 'ruby' },
    { id: 'php', name: 'php' },
    { id: 'natural-language-processing', name: 'nlp' },
    { id: 'quality-engineer', name: 'Quality Engineer' },
    { id: 'web-design', name: 'Web Design' },
    { id: 'java', name: 'java' },
    { id: 'vue.js', name: 'vue.js' },
    { id: 'angular.js', name: 'angular.js' },
    { id: 'node.js', name: 'node.js' },
    { id: 'django', name: 'django' },
    { id: 'scala', name: 'scala' },
    { id: 'swift', name: 'swift' },
    { id: 'symfony', name: 'symfony' },
    { id: 'android-development', name: 'android-development' },
    { id: 'ios-development', name: 'ios-development' },
    { id: 'mobile-development', name: 'mobile-development' },
    { id: 'react-native', name: 'react-native' },
    { id: 'api', name: 'api' },
    { id: 'amazon-web-services', name: 'amazon-web-services' },
    { id: 'sql', name: 'sql' },
    { id: 'docker', name: 'docker' },
    { id: 'mongodb', name: 'mongodb' },
    { id: 'cpp', name: 'cpp' },
    { id: 'elixir', name: 'elixir' },
    { id: 'kotlin', name: 'kotlin' },
    { id: 'flask', name: 'flask' },
    { id: 'csharp', name: 'csharp' },
    { id: 'objective-c', name: 'objective-c' },
    { id: 'redux.js', name: 'redux.js' },
    { id: 'go', name: 'go' },
    { id: '_mean', name: 'Mean' },
  ];
  return (
    <Select
      {...props}
      options={options}
      labelKey="name"
      valueKey="id"
      multi="true"
      defaultValue={options}
    />
  );
};

const OWNERS = [
  { label: 'Root', value: 'root' },
  { label: 'Luc', value: 'luc' },
  { label: 'Valentin', value: 'valentin' },
  { label: 'Victor', value: 'victor' },
  { label: 'Rémils', value: 'rlescaon' },
];
const MODELS = [
  { label: 'S6JobPosition', value: 'S6JobPosition' },
  { label: 'S6JobPositionFallback', value: 'S6JobPositionFallback' },
  { label: 'GenericS6SkillModel', value: 'GenericS6SkillModel' },
  { label: 'S6Merger', value: 'S6Merger' },
  { label: 'S7Merger', value: 'S7Merger' },
  { label: 'S8Merger', value: 'S8Merger' },
  { label: 'S9LassoMerger', value: 'S9LassoMerger' },
  { label: 'S9XGBMerger', value: 'S9Merger' },
];
const MODELSROUTES = {
  S6JobPosition: 's6models',
  S6JobPositionFallback: 's6models',
  GenericS6SkillModel: 's6models',
  S6Merger: 'S6Merger',
  S7Merger: 'S7Merger',
  S8Merger: 's6models',
  S9LassoMerger: 's6models',
  S9Merger: 's6models',
};
const MODELSDOMAINS = {
  S6JobPosition: [40, 100],
  S6JobPositionFallback: [40, 100],
  GenericS6SkillModel: [40, 100],
  S6Merger: [0, 1],
  S7Merger: [0, 1],
  S9LassoMerger: [0.6, 0.8],
  S9Merger: [0.6, 0.8],
  S8Merger: [40, 100],
};

class ModelsMonitoring extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { results, onSetParamsWithCall, onSetParams, params } = this.props;
    return (
      <Grid>
        <Grid.Column width={13}>
          <ReportChart
            results={_.map(
              _.filter(
                results,
                (x) =>
                  (typeof x.scores[params.scoreField] !== 'undefined') &
                  ((params.poolIdentifier === 'all') |
                    (x.poolIdentifier === params.poolIdentifier) |
                    (typeof x.poolIdentifier === 'undefined')),
              ),
              (x) => ({
                ...x,
                score:
                  x.scores[params.scoreField] === null
                    ? 0
                    : x.scores[params.scoreField].toFixed(3),
                timestampDays: (
                  (x.timestamp - Date.now() / 1000) /
                  (24 * 3600)
                ).toFixed(2),
              }),
            )}
            selectedJobPositions={params.jobPositions}
            scoreField={params.scoreField}
            domain={MODELSDOMAINS[params.modelName]}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <Form>
            <SweetForm
              onChange={onSetParamsWithCall}
              initialValues={{ timeRange: { min: -100, max: 0 } }}
            >
              <Form.Field>
                <label>Model name:</label>
                <Select
                  options={MODELS}
                  field="modelName"
                  placeholder="Type"
                  defaultValue="S6JobPosition"
                />
                <label>Owner:</label>
                <Select
                  options={OWNERS}
                  field="owner"
                  placeholder="Type"
                  defaultValue="root"
                />
                <label>Limit:</label>
                <Input field="limit" defaultValue={1000} type="number" />
                <label>Time range:</label>
                <Range field="timeRange" min={-365} max={0} step={1} />
              </Form.Field>
            </SweetForm>
            <SweetForm onChange={onSetParams}>
              <Form.Field>
                <label>Score field:</label>
                <Select
                  options={params.possibleScores}
                  field="scoreField"
                  placeholder="Type"
                  defaultValue={params.scoreField}
                />
                <label>Pool identifier:</label>
                <Select
                  options={params.possiblePools}
                  field="poolIdentifier"
                  placeholder="Type"
                  defaultValue={params.poolIdentifier}
                />
                <label>Job positions:</label>
                <SelectJobPositions field="jobPositions" />
              </Form.Field>
            </SweetForm>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapSModelsMonitoring = (state) => ({
  params: state.params,
  results: state.results,
});

const mapDModelsMonitoring = (dispatch) => ({
  onLoad: async () => {
    const results = (await axios.post(`${baseUrl}/monitoring/s6models`, {
      user: 'root',
      limit: 1000,
      timestampLte: Date.now() / 1000,
      timestampGte: Date.now() / 1000 - 365 * 24 * 3600,
      modelName: 'S6JobPosition',
    })).data;
    const possibleScores = _.map(Object.keys(results[0].scores), (x) => ({
      label: x,
      value: x,
    }));
    const possiblePools = _.map(
      ['all', ...new Set(_.map(results, (x) => x.poolIdentifier))],
      (x) => ({ label: typeof x === 'undefined' ? 'undefined' : x, value: x }),
    );
    dispatch(
      setResults(results, {
        ...params,
        possiblePools: possiblePools,
        possibleScores: possibleScores,
        scoreField: possibleScores[0].value,
        poolIdentifier: 'light_search_pool',
      }),
    );
  },
  onSetParamsWithCall: async (params) => {
    const results = (await axios.post(
      `${baseUrl}/monitoring/${MODELSROUTES[params.modelName]}`,
      {
        user: params.owner,
        limit: params.limit,
        timestampLte: Date.now() / 1000 + params.timeRange.max * 24 * 3600,
        timestampGte: Date.now() / 1000 + params.timeRange.min * 24 * 3600,
        modelName: params.modelName,
      },
    )).data;
    const possibleScores =
      results.length === 0
        ? [{ label: 'None', value: 'None' }]
        : _.map(Object.keys(results[0].scores), (x) => ({
          label: x,
          value: x,
        }));
    const possiblePools = _.map(
      ['all', ...new Set(_.map(results, (x) => x.poolIdentifier))],
      (x) => ({ label: typeof x === 'undefined' ? 'undefined' : x, value: x }),
    );
    dispatch(
      setResults(results, {
        ...params,
        possibleScores: possibleScores,
        possiblePools: possiblePools,
        scoreField: possibleScores[0].value,
        poolIdentifier: 'light_search_pool',
      }),
    );
  },
  onSetParams: async (params) => {
    dispatch(setParams(params));
  },
});

const ModelsMonitoringContainer = connect(
  mapSModelsMonitoring,
  mapDModelsMonitoring,
)(ModelsMonitoring);

export default () => (
  <Provider store={store}>
    <ModelsMonitoringContainer />
  </Provider>
);
