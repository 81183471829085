var Options = {
  Days: [{
    value: 0,
    label: 'Mon'
  }, {
    value: 1,
    label: 'Tue'
  }, {
    value: 2,
    label: 'Wed'
  }, {
    value: 3,
    label: 'Thu'
  }, {
    value: 4,
    label: 'Fri'
  }, {
    value: 5,
    label: 'Sat'
  }, {
    value: 6,
    label: 'Sun'
  }],
  Months: [{
    value: 0,
    label: 'Jan'
  }, {
    value: 1,
    label: 'Feb'
  }, {
    value: 2,
    label: 'Mar'
  }, {
    value: 3,
    label: 'Apr'
  }, {
    value: 4,
    label: 'May'
  }, {
    value: 5,
    label: 'Jun'
  }, {
    value: 6,
    label: 'Jul'
  }, {
    value: 7,
    label: 'Aug'
  }, {
    value: 8,
    label: 'Sep'
  }, {
    value: 9,
    label: 'Oct'
  }, {
    value: 10,
    label: 'Nov'
  }, {
    value: 11,
    label: 'Dec'
  }]
};
export default Options;