import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Form, Header, Icon, List, Segment, Table, Modal } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, Line, CartesianGrid, Tooltip, AreaChart, Area } from 'recharts';
import { Grid, Checkbox } from 'semantic-ui-react';
import { throws } from 'assert';
import moment from 'moment/moment';
import { SweetForm, Select, Input } from 'sweetform';
import { Textarea } from '../common';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

const policyOptions = [
  {
    label: 'Hourly',
    value: 'hourly',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
];

const schemaToSortKeyOption = (schema) => {
  return _.map(_.filter(schema, (row) => row.fieldType == 'INTEGER'), (row) => ({
    label: row.name,
    value: row.name,
  }));
};

const schemaToDistributionKeyOption = (schema) => {
  return _.map(
    _.filter(
      schema,
      (row) =>
        row.fieldType == 'INTEGER' ||
        row.fieldType == 'FLOAT' ||
        row.fieldType == 'STRING' ||
        row.fieldType == 'BOOLEAN',
    ),
    (row) => ({
      label: row.name,
      value: row.name,
    }),
  );
};

class DistributionView extends Component {
  render() {
    const { values, title } = this.props;
    return (
      <Segment>
        <Header as='h3' textAlign='left'>
          {title}
        </Header>
        <AreaChart
          width={500}
          height={400}
          data={values}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='key' />
          <YAxis />
          <Tooltip />
          <Area type='monotone' dataKey='value' stroke='#8884d8' fill='#8884d8' />
        </AreaChart>
      </Segment>
    );
  }
}

class AddTrackerModal extends Component {
  handleChange(params) {
    const { tableId, datasetId, projectId } = this.props || {};
    const tableDefinition = '`' + projectId + '.' + datasetId + '.' + tableId + '`';
    const whereQuery = params.gcpQuery;
    const operatorQuery = params.operatorQuery;
    const newSQLQuery = 'SELECT ' + operatorQuery + ' FROM ' + tableDefinition + ' WHERE ' + whereQuery;
    this.setState({ currentSQLQuery: newSQLQuery, params: params });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const { currentSQLQuery } = this.state || {};
    return (
      <Modal open closeIcon open={true} headerText={`Create tracker for table`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Name</label>
              <Input autoFocus field='name' />
            </Form.Field>
            <Form.Field>
              <label>Policy</label>
              <Select field='policy' options={policyOptions} />
            </Form.Field>
            <Form.Field>
              <label>Operator</label>
              <Textarea
                style={{ width: '100%' }}
                field='operatorQuery'
                label='Raw Query Editor'
                placeholder='Write operator - ex: Count(*)'
              />
            </Form.Field>
            <Form.Field>
              <label>GCP Query</label>
              <Textarea
                style={{ width: '100%' }}
                field='gcpQuery'
                label='Raw Query Editor'
                placeholder='Write filter query - ex: job__data_science >= 45'
              />
            </Form.Field>
          </SweetForm>
          <b>Preview :</b> {currentSQLQuery}
          <div style={{ height: '200px' }} />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state.params, this.state.currentSQLQuery)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class AddDistributionModal extends Component {
  handleChange(params) {
    this.setState({ ...params });
  }

  render() {
    const { onSubmit, onCancel, schema } = this.props;
    return (
      <Modal open closeIcon open={true} headerText={`Create tracker for table`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Title</label>
              <Input autoFocus field='title' />
            </Form.Field>
            <Form.Field>
              <label>Field</label>
              <Select
                style={{ width: '100%' }}
                field='projectionKey'
                options={schemaToDistributionKeyOption(schema)}
                placeholder='skill__python'
              />
            </Form.Field>
          </SweetForm>
          <div style={{ height: '200px' }} />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state.projectionKey, this.state.title)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class GBQInformationCollectionView extends Component {
  componentDidMount() {
    this.getTrackerInformation();
    this.getDistributionInformation();
  }

  getDistributionInformation = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getDistributions';
    const distributions = (await axios.get(url, customHeaders)).data;
    this.setState({ distributions: (distributions.data || {}).results || [] });
  };

  getRelevantTrackerIds = async () => {
    const { projectId, datasetId, tableId } = this.props;
    const payload = {
      projectId,
      datasetId,
      tableId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getBigQueryTrackerIds';
    const data = (await axios.post(url, payload, customHeaders)).data;
    return data;
  };

  getSingleTrackerInformation = async (trackerId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getTrackerPoints/' + trackerId;
    const result = (await axios.get(url, customHeaders)).data;
    return result;
  };

  getTrackerInformation = async () => {
    const trackerIds = await this.getRelevantTrackerIds();
    var trackers = [];
    var trackerIdToDifferenceMode = {};
    for (var i = 0; i < trackerIds.length; i++) {
      const trackerData = await this.getSingleTrackerInformation(trackerIds[i].id);
      trackers.push({
        trackerId: trackerIds[i].id,
        trackerData: trackerData,
        name: trackerIds[i].name,
        mode: 'absolute',
      });
      trackerIdToDifferenceMode[trackerIds[i].id] = false;
    }
    this.setState({
      allTrackerInformation: trackers,
    });

    //this.setState({
    //  trackerIdToDifferenceMode,
    //});
  };

  deleteTracker = async (trackerId) => {
    const payload = {
      trackerId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteTracker';
    const data = (await axios.post(url, payload, customHeaders)).data;
    this.getTrackerInformation();
  };

  async handleToggleModeDifference(trackerId) {
    const oldTrackers = this.state.allTrackerInformation;
    const tracker = _.filter(oldTrackers, (tracker) => tracker.trackerId == trackerId)[0];
    const newMode = tracker.mode == 'absolute' ? 'diff' : 'absolute';
    const newTrackers = _.map(oldTrackers, (tracker) => {
      if (tracker.trackerId == trackerId) {
        return {
          ...tracker,
          mode: newMode,
        };
      } else {
        return tracker;
      }
    });
    await this.setState({ allTrackerInformation: newTrackers });
  }

  plotTracker(trackerId, trackerName, trackerMode) {
    const difference = trackerMode == 'diff';
    const timeFormatter = (tick) => {
      return moment(new Date(tick)).format('YY-MM-DD');
    };
    const trackerInformation = _.find((this.state || {}).allTrackerInformation || [], { trackerId: trackerId });
    const trackerData = (trackerInformation || {}).trackerData;
    var plotData = [];

    if (difference) {
      const sortedTrackerData = _.sortBy(trackerData, 'timestamp');
      const indexedTrackerData = {};
      for (var idx = 0; idx < sortedTrackerData.length; idx++) {
        indexedTrackerData[idx] = sortedTrackerData[idx];
      }
      plotData = [];
      for (var i = 0; i < sortedTrackerData.length - 1; i++) {
        plotData.push({
          x: timeFormatter(sortedTrackerData[i + 1].timestamp),
          y: sortedTrackerData[i + 1].value - sortedTrackerData[i].value,
        });
      }
    } else {
      plotData = _.sortBy(
        _.map(trackerData, (stat) => ({
          x: timeFormatter(stat.timestamp),
          y: stat.value,
        })),
        'x',
      );
    }

    return (
      <Segment>
        <Header as='h3' textAlign='left'>
          {trackerName}
        </Header>
        <Checkbox slider onChange={() => this.handleToggleModeDifference(trackerId)} checked={difference} />
        <LineChart
          width={500}
          height={250}
          type='line'
          data={plotData}
          margin={{ top: 0, right: 5, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <Line type='monotone' dataKey='y' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <Tooltip />
          <YAxis
            label={{
              angle: -90,
              position: 'insideLeft',
              dy: 70,
              dx: 20,
            }}
          />
        </LineChart>
        <Icon style={{ cursor: 'pointer' }} name='delete' color='red' onClick={() => this.deleteTracker(trackerId)} />
        <b style={{ color: 'red' }}> Delete Tracker </b>
      </Segment>
    );
  }

  toggleSchemaDisplay() {
    const previousSchemaIsExpanded = (this.state || {}).schemaIsExpanded == true;
    this.setState({ schemaIsExpanded: !previousSchemaIsExpanded });
  }

  handleClickOnAddTracker() {
    this.setState({ trackerModalVisible: true });
  }
  handleClickOnAddDistribution() {
    this.setState({ distributionModalVisible: true });
  }

  onCancel = () => {
    this.setState({ trackerModalVisible: false });
  };

  onCancelDistribution = () => {
    this.setState({ distributionModalVisible: false });
  };

  onSubmit = async (params, sqlQuery) => {
    if (!params.name) {
      alert('You Must Specify a name');
      return;
    }
    if (!params.gcpQuery) {
      alert('You Must Specify a query');
      return;
    }
    if (!params.policy) {
      alert('You Must Specify a policy');
      return;
    }
    const { projectId, datasetId, tableId } = this.props;
    const payload = {
      projectId,
      datasetId,
      tableId,
      policy: params.policy,
      name: params.name,
      gcpQuery: sqlQuery,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createTrackerBigQuery';
    const result = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ trackerModalVisible: false });
    this.getTrackerInformation();
  };

  onSubmitDistribution = async (projectionKey, title, setId) => {
    const payload = {
      title: title,
      projectionKey: projectionKey,
      setId: setId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createDistribution';
    const result = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ distributionModalVisible: false });
    this.getDistributionInformation();
  };

  async handleRefreshQuery(id) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/refreshDistribution/' + id;
    const data = (await axios.get(url, customHeaders)).data;
    if (data.success == false) {
      alert('Error happened');
      return;
    }
    this.getDistributionInformation();
  }

  async deleteDistribution(id) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteDistribution/' + id;
    const data = (await axios.get(url, customHeaders)).data;
    if (data.success == false) {
      alert('Error happened');
      return;
    }
    this.getDistributionInformation();
  }

  render() {
    const { datasetId, tableId, projectId, type, schema, keyType, setId } = this.props;
    const columnNames = [{ name: 'name' }, { name: 'fieldType' }, { name: 'mode' }];
    const { schemaIsExpanded } = this.state || {};
    const { trackerModalVisible, distributionModalVisible } = this.state || {};
    const allTrackerInformation = (this.state || {}).allTrackerInformation || [];
    const distributions = (this.state || {}).distributions || [];
    return (
      <div>
        {trackerModalVisible ? (
          <AddTrackerModal
            tableId={tableId}
            datasetId={datasetId}
            projectId={projectId}
            onSubmit={(params, sqlQuery) => this.onSubmit(params, sqlQuery)}
            onCancel={this.onCancel}
          />
        ) : null}
        {distributionModalVisible ? (
          <AddDistributionModal
            schema={schema}
            onSubmit={(projectionKey, title) => this.onSubmitDistribution(projectionKey, title, setId)}
            onCancel={this.onCancelDistribution}
          />
        ) : null}
        <List>
          {projectId ? (
            <List.Item>
              <b>{'Project ID : ' + projectId} </b>
            </List.Item>
          ) : null}
          {datasetId ? (
            <List.Item>
              <b>{'Dataset ID : ' + datasetId} </b>
            </List.Item>
          ) : null}
          {tableId ? (
            <List.Item>
              <b>{'Table ID : ' + tableId} </b>
            </List.Item>
          ) : null}
          <List.Item>
            <b>{'Type : ' + type} </b>
          </List.Item>
          <List.Item>
            <b>{'Meta Type : ' + keyType} </b>
          </List.Item>
          <List.Item>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => this.toggleSchemaDisplay()}>
                {schemaIsExpanded ? 'Collapse schema' : 'Display schema'}
              </a>
              {schema ? (
                <div>
                  {schemaIsExpanded ? (
                    <div
                      style={{
                        display: 'inline-block',
                        paddingTop: '5px',
                        paddingBottom: '20px',
                        height: '300px',
                        width: '100%',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                      }}
                    >
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            {_.map(columnNames, (item, index) => {
                              return (
                                <Table.HeaderCell key={index} textAlign='left'>
                                  {item.name}
                                </Table.HeaderCell>
                              );
                            })}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {_.map(schema, (row, index) => (
                            <Table.Row key={index}>
                              <Table.Cell textAlign='left'>{row.name}</Table.Cell>
                              <Table.Cell textAlign='left'>{row.fieldType}</Table.Cell>
                              <Table.Cell textAlign='left'>{row.mode}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </List.Item>
        </List>
        <Header as='h2'>Trackers :</Header>
        <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnAddTracker()}>
          <Icon name='add' size='big' color='green' /> <b style={{ color: 'green' }}> Add Tracker</b>
        </a>
        <br /> <br />
        <Grid columns={2}>
          {_.map(allTrackerInformation, (trackerInfo, index) => (
            <Grid.Column key={index}>
              {this.plotTracker(trackerInfo.trackerId, trackerInfo.name, trackerInfo.mode)}
            </Grid.Column>
          ))}
        </Grid>
        <Header as='h2'>Distributions :</Header>
        <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnAddDistribution()}>
          <Icon name='add' size='big' color='green' /> <b style={{ color: 'green' }}> Add Distribution</b>
        </a>
        <br /> <br />
        <Grid columns={2}>
          {_.map(distributions, (distribution, index) => (
            <Grid.Column key={index}>
              <DistributionView
                id={distribution.id}
                title={distribution.title}
                values={distribution.values}
                refreshDistribution={this.getDistributionInformation}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => this.handleRefreshQuery(distribution.id)}>
                <Icon name='refresh' />
                <a> refresh distribution </a>
              </div>
              <Icon
                style={{ cursor: 'pointer' }}
                name='delete'
                color='red'
                onClick={() => this.deleteDistribution(distribution.id)}
              />
              <b style={{ color: 'red' }}> Delete Distribution </b>
            </Grid.Column>
          ))}
        </Grid>
      </div>
    );
  }
}
export default GBQInformationCollectionView;
