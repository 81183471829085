import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import React from 'react';
import { Modal, Grid, Table, Loader, Icon } from 'semantic-ui-react';
import WidgetPreviewModal from './WidgetPreviewModal';
import WidgetEditionModal from './WidgetEditionModal';

const getRandomString = (size) => {
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let text = "";
  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};


class StatsWidgets extends React.Component {
  state = {
    loading: true,
    widgets: undefined,
  }

  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/stats/widgets`);
    if (data.error) {
      return alert(data.error);
    }
    this.setState({
      widgets: data
    });
  }

  handleOpenWidgetCreationModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-creation',
        widget: {
          ...widget,
          id: getRandomString(6),
          title: 'Untitled',
          creationTimestamp: Date.now()
        }
      }
    });
  }

  handleOpenWidgetEditionModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-edition',
        widget: widget
      }
    });
  }

  handleOpenWidgetPreviewModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-preview',
        widget: widget
      }
    });
  }

  handleCloseModal = () => {
    this.setState({
      modal: undefined
    });
  }

  handleSaveEditedWidget = async ({ widget }) => {
    try {
      if (!widget.id) {
        throw Error('need widget id');
      }
      const { data } = await axios.put(`${baseUrl}/stats/widgets/${widget.id}`, { widget });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  }

  handleSaveCreatedWidget = async ({ widget }) => {
    try {
      if (!widget.id) {
        throw Error('need widget id');
      }
      const { data } = await axios.post(`${baseUrl}/stats/widgets`, { widget });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {

    const { widgets, modal } = this.state;

    if (widgets === undefined) {
      return <span>Loading...</span>
    }

    return (
      <div>
      <Grid>
      <Grid.Row>
      <Grid.Column width={12}> 
      <h3>Widgets</h3>
      </Grid.Column>
      <Grid.Column width={4} textAlign="right"> 
      <a 
      style={{ cursor: 'pointer' }} 
      onClick={() => this.handleOpenWidgetCreationModal({ widget: {} })}
      >
      Add widget
      </a>
      </Grid.Column>
      </Grid.Row>
      </Grid>
      <Table>
      <Table.Header>
      <Table.Row>
      <Table.HeaderCell>owner</Table.HeaderCell>
      <Table.HeaderCell>type</Table.HeaderCell>
      <Table.HeaderCell>id</Table.HeaderCell>
      <Table.HeaderCell>title</Table.HeaderCell>
      <Table.HeaderCell textAlign='center'>edit</Table.HeaderCell>
      <Table.HeaderCell textAlign='center'>preview</Table.HeaderCell>
      <Table.HeaderCell textAlign='center'>fork</Table.HeaderCell>
      </Table.Row>
      </Table.Header>
      <Table.Body>
      {_.map(widgets, (widget, index) => (
        <Table.Row key={index + '_' + widget.id}>
          <Table.Cell>{widget.username}</Table.Cell>
          <Table.Cell>{widget.type}</Table.Cell>
          <Table.Cell>{widget.id}</Table.Cell>
          <Table.Cell>{widget.title}</Table.Cell>
          <Table.Cell>
            <center>
              <Icon 
                link
                name="pencil" 
                onClick={() => this.handleOpenWidgetEditionModal({ widget }) } 
              />
            </center>
          </Table.Cell>
          <Table.Cell>
            <center>
              <Icon 
                link
                name="eye" 
                onClick={() => this.handleOpenWidgetPreviewModal({ widget }) } 
              />
            </center>
          </Table.Cell>
          <Table.Cell>
            <center>
              <Icon 
                link
                name="fork" 
                onClick={() => this.handleOpenWidgetCreationModal({ widget }) } 
              />
            </center>
          </Table.Cell>
        </Table.Row>
      ))}
      </Table.Body>
      </Table>

      {(modal || {}).type === 'widget-preview' && (
        <WidgetPreviewModal 
          onClose={this.handleCloseModal}
          widget={modal.widget}
        />
      )}

      {(modal || {}).type === 'widget-edition' && (
        <WidgetEditionModal
          onClose={this.handleCloseModal}
          widget={modal.widget}
          onSave={this.handleSaveEditedWidget}
        />
      )}

      {(modal || {}).type === 'widget-creation' && (
        <WidgetEditionModal
          onClose={this.handleCloseModal}
          widget={modal.widget}
          onSave={this.handleSaveCreatedWidget}
        />
      )}

      </div>
    );
  }
}

export default StatsWidgets; 
