import _ from 'underscore';
import React from 'react';
import { Table } from 'semantic-ui-react';

const JobList = (jobs, handleSelectJob) => (
  <Table basic='very' selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Job Type</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Advancement</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {_.map(jobs, (job, key) => (
        <Table.Row
          key={key}
          onClick={() => handleSelectJob(key)}
          style={{ cursor: 'pointer' }}
          positive={job.status === 'SUCCESS'}
          warning={job.status === 'PENDING' || job.status === 'RUNNING'}
          negative={job.status !== 'SUCCESS' && job.status !== 'PENDING' && job.status !== 'RUNNING'}
        >
          <Table.Cell>{new Date(job.startTimestamp).toLocaleString()}</Table.Cell>
          <Table.Cell>{(job.params || {}).__type.replace('JobParams', '')}</Table.Cell>
          <Table.Cell>{job.status}</Table.Cell>
          <Table.Cell>{Math.round(1000 * job.advancement) / 10}%</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default JobList;
