import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import React, { Component } from 'react';
import { Button, Card, Grid, Form, Icon, Modal } from 'semantic-ui-react';
import { Select, SweetForm } from 'sweetform';
import LinkedinEditorModal from '../DataPointSandbox/LinkedinEditorModal';
import ProfileView from '../SweetComponents/Profile/View';

class AddProfileModal extends Component {
  handleClickOnEdit = (editMode) => {
    this.setState({ editMode, key: '' + Math.random() });
  };

  handleClickOnSample = async () => {
    const { propertyTest, samplingBase, getEnrichedProfile } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/randomProfile';
    const sampleWithProperty =
      ((propertyTest || {}).property || {}).constraints ||
      (samplingBase && samplingBase.samplingMode !== 'all-profiles');
    if (!sampleWithProperty) {
      const profileJSON = (await axios.get(url, customHeaders)).data;
      const enrichedEntity = await getEnrichedProfile(profileJSON);
      this.setState({ profileJSON, enrichedEntity });
    } else {
      const payload = { test: propertyTest, samplingBase };
      const profileJSON = (await axios.post(url + 'WithProperty', payload, customHeaders)).data;
      if (!profileJSON || profileJSON.success === false) {
        alert('No Profile found with this property while sampling 1000');
      }
      const enrichedEntity = await getEnrichedProfile(profileJSON);
      this.setState({ profileJSON, enrichedEntity });
    }
  };

  handleClickOnSampleMatchAnywhere = async () => {
    const { propertyTest, samplingBase, getEnrichedProfile } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/matchAnywhereProfile';
    const pattern = (this.state || {}).pattern || 'coffee';
    const payload = { pattern: pattern, test: propertyTest, samplingBase };
    const profileJSON = (await axios.post(url, payload, customHeaders)).data;
    if (!profileJSON || profileJSON.success === false) {
      alert('No profile found with pattern ' + pattern);
    }
    const enrichedEntity = await getEnrichedProfile(profileJSON.data);
    this.setState({ profileJSON: profileJSON.data, enrichedEntity });
  };

  handlePatternChange = async (value) => {
    this.setState({
      pattern: value,
    });
  };

  onCloseEditMode = () => {
    this.setState({ editMode: null });
  };

  onSubmitEditorModal = (linkedinData) => {
    const { onSubmit } = this.props;
    const { profileJSON } = this.state || {};
    const linkedinId = (profileJSON || {}).id || '' + Math.random();

    const newProfileJSON = {
      id: linkedinId,
      ...linkedinData,
    };
    onSubmit(newProfileJSON);
  };

  render() {
    const { onSubmit, onCancel } = this.props;
    const { enrichedEntity, profileJSON } = this.state || {};
    const linkedinData = (profileJSON || {}).data;
    const key = (this.state || {}).key || '' + Math.random();

    return (
      <div>
        {(this.state || {}).editMode == 'linkedin' ? (
          <LinkedinEditorModal
            key={key}
            onClose={this.onCloseEditMode}
            onSave={this.onSubmitEditorModal}
            initialValue={linkedinData}
          />
        ) : null}
        <Modal closeIcon open={true} onClose={onCancel}>
          <Modal.Content size='fullscreen'>
            <div>
              <Grid columns={4}>
                <Grid.Column>
                  <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnEdit('linkedin')}>
                    Edit or Import Linkedin
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSample()}>
                    Sample Profile
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSampleMatchAnywhere()}>
                    Sample Profile (Match Anywhere)
                  </a>
                  <Form.TextArea
                    style={{ width: '160px', height: '15px' }}
                    value={(this.state || {}).pattern || ''}
                    onChange={(e, { value }) => {
                      this.handlePatternChange(value);
                    }}
                  />
                </Grid.Column>
              </Grid>
              {enrichedEntity ? <ProfileView data={enrichedEntity} /> : null}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button positive onClick={() => onSubmit(profileJSON)}>
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const SAMPLING_PROFILE_COLLECTION_OPTIONS = [
  { label: 'All Profiles', value: 'all_profiles' },
  { label: 'Positive Profiles', value: 'positive_profiles' },
  { label: 'Negative Profiles', value: 'negative_profiles' },
];

class FillWithProfileCollectionModal extends Component {
  handleChange(params) {
    this.setState({
      ...params,
    });
  }

  render() {
    const { onSubmit, onClose } = this.props;
    return (
      <Modal open closeIcon open={true} onClose={onClose}>
        <Modal.Content sze='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <h3>Sample Profiles : </h3>
            <Select
              field='samplingProfileCollectionMode'
              defaultValue='all_profiles'
              options={SAMPLING_PROFILE_COLLECTION_OPTIONS}
            />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onClose()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class ProfilesFunctionInput extends Component {
  constructor(props) {
    super(props);
    const { rawEntity } = this.props;
    this.state = { rawEntities: rawEntity || [] };
  }

  onClickRemoveProfile = (profile) => {
    const { onSubmit } = this.props;
    const { rawEntities, selectedRawEntity } = this.state || {};
    const newRawEntities = _.filter(rawEntities, (entity) => entity.sourceProfiles.linkedin.id !== profile.id);
    this.setState({ rawEntities: newRawEntities });
    if ((selectedRawEntity || {}).id === profile.id) {
      this.setState({ selectedEnrichedEntity: undefined, selectedRawEntity: undefined });
    }
    onSubmit(newRawEntities);
  };

  onClickEditProfile = (profile) => {
    this.setState({ rawProfileToEdit: profile });
  };
  onClickOnProfile = async (profile) => {
    const { getEnrichedProfile } = this.props;
    const enrichedEntity = await getEnrichedProfile(profile);
    this.setState({ selectedEnrichedEntity: enrichedEntity, selectedRawEntity: profile });
  };

  onClickAddProfile = () => {
    this.setState({ addProfileModalVisible: true });
  };

  onClickFillWithProfileCollection = () => {
    this.setState({ fillWithProfileCollectionModalVisible: true });
  };

  onSubmitAddProfile = (profile) => {
    const { onSubmit } = this.props;
    const { rawEntities } = this.state || {};

    const newEntity = {
      sourceProfiles: {
        linkedin: profile,
      },
    };

    const newRawEntities = rawEntities.concat(newEntity);
    this.setState({ addProfileModalVisible: false, rawEntities: newRawEntities });
    onSubmit(newRawEntities);
  };

  onCancelAddProfile = () => {
    this.setState({ addProfileModalVisible: false });
  };

  onCloseEditModal = () => {
    this.setState({ rawProfileToEdit: undefined });
  };

  onSaveEditModal = async (data) => {
    const { getEnrichedProfile } = this.props;
    const { rawProfileToEdit, rawEntities, selectedRawEntity } = this.state || {};

    _.find(
      rawEntities,
      (entity) => entity.sourceProfiles.linkedin.id === rawProfileToEdit.id,
    ).sourceProfiles.linkedin.data = data.data;

    if ((selectedRawEntity || {}).id === rawProfileToEdit.id) {
      const newSelectedRawEntity = {
        ...selectedRawEntity,
        ...data,
      };
      const newEnrichedEntity = await getEnrichedProfile(newSelectedRawEntity);
      this.setState({ selectedEnrichedEntity: newEnrichedEntity, selectedRawEntity: newSelectedRawEntity });
    }

    this.setState({ rawProfileToEdit: undefined, rawEntities });
  };

  onCloseFillWithProfileCollectionModal = () => {
    this.setState({ fillWithProfileCollectionModalVisible: false });
  };

  render() {
    const { getEnrichedProfile } = this.props;
    const {
      addProfileModalVisible,
      fillWithProfileCollectionModalVisible,
      rawEntities,
      selectedEnrichedEntity,
      rawProfileToEdit,
    } = this.state || {};
    const profiles = _.map(rawEntities, (entity) => entity.sourceProfiles.linkedin);
    const key = (this.state || {}).key || '' + Math.random();

    return (
      <div>
        {addProfileModalVisible ? (
          <AddProfileModal
            onSubmit={this.onSubmitAddProfile}
            onCancel={this.onCancelAddProfile}
            getEnrichedProfile={getEnrichedProfile}
          />
        ) : null}
        {rawProfileToEdit ? (
          <LinkedinEditorModal
            key={key}
            onClose={this.onCloseEditModal}
            onSave={this.onSaveEditModal}
            initialValue={rawProfileToEdit.data}
          />
        ) : null}
        {fillWithProfileCollectionModalVisible ? (
          <FillWithProfileCollectionModal
            onClose={this.onCloseFillWithProfileCollectionModal}
            onSubmit={this.onSubmitFillWithProfileCollectionModal}
          />
        ) : null}
        <a style={{ cursor: 'pointer' }} onClick={() => this.onClickAddProfile()}>
          <Icon name='add' color='green' /> <b style={{ color: 'green' }}> Add Profile</b>
        </a>
        <br />
        <br />
        <Grid>
          <Grid.Column width={5}>
            {_.map(profiles, (profile) => (
              <Card fluid>
                <Card.Content>
                  <Grid>
                    <Grid.Column width={14}>
                      <Card.Header onClick={() => this.onClickOnProfile(profile)}>
                        {profile.data.firstname + ' ' + profile.data.lastname}
                      </Card.Header>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='blue' name='edit' onClick={() => this.onClickEditProfile(profile)} />
                      <Icon color='red' name='close' onClick={() => this.onClickRemoveProfile(profile)} />
                    </Grid.Column>
                  </Grid>
                  <Card.Description>
                    <span>{profile.data.headline}</span>
                  </Card.Description>
                </Card.Content>
              </Card>
            ))}
          </Grid.Column>
          {selectedEnrichedEntity ? (
            <Grid.Column width={11}>
              <ProfileView data={selectedEnrichedEntity} />
            </Grid.Column>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default ProfilesFunctionInput;
