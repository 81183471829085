import _ from 'underscore';
import React, { Component } from 'react';
import { Button, Form, Header, Icon, List, Segment, Table, Modal } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, Line, CartesianGrid, Tooltip, AreaChart, Area } from 'recharts';
import { Grid, Checkbox } from 'semantic-ui-react';
import { throws } from 'assert';
import moment from 'moment/moment';
import { SweetForm, Select, Input } from 'sweetform';
import { Textarea } from '../common';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { readSync } from 'fs';

const policyOptions = [
  {
    label: 'Hourly',
    value: 'hourly',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
];

class AddTrackerModal extends Component {
  handleChange(params) {
    const { profileCollections, sets } = this.props || {};
    const activeProfileCollection = profileCollections.find((e) => e.id === params.targetProfileCollectionId) || {};
    const activeSet = _.filter(sets, (set) => (activeProfileCollection.setIds || []).includes(set.id)).find(
      (e) => e.metaType === 'searchable-profiles',
    );

    const gcpParams = ((activeSet || {}).definition || {}).params || {};
    const gcqProjectId = gcpParams.projectId;
    const datasetId = gcpParams.datasetId;
    const tableId = gcpParams.tableId;
    const tableDefinition = '`' + gcqProjectId + '.' + datasetId + '.' + tableId + '`';
    const whereQuery = params.gcpQuery || 'TRUE';
    const operatorQuery = params.operatorQuery;
    const newSQLQuery = 'SELECT ' + operatorQuery + ' FROM ' + tableDefinition + ' WHERE ' + whereQuery;
    this.setState({
      currentSQLQuery: newSQLQuery,
      params: params,
      profileCollectionId: activeProfileCollection.id,
      gcqProjectId,
      datasetId,
      tableId,
    });
  }

  render() {
    const { onSubmit, onCancel, profileCollections } = this.props;
    const { currentSQLQuery } = this.state || {};
    const profileCollectionOptions = _.map(profileCollections, (profileCollection, index) => ({
      label: profileCollection.name ? profileCollection.name : profileCollection.title,
      value: profileCollection.id,
    }));
    return (
      <Modal open closeIcon open={true} headerText={`Create tracker for table`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Name</label>
              <Input autoFocus field='name' />
            </Form.Field>
            <Form.Field>
              <label>Target Profile Collection</label>
              <Select field='targetProfileCollectionId' options={profileCollectionOptions} />
            </Form.Field>
            <Form.Field>
              <label>Policy</label>
              <Select field='policy' options={policyOptions} />
            </Form.Field>
            <Form.Field>
              <label>Operator</label>
              <Textarea
                style={{ width: '100%' }}
                field='operatorQuery'
                label='Raw Query Editor'
                placeholder='Write operator - ex: Count(*)'
              />
            </Form.Field>
            <Form.Field>
              <label>GCP Query</label>
              <Textarea
                style={{ width: '100%' }}
                field='gcpQuery'
                label='Raw Query Editor'
                placeholder='Write filter query - ex: job__data_science >= 45'
              />
            </Form.Field>
          </SweetForm>
          <b>Preview :</b> {currentSQLQuery}
          <div style={{ height: '200px' }} />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() =>
              onSubmit(
                this.state.params,
                this.state.currentSQLQuery,
                this.state.profileCollectionId,
                this.state.gcqProjectId,
                this.state.datasetId,
                this.state.tableId,
              )
            }
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class DistributionView extends Component {
  render() {
    const { data, id, name } = this.props;
    const { values, intervals } = data;
    const formattedData = _.map(_.range(100), (i, index) => ({
      interval: intervals[i],
      value: values[i],
    }));
    return (
      <Segment>
        <Header as='h3' textAlign='left'>
          {name}
        </Header>
        <AreaChart
          width={500}
          height={400}
          data={formattedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='interval' />
          <YAxis />
          <Tooltip />
          <Area type='monotone' dataKey='value' stroke='#8884d8' fill='#8884d8' />
        </AreaChart>
      </Segment>
    );
  }
}

class AddDistributionModal extends Component {
  handleChange(params) {
    const { profileCollections, sets } = this.props || {};
    const activeProfileCollection = profileCollections.find((e) => e.id === params.targetProfileCollectionId) || {};
    const activeSet = _.filter(sets, (set) => (activeProfileCollection.setIds || []).includes(set.id)).find(
      (e) => e.metaType === 'searchable-profiles',
    );

    const gcpParams = ((activeSet || {}).definition || {}).params || {};
    const gcqProjectId = gcpParams.projectId;
    const datasetId = gcpParams.datasetId;
    const tableId = gcpParams.tableId;
    const tableDefinition = '`' + gcqProjectId + '.' + datasetId + '.' + tableId + '`';
    const whereQuery = params.gcpQuery || 'TRUE';
    const fieldQuery = params.fieldQuery;
    const newSQLQuery = 'SELECT ' + fieldQuery + ' FROM ' + tableDefinition + ' WHERE ' + whereQuery;
    this.setState({
      currentSQLQuery: newSQLQuery,
      params: params,
      profileCollectionId: activeProfileCollection.id,
    });
  }

  render() {
    const { onSubmit, onCancel, profileCollections } = this.props;
    const { currentSQLQuery } = this.state || {};
    const profileCollectionOptions = _.map(profileCollections, (profileCollection, index) => ({
      label: profileCollection.name ? profileCollection.name : profileCollection.title,
      value: profileCollection.id,
    }));
    return (
      <Modal open closeIcon open={true} headerText={`Create tracker for table`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Name</label>
              <Input autoFocus field='name' />
            </Form.Field>
            <Form.Field>
              <label>Target Profile Collection</label>
              <Select field='targetProfileCollectionId' options={profileCollectionOptions} />
            </Form.Field>
            <Form.Field>
              <label>Field</label>
              <Textarea style={{ width: '100%' }} field='fieldQuery' label='Field' placeholder='skill__python' />
            </Form.Field>
            <Form.Field>
              <label>GCP Query</label>
              <Textarea
                style={{ width: '100%' }}
                field='gcpQuery'
                label='Raw Query Editor'
                placeholder='Write filter query - ex: job__data_science >= 45'
              />
            </Form.Field>
          </SweetForm>
          <b>Preview :</b> {currentSQLQuery}
          <div style={{ height: '200px' }} />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() => onSubmit(this.state.params, this.state.currentSQLQuery, this.state.profileCollectionId)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class Insights extends Component {
  componentDidMount() {
    const { profileCollections } = this.props || {};
    this.getTrackerInformation(profileCollections);
    this.getAllDistributionInformation(profileCollections);
    this.handleLoadSets();
  }

  getTrackerInformation = async (profileCollections) => {
    const profileCollectionsTrackerIds = await this.getRelevantProfileCollectionsTrackerIds(profileCollections);
    var trackers = [];
    var trackerIdToDifferenceMode = {};
    for (var idx = 0; idx < profileCollectionsTrackerIds.length; idx++) {
      const profileCollectionTrackerIds = profileCollectionsTrackerIds[idx];
      const trackerIds = (profileCollectionTrackerIds || {}).trackerIds || [];
      const profileCollectionId = (profileCollectionTrackerIds || {}).profileCollectionId;
      for (var i = 0; i < trackerIds.length; i++) {
        const trackerData = await this.getSingleTrackerInformation(trackerIds[i].id);
        trackers.push({
          profileCollectionId,
          trackerId: trackerIds[i].id,
          trackerData: trackerData,
          name: trackerIds[i].name,
          mode: 'absolute',
        });
        trackerIdToDifferenceMode[trackerIds[i].id] = false;
      }
    }
    this.setState({
      allTrackerInformation: trackers,
    });
  };

  getDistributionInformation = async (profileCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getDistributions/' + profileCollectionId;
    const distributions = (await axios.get(url, customHeaders)).data;
    return {
      profileCollectionId,
      distributions: (distributions.data || {}).results || [],
    };
  };

  getAllDistributionInformation = async (profileCollections) => {
    var profileCollectionsDistributions = [];
    for (var idx = 0; idx < profileCollections.length; idx++) {
      const profileDistribution = await this.getDistributionInformation(profileCollections[idx].id);
      profileCollectionsDistributions.push(profileDistribution);
    }
    this.setState({ profileCollectionsDistributions });
  };

  async getSets() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getSets';
    const data = (await axios.get(url, customHeaders)).data;
    return data || [];
  }

  handleLoadSets = async () => {
    const sets = await this.getSets();
    this.setState({ sets });
  };

  getProfileCollectionTrackerIds = async (profileCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getTrackerIds/' + profileCollectionId;
    const result = (await axios.get(url, customHeaders)).data;
    return {
      profileCollectionId,
      trackerIds: result || [],
    };
  };

  getRelevantProfileCollectionsTrackerIds = async (profileCollections) => {
    var profileCollectionsTrackerIds = [];
    for (var idx = 0; idx < profileCollections.length; idx++) {
      const profileCollectionTrackerIds = await this.getProfileCollectionTrackerIds(profileCollections[idx].id);
      profileCollectionsTrackerIds.push(profileCollectionTrackerIds);
    }
    return profileCollectionsTrackerIds;
  };

  getSingleTrackerInformation = async (trackerId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getTrackerPoints/' + trackerId;
    const result = (await axios.get(url, customHeaders)).data;
    return result;
  };

  deleteTracker = async (trackerId) => {
    const payload = {
      trackerId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteTracker';
    const data = (await axios.post(url, payload, customHeaders)).data;
    const { profileCollections } = this.props;
    this.getTrackerInformation(profileCollections);
  };

  async handleToggleModeDifference(trackerId) {
    const oldTrackers = this.state.allTrackerInformation;
    const tracker = _.filter(oldTrackers, (tracker) => tracker.trackerId == trackerId)[0];
    const newMode = tracker.mode == 'absolute' ? 'diff' : 'absolute';
    const newTrackers = _.map(oldTrackers, (tracker) => {
      if (tracker.trackerId == trackerId) {
        return {
          ...tracker,
          mode: newMode,
        };
      } else {
        return tracker;
      }
    });
    await this.setState({ allTrackerInformation: newTrackers });
  }

  plotTracker(trackerId, trackerName, trackerMode) {
    const difference = trackerMode == 'diff';
    const timeFormatter = (tick) => {
      return moment(new Date(tick)).format('YY-MM-DD');
    };
    const trackerInformation = _.find((this.state || {}).allTrackerInformation || [], { trackerId: trackerId });
    const trackerData = (trackerInformation || {}).trackerData;
    var plotData = [];

    if (difference) {
      const sortedTrackerData = _.sortBy(trackerData, 'timestamp');
      const indexedTrackerData = {};
      for (var idx = 0; idx < sortedTrackerData.length; idx++) {
        indexedTrackerData[idx] = sortedTrackerData[idx];
      }
      plotData = [];
      for (var i = 0; i < sortedTrackerData.length - 1; i++) {
        plotData.push({
          x: timeFormatter(sortedTrackerData[i + 1].timestamp),
          y: sortedTrackerData[i + 1].value - sortedTrackerData[i].value,
        });
      }
    } else {
      plotData = _.sortBy(
        _.map(trackerData, (stat) => ({
          x: timeFormatter(stat.timestamp),
          y: stat.value,
        })),
        'x',
      );
    }

    return (
      <Segment>
        <Header as='h3' textAlign='left'>
          {trackerName}
        </Header>
        <Checkbox slider onChange={() => this.handleToggleModeDifference(trackerId)} checked={difference} />
        <LineChart
          width={500}
          height={250}
          type='line'
          data={plotData}
          margin={{ top: 0, right: 5, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <Line type='monotone' dataKey='y' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <Tooltip />
          <YAxis
            label={{
              angle: -90,
              position: 'insideLeft',
              dy: 70,
              dx: 20,
            }}
          />
        </LineChart>
        <Icon style={{ cursor: 'pointer' }} name='delete' color='red' onClick={() => this.deleteTracker(trackerId)} />
        <b style={{ color: 'red' }}> Delete Tracker </b>
      </Segment>
    );
  }

  handleClickOnAddTracker() {
    this.setState({ trackerModalVisible: true });
  }
  handleClickOnAddDistribution() {
    this.setState({ distributionModalVisible: true });
  }

  onCancel = () => {
    this.setState({ trackerModalVisible: false });
  };

  onCancelDistribution = () => {
    this.setState({ distributionModalVisible: false });
  };

  addLinkTrackerToProfileCollection = async (trackerId, profileCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const payload = {
      trackerId,
      profileCollectionId,
    };
    const url = baseUrl + '/sweetchain/addLinkTrackerToProfileCollection';
    const result = (await axios.post(url, payload, customHeaders)).data;
  };

  onSubmit = async (params, sqlQuery, profileCollectionId, gcpProjectId, datasetId, tableId) => {
    if (!params.name) {
      alert('You Must Specify a name');
      return;
    }
    if (!params.gcpQuery) {
      alert('You Must Specify a query');
      return;
    }
    if (!params.policy) {
      alert('You Must Specify a policy');
      return;
    }
    const { profileCollections } = this.props;
    const payload = {
      projectId: gcpProjectId,
      datasetId,
      tableId,
      policy: params.policy,
      name: params.name,
      gcpQuery: sqlQuery,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createTrackerBigQuery';
    const result = (await axios.post(url, payload, customHeaders)).data.data;
    const trackerId = result.id;
    this.addLinkTrackerToProfileCollection(trackerId, profileCollectionId);
    this.setState({ trackerModalVisible: false });
    this.getTrackerInformation(profileCollections);
  };

  onSubmitDistribution = async (params, sqlQuery, profileCollectionId) => {
    if (!params.name) {
      alert('You Must Specify a name');
      return;
    }
    const payload = {
      name: params.name,
      gcpQuery: sqlQuery,
      profileCollectionId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createDistribution';
    const result = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ distributionModalVisible: false });
    const { profileCollections } = this.props;
    this.getAllDistributionInformation(profileCollections);
  };

  async handleRefreshQuery(id) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/refreshDistribution/' + id;
    const data = (await axios.get(url, customHeaders)).data;
    if (data.success == false) {
      alert('Error happened');
      return;
    }
    const { profileCollections } = this.props;
    this.getAllDistributionInformation(profileCollections);
  }

  async deleteDistribution(id) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteDistribution/' + id;
    const data = (await axios.get(url, customHeaders)).data;
    if (data.success == false) {
      alert('Error happened');
      return;
    }
    const { profileCollections } = this.props;
    this.getAllDistributionInformation(profileCollections);
  }

  render() {
    const { projectId, profileCollections } = this.props;
    const { profileCollectionsDistributions, sets } = this.state || {};
    const { trackerModalVisible, distributionModalVisible } = this.state || {};
    const allTrackerInformation = (this.state || {}).allTrackerInformation || [];
    const distributionsList =
      _.map(profileCollectionsDistributions || [], (profileCollectionDistribution) => {
        return profileCollectionDistribution.distributions;
      }) || [];
    const distributions = [].concat.apply([], distributionsList);

    return (
      <div>
        {trackerModalVisible ? (
          <AddTrackerModal
            profileCollections={profileCollections}
            sets={sets}
            onSubmit={(params, sqlQuery, profileCollectionId, gcpProjectId, datasetId, tableId) =>
              this.onSubmit(params, sqlQuery, profileCollectionId, gcpProjectId, datasetId, tableId)
            }
            onCancel={this.onCancel}
          />
        ) : null}
        {distributionModalVisible ? (
          <AddDistributionModal
            profileCollections={profileCollections}
            sets={sets}
            onSubmit={(params, sqlQuery, profileCollectionId) =>
              this.onSubmitDistribution(params, sqlQuery, profileCollectionId)
            }
            onCancel={this.onCancelDistribution}
          />
        ) : null}
        <Header as='h2'>Trackers :</Header>
        <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnAddTracker()}>
          <Icon name='add' size='big' color='green' /> <b style={{ color: 'green' }}> Add Tracker</b>
        </a>
        <br /> <br />
        <Grid columns={2}>
          {_.map(allTrackerInformation, (trackerInfo, index) => (
            <Grid.Column key={index}>
              {this.plotTracker(trackerInfo.trackerId, trackerInfo.name, trackerInfo.mode)}
            </Grid.Column>
          ))}
        </Grid>
        <Header as='h2'>Distributions :</Header>
        <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnAddDistribution()}>
          <Icon name='add' size='big' color='green' /> <b style={{ color: 'green' }}> Add Distribution</b>
        </a>
        <br /> <br />
        <Grid columns={2}>
          {_.map(distributions || [], (distribution, index) =>
            distribution.data ? (
              <Grid.Column key={index}>
                <DistributionView
                  id={distribution.id}
                  name={distribution.name}
                  data={distribution.data}
                  refreshDistribution={this.getAllDistributionInformation}
                />
                <div style={{ cursor: 'pointer' }} onClick={() => this.handleRefreshQuery(distribution.id)}>
                  <Icon name='refresh' />
                  <a> refresh distribution </a>
                </div>
                <Icon
                  style={{ cursor: 'pointer' }}
                  name='delete'
                  color='red'
                  onClick={() => this.deleteDistribution(distribution.id)}
                />
                <b style={{ color: 'red' }}> Delete Distribution </b>
              </Grid.Column>
            ) : null,
          )}
        </Grid>
      </div>
    );
  }
}
export default Insights;
