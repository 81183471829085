import _ from 'underscore';
import React, { Component } from 'react';
import RequirementStatusView from './RequirementStatusView.js';
import baseUrl from '../baseUrl.js';
import { Icon, List, Grid, Segment, Button, Form, Modal } from 'semantic-ui-react';
import { SweetForm, Select, Input } from 'sweetform';
import { Textarea } from '../common';
import axios from 'axios';

const sourceTypeOptions = [
  {
    label: 'Inline',
    value: 'inline',
  },
  {
    label: 'JSON',
    value: 'json',
  },
];

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const checkPayload = (payload, metaType) => {
  if (metaType == 'profile-id-fields-tasks') {
    return _.all(
      _.map(payload, (item) => {
        return item.id && item.linkedin && item.creationTimestamp && item.status == 'pending';
      }),
    );
  }
  return true;
};

const isCorrectJson = (textValue) => {
  if (!IsJsonString(textValue)) {
    return false;
  }
  return true;
};

class UploadDataModal extends Component {
  handleChange = (params) => {
    const dataIsValid = isCorrectJson(params.data);
    const data = dataIsValid ? JSON.parse(params.data) : params.data;
    this.setState({ ...params, dataIsValid, data });
  };

  handleFile = (e) => {
    const content = e.target.result;
    console.log('file content', content);
    const dataIsValid = isCorrectJson(content);
    const data = dataIsValid ? JSON.parse(content) : content;
    this.setState({ data });
  };

  handleChangeFile = (file) => {
    let fileData = new FileReader();
    fileData.onloadend = this.handleFile;
    fileData.readAsText(file);
  };

  render() {
    const { sourceType, dataIsValid } = this.state || {};
    const { onCancel, onSubmit } = this.props;
    return (
      <Modal open closeIcon open={true} headerText={`Create tracker for table`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Source Type</label>
              <Select field='sourceType' options={sourceTypeOptions} />
            </Form.Field>
            {sourceType == 'inline' ? (
              <Form.Field>
                <Textarea
                  field='data'
                  style={{
                    background: dataIsValid ? '#e9ffd5' : '#ffeee6',
                    width: '100%',
                    resize: 'none',
                    height: '35px',
                    overflow: 'hidden',
                    display: 'table-cell',
                  }}
                  placeholder='write your json here - ex : [{"linkedin": "xxxxxx"}, {"linkedin": "yyyyyy"}]'
                />
              </Form.Field>
            ) : sourceType == 'json' ? (
              <div>
                <input type='file' accept='.json' onChange={(e) => this.handleChangeFile(e.target.files[0])} />
              </div>
            ) : null}
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const generateId = () => {
  var letters = '0123456789AZERTYUIOPQSDFGHJKLMWXCVBN';
  var id = '';
  for (var i = 0; i < 15; i++) {
    id += letters[Math.floor(Math.random() * 30)];
  }
  return id;
};

class MongoAdminCollectionView extends Component {
  onSubmit = async (params) => {
    const { keyType } = this.props.data;
    const data =
      keyType == 'profile-id-fields-tasks'
        ? _.map(params.data, (item) => {
            return {
              status: 'pending',
              id: generateId(),
              creationTimestamp: Date.now(),
              ...item,
            };
          })
        : params.data;
    const validPayload = checkPayload(data, keyType);
    if (!validPayload) {
      alert('Invalid Payload : check schema and JSON validity');
      return;
    }
    const payload = {
      ...this.props.data,
      data,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/uploadData';
    console.log(payload);
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ uploadDataModal: false });
  };

  onCancel = () => {
    this.setState({ uploadDataModal: false });
  };

  handleClickOnUploadData = () => {
    this.setState({ uploadDataModal: true });
  };

  render() {
    const { collection, database, mongoHost, type } = (this.props || {}).data || {};
    const { uploadDataModal } = this.state || {};
    return (
      <div>
        {uploadDataModal ? <UploadDataModal onSubmit={this.onSubmit} onCancel={this.onCancel} /> : null}
        <List>
          <List.Item>
            <Button color='green' onClick={this.handleClickOnUploadData}>
              Upload Data
            </Button>
          </List.Item>
        </List>
      </div>
    );
  }
}
export default MongoAdminCollectionView;
