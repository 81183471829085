import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { Card, Container, Dropdown, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { Input, Select, SweetForm } from 'sweetform';
import DiffusionTable from './DiffusionTable.js';

class JobDiffusion extends Component {
  componentDidMount() {
    this.loadCategoryData();
    this.getInitialFilters();
  }

  async getJobs() {
    const url = baseUrl + '/cerejobsV2/getJobs';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  async getMainJobsCategories() {
    const url = baseUrl + '/cerejobsV2/getMainJobsCategories';
    const mainJobsCategories = (await axios.get(url)).data;
    return mainJobsCategories;
  }

  getJobsInLevel2Category(level2JobsCategoryId, jobs) {
    const jobsInCategoryIds = _.filter(jobs, (job) => (job.groups || []).some((e) => e.id === level2JobsCategoryId));
    return jobsInCategoryIds;
  }

  getJobsInLevel1Category(jobsCategoryId, jobs) {
    const level2JobsInCategoryIds = _.map(
      _.filter(jobs, (job) => (job.groups || []).some((e) => e.id === jobsCategoryId)),
      (level2JobsInCategory) => level2JobsInCategory.id,
    );
    const jobsInCategoryIds = _.filter(jobs, (job) =>
      (job.groups || []).some((e) => level2JobsInCategoryIds.includes(e.id)),
    );
    return jobsInCategoryIds;
  }

  async loadCategoryData() {
    const jobs = await this.getJobs();
    const mainJobsCategories = await this.getMainJobsCategories();
    const level1JobsCategoryIds = _.map(mainJobsCategories, (level1JobsCategory) => level1JobsCategory.id);
    const level1JobsCategories = _.filter(jobs, (job) => level1JobsCategoryIds.includes(job.id));

    const jobsInLevel1Categories = _.map(level1JobsCategories, (level1JobsCategory) => ({
      category: level1JobsCategory,
      jobsInCategory: this.getJobsInLevel1Category(level1JobsCategory.id, jobs),
    }));

    const level2JobsCategories = _.filter(
      jobs,
      (job) =>
        (job.groups || []).some((e) => level1JobsCategoryIds.includes(e.id)) &&
        (job.categorizations || []).some((e) => e.id === 'main-jobs-categories'),
    );

    const jobsInLevel2Categories = _.map(level2JobsCategories, (level2JobsCategory) => ({
      category: level2JobsCategory,
      jobsInCategory: this.getJobsInLevel2Category(level2JobsCategory.id, jobs),
    }));

    this.setState({ jobs, jobsInLevel1Categories, jobsInLevel2Categories });

    this.computeJobToCategories(jobsInLevel1Categories, jobsInLevel2Categories);
  }

  async computeJobToCategories(jobsInLevel1Categories, jobsInLevel2Categories) {
    let jobIdToLevel1Category = {};
    _.map(jobsInLevel1Categories, (jobsInLevel1Category) => {
      _.map(jobsInLevel1Category.jobsInCategory, (jobInCategory) => {
        jobIdToLevel1Category[jobInCategory.id] = jobsInLevel1Category.category.id;
        return;
      });
      return;
    });

    let jobIdToLevel2Category = {};
    _.map(jobsInLevel2Categories, (jobsInLevel2Category) => {
      _.map(jobsInLevel2Category.jobsInCategory, (jobInCategory) => {
        jobIdToLevel2Category[jobInCategory.id] = jobsInLevel2Category.category.id;
        return;
      });
      return;
    });

    this.setState({ jobIdToLevel1Category, jobIdToLevel2Category });
  }

  getInitialFilters() {
    const { initialInCategoryFilter } = this.props;
    if (!_.isUndefined(initialInCategoryFilter)) {
      this.setState({ inCategoryFilter: initialInCategoryFilter });
    }
  }

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  onChangeInCategoryFilter = (params) => {
    this.setState({ inCategoryFilter: params.inCategoryFilter });
  };

  handleSelectJob = async (jobId) => {
    this.setState({ selectedJobId: jobId });
  };

  handleClickOnSortImplications = () => {
    const { tagScores } = this.state || {};
    const sortedTagScores = _.sortBy(tagScores, 'implicationScore').reverse();
    this.setState({ tagScores: sortedTagScores });
  };

  handleClickOnSortSources = () => {
    const { tagScores } = this.state || {};
    const sortedTagScores = _.sortBy(tagScores, 'sourceScore').reverse();
    this.setState({ tagScores: sortedTagScores });
  };

  render() {
    const { initialInCategoryFilter } = this.props;
    const {
      inCategoryFilter,
      jobs,
      jobIdToLevel1Category,
      jobIdToLevel2Category,
      jobsInLevel1Categories,
      jobsInLevel2Categories,
      search,
      selectedJobId,
    } = this.state || {};

    const category1Options = _.map(jobsInLevel1Categories, (jobsInLevel1Category) => jobsInLevel1Category.category);
    const category2Options = _.map(jobsInLevel2Categories, (jobsInLevel2Category) => jobsInLevel2Category.category);

    const categoryOptions = _.map(category1Options.concat(category2Options) || [], (category) => ({
      value: category.id,
      label: category.name,
      text: category.id,
      key: category.id,
    }));

    const jobsInLevel1CategoryFilter = !_.isEmpty(inCategoryFilter)
      ? _.find(jobsInLevel1Categories, (jobsInLevel1Category) => jobsInLevel1Category.category.id === inCategoryFilter)
      : {};
    const jobsInLevel2CategoryFilter = !_.isEmpty(inCategoryFilter)
      ? _.find(jobsInLevel2Categories, (jobsInLevel2Category) => jobsInLevel2Category.category.id === inCategoryFilter)
      : {};

    const jobsInCategoryFilter = jobsInLevel1CategoryFilter || jobsInLevel2CategoryFilter;
    const filteredJobsInCategory = !_.isEmpty(jobsInCategoryFilter) ? jobsInCategoryFilter.jobsInCategory : jobs;

    const indexOfSearch = ({ name, id }, search) => (name || id || '').toLowerCase().indexOf(search.toLowerCase());
    const filteredJobs = !search
      ? filteredJobsInCategory
      : _.sortBy(_.filter(filteredJobsInCategory, (job) => indexOfSearch(job, search) >= 0), (job) =>
          indexOfSearch(job, search),
        );

    const selectedJobIdLevel1Category = selectedJobId ? jobIdToLevel1Category[selectedJobId] : null;
    const jobsInSameLevel1Category = (
      _.find(
        jobsInLevel1Categories,
        (jobsInLevel1Category) => jobsInLevel1Category.category.id === selectedJobIdLevel1Category,
      ) || {}
    ).jobsInCategory;

    const selectedJobIdLevel2Category = selectedJobId ? jobIdToLevel2Category[selectedJobId] : null;
    const jobsInSameLevel2Category = (
      _.find(
        jobsInLevel2Categories,
        (jobsInLevel2Category) => jobsInLevel2Category.category.id === selectedJobIdLevel2Category,
      ) || {}
    ).jobsInCategory;

    return (
      <Grid columns={2}>
        <Grid.Column width={6}>
          <Container>
            <Card fuild>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <h3>Jobs</h3>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <SweetForm onChange={this.onChangeSearch}>
                          <Form.Field>
                            <Input field='search' />
                          </Form.Field>
                        </SweetForm>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6} verticalAlign={'middle'}>
                        <h4>In category </h4>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <SweetForm onChange={(params) => this.onChangeInCategoryFilter(params)}>
                          <Form.Field>
                            <Select
                              placeholder='Category'
                              field='inCategoryFilter'
                              search
                              options={categoryOptions}
                              defaultValue={initialInCategoryFilter}
                            />
                          </Form.Field>
                        </SweetForm>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Header>
                <br />
                <div
                  style={{
                    overflowY: 'scroll',
                    height: 'calc(100vh - 100px)',
                  }}
                >
                  <Card.Description>
                    {(filteredJobs || []).map((job) => {
                      return (
                        <Grid.Row
                          key={job.id}
                          onClick={() => this.handleSelectJob(job.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          <span
                            style={
                              selectedJobId && selectedJobId === job.id
                                ? {
                                    fontWeight: 'bold',
                                    color: 'green',
                                    fontSize: '13px',
                                  }
                                : {}
                            }
                          >
                            {job.name || job.id}
                          </span>
                        </Grid.Row>
                      );
                    })}
                  </Card.Description>
                </div>
              </Card.Content>
            </Card>
          </Container>
        </Grid.Column>
        {selectedJobId ? (
          <Grid.Column width={10}>
            <DiffusionTable
              key={selectedJobId}
              jobId={selectedJobId}
              jobsInSameLevel1Category={jobsInSameLevel1Category}
              jobsInSameLevel2Category={jobsInSameLevel2Category}
            />
          </Grid.Column>
        ) : null}
      </Grid>
    );
  }
}

export default JobDiffusion;
