import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Grid, Button, Form, Input, TextArea } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import loadLinkedinFromId, { loadLinkedinFromProfileCollection } from './loadLinkedinProfile';
import baseUrl from '../../../baseUrl.js';
import LinkedinProfileView from '../View';

const linkedinStringify = ({ linkedinData }) => {
  const { firstname, lastname, photoLink, headline, location, summary, experiences, education } = linkedinData;

  return JSON.stringify(
    _.omit(
      {
        ...(firstname && { firstname }),
        ...(lastname && { lastname }),
        ...(photoLink && { photoLink }),
        ...(headline && { headline }),
        ...(location && { location }),
        ...(summary && { summary }),
        ...(experiences && { experiences }),
        ...(education && { education }),
        ...linkedinData,
      },
      'fullname',
    ),
    null,
    4,
  );
};

class LinkedinMultiEditor extends Component {
  state = {
    jsonStr: '',
    jsonIsCorrect: true,
    linkedinId: '',
  };

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.state.jsonStr = linkedinStringify({ linkedinData: props.initialValue });
    }
  }

  componentDidMount() {
    this.handleLoadProfileCollections();
  }

  handleChangeLinkedinId = ({ linkedinId }) => {
    this.setState({ linkedinId });
  };

  handleChangeProfileCollectionId = ({ profileCollectionId }) => {
    this.setState({ profileCollectionId });
  };

  handleChangeProfileId = ({ profileId }) => {
    this.setState({ profileId });
  };

  handleChangeJsonStr = ({ jsonStr }) => {
    let data = null;
    let jsonIsCorrect = true;
    try {
      data = JSON.parse(jsonStr);
    } catch (e) {
      jsonIsCorrect = false;
    }
    this.setState({
      jsonStr,
      jsonIsCorrect,
    });
    if (jsonIsCorrect) {
      this.props.onUpdate({ data });
    }
  };

  handleLoadLinkedinById = async ({ linkedinId }) => {
    const result = await loadLinkedinFromId({ id: linkedinId });
    if (!result || !result.data) {
      return alert('not found');
    }
    const { data } = result;
    this.setState({
      jsonStr: linkedinStringify({ linkedinData: data }),
    });
    this.props.onUpdate({ data });
  };

  handleLoadProfileCollections = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileCollections';
    const results = (await axios.get(url, customHeaders)).data;
    const profileCollectionOptions = _.map(results.data || [], (profileCollection, key) => ({
      value: profileCollection.id,
      key: key,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
    }));
    this.setState({ profileCollectionOptions });
  };

  handleLoadLinkedinByProfileCollection = async ({ profileCollectionId, profileId }) => {
    const result = await loadLinkedinFromProfileCollection({ profileCollectionId, profileId });
    if (result.error) {
      return alert(result.error);
    }
    this.setState({
      jsonStr: linkedinStringify({ linkedinData: result.data }),
    });
    this.props.onUpdate({ data: result.data });
  };

  renderJsonEditor() {
    return (
      <Form>
        <TextArea
          style={{ borderColor: this.state.jsonIsCorrect ? 'lime' : 'orange' }}
          value={this.state.jsonStr}
          rows='36'
          onChange={({ target }) => {
            this.handleChangeJsonStr({ jsonStr: target.value });
          }}
        />
      </Form>
    );
  }

  renderImportEditor() {
    const { linkedinId } = this.state;
    const isButtonDisabled = _.isEmpty(linkedinId) || linkedinId.indexOf(' ') >= 0;
    return (
      <div>
        <Input
          type='text'
          value={this.state.value}
          onChange={({ target }) => {
            this.handleChangeLinkedinId({ linkedinId: target.value });
          }}
        />
        <Button
          disabled={isButtonDisabled}
          color='blue'
          onClick={() => {
            this.handleLoadLinkedinById({ linkedinId });
          }}
        >
          Load
        </Button>
      </div>
    );
  }

  renderProfileCollectionImportEditor() {
    const { profileId, profileCollectionId, profileCollectionOptions } = this.state;
    const isButtonDisabled = _.isEmpty(profileId) || profileId.indexOf(' ') >= 0 || _.isEmpty(profileCollectionId);
    return (
      <div>
        <Form.Select
          search
          placeholder='Profile Collection'
          options={profileCollectionOptions}
          onChange={(e, { value }) => {
            this.handleChangeProfileCollectionId({ profileCollectionId: value });
          }}
          value={profileCollectionId}
        />
        <Input
          type='text'
          placeholder='profileId'
          value={profileId}
          onChange={({ target }) => {
            this.handleChangeProfileId({ profileId: target.value });
          }}
        />
        <Button
          disabled={isButtonDisabled}
          color='blue'
          onClick={() => {
            this.handleLoadLinkedinByProfileCollection({ profileCollectionId, profileId });
          }}
        >
          Load
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Tabs defaultIndex={this.state.jsonStr ? 0 : 1}>
          <TabList>
            <Tab>JSON</Tab>
            <Tab>Import</Tab>
            <Tab>Import - Profile Collection</Tab>
          </TabList>
          <TabPanel>{this.renderJsonEditor()}</TabPanel>
          <TabPanel>{this.renderImportEditor()}</TabPanel>
          <TabPanel>{this.renderProfileCollectionImportEditor()}</TabPanel>
        </Tabs>
      </div>
    );
  }
}

class LinkedinEditor extends Component {
  state = { data: {} };

  constructor(props) {
    super(props);
    this.state.data = props.initialValue || {};
  }

  handleUpdate = ({ data }) => {
    this.setState({ data });
    this.props.onUpdate({ data });
  };

  render() {
    const { initialValue } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <LinkedinMultiEditor onUpdate={this.handleUpdate} initialValue={initialValue} />
          </Grid.Column>
          <Grid.Column width={8}>
            <LinkedinProfileView data={this.state.data} isV4={this.props.isV4} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default LinkedinEditor;
