import React, { Component } from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import JsonEditor from './jsonEditor';
import XG1FormattedJob from './XG1FormattedJob';

const stringifyFormattedJob = (formattedJob) => {
  const {id, creation, meta, title, skills, disciplines, subDisciplines, content, contentHtml, company} = formattedJob
  return JSON.stringify({
    ...id && { id },
    ...creation && { creation },
    ...company && { company },
    ...meta && { meta },
    ...title && { title },
    ...skills && { skills },
    ...disciplines && { disciplines },
    ...subDisciplines && { subDisciplines },
    ...content && { content },
    ...contentHtml && { contentHtml },
  }, null, 4);
}

class EditFormattedJobModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.state.data = props.initialValue;
  }

  handleUpdateData = ({ data }) => {
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({
      data: this.state.data,
    });
  };

  render() {
    const { initialValue, onClose } = this.props;
    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit FormattedJob</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid>
            <Grid.Column width={8}>
              <JsonEditor initialValue={initialValue} stringify={stringifyFormattedJob} onUpdate={this.handleUpdateData}/>
            </Grid.Column>
            <Grid.Column width={8}>
              <XG1FormattedJob formattedJob={this.state.data}/>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => this.handleSave()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditFormattedJobModal;
