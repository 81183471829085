import _ from 'underscore';
import React, { Component } from 'react';
import GBQQueryCollectionView from '../SweetChain/GBQQueryCollectionView';
import { Form } from 'semantic-ui-react';
import Talents from './Talents';
import Jobs from './Jobs';
import Insights from './Insights';
import Search from './Search';
import baseUrl from '../baseUrl.js';

import axios from 'axios';

class Playground extends Component {
  async loadSchemaInformation(profileCollectionId) {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const fetchSetUrl = baseUrl + '/sweetchain/getSetsFromProfileCollection/' + profileCollectionId;
    const results = (await axios.get(fetchSetUrl, customHeaders)).data;

    const searchableProfileSet = _.find(results, (set) => set.metaType === 'searchable-profiles') || {};
    const payload = {
      ...((searchableProfileSet.definition || {}).params || {}),
    };
    const url = baseUrl + '/sweetchain/getSchema';
    const schema = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ ...payload, schema });
  }

  handleSelectProfileCollection = async (e, { value }) => {
    this.setState({ selectedProfileCollectionId: value });
    this.loadSchemaInformation(value);
  };

  render() {
    const { schema, datasetId, tableId, projectId, selectedProfileCollectionId } = this.state || {};
    const { profileCollections } = this.props;
    const profileCollectionOptions = _.map(profileCollections, (profileCollection, index) => ({
      key: index,
      value: profileCollection.id,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
    }));
    return (
      <div>
        <Form.Select
          search
          placeholder='Profile Collection'
          options={profileCollectionOptions}
          onChange={this.handleSelectProfileCollection}
          value={selectedProfileCollectionId}
        />
        {schema ? (
          <GBQQueryCollectionView
            schema={schema}
            datasetId={datasetId}
            tableId={tableId}
            projectId={projectId}
            type={'bigquery'}
            keyType={'searchable-profiles'}
            displayEstimatedCost={false}
          />
        ) : null}
      </div>
    );
  }
}

export default Playground;
