import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Table,
} from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

class GradesDistributionsMonitoring extends Component {
  state = {};
  componentWillMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = (await axios.get(
      baseUrl + '/sweetwork/getGradesDistributions',
    )).data;
    console.log(data);
    this.setState(data);
  };

  handleClick(gradeData) {
    if (_.isEmpty((gradeData || {}).examples)) {
      return alert('No Example');
    }
    let str = '';
    _.each(gradeData.examples, (example) => {
      str += '\n';
      if (example.linkedin) {
        str += 'https://linkedin.com/in/' + example.linkedin;
        str += '\n';
      }
      if (example.github) {
        str += 'https://github.com/' + example.github;
        str += '\n';
      }
      if (example.stackoverflow) {
        str +=
          'https://stackoverflow.com/users/' + example.stackoverflow + '/tmp';
        str += '\n';
      }
    });
    return alert(str);
  }

  renderDistributionTable(distributionFromId) {
    if (!distributionFromId) {
      return;
    }
    const grades = [
      'A+',
      'A',
      'AB',
      'B',
      'BC',
      'C',
      'CD',
      'D',
      'DE',
      'E',
      'E-',
    ];
    const sortedDistributionFromId = _.sortBy(
      _.map(distributionFromId, (val, key) => ({ ...val, id: key })),
      (distribution) => (distribution['A'] || {}).count || 0,
    );
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">id</Table.HeaderCell>
            {_.map(grades, (grade, index) => (
              <Table.HeaderCell key={index} textAlign="center">
                {grade}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedDistributionFromId, (distribution, index) => (
            <Table.Row key={index}>
              <Table.Cell textAlign="center">
                <b>{distribution.id}</b>
              </Table.Cell>
              {_.map(grades, (grade, index) => (
                <Table.Cell
                  key={index}
                  textAlign="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleClick(distribution[grade])}
                >
                  <span>{(distribution[grade] || {}).count || 0}</span>
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  renderJobPositionDistributions() {
    const { jobPositionDistributions } = this.state;
    return this.renderDistributionTable(jobPositionDistributions);
  }

  renderSkillDistributions() {
    const { skillDistributions } = this.state;
    return this.renderDistributionTable(skillDistributions);
  }

  render() {
    return (
      <div>
        <h1> GRADES </h1>
        <h2> Job Position </h2>
        {this.renderJobPositionDistributions()}
        <h2> Skills </h2>
        {this.renderSkillDistributions()}
      </div>
    );
  }
}

export default GradesDistributionsMonitoring;
