import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Grid, Table, Button, Dropdown, Input, Image, Popup, Icon, Select, Statistic } from 'semantic-ui-react';
import moment from 'moment';
import FlowBoardUsers from './Users';
import baseUrl from '../baseUrl.js';
import TasksBoard from './TasksBoard';
import { isNumber } from 'util';
import './style.css';

// Actions
const SET_FORM = 'SET_FORM';
const setForm = (form) => ({ type: SET_FORM, form });

const TOGGLE_PFP = 'TOGGLE_PFP';
const togglePushFlowPolicy = () => ({ type: TOGGLE_PFP });

const APPEND_INSTANCES = 'APPEND_INSTANCES';
// const appendInstances = instances => ({ type: APPEND_INSTANCES, instances });

const SET_INSTANCES = 'SET_INSTANCES';
const setInstances = (instances) => ({ type: SET_INSTANCES, instances });

// const SET_SORT_FIELD = 'SET_SORT_FIELD';
// const setSortField = (key, desc) => ({ type: SET_SORT_FIELD, key, desc });

const SET_SORT_FIELDS = 'SET_SORT_FIELDS';
const setSortFields = (fields) => ({ type: SET_SORT_FIELDS, fields });

const SET_FILTERS = 'SET_FILTERS';
const setFilters = (filters) => ({ type: SET_FILTERS, filters });

const SET_USERS = 'SET_USERS';
const setUsers = (users) => ({ type: SET_USERS, users });

// Reducers
const form = (state = {}, action) => {
  switch (action.type) {
    case SET_FORM:
      return action.form;
    default:
      return state;
  }
};

const instances = (state = [], action) => {
  switch (action.type) {
    case APPEND_INSTANCES:
      return [...state, ...action.instances];
    case SET_INSTANCES:
      return action.instances;
    default:
      return state;
  }
};

const pushFlowPolicy = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_PFP:
      return !state;
    default:
      return state;
  }
};

// const sortField = (state = { key: 'companyId', desc: false }, action) => {
//   switch (action.type) {
//     case SET_SORT_FIELD:
//       return { key: action.key, desc: action.desc };
//     default:
//       return state;
//   }
// }

const sortFields = (state = [{ key: 'state', mode: -1 }], action) => {
  switch (action.type) {
    case SET_SORT_FIELDS:
      return action.fields;
    default:
      return state;
  }
};

const filters = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

const users = (state = [], action) => {
  switch (action.type) {
    case SET_USERS:
      return action.users;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form,
  instances,
  pushFlowPolicy,
  sortFields,
  filters,
  users,
});

const getOppositeLetterInAlphabet = (letter) => {
  if (!letter || !_.isString(letter)) return letter;
  const zMinAsciiNumber = 'z'.charCodeAt(0);
  const zMajAsciiNumber = 'Z'.charCodeAt(0);
  const aMinAsciiNumber = 'a'.charCodeAt(0);
  const aMajAsciiNumber = 'A'.charCodeAt(0);
  const letterAsciiNumber = letter.charCodeAt(0);
  const oppositeLetterAsciiNumber =
    letterAsciiNumber >= aMinAsciiNumber && letterAsciiNumber <= zMinAsciiNumber
      ? zMinAsciiNumber - (letterAsciiNumber - aMinAsciiNumber)
      : letterAsciiNumber >= aMajAsciiNumber && letterAsciiNumber <= zMajAsciiNumber
      ? zMajAsciiNumber - (letterAsciiNumber - aMajAsciiNumber)
      : letterAsciiNumber;
  return String.fromCharCode(oppositeLetterAsciiNumber);
};

const getOppositeWord = (word) => _.reduce(word, (memo, letter) => memo + getOppositeLetterInAlphabet(letter), '');

// Store
const store = createStore(rootReducer);

// Components
const stateSourcingOptions = [
  { key: 'all', value: '', text: 'All' },
  { key: 'active', value: 'active', text: 'Active' },
  { key: 'stack', value: 'stack', text: 'Stack' },
];

const getFilteredAndSortedInstances = ({ instances, sortFields, filters }) => {
  const filtersRegex = _.compact(_.map(filters, (val, key) => (key === 'companyId' ? [key, new RegExp(val)] : null)));
  const filtersMatch = _.compact(_.map(filters, (val, key) => (key !== 'companyId' ? [key, val] : null)));
  const filteredInstancesRegex =
    filtersRegex.length > 0
      ? _.filter(instances, (instance) => {
          for (let i = 0; i < filtersRegex.length; i++) {
            if (!filtersRegex[i][1].test(instance[filtersRegex[i][0]])) {
              return false;
            }
          }
          return true;
        })
      : instances;
  const filteredInstances =
    filtersMatch.length > 0
      ? _.filter(filteredInstancesRegex, (instance) => {
          for (let i = 0; i < filtersMatch.length; i++) {
            if (filtersMatch[i][1] !== instance[filtersMatch[i][0]]) {
              return false;
            }
          }
          return true;
        })
      : filteredInstancesRegex;

  const sortedInstances = !_.isEmpty(sortFields)
    ? _.sortBy(filteredInstances, (instance) => {
        const keys = _.map(sortFields, ({ key, mode }) =>
          _.isString(instance[key])
            ? mode === -1
              ? instance[key]
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .padEnd(15, 'a')
              : getOppositeWord(
                  instance[key]
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .padEnd(15, 'a'),
                )
            : mode === -1
            ? (instance[key] || 0).toString().padStart(10, '0')
            : (999999999 - (instance[key] || 0)).toString().padStart(10, '0'),
        ).join('_');
        return keys;
      })
    : filteredInstances;
  return sortedInstances;
};

const getDateColor = (date) =>
  !date
    ? 'black'
    : moment()
        .subtract(3, 'days')
        .isSameOrBefore(moment(date, 'YYYY-MM-DD'))
    ? 'green'
    : moment()
        .subtract(7, 'days')
        .isSameOrBefore(moment(date, 'YYYY-MM-DD'))
    ? 'orange'
    : 'red';

const getTxColor = (percentage) =>
  !isNumber(percentage) ? 'black' : percentage > 70 ? 'green' : percentage > 50 ? 'orange' : 'red';

const getAnswerTxColor = (percentage) =>
  !isNumber(percentage) ? 'black' : percentage > 8 ? 'green' : percentage > 4 ? 'orange' : 'red';

const getBackgroundColorFromDaysAhead = ({ nbDaysAhead, state }) =>
  nbDaysAhead === 0
    ? '#ffdddd'
    : state === 'stack'
    ? '#eeeeee'
    : !_.isNumber(nbDaysAhead)
    ? '#fff'
    : nbDaysAhead < 3
    ? '#ffffdd'
    : '#ddffdd';

const InstancesTable = ({
  instances,
  pushFlowPolicy,
  sortFields,
  filters,
  users,
  onTogglePushFlowPolicy,
  onSort,
  onSortMulti,
  onFilter,
  onLoad,
  restrictedUsers,
}) => {
  const usersOptions = [
    { key: 'all', value: '', text: 'All' },
    ..._.map(users, ({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    })),
  ];
  const filteredAndSortedInstances = getFilteredAndSortedInstances({
    instances,
    sortFields,
    filters,
  });
  // const s = (k) => (
  //   sortField.key === k ?
  //     (sortField.desc ?
  //       { sorted: 'descending', onClick: () => onSort(k, false) } :
  //       { sorted: 'ascending', onClick: () => onSort(k, true) }) :
  //     { onClick: () => onSort(k, false) }
  // );
  const s = (k) => {
    if (_.contains(_.pluck(sortFields, 'key'), k)) {
      const newSortFields = _.compact(
        _.map(sortFields, ({ key, mode }) =>
          key === k && mode === 1
            ? null
            : {
                key,
                mode: key !== k ? mode : mode === -1 ? 1 : -1,
              },
        ),
      );
      const { mode } = _.findWhere(sortFields, { key: k }) || {};
      return {
        ...(mode && {
          className: mode === 1 ? 'table-header-descending' : 'table-header-ascending',
        }),
        onClick: () => onSortMulti(newSortFields),
      };
    } else {
      const { mode } = _.findWhere(sortFields, { key: k }) || {};
      return {
        ...(mode && {
          className: mode === 1 ? 'table-header-descending' : 'table-header-ascending',
        }),
        onClick: () => onSortMulti([...sortFields, { key: k, mode: -1 }]),
      };
    }
  };

  // (
  //   sortField.key === k ?
  //     (sortField.desc ?
  //       { sorted: 'descending', onClick: () => onSortMulti(k, false) } :
  //       { sorted: 'ascending', onClick: () => onSortMulti(k, true) }) :
  //     { onClick: () => onSortMulti(k, false) }
  // );
  const {
    totalNotUploaded,
    totalPendingProfiles,
    totalToBeTreated,
    totalUploaded,
    totalDaysAhead,
    nbWeekSend,
    weekTxSend,
    nbWeekPosAns,
    nbGlobalSend,
    nbGlobalTxSend,
    nbGlobalTxAns,
    nbGlobalTxPosAns,
  } = _.reduce(
    instances,
    (memo, i) => ({
      totalNotUploaded: memo['totalNotUploaded'] + ((i.state !== 'stack' && i.notUploaded) || 0),
      totalPendingProfiles: memo['totalPendingProfiles'] + ((i.state !== 'stack' && i.pendingProfiles) || 0),
      totalToBeTreated: memo['totalToBeTreated'] + ((i.state !== 'stack' && i.nbPerDay) || 0),
      totalUploaded: memo['totalUploaded'] + ((i.state !== 'stack' && i.uploaded) || 0),
      totalDaysAhead: memo['totalDaysAhead'] + ((i.state !== 'stack' && i.nbDaysAhead) || 0),
      nbWeekSend: memo['nbWeekSend'] + ((i.state !== 'stack' && i.weekSend) || 0),
      weekTxSend: memo['weekTxSend'] + ((i.state !== 'stack' && i.weekTxSend) || 0),
      nbWeekPosAns: memo['nbWeekPosAns'] + ((i.state !== 'stack' && i.weekPosAns) || 0),
      nbGlobalSend: memo['nbGlobalSend'] + ((i.state !== 'stack' && i.globalSend) || 0),
      nbGlobalTxSend: memo['nbGlobalTxSend'] + ((i.state !== 'stack' && i.globalTxSend) || 0),
      nbGlobalTxAns: memo['nbGlobalTxAns'] + ((i.state !== 'stack' && i.globalTxAns) || 0),
      nbGlobalTxPosAns: memo['nbGlobalTxPosAns'] + ((i.state !== 'stack' && i.globalTxPosAns) || 0),
    }),
    {
      totalNotUploaded: 0,
      totalPendingProfiles: 0,
      totalToBeTreated: 0,
      totalUploaded: 0,
      totalDaysAhead: 0,
      nbWeekSend: 0,
      weekTxSend: 0,
      nbWeekPosAns: 0,
      nbGlobalSend: 0,
      nbGlobalTxSend: 0,
      nbGlobalTxAns: 0,
      nbGlobalTxPosAns: 0,
    },
  );
  const nbFilteredAndSortedInstances = _.filter(filteredAndSortedInstances, (i) => i.state !== 'stack').length || 1;
  const meanGlobalTxSend = Math.floor((nbGlobalTxSend * 10) / nbFilteredAndSortedInstances) / 10;
  const meanGlobalTxAns = Math.floor((nbGlobalTxAns * 10) / nbFilteredAndSortedInstances) / 10;
  const meanGlobalTxPosAns = Math.floor((nbGlobalTxPosAns * 10) / nbFilteredAndSortedInstances) / 10;
  const meanWeekTxSend = Math.floor((weekTxSend * 10) / nbFilteredAndSortedInstances) / 10;
  const meanDaysAhead = Math.floor((totalDaysAhead * 10) / nbFilteredAndSortedInstances) / 10;
  return (
    <Table celled compact='very' sortable style={{ fontSize: '13px' }}>
      <Table.Header>
        <Table.Row textAlign='center'>
          <Table.HeaderCell colSpan={4} rowSpan={pushFlowPolicy ? 1 : 2} style={{ borderRight: 'solid 1px black' }}>
            Offer
          </Table.HeaderCell>
          <Table.HeaderCell
            colSpan={(restrictedUsers || []).length !== 1 ? 7 : 6}
            rowSpan={pushFlowPolicy ? 1 : 2}
            style={{ borderRight: 'solid 1px black' }}
          >
            Sourcing
          </Table.HeaderCell>
          {!pushFlowPolicy && (
            <Table.HeaderCell colSpan={7} style={{ borderRight: 'solid 1px black' }}>
              Results
            </Table.HeaderCell>
          )}
          {(restrictedUsers || []).length !== 1 && (
            <Table.HeaderCell
              colSpan={pushFlowPolicy ? 3 : 1}
              rowSpan={pushFlowPolicy ? 1 : 3}
              onClick={onTogglePushFlowPolicy}
            >
              {pushFlowPolicy ? `Push Flow Policy` : `PFP`}
            </Table.HeaderCell>
          )}
        </Table.Row>
        {!pushFlowPolicy && (
          <Table.Row textAlign='center'>
            <Table.HeaderCell colSpan={3} style={{ borderRight: 'solid 1px black' }}>
              Last Week
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={4} style={{ borderRight: 'solid 1px black' }}>
              Global
            </Table.HeaderCell>
          </Table.Row>
        )}
        <Table.Row textAlign='center'>
          <Table.HeaderCell {...s('companyId')}>
            <Input
              placeholder='Client'
              style={{ maxWidth: '70px' }}
              onChange={_.debounce((e, o) => onFilter('companyId', o.value), 1000)}
            />
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'companyId') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'companyId') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('title')}>
            Offre
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'title') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'title') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('salesPerson')}>
            <Dropdown
              basic
              compact
              selection
              search
              placeholder='Sales'
              options={usersOptions}
              style={{ maxWidth: '70px' }}
              onChange={(e, o) => onFilter('salesPerson', o.value)}
            />
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'salesPerson') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'salesPerson') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('state')} style={{ borderRight: 'solid 1px black' }}>
            <Dropdown
              basic
              compact
              selection
              search
              placeholder='State'
              options={stateSourcingOptions}
              style={{ maxWidth: '70px' }}
              onChange={(e, o) => onFilter('state', o.value)}
            />
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'state') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'state') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          {/* Next section */}
          {(restrictedUsers || []).length !== 1 && (
            <Table.HeaderCell {...s('owner')}>
              <Dropdown
                basic
                compact
                selection
                search
                placeholder='Owner'
                options={usersOptions}
                style={{ maxWidth: '70px' }}
                onChange={(e, o) => onFilter('owner', o.value)}
              />
              <span>
                {_.indexOf(_.pluck(sortFields, 'key'), 'owner') > -1 &&
                  `(${_.indexOf(_.pluck(sortFields, 'key'), 'owner') + 1})`}
              </span>
              <span className='caret' />
            </Table.HeaderCell>
          )}
          <Table.HeaderCell {...s('nbPendingProfiles')}>
            A Traiter
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'nbPendingProfiles') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'nbPendingProfiles') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('nbPerDay')}>
            Uploads/j
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'nbPerDay') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'nbPerDay') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('notUploaded')}>
            Avance
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'notUploaded') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'notUploaded') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('nbDaysAhead')}>
            Jours
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'nbDaysAhead') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'nbDaysAhead') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('uploaded')}>
            Uploadés
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'uploaded') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'uploaded') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          <Table.HeaderCell {...s('lastActivity')} style={{ borderRight: 'solid 1px black' }}>
            Last activity
            <span>
              {_.indexOf(_.pluck(sortFields, 'key'), 'lastActivity') > -1 &&
                `(${_.indexOf(_.pluck(sortFields, 'key'), 'lastActivity') + 1})`}
            </span>
            <span className='caret' />
          </Table.HeaderCell>
          {/* Next section */}
          {!pushFlowPolicy
            ? [
                <Table.HeaderCell key={'weekSend'} {...s('weekSend')}>
                  Nb Send
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'weekSend') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'weekSend') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
                <Table.HeaderCell key={'weekTxSend'} {...s('weekTxSend')}>
                  Tx Send
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'weekTxSend') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'weekTxSend') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
                <Table.HeaderCell key={'weekPosAns'} {...s('weekPosAns')} style={{ borderRight: 'solid 1px black' }}>
                  Nb Pos Ans
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'weekPosAns') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'weekPosAns') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
                <Table.HeaderCell key={'globalSend'} {...s('globalSend')}>
                  Nb Send
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'globalSend') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'globalSend') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
                <Table.HeaderCell key={'globalTxSend'} {...s('globalTxSend')}>
                  Tx Send
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'globalTxSend') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'globalTxSend') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
                <Table.HeaderCell key={'globalTxAns'} {...s('globalTxAns')}>
                  Tx Ans
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'globalTxAns') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'globalTxAns') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
                <Table.HeaderCell
                  key={'globalTxPosAns'}
                  {...s('globalTxPosAns')}
                  style={{ borderRight: 'solid 1px black' }}
                >
                  Tx Pos Ans
                  <span>
                    {_.indexOf(_.pluck(sortFields, 'key'), 'globalTxPosAns') > -1 &&
                      `(${_.indexOf(_.pluck(sortFields, 'key'), 'globalTxPosAns') + 1})`}
                  </span>
                  <span className='caret' />
                </Table.HeaderCell>,
              ]
            : null}
          {/* Next section */}
          {pushFlowPolicy
            ? [
                <Table.HeaderCell key={'#Candidates'}>Nb Profiles</Table.HeaderCell>,
                <Table.HeaderCell key={'Frequency'}>Frequency</Table.HeaderCell>,
                <Table.HeaderCell key={'MaxStack'}>Max Stack</Table.HeaderCell>,
              ]
            : null}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(filteredAndSortedInstances, (i) => (
          <Table.Row key={i.id} textAlign='center' style={{ backgroundColor: getBackgroundColorFromDaysAhead(i) }}>
            {console.log(i)}
            <Table.Cell textAlign='left'>{i.companyId}</Table.Cell>
            <Table.Cell textAlign='left' style={{ padding: 0 }}>
              <Grid style={{ margin: 0 }}>
                <Grid.Column width={11} style={{ padding: '5px' }}>
                  <a href={`/offers/${i.id}`} target='_blank' rel='noopener noreferrer'>
                    {i.title && i.title.length > 20 ? <div>{i.title.slice(0, 20)}</div> : <div>{i.title}</div>}
                  </a>
                </Grid.Column>
                <Grid.Column width={1} style={{ padding: '5px' }}>
                  {i.title && i.title.length > 20 && (
                    <Popup wide='very' trigger={<Icon name='add' size='small' />} content={i.title} />
                  )}
                </Grid.Column>
                <Grid.Column width={2} style={{ padding: '5px' }} textAlign='right'>
                  {i.sweetsheetId && (
                    <a href={`/flowboard/sweetsheets/id/${i.sweetsheetId}`} target='_blank' rel='noopener noreferrer'>
                      <Image src='/images/google-sheet.png' style={{ width: '15px', height: '15px' }} />
                    </a>
                  )}
                </Grid.Column>
                <Grid.Column width={2} style={{ padding: '5px' }}>
                  {i.platformId && (
                    <a
                      href={`https://app.hiresweet.com/client/${i.companyId}/offers/${i.platformId}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Image src='/images/sweetapp-logo.png' style={{ width: '15px', height: '15px' }} />
                    </a>
                  )}
                </Grid.Column>
              </Grid>
            </Table.Cell>
            <Table.Cell style={{ padding: 0 }}>{i.salesPerson}</Table.Cell>
            <Table.Cell style={{ borderRight: 'solid 1px black' }}>{i.state}</Table.Cell>
            {/* Next section */}
            {(restrictedUsers || []).length !== 1 && <Table.Cell>{i.owner}</Table.Cell>}
            <Table.Cell>{i.nbPendingProfiles}</Table.Cell>
            <Table.Cell>{i.nbPerDay}</Table.Cell>
            <Table.Cell>{i.notUploaded || ''}</Table.Cell>
            <Table.Cell>{i.nbDaysAhead}</Table.Cell>
            <Table.Cell>{i.uploaded || ''}</Table.Cell>
            <Table.Cell style={{ borderRight: 'solid 1px black' }}>
              <div style={{ color: getDateColor(i.lastActivity) }}>
                {!i.lastActivity
                  ? ''
                  : moment()
                      .subtract(1, 'days')
                      .isSameOrBefore(moment(i.lastActivity, 'YYYY-MM-DD'))
                  ? "aujourd'hui"
                  : moment(i.lastActivity).fromNow()}
              </div>
            </Table.Cell>
            {/* Next section */}
            {!pushFlowPolicy
              ? [
                  <Table.Cell key={'weekSend'}>{i.weekSend}</Table.Cell>,
                  <Table.Cell key={'weekTxSend'}>
                    <div style={{ color: getTxColor(i.weekTxSend) }}>
                      <span>{i.weekTxSend ? i.weekTxSend + '%' : ''}</span>
                      {i.weekTxSend && i.globalTxSend && i.weekTxSend < i.globalTxSend - 10 ? (
                        <Icon flipped={'vertically'} name='mouse pointer' />
                      ) : i.weekTxSend && i.globalTxSend && i.weekTxSend > i.globalTxSend + 10 ? (
                        <Icon flipped={'horizontally'} name='mouse pointer' />
                      ) : i.weekTxSend && i.globalTxSend ? (
                        <Icon name='arrow right' />
                      ) : null}
                    </div>
                  </Table.Cell>,
                  <Table.Cell key={'weekPosAns'} style={{ borderRight: 'solid 1px black' }}>
                    {i.weekPosAns}
                  </Table.Cell>,
                  <Table.Cell key={'globalSend'}>{i.globalSend}</Table.Cell>,
                  <Table.Cell key={'globalTxSend'}>
                    <div style={{ color: getTxColor(i.globalTxSend) }}>
                      {i.globalTxSend ? i.globalTxSend + '%' : ''}
                    </div>
                  </Table.Cell>,
                  <Table.Cell key={'globalTxAns'}>{i.globalTxAns ? i.globalTxAns + '%' : ''}</Table.Cell>,
                  <Table.Cell key={'globalTxPosAns'} style={{ borderRight: 'solid 1px black' }}>
                    <div style={{ color: getAnswerTxColor(i.globalTxPosAns) }}>
                      {i.globalTxPosAns ? i.globalTxPosAns + '%' : ''}
                    </div>
                  </Table.Cell>,
                ]
              : null}
            {/* Next section */}
            {/* (restrictedUsers || []).length === 1 ? null :  */}
            {pushFlowPolicy
              ? [
                  <Table.Cell key={'#Candidates'}>{i.candidates}</Table.Cell>,
                  <Table.Cell key={'Frequency'}>{i.frequency}</Table.Cell>,
                  <Table.Cell key={'MaxStack'}>{i.maxStack}</Table.Cell>,
                ]
              : (restrictedUsers || []).length !== 1 && <Table.Cell>...</Table.Cell>}
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row textAlign='center'>
          <Table.HeaderCell colSpan={3}>
            <Button color='teal' fluid onClick={onLoad}>
              Load More
            </Button>
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={(restrictedUsers || []).length !== 1 ? 2 : 1}>Total / Average</Table.HeaderCell>
          <Table.HeaderCell>T : {totalPendingProfiles}</Table.HeaderCell>
          <Table.HeaderCell>T : {totalToBeTreated}</Table.HeaderCell>
          <Table.HeaderCell>T : {totalNotUploaded}</Table.HeaderCell>
          <Table.HeaderCell>A : {meanDaysAhead}</Table.HeaderCell>
          <Table.HeaderCell>T : {totalUploaded}</Table.HeaderCell>
          <Table.HeaderCell />
          {!pushFlowPolicy
            ? [
                <Table.HeaderCell key={'weekSend'}>T : {nbWeekSend}</Table.HeaderCell>,
                <Table.HeaderCell key={'weekTxSend'}>
                  A : {meanWeekTxSend ? meanWeekTxSend + '%' : ''}
                </Table.HeaderCell>,
                <Table.HeaderCell key={'weekPosAns'}>T : {nbWeekPosAns}</Table.HeaderCell>,
                <Table.HeaderCell key={'globalSend'}>T : {nbGlobalSend}</Table.HeaderCell>,
                <Table.HeaderCell key={'globalTxSend'}>
                  A : {meanGlobalTxSend ? meanGlobalTxSend + '%' : ''}
                </Table.HeaderCell>,
                <Table.HeaderCell key={'globalTxAns'}>
                  A : {meanGlobalTxAns ? meanGlobalTxAns + '%' : ''}
                </Table.HeaderCell>,
                <Table.HeaderCell key={'globalTxPosAns'}>
                  A : {meanGlobalTxPosAns ? meanGlobalTxPosAns + '%' : ''}
                </Table.HeaderCell>,
                ...((restrictedUsers || []).length !== 1 ? [<Table.HeaderCell key={'?'} />] : []),
              ]
            : [
                <Table.HeaderCell key={'1'} />,
                <Table.HeaderCell key={'2'}>?</Table.HeaderCell>,
                <Table.HeaderCell key={'3'} colSpan={4} />,
              ]}
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

class StaffingLevelSelector extends Component {
  state = { currentState: this.props.currentState };

  handleChange = async (c, { value }) => {
    try {
      if (value !== this.state.currentState) {
        await axios.put(`${baseUrl}/users/${this.props.user}`, {
          staffingLevel: value,
        });
        this.setState({ currentState: value });
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const stateOptions = [
      { value: 'easy', key: 'Easy', text: 'Easy' },
      { value: 'medium', key: 'Medium', text: 'Medium' },
      { value: 'full', key: 'Full', text: 'Full' },
    ];
    return (
      <div>
        <Select
          placeholder='Select your state'
          options={stateOptions}
          onChange={this.handleChange}
          selection
          value={this.state.currentState}
        />
      </div>
    );
  }
}

class FlowBoard extends Component {
  componentWillMount() {
    const { onSortMulti, onReset, onLoadUsers, onLoad, sortFields, filters, restrictedUsers, watchMode } = this.props;
    onSortMulti([
      { key: 'state', mode: -1 },
      ...((restrictedUsers || []).length === 1 ? [] : [{ key: 'owner', mode: -1 }]),
      { key: 'nbDaysAhead', mode: -1 },
    ]);
    onReset();
    onLoadUsers();

    console.log({ watchMode });

    onLoad(0, sortFields, filters || {}, restrictedUsers, watchMode);
  }

  componentDidMount() {
    const { onLoad, instances, sortFields, filters, restrictedUsers, watchMode } = this.props;
    onLoad(instances.length, sortFields, filters, restrictedUsers, watchMode);
  }

  render() {
    const defaultStats = {
      nbProfilesPerDay: 0,
      nbProfilesPerDayOnNonStackedOffers: 0,
      nbDaysAhead: 0,
      nbDaysAheadOnNonStackedOffers: 0,
    };
    console.log(this.props.instances);
    const { nbProfilesPerDayOnNonStackedOffers, nbDaysAheadOnNonStackedOffers } =
      this.props.instances && this.props.instances.length > 0
        ? _.reduce(
            this.props.instances,
            (memo, i) => ({
              nbProfilesPerDay: memo['nbProfilesPerDay'] + (i.nbPerDay || 0),
              nbProfilesPerDayOnNonStackedOffers:
                memo['nbProfilesPerDayOnNonStackedOffers'] + ((i.state === 'active' && i.nbPerDay) || 0),
              nbDaysAhead: memo['nbDaysAhead'] + (i.nbDaysAhead || 0),
              nbDaysAheadOnNonStackedOffers:
                memo['nbDaysAheadOnNonStackedOffers'] + ((i.state === 'active' && i.nbDaysAhead) || 0),
            }),
            defaultStats,
          )
        : defaultStats;
    const activeInstances = _.filter(this.props.instances || [], (i) => i.state === 'active');
    const meanDaysAhead =
      activeInstances && activeInstances.length > 0 && nbDaysAheadOnNonStackedOffers > -1
        ? Math.floor((nbDaysAheadOnNonStackedOffers * 10) / activeInstances.length) / 10
        : 0;
    const currentUser =
      _.isArray(this.props.restrictedUsers) && this.props.restrictedUsers.length > 0 && this.props.restrictedUsers[0];
    const currentState = (_.findWhere(this.props.users, { id: currentUser }) || {}).staffingLevel;
    return (
      <div style={{ overflowY: 'auto' }}>
        {(this.props.restrictedUsers || []).length !== 1 && (
          <FlowBoardUsers offers={this.props.instances} users={this.props.users} />
        )}
        <br />
        <br />
        <br />
        <br />
        <center>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6} />
              <Grid.Column width={4}>
                <TasksBoard />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
          </Grid>
        </center>
        <br />
        <br />
        <br />
        <br />
        {!_.isEmpty(this.props.users) && (this.props.restrictedUsers || []).length === 1 && (
          <StaffingLevelSelector user={currentUser} currentState={currentState} />
        )}
        {(this.props.restrictedUsers || []).length > 0 && (
          <Statistic.Group widths={'3'} style={{ width: '100%' }}>
            <Statistic>
              <Statistic.Label>Offres actives</Statistic.Label>
              <Statistic.Value>
                {this.props.instances && _.filter(this.props.instances, (i) => i.state === 'active').length}
              </Statistic.Value>
            </Statistic>
            <Statistic>
              <Statistic.Label>Profils par jour sur les offres non stackées</Statistic.Label>
              <Statistic.Value>{nbProfilesPerDayOnNonStackedOffers}</Statistic.Value>
            </Statistic>
            <Statistic>
              <Statistic.Label>Avance moyenne (en jours)</Statistic.Label>
              <Statistic.Value>{meanDaysAhead}</Statistic.Value>
            </Statistic>
          </Statistic.Group>
        )}
        <InstancesTable
          {...this.props}
          onLoad={() =>
            this.props.onLoad(
              this.props.instances.length,
              this.props.sortFields,
              this.props.filters,
              this.props.restrictedUsers,
              this.props.watchMode
            )
          }
          // onSort={(k, v) => this.props.onSort(k, v, this.props.sortField, this.props.filters)}
          onSortMulti={(k) => this.props.onSortMulti(k, this.props.sortFields, this.props.filters)}
          onFilter={(k, v) => this.props.onFilter(k, v, this.props.sortFields, this.props.filters)}
        />
      </div>
    );
  }
}

// Containers
const mapSFlowBoard = (state) => ({
  form: state.form,
  instances: state.instances,
  pushFlowPolicy: state.pushFlowPolicy,
  sortFields: state.sortFields,
  filters: state.filters,
  users: state.users,
//  watchMode: true
});

const loadData = async (skip = 0, { key: sort, desc }, filters, users, watchMode) => {
  const params = {
    skip,
    sort,
    desc: desc ? 1 : 0,
    ...(!_.isEmpty(users) && { users: users.join(';') }),
    ...filters,
    ...watchMode && { watchMode }
  };
  const { data } = await axios.get(`${baseUrl}/flowboard`, { params });
  return data;
};

const mapDFlowBoard = (dispatch) => ({
  onReset: async () => {
    dispatch(setInstances([]));
  },
  onLoadUsers: async () => {
    const { data } = await axios.get(`${baseUrl}/users`);
    dispatch(setUsers(data));
  },
  onTogglePushFlowPolicy: () => dispatch(togglePushFlowPolicy()),
  onChange: (form) => dispatch(setForm(form)),
  onLoad: async (skip = 0, sortFields = {}, filters = {}, users, watchMode) => {
    const data = await loadData(skip, sortFields, filters, users, watchMode);
    dispatch(setInstances(data));
  },
  // onSort: async (key, desc, filters) => {
  //   dispatch(setSortField(key, desc));
  // },
  onSortMulti: async (fields, filters) => {
    // mode:
    // 0: nothing
    // -1: asc
    // 1: desc
    dispatch(setSortFields(fields));
  },
  onFilter: async (key, value, sortFields, filters) => {
    const newFilters = value ? { ...filters, [key]: value } : _.omit(filters, key);
    dispatch(setFilters(newFilters));
  },
});

const FlowBoardContainer = connect(
  mapSFlowBoard,
  mapDFlowBoard,
)(FlowBoard);

export default (props) => (
  <Provider store={store}>
    <FlowBoardContainer {...props} />
  </Provider>
);
