import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Message
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';


class OfferLastRating extends Component {
  state = {};
  componentWillMount() {
    this.handleLoadActions(this.props.jobOfferId);
  }
  handleLoadActions = async (jobOfferId) => {
    const actions = ((await axios.post(`${baseUrl}/station/actions`, {
      jobOfferIds: jobOfferId,
      types: 'rate-selection'
    })).data).results;
    console.log("this.state.actions", actions)
    this.setState({
      actions,
    })
  };
  render() {
    if (_.isEmpty(this.state.actions)) {
      return (
        <div>No rating</div>
      )
    }
    const lastAction = _.first(this.state.actions);
    console.log("lastAction", lastAction)
    const rating = (lastAction || {}).rating || 0
    const color = rating > 7 ? 'green' : rating > 3 ? 'orange' : 'red';
    return (
      <Message color={color}>
        <Message.Header>
        [{_.first(lastAction.date, 10)}] Last rating : {rating}
        </Message.Header>
        {_.rest(lastAction.reason, 2) && _.rest(lastAction.reason, 2)}
      </Message>
    );
  }
}

export default OfferLastRating;
