import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { Map, fromJS } from 'immutable';
import { Button, Form, Modal, Grid } from 'semantic-ui-react';
import { SweetForm, Input, Checkbox, Clearable, Select, SelectDate, List, Range } from 'sweetform';
import {
  SelectJobTitles,
  PushFlowPolicy,
  SkillsEditor,
  SelectExperience,
  SelectUsers,
  SelectTags,
  CONTRACT_TYPES,
  MAHER_CATEGORIES,
  OFFER_STATUS,
  SelectPlatformOffers,
  SelectResponsibilities,
  EducationEditor,
} from './EditOfferModalComponents';
import { Textarea } from '../../common';
import { EditOmegaCriteria, schemaCriteriaToFormCriteria, formCriteriaToSchemaCriteria } from './EditOmegaCriteria';

class EditOfferModal extends React.Component {
  componentWillMount() {
    const currentValueRaw = this.props.initialValues || {};
    // convert criteria to the good format for sweetform
    const currentValue = {
      ...currentValueRaw,
      criteria: schemaCriteriaToFormCriteria(currentValueRaw.criteria || {}),
    };
    this.setState({
      currentValue,
      pushCriteriaModal: false,
      criteriaMode: 'old',
    });
  }

  getFormattedResult = (value) => {
    return {
      ...value,
      ...(value.tags && {
        tags: _.filter(value.tags, (tag) => !_.isEmpty(tag)),
      }),
      criteria: formCriteriaToSchemaCriteria(value.criteria || {}),
    };
  };

  handleChangeEdit = (newValue) => {
    this.setState({ currentValue: newValue });
    if (this.props.onUpdate) {
      this.props.onUpdate({
        data: this.getFormattedResult(newValue),
      });
    }
  };

  handleSubmit = async (creationMode) => {
    const { onSubmit, onClose } = this.props;
    let { currentValue } = this.state;
    if (_.isEmpty(currentValue.title)) {
      return alert('field title is required !');
    }
    if (creationMode) {
      console.log('editting id');
      currentValue.id = (currentValue.id || currentValue.companyId + '-' + currentValue.title)
        .toLowerCase()
        .replace(/[^a-z0-9_]/g, '-');
      if (currentValue.id.indexOf(' ') >= 0) {
        return alert('white spaces are not allowed in ids');
      }
      if (currentValue.id.toLowerCase() !== currentValue.id) {
        return alert('upper case characters are not allowed in ids');
      }
      if (currentValue.id.indexOf('/') >= 0) {
        return alert('slashes are not allowed in ids');
      }
    }

    // convert criteria to the good format for sweetform
    let currentValueFormatted = {};
    try {
      console.log('formatting');
      console.log(currentValue);
      currentValueFormatted = this.getFormattedResult(currentValue);
    } catch (e) {
      return alert(e);
    }

    console.log('SUB ', currentValueFormatted);
    const result = await onSubmit(currentValueFormatted);
    if (result.success) {
      onClose();
    } else {
      return alert(JSON.stringify(result.error));
    }
  };
  handleDelete = async () => {
    const { onDelete, onClose } = this.props;
    const { currentValue } = this.state;
    const result = await onDelete(currentValue);
    if (result.success) {
      onClose();
    } else {
      return alert(result.error);
    }
  };
  onChangeCriteriaMode = (mode) => {
    this.setState({ criteriaMode: mode });
  };

  renderBody() {
    const { initialValues, onDelete } = this.props;
    const creationMode = _.isEmpty(initialValues) || !initialValues.id;
    const { currentValue, criteriaMode } = this.state;
    const cbStyle = { display: 'block', marginTop: 9 };


    return (
      <SweetForm initialValues={currentValue} onChange={this.handleChangeEdit}>
        <Form>
          <Grid columns={2}>
            <Grid.Column>
              <h2> Admin </h2>
              {creationMode ? (
                <div>
                  <Form.Field>
                    <label>Id</label>
                    <Clearable component={Input} field='id' innerProps={{ placeholder: 'offer-id' }} nullLabel='Auto' />
                  </Form.Field>
                  <Form.Field>
                    <label>WorkplaceId</label>
                    <Input disabled field='companyId' />
                  </Form.Field>
                </div>
              ) : null}
              <Form.Field>
                <label>Title</label>
                <Input autoFocus field='title' />
              </Form.Field>
              <Grid>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Form.Field>
                      <label>Category</label>
                      <Select field='category' labelKey='name' valueKey='id' options={MAHER_CATEGORIES} />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Contract Type</label>
                      <Select field='contractType' options={CONTRACT_TYPES} defaultValue='CDI' />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Responsability</label>
                      <SelectResponsibilities
                        field='responsibilities'
                      // defaultValue="normal"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Form.Field>
                <label>Start date</label>
                <Clearable
                  field='startDate'
                  component={SelectDate}
                  nullLabel='ASAP'
                  nullValue={Map({ year: +moment().format('YYYY') })}
                />
              </Form.Field>
              <Form.Field>
                <label>PushFlow Policy</label>
                <PushFlowPolicy
                  field='pushFlowPolicy'
                  defaultValue={fromJS({
                    type: 'daily-regular',
                    nbPerDay: 10,
                    maxStackSize: 30,
                  })}
                  nullValue={Map({})}
                />
              </Form.Field>
              <Grid>
                <Grid.Column width={4}>
                  <Form.Field>
                    <Checkbox slider field='isAutopilot' style={cbStyle} defaultValue={false} label='AutoPilot' />
                  </Form.Field>
                </Grid.Column>
              </Grid>
              <Grid columns={2}>
                <Grid.Column>
                  <Form.Field>
                    <label>Platform Id</label>
                    <SelectPlatformOffers field='platformId' />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Status</label>
                    <Select field='status' defaultValue='current' options={OFFER_STATUS} />
                  </Form.Field>
                </Grid.Column>
              </Grid>
              <Form.Field>
                <label>Custom Upload Rule (mainly for Workable)</label>
                <Clearable component={Input} field='uploadRule' />
              </Form.Field>
              <Form.Field>
                <label>Onboarding Date (suggested: {new Date().toISOString().slice(2, 10)})</label>
                <Input field='onboardingDate' defaultValue={new Date().toISOString().slice(2, 10)} />
              </Form.Field>
              <Grid>
                <Grid.Column width={4}>
                  <Checkbox slider field='isActive' style={cbStyle} defaultValue={true} label='Active' />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Checkbox
                    slider
                    field='isHiresweet'
                    style={{
                      ...cbStyle,
                      ...(currentValue.isHiresweet === false && { background: 'orange' }),
                    }}
                    defaultValue={true}
                    label={currentValue.isHiresweet === false ? 'NOT HIRESWEET - DANGER' : 'Hiresweet'}
                  />
                </Grid.Column>
              </Grid>
              <Form.Field>
                <label>Salary range (per year)</label>
                <Clearable
                  component={Range}
                  field='salaryRange'
                  innerProps={{ min: 15000, max: 200000, step: 1000 }}
                  nullValue={Map()}
                />
              </Form.Field>
              <Form.Field>
                <label>Link</label>
                <Clearable component={Input} field='link' />
              </Form.Field>
              <Grid columns={2}>
                <Grid.Column width={8}>
                  <Form.Field>
                    <label>Seeking-Candidates Only</label>
                    <Clearable component={Checkbox} field='seekingCandidatesOnly' />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field>
                    <label>Disable Seeking Candidates</label>
                    <Checkbox field='disableSeekingCandidates' defaultValue={false} />
                  </Form.Field>
                </Grid.Column>
              </Grid>

              <Form.Field>
                <Textarea field='description' placeholder='Description' />
              </Form.Field>
              <Grid columns={2}>
                <Grid.Column>
                  <Form.Field>
                    <Textarea field='comments' placeholder='Comments' />
                  </Form.Field>
                  <Grid columns={2}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Users</label>
                        <List field='users' component={SelectUsers} />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Sales</label>
                        <List field='sales' component={SelectUsers} />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <Textarea field='callReport' placeholder='Call Report' />
                  </Form.Field>
                  <Grid columns={2}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Recipients</label>
                        <List field='recipients' component={Input} />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Tags</label>
                        <List field='tags' component={SelectTags} />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Column width={4}>
                  <h2> Criteria </h2>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button onClick={() => this.onChangeCriteriaMode('old')} positive={criteriaMode === 'old'}>
                    Current
                  </Button>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button onClick={() => this.onChangeCriteriaMode('omega')} positive={criteriaMode === 'omega'}>
                    Omega
                  </Button>
                </Grid.Column>
              </Grid>
              {criteriaMode === 'omega' ? (
                <div>
                  <Checkbox slider field='useSweetappCriteria' style={cbStyle} label='Use Sweetapp Criteria' />
                  <EditOmegaCriteria field='criteria' onlyExtraCriteria={(currentValue || {}).useSweetappCriteria} />
                </div>
              ) : (
                <div>
                  <Grid>
                    <Grid.Column width={4}>
                      <Checkbox slider field='topSchool' style={cbStyle} label='Top School' />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Checkbox slider field='remote' style={cbStyle} label='Remote' />
                    </Grid.Column>
                  </Grid>
                  <Form.Field>
                    <label>Job</label>
                    <SelectJobTitles field='jobId' />
                  </Form.Field>
                  <Form.Field>
                    <label>Location</label>
                    <Input field='location' defaultValue='paris' />
                  </Form.Field>
                  <SkillsEditor field='skills' />
                  <Form.Field>
                    <label>Experience range</label>
                    <Clearable
                      component={Range}
                      field='experience'
                      innerProps={{ component: SelectExperience }}
                      nullValue={Map()}
                    />
                  </Form.Field>
                  <Form.Field>
                    <EducationEditor field='education' />
                  </Form.Field>
                </div>
              )}
            </Grid.Column>
          </Grid>
          {!creationMode && onDelete && (
            <Form.Field>
              <label>Write "DELETE" to enable the delete button</label>
              <Input field='deleteField' />
            </Form.Field>
          )}
        </Form>
      </SweetForm>
    );
  }

  render() {
    const { initialValues, onClose, onDelete, onOpenPushCriteria, noModal } = this.props;
    const creationMode = _.isEmpty(initialValues) || !initialValues.id;
    const { currentValue, criteriaMode } = this.state;
    const cbStyle = { display: 'block', marginTop: 9 };

    if (noModal) {
      // small hack
      return this.renderBody();
    }

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>{creationMode ? 'Create Offer' : 'Edit Offer ' + initialValues.id}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          {this.renderBody()}
        </Modal.Content>
        <Modal.Actions>
          {!creationMode && currentValue && onDelete && currentValue.deleteField === 'DELETE' && (
            <Button negative onClick={this.handleDelete}>
              DELETE
            </Button>
          )}
          {onOpenPushCriteria ? (
            <Button
              color='blue'
              onClick={() => onOpenPushCriteria(currentValue)}
              disabled={!(this.state.currentValue || {}).platformId}
            >
              Push Criteria
            </Button>
          ) : null}
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={() => this.handleSubmit(creationMode)}>
            {creationMode ? 'Create' : 'Edit'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default EditOfferModal;
