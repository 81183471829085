import _ from 'underscore';
import React from 'react';
import {
  Card,
  Icon,
} from 'semantic-ui-react';
import MatchMiniCard from './MatchMiniCard';

const WatchSelection = ({ 
  selection,
  handleClearSelection,
  handleSelectTargetSheetItem,
  handleSelectTargetClient,
  handleChangeMatchState,
  handleUpdateMatch,
  handleDeleteMatch,
  handleUploadProfile
}) => {
  if (!selection) {
    return null;
  }
  const { type, descriptor, matches } = selection;
  const sortedMatches = _.sortBy(matches, ({ disqualified, score, bestOfferActivity }) => {
    if (disqualified){
      return -(score || 0) + 100000
    } else if (score > 0.5 && ['high', 'medium'].indexOf(bestOfferActivity)>=0) {
      return - score - 100000
    } else {
      return -(score || 0)
    }
  });
  console.log('selection matches', sortedMatches)

  return (
    <Card fluid>
      <Card.Content>
        <div style={{ position: 'absolute', top: 3, right: 5 }}>
          <Icon
            name="close"
            link
            onClick={() => handleClearSelection()}
          />
        </div>
        <Card.Header>{(descriptor || {}).title || 'No Title'}</Card.Header>
        <Card.Description>
          {_.map(sortedMatches, (match, index) => (
            <MatchMiniCard
              key={match.clientId + '_' + match.sheetItemId}
              type={type}
              match={match}
              onSelect={() => {
                if (type === 'profile') {
                  handleSelectTargetSheetItem(match.sheetItemId);
                } else if (type === 'client') {
                  handleSelectTargetClient(match.clientId);
                } else {
                  return alert('unhandled section type ' + type);
                }
              }}
              onChangeState={(newState) => {
                handleChangeMatchState({ match, newState });
              }}
              onDelete={() => {
                if (match.state === 'pool') {
                  return alert('A pool profile cannot be deleted');
                } else {
                  handleDeleteMatch({ match });
                }
              }}
              onChangeDisqualify={(disqualified) => {
                const update = {disqualified}
                handleUpdateMatch({ match, update});
              }}
              handleUploadProfile={handleUploadProfile}
            />
          ))}
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

export default WatchSelection;
