import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button, Icon, Label, Table, Header, Popup, IconGroup } from 'semantic-ui-react';
import moment from 'moment';
import Modal from '../../Modal';

export const STATUS_TYPES_PARAMS = [
  { id: 'enabled', name: 'Enabled', color: '#ddffdd' },
  { id: 'hold', name: 'Hold', color: '#fff4d4' },
  { id: 'disabled', name: 'Disabled', color: '#ff8a8a' },
];

const ACTIONS_PARAMS = [
  {
    id: 'hold',
    actionId: 'hold',
    icon: 'pause',
    name: 'Hold',
    color: '#fff4d4',
  },
  {
    id: 'enable',
    actionId: 'enable',
    icon: 'check',
    name: 'Enable',
    color: '#ddffdd',
  },
  {
    id: 'disable',
    actionId: 'disable',
    icon: 'close',
    name: 'Disable',
    color: '#ff8a8a',
  },
  {
    id: 'disable-reactivation-notification',
    actionId: 'disable',
    icon: 'mail',
    name: 'Disable Mail',
    color: '#ff8a8a',
    notificationId: 'disableReactivation',
  },
];

class ActionButton extends Component {
  render() {
    const { icon, name, onPerformAction, actionId, notificationId, color, mini } = this.props;
    return (
      <Button
        size='small'
        basic
        icon
        {...!mini && { labelPosition: 'left' }}
        onClick={() => onPerformAction({ actionId, notificationId })}
        style={{ backgroundColor: color }}
      >
        <Icon name={icon} />
        {mini ? null : name}
      </Button>
    );
  }
}

const ExtendEnabledButton = ({ onSetExtendedEnabledTimestamp, nbWeeks, extendedEnabledTimestamp }) => {
  const timestamp =
    1000 *
      moment()
        .startOf('isoWeek')
        .add(3, 'days')
        .unix() +
    nbWeeks * 7 * 24 * 3600 * 1000;
  const isExtended = extendedEnabledTimestamp && extendedEnabledTimestamp > new Date().getTime();
  return (
    <Popup
      trigger={
        <Button
          size='small'
          basic
          icon
          labelPosition={'left'}
          onClick={() => {
            if (isExtended) {
              return;
            }
            onSetExtendedEnabledTimestamp({ timestamp });
          }}
        >
          <Icon name={'add'} color={isExtended && 'blue'} /> {nbWeeks} weeks
        </Button>
      }
      content={
        <div>
          {isExtended ? (
            <span>
              Candidate is enabled until at least {moment(extendedEnabledTimestamp).format('DD/MM/YYYY')}
              <br />
            </span>
          ) : (
            <span>
              {' '}
              Add {nbWeeks} weeks of activity for candidate, until {moment(timestamp).format('DD/MM/YYYY')}
            </span>
          )}
        </div>
      }
    />
  );
};

const DisplayStatus = ({ status, creationTimestamp }) => {
  const { actions, extendedEnabledTimestamp, lastEnabledTimestamp, lastDisabledTimestamp, lastHoldTimestamp } =
    status || {};

  return (
    <div>
      <Header as='h2'>Last Changes</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Creation</Table.Cell>
            <Table.Cell>{creationTimestamp && moment(creationTimestamp).toISOString()}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Hold</Table.Cell>
            <Table.Cell>{lastHoldTimestamp && moment(lastHoldTimestamp).toISOString()}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Enable</Table.Cell>
            <Table.Cell>{lastEnabledTimestamp && moment(lastEnabledTimestamp).toISOString()}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Disable</Table.Cell>
            <Table.Cell>{lastDisabledTimestamp && moment(lastDisabledTimestamp).toISOString()}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Extended Enabled</Table.Cell>
            <Table.Cell>{extendedEnabledTimestamp && moment(extendedEnabledTimestamp).toISOString()}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Header as='h2'>Actions</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Operation</Table.HeaderCell>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(actions, ({ type, operation, status, timestamp, source, reason }, i) => (
            <Table.Row key={i}>
              <Table.Cell>{timestamp && moment(timestamp).toISOString()}</Table.Cell>
              <Table.Cell>{type}</Table.Cell>
              <Table.Cell>{operation || status}</Table.Cell>
              <Table.Cell>{source}</Table.Cell>
              <Table.Cell>{reason}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

class WatchStatus extends Component {
  state = { loading: false, displayModalOpen: false };

  performActionWithLoading = async (params) => {
    this.setState({ loading: true });
    const { onPerformAction } = this.props;
    try {
      await onPerformAction(params);
    } catch (e) {
      alert('Error while performing action.' + JSON.stringify(e));
    }
    this.setState({ loading: false });
  };

  performSetExtendedEnabledTimestampWithLoading = async ({ timestamp }) => {
    this.setState({ loading: true });
    try {
      await this.props.onSetExtendedEnabledTimestamp(timestamp);
    } catch (e) {
      alert('Error while performing action.' + JSON.stringify(e));
    }
    this.setState({ loading: false });
  };

  handleOpenDisplayModal = () => {
    this.setState({ displayModalOpen: true });
  };

  handleCloseDisplayModal = () => {
    this.setState({ displayModalOpen: false });
  };

  render() {
    if (!this.props) {
      return <div />;
    }
    const { status, creationTimestamp, mini } = this.props;
    const { loading, displayModalOpen } = this.state;
    if (loading) {
      return <Button loading />;
    }
    const statusTypeToParams = _.indexBy(STATUS_TYPES_PARAMS, 'id');
    const actionToParams = _.indexBy(ACTIONS_PARAMS, 'id');
    return (
      <Grid>
        <Grid.Row>
          {status && status.type && !mini && (
            <Label size='big' style={{ backgroundColor: (statusTypeToParams[status.type] || {}).color }}>
              {(statusTypeToParams[status.type] || {}).name}{' '}
              {status.holdUntilTimestamp
                ? `(hold until ${moment(status.holdUntilTimestamp).format('YYYY-MM-DD HH:mm:ss')})`
                : ''}
            </Label>
          )}
          {status && status.type === 'enabled' ? (
            <div>
              <ActionButton
                {...actionToParams['disable']}
                mini={mini}
                onPerformAction={this.performActionWithLoading}
              />
              <ActionButton {...actionToParams['hold']} mini={mini} onPerformAction={this.performActionWithLoading} />
              <ExtendEnabledButton
                onSetExtendedEnabledTimestamp={this.performSetExtendedEnabledTimestampWithLoading}
                nbWeeks={4}
                extendedEnabledTimestamp={status.extendedEnabledTimestamp}
              />
            </div>
          ) : null}
          {status && status.type === 'hold' ? (
            <div>
              <ActionButton
                {...actionToParams['disable']}
                mini={mini}
                onPerformAction={this.performActionWithLoading}
              />
              <ActionButton
                {...actionToParams['disable-reactivation-notification']}
                mini={mini}
                onPerformAction={this.performActionWithLoading}
              />
              <ActionButton {...actionToParams['enable']} mini={mini} onPerformAction={this.performActionWithLoading} />
            </div>
          ) : null}
          {status && status.type === 'disabled' ? (
            <div>
              <ActionButton {...actionToParams['hold']} mini={mini} onPerformAction={this.performActionWithLoading} />
              <ActionButton {...actionToParams['enable']} mini={mini} onPerformAction={this.performActionWithLoading} />
            </div>
          ) : null}
          {!mini && (
            <Button icon size='mini' onClick={() => this.handleOpenDisplayModal()}>
              <Icon name='info' />
            </Button>
          )}
          {displayModalOpen && (
            <Modal
              active={true}
              headerText={'Details'}
              onCancel={this.handleCloseDisplayModal}
              size='fullscreen'
              closeOnDimmerClick={true}
            >
              <DisplayStatus status={status} creationTimestamp={creationTimestamp} />
            </Modal>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

export default WatchStatus;
