import React from 'react';
import _ from 'underscore';

const getEnrichedTextCost = (enrichedText, { lineCost, charCost }) => {
  if (!enrichedText) {
    return 0;
  }
  if (enrichedText.text) {
    return (enrichedText.text || '').length * charCost;
  }

  const { children } = enrichedText;
  if (children && children.length && !_.isEmpty(children)) {
    const lineCostPart =
      enrichedText.type === 'paragraphs'
        ? (children.length - 1) * lineCost * 2
        : enrichedText.type === 'paragraph'
        ? (children.length - 1) * lineCost
        : 0;
    const childrenCost = _.reduce(
      children,
      (cumCost, enrichedChild) => cumCost + getEnrichedTextCost(enrichedChild, { lineCost, charCost }),
      0,
    );
    return lineCostPart + childrenCost;
  }
  return 0;
};

const smoothCutText = (text, nbChars) => {
  const targetNbCharsToRemove = Math.max(0, text.length - nbChars);
  if (text.length <= 20 || 4 * targetNbCharsToRemove <= text.length || targetNbCharsToRemove <= 20) {
    return text;
  }
  return `${text.slice(0, nbChars).trim()}...`;
};

const truncateEnrichedText = (enrichedText, { lineCost, charCost, maxCost }) => {
  if (!enrichedText) {
    return null;
  }
  if (enrichedText.text) {
    const cost = getEnrichedTextCost(enrichedText, { lineCost, charCost });
    return cost < maxCost
      ? enrichedText
      : {
          ...enrichedText,
          text: smoothCutText(enrichedText.text, maxCost),
        };
  }
  const { children } = enrichedText;
  if (children && children.length && !_.isEmpty(children)) {
    const truncatedChildren = [];
    let curCost = maxCost;
    _.each(children, (child) => {
      if (curCost <= 0) {
        return;
      }
      truncatedChildren.push(
        truncateEnrichedText(child, {
          lineCost,
          charCost,
          maxCost: curCost,
        }),
      );
      const childCost = getEnrichedTextCost(child, { lineCost, charCost });
      curCost -= childCost;
      if (enrichedText && enrichedText.type === 'paragraphs') {
        curCost -= 2 * lineCost;
      }
      if (enrichedText && enrichedText.type === 'paragraph') {
        curCost -= lineCost;
      }
    });
    return {
      ...enrichedText,
      children: truncatedChildren,
    };
  }
  return enrichedText;
};

class CollapsibleEnrichedText extends React.PureComponent {
  static defaultProps = {
    lineCost: 100,
    charCost: 1,
    targetMaxCost: 500,
    toleranceFactor: 0.5,
    seeMoreButtonText: 'profile.contact.timeline.seeMore',
    seeLessButtonText: 'profile.contact.timeline.seeLess',
  };
  state = {
    collapsed: true,
  };
  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  renderToggleButton() {
    const { t, seeLessButtonText, seeMoreButtonText } = this.props;
    const seeMoreButtonTextTranslated = t(seeMoreButtonText) ? t(seeMoreButtonText) : seeMoreButtonText;
    const seeLessButtonTextTranslated = t(seeLessButtonText) ? t(seeLessButtonText) : seeLessButtonText;
    const innerText = this.state.collapsed ? seeMoreButtonTextTranslated : seeLessButtonTextTranslated;
    const className = this.state.collapsed ? 'toggle-see-more' : 'toggle-see-less';
    if (!this.state.collapsed && this.props.expandOnly) {
      return;
    }
    return (
      <span className={`collapsible-toggle ${className}`} onClick={this.toggleCollapsed}>
        {innerText}
      </span>
    );
  }
  renderEnrichedText(enrichedText, withToggleButton, isLast) {
    const displayButton = withToggleButton && isLast;
    if (!enrichedText) {
      return null;
    }
    const { children } = enrichedText;
    if (enrichedText.text) {
      return (
        <span>
          {enrichedText.text}&nbsp;
          {displayButton && this.renderToggleButton()}
        </span>
      );
    }
    if (children && children.length && !_.isEmpty(children)) {
      return (
        <span>
          {_.map(children, (child, index) => [
            <span key={2 * index}>{this.renderEnrichedText(child, withToggleButton, child === _.last(children))}</span>,
            enrichedText.type === 'paragraphs' && child !== _.last(children) ? (
              <div key={2 * index + 1}>
                <br />
              </div>
            ) : null,
            enrichedText.type === 'paragraph' && child !== _.last(children) ? (
              <br key={'br' + (2 * index + 1)} />
            ) : null,
          ])}
        </span>
      );
    }
    if (displayButton) {
      return <span>{displayButton && this.renderToggleButton()}</span>;
    }
    return null;
  }
  render() {
    const { lineCost, charCost, targetMaxCost, toleranceFactor, enrichedText } = this.props;

    const { collapsed } = this.state;
    const totalCost = getEnrichedTextCost(enrichedText, { lineCost, charCost });
    const noCollapse = totalCost < targetMaxCost * (1 + toleranceFactor);

    const withoutLastEmptyParagraph = (enrichedText) => {
      if (enrichedText && !_.isEmpty(enrichedText.children)) {
        const lastChild = _.last(enrichedText.children);
        if (lastChild && lastChild.type === 'raw' && !lastChild.text) {
          return {
            ...enrichedText,
            children: enrichedText.children.slice(0, enrichedText.children.length - 1),
          };
        }
      }
      return enrichedText;
    };

    const displayedEnrichedText = withoutLastEmptyParagraph(
      noCollapse || !collapsed
        ? enrichedText
        : truncateEnrichedText(enrichedText, {
            lineCost,
            charCost,
            maxCost: targetMaxCost,
          }),
    );

    if (!(enrichedText || {}).children) {
      return this.renderEnrichedText(displayedEnrichedText, !noCollapse, true);
    }

    return this.renderEnrichedText(displayedEnrichedText, !noCollapse);
  }
}

export default CollapsibleEnrichedText;
