import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

import { Card, Checkbox, Form, Grid, Menu, Icon, Popup, Table } from 'semantic-ui-react';

import CreateClientModal from './CreateClientModal.js';
import ManualRecommendations from './ManualRecommendations.js';
import AutoRecommendations from './AutoRecommendations.js';

class RevealRecommendations extends Component {
  componentDidMount() {
    this.getTargetClients();
  }

  async getTargetClients() {
    const url = baseUrl + '/revealRecommendations/getTargetClients';
    const targetClients = (await axios.get(url)).data;
    this.setState({ targetClients });
  }

  handleChangeActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  selectClient = async (clientId) => {
    const { targetClients } = this.state || {};
    const selectedClient = _.find(targetClients, (client) => client.id === clientId);
    this.setState({ selectedClient });
  };

  handleSelectClient = async (e, { value }) => {
    if (value === 'new-client') {
      this.setState({ createClientModalVisible: true });
      return;
    }
    await this.selectClient(value);
  };

  createTargetClient = async (params) => {
    const url = baseUrl + '/revealRecommendations/createTargetClient';
    const { data } = await axios.post(url, params);
    return data;
  };

  onSubmitCreateClient = async (selectedProjectInfo) => {
    if (_.isUndefined(selectedProjectInfo)) {
      this.setState({ createClientModalVisible: false });
      return;
    }
    if (_.isUndefined(selectedProjectInfo.id) || _.isEmpty(selectedProjectInfo.id)) {
      alert('Project Id should not be empty.');
      return;
    }
    if (_.isUndefined(selectedProjectInfo.userId) || _.isEmpty(selectedProjectInfo.userId)) {
      alert('userId should not be empty.');
      return;
    }
    const targetClient = {
      id: selectedProjectInfo.userId,
      name: selectedProjectInfo.userId,
      projectId: selectedProjectInfo.id,
    };

    const data = await this.createTargetClient(targetClient);
    if (data.error) {
      alert(data.error);
      return;
    }
    await this.getTargetClients();
    await this.selectClient(targetClient.id);
    this.setState({ createClientModalVisible: false });
  };

  onCancelCreateClient = () => {
    this.setState({ createClientModalVisible: false });
  };

  render() {
    const tabNames = ['Manual', 'Auto Reco'];
    const { createClientModalVisible, selectedClient, targetClients } = this.state || {};
    const activeMenu = (this.state || {}).activeMenu || 'Manual';

    const sortedTargetClients = _.sortBy(targetClients, (client) => client.name.toLowerCase());
    const clientOptions = _.map(sortedTargetClients || [], (client, key) => ({
      value: client.id,
      key: key,
      text: client.name ? client.name : client.title,
    })).concat([
      {
        value: 'new-client',
        key: 'new-client',
        text: '+ Create Client',
      },
    ]);

    return (
      <div>
        {createClientModalVisible ? (
          <CreateClientModal onSubmit={this.onSubmitCreateClient} onCancel={this.onCancelCreateClient} />
        ) : null}
        <Form.Select
          search
          placeholder='Clients'
          options={clientOptions}
          onChange={this.handleSelectClient}
          value={(selectedClient || {}).id}
        />
        <br />
        {selectedClient ? (
          <Grid columns={2}>
            <Grid.Column width={2}>
              <Menu pointing secondary fluid vertical tabular>
                {_.map(tabNames, (tabName, index) => (
                  <Menu.Item
                    key={index}
                    name={tabName}
                    active={activeMenu === tabName}
                    onClick={() => {
                      this.handleChangeActiveMenu(tabName);
                    }}
                  >
                    {tabName}
                  </Menu.Item>
                ))}
              </Menu>
            </Grid.Column>
            <Grid.Column width={14}>
              {activeMenu == 'Manual' ? (
                <ManualRecommendations selectedClient={selectedClient} key={selectedClient.id} />
              ) : activeMenu == 'Auto Reco' ? (
                <AutoRecommendations selectedClient={selectedClient} key={selectedClient.id} />
              ) : null}
            </Grid.Column>
          </Grid>
        ) : null}
      </div>
    );
  }
}

export default RevealRecommendations;
