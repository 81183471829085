import React from 'react';
import _ from 'underscore';
import { Progress } from 'semantic-ui-react';

const ProgressBar = ({ progress }) => {
  if (!progress || _.isEmpty(progress)) {
    return null;
  }
  const { pushFlowPolicy, itemsStatusCount, watchItemsStatusCount } = progress;
  const nbPerDay = (pushFlowPolicy || {}).nbPerDay;
  const nbPending = (itemsStatusCount || {}).pending || 0;
  const nbPendingWatch = (watchItemsStatusCount || {}).pending || 0;
  const nbDaysAhead = nbPerDay > 0 ? Math.floor(nbPending / nbPerDay) : null;
  const lastDayProgress = nbPending % nbPerDay;
  let label =
    nbDaysAhead > 1
      ? `${nbDaysAhead} days ahead + ${lastDayProgress}/${nbPerDay}`
      : nbDaysAhead === 1
      ? `1 day ahead + ${lastDayProgress}/${nbPerDay}`
      : `No days ahead (${lastDayProgress}/${nbPerDay})`;

  if (nbPendingWatch) {
    label += `  [${nbPendingWatch}w]`
  }
  
  const emoji = nbDaysAhead > 1 ? '😍' : nbDaysAhead === 1 ? '🙂' : '😢';
  return (
    <div style={{ display: 'flex' }}>
      <span style={{ fontSize: 24, marginRight: 5 }}>{emoji}</span>
      <Progress
        value={lastDayProgress}
        total={nbPerDay}
        style={{ flexGrow: 1 }}
        success={nbDaysAhead >= 3}
        warning={nbDaysAhead > 0 && nbDaysAhead < 3}
        error={nbDaysAhead === 0}
        label={label}
        size='tiny'
      />
    </div>
  );
};
export default ProgressBar;
