import _ from 'underscore';
import uuid from 'uuid/v4';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment, List, Button, Form, Dimmer, Icon, Label, Checkbox, Popup } from 'semantic-ui-react';
import { SweetForm, Input } from 'sweetform';

import { Textarea } from '../common';
import baseUrl from '../baseUrl';
import { WorkPipeStats } from '../Actions';
import Modal from '../Modal';
import { formScorerToQuery, SearchModal } from './SearchModal';
import { actions } from './reducers';

const AddEmptyModal = ({ onChangeEdit, onCancel, onSubmit }) => (
  <Modal active={true} headerText="Add new input" onCancel={onCancel} onSubmit={onSubmit}>
    <SweetForm onChange={onChangeEdit}>
      <Grid columns={1}>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>Input title</label>
              <Input field="title" defaultValue={moment().format('YYMMDD_HHmmss')} />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
    </SweetForm>
  </Modal>
);
const InputTags = ({ input }) => {
  const rootFilters = ((input.searchParam || {}).searchParams || {}).filters || [];

  const tags = _.compact(
    _.map(rootFilters, (filter) => {
      const id = (filter || {}).id || '';
      let color = id.toLowerCase().indexOf('relaxed') >= 0 ? 'orange' : 'green';
      let text = null;
      if (id.toLowerCase().indexOf('everbeento') >= 0) {
        text = (filter.params || {}).location;
      }
      if (id.toLowerCase().indexOf('location') >= 0) {
        text = (filter.params || {}).location;
      }
      if (id.toLowerCase().indexOf('jobposition') >= 0) {
        text = (filter.params || {}).jobPosition;
      }
      if (id.toLowerCase().indexOf('skill') >= 0) {
        text = (filter.params || {}).skillId;
      }
      if (id.toLowerCase().indexOf('experienceyearrange') >= 0) {
        text = (filter.params || {}).min + '-' + (filter.params || {}).max;
      }
      if (text) {
        return { color, text };
      }
    }),
  );

  if (_.isEmpty(tags)) {
    return <div style={{ height: 7 }} />;
  }

  return (
    <div>
      {_.map(tags, (tag, index) => (
        <Label key={index} color={tag.color}>
          {tag.text}
        </Label>
      ))}
    </div>
  );
};

const EditModal = ({ description, onChangeEdit, onCancel, onSubmit }) => (
  <Modal active={true} headerText="Edit description" onCancel={onCancel} onSubmit={onSubmit}>
    <SweetForm onChange={onChangeEdit}>
      <Form>
        <Form.Field>
          <Textarea autoFocus field="description" defaultValue={description} />
        </Form.Field>
      </Form>
    </SweetForm>
  </Modal>
);
class EditInputTitleModal extends Component {
  state = { title: this.props.initialTitle };
  handleChangeEdit = (newValue) => {
    this.setState(newValue);
  };
  render() {
    const { onCancel, initialTitle, onSubmit } = this.props;
    return (
      <Modal
        active={true}
        headerText="Edit input title"
        onCancel={onCancel}
        onSubmit={() => onSubmit(this.state.title)}
      >
        <SweetForm onChange={this.handleChangeEdit}>
          <Form>
            <Form.Field>
              <Input autoFocus field="title" defaultValue={initialTitle || ''} />
            </Form.Field>
          </Form>
        </SweetForm>
      </Modal>
    );
  }
}

const WATCH_SEARCH_INITIAL_VALUES = {
  copyable: true,
  filters: [
    {
      selection: 'magicFilterWatch',
      magicFilter: { relaxation: 'extended' },
    },
  ],
  scorer: {
    selection: 'watchSearch',
  },
}

class SearchRefresher extends Component {
  state = {};
  handleClick = () => {
    if (this.state.clicked) {
      return;
    }
    this.setState({ clicked: true });
    const url = baseUrl + '/sweetsearch/searches/id/' + this.props.search.id + '/refresh';
    axios.get(url);
  };
  render() {
    const { search } = this.props;

    let lastRefreshTimestamp = search.lastSuccessTimestamp || null;

    // deprecated
    _.each(search.events, (event) => {
      if (event.type === 'search-successful' && event.timestamp) {
        if (!lastRefreshTimestamp || event.timestamp > lastRefreshTimestamp) {
          lastRefreshTimestamp = event.timestamp;
        }
      }
    });

    // deprecated
    let lastRefreshOrderTimestamp = search.lastRefreshOrderTimestamp || null;
    _.each(search.events, (event) => {
      if ((event.type === 'force-refresh-order-sent' || event.type === 'creation-order-sent') && event.timestamp) {
        if (!lastRefreshOrderTimestamp || event.timestamp > lastRefreshOrderTimestamp) {
          lastRefreshOrderTimestamp = event.timestamp;
        }
      }
    });

    const lastRefreshDateStr = lastRefreshTimestamp ? moment(new Date(lastRefreshTimestamp)).fromNow() : 'never';
    const lastRefreshOrderDateStr = lastRefreshOrderTimestamp
      ? moment(new Date(lastRefreshOrderTimestamp)).fromNow()
      : 'never';

    const nbDays = lastRefreshTimestamp ? Math.ceil((Date.now() - lastRefreshTimestamp) / (24 * 3600 * 1000)) : 100;

    const color =
      nbDays <= 1 ? 'green' : nbDays <= 3 ? 'olive' : nbDays <= 5 ? 'yellow' : nbDays <= 10 ? 'orange' : 'red';

    return (
      <Button animated color={color} size="mini" onClick={this.handleClick}>
        <Button.Content visible>
          <Icon name="refresh" />
          {lastRefreshDateStr}
        </Button.Content>
        <Button.Content hidden>Asked {lastRefreshOrderDateStr}</Button.Content>
      </Button>
      // <Button.Group size="mini">
      //  <Button color={color} size="mini" onClick={this.handleClick}>
      //       <Icon name="refresh" />
      //       {this.state.clicked ? 'launched' : lastRefreshDateStr}
      //     </Button>
      //     <Button color="grey" size="mini">
      //       {lastRefreshOrderDateStr}
      //     </Button>
      // </Button.Group>
    );
  }
}

class WorkPipe extends Component {
  componentWillMount() {
    const { match, offer, workPipe, onLoad, onLoadOffer } = this.props;
    const { id, wid } = match.params;

    onLoad(wid, workPipe);

    if (!offer) {
      onLoadOffer(id);
    }
  }

  render() {
    const {
      history,
      username,
      offer,
      workPipe,
      match,
      editMode,
      edit,
      onAdd,
      getSearchFilteringEstimation,
      onAddSubmit,
      onAddEmpty,
      onAddEmptySubmit,
      onChangeEdit,
      onDetails,
      onCancel,
      onEdit,
      onDelete,
      onDeleteConfirm,
      onDeleteInput,
      onDeleteInputConfirm,
      onSetInputAsDefaultForAutoselect,
      onEditInputTitle,
      onUpdateInputTitle,
      onSetUploadRule,
      onSetUploadAuto,
      onSetDescription,
      onToggleFavorite,
      onMakeABCDE,
      onAddWatchInput,
      onAddWatchInputSubmit
    } = this.props;

    if (!workPipe) {
      return null;
    }

    const onDeleteConfirmHistory = (id) => {
      onDeleteConfirm(id).then(() => history.replace(`/offers/${offer.id}`));
    };

    const { searchInputs, searchV3Inputs, customInputs } = _.reduce(
      workPipe.inputs,
      (memo, input) =>
        input.searchParam && input.pipeDescriptor && input.pipeDescriptor.type === 'search-v3'
          ? {
              searchV3Inputs: [...memo.searchV3Inputs, input],
              searchInputs: [...memo.searchInputs],
              customInputs: [...memo.customInputs],
            }
          : input.searchParam
          ? {
              searchV3Inputs: [...memo.searchV3Inputs],
              searchInputs: [...memo.searchInputs, input],
              customInputs: [...memo.customInputs],
            }
          : {
              searchV3Inputs: [...memo.searchV3Inputs],
              searchInputs: [...memo.searchInputs],
              customInputs: [...memo.customInputs, input],
            },
      { searchV3Inputs: [], searchInputs: [], customInputs: [] },
    );

    const sweetsheetUrl = offer && offer.sweetsheetId ? `/flowboard/sweetsheets/id/${offer.sweetsheetId}` : '';

    return (
      <div>
        <Grid>
          <Grid.Column width={6}>
            <SweetForm onChange={onChangeEdit}>
              <Form>
                <Form.Field>
                  <label>Upload rule</label>
                  <Input
                    size="mini"
                    field="uploadRule"
                    action={
                      <Button size="small" color="green" onClick={() => onSetUploadRule(workPipe, edit.uploadRule)}>
                        Save
                      </Button>
                    }
                    defaultValue={workPipe.uploadRule}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="Upload auto on select"
                  // field='uploadAuto'
                    checked={workPipe.uploadAuto ? true : false}
                    onChange={() => onSetUploadAuto(workPipe, workPipe.uploadAuto ? false : true)}
                  />
                </Form.Field>
              </Form>
            </SweetForm>

            <div style={{ marginTop: 20 }}>
              <Link to={`${match.url}/items`}>
                <Icon name="eye" /> See items
              </Link>
            </div>
            {sweetsheetUrl && (
              <div style={{ marginTop: 10 }}>
                <Link to={sweetsheetUrl}>
                  <Icon name="address book" /> Sweetsheet
                </Link>
              </div>
            )}
            <Header as="h1">Inputs</Header>
            <Header as="h4">Search V3</Header>
            <List relaxed>
              {_.map(searchV3Inputs, (input) => (
                <List.Item key={input.id}>
                  <List.Content floated="left" style={{ height: 40, paddingTop: 3 }}>
                    {input.search && <SearchRefresher search={input.search} />}
                    <Button.Group size="mini">
                      <Button icon="remove" color="red" onClick={() => onDeleteInput(input)} />
                      <Button icon="code" onClick={() => onDetails(input)} />
                      {input.formParam && <Button icon="copy" color="black" onClick={() => onAdd(input.formParam)} />}
                    </Button.Group>
                    <Popup
                      content={'set this input as default for auto select'}
                      trigger={
                        <Icon
                          name="star"
                                color={input.isDefaultAutoselectInput ? 'yellow' : 'grey'}
                          onClick={() => onSetInputAsDefaultForAutoselect(workPipe, input.id)}
                        />
                      }
                    />
                  </List.Content>
                  <List.Content>
                    <div>
                      <InputTags input={input} />
                    </div>
                    {/*<Link to={`${match.url}/${input.id}`}>*/}
                    <Link to={`/sweetwork/${(offer || {}).id}/workpipe/${(workPipe || {}).id}/${(input || {}).id}`}>
                      {input.title}
                      {input.nbProfilesAfterFiltering ? ' (' + input.nbProfilesAfterFiltering + ')' : ''}
                    </Link>
                    <Icon name="pencil" link onClick={() => onEditInputTitle(input)} />
                    <Popup
                      trigger={
                        <Link to={`/sweetwork/${(offer || {}).id}/workpipe/${(workPipe || {}).id}/${(input || {}).id}`}>
                          <Icon name="lightning" /> 
                        </Link>
                      }
                      content="Try the SweetWork V4"
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
            {!_.isEmpty(searchInputs) && <Header as="h4">Search</Header>}
            <List relaxed>
              {_.map(searchInputs, (input) => (
                <List.Item key={input.id}>
                  <List.Content floated="left">
                    <Button.Group size="mini">
                      <Button icon="remove" color="red" onClick={() => onDeleteInput(input)} />
                      <Button icon="code" onClick={() => onDetails(input)} />
                    </Button.Group>
                  </List.Content>
                  <List.Content>
                    <Link to={`${match.url}/${input.id}`}>
                      {input.title}
                      {input.nbProfilesAfterFiltering ? ' (' + input.nbProfilesAfterFiltering + ')' : ''}
                    </Link>
                  </List.Content>
                </List.Item>
              ))}
            </List>
            <Header as="h4">Custom</Header>
            <List relaxed>
              {_.map(customInputs, (input, index) => (
                <List.Item key={index}>
                  <List.Content floated="left">
                    <Button.Group size="mini">
                      <Button icon="remove" color="red" onClick={() => onDeleteInput(input)} />
                      <Button icon="code" onClick={() => onDetails(input)} />
                    </Button.Group>
                  </List.Content>
                  <List.Content>
                    {/*<Link to={`${match.url}/${input.id}`}>*/}
                    <Link to={`/sweetwork/${(offer || {}).id}/workpipe/${(workPipe || {}).id}/${(input || {}).id}`}>
                      {input.title}
                      {input.nbProfilesAfterFiltering ? ' (' + input.nbProfilesAfterFiltering + ')' : ''}
                    </Link>
                    <Popup
                      trigger={
                        <Link to={`/sweetwork/${(offer || {}).id}/workpipe/${(workPipe || {}).id}/${(input || {}).id}`}>
                          <Icon name="lightning" /> 
                        </Link>
                      }
                      content="Try the SweetWork V4"
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
            <Button color="green" onClick={() => onAdd(WATCH_SEARCH_INITIAL_VALUES)}>
              Add Watch
            </Button>
            <Button color="grey" onClick={onAddEmpty}>
              Add Empty
            </Button>
            <Button color="grey" onClick={onAddWatchInput}>
              Add Watch Input (old)
            </Button>
            <Button color="gray" onClick={onAdd}>
              Add Lead Gen (old)
            </Button>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header as="h1">WorkPipe "{workPipe.title}"</Header>
            <Segment size="huge">
              <Icon name="pencil" link onClick={onEdit} />
              {workPipe.description || '(no description)'}
            </Segment>
            {workPipe.description && workPipe.description.indexOf('#dataset') >= 0 && (
              <Button onClick={() => onMakeABCDE(workPipe)}>Make ABCDE DataSet</Button>
            )}
            <Segment secondary>
              <Header as="h4">Log</Header>
              {!workPipe.actions || workPipe.actions.length === 0 ? <p>(no data)</p> : null}

              <List>
                {_.map(workPipe.actions, (action, i) => {
                  const { state, profile } = action.body;
                  return (
                    <List.Item key={i}>
                      <List.Content>
                        <strong>
                          <Icon name={state === 'selected' ? 'smile' : state === 'backlog' ? 'meh' : 'frown'} />
                    &nbsp;{action.username} {state} {profile.fullname}
                        </strong>
                    &nbsp;
                    <span title={moment(action.date).format('L LTS')}>{moment(action.date).fromNow()}</span>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Button color="red" onClick={() => onDelete()} size="mini" floated="right">
              Delete
            </Button>
            <Icon
              link
              name={workPipe.favorite ? 'star' : 'empty star'}
              size="large"
              onClick={() => onToggleFavorite(workPipe)}
            />
            <div style={{ clear: 'right' }} />
            <Segment>
              <Header as="h3">Weekly stats</Header>
              {workPipe.stats ? <WorkPipeStats stats={workPipe.stats.weekly} /> : <p>...</p>}
              <Header as="h3">Overall stats</Header>
              {workPipe.stats ? <WorkPipeStats stats={workPipe.stats.overall} /> : <p>...</p>}
            </Segment>
          </Grid.Column>
        </Grid>
        {editMode === 'editInputTitle' && edit && (
          <EditInputTitleModal
            onCancel={onCancel}
            initialTitle={edit.title || ''}
            onSubmit={(title) => onUpdateInputTitle(workPipe, edit.id, title)}
          />
        )}
        {editMode === 'add' && (
          <SearchModal
            onCancel={onCancel}
            onChangeEdit={onChangeEdit}
            username={username}
            initialValues={edit && edit.copyable ? edit : null}
            workPipe={workPipe}
            offer={offer}
            onSubmit={() => onAddSubmit(offer, workPipe, edit)}
            getSearchFilteringEstimation={() => getSearchFilteringEstimation(offer, workPipe, edit)}
          />
        )}
        {editMode === 'addEmpty' ? (
          <AddEmptyModal
            onCancel={onCancel}
            onChangeEdit={onChangeEdit}
            onSubmit={() => onAddEmptySubmit(workPipe, edit)}
          />
        ) : null}
        {editMode === 'addWatchInput' ? (
          <AddEmptyModal
            onCancel={onCancel}
            onChangeEdit={onChangeEdit}
            onSubmit={() => onAddWatchInputSubmit(offer, workPipe, edit)}
          />
        ) : null}
        {editMode === 'edit' ? (
          <EditModal
            description={workPipe.description}
            onCancel={onCancel}
            onChangeEdit={onChangeEdit}
            onSubmit={() => onSetDescription(workPipe, edit.description)}
          />
        ) : null}
        {editMode === 'delete' ? (
          <Modal
            active={true}
            headerText={`Delete WorkPipe "${workPipe.title}"`}
            submitText="Confirm"
            onCancel={onCancel}
            onSubmit={() => onDeleteConfirmHistory(workPipe.id)}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </Modal>
        ) : null}
        {editMode === 'deleteInput' ? (
          <Modal
            active={true}
            headerText={`Delete input "${edit ? edit.title : ''}"`}
            submitText="Confirm"
            onCancel={onCancel}
            onSubmit={() => onDeleteInputConfirm(workPipe, edit.id)}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </Modal>
        ) : null}
        {editMode === 'details' ? (
          <Modal
            active={true}
            headerText={`SearchParam of "${edit ? edit.title : ''}"`}
            submitText="Close"
            cancelText=""
            onCancel={onCancel}
            onSubmit={onCancel}
          >
            {edit && edit.formParam && (
              <div>
                <h1>Search V3 params</h1>
                <pre>{JSON.stringify(edit.formParam, null, '  ')}</pre>
              </div>
            )}
            {edit && edit.searchParam && (
              <div>
                <h1>Do not copy this</h1>
                <pre>{JSON.stringify(edit ? edit.searchParam : {}, null, '  ')}</pre>
              </div>
            )}
          </Modal>
        ) : null}
        {editMode === 'loading' ? (
          <Dimmer active={true} page>
            Creating search pipe...
          </Dimmer>
        ) : null}
      </div>
    );
  }
}

const mapSWorkPipe = (state) => ({
  offer: state.selectedOffer,
  workPipe: state.selectedWorkPipe,
  editMode: state.editMode,
  edit: state.edit,
});

const mapDWorkPipe = (dispatch) => ({
  onLoad: async (id, workPipe) => {
    // Load workPipe data if needed
    if (!workPipe || workPipe.id !== id) {
      dispatch(actions.setSelectedWorkPipe(null));
      workPipe = (await axios.get(`${baseUrl}/sweetwork/workPipes/${id}`)).data;
      dispatch(actions.setSelectedWorkPipe(workPipe));
    }

    const targetActions = (await axios.get(`${baseUrl}/actions?workPipeId=${id}`)).data;
    const weekly = (await axios.get(`${baseUrl}/actions/stats?workPipeId=${id}&period=weekly`)).data;
    const overall = (await axios.get(`${baseUrl}/actions/stats?workPipeId=${id}&period=overall`)).data;

    // Load workPipe stats and actions in every load
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        actions: targetActions,
        stats: {
          weekly,
          overall,
        },
      }),
    );
  },
  onLoadOffer: async (offerId) => {
    const offer = (await axios.get(`${baseUrl}/offers/${offerId}`)).data;
    dispatch(actions.setSelectedOffer(offer));
  },
  onDetails: (input) => {
    dispatch(actions.setEditMode('details'));
    dispatch(actions.setEdit(input));
  },
  onChangeEdit: (e) => dispatch(actions.setEdit(e)),
  onCancel: () => dispatch(actions.setEditMode('none')),
  onAdd: (formParam) => {
    if (formParam) {
      dispatch(actions.setEditModeAndEdit('add', formParam));
    } else {
      dispatch(actions.setEditMode('add'));
    }
  },
  onAddEmpty: () => dispatch(actions.setEditMode('addEmpty')),
  onAddEmptySubmit: async (workPipe, input) => {
    // Lock background process
    dispatch(actions.setEditMode('loading'));

    // Build Search Pipe
    const baseId = `${workPipe.id}_${input.title}`.toLowerCase().replace(/\s/g, '_');

    const pipeDescriptor = {
      type: 'raw',
      id: baseId,
    };

    // Build input
    const itemFilter = {
      ...((input.title || '').indexOf('nofilter') >= 0 && {
        noSolicitationFilter: true,
      }),
      excludedPipeDescriptors: [{ type: 'workpipe', id: workPipe.id }],
    };

    const fullInput = {
      id: uuid(),
      title: input.title,
      itemFilter,
      pipeDescriptor,
    };

    await axios.post(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/input`, fullInput);
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        inputs: [...(workPipe.inputs || []), fullInput],
      }),
    );
    dispatch(actions.setEditMode('none'));
  },
  onAddWatchInput: () => dispatch(actions.setEditMode('addWatchInput')),
  onAddWatchInputSubmit: async (offer, workPipe, input) => {
    // Lock background process
    dispatch(actions.setEditMode('loading'));

    // Build Search Pipe
    const baseId = `${workPipe.id}_${input.title}`.toLowerCase().replace(/\s/g, '_');

    const pipeDescriptor = {
      type: 'watch',
      offerId: offer.id
    };

    // Build input
    const itemFilter = {
      ...((input.title || '').indexOf('nofilter') >= 0 && {
        noSolicitationFilter: true,
      }),
      excludedPipeDescriptors: [{ type: 'workpipe', id: workPipe.id }],
    };

    const fullInput = {
      id: uuid(),
      title: input.title,
      itemFilter,
      pipeDescriptor,
    };

    await axios.post(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/input`, fullInput);
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        inputs: [...(workPipe.inputs || []), fullInput],
      }),
    );
    dispatch(actions.setEditMode('none'));
  },
  onMakeABCDE: async (workPipe) => {
    await axios.post(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/actionsSetDescriptor`, {
      isDataset: true,
      actionsSetDescriptor: {
        type: 'labeling',
        actions: [
          {
            backgroundColor: 'green',
            name: 'A',
            targetState: 'A',
            icon: 'check',
          },
          {
            backgroundColor: 'olive',
            name: 'B',
            targetState: 'B',
          },
          {
            backgroundColor: 'yellow',
            name: 'C',
            targetState: 'C',
          },
          {
            backgroundColor: 'orange',
            name: 'D',
            targetState: 'D',
          },
          {
            backgroundColor: 'red',
            name: 'E',
            targetState: 'E',
          },
        ],
      },
    });
    alert('done');
  },

  onAddSubmit: async (offer, workPipe, input) => {
    // Lock background process

    dispatch(actions.setEditMode('loading'));

    // Build Search Pipe
    const baseId = `${workPipe.id}_${input.title}`.toLowerCase().replace(/\s/g, '_');

    const searchPipe = {
      searchParams: {
        baseId,
        offer: {
          location: offer.location,
          offerId: offer.id,
          requiredSkills: offer.skills.required,
          importantSkills: offer.skills.important,
          bonusSkills: offer.skills.bonus,
          topSchool: offer.topSchool,
          workPipeId: workPipe.id,
          experience: offer.experience,
          jobId: offer.jobId,
        },
        biases: [],
        ..._.omit(formScorerToQuery(input), 'title'),
      },
    };

    const pipeDescriptor = (await axios.post(`${baseUrl}/sweetwork/searchPipeV3`, searchPipe)).data;

    // Build input
    const itemFilter = {
      ...((input.title || '').indexOf('nofilter') >= 0 && {
        noSolicitationFilter: true,
      }),
      excludedPipeDescriptors: [{ type: 'workpipe', id: workPipe.id }],
    };

    const fullInput = {
      id: uuid(),
      title: input.title,
      itemFilter,
      pipeDescriptor,
      searchParam: searchPipe,
      formParam: {
        ...input,
        copyable: true,
      },
    };

    await axios.post(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/input`, fullInput);
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        inputs: [...(workPipe.inputs || []), fullInput],
      }),
    );

    dispatch(actions.setEditMode('none'));
  },
  onEdit: () => dispatch(actions.setEditMode('edit')),
  onDelete: () => dispatch(actions.setEditMode('delete')),
  onDeleteConfirm: async (id) => {
    await axios.delete(`${baseUrl}/sweetwork/workPipes/${id}`);
    dispatch(actions.setEditMode('none'));
    dispatch(actions.setSelectedInput(null));
    dispatch(actions.setSelectedWorkPipe(null));
  },
  onDeleteInput: (input) => {
    dispatch(actions.setEditMode('deleteInput'));
    dispatch(actions.setEdit(input));
  },
  onSetInputAsDefaultForAutoselect: async (workPipe, inputId) => {
    await axios.put(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/input/${inputId}/defaultAutoselect`);
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        inputs: _.map(workPipe.inputs, (input) =>
          input.id !== inputId
            ? {
                ...input,
                isDefaultAutoselectInput: false,
              }
            : {
                ...input,
                isDefaultAutoselectInput: !input.isDefaultAutoselectInput,
              },
        ),
      }),
    );
  },
  onDeleteInputConfirm: async (workPipe, inputId) => {
    await axios.delete(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/input/${inputId}`);
    dispatch(actions.setEditMode('none'));
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        inputs: _.filter(workPipe.inputs, (input) => input.id !== inputId),
      }),
    );
  },
  onEditInputTitle: (input) => {
    dispatch(actions.setEditMode('editInputTitle'));
    dispatch(actions.setEdit(input));
  },
  onUpdateInputTitle: async (workPipe, inputId, newTitle) => {
    const updatedInput = (await axios.put(`${baseUrl}/sweetwork/workPipes/${workPipe.id}/input/${inputId}/title`, {
      title: newTitle,
    })).data;
    if (!updatedInput) {
      return alert('error while updating input');
    }
    if (updatedInput && updatedInput.error) {
      return alert(updatedInput.error);
    }
    dispatch(actions.setEditMode('none'));
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        inputs: _.map(workPipe.inputs, (input) => (input.id !== inputId ? input : updatedInput)),
      }),
    );
  },
  onSetUploadAuto: async (workPipe, uploadAuto) => {
    await axios.put(`${baseUrl}/sweetwork/workPipes/${workPipe.id}`, {
      uploadAuto,
    });
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        uploadAuto,
      }),
    );
  },
  onSetUploadRule: async (workPipe, uploadRule) => {
    await axios.put(`${baseUrl}/sweetwork/workPipes/${workPipe.id}`, {
      uploadRule,
    });
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        uploadRule,
      }),
    );
  },
  onSetDescription: async (workPipe, description) => {
    await axios.put(`${baseUrl}/sweetwork/workPipes/${workPipe.id}`, {
      description,
    });
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        description,
      }),
    );
    dispatch(actions.setEditMode('none'));
  },
  onToggleFavorite: async (workPipe) => {
    const method = workPipe.favorite ? axios.delete : axios.post;
    dispatch(
      actions.setSelectedWorkPipe({
        ...workPipe,
        favorite: !workPipe.favorite,
      }),
    );
    return method(`${baseUrl}/favorites/workPipes/${workPipe.id}`);
  },
});

export default connect(
  mapSWorkPipe,
  mapDWorkPipe,
)(WorkPipe);
