import myriamData from './myriam';
import elsaData from './elsa';
import gabrielData from './gabriel';
import hamzaData from './hamza';
import teddyData from './teddy';

const blurbExamples = [
  {
    id: "teddy - 2 xp",
    data: teddyData
  }
]

export default blurbExamples;