import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Route } from 'react-router';

import baseUrl from '../baseUrl';

import { actionCreators } from './redux';
import RegressionFailureDetail from './RegressionFailureDetail.component';
import RegressionsList from './RegressionsList.component';
import RegressionsSelection from './RegressionsSelection.component';

export const routes = [
  {
    id: 'regressions:failure',
    exact: true,
    path: '/monitoring/scrap/regressions/detail/:failureId',
    component: RegressionFailureDetail,
    onLoad: async (match, dispatch) => {
      const failure = await axios.get(
        `${baseUrl}/regressions/failures/${match.params.failureId}`,
      );
      dispatch(actionCreators.setScrapFailure(failure.data));
    },
  },
  {
    id: 'regressions:workers',
    path: '/monitoring/scrap/regressions',
    component: RegressionsSelection,
    onLoad: async (match, dispatch) => {
      let { date } = match.params;
      date = date || moment().format('YY-MM-DD');
      dispatch(actionCreators.selectDate(date));
      const usersForDate = await axios.get(
        `${baseUrl}/regressions/failures/users/list/${date}`,
      );
      dispatch(actionCreators.loadUsersForDates(usersForDate.data));
    },
    routes: [
      {
        id: 'regressions:workers:failures',
        exact: true,
        path: '/monitoring/scrap/regressions/:date/:workerId',
        component: RegressionsList,
        onLoad: async (match, dispatch) => {
          const { date, workerId } = match.params;
          dispatch(actionCreators.selectDate(date));
          dispatch(actionCreators.selectWorker(workerId));
          const failuresForDay = await axios.get(
            `${baseUrl}/regressions/failures/users/${workerId}/${date}`,
          );
          dispatch(actionCreators.setFailuresForDay(failuresForDay.data));
        },
      },
    ],
  },
];

const ROUTES = new Map();
(function registerRoutes(routes) {
  for (const route of routes) {
    if (ROUTES.has(route.id)) {
      throw new Error(`Route with id ${route.id} already exists`);
    }
    ROUTES.set(route.id, route);
    if (route.routes) {
      registerRoutes(route.routes);
    }
  }
})(routes);

export function url(routeId, args) {
  if (!ROUTES.has(routeId)) {
    throw new Error(`No route with id ${routeId}`);
  }

  const route = ROUTES.get(routeId);

  return route.path.replace(/(?::([^/]+))/g, (_, paramName) => {
    if (!args[paramName]) {
      throw new Error(`No arg value for key ${paramName}`);
    }
    return args[paramName];
  });
}
export function buildRoutes(routes) {
  const Routes = routes.map((route) => {
    return (
      <Route
        key={route.id}
        exact={route.exact}
        path={route.path}
        render={(props) => {
          return (
            <route.component
              {...props}
              onLoad={route.onLoad}
              routes={route.routes}
            />
          );
        }}
      />
    );
  });
  return Routes;
}
