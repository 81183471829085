import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { Segment, Form, Message, Search } from 'semantic-ui-react';

export class WorkplacePatternsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parseOk: true,
      patternsFound: false,
      loadingDelete: false,
      loadingSubmit: false,
      sourceId: 'linkedin',
      searchLoading: false,
    };

    this.handleIDChange = this.handleIDChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.flushPatterns = this.flushPatterns.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSourceIDChange = this.handleSourceIDChange.bind(this);
    this.handlePatternsChange = this.handlePatternsChange.bind(this);
  }

  async handleIDChange(e) {
    await this.setState({ id: e.target.value });
    this.refreshPatterns();
    this.clearMessage();
  }

  async handleSourceIDChange(e, { value }) {
    await this.setState({ sourceId: value });
    this.refreshPatterns();
    this.clearMessage();
  }

  handlePatternsChange(e) {
    this.clearMessage();
    try {
      const patterns = JSON.parse(e.target.value);
      this.setState({
        parseOk: true,
        newPatternsString: e.target.value,
        lastGoodPatterns: patterns,
      });
    } catch (error) {
      this.setState({ parseOk: false, newPatternsString: e.target.value });
    }
  }

  async flushPatterns() {
    await this.setState({ newPatternsString: '[]' });
  }

  clearMessage() {
    this.setState({ message: '' });
  }

  async handleSubmit() {
    this.clearMessage();
    await this.setState({ loadingSubmit: true });
    try {
      await axios.put(`${baseUrl}/workplacePatterns/patterns`, {
        id: this.state.id,
        sourceId: this.state.sourceId,
        patterns: this.state.lastGoodPatterns,
      });
    } catch (e) {
      this.setState({ message: 'Failed to add patterns: ' + e.response.data });
    }
    this.setState({ loadingSubmit: false });
    this.refreshPatterns();
  }

  async handleDelete() {
    this.clearMessage();
    await this.setState({ loadingDelete: true });
    try {
      await axios.delete(
        `${baseUrl}/workplacePatterns/patterns?id=${this.state.id}&sourceId=${
        this.state.sourceId
        }`,
      );
    } catch (e) {
      this.setState({ message: 'Failed to delete: ' + e.response.data });
    }
    this.setState({ loadingDelete: false });
    this.refreshPatterns();
  }

  async onCompanySelect(e, result) {
    await this.setState({
      id: result.result.description,
      searchValue: result.result.title,
    });
    this.refreshPatterns();
  }

  async handleSearchChange(e) {
    const value = e.target.value;
    await this.setState({ searchValue: value });
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    await this.setState({
      searchTimeout: setTimeout(async () => await this.updateSearch(), 100),
    });
  }

  async updateSearch() {
    const response = await axios.get(
      `${baseUrl}/workplacePatterns/companies?sourceId=${
      this.state.sourceId
      }&name=${this.state.searchValue}`,
    );
    this.setState({
      searchResults: _.map(response.data, (c) => ({
        title: c.name,
        description: c.id,
      })),
    });
  }

  async refreshPatterns() {
    await this.flushPatterns();
    try {
      const response = await axios.get(
        `${baseUrl}/workplacePatterns/patterns?sourceId=${
        this.state.sourceId
        }&id=${this.state.id}`,
      );

      this.setState({
        patternsFound: true,
        patterns: response.data.patterns,
        newPatternsString: JSON.stringify(response.data.patterns),
        lastGoodPatterns: response.data.patterns,
      });
    } catch (e) {
      this.setState({ patternsFound: false, parseOk: true });
    }
  }

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Source</label>
              <Form.Select
                placeholder="Source ID"
                options={[
                  { key: 'linkedin', text: 'LinkedIn', value: 'linkedin' },
                  { key: 'hiresweet', text: 'Hiresweet', value: 'hiresweet' },
                ]}
                onChange={this.handleSourceIDChange}
                value={this.state.sourceId}
              />
            </Form.Field>
            <Form.Field>
              <label>Search for a company name</label>
              <Search
                selectFirstResult={true}
                onResultSelect={this.onCompanySelect}
                onSearchChange={this.handleSearchChange}
                results={this.state.searchResults}
                value={this.state.searchValue}
              />
            </Form.Field>
            <Form.Field>
              <label>Company ID</label>
              <Form.Input
                onChange={this.handleIDChange}
                placeholder="Company ID"
                value={this.state.id}
              />
            </Form.Field>
          </Form.Group>
          <Form.TextArea
            value={this.state.newPatternsString}
            onChange={this.handlePatternsChange}
          />
          {!this.state.parseOk && <Message>Invalid JSON</Message>}
          {this.state.message && <Message>{this.state.message}</Message>}
          <Form.Group>
            <Form.Button
              color={this.state.patternsFound ? 'blue' : 'green'}
              loading={this.state.loadingSubmit}
              disabled={!this.state.parseOk}
              onClick={this.handleSubmit}
            >
              {this.state.patternsFound ? 'Edit' : 'Add new pattern'}
            </Form.Button>
            <Form.Button
              color="red"
              loading={this.state.loadingDelete}
              onClick={this.handleDelete}
              disabled={!this.state.patternsFound}
            >
              Delete record
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
