import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Grid,
  Table,
  Form,
  Popup,
  Icon,
  Checkbox as SCheckbox,
  Modal as SModal,
  Input as SInput,
  Button,
  Header,
} from 'semantic-ui-react';
import { SweetForm, Select, Input, Checkbox } from 'sweetform';

import SweetappProfile from '../components/SweetappProfile';
import OfferCriteriaDiff from '../components/OfferCriteriaDiff';
import TemplateDiff from '../components/TemplateDiff';
import baseUrl from '../baseUrl';
import Modal from '../Modal';
import Pagination from '../Pagination';

const PER_PAGE = 100;

// Actions

const SET_ACTIONS = 'SET_ACTIONS';
const setActions = (actions, page) => ({ type: SET_ACTIONS, actions, page });

const SET_ACTIONS_FILTER_OPTIONS = 'SET_ACTIONS_FILTER_OPTIONS';
const setActionsFilterOptions = (options) => ({
  type: SET_ACTIONS_FILTER_OPTIONS,
  options,
});

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_MODAL_MODE = 'SET_MODAL_MODE';
const setModalMode = (mode, initial = {}) => ({
  type: SET_MODAL_MODE,
  mode,
  initial,
});

// Reducers

const actions = (state = [], action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return action.actions;
    default:
      return state;
  }
};

const page = (state = 0, action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return action.page || 0;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const modalMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.mode;
    default:
      return state;
  }
};

const selectedProfile = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.initial;
    default:
      return state;
  }
};

const selectedAction = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.initial;
    default:
      return state;
  }
};

const actionsOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIONS_FILTER_OPTIONS:
      return action.options;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  actions,
  page,
  search,
  modalMode,
  selectedProfile,
  selectedAction,
  actionsOptions,
});

// Store
const store = createStore(rootReducer);

// Components

const getFormattedDate = (date) => {
  const dateObj = new Date(date);
  return date && dateObj
    ? dateObj.toDateString() + ' - ' + dateObj.toLocaleTimeString()
    : null;
};

const typeStyles = {
  send: {
    color: 'blue',
    fontWeight: 'bold',
  },
  'send-discover-profile': {
    color: 'darkviolet',
    fontWeight: 'bold'
  },
  sendError: {
    color: 'red',
    fontWeight: 'bold',
  },
  'new-offer': {
    color: 'lime',
    fontWeight: 'bold',
  },
  'move-to-pending': {
    color: 'purple',
    fontWeight: 'bold',
  },
  'move-to-contacted': {
    color: 'blue',
    fontWeight: 'bold',
  },
  'move-to-hired': {
    color: 'orange',
    fontWeight: 'bold',
  },
  'move-to-process': {
    color: 'green',
    fontWeight: 'bold',
  },
  'move-to-milestone-1': {
    color: 'green',
    fontWeight: 'bold',
  },
  'move-to-milestone-2': {
    color: 'green',
    fontWeight: 'bold',
  },
  'move-to-milestone-3': {
    color: 'green',
    fontWeight: 'bold',
  },
  'move-to-milestone-4': {
    color: 'green',
    fontWeight: 'bold',
  },
  'send-follow-up': {
    color: 'cyan',
    fontWeight: 'bold',
  },
  'rate-selection': {
    color: 'purple',
    fontWeight: 'bold',
  },
  'blocked-send-follow-up': {
    color: 'gray',
    fontWeight: 'bold',
  },
  edit_criteria: {
    color: 'brown',
    fontWeight: 'bold',
  },
  'answer-detected': {
    color: 'green',
    fontWeight: 'bold',
  },
  disqualifyProfile: {
    color: 'orange',
    fontWeight: 'bold',
  },
  skipProfile: {
    color: 'red',
    fontWeight: 'bold',
  },
  'enable-offer-autopilot': {
    color: 'green',
    fontWeight: 'bold'
  },
  'disable-offer-autopilot': {
    color: 'red',
    fontWeight: 'bold'
  },
  'dismiss-autopilot-invitation-empty-pane': {
    color: 'orange',
  },
  'dismiss-autopilot-invitation-kpi-tip': {
    color: 'orange',
  },
  'update-offer-push-flow': {
    color: 'green',
    fontWeight: 'bold'
  },
  'dismiss-flow-augmentation-invitation-empty-pane': {
    color: 'orange',
  },
  'dismiss-flow-augmentation-invitation-kpi-tip': {
    color: 'orange',
  },
  'new-stripe-credit-card': {
    color: '#CCFF00',
    fontWeight: 'bold'
  },
  "update-billing-plan": {
    color: '#CCFF00',
    fontWeight: 'bold'
  },
  "remove-stripe-credit-card": {
    color: 'orange',
    fontWeight: 'bold'
  },
};

const typeTexts = {
  disqualifyProfile: 'disqualified',
  skipProfile: 'skip',
  'move-to-hired': 'HIRED',
  'select-profile': 'see',
};

const getPlatformBaseUrl = (clientId) =>
  `https://app.hiresweet.com/client/${clientId}`;

const getEmailBodyDiffHtml = (diff) =>
  _.reduce(
    diff,
    (memo, part) =>
      part.added
        ? `${memo} <span style="color:green">${part.value}</span>`
        : part.removed
        ? `${memo} <span style="color:red">${part.value}</span>`
        : `${memo} <span style="color:black">${part.value}</span>`,
    '',
  );

const getDisplayedAuthor = ({ author }) => {
  if (!author) {
    return null;
  }
  if (_.isString(author)) {
    return author;
  }
  if (_.isObject(author)) {
    if (author.firstname && author.lastname) {
      return `${author.firstname} ${author.lastname}`;
    }
    if (author.email) {
      return author.email;
    }
  }
  return null;
}

const Action = ({
  key,
  action,
  onProfileModal,
  onOfferCriteriaModal,
  onTemplateDiffModal,
  onNewSuggestedOffer,
  onMessageModal,
  restrictedUsers,
  sweethubIdFromSweetappId,
}) => {
  const localeDate = getFormattedDate(action.date);
  const { author } = action || {};
  const displayedAuthor = getDisplayedAuthor({ author });
  return (
    <Table.Row key={key}>
      <Table.Cell>
        <span>&nbsp;{localeDate}&nbsp;</span>
      </Table.Cell>
      <Table.Cell>
        <span className="show-offer">
          <a
            target="_blank"
            href={`${getPlatformBaseUrl(action.clientId)}/offers/${
              action.jobOfferId
            }`}
            rel='noopener noreferrer'
          >
            {action.jobOfferId}&nbsp;
          </a>
          {sweethubIdFromSweetappId[action.jobOfferId] && (
            <a
              target="_blank"
              href={`/offers/${sweethubIdFromSweetappId[action.jobOfferId]}`}
              rel='noopener noreferrer'
            >
              <Icon name="external" size="tiny" />
            </a>
          )}
        </span>
        {action.author ? (
          <span>
            {!action.jobOfferId && (
              <a target='_blank' href={`${getPlatformBaseUrl(action.clientId)}`} rel='noopener noreferrer'>
                {action.clientId}
              </a>
            )}
            &nbsp;({displayedAuthor})&nbsp;
          </span>
        ) : action.clientId ? (
          <span>
            {!action.jobOfferId && (
              <a
                target="_blank"
                href={`${getPlatformBaseUrl(action.clientId)}`}
                rel='noopener noreferrer'
              >
                {action.clientId}
              </a>
            )}
          </span>
        ) : (
          ''
        )}
      </Table.Cell>
      <Table.Cell>
        <span style={{ ...typeStyles[action.type] }}>
          {typeTexts[action.type] ? typeTexts[action.type] : action.type}&nbsp;
          {action.reason ? `${action.reason.toLowerCase().slice(0, 32)}` : null}
          {action.error ? (
            <span style={{ fontSize: 15 }}>
              {action.error.toLowerCase().slice(0, 32)}
            </span>
          ) : null}
          {action.senderAddress
            ? ` (${action.senderAddress.slice(0, 32)})`
            : null}
        </span>
        {action.type === 'edit_criteria' && (
          <Icon name="eye" link onClick={() => onOfferCriteriaModal(action)} />
        )}
        {action.type === 'template_edited' && (
          <Icon name='eye' link onClick={() => onTemplateDiffModal(action)} />
        )}
        {action.type === 'new-suggested-offer' && (
          <Icon name='eye' link onClick={() => onNewSuggestedOffer(action)} />
        )}
        {
          (action.error ||
           action.reason ||
           action.target ||
           action.annotation) &&
            (`${action.error || ''} ${action.reason || ''} ${action.target || ''} ${action.annotation || ''}`).length > 32 ? (
              <Popup
                trigger={<Icon name='add' />}
                content={`${action.error || ''} ${action.reason || ''} ${action.target || ''} ${action.annotation || ''}`}
              />
            ) : null
        }
        {action.diff && (
          <Popup
            wide="very"
            trigger={<Icon name="add" />}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: getEmailBodyDiffHtml(action.diff),
                }}
              />
            }
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {action.profile &&
          (action.type !== 'answer-detected' ||
            (restrictedUsers || []).length === 0) && (
            <span className="show-details">
              {/* eslint-disable-next-line */}
              <a
                style={{ cursor: 'pointer' }}
                className="disable-select with-cursor-pointer"
                onClick={() => onProfileModal(action.profile.id)}
              >
                {(restrictedUsers || []).length === 0 ? (
                  <span>
                    {action.profile.firstName} {action.profile.lastName}&nbsp;
                  </span>
                ) : (
                  <Icon name="eye" link />
                )}
              </a>
              {action.linkedin && (
                <a
                  className="disable-select with-cursor-pointer"
                  href={action.linkedin}
                  target="_blank"
                  rel='noopener noreferrer'
                >
                  <Icon name="linkedin" />
                </a>
              )}
              {action.from && action.to && (
                <Popup
                  trigger={<Icon name="add" />}
                  content={`from ${action.from} to ${action.to}`}
                />
              )}
            </span>
          )}
          {!action.profile && action.searchPoolProfileId && (
            <span>{action.searchPoolProfileId}</span>
          )}
          {!action.profile && action.candidateId && (
            <span>{action.candidateId}</span>
          )}
      </Table.Cell>
    </Table.Row>
  );
};

const ActionsList = ({
  actions,
  page,
  onPage,
  onProfileModal,
  onOfferCriteriaModal,
  onTemplateDiffModal,
  onNewSuggestedOffer,
  onMessageModal,
  restrictedUsers,
  sweethubIdFromSweetappId,
}) => (
    <div>
      <div style={{ position: 'relative', height: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Date</Table.HeaderCell>
              <Table.HeaderCell width={4}>OfferId or ClientId/Recruiter</Table.HeaderCell>
              <Table.HeaderCell width={5}>Action</Table.HeaderCell>
              <Table.HeaderCell width={3}>Profile</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              _.map(actions.results, (action, key) => (
                <Action key={key} 
                action={action} 
                onProfileModal={onProfileModal} 
                onOfferCriteriaModal={onOfferCriteriaModal}
                onTemplateDiffModal={onTemplateDiffModal}
                onNewSuggestedOffer={onNewSuggestedOffer}
                onMessageModal={onMessageModal} 
                restrictedUsers={restrictedUsers} 
                sweethubIdFromSweetappId={sweethubIdFromSweetappId}
                />
                ))
              }
          </Table.Body>
        </Table>
      </div>
      <Pagination floated='right' current={page} total={100} onPage={onPage} />
    </div>
  );


class RegexModal extends Component {
  state = { modalOpen: false, search: '' };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = (saveSearch) => {
    if (saveSearch) {
      this.props.searchRegex(this.state.search);
    }
    this.setState({ modalOpen: false });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ search: value });
  };

  render() {
    return (
      <SModal
        trigger={
          <Button size="mini" secondary onClick={this.handleOpen}>
            Regex
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Header icon="search" content="Regex search" />
        <SModal.Content>
          <h3>Entrez un mot à rechercher dans l{"'"}id ou le nom des offres</h3>
          <SInput onChange={this.handleSearchChange} />
        </SModal.Content>
        <SModal.Actions>
          <Button color="red" onClick={() => this.handleClose(false)} inverted>
            <Icon name="cancel" /> cancel
          </Button>
          <Button color="green" onClick={() => this.handleClose(true)} inverted>
            <Icon name="checkmark" /> OK
          </Button>
        </SModal.Actions>
      </SModal>
    );
  }
}

class ActionsSearchForm extends Component {
  state = {};
  componentWillReceiveProps = (props) => {
    const { actionsOptions, search } = props;
    const actionsTypeOptions = _.map(
      actionsOptions.actionsList || ['alpha', 'beta'],
      (a) => ({ value: a, label: a }),
    );
    const clientsOptions = _.map(
      actionsOptions.clientsList,
      ({ id, name }) => ({ value: id, label: `${id} (${name})` }),
    );
    const selectedClients = search.clientIds
      ? search.clientIds.split(';')
      : null;
    const offersFiltered = selectedClients
      ? _.filter(
          actionsOptions.offersList,
          (o) => _.indexOf(selectedClients, o.clientId) > -1,
        )
      : actionsOptions.offersList;
    const offersOptions = _.map(offersFiltered, ({ id, title }) => ({
      value: id,
      label: `${id} (${title})`,
    }));
    this.setState({ actionsTypeOptions, clientsOptions, offersOptions });
  };
  searchRegex = (text) => {
    if (text) {
      const { search, onChange } = this.props;
      const { offersOptions } = this.state;
      const offersSelected = _.reduce(
        offersOptions,
        (memo, { value, label }) =>
          label.indexOf(text) >= 0 ? (memo += ';' + value) : memo,
        '',
      );
      onChange({ ...search, jobOfferIds: offersSelected });
    }
  };
  render() {
    const { onChange, search } = this.props;
    const { actionsTypeOptions, clientsOptions, offersOptions } = this.state;
    return (
      <SweetForm
        onChange={onChange}
        initialValues={search}
        key={JSON.stringify(search).length}
      >
        <Form>
          <Form.Field>
            <label>Types</label>
            <Select
              field="types"
              placeholder="Types"
              options={actionsTypeOptions}
              multi={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Clients</label>
            <Select
              field="clientIds"
              placeholder="Clients"
              options={clientsOptions}
              multi={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Offers</label>
            <Select
              field="jobOfferIds"
              placeholder="Offers"
              options={offersOptions}
              multi={true}
            />
            <RegexModal searchRegex={this.searchRegex} />
            <Button
              size="mini"
              onClick={() => this.searchRegex('hiresweet-watch')}
            >
              /hiresweet-watch/
            </Button>
            <Button size="mini" onClick={() => this.searchRegex('instantflow')}>
              /instantflow/
            </Button>
          </Form.Field>
          <Form.Field>
            <label>Actions comprises entre le</label>
            <Input field="minDate" type="date" placeholder="Date min" />
          </Form.Field>
          <Form.Field>
            <label>et le</label>
            <Input field="maxDate" type="date" placeholder="Date max" />
          </Form.Field>
          <Form.Field>
            <label>WatchOnly</label>
            <Checkbox field="onlyWatch" />
          </Form.Field>
        </Form>
      </SweetForm>
    );
  }
}

const getMoreActions = (users) => {
  if ((users || []).length === 1) {
    return [
      'answer-detected',
      'collaborator_invited',
      'go-to-criteria',
      'edit_criteria',
      'new-offer',
      'archive-offer',
      'stop-offer-sourcing',
      'reactivate-offer-sourcing',
      'disable-offer-autopilot',
      'enable-offer-autopilot',
      'update-offer-push-flow',
      'rate-selection',
      'disqualifyProfile',
      'send',
      'sendError',
      'skipProfile',
      'move-to-contacted',
      'move-to-answered',
      'move-to-contacted',
      'move-to-hired',
      'move-to-pending',
      'move-to-process',
      'move-to-milestone-1',
      'move-to-milestone-2',
      'move-to-milestone-3',
      'move-to-milestone-4',
      'restore-profile',
      'remove-profile',
      'template_unlinked',
      'template_linked',
      'template_edited',
      'template_restored',
      'template_duplicated',
      'template_archived',
      'template_created',
      'copy-email',
      'search-pool-profile-seen',
      'click-profile-source',
    ];
  } else {
    return [
      'click-notification',
      'collaborator_deleted',
      'collaborator_invited',
      'collaborator_signup',
      'comment',
      'copy-email',
      'delete-annotation',
      'save-annotation',
      'disable-follow-up',
      'search-profile-by-text',
      'go-to-criteria',
      'edit_criteria',

      'new-offer',
      'archive-offer',
      'stop-offer-sourcing',
      'reactivate-offer-sourcing',
      'disable-offer-autopilot',
      'enable-offer-autopilot',
      'update-offer-push-flow',
      'dismiss-autopilot-invitation-empty-pane',
      'dismiss-autopilot-invitation-kpi-tip',
      'dismiss-flow-augmentation-invitation-empty-pane',
      'dismiss-flow-augmentation-invitation-kpi-tip',
      'disqualifyProfile',
      'send',
      'sendError',
      'rate-selection',
      'skipProfile',
      'move-to-contacted',
      'move-to-answered',
      'move-to-pending',
      'move-to-contacted',
      'move-to-hired',
      'move-to-process',
      'move-to-milestone-1',
      'move-to-milestone-2',
      'move-to-milestone-3',
      'move-to-milestone-4',
      'restore-profile',
      'remove-profile',
      'search-pool-profile-hidden',
      'search-pool-profile-add-to-job-offer',

      'send-extra-email',

      'expand-profile-education',
      'expand-profile-experience',
      'expand-profile-summary',
      'open-github-repository',
      'website',
      'twitter',
      'github',
      'linkedin',
      'stackoverflow',
      'stackOverflow',

      'template_unlinked',
      'template_linked',
      'template_edited',
      'template_restored',
      'template_duplicated',
      'template_archived',
      'template_created',
      'copy-email',
      'search-pool-profile-seen',
      'click-profile-source',
    ];
  }
};

class Actions extends Component {
  state = {};
  componentWillMount() {
    this.handleLoadSweethubOffermap();
  }

  componentDidMount = async () => {
    const { restrictedUsers } = this.props;
    console.log("onLoadFilterOptions...")
    const offersList = await this.props.onLoadFilterOptions({
      restrictedUsers,
    });
    const jobOfferIds = _.pluck(offersList, 'id').join(';') || 'random';
    const urlSearch = this.getUrlSearch();
    const search = (restrictedUsers || []).length === 0 ? {...urlSearch} : { ...urlSearch, jobOfferIds };

    const moreDevelopedActions = getMoreActions(restrictedUsers);
    const types = moreDevelopedActions.join(';');
    const searchWithRestriction =
      (restrictedUsers || []).length === 0
        ? {
            ...search,
          }
        : {
            ...search,
            types,
          };
    console.log("onLoadActions...")

    await this.props.onLoadActions({ ...searchWithRestriction }, 0);
    console.log("done.")


  };

  getUrlSearch () {
    let urlSearch = {}
    if (this.props.match.params.search){
      try {
        urlSearch = JSON.parse(this.props.match.params.search)
      } catch (e) {
        alert('invalid url search :'+this.props.match.params.search)
      }
    }
    return urlSearch
  }

  handleLoadSweethubOffermap = async () => {
    const offers = (await axios.get(`${baseUrl}/station/offers`)).data;
    const sweethubIdFromSweetappId = {};
    _.each(offers, ({ sweethubId, id }) => {
      if (sweethubId && id) {
        sweethubIdFromSweetappId[id] = sweethubId;
      }
    });
    this.setState({ sweethubIdFromSweetappId });
  };
  render() {
    const {
      actions,
      page,
      search,
      selectedProfile,
      selectedAction,
      modalMode,
      actionsOptions,
      onCancel,
      onLoadActions,
      onProfileModal,
      onOfferCriteriaModal,
      onTemplateDiffModal,
      onNewSuggestedOffer,
      onMessageModal,
      onSearch,
      restrictedUsers,
    } = this.props;
    const moreDevelopedActions = getMoreActions(restrictedUsers);
    const types = moreDevelopedActions.join(';');
    return (
      <div>
        {
          modalMode === 'profile' ?
            (
              <Modal
                active={true}
                headerText='Profile'
                submitText='OK'
                cancelText=''
                onSubmit={onCancel}
                onCancel={onCancel}
              >
                <SweetappProfile profileId={selectedProfile.id} anonymised={(restrictedUsers || []).length > 0}/>
              </Modal>
            ) : null
        }
        {
          modalMode === 'offer-criteria' ?
            (
              <Modal
                active={true}
                headerText='Criteria Edited'
                submitText='OK'
                cancelText=''
                onSubmit={onCancel}
                onCancel={onCancel}
                size='fullscreen'
              >
                <OfferCriteriaDiff {...selectedAction} />
              </Modal>
            ) : null
        }
        {
          modalMode === 'template-diff' ?
            (
              <Modal
                active={true}
                headerText='Template Edited'
                submitText='OK'
                cancelText=''
                onSubmit={onCancel}
                onCancel={onCancel}
                size='fullscreen'
              >
                <TemplateDiff {...selectedAction} />
              </Modal>
            ) : null
        }
        {
          modalMode === 'message' ?
            (
              <Modal
                active={true}
                headerText='Message'
                submitText='OK'
                cancelText=''
                onSubmit={onCancel}
              >
                {
                  _.isString(selectedAction.message) ? (
                    <div>{selectedAction.message}</div>
                  ) : _.isString(selectedAction.message.snippet) ? (
                    <div>{selectedAction.message.snippet}</div>
                  ) : null
                }
              </Modal>
            ) : null
        }
        {
          modalMode === 'new-suggested-offer' ?
            (
              <Modal
                active={true}
                headerText='New Suggested Offer'
                submitText='OK'
                cancelText=''
                onSubmit={onCancel}
                onCancel={onCancel}
              >
                {
                  _.isBoolean(selectedAction.seekingCandidatesOnly) ? (
                    <div>Seeking Profiles Only: {selectedAction.seekingCandidatesOnly ? 'True': 'False'}</div>
                  ) : null
                }
              </Modal>
            ) : null
        }
        <Grid columns={2}>
          <Grid.Column width={3}>
            <SCheckbox
              toggle
              label="See more"
              onChange={(e, { checked }) => {
                if (checked) {
                  const clientIds =
                    search.clientIds ||
                    _.pluck(actionsOptions.clientsList, 'id').join(';') ||
                    'random';
                  const jobOfferIds =
                    search.jobOfferIds ||
                    _.pluck(actionsOptions.offersList, 'id').join(';') ||
                    'random';
                  const userTypes =
                    search.types || actionsOptions.actionsList.join(';');
                  const searchWithRestriction =
                    (restrictedUsers || []).length === 0
                      ? {
                          ...search,
                          types,
                        }
                      : {
                          ...search,
                          clientIds,
                          jobOfferIds,
                          types: userTypes,
                        };
                  onSearch({ ...search, types });
                  onLoadActions({ ...searchWithRestriction }, 0);
                } else {
                  const clientIds =
                    search.clientIds ||
                    _.pluck(actionsOptions.clientsList, 'id').join(';') ||
                    'random';
                  const jobOfferIds =
                    search.jobOfferIds ||
                    _.pluck(actionsOptions.offersList, 'id').join(';') ||
                    'random';
                  const types =
                    search.types || actionsOptions.actionsList.join(';');
                  const searchWithRestriction =
                    (restrictedUsers || []).length === 0
                      ? search
                      : {
                          ...search,
                          clientIds,
                          jobOfferIds,
                          types,
                        };
                  onSearch({ ...search, types: '' });
                  onLoadActions(
                    {
                      ...searchWithRestriction,
                      types:
                        (restrictedUsers || []).length === 0
                          ? ''
                          : actionsOptions.actionsList.join(';'),
                    },
                    0,
                  );
                }
              }}
            />
            <ActionsSearchForm
              actionsOptions={actionsOptions}
              onChange={(s) => {
                const clientIds =
                  s.clientIds ||
                  _.pluck(actionsOptions.clientsList, 'id').join(';') ||
                  'random';
                const jobOfferIds =
                  s.jobOfferIds ||
                  _.pluck(actionsOptions.offersList, 'id').join(';') ||
                  'random';
                const types = s.types || actionsOptions.actionsList.join(';');
                const searchWithRestriction =
                  (restrictedUsers || []).length === 0
                    ? s
                    : { ...s, clientIds, jobOfferIds, types };
                onSearch(s);
                onLoadActions(searchWithRestriction, 0);
              }}
              search={search}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            <ActionsList
              actions={actions}
              page={page}
              onPage={(i) => {
                const clientIds =
                  search.clientIds ||
                  _.pluck(actionsOptions.clientsList, 'id').join(';') ||
                  'random';
                const jobOfferIds =
                  search.jobOfferIds ||
                  _.pluck(actionsOptions.offersList, 'id').join(';') ||
                  'random';
                const types =
                  search.types || actionsOptions.actionsList.join(';');
                const searchWithRestriction =
                  (restrictedUsers || []).length === 0
                    ? search
                    : { ...search, clientIds, jobOfferIds, types };
                onLoadActions(searchWithRestriction, i);
              }}
              onProfileModal={onProfileModal}
              onOfferCriteriaModal={onOfferCriteriaModal}
              onTemplateDiffModal={onTemplateDiffModal}
              onNewSuggestedOffer={onNewSuggestedOffer}
              onMessageModal={onMessageModal}
              restrictedUsers={restrictedUsers}
              sweethubIdFromSweetappId={
                this.state.sweethubIdFromSweetappId || {}
              }
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

// Containers
const mapSAction = (state) => ({
  page: state.page,
  search: state.search,
  actions: state.actions,
  modalMode: state.modalMode,
  selectedProfile: state.selectedProfile,
  selectedAction: state.selectedAction,
  actionsOptions: state.actionsOptions,
});

const mapDAction = (dispatch) => ({
  onLoadFilterOptions: async ({ restrictedUsers }) => {
    if ((restrictedUsers || []).length === 0) {
      const clients = (await axios.get(`${baseUrl}/station/clients`)).data;
      const clientsList = _.map(clients, ({ id, name }) => ({ id, name }));
      const offers = (await axios.get(`${baseUrl}/station/clients/all/offers`))
        .data;
      const offersList = _.map(offers, ({ id, title, clientId }) => ({
        id,
        title,
        clientId,
      }));
      const actionsList = (await axios.get(`${baseUrl}/station/actions/types`))
        .data;
      dispatch(
        setActionsFilterOptions({ clientsList, offersList, actionsList }),
      );
    } else {
      const offers = (await axios.get(`${baseUrl}/myspace/station_offers`, {
        params: { users: restrictedUsers },
      })).data;
      const clientsList = _.uniq(
        _.map(offers, ({ clientId, clientName }) => ({
          id: clientId,
          name: clientName,
        })),
      );
      const offersList = _.map(offers, ({ id, title, clientId }) => ({
        id,
        title,
        clientId,
      }));
      const actionsList = getMoreActions(restrictedUsers);
      dispatch(
        setActionsFilterOptions({ clientsList, offersList, actionsList }),
      );
      return offersList;
    }
  },
  onLoadActions: async (search = {}, page = 0) => {
    const minTimeStamp = search.minDate && new Date(search.minDate).getTime();
    const maxTimeStamp = search.maxDate && new Date(search.maxDate).getTime();
    const body = {
      ...search,
      ...(minTimeStamp && { minTimeStamp }),
      ...(maxTimeStamp && { maxTimeStamp }),
    };
    const { data } = await axios.post(
      `${baseUrl}/station/actions?limit=${PER_PAGE}&skip=${PER_PAGE * page}`,
      {
        minTimeStamp: Date.now() - 90*24*3600*1000,
        ...body
      },
    );
    dispatch(setActions(data, page));
  },
  onProfileModal: async (profileId) => {
    const body = {
      ids: profileId,
    };
    const { data } = await axios.post(
      `${baseUrl}/station/profiles?limit=10`,
      body,
    );
    dispatch(setModalMode('profile', data.results[0]));
  },
  onOfferCriteriaModal: async (action) => {
    dispatch(setModalMode('offer-criteria', action));
  },
  onTemplateDiffModal: async (action) => {
    dispatch(setModalMode('template-diff', action));
  },
  onNewSuggestedOffer:async (action) => {
    dispatch(setModalMode('new-suggested-offer', action));
  },

  onMessageModal: async (action) => {
    dispatch(setModalMode('message', action));
  },
  onSearch: (search) => dispatch(setSearch(search)),
  onCancel: () => dispatch(setModalMode('none')),
});

const ActionContainer = connect(
  mapSAction,
  mapDAction,
)(Actions);

export default (props) => (
  <Provider store={store}>
    <ActionContainer {...props} />
  </Provider>
);
