import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Matches from './Matches';

export default class MappingEditor extends React.Component {

  render() {
    return (
      <Modal
        trigger={<Button>Edit location</Button>}
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onModalClose}
      >
        <Modal.Header>Edit mapping: {this.props.textToEdit}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {this.props.matches ? (
              <Matches
                addNewMatch={this.props.addNewMatch}
                addNewWeightedLocation={this.props.addNewWeightedLocation}
                deleteMatch={this.props.deleteMatch}
                deleteWeightedLocation={this.props.deleteWeightedLocation}
                matches={this.props.matches}
                editable
                loadOptions={this.props.loadOptions}
                onSubmitProba={this.props.onSubmitProba}
                handleLocationSearchSubmit={this.props.handleLocationSearchSubmit}
                onSubmitBegin={this.props.onSubmitBegin}
                onSubmitEnd={this.props.onSubmitEnd}
              />
            ) : null}
            <Button positive onClick={this.props.onSubmit} floated="right">
              Submit
            </Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
