import React from 'react';
import { Form } from 'semantic-ui-react';

export default class Search extends React.Component {
  state = { search: '' };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    return (
      <div>
        <Form onSubmit={() => this.props.handleSubmit(this.state.search)}>
          <Form.Input
            value={this.state.search}
            name="search"
            icon={this.props.icon || 'search'}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
          />
        </Form>
      </div>
    );
  }
}
