import _ from 'underscore';
import React, { Component } from 'react';
import { Table, Icon, Popup } from 'semantic-ui-react';
import { isSummarySuspicious } from '../common';
import withSheetsManager from '../FlowBoard/withSheetsManager';

const getSummaryStr = (summary) => (
  _.isString(summary) ? (
    summary
  ) : _.isObject(summary) && _.isString(summary.content) ? (
    summary.content
  ) : _.isObject(summary) && _.isString((summary.content || {}).text) ? (
    summary.content.text
  ) : ''
);

const getHeadlineStr = (headline) => (
  _.isString(headline) ? (
    headline
  ) : _.isObject(headline) && _.isString(headline.content) ? (
    headline.content
  ) : _.isObject(headline) && _.isString((headline.content || {}).text) ? (
    headline.content.text
  ) : ''
)


const InstanceCell = ({ i, b, field, condensed, onClick }) => {
  const value = i[field] || '';
  return (
    <Table.Cell
      onClick={
        b && i.sources && i.sources[field]
          ? () => {
              window.open(i.sources[field], '_blank');
            }
          : onClick
      }
      style={
        b && i.sources && i.sources[field]
          ? { backgroundColor: '#bbbbff' }
          : field === 'summary' && isSummarySuspicious(getSummaryStr(i[field]))
          ? { backgroundColor: '#ffdddd' }
          : {}
      }
    >
      {(field === 'summary' || (field === 'email' && condensed)) && i[field] ? (
        <Popup
          wide="very"
          trigger={<Icon name="add" />}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: getSummaryStr(i[field]).replace(/\n/g, '<br/>'),
              }}
            />
          }
        />
      ) : !condensed ? (
        <span>{_.isObject(value) ? (value.content || {}).text : value}</span>
      ) : (
        <span title={value}>
          {(_.isObject(value) ? (value.content || {}).text : value).substr(0, 20) + (value.length > 20 ? '...' : '')}
        </span>
      )}
    </Table.Cell>
  );
};

const ItemRow = ({
  sheet,
  docker,
  item,
  index,
  withAdminActions,
  askEditItemModal,
  uploadItems,
  unUploadItem,
  deleteItem,
  unlockItem,
  inverted,
  condensed,
  nbItems,
  withSweetappData,
}) => {
  const profileData = (item || {}).content || {};

  // TODO, modify
  const editFieldFirstname = {
    label: 'firstname',
    key: 'firstname',
    type: 'input',
    initialValue: profileData.firstname,
  };
  const editFieldLastname = {
    label: 'lastname',
    key: 'lastname',
    type: 'input',
    initialValue: profileData.lastname,
  };
  const editFieldEmail = {
    label: 'email',
    key: 'email',
    type: 'input',
    initialValue: profileData.email,
  };
  const editFieldLinkedin = {
    label: 'linkedin',
    key: 'sources.linkedin',
    type: 'input',
    initialValue: (profileData.sources && profileData.sources.linkedin) || null,
  };
  const editFieldGithub = {
    label: 'github',
    key: 'sources.github',
    type: 'input',
    initialValue: (profileData.sources && profileData.sources.github) || null,
  };
  const editFieldStackoverflow = {
    label: 'stackoverflow',
    key: 'sources.stackoverflow',
    type: 'input',
    initialValue:
      (profileData.sources && profileData.sources.stackoverflow) || null,
  };
  const editFieldTwitter = {
    label: 'twitter',
    key: 'sources.twitter',
    type: 'input',
    initialValue: (profileData.sources && profileData.sources.twitter) || null,
  };
  const editFieldWebsite = {
    label: 'website',
    key: 'sources.website',
    type: 'input',
    initialValue: (profileData.sources && profileData.sources.website) || null,
  };
  const editFieldHeadline = {
    label: 'headline',
    key: 'headline',
    type: 'input',
    initialValue: getHeadlineStr(profileData.headline),
  };
  const editFieldComment = {
    label: 'comment',
    key: 'comment',
    type: 'input',
    initialValue:
      (((item || {}).annotations || {}).hiresweet || {}).message || null,
  };
  const editFieldSummary = {
    label: 'summary',
    key: 'summary',
    type: 'textarea',
    initialValue: getSummaryStr(profileData)
  };

  const unitaryEdition = ({ label, key, type, initialValue }) => ({
    onClick: () => {
      askEditItemModal({
        sheetId: sheet.id,
        itemId: item.id,
        editFields: [{ label, key, type, initialValue }],
      });
    },
  });

  const fullEdition = () => ({
    onClick: () => {
      askEditItemModal({
        sheetId: sheet.id,
        itemId: item.id,
        editFields: [
          editFieldFirstname,
          editFieldLastname,
          editFieldEmail,
          editFieldLinkedin,
          editFieldGithub,
          editFieldStackoverflow,
          editFieldTwitter,
          editFieldWebsite,
          editFieldHeadline,
          editFieldComment,
          editFieldSummary,
        ],
        withDeleteMode: true,
      });
    },
  });

  const companyName =
    ((profileData.experiences || []).length > 0
      ? profileData.experiences[0].companyName
      : null) || '';

  const sweetappStep = (item.funnelStatus || {}).step;
  //const sweetappContactStatus = (item.funnelStatus || {}).contactStatus;

  const sweetappColor =
    sweetappStep && sweetappStep.type
      ? sweetappStep.type === 'pending'
        ? null
        : sweetappStep.type === 'skipped'
        ? '#FFEEEE' //red
        : sweetappStep.type === 'disqualified'
        ? '#FFFFEE'
        : '#EEFFEE'
      : null;

  //const answerLabel = sweetappContactStatus && sweetappContactStatus.answer && sweetappContactStatus.answer.label;
  //const answerLabels = sweetappContactStatus && sweetappContactStatus.answer && sweetappContactStatus.answer.labels;
  //const answerLabelsText = (answerLabels || []).join(' \n');

  return (
    <Table.Row
      key={item.id}
      textAlign="center"
      style={{
        ...(sweetappColor && { background: sweetappColor }),
      }}
    >
      <Table.Cell>{inverted ? nbItems - index : index + 1}</Table.Cell>
      <Table.Cell {...fullEdition()}>
        <Icon link name="pencil" />
      </Table.Cell>
      <InstanceCell
        {...unitaryEdition(editFieldFirstname)}
        i={profileData}
        condensed={true}
        field="firstname"
      />
      <InstanceCell
        {...unitaryEdition(editFieldLastname)}
        i={profileData}
        condensed={true}
        field="lastname"
      />
      <InstanceCell
        {...unitaryEdition(editFieldEmail)}
        i={profileData}
        condensed={condensed}
        field="email"
      />
      <Table.Cell>
        <span title={companyName}>
          {companyName.substr(0, 15) + (companyName.length > 15 ? '...' : '')}{' '}
        </span>
      </Table.Cell>
      <InstanceCell
        {...unitaryEdition(editFieldHeadline)}
        i={profileData}
        condensed={true}
        field="headline"
      />
      {!condensed && (
        <InstanceCell
          {...unitaryEdition(editFieldSummary)}
          i={ { ...profileData, summary: getSummaryStr(profileData.summary) } }
          field="summary"
        />
      )}
      <InstanceCell
        {...unitaryEdition(editFieldLinkedin)}
        i={profileData}
        b={true}
        field="linkedin"
      />
      <InstanceCell
        {...unitaryEdition(editFieldGithub)}
        i={profileData}
        b={true}
        field="github"
      />
      <InstanceCell
        {...unitaryEdition(editFieldStackoverflow)}
        i={profileData}
        b={true}
        field="stackoverflow"
      />
      <InstanceCell
        {...unitaryEdition(editFieldTwitter)}
        i={profileData}
        b={true}
        field="twitter"
      />
      <InstanceCell
        {...unitaryEdition(editFieldWebsite)}
        i={profileData}
        b={true}
        field="website"
      />
      <InstanceCell i={profileData} b={true} field="cv" />
      <InstanceCell i={profileData} b={true} field="dribbble" />
      <InstanceCell i={profileData} b={true} field="behance" />
      <InstanceCell i={profileData} b={true} field="angellist" />
      <Table.Cell>
        {(((item || {}).annotations || {}).hiresweet || {}).message}
      </Table.Cell>
      {!condensed && (
        <Table.Cell>
          {item.state.status}
          {(item.state || {}).message && (
            <Popup
              wide="very"
              trigger={<Icon name="add" />}
              content={item.state.message}
            />
          )}
        </Table.Cell>
      )}
      {withAdminActions && (
        <Table.Cell>
          {(item.state || {}).status === 'uploaded' ? (
            <Icon
              link
              name="minus"
              color="red"
              onClick={() => unUploadItem({ item, docker })}
            />
          ) : (
            <span>
              <Icon
                link
                name="add"
                color="green"
                onClick={() => {
                  uploadItems({ items: [item], docker });
                }}
              />
            </span>
          )}
          {(item.state || {}).lockedFor ? (
            <span>
              <Popup
                wide="very"
                trigger={
                  <Icon
                    name="unlock"
                    onClick={() => unlockItem({ item, docker })}
                  />
                }
                content={(item.state || {}).error || 'unknown reason'}
              />
              {((item.state || {}).error || '').indexOf('conflict') >= 0 && (
                <span>
                  <Popup
                    trigger={
                      <Icon
                        name="remove"
                        style={{ cursor: 'pointer' }}
                        color="red"
                        onClick={() =>
                          deleteItem({
                            sheetId: item.sweetsheetId,
                            itemId: item.id,
                          })
                        }
                      />
                    }
                    content={'remove the profile from the sweetsheet'}
                  />
                </span>
              )}
            </span>
          ) : (item.uploadabilityStatus || {}).isConflicting ? (
            <span>
              <Icon name="tags" color="red" />
              {(item.state || {}).status !== 'uploaded' && (
                <Popup
                  trigger={
                    <Icon
                      link
                      name="add"
                      color="orange"
                      onClick={() => {
                        uploadItems({ items: [item], docker, force: true });
                      }}
                    />
                  }
                  content={'upload the profile on the client platform'}
                />
              )}
              <Popup
                trigger={
                  <Icon
                    name="remove"
                    style={{ cursor: 'pointer' }}
                    color="red"
                    onClick={() =>
                      deleteItem({
                        sheetId: item.sweetsheetId,
                        itemId: item.id,
                      })
                    }
                  />
                }
                content={'remove the profile from the sweetsheet'}
              />
            </span>
          ) : (item.uploadabilityStatus || {}).lock ? (
            <span>
              {(item.state || {}).status !== 'uploaded' && (
                <Popup
                  trigger={
                    <Icon
                      link
                      name="add"
                      color="orange"
                      onClick={() => {
                        uploadItems({ items: [item], docker, force: true });
                      }}
                    />
                  }
                  content={'upload the profile on the client platform'}
                />
              )}
              <Popup
                wide="very"
                trigger={<Icon name="clock" color="red" />}
                content={JSON.stringify(item.uploadabilityStatus.lock)}
              />
            </span>
          ) : null}
        </Table.Cell>
      )}
      {withSweetappData && (
        <Table.Cell>{(sweetappStep && sweetappStep.type) || ''}</Table.Cell>
      )}
      {withSweetappData && (
        <Table.Cell>{(sweetappStep && sweetappStep.reason) || ''}</Table.Cell>
      )}
    </Table.Row>
  );
};

const InstancesTable = ({
  sheet,
  docker,
  instances,
  withAdminActions,
  uploadItems,
  unUploadItem,
  deleteItem,
  unlockItem,
  askEditItemModal,
  condensed,
  inverted,
  withSweetappData,
}) => {
  return (
    <Table celled compact="very" sortable>
      <Table.Header>
        <Table.Row textAlign="center">
          <Table.HeaderCell>N°</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>Firstname</Table.HeaderCell>
          <Table.HeaderCell>Lastname</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Headline</Table.HeaderCell>
          {!condensed && <Table.HeaderCell>Summary</Table.HeaderCell>}
          <Table.HeaderCell>L</Table.HeaderCell>
          <Table.HeaderCell>G</Table.HeaderCell>
          <Table.HeaderCell>SO</Table.HeaderCell>
          <Table.HeaderCell>T</Table.HeaderCell>
          <Table.HeaderCell>W</Table.HeaderCell>
          <Table.HeaderCell>CV</Table.HeaderCell>
          <Table.HeaderCell>D</Table.HeaderCell>
          <Table.HeaderCell>B</Table.HeaderCell>
          <Table.HeaderCell>AL</Table.HeaderCell>
          <Table.HeaderCell>Comment</Table.HeaderCell>

          {!condensed && <Table.HeaderCell>Status</Table.HeaderCell>}
          {withAdminActions && (
            <Table.HeaderCell>Admin Actions</Table.HeaderCell>
          )}
          {withSweetappData && <Table.HeaderCell>SA State</Table.HeaderCell>}
          {withSweetappData && <Table.HeaderCell>Reason</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(instances, (item, index) => (
          <ItemRow
            key={index}
            sheet={sheet}
            docker={docker}
            item={item}
            index={index}
            withAdminActions={withAdminActions}
            uploadItems={uploadItems}
            unUploadItem={unUploadItem}
            deleteItem={deleteItem}
            unlockItem={unlockItem}
            askEditItemModal={askEditItemModal}
            inverted={inverted}
            condensed={condensed}
            nbItems={instances.length}
            withSweetappData={withSweetappData}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

class SweetSheet extends Component {
  componentWillMount() {
    this.props.reloadAllDockers();
    setInterval(
      () => {
        console.log('reeload');
        this.props.reloadAllDockers();
      },
      60 * 1000,
      true,
    );
  }
  render() {
    const { dockers, askEditItemModal, withAdminActions, loading } = this.props;
    if (loading) {
      return <div>Loading...</div>;
    }
    if (_.isArray(dockers) && _.isEmpty(dockers)) {
      return <div>No sheet found </div>;
    }
    const docker = _.isEmpty(dockers) ? null : dockers[0];

    const groupedItems = _.groupBy(
      _.sortBy(
        docker.items,
        ({ state }) => (state || {}).sweetappUploadTimestamp,
      ),
      (item) =>
        (item.state || {}).status === 'uploaded' ? 'processed' : 'waiting',
    );
    const waitingItems = groupedItems['waiting'] || [];
    const processedItems = groupedItems['processed'] || [];
    const sortedProcessedItems = _.sortBy(processedItems, ({ state }) => {
      if (state.sweetappUploadTimestamp) {
        return -state.sweetappUploadTimestamp;
      }
      return 0;
    });
    return (
      <div style={{ overflowY: 'auto' }}>
        <h2>Waiting items</h2>
        {waitingItems.length > 0 ? (
          <InstancesTable
            instances={waitingItems}
            withAdminActions={withAdminActions}
            askEditItemModal={askEditItemModal}
            sheet={(docker || {}).sweetsheet || {}}
            docker={docker}
            uploadItems={this.props.uploadItems}
            unUploadItem={this.props.unUploadItem}
            deleteItem={this.props.deleteItem}
            unlockItem={this.props.unlockItem}
            withSweetappData={false}
          />
        ) : (
          <span>No waiting items</span>
        )}
        <h2>Processed Items</h2>
        {processedItems.length > 0 ? (
          <InstancesTable
            instances={sortedProcessedItems}
            withAdminActions={withAdminActions}
            askEditItemModal={askEditItemModal}
            uploadItems={this.props.uploadItems}
            unUploadItem={this.props.unUploadItem}
            sheet={(docker || {}).sweetsheet || {}}
            docker={docker}
            inverted={true}
            condensed={true}
            withSweetappData={true}
          />
        ) : (
          <span>No processed items</span>
        )}
      </div>
    );
  }
}

const SweetSheetComponent = withSheetsManager(SweetSheet, {
  items: {
    enrichments: {
      uploadabilityStatus: 1,
      funnelStatus: 1,
    },
  },
});

export default SweetSheetComponent;
