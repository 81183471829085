import React from 'react';
import _ from 'underscore';

import { Menu, Segment } from 'semantic-ui-react';

export default class LocationSelector extends React.Component {
  state = { activeItem: 0 };
  handleClick = (e, { name }) => {
    this.setState({ activeItem: parseInt(name) });
    this.props.handleClick(name);
  };
  render() {
    return (
      <Segment>
        <Menu pointing secondary>
          {_.map(this.props.locations, (l, index) => (
            <Menu.Item name={index} key={index} active={this.state.activeItem === index} onClick={this.handleClick}>
              {l.rawName}
            </Menu.Item>
          ))}
        </Menu>
      </Segment>
    );
  }
}
