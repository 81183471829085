var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import React from 'react';
import PropTypes from 'prop-types';

var enhance = function enhance(WrappedComponent) {
  var Result = function (_React$Component) {
    _inherits(Result, _React$Component);

    function Result() {
      _classCallCheck(this, Result);

      return _possibleConstructorReturn(this, (Result.__proto__ || Object.getPrototypeOf(Result)).apply(this, arguments));
    }

    _createClass(Result, [{
      key: 'getPath',
      value: function getPath() {
        var field = this.props.field;
        var path = this.context.path;
        return [].concat(_toConsumableArray(path), [field]);
      }
    }, {
      key: 'componentWillMount',
      value: function componentWillMount() {
        var defaultValue = this.props.defaultValue;
        var _context = this.context,
            getState = _context.getState,
            setState = _context.setState;
        var selfPath = this.getPath();
        var value = getState().getIn(selfPath);

        if (value === undefined && defaultValue !== undefined) {
          setState(selfPath, defaultValue);
        }
      }
    }, {
      key: 'getChildContext',
      value: function getChildContext() {
        var field = this.props.field;
        var _context2 = this.context,
            getState = _context2.getState,
            setState = _context2.setState;
        return {
          path: [].concat(_toConsumableArray(this.context.path), [field]),
          getState: getState,
          setState: setState
        };
      }
    }, {
      key: 'render',
      value: function render() {
        var defaultValue = this.props.defaultValue;
        var _context3 = this.context,
            getState = _context3.getState,
            setState = _context3.setState;
        var selfPath = this.getPath();
        var value = getState().getIn(selfPath);

        var setValue = function setValue(newValue) {
          setState(selfPath, newValue);
        };

        return React.createElement(WrappedComponent, Object.assign({}, this.props, {
          value: value !== undefined ? value : defaultValue,
          setValue: setValue
        }));
      }
    }]);

    return Result;
  }(React.Component);

  Result.childContextTypes = {
    path: PropTypes.array,
    getState: PropTypes.func,
    setState: PropTypes.func
  };
  Result.contextTypes = {
    path: PropTypes.array,
    getState: PropTypes.func,
    setState: PropTypes.func
  };
  return Result;
};

export default enhance;