import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Header, List, Button, Label, Segment, Loader } from 'semantic-ui-react';

import baseUrl from '../../baseUrl';
import WorkplaceList from './WorkplaceList';
import { filterWorkplaces, getPageWorkplacesAndCounts } from './WorkplaceSearch';
import EditWorkplaceGroupModal from './EditWorkplaceGroupModal';

class WorkplaceGroups extends Component {
  state = {
    groups: null,
    selectedWorkplace: null,
    editedGroup: null,
    allWorkplaces: null,
    editMode: null,
    pageOffset: 0,
    nbPerPage: 30,
  };

  async componentWillMount() {
    this.loadWorkplaceGroups();
    this.loadWorkplaces();
  }

  loadWorkplaceGroups = async () => {
    let endUrl = '?'
    if (this.props.type){
      endUrl += 'type='+this.props.type
    }
    const groups = (await axios.get(`${baseUrl}/workplaces/groups${endUrl}`)).data;
    this.setState({ groups });
  };

  loadWorkplaces = async () => {
    const { data } = await axios.post(`${baseUrl}/workplaces`, {
      ...(this.props.type && {filter: { type: this.props.type }}),
    });
    const allWorkplaces = _.sortBy(data || [], ({ id }) => id);
    this.setState({
      allWorkplaces,
    });
  };

  onOpenCreateWorkplaceGroup = () => {
    this.setState({ editMode: 'create' });
  };

  onOpenEditWorkplaceGroup = (editedGroup) => {
    this.setState({ editMode: 'edit', editedGroup });
  };

  onCloseEdition = () => {
    this.setState({ editMode: null, editedGroup: null });
  };

  onSelectWorkplaceGroup = (group) => {
    this.setState({ selectedGroup: group });
  };
  onSetPageOffset = (pageOffset) => {
    this.setState({ pageOffset });
  };

  onCreateWorkplaceGroup = async (input) => {
    try {
      const result = (await axios.post(`${baseUrl}/workplaces/groups`, input)).data;
      if (!result.success) {
        throw Error(JSON.stringify(result));
      }
    } catch (e) {
      console.log('CREATE ERROR', e);
      alert(JSON.stringify(e));
    }
    this.loadWorkplaceGroups();
  };

  onEditWorkplaceGroup = async (input) => {
    try {
      const groupId = input.id;
      const result = (await axios.put(`${baseUrl}/workplaces/groups/${groupId}`, _.omit(input, 'id'))).data;
      if (!result.success) {
        throw Error(JSON.stringify(result));
      }
      const newGroups = _.map(this.state.groups, (group) => (group.id === groupId ? input : group));
      const newSelectedGroup = (this.state.selectedGroup || {}).id === groupId ? input : this.state.selectedGroup;
      this.setState({
        groups: newGroups,
        selectedGroup: newSelectedGroup,
      });
    } catch (e) {
      console.log('EDIT ERROR', e);
      alert(JSON.stringify(e));
    }
  };
  onDeleteWorkplaceGroup = async (groupId) => {
    try {
      const result = (await axios.delete(`${baseUrl}/workplaces/groups/${groupId}`)).data;
      if (!result.success) {
        throw Error(JSON.stringify(result));
      }
    } catch (e) {
      alert(JSON.stringify(e));
    }
    this.loadWorkplaceGroups();
  };

  render() {
    const { selectedGroup, editedGroup, groups, allWorkplaces, pageOffset, nbPerPage, editMode } = this.state;
    const { type } = this.props;
    let pageWorkplaces, counts;
    if (allWorkplaces && selectedGroup) {
      const filteredWorkplaces = filterWorkplaces(allWorkplaces, selectedGroup.filter);
      let pageWorkplacesAndCounts = getPageWorkplacesAndCounts(filteredWorkplaces, pageOffset, nbPerPage);
      pageWorkplaces = pageWorkplacesAndCounts.pageWorkplaces;
      counts = pageWorkplacesAndCounts.counts;
    }

    return (
      <Grid>
        <Grid.Column width={4}>
          <Button positive onClick={() => this.onOpenCreateWorkplaceGroup()}>
            Create Group
          </Button>
          <List bulleted>
            {_.map(groups, (group) => (
              <List.Item key={group.id}>
                <a onClick={(e) => this.onSelectWorkplaceGroup(group)}>{group.name}</a>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedGroup ? (
            <div>
              <Segment>
                <Header as='h2'>{selectedGroup.name}</Header>
                <Label>id: {selectedGroup.id}</Label>
                <Label>type: {selectedGroup.type}</Label>
                <Header as='h3'>Filter</Header>
                <Segment style={{ 'white-space': 'pre-wrap' }}>
                  {JSON.stringify(selectedGroup.filter, null, '\t')}
                </Segment>

                <Button positive onClick={() => this.onOpenEditWorkplaceGroup(selectedGroup)}>
                  Edit Group
                </Button>
              </Segment>
              <Segment>
                <Header as='h3'>{`Workplaces (${this.props.type || ''})`}</Header>
                {allWorkplaces ? (
                  <WorkplaceList
                    workplaces={pageWorkplaces}
                    counts={counts}
                    onChangePage={this.onSetPageOffset}
                    onSelect={() => {}}
                    workplaceType={this.props.type}
                  />
                ) : (
                  <Loader active inline />
                )}
              </Segment>
            </div>
          ) : (
            <p>Select a group</p>
          )}
          {editMode === 'edit' ? (
            <EditWorkplaceGroupModal
              initialValues={editedGroup}
              onCancel={this.onCloseEdition}
              onSubmit={this.onEditWorkplaceGroup}
              onDelete={this.onDeleteWorkplaceGroup}
            />
          ) : null}
          {editMode === 'create' ? (
            <EditWorkplaceGroupModal
              onCancel={this.onCloseEdition}
              onSubmit={this.onCreateWorkplaceGroup}
              type={type}
              creationMode={true}
            />
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

export default WorkplaceGroups;
