import React from 'react';


class ExternalProfileReceiver extends React.Component {
  getProfileFromInput = () => {
    const value = (this.input || {}).value || '';
    try {
      const profile = JSON.parse(value);
      return profile;
    } catch(e) {
      alert(e.message);
      return null;
    }
  }
  onAddProfile = () => {
    const profile = this.getProfileFromInput();
    
    if (profile && this.props.onAddProfile) {
      this.props.onAddProfile(profile);
    }
  }
  onUpdateProfile = () => {
    const profile = this.getProfileFromInput();
    if (profile && this.props.onUpdateProfile) {
      this.props.onUpdateProfile(profile);
    }
  }
  render() {
    return (
      <div style={{ 
        position: 'fixed',
        left: -1000,
        top: -1000,
      }}>
        <input 
          className='external-profile-receiver__input'
          ref={e => this.input = e}
        />
        <button 
          className='external-profile-receiver__add-profile'
          onClick={this.onAddProfile}
        >
          Add Profile
        </button>
        <button 
          className='external-profile-receiver__update-profile'
          onClick={this.onUpdateProfile}
        >
          Update Profile
        </button>
      </div>
    );
  }
}

export default ExternalProfileReceiver;
