import React from 'react';
import _ from 'underscore';
import { Statistic } from 'semantic-ui-react';
import {
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  ComposedChart,
} from 'recharts';

const timestampToHuman = (ts) => {
  const d = new Date(ts * 1000);
  return d.toLocaleDateString('fr-FR', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
};

class Graph extends React.PureComponent {
  render() {
    if (!this.props.successData || !this.props.failureData) {
      return <div />;
    }

    let totalSuccess = 0;
    let totalFailures = 0;

    const metrics = {};
    _.forEach(this.props.successData, (value, timestamp) => {
      if (!metrics[timestamp]) {
        metrics[timestamp] = {
          success: 0,
          failure: 0,
        };
      }
      metrics[timestamp].success = value;
      totalSuccess += value;
    });

    _.forEach(this.props.failureData, (value, timestamp) => {
      if (!metrics[timestamp]) {
        metrics[timestamp] = {
          success: 0,
          failure: 0,
        };
      }
      metrics[timestamp].failure = value;
      totalFailures += value;
    });

    const totalRatio = Math.round((totalSuccess / (totalFailures + totalSuccess)) * 100, 2) / 100;

    const data = _.map(metrics, (values, timestamp) => ({
      name: timestampToHuman(timestamp),
      success: values.success,
      failure: values.failure,
      ratio: Math.round((values.success / (values.success + values.failure)) * 100) / 100,
    }));
    return (
      <div>
        <ResponsiveContainer width="100%" height={200} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <ComposedChart data={data}>
            <Bar type="monotone" stackId="s" dataKey="success" fill="#00FF00" dot={false} />
            <Bar type="monotone" stackId="s" dataKey="failure" fill="#FF0000" dot={false} />
            <Line type="monotone" dataKey="ratio" stroke="#888888" dot={false} yAxisId="ratio" />
            <XAxis dataKey="name" />
            <Tooltip />
            <YAxis />
            <YAxis yAxisId="ratio" orientation="right" />
            <CartesianGrid strokeDasharray="3 3" />
            <Legend />
          </ComposedChart>
        </ResponsiveContainer>
        <Statistic size="tiny" color="green">
          <Statistic.Value>{totalSuccess}</Statistic.Value>
          <Statistic.Label>Successful tasks</Statistic.Label>
        </Statistic>
        <Statistic size="tiny" color="red">
          <Statistic.Value>{totalFailures}</Statistic.Value>
          <Statistic.Label>Failed tasks</Statistic.Label>
        </Statistic>
        <Statistic size="tiny" color="blue">
          <Statistic.Value>{totalRatio}</Statistic.Value>
          <Statistic.Label>Success ratio</Statistic.Label>
        </Statistic>
      </div>
    );
  }
}

export default Graph;
