import _ from 'underscore';
import React from 'react';
import { Modal, Accordion, Icon, Button } from 'semantic-ui-react';
import { getPercent } from './common.js';

class Submission extends React.Component {
  state = {
    confirmationModalIsOpen: false,
    deleting: false,
  };

  handleDelete = async () => {
    const { submission } = this.props;
    this.setState({ deleting: true });
    await this.props.onDeleteSubmission({ submissionId: submission.id });
    this.setState({ confirmationModalIsOpen: false });
  };

  handleToggleSelect = () => {
    const { folderId, submission, onChangeSubmissionsSelection } = this.props;

    onChangeSubmissionsSelection({
      folderId,
      submissionId: submission.id,
      selected: !submission.selected,
      superSelected: submission.superSelected,
    });
  };

  handleToggleSuperSelect = () => {
    const { folderId, submission, onChangeSubmissionsSelection } = this.props;

    onChangeSubmissionsSelection({
      folderId,
      submissionId: submission.id,
      selected: submission.selected,
      superSelected: !submission.superSelected,
    });
  };

  handleDownloadScoresAsJson = async () => {
    const { submission } = this.props;
    const chunkDataPointIdToScore = {};
    _.each(submission.executions, ({ predictions }) => {
      _.each(predictions, ({ chunkDataPointId, score }) => {
        chunkDataPointIdToScore[chunkDataPointId] = score;
      })
    })
    //await navigator.clipboard.writeText(JSON.stringify(chunkDataPointIdToScore, null, 4));
    var el = document.createElement('a');
    document.body.appendChild(el);
    var data_string = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(chunkDataPointIdToScore));
    el.setAttribute('href', data_string);
    el.setAttribute('download', `${submission.name}.json`);
    el.click();
  };

  render() {
    const { submission } = this.props;

    return (
      <div style={{ padding: '10px', paddingLeft: '30px' }}>
        <div>
          <Icon
            link
            size='large'
            color={submission.isInSelection ? 'blue' : 'silver'}
            name={submission.selected ? 'check square' : 'square full'}
            onClick={this.handleToggleSelect}
          />
          <Icon
            link
            size='large'
            color={submission.isInSelection ? 'orange' : 'silver'}
            name={submission.superSelected ? 'circle' : 'circle outline'}
            onClick={this.handleToggleSuperSelect}
          />
          <span style={{ color: submission.isInSelection ? 'blue' : 'black' }}>
            {submission.score !== undefined ? `[${getPercent(submission.score)}%` : ''}
            {submission.score !== undefined && submission.optimizedScore !== undefined && (
              <span style={{ color: 'grey' }}>{` - ${getPercent(submission.optimizedScore)}% `}</span>
            )}
            {submission.score !== undefined ? `] ` : ''}
            {submission.name}
          </span>

          <Icon
            color='red'
            name='trash outline'
            onClick={() => {
              this.setState({ confirmationModalIsOpen: true });
            }}
          />
          <Modal open={this.state.confirmationModalIsOpen}>
            <Modal.Content>
              <p>{`Are you sure you want to delete this submission?`}</p>
              <div>
                <div>Name: {submission.name}</div>
                <div>Id: {submission.id}</div>
              </div>
            </Modal.Content>
            <Modal.Actions>
              {this.state.deleting ? (
                <Button color='red' disabled>
                  Deleting...
                </Button>
              ) : (
                <div>
                  <Button
                    color='blue'
                    inverted
                    onClick={() => {
                      this.setState({ confirmationModalIsOpen: false });
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button color='red' inverted onClick={this.handleDelete}>
                    <Icon name='trash outline' /> DELETE
                  </Button>
                </div>
              )}
            </Modal.Actions>
          </Modal>
          <Icon style={{cursor: 'pointer'}} name='download' color='grey' onClick={this.handleDownloadScoresAsJson} />
        </div>
      </div>
    );
  }
}

class SubmissionsFolder extends React.Component {
  state = { collapsed: true };

  handleToggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  handleToggleSelect = () => {
    const { submissionsFolder, onChangeFolderSelection } = this.props;

    onChangeFolderSelection({
      folderId: submissionsFolder.id,
      selected: !submissionsFolder.selected,
      superSelected: submissionsFolder.superSelected,
    });
  };

  handleToggleSuperSelect = () => {
    const { submissionsFolder, onChangeFolderSelection } = this.props;

    onChangeFolderSelection({
      folderId: submissionsFolder.id,
      selected: submissionsFolder.selected,
      superSelected: !submissionsFolder.superSelected,
    });
  };

  render() {
    const {
      oneSubmissionsFolderIsSuperSelected,
      submissionsFolder,
      onChangeSubmissionsSelection,
      onDeleteSubmission,
    } = this.props;
    const { collapsed } = this.state;

    const nbSubmissionsInSelection = _.where(submissionsFolder.submissions, { isInSelection: true }).length;

    return (
      <div style={{ padding: '10px' }}>
        <Accordion>
          <Accordion.Title active={!collapsed}>
            <Icon name='dropdown' size='large' onClick={this.handleToggleCollapse} />
            <Icon
              link
              size='large'
              color={oneSubmissionsFolderIsSuperSelected && !submissionsFolder.superSelected ? 'silver' : 'blue'}
              name={submissionsFolder.selected ? 'check square' : 'square full'}
              onClick={this.handleToggleSelect}
            />
            <Icon
              link
              size='large'
              color='orange'
              name={submissionsFolder.superSelected ? 'circle' : 'circle outline'}
              onClick={this.handleToggleSuperSelect}
            />
            <span> </span>
            <b style={{ color: nbSubmissionsInSelection > 0 ? 'blue' : 'black' }} onClick={this.handleToggleCollapse}>
              {submissionsFolder.score !== undefined ? `[${getPercent(submissionsFolder.score)}%` : ''}
              {submissionsFolder.score !== undefined && submissionsFolder.optimizedScore !== undefined && (
                <span style={{ color: 'grey' }}>{` - ${getPercent(submissionsFolder.optimizedScore)}% `}</span>
              )}
              {submissionsFolder.score !== undefined ? `] ` : ''}
              {submissionsFolder.name}
              {nbSubmissionsInSelection > 0 ? ' (' + nbSubmissionsInSelection + ')' : ''}
            </b>
          </Accordion.Title>
          <Accordion.Content active={!collapsed}>
            {_.map(submissionsFolder.submissions, (submission, index) => (
              <Submission
                key={index}
                folderId={submissionsFolder.id}
                submission={submission}
                onChangeSubmissionsSelection={onChangeSubmissionsSelection}
                onDeleteSubmission={onDeleteSubmission}
              />
            ))}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

const SubmissionsFolders = ({
  submissionsFolders,
  onChangeFolderSelection,
  onChangeSubmissionsSelection,
  onDeleteSubmission,
}) => (
  <div>
    {_.map(submissionsFolders, (submissionsFolder, index) => (
      <SubmissionsFolder
        key={index}
        oneSubmissionsFolderIsSuperSelected={!!_.findWhere(submissionsFolders, { superSelected: true })}
        submissionsFolder={submissionsFolder}
        onChangeFolderSelection={onChangeFolderSelection}
        onChangeSubmissionsSelection={onChangeSubmissionsSelection}
        onDeleteSubmission={onDeleteSubmission}
      />
    ))}
  </div>
);

class FolderedSubmissionsSelector extends React.Component {
  enrichWithInSelectionBooleans = ({ submissionsFolders }) => {
    const enrichFolder = ({ folder, isFolderSelected }) => {
      const isOneSubmissionSuperSelected = !!_.findWhere(folder.submissions, { superSelected: true });
      return {
        ...folder,
        submissions: _.map(folder.submissions, (submission) => ({
          ...submission,
          isInSelection:
            isFolderSelected && (isOneSubmissionSuperSelected ? submission.superSelected : submission.selected),
        })),
      };
    };

    const isOneFolderSuperSelected = !!_.findWhere(submissionsFolders, { superSelected: true });

    return _.map(submissionsFolders, (submissionsFolder) =>
      enrichFolder({
        folder: submissionsFolder,
        isFolderSelected: isOneFolderSuperSelected ? submissionsFolder.superSelected : submissionsFolder.selected,
      }),
    );
  };

  handleChangeFolderSelection = ({ folderId, selected, superSelected }) => {
    const { submissionsFolders } = this.props;

    const newSubmissionsFolders = this.enrichWithInSelectionBooleans({
      submissionsFolders: _.map(submissionsFolders, (submissionsFolder) =>
        submissionsFolder.id === folderId
          ? {
            ...submissionsFolder,
            selected,
            superSelected,
          }
          : submissionsFolder,
      ),
    });

    this.props.onChangeSubmissionsFoldersSelection({
      submissionsFolders: newSubmissionsFolders,
    });
  };

  handleChangeSubmissionsSelection = ({ folderId, submissionId, selected, superSelected }) => {
    const { submissionsFolders } = this.props;

    const newSubmissionsFolders = this.enrichWithInSelectionBooleans({
      submissionsFolders: _.map(submissionsFolders, (submissionsFolder) =>
        submissionsFolder.id === folderId
          ? {
            ...submissionsFolder,
            submissions: _.map(submissionsFolder.submissions, (submission) =>
              submission.id === submissionId
                ? {
                  ...submission,
                  selected,
                  superSelected,
                }
                : submission,
            ),
          }
          : submissionsFolder,
      ),
    });

    this.props.onChangeSubmissionsFoldersSelection({
      submissionsFolders: newSubmissionsFolders,
    });
  };

  render() {
    const { submissionsFolders, onDeleteSubmission } = this.props;

    return (
      <div>
        <SubmissionsFolders
          submissionsFolders={submissionsFolders}
          onChangeFolderSelection={this.handleChangeFolderSelection}
          onChangeSubmissionsSelection={this.handleChangeSubmissionsSelection}
          onDeleteSubmission={onDeleteSubmission}
        />
      </div>
    );
  }
}

export default FolderedSubmissionsSelector;
