import _ from 'underscore';
import React from 'react';
import {
  getGradColor,
  // getGradSkillColor
} from '../common';

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

export const getColorFromEntity = ({ id, relevance, label }) => {
  return label
    ? (label === 'required' || label === 'required__strong-implication' || label === 'required__medium-implication')
    ? '#21BA45'
    : (label === 'important' || label === 'important__strong-implication' || label === 'important__medium-implication')
    ? '#B5CC18'
    : (label === 'bonus' || label === 'bonus__strong-implication' || label === 'bonus__medium-implication')
    ? '#FBBD08'
    : label === 'cardinal'
    ? 'yellow'
    : label === 'positive'
    ? '#21BA45'
    : 'red'
  : '#eee';
};

export const getColorFromEnrichedText = (enrichedText) => {
  if (!enrichedText) {
    return;
  }
  if (enrichedText.entities) {
    for (let iEntity = 0; iEntity < enrichedText.entities.length; iEntity++) {
      const entity = enrichedText.entities[iEntity];
      if (entity.label) {
        return getColorFromEntity(entity);
      }
    }
  }
  if (enrichedText.content) {
    for (let iContent = 0; iContent < enrichedText.content.length; iContent++) {
      let color = getColorFromEnrichedText(enrichedText.content[iContent]);
      if (color) {
        return color;
      }
    }
  }
};
const EntityBlock = ({ text, entity }) => {
  const { name, relevance } = entity;
  const title = name + ': ' + relevance;
  const color = getColorFromEntity(entity);
  return (
    <span style={{ backgroundColor: color }} title={title}>
      {text}
    </span>
  );
};

const LeafEnrichedText = ({ text, entities }) => {
  const sortedEntities = _.sortBy(
    _.filter(
      entities,
      ({ position }) => _.isArray(position) && position.length === 2,
    ),
    ({ position }) => {
      return 1000 * position[0] + (1000 - position[1]);
    },
  );

  let items = [];
  let curPos = 0;
  _.each(sortedEntities, (entity) => {
    const [beg, end] = entity.position;
    if (beg < curPos) {
      return;
    }
    if (beg > curPos) {
      items.push(
        <span
          key={items.length}
          dangerouslySetInnerHTML={getRawMarkup(text.slice(curPos, beg))}
        />,
      );
    }
    items.push(
      <span key={items.length}>
        {EntityBlock({ text: text.slice(beg, end), entity })}
      </span>,
    );
    curPos = end;
  });
  if (text.length > curPos) {
    items.push(
      <span
        key={items.length}
        dangerouslySetInnerHTML={getRawMarkup(text.slice(curPos, text.length))}
      />,
    );
  }
  return <span>{items}</span>;
};

const EnrichedText = (enrichedText) => {
  if (_.isString(enrichedText)) {
    return <span>{enrichedText}</span>;
  }
  if (!_.isObject(enrichedText)) {
    return null;
  }
  const { content, entities } = enrichedText;
  if (_.isArray(content)) {
    if (content.length === 1 && _.isString(content[0])) {
      return LeafEnrichedText({ text: content[0], entities });
    } else {
      return (
        <span>
          {_.map(content, (part, index) => (
            <span key={index}>
              {_.isObject(part) && part.type === 'paragraph' && index ? (
                <br />
              ) : (
                ''
              )}
              <EnrichedText {...part} />{' '}
            </span>
          ))}
        </span>
      );
    }
  } else {
    return null;
  }
};

const extractSkillsFrontErichedText = (enrichedText) => {
  const cardinalSkills = [
    'javascript', 'python', 'ruby', 
    'scala', 'cpp', 'php', 'java',
    'ios-development', 'android-development'
  ];
  if (_.isString(enrichedText)) {
    return [];
  }
  if (!_.isObject(enrichedText)) {
    return [];
  }
  const { content, entities } = enrichedText;
  if (_.isArray(content)) {
    if (content.length === 1 && _.isString(content[0])) {
      return _.flatten(
        _.compact(
          _.map(entities, ({ label, id }) => {
            if (label) {
              return { label, id };
            }
            if (cardinalSkills.indexOf(id) >= 0) {
              return { label: 'cardinal', id };
            }
          })
        )
      );
    } else {
      return _.flatten(
        _.map(content, (part) => extractSkillsFrontErichedText(part)),
      );
    }
  }
};

export default EnrichedText;
export { getGradColor, extractSkillsFrontErichedText };
