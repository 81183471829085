/* eslint react/jsx-no-target-blank: 0 */
import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card, Grid, Button, Icon, Image, Input, Message, Dropdown } from 'semantic-ui-react';
import baseUrl from '../baseUrl';

class MailingComponent extends React.Component {
  state = {
    disabled: {},
    sent: {},
  };
  componentWillMount() {
    this.lock = {};
    if (this.props.mode === 'morning') {

      axios.get(baseUrl + '/station/getAutomaticMailsWarnings').then(({ data }) => {
        this.setState({
          warnings: data.warnings,
        });
      });


      axios.get(baseUrl + '/station/getAutomaticMailsData').then(({ data }) => {
        this.setState({
          recipients: data,
        });
      });
    } else if (this.props.mode === 'all') {
      axios
        .get(baseUrl + '/station/getAllAutomaticMailsData')
        .then(({ data }) => {
          this.setState({
            recipients: data,
          });
        });
    } else if (this.props.mode === 'watch') {
      axios
        .get(baseUrl + '/station/getHiresweetWatchAutomaticMailsData')
        .then(({ data }) => {
          this.setState({
            recipients: data,
          });
        });
    } else if (this.props.mode === 'active') {
      axios.get(baseUrl + '/station/getAutomaticMailsData').then(({ data }) => {
        this.setState({
          recipients: data,
        });
      });
    }
  }
  toggle = async (recipient) => {
    const disabled = this.state.disabled;
    this.setState({
      disabled: {
        ...disabled,
        [recipient.email]: !disabled[recipient.email],
      },
    });
  };
  inputUpdate = async (event, { value }) => {
    this.setState({
      inputValue: value,
    });
  };
  templateIdUpdate = async (event, { value }) => {
    this.setState({
      templateId: value,
    });
  };
  senderUpdate = async ({ email, name }) => {
    this.setState({
      from: { email, name },
    });
  };

  sendAll = async () => {
    const recipients = this.state.recipients || [];
    for (let i = 0; i < recipients.length; i++) {
      const recipient = recipients[i];
      if (
        !this.state.disabled[recipient.email] &&
        !this.state.sent[recipient.email]
      ) {
        this.onSend(recipient);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
  };
  onSend = async (recipient) => {
    const templateId = this.state.templateId;
    const from = this.state.from;

    if (this.props.mode !== 'morning' && !templateId) {
      return alert('need templateId');
    }
    if (!recipient.email) {
      return alert('no email in recipient');
    }
    if (this.state.disabled[recipient.email]) {
      return;
    }
    if (this.lock[recipient.email]) {
      return alert(recipient.email + ' is locked');
    }
    this.lock[recipient.email] = true;
    const currentSent = this.state.sent;
    this.setState(
      {
        sent: {
          ...currentSent,
          [recipient.email]: 1,
        },
      },
      () => {
        axios
          .post(baseUrl + '/station/sendAutomaticMail', {
            recipient,
            templateId,
            ...from && { from }
          })
          .then(({ data }) => {
            if (data.error) {
              console.error(data.error);
            } else {
              console.error(recipient.email, 'ok');
            }
          });
      },
    );
  };
  renderRecipients(recipients) {
    const defaultPicture = '/images/defaultPhoto.png';
    const canSend = (this.state.inputValue || '').indexOf('send') >= 0;
    return (
      <div>
        {_.map(recipients, (recipient, index) => (
          <Card key={index} fluid>
            <Card.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={3} verticalAlign="middle">
                    <Image
                      floated="left"
                      size="tiny"
                      circular
                      src={recipient.photoLink || defaultPicture}
                      onError={(e) => {
                        e.target.src = defaultPicture;
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={1} verticalAlign="middle">
                    <div>
                      {this.state.disabled[recipient.email] ? (
                        <Icon
                          color="orange"
                          size="big"
                          name="play"
                          link
                          onClick={() => this.toggle(recipient)}
                        />
                      ) : (
                        <Icon
                          color="gray"
                          size="big"
                          name="pause"
                          link
                          onClick={() => this.toggle(recipient)}
                        />
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} verticalAlign="middle">
                    <b>
                      [{recipient.clientId}
                      {recipient.language && ' -' + recipient.language}]{' '}
                      {recipient.hasDiscover && (<span style={{color:'orange'}}>{' <DISCOVER>'}</span>)}{' '}
                      {recipient.firstname}
                    </b>
                    <br />
                    <em>{recipient.email}</em>
                  </Grid.Column>
                  <Grid.Column width={6} verticalAlign="middle">
                    <ul>
                      {_.map(recipient.offers, (offer, index) => (
                        <li key={index}>
                          {offer.nbPending} {offer.title} ({offer.id})
                        </li>
                      ))}
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={2} verticalAlign="middle">
                    {this.state.sent[recipient.email] ? (
                      <Icon color="green" size="huge" name="check" />
                    ) : recipient.email &&
                      !this.state.disabled[recipient.email] &&
                      canSend ? (
                      <Button
                        color="blue"
                        onClick={() => this.onSend(recipient)}
                      >
                        Send
                      </Button>
                    ) : (
                      <Button disabled>Send</Button>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        ))}
      </div>
    );
  }
  renderWarnings() {
    const { warnings } = this.state;
    
    const byCategory = {}
    _.each(warnings, (warning) => {
      const category = (warning || {}).category
      if (byCategory[category] === undefined) {
        byCategory[category] = []
      }
      byCategory[category].push(warning);
    });

    return (
      <div>
        {_.map(byCategory, (warnings, category) => (
          <Message error key={category}>
            <Message.Header>{category}:</Message.Header>
            {_.map(_.sortBy(warnings, 'title').reverse(), ({ title }) => (
              <Message.Content><span style={{ marginLeft: 5}}></span>- {title}</Message.Content>
            ))}
          </Message>
          
        ))}
      </div>
    )
  }
  render() {
    const canSendAll = (this.state.inputValue || '').indexOf('sendall') >= 0;
    const recipients = this.state.recipients || [];
    const senderOptions= [
      { value: 1, 'email': 'contact@hiresweet.com', name: 'HireSweet' },
      { value: 2, 'email': 'robin.choy@hiresweet.com', name: 'Robin from HireSweet' },
    ];

    return (
      <div>
        {this.props.mode !== 'morning' && (
          <div>
            <Input placeholder="templateId" onChange={this.templateIdUpdate} />
            <br />
            <Dropdown 
              selection
              options={_.map(senderOptions, (option) => ({ 
                ...option,
                text: option.email + ' (' + option.name + ')'
              }))}
              onChange={(event, { value }) => {
                const sender = _.findWhere(senderOptions, { value });
                this.senderUpdate(sender);
              }}
            />
            <br />
          </div>
        )}
        <Input onChange={this.inputUpdate} />
        {canSendAll && (
          <Button color="purple" onClick={() => this.sendAll()}>
            Send All
          </Button>
        )}
        <Grid fluid>
          <Grid.Row>
            <Grid.Column width={11}>
              {this.renderRecipients(recipients)}
            </Grid.Column>
            <Grid.Column width={5}>
              {this.renderWarnings()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

class MailingView extends React.Component {
  render() {
    return (
      <Tabs>
        <TabList>
          <Tab>Morning</Tab>
          <Tab>Watch</Tab>
          <Tab>All</Tab>
          <Tab>Active</Tab>
        </TabList>
        <TabPanel key="morning">
          <MailingComponent mode="morning" />
        </TabPanel>
        <TabPanel key="watch">
          <MailingComponent mode="watch" />
        </TabPanel>
        <TabPanel key="all">
          <MailingComponent mode="all" />
        </TabPanel>
        <TabPanel key="active">
          <MailingComponent mode="active" />
        </TabPanel>
      </Tabs>
    );
  }
}

export default MailingView;
