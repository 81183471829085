import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Header, List, Icon } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

// Actions
const SET_FAVORITES = 'SET_FAVORITES';
const setFavorites = (favorites) => ({ type: SET_FAVORITES, favorites });

const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
const removeFavorite = (category, id) => ({
  type: REMOVE_FAVORITE,
  category,
  id,
});

// Reducers
const favorites = (state = {}, action) => {
  switch (action.type) {
    case SET_FAVORITES:
      return action.favorites;
    case REMOVE_FAVORITE:
      return {
        ...state,
        [action.category]: _.filter(
          state[action.category],
          ({ id }) => id !== action.id,
        ),
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  favorites,
});

// Store
const store = createStore(rootReducer);

// Components
class Favorites extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { favorites, onRemove } = this.props;

    return (
      <div>
        <a href='/offers'>See all offers</a>
        <br/>

        <Header as="h2">Workpipes</Header>
        <List>
          {_.map(favorites.workPipes, (workPipe) => (
            <List.Item key={workPipe.id}>
              <List.Content>
                <Icon
                  name="remove"
                  color="red"
                  link
                  inverted
                  circular
                  size="small"
                  onClick={() => onRemove('workPipes', workPipe.id)}
                />
                &nbsp;
                <Link
                  to={`/offers/${workPipe.jobOfferId}/workpipe/${workPipe.id}`}
                >
                  {workPipe.title}
                </Link>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
}

// Containers
const mapSFavorites = (state) => ({
  favorites: state.favorites,
});

const mapDFavorites = (dispatch) => ({
  onLoad: async () => {
    const favorites = (await axios.get(`${baseUrl}/favorites`)).data;
    dispatch(setFavorites(favorites));
  },
  onRemove: async (type, id) => {
    dispatch(removeFavorite(type, id));
    await axios.delete(`${baseUrl}/favorites/${type}/${id}`);
  },
});

const FavoritesContainer = connect(
  mapSFavorites,
  mapDFavorites,
)(Favorites);

export default ({ match }) => (
  <Provider store={store}>
    <FavoritesContainer match={match} />
  </Provider>
);
