export default [
  {
    "value": "dot-net",
    "text": ".NET"
  },
  {
    "value": "3d",
    "text": "3D"
  },
  {
    "value": "3d-modeling",
    "text": "3D Modeling"
  },
  {
    "value": "3d-programming",
    "text": "3D Programming"
  },
  {
    "value": "3d-rendering",
    "text": "3D Rendering"
  },
  {
    "value": "3d-studio-max",
    "text": "3d Studio Max"
  },
  {
    "value": "ab-testing",
    "text": "AB Testing"
  },
  {
    "value": "api",
    "text": "API"
  },
  {
    "value": "arcore",
    "text": "ARCore"
  },
  {
    "value": "asic",
    "text": "ASIC"
  },
  {
    "value": "asp.net",
    "text": "ASP.NET"
  },
  {
    "value": "asp-mvc",
    "text": "ASP.NET MVC"
  },
  {
    "value": "amazon-web-services",
    "text": "AWS"
  },
  {
    "value": "access-management",
    "text": "Access Management"
  },
  {
    "value": "accountancy",
    "text": "Accountancy"
  },
  {
    "value": "accumulo",
    "text": "Accumulo"
  },
  {
    "value": "acquisition-management",
    "text": "Acquisition Management"
  },
  {
    "value": "actionscript",
    "text": "ActionScript"
  },
  {
    "value": "active-directory",
    "text": "Active Directory"
  },
  {
    "value": "activemq",
    "text": "ActiveMQ"
  },
  {
    "value": "activerecord",
    "text": "ActiveRecord"
  },
  {
    "value": "actuarial-science",
    "text": "Actuarial Science"
  },
  {
    "value": "administration",
    "text": "Administration"
  },
  {
    "value": "after-effects",
    "text": "Adobe After Effects"
  },
  {
    "value": "adobe-creative-suite",
    "text": "Adobe Creative Suite"
  },
  {
    "value": "adobe-premiere",
    "text": "Adobe Premiere"
  },
  {
    "value": "agile-methodology",
    "text": "Agile Methodology"
  },
  {
    "value": "airflow",
    "text": "Airflow"
  },
  {
    "value": "ajax",
    "text": "Ajax"
  },
  {
    "value": "algebra",
    "text": "Algebra"
  },
  {
    "value": "algorithms",
    "text": "Algorithms"
  },
  {
    "value": "allegro",
    "text": "Allegro"
  },
  {
    "value": "allegrograph",
    "text": "AllegroGraph"
  },
  {
    "value": "alooma",
    "text": "Alooma"
  },
  {
    "value": "altium",
    "text": "Altium"
  },
  {
    "value": "amazon-aurora",
    "text": "Amazon Aurora"
  },
  {
    "value": "amazon-rds",
    "text": "Amazon RDS"
  },
  {
    "value": "ambari",
    "text": "Ambari"
  },
  {
    "value": "amplitude",
    "text": "Amplitude"
  },
  {
    "value": "android-development",
    "text": "Android Development"
  },
  {
    "value": "android-sdk",
    "text": "Android SDK"
  },
  {
    "value": "android-studio",
    "text": "Android Studio"
  },
  {
    "value": "angular.js",
    "text": "Angular"
  },
  {
    "value": "angular2.js",
    "text": "Angular 2"
  },
  {
    "value": "angular-4",
    "text": "Angular 4"
  },
  {
    "value": "angular-5",
    "text": "Angular 5"
  },
  {
    "value": "angular-6",
    "text": "Angular 6"
  },
  {
    "value": "angular-7",
    "text": "Angular 7"
  },
  {
    "value": "angular-8",
    "text": "Angular 8"
  },
  {
    "value": "animation",
    "text": "Animation"
  },
  {
    "value": "ansible",
    "text": "Ansible"
  },
  {
    "value": "ant",
    "text": "Ant"
  },
  {
    "value": "antitag",
    "text": "AntiTag"
  },
  {
    "value": "apache",
    "text": "Apache"
  },
  {
    "value": "apex",
    "text": "Apex"
  },
  {
    "value": "apollo",
    "text": "Apollo"
  },
  {
    "value": "appcode",
    "text": "AppCode"
  },
  {
    "value": "appium",
    "text": "Appium"
  },
  {
    "value": "applescript",
    "text": "AppleScript"
  },
  {
    "value": "applied-mathematics",
    "text": "Applied Mathematics"
  },
  {
    "value": "arangodb",
    "text": "ArangoDB"
  },
  {
    "value": "arduino",
    "text": "Arduino"
  },
  {
    "value": "arm",
    "text": "Arm"
  },
  {
    "value": "art-direction",
    "text": "Art Direction"
  },
  {
    "value": "artificial-intelligence",
    "text": "Artificial Intelligence"
  },
  {
    "value": "assembly-programming",
    "text": "Assembly Programming"
  },
  {
    "value": "astronomy",
    "text": "Astronomy"
  },
  {
    "value": "audio-processing",
    "text": "Audio Processing"
  },
  {
    "value": "augmented-reality",
    "text": "Augmented Reality"
  },
  {
    "value": "aurelia.js",
    "text": "Aurelia"
  },
  {
    "value": "authentication-systems",
    "text": "Authentication Systems"
  },
  {
    "value": "authorization-systems",
    "text": "Authorization Systems"
  },
  {
    "value": "autocad",
    "text": "AutoCAD"
  },
  {
    "value": "automatic-control",
    "text": "Automatic Control"
  },
  {
    "value": "automation",
    "text": "Automation"
  },
  {
    "value": "axios",
    "text": "Axios"
  },
  {
    "value": "b2b",
    "text": "B2B"
  },
  {
    "value": "b2c",
    "text": "B2C"
  },
  {
    "value": "behaviour-driven-development",
    "text": "BDD"
  },
  {
    "value": "babel",
    "text": "Babel"
  },
  {
    "value": "web-backend",
    "text": "Back-End"
  },
  {
    "value": "backbone.js",
    "text": "Backbone"
  },
  {
    "value": "balsamiq",
    "text": "Balsamiq"
  },
  {
    "value": "bamboo",
    "text": "Bamboo"
  },
  {
    "value": "bash",
    "text": "Bash"
  },
  {
    "value": "basic-auth",
    "text": "Basic Auth"
  },
  {
    "value": "batch-file",
    "text": "Batch File"
  },
  {
    "value": "beam",
    "text": "Beam"
  },
  {
    "value": "behat",
    "text": "Behat"
  },
  {
    "value": "big-data",
    "text": "Big Data"
  },
  {
    "value": "bioinformatics",
    "text": "Bioinformatics"
  },
  {
    "value": "bitbucket",
    "text": "Bitbucket"
  },
  {
    "value": "cryptocurrency",
    "text": "Bitcoin"
  },
  {
    "value": "blender",
    "text": "Blender"
  },
  {
    "value": "blockchain",
    "text": "Blockchain"
  },
  {
    "value": "blogging",
    "text": "Blogging"
  },
  {
    "value": "bluetooth",
    "text": "Bluetooth"
  },
  {
    "value": "boost",
    "text": "Boost"
  },
  {
    "value": "boost-test-library",
    "text": "Boost Test Library"
  },
  {
    "value": "booting",
    "text": "Booting"
  },
  {
    "value": "bootstrap",
    "text": "Bootstrap"
  },
  {
    "value": "bower",
    "text": "Bower"
  },
  {
    "value": "branding",
    "text": "Branding"
  },
  {
    "value": "build",
    "text": "Build"
  },
  {
    "value": "business-analysis",
    "text": "Business Analysis"
  },
  {
    "value": "business-development",
    "text": "Business Development"
  },
  {
    "value": "business-engineer",
    "text": "Business Engineer"
  },
  {
    "value": "business-intelligence",
    "text": "Business Intelligence"
  },
  {
    "value": "business-strategy",
    "text": "Business Strategy"
  },
  {
    "value": "butterknife",
    "text": "ButterKnife"
  },
  {
    "value": "c",
    "text": "C"
  },
  {
    "value": "csharp",
    "text": "C#"
  },
  {
    "value": "cpp",
    "text": "C++"
  },
  {
    "value": "cpp11",
    "text": "C++11"
  },
  {
    "value": "cpp14",
    "text": "C++14"
  },
  {
    "value": "cpp17",
    "text": "C++17"
  },
  {
    "value": "c3.js",
    "text": "C3"
  },
  {
    "value": "catia",
    "text": "CATIA"
  },
  {
    "value": "cdn",
    "text": "CDN"
  },
  {
    "value": "cms",
    "text": "CMS"
  },
  {
    "value": "cmake",
    "text": "CMake"
  },
  {
    "value": "corba",
    "text": "CORBA"
  },
  {
    "value": "cqrs",
    "text": "CQRS"
  },
  {
    "value": "crm",
    "text": "CRM"
  },
  {
    "value": "css",
    "text": "CSS"
  },
  {
    "value": "css3",
    "text": "CSS3"
  },
  {
    "value": "cto",
    "text": "CTO / VP Engineering"
  },
  {
    "value": "cvs",
    "text": "CVS"
  },
  {
    "value": "cacti",
    "text": "Cacti"
  },
  {
    "value": "cadence",
    "text": "Cadence"
  },
  {
    "value": "caffe",
    "text": "Caffe"
  },
  {
    "value": "cake-php",
    "text": "Cake PHP"
  },
  {
    "value": "calabash",
    "text": "Calabash"
  },
  {
    "value": "calabash-android",
    "text": "Calabash Android"
  },
  {
    "value": "calabash-ios",
    "text": "Calabash iOS"
  },
  {
    "value": "caml",
    "text": "Caml"
  },
  {
    "value": "campaign-management",
    "text": "Campaign Management"
  },
  {
    "value": "camunda",
    "text": "Camunda"
  },
  {
    "value": "cancan",
    "text": "Cancan"
  },
  {
    "value": "capristrano",
    "text": "Capistrano"
  },
  {
    "value": "capybara",
    "text": "Capybara"
  },
  {
    "value": "cascading",
    "text": "Cascading"
  },
  {
    "value": "casper.js",
    "text": "Casper"
  },
  {
    "value": "cassandra",
    "text": "Cassandra"
  },
  {
    "value": "centos",
    "text": "CentOS"
  },
  {
    "value": "chai.js",
    "text": "Chai"
  },
  {
    "value": "chart.js",
    "text": "Chart"
  },
  {
    "value": "chatbot",
    "text": "Chatbot"
  },
  {
    "value": "checkmk",
    "text": "CheckMK"
  },
  {
    "value": "chef",
    "text": "Chef"
  },
  {
    "value": "cherrypy",
    "text": "CherryPy"
  },
  {
    "value": "circleci",
    "text": "CircleCI"
  },
  {
    "value": "citrix",
    "text": "Citrix"
  },
  {
    "value": "clickhouse",
    "text": "Clickhouse"
  },
  {
    "value": "clojure",
    "text": "Clojure"
  },
  {
    "value": "cloud-computing",
    "text": "Cloud Computing"
  },
  {
    "value": "cloud-management",
    "text": "Cloud Management"
  },
  {
    "value": "cloudstack",
    "text": "CloudStack"
  },
  {
    "value": "cloudera",
    "text": "Cloudera"
  },
  {
    "value": "cluster-computing",
    "text": "Cluster Computing"
  },
  {
    "value": "clustering-models",
    "text": "Clustering Models"
  },
  {
    "value": "cobol",
    "text": "Cobol"
  },
  {
    "value": "cocoa",
    "text": "Cocoa"
  },
  {
    "value": "code-igniter",
    "text": "Code Igniter"
  },
  {
    "value": "codec",
    "text": "Codec"
  },
  {
    "value": "coffeescript",
    "text": "Coffeescript"
  },
  {
    "value": "community-management",
    "text": "Community Management"
  },
  {
    "value": "compass",
    "text": "Compass"
  },
  {
    "value": "computer-aided-design",
    "text": "Computer Aided Design"
  },
  {
    "value": "computer-aided-manufacturing",
    "text": "Computer Aided Manufacturing"
  },
  {
    "value": "computer-music",
    "text": "Computer Music"
  },
  {
    "value": "computer-programming",
    "text": "Computer Programming"
  },
  {
    "value": "computer-science",
    "text": "Computer Science"
  },
  {
    "value": "computer-vision",
    "text": "Computer Vision"
  },
  {
    "value": "concourse",
    "text": "Concourse"
  },
  {
    "value": "confluent",
    "text": "Confluent"
  },
  {
    "value": "consul",
    "text": "Consul"
  },
  {
    "value": "consulting",
    "text": "Consulting"
  },
  {
    "value": "content-creation",
    "text": "Content Creation"
  },
  {
    "value": "content-management",
    "text": "Content Management"
  },
  {
    "value": "content-marketing",
    "text": "Content Marketing"
  },
  {
    "value": "content-writing",
    "text": "Content Writing"
  },
  {
    "value": "continuous-deployment",
    "text": "Continuous Deployment"
  },
  {
    "value": "continuous-integration",
    "text": "Continuous Integration"
  },
  {
    "value": "contract-manufacturing",
    "text": "Contract Manufacturing"
  },
  {
    "value": "conversion-rate-optimization",
    "text": "Conversion Rate Optimization"
  },
  {
    "value": "convolutional-neural-network",
    "text": "Convolutional Neural Network"
  },
  {
    "value": "coq",
    "text": "Coq"
  },
  {
    "value": "cordova",
    "text": "Cordova"
  },
  {
    "value": "cost-optimization",
    "text": "Cost Optimization"
  },
  {
    "value": "couchdb",
    "text": "CouchDB"
  },
  {
    "value": "coveralls",
    "text": "Coveralls"
  },
  {
    "value": "cpp-unit",
    "text": "CppUnit"
  },
  {
    "value": "creative-writing",
    "text": "Creative Writing"
  },
  {
    "value": "cron",
    "text": "Cron"
  },
  {
    "value": "cross-compiling",
    "text": "Cross Compiling"
  },
  {
    "value": "cross-platform",
    "text": "Cross Platform"
  },
  {
    "value": "crypto-currency",
    "text": "Crypto Currency"
  },
  {
    "value": "cryptography",
    "text": "Cryptography"
  },
  {
    "value": "crystal",
    "text": "Crystal"
  },
  {
    "value": "crystal-reports",
    "text": "Crystal Reports"
  },
  {
    "value": "cucumber",
    "text": "Cucumber"
  },
  {
    "value": "cuda",
    "text": "Cuda"
  },
  {
    "value": "curl",
    "text": "Curl"
  },
  {
    "value": "customer-billing",
    "text": "Customer Billing"
  },
  {
    "value": "customer-experience",
    "text": "Customer Experience"
  },
  {
    "value": "customer-success",
    "text": "Customer Success"
  },
  {
    "value": "cypress",
    "text": "Cypress"
  },
  {
    "value": "cython",
    "text": "Cython"
  },
  {
    "value": "d",
    "text": "D"
  },
  {
    "value": "d3.js",
    "text": "D3"
  },
  {
    "value": "db2",
    "text": "DB2"
  },
  {
    "value": "domain-driven-development",
    "text": "DDD"
  },
  {
    "value": "dhcp",
    "text": "DHCP"
  },
  {
    "value": "dhtml",
    "text": "DHTML"
  },
  {
    "value": "dll",
    "text": "DLL"
  },
  {
    "value": "dns",
    "text": "DNS"
  },
  {
    "value": "dagger2",
    "text": "Dagger2"
  },
  {
    "value": "dart",
    "text": "Dart"
  },
  {
    "value": "data-analysis",
    "text": "Data Analysis"
  },
  {
    "value": "data-cleaning",
    "text": "Data Cleaning"
  },
  {
    "value": "data-engineering",
    "text": "Data Engineering"
  },
  {
    "value": "data-gathering",
    "text": "Data Gathering"
  },
  {
    "value": "data-mining",
    "text": "Data Mining"
  },
  {
    "value": "data-modeling",
    "text": "Data Modeling"
  },
  {
    "value": "data-processing",
    "text": "Data Processing"
  },
  {
    "value": "data-science",
    "text": "Data Science"
  },
  {
    "value": "data-structures",
    "text": "Data Structures"
  },
  {
    "value": "data-transformation",
    "text": "Data Transformation"
  },
  {
    "value": "data-visualization",
    "text": "Data Visualization"
  },
  {
    "value": "data-warehousing",
    "text": "Data Warehousing"
  },
  {
    "value": "database",
    "text": "Database"
  },
  {
    "value": "dataiku",
    "text": "Dataiku"
  },
  {
    "value": "debian",
    "text": "Debian"
  },
  {
    "value": "decisional-informatics",
    "text": "Decisional informatics"
  },
  {
    "value": "deep-learning",
    "text": "Deep Learning"
  },
  {
    "value": "delphi",
    "text": "Delphi"
  },
  {
    "value": "design",
    "text": "Design"
  },
  {
    "value": "design-patterns",
    "text": "Design Patterns"
  },
  {
    "value": "devangelist",
    "text": "DevAngelist"
  },
  {
    "value": "devops",
    "text": "DevOps"
  },
  {
    "value": "developer-advocate",
    "text": "Developer Advocate"
  },
  {
    "value": "device-drivers",
    "text": "Device Drivers"
  },
  {
    "value": "digest-auth",
    "text": "Digest Auth"
  },
  {
    "value": "digital-ocean",
    "text": "Digital Ocean"
  },
  {
    "value": "dimensionality-reduction",
    "text": "Dimensionality Reduction"
  },
  {
    "value": "direct-x",
    "text": "DirectX"
  },
  {
    "value": "django",
    "text": "Django"
  },
  {
    "value": "docker",
    "text": "Docker"
  },
  {
    "value": "dockerfile",
    "text": "Dockerfile"
  },
  {
    "value": "doctrine",
    "text": "Doctrine"
  },
  {
    "value": "domotics",
    "text": "Domotics"
  },
  {
    "value": "dreamweaver",
    "text": "Dreamweaver"
  },
  {
    "value": "drill",
    "text": "Drill"
  },
  {
    "value": "droopal",
    "text": "Droopal"
  },
  {
    "value": "dropwizard",
    "text": "Dropwizard"
  },
  {
    "value": "druid",
    "text": "Druid"
  },
  {
    "value": "drupal",
    "text": "Drupal"
  },
  {
    "value": "dust",
    "text": "Dust"
  },
  {
    "value": "dynamodb",
    "text": "DynamoDB"
  },
  {
    "value": "ecmascript",
    "text": "ECMAScript"
  },
  {
    "value": "ecmascript6",
    "text": "ECMAScript6"
  },
  {
    "value": "ejb",
    "text": "EJB"
  },
  {
    "value": "elk",
    "text": "ELK"
  },
  {
    "value": "elm",
    "text": "ELM"
  },
  {
    "value": "erp",
    "text": "ERP"
  },
  {
    "value": "es7",
    "text": "ES7"
  },
  {
    "value": "eslint",
    "text": "ESLint"
  },
  {
    "value": "etl",
    "text": "ETL"
  },
  {
    "value": "extremedb",
    "text": "EXtremeDB"
  },
  {
    "value": "eagle",
    "text": "Eagle"
  },
  {
    "value": "easymock",
    "text": "EasyMock"
  },
  {
    "value": "eclipse",
    "text": "Eclipse"
  },
  {
    "value": "eclipselink",
    "text": "EclipseLink"
  },
  {
    "value": "ecommerce",
    "text": "Ecommerce"
  },
  {
    "value": "elastic-beanstalk",
    "text": "Elastic Beanstalk"
  },
  {
    "value": "elastic-search",
    "text": "Elasticsearch"
  },
  {
    "value": "electronics",
    "text": "Electronics"
  },
  {
    "value": "elixir",
    "text": "Elixir"
  },
  {
    "value": "emacs",
    "text": "Emacs"
  },
  {
    "value": "embedded-systems",
    "text": "Embedded Systems"
  },
  {
    "value": "ember.js",
    "text": "Ember"
  },
  {
    "value": "ensemble-models",
    "text": "Ensemble Models"
  },
  {
    "value": "entity-framework",
    "text": "Entity Framework"
  },
  {
    "value": "entrepreneurship",
    "text": "Entrepreneurship"
  },
  {
    "value": "erlang",
    "text": "Erlang"
  },
  {
    "value": "etherium",
    "text": "Etherium"
  },
  {
    "value": "evangelist",
    "text": "Evangelist"
  },
  {
    "value": "event-planning",
    "text": "Event Planning"
  },
  {
    "value": "exim",
    "text": "Exim"
  },
  {
    "value": "express.js",
    "text": "Express"
  },
  {
    "value": "extreme-programming",
    "text": "Extreme Programming"
  },
  {
    "value": "fsharp",
    "text": "F#"
  },
  {
    "value": "fea",
    "text": "FEA"
  },
  {
    "value": "ffmpg",
    "text": "FFMPG"
  },
  {
    "value": "flash",
    "text": "FLash"
  },
  {
    "value": "fpga",
    "text": "FPGA"
  },
  {
    "value": "facebook-ads",
    "text": "Facebook Ads"
  },
  {
    "value": "feathers",
    "text": "Feathers"
  },
  {
    "value": "fedora",
    "text": "Fedora"
  },
  {
    "value": "figma",
    "text": "Figma"
  },
  {
    "value": "final-cut-pro",
    "text": "Final Cut Pro"
  },
  {
    "value": "finance",
    "text": "Finance"
  },
  {
    "value": "firebase",
    "text": "Firebase"
  },
  {
    "value": "firmware",
    "text": "Firmware"
  },
  {
    "value": "fitnesse",
    "text": "FitNesse"
  },
  {
    "value": "fivetran",
    "text": "Fivetran"
  },
  {
    "value": "flask",
    "text": "Flask"
  },
  {
    "value": "flex",
    "text": "Flex"
  },
  {
    "value": "flink",
    "text": "Flink"
  },
  {
    "value": "fluid-mechanics",
    "text": "Fluid Mechanics"
  },
  {
    "value": "flume",
    "text": "Flume"
  },
  {
    "value": "flux",
    "text": "Flux"
  },
  {
    "value": "form-auth",
    "text": "Form Auth"
  },
  {
    "value": "formal-calculus",
    "text": "Formal Calculus"
  },
  {
    "value": "fortran",
    "text": "Fortran"
  },
  {
    "value": "freebsd",
    "text": "FreeBSD"
  },
  {
    "value": "web-frontend",
    "text": "Front-End"
  },
  {
    "value": "web-frontend-js",
    "text": "Front-End-Js"
  },
  {
    "value": "full-stack",
    "text": "Fullstack"
  },
  {
    "value": "functional-programming",
    "text": "Functional Programming"
  },
  {
    "value": "fusion360",
    "text": "Fusion360"
  },
  {
    "value": "gdb",
    "text": "GDB"
  },
  {
    "value": "gdscript",
    "text": "GDScript"
  },
  {
    "value": "gems",
    "text": "GEMS"
  },
  {
    "value": "gimp",
    "text": "GIMP"
  },
  {
    "value": "glsl",
    "text": "GLSL"
  },
  {
    "value": "gnu",
    "text": "GNU"
  },
  {
    "value": "gpu-programming",
    "text": "GPU Programming"
  },
  {
    "value": "graphical-user-interface",
    "text": "GUI"
  },
  {
    "value": "gui-testing",
    "text": "GUI Testing"
  },
  {
    "value": "gwt",
    "text": "GWT"
  },
  {
    "value": "gallina",
    "text": "Gallina"
  },
  {
    "value": "game-design",
    "text": "Game Design"
  },
  {
    "value": "game-programming",
    "text": "Game Programming"
  },
  {
    "value": "game-theory",
    "text": "Game Theory"
  },
  {
    "value": "ganglia",
    "text": "Ganglia"
  },
  {
    "value": "gantt",
    "text": "Gantt"
  },
  {
    "value": "general-purpose-programming",
    "text": "General Purpose Programming"
  },
  {
    "value": "gephi",
    "text": "Gephi"
  },
  {
    "value": "gerrit",
    "text": "Gerrit"
  },
  {
    "value": "giraph",
    "text": "Giraph"
  },
  {
    "value": "git",
    "text": "Git"
  },
  {
    "value": "github",
    "text": "GitHub"
  },
  {
    "value": "gitlab",
    "text": "Gitlab"
  },
  {
    "value": "go",
    "text": "Go"
  },
  {
    "value": "google-adwords",
    "text": "Google Adwords"
  },
  {
    "value": "google-analytics",
    "text": "Google Analytics"
  },
  {
    "value": "google-app-engine",
    "text": "Google App Engine"
  },
  {
    "value": "google-cloud",
    "text": "Google Cloud"
  },
  {
    "value": "google-compute-engine",
    "text": "Google Compute Engine"
  },
  {
    "value": "google-tag-manager",
    "text": "Google Tag Manager"
  },
  {
    "value": "google-test",
    "text": "Google Test"
  },
  {
    "value": "gradle",
    "text": "Gradle"
  },
  {
    "value": "grafana",
    "text": "Grafana"
  },
  {
    "value": "grails",
    "text": "Grails"
  },
  {
    "value": "graph-database",
    "text": "Graph Database"
  },
  {
    "value": "graphql",
    "text": "GraphQL"
  },
  {
    "value": "groovy",
    "text": "Groovy"
  },
  {
    "value": "growth-hacking",
    "text": "Growth Hacking"
  },
  {
    "value": "grunt",
    "text": "Grunt"
  },
  {
    "value": "gulp.js",
    "text": "Gulp"
  },
  {
    "value": "haml",
    "text": "HAML"
  },
  {
    "value": "hapi",
    "text": "HAPI"
  },
  {
    "value": "haproxy",
    "text": "HAProxy"
  },
  {
    "value": "hbase",
    "text": "HBase"
  },
  {
    "value": "hcatalog",
    "text": "HCatalog"
  },
  {
    "value": "hdfs",
    "text": "HDFS"
  },
  {
    "value": "html",
    "text": "HTML"
  },
  {
    "value": "html5",
    "text": "HTML5"
  },
  {
    "value": "http",
    "text": "HTTP"
  },
  {
    "value": "hadoop",
    "text": "Hadoop"
  },
  {
    "value": "hama",
    "text": "Hama"
  },
  {
    "value": "hamcrest",
    "text": "Hamcrest"
  },
  {
    "value": "happiness-management",
    "text": "Happiness Management"
  },
  {
    "value": "hardware-architecture",
    "text": "Hardware Architecture"
  },
  {
    "value": "hardware-software-codesign",
    "text": "Hardware Software Codesign"
  },
  {
    "value": "haskell",
    "text": "Haskell"
  },
  {
    "value": "haxe",
    "text": "Haxe"
  },
  {
    "value": "health-level-7",
    "text": "Health Level 7"
  },
  {
    "value": "helpdesk",
    "text": "Helpdesk"
  },
  {
    "value": "heroku",
    "text": "Heroku"
  },
  {
    "value": "heuristic-evaluation",
    "text": "Heuristic Evaluation"
  },
  {
    "value": "hibernate",
    "text": "Hibernate"
  },
  {
    "value": "high-performance-computing",
    "text": "High Performance Computing"
  },
  {
    "value": "hive",
    "text": "Hive"
  },
  {
    "value": "hockey-app",
    "text": "Hockey App"
  },
  {
    "value": "hornetq",
    "text": "HornetQ"
  },
  {
    "value": "hortonworks",
    "text": "Hortonworks"
  },
  {
    "value": "hotjar",
    "text": "Hotjar"
  },
  {
    "value": "hubspot",
    "text": "HubSpot"
  },
  {
    "value": "hudson",
    "text": "Hudson"
  },
  {
    "value": "human-computer-interaction",
    "text": "Human Computer Interaction"
  },
  {
    "value": "human-ressources",
    "text": "Human Resources"
  },
  {
    "value": "hybrid-application",
    "text": "Hybrid Application"
  },
  {
    "value": "hybrid-development",
    "text": "Hybrid Development"
  },
  {
    "value": "hyper-v",
    "text": "Hyper-V"
  },
  {
    "value": "hypertable",
    "text": "Hypertable"
  },
  {
    "value": "ibm-db2",
    "text": "IBM Db2"
  },
  {
    "value": "ide",
    "text": "IDE"
  },
  {
    "value": "iso-standardization",
    "text": "ISO standardization"
  },
  {
    "value": "itil",
    "text": "ITIL"
  },
  {
    "value": "iwa",
    "text": "IWA"
  },
  {
    "value": "iccube",
    "text": "IcCube"
  },
  {
    "value": "icinga",
    "text": "Icinga"
  },
  {
    "value": "idris",
    "text": "Idris"
  },
  {
    "value": "illustration",
    "text": "Illustration"
  },
  {
    "value": "illustrator",
    "text": "Illustrator"
  },
  {
    "value": "image-processing",
    "text": "Image Processing"
  },
  {
    "value": "impala",
    "text": "Impala"
  },
  {
    "value": "imperative-programming",
    "text": "Imperative Programming"
  },
  {
    "value": "indesign",
    "text": "InDesign"
  },
  {
    "value": "invision",
    "text": "InVision"
  },
  {
    "value": "influxdb",
    "text": "InfluxDB"
  },
  {
    "value": "informatica",
    "text": "Informatica"
  },
  {
    "value": "informix",
    "text": "Informix"
  },
  {
    "value": "integration-testing",
    "text": "Integration Testing"
  },
  {
    "value": "integrator",
    "text": "Integrator"
  },
  {
    "value": "intellij",
    "text": "Intellij"
  },
  {
    "value": "internet-of-things",
    "text": "IoT"
  },
  {
    "value": "ionic",
    "text": "Ionic"
  },
  {
    "value": "istio",
    "text": "Istio"
  },
  {
    "value": "java-enterprise-edition",
    "text": "J2EE"
  },
  {
    "value": "j2ee-web-services",
    "text": "J2EE Web Services"
  },
  {
    "value": "jaxb",
    "text": "JAXB"
  },
  {
    "value": "jbehave",
    "text": "JBehave"
  },
  {
    "value": "jdbc",
    "text": "JDBC"
  },
  {
    "value": "jdm",
    "text": "JDM"
  },
  {
    "value": "jdave",
    "text": "JDave"
  },
  {
    "value": "jms",
    "text": "JMS"
  },
  {
    "value": "jmeter",
    "text": "JMeter"
  },
  {
    "value": "jpa",
    "text": "JPA"
  },
  {
    "value": "jsf",
    "text": "JSF"
  },
  {
    "value": "json",
    "text": "JSON"
  },
  {
    "value": "jsp",
    "text": "JSP"
  },
  {
    "value": "jsunit",
    "text": "JSUnit"
  },
  {
    "value": "jtag",
    "text": "JTAG"
  },
  {
    "value": "junit",
    "text": "JUnit"
  },
  {
    "value": "jwt",
    "text": "JWT"
  },
  {
    "value": "jalios",
    "text": "Jalios"
  },
  {
    "value": "jasmine",
    "text": "Jasmine"
  },
  {
    "value": "jasper-reports",
    "text": "Jasper Reports"
  },
  {
    "value": "java",
    "text": "Java"
  },
  {
    "value": "java-virtual-machine",
    "text": "Java Virtual Machine"
  },
  {
    "value": "javafx",
    "text": "JavaFX"
  },
  {
    "value": "javascript",
    "text": "Javascript"
  },
  {
    "value": "jedox",
    "text": "Jedox"
  },
  {
    "value": "jelix",
    "text": "Jelix"
  },
  {
    "value": "jena",
    "text": "Jena"
  },
  {
    "value": "jenkins",
    "text": "Jenkins"
  },
  {
    "value": "jest",
    "text": "Jest"
  },
  {
    "value": "jira",
    "text": "Jira"
  },
  {
    "value": "joomla",
    "text": "Joomla"
  },
  {
    "value": "jscript",
    "text": "Jscript"
  },
  {
    "value": "julia",
    "text": "Julia"
  },
  {
    "value": "jupyter-notebook",
    "text": "Jupyter Notebook"
  },
  {
    "value": "knime",
    "text": "KNIME"
  },
  {
    "value": "kafka",
    "text": "Kafka"
  },
  {
    "value": "kaggle",
    "text": "Kaggle"
  },
  {
    "value": "kanban",
    "text": "Kanban"
  },
  {
    "value": "karaf",
    "text": "Karaf"
  },
  {
    "value": "karma",
    "text": "Karma"
  },
  {
    "value": "katalon",
    "text": "Katalon"
  },
  {
    "value": "keras",
    "text": "Keras"
  },
  {
    "value": "kettle",
    "text": "Kettle"
  },
  {
    "value": "key-account",
    "text": "Key Account"
  },
  {
    "value": "kibana",
    "text": "Kibana"
  },
  {
    "value": "kicad",
    "text": "Kicad"
  },
  {
    "value": "knockout.js",
    "text": "Knockout"
  },
  {
    "value": "koa",
    "text": "Koa"
  },
  {
    "value": "kotlin",
    "text": "Kotlin"
  },
  {
    "value": "kpi",
    "text": "Kpi"
  },
  {
    "value": "kubernetes",
    "text": "Kubernetes"
  },
  {
    "value": "kudu",
    "text": "Kudu"
  },
  {
    "value": "kurento",
    "text": "Kurento"
  },
  {
    "value": "kylin",
    "text": "Kylin"
  },
  {
    "value": "lamp",
    "text": "LAMP"
  },
  {
    "value": "ldap",
    "text": "LDAP"
  },
  {
    "value": "less",
    "text": "LESS"
  },
  {
    "value": "llvm",
    "text": "LLVM"
  },
  {
    "value": "lstm",
    "text": "LSTM"
  },
  {
    "value": "lxc",
    "text": "LXC"
  },
  {
    "value": "latex",
    "text": "LaTeX"
  },
  {
    "value": "laravel",
    "text": "Laravel"
  },
  {
    "value": "lead-dev",
    "text": "Lead Dev"
  },
  {
    "value": "lead-generation",
    "text": "Lead Generation"
  },
  {
    "value": "leaflet",
    "text": "Leaflet"
  },
  {
    "value": "lean-methodology",
    "text": "Lean Methodology"
  },
  {
    "value": "leveldb",
    "text": "LevelDB"
  },
  {
    "value": "libra",
    "text": "Libra"
  },
  {
    "value": "linkedin-ads",
    "text": "Linkedin Ads"
  },
  {
    "value": "linq",
    "text": "Linq"
  },
  {
    "value": "linux",
    "text": "Linux"
  },
  {
    "value": "lisp",
    "text": "Lisp"
  },
  {
    "value": "livescript",
    "text": "LiveScript"
  },
  {
    "value": "load-balancing",
    "text": "Load Balancing"
  },
  {
    "value": "lodash",
    "text": "Lodash"
  },
  {
    "value": "logic-programming",
    "text": "Logic Programming"
  },
  {
    "value": "logo-design",
    "text": "Logo Design"
  },
  {
    "value": "logstash",
    "text": "Logstash"
  },
  {
    "value": "looker",
    "text": "Looker"
  },
  {
    "value": "low-level-programming",
    "text": "Low Level Programming"
  },
  {
    "value": "lua",
    "text": "Lua"
  },
  {
    "value": "lucene",
    "text": "Lucene"
  },
  {
    "value": "m_a",
    "text": "M&A"
  },
  {
    "value": "mamp",
    "text": "MAMP"
  },
  {
    "value": "mba",
    "text": "MBA"
  },
  {
    "value": "mvc",
    "text": "MVC"
  },
  {
    "value": "mvvc",
    "text": "MVVC"
  },
  {
    "value": "mvvm",
    "text": "MVVM"
  },
  {
    "value": "macos-development",
    "text": "Mac OS"
  },
  {
    "value": "mac-server",
    "text": "Mac Server"
  },
  {
    "value": "machine-learning",
    "text": "Machine Learning"
  },
  {
    "value": "machining",
    "text": "Machining"
  },
  {
    "value": "magento",
    "text": "Magento"
  },
  {
    "value": "mahout",
    "text": "Mahout"
  },
  {
    "value": "mail-transfer-agent",
    "text": "Mail Transfer Agent"
  },
  {
    "value": "mailchimp",
    "text": "Mailchimp"
  },
  {
    "value": "make",
    "text": "Make"
  },
  {
    "value": "management",
    "text": "Management"
  },
  {
    "value": "management-information-systems",
    "text": "Management Information Systems"
  },
  {
    "value": "map-reduce",
    "text": "MapReduce"
  },
  {
    "value": "maple",
    "text": "Maple"
  },
  {
    "value": "mariadb",
    "text": "MariaDB"
  },
  {
    "value": "marionette.js",
    "text": "Marionette"
  },
  {
    "value": "market-research",
    "text": "Market Research"
  },
  {
    "value": "marketing",
    "text": "Marketing"
  },
  {
    "value": "marketing-automation",
    "text": "Marketing Automation"
  },
  {
    "value": "marvel",
    "text": "Marvel"
  },
  {
    "value": "matlab",
    "text": "MatLab"
  },
  {
    "value": "material-design",
    "text": "Material Design"
  },
  {
    "value": "mathematica",
    "text": "Mathematica"
  },
  {
    "value": "mathematics",
    "text": "Mathematics"
  },
  {
    "value": "mathematical-modeling",
    "text": "Mathematics Modeling"
  },
  {
    "value": "matillion",
    "text": "Matillion"
  },
  {
    "value": "maven",
    "text": "Maven"
  },
  {
    "value": "maya",
    "text": "Maya"
  },
  {
    "value": "mechanical-engineering",
    "text": "Mechanical Engineering"
  },
  {
    "value": "mechatronics",
    "text": "Mechatronics"
  },
  {
    "value": "medical-imaging",
    "text": "Medical Imaging"
  },
  {
    "value": "memcached",
    "text": "Memcached"
  },
  {
    "value": "merb",
    "text": "Merb"
  },
  {
    "value": "mercurial",
    "text": "Mercurial"
  },
  {
    "value": "merise",
    "text": "Merise"
  },
  {
    "value": "mesos",
    "text": "Mesos"
  },
  {
    "value": "message-broker",
    "text": "Message Broker"
  },
  {
    "value": "metal",
    "text": "Metal"
  },
  {
    "value": "meteor.js",
    "text": "Meteor"
  },
  {
    "value": "methodology",
    "text": "Methodology"
  },
  {
    "value": "microcontrollers",
    "text": "Microcontrollers"
  },
  {
    "value": "microservices",
    "text": "Microservices"
  },
  {
    "value": "microsoft-dynamics",
    "text": "Microsoft Dynamics"
  },
  {
    "value": "microsoft-excel",
    "text": "Microsoft Excel"
  },
  {
    "value": "microsoft-office",
    "text": "Microsoft Office"
  },
  {
    "value": "microsoft-project",
    "text": "Microsoft Project"
  },
  {
    "value": "microsoft-sql-server",
    "text": "Microsoft SQL Server"
  },
  {
    "value": "microsoft-visio",
    "text": "Microsoft Visio"
  },
  {
    "value": "microsoft-windows-sdk",
    "text": "Microsoft Windows SDK"
  },
  {
    "value": "microsoft-word",
    "text": "Microsoft Word"
  },
  {
    "value": "mind-mapping",
    "text": "Mind Mapping"
  },
  {
    "value": "mixpanel",
    "text": "MixPanel"
  },
  {
    "value": "mobile-3d-graphics",
    "text": "Mobile 3D Graphics"
  },
  {
    "value": "mobile-development",
    "text": "Mobile Development"
  },
  {
    "value": "mocha",
    "text": "Mocha"
  },
  {
    "value": "mockito",
    "text": "Mockito"
  },
  {
    "value": "modeling-language",
    "text": "Modeling Language"
  },
  {
    "value": "monetdb",
    "text": "MonetDB"
  },
  {
    "value": "mongodb",
    "text": "MongoDB"
  },
  {
    "value": "mootools",
    "text": "Mootools"
  },
  {
    "value": "moqups",
    "text": "Moqups"
  },
  {
    "value": "multi-server-deployment",
    "text": "Multi Server Deployment"
  },
  {
    "value": "mustache.js",
    "text": "Mustache"
  },
  {
    "value": "mysql",
    "text": "MySQL"
  },
  {
    "value": "m_trologie",
    "text": "Métrologie"
  },
  {
    "value": "nas",
    "text": "NAS"
  },
  {
    "value": "natural-language-processing",
    "text": "NLP"
  },
  {
    "value": "nlp-technics",
    "text": "NLP Technics"
  },
  {
    "value": "nnmf",
    "text": "NNMF"
  },
  {
    "value": "nunit",
    "text": "NUnit"
  },
  {
    "value": "nunit-asp",
    "text": "NUnit ASP"
  },
  {
    "value": "nvd3.js",
    "text": "NVD3.js"
  },
  {
    "value": "nw.js",
    "text": "NW"
  },
  {
    "value": "nagios",
    "text": "Nagios"
  },
  {
    "value": "negotiation",
    "text": "Negotiation"
  },
  {
    "value": "neo4j",
    "text": "Neo4j"
  },
  {
    "value": "nestjs",
    "text": "NestJS"
  },
  {
    "value": "netbeans",
    "text": "NetBeans"
  },
  {
    "value": "network-security",
    "text": "Network Security"
  },
  {
    "value": "neural-network",
    "text": "Neural Network"
  },
  {
    "value": "new-relic",
    "text": "New Relic"
  },
  {
    "value": "newsletter",
    "text": "Newsletter"
  },
  {
    "value": "next-js",
    "text": "Next Js"
  },
  {
    "value": "nginx",
    "text": "Nginx"
  },
  {
    "value": "nifi",
    "text": "NiFi"
  },
  {
    "value": "nimrod",
    "text": "Nimrod"
  },
  {
    "value": "nosql",
    "text": "NoSQL"
  },
  {
    "value": "node.js",
    "text": "Node"
  },
  {
    "value": "nomad",
    "text": "Nomad"
  },
  {
    "value": "not-job-seeking",
    "text": "Not Job Seeking"
  },
  {
    "value": "nuclear-engineering",
    "text": "Nuclear Engineering"
  },
  {
    "value": "numpy",
    "text": "NumPy"
  },
  {
    "value": "oauth",
    "text": "OAuth"
  },
  {
    "value": "ocunit",
    "text": "OCUnit"
  },
  {
    "value": "ocaml",
    "text": "OCaml"
  },
  {
    "value": "object-oriented-design",
    "text": "OOD"
  },
  {
    "value": "object-oriented-programming",
    "text": "OOP"
  },
  {
    "value": "osgi",
    "text": "OSGI"
  },
  {
    "value": "ovh",
    "text": "OVH"
  },
  {
    "value": "object-relational-mapping",
    "text": "Object Relational Mapping"
  },
  {
    "value": "objective-j",
    "text": "Objective J"
  },
  {
    "value": "objective-c",
    "text": "Objective-C"
  },
  {
    "value": "octave",
    "text": "Octave"
  },
  {
    "value": "octopus-deploy",
    "text": "Octopus Deploy"
  },
  {
    "value": "oculus",
    "text": "Oculus"
  },
  {
    "value": "office-management",
    "text": "Office Management"
  },
  {
    "value": "onboarding",
    "text": "Onboarding"
  },
  {
    "value": "oozie",
    "text": "Oozie"
  },
  {
    "value": "open-source",
    "text": "Open Source"
  },
  {
    "value": "openam",
    "text": "OpenAM"
  },
  {
    "value": "opencv",
    "text": "OpenCV"
  },
  {
    "value": "opengl",
    "text": "OpenGL"
  },
  {
    "value": "openid",
    "text": "OpenID"
  },
  {
    "value": "opennms",
    "text": "OpenNMS"
  },
  {
    "value": "openscad",
    "text": "OpenSCAD"
  },
  {
    "value": "openshift",
    "text": "OpenShift"
  },
  {
    "value": "openstack",
    "text": "OpenStack"
  },
  {
    "value": "openui5",
    "text": "OpenUI5"
  },
  {
    "value": "openvpn",
    "text": "OpenVPN"
  },
  {
    "value": "openlayers",
    "text": "Openlayers"
  },
  {
    "value": "operating-systems",
    "text": "Operating Systems"
  },
  {
    "value": "operational-research",
    "text": "Operational Research"
  },
  {
    "value": "operations",
    "text": "Operations"
  },
  {
    "value": "optimization",
    "text": "Optimization"
  },
  {
    "value": "oracle-database",
    "text": "Oracle Database"
  },
  {
    "value": "order-management",
    "text": "Order Management"
  },
  {
    "value": "organization-development",
    "text": "Organization Development"
  },
  {
    "value": "orientdb",
    "text": "OrientDB"
  },
  {
    "value": "pca",
    "text": "PCA"
  },
  {
    "value": "pcb-design",
    "text": "PCB design"
  },
  {
    "value": "pcba",
    "text": "PCBA"
  },
  {
    "value": "php",
    "text": "PHP"
  },
  {
    "value": "php-extension",
    "text": "PHP Extension"
  },
  {
    "value": "php-template-engine",
    "text": "PHP Template Engine"
  },
  {
    "value": "php-unit",
    "text": "PHP Unit"
  },
  {
    "value": "php4",
    "text": "PHP4"
  },
  {
    "value": "php5",
    "text": "PHP5"
  },
  {
    "value": "php6",
    "text": "PHP6"
  },
  {
    "value": "php7",
    "text": "PHP7"
  },
  {
    "value": "packer",
    "text": "Packer"
  },
  {
    "value": "pair-programming",
    "text": "Pair Programming"
  },
  {
    "value": "pandas",
    "text": "Pandas"
  },
  {
    "value": "parallel-computing",
    "text": "Parallel Computing"
  },
  {
    "value": "parquet",
    "text": "Parquet"
  },
  {
    "value": "pascal",
    "text": "Pascal"
  },
  {
    "value": "pattern-recognition",
    "text": "Pattern Recognition"
  },
  {
    "value": "pedagogy",
    "text": "Pedagogy"
  },
  {
    "value": "penetration-testing",
    "text": "Penetration Testing"
  },
  {
    "value": "pentaho",
    "text": "Pentaho"
  },
  {
    "value": "perforce",
    "text": "Perforce"
  },
  {
    "value": "perl",
    "text": "Perl"
  },
  {
    "value": "phd",
    "text": "PhD"
  },
  {
    "value": "phantom.js",
    "text": "Phantom"
  },
  {
    "value": "phoenix",
    "text": "Phoenix"
  },
  {
    "value": "photography",
    "text": "Photography"
  },
  {
    "value": "photoshop",
    "text": "Photoshop"
  },
  {
    "value": "physics",
    "text": "Physics"
  },
  {
    "value": "pig",
    "text": "Pig"
  },
  {
    "value": "planisware",
    "text": "Planisware"
  },
  {
    "value": "play-framework",
    "text": "Play Framework"
  },
  {
    "value": "posix",
    "text": "Posix"
  },
  {
    "value": "postscript",
    "text": "PostScript"
  },
  {
    "value": "postfix",
    "text": "Postfix"
  },
  {
    "value": "postgresql",
    "text": "PostgreSQL"
  },
  {
    "value": "postman",
    "text": "Postman"
  },
  {
    "value": "powerbi",
    "text": "PowerBI"
  },
  {
    "value": "powerpoint",
    "text": "PowerPoint"
  },
  {
    "value": "powershell",
    "text": "PowerShell"
  },
  {
    "value": "preactjs",
    "text": "PreactJS"
  },
  {
    "value": "predictive-marketing",
    "text": "Predictive Marketing"
  },
  {
    "value": "press-relations",
    "text": "Press Relations"
  },
  {
    "value": "prestashop",
    "text": "Prestashop"
  },
  {
    "value": "presto",
    "text": "Presto"
  },
  {
    "value": "primefaces",
    "text": "PrimeFaces"
  },
  {
    "value": "principle",
    "text": "Principle"
  },
  {
    "value": "printed-circuit-board",
    "text": "Printed Circuit Board"
  },
  {
    "value": "problem-solving",
    "text": "Problem Solving"
  },
  {
    "value": "product-costing",
    "text": "Product Costing"
  },
  {
    "value": "product-design",
    "text": "Product Design"
  },
  {
    "value": "product-forecasting",
    "text": "Product Forecasting"
  },
  {
    "value": "product-management",
    "text": "Product Management"
  },
  {
    "value": "product-strategy",
    "text": "Product Strategy"
  },
  {
    "value": "production-planning",
    "text": "Production Planning"
  },
  {
    "value": "programmable-editor",
    "text": "Programmable Editor"
  },
  {
    "value": "project-management",
    "text": "Project Management"
  },
  {
    "value": "prolog",
    "text": "Prolog"
  },
  {
    "value": "prometheus",
    "text": "Prometheus"
  },
  {
    "value": "promotional-marketing",
    "text": "Promotional Marketing"
  },
  {
    "value": "protocol-web",
    "text": "Protocol Web"
  },
  {
    "value": "public-speaking",
    "text": "Public Speaking"
  },
  {
    "value": "puppet",
    "text": "Puppet"
  },
  {
    "value": "purescript",
    "text": "Purescript"
  },
  {
    "value": "pycaffe",
    "text": "PyCaffe"
  },
  {
    "value": "pytorch",
    "text": "PyTorch"
  },
  {
    "value": "python",
    "text": "Python"
  },
  {
    "value": "python-extension",
    "text": "Python Extension"
  },
  {
    "value": "quality-assurance",
    "text": "QA"
  },
  {
    "value": "qml",
    "text": "QML"
  },
  {
    "value": "qmake",
    "text": "QMake"
  },
  {
    "value": "qunit",
    "text": "QUnit"
  },
  {
    "value": "qliksense",
    "text": "QlikSense"
  },
  {
    "value": "qlikview",
    "text": "QlikView"
  },
  {
    "value": "qpid",
    "text": "Qpid"
  },
  {
    "value": "qt",
    "text": "Qt"
  },
  {
    "value": "quantified-self",
    "text": "Quantified Self"
  },
  {
    "value": "R",
    "text": "R"
  },
  {
    "value": "r-and-d",
    "text": "R&D"
  },
  {
    "value": "rest",
    "text": "REST"
  },
  {
    "value": "rnn",
    "text": "RNN"
  },
  {
    "value": "rpc",
    "text": "RPC"
  },
  {
    "value": "rrdtool",
    "text": "RRDTool"
  },
  {
    "value": "rspec",
    "text": "RSpec"
  },
  {
    "value": "rabbitmq",
    "text": "RabbitMQ"
  },
  {
    "value": "rackspace",
    "text": "Rackspace"
  },
  {
    "value": "ramda",
    "text": "Ramda"
  },
  {
    "value": "ranorex",
    "text": "Ranorex"
  },
  {
    "value": "raphael.js",
    "text": "Raphael"
  },
  {
    "value": "rapidminer",
    "text": "RapidMiner"
  },
  {
    "value": "raspberry-pi",
    "text": "Raspberry Pi"
  },
  {
    "value": "react.js",
    "text": "React"
  },
  {
    "value": "react-native",
    "text": "React Native"
  },
  {
    "value": "real-time-bidding",
    "text": "Real-Time Bidding"
  },
  {
    "value": "realm",
    "text": "Realm"
  },
  {
    "value": "recommender-system",
    "text": "Recommender System"
  },
  {
    "value": "recruitment",
    "text": "Recruitment"
  },
  {
    "value": "redhat",
    "text": "Red Hat"
  },
  {
    "value": "redis",
    "text": "Redis"
  },
  {
    "value": "redmine",
    "text": "Redmine"
  },
  {
    "value": "redux.js",
    "text": "Redux"
  },
  {
    "value": "regression-models",
    "text": "Regression Models"
  },
  {
    "value": "reinforcement-learning",
    "text": "Reinforcement Learning"
  },
  {
    "value": "relational-database",
    "text": "Relational Database"
  },
  {
    "value": "resharper",
    "text": "Resharper"
  },
  {
    "value": "resource-management",
    "text": "Resource Management"
  },
  {
    "value": "responsive-design",
    "text": "Responsive Design"
  },
  {
    "value": "responsive-web-design",
    "text": "Responsive Web Design"
  },
  {
    "value": "restify",
    "text": "Restify"
  },
  {
    "value": "revenue-management",
    "text": "Revenue Management"
  },
  {
    "value": "riak",
    "text": "Riak"
  },
  {
    "value": "ripple",
    "text": "Ripple"
  },
  {
    "value": "risk-management",
    "text": "Risk Management"
  },
  {
    "value": "roadmapping",
    "text": "Roadmapping"
  },
  {
    "value": "robolectric",
    "text": "Robolectric"
  },
  {
    "value": "robotics",
    "text": "Robotics"
  },
  {
    "value": "robotium",
    "text": "Robotium"
  },
  {
    "value": "rocketmq",
    "text": "RocketMQ"
  },
  {
    "value": "ruby",
    "text": "Ruby"
  },
  {
    "value": "ruby-c",
    "text": "Ruby C"
  },
  {
    "value": "ruby-on-rails",
    "text": "Ruby on Rails"
  },
  {
    "value": "rundeck",
    "text": "Rundeck"
  },
  {
    "value": "rust",
    "text": "Rust"
  },
  {
    "value": "rxjava",
    "text": "RxJava"
  },
  {
    "value": "san",
    "text": "SAN"
  },
  {
    "value": "sap",
    "text": "SAP"
  },
  {
    "value": "sas",
    "text": "SAS"
  },
  {
    "value": "sdk",
    "text": "SDK"
  },
  {
    "value": "sea",
    "text": "SEA"
  },
  {
    "value": "search-engine-marketing",
    "text": "SEM"
  },
  {
    "value": "search-engine-optimization",
    "text": "SEO"
  },
  {
    "value": "smb",
    "text": "SMB"
  },
  {
    "value": "soa",
    "text": "SOA"
  },
  {
    "value": "sparql",
    "text": "SPARQL"
  },
  {
    "value": "spss",
    "text": "SPSS"
  },
  {
    "value": "sqf",
    "text": "SQF"
  },
  {
    "value": "sql",
    "text": "SQL"
  },
  {
    "value": "sqlalchemy",
    "text": "SQLAlchemy"
  },
  {
    "value": "sqlite",
    "text": "SQLite"
  },
  {
    "value": "srm",
    "text": "SRM"
  },
  {
    "value": "stl",
    "text": "STL"
  },
  {
    "value": "svg",
    "text": "SVG"
  },
  {
    "value": "sail.js",
    "text": "Sail"
  },
  {
    "value": "sales",
    "text": "Sales"
  },
  {
    "value": "sales-ops",
    "text": "Sales Ops"
  },
  {
    "value": "salesforce",
    "text": "Salesforce"
  },
  {
    "value": "salt",
    "text": "Salt"
  },
  {
    "value": "saml",
    "text": "Saml"
  },
  {
    "value": "sass",
    "text": "Sass"
  },
  {
    "value": "sax",
    "text": "Sax"
  },
  {
    "value": "scala",
    "text": "Scala"
  },
  {
    "value": "scheme",
    "text": "Scheme"
  },
  {
    "value": "scipy",
    "text": "SciPy"
  },
  {
    "value": "scientific-programming",
    "text": "Scientific Programming"
  },
  {
    "value": "scikit-learn",
    "text": "Scikit Learn"
  },
  {
    "value": "scilab",
    "text": "Scilab"
  },
  {
    "value": "scraping",
    "text": "Scraping"
  },
  {
    "value": "scraproxy",
    "text": "Scraproxy"
  },
  {
    "value": "scrapy",
    "text": "Scrapy"
  },
  {
    "value": "scripting",
    "text": "Scripting"
  },
  {
    "value": "scrum",
    "text": "Scrum"
  },
  {
    "value": "scrutinizer",
    "text": "Scrutinizer"
  },
  {
    "value": "scylla",
    "text": "Scylla"
  },
  {
    "value": "security",
    "text": "Security"
  },
  {
    "value": "selenium",
    "text": "Selenium"
  },
  {
    "value": "semantic-web",
    "text": "Semantic Web"
  },
  {
    "value": "semaphore",
    "text": "Semaphore"
  },
  {
    "value": "semi-conducteur",
    "text": "Semi Conducteur"
  },
  {
    "value": "sendmail",
    "text": "Sendmail"
  },
  {
    "value": "serialization",
    "text": "Serialization"
  },
  {
    "value": "server-automation",
    "text": "Server Automation"
  },
  {
    "value": "server-deployment",
    "text": "Server Deployment"
  },
  {
    "value": "server-stack",
    "text": "Server Stack"
  },
  {
    "value": "serverless",
    "text": "Serverless"
  },
  {
    "value": "servlets",
    "text": "Servlets"
  },
  {
    "value": "shape-recognition",
    "text": "Shape Recognition"
  },
  {
    "value": "sharepoint",
    "text": "SharePoint"
  },
  {
    "value": "shell",
    "text": "Shell"
  },
  {
    "value": "shopify",
    "text": "Shopify"
  },
  {
    "value": "siebel",
    "text": "Siebel"
  },
  {
    "value": "signal-processing",
    "text": "Signal Processing"
  },
  {
    "value": "silex",
    "text": "Silex"
  },
  {
    "value": "silverlight",
    "text": "Silverlight"
  },
  {
    "value": "simpler.js",
    "text": "Simplet"
  },
  {
    "value": "simulink",
    "text": "Simulink"
  },
  {
    "value": "sinatra",
    "text": "Sinatra"
  },
  {
    "value": "single-page-app",
    "text": "Single Page App"
  },
  {
    "value": "site-reliability-engineering",
    "text": "Site Reliability Engineering"
  },
  {
    "value": "sketch",
    "text": "Sketch"
  },
  {
    "value": "smalltalk",
    "text": "Smalltalk"
  },
  {
    "value": "smart-contracts",
    "text": "Smart Contracts"
  },
  {
    "value": "smart-home-devices",
    "text": "Smart Home Devices"
  },
  {
    "value": "smarty",
    "text": "Smarty"
  },
  {
    "value": "smelter",
    "text": "Smelter"
  },
  {
    "value": "snaplogic",
    "text": "SnapLogic"
  },
  {
    "value": "soap",
    "text": "Soap"
  },
  {
    "value": "soapui",
    "text": "SoapUI"
  },
  {
    "value": "social-media",
    "text": "Social Media"
  },
  {
    "value": "social-media-marketing",
    "text": "Social Media Marketing"
  },
  {
    "value": "social-media-optimization-",
    "text": "Social Media Optimization "
  },
  {
    "value": "socket.io",
    "text": "Socket.io"
  },
  {
    "value": "soft-layer",
    "text": "Soft Layer"
  },
  {
    "value": "software-architect",
    "text": "Software Architect"
  },
  {
    "value": "software-engineering",
    "text": "Software Engineering"
  },
  {
    "value": "software-management",
    "text": "Software Management"
  },
  {
    "value": "solaris",
    "text": "Solaris"
  },
  {
    "value": "solidworks",
    "text": "SolidWorks"
  },
  {
    "value": "solidity",
    "text": "Solidity"
  },
  {
    "value": "solr",
    "text": "Solr"
  },
  {
    "value": "solutions-engineer",
    "text": "Solutions Engineer"
  },
  {
    "value": "sonar-qube",
    "text": "SonarQube"
  },
  {
    "value": "spark",
    "text": "Spark"
  },
  {
    "value": "sparkr",
    "text": "SparkR"
  },
  {
    "value": "specifications",
    "text": "Specifications"
  },
  {
    "value": "speech-recognition",
    "text": "Speech Recognition"
  },
  {
    "value": "spotfire",
    "text": "Spotfire"
  },
  {
    "value": "spring",
    "text": "Spring"
  },
  {
    "value": "sqoop",
    "text": "Sqoop"
  },
  {
    "value": "squish",
    "text": "Squish"
  },
  {
    "value": "stata",
    "text": "Stata"
  },
  {
    "value": "statistics",
    "text": "Statistics"
  },
  {
    "value": "streamsets",
    "text": "StreamSets"
  },
  {
    "value": "striim",
    "text": "Striim"
  },
  {
    "value": "struts",
    "text": "Struts"
  },
  {
    "value": "svn",
    "text": "Subversion"
  },
  {
    "value": "swagger",
    "text": "Swagger"
  },
  {
    "value": "swift",
    "text": "Swift"
  },
  {
    "value": "swing",
    "text": "Swing"
  },
  {
    "value": "sybase",
    "text": "Sybase"
  },
  {
    "value": "symfony",
    "text": "Symfony"
  },
  {
    "value": "symfony2",
    "text": "Symfony 2"
  },
  {
    "value": "symfony3",
    "text": "Symfony 3"
  },
  {
    "value": "system-administration",
    "text": "System Administration"
  },
  {
    "value": "system-architecture",
    "text": "System Architecture"
  },
  {
    "value": "tcl",
    "text": "TCL"
  },
  {
    "value": "tcp",
    "text": "TCP"
  },
  {
    "value": "test-driven-development",
    "text": "TDD"
  },
  {
    "value": "tfs",
    "text": "TFS"
  },
  {
    "value": "tableau",
    "text": "Tableau"
  },
  {
    "value": "talend",
    "text": "Talend"
  },
  {
    "value": "teamcity",
    "text": "Teamcity"
  },
  {
    "value": "technical-support",
    "text": "Technical Support"
  },
  {
    "value": "technitool",
    "text": "Technitool"
  },
  {
    "value": "tensorboard",
    "text": "TensorBoard"
  },
  {
    "value": "tensorflow",
    "text": "Tensorflow"
  },
  {
    "value": "terraform",
    "text": "Terraform"
  },
  {
    "value": "testcomplete",
    "text": "TestComplete"
  },
  {
    "value": "testng",
    "text": "TestNG"
  },
  {
    "value": "text-mining",
    "text": "Text Mining"
  },
  {
    "value": "text-to-speech",
    "text": "Text to Speech"
  },
  {
    "value": "tez",
    "text": "Tez"
  },
  {
    "value": "thanos",
    "text": "Thanos"
  },
  {
    "value": "theano",
    "text": "Theano"
  },
  {
    "value": "three",
    "text": "Three"
  },
  {
    "value": "thumbor",
    "text": "Thumbor"
  },
  {
    "value": "thymeleaf",
    "text": "Thymeleaf"
  },
  {
    "value": "time-series-analysis",
    "text": "Time Series Analysis"
  },
  {
    "value": "tinytest",
    "text": "Tinytest"
  },
  {
    "value": "titanium",
    "text": "Titanium"
  },
  {
    "value": "tomcat",
    "text": "Tomcat"
  },
  {
    "value": "top-management",
    "text": "Top Management"
  },
  {
    "value": "tortoise-svn",
    "text": "TortoiseSVN"
  },
  {
    "value": "toucan-toco",
    "text": "Toucan Toco"
  },
  {
    "value": "transact-sql",
    "text": "Transact SQL"
  },
  {
    "value": "travis",
    "text": "Travis"
  },
  {
    "value": "trello",
    "text": "Trello"
  },
  {
    "value": "troubleshooting",
    "text": "TroubleShooting"
  },
  {
    "value": "twig",
    "text": "Twig"
  },
  {
    "value": "twilio.js",
    "text": "Twilio"
  },
  {
    "value": "typescript",
    "text": "Typescript"
  },
  {
    "value": "user-interface",
    "text": "UI"
  },
  {
    "value": "uml",
    "text": "UML"
  },
  {
    "value": "user-experience",
    "text": "UX"
  },
  {
    "value": "ubuntu",
    "text": "Ubuntu"
  },
  {
    "value": "underscore.js",
    "text": "Underscore"
  },
  {
    "value": "unit-testing",
    "text": "Unit Testing"
  },
  {
    "value": "unittest",
    "text": "Unittest"
  },
  {
    "value": "unity",
    "text": "Unity"
  },
  {
    "value": "unity3d",
    "text": "Unity3D"
  },
  {
    "value": "unix",
    "text": "Unix"
  },
  {
    "value": "unix-kernel",
    "text": "Unix Kernel"
  },
  {
    "value": "user-testing",
    "text": "User Testing"
  },
  {
    "value": "vba",
    "text": "VBA"
  },
  {
    "value": "vbscript",
    "text": "VBScript"
  },
  {
    "value": "vmware",
    "text": "VMWare"
  },
  {
    "value": "vpc",
    "text": "VPC"
  },
  {
    "value": "vpn",
    "text": "VPN"
  },
  {
    "value": "vscode",
    "text": "VSCode"
  },
  {
    "value": "vagrant",
    "text": "Vagrant"
  },
  {
    "value": "vala",
    "text": "Vala"
  },
  {
    "value": "varnish",
    "text": "Varnish"
  },
  {
    "value": "vault",
    "text": "Vault"
  },
  {
    "value": "verilog",
    "text": "Verilog"
  },
  {
    "value": "version-control-system",
    "text": "Version Control System"
  },
  {
    "value": "vertx",
    "text": "Vert.x"
  },
  {
    "value": "vhdl",
    "text": "Vhdl"
  },
  {
    "value": "video.js",
    "text": "Video"
  },
  {
    "value": "video-games",
    "text": "Video Games"
  },
  {
    "value": "vim",
    "text": "Vim"
  },
  {
    "value": "virtual-reality",
    "text": "Virtual Reality"
  },
  {
    "value": "virtualization",
    "text": "Virtualization"
  },
  {
    "value": "visual-cpp",
    "text": "Visual C++"
  },
  {
    "value": "visual-management",
    "text": "Visual Management"
  },
  {
    "value": "visual-studio",
    "text": "Visual Studio"
  },
  {
    "value": "visualforce",
    "text": "Visualforce"
  },
  {
    "value": "voip",
    "text": "VoIP"
  },
  {
    "value": "vue.js",
    "text": "Vue"
  },
  {
    "value": "vulkan",
    "text": "Vulkan"
  },
  {
    "value": "wadl",
    "text": "WADL"
  },
  {
    "value": "wamp",
    "text": "WAMP"
  },
  {
    "value": "wcf",
    "text": "WCF"
  },
  {
    "value": "wimp",
    "text": "WIMP"
  },
  {
    "value": "watir",
    "text": "Watir"
  },
  {
    "value": "web",
    "text": "Web"
  },
  {
    "value": "web-analytics",
    "text": "Web Analytics"
  },
  {
    "value": "web-app",
    "text": "Web App"
  },
  {
    "value": "web-app-js",
    "text": "Web App JS"
  },
  {
    "value": "web-design",
    "text": "Web Design"
  },
  {
    "value": "web-marketing",
    "text": "Web Marketing"
  },
  {
    "value": "web-service",
    "text": "Web Service"
  },
  {
    "value": "web-style",
    "text": "Web Style"
  },
  {
    "value": "web3js",
    "text": "Web3JS"
  },
  {
    "value": "webgl",
    "text": "WebGL"
  },
  {
    "value": "weblogic",
    "text": "WebLogic"
  },
  {
    "value": "webrtc",
    "text": "WebRTC"
  },
  {
    "value": "webpack",
    "text": "Webpack"
  },
  {
    "value": "weka",
    "text": "Weka"
  },
  {
    "value": "wildfly",
    "text": "WildFly"
  },
  {
    "value": "win32",
    "text": "Win32"
  },
  {
    "value": "windows",
    "text": "Windows"
  },
  {
    "value": "windows-azure",
    "text": "Windows Azure"
  },
  {
    "value": "windows-development",
    "text": "Windows Development"
  },
  {
    "value": "windows-kernel",
    "text": "Windows Kernel"
  },
  {
    "value": "windows-phone",
    "text": "Windows Phone"
  },
  {
    "value": "windows-presentation-foundation",
    "text": "Windows Presentation Foundation"
  },
  {
    "value": "windows-server",
    "text": "Windows Server"
  },
  {
    "value": "wireframes",
    "text": "Wireframes"
  },
  {
    "value": "wordpress",
    "text": "Wordpress"
  },
  {
    "value": "xaml",
    "text": "XAML"
  },
  {
    "value": "xampp",
    "text": "XAMPP"
  },
  {
    "value": "xhtml",
    "text": "XHTML"
  },
  {
    "value": "xml",
    "text": "XML"
  },
  {
    "value": "xquery",
    "text": "XQuery"
  },
  {
    "value": "xsd",
    "text": "XSD"
  },
  {
    "value": "xsl",
    "text": "XSL"
  },
  {
    "value": "xsl-fo",
    "text": "XSL-Fo"
  },
  {
    "value": "xslt",
    "text": "XSLT"
  },
  {
    "value": "xunit",
    "text": "XUnit"
  },
  {
    "value": "xamarin",
    "text": "Xamarin"
  },
  {
    "value": "xamarin-test-cloud",
    "text": "Xamarin Test Cloud"
  },
  {
    "value": "xcode",
    "text": "Xcode"
  },
  {
    "value": "xen",
    "text": "Xen"
  },
  {
    "value": "xpath",
    "text": "Xpath"
  },
  {
    "value": "yaml",
    "text": "YAML"
  },
  {
    "value": "yarn",
    "text": "YARN"
  },
  {
    "value": "yocto",
    "text": "YOCTO"
  },
  {
    "value": "yii",
    "text": "Yii"
  },
  {
    "value": "zabbix",
    "text": "Zabbix"
  },
  {
    "value": "zapier",
    "text": "Zapier"
  },
  {
    "value": "zend",
    "text": "Zend"
  },
  {
    "value": "zeplin",
    "text": "Zeplin"
  },
  {
    "value": "zeromq",
    "text": "ZeroMQ"
  },
  {
    "value": "zookeeper",
    "text": "Zookeeper"
  },
  {
    "value": "bitbake",
    "text": "bitbake"
  },
  {
    "value": "bootcamp",
    "text": "bootcamp"
  },
  {
    "value": "cocos2d",
    "text": "cocos2d"
  },
  {
    "value": "cryengine",
    "text": "cryengine"
  },
  {
    "value": "e-commerce-cms",
    "text": "e-commerce CMS"
  },
  {
    "value": "electron",
    "text": "electron"
  },
  {
    "value": "ext.js",
    "text": "ext.js"
  },
  {
    "value": "ez-publish",
    "text": "ez-publish"
  },
  {
    "value": "flutter",
    "text": "flutter"
  },
  {
    "value": "gatsby",
    "text": "gatsby"
  },
  {
    "value": "groff",
    "text": "groff"
  },
  {
    "value": "html-unit",
    "text": "html-unit"
  },
  {
    "value": "ios-development",
    "text": "iOS"
  },
  {
    "value": "jquery.js",
    "text": "jQuery"
  },
  {
    "value": "jquery-mobile",
    "text": "jQuery Mobile"
  },
  {
    "value": "job-seeking",
    "text": "job-seeking"
  },
  {
    "value": "kdb_",
    "text": "kdb+"
  },
  {
    "value": "minitest",
    "text": "minitest"
  },
  {
    "value": "nuxt_js",
    "text": "nuxt.js"
  },
  {
    "value": "opencl",
    "text": "openCL"
  },
  {
    "value": "pardot",
    "text": "pardot"
  },
  {
    "value": "php-my-admin",
    "text": "phpMyAdmin"
  },
  {
    "value": "pl-pgsql",
    "text": "pl-pgSQL"
  },
  {
    "value": "pl-sql",
    "text": "pl-sql"
  },
  {
    "value": "racket",
    "text": "racket"
  },
  {
    "value": "ractive-js",
    "text": "ractive JS"
  },
  {
    "value": "symfony4",
    "text": "symfony4"
  },
  {
    "value": "t-sne",
    "text": "t-SNE"
  },
  {
    "value": "top-engineering-school",
    "text": "top-engineering-school"
  },
  {
    "value": "top-school",
    "text": "top-school"
  },
  {
    "value": "x86-assembly-programming",
    "text": "x86 Assembly Programming"
  }
]
