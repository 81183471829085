import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import {
  Segment,
  Label,
  Header,
  Grid,
  Statistic,
  Container,
  Icon,
  List,
} from 'semantic-ui-react';
import { ExtLink } from './ExtLink';
import {
  transformEnrichmentByHint,
  // getEnrichedTextByType,
} from '../enrichment';
import { getColorFromScrapDate } from '../../common'; //getTagColor
import EnrichedText, {
  getColorFromEnrichedText,
} from '../../components/EnrichedText';

export const StackOverflowProfile = ({ source, globalHint }) => {
  const {
    id,
    fullname,
    website,
    location,
    age,
    summary,
    answerCount,
    questionCount,
    reputation,
    acceptRate,
    tags,
    // enrichedData,
    summaryEnriched,
  } = source;

  const enrichedLocation = location; //getEnrichedTextByType(enrichedData, 'location');
  const enrichedSummary = summaryEnriched; //getEnrichedTextByType(enrichedData, 'summary');

  return (
    <Segment color="orange">
      <Header size="medium">
        <div style={{ float: 'right' }}>
          <Label
            style={{ marginRight: 20 }}
            color={getColorFromScrapDate(source.date)}
          >
            {moment(source.date, 'YY-MM-DD').fromNow()}
          </Label>
          <ExtLink
            classes="source-link"
            to={'https://stackoverflow.com/users/' + id}
          >
            <Icon name="stack overflow" />
          </ExtLink>
          {website ? (
            <ExtLink to={website}>
              <Icon name="external" />
            </ExtLink>
          ) : null}
        </div>
        <Header.Content>{fullname}</Header.Content>
      </Header>
      <Grid style={{ clear: 'right' }}>
        <Grid.Column width={6}>
          <List>
            {location ? (
              <List.Item
                icon="marker"
                content={
                  enrichedLocation ? (
                    <EnrichedText
                      {...transformEnrichmentByHint(
                        globalHint,
                        enrichedLocation,
                      )}
                    />
                  ) : (
                    location
                  )
                }
              />
            ) : null}
            {age ? <List.Item icon="time" content={age} /> : null}
          </List>
        </Grid.Column>
        <Grid.Column width={10}>
          {summary && !enrichedSummary ? (
            <Segment secondary dangerouslySetInnerHTML={{ __html: summary }} />
          ) : null}
          {enrichedSummary ? (
            <Segment secondary>
              <EnrichedText
                {...transformEnrichmentByHint(globalHint, enrichedSummary)}
              />
            </Segment>
          ) : null}
        </Grid.Column>
      </Grid>

      <Statistic.Group widths={4} size="mini" style={{ marginTop: 20 }}>
        <Statistic label="Questions" value={questionCount} />
        <Statistic label="Answers" value={answerCount} />
        <Statistic label="Reputation" value={reputation} />
        <Statistic label="Accept Rate" value={`${acceptRate || '?'} %`} />
      </Statistic.Group>

      <Container fluid textAlign="center">
        <Label.Group style={{ marginTop: 20 }}>
          {_.map(tags, ({ name, answerScore, nameEnriched, enrichedTag }) => (
            <Label
              key={name}
              size="big"
              style={{
                backgroundColor:
                  getColorFromEnrichedText(nameEnriched) || '#eee',
              }}
            >
              {name} <span style={{ fontSize: '0.7em' }}>{answerScore}</span>
            </Label>
          ))}
        </Label.Group>
      </Container>
    </Segment>
  );
};
