import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import ItemEditModal from './ItemEditModal';

/*
  Before, params should be of the form :
  {
    ?waitingForUpload: Bool,
    ?withSweetappNbStackedProfiles: Bool,
    ?withAllItems: Bool,
    ?withTodaysItems: Bool,
    ?withItemFunnelStatus: Bool,
  }
  Now, params should be of the form :
  {
     filter: undefined or { rule: 'todays' } or { sweetsheetIds: ['abc',... ] },
     offer: {
       enrichments: { // optional
         nbStackedProfiles: 1
       }
     },
     items : { // if missing, no items in results
       filter: undefined or { rule: 'todays' } or mongo-like
       projection, // optional, mongo-like
       enrichments: { // optional
         funnelStatus: 1
       }
     }
    
  }
*/
const withSheetsManager = (WrappedComponent, params) => class extends React.PureComponent {
  state = {
    dockers: [],
    loading: true,
  };
  getDockersBaseParams = (options) => {
    const flyweight = (options && options.flyweight) || false;
    return flyweight ? (
      _.omit(params, 'items')
    ) : params;
  }
  handleLoadDockers = async (options) => {
    try {
      const baseParams = this.getDockersBaseParams(options);
      const { data } = await axios.post(`${baseUrl}/uploadView/dockers`, {
         ...baseParams,
         ...this.props.sheetId && { filter: { sweetsheetIds: [this.props.sheetId] } }
      });
      if (data.error) {
        console.error('ERROR in data, in handleLoadDockers');
        console.error(data.error);
        alert(data.error);
        this.setState({ 
          loading: false,
        });
      } else {
        this.setState({ 
          dockers: data,
          loading: false,
        });
      }
    } catch (e) {
      console.error(e)
      alert('handleLoadDockers ' + e.message)
    }
  }
  handleLoadItems = async (sheetIds) => {
    try {
      const { data } = await axios.post(`${baseUrl}/uploadView/dockers`, {
        ...this.getDockersBaseParams(),
        filter: { sweetsheetIds: sheetIds }
      });
      
      if (data.error) {
        alert(data.error);
      } else {
        const newDockers = data;
        let newDockerFromId = {};
        _.each(newDockers, (docker) => {
          if (docker.sweetsheet && docker.sweetsheet.id) {
            newDockerFromId[docker.sweetsheet.id] = docker;
          }
        });
        const currentDockers = this.state.dockers;
        const updatedDockers = _.map(currentDockers, docker => (
          newDockerFromId[(docker.sweetsheet || {}).id] || docker
        ));
        this.setState({ 
          dockers: updatedDockers,
          loading: false
        });
      }
    } catch (e) {
      console.error(e)
      alert('handleLoadItems' + e.message)
    }
  }
  handleReloadDocker = async (sheetId) => {
    try {
      const { data } = await axios.post(`${baseUrl}/uploadView/dockers`, {
         ...this.getDockersBaseParams(),
         filter: { sweetsheetIds: [sheetId] }
      });
      if (data.error) {
        alert(data.error);
      } else if (!_.isArray(data) || data.length !== 1) {
        alert('error in handleReloadSheet, should receive exactly one docker');
      } else {
        const newDocker = data[0];
        const newDockers = _.map(this.state.dockers, docker => (
          docker.sweetsheet && docker.sweetsheet.id === sheetId  ? newDocker : docker
        ));

        this.setState({ 
          dockers: newDockers,
          loading: false
        });
      }
    } catch (e) {
      alert('handleReloadDocker' + e.message)
    }
  }
  /* 
    editFields should be of the form 
      [
        { 
          key : 'item.profile.data.headline', 
          label: 'Headline',
          type: 'input' (default is 'input')
          initialValue: 'Rock Star'
        }, 
        ...
      ]
  */
  handleAskEditItemModal = ({ sheetId, itemId, editFields, withDeleteMode }) => {
    this.setState({
      editMode: {
        type: 'edit-item',
        withDeleteMode,
        sheetId,
        itemId,
        editFields
      }
    })
  }
  /**
   * actionData should be of the form
   * {
   *  type: String, // "upload"...
   *  target: String, // sweetapp
   *  jobOfferId: String, // hiresweet-fullstack => platformId
   *  items: [sweetsheetItems] // items à uploader
   * }
   */
  handlePerformActions = async (actions) => {
    const errors = _.compact(await Promise.all(_.map(actions, async ({ sweetsheetId, itemId, actionData }) => {
      const { data } = await axios.post(`${baseUrl}/uploadView/applyAction`, {
        sweetsheetId,
        itemId,
        actionData
      });
      if (data.error) {
        return itemId + ': ' + data.error;
      } 
    })));
    if (!_.isEmpty(errors)) {
      alert(errors.join('\n---\n'));
    }
    let sweetsheetIds = _.uniq(_.compact(_.pluck(actions, 'sweetsheetId')));
    _.each(sweetsheetIds, (sweetsheetId) => {
      this.handleReloadDocker(sweetsheetId);
    });
  }
  getBaseActionFromOffer = (offer) => {
    return {
      target: 'sweetapp',
      jobOfferId: offer.platformId
    }
  }
  handleUploadItems = ({ docker, items, force, forcedIds, prependingModeIds }) => {
    const sweetsheetId = (docker.sweetsheet || {}).id;
    const offer = docker.offer || {};
    const baseAction = this.getBaseActionFromOffer(offer);
    const actions = _.compact(_.map(items, (item) => {
      const itemId = item.id;
      return itemId && sweetsheetId && baseAction.target ? {
        sweetsheetId,
        itemId,
        actionData: {
          type: 'upload',
          ...baseAction,
          force: force || !!(forcedIds || {})[itemId],
          prependingMode: !!(prependingModeIds || {})[itemId],
        }
      } : null
    }));

    if (!_.isEmpty(actions)) {    
      this.handlePerformActions(actions);
    }
  }
  handleUnlockItem = ({ docker, item }) => {
    const sweetsheetId = (docker.sweetsheet || {}).id;
    const offer = docker.offer || {};
    const baseAction = this.getBaseActionFromOffer(offer);
    const itemId = item.id;
    
    const action = baseAction.target ? { 
      sweetsheetId,
      itemId,
      actionData: {
        type: 'unlock',
        ...baseAction,
      }
    } : null;

    if (action) {    
      this.handlePerformActions([action]);
    }    
  }
  handleforceSetItemStatus = ({ docker, item, status }) => {
    const sweetsheetId = (docker.sweetsheet || {}).id;
    const offer = docker.offer || {};
    const baseAction = this.getBaseActionFromOffer(offer);
    const itemId = item.id;
    
    const action = baseAction.target ? { 
      sweetsheetId,
      itemId,
      actionData: {
        type: 'set-force',
        status,
        ...baseAction,
      }
    } : null;
    if (action) {    
      this.handlePerformActions([action]);
    }
  }
  handleUnUploadItem = ({ docker, item }) => {
    const sweetsheetId = (docker.sweetsheet || {}).id;
    const offer = docker.offer || {};
    const baseAction = this.getBaseActionFromOffer(offer);
    const itemId = item.id;
    const action = baseAction.target ? {
      sweetsheetId,
      itemId,
      actionData: {
        type: 'unupload',
        ...baseAction,
      }
    } : null
    if (action) {    
      this.handlePerformActions([action]);
    }
  }
  onCloseEdition = () => {
    this.setState({ editMode: null });
  }
  handleSubmitItemContent = async ({ sheetId, itemId, diffObject }) => {
    try {
      const url = `${baseUrl}/sweetsheets/${sheetId}/items/${itemId}/content`;
      const { data } = await axios.put(url, diffObject);
      if (data.error) {
        throw Error(data.error);
      } 
      const newContent = data;
      return newContent;
    } catch (e) {
      console.error(e);
      alert(e.message);
      return null;
    }
  }
  handleSubmitItemComment = async ({ sheetId, itemId, comment }) => {
    try {
      const annotationItem = {
        category: 'hiresweet',
        annotation: {
          type: 'comment',
          message: comment
        }
      };
      const url = `${baseUrl}/sweetsheets/${sheetId}/items/${itemId}/annotations`;
      const { data } = await axios.put(url, annotationItem);
      if (data.error) {
        throw Error(data.error);
      }
      return data;
    } catch (e) {
      console.error(e);
      alert(e.message);
      return null;
    }    
  }
  
  handleSubmitItem = async ({ sheetId, itemId, diffObject }) => {
    try {
      const comment = (diffObject['comment'] !== undefined) ? diffObject['comment'] : null;
      const diffContentObject = _.omit(diffObject, 'comment');
      const newAnnotations = comment !== null ? (
        await this.handleSubmitItemComment({ sheetId, itemId, comment })
      ) : null;
      const newContent = !_.isEmpty(diffContentObject) ? (
        await this.handleSubmitItemContent({ sheetId, itemId, diffObject: diffContentObject })
      ) : null;
      const newDockers = _.map(this.state.dockers, docker => (
        sheetId && docker.sweetsheet && sheetId === docker.sweetsheet.id ? ({
            ...docker,
          items: _.map(docker.items, item => (
            itemId && itemId === item.id ? {
                ...item,
                ...newContent && { content: newContent },
                ...newAnnotations && { annotations: newAnnotations },
            }: item
          ))
        }) : docker
      ));
      this.setState({ dockers: newDockers, editMode: null });
    } catch(e) {
      console.error(e);
      alert(e.message);
    }
  }
  handleDeleteItem = async ({ sheetId, itemId }) => {
    const { data } = await axios.delete(`${baseUrl}/sweetsheets/${sheetId}/items/${itemId}`);
    if (data.error) {
      alert(data.error);
    } else {
      const newDockers = _.map(this.state.dockers, docker => (
        sheetId && docker.sweetsheet && sheetId === docker.sweetsheet.id ? ({
          ...docker,
          items: _.compact(_.map(docker.items, item => (
            itemId && itemId === item.id ? null : item
          )))
        }) : docker
      ));
      this.setState({ dockers: newDockers });
    }
    this.setState({ editMode: null });
  }
  handleChangeItemPreselection = async ({ sheetId, itemId, preselection }) => {
    /* TODO
    this.handleSubmitItem({ 
      sheetId, 
      itemId, 
      diffObject: { 'preselected': preselection }
    })
    */
  }
  handleMarkSheetAsCompleted = async ({ sheetId, noReload }) => {
    if (!noReload) {
      await axios.post(`${baseUrl}/uploadView/markAsCompleted`, {
        sweetsheetId: sheetId
      });      
      this.handleReloadDocker(sheetId);
    } else {
      axios.post(`${baseUrl}/uploadView/markAsCompleted`, {
        sweetsheetId: sheetId
      });      
      const markedAsCompleted = {
        ...this.state.markedAsCompleted,
        [sheetId]: 1
      };
      this.setState({
        markedAsCompleted
      });
    }
  }
  render() {
    const { editMode } = this.state;
    return (
      <div>
        <WrappedComponent 
          {...this.props}
          dockers={this.state.dockers}
          loadDockers={this.handleLoadDockers}
          loadItems={this.handleLoadItems}
          reloadAllDockers={this.handleLoadDockers}
          reloadDocker={this.handleReloadDocker}
          askEditItemModal={this.handleAskEditItemModal}
          performActions={this.handlePerformActions}
          uploadItems={this.handleUploadItems}
          unUploadItem={this.handleUnUploadItem}
          markedAsCompleted={this.state.markedAsCompleted}
          unlockItem={this.handleUnlockItem}
          deleteItem={this.handleDeleteItem}            
          forceSetItemStatus={this.handleforceSetItemStatus}
          changeItemPreselection={this.handleChangeItemPreselection}
          markSheetAsCompleted={this.handleMarkSheetAsCompleted}
          loading={this.state.loading}
        />
        {editMode && editMode.type === 'edit-item' && (
          <ItemEditModal
            key={editMode.itemId}
            sheetId={editMode.sheetId}
            itemId={editMode.itemId}
            editFields={editMode.editFields}
            withDeleteMode={editMode.withDeleteMode}
            onClose={this.onCloseEdition} 
            onSubmit={this.handleSubmitItem}
            onDelete={this.handleDeleteItem}            
          />
        )}
      </div>
    );
  }
}

export default withSheetsManager;
