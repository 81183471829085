import React from 'react';
import { Button } from 'semantic-ui-react';

const MiniButton = ({ text, onClick, color='grey' }) => (
  <Button
    circular
    color={color}
    size="big"
    style={{ padding: '.35em .4em .35em .5em' }}
    onClick={onClick}
  >
    <div
      style={{
        marginLeft: -2,
        width: '25px',
        height: '25px',
        fontSize: '8px',
        lineHeight: '25px',
        verticalAlign: 'middle',
      }}
    >
      <center>
        {text}
      </center>
    </div>
  </Button>
);

export default MiniButton;
