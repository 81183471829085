import React from 'react';
import _ from 'underscore';
import { translate } from 'react-i18next';
import { Stack } from '../../components/Stack';
import TextInput from '../components/TextInput';
import RelevantTag, { AddRelevantTag } from '../components/RelevantTag';
import { Image, Label, Dropdown, Icon } from 'semantic-ui-react';
import { getColorFromScrapDate } from '../../common';
import { getLastScrapText } from '../utils';
import { getDurationSinceEducationEnd } from './ProfileEducations';
import { EditableTranslatableText } from '../components/TranslatableText';
import SmartSummary, { getSortedSkillsWishes } from '../../components/ClientProfile/SmartSummary';
import EnrichedText from '../components/EnrichedText';
import Collapsible from '../components/Collapsible';
import ScrappedTextWrapper from '../components/ScrappedTextWrapper';

const MainEducation = ({ education }) => {
  if (!education) {
    return null;
  }
  let currentValue;
  let mainEducationEndDate;
  const options = _.map(education.get(), (educationItem, index) => {
    if (educationItem.getIsMain().get()) {
      currentValue = index;
      mainEducationEndDate = educationItem.getEndDate();
    }
    return {
      key: index,
      value: index,
      text: educationItem.getSchoolName().get(),
    };
  });

  const onChange = (e, { value: newMainEdu }) => {
    const updatedEducation = _.map(education.getRawValue(), (educationItem, index) => {
      if (index === newMainEdu) {
        return { ...educationItem, isMain: true };
      }
      return { ...educationItem, isMain: false };
    });
    education.setRawValue(updatedEducation); // Use of setRawValue to avoid making multiple updates
  };

  return (
    <div style={{ color: 'grey', marginBottom: '6px' }}>
      {'Trained at '}
      <Dropdown inline options={options} value={currentValue} error={currentValue === undefined} onChange={onChange} />
      {currentValue === undefined && <span style={{ color: '#9f3a38' }}>No Main Education</span>}
      <span>
        {mainEducationEndDate &&
          mainEducationEndDate.getYear().get() &&
          getDurationSinceEducationEnd({ endDate: mainEducationEndDate })}
      </span>
    </div>
  );
};

class SourcesList extends React.Component {
  render() {
    const { sources, firstname, lastname, onRemoveSource } = this.props;
    const listSources = [
      {
        object: sources.getLinkedin(),
        icon: '/images/contact_logos/logolink_linkedin.svg',
        notRemovable: true,
      },
      {
        sourceId: 'github',
        object: sources.getGithub(),
        icon: '/images/contact_logos/logolink_github.svg',
      },
      {
        sourceId: 'stackoverflow',
        object: sources.getStackoverflow(),
        icon: '/images/contact_logos/logolink_stackoverflow.svg',
      },
      {
        object: sources.getMedium(),
        icon: '/images/contact_logos/logolink_medium.svg',
      },
      {
        sourceId: 'twitter',
        object: sources.getTwitter(),
        icon: '/images/contact_logos/logolink_twitter.svg',
      },
      {
        sourceId: 'dribbble',
        object: sources.getDribbble(),
        icon: '/images/contact_logos/logolink_dribbble.svg',
      },
      {
        sourceId: 'behance',
        object: sources.getBehance(),
        icon: '/images/contact_logos/logolink_behance.svg',
      },
      {
        object: sources.getPinterest(),
        icon: '/images/contact_logos/logolink_pinterest.svg',
      },
      {
        sourceId: 'angelList',
        object: sources.getAngelList(),
        icon: '/images/contact_logos/logolink_angelList.svg',
      },
      {
        object: sources.getMeetup(),
        icon: '/images/contact_logos/logolink_meetup.svg',
      },
      {
        object: sources.getBitBucket(),
        icon: '/images/contact_logos/logolink_bitBucket.svg',
      },
      {
        object: sources.getAboutMe(),
        icon: '/images/contact_logos/logolink_aboutMe.svg',
      },
      {
        object: sources.getKeyBase(),
        icon: '/images/contact_logos/logolink_keyBase.svg',
      },
      {
        object: sources.getViadeo(),
        icon: '/images/contact_logos/logolink_viadeo.svg',
      },
      {
        object: sources.getWordPress(),
        icon: '/images/contact_logos/logolink_wordPress.svg',
      },
      {
        object: sources.getMalt(),
        icon: '/images/contact_logos/logolink_malt.svg',
      },
      {
        object: sources.getDoYouBuzz(),
        icon: '/images/contact_logos/logolink_doYouBuzz.svg',
      },
      {
        object: sources.getCoderWall(),
        icon: '/images/contact_logos/logolink_coderWall.svg',
      },
      {
        object: sources.getSlideshare(),
        icon: '/images/contact_logos/logolink_slideshare.svg',
      },
      {
        object: sources.getRemixJobs(),
        icon: '/images/contact_logos/logolink_remixJobs.svg',
      },
      {
        sourceId: 'website',
        object: sources.getWebsite(),
        text: 'website',
      },
      {
        object: sources.getGithubIo(),
        text: 'githubIo',
      },
      {
        object: sources.getCv(),
        text: 'cv',
      },
    ];

    const getSearchSourceLink = ({ site, firstname, lastname }) => {
      const link = 'https://google.com/search?q=';
      const paramsSite = site && site !== 'name' ? `site:${site}.com ` : '';
      const paramsName = `"${firstname || ''} ${lastname || ''}"`;
      return `${link}${encodeURIComponent(paramsSite + paramsName)}`;
    };

    const extraLinks = [
      getSearchSourceLink({
        firstname: firstname.get() || '',
        lastname: lastname.get() || '',
      }),
    ];

    if (!sources.getGithub().get()) {
      extraLinks.push(
        getSearchSourceLink({
          site: 'github',
          firstname: firstname.get() || '',
          lastname: lastname.get() || '',
        }),
      );
      if (firstname.get() && lastname.get()) {
        extraLinks.push(
          ('https://github.com/search?q=' + firstname.get() + '+' + lastname.get() + '&type=Users').toLowerCase(),
        );
      }
    }
    if (!sources.getStackoverflow().get()) {
      extraLinks.push(
        getSearchSourceLink({
          site: 'stackoverflow',
          firstname: firstname.get() || '',
          lastname: lastname.get() || '',
        }),
      );
    }
    extraLinks.push(sources.getLinkedin().get());

    const extraDesignLinks = [
      getSearchSourceLink({
        firstname: firstname.get() || '',
        lastname: lastname.get() || '',
      }),
      getSearchSourceLink({
        site: 'dribbble',
        firstname: firstname.get() || '',
        lastname: lastname.get() || '',
      }),
      getSearchSourceLink({
        site: 'behance',
        firstname: firstname.get() || '',
        lastname: lastname.get() || '',
      }),
    ];

    const getSalesNavigatorLinks = ({ firstname, lastname }) => {
      if (!firstname || !lastname) {
        return [];
      }

      const firstName = firstname
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .replace(/\./g, '')
        .replace(/\,/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const lastName = lastname
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .replace(/\./g, '')
        .replace(/\,/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      const f = firstName.substr(0, 1);
      const emails = [
        firstName + '.' + lastName + '@gmail.com',
        firstName + lastName + '@gmail.com',
        f + '.' + lastName + '@gmail.com',
        f + lastName + '@gmail.com',
        lastName + '.' + firstName + '@gmail.com',
        lastName + firstName + '@gmail.com',
      ];
      return _.map(emails, (email) => 'https://www.linkedin.com/sales/gmail/profile/viewByEmail/' + email);
    };

    const salesNavigatorLinks = getSalesNavigatorLinks({
      firstname: (firstname.get() || '').trim(),
      lastname: (lastname.get() || '').trim(),
    });

    const sourceStyle = { maxWidth: '13px', maxHeight: '13px', marginLeft: '8px' };
    const iconStyle = { with: '13px', maxHeight: '13px', opacity: 0.35 };
    return (
      <div
        className='sources-link-list'
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '6px' }}
      >
        {_.map(
          listSources,
          ({ object, text, icon, notRemovable, sourceId }, index) =>
            object.get() && (
              <div style={{ display: 'flex', position: 'relative' }} key={index}>
                <a
                  className='source-link'
                  href={object.get()}
                  style={icon ? sourceStyle : { marginLeft: 8 }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div style={{ display: 'flex' }}>
                    {icon ? <img src={icon} alt={object.get()} style={iconStyle} /> : <span>{text} </span>}
                  </div>
                </a>
                {!notRemovable && (
                  <div style={{ position: 'absolute', right: -10, top: -10, opacity: 0.5 }}>
                    <Icon
                      link
                      size='small'
                      name='remove'
                      color='red'
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (sourceId) {
                          await onRemoveSource({ sourceIdToRemove: sourceId, idToRemove: object.get() });
                        }
                        object.set(null);
                      }}
                    />
                  </div>
                )}
              </div>
            ),
        )}

        <div style={{ position: 'fixed', top: -100, bottom: -100 }}>
          {_.map(extraLinks, (extraLink, index) => (
            // eslint-disable-next-line
            <a className='source-search' key={index} href={extraLink} />
          ))}
          {_.map(extraDesignLinks, (extraDesignLink, index) => (
            // eslint-disable-next-line
            <a className='source-design-search' key={index} href={extraDesignLink} />
          ))}
          {_.map(salesNavigatorLinks, (salesNavigatorLink, index) => (
            // eslint-disable-next-line
            <a className='source-sales-navigator' key={index} href={salesNavigatorLink} />
          ))}
        </div>
      </div>
    );
  }
}

const renderRelevantTags = (relevantTags) => {
  let relevantTagsElements = [];
  for (let i = 0; i < relevantTags.length; i++) {
    relevantTagsElements.push(
      <RelevantTag
        key={i}
        label={relevantTags.at(i).getLabel()}
        content={relevantTags.at(i).getContent()}
        onRemove={() => relevantTags.removeAt(i)}
        style={{ marginBottom: '8px', marginRight: '11px' }}
      />,
    );
  }
  return relevantTagsElements;
};

const ProfileHeader = ({
  editable,
  offerId, // for the link to the gamepad
  firstname,
  lastname,
  headline,
  location,
  photoLink,
  summary,
  smartSummary,
  sources,
  relevantTags,
  education,
  lastScrapDay,
  onToggleClientView,
  summaryLinkedRessources,
  onRemoveSource,
  hiresweetData,
  t,
}) => (
  <div className='profile-header' style={{ fontFamily: 'SourceSansPro', fontSize: 12 }}>
    <div style={{ display: 'flex', flexDirection: 'row', padding: '11px', paddingRight: 0 }}>
      <div style={{ width: '80px', maxHeight: '80px', marginRight: '30px' }}>
        <Image
          src={photoLink.get() || '/images/default-avatar-1.svg'}
          circular
          fluid
          onError={(e) => {
            e.target.src = '/images/default-avatar-1.svg';
          }}
        />
      </div>

      <div style={{ fontSize: '14px', flexGrow: 1 }}>
        <div style={{ fontSize: '16px', fontFamily: 'SourceSansProSemiBold', marginBottom: '6px' }}>
          <TextInput
            placeholder='Firstname'
            name='firstname'
            value={firstname.get() || ''}
            onChange={({ value }) => firstname.set(value)}
            style={{ marginRight: '6px' }}
          />
          <TextInput
            placeholder='Lastname'
            name='lastname'
            value={lastname.get() || ''}
            onChange={({ value }) => lastname.set(value)}
          />
        </div>
        <div style={{ marginBottom: '6px' }}>
          <TextInput
            placeholder='Headline'
            name='Headline'
            value={
              headline
                .getContent()
                .getText()
                .get() || ''
            }
            onChange={({ value }) =>
              headline
                .getContent()
                .getText()
                .set(value)
            }
            style={{ width: '50%' }}
          />
          <span> • </span>
          <EditableTranslatableText translatableText={location.getName()} placeholder='Location' />
        </div>
        <MainEducation education={education} />

        {!_.isEmpty(summaryLinkedRessources) && (
          <span>
            Linked Ressources:{' '}
            {_.map(summaryLinkedRessources, ({ url }, index) =>
              url ? (
                <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
                  <Icon link name='file outline' />
                </a>
              ) : null,
            )}
          </span>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        {offerId && (
          <div style={{ position: 'absolute', right: 10, top: -20 }}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={
                '/ml/sandbox?' +
                ('offer=' + offerId) +
                (sources.getLinkedin() && sources.getLinkedin().get()
                  ? ('&linkedin=' + sources.getLinkedin().get()).replace('https://linkedin.com/in/', '')
                  : '') +
                (sources.getGithub() && sources.getGithub().get()
                  ? ('&github=' + sources.getGithub().get()).replace('https://github.com/', '')
                  : '')
              }
            >
              <Icon link name='gamepad' color='blue' />
            </a>
          </div>
        )}
        {onToggleClientView && (
          <div style={{ position: 'absolute', right: -10, top: -20 }}>
            <Icon link name='user' color='blue' onClick={() => onToggleClientView()} />
          </div>
        )}
        {lastScrapDay.get() ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Label
              style={{ marginRight: -5, whiteSpace: 'nowrap', fontFamily: 'SourceSansProSemiBold' }}
              color={getColorFromScrapDate(lastScrapDay.get())}
            >
              {getLastScrapText(lastScrapDay.get())}
            </Label>
          </div>
        ) : null}
        <div style={{ marginTop: 10 }}>
          <SourcesList sources={sources} firstname={firstname} lastname={lastname} onRemoveSource={onRemoveSource} />
        </div>
      </div>
    </div>
    {smartSummary && !_.isEmpty(smartSummary.getArguments().getRawValue()) && (
      <SmartSummary
        isWatchProfile={true}
        argumentation={smartSummary.getArguments().getRawValue()}
        t={t}
        skills={getSortedSkillsWishes({ hiresweetData })}
      />
    )}
    {summary
      .getContent()
      .getText()
      .get() && (
      <div className='summary-tag-stack-container' style={{ padding: '11px', paddingTop: 0 }}>
        <div className='stack-container'>
          <span style={{ fontSize: 14, fontFamily: 'SourceSansProSemiBold' }}>Summary</span>
          <div className='stack'>
            <Stack stack={summary.getStack().getRawValue()} />
          </div>
        </div>
        <Collapsible maxHeight={95}>
          <ScrappedTextWrapper
            text={summary
              .getContent()
              .getText()
              .get()}
          >
            <EnrichedText
              text={summary
                .getContent()
                .getText()
                .get()}
              enrichments={summary.getContent().getEnrichments()}
            />
          </ScrappedTextWrapper>
        </Collapsible>
      </div>
    )}
    <div style={{ padding: '11px', paddingBottom: '0px' }}>
      {renderRelevantTags(relevantTags)}
      {editable !== false && <AddRelevantTag onAdd={relevantTags.push} />}
    </div>
  </div>
);

export default translate('translations')(ProfileHeader);
