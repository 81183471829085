import moment from 'moment';
import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';

import { Form, Grid, Table } from 'semantic-ui-react';

import { SelectInt, SweetForm } from 'sweetform';

import baseUrl from './baseUrl';

// Actions
const SET_PARAMS = 'SET_PARAMS';
const setParams = (params) => ({ type: SET_PARAMS, params });

const SET_RESULTS = 'SET_RESULTS';
const setResults = (results) => ({ type: SET_RESULTS, results });

// Reducers
const params = (state = {}, action) => {
  switch (action.type) {
    case SET_PARAMS:
      return action.params;
    default:
      return state;
  }
};

const results = (state = [], action) => {
  switch (action.type) {
    case SET_RESULTS:
      return action.results;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  params,
  results,
});

// Store
const store = createStore(rootReducer);

// Components
const WarningsTable = ({ results }) => (
  <Table celled compact="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Last report</Table.HeaderCell>
        <Table.HeaderCell>Worker</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Count</Table.HeaderCell>
        <Table.HeaderCell>Messages</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(results, ({ _id, worker, type, messages, last, qty }, i) => (
        <Table.Row key={i}>
          <Table.Cell>{moment(last).format('YY-MM-DD HH:mm:ss')}</Table.Cell>
          <Table.Cell>{worker}</Table.Cell>
          <Table.Cell>{type}</Table.Cell>
          <Table.Cell>{qty}</Table.Cell>
          <Table.Cell>
            <pre style={{ fontSize: '0.8rem' }}>{messages.join('\n')}</pre>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
class Warnings extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { results, onSetParams } = this.props;

    return (
      <Grid>
        <Grid.Column width={3}>
          <Form>
            <SweetForm onChange={onSetParams}>
              <Form.Field>
                <label>Number of days</label>
                <SelectInt field="days" min={0} max={30} placeholder="0" />
              </Form.Field>
            </SweetForm>
          </Form>
        </Grid.Column>
        <Grid.Column width={13}>
          <WarningsTable results={results} />
        </Grid.Column>
      </Grid>
    );
  }
}

const mapSWarnings = (state) => ({
  params: state.params,
  results: state.results,
});

const mapDWarnings = (dispatch) => ({
  onLoad: async () => {
    const result = (await axios.get(`${baseUrl}/scrapers/warnings`)).data;
    dispatch(setResults(result));
  },
  onSetParams: async (params) => {
    dispatch(setParams(params));
    dispatch(
      setResults(
        (await axios.get(
          `${baseUrl}/scrapers/warnings?days=${params.days || 0}`,
        )).data,
      ),
    );
  },
});

const WarningsContainer = connect(
  mapSWarnings,
  mapDWarnings,
)(Warnings);

export default () => (
  <Provider store={store}>
    <WarningsContainer />
  </Provider>
);
