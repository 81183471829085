import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Map } from 'immutable';
import switchSelect from './switchSelect';
import List from './List';
import Input from './Input';
import Range from './Range';
import Weighted from './Weighted';
import SelectDate from './SelectDate';
import enhance from './enhance';
var basicOperators = Map({
  'not': {
    label: 'NOT',
    children: 1
  },
  'eq': {
    label: 'EQ',
    children: 2
  },
  'lt': {
    label: 'LT',
    children: 2
  },
  'gt': {
    label: 'GT',
    children: 2
  },
  'and': {
    label: 'AND',
    children: 'n'
  },
  'or': {
    label: 'OR',
    children: 'n'
  },
  'mean': {
    label: 'Mean',
    children: 'n'
  },
  'weightedMean': {
    label: 'Weighted Mean',
    children: 'n',
    component: enhance(function (_ref) {
      var field = _ref.field,
          Op = _ref.Op;
      return (// Op is the built constructor component
        React.createElement(Weighted, {
          field: field
        }, React.createElement(Op, {
          field: 'value'
        }))
      );
    })
  },
  'biais': {
    label: 'Bias',
    children: 1,
    component: function component(_ref2) {
      var field = _ref2.field,
          Op = _ref2.Op;
      return React.createElement(Weighted, {
        field: field,
        percent: true,
        min: -100
      }, React.createElement(Op, {
        field: 'value'
      }));
    }
  },
  'plateau': {
    label: 'Plateau',
    children: 1,
    component: enhance(function (_ref3) {
      var Op = _ref3.Op;
      return React.createElement('div', null, React.createElement(Range, {
        min: 0,
        max: 100,
        field: 'range'
      }), React.createElement(Op, {
        field: 'value'
      }));
    })
  },
  'text': {
    label: 'Text',
    nested: true,
    component: Input
  },
  'date': {
    label: 'Date',
    nested: true,
    component: SelectDate
  },
  'range': {
    label: 'Range',
    children: 1,
    component: Range,
    props: {
      min: -1000,
      max: 1000
    }
  }
});

var customOperator = function customOperator(customOperators) {
  var includeBasic = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var operators = includeBasic ? basicOperators.merge(Map(customOperators)) : Map(customOperators);
  var options = operators.map(function (v, k) {
    return Object.assign({}, v, {
      value: k
    });
  }).toList().sortBy(function (o) {
    return o.label;
  }).toArray();
  var Result = switchSelect(options, function (_ref4) {
    var selector = _ref4.selector,
        selection = _ref4.selection;

    if (!selection) {
      return null;
    }

    var operator = operators.get(selection);
    var nested = operator.nested,
        children = operator.children,
        component = operator.component,
        props = operator.props;
    var Inner = component ? component : Result;

    if (nested) {
      return React.createElement(Grid, {
        columns: 2
      }, React.createElement(Grid.Column, {
        width: 6
      }, selector), React.createElement(Grid.Column, {
        width: 10
      }, React.createElement(Inner, Object.assign({}, props, {
        field: selection,
        Op: Result
      }))));
    } else if (children === 1) {
      return React.createElement(Grid, {
        columns: 2
      }, React.createElement(Grid.Column, {
        width: 2
      }), React.createElement(Grid.Column, {
        width: 14
      }, React.createElement(Inner, Object.assign({}, props, {
        field: selection,
        Op: Result
      }))));
    } else if (children === 2) {
      return React.createElement(Grid, {
        columns: 2
      }, React.createElement(Grid.Column, {
        width: 2
      }), React.createElement(Grid.Column, {
        width: 14
      }, React.createElement(Inner, Object.assign({}, props, {
        field: selection + '1',
        Op: Result
      })), React.createElement(Inner, Object.assign({}, props, {
        field: selection + '2',
        Op: Result
      }))));
    } else if (children === 'n') {
      return React.createElement(List, {
        field: selection,
        component: Inner,
        Op: Result
      });
    } else {
      return null;
    }
  });
  return Result;
};

export { customOperator };
export default customOperator();