import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import baseUrl from '../baseUrl';
import { formScorerToQuery, SelectGlobalBackground, SelectAdvancedBackground } from '../Offers/SearchModal.js';
import { SweetForm, customOperator } from 'sweetform';

const ExtraScorers = customOperator(
 {
    globalBackground: { label: 'Global Background', children: 1, component: SelectGlobalBackground },
    advancedBackground: { label: 'Advanced Background', children: 1, component: SelectAdvancedBackground },
  },
  false
)



export const CriterionForm = ({onUpdate, initialValues}) => (
    <SweetForm onChange={onUpdate} initialValues={initialValues}>
      <ExtraScorers field="scorer" />
    </SweetForm>
)

class EditCriterionModal extends Component {

  state = {
    criterion: null
  };

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.state.criterion = props.initialValue;
    }
  }

  handleUpdate = (formParams) => {
    const searchParams = formScorerToQuery((formParams || {}).scorer);
    this.setState({ criterion: {...this.state.criterion, searchParams, formParams} });
  }

  handleSave = () => {
    this.props.onSave({ criterion: this.state.criterion });
  }

  render() {
    const { onClose } = this.props;
    const { criterion } = this.state;
    const { searchParams, formParams } = criterion || {};

    return (
      <Modal onClose={onClose} open closeIcon size="fullscreen">
        <Modal.Header>
          Edit Criterion
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <CriterionForm initialValues={formParams} onUpdate={this.handleUpdate} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' disabled={_.isEmpty(searchParams)} onClick={this.handleSave}>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}


export default EditCriterionModal;
