import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import LinkedinEditor from '../SweetComponents/LinkedinProfile/Editor';

class LinkedinEditorModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.state.data = props.initialValue;
  }

  handleUpdateData = ({ data }) => {
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({
      data: this.state.data,
    });
  };

  render() {
    const { initialValue, onClose } = this.props;

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit LinkedinProfile</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <LinkedinEditor initialValue={initialValue} onUpdate={this.handleUpdateData} isV4={true} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => this.handleSave()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default LinkedinEditorModal;
