import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Button, Message } from 'semantic-ui-react';

import Order from './Order';
import baseUrl from '../baseUrl';

class SweetminerOrdersEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loading: {},
      changed: {},
      isNew: [],
      messages: {},
      displayArchived: true,
      displayUnarchived: true,
    };

    this.handleEnabledChange = this.handleEnabledChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleOrderSave = this.handleOrderSave.bind(this);
    this.handleOrderDelete = this.handleOrderDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleObjectiveChange = this.handleObjectiveChange.bind(this);
    this.handleSettingsChange = this.handleSettingsChange.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  async componentDidMount() {
    this.getSettingsOptions();
    const response = await axios.get(`${baseUrl}/sweetminer/order`);
    this.setState({
      orders: response.data,
    });
  }

  async getSettingsOptions() {
    const results = await Promise.all([
      axios.get(`${baseUrl}/tags/list?type=skill`),
      axios.get(`${baseUrl}/tags/list?type=job`),
    ]);

    const skills = results[0];
    const jobs = results[1];

    const skillOptions = _.map(skills.data, (skill, i) => ({
      key: i,
      value: skill.id,
      text: skill.name,
    }));

    const jobOptions = _.map(jobs.data, (job, i) => ({
      key: i,
      value: job.id,
      text: job.name,
    }));

    this.setState({ settingsOptions: { skills: skillOptions, jobs: jobOptions } });
  }

  async handleDisplayUnarchivedTasks() {
    let displayUnarchived = this.state.displayUnarchived;
    displayUnarchived = !displayUnarchived;
    await this.setState({ displayUnarchived });
  }

  async handleDisplayArchivedTasks() {
    let displayArchived = this.state.displayArchived;
    displayArchived = !displayArchived;
    await this.setState({ displayArchived });
  }

  async loading(order_id) {
    let loading = this.state.loading;
    loading[order_id] = true;
    await this.setState({ loading });
  }

  async doneLoading(order_id) {
    let loading = this.state.loading;
    loading[order_id] = false;
    await this.setState({ loading });
  }

  async change(order_id) {
    let changed = this.state.changed;
    changed[order_id] = true;
    await this.setState({ changed });
  }

  async saved(order_id) {
    let changed = this.state.changed;
    changed[order_id] = false;
    await this.setState({ changed });
  }

  async setNew(index) {
    let isNew = this.state.isNew;
    isNew[index] = true;
    await this.setState({ isNew });
  }

  async unsetNew(index) {
    let isNew = this.state.isNew;
    isNew[index] = false;
    await this.setState({ isNew });
  }

  async handleOrderSave(index) {
    const order = this.state.orders[index];
    await this.loading(order.id);
    try {
      await axios.put(`${baseUrl}/sweetminer/order/${order.id}`, order);
      this.showMessage({ message: 'Order saved !', severity: 'success' }, 5);
      this.saved(order.id);
    } catch (e) {
      this.showMessage({ message: 'Unable to save this order', severity: 'error' }, 5);
    }
    this.doneLoading(order.id);
    this.unsetNew(index);
  }

  async handleShowFutureTask(index) {
    const order = this.state.orders[index];
    await this.loading(order.id);
    try {
      const urls = await axios.get(`${baseUrl}/sweetminer/order/${order.id}/sample_future`);
      const url = urls['data'][0];
      window.open(url, '_blank');
      this.showMessage({ message: 'Tasks retrieved !', severity: 'success' }, 5);
    } catch (e) {
      this.showMessage({ message: 'No task example could be retrieved', severity: 'error' }, 5);
    }
    this.doneLoading(order.id);
  }

  async handleShowPreviousTask(index) {
    const order = this.state.orders[index];
    await this.loading(order.id);
    try {
      const urls = await axios.get(`${baseUrl}/sweetminer/order/${order.id}/sample_previous`);
      const url = urls['data'][0];
      window.open(url, '_blank');
      this.showMessage({ message: 'Tasks retrieved !', severity: 'success' }, 5);
    } catch (e) {
      this.showMessage({ message: 'No task example could be retrieved', severity: 'error' }, 5);
    }
    this.doneLoading(order.id);
  }

  async handleArchiveTask(index) {
    let orders = this.state.orders;
    if (orders[index].enabled) {
      this.showMessage({ message: 'Unable to archive this order because it is enabled', severity: 'error' }, 5);
    } else {
      orders[index].archived = true;
      this.change(orders[index].id);
      await this.setState({ orders: orders });
      await this.handleOrderSave(index);
      this.showMessage({ message: 'Order archived', severity: 'success' }, 5);
    }
  }

  async handleUnarchiveTask(index) {
    let orders = this.state.orders;
    orders[index].archived = false;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
    await this.handleOrderSave(index);
    this.showMessage({ message: 'Order unarchived', severity: 'success' }, 5);
  }

  async showMessage(message, delay) {
    const id = Math.floor(Math.random() * 999999);
    let messages = this.state.messages;
    messages[id] = message;
    this.setState({ messages });
    setTimeout(() => {
      let messages = this.state.messages;
      delete messages[id];
      this.setState({ messages });
    }, delay * 1000);
  }

  async handleOrderDelete(index) {
    const order = this.state.orders[index];
    await this.loading(order.id);
    try {
      await axios.delete(`${baseUrl}/sweetminer/order/${order.id}`);
      this.showMessage({ message: 'Order deleted !', severity: 'success' }, 5);
    } catch (e) {
      this.showMessage({ message: 'Unable to delete this order', severity: 'error' }, 5);
    }
    let orders = this.state.orders;
    orders.splice(index, 1);
    delete this.state.loading[order.id];
    delete this.state.changed[order.id];
    this.setState({ orders });
  }

  async handleOrderFlush(index) {
    const order = this.state.orders[index];
    await this.loading(order.id);
    try {
      await axios.delete(`${baseUrl}/sweetminer/order/${order.id}/reset`);
      this.showMessage({ message: 'Order flushed !', severity: 'success' }, 5);
    } catch (e) {
      this.showMessage({ message: 'Unable to flush this order', severity: 'error' }, 5);
    }
    this.doneLoading(order.id);
  }

  async handleEnabledChange(index, enabled) {
    let orders = this.state.orders;
    orders[index].enabled = enabled;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async handlePriorityChange(index, priority) {
    let orders = this.state.orders;
    orders[index].priority = priority;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async handleObjectiveChange(index, objective) {
    let orders = this.state.orders;
    orders[index].objective = { profilesCount: objective };
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async getNewOrderNumber() {
    var index = 1;
    const orders_ids = _.map(this.state.orders, (order) => order.id);
    while (_.indexOf(orders_ids, 'new_order' + index) >= 0) {
      index += 1;
    }
    return String(index);
  }

  async createOrder() {
    const order = {
      id: 'new_order' + (await this.getNewOrderNumber()),
      priority: 0,
      type: '',
      name: 'New Order',
      enabled: true,
      settings: {},
      objective: null,
    };
    await this.setState({ orders: [...this.state.orders, order] });
    this.setNew(this.state.orders.length - 1);
  }

  async handleTypeChange(index, type) {
    let orders = this.state.orders;
    orders[index].type = type;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async handleNameChange(index, name) {
    let orders = this.state.orders;
    orders[index].name = name;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async handleIDChange(index, id) {
    let orders = this.state.orders;
    orders[index].id = id;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async handleSettingsChange(index, settings) {
    let orders = this.state.orders;
    orders[index].settings = settings;
    this.change(orders[index].id);
    await this.setState({ orders: orders });
  }

  async handleOrderUnlock(index) {
    const order = this.state.orders[index];
    await this.loading(order.id);
    try {
      await axios.delete(`${baseUrl}/sweetminer/order/${order.id}/unlock`);
      this.showMessage({ message: 'Order unlocked !', severity: 'success' }, 5);
    } catch (e) {
      this.showMessage({ message: 'Unable to unlock this order, check it has an extend lock', severity: 'error' }, 5);
    }
    this.doneLoading(order.id);
  }

  render() {
    return (
      <div>
        {_.map(this.state.messages, (msg, id) => {
          const sev = msg.severity;
          return (
            <Message key={id} error={sev === 'error'} success={sev === 'success'} warning={sev === 'warning'}>
              {msg.message}
            </Message>
          );
        })}

        <a
          target='_blank_'
          rel='noopener noreferrer'
          href='https://p.datadoghq.com/sb/0mz6f63yvbua4o4w-8fd2796fc144104ccca2497bb9909bfc'
        >
          <Button basic color='purple'>
            {' '}
            Summary Dashboard{' '}
          </Button>
        </a>

        <Button.Group>
          <Button toggle active={this.state.displayUnarchived} onClick={() => this.handleDisplayUnarchivedTasks()}>
            Ordres en cours
          </Button>
          <Button toggle active={this.state.displayArchived} onClick={() => this.handleDisplayArchivedTasks()}>
            Ordres archivés
          </Button>
        </Button.Group>

        {_.map(this.state.orders, (order, index) =>
          ((order.archived || false) && this.state.displayArchived) ||
          (!(order.archived || false) && this.state.displayUnarchived) ? (
            <Order
              key={index}
              id={order.id}
              name={order.name}
              priority={order.priority}
              enabled={order.enabled}
              type={order.type}
              settings={order.settings}
              message={order.message}
              objective={(order.objective || {}).profilesCount}
              nbSuccessfulTasks={order.nbSuccessfulTasks || 0}
              archived={order.archived || false}
              isNew={this.state.isNew[index] || false}
              loading={this.state.loading[order.id] || false}
              changed={this.state.changed[order.id] || false}
              settingsOptions={this.state.settingsOptions || {}}
              onEnabledChange={(value) => this.handleEnabledChange(index, value)}
              onPriorityChange={(value) => this.handlePriorityChange(index, value)}
              onNameChange={(value) => this.handleNameChange(index, value)}
              onTypeChange={(value) => this.handleTypeChange(index, value)}
              onObjectiveChange={(value) => this.handleObjectiveChange(index, value)}
              onIDChange={(value) => this.handleIDChange(index, value)}
              onSettingsChange={(value) => this.handleSettingsChange(index, value)}
              onSave={() => this.handleOrderSave(index)}
              onFlush={() => this.handleOrderFlush(index)}
              onDelete={() => this.handleOrderDelete(index)}
              onShowFutureTask={() => this.handleShowFutureTask(index)}
              onShowPreviousTask={() => this.handleShowPreviousTask(index)}
              onArchive={() => this.handleArchiveTask(index)}
              onUnarchive={() => this.handleUnarchiveTask(index)}
              onUnlock={() => this.handleOrderUnlock(index)}
              showMessage={this.showMessage}
            />
          ) : (
            <div />
          ),
        )}
        <div>
          <Button positive onClick={() => this.createOrder()}>
            Add order
          </Button>
        </div>
      </div>
    );
  }
}

export default SweetminerOrdersEditor;
