import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Dropdown, Grid, Card, Icon, Popup, Button } from 'semantic-ui-react';

import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

import baseUrl from './baseUrl.js';

const getDays = () => {
  const date = new Date();
  const lastMidnightTimestamp = date.setHours(3, 0, 0, 0);
  let days = [];
  let nbDays = 60;
  const iWeekDay = date.getDay();
  for (let iDay = 0; iDay < nbDays; iDay++) {
    const timestamp = lastMidnightTimestamp - iDay * 24 * 3600 * 1000;
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    days.push({
      timestamp,
      date: new Date(timestamp).toISOString().slice(2, 10),
      weekDay: weekDays[(iWeekDay - (iDay % 7) + 7) % 7],
    });
  }
  return days;
}

const getRuns = ({datasets}) => {
  const runIds = _.compact(_.uniq(_.pluck(datasets, 'run')));
  const runs = _.map(runIds, (runId)=>{
    return {
      id:runId,
      name:runId
    }
  })
  return _.flatten([
    [{id:'all', name:'all'}, {id:'other', name:'other'}],
    runs
  ])
}


class DatasetsMonitoring extends Component {
  days = getDays();
  state = {
    maxDay: this.days[0],
    categories: {
      a: true,
      b: true,
      c: true,
      d: true,
      e: true,
    },
    hiddenUsernames: {},
    hiddenIds: {},
  };
  componentWillMount() {
    this.handleLoad();
  }
  handleLoad = async () => {
    const dataTrain = (await axios.get(`${baseUrl}/datasets/perfs/train`)).data;
    const dataPretest = (await axios.get(`${baseUrl}/datasets/perfs/pretest`))
      .data;
    const dataTest = (await axios.get(`${baseUrl}/datasets/perfs/test`)).data;
    this.setState({
      trainDatasets: dataTrain.datasets,
      pretestDatasets: dataPretest.datasets,
      testDatasets: dataTest.datasets,
    });

    
    this.runs = getRuns({datasets:_.flatten([dataTrain.datasets, dataPretest.datasets, dataTest.datasets])});
    this.setState({run:this.runs[0]})

  }
  handleToggleCategory = (category) => {
    this.setState({
      categories: {
        ...this.state.categories,
        [category]: !this.state.categories[category],
      },
    });
  };
  handleToggleUsername = (username) => {
    this.setState({
      hiddenUsernames: {
        ...this.state.hiddenUsernames,
        [username]: !this.state.hiddenUsernames[username],
      },
    });
  };
  handleToggleId = (id) => {
    this.setState({
      hiddenIds: {
        ...this.state.hiddenIds,
        [id]: !this.state.hiddenIds[id],
      },
    });
  };

  handleToggleDeployGroupIdAndName = ({ id, name }) => {
    const groupIdAndNameIsDeployed = this.state.groupIdAndNameIsDeployed || {};
    const key = id + '__' + name;
    this.setState({
      groupIdAndNameIsDeployed: {
        ...groupIdAndNameIsDeployed,
        [key]: !groupIdAndNameIsDeployed[key],
      },
    });
  };
  handleToggleDeployGroupId = ({ id }) => {
    const groupIdIsDeployed = this.state.groupIdIsDeployed || {};
    this.setState({
      groupIdIsDeployed: {
        ...groupIdIsDeployed,
        [id]: !groupIdIsDeployed[id],
      },
    });
  };
  handleSelectLinkedin = async (linkedinId) => {
    window.open(`${window.location.origin}/profiles/${linkedinId}/static`, '_blank');
  }
  renderRanking({ ranking, scores, optimizedScores, unitaryCosts, linkedinIds }, totalWidth) {
    const letterToColor = (letter) => {
      return (
        {
          a: '#3EF005',
          b: '#67B603',
          c: '#917C02',
          d: '#BB4201',
          e: '#E50800',
        }[letter.toLowerCase()] || 'grey'
      );
    };
    const cellWidth = Math.round(totalWidth / Math.max(ranking.length, 1));

    const chartData = _.map(scores, (value, index) => ({
      key: index,
      value,
      optimized: optimizedScores[index],
    }));

    const { categories } = this.state;

    const totalCost = Math.max(
      0.0000001,
      _.reduce(unitaryCosts, (memo, x) => memo + x, 0),
    );
    const maxUnitaryCost = _.reduce(
      unitaryCosts,
      (memo, x) => Math.max(memo, x),
      0.0000001,
    );

    return (
      <div>
        <div style={{ display: 'inline-block' }}>
          {_.map(
            ranking.split(''),
            (letter, index) =>
              categories[letter.toLowerCase()] && (
                <div
                  key={index}
                  title={
                    (scores && scores.length > index
                      ? '' + scores[index]
                      : '') +
                    '\n' +
                    (unitaryCosts && unitaryCosts.length > index
                      ? '' +
                        Math.round((10000 * unitaryCosts[index]) / totalCost) /
                          100
                      : '')
                  }
                  onClick={() =>
                    this.handleSelectLinkedin(
                      linkedinIds && linkedinIds.length > index
                        ? linkedinIds[index]
                        : null,
                    )
                  }
                  style={{
                    display: 'inline-block',
                    height: 20,
                    width: cellWidth,
                    background: letterToColor(letter),
                  }}
                />
              ),
          )}
          <br />
          {_.map(ranking.split(''), (letter, index) => {
            const opacity =
              unitaryCosts && unitaryCosts.length > index
                ? unitaryCosts[index] / maxUnitaryCost
                : 0;
            return (
              <div
                key={'__' + index}
                style={{
                  display: 'inline-block',
                  height: 20,
                  width: cellWidth,
                  background: 'black',
                  opacity: opacity,
                }}
              />
            );
          })}
        </div>
        <div
          style={{
            display: 'inline-block',
            marginLeft: 10,
            verticalAlign: 'top',
          }}
        >
          <Popup
            trigger={
              <Icon
                size="big"
                color="blue"
                name="bar chart"
                style={{ cursor: 'pointer' }}
              />
            }
            content={
              <LineChart width={800} height={300} data={chartData}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  animationDuration={0}
                />
                <Line
                  type="monotone"
                  dataKey="optimized"
                  stroke="orange"
                  animationDuration={0}
                />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="key" />
                <YAxis />
              </LineChart>
            }
          />
        </div>
      </div>
    );
  }
  renderDatasetsOfName({ id, name, datasets }) {
    let bestDataset = null;
    _.each(datasets, (dataset) => {
      if (!bestDataset || dataset.score > bestDataset.score) {
        bestDataset = dataset;
      }
    });

    let paretoSortedByDates = [];
    _.each(_.sortBy(datasets, 'timestamp'), (dataset) => {
      if (
        _.isEmpty(paretoSortedByDates) ||
        dataset.score > _.last(paretoSortedByDates).score
      ) {
        paretoSortedByDates.push(dataset);
      }
    });

    const key = id + '__' + name;
    const isDeployed = (this.state.groupIdAndNameIsDeployed || {})[key];
    return (
      <Card.Description>
        <h2>
          <Icon
            link
            size="large"
            name={isDeployed ? 'caret up' : 'caret down'}
            onClick={() => this.handleToggleDeployGroupIdAndName({ id, name })}
          />
          {bestDataset && (
            <span>
              {' '}
              [{Math.round(bestDataset.score * 100) / 100}
              <span style={{ color: 'green' }}>
                {' '}
                / {Math.round(bestDataset.optimizedScore * 100) / 100}
              </span>
              ]{' '}
            </span>
          )}
          {name || ''}
          {bestDataset && (
            <span style={{ fontSize: 15, marginLeft: 10 }}>
              ({bestDataset.date}
              {bestDataset.username && (
                <span style={{ color: 'orange' }}>
                  {' '}
                  [{bestDataset.username}]
                </span>
              )}
              )
            </span>
          )}
        </h2>
        {bestDataset && this.renderRanking(bestDataset, 560)}
        {isDeployed && (
          <div style={{ marginLeft: 40, marginTop: 10 }}>
            {_.map(paretoSortedByDates, (dataset, index) => (
              <div key={index}>
                <h3>
                  <span>
                    {' '}
                    [{Math.round(dataset.score * 100) / 100}
                    <span style={{ color: 'green' }}>
                      {' '}
                      / {Math.round(dataset.optimizedScore * 100) / 100}
                    </span>
                    ]{' '}
                  </span>
                  {new Date(dataset.timestamp)
                    .toISOString()
                    .slice(2, 16)
                    .replace('T', ' ') + ' '}
                  (
                  {bestDataset.username && (
                    <span style={{ color: 'orange' }}>
                      {' '}
                      [{bestDataset.username}]
                    </span>
                  )}
                  )
                </h3>
                {this.renderRanking(dataset, 500)}
              </div>
            ))}
          </div>
        )}
      </Card.Description>
    );
  }
  renderDatasetsOfGroupId({ id, datasets }) {
    let bestDataset = null;
    _.each(datasets, (dataset) => {
      if (!bestDataset || dataset.score > bestDataset.score) {
        bestDataset = dataset;
      }
    });

    let byName = {};
    _.each(datasets, (dataset) => {
      if (!byName[dataset.modelName]) {
        byName[dataset.modelName] = [];
      }
      byName[dataset.modelName].push(dataset);
    });

    let nameAndScore = [];
    _.each(byName, (datasets, name) => {
      let bestScore = 0;
      _.each(_.pluck(datasets, 'score'), (score) => {
        if (score > bestScore) {
          bestScore = score;
        }
      });
      nameAndScore.push({ name, score: bestScore });
    });

    const isDeployed = (this.state.groupIdIsDeployed || {})[id];
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <h1>
              <Icon
                link
                size="large"
                name={isDeployed ? 'caret up' : 'caret down'}
                onClick={() => this.handleToggleDeployGroupId({ id })}
              />
              {bestDataset && (
                <span>
                  {' '}
                  [{Math.round(bestDataset.score * 100) / 100}
                  <span style={{ color: 'green' }}>
                    {' '}
                    / {Math.round(bestDataset.optimizedScore * 100) / 100}
                  </span>
                  ]{' '}
                </span>
              )}
              {(id || '').replace('-abcde', '').toUpperCase()}
              {bestDataset && (
                <span style={{ fontSize: 15, marginLeft: 10 }}>
                  ({bestDataset.modelName}
                  {bestDataset.username && (
                    <span style={{ color: 'orange' }}>
                      {' '}
                      [{bestDataset.username}]
                    </span>
                  )}
                  )
                </span>
              )}
            </h1>
          </Card.Header>
          {bestDataset && (
            <Card.Description>
              {this.renderRanking(bestDataset, 600)}
            </Card.Description>
          )}
          {isDeployed && (
            <div style={{ marginLeft: 40, marginTop: 10 }}>
              {_.map(_.sortBy(nameAndScore, 'score').reverse(), ({ name }) => (
                <div key={name}>
                  {this.renderDatasetsOfName({
                    id,
                    name,
                    datasets: byName[name],
                  })}
                </div>
              ))}
            </div>
          )}
        </Card.Content>
      </Card>
    );
  }
  renderScoreEvolution({ datasets }) {
    const datasetIds = _.uniq(_.pluck(datasets, 'id'));
    const bestScoreByDatasetAndDay = {};
    _.each(datasetIds, (id) => {
      bestScoreByDatasetAndDay[id] = {};
    });
    let minDay = '';
    _.each(datasets, ({ id, score, date }) => {
      if (
        !bestScoreByDatasetAndDay[id][date] ||
        score > bestScoreByDatasetAndDay[id][date]
      ) {
        bestScoreByDatasetAndDay[id][date] = score;
        if (!minDay || date < minDay) {
          minDay = date;
        }
      }
    });
    let results = [];
    let bestScoreByDataset = {};
    _.each(datasetIds, (id) => {
      bestScoreByDataset[id] = 0;
    });
    _.each(_.pluck(this.days, 'date').sort(), (date) => {
      if (minDay && date >= minDay) {
        _.each(datasetIds, (id) => {
          if (
            bestScoreByDatasetAndDay[id][date] &&
            bestScoreByDatasetAndDay[id][date] > bestScoreByDataset[id]
          ) {
            bestScoreByDataset[id] = bestScoreByDatasetAndDay[id][date];
          }
        });
        let sumScore = 0;
        let missingDataset = false;
        _.each(datasetIds, (id) => {
          sumScore += bestScoreByDataset[id];
          if (!bestScoreByDataset[id] || bestScoreByDataset[id] < 0.1) {
            missingDataset = true;
          }
        });
        const meanScore = sumScore / Math.max(datasetIds.length, 1);

        if (!missingDataset) {
          results.push({ score: meanScore, date });
        }
      }
    });
    const chartData = _.map(results, ({ score, date }) => ({
      key: date,
      value: score,
    }));

    return (
      <Popup
        trigger={
          <Icon
            color="blue"
            name="bar chart"
            style={{ cursor: 'pointer' }}
          />
        }
        content={
          <LineChart width={500} height={300} data={chartData}>
            <Line
              type="monotone"
              dataKey="value"
              stroke="red"
              animationDuration={0}
            />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="key" />
            <YAxis domain={[0.5, 0.8]} />
          </LineChart>
        }
      />
    );
  }
  renderAllDatasets({ datasets }) {
    let byId = {};
    _.each(datasets, (dataset) => {
      if (!byId[dataset.id]) {
        byId[dataset.id] = [];
      }
      byId[dataset.id].push(dataset);
    });
    let columns = [[], []];
    _.each(byId, (datasets, id) => {
      if (columns[0].length <= columns[1].length) {
        columns[0].push({ id, datasets });
      } else {
        columns[1].push({ id, datasets });
      }
    });

    let sumScore = 0;
    _.each(byId, (datasets) => {
      let maxi = 0;
      _.each(datasets, (dataset) => {
        if (dataset.score > maxi) {
          maxi = dataset.score;
        }
      });
      sumScore += maxi;
    });
    const meanScore =
      Math.round((sumScore / Math.max(_.keys(byId).length, 1)) * 10000) / 100;

    return (
      <div>
        <br />
        <center>
          <h1>
            {meanScore} % <span style={{ marginLeft: 5 }}> </span>
            {this.renderScoreEvolution({ datasets })}
          </h1>
        </center>
        <br />
        <br />
        <Grid>
          <Grid.Row columns={2}>
            {_.map(columns, (column, index) => (
              <Grid.Column key={index}>
                {_.map(column, ({ id, datasets }) => (
                  <div key={id} style={{ marginBottom: 5 }}>
                    {this.renderDatasetsOfGroupId({ id, datasets })}
                  </div>
                ))}
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
  renderSelectors() {
    const { categories, hiddenUsernames, hiddenIds } = this.state;
    const usernames = _.uniq(
      _.compact(
        _.pluck(
          [
            ...(this.state.pretestDatasets || []),
            ...(this.state.testDatasets || []),
          ],
          'username',
        ),
      ),
    );
    const datasetIds = _.uniq(
      _.compact(
        _.pluck(
          [
            ...(this.state.pretestDatasets || []),
            ...(this.state.testDatasets || []),
          ],
          'id',
        ),
      ),
    );
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
          {this.renderDaySelector('maxDay')}
          {this.renderRunSelector('run')}
          {_.map(['a','b','c','d','e'], (category) => (
            <Button key={category}
              color={categories[category] ? 'blue': 'grey' }
              onClick={() => this.handleToggleCategory(category)}
            >
              {category.toUpperCase()}
            </Button>
          ))}
          </Grid.Column>
          <Grid.Column textAlign="right">
            {_.map(usernames, (username) => (
              <Button
                key={username}
                color={hiddenUsernames[username] ? 'grey' : 'blue'}
                onClick={() => this.handleToggleUsername(username)}
              >
                {username}
              </Button>
            ))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {_.map(datasetIds, (id) => (
            <Button
              key={id}
              color={hiddenIds[id] ? 'grey' : 'blue'}
              onClick={() => this.handleToggleId(id)}
            >
              {id}
            </Button>
          ))}
        </Grid.Row>
      </Grid>
    );
  }
  renderRunSelector(key) {
    const options = _.map(this.runs, (run, index) => ({
      key: index,
      text: run.name,
      value: run.id
    }));
    const handleChange = (e, { value }) => {
      const run = _.findWhere(this.runs, { id: value });
      this.setState({
        [key]: run
      });
    }
    return (
      <Dropdown
        onChange={handleChange}
        options={options}
        placeholder='Choose an option'
        selection
        value={(this.state[key] || {}).id || null}
      />
    );
  }
  renderDaySelector(key) {
    const options = _.map(this.days, (day, index) => ({
      key: index,
      text: day.weekDay + '. ' + day.date,
      value: day.date,
    }));
    const handleChange = (e, { value }) => {
      const day = _.findWhere(this.days, { date: value });
      this.setState({
        [key]: day,
      });
    };
    return (
      <Dropdown
        onChange={handleChange}
        options={options}
        placeholder="Choose an option"
        selection
        value={this.state[key].date}
      />
    );
  }
  render() {

    const filter = ({ date, username, id, run }) => {
      const selectedRun = (this.state.run || {}).id;
      return (
        date <= (this.state.maxDay || {}).date || ''
      ) && (
        !this.state.hiddenUsernames[username]
      ) && (
        !this.state.hiddenIds[id]
      ) && (
        selectedRun==='all' || selectedRun === run || (!run && selectedRun==='other')
      )
    }
    const trainDatasets = _.filter(
      this.state.trainDatasets || [],
      filter
    );
    
    const pretestDatasets = _.filter(
      this.state.pretestDatasets || [],
      filter
    );
    const testDatasets = _.filter(
      this.state.testDatasets || [],
      filter
    );      

    return (
      <div>
        {this.renderSelectors()}
        <Tabs>
          <TabList>
            <Tab>Train</Tab>
            <Tab>Pretest</Tab>
            <Tab>Test</Tab>
          </TabList>
          <TabPanel>
            {this.renderAllDatasets({ datasets: trainDatasets })}
          </TabPanel>
          <TabPanel>
            {this.renderAllDatasets({ datasets: pretestDatasets })}
          </TabPanel>
          <TabPanel>
            {this.renderAllDatasets({ datasets: testDatasets })}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default DatasetsMonitoring;
