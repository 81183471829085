import React from 'react';
import { Button } from 'semantic-ui-react';

const ExternalButton = ({ id, onLoadProfile, input, offer, nth }) => (
  <Button
    id={id}
    style={{ fontSize: '0px', padding: '0px', width: '1px', height: '1px' }}
    onClick={() => {
      onLoadProfile(input, offer, nth, 0/*step*/, false/*isPrefetching*/, true );
    }}
  />
);

export const ExternalPushAndRefetchButtons = ({
  onLoadProfile,
  input,
  offer,
  nth,
}) => (
  <div>
    <ExternalButton
      id={'external-push'}
      onLoadProfile={onLoadProfile}
      input={input}
      offer={offer}
      nth={0}
    />
    <ExternalButton
      id={'external-refetch'}
      onLoadProfile={onLoadProfile}
      input={input}
      offer={offer}
      nth={nth}
    />
  </div>
);
