import React from 'react';
import WorkplacesOverview, { WorkplaceOverview } from './WorkplacesOverview';
import EditWorkplaceModal from './EditWorkplaceModal';
import { Icon } from 'semantic-ui-react';
import OffersOverview from './OffersOverview';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';

class SalesBoard extends React.Component {
  state = {
    editMode: 'none',
    editedOffer: null,
    workplace: null,
    workplaces: [],
    workplaceOffers: [],
    offersCount: {},
  };

  componentWillMount() {
    if (this.props.match.params.id) {
      this.loadWorkplace({ workplaceId: this.props.match.params.id });
    }
    this.loadWorkplaces();
    this.loadOffersCount();
  }

  loadWorkplace = async ({ workplaceId }) => {
    try {
      const data = (await axios.get(`${baseUrl}/salesboard/workplaces/${workplaceId}`)).data;
      console.log({ data })
      if (data.error) {
        throw Error(data.error);
      }
      console.log("setState")

      this.setState({ workplace: data.workplace });
      this.loadWorkplaceOffers({ workplaceId });
    } catch (e) {
      alert(`Error loading workplace ${workplaceId} : ${JSON.stringify(e, null, 4)}`);
    }
  }


  loadWorkplaces = async () => {
    const { data } = await axios.get(`${baseUrl}/salesboard/workplaces`);
    this.setState({
      workplaces: data.workplaces,
    });
    return data.workplaces;
  };

  loadOffersCount = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/salesboard/offersCountByWorkplace`);
      const offersCountByWorkplace = data.offersCountByWorkplace;
      this.setState({
        offersCount: offersCountByWorkplace,
      });
    } catch (e) {
      console.log('Failed loadOffersCount ', e.message);
    }
  };

  loadWorkplaceOffers = async ({ workplaceId }) => {
    try {
      if (!workplaceId) {
        throw Error('No workplaceId provided.');
      }
      const { data } = await axios.get(`${baseUrl}/salesboard/workplaces/${workplaceId}/offers`);
      this.setState({
        workplaceOffers: data.offers,
      });
    } catch (e) {
      console.log('Failed to load client offers ', e.message);
      return [];
    }
  };

  onSelectWorkplace = async (workplace) => {
    this.setState({
      workplace: workplace,
    });
    await this.loadWorkplaceOffers({ workplaceId: workplace.id });
  };

  onOpenAddOffer = () => {
    this.setState({
      editMode: 'addOffer',
    });
  };

  onOpenEditOffer = (offer) => {
    this.setState({
      editMode: 'editOffer',
      editedOffer: offer,
    });
  };

  onOpenDuplicateOffer = (offer) => {
    this.setState({
      editMode: 'duplicateOffer',
      editedOffer: offer
    })
  }

  onSubmitAddOffer = async (input) => {
    try {
      const { data } = await axios.post(`${baseUrl}/salesboard/offers`, input);
      if (data && data.success) {
        // refetch the offers to include the new one
        this.loadWorkplaceOffers({ workplaceId: (this.state.workplace || {}).id });
        // add it to the offer count
        this.setState((prevState) => ({
          offersCount: {
            ...prevState.offersCount,
            [input.companyId]: prevState.offersCount[input.companyId] ? prevState.offersCount[input.companyId] + 1 : 1,
          },
        }));
      }
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  };

  onSubmitEditOffer = async (input) => {
    try {
      const { data } = await axios.put(`${baseUrl}/salesboard/offers/${input.id}`, _.omit(input, 'id'));
      if (data && data.success) {
        this.loadWorkplaceOffers({ workplaceId: (this.state.workplace || {}).id });
      }
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  };

  onDeleteOffer = async (input) => {
    try {
      const { data } = await axios.delete(`${baseUrl}/salesboard/offers/${input.id}`);
      if (data && data.success) {
        this.loadWorkplaceOffers({ workplaceId: (this.state.workplace || {}).id });
        // remove it from the offer count
        this.setState((prevState) => ({
          offersCount: {
            ...prevState.offersCount,
            [input.companyId]: prevState.offersCount[input.companyId] ? prevState.offersCount[input.companyId] - 1 : 0,
          },
        }));
      }
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  };

  onCloseEdition = () => {
    this.setState({
      editMode: 'none',
      editedOffer: null,
      editedWorplace: null,
    });
  };

  onOpenPushCriteriaModal = (offer) => {
    this.setState({
      editMode: 'pushCriteria',
      editedOffer: offer,
    });
  };

  onSubmitPushCriteria = async (criteria, platformId) => {
    try {
      const { data } = await axios.put(`${baseUrl}/salesboard/criteria/${platformId}`, criteria);
      return data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  onSetIsActive = async (offerId, isActive) => {
    try {
      const input = { isActive };
      const { data } = await axios.put(`${baseUrl}/salesboard/offers/${offerId}`, input);
      if (data && data.success) {
        const modifiedOffers = _.map(this.state.workplaceOffers, (offer) => {
          if (offer.id === offerId) {
            offer.isActive = isActive;
          }
          return offer;
        });
        this.setState({ workplaceOffers: modifiedOffers });
      }
      return data;
    } catch (e) {
      return { success: false, error: e };
    }
  };

  onSubmitAddWorkplace = async (workplace) => {
    try {
      const { data } = await axios.post(`${baseUrl}/salesboard/workplaces`, workplace);
      if (data && data.success) {
        // refetch workplaces
        this.loadWorkplaces();
        return { success: true };
      } else {
        return { success: false, error: (data || {}).error || 'unknown error' };
      }
    } catch (e) {
      return { success: false, error: e };
    }
  };

  onSubmitEditWorkplace = async (workplace) => {
    const workplaceId = (workplace || {}).id;
    if (!workplaceId) {
      return { success: false, error: 'No workplace id provided' };
    }
    try {
      const { data } = await axios.put(`${baseUrl}/salesboard/workplaces/${workplaceId}`, _.omit(workplace, 'id'));
      if (data && data.success) {
        // refetch workplaces
        if (this.state.workplace) {
          this.loadWorkplace({ workplaceId: this.state.workplace.id });
        }
        this.loadWorkplaces();
        return { success: true };
      } else {
        return { success: false, error: (data || {}).error || 'unknown error' };
      }
    } catch (e) {
      return { success: false, error: e };
    }
  };

  onDeleteWorkplace = async (workplace) => {
    const workplaceId = (workplace || {}).id;
    if (!workplaceId) return { success: false, error: 'No workplace id provided' };
    try {
      const { data } = await axios.delete(`${baseUrl}/salesboard/workplaces/${workplaceId}`);
      if (data && data.success) {
        this.loadWorkplaces();
        return { success: true };
      } else {
        return { success: false, error: (data || {}).error || 'unknown error' };
      }
    } catch (error) {
      return { success: false, error };
    }
  };

  render() {
    const { editMode, editedOffer } = this.state;

    return (
      <div
        style={{
          position: 'fixed',
          top: '68px',
          left: 0,
          height: 'calc(100vh - 68px)',
          width: '100vw',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div style={{ position: 'absolute', right: 10, top: -20 }}>
            <a href='/offers'>
              <Icon name='list' />
            </a>
          </div>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '25%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <WorkplacesOverview
              onSelectWorkplace={this.onSelectWorkplace}
              selectedWorkplace={this.state.workplace}
              workplaces={this.state.workplaces}
              onSubmitAddWorkplace={this.onSubmitAddWorkplace}
              onSubmitEditWorkplace={this.onSubmitEditWorkplace}
              onDeleteWorkplace={this.onDeleteWorkplace}
              offersCount={this.state.offersCount}
            />
          </div>
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '75%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <WorkplaceOverview
              selectedWorkplace={this.state.workplace}
              onSubmitEditWorkplace={this.onSubmitEditWorkplace}
              onReloadWorkplace={this.loadWorkplace}
              onDeleteWorkplace={this.onDeleteWorkplace}
            />
            <OffersOverview
              workplace={this.state.workplace}
              offers={this.state.workplaceOffers}
              editMode={editMode}
              editedOffer={editedOffer}
              onOpenAddOffer={this.onOpenAddOffer}
              onOpenEditOffer={this.onOpenEditOffer}
              onOpenDuplicateOffer={this.onOpenDuplicateOffer}
              onClose={this.onCloseEdition}
              onSubmitAddOffer={this.onSubmitAddOffer}
              onSubmitEditOffer={this.onSubmitEditOffer}
              onDeleteOffer={this.onDeleteOffer}
              onSubmitPushCriteria={this.onSubmitPushCriteria}
              onOpenPushCriteriaModal={this.onOpenPushCriteriaModal}
              onSetIsActive={this.onSetIsActive}
              username={this.props.username}
            />

          </div>
        </div>
      </div>
    );
  }
}
export default SalesBoard;
