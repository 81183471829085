import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Button, Modal, Form } from 'semantic-ui-react';
import { SweetForm, Input } from 'sweetform';

import baseUrl from './baseUrl.js';
import { FilterForm } from './Offers/SearchComponents/SearchForm';
import { Filter, formScorerToQuery } from './Offers/SearchModal';
import { EnrichedLinkedinProfile } from './RayonX/EnrichedLinkedinProfile';
import { PlatformStats } from './RayonX/Components';
import { GlobalEnrichedData } from './RayonX/GlobalEnrichedData';
import { GithubProfile } from './Offers/WorkPipeInputComponents/GithubProfile';
import { StackOverflowProfile } from './Offers/WorkPipeInputComponents/StackoverflowProfile';
import { OutlierModal } from './RayonX/AnnotationButtons';
import { actions } from './Offers/reducers';
import ProfileView from './SweetComponents/Profile/View';

import './RayonX/Parsing.css';

const actionsContainerStyle = {
  position: 'absolute',
  marginLeft: '40vw',
  bottom: '2vh',
  zIndex: '1',
  textAlign: 'center',
};

class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
    if (props.match.params.linkedinId) {
      if (props.match.params.static === 'static') {
        this.handleLoadProfile(props.match.params.linkedinId, true);
      } else {
        this.handleLoadProfile(props.match.params.linkedinId, false);
      }
    }
  }

  handleChangeForm = (e) => {
    this.setState({ filters: e });
  };

  getEntities = async (text) => {
    try {
      const parsing = (await axios.post(`${baseUrl}/rayonX/getParsing`, {
        text: text,
      })).data;
      return parsing;
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  enrichTextFromEntities = (text, entities) => {
    let i = 0;
    let enrichedText = [];
    let count = 0;
    for (let iEntity = 0; iEntity < entities.length; iEntity++) {
      let entity = entities[iEntity];
      let start = entity.position[0];
      let stop = entity.position[1];
      enrichedText.push(text.slice(i, start));
      enrichedText.push(
        <div className='tooltip' key={count}>
          {text.slice(start, stop)}
          <span className='tooltiptext'>
            <b>id: </b>
            {entity.tag}
            <br />
            <b>types: </b>
            {entity.types.join(', ')}
          </span>
        </div>,
      );
      count += 1;
      i = stop;
    }
    enrichedText.push(text.slice(i));
    return enrichedText;
  };

  getEnrichedText = async (text) => {
    const { entities } = await this.getEntities(text);
    if (entities && entities.length > 0) {
      const textWithParsing = this.enrichTextFromEntities(text, entities);
      return textWithParsing;
    } else {
      return text;
    }
  };

  enrichProfileWithParse = async (profile) => {
    let profileWithParse = JSON.parse(JSON.stringify(profile));
    profileWithParse.linkedin.summary = await this.getEnrichedText(profileWithParse.linkedin.summary);
    profileWithParse.linkedin.headline = await this.getEnrichedText(profileWithParse.linkedin.headline);
    let experiences = profileWithParse.linkedin.experiences;
    for (let iXp = 0; iXp < experiences.length; iXp++) {
      let experience = experiences[iXp];
      experience.experienceName = experience.experienceName && (await this.getEnrichedText(experience.experienceName));
      experience.descriptionHTML =
        experience.descriptionHTML && (await this.getEnrichedText(experience.descriptionHTML));
    }
    return profileWithParse;
  };

  getSearchResults = async (searchParams) => {
    try {
      return (await axios.post(`${baseUrl}/rayonX/search`, searchParams)).data;
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  getEnrichedProfile = async (linkedinId, staticMode) => {
    try {
      console.log(`${baseUrl}/rayonX/getEnrichedProfile/${linkedinId}/${staticMode}`);
      const profile = (await axios.get(`${baseUrl}/rayonX/getEnrichedProfile/${linkedinId}/${staticMode}`)).data;
      try {
        const { enrichedProfile, platformStats, idFields } = profile;
        // console.log(enrichedProfile);
        // const enrichedProfileWithParse = await this.enrichProfileWithParse(enrichedProfile);
        return {
          enrichedProfile,
          platformStats,
          idFields,
        };
      } catch (e) {
        console.error(e);
        return profile;
      }
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  handleLoadProfile = async (linkedinId, staticMode) => {
    const { enrichedProfile, platformStats, idFields } = await this.getEnrichedProfile(linkedinId, staticMode);
    const selectedProfile = {
      linkedin: linkedinId,
      github: null,
      stackoverflow: null,
    };
    const profiles = [selectedProfile];
    this.setState({
      profiles,
      selectedProfileIndex: 0,
      nbProfilesPassingFilters: 1,
      nextProfile: null,
      profile: {
        enrichedProfile,
        platformStats: platformStats || {},
        idFields,
        selectedProfile,
      },
    });
  };

  preloadNextAvailableProfileAfterIndex = async (index) => {
    var success = false;
    var currentIndex = index;
    const sleep = async (milis = 1000) => new Promise((resolve) => setTimeout(resolve, milis));
    const maxTries = 10;
    while (!success && currentIndex - index < maxTries) {
      currentIndex += 1;
      var response = await this.preloadProfileFromIndex(currentIndex);
      success = response === 'success' || response === 'end';
      if (!success) {
        sleep();
      }
    }
    this.setState({
      nextProfileIndex: currentIndex,
    });
  };

  preloadProfileFromIndex = async (index) => {
    if (index < this.state.profiles.length) {
      const nextSelectedProfile = this.state.profiles[index];
      const { enrichedProfile, platformStats, idFields } = await this.getEnrichedProfile(
        nextSelectedProfile.linkedin,
        false,
      );
      if (!enrichedProfile || Object.keys(enrichedProfile).length === 0) {
        console.log('Empty next linkedin profile');
        console.log(nextSelectedProfile.linkedin);
        return 'empty';
      }
      this.setState({
        nextProfile: {
          enrichedProfile,
          platformStats,
          idFields,
          selectedProfile: nextSelectedProfile,
        },
      });
      return 'success';
    } else {
      this.setState({
        nextProfile: null,
      });
      console.log('No more profiles');
      return 'end';
    }
  };

  displayNextProfile = async () => {
    if (!Array.isArray(this.state.profiles) || this.state.profiles.length === 0) {
      return;
    }
    if (this.state.nextProfile === null) {
      console.log('No more profiles');
      return;
    }
    const profile = this.state.nextProfile;
    const selectedProfileIndex = this.state.nextProfileIndex;
    this.setState({
      profile,
      selectedProfileIndex,
      nextProfile: null,
      nextProfileIndex: null,
    });
    this.preloadNextAvailableProfileAfterIndex(selectedProfileIndex);
  };

  onSubmit = async () => {
    const searchParams = {
      searchParams: {
        offer: {
          offerId: 'fake-offer',
        },
        ..._.omit(formScorerToQuery(this.state.filters), 'title'),
      },
    };

    this.setState({
      nbProfilesPassingFilters: null,
      message: null,
      selectedProfileIndex: null,
      profile: null,
      profiles: null,
      nextProfile: null,
      searchParam: searchParams,
    });

    const searchedLinkedinId = searchParams.searchParams.linkedin_id;
    const selectedProfileIndex = 0;

    // No linkedin id provided
    if (!searchedLinkedinId) {
      const { nbProfilesPassingFilters, profiles } = (await this.getSearchResults(searchParams)) || {};
      this.setState({ nbProfilesPassingFilters: nbProfilesPassingFilters });
      if (nbProfilesPassingFilters === 0 || !_.isArray(profiles) || profiles.length === 0) {
        return;
      }
      // Shuffle profiles and select first
      const shuffledProfiles = _.shuffle(profiles);
      const selectedProfile = shuffledProfiles[0];
      const { enrichedProfile, platformStats, idFields } = await this.getEnrichedProfile(
        selectedProfile.linkedin,
        false,
      );
      if (!enrichedProfile || _.keys(enrichedProfile).length === 0) {
        console.log('Empty linkedin profile');
        console.log(selectedProfile.linkedin);
        return;
      }
      this.setState({
        profiles: shuffledProfiles,
        selectedProfileIndex,
        profile: {
          enrichedProfile,
          platformStats,
          idFields,
          selectedProfile,
        },
        nextProfile: null,
      });
      this.preloadNextAvailableProfileAfterIndex(selectedProfileIndex);
      // Linkedin id provided
    } else {
      this.handleLoadProfile(searchedLinkedinId, false);
    }
  };

  onChangeComment = async (comment) => {
    this.setState({ comment });
  };

  onChangeIdeaComment = async (ideaComment) => {
    this.setState({ ideaComment });
  };

  render() {
    return (
      <div>
        <div className='actionsContainer' style={actionsContainerStyle}>
          <Button
            disabled={!this.state.nextProfile}
            circular
            color='green'
            size='massive'
            icon='check'
            onClick={this.displayNextProfile}
          />
          <OutlierModal
            onChangeComment={this.onChangeComment}
            comment={this.state.comment}
            searchParam={this.state.searchParam}
            profile={this.state.profile}
          />
          <OutlierModal
            onChangeComment={this.onChangeIdeaComment}
            comment={this.state.ideaComment}
            searchParam={this.state.searchParam}
            profile={this.state.profile}
            idea={true}
          />
        </div>
        <Grid>
          <Grid.Row>
            <Modal trigger={<Button>Search modal</Button>} closeIcon closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>Search modal</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Grid>
                    <SweetForm onChange={this.handleChangeForm}>
                      <FilterForm Filter={Filter} />
                      <Grid.Row style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>
                        <Form.Field width={10}>
                          <h1>Linkedin Id :</h1>
                          <Input field='linkedin_id' placeholder='' autoFocus />
                        </Form.Field>
                      </Grid.Row>
                      <Grid.Row style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>
                        <Grid.Column width={6}>
                          <Button positive onClick={this.onSubmit}>
                            Submit
                          </Button>
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <p>
                            {this.state.nbProfilesPassingFilters !== null &&
                              typeof this.state.nbProfilesPassingFilters !== 'undefined' &&
                              `${this.state.nbProfilesPassingFilters} results`}
                          </p>
                          <p>
                            {this.state.message !== null &&
                              typeof this.state.message !== 'undefined' &&
                              `${this.state.message}`}
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </SweetForm>
                  </Grid>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8} style={{ overflow: 'auto', height: '95vh' }}>
              {((this.state || {}).profile || {}).enrichedProfile && (
                <div>
                  <h1>Enriched Profile</h1>
                  <ProfileView data={((this.state || {}).profile || {}).enrichedProfile} />
                </div>
              )}
            </Grid.Column>

            <Grid.Column rows={4} width={8} style={{ overflow: 'auto', height: '95vh' }}>
              {(this.state || {}).profile && (
                /*<Grid.Row>
                  <h1>Computed Data</h1>
                  <GlobalEnrichedData
                    enrichedProfile={this.state.profile.enrichedProfile}
                    rawData={(((this.state || {}).profile || {}).enrichedProfile || {}).linkedin}
                  />
                </Grid.Row>
                */
                <div />
              )}
              {(this.state || {}).profile && (
                <Grid.Row>
                  <h1>Platform Stats</h1>
                  <PlatformStats platformStats={this.state.profile.platformStats} />
                </Grid.Row>
              )}
              {(((this.state || {}).profile || {}).enrichedProfile || {}).github && (
                <Grid.Row>
                  <h1>Github</h1>
                  <GithubProfile
                    source={this.state.profile.enrichedProfile.github}
                    more={{ githubRepos: true }}
                    globalHints={[]}
                    onChangeExperienceHint={() => {}}
                    onMore={(i) => actions.toggleMore(i)}
                  />
                </Grid.Row>
              )}
              {(this.state || {}).profile && (this.state.profile.enrichedProfile || {}).stackoverflow && (
                <Grid.Row>
                  <h1>Stackoverflow</h1>
                  <StackOverflowProfile
                    source={this.state.profile.enrichedProfile.stackoverflow}
                    more={{}}
                    globalHint={null}
                    onChangeExperienceHint={() => {}}
                    onMore={() => {}}
                  />
                </Grid.Row>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Profiles;
