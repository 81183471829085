import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../../baseUrl';
import { Grid, Form, Segment, Popup, Icon, Button } from 'semantic-ui-react';
import { SweetForm, Input, enhance, Checkbox } from 'sweetform';
import { Textarea } from '../../common';
import OpenAiGenerator from '../../components/OpenAiGenerator';
import blurbExamples from './examples';

const FieldWithCheckbox = enhance((props) => {
  const { value, title } = props;
  const isActive = value ? value.get('isActive') : false;
  return (
    <Grid columns={3} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
      <Grid.Column width={2} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
        <Checkbox field='isActive' />
      </Grid.Column>
      <Grid.Column width={4} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
        {title}
      </Grid.Column>
      <Grid.Column width={8} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
        {isActive && (
          <Input field='value' />
        )}
      </Grid.Column>
    </Grid>
  )
})

const CANDIATE_FIELDS = [
  { label: "Prénom", field: "firstname" },
  { label: "Expérience", field: "experienceYears" },
  { label: "Formé à", field: "mainEducationSchoolName" },
  { label: "Poste actuel", field: "currentJob" },
]


const CandidateFields = enhance((props) => {
  return (
    <div>
      {
        _.map(CANDIATE_FIELDS, ({ label, field }) => (<FieldWithCheckbox key={field} title={label} field={field} />))
      }
    </div>
  )
})

const ExampleEditor = enhance((props) => {
  const { value, onSetExample } = props;
  const useExample = value ? value.get('isActive') : false;
  const exampleId = value ? value.get('id') : false;
  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column width={2} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
          <Checkbox field='isActive' />
        </Grid.Column>
        <Grid.Column width={14} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
          {_.map(blurbExamples, ({ id, data }) => {
            return (
              <Button color={(exampleId == id)&&useExample&&'blue'} onClick={() => onSetExample({ id, data })}>{id}</Button>
            )
          })}
        </Grid.Column>
      </Grid.Row>

      {useExample && (
        <Grid.Row>

          <Grid.Column width={5} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Row>
              <span>Blurb</span>
            </Grid.Row>
            <Grid.Row>
              <Textarea field="blurb" rows='7' cols='30' />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={5} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Row>
              <span>Notes</span>
            </Grid.Row>
            <Grid.Row>
              <Textarea field="notes" rows='7' cols='30' />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={6} style={{ marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
            <CandidateFields field="candidateFields" />
          </Grid.Column>
        </Grid.Row>
      )}

    </Grid>
  )
})

class BlurbGenerationDataEditor extends Component {

  render() {

    return (
      <div>
        <SweetForm initialValues={this.props.initialValues} onChange={this.props.onChange}>
          <h3>Instructions</h3>
          <Textarea field="instructions" rows='10' cols='60' />
          <h3>Example</h3>
          <ExampleEditor field="example" onSetExample={this.props.onSetExample} />
          <h3>Notes</h3>
          <Textarea field='notes' rows='10' cols='60' />
          <CandidateFields field='candidateFields' />
        </SweetForm>
      </div>
    );
  }
}

const INITIAL_INSTRUCTIONS = `Tu es un expert en rédaction de texte de présentation de candidats. Tu dois écrire un texte de présentation pour un candidat qui reprend le format du texte dans l’exemple d’après. Le texte doit mettre en avant les compétences du candidats qui sont présentes dans les notes. Le texte doit être divisé en 3 parties :
La première partie est un titre qui comprend le nom de poste du candidat, ses années d’expérience, sa formation, et des informations sur le poste qu’il recherche (nom de poste, stack / techno utilisée).
La seconde partie a un titre. Ce titre est : « son expérience chez «  »XX » » où «  »XX » » est son entreprise actuelle.
La seconde partie décrit ce que le candidat a fait durant sa dernière expérience, dans sa dernière entreprise. Tu peux également, si tu en as l’information, décrire ce qu’il a fait dans ses expériences passées.
La troisième partie a un titre qui est toujours : « » Que recherche-t-il aujourd’hui ? « » lorsque le candidat est un homme, et « que recherche-t-elle aujourd’hui » lorsque c’est une femme.
La troisième partie explique pourquoi le candidat est à la recherche d’une nouvelle entreprise et décrit ce qu’il recherche pour son futur poste (où est-ce qu’il est basé, s’il souhaite travailler en télétravail ou pas).
`

const buildInput = ({ notes, candidateFields }) => {
  const fieldStrings = _.compact(_.map(CANDIATE_FIELDS, ({ label, field }) => {
    const { isActive, value } = (candidateFields || {})[field] || {};
    if (isActive) {
      return `${label}: ${value}`;
    };
  }));
  return `${fieldStrings.join('\n')}\n${notes}`
}

const buildExample = ({ isActive, notes, candidateFields, blurb }) => {
  if (!isActive) {
    return;
  }
  const input = buildInput({ notes, candidateFields });
  return `Input:"""\n${input}"""\nOutput:"""${blurb}"""`
}

const blurbGenerationDataToPrompt = ({ instructions, notes, candidateFields, example }) => {
  let prompt = `${instructions}\n###\n`
  const exampleStr = buildExample(example);
  const inputStr = buildInput({ notes, candidateFields });
  if (exampleStr) {
    prompt += `Example:\n${exampleStr}\n###\n`;
  }
  prompt += `Input:"""\n${inputStr}"""\nOutput:\n`;
  return {
    prompt,
    isOk: instructions && notes,
  };
};

const candidateFieldsFromEnrichedProfile = (data) => {
  const { wishes } = ((data || {}).sourceData || {}).hiresweet || {};
  const groupedKeyPoints = _.findWhere(((data || {}).smartSummary || {}).arguments, ({ type }) => (type == "grouped-key-points"))
  const neutralKeyPoints = (_.first(_.filter((groupedKeyPoints || {}).groups, ({ category }) => (category == "neutral")) || {}) || {}).keyPoints;
  const experienceKeyPoint = _.first(_.filter(neutralKeyPoints, ({ category }) => (category == "experience")))
  return {
    "firstname": {
      value: (data || {}).firstname,
      isActive: true,
    },
    "experienceYears": {
      value: (((experienceKeyPoint || {}).content || {}).fr || "").replace("années", "ans").replace("année", "an"),
      isActive: true,
    },
    "mainEducationSchoolName": {
      value: ((data || {}).mainEducation || {}).schoolName,
      isActive: true,
    },
    "currentJob": {
      value: (((data || {}).headline || {}).content || {}).text,
      isActive: true,
    },
  }
}

class BlurbGenerator extends Component {
  state = {}

  componentDidMount() {
    const candidateFields = candidateFieldsFromEnrichedProfile(this.props.enrichedProfile);
    const initialExample = blurbExamples[0]

    this.setState({
      blurbGenerationData: {
        notes: this.props.notes,
        instructions: INITIAL_INSTRUCTIONS,
        candidateFields,
        example: { id: initialExample.id, isActive: true, ...initialExample.data }
      }
    });
  }

  handleChangeBlurbGenerationData = (values) => {
    this.setState({ blurbGenerationData: values })
  };

  onSetExample = ({ id, data }) => {
    const { blurbGenerationData } = this.state;
    this.setState({
      blurbGenerationData: {
        ...blurbGenerationData,
        example: { id, isActive: true, ...data },
      },
    })

  }

  render() {
    if (!this.state.blurbGenerationData) {
      return <div />;
    }
    const { blurbGenerationData } = this.state;
    const { prompt, isOk } = blurbGenerationDataToPrompt(blurbGenerationData);
    return (
      <Grid>
        <Grid.Row>
          <BlurbGenerationDataEditor
            key={(this.state.blurbGenerationData.example || {}).id}
            initialValues={this.state.blurbGenerationData}
            onChange={this.handleChangeBlurbGenerationData}
            onSetExample={this.onSetExample}
          />
        </Grid.Row>
        <Grid.Row>
          <OpenAiGenerator
            prompt={prompt}
            isOk={isOk}
          />
        </Grid.Row>
      </Grid>
    )

  }
}


export default BlurbGenerator;
