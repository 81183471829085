import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Table, Modal } from 'semantic-ui-react';
import baseUrl from '../../baseUrl';
import SegmentationEditor from './SegmentationEditor';
import ItemsDisplayer from './ItemsDisplayer';
import HistoryEditor from './HistoryEditor';
import { Checkbox, SweetForm, List as SweetList } from 'sweetform';
import moment from 'moment';
import { colorFromName } from './common';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';


const SegmentsBarChart = ({ weeksCounts, selectedSegments, onSelectItems }) => {
  return (
    <BarChart data={weeksCounts} width={700} height={300}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="week" />
      <YAxis />
      <Tooltip />
      {_.map(selectedSegments, ({ segment, color }) => (
        <Bar
          key={segment}
          dataKey={segment}
          fill={color}
          stackId={1}
          onClick={(e) => onSelectItems({ week: e.week, segment })}
        />
      ))
      }
    </BarChart>
  )
}



const SegmentSelector = ({ segmentation, onChange }) => {
  const sortedSegmentation = _.sortBy(segmentation, ({ count }) => -(count || 0));
  return (
    <SweetForm onChange={onChange}>
      <Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'><Checkbox field={"all"} /></Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Segment</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'># Items</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedSegmentation, (segment, i) => {
            const { segment: name, count, countItems, items } = segment;
            return (
              <Table.Row key={i}>
                <Table.Cell textAlign='center'>
                  <Checkbox field={name} />
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {name}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {countItems}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </SweetForm>

  )
}

const getWeeksBetween = ({ minDate, maxDate }) => {
  let cMinDate = minDate ? moment(minDate, "YYYY-MM-DD") : moment("2022-01-01", "YYYY-MM-DD").startOf('isoWeek');
  let cMaxDate = maxDate ? moment(maxDate, "YYYY-MM-DD") : moment().startOf('isoWeek');
  let weeks = []
  while (cMinDate <= cMaxDate) {
    weeks.push(cMinDate.format("YYYY-MM-DD"))
    cMinDate.add(7, 'days')
  }
  return weeks;
}

const makeWeeksCounts = ({ weeksSegmentation, weeks }) => {
  const weekToSegments = _.groupBy(weeksSegmentation, "week");
  return _.map(weeks, (week) => {
    let weekCounts = { week };
    _.each(weekToSegments[week], ({ segment, count }) => {
      weekCounts[segment] = count;
    })
    return weekCounts;
  })
}

class HistorySegmentation extends Component {
  state = {
    segmentation: null,
    selectedItems: null,
  };

  onSubmit = async ({ weekMode, weeksRange, segmentationDefinition }) => {
    try {
      const { segmentation, success: s1, error: e1 } = (await axios.post(`${baseUrl}/watchTables/candidates/segmentation`, {
        segmentationDefinition,
      })).data;
      if (!s1) {
        throw Error(e1)
      }
      const { segmentation: weeksSegmentation, success: s2, error: e2 } = (await axios.post(`${baseUrl}/watchTables/candidates/segmentation`, {
        segmentationDefinition,
        weekMode,
      })).data;
      if (!s2) {
        throw Error(e2)
      }
      const { minDate, maxDate } = weeksRange || {};
      const weeks = getWeeksBetween({ minDate, maxDate });
      const weeksCounts = makeWeeksCounts({ weeksSegmentation, weeks })
      this.setState({
        segmentation,
        weeksCounts,
        weeksSegmentation,
        selectedSegments: null,
        selectedItems: null,
        allSegments: _.pluck(segmentation, "segment"),
      });
    } catch (e) {
      return alert(JSON.stringify(e))
    }
  }

  onSelectItems = ({ week, segment }) => {
    const { weeksSegmentation } = this.state;
    const items = (_.findWhere(weeksSegmentation, { week, segment }) || {}).items;
    this.setState({ selection: { items } });
  }

  onCloseSelection = () => {
    this.setState({ selection: null });
  }

  onChangeSelectedSegments = (selection) => {
    const selectedSegments = selection.all ?
      this.state.allSegments : (
        _.compact(_.map(selection, (isSelected, segment) => isSelected ? segment : null))
      )
    this.setState({
      selectedSegments: _.map(selectedSegments, (n) => ({
        segment: n,
        color: colorFromName(n),
      }))
    })
  }


  render() {

    const { segmentation, weeksCounts, selectedSegments, selection } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <HistoryEditor onSubmit={this.onSubmit} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {segmentation && <SegmentSelector segmentation={segmentation} onChange={this.onChangeSelectedSegments} />}
          </Grid.Column>
          <Grid.Column width={12}>
            {selectedSegments && <SegmentsBarChart onSelectItems={this.onSelectItems} selectedSegments={selectedSegments} weeksCounts={weeksCounts} />}
            {selection && (
              <Modal
                onClose={() => this.onCloseSelection()}
                open
                closeIcon
              >
                <Modal.Content>
                  <ItemsDisplayer key={Math.random()} items={selection.items} />
                </Modal.Content>
              </Modal>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default HistorySegmentation;
