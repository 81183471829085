import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import baseUrl from '../baseUrl';
import OfferSelector from '../SweetComponents/Offer/Selector';

class ImportOfferModal extends Component {

  state = {
    offerId: null,
    offer: null,
  }

  getOfferById = async ({ id }) => {
    try {
      if (!id) {
        throw Error('No offerId provided.');
      }
      const { data } = await axios.get(`${baseUrl}/offers/${id}`);
      return data;
    } catch (e) {
      console.log('Failed to load workplace ', e.message);
      return {};
    }
  }

  handleSave = () => {
    this.props.onSave({ 
      data: this.state.offer
    });
  }

  handleSelectOffer = async ({ id }) => {
    const offer = await this.getOfferById({ id });
    this.setState({ offer });
  }

  render() {
    const { onClose, onSave } = this.props;
    const { offer } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon size="fullscreen">
        <Modal.Header>
          Import Offer
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <OfferSelector mode={'sweethub'} onSelect={this.handleSelectOffer} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' disabled={_.isEmpty(offer)} onClick={this.handleSave}>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}


export default ImportOfferModal;
