import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Button, Modal, Icon } from 'semantic-ui-react';
import { Checkbox, Select, SweetForm } from 'sweetform';
import baseUrl from '../../baseUrl.js';
import ProspectsTable from './ProspectsTable.js';
import { ProspectQuickFilters, filterProspects } from "./ProspectQuickFilters.js";


const AffectationModal = ({ initialValues, handleChange, handleSubmit, handleClose, users }) => {
  return (
    <Modal closeIcon open={true} onClose={handleClose}>
      <Modal.Content>
        <SweetForm onChange={handleChange} initialValues={initialValues}>
          <b>Selected Users</b> <Select multi options={users} field="selectedUsers" labelKey="name" valueKey="id" /> <br />
          <b>Affect in priority with hubspot last communication</b> <Checkbox field="preAffectFromHubspotLastCommunication" /> <br />
        </SweetForm>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleSubmit}> Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}



class ProspectsDump extends React.Component {
  state = {
    loading: true,
    selection: null,
    affectationParams: {
      preAffectFromHubspotLastCommunication: true,
    },
    filters: {
      user: "none",
      completionMode: "hide-completed",
      clientsMode: "no-filter",
      contactsMode: "no-filter",
    },
  };

  loadUsers = async () => {
    const { data: users } = await axios.get(`${baseUrl}/users`);
    this.setState({ users });
  }

  loadDump = async () => {
    this.setState({ loading: true });
    const { dumpId } = this.props;
    const { prospects } = (await axios.get(`${baseUrl}/salesOptimisationApi/prospects/getDump/${dumpId}`)).data;
    console.log({ prospects })
    this.setState({ prospects, loading: false });
  }

  handleChangeSelectedUser = ({ user }) => {
    this.setState({
      selectedUser: user,
    });
  }


  handleChangeCompletionMode = ({ completionMode }) => {
    this.setState({
      selectedCompletionMode: completionMode,
    });
  }

  handleChangeFilters = (filters) => {
    console.log({ filters });
    this.setState({ filters });
  }

  componentDidMount = async () => {
    try {
      await this.loadUsers();
      await this.loadDump();
    } catch (e) {
      console.log(e)
      alert(JSON.stringify(e, null, 4));
    }
  };

  handleOpenAffectationModal = async () => {
    this.setState({ selection: { type: 'affectation-modal' } });
  }

  handleCloseSelection = async () => {
    this.setState({ selection: null });
  }

  handleChangeAffectationParams = async (params) => {
    this.setState({ affectationParams: params });
  }

  handleSubmitAffectationParams = async () => {
    const { dumpId } = this.props;
    const { affectationParams, filters, prospects } = this.state;
    const formatAffectationParams = ({ selectedUsers, preAffectFromHubspotLastCommunication }) => {
      const userIds = selectedUsers.split(';');
      return {
        weightedUsers: _.map(userIds, (userId) => ({ userId, weight: 1 })),
        preAffectFromHubspotLastCommunication: !!preAffectFromHubspotLastCommunication,
      }
    };
    const filteredProspects = filterProspects({ prospects, filters });
    const prospectIds = _.pluck(filteredProspects, 'prospectId');
    await axios.post(`${baseUrl}/salesOptimisationApi/prospects/affectDump/${dumpId}`, {
      affectationParams: formatAffectationParams(affectationParams),
      prospectIds,
    });
    await this.loadDump();
    this.handleCloseSelection();
  };

  handleChangeDumpProspectOwner = async ({ prospect, sweethubUserId }) => {
    const prospectId = prospect.prospectId;
    const newProspects = _.map(this.state.prospects, (stateProspect) => {
      if (stateProspect.prospectId == prospectId) {
        return { ...stateProspect, sweethubUserId };
      }
      return stateProspect;
    })
    await axios.post(`${baseUrl}/salesOptimisationApi/prospects/editDumpItem/${this.props.dumpId}`, {
      prospectId,
      sweethubUserId,
    });
    this.setState({ prospects: newProspects });
  }

  handleChangeDumpProspectHubspotCompanyId = async ({ prospect, hubspotCompanyId }) => {
    const prospectId = prospect.prospectId;
    const newProspects = _.map(this.state.prospects, (stateProspect) => {
      if (stateProspect.prospectId == prospectId) {
        return { ...stateProspect, hubspotData: { companyId: hubspotCompanyId } };
      }
      return stateProspect;
    })
    await axios.post(`${baseUrl}/salesOptimisationApi/prospects/editDumpItem/${this.props.dumpId}`, {
      prospectId,
      hubspotCompanyId,
    });
    this.setState({ prospects: newProspects });
  }

  handlePerformAction = async ({ prospect, action }) => {
    const prospectId = prospect.prospectId;
    const completedReason = action.type
    const newProspects = _.map(this.state.prospects, (stateProspect) => {
      if (stateProspect.prospectId == prospectId) {
        return { ...stateProspect, completedReason };
      }
      return stateProspect;
    })
    const payload = {
      prospect,
      dumpId: this.props.dumpId,
      ...action,
    }
    await axios.post(`${baseUrl}/salesOptimisation/prospectActions`, payload);
    await axios.post(`${baseUrl}/salesOptimisationApi/prospects/editDumpItem/${this.props.dumpId}`, {
      prospectId,
      completedReason,
    });
    this.setState({ prospects: newProspects });
  }


  render() {
    const { loading, prospects, filters, users, affectationParams } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }
    const filteredProspects = filterProspects({ prospects, filters });

    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={14}>
            <ProspectQuickFilters
              handleChange={this.handleChangeFilters}
              initialValues={this.state.filters}
              users={users}
              dumpMode={true}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Button icon onClick={this.handleOpenAffectationModal} color="purple"><Icon name="user" />Make Affectation</Button>
          </Grid.Column>

        </Grid.Row>
        <Grid.Row>
          {!_.isEmpty(filteredProspects) && (
            <ProspectsTable
              prospects={filteredProspects}
              dumpMode={true}
              users={users}
              handleChangeDumpProspectHubspotCompanyId={this.handleChangeDumpProspectHubspotCompanyId}
              handleChangeDumpProspectOwner={this.handleChangeDumpProspectOwner}
              handlePerformAction={this.handlePerformAction}
            />
          )}
        </Grid.Row>
        {this.state.selection && this.state.selection.type == "affectation-modal" && (
          <AffectationModal
            handleChange={this.handleChangeAffectationParams}
            handleClose={this.handleCloseSelection}
            handleSubmit={this.handleSubmitAffectationParams}
            initialValues={affectationParams}
            users={users}
          />
        )}
      </Grid>
    );
  }
}

export default ProspectsDump;
