import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { Radio, SweetForm, List as SweetList } from 'sweetform';
import moment from "moment";
import {
  TypologyFilter,
  SelectSegmentationFields,
  wrapFilterToIdParams,
  SelectLastWeekRange
} from './common';

const INITIAL_VALUES = {
  actionFilter: { selection: "nbSends" },
  activityFilter: { selection: "isActive" },
  segmentationFields: "categories",
  weeksRange: {
    minDate: moment().subtract(2, 'months').startOf('isoWeek').format("YYYY-MM-DD")
  }
}

const stateFromForm = (form) => {
  const { typologyFilters, weekMode, segmentationFields, weeksRange } = form || {};
  return {
    typologyFilters: _.map(typologyFilters, (filt) => wrapFilterToIdParams(filt)),
    segmentationFields: segmentationFields ? segmentationFields.split(";") : [],
    activityFilter: {
      id: weekMode == "first" ? "createdBetween" : "activeBetween",
      params: { ...weeksRange },
    },
    weekMode,
    weeksRange,
  }
}
class HistoryEditor extends Component {
  state = {
    typologyFilters: null,
    segmentationFields: null,
    actionFilter: null,
    activityFilter: null,
    weeksRange: null,
    weekMode: null,
  };
  componentDidMount = () => {
    this.setState(stateFromForm(INITIAL_VALUES));
  }

  handleChange = (form) => {
    this.setState(stateFromForm(form));
  }

  onSubmit = () => {
    const { typologyFilters, weekMode, segmentationFields, activityFilter, weeksRange } = this.state;
    if (!segmentationFields) {
      return alert("No segmentation fields")
    }
    this.props.onSubmit({
      segmentationDefinition: {
        type: "fields",
        mode: "exhaustive",
        fields: segmentationFields,
        filters: [activityFilter, ...typologyFilters],
      },
      weekMode,
      weeksRange,
    })
  }

  render() {

    return (
      <SweetForm onChange={this.handleChange} initialValues={INITIAL_VALUES}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={4}>
              <h3> Activity </h3>
            </Grid.Column>
            <Grid.Column width={12}>
              <Radio
                field='weekMode'
                defaultValue='first'
                options={[
                  { value: 'first', label: 'Creations' },
                  { value: 'all', label: 'Active' },
                ]}
              />
              <SelectLastWeekRange field="weeksRange" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <h3> Typology Filters </h3>
            </Grid.Column>
            <Grid.Column width={14}>
              <SweetList component={TypologyFilter} field="typologyFilters" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <h3> Segment by </h3>
            </Grid.Column>
            <Grid.Column width={14}>
              <SelectSegmentationFields />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button onClick={() => this.onSubmit()}>Submit</Button>
      </SweetForm>
    );
  }
}

export default HistoryEditor;