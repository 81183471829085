import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { Header, Grid, Button, Table } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

// Actions
const SET_JOBS = 'SET_JOBS';
const setJobs = (jobs) => ({ type: SET_JOBS, jobs });

const SET_PIPELINES = 'SET_PIPELINES';
const setPipelines = (pipelines) => ({ type: SET_PIPELINES, pipelines });

const SET_SELECTED_PIPELINE = 'SET_SELECTED_PIPELINE';
const setSelectedPipeline = (pipeline) => ({
  type: SET_SELECTED_PIPELINE,
  pipeline,
});

const SET_TIMER = 'SET_TIMER';
const setTimer = (timer) => ({ type: SET_TIMER, timer });

// Reducers
const jobs = (state = [], action) => {
  switch (action.type) {
    case SET_JOBS:
      return action.jobs;
    default:
      return state;
  }
};

const pipelines = (state = [], action) => {
  switch (action.type) {
    case SET_PIPELINES:
      return action.pipelines;
    default:
      return state;
  }
};

const selectedPipeline = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_PIPELINE:
      return action.pipeline;
    default:
      return state;
  }
};

const timer = (state = null, action) => {
  switch (action.type) {
    case SET_TIMER:
      return action.timer;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  jobs,
  pipelines,
  selectedPipeline,
  timer,
});

// Store
const store = createStore(rootReducer);

// Components
class Jobs extends Component {
  componentDidMount() {
    this.props.onLoad();
    this.props.onTimer(setInterval(this.props.onLoad, 1000));
  }

  componentWillUnmount() {
    clearInterval(this.props.timer);
  }

  render() {
    const { jobs, pipelines, selectedPipeline, onSelect, onPurge } = this.props;

    return (
      <Grid>
        <Grid.Column width={6}>
          <Header as="h2">Pending Pipelines</Header>
          <Table compact="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Pipeline</Table.HeaderCell>
                <Table.HeaderCell>Stage</Table.HeaderCell>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pipelines.map((pipeline) => (
                <Table.Row key={pipeline.key}>
                  <Table.Cell>
                    <a
                      href="#p"
                      onClick={(e) => {
                        e.preventDefault();
                        onSelect(pipeline);
                      }}
                    >
                      {pipeline.key.slice(0, 8)}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{pipeline.state.stage}</Table.Cell>
                  <Table.Cell>{pipeline.state.id}</Table.Cell>
                  <Table.Cell>
                    <Button
                      size="mini"
                      color="red"
                      onClick={() => onPurge(pipeline)}
                    >
                      Purge
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column width={10}>
          <Header as="h2">
            Jobs&nbsp;
            {selectedPipeline ? (
              <small>
                for {selectedPipeline.key}&nbsp;
                <Button size="small" onClick={() => onSelect(null)}>
                  Reset
                </Button>
              </small>
            ) : null}
          </Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Queue</Table.HeaderCell>
                <Table.HeaderCell>Query</Table.HeaderCell>
                <Table.HeaderCell>Pipeline</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {jobs.map((job) =>
                !selectedPipeline ||
                (selectedPipeline &&
                  job.pipelineId === selectedPipeline.key) ? (
                  <Table.Row key={job._id}>
                    <Table.Cell title={moment(job.date).format('YYYY-MM-DD')}>
                      {moment(job.date).format('HH:mm:ss.SSS')}
                    </Table.Cell>
                    <Table.Cell>{job.queue}</Table.Cell>
                    <Table.Cell>
                      {job.queue === 'scrape'
                        ? `${job.type} ${job.source} ${job.query.id}`
                        : ''}
                      {job.queue === 'translate'
                        ? `${job.source} -> ${job.destination} ${job.id ||
                            'ALL'}`
                        : ''}
                      {job.queue === 'merge'
                        ? `${job.query} ${job.type} ${job.id || ''}`
                        : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {job.pipelineType
                        ? `${job.pipelineType} ${job.pipelineId.slice(0, 8)}`
                        : ''}
                    </Table.Cell>
                  </Table.Row>
                ) : null,
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers
const mapSJobs = (state) => ({
  jobs: state.jobs,
  pipelines: state.pipelines,
  selectedPipeline: state.selectedPipeline,
  timer: state.timer,
});

const mapDJobs = (dispatch) => ({
  onLoad: async () => {
    const [logs, pipelines] = await Promise.all([
      axios.get(`${baseUrl}/cerejobs/logs`),
      axios.get(`${baseUrl}/cerejobs/pipelines`),
    ]);

    dispatch(setJobs(logs.data));
    dispatch(setPipelines(pipelines.data));
  },
  onTimer: (id) => dispatch(setTimer(id)),
  onSelect: (pipeline) => dispatch(setSelectedPipeline(pipeline)),
  onPurge: async (pipeline) =>
    axios.delete(`${baseUrl}/cerejobs/pipelines/${pipeline.key}`),
});

const JobsContainer = connect(
  mapSJobs,
  mapDJobs,
)(Jobs);

export default () => (
  <Provider store={store}>
    <JobsContainer />
  </Provider>
);
