import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Popup, Image, Loader } from 'semantic-ui-react';
import i18n from 'i18next';

const softSkills = [
  { id: 'product-design', name: 'Product Design', initials: 'PD', color: '#87d500' },
  { id: 'user-experience', name: 'UX', initials: 'UX', color: '#87d500' },
  { id: 'user-interface', name: 'UI', initials: 'UI', color: '#87d500' },
  { id: 'product-management	', name: 'Product Management', initials: 'PM', color: '#87d500' },

  { id: 'agile-methodology', name: 'Agile Methodology', initials: 'AM', color: '#d50087' },
  { id: 'scrum', name: 'Scrum', initials: 'SM', color: '#d50087' },

  { id: 'business-development', name: 'Business Development', initials: 'BD', color: '#9fdd32' },
  { id: 'growth-hacking', name: 'Growth Hacking', initials: 'GH', color: '#9fdd32' },
  { id: 'customer-success', name: 'Customer Success', initials: 'CS', color: '#9fdd32' },
  { id: 'search-engine-optimization', name: 'SEO', initials: 'SE', color: '#9fdd32' },
  { id: 'search-engine-marketing', name: 'SEM', initials: 'SE', color: '#9fdd32' },

  { id: 'data-science', name: 'Data Science', initials: 'DS', color: '#0087d5' },
  { id: 'machine-learning', name: 'Machine Learning', initials: 'ML', color: '#0087d5' },
  { id: 'deep-learning', name: 'Deep Learning', initials: 'DL', color: '#0087d5' },
  { id: 'data-engineering', name: 'Data Engineering', initials: 'DE', color: '#0087d5' },
  { id: 'business-intelligence', name: 'Business Intelligence', initials: 'BI', color: '#0087d5' },
  { id: 'data-analysis', name: 'Data Analysis', initials: 'DA', color: '#0087d5' },

  { id: 'site-reliability-engineering', name: 'Site Reliability Engineering', initials: 'SR', color: '#1993d9' },
  { id: 'devops', name: 'DevOps', initials: 'DO', color: '#1993d9' },
  { id: 'system-administration', name: 'System Administration', initials: 'SA', color: '#1993d9' },

  { id: '3d', name: '3D', initials: '3D', color: '#4cabe1' },
  { id: 'game-design', name: 'Game Design', initials: 'GD', color: '#4cabe1' },
  { id: 'game-programming', name: 'Game Programming', initials: 'GP', color: '#4cabe1' },
  { id: 'video-games', name: 'Video Games', initials: 'VG', color: '#4cabe1' },

  { id: 'ecommerce', name: 'Ecommerce', initials: 'EC', color: '#93d919' },
  { id: 'project-management', name: 'Project Management', initials: 'PM', color: '#93d919' },
  { id: 'operations', name: 'Operations', initials: 'OP', color: '#93d919' },

  { id: 'quality-assurance', name: 'QA', initials: 'QA', color: '#329fdd' },
  { id: 'test-driven-development', name: 'TDD', initials: 'TD', color: '#329fdd' },
  { id: 'hardware-architecture', name: 'Hardware Architecture', initials: 'HA', color: '#329fdd' },
  { id: 'solutions-engineer', name: 'Solutions Engineer', initials: 'SE', color: '#329fdd' },
];

const getTranslatedText = (translatableText) => {
  const { language } = i18n;
  const languageWithoutCountry = language ? language.split('-')[0] : null;
  const text = translatableText || {};
  return text[languageWithoutCountry] || text.default || '';
};

const getSecureLink = (url) => (!url || !_.isString(url) ? url : url.replace('http://', 'https://'));

const mergeStackItemsWithSameIcon = (stack) => {
  const mergedStackIcons = [];
  const mergedStack = [];
  _.forEach(stack, (stackItem) => {
    if (stackItem.icon && mergedStackIcons.indexOf(stackItem.icon) === -1) {
      mergedStack.push(stackItem);
      mergedStackIcons.push(stackItem.icon);
    } else if (stackItem.icon && mergedStackIcons.indexOf(stackItem.icon) > -1) {
      const sameStackIconIndex = mergedStackIcons.indexOf(stackItem.icon);
      mergedStack[sameStackIconIndex].translatedName += ', ' + stackItem.translatedName;
    } else {
      mergedStack.push(stackItem);
    }
  });
  return mergedStack;
};

export const getFormattedStack = (stack, displaySoftSkills) => {
  const formattedStack = _.map(stack, (stackItem) => {
    if (!stackItem) return null;
    const isSoftSkill =
      !stackItem.icon &&
      stackItem.name &&
      (_.pluck(softSkills, 'name').indexOf(stackItem.name.default) >= 0 ||
        _.pluck(softSkills, 'id').indexOf(stackItem.name.default) >= 0 ||
        _.pluck(softSkills, 'id').indexOf(stackItem.id) >= 0);
    if (displaySoftSkills && isSoftSkill) {
      const softSkill = _.find(
        softSkills,
        (skill) =>
          skill.id === stackItem.id || skill.id === stackItem.name.default || skill.name === stackItem.name.default,
      );
      return softSkill
        ? {
            translatedName: softSkill.name,
            icon: null,
            detailsURL: stackItem.detailsURL, // V4
            ...((stackItem.details || {}).url && { // V5
              details: {
                type: 'url',
                url: stackItem.details.url,
              },
            }),
            softSkill,
            shouldBeDisplayed: stackItem.shouldBeDisplayed !== false,
          }
        : null;
    } else if (stackItem.icon) {
      return {
        translatedName: getTranslatedText(stackItem.name),
        icon: stackItem.icon,
        detailsURL: stackItem.detailsURL, // V4
        ...((stackItem.details || {}).url && { // V5
          details: {
            type: 'url',
            url: stackItem.details.url,
          },
        }),
        shouldBeDisplayed: stackItem.shouldBeDisplayed !== false,
      };
    }
    return null;
  });
  const filteredFormattedStack = _.filter(formattedStack, (stackItem) => (stackItem || {}).translatedName && (stackItem || {}).shouldBeDisplayed);
  return mergeStackItemsWithSameIcon(_.uniq(filteredFormattedStack, (stackItem) => (stackItem || {}).translatedName)).slice(
    0,
    5,
  );
};

class StackItemIconPopup extends React.Component {
  componentWillMount() {
    this.props.loadDetails();
  }
  render() {
    const { name, details, hasDetails } = this.props;
    return (
      <div className={hasDetails ? 'has-details' : ''}>
        <div className='skill-name'>
          <span>{name}</span>
        </div>
        {hasDetails &&
          (details && details.description ? (
            <div className='skill-description'>{getTranslatedText((details || {}).description) || ' '}</div>
          ) : (
            <center>
              <Loader inline active />
            </center>
          ))}
      </div>
    );
  }
}

class StackItemIcon extends React.Component {
  state = { iconError: false };

  loadDetailsCalled = false;

  loadDetails = async () => {
    if (this.loadDetailsCalled) {
      return;
    }
    this.loadDetailsCalled = true;

    const { detailsURL } = this.props;
    if (detailsURL) {
      try {
        const result = (await axios.get(detailsURL, {
          withCredentials: false,
        })).data;
        this.setState({
          details: {
            ...result,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  render() {
    const { name, icon, detailsURL, softSkill } = this.props;
    const { details } = this.state;
    if (this.state.iconError === true) {
      return null;
    }

    if (softSkill) {
      return (
        <Popup
          key={name}
          position='top right'
          size='mini'
          hoverable
          className='skill-popup'
          content={<StackItemIconPopup name={name} loadDetails={this.loadDetails} />}
          trigger={
            <div className='technology-icon'>
              <div className='initials-container' style={{ backgroundColor: softSkill.color }}>
                <div className='initials'>{softSkill.initials}</div>
              </div>
            </div>
          }
        />
      );
    }

    return (
      <Popup
        key={name}
        position='top right'
        size='mini'
        hoverable
        className='skill-popup'
        content={
          <StackItemIconPopup name={name} details={details} hasDetails={!!detailsURL} loadDetails={this.loadDetails} />
        }
        trigger={
          <Image
            className='technology-icon'
            inline
            src={getSecureLink(icon)}
            onError={(e) => {
              this.setState({ iconError: true });
            }}
          />
        }
      />
    );
  }
}

export const Stack = ({ stack, displaySoftSkills }) => {
  const displayedStack = getFormattedStack(stack, displaySoftSkills);
  return (
    <span>
      {_.map(displayedStack, (stackItem) => (
        <StackItemIcon
          key={(stackItem || {}).translatedName}
          name={(stackItem || {}).translatedName}
          detailsURL={(stackItem || {}).detailsURL || ((stackItem || {}).details || {}).url}
          icon={(stackItem || {}).icon}
          softSkill={(stackItem || {}).softSkill}
        />
      ))}
    </span>
  );
};
