import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Icon, Table, Button } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';

class SynchroSpreadsheets extends Component {
  state = {};
  componentWillMount() {
    axios
      .get(`${baseUrl}/uploadView/listTodaysUploadedItems`)
      .then(({ data }) => {
        if (data.error) {
          return alert(data.error);
        }
        if (!data.items) {
          return alert('items not retrieved');
        }
        let collectionFromSweetsheetId = {};
        _.each(data.items, (item) => {
          if (!item.sweetsheetId) {
            return;
          }
          if (!collectionFromSweetsheetId[item.sweetsheetId]) {
            collectionFromSweetsheetId[item.sweetsheetId] = {
              sweetsheetId: item.sweetsheetId,
              offerId: item.offerId,
              spreadsheetId: item.spreadsheetId,
              items: [],
            };
          }
          collectionFromSweetsheetId[item.sweetsheetId].items.push(
            item.content,
          );
          this.setState({
            offerMap: collectionFromSweetsheetId,
            markedOffers: {},
          });
        });
      });
  }
  async synchronizeSpreadsheet({
    sweetsheetId,
    spreadsheetId,
    items,
    noAlert,
  }) {
    const result = (await axios.post(
      `${baseUrl}/uploadView/markAsDoneInSpreadsheet`,
      {
        spreadsheetId,
        emails: _.compact(_.pluck(items, 'email')),
      },
    )).data;
    if (!noAlert) {
      alert(JSON.stringify(result, null, 4));
    } else {
      console.log(result);
    }
    const { markedOffers } = this.state;
    this.setState({
      markedOffers: {
        ...markedOffers,
        [sweetsheetId]: 1,
      },
    });
  }
  async synchronizeAllSpreadsheets(offerMap) {
    if (!offerMap) {
      return alert('offerMap missing');
    }
    const keys = _.keys(offerMap);
    for (let iOffer = 0; iOffer < keys.length; iOffer++) {
      const { sweetsheetId, spreadsheetId, items } = offerMap[keys[iOffer]];

      console.log('synchronizing ' + sweetsheetId + ' with ' + spreadsheetId);

      await this.synchronizeSpreadsheet({
        sweetsheetId,
        spreadsheetId,
        items,
        noAlert: true,
      });
    }
  }
  renderOfferWithItems(offerWithItems) {
    const { sweetsheetId, offerId, spreadsheetId, items } = offerWithItems;
    const { markedOffers } = this.state;
    return (
      <div key={sweetsheetId}>
        <h1
          style={{
            color: markedOffers[sweetsheetId] ? 'green' : 'black',
          }}
        >
          {offerId + ' '}
          {spreadsheetId && (
            <a
              _target="blank"
              href={'https://docs.google.com/spreadsheets/d/' + spreadsheetId}
            >
              <Icon name="google" />
            </a>
          )}
          <Icon
            style={{ cursor: 'pointer' }}
            name="random"
            onClick={() => this.synchronizeSpreadsheet(offerWithItems)}
          />
        </h1>
        <Table>
          {_.map(items, ({ firstname, lastname, email }) => (
            <Table.Row>
              <Table.Cell>{firstname}</Table.Cell>
              <Table.Cell>{lastname}</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
        <br />
      </div>
    );
  }
  render() {
    const offerMap = this.state.offerMap || {};
    return (
      <div>
        {!_.isEmpty(offerMap) && (
          <Button onClick={() => this.synchronizeAllSpreadsheets(offerMap)}>
            Synchronize All
          </Button>
        )}
        {_.map(offerMap, (offerWithItems) =>
          this.renderOfferWithItems(offerWithItems),
        )}
      </div>
    );
  }
}

export default SynchroSpreadsheets;
