import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

import { Button, Card, Checkbox, Form, Grid, Header, Icon, List, Modal, Segment, Table } from 'semantic-ui-react';
import { Input, Select, SweetForm, List as SweetList } from 'sweetform';
import React, { Component } from 'react';
import { buildFilter, formScorerToQuery } from './BuildAdministrativeFilters.js';

export class CustomFiltersMenu extends Component {
  async componentDidMount() {
    const { onChangeCustomFilters } = this.props;
    const savedCustomFilters = (await this.getSavedCustomFilters()) || [];
    await onChangeCustomFilters(savedCustomFilters);
    this.setState({ savedCustomFilters });
  }

  handleChangeFilters = async (params) => {
    const { onChangeCustomFilters } = this.props;
    onChangeCustomFilters(formScorerToQuery(params.filters));
    this.setState({ newFilters: params.filters });
  };

  getSavedCustomFilters = async () => {
    const { selectedClient, selectedMissionId, selectedRecommenderSystemId } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: selectedRecommenderSystemId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getRecommenderSystemCustomFilters';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      return result.data;
    }
  };

  setSavedCustomFiltersAsCurrentCustomFilters = async () => {
    const { onChangeCustomFilters } = this.props;
    const savedCustomFilters = (await this.getSavedCustomFilters()) || [];
    await onChangeCustomFilters(savedCustomFilters);
    this.setState({ savedCustomFilters });
  };

  updateCustomFiltersInMongo = async (newFilters) => {
    const { selectedClient, selectedMissionId, selectedRecommenderSystemId } = this.props;
    if (_.isUndefined(selectedRecommenderSystemId)) {
      alert('No recommender System selected');
    }
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: selectedRecommenderSystemId,
      customFilters: newFilters,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/updateRecommenderSystemCustomFilters';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    }
  };

  updateCustomFiltersInMongoAndDisplay = async (newFilters) => {
    this.updateCustomFiltersInMongo(newFilters);
    this.setState({ savedCustomFilters: newFilters });
  };

  render() {
    const { setSavedCustomFiltersAsCurrentCustomFilters } = this.props;
    const { newFilters, savedCustomFilters } = this.state || {};
    const Filter = buildFilter(false, false);

    return (
      <div>
        <h5> Custom Filters Definition</h5>
        <SweetForm onChange={(params) => this.handleChangeFilters(params)}>
          <SweetList field='filters' component={Filter} />
        </SweetForm>
        <br />
        <br />
        <Button
          color='green'
          onClick={() => this.updateCustomFiltersInMongoAndDisplay(formScorerToQuery(newFilters || []))}
        >
          Save Custom Filters In Mongo
        </Button>
        <Card
          style={{
            width: '100%',
          }}
        >
          <Card.Header>Saved Custom Filters Definition</Card.Header>
          <Card.Content>{JSON.stringify(savedCustomFilters, null, 4)}</Card.Content>
        </Card>
        <Button color='green' onClick={() => setSavedCustomFiltersAsCurrentCustomFilters()}>
          Set Save settings as current settings
        </Button>
      </div>
    );
  }
}

export default CustomFiltersMenu;
