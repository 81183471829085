import React from 'react';
import _ from 'underscore';
import { Grid, Container, Card, Icon, Label, Image, Button } from 'semantic-ui-react';
import { SweetForm, Input } from 'sweetform';
import baseUrl from '../../baseUrl';
import EditWorkplaceModal from './EditWorkplaceModal';
import PhotoLinkModal from '../../components/PhotoLinkModal';
import axios from 'axios';

class WorkplacesOverview extends React.Component {
  state = {
    search: '',
    openWorkplaceModal: false,
    editedWorkplace: null,
  };

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  onEditWorkplace = (workplace) => {
    this.setState({
      openWorkplaceModal: true,
      editedWorkplace: workplace,
    });
  };

  onCloseEditWorkplace = () => {
    this.setState({
      openWorkplaceModal: false,
      editedWorkplace: null,
    });
  };

  onCreateWorkplace = () => {
    this.setState({
      openWorkplaceModal: true,
      editedWorkplace: null,
    });
  };

  render() {
    const {
      workplaces,
      onSelectWorkplace,
      selectedWorkplace,
      onSubmitAddWorkplace,
      onSubmitEditWorkplace,
      onDeleteWorkplace,
      offersCount,
    } = this.props;
    const { search, openWorkplaceModal, editedWorkplace } = this.state;

    const indexOfSearch = ({ name, id }, search) => {
      const resName = (name || '').toLowerCase().indexOf(search.toLowerCase());
      if (resName >= 0) {
        return resName;
      }
      return (id || '').toLowerCase().indexOf(search.toLowerCase());
    }

    const filteredWorkplaces = !search
      ? _.filter(workplaces, ({ networkLevel }) => networkLevel === 0 || networkLevel === 1)
      : _.sortBy(_.filter(workplaces, (workplace) => indexOfSearch(workplace, search) >= 0), (workplace) =>
        indexOfSearch(workplace, search),
      );

    return (
      <Container>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5} verticalAlign='middle'>
                    <h3>
                      Workplaces{' '}
                      <Icon
                        onClick={this.onCreateWorkplace}
                        name='add circle'
                        color='blue'
                        style={{ cursor: 'pointer' }}
                      />
                    </h3>
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <SweetForm onChange={this.onChangeSearch}>
                      <Input field='search' />
                    </SweetForm>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
            {openWorkplaceModal && editedWorkplace ? (
              <EditWorkplaceModal
                initialValues={editedWorkplace}
                onCancel={this.onCloseEditWorkplace}
                onSubmit={onSubmitEditWorkplace}
                onDelete={onDeleteWorkplace}
              />
            ) : null}
            {openWorkplaceModal && !editedWorkplace ? (
              <EditWorkplaceModal
                onCancel={this.onCloseEditWorkplace}
                onSubmit={onSubmitAddWorkplace}
                creationMode={true}
              />
            ) : null}
            <Card.Description>
              <Grid>
                {filteredWorkplaces.map((workplace) => {
                  return (
                    <Grid.Row key={workplace.id}>
                      <Grid.Column
                        width={10}
                        onClick={() => onSelectWorkplace(workplace)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          style={
                            selectedWorkplace && selectedWorkplace.id === workplace.id
                              ? {
                                fontWeight: 'bold',
                                color: 'green',
                                fontSize: '13px',
                              }
                              : {}
                          }
                        >
                          {(workplace.name || workplace.id) +
                            ((offersCount[workplace.id] && ` (${offersCount[workplace.id]})`) || '')}
                        </span>
                      </Grid.Column>
                      <Grid.Column width={4} floated='right' textAlign='right'>
                        {workplace.platformId ? (
                          <a
                            href={`https://app.hiresweet.com/client/${workplace.platformId}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <Image
                              src='/images/sweetapp-logo.png'
                              style={{ width: '16px', height: '16px', marginRight: '8px' }}
                            />
                          </a>
                        ) : null}
                        <Icon
                          name='write'
                          size='large'
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.onEditWorkplace(workplace)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

export default WorkplacesOverview;

const LabelWithValue = ({ value, label, options, ...props }) => {
  if (typeof value === 'number')
    return <Label {...props}>{label + (options ? options[value] : value.toString())}</Label>;
  if (_.isEmpty(value)) return null;
  if (typeof value === 'string') return <Label {...props}>{label + (options ? options[value] : value)}</Label>;
  if (_.isArray(value))
    return (
      <span>
        {_.map(value, (val, id) => (
          <LabelWithValue key={id} label={label} value={val} />
        ))}
      </span>
    );
  return null;
};


export class WorkplaceOverview extends React.Component {
  state = {
    openWorkplaceModal: false,
  };

  onCloseEditWorkplace = () => {
    this.setState({
      openWorkplaceModal: false,
    });
  };

  onOpenEditWorkplace = () => {
    this.setState({
      openWorkplaceModal: true,
    });
  };

  onOpenEditPhotoLink = () => {
    this.setState({
      openEditPhotoLinkModal: true,
    });
  };

  onCloseEditPhotoLink = () => {
    this.setState({
      openEditPhotoLinkModal: false,
    });
  };

  onSubmitPhotoLink = async ({ photoLink }) => {
    try {
      console.log({ id: "submitPhotolink", photoLink })
      const workplaceId = (this.props.selectedWorkplace || {}).id;
      const res = (await axios.put(`${baseUrl}/salesboard/workplaces/${workplaceId}`, { photoLink })).data;
      this.props.onReloadWorkplace({ workplaceId });
    } catch (e) {
      alert(JSON.stringify(e))
    }
  }

  render() {
    return (
      <div>
        <WorkplaceDetails
          onOpenEditPhotoLink={this.onOpenEditPhotoLink}
          onOpenEditWorkplace={this.onOpenEditWorkplace}
          selectedWorkplace={this.props.selectedWorkplace}
        />
        {this.state.openEditPhotoLinkModal ? (
          <PhotoLinkModal
            onClose={this.onCloseEditPhotoLink}
            photoLink={(this.props.selectedWorkplace || {}).photoLink}
            setPhotoLink={({ photoLink }) => this.onSubmitPhotoLink({ photoLink })}
          />
        ) : null}
        {this.state.openWorkplaceModal ? (
          <EditWorkplaceModal
            initialValues={this.props.selectedWorkplace}
            onCancel={this.onCloseEditWorkplace}
            onSubmit={this.props.onSubmitEditWorkplace}
            onDelete={this.props.onDeleteWorkplace}
          />
        ) : null}
        {this.state.openWorkplaceModal ? (
          <EditWorkplaceModal
            initialValues={this.props.selectedWorkplace}
            onCancel={this.onCloseEditWorkplace}
            onSubmit={this.props.onSubmitEditWorkplace}
            onDelete={this.props.onDeleteWorkplace}
          />
        ) : null}
      </div>
    )
  }
}


const WorkplaceDetails = ({ onOpenEditWorkplace, onOpenEditPhotoLink, selectedWorkplace }) => {
  const {
    id,
    name,
    type,
    creationDate,
    industriesLabeled,
    stackLabeled,
    companyTypes,
    platformId,
    staffCountRange,
    status,
    prestige,
    networkLevel,
    adminTagsText,
    description,
    photoLink,
    schoolType,
    shortDescription
    //educationFieldPrestige,
  } = selectedWorkplace || {};
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>

          <Grid columns={2}>
          <Grid.Column width={2}>
              <Image
                src={photoLink || '/images/default-avatar-1.svg'}
                circular
                style={{
                  width: '36px',
                  height: '36px',
                  cursor: 'pointer'
                }}
                onError={(e) => {
                  e.target.src = '/images/default-avatar-1.svg';
                }}
                onClick={() => onOpenEditPhotoLink()}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <h3>
                <Icon
                  name='write'
                  style={{ cursor: 'pointer' }}
                  onClick={onOpenEditWorkplace}
                />
                {name || id ? (
                  <span>
                    {name && name + ' - '}
                    <code>{id}</code>
                  </span>
                ) : (
                  'Select a workplace on the left panel.'
                )}
                {

                }
              </h3>
            
            </Grid.Column>
          </Grid>

        </Card.Header>
        <Card.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <LabelWithValue value={type} label='type: ' />
                <LabelWithValue value={schoolType} label='school type :' />
                <LabelWithValue value={companyTypes} label='company type: ' />
                <LabelWithValue value={industriesLabeled} label='industry: ' />
                <LabelWithValue value={stackLabeled && stackLabeled.join('|')} label='stack: ' />
                <LabelWithValue value={creationDate} label='created: ' />
                <LabelWithValue value={platformId} label='platform Id: ' />
                <LabelWithValue value={staffCountRange && staffCountRange.min} label='staff min: ' />
                <LabelWithValue value={staffCountRange && staffCountRange.max} label='staff max: ' />
                <LabelWithValue value={status} label='status: ' />
                <LabelWithValue value={prestige} label='prestige: ' />
                <LabelWithValue value={networkLevel} label='network level: ' />
                <LabelWithValue value={adminTagsText} label='admin tags: ' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column>{shortDescription}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
