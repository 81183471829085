import _ from 'underscore';
import axios from 'axios';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid, Header, List, Icon, Button, Loader, Segment } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import LinkedinEditorModal from '../../DataPointSandbox/LinkedinEditorModal';
import LinkedinProfileView from '../../SweetComponents/LinkedinProfile/View';
import ProfileSelectableEducations from './ProfileSelectableEducations';
import profileDataManager from '../../SweetWork/profileData';
import getProfileV5FromLinkedinV4 from '../../SweetComponents/LinkedinProfile/profileV5FromLinkedinV4';
import ReactJson from 'react-json-view';


class EducationSandbox extends Component {
  state = {
    edition: null,
    linkedinProfile: null,
    linkedinId: 'jebarjonet',
    selectedEducationNumber: null,
    enrichedEducation: null,
    profileLoading: false,
    enrichedEducationLoading: false
  };

  getLinkedinProfileFromId = async ({ id }) => {
    const url = baseUrl + '/sourceData/linkedin/' + id;
    const { data } = await axios.get(url);
    return (data || {}).data || null;
  };

  componentDidMount = async () => {
    const { initialLinkedinId } = this.props;

    const getInitialData = async () => {
      let initialLinkedin;

      if (initialLinkedinId) {
        const linkedinProfile = await this.getLinkedinProfileFromId({ id: initialLinkedinId });
        if (!linkedinProfile) {
          throw Error('no linkedin found for id ' + initialLinkedinId);
        }
        initialLinkedin = linkedinProfile;
      }

      return { initialLinkedin };
    };

    try {
      const { initialLinkedin } = await getInitialData();
      if (initialLinkedin) {
        await this.handleSaveLinkedinProfile({ data: initialLinkedin });
      }
    } catch (e) {
      return alert(e.message);
    }
  };

  getEnrichedEducation = async (education) => {

    const enrichedEducation = (await axios.post(`${baseUrl}/monitoring/get_enriched_education`, education)).data;
    return enrichedEducation;
  };

  handleOpenLinkedinEditionModal = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: 'linkedin-profile',
        initialValue: this.state.linkedinProfile === null ? null : this.state.linkedinProfile.data,
      },
    });
  };

  handleSaveLinkedinProfile = async ({ data }) => {
    this.setState({ edition: null });
    this.setState({ profileLoading: true });
    this.setState({ enrichedEducationLoading: true });
    await this.setState({linkedinProfile: {data}});
    this.setState({ profileLoading: false });
    await this.handleSetSelectedEducationNumber(this.state.selectedEducationNumber);
    this.setState({ enrichedEducationLoading: false });
  };

  handleSetSelectedEducationNumber = async (index) => {
    this.setState({ enrichedEducationLoading: true });
    this.setState({selectedEducationNumber: index})
    if (index===null || this.state.linkedinProfile === null) {
      this.setState({enrichedEducation: null});
      this.setState({ enrichedEducationLoading: false });
    } else {
      try {
        const education = this.state.linkedinProfile.data.education;
        if (education === null || education.length <= index) {
          this.setState({enrichedEducation: null});
        } else {
          const enrichedEducation = await this.getEnrichedEducation(education[index]);
          this.setState({enrichedEducation: enrichedEducation});
        }
        this.setState({ enrichedEducationLoading: false });
      }
      catch(e) {
        this.setState({ enrichedEducationLoading: false });
        return alert(e.message);
      }
    }
    
  };

  handleCloseEdition = () => {
    this.setState({ edition: null });
  };

  render() {
    const { linkedinProfile, linkedinId, profileLoading, enrichedEducation, enrichedEducationLoading, edition } = this.state;
    const displayEducations = linkedinProfile && profileDataManager(
                  getProfileV5FromLinkedinV4(
                    {linkedinId: linkedinId, linkedinData: linkedinProfile.data}
                    ), () => null).getEducation().length > 0;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <div>
              <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={this.handleOpenLinkedinEditionModal}>
                Edit Linkedin
              </a>
              {(edition || {}).type === 'linkedin-profile' && (
                <LinkedinEditorModal
                  key={edition.key}
                  onClose={this.handleCloseEdition}
                  onSave={this.handleSaveLinkedinProfile}
                  initialValue={edition.initialValue}
                />
              )}
              {!linkedinProfile || profileLoading ? (profileLoading && <Loader active inline />) : <LinkedinProfileView data={linkedinProfile.data} isV4={true}/>}
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment>
              <Header>
                Select Education
              </Header>
              {
                displayEducations &&
                <div>
                  <ProfileSelectableEducations
                  education={
                    profileDataManager(
                      getProfileV5FromLinkedinV4(
                        {linkedinId: linkedinId, linkedinData: linkedinProfile.data}
                        ), () => null
                      ).getEducation()
                  }
                  setSelectedEducationNumber={(index) => this.handleSetSelectedEducationNumber(index)}
                  />
                </div>
              }
            </Segment>
            <Segment>
              <Header>
                Enriched Education
              </Header>

              {enrichedEducationLoading ? (<Loader active inline />) : (enrichedEducation && <ReactJson src={enrichedEducation} />)}

              {/*<div style={{'white-space': 'pre-wrap'}}>
                {enrichedEducationLoading ? (<Loader active inline />) : JSON.stringify(enrichedEducation, null, '\t')}
              </div>*/}

            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

// const withInitialQuery = (WrappedComponent) =>
//   class extends React.Component {
//     render() {
//       return (
//         <Switch>
//           <Route exact path='/monitoring/knowledge/educations/sandbox'>
//             {({ location }) => {
//               const queryValues = queryString.parse(location.search);

//               const initialLinkedinId = queryValues.linkedin;
//               return <WrappedComponent initialLinkedinId={initialLinkedinId} />;
//             }}
//           </Route>
//         </Switch>
//       );
//     }
//   };

export default EducationSandbox;
