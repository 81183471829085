import _ from 'underscore';
import React from 'react';
import { Statistic } from 'semantic-ui-react';

const StatsTable = ({
  id,
  stats,
  handleLoadProfilesSelection,
  handleLoadClientsSelection,
  mode, // "offers" or "profiles"
}) => {
  const colorFromKey = {
    '#contacted': 'blue',
    '#answered': 'olive',
    '#in-process': 'green',
    '#hired': 'blue',
    '#disqualified': 'orange',
    '#skipped': 'red',
  };
  return (
    <Statistic.Group size='tiny' style={{ paddingLeft: 10, paddingRight: 0 }}>
      {_.map(
        ['#pool', '#pending', '#contacted', '#answered', '#in-process', '#hired', '#disqualified', '#skipped'],
        (key, index) => {
          const nDisqualifiedInState = stats[key + '#disqualified'] || 0;
          const hasRecentlyInState = (stats[key + '#recently'] || 0) > 0;
          const nInState = stats[key] || 0;
          return (
            <Statistic
              key={index}
              color={colorFromKey[key] || 'black'}
              style={{
                ...(id && { cursor: 'pointer' }),
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 5,
                marginTop: 5,
              }}
              onClick={() =>
                id &&
                (mode === 'clients'
                  ? handleLoadProfilesSelection({
                      clientId: id,
                      state: key.replace('#', ''),
                    })
                  : handleLoadClientsSelection({
                      sheetItemId: id,
                      state: key.replace('#', ''),
                    }))
              }
            >
              <Statistic.Value>
                {nInState - nDisqualifiedInState}
                {nDisqualifiedInState ? (
                  <sup style={{ color: 'maroon' }}>
                    <small>{nDisqualifiedInState}</small>
                  </sup>
                ) : null}
              </Statistic.Value>
              <Statistic.Label style={{ ...(hasRecentlyInState && { backgroundColor: '#FFFF00' }) }}>
                {key.replace('#', '')}
              </Statistic.Label>
            </Statistic>
          );
        },
      )}
    </Statistic.Group>
  );
};

export default StatsTable;
