import React from 'react';
import moment from 'moment';
import hjson from 'hjson';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Label, Table } from 'semantic-ui-react';
import { ReactGhLikeDiff } from 'react-gh-like-diff';

import 'react-gh-like-diff/lib/diff2html.min.css';

import { url } from './routes';
import { cleanDataProfile } from './redux';

function getStringAttributes(d) {
  const o = {};
  Object.keys(d)
    .sort()
    .forEach((key) => {
      if (typeof d[key] === 'string') {
        o[key] = d[key];
      }
    });
  return o;
}

class RegressionFailureDetail extends React.Component {
  render() {
    if (!this.props.failure || this.props.failure.get('failure').isEmpty()) {
      return null;
    }
    const { failure, history, newlyScraped } = this.props.failure.toJS();

    const oldProfile = cleanDataProfile(failure.oldProfile || {});
    const newProfile = cleanDataProfile(failure.newProfile || {});

    const { itemId } = failure;

    const oldData = oldProfile.data || {};
    const newData = newProfile.data || {};
    return (
      <div className="regression-detail">
        <Button onClick={() => this.props.history.goBack()}>Retour</Button>
        <Button.Group floated="right">
          <Button
            onClick={() =>
              window.open(
                `https://www.linkedin.com/in/${itemId}`,
                'linkedin',
                'rel=noopener noreferrer',
              )
            }
          >
            LinkedIn
          </Button>
        </Button.Group>
        <h2>Historique</h2>
        Last scrap date:{' '}
        {newlyScraped && newlyScraped.scrapHistory
          ? moment(newlyScraped.scrapHistory.slice(-1).timestamp).format(
              'ddd D MMM HH:mm:ss',
            )
          : 'Never'}
        <Table>
          <Table.Body>
            {history.map((attempt) => (
              <Table.Row key={attempt.timestamp}>
                <Table.Cell>
                  {moment(attempt.timestamp).format('YY-MM-DD HH:mm:ss')}
                </Table.Cell>
                <Table.Cell>
                  {attempt._id === failure._id ? (
                    attempt.worker
                  ) : (
                    <Link
                      to={url('regressions:failure', {
                        failureId: attempt._id,
                      })}
                    >
                      {attempt.worker}
                    </Link>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {[].concat(
          ...failure.regressions.map((regression, i) => {
            return [
              <br key={`${i}-br-1`} />,
              <Label
                color={regression.type === 'error' ? 'red' : 'yellow'}
                key={regression.message}
                size="big"
              >
                {regression.message}
              </Label>,
              <br key={`${i}-br-2`} />,
            ];
          }),
        )}
        <h2>Details</h2>
        <ReactGhLikeDiff
          past={hjson.stringify(getStringAttributes(oldData))}
          current={hjson.stringify(getStringAttributes(newData))}
          options={{
            matching: 'lines',
          }}
        />
        <h2>Experiences</h2>
        <ReactGhLikeDiff
          past={hjson.stringify(oldData.experiences)}
          current={hjson.stringify(newData.experiences)}
          options={{
            matching: 'lines',
          }}
        />
        <h2>Education</h2>
        <ReactGhLikeDiff
          past={hjson.stringify(oldData.education)}
          current={hjson.stringify(newData.education)}
          options={{
            matching: 'lines',
          }}
        />
        <h2>Skills</h2>
        <ReactGhLikeDiff
          past={hjson.stringify(oldData.skills)}
          current={hjson.stringify(newData.skills)}
          options={{
            matching: 'lines',
          }}
        />
      </div>
    );
  }
}

function mapStateToPropsRegressionDetail(state) {
  return {
    failure: state.failuresByUser.failure,
  };
}

export default connect(mapStateToPropsRegressionDetail)(
  RegressionFailureDetail,
);
