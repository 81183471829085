import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Table, Label, Popup, Modal, Icon, Statistic, Select as SSelect, Button, Pagination, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ProspectContextView from './ProspectContextView';
import HubspotLinkEditor from './HubspotLinkEditor';

const PROSPECTS_PER_PAGE = 100;

const prioToColor = {
  10: 'red',
  9: 'orange',
  8: 'yellow',
  7: 'olive',
  6: 'green',
  5: 'teal',
  4: 'blue',
  3: 'violet',
  2: 'purple',
  1: 'pink',
}

const prioToBackgroundColor = {
  10: '#fac2be',
  9: '#fadbbe',
  8: '#faf9be',
  7: '#e2fabe',
  6: '#d0fabe',
  5: '#befae2',
  4: '#bef1fa',
  3: '#bec3fa',
  2: '#d5befa',
  1: '#fabefa',
}
const getBackgroundColor = (priority) => (
  prioToBackgroundColor[priority] || '#c7c9c9'
)

const getColor = (priority) => (
  prioToColor[priority] || 'grey'
)

const MiniOffer = ({ miniOffer }) => {
  const { name, source, link, relevance, publicationDate, creationDate } = miniOffer || {}
  return (
    <div>
      <Label>{source}</Label>
      <Label>{relevance}</Label>
      <Link to={link} target="_blank">{(name || "").slice(0, 50)}</Link>
      ({moment(publicationDate).fromNow()})
    </div>
  )
}
const ClientData = ({ clientData, clientId }) => {
  if (!clientId) {
    return <div>Not found</div>
  }
  return (
    <div>
      <a href={`https://app.hiresweet.com/client/${clientId}`} target="_blank">{clientId}</a><br />
      <b>Send:</b> {clientData.lastSend ? moment(clientData.lastSend).format('YYYY-MM-DD') : 'never'}<br />
      <b>Action:</b> {clientData.lastAction ? moment(clientData.lastAction).format('YYYY-MM-DD') : 'never'}<br />
    </div>
  )
}

const HubspotData = ({ sources, hubspotData, onSelectHubspotCompanyId, onSubmitNewHubspotCompanyId }) => {
  const { lastContact, lastAnswer, companyId, deals } = hubspotData || {};
  if (!hubspotData) {
    return <HubspotLinkEditor hubspotCompanyId={companyId} sources={sources} onSubmitNewHubspotCompanyId={onSubmitNewHubspotCompanyId} />;
  }
  return (
    <div>
      {/*<a href={`https://app.hubspot.com/contacts/1768483/company/${companyId}`} target="_blank">{companyId}</a>*/}
      <span style={{ cursor: 'pointer' }} onClick={() => onSelectHubspotCompanyId({ hubspotCompanyId: companyId })} >
        <b>Context</b> <Icon name="eye" />
      </span><br />
      <b>Contact:</b> {lastContact ? lastContact : 'never'}<br />
      <b>Answer:</b> {lastAnswer ? lastAnswer : 'never'}<br />
      {!_.isEmpty(deals) ? (
        <div> {_.map(deals, ({ name, stage, pipeline }) => {
          return (
            <div>
              <Label>
                <span>{name || '?'}</span>
                <Label.Detail>{pipeline || '?'}{' - '}{stage || '?'}</Label.Detail>
              </Label>
              <br />
            </div>

          )
        })}
        </div>
      ) : (
        <div>No deals <br /></div>
      )}
      <HubspotLinkEditor hubspotCompanyId={companyId} sources={sources} />
    </div>
  )
}

const CompanyData = ({ companyData }) => {
  return (
    <div>
      {!_.isEmpty(companyData.companyTypes) && `Company types : ${(companyData.companyTypes || {}).join(', ')}`}<br />
      {!_.isEmpty(companyData.industries) && `Industries : ${(companyData.industries || {}).join(', ')}`}<br />
      {(companyData.staffCountMin || companyData.staffCountMax) && `Employees : ${companyData.staffCountMin} - ${companyData.staffCountMax}`}<br />
    </div>
  )
}

const SelectOwner = ({ users, handleSelectUser }) => {
  const userOptions = _.map(users, ({ id, name }) => ({ value: id, text: name }));
  return (
    <div style={{ display: 'inline-block', marginLeft: 20 }}>
      <b>Owner: </b>
      <SSelect
        search
        options={userOptions}
        onChange={(e, { value }) => {
          handleSelectUser({ user: value });
        }}
      />
    </div>
  )
}


class ProspectsTable extends React.Component {
  state = {
    loading: true,
    selection: null,
    activePage: 1
  };

  handleCloseSelection = () => {
    this.setState({ selection: null })
  }

  handleOpenMiniOffers = ({ miniOffers }) => {
    this.setState({
      selection: {
        type: 'miniOffers',
        miniOffers,
      }
    })
  }

  handleSelectHubspotCompanyId = ({ hubspotCompanyId }) => {
    this.setState({
      selection: {
        type: 'hubspotCompany',
        hubspotCompanyId,
      }
    })
  }

  handleOpenOwnerModal = ({ prospect }) => {
    this.setState({
      selection: {
        type: 'ownerModal',
        prospect,
      }
    })
  }

  handleSelectOwner = ({ user }) => {
    const prospect = this.state.selection.prospect;
    this.props.handleChangeDumpProspectOwner({ prospect, sweethubUserId: user });
    this.handleCloseSelection();
  };

  handleSetActivePage = (e, { activePage }) => {
    this.setState({ activePage })
  }

  render() {
    const {
      prospects,
      dumpMode,
    } = this.props;
    const { selection, activePage } = this.state;
    const sortedProspects = _.sortBy(prospects, 'priority').reverse();
    const prioCount = _.countBy(sortedProspects, 'priority');

    const nbProspects = sortedProspects.length;
    const nbPages = Math.ceil(nbProspects / PROSPECTS_PER_PAGE);
    const pageProspects = sortedProspects.slice((activePage - 1) * PROSPECTS_PER_PAGE, activePage * PROSPECTS_PER_PAGE);


    return (
      <div>
        <Grid>
          <Grid.Column width={2}>
            <b>{nbProspects} items</b>
          </Grid.Column>
          <Grid.Column width={7}>
            <Pagination
              activePage={activePage}
              onPageChange={this.handleSetActivePage}
              totalPages={nbPages}
              secondary
            />
          </Grid.Column>
          <Grid.Column width={7}>
            <Statistic.Group size={'mini'}>
              {_.map([10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0], (prio) => (
                <Statistic color={prioToColor[prio]}>
                  <Statistic.Value>{prioCount[prio] || 0}</Statistic.Value>
                  <Statistic.Label>prio {prio}</Statistic.Label>
                </Statistic>
              ))
              }
            </Statistic.Group>
          </Grid.Column>

        </Grid>


        <Table>
          <Table.Header>
            <Table.Row>
              {dumpMode && <Table.HeaderCell>Owner</Table.HeaderCell>}
              {dumpMode && <Table.HeaderCell>Actions</Table.HeaderCell>}
              <Table.HeaderCell>Prio</Table.HeaderCell>
              <Table.HeaderCell>Prospect</Table.HeaderCell>
              <Table.HeaderCell>Offers</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>Hubspot</Table.HeaderCell>
              <Table.HeaderCell>Company Data</Table.HeaderCell>

            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(pageProspects, (prospect) => {
              const { completedReason, sweethubUserId, sources, hubspotData, clientId, clientData, companyData, miniOffers, priority, priorityReasons, companyName, prospectId, offersRelevance } = prospect;
              const displayedOffers = _.first(miniOffers, 3);
              return (
                <Table.Row
                  key={prospectId}
                //style={{ backgroundColor: getBackgroundColor(priority) }}
                >
                  {dumpMode && (
                    <Table.Cell>
                      <b>{sweethubUserId || 'no owner'}</b><br />
                      <Icon name='pencil' onClick={() => this.handleOpenOwnerModal({ prospect })} style={{ cursor: 'pointer' }} />
                    </Table.Cell>
                  )}
                  {dumpMode && (
                    <Table.Cell>
                      {completedReason ? (
                        <div>{completedReason}</div>
                      ) : (
                        <div>
                          <Button size='mini' onClick={() => this.props.handlePerformAction({ prospect, action: { 'type': 'mark-prospect-completed' } })} icon color="blue">
                            <Icon name="check"/>
                            Mark completed
                          </Button>
                          <Button size='mini' onClick={() => this.props.handlePerformAction({ prospect, action: { 'type': 'skip-prospect' } })} basic icon color="red">
                            <Icon name="close"/>
                            Skip prospect
                          </Button>
                        </div>
                      )}
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    <Popup
                      trigger={<Label color={getColor(priority)}>{priority}</Label>}
                      content={<div>{priorityReasons}</div>}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {companyName}
                  </Table.Cell>
                  <Table.Cell>{`${miniOffers.length} offers`}<br />{`relevance ${offersRelevance}`}</Table.Cell>
                  <Table.Cell>
                    {_.map(displayedOffers, (miniOffer) => (
                      <span key={miniOffer.id}>
                        <MiniOffer miniOffer={miniOffer} /><br />
                      </span>
                    ))
                    }
                    <a style={{ cursor: 'pointer' }} onClick={() => this.handleOpenMiniOffers({ miniOffers })}>{`See all (${miniOffers.length})`}</a>
                  </Table.Cell>
                  <Table.Cell><ClientData clientData={clientData} clientId={clientId} /></Table.Cell>
                  <Table.Cell><HubspotData
                    hubspotData={hubspotData}
                    onSelectHubspotCompanyId={this.handleSelectHubspotCompanyId}
                    sources={sources}
                    onSubmitNewHubspotCompanyId={dumpMode ? (hubspotCompanyId) => this.props.handleChangeDumpProspectHubspotCompanyId({ prospect, hubspotCompanyId }) : null}
                  /></Table.Cell>
                  <Table.Cell><CompanyData companyData={companyData} /></Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        {selection && selection.type == "miniOffers" && (
          <Modal closeIcon open={true} onClose={this.handleCloseSelection}>
            <Modal.Content>
              {_.map(selection.miniOffers, (miniOffer) => (
                <span key={miniOffer.id}>
                  <MiniOffer miniOffer={miniOffer} /><br />
                </span>
              ))}
            </Modal.Content>
          </Modal>

        )}
        {selection && selection.type == "hubspotCompany" && (
          <Modal size="fullscreen" closeIcon open={true} onClose={this.handleCloseSelection}>
            <Modal.Content>
              <ProspectContextView hubspotCompanyId={selection.hubspotCompanyId} />
            </Modal.Content>
          </Modal>

        )}
        {selection && selection.type == "ownerModal" && (
          <Modal closeIcon open={true} onClose={this.handleCloseSelection}>
            <Modal.Content>
              <SelectOwner users={this.props.users} handleSelectUser={this.handleSelectOwner} />
            </Modal.Content>
          </Modal>
        )}
      </div>

    )
  }
};
export default ProspectsTable;