import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import baseUrl from '../../baseUrl.js';

import { Grid, Segment, Table } from 'semantic-ui-react';

import JobCard from '../../Monitoring/Cerejobsv2/JobCard.js';
import JobViewer from '../../Monitoring/Cerejobsv2/JobViewer.js';
import { getRandomColor, getPayloadFromParams, addJobsToGroup } from '../../Monitoring/Cerejobsv2/index.js';

class JobsWithoutCategory extends React.Component {
  componentDidMount() {
    this.loadJobsWithoutCategory();
  }

  setColorMap = (jobs) => {
    const colorMap = {};
    _.each(jobs, (job) => {
      colorMap[job.id] = getRandomColor();
    });
    this.setState({ colorMap });
  };

  async getJobs() {
    const url = baseUrl + '/cerejobsV2/getJobs';
    const jobs = (await axios.get(url)).data;
    this.setColorMap(jobs);
    return jobs;
  }

  async getTagIds() {
    const url = baseUrl + '/cerejobsV2/getTagIds';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  async getMainJobsCategories() {
    const url = baseUrl + '/cerejobsV2/getMainJobsCategories';
    const jobsCategories = (await axios.get(url)).data;
    return jobsCategories;
  }

  getLevel2CategoryIds = (mainJobsCategoryIds, jobs) => {
    const level2Categories = _.filter(jobs, (job) =>
      (job.groups || []).some((e) => mainJobsCategoryIds.includes(e.id)),
    );
    const level2CategoryIds = _.map(level2Categories, (level2Category) => level2Category.id);
    return level2CategoryIds;
  };

  loadJobsWithoutCategory = async () => {
    const jobs = await this.getJobs();
    const tagIds = await this.getTagIds();
    const mainJobsCategories = await this.getMainJobsCategories();
    const mainJobsCategoryIds = _.map(mainJobsCategories, (jobsCategory) => jobsCategory.id);
    const level2CategoryIds = this.getLevel2CategoryIds(mainJobsCategoryIds, jobs);

    const jobsFilteredMainCategories = _.filter(jobs, (job) => !mainJobsCategoryIds.includes(job.id));
    const jobsFilteredAllCategories = _.filter(
      jobsFilteredMainCategories,
      (job) => !level2CategoryIds.includes(job.id),
    );
    const filteredJobs = _.filter(
      jobsFilteredAllCategories,
      (job) => !(job.groups || []).some((e) => level2CategoryIds.includes(e.id)),
    );
    this.reloadSelectedJob(jobs);
    this.setState({ jobs, filteredJobs, tagIds });
  };

  onSelectJob = (job) => {
    this.setState({
      selectedJob: job,
    });
  };

  onUnsetSelectedJob = () => {
    this.setState({
      selectedJob: undefined,
    });
  };

  reloadSelectedJob = (jobs) => {
    const { selectedJob } = this.state || {};
    const updatedSelectedJob = _.filter(jobs, (jobItem) => jobItem.id === (selectedJob || {}).id);
    if (_.isEmpty(updatedSelectedJob)) {
      this.setState({
        selectedJob: undefined,
      });
    } else {
      this.setState({
        selectedJob: updatedSelectedJob[0],
      });
    }
  };

  render() {
    const { colorMap, filteredJobs, jobs, selectedJob, tagIds } = this.state || {};

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Segment>
              <div>
                <h2>Jobs</h2>
                {'(' + (filteredJobs || []).length + ')'}
              </div>
              <Grid.Row />
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Groups</Table.HeaderCell>
                  <Table.HeaderCell>Implies</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {_.map(filteredJobs, (job) => {
                    return (
                      <JobViewer
                        selectedJob={selectedJob}
                        job={job}
                        onSelectJob={this.onSelectJob}
                        colorMap={colorMap}
                      />
                    );
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
          {selectedJob ? (
            <Grid.Column width={7}>
              <JobCard
                job={selectedJob}
                jobs={jobs}
                onUnsetSelectedJob={this.onUnsetSelectedJob}
                colorMap={colorMap}
                reloadJobs={this.loadJobsWithoutCategory}
                getPayloadFromParams={getPayloadFromParams}
                addJobsToGroup={addJobsToGroup}
                tagIds={tagIds}
              />
            </Grid.Column>
          ) : null}
        </Grid.Row>
      </Grid>
    );
  }
}

export default JobsWithoutCategory;
