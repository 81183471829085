import React, { Component } from 'react';
import { Form, Button, Modal, Grid } from 'semantic-ui-react';
import { Experience as Experience_ } from '../SweetWork/profileData';
import { Experience } from '../SweetWork/containers/ProfileExperiences';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class ExperienceModalEditor extends Component {
  handleTextChange(value) {
    this.setState({ rawText: value });
  }

  render() {
    const { onClose } = this.props;
    const { rawEntity } = this.props;
    const handleSubmit = () => {
      this.props.onSubmit(JSON.parse((this.state || {}).rawText || ''));
      onClose();
    };
    if (!this.state) {
      this.setState({ rawText: JSON.stringify(rawEntity, null, 2) });
    }
    return (
      <Modal open onClose={onClose} onSubmit={handleSubmit} closeIcon size='large'>
        <Modal.Header>Edit Experience</Modal.Header>
        <Modal.Content style={{ minHeight: 500, minWidth: 300 }}>
          <Form.TextArea
            style={{ minHeight: 500, minWidth: 300 }}
            value={(this.state || {}).rawText || JSON.stringify(rawEntity, null, 2)}
            onChange={(e, { value }) => {
              this.handleTextChange(value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onClose}>
            Close
          </Button>
          <Button positive onClick={handleSubmit}>
            Save changes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class ExperienceFunctionInput extends Component {
  getRandomExperience() {
    return {
      elementId: 'IXHPZGMPNS',
      companyName: 'Scaleway',
      workplace: {
        idFields: {
          hiresweet: 'scaleway',
          linkedin: '9427185',
          crunchbase: 'scaleway_2013',
          website: 'https://www.scaleway.com',
        },
        data: {
          name: 'Scaleway',
          foundedYear: 2013,
          staffCount: { min: 50, max: 200 },
          types: [{ id: 'big', name: { default: 'Large Company', fr: 'Grande Entreprise' } }],
          stack: [
            { id: 'python', name: { default: 'Python' } },
            { id: 'docker', name: { default: 'Docker' } },
            { id: 'go', name: { default: 'Go' } },
            { id: 'ansible', name: { default: 'Ansible' } },
            { id: 'postgresql', name: { default: 'PostgreSQL' } },
            { id: 'kubernetes', name: { default: 'Kubernetes' } },
            { id: 'flask', name: { default: 'Flask' } },
            { id: 'salt', name: { default: 'Salt' } },
            { id: 'terraform', name: { default: 'Terraform' } },
            { id: 'go', name: { default: 'Go' } },
            { id: 'python', name: { default: 'Python' } },
            { id: 'docker', name: { default: 'Docker' } },
            { id: 'amazon-web-services', name: { default: 'AWS' } },
            { id: 'css', name: { default: 'CSS' } },
            { id: 'javascript', name: { default: 'Javascript' } },
          ],
          prestige: 3,
          industries: [
            { name: { default: 'Information Services ', en: 'Information Services ', fr: "Services d'Information" } },
            { name: { default: 'IT Services ', en: 'IT Services ', fr: 'Services informatiques' } },
            { name: { default: 'Internet', en: 'Internet', fr: 'Internet' } },
            { name: { default: 'Telecommunication', en: 'Telecommunication', fr: 'Télécommunication' } },
            { name: { default: 'Software', en: 'Software', fr: 'Logiciel' } },
          ],
          sources: [
            { sourceId: 'linkedin', url: 'https://linkedin.com/company/9427185', name: { default: 'LinkedIn' } },
            {
              sourceId: 'crunchbase',
              url: 'https://www.crunchbase.com/organization/scaleway',
              name: { default: 'Crunchbase' },
            },
            { sourceId: 'website', url: 'https://www.scaleway.com', name: { default: 'Website' } },
          ],
          description: {
            default: {
              text:
                'Scaleway is a european cloud computing company proposing a complete & simple public cloud ecosystem, bare metal servers & private datacenter infrastructures.',
              enrichments: [],
            },
          },
        },
      },
      companyWebsite: 'https://www.scaleway.com',
      location: {
        elementId: 'paris',
        name: { type: 'string', default: '', fr: 'Paris, Île-de-France, France', en: '' },
      },
      title: { text: 'Tech lead', enrichments: [] },
      description: {
        text: "startup et ingénieur d'études",
        enrichments: [],
      },
      startDate: { raw: '01/01/2018', year: 2018, month: 1 },
    };
  }

  getRawEntity() {
    return {
      experienceName: 'Tech lead',
      companyName: 'Scaleway',
      linkedinCompanyId: 'scaleway',
      rawDates: ['janv. 2018'],
      location: 'Paris, Île-de-France, France',
      descriptionHTML: "startup et ingénieur d'études",
    };
  }

  handleClickOnEdit = async () => {
    this.setState({ editable: true });
  };

  onClose = async () => {
    this.setState({ editable: false });
  };

  handleSubmit = async (xpJSON) => {
    this.props.onSubmit(xpJSON);
  };

  handleClickOnSample = async () => {
    const { propertyTest, samplingBase } = this.props;
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/techAsset/randomExperience';
    const sampleWithProperty = ((propertyTest || {}).property || {}).constraints || (samplingBase && samplingBase.samplingMode !== 'all-profiles')
    if (!sampleWithProperty) {
      const randomExperienceJSON = (await axios.get(url, customHeaders)).data;
      this.handleSubmit(randomExperienceJSON);
    } else {
      const payload = { test: propertyTest, samplingBase };
      const randomExperienceJSON = (await axios.post(url + 'WithProperty', payload, customHeaders)).data;
      if (!randomExperienceJSON || randomExperienceJSON.success === false) {
        alert('No Experience found with this property while sampling 1000');
      }
      this.handleSubmit(randomExperienceJSON);
    }
  };

  render() {
    const enrichedEntity = this.props.enrichedEntity || this.getRandomExperience();
    const rawEntity = this.props.rawEntity || this.getRawEntity();
    const xp = Experience_(enrichedEntity, () => null);
    return (
      <div>
        {(this.state || {}).editable || false ? (
          <ExperienceModalEditor onClose={this.onClose} rawEntity={rawEntity} onSubmit={this.handleSubmit} />
        ) : (
          <div>
            <Grid columns={2}>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={this.handleClickOnEdit}>
                  Edit Experience
                </a>
              </Grid.Column>
              <Grid.Column>
                <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnSample()}>
                  Sample Experience
                </a>
              </Grid.Column>
            </Grid>
            <Experience
              isFirst={true}
              elementId={xp.getElementId()}
              title={xp.getTitle()}
              location={xp.getLocation()}
              description={xp.getDescription()}
              companyName={xp.getCompanyName()}
              companyWebsite={xp.getCompanyWebsite()}
              startDate={xp.getStartDate()}
              endDate={xp.getEndDate()}
              photoLink={xp.getPhotoLink()}
              stack={xp.getStack()}
              workplace={xp.getWorkplace()}
              onRemove={() => null}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ExperienceFunctionInput;
