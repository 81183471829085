import { HiresweetDataForm, sheetHiresweetDataToFormHiresweetData, formHiresweetDataToSheetHiresweetData } from '../Watch/Candidate/WatchHiresweetData';
import React, { Component } from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import { SweetForm } from 'sweetform';
import { getCandidatesOptions } from '../Watch/Candidate';
import axios from 'axios';
import baseUrl from '../baseUrl';
import Select from 'react-select';

class HiresweetEditorModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.state.data = sheetHiresweetDataToFormHiresweetData(props.initialValue || {});
  }

  componentDidMount = async () => {
    try {
      const candidateOptions = await getCandidatesOptions();
      this.setState({ candidateOptions });
    } catch (e) {
      alert(JSON.stringify(e, null, 4));
    }
  };

  handleUpdateData = (data) => {
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({
      data: formHiresweetDataToSheetHiresweetData(this.state.data),
    });
  };
  handleChangeSelectedCandidate = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleImportCandidateData = async () => {
    const targetId = (this.state.selectedOption || {}).value;
    if (!targetId) {
      return alert('Select a candidate first.')
    }
    const responseData = (await axios.get(`${baseUrl}/candidates/${targetId}?withData=1&withAccount=1`)).data;
    if (!responseData || responseData.error) {
      throw Error('Error loading candidate: ' + JSON.stringify(responseData));
    }
    const candidate = responseData.candidate;
    this.setState({ 
      data: sheetHiresweetDataToFormHiresweetData(((candidate.data || {}).sourceData || {}).hiresweet),
      importedId: candidate.id
    });
  }
  render() {
    const { selectedOption, candidateOptions, data, onClose, importedId } = this.state || {};

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit HiresweetProfile</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid>
            <Grid.Row>
              <Select style={{ width: '200px' }} options={candidateOptions} onChange={this.handleChangeSelectedCandidate} value={selectedOption} />
              <Button color='blue' onClick={this.handleImportCandidateData}>Import Data</Button>
            </Grid.Row>
            <Grid.Row>
              <SweetForm key={importedId} initialValues={data} onChange={this.handleUpdateData}>
                <HiresweetDataForm />
              </SweetForm>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default HiresweetEditorModal;
