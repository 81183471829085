//const WATCH_SHEET_ID = 'watch-sheet-1';

import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button, Table, Icon, Modal } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import baseUrl from '../../baseUrl';
import { Checkbox, Input, Select, SweetForm } from 'sweetform';
import { Textarea } from '../../common';
import { normalizeLinkedin } from '../Candidate/utils';

const loadUsers = async (input) => {
  const users = (await axios.get(`${baseUrl}/users`)).data;
  return { options: users, complete: true };
};

const SelectUsers = (props) => <Select {...props} async={true} loadOptions={loadUsers} labelKey='name' valueKey='id' />;

class AddCampaignModal extends Component {
  state = {};

  handleChange = (value) => {
    this.setState({ value });
  }

  onClickSubmit = async () => {
    const result = await this.props.onSubmitAddCampaign(this.state.value);
    if (result.error) {
      alert(JSON.stringify(result.error));
    } else {
      alert('Campaign successfully logged');
      this.props.onCloseAndReloadCampaigns();
    }
  };

  render() {
    return (
      <Modal onClose={this.props.onCloseEdition} open closeIcon>
        <Modal.Header>Log Campaign</Modal.Header>
        <Modal.Content>
          <SweetForm onChange={this.handleChange}>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={4}>
                  <label>
                    <b>Name</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Input field='name' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column width={4}>
                  <label>
                    <b>Owner</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={6}>
                  <SelectUsers field='owner' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column width={4}>
                  <label>
                    <b>Creation Date (default = today)</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Input type='date' field='creationDate' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={4}>
                  <label>
                    <b>Linkedin Urls</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Textarea
                    field='linkedinUrlsText'
                    placeholder={'https://linkedin.com/in/victor-moeneclaey\nhttps://linkedin.com/in/jebarjonet\n...'}
                    rows='30'
                    cols='60'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => this.onClickSubmit()}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class DeleteCampaignModal extends Component {
  state = {value:{deleteEvents: true}};

  handleChange = (value) => {
    this.setState({ value });
  }

  onClickSubmit = async () => {
    const {deleteEvents} = this.state.value
    const result = await this.props.onSubmitDeleteCampaign({campaignId: this.props.campaignId, deleteEvents});
    if (result.error) {
      alert(JSON.stringify(result.error));
    } else {
      alert('Campaign successfully deleted');
      this.props.onCloseAndReloadCampaigns();
    }
  };

  render() {
    return (
      <Modal onClose={this.props.onCloseEdition} open closeIcon>
        <Modal.Header>Delete Campaign {this.props.campaignId} ?</Modal.Header>
        <Modal.Content>
          <SweetForm initialValues={{deleteEvents: true}} onChange={this.handleChange}>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={4}>
                  <label>
                    <b>Delete All Associated Events ?</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Checkbox field='deleteEvents' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.onClickSubmit()}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const getCampaigns = async () => {
  try {
    const { campaigns } = (await axios.get(`${baseUrl}/talentAcquisition/campaigns`)).data;
    console.log('campaigns', campaigns);
    return campaigns;
  } catch (e) {
    alert(e.message);
  }
};

const postCampaign = async (payload) => {
  try {
    const result = (await axios.post(`${baseUrl}/talentAcquisition/campaigns`, payload)).data;
    return result;
  } catch (e) {
    return {success: false, error: e.message}
  }
};

const deleteCampaign = async ({campaignId, deleteEvents}) => {
  try {
    let url = `${baseUrl}/talentAcquisition/campaigns/${campaignId}`
    if (deleteEvents){
      url += '?deleteEvents=1'
    }
    const result = (await axios.delete(url)).data;
    return result;
  } catch (e) {
    return {success: false, error: e.message}
  }
};

class AcquisitionCampaigns extends React.Component {
  state = {
    campaigns: [],
  };

  componentWillMount() {
    this.load();
  }

  load = async () => {
    const campaigns = await getCampaigns();
    this.setState({
      campaigns,
      editMode: null
    });
  };

  onOpenAddCampaign = () => {
    this.setState({
      editMode: 'addCampaign',
    });
  };
  onOpenDeleteModal = async (id) => {
    this.setState({editMode: 'deleteCampaign', editedCampaignId: id})
  };
  onCloseEdition = () => {
    this.setState({
      editMode: null,
    });
  };
  onCloseAndReloadCampaigns = () => {
    this.load();
  };

  onSubmitAddCampaign = async ({ name, date, linkedinUrlsText, owner }) => {
    try {
      console.log({ name, date, linkedinUrlsText, owner })
      if (_.isEmpty(owner)){
        throw Error('Should specify Owner')
      }
      if (_.isEmpty(name)){
        throw Error('Should specify Name')
      }
      const profiles = _.map(linkedinUrlsText.split('\n'), (url)=>{
        const linkedin = normalizeLinkedin({linkedin:url});
        if (_.isEmpty(linkedin)){
          throw Error('Invalid linkedin url :' + url)
        }
        return {idFields: {linkedin}};
      })
      const isoDate = date && moment(date).toISOString();
      const payload = {
        name,
        owner,
        profiles,
        ...(isoDate && {date: isoDate}),
      }
      console.log(payload)
      const result = postCampaign(payload)
      return result
    } catch (e) {
      return { success: false, error: e.message };
    }
  };
  onSubmitDeleteCampaign = async ({ campaignId, deleteEvents}) => {
    try {
      console.log({ campaignId, deleteEvents })
      if (_.isEmpty(campaignId)){
        throw Error('Should specify campaignId')
      }
      const result = deleteCampaign({campaignId, deleteEvents})
      return result
    } catch (e) {
      return { success: false, error: e.message };
    }
  };



  render() {
    const { campaigns, editMode, editedCampaignId } = this.state;
    return (
      <div
        style={{
          display: 'inline-block',
          paddingTop: '5px',
          paddingBottom: '20px',
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <Button onClick={this.onOpenAddCampaign} color='green'>New campaign</Button>
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>Id</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Creation Date</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Owner</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'># Profiles</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Delete</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(campaigns, (campaign, i) => {
              if (_.isEmpty(campaign)) {
                return <Table.Row />;
              }
              const { id, name, creationDate, owner, nbProfiles } = campaign;
              return (
                <Table.Row key={i}>
                  <Table.Cell textAlign='center'>{id}</Table.Cell>
                  <Table.Cell textAlign='center'>{name}</Table.Cell>
                  <Table.Cell textAlign='center'>
                    {creationDate && moment(creationDate).format('DD-MM-YYYY')}
                  </Table.Cell>
                  <Table.Cell textAlign='center'>{owner}</Table.Cell>
                  <Table.Cell textAlign='center'>{nbProfiles}</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Button size='mini' icon color='red' onClick={() => this.onOpenDeleteModal(id)}><Icon name='delete' /></Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {editMode === 'addCampaign' ? (
          <AddCampaignModal onSubmitAddCampaign={this.onSubmitAddCampaign} onCloseEdition={this.onCloseEdition} onCloseAndReloadCampaigns={this.onCloseAndReloadCampaigns}/>
        ) : null}
        {editMode === 'deleteCampaign' ? (
          <DeleteCampaignModal campaignId={editedCampaignId} onSubmitDeleteCampaign={this.onSubmitDeleteCampaign} onCloseEdition={this.onCloseEdition} onCloseAndReloadCampaigns={this.onCloseAndReloadCampaigns}/>
        ) : null}
      </div>
    );
  }
}
export default AcquisitionCampaigns;
