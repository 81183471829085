import _ from 'underscore';
import React from 'react';
import {
  Container,
  Header,
  Label,
  Segment,
  Icon,
} from 'semantic-ui-react';
import TagsView from '../TagsView';
import moment from 'moment';
import { Link } from 'react-router-dom';


const ColorTag = ({ label, color, detail }) =>
  <Label color={color}>
    {label}
    {detail ?
      <Label.Detail>{detail}</Label.Detail> : null}
  </Label>
  ;

const sourceTypeLabels = {
  'wttjOffers': 'Welcome To The Jungle',
  'workableOffers': 'Workable',
};

const getRawMarkup = (content) =>
  ({ __html: (content || '').replace(/\n/g, '<br>') });

export default ({ offer }) => {
  console.log('OFFER DETAILS', offer)
  return (
    <Segment>
      {offer.isHiresweet ?
        <Label color='green' ribbon>Hiresweet Offer</Label> : null}
      {offer.creationDate ?
        <ColorTag color='blue' label='Creation' detail={moment(offer.creationDate).fromNow()} /> : null}
      {offer.lastSentDate ?
        <ColorTag color='blue' label='Last Send' detail={moment(offer.lastSentDate).fromNow()} /> : null}
      {offer.expired ?
        <ColorTag label='Expired' color='red' /> : null}
      {offer.isHot ?
        <Label color='orange'><Icon name='warning' />Hot</Label> : null}
      <Header as='h2'><Link to={`/offers/${offer.id}`}>{offer.title} @ {offer.workplaceId || offer.companyId}</Link></Header>
      {offer.link ?
        <a href={offer.link.slice(0, 4) === 'http' ? offer.link : `https://${offer.link}`} target='__blank'>
          {offer.sourceType ? `Link : ${sourceTypeLabels[offer.sourceType]}` : 'Link'}
        </a> : null}
      <TagsView tags={[['location', offer.location]]} />
      <TagsView tags={[['headline', offer.jobId]]} />
      <TagsView tags={[
        ['type', offer.contractType],
        ['responsibilities', offer.responsibilities],
        ['remote', offer.remote ? 'yes' : ''],
      ]} />
      <TagsView tags={[
        ['experience', offer.experience ? `${offer.experience.min} - ${offer.experience.max}` : null],
        ['seniority', offer.seniority ? offer.seniority : null],
        ['top school', offer.topSchool ? 'True' : 'False'],
      ]} />
      {offer.salaryRange ?
        <p>Salary: {offer.salaryRange.min} - {offer.salaryRange.max}</p> : null}

      {offer.skills ?
        <Container>
          {
            _.map(['required', 'important', 'bonus'], (key) => {
              if (offer.skills[key] && offer.skills[key].length > 0){
                return (
                  <div key={key}>
                    <b>{key}</b>
                    <TagsView tags={offer.skills[key]} />
                  </div>
                )
              }
            })
          }
        </Container> 
      : null}
      {offer.sourceText ?
        <Segment secondary dangerouslySetInnerHTML={getRawMarkup(offer.sourceText)} />
        : null}
      <TagsView tags={[
        ['id', offer.id],
      ]} />
    </Segment>
  )
};