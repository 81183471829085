import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import ClientProfile from './ClientProfile';

const addMainEducation = ({ enrichedProfile }) => {
  let mainEducation = null;
  _.each((enrichedProfile || {}).education, (education) => {
    if (education.isMain) {
      mainEducation = education;
    }
  })
  return {
    ...enrichedProfile,
    ...(mainEducation && { mainEducation }),
  }
}

class SweetappProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { profileId } = props;
    if (!profileId) {
      return alert('profileId undefined');
    }
    this.handleLoadProfile(profileId);
  }
  handleLoadProfile = async (profileId) => {
    const { data } = await axios.post(baseUrl + '/station/profiles', {
      ids: profileId,
      withResumeData: true,
    });
    if (_.isEmpty(data.results)) {
      return alert('no results retrieved');
    }

    const getAnonymisedData = (resumeData) => ({
      ..._.omit(
        resumeData,
        'email',
        'firstname',
        'lastname',
        'photoLink',
        'sources',
      ),
    });

    const { resumeData } = data.results[0];
    const enrichedProfile = (!this.props.anonymised || !_.isEmpty(resumeData.smartSummary))
      ? resumeData
      : getAnonymisedData(resumeData);
    const profileData = addMainEducation({ enrichedProfile })
    this.setState({
      selectedProfile: {
        id: profileId,
        data: profileData,
      },
    });
  };
  render() {
    if (!this.state.selectedProfile) {
      return (
        <center>
          <Loader />
        </center>
      );
    }
    return (
      <div style={{ position: 'relative' }}>
        <ClientProfile profile={this.state.selectedProfile} />
      </div>
    );
  }
}

export default SweetappProfile;
