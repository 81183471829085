import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Table, Card } from 'semantic-ui-react';
import Copy from '../components/Copy';
import baseUrl from '../baseUrl.js';

class PostponeView extends Component {
  state = {};
  componentWillMount() {
    this.handleLoad();
  }
  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/strat/postponed/stats`);
    console.log(data);
    this.setState(data);
  };
  renderTotal() {
    const { total } = this.state;
    if (!total) {
      return;
    }
    const { nbUsed, nbPostponed } = total;
    const percentFree = (nbUsed - nbPostponed) / Math.max(nbUsed, 1);
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Total</Card.Header>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Ever Used</Table.HeaderCell>
              <Table.HeaderCell>Postponed</Table.HeaderCell>
              <Table.HeaderCell>% Free</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{nbUsed}</Table.Cell>
                <Table.Cell>{nbPostponed}</Table.Cell>
                <Table.Cell>
                  {Math.round((percentFree || 0) * 10000) / 100} %
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  renderPostponeReasons() {
    const { blockReasonByLinkedinId } = this.state;

    if (!blockReasonByLinkedinId) {
      return;
    }

    const nbByReason = {};
    _.each(
      blockReasonByLinkedinId,
      (reason, linkedinId) =>
        (nbByReason[reason] = 1 + (nbByReason[reason] || 0)),
    );

    const reasons = _.sortBy(
      _.keys(nbByReason),
      (reason) => -nbByReason[reason],
    );
    const text = _.map(
      blockReasonByLinkedinId,
      (reason, linkedinId) => linkedinId + '\t' + reason,
    ).join('\n');

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Reasons (Copy CSV {<Copy text={text} />})</Card.Header>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Reason</Table.HeaderCell>
              <Table.HeaderCell>Postponed</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(reasons, (reason) => (
                <Table.Row key={reason}>
                  <Table.Cell>{reason}</Table.Cell>
                  <Table.Cell>{nbByReason[reason]}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  renderCategories() {
    const { categories } = this.state;
    if (!categories) {
      return;
    }
    const items = _.sortBy(
      _.map(categories, (category) => ({
        ...category,
        percentFree:
          (category.nbUsed - category.nbPostponed) /
          Math.max(1, category.nbUsed),
      })),
      'percentFree',
    );
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Category Flows</Card.Header>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Ever Used</Table.HeaderCell>
              <Table.HeaderCell>Postponed</Table.HeaderCell>
              <Table.HeaderCell>% Free</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(
                items,
                ({ id, nbPostponed, nbUsed, percentFree }, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>{nbUsed}</Table.Cell>
                    <Table.Cell>{nbPostponed}</Table.Cell>
                    <Table.Cell>
                      {Math.round((percentFree || 0) * 10000) / 100} %
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    return (
      <div>
        {this.renderTotal()}
        {this.renderPostponeReasons()}
        {this.renderCategories()}
      </div>
    );
  }
}

export default PostponeView;
