import React, { Component } from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import JsonEditor from './jsonEditor';

const stringifyTextualJob = (textualJob) => {
  const {id, category, title, location, company, description} = textualJob
  return JSON.stringify({
    ...id && { id },
    ...company && { company },
    ...category && { category },
    ...title && { title },
    ...location && { location },
    ...description && { description },
  }, null, 4);
}

class EditTextualJobModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.state.data = props.initialValue;
  }

  handleUpdateData = ({ data }) => {
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({
      data: this.state.data,
    });
  };

  render() {
    const { initialValue, onClose } = this.props;
    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit TextualJob</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid>
            <Grid.Column width={8}>
              <JsonEditor initialValue={initialValue} stringify={stringifyTextualJob} onUpdate={this.handleUpdateData}/>
            </Grid.Column>
            <Grid.Column width={8}>
              
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => this.handleSave()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditTextualJobModal;
