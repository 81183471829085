import _ from 'underscore';
import React from 'react';
import { Grid, Table, Label, Image } from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../../../baseUrl';
import WorkplaceSelector from '../../Workplace/Selector';

/*
  props: { 
    mode: 'sweethub' || 'sweetapp',
    onSelect: ({ id }) => void
  }
*/

class OfferSelector extends React.Component {
  state = {
    selectedWorkplaceId: null,
    selectedWorkplace: null,
    selectedOfferId: null,
    offers: null,
  };

  getWorkplaceById = async ({ id }) => {
    if (this.props.mode === 'sweetapp') {
      return alert('mode "sweetapp" not implemented');
    }
    if (this.props.mode === 'sweethub') {
      try {
        if (!id) {
          throw Error('No workplaceId provided.');
        }
        const { data } = await axios.get(`${baseUrl}/workplaces/${id}`);
        return data;
      } catch (e) {
        console.log('Failed to load workplace ', e.message);
        return [];
      }
    }
  };

  loadWorkplaceOffers = async ({ workplaceId }) => {
    if (this.props.mode === 'sweetapp') {
      return alert('mode "sweetapp" not implemented');
    }
    if (this.props.mode === 'sweethub') {
      try {
        if (!workplaceId) {
          throw Error('No workplaceId provided.');
        }
        const selectedWorkplace = await this.getWorkplaceById({ id: workplaceId });
        const { data } = await axios.get(`${baseUrl}/salesboard/workplaces/${workplaceId}/offers`);
        this.setState({
          selectedWorkplace,
          offers: data.offers,
        });
      } catch (e) {
        console.log('Failed to load client offers ', e.message);
        return [];
      }
    }
  };

  handleSelectWorkplace = async ({ id }) => {
    if (this.state.selectedWorkplaceId === id) {
      return;
    }
    this.setState(
      {
        selectedWorkplaceId: id,
        selectedWorkplace: null,
        offers: null,
        selectedOfferId: null,
      },
      async () => {
        await this.loadWorkplaceOffers({ workplaceId: id });
      },
    );
  };

  handleSelectOffer = async ({ id }) => {
    this.setState({ selectedOfferId: id });
    this.props.onSelect({ id });
  };

  renderOffers() {
    const { selectedWorkplaceId, selectedWorkplace, offers, selectedOfferId } = this.state;
    if (offers === null) {
      return;
    }
    if (selectedWorkplaceId && offers === null) {
      return <h5>Loading...</h5>;
    }
    if (selectedWorkplaceId && _.isEmpty(offers)) {
      return <h5>No offers...</h5>;
    }
    return (
      <Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Required Skills</Table.HeaderCell>
            <Table.HeaderCell>Experience</Table.HeaderCell>
            <Table.HeaderCell>Users</Table.HeaderCell>
            <Table.HeaderCell>Sales</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {offers.map((offer) => (
            <Table.Row key={offer.id}>
              <Table.Cell>
                {offer.platformId ? (
                  <span>
                    <a
                      href={`https://app.hiresweet.com/client/${selectedWorkplace.platformId}/offers/${
                        offer.platformId
                      }`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Image src='/images/sweetapp-logo.png' style={{ width: '16px', height: '16px' }} />
                    </a>
                  </span>
                ) : null}
              </Table.Cell>
              <Table.Cell
                style={{
                  cursor: 'pointer',
                  ...(selectedOfferId === offer.id && {
                    fontWeight: 'bold',
                    color: 'green',
                    fontSize: '13px',
                  }),
                }}
                onClick={() => this.handleSelectOffer({ id: offer.id })}
              >
                {offer.title}
              </Table.Cell>
              <Table.Cell>{((offer.skills || {}).required || []).join(' ')}</Table.Cell>
              <Table.Cell>{offer.experience ? `${offer.experience.min}-${offer.experience.max}` : null}</Table.Cell>
              <Table.Cell>{(offer.users || []).join(' ')}</Table.Cell>
              <Table.Cell>{(offer.sales || []).join(' ')}</Table.Cell>
              <Table.Cell>
                <Label color={offer.isActive ? 'green' : 'red'} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { mode } = this.props;
    const { selectedWorkplaceId } = this.state;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <h4> Select a workplace</h4>
            <WorkplaceSelector mode={mode} onSelect={this.handleSelectWorkplace} />
          </Grid.Column>
          <Grid.Column width={8}>{this.renderOffers()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default OfferSelector;
