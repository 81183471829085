import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import mingo from 'mingo';
import baseUrl from '../../baseUrl';
import { Input, Select, SelectInt, customOperator, enhance } from 'sweetform';
import { SelectSchoolType, COMPANY_TYPES_OPTIONS } from '../../Sales/SalesBoard/EditWorkplaceModal';
import { SweetForm } from 'sweetform';
import { Input as SInput, Grid, Button } from 'semantic-ui-react';

const getAdminTags = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/workplaces/adminTags`);
    return { options: data.error ? [] : _.map(data, (tag) => ({ value: tag, label: tag })) };
  } catch (e) {
    alert(e);
  }
};

const SelectAdminTag = (props) => <Select {...props} async loadOptions={getAdminTags} />;

const SelectCompanyPrestige = enhance(() => (
  <div>
    <SelectInt field='min' placeholder='Min Prestige' min={0} max={5} />
    <SelectInt field='max' placeholder='Max Prestige' min={0} max={5} />
  </div>
));

const SelectCompanyType = (props) => (
  <Select {...props} value options={COMPANY_TYPES_OPTIONS} labelKey='name' valueKey='id' />
);

export const WorkplaceFilters = customOperator(
  {
    not: { label: 'NOT', children: 1 },
    and: { label: 'AND', children: 'n' },
    or: { label: 'OR', children: 'n' },
    name: { label: 'Name', children: 1, nested: true, component: Input },
    companyTypes: { label: 'Company Type', children: 1, nested: true, component: SelectCompanyType },
    schoolType: { label: 'School Type', children: 1, nested: true, component: SelectSchoolType },
    prestige: { label: 'Prestige', children: 1, nested: true, component: SelectCompanyPrestige },
    adminTags: { label: 'Admin Tags', children: 1, nested: true, component: SelectAdminTag },
  },
  false,
);

export const sweetformFilterToMongoQuery = (filter) => {
  const filterId = (filter || {}).selection;
  if (!filterId) {
    return {};
  }
  if (filterId === 'and') {
    return {
      $and: _.map(filter[filterId], (subFilter) => sweetformFilterToMongoQuery(subFilter)),
    };
  }
  if (filterId === 'or') {
    return {
      $or: _.map(filter[filterId], (subFilter) => sweetformFilterToMongoQuery(subFilter)),
    };
  }
  if (filterId === 'not') {
    const subQuery = sweetformFilterToMongoQuery(filter[filterId]);
    return _.mapObject(subQuery, (value) => ({ $not: value }));
  }
  if (filterId === 'name') {
    return {
      [filterId]: { $regex: filter[filterId], $options: 'i' },
    };
  }
  if (filterId === 'prestige') {
    return {
      [filterId]: {
        ...((filter[filterId] || {}).min && { $gte: filter[filterId].min }),
        ...((filter[filterId] || {}).max && { $lte: filter[filterId].max }),
      },
    };
  }
  if (['schoolType', 'adminTags', 'companyTypes'].indexOf(filterId) >= 0) {
    return {
      [filterId]: filter[filterId],
    };
  }
  return {};
};

export const mongoQueryToSweetformFilter = (query) => {
  // {key: filter} => {selection: key, key: filter}
  if (_.isEmpty(query) || !_.isObject(query)) {
    return;
  }
  const key = _.keys(query)[0];
  const value = _.values(query)[0];
  if (key === '$and') {
    return { selection: 'and', and: _.map(value, (subValue) => mongoQueryToSweetformFilter(subValue)) };
  }
  if (key === '$or') {
    return { selection: 'or', and: _.map(value, (subValue) => mongoQueryToSweetformFilter(subValue)) };
  }
  if (_.isObject(value) && _.keys(value)[0] == '$not') {
    let subvalue = _.values(value)[0];
    return { selection: 'not', not: mongoQueryToSweetformFilter({ [key]: subvalue }) };
  }
  if (['schoolType', 'adminTags', 'companyTypes'].indexOf(key) >= 0) {
    return { selection: key, [key]: value };
  }
  if (key === 'name') {
    let textValue = value['$regex'] || '';
    if (textValue && textValue instanceof RegExp) {
      textValue = textValue.toString().split('/')[1]; // remove slashes and i
    }
    return { selection: key, [key]: textValue };
  }
  if (key === 'prestige') {
    return {
      selection: key,
      [key]: {
        ...((value || {})['$gte'] && { min: value['$gte'] }),
        ...((value || {})['$lte'] && { max: value['$lte'] }),
      },
    };
  }
  return {};
};

export const filterWorkplaces = (workplaces, query) => {
  if (!query) {
    return workplaces;
  } else {
    let mingoQuery = new mingo.Query(query);
    return _.filter(workplaces, (workplace) => mingoQuery.test(workplace));
  }
};

export const getPageWorkplacesAndCounts = (workplaces, pageOffset, nbPerPage) => {
  const nbWorkplaces = (workplaces || []).length;
  const pages = Math.ceil(nbWorkplaces / nbPerPage);
  const pageWorkplaces = (workplaces || []).slice(pageOffset * nbPerPage, (pageOffset + 1) * nbPerPage);
  const counts = {
    pages,
    pageOffset,
    results: nbWorkplaces,
  };
  return {
    pageWorkplaces,
    counts,
  };
};

const textToMongoQuery = (text) => {
  if (!text) {
    return {};
  }
  return sweetformFilterToMongoQuery({ selection: 'name', name: text });
};

class WorkplacesSearch extends Component {
  state = { mode: 'text' };

  handleChangeForm = (value) => {
    const query = sweetformFilterToMongoQuery(value.filter);
    this.setState({
      formQuery: query,
      formValue: value,
    });
  };

  handleChangeTextInput = (value) => {
    const query = textToMongoQuery(value);
    this.setState({
      textQuery: query,
      textValue: value,
    });
  };

  getCurrentQuery = () => {
    if (this.state.mode === 'text') {
      return this.state.textQuery;
    } else {
      return this.state.formQuery;
    }
  };

  render() {
    const { textValue, formValue, mode } = this.state;
    const query = this.getCurrentQuery();
    return (
      <Grid>
        <Grid.Row>
          <Button onClick={() => this.setState({ mode: 'text' })} secondary={mode === 'text'}>
            Text
          </Button>
          <Button onClick={() => this.setState({ mode: 'filters' })} secondary={mode === 'filters'}>
            Filters
          </Button>
        </Grid.Row>
        <Grid.Row>
          {mode === 'text' ? (
            <SInput value={textValue || ''} onChange={(e, { value }) => this.handleChangeTextInput(value)} />
          ) : (
            <SweetForm key='form' value={formValue} onChange={this.handleChangeForm}>
              <WorkplaceFilters field='filter' />
            </SweetForm>
          )}
        </Grid.Row>
        <Grid.Row>
          <pre>{JSON.stringify(query, null, 4)}</pre>
          <pre>{JSON.stringify(formValue, null, 4)}</pre>
          <br />
          <Button
            positive
            onClick={() => {
              this.props.onSearch(query);
            }}
          >
            Search
          </Button>
        </Grid.Row>
      </Grid>
    );
  }
}

export default WorkplacesSearch;
