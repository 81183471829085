import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';

import { Button, Grid, Modal, Table } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

import ProfileActivityDisplay from './ProfileActivityDisplay.js';
import { DetailedProfileView } from '../TalentReveal/Talents';

class ProfilesViewModal extends Component {
  getProfilePath = async (profileId) => {
    const { selectedClient } = this.props;
    const profilesCollectionId = selectedClient.profilesCollectionId;
    const url = baseUrl + '/revealRecommendations/getProfilePath/' + profilesCollectionId + '/' + profileId;
    const result = (await axios.get(url)).data;
    if (result.error) {
      alert(result.error);
      return;
    } else {
      const data = result.data || {};
      return data;
    }
  };

  onClickOnProfile = async (profileId) => {
    const selectedProfilePath = await this.getProfilePath(profileId);
    this.setState({ selectedProfileId: profileId, selectedProfilePath });
  };

  render() {
    const { positiveProfileIds, onClose, selectedClient, selectedMissionId } = this.props;
    const { selectedProfileId, selectedProfilePath } = this.state || {};
    return (
      <Modal closeIcon open={true} onClose={onClose}>
        <Modal.Content size='fullscreen'>
          <Grid columns={3}>
            <Grid.Column
              width={3}
              style={{
                maxHeight: '600px',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <h3> Positive positiveProfileIds </h3>
              <Table
                style={{
                  width: '100%',
                }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign='left'>Talent</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {positiveProfileIds
                    ? _.map(positiveProfileIds, (profileId, index) => {
                        return (
                          <Table.Row>
                            <Table.Cell
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onClickOnProfile(profileId)}
                              key={index}
                              textAlign='left'
                            >
                              {profileId}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    : null}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column
              width={6}
              style={{
                maxHeight: '600px',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {selectedProfileId ? (
                <ProfileActivityDisplay
                  positiveProfileIds={positiveProfileIds}
                  selectedProfileId={selectedProfileId}
                  selectedClient={selectedClient}
                  selectedMissionId={selectedMissionId}
                />
              ) : null}
            </Grid.Column>

            <Grid.Column width={6}>
              {selectedProfilePath ? (
                <div>
                  <h3> Detailed Profile View</h3>
                  <DetailedProfileView
                    key={selectedProfilePath}
                    profilePath={selectedProfilePath}
                    criteria={undefined}
                    scoreWithDetails={undefined}
                  />
                </div>
              ) : null}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onClose()}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ProfilesViewModal;
