import { Button, Checkbox, Form, Grid, Modal, Segment } from 'semantic-ui-react';
import {
  EditOmegaCriteria,
  formCriteriaToSchemaCriteria,
  schemaCriteriaToFormCriteria,
} from '../Sales/SalesBoard/EditOmegaCriteria';
import { Input, List, Select, SweetForm } from 'sweetform';
import React, { Component } from 'react';
import { buildFilter, formScorerToQuery } from '../Offers/SearchModal';

import { TalentCollectionView } from './Talents';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class ImportCriteriaFromJobModal extends Component {
  handleChange = async (params) => {
    const selectedJobCollectionId = params.jobCollectionId;
    const currentJobs = await this.getJobsFromJobCollectionId(selectedJobCollectionId);
    const selectedJobId = params.selectedJobId;
    const selectedJob = _.find(currentJobs, (job) => job.identifier == selectedJobId);
    this.setState({ currentJobs, selectedJobCollectionId, selectedJobId, selectedJob });
  };

  getJobsFromJobCollectionId = async (jobCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobs/' + jobCollectionId;
    const jobs = (await axios.get(url, customHeaders)).data;
    return jobs.results;
  };

  render() {
    const { onSubmit, onCancel, jobCollections } = this.props || {};
    const jobCollectionOptions = _.map(jobCollections, (jobCollection, index) => ({
      value: jobCollection.id,
      label: jobCollection.id,
    }));
    const { currentJobs } = this.state || {};
    const jobOptions = _.map(currentJobs, (job, index) => ({
      value: job.identifier,
      label: job.identifier,
    }));
    return (
      <Modal open closeIcon open={true} headerText={`Import criteria from existing Job`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Job Collection</label>
              <Select field='jobCollectionId' options={jobCollectionOptions} />
            </Form.Field>
            {currentJobs ? (
              <Form.Field>
                <label>Job</label>
                <Select fluid field='selectedJobId' label='selected Job Id' options={jobOptions} />
              </Form.Field>
            ) : null}
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state.selectedJob)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class TalentSearch extends Component {
  componentDidMount() {}

  handleSelectProfileCollection = (e, { value }) => {
    this.setState({ selectedProfileCollectionId: value });
  };

  computeSearch = async () => {
    this.setState({ profiles: null });
    const { onCompute, criteria, filters, selectedProfileCollectionId, toggleScoring, toggleFreeText } =
      this.state || {};
    if (onCompute) {
      return;
    }
    const scorer = {
      id: toggleScoring ? (toggleFreeText ? 'XS1' : 'fastSearch') : 'fastSearchPrescoring',
    };
    const newFilters = filters.concat([]);
    const payload = {
      criteria,
      filters: newFilters,
      scorer,
      profileCollectionId: selectedProfileCollectionId,
      projectionKeys: ['firstname', 'lastname', 'profile_path'],
      limit: 500,
    };
    this.setState({ onCompute: true });
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/talentRevealSearch';
    const data = (await axios.post(url, payload, customHeaders)).data;
    if (data.success == false) {
      alert('Error happened');
      this.setState({ onCompute: false });
      return;
    }
    const formattedProfiles = _.map(data.data.results, (profile) => {
      return {
        score: profile.innerData.score,
        firstname: profile.projectionKeys.firstname,
        lastname: profile.projectionKeys.lastname,
        profile_path: profile.projectionKeys.profile_path,
      };
    });

    this.setState({
      profiles: formattedProfiles,
      onCompute: false,
    });
  };

  handleClickOnImportCriteriaFromJob() {
    this.setState({ importCriteriaFromJobModalVisible: true });
  }

  onCancel = () => {
    this.setState({ importCriteriaFromJobModalVisible: false });
  };

  handleClickOnToggle = () => {
    const { toggleFreeText } = this.state || {};
    this.setState({ toggleFreeText: !(toggleFreeText || false), criteria: null });
  };

  handleClickOnToggleScorer = () => {
    const { toggleScoring } = this.state || {};
    this.setState({ toggleScoring: !(toggleScoring || false) });
  };

  onSubmit = async (selectedJob) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const fetchUrl = baseUrl + '/sweetchain/getJobV5';
    const result = (await axios.post(fetchUrl, { jobPath: selectedJob.job_path }, customHeaders)).data;
    const criteria = ((result || {}).search || {}).criteria;
    const key = '' + Math.random();
    this.setState({ criteria, importCriteriaFromJobModalVisible: false, key });
  };

  async handleChange(params) {
    this.setState({
      criteria: {
        ...(params.criteria == undefined ? {} : formCriteriaToSchemaCriteria(params.criteria)),
        freeText: params.freeText,
      },
      filters: params.filters == undefined ? [] : formScorerToQuery(params.filters),
    });
  }

  render() {
    const {
      onCompute,
      selectedProfileCollectionId,
      profiles,
      importCriteriaFromJobModalVisible,
      toggleFreeText,
      toggleScoring,
    } = this.state || {};
    const { profileCollections, jobCollections } = this.props;
    const profileCollectionOptions = _.map(profileCollections, (profileCollection, index) => ({
      key: index,
      value: profileCollection.id,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
    }));
    const criteria = (this.state || {}).criteria || {};
    const formatedCriteria = schemaCriteriaToFormCriteria(criteria);
    return (
      <div>
        {importCriteriaFromJobModalVisible ? (
          <ImportCriteriaFromJobModal
            jobCollections={jobCollections}
            onSubmit={(selectedJob) => this.onSubmit(selectedJob)}
            onCancel={this.onCancel}
          />
        ) : null}
        <Form.Select
          search
          placeholder='Profile Collection'
          options={profileCollectionOptions}
          onChange={this.handleSelectProfileCollection}
          value={selectedProfileCollectionId}
        />

        {selectedProfileCollectionId ? (
          <div>
            <br />
            <Button onClick={() => this.computeSearch()} color={onCompute ? 'grey' : 'green'}>
              Run Search
            </Button>
            <br />
            <a
              style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }}
              onClick={() => this.handleClickOnImportCriteriaFromJob()}
            >
              Import criteria from existing job
            </a>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <SweetForm
                    onChange={(params) => this.handleChange(params)}
                    initialValues={{
                      criteria: formatedCriteria,
                    }}
                    key={(this.state || {}).key}
                  >
                    <Segment>
                      <div>
                        <Grid>
                          <Grid.Column>
                            <Checkbox toggle onClick={() => this.handleClickOnToggle()} /> Mode :{' '}
                            {toggleFreeText ? 'Free Text' : 'Criteria'}
                          </Grid.Column>
                        </Grid>
                        <Grid>
                          <Grid.Column>
                            <Checkbox toggle onClick={() => this.handleClickOnToggleScorer()} /> Scoring Mode :{' '}
                            {toggleScoring ? 'Precise' : 'Prescoring'}
                          </Grid.Column>
                        </Grid>
                        {toggleFreeText ? (
                          <Form.Field width={10}>
                            <h1>Free Text :</h1>
                            <Input field='freeText' placeholder='' autoFocus />
                          </Form.Field>
                        ) : (
                          <Form.Field>
                            <h1>Criteria :</h1>
                            <EditOmegaCriteria field='criteria' cerejobsV2={true} />
                          </Form.Field>
                        )}

                        <Form.Field>
                          <h1>Filters :</h1>
                          <List field='filters' component={buildFilter(false, true)} />
                        </Form.Field>
                      </div>
                    </Segment>
                  </SweetForm>
                </Grid.Column>
                <Grid.Column width={8}>
                  {profiles ? (
                    <TalentCollectionView profiles={profiles} key={selectedProfileCollectionId} criteria={criteria} />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : null}
      </div>
    );
  }
}

export default TalentSearch;