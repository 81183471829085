import React, { Component } from 'react';
import _ from 'underscore';
import { Loader, Modal, Dropdown, Header, Button } from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';

export const withExternalPush = (WrappedComponent) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    componentWillMount() {
      if (!this.state.workPipes) {
        axios.get(`${baseUrl}/sweetwork/workPipes`).then(({ data }) => {
          this.setState({ workPipes: data });
        });
      }
      if (!this.state.offers) {
        axios.get(`${baseUrl}/offers/list/hiresweet`).then(({ data }) => {
          this.setState({ offers: data });
        });
      }
    }
    handleSelectOfferId = (e, { value }) => {
      const selectedOfferId = value;

      const targetWorkpipes = _.filter(
        this.state.workPipes,
        ({ jobOfferId }) => jobOfferId === selectedOfferId,
      );
      const selectedWorkPipeId =
        targetWorkpipes.length === 1 ? targetWorkpipes[0].id : null;

      const externalProfilesInput = _.findWhere(
        targetWorkpipes && (targetWorkpipes[0] || {}).inputs,
        { title: 'External Profiles' },
      );
      const selectedInputId =
        selectedWorkPipeId && (targetWorkpipes[0].inputs || []).length === 1
          ? targetWorkpipes[0].inputs[0].id
          : externalProfilesInput
          ? externalProfilesInput.id
          : null;

      this.setState({
        selectedOfferId,
        selectedWorkPipeId,
        selectedInputId,
      });
    };
    handleSelectWorkPipeId = (e, { value }) => {
      const selectedWorkPipeId = value;
      const workPipe = _.findWhere(this.state.workPipes, {
        id: selectedWorkPipeId,
      });

      const externalProfilesInput = _.findWhere(workPipe && workPipe.inputs, {
        title: 'External Profiles',
      });
      const selectedInputId =
        workPipe && (workPipe.inputs || []).length === 1
          ? workPipe.inputs[0].id
          : externalProfilesInput
          ? externalProfilesInput.id
          : null;

      this.setState({
        selectedWorkPipeId,
        selectedInputId,
      });
    };
    handleSelectInputId = (e, { value }) => {
      this.setState({ selectedInputId: value });
    };
    handlePushProfile = async (profile, source) => {
      try {
        if (!profile) {
          throw Error('no profile found');
        }
        if (!profile.idFields) {
          throw Error('no profile.idFields found');
        }
        const alreadyInWorkPipeResult = (await axios.post(
          `${baseUrl}/sweetwork/isItemNotAlreadyInPipe`,
          {
            idFields: profile.idFields,
            workPipeId: this.state.selectedWorkPipeId,
          },
        )).data;
        if (alreadyInWorkPipeResult.error) {
          throw Error(alreadyInWorkPipeResult.error);
        }
        if (alreadyInWorkPipeResult.response === true) {
          throw Error('This profile is already in this pipe');
        }
        const pushUrl =
          `${baseUrl}/sweetwork/workPipes/` +
          this.state.selectedWorkPipeId +
          '/input/' +
          this.state.selectedInputId +
          '/items';
        const pushResponse = (await axios.post(pushUrl, {
          items: [
            {
              type: 'profile',
              idFields: profile.idFields,
              flyWeight: true,
              pushTimestamp: Date.now(),
              ...(source && { source }),
            },
          ],
        })).data;
        if (pushResponse.error) {
          throw Error(pushResponse.error);
        }
      } catch (e) {
        alert(e.message);
      }
    };
    renderOfferSelector = () => {
      const { offers, selectedOfferId } = this.state;
      if (!offers) {
        return <Loader active inline="centered" />;
      }
      const offerOptions = _.map(offers, (offer) => ({
        text: offer.companyId + '-' + offer.title,
        value: offer.id,
      }));
      return (
        <Dropdown
          fluid
          options={offerOptions}
          placeholder="Offer"
          value={selectedOfferId}
          selection
          search
          onChange={this.handleSelectOfferId}
        />
      );
    };
    renderWorkPipeSelector = () => {
      const { workPipes, selectedOfferId, selectedWorkPipeId } = this.state;
      if (!selectedOfferId) {
        return null;
      }
      if (!workPipes) {
        return <Loader active inline="centered" />;
      }
      const targetWorkPipes = _.filter(
        workPipes,
        ({ jobOfferId }) => jobOfferId === selectedOfferId,
      );
      const workPipeOptions = _.map(targetWorkPipes, (workPipe) => ({
        text: workPipe.title,
        value: workPipe.id,
      }));
      return (
        <Dropdown
          fluid
          options={workPipeOptions}
          placeholder="WorkPipe"
          value={selectedWorkPipeId}
          selection
          search
          onChange={this.handleSelectWorkPipeId}
        />
      );
    };
    renderInputSelector = () => {
      const { workPipes, selectedWorkPipeId, selectedInputId } = this.state;
      const workPipe = _.findWhere(workPipes, { id: selectedWorkPipeId });
      if (!workPipe) {
        return <Loader active inline="centered" />;
      }
      const inputOptions = _.map(workPipe.inputs, (input) => ({
        text: input.title,
        value: input.id,
      }));
      return (
        <Dropdown
          fluid
          options={inputOptions}
          placeholder="Input"
          value={selectedInputId}
          selection
          search
          onChange={this.handleSelectInputId}
        />
      );
    };
    renderSelector = () => {
      return (
        <div>
          {this.renderOfferSelector()}
          {this.renderWorkPipeSelector()}
          {this.renderInputSelector()}
        </div>
      );
    };
    render() {
      const externalPush = {
        loaded: !!(this.state.offers && this.state.workPipes),
        renderSelector: this.renderSelector,
        getSelection: this.getSelection,
        selection: {
          offerId: this.state.selectedOfferId || null,
          workPipeId: this.state.selectedWorkPipeId || null,
          inputId: this.state.selectedInputId || null,
        },
        pushProfile: this.handlePushProfile,
        selectOfferId: (offerId) =>
          this.handleSelectOfferId(null, { value: offerId }),
      };
      return <WrappedComponent {...this.props} externalPush={externalPush} />;
    }
  };

class ExternalPushModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = async () => {
    try {
      await this.props.externalPush.pushProfile(this.props.profile);
      this.setState({
        open: false,
      });
    } catch (e) {
      alert(e.message);
    }
  };
  render() {
    const selection = this.props.externalPush.selection;
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <Button
            className="share-profile-button"
            color="orange"
            style={{ cursor: 'pointer' }}
            circular
            icon="share"
            onClick={() => {
              this.setState({ open: true });
            }}
          />
        }
      >
        <Header icon="share" content="Share Profile" />
        <Modal.Content>
          {this.props.externalPush.renderSelector()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            disabled={!selection || !selection.inputId}
            onClick={this.handleSubmit}
          >
            Share
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export const ExternalPushModal = withExternalPush(ExternalPushModalComponent);

export default ExternalPushModal;
