import React, { Component } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { SweetForm } from 'sweetform';

import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';
import { Textarea } from '../common';
import ProjectView from './ProjectView';

const typeOptions = [
  {
    label: 'Hiresweet Profile Search',
    value: 'hiresweet-profile-search',
  },
];

class AddProjectModal extends Component {
  async handleChange(params) {
    this.setState({
      ...params,
    });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    return (
      <Modal open closeIcon open={true} headerText={`Create Set`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Project Title</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='title'
                label='title'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>User Id</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='userId'
                label='userId'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '200px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='description'
                label='description'
                placeholder=''
              />
            </Form.Field>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class TalentReveal extends Component {
  componentDidMount() {
    this.handleLoadProjectsState();
  }

  async getProjects() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProjects';
    const data = (await axios.get(url, customHeaders)).data;
    return (data || {}).data || [];
  }

  handleLoadProjectsState = async () => {
    const projects = await this.getProjects();
    this.setState({ projects });
  };

  handleSelectProject = async (e, { value }) => {
    this.setState({ selectedProjectId: value });
  };

  onCancel = () => {
    this.setState({ selectedProjectId: null });
  };

  generateId = () => {
    var letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var id = '';
    for (var i = 0; i < 10; i++) {
      id += letters[Math.floor(Math.random() * 26)];
    }
    return id;
  };

  onSubmit = async (params) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createProject';
    const id = this.generateId();
    const payload = {
      ...params,
      id,
      config: {
        type: 'mongo',
        dbHosts: 'GCP',
        dbName: 'reveal',
      },
    };
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ selectedProjectId: null });
    this.handleLoadProjectsState();
    return;
  };

  render() {
    const { projects, selectedProjectId } = this.state || {};
    const addProjectModalVisible = selectedProjectId === 'new-project';
    const projectOptions = _.map(projects || [], (project, key) => ({
      value: project.id,
      key: key,
      text: project.name ? project.name : project.title ? project.title : project.id,
    })).concat([
      {
        value: 'new-project',
        key: 'new-project',
        text: '+ New Project',
      },
    ]);
    const selectedProject = _.find(projects || [], (item) => item.id === selectedProjectId);
    const userId =
      selectedProject && !_.isUndefined(selectedProject.userId) && !_.isNull(selectedProject.userId)
        ? selectedProject.userId
        : 'sweethub';
    return (
      <div>
        {addProjectModalVisible ? <AddProjectModal onSubmit={this.onSubmit} onCancel={this.onCancel} /> : null}
        <Form.Select
          search
          placeholder='Projects'
          options={projectOptions}
          onChange={this.handleSelectProject}
          value={selectedProjectId}
        />
        {selectedProjectId && selectedProjectId !== undefined ? (
          <ProjectView projectId={selectedProjectId} key={selectedProjectId} userId={userId} />
        ) : null}
      </div>
    );
  }
}

export default TalentReveal;
