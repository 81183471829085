import React from 'react';
import _ from 'underscore';
import axios from 'axios';

import baseUrl from '../../baseUrl.js';
import { Button, Dropdown, Form, Grid, Modal } from 'semantic-ui-react';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';

class CreateSkillExtenderSystemModal extends React.Component {
  async componentDidMount() {
    const { skillExtenderSystems } = this.props;
    const profilesCollectionsAlreadyInSystem = _.map(
      skillExtenderSystems,
      (system) => (system.profilesSetDescriptor || {}).profilesCollectionId,
    );
    await this.getCandidateProfilesCollections(profilesCollectionsAlreadyInSystem);
  }

  getCandidateProfilesCollections = async (profilesCollectionsAlreadyInSystem) => {
    const url = baseUrl + '/sweetchain/getProfileCollections';
    const profilesCollections = (await axios.get(url)).data.data;
    const candidateProfilesCollections = _.filter(
      profilesCollections,
      (profileCollection) => !profilesCollectionsAlreadyInSystem.includes(profileCollection.id),
    );
    this.setState({ profilesCollections: candidateProfilesCollections });
  };

  handleChange(params) {
    this.setState({ params });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const { profilesCollections } = this.state || {};

    const profilesCollectionOptions = _.map(profilesCollections, (profilesCollection) => ({
      label: profilesCollection.id,
      value: profilesCollection.id,
    }));

    return (
      <Modal closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Name</label>
              <Input field='name' />
            </Form.Field>
            <Form.Field>
              <label>Profiles Collection</label>
              <Select options={profilesCollectionOptions} field='profilesCollectionId' />
            </Form.Field>
            <br />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit((this.state || {}).params)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CreateSkillExtenderSystemModal;
