import React, { Component } from 'react';
import { Button, Dropdown, Form, Grid, Modal } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';
import baseUrl from '../baseUrl.js';

class RecommenderSystemNameModal extends Component {
  handleChange(params) {
    this.setState({
      params,
    });
  }

  render() {
    const { onSubmit, onCancel, selectedRecommenderSystem } = this.props;
    return (
      <Modal closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <h1>Old Recommender System Name : {selectedRecommenderSystem.name || ''}</h1>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>New Recommender System Name</label>
              <Input autoFocus field='name' />
            </Form.Field>
            <br />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit((this.state || {}).params)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RecommenderSystemNameModal;
