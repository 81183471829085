import React from 'react';
import _ from 'underscore';
import { Button, Popup, Label, Icon } from 'semantic-ui-react';
import { EditableTranslatableText } from '../components/TranslatableText';
import EnrichedText from '../components/EnrichedText';
import ScrappedTextWrapper from '../components/ScrappedTextWrapper';
import { Stack } from '../../components/Stack';
import { renderDates, renderDurationFromDates } from '../utils';
import { WorkplacePopup } from '../components/workplacePopup';
import Collapsible from '../components/Collapsible';

const MAX_EXP = 4;

const getWorkplaceTypeIcon = (type) =>
  type === 'ssii'
    ? 'desktop'
    : type === 'st' || type === 'startup'
    ? 'lightbulb'
    : type === 'big'
    ? 'industry'
    : 'question';

const WorkplaceIcon = ({ type }) => (
  <Popup
    trigger={<Icon style={{ marginLeft: 5 }} name={getWorkplaceTypeIcon(type && type.getId().get())} />}
    content={
      type
        ? type
            .getName()
            .getDefault()
            .get()
        : 'Unknown company type'
    }
  />
);

export const Experience = (props) => {
  const { title, description, location, startDate, endDate, companyName, stack, isFirst, workplace } = props;
  const rawStack = stack.getRawValue();
  const workplacePrestige = workplace
    .getData()
    .getPrestige()
    .get();
  const workplaceTypes = workplace.getData().getTypes();
  const workplaceMainType = workplaceTypes.length > 0 ? workplaceTypes.at(0) : null;
  return (
    <div
      style={{ padding: '11px', position: 'relative', fontFamily: 'SourceSansPro', fontSize: 12 }}
      className='experience-container'
    >
      <div style={{ opacity: 0.5, position: 'absolute', top: 0, right: -12 }}>
        <Icon
          link
          color='red'
          name='remove'
          onClick={() => {
            props.onRemove();
          }}
        />
      </div>
      <div className='stack-container' style={{ marginTop: '11px' }}>
        <Stack stack={rawStack} />
      </div>
      <div style={{ fontSize: 14, fontFamily: 'SourceSansProSemiBold' }}>
        <EnrichedText text={title.getText().get()} enrichments={title.getEnrichments()} /> •{' '}
        {workplace
          .getData()
          .getName()
          .get() ? (
          <WorkplacePopup workplace={workplace} />
        ) : companyName.get() ? (
          companyName.get()
        ) : null}
        <WorkplaceIcon type={workplaceMainType} />
        {workplacePrestige !== undefined && (
          <Popup
            trigger={
              <Label style={{ marginLeft: '5px' }} color={workplacePrestige > 3 ? 'green' : 'orange'}>
                {workplacePrestige}
              </Label>
            }
            content='Workplace Prestige'
          />
        )}
      </div>
      <div style={{ fontSize: '11px' }}>
        <span style={{ opacity: 0.5 }}>
          {renderDates({ startDate, endDate })} •{' '}
          <EditableTranslatableText translatableText={location.getName()} placeholder='Location' />
        </span>
        {renderDurationFromDates({ startDate, endDate, firstExperience: isFirst })}
      </div>
      <Collapsible maxHeight={50}>
        <ScrappedTextWrapper text={description.getText().get()}>
          <EnrichedText text={description.getText().get()} enrichments={description.getEnrichments()} />
        </ScrappedTextWrapper>
      </Collapsible>
    </div>
  );
};

class ProfileExperiences extends React.Component {
  state = { showMore: false };
  onClickShowMore = () => {
    this.setState({ showMore: true });
  };
  render() {
    const { experiences } = this.props;
    const { showMore } = this.state;
    const shouldDisplayMoreButton = experiences.length > MAX_EXP && !showMore;
    return (
      <div style={{ marginBottom: -10 }}>
        {_.map(showMore ? experiences.get() : _.first(experiences.get(), MAX_EXP), (xp, index) => (
          <Experience
            key={index}
            isFirst={index === 0}
            elementId={xp.getElementId()}
            title={xp.getTitle()}
            location={xp.getLocation()}
            description={xp.getDescription()}
            companyName={xp.getCompanyName()}
            companyWebsite={xp.getCompanyWebsite()}
            startDate={xp.getStartDate()}
            endDate={xp.getEndDate()}
            photoLink={xp.getPhotoLink()}
            stack={xp.getStack()}
            workplace={xp.getWorkplace()}
            onRemove={() => experiences.removeAt(index)}
          />
        ))}
        {shouldDisplayMoreButton && (
          <Button onClick={this.onClickShowMore} style={{ marginLeft: 11, marginTop: -5 }} size='mini'>
            More
          </Button>
        )}
      </div>
    );
  }
}

export default ProfileExperiences;
