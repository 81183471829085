import _, { random } from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import { Button, Card, Form, Icon, TextArea, Input } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProfileView from '../SweetComponents/Profile/View';
import queryString from 'query-string';
import { Switch, Route } from 'react-router-dom';
import { LinkedinProfile } from '../Offers/WorkPipeInputComponents/LinkedinProfile';


//import Timeline from 'react-timeline-semantic-ui';
import { CardGroup } from 'semantic-ui-react';


class PureTimeLine extends Component {

    render() {
        const { status, timeline } = this.props;
        const titles = [
            "Mongo Raw Profile",
            "BGQ Raw Profile",
            "BGQ Enriched Profile",
        ]
        const titleMap = {
            "Mongo Raw Profile": "mongoRawProfile",
            "BGQ Raw Profile": "bgqRawProfile",
            "BGQ Enriched Profile": "bgqEnrichedProfile",
        }
        const durationInSec = (timeline.bgqEnrichedProfile - timeline.mongoRawProfile) / 1000
        return (
            <div>
                <span style={{ fontSize: 15, marginLeft: 10 }}>
                    {durationInSec > 0 ? "Total Duration : " + durationInSec + "  seconds" : "Duration not available"}
                </span>
                <CardGroup>
                    {_.map(titles, (title, key) => {
                        const isOk = (title == "BGQ Enriched Profile" && status.enrichedProfileSync) || (title == "BGQ Raw Profile" && status.cloudStorageSync) || title == "Mongo Raw Profile"
                        return (
                            <Card key={key}>
                                <Card.Header>
                                    {title}
                                </Card.Header>
                                <Card.Content>
                                    {isOk ? <Icon name="check" color="green" size="large" /> : <Icon name="cancel" color="red" size="large" />}
                                    {(new Date(timeline[titleMap[title]])).toISOString()}
                                </Card.Content>
                            </Card>)
                    })}
                </CardGroup>
            </div>
        )
    }
}



const buildLinkedinFromLinkedin = (cloudStorageProfile) => {
    return ((((cloudStorageProfile || {}).data || {}).sourceData || {}).linkedin || {}).data
}

const buildLinkedinFromResume = (cloudStorageProfile) => {
    const sourceData = (((cloudStorageProfile || {}).data || {}).sourceData || {})
    const resumeProfile = (sourceData.greenhouse || {}).parsedResume ? (sourceData.greenhouse || {}).parsedResume :
        (sourceData.lever || {}).parsedResume ? (sourceData.lever || {}).parsedResume :
            (sourceData.smartrecruiters || {}).parsedResume ? (sourceData.smartrecruiters || {}).parsedResume :
                (sourceData.teamtailor || {}).parsedResume ? (sourceData.teamtailor || {}).parsedResume :
                  (sourceData.attachments || {}).parsedResume ? (sourceData.attachments || {}).parsedResume : {}
    return resumeProfile || {}

}

const buildProfileV5FromData = (cloudStorageProfile) => {
    return cloudStorageProfile.data
}


class ItemTimeline extends Component {

    renderJSON = (item) => {
        return (
            <div>
                {
                    item ?
                        <TextArea
                            rows={50}
                            cols={140}
                            value={JSON.stringify(item, null, '  ')}
                        /> : null
                }
            </div>
        );
    }

    render() {
        const { timeline, profileV5 } = this.props || {}
        const inCloudTask = timeline.inCloudTask
        const inSQLizerTask = timeline.inSQLizerTask
        const {
            mongoRawProfile, cloudStorageProfile, bgqRawProfile, bgqEnrichedProfile
        } = (timeline || {}).data
        const profileV5FromLinkedin = buildProfileV5FromData(cloudStorageProfile)
        const linkedinFromLinkedin = buildLinkedinFromLinkedin(cloudStorageProfile)
        const linkedinFromResume = buildLinkedinFromResume(cloudStorageProfile)
        return (
            <div>
                {timeline ? (
                    <div>
                        <PureTimeLine timeline={timeline.timeline} status={timeline.status} />
                        {inCloudTask ? <span
                            style={{
                                color: 'green'
                            }}
                        > Profile currently in cloud task</span> : null}
                        {inSQLizerTask ? <span
                            style={{
                                color: 'green'
                            }}
                        > Profile currently in sqlizer task</span> : null}
                        {timeline.error ?
                            <span
                                style={{
                                    color: 'red'
                                }}
                            >{timeline.error}</span> : null
                        }
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab>Decrypted Mongo Profile</Tab>
                                <Tab>Decrypted Cloud Storage Profile</Tab>
                                <Tab>Profile From Linkedin</Tab>
                                <Tab>Profile From Resume</Tab>
                                <Tab>Profile From Data</Tab>
                                <Tab>Profile V5</Tab>
                                <Tab>Mini Profile SQL</Tab>
                                <Tab>Enriched Profile SQL</Tab>
                            </TabList>
                            <TabPanel>{this.renderJSON(mongoRawProfile)}</TabPanel>
                            <TabPanel>{this.renderJSON(cloudStorageProfile)}</TabPanel>
                            <TabPanel><LinkedinProfile source={{ data: linkedinFromLinkedin }} more={{}} />{' '}</TabPanel>
                            <TabPanel><LinkedinProfile source={{ data: linkedinFromResume }} more={{}} />{' '}</TabPanel>
                            <TabPanel><ProfileView data={profileV5FromLinkedin} />{' '}</TabPanel>
                            <TabPanel><ProfileView data={profileV5} />{' '}</TabPanel>
                            <TabPanel>{this.renderJSON(bgqRawProfile)}</TabPanel>
                            <TabPanel>{this.renderJSON(bgqEnrichedProfile)}</TabPanel>
                        </Tabs>

                    </div >
                )
                    : null}
            </div>
        );
    }

}




class ItemTimelineEditor extends Component {
    componentDidMount() {
        this.handleLoadProfileCollections();
        this.handleLoadTimeline();
        const { initialProfileCollectionId, initialProfileId } = this.props
        this.setState({
            profileCollectionId: initialProfileCollectionId,
            profileId: initialProfileId
        })
    }


    loadProfileV5 = async (profilePath) => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getProfileV5WithoutHints';
        const payload = {
            profile: {
                mode: 'cloud-storage',
                profilePath: profilePath,
            },
        };
        console.log(payload)
        const result = (await axios.post(url, payload, customHeaders)).data;
        console.log(result)
        this.setState({ profileV5: result });
    };

    async getProfileCollections() {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getProfileCollections';
        const results = (await axios.get(url, customHeaders)).data;
        return results.data;
    }

    handleLoadProfileCollections = async () => {
        const profileCollections = await this.getProfileCollections();
        const profileCollectionOptions = _.map(profileCollections || [], (profileCollection, key) => ({
            value: profileCollection.id,
            key: key,
            text: profileCollection.name ? profileCollection.name : profileCollection.title,
        }));
        this.setState({ profileCollectionOptions });
    };

    handleLoadTimeline = async () => {
        const { initialProfileCollectionId, initialProfileId } = this.props;
        if (initialProfileCollectionId && initialProfileId) {
            await this.getTimeline(initialProfileCollectionId, initialProfileId)
        }
    };

    getTimeline = async (profileCollectionId, profileId) => {
        const { isWorking, prefix } = this.state || {};
        if (isWorking) {
            return;
        }
        this.setState({ isWorking: true })
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getProfileTimeline';
        if (!profileCollectionId) {
            alert("Profile Collection Not Defined")
            return
        }
        if (!profileId) {
            alert("Profile Id Not Defined")
            return
        }
        const results = (await axios.post(url, { profileCollectionId, profileId }, customHeaders)).data;
        const profilePath = (((results || {}).data || {}).bgqRawProfile || {}).item_url
        this.loadProfileV5(profilePath);
        this.setState({ timeline: results });
        // this.getProfileDurationAndProfileNotSync(profileCollectionId)
        this.setState({ isWorking: false });

    }

    getProfileDurationAndProfileNotSync = async (profileCollectionId) => {
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getProfileNotSync';
        if (!profileCollectionId) {
            alert("Profile Collection Not Defined")
            return
        }
        const results = (await axios.post(url, { profileCollectionId }, customHeaders)).data;
        this.setState({ ...results });
        return results
    }

    getRandomTimeline = async (profileCollectionId) => {
        const { isWorking } = this.state || {};
        if (isWorking) {
            return;
        }
        this.setState({ isWorking: true })
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/getRandomProfileTimeline';
        if (!profileCollectionId) {
            alert("Profile Collection Not Defined")
            return
        }
        const results = (await axios.post(url, { profileCollectionId }, customHeaders)).data;
        const profilePath = (((results || {}).data || {}).bgqRawProfile || {}).item_url
        const profileId = (((results || {}).data || {}).bgqRawProfile || {}).item_id
        this.loadProfileV5(profilePath);
        this.setState({ timeline: results, profileId: profileId });
        // this.getProfileDurationAndProfileNotSync(profileCollectionId)
        this.setState({ isWorking: false })
    }

    askForSync = async (profileCollectionId, profileId) => {
        const { isWorking } = this.state || {};
        if (isWorking) {
            return;
        }
        const customHeaders = {
            'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/askForSyncProfile';
        if (!profileCollectionId) {
            alert("Profile Collection Not Defined")
            return
        }
        if (!profileId) {
            alert("Profile Id Not Defined")
            return
        }
        await axios.post(url, { profileCollectionId, profileId }, customHeaders)
        this.getTimeline(profileCollectionId, profileId)
    }

    sampleProfileNotSync = async (profileCollectionId) => {
        const results = await this.getProfileDurationAndProfileNotSync(profileCollectionId)
        if (results.notSyncProfile) {
            this.setState({ profileId: results.notSyncProfile })
            this.getTimeline(profileCollectionId, results.notSyncProfile)
        } else {
            alert("You must specify a profile collection")
        }
    }

    handleChangeProfileCollectionId = ({ profileCollectionId }) => {
        // this.getProfileDurationAndProfileNotSync(profileCollectionId)
        this.setState({ profileCollectionId, timeline: null, profileV5: null });
    };

    handleChangeProfileId = ({ profileId }) => {
        this.setState({ profileId });
    };

    renderProfileCollectionImportEditor() {
        const { initialProfileCollectionId, initialProfileId } = this.props;
        const { profileId, profileCollectionId, profileCollectionOptions, isWorking } = this.state || {};
        return (
            <div>
                <Form.Select
                    search
                    placeholder='Profile Collection'
                    options={profileCollectionOptions}
                    onChange={(e, { value }) => {
                        this.handleChangeProfileCollectionId({ profileCollectionId: value });
                    }}
                    value={profileCollectionId || initialProfileCollectionId}
                />
                <Input
                    type='text'
                    placeholder='profileId'
                    value={profileId || initialProfileId}
                    onChange={({ target }) => {
                        this.handleChangeProfileId({ profileId: target.value });
                    }}
                />
                <Button
                    color={isWorking ? 'grey' : 'blue'}
                    onClick={() => {
                        this.getTimeline(profileCollectionId, profileId);
                    }}
                >
                    Load
                </Button>
                <Button
                    color={isWorking ? 'grey' : 'green'}
                    onClick={() => {
                        this.getRandomTimeline(profileCollectionId);
                    }}
                >
                    Sample
                </Button>
                <Button
                    color={isWorking ? 'grey' : 'orange'}
                    onClick={() => {
                        this.sampleProfileNotSync(profileCollectionId);
                    }}
                >
                    Sample Not Sync Profile
                </Button>
                {profileId ? < Button
                    color={isWorking ? 'grey' : 'yellow'}
                    onClick={() => {
                        this.askForSync(profileCollectionId, profileId);
                    }}
                >
                    Ask For Sync
                </Button> : null}

            </div>
        );
    }

    render() {
        const { timeline, profileV5 } = this.state || {}
        return (
            <div>
                {this.renderProfileCollectionImportEditor()}
                {timeline ? <ItemTimeline timeline={timeline} profileV5={profileV5} key={random()} /> : null}
            </div>
        )
    }
}


class ItemTimelineRouter extends Component {
    render() {

        return (
            <Switch>
                <Route exact path='/ml/sweetchain/item-timeline'>
                    {({ location }) => {
                        const queryValues = queryString.parse(location.search);
                        const profilesCollectionId = queryValues.profilesCollectionId;
                        const profileId = queryValues.profileId;
                        return (
                            <ItemTimelineEditor
                                initialProfileCollectionId={profilesCollectionId}
                                initialProfileId={profileId}
                            />
                        );
                    }}
                </Route>
            </Switch >
        )
    }

}


export default ItemTimelineRouter;
