import React from 'react';
import { Form } from 'semantic-ui-react';

class TargetCollectionSettings extends React.Component {
  constructor(props) {
    super(props);

    this.handleSourceCollectionChange = this.handleSourceCollectionChange.bind(this);
    this.handleDestCollectionChange = this.handleDestCollectionChange.bind(this);
  }

  handleSourceCollectionChange(e, data) {
    this.props.onSettingsChange({
      ...this.props.settings,
      source_collection: data.value,
    });
  }

  handleDestCollectionChange(e, data) {
    this.props.onSettingsChange({
      ...this.props.settings,
      dest_collection: data.value,
    });
  }

  render() {
    const settings = this.props.settings || {};
    return (
      <Form>
        <Form.Input
          fluid
          label="Source Collection"
          value={settings.source_collection}
          onChange={this.handleSourceCollectionChange}
        />
        <Form.Input
          fluid
          label="Target Collection"
          value={settings.dest_collection}
          onChange={this.handleDestCollectionChange}
        />
      </Form>
    );
  }
}

export default TargetCollectionSettings;
