import React, { Component } from 'react';
import _ from 'underscore';

import { Button, Card, Form, Grid } from 'semantic-ui-react';
import { Input, Select, SweetForm, List } from 'sweetform';

import ProfilesViewModal from './ProfilesViewModal.js';

class IdealCandidatesSelection extends Component {
  handleChangeIdealCandidates = (params) => {
    const { setIdealCandidates } = this.props;
    const idealCandidateIds = _.filter((params || {}).idealCandidateIds, (candidateId) => !_.isEmpty(candidateId));
    setIdealCandidates(idealCandidateIds);
    this.setState({ idealCandidateIds });
  };

  onDisplayProfiles = async () => {
    this.setState({ displayProfilesModalVisible: true });
  };

  onCloseShowProfiles = () => {
    this.setState({ displayProfilesModalVisible: false });
  };

  render() {
    const { selectedClient, selectedMissionId } = this.props;
    const { displayProfilesModalVisible, idealCandidateIds } = this.state || {};
    return (
      <div>
        {displayProfilesModalVisible ? (
          <ProfilesViewModal
            positiveProfileIds={idealCandidateIds}
            onClose={this.onCloseShowProfiles}
            selectedClient={selectedClient}
            selectedMissionId={selectedMissionId}
          />
        ) : null}
        <Card
          style={{
            width: '100%',
          }}
        >
          <Card.Content>
            <Card.Header>
              <h3>Ideal Candidates Selection</h3>
            </Card.Header>
            <br />
            <Card.Description>
              <SweetForm onChange={(params) => this.handleChangeIdealCandidates(params)}>
                <Form.Field>
                  <List
                    field='idealCandidateIds'
                    component={Input}
                    style={{
                      width: '30%',
                    }}
                  />
                </Form.Field>
              </SweetForm>
              <br />
              <br />
            </Card.Description>
            <Button color='blue' onClick={() => this.onDisplayProfiles()}>
              Display Positive Profiles
            </Button>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

export default IdealCandidatesSelection;
