import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { Icon, Form, Button } from 'semantic-ui-react';
import { SweetForm, Input } from 'sweetform';

const NO_OFFER_ID = '__none';
const DISABLED_OFFER_ID = '__null';
const SEEN_OFFER_ID = '__seen';

class SpreadsheetEditForm extends React.Component {
  componentWillMount() {
    const { spreadsheet } = this.props;
    const initialValues = {
      ...(spreadsheet.comment && { comment: spreadsheet.comment }),
      ...(spreadsheet.sortIndex && { sortIdnex: spreadsheet.sortIndex }),
      ...(spreadsheet.offerId && { offerId: spreadsheet.offerId }),
    };
    this.setState({
      currentValue: initialValues,
      initialValues,
    });
  }
  handleChangeEdit = (newValue) => {
    this.setState({ currentValue: newValue });
  };
  handleSubmit = () => {
    const { onSubmit, spreadsheet } = this.props;
    const { initialValues, currentValue } = this.state;
    const diffObject = _.object(
      _.compact(
        _.map(_.keys(currentValue), (key) =>
          initialValues[key] || currentValue[key]
            ? [key, currentValue[key]]
            : null,
        ),
      ),
    );
    onSubmit(spreadsheet.id, diffObject);
  };
  render() {
    const { initialValues } = this.state;
    return (
      <SweetForm initialValues={initialValues} onChange={this.handleChangeEdit}>
        <Form>
          <Form.Field>
            Comment: <Input field={'comment'} />
          </Form.Field>
          <Form.Field>
            SweetappId: <Input field={'sweetappOfferId'} />
          </Form.Field>
          <Form.Field>
            BaseSweetsheetId: <Input field={'baseSweetsheetId'} />
          </Form.Field>
        </Form>
        <Button onClick={this.handleSubmit}>Save</Button>
      </SweetForm>
    );
  }
}

class MegaMigration extends React.Component {
  state = {
    spreadsheets: [],
  };
  componentWillMount() {
    this.loadSpreadsheets();
  }
  loadSpreadsheets = () => {
    axios.get(baseUrl + '/megamigration/spreadsheets').then(({ data }) => {
      if (data.error) {
        alert(data.error);
      } else {
        this.setState({ spreadsheets: data.spreadsheets || [] });
      }
    });
  };
  updateSpreadsheet = (spreadsheetId, diffObject) => {
    axios
      .put(baseUrl + '/megamigration/spreadsheets/id/' + spreadsheetId, {
        ...diffObject,
      })
      .then(({ data }) => {
        if (data && data.error) {
          alert(data.error);
        } else {
          const { spreadsheets } = this.state;
          this.setState({
            spreadsheets: _.compact(
              _.map(spreadsheets, (spreadsheet) =>
                spreadsheetId !== spreadsheet.id ? spreadsheet : data,
              ),
            ),
          });
        }
      });
  };
  createSweetsheet = async (spreadsheetId, baseSweetsheetId) => {
    const url =
      baseUrl +
      '/megamigration/spreadsheets/id/' +
      spreadsheetId +
      '/createSweetsheet/' +
      baseSweetsheetId;
    await axios.post(url);
    axios
      .get(baseUrl + '/megamigration/spreadsheets/id/' + spreadsheetId)
      .then(({ data }) => {
        if (data && data.error) {
          alert(data.error);
        } else {
          const { spreadsheets } = this.state;
          this.setState({
            spreadsheets: _.compact(
              _.map(spreadsheets, (spreadsheet) =>
                spreadsheetId !== spreadsheet.id ? spreadsheet : data,
              ),
            ),
          });
        }
      });
  };
  linkSweetsheetToOffer = async (spreadsheetId, sweetsheetId, offerId) => {
    const url =
      baseUrl +
      `/megamigration/linkOfferAndSweetsheet/offer/${offerId}/sweetsheet/${sweetsheetId}`;
    await axios.get(url);

    // TODO: refacto
    axios
      .get(baseUrl + '/megamigration/spreadsheets/id/' + spreadsheetId)
      .then(({ data }) => {
        if (data && data.error) {
          alert(data.error);
        } else {
          const { spreadsheets } = this.state;
          this.setState({
            spreadsheets: _.compact(
              _.map(spreadsheets, (spreadsheet) =>
                spreadsheetId !== spreadsheet.id ? spreadsheet : data,
              ),
            ),
          });
        }
      });
  };
  rebindSweetsheetItemIdsWithSweetappOffer = async (
    sweetsheetId,
    sweetappOfferId,
  ) => {
    const url =
      baseUrl +
      '/megamigration/rebindSweetsheetItemIdsWithSweetappOffer' +
      `/sweetsheet/${sweetsheetId}/sweetappOffer/${sweetappOfferId}`;
    const result = (await axios.get(url)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const { nbRemapped, nbInSweetapp } = result;
      alert(nbRemapped + ' items among ' + nbInSweetapp + ' on Sweetapp offer');
    }
  };
  selectSpreadsheet = (spreadsheetId) => {
    const { currentSpreadsheetId } = this.state;
    if (currentSpreadsheetId === spreadsheetId) {
      this.setState({
        currentSpreadsheetId: null,
      });
    } else {
      this.setState({
        currentSpreadsheetId: spreadsheetId,
      });
    }
  };
  renderSpreadsheet(spreadsheet, index, offerId) {
    const { currentSpreadsheetId } = this.state;

    const upRule = spreadsheet.spreadsheetUploadRule;
    let suggestedPlatformId = null;
    const ruleParts = (upRule || '').split(':');
    if (
      ruleParts.length === 3 &&
      ruleParts[0] === 'upload' &&
      (ruleParts[1] === 'hiresweet' || ruleParts[1] === 'sweetapp') &&
      ruleParts[2] !== 'company-offer'
    ) {
      suggestedPlatformId = ruleParts[2];
    }

    const baseSweetsheetId =
      spreadsheet.baseSweetsheetId || spreadsheet.offerId;

    return (
      <div style={{ paddingLeft: '20px' }}>
        <h2 style={{ marginBottom: 0 }}>
          {index + 1 + ') '}
          <a
            href={`https://docs.google.com/spreadsheets/d/${spreadsheet.id}`}
            target="_blank"
            rel='noopener noreferrer'
          >
            <Icon name="google" />
          </a>
          <Button onClick={() => this.selectSpreadsheet(spreadsheet.id)}>
            Edit
          </Button>
          <span
            style={{
              color:
                offerId === NO_OFFER_ID && spreadsheet.suggestedOfferId
                  ? 'green'
                  : 'black',
            }}
          >
            {' ' + spreadsheet.title}
          </span>
          <Icon
            style={{ cursor: 'pointer' }}
            name="delete"
            color="red"
            onClick={() => {
              this.updateSpreadsheet(spreadsheet.id, { disabled: true });
            }}
          />
          <Icon
            style={{ cursor: 'pointer' }}
            name="check"
            color="green"
            onClick={() => {
              this.updateSpreadsheet(spreadsheet.id, { disabled: false });
            }}
          />
          <Icon
            style={{ cursor: 'pointer' }}
            name="eye"
            color="blue"
            onClick={() => {
              this.updateSpreadsheet(spreadsheet.id, { seen: true });
            }}
          />
        </h2>
        {currentSpreadsheetId && currentSpreadsheetId === spreadsheet.id ? (
          <div key={spreadsheet.id}>
            <SpreadsheetEditForm
              spreadsheet={spreadsheet}
              onSubmit={this.updateSpreadsheet}
            />
          </div>
        ) : (
          <div>
            {spreadsheet.sweetsheetId &&
              (!spreadsheet.offerSweetsheetId ? (
                <div>
                  Sweetsheet not linked to offer
                  {spreadsheet.offerId && (
                    <Button
                      onClick={() => {
                        this.linkSweetsheetToOffer(
                          spreadsheet.id,
                          spreadsheet.sweetsheetId,
                          spreadsheet.offerId,
                        );
                      }}
                    >
                      Link {spreadsheet.sweetsheetId} to offer{' '}
                      {spreadsheet.offerId}
                    </Button>
                  )}
                </div>
              ) : spreadsheet.offerSweetsheetId !== spreadsheet.sweetsheetId ? (
                <div>
                  <span style={{ color: 'red' }}>
                    MISMATCH {spreadsheet.offerSweetsheetId} !=={' '}
                    {spreadsheet.sweetsheetId}
                  </span>
                </div>
              ) : (
                <div>
                  {spreadsheet.sweetappOfferId && (
                    <Button
                      onClick={() => {
                        this.rebindSweetsheetItemIdsWithSweetappOffer(
                          spreadsheet.sweetsheetId,
                          spreadsheet.sweetappOfferId,
                        );
                      }}
                    >
                      Rebind items to sweetapp offer{' '}
                      {spreadsheet.sweetappOfferId}
                    </Button>
                  )}
                  <span style={{ color: 'green' }}>Correctly linked</span>
                </div>
              ))}
            {spreadsheet.sweetsheetId ? (
              <div>
                Linked to sweetsheet
                <a
                  href={`/flowboard/sweetsheets/id/${spreadsheet.sweetsheetId}`}
                >
                  {' '}
                  {spreadsheet.sweetsheetId}
                </a>
              </div>
            ) : baseSweetsheetId ? (
              <div>
                <Button
                  onClick={() =>
                    this.createSweetsheet(spreadsheet.id, baseSweetsheetId)
                  }
                >
                  Create Sweetsheet {baseSweetsheetId}-...
                </Button>
              </div>
            ) : null}
            {spreadsheet.comment && (
              <div>
                <span>Comment: {spreadsheet.comment}</span>
                <br />
              </div>
            )}
            {upRule && (
              <div>
                <span style={{ color: 'purple' }}>{upRule}</span>
                <span> </span>
                {!spreadsheet.sweetappOfferId && suggestedPlatformId && (
                  <span
                    style={{ color: 'green', cursor: 'pointer' }}
                    onClick={() => {
                      this.updateSpreadsheet(spreadsheet.id, {
                        sweetappOfferId: suggestedPlatformId,
                      });
                    }}
                  >
                    [confirm {suggestedPlatformId}]
                  </span>
                )}
                <br />
              </div>
            )}
            {spreadsheet.sweetappOfferId && (
              <div>
                <span style={{ color: 'orange' }}>
                  {spreadsheet.sweetappOfferId}
                </span>
                <br />
              </div>
            )}
            {spreadsheet.suggestedOfferId && (
              <div>
                <span style={{ color: 'black' }}>
                  {spreadsheet.suggestedOfferId}
                </span>
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => {
                    this.updateSpreadsheet(spreadsheet.id, {
                      offerId: spreadsheet.suggestedOfferId,
                    });
                  }}
                >
                  [confirm]
                </span>
              </div>
            )}
            <br />
          </div>
        )}
      </div>
    );
  }
  render() {
    const { spreadsheets } = this.state;
    let spreadsheetsByOffer = {};
    spreadsheetsByOffer[NO_OFFER_ID] = [];
    spreadsheetsByOffer[SEEN_OFFER_ID] = [];
    spreadsheetsByOffer[DISABLED_OFFER_ID] = [];
    _.each(spreadsheets, (spreadsheet) => {
      const offerId = spreadsheet.offerId
        ? spreadsheet.offerId
        : spreadsheet.disabled
        ? DISABLED_OFFER_ID
        : spreadsheet.seen
        ? SEEN_OFFER_ID
        : NO_OFFER_ID;
      if (!spreadsheetsByOffer[offerId]) {
        spreadsheetsByOffer[offerId] = [];
      }
      spreadsheetsByOffer[offerId].push(spreadsheet);
    });

    return (
      <div>
        <h1>Spreadsheets</h1>
        {_.map(spreadsheetsByOffer, (offerSpreadsheets, offerId) => (
          <div key={'offer_' + offerId}>
            <h1>{offerId}</h1>
            {_.map(offerSpreadsheets, (spreadsheet, index) => (
              <div key={'spreadsheet_' + index}>
                {this.renderSpreadsheet(spreadsheet, index, offerId)}
              </div>
            ))}
            {offerId === NO_OFFER_ID && (
              <div>
                <br />
                <br />
                <br />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default MegaMigration;
