var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import React from 'react';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { List as IList, Map } from 'immutable';
import enhance from './enhance';
import Input from './Input';

var List = function (_React$Component) {
  _inherits(List, _React$Component);

  function List() {
    _classCallCheck(this, List);

    return _possibleConstructorReturn(this, (List.__proto__ || Object.getPrototypeOf(List)).apply(this, arguments));
  }

  _createClass(List, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          displayAddButton = _props.displayAddButton,
          value = _props.value,
          setValue = _props.setValue,
          InnerComponent = _props.component,
          compact = _props.compact,
          props = _objectWithoutProperties(_props, ['displayAddButton', 'value', 'setValue', 'component', 'compact']);

      var nullValue = InnerComponent === Input ? "" : Map();
      var safeValue = value ? value : IList();

      var addField = function addField() {
        return setValue(safeValue.push(nullValue));
      };

      var rmField = function rmField(i) {
        return setValue(safeValue.delete(i));
      };

      var crossSize = compact ? 1 : 2;
      return React.createElement(Grid, null, safeValue.map(function (_, i) {
        return React.createElement(Grid.Row, {
          key: i,
          style: {
            paddingBottom: 0
          }
        }, displayAddButton !== false && React.createElement(Grid.Column, {
          width: crossSize
        }, compact ? React.createElement(Icon, {
          name: 'close',
          link: true,
          onClick: function onClick() {
            return rmField(i);
          },
          style: {
            verticalAlign: 'bottom'
          }
        }) : React.createElement(Button, {
          negative: true,
          size: 'mini',
          icon: 'close',
          onClick: function onClick() {
            return rmField(i);
          }
        })), React.createElement(Grid.Column, {
          width: 16 - crossSize
        }, React.createElement(InnerComponent, Object.assign({}, props, {
          field: i
        }))));
      }), displayAddButton !== false && React.createElement(Grid.Row, null, React.createElement(Grid.Column, null, React.createElement(Button, {
        positive: true,
        size: 'mini',
        onClick: addField
      }, 'Add'))));
    }
  }]);

  return List;
}(React.Component);

;
export default enhance(List);