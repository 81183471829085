import React from 'react';
import _ from 'underscore';
import GeopySelector from './GeopySelector';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import Search from './Search';
import Location from './Location';

export default class AddLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: 0, patterns: [] };
  }
  async componentDidMount() {
    this.setState({ availableAPI: ['open-street-map'], availableAPIRoutes: ['osm'] }, () => {
      this.props.searchPattern && this.handleSearchSubmit(this.props.searchPattern);
    });
  }
  handleGeopySelectorClick = (index) => {
    if (this.state.availableAPI) {
      this.setState({ index });
    }
  };
  handleSearchSubmit = async (search) => {
    this.setState({ search: search });
    const response = await axios.get(
      `${baseUrl}/locationsV2/search/${this.state.availableAPIRoutes[this.state.index]}/${search}`,
    );
    const locations = response.data.locations;
    const pattern = this.props.searchPattern ? [this.props.searchPattern] : [];
    this.setState({
      locations: locations,
      index: 0,
      patterns: _.map(locations, () => pattern),
    });
  };

  handleInputChange = (index) => {
    return (e, { name, value }) => {
      let newLocations = Object.assign([], this.state.locations);
      newLocations[index][name] = value;
      this.setState({ locations: newLocations });
    };
  };

  handleBoundingBoxChange = (index) => {
    return (e, { name, value }) => {
      let newLocations = Object.assign([], this.state.locations);
      newLocations[index].shape.boxes[0][name] = parseFloat(value);
      this.setState({ locations: newLocations });
    };
  };
  handleChangeCountryMembers = (index)=>{
    return ((e) => {
      let newLocations = Object.assign([], this.state.locations);
      newLocations[index].countryMembers = _.pluck(e, 'value');
      this.setState({ locations: newLocations });
    })
  }
  handleLocationSubmit = (index) => {
    return async () => {
      const location = this.state.locations[index];
      const patterns = this.state.patterns[index];
      if (!_.isEmpty(location.countryMembers) && location.type !== 'country-group'){
        return alert('Only locations of type "country-group" can have country members');
      }
      patterns && patterns.length > 0
        ? await axios
            .post(`${baseUrl}/locationsV2/location`, { ...location })
            .then((response) => {
              alert('add location ' + response.statusText);
              return axios.post(`${baseUrl}/locationsV2/monoEntityUnambiguousMappings`, {
                locationId: location.id,
                patterns: patterns,
              });
            })
            .then((response) => {
              alert('add patterns ' + response.statusText);
            })
            .catch((error) => alert(error))
        : alert('patterns required for new location');
    };
  };

  handlePatterns = (index) => {
    return (e, v) => {
      let newPatterns = Object.assign([], this.state.patterns);
      newPatterns[index] = v.value;
      this.setState({ patterns: newPatterns });
    };
  };

  render() {
    return (
      <div>
        <GeopySelector availableAPI={this.state.availableAPI} handleClick={this.handleGeopySelectorClick} />
        <Search handleSubmit={this.handleSearchSubmit} placeholder="Search location..." />
        {_.map(this.state.locations, (location, index) => (
          <Location
            location={location}
            patternOptions={_.map(this.state.patterns[index], (pattern) => ({
              key: pattern,
              value: pattern,
              text: pattern,
            }))}
            patternValue={this.state.patterns[index]}
            handleInputChange={this.handleInputChange(index)}
            handleBoundingBoxChange={this.handleBoundingBoxChange(index)}
            editable
            editableId
            handlePatterns={this.handlePatterns(index)}
            handleSubmit={this.handleLocationSubmit(index)}
            handleChangeCountryMembers={this.handleChangeCountryMembers(index)}
          />
        ))}
      </div>
    );
  }
}
