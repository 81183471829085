import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Grid,
  Header,
  Button,
  Table,
  Form,
  Divider,
  Tab,
  Label,
} from 'semantic-ui-react';
import {
  SweetForm,
  Clearable,
  Input,
  Select,
  SelectInt,
  Range,
  List,
  customOperator,
} from 'sweetform';

import baseUrl from './baseUrl.js';
import Modal from './Modal';
import WorkplacesViews from './WorkplacesViews';
import Pagination from './Pagination';
import { textToId, Textarea } from './common';

// Actions
const SET_WORKPLACE_LIST = 'SET_WORKPLACE_LIST';
const setWorkplaceList = (workplaceList) => ({
  type: SET_WORKPLACE_LIST,
  workplaceList,
});

const SET_COUNTS = 'SET_COUNTS';
const setCounts = (counts) => ({ type: SET_COUNTS, counts });

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_SELECTED_WORKPLACE = 'SET_SELECTED_WORKPLACE';
const setSelectedWorkplace = (data) => ({ type: SET_SELECTED_WORKPLACE, data });

const SET_HIRESWEET_WORKPLACE = 'SET_HIRESWEET_WORKPLACE';
const setHiresweetWorkplace = (hw) => ({ type: SET_HIRESWEET_WORKPLACE, hw });

const SET_EDIT_MODE = 'SET_EDIT_MODE';
const setEditMode = (mode) => ({ type: SET_EDIT_MODE, mode });

const SET_INDUSTRIES = 'SET_INDUSTRIES';
const setIndustries = (industries) => ({ type: SET_INDUSTRIES, industries });

const SET_ACTION = 'SET_ACTION';
const setAction = (action) => ({ type: SET_ACTION, action });

const SET_EDIT = 'SET_EDIT';
const setEdit = (edit) => ({ type: SET_EDIT, edit });

// Reducers
const updateWorkplace = (state, hiresweetWorkplace) => {
  const source = {
    ...hiresweetWorkplace,
    patterns: hiresweetWorkplace.patterns.join(';'),
    source: 'hiresweetWorkplaces',
  };
  if (!state) {
    return {
      id: hiresweetWorkplace.id,
      name: hiresweetWorkplace.name,
      sources: [source],
    };
  }

  const otherSources = _.filter(
    state.sources,
    (s) => s.source !== 'hiresweetWorkplaces',
  );
  return {
    ...state,
    sources: [source, ...otherSources],
  };
};

const workplaceList = (state = [], action) => {
  switch (action.type) {
    case SET_WORKPLACE_LIST:
      return action.workplaceList;
    case SET_SELECTED_WORKPLACE:
      if (!action.data) {
        return state;
      }
      const i = _.findIndex(state, (w) => w.id === action.data.id);
      return [...state.slice(0, i), action.data, ...state.slice(i + 1)];
    case SET_HIRESWEET_WORKPLACE:
      const j = _.findIndex(state, (w) => w.id === action.hw.id);
      const newWorkplace = updateWorkplace(state[j], action.hw);
      return [...state.slice(0, j), newWorkplace, ...state.slice(j + 1)];
    default:
      return state;
  }
};

const counts = (state = {}, action) => {
  switch (action.type) {
    case SET_COUNTS:
      return action.counts;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const selectedWorkplace = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_WORKPLACE:
      return action.data;
    case SET_HIRESWEET_WORKPLACE:
      return updateWorkplace(state, action.hw);
    default:
      return state;
  }
};

const hiresweetWorkplace = (state = null, action) => {
  switch (action.type) {
    case SET_HIRESWEET_WORKPLACE:
      return action.hw;
    case SET_SELECTED_WORKPLACE:
      if (!action.data) {
        return null;
      }
      const hw = _.findWhere(action.data.sources, {
        source: 'hiresweetWorkplaces',
      });
      return hw
        ? {
            ...hw,
            patterns: (hw.patterns || '').split(';'),
          }
        : null;
    default:
      return state;
  }
};

const editMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.mode;
    default:
      return state;
  }
};

const industries = (state = [], action) => {
  switch (action.type) {
    case SET_INDUSTRIES:
      return action.industries;
    default:
      return state;
  }
};

const actions = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
    case SET_SELECTED_WORKPLACE:
    case SET_HIRESWEET_WORKPLACE:
      return {};
    case SET_ACTION:
      return {
        ...state,
        [action.action]: true,
      };
    default:
      return state;
  }
};

const edit = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_WORKPLACE:
    case SET_EDIT_MODE:
      return {};
    case SET_EDIT:
      return action.edit;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  search,
  workplaceList,
  counts,
  selectedWorkplace,
  hiresweetWorkplace,
  editMode,
  edit,
  industries,
  actions,
});

// Store
const store = createStore(rootReducer);

// Components
const AddWorkplaceModal = ({ initialValues, onCancel, onChange, onSubmit }) => (
  <Modal
    active={true}
    headerText="Add a new hiresweetWorkplace"
    submitText="Next"
    onSubmit={onSubmit}
    onCancel={onCancel}
  >
    <Form onSubmit={onSubmit}>
      <SweetForm onChange={onChange} initialValues={initialValues}>
        <Form.Field>
          <label>Identifier</label>
          <Clearable component={Input} field="id" nullLabel="Auto" />
        </Form.Field>
        <Form.Field>
          <label>Name</label>
          <Input autoFocus field="name" />
        </Form.Field>
        <input type="submit" style={{ visibility: 'hidden' }} />
      </SweetForm>
    </Form>
  </Modal>
);
const getClients = async (input) => {
  const result = await axios.get(
    `${baseUrl}/station/clients?limit=100&filter=${input}`,
  );
  return { options: result.data.error ? [] : result.data };
};

const COMPANY_TYPES_OPTIONS = [
  { name: 'Start Up', id: 'startup' },
  { name: 'Big', id: 'big' },
  { name: 'SSII', id: 'ssii' },
];

const EditWorkplaceModal = ({
  industries,
  workplaceName,
  initialValues,
  onCancel,
  onChange,
  onSubmit,
}) => (
  <Modal
    active={true}
    headerText={`Edit workplace ${workplaceName}`}
    submitText="Submit"
    onSubmit={onSubmit}
    onCancel={onCancel}
  >
    <Form>
      <SweetForm
        onChange={onChange}
        initialValues={{
          ...initialValues,
          industriesLabeled: (initialValues.industriesLabeled || []).join(';'),
          companyTypes: (initialValues.companyTypes || []).join(';'),
        }}
      >
        <Grid columns={2}>
          <Grid.Column>
            <Form.Field>
              <label>Name</label>
              <Input autoFocus field="name" />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <SelectType field="type" />
            </Form.Field>
            <Form.Field>
              <label>Creation date</label>
              <Input
                field="creationDate"
                type="date"
                placeholder="yyyy-mm-dd"
              />
            </Form.Field>
            <Form.Field>
              <label>Industries</label>
              <Select
                multi
                field="industriesLabeled"
                value
                options={industries}
                labelKey="name"
                valueKey="id"
              />
            </Form.Field>
            <Form.Field>
              <label>Company Types</label>
              <Select
                multi
                field="companyTypes"
                value
                options={COMPANY_TYPES_OPTIONS}
                labelKey="name"
                valueKey="id"
              />
            </Form.Field>
            <Form.Field>
              <label>Tags</label>
              <List field="tags" component={Input} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label>Station Client</label>
              <Select
                async
                field="platformId"
                loadOptions={getClients}
                labelKey="name"
                valueKey="id"
              />
            </Form.Field>
            <Form.Field>
              <label>Staff count range</label>
              <Range field="staffCountRange" min={0} max={10000} step={10} />
            </Form.Field>
            <Form.Field>
              <label>Status</label>
              <SelectStatus field="status" />
            </Form.Field>
            <Form.Field>
              <label>Prestige</label>
              <SelectPrestige field="prestige" />
            </Form.Field>
            <Form.Field>
              <label>Network Level</label>
              <SelectNetworkLevel field="networkLevel" />
            </Form.Field>
            <Form.Field>
              <label>Admin #Tags</label>
              <Input field="adminTagsText" placeholder="A #gfc #tech company" />
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Form.Field>
          <label>Patterns</label>
          <List field="patterns" component={Input} />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <Textarea field="description" />
        </Form.Field>
      </SweetForm>
    </Form>
  </Modal>
);
const bc = { style: { marginLeft: 10 } }; // button class
const WorkplaceDetails = ({
  workplace,
  source,
  actions,
  onBack,
  onAdd,
  onEdit,
  onDelete,
  onSource,
  onUpdate,
  onRecreate,
}) => {
  const paneRender = (source) => () => (
    <Tab.Pane>
      <WorkplacesViews
        workplace={
          source
            ? _.findWhere(workplace.sources, { source })
            : _.omit(workplace, 'source', 'patterns')
        }
      />
    </Tab.Pane>
  );

  return (
    <div>
      <Button {...bc} onClick={onBack}>
        Back to results
      </Button>
      {actions.recreate ? (
        <Button {...bc} loading color="blue">
          Recreating...
        </Button>
      ) : (
        <span>
          <Button {...bc} color="blue" onClick={() => onUpdate(workplace.id)}>
            Update
          </Button>
          <Button
            {...bc}
            color="blue"
            onClick={() => {
              onRecreate(workplace.id);
            }}
          >
            Recreate
          </Button>
          <Button
            {...bc}
            color="blue"
            onClick={() => onRecreate(workplace.id, true)}
          >
            Full Recreate
          </Button>
        </span>
      )}
      {_.every(workplace.sources, (s) => s.source !== 'hiresweetWorkplaces') ? (
        <Button
          {...bc}
          color="green"
          onClick={() => onAdd(_.pick(workplace, 'id', 'name'))}
        >
          Add hiresweetWorkplace
        </Button>
      ) : (
        [
          <Button key={1} {...bc} color="yellow" onClick={onEdit}>
            Edit
          </Button>,
          <Button key={2} {...bc} color="red" onClick={onDelete}>
            Delete
          </Button>,
        ]
      )}

      <Tab
        style={{ marginTop: 40 }}
        panes={[
          { menuItem: 'mergedWorkplace', render: paneRender() },
          ..._.map(workplace.sources, ({ source }) => ({
            menuItem: source,
            render: paneRender(source),
          })),
        ]}
      />
    </div>
  );
};

const statusToTagColor = {
  current: 'green',
  hold: 'yellow',
  finished: 'grey',
};
const WorkplaceList = ({ counts, workplaces, onPage, onSelect, onAdd }) => (
  <div>
    <Grid>
      <Grid.Column width={3}>
        <strong>Results: {counts.results}</strong>
      </Grid.Column>
      <Grid.Column width={13} textAlign="right">
        <Button color="green" onClick={() => onAdd()}>
          Add workplace
        </Button>
      </Grid.Column>
    </Grid>
    <Divider />
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Reference</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>HireSweet</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(workplaces, (workplace) => (
          <Table.Row key={workplace.id} onClick={() => onSelect(workplace)}>
            <Table.Cell>{workplace.id}</Table.Cell>
            <Table.Cell>{workplace.name}</Table.Cell>
            <Table.Cell>
              <Label
                color={
                  _.some(
                    workplace.sources,
                    (s) => s.source === 'hiresweetWorkplaces',
                  )
                    ? 'green'
                    : 'red'
                }
              />
            </Table.Cell>
            <Table.Cell>
              {workplace.status ? (
                <Label color={statusToTagColor[workplace.status]} />
              ) : null}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {counts.pages ? (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>
              <Pagination
                floated="right"
                current={counts.pageOffset}
                total={counts.pages}
                onPage={onPage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      ) : null}
    </Table>
  </div>
);
const SelectStatus = (props) => (
  <Select
    {...props}
    options={[
      { value: 'current', label: 'Current' },
      { value: 'hold', label: 'Hold' },
      { value: 'finished', label: 'Finished' },
    ]}
  />
);

const SelectType = (props) => (
  <Select
    {...props}
    options={[
      { value: 'freelance', label: 'Freelance' },
      { value: 'school', label: 'School' },
      { value: 'research', label: 'Research' },
      { value: 'company', label: 'Company' },
    ]}
  />
);

const SelectPrestige = (props) => <SelectInt {...props} min={0} max={5} />;
const SelectNetworkLevel = (props) => (
  <Select
    {...props}
    options={[
      { value: 0, label: '0 (active client)' },
      { value: 1, label: '1 (inactive client)' },
      { value: 2, label: '2 (in touch)' },
      { value: 3, label: '3 (close network)' },
      { value: 4, label: '4 (far network)' },
      { value: 5, label: '5 (not network)' },
    ]}
  />
);

const Filters = customOperator(
  {
    not: { label: 'NOT', children: 1 },
    and: { label: 'AND', children: 'n' },
    or: { label: 'OR', children: 'n' },
    name: { label: 'Name', children: 1, nested: true, component: Input },
    location: {
      label: 'Location',
      children: 1,
      nested: true,
      component: Input,
    },
    isHiresweet: { label: 'Hiresweet' },
    status: {
      label: 'Status',
      children: 1,
      nested: true,
      component: SelectStatus,
    },
    type: { label: 'Type', children: 1, nested: true, component: SelectType },
    prestige: {
      label: 'Prestige',
      children: 1,
      nested: true,
      component: SelectPrestige,
    },
  },
  false,
);

class Workplaces extends Component {
  componentDidMount() {
    this.props.onLoad();
    this.props.onSearch();
  }

  render() {
    const {
      workplaceList,
      counts,
      search,
      selectedWorkplace,
      hiresweetWorkplace,
      source,
      actions,
      editMode,
      edit,
      industries,
      onSearch,
      onChangeSearch,
      onClickWorkplace,
      onBack,
      onSource,
      onAdd,
      onAddSubmit,
      onEdit,
      onSubmit,
      onDelete,
      onDeleteConfirm,
      onCancel,
      onUpdate,
      onRecreate,
      onChangeEdit,
    } = this.props;

    return (
      <Grid>
        <Grid.Column width={4}>
          <SweetForm onChange={onChangeSearch}>
            <Header as="h2">Reference</Header>
            <Input field="ref" />

            <Header as="h2">Filters</Header>
            <Filters field="filters" />
          </SweetForm>

          <Button color="teal" fluid onClick={() => onSearch(search)}>
            Search
          </Button>
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedWorkplace ? (
            <WorkplaceDetails
              workplace={selectedWorkplace}
              source={source}
              actions={actions}
              onBack={onBack}
              onSource={onSource}
              onAdd={onAdd}
              onEdit={onEdit}
              onSubmit={onSubmit}
              onDelete={onDelete}
              onDeleteConfirm={onDeleteConfirm}
              onUpdate={onUpdate}
              onRecreate={onRecreate}
            />
          ) : (
            <WorkplaceList
              counts={counts}
              workplaces={workplaceList}
              onAdd={onAdd}
              onSelect={onClickWorkplace}
              onPage={(i) => onSearch(search, i)}
            />
          )}
        </Grid.Column>
        {editMode === 'add' ? (
          <AddWorkplaceModal
            onCancel={onCancel}
            onChange={onChangeEdit}
            onSubmit={() => onAddSubmit(selectedWorkplace, edit)}
            initialValues={edit}
          />
        ) : null}
        {editMode === 'edit' ? (
          <EditWorkplaceModal
            industries={industries}
            workplaceName={hiresweetWorkplace ? hiresweetWorkplace.name : ''}
            onCancel={onCancel}
            onChange={onChangeEdit}
            onSubmit={() => onSubmit(edit)}
            initialValues={hiresweetWorkplace}
          />
        ) : null}
        {editMode === 'delete' ? (
          <Modal
            active={true}
            headerText={`Delete workplace ${selectedWorkplace.name}`}
            submitText="Confirm"
            onCancel={onCancel}
            onSubmit={() => onDeleteConfirm(selectedWorkplace)}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </Modal>
        ) : null}
      </Grid>
    );
  }
}

// Containers
const mapSWorkplaces = (state) => ({
  search: state.search,
  workplaceList: state.workplaceList,
  counts: state.counts,
  selectedWorkplace: state.selectedWorkplace,
  hiresweetWorkplace: state.hiresweetWorkplace,
  editMode: state.editMode,
  edit: state.edit,
  industries: state.industries,
  actions: state.actions,
});

const mapDWorkplaces = (dispatch) => ({
  onLoad: async () => {
    const result = await axios.get(`${baseUrl}/tags/list?type=industry`);
    dispatch(setIndustries(result.data));
  },
  onChangeSearch: (s) => dispatch(setSearch(s)),
  onSearch: async (search, page = 0) => {
    const result = await axios.get(`${baseUrl}/workplaces`, {
      params: { search, page },
    });
    const { workplaces, counts } = result.data;
    dispatch(setCounts(counts));
    dispatch(setWorkplaceList(workplaces));
  },
  onClickWorkplace: (workplace) => dispatch(setSelectedWorkplace(workplace)),
  onBack: () => dispatch(setSelectedWorkplace(null)),
  onAdd: (edit) => {
    dispatch(setEditMode('add'));
    if (edit) {
      dispatch(setEdit(edit));
    }
  },
  onAddSubmit: (selectedWorkplace, values) => {
    if (!values.name) {
      return;
    }

    values.id = values.id || textToId(values.name);
    dispatch(
      setSelectedWorkplace({
        id: values.id,
        name: values.name,
        sources: [
          {
            id: values.id,
            name: values.name,
            source: 'hiresweetWorkplaces',
          },
          ...(selectedWorkplace ? selectedWorkplace.sources : []),
        ],
      }),
    );
    dispatch(setEditMode('edit'));
  },
  onChangeEdit: (e) => dispatch(setEdit(e)),
  onEdit: () => dispatch(setEditMode('edit')),
  onSubmit: async (values) => {
    values.industriesLabeled = values.industriesLabeled
      ? values.industriesLabeled.split(';')
      : [];
    values.companyTypes = values.companyTypes
      ? values.companyTypes.split(';')
      : [];
    values.tags = values.tags || [];

    await axios.put(`${baseUrl}/workplaces/${values.id}`, {
      ...values,
      patterns: values.patterns.join(';'),
    });

    dispatch(setEditMode('none'));
    dispatch(setHiresweetWorkplace(values));
  },
  onDelete: () => dispatch(setEditMode('delete')),
  onDeleteConfirm: async (workplace) => {
    await axios.delete(`${baseUrl}/workplaces/${workplace.id}`);
    dispatch(
      setSelectedWorkplace({
        ...workplace,
        sources: _.filter(
          workplace.sources,
          (s) => s.source !== 'hiresweetWorkplaces',
        ),
      }),
    );
    dispatch(setEditMode('none'));
  },
  onCancel: () => dispatch(setEditMode('none')),
  onUpdate: async (id) => {
    dispatch(setAction('recreate'));
    await axios.get(`${baseUrl}/cerejobs/workplaces/updateOne/${id}`);
  },
  onRecreate: async (id, full) => {
    if (!id) {
      alert('empty workplace id');
      return;
    }
    const fullQuery = full ? '?full=1' : '';
    dispatch(setAction('recreate'));
    await axios.get(
      `${baseUrl}/cerejobs/workplaces/recreateOne/${id}${fullQuery}`,
    );
  },
});

const WorkplacesContainer = connect(
  mapSWorkplaces,
  mapDWorkplaces,
)(Workplaces);

export default ({ match, history }) => (
  <Provider store={store}>
    <WorkplacesContainer match={match} history={history} />
  </Provider>
);
