import _ from 'underscore';
import React, { Component } from 'react';
import { Label, Table } from 'semantic-ui-react';

const Job = ({ job }) =>
  job ? (
    <div>
      {job.name || job.id}
      <br />
      <Label>id: {job.id}</Label>
    </div>
  ) : null;

class JobViewer extends Component {
  render() {
    const { job, selectedJob, onSelectJob, colorMap } = this.props;
    const groups = job.groups || [];
    const strongDependencies = job.strong_dependencies || [];
    const mediumDependencies = job.medium_dependencies || [];
    const weakDependencies = job.weak_dependencies || [];

    return (
      <Table.Row key={job.id} onClick={() => onSelectJob(job)} style={{ cursor: 'pointer' }}>
        <Table.Cell>
          <span
            style={
              selectedJob && selectedJob.id === job.id
                ? {
                    fontWeight: 'bold',
                    color: 'green',
                    fontSize: '13px',
                  }
                : {}
            }
          >
            <Job job={job} />
          </span>
        </Table.Cell>
        <Table.Cell>
          {_.map(groups, (group) => {
            const groupId = group.id;
            return (
              <Label
                key={groupId}
                style={{
                  color: 'white',
                  backgroundColor: (colorMap || {})[groupId],
                }}
              >
                {groupId}
              </Label>
            );
          })}
        </Table.Cell>
        <Table.Cell>
          {strongDependencies.map((dependency) => {
            return (
              <Label
                key={dependency}
                style={{
                  color: 'white',
                  backgroundColor: '#008000',
                }}
              >
                {dependency}
              </Label>
            );
          })}
          {mediumDependencies.map((dependency) => {
            return (
              <Label
                key={dependency}
                style={{
                  color: 'white',
                  backgroundColor: '#3CB371',
                }}
              >
                {dependency}
              </Label>
            );
          })}
          {weakDependencies.map((dependency) => {
            return (
              <Label
                key={dependency}
                style={{
                  backgroundColor: '#98FB98',
                }}
              >
                {dependency}
              </Label>
            );
          })}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default JobViewer;
