import _ from 'underscore';
import React from 'react';
import { Modal, Grid, Table, Loader, Icon } from 'semantic-ui-react';
import GenericWidget from './GenericWidget';

const WidgetPreviewModal = ({ onClose, widget }) => (
  <Modal
    open={true}
    onClose={onClose}
    closeIcon
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      {(widget || {}).title} ({(widget || {}).id})
    </Modal.Header>
    <Modal.Content>
      <center>
        <GenericWidget widget={widget} />
      </center>
    </Modal.Content>
  </Modal>
);

export default WidgetPreviewModal;
