import React from 'react';
import ProspectsSearch from './ProspectsSearch';
import ProspectsDump from './ProspectsDump';
import axios from 'axios';
import _ from 'underscore';
import moment from 'moment';
import { Modal, Button, Table, Icon } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';


class ProspectsDumpSelector extends React.Component {
  state = { modalOpen: false }

  componentDidMount = async () => {
    this.handleLoadDumps();
  }

  handleLoadDumps = async () => {
    const { prospectDumps } = (await axios.get(`${baseUrl}/salesOptimisationApi/prospects/listDumps`)).data;
    console.log({ prospectDumps })
    this.setState({
      prospectDumps,
    })
  }

  handleOpenSelectionModal = async () => {
    await this.handleLoadDumps();
    this.setState({ modalOpen: true });
  }

  handleCloseSelectionModal = () => {
    this.setState({ modalOpen: false });
  }

  handleSelectDumpId = ({ dumpId }) => {
    this.props.handleSelectDumpId({ dumpId });
    this.setState({ modalOpen: false });
  }



  render = () => {
    const { prospectDumps, modalOpen } = this.state;
    const selectedDump = _.find(prospectDumps, ({ id }) => (id == this.props.selectedDumpId));
    return (
      <div>
        <Button basic icon onClick={() => this.handleOpenSelectionModal()} color="green"><Icon name="save outline"/>Load</Button>
        {selectedDump ? (
          <span><b>{selectedDump.name}</b></span>
        ) : this.props.selectedDumpId ? (
          <span>No dump with id {this.props.selectedDumpId}</span>
        ) : (
          <span>No dump selected</span>
        )}
        {modalOpen && (
          <Modal closeIcon open={true} onClose={() => this.handleCloseSelectionModal()}>
            <Modal.Content>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Dump Id</Table.HeaderCell>
                    <Table.HeaderCell>Dump Name</Table.HeaderCell>
                    <Table.HeaderCell>Creation Date</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    _.map(_.sortBy(prospectDumps, ({creationDate})=>(creationDate)).reverse(), ({ id, name, creationDate }) => (
                      <Table.Row
                        key={id}
                        onClick={() => this.handleSelectDumpId({ dumpId: id })}
                        style={{ cursor: 'pointer' }}
                      >
                        <Table.Cell>
                          {id}
                        </Table.Cell>
                        <Table.Cell>
                          {name}
                        </Table.Cell>
                        <Table.Cell>
                          {creationDate}
                        </Table.Cell>

                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
            </Modal.Content>
          </Modal>
        )}
      </div>
    )
  }

}

class Prospects extends React.Component {

  state = {
    selectedDumpId: null,
  };

  componentDidMount = async () => {
    if (this.props.match.params.dumpId) {
      this.setState({ selectedDumpId: this.props.match.params.dumpId });
    }
  };

  handleSelectDumpId = ({ dumpId }) => {
    this.setState({ selectedDumpId: dumpId });
  };

  render() {
    return (
      <div>
        <ProspectsDumpSelector handleSelectDumpId={this.handleSelectDumpId} selectedDumpId={this.state.selectedDumpId} />
        {this.state.selectedDumpId ? (
          <ProspectsDump dumpId={this.state.selectedDumpId} />
        ) : (
          <ProspectsSearch />
        )}
      </div>
    )

  }
}
export default Prospects;
