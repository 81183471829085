import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import baseUrl from './baseUrl.js';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

// Reducers
const monitoringStats = (state = {}, action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  monitoringStats,
});

// Components
const store = createStore(rootReducer);

class SchoolParsing extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { monitoringStats } = this.props;

    return <pre>{JSON.stringify(monitoringStats, null, '  ')}</pre>;
  }
}

// Containers
const mapSSchoolParsing = (state) => ({
  monitoringStats: state.monitoringStats,
});

const mapDSchoolParsing = (dispatch) => ({
  onLoad: async () => {
    const { data } = await axios.get(`${baseUrl}/monitoring/schoolParsing`);
    dispatch(setInstanceData(data));
  },
});

const SchoolParsingContainer = connect(
  mapSSchoolParsing,
  mapDSchoolParsing,
)(SchoolParsing);

export default () => (
  <Provider store={store}>
    <SchoolParsingContainer />
  </Provider>
);
