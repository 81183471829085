import _ from 'underscore';
import React from 'react';
import { Container, Button, Form, Modal } from 'semantic-ui-react';
import { SweetForm, Input, TextArea, Checkbox, Select } from 'sweetform';

class EditOfferModal extends React.Component {
  state = {
    currentValue: this.props.initialValues || {},
  };
  
  handleChangeEdit = (newValue) => {
    this.setState({ currentValue: newValue });
  };
  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { currentValue } = this.state;
    if (!currentValue.id || _.isEmpty(currentValue.title)) {
      return alert('fields id and title are required !');
    }
    if (currentValue.id.indexOf(' ') >= 0) {
      return alert('white spaces are not allowed in ids');
    }
    if (!this.props.edition && currentValue.id.toLowerCase() !== currentValue.id) {
      return alert('upper case characters are not allowed in ids');
    }
    if (currentValue.id.indexOf('/') >= 0) {
      return alert('slashes are not allowed in ids');
    }
    onSubmit(currentValue);
  };
  handleDelete = () => {
    const { onDelete } = this.props;
    const { currentValue } = this.state;
    onDelete(currentValue);
  };

  render() {
    const { initialValues, onClose } = this.props;

    const creationMode = _.isEmpty(initialValues) || !initialValues.id;

    const { currentValue } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>
          {creationMode ? 'Create Offer' : 'Edit Offer ' + initialValues.id}
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Container>
            <SweetForm
              initialValues={initialValues}
              onChange={this.handleChangeEdit}
            >
              <Form>
                <Form.Field>
                  <label>Id</label>
                  {!creationMode ? (
                    <Input disabled field="id" />
                  ) : (
                    <Input field="id" />
                  )}
                </Form.Field>
                <Form.Field>
                  <label>ClientId</label>
                  <Input disabled field="clientId" />
                </Form.Field>
                <Form.Field>
                  <label>Title</label>
                  <Input autoFocus field="title" />
                </Form.Field>
                <Form.Field>
                  <label>SheetId</label>
                  <Input field="sheetId" />
                </Form.Field>
                <Form.Field>
                  <label>Status</label>
                  <Input field="status" />
                </Form.Field>
                <Form.Field>
                  <label>Estimated Number of Sends By Positive Answer</label>
                  <Select
                    field="estimatedNbSendsByPositiveAnswer"
                    options={[
                      { label: '0 (i.e. disabled)', value: 0 },
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                      { label: '6', value: 6 },
                      { label: '7 (UI/UX)', value: 7 },
                      { label: '8 (PM)', value: 8 },
                      { label: '9', value: 9 },
                      { label: '10 (Business)', value: 10 },
                      { label: '11', value: 11 },
                      { label: '12', value: 12 },
                      { label: '13 (Data-science)', value: 13 },
                      { label: '14 (Lead/CTO/Manager, Data)', value: 14},
                      { label: '15', value: 15 },
                      { label: '16', value: 16 },
                      { label: '17', value: 17 },
                      { label: '18', value: 18 },
                      { label: '19 (Mobile-other)', value: 19 },
                      { label: '20 (iOS)', value: 20 },
                      { label: '21 (DevOps, QA, C/C++)', value: 21 },
                      { label: '22 (Android)', value: 22 },
                      { label: '23 (Fullstack JS)', value: 23 },
                      { label: '24 (Back JS, Go)', value: 24 },
                      { label: '25 (PHP, Java)', value: 25 },
                      { label: '26', value: 26 },
                      { label: '27', value: 27 },
                      { label: '28 (Front JS, Ruby)', value: 28 },
                      { label: '29 (Python, C#/.NET)', value: 29 },
                      { label: '30', value: 30 },
                      { label: '31', value: 31 },
                      { label: '32', value: 32 },
                      { label: '33', value: 33 },
                      { label: '34', value: 34 },
                      { label: '35', value: 35 },
                      { label: '36 (Scala)', value: 36 },
                      { label: '37', value: 37 },
                      { label: '38', value: 38 },
                      { label: '39', value: 39 },
                      { label: '40', value: 40 },
                    ]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Unlimited flow batch size (task force)</label>
                  <Select field="unlimitedFlowBatchSize" options={[{label: '0 (i.e. disabled)', value: 0}, {label: '5', value: 5}, {label: '10', value: 10}]}/>
                </Form.Field>
                <Form.Field>
                  <label>Admin Tags</label>
                  <Select
                    field='adminTags'
                    options={[
                      { id: 'managed-offer', name: 'Managed Offer' },
                      { id: 'recontact-offer', name: 'Recontact Offer' },
                      { id: 'external-send', name: 'External Send' },
                      { id: 'no-followup', name: 'No Followup' },
                      { id: 'on-fly-template-selection', name: 'On-Fly Template Selection' },
                      { id: 'wants-no-notification', name: 'Wants No Notification' },
                    ]}
                    labelKey="name"
                    valueKey="id"
                    multi={true}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Is Fake</label>
                  <Checkbox field="isFake" />
                </Form.Field>
                <Form.Field>
                  <label>Is Suggested For Creation (Offer Import)</label>
                  <Checkbox field="isSuggestedForCreation" />
                </Form.Field>
                <Form.Field>
                  <label>Description</label>
                  <TextArea field="description" />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    slider
                    defaultValue={false}
                    field="manualStepsAllowed"
                    label="Manual Steps Allowed"
                  />
                </Form.Field>
                {!creationMode && (
                  <Form.Field>
                    <label>Write "DELETE" to enable the delete button</label>
                    <Input field="deleteField" />
                  </Form.Field>
                )}
              </Form>
            </SweetForm>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          {!creationMode &&
            currentValue &&
            currentValue.deleteField === 'DELETE' && (
              <Button negative onClick={this.handleDelete}>
                DELETE
              </Button>
            )}
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={this.handleSubmit}>
            {creationMode ? 'Create' : 'Edit'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditOfferModal;
