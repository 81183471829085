import React, { Component } from 'react';
import { Divider, Grid, Icon, Label, List, Modal, Segment } from 'semantic-ui-react';

import axios from 'axios';
import { Button } from 'semantic-ui-react';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import EditJobModal, { hasEmptyPattern, ensureDefaultTranslation } from './EditJobModal';
import { getPayloadFromParams } from './index';

const Job = ({ job }) =>
  job ? (
    <div>
      <h2>{job.name}</h2>
      <Label>id: {job.id}</Label>
    </div>
  ) : null;

export const updateJob = async (params, isGroup, groupIdToDiffusionToGroup, jobIdToFullJobInGroup) => {
  const payload = await getPayloadFromParams(params, isGroup, groupIdToDiffusionToGroup, jobIdToFullJobInGroup);
  const url = baseUrl + '/cerejobsV2/updateJob';
  const { data } = await axios.post(url, payload);
  return data;
};

export const updateJobDependencies = async (
  params,
  isGroup,
  jobs,
  groupIdToDiffusionToElement,
  jobIdToFullJobInGroup,
  jobsInGroup,
) => {
  const neighboursPayload = {
    jobId: params.id,
    jobsInGroup: jobsInGroup,
    defaultDiffusionToGroup: params.defaultDiffusionToGroup || null,
    groupIdToDefaultDiffusionToElement: groupIdToDiffusionToElement || {},
    groups: params.groups ? params.groups || [] : [],
    jobIdToFullJobInGroup: jobIdToFullJobInGroup || {},
  };
  const neighboursUrl = baseUrl + '/cerejobsV2/deleteAndChangeJobDependencies';
  const neighboursData = (await axios.post(neighboursUrl, neighboursPayload)).data;
  return neighboursData;
};

class DeleteJobModal extends Component {
  render() {
    const { job, onSubmit, onCancel } = this.props;
    return (
      <Modal open={true}>
        <Modal.Content>
          <p>{`Are you sure you want to delete this job?`}</p>
          <div>
            <div>Name: {job.name}</div>
            <div>Id: {job.id}</div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <Button color='blue' onClick={() => onCancel()}>
              CANCEL
            </Button>
            <Button color='red' onClick={() => onSubmit(job.id)}>
              <Icon name='trash outline' /> DELETE
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

class JobCard extends Component {
  state = {
    editJobModalVisible: false,
    deleteJobModalVisible: false,
  };

  onSubmitEditJob = async (
    params,
    jobs,
    groupIdToDiffusionToGroup,
    groupIdToDiffusionToElement,
    fullJobsInGroup,
    collectionName,
  ) => {
    if (_.isUndefined(params)) {
      this.setState({ editJobModalVisible: false });
      return;
    }

    const { reloadJobs } = this.props;
    if (_.isEmpty(params.id)) {
      alert('id field should not be changed.');
      return;
    }
    if (hasEmptyPattern({ patterns: params.patterns })) {
      return alert('Contains empty pattern');
    }
    if (params.translations) {
      params.translations = ensureDefaultTranslation({ translations: params.translations });
    }

    const isGroup = params.isGroup;
    const jobsInGroup = isGroup ? (params.jobsInGroup || '').split(';') || [] : [];
    const jobIdToFullJobInGroup = {};
    _.each(jobsInGroup, (jobInGroup) => {
      jobIdToFullJobInGroup[jobInGroup] = { id: jobInGroup, groups: [{ id: params.id }] };
    });
    _.each(fullJobsInGroup, (fullJobInGroup) => {
      jobIdToFullJobInGroup[fullJobInGroup.id] = fullJobInGroup;
    });

    if (_.isUndefined(params.types) || _.isEmpty(params.types)) {
      alert('Types should not be empty.');
      return;
    }
    if (!(params.types || '').split(';').includes('job') && isGroup) {
      alert('Groups should be of type job.');
      return;
    }
    if (
      !(params.types || '').split(';').includes('job') &&
      (!_.isUndefined(params.groups) || _.isEmpty(params.groups))
    ) {
      alert('Groups should only contain items of type job.');
      return;
    }
    const data = await updateJob(params, isGroup, groupIdToDiffusionToGroup, jobIdToFullJobInGroup);
    if (data.error) {
      alert(data.error);
      return;
    }
    const neighboursData = await updateJobDependencies(
      params,
      isGroup,
      jobs,
      groupIdToDiffusionToElement,
      jobIdToFullJobInGroup,
      jobsInGroup,
    );
    if (neighboursData.error) {
      alert(neighboursData.error);
      return;
    }

    await reloadJobs();
    this.setState({ editJobModalVisible: false });
  };

  onClickDeleteJob = () => {
    this.setState({ deleteJobModalVisible: true });
  };

  onCancelDeleteJob = () => {
    this.setState({ deleteJobModalVisible: false });
  };

  onSubmitDeleteJob = async (jobId) => {
    const { reloadJobs } = this.props;
    const payload = {
      jobId,
    };
    const url = baseUrl + '/cerejobsV2/deleteJob';
    const { data } = await axios.post(url, payload);
    if (data.error) {
      alert(data.error);
      return;
    }
    await reloadJobs();
    this.setState({ deleteJobModalVisible: false });
  };

  onEditJob = () => {
    this.setState({ editJobModalVisible: true });
  };

  onCancelEditJob = () => {
    this.setState({ editJobModalVisible: false });
  };

  render() {
    const { job, jobs, onUnsetSelectedJob, colorMap, tagIds, mainJobsCategoryIds, level2CategoryIds } = this.props;
    const { editJobModalVisible, deleteJobModalVisible } = this.state || {};
    const groups = job.groups || [];
    const isGroup = !_.isUndefined(job.group_configuration);
    const jobsInGroup = _.filter(jobs, (jobItem) => (jobItem.groups || []).some((item) => item.id === job.id));

    const strongDependenciesOrigin = job.strong_dependencies_origin || [];
    const mediumDependenciesOrigin = job.medium_dependencies_origin || [];
    const weakDependenciesOrigin = job.weak_dependencies_origin || [];

    const directDiffusionStrongDependencies = _.map(
      _.filter(strongDependenciesOrigin, (item) => item.origin === 'direct-diffusion'),
      (item) => item.id,
    );
    const directDiffusionMediumDependencies = _.map(
      _.filter(mediumDependenciesOrigin, (item) => item.origin === 'direct-diffusion'),
      (item) => item.id,
    );
    const directDiffusionWeakDependencies = _.map(
      _.filter(weakDependenciesOrigin, (item) => item.origin === 'direct-diffusion'),
      (item) => item.id,
    );

    const groupDiffusionStrongDependencies = _.map(
      _.filter(strongDependenciesOrigin, (item) => item.origin === 'group-diffusion'),
      (item) => item.id,
    );
    const groupDiffusionMediumDependencies = _.map(
      _.filter(mediumDependenciesOrigin, (item) => item.origin === 'group-diffusion'),
      (item) => item.id,
    );
    const groupDiffusionWeakDependencies = _.map(
      _.filter(weakDependenciesOrigin, (item) => item.origin === 'group-diffusion'),
      (item) => item.id,
    );

    return (
      <div>
        {deleteJobModalVisible ? (
          <DeleteJobModal job={job} onCancel={this.onCancelDeleteJob} onSubmit={this.onSubmitDeleteJob} />
        ) : null}
        {editJobModalVisible ? (
          <EditJobModal
            onSubmit={this.onSubmitEditJob}
            onCancel={this.onCancelEditJob}
            jobs={jobs}
            job={job}
            tagIds={tagIds}
            mainJobsCategoryIds={mainJobsCategoryIds}
            level2CategoryIds={level2CategoryIds}
          />
        ) : null}
        <Segment key={job.id}>
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Job job={job} />
                  <br />
                  <a style={{ cursor: 'pointer' }} onClick={() => this.onClickDeleteJob()}>
                    <b style={{ color: 'red' }}>Delete Job </b>
                  </a>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon color={'red'} name='close' onClick={() => onUnsetSelectedJob()} />
                  <br />
                  <br />
                  <br />
                  <a style={{ cursor: 'pointer' }} onClick={() => this.onEditJob()}>
                    <b style={{ color: 'green' }}>Edit Job </b>
                    <Icon name='pencil' color='green' />
                  </a>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <b>Description </b>
            <br />
            {job.description || ''}
            <br />
            <br />
            <div>
              <b>Default Translation :</b>
              {(job.translations || {}).default}
              <br />
              <b>English Translation :</b>
              {(job.translations || {}).en}
              <br />
              <b>French Translation:</b>
              {(job.translations || {}).fr}
              <br />
            </div>
            <br />
            <br />
            <div>
              <h4>In Reveal Column: </h4> {_.isUndefined(job.inRevealColumn) || job.inRevealColumn ? 'yes' : 'no'}
            </div>
            <h3>Implications</h3>
            <div>
              <b>Strong </b>
            </div>
            <br />
            <div>
              Direct diffusion{' '}
              {directDiffusionStrongDependencies.map((dependency) => {
                return (
                  <Label
                    key={dependency}
                    style={{
                      color: 'white',
                      backgroundColor: '#008000',
                    }}
                  >
                    {dependency}{' '}
                  </Label>
                );
              })}
            </div>
            <br />
            <div>
              Group diffusion{' '}
              {groupDiffusionStrongDependencies.map((dependency) => {
                return (
                  <Label
                    key={dependency}
                    style={{
                      color: 'white',
                      backgroundColor: '#008000',
                    }}
                  >
                    {dependency}{' '}
                  </Label>
                );
              })}
            </div>
            <br />
            <br />
            <div>
              <b>Medium </b>
            </div>
            <br />
            <div>
              Direct diffusion{' '}
              {directDiffusionMediumDependencies.map((dependency) => {
                return (
                  <Label
                    key={dependency}
                    style={{
                      color: 'white',
                      backgroundColor: '#3CB371',
                    }}
                  >
                    {dependency}{' '}
                  </Label>
                );
              })}
            </div>
            <br />
            <div>
              Group diffusion{' '}
              {groupDiffusionMediumDependencies.map((dependency) => {
                return (
                  <Label
                    key={dependency}
                    style={{
                      color: 'white',
                      backgroundColor: '#3CB371',
                    }}
                  >
                    {dependency}{' '}
                  </Label>
                );
              })}
            </div>
            <br />
            <br />
            <div>
              <b>Weak </b>
            </div>
            <br />
            <div>
              Direct diffusion{' '}
              {directDiffusionWeakDependencies.map((dependency) => {
                return (
                  <Label
                    key={dependency}
                    style={{
                      color: 'white',
                      backgroundColor: '#98FB98',
                    }}
                  >
                    {dependency}{' '}
                  </Label>
                );
              })}
            </div>
            <br />
            <div>
              Group diffusion{' '}
              {groupDiffusionWeakDependencies.map((dependency) => {
                return (
                  <Label
                    key={dependency}
                    style={{
                      color: 'white',
                      backgroundColor: '#98FB98',
                    }}
                  >
                    {dependency}{' '}
                  </Label>
                );
              })}
            </div>
            <br />
            <br />
            <h3>Groups</h3>
            <div>
              <b>Groups </b>{' '}
              {_.map(groups, (group) => {
                return (
                  <Label
                    key={group.id}
                    style={{
                      color: 'white',
                      backgroundColor: (colorMap || {})[group.id],
                    }}
                  >
                    {group.id}
                  </Label>
                );
              })}
            </div>
            {isGroup ? (
              <div>
                <br />
                <div>
                  <b>Jobs in group</b>
                  {_.map(jobsInGroup, (jobItem) => {
                    return <Label key={jobItem.id}>{jobItem.id}</Label>;
                  })}
                </div>
                <br />
                <div>
                  <b>Diffusion to group</b>
                  <Label key={job.group_configuration.default_diffusion_to_group || 'None'}>
                    {job.group_configuration.default_diffusion_to_group || 'None'}
                  </Label>
                </div>
                <br />
                <div>
                  <b>Diffusion to element</b>
                  <Label key={job.group_configuration.default_diffusion_to_element || 'None'}>
                    {job.group_configuration.default_diffusion_to_element || 'None'}
                  </Label>
                </div>
              </div>
            ) : null}
            <br />
            <h3>Patterns</h3>
            <List bulleted>
              {_.map(job.patterns, (p, i) => (
                <List.Item key={i}>{p.join(';')}</List.Item>
              ))}
            </List>
          </div>
        </Segment>
      </div>
    );
  }
}

export default JobCard;
