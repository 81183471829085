import React from 'react';
import axios from 'axios';
import { Select, Grid } from 'semantic-ui-react';

import baseUrl from '../baseUrl';
import Graph from './Graph';
import QueueGraph from './QueueGraph';

class OrderMonitoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      frame: '6h',
    };

    this.handleFrameChange = this.handleFrameChange.bind(this);
  }

  componentDidMount() {
    this.updateMetrics();
  }

  async updateMetrics() {
    const params = {
      '1h': 'rollup_minutes=1&nb_hours=1',
      '6h': 'rollup_minutes=5&nb_hours=6',
      '1d': 'rollup_minutes=30&nb_hours=24',
      '1w': 'rollup_minutes=180&nb_hours=168',
      '1m': 'rollup_minutes=720&nb_hours=720',
      max: 'rollup_minutes=1440',
    };

    Promise.all([
      axios.get(
        `${baseUrl}/sweetminer/metrics/order/${this.props.id}/orders.successful_tasks?${params[this.state.frame]}`,
      ),
      axios.get(`${baseUrl}/sweetminer/metrics/order/${this.props.id}/orders.failed_tasks?${params[this.state.frame]}`),
      axios.get(
        `${baseUrl}/sweetminer/metrics/order/${this.props.id}/queue.not_locked?rollup=last&${params[this.state.frame]}`,
      ),
    ]).then(([response, failedResponse, queueResponse]) => {
      this.setState({
        successfulData: response.data,
        failedData: failedResponse.data,
        queueData: queueResponse.data,
      });
    });
  }

  async handleFrameChange(e, data) {
    await this.setState({ frame: data.value });
    this.updateMetrics();
  }

  render() {
    return (
      <div>
        <Select
          options={[
            { key: '1h', value: '1h', text: '1 Hour' },
            { key: '6h', value: '6h', text: '6 Hours' },
            { key: '1d', value: '1d', text: '1 Day' },
            { key: '1w', value: '1w', text: '1 Week' },
            { key: '1m', value: '1m', text: '1 Month' },
            { key: 'max', value: 'max', text: 'Maximum' },
          ]}
          value={this.state.frame}
          onChange={this.handleFrameChange}
        />
        <Grid columns={2}>
          <Grid.Column>
            <Graph successData={this.state.successfulData} failureData={this.state.failedData} />
          </Grid.Column>
          <Grid.Column>
            <QueueGraph queueData={this.state.queueData} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default OrderMonitoring;
