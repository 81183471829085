import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, Icon, Popup, Input as SInput } from 'semantic-ui-react';
import { Select, SweetForm, enhance, Checkbox, Input, SelectInt, customOperator, List } from 'sweetform';
import { getSkillIds, getJobPositions } from '../../Offers/SearchModal';
import { loadLocations } from '../../Sales/SalesBoard/EditOmegaCriteria';
import moment from 'moment';


const SelectJobs = enhance(() => <Select field='jobIds' async loadOptions={getJobPositions} labelKey='name' valueKey='id' multi={true} />)
const SelectSkills = enhance(() => <Select field='skillIds' async loadOptions={getSkillIds} labelKey='name' valueKey='id' multi={true} />)
const SelectExperience = enhance(() => (
  <div>
    <SelectInt field='minExperience' placeholder='Min Experience' min={0} max={50} />
    <SelectInt field='maxExperience' placeholder='Max Experience' min={0} max={50} />
  </div>
))
const SelectLocations = enhance(() => <Select field='locationIds' loadOptions={loadLocations} labelKey='label' valueKey='value' async={true} multi={true} />)
const SelectFullRemote = enhance(() => <Select field='mode' options={[
  { label: "Only Full Remote", value: "only-full-remote" },
  { label: "Exclude Full Remote", value: "exclude-full-remote" },
  { label: "No filter", value: "no-filter" },
]} />)


const OFFER_FILTERS = {
  experience: { label: 'Experience', children: 1, component: SelectExperience },
  jobs: { label: 'Jobs', children: 1, component: SelectJobs },
  skills: { label: 'Skills', children: 1, component: SelectSkills },
  fullRemote: { label: 'Full Remote', children: 1, component: SelectFullRemote },
  locations: { label: 'Locations', children: 1, component: SelectLocations },
}

const OfferFilter = customOperator(OFFER_FILTERS, true)

const OfferRecencySelector = ({ onChange, initialValues }) => {
  return (
    <SweetForm onChange={onChange} initialValues={initialValues}>
      <Grid columns={5}>
        <Grid.Column>
          <Select options={[
            { label: "Publiées", value: "publication" },
            { label: "Créées", value: "creation" }
          ]} field="dateUsed" />
        </Grid.Column>
        <Grid.Column>
          depuis le
        </Grid.Column>
        <Grid.Column>
          <Input type='date' field='minDate' />
        </Grid.Column>
        <Grid.Column>
          exclure contactées après
        </Grid.Column>
        <Grid.Column>
          <Checkbox field="excludeContactedAfter" />
        </Grid.Column>
      </Grid>
    </SweetForm>
  )
}

const ProspectFiltersSelector = ({ onChange, initialValues }) => {
  return (
    <SweetForm onChange={onChange} initialValues={initialValues}>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            Exclure les entreprises traitées après le
            <Popup trigger={<Icon name="question" />} content={<div>Contact hubspot OU Mark as completed</div>} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Input type='date' field='maxTreatmentDate' />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            Exclure les entreprises skippées
          </Grid.Column>
          <Grid.Column width={8}>
            <Checkbox field="excludeSkippedProspects" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            Exclure les entreprises de recrutement 
            <Popup trigger={<Icon name="question" />} content={<div>Déterminé avec l'industry hubspot</div>} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Checkbox field="excludeRecruitingCompanies" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </SweetForm>
  )
}


const OfferRelevanceSimpleSelector = enhance((props) => (
  <Grid columns={2}>
    <Grid.Row>
      <Grid.Column>
        Jobs
      </Grid.Column>
      <Grid.Column>
        <SelectJobs field="jobs" />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        Skills
      </Grid.Column>
      <Grid.Column>
        <SelectSkills field="skills" />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        Location
      </Grid.Column>
      <Grid.Column>
        <SelectLocations field="locations" />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        Full Remote
      </Grid.Column>
      <Grid.Column>
        <SelectFullRemote field="fullRemote" />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        Experience
      </Grid.Column>
      <Grid.Column>
        <SelectExperience field="experience" />
      </Grid.Column>
    </Grid.Row>
  </Grid>
))

const OfferRelevanceAdvancedSelector = enhance((props) => (
  <Grid columns={3}>
    <Grid.Column>
      Target
      <List field="target" component={OfferFilter} />
    </Grid.Column>
    <Grid.Column>
      OkWith
      <List field="okWith" component={OfferFilter} />
    </Grid.Column>
    <Grid.Column>
      Low
      <List field="low" component={OfferFilter} />
    </Grid.Column>
  </Grid>
))

const OfferRelevanceSelector = ({ onChange, values, offerRelevanceOptions }) => {
  return (
    <SweetForm onChange={onChange} initialValues={values}>
      <Select
        options={[
          { label: "Predefined Mode", value: "predefined" },
          { label: "Simple Mode", value: "simple" },
          { label: "Advanced Mode", value: "advanced" }
        ]} field="mode"
      />
      {values.mode == "predefined" ? (
        <Select options={offerRelevanceOptions} field="predefinedId" labelKey="name" valueKey="id" />
      ) : values.mode == "simple" ? (
        <OfferRelevanceSimpleSelector field="simpleParams" />
      ) : values.mode == "advanced" ? (
        <OfferRelevanceAdvancedSelector field="advancedParams" />
      ) : null}
    </SweetForm>
  )
}

const formatPredefinedOfferRelevance = ({ predefinedId, offerRelevanceOptions }) => {
  return {
    mode: "predefined",
    predefinedId,
    ...(_.find(offerRelevanceOptions, ({ id }) => (id == predefinedId)) || {}).offerRelevance
  }

}

const formatSimpleOfferRelevance = ({ simpleParams }) => {
  const targetFilters = [];
  _.each(OFFER_FILTERS, (value, filterId) => {
    if ((simpleParams || {})[filterId]) {
      targetFilters.push({
        id: filterId,
        params: splitLists(simpleParams[filterId])
      })
    }
  })
  return { mode: "simple", target: { id: "#and", params: { sons: targetFilters } } };
}

const splitLists = (params) => {
  const splitListsFields = ["skillIds", "jobIds", "locationIds"];
  _.each(splitListsFields, (field) => {
    if (params[field]) {
      params[field] = params[field].split(';');
    }
  })
  return params;
}

const formatOfferFilter = (filterDict) => {
  const selection = filterDict || {};
  if (OFFER_FILTERS[selection]) {
    return {
      id: filterDict.selection,
      params: splitLists(filterDict[filterDict.selection])
    };
  }
  if (selection == "or") {
    const sons = _.map(filterDict.or, (sonFilterDict) => formatOfferFilter(sonFilterDict));
    return {
      id: "#or",
      params: { sons }
    };
  }
  if (selection == "and") {
    const sons = _.map(filterDict.and, (sonFilterDict) => formatOfferFilter(sonFilterDict));
    return {
      id: "#and",
      params: { sons }
    };
  }
  if (selection == "not") {
    const son = formatOfferFilter(filterDict.not)
    return {
      id: "#not",
      params: { son }
    };
  }
}

const formatAdvancedOfferRelevance = ({ advancedParams }) => {
  const targetFilters = _.map((advancedParams || {}).target, (filterDict) => formatOfferFilter(filterDict));
  const okWithFilters = _.map((advancedParams || {}).okWith, (filterDict) => formatOfferFilter(filterDict));
  const lowFilters = _.map((advancedParams || {}).low, (filterDict) => formatOfferFilter(filterDict));
  return {
    mode: "advanced",
    ...(targetFilters && { target: { id: "#and", params: { sons: targetFilters } } }),
    ...(okWithFilters && { okWith: { id: "#and", params: { sons: okWithFilters } } }),
    ...(lowFilters && { low: { id: "#and", params: { sons: lowFilters } } }),
  }

}
const formatOfferRelevance = ({ offerRelevance, offerRelevanceOptions }) => {
  if (offerRelevance.mode == "predefined") {
    return formatPredefinedOfferRelevance({ predefinedId: offerRelevance.predefinedId, offerRelevanceOptions });
  }
  if (offerRelevance.mode == "simple") {
    return formatSimpleOfferRelevance({ simpleParams: offerRelevance.simpleParams });
  }
  if (offerRelevance.mode == "advanced") {
    return formatAdvancedOfferRelevance({ advancedParams: offerRelevance.advancedParams });
  }
}

class ProspectParamsSelector extends React.Component {

  handleChange = async (update) => {
    this.props.handleChange({ ...this.props.prospectParams, ...update });
  };

  handleChangeOfferRecency = async (offerRecency) => {
    this.handleChange({ offerRecency });
  };

  handleChangeProspectFilters = async (prospectFilters) => {
    this.handleChange({ prospectFilters });
  }
  handleChangeOfferRelevance = async (value) => {
    console.log({ value })
    const offerRelevance = value || {};
    const formattedOfferRelevance = formatOfferRelevance({
      offerRelevance,
      offerRelevanceOptions: this.props.offerRelevanceOptions
    });
    this.handleChange({ offerRelevance: formattedOfferRelevance });
  }

  handleChangeOfferSources = async ({ offerSources }) => {
    this.handleChange({ offerSources: offerSources.split(';') });
  }

  render() {
    const { prospectParams } = this.props;
    const { offerRecency, offerRelevance, offerSources, prospectFilters } = prospectParams;
    return (
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={4}>
            Date des offres
          </Grid.Column>
          <Grid.Column width={12}>
            <OfferRecencySelector
              initialValues={offerRecency}
              onChange={this.handleChangeOfferRecency}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            Sources des offres
          </Grid.Column>
          <Grid.Column width={12}>
            <SweetForm
              initialValues={{ offerSources: offerSources.join(";") }}
              onChange={this.handleChangeOfferSources}
            >
              <Select
                multi
                options={[{ label: "Linkedin", value: "linkedin" }, { label: "WTTJ", value: "wttj" }, { label: "Indeed", value: "indeed" }]}
                field="offerSources"
              />
            </SweetForm>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            Caractéristiques des offres
          </Grid.Column>
          <Grid.Column width={12}>
            <OfferRelevanceSelector
              values={offerRelevance}
              onChange={this.handleChangeOfferRelevance}
              offerRelevanceOptions={this.props.offerRelevanceOptions}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            Entreprises
          </Grid.Column>
          <Grid.Column width={12}>
            <ProspectFiltersSelector
              initialValues={prospectFilters}
              onChange={this.handleChangeProspectFilters}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default ProspectParamsSelector;