import _ from 'underscore'; 
import React, { Component } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';


import { Grid, Button, Image, Modal, Header } from 'semantic-ui-react';

const colorFromLabel = ({ label }) => {
  if (!label) {
    return 'black';
  }
  return {
    'positive': 'green',
    'medium': 'orange',
    'negative': 'red'
  }[label] || 'gray'
}

class FocusModal extends Component {

  state = { currentIndex: 0 }
  
  constructor(props) {
    super(props);
    this.state.currentIndex = this.props.defaultIndex || 0;
  }

  handleNext = () => {
    this.setState({ 
      currentIndex: (this.state.currentIndex || 0) + 1
    })
  }

  handlePrevious = () => {
    this.setState({ 
      currentIndex: Math.max((this.state.currentIndex || 0) - 1, 0)
    })
  }

  render() {
    const { actions, onSetMessageLabel, onHardPostpone, onClose } = this.props;

    const currentIndex = this.state.currentIndex || 0;
    const currentAction = (actions || [])[currentIndex];

    console.log(currentAction);

    return (
      <Modal 
        open={true} 
        closeIcon 
        onClose={onClose}
      >
        <Modal.Header>
          Laser Focus
        </Modal.Header>
        <Modal.Content>
          {currentAction ? (
            <div style={{ height: 500, overflowY: 'scroll' }}>
              <KeyboardEventHandler 
                handleKeys={['space', 'backspace', 'q', 'p', 'm', 'n', 'b', 'v', 'y', 'r']}
                onKeyEvent={(key, e) => {
                  if (key === 'space') {
                    this.handleNext();
                  } else if (key === 'backspace') {
                    this.handlePrevious();
                  } else if (key === "q") {
                    onSetMessageLabel(currentAction, 'clearLabel');
                  } else if (key === "p") {
                    onSetMessageLabel(currentAction, 'positive');
                  } else if (key === "m") {
                    onSetMessageLabel(currentAction, 'medium');
                  } else if (key === "n") {
                    onSetMessageLabel(currentAction, 'negative');
                  } else if (key === "b") {
                    onSetMessageLabel(currentAction, 'bounce');
                  } else if (key === "v") {
                    onSetMessageLabel(currentAction, 'bug');
                  } else if (key === "y") {
                    onHardPostpone(currentAction.linkedin, 1);
                  } else if (key ==="r" ){
                    onHardPostpone(currentAction.linkedin, 2);
                  }
                }}
              />
              <h4
                style={{ color: colorFromLabel({ label: currentAction.label })}}
              >{currentAction.jobOfferId} ({currentAction.date}){' '}
                {currentAction.label && <span>({currentAction.label})</span>}
              </h4>
              <h5 style={{ marginTop: 2, marginBottom: 20 }}>
                {currentAction.profile ? (
                  <span>{currentAction.profile.firstName} {currentAction.profile.lastName}</span>
                ) : (
                  ''
                )}
              </h5>

              {_.isString(currentAction.message) ? (
                <div dangerouslySetInnerHTML={{ __html: currentAction.message }} />
              ) : _.isString(currentAction.message.snippet) ? (
                <div>{currentAction.message.snippet}</div>
              ) : null}
            </div>
          ) : (
            <span>No action selected</span>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button 
            disabled={currentIndex === 0}
            onClick={this.handlePrevious}
          >
            Previous
          </Button>
          <Button 
            disabled={currentIndex+1 >= actions.length}
            onClick={this.handleNext}
          >
            Next
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default FocusModal;
