import React, { Component } from 'react';
import {
  Segment,
  Tab
} from 'semantic-ui-react';
import { EnrichedLinkedinProfile } from '../RayonX/EnrichedLinkedinProfile';
import { GithubProfile } from '../Offers/WorkPipeInputComponents/GithubProfile';
import { StackOverflowProfile } from '../Offers/WorkPipeInputComponents/StackoverflowProfile';
import SweetappProfile from '../components/SweetappProfile';
import ClientProfile from '../components/ClientProfile';
import getProfileV4fromV5 from '../SweetWork/profileV5toV4.js';




class SourcesView extends Component {

  state = { activeIndex: 0 }
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

  render () {
    const {linkedinProfile, githubProfile, stackoverflowProfile, watchId, enrichedProfile} = this.props;
    const { activeIndex } = this.state;
    const panes = [
      ...(linkedinProfile ? 
        [{ 
          menuItem: 'Linkedin', 
          render: () => 
          <EnrichedLinkedinProfile
              source={{
                data: linkedinProfile.data,
                id: linkedinProfile.id
              }}
              noReverseXp={true}
          /> 
        }] : []),
      ...(watchId ? 
        [{ 
          menuItem: 'Watch', 
          render: () => 
            <Segment>
              <SweetappProfile profileId={watchId} />
            </Segment> 
        }] : []),
      ...(githubProfile ? 
        [{ 
          menuItem: 'Github', 
          render: () => 
            <GithubProfile
              source={githubProfile}
              more={{ "githubRepos": false }}
            />
        }] : []),
        ...(enrichedProfile ?
          [{ 
            menuItem: 'Platform', 
            render: () => 
            <ClientProfile
              profile={{data:getProfileV4fromV5(enrichedProfile)}}
            />
          }]
          : []),
      ...(stackoverflowProfile ? 
        [{ 
          menuItem: 'Stack Overflow', 
          render: () => 
            <StackOverflowProfile
              source={stackoverflowProfile}
              more={{}}
              globalHint={null}
              onChangeExperienceHint={() => {}}
              onMore={() => {}}
            />
        }] : [])
    ]
    let modifiedActiveIndex = activeIndex;
    if (panes.length <= modifiedActiveIndex){
      modifiedActiveIndex = 0;
    } 
    console.log('SourcesView enrichedProfile', enrichedProfile)

    return <Tab panes={panes} activeIndex={modifiedActiveIndex} onTabChange={this.handleTabChange} />
  }
}
   
export default SourcesView;