import moment from 'moment';

export const parseDate = (date) => {
  if (date.year) {
    return moment([date.year, date.month || 0]);
  }
  if (date.raw && date.raw.match(/\d{4}/)) {
    return moment([+date.raw]);
  }
  const parsed = date.raw && moment(date.raw);
  return parsed && parsed.isValid() ? parsed : null;
};

export const durationBetween = (startDate, endDate) => {
  if (startDate) {
    const end = endDate || moment();
    const diff = end.diff(startDate);
    if (diff < 30 * 24 * 60 * 60 * 1000) {
      return null;
    }
    const result = moment.duration(end.diff(startDate)).humanize();
    if (result === 'un an') {
      return '1 an';
    }
    if (result === 'a year' || result === 'one year') {
      return '1 year';
    }
    return result;
  }
  return null;
};

export const durationIsOverAYear = (startDate, endDate) => {
  if (startDate) {
    const end = endDate || moment();
    const diff = end.diff(startDate);
    if (diff < 365 * 24 * 60 * 60 * 1000) {
      return false;
    }
    return true;
  }
  return false;
};
