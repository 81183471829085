import React from 'react';
import _ from 'underscore';

import { Card, Header, Icon, Accordion, Segment } from 'semantic-ui-react';

import Check from './Check';

export default class Service extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.service && this.props.service.alertCount !== 0) {
      this.state = { active: true };
    } else {
      this.state = { active: false };
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({ active: !prevState.active }));
  }

  render() {
    if (!this.props.service) {
      return <div />;
    }

    const ok = this.props.service.alertCount === 0;

    return (
      <Segment color={ok ? 'green' : 'red'}>
        <Accordion.Title
          color={ok ? 'green' : 'red'}
          active={this.state.active}
          onClick={this.handleClick}
        >
          <Header as="h2">
            <Icon
              name={ok ? 'check circle' : 'warning circle'}
              color={ok ? 'green' : 'red'}
              size="large"
            />
            {this.props.service.name} {this.props.service.okCount} /{' '}
            {this.props.service.checks.length}{' '}
          </Header>
        </Accordion.Title>
        <Accordion.Content active={this.state.active}>
          <Card.Group>
            {_.map(this.props.service.checks, (check, index) => (
              <Check key={index} check={check} />
            ))}
          </Card.Group>
        </Accordion.Content>
      </Segment>
    );
  }
}
