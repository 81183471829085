import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Button } from 'semantic-ui-react';
import { Input, SweetForm } from 'sweetform';

import baseUrl from '../baseUrl.js';
import moment from 'moment';

// Components
class OffersDetectorDownloader extends Component {
  state = {};

  onChangeSearch = (search) => {
    this.setState({ search });
  };
  getSearchResult = async (search, mode) => {
    const { minDisplayDate, maxDisplayDate } = search || {};
    const query = {};
    if (minDisplayDate) {
      query.minDisplayTimestamp = 1000 * moment(minDisplayDate).unix();
    }
    if (minDisplayDate) {
      query.maxDisplayTimestamp = 1000 * moment(maxDisplayDate).unix();
    }
    const url = mode === 'offers' ? `${baseUrl}/offerDetector/displayedOffers` : `${baseUrl}/offerDetector/displayedClients`
    const result = (await axios.post(url, query)).data;
    return result;
  };
  onCount = async (mode) => {
    const { search } = this.state;
    const offers = await this.getSearchResult(search, mode);
    this.setState({ [mode]: offers.length });
  };
  onDownload = async (mode) => {
    const { search } = this.state;
    const searchResult = await this.getSearchResult(search, mode);

    // Convert to csv
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = _.uniq(_.flatten(_.map(searchResult, (result) => _.keys(result)))).sort();
    let csv = searchResult.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');

    // Download csv
    const { minDisplayDate, maxDisplayDate } = search || {};
    var csvData = new Blob([csv], { type: 'text/csv' });
    var csvUrl = URL.createObjectURL(csvData);
    var hiddenElement = document.createElement('a');
    const filename = `${mode}_${minDisplayDate || ''}_${maxDisplayDate || ''}.csv`;
    hiddenElement.href = csvUrl;
    hiddenElement.download = filename;
    hiddenElement.click();
  };
  render() {
    const { search, offers, clients } = this.state;
    return (
      <Grid centered>
        <Grid.Column width={3}>
          <SweetForm onChange={this.onChangeSearch} initialValues={this.props.search}>
            <label>
              <b>Displayed Between</b>
            </label>
            <Input type='date' field='minDisplayDate' />
            <label>
              <b>And</b>
            </label>
            <Input type='date' field='maxDisplayDate' />
          </SweetForm>

          <Button fluid color='teal' onClick={() => this.onCount("offers")}>
            Count offers : {_.isNumber(offers) ? offers : ''}
          </Button>
          <Button fluid color='orange' onClick={() => this.onDownload("offers")}>
            Download as CSV (offers)
          </Button>
          <Button fluid color='teal' onClick={() => this.onCount("clients")}>
            Count clients : {_.isNumber(clients) ? clients : ''}
          </Button>
          <Button fluid color='orange' onClick={() => this.onDownload("clients")}>
            Download as CSV (clients)
          </Button>
        </Grid.Column>
      </Grid>
    );
  }
}

export default OffersDetectorDownloader;
