import _ from 'underscore';
import React from 'react';
import { Modal, Grid, Table, Loader, Icon } from 'semantic-ui-react';
import GenericDashboard from './GenericDashboard';

const DashboardPreviewModal = ({ onClose, dashboard }) => (
  <Modal
    open={true}
    onClose={onClose}
    closeIcon
    size="fullscreen"
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      {(dashboard || {}).title} ({(dashboard || {}).id})
    </Modal.Header>
    <Modal.Content>
      <center>
        <GenericDashboard dashboard={dashboard} />
      </center>
    </Modal.Content>
  </Modal>
);

export default DashboardPreviewModal;
