import axios from 'axios';
import React from 'react';
import _ from 'underscore';
import baseUrl from '../../baseUrl.js';
import { Grid, Button, Icon, Table, Statistic, List, Modal } from 'semantic-ui-react';
import AddLocations from './AddLocations.js';

export default class MissingPatterns extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reloading: false, reparsing: false };
  }

  handleReload = async () => {
    this.setState({ reloading: true });
    await this.props.handleReload();
    this.setState({ reloading: false });
  };

  handleReparse = async () => {
    this.setState({ reparsing: true });
    await this.props.handleReparse();
    this.setState({ reparsing: false });
  };

  backlog = async (pattern) => {
    const response = await axios
      .post(`${baseUrl}/locationsV2/missingPatterns/backlog`, { pattern })
      .catch((error) => alert(error));
    if ((response.data || {}).ok) {
      alert('ok');
    }
    this.props.reloadData();
  };

  submitMapping = async (pattern, locationId) => {
    const response = await axios
      .post(`${baseUrl}/locationsV2/monoEntityUnambiguousMappings`, { patterns: [pattern], locationId })
      .catch((error) => alert(error));
    if ((response.data || {}).ok) {
      alert('ok');
    }
  };

  render() {
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column width={3}>
            <Button onClick={this.handleReload}>
              <Icon name={this.state.reloading ? 'wait' : 'check'} loading={this.state.reloading} />
              Reload data
            </Button>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button onClick={this.handleReparse}>
              <Icon name={this.state.reparsing ? 'wait' : 'check'} loading={this.state.reparsing} />
              Recompute parse
            </Button>
          </Grid.Column>
          {this.props.missingPatterns ? (
            <Grid.Column width={3}>
              {this.props.missingPatterns.loaded ? (
                <Button positive basic>
                  Loaded
                </Button>
              ) : (
                <Button negative basic>
                  Not loaded
                </Button>
              )}
            </Grid.Column>
          ) : null}
          {this.props.missingPatterns && this.props.missingPatterns.loaded ? (
            <Grid.Column width={3}>
              {(this.props.missingPatterns.parseInfo || {}).parsed ? (
                <Button positive basic>
                  Parsed
                </Button>
              ) : (
                <Button negative basic>
                  Not parsed
                </Button>
              )}
            </Grid.Column>
          ) : null}
        </Grid.Row>
        {this.props.missingPatterns &&
        this.props.missingPatterns.loaded &&
        (this.props.missingPatterns.parseInfo || {}).parsed ? (
          <Grid>
            <Grid.Row centered>
              <Statistic.Group>
                <Statistic>
                  <Statistic.Value>
                    {Math.floor(this.props.missingPatterns.parseInfo.exactMatchRateWithRepeat * 1000) / 10}%
                  </Statistic.Value>
                  <Statistic.Label>Exact matches rate</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>
                    {Math.floor(this.props.missingPatterns.parseInfo.exactMatchRateWithoutRepeat * 1000) / 10}%
                  </Statistic.Value>
                  <Statistic.Label>Exact matches rate (without repeat)</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>
                    {Math.floor(this.props.missingPatterns.parseInfo.parseRateWithRepeat * 1000) / 10}%
                  </Statistic.Value>
                  <Statistic.Label>Parse rate</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>
                    {Math.floor(this.props.missingPatterns.parseInfo.parseRateWithoutRepeat * 1000) / 10}%
                  </Statistic.Value>
                  <Statistic.Label>Parse rate (without repeat)</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Row>
            <Grid.Row centered>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>String</Table.Cell>
                    <Table.Cell>Occurrences</Table.Cell>
                    <Table.Cell>Proportion</Table.Cell>
                    <Table.Cell>Exact match</Table.Cell>
                    <Table.Cell>Parsed</Table.Cell>
                    <Table.Cell>Backlogged</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(this.props.missingPatterns.parseInfo.parses, (parse, parseIndex) => (
                    <Table.Row key={parseIndex}>
                      <Table.Cell>
                        <a href={`/monitoring/knowledge/location_mappings/${parse.string}`}>{parse.string}</a>
                        <Modal
                          trigger={
                            <Button basic>
                              <Icon name="search" />
                            </Button>
                          }
                          closeIcon
                          closeOnEscape={false}
                          closeOnDimmerClick={true}
                        >
                          <Modal.Header>Add Location</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              <AddLocations searchPattern={parse.string} />
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>
                      </Table.Cell>
                      <Table.Cell>{parse.occurrences}</Table.Cell>
                      <Table.Cell>{Math.floor(parse.proportion * 1000) / 10}%</Table.Cell>
                      <Table.Cell positive={parse.manuallyValidated} negative={!parse.manuallyValidated} />
                      <Table.Cell positive={parse.parsed} negative={!parse.parsed}>
                        <List>
                          {_.map(parse.matches, (match, index) => (
                            <List.Item key={index}>
                              [{match.begin}, {match.end}]
                              <List>
                                {_.map(match.weightedLocations, (weightedLocation, weightedLocationIndex) => (
                                  <List.Item key={weightedLocationIndex}>
                                    {weightedLocation.location.rawName} [proba={weightedLocation.proba}]{' '}
                                    {parse.manuallyValidated ? null : (
                                      <Button
                                        size="mini"
                                        icon
                                        onClick={() => this.submitMapping(parse.string, weightedLocation.location.id)}
                                      >
                                        <Icon name="send" size="small" />
                                      </Button>
                                    )}
                                  </List.Item>
                                ))}
                              </List>
                            </List.Item>
                          ))}
                        </List>
                      </Table.Cell>
                      <Table.Cell positive={parse.backlog} negative={!parse.backlog}>
                        <Button
                          positive={parse.backlog}
                          negative={!parse.backlog}
                          onClick={() => this.backlog(parse.string)}
                        >
                          {parse.backlog ? 'Unbacklog' : 'Backlog'}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Row>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}
