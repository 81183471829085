import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import { Checkbox, Divider, Dropdown, Form, Grid, Header, Icon, List, Popup, Segment } from 'semantic-ui-react';
import { SweetForm } from 'sweetform';
import { RankingABCDE } from './Ranking';
import GridClusters from './GridClusters';
import OfferThreeCriteria from '../components/OfferThreeCriteria';
import OfferCriteriaDiff from '../components/OfferCriteriaDiff';
import ChunkDisplayModalSelector from './ChunkDisplayModalSelector';
import MetricsSelector from './MetricsSelector';
import { getPercent } from './common.js';
import baseUrl from '../baseUrl.js';
import Modal from '../Modal';

class ChunkExecution extends React.Component {
  handleClickPrediction = async (prediction) => {
    const { chunk, challengeId, datasetId } = this.props;
    const { chunkDataPointId } = prediction;

    const url =
      `${baseUrl}/challenges/${challengeId}/datasets/${datasetId}/chunks/${chunk.id}` +
      `/dataPoint/${chunkDataPointId}`;

    const { data } = await axios.get(url);

    if (!data) {
      return alert('no data found\n' + url);
    }
    let target;
    if (challengeId == 'xg1' || challengeId == 'xg1poc') {
      target = `/ml/xg1poc?dataPointsSet=${data.dataPointsSetId}&dataPoint=${data.id}`;
    } else {
      target = `/ml/labelized-data/dataPointsSet/${data.dataPointsSetId}/dataPoint/${data.id}`;
    }
    window.open(target);
  };

  handleClickCriteria = () => {
    this.setState({ isOfferCriteriaDiffOpen: true });
  };

  onCancel = () => {
    this.setState({ isOfferCriteriaDiffOpen: false });
  };

  render() {
    const { chunk, score, name, predictions, criteria, displayMode, labelOrScoreColor } = this.props;
    const { isOfferCriteriaDiffOpen } = this.state || {};

    const sortedPredictions = _.sortBy(predictions, 'score').reverse();

    const percentScore = score && getPercent(score);
    const totalWidth = 550 * Math.ceil(sortedPredictions.length / 100);

    const oldCriteria1 = (criteria || {}).oldCriteria1;
    const oldCriteria2 = (criteria || {}).oldCriteria2;
    const newCriteria = (criteria || {}).newCriteria;

    return (
      <div>
        {isOfferCriteriaDiffOpen && !_.isUndefined(newCriteria) ? (
          <Modal
            active={true}
            headerText='Criteria Diff'
            submitText='OK'
            cancelText=''
            onSubmit={this.onCancel}
            onCancel={this.onCancel}
            size='fullscreen'
          >
            {_.isEmpty(oldCriteria2) ? (
              <OfferCriteriaDiff
                jobOfferId={chunk.name}
                oldCriteria={oldCriteria1}
                newCriteria={newCriteria}
                noOldCriteria={_.isEmpty(oldCriteria1)}
              />
            ) : _.isEmpty(oldCriteria1) ? (
              <OfferCriteriaDiff
                jobOfferId={chunk.name}
                oldCriteria={oldCriteria2}
                newCriteria={newCriteria}
                noOldCriteria={_.isEmpty(oldCriteria2)}
              />
            ) : (
              <OfferThreeCriteria
                jobOfferId={chunk.name}
                oldCriteria1={oldCriteria1}
                oldCriteria2={oldCriteria2}
                newCriteria={newCriteria}
              />
            )}
          </Modal>
        ) : null}
        <h3>
          {score && <span>[{percentScore}%]</span>} {name}{' '}
        </h3>
        {!_.isUndefined(newCriteria) ? (
          <a style={{ cursor: 'pointer' }} size='large' onClick={this.handleClickCriteria}>
            Compare Criteria
          </a>
        ) : null}{' '}
        {displayMode.type === 'grid' ? (
          <center>
            <GridClusters
              predictions={sortedPredictions}
              totalWidth={totalWidth}
              onClickPrediction={this.handleClickPrediction}
              labelClusters={displayMode.labelClusters}
              clusterFromLabel={displayMode.clusterFromLabel}
              scoreClusters={displayMode.scoreClusters}
              clusterFromScore={displayMode.clusterFromScore}
              colorFromPosition={displayMode.colorFromPosition}
            />
          </center>
        ) : (
          <RankingABCDE
            predictions={sortedPredictions}
            totalWidth={totalWidth}
            onClickPrediction={this.handleClickPrediction}
            categories={displayMode.categories}
            colorMap={displayMode.colorMap}
            labelOrScoreColor={labelOrScoreColor}
          />
        )}
      </div>
    );
  }
}

class Chunk extends React.Component {
  state = { collapsed: true };

  handleToggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const { challengeId, datasetId, chunk, displayMode, labelOrScoreColor } = this.props;
    const sortedExecutions = _.sortBy(chunk.executions || [], 'score').reverse();
    const bestExecution = !_.isEmpty(sortedExecutions) ? sortedExecutions[0] : null;

    const bestScore = bestExecution && getPercent(bestExecution.score);

    const optimizedScore = bestExecution && bestExecution.optimizedScore && getPercent(bestExecution.optimizedScore);

    const bestExecutionName = ((bestExecution || {}).submission || {}).name || 'unknown';

    return (
      <Segment style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
        <h2>
          <Icon
            link
            size='large'
            name={collapsed ? 'caret down' : 'caret up'}
            onClick={() => this.handleToggleCollapse()}
          />{' '}
          {bestScore && <span>[{bestScore}%</span>}
          {optimizedScore && <span style={{ color: 'green' }}> / {optimizedScore}%</span>}
          {bestScore && <span>] </span>}
          {
            <span style={{ color: 'grey' }}>
              {!_.isUndefined(chunk.types) && !_.isEmpty(chunk.types)
                ? '[' + chunk.types.map((item, i) => (i > 0 ? ' ' : '') + item) + '] '
                : null}
            </span>
          }
          <span>{chunk.name + ' '}</span>
          {bestExecutionName && <span style={{ color: 'orange' }}>({bestExecutionName}) </span>}{' '}
          {chunk.metaInfo ? (
            <Popup trigger={<Icon size='small' name='info circle' />} flowing hoverable>
              <Header as='h4'>{chunk.name}</Header>
              <List bulleted>
                {chunk.metaInfo.map((info) => {
                  return <List.Item key={info}>{info}</List.Item>;
                })}
              </List>
            </Popup>
          ) : null}{' '}
        </h2>

        {bestExecution && (
          <ChunkExecution
            challengeId={challengeId}
            datasetId={datasetId}
            chunk={chunk}
            predictions={bestExecution.predictions}
            criteria={bestExecution.criteria}
            displayMode={displayMode}
            labelOrScoreColor={labelOrScoreColor}
          />
        )}
        {!collapsed && (
          <div>
            <Divider />
            {_.map(sortedExecutions, (execution, index) => (
              <Segment key={index}>
                <ChunkExecution
                  challengeId={challengeId}
                  datasetId={datasetId}
                  chunk={chunk}
                  score={execution.score}
                  name={(execution.submission || {}).name || 'unknown'}
                  predictions={execution.predictions}
                  criteria={execution.criteria}
                  displayMode={displayMode}
                  labelOrScoreColor={labelOrScoreColor}
                />
              </Segment>
            ))}
          </div>
        )}
      </Segment>
    );
  }
}

class ChunkSubmissionsDashboard extends React.Component {
  state = {
    isDisplaySelectorOpen: false,
    displayMode: {},
  };

  handleOpenDisplaySelector = () => {
    this.setState({ isDisplaySelectorOpen: true });
  };

  handleCloseDisplaySelector = () => {
    this.setState({ isDisplaySelectorOpen: false });
  };

  handleChangeDisplayMode = ({ displayMode }) => {
    this.setState({ displayMode });
  };

  handleClickOnToggleColorMode = () => {
    const { toggleColor } = this.state || {};
    this.setState({ toggleColor: !(toggleColor || false) });
  };

  handleChangeTypesFilter = (e, params) => {
    this.setState({ typesFilter: params.value });
  };

  render() {
    const { challengeId, datasetId, chunks, submissions } = this.props;
    const { isDisplaySelectorOpen, displayMode, toggleColor, typesFilter } = this.state;

    const allTypes = _.map(_.filter(chunks, (chunk) => !_.isUndefined(chunk.types)), (item) => item.types).flat();
    const types = Array.from(new Set(allTypes));
    const typesOptions = _.map(types, (type) => ({
      value: type,
      text: type,
      key: type,
    }));

    const filteredChunks = _.isEmpty(typesFilter)
      ? chunks
      : _.filter(chunks, (chunk) => ((chunk || {}).types || []).some((item) => typesFilter.includes(item)));

    const executionsByChunk = {};
    _.each(submissions, (submission) => {
      _.each(submission.executions, (execution) => {
        if (!executionsByChunk[execution.chunkId]) {
          executionsByChunk[execution.chunkId] = [];
        }
        executionsByChunk[execution.chunkId].push({
          ...execution,
          submission,
        });
      });
    });

    const nbColumns = 2;
    const chunksRows = [[]];
    _.each(filteredChunks, (chunk) => {
      if (_.last(chunksRows).length === nbColumns) {
        chunksRows.push([]);
      }
      _.last(chunksRows).push({
        ...chunk,
        executions: executionsByChunk[chunk.id] || [],
      });
    });

    const bestScoreByChunkId = {};
    _.each(filteredChunks, (chunk) => {
      bestScoreByChunkId[chunk.id] = undefined;
      _.each(executionsByChunk[chunk.id], ({ score }) => {
        if (
          score !== undefined &&
          (bestScoreByChunkId[chunk.id] === undefined || score > bestScoreByChunkId[chunk.id])
        ) {
          bestScoreByChunkId[chunk.id] = score;
        }
      });
    });

    const bestOptimizedScoreByChunkId = {};
    _.each(filteredChunks, (chunk) => {
      bestOptimizedScoreByChunkId[chunk.id] = undefined;
      _.each(executionsByChunk[chunk.id], ({ optimizedScore }) => {
        if (
          optimizedScore !== undefined &&
          (bestOptimizedScoreByChunkId[chunk.id] === undefined ||
            optimizedScore > bestOptimizedScoreByChunkId[chunk.id])
        ) {
          bestOptimizedScoreByChunkId[chunk.id] = optimizedScore;
        }
      });
    });

    const meanScore = getPercent(
      _.reduce(bestScoreByChunkId, (memo, score) => memo + (score === undefined ? 0 : score), 0) /
        Math.max(1, filteredChunks.length),
    );

    const meanOptimizedScore =
      !_.isEmpty(bestOptimizedScoreByChunkId) &&
      getPercent(
        _.reduce(
          bestOptimizedScoreByChunkId,
          (memo, optimizedScore) => memo + (optimizedScore === undefined ? 0 : optimizedScore),
          0,
        ) / Math.max(1, filteredChunks.length),
      );

    const meanScoreAmongPositives = getPercent(
      _.reduce(bestScoreByChunkId, (memo, score) => memo + (score === undefined ? 0 : score), 0) /
        Math.max(1, _.filter(bestScoreByChunkId, (score) => score !== undefined).length),
    );

    const displayToggleColorMode = (displayMode || {}).type == 'grid' ? false : true;
    const labelOrScoreColor = toggleColor ? 'score' : 'label';

    return (
      <div style={{ padding: '20px' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <MetricsSelector
                metrics={this.props.metrics}
                onChangeMetricsSelection={this.props.onChangeMetricsSelection}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              {!_.isEmpty(typesOptions) ? (
                <div>
                  <h4>Chunk Types</h4>
                  <SweetForm>
                    <Form.Field>
                      <Dropdown
                        placeholder='Types'
                        fluid
                        multiple
                        selection
                        search
                        onChange={this.handleChangeTypesFilter}
                        options={typesOptions}
                      />
                    </Form.Field>
                  </SweetForm>
                </div>
              ) : null}
            </Grid.Column>
            <Grid.Column width={4}>
              <center>
                <h1 style={{ fontSize: 40 }}>
                  {meanScore} %{meanOptimizedScore && <span style={{ color: 'green' }}> - {meanOptimizedScore} %</span>}
                </h1>
                {meanScoreAmongPositives !== meanScore && (
                  <h2>({meanScoreAmongPositives} % on the evaluated chunks)</h2>
                )}
              </center>
              <br />
            </Grid.Column>
            <Grid.Column width={6} textAlign='right'>
              <a style={{ cursor: 'pointer' }} onClick={this.handleOpenDisplaySelector}>
                Edit display
              </a>
              <br />
              {displayToggleColorMode ? (
                <div>
                  <Checkbox toggle onClick={() => this.handleClickOnToggleColorMode()} /> Color Mode :{' '}
                  {toggleColor ? 'From score' : 'From label'}
                </div>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={nbColumns}>
            {_.map(_.range(nbColumns), (iColumn) => (
              <Grid.Column key={iColumn}>
                {_.map(chunksRows, (chunksRow, iRow) => (
                  <div key={iRow} style={{ paddingBottom: 10 }}>
                    {iColumn < chunksRow.length && (
                      <Chunk
                        challengeId={challengeId}
                        datasetId={datasetId}
                        chunk={chunksRow[iColumn]}
                        displayMode={displayMode}
                        labelOrScoreColor={labelOrScoreColor}
                      />
                    )}
                  </div>
                ))}
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
        {isDisplaySelectorOpen && (
          <ChunkDisplayModalSelector
            onClose={this.handleCloseDisplaySelector}
            onSubmit={this.handleChangeDisplayMode}
          />
        )}
      </div>
    );
  }
}

export default ChunkSubmissionsDashboard;
