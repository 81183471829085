import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import { Loader, Divider } from 'semantic-ui-react';
import GenericWidget from '../Widgets/GenericWidget';

const GenericDashboardPart = ({ layout, widgetById }) => {
  if (layout.type === 'void') {
    return (
      <div />
    );
  }
  if (layout.type === 'widget') {
    if (!widgetById[layout.widgetId]) {
      return (
        <div>
          <span>Error: {layout.widgetId} not found </span>
        </div>
      );
    }
    return (
      <GenericWidget widget={widgetById[layout.widgetId]} />
    );
  }
  if (layout.type === 'separator') {
    return (
      <Divider horizontal>{layout.title}</Divider>
    );
  }
  if (layout.type === 'rows') {
    return (
      <div>
        {_.map(layout.rows, (row, index) => (
          <div key={index}>
            <GenericDashboardPart layout={row} widgetById={widgetById} />
          </div>
        ))}
      </div>
    );
  }
  if (layout.type === 'columns') {
    return (
      <Grid>
        <Grid.Row>
          {_.map(layout.columns, (column, index) => (
            <Grid.Column 
              key={index} 
              {...(layout.widths && layout.widths[index] && { width: layout.widths[index] })}
            >
              <GenericDashboardPart layout={column} widgetById={widgetById} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    );    
  }
}

class GenericDashboard extends React.Component {
  state = {
    loadedWidgetById: undefined
  };

  handleLoadWidgets = async () => {
    try {
      const { widgets, widgetById } = this.props.dashboard || {};
      if (!widgetById && !_.isEmpty(widgets)) {
        const { data } = await axios.get(`${baseUrl}/stats/widgets`);
        if (data.error) {
          return alert(data.error);
        }
        const listWidgets = data;
        console.log('WIDG', data);
        const loadedWidgetById = {}
        _.each(listWidgets, (widget) => {
          if (widgets.indexOf(widget.id) >= 0) {
            loadedWidgetById[widget.id] = widget;
          }
        });
        this.setState({ loadedWidgetById });
      }
    } catch (e) {
      return alert(e.message);
    }
  }

  componentDidMount() {
    this.handleLoadWidgets()
  }

  render() {
    const { dashboard } = this.props;
    const { title, layout, widgetById } = dashboard;
    const { loadedWidgetById } = this.state;

    if (!widgetById && !loadedWidgetById) {
      return (
        <div>
          <br/><br/><br/>
          Loading... <Loader active inline />
          <br/><br/><br/>
        </div>
      );
    }

    return (
      <div>
        <GenericDashboardPart layout={layout} widgetById={widgetById || loadedWidgetById} />
      </div>
    );
  }
}

export default GenericDashboard;
