import React from 'react';
import { Segment, Header, Grid, Icon, Image } from 'semantic-ui-react';

import Copy from '../../components/Copy';
import { removeSpacesAndDash } from '../../common';
import { ExtLink } from '../../Offers/WorkPipeInputComponents/ExtLink';

export const Headline = ({ data, id, firstEducation, linkedinLocation }) => {
  const {
    photoLink,
    fullname,
    firstname,
    lastname,
    headline,
    summary,
    website,
    twitter,
  } = data || {};

  return (
    <div>
      <div
        style={{ position: 'absolute', right: '10px', top: '10px' }}
        className="sources-link-list"
      >
        <ExtLink to={`https://linkedin.com/in/${id}`}>
          <Icon name="linkedin" />
        </ExtLink>
        {website ? (
          <ExtLink to={website}>
            <Icon name="external" />
          </ExtLink>
        ) : null}
        {twitter ? (
          <ExtLink to={twitter}>
            <Icon name="twitter" />
          </ExtLink>
        ) : null}
      </div>
      <Header size="medium">
        <Grid>
          <Grid.Row>
            <Grid.Column width={3} style={{ paddingRight: 0, paddingLeft: 0 }}>
              <center>
                <Image
                  circular
                  src={photoLink}
                  style={{ fontSize: '3rem', width: '80px', height: '80px' }}
                />
              </center>
            </Grid.Column>
            <Grid.Column width={13}>
              <Header.Content>
                {firstname && lastname ? (
                  <span>
                    {`${firstname} ${lastname}`}
                    <Copy
                      text={`${removeSpacesAndDash(
                        firstname,
                      )} ${removeSpacesAndDash(lastname)}@gmail.com`}
                    />
                  </span>
                ) : fullname ? (
                  <span>
                    {fullname}
                    <Copy text={`${removeSpacesAndDash(fullname)}@gmail.com`} />
                  </span>
                ) : null}
                <Header.Subheader style={{ fontSize: '13px' }}>
                  {headline}
                  <br />
                  {firstEducation.schoolName} • {linkedinLocation}
                </Header.Subheader>
              </Header.Content>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
      {summary && <Segment secondary>{summary}</Segment>}
    </div>
  );
};
