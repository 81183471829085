import _ from 'underscore';
import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import moment from 'moment/moment';

export default class DashBoardSearchSelectionRateHistory extends Component {
  render() {
    const timeFormatter = (tick) => {
      return moment(new Date('20' + tick)).format('YY-MM-DD');
    };
    const data = _.sortBy(
      _.map(this.props.stats, (stat) => ({
        x: timeFormatter(stat.date),
        selected: stat.selected * 100,
        backlog: stat.backlog * 100,
        disqualified: 100 - stat.selected * 100 - stat.backlog * 100,
      })),
      'x',
    );
    console.log(data);
    const formattedData = _.map(data, (da) => timeFormatter(da.x));

    console.log(formattedData);

    return (
      <Segment>
        <Header as="h3" textAlign="center">
          Taux de Select/Backlog/Disqualify
        </Header>
        <AreaChart
          width={310}
          height={150}
          data={data}
          margin={{
            top: 0,
            right: 5,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            label={{ value: 'Date', position: 'insideBottom', dy: 5 }}
          />
          <YAxis
            domain={[0, 100]}
            label={{
              value: 'Taux (%)',
              angle: -90,
              position: 'insideLeft',
              dy: 20,
              dx: 20,
            }}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="selected"
            stackId="1"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
          <Area
            type="monotone"
            dataKey="backlog"
            stackId="1"
            stroke="#ffc658"
            fill="#ffc658"
          />
          <Area
            type="monotone"
            dataKey="disqualified"
            stackId="1"
            stroke="#ff7358"
            fill="#ff7358"
          />
        </AreaChart>
      </Segment>
    );
  }
}
