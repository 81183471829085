import _ from 'underscore';
import React from 'react';
import {
  Grid,
} from 'semantic-ui-react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

class ActivityGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      target: 'all',
      minNbSends: 0,
      itemType: 'clients-and-offers',
    };
  }

  handleSetTargetAll = () => {
    this.setState({ target: 'all' });
  };
  handleSetTargetLeadGen = () => {
    this.setState({ target: 'lead-gen' });
  };
  handleSetTargetWatch = () => {
    this.setState({ target: 'watch' });
  };
  handleSetMinNbSends = (value) => {
    this.setState({ minNbSends: value });
  };
  handleSetItemTypeAll = () => {
    this.setState({ itemType: 'clients-and-offers' });
  };
  handleSetItemTypeClients = () => {
    this.setState({ itemType: 'clients' });
  };
  handleSetItemTypeOffers = () => {
    this.setState({ itemType: 'offers' });
  };

  render() {
    const { offers, offerEvents, offerStats } = this.props;

    if (!offers || !offerEvents || !offerStats) {
      return <div />;
    }

    const slots = _.last(
      offerStats[this.props.splitMode].slots,
      this.props.nbSplits,
    );

    const clientIdFromOfferId = {};
    _.each(offers, ({ platformId, platformClientId }) => {
      clientIdFromOfferId[platformId] = platformClientId;
    });

    const offerFilter = (offerId) => {
      if (this.state.target === 'all') {
        return true;
      } else if (this.state.target === 'lead-gen') {
        return offerId.indexOf('hiresweet-watch') < 0;
      } else if (this.state.target === 'watch') {
        return offerId.indexOf('hiresweet-watch') >= 0;
      }
    };

    const data = _.map(slots, ({ beginDay, endDay, title }) => {
      const offerStatsFromId = {};
      const clientStatsFromId = {};

      _.each(offerEvents, ({ itemId, type, count, day, salesOwner }) => {
        if (['sends', 'skips'].indexOf(type) < 0) {
          return;
        }
        if (day < beginDay || day >= endDay) {
          return;
        }
        if (!offerFilter(itemId)) {
          return;
        }
        if (this.props.targetUser && this.props.targetUser !== salesOwner) {
          return;
        }

        // offer count
        if (!offerStatsFromId[itemId]) {
          offerStatsFromId[itemId] = {
            sendsCount: 0,
            actionsCount: 0,
          };
        }
        offerStatsFromId[itemId].actionsCount += count || 0;
        if (type === 'sends') {
          offerStatsFromId[itemId].sendsCount += count || 0;
        }

        // client count
        const clientId = clientIdFromOfferId[itemId];
        if (clientId) {
          if (!clientStatsFromId[clientId]) {
            clientStatsFromId[clientId] = {
              sendsCount: 0,
              actionsCount: 0,
            };
          }
          clientStatsFromId[clientId].actionsCount += count || 0;
          if (type === 'sends') {
            clientStatsFromId[clientId].sendsCount += count || 0;
          }
        }
      });

      const result = { name: title, clients: 0, offers: 0 };
      const { minNbSends } = this.state;
      // offers count
      _.each(offerStatsFromId, ({ actionsCount, sendsCount }) => {
        if (actionsCount >= 1 && sendsCount >= minNbSends) {
          result['offers'] = 1 + (result['offers'] || 0);
        }
      });
      // clients count
      _.each(clientStatsFromId, ({ actionsCount, sendsCount }) => {
        if (actionsCount >= 1 && sendsCount >= minNbSends) {
          result['clients'] = 1 + (result['clients'] || 0);
        }
      });

      return {
        ...result,
        'offers ':  Math.max(result['offers'] - result['clients'], 0),
        nbOffersByClient: Math.round(100 * result['offers'] / Math.max(result['clients'], 1)) / 100,
      };
    });

    const style = (key) => {
      const selected =
        this.state.target === key ||
        this.state.itemType === key ||
        this.state.minNbSends === key;
      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    const canStack =
      this.state.itemType === 'clients-and-offers' &&
      _.all(data, ({ offers, clients }) => offers >= clients);

    return (
      <Grid><Grid.Row>
        <Grid.Column width={12}>
          <ComposedChart
            data={data}
            width={450} height={200} 
            margin={{ left: -10 }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis yAxisId="left" />
            {this.state.itemType === 'clients-and-offers' && (
              <YAxis yAxisId="right" orientation="right" />
            )}
            <Tooltip formatter={(value, name, props) => {
              if (name === 'offers ') {
                return value + ((props.payload || {}).clients || 0);
              }
              return value;
            }}/>
            {this.state.itemType !== 'offers' && (
              <Bar yAxisId='left' dataKey={'clients'} stackId='1' fill="#2ECC71"/>
            )}
            {canStack && (
              <Bar yAxisId='left' dataKey={'offers '} stackId='1' fill="#F1C40F"/>
            )}
            {!canStack && this.state.itemType !== 'clients' && (
              <Bar yAxisId='left' dataKey={'offers'} stackId='2' fill="#F1C40F"/>
            )}
            {this.state.itemType === 'clients-and-offers' && (
              <Line yAxisId='right' dataKey={'nbOffersByClient'} stroke="blue"/>
            )}
          </ComposedChart>
        </Grid.Column>
        <Grid.Column width={4}>
          <h3>{this.props.title}</h3>
          <br/>
          <div>
            <span style={style('all')} onClick={this.handleSetTargetAll}>All</span><span> | </span>
            <span style={style('lead-gen')} onClick={this.handleSetTargetLeadGen}>Lead Gen</span><span> | </span>
            <span style={style('watch')} onClick={this.handleSetTargetWatch}>Watch</span>
          </div>
          <br/>
          <div>
            <span style={style('clients-and-offers')} onClick={this.handleSetItemTypeAll}>All</span><span> | </span>
            <span style={style('clients')} onClick={this.handleSetItemTypeClients}>Clients</span><span> | </span>
            <span style={style('offers')} onClick={this.handleSetItemTypeOffers}>Offers</span>
          </div>
          <br/>
          <div>
            <span style={style(0)} onClick={() => this.handleSetMinNbSends(0)}>0</span>
            <span> | </span>
            <span style={style(1)} onClick={() => this.handleSetMinNbSends(1)}>1</span>
            <span> | </span>
            <span style={style(2)} onClick={() => this.handleSetMinNbSends(2)}>2</span>
            <span> | </span>
            <span style={style(5)} onClick={() => this.handleSetMinNbSends(5)}>5</span>
            <span> | </span>
            <span style={style(10)} onClick={() => this.handleSetMinNbSends(10)}>10</span>
            <span> | </span>
            <span style={style(20)} onClick={() => this.handleSetMinNbSends(20)}>20</span>
            <span> | </span>
            <span style={style(50)} onClick={() => this.handleSetMinNbSends(50)}>50</span>
            <span> | </span>
            <span style={style(100)} onClick={() => this.handleSetMinNbSends(100)}>100</span>
          </div>
          <br/>
        </Grid.Column>
      </Grid.Row></Grid>
    );
  }
}

export default ActivityGraph;
