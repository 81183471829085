function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import _ from 'underscore';
import React from 'react';
import { Checkbox as UCheckbox } from 'semantic-ui-react';
import enhance from './enhance';
var Checkbox = enhance(function (_ref) {
  var value = _ref.value,
      setValue = _ref.setValue,
      field = _ref.field,
      props = _objectWithoutProperties(_ref, ['value', 'setValue', 'field']);

  return React.createElement(UCheckbox, Object.assign({
    checked: value,
    onChange: function onChange(event) {
      return setValue(!value);
    }
  }, props));
});
export default Checkbox;