import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import { Icon, List, Grid, Segment, Button, Form, Modal, Table } from 'semantic-ui-react';
import CustomModal from '../Modal';

import { SweetForm, Select, Input } from 'sweetform';
import { Textarea } from '../common';

import axios from 'axios';
import GBQCollectionView from './GBQCollectionView';
import MongoCollectionView from './MongoCollectionView';

const keyTypeOptions = [
  {
    label: 'Searchable Profiles',
    value: 'searchable-profiles',
  },
  {
    label: 'Raw Profiles',
    value: 'raw-profiles',
  },
  {
    label: 'Jobs',
    value: 'jobs',
  },
  {
    label: 'Workplaces',
    value: 'workplaces',
  },
  {
    label: 'Profile Id Fields Tasks',
    value: 'profile-id-fields-tasks',
  },
];

const databaseTypeOptions = [
  {
    label: 'Mongo',
    value: 'mongo',
  },
  {
    label: 'Big Query',
    value: 'bigquery',
  },
];

const mongoHostOptions = [
  {
    label: 'AWS',
    value: 'AWS',
  },
  {
    label: 'GCP',
    value: 'GCP',
  },
];

const contextVersionOptions = _.map(_.range(7, 20), (i, index) => ({
  label: i,
  value: i,
}));

const mongoFields = ['mongoHost', 'database', 'collection'];
const bigqueryFields = ['projectId', 'datasetId', 'tableId'];

class AddSetModal extends Component {
  async handleChange(params) {
    const databaseType =
      params.keyType == 'searchable-profiles' || params.keyType == 'raw-profiles' ? 'bigquery' : params.databaseType;
    this.setState({
      ...params,
      databaseType,
    });
  }

  async handleSubmit() {
    const { onSubmit } = this.props;
    this.setState({ waitingForResponse: true });
    onSubmit(this.state);
    this.setState({ waitingForResponse: false });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const { databaseType, keyType, waitingForResponse } = this.state || {};
    const fields = databaseType == 'mongo' ? mongoFields : databaseType == 'bigquery' ? bigqueryFields : [];
    return (
      <Modal open closeIcon open={true} headerText={`Create Set`} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm
            initialValues={{ projectId: 'trim-cistern-262208', datasetId: 'pools' }}
            onChange={(params) => this.handleChange(params)}
          >
            <Form.Field>
              <label>Id</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='id'
                label='Raw Query Editor'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Key Type</label>
              <Select
                fluid
                field='keyType'
                label='Key Type'
                options={keyTypeOptions}
                placeholder='searchable-profiles'
              />
            </Form.Field>
            {keyType == 'searchable-profiles' ? (
              <Form.Field>
                <label>Version</label>
                <Select
                  fluid
                  field='contextVersion'
                  label='Context Version'
                  options={contextVersionOptions}
                  placeholder='10'
                />
              </Form.Field>
            ) : null}
            {keyType == 'searchable-profiles' || keyType == 'raw-profiles' ? null : (
              <Form.Field>
                <label>Database Type</label>
                <Select
                  fluid
                  field='databaseType'
                  label='Database Type'
                  options={databaseTypeOptions}
                  placeholder='mongo'
                />
              </Form.Field>
            )}
            <div>
              {_.map(fields, (field, index) => (
                <Form.Field key={index}>
                  <label>{field}</label>
                  {field == 'mongoHost' ? (
                    <Select
                      fluid
                      field='mongoHost'
                      label='Database Type'
                      options={mongoHostOptions}
                      placeholder='AWS'
                    />
                  ) : (
                    <Textarea
                      style={{
                        width: '100%',
                        resize: 'none',
                        height: '35px',
                        overflow: 'hidden',
                        display: 'table-cell',
                      }}
                      field={field}
                      label='Raw Query Editor'
                      placeholder=''
                    />
                  )}
                </Form.Field>
              ))}
            </div>
          </SweetForm>
        </Modal.Content>
        {waitingForResponse ? (
          'Creating tables...'
        ) : (
          <Modal.Actions>
            <Button negative onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button positive onClick={() => this.handleSubmit()}>
              Submit
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

const cleanId = (rawId) => rawId.replaceAll(' ', '-').toLowerCase();

class SetManager extends Component {
  componentDidMount() {
    this.handleLoadSets();
    this.handleFunctionChange = this.handleFunctionChange.bind(this);
  }

  async getSets() {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getSets';
    const data = (await axios.get(url, customHeaders)).data;
    return data || [];
  }

  async handleFunctionChange(e, { value }) {
    const { sets } = this.state || {};
    const set = _.find(sets, (set, index) => set.id === value);
    this.handleClickOnSet(set);
  }

  handleLoadSets = async () => {
    const sets = await this.getSets();
    this.setState({ sets });
  };

  handleClickOnSet = async (selectedSet) => {
    this.setState({ selectedSet });
  };

  handleClickOnCreateSet = () => {
    this.setState({ addSetModalVisible: true });
  };

  onCancel = () => {
    this.setState({ addSetModalVisible: false, onDeleteModalVisible: false });
  };

  handleClickOnDelete = (setId) => {
    this.setState({ onDeleteModalVisible: true, setId: setId });
  };

  onDeleteConfirm = async (set) => {
    const payload = {
      setId: set.id,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/deleteSet';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ onDeleteModalVisible: false });
    this.handleLoadSets();
  };

  onSubmit = async (params) => {
    const definitionParams =
      params.databaseType == 'bigquery'
        ? { projectId: params.projectId, datasetId: params.datasetId, tableId: params.tableId }
        : { collection: params.collection, database: params.database, mongoHost: params.mongoHost };

    const payload = {
      id: cleanId(params.id),
      metaType: params.keyType,
      definition: {
        type: params.databaseType,
        params: definitionParams,
      },
      params: params.keyType == 'searchable-profiles' ? { contextVersion: params.contextVersion } : {},
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/createSet';
    const result = (await axios.post(url, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ addSetModalVisible: false });
    this.handleLoadSets();
    return;
  };

  renderCell = (name, key, set) => {
    const { selectedSet } = this.state || {};
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Meta type' ? (
          <span> {set.metaType} </span>
        ) : name == 'Id' ? (
          <span>{set.id}</span>
        ) : name == 'Select set' && set.id === (selectedSet || {}).id ? (
          <Icon color={'green'} name='check square outline' />
        ) : name == 'Select set' && set.id !== (selectedSet || {}).id ? (
          <Icon color={'green'} name='square outline' onClick={() => this.handleClickOnSet(set)} />
        ) : name == 'Type' ? (
          <span>{(set.definition || {}).type}</span>
        ) : name == 'Delete Set' ? (
          <Icon color={'red'} name='close' onClick={() => this.handleClickOnDelete(set)} />
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const { sets, selectedSet, addSetModalVisible, onDeleteModalVisible, setId } = this.state || {};
    const columnNames = ['Id', 'Meta type', 'Type', 'Select set', 'Delete Set'];
    return (
      <div>
        {onDeleteModalVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Set ${setId}`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={() => this.onDeleteConfirm(setId)}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </CustomModal>
        ) : null}

        {addSetModalVisible ? <AddSetModal onSubmit={this.onSubmit} onCancel={this.onCancel} /> : null}
        <Grid rows={2}>
          <Grid.Row>
            {sets ? (
              <Grid.Row
                style={{
                  display: 'inline-block',
                  maxHeight: '200px',
                  width: '90%',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                }}
              >
                <Table>
                  <Table.Header>
                    <Table.Row>
                      {columnNames.map((name, index) => {
                        return (
                          <Table.HeaderCell key={index} textAlign='center'>
                            {name}
                          </Table.HeaderCell>
                        );
                      })}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {sets.map((set, index) => {
                      return (
                        <Table.Row key={index}>
                          {columnNames.map((name, index2) => {
                            return this.renderCell(name, index + index2, set);
                          })}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Grid.Row>
            ) : null}
          </Grid.Row>
          <Grid.Row>
            <a style={{ cursor: 'pointer' }} onClick={() => this.handleClickOnCreateSet()}>
              <Icon name='add' size='small' color='green' /> <b style={{ color: 'green' }}> Create Set</b>
            </a>
          </Grid.Row>
          <Grid.Row>
            {selectedSet ? (
              <Grid.Row
                style={{
                  display: 'inline-block',
                  maxHeight: '200px',
                  width: '90%',
                }}
              >
                <Segment height='100%' width='100%'>
                  {(selectedSet.definition || {}).type == 'bigquery' ? (
                    <GBQCollectionView
                      key={selectedSet.id + '1'}
                      data={{
                        ...selectedSet.definition.params,
                        table: selectedSet.definition.params.tableId,
                        keyType: selectedSet.metaType,
                        setId: selectedSet.id,
                      }}
                    />
                  ) : (
                    <MongoCollectionView
                      key={selectedSet.id + '2'}
                      data={{ ...selectedSet.definition.params, keyType: selectedSet.metaType }}
                    />
                  )}
                </Segment>
              </Grid.Row>
            ) : null}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default SetManager;
