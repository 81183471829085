import _ from 'underscore';
import React from 'react';
import { Label } from 'semantic-ui-react';

const TagsView = ({ tags }) => (
  <Label.Group>
    {_.map(tags, (tag, i) =>
      _.isArray(tag) ? (
        tag[1] ? (
          <Label key={i}>
            {tag[0]}
            <Label.Detail>{tag[1]}</Label.Detail>
          </Label>
        ) : null
      ) : tag ? (
        <Label key={i}>{tag}</Label>
      ) : null,
    )}
  </Label.Group>
);
export default TagsView;
