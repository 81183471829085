import _ from 'underscore';
import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import EducationFields from './EducationFields';
import SchoolTypes from './SchoolTypes';
import WorkplaceGroups from '../WorkplacesManagement/WorkplacesGroups';
import WorkplacesManagement from '../WorkplacesManagement';
import EducationSandbox from './EducationSandbox';

class EducationMonitoring extends Component {
  render() {
    const panes = [
      {
        menuItem: 'Presentation',
        render: () => <Tab.Pane attached={false}>Presentation</Tab.Pane>,
      },
      {
        menuItem: 'Education Sandbox',
        render: () => <Tab.Pane attached={false}><EducationSandbox /></Tab.Pane>,
      },
      {
        menuItem: 'Schools',
        render: () => <Tab.Pane attached={false}><WorkplacesManagement type='school'/></Tab.Pane>,
      },
      {
        menuItem: 'Schools Groups',
        render: () => <Tab.Pane attached={false}><WorkplaceGroups type='school' /></Tab.Pane>,
      },
      {
        menuItem: 'Education Fields',
        render: () => <Tab.Pane attached={false}><EducationFields /></Tab.Pane>,
      },
      {
        menuItem: 'School Types',
        render: () => <Tab.Pane attached={false}><SchoolTypes /></Tab.Pane>,
      }
    ];
    return <Tab menu={{ secondary: true }} panes={panes} />;
  }
}

export default EducationMonitoring;
