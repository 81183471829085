import _ from 'underscore';

const fields = [
  'fullname',
  'headline',
  'linkedin',
  'stackOverflow',
  'github',
  'twitter',
  'website',
  'viadeo',
  'coderWall',
  'codePen',
  'bitBucket',
  'reddit',
  'angelList',
  'meetup',
  'googlePlus',
  'dribbble',
  'behance',
  'pinterest',
  'drupal',
  'joomla',
  'aboutMe',
  'medium',
  'quora',
  'speakerDeck',
];

export default _.map(fields, (f) => ({ value: f, label: f }));
