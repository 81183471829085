import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Table, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class SelectionStats extends Component {
  state = {};
  componentWillMount() {
    this.handleLoad();
  }
  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/strat/selection/stats`);
    this.setState(data);
  };
  renderTable(category) {
    const { byDays } = this.state;
    const groupingFunc = (day) => day.slice(0, 5);
    let groups = [];
    _.each(byDays, (dayStats) => {
      const day = dayStats.day;
      const groupId = groupingFunc(day);
      if (_.isEmpty(groups) || _.last(groups).id !== groupId) {
        const newGroup = {
          id: groupId,
          all: 0,
          select: 0,
          backlog: 0,
          disqualified: 0,
        };
        groups.push(newGroup);
      }
      const curStats = dayStats[category] || {};
      _.last(groups).all += curStats.all || 0;
      _.last(groups).select += curStats.select || 0;
      _.last(groups).backlog += curStats.backlog || 0;
      _.last(groups).disqualified += curStats.disqualified || 0;
    });
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{category}</Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              {_.map(groups, ({ id }) => (
                <Table.HeaderCell>{id}</Table.HeaderCell>
              ))}
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>All</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'all_' + index}>{group.all}</Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Select</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'select_' + index}>
                    {group.select} (
                    {Math.round((group.select / Math.max(1, group.all)) * 100)}
                    %)
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Backlog</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'backlog_' + index}>
                    {group.backlog}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Disqualified</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'disqualified_' + index}>
                    {group.disqualified}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    return (
      <div>
        {this.renderTable('total')}
        <br />
        <br />
        {_.map(this.state.categories, (category, index) => (
          <div key={index}>
            {this.renderTable(category)}
            <br />
          </div>
        ))}
      </div>
    );
  }
}

export default SelectionStats;
