import React from 'react';
import i18n from 'i18next';

const getTranslatedText = (translatableText) => {
  const { language } = i18n;
  const languageWithoutCountry = language ? language.split('-')[0] : null;
  const text = translatableText || {};
  return text[languageWithoutCountry] || text.default || '';
};

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

const replaceWildcardsWithBoldHTMLText = (content) => {
  const wordBetweenWildcardsRegex = /\*\*[^*]+\*\*/g;
  let currentBoldWord;
  do {
    currentBoldWord = wordBetweenWildcardsRegex.exec(content);
    if (currentBoldWord) {
      const stringToBold = currentBoldWord[0];
      content = content.replace(stringToBold, `<span style="font-family: sourceSansProSemiBold;">${stringToBold.substr(2, stringToBold.length - 4)}</span>`);
    }
  } while (currentBoldWord);
  if (!content) {
    return null;
  }
  return content;
};

const TranslatableText = ({ text, useBold }) => {
  let content = getTranslatedText(text);
  if (useBold) {
    content = replaceWildcardsWithBoldHTMLText(content);
  }
  return <span dangerouslySetInnerHTML={getRawMarkup(content)} />;
};

export default TranslatableText;
