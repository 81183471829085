import React, { Component } from 'react';
import EditOfferModal from '../../../Sales/SalesBoard/EditOfferModal'; // not used as modal

/*
  props: {
    initialValue,
    onUpdate({ data })
  }
*/
class OfferEditor extends Component {
  handleUpdate = ({ data }) => {
    this.props.onUpdate({ data });
  };

  render() {
    const { initialValue } = this.props;

    return (
      <div>
        <h1>Offer Editor LL</h1>
        <EditOfferModal
          initialValues={initialValue}
          onUpdate={this.handleUpdate}
          onClose={() => {}}
          onSubmit={() => {}}
          onDelete={undefined}
          onOpenPushCriteria={undefined}
          noModal={true}
        />
      </div>
    );
  }
}

export default OfferEditor;
