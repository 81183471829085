import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import { Button, Popup, Label, Icon } from 'semantic-ui-react';
import EnrichedText from '../components/EnrichedText';
import TextInput from '../components/TextInput';
import { renderDates } from '../utils';
import { Stack } from '../../components/Stack';
import ScrappedTextWrapper from '../components/ScrappedTextWrapper';

const MAX_EDU = 2;

export class Education extends React.Component {
  state = {
    shouldDisplayDescription: false,
  };

  toggleDisplayDescription = () => {
    this.setState({ shouldDisplayDescription: !this.state.shouldDisplayDescription });
  };
  render() {
    const { degree, description, schoolName, startDate, endDate, workplace, stack } = this.props;
    const workplacePrestige = workplace
      .getData()
      .getPrestige()
      .get();
    const rawStack = stack.getRawValue();
    return (
      <div style={{ padding: '11px', position: 'relative' }} className='education-container'>
        <div style={{ opacity: 0.5, position: 'absolute', top: 0, right: -10 }}>
          <Icon
            link
            color='red'
            name='remove'
            onClick={() => {
              this.props.onRemove();
            }}
          />
        </div>
        {description.getText().get() && (
          <div style={{ position: 'absolute', top: 13, right: -10 }}>
            <Icon
              name={`caret ${this.state.shouldDisplayDescription ? 'up' : 'down'}`}
              onClick={this.toggleDisplayDescription}
            />
          </div>
        )}
        <div className='stack-container' style={{ marginTop: '11px' }}>
          <Stack stack={rawStack} />
        </div>
        <div style={{ fontSize: '14px', fontFamily: 'SourceSansProSemiBold' }}>
          <TextInput
            placeholder='SchoolName'
            value={schoolName.get() || ''}
            onChange={({ value }) => schoolName.set(value)}
            style={{ minWidth: '50%' }}
          />

          {workplacePrestige !== undefined && (
            <Popup
              trigger={
                <Label style={{ marginLeft: '5px' }} color={workplacePrestige > 3 ? 'green' : 'orange'}>
                  {workplacePrestige}
                </Label>
              }
              content='Workplace Prestige'
            />
          )}
        </div>
        <div style={{ fontSize: '11px' }}>
          <span style={{ opacity: 0.5 }}>
            <EnrichedText text={degree.getText().get()} enrichments={degree.getEnrichments()} />
            {degree.getText().get() && startDate.getRaw().get() && ' • '}
            {renderDates({ startDate, endDate })}
          </span>
          <span
            style={{
              marginLeft: '5px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            {getDurationSinceEducationEnd({ endDate })}
          </span>
        </div>
        {this.state.shouldDisplayDescription && (
          <ScrappedTextWrapper text={description.getText().get()}>
            <EnrichedText text={description.getText().get()} enrichments={description.getEnrichments()} />
          </ScrappedTextWrapper>
        )}
      </div>
    );
  }
}

export const getDurationSinceEducationEnd = ({ endDate }) => {
  const currentMonth = new Date().getMonth();
  const eduEndMomentDate =
    endDate.getYear().get() &&
    moment([
      endDate.getYear().get(),
      _.isNumber(endDate.getMonth().get()) ? endDate.getMonth().get() : currentMonth,
      1,
    ]);
  return eduEndMomentDate && eduEndMomentDate.fromNow();
};

class ProfileEducations extends React.Component {
  state = { showMore: false };
  onClickShowMore = () => {
    this.setState({ showMore: true });
  };
  render() {
    const { education } = this.props;
    const { showMore } = this.state;
    const shouldDisplayMoreButton = education.length > MAX_EDU && !showMore;
    return (
      <div style={{ marginBottom: -10 }}>
        {_.map(showMore ? education.get() : _.first(education.get(), MAX_EDU), (val, index) => (
          <Education
            key={index}
            elementId={val.getElementId()}
            degree={val.getDegree()}
            description={val.getDescription()}
            schoolName={val.getSchoolName()}
            schoolWebsite={val.getSchoolWebsite()}
            startDate={val.getStartDate()}
            endDate={val.getEndDate()}
            photoLink={val.getPhotoLink()}
            stack={val.getStack()}
            workplace={val.getWorkplace()}
            onRemove={() => education.removeAt(index)}
          />
        ))}
        {shouldDisplayMoreButton && (
          <Button onClick={this.onClickShowMore} style={{ marginLeft: 11, marginTop: -5 }} size='mini'>
            More
          </Button>
        )}
      </div>
    );
  }
}

export default ProfileEducations;
