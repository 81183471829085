import React from 'react';
import _ from 'underscore';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Rectangle, Marker } from 'react-google-maps';
import { Form } from 'semantic-ui-react';

import axios from 'axios';
import baseUrl from '../../baseUrl.js';

export default class AreaCoverage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { locationId: '198006226' };
  }

  async componentDidMount() {
    const locationId = this.props.match.params.locationId || '198006226';
    if (locationId) {
      const response = await axios.get(`${baseUrl}/locationsV2/location/${locationId}`);
      const location = response.data.location;
      const response_locations = await axios.post(`${baseUrl}/locationsV2/includedLocations`, { ...location });
      const locations = response_locations.data.locations;
      this.setState({ locations: locations, location: location, locationId: locationId });
    }
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleSubmit = async () => {
    const response = await axios.get(`${baseUrl}/locationsV2/location/${this.state.locationId}`);
    const location = response.data.location;
    const response_locations = await axios.post(`${baseUrl}/locationsV2/includedLocations`, { ...location });
    const locations = response_locations.data.locations;
    this.setState({ locations: locations, location: location });
  };
  getBounds = (box) => {
    const bounds = {
      north: +box['minLatitude'],
      south: +box['maxLatitude'],
      west: +box['minLongitude'],
      east: +box['maxLongitude'],
    };
    return bounds;
  };

  getPosition = (box) => {
    const position = {
      lat: (parseFloat(box['minLatitude']) + parseFloat(box['maxLatitude'])) / 2,
      lng: (parseFloat(box['minLongitude']) + parseFloat(box['maxLongitude'])) / 2,
    };
    return position;
  };
  render() {
    // const { center, zoom } = fitBounds(bounds, size);
    const Map = compose(
      withProps({
        googleMapURL:
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAmlwt_tI6GkJTPB13r3hB3h1WlMGKdzSA&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `1000px` }} />,
        containerElement: <div style={{ height: `1000px`, minHeight: `100px` }} />,
        mapElement: <div style={{ height: `1000px` }} />,
      }),
      withScriptjs,
      withGoogleMap,
    )(() => (
      <div>
        {this.state.location ? (
          <GoogleMap defaultZoom={10} defaultCenter={this.getPosition(this.state.location.shape.boxes[0])}>
            {this.state.locations
              ? _.map(this.state.locations, (loc, index) => (
                  <div>
                    <Marker
                      key={`marker-${index}`}
                      position={this.getPosition(loc.shape.boxes[0])}
                      title={loc.rawName}
                      icon={`https://maps.google.com/mapfiles/ms/icons/green.png`}
                    />
                    <Rectangle key={`rectangle-${index}`} bounds={this.getBounds(loc.shape.boxes[0])} color={'white'} />
                  </div>
                ))
              : null}
          </GoogleMap>
        ) : null}
      </div>
    ));
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field inline>
            <Form.Input value={this.state.locationId} name="locationId" onChange={this.handleChange} />
            <Form.Button content="Submit" />
          </Form.Field>
        </Form>
        <Map />
      </div>
    );
  }
}
