import _ from 'underscore';
import React from 'react';
import { Divider, Segment, Label, Icon, Header, Tab } from 'semantic-ui-react';
const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

const TargetOkWish = ({ wish, title }) => (
  <div>
    <div horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
      {title}
    </div>

    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        Target:{' '}
        {_.map(wish.target, ({ id }, i) => (
          <Label key={i} color='blue'>
            {id}{' '}
          </Label>
        ))}
      </div>
      <div style={{ width: '50%' }}>
        Ok with:{' '}
        {_.map(wish.okWith, ({ id }, i) => (
          <Label key={i} color='blue'>
            {id}{' '}
          </Label>
        ))}
      </div>
    </div>
  </div>
);
const TargetOkLocations = ({ locations }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Locations</div>
    <div style={rowStyle}>
      <div style={{ width: '50%' }}>
        Remote: <Label color='blue'>{locations.openToRemote}</Label>
      </div>
      <div style={{ width: '50%' }}>
        Target:{' '}
        {_.map(locations.target, ({ location }, i) => (
          <Label key={i} color='blue'>
            {location.id}{' '}
          </Label>
        ))}
      </div>
      <div style={{ width: '50%' }}>
        Ok with:{' '}
        {_.map(locations.okWith, ({ location }, i) => (
          <Label key={i} color='blue'>
            {location.id}{' '}
          </Label>
        ))}
      </div>
    </div>
  </div>
);

const Salary = ({ salary }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Salary</div>
    <Label color='blue'>
      {(salary || {}).min} - {(salary || {}).max}
    </Label>
  </div>
);

const ExtraCriteria = ({ extraCriteria }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Extra Criteria</div>
    <pre>{JSON.stringify(extraCriteria, null, 4)}</pre>
  </div>
);
const Wishes = ({ wishes }) => {
  const { jobs, skills, locations, responsibilities, contracts, salary, extraCriteria } = wishes;
  return (
    <div>
      {jobs && (<TargetOkWish title='Jobs' wish={jobs} />)}
      {skills && (<TargetOkWish title='Skills' wish={skills} />)}
      {locations && (<TargetOkLocations locations={locations} />)}
      {responsibilities && (<TargetOkWish title='Responsibilities' wish={responsibilities} />)}
      {contracts && (<TargetOkWish title='Contracts' wish={contracts} />)}
      {salary && (<Salary salary={salary} />)}
      {extraCriteria && (<ExtraCriteria extraCriteria={extraCriteria} />)}
    </div>
  );
};

const Languages = ({ languages }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Languages</div>
    <div>
      {_.map(languages, ({ level, id }, i) => (
        <Label key={i} color='blue'>
          {id} {level}
        </Label>
      ))}
    </div>
  </div>
);

const Availability = ({ availability }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Availability</div>
    <div>
      {availability.targetDate && <Label color='blue'>{availability.targetDate}</Label>}
      {_.isNumber(availability.transitionDelayInMonths) && (
        <Label color='blue'>{availability.transitionDelayInMonths}</Label>
      )}
    </div>
  </div>
);
const SeekingLevel = ({ seekingLevel }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Seeking Level</div>
    <div>
      <Label color='blue'>{seekingLevel}</Label>
    </div>
  </div>
);

const ExperienceYearsAtDate = ({ experienceYearsAtDate }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Experience Years At Date</div>
    <div>
      {experienceYearsAtDate.date && <Label color='blue'>{experienceYearsAtDate.date}</Label>}
      {_.isNumber(experienceYearsAtDate.years) && <Label color='blue'>{experienceYearsAtDate.years}</Label>}
    </div>
  </div>
);

const ExtraInfo = ({ extraInfo }) => (
  <div>
    <div style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>Extra Info</div>
    <div>
      <pre>{(extraInfo || {}).text}</pre>
    </div>
  </div>
);

const HiresweetProfile = ({ hiresweetProfile }) => {
  const { id, wishes, languages, availability, seekingLevel, experienceYearsAtDate, extraInfo } = hiresweetProfile;
  return (
    <Segment color='blue'>
      {id && <Label>{id}</Label>}
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='heart' />
          Wishes
        </Header>
      </Divider>
      <Wishes wishes={wishes} />
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='info' />
          Other Info
        </Header>
      </Divider>
      {languages && (<Languages languages={languages} />)}
      {availability && (<Availability availability={availability} />)}
      {seekingLevel && (<SeekingLevel seekingLevel={seekingLevel} />)}
      {experienceYearsAtDate && (<ExperienceYearsAtDate experienceYearsAtDate={experienceYearsAtDate} />)}
      {extraInfo && (<ExtraInfo extraInfo={extraInfo} />)}
    </Segment>
  );
};

export default HiresweetProfile;
