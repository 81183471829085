import React, { Component } from 'react';
import _ from 'underscore';
import moment from 'moment';
import {
  Segment,
  Label,
  Header,
  Grid,
  Item,
  Divider,
  Icon,
  Image,
  Button,
  Container,
  Popup,
  // Menu
} from 'semantic-ui-react';
import { SkillDescription } from '../../SkillsAndIcons.js';
import {
  // listHintsInEnrichedText,
  // listEntitiesInEnrichedText,
  transformEnrichmentByHint,
  // transformEnrichmentByEntity,
  // getEnrichedTextByType,
} from '../enrichment';
import Copy from '../../components/Copy';
import EnrichedText, {
  extractSkillsFrontErichedText,
  getColorFromEnrichedText,
} from '../../components/EnrichedText';
import {
  getColorFromScrapDate,
  removeSpacesAndDash,
  isSummarySuspicious,
} from '../../common';
import { Workplace } from '../../RayonX/Workplace';

import skillDataFromId from '../../common/skillDataFromId';
import { ExtLink } from './ExtLink';
//import logos from '../../common/logos.json';
import sources from '../sources';

// const HintsMenu = ({ globalHint, globalHints, onChangeGlobalHint }) =>
//   <Menu secondary>
//     {/* size='mini' */}
//     <Menu.Item name='All' active={!globalHint} onClick={() => onChangeGlobalHint('')} />
//     {_.map(globalHints, hint =>
//       <Menu.Item key={hint} name={hint} active={globalHint === hint}
//         onClick={() => onChangeGlobalHint(hint)} />)}
//   </Menu>
//   ;
/*
const logosMap = _.object(
  _.map(logos, (logo) => logo.shortname.toLowerCase()),
  logos,
);

const skillNameMap = {
  'react.js': 'react',
  'node.js': 'nodejs',
  'redux.js': 'redux',
  'chai.js': 'chai',
  R: 'r-lang',
  'jquery.js': 'jquery',
  'android-development': 'android',
  'ios-development': 'ios',
  cpp: 'c++',
  unity3d: 'unity',
  'express.js': 'express',
  'cake-php': 'cakephp',
  'gulp.js': 'gulp',
  feathers: 'feathersjs',
  'nw.js': 'nwjs',
  'sail.js': 'sails',
  'ruby-on-rails': 'rails',
  'dot-net': 'dotnet',
  'backbone.js': 'backbone',
  'aurelia.js': 'aurelia',
  'angular.js': 'angular',
  'raphael.js': 'raphael',
  'knockout.js': 'knockout',
  'meteor.js': 'meteor',
  'vue.js': 'vue',
  'socket.io': 'socket_io',
  salt: 'saltstack',
  'amazon-web-services': 'AWS',
  css: 'css-3',
  css3: 'css-3',
  html: 'html-5',
  html5: 'html-5',
  'windows-azure': 'azure',
  zend: 'zend-framework',
  'twilio.js': 'twilio',
  sketch: 'sketchapp',
  php4: 'php',
  php5: 'php',
  pandas: 'panda',
  'marionette.js': 'marionette',
  'ember.js': 'ember',
  'd3.js': 'd3',
  'code-igniter': 'codeigniter',
  'batch-file': 'batch',
};


const getRemappingSkill = (skill) => skillNameMap[skill] || skill;
*/

const findIconLink = (id) => {
  if (!_.isString(id) || _.isEmpty(id)) {
    return '';
  }
  const data = skillDataFromId[id];
  return (data && data.icon) || '';
};

const findSkillDetailsURL = (id) => {
  if (!_.isString(id) || _.isEmpty(id)) {
    return undefined;
  }
  const data = skillDataFromId[id];
  return data && data.detailsURL;
}

const findSkillName = (id) => {
  if (!_.isString(id) || _.isEmpty(id)) {
    return undefined;
  }
  const data = skillDataFromId[id];
  return (data && data.name) || { default: id };
}



const findColorForSkillRelevance = (relevance) => {
   const result = relevance === 'required' ? (
     '#cfffc7'
   ) : relevance === 'required__strong-implication' ? (
     '#cfffc7'
   ) : relevance === 'required__medium-implication' ? (
     '#cfffc7'
   ) : relevance === 'important' ? (
     '#f9ffc7'
   ) : relevance === 'important__strong-implication' ? (
     '#f9ffc7'
   ) : relevance === 'important__medium-implication' ? (
     '#f9ffc7'
   ) : relevance === 'bonus' ? (
     '#fff1c7'
   ) : relevance === 'bonus__strong-implication' ? (
     '#fff1c7'
   ) : relevance === 'bonus__medium-implication' ? (
     '#fff1c7'
   ) : relevance === 'cardinal' ? (
     'yellow'
   ) : null;
  return result;
};

// const findSkillRelevanceForOffer = (skill, offerSkills) => (
//   _.indexOf((offerSkills || {}).required || [], skill) >= 0 ? (
//     'required'
//   ) : _.indexOf((offerSkills || {}).important || [], skill) >= 0 ? (
//     'important'
//   ) : _.indexOf((offerSkills || {}).bonus || [], skill) >= 0 ? (
//     'bonus'
//   ) : null
// )

const LinkedinContactView = ({ data }) => {
  const { summaryLinkedRessources, websites } = data || {};
  //
  // websites
  const ressources = [
    ...(summaryLinkedRessources || []),
    ..._.map(websites, (url) => {
      const type =
        url.indexOf('github') >= 0
          ? 'github'
          : url.indexOf('twitter') >= 0
          ? 'twitter'
          : url.indexOf('behance') >= 0
          ? 'behance'
          : url.indexOf('dribbble') >= 0
          ? 'dribbble'
          : url.indexOf('stackoverflow') >= 0
          ? 'stackoverflow'
          : 'website';
      return { url, type, title: type };
    }),
  ];
  return (
    <div>
      {_.map(ressources, ({ url, type, title }, index) => (
        <a key={index} href={url} target="_blank" rel="noopener noreferrer">
          <div
            style={{
              display: 'inline-block',
              padding: 8,
              border: '1px solid lightgrey',
              borderRadius: 5,
              marginTop: 10,
              marginRight: 10,
            }}
          >
            <span style={{ color: 'grey' }}>{title}</span>
          </div>
        </a>
      ))}
    </div>
  );
};

class LinkedinExperienceDescription extends Component {
  constructor() {
    super();
    this.state = { height: 0 };
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  updateHeight() {
    if (this.div && this.state.height !== this.div.clientHeight) {
      this.setState({ height: this.div.clientHeight });
    }
  }

  render() {
    const { i, raw, details, enrichedExperience, onMore } = this.props;

    const suspicious = isSummarySuspicious(raw);

    const maxHeight = i < 2 ? 66 : 33;
    const style = details
      ? {}
      : {
          maxHeight: maxHeight + 'px',
          overflow: 'hidden',
          ...(suspicious && {
            background: 'orange',
          }),
        };
    return (
      <div>
        <div
          style={style}
          onClick={onMore}
          ref={(div) => {
            this.div = div;
          }}
        >
          {enrichedExperience ? (
            <EnrichedText key="3" {...enrichedExperience} />
          ) : (
            <Item.Description
              key="1"
              dangerouslySetInnerHTML={{ __html: raw }}
            />
          )}
        </div>
        {!details && this.state.height === maxHeight && (
          <Icon name="caret down" />
        )}
      </div>
    );
  }
}
// TODO do this in back (duplicated functions)
const getYearMonthFromRawDate = (raw) => {
  if (!raw) {
    return [];
  } else if (_.isString(raw)) {
    return raw.split(' ').reverse();
  } else if (_.isNumber(raw)) {
    return [raw, null];
  } else {
    return [];
  }
};

const getMonthFromString = (raw) => {
  const monthToId = {
    jan: 0,
    fev: 1,
    fév: 1,
    feb: 1,
    mar: 2,
    avr: 3,
    apr: 3,
    mai: 4,
    may: 4,
    juin: 5,
    jun: 5,
    juil: 6,
    jul: 6,
    ao: 7,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
    déc: 11,
  };
  let month = null;
  _.each(monthToId, (value, key) => {
    if (('' + raw).toLowerCase().indexOf(key) >= 0) {
      month = value;
    }
  });
  return month;
};

const getDateObjFromRaw = (raw) => {
  const [yearString, monthString] = getYearMonthFromRawDate(raw);
  const month = monthString
    ? getMonthFromString(monthString.toLowerCase())
    : null;
  const year = (yearString && parseInt(yearString, 10)) || null;
  return {
    raw,
    month,
    year,
  };
};

const getAugmentedElementHash = (baseHash) => {
  const text = (baseHash || '').toLowerCase().slice(0, 100);
  let result = '';
  _.each(text, (c) => {
    result += c >= 'a' && c <= 'z' ? c : '_';
  });
  return result;
};

const getWebsiteLogo = (website) => {
  if (!_.isString(website)) {
    return 'external';
  }
  let icon = 'external';
  _.each(sources, (source) => {
    if (source.website && source.icon && website.indexOf(source.website) >= 0) {
      icon = source.icon;
    }
  });
  return icon;
};

export const LinkedinProfile = ({
  source,
  more,
  globalHint,
  globalHints,
  onMore,
  onChangeExperienceHint,
  onChangeGlobalHint,
  augmentedExperiencesAndEducation,
  offerSkills,
  mainEducation,
}) => {
  const { id, data } = source || {};
  const {
    photoLink,
    fullname,
    firstname,
    lastname,
    headline,
    summary,
    website,
    twitter,
    experiences,
    education,
    skills,
    // enrichedData,
    summaryEnriched,
    headlineEnriched,
  } = data || {};
  // const summaryEnriched = getEnrichedTextByType(enrichedData, 'summary');
  // const hintListExp = _.map(experiences, ({ enrichedExperience, descriptionHTML }) =>
  //   enrichedExperience ? listHintsInEnrichedText(enrichedExperience) : []
  // );


  const firstExperience = _.first(experiences) || {};
  const firstEducation = mainEducation || {}; //_.first(education) || {};
  const currentMonth = new Date().getMonth();

  const firstEducationDates = (firstEducation || {}).endDate || {}; //((firstEducation || {}).rawDates || []).length > 1 && getDateObjFromRaw(firstEducation.rawDates[1]);
  const defaultEducationMonth = 6;
  const firstEducationEndFromNow =
    firstEducationDates.year &&
    moment([
      firstEducationDates.year,
      _.isNumber(firstEducationDates.month)
        ? firstEducationDates.month
        : defaultEducationMonth,
      1,
    ]).fromNow();
  const isSuspicious = isSummarySuspicious(summary || '');
  const summaryStyle = more['linkedinSummary']
    ? {}
    : {
        maxHeight: '8em',
        overflow: 'hidden',
        ...(isSuspicious && { background: 'orange' }),
      };
  const getLabelPriority = (label) => (
    {
      'required': 9,
      'important': 8,
      'bonus': 7,
      'required__strong-implication': 6,
      'required__medium-implication': 5,
      'important__strong-implication': 4,
      'important__medium-implication': 3,
      'bonus__strong-implication': 2,
      'cardinal': 1,
    }[label] || 0
  );
  const getUniqId = (skills) => {
    const skillsMap = _.reduce(
      skills,
      (memo, { label, id }) => {
        if (!memo[id]) {
          memo[id] = label;
        } else if (getLabelPriority(memo[id]) < getLabelPriority(label)) {
          memo[id] = label;
        }
        return memo;
      },
      {},
    );
    return _.map(skillsMap, (label, id) => ({ label, id }));
  };
  const removeSomeCardinalSkillsIfMoreThan2 = (skills) => {
    const result = [];
    let nbCardinals = 0;
    _.each(skills, (skill) => {
      if (skill.label === 'cardinal') {
        nbCardinals++;
        if (nbCardinals >= 3) {
          return;
        }
      }
      result.push(skill);
    });
    return result;
  }

  const summarySkills = summaryEnriched
    ? extractSkillsFrontErichedText(summaryEnriched)
    : [];

  const summarySkillsList = _.isArray(summarySkills)
      ? _.map(removeSomeCardinalSkillsIfMoreThan2(getUniqId(summarySkills)), ({ label, id }) => {
        return {
          skill: id,
          icon: findIconLink(id),
          detailsURL: findSkillDetailsURL(id),
          name: findSkillName(id),
          label,
          bgColor: findColorForSkillRelevance(label),
        };
      })
    : [];
  const sortedSummarySkillsList = _.sortBy(
    summarySkillsList,
    ({ label }) => getLabelPriority(label) * -1,
  );
  return (
    <div>
      <Segment color="blue">
        <div
          style={{ position: 'absolute', right: '10px', top: '10px' }}
          className="sources-link-list"
        >
          <Label
            style={{ marginRight: 20 }}
            color={getColorFromScrapDate((source || {}).date)}
          >
            {moment((source || {}).date, 'YY-MM-DD').fromNow()}
          </Label>
          <ExtLink to={`https://linkedin.com/in/${id}`}>
            <Icon name="linkedin" />
          </ExtLink>
          {website ? (
            <ExtLink to={website}>
              <Icon name={getWebsiteLogo(website)} />
            </ExtLink>
          ) : null}
          {twitter ? (
            <ExtLink to={twitter}>
              <Icon name="twitter" />
            </ExtLink>
          ) : null}
        </div>
        <Header size="medium">
          <Grid>
            <Grid.Row>
              <Grid.Column
                width={3}
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <center>
                  <Image
                    circular
                    src={photoLink}
                    style={{ fontSize: '3rem', width: '80px', height: '80px' }}
                  />
                </center>
              </Grid.Column>
              <Grid.Column width={13}>
                <Header.Content>
                  {firstname && lastname ? (
                    <span>
                      {`${firstname} ${lastname}`}
                      <Copy
                        text={`${removeSpacesAndDash(
                          firstname,
                        )} ${removeSpacesAndDash(lastname)}@gmail.com`}
                      />
                    </span>
                  ) : fullname ? (
                    <span>
                      {fullname}
                      <Copy
                        text={`${removeSpacesAndDash(fullname)}@gmail.com`}
                      />
                    </span>
                  ) : null}
                  <Header.Subheader style={{ fontSize: '13px' }}>
                    {headlineEnriched ? (
                      <EnrichedText
                        {...transformEnrichmentByHint(
                          globalHint,
                          headlineEnriched,
                        )}
                      />
                    ) : (
                      <span>{headline}</span>
                    )}
                    <br />
                    {!_.isEmpty(firstEducation) &&
                      `formé à ${firstEducation.schoolName} ${
                        firstEducationEndFromNow
                          ? `(${firstEducationEndFromNow})`
                          : ''
                      } • ${firstExperience.location}`}
                    {/* <HintsMenu globalHint={globalHint} globalHints={globalHints} onChangeGlobalHint={onChangeGlobalHint} /> */}
                    <LinkedinContactView data={data} />
                  </Header.Subheader>
                </Header.Content>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>

        {summary && !summaryEnriched ? (
          <Segment
            secondary
            style={summaryStyle}
            onClick={() => onMore('linkedinSummary')}
          >
            {summary}
          </Segment>
        ) : null}
        {summary && summaryEnriched && summaryEnriched.content.length > 0 ? (
          <Segment
            style={summaryStyle}
            onClick={() => onMore('linkedinSummary')}
          >
            <Grid>
              <Grid.Column width={12}>
                <Grid.Row style={{ padding: '0' }}>
                  <EnrichedText
                    {...transformEnrichmentByHint(globalHint, summaryEnriched)}
                  />
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                {_.map(
                  sortedSummarySkillsList,
                  ({ skill, icon, name, detailsURL, bgColor }, index) =>
                    icon ? (
                      <SkillDescription 
                        key={index}
                        data={{
                          name,
                          detailsURL,
                          icon,
                        }}
                        language='en'
                      />
                    ) : (
                      <span
                        key={index}
                        style={{
                          marginLeft: '5px',
                          fontWeight: 'bold',
                          ...(bgColor && { backgroundColor: bgColor }),
                        }}
                      >
                        {skill}
                      </span>
                    ),
                )}
              </Grid.Column>
            </Grid>
          </Segment>
        ) : null}
        <Divider horizontal>Experiences</Divider>

        <Item.Group divided>
          {_.map(experiences, (exp, i) => {
            // const hints = globalHint ?
            //   _.first(listEntitiesInEnrichedText(globalHint, exp.enrichedExperience), 4) :
            //   _.first(hintListExp[i], 4);
            const enrichedExperience = exp.descriptionHTMLEnriched;
            // const enrichedExperience = exp.enrichedExperience
            // const enrichedExperience = globalHint ? (
            //   exp.hint ? (
            //     transformEnrichmentByEntity(exp.hint, exp.enrichedExperience)
            //   ) : transformEnrichmentByHint(globalHint, exp.enrichedExperience)
            // ) : exp.hint ? (
            //   transformEnrichmentByHint(exp.hint, exp.enrichedExperience)
            // ) : exp.enrichedExperience;
            const moreKey = `linkedinExp${i}`;
            const xpStartDate =
              ((exp || {}).rawDates || []).length > 0 &&
              getDateObjFromRaw(exp.rawDates[0]);
            const xpEndDate =
              ((exp || {}).rawDates || []).length > 1 &&
              getDateObjFromRaw(exp.rawDates[1]);
            const currentYear = new Date().getFullYear();
            const currentMomentDate = moment([currentYear, currentMonth, 1]);
            const xpStartMomentDate =
              (xpStartDate || {}).year &&
              moment([
                xpStartDate.year,
                _.isNumber(xpStartDate.month)
                  ? xpStartDate.month
                  : currentMonth,
                1,
              ]);
            const xpEndMomentDate =
              (xpEndDate || {}).year &&
              moment([
                xpEndDate.year,
                _.isNumber(xpEndDate.month) ? xpEndDate.month : currentMonth,
                1,
              ]);
            const xpDurationYears = xpEndMomentDate
              ? xpEndMomentDate.diff(xpStartMomentDate, 'years')
              : currentMomentDate.diff(xpStartMomentDate, 'years');
            const xpDurationMonths =
              _.isNumber(xpStartDate.month) && xpEndMomentDate
                ? xpEndMomentDate.diff(
                    xpStartMomentDate.add(xpDurationYears, 'years'),
                    'months',
                  )
                : _.isNumber(xpStartDate.month) && !xpEndDate.year
                ? currentMomentDate.diff(
                    xpStartMomentDate.add(xpDurationYears, 'years'),
                    'months',
                  )
                : 0;
            const xpDuration =
              (xpDurationYears > 1
                ? xpDurationYears + ' ans '
                : xpDurationYears
                ? xpDurationYears + ' an '
                : '') + (xpDurationMonths ? xpDurationMonths + ' mois' : '');
            const durationYearsSinceHasNoCurrentXp =
              i === 0 &&
              xpEndMomentDate &&
              xpEndDate &&
              currentMomentDate.diff(xpEndMomentDate, 'years');
            const durationMonthsSinceHasNoCurrentXp =
              i === 0 &&
              xpEndMomentDate &&
              xpEndDate &&
              currentMomentDate.diff(
                xpEndMomentDate.add(durationYearsSinceHasNoCurrentXp, 'years'),
                'months',
              );
            const durationSinceHasNoCurrentXp =
              _.isNumber(durationYearsSinceHasNoCurrentXp) &&
              (durationYearsSinceHasNoCurrentXp > 1
                ? durationYearsSinceHasNoCurrentXp + ' ans '
                : durationYearsSinceHasNoCurrentXp
                ? durationYearsSinceHasNoCurrentXp + ' an '
                : '') +
                (durationMonthsSinceHasNoCurrentXp
                  ? durationMonthsSinceHasNoCurrentXp + ' mois'
                  : '');
            const hash = getAugmentedElementHash(
              (exp.experienceName || '') +
                (exp.companyName || '') +
                (exp.descriptionHTML || ''),
            );
            const augmentedExperience =
              hash &&
              augmentedExperiencesAndEducation &&
              (augmentedExperiencesAndEducation.experiences || {})[hash];
            const getWorkplaceTypeIcon = (
              type, //handshake
            ) =>
              type === 'ssii'
                ? 'desktop'
                : type === 'st'
                ? 'lightbulb'
                : type === 'big'
                ? 'industry'
                : 'question';

            const skills = [
              ...extractSkillsFrontErichedText(enrichedExperience),
              ...extractSkillsFrontErichedText(exp.experienceNameEnriched),
            ];

            const skillsList = _.isArray(skills)
               ? _.map(removeSomeCardinalSkillsIfMoreThan2(getUniqId(skills)), ({ label, id }) => {
                  return {
                    skill: id,
                    icon: findIconLink(id),
                    detailsURL: findSkillDetailsURL(id),
                    name: findSkillName(id),
                    label,
                    bgColor: findColorForSkillRelevance(label),
                  };
                })
              : [];


            const sortedSkillsList = _.sortBy(
              skillsList,
              ({ label }) => getLabelPriority(label) * -1,
            );


            return more['linkedinExperiences'] || i < 4 ? (
              <Grid style={{ margin: '0' }} key={i}>
                <Grid.Column width={12}>
                  <Grid.Row style={{ padding: '0' }}>
                    <Item>
                      <Item.Content>
                        <Item.Header
                          style={{
                            width: '100%',
                            fontSize: '1.28571429em',
                            fontWeight: '700',
                            color: 'rgba(0,0,0,.85)',
                            marginTop: '3px',
                          }}
                        >
                          {exp.experienceNameEnriched ? (
                            <EnrichedText
                              {...transformEnrichmentByHint(
                                globalHint,
                                exp.experienceNameEnriched,
                              )}
                            />
                          ) : (
                            <span>{exp.experienceName}</span>
                          )}{' '}
                          •{' '}

                          {(augmentedExperience || {}).mergedWorkplace ? (
                            <Popup
                              trigger={
                                <span
                                  style={{
                                    marginLeft: '5px',
                                    borderBottom: ' 1px dotted #000',
                                  }}
                                >
                                  {exp.companyName}
                                </span>
                              }
                              content={<Workplace data={
                                {
                                  prestige: (augmentedExperience || {}).prestige,
                                  ...(augmentedExperience || {}).mergedWorkplace,
                                }
                              }/>}
                            />
                          ) : (
                            <span>{exp.companyName}</span>
                          )}

                          {augmentedExperience &&
                            _.isNumber(augmentedExperience.prestige) && (
                              <Popup
                                trigger={
                                  <Label
                                    color={
                                      augmentedExperience.prestige > 3
                                        ? 'green'
                                        : 'orange'
                                    }
                                    style={{ marginLeft: '5px' }}
                                  >
                                    {augmentedExperience.prestige}
                                  </Label>
                                }
                                content={'workplace prestige'}
                              />
                            )}
                          {/* {augmentedExperience && !augmentedExperience.prestige && augmentedExperience.workplace && (
                            <Popup
                              trigger={
                                <Label style={{ marginLeft: '5px' }} onClick={() => console.log('TODO')}>
                                  <Icon name='add' />
                                </Label>
                              }
                              content={`add a prestige to the workplace ${augmentedExperience.workplace}`}
                            />
                          )} */}
                          {augmentedExperience &&
                            _.isArray(augmentedExperience.workplaceType) &&
                            _.map(
                              _.uniq(
                                _.compact(augmentedExperience.workplaceType),
                              ),
                              (type) => (
                                <Popup
                                  key={type}
                                  trigger={
                                    <Icon
                                      name={getWorkplaceTypeIcon(type)}
                                      style={{ marginLeft: '5px' }}
                                    />
                                  }
                                  content={
                                    type === 'st'
                                      ? 'startup'
                                      : type === 'big'
                                      ? 'grand groupe'
                                      : type
                                  }
                                />
                              ),
                            )}
                        </Item.Header>
                        <Item.Meta style={{ color: 'rgba(0,0,0,.6)' }}>
                          {(exp.rawDates || []).join(' - ')} · {exp.location}
                          <span
                            style={{
                              color: xpDurationYears > 0 ? 'green' : 'orange',
                              marginLeft: '5px',
                              fontWeight: 'bold',
                            }}
                          >
                            {xpDuration}
                          </span>
                          {durationSinceHasNoCurrentXp && (
                            <span style={{ marginLeft: '5px', color: 'blue' }}>
                              Disponible depuis {durationSinceHasNoCurrentXp}
                            </span>
                          )}
                        </Item.Meta>
                        <LinkedinExperienceDescription
                          i={i}
                          details={more[moreKey]}
                          onMore={() => onMore(moreKey)}
                          raw={exp.descriptionHTML || ''}
                          enrichedExperience={enrichedExperience}
                        />
                      </Item.Content>
                    </Item>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  {_.map(sortedSkillsList, ({ skill, icon, detailsURL, name,  bgColor }, index) =>
                    icon ? (
                      <SkillDescription 
                        key={index}
                        data={{
                          name,
                          icon,
                          detailsURL
                        }}
                        language='en'
                      />
                    ) : (
                      <span
                        key={index}
                        style={{
                          marginLeft: '5px',
                          fontWeight: 'bold',
                          ...(bgColor && { backgroundColor: bgColor }),
                        }}
                      >
                        {skill}
                      </span>
                    ),
                  )}
                  {/* <div style={{ margin: '10px' }}>
                  {augmentedExperience && _.isArray(augmentedExperience.jobPosition) && _.compact(_.map(augmentedExperience.jobPosition, (jobPos, index) => (
                    augmentedExperience.jobPosition.length > 1 && jobPos === 'software-engineering' ? null : (
                      <span key={index} style={{ marginLeft: '5px', color: 'grey', fontWeight: 'bold' }}>{jobPos}</span>
                    )
                  )))}
                  </div> */}
                </Grid.Column>
              </Grid>
            ) : null;
          })}
        </Item.Group>
        {(experiences || []).length > 4 && !more['linkedinExperiences'] ? (
          <Button size="small" onClick={() => onMore('linkedinExperiences')}>
            Load more
          </Button>
        ) : null}
        <Divider horizontal>Education</Divider>
        {/* <Feed> */}
        <Item.Group divided>
          {_.map(education, (edu, i) => {
            // const enrichedEducation = edu.descriptionHTMLEnriched
            // const eduStartDate = ((edu || {}).rawDates || []).length > 0 && getDateObjFromRaw(edu.rawDates[0]);
            const eduEndDate =
              ((edu || {}).rawDates || []).length > 1 &&
              getDateObjFromRaw(edu.rawDates[1]);
            // const currentYear = (new Date()).getFullYear();
            // const currentMomentDate = moment([currentYear, currentMonth, 1]);
            // const eduStartMomentDate = (eduStartDate || {}).year && moment([eduStartDate.year, _.isNumber(eduStartDate.month) ? eduStartDate.month : currentMonth, 1]);
            const eduEndMomentDate =
              (eduEndDate || {}).year &&
              moment([
                eduEndDate.year,
                _.isNumber(eduEndDate.month) ? eduEndDate.month : currentMonth,
                1,
              ]);
            // const eduDurationYears = eduEndMomentDate ? eduEndMomentDate.diff(eduStartMomentDate, 'years') : currentMomentDate.diff(eduStartMomentDate, 'years');
            // const eduDurationMonths = eduEndDate.month && eduStartDate.month ? eduEndMomentDate.diff(eduStartMomentDate.add(eduDurationYears, 'years'), 'months') : (
            //   eduStartDate.month && !eduEndDate.year ? currentMomentDate.diff(eduStartMomentDate.add(eduDurationYears, 'years'), 'months') : 0
            // );
            // const eduDuration = (eduDurationYears > 1 ? eduDurationYears + ' ans ' : eduDurationYears ? eduDurationYears + ' an ' : '') + (eduDurationMonths ? eduDurationMonths + ' mois' : '');
            const hash = getAugmentedElementHash(
              (edu.schoolName || '') + (edu.degree || ''),
            );
            const augmentedEducation =
              hash &&
              augmentedExperiencesAndEducation &&
              (augmentedExperiencesAndEducation.education || {})[hash];

            const eduEndDateUntilNow =
              eduEndMomentDate && eduEndMomentDate.fromNow();
            return more['linkedinEducation'] || i < 2 ? (
              <Item key={i}>
                <Item.Content>
                  <Item.Header style={{ width: '100%' }}>
                    <Icon name="map pin" />

                    {(augmentedEducation || {}).workplace ? (
                      <Popup
                        trigger={
                          <span
                            style={{
                              marginLeft: '5px',
                              borderBottom: ' 1px dotted #000',
                            }}
                          >
                            {edu.schoolName}
                          </span>
                        }
                        content={augmentedEducation.workplace}
                      />
                    ) : (
                      <span>{edu.schoolName}</span>
                    )}
                    {augmentedEducation &&
                      _.isNumber(augmentedEducation.prestige) && (
                        <Popup
                          trigger={
                            <Label
                              color={
                                augmentedEducation.prestige > 3
                                  ? 'green'
                                  : 'orange'
                              }
                              style={{ marginLeft: '5px' }}
                            >
                              {augmentedEducation.prestige}
                            </Label>
                          }
                          content={'workplace prestige'}
                        />
                      )}
                    {augmentedEducation &&
                      !augmentedEducation.prestige &&
                      (augmentedEducation || {}).workplace && (
                        <Popup
                          trigger={
                            <Label
                              style={{ marginLeft: '5px' }}
                              onClick={() => console.log('TODO')}
                            >
                              <Icon name="add" />
                            </Label>
                          }
                          content={`add a prestige to the workplace ${
                            augmentedEducation.workplace
                          }`}
                        />
                      )}
                  </Item.Header>
                  <Item.Meta>
                    {edu.degreeEnriched ? (
                      <EnrichedText
                        {...transformEnrichmentByHint(
                          globalHint,
                          edu.degreeEnriched,
                        )}
                      />
                    ) : (
                      <span>{edu.degree}</span>
                    )}{' '}
                    · {(edu.rawDates || []).join(' - ')}
                    <span
                      style={{
                        marginLeft: '5px',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {eduEndDateUntilNow}
                    </span>
                    {/* <span style={{ color: eduDurationYears > 0 ? 'green' : 'orange', marginLeft: '5px', fontWeight: 'bold' }}>{eduDuration}</span> */}
                    {edu.descriptionHTMLEnriched ? (
                      [
                        <br key={0} />,
                        <EnrichedText
                          key={1}
                          {...transformEnrichmentByHint(
                            globalHint,
                            edu.descriptionHTMLEnriched,
                          )}
                        />,
                      ]
                    ) : edu.descriptionHTML ? (
                      <span>{edu.descriptionHTML}</span>
                    ) : null}
                  </Item.Meta>
                </Item.Content>
              </Item>
            ) : null;
          })}
          {/* </Feed> */}
        </Item.Group>
        {(education || []).length > 2 && !more['linkedinEducation'] ? (
          <Button size="small" onClick={() => onMore('linkedinEducation')}>
            Load more
          </Button>
        ) : null}
        <Divider horizontal>Skills</Divider>
        <Container fluid textAlign="center">
          <Label.Group>
            {_.map(_.sortBy(skills, ({ num }) => -1 * num), (skill, i) => (
              // getTagColor(globalHint, skill.enrichedSkill)
              // TODO
              <Label
                key={i}
                style={{
                  backgroundColor:
                    getColorFromEnrichedText(skill.tagEnriched) || '#eee',
                }}
              >
                {skill.tag}
                <Label.Detail>{skill.num}</Label.Detail>
              </Label>
            ))}
          </Label.Group>
        </Container>
      </Segment>
    </div>
  );
};
