export default
[
    "fr",
    "en",
    "ar",
    "es",
    "de",
    "it",
    "hi",
    "he",
    "zh",
    "jp",
    "sq",
    "eu",
    "be",
    "bg",
    "ca",
    "hr",
    "cs",
    "da",
    "nl",
    "et",
    "fo",
    "fa",
    "fi",
    "gd",
    "el",
    "hu",
    "is",
    "id",
    "ga",
    "ko",
    "ku",
    "lv",
    "lt",
    "mk",
    "ml",
    "ms",
    "mt",
    "no",
    "pl",
    "pt-br",
    "pa",
    "rm",
    "ro",
    "ru",
    "sr",
    "sk",
    "sl",
    "sb",
    "sv",
    "th",
    "ts",
    "tn",
    "tr",
    "uk",
    "ur",
    "ve",
    "vi",
    "cy",
    "xh",
    "ji",
    "zu"
  ]
  