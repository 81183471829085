import React from 'react';
import _ from 'underscore';

class GenericSweethubView extends React.Component {
  render() {
    const { url } = this.props;
    return <iframe src={url} width='100%' height='600px' />;
  }
}

export default GenericSweethubView;
