import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Loader, Table, Accordion, Icon } from 'semantic-ui-react';
import { DoubleClickIcon } from '../common'
import baseUrl from '../baseUrl.js';

class TasksBoard extends Component {
  state = {};
  loadData = async () => {
    const { data } = await axios.get(baseUrl + '/preflows/tasks');
    this.setState({ tasksByOwner: data });
  }
  componentDidMount() {
    this.loadData();
  }
  skipPendingActions = async ({offerId, externalFlowId}) => {
    try {
      const payload = { offerId, externalFlowId };
      const { data } = await axios.post(baseUrl + '/preflows/tasks/skipPendingActions', payload);
      if (!data.success){
        throw Error(data.error || "")
      } else {
        this.loadData();
      }
    } catch (e) {
      alert("Failed skipping tasks :\n" + JSON.stringify(e, null, 4))
    }
    
  }
  render() {
    const { tasksByOwner, selectedOwner } = this.state;
    if (!tasksByOwner) {
      return <center><Loader inline active /></center>;
    }
    
    const targetType = 'watch';//'watch';

    const targetTasksByOwner = {};
    _.each(tasksByOwner, (tasksByType, owner) => {
      _.each((tasksByType[targetType] || {}).tasks, (task) => {
        if (!targetTasksByOwner[owner]) {
          targetTasksByOwner[owner] = {}
        }
        const { offerId, url } = task;
        if (!targetTasksByOwner[owner][offerId]) {
          targetTasksByOwner[owner][offerId] = { count: 0, url };
        }
        targetTasksByOwner[owner][offerId].count++;
      })
    });


    const nbTasksByOwner = {};
    _.each(targetTasksByOwner, (tasksByOfferId, owner) => {
      const count = _.reduce(_.map(tasksByOfferId, ({ count }) => count), (memo, x) => memo + x, 0);
      if (count) {
        nbTasksByOwner[owner] = count
      }
    });

    const colorFromNbTasks = (nbTasks) => (
      nbTasks > 50 ? (
        '#ffdddd'
      ) : nbTasks > 25 ? (
        '#fdebd0'
      ) : nbTasks > 5 ? (
        '#ffffdd'
      ) : (
        'whitesmoke'
      )
    );

    const sortedOwner = _.sortBy(_.keys(nbTasksByOwner), (owner) => -nbTasksByOwner[owner]);

    return (
      <Table>
      <Table.Header>
      </Table.Header>
      <Table.Body>
      {_.map(sortedOwner, (owner) => (
        <Table.Row key={owner} textAlign='top' style={{ backgroundColor: colorFromNbTasks(nbTasksByOwner[owner]) }}>
        <Table.Cell>
        <Accordion>
        <Accordion.Title
        active={selectedOwner === owner}
            index={0}
            onClick={() => { this.setState({ 
              selectedOwner: selectedOwner === owner ? null : owner,
            }); } }
            >
            <Icon name='dropdown' />
            {owner}
            </Accordion.Title>
            <Accordion.Content active={selectedOwner === owner}>
            <div style={{ paddingLeft: '20px' }}>
            {_.map(targetTasksByOwner[owner], ({ url, count }, offerId) => (
              <div style={{ padding: 5 }}>
                <DoubleClickIcon 
                  name='close' 
                  color='black'
                  size='small'
                  style={{marginRight:'5px', cursor:'pointer'}}
                  onClick={()=>this.skipPendingActions({offerId, externalFlowId:targetType})} 
                />
                 <a key={url} href={url} target='_blank' rel="noopener noreferrer">
                  
                    {offerId} ({count})
                  
                </a>
              </div>
             
            ))}
            </div>
            </Accordion.Content>
            </Accordion>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <span style={{ paddingRight: 10 }}>{nbTasksByOwner[owner]}</span>
            </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
}
}

export default TasksBoard; 
