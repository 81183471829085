import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import React, { Component } from 'react';
import { Button, Form, Grid, Modal, Segment } from 'semantic-ui-react';

import { JobCollectionView } from './Jobs';
import { DetailedProfileView } from './Talents';

class JobSearch extends Component {
  handleSelectProfileCollection = async (e, { value }) => {
    const selectedProfileCollectionId = value;
    this.setState({ selectedProfileCollectionId });
    await this.loadProfiles(selectedProfileCollectionId);
  };

  handleSelectJobCollection = (e, { value }) => {
    this.setState({ selectedJobCollectionId: value });
  };

  handleSelectProfile = async (e, { value }) => {
    const { profiles } = this.state || {};
    const selectedProfile = _.find(profiles, (p) => p.profile_path === value);
    this.setState({ selectedProfile, selectedProfileId: value });
  };

  loadProfiles = async (profileCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfiles/' + profileCollectionId;
    const profiles = (await axios.get(url, customHeaders)).data;
    this.setState({ profiles: profiles.data.results });
  };

  runSearch = async () => {
    const { selectedJobCollectionId, selectedProfile } = this.state || {};
    const payload = {
      profilePath: selectedProfile.profile_path,
      jobCollectionId: selectedJobCollectionId,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/reverseSearch';
    const data = (await axios.post(url, payload, customHeaders)).data;
    const formattedJobs = _.map(data.results, (res) => ({
      id: res.id,
      job_path: res.filename,
    }));
    this.setState({ jobs: formattedJobs });
  };

  render() {
    const { profileCollections, jobCollections } = this.props;
    const { selectedProfileCollectionId, profiles, selectedProfile, selectedProfileId, jobs, selectedJobCollectionId } =
      this.state || {};
    const profileCollectionsOptions = _.map(profileCollections, (profileCollection, index) => ({
      key: index,
      value: profileCollection.id,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
    }));
    const jobCollectionsOptions = _.map(jobCollections, (jobCollection, index) => ({
      key: index,
      value: jobCollection.id,
      text: jobCollection.name ? jobCollection.name : jobCollection.title,
    }));
    const profilesOptions = _.map(profiles, (profile, index) => ({
      key: index,
      value: profile.profile_path,
      text: profile.firstname + ' ' + profile.lastname,
    }));
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7}>
              <Form.Select
                search
                placeholder='Profile Collection'
                options={profileCollectionsOptions}
                onChange={this.handleSelectProfileCollection}
                value={selectedProfileCollectionId}
              />
              {selectedProfileCollectionId ? (
                <Form.Select
                  search
                  placeholder='Profile'
                  options={profilesOptions}
                  onChange={this.handleSelectProfile}
                  value={selectedProfileId}
                />
              ) : null}
            </Grid.Column>
            <Grid.Column width={9}>
              <Form.Select
                search
                placeholder='Job Collection'
                options={jobCollectionsOptions}
                onChange={this.handleSelectJobCollection}
                value={selectedJobCollectionId}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {selectedProfile ? (
          <Grid>
            <Grid.Row>
              {selectedJobCollectionId ? (
                <Button onClick={() => this.runSearch()} color='green'>
                  Search
                </Button>
              ) : null}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <DetailedProfileView key={selectedProfile.profile_path} profilePath={selectedProfile.profile_path} />
              </Grid.Column>
              <Grid.Column width={9}>
                {jobs ? <JobCollectionView jobs={jobs} isJobEditable={false} /> : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </div>
    );
  }
}
export default JobSearch;
