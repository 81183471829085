import React, { Component } from 'react';

import DoublePanesCanvas from '../../common/DoublePanesCanvas';
import DualOverview from './DualOverview';
import WatchSelection from './WatchSelection';
import Warnings from './Warnings';
import { Message, Icon } from 'semantic-ui-react';

class GlobalView extends Component {
  state = {
    mode: 'profiles',
  };
  handleChangeMode = (mode) => {
    if (mode !== this.state.mode) {
      this.setState({
        mode,
        targetWatchProfileId: null,
        targetOfferId: null,
      });
    }
  };
  handleSelectTargetSheetItem = (sheetItemId) => {
    this.setState({
      mode: 'profiles',
      targetSheetItemId: sheetItemId,
    });
  };
  handleSelectTargetClient = (clientId) => {
    this.setState({
      mode: 'clients',
      targetClientId: clientId,
    });
  };
  render() {
    const {
      nbOtherMatchOpportunies,

      clients,
      handleLoadClientsSelection,

      sheetItems,
      handleLoadProfilesSelection,

      handleUpdateProfile,
      handleRecomputeReverseSearch,

      selection,
      handleClearSelection,
      handleChangeMatchState,
      handleUpdateMatch,
      handleDeleteMatch,
      handleUploadProfile,

      handleChangeSelectedUser,
      usersOptions,
      selectedUser,
      recomputingScores
    } = this.props;

    const { mode, targetClientId, targetSheetItemId, warnings } = this.state;

    return (
      <DoublePanesCanvas leftWidthPercent='70%' rightWidthPercent='30%'>
        <div>
          <DualOverview
            mode={mode}
            handleChangeMode={this.handleChangeMode}
            nbOtherMatchOpportunies={nbOtherMatchOpportunies}
            clients={clients}
            targetClientId={targetClientId}
            handleSelectTargetClient={this.handleSelectTargetClient}
            handleLoadClientsSelection={handleLoadClientsSelection}
            sheetItems={sheetItems}
            targetSheetItemId={targetSheetItemId}
            handleSelectTargetSheetItem={this.handleSelectTargetSheetItem}
            handleLoadProfilesSelection={handleLoadProfilesSelection}
            handleUpdateProfile={handleUpdateProfile}
            handleRecomputeReverseSearch={handleRecomputeReverseSearch}
            handleChangeSelectedUser={handleChangeSelectedUser}
            usersOptions={usersOptions}
            selectedUser={selectedUser}
          />
        </div>
        <div>
          {recomputingScores && (
            <Message info ><Icon name='circle notched' loading /> Recomputing scores... </Message>
          )}
          <Warnings warnings={warnings} />
          <WatchSelection
            selection={selection}
            handleClearSelection={handleClearSelection}
            handleSelectTargetSheetItem={this.handleSelectTargetSheetItem}
            handleSelectTargetClient={this.handleSelectTargetClient}
            handleChangeMatchState={handleChangeMatchState}
            handleUpdateMatch={handleUpdateMatch}
            handleDeleteMatch={handleDeleteMatch}
            handleUploadProfile={handleUploadProfile}
          />
        </div>
      </DoublePanesCanvas>
    );
  }
}

export default GlobalView;
