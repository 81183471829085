import React from 'react';
import _ from 'underscore';
import {
  Image,
  Grid,
  Divider,
  Segment,
  Header,
  Icon,
  Statistic,
  Label,
  Container,
  Checkbox,
  Popup,
} from 'semantic-ui-react';
import GITHUB_COLORS from '../github-colors.json';
import { Stack, getFormattedStack } from '../Stack';
import '../ClientProfile.css';
import { translate } from 'react-i18next';
import i18n from 'i18next';
import { parseDate, durationBetween } from '../../common/dates';

import LoadingComponent from './LoadingComponent';
import ProfileHeader from './ProfileHeader';
import CollapsibleEnrichedText from './CollapsibleEnrichedText';
import SummaryAndStack from './SummaryAndStack';
import SmartSummary from './SmartSummary';

const RelevantTags = ({ relevantTags }) => (
  <Grid padded className='profile-relevant-tags'>
    {_.map(
      relevantTags,
      ({ label, content }, index) =>
        !_.isEmpty(content) &&
        !_.isEmpty(label) &&
        !_.isEmpty(label.default) && <Pill key={index} content={content} label={label} />,
    )}
  </Grid>
);

const Pill = ({ label, content }) => (
  <div className='relevant-tag-pill'>
    <span className='relevant-tag-pill-inner'>
      <span className='tag-label'>
        <TranslatableText text={label} />
      </span>
      {label && content && <span> • </span>}
      <span className='tag-content'>
        <TranslatableText text={content} />
      </span>
    </span>
  </div>
);

const getTranslatedText = (translatableText) => {
  const { language } = i18n;
  const languageWithoutCountry = language ? language.split('-')[0] : null;
  const text = translatableText || {};
  return text[languageWithoutCountry] || text.default || '';
};

const TranslatableText = ({ text }) => {
  return <span>{getTranslatedText(text)}</span>;
};

const EmptyBackgroundView = () => (
  <div>
    <LoadingComponent as='div' loading={true} length={60} margin={1} randomCoef={5} />
    <LoadingComponent as='div' loading={true} length={60} randomCoef={10} />
    <LoadingComponent as='div' loading={true} length={60} randomCoef={10} />
    <LoadingComponent as='div' loading={true} length={60} randomCoef={10} />
    <LoadingComponent as='div' loading={true} length={60} rows={3} randomCoef={10} margin={2} />
    <LoadingComponent as='div' loading={true} length={60} rows={5} randomCoef={10} margin={2} />
  </div>
);

const Background = ({ resumeData, t }) => {
  const { github, linkedin, stackoverflow } = (resumeData && resumeData.sourceData) || {};

  if (_.isEmpty(resumeData)) {
    return <EmptyBackgroundView />;
  }

  const hasSomethingInGithub = github && (!_.isEmpty(github.repositories) || !_.isEmpty(github.relevantFacts));

  const hasSomethingInLinkedin = linkedin && !_.isEmpty(linkedin.skills);

  const hasSomethingInStackoverflow = stackoverflow && !_.isEmpty(stackoverflow.relevantFacts);

  const hasSomethingInExpertise = hasSomethingInGithub || hasSomethingInLinkedin || hasSomethingInStackoverflow;
  return (
    <div className='profile-background'>
      {resumeData && !_.isEmpty(resumeData.experiences) && (
        <div>
          <Divider horizontal className='background-section-title'>
            {t('profile.resume.experience')}
          </Divider>
          <Experiences experiences={resumeData.experiences} t={t} />
        </div>
      )}
      {resumeData && !_.isEmpty(resumeData.education) && (
        <div>
          <Divider horizontal className='background-section-title'>
            {t('profile.resume.education')}
          </Divider>
          <Education education={resumeData.education} t={t} />
        </div>
      )}
      {hasSomethingInExpertise && (
        <div>
          <Divider horizontal className='background-section-title'>
            {t('profile.resume.expertise')}
          </Divider>
          {hasSomethingInLinkedin && <LinkedInExtract skills={linkedin.skills} t={t} />}
          {hasSomethingInStackoverflow && <StackOverflowExtract relevantFacts={stackoverflow.relevantFacts} t={t} />}
          {hasSomethingInGithub && (
            <GithubExtract repositories={github.repositories} relevantFacts={github.relevantFacts} t={t} />
          )}
        </div>
      )}
    </div>
  );
};

const capitalizeFirstLetter = (translatableText) => {
  const capitalizedTranslateableText = {};
  for (var key in translatableText) {
    capitalizedTranslateableText[key] = translatableText[key].charAt(0).toUpperCase() + translatableText[key].slice(1);
  }
  return capitalizedTranslateableText;
};

const Source = ({ source }) => {
  const { sourceId, url } = source;
  if (sourceId === 'linkedin' || sourceId === 'wttj' || sourceId === 'crunchbase') {
    return (
      <div className='source-link'>
        <a href={url} className='' target='_blank' rel='noreferrer noopener'>
          <div className='source-link-inner'>
            <img src={`/images/contact_logos/logolink_${sourceId}.svg`} alt={url} />
          </div>
        </a>
      </div>
    );
  }
  return null;
};

const WorkplacePopupRaw = (props) => {
  const { workplace, titleSecondPart, t } = props;
  if (!workplace || !workplace.data || !workplace.data.name) {
    return '';
  }
  const workplaceData = workplace.data || {};
  let workplaceType;
  if (workplaceData.types && workplaceData.types.length > 0) {
    workplaceType = workplaceData.types[0].name; // TODO Choose a type or display all ?
  }
  const { min: minEmployees, max: maxEmployees } = workplaceData.staffCount || {};
  const mainLocation = (workplaceData.mainLocation || {}).name;
  const formattedMainLocation = mainLocation ? capitalizeFirstLetter(mainLocation) : '';

  const formattedStack = getFormattedStack(workplaceData.stack);
  const description = getEnrichedText(getTranslatedText(workplaceData.description));
  const website =
    (titleSecondPart || {}).link || (_.findWhere((workplaceData || {}).sources, { sourceId: 'website' }) || {}).url;
  return (
    <Popup
      className='workplace-popup'
      basic
      hoverable
      position='bottom center'
      wide
      trigger={
        <span className='workplace-popup-trigger'>
          {website ? (
            <a href={website} target='_blank' rel='noopener noreferrer' className='card-link'>
              {workplaceData.name}
            </a>
          ) : (
            workplaceData.name
          )}
        </span>
      }
    >
      <div className='popup-first-line'>
        <div className='popup-title'>
          {workplaceData.name}
          {workplaceType && (
            <span>
              {' '}
              • <TranslatableText text={workplaceType} />
            </span>
          )}
        </div>
        {workplaceData.sources && (
          <div className='sources-container'>
            {_.map(_.sortBy(workplaceData.sources, 'sourceId'), (source) => (
              <Source key={source.sourceId} source={source} />
            ))}
          </div>
        )}
      </div>

      <div className='popup-subtitle'>
        <span>
          {workplaceData.foundedYear && (
            <span>
              {t('profile.resume.workplace.foundedIn')} {workplaceData.foundedYear}{' '}
            </span>
          )}
          {workplaceData.foundedYear && formattedMainLocation && <span> • </span>}
          {formattedMainLocation && <TranslatableText text={formattedMainLocation} />}
        </span>
        {_.isNumber(minEmployees) && _.isNumber(maxEmployees) && (
          <div className='workplace-staff-count'>
            {minEmployees !== maxEmployees
              ? t('profile.resume.workplace.employeesCountMinMax', { min: minEmployees, max: maxEmployees })
              : t('profile.resume.workplace.employeesCount', { employeesCount: minEmployees })}
          </div>
        )}
      </div>
      <div className='workplace-industries'>
        {_.map(
          workplaceData.industries,
          (industry) =>
            industry &&
            industry.name &&
            industry.name.default && (
              <Label key={industry.name.default} className='industry-label'>
                <TranslatableText text={industry.name} />
              </Label>
            ),
        )}
      </div>
      {formattedStack && formattedStack.length > 0 && (
        <div className='workplace-stack'>
          <span>
            <u>Stack:</u>
          </span>{' '}
          <Stack stack={workplaceData.stack} displaySoftSkills={false} />
        </div>
      )}
      <p className='workplace-description'>
        <CollapsibleEnrichedText
          t={t}
          charCost={1}
          lineCost={90}
          targetMaxCost={200}
          toleranceFactor={0.3}
          enrichedText={description}
          expandOnly={true}
        />
      </p>
    </Popup>
  );
};

export const WorkplacePopup = translate('translations')(WorkplacePopupRaw);


const SchoolPopup = (props) => {
  const { workplace, titleSecondPart, t } = props;
  if (!workplace || !workplace.data || !workplace.data.name) {
    return '';
  }
  const workplaceData = workplace.data || {};
  const schoolType = workplaceData.schoolType;

  return (
    <Popup
      className='workplace-popup'
      basic
      hoverable
      position='bottom center'
      wide
      trigger={<span className='workplace-popup-trigger'>{titleSecondPart.text}</span>}
    >
      <div className='popup-first-line'>
        <div className='popup-title'>
          {workplaceData.name}
          {schoolType && schoolType.name && (
            <span style={{fontWeight: "bold"}}>
              {' '}
              • <TranslatableText text={schoolType.name} />
            </span>
          )}
        </div>
        {workplaceData.sources && (
          <div className='sources-container'>
            {_.map(_.sortBy(workplaceData.sources, 'sourceId'), (source) => (
              <Source key={source.sourceId} source={source} />
            ))}
          </div>
        )}
      </div>
    </Popup>
  );
};

class BackgroundItemCard extends React.PureComponent {
  static defaultProps = {
    noCollapse: false,
  };
  state = {
    expanded: false,
  };

  getContent() {
    const { content } = this.props;
    if (_.isEmpty(content)) {
      return null;
    }
    if (_.isString(content)) {
      return <div className='card-description'>{content}</div>;
    }
    return (
      <div className='card-description'>
        <EnrichedText enrichedText={content} />
      </div>
    );
  }

  showMoreClicked(expanded) {
    this.setState({ expanded: !expanded });
  }

  renderContent() {
    return (
      <Grid.Row>
        <Grid.Column width={1} />
        <Grid.Column width={15}>{this.getContent()}</Grid.Column>
      </Grid.Row>
    );
  }

  renderTitle() {
    const { title, titleSecondPart, workplace, isEducation } = this.props;
    return (
      <div className='card-title'>
        {title}
        {titleSecondPart && titleSecondPart.text ? (
          <span>
            {title && <span> • </span>}
            {workplace && workplace.data && workplace.data.name ? (
              isEducation ? (
                <SchoolPopup workplace={workplace} titleSecondPart={titleSecondPart} />
              ) : (
                <WorkplacePopup workplace={workplace} titleSecondPart={titleSecondPart} />
              )
            ) : titleSecondPart.link ? (
              <a href={titleSecondPart.link} target='_blank' rel='noopener noreferrer' className='card-link'>
                {titleSecondPart.text}
              </a>
            ) : (
              titleSecondPart.text
            )}
          </span>
        ) : null}
      </div>
    );
  }

  renderCaret() {
    const { content } = this.props;
    const { expanded } = this.state;
    const expandable =
      content && ((_.isString(content) && content.trim()) || content.text || !_.isEmpty(content.children));
    const direction = expanded ? 'down' : 'up';
    return (
      <div className='show-more'>
        {this.renderSkills()}
        {expandable && <Icon className='card-caret' name={`caret ${direction}`} />}
      </div>
    );
  }

  renderSkills() {
    const { stack } = this.props;
    return <Stack stack={stack} displaySoftSkills={true} />;
  }

  renderPicture() {
    const { icon } = this.props;
    return (
      <Image
        className='card-company-image'
        src={icon || '/images/defaultCompanyPhoto.png'}
        onError={(e) => {
          e.target.src = '/images/defaultCompanyPhoto.png';
        }}
      />
    );
  }

  render() {
    const { subtitle, noCollapse, content } = this.props;
    const { expanded } = this.state;
    const expandable =
      content && ((_.isString(content) && content.trim()) || content.text || !_.isEmpty(content.children));
    const collapseClass = noCollapse || expanded ? 'expanded' : '';
    const className = (noCollapse ? 'no-collapse' : '') + (expandable ? ' expandable' : '');
    return (
      <div
        className={`background-item-card ${className} ${collapseClass}`}
        onClick={() => !_.isEmpty(content) && this.showMoreClicked(expanded)}
      >
        <div className='gutter'>{this.renderPicture()}</div>
        <div className='main'>
          <div className='card-header'>
            {!noCollapse && this.renderCaret()}
            {this.renderTitle()}
            <div className='card-subtitle'>{subtitle}</div>
          </div>
          <div className='card-content'>{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

export const EducationCard = translate('translations')(({ educationItem, t }) => {
  const startDate = educationItem.startDate && parseDate(educationItem.startDate);
  const endDate = educationItem.endDate && parseDate(educationItem.endDate);

  const formattedStartDate = (startDate && startDate.format('YYYY')) || '';
  const formattedEndDate = (endDate && endDate.format('YYYY')) || t('profile.resume.present');
  const duration = startDate && endDate && durationBetween(startDate, endDate);
  const formattedDuration = duration ? `(${duration})` : '';

  const subtitle = formattedStartDate
    ? formattedStartDate +
    (formattedEndDate ? ` – ${formattedEndDate}` : '') +
    (formattedEndDate && formattedDuration ? `  ${formattedDuration}` : '')
    : '';

  return (
    <BackgroundItemCard
      icon={educationItem.photoLink || '/images/defaultEducationPhoto.png'}
      title=''
      titleSecondPart={{
        text: educationItem.schoolName,
        link: educationItem.schoolWebsite,
      }}
      subtitle={subtitle}
      content={educationItem.degree}
      stack={educationItem.stack}
      workplace={educationItem.workplace}
      isEducation={true}
    />
  );
});

class Education extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.profileId !== this.props.profileId) {
      this.setState({ isOpened: false });
    }
  }
  componentWillMount() {
    const isOpened = (this.state || {}).isOpened || false;
    this.setState({ isOpened });
  }
  handleToggleEducation = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };
  render() {
    const displayToggle = (this.props.education || []).length > 3;
    const { isOpened } = this.state;
    const { education, t, profileId } = this.props;
    return (
      <div>
        {_.map(education, (educationItem, index) =>
          !displayToggle || (displayToggle && !isOpened && index < 3) || (displayToggle && isOpened) ? (
            <EducationCard key={index} educationItem={educationItem} t={t} profileId={profileId} />
          ) : null,
        )}
        {displayToggle ? (
          isOpened === false ? (
            <div className='background-toggle down' onClick={() => this.handleToggleEducation()}>
              {t('profile.contact.timeline.seeMore')}
            </div>
          ) : (
            <div className='background-toggle up' onClick={() => this.handleToggleEducation()}>
              {t('profile.contact.timeline.seeLess')}
            </div>
          )
        ) : null}
      </div>
    );
  }
}

export const Experience = translate('translations')(({ experience, t }) => {
  const startDate = experience.startDate && parseDate(experience.startDate);
  const endDate = experience.endDate && parseDate(experience.endDate);
  const formattedStartDate = (startDate && startDate.format('MMM YYYY')) || '';
  const formattedEndDate = (endDate && endDate.format('MMM YYYY')) || t('profile.resume.present');
  const duration = startDate && durationBetween(startDate, endDate);
  const formattedDuration = duration ? `(${duration})` : '';

  const leftPart = formattedStartDate
    ? formattedStartDate +
    (formattedEndDate ? ` – ${formattedEndDate}` : '') +
    (formattedEndDate && formattedDuration ? `  ${formattedDuration}` : '')
    : '';
  const rightPart = _.isObject(experience.location)
    ? (experience.location.name || {}).default || ''
    : _.isString(experience.location)
      ? experience.location
      : '';
  const subtitle = leftPart + (leftPart && rightPart ? ' • ' : '') + rightPart;

  return (
    <BackgroundItemCard
      icon={experience.photoLink || '/images/defaultCompanyPhoto.png'}
      title={experience.title}
      titleSecondPart={{
        text: experience.companyName,
        link: experience.companyWebsite,
      }}
      subtitle={subtitle}
      content={experience.description}
      stack={experience.stack}
      workplace={experience.workplace}
    />
  );
});

class Experiences extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.profileId !== this.props.profileId) {
      this.setState({ isOpened: false });
    }
  }
  componentWillMount() {
    const isOpened = (this.state || {}).isOpened || false;
    this.setState({ isOpened });
  }
  handleToggleExperiences = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };
  render() {
    const displayToggle = (this.props.experiences || []).length > 5;
    const { isOpened } = this.state;
    const { experiences, t, profileId } = this.props;
    return (
      <div>
        {_.map(experiences, (experience, index) =>
          !displayToggle || (displayToggle && !isOpened && index < 4) || (displayToggle && isOpened) ? (
            <Experience key={index} experience={experience} t={t} profileId={profileId} />
          ) : null,
        )}
        {displayToggle ? (
          isOpened === false ? (
            <div className='background-toggle down' onClick={() => this.handleToggleExperiences()}>
              {t('profile.contact.timeline.seeMore')}
            </div>
          ) : (
            <div className='background-toggle up' onClick={() => this.handleToggleExperiences()}>
              {t('profile.contact.timeline.seeLess')}
            </div>
          )
        ) : null}
      </div>
    );
  }
}

const GithubExtract = ({ relevantFacts, repositories }) => {
  return (
    <div className='github-extract'>
      <h4>GitHub</h4>
      <Container fluid>
        {_.map(repositories, ({ name, fullname, description, language, stargazersCount, forksCount }, index) => (
          <RepositoryCard
            key={index}
            name={name}
            fullname={fullname}
            description={description}
            language={language}
            stargazersCount={stargazersCount}
            forksCount={forksCount}
          />
        ))}
        {relevantFacts && <RelevantFacts facts={relevantFacts} />}
      </Container>
    </div>
  );
};

const LinkedInExtract = ({ skills }) => {
  return (
    <div className='linkedin-extract'>
      <Container className='skill-tags-container' fluid>
        <h4>Skills</h4>
        <Label.Group className='skill-tags'>
          {_.map(skills, (skill, index) => (
            <SkillTag
              key={index}
              label={skill.label}
              endorsementsCount={skill.endorsementsCount}
              relevance={skill.relevance}
            />
          ))}
        </Label.Group>
      </Container>
    </div>
  );
};

const SkillTag = ({ label, endorsementsCount, relevance }) => {
  // const opacity = (relevance + 28) / 40;
  const className = `skill-tag${relevance > 0 ? ' important' : ' normal'}`;
  return <Label className={className} detail={endorsementsCount} content={label.default} />;
};

export const RelevantFact = ({ label, content, isNotFirstRow, isNotFirstColumn }) => {
  const className = `relevant-fact-container${isNotFirstRow ? ' not-first-row' : ''}${isNotFirstColumn ? ' not-first-column' : ''
    }`;
  return (
    <Container className={className} textAlign='center' fluid>
      <Statistic className='relevant-fact'>
        <Statistic.Value>{getTranslatedText(label)}</Statistic.Value>
        <Statistic.Label>{getTranslatedText(content)}</Statistic.Label>
      </Statistic>
    </Container>
  );
};

const RelevantFacts = ({ facts }) => {
  const factsGroups = _.groupBy(facts, (__, i) => Math.floor(i / 2));

  return (
    <Grid className='relevant-facts' padded>
      {_.map(factsGroups, (group, index) => (
        <Grid.Row key={index}>
          <Grid.Column width={8}>
            {group.length >= 1 && (
              <RelevantFact label={group[0].title} content={group[0].description} isNotFirstRow={index > 0} />
            )}
          </Grid.Column>
          <Grid.Column width={8}>
            {group.length >= 2 && (
              <RelevantFact
                label={group[1].title}
                content={group[1].description}
                isNotFirstRow={index > 0}
                isNotFirstColumn
              />
            )}
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

export const RepositoryCard = ({ name, fullname, description, language, stargazersCount, forksCount }) => {
  const langColor = (GITHUB_COLORS[language || 'JavaScript'] || { color: 'blue' }).color;
  return (
    <Segment className='repository-card'>
      <Header className='repo-stats' floated='right' size='small'>
        {language && (
          <span className='language'>
            <div
              style={{
                display: 'inline-block',
                marginBottom: '-1px',
                marginRight: '4px',
                width: '14px',
                height: '14px',
                borderRadius: '50%',
                backgroundColor: langColor,
              }}
            />
            <span className='text'>{language}</span>
          </span>
        )}
        {stargazersCount !== undefined && stargazersCount > 0 && (
          <span className='stars'>
            <Icon name='star' />
            <span className='text'>{stargazersCount}</span>
          </span>
        )}

        {forksCount !== undefined && forksCount > 0 && (
          <span className='forks'>
            <Icon name='fork' />
            <span className='text'>{forksCount}</span>
          </span>
        )}
      </Header>
      <Header className='repo-title' floated='left' size='small'>
        <a
          className='repo-link'
          href={`https://github.com/${_.isObject(fullname) ? fullname.text : fullname}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {_.isObject(name) ? name.text : name}
        </a>
      </Header>

      <Divider clearing hidden fitted />

      <p className='description'>{_.isObject(description) ? description.text : description}</p>
    </Segment>
  );
};

const StackOverflowExtract = ({ relevantFacts }) => {
  return (
    <div className='stackoverflow-extract'>
      <h4>Stack Overflow</h4>
      <RelevantFacts facts={relevantFacts} />
    </div>
  );
};

const EnrichedText = ({ enrichedText }) => (
  <CollapsibleEnrichedText
    lineCost={0}
    charCost={0}
    targetMaxCost={10000}
    toleranceFactor={1000}
    enrichedText={enrichedText}
  />
);

const SchoolTypes = ({ education }) => {
  const schoolNames = _.compact(_.pluck(education, 'schoolName'));
  const hasSchoolNames = !_.isEmpty(schoolNames);
  if (!hasSchoolNames) {
    return null;
  }
  const schoolTypeNames = _.compact(_.map(education, ({ workplace }) => (((((workplace || {}).data) || {}).schoolType || {}).name)));
  return (
    <div>
      <Popup
        trigger={<Icon size="small" circular inverted color="blue" name="pencil" />}
        content={<p>To annotate school types go to :<br/>Right pane > "Hiresweet Data" > "Annotations"</p>}
      />
      <span style={{fontFamily: 'SourceSansProSemiBold'}}>School types :</span>
      {_.map(schoolTypeNames, (name, i) => (
        <span index={`school-type-${i}`} >
          {i==0 ? ' ' : ' • '}
          <TranslatableText text={name} />
        </span>
      ))}
      {_.isEmpty(schoolTypeNames) && (
        <span style={{color:"red"}}>NO SCHOOL TYPES</span>
      )}
      {' '}
    </div>
  )


}

const getTextWithoutHTMLEntities = (text) => {
  const step1 = text.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
  const step2 = step1.replace(/&quot;/g, '"');
  const step3 = step2.replace(/&amp;/g, '&');
  const step4 = step3.replace(/&nbsp;/g, ' ');
  return step4;
};

const getEnrichedText = (string) => {
  if (string && string.type && _.contains(['raw', 'paragraph'], string.type)) {
    return string;
  }
  if (!string || !_.isString(string) || String(string).trim() === '') {
    return { type: null, text: null, children: null };
  }
  if (_.indexOf(string, '\n') > -1) {
    const paragraphs = getTextWithoutHTMLEntities(string).split('\n');
    return {
      type: 'paragraph',
      text: null,
      children: _.map(paragraphs, (p) => ({
        type: 'raw',
        text: p,
        children: null,
      })),
    };
  } else {
    // HACK cut if string too long ?
    return {
      type: 'raw',
      text: getTextWithoutHTMLEntities(string),
      children: null,
    };
  }
};

const wrapSummary = (summary) => {
  const summaryStr = _.isString(((summary || {}).content || {}).text)
    ? summary.content.text
    : _.isString((summary || {}).content)
      ? summary.content
      : '';

  const summaryStack = _.isObject(summary) && summary.stack;
  return {
    content: getEnrichedText(summaryStr),
    ...(summaryStack && { stack: summaryStack }),
  };
};

class ClientProfile extends React.PureComponent {
  state = {
    lng: null,
  };
  handleChangeLanguage(lng) {
    this.setState({ lng });
  }

  render() {
    const { profile, withSchoolTypes, neutralMode, t } = this.props;
    const { lng } = this.state;
    if (lng) {
      const { language } = i18n;
      const languageWithoutCountry = language ? language.split('-')[0] : null;
      if (lng && lng !== languageWithoutCountry) {
        i18n.changeLanguage(lng);
      }
    }

    const transformedData = profile &&
      profile.data && {
      ...profile.data,
      summary: wrapSummary(profile.data.summary),
      experiences: _.map((profile.data || {}).experiences, (exp) => ({
        ...exp,
        ...(exp.title && {
          title: _.isObject(exp.title) ? exp.title.text : exp.title,
        }),
        ...(exp.description && {
          description: getEnrichedText(_.isObject(exp.description) ? exp.description.text : exp.description),
        }),
      })),
      education: _.map((profile.data || {}).education, (edu) => ({
        ...edu,
        ...(edu.degree && {
          degree: _.isObject(edu.degree) ? edu.degree.text : edu.degree,
        }),
        ...(edu.description && {
          description: getEnrichedText(_.isObject(edu.description) ? edu.description.text : edu.description),
        }),
      })),
    };

    const smartSummaryArgumentation = ((transformedData || {}).smartSummary || {}).arguments;

    const isSummarySuspicious = (summary) => {
      const summaryStr = _.isString(summary) ? summary : _.isObject(summary) ? summary.content || '' : '';
      if (!summaryStr || !_.isString(summaryStr)) {
        return false;
      }
      const normalizedSummary = summaryStr.trim();
      const lines = normalizedSummary.split('\n');
      let isSuspicious = false;
      _.each(lines, (line) => {
        if (line.indexOf('      ') === 0) {
          isSuspicious = true;
        }
      });
      if (summaryStr && summaryStr.length > 3 && summaryStr.substr(summaryStr.length - 3) === '...') {
        isSuspicious = true;
      }
      return isSuspicious;
    };

    const isSuspicious = isSummarySuspicious(profile.data.summary || '');
    const isWatchProfile = ((transformedData || {}).prequalification || {}).type === 'hiresweet-watch';
    const shouldDisplaySummary =
      transformedData &&
      !_.isEmpty(transformedData.summary) &&
      !_.isEmpty(transformedData.summary.content) &&
      (!_.isEmpty(transformedData.summary.content.text) || !_.isEmpty(transformedData.summary.content.children));
    const shouldDisplaySmartSummary = _.isArray(smartSummaryArgumentation) && !_.isEmpty(smartSummaryArgumentation);
    const shouldDisplayTags = transformedData && !_.isEmpty(transformedData.relevantTags);
    const shouldDisplayStack = !_.isEmpty((transformedData.summary || {}).stack);
    const hiresweetData = (((profile || {}).data || {}).sourceData || {}).hiresweet || {};
    const education = (transformedData || {}).education

    return (
      <div className='profile-resume-container'>
        <Grid
          style={{
            padding: 0,
            margin: 0,
            width: '100px',
            position: 'absolute',
            top: '2vh',
            zIndex: 1,
            textAlign: 'center',
          }}
        >
          <Grid.Row style={{ padding: '5px', margin: 0 }}>
            <Grid.Column width={4} style={{ padding: 0, margin: 0 }}>
              <span style={{ opacity: lng !== 'en' ? '1' : '0.3' }}>Fr</span>
            </Grid.Column>
            <Grid.Column width={8} style={{ padding: 0, margin: 0 }}>
              <Checkbox
                toggle
                checked={lng === 'en'}
                onChange={(e, { checked }) => this.handleChangeLanguage(checked ? 'en' : 'fr')}
              />
            </Grid.Column>
            <Grid.Column width={4} style={{ padding: 0, margin: 0 }}>
              <span style={{ padding: '5px', opacity: lng === 'en' ? '1' : '0.3' }}>En</span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className='resume-data-card'>
          <ProfileHeader resumeData={transformedData} t={t} />
          {withSchoolTypes && (
            <SchoolTypes education={education} />
          )}
          {shouldDisplaySmartSummary && (
            <SmartSummary
              isWatchProfile={isWatchProfile}
              argumentation={smartSummaryArgumentation}
              t={t}
              skills={getSortedSkillsWishes({ hiresweetData })}
              neutralMode={neutralMode}
            />
          )}
          {transformedData && (shouldDisplayStack || shouldDisplaySummary || shouldDisplayTags) && (
            <div className='summary-tag-stack-container'>
              {shouldDisplaySummary && (
                <SummaryAndStack
                  isWatchProfile={isWatchProfile}
                  summary={transformedData.summary}
                  isSuspicious={isSuspicious}
                  shouldDisplayStack={shouldDisplayStack}
                  t={t}
                />
              )}
              <LoadingComponent as='div' loading={_.isEmpty(transformedData)} length={60} margin={1}>
                {transformedData && !_.isEmpty(transformedData.relevantTags) && (
                  <RelevantTags relevantTags={transformedData.relevantTags} />
                )}
              </LoadingComponent>
            </div>
          )}
        </div>
        <Background resumeData={transformedData} t={t} />
      </div>
    );
  }
}

const getSortedSkillsWishes = ({ hiresweetData }) => {
  const skills = ((hiresweetData || {}).wishes || {}).skills;
  const target = (skills || {}).target;
  const okWith = (skills || {}).okWith;
  const mergedWishes = [...(target || []), ...(okWith || [])];
  return _.sortBy(mergedWishes, ({ relevance }) => -relevance);
};

export default translate('translations')(ClientProfile);
