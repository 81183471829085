import { Button, Grid, Input as SInput, Segment, Tab } from 'semantic-ui-react';
import {Input, Select, SweetForm} from 'sweetform';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Criteria } from '../components/OfferV5';
import EditDataPointAdminModal from './EditDataPointAdminModal';
import EditFormattedJobModal from './EditFormattedJobModal';
import EditFormattedProfileModal from './EditFormattedProfileModal';
import EditTextualJobModal from './EditTextualJobModal';
import { EnrichedJobPosting } from '../JobParser';
import EnrichedProfileView from '../LabelizedData/DataPointView/EnrichedProfileView';
import LinkedinEditorModal from '../DataPointSandbox/LinkedinEditorModal';
import LinkedinProfileView from '../SweetComponents/LinkedinProfile/View';
import XG1FormattedJob from './XG1FormattedJob.jsx';
import XG1FormattedProfile from './XG1FormattedProfile.jsx';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import formatJob from './formatJob';
import formatProfile from './formatProfile';
import queryString from 'query-string';

const getColor = (dataPoint) => {
  const { label} = dataPoint
  if (label=="A"){
    return "green"
  } else if (label=="E"){
    return "red"
  } else {
    return "black"
  }
}

const DataPointDisplay = ({ dataPoint }) => (
  _.isEmpty(dataPoint) ? <div>Empty</div> : (
    <Segment>{/* color={getColor(dataPoint)}> */}
      <b>id: </b> {dataPoint.id} <br />
      {/*<b>label: </b> <span style={{color:getColor(dataPoint)}}>{dataPoint.label} </span><br />*/}
      <b>comments: </b><br /> {(dataPoint.admin || {}).comment}
    </Segment>
  )
)

class XG1Sandbox extends Component {
  state = {
    edition: null,
    dataPointSetId: null,
    dataPoint: {},
    profile: {},
    job: {},
    formattedProfile: {},
    formattedJob: {},
    linkedinProfile: {},
    textualJob: {},
  };

  componentDidMount = async () => {
    const {
      initialDataPointId,
      initialDataPointSetId,
    } = this.props;

    const getInitialData = async () => {
      let initialProfile, initialLinkedinProfile, initialJob, initialTextualJob, initialDataPoint;

      if (initialDataPointId && initialDataPointSetId) {
        const { data } = await axios(
          `${baseUrl}/xg1poc/${initialDataPointSetId}/dataPoint/${initialDataPointId}`,
        );
        const { dataPoint, profile, linkedinProfile, job, textualJob } = data;
        initialDataPoint = dataPoint
        initialJob = job;
        initialLinkedinProfile = linkedinProfile;
        initialProfile = profile;
        initialTextualJob = textualJob;
      }
      return { initialDataPoint, initialJob, initialTextualJob, initialProfile, initialLinkedinProfile };
    };
    try {

    } catch(e){

    }

    try {
      if (_.isEmpty(initialDataPointId)){
        this.handleSampleXG1PocDataPoint()
      } else {
        const {
          initialDataPoint, initialJob, initialTextualJob, initialProfile, initialLinkedinProfile
        } = await getInitialData();
        let formattedProfile, formattedJob;
        this.setState({ dataPointSetId: initialDataPointSetId })
        if (initialProfile) {
          formattedProfile = formatProfile(initialProfile)
          await new Promise((resolve) => {
            this.setState({ profile: initialProfile, formattedProfile }, () => { resolve(); });
          });
        }
        if (initialLinkedinProfile) {
          await new Promise((resolve) => {
            this.setState({ linkedinProfile: initialLinkedinProfile }, () => { resolve(); });
          });
        }
        if (initialJob) {
          formattedJob = formatJob(initialJob)
          await new Promise((resolve) => {
            this.setState({ job: initialJob, formattedJob }, () => { resolve(); });
          });
        }
        if (initialTextualJob) {
          await new Promise((resolve) => {
            this.setState({ textualJob: initialTextualJob }, () => { resolve(); });
          });
        }
        if (initialDataPoint) {
          await new Promise((resolve) => {
            this.setState({ dataPoint: initialDataPoint }, () => { resolve(); });
          });
        }
        if (initialProfile || initialJob || initialTextualJob || initialLinkedinProfile) {
          this.handleRecomputeEnrichedData()
        }
      }
      
    } catch (e) {
      return alert(e.message);
    }
  }

  
  handleSampleXG1PocDataPoint = async () => {
    const {hasComment, jobId, label} = this.state.sampleParams || {};
    const { data } = await axios.post(
      `${baseUrl}/xg1poc/xg1poc/sampleDataPoint`,
      { hasComment, jobId, label }
    );
    const { dataPoint, profile, job } = data;
    const formattedProfile = formatProfile(profile)
    await new Promise((resolve) => {
      this.setState({ profile, formattedProfile }, () => { resolve(); });
    });
    const formattedJob = formatJob(job)
    await new Promise((resolve) => {
      this.setState({ job, formattedJob }, () => { resolve(); });
    });
    await new Promise((resolve) => {
      this.setState({ dataPoint }, () => { resolve(); });
    });
    this.setState({ dataPointSetId: "xg1poc" })
    this.handleRecomputeEnrichedData();
  }

  handleSaveDataPointAdmin = async ({ admin }) => {
    try {
      const { data } = await axios.put(
        `${baseUrl}/xg1poc/${this.state.dataPointSetId}/dataPoint/${this.state.dataPoint.id}/admin`,
        admin
      );
      if (data.error) {
        throw Error(data.error)
      }
      this.setState({
        dataPoint: {
          ...this.state.dataPoint,
          admin
        }
      })
      this.handleCloseEdition();
    } catch (e) {
      alert(e.message)
    }
  }

  getEnrichedProfile = async ({ formattedProfile, linkedinProfile }) => {
    try {
      const { data } = await axios.post(`${baseUrl}/xg1poc/getEnrichedProfile`, {
        xg1Profile: formattedProfile,
        linkedinProfile,
        ...this.state.sandboxAppUrl && ({ sandboxAppUrl: this.state.sandboxAppUrl })
      })
      if ((data || {}).error) {
        console.log(data)
        throw Error(data.error)
      }
      return data;
    } catch (e) {
      console.log(e)
      alert("Fail /getEnrichedProfile : " + e.message)
    }
  }

  getEnrichedJobPosting = async ({ formattedJob, textualJob }) => {
    try {
      const { data } = await axios.post(`${baseUrl}/xg1poc/getEnrichedJobPosting`, {
        xg1Job: formattedJob,
        textualJob,
        ...this.state.sandboxAppUrl && ({ sandboxAppUrl: this.state.sandboxAppUrl })
      })
      if ((data || {}).error) {
        console.log(data)
        throw Error(data.error)
      }
      return data;
    } catch (e) {
      console.log(e)
      alert("Fail /getEnrichedJobPosting : " + e.message)
    }
  }

  getScoreResult = async ({ formattedJob, textualJob, linkedinProfile, formattedProfile }) => {
    try {
      const { data } = await axios.post(`${baseUrl}/xg1poc/scoreProfileJob`, {
        job: {
          xg1Job: formattedJob,
          textualJob,
        },
        profile: {
          xg1Profile: formattedProfile,
          linkedinProfile,
        },
        ...this.state.sandboxAppUrl && ({ sandboxAppUrl: this.state.sandboxAppUrl })
      })
      if ((data || {}).error) {
        console.log(data)
        throw Error(data.error)
      }
      return data;
    } catch (e) {
      console.log(e)
      alert("Fail /scoreProfileJob : " + e.message)
    }
  }

  handleSaveLinkedinProfile = async ({ data }) => {
    this.setState({ linkedinProfile: data }, () => this.handleRecomputeEnrichedData());
    this.handleCloseEdition();
  }

  handleSaveTextualJob = async ({ data }) => {
    this.setState({ textualJob: data }, () => this.handleRecomputeEnrichedData());
    this.handleCloseEdition();
  }

  handleSaveFormattedProfile = async ({ data }) => {
    this.setState({ formattedProfile: data }, () => this.handleRecomputeEnrichedData());
    this.handleCloseEdition();
  }

  handleSaveFormattedJob = async ({ data }) => {
    this.setState({ formattedJob: data }, () => this.handleRecomputeEnrichedData());
    this.handleCloseEdition();
  }

  handleRecomputeEnrichedData = async () => {
    const [dataEnrichedJobPosting, dataEnrichedProfile, dataScore] = await Promise.all([
      this.getEnrichedJobPosting({
        formattedJob: this.state.formattedJob,
        textualJob: this.state.textualJob,
      }),
      this.getEnrichedProfile({
        formattedProfile: this.state.formattedProfile,
        linkedinProfile: this.state.linkedinProfile,
      }),
      this.getScoreResult({
        formattedJob: this.state.formattedJob,
        textualJob: this.state.textualJob,
        formattedProfile: this.state.formattedProfile,
        linkedinProfile: this.state.linkedinProfile,
      }),
    ]);
    const {criteria, enrichedJobPosting} = dataEnrichedJobPosting || {};
    const { enrichedProfile } = dataEnrichedProfile || {}
    const { result } = dataScore || {}
    console.log("enrichedProfile", enrichedProfile)
    console.log("enrichedJobPosting", enrichedJobPosting)
    console.log("criteria", criteria)
    console.log("result", result)
    this.setState({
      enrichedProfile,
      enrichedJobPosting,
      criteria,
      score: result
    });
  }


  handleOpenEditDataPointAdmin = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: "data-point-admin",
        initialValue: this.state.dataPoint.admin
      }
    })
  }

  handleOpenEditLinkedinProfile = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: "linkedin-profile",
        initialValue: this.state.linkedinProfile
      }
    })
  }

  handleOpenEditTextualJob = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: "textual-job",
        initialValue: this.state.textualJob
      }
    })
  }

  handleOpenEditFormattedProfile = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: "formatted-profile",
        initialValue: this.state.formattedProfile
      }
    })
  }

  handleOpenEditFormattedJob = () => {
    this.setState({
      edition: {
        key: '' + Math.random(),
        type: "formatted-job",
        initialValue: this.state.formattedJob
      }
    })
  }
  handleCloseEdition = () => {
    this.setState({ edition: null })
  }

  handleSandboxAppUrlChange = (e, { value }) => {
    this.setState({ sandboxAppUrl: value });
  }

  renderProfileTabs() {
    const panes = [
      ...!_.isEmpty(this.state.formattedProfile) ? [{
        menuItem: 'Formatted Profile',
        render: () => (
          <Tab.Pane>
            <XG1FormattedProfile formattedProfile={this.state.formattedProfile} />
          </Tab.Pane>
        )
      }] : [],
      ...!_.isEmpty(this.state.enrichedProfile) ? [{
        menuItem: 'Enriched Profile',
        render: () => (
          <Tab.Pane>
            <EnrichedProfileView enrichedProfile={this.state.enrichedProfile} sourceProfiles={{}} />
          </Tab.Pane>
        )
      }] : [],
      ...!_.isEmpty(this.state.profile) ? [{
        menuItem: 'Raw JSON',
        render: () => (
          <Tab.Pane>
            <pre>{JSON.stringify(this.state.profile, null, 4)}</pre>
          </Tab.Pane>
        )
      }] : [],
     
    ];
    return <Tab panes={panes} />
  }

  renderScore = () => {
    const { item } = this.state.score || {}
    return (
      <center>
       <h2>{Math.round(item * 10000) / 100}%</h2>
    </center>
    )
  }

  renderJobTabs() {
    const panes = [
      ...!_.isEmpty(this.state.formattedJob) ? [{
        menuItem: 'Formatted Job',
        render: () => (
          <Tab.Pane>
            <XG1FormattedJob formattedJob={this.state.formattedJob} />
          </Tab.Pane>
        )
      }] : [],
      ...!_.isEmpty(this.state.enrichedJobPosting) ? [{
        menuItem: 'Enriched Job Posting',
        render: () => (
          <Tab.Pane>
            <EnrichedJobPosting enrichedJobPosting={this.state.enrichedJobPosting} />
          </Tab.Pane>
        )
      }] : [],
      ...!_.isEmpty(this.state.criteria) ? [{
        menuItem: 'Criteria',
        render: () => (
          <Tab.Pane>
            <Criteria criteria={this.state.criteria} />
          </Tab.Pane>
        )
      }] : [],
      ...!_.isEmpty(this.state.job) ? [{
        menuItem: 'Raw JSON',
        render: () => (
          <Tab.Pane>
            <pre>{JSON.stringify(this.state.job, null, 4)}</pre>
          </Tab.Pane>
        )
      }] : [],
      ...!_.isEmpty(this.state.textualJob) ? [{
        menuItem: 'Texual Job',
        render: () => (
          <Tab.Pane>
            <pre>{JSON.stringify(this.state.textualJob, null, 4)}</pre>
          </Tab.Pane>
        )
      }] : [],
    ];
    return <Tab panes={panes} />
  }

  handleChangeSampleParams = async ({hasComment, jobId, label}) => {
    this.setState({sampleParams: {hasComment, jobId, label}})
  }

  render() {
    const { edition } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <DataPointDisplay dataPoint={this.state.dataPoint} />
            {this.state.dataPointSetId == "xg1poc" && (
              <a onClick={() => (this.handleOpenEditDataPointAdmin())} style={{ cursor: 'pointer' }}>Modify data point</a>
            )}
          </Grid.Column>
          <Grid.Column width={6}>
            {this.renderScore()}
          </Grid.Column>
          <Grid.Column width={2}>

            <SweetForm key={'sample'} onChange={this.handleChangeSampleParams}>
              <Select field='hasComment' options={[{label: "With Comment", value: true}, {label: "Without comment", value: false}]}/>
              <Input field='jobId' placeholder="jobId"/>
              <Input field='label' placeholder="label" />
            </SweetForm>
            <Button onClick={()=>this.handleSampleXG1PocDataPoint({})}>Sample</Button>

          </Grid.Column>
          <Grid.Column width={4}>
            <Button icon="refresh" onClick={() => this.handleRecomputeEnrichedData()} />
            <SInput onChange={this.handleSandboxAppUrlChange} placeholder="SANDBOX APP URL" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            {this.state.dataPointSetId == "xg1poc" && (
              <a onClick={() => (this.handleOpenEditFormattedProfile())} style={{ cursor: 'pointer' }}>Edit formatted profile</a>
            )}
            {this.state.dataPointSetId == "xg1" && (
              <a onClick={() => (this.handleOpenEditLinkedinProfile())} style={{ cursor: 'pointer' }}>Edit linkedin profile</a>
            )}
            {this.renderProfileTabs()}
          </Grid.Column>
          <Grid.Column width={8}>
            {this.state.dataPointSetId == "xg1poc" && (
              <a onClick={() => (this.handleOpenEditFormattedJob())} style={{ cursor: 'pointer' }}>Edit formatted job</a>
            )}
            {this.state.dataPointSetId == "xg1" && (
              <a onClick={() => (this.handleOpenEditTextualJob())} style={{ cursor: 'pointer' }}>Edit textual job</a>
            )}
            {this.renderJobTabs()}
          </Grid.Column>
        </Grid.Row>
        {(edition || {}).type === 'data-point-admin' && (
          <EditDataPointAdminModal
            key={edition.key}
            onClose={this.handleCloseEdition}
            onSave={this.handleSaveDataPointAdmin}
            initialValue={edition.initialValue}
          />
        )}
        {(edition || {}).type === 'formatted-profile' && (
          <EditFormattedProfileModal
            key={edition.key}
            onClose={this.handleCloseEdition}
            onSave={this.handleSaveFormattedProfile}
            initialValue={edition.initialValue}
          />
        )}
        {(edition || {}).type === 'formatted-job' && (
          <EditFormattedJobModal
            key={edition.key}
            onClose={this.handleCloseEdition}
            onSave={this.handleSaveFormattedJob}
            initialValue={edition.initialValue}
          />
        )}
        {(edition || {}).type === 'linkedin-profile' && (
          <LinkedinEditorModal
            key={edition.key}
            onClose={this.handleCloseEdition}
            onSave={this.handleSaveLinkedinProfile}
            initialValue={edition.initialValue}
          />
        )}
        {(edition || {}).type === 'textual-job' && (
          <EditTextualJobModal
            key={edition.key}
            onClose={this.handleCloseEdition}
            onSave={this.handleSaveTextualJob}
            initialValue={edition.initialValue}
          />
        )}
      </Grid>
    )
  }
}

const withInitialQuery = (WrappedComponent) =>
  class extends React.Component {
    render() {
      return (
        <Switch>
          <Route exact path='/ml/old/xg1poc'>
            {({ location }) => {
              const queryValues = queryString.parse(location.search);
              const initialDataPointId = queryValues.dataPoint;
              const initialDataPointSetId = queryValues.dataPointsSet;

              return (
                <WrappedComponent
                  initialDataPointSetId={initialDataPointSetId}
                  initialDataPointId={initialDataPointId}
                />
              );
            }}
          </Route>
        </Switch>
      );
    }
  };

export default withInitialQuery(XG1Sandbox);
