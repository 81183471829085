import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Table, Statistic } from 'semantic-ui-react';

import baseUrl from '../baseUrl.js';

const UsersTable = ({ users, offers }) => {
  const userFromUserId = _.indexBy(users, 'name');
  const statsFromUserId = _.mapObject(
    _.groupBy(offers, 'owner'),
    (offers, userId) => {
      const { totalProfilesPerDay, totalDaysAhead } = _.reduce(
        offers,
        (memo, offer) => ({
          totalProfilesPerDay:
            memo['totalProfilesPerDay'] + (offer.candidates || 5),
          totalDaysAhead: memo['totalDaysAhead'] + (offer.nbDaysAhead || 0),
        }),
        {
          totalProfilesPerDay: 0,
          totalDaysAhead: 0,
        },
      );
      const meanDaysAhead =
        Math.floor((totalDaysAhead * 10) / (offers.length || 1)) / 10;
      return {
        offers,
        nbOffers: offers.length,
        meanDaysAhead,
        totalProfilesPerDay,
        userId,
      };
    },
  );
  const {
    totalProfilesPerDayAllUsers,
    totalNbOffers,
    totalMeanDaysAhead,
  } = _.reduce(
    statsFromUserId,
    (memo, { totalProfilesPerDay, nbOffers, meanDaysAhead }) => ({
      totalProfilesPerDayAllUsers:
        memo.totalProfilesPerDayAllUsers + totalProfilesPerDay,
      totalNbOffers: memo.totalNbOffers + nbOffers,
      totalMeanDaysAhead: memo.totalMeanDaysAhead + meanDaysAhead,
    }),
    {
      totalProfilesPerDayAllUsers: 0,
      totalNbOffers: 0,
      totalMeanDaysAhead: 0,
    },
  );
  const globalMeanDaysAhead =
    Math.floor(
      (totalMeanDaysAhead * 10) / (_.keys(statsFromUserId).length || 1),
    ) / 10;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width="3" />
        <Grid.Column width="10">
          <Table
            celled
            compact="very"
            textAlign="center"
            style={{ padding: 0, margin: 0 }}
          >
            <Table.Header>
              <Table.HeaderCell
                style={{ backgroundColor: '#0264C0', color: 'white' }}
              >
                User Ops
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: '#0264C0', color: 'white' }}
              >
                Profiles / Day
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: '#0264C0', color: 'white' }}
              >
                #Offers
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: '#0264C0', color: 'white' }}
              >
                Mean Days Ahead
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: '#0264C0', color: 'white' }}
              >
                State
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(
                _.sortBy(statsFromUserId, 'nbOffers').reverse(),
                (
                  {
                    offers,
                    nbOffers,
                    totalProfilesPerDay,
                    meanDaysAhead,
                    userId,
                  },
                  index,
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {(userFromUserId[userId] || {}).name || (
                        <span style={{ color: 'orange' }}>{userId}</span>
                      )}
                    </Table.Cell>
                    <Table.Cell>{totalProfilesPerDay}</Table.Cell>
                    <Table.Cell>
                      <span
                        title={_.map(
                          offers,
                          (offer) => `${offer.title} (${offer.companyId})`,
                        ).join(',\n')}
                      >
                        {nbOffers}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <span
                        style={{
                          color:
                            meanDaysAhead < 2
                              ? 'red'
                              : meanDaysAhead < 3
                              ? 'orange'
                              : 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {meanDaysAhead}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <span
                        style={{
                          color:
                            (userFromUserId[userId] || {}).staffingLevel ===
                            'full'
                              ? 'red'
                              : (userFromUserId[userId] || {}).staffingLevel ===
                                'medium'
                              ? 'orange'
                              : 'green',
                        }}
                      >
                        {(userFromUserId[userId] || {}).staffingLevel}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
            <Table.Footer>
              <Table.HeaderCell
                style={{
                  backgroundColor: '#51A7F9',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                Total / Average
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  backgroundColor: '#51A7F9',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                {totalProfilesPerDayAllUsers}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  backgroundColor: '#51A7F9',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                {totalNbOffers}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  backgroundColor: '#51A7F9',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                {globalMeanDaysAhead}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  backgroundColor: '#51A7F9',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            </Table.Footer>
          </Table>
        </Grid.Column>
        <Grid.Column width="3" />
      </Grid.Row>
    </Grid>
  );
};

class UsersView extends Component {
  // componentWillMount() {
  //   this.state = { users: [], offers: [] };
  //   this.onLoadUsers();
  //   this.onLoadOffers();
  // }

  onLoadUsers = async () => {
    const { data } = await axios.get(`${baseUrl}/users`);
    this.setState({ users: data });
  };

  onLoadOffers = async () => {
    const { data } = await axios.get(`${baseUrl}/flowboard`, {});
    this.setState({
      offers: _.filter(data, ({ state }) => state === 'active'),
    });
  };
  render() {
    const { offers: unfilteredOffers, users } = this.props;
    const offers = _.filter(unfilteredOffers, ({ state }) => state ==='active');
    const defaultStats = {
      nbProfilesPerDay: 0,
      nbDaysAhead: 0,
    };
    const { nbProfilesPerDay, nbDaysAhead } =
      offers && offers.length > 0
        ? _.reduce(
            offers,
            (memo, i) => ({
              nbProfilesPerDay: memo['nbProfilesPerDay'] + (i.nbPerDay || 0),
              nbDaysAhead: memo['nbDaysAhead'] + (i.nbDaysAhead || 0),
            }),
            defaultStats,
          )
        : defaultStats;
    const meanDaysAhead =
      offers && offers.length > 0 && nbDaysAhead > -1
        ? Math.floor((nbDaysAhead * 10) / offers.length) / 10
        : 0;
    return (
      <div>
        <Statistic.Group widths={'3'} style={{ width: '100%' }}>
          <Statistic>
            <Statistic.Label>Offres actives</Statistic.Label>
            <Statistic.Value>{offers && offers.length}</Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Label>Profils par jour</Statistic.Label>
            <Statistic.Value>{nbProfilesPerDay}</Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Label>Avance moyenne (en jours)</Statistic.Label>
            <Statistic.Value>{meanDaysAhead}</Statistic.Value>
          </Statistic>
        </Statistic.Group>
        <UsersTable offers={offers} users={users} />
      </div>
    );
  }
}

// const loadData = async (skip = 0, { key: sort, desc }, filters) => {
//   const params = {
//     skip,
//     sort,
//     desc: desc ? 1 : 0,
//     ...filters,
//   }
//   console.log('params', params)
//   const { data } = await axios.get(`${baseUrl}/flowboard`, { params });
//   console.log('data', data)
//   return data;
// };

//   onLoadUsers: async () => {
//     const { data } = await axios.get(`${baseUrl}/users`);
//     const users = _.map(data, ({ id, name }) => ({
//       key: id,
//       value: id,
//       text: name,
//     }));
//     dispatch(setUsers([{ key: 'all', value: '', text: 'All' }, ...users]));
export default UsersView;
