import React, { Component } from 'react';
import { Header, Segment, List, Accordion, Icon } from 'semantic-ui-react';

class ScoreDetails extends Component {
  state = { activeIndex: 0 };

  activateDetails() {
    this.setState({
      data: {
        seeMoreActivated: true,
      },
    });
    console.log(this.state);
  }

  detailComponent(scoreDetails) {
    return (
      <List>
        {scoreDetails.experience_match !== null ? (
          <List.Item>Experience Year Match : {scoreDetails.experience_match} </List.Item>
        ) : null}
        {scoreDetails.job_match !== null ? <List.Item>Job Match : {scoreDetails.job_match} </List.Item> : null}
        {scoreDetails.prestige_match !== null ? (
          <List.Item>Prestige Match : {scoreDetails.prestige_match} </List.Item>
        ) : null}
        {scoreDetails.responsibily_match !== null ? (
          <List.Item>Responsability Match : {scoreDetails.responsibily_match} </List.Item>
        ) : null}
        {scoreDetails.skill_match !== null ? <List.Item>Skills Match : {scoreDetails.skill_match} </List.Item> : null}
      </List>
    );
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { details, detailsMode } = this.props || {};
    const scoreDetails = (details || {}).rs_score_details;
    const { activeIndex } = this.state;
    return (
      <Segment style={{ overflowY: 'auto' }}>
        <Accordion>
          <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
            <Header as='h2'>
              Details <Icon name='dropdown' />
            </Header>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            {detailsMode === 'raw' ? (
              <pre>{JSON.stringify(details, null, '  ')}</pre>
            ) : (
              <div>
                <List>
                  {details.profile_has_positive_answer ? <List.Item>Répond Positivement</List.Item> : null}
                  {details.profile_has_signal ? <List.Item>Signaux de réponses positives trouvés</List.Item> : null}
                  {details.offer_send_rate ? (
                    <List.Item>Taux de send : {100 * details.offer_send_rate} % </List.Item>
                  ) : null}
                  {details.rs_score ? <List.Item>Match Score : {details.rs_score} </List.Item> : null}
                </List>

                {this.detailComponent(scoreDetails)}
              </div>
            )}
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

export default ScoreDetails;
