import React from 'react';

import { Stack } from '../Stack';
import CollapsibleEnrichedText from './CollapsibleEnrichedText';

const Summary = ({ summary, t, isSuspicious }) => {
  const style = {
    ...(isSuspicious && { background: 'orange' }),
  };

  return (
    <div className='profile-summary' style={style}>
      <CollapsibleEnrichedText
        t={t}
        enrichedText={(summary || {}).content}
        charCost={1}
        lineCost={90}
        targetMaxCost={400}
        toleranceFactor={0.3}
      />
    </div>
  );
};

const SummaryAndStack = ({ shouldDisplayStack, summary, isSuspicious, isWatchProfile, t }) => (
  <div>
    {shouldDisplayStack && (
      <div className='stack-container'>
        <div className='summary-title'>
          {t('profile.resume.summary')}
          <span className='summary-subtitle'> ({isWatchProfile ? 'HireSweet' : 'LinkedIn'})</span>
        </div>
        <div className='stack'>
          <Stack stack={(summary || {}).stack} displaySoftSkills={true} context='summary' />
        </div>
      </div>
    )}
    <Summary summary={summary} t={t} isSuspicious={isSuspicious} />
  </div>
);

export default SummaryAndStack;
