import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Loader,
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class ClientFriendCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.jobOfferId) {
      this.handleLoadOffer(props.jobOfferId);
    }
  }
  handleLoadOffer = async (jobOfferId) => {
    const offerData = (await axios.post(baseUrl + '/station/offers', {
      ids: jobOfferId,
    })).data;
    if (_.isEmpty(offerData.results)) {
      return alert('no results retrieved');
    }
    const offer = offerData.results[0];
    if (!offer || !offer.clientId) {
      return alert('clientId not retrieved in ClientFriendCompanies View');
    }
    const clientData = (await axios.post(baseUrl + '/station/stats/clients', {
      ids: offer.clientId,
      withFriendCompanies: 1,
    })).data;

    if (_.isEmpty(clientData.results)) {
      return alert('no results retrieved');
    }
    const client = clientData.results[0];

    this.setState({
      friendCompanies: (client || {}).friendCompanies || [],
    });
  };
  render() {
    if (!this.state.friendCompanies) {
      return (
        <center>
          <Loader />
        </center>
      );
    }

    const pretty = (str) => {
      return _.map(str.split(' '), (part) =>
        part.length > 0 ? part.charAt(0).toUpperCase() + part.slice(1) : '',
      ).join(' ');
    };

    return (
      <div>
        {_.isEmpty(this.state.friendCompanies) ? (
          <h3>No Friend Companies</h3>
        ) : (
          <div>
            <h3>Friend Companies</h3>
            <ul>
              {_.map(this.state.friendCompanies, ({ name, type }, index) => (
                <li key={index}>
                  {type ? '[from ' + type + '] ' : ''}
                  {pretty(name)}{' '}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default ClientFriendCompanies;
