import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Menu } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import ChallengeDataset from './ChallengeDataset';

class Challenge extends React.Component {

  state = {};

  componentDidMount() {
    this.handleLoadDatasets();
  }

  handleLoadDatasets = async () => {
    const { challengeId } = this.props;
    const url = baseUrl + '/challenges/' + challengeId + '/datasets';
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const datasets = data.results;
    this.setState({ datasets });
  }

  handleChangeDataset = ({ datasetId }) => {
    this.setState({ selectedDatasetId: datasetId });
  }

  render() {
    const { challengeId } = this.props;
    const { datasets, selectedDatasetId } = this.state;

    if (!datasets) {
      return null;
    }

    return (
      <div>
        <Menu pointing secondary>
          {_.map(datasets, (dataset, index) => (
            <Menu.Item
              key={index}
              name={dataset.name}
              active={dataset.id === selectedDatasetId}
              onClick={() => { this.handleChangeDataset({ datasetId: dataset.id }); }}
            />
          ))}
        </Menu>
        {challengeId && selectedDatasetId && (
          <ChallengeDataset 
            key={selectedDatasetId} 
            challengeId={challengeId} 
            datasetId={selectedDatasetId} 
          />
        )}
      </div>
    );
  }
}


export default Challenge;
