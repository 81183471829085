import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid, List, Segment, Button, Icon, Form, Progress, Table } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';
import ReactJson from 'react-json-view';
import JobList from './JobList';

const Job = (job) => (
  <div>
    <h2>Job {job.params.__type.replace('JobParams', '')}</h2>
    <div style={{ color: 'grey', 'font-size': '0.8em' }}> id: {job.id}</div>
    <Progress percent={Math.round(1000 * job.advancement) / 10} indicating progress />
    <h3>Params</h3>
    <ReactJson src={job.params} displayDataTypes={false} />
    <h3>Events</h3>
    <Table basic='very' selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(job.events, (event, key) => (
          <Table.Row key={key}>
            <Table.Cell>{new Date(event.timestamp).toLocaleString()}</Table.Cell>
            <Table.Cell>{event.message}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </div>
);

class PoolManager extends React.Component {
  state = {};

  componentDidMount() {
    this.handleLoadJobs();
  }

  handleLoadJobs = async () => {
    const { poolId } = this.props;
    const url = baseUrl + '/fastSearch/pools/id/' + poolId + '/withJobs';
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    const jobs = data.jobs;
    const pool = data;
    this.setState({ pool, jobs });
  };

  handleSelectJob = async (key) => {
    this.setState({ selectedJobKey: key });
  };

  handleFirstnameChange = async (firstname) => {
    this.setState({ firstname });
  };

  addProfiles = async (nbProfiles) => {
    const { poolId } = this.props;
    const url = baseUrl + '/fastSearch/pools/id/' + poolId + '/addRandomProfiles';
    const { data } = await axios.post(url, { nbProfiles });
    if (data.error) {
      return alert(data.error);
    }
    this.handleLoadJobs();
  };

  addProfilesViaFirstname = async () => {
    const { poolId } = this.props;
    const url = baseUrl + '/fastSearch/pools/id/' + poolId + '/addProfilesViaFirstname';
    const { data } = await axios.post(url, { firstname: this.state.firstname });
    if (data.error) {
      return alert(data.error);
    }
    this.handleLoadJobs();
  };

  render() {
    const { poolId } = this.props;
    const { pool, jobs } = this.state;
    return (
      <div>
        <h1>
          <a href={'/ml/fast-search'}>
            <Button icon='arrow left' size='small' fitted />
          </a>{' '}
          <a href={'/ml/fast-search/search/' + poolId}>
            <Button icon='search' size='small' fitted />
          </a>{' '}
          Talent pool {poolId}{' '}
        </h1>
        {pool ? (
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Segment>
                  <h2>Information</h2>
                  <List bulleted>
                    <List.Item>Id: {pool.id}</List.Item>
                    <List.Item>Name: {pool.name}</List.Item>
                    <List.Item>Type: {pool.type}</List.Item>
                    <List.Item>Dataset: {pool.datasetName}</List.Item>
                    <List.Item>Table: {pool.tableName}</List.Item>
                    <List.Item>
                      History: {pool.jobIds.length} job{pool.jobIds.length >= 2 ? 's' : ''}
                    </List.Item>
                  </List>
                </Segment>
                <Segment>
                  <h2>Actions</h2>
                  <h3>Random profiles insertion</h3>
                  {_.map([10, 1000, 10000], (nbProfiles, key) => (
                    <Button key={key} onClick={() => this.addProfiles(nbProfiles)}>
                      Add {nbProfiles} random profile{nbProfiles >= 2 ? 's' : ''}
                    </Button>
                  ))}
                  <h3>Insert profiles via first name</h3>
                  <Form>
                    <Form.Group inline>
                      <Form.Input onChange={(e, { value }) => this.handleFirstnameChange(value)} width={10} />
                      <Button onClick={this.addProfilesViaFirstname}>Launch Job</Button>
                    </Form.Group>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column width={5}>
                <Segment>
                  <h2>
                    Jobs
                    <Icon onClick={this.handleLoadJobs} name='repeat' style={{ float: 'right', cursor: 'pointer' }} />
                  </h2>
                  {JobList(jobs, this.handleSelectJob)}
                </Segment>
              </Grid.Column>
              {this.state.selectedJobKey !== undefined ? (
                <Grid.Column width={5}>
                  <Segment>{Job(this.state.jobs[this.state.selectedJobKey])}</Segment>
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        ) : null}
      </div>
    );
  }
}

export default PoolManager;
