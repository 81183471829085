import _ from 'underscore';
import React, { Component } from 'react';
import { Table, Label } from 'semantic-ui-react';
import Pagination from '../../Pagination';

class WorkplaceList extends Component {
  render() {
    const { workplaces, counts, workplaceType } = this.props;

    const TypeLabel = ({ workplace }) => {
      const { companyTypes, type, schoolType } = workplace;
      if (workplaceType === 'company') {
        return (
          <div>
            {_.map(companyTypes || [], (companyType) => (
              <Label key={companyType}>{companyType}</Label>
            ))}
          </div>
        );
      }
      if (workplaceType === 'school') {
        return schoolType ? <Label>{schoolType}</Label> : null;
      }
      return type ? <Label>{type}</Label> : null;
    };
    return counts ? (
      <div>
        <b>Results: {counts.results || 0}</b>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Prestige</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Admin Tags</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(workplaces, (workplace) => (
              <Table.Row key={workplace.id} onClick={() => this.props.onSelect(workplace)}>
                <Table.Cell>{workplace.id}</Table.Cell>
                <Table.Cell>{workplace.name}</Table.Cell>
                <Table.Cell>{workplace.prestige}</Table.Cell>
                <Table.Cell>
                  <TypeLabel workplace={workplace} />
                </Table.Cell>
                <Table.Cell>
                  <div>
                    {_.map(workplace.adminTags || [], (adminTag) => (
                      <Label key={adminTag}>{adminTag}</Label>
                    ))}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {counts.pages ? (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={4}>
                  <Pagination
                    floated='right'
                    current={counts.pageOffset}
                    total={counts.pages}
                    onPage={this.props.onChangePage}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          ) : null}
        </Table>
      </div>
    ) : (
      'Loading...'
    );
  }
}

export default WorkplaceList;
