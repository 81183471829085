import React, { Component } from 'react';
import { Dropdown, Form, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import { Input, SweetForm } from 'sweetform';

import axios from 'axios';
import _ from 'underscore';
import {
  getLevel2CategoryIds,
  getMainJobsCategories,
} from '../../AssetsExplorer/JobsCategories/JobsCategoriesDashboard.js';
import baseUrl from '../../baseUrl.js';
import EditJobModal, { hasEmptyPattern, ensureDefaultTranslation } from './EditJobModal.js';
import JobCard from './JobCard.js';
import JobViewer from './JobViewer.js';

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const addJobsToGroup = async (params) => {
  const jobIds = params.jobsInGroup ? (params.jobsInGroup || '').split(';') : [];
  const payload = {
    jobIds,
    groupId: params.id,
  };
  const url = baseUrl + '/cerejobsV2/addJobsToGroup';
  const { data } = await axios.post(url, payload);
  if (data.error) {
    alert(data.error);
    return;
  }
};

const getCleanPayload = (payload) => {
  payload.medium_dependencies = _.filter(
    payload.medium_dependencies,
    (dependency) => payload.strong_dependencies.indexOf(dependency) === -1,
  );
  payload.weak_dependencies = _.filter(
    payload.weak_dependencies,
    (dependency) =>
      payload.strong_dependencies.indexOf(dependency) === -1 && payload.medium_dependencies.indexOf(dependency) === -1,
  );
  payload.medium_dependencies_origin = _.filter(
    payload.medium_dependencies_origin,
    (dependency) => payload.strong_dependencies.indexOf(dependency.id) === -1,
  );
  payload.weak_dependencies_origin = _.filter(
    payload.weak_dependencies_origin,
    (dependency) =>
      payload.strong_dependencies.indexOf(dependency.id) === -1 &&
      payload.medium_dependencies.indexOf(dependency.id) === -1,
  );
  return payload;
};

export const getPayloadFromParams = async (params, isGroup, groupIdToDiffusionToGroup, jobIdToFullJobInGroup) => {
  const strongDependencies = params.strongDependencies ? params.strongDependencies.split(';') : [];
  const mediumDependencies = params.mediumDependencies ? params.mediumDependencies.split(';') : [];
  const weakDependencies = params.weakDependencies ? params.weakDependencies.split(';') : [];
  const strongDependenciesOrigin = _.map(strongDependencies || [], (dep) => ({
    id: dep,
    origin: 'direct-diffusion',
  }));
  const mediumDependenciesOrigin = _.map(mediumDependencies || [], (dep) => ({
    id: dep,
    origin: 'direct-diffusion',
  }));
  const weakDependenciesOrigin = _.map(weakDependencies || [], (dep) => ({ id: dep, origin: 'direct-diffusion' }));
  const groups = (params.groups || []).concat(params.jobCategories || []);
  _.each(groups || [], (group) => {
    const strength = group.diffusionToGroup ? group.diffusionToGroup : groupIdToDiffusionToGroup[group.groupId] || null;
    const dependency = { id: group.groupId, origin: 'group-diffusion' };
    if (strength === 'strong' || strength === 'absolute') {
      if (strongDependenciesOrigin.indexOf(dependency) === -1) {
        strongDependenciesOrigin.push(dependency);
      }
      if (strongDependencies.indexOf(group.groupId) === -1) {
        strongDependencies.push(group.groupId);
      }
    }
    if (strength === 'medium') {
      if (mediumDependenciesOrigin.indexOf(dependency) === -1) {
        mediumDependenciesOrigin.push(dependency);
      }
      if (mediumDependencies.indexOf(group.groupId) === -1) {
        mediumDependencies.push(group.groupId);
      }
    }
    if (strength === 'weak') {
      if (weakDependenciesOrigin.indexOf(dependency) === -1) {
        weakDependenciesOrigin.push(dependency);
      }
      if (weakDependencies.indexOf(group.groupId) === -1) {
        weakDependencies.push(group.groupId);
      }
    }
  });

  const jobsInGroup = params.jobsInGroup ? (params.jobsInGroup || '').split(';') || [] : [];
  _.each(jobsInGroup, (jobInGroup) => {
    const fullJob = jobIdToFullJobInGroup[jobInGroup];
    _.each(fullJob.groups, (group) => {
      const strength = group.overwritten_diffusion_to_element
        ? group.overwritten_diffusion_to_element
        : params.defaultDiffusionToElement || null;
      const dependency = { id: fullJob.id, origin: 'group-diffusion' };
      if (strength === 'strong' || strength === 'absolute') {
        if (strongDependenciesOrigin.indexOf(dependency) === -1) {
          strongDependenciesOrigin.push(dependency);
        }
        if (strongDependencies.indexOf(fullJob.id) === -1) {
          strongDependencies.push(fullJob.id);
        }
      }
      if (strength === 'medium') {
        if (mediumDependenciesOrigin.indexOf(dependency) === -1) {
          mediumDependenciesOrigin.push(dependency);
        }
        if (mediumDependencies.indexOf(fullJob.id) === -1) {
          mediumDependencies.push(fullJob.id);
        }
      }
      if (strength === 'weak') {
        if (weakDependenciesOrigin.indexOf(dependency) === -1) {
          weakDependenciesOrigin.push(dependency);
        }
        if (weakDependencies.indexOf(fullJob.id) === -1) {
          weakDependencies.push(fullJob.id);
        }
      }
    });
  });
  const payload = {
    name: params.name,
    id: params.id,
    translations: params.translations,
    patterns: params.patterns ? _.map(params.patterns, (patterns) => patterns.split(';')) : [],
    inRevealColumn: params.inRevealColumn,
    types: params.types ? params.types.split(';') : [],
    description: params.description || '',
    strong_dependencies: strongDependencies,
    medium_dependencies: mediumDependencies,
    weak_dependencies: weakDependencies,
    groups: groups
      ? _.map(groups, (group) => {
          const formattedGroup = { id: group.groupId };
          if (group.diffusionToGroup) {
            formattedGroup.overwritten_diffusion_to_group = group.diffusionToGroup;
          }
          if (group.diffusionToElement) {
            formattedGroup.overwritten_diffusion_to_element = group.diffusionToElement;
          }
          if (group.isMinorCategory) {
            formattedGroup.type = 'minor';
          }
          return formattedGroup;
        })
      : [],
    strong_dependencies_origin: strongDependenciesOrigin,
    medium_dependencies_origin: mediumDependenciesOrigin,
    weak_dependencies_origin: weakDependenciesOrigin,
    ...(isGroup && {
      group_configuration: {
        default_diffusion_to_group: params.defaultDiffusionToGroup || null,
        default_diffusion_to_element: params.defaultDiffusionToElement || null,
      },
    }),
  };
  const cleanPayload = getCleanPayload(payload);
  return cleanPayload;
};

class CereJobsV2 extends Component {
  state = {
    search: '',
    jobs: [],
    createJobModalVisible: false,
  };

  componentDidMount() {
    this.handleLoadJobs().then(() => this.handleLoadCategories());
    this.getInitialFilters();
  }

  handleLoadJobs = async () => {
    const jobs = await this.getJobs();
    const tagIds = await this.getTagIds();
    this.setState({ jobs, tagIds });
    this.setColorMap({ jobs });
  };

  handleLoadCategories = async () => {
    const { jobs } = this.state || {};
    const mainJobsCategories = await getMainJobsCategories();
    const mainJobsCategoryIds = _.map(mainJobsCategories, (jobsCategory) => jobsCategory.id);
    const level2CategoryIds = getLevel2CategoryIds(mainJobsCategoryIds, jobs);
    this.setState({ mainJobsCategoryIds, level2CategoryIds });
  };

  async getJobs() {
    const url = baseUrl + '/cerejobsV2/getJobs';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  async getTagIds() {
    const url = baseUrl + '/cerejobsV2/getTagIds';
    const jobs = (await axios.get(url)).data;
    return jobs;
  }

  setColorMap = ({ jobs }) => {
    const colorMap = {};
    _.each(jobs, (job) => {
      colorMap[job.id] = getRandomColor();
    });
    this.setState({ colorMap });
  };

  getInitialFilters() {
    const { initialInGroupsFilter, initialNotInGroupsFilter } = this.props;
    if (!_.isUndefined(initialInGroupsFilter)) {
      this.setState({ inGroupsFilter: initialInGroupsFilter });
    }
    if (!_.isUndefined(initialNotInGroupsFilter)) {
      this.setState({ notInGroupsFilter: initialNotInGroupsFilter });
    }
  }

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  onChangeInGroupsFilters = (e, params) => {
    this.setState({ inGroupsFilter: params.value });
  };

  onChangeNotInGroupsFilters = (e, params) => {
    this.setState({ notInGroupsFilter: params.value });
  };

  onSelectJob = (job) => {
    this.setState({
      selectedJob: job,
    });
  };

  onUnsetSelectedJob = () => {
    this.setState({
      selectedJob: undefined,
    });
  };

  onCreateJob = () => {
    this.setState({ createJobModalVisible: true });
  };

  onCancelCreateJob = () => {
    this.setState({ createJobModalVisible: false });
  };

  reloadJobs = async () => {
    const { selectedJob } = this.state || {};
    const jobs = await this.getJobs();
    this.setState({ jobs });
    const updatedSelectedJob = _.filter(jobs, (jobItem) => jobItem.id === (selectedJob || {}).id);
    if (_.isEmpty(updatedSelectedJob)) {
      this.setState({
        selectedJob: undefined,
      });
    } else {
      this.setState({
        selectedJob: updatedSelectedJob[0],
      });
    }
  };

  onSubmitCreateJob = async (
    params,
    jobs,
    groupIdToDiffusionToGroup,
    groupIdToDiffusionToElement,
    fullJobsInGroup,
    collectionName,
  ) => {
    params.types = params.types ? params.types : ['job'];
    if (_.isUndefined(params)) {
      this.setState({ createJobModalVisible: false });
      return;
    }
    if (_.isEmpty(params.id)) {
      alert('id field should not be empty');
      return;
    }
    if (_.isEmpty(params.name)) {
      alert('name field should not be empty');
      return;
    }
    if (hasEmptyPattern({ patterns: params.patterns })) {
      return alert('Contains empty patterns');
    }
    if (params.translations) {
      params.translations = ensureDefaultTranslation({ translations: params.translations });
    }
    const isGroup = params.isGroup;
    const jobsInGroup = isGroup ? (params.jobsInGroup || '').split(';') || [] : [];
    const jobIdToFullJobInGroup = {};
    _.each(jobsInGroup, (jobInGroup) => {
      jobIdToFullJobInGroup[jobInGroup] = { id: jobInGroup, groups: [{ id: params.id }] };
    });
    _.each(fullJobsInGroup, (fullJobInGroup) => {
      jobIdToFullJobInGroup[fullJobInGroup.id] = fullJobInGroup;
    });
    if (!(params.types || '').split(';').includes('job')) {
      alert('Should be of type job.');
      return;
    }
    const payload = await getPayloadFromParams(params, isGroup, groupIdToDiffusionToGroup, jobIdToFullJobInGroup);
    const url = baseUrl + '/cerejobsV2/createJob';
    const { data } = await axios.post(url, payload);
    if (data.error) {
      alert(data.error);
      return;
    }

    const neighboursPayload = {
      jobId: params.id,
      jobsInGroup: jobsInGroup,
      defaultDiffusionToGroup: params.defaultDiffusionToGroup || null,
      groupIdToDefaultDiffusionToElement: groupIdToDiffusionToElement || {},
      groups: params.groups ? params.groups || [] : [],
      jobIdToFullJobInGroup: jobIdToFullJobInGroup || {},
    };
    const neighboursUrl = baseUrl + '/cerejobsV2/deleteAndChangeJobDependencies';
    const neighboursData = (await axios.post(neighboursUrl, neighboursPayload)).data;
    if (neighboursData.error) {
      alert(neighboursData.error);
      return;
    }

    await this.reloadJobs();
    this.setState({ createJobModalVisible: false });
  };

  render() {
    const { initialInGroupsFilter, initialNotInGroupsFilter } = this.props;
    const {
      jobs,
      tagIds,
      search,
      selectedJob,
      colorMap,
      inGroupsFilter,
      notInGroupsFilter,
      createJobModalVisible,
      mainJobsCategoryIds,
      level2CategoryIds,
    } = this.state || {};

    const indexOfSearch = (name, search) => (name || '').toLowerCase().indexOf(search.toLowerCase());
    const jobGroupOptions = _.map(jobs, (jobGroup) => ({
      value: jobGroup.id,
      text: jobGroup.id,
      key: jobGroup.id,
    }));

    const filteredJobsOnSearch = !search
      ? jobs
      : _.filter(
          jobs,
          (job) =>
            _.any(_.map(job.patterns || [], (pattern) => indexOfSearch(pattern.join(''), search) >= 0)) ||
            indexOfSearch(job.name || job.id, search) >= 0,
        );

    const filteredJobsInGroups = !_.isEmpty(inGroupsFilter)
      ? _.filter(filteredJobsOnSearch, (job) =>
          ((job || {}).groups || []).some((item) => inGroupsFilter.includes(item.id)),
        )
      : filteredJobsOnSearch;

    const filteredJobs = !_.isEmpty(notInGroupsFilter)
      ? _.filter(filteredJobsInGroups, (job) =>
          ((job || {}).groups || []).every((item) => !notInGroupsFilter.includes(item.id)),
        )
      : filteredJobsInGroups;

    return (
      <div>
        {createJobModalVisible ? (
          <EditJobModal
            jobs={jobs}
            tagIds={tagIds}
            onSubmit={this.onSubmitCreateJob}
            onCancel={this.onCancelCreateJob}
            mainJobsCategoryIds={mainJobsCategoryIds}
            level2CategoryIds={level2CategoryIds}
          />
        ) : null}
        <Grid>
          <Grid.Row>
            <Grid.Column width={9}>
              <Segment>
                <div>
                  <h2>Jobs</h2>
                  {'(' + filteredJobs.length + ')'}
                </div>
                <br />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5} verticalAlign={'middle'}>
                      <h4>Filter by name </h4>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <SweetForm onChange={this.onChangeSearch}>
                        <Form.Field>
                          <Input field='search' />
                        </Form.Field>
                      </SweetForm>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={5} verticalAlign={'middle'}>
                      <h4>In groups </h4>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <SweetForm>
                        <Form.Field>
                          <Dropdown
                            placeholder='Job groups'
                            fluid
                            multiple
                            selection
                            search
                            onChange={this.onChangeInGroupsFilters}
                            options={jobGroupOptions}
                            defaultValue={initialInGroupsFilter}
                          />
                        </Form.Field>
                      </SweetForm>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={5} verticalAlign={'middle'}>
                      <h4>Not in groups </h4>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <SweetForm>
                        <Form.Field>
                          <Dropdown
                            placeholder='Job groups'
                            fluid
                            multiple
                            selection
                            search
                            onChange={this.onChangeNotInGroupsFilters}
                            options={jobGroupOptions}
                            defaultValue={initialNotInGroupsFilter}
                          />
                        </Form.Field>
                      </SweetForm>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />
                <a style={{ cursor: 'pointer' }} onClick={() => this.onCreateJob()}>
                  <Icon name='add' color='green' /> <b style={{ color: 'green' }}> Create Job</b>
                </a>
                <br />
                <Table>
                  <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Groups</Table.HeaderCell>
                    <Table.HeaderCell>Implies</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {_.map(filteredJobs, (job) => {
                      return (
                        <JobViewer
                          selectedJob={selectedJob}
                          job={job}
                          onSelectJob={this.onSelectJob}
                          colorMap={colorMap}
                        />
                      );
                    })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
            {selectedJob ? (
              <Grid.Column width={7}>
                <JobCard
                  job={selectedJob}
                  jobs={jobs}
                  onUnsetSelectedJob={this.onUnsetSelectedJob}
                  colorMap={colorMap}
                  reloadJobs={this.reloadJobs}
                  getPayloadFromParams={getPayloadFromParams}
                  addJobsToGroup={addJobsToGroup}
                  tagIds={tagIds}
                  mainJobsCategoryIds={mainJobsCategoryIds}
                  level2CategoryIds={level2CategoryIds}
                />
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default CereJobsV2;
