import React, { Component } from 'react';
import _ from 'underscore';
import { Grid, Icon, Message, Button } from 'semantic-ui-react';
import ClientProfile from '../../components/ClientProfile';

class WatchEnrichedProfile extends Component {
  state = { neutralMode: true };

  handleChangeForm = (value) => {
    this.setState({ offerId: value });
  };

  handleSubmit = () => {
    const { offerId } = this.state;
    this.props.onSelectOfferId(offerId);
  };
  toggleNeutralMode = () => {
    this.setState({ neutralMode: !this.state.neutralMode });
  }

  render() {
    const { enrichedProfile, offerIds } = this.props;
    //const { offerId } = this.state;
    //const options = _.map(offerIds, (offerId) => ({ label: offerId, value: offerId }));
    return (
      <Grid>
        {/* <Grid.Row>
          <Grid.Column width={8}>
            <SweetForm value={{ offerId }} onChange={this.handleChangeForm}>
              <Select field='offerId' options={options} />
            </SweetForm>
          </Grid.Column>
          <Grid.Column width={8}>
            <Button onClick={() => this.handleSubmit()}>Enrich</Button>
          </Grid.Column>
        </Grid.Row> */}
        <Button size='tiny' onClick={() => this.toggleNeutralMode()}>neutral mode</Button>
        <Grid.Row>
          {enrichedProfile ? (
            enrichedProfile.error ? (
              <Message warning>
                <Message.Header>Error enriching profile</Message.Header>
                <p>{JSON.stringify(enrichedProfile.error)}</p>
              </Message>
            ) : (
              <ClientProfile neutralMode={this.state.neutralMode} withSchoolTypes={true} profile={{ data: enrichedProfile }} />
            )
          ) : (
            <Message icon>
              <Icon name='circle notched' loading />
              <Message.Content>
                <Message.Header>Loading...</Message.Header>
              </Message.Content>
            </Message>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

export default WatchEnrichedProfile;
