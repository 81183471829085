import _ from 'underscore';
import React, { Component } from 'react';
import { Table, Checkbox, Button } from 'semantic-ui-react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import CustomModal from '../Modal';

class JobCollectionsView extends Component {
  componentDidMount() {
    this.handleLoadJobCollections();
  }

  handleLoadJobCollections = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getJobCollectionsData';
    const results = (await axios.get(url, customHeaders)).data;
    this.setState({
      modalDeleteJobCollectionVisible: false,
      jobCollections: results,
      selectedJobCollectionIds: [],
      modalUpgradeJobCollectionVisible: false,
      modalSetLatestActiveVisible: false,
      modalDeleteNotActiveCollectionVisible: false,
    });
  };

  handleClickOnField = (fieldName) => {
    const { jobCollections, previousSort } = this.state || {};
    if (fieldName == previousSort) {
      this.setState({
        previousSort: null,
        jobCollections: _.sortBy(jobCollections, (jobCollection) => jobCollection[fieldName]).reverse(),
      });
    } else {
      this.setState({
        previousSort: fieldName,
        jobCollections: _.sortBy(jobCollections, (jobCollection) => jobCollection[fieldName]),
      });
    }
  };

  onChangeCheckbox = (jobCollectionId) => {
    const { selectedJobCollectionIds } = this.state;
    if (selectedJobCollectionIds.includes(jobCollectionId)) {
      this.setState({
        selectedJobCollectionIds: _.filter(
          selectedJobCollectionIds,
          (selectedJobCollectionId) => selectedJobCollectionId != jobCollectionId,
        ),
      });
    } else {
      selectedJobCollectionIds.push(jobCollectionId);
      this.setState({ selectedJobCollectionIds });
    }
  };

  onChangeGlobalCheckbox = () => {
    const { selectedJobCollectionIds, jobCollections } = this.state;
    const allSelected = (selectedJobCollectionIds || []).length == (jobCollections || []).length;
    if (allSelected) {
      this.setState({
        selectedJobCollectionIds: [],
      });
    } else {
      this.setState({ selectedJobCollectionIds: _.map(jobCollections, (jobCollection) => jobCollection.id) });
    }
  };

  onSelectJobCollectionSameCurrentAndLatestSize = () => {
    const { jobCollections } = this.state;
    const selectedJobCollectionIds = _.map(
      _.filter(jobCollections, (jobCollection) => jobCollection.currentSizeSet == jobCollection.latestSizeSet),
      (item) => item.id,
    );
    this.setState({ selectedJobCollectionIds });
  };

  onDeleteJobCollections = async () => {
    const { selectedJobCollectionIds } = this.state || {};
    _.each(selectedJobCollectionIds, async (selectedJobCollectionId) => {
      if (selectedJobCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/deleteJobsCollectionAndWorkflow';
        const payload = {
          jobCollectionId: selectedJobCollectionId,
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadJobCollections();
  };

  onUpgradeJobCollection = async () => {
    const { selectedJobCollectionIds } = this.state || {};
    _.each(selectedJobCollectionIds, async (selectedJobCollectionId) => {
      if (selectedJobCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/upgradeJobCollection';
        const payload = {
          jobCollectionId: selectedJobCollectionId,
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadJobCollections();
  };

  onSetLatestActive = async () => {
    const { selectedJobCollectionIds } = this.state || {};
    _.each(selectedJobCollectionIds, async (selectedJobCollectionId) => {
      if (selectedJobCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/setActiveLatestSearchableSet';
        const payload = {
          collectionId: selectedJobCollectionId,
          type: 'job',
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadJobCollections();
  };

  onDeleteNotActive = async () => {
    const { selectedJobCollectionIds } = this.state || {};
    _.each(selectedJobCollectionIds, async (selectedJobCollectionId) => {
      if (selectedJobCollectionId) {
        const customHeaders = {
          'content-type': 'application/x-www-form-urlencoded',
        };
        const url = baseUrl + '/sweetchain/deleteAllExceptActiveVersion';
        const payload = {
          collectionId: selectedJobCollectionId,
          type: 'job',
        };
        await axios.post(url, payload, customHeaders);
      }
    });
    this.handleLoadJobCollections();
  };

  onCancel = () => {
    this.setState({
      modalDeleteJobCollectionVisible: false,
      modalUpgradeJobCollectionVisible: false,
      modalSetLatestActiveVisible: false,
      modalDeleteNotActiveCollectionVisible: false,
    });
  };

  handleClickOnDelete = () => {
    this.setState({ modalDeleteJobCollectionVisible: true });
  };

  handleClickOnUpgrade = () => {
    this.setState({ modalUpgradeJobCollectionVisible: true });
  };

  handleClickOnSetLatestActive = () => {
    this.setState({ modalSetLatestActiveVisible: true });
  };

  handleClickOnDeleteNotActive = () => {
    this.setState({ modalDeleteNotActiveCollectionVisible: true });
  };

  getItemColor = (row) => {
    if (row.currentSizeSet === 0 && row.nbJobNotDeleted > 0) {
      return '#ffdddd';
    }
    if (row.latestContextVersion !== row.activeContextVersion && row.latestContextVersion > 0) {
      return '#ffb74d';
    }
    if (row.nbExistingVersions > 1) {
      return '#ffe0b3';
    }
    return '#ddffdd';
  };

  render() {
    const {
      jobCollections,
      selectedJobCollectionIds,
      modalDeleteJobCollectionVisible,
      modalUpgradeJobCollectionVisible,
      modalSetLatestActiveVisible,
      modalDeleteNotActiveCollectionVisible,
    } = this.state || {};
    return (
      <div>
        {modalDeleteJobCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Job Collections`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteJobCollections}
          >
            <p>
              Are you sure ? This operation cannot be reverted. It will also delete all the services and sets related to
              the selected job collection.
            </p>
          </CustomModal>
        ) : null}
        {modalUpgradeJobCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Upgrade Job Collections`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onUpgradeJobCollection}
          >
            <p>Are you sure ? This operation can be heavy if many job collections are selected.</p>
          </CustomModal>
        ) : null}
        {modalSetLatestActiveVisible ? (
          <CustomModal
            active={true}
            headerText={`Set Latest Active`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onSetLatestActive}
          >
            <p>Are you sure ? Be sure to have checked the size of latest sets before migrating</p>
          </CustomModal>
        ) : null}
        {modalDeleteNotActiveCollectionVisible ? (
          <CustomModal
            active={true}
            headerText={`Delete Not Active Sets & Services`}
            submitText='Confirm'
            onCancel={this.onCancel}
            onSubmit={this.onDeleteNotActive}
          >
            <p>
              Are you sure ? This operation cannot be revert, it will delete all the sets and services except the
              current active one
            </p>
          </CustomModal>
        ) : null}
        <Button onClick={() => this.onSelectJobCollectionSameCurrentAndLatestSize()}>
          Select Same Current and Latest Set Size
        </Button>
        {selectedJobCollectionIds && selectedJobCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnUpgrade()} color='green'>
            Upgrade Batch
          </Button>
        ) : null}
        {selectedJobCollectionIds && selectedJobCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnSetLatestActive()} color='green'>
            Set Latest Active
          </Button>
        ) : null}
        {selectedJobCollectionIds && selectedJobCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnDeleteNotActive()} color='orange'>
            Delete Not Active Versions
          </Button>
        ) : null}
        {selectedJobCollectionIds && selectedJobCollectionIds.length > 0 ? (
          <Button onClick={() => this.handleClickOnDelete()} color='red'>
            Delete Batch
          </Button>
        ) : null}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>
                <Checkbox
                  checked={(selectedJobCollectionIds || []).length == (jobCollections || []).length}
                  onChange={() => this.onChangeGlobalCheckbox()}
                />
              </Table.HeaderCell>
              <Table.HeaderCell singleLine>
                <a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleClickOnField('id')}>
                  Id
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('latestContextVersion')}
                >
                  Most Recent Version
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('activeContextVersion')}
                >
                  Active Version
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('lastModificationInSearchableSet')}
                >
                  Last Modification Of Searchable Set
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('nbExistingVersions')}
                >
                  Nb Existing Versions
                </a>
              </Table.HeaderCell>

              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('creationTimestamp')}
                >
                  Creation Date
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('lastCursorUpdateDate')}
                >
                  Last Cursor Use
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('nbJobNotDeleted')}
                >
                  Nb Jobs
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('currentSizeSet')}
                >
                  Current Size Set
                </a>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <a
                  style={{ cursor: 'pointer', color: 'black' }}
                  onClick={() => this.handleClickOnField('latestSizeSet')}
                >
                  Latest Size Set
                </a>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(jobCollections || [], (jobCollection, key) => (
              <Table.Row key={key} style={{ backgroundColor: this.getItemColor(jobCollection) }}>
                <Table.Cell>
                  <Checkbox
                    checked={(selectedJobCollectionIds || []).includes(jobCollection.id)}
                    onChange={() => this.onChangeCheckbox(jobCollection.id)}
                  />
                </Table.Cell>
                <Table.Cell>{jobCollection.id}</Table.Cell>
                <Table.Cell>{jobCollection.latestContextVersion}</Table.Cell>
                <Table.Cell>{jobCollection.activeContextVersion}</Table.Cell>

                <Table.Cell>{new Date(jobCollection.lastModificationInSearchableSet).toLocaleString()}</Table.Cell>
                <Table.Cell>{jobCollection.nbExistingVersions}</Table.Cell>
                <Table.Cell>{new Date(jobCollection.creationTimestamp).toLocaleString()}</Table.Cell>
                <Table.Cell>{new Date(jobCollection.lastCursorUpdateDate).toLocaleString()}</Table.Cell>
                <Table.Cell>{jobCollection.nbJobNotDeleted}</Table.Cell>
                <Table.Cell>{jobCollection.currentSizeSet}</Table.Cell>
                <Table.Cell>{jobCollection.latestSizeSet}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default JobCollectionsView;
