import React from 'react';
import _ from 'underscore';
import { translate } from 'react-i18next';
import { Popup, Label } from 'semantic-ui-react';
import { TranslatableText } from '../components/TranslatableText';
import CollapsibleEnrichedText from '../components/CollapsibleEnrichedText';
import { getFormattedStack, Stack } from '../../components/Stack';


const Source = ({ source }) => {
  const url = source.getUrl().get();
  const sourceId = source.getSourceId().get();
  if (sourceId === 'linkedin' || sourceId === 'wttj' || sourceId === 'crunchbase') {
    return (
      <div className='source-link'>
        <a href={url} className='' target='_blank' rel='noreferrer noopener'>
          <div className='source-link-inner'>
            <img src={`/images/contact_logos/logolink_${sourceId}.svg`} alt={url} />
          </div>
        </a>
      </div>
    );
  }
  return null;
};

const WorkplacePopupRaw = (props) => {
  const { workplace, t } = props;

  if (!workplace) {
    return null;
  }
  const workplaceData = workplace.getData();
  const workplaceName = workplaceData.getName().get();
  if (!workplaceName) {
    return null;
  }
  // TODO Choose a type or display all ?
  const workplaceType =
    workplaceData.getTypes().length > 0
      ? workplaceData
          .getTypes()
          .at(0)
          .getName()
      : null;
  const minEmployees = (workplaceData.getStaffCount().get() || {}).min;
  const maxEmployees = (workplaceData.getStaffCount().get() || {}).max;
  const mainLocation = workplaceData.getMainLocation().getName();
  const stack= workplaceData.getStack().getRawValue();
  const formattedStack = getFormattedStack(stack);
  const defaultDescription = workplaceData.getDescription().getDefault();
  const sources = workplaceData.getSources().get();
  const sortedSources = sources.sort((source1, source2) =>
    source1.getSourceId().get() > source2.getSourceId().get() ? 1 : -1,
  );
  const websiteSource = _.find(sources, (source) => source.getSourceId().get() === 'website');
  const website = websiteSource ? websiteSource.getUrl().get(): null;
  const foundedYear = workplaceData.getFoundedYear().get();
  const industries = workplaceData.getIndustries().get();

  return (
    <Popup
      className='workplace-popup'
      basic
      hoverable
      position='bottom center'
      wide
      trigger={
        <span className='workplace-popup-trigger'>
          {website ? (
            <a href={website}
              target='_blank'
              rel='noopener noreferrer'
              className='card-link'
              style={{ color: '#1d1e21', textDecoration: 'underline' }}
            >
              {workplaceName}
            </a>
          ) : (
            workplaceName
          )}
        </span>
      }
    >
      <div className='popup-first-line'>
        <div className='popup-title'>
          {workplaceName}
          {workplaceType && workplaceType.getDefault().get() && (
            <span>
              {' '}
              • <TranslatableText translatableText={workplaceType} />
            </span>
          )}
        </div>
        {workplaceData.getSources().length > 0 && (
          <div className='sources-container'>
            {_.map(sortedSources, (source) => (
              <Source key={source.getSourceId().get()} source={source} />
            ))}
          </div>
        )}
      </div>

      <div className='popup-subtitle'>
        <span>
          {foundedYear && (
            <span>
              {t('profile.resume.workplace.foundedIn')} {foundedYear}{' '}
            </span>
          )}
          {foundedYear && mainLocation.getDefault().get() && <span> • </span>}
          {mainLocation && <TranslatableText translatableText={mainLocation} />}
        </span>
        {_.isNumber(minEmployees) && _.isNumber(maxEmployees) && (
          <div className='workplace-staff-count'>
            {minEmployees !== maxEmployees
              ? t('profile.resume.workplace.employeesCountMinMax', { min: minEmployees, max: maxEmployees })
              : t('profile.resume.workplace.employeesCount', { employeesCount: minEmployees })}
          </div>
        )}
      </div>
      <div className='workplace-industries'>
        {_.map(
          industries,
          (industry) =>
            industry &&
            industry
              .getName()
              .getDefault()
              .get() && (
              <Label
                key={industry
                  .getName()
                  .getDefault()
                  .get()}
                className='industry-label'
              >
                <TranslatableText translatableText={industry.getName()} />
              </Label>
            ),
        )}
      </div>
      {formattedStack && formattedStack.length > 0 && (
        <div className='workplace-stack'>
          <span>
            <u>Stack:</u>
          </span>{' '}
          <Stack stack={stack} displaySoftSkills={false} />
        </div>
      )}
      <p className='workplace-description'>
        <CollapsibleEnrichedText
          text={defaultDescription.getText().get()}
          limit={300}
          enrichments={defaultDescription.getEnrichments()}
          expandOnly={true}
          t={t}
        />
      </p>
    </Popup>
  );
};

export const WorkplacePopup = translate('translations')(WorkplacePopupRaw);
