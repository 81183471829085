import React, { Component } from 'react';
import { Divider } from 'semantic-ui-react';
import profileDataManager from '../../../SweetWork/profileData';
import ProfileHeader from '../../../SweetWork/containers/ProfileHeader';
import ProfileExperiences from '../../../SweetWork/containers/ProfileExperiences';
import ProfileEducations from '../../../SweetWork/containers/ProfileEducations';
import ProfileSkills from '../../../SweetWork/containers/ProfileSkills';
import GithubProfile from '../../../SweetWork/containers/GithubProfile';
import StackoverflowProfile from '../../../SweetWork/containers/StackoverflowProfile';

/*
   props: { 
   data, 
   }
 */

class ProfileView extends Component {
  render() {
    const data = this.props.data || {};
    const profileData = profileDataManager(data, () => null);



    return (
      <div>
        <ProfileHeader
          editable={false}
          firstname={profileData.getFirstname()}
          lastname={profileData.getLastname()}
          headline={profileData.getHeadline()}
          location={profileData.getLocation()}
          photoLink={profileData.getPhotoLink()}
          summary={profileData.getSummary()}
          sources={profileData.getSources()}
          relevantTags={profileData.getRelevantTags()}
          education={profileData.getEducation()}
          lastScrapDay={profileData
            .getSourceData()
            .getLinkedin()
            .getLastScrapDay()}
        />

        {profileData.getExperiences().length > 0 && (
          <div>
            <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
              Experiences
            </Divider>
            <ProfileExperiences experiences={profileData.getExperiences()} />
          </div>
        )}
        {profileData.getEducation().length > 0 && (
          <div>
            <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
              Education
            </Divider>
            <ProfileEducations education={profileData.getEducation()} />
          </div>
        )}
        {profileData.getSourceData().getLinkedin().getSkills().length > 0 && (
          <div>
            <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
              Skills
            </Divider>
            <ProfileSkills
              skills={profileData.getSourceData().getLinkedin().getSkills()}
            />
          </div>
        )}
        <br/>
        {profileData
          .getSourceData()
          .getGithub()
          .getId()
          .get() && (
            <div style={{ position: 'relative' }}>
              <Divider horizontal>Github</Divider>
              <GithubProfile profile={profileData.getSourceData().getGithub()} />
            </div>
          )}

        {profileData
          .getSourceData()
          .getStackoverflow()
          .getId()
          .get() && (
            <div style={{ position: 'relative' }}>
              <Divider horizontal>Stackoverflow</Divider>
              <StackoverflowProfile stackoverflowData={profileData.getSourceData().getStackoverflow()} />
            </div>
          )}

      </div>
    );
  }
}

export default ProfileView;
