import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import moment from 'moment';
import baseUrl from '../../baseUrl';
import {
  Button,
  Modal,
  Grid,
  Table,
  Icon,
  Segment,
  Input as SInput,
  Checkbox as SCheckbox,
  Label,
  Popup
} from 'semantic-ui-react';
import Account from '../Candidate/WatchAccount';
import WatchHiresweetData from '../Candidate/WatchHiresweetData';
import { GRADES } from '../Candidate/utils';
import ClientProfile from '../../components/ClientProfile';
import { SweetForm, Input } from 'sweetform';
import Candidate from '../Candidate'
import { SendNotificationModal } from '../Candidate/SendNotificationsModal';
import { InstantScraper } from '../../InstantScrap';

const getScreeningItems = async (filters) => {
  try {
    const { minCreationDate, maxCreationDate } = filters;
    const params = {
      ...(minCreationDate && { minCreationTimestamp: moment(minCreationDate).unix() }),
      ...(maxCreationDate && { maxCreationTimestamp: moment(maxCreationDate).unix() }),
    };
    const reqUrl = baseUrl + '/candidates/screening/app';
    const reqOptions = { params };
    const result = (await axios.get(reqUrl, reqOptions)).data;
    if (result.error) {
      throw Error(result.error);
    }
    const { screeningItems } = result;
    return screeningItems;
  } catch (e) {
    alert('Error loading screening items :' + e.message);
  }
};

const getScreeningItem = async (candidateId) => {
  try {
    const reqUrl = baseUrl + '/candidates/screening/app/' + candidateId;
    const result = (await axios.get(reqUrl)).data;
    if (result.error) {
      throw Error(result.error);
    }
    return result;
  } catch (e) {
    alert('Error loading screening item ' + candidateId + ' : ' + e.message);
  }
};

const postScreeningAndGetManagementInfo = async ({ grade, candidateId, linkedinId, setDataToLinkedin, linkToHiresweetId }) => {
  try {
    const reqUrl = baseUrl + '/candidates/screening/app/' + candidateId;
    const reqBody = { grade, linkedinId, setDataToLinkedin, linkToHiresweetId };
    const result = (await axios.post(reqUrl, reqBody)).data;
    if (result.error) {
      throw Error(result.error);
    }
    return result;
  } catch (e) {
    alert('Error posting screening for id ' + candidateId + ' : ' + e.message);
  }
};

class ScreeningModal extends React.Component {
  state = {
    linkedinId: '',
  };
  componentWillMount() {
    const { screeningItem } = this.props;
    const { data } = screeningItem || {};
    const defaultSetDataToLinkedin = _.isEmpty(data.experiences) && _.isEmpty(data.summary) && _.isEmpty(data.headline);
    this.setState({ setDataToLinkedin: defaultSetDataToLinkedin })
  }
  handleChangeLinkToHiresweetId = (value) => {
    this.setState({ linkToHiresweetId: value });
  }
  handleToggleLinkToHiresweetId = () => {
    const { linkToHiresweetIdChecked } = this.state;
    this.setState({
      linkToHiresweetIdChecked: !linkToHiresweetIdChecked,
      ...(linkToHiresweetIdChecked && { linkToHiresweetId: null })
    });
  };
  handleChangeLinkedinId = (value) => {
    this.setState({ linkedinId: value });
  };

  handleToggleSetDataToLinkedin = () => {
    const { setDataToLinkedin } = this.state;
    this.setState({
      setDataToLinkedin: !setDataToLinkedin,
    });
  };
  onSubmitGrade = (grade) => {
    const { linkedinId, setDataToLinkedin, linkToHiresweetId, linkToHiresweetIdChecked } = this.state;
    const { screeningItem, handleSubmitGrade } = this.props;
    handleSubmitGrade({
      candidateId: screeningItem.id,
      grade,
      linkedinId,
      setDataToLinkedin,
      linkToHiresweetId: linkToHiresweetIdChecked ? linkToHiresweetId : null
    });
  };
  render() {
    const { linkedinId, setDataToLinkedin, linkToHiresweetIdChecked, linkToHiresweetId } = this.state;
    const { screeningItem, onClose, onSkip } = this.props;
    const { data, account, id, managementInfo } = screeningItem || {};
    const { hiresweet } = (data || {}).sourceData || {};
    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>{id}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid columns={2}>
            <Grid.Column>
              <ClientProfile profile={{ data: data }} />
            </Grid.Column>
            <Grid.Column>
              <Grid.Row width={8}>
                <Account account={account} />
              </Grid.Row>
              <Grid.Row>
                <Segment>
                  <WatchHiresweetData hiresweetData={hiresweet} noEdit={true} />
                </Segment>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        {_.isEmpty(managementInfo) && (
          <Modal.Actions>
            <center>
              <Grid columns={3} centered>
                <Grid.Column width={4} textAlign='center'>
                  <Grid.Row>
                    <SInput
                      value={linkedinId}
                      onChange={(e, { value }) => this.handleChangeLinkedinId(value)}
                      placeholder='Linkedin Id'
                    />
                  </Grid.Row>

                  {/* Now useless, we detect if need to overwride data in componentWillMount
                        <Grid.Row>
                        <SCheckbox
                          visible={false}
                          label="Set candidate's data as in linkedin"
                          onChange={this.handleToggleSetDataToLinkedin}
                          checked={setDataToLinkedin}
                        />
                      </Grid.Row>
                  */}

                    

                  <Grid.Row>
                    <SCheckbox
                      label="Link To Existing Candidate"
                      onChange={this.handleToggleLinkToHiresweetId}
                      checked={linkToHiresweetIdChecked}
                    />
                    {linkToHiresweetIdChecked && (
                      <SInput
                        value={linkToHiresweetId}
                        onChange={(e, { value }) => this.handleChangeLinkToHiresweetId(value)}
                        placeholder='Candidate Id'
                      />
                    )}
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4}>
                  <InstantScraper linkedinLink={linkedinId}/>
                </Grid.Column>
                <Grid.Column width={8} textAlign='center'>
                  <Grid.Row>
                    {_.map(GRADES, ({ value, color }) => (
                      <Button color={color} onClick={() => this.onSubmitGrade(value)}>
                        {value}
                      </Button>
                    ))}
                    <Button onClick={() => onSkip()}>Skip</Button>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </center>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

class EditCandidateModal extends React.Component {
  render() {
    const { id, onFinish } = this.props;
    return (
      <Modal open size='fullscreen'>
        <Modal.Header>Editing candidate {id}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Candidate id={id} />
        </Modal.Content>
        <Modal.Actions>
          <span style={{fontSize:14}}>Finish and send onboarding email </span>
          <Popup
            trigger={
              <Button icon onClick={() => onFinish({ setLastContactPhone: true })}>
                <Icon.Group size="big">
                  <Icon name='phone' />
                  <Icon corner='top right' color='green' name='check' />
                </Icon.Group>
                Already called
              </Button>
            }
            content={<div>No email will be sent</div>}
          />
          <Popup
            trigger={
              <Button icon onClick={() => onFinish({ setLastContactPhone: false })}>
                <Icon.Group size="big">
                  <Icon name='phone' />
                  <Icon corner='top right' color='red' name='dont' />
                </Icon.Group>
                Not already called
              </Button>
            }
            content={<div>Calendly will be sent</div>}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}



const screeningItemIsAvailable = ({ managementInfo, onboarded }) => {
  return Boolean(!managementInfo && onboarded);
};

export default class Screening extends React.Component {
  state = {
    screeningItems: null,
    selectedIndex: null,
    selectedScreeningItem: null,
    loadedScreeningItems: {},
    filters: {
      minCreationDate: moment()
        .subtract(7, 'd')
        .format('YYYY-MM-DD'),
    },
  };

  componentWillMount() {
    this.loadScreeningItems();
  }

  loadScreeningItems = async () => {
    const { filters } = this.state;
    const screeningItems = await getScreeningItems(filters);
    console.log('screeningItems', screeningItems);
    this.setState({
      screeningItems,
    });
  };

  selectScreeningItemAtIndex = async (index) => {
    this.setState({ selectedScreeningItem: null });
    const { candidateId } = (this.state.screeningItems || [])[index] || {};
    if (!candidateId) {
      alert('Could not find screening item at index' + index);
      return;
    }

    let loadedScreeningItem;
    if (this.state.loadedScreeningItems[candidateId]) {
      loadedScreeningItem = this.state.loadedScreeningItems[candidateId];
    } else {
      loadedScreeningItem = await getScreeningItem(candidateId);
      if (!loadedScreeningItem) {
        return;
      }
      this.state.loadedScreeningItems[candidateId] = loadedScreeningItem;
    }
    this.setState({
      selectedScreeningItem: loadedScreeningItem,
      selectedIndex: index,
    });
  };

  handleChangeFilters = (filters) => {
    this.setState({ filters });
  };

  handleSearch = async () => {
    this.setState({ screeningItems: null, loadedScreeningItems: {} });
    const screeningItems = await getScreeningItems(this.state.filters);
    this.setState({ screeningItems });
  };

  handleSubmitGrade = async ({ grade, candidateId, linkedinId, setDataToLinkedin, linkToHiresweetId }) => {
    const managementInfo = await postScreeningAndGetManagementInfo({ grade, candidateId, linkedinId, setDataToLinkedin, linkToHiresweetId });
    if (!managementInfo) {
      return;
    }
    const newScreeningItems = _.map(this.state.screeningItems, (screeningItem) => {
      if (screeningItem.candidateId === candidateId) {
        return {
          ...screeningItem,
          managementInfo,
        };
      }
      return screeningItem;
    });
    this.setState({ screeningItems: newScreeningItems });
    if (['A', 'B', 'C'].indexOf(grade) >= 0) {
      this.resetSelection();
      this.setState({ editedHiresweetId: managementInfo.id })
    } else {
      this.setState({ notificationModalHiresweetId: managementInfo.id })
    }
  };

  resetSelection = () => {
    this.setState({ selectedIndex: null, selectedScreeningItem: null });
  };

  handleFinishCandidateEditionAndOpenNotificationModal = async ({ setLastContactPhone }) => {
    const hiresweetId = this.state.editedHiresweetId;
    if (setLastContactPhone) {
      await axios.put(`${baseUrl}/candidates/${hiresweetId}/admin`, { "lastContactType": "phone" }).data;
    }
    this.setState({ editedHiresweetId: null, notificationModalHiresweetId: hiresweetId })
  };

  handleCloseNotificationModal = () => {
    this.setState({ notificationModalHiresweetId: null })
    this.resetSelection();
  }

  selectNextAvailableScreeningItem = () => {
    const { selectedIndex, screeningItems } = this.state;
    const previousIndex = _.isNumber(selectedIndex) ? selectedIndex : -1;
    let nextIndex;
    let i;
    console.log('previousIndex', previousIndex);
    for (i = previousIndex + 1; i < (screeningItems || []).length; i++) {
      console.log('i', i);
      if (screeningItemIsAvailable(screeningItems[i])) {
        console.log('break');
        nextIndex = i;
        break;
      }
    }
    console.log('nextIndex', nextIndex);
    if (_.isNumber(nextIndex)) {
      this.selectScreeningItemAtIndex(nextIndex);
    } else {
      alert('No more screening items availables !');
      this.resetSelection();
    }
  };

  render() {
    const { screeningItems, filters, selectedScreeningItem, editedHiresweetId, notificationModalHiresweetId } = this.state;
    console.log("editedHiresweetId", editedHiresweetId)
    console.log("notificationModalHiresweetId", notificationModalHiresweetId)
    const nAvailableScreeningItems = _.filter(screeningItems || [], (item) => screeningItemIsAvailable(item)).length;
    return (
      <div>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <SweetForm initialValues={filters} onChange={this.handleChangeFilters}>
                Min Creation Date : <Input type='date' field='minCreationDate' />
                Max Creation Date : <Input type='date' field='maxCreationDate' />
                <Button positive onClick={() => this.handleSearch()}>
                  {'Search'}
                </Button>
              </SweetForm>
            </Grid.Column>
            <Grid.Column>
              <Button onClick={() => this.selectNextAvailableScreeningItem()}>
                Screen available items ({nAvailableScreeningItems})
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {screeningItems ? (
              <Table basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Candidate Id</Table.HeaderCell>
                    <Table.HeaderCell>Creation</Table.HeaderCell>
                    <Table.HeaderCell>Token</Table.HeaderCell>
                    <Table.HeaderCell>Origin</Table.HeaderCell>
                    <Table.HeaderCell>Onboarding Finished</Table.HeaderCell>
                    <Table.HeaderCell>Screening</Table.HeaderCell>
                    <Table.HeaderCell>Screen</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {screeningItems.map((screeningItem, index) => {
                    const {
                      candidateId,
                      creationDate,
                      managementInfo,
                      onboarded,
                      token,
                      originToken,
                      tsOriginToken
                    } = screeningItem;
                    const available = screeningItemIsAvailable(screeningItem);
                    const color = available ? '#d2f1fc' : '';
                    const { grade, users } = (managementInfo || {}).admin || {};
                    const managementCreationDate = (managementInfo || {}).creationDate;
                    return (
                      <Table.Row key={candidateId} style={{ backgroundColor: color }}>
                        <Table.Cell>{index}</Table.Cell>
                        <Table.Cell>{candidateId}</Table.Cell>
                        <Table.Cell>{creationDate}</Table.Cell>
                        <Table.Cell>{token}</Table.Cell>
                        <Table.Cell>{tsOriginToken}{originToken && tsOriginToken != originToken && ` (${originToken})`}</Table.Cell>
                        <Table.Cell>
                          <Label circular color={onboarded ? 'green' : 'red'} empty />
                        </Table.Cell>
                        <Table.Cell>{managementInfo ? `${grade || "?"} - ${(users || []).join(', ')} - ${(managementCreationDate || "").slice(0, 10)}` : null}</Table.Cell>
                        <Table.Cell>
                          <Icon
                            name='eye'
                            size='large'
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.selectScreeningItemAtIndex(index)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            ) : (
              <div>Loading...</div>
            )}
          </Grid.Row>
        </Grid>
        {notificationModalHiresweetId && (
          <SendNotificationModal id={notificationModalHiresweetId} notificationId={'onboarding'} onClose={this.handleCloseNotificationModal} />
        )}
        {editedHiresweetId && (
          <EditCandidateModal id={editedHiresweetId} onFinish={this.handleFinishCandidateEditionAndOpenNotificationModal} />
        )}
        {selectedScreeningItem && (
          <ScreeningModal
            screeningItem={selectedScreeningItem}
            onClose={this.resetSelection}
            handleSubmitGrade={this.handleSubmitGrade}
            onSkip={this.selectNextAvailableScreeningItem}
          />
        )}
      </div>
    );
  }
}
