import React from 'react';
import { Segment } from 'semantic-ui-react';

import _ from 'underscore';

import { LineChart, XAxis, YAxis, Line, Legend, CartesianGrid, Tooltip } from 'recharts';
import { Header } from 'semantic-ui-react';

export default (props) => {
  const workplace = props.workplace;
  const nbEmployeesData = _.map(workplace.flow.nb_employees_per_year, (nb, year) => {
    return {
      name: year,
      total: nb,
      lost: workplace.flow.lost_employees_per_year[year] || 0,
      new: workplace.flow.new_employees_per_year[year] || 0,
    };
  });
  return (
    <Segment>
      <Header as='h2'>
        Employee evolution {props.workplace.flow.sampling ? '(From a sampling of 1000 profiles)' : null}
      </Header>

      <LineChart width={500} height={200} data={nbEmployeesData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type='monotone' dataKey='total' stroke='#8884d8' />
        <Line type='monotone' dataKey='lost' stroke='#ffc658' />
        <Line type='monotone' dataKey='new' stroke='#82ca9d' />
      </LineChart>
    </Segment>
  );
};
