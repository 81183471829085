import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';

import { Label, Table } from 'semantic-ui-react';

class FindSkillPatterns extends React.Component {
  componentDidMount() {
    this.getCandidatePatterns();
  }

  getCandidatePatterns = async () => {
    const { profilesCollectionId } = this.props;
    const url = baseUrl + '/extendSkills/getCandidatePatterns/' + profilesCollectionId;
    const result = (await axios.get(url)).data;
    if (!result.success) {
      alert(result.message);
      return;
    }
    const { candidatePatterns } = result;
    this.setState({ candidatePatterns });
  };

  renderCell = (name, key, item) => {
    const entityId = item.entityId;
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Entity Id'
          ? entityId
          : name == 'Suggested Patterns'
          ? _.map((item || {}).patterns || [], (pattern) => {
              return <Label>{pattern.text}</Label>;
            })
          : null}
      </Table.Cell>
    );
  };

  render() {
    const { candidatePatterns } = this.state || {};
    const columnNames = ['Entity Id', 'Suggested Patterns'];
    return (
      <Table>
        <Table.Header>
          <Table.HeaderCell>Entity Id</Table.HeaderCell>
          <Table.HeaderCell>Suggested Patterns</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {(candidatePatterns || []).map((item, key) => {
            return (
              <Table.Row key={key}>
                {columnNames.map((name, index2) => {
                  return this.renderCell(name, index2 + key, item);
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export default FindSkillPatterns;
