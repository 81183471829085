import React from 'react';
import axios from 'axios';
import _ from 'underscore';

// import Summary from './Summary.js';
import Service from './Service.js';

import { Menu, Tab, Icon, Accordion } from 'semantic-ui-react';

import baseUrl from '../../baseUrl.js';

export const DEFAULT_CATEGORY = 'Default';
export const DEFAULT_SERVICE = 'Default';

const unpackData = (data) => {
  let unpackedData = { summary: { okCount: 0, alertCount: 0 }, categories: [] };

  const categoryToChecks = _.groupBy(data.status, ({ category }) => category || DEFAULT_CATEGORY);
  _.each(categoryToChecks, (categoryChecks, categoryName) => {
    let category = { name: categoryName, okCount: 0, alertCount: 0, services: [] };
    const serviceToChecks = _.groupBy(categoryChecks, ({ service }) => service || DEFAULT_SERVICE);
    _.each(serviceToChecks, (serviceChecks, serviceName) => {
      let service = { name: serviceName, okCount: 0, alertCount: 0, checks: [] };
      _.each(serviceChecks, (check) => {
        const checkIsOk = ((check.status || {}).lastCheck || {}).severity === 'ok';
        if (checkIsOk) {
          service.okCount++;
          category.okCount++;
          unpackedData.summary.okCount++;
        } else {
          service.alertCount++;
          category.alertCount++;
          unpackedData.summary.alertCount++;
        }
        service.checks.push(check);
      });
      category.services.push(service);
    });
    unpackedData.categories.push(category);
  });

  return unpackedData;
};

export default class SentryDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      error: false,
    };

    this.refreshData = this.refreshData.bind(this);
  }

  async refreshData() {
    try {
      const result = await axios.get(`${baseUrl}/sentry/dashboard`);
      const unpackedData = unpackData(result.data);
      this.setState({
        loading: false,
        error: false,
        data: unpackedData,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.refreshData();
    this.refresher = setInterval(this.refreshData, 20000);
  }

  componentWillUnmount() {
    clearInterval(this.refresher);
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    if (this.state.error) {
      return <div>Loading failed. Sentry API is down.</div>;
    }
    const { summary, categories } = this.state.data;

    const panes = _.map(categories, ({ name, okCount, alertCount, services }) => {
      const ok = alertCount == 0;
      return {
        menuItem: (
          <Menu.Item key={name} color={ok ? 'green' : 'red'}>
            <Icon
              name={ok ? 'check circle' : 'warning circle'}
              color={ok ? 'green' : 'red'}
              size="large"
            />
            {`${name} ${alertCount}/${okCount + alertCount}`}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Accordion>
              {_.map(services, (service) => (
                <Service service={service} key={service.name} />
              ))}
            </Accordion>
          </Tab.Pane>
        ),
      }
    })

    return (
      <div>
        {/*<Summary summary={summary} />*/}
        <Tab panes={panes} />
        {/*
          <Accordion>
            {_.map(this.state.data.services, (service, name) => (
              <Service service={service} name={name} key={name} />
            ))}
          </Accordion>
        */}
      </div>
    );
  }
}
