import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GenericDashboard from '../Boards/GenericDashboard';

const addFiltersToWidget = ({ widget, filters }) => {
  const widgetType = (((widget || {})).data || {}).type; 
  if (["bigquery-count-range", "bigquery-sequential-filter-groups"].indexOf(widgetType) >= 0) {
    return {
      ...widget,
      data: {
        ...widget.data,
        filters: [
          ...widget.data.filters || [],
          ...filters,
        ]
      }
    }
  }
  if (widgetType == "bigquery") {

    return {
      ...widget,
      data: {
        ...widget.data,
        groups: _.map(widget.data.groups || [], (group) => ({
          ...group,
          filters: [
            ...group.filters,
            ...filters
          ]
        }))
      }
    }
  }
  return widget;
}
const addFiltersToDashboard = ({ dashboard, filters }) => {
  return {
    ...dashboard,
    widgetById: _.mapObject(dashboard.widgetById, (widget) => addFiltersToWidget({ widget, filters }))
  }
}

class FilteredDashboardContainer extends React.Component {

  state = {
    dashboard: undefined,
  }

  handleLoad = async () => {
    const { dashboardId, filters } = this.props;
    const { data } = await axios.get(`${baseUrl}/stats/dashboards/${dashboardId}`);
    console.log('Dashboard', data);
    if (data.error) {
      return alert(data.error);
    }
    const filteredDashboard = addFiltersToDashboard({ dashboard: data, filters });
    console.log("Filtered dashboard", filteredDashboard)
    this.setState({ dashboard: filteredDashboard });
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    const { dashboard } = this.state;

    if (!dashboard) {
      return (
        <div>
          <br /><br /><br />
          <center> <Loader active inline /> </center>
          <br /><br /><br />
        </div>
      );
    }

    return (
      <center>
        <GenericDashboard dashboard={dashboard} />
      </center>
    );
  }
}

export default FilteredDashboardContainer;