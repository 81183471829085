import { Button, Card, Form, Grid, Header, Icon, List, Modal, Segment, Table } from 'semantic-ui-react';
import { Input, Select, SweetForm } from 'sweetform';
import React, { Component } from 'react';

import CustomModal from '../Modal';
import ProfileScores from '../SweetWork/containers/ProfileScores';
import ProfileView from '../SweetComponents/Profile/View';
import ScoringDetails from '../DataPointSandbox/ScoringDetails';
import { Textarea } from '../common';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

const generateId = () => {
  var letters = 'azertyuiopqsdfghjklmwxcvbn';
  var id = '';
  for (var i = 0; i < 15; i++) {
    id += letters[Math.floor(Math.random() * 20)];
  }
  return id;
};

const sourceTypeOptions = [
  {
    label: 'Inline',
    value: 'inline',
  },
  {
    label: 'JSON',
    value: 'json',
  },
];

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export class DetailedProfileView extends Component {
  componentDidMount() {
    const { profilePath, criteria } = this.props;
    this.loadProfileV5(profilePath, criteria);
  }

  loadProfileV5 = async (profilePath, criteria) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileV5';
    const payload = {
      profile: {
        mode: 'cloud-storage',
        profilePath: profilePath,
      },
      offer: {
        companyId: 'hiresweet',
        criteria,
      },
    };
    const result = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ profileResult: result });
  };

  render() {
    const { criteria, scoreWithDetails } = this.props;
    const { profileResult } = this.state || {};
    const profile = (profileResult || {}).profile;
    const profileScores = (profileResult || {}).profileScores;
    const cv = (profileResult || {}).cv;
    return profile ? (
      <div>
        {cv ? (
          <a style={{ cursor: 'pointer' }} href={cv}>
            <Icon name='file alternate' /> Open CV
          </a>
        ) : null}
        {criteria ? <ProfileScores profileScores={profileScores} /> : null}
        {criteria ? <ScoringDetails scoringDetails={scoreWithDetails} profileScores={profileScores} /> : null}
        <ProfileView data={profile} />{' '}
      </div>
    ) : (
      <div />
    );
  }
}

export class TalentCollectionView extends Component {
  onClickOnProfile = async (profile, criteria) => {
    if (criteria) {
      await this.getScoreWithDetails(profile, criteria);
    }
    this.setState({ selectedProfile: profile });
  };

  getScoreWithDetails = async (profile, criteria) => {
    const payload = {
      job: { criteria },
      profilePath: profile.profile_path,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/scoreWithDetails';
    const results = (await axios.post(url, payload, customHeaders)).data;
    const scoreWithDetails = results.results;
    this.setState({ scoreWithDetails });
  };

  render() {
    const { profiles, criteria } = this.props;
    const { selectedProfile, scoreWithDetails } = this.state || {};
    return (
      <Grid columns={2}>
        <Grid.Column
          width={2}
          style={{
            maxHeight: '600px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <Table
            style={{
              width: '100%',
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='left'>Talent</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {profiles
                ? _.map(profiles.slice(0, 100), (profile, index) => (
                    <Table.Row
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.onClickOnProfile(profile, criteria)}
                      key={index}
                    >
                      <Table.Cell textAlign='left'>
                        {profile.firstname + ' ' + profile.lastname}
                        {profile.score ? (
                          <div>
                            <br />
                            {(profile.score * 100).toFixed(2)}
                          </div>
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedProfile ? (
            <div>
              <DetailedProfileView
                key={selectedProfile.profile_path}
                profilePath={selectedProfile.profile_path}
                criteria={criteria}
                scoreWithDetails={scoreWithDetails}
              />
            </div>
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

class CreateProfileCollectionModal extends Component {
  handleChange(params) {
    this.setState({
      ...params,
    });
  }
  render() {
    const { onCancel, onSubmit } = this.props;
    return (
      <Modal open closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Title</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='title'
                label='title'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Id</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='id'
                label='id'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '200px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='description'
                label='description'
                placeholder=''
              />
            </Form.Field>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class Talents extends Component {
  componentDidMount() {
    const { projectId } = this.props;
    this.loadProfileCollections(projectId);
  }

  loadProfiles = async (profileCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfiles/' + profileCollectionId;
    const profiles = (await axios.get(url, customHeaders)).data;
    this.setState({ profiles: profiles.data.results });
  };

  loadProfileCollections = async (projectId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileCollections/' + projectId;
    const profileCollections = (await axios.get(url, customHeaders)).data;
    this.setState({ profileCollections });
  };

  handleClickOnUploadTalent = () => {
    this.setState({ uploadTalentModalVisible: true });
  };

  handleClickOnCollection = (e, { value }) => {
    this.setState({ selectedProfileCollectionId: value });
    this.loadProfiles(value);
  };

  handleClickDeleteProfileCollectionOpenModal = async () => {
    this.setState({ onDeleteModalVisible: true });
  };

  handleClickDeleteProfileCollection = async (profileCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { projectId } = this.props;
    const deleteUrl = baseUrl + '/sweetchain/deleteProfilesCollectionAndWorkflow';
    const result = (await axios.post(deleteUrl, { profileCollectionId }, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    const deleteLinkUrl = baseUrl + '/sweetchain/removeProfileCollectionToProject';
    const payload = {
      projectId,
      profileCollectionId,
    };
    await axios.post(deleteLinkUrl, payload, customHeaders);
    this.loadProfileCollections(projectId);
    this.setState({ onDeleteModalVisible: false });
  };

  cancelCreateProfileCollectionModal = () => {
    this.setState({ selectedProfileCollectionId: null });
  };

  submitProfileCollection = async (params) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const createUrl = baseUrl + '/sweetchain/createProfilesCollection';

    const { projectId, userId } = this.props;
    const payload = {
      ...params,
      projectId,
      userId,
    };
    const result = (await axios.post(createUrl, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ newProfileCollectionModalVisible: false });
  };

  render() {
    const { profiles, profileCollections, selectedProfileCollectionId, onDeleteModalVisible } = this.state || {};
    const profileCollectionsOptions = _.map(profileCollections, (profileCollection) => ({
      value: profileCollection.id,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
      key: profileCollection.id,
    })).concat({
      value: 'new-profile-collection',
      key: 'new-profile-collection',
      text: '+ New Profile Collection',
    });

    const newProfileCollectionModalVisible = selectedProfileCollectionId === 'new-profile-collection';

    const { projectId } = this.props;
    return (
      <div>
        {newProfileCollectionModalVisible ? (
          <CreateProfileCollectionModal
            projectId={projectId}
            onCancel={this.cancelCreateProfileCollectionModal}
            onSubmit={this.submitProfileCollection}
          />
        ) : (
          <div>
            {onDeleteModalVisible ? (
              <CustomModal
                active={true}
                headerText={`Delete Profile Collection ${selectedProfileCollectionId}`}
                submitText='Confirm'
                onCancel={this.cancelCreateProfileCollectionModal}
                onSubmit={() => this.handleClickDeleteProfileCollection(selectedProfileCollectionId)}
              >
                <p>Are you sure? This operation cannot be reverted.</p>
              </CustomModal>
            ) : null}
            <Grid rows={3}>
              <Grid.Row>
                <Form.Select
                  search
                  placeholder='Select Talent Collections'
                  options={profileCollectionsOptions}
                  onChange={this.handleClickOnCollection}
                  value={selectedProfileCollectionId}
                />
              </Grid.Row>
              {selectedProfileCollectionId ? (
                <div>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Button onClick={() => this.handleClickDeleteProfileCollectionOpenModal()} color='red'>
                        Delete Collection
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </div>
              ) : null}
            </Grid>
            {selectedProfileCollectionId ? (
              <TalentCollectionView profiles={profiles} key={selectedProfileCollectionId} />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Talents;
