import React, { Component } from 'react';
import axios from 'axios';
import { Button, Modal, Header, TextArea, Form } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

export class OutlierModal extends Component {
  state = { open: false };
  onSubmitOutlier = async () => {
    const { comment, profile, searchParam, idea } = this.props;
    if (!comment) {
      alert('comment needed');
      return;
    }

    const payLoad = {
      idFields: profile ? profile.idFields : null,
      reason: comment,
      searchParam: searchParam,
      offer: idea
        ? { title: 'Rayon X : Idea' }
        : { title: 'Rayon X : Outlier' },
      rayonX: true,
    };

    const response = (await axios.post(
      `${baseUrl}/sweetwork/reportOutlier`,
      payLoad,
    )).data;
    if (response.error) {
      alert(response.error);
    } else {
      alert('reported');
      this.setState({ open: false });
    }
  };
  render() {
    const { onChangeComment, comment, idea } = this.props;
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <Button
            circular
            color={idea ? 'blue' : 'black'}
            size="massive"
            onClick={() => this.setState({ open: true })}
            disabled={!this.props.profile}
            icon={idea ? 'lightbulb' : 'bell'}
          />
        }
      >
        <Header icon="pencil" content={idea ? 'Idea' : 'Outlier'} />
        <Modal.Content>
          <Form>
            <TextArea
              onChange={(e) => onChangeComment(e.target.value)}
              value={comment || ''}
              placeholder="Comment"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.onSubmitOutlier}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
