import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SweetSheet from './SweetSheet';
import * as qs from 'query-string';

export default ({ match, ...props }) => (
  <Switch>
    <Route
      path={`${match.path}/id/:id`}
      render={(p) => {
        const sheetId = p.match.params.id;
        const queryParams = qs.parse(p.location.search || '');
        const withAdminActions = (queryParams || {}).withAdminActions === '1';
        return (
          <SweetSheet sheetId={sheetId} withAdminActions={withAdminActions} />
        );
      }}
    />
  </Switch>
);
