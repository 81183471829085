import _ from 'underscore';

export const List = (Element) => (state, onUpdate) => {
  if (!_.isArray(state) && state !== null && state !== undefined) {
    throw Error('state should be an array, but got ' + state);
  }
  const updateArrayAt = (array, i, newValue) => _.map(array, (value, j) => (i === j ? newValue : value));
  const children = _.map(state, (childState, index) =>
    Element(childState, (newValue) => onUpdate(updateArrayAt(state, index, newValue))),
  );
  return {
    get: () => children,
    at: (index) => {
      if (index < 0 || index >= ((state || {}).length || 0)) {
        throw Error('index should be positive and < ' + state.length + ' but got ' + index);
      }
      return children[index];
    },
    removeAt: (index) => {
      if (index < 0 || index >= ((state || {}).length || 0)) {
        throw Error('index should be positive and < ' + state.length + ' but got ' + index);
      }
      return onUpdate(state.slice(0, index).concat(state.slice(index + 1)));
    },
    length: (state || {}).length || 0,
    getRawValue: () => _.map(children, ({ getRawValue }) => getRawValue()),
    push: (value) => onUpdate((state || []).concat([value])),
    setRawValue: (value) => onUpdate(value),
  };
};

export const Dict = (subFieldSchemas) => (state, onUpdate) => {
  if (!_.isObject(state) && state !== null && state !== undefined) {
    throw Error('state should be an object, but got ' + state);
  }
  const children = _.object(
    _.map(subFieldSchemas, ({ key, Element }) => [
      key,
      Element((state || {})[key], (newValue) => onUpdate({ ...state, [key]: newValue })),
    ]),
  );

  const getRawValue = () => {
    return _.pick(
      _.mapObject(children, ({ getRawValue }) => getRawValue()),
      (value) =>
        !(_.isObject(value) && _.isEmpty(value)) &&
        value !== false &&
        value !== null &&
        value !== '' &&
        value !== undefined,
    );
  };

  return {
    ..._.object(_.map(subFieldSchemas, ({ key, getterName }) => [getterName, () => children[key]])),
    getRawValue: getRawValue,
    remove: () => onUpdate(null),
  };
};

const Leaf = (state, onUpdate) => ({ get: () => state, set: onUpdate, getRawValue: () => state });

const TranslatableText = Dict([
  { getterName: 'getDefault', key: 'default', Element: Leaf },
  { getterName: 'getEn', key: 'en', Element: Leaf },
  { getterName: 'getFr', key: 'fr', Element: Leaf },
]);

const Entity = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getType', key: 'type', Element: Leaf },
  { getterName: 'getLabel', key: 'label', Element: Leaf },
  { getterName: 'getId', key: 'id', Element: Leaf },
  { getterName: 'getName', key: 'name', Element: TranslatableText },
]);

export const Enrichment = Dict([
  { getterName: 'getBegin', key: 'begin', Element: Leaf },
  { getterName: 'getEnd', key: 'end', Element: Leaf },
  { getterName: 'getEntity', key: 'entity', Element: Entity },
]);

export const EnrichedText = Dict([
  { getterName: 'getText', key: 'text', Element: Leaf },
  { getterName: 'getEnrichments', key: 'enrichments', Element: List(Enrichment) },
]);

const Warnings = List(
  Dict([
    { getterName: 'getIsSoft', key: 'isSoft', Element: Leaf },
    { getterName: 'getText', key: 'text', Element: Leaf },
    { getterName: 'getTitle', key: 'title', Element: Leaf },
  ]),
);

const Stack = List(
  Dict([
    { getterName: 'getName', key: 'name', Element: Leaf },
    { getterName: 'getIcon', key: 'icon', Element: Leaf },
    {
      getterName: 'getDetails',
      key: 'details',
      Element: Dict([
        { getterName: 'getType', key: 'type', Element: Leaf },
        { getterName: 'getUrl', key: 'url', Element: Leaf },
      ]),
    },
    { getterName: 'getShouldBeDisplayed', key: 'shouldBeDisplayed', Element: Leaf },
  ]),
);

const EnrichedTextAndStack = Dict([
  { getterName: 'getContent', key: 'content', Element: EnrichedText },
  { getterName: 'getStack', key: 'stack', Element: Stack },
]);

const SweetDate = Dict([
  { getterName: 'getRaw', key: 'raw', Element: Leaf },
  { getterName: 'getMonth', key: 'month', Element: Leaf },
  { getterName: 'getYear', key: 'year', Element: Leaf },
]);

const Location = Dict([
  { getterName: 'getName', key: 'name', Element: TranslatableText },
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
]);

const EnrichedTranslatableText = Dict([
  { getterName: 'getType', key: 'type', Element: Leaf },
  { getterName: 'getDefault', key: 'default', Element: EnrichedText },
  { getterName: 'getEn', key: 'en', Element: EnrichedText },
  { getterName: 'getFr', key: 'fr', Element: EnrichedText },
]);

const WorkplaceData = Dict([
  { getterName: 'getName', key: 'name', Element: Leaf },
  {
    getterName: 'getTypes',
    key: 'types',
    Element: List(
      Dict([
        { getterName: 'getId', key: 'id', Element: Leaf },
        { getterName: 'getName', key: 'name', Element: TranslatableText },
      ]),
    ),
  },
  { getterName: 'getFoundedYear', key: 'foundedYear', Element: Leaf },
  { getterName: 'getStaffCount', key: 'staffCount', Element: Leaf },
  { getterName: 'getStack', key: 'stack', Element: Stack },
  { getterName: 'getMainLocation', key: 'mainLocation', Element: Location },
  {
    getterName: 'getIndustries',
    key: 'industries',
    Element: List(Dict([{ getterName: 'getName', key: 'name', Element: TranslatableText }])),
  },
  { getterName: 'getDescription', key: 'description', Element: EnrichedTranslatableText },
  { getterName: 'getPrestige', key: 'prestige', Element: Leaf },
  {
    getterName: 'getSources',
    key: 'sources',
    Element: List(
      Dict([
        { getterName: 'getSourceId', key: 'sourceId', Element: Leaf },
        { getterName: 'getUrl', key: 'url', Element: Leaf },
        { getterName: 'getName', key: 'name', Element: TranslatableText },
      ]),
    ),
  },
]);

const Workplace = Dict([
  { getterName: 'getId', key: 'id', Element: Leaf },
  { getterName: 'getIdFields', key: 'idFields', Element: Leaf },
  { getterName: 'getData', key: 'data', Element: WorkplaceData },
]);

export const Experience = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getTitle', key: 'title', Element: EnrichedText },
  { getterName: 'getLocation', key: 'location', Element: Location },
  { getterName: 'getDescription', key: 'description', Element: EnrichedText },
  { getterName: 'getCompanyName', key: 'companyName', Element: Leaf },
  { getterName: 'getCompanyWebsite', key: 'companyWebsite', Element: Leaf },
  { getterName: 'getStartDate', key: 'startDate', Element: SweetDate },
  { getterName: 'getEndDate', key: 'endDate', Element: SweetDate },
  { getterName: 'getPhotoLink', key: 'photoLink', Element: Leaf },
  { getterName: 'getStack', key: 'stack', Element: Stack },
  { getterName: 'getWorkplace', key: 'workplace', Element: Workplace },
]);

const Sources = Dict([
  { getterName: 'getLinkedin', key: 'linkedin', Element: Leaf },
  { getterName: 'getGithub', key: 'github', Element: Leaf },
  { getterName: 'getStackoverflow', key: 'stackoverflow', Element: Leaf },
  { getterName: 'getMedium', key: 'medium', Element: Leaf },
  { getterName: 'getTwitter', key: 'twitter', Element: Leaf },
  { getterName: 'getDribbble', key: 'dribbble', Element: Leaf },
  { getterName: 'getBehance', key: 'behance', Element: Leaf },
  { getterName: 'getPinterest', key: 'pinterest', Element: Leaf },
  { getterName: 'getAngelList', key: 'angelList', Element: Leaf },
  { getterName: 'getMeetup', key: 'meetup', Element: Leaf },
  { getterName: 'getGithubIo', key: 'githubIo', Element: Leaf },
  { getterName: 'getBitBucket', key: 'bitBucket', Element: Leaf },
  { getterName: 'getAboutMe', key: 'aboutMe', Element: Leaf },
  { getterName: 'getKeyBase', key: 'keyBase', Element: Leaf },
  { getterName: 'getViadeo', key: 'viadeo', Element: Leaf },
  { getterName: 'getWordPress', key: 'wordPress', Element: Leaf },
  { getterName: 'getMalt', key: 'malt', Element: Leaf },
  { getterName: 'getDoYouBuzz', key: 'doYouBuzz', Element: Leaf },
  { getterName: 'getCoderWall', key: 'coderWall', Element: Leaf },
  { getterName: 'getSlideshare', key: 'slideshare', Element: Leaf },
  { getterName: 'getRemixJobs', key: 'remixJobs', Element: Leaf },
  { getterName: 'getWebsite', key: 'website', Element: Leaf },
  { getterName: 'getCv', key: 'cv', Element: Leaf },
]);

export const Education = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getIsMain', key: 'isMain', Element: Leaf },
  { getterName: 'getDegree', key: 'degree', Element: EnrichedText },
  { getterName: 'getSchoolName', key: 'schoolName', Element: Leaf },
  { getterName: 'getSchoolWebsite', key: 'schoolWebsite', Element: Leaf },
  { getterName: 'getDescription', key: 'description', Element: EnrichedText },
  { getterName: 'getStartDate', key: 'startDate', Element: SweetDate },
  { getterName: 'getEndDate', key: 'endDate', Element: SweetDate },
  { getterName: 'getPhotoLink', key: 'photoLink', Element: Leaf },
  { getterName: 'getStack', key: 'stack', Element: Stack },
  { getterName: 'getWorkplace', key: 'workplace', Element: Workplace },
]);

const RelevantTag = Dict([
  { getterName: 'getLabel', key: 'label', Element: TranslatableText },
  { getterName: 'getContent', key: 'content', Element: TranslatableText },
]);

const RelevantFact = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getTitle', key: 'title', Element: TranslatableText },
  { getterName: 'getDescription', key: 'description', Element: TranslatableText },
]);

// TODO: Polymorphism & set
const SmartSummaryArgument = (state, onUpdate) => ({ get: () => state, set: onUpdate, getRawValue: () => state });

const SmartSummary = Dict([{ getterName: 'getArguments', key: 'arguments', Element: List(SmartSummaryArgument) }]);

const Skill = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getLabel', key: 'label', Element: TranslatableText },
  { getterName: 'getEndorsementsCount', key: 'endorsementsCount', Element: Leaf },
  { getterName: 'getRelevance', key: 'relevance', Element: Leaf },
]);

const LinkedinData = Dict([
  { getterName: 'getId', key: 'id', Element: Leaf },
  { getterName: 'getLastScrapDay', key: 'lastScrapDay', Element: Leaf },
  { getterName: 'getFirstName', key: 'firstName', Element: Leaf },
  { getterName: 'getLastName', key: 'lastName', Element: Leaf },
  { getterName: 'getHeadline', key: 'headline', Element: EnrichedTextAndStack },
  { getterName: 'getSummary', key: 'summary', Element: EnrichedTextAndStack },
  { getterName: 'getEducation', key: 'education', Element: List(Education) },
  { getterName: 'getExperiences', key: 'experiences', Element: List(Experience) },
  { getterName: 'getSkills', key: 'skills', Element: List(Skill) },
  { getterName: 'getRelevantFacts', key: 'relevantFacts', Element: List(RelevantFact) },
]);

const Repository = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getId', key: 'id', Element: Leaf },
  { getterName: 'getName', key: 'name', Element: EnrichedText },
  { getterName: 'getFullname', key: 'fullname', Element: EnrichedText },
  { getterName: 'getDescription', key: 'description', Element: EnrichedText },
  { getterName: 'getFork', key: 'fork', Element: Leaf },
  { getterName: 'getStargazersCount', key: 'stargazersCount', Element: Leaf },
  { getterName: 'getForksCount', key: 'forksCount', Element: Leaf },
  { getterName: 'getLanguage', key: 'language', Element: Leaf },
  { getterName: 'getStack', key: 'stack', Element: Stack },
]);

const GithubData = Dict([
  { getterName: 'getId', key: 'id', Element: Leaf },
  { getterName: 'getLogin', key: 'login', Element: Leaf },
  { getterName: 'getLastScrapDay', key: 'lastScrapDay', Element: Leaf },
  { getterName: 'getFullname', key: 'fullname', Element: Leaf },
  { getterName: 'getHeadline', key: 'headline', Element: EnrichedTextAndStack },
  { getterName: 'getCompanyName', key: 'companyName', Element: Leaf },
  { getterName: 'getLocation', key: 'location', Element: Location },
  { getterName: 'getEmail', key: 'email', Element: Leaf },
  { getterName: 'getPhotoLink', key: 'photoLink', Element: Leaf },
  { getterName: 'getRepositories', key: 'repositories', Element: List(Repository) },
  { getterName: 'getNbFollowing', key: 'nbFollowing', Element: Leaf },
  { getterName: 'getNbFollowers', key: 'nbFollowers', Element: Leaf },
  { getterName: 'getRelevantFacts', key: 'relevantFacts', Element: List(RelevantFact) },
  { getterName: 'getStack', key: 'stack', Element: Stack },
]);

const StackoverflowTag = Dict([
  { getterName: 'getElementId', key: 'elementId', Element: Leaf },
  { getterName: 'getName', key: 'name', Element: EnrichedText },
  { getterName: 'getAnswersCount', key: 'answersCount', Element: Leaf },
  { getterName: 'getAnswersScore', key: 'answersScore', Element: Leaf },
  { getterName: 'getQuestionsCount', key: 'questionsCount', Element: Leaf },
  { getterName: 'getQuestionsScore', key: 'questionsScore', Element: Leaf },
]);

const StackoverflowData = Dict([
  { getterName: 'getId', key: 'id', Element: Leaf },
  { getterName: 'getLastScrapDay', key: 'lastScrapDay', Element: Leaf },
  { getterName: 'getFullname', key: 'fullname', Element: Leaf },
  { getterName: 'getWebsite', key: 'website', Element: Leaf },
  { getterName: 'getLocation', key: 'location', Element: Location },
  { getterName: 'getSummary', key: 'summary', Element: EnrichedTextAndStack },
  { getterName: 'getAge', key: 'age', Element: Leaf },
  { getterName: 'getReputation', key: 'reputation', Element: Leaf },
  { getterName: 'getAnswersCount', key: 'answersCount', Element: Leaf },
  { getterName: 'getQuestionsCount', key: 'questionsCount', Element: Leaf },
  { getterName: 'getAcceptRate', key: 'acceptRate', Element: Leaf },
  { getterName: 'getTags', key: 'tags', Element: List(StackoverflowTag) },
  { getterName: 'getRelevantFacts', key: 'relevantFacts', Element: List(RelevantFact) },
  { getterName: 'getStack', key: 'stack', Element: Stack },
]);

const HiresweetWishes = Dict([
  {
    getterName: 'getSkills',
    key: 'skills',
    Element: Dict([
      { getterName: 'getTarget', key: 'target', Element: Leaf },
      { getterName: 'getOkWith', key: 'okWith', Element: Leaf },
    ]),
  },
]);

const HiresweetData = Dict([{ getterName: 'getWishes', key: 'wishes', Element: HiresweetWishes }]);

const SourceData = Dict([
  { getterName: 'getLinkedin', key: 'linkedin', Element: LinkedinData },
  { getterName: 'getGithub', key: 'github', Element: GithubData },
  { getterName: 'getStackoverflow', key: 'stackoverflow', Element: StackoverflowData },
  { getterName: 'getHiresweet', key: 'hiresweet', Element: HiresweetData },
]);

const ProfileData = Dict([
  { getterName: 'getEmail', key: 'email', Element: Leaf },
  { getterName: 'getFirstname', key: 'firstname', Element: Leaf },
  { getterName: 'getLastname', key: 'lastname', Element: Leaf },
  { getterName: 'getHeadline', key: 'headline', Element: EnrichedTextAndStack },
  { getterName: 'getLocation', key: 'location', Element: Location },
  { getterName: 'getPhotoLink', key: 'photoLink', Element: Leaf },
  { getterName: 'getSummary', key: 'summary', Element: EnrichedTextAndStack },
  { getterName: 'getSmartSummary', key: 'smartSummary', Element: SmartSummary },
  { getterName: 'getSources', key: 'sources', Element: Sources },
  { getterName: 'getExperiences', key: 'experiences', Element: List(Experience) },
  { getterName: 'getEducation', key: 'education', Element: List(Education) },
  { getterName: 'getRelevantTags', key: 'relevantTags', Element: List(RelevantTag) },
  { getterName: 'getSourceData', key: 'sourceData', Element: SourceData },
  { getterName: 'getEmailHints', key: 'emailHints', Element: Leaf },
  { getterName: 'getWarnings', key: 'warnings', Element: Warnings },
]);

export default ProfileData;
