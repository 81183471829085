import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../baseUrl';
import { Image, Grid, Button, Modal, Input as SInput } from 'semantic-ui-react';

const storePictureAndGetPhotoLink = async ({ filename, content }) => {
  const result = (await axios.post(`${baseUrl}/pictures/storePicture`, { filename, content })).data;
  if (result.error) {
    throw Error(result.error.message);
  }
  return result.photoLink;
}

const deletePicture = async ({ photoLink }) => {
  console.log('delete picture...')
  const result = (await axios.post(`${baseUrl}/pictures/deletePicture`, { photoLink })).data;
  if (result.error) {
    throw Error(result.error.message);
  }
  console.log('done.')
  console.log(result)

}

class PhotoLinkModal extends React.Component {
  state = {};

  componentWillMount() {
    this.setState({
      photoLink: this.props.photoLink,
    })
  }


  onChangeHandler = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        filename: (file || {}).name,
        content: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  onSubmit = async () => {
    const { filename, content, photoLink: previousPhotoLink } = this.state;
    if (!filename || !content) {
      return alert('Should upload file first');
    }
    try {
      if (previousPhotoLink) {
        await deletePicture({ photoLink: previousPhotoLink });
      }
      const photoLink = await storePictureAndGetPhotoLink({ filename, content });
      await this.props.setPhotoLink({ photoLink });
      this.setState({ photoLink });
      alert('Done !');
    } catch (e) {
      console.log(e)
      return alert(`Error : ${JSON.stringify(e, null, 4)}`);
    }

  }
  render() {
    return (
      <div>
        <Modal
          onClose={() => this.props.onClose()}
          open
          closeIcon
        >
          <Modal.Header>Update Picture</Modal.Header>
          <Modal.Content>
            <Grid colums={2}>
              <Grid.Column width={8}>
                <Image
                  src={this.state.photoLink || '/images/default-avatar-1.svg'}
                  circular
                  style={{
                    width: '144px',
                    height: '144px'
                  }}
                  onError={(e) => {
                    e.target.src = '/images/default-avatar-1.svg';
                  }}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid.Row>
                  <SInput type='file' name='file' onChange={this.onChangeHandler} />
                </Grid.Row>
                <Grid.Row>
                  <Button color='green' onClick={() => this.onSubmit()}>Submit</Button>
                </Grid.Row>
              </Grid.Column>
            </Grid>

          </Modal.Content>
        </Modal>
      </div>
    )

  }
}

export default PhotoLinkModal;