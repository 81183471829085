import _ from 'underscore';
import React from 'react';

const DoublePanesCanvas = ({ 
  leftWidthPercent,
  rightWidthPercent,
  children, 
}) => (
  <div
    style={{
      position: 'fixed',
      top: '68px',
      left: 0,
      height: 'calc(100vh - 68px)',
      width: '100%',
    }}
  >
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'inline-block',
          paddingTop: '5px',
          paddingBottom: '20px',
          height: '100%',
          width: leftWidthPercent,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {_.isArray(children) && children.length > 0 && children[0]}
      </div>
      <div
        style={{
          display: 'inline-block',
          paddingTop: '5px',
          paddingBottom: '20px',
          height: '100%',
          width: rightWidthPercent,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {_.isArray(children) && children.length > 1 && children[1]}
      </div>
    </div>
  </div>
);

export default DoublePanesCanvas;
