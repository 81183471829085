import React, { Component } from 'react';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import axios from 'axios';
import { Grid, Icon, Button, Dropdown, Popup, Modal } from 'semantic-ui-react';
import { Input, Select, List, SweetForm, Checkbox, enhance } from 'sweetform';
import { SendNotificationModal } from './SendNotificationsModal';
import { Textarea } from '../../common';
import moment from 'moment';

const sheetHiresweetDescriptionsToFormHiresweetDescriptions = (hiresweetDescriptions) => {
  return _.map(hiresweetDescriptions, (desc) => {
    const { id, hidden, description, hiddenUntilTimestamp } = desc;
    return {
      id,
      hidden,
      text: ((description || {}).content || {}).text,
      hiddenUntilTimestamp,
    }
  })
}

const sheetDataToFormData = (data) => {
  const { firstname, lastname, photoLink, location, headline, summary, hiresweetDescriptions, sources } = data || {};
  const formattedDescriptions = sheetHiresweetDescriptionsToFormHiresweetDescriptions(hiresweetDescriptions);
  const formattedSources = _.map(sources, (link, name) => ({ name, link }))
  const formData = {
    firstname,
    lastname,
    photoLink,
    location: ((location || {}).name || {}).default,
    headline: ((headline || {}).content || {}).text,
    summary: ((summary || {}).content || {}).text,
    hiresweetDescriptions: formattedDescriptions,
    sources: formattedSources
  }
  return formData;
}
export const getRandomString = (size) => {
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let text = "";
  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const formHiresweetDescriptionsToSheetHiresweetDescriptions = (hiresweetDescriptions) => {
  const formattedDescriptions = _.map(hiresweetDescriptions, (description) => {
    const { id, hidden, text, hiddenUntilTimestamp } = description;
    return {
      id: id || "description-" + getRandomString(6),
      hidden,
      description: { content: { text } },
      hiddenUntilTimestamp,
    }
  })
  return formattedDescriptions;
}

const formDataToSheetData = (data) => {
  const { firstname, lastname, photoLink, location, headline, summary, hiresweetDescriptions, sources } = data;
  const formattedDescriptions = formHiresweetDescriptionsToSheetHiresweetDescriptions(hiresweetDescriptions);
  const formattedSources = _.object(_.pluck(sources, 'name'), _.pluck(sources, 'link'))
  const sheetData = {
    ...(_.isString(firstname) && { firstname }),
    ...(_.isString(lastname) && { lastname }),
    ...(_.isString(photoLink) && { photoLink }),
    ...(_.isString(location) && { location: { name: { default: location } } }),
    ...(_.isString(headline) && { headline: { content: { text: headline } } }),
    ...(_.isString(summary) && { summary: { content: { text: summary } } }),
    ...(!_.isEmpty(formattedDescriptions) && { hiresweetDescriptions: formattedDescriptions }),
    ...(!_.isEmpty(formattedSources) && { sources: formattedSources })
  }
  return sheetData;
}

const setMainEducationFromIndex = ({ education, mainEducationIndex }) => {
  if (!_.isNumber(mainEducationIndex)) {
    return education;
  }
  if (education.length <= mainEducationIndex) {
    return education;
  }
  return _.map(education, (education, i) => {
    if (i == mainEducationIndex) {
      return {
        ...education,
        isMain: true,
      };
    } else {
      return {
        ...education,
        isMain: false,
      };
    }
  });
}

const getMainEucationIndex = ({ education }) => {
  for (let i = 0; i < (education || []).length; i++) {
    if (education[i].isMain) {
      return i;
    }
  }
}

const SOURCE_NAME_OPTIONS = [
  'linkedin',
  'stackOverflow',
  'github',
  'twitter',
  'website',
  'viadeo',
  'coderWall',
  'codePen',
  'bitBucket',
  'reddit',
  'angelList',
  'meetup',
  'googlePlus',
  'dribbble',
  'behance',
  'pinterest',
  'drupal',
  'joomla',
  'aboutMe',
  'medium',
  'quora',
  'speakerDeck',
]
const SourceField = enhance(() => (
  <Grid columns={2}>
    <Grid.Column width={4}><Select field="name" options={_.map(SOURCE_NAME_OPTIONS, (name) => ({ label: name, value: name }))} /></Grid.Column>
    <Grid.Column width={8}><Input field="link" placeholder='https://...' /></Grid.Column>
  </Grid>
))

const HiresweetDescription = enhance(({ value }) => {
  const hiddenUntilTimestamp = value ? value.get('hiddenUntilTimestamp') : null;
  const hidden = value ? value.get('hidden') : null;
  return (
    <Grid>
      {/*<Grid.Column width={2}>Hide ?</Grid.Column>
          <Grid.Column width={1}><Checkbox field="hidden" defaultValue={false} /></Grid.Column>*/}
      <Grid.Row>
        <div>
          {hiddenUntilTimestamp ? `Hidden until ${moment(hiddenUntilTimestamp).format('YYYY-MM-DD hh:mm:ss')}\n` : null}<br />
          {hidden ? `Hidden by candidate` : null} </div>
      </Grid.Row>
      <Grid.Row>
        <Input field="id" type="hidden" />
        <Input field="hiddenUntilTimestamp" type="hidden" />
        <Textarea field="text" rows='20' cols='70'></Textarea>
      </Grid.Row>
    </Grid>
  )
})

const BLURB_TEMPLATE = (
  `**Son expérience chez XXXX et XXXX :**
**Que recherche-t-il aujourd'hui ?**`
)


class SetDataFromLinkedinModal extends Component {
  state = {
    loading: false
  }
  onConfirm = async (mode) => {
    this.setState({ loading: true })
    try {
      const result = (await axios.get(`${baseUrl}/candidates/${this.props.id}/refreshDataFromLinkedin?mode=${mode}`)).data;
      if (result.error) {
        throw Error(result.error);
      }
      alert('Done !')
      this.props.onReload();
      this.props.onClose();
    } catch (e) {
      alert(`Error : ${e.message}`)
    }
    this.setState({ loading: false })

  }
  render = () => (
    <Modal onClose={this.props.onClose} open closeIcon >
      <Modal.Header>Set data from linkedin</Modal.Header>
      <Modal.Content style={{ fontSize: 14 }}>
        <b>All candidate's data will be overwritten by data from linkedin profile : headline, experiences, educations, endorsements..</b><br />
        Please check that candidate has not already filled these informations manually.<br />
        Do you want to proceed ?
      </Modal.Content>
      <Modal.Actions>
        {this.state.loading ? (
          <Icon name='circle notched' loading />
        ) : (
          <div>
            <Button onClick={() => this.onConfirm("normal")}>
              Confirm without scrap
            </Button>
            <Button positive onClick={() => this.onConfirm("instantscrap")}>
              Confirm
            </Button>
            <Button negative onClick={() => this.props.onClose()}>
              Cancel
            </Button>
          </div>
        )}

      </Modal.Actions>
    </Modal>
  )
}

class WatchEditData extends Component {
  state = {};

  handleSave = async () => {
    try {
      const newData = this.state.value ? formDataToSheetData(this.state.value) : this.props.data;
      const education = setMainEducationFromIndex({
        education: (this.props.data || {}).education,
        mainEducationIndex: this.state.mainEducationIndex,
      })
      await this.props.onSubmit({ ...newData, education });
      alert('Saved.')
    } catch (e) {
      alert(e.message)
    }
  }

  handleChange = (value) => {
    if (!value.overwritePhotoLink) {
      value = _.omit(value, "photoLink")
    }
    value = _.omit(value, "overwritePhotoLink")
    this.setState({ value })
  }

  handleSaveDescriptionWithTimeoutAndOpenEmailModal = async () => {
    const { hiresweetDescriptions } = (this.state.value || {});
    if (_.isEmpty(hiresweetDescriptions) || _.isEmpty(hiresweetDescriptions[0].text)) {
      return alert('Empty hiresweet descriptions');
    }
    const formattedDescriptions = formHiresweetDescriptionsToSheetHiresweetDescriptions(_.map(hiresweetDescriptions, (description) => ({
      ...description,
      hiddenUntilTimestamp: 1000 * moment().add(2, 'days').unix()
    })))
    this.props.onSubmit({
      hiresweetDescriptions: formattedDescriptions
    });
    this.setState({ notificationModalOpen: true });
  }

  handleSaveDescriptionAndOpenEmailModal = async () => {
    const { hiresweetDescriptions } = (this.state.value || {});
    if (_.isEmpty(hiresweetDescriptions) || _.isEmpty(hiresweetDescriptions[0].text)) {
      return alert('Empty hiresweet descriptions');
    }
    const formattedDescriptions = formHiresweetDescriptionsToSheetHiresweetDescriptions(_.map(hiresweetDescriptions, (description) => ({
      ...description,
    })))
    this.props.onSubmit({
      hiresweetDescriptions: formattedDescriptions
    });
    this.setState({ notificationModalOpen: true });
  }
  handleSaveDescription = async () => {
    const { hiresweetDescriptions } = (this.state.value || {});
    if (_.isEmpty(hiresweetDescriptions) || _.isEmpty(hiresweetDescriptions[0].text)) {
      return alert('Empty hiresweet descriptions');
    }
    const formattedDescriptions = formHiresweetDescriptionsToSheetHiresweetDescriptions(_.map(hiresweetDescriptions, (description) => ({
      ...description,
    })))
    this.props.onSubmit({
      hiresweetDescriptions: formattedDescriptions
    });
    alert('Saved.')
  }

  handleRemoveHiddenUntilTimestamp = async () => {
    const { hiresweetDescriptions } = (this.state.value || {});
    if (_.isEmpty(hiresweetDescriptions) || _.isEmpty(hiresweetDescriptions[0].text)) {
      return alert('Make a change to description first');
    }
    const formattedDescriptions = formHiresweetDescriptionsToSheetHiresweetDescriptions(_.map(hiresweetDescriptions, (description) => (_.omit(description, 'hiddenUntilTimestamp'))))
    this.props.onSubmit({
      hiresweetDescriptions: formattedDescriptions
    });
  }

  handleRemoveHidden = async () => {
    const { hiresweetDescriptions } = (this.state.value || {});
    if (_.isEmpty(hiresweetDescriptions) || _.isEmpty(hiresweetDescriptions[0].text)) {
      return alert('Make a change to description first');
    }
    const formattedDescriptions = formHiresweetDescriptionsToSheetHiresweetDescriptions(_.map(hiresweetDescriptions, (description) => (_.omit(description, 'hidden'))))
    this.props.onSubmit({
      hiresweetDescriptions: formattedDescriptions
    });
  }

  handleCloseEmailModal = () => {
    this.setState({ notificationModalOpen: false })
  }

  handleChangeMainEducationIndex = (e, { value }) => {
    this.setState({ mainEducationIndex: value });
  }

  handleOpenSetDataModal = () => {
    this.setState({ setDataModalOpen: true });
  }

  handleCloseSetDataModal = () => {
    this.setState({ setDataModalOpen: false });
  }

  render() {

    const { data, id } = this.props;
    const { notificationModalOpen, mainEducationIndex, setDataModalOpen } = this.state;
    const formattedData = sheetDataToFormData(data || {});
    const existingMainEducationIndex = getMainEucationIndex({ education: data.education });
    const currentMainEducationIndex = _.isNumber(mainEducationIndex) ? mainEducationIndex : existingMainEducationIndex;
    const mainEducationOptions = _.map((data || {}).education, (education, index) => {
      return {
        key: index,
        value: index,
        text: education.schoolName,
      };
    });
    return (
      <div>
        <Button onClick={() => this.handleSave()} color='green'><Icon name='save' /> Save</Button>

        <Button onClick={() => this.handleOpenSetDataModal()} color='blue'><Icon name='copy' /> Set data from linkedin</Button>
        <SweetForm initialValues={formattedData} onChange={this.handleChange}>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Firstname</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='firstname' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Lastname</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='lastname' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Main Education</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Dropdown
                  inline
                  options={mainEducationOptions}
                  value={currentMainEducationIndex}
                  error={currentMainEducationIndex === undefined}
                  onChange={this.handleChangeMainEducationIndex}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Location</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='location' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Photo Link</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='photoLink' />
              </Grid.Column>
              <Grid.Column width={6}>
                <p>Overwrite ?</p>
                <Checkbox field='overwritePhotoLink' default={false} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Headline</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='headline' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Summary</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Textarea field='summary' rows='10' cols='40' />
              </Grid.Column>
            </Grid.Row>
            <Popup
              trigger={
                <Button onClick={() => this.handleSaveDescriptionWithTimeoutAndOpenEmailModal()} color='orange'><Icon name='file text' />Save with mail + hiding</Button>
              }
              content={
                <div><b>To use for first time save.</b><br />Description will be hidden during 48h.<br />Candidate will receive an email.</div>
              }
            />
            <Popup
              trigger={
                <Button onClick={() => this.handleSaveDescriptionAndOpenEmailModal()} color='yellow'><Icon name='file text' />Save with mail</Button>
              }
              content={
                <div><b>To use on friday.</b><br />Description will not be hidden during 48h.<br />Candidate will receive an email.</div>
              }
            />
            <Popup
              trigger={
                <Button onClick={() => this.handleSaveDescription()} color='green'><Icon name='file text' /> Save</Button>
              }
              content={
                <div><b>To use for small edits.</b><br />Description will not be hidden during 48h.<br />No email will be sent.</div>
              }
            />

            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Hiresweet Descriptions</b>
                </label>
                <Popup
                  content={<div style={{ 'white-space': 'pre-wrap' }}>{BLURB_TEMPLATE}</div>}
                  trigger={
                    <Button basic mini onClick={() => { navigator.clipboard.writeText(BLURB_TEMPLATE) }} color='blue'>
                      <Icon name={'copy'} />Template
                    </Button>
                  }
                />


              </Grid.Column>
              <Grid.Column width={12}>
                <List component={HiresweetDescription} field='hiresweetDescriptions' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Sources</b>
                </label>
              </Grid.Column>
              <Grid.Column width={12}>
                <List component={SourceField} field='sources' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Button size='tiny' onClick={() => this.handleRemoveHiddenUntilTimestamp()}>
                Remove hidden until
              </Button>
              <Button size='tiny' onClick={() => this.handleRemoveHidden()}>
                Remove hidden
              </Button>
            </Grid.Row>
          </Grid>

        </SweetForm>
        {notificationModalOpen && (
          <SendNotificationModal id={id} notificationId={'reviewSummary'} onClose={this.handleCloseEmailModal} />
        )}
        {setDataModalOpen && (
          <SetDataFromLinkedinModal
            id={id}
            onClose={this.handleCloseSetDataModal}
            onReload={this.props.onReload}
          />
        )}
      </div>
    );
  }
}

export default WatchEditData;
