import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { Checkbox, Grid, Icon, Table } from 'semantic-ui-react';

import ProfileActivityDisplay from './ProfileActivityDisplay';
import { DetailedProfileView } from '../TalentReveal/Talents';

class ManualRecommendationsTable extends Component {
  async loadData() {
    this.setState({ loadingData: true });
    const profiles = this.props;
    const profilesData = profiles;
    this.setState({ profilesData, loadingData: false });
  }

  onClickOnProfile = async (profile, criteria) => {
    if (criteria) {
      await this.getScoreWithDetails(profile, criteria);
    }
    this.setState({ selectedProfile: profile });
  };

  getScoreWithDetails = async (profile, criteria) => {
    const payload = {
      job: { criteria },
      profilePath: profile.profile_path,
    };
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/scoreWithDetails';
    const results = (await axios.post(url, payload, customHeaders)).data;
    const scoreWithDetails = results.results;
    this.setState({ scoreWithDetails });
  };

  render() {
    const {
      profiles,
      criteria,
      hideProfileForMission,
      onclickOnCheckboxProfile,
      selectedClient,
      selectedMissionId,
      selectedProfileIds,
    } = this.props;
    const { selectedProfile, scoreWithDetails } = this.state || {};

    const now = new Date();
    const snoozed1month = now.getTime() + 30 * 24 * 3600 * 1000;
    const snoozed3months = now.getTime() + 3 * 30 * 24 * 3600 * 1000;
    const snoozed6months = now.getTime() + 6 * 30 * 24 * 3600 * 1000;

    return (
      <Grid columns={3}>
        <Grid.Column
          width={5}
          style={{
            maxHeight: '600px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <h3> Search Results </h3>
          <Table
            style={{
              width: '100%',
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='left'>Select</Table.HeaderCell>
                <Table.HeaderCell textAlign='left'>Talent</Table.HeaderCell>
                <Table.HeaderCell textAlign='left'>Score</Table.HeaderCell>
                <Table.HeaderCell textAlign='left'>Hide for Mission</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {profiles
                ? _.map(profiles.slice(0, 100), (profile, index) => {
                    const isSelected = selectedProfileIds.includes(profile.id);
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <Checkbox
                            checked={!!isSelected}
                            onChange={() => onclickOnCheckboxProfile({ profileId: profile.id })}
                          />
                        </Table.Cell>
                        <Table.Cell
                          style={
                            selectedProfile && selectedProfile.id === profile.id
                              ? { cursor: 'pointer', fontWeight: 'bold', color: 'green' }
                              : { cursor: 'pointer' }
                          }
                          onClick={() => this.onClickOnProfile(profile, criteria)}
                          key={profile.id}
                          textAlign='left'
                        >
                          {profile.firstname + ' ' + profile.lastname}
                        </Table.Cell>
                        <Table.Cell>
                          {profile.score ? (
                            <div>
                              <br />
                              {(profile.score * 100).toFixed(2)}
                            </div>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          <a
                            style={{ fontSize: 10, cursor: 'pointer', marginLeft: 5, color: 'red' }}
                            onClick={() =>
                              hideProfileForMission({ profileId: profile.id, snoozedUntilTimestamp: snoozed1month })
                            }
                          >
                            1M
                          </a>
                          <a
                            style={{ fontSize: 10, cursor: 'pointer', marginLeft: 5, color: 'red' }}
                            onClick={() =>
                              hideProfileForMission({ profileId: profile.id, snoozedUntilTimestamp: snoozed3months })
                            }
                          >
                            3M
                          </a>
                          <a
                            style={{ fontSize: 10, cursor: 'pointer', marginLeft: 5, marginRight: 5, color: 'red' }}
                            onClick={() =>
                              hideProfileForMission({ profileId: profile.id, snoozedUntilTimestamp: snoozed6months })
                            }
                          >
                            6M
                          </a>
                          <Icon
                            link
                            color={'red'}
                            name='delete'
                            onClick={() => hideProfileForMission({ profileId: profile.id })}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                : null}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column
          width={5}
          style={{
            maxHeight: '600px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {selectedProfile ? (
            <ProfileActivityDisplay
              profiles={profiles}
              key={selectedProfile.id}
              selectedProfileId={selectedProfile.id}
              selectedClient={selectedClient}
              selectedMissionId={selectedMissionId}
            />
          ) : null}
        </Grid.Column>

        <Grid.Column width={6}>
          {selectedProfile ? (
            <div>
              <h3> Detailed Profile View</h3>
              <DetailedProfileView
                key={selectedProfile.profile_path}
                profilePath={selectedProfile.profile_path}
                criteria={criteria}
                scoreWithDetails={scoreWithDetails}
              />
            </div>
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

export default ManualRecommendationsTable;
