import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Table, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class SendStats extends Component {
  state = {};
  componentWillMount() {
    this.handleLoad();
  }
  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/strat/sends/stats`);
    this.setState(data);
  };
  renderTable(category) {
    const { byDays } = this.state;
    const groupingFunc = (day) => day.slice(0, 5);
    let groups = [];
    _.each(byDays, (dayStats) => {
      const day = dayStats.day;
      const groupId = groupingFunc(day);
      if (_.isEmpty(groups) || _.last(groups).id !== groupId) {
        const newGroup = {
          id: groupId,
          nbSent: 0,
          nbNewSent: 0,
          cumulatedNbSent: _.isEmpty(groups)
            ? 0
            : _.last(groups).cumulatedNbSent,
          cumulatedNbNewSent: _.isEmpty(groups)
            ? 0
            : _.last(groups).cumulatedNbNewSent,
        };
        groups.push(newGroup);
      }
      const curStats = dayStats[category] || {};
      _.last(groups).nbSent += curStats.nbSent || 0;
      _.last(groups).cumulatedNbSent += curStats.nbSent || 0;
      _.last(groups).nbNewSent += curStats.nbNewSent || 0;
      _.last(groups).cumulatedNbNewSent += curStats.nbNewSent || 0;
    });
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{category}</Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              {_.map(groups, ({ id }) => (
                <Table.HeaderCell>{id}</Table.HeaderCell>
              ))}
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>#Sends</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'nb_sent_' + index}>
                    {group.nbSent}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>#NewSends</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'nb_new_sent_' + index}>
                    {group.nbNewSent} (
                    {Math.round(
                      (group.nbNewSent / Math.max(1, group.nbSent)) * 100,
                    )}
                    %)
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Cumulative Sends</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'c_nb_sent_' + index}>
                    {group.cumulatedNbSent}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Cumulative NewSends</Table.Cell>
                {_.map(groups, (group, index) => (
                  <Table.Cell key={'c_nb_new_sent_' + index}>
                    {group.cumulatedNbNewSent}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    return (
      <div>
        {this.renderTable('total')}
        <br />
        <br />
        {_.map(this.state.categories, (category, index) => (
          <div key={index}>
            {this.renderTable(category)}
            <br />
          </div>
        ))}
      </div>
    );
  }
}

export default SendStats;
