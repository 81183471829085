import React from 'react';
import TargetCollectionSettings from './TargetCollectionSettings';
import SimilarProfilesSettings from './SimilarProfilesSettings';
import RefreshMoveDetectorSettings from './RefreshMoveDetectorSettings';
import RefreshExtendDeleteFromCollectionSettings from './RefreshExtendDeleteFromCollectionSettings';

export default {
  'target-collection-linkedin': {
    name: 'Target Collection',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: (props) => <TargetCollectionSettings {...props} />,
  },
  'similar-linkedin-profiles': {
    name: 'Similar Profiles',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: (props) => <SimilarProfilesSettings {...props} />,
  },
  'similar-linkedin-profiles-relaxed': {
    name: 'Similar Profiles Relaxed',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: (props) => <SimilarProfilesSettings {...props} />,
  },
  'sweetsheet-items-similar-profiles': {
    name: 'Similar to SweetsheetItems',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'missing-sweetsheet-items': {
    name: 'Missing SweetsheetItems',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-linkedin-ever-pushed': {
    name: 'Refresh Ever Pushed',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-more-than-k-days': {
    name: 'Refresh > k days',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-linkedin-opportunities': {
    name: 'Refresh Opportunities',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'workplaces-sweetsheet-items': {
    name: 'Sweetsheet Items Workplaces',
    tags: ['Companies'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'workplace-profiles': {
    name: "Workplaces In Profile's Experiences",
    tags: ['Companies'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'school-profiles': {
    name: "School In Profile's Education",
    tags: ['Companies'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-instant-flow': {
    name: 'Refresh InstantFlow',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-move-detector': {
    name: 'Refresh Move Detector',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: (props) => <RefreshMoveDetectorSettings {...props} />,
  },
  'refresh-ever-enabled-candidates': {
    name: 'Refresh Ever Enabled Candidates',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: (props) => <RefreshMoveDetectorSettings {...props} />,
  },
  'github-mail-to-linkedin-order': {
    name: 'Github Mail to Linkedin',
    tags: ['Profiles'],
    sources: ['github'],
    settingsComponent: () => <div />,
  },
  'mail-to-mini-linkedin': {
    name: 'Mail to Mini Linkedin',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'mail-to-mini-linkedin-batch': {
    name: 'Mail to Mini Linkedin Batch',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-max-matching': {
    name: 'Refresh Max Matching',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'refresh-and-extend-from-collection': {
    name: 'Refresh Extend From Collection',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: (props) => <RefreshExtendDeleteFromCollectionSettings {...props} />,
  },
  'mail-to-linkedin': {
    name: 'Mail to Linkedin',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'crazy-mail-to-mini-linkedin-batch': {
    name: 'Crazy Scrap Mail',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'mini-linkedin-search-results': {
    name: 'Mini Linkedin Search Results',
    tags: ['Profiles'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
  'workplace-crunchbase': {
    name: 'Workplaces In Crunchbase items',
    tags: ['Companies'],
    sources: ['linkedin'],
    settingsComponent: () => <div />,
  },
};
