import axios from 'axios';
import _ from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import baseUrl from '../../baseUrl';
import { SelectPlatformOffers } from '../../Sales/SalesBoard/EditOfferModalComponents';
import { Table, Icon, Grid, Popup, Button } from 'semantic-ui-react';
import { SweetForm } from 'sweetform';
import { MATCHING_LEVELS } from './utils';

const OffersTable = ({ offers, sweetapp, onUnupload, onUpload, onUnlock, getItemColor, disableUpload }) => {
  const jobOfferIdToOfferState = _.indexBy(_.values(sweetapp), ({ jobOfferId }) => jobOfferId);
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Offer Id</Table.HeaderCell>
          <Table.HeaderCell>Client Id</Table.HeaderCell>
          <Table.HeaderCell>Sweethub Offer Id</Table.HeaderCell>
          <Table.HeaderCell>Sweetapp Profile Id</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>State</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.HeaderCell>Lock</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(offers, (offer) => {
          const offerState = jobOfferIdToOfferState[offer.id] || {};
          const { sweethubOfferId } = offer;
          const { sweetappProfile } = offerState;
          return (
            <Table.Row style={{ backgroundColor: getItemColor(offerState) }}>
              <Table.Cell>{offer.id}</Table.Cell>
              <Table.Cell>{offer.clientId}</Table.Cell>
              <Table.Cell>{offer.sweethubOfferId}</Table.Cell>
              <Table.Cell>{offerState.profileId}</Table.Cell>
              <Table.Cell>{offerState.status}</Table.Cell>
              <Table.Cell>{offerState.sweetappUploadTimestamp && moment(offerState.sweetappUploadTimestamp).format('YYYY-MM-DD')}</Table.Cell>
              <Table.Cell>{sweetappProfile && sweetappProfile.state}</Table.Cell>

              <Table.Cell>
                {(_.isEmpty(offerState.status) || offerState.status === 'pending') &&
                  !disableUpload &&
                  !(offerState.lockedFor === 'upload') ? (
                  <div>
                    {_.map(MATCHING_LEVELS, ({ value, color, number }) => (
                      <Popup
                        content={sweethubOfferId ? `Upload ${value}` : `Upload ${value} (no enrichments)`}
                        trigger={
                          <Button
                            size='mini'
                            style={{ cursor: 'pointer' }}
                            color={sweethubOfferId ? color : 'red'}
                            onClick={() => onUpload(offer, value)}
                          >{number}</Button>
                        }
                      />
                    ))}

                  </div>
                ) : null}
                {offerState.status === 'uploaded' && !(offerState.lockedFor === 'unupload') ? (
                  <Popup
                    content={'Unupload'}
                    trigger={
                      <Icon
                        name='undo'
                        style={{ cursor: 'pointer' }}
                        color='orange'
                        onClick={() => onUnupload(offer)}
                      />
                    }
                  />
                ) : null}
              </Table.Cell>
              <Table.Cell>
                {offerState.lockedFor ? (
                  <Popup
                    content={offerState.error ? 'Unlock :\n' + offerState.error : 'Unlock'}
                    trigger={
                      <div style={{ cursor: 'pointer' }}>
                        <Icon name='lock' color='red' onClick={() => onUnlock(offer)} />
                        {offerState.lockedFor}
                      </div>
                    }
                  />
                ) : null}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const getSweethubOfferIdFromJobOfferIds = async (jobOfferIds) => {
  try {
    const { data: sweethubOffers } = await axios.post(`${baseUrl}/hiresweetwatch/offers/miniSweethubOffers`, {
      jobOfferIds,
    });
    const jobOfferIdToSweethubOfferId = _.object(
      _.pluck(sweethubOffers, 'platformId'),
      _.pluck(sweethubOffers, 'id'),
    )
    return jobOfferIdToSweethubOfferId;
  } catch (error) {
    alert(error + `\nwith inpue ${jobOfferIds}`);
    return [];
  }
};

const getSweetappOffers = async () => { };

class WatchUpload extends Component {
  state = {
    sweetapp: null,
    selectedJobOfferId: null,
    force: false,
  };

  componentWillMount() {
    this.handleLoad();
  }

  handleChangeJobOfferId = ({ jobOfferId }) => {
    this.setState({ selectedJobOfferId: jobOfferId });
  };

  handleUploadToSelectedJobOfferId = async (prependingMode) => {
    const { selectedJobOfferId } = this.state;
    const res = await getSweethubOfferIdFromJobOfferIds([selectedJobOfferId]);
    console.log({res, selectedJobOfferId})
    if (_.isEmpty(res)) {
      return alert(`No sweethub offer found for jobOfferId ${selectedJobOfferId}`)
    }
    this.onUpload({ id: selectedJobOfferId, sweethubOfferId: res[selectedJobOfferId] }, 'normal', prependingMode);
  };

  handleLoad = async () => {
    try {
      const { id } = this.props;
      console.log(`${baseUrl}/candidates/${id}/sweetapp...`)
      const data = (await axios.get(`${baseUrl}/candidates/${id}/sweetapp`)).data;
      if (!data || data.error) {
        throw Error('Error in result');
      }
      const sweetapp = data.sweetapp || {};
      console.log(`loadOffers...`)
      const { clientOffers } = await this.loadOffers(sweetapp);
      console.log(`done.`)

      this.setState({
        sweetapp,
        clientOffers,
      });
    } catch (e) {
      console.log(e);
      alert('Failed loading sweetapp state');
    }
  };

  setForce = (force) => {
    this.setState({ force });
  };

  loadOffers = async (sweetapp) => {
    if (sweetapp) {
      try {
        const clientOfferIds = _.pluck(_.values(sweetapp), 'jobOfferId');
        const jobOfferIdToSweethubOffers = await getSweethubOfferIdFromJobOfferIds(clientOfferIds);
        const clientOffers = _.map(clientOfferIds, (offerId) => ({
          id: offerId,
          sweethubOfferId: jobOfferIdToSweethubOffers[offerId],
        }));

        return {
          clientOffers,
        };
      } catch (e) {
        alert(e.message || 'Error loading offers.');
      }
    }
  };

  getBaseActionFromOffer = (offer, prependingMode) => {
    return {
      jobOfferId: offer.id,
      ...(offer.sweethubOfferId && { sweethubOfferId: offer.sweethubOfferId }),
      ...(!offer.sweethubOfferId && { noEnrichments: true }),
      ...(_.isBoolean(prependingMode) ? { prependingMode } : { prependingMode: true }),
    };
  };

  getItemActionUrl = () => {
    const id = this.props.id;
    const url = `${baseUrl}/candidates/${id}/sweetapp`;
    return url;
  };

  onUpload = async (offer, matchingLevel, prependingMode) => {
    const { force } = this.state;
    const url = this.getItemActionUrl();
    const baseAction = this.getBaseActionFromOffer(offer, prependingMode);
    const action = {
      type: 'upload',
      force,
      matchingLevel,
      ...baseAction,
    };
    try {
      const { data } = await axios.post(url, { action });
      if (data.error) {
        console.log('error', data);
        throw Error(data.error);
      }
    } catch (e) {
      alert(e.message);
    }
    this.setForce(false);
    this.handleLoad();
  };

  onUnupload = async (offer) => {
    const { force } = this.state;
    const url = this.getItemActionUrl();
    const baseAction = this.getBaseActionFromOffer(offer);
    const action = {
      type: 'unupload',
      force,
      ...baseAction,
    };
    try {
      const { data } = await axios.post(url, { action });
      if (data.error) {
        console.log('error', data);
        throw Error(data.error);
      }
    } catch (e) {
      alert(e.message);
    }
    this.setForce(false);
    this.handleLoad();
  };

  onUnlock = async (offer) => {
    const url = this.getItemActionUrl();
    const baseAction = this.getBaseActionFromOffer(offer);
    const action = {
      type: 'unlock',
      ...baseAction,
    };
    try {
      const { data } = await axios.post(url, { action });
      if (data.error) {
        console.log('error', data);
        throw Error(data.error);
      }
    } catch (e) {
      alert(e.message);
    }
    this.handleLoad();
  };

  getItemColor(offerState) {
    if (offerState.status === 'uploaded') {
      return '#ddffdd';
    } else {
      return '#ffffdd';
    }
  }

  render() {
    const { selectedJobOfferId, clientOffers, force, sweetapp } = this.state;
    if (!sweetapp) {
      return <div>LOADING</div>;
    }
    return (
      <div>
        {force ? (
          <Button icon color='blue' onClick={() => this.setForce(false)}>
            <Icon name='shield' />
            Force
          </Button>
        ) : (
          <Button icon basic color='blue' onClick={() => this.setForce(true)}>
            <Icon name='shield' />
          </Button>
        )}
        <h2>Upload to new offer</h2>
        <SweetForm onChange={this.handleChangeJobOfferId}>
          <Grid columns={2}>
            <Grid.Column width={10}>
              <SelectPlatformOffers field='jobOfferId' />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                onClick={() => this.handleUploadToSelectedJobOfferId(false)}
                disabled={_.isEmpty(selectedJobOfferId)}
                color='blue'
              >
                <Icon name='send' /> Pending
              </Button>
              <Button
                onClick={() => this.handleUploadToSelectedJobOfferId(true)}
                disabled={_.isEmpty(selectedJobOfferId)}
                color='purple'
              >
                <Icon name='send' /> Pre-pending
              </Button>
            </Grid.Column>
          </Grid>
        </SweetForm>
        <h2>Current uploads</h2>
        <OffersTable
          offers={clientOffers}
          sweetapp={sweetapp}
          onUnupload={this.onUnupload}
          onUpload={this.onUpload}
          onUnlock={this.onUnlock}
          getItemColor={this.getItemColor}
        />
      </div>
    );
  }
}
export default WatchUpload;
