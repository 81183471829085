import _ from 'underscore';
import React, { Component } from 'react';
import { Icon, Table, Loader } from 'semantic-ui-react';

const FormatValue = ({ value, path, color }) => {
  if (_.isArray(value)) {
    return (
      <span>
        {_.map(value, (subVal, index) => (
          <FormatValue key={index} path={path} color={color} value={subVal} />
        ))}
      </span>
    );
  }
  if (_.isString(value) || _.isNumber(value)) {
    return <span style={{ color: color || 'black' }}>{value} </span>;
  }
  if (_.isBoolean(value)) {
    return <span style={{ color: color || 'black' }}>{value ? 'True' : 'False'}</span>;
  }
  if (_.isObject(value) && (value.min || value.max)) {
    return (
      <span>
        <FormatValue path={path} color={color} value={value.min} />-
        <FormatValue path={path} color={color} value={value.max} />
      </span>
    );
  }
  if (_.isObject(value) && value.identifier) {
    return (
      <div
        style={{
          display: 'inline-block',
          marginRight: 3,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 3,
          background: color || 'silver',
          fontWeight: 'bold',
        }}
      >
        <FormatValue value={value.identifier} path={path} color="white" />
      </div>
    );
  }
  return <Icon color={color} name="close" />;
};

class KeyDiffRow extends Component {
  render() {
    const getValue = (obj, path) => {
      const parts = path.split('.');
      let result = obj;
      for (let iPart = 0; iPart < parts.length; iPart++) {
        result = (result || {})[parts[iPart]];
      }
      return result;
    };

    const formatPath = (path) => {
      if (path === 'skills.required') {
        return 'required skills';
      } else if (path === 'skills.important') {
        return 'important skills';
      } else if (path === 'skills.bonus') {
        return 'bonus skills';
      } else if (path === 'jobPositions.required') {
        return 'job position';
      } else if (path === 'education.important') {
        return 'education';
      } else if (path === 'experience.years') {
        return 'xp';
      } else if (path === 'languages.important') {
        return 'languages';
      } else if (path === 'locationsAndRemote.target') {
        return 'locations target';
      } else if (path === 'locationsAndRemote.okWith') {
        return 'locations ok';
      } else {
        return path;
      }
    };

    const { path, oldCriteria, newCriteria, noOldCriteria } = this.props;
    const oldValue = getValue(oldCriteria, path);
    const newValue = getValue(newCriteria, path);
    const hasChanged =
      noOldCriteria || JSON.stringify(oldValue) !== JSON.stringify(newValue);

    return (
      <Table.Row warning={hasChanged}>
        <Table.Cell>
          <span style={{ whiteSpace: 'no-wrap' }}>{formatPath(path)}</span>
        </Table.Cell>
        <Table.Cell>
          <FormatValue
            value={newValue}
            path={path}
            color={hasChanged ? 'green' : null}
          />
        </Table.Cell>
        {!noOldCriteria && (
          <Table.Cell>
            <FormatValue
              value={oldValue}
              path={path}
              color={hasChanged ? 'orange' : null}
            />
          </Table.Cell>
        )}
      </Table.Row>
    );
  }
}

class OfferCriteriaDiff extends Component {
  render() {
    const {
      //clientId,
      jobOfferId,
      date,
      author,
      oldCriteria,
      newCriteria,
      noOldCriteria,
    } = this.props;

    if (!oldCriteria && !newCriteria) {
      return (
        <center>
          <Loader />
        </center>
      );
    }

    const paths = [
      'jobPositions.required',
      'locationsAndRemote.target',
      'locationsAndRemote.okWith',
      'remote',
      'freelance',
      'education.important',
      'experience.years',
      'salary',
      'skills.required',
      'skills.important',
      'skills.bonus',
      'languages.important',
      'collaboratorsInCharge',
      'blacklistedCompanies',
      'relevantCompanies',
      'onlineLinks',
      'exampleProfiles',
      'extraKeyWords',
      'moreInfo',
      'seekingCandidatesOnly'
    ];

    const allKeys = _.difference(
      _.union(_.keys(oldCriteria), _.keys(newCriteria)),
      ['skills', 'jobPositions', 'experience', 'education', 'languages'],
    );

    const diffKeys = _.difference(allKeys, paths);

    const strFromAuthor = (author) => {
      if (_.isObject(author) && author.email) {
        return author.email + (author.firstname ? (
          ' (' + author.firstname +  (author.lastname ? ' ' + author.lastname : '') + ')'
        ) : '');
      }
      if (_.isString(author)) {
        return author;
      }
      return 'Unknown';
    }

    return (
      <div style={{ position: 'relative' }}>
        <h1>{jobOfferId}</h1>
        {date && (
          <span>
            Edited by {strFromAuthor(author)} - {new Date(date).toDateString()} :{' '}
            {new Date(date).toLocaleTimeString()}
            <br />
          </span>
        )}
        {diffKeys.length > 0 && (
          <h2 style={{ color: 'orange' }}>
            Warning key(s) {diffKeys.join(', ')} not handled : contact Ismael
          </h2>
        )}
        <Table definition>
          <Table.Header>
            <Table.HeaderCell />
            <Table.HeaderCell>
              {noOldCriteria ? 'Value' : 'New'}
            </Table.HeaderCell>
            {!noOldCriteria && <Table.HeaderCell>Old</Table.HeaderCell>}
          </Table.Header>
          <Table.Body>
            {_.map(paths, (path) => (
              <KeyDiffRow
                key={path}
                path={path}
                oldCriteria={oldCriteria}
                newCriteria={newCriteria}
                noOldCriteria={noOldCriteria}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default OfferCriteriaDiff;
