import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Message,
  Form,
  Image,
  Icon,
  Card,
  List,
  Button,
} from 'semantic-ui-react';
import { LinkedinProfile } from './Offers/WorkPipeInputComponents/LinkedinProfile';
import DisplaySearchResult from './SearchResultView.js';
import baseUrl from './baseUrl.js';

class OutliersMonitoring extends Component {
  state = {};
  componentWillMount() {
    if (this.props.match.params.id) {
      this.loadOutlier(this.props.match.params.id);
    }
  }
  loadOutliers() {
    this.setState(
      {
        outliers: [],
        deleteMode: false,
      },
      () => {
        axios
          .get(baseUrl + '/sweetwork/outliers')
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for /outliers');
            }
            if (data.error) {
              throw Error(data.error);
            }
            if (!data.outliers) {
              throw Error('need a field outliers');
            }
            this.setState({
              outliers: data.outliers,
            });
          })
          .catch((e) => {
            alert(e.message);
          });
      },
    );
  }
  loadOutlier(id) {
    this.setState(
      {
        selectedOutlier: null,
        deleteMode: false,
        editComment: false,
        currentComment: '',
        currentLinkedin: null,
        searchResult: null,
      },
      () => {
        axios
          .get(baseUrl + '/sweetwork/outliers/id/' + id)
          .then(async ({ data }) => {
            if (!data) {
              throw Error('no data for /outliers/id/:id');
            }
            if (!data.outlier) {
              throw Error('need a field outlier');
            }
            if (data.error) {
              throw Error(data.error);
            }
            const linkedin = data.outlier.idFields
              ? data.outlier.idFields.linkedin
              : '';

            let currentLinkedin =
              data.outlier.data && data.outlier.data.linkedin
                ? ((linkedinData) => ({
                    ...linkedinData,
                    data: {
                      ...linkedinData.data,
                      lastname:
                        linkedinData.data.lastname + ' [Static Version]',
                    },
                  }))(data.outlier.data.linkedin)
                : linkedin
                ? (await axios.get(
                    baseUrl + '/sourceData//linkedin/' + linkedin,
                  )).data
                : null;
            this.setState({
              selectedOutlier: data.outlier,
              currentLinkedin,
            });
          })
          .catch((e) => {
            alert(e.message);
          });
      },
    );
  }
  loadSearchResult() {
    const outlier = this.state.selectedOutlier;
    if (!outlier) {
      return;
    }
    const pipe = outlier.pipeDescriptor || {};
    const searchId = pipe && pipe.type === 'search-v3' ? pipe.searchId : null;
    const idFields = {
      ...outlier.idFields,
      ...(outlier.idFields &&
        outlier.idFields.stackOverflow && {
          stackoverflow: outlier.idFields.stackOverflow,
        }),
    };
    const searchOneParam = {
      searchId,
      idFields,
    };
    const url = baseUrl + '/sweetwork/searchOneV3';
    axios.post(url, searchOneParam).then(({ data }) => {
      if (data.error) {
        alert(data.error);
      } else {
        if (
          this.state.selectedOutlier &&
          this.state.selectedOutlier.id === outlier.id &&
          data.resultingProfile &&
          data.resultingProfile.innerData
        ) {
          const { searchParamsWithScore } = data.resultingProfile.innerData;
          this.setState({
            searchResult: {
              messageHTML: data.resultingProfile.innerData.messageHtml,
              searchParamsWithScore,
            },
          });
        }
      }
    });
  }
  changeOutlierStatus(id, newStatus) {
    this.setState(
      {
        outliers: [],
        selectedOutlier: null,
        deleteMode: false,
      },
      () => {
        axios
          .put(baseUrl + '/sweetwork/outliers/id/' + id + '/status', {
            status: newStatus,
          })
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for /outliers/id/:id/status');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.loadOutliers();
            this.loadOutlier(id);
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  // label: 'negative', 'positive'
  changeOutlierLabel(id, newLabel) {
    this.setState(
      {
        outliers: [],
        selectedOutlier: null,
        deleteMode: false,
      },
      () => {
        axios
          .put(baseUrl + '/sweetwork/outliers/id/' + id + '/label', {
            label: newLabel,
          })
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for /outliers/id/:id/label');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.loadOutliers();
            this.loadOutlier(id);
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  // watched: true, false
  changeOutlierWatched(id, newWatched) {
    this.setState(
      {
        outliers: [],
        selectedOutlier: null,
        deleteMode: false,
      },
      () => {
        axios
          .put(baseUrl + '/sweetwork/outliers/id/' + id + '/watched', {
            watched: newWatched,
          })
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for /outliers/id/:id/watched');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.loadOutliers();
            this.loadOutlier(id);
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  changeOutlierComment(id, newComment) {
    this.setState(
      {
        outliers: [],
        selectedOutlier: null,
        deleteMode: false,
      },
      () => {
        axios
          .put(baseUrl + '/sweetwork/outliers/id/' + id + '/comment', {
            comment: newComment,
          })
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for sweetwork/outliers/id/:id/comment');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.loadOutliers();
            this.loadOutlier(id);
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  deleteOutlier(id) {
    this.setState(
      {
        outliers: [],
        selectedOutlier: null,
        deleteMode: false,
      },
      () => {
        axios
          .delete(baseUrl + '/sweetwork/outliers/id/' + id)
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for delete /outliers/id/:id');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.loadOutliers();
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  componentDidMount() {
    this.loadOutliers();
  }
  renderOutliers() {
    const status = this.state.status;
    const targetLabel = this.state.label;
    const targetWatched = this.state.watched;
    const targetTag = this.state.tag;

    const outliers1 = _.sortBy(
      _.filter(
        this.state.outliers,
        (outlier) =>
          (!status || outlier.status === status) &&
          (targetWatched === undefined ||
            (outlier.watched ? true : false) === targetWatched) &&
          (!targetLabel || (outlier.label || 'negative') === targetLabel),
      ),
      'timestamp',
    ).reverse();

    const statuses = _.uniq([
      'all',
      'pending',
      'in-process',
      'hold',
      'conflictual',
      'resolved',
      ..._.compact(_.map(outliers1, (outlier) => outlier.status)),
    ]);

    const isCurrentOutlier = (outlier) => {
      const { selectedOutlier } = this.state;
      return selectedOutlier && selectedOutlier.id === outlier.id;
    };

    const countFromTag = {};

    const tags = _.uniq(
      _.reduce(
        outliers1,
        (memo, outlier) => {
          const newTags = _.filter(
            (outlier.comment || '').replace(/\n/g, ' ').split(' '),
            (token) => token.length > 0 && token[0] === '#',
          );
          _.each(newTags, (tag) => {
            countFromTag[tag] = (countFromTag[tag] || 0) + 1;
          });
          if (_.isEmpty(_.compact(newTags))) {
            countFromTag['no-tag'] = (countFromTag['no-tag'] || 0) + 1;
          }
          return [...memo, ...newTags];
        },
        ['all', 'no-tag'],
      ),
    );

    const outliers = targetTag
      ? _.filter(outliers1, (outlier) => {
          const outlierTags = _.filter(
            (outlier.comment || '').replace(/\n/g, ' ').split(' '),
            (token) => token.length > 0 && token[0] === '#',
          );
          return targetTag !== 'no-tag'
            ? outlierTags.indexOf(targetTag) >= 0
            : _.isEmpty(_.compact(outlierTags));
        })
      : outliers1;

    return (
      <div>
        <div style={{ marginTop: '5px' }}>
          {_.map(statuses, (choice, index) => (
            <Button
              color={
                (choice === 'all' && !status) || choice === status
                  ? 'green'
                  : 'grey'
              }
              key={index}
              onClick={() => {
                this.setState({ status: choice !== 'all' ? choice : null });
              }}
            >
              {choice}
            </Button>
          ))}
        </div>
        <div style={{ marginTop: '5px' }}>
          {_.map(['all', 'negative', 'positive'], (label, index) => (
            <Button
              color={
                (label === 'all' && !targetLabel) || label === targetLabel
                  ? 'green'
                  : 'grey'
              }
              key={index}
              onClick={() => {
                this.setState({
                  label: label !== 'all' ? label : null,
                });
              }}
            >
              {label === 'all'
                ? 'all'
                : label === 'negative'
                ? 'To Reject'
                : 'To Accept'}
            </Button>
          ))}
        </div>
        <div style={{ marginTop: '5px' }}>
          {_.map(['all', true, false], (watched, index) => (
            <Button
              color={
                (watched === 'all' && targetWatched === undefined) ||
                watched === targetWatched
                  ? 'green'
                  : 'grey'
              }
              key={index}
              onClick={() => {
                this.setState({
                  watched: watched !== 'all' ? watched : undefined,
                });
              }}
            >
              {watched === 'all' ? 'all' : watched ? 'Watched' : 'Ignored'}
            </Button>
          ))}
        </div>
        <div style={{ marginTop: '5px' }}>
          {_.map(tags, (choice, index) => (
            <Button
              color={
                (choice === 'all' && !targetTag) || choice === targetTag
                  ? 'green'
                  : 'silver'
              }
              key={index}
              onClick={() => {
                this.setState({ tag: choice !== 'all' ? choice : null });
              }}
            >
              {(choice !== 'no-tag' ? choice : '∅') +
                (['all'].indexOf(choice) < 0
                  ? ' (' + (countFromTag[choice] || 0) + ')'
                  : '')}
            </Button>
          ))}
        </div>
        <h4>
          {outliers.length} outlier{outliers.length !== 1 ? 's' : ''}
        </h4>
        <List bulleted>
          {_.map(outliers, (outlier, index) => (
            <List.Item key={index}>
              <span>
                {/* eslint-disable-next-line */}
                <a
                  style={{
                    ...(isCurrentOutlier(outlier) && {
                      fontSize: 15,
                      fontWeight: 'bold',
                    }),
                  }}
                  onClick={() => this.loadOutlier(outlier.id)}
                >
                  {'[' +
                    (outlier.watched ? 'watched' : 'ignored') +
                    ']  ' +
                    '[' +
                    (outlier.label === 'positive' ? 'To Accept' : 'To Reject') +
                    ']  ' +
                    '[' +
                    new Date(outlier.timestamp).toLocaleDateString() +
                    '  -  ' +
                    new Date(outlier.timestamp).toLocaleTimeString() +
                    ']  ' +
                    outlier.reason +
                    ' ' +
                    (outlier.offer && outlier.offer.title
                      ? '(' + outlier.offer.title + ') '
                      : '')}
                </a>
                <Icon
                  name={outlier.status === 'resolved' ? 'checkmark' : 'wrench'}
                />
              </span>
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
  renderContacts(idFields) {
    return (
      <span>
        {_.map(idFields, (value, key) => {
          const link =
            key === 'linkedin'
              ? 'https://linkedin.com/in/' + value
              : key === 'github'
              ? 'https://github.com/' + value
              : key === 'stackOverflow'
              ? 'https://stackoverflow.com/users/' +
                (value.indexOf('/') >= 0 ? value : value + '/osef')
              : key === 'twitter'
              ? 'https://twitter.com/' + value
              : '';
          const style = { marginRight: '10px' };
          return link ? (
            <a key={key} href={link} target='_blank' style={style} rel='noopener noreferrer'>
              {key}
            </a>
          ) : (
            ''
          );
        })}
      </span>
    );
  }
  renderSelectedOutlier() {
    const outlier = this.state.selectedOutlier;
    if (!outlier) {
      return null;
    }

    const date = new Date(outlier.timestamp);
    const offerTitle =
      outlier.offer && outlier.offer.title ? outlier.offer.title : '';

    const title =
      '[' +
      (outlier.label === 'positive' ? 'To Accept' : 'To Reject') +
      '] ' +
      '[' +
      offerTitle +
      '] ' +
      outlier.reason;
    const subTitle =
      (outlier.username ? outlier.username + ' ' : '') +
      ' le ' +
      date.toLocaleDateString() +
      ' à ' +
      date.toLocaleTimeString();

    const offer = outlier.offer || {};
    const sweetappStr = offer.platformId
      ? '(sweetapp: ' + offer.platformId + ')'
      : '';
    const searchId =
      outlier.pipeDescriptor && outlier.pipeDescriptor.searchId
        ? outlier.pipeDescriptor.searchId
        : 'not found';
    const image =
      outlier.status === 'resolved' ? '/images/done.png' : '/images/wrench.png';
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Image floated="right" size="mini" src={image} />
            <Card.Header>
              {title} <Icon name={outlier.watched ? 'eye' : 'low vision'} />
            </Card.Header>
            <Card.Meta>{subTitle}</Card.Meta>

            <Card.Content>
              <div className="ui two buttons">
                <Button
                  basic
                  color="orange"
                  onClick={() =>
                    this.changeOutlierLabel(outlier.id, 'negative')
                  }
                >
                  To Reject
                </Button>
                <Button
                  basic
                  color="blue"
                  onClick={() =>
                    this.changeOutlierLabel(outlier.id, 'positive')
                  }
                >
                  To Accept
                </Button>
              </div>
            </Card.Content>
            <br />
            <Card.Content>
              <div className="ui two buttons">
                <Button
                  basic
                  color="orange"
                  onClick={() => this.changeOutlierWatched(outlier.id, true)}
                >
                  Watch In Anti-Regression
                </Button>
                <Button
                  basic
                  color="blue"
                  onClick={() => this.changeOutlierWatched(outlier.id, false)}
                >
                  Ignore in Anti-Regression
                </Button>
              </div>
            </Card.Content>

            <Card.Description>
              <br />
              <List bulleted>
                <List.Item>
                  <strong>Sources</strong>:{' '}
                  {this.renderContacts(outlier.idFields)}
                </List.Item>
                <List.Item>
                  <strong>Offer</strong>:{' '}
                  <a href={'/offers/' + offer.id}>{offerTitle}</a> {sweetappStr}
                </List.Item>
                <List.Item>
                  <strong>Search Identifier</strong>: <code>{searchId}</code>
                </List.Item>
                <List.Item>
                  <strong>Outlier Identifier</strong>: <code>{outlier.id}</code>
                </List.Item>
                {outlier.idFields.linkedin ? (
                  <List.Item>
                    <strong>RayonX</strong>:{' '}
                    <a
                      href={'/profiles/' + outlier.idFields.linkedin}
                      target="_blank"
                      rel='noopener noreferrer'
                    >
                      {outlier.idFields.linkedin}
                    </a>
                  </List.Item>
                ) : null}
              </List>
            </Card.Description>
            <br />

            {this.state.editComment ? (
              <div>
                <Form reply>
                  <Form.TextArea
                    value={this.state.currentComment}
                    onChange={(event, { value }) => {
                      this.setState({ currentComment: value });
                    }}
                  />
                </Form>
                <Button
                  color="green"
                  onClick={() => {
                    const currentComment = this.state.currentComment;
                    this.setState(
                      {
                        editComment: false,
                        currentComment: '',
                      },
                      () => {
                        this.changeOutlierComment(outlier.id, currentComment);
                      },
                    );
                  }}
                >
                  Save Comment
                </Button>
              </div>
            ) : (
              <div>
                {outlier.comment ? (
                  <Message
                    icon="comment"
                    header="Comment"
                    list={outlier.comment.split('\n')}
                  />
                ) : null}
                <Button
                  color="blue"
                  onClick={() =>
                    this.setState({
                      editComment: true,
                      currentComment: outlier.comment || '',
                    })
                  }
                >
                  Edit comment
                </Button>
              </div>
            )}
            <br />

            <center>
              <Button
                onClick={() => {
                  console.log(JSON.stringify(outlier.searchParam, null, 4));
                  console.log(outlier.searchParam);
                }}
              >
                Log search in console
              </Button>
            </center>
            <br />
            <br />
          </Card.Content>
          <Card.Content extra>
            <div className="ui six buttons">
              <Button
                basic
                color="orange"
                onClick={() => this.changeOutlierStatus(outlier.id, 'pending')}
              >
                Pending
              </Button>
              <Button
                basic
                color="yellow"
                onClick={() =>
                  this.changeOutlierStatus(outlier.id, 'in-process')
                }
              >
                In Process
              </Button>
              <Button
                basic
                color="brown"
                onClick={() => this.changeOutlierStatus(outlier.id, 'hold')}
              >
                Hold
              </Button>
              <Button
                basic
                color="brown"
                onClick={() =>
                  this.changeOutlierStatus(outlier.id, 'conflictual')
                }
              >
                Conflictual
              </Button>
              <Button
                basic
                color="green"
                onClick={() => this.changeOutlierStatus(outlier.id, 'resolved')}
              >
                Resolved
              </Button>
              <Button
                basic
                color="red"
                onClick={() => {
                  if (!this.state.deleteMode) {
                    this.setState({ deleteMode: true });
                    setTimeout(() => {
                      this.setState({ deleteMode: false });
                    }, 3000);
                  } else {
                    this.deleteOutlier(outlier.id);
                  }
                }}
              >
                {this.state.deleteMode ? 'Confirm' : 'Delete'}
              </Button>
            </div>
          </Card.Content>
        </Card>

        {this.state.searchResult ? (
          <DisplaySearchResult searchResult={this.state.searchResult} />
        ) : (
          <center>
            <br />
            <Button onClick={() => this.loadSearchResult()}>
              Reevaluate On Search
            </Button>
            <br />
            <br />
          </center>
        )}

        {this.state.currentLinkedin ? (
          <LinkedinProfile
            source={this.state.currentLinkedin}
            more={{}}
            globalHint={null}
            globalHints={[]}
            onMore={() => {}}
            onChangeExperienceHint={() => {}}
            onChangeGlobalHint={() => {}}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
  render() {
    return (
      <div
        style={{
          position: 'fixed',
          top: '68px',
          left: '13%',
          height: 'calc(100vh - 68px)',
          width: '87%',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '50%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {this.renderOutliers()}
          </div>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '50%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {this.renderSelectedOutlier()}
          </div>
        </div>
      </div>
    );
  }
}

export default OutliersMonitoring;
