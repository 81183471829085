function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import _ from 'underscore';
import React from 'react';
import { default as RSelect, Creatable, AsyncCreatable } from 'react-select';
import enhance from './enhance';
var Select = enhance(function (_ref) {
  var _ref2;

  var value = _ref.value,
      setValue = _ref.setValue,
      async = _ref.async,
      creatable = _ref.creatable,
      options = _ref.options,
      labelKey = _ref.labelKey,
      valueKey = _ref.valueKey,
      multi = _ref.multi,
      innerProps = _objectWithoutProperties(_ref, ['value', 'setValue', 'async', 'creatable', 'options', 'labelKey', 'valueKey', 'multi']);

  var InnerComponent = creatable ? async ? AsyncCreatable : Creatable : async ? RSelect.Async : RSelect;
  var valKey = valueKey || 'value';
  var computedValue = multi ? _.filter(_.isArray(value) ? value : (value || '').split(';'), function (e) {
    return !!e;
  }) : value;
  var augmentedOptions = multi || !options || !value || _.some(options, function (opt) {
    return opt[valKey] === value;
  }) ? options : [].concat(_toConsumableArray(options), [(_ref2 = {}, _defineProperty(_ref2, labelKey || 'label', value), _defineProperty(_ref2, valKey, value), _ref2)]);
  return React.createElement(InnerComponent, Object.assign({}, innerProps, {
    options: augmentedOptions,
    value: computedValue,
    labelKey: labelKey,
    valueKey: valueKey,
    inputProps: {
      type: 'react-type'
    },
    multi: multi,
    simpleValue: true,
    delimiter: ';',
    onChange: setValue
  }));
});
export default Select;