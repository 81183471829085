import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import { Grid } from 'semantic-ui-react';
import { Checkbox, Select, SweetForm } from 'sweetform';



const filterWithUser = ({ prospects, user }) => {
  return _.compact(_.map(prospects, (prospect) => {
    if (user && user != "none") {
      if (user == "no-owner" && prospect.sweethubUserId) {
        return null;
      }
      if (prospect.sweethubUserId !== user) {
        return null
      }
    }
    return prospect;
  }
  ))
}
const filterWithCompletion = ({ prospects, completionMode }) => {
  return _.compact(_.map(prospects, (prospect) => {
    if (completionMode && completionMode != "show-completed" && prospect.completedReason) {
      return null;
    }
    return prospect;
  }
  ))
}

const filterWithClient = ({ prospects, clientsMode }) => {
  return _.compact(_.map(prospects, (prospect) => {
    if (clientsMode && clientsMode != "no-filter") {
      const isActiveClient = !!(_.find((prospect.hubspotData || {}).deals, ({ pipeline }) => (pipeline == "3 - Account Management")));
      const isEverClient = !!prospect.clientId;

      if (clientsMode == "only-active-clients" && !isActiveClient) {
        return null;
      }
      if (clientsMode == "exclude-active-clients" && isActiveClient) {
        return null;
      }
      if (clientsMode == "only-ever-clients" && !isEverClient) {
        return null;
      }
      if (clientsMode == "exclude-ever-clients" && isEverClient) {
        return null;
      }
      return prospect;
    }
    return prospect;
  }
  ))
}

const filterWithContacts = ({ prospects, contactsMode }) => {
  return _.compact(_.map(prospects, (prospect) => {
    if (contactsMode && contactsMode != "no-filter") {
      const everAnswered = !!(prospect.hubspotData || {}).lastAnswer;
      const everContacted = !!(prospect.hubspotData || {}).lastContact;
      if (contactsMode == "exclude-contacted-never-answered" && everContacted && !everAnswered) {
        return null;
      }
      return prospect;
    }
    return prospect;
  }
  ))
}

export const filterProspects = ({ prospects, filters }) => {
  let filteredProspects = prospects;

  filteredProspects = filterWithUser({ prospects: filteredProspects, user: filters.user });
  filteredProspects = filterWithCompletion({ prospects: filteredProspects, completionMode: filters.completionMode });
  filteredProspects = filterWithClient({ prospects: filteredProspects, clientsMode: filters.clientsMode });
  filteredProspects = filterWithContacts({ prospects: filteredProspects, contactsMode: filters.contactsMode });

  console.log({ filteredProspects })


  return filteredProspects;
}



const capitalizeFirstLetter = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export const ProspectQuickFilters = ({
  users,
  initialValues,
  handleChange,
  dumpMode
}) => {
  const userOptions = [
    { value: 'none', label: 'All' },
    { value: 'no-owner', label: 'No Owner' },
    ..._.map(users, ({ id, name }) => ({ value: id, label: capitalizeFirstLetter(name || '') }))
  ];

  return (
    <div>
      <SweetForm initialValues={initialValues} onChange={handleChange}>
        <Grid columns={dumpMode ? 8 : 4}>

            {dumpMode && (
              <Grid.Column width={1}>
                <b>Completion</b>
              </Grid.Column>
            )}
            {dumpMode && (
              <Grid.Column width={3}>
                <Select
                  options={[
                    { value: 'hide-completed', label: 'Hide completed' },
                    { value: 'show-completed', label: 'Show completed' },
                  ]}
                  field="completionMode"
                />

              </Grid.Column>
            )}
            {dumpMode && (
              <Grid.Column width={1}>
                <b>Owner: </b>
              </Grid.Column>
            )}
            {dumpMode && (
              <Grid.Column width={3}>
                <Select
                  search
                  options={userOptions}
                  field="user"
                />
              </Grid.Column>
            )}
            <Grid.Column width={1}>
              <b>Clients</b>
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                options={[
                  { value: 'only-active-clients', label: 'Only Active Clients' },
                  { value: 'exclude-active-clients', label: 'Exclude Active Clients' },
                  { value: 'only-ever-clients', label: 'Only Ever Clients' },
                  { value: 'exclude-ever-clients', label: 'Exclude Ever Clients' },
                  { value: 'no-filter', label: 'No Filter' },
                ]}
                field="clientsMode"
              />
            </Grid.Column>
            <Grid.Column width={1}>
              <b>Contacts</b>
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                options={[
                  { value: 'exclude-contacted-never-answered', label: 'Exclude Contacted Never Answered' },
                  { value: 'no-filter', label: 'No Filter' },
                ]}
                field="contactsMode"
              />
            </Grid.Column>
        </Grid>
      </SweetForm>
    </div>
  );
}