import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Card, Grid, Button, Checkbox, Loader, Form, TextArea, Modal } from 'semantic-ui-react';
import baseUrl from '../../baseUrl';

class CustomNotifications extends React.Component {
  state = {
    clientsData: null,
    loadingData: false,
    modalOpen: false,
    text: '', // TextArea
  };

  async loadData() {
    this.setState({ loadingData: true });
    let clientsData;
    try {
      const { data } = await axios.get(baseUrl + '/station/slack/custom/clients');
      clientsData = data.clients;
    } catch (e) {
      console.log(e.message);
    }
    this.setState({ clientsData, loadingData: false });
  }

  componentDidMount() {
    this.loadData();
  }

  toggleClient = async ({ clientId }) => {
    const { clientsData } = this.state;
    const newClientState = _.map(clientsData, (client) => {
      if (client.id === clientId) {
        return {
          ...client,
          checked: !client.checked,
        };
      }
      return client;
    });
    this.setState({ clientsData: newClientState });
  };

  handleSendNotifications = async () => {
    const { clientsData, text } = this.state;
    const selectedClients = _.filter(clientsData, ({ checked }) => !!checked);
    try {
      const body = { clients: selectedClients, text: JSON.parse(text) };
      const { data } = await axios.post(baseUrl + '/station/slack/custom/send-notification', body);
    } catch (e) {
      console.log(e.message);
    }
    this.closeModal();
  };

  onChangeTextArea = (e, { value }) => {
    this.setState({ text: value });
  };

  onSelectAll = async () => {
    const { clientsData } = this.state;
    const newClientState = _.map(clientsData, (client) => ({ ...client, checked: true }));
    this.setState({ clientsData: newClientState });
  };

  onSelectNone = async () => {
    const { clientsData } = this.state;
    const newClientState = _.map(clientsData, (client) => ({ ...client, checked: false }));
    this.setState({ clientsData: newClientState });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { clientsData, loadingData, text, modalOpen } = this.state;

    if (loadingData) {
      return <Loader active size='large'/>;
    }

    if (_.isEmpty(clientsData)) {
      return (
        <Grid columns='sixteen'>
          <Grid.Row>
            <Grid.Column width={12}>
              <Card style={{ padding: 20 }}>
                There are no active Slack clients.
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }

    console.log('clientsData', clientsData);
    const selectedClientIds = _.compact(_.map(clientsData, ({ id, checked }) => (
      checked ? id : null
    )));

    const allSelected = _.reduce(clientsData, (memo, { checked }) => memo && checked, true);

    const modalTrigger = (
      <Button primary disabled={_.isEmpty(selectedClientIds) || !isValidJSON(text)} onClick={this.openModal}>
        Send Notifications
      </Button>
    );

    return (
      <Grid columns='sixteen'>
        <Grid.Row>
          <Grid.Column width={4}>
            <div>
              <Button onClick={allSelected ? this.onSelectNone : this.onSelectAll} primary>
                {allSelected ? 'Select none' : 'Select all'}
              </Button>
            </div>
            {_.map(clientsData, (client) => {
              const { id, name, checked } = client || {};
              return (
                <Card key={id} className='client-card' fluid>
                  <Card.Content>
                    <Grid columns='sixteen'>
                      <Grid.Row>
                        <Grid.Column width={16} verticalAlign="middle">
                          <Checkbox
                            checked={!!checked}
                            label={`${name} [${id}]`}
                            onChange={() => this.toggleClient({ clientId: id })}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Card.Content>
                </Card>
              );
            })}
          </Grid.Column>
          <Grid.Column width={8} verticalAlign="middle">
            <Form>
              <h4>Use Slack's
                <a href='https://app.slack.com/block-kit-builder' target='_blank' rel='noopener noreferrer'>
                  &nbsp;Block Kit Builder&nbsp;
                </a>
                to format your message. Paste JSON here:
              </h4>
              <TextArea
                style={{ width: '100%', 'min-height': '300px' }}
                value={text}
                onChange={this.onChangeTextArea}
              >
            </TextArea>
            </Form>
          </Grid.Column>
          <Grid.Column width={4}>
            <Modal trigger={modalTrigger} open={modalOpen} onClose={this.closeModal} size='tiny'>
              <Modal.Header>
                Are you sure you want to Send ?
              </Modal.Header>
              <Modal.Actions>
                <Button basic color='grey' content='Cancel' onClick={this.closeModal}/>
                <Button basic color='blue' content='Send' onClick={this.handleSendNotifications}/>
              </Modal.Actions>
            </Modal>
            {
              !_.isEmpty(selectedClientIds) && (
                <div>
                  <span>to</span>
                  <ul>
                    {_.map(selectedClientIds, (id) => (
                      <li key={id}>{id}</li>
                    ))}
                  </ul>
                </div>
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}

const isValidJSON = (text) => {
  try {
    return !!JSON.parse(text);
  } catch (e) {
    return false;
  }
};

export default CustomNotifications;
