import React from 'react';
import _ from 'underscore';
import { Grid, Segment, Label } from 'semantic-ui-react';

const WatchAccount = ({ account }) => {
  const {
    deleted,
    emails,
    contactPreferences,
    phoneNumber,
    onboarding,
    credentials,
    origin,
    referral,
    talentStrategist
  } = account;


  const contactActive = (contactPreferences || {}).active;
  const linkedinEmail = ((credentials || {}).linkedin || {}).email;
  const onboardingFinished = (onboarding || {}).finished;
  const referredBy = (origin || {}).referralToken
  const referralToken = (referral || {}).token
  return (
    <Segment>
      <h2>Account</h2>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Deleted ?</h3>
          </Grid.Column>
          <Grid.Column width={12}>
            <Label color={deleted ? 'red' : 'green'}>{deleted ? 'Deleted' : 'Not deleted'}</Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Contact Preferences</h3>
          </Grid.Column>
          <Grid.Column width={12}>
            <Label color={contactActive ? 'green' : 'red'}>{contactActive ? 'Active' : 'Inactive'}</Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Onboarding Finished</h3>
          </Grid.Column>
          <Grid.Column width={12}>
            <Label color={onboardingFinished ? 'green' : 'red'}>{onboardingFinished ? 'Yes' : 'No'}</Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Referral token</h3>
          </Grid.Column>
          <Grid.Column width={12}>{referralToken}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Referred by</h3>
          </Grid.Column>
          <Grid.Column width={12}>{referredBy}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Phone Number</h3>
          </Grid.Column>
          <Grid.Column width={12}>{phoneNumber}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Linkedin Email</h3>
          </Grid.Column>
          <Grid.Column width={12}>{linkedinEmail}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Mails</h3>
          </Grid.Column>
          <Grid.Column width={12}>
            <ul>
              {_.map(emails || [], ({ address, confirmed, selected }) => (
                <li>
                  {address}
                  {confirmed ? '[confirmed]' : '[not confirmed]'}
                  {selected && '[selected]'}
                </li>
              ))}
            </ul>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} textAlign='right'>
            <h3>Talent strategist</h3>
          </Grid.Column>
          <Grid.Column width={12}>{(talentStrategist || {}).userId}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default WatchAccount;
