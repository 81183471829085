export const locationMapping = {
  'san-francisco': 'bay-area-region',
  'new-york': 'new-york-region',
  chicago: 'chicago-region',
  'washington-dc': 'washington-dc-region',
  houston: 'houston-region',
  philadelphia: 'philadelphia-region',
  boston: 'boston-region',
  dallas: 'dallas-region',
  'los-angeles': 'los-angeles-region',
  'palo-alto': 'bay-area-region', //'198926971', Temporary mapping to san-francisco bay area
  'redwood-city': 'bay-area-region', //'198053895', Temporary mapping to san-francisco bay area
  'mountain-view': 'bay-area-region', //'198499062', Temporary mapping to san-francisco bay area
  cupertino: '198402274',
  'san-diego': 'san-diego-region',
  'charlotte-north-carolina': '197836837',
  detroit: '198089497',
  miami: '198370113',
  phoenix: '235524047',
  seattle: '198146136',
  indianapolis: '402902',
  portland: '240129139',
  memphis: '197867712',
  atlanta: '198320690',
  austin: '240569707',
  tampa: '198586532',

  london: 'londres-region',
  birmingham: '197658200',
  manchester: '197628487', //
  glasgow: '258698348', //
  leeds: '197567930', //
  liverpool: '235354692',
  newcastle: '235394729',
  sheffield: '235377645',

  paris: 'paris-region',
  '198254379': '198254379', // ile de france
  lyon: 'lyon-region',
  marseille: 'marseille-region',
  lille: 'lille-region',
  toulouse: 'toulouse-region',
  bordeaux: 'bordeaux-region',
  rennes: 'rennes-region',
  nantes: 'nantes-region',
  montpellier: 'montpellier-region',
  strasbourg: 'strasbourg-region',
  nice: 'nice-region',
  grenoble: 'grenoble-region',

  berlin: 'berlin-region',
  hamburg: '114035', //
  munich: '197724330', //
  cologne: '197458053', //
  frankfurt: '142284', //
  stuttgart: '17476218', //
  dusseldorf: '605203', //
  dortmund: '121619', //

  barcelona: 'barcelone-region',
  madrid: 'madrid-region',
  valencia: '197979147', //
  seville: '198420888', //
  bilbao: '236505509', //

  brussels: 'bruxelles-region',
  antwerp: '197426816', //
  ghent: '198142898', //
  charleroi: '198398489', //
  liege: '198501646', //

  lisbon: 'lisbonne-region',
  porto: 'porto-region',

  zurich: '198534114', //
  lausanne: 'lausanne-region',
  geneva: 'geneve-region',
  bern: '198189641', //
  basel: '198285479', //

  montreal: 'montreal-region',
  toronto: 'toronto-region',
  vancouver: 'vancouver-region',
  calgary: '263012842', //
  edmonton: '262834583', //
  ottawa: '112144', //
  'quebec-city': '198665384', //

  'mexico-city': '257304', //

  usa: '197597979',
  uk: '198730323',
  france: '198319371',
  germany: '198664929',
  spain: '198974448',
  belgium: '198019243',
  portugal: '198200685',
  swiss: '198667942',
  canada: '198904141',
  mexico: '197542602',
  romania: '198306739',
  poland: '198010785',
  hungary: '198645167',
  bulgary: '198203233',
  'czech-republic': '198038226',
  slovenia: '197922193',
  slovakia: '197660455',
  lithuania: '197464526',
  estonia: '197461108',
  latvia: '197449445',
  croatia: '198746961',
  ukraine: '198675244',

  europe: 'europe', //
  //'north-america': '', // removed because no sweethub location available at the moment
};
