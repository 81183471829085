import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ChallengesDashboard from './ChallengesDashboard';
import Challenge from './Challenge';


class MachineLearningChallengesRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/ml/challenges">
          <ChallengesDashboard />
        </Route>
        <Route exact path="/ml/challenges/:challengeId">
          {({ match }) => (
            <Challenge challengeId={((match || {}).params || {}).challengeId} />
          )}
        </Route>
      </Switch>
    )
  }
}


export default MachineLearningChallengesRouter;
