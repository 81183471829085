import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
// import moment from 'moment';
import { Checkbox, Grid, Header, List, Table } from 'semantic-ui-react';
import { Provider, connect } from 'react-redux';
import React, { Component } from 'react';
import { Select, SweetForm } from 'sweetform';
import { combineReducers, createStore } from 'redux';

import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

const SET_GROUP = 'SET_GROUP';
const setGroup = (group) => ({ type: SET_GROUP, group });

const SET_SOURCE = 'SET_SOURCE';
const setSource = (source) => ({ type: SET_SOURCE, source });

const TOGGLE_CHART_MODE = 'TOGGLE_CHART_MODE';
const toggleChartMode = () => ({ type: TOGGLE_CHART_MODE });

// Reducers
const instanceData = (state = [], action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

const group = (state = [], action) => {
  switch (action.type) {
    case SET_GROUP:
      return action.group;
    default:
      return state;
  }
};

const source = (state = [], action) => {
  switch (action.type) {
    case SET_SOURCE:
      return action.source;
    default:
      return state;
  }
};

const chartModeCumulative = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CHART_MODE:
      return !state;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  instanceData,
  group,
  source,
  chartModeCumulative,
});

// Store
const store = createStore(rootReducer);

// Components
const ReportTable = ({ results }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Time</Table.HeaderCell>
        <Table.HeaderCell>Documents conciliated</Table.HeaderCell>
        <Table.HeaderCell>Number or Documents</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map([...results].reverse(), ({ date, count, cumulativeCount }, i) => (
        <Table.Row key={i}>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{count || 0}</Table.Cell>
          <Table.Cell>{cumulativeCount}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

const ReportChartCumulative = ({ results }) => (
  <ResponsiveContainer width="100%" height={200}>
    <ComposedChart data={results}>
      <XAxis dataKey="date" />
      <YAxis />
      <CartesianGrid />
      <Tooltip />
      <Legend />
      <Area
        isAnimationActive={false}
        type="monotone"
        dataKey="cumulativeCount"
      />
    </ComposedChart>
  </ResponsiveContainer>
);

const ReportChart = ({ results }) => (
  <ResponsiveContainer width="100%" height={200}>
    <ComposedChart data={results}>
      <XAxis dataKey="date" />
      <YAxis />
      <CartesianGrid />
      <Tooltip />
      <Legend />
      {/* <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
          <stop offset={gradientOffset(results)} stopColor="green" stopOpacity={1} />
          <stop offset={gradientOffset(results)} stopColor="red" stopOpacity={1} />
        </linearGradient>
      </defs> */}
      {/* <Line isAnimationActive={false} type="monotone" dataKey="cumulativeCount" stroke="black" /> */}
      <Area
        isAnimationActive={false}
        type="monotone"
        dataKey="count"
        fill="url(#splitColor)"
      />
    </ComposedChart>
  </ResponsiveContainer>
);

class Sweetynotes extends Component {
  componentDidMount() {
    const { onLoad, onDisplaySource, source } = this.props;
    if (!source || _.isEmpty(source)) {
      onDisplaySource('github');
    }
    onLoad();
  }

  render() {
    const {
      instanceData,
      onLoad,
      onDisplaySource,
      source,
      chartModeCumulative,
      onToggleChartMode,
    } = this.props;
    const sortedResults =
      _.isString(source) && instanceData && instanceData[source]
        ? _.sortBy(
            _.map(instanceData[source], (value, key) => ({
              date: key,
              count: value,
            })),
            'date',
          )
        : [];
    const cumulativeCount =
      !_.isEmpty(sortedResults) &&
      _.reduce(
        sortedResults,
        (memo, { date, count }) => [
          ...memo,
          {
            date,
            count,
            cumulativeCount:
              count + (_.last(memo) || { cumulativeCount: 0 }).cumulativeCount,
          },
        ],
        [],
      );
    return (
      <Grid>
        <Grid.Column width={3}>
          <SweetForm
            initialValues={{ group: 'day' }}
            onChange={({ group }) => onLoad(group)}
          >
            <Select
              field="group"
              clearable={false}
              options={[
                { label: 'Hour', value: 'hour' },
                { label: 'Day', value: 'day' },
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' },
              ]}
            />
          </SweetForm>
          <List bulleted>
            {_.map(_.keys(instanceData), (sourceName, i) => (
              <List.Item key={i}>
                {/* eslint-disable-next-line */}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    onDisplaySource(sourceName);
                  }}
                >
                  {sourceName}
                </a>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column width={6}>
          {_.isString(source) && instanceData && instanceData[source] ? (
            <div>
              <Header as="h2">{source}</Header>
              {chartModeCumulative ? (
                <ReportChartCumulative results={cumulativeCount} />
              ) : (
                <ReportChart results={cumulativeCount} />
              )}
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Checkbox
                  label="Cumulative"
                  slider
                  checked={chartModeCumulative}
                  onChange={onToggleChartMode}
                />
              </div>
              <ReportTable results={cumulativeCount} />
            </div>
          ) : (
            <p>Select a source</p>
          )}
        </Grid.Column>
        {/* <Grid.Column width={7}>
          {sample ?
            <pre>{JSON.stringify(sample, null, '  ')}</pre> : null}
        </Grid.Column> */}
      </Grid>
    );
  }
}

const mapSSweetynotes = (state) => ({
  instanceData: state.instanceData,
  source: state.source,
  chartModeCumulative: state.chartModeCumulative,
});

const loadSweetynotes = async (dispatch, group) => {
  const { data } = await axios.get(
    `${baseUrl}/monitoring/sweetynotes/${group}`,
  );
  // const { mailAccountsWithClients, mailAccountsWithoutClients } = _.reduce(data, (memo, mailAccount) => (
  //   mailAccount.clients && mailAccount.clients[0] ? { ...memo, mailAccountsWithClients: [mailAccount, ...memo.mailAccountsWithClients] } : (
  //     { ...memo, mailAccountsWithoutClients: [mailAccount, ...memo.mailAccountsWithoutClients] }
  //   )
  // ), { mailAccountsWithClients: [], mailAccountsWithoutClients: [] });
  // console.log({ mailAccountsWithClients, mailAccountsWithoutClients });
  // const sortedSweetynotesWithClients = _.sortBy(mailAccountsWithClients, mailAccount => (2 - getMailAccountStatusCode(mailAccount)) + mailAccount.clients[0].id)
  // const sortedSweetynotesWithoutClients = _.sortBy(mailAccountsWithoutClients, mailAccount => 2 - getMailAccountStatusCode(mailAccount))
  // const instances = { mailAccountsWithClients: sortedSweetynotesWithClients, mailAccountsWithoutClients: sortedSweetynotesWithoutClients }
  dispatch(setInstanceData(data));
};

const mapDSweetynotes = (dispatch) => ({
  onLoad: async (group) => {
    dispatch(setGroup(group || 'day'));
    await loadSweetynotes(dispatch, group || 'day');
  },
  onDisplaySource: async (sourceName) => {
    dispatch(setSource(sourceName));
  },
  onToggleChartMode: () => dispatch(toggleChartMode()),
});

const SweetynotesContainer = connect(
  mapSSweetynotes,
  mapDSweetynotes,
)(Sweetynotes);

export default () => (
  <Provider store={store}>
    <SweetynotesContainer />
  </Provider>
);
