import React, { Component } from 'react';
import _ from 'underscore';
import { Grid, Table, Form, Modal, TextArea, Button, Header, Dropdown } from 'semantic-ui-react';
import { FilterDetailsTree } from '../../../Offers/FilterDetails';

const rounded = (x) => (_.isNumber(x) ? Math.round(x * 100) / 100 : x);

const annotationTypeOptions = [
  {
    key: 0,
    text: 0,
    description: 'Has nothing to do with this list',
    value: 'disqualified;awful',
  },
  {
    key: 1,
    text: 1,
    description: 'Does not match the offer',
    value: 'disqualified;bad',
  },
  {
    key: 2,
    text: 2,
    description: 'Maybe as a last resort',
    value: 'backlog;weak',
  },
  {
    key: 3,
    text: 3,
    description: 'Maybe later',
    value: 'backlog;normal',
  },
  {
    key: 4,
    text: 4,
    description: 'Match most criteria',
    value: 'selected;normal',
  },
  {
    key: 5,
    text: 5,
    description: 'Match all criteria',
    value: 'selected;strong',
  },
  {
    key: 6,
    text: 6,
    description: 'Match all criteria',
    value: 'selected;very-strong',
  },
];

class FeedbackModal extends Component {
  state = {
    comment: '',
    annotationType: null,
  };

  handleChangeComment = (comment) => {
    this.setState({ comment });
  };

  handleSubmit = ({ shouldBeProcessed }) => {
    if (!this.state.comment) {
      return alert('Comment needed');
    }
    const annotationType = this.state.annotationType || this.props.presetAnnotation;

    if (!annotationType) {
      return alert('Annotation needed');
    }
    this.props.onSubmit({ 
      annotationType: annotationType, 
      comment: this.state.comment, 
      shouldBeProcessed 
    });
  };

  renderDetails = () => {
    const details = this.props.scorerDetails;
    if (!details || !details.model_omega_score) {
      return <h4>No Details</h4>;
    }

    const letterFromScore = (score) => {
      if (score > 0.8) {
        return 'A';
      } else if (score > 0.6) {
        return 'B';
      } else if (score > 0.4) {
        return 'C';
      } else if (score > 0.2) {
        return 'D';
      } else {
        return 'E';
      }
    };

    const colorFromScore = (score) => {
      return (
        {
          A: 'lime',
          B: 'green',
          C: 'orange',
          D: 'red',
          E: 'brown',
        }[letterFromScore(score)] || 'black'
      );
    };

    const score = details.model_omega_score;

    const colorFromCoef = (coef) => {
      if (coef === 1) {
        return 'black';
      }
      if (coef >= 1.5) {
        return 'lime';
      } else if (coef >= 1.3) {
        return 'green';
      } else if (coef >= 1) {
        return 'olive';
      } else if (coef >= 0.6) {
        return 'orange';
      } else if (coef >= 0.3) {
        return 'purple';
      } else if (coef >= 0.1) {
        return 'red';
      } else {
        return 'brown';
      }
    };

    const extraCriteria = details.extra_criteria;

    const totalCoef = _.reduce(_.pluck(extraCriteria, 'coef'), (memo, c) => memo * c, 1);

    const newScore = score * Math.pow(totalCoef, 1 / 2);

    const bricks = details.model_omega_bricks || {};

    const nameFromBrickId = {
      experience_year: 'XP Year',
      job_position: 'Job Position',
      skills: 'Skills',
      responsability: 'Responsability',
      school_prestige: 'School',
    };

    const features = (details || {}).feature_values || {};

    const primitives = [
      {
        name: 'Major JobPos',
        data: (features.jobPosition || {}).majorJobPosition,
      },
      ..._.map((features.jobPosition || {}).minorJobPositions, (feature, index) => ({
        name: 'Minor JobPos ' + (index + 1),
        data: feature,
      })),
      ..._.flatten(
        _.map(features.skills, (skillGroup, key) =>
          _.map(skillGroup, (skill, index) => ({
            name: key + ' skill ' + (index + 1),
            data: skill,
          })),
        ),
      ),
      {
        name: 'XP Year',
        data: (features.experienceYear || {})[0],
      },
      {
        name: 'Tech XP Year',
        data: (features.experienceYear || {})[1],
      },
      {
        name: 'JobPos XP Year',
        data: (features.experienceYear || {})[2],
      },
    ];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={1} />
          <Grid.Column width={6}>
            <h2>Primitives</h2>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>name</Table.HeaderCell>
                  <Table.HeaderCell>id</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>value</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>score</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(
                  primitives,
                  ({ name, data }, index) =>
                    data && (
                      <Table.Row key={index}>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>{data.id}</Table.Cell>
                        <Table.Cell textAlign='center'>
                          {data.value !== undefined ? rounded(data.value) : rounded(data.score)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{rounded(data.score)}</Table.Cell>
                        <Table.Cell textAlign='center'>
                          <span style={{ color: colorFromScore(data.score) }}>{letterFromScore(data.score)}</span>
                        </Table.Cell>
                      </Table.Row>
                    ),
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={7}>
            <h1>
              Base Score<span> </span>
              {score && (
                <span style={{ weight: 'bold', color: colorFromScore(score) }}>
                  {' '}
                  {Math.round(score * 10000) / 100}% ({letterFromScore(score)})
                </span>
              )}
            </h1>
            <br />
            <Table basic='very' style={{ width: '40%' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign='center'>Brick</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Cost</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Eq. Grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(['experienceYear', 'jobPosition', 'skills', 'responsibility', 'schoolPrestige'], (key) => (
                  <Table.Row key={key}>
                    <Table.Cell textAlign='center'>{nameFromBrickId[key] || key}</Table.Cell>
                    <Table.Cell textAlign='center'>{rounded((bricks[key] || {}).cost) || '.'}</Table.Cell>
                    <Table.Cell textAlign='center'>
                      {rounded((bricks[key] || {}).scoreIfOtherFeaturesPerfect)}{' '}
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <span
                        style={{
                          weight: 'bold',
                          color: colorFromScore((bricks[key] || {}).scoreIfOtherFeaturesPerfect),
                        }}
                      >
                        {letterFromScore((bricks[key] || {}).scoreIfOtherFeaturesPerfect) || '.'}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <h1>
              Extra Criteria<span> </span>
              <span style={{ color: colorFromCoef(totalCoef) }}>
                {totalCoef >= 1 ? 'x' : ''}
                {Math.round(Math.pow(totalCoef, 1 / 2) * 1000) / 1000}
              </span>
              <b> -> </b>
              {newScore && (
                <span style={{ color: colorFromScore(newScore) }}>
                  {' '}
                  {Math.round(newScore * 10000) / 100}% ({letterFromScore(newScore)})
                </span>
              )}
            </h1>
            <br />
            <Table basic='very' style={{ width: '80%' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Coef</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Details</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(extraCriteria, ({ coef, importance, criterion }, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <span style={{ fontWeight: 'bold', color: colorFromCoef(coef * coef) }}>
                        {Math.round(coef * 100) / 100}{' '}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <span>
                        {{
                          required: 'Required',
                          important: 'Important',
                          bonus: 'Bonus',
                          'super-bonus': 'Super Bonus',
                          super_bonus: 'Super Bonus',
                          superBonus: 'Super Bonus',
                        }[importance] || importance}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <FilterDetailsTree tree={criterion} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderForm() {
    const { presetAnnotation } = this.props;
    const { comment } = this.state;
    return (
      <Form>
        {!presetAnnotation && (
          <div>
            <br />
            <br />
            <br />
            <h3>
              Choose your annotation for this profile :{' '}
              <Dropdown
                inline
                options={annotationTypeOptions}
                value={this.state.annotationType}
                onChange={(e, { value }) => this.setState({ annotationType: value })}
              />
            </h3>
          </div>
        )}
        <br />
        <br />
        <TextArea
          onChange={(e) => this.handleChangeComment(e.target.value)}
          value={comment || ''}
          placeholder='Comment'
        />
      </Form>
    );
  }

  render() {
    const { onClose, presetAnnotation, noDetails } = this.props;

    const colorFromAnnotation = {
			'disqualified;awful': 'brown',
			'disqualified;bad': 'red',
      'backlog;weak': 'orange',
			'backlog;normal': 'yellow',
			'selected;normal': 'olive',
      'selected;strong': 'green',
      'selected;very-strong': 'teal'
    };

    const textFromAnnotation = {
			'disqualified;awful': '0',
			'disqualified;bad': '1',
      'backlog;weak': '2',
			'backlog;normal': '3',
			'selected;normal': '4',
      'selected;strong': '5',
      'selected;very-strong': '6'
    };

    return (
      <Modal open onClose={onClose} closeIcon size='fullscreen'>
        <Header icon='sliders' content={'Feedback (' + this.state.annotationType + ')'} />
        <Modal.Content>
          {!noDetails && this.renderDetails()}
          {this.renderForm()}
        </Modal.Content>
        <Modal.Actions>
          {!presetAnnotation && (
            <Button color='blue' onClick={() => this.handleSubmit({ shouldBeProcessed: false })}>
              Just send feedback
            </Button>
          )}
          {!presetAnnotation && (
            <Button color='green' onClick={() => this.handleSubmit({ shouldBeProcessed: true })}>
              Annotate and send feedback
            </Button>
          )}
          {presetAnnotation && (
            <Button color={colorFromAnnotation[presetAnnotation] || 'green'} onClick={() => this.handleSubmit({ shouldBeProcessed: true })}>
              {textFromAnnotation[presetAnnotation] || ''} ({presetAnnotation})
            </Button>
          )}
          
        </Modal.Actions>
      </Modal>
    );
  }
}

export default FeedbackModal;
