import React, { Component } from 'react';
import { Button, Dropdown, Form, Grid, Modal } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import { Checkbox, Input, List, Select, SweetForm, enhance } from 'sweetform';
import baseUrl from '../baseUrl.js';

class CreateMissionModal extends Component {
  async componentDidMount() {
    const { selectedClientJobsCollectionId } = this.props;
    const missionOptions = await this.getMissionOptions(selectedClientJobsCollectionId);
    this.setState({ missionOptions });
  }

  async getMissionOptions(jobsCollectionId) {
    const url = baseUrl + '/revealRecommendations/getClientJobs/' + jobsCollectionId;
    const missions = (await axios.get(url)).data;
    const missionOptions = _.map(missions, (mission) => ({
      value: mission.id,
      label: mission.id,
    }));
    return missionOptions;
  }

  handleChange(params) {
    this.setState({
      params,
    });
  }

  render() {
    const { onSubmit, onCancel } = this.props;
    const { missionOptions } = this.state || {};
    return (
      <Modal closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Mission Name</label>
              <Input autoFocus field='name' />
            </Form.Field>
            <br />
            <Form.Field>
              <label>Mission Id</label>
              <Select field='id' options={missionOptions} />
            </Form.Field>
            <br />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit((this.state || {}).params)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CreateMissionModal;
