import React from 'react';
import EnrichedText from './EnrichedText';

class CollapsibleEnrichedText extends React.Component {
  state = {
    collapsed: true,
  };

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  renderToggleButton = () => {
    const { t } = this.props;
    const seeMoreButtonText = 'profile.contact.timeline.seeMore';
    const seeLessButtonText = 'profile.contact.timeline.seeLess';
    const seeMoreButtonTextTranslated = t(seeMoreButtonText) ? t(seeMoreButtonText) : seeMoreButtonText;
    const seeLessButtonTextTranslated = t(seeLessButtonText) ? t(seeLessButtonText) : seeLessButtonText;
    const innerText = this.state.collapsed ? seeMoreButtonTextTranslated : seeLessButtonTextTranslated;
    const className = this.state.collapsed ? 'toggle-see-more' : 'toggle-see-less';
    if (!this.state.collapsed && this.props.expandOnly) {
      return;
    }
    return (
      <span className={`collapsible-toggle ${className}`} onClick={this.toggleCollapsed}>
        {innerText}
      </span>
    );
  };

  render() {
    const { text, limit, enrichments, expandOnly } = this.props;
    if(!text) {
      return null
    }
    if (this.state.collapsed) {
      return (
        <span>
          <EnrichedText text={text.slice(0, limit)} enrichments={enrichments} />
          {'...'}
          {this.renderToggleButton()}
        </span>
      );
    }
    return (
      <span>
        <EnrichedText text={text} enrichments={enrichments} />
        {!expandOnly && this.renderToggleButton()}
      </span>
    );
  }
}

export default CollapsibleEnrichedText;
