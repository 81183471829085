import React from 'react';
import queryString from 'query-string';
import { Switch, Route } from 'react-router-dom';
import DataPointView from './DataPointView';

class LabelizedData extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/ml/labelized-data/dataPointsSet/:dataPointsSetId/dataPoint/:dataPointId">
          {({ match, location }) => {
            const queryValues = queryString.parse(location.search);
            const noLabel = queryValues.noLabel === 'true' ? true : false;
            const toAnnotate =
              queryValues.toAnnotate === 'true'
                ? true
                : queryValues.toAnnotate === 'elevenLevels'
                ? 'elevenLevels'
                : false;
            return (
              <DataPointView
                dataPointsSetId={((match || {}).params || {}).dataPointsSetId}
                dataPointId={((match || {}).params || {}).dataPointId}
                noLabel={noLabel}
                toAnnotate={toAnnotate}
              />
            );
          }}
        </Route>
      </Switch>
    );
  }
}

export default LabelizedData;
