import React, { Component } from 'react';
import CereJobsV2 from '../Cerejobsv2';
import JobDiffusion from '../JobDiffusion';
import Tags from '../../Tags';
import { Tab } from 'semantic-ui-react';

import _ from 'underscore';

class Ontologies extends Component {
  render() {
    const panes = [
      {
        menuItem: 'Tags',
        render: () => (
          <Tab.Pane attached={false}>
            <Tags history={[]} match={null} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Jobs',
        render: () => (
          <Tab.Pane attached={false}>
            <CereJobsV2 />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Job Diffusion',
        render: () => (
          <Tab.Pane attached={false}>
            <JobDiffusion />
          </Tab.Pane>
        ),
      },
    ];
    return <Tab menu={{ secondary: true }} panes={panes} />;
  }
}

export default Ontologies;
