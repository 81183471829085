import _ from 'underscore';
import React from 'react';
import {
  Grid,
} from 'semantic-ui-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList
} from 'recharts';

const round = (x) => Math.round(100 * x) / 100;

class OnboardingLevel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      percentMode: false,
      splitMode: this.props.splitMode,
      itemType: 'client',
    };
  }
  handleSetClientItemType = () => {
    this.setState({ itemType: 'client' });
  };
  handleSetOfferItemType = () => {
    this.setState({ itemType: 'offer' });
  };

  handleSetMedianDelayMode = () => {
    this.setState({ medianDelayMode: true, percentMode: false });
  };
  handleSetPercentMode = () => {
    this.setState({ percentMode: true, medianDelayMode: false });
  };
  handleSetCountMode = () => {
    this.setState({ percentMode: false, medianDelayMode: false });
  };
  render() {
    const { offerStats, clientStats } = this.props;

    console.log('OBL');
    console.log('offerStats', offerStats);
    console.log('clientStats', clientStats);

    if (!offerStats || !clientStats) {
      return <div />;
    }

    const stats = this.state.itemType === 'offer' ? offerStats : clientStats;
    const slots = _.last(
      stats[this.props.splitMode].slots,
      this.props.nbSplits,
    );

    const funnelEventTypes = [
      'onboarding',
      '1st-send',
      '10th-send',
      '20th-send',
      '50th-send',
      'none',
    ];

    const data = _.map(slots, ({ title, byUser, total }) => {
      const result = {
        name: title,
      };
      for (let iType = funnelEventTypes.length - 2; iType >= 0; iType--) {
        const type = funnelEventTypes[iType];
        const nextType = funnelEventTypes[iType + 1];
        const nbEvents = (total[type] || {}).count || 0;
        const nbNextEvents = (total[nextType] || {}).count || 0;
        const key = '[total] ' + type;
        if (this.state.medianDelayMode) {
          result[key] = (total[type] || {}).medianDelay || 0;
        } else {
          result[key] = nbEvents - nbNextEvents;
          if (this.state.percentMode) {
            result[key] = round(
              (100 * result[key]) / ((total['onboarding'] || {}).count || 1)
            );
          }
        }
      }
      result['[total] all'] = (total['onboarding'] || {}).count || 1;
      
      _.each(byUser, (userStats, user) => {
        for (let iType = funnelEventTypes.length - 2; iType >= 0; iType--) {
          const type = funnelEventTypes[iType];
          const nextType = funnelEventTypes[iType + 1];
          const nbEvents = (userStats[type] || {}).count || 0;
          const nbNextEvents = (userStats[nextType] || {}).count || 0;
          const key = '[' + user + '] ' + type;
          if (this.state.medianDelayMode) {
            result[key] = (userStats[type] || {}).medianDelay || 0;
          } else {
            result[key] = nbEvents - nbNextEvents;
            if (this.state.percentMode) {
              result[key] = round(
                (100 * result[key]) /
                ((userStats['onboarding'] || {}).count || 1)
              );
            }
          }
        }
        result['[' + user + '] all'] = (userStats['onboarding'] || {}).count || 1;
      });

      return result;
    });

    //const allTypes = ['1st-send', '10th-send', '20th-send', '50th-send'];
    const userKey = this.props.targetUser || 'total';

    const style = (key) => {
      const selected =
        this.state.itemType === key ||
        (key === 'percent' && this.state.percentMode) ||
        (key === 'delay' && this.state.medianDelayMode) ||
        (key === 'count' &&
          !this.state.percentMode &&
          !this.state.medianDelayMode);
      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };


    return (
      <Grid style={{ marginTop: 5 }}>
        <Grid.Row>
          <Grid.Column width={12}>
            <BarChart
              data={data}
              width={450}
              height={200}
              margin={{ left: -10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey={'[' + userKey + '] onboarding'}
                stackId={userKey}
                fill="#D35400"
              />
              ,
              <Bar
                dataKey={'[' + userKey + '] 1st-send'}
                stackId={userKey}
                fill="#F39C12"
              />
              ,
              <Bar
                dataKey={'[' + userKey + '] 10th-send'}
                stackId={userKey}
                fill="#F1C40F"
              />
              ,
              <Bar
                dataKey={'[' + userKey + '] 20th-send'}
                stackId={userKey}
                fill="#2ECC71"
              />
              ,
              <Bar
                dataKey={'[' + userKey + '] 50th-send'}
                stackId={userKey}
                fill="#3498DB"
              >
                <LabelList 
                  dataKey={'[' + userKey + '] all'} 
                  position="top" 
                />
              </Bar>
            </BarChart>
          </Grid.Column>
          <Grid.Column width={4}>
            <h3>Onboarding Level</h3>
            <br />
            <div>
              <span style={style('count')} onClick={this.handleSetCountMode}>
                Count
              </span>
              <span> | </span>
              <span
                style={style('percent')}
                onClick={this.handleSetPercentMode}
              >
                Percent
              </span>
              <span> | </span>
              <span
                style={style('delay')}
                onClick={this.handleSetMedianDelayMode}
              >
                Delay
              </span>
            </div>
            <br />
            <div>
              <span
                style={style('client')}
                onClick={this.handleSetClientItemType}
              >
                Clients
              </span>
              <span> | </span>
              <span
                style={style('offer')}
                onClick={this.handleSetOfferItemType}
              >
                Offers
              </span>
            </div>
          </Grid.Column>
          <div style={{ position: 'absolute', top: -10, left: 70 }} />
        </Grid.Row>
      </Grid>
    );
  }
}

export default OnboardingLevel;
