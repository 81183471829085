import _, { filter } from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';

import {
  Grid,
  Form,
  Segment,
  Header,
  Button,
  Table,
  Icon,
  Message,
} from 'semantic-ui-react';

import {
  List,
  Input,
  Select,
  Checkbox,
  Clearable,
  SweetForm,
  enhance,
} from 'sweetform';

import baseUrl from './baseUrl';
import Pagination from './Pagination';


const FIELDS = [
  'name',
  'crunchbase',
  'linkedin',
  'website',
  'logoURL',
  'industries',
  'locations',
  'stack',
  'staffRange',
  'description',
]

const isMissing = ({ field, value }) => {
  const arrayFields = [
    'industries',
    'locations',
    'stack'
  ]

  if (arrayFields.indexOf(field) >= 0) {
    return !value || _.isEmpty(value);
  }
  if (field == 'staffRange') {
    const { min, max } = value || {};
    return !_.isNumber(min) || !_.isNumber(max);
  }
  if (field == 'description') {
    return !value || _.isEmpty((value || {}).sections);
  }
  return !value;
}

const matchFilter = ({ missingFields, filterMode }) => {
  if (filterMode == 'any') {
    return !_.isEmpty(missingFields);
  }
  if (filterMode == 'all') {
    return (_.difference(FIELDS, missingFields)).length == 0;
  }
  return missingFields.indexOf(filterMode) >= 0
}



class ClientsMarketplaceDescriptor extends React.Component {
  state = {
    filterMode: 'all',
  };

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = async () => {
    let clients = (await axios.get(`${baseUrl}/activeClientsWithMarketplaceDescriptor`)).data;
    clients = _.filter(clients, ({ id }) => !(id.indexOf("demo") >= 0));
    _.each(clients, (client) => {
      const missingFields = [];
      _.each(FIELDS, (field) => {
        if (isMissing({ field, value: (client.marketplaceDescriptor || {})[field] })) {
          missingFields.push(field);
        }
      });
      client.missingFields = missingFields;
    });
    this.setState({ clients });
  };

  handleSetFilterMode = async ({ filterMode }) => {
    this.setState({ filterMode });
  };

  render() {
    const { filterMode, clients } = this.state;
    const filteredClients = _.filter(clients, ({ missingFields }) => matchFilter({ missingFields, filterMode }));
    const filterModeOptions = ['all', 'any'].concat(FIELDS);
    return (
      <Grid>

        <Grid.Row>
          <b>Missing field :</b>
          {_.map(filterModeOptions, (mode) => {
            return (
              <Button
                key={`button-${mode}`}
                color={filterMode == mode ? 'green' : null}
                active={filterMode == mode}
                onClick={() => this.handleSetFilterMode({ filterMode: mode })}
              >
                {mode}
              </Button>
            )
          })}
        </Grid.Row>
        <Grid.Row>
          {(filteredClients || []).length} clients
        </Grid.Row>

        <Grid.Row>
          <Segment>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Client</Table.HeaderCell>
                  {_.map(FIELDS, (field) => {
                    return <Table.HeaderCell key={field}>{field}</Table.HeaderCell>

                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(filteredClients, ({ id, missingFields }, key) => (
                  <Table.Row key={key}>
                    <Table.Cell><a target="_blank" href={`https://app.hiresweet.com/client/${id}/company-settings`}>{id}</a></Table.Cell>
                    {_.map(FIELDS, (field) => {
                      return <Table.Cell key={`${id}-${field}`}>
                        {missingFields.indexOf(field) >= 0 ? (
                          <Icon name='close' color='red' />
                        ) : (<Icon name='check' color='green' />)}
                      </Table.Cell>
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>

        </Grid.Row>
      </Grid>
    );
  }
}

export default ClientsMarketplaceDescriptor;