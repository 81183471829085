import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Icon, Button, Header, Table, Label } from 'semantic-ui-react';
import { Input, Select, List, SweetForm, Checkbox, enhance } from 'sweetform';
import RSelect from 'react-select';
import { Link } from 'react-router-dom';
import baseUrl from './baseUrl.js';
import moment from 'moment';

const initialValue = {
  minScore: 0.7,
  limit: 3000,
  companyIds: '',
  minPublicationDate: moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
  maxPublicationDate: null,
};

const formValueToQuery = (value) => {
  return {
    ...value,
    ...(!_.isEmpty(value.companyIds) && { companyIds: value.companyIds.split(';') }),
  };
};

const discoverLinkFromProfileId = (profileId) => {
  const searchPoolId = "watch"
  const spId = encodeURIComponent(btoa(searchPoolId));
  const sppId = encodeURIComponent(btoa(profileId));
  const querystring = `?spId=${spId}&sppId=${sppId}`;
  return `https://app.hiresweet.com/redirect/discover/candidates${querystring}`;
}

const getQueryResult = async ({ query, jobCollectionId }) => {
  const response = (await axios.post(`${baseUrl}/multiSearch/search`, { ...query, jobCollectionId })).data;
  console.log('response');
  console.log(response);
  if (!response.success) {
    throw Error(JSON.stringify(response.error));
  }
  return response.results;
};

const getOffersQueryResult = async ({ query, jobCollectionId }) => {
  const response = (await axios.post(`${baseUrl}/multiSearch/searchOffers`, { ...query, jobCollectionId })).data;
  console.log('response');
  console.log(response);
  if (!response.success) {
    throw Error(JSON.stringify(response.error));
  }
  return response.results;
};

const getCompanyOptions = async (jobCollectionId) => {
  const { companyIds } = (await axios.get(`${baseUrl}/multiSearch/companyIds/${jobCollectionId}`)).data;
  return _.map(companyIds, (id) => ({ label: id, value: id }));
};

const getJobCollectionOptions = async () => {
  const { jobCollections } = (await axios.get(`${baseUrl}/multiSearch/jobCollections`)).data;
  console.log(jobCollections);
  return _.map(jobCollections, ({ id, name }) => ({ label: name, value: id }));
};

const downloadAsCsv = ({ items, prefix }) => {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  // Download csv
  var csvData = new Blob([csv], { type: 'text/csv' });
  var csvUrl = URL.createObjectURL(csvData);
  var now = moment().format('YYMMDDHHmmss');
  var hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.download = `jungle_search_${now}.csv`;
  hiddenElement.click();
};

const JobsTable = ({ jobs, handleSelectJob, selectedJobId }) => {
  return (
    <Table basic='very'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Modification</Table.HeaderCell>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Offer</Table.HeaderCell>
          <Table.HeaderCell># Candidates</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(jobs, (job) => {
          const { id, title, link, modificationTimestamp, companyId, profiles } = job;
          return (
            <Table.Row key={id} style={selectedJobId == id ? { backgroundColor: '#ddffdd' } : null}>
              <Table.Cell>{moment(modificationTimestamp).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
              <Table.Cell>{companyId}</Table.Cell>
              <Table.Cell>
                <Link to={link} target='_blank'>
                  {title}
                </Link>
              </Table.Cell>
              <Table.Cell>{profiles.length}</Table.Cell>
              <Table.Cell>
                <Icon name='eye' style={{ cursor: 'pointer' }} onClick={() => handleSelectJob(job)} />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const JobDisplay = ({ job }) => {
  const { id, title, link, modificationTimestamp, companyId, profiles } = job;
  return (
    <div>
      <Header as='h2'>
        <Link to={link} target='_blank'>
          {companyId} - {title} [{moment(modificationTimestamp).format('YYYY-MM-DD HH:mm:ss')}]
        </Link>
      </Header>
      <Label>{id}</Label>
      <Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Profile</Table.HeaderCell>
            <Table.HeaderCell>Score</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(_.sortBy(profiles, ({ score }) => -score), ({ profileId, score }) => {
            return (
              <Table.Row key={profileId}>
                <Table.Cell>
                  <Link to={`/watch/candidate/${profileId}`} target='_blank'>
                    {profileId}
                  </Link>
                </Table.Cell>
                <Table.Cell>{score}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

class MultiSearch extends React.Component {
  state = {};

  componentWillMount() {
    this.loadData();
  }

  loadData = async () => {
    const jobCollectionOptions = await getJobCollectionOptions();
    const jobCollectionId = _.first(jobCollectionOptions).value;
    this.setState({
      jobCollectionOptions,
      query: formValueToQuery(initialValue),
    });
    this.loadCompanyOptions(jobCollectionId);
  };

  loadCompanyOptions = async (jobCollectionId) => {
    console.log('loadCompanyOptions');
    const companyOptions = await getCompanyOptions(jobCollectionId);
    console.log(companyOptions);
    this.setState({ companyOptions, jobCollectionId });
  };

  handleChangeJobCollection = ({ value }) => {
    this.setState({ companyOptions: [], jobCollectionId: null });
    this.loadCompanyOptions(value);
  };

  handleChangeQuery = (value) => {
    const query = this.state.query;
    console.log("query", query)
    this.setState({
      query: {
        ...query,
        ...formValueToQuery(value),
      },
    });
  };

  handleSubmitQuery = async () => {
    try {
      this.setState({ computing: true, selectedJob: null });
      const query = this.state.query;
      const jobCollectionId = this.state.jobCollectionId;
      if (_.isEmpty(query)) {
        throw Error('empty query');
      }
      const results = await getQueryResult({ query, jobCollectionId });
      this.setState({
        computing: false,
        jobs: results,
        selectedJob: results[0],
      });
    } catch (e) {
      alert('Error getting query results : ' + JSON.stringify(e.message));
      this.setState({
        computing: false,
      });
    }
  };

  handleSubmitOffersQueryAndDownloadResult = async () => {
    this.setState({ computing: true });
    const query = this.state.query;
    console.log("submit query", query)

    const jobCollectionId = this.state.jobCollectionId;
    if (_.isEmpty(query)) {
      throw Error('empty query');
    }
    const results = await getOffersQueryResult({ query, jobCollectionId });
    const items = _.map(results, (result)=>({
      ...result,
      modificationDate: result.modificationTimestamp ? moment(result.modificationTimestamp).format('YYYY-MM-DD HH:mm:ss') : "",
      lastActionDate: result.lastActionTimestamp ? moment(result.lastActionTimestamp).format('YYYY-MM-DD HH:mm:ss') : "",
    }))
    downloadAsCsv({items, prefix: "jobs-"+jobCollectionId})
    this.setState({
      computing: false,
    });
  }

  handleSelectJob = (job) => {
    this.setState({ selectedJob: job });
  };

  handleDownloadAsCSV = (maxPerOffer) => {
    const { jobs, jobCollectionId } = this.state;
    const items = [];
    _.each(jobs, ({ link, title, modificationTimestamp, companyId, profiles }) => {
      let slicedProfiles = profiles
      if (maxPerOffer){
        slicedProfiles = _.first(_.sortBy(profiles, ({score, isOnDiscover})=>(-score - (isOnDiscover ? 1000 : 0))), maxPerOffer)
      }
      _.each(slicedProfiles, ({ profileId, score, isOnDiscover }) => {
        items.push({
          link,
          companyId,
          title,
          modificationDate: moment(modificationTimestamp).format('YYYY-MM-DD HH:mm:ss'),
          score,
          profileId,
          link: discoverLinkFromProfileId(profileId),
          isOnDiscover
        });
      });
    });
    downloadAsCsv({ items, prefix: jobCollectionId });
  };

  render() {
    const { computing, jobs, selectedJob, jobCollectionOptions, jobCollectionId, companyOptions } = this.state;
    if (!jobCollectionId) {
      return <div />;
    }
    const nbJobs = _.isArray(jobs) ? jobs.length : 0;
    const nbMatches = _.isArray(jobs) ? _.map(jobs, ({ profiles }) => profiles.length).reduce((a, b) => a + b, 0) : 0;
    const scoreOptions = _.map([0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95], (s) => ({
      label: s,
      value: s,
    }));
    return (
      <Grid columns={2}>
        <Grid.Column width={2}>
          <RSelect
            options={jobCollectionOptions}
            value={jobCollectionId}
            onChange={this.handleChangeJobCollection}
            placeholder='Job Collection'
          />
          <SweetForm key={jobCollectionId} initialValues={initialValue} onChange={this.handleChangeQuery}>
            <Input type='date' field='minModificationDate' />
            <Input type='date' field='maxModificationDate' />
            <Select field='minScore' placeholder='Min Score' options={scoreOptions} />
            <Select
              multi={true}
              options={companyOptions}
              labelKey='label'
              valueKey='value'
              field='companyIds'
              placeholder='Companies'
            />
            <Button color='green' onClick={() => this.handleSubmitQuery()}>
              Search Offers
            </Button>
            <br />
            <b>{`${nbJobs} offers.`}</b>
            <br />
            <b>{`${nbMatches} matches.`}</b>
            <br />
            {nbMatches ? (
              <Button size='mini' color='orange' onClick={() => this.handleDownloadAsCSV()}>
                Download CSV
              </Button>
            ) : null}
            {nbMatches ? (
              <Button size='mini' color='orange' onClick={() => this.handleDownloadAsCSV(15)}>
                Download CSV (max 15 / o)
              </Button>
            ) : null}
            <Button size='mini' color='orange' onClick={() => this.handleSubmitOffersQueryAndDownloadResult()}>
                Download Jobs CSV
            </Button>
          </SweetForm>
        </Grid.Column>
        <Grid.Column width={7}>
          <div
            style={{
              height: '900px',
              display: 'inline-block',
              width: '100%',
              overflowY: 'scroll',
            }}
          >
            {computing ? (
              <Icon name='circle notched' loading />
            ) : (
              <JobsTable
                jobs={jobs}
                handleSelectJob={this.handleSelectJob}
                selectedJobId={selectedJob && selectedJob.id}
              />
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={7}>
          <div
            style={{
              height: '900px',
              overflowY: 'scroll',
              display: 'inline-block',
              width: '100%',
            }}
          >
            {selectedJob && <JobDisplay job={selectedJob} />}
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default MultiSearch;
