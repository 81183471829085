import _ from 'underscore';
import React, { Component } from 'react';

import { Icon, Table } from 'semantic-ui-react';

class AutoRecommendationsTable extends Component {
  render() {
    const {
      recommendations,
      clickOnUpdateStatus,
      onClickOnSelectProfile,
      selectedProfileId,
      removeRecommendationAndHideForMission,
    } = this.props;
    return (
      <Table
        style={{
          width: '100%',
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='left'>Talent</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Status</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Update Status</Table.HeaderCell>
            <Table.HeaderCell textAlign='left'>Hide</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {recommendations
            ? _.map(recommendations, (recommendation, index) => {
                return (
                  <Table.Row>
                    <Table.Cell
                      style={
                        selectedProfileId && selectedProfileId === recommendation.profileId
                          ? { cursor: 'pointer', fontWeight: 'bold', color: 'green' }
                          : { cursor: 'pointer' }
                      }
                      onClick={() => onClickOnSelectProfile(recommendation.profileId)}
                      key={recommendation.profileId + index}
                      textAlign='left'
                    >
                      {recommendation.profileId}
                    </Table.Cell>
                    <Table.Cell>{recommendation.status}</Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Icon
                        link
                        color={recommendation.status === 'pre-pending' ? 'green' : 'red'}
                        name={recommendation.status === 'pre-pending' ? 'caret up' : 'caret down'}
                        onClick={() => clickOnUpdateStatus(recommendation)}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Icon
                        link
                        color={'red'}
                        name='delete'
                        onClick={() => removeRecommendationAndHideForMission(recommendation)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            : null}
        </Table.Body>
      </Table>
    );
  }
}

export default AutoRecommendationsTable;
