import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';

import { Grid, Form, Table, Label, Icon } from 'semantic-ui-react';

import { Select, SweetForm } from 'sweetform';

import baseUrl from './baseUrl';
import { sourcesOptions } from './Config';
import Modal from './Modal';

const typeOptions = [
  { value: 'getUser', label: 'getUser' },
  { value: 'getCompany', label: 'getCompany' },
  { value: 'getOffer', label: 'getOffer' },
  { value: 'listUser', label: 'listUser' },
  { value: 'listCompanies', label: 'listCompanies' },
  { value: 'listGroups', label: 'listGroups' },
  { value: 'listMembers', label: 'listMembers' },
  { value: 'listOffers', label: 'listOffers' },
  { value: 'listSheets', label: 'listSheets' },
  { value: 'listClients', label: 'listClients' },
  { value: 'listProfiles', label: 'listProfiles' },
  { value: 'discoverCompanies', label: 'discoverCompanies' },
];

const statusOptions = [
  { value: 'healthy', label: 'Healthy' },
  { value: 'warning', label: 'Warning' },
  { value: 'error', label: 'Error' },
  { value: 'dead', label: 'Dead' },
];

// Actions
const SET_PARAMS = 'SET_PARAMS';
const setParams = (params) => ({ type: SET_PARAMS, params });

const SET_RESULTS = 'SET_RESULTS';
const setResults = (results) => ({ type: SET_RESULTS, results });

const SET_SAMPLE = 'SET_SAMPLE';
const setSample = (sample) => ({ type: SET_SAMPLE, sample });

// Reducers
const params = (state = {}, action) => {
  switch (action.type) {
    case SET_PARAMS:
      return action.params;
    default:
      return state;
  }
};

const results = (state = null, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return action.results;
    default:
      return state;
  }
};

const sample = (state = null, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return null;
    case SET_SAMPLE:
      return action.sample;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  params,
  results,
  sample,
});

// Store
const store = createStore(rootReducer);

const healthColor = {
  healthy: 'green',
  warning: 'yellow',
  error: 'red',
  dead: 'black',
};

const WorkersTable = ({ results, onSample }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={4}>Worker</Table.HeaderCell>
        <Table.HeaderCell width={2}>Type</Table.HeaderCell>
        <Table.HeaderCell width={2} colSpan="2">
          1 min.
        </Table.HeaderCell>
        <Table.HeaderCell width={2} colSpan="2">
          5 min.
        </Table.HeaderCell>
        <Table.HeaderCell width={2} colSpan="2">
          30 min.
        </Table.HeaderCell>
        <Table.HeaderCell width={2} colSpan="2">
          1 hour
        </Table.HeaderCell>
        <Table.HeaderCell width={2} colSpan="2">
          1 day
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(results, (row, i) => (
        <Table.Row key={i}>
          <Table.Cell>
            <Label
              circular
              color={healthColor[row.health]}
              empty
              style={{ marginRight: '1rem' }}
            />
            <strong>{row.name || row.hostname}</strong>
          </Table.Cell>
          <Table.Cell>{row.source}</Table.Cell>
          <Table.Cell width={1}>{row.minute}</Table.Cell>
          <Table.Cell
            width={1}
            negative={row.minuteErrors > 0}
            onClick={() => onSample(row, 1)}
          >
            {row.minute1Errors ? <Icon name="attention" /> : null}{' '}
            {row.minuteErrors}
          </Table.Cell>
          <Table.Cell width={1}>{row.minute5}</Table.Cell>
          <Table.Cell
            width={1}
            negative={row.minute5Errors > 0}
            onClick={() => onSample(row, 5)}
          >
            {row.minute5Errors ? <Icon name="attention" /> : null}{' '}
            {row.minute5Errors}
          </Table.Cell>
          <Table.Cell width={1}>{row.minute30}</Table.Cell>
          <Table.Cell
            width={1}
            negative={row.minute30Errors > 0}
            onClick={() => onSample(row, 30)}
          >
            {row.minute30Errors}
          </Table.Cell>
          <Table.Cell width={1}>{row.hour}</Table.Cell>
          <Table.Cell
            width={1}
            negative={row.hourErrors > 0}
            onClick={() => onSample(row, 60)}
          >
            {row.hourErrors}
          </Table.Cell>
          <Table.Cell width={1}>{row.day}</Table.Cell>
          <Table.Cell
            width={1}
            negative={row.dayErrors > 0}
            onClick={() => onSample(row, 60 * 24)}
          >
            {row.dayErrors}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
class Workers extends Component {
  componentDidMount() {
    this.props.onLoad({});
  }

  render() {
    const { params, results, sample, onLoad, onClose, onSample } = this.props;

    return (
      <div>
        {sample ? (
          <Modal
            headerText="Error sample"
            submitText="Close"
            cancelText=""
            onSubmit={onClose}
            onCancel={onClose}
          >
            <pre>{JSON.stringify(sample, null, '  ')}</pre>
          </Modal>
        ) : null}
        <Grid>
          <Grid.Column width={3}>
            <Form>
              <SweetForm onChange={(p) => onLoad({ ...params, ...p })}>
                <Form.Field>
                  <Select
                    multi
                    field="sources"
                    options={sourcesOptions}
                    placeholder="Every source"
                  />
                </Form.Field>
                <Form.Field>
                  <Select
                    multi
                    field="types"
                    options={typeOptions}
                    placeholder="Every task type"
                  />
                </Form.Field>
                <Form.Field>
                  <Select
                    multi
                    field="status"
                    options={statusOptions}
                    placeholder="Every status"
                  />
                </Form.Field>
              </SweetForm>
            </Form>
          </Grid.Column>
          <Grid.Column width={13}>
            <WorkersTable
              onSample={(row, minutes) => onSample(params, { ...row, minutes })}
              results={results}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapSWorkers = (state) => ({
  params: state.params,
  results: state.results,
  sample: state.sample,
});

const mapDWorkers = (dispatch) => ({
  onLoad: async (params) => {
    dispatch(setParams(params));
    const { sources, types, status } = params;

    const endpoint = `/scrapers/workers/${sources ||
      _.pluck(sourcesOptions, 'value').join(';')}?types=${types || ''}`;
    const results = (await axios.get(`${baseUrl}${endpoint}`)).data;
    const filtered = status
      ? _.filter(results, ({ health }) => status.includes(health))
      : results;

    dispatch(setResults(filtered));
  },
  onSample: async ({ types }, { source, hostname, minutes }) => {
    const from = _.now() - minutes * 60000;
    const result = (await axios.get(
      `${baseUrl}/scrapers/errors/${source}?from=${from}&hostname=${hostname}&types=${types ||
        ''}`,
    )).data;
    dispatch(setSample(result));
  },
  onClose: () => dispatch(setSample(null)),
});

const WorkersContainer = connect(
  mapSWorkers,
  mapDWorkers,
)(Workers);

export default () => (
  <Provider store={store}>
    <WorkersContainer />
  </Provider>
);
