import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Grid,
  Label,
  Button,
  Modal,
  Popup,
  Icon,
  Message,
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class OfferDetailsModal extends Component {
  renderOfferMemo() {
    const { offer } = this.props;
    return (
      <div>
        <div>{offer.location && <Label>{offer.location}</Label>}</div>
        <div>
          {_.map((offer.skills || {}).required, (skill) => (
            <Label color="blue">{skill}</Label>
          ))}
          {_.map((offer.skills || {}).important, (skill) => (
            <Label color="cyan">{skill}</Label>
          ))}
          {_.map((offer.skills || {}).bonus, (skill) => (
            <Label>{skill}</Label>
          ))}
        </div>
        {offer.comments && (
          <Message
            icon="comment"
            header="HireSweet Comment"
            list={offer.comments.split('\n')}
          />
        )}
        {offer.callReport && (
          <Message
            icon="file"
            header="HireSweet Comment"
            list={offer.callReport.split('\n')}
          />
        )}
      </div>
    );
  }
  renderActions() {
    const { offer } = this.props;
    const actions = offer.actions;
    if (!actions) {
      return null;
    }
    const actionsFromType = _.groupBy(actions, 'type');
    const nbSends = (actionsFromType['send'] || []).length;
    const nbSkips = (actionsFromType['skipProfile'] || []).length;
    const lastActions = _.filter(
      actions,
      ({ type }) => ['send', 'skipProfile'].indexOf(type) >= 0,
    ).slice(0, 15);

    return (
      <div>
        <div>
          <Label color="green">
            {nbSends} send{nbSends !== 1 ? 's' : ''}
          </Label>
          <Label color="red">
            {nbSkips} skip{nbSkips !== 1 ? 's' : ''}
          </Label>
        </div>
        <Grid padded>
          {_.map(lastActions, (action, index) => (
            <Grid.Row key={index} style={{ padding: 0 }}>
              <Grid.Column width={2} textAlign="right">
                <div
                  style={{
                    fontWeight: 'bold',
                    color: action.type === 'skipProfile' ? 'red' : 'green',
                  }}
                >
                  {action.type === 'skipProfile' ? 'skip' : action.type}
                </div>
              </Grid.Column>
              <Grid.Column width={1}>
                {action.reason && (
                  <Popup
                    trigger={<Icon name="add" link />}
                    content={action.reason}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={13}>{(action.profile || {}).id}</Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </div>
    );
  }
  render() {
    const { offer, onClose } = this.props;
    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>{offer && offer.title}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid columns={2}>
            <Grid.Column>{this.renderOfferMemo()}</Grid.Column>
            <Grid.Column>{this.renderActions()}</Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={onClose}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const withOfferDetailsModal = (WrappedComponent) =>
  class extends React.PureComponent {
    state = {};
    handleOpenOffer = async (offerId) => {
      let offer = (await axios.get(`${baseUrl}/offers/${offerId}`)).data;
      if (offer.platformId) {
        const actionStats = (await axios.post(`${baseUrl}/station/actions`, {
          jobOfferIds: offer.platformId,
        })).data;
        if (actionStats.results) {
          offer.actions = actionStats.results;
        }
      }
      this.setState({
        offer,
        showModal: true,
      });
    };
    handleClose = () => {
      this.setState({
        showModal: false,
      });
    };
    render() {
      const { offer, showModal } = this.state;
      return (
        <span>
          {showModal && (
            <OfferDetailsModal offer={offer} onClose={this.handleClose} />
          )}
          <WrappedComponent
            {...this.props}
            showOfferDetails={this.handleOpenOffer}
          />
        </span>
      );
    }
  };

export default withOfferDetailsModal;
