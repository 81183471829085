import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import { Grid, Segment, Statistic, Table, Tab, Progress, Button } from 'semantic-ui-react';
import _ from 'underscore';
import baseUrl from '../baseUrl';

const GRADES = [
  { grade: 'A', color: '#84fa88' },
  { grade: 'B', color: '#c9fa84' },
  { grade: 'C', color: '#f0fa84' },
  { grade: 'D', color: '#fac184' },
  { grade: 'E', color: '#fa9284' },
  { grade: 'X', color: '#a8a8a8' },
]

const computeKPIs = ({
  onboarded,
  upload,
  select,
  disqualify,
  skip,
  send,
  skipAtUpload,
  sendAtUpload,
  answerAtSend,
  positiveAnswerAtSend,
  recruitmentAtOnboarding,
}) => {
  return {
    onboarded,
    upload,
    selectRate: select / (select + disqualify || 1),
    uploadTreatmentRate: (skipAtUpload + sendAtUpload) / (upload || 1),
    sendRate: send / (send + skip || 1),
    answerRate: answerAtSend / (send || 1),
    positiveAnswerRate: positiveAnswerAtSend / (send || 1),
    conversionRate: recruitmentAtOnboarding / (onboarded || 1),
  };
};

const slotBarChart = ({ temporalKpis, dataKey, kpiName, color, unit }) => {
  if (!temporalKpis) {
    return <div />;
  }
  return (
    <div>
      <h4>{kpiName}</h4>
      <BarChart width={600} height={300} data={temporalKpis} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey='beginDay' angle={45} textAnchor='start' height={70} interval={0} style={{ fontSize: '10px' }} />
        <YAxis unit={unit} />
        <CartesianGrid strokeDasharray='3 3' vertical={false} />
        <Tooltip itemStyle={{ color: 'black' }} />
        {/* <Legend /> */}
        <Bar dataKey={dataKey} name={kpiName} stackId='a' fill={color} />
      </BarChart>
    </div>
  );
};

const stackedSlotBarChart = ({ temporalKpis, items, kpiName, unit }) => {
  if (!temporalKpis) {
    return <div />;
  }
  return (
    <div>
      <h4>{kpiName}</h4>
      <BarChart width={600} height={300} data={temporalKpis} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey='beginDay' angle={45} textAnchor='start' height={70} interval={0} style={{ fontSize: '10px' }} />
        <YAxis unit={unit} />
        <CartesianGrid strokeDasharray='3 3' vertical={false} />
        <Tooltip itemStyle={{ color: 'black' }} />
        {_.map(items, ({ name, dataKey, color }) => (
          <Bar key={dataKey} dataKey={dataKey} name={name} stackId='a' fill={color} />
        ))}
      </BarChart>
    </div>
  );
};


const kpiStatistics = ({ kpis, kpiObjectives, previousKpis }) => {
  const colorFromObjectiveAndValue = (value, objective) => {
    if (!objective || !value) {
      return 'black';
    }
    if (value > objective) {
      return 'green';
    }
    if (2 * value < objective) {
      return 'red';
    }
    return 'orange';
  };
  if (!kpis) {
    return <div />;
  }
  return (
    <Statistic.Group widths={'4'} style={{ width: '100%' }}>
      <Statistic color={colorFromObjectiveAndValue(kpis.onboarded, kpiObjectives.onboarded)}>
        <Statistic.Label>
          Nombre d'onboardings {kpiObjectives.onboarded ? ` (${kpiObjectives.onboarded})` : null}
        </Statistic.Label>
        <Statistic.Value>{kpis.onboarded} <span style={{ 'font-size': 'small' }}>({previousKpis.onboarded})</span> </Statistic.Value>
      </Statistic>
      <Statistic color={colorFromObjectiveAndValue(kpis.upload, kpiObjectives.upload)}>
        <Statistic.Label>Nombre d'uploads {kpiObjectives.upload ? ` (${kpiObjectives.upload})` : null}</Statistic.Label>
        <Statistic.Value>{kpis.upload} <span style={{ 'font-size': 'small' }}>({previousKpis.upload})</span></Statistic.Value>
      </Statistic>
      <Statistic color={colorFromObjectiveAndValue(kpis.send, kpiObjectives.send)}>
        <Statistic.Label>Mails envoyés {kpiObjectives.send ? ` (${kpiObjectives.send})` : null}</Statistic.Label>
        <Statistic.Value>{kpis.send} <span style={{ 'font-size': 'small' }}>({previousKpis.send})</span></Statistic.Value>
      </Statistic>
      <Statistic color={colorFromObjectiveAndValue(kpis.positiveAnswer, kpiObjectives.positiveAnswer)}>
        <Statistic.Label>
          Réponses positives {kpiObjectives.positiveAnswer ? ` (${kpiObjectives.positiveAnswer})` : null}
        </Statistic.Label>
        <Statistic.Value>{kpis.positiveAnswer} <span style={{ 'font-size': 'small' }}>({previousKpis.positiveAnswer})</span></Statistic.Value>
      </Statistic>
    </Statistic.Group>
  );
};

class KPIGraphs extends Component {
  state = {};
  handleSetSplitMode = (splitMode) => {
    this.setState({ splitMode });
  };
  render() {
    const { slots, kpiObjectives } = this.props;
    const splitMode = this.state.splitMode || 'byMonth';
    const temporalKpis = _.map(slots[splitMode], (actions) => ({
      ...computeKPIs(actions),
      ...actions,
    }));
    const periodObjectives = (kpiObjectives || {})[splitMode] || {};
    console.log('periodObjectives', periodObjectives);

    const kpisForStatistics = temporalKpis && _.last(temporalKpis);
    const previousKpisForStatistics = temporalKpis && temporalKpis.slice(-2)[0];

    const style = (key) => {
      const selected =
        this.state.itemType === key ||
        (key === 'byWeek' && splitMode === 'byWeek') ||
        (key === 'byMonth' && splitMode === 'byMonth') ||
        (key === 'byQuarter' && splitMode === 'byQuarter');
      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    return (
      <div>
        <Grid centered>
          <Grid.Column>
            <Grid.Row>
              <center>
                <div>
                  <span style={style('byWeek')} onClick={() => this.handleSetSplitMode('byWeek')}>
                    Week
                  </span>
                  <span> | </span>
                  <span style={style('byMonth')} onClick={() => this.handleSetSplitMode('byMonth')}>
                    Month
                  </span>
                  <span> | </span>
                  <span style={style('byQuarter')} onClick={() => this.handleSetSplitMode('byQuarter')}>
                    Quarter
                  </span>
                </div>
              </center>
            </Grid.Row>
            {kpisForStatistics && (
              <div>
                <Grid.Row columns={1}>{kpiStatistics({ kpis: kpisForStatistics, kpiObjectives: periodObjectives, previousKpis: previousKpisForStatistics })}</Grid.Row>
              </div>
            )}


            {temporalKpis && (
              <div sytle={{ margin: '20px' }}>
                <Grid.Row centered columns={2}>
                  <Grid.Column>
                    {stackedSlotBarChart({
                      temporalKpis,
                      kpiName: "Nombre d'onboardings",
                      items: _.map(GRADES, ({ grade, color }) => ({ dataKey: `onboarded${grade}`, name: grade, color })),
                      unit: '',
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    {stackedSlotBarChart({
                      temporalKpis,
                      kpiName: "Nombre d'uploads",
                      items: _.map(GRADES, ({ grade, color }) => ({ dataKey: `upload${grade}`, name: grade, color })),
                      unit: '',
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column>
                    {stackedSlotBarChart({
                      temporalKpis,
                      kpiName: "Nombre de sends",
                      items: _.map(GRADES, ({ grade, color }) => ({ dataKey: `send${grade}`, name: grade, color })),
                      unit: '',
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    {stackedSlotBarChart({
                      temporalKpis,
                      kpiName: "Nombre de réponses positives",
                      items: _.map(GRADES, ({ grade, color }) => ({ dataKey: `positiveAnswer${grade}`, name: grade, color })),
                      unit: '',
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column>
                    {slotBarChart({
                      temporalKpis,
                      dataKey: 'selectRate',
                      kpiName: 'Taux de select',
                      color: '#82ca9d',
                      unit: '%',
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    {slotBarChart({
                      temporalKpis,
                      dataKey: 'uploadTreatmentRate',
                      kpiName: 'Taux de traitement des uploads',
                      color: '#82ca9d',
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column>
                    {slotBarChart({
                      temporalKpis,
                      dataKey: 'sendRate',
                      kpiName: 'Taux de send',
                      color: '#82ca9d',
                      unit: '%',
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    {slotBarChart({
                      temporalKpis,
                      dataKey: 'answerRate',
                      kpiName: 'Taux de réponse',
                      color: '#82ca9d',
                      unit: '%',
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column>
                    {slotBarChart({
                      temporalKpis,
                      dataKey: 'positiveAnswerRate',
                      kpiName: 'Taux de réponses positives',
                      color: '#82ca9d',
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    {slotBarChart({
                      temporalKpis,
                      dataKey: 'conversionRate',
                      kpiName: 'Taux de conversion',
                      color: '#fff4d4',
                      unit: '%',
                    })}
                  </Grid.Column>
                </Grid.Row>
              </div>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

class SlotFunnelsTable extends Component {
  state = {
    percentMode: false,
  };
  handleSetPercentMode = () => {
    this.setState({ percentMode: true });
  };
  handleSetCountMode = () => {
    this.setState({ percentMode: false });
  };
  handleSetSplitMode = (splitMode) => {
    this.setState({ splitMode });
  };
  render() {
    const { slots, attributes, defaultSplitMode, disablePercentMode } = this.props;
    const splitMode = this.state.splitMode || defaultSplitMode || 'byWeek';

    const style = (key) => {
      const selected =
        this.state.itemType === key ||
        (key === 'percent' && this.state.percentMode) ||
        (key === 'count' && !this.state.percentMode) ||
        (key === 'byWeek' && splitMode === 'byWeek') ||
        (key === 'byMonth' && splitMode === 'byMonth') ||
        (key === 'byQuarter' && splitMode === 'byQuarter');

      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    const round = (x) => Math.round(x);

    return (
      <div>
        <Grid>
          <Grid.Row columns={2}>
            {!disablePercentMode && (
              <Grid.Column center>
                <center>
                  <div>
                    <span style={style('count')} onClick={this.handleSetCountMode}>
                      Count
                    </span>
                    <span> | </span>
                    <span style={style('percent')} onClick={this.handleSetPercentMode}>
                      Percent
                    </span>
                  </div>
                </center>
              </Grid.Column>
            )}

            <Grid.Column>
              <center>
                <div>
                  <span style={style('byWeek')} onClick={() => this.handleSetSplitMode('byWeek')}>
                    Week
                  </span>
                  <span> | </span>
                  <span style={style('byMonth')} onClick={() => this.handleSetSplitMode('byMonth')}>
                    Month
                  </span>
                  <span> | </span>
                  <span style={style('byQuarter')} onClick={() => this.handleSetSplitMode('byQuarter')}>
                    Quarter
                  </span>
                </div>
              </center>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              {_.map(attributes, ({ title }, index) => (
                <Table.HeaderCell key={index}>{title}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map([...slots[splitMode]].reverse(), (slot, index) => (
              <Table.Row key={index}>
                <Table.Cell>{slot.title}</Table.Cell>
                {_.map(attributes, ({ id, percentRef }) => (
                  <Table.Cell key={id}>
                    {this.state.percentMode && percentRef
                      ? round((100 * (slot[id] || 0)) / (slot[percentRef] || 1)) + '%'
                      : slot[id] || 0}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

class SlotFunnelsChart extends Component {
  state = {
    percentMode: false,
    splitMode: 'byWeek',
  };
  handleSetPercentMode = () => {
    this.setState({ percentMode: true });
  };
  handleSetCountMode = () => {
    this.setState({ percentMode: false });
  };
  handleSetSplitMode = (splitMode) => {
    this.setState({ splitMode });
  };
  render() {
    const { slots, states, defaultSplitMode, disablePercentMode } = this.props;

    if (!slots || !states) {
      return <div />;
    }
    const splitMode = this.state.splitMode || defaultSplitMode || 'byWeek';

    const round = (x) => Math.round(100 * x) / 100;

    const data = _.map(slots[splitMode], (slot) => {
      const result = {
        name: slot.title,
      };
      _.each(states, (state) => {
        result[state] = slot[state] || 0;
        if (this.state.percentMode) {
          result[state] = round((100 * result[state]) / (slot['total'] || 1));
        }
      });

      return result;
    });

    const style = (key) => {
      const selected =
        this.state.itemType === key ||
        (key === 'percent' && this.state.percentMode) ||
        (key === 'count' && !this.state.percentMode) ||
        (key === 'byWeek' && splitMode === 'byWeek') ||
        (key === 'byMonth' && splitMode === 'byMonth') ||
        (key === 'byQuarter' && splitMode === 'byQuarter');

      return {
        cursor: 'pointer',
        color: selected ? 'blue' : 'lightgray',
      };
    };

    const colorFromIndex = (index) => {
      return ['#D35400', '#F39C12', '#F1C40F', '#2ECC71', '#3498DB'][index] || 'gray';
    };

    return (
      <Grid style={{ marginTop: 5 }}>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column style={{ padding: 0 }}>
            <center>{this.props.subtitle}</center>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <BarChart data={data} width={450} height={200} margin={{ left: -10 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              {_.map(states, (state, index) => (
                <Bar key={index} dataKey={state} stackId='1' fill={colorFromIndex(index)}>
                  {state === _.last(states) ? <LabelList dataKey='total' position='top' /> : null}
                </Bar>
              ))}
            </BarChart>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          {!disablePercentMode && (
            <Grid.Column width={4}>
              <div>
                <span style={style('count')} onClick={this.handleSetCountMode}>
                  Count
                </span>
                <span> | </span>
                <span style={style('percent')} onClick={this.handleSetPercentMode}>
                  Percent
                </span>
              </div>
            </Grid.Column>
          )}
          <Grid.Column width={4}>
            <span style={style('byWeek')} onClick={() => this.handleSetSplitMode('byWeek')}>
              Week
            </span>
            <span> | </span>
            <span style={style('byMonth')} onClick={() => this.handleSetSplitMode('byMonth')}>
              Month
            </span>
            <span> | </span>
            <span style={style('byQuarter')} onClick={() => this.handleSetSplitMode('byQuarter')}>
              Quarter
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

class StatsTable extends Component {
  state = {};
  renderMatchCountTable({ keyName, baseIndex, counts }) {
    const hasNbOffers = _.filter(counts, ({ nbOffers }) => nbOffers).length > 0;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>index</Table.HeaderCell>
            <Table.HeaderCell>{keyName}</Table.HeaderCell>
            {hasNbOffers && <Table.HeaderCell>#offers</Table.HeaderCell>}
            {hasNbOffers && <Table.HeaderCell>avg</Table.HeaderCell>}
            <Table.HeaderCell>total</Table.HeaderCell>
            <Table.HeaderCell>#A</Table.HeaderCell>
            <Table.HeaderCell>#B</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(counts, ({ total, nbOffers, nbA, nbB, key }, index) => (
            <Table.Row key={index}>
              <Table.Cell>{(baseIndex || 0) + index + 1}</Table.Cell>
              <Table.Cell>{key}</Table.Cell>
              {hasNbOffers && <Table.Cell>{nbOffers}</Table.Cell>}
              {hasNbOffers && <Table.Cell>{Math.round((10 * total) / (nbOffers || 1)) / 10}</Table.Cell>}
              <Table.Cell>{total}</Table.Cell>
              <Table.Cell>{nbA}</Table.Cell>
              <Table.Cell>{nbB}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
  render() {
    const { keyName, nbMatchesByItem } = this.props;
    const sortedCounts = _.sortBy(
      _.map(nbMatchesByItem, (value, key) => ({
        ...value,
        key: key,
      })),
      'total',
    ).reverse();

    const top10 = sortedCounts.slice(0, 10);
    const flop10 = sortedCounts.slice(sortedCounts.length - 10);
    const baseIndexFlop10 = sortedCounts.length - 10;

    const totalNbMatches = _.reduce(sortedCounts, (memo, { total }) => memo + total, 0);

    const average = Math.round(100 * (totalNbMatches / Math.max(sortedCounts.length, 1))) / 100;
    const median = (sortedCounts.length && sortedCounts[Math.floor(sortedCounts.length / 2)].total) || 0;

    const alwaysDeployed = (sortedCounts || []).length <= 40;
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7} />
            <Grid.Column width={4}>
              <Statistic.Group>
                <Statistic>
                  <Statistic.Value>{average}</Statistic.Value>
                  <Statistic.Label>Average #matches</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{median}</Statistic.Value>
                  <Statistic.Label>Median #matches</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
            <Grid.Column width={5} />
          </Grid.Row>
        </Grid>
        {this.state.seeAll || alwaysDeployed ? (
          <div>
            {this.renderMatchCountTable({ keyName, counts: sortedCounts })}
            {!alwaysDeployed && (
              <center>
                {/* eslint-disable-next-line */}
                <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ seeAll: false })}>
                  collapse
                </a>
              </center>
            )}
          </div>
        ) : (
          <div>
            <h4>Top 10</h4>
            {this.renderMatchCountTable({ keyName, counts: top10 })}
            <center>
              {/* eslint-disable-next-line */}
              <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ seeAll: true })}>
                see all
              </a>
            </center>
            <h4>Flop 10</h4>
            {this.renderMatchCountTable({ keyName, counts: flop10, baseIndex: baseIndexFlop10 })}
          </div>
        )}
      </div>
    );
  }
}

const createSlots = ({ states }) => {
  const today = new Date().toISOString().slice(2, 10);
  const slotFilter = (slots) => _.filter(slots, ({ beginDay }) => beginDay <= today);

  const enrichSlots = (slots) => {
    _.each(slots, (slot) => {
      slot.total = 0;
      _.each(states, (state) => {
        slot[state] = 0;
      });
    });
  };

  const weekSlots = slotFilter(
    _.map(_.range(400), (iWeek) => {
      const monday4June2018Timestamp = 1528074024000;
      const nbMsInADay = 24 * 3600 * 1000;
      const mondayTimestamp = monday4June2018Timestamp + iWeek * 7 * nbMsInADay;
      const beginDay = new Date(mondayTimestamp).toISOString().slice(2, 10);
      const endDay = new Date(mondayTimestamp + 7 * nbMsInADay).toISOString().slice(2, 10);
      const slot = {
        beginDay,
        endDay,
        title: 'W: ' + beginDay,
      };
      return slot;
    }),
  );
  enrichSlots(weekSlots);

  const nextMonthIds = ['02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const monthSlots = slotFilter([
    { beginDay: '18-10-01', endDay: '18-11-01', title: '10-2018' },
    { beginDay: '18-11-01', endDay: '18-12-01', title: '11-2018' },
    { beginDay: '18-12-01', endDay: '19-01-01', title: '12-2018' },
    ..._.flatten(
      _.map([19, 20, 21, 22, 23, 24], (year) => [
        ..._.map(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'], (monthId, index) => ({
          beginDay: year + '-' + monthId + '-01',
          endDay: year + '-' + nextMonthIds[index] + '-01',
          title: monthId + '-20' + year,
        })),
        {
          beginDay: year + '-12-01',
          endDay: year + 1 + '-01-01',
          title: '12-20' + year,
        },
      ]),
    ),
  ]);
  enrichSlots(monthSlots);

  const startDays = ["01-01", "04-01", "07-01", "10-01"]
  const endDays = ["03-31", "06-30", "09-30", "12-31"]
  const quarterSlots = slotFilter([
    ..._.flatten(
      _.map(["19", "20", "21", "22"], (year) => [
        ..._.map(['Q1', 'Q2', 'Q3'], (quarterId, index) => ({
          beginDay: year + '-' + startDays[index],
          endDay: year + '-' + endDays[index],
          title: quarterId + '-20' + year,
        })),
        {
          beginDay: year + '-' + startDays[3],
          endDay: year + '-' + endDays[3],
          title: 'Q4' + '-20' + year,
        },
      ]),
    ),
  ]);
  console.log({ quarterSlots })
  enrichSlots(quarterSlots);

  const slots = {
    byWeek: weekSlots.slice(weekSlots.length - 15),
    byMonth: monthSlots.slice(monthSlots.length - 15),
    byQuarter: quarterSlots
  };
  return slots;
};

class OnboardingLevel extends Component {
  state = {
    source: 'all',
  };
  renderNbSendsByProfile({ profileById }) {
    const slots = createSlots({ states: [] });
    const attributes = [
      { id: 'count', title: '#onboarded' },
      { id: 'meanNbSends', title: 'Avg #sends' },
      { id: 'sends0', title: '0', percentRef: 'count' },
      { id: 'sends1', title: '1', percentRef: 'count' },
      { id: 'sends2-4', title: '2-4', percentRef: 'count' },
      { id: 'sends5-9', title: '5-9', percentRef: 'count' },
      { id: 'sends>=10', title: '>=10', percentRef: 'count' },
    ];

    _.each(slots, (slotsByX, key) => {
      _.each(slotsByX, (slot) => {
        _.each(attributes, ({ id }) => {
          slot[id] = 0;
        });
      });
    });

    _.each(profileById, (profile) => {
      if (!profile.onboardingTimestamp) {
        return;
      }
      const day = new Date(profile.onboardingTimestamp).toISOString().slice(2, 10);
      _.each(slots, (slotsByX, key) => {
        _.each(slotsByX, (slot) => {
          if (slot.beginDay <= day && day < slot.endDay) {
            slot['count']++;
            slot['meanNbSends'] += profile.nbSends;
            if (profile.nbSends >= 10) {
              slot['sends>=10']++;
            } else if (profile.nbSends >= 5) {
              slot['sends5-9']++;
            } else if (profile.nbSends >= 2) {
              slot['sends2-4']++;
            } else if (profile.nbSends >= 1) {
              slot['sends1']++;
            } else {
              slot['sends0']++;
            }
          }
        });
      });
    });

    _.each(slots, (slotsByX, key) => {
      _.each(slotsByX, (slot) => {
        slot['meanNbSends'] = Math.round((10 * (slot['meanNbSends'] || 0)) / (slot['count'] || 1)) / 10;
      });
    });

    return (
      <div>
        <SlotFunnelsTable slots={slots} attributes={attributes} />
      </div>
    );
  }
  renderNbAnswersByProfile({ profileById }) {
    const slots = createSlots({ states: [] });

    const attributes = [
      { id: 'count', title: '#onboarded' },
      { id: 'meanNbAnswers', title: 'Avg #answers' },
      { id: 'answers0', title: '0', percentRef: 'count' },
      { id: 'answers1', title: '1', percentRef: 'count' },
      { id: 'answers2-4', title: '2-4', percentRef: 'count' },
      { id: 'answers5-9', title: '5-9', percentRef: 'count' },
      { id: 'answers>=10', title: '>=10', percentRef: 'count' },
    ];

    _.each(slots, (slotsByX, key) => {
      _.each(slotsByX, (slot) => {
        _.each(attributes, ({ id }) => {
          slot[id] = 0;
        });
      });
    });

    _.each(profileById, (profile) => {
      if (!profile.onboardingTimestamp) {
        return;
      }
      const day = new Date(profile.onboardingTimestamp).toISOString().slice(2, 10);
      _.each(slots, (slotsByX, key) => {
        _.each(slotsByX, (slot) => {
          if (slot.beginDay <= day && day < slot.endDay) {
            slot['count']++;
            slot['meanNbAnswers'] += profile.nbPositiveAnswers;
            if (profile.nbPositiveAnswers >= 10) {
              slot['answers>=10']++;
            } else if (profile.nbPositiveAnswers >= 5) {
              slot['answers5-9']++;
            } else if (profile.nbPositiveAnswers >= 2) {
              slot['answers2-4']++;
            } else if (profile.nbPositiveAnswers >= 1) {
              slot['answers1']++;
            } else {
              slot['answers0']++;
            }
          }
        });
      });
    });

    _.each(slots, (slotsByX, key) => {
      _.each(slotsByX, (slot) => {
        slot['meanNbAnswers'] = Math.round((10 * (slot['meanNbAnswers'] || 0)) / (slot['count'] || 1)) / 10;
      });
    });

    return (
      <div>
        <SlotFunnelsTable slots={slots} attributes={attributes} />
      </div>
    );
  }
  renderSlotFunnels({ subtitle, profileById, getState, states }) {
    const slots = createSlots({ states });

    _.each(profileById, (profile) => {
      if (!profile.onboardingTimestamp) {
        return;
      }
      const day = new Date(profile.onboardingTimestamp).toISOString().slice(2, 10);
      _.each(slots, (slotsByX, key) => {
        _.each(slotsByX, (slot) => {
          if (slot.beginDay <= day && day < slot.endDay) {
            const state = getState(profile);
            slot[state] = (slot[state] || 0) + 1;
            slot['total'] = (slot['total'] || 0) + 1;
          }
        });
      });
    });

    return (
      <div>
        <SlotFunnelsChart subtitle={subtitle} slots={slots} states={states} />
      </div>
    );
  }

  handleSetSourceMode = (source) => {
    this.setState({
      sourceMode: source,
    });
  };

  filterProfileById = ({ profileById, sourceMode }) => {
    const result = {};
    _.each(profileById, (profile, id) => {
      if (sourceMode === 'all' || sourceMode === profile.source) {
        result[id] = profile;
      }
    });
    return result;
  };

  render() {
    const { profileById } = this.props;
    const sourceMode = this.state.sourceMode || 'all';
    const sourceOptions = [
      'all',
      'codingame',
      'thefamily',
      'lion',
      'opportunities',
      'hunt',
      'manual',
      'digirocks',
      'alvis',
      'app',
    ];
    const style = (isSelected) => {
      return {
        cursor: 'pointer',
        color: isSelected ? 'blue' : 'lightgray',
      };
    };
    const filteredProfileById = this.filterProfileById({ profileById, sourceMode });

    return (
      <Grid>
        <Grid.Row>
          {_.map(sourceOptions, (source, idx) => (
            <div>
              <span style={style(sourceMode === source)} onClick={() => this.handleSetSourceMode(source)}>
                {source}
              </span>
              {idx !== sourceOptions.length - 1 && <span> | </span>}
            </div>
          ))}
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <center>
              <h2>#Sends by profile</h2>
            </center>
            <br />
            {this.renderNbSendsByProfile({ profileById: filteredProfileById })}
          </Grid.Column>
          <Grid.Column>
            <center>
              <h2>#Answers by profile</h2>
            </center>
            <br />
            {this.renderNbAnswersByProfile({ profileById: filteredProfileById })}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} style={{ paddingTop: 50 }}>
          <Grid.Column>
            <center>
              <h2>Sends</h2>
            </center>
            {this.renderSlotFunnels({
              subtitle: '[0-send, >=1-send, >=3-sends]',
              profileById: filteredProfileById,
              states: ['0-send', '>=1-send', '>=3-sends'],
              getState: (profile) => {
                if (profile.nbSends >= 3) {
                  return '>=3-sends';
                }
                if (profile.nbSends >= 1) {
                  return '>=1-send';
                }
                return '0-send';
              },
            })}
          </Grid.Column>
          <Grid.Column>
            <center>
              <h2>Send/Positive Answer</h2>
            </center>
            {this.renderSlotFunnels({
              subtitle: '[0-send, >=1-send, >=1-pa]',
              profileById: filteredProfileById,
              states: ['0-send', '>=1-send', '>=1-pa'],
              getState: (profile) => {
                if (profile.nbPositiveAnswers >= 1) {
                  return '>=1-pa';
                }
                if (profile.nbSends >= 1) {
                  return '>=1-send';
                }
                return '0-send';
              },
            })}
          </Grid.Column>
          <Grid.Column>
            <center>
              <h2>Positive Answers</h2>
            </center>
            {this.renderSlotFunnels({
              subtitle: '[0-pa, >=1-pa, >=3-pa]',
              profileById: filteredProfileById,
              states: ['0-pa', '>=1-pa', '>=3-pa'],
              getState: (profile) => {
                if (profile.nbPositiveAnswers >= 3) {
                  return '>=3-pa';
                }
                if (profile.nbPositiveAnswers >= 1) {
                  return '>=1-pa';
                }
                return '0-pa';
              },
            })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

class WatchStats extends Component {
  state = {};
  loadProfilesStats = async (userId) => {
    try {
      const todayTimestamp = new Date().getTime();
      let minCreationTimestamp;
      let maxCreationTimestamp;
      let allTimestampActions = [];
      let allProfileOnboardingFromId = {};
      for (let i = 0; i < 12; i++) {
        this.setState({
          userId,
          loadingPercent: 100 * i / 12
        })
        minCreationTimestamp = todayTimestamp - (i + 1) * 60 * 24 * 3600 * 1000;
        maxCreationTimestamp = todayTimestamp - i * 60 * 24 * 3600 * 1000;
        console.log({
          minCreationTimestamp,
          maxCreationTimestamp,
        });
        const { data } = await axios.post(baseUrl + '/watchStats/profilesStats', {
          minCreationTimestamp,
          maxCreationTimestamp,
          userId: userId == "all users" ? null : userId,
        });
        if (data.error) {
          throw Error(data.error);
        }
        const { timestampActions, profileOnboardingFromId } = data;
        console.log("timestampActions", timestampActions)
        console.log("profileOnboardingFromId", profileOnboardingFromId)

        allTimestampActions = [...timestampActions, ...allTimestampActions];
        allProfileOnboardingFromId = { ...profileOnboardingFromId, ...allProfileOnboardingFromId };

      }
      this.setState({
        timestampActions: allTimestampActions,
        profileOnboardingFromId: allProfileOnboardingFromId,
        loaded: true
      });
    } catch (e) {
      alert(e);
    }
  };
  loadMatchStats = async () => {
    try {
      //const { data } = await axios.post(baseUrl + '/watchStats/matchStats', {});
      const data = {};
      if (data.error) {
        throw Error(data.error);
      }
      console.log('loadMatchStats', data);
      this.setState({
        nbMatchesByClient: data.nbMatchesByClient,
        nbMatchesByCategory: data.nbMatchesByCategory,
        nbMatchesByOffer: data.nbMatchesByOffer,
      });
    } catch (e) {
      alert(e);
    }
  };
  loadKpiObjectives = async () => {
    try {
      const { data } = await axios.get(baseUrl + '/watchStats/kpiObjectives', {});
      if (data.error) {
        throw Error(data.error);
      }
      console.log('data', data);
      this.setState({
        kpiObjectives: data.kpiObjectives,
      });
    } catch (e) {
      alert(e);
    }
  };
  handleLoadUsers = async () => {
    const usersOptions = _.pluck((await axios.get(`${baseUrl}/hiresweetEmployees`)).data, 'userId');
    this.setState({
      usersOptions: [...['all users'], ...usersOptions, ...['nathan', 'leo']],
    });
  };
  componentWillMount() {
    this.handleLoadUsers();
    this.loadKpiObjectives();
  }

  getActionCountPerTimeslots = ({ timestampActions, timeslots }) => {
    const actionTypes = _.uniq(_.pluck(timestampActions, 'action'));
    const actionCountsPerTimeslots = _.map(timeslots, ({ minDaysAgo, maxDaysAgo }) => ({
      minDaysAgo,
      maxDaysAgo,
      actionCounts: _.object(actionTypes, _.map(actionTypes, (t) => 0)),
    }));
    _.each(actionCountsPerTimeslots, ({ minDaysAgo, maxDaysAgo, actionCounts }) => {
      const minTimestamp =
        minDaysAgo &&
        moment()
          .subtract(minDaysAgo, 'd')
          .valueOf();
      const maxTimestamp =
        maxDaysAgo &&
        moment()
          .subtract(maxDaysAgo, 'd')
          .valueOf();
      _.each(timestampActions, ({ action, timestamp }) => {
        if ((!minTimestamp || minTimestamp < timestamp) && (!maxTimestamp || timestamp < maxTimestamp)) {
          actionCounts[action]++;
        }
      });
    });
    console.log('actionCountsPerTimeslots after:', actionCountsPerTimeslots);
    return actionCountsPerTimeslots;
  };

  renderOnboardingLevel() {
    const { profileOnboardingFromId } = this.state;
    if (!profileOnboardingFromId) {
      return;
    }
    return (
      <Segment>
        <center>
          <h1 style={{ color: '#0087D5' }}>Timeslot Funnels</h1>
        </center>
        <br />
        <br />
        <br />
        <OnboardingLevel profileById={profileOnboardingFromId} />
        <br />
      </Segment>
    );
  }

  renderMatchesByCategory() {
    const { nbMatchesByCategory } = this.state;
    if (!nbMatchesByCategory) {
      return;
    }

    return (
      <div>
        <h2>Category Stats</h2>
        <StatsTable keyName='category' nbMatchesByItem={nbMatchesByCategory} />
      </div>
    );
  }

  renderMatchesByOffer() {
    const { nbMatchesByOffer } = this.state;
    if (!nbMatchesByOffer) {
      return;
    }

    return (
      <div>
        <h2>Offer Stats</h2>
        <StatsTable keyName='offerId' nbMatchesByItem={nbMatchesByOffer} />
      </div>
    );
  }

  renderMatchesByClient() {
    const { nbMatchesByClient } = this.state;
    if (!nbMatchesByClient) {
      return;
    }

    return (
      <div>
        <h2>Client Stats</h2>
        <StatsTable keyName='clientId' nbMatchesByItem={nbMatchesByClient} />
      </div>
    );
  }

  renderMatchingOpportunities() {
    return (
      <Segment>
        <center>
          <h1 style={{ color: '#0087D5' }}>Matching Opportunity</h1>
        </center>
        <br />
        {this.renderMatchesByCategory()}
        <br />
        <br />
        <br />
        {this.renderMatchesByOffer()}
        <br />
        <br />
        <br />
        {this.renderMatchesByClient()}
        <br />
      </Segment>
    );
  }

  renderKPIs() {
    const { timestampActions, kpiObjectives } = this.state;
    if (!timestampActions) {
      return;
    }
    const actions = [
      { id: 'contacted', title: '#contacted' },
      { id: 'onboarded', title: '#onboarded' },
      ..._.map(GRADES, ({ grade }) => ({ id: `onboarded${grade}`, title: `#onboarded${grade}` })),
      { id: 'select', title: '#selects' },
      { id: 'disqualify', title: '#disqualify' },
      { id: 'upload', title: '#uploads' },
      ..._.map(GRADES, ({ grade }) => ({ id: `upload${grade}`, title: `#uploads${grade}` })),
      { id: 'send', title: '#sends', percentRef: 'upload' },
      ..._.map(GRADES, ({ grade }) => ({ id: `send${grade}`, title: `#send${grade}`, percentRef: `upload${grade}` })),
      { id: 'skip', title: '#skips', percentRef: 'upload' },
      ..._.map(GRADES, ({ grade }) => ({ id: `skip${grade}`, title: `#skip${grade}`, percentRef: `upload${grade}` })),
      { id: 'sendAtUpload', title: '#sends@upload', percentRef: 'upload' },
      { id: 'skipAtUpload', title: '#skips@upload', percentRef: 'upload' },
      { id: 'answer', title: '#answers', percentRef: 'send' },
      ..._.map(GRADES, ({ grade }) => ({ id: `answer${grade}`, title: `#answers${grade}`, percentRef: `send${grade}` })),
      { id: 'positiveAnswer', title: '#positiveAnswers' },
      ..._.map(GRADES, ({ grade }) => ({ id: `positiveAnswer${grade}`, title: `#positiveAnswers${grade}` })),
      { id: 'answerAtSend', title: '#answers@send', percentRef: 'send' },
      { id: 'positiveAnswerAtSend', title: '#positiveAnswers@send' },
      { id: 'recruitment', title: '#recruitments' },
      { id: 'recruitmentAtOnboarding', title: '#recruitments@onboarding' },
    ];
    const slots = createSlots({ states: _.pluck(actions, 'id') });

    _.each(slots, (slotsByX, key) => {
      _.each(slotsByX, (slot) => {
        _.each(timestampActions, ({ action, timestamp }) => {
          const day = new Date(timestamp).toISOString().slice(2, 10);
          if (slot.beginDay <= day && day < slot.endDay) {
            slot[action]++;
          }
        });
      });
    });

    const panes = [
      { menuItem: 'Graphs', render: () => <KPIGraphs slots={slots} kpiObjectives={kpiObjectives} /> },
      {
        menuItem: 'Raw data',
        render: () => (
          <SlotFunnelsTable slots={slots} attributes={actions} disablePercentMode={true} defaultSplitMode='byMonth' />
        ),
      },
    ];

    return (
      <Segment>
        <center>
          <h1 style={{ color: '#0087D5' }}>KPIs</h1>
        </center>
        <Tab menu={{ secondary: true }} panes={panes} />
      </Segment>
    );
  }

  render() {
    const { loadingPercent, loaded, userId } = this.state;
    if (!userId) {
      return (
        <Grid>
          <Grid.Row>
            <b> Stats for candidates of : </b>

          </Grid.Row>
          <Grid.Row>

            <Button.Group size='large' vertical>
              {_.map(this.state.usersOptions, (user) => (
                <Button primary={user == 'all users'} onClick={() => this.loadProfilesStats(user)}>{user}</Button>
              ))}
            </Button.Group>
          </Grid.Row>

        </Grid>
      )
    }
    return !loaded ? (
      <Progress percent={loadingPercent} />
    ) : (
      <div>
        <b> User : {userId}</b>
        {this.renderKPIs()}
        <br />
        <br />
        <br />
        {this.renderOnboardingLevel()}
        <br />
        <br />
        <br />
        {/*this.renderMatchingOpportunities()*/}
      </div>
    )

  };

}

export default WatchStats;
