const sources = [
  {
    id: 'linkedin',
    icon: 'linkedin',
    label: 'Linkedin',
    url: 'https://linkedin.com/in/:id',
    website: 'https://linkedin.com/',
    searchSite: 'linkedin',
  },
  {
    id: 'github',
    icon: 'github',
    label: 'Github',
    url: 'https://github.com/:id',
    website: 'https://github.com/',
    searchSite: 'github',
  },
  {
    id: 'stackOverflow',
    icon: 'stack overflow',
    label: 'StackOverflow',
    url: 'https://stackoverflow.com/users/:id',
    website: 'https://stackoverflow.com/',
    searchSite: 'stackoverflow',
  },
  {
    id: 'twitter',
    icon: 'twitter',
    label: 'Twitter',
    url: 'https://www.twitter.com/:id',
    website: 'https://www.twitter.com/',
    // searchSite: 'twitter.com'
  },
  {
    id: 'viadeo',
    icon: 'viadeo',
    label: 'Viadeo',
    url: 'http://fr.viadeo.com/fr/profile/:id',
    website: 'http://fr.viadeo.com/',
  },
  {
    id: 'coderWall',
    icon: 'code',
    label: 'CoderWall',
    url: 'https://coderwall.com/:id',
    website: 'https://coderwall.com/',
  },
  {
    id: 'codePen',
    icon: 'codepen',
    label: 'CodePen',
    url: 'https://codepen.io/:id',
    website: 'https://codepen.io/',
  },
  {
    id: 'bitBucket',
    icon: 'bitbucket',
    label: 'BitBucket',
    url: 'https://bitbucket.org/:id',
    website: 'https://bitbucket.org/',
  },
  {
    id: 'reddit',
    icon: 'reddit',
    label: 'Reddit',
    url: 'https://www.reddit.com/user/:id',
    website: 'https://www.reddit.com/',
  },
  {
    id: 'angelList',
    icon: 'angellist',
    label: 'AngelList',
    url: 'https://angel.co/:id',
    website: 'https://angel.co/',
  },
  {
    id: 'meetup',
    icon: 'meetup',
    label: 'Meetup',
    url: 'https://www.meetup.com/fr-FR/members/:id',
    website: 'https://www.meetup.com/',
  },
  {
    id: 'googlePlus',
    icon: 'google plus',
    label: 'Google+',
    url: 'https://plus.google.com/+:id',
    website: 'https://plus.google.com/',
  },
  {
    id: 'dribbble',
    icon: 'paint brush',
    label: 'Dribbble',
    url: 'https://dribbble.com/:id',
    website: 'https://dribbble.com/',
  },
  {
    id: 'behance',
    icon: 'behance',
    label: 'Behance',
    url: 'https://www.behance.net/:id',
    website: 'https://www.behance.net/',
  },
  {
    id: 'pinterest',
    icon: 'pinterest',
    label: 'Pinterest',
    url: 'https://www.pinterest.fr/:id',
    website: 'https://www.pinterest.fr/',
  },
  {
    id: 'drupal',
    icon: 'drupal',
    label: 'Drupal',
    url: 'https://www.drupal.org/u/:id',
    website: 'https://www.drupal.org/',
  },
  {
    id: 'joomla',
    icon: 'joomla',
    label: 'Joomla',
    url: 'https://volunteers.joomla.org/joomlers/:id',
    website: 'https://volunteers.joomla.org/',
  },
  {
    id: 'aboutMe',
    icon: 'user',
    label: 'About Me',
    url: 'https://about.me/:id',
    website: 'https://about.me/',
  },
  {
    id: 'medium',
    icon: 'medium',
    label: 'Medium',
    url: 'https://medium.com/@:id',
    website: 'https://medium.com/',
  },
  {
    id: 'quora',
    icon: 'quora',
    label: 'Quora',
    url: 'https://www.quora.com/profile/:id',
    website: 'https://www.quora.com/',
  },
  {
    id: 'speakerDeck',
    icon: 'object group',
    label: 'SpeakerDeck',
    url: 'https://speakerdeck.com/:id',
    website: 'https://speakerdeck.com/',
  },
  {
    id: 'githubIo',
    icon: 'github square',
    label: 'Github.io',
    url: 'https://:id',
    // url: 'https://:id.github.io',
  },
  {
    id: 'website',
    icon: 'external',
    label: 'Website',
    url: ':id',
  },
  {
    id: 'name',
    icon: 'ellipsis horizontal',
    label: 'Name',
    url: '',
    searchSite: 'name',
  },
];

export default sources;
