function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import React from 'react';
import { Grid } from 'semantic-ui-react';
import SelectInt from './SelectInt';
import enhance from './enhance';
var Range = enhance(function (_ref) {
  var value = _ref.value,
      min = _ref.min,
      max = _ref.max,
      component = _ref.component,
      innerProps = _objectWithoutProperties(_ref, ['value', 'min', 'max', 'component']);

  var currentMin = value ? value.get('min') : min;
  var currentMax = value ? value.get('max') : max;
  var InnerComponent = component ? component : SelectInt;
  return React.createElement(Grid, {
    columns: 4
  }, React.createElement(Grid.Column, {
    width: 2
  }, 'Min'), React.createElement(Grid.Column, {
    width: 6
  }, React.createElement(InnerComponent, Object.assign({}, innerProps, {
    min: min,
    max: currentMax,
    field: 'min',
    defaultValue: min
  }))), React.createElement(Grid.Column, {
    width: 2
  }, 'Max'), React.createElement(Grid.Column, {
    width: 6
  }, React.createElement(InnerComponent, Object.assign({}, innerProps, {
    min: currentMin,
    max: max,
    field: 'max',
    defaultValue: max
  }))));
});
export default Range;