import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';
import {
  Button,
  Grid,
  Table,
  Modal,
  Header,
  TextArea,
  Form,
  Icon,
  Popup,
  Segment,
  Label,
  Input,
  Select,
} from 'semantic-ui-react';
import {
  FilterDetailsTree
} from '../FilterDetails';
import { SearchDetailsModalAndButton } from './SearchDetailsModalAndButton';
import baseUrl from '../../baseUrl.js';

const rounded = (x) => _.isNumber(x) ? Math.round(x*100)/100 : x;

class MemoModal extends Component {
  state = { open: false, comment: null };
  onChangeComment = (comment) => {
    this.setState({ comment });
  };

  onSaveComment = async () => {
    await this.props.onSaveItemComment(this.state.comment);
    this.setState({ open: false, comment: null });
  };

  render() {
    // const { onChangeComment, comment } = this.props;
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <Button
            circular
            color="grey"
            size="big"
            // icon='bell'
            style={{ padding: '.5em .5em .5em' }}
            onClick={() => this.setState({ open: true })}
          >
            <div
              style={{
                width: '25px',
                height: '25px',
                fontSize: '9px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              Memo
            </div>
          </Button>
        }
      >
        <Header icon="pencil" content="Comment" />
        <Modal.Content>
          <Segment color="red" tertiary>
            <Icon name="warning sign" />
             Les commentaires ne doivent en aucun cas porter atteinte à
             {"l'intégrité morale ou à la dignité du candidat. Les propos"}
             insultants ou discriminatoires sont à proscrire.
          </Segment>
          <Form>
            <TextArea
              onChange={(e) => this.onChangeComment(e.target.value)}
              value={this.state.comment || ''}
              placeholder="Comment"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.onSaveComment()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class DuplicateModal extends Component {
  state = { open: false, duplicate: null };
  onChangeDuplicate = (duplicate) => {
    this.setState({ duplicate });
  };

  onSaveDuplicate = async () => {
    await this.props.onSaveItemDuplicate(this.state.duplicate);
    this.setState({ open: false, duplicate: null });
  };

  onNotifyDuplicate = async () => {
    await this.props.onNotifyItemDuplicate();
    this.setState({ open: false, duplicate: null });
  };

  render() {
    // const { onChangeDuplicate, duplicate } = this.props;
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <Button
            circular
            color="grey"
            size="big"
            // icon='bell'
            style={{ padding: '.35em .5em .35em .5em' }}
            onClick={() => this.setState({ open: true })}
          >
            <div
              style={{
                marginLeft: -5,
                width: '25px',
                height: '25px',
                fontSize: '8px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              Doublon
            </div>
          </Button>
        }
      >
        <Header icon="pencil" content="Duplicate" />
        <Modal.Content>
          <Form>
            <Input
              onChange={(e) => this.onChangeDuplicate(e.target.value)}
              value={this.state.duplicate || ''}
              placeholder="Duplicate"
              label="https://linkedin.com/in/"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={() => this.onNotifyDuplicate()}>
            Je sais que c'est un doublon mais je ne connais pas l'id de son
            doublon
          </Button>
          <Button color="green" onClick={() => this.onSaveDuplicate()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class OutlierModal extends Component {
  state = { open: false };
  onSubmitOutlier = async () => {
    const { comment, profile, offer, input } = this.props;
    if (!comment) {
      alert('comment needed');
      return;
    }
    const response = (await axios.post(`${baseUrl}/sweetwork/reportOutlier`, {
      pipeDescriptor: input ? input.pipeDescriptor : null,
      idFields: profile ? profile.idFields : null,
      offer,
      reason: comment,
      searchParam: input ? input.searchParam : null,
    })).data;
    if (response.error) {
      alert(response.error);
    } else {
      alert('reported');
      this.setState({ open: false });
    }
  };
  render() {
    const { onChangeComment, comment } = this.props;
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <Button
            circular
            color="grey"
            size="big"
            style={{ padding: '.5em .5em .5em' }}
            onClick={() => this.setState({ open: true })}
          >
            <div
              style={{
                width: '25px',
                height: '25px',
                fontSize: '9px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              Outlier
            </div>
          </Button>
        }
      >
        <Header icon="pencil" content="Outlier" />
        <Modal.Content>
          <Form>
            <TextArea
              onChange={(e) => onChangeComment(e.target.value)}
              value={comment || ''}
              placeholder="Comment"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.onSubmitOutlier}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export class FeedbackModal extends Component {
  state = {
    comment: ''
  }
  handleChangeComment = (comment) => {
    this.setState({ comment });
  }
  handleSubmit = () => {
    if (!this.state.comment) {
      return alert('comment needed');
    }
    this.props.onSubmit({ annotationType: this.props.annotationType, comment: this.state.comment });
  }
  renderDetails = () => {
    const details = ((this.props.profile || {}).innerData || {}).scorerDetails;
    if (!details) {
      return <h4>No Details</h4>;
    }

    const letterFromScore = (score) => {
      if (score > 0.8) {
        return 'A';
      } else if (score > 0.6) {
        return 'B';
      } else if (score > 0.4) {
        return 'C';
      } else if (score > 0.2) {
        return 'D';
      } else {
        return 'E';
      }
    }

    const colorFromScore = (score) => {
      return {
        'A': 'lime',
        'B': 'green',
        'C': 'orange',
        'D': 'red',
        'E': 'brown'
      }[letterFromScore(score)] || 'black';
    }

    const score = details.model_omega_score;


    const colorFromCoef = (coef) => {
      if (coef === 1) {
        return 'black';
      }
      if (coef >= 1.5) {
        return 'lime';
      } else if (coef >= 1.3) {
        return 'green';
      } else if (coef >= 1) {
        return 'olive'
      } else if (coef >= 0.6) {
        return 'orange'
      } else if (coef >= 0.3) {
        return 'purple'
      } else if (coef >= 0.1) {
        return 'red';
      } else {
        return 'brown'
      }
    }

    const extraCriteria = details.extra_criteria;

    const totalCoef = _.reduce(_.pluck(extraCriteria, 'coef'), (memo, c) => (
      memo*c
    ), 1);

    const newScore = score * Math.pow(totalCoef,1/2);

    const bricks = details.model_omega_bricks;

    const nameFromBrickId = {
      'experience_year': 'XP Year',
      'job_position': 'Job Position', 
      'skills': 'Skills', 
      'responsability': 'Responsability', 
      'school_prestige': 'School'
    };

    const features = (details || {}).feature_values || {};

    const primitives = [
      {
        name: 'Major JobPos',
        data: (features.jobPosition || {}).majorJobPosition
      },
      ..._.map((features.jobPosition || {}).minorJobPositions, (feature, index) => ({
        name: 'Minor JobPos ' + (index + 1),  
        data: feature
      })),
      ..._.flatten(_.map(features.skills, (skillGroup, key) => (
        _.map(skillGroup, (skill, index) => ({
          name: key + ' skill ' + (index + 1),
          data: skill
        }))
      ))),      
      {
        name: 'XP Year',
        data: (features.experienceYear || {})[0],
      },
      {
        name: 'Tech XP Year',
        data: (features.experienceYear || {})[1],
      },
      {
        name: 'JobPos XP Year',
        data: (features.experienceYear || {})[2],
      },
    ];


    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={1} />
          <Grid.Column width={6}>
          <h2>Primitives</h2>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>name</Table.HeaderCell>
                  <Table.HeaderCell>id</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>value</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>score</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {_.map(primitives, ({ name, data }, index) => (
                data && (
                  <Table.Row key={index}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{data.id}</Table.Cell>                  
                    <Table.Cell textAlign='center' >{data.value !== undefined ? rounded(data.value) : rounded(data.score)}</Table.Cell>                  
                    <Table.Cell textAlign='center'>{rounded(data.score)}</Table.Cell>                  
                    <Table.Cell textAlign='center'>
                      <span style={{ color: colorFromScore(data.score)}}>{letterFromScore(data.score)}</span>
                    </Table.Cell>                  
                  </Table.Row>
                )
              ))}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={7}>
            <h1>Base Score<span> </span>
            {score && (
              <span style={{ weight: 'bold', color: colorFromScore(score)}}> {Math.round(score*10000)/100}%  ({letterFromScore(score)})</span>
            )}
            </h1>
            <br/>
            <Table basic='very' style={{ width: '40%' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign='center'>Brick</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Cost</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Eq. Grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {_.map(['experienceYear','jobPosition', 'skills', 'responsibility', 'schoolPrestige'], (key) => (
                <Table.Row key={key}>
                  <Table.Cell textAlign='center'>{nameFromBrickId[key] || key}</Table.Cell>
                  <Table.Cell textAlign='center'>{rounded((bricks[key] || {}).cost) || '.'}</Table.Cell>
                  <Table.Cell textAlign='center'>{rounded((bricks[key] || {}).scoreIfOtherFeaturesPerfect)} </Table.Cell>
                  <Table.Cell textAlign='center'>
                  <span style={{ weight: 'bold', color: colorFromScore((bricks[key] || {}).scoreIfOtherFeaturesPerfect)}}>
                      {letterFromScore((bricks[key] || {}).scoreIfOtherFeaturesPerfect) || '.'}
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
              </Table.Body>
            </Table>
            <h1>Extra Criteria<span> </span>
              <span style={{ color: colorFromCoef(totalCoef)}}>{totalCoef >= 1 ? 'x' : ''}{Math.round((Math.pow(totalCoef,1/2))*1000)/1000}</span>
              <b> -> </b>
              {newScore && (
                <span style={{ color: colorFromScore(newScore)}}> {Math.round(newScore*10000)/100}%  ({letterFromScore(newScore)})</span>
              )}
            </h1>
            <br/>
            <Table basic='very' style={{ width: '80%' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Coef</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Details</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {_.map(extraCriteria, ({ coef, importance, criterion }, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <span style={{ fontWeight: 'bold', color: colorFromCoef(coef*coef)}}>{Math.round(coef*100)/100} </span>
                  </Table.Cell>
                  <Table.Cell>
                    <span>{
                      {
                        'required': 'Required', 
                        'important': 'Important', 
                        'bonus': 'Bonus', 
                        'super-bonus': 'Super Bonus', 
                        'super_bonus': 'Super Bonus', 
                        'superBonus': 'Super Bonus'
                      }[importance] || importance
                    }</span>
                  </Table.Cell>
                  <Table.Cell>
                    <FilterDetailsTree tree={criterion}/>
                  </Table.Cell>
                </Table.Row>
              ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderForm() {
    const { comment } = this.state;
    return (
      <Form>
        <br/><br/><br/>
        <TextArea
          onChange={(e) => this.handleChangeComment(e.target.value)}
          value={comment || ''}
          placeholder="Comment"
        />
      </Form>
    );
  }
  render() {
    const { onClose } = this.props;
    
    return (
      <Modal
        open
        onClose={onClose}
        closeIcon
        size='fullscreen'
      >
        <Header icon="sliders" content={"Feedback (" + this.props.annotationType + ")"} />
        <Modal.Content>
          {this.renderDetails()}
          {this.renderForm()}
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleSubmit}>
            Send
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const SimpleButton = ({ color, size, onClick, text }) => (
  <Button
    circular
    color={color || 'grey'}
    size={size || 'big'}
    style={{ padding: '.5em .5em .5em' }}
    onClick={async () => {
      await onClick();
    }}
  >
    <div
      style={{
        width: '25px',
        height: '25px',
        fontSize: '9px',
        lineHeight: '25px',
        verticalAlign: 'middle',
      }}
    >
      {text || ''}
    </div>
  </Button>
);

class EmailButton extends Component {
  componentWillMount() {
    this.setState({ open: false });
  }

  openOptionsButtons = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      emailRetrievalFailsUsernames,
      pendingUpload,
      togglePendingUploadHistory,
      setEmailAsNotFound,
      annotate,
      ...rest
    } = this.props;
    const emailButtons = [
      {
        color: 'yellow',
        onClick: async () => {
          this.setState({ open: false });
          await setEmailAsNotFound();
          annotate('backlog;no_email;fail')();
        },
        text: 'Fail',
      },
      {
        color: 'blue',
        onClick: async () => {
          this.setState({ open: false });
          annotate('backlog;no_email;no_time')();
        },
        text: 'Time',
      },
    ];
    return (
      <div {...rest}>
        <Button
          circular
          color={emailRetrievalFailsUsernames.length > 0 ? 'teal' : 'grey'}
          size="big"
          style={{ padding: '.5em .5em .5em' }}
          onClick={async () => {
            if (!pendingUpload) {
              togglePendingUploadHistory();
            } else {
              this.openOptionsButtons();
            }
          }}
        >
          <div
            style={{
              width: '25px',
              height: '25px',
              fontSize: '9px',
              lineHeight: '25px',
              verticalAlign: 'middle',
            }}
          >
            Email
          </div>
        </Button>
        {this.state.open &&
          _.map(emailButtons, ({ color, size, onClick, text }, index) => (
            <SimpleButton
              key={index}
              color={color}
              size={size}
              onClick={onClick}
              text={text}
            />
          ))}
      </div>
    );
  }
}

const NoEmailButtonWithPopup = ({
  emailRetrievalFailsUsernames,
  pendingUpload,
  togglePendingUploadHistory,
  setEmailAsNotFound,
  annotate,
}) => (
  <div>
    {emailRetrievalFailsUsernames.length > 0 ? (
      <Popup
        inverted
        trigger={
          <EmailButton
            emailRetrievalFailsUsernames={emailRetrievalFailsUsernames}
            pendingUpload={pendingUpload}
            togglePendingUploadHistory={togglePendingUploadHistory}
            setEmailAsNotFound={setEmailAsNotFound}
            annotate={annotate}
          />
        }
      >
        <Popup.Header>
          {`${emailRetrievalFailsUsernames.length} user${
            emailRetrievalFailsUsernames.length === 1 ? '' : 's'
          }`}
        </Popup.Header>
        <Popup.Content>{emailRetrievalFailsUsernames.join(', ')}</Popup.Content>
      </Popup>
    ) : (
      <EmailButton
        emailRetrievalFailsUsernames={emailRetrievalFailsUsernames}
        pendingUpload={pendingUpload}
        togglePendingUploadHistory={togglePendingUploadHistory}
        setEmailAsNotFound={setEmailAsNotFound}
        annotate={annotate}
      />
    )}
  </div>
);

class LinksModal extends Component {
  state = { open: false, extraIdFields: [], edit: { sourceId: '', id: '' } };
  addAnExtraSourceId = () => {
    this.setState({
      extraIdFields: [
        ...this.state.extraIdFields,
        { sourceId: this.state.edit.sourceId, id: this.state.edit.id },
      ],
      edit: { sourceId: '', id: '' },
    });
  };
  onChangeSourceId = (value) => {
    this.setState({ edit: { ...this.state.edit, sourceId: value } });
  };
  onChangeId = (value) => {
    this.setState({ edit: { ...this.state.edit, id: value } });
  };
  render() {
    const {
      idFields,
      mismatchLinks,
      matchLinks,
      markAsMatch,
      markAsMismatch,
    } = this.props;
    return (
      <Modal
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        closeIcon
        trigger={
          <Button
            circular
            color="grey"
            size="big"
            style={{ padding: '.5em .5em .5em' }}
            onClick={() => this.setState({ open: true })}
          >
            <div
              style={{
                width: '25px',
                height: '25px',
                fontSize: '9px',
                lineHeight: '25px',
                verticalAlign: 'middle',
              }}
            >
              Links
            </div>
          </Button>
        }
      >
        <Header icon="sitemap" content="Sources Links" />
        <Modal.Content>
          <Segment>
            <Header as="h3">
              Linkedin: {(idFields || {}).linkedin || 'unknown'}
            </Header>
          </Segment>
          <Grid columns="two">
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Label attached="top">ID FIELDS</Label>
                  <Grid divided>
                    {_.map(
                      _.omit(idFields, 'linkedin', 'id'),
                      (id, sourceId) => (
                        <Grid.Row columns="three" key={sourceId}>
                          <Grid.Column>{sourceId}</Grid.Column>
                          <Grid.Column>
                            <b>{id}</b>
                          </Grid.Column>
                          <Grid.Column>
                            <Popup
                              trigger={
                                <Button
                                  icon="minus"
                                  color="red"
                                  onClick={() => markAsMismatch(sourceId, id)}
                                />
                              }
                              content="Mark as mismatch"
                              inverted
                            />
                            <Popup
                              trigger={
                                <Button
                                  icon="check"
                                  color="green"
                                  onClick={() => markAsMatch(sourceId, id)}
                                />
                              }
                              content="Mark as match"
                              inverted
                            />
                          </Grid.Column>
                        </Grid.Row>
                      ),
                    )}
                    {_.map(
                      this.state.extraIdFields,
                      ({ id, sourceId }, index) => (
                        <Grid.Row columns="three" key={'extra-' + index}>
                          <Grid.Column>{sourceId}</Grid.Column>
                          <Grid.Column>
                            <b>{id}</b>
                          </Grid.Column>
                          <Grid.Column>
                            <Popup
                              trigger={
                                <Button
                                  icon="minus"
                                  color="red"
                                  onClick={() => markAsMismatch(sourceId, id)}
                                />
                              }
                              content="Mark as mismatch"
                              inverted
                            />
                            <Popup
                              trigger={
                                <Button
                                  icon="check"
                                  color="green"
                                  onClick={() => markAsMatch(sourceId, id)}
                                />
                              }
                              content="Mark as match"
                              inverted
                            />
                          </Grid.Column>
                        </Grid.Row>
                      ),
                    )}
                  </Grid>
                </Segment>
                <Grid divided>
                  <Grid.Row columns="three">
                    <Grid.Column>
                      <Select
                        style={{ minWidth: '0px', width: '100%' }}
                        onChange={(e, { value }) =>
                          this.onChangeSourceId(value)
                        }
                        value={this.state.edit.sourceId}
                        placeholder="github"
                        options={[
                          { text: 'github', value: 'github' },
                          { text: 'stackoverflow', value: 'stackoverflow' },
                          { text: 'website', value: 'website' },
                        ]}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Input
                        style={{ width: '100%' }}
                        onChange={(e) => this.onChangeId(e.target.value)}
                        value={this.state.edit.id}
                        placeholder="identifier"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Popup
                        trigger={
                          <Button
                            icon="add"
                            color="blue"
                            onClick={() => this.addAnExtraSourceId()}
                          />
                        }
                        content="Add a link"
                        inverted
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column>
                {(matchLinks || mismatchLinks) && (
                  <Segment>
                    {matchLinks && (
                      <Label as="div" color="green" ribbon>
                        Matches
                      </Label>
                    )}
                    <Grid columns="four">
                      {_.map(matchLinks, ({ id, username, date }, sourceId) => (
                        <Grid.Row key={'matches-' + sourceId}>
                          <Grid.Column>{sourceId}</Grid.Column>
                          <Grid.Column>
                            <b>{id}</b>
                          </Grid.Column>
                          <Grid.Column>{username}</Grid.Column>
                          <Grid.Column>{(date || '').slice(0, 10)}</Grid.Column>
                        </Grid.Row>
                      ))}
                    </Grid>
                    {mismatchLinks && (
                      <Label as="div" color="red" ribbon>
                        Mismatches
                      </Label>
                    )}
                    <Grid columns="four">
                      {_.map(mismatchLinks, (list, sourceId, index) =>
                        _.map(list, ({ id, username, date }, index2) => (
                          <Grid.Row key={index + '-' + index2}>
                            <Grid.Column>{sourceId}</Grid.Column>
                            <Grid.Column>
                              <b>{id}</b>
                            </Grid.Column>
                            <Grid.Column>{username}</Grid.Column>
                            <Grid.Column>
                              {(date || '').slice(0, 10)}
                            </Grid.Column>
                          </Grid.Row>
                        )),
                      )}
                    </Grid>
                  </Segment>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            // color='green'
            onClick={() => this.setState({ open: false })}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const AnnotationBigButton = ({
  disabled,
  pendingUpload,
  annotate,
  togglePendingUploadHistory,
  color,
  annotationTags,
  displayText,
  noArowIcon,
  hoverText,
}) => (
  <Popup
    inverted
    content={hoverText}
    trigger={
      <Button
        disabled={disabled}
        circular
        color={color}
        size="massive"
        style={{ padding: '.78571429em .78571429em .78571429em' }}
        onClick={
          !pendingUpload ? annotate(annotationTags) : togglePendingUploadHistory
        }
      >
        {!pendingUpload || noArowIcon ? (
          <div
            style={{
              width: '30px',
              height: '30px',
              fontSize: '16px',
              lineHeight: '30px',
              verticalAlign: 'middle',
            }}
          >
            {displayText}
          </div>
        ) : (
          <div
            style={{
              width: '30px',
              height: '30px',
              fontSize: '16px',
              lineHeight: '30px',
              verticalAlign: 'middle',
            }}
          >
            <Icon name="arrow left" />
          </div>
        )}
      </Button>
    }
  />
);

export class AnnotationButtons extends Component {
  state = {
  }

  handleOpenFeedbackModal = (annotationType) => {
    this.setState({
      feedbackModal: {
        annotationType
      }
    });
  }

  handleCloseFeedbackModal = (annotationType) => {
    this.setState({
      feedbackModal: null
    });
  }

  handleSubmitFeedbackModal = async ({ annotationType, comment }) => {
    const { profile, offer, input } = this.props;
    const response = (await axios.post(`${baseUrl}/sweetwork/searchFeedback`, {
      pipeDescriptor: input ? input.pipeDescriptor : null,
      idFields: profile ? profile.idFields : null,
      offer,
      reason: comment,
      searchParam: input ? input.searchParam : null,
      scorerDetails: ((profile || {}).innerData || {}).scorerDetails,
      annotationType
    })).data;
    if (response.error) {
      alert(response.error);
    } else {
      alert('reported');
      this.setState({ open: false });
    }

    this.setState({
      feedbackModal: null
    });
    if (annotationType !== 'display') {
      this.props.annotate(annotationType)();
    }
  }

  render() {
    const {
      disabled,
      pendingUpload,
      togglePendingUploadHistory,
      annotate,
      //username,
      edit,
      profile,
      input,
      onChangeComment,
      offer,
      setEmailAsNotFound,
      onSaveItemComment,
      emailRetrievalFailsUsernames,
      mismatchLinks,
      matchLinks,
      markAsMatch,
      markAsMismatch,
      onSaveItemDuplicate,
      onNotifyItemDuplicate,
    } = this.props;

    const source = ((profile.innerData || {}).sourceDescriptor || {}).id;
    const feedback = source === "S8OmegaScorer";

    const wrappedAnnotate = annotate;/*(annotationType) => {
      return () => {
        const source = ((profile.innerData || {}).sourceDescriptor || {}).id;
        
        const canAccessOmegaMode = [
          'florent',
          'rodolphe',
          'paul',
          'ismael',
          'luc',
          'remils',
          'remidbs',
          'valentin',
          'victor'
        ].indexOf(username) >= 0;   

        const feedback = source === "S8OmegaScorer" && canAccessOmegaMode && (
          Math.random() < 0.1
        );

        if (feedback) {
          this.handleOpenFeedbackModal(annotationType);
        } else {
          return annotate(annotationType)();
        }
      }
    }*/

    return (
      <div className="actionsContainer">
        {this.state.feedbackModal && (
          <FeedbackModal 
            onClose={this.handleCloseFeedbackModal}
            onSubmit={this.handleSubmitFeedbackModal}
            annotationType={this.state.feedbackModal.annotationType}
            profile={profile}
          />
        )}
        <Grid style={{ margin: '0px', width: '380px' }}>
          <Grid.Row columns={5} style={{ padding: '0px', margin: '-8px 8%' }}>
            <Grid.Column style={{ padding: '0px' }}>
              <Button
                circular
                color="grey"
                size="big"
                style={{ padding: '.35em .4em .35em .5em' }}
                onClick={annotate('forgotten')}
              >
                <div
                  style={{
                    marginLeft: -2,
                    width: '25px',
                    height: '25px',
                    fontSize: '8px',
                    lineHeight: '25px',
                    verticalAlign: 'middle',
                  }}
                >
                  Forget
                </div>
              </Button>
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <DuplicateModal
                onSaveItemDuplicate={onSaveItemDuplicate}
                onNotifyItemDuplicate={onNotifyItemDuplicate}
              />
            </Grid.Column>
            {feedback ? (
                <Grid.Column style={{ padding: '0px' }}>
                  <Button
                    circular
                    color="grey"
                    size="big"
                    style={{ padding: '.35em .4em .35em .5em' }}
                    onClick={() => this.handleOpenFeedbackModal('display')}
                  >
                    <div
                      style={{
                        marginLeft: -2,
                        width: '25px',
                        height: '25px',
                        fontSize: '8px',
                        lineHeight: '25px',
                        verticalAlign: 'middle',
                      }}
                    >
                      Details
                    </div>
                 </Button>
              </Grid.Column>
            ) : (
              <Grid.Column style={{ padding: '0px' }} />
            )}
            <Grid.Column style={{ padding: '0px' }} />
            <Grid.Column style={{ padding: '0px' }} />
          </Grid.Row>
          <Grid.Row columns={6} style={{ padding: '0px' }}>
            <Grid.Column style={{ padding: '0px' }}>
              <AnnotationBigButton
                disabled={disabled}
                pendingUpload={pendingUpload}
                annotate={wrappedAnnotate}
                togglePendingUploadHistory={togglePendingUploadHistory}
                color="brown"
                displayText={0}
                annotationTags="disqualified;awful"
                hoverText="N'a rien à faire dans cette liste"
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <AnnotationBigButton
                disabled={disabled}
                pendingUpload={pendingUpload}
                annotate={wrappedAnnotate}
                togglePendingUploadHistory={togglePendingUploadHistory}
                color="red"
                displayText={1}
                annotationTags="disqualified;bad"
                hoverText="Ne correspond pas à l'offre"
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <AnnotationBigButton
                disabled={disabled}
                pendingUpload={false}
                annotate={wrappedAnnotate}
                togglePendingUploadHistory={togglePendingUploadHistory}
                color="orange"
                displayText={2}
                annotationTags="backlog;weak"
                hoverText="Éventuellement en dernier recours"
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <AnnotationBigButton
                disabled={disabled}
                pendingUpload={false}
                annotate={wrappedAnnotate}
                togglePendingUploadHistory={togglePendingUploadHistory}
                color="yellow"
                displayText={3}
                annotationTags="backlog;normal"
                hoverText="Peut-être plus tard"
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <AnnotationBigButton
                disabled={disabled}
                pendingUpload={!pendingUpload}
                annotate={wrappedAnnotate}
                togglePendingUploadHistory={togglePendingUploadHistory}
                color="olive"
                displayText={4}
                annotationTags="selected;normal"
                noArowIcon={true}
                hoverText="Correspond à la majeure partie des critères"
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <AnnotationBigButton
                disabled={disabled}
                pendingUpload={!pendingUpload}
                annotate={wrappedAnnotate}
                togglePendingUploadHistory={togglePendingUploadHistory}
                color="green"
                displayText={5}
                annotationTags="selected;strong"
                noArowIcon={true}
                hoverText="Correspond à tous les critères"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={5} style={{ padding: '0px', margin: '-8px 8%' }}>
            <Grid.Column style={{ padding: '0px' }}>
              <OutlierModal
                onChangeComment={onChangeComment}
                comment={edit.comment || ''}
                input={input}
                profile={profile}
                offer={offer}
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <SearchDetailsModalAndButton
                input={input}
                profile={profile}
                trigger={
                  <Button
                    circular
                    color="grey"
                    size="big"
                    style={{ padding: '.5em .5em .5em' }}
                  >
                    <div
                      style={{
                        width: '25px',
                        height: '25px',
                        fontSize: '9px',
                        lineHeight: '25px',
                        verticalAlign: 'middle',
                      }}
                    >
                      Filters
                    </div>
                  </Button>
                }
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <MemoModal onSaveItemComment={onSaveItemComment} />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <NoEmailButtonWithPopup
                emailRetrievalFailsUsernames={emailRetrievalFailsUsernames}
                pendingUpload={pendingUpload}
                togglePendingUploadHistory={togglePendingUploadHistory}
                setEmailAsNotFound={setEmailAsNotFound}
                annotate={annotate}
              />
            </Grid.Column>
            <Grid.Column style={{ padding: '0px' }}>
              <LinksModal
                idFields={(profile || {}).idFields}
                mismatchLinks={mismatchLinks}
                matchLinks={matchLinks}
                markAsMatch={markAsMatch}
                markAsMismatch={markAsMismatch}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}


