import _ from 'underscore';
import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const CustomModal = ({
  headerText,
  submitText = 'Submit',
  cancelText = 'Cancel',
  onSubmit,
  onCancel,
  children,
  size,
  disabled,
  extraActions,
  closable = true,
}) => (
  <Modal
    size={size}
    onClose={closable ? onCancel : () => {}}
    closeOnDimmerClick={false}
    open
    {...closable && { closeIcon: true }}
  >
    <Modal.Header>{headerText}</Modal.Header>
    <Modal.Content scrolling>{children}</Modal.Content>
    <Modal.Actions>
      {_.map(extraActions, ({ onClick, text, color }, index) => (
        <Button key={index} color={color} onClick={onClick}>
          {text}
        </Button>
      ))}
      {cancelText ? (
        <Button negative onClick={onCancel}>
          {cancelText}
        </Button>
      ) : null}
      {onSubmit && (
        <Button positive onClick={onSubmit} disabled={disabled}>
          {submitText}
        </Button>
      )}
    </Modal.Actions>
  </Modal>
);

export default CustomModal;
