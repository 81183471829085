import _, { each } from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Icon, Button, Input, Segment, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import baseUrl from './baseUrl.js';
import moment from 'moment';

const normalizeLinkedin = ({ linkedinLink }) => {
  if (!linkedinLink) {
    return null;
  }
  const decodedLinkedin = decodeURIComponent(linkedinLink);
  const prefix = "linkedin.com/in/";
  const iCut = decodedLinkedin.indexOf(prefix);
  const linkedinWithoutPrefix =
    iCut >= 0 ? decodedLinkedin.substr(iCut + prefix.length) : decodedLinkedin;
  const jCut = linkedinWithoutPrefix.indexOf("/");
  const linkedinWithoutSlash =
    jCut >= 0 ? linkedinWithoutPrefix.substr(0, jCut) : linkedinWithoutPrefix;
  const kCut = linkedinWithoutSlash.indexOf("?");
  const linkedinWithoutQueryParam =
    kCut >= 0 ? linkedinWithoutSlash.substr(0, kCut) : linkedinWithoutSlash;
  return linkedinWithoutQueryParam;
};

const checkScraped = async ({ linkedinId }) => {
  try {
    const profile = (await axios.get(baseUrl + '/sourceData/linkedin/' + linkedinId)).data;
    if (profile) {
      return true
    }
  } catch (e) {
    console.log("CHECK SCRAPED")
    console.log(e);
    return false
  }
}

const performInstantScrap = async ({ linkedinId }) => {
  try {
    const profile = (await axios.get(baseUrl + '/instantscrap/linkedin/' + linkedinId)).data;
    if (profile) {
      return true
    }
  } catch (e) {
    console.log(e);
    return false
  }
}


class InstantScraper extends React.Component {
  state = {};


  componentWillReceiveProps() {
    console.log("componentWillReceiveProps")
    console.log(this.props)
    _.debounce(this.handleCheckIfScraped, 300)();
  }

  handleCheckIfScraped = async () => {
    this.setState({ checking: true });
    const { linkedinLink } = this.props;
    console.log("check if scraped..", linkedinLink)
    const linkedinId = normalizeLinkedin({ linkedinLink });
    const isScraped = await checkScraped({ linkedinId });
    this.setState({ isScraped, checking: false });
  }

  handlePerformInstantscrap = async () => {
    this.setState({ scrapping: true });
    const { linkedinLink } = this.props;
    console.log("performing instantscrap..", linkedinLink)

    const linkedinId = normalizeLinkedin({ linkedinLink });
    const isScraped = await performInstantScrap({ linkedinId });
    alert(isScraped ? `Successfully scrapped ${linkedinLink} !` : `Failed scrapping ${linkedinLink}.`)
    this.setState({ isScraped, scrapping: false });
  }


  render() {
    const { scrapping, checking, isScraped } = this.state;
    return (
      <div>
        {scrapping ? (
          <Icon name='circle notched' loading />
        ) : (
          <Button color='blue' onClick={() => this.handlePerformInstantscrap()}>
            Instant Scrap (python)
          </Button>
        )}
        {checking ? (
          <Icon name='circle notched' loading />
        ) : (
          <Icon name={isScraped ? 'check' : 'close'} />

        )

        }
      </div>
    );
  }
}

class InstantScrap extends React.Component {
  state = {};

  handleChangeLinkedinLink = async (value) => {
    this.setState({ linkedinLink: value })
  }
  render() {
    const { linkedinLink } = this.state;
    return (
      <div>
        <Input style={{"width":"400px"}} value={linkedinLink} onChange={(e, { value }) => this.handleChangeLinkedinLink(value)} />
        <InstantScraper linkedinLink={linkedinLink} />
      </div>
    )
  }

}



export { InstantScrap, InstantScraper };
