import { Route, Switch } from 'react-router-dom';

import Challenge from '../../MachineLearningChallenges/Challenge';
import ChallengesDashboard from '../../MachineLearningChallenges/ChallengesDashboard';
import React from 'react';

class ViewsRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/assets-explorer/views'>
          <ChallengesDashboard />
        </Route>
        <Route exact path='/assets-explorer/views/:challengeId'>
          {({ match }) => (
            <Challenge challengeId={((match || {}).params || {}).challengeId} />
          )}
        </Route>
      </Switch>
    )
  }
}


export default ViewsRouter;
