/* eslint no-eval: 0 */

import _ from 'underscore';
import mingo from 'mingo';
import moment from 'moment';
import React, { PureComponent, Component } from 'react';
import axios from 'axios';
import {
  Form,
  Message,
  Image,
  Icon,
  Card,
  List,
  Button,
  Table,
  Grid,
} from 'semantic-ui-react';
import {
  SweetForm,
  enhance,
  Input,
  List as SweetList,
  Select,
} from 'sweetform';
import { LinkedinProfile } from './Offers/WorkPipeInputComponents/LinkedinProfile';
import baseUrl from './baseUrl.js';

const evaluable = (str) => {
  if (!str) {
    return true;
  }
  try {
    eval('var x = ' + str + ' ; x ');
    return true;
  } catch (e) {
    return false;
  }
};

const defaultExtaFieldKeys = [
  { value: 'firstname', label: 'Firstname' },
  { value: 'lastname', label: 'Lastname' },
  { value: 'website', label: 'Website' },
  { value: 'linkedin', label: 'Linkedin' },
  { value: 'firstCallDate', label: 'First Call Date' },
  { value: 'firstEmailDate', label: 'First Email Date' },
  { value: 'firstFollowupDate', label: 'First Followup Date' },
  { value: 'reminderDate', label: 'Reminder(YY-MM-DD)' },
  { value: 'ShowedOffers', label: 'ShowedOffers' },
  { value: 'ViewedByClients', label: 'ViewedByClients' },
  { value: 'AchievedIntros', label: 'AchievedIntros' },
];

const ExtraField = enhance(() => (
  <Grid>
    <Grid.Column width={4}>
      <Select
        autoFocus
        field="key"
        creatable
        options={defaultExtaFieldKeys}
        placeholder="key"
      />
    </Grid.Column>
    <Grid.Column width={12}>
      <Input field="value" placeholder="value" />
    </Grid.Column>
  </Grid>
));

// props: { initialExtraFields: { firstName: ..., ...}, onSave(extraFields) }
class ExtraFields extends Component {
  constructor(props) {
    super(props);
    const { initialExtraFields } = props;
    const extraFields = _.reduce(
      initialExtraFields,
      (memo, value, key) => [...memo, { key, value }],
      [],
    );
    this.state = {
      editMode: false,
      extraFields,
      initialExtraFields: {
        extraFields: extraFields.length > 0 ? extraFields : [{}],
      },
    };
  }
  handleSave = async () => {
    const { extraFields } = this.state;
    const extraFieldsObject = _.reduce(
      extraFields,
      (memo, { key, value }) => ({
        ...memo,
        ...(key && { [key]: value }),
      }),
      {},
    );
    this.setState({
      editMode: false,
      initialExtraFields: {
        extraFields: extraFields.length > 0 ? extraFields : [{}],
      },
    });
    await this.props.onSave(extraFieldsObject);
  };
  handleRunEditMode = async () => {
    this.setState({ editMode: true });
  };
  handleUpdate = async ({ extraFields }) => {
    this.setState({ extraFields });
  };
  renderEditMode() {
    return (
      <SweetForm
        onChange={this.handleUpdate}
        initialValues={this.state.initialExtraFields}
      >
        <SweetList component={ExtraField} field="extraFields" compact />
      </SweetForm>
    );
  }
  renderStaticMode() {
    const { extraFields } = this.state;
    return extraFields.length > 0 ? (
      <Table celled>
        <Table.Body>
          {_.map(extraFields, ({ key, value }) => (
            <Table.Row key={key}>
              <Table.Cell>{key} </Table.Cell>
              <Table.Cell width={13}>{value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    ) : null;
  }
  render() {
    const { editMode } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={13}>
            {editMode ? this.renderEditMode() : this.renderStaticMode()}
          </Grid.Column>
          <Grid.Column width={3}>
            {editMode ? (
              <Button color="green" onClick={this.handleSave}>
                Save
              </Button>
            ) : (
              <Button color="orange" onClick={this.handleRunEditMode}>
                Edit
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

// { initialComment, onSave }
class HiresweetComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      currentComment: props.initialComment || '',
    };
  }
  handleSave = async () => {
    await this.props.onSave(this.state.currentComment);
    this.setState({
      editMode: false,
    });
  };
  handleRunEditMode = async () => {
    this.setState({ editMode: true });
  };
  renderEditMode() {
    return (
      <Form reply>
        <Form.TextArea
          value={this.state.currentComment}
          onChange={(event, { value }) => {
            this.setState({ currentComment: value });
          }}
        />
      </Form>
    );
  }
  renderStaticMode() {
    const { currentComment } = this.state;
    return currentComment ? (
      <Message
        icon="comment"
        header="HireSweet Comment"
        list={currentComment.split('\n')}
      />
    ) : null;
  }
  render() {
    const { currentComment, editMode } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={13}>
            {editMode ? this.renderEditMode() : this.renderStaticMode()}
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingLeft: 0 }}>
            {editMode ? (
              <Button color="green" onClick={this.handleSave}>
                Save
              </Button>
            ) : (
              <Button color="blue" onClick={this.handleRunEditMode}>
                {currentComment ? 'Edit' : 'Comment'}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

class ProfilesListView extends PureComponent {
  state = {
    searchInput: '',
  };
  
  render() {
    const status = this.state.status;
    const targetTag = this.state.tag;
    const targetGrade = this.state.grade;
    const reminderFilter = this.state.reminderFilter;

    const annotateAlreadySeen = (profiles) => {
      let seenHandle = {};
      return _.map(profiles, (profile) => {
        const handle = profile.data && profile.data.handle;
        if (!handle) {
          return profile;
        }
        if (seenHandle[handle]) {
          return { ...profile, handleSeenBefore: true };
        }
        seenHandle[handle] = true;
        return profile;
      });
    };

    const annotatedProfiles = annotateAlreadySeen(
      _.sortBy(this.props.profiles || [], 'timestamp'),
    ).reverse();

    const filteredProfiles1 = _.filter(
      annotatedProfiles,
      (profile) =>
        (!status || profile.status === status) &&
        (!targetGrade || profile.grade === targetGrade),
    );

    const filteredProfiles2 = targetTag
      ? _.filter(filteredProfiles1, (profile) => {
          const profileTags = _.filter(
            (profile.comment || '').replace(/\n/g, ' ').split(' '),
            (token) => token.length > 0 && token[0] === '#',
          );

          return targetTag !== 'no-tag'
            ? profileTags.indexOf(targetTag) >= 0
            : _.isEmpty(_.compact(profileTags));
        })
      : filteredProfiles1;

    const curDate = moment().format('YY-MM-DD');

    const filteredProfiles3 = reminderFilter
      ? _.filter(filteredProfiles2, (profile) => {
          const reminderDate = profile.extraFields
            ? profile.extraFields.reminderDate
            : null;
          return reminderDate && reminderDate <= curDate;
        })
      : filteredProfiles2;

    const countFromTag = {};
    const tags = _.uniq(
      _.reduce(
        filteredProfiles1,
        (memo, profile) => {
          const newTags = _.filter(
            (profile.comment || '').replace(/\n/g, ' ').split(' '),
            (token) => token.length > 0 && token[0] === '#',
          );
          _.each(newTags, (tag) => {
            countFromTag[tag] = (countFromTag[tag] || 0) + 1;
          });
          if (_.isEmpty(_.compact(newTags))) {
            countFromTag['no-tag'] = (countFromTag['no-tag'] || 0) + 1;
          }
          return [...memo, ...newTags];
        },
        ['all', 'no-tag'],
      ),
    );

    let queryAccept = () => true;
    const searchInput = this.state.searchInput;
    if (searchInput && evaluable(searchInput)) {
      const queryParam = eval('var dark_tmp = ' + searchInput + ' ; dark_tmp');
      const mingoQuery = new mingo.Query(queryParam);
      queryAccept = (profile) =>
        mingoQuery.test({
          ...profile,
          ...profile.data,
          ...profile.extraFields,
        });
    }

    const profiles = _.filter(filteredProfiles3, queryAccept);

    const statuses = _.uniq([
      'all',
      'pending',
      'to-be-contacted',
      'contacted',
      'doing',
      'in-process',
      'backlog',
      ..._.compact(_.map(profiles, (profile) => profile.status)),
    ]);

    const locationFromProfile = (profile) => {
      if (!profile.data) {
        return;
      }
      const { data } = profile;
      return data.city || data.region || data.country || data.locations || '';
    };

    const isSpecialTarget = (profile) => {
      if (!profile.data) {
        return false;
      }
      const { data } = profile;
      if (!data.salary) {
        return false;
      }
      let salary = 0;
      try {
        salary = parseInt(data.salary, 10);
      } catch (e) {
        return 0;
      }
      // const minSalary = 40000;
      const location =
        data.city +
        ' ' +
        data.region +
        ' ' +
        data.country +
        ' ' +
        data.locations;
      return salary >= 40000 && location.indexOf('le-de-France') >= 0;
    };

    const isCurrentProfile = (profile) => {
      const { selectedProfileId } = this.props;
      return selectedProfileId && selectedProfileId === profile.id;
    };

    return (
      <div>
        <div>
          <Button
            color={this.state.showId ? 'green' : 'gray'}
            onClick={() => {
              this.setState({ showId: !this.state.showId });
            }}
          >
            {' '}
            Show Id
          </Button>
          {_.map(statuses, (choice, index) => (
            <Button
              color={
                (choice === 'all' && !status) || choice === status
                  ? 'green'
                  : 'grey'
              }
              key={index}
              onClick={() => {
                this.setState({ status: choice !== 'all' ? choice : null });
              }}
            >
              {choice === 'to-be-contacted' ? 'tbc' : choice}
            </Button>
          ))}
        </div>
        <div style={{ marginTop: '5px' }}>
          <Button
            color={!reminderFilter ? 'green' : 'grey'}
            onClick={() => {
              this.setState({ reminderFilter: null });
            }}
          >
            all
          </Button>
          <Button
            color={reminderFilter ? 'green' : 'grey'}
            onClick={() => {
              this.setState({ reminderFilter: true });
            }}
          >
            hot
          </Button>
        </div>
        <div style={{ marginTop: '5px' }}>
          {_.map(['all', 'A', 'B', 'C', 'D'], (grade, index) => (
            <Button
              color={
                (grade === 'all' && !targetGrade) || grade === targetGrade
                  ? 'green'
                  : 'grey'
              }
              key={index}
              onClick={() => {
                this.setState({ grade: grade !== 'all' ? grade : null });
              }}
            >
              {grade}
            </Button>
          ))}
        </div>

        <div style={{ marginTop: '5px' }}>
          {_.map(tags, (choice, index) => (
            <Button
              color={
                (choice === 'all' && !targetTag) || choice === targetTag
                  ? 'green'
                  : 'silver'
              }
              key={index}
              onClick={() => {
                this.setState({ tag: choice !== 'all' ? choice : null });
              }}
            >
              {(choice !== 'no-tag' ? choice : '∅') +
                (['all'].indexOf(choice) < 0
                  ? ' (' + (countFromTag[choice] || 0) + ')'
                  : '')}
            </Button>
          ))}
        </div>
        <div style={{ marginTop: '5px' }}>
          <Form reply>
            <Form.TextArea
              style={
                !evaluable(this.state.searchInput)
                  ? { background: '#ffeee6' }
                  : {}
              }
              value={this.state.searchInput}
              onChange={(event, { value }) => {
                this.setState({ searchInput: value });
              }}
            />
          </Form>
        </div>
        <h4>
          {profiles.length} profile{profiles.length !== 1 ? 's' : ''}
        </h4>
        <List bulleted>
          {_.map(profiles, (profile, index) => (
            <List.Item key={index}>
              <span>
                {/* eslint-disable-next-line */}
                <a
                  style={{
                    ...(isSpecialTarget(profile) && { color: 'green' }),
                    ...(profile.handleSeenBefore && { color: 'red' }),
                    ...(((profile.data &&
                      profile.data.notInterested === 'true') ||
                      (profile.data && profile.data.motivation === 'none')) && {
                      color: 'purple',
                    }),
                    ...(isCurrentProfile(profile) && {
                      fontSize: 15,
                      fontWeight: 'bold',
                    }),
                  }}
                  onClick={() => this.props.onLoadProfile(profile.id)}
                >
                  {(this.state.showId
                    ? '[' + (profile.data && profile.data.handle) + '] '
                    : '') +
                    '[' +
                    (profile.grade || '?') +
                    ']' +
                    '[' +
                    new Date(profile.timestamp).toLocaleDateString() +
                    '  -  ' +
                    new Date(profile.timestamp).toLocaleTimeString() +
                    ']  ' +
                    (locationFromProfile(profile)
                      ? '[' + locationFromProfile(profile) + ']  '
                      : '') +
                    ((profile.data && profile.data.username) || profile.id) +
                    (profile.extraFields &&
                    (profile.extraFields.firstname ||
                      profile.extraFields.lastname)
                      ? ' - ' +
                        (profile.extraFields.firstname || '') +
                        ' ' +
                        (profile.extraFields.lastname || '')
                      : '')}
                </a>
                <Icon
                  name={profile.status === 'resolved' ? 'checkmark' : 'wrench'}
                />
              </span>
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
}

const decodeURI = (link) => {
  if (!link) {
    return link;
  }
  try {
    return decodeURIComponent(link);
  } catch (e) {
    return link;
  }
};

class CodinGameMonitoring extends Component {
  state = {};
  componentWillMount() {
    if (this.props.match.params.id) {
      this.handleLoadProfile(this.props.match.params.id);
    }
  }
  loadProfiles() {
    this.setState(
      {
        profiles: [],
        deleteMode: false,
      },
      () => {
        const queryString = this.state.fullMode ? '' : '?nbMax=300';
        axios
          .get(baseUrl + '/codingame/profiles' + queryString)
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for /codingame/profiles');
            }
            if (data.error) {
              throw Error(data.error);
            }
            if (!data.profiles) {
              throw Error('need a field profiles');
            }
            this.setState({
              profiles: data.profiles,
            });
          })
          .catch((e) => {
            alert(e.message);
          });
      },
    );
  }
  handleSwitchToFullMode = () => {
    this.setState(
      {
        fullMode: true,
      },
      () => {
        this.loadProfiles();
      },
    );
  };
  handleLoadProfile = (id) => {
    this.setState(
      {
        //selectedProfile: null,
        deleteMode: false,
        currentLinkedin: null,
      },
      () => {
        axios
          .get(baseUrl + '/codingame/profiles/id/' + id)
          .then(async ({ data }) => {
            if (!data) {
              throw Error('no data for /codingame/profiles/id/:id');
            }
            if (!data.profile) {
              throw Error('need a field profile');
            }
            if (data.error) {
              throw Error(data.error);
            }
            const linkedInUrl =
              data.profile.data && data.profile.data.linkedInUrl
                ? data.profile.data.linkedInUrl
                : null;

            const linkedin = linkedInUrl
              ? linkedInUrl
                  .substr(linkedInUrl.indexOf('linkedin.com/in/'))
                  .substr(16)
              : '';
            const linkedinWithoutSlashes =
              _.isString(linkedin) && linkedin.split('/')[0];
            let currentLinkedin = null;
            if (linkedinWithoutSlashes) {
              currentLinkedin = (await axios.get(
                baseUrl + '/sourceData/linkedin/' + linkedinWithoutSlashes,
              )).data;
            }
            this.setState({
              selectedProfile: data.profile,
              currentLinkedin,
            });
          })
          .catch((e) => {
            console.error(e);
            alert(e.message);
          });
      },
    );
  };
  changeProfileField(id, key, value) {
    this.setState(
      {
        //profiles: [],
        //selectedProfile: null,
        deleteMode: false,
      },
      () => {
        axios
          .put(baseUrl + '/codingame/profiles/id/' + id + '/field/' + key, {
            [key]: value,
          })
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for codingame/profiles/id/:id/fiel/' + key);
            }
            if (data.error) {
              throw Error(data.error);
            }
            //this.loadProfiles();
            this.handleLoadProfile(id);
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  deleteProfile(id) {
    this.setState(
      {
        profiles: [],
        selectedProfile: null,
        deleteMode: false,
      },
      () => {
        axios
          .delete(baseUrl + '/codingame/profiles/id/' + id)
          .then(({ data }) => {
            if (!data) {
              throw Error('no data for delete /codingame/profiles/id/:id');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.loadProfiles();
          })
          .catch((e) => {
            console.error(e.message);
          });
      },
    );
  }
  uploadOnSweetapp(id) {
    axios
      .get(
        baseUrl +
          '/codingame/profiles/id/' +
          id +
          '/pushToSweetapp/contact-codingame',
      )
      .then(({ data }) => {
        if (!data) {
          throw Error(
            'no data for delete /codingame/profiles/id/:id/pushToSweetapp',
          );
        }
        if (data.error) {
          throw Error(data.error);
        }
        this.handleLoadProfile(id);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }
  deleteOnSweetapp(id) {
    axios
      .get(
        baseUrl +
          '/codingame/profiles/id/' +
          id +
          '/removeFromSweetapp/contact-codingame',
      )
      .then(({ data }) => {
        if (!data) {
          throw Error(
            'no data for delete /codingame/profiles/id/:id/deleteFromSweetapp',
          );
        }
        if (data.error) {
          throw Error(data.error);
        }
        this.handleLoadProfile(id);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }
  componentDidMount() {
    this.loadProfiles();
  }
  renderSelectedProfile() {
    const profile = this.state.selectedProfile;
    if (!profile) {
      return null;
    }

    const date = new Date(profile.timestamp);
    const grade = profile.data ? profile.grade : null;
    const title =
      '[' +
      ((profile.data && profile.data.username) || profile.id) +
      '] ' +
      (grade ? '(' + grade + ')' : '');
    const subTitle =
      'envoyé le ' +
      date.toLocaleDateString() +
      ' à ' +
      date.toLocaleTimeString();

    const image =
      profile.status === 'resolved' ? '/images/done.png' : '/images/wrench.png';
    const data = profile.data || {};

    const baseLocation = data.city || data.region || data.locations;
    const location =
      (baseLocation ? baseLocation + ' - ' : '') + (data.country || '');

    const codingameLink = data.handle
      ? 'https://www.codingame.com/profile/' + data.handle
      : '';

    const codingameName = data.username || 'Unnamed User';

    const linkedin = data.linkedInUrl
      ? data.linkedInUrl
          .substr(data.linkedInUrl.indexOf('linkedin.com/in/'))
          .substr(16)
      : '';

    const avatarLink = data.avatar
      ? 'https://static.codingame.com/servlet/fileservlet?id=' +
        data.avatar +
        '&format=profile_avatar'
      : '';

    const salaryStr = data.salary
      ? data.salary + ' ' + (data['salary-currency'] || '')
      : '';

    const domains = data.domains ? data.domains : '';

    const notInterested = profile.data && profile.data.notInterested === 'true';
    const decodedLinkedinURL = decodeURI(linkedin);
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Image floated="right" size="mini" src={image} />
            {avatarLink ? (
              <Image floated="right" size="mini" src={avatarLink} />
            ) : null}
            <Card.Header>{title}</Card.Header>
            <Card.Meta>{subTitle}</Card.Meta>

            <Card.Content>
              <div className="ui four buttons">
                <Button
                  basic
                  color="green"
                  onClick={() =>
                    this.changeProfileField(profile.id, 'grade', 'A')
                  }
                >
                  A
                </Button>
                <Button
                  basic
                  color="yellow"
                  onClick={() =>
                    this.changeProfileField(profile.id, 'grade', 'B')
                  }
                >
                  B
                </Button>
                <Button
                  basic
                  color="brown"
                  onClick={() =>
                    this.changeProfileField(profile.id, 'grade', 'C')
                  }
                >
                  C
                </Button>
                <Button
                  basic
                  color="red"
                  onClick={() =>
                    this.changeProfileField(profile.id, 'grade', 'D')
                  }
                >
                  D
                </Button>
              </div>
            </Card.Content>

            <Card.Description>
              {notInterested && (
                <h1 style={{ color: 'red' }}>NOT-INTERESTED</h1>
              )}
              <br />

              <HiresweetComment
                key={profile.id}
                initialComment={profile.comment}
                onSave={(comment) => {
                  this.changeProfileField(profile.id, 'comment', comment);
                }}
              />

              <List bulleted>
                {data.motivation ? (
                  <List.Item>
                    Motivation:{' '}
                    {
                      <span
                        style={{
                          color: data.motivation === 'high' ? 'green' : 'black',
                        }}
                      >
                        {data.motivation}
                      </span>
                    }
                  </List.Item>
                ) : (
                  ''
                )}

                {location ? <List.Item>{location}</List.Item> : ''}
                {data.experience ? (
                  <List.Item>
                    <strong>XP: </strong> {data.experience}
                  </List.Item>
                ) : (
                  ''
                )}
                {linkedin ? (
                  <List.Item>
                    <a href={data.linkedInUrl} target="_blank" rel='noopener noreferrer'>
                      <Icon name="linkedin" />
                      {decodedLinkedinURL}
                    </a>
                  </List.Item>
                ) : (
                  ''
                )}
                {codingameLink ? (
                  <List.Item>
                    <strong>GC: </strong>
                    <a href={codingameLink} target="_blank" rel='noopener noreferrer'>
                      {codingameName}
                    </a>
                  </List.Item>
                ) : (
                  ''
                )}
                {data.email ? <List.Item>{data.email}</List.Item> : ''}
                {data.phoneNumber ? (
                  <List.Item>{data.phoneNumber}</List.Item>
                ) : (
                  ''
                )}
                {salaryStr ? <List.Item>{salaryStr}</List.Item> : ''}
                {data.availability ? (
                  <List.Item>Dispo: {data.availability}</List.Item>
                ) : (
                  ''
                )}
                {domains ? <List.Item>{domains}</List.Item> : ''}
                {data.technologies ? (
                  <List.Item>{data.technologies}</List.Item>
                ) : (
                  ''
                )}
                {data.careerWishes ? (
                  <Message
                    icon="heart"
                    header="Wishes"
                    content={data.careerWishes}
                  />
                ) : (
                  ''
                )}
              </List>
            </Card.Description>
            <br />

            <ExtraFields
              key={profile.id}
              initialExtraFields={
                _.isEmpty(profile.extraFields)
                  ? this.state.currentLinkedin &&
                    this.state.currentLinkedin.data
                    ? {
                        ...(this.state.currentLinkedin.data.firstname && {
                          firstname: this.state.currentLinkedin.data.firstname,
                        }),
                        ...(this.state.currentLinkedin.data.lastname && {
                          lastname: this.state.currentLinkedin.data.lastname,
                        }),
                      }
                    : null
                  : profile.extraFields
              }
              onSave={(extraFields) => {
                this.changeProfileField(profile.id, 'extraFields', extraFields);
              }}
            />

            <br />
            <center>
              <Button
                onClick={() => {
                  console.log(JSON.stringify(profile, null, 4));
                  console.log(profile);
                }}
              >
                Log profile in console
              </Button>
              {profile.sweetappProfileId ? (
                <span>
                  <a
                    href={
                      'https://app.hiresweet.com/client/codingame-contact/offers/contact-codingame/profiles/' +
                      profile.sweetappProfileId
                    }
                    target="_blank"
                    rel='noopener noreferrer'
                  >
                    <Button color="blue">See on SweetApp</Button>
                  </a>
                  <Button
                    color="orange"
                    onClick={() => {
                      this.deleteOnSweetapp(profile.id);
                    }}
                  >
                    Delete on SweetApp
                  </Button>
                </span>
              ) : (
                <Button
                  onClick={() => {
                    this.uploadOnSweetapp(profile.id);
                  }}
                >
                  Send to SweetApp
                </Button>
              )}
            </center>
            <br />
            <br />
          </Card.Content>

          <Card.Content extra>
            <div className="ui seven buttons">
              <Button
                basic
                color="orange"
                onClick={() =>
                  this.changeProfileField(profile.id, 'status', 'pending')
                }
              >
                Pending
              </Button>

              <Button
                basic
                color="orange"
                onClick={() =>
                  this.changeProfileField(
                    profile.id,
                    'status',
                    'to-be-contacted',
                  )
                }
              >
                TBC
              </Button>

              <Button
                basic
                color="orange"
                onClick={() =>
                  this.changeProfileField(profile.id, 'status', 'contacted')
                }
              >
                Contacted
              </Button>

              <Button
                basic
                color="yellow"
                onClick={() =>
                  this.changeProfileField(profile.id, 'status', 'doing')
                }
              >
                Doing
              </Button>

              <Button
                basic
                color="green"
                onClick={() =>
                  this.changeProfileField(profile.id, 'status', 'in-process')
                }
              >
                In Process
              </Button>

              <Button
                basic
                color="brown"
                onClick={() =>
                  this.changeProfileField(profile.id, 'status', 'backlog')
                }
              >
                Backlog
              </Button>

              <Button
                basic
                color="red"
                onClick={() => {
                  if (!this.state.deleteMode) {
                    this.setState({ deleteMode: true });
                    setTimeout(() => {
                      this.setState({ deleteMode: false });
                    }, 3000);
                  } else {
                    this.deleteProfile(profile.id);
                  }
                }}
              >
                {this.state.deleteMode ? 'Confirm' : 'Delete'}
              </Button>
            </div>
          </Card.Content>
        </Card>

        {this.state.currentLinkedin ? (
          <LinkedinProfile
            source={this.state.currentLinkedin}
            more={{}}
            globalHint={null}
            globalHints={[]}
            onMore={() => {}}
            onChangeExperienceHint={() => {}}
            onChangeGlobalHint={() => {}}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
  render() {
    const selectedProfileId =
      this.state.selectedProfile && this.state.selectedProfile.id;
    return (
      <div
        style={{
          position: 'fixed',
          top: '68px',
          left: 0,
          height: 'calc(100vh - 68px)',
          width: '100vw',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '50%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <ProfilesListView
              profiles={this.state.profiles}
              selectedProfileId={selectedProfileId}
              onLoadProfile={this.handleLoadProfile}
            />
            {!this.state.fullMode && (
              <div>
                <br />
                <center>
                  <Button onClick={this.handleSwitchToFullMode}>See All</Button>
                </center>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'inline-block',
              paddingTop: '5px',
              paddingBottom: '20px',
              height: '100%',
              width: '50%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {this.renderSelectedProfile()}
          </div>
        </div>
      </div>
    );
  }
}

export default CodinGameMonitoring;
