import React from 'react';
import _ from 'underscore';

import { Header, Statistic, Icon, Popup, Label, Segment, Grid } from 'semantic-ui-react';

const getWorkplaceTypeIcon = (type, startupness) =>
  type === 'ssii'
    ? 'desktop'
    : type === 'st' || type === 'startup'
    ? 'lightbulb'
    : type === 'big'
    ? 'industry'
    : startupness > 0.7
    ? 'lightbulb'
    : 'question';

const WorkplaceIcon = ({ type, startupness }) => {
  return <Popup trigger={<Icon size='large' name={getWorkplaceTypeIcon(type, startupness)} />} content={type} />;
};

const WorkplacePrestige = ({ prestige }) => {
  return (
    <Popup
      trigger={
        <Label size='large' color={prestige > 3 ? 'green' : 'orange'}>
          {prestige}
        </Label>
      }
      content='Workplace Prestige'
    />
  );
};

export default class WorkplaceHeader extends React.Component {
  render() {
    const workplace = this.props.workplace;
    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <Header as='h2'>{workplace.name}</Header>
            </Grid.Column>
            <Grid.Column width={2}>
              <WorkplacePrestige prestige={workplace.prestige} />
              {_.map(workplace.types, (type) => (
                <WorkplaceIcon type={type} startupness={workplace.startupness} key={type} />
              ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>{workplace.description}</Grid.Column>
            <Grid.Column width={2}>
              <Statistic size='small'>
                <Statistic.Label>Founded in</Statistic.Label>
                <Statistic.Value>{workplace.foundedYear}</Statistic.Value>
              </Statistic>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
