import _ from 'underscore';
import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, Legend } from 'recharts';
import moment from 'moment/moment';

function getColor(source) {
  if (source == 'watch') {
    return 'red';
  }
  if (source == 'more-opportunities') {
    return '#8884d8';
  }

  if (source == 'max-matching') {
    return '#ffc658';
  }

  if (source == 'search') {
    return 'brown';
  }
}
const externalFlows = ['watch', 'more-opportunities', 'max-matching', 'search'];

export default class DashBoardExternalFlow extends Component {
  render() {
    const timeFormatter = (tick) => {
      return moment(new Date('20' + tick)).format('YY-MM-DD');
    };
    console.log('A');
    console.log(this.props.stats);
    const data = _.sortBy(
      _.map(this.props.stats, (stat) => ({
        ...stat,
        x: timeFormatter(stat.date),
      })),
      'x',
    );
    return (
      <Segment>
        <Header as='h3' textAlign='center'>
          Evolution of select rate for external flows
        </Header>
        <LineChart width={680} height={300} data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <YAxis
            label={{
              value: 'Select Rate',
              angle: -90,
              position: 'insideLeft',
              dy: 40,
              dx: 20,
            }}
          />
          <Tooltip />
          <Legend />
          {_.map(externalFlows, (flow) => {
            return <Line type='monotone' dataKey={flow} stroke={getColor(flow)} />;
          })}
        </LineChart>
      </Segment>
    );
  }
}
