import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import { Map, fromJS } from 'immutable';
import { Button, Header, Modal, Grid, Segment, Divider } from 'semantic-ui-react';
import { SweetForm, Input, Checkbox, Clearable, Select, SelectDate, List, Range } from 'sweetform';
import { EditOmegaCriteria, schemaCriteriaToFormCriteria, formCriteriaToSchemaCriteria } from './EditOmegaCriteria';
import Offer from '../../components/Offer';
import OfferCriteria from '../../Offers/OfferCriteria';

export const getHiresweetOffer = async (offerId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/offers/${offerId}`);
    if (!data){
      throw Error(`No hiresweet offer with id ${offerId}`)
    }
    return data;
  } catch (e) {
    alert(e)
  }
}

export const getMergedHiresweetOffer = async (offerId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/offersConciliation/getMergedHiresweetOfferForSearch/${offerId}`);
    if (!data){
      throw Error(`No merged offer with id ${offerId}`)
    }
    if (!data.success){
      throw Error(data.error);
    }
    return data.hiresweetOffer;
  } catch (e) {
    alert(e)
  }
}

class EditCriteriaModal extends React.Component {
  state = {}
  
  componentWillMount() {
    this.load();
  }
  
  load = async () => {
    const hiresweetOffer = await getHiresweetOffer(this.props.offerId);
    const mergedOffer = await getMergedHiresweetOffer(this.props.offerId);
    this.setState({
      currentValue: {
        ...(hiresweetOffer && {
          criteria: schemaCriteriaToFormCriteria(hiresweetOffer.criteria)}),
          useSweetappCriteria: hiresweetOffer.useSweetappCriteria,
      },
      platformId: hiresweetOffer && hiresweetOffer.platformId,
      mergedOffer, 
    })
  }


  handleChangeSweethubCriteria = ({criteria, useSweetappCriteria}) => {
    this.setState({ currentValue: {criteria, useSweetappCriteria} });
  };

  handleSaveSweethubCriteria = async () => {
    const { currentValue } = this.state;
    const { criteria, useSweetappCriteria} = currentValue;
    await axios.put(`${baseUrl}/salesboard/offers/${this.props.offerId}`, {
      criteria: formCriteriaToSchemaCriteria(criteria),
      useSweetappCriteria
    })
    const mergedOffer = await getMergedHiresweetOffer(this.props.offerId);
    this.setState({ mergedOffer });
  }



  render() {
    const { offerId, onClose } = this.props;
    const { platformId, currentValue, mergedOffer } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>{`Edit Criteria ${offerId}`}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 700 }}>
          <Grid columns={3}>
            <Grid.Column width={6}>
              <Header as='h4'>Sweethub Criteria</Header>
              {currentValue && (
                <div>
                  <SweetForm initialValues={currentValue} onChange={this.handleChangeSweethubCriteria}>
                    <Checkbox slider field='useSweetappCriteria' label='Use Sweetapp Criteria' />
                    <Divider horizontal/>
                    <EditOmegaCriteria field='criteria' onlyExtraCriteria={currentValue.useSweetappCriteria}/>
                  </SweetForm>
                  <Button color='green' onClick={()=>this.handleSaveSweethubCriteria()}>Save</Button>
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={5}>
              <Header as='h4'>Sweetapp Criteria</Header>
              {platformId && (
                <Segment>
                  <OfferCriteria jobOfferId={platformId} />
                </Segment>
              )}
            </Grid.Column>
            <Grid.Column width={5}>
              <Header as='h4'>Merged Criteria</Header>
              {mergedOffer && (
                <Offer offer={mergedOffer}/>
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default EditCriteriaModal;
