import axios from 'axios';
import React, { Component } from 'react';
import { Button, Card, Form, Grid, Icon, Modal, Popup } from 'semantic-ui-react';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';

import { Select, SweetForm } from 'sweetform';
import { sweethubToSweetappCriteria } from '../TechAssetSandBox/FunctionResult';

import EditOfferModal from '../DataPointSandbox/EditOfferModal';
import Offer from '../components/Offer';
import OfferCriteriaDiff from '../components/OfferCriteriaDiff.js';
import AdministrativeFiltersMenu, { archiveTypesMap } from './AdministrativeFiltersMenu.js';
import CustomFiltersMenu from './CustomFiltersMenu.js';
import IdealCandidatesSelection from './IdealCandidatesSelection.js';
import MagicFilterMenu from './MagicFilterMenu.js';
import ManualRecommendationsTable from './ManualRecommendationsTable.js';
import ProfilesSampleSelection from './ProfilesSampleSelection.js';
import RecommenderSystemCreationModal from './RecommenderSystemCreationModal.js';
import RecommenderSystemNameModal from './RecommenderSystemNameModal.js';

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

function convertDateForDisplay(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  return date.toLocaleDateString('fr-FR', options);
}

function setToNearestHigher15minutes(nbQuarters, date = new Date()) {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  return new Date((Math.floor(date.getTime() / ms) + nbQuarters) * ms);
}

function setToXDaysAt7(nbDays = 1, date = new Date()) {
  var newDate = new Date(date.setDate(date.getDate() + nbDays));
  newDate.setHours(7, 0, 0);
  return newDate;
}

function getDiffInHoursToNow(date) {
  const now = new Date();
  const hours = Math.round((date - now) / 36e5);
  return hours;
}

function getHiddenUntilTimestampOptions() {
  return [
    { value: undefined, label: 'Now' },
    { value: setToNearestHigher15minutes(1).getTime(), label: convertDateForDisplay(setToNearestHigher15minutes(1)) },
    { value: setToNearestHigher15minutes(2).getTime(), label: convertDateForDisplay(setToNearestHigher15minutes(2)) },
    { value: setToNearestHigher15minutes(3).getTime(), label: convertDateForDisplay(setToNearestHigher15minutes(3)) },
    { value: setToNearestHigher15minutes(4).getTime(), label: convertDateForDisplay(setToNearestHigher15minutes(4)) },
    {
      value: setToXDaysAt7(1).getTime(),
      label: 'Tomorrow at 7h00 (in ' + getDiffInHoursToNow(setToXDaysAt7(1)) + ' hours)',
    },
    {
      value: setToXDaysAt7(2).getTime(),
      label: 'After Tomorrow at 7h00 (in ' + getDiffInHoursToNow(setToXDaysAt7(2)) + ' hours)',
    },
    {
      value: setToXDaysAt7(3).getTime(),
      label: 'In 3 days at 7h00 (in ' + getDiffInHoursToNow(setToXDaysAt7(3)) + ' hours)',
    },
  ];
}

class ConfigSearchView extends Component {
  async componentDidMount() {
    const recommenderSystemIdOptions = await this.getRecommendationSystemIdOptions();
    const profileIdsInMission = await this.getProfileIdsInMission();
    const profileIdsInRecommendedMission = await this.getProfileIdsInRecommendedMission();
    const profileIdsWithActiveRecommendations = await this.getProfileIdsWithActiveRecommendations();
    const hiddenProfileIds = await this.getHiddenProfilesForMission();
    const profileIdsDoNotContact = await this.getProfileIdsDoNotContact();
    const defaultRecommenderSystemId = ((recommenderSystemIdOptions || [])[0] || {}).value || undefined;
    if (defaultRecommenderSystemId) {
      this.handleSelectRecommenderSystem({ recommenderSystemId: defaultRecommenderSystemId });
    }
    this.setState({
      recommenderSystemIdOptions,
      profileIdsInMission,
      profileIdsInRecommendedMission,
      profileIdsWithActiveRecommendations,
      profileIdsDoNotContact,
      hiddenProfileIds,
      selectedProfileIds: [],
      filters: [],
    });
  }

  getProfileIdsInMission = async (startTimestamp = undefined) => {
    const { selectedClient, selectedMissionId } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      startTimestamp: startTimestamp || undefined,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileIdsInMission';
    const { data } = await axios.post(url, params);
    const profileIdsInMission = data.data;
    return profileIdsInMission;
  };

  setStateProfileIdsInMission = async () => {
    const profileIdsInMission = await this.getProfileIdsInMission();
    this.setState({ profileIdsInMission });
  };

  setStateProfileIdsInRecommendedMission = async () => {
    const profileIdsInRecommendedMission = await this.getProfileIdsInRecommendedMission();
    this.setState({ profileIdsInRecommendedMission });
  };

  setStateHiddenProfileIds = async () => {
    const hiddenProfileIds = await this.getHiddenProfilesForMission();
    this.setState({ hiddenProfileIds });
  };

  getSkippedProfileIds = async (startTimestamp = undefined) => {
    const { selectedClient, selectedMissionId } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      startTimestamp: startTimestamp || undefined,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getSkippedProfileIds';
    const { data } = await axios.post(url, params);
    const negativeProfileIds = data.data;
    return negativeProfileIds;
  };

  getProfileIdsInRecommendedMission = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileIdsInRecommendedMission';
    const { data } = await axios.post(url, params);
    const profileIdsInRecommendedMission = data.data;
    return profileIdsInRecommendedMission;
  };

  getHiddenProfilesForMission = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getHiddenProfileIdsForMission';
    const { data } = await axios.post(url, params);
    const hiddenProfileIds = data.data;
    return hiddenProfileIds;
  };

  getProfileIdsWithActiveRecommendations = async () => {
    const { selectedClient } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileIdsWithActiveRecommendations';
    const { data } = await axios.post(url, params);
    const profileIdsWithActiveRecommendations = data.data;
    return profileIdsWithActiveRecommendations;
  };

  getProfileIdsDoNotContact = async () => {
    const { selectedClient } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileIdsDoNotContact';
    const { data } = await axios.post(url, params);
    const profileIdsDoNotContact = data.data;
    return profileIdsDoNotContact;
  };

  getProfileIdsRemovedFromMission = async (minMonthsSinceRemoveFromMission) => {
    if (_.isUndefined(minMonthsSinceRemoveFromMission) || _.isNull(minMonthsSinceRemoveFromMission)) {
      return [];
    }
    const { selectedClient, selectedMissionId } = this.props;
    const now = new Date();
    const maxTimestampSinceRemoveFromMission = now.getTime() - minMonthsSinceRemoveFromMission * 30 * 24 * 3600 * 1000;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      timestamp: maxTimestampSinceRemoveFromMission,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getProfileIdsRemovedFromMission';
    const { data } = await axios.post(url, params);
    const profileIds = data.data;
    return profileIds;
  };

  onclickOnCheckboxProfile = async ({ profileId }) => {
    var { selectedProfileIds } = this.state;
    if (selectedProfileIds.includes(profileId)) {
      selectedProfileIds = selectedProfileIds.filter((id) => id != profileId);
    } else {
      selectedProfileIds.push(profileId);
    }
    this.setState({ selectedProfileIds });
  };

  addProfilesAsRecommendations = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const { selectedProfileIds, hiddenUntilTimestamp } = this.state;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      profileIds: selectedProfileIds,
      hiddenUntilTimestamp,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/addProfilesAsRecommendations';
    const { data } = await axios.post(url, params);
    if (data.error) {
      alert(data.error);
    } else {
      alert('Profiles have been added to Recommendation');
    }
    this.setStateProfileIdsInRecommendedMission();
    this.setState({ selectedProfileIds: [] });
  };

  hideProfileForMission = async ({ profileId, snoozedUntilTimestamp }) => {
    const { selectedClient, selectedMissionId } = this.props;
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      profileId,
      snoozedUntilTimestamp,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/hideProfileForMission';
    const { data } = await axios.post(url, params);
    if (data.error) {
      alert(data.error);
    }
    this.setStateHiddenProfileIds();
  };

  handleSelectPipe = async (e, { value }) => {
    this.setState({ selectedPipeId: value });
  };

  getSelectedClientProfilesCollectionId = async () => {
    const { selectedClient } = this.props;
    const profilesCollectionId = selectedClient.profilesCollectionId;
    return profilesCollectionId;
  };

  getCurrentCriteria = async () => {
    const recommenderSystems = await this.getRecommenderSystemsMission();
    const currentCriteria = (recommenderSystems[0] || {}).currentCriteria || {};
    return currentCriteria;
  };

  onChangeRecommenderSystemName = async () => {
    this.setState({ recommenderSystemNameModalVisible: true });
  };

  openRecommenderSystemCreationModal = async () => {
    this.setState({ customRecommenderSystemCreationVisible: true });
  };

  displayOrHideCriteria = async () => {
    const { criteriaVisible } = this.state || {};
    this.setState({ criteriaVisible: !criteriaVisible });
  };

  displayOrHideCustomFilters = async () => {
    const { customFiltersVisible } = this.state || {};
    this.setState({ customFiltersVisible: !customFiltersVisible });
  };

  getRecommenderSystemsMission = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const selectedClientId = selectedClient.id;
    const selectedProjectId = selectedClient.projectId;
    const url =
      baseUrl +
      '/RevealRecommendationsRecommenderSystems/getRecommenderSystems/' +
      selectedClientId +
      '/' +
      selectedProjectId +
      '/' +
      selectedMissionId;
    const result = (await axios.get(url)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const recommenderSystems = result.data || {};
      this.setState({ recommenderSystems });
      return recommenderSystems;
    }
  };

  updateRecommendationsCurrentCriteriaInMongo = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const { currentCriteria } = this.state || {};
    const { selectedRecommenderSystem } = this.state;
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No recommender System selected');
    }
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const recommendationSystemId = (selectedRecommenderSystem || {}).id || {};
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: recommendationSystemId,
      criteria: currentCriteria,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/updateRecommenderSystemCurrentCriteria';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      await this.getRecommenderSystemsMission();
      const newRecommenderSystem = result.data || {};
      this.setState({ selectedRecommenderSystem: newRecommenderSystem });
    }
  };

  updateMagicFilterOptionsInMongo = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const { magicFilterOptions, selectedRecommenderSystem } = this.state || {};
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No recommender System selected');
    }
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No Administrative Criteria selected');
    }
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const recommendationSystemId = (selectedRecommenderSystem || {}).id || {};
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: recommendationSystemId,
      magicFilterOptions,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/updateRecommenderSystemMagicFilterOptions';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      await this.getRecommenderSystemsMission();
      const newRecommenderSystem = result.data || {};
      this.setState({ selectedRecommenderSystem: newRecommenderSystem });
      return newRecommenderSystem.magicFilterOptions || undefined;
    }
  };

  updateAdministrativeCriteriaInMongo = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const { administrativeSettingOptions, selectedRecommenderSystem } = this.state || {};
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No recommender System selected');
    }
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No Administrative Criteria selected');
    }
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const recommendationSystemId = (selectedRecommenderSystem || {}).id || {};
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: recommendationSystemId,
      administrativeCriteria: administrativeSettingOptions,
    };
    const url =
      baseUrl + '/RevealRecommendationsRecommenderSystems/updateRecommenderSystemCurrentAdministrativeCurrentCriteria';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      await this.getRecommenderSystemsMission();
      const newRecommenderSystem = result.data || {};
      this.setState({ selectedRecommenderSystem: newRecommenderSystem });
      return newRecommenderSystem.administrativeCriteria || undefined;
    }
  };

  formatSearchResults = (profiles) => {
    const formattedProfiles = _.map(profiles, (profile) => {
      return {
        score: profile.innerData.score,
        firstname: profile.projectionKeys.firstname,
        lastname: profile.projectionKeys.lastname,
        profile_path: profile.projectionKeys.profile_path,
        id: profile.id,
      };
    });
    return formattedProfiles;
  };

  getSearchResults = async () => {
    this.setState({ computingSearchWithCriteria: true });
    const { selectedClient } = this.props;
    const { currentCriteria } = this.state || {};
    const profilesCollectionId = selectedClient.profilesCollectionId;
    const filters = this.createFilters();
    this.setProfileIdsRemovedFromMission();

    const params = {
      profilesCollectionId,
      criteria: currentCriteria,
      filters,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getSearchResults';
    const result = (await axios.post(url, params)).data;

    if (!result.success) {
      alert(result.message);
    } else {
      const profilesSearch = result.results || [];
      const formattedProfilesSearch = this.formatSearchResults(profilesSearch);
      this.setState({ profilesSearch: formattedProfilesSearch });
    }
    this.setState({ computingSearchWithCriteria: false });
  };

  getRecommendationResults = async () => {
    this.setState({ computingSearchWithRecommendationSystem: true });
    const { selectedClient } = this.props;
    const { positiveProfileIds, negativeProfileIds } = this.state || {};
    const profilesCollectionId = selectedClient.profilesCollectionId;
    const filters = this.createFilters();
    const params = {
      profilesCollectionId,
      positiveProfileIds,
      negativeProfileIds,
      filters,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getRecommendationSystemResults';
    const result = (await axios.post(url, params)).data;

    if (!_.isUndefined(result.error)) {
      alert(result.error);
    } else if (!result.success) {
      alert(result.message);
    } else {
      const profilesSearch = result.results || [];
      const formattedProfilesSearch = this.formatSearchResults(profilesSearch);
      this.setState({ profilesSearch: formattedProfilesSearch });
    }
    this.setState({ computingSearchWithRecommendationSystem: false });
  };

  deleteRecommenderSystem = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const { selectedRecommenderSystem } = this.state;
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No recommender System selected');
    }
    const recommendationSystemId = (selectedRecommenderSystem || {}).id || {};
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: recommendationSystemId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/deleteRecommenderSystem';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const newRecommendationSystems = result.data || {};
      await this.getRecommendationSystemIdOptions();
      this.setState({
        selectedRecommenderSystem: undefined,
        computedCriteria: undefined,
        currentCriteria: undefined,
        currentAdministrativeCriteria: undefined,
      });
    }
  };

  getMainComputedCriteria = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const profilesCollectionId = (selectedClient || {}).profilesCollectionId;
    const url =
      baseUrl + '/revealRecommendations/getComputedCriteria/' + profilesCollectionId + '/' + selectedMissionId;
    const results = (await axios.get(url)).data;
    if (!results.success) {
      alert('Error in computing criteria with main model.');
    }
    const computedCriteria = (results || {}).criteria;
    this.setState({ computedCriteria });
  };

  setComputedCriteriaAsCurrent = () => {
    const { computedCriteria } = this.state || {};
    this.setState({ currentCriteria: computedCriteria });
  };

  setIdealCandidates = (candidateIds) => {
    this.setState({ idealProfileIds: candidateIds });
  };

  getMainComputedCriteriaWithSelectedPositiveProfiles = async () => {
    const { selectedClient } = this.props;
    const profilesCollectionId = (selectedClient || {}).profilesCollectionId;
    const { positiveProfileIds, idealProfileIds } = this.state || {};
    if (_.isEmpty(positiveProfileIds)) {
      alert('No selected positive profiles');
    }

    const params = {
      profilesCollectionId,
      positiveProfileIds,
      idealProfileIds: idealProfileIds || [],
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/getComputedCriteriaWithSelectedPositiveProfiles';
    const results = (await axios.post(url, params)).data;
    if (!results.success) {
      alert('Error in computing criteria with main model.');
    }
    const computedCriteria = (results || {}).criteria;
    this.setState({ computedCriteria });
  };

  displayDiffWithSweetApp = () => {
    const { currentCriteria } = this.state || {};
    const currentCriteriaInSweetappForm = sweethubToSweetappCriteria(currentCriteria);
    this.setState({ currentCriteriaInSweetappForm, diffWithSweetAppModalVisible: true });
  };

  onCloseDiffWithSweetAppModal = () => {
    this.setState({ diffWithSweetAppModalVisible: false });
  };

  async getRecommendationSystemIdOptions() {
    await this.getRecommenderSystemsMission();
    const { recommenderSystems } = this.state;
    // const recommenderSystemIdOptions = _.map(recommenderSystems, (recommenderSystem) => ({
    //   value: recommenderSystem.id,
    //   key: recommenderSystem.id,
    //   text: recommenderSystem.name || recommenderSystem.id,
    // }));
    const recommenderSystemIdOptions = _.map(recommenderSystems, (recommenderSystem) => ({
      value: recommenderSystem.id,
      label: recommenderSystem.name || recommenderSystem.id,
    }));

    this.setState({ recommenderSystemIdOptions });
    return recommenderSystemIdOptions;
  }
  onCancelChangeRecommenderSystemName = () => {
    this.setState({ recommenderSystemNameModalVisible: false });
  };
  onCancelCreateRecommenderSystem = () => {
    this.setState({ customRecommenderSystemCreationVisible: false });
  };

  deleteRecommenderSystem = async () => {
    const { selectedClient, selectedMissionId } = this.props;
    const clientId = selectedClient.id;
    const projectId = selectedClient.projectId;
    const { selectedRecommenderSystem } = this.state;
    if (_.isUndefined(selectedRecommenderSystem)) {
      alert('No recommender System selected');
    }
    const recommendationSystemId = (selectedRecommenderSystem || {}).id || {};
    const params = {
      clientId,
      projectId,
      missionId: selectedMissionId,
      recommendationSystemId: recommendationSystemId,
    };
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/deleteRecommenderSystem';
    const result = (await axios.post(url, params)).data;
    if (result.error) {
      alert(result.error);
    } else {
      const newRecommendationSystems = result.data || {};
      await this.getRecommendationSystemIdOptions();
      this.setState({
        selectedRecommenderSystem: undefined,
        computedCriteria: undefined,
        currentCriteria: undefined,
      });
    }
  };
  onSubmitChangeRecommenderSystemName = async (params) => {
    const { selectedClient, selectedMissionId } = this.props;
    const { selectedRecommenderSystem } = this.state;
    const payload = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      recommendationSystemId: selectedRecommenderSystem.id,
      name: params.name,
    };
    if (_.isUndefined(params)) {
      this.setState({ createMissionModalVisible: false });
      return;
    }
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/updateRecommenderSystemName';
    const result = (await axios.post(url, payload)).data;
    if (result.error) {
      alert(result.error);
      return;
    }
    const newRecommenderSystems = result.data || {};
    await this.getRecommendationSystemIdOptions();
    this.setState({ recommenderSystems: newRecommenderSystems, recommenderSystemNameModalVisible: false });
  };

  onSubmitCreateRecommenderSystem = async (params) => {
    const { selectedClient, selectedMissionId } = this.props;
    const payload = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
      name: params.name,
    };
    if (_.isUndefined(params)) {
      this.setState({ createMissionModalVisible: false });
      return;
    }
    const url = baseUrl + '/RevealRecommendationsRecommenderSystems/createCustomRecommenderSystem';
    const result = (await axios.post(url, payload)).data;
    if (result.error) {
      alert(result.error);
      return;
    }
    const newRecommenderSystems = result.data || {};
    await this.getRecommendationSystemIdOptions();
    const recommenderSystemId = newRecommenderSystems[newRecommenderSystems.length - 1].id;
    const paramsHandleSelectedRecommenderSystem = {
      recommenderSystemId,
    };
    await this.handleSelectRecommenderSystem(paramsHandleSelectedRecommenderSystem);
    this.setState({
      recommenderSystems: newRecommenderSystems,
      customRecommenderSystemCreationVisible: false,
    });
  };

  handleSelectRecommenderSystem = async (params) => {
    const { recommenderSystems } = this.state || {};
    const selectedRecommenderSystem = _.find(
      recommenderSystems,
      (recommenderSystem) => recommenderSystem.id === params.recommenderSystemId,
    );
    const administrativeSettingOptions = {
      minMonthsSinceLastInteraction: 3,
      minMonthsSinceLastAddingToMission: 3,
      minMonthsSinceRemoveFromMission: 3,
      workingForClientMode: 'ever',
      ...(selectedRecommenderSystem.administrativeCriteria || {}),
    };
    const currentCriteria = (selectedRecommenderSystem || {}).currentCriteria || {};
    const positiveProfileIds = await this.getProfileIdsInMission();
    const negativeProfileIds = await this.getSkippedProfileIds();

    this.setState({
      selectedRecommenderSystem,
      administrativeSettingOptions,
      initialAdministrativeSettingOptions: administrativeSettingOptions,
      criteriaVisible: false,
      currentCriteria,
      computedCriteria: undefined,
      profilesSearch: undefined,
      positiveProfileIds,
      negativeProfileIds,
    });
  };

  handleChangeHiddenUntilTimestamp(params) {
    this.setState({
      hiddenUntilTimestamp: params.hiddenUntilTimestamp,
      snoozedForFutureRecommendationsUntilTimestamp: params.snoozedForFutureRecommendationsUntilTimestamp,
    });
  }

  onChangeAdministrativeSettings = (params) => {
    const { administrativeSettingOptions } = this.state || {};
    const excludedArchiveTypes = params.excludedArchiveTypes ? params.excludedArchiveTypes.split(';') : [];
    const mapExcludedArchiveTypes = _.map(
      excludedArchiveTypes,
      (archiveType) => (_.find(archiveTypesMap, (item) => item.value === archiveType) || {}).id,
    );
    const allKeywords = params.allKeywords ? params.allKeywords.split(';') : [];
    const anyKeywords = params.anyKeywords ? params.anyKeywords.split(';') : [];
    const friendCompanies = params.friendCompanies ? params.friendCompanies.split(';') : [];
    const newAdministrativeSettingOptions = {
      ...administrativeSettingOptions,
      ...params,
      excludedArchiveTypes: mapExcludedArchiveTypes,
      allKeywords,
      anyKeywords,
      friendCompanies,
    };
    this.setState({ administrativeSettingOptions: newAdministrativeSettingOptions });
  };

  onChangeMagicFilterOptions = (params) => {
    this.setState({
      magicFilterOptions: params,
    });
  };

  onChangeCustomFilters = (params) => {
    this.setState({ customFilters: params });
  };

  setProfileIdsRemovedFromMission = async () => {
    const { administrativeSettingOptions } = this.state;
    if (
      !_.isUndefined(administrativeSettingOptions.minMonthsSinceRemoveFromMission) &&
      !_.isNull(administrativeSettingOptions.minMonthsSinceRemoveFromMission)
    ) {
      const profileIdsRemovedFromMission = await this.getProfileIdsRemovedFromMission(
        administrativeSettingOptions.minMonthsSinceRemoveFromMission,
      );
      this.setState({ profileIdsRemovedFromMission });
    }
  };

  createFilters = () => {
    const { selectedClient } = this.props;
    const { administrativeSettingOptions, magicFilterOptions, customFilters } = this.state;
    let filters = [];
    if (administrativeSettingOptions.useFreelance) {
      filters.push({
        id: '#not',
        params: {
          son: {
            id: 'isFreelance',
            params: {},
          },
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.minMonthsSinceLastHiring) &&
      !_.isNull(administrativeSettingOptions.minMonthsSinceLastHiring)
    ) {
      filters.push({
        id: 'timeElapsedSinceLastHiring',
        params: {
          min: administrativeSettingOptions.minMonthsSinceLastHiring,
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.minMonthsSinceLastInteraction) &&
      !_.isNull(administrativeSettingOptions.minMonthsSinceLastInteraction)
    ) {
      const nbMonthsSinceLastInteraction = administrativeSettingOptions.minMonthsSinceLastInteraction;
      filters.push({
        id: '#or',
        params: {
          sons: [
            {
              id: 'commonField',
              params: {
                max: new Date().getTime() - nbMonthsSinceLastInteraction * 30 * 24 * 3600 * 1000,
                key: 'last_interaction_timestamp',
              },
            },
            {
              id: 'commonField',
              params: {
                policy: 'isNull',
                key: 'last_interaction_timestamp',
              },
            },
          ],
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.minMonthsSinceLastAddingToMission) &&
      !_.isNull(administrativeSettingOptions.minMonthsSinceLastAddingToMission)
    ) {
      const nbMonthsSinceLastAddingToMission = administrativeSettingOptions.minMonthsSinceLastAddingToMission;
      filters.push({
        id: '#or',
        params: {
          sons: [
            {
              id: '#not',
              params: {
                son: {
                  id: 'missionsFilter',
                  params: {
                    minInsertionTimestamp:
                      new Date().getTime() - nbMonthsSinceLastAddingToMission * 30 * 24 * 3600 * 1000,
                  },
                },
              },
            },
            {
              id: '#not',
              params: {
                son: {
                  id: 'missionsFilter',
                  params: {
                    mode: 'in-at-least-one',
                  },
                },
              },
            },
          ],
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.excludedArchiveTypes) &&
      !_.isEmpty(administrativeSettingOptions.excludedArchiveTypes)
    ) {
      const excludedArchiveTypes = administrativeSettingOptions.excludedArchiveTypes;
      filters.push({
        id: '#not',
        params: {
          son: {
            id: 'missionsFilter',
            params: {
              archiveLabels: excludedArchiveTypes,
            },
          },
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.allKeywords) &&
      !_.isEmpty(administrativeSettingOptions.allKeywords)
    ) {
      const allKeywords = administrativeSettingOptions.allKeywords;
      filters.push({
        id: 'hasKeywords',
        params: {
          keywords: allKeywords,
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.workingForClientMode) &&
      !_.isNull(administrativeSettingOptions.workingForClientMode)
    ) {
      const workingForClientMode = administrativeSettingOptions.workingForClientMode;
      const clientId = selectedClient.id;
      filters.push({
        id: '#not',
        params: {
          son: {
            id: 'workplaceFilter',
            params: {
              workplaceType: 'company',
              mode: workingForClientMode,
              workplaceNames: [clientId, ...(clientId.indexOf('-') >= 0 ? [clientId.replace('-', '')] : [])],
            },
          },
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.friendCompanies) &&
      !_.isEmpty(administrativeSettingOptions.friendCompanies)
    ) {
      const friendCompanies = administrativeSettingOptions.friendCompanies;
      filters.push({
        id: '#not',
        params: {
          son: {
            id: 'workplaceFilter',
            params: {
              workplaceType: 'company',
              mode: 'currently',
              workplaceNames: friendCompanies,
            },
          },
        },
      });
    }
    if (
      !_.isUndefined(administrativeSettingOptions.anyKeywords) &&
      !_.isEmpty(administrativeSettingOptions.anyKeywords)
    ) {
      const anyKeywords = administrativeSettingOptions.anyKeywords;
      filters.push({
        id: 'hasAnyKeywords',
        params: {
          keywords: anyKeywords,
        },
      });
    }
    if (!_.isUndefined(magicFilterOptions)) {
      const magicFilter = {
        id: 'magicFilterOmega',
        params: {
          positiveAnswerThreshold: 0,
          relaxed: !_.isUndefined(magicFilterOptions.relaxed) ? magicFilterOptions.relaxed : true,
          useDoNotContact: !_.isUndefined(magicFilterOptions.useDoNotContact)
            ? magicFilterOptions.useDoNotContact
            : true,
          useExperienceRange: !_.isUndefined(magicFilterOptions.useExperienceRange)
            ? magicFilterOptions.useExperienceRange
            : true,
          useJobExperience: !_.isUndefined(magicFilterOptions.useJobExperience)
            ? magicFilterOptions.useJobExperience
            : false,
          useFounderFilter: !_.isUndefined(magicFilterOptions.useFounderFilter)
            ? magicFilterOptions.useFounderFilter
            : false,
          useFreelance: false,
          useJobSkills: !_.isUndefined(magicFilterOptions.useJobSkills) ? magicFilterOptions.useJobSkills : true,
          useLocation: !_.isUndefined(magicFilterOptions.useLocation) ? magicFilterOptions.useLocation : true,
          useNotAlreadyContacted: false,
          usePrestige: !_.isUndefined(magicFilterOptions.usePrestige) ? magicFilterOptions.usePrestige : false,
          useTimeSinceLastHiring: false,
        },
      };
      filters.push(magicFilter);
    }
    if (!_.isUndefined(customFilters)) {
      filters = filters.concat(customFilters);
    }
    return filters;
  };

  updatePositiveProfileIds = (positiveProfileIds) => {
    this.setState({ positiveProfileIds });
  };

  updateNegativeProfileIds = (negativeProfileIds) => {
    this.setState({ negativeProfileIds });
  };

  setSavedCustomFiltersAsCurrentCustomFilters = (customFilters) => {
    this.setState({ currentCustomFilters: customFilters });
  };

  handleOpenEditOfferModal = () => {
    this.setState({ editOfferModalVisible: true });
  };

  handleCloseOfferEdition = () => {
    this.setState({ editOfferModalVisible: false });
  };

  handleSaveOfferEdition = (params) => {
    if (!_.isUndefined(params)) {
      const currentCriteria = params.data.criteria;
      this.setState({ currentCriteria });
    }
    this.setState({ editOfferModalVisible: false });
  };

  render() {
    const { sweetappCriteria, selectedClient, selectedMissionId } = this.props;
    const {
      currentCriteria,
      criteriaVisible,
      customFiltersVisible,
      customRecommenderSystemCreationVisible,
      computedCriteria,
      currentCriteriaInSweetappForm,
      diffWithSweetAppModalVisible,
      hiddenProfileIds,
      profileIdsInMission,
      profileIdsInRecommendedMission,
      profileIdsWithActiveRecommendations,
      profileIdsDoNotContact,
      profileIdsRemovedFromMission,
      profilesSearch,
      recommenderSystemIdOptions,
      recommenderSystemNameModalVisible,
      selectedRecommenderSystem,
      selectedProfileIds,
      editOfferModalVisible,
    } = this.state || {};

    const filteredProfilesSearch = _.filter(
      profilesSearch,
      (profile) =>
        !(profileIdsInMission || []).includes(profile.id) &&
        !(profileIdsInRecommendedMission || []).includes(profile.id) &&
        !(hiddenProfileIds || []).includes(profile.id) &&
        !(profileIdsWithActiveRecommendations || []).includes(profile.id) &&
        !(profileIdsDoNotContact || []).includes(profile.id) &&
        !(profileIdsRemovedFromMission || []).includes(profile.id),
    );

    return (
      <div>
        {editOfferModalVisible ? (
          <EditOfferModal
            key={'' + Math.random()}
            initialValue={{ criteria: currentCriteria }}
            onClose={this.handleCloseOfferEdition}
            onSave={this.handleSaveOfferEdition}
          />
        ) : null}
        <h3> Recommender Systems </h3>
        <br />
        <Popup
          trigger={
            <Icon name='edit' size='huge' color='green' onClick={() => this.openRecommenderSystemCreationModal()} />
          }
          content={'Create New Recommender System'}
        />
        <Popup
          trigger={<Icon name='trash' size='huge' color='red' onClick={() => this.deleteRecommenderSystem()} />}
          content={'Permanently delete Recommender System'}
        />
        <br />
        <br />

        <div style={{ width: '20%' }}>
          {/* <Form.Select
            placeholder='Pipes'
            options={recommenderSystemIdOptions}
            onChange={this.handleSelectPipe}
            value={selectedRecommenderSystemId}
          /> */}
          <SweetForm onChange={(params) => this.handleSelectRecommenderSystem(params)}>
            <Form.Field>
              <label>Recommender System Name</label>
              <Select
                field='recommenderSystemId'
                options={recommenderSystemIdOptions}
                defaultValue={((recommenderSystemIdOptions || [])[0] || {}).value || undefined}
              />
            </Form.Field>
          </SweetForm>
        </div>
        <br />
        {customRecommenderSystemCreationVisible ? (
          <RecommenderSystemCreationModal
            onSubmit={this.onSubmitCreateRecommenderSystem}
            onCancel={this.onCancelCreateRecommenderSystem}
          />
        ) : null}
        {diffWithSweetAppModalVisible ? (
          <Modal onClose={this.onCloseDiffWithSweetAppModal} open closeIcon size='fullscreen'>
            <Modal.Header />
            <Modal.Content>
              <OfferCriteriaDiff
                oldCriteria={sweetappCriteria}
                newCriteria={currentCriteriaInSweetappForm}
                noOldCriteria={_.isEmpty(sweetappCriteria)}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={this.onCloseDiffWithSweetAppModal}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null}
        {recommenderSystemNameModalVisible ? (
          <RecommenderSystemNameModal
            selectedRecommenderSystem={selectedRecommenderSystem}
            onSubmit={this.onSubmitChangeRecommenderSystemName}
            onCancel={this.onCancelChangeRecommenderSystemName}
          />
        ) : null}

        {selectedRecommenderSystem ? (
          <div>
            <Card
              style={{
                width: '100%',
              }}
            >
              <Card.Content>
                <Card.Header>
                  <h3>Settings</h3>
                </Card.Header>
                <br />
                <Card.Description>
                  <div>
                    <AdministrativeFiltersMenu
                      key={selectedRecommenderSystem.id}
                      onChangeAdministrativeSettings={this.onChangeAdministrativeSettings}
                      updateAdministrativeCriteriaInMongo={this.updateAdministrativeCriteriaInMongo}
                      selectedClient={selectedClient}
                      selectedMissionId={selectedMissionId}
                      selectedRecommenderSystemId={selectedRecommenderSystem.id}
                    />
                    <ColoredLine color='grey' />
                    <h4>Magic Filter Options</h4>
                    <MagicFilterMenu
                      onChange={this.onChangeMagicFilterOptions}
                      updateMagicFilterOptionsInMongo={this.updateMagicFilterOptionsInMongo}
                      selectedClient={selectedClient}
                      selectedMissionId={selectedMissionId}
                      selectedRecommenderSystemId={selectedRecommenderSystem.id}
                    />
                    <ColoredLine color='grey' />
                    <Button color='blue' onClick={() => this.displayOrHideCustomFilters()}>
                      {!customFiltersVisible ? 'Display Custom Filters' : 'Hide Custom Filters'}
                    </Button>
                    {customFiltersVisible ? (
                      <CustomFiltersMenu
                        onChangeCustomFilters={this.onChangeCustomFilters}
                        setSavedCustomFiltersAsCurrentCustomFilters={this.setSavedCustomFiltersAsCurrentCustomFilters}
                        selectedClient={selectedClient}
                        selectedMissionId={selectedMissionId}
                        selectedRecommenderSystemId={selectedRecommenderSystem.id}
                      />
                    ) : null}
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>

            <ProfilesSampleSelection
              selectedClient={selectedClient}
              selectedMissionId={selectedMissionId}
              updateNegativeProfileIds={this.updateNegativeProfileIds}
              updatePositiveProfileIds={this.updatePositiveProfileIds}
              getProfileIdsInMission={this.getProfileIdsInMission}
              getSkippedProfileIds={this.getSkippedProfileIds}
            />

            <IdealCandidatesSelection
              selectedClient={selectedClient}
              selectedMissionId={selectedMissionId}
              setIdealCandidates={this.setIdealCandidates}
            />

            <Card
              style={{
                width: '100%',
              }}
            >
              <Card.Content>
                <Card.Header>
                  <h3> Criteria</h3>
                </Card.Header>
                <br />
                <Card.Description>
                  <div>
                    <Button color='blue' onClick={() => this.displayOrHideCriteria()}>
                      {!criteriaVisible ? 'Display Criteria' : 'Hide Criteria'}
                    </Button>
                    {computedCriteria ? (
                      <Button onClick={() => this.setComputedCriteriaAsCurrent()}>
                        Set Computed Criteria as Current
                      </Button>
                    ) : null}
                    {sweetappCriteria ? (
                      <Button color='grey' onClick={() => this.displayDiffWithSweetApp()}>
                        Diff with SweetApp
                      </Button>
                    ) : null}
                    <Button color='green' onClick={() => this.updateRecommendationsCurrentCriteriaInMongo()}>
                      Set Criteria in mongo
                    </Button>
                    <Button color='purple' onClick={() => this.getMainComputedCriteriaWithSelectedPositiveProfiles()}>
                      Recompute Criteria With Selected Profiles
                    </Button>
                    <br />
                    <Grid>
                      {criteriaVisible ? (
                        <Grid.Column width={8}>
                          {' '}
                          <br />
                          <h3>Current Criteria</h3>
                          <a
                            style={{ fontSize: 10, cursor: 'pointer', marginLeft: 20 }}
                            onClick={this.handleOpenEditOfferModal}
                          >
                            Edit Offer
                          </a>
                          <Offer offer={{ criteria: currentCriteria }} />{' '}
                        </Grid.Column>
                      ) : null}
                      {criteriaVisible && !_.isUndefined(computedCriteria) ? (
                        <Grid.Column width={8}>
                          {' '}
                          <br />
                          <h3>Computed Criteria</h3> <Offer offer={{ criteria: computedCriteria }} />{' '}
                        </Grid.Column>
                      ) : null}
                    </Grid>
                    <br />
                    <br />
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>

            <Card
              style={{
                width: '100%',
              }}
            >
              <Card.Content>
                <Card.Header>
                  <h3> Recommendations </h3>
                </Card.Header>
                <br />
                <Card.Description>
                  <Button
                    color={(this.state || {}).computingSearchWithCriteria ? 'grey' : 'purple'}
                    onClick={() => this.getSearchResults()}
                  >
                    Search with Current Criteria
                  </Button>
                  <Button
                    color={(this.state || {}).computingSearchWithRecommendationSystem ? 'grey' : 'violet'}
                    onClick={() => this.getRecommendationResults()}
                  >
                    Search with Recommendation System
                  </Button>
                  <br />
                  <br />
                  <SweetForm onChange={(params) => this.handleChangeHiddenUntilTimestamp(params)}>
                    <Form.Field>
                      <label>Recommendations Hidden for Client Until Timestamp</label>
                      <Select
                        style={{
                          width: '20%',
                        }}
                        field='hiddenUntilTimestamp'
                        options={getHiddenUntilTimestampOptions()}
                      />
                    </Form.Field>
                  </SweetForm>
                  <br />
                  {!_.isEmpty(filteredProfilesSearch) ? (
                    <div>
                      <h3>{selectedProfileIds.length} profiles selected</h3>
                      <Button color='green' onClick={() => this.addProfilesAsRecommendations()}>
                        Add Selected Profiles To Recommendation
                      </Button>
                      <br />
                      <br />
                      <ManualRecommendationsTable
                        selectedProfileIds={selectedProfileIds}
                        profiles={filteredProfilesSearch}
                        key={selectedClient.profilesCollectionId}
                        criteria={currentCriteria}
                        hideProfileForMission={this.hideProfileForMission}
                        onclickOnCheckboxProfile={this.onclickOnCheckboxProfile}
                        selectedClient={selectedClient}
                        selectedMissionId={selectedMissionId}
                      />
                    </div>
                  ) : null}
                </Card.Description>
              </Card.Content>
            </Card>
          </div>
        ) : null}
      </div>
    );
  }
}
export default ConfigSearchView;
