import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../../baseUrl.js';

const loadLinkedinFromId = async ({ id }) => {
  const url = baseUrl + '/sourceData/linkedin/' + id;
  const { data } = await axios.get(url);
  return data;
};

export const loadLinkedinFromProfileCollection = async ({ profileId, profileCollectionId }) => {
  try {
    const url = `${baseUrl}/sweetchain/getProfileSourceData/${profileCollectionId}/${profileId}`;
    const result = (await axios.get(url)).data;
    const { linkedin } = result || {};
    if (result.error) {
      throw Error(result.error);
    }
    if (_.isEmpty(linkedin)) {
      throw Error('no linkedin');
    }
    return linkedin;
  } catch (e) {
    return { error: e.message }
  }
}

export default loadLinkedinFromId;
