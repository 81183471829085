import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import { Button, Header, Modal, Grid, Message, Divider } from 'semantic-ui-react';
import { SweetForm, Checkbox } from 'sweetform';
import { EditOmegaCriteria, schemaCriteriaToFormCriteria, formCriteriaToSchemaCriteria } from './EditOmegaCriteria';
import { getHiresweetOffer, getMergedHiresweetOffer } from './EditCriteriaModal';


class EditTargetedHuntCriteriaModal extends React.Component {
  state = {};

  componentWillMount() {
    this.load();
  }

  load = async () => {
    const hiresweetOffer = await getHiresweetOffer(this.props.offerId);
    const mergedHiresweetOffer = await getMergedHiresweetOffer(this.props.offerId);
    this.setState({
      currentValue: {
        targetedHuntCriteria: schemaCriteriaToFormCriteria(
            hiresweetOffer.targetedHuntCriteria || mergedHiresweetOffer.criteria),
        useTargetedHuntCriteria: hiresweetOffer.useTargetedHuntCriteria,
      },
    });
  };

  handleChange = ({ targetedHuntCriteria, useTargetedHuntCriteria }) => {
    this.setState({ currentValue: { targetedHuntCriteria, useTargetedHuntCriteria } });
  };

  handleSave = async () => {
    const { currentValue } = this.state;
    const { targetedHuntCriteria, useTargetedHuntCriteria } = currentValue;
    await axios.put(`${baseUrl}/salesboard/offers/${this.props.offerId}`, {
      targetedHuntCriteria: formCriteriaToSchemaCriteria(targetedHuntCriteria),
      useTargetedHuntCriteria,
    });
  };

  render() {
    const { offerId, onClose } = this.props;
    const { currentValue } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>{`Edit Targeted Hunt Criteria ${offerId}`}</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 700 }}>
          <Grid columns={1}>
            <Grid.Column width={16}>
              {currentValue && (
                <div>
                  <SweetForm initialValues={currentValue} onChange={this.handleChange}>
                    <Checkbox slider field='useTargetedHuntCriteria' label='Use Targeted Hunt Criteria' />
                    <Divider horizontal />
                    {currentValue.useTargetedHuntCriteria ? (
                      <div>
                        <Message>Will use these criteria to generate candidats to hunt :</Message>
                        <EditOmegaCriteria field='targetedHuntCriteria' />
                      </div>
                    ) : (
                      <div>
                        <Message>Will use criteria filled by client on app to generate candidates to hunt</Message>
                      </div>
                    )}
                  </SweetForm>
                  <Button color='green' onClick={() => this.handleSave()}>
                    Save
                  </Button>
                </div>
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default EditTargetedHuntCriteriaModal;
