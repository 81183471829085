import React from 'react';
import { Range } from 'immutable';
import Select from './Select';

var SelectInt = function SelectInt(props) {
  var min = props.min,
      max = props.max,
      step = props.step,
      suffix = props.suffix;
  var options = props.options || Range(min || 0, max + 1).filter(function (n) {
    return !step || n % step === 0;
  }).map(function (n) {
    return {
      value: n,
      label: n + ' ' + (suffix || '')
    };
  }).toArray();
  return React.createElement(Select, Object.assign({
    clearable: false
  }, props, {
    options: options
  }));
};

export default SelectInt;