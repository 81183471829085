import React from 'react';
import { connect } from 'react-redux';
import { Menu, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { url } from './routes';

class RegressionsSummary extends React.Component {
  handleSelectWorker = (workerId) => {
    const { date } = this.props;
    this.props.history.push(
      url('regressions:workers:failures', { date, workerId }),
    );
  };
  render() {
    return (
      <Menu vertical>
        {this.props.users.map((item) => {
          const id = item.get('_id') || 'Manual scrap';
          return (
            <Menu.Item
              key={id}
              name={id}
              active={this.props.active === id}
              onClick={() => this.handleSelectWorker(id)}
            >
              <Label>{item.get('count')}</Label>
              {id}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }
}
function mapStateToProps(state) {
  return {
    users: state.failuresByUser.users,
    active: state.failuresByUser.selectedWorker,
    date: state.failuresByUser.date,
  };
}

export default connect(mapStateToProps)(withRouter(RegressionsSummary));
