import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import {
  Loader,
  Button,
} from 'semantic-ui-react';
import baseUrl from '../baseUrl';

class StationJourneys extends Component {
  state = { journeys: null }
  randJourneys() {
    this.setState({
      journeys: null,
      selectedIndex: -1,
    }, async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/station/journeys/random`,
          {
            nbJourneys: 20
          }
        );
        if (data.error) {
          throw Error(data.error);
        }
        this.setState({
          journeys: data.results || [],
          selectedIndex: _.isEmpty(data.results) ? -1 : 0
        });
      } catch (e) {
        alert(e.message);
      }
    });
  }
  componentWillMount() {
    this.randJourneys();
  }
  renderJourney({ day, clientId, actions }) {
    const formatDate = (date) => {
      return new Date(date).toISOString().slice(11, 16);
    }
    return (
      <div>
        <h4>[{day}] {clientId}</h4>
        <div style={{ paddingLeft: 50 }}>
          {_.map(actions, (action, index) => {

            const timeFromPrevious = index > 0 ? (
              Math.round((new Date(action.date).getTime() - new Date(actions[index-1].date).getTime()) / 1000)
            ) : undefined;

            // const height = timeFromPrevious ? (
            //   Math.max(Math.round(timeFromPrevious / 10), 14)
            // ) : 14;
            const style = {
              color: {
                'send': 'blue',
                'linkedin': 'cyan',
                'website': 'cyan',
                'github': 'cyan',
                'skipProfile': 'red',
                'sendError': 'red',
                'new-offer': 'lime',
                'move-to-pending': 'purple',
                'move-to-contacted': 'blue',
                'move-to-hired': 'orange',
                'move-to-process': 'green',
                'send-follow-up': 'cyan',
                'rate-selection': 'purple',
                'blocked-send-follow-up': 'gray',
                'edit_criteria': 'brown',
                'answer-detected': 'green',
                'disqualifyProfile': 'orange',
              }[action.type] || 'black'
            };
                  
            return (
              <div key={index}>
                <div style={{ fontSize: 10, paddingLeft: 20 }}><i>{timeFromPrevious ? timeFromPrevious + 's' : ''}</i></div>
                <div style={style}>                
                  <div>{formatDate(action.date)} {action.type}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  render() {
    const { journeys, selectedIndex } = this.state;
    
    if (!journeys) {
      return (
        <div>
          <br/><br/><br/><br/><br/><br/><br/><br/>
          <Loader size='big' active inline='centered'/>
        </div>
      )
    }
    return (
      <div>
        <center>
          <Button color='blue' onClick={() => this.randJourneys()}>Rand</Button>
          <br/><br/>
          <Button.Group>
          {_.map(journeys, (journey, index) => (
            <Button 
              key={index} color={selectedIndex === index ? 'green': 'gray'} 
              onClick={() => { this.setState({ selectedIndex: index }) }}
            >
              {index+1}
            </Button>
          ))}
          </Button.Group>
        </center>
        <br/>
        {journeys && selectedIndex >= 0 && journeys.length > selectedIndex ? (
          this.renderJourney(journeys[selectedIndex])
        ) : ''}
      </div>
    );
  }
}

export default StationJourneys;
