import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import {
  Grid,
  Table,
  Form,
  Popup,
  Icon,
  Checkbox as SCheckbox,
} from 'semantic-ui-react';
import { SweetForm, Select, Input, Checkbox } from 'sweetform';

import baseUrl from '../baseUrl';
import Modal from '../Modal';
import Pagination from '../Pagination';

const PER_PAGE = 100;

// Actions

const SET_ACTIONS = 'SET_ACTIONS';
const setActions = (actions, page) => ({ type: SET_ACTIONS, actions, page });

const SET_ACTIONS_FILTER_OPTIONS = 'SET_ACTIONS_FILTER_OPTIONS';
const setActionsFilterOptions = (options) => ({
  type: SET_ACTIONS_FILTER_OPTIONS,
  options,
});

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_MODAL_MODE = 'SET_MODAL_MODE';
const setModalMode = (mode, initial = {}) => ({
  type: SET_MODAL_MODE,
  mode,
  initial,
});

// Reducers

const actions = (state = [], action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return action.actions;
    default:
      return state;
  }
};

const page = (state = 0, action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return action.page || 0;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const modalMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.mode;
    default:
      return state;
  }
};

const selectedProfile = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.initial;
    default:
      return state;
  }
};

const selectedAction = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL_MODE:
      return action.initial;
    default:
      return state;
  }
};

const actionsOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIONS_FILTER_OPTIONS:
      return action.options;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  actions,
  page,
  search,
  modalMode,
  selectedProfile,
  selectedAction,
  actionsOptions,
});

// Store
const store = createStore(rootReducer);

// Components

const getFormattedDate = (date) => {
  const dateObj = new Date(date);
  return date && dateObj
    ? dateObj.toDateString() + ' - ' + dateObj.toLocaleTimeString()
    : null;
};

export const typeStyles = {
  send: {
    color: 'blue',
    fontWeight: 'bold',
  },
  sendError: {
    color: 'red',
    fontWeight: 'bold',
  },
  'answer-detected': {
    color: 'green',
    fontWeight: 'bold',
  },
  'new-stripe-credit-card': {
    color: '#CCFF00',
    fontWeight: 'bold'
  },
  "remove-stripe-credit-card": {
    color: 'orange',
    fontWeight: 'bold',
  },
  'reveal-mission-profile-sourced': {
    color: 'green',
    fontWeight: 'bold',
  },
  'reveal-sequence-created': {
    color: 'purple',
    fontWeight: 'bold',
  },
  'reveal-task-completed': {
    color: 'lime',
    fontWeight: 'bold',
  },
  'create-profile': {
    color: 'orange',
    fontWeight: 'bold',
  },
};

const getPlatformBaseUrl = (clientId) =>
  `https://app.hiresweet.com/client/${clientId}`;


const getDisplayedAuthor = ({ author }) => {
  if (!author) {
    return null;
  }
  if (_.isString(author)) {
    return author;
  }
  if (_.isObject(author)) {
    if (author.firstname && author.lastname) {
      return `${author.firstname} ${author.lastname}`;
    }
    if (author.email) {
      return author.email;
    }
  }
  return null;
}

const getClientOrMissionLink = ({ clientId, jobId, missionId, input }) => {
  if (!clientId) {
    return <span>?</span>
  }
  const fallbackMissionId = missionId || jobId || (input || {}).missionId;
  if (!fallbackMissionId) {
    return (
      <a
        target="_blank"
        href={getPlatformBaseUrl(clientId)}
        rel='noopener noreferrer'
      >
        {clientId}&nbsp;
      </a>
    )
  }
  const displayMissionId = _.initial(fallbackMissionId.split("-")).join("-");
  return (
    <a
      target="_blank"
      href={`${getPlatformBaseUrl(clientId)}/reveal/missions/${fallbackMissionId}`}
      rel='noopener noreferrer'
    >
      {displayMissionId}&nbsp;
    </a>
  )
}
const Action = ({
  key,
  action,
}) => {
  const localeDate = getFormattedDate(action.date);
  const { author } = action || {};
  const displayedAuthor = getDisplayedAuthor({ author });
  const profileId = action.searchPoolProfileId || ((action.task || {}).target || {}).targetId;
  const profileData = (((action || {}).input || {}).profile || {}).data;
  const profileName = profileData && `${profileData.firstname} ${profileData.lastname}`
  return (
    <Table.Row key={key}>
      <Table.Cell>
        <span>&nbsp;{localeDate}&nbsp;</span>
      </Table.Cell>
      <Table.Cell>
        <span className="show-offer">
          {getClientOrMissionLink(action)}
          {
          ((action.task || {}).id &&
            (
              <a
                target="_blank"
                href={getPlatformBaseUrl(action.clientId) + "/reveal/tasks/focus/" + action.task.id}
                rel='noopener noreferrer'
              >
                <Icon name='tasks' />
              </a>
            )
          )
        }
        {
          (action.sequenceId &&
            (
              <a
                target="_blank"
                href={getPlatformBaseUrl(action.clientId) + "/sequences/" + action.sequenceId}
                rel='noopener noreferrer'
              >
                <Icon name='calendar' />
              </a>
            )
          )
        }
          {displayedAuthor && <span>&nbsp;({displayedAuthor})&nbsp;</span>}
        </span>
      </Table.Cell>
      <Table.Cell>
        <span style={{ ...typeStyles[action.type] }}>
          {action.type}&nbsp;
          {action.error ? (
            <span style={{ fontSize: 15 }}>
              {action.error.toLowerCase().slice(0, 32)}
            </span>
          ) : null}
          {action.senderAddress
            ? ` (${action.senderAddress.slice(0, 32)})`
            : null}
        </span>
        {
          (action.error ||
            action.reason ||
            action.target ||
            action.annotation) &&
            (`${action.error || ''} ${action.reason || ''} ${action.target || ''} ${action.annotation || ''}`).length > 32 ? (
            <Popup
              trigger={<Icon name='add' />}
              content={`${action.error || ''} ${action.reason || ''} ${action.target || ''} ${action.annotation || ''}`}
            />
          ) : null
        }
        {
          (action.type == "reveal-task-completed" && (action.task || {}).type &&
            (
              <span> ({(action.task || {}).type}) </span>
            )
          )
        }
        {
          (action.type == "reveal-mission-update-profile-stage" && (action.newStage) &&
            (
              <span> ({action.newStage}) </span>
            )
          )
        }
      </Table.Cell>
      <Table.Cell>
        {profileId ? (
          <a
            target="_blank"
            href={getPlatformBaseUrl(action.clientId) + "/reveal/search?profileId=" + profileId}
            rel='noopener noreferrer'
          >
            {profileId} <Icon name='external' />
          </a>
        ) : profileName ? (
          profileName
        ) : null}
      </Table.Cell>
    </Table.Row>
  );
};

const ActionsList = ({
  actions,
  page,
  onPage,
}) => (
  <div>
    <div style={{ position: 'relative', height: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Date</Table.HeaderCell>
            <Table.HeaderCell width={6}>Client / Mission / Sequence / Task / Recruiter</Table.HeaderCell>
            <Table.HeaderCell width={4}>Action</Table.HeaderCell>
            <Table.HeaderCell width={3}>Profile</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            _.map(actions.results, (action, key) => (
              <Action
                key={key}
                action={action}
              />
            ))
          }
        </Table.Body>
      </Table>
    </div>
    <Pagination floated='right' current={page} total={100} onPage={onPage} />
  </div>
);

class ActionsSearchForm extends Component {
  state = {};
  componentWillReceiveProps = (props) => {
    const { actionsOptions } = props;
    const actionsTypeOptions = _.map(
      actionsOptions.actionsList || ['alpha', 'beta'],
      (a) => ({ value: a, label: a }),
    );
    const clientsOptions = _.map(
      actionsOptions.clientsList,
      ({ id, name }) => ({ value: id, label: `${id} (${name})` }),
    );
    this.setState({ actionsTypeOptions, clientsOptions });
  };
  render() {
    const { onChange, search } = this.props;
    const { actionsTypeOptions, clientsOptions } = this.state;
    return (
      <SweetForm
        onChange={onChange}
        initialValues={search}
        key={JSON.stringify(search).length}
      >
        <Form>
          <Form.Field>
            <label>Types</label>
            <Select
              field="types"
              placeholder="Types"
              options={actionsTypeOptions}
              multi={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Clients</label>
            <Select
              field="clientIds"
              placeholder="Clients"
              options={clientsOptions}
              multi={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Actions comprises entre le</label>
            <Input field="minDate" type="date" placeholder="Date min" />
          </Form.Field>
          <Form.Field>
            <label>et le</label>
            <Input field="maxDate" type="date" placeholder="Date max" />
          </Form.Field>
          <Form.Field>
            <label>Exclude Hiresweet Authors</label>
            <Checkbox field="excludeHiresweetAuthors" defaultValue={true} />
          </Form.Field>
          <Form.Field>
            <label>Exclude Fake / Demo Clients</label>
            <Checkbox field="excludeFakeClientIds" defaultValue={true} />
          </Form.Field>
        </Form>
      </SweetForm>
    );
  }
}

class Actions extends Component {
  state = {};

  componentDidMount = async () => {
    const urlSearch = this.getUrlSearch();

    await this.props.onLoadFilterOptions();
    await this.props.onLoadActions({ ...urlSearch, excludeHiresweetAuthors: true, excludeFakeClientIds: true }, 0);

  };

  getUrlSearch() {
    let urlSearch = {}
    if (this.props.match.params.search) {
      try {
        urlSearch = JSON.parse(this.props.match.params.search)
      } catch (e) {
        alert('invalid url search :' + this.props.match.params.search)
      }
    }
    return urlSearch
  }

  render() {
    const {
      actions,
      page,
      search,
      actionsOptions,
      onLoadActions,
      onSearch,
    } = this.props;
    return (
      <div>
        <Grid columns={2}>
          <Grid.Column width={3}>
            <SCheckbox
              toggle
              label="See more"
              onChange={(e, { checked }) => {
                if (checked) {
                  onSearch({
                    ...search,
                    types: REVEAL_ACTION_TYPES.join(";"),
                  });
                  onLoadActions({
                    ...search,
                    types: REVEAL_ACTION_TYPES.join(";"),
                  }, 0);
                } else {
                  onSearch({ ...search, types: '' });
                  onLoadActions(
                    {
                      ...search,
                      types: '',
                    },
                    0,
                  );
                }
              }}
            />
            <ActionsSearchForm
              actionsOptions={actionsOptions}
              onChange={(s) => {
                onLoadActions(s, 0);
              }}
              search={search}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            <ActionsList
              actions={actions}
              page={page}
              onPage={(i) => {
                onLoadActions(search, i);
              }}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

// Containers
const mapSAction = (state) => ({
  page: state.page,
  search: state.search,
  actions: state.actions,
  modalMode: state.modalMode,
  selectedProfile: state.selectedProfile,
  selectedAction: state.selectedAction,
  actionsOptions: state.actionsOptions,
});

const REVEAL_ACTION_TYPES = [
  "send",
  "reveal-task-completed",
  "reveal-profile-enrolled",
  "reveal-profile-unenrolled",
  "reveal-mission-profile-sourced",
  "reveal-mission-created",
  "reveal-mission-archived",
  "reveal-mission-criteria-updated",
  "reveal-mission-updated",
  "reveal-mission-profile-removed",
  "reveal-mission-unarchived",
  "answer-detected",
  "thread-refreshed",
  "open-profile-email",
  "click-email-link",
  "reveal-task-deleted",
  "reveal-task-created",
  "send-extra-email",
  "sendError",
  "reveal-mission-update-profile-stage",
  "reveal-sequence-created",
  "reveal-sequence-edited",
  "reveal-sequence-archived",
  "reveal-sequence-restored",
  "send-email-on-behalf-of",
  "reveal-profile-recommendation-skipped",
  "update-resume-data",
  "create-profile",
  "reveal-task-updated",
  "reveal-profile-recommendation-accepted"
]
const DEFAULT_HIDDEN_ACTION_TYPES = [
  "send"
]
const mapDAction = (dispatch) => ({
  onLoadFilterOptions: async () => {
    const clients = (await axios.get(`${baseUrl}/station/clients`)).data;
    const clientsList = _.map(clients, ({ id, name }) => ({ id, name }));
    const actionsList = REVEAL_ACTION_TYPES
    dispatch(
      setActionsFilterOptions({ clientsList, actionsList }),
    );
  },
  onLoadActions: async (search = {}, page = 0) => {
    const minTimeStamp = search.minDate && new Date(search.minDate).getTime();
    const maxTimeStamp = search.maxDate && new Date(search.maxDate).getTime();
    const body = {
      onlyReveal: true,
      ...search,
      ...(minTimeStamp && { minTimeStamp }),
      ...(maxTimeStamp && { maxTimeStamp }),
      ...({ types: (search || {}).types || _.difference(REVEAL_ACTION_TYPES, DEFAULT_HIDDEN_ACTION_TYPES).join(";") })
    };
    const { data } = await axios.post(
      `${baseUrl}/station/actions?limit=${PER_PAGE}&skip=${PER_PAGE * page}`,
      {
        minTimeStamp: Date.now() - 90 * 24 * 3600 * 1000,
        ...body
      },
    );
    console.log("data", data)
    dispatch(setActions(data, page));
  },
  onSearch: (search) => dispatch(setSearch(search)),
  onCancel: () => dispatch(setModalMode('none')),
});

const ActionContainer = connect(
  mapSAction,
  mapDAction,
)(Actions);

export default (props) => (
  <Provider store={store}>
    <ActionContainer {...props} />
  </Provider>
);
