import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

const TemplateDisplay = (props) => {
  const { title, subject, body } = props;
  return (
    <Segment>
      <h3>{title}</h3>
      <h4>{subject}</h4>
      <span style={{ display: 'block', marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: body + '<br/>' }} />
    </Segment>
  );
};

const TemplateDiff = (props) => {
  const { oldTemplate, newTemplate } = props;
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <h2>Previous Template</h2>
          <TemplateDisplay {...oldTemplate} />
        </Grid.Column>
        <Grid.Column>
          <h2>New Template</h2>
          <TemplateDisplay {...newTemplate} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TemplateDiff;
