import axios from 'axios';
import React from 'react';
import baseUrl from '../../baseUrl.js';
import Selector from './Selector.js';
import MissingPatterns from './MissingPatterns.js';
import { Button } from 'semantic-ui-react';
import _ from 'underscore';

export default class MissingPatternsExplorator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      missingPatternsList: [],
      keepBacklogged: false,
      keepNonBacklogged: true,
      keepParsed: true,
      keepNonParsed: true,
      keepManuallyValidated: true,
      keepNonManuallyValidated: true,
    };
  }

  fetchLocation = async (locationId) => {
    const response = await axios.get(`${baseUrl}/locationsV2/location/${locationId}`);
    return response.data.location;
  };

  enrichMissingPatternList = async (missingPatternList) => {
    const enrichedMissingPatternList = await Promise.all(
      _.map(missingPatternList, async (missingPatterns) => ({
        ...missingPatterns,
        parseInfo: {
          ...missingPatterns.parseInfo,
          parses: await Promise.all(
            _.map((missingPatterns.parseInfo || {}).parses, async (parse) => ({
              ...parse,
              matches: await Promise.all(
                _.map(parse.matches, async (match) => ({
                  ...match,
                  weightedLocations: await Promise.all(
                    _.map(match.weightedLocations, async (weightedLocation) => ({
                      ...weightedLocation,
                      location: await this.fetchLocation(weightedLocation.location),
                    })),
                  ),
                })),
              ),
            })),
          ),
        },
      })),
    );
    return enrichedMissingPatternList;
  };

  reloadData = async () => {
    const filter = {
      keepBacklogged: this.state.keepBacklogged,
      keepNonBacklogged: this.state.keepNonBacklogged,
      keepParsed: this.state.keepParsed,
      keepNonParsed: this.state.keepNonParsed,
      keepManuallyValidated: this.state.keepManuallyValidated,
      keepNonManuallyValidated: this.state.keepNonManuallyValidated,
    };
    const response = await axios
      .post(`${baseUrl}/locationsV2/missingPatterns`, { filter })
      .catch((error) => alert(error));
    const missingPatternsList = response.data.missingPatternsList;
    const enrichedMissingPatternList = await this.enrichMissingPatternList(missingPatternsList);
    this.setState({ missingPatternsList: enrichedMissingPatternList });
  };

  async componentDidMount() {
    await this.reloadData();
  }

  handleItemSelectorClick = (index) => {
    this.setState({ index });
  };

  handleReparse = async (index) => {
    const listId = this.state.missingPatternsList[index].id;
    const response = await axios
      .put(`${baseUrl}/locationsV2/missingPatterns/${listId}/parse`)
      .catch((error) => alert(error));
    if (!response.data.ok) {
      alert(response.data.errorMessage);
    }
    await this.reloadData();
  };

  handleReload = async (index) => {
    const listId = this.state.missingPatternsList[index].id;
    const response = await axios
      .put(`${baseUrl}/locationsV2/missingPatterns/${listId}/reload`)
      .catch((error) => alert(error));
    if (!response.data.ok) {
      alert(response.data.errorMessage);
    }
    await this.reloadData();
  };

  handleKeepBackloggedClick = async () => {
    await this.setState({ keepBacklogged: !this.state.keepBacklogged });
    await this.reloadData();
  };
  handleKeepNonBackloggedClick = async () => {
    await this.setState({ keepNonBacklogged: !this.state.keepNonBacklogged });
    await this.reloadData();
  };
  handleKeepParsedClick = async () => {
    await this.setState({ keepParsed: !this.state.keepParsed });
    await this.reloadData();
  };
  handleKeepNonParsedClick = async () => {
    await this.setState({ keepNonParsed: !this.state.keepNonParsed });
    await this.reloadData();
  };
  handleKeepManuallyValidatedClick = async () => {
    await this.setState({ keepManuallyValidated: !this.state.keepManuallyValidated });
    await this.reloadData();
  };
  handleKeepNonManuallyValidatedClick = async () => {
    await this.setState({ keepNonManuallyValidated: !this.state.keepNonManuallyValidated });
    await this.reloadData();
  };

  render() {
    return (
      <div>
        <div>
          {this.state.missingPatternsList ? (
            <Selector items={this.state.missingPatternsList} handleClick={this.handleItemSelectorClick} />
          ) : null}
        </div>
        <Button toggle active={this.state.keepBacklogged} onClick={this.handleKeepBackloggedClick}>
          Backlog
        </Button>
        <Button toggle active={this.state.keepNonBacklogged} onClick={this.handleKeepNonBackloggedClick}>
          Not backlog
        </Button>
        <Button toggle active={this.state.keepParsed} onClick={this.handleKeepParsedClick}>
          Parsed
        </Button>
        <Button toggle active={this.state.keepNonParsed} onClick={this.handleKeepNonParsedClick}>
          Not parsed
        </Button>
        <Button toggle active={this.state.keepManuallyValidated} onClick={this.handleKeepManuallyValidatedClick}>
          Manually validated
        </Button>
        <Button toggle active={this.state.keepNonManuallyValidated} onClick={this.handleKeepNonManuallyValidatedClick}>
          Not manually validated
        </Button>
        <div>
          {this.state.missingPatternsList && this.state.missingPatternsList.length > 0 ? (
            <MissingPatterns
              missingPatterns={this.state.missingPatternsList[this.state.index]}
              handleReparse={() => this.handleReparse(this.state.index)}
              handleReload={() => this.handleReload(this.state.index)}
              reloadData={this.reloadData}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
