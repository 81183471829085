import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Grid, Table, Header, List } from 'semantic-ui-react';
import baseUrl from './baseUrl.js';
import COLLECTIONS from './collections';

// Actions
const SET_COLLECTION = 'SET_COLLECTION';
const setCollection = (collection) => ({ type: SET_COLLECTION, collection });

const baseResults = [
  { label: '< 1 day', key: '1' },
  { label: '1 - 5 days', key: '5' },
  { label: '5 - 15 days', key: '15' },
  { label: '15 - 30 days', key: '30' },
  { label: '30 - 60 days', key: '60' },
  { label: '> 60 days', key: '1000' },
];

// Reducers
const collection = (state = null, action) => {
  switch (action.type) {
    case SET_COLLECTION:
      const results = [];
      let accumulator = 0;
      _.each(baseResults, ({ label, key }) => {
        const qty = action.collection[key];
        accumulator += qty;
        results.push({ label, qty, accumulator });
      });

      return {
        collectionName: action.collection.collectionName,
        results,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  collection,
});

// Store
const store = createStore(rootReducer);

// Components
class Stats extends Component {
  render() {
    const { collection, setCollection } = this.props;

    return (
      <Grid>
        <Grid.Column width={4}>
          <List bulleted>
            {_.map(COLLECTIONS, (collectionName, i) => (
              <List.Item key={i}>
                {/* eslint-disable-next-line */}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setCollection(collectionName);
                  }}
                >
                  {collectionName}
                </a>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column width={12}>
          {collection ? (
            <div>
              <Header as="h2">{collection.collectionName}</Header>
              <Table basic="very" collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Last scraping</Table.HeaderCell>
                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Cumulated</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(
                    collection.results,
                    ({ label, qty, accumulator }, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{label}</Table.Cell>
                        <Table.Cell>{qty}</Table.Cell>
                        <Table.Cell>{accumulator}</Table.Cell>
                      </Table.Row>
                    ),
                  )}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <p>Select a collection</p>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers
const mapSStats = (state) => ({
  collection: state.collection,
});

const mapDStats = (dispatch) => ({
  setCollection: async (collectionName) => {
    const result = await axios.get(
      `${baseUrl}/scrapers/stats/${collectionName}`,
    );
    dispatch(setCollection({ collectionName, ...result.data }));
  },
});

const StatsContainer = connect(
  mapSStats,
  mapDStats,
)(Stats);

export default () => (
  <Provider store={store}>
    <StatsContainer />
  </Provider>
);
