import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Table } from 'semantic-ui-react';
import { Input, Range, Select, SelectInt, SweetForm, List as SweetList, customOperator, enhance } from 'sweetform';
import {
  TypologyFilter,
  ActivityFilter,
  ActionFilter,
  SelectSegmentationFields,
  wrapActionFilterToIdParams,
  wrapFilterToIdParams
} from './common';
import moment from 'moment';
import baseUrl from '../../baseUrl';

const ItemsDisplayer = ({ items, type, actionsName }) => {
  let sortedItems = _.sortBy(items, ({ count }) => -(count || 0));
  return (
    <Table basic='very'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center'>Item</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>{actionsName || '# Actions'}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(sortedItems, (item, i) => {
          const { id, count } = item;
          const link = type == "candidate" ? `/watch/candidate/${id}` : type == "offer" ? `/offers/${id}` : null
          return (
            <Table.Row key={i}>
              <Table.Cell textAlign='center'>
                {link ? (
                  <a target="_blank" href={link} rel='noopener noreferrer'>{id}</a>
                ) : id }
              </Table.Cell>
              <Table.Cell textAlign='center'>
                {count}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  )
}

export default ItemsDisplayer;