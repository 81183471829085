import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Table, Icon } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

// const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
// const setFilterOptions = (options) => ({ type: SET_FILTER_OPTIONS, options });

// const SET_SEARCH = 'SET_SEARCH';
// const setSearch = (search) => ({ type: SET_SEARCH, search });

// Reducers
const instanceData = (state = [], action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

// const filterOptions = (state = {}, action) => {
//   switch (action.type) {
//     case SET_FILTER_OPTIONS:
//       return action.options;
//     default:
//       return state;
//   }
// };

// const search = (state = {}, action) => {
//   switch (action.type) {
//     case SET_SEARCH:
//       return action.search;
//     default:
//       return state;
//   }
// };

const rootReducer = combineReducers({
  instanceData,
  // filterOptions,
  // search,
});

// Store
const store = createStore(rootReducer);

const ClientRow = ({ client, onClickRow }) => (
  <Table.Row
    onClick={onClickRow}
    {...(client.threadsData.nbThreadsNotRefreshedLastDay > 10
      ? { negative: true }
      : client.threadsData.nbThreadsNotRefreshedLastDay > 0
      ? { warning: true }
      : {})}
  >
    <Table.Cell>{client.name}</Table.Cell>
    <Table.Cell>{client.threadsData.nbThreads}</Table.Cell>
    <Table.Cell>{client.threadsData.nbThreadsLast2MonthTotal}</Table.Cell>
    <Table.Cell>{client.threadsData.nbThreadsNotRefreshedLastHour}</Table.Cell>
    <Table.Cell>
      {client.threadsData.nbThreadsNotRefreshedLast6Hours}
    </Table.Cell>
    <Table.Cell>
      {client.threadsData.nbThreadsNotRefreshedLastDay > 10 && (
        <Icon name="attention" />
      )}
      {client.threadsData.nbThreadsNotRefreshedLastDay}
    </Table.Cell>
    <Table.Cell>{client.threadsData.nbThreadsNotRefreshedLast3Days}</Table.Cell>
    <Table.Cell>{client.threadsData.nbThreadsNotRefreshedLastWeek}</Table.Cell>
    <Table.Cell
      {..._.isNumber(client.threadsData.lastWeekAnswerDetectedRate) &&
        client.threadsData.lastWeekAnswerDetectedRate < 5 && { error: true }}
    >
      {_.isNumber(client.threadsData.lastWeekAnswerDetectedRate) &&
        client.threadsData.lastWeekAnswerDetectedRate < 5 && (
          <Icon name="attention" />
        )}
      {_.isNumber(client.threadsData.lastWeekAnswerDetectedRate)
        ? `${client.threadsData.lastWeekAnswerDetectedRate}%`
        : ''}
    </Table.Cell>
  </Table.Row>
);

const MailAccountRow = ({ mailAccountThreadData, mailAccountId }) => (
  <Table.Row
    {...(mailAccountThreadData.nbThreadsNotRefreshedLastDay > 10
      ? { negative: true }
      : mailAccountThreadData.nbThreadsNotRefreshedLastDay > 0
      ? { warning: true }
      : {})}
  >
    <Table.Cell style={{ paddingLeft: '30px' }}>{mailAccountId}</Table.Cell>
    <Table.Cell>{mailAccountThreadData.nbThreads}</Table.Cell>
    <Table.Cell>{mailAccountThreadData.nbThreadsLast2MonthTotal}</Table.Cell>
    <Table.Cell>
      {mailAccountThreadData.nbThreadsNotRefreshedLastHour}
    </Table.Cell>
    <Table.Cell>
      {mailAccountThreadData.nbThreadsNotRefreshedLast6Hours}
    </Table.Cell>
    <Table.Cell>
      {mailAccountThreadData.nbThreadsNotRefreshedLastDay > 10 && (
        <Icon name="attention" />
      )}
      {mailAccountThreadData.nbThreadsNotRefreshedLastDay}
    </Table.Cell>
    <Table.Cell>
      {mailAccountThreadData.nbThreadsNotRefreshedLast3Days}
    </Table.Cell>
    <Table.Cell>
      {mailAccountThreadData.nbThreadsNotRefreshedLastWeek}
    </Table.Cell>
    <Table.Cell
      {..._.isNumber(mailAccountThreadData.lastWeekAnswerDetectedRate) &&
        mailAccountThreadData.lastWeekAnswerDetectedRate < 5 && { error: true }}
    >
      {_.isNumber(mailAccountThreadData.lastWeekAnswerDetectedRate) &&
        mailAccountThreadData.lastWeekAnswerDetectedRate < 5 && (
          <Icon name="attention" />
        )}
      {_.isNumber(mailAccountThreadData.lastWeekAnswerDetectedRate)
        ? `${mailAccountThreadData.lastWeekAnswerDetectedRate}%`
        : ''}
    </Table.Cell>
  </Table.Row>
);

class ClientRows extends Component {
  state = { open: false };

  handleClickClient = () => this.setState({ open: !this.state.open });

  render() {
    const { client } = this.props;
    return (
      <Table.Body>
        {[
          <ClientRow
            key={`client-row-${client.id}`}
            client={client}
            onClickRow={this.handleClickClient}
          />,
          ...((this.state.open &&
            _.map(
              client.threadsData.mailAccountsThreads,
              (mailAccountThreadData, mailAccountId) => (
                <MailAccountRow
                  key={`client-${client.id}-mailaccount-${mailAccountId}`}
                  mailAccountThreadData={mailAccountThreadData}
                  mailAccountId={mailAccountId}
                />
              ),
            )) || []),
        ]}
      </Table.Body>
    );
  }
}

const TableThreads = ({ clientsThreads }) => (
  <Table compact celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell rowSpan={2}>Client</Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>Nb threads</Table.HeaderCell>
        <Table.HeaderCell colSpan={5}>
          Nb threads non rafraichis
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={1}>Taux de réponse</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Total</Table.HeaderCell>
        <Table.HeaderCell>Depuis 2 mois</Table.HeaderCell>
        <Table.HeaderCell>1 heure</Table.HeaderCell>
        <Table.HeaderCell>6 heures</Table.HeaderCell>
        <Table.HeaderCell>1 jour</Table.HeaderCell>
        <Table.HeaderCell>3 jours</Table.HeaderCell>
        <Table.HeaderCell>1 semaine</Table.HeaderCell>
        <Table.HeaderCell>1 semaine</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    {_.map(clientsThreads, (client) => (
      <ClientRows key={client.id} client={client} />
    ))}
  </Table>
);

// class FormForceRefreshThreads extends Component {
//   render() {
//     const { filters, onChange, filterOptions, onRefreshThreads } = this.props;
//     const clientsOptions = _.map(filterOptions.clientsList, ({ id, name }) => ({
//       value: id,
//       label: `${id} (${name})`,
//     }));
//     return (
//       <Form style={{ width: '100%' }}>
//         <SweetForm onChange={onChange}>
//           <Form.Group widths="equal">
//             <Form.Field>
//               <label>Clients</label>
//               <Select
//                 field="selectedClientId"
//                 placeholder="Client"
//                 options={clientsOptions}
//               />
//               <Button
//                 {...filters.selectedClientId && { positive: true }}
//                 onClick={() =>
//                   filters.selectedClientId &&
//                   onRefreshThreads(filters, 'selectedClientId')
//                 }
//               >
//                 Go
//               </Button>
//             </Form.Field>
//             <Form.Field>
//               <label>Mail Account</label>
//               <Input field="selectedMailAccountId" placeholder="Mail Account" />
//               <Button
//                 {...filters.selectedMailAccountId && { positive: true }}
//                 onClick={() =>
//                   filters.selectedMailAccountId &&
//                   onRefreshThreads(filters, 'selectedMailAccountId')
//                 }
//               >
//                 Go
//               </Button>
//             </Form.Field>
//             <Form.Field>
//               <label>Profile</label>
//               <Input field="selectedProfileId" placeholder="Profile" />
//               <Button
//                 {...filters.selectedProfileId && { positive: true }}
//                 onClick={() =>
//                   filters.selectedProfileId &&
//                   onRefreshThreads(filters, 'selectedProfileId')
//                 }
//               >
//                 Go
//               </Button>
//             </Form.Field>
//             <Form.Field>
//               <label>Thread Id</label>
//               <Input field="selectedThreadId" placeholder="Thread" />
//               <Button
//                 {...filters.selectedThreadId && { positive: true }}
//                 onClick={() =>
//                   filters.selectedThreadId &&
//                   onRefreshThreads(filters, 'selectedThreadId')
//                 }
//               >
//                 Go
//               </Button>
//             </Form.Field>
//           </Form.Group>
//         </SweetForm>
//       </Form>
//     );
//   }
// }

class Threads extends Component {
  componentDidMount() {
    this.props.onLoad();
    // this.props.onLoadFilterOptions();
  }

  render() {
    const {
      instanceData,
      // filterOptions,
      // onSearch,
      // search,
      // onRefreshThreads,
    } = this.props;

    return (
      <div>
        {/* <FormForceRefreshThreads
          filterOptions={filterOptions}
          onChange={(s) => {
            onSearch(s);
          }}
          filters={search}
          onRefreshThreads={onRefreshThreads}
        /> */}
        <TableThreads clientsThreads={instanceData} />
      </div>
    );
  }
}

const mapSThreads = (state) => ({
  instanceData: state.instanceData,
  // filterOptions: state.filterOptions,
  // search: state.search,
});

const mapDThreads = (dispatch) => ({
  onLoad: async () => {
    const { data } = await axios.get(`${baseUrl}/monitoring/threadData`);
    const clientThreadsDataWithNbNotRefreshedThreads = _.sortBy(
      _.map(data.results, (client) => {
        const nbThreadsLast2Month = _.reduce(
          client.threadsData.nbThreadsLast2Month,
          (memo, mailAccountCount) => memo + mailAccountCount,
          0,
        );
        const nbThreadsRefreshedLastHour = _.reduce(
          client.threadsData.nbThreadsRefreshedLastHour,
          (memo, mailAccountCount) => memo + mailAccountCount,
          0,
        );
        const nbThreadsRefreshedLast6Hours = _.reduce(
          client.threadsData.nbThreadsRefreshedLast6Hours,
          (memo, mailAccountCount) => memo + mailAccountCount,
          0,
        );
        const nbThreadsRefreshedLastDay = _.reduce(
          client.threadsData.nbThreadsRefreshedLastDay,
          (memo, mailAccountCount) => memo + mailAccountCount,
          0,
        );
        const nbThreadsRefreshedLast3Days = _.reduce(
          client.threadsData.nbThreadsRefreshedLast3Days,
          (memo, mailAccountCount) => memo + mailAccountCount,
          0,
        );
        const nbThreadsRefreshedLastWeek = _.reduce(
          client.threadsData.nbThreadsRefreshedLastWeek,
          (memo, mailAccountCount) => memo + mailAccountCount,
          0,
        );
        const mailAccountsThreadsData = _.reduce(
          [
            'nbThreadsLast2Month',
            'nbThreadsRefreshedLastHour',
            'nbThreadsRefreshedLast6Hours',
            'nbThreadsRefreshedLastDay',
            'nbThreadsRefreshedLast3Days',
            'nbThreadsRefreshedLastWeek',
          ],
          (memo, key) => {
            const mailAccounts = (client.threadsData || {})[key];
            _.each(mailAccounts, (mailAccountCount, mailAccountId) => {
              memo[mailAccountId]
                ? (memo[mailAccountId][key] = mailAccountCount)
                : (memo[mailAccountId] = { key: mailAccountCount });
            });
            return memo;
          },
          {},
        );
        // (client.threadsData.nbThreadsLast2Month[mailAccountId] || 0) - mailAccountCount
        const mailAccountsThreads = _.mapObject(
          mailAccountsThreadsData,
          (mailAccountThreads, mailAccountId) => {
            const nbThreadsLast2MonthTotal = _.reduce(
              client.threadsData.nbThreadsLast2Month,
              (memo, mailAccountCount, id) =>
                id === mailAccountId ? memo + mailAccountCount : memo,
              0,
            );
            return {
              ...mailAccountThreads,
              nbThreadsLast2MonthTotal,
              nbThreadsNotRefreshedLastHour:
                (nbThreadsLast2MonthTotal || 0) -
                (mailAccountThreads.nbThreadsRefreshedLastHour || 0),
              nbThreadsNotRefreshedLast6Hours:
                (nbThreadsLast2MonthTotal || 0) -
                (mailAccountThreads.nbThreadsRefreshedLast6Hours || 0),
              nbThreadsNotRefreshedLastDay:
                (nbThreadsLast2MonthTotal || 0) -
                (mailAccountThreads.nbThreadsRefreshedLastDay || 0),
              nbThreadsNotRefreshedLast3Days:
                (nbThreadsLast2MonthTotal || 0) -
                (mailAccountThreads.nbThreadsRefreshedLast3Days || 0),
              nbThreadsNotRefreshedLastWeek:
                (nbThreadsLast2MonthTotal || 0) -
                (mailAccountThreads.nbThreadsRefreshedLastWeek || 0),
            };
          },
        );
        return {
          ...client,
          threadsData: {
            ...client.threadsData,
            nbThreadsLast2MonthTotal: nbThreadsLast2Month,
            nbThreadsNotRefreshedLastHour:
              (nbThreadsLast2Month || 0) - (nbThreadsRefreshedLastHour || 0),
            nbThreadsNotRefreshedLast6Hours:
              (nbThreadsLast2Month || 0) - (nbThreadsRefreshedLast6Hours || 0),
            nbThreadsNotRefreshedLastDay:
              (nbThreadsLast2Month || 0) - (nbThreadsRefreshedLastDay || 0),
            nbThreadsNotRefreshedLast3Days:
              (nbThreadsLast2Month || 0) - (nbThreadsRefreshedLast3Days || 0),
            nbThreadsNotRefreshedLastWeek:
              (nbThreadsLast2Month || 0) - (nbThreadsRefreshedLastWeek || 0),
            mailAccountsThreads,
          },
        };
      }),
      (client) => client.threadsData.nbThreadsNotRefreshedLastDay * -1,
    );
    dispatch(setInstanceData(clientThreadsDataWithNbNotRefreshedThreads));
  },
  // onLoadFilterOptions: async () => {
  //   const clients = (await axios.get(`${baseUrl}/station/clients`)).data;
  //   const clientsList = _.map(clients, ({ id, name }) => ({ id, name }));
  //   dispatch(setFilterOptions({ clientsList }));
  // },
  // onSearch: (search) => dispatch(setSearch(search)),
  // onRefreshThreads: async (search, field) => {
  //   await axios.post(`${baseUrl}/threads/refresh`, { search, field });
  //   dispatch(setSearch({ ...search, [field]: null }));
  // },
});

const ThreadsContainer = connect(
  mapSThreads,
  mapDThreads,
)(Threads);

export default () => (
  <Provider store={store}>
    <ThreadsContainer />
  </Provider>
);
