import _ from 'underscore';

export const normalizeLinkedin = ({ linkedin }) => {
  if (!linkedin) {
    return null;
  }
  const decodedLinkedin = decodeURIComponent(linkedin);
  const prefix = 'linkedin.com/in/';
  const iCut = decodedLinkedin.indexOf(prefix);
  const linkedinWithoutPrefix = iCut >= 0 ? decodedLinkedin.substr(iCut + prefix.length) : decodedLinkedin;
  const jCut = linkedinWithoutPrefix.indexOf('/');
  const linkedinWithoutSlash = jCut >= 0 ? linkedinWithoutPrefix.substr(0, jCut) : linkedinWithoutPrefix;
  const kCut = linkedinWithoutSlash.indexOf('?');
  const linkedinWithoutQueryParam = kCut >= 0 ? linkedinWithoutSlash.substr(0, kCut) : linkedinWithoutSlash;
  return linkedinWithoutQueryParam;
};

export const normalizeGithub = (github) => {
  if (!github) {
    return '';
  }
  let result = decodeURIComponent(github).toLowerCase();
  if (result.indexOf('?') >= 0) {
    result = result.slice(0, result.indexOf('?'));
  }
  result = result.replace('https://github.com/', '');
  result = result.replace('http://github.com/', '');
  result = result.replace('http://www.github.com/', '');
  result = result.replace('www.github.com/', '');
  result = result.replace('github.com/', '');
  if (result.length > 1 && result.indexOf('/') < 0) {
    return result;
  }
  return '';
};

export const normalizeStackoverflow = (stackoverflow) => {
  if (!stackoverflow) {
    return '';
  }
  let result = decodeURIComponent(stackoverflow).toLowerCase();
  if (result.indexOf('?') >= 0) {
    result = result.slice(0, result.indexOf('?'));
  }
  result = result.replace('https://stackoverflow.com/users/', '');
  result = result.replace('http://stackoverflow.com/users/', '');
  result = result.replace('http://www.stackoverflow.com/users/', '');
  result = result.replace('www.stackoverflow.com/users/', '');
  result = result.replace('stackoverflow.com/users', '');
  if (result.indexOf('/') >= 0) {
    result = result.slice(0, result.indexOf('/'));
  }
  if (result.length > 1 && result.indexOf('/') < 0) {
    try {
      let id = parseInt(result);
      if (!_.isNumber(id) || _.isNaN(id)) {
        throw Error('so parse');
      }
      return id;
    } catch (e) {
      //console.log('SO_ERROR: ', stackoverflow);
    }
  }
  return '';
};

export const getIdFieldsFromSources = (sources) => {
  return {
    ...(sources && sources.linkedin && { linkedin: normalizeLinkedin({ linkedin: sources.linkedin }) }),
    ...(sources && sources.github && { github: normalizeGithub(sources.github) }),
    ...(sources && sources.stackoverflow && { stackoverflow: normalizeStackoverflow(sources.stackoverflow) }),
  };
};

export const GRADES = [
  { value: 'A', color: 'green' },
  { value: 'B', color: 'olive' },
  { value: 'C', color: 'yellow' },
  { value: 'D', color: 'orange' },
  { value: 'E', color: 'red' },
]

export const MATCHING_LEVELS = [
  { value: 'normal', color: 'olive', number: 4},
  { value: 'strong', color: 'green', number: 5},
  { value: 'very-strong', color: 'teal', number: 6},
]
