import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Dropdown, Table, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

const getDays = () => {
  const date = new Date();
  const lastMidnightTimestamp = date.setHours(3, 0, 0, 0);
  let days = [];
  let nbDays = 200;
  const iWeekDay = date.getDay();
  for (let iDay = 0; iDay < nbDays; iDay++) {
    const timestamp = lastMidnightTimestamp - iDay * 24 * 3600 * 1000;
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    days.push({
      timestamp,
      date: new Date(timestamp).toISOString().slice(2, 10),
      weekDay: weekDays[(iWeekDay - (iDay % 7) + 7) % 7],
    });
  }
  return days;
};

const getNbWorkingDays = ({ days, minDate, maxDate }) => {
  let result = 0;
  _.each(days, ({ date, weekDay }) => {
    if (
      date >= minDate &&
      date <= maxDate &&
      weekDay !== 'Sun' &&
      weekDay !== 'Sat'
    ) {
      result++;
    }
  });
  return result;
};

class OpsRangeSends extends Component {
  days = getDays(200);
  state = {
    minDay: this.days[6],
    maxDay: this.days[0],
  };
  componentWillMount() {
    this.handleReloadQuery();
  }
  handleReloadQuery = async () => {
    this.setState({
      totalFlow: null,
      opsFlows: null,
      offerFlows: null,
    });
    const query = {
      target: {
        type: 'sweetapp-send',
        minTimestamp: this.state.minDay.timestamp,
        maxTimestamp: this.state.maxDay.timestamp + 24 * 3600 * 1000,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/strat/synthetic/cashflow`,
      query,
    );
    this.setState(data);
  };
  renderDaySelector(key) {
    const options = _.map(this.days, (day, index) => ({
      key: index,
      text: day.weekDay + '. ' + day.date,
      value: day.date,
    }));

    const handleChange = (e, { value }) => {
      const day = _.findWhere(this.days, { date: value });
      this.setState(
        {
          [key]: day,
        },
        () => {
          this.handleReloadQuery();
        },
      );
    };

    return (
      <Dropdown
        onChange={handleChange}
        options={options}
        placeholder="Choose an option"
        selection
        value={this.state[key].date}
      />
    );
  }
  renderDaysSelector() {
    return (
      <div>
        <span>
          <b>Start</b>: {this.renderDaySelector('minDay')}{' '}
        </span>
        <span style={{ marginLeft: 20 }}>
          <b>End</b>: {this.renderDaySelector('maxDay')}
        </span>
      </div>
    );
  }
  renderOverview() {
    const { minDay, maxDay, offerFlows } = this.state;
    if (!offerFlows) {
      return;
    }
    const nbWorkingDays = getNbWorkingDays({
      minDate: minDay.date,
      maxDate: maxDay.date,
      days: this.days,
    });

    const getStats = (flows) => {
      const result = {
        nbSendingClients: 0,
        nbSendingOffers: 0,
        nbSends: 0,
      };
      const seenClients = {};
      const seenOffers = {};
      _.each(flows, ({ count, offerId, clientId }) => {
        result.nbSends += count;
        if (count >= 0 && offerId && !seenOffers[offerId]) {
          seenOffers[offerId] = true;
          result.nbSendingOffers++;
        }
        if (count >= 0 && clientId && !seenClients[clientId]) {
          seenClients[clientId] = true;
          result.nbSendingClients++;
        }
      });
      return result;
    };

    const opsOwners = _.uniq(_.pluck(offerFlows, 'opsOwner'));
    let opsStats = [];
    _.each(opsOwners, (opsOwner) => {
      opsStats.push({
        opsOwner,
        ...getStats(
          _.filter(offerFlows, (offerFlow) => offerFlow.opsOwner === opsOwner),
        ),
      });
    });
    opsStats = _.sortBy(
      opsStats,
      ({ opsOwner, nbSends }) =>
        (opsOwner !== 'undefined' ? opsOwner : 'zzz') +
        '#' +
        (1000 * 1000 * 1000 - nbSends),
    );

    const totalStats = getStats(offerFlows);

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Overview (from {minDay.date} morning to {maxDay.date} evening)
          </Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              <Table.HeaderCell>#Sending Clients</Table.HeaderCell>
              <Table.HeaderCell>#Sending Offers</Table.HeaderCell>
              <Table.HeaderCell>#Sends</Table.HeaderCell>
              <Table.HeaderCell>Daily Sends</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>total</Table.Cell>
                <Table.Cell>{totalStats.nbSendingClients}</Table.Cell>
                <Table.Cell>{totalStats.nbSendingOffers}</Table.Cell>
                <Table.Cell>{totalStats.nbSends || 0}</Table.Cell>
                <Table.Cell>
                  {Math.round((totalStats.nbSends || 0) / (nbWorkingDays || 1))}
                </Table.Cell>
              </Table.Row>
              {_.map(
                opsStats,
                (
                  { opsOwner, nbSendingClients, nbSendingOffers, nbSends },
                  index,
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>{opsOwner}</Table.Cell>
                    <Table.Cell>{nbSendingClients}</Table.Cell>
                    <Table.Cell>{nbSendingOffers}</Table.Cell>
                    <Table.Cell>{nbSends || 0}</Table.Cell>
                    <Table.Cell>
                      {Math.round((nbSends || 0) / (nbWorkingDays || 1))}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  renderOpsOwnerOffers(opsOwner) {
    const { minDay, maxDay } = this.state;
    if (!this.state.offerFlows) {
      return;
    }

    let opsFlow = _.where(this.state.offerFlows, { opsOwner });

    let amountByClient = {};
    _.each(opsFlow, ({ clientId, count }) => {
      if (clientId) {
        amountByClient[clientId] = (amountByClient[clientId] || 0) + count;
      }
    });

    opsFlow = _.sortBy(opsFlow, ({ clientId }) => clientId);
    opsFlow = _.sortBy(
      opsFlow,
      ({ count, clientId }) =>
        -(amountByClient[clientId] || 0) - count / 1000000,
    );

    const nbWorkingDays = getNbWorkingDays({
      minDate: minDay.date,
      maxDate: maxDay.date,
      days: this.days,
    });

    const capitalize = (str) => {
      if (str.length < 1) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.substr(1);
    };

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{capitalize(opsOwner)}</Card.Header>
          <Table definition>
            <Table.Header>
              <Table.HeaderCell />
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Sweethub Id</Table.HeaderCell>
              <Table.HeaderCell>Sweetapp App</Table.HeaderCell>
              <Table.HeaderCell>#Sends</Table.HeaderCell>
              <Table.HeaderCell>#Daily</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(
                opsFlow,
                (
                  {
                    opsOwner,
                    clientId,
                    categoryId,
                    offerId,
                    title,
                    sweetappOfferId,
                    count,
                    baseValue,
                    value,
                  },
                  index,
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>{clientId}</Table.Cell>
                    <Table.Cell>{categoryId}</Table.Cell>
                    <Table.Cell>
                      <a target="_blank" href={'/offers/' + offerId} rel='noopener noreferrer'>
                        {offerId}
                      </a>
                    </Table.Cell>
                    <Table.Cell>
                      <span> {sweetappOfferId}</span>
                    </Table.Cell>
                    <Table.Cell>{count || 0}</Table.Cell>
                    <Table.Cell>
                      {Math.round((count || 0) / (nbWorkingDays || 1))}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    const offerFlows = this.state.offerFlows || [];
    const opsOwners = _.sortBy(
      _.uniq(_.pluck(offerFlows, 'opsOwner')),
      (opsOwner) => (opsOwner !== 'undefined' ? opsOwner : 'zzz'),
    );

    return (
      <div>
        {this.renderDaysSelector()}
        {this.renderOverview()}
        {_.map(opsOwners, (opsOwner, index) => (
          <div key={index} style={{ marginTop: 20, marginBottom: 20 }}>
            {this.renderOpsOwnerOffers(opsOwner)}
          </div>
        ))}
      </div>
    );
  }
}

export default OpsRangeSends;
