import React from 'react';
import _ from 'underscore';
import { Segment, Item, Divider, Feed } from 'semantic-ui-react';

import { Headline } from './Headline';
import { Experience } from './Experience';
import { Education } from './Education';
import { Endorsments } from './Endorsments';

export const EnrichedLinkedinProfile = ({ source, noReverseXp }) => {
  const { id, data } = source || {};

  const { experiences, education, enriched, skills, location } = data || {};
  var reversedExperiences = experiences;
  var reversedEducation = education;
  if (!noReverseXp) {
    reversedExperiences = experiences ? experiences.slice().reverse() : [];
    reversedEducation = education ? education.slice().reverse() : [];
  }

  const endorsements =
    (enriched || {}).endorsements ||
    _.map(skills, (item) => ({ identifier: item.tag, num: item.num }));
  const firstEducation = _.first(reversedEducation) || {};

  return (
    <div>
      <Segment color="blue">
        <Headline data={data || {}} id={id} firstEducation={firstEducation} linkedinLocation={location} />

        <Divider horizontal>Experiences</Divider>

        <Item.Group divided>
          {_.map(reversedExperiences, (exp, i) => (
            <Experience i={i} exp={exp} key={i} />
          ))}
        </Item.Group>

        <Divider horizontal>Education</Divider>

        <Feed>
          {_.map(reversedEducation, (edu, i) => (
            <Education edu={edu} key={i} />
          ))}
        </Feed>

        <Divider horizontal>Endorsements Parsed</Divider>

        <Endorsments endorsements={endorsements} />
      </Segment>
    </div>
  );
};
