import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Grid, Button, Select, Modal, Icon } from 'semantic-ui-react';
import MatchList from './MatchList';
import Annotation from './Annotation';
import ScoreDetails from './ScoreDetails';
import Actions from './Actions';
import SourcesView from './SourcesView';
import baseUrl from '../baseUrl.js';
import HiresweetOffer from '../components/HiresweetOffer';

const actionsContainerStyle = {
  position: 'fixed',
  marginLeft: '30vw',
  bottom: '5vh',
  zIndex: '1',
  textAlign: 'center',
};

class MatchingView extends Component {
  getLinkedinData = async ({ linkedinId }) => {
    return null;
  };

  getGithubData = async ({ githubId }) => {
    return null;
  };

  getStackoverflowData = async ({ stackoverflowId }) => {
    return null;
  };

  setSortType = (sortType) => {
    this.setState({ sortType });
  };

  selectMatchingId = async ({ matchingId }) => {
    await this.setSelectedMatch({});
    const { matches, matchingInfo } = (await axios.post(`${baseUrl}/maxMatching/getMatching`, { id: matchingId })).data;
    this.setState({
      selectedMatchingId: matchingId,
      matchingInfo: matchingInfo,
      matches: matches,
    });
  };

  selectNextMatch = async (indexIncrease) => {
    const { idFields, offer, sortType, matches } = this.state;
    const sortedMatches = this.sortSlicesMatches(matches, sortType);
    const selectedIndex =
      idFields && offer
        ? _.findIndex(sortedMatches, (match) => {
            return match.offer.id === offer.id && match.profile.idFields.linkedin === idFields.linkedin;
          })
        : -1;
    const nextMatchIndex = selectedIndex + indexIncrease;
    if (nextMatchIndex >= 0 && nextMatchIndex < sortedMatches.length) {
      const nextMatch = sortedMatches[nextMatchIndex];
      await this.setSelectedMatch({ match: nextMatch });
    } else {
      console.log('Index not in matches array', nextMatchIndex);
    }
  };

  setSelectedMatch = async ({ match }) => {
    this.setState({
      offer: null,
      profile: null,
      scoreDetails: null,
      idFields: null,
      enrichedProfile: null,
      githubProfile: null,
      stackoverflowProfile: null,
    });
    if (match) {
      const offerId = (match.offer || {}).id;
      console.log('offer...');
      const offer = await this.getOfferData({ offerId });
      const linkedinId = ((match.profile || {}).idFields || {}).linkedin;
      const githubId = ((match.profile || {}).idFields || {}).github;
      const stackoverflowId = ((match.profile || {}).idFields || {}).stackoverflow;
      const profile = await this.getLinkedinData({ linkedinId });
      console.log('github...');
      const githubProfile = await this.getGithubData({ githubId });
      const stackoverflowProfile = await this.getStackoverflowData({ stackoverflowId });
      const parsedProfile = await this.enrichProfileWithParse(profile);
      const enrichedProfile = this.state.enableUpload
        ? await this.getEnrichedProfile({
            idFields: match.profile.idFields,
            sourceProfiles: {
              linkedin: profile,
              ...(githubProfile && { github: githubProfile }),
              ...(stackoverflowProfile && { stackoverflow: stackoverflowProfile }),
            },
            jobOfferId: offerId,
          })
        : null;
      console.log('enrichedProfile', enrichedProfile);
      this.setState({
        ...this.state,
        offer: offer,
        profile: parsedProfile,
        scoreDetails: match.details,
        idFields: (match.profile || {}).idFields,
        githubProfile,
        stackoverflowProfile,
        enrichedProfile,
        push: match.push,
        disqualify: match.disqualify,
      });
    }
  };

  sortSlicesMatches = (matches, sortType) => {
    if (sortType === 'twistedError') {
      return _.sortBy(matches, (match) => {
        return -((match.details || {}).twisted_error || 0);
      });
    }
    if (sortType === 'score') {
      return _.sortBy(matches, (match) => {
        return -match.score || 0;
      });
    }
    const getItemId = (match, sortType) => {
      if (sortType === 'profile') {
        return ((match.profile || {}).idFields || {}).linkedin;
      } else {
        return (match.offer || {}).id;
      }
    };
    const itemGroups = _.groupBy(matches, (match) => getItemId(match, sortType));
    const sortedItemIds = _.sortBy(
      _.map(itemGroups, (group, itemId) => ({
        group: _.sortBy(group, (match) => -match.score),
        itemId: itemId,
        score: _.reduce(group, (acc, currentValue) => acc + currentValue.score / (group.length || 1), 0),
      })),
      (item) => -item.score,
    );
    return _.reduce(sortedItemIds, (acc, currentValue) => acc.concat(currentValue.group), []);
  };

  parseLinkedinProfile = async () => {
    const { linkedinProfile } = this.props;
    console.log('{linkedinProfile}');
    console.log({ linkedinProfile });
    const parsedlinkedinProfile = await this.enrichProfileWithParse(linkedinProfile);
    this.setState({ linkedinProfile: parsedlinkedinProfile });
  };
  getEntities = async (text) => {
    try {
      const parsing = (await axios.post(`${baseUrl}/rayonX/getParsing`, { text: text })).data;
      return parsing;
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  enrichTextFromEntities = (text, entities) => {
    let i = 0;
    let enrichedText = [];
    let count = 0;
    for (let iEntity = 0; iEntity < entities.length; iEntity++) {
      let entity = entities[iEntity];
      let start = entity.position[0];
      let stop = entity.position[1];
      enrichedText.push(text.slice(i, start));
      enrichedText.push(
        <div className='tooltip' key={count}>
          {text.slice(start, stop)}
          <span className='tooltiptext'>
            <b>id: </b>
            {entity.tag}
            <br />
            <b>types: </b>
            {entity.types.join(', ')}
          </span>
        </div>,
      );
      count += 1;
      i = stop;
    }
    enrichedText.push(text.slice(i));
    return enrichedText;
  };

  getEnrichedText = async (text) => {
    const { entities } = await this.getEntities(text);
    if (entities && entities.length > 0) {
      const textWithParsing = this.enrichTextFromEntities(text, entities);
      return textWithParsing;
    } else {
      return text;
    }
  };

  enrichProfileWithParse = async (linkedinProfile) => {
    let dataWithParse = JSON.parse(JSON.stringify(linkedinProfile.data));
    dataWithParse.summary = await this.getEnrichedText(dataWithParse.summary);
    dataWithParse.headline = await this.getEnrichedText(dataWithParse.headline);
    let experiences = dataWithParse.experiences;
    for (let iXp = 0; iXp < experiences.length; iXp++) {
      let experience = experiences[iXp];
      experience.experienceName = experience.experienceName && (await this.getEnrichedText(experience.experienceName));
      experience.descriptionHTML =
        experience.descriptionHTML && (await this.getEnrichedText(experience.descriptionHTML));
    }
    linkedinProfile.data = dataWithParse;
    return linkedinProfile;
  };

  renderMatchingOptions({ matchingOptions }) {
    const options = _.map(_.sortBy(matchingOptions, (m) => m.date).reverse(), (matching) => ({
      key: matching.id,
      text: `${matching.name}`,
      value: matching.id,
    }));
    return (
      <Select
        placeholder='Select matching'
        options={options}
        onChange={(e, data) => this.selectMatchingId({ matchingId: data.value })}
      />
    );
  }

  renderActionButtons({ idFields, offer, matchingInfo, buttonsList }) {
    return (
      <Annotation
        idFields={idFields}
        offer={offer}
        selectNextMatch={this.selectNextMatch}
        matchingId={matchingInfo.id}
        buttonsList={buttonsList}
      />
    );
  }

  getEnrichedProfile = async ({ idFields, sourceProfiles, jobOfferId }) => {
    try {
      const { data } = await axios.post(`${baseUrl}/sweetwork2/item`, {
        idFields,
        sourceProfiles,
        jobOfferId,
      });
      return data;
    } catch (e) {
      return alert('Error getting enriched profile.');
    }
  };
  handleDisqualifySelectedMatch = async ({ reason }) => {
    try {
      const { idFields, offer, selectedMatchingId } = this.state;

      const { data } = await axios.post(`${baseUrl}/maxMatching/disqualifyMatch`, {
        idFields,
        offerId: offer.id,
        matchingId: selectedMatchingId,
        reason,
      });
      if (!data.success) {
        throw Error(data.error);
      }
      const { disqualify } = data;
      const newMatches = _.map(this.state.matches, (match) => {
        if (match.offer.id === offer.id && match.profile.idFields.linkedin === idFields.linkedin) {
          return {
            ...match,
            disqualify: disqualify,
          };
        }
        return match;
      });
      this.setState({
        disqualify,
        matches: newMatches,
      });
    } catch (e) {
      alert(JSON.stringify(e.message));
    }
  };

  handlePushProfileToSweetSheet = async () => {
    try {
      const { enrichedProfile, idFields, offer, selectedMatchingId } = this.state;
      if (!enrichedProfile.email) {
        throw Error('Cannot upload without a selected email! Please select an email.');
      } else if (
        _.findWhere(enrichedProfile.emailHints, {
          email: enrichedProfile.email,
          bouncing: true,
        })
      ) {
        throw Error('The selected email is a bounced email! Please select another one.');
      }

      const formattedItem = {
        id: enrichedProfile.id,
        idFields: enrichedProfile.idFields,
        data: enrichedProfile,
        annotations: [...(enrichedProfile.annotations || [])],
      };

      const { data } = await axios.post(`${baseUrl}/maxMatching/uploadProfileToOfferSweetsheet`, {
        item: formattedItem,
        idFields,
        offerId: offer.id,
        matchingId: selectedMatchingId,
      });
      if (!data.success) {
        console.log('no success');
        console.log(data);
        throw Error(data.error);
      }
      const { push } = data;
      const newMatches = _.map(this.state.matches, (match) => {
        if (match.offer.id === offer.id && match.profile.idFields.linkedin === idFields.linkedin) {
          return {
            ...match,
            push: push,
          };
        }
        return match;
      });
      this.setState({
        push,
        matches: newMatches,
      });
    } catch (e) {
      console.log('catch');
      console.log(e);
      alert(JSON.stringify(e.message));
    }
  };

  changeEnrichedProfileEmail = (value) => {
    const { enrichedProfile } = this.state || {};
    this.setState({
      enrichedProfile: {
        ...enrichedProfile,
        email: value,
      },
    });
  };

  render() {
    const {
      offer,
      profile,
      scoreDetails,
      idFields,
      sortType,
      //defaultSortType,
      matches,
      matchingOptions,
      //selectedMatchingId,
      matchingInfo,
      detailsMode,
      matchingInfoMode,
      disableSortOptions,
      displayAnnotation,
      buttonsList,
      enableUpload,
      githubProfile,
      stackoverflowProfile,
      enrichedProfile,
      push,
      disqualify,
      enableAnnotations,
    } = this.state;

    console.log('githubProfile', githubProfile);
    const sortedMatches = this.sortSlicesMatches(matches, sortType);
    const watchId = (idFields || {}).watch;
    //const linkedin = (idFields || {}).linkedin
    //const offerId = (offer || {}).id
    //const currentItemFrontId = linkedin
    return (
      <div>
        <div className='actionsContainer' style={actionsContainerStyle}>
          {enableAnnotations && idFields && offer && matchingInfo && buttonsList
            ? this.renderActionButtons({ idFields, offer, matchingInfo, buttonsList })
            : null}
        </div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}>
              <Grid.Row>
                {matchingInfo ? (
                  <Modal
                    trigger={
                      <Button>
                        <Icon name='info' />
                      </Button>
                    }
                  >
                    {this.renderMatchingInfo({ matchingInfo, matchingInfoMode })}
                  </Modal>
                ) : null}
                {matchingOptions ? this.renderMatchingOptions({ matchingOptions }) : null}
              </Grid.Row>
              {matches ? (
                <MatchList
                  disableSortOptions={disableSortOptions}
                  matches={sortedMatches}
                  sortType={sortType}
                  setSortType={this.setSortType}
                  setSelectedMatch={(match) => this.setSelectedMatch({ match })}
                  selectedOffer={offer}
                  selectedProfile={profile}
                  displayAnnotation={displayAnnotation}
                />
              ) : null}
            </Grid.Column>
            <Grid.Column width={7} style={{ overflowY: 'auto', overflowX: 'auto', height: '85vh' }}>
              {profile || watchId || githubProfile || stackoverflowProfile ? (
                <SourcesView
                  linkedinProfile={profile}
                  watchId={watchId}
                  githubProfile={githubProfile}
                  stackoverflowProfile={stackoverflowProfile}
                  enrichedProfile={enrichedProfile}
                />
              ) : null}
            </Grid.Column>
            <Grid.Column width={7} style={{ overflowY: 'auto', overflowX: 'auto', height: '85vh' }}>
              {enableUpload && enrichedProfile && offer && idFields ? (
                <Actions
                  enrichedProfile={enrichedProfile}
                  handlePushProfileToSweetSheet={this.handlePushProfileToSweetSheet}
                  handleDisqualifySelectedMatch={this.handleDisqualifySelectedMatch}
                  changeEmail={this.changeEnrichedProfileEmail}
                  push={push}
                  disqualify={disqualify}
                />
              ) : null}
              {scoreDetails ? (
                <ScoreDetails
                  detailsMode={detailsMode}
                  details={scoreDetails}
                  profile={{ idFields: idFields }}
                  offer={offer}
                />
              ) : null}
              {offer ? <HiresweetOffer hiresweetOffer={offer} /> : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default MatchingView;
