import _ from 'underscore';
import React, { Component } from 'react';
import { Grid, Button, Form, Input, TextArea } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import loadGithubFromLogin from './loadGithubProfile';

class GithubProfileView extends Component {
  render() {
    const { data } = this.props;
    return (
      <div>
        <h4>No display implemented yet</h4>
        <h5>{(data || {}).login}</h5>
      </div>
    );
  }
}

const githubStringify = ({ githubData }) => {
  const { 
    id,
    login,
    lowercase_login,
    date,
    createdAt,
    updatedAt,
    headline,
    bio,
    photoLink,
    location,
    type,
    company,
    blog,
    email,
    repos
  } = githubData;

  return JSON.stringify(_.omit({
    ...id && { id },
    ...login && { login },
    ...lowercase_login && { lowercase_login },
    ...date && { date },
    ...createdAt && { createdAt },
    ...updatedAt && { updatedAt },
    ...headline && { headline },
    ...bio && { bio },
    ...photoLink && { photoLink },
    ...location && { location },
    ...type && { type },
    ...company && { company },
    ...blog && { blog },
    ...email && { email },
    ...repos && { repos },
    ...githubData
  }, 'fullname'), null, 4);
}

class GithubMultiEditor extends Component {

    state = {
          jsonStr: '',
          jsonIsCorrect: true,
          githubLogin: '',
        }

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.state.jsonStr = githubStringify({ githubData: props.initialValue });
    }
  }

  handleChangeGithubLogin = ({ githubLogin }) => {
    this.setState({ githubLogin });
  }

  handleChangeJsonStr = ({ jsonStr }) => {    
    let data = null;
    let jsonIsCorrect = true;
    try {
      data = JSON.parse(jsonStr);
    } catch (e) {
      jsonIsCorrect = false;
    }
    this.setState({ 
      jsonStr,
      jsonIsCorrect
    });
    if (jsonIsCorrect) {
      this.props.onUpdate({ data });
    }
  }

  handleLoadGithubByLogin = async ({ githubLogin }) => {
    const data = await loadGithubFromLogin({ login: githubLogin });
    if (!data) {
      return alert('not found');
    }
    this.setState({
      jsonStr:  githubStringify({ githubData: data })
    });
    this.props.onUpdate({ data });
  }

  renderJsonEditor() {
    return (
      <Form>
        <TextArea 
          style={{ borderColor: this.state.jsonIsCorrect ? 'lime' : 'orange' }}
          value={this.state.jsonStr} 
          rows="36" 
          onChange={({ target }) => { 
            this.handleChangeJsonStr({ jsonStr: target.value });
          }}
        />
      </Form>
    )
  }

  renderImportEditor() {
    const { githubLogin } = this.state;
    const isButtonDisabled = _.isEmpty(githubLogin) || githubLogin.indexOf(' ') >= 0;
    return (
      <div>
        <Input type="text" value={this.state.value} onChange={({ target }) => { 
          this.handleChangeGithubLogin({ githubLogin: target.value })
        }} />
        <Button disabled={isButtonDisabled} color="blue" onClick={() => {
          this.handleLoadGithubByLogin({ githubLogin })
        }}>Load</Button>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Tabs defaultIndex={this.state.jsonStr ? 0 : 1}>
          <TabList>
            <Tab>JSON</Tab>
            <Tab>Import</Tab>
          </TabList>
          <TabPanel>
            {this.renderJsonEditor()}
          </TabPanel>
          <TabPanel>
            {this.renderImportEditor()}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}



class GithubEditor extends Component {

  state = { data: {} }

  constructor(props) {
    super(props);
    this.state.data = props.initialValue || {};
  }

  handleUpdate = ({ data }) => {
    this.setState({ data });
    this.props.onUpdate({ data });
  }

  render() {
    const { initialValue } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <GithubMultiEditor onUpdate={this.handleUpdate} initialValue={initialValue} />
          </Grid.Column>
          <Grid.Column width={8}> 
            <GithubProfileView data={this.state.data} isV4={this.props.isV4} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default GithubEditor;
