import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';
import ProfilesPipeManager from './ProfilesPipeManager.js';

const ismaSanitize = (profileData) => {
  const cleanIndustry = (industry) => {
    if (!industry.name) {
      return industry;
    }
    if (industry.name.en && !industry.name.default) {
      return {
        ...industry,
        name: {
          ...industry.name,
          default: industry.name.en,
        },
      };
    }
    if (industry.name.fr && !industry.name.default) {
      return {
        ...industry,
        name: {
          ...industry.name,
          default: industry.name.fr,
        },
      };
    }
    return industry;
  };

  const cleanWorkplace = (workplace) => {
    if (!workplace.data) {
      return workplace;
    }
    return {
      ...workplace,
      data: {
        ...workplace.data,
        industries: _.map(workplace.data.industries, cleanIndustry),
      },
    };
  };

  const cleanExperiences = (experiences) =>
    _.map(experiences, (xp) => ({
      ...xp,
      ...(!_.isEmpty(xp.workplace) && {
        workplace: cleanWorkplace(xp.workplace),
      }),
    }));

  const result = {
    ...profileData,
    experiences: cleanExperiences(profileData.experiences),
  };

  if (result.sourceData && result.sourceData.linkedin) {
    result.sourceData.linkedin.experiences = cleanExperiences(result.sourceData.linkedin.experiences);
  }

  return result;
};

class SweetWork extends React.Component {
  state = {
    feedbackModeEnabled: false,
  };

  componentWillMount() {
    this.loadWorkpipe();
    this.loadOffer();
  }

  loadWorkpipe = async () => {
    const { workpipeId, offerId, inputId } = (this.props.match || {}).params || {};
    if (!workpipeId || !inputId) {
      this.setState({ error: 'no workpipe id or input id' });
      return;
    }
    const { data: workpipe } = await axios.get(`${baseUrl}/sweetwork/workPipes/${workpipeId}`);

    _.each(workpipe.inputs, (input) => {
      if (input.pipeDescriptor) {
        input.pipeDescriptor.externalFlows = [
          {
            type: 'all-external-flows',
            query: { offerId },
          },
        ];
      }
    });

    const input = _.findWhere(workpipe.inputs, { id: inputId });
    this.setState({ currentInput: input });
  };

  loadOffer = async () => {
    const offerId = ((this.props.match || {}).params || {}).offerId;
    if (!offerId) {
      return;
    }
    const { data: offer } = await axios.get(`${baseUrl}/offers/${offerId}`);
    let sweetappOffer;
    if (offer.platformId) {
      try {
        const result = (await axios.get(`${baseUrl}/offers/sweetapp/${offer.platformId}`)).data;
        if (_.isEmpty(result.sweetappOffer)) {
          throw Error(result.error);
        }
        sweetappOffer = result.sweetappOffer;
      } catch (e) {
        console.log('Error fetching sweetapp offer : ' + e.message);
      }
    }

    let mergedOffer;
    try {
      const { data } = await axios.get(`${baseUrl}/offersConciliation/getMergedHiresweetOfferForSearch/${offerId}`);
      if (!data.success) {
        throw Error(data.error);
      }
      mergedOffer = data.hiresweetOffer;
    } catch (e) {
      console.log('Error fetching merged offer : ' + e.message);
    }
    this.setState({ offer, sweetappOffer, mergedOffer });
  };

  fetchItem = async ({ idFields, sourceProfiles }) => {
    const jobOfferId = ((this.props.match || {}).params || {}).offerId;
    if (!jobOfferId) {
      alert('cannot fetch without jobOfferId');
      throw Error('cannot fetch without jobOfferId');
    }
    const { searchId } = (this.state.currentInput || {}).pipeDescriptor || {};

    const { data } = await axios.post(`${baseUrl}/sweetwork2/item`, {
      idFields,
      sourceProfiles,
      jobOfferId,
      searchId,
    });
    if (data.error) {
      throw Error(data.error);
    }
    if (data.success === false) {
      throw Error('no success in fetchItem ' + JSON.stringify(idFields));
    }
    return data;
  };

  fetchItems = async () => {
    const { pipeDescriptor, itemFilter } = this.state.currentInput || {};
    if (!pipeDescriptor || !itemFilter) {
      throw Error('no pipeDescriptor or itemFilter');
    }
    const { data } = await axios.post(`${baseUrl}/sweetwork2/workpipe/items`, {
      pipeDescriptor,
      itemFilter,
    });
    if (data.error) {
      throw Error(data.error);
    }
    if (data.success === false) {
      throw Error('no success in fetchItems');
    }
    return data;
  };

  getProgress = async () => {
    const jobOfferId = ((this.props.match || {}).params || {}).offerId;
    const { data } = await axios.get(`${baseUrl}/sweetwork2/offer/${jobOfferId}/progress`);
    return data;
  };

  annotateItem = async ({ item, annotationType }) => {
    const { workpipeId } = (this.props.match || {}).params || {};
    if (!workpipeId) {
      this.setState({ error: 'Cannot annotate item: no workpipe id' });
      return;
    }

    if (annotationType.indexOf('select') > -1) {
      if (!item.state.cacheState.data.email) {
        throw Error('Cannot select profile without a selected email! Please select an email.');
      } else if (
        _.findWhere(item.state.cacheState.data.emailHints, {
          email: item.state.cacheState.data.email,
          bouncing: true,
        })
      ) {
        throw Error('The selected email is a bounced email! Please select another one.');
      }
    }

    // hard clean of industries
    const profileData = ismaSanitize(_.omit(_.omit(item.state.cacheState.data, 'emailHints'), 'warnings'));

    const sourceDescriptor = { id: (((profileData || {}).tmp || {}).scorerDetails || {}).sourceScorer };
    const formattedItem = {
      id: item.id,
      idFields: item.idFields,
      ...(item.source && { source: item.source }),
      data: profileData,
      annotations: [
        ...(item.annotations || []),
        {
          date: new Date(),
          state: annotationType,
          ...(sourceDescriptor.id && { sourceDescriptor }),
        },
      ],
    };
    const currentPipeDescriptor = (this.state.currentInput || {}).pipeDescriptor;
    const overwrite = ((this.state.currentInput || {}).title || '').toLowerCase().indexOf('backlog') >= 0;

    const searchId = (currentPipeDescriptor || {}).type === 'search-v3' && (currentPipeDescriptor || {}).searchId;
    const { data } = await axios.post(`${baseUrl}/sweetwork2/workPipes/${workpipeId}/item/`, {
      item: formattedItem,
      ...(overwrite && { overwrite }),
      ...(searchId && { searchId }),
    });

    if (data.error) {
      console.log(data.error);
      throw Error(data.error);
    }
  };

  toggleFeedbackMode = () => {
    this.setState({
      feedbackModeEnabled: !this.state.feedbackModeEnabled,
    });
  };

  sendSearchFeedback = async ({ idFields, scorerDetails, annotationType, comment }) => {
    return (await axios.post(`${baseUrl}/sweetwork/searchFeedback`, {
      pipeDescriptor: (this.state.currentInput || {}).pipeDescriptor || null,
      idFields: idFields || null,
      offer: this.state.offer || null,
      reason: comment || null,
      searchParam: (this.state.currentInput || {}).searchParam || null,
      scorerDetails: scorerDetails || null,
      annotationType: annotationType || null,
    })).data;
  };

  render() {
    const { error, currentInput, feedbackModeEnabled } = this.state;
    const { workpipeId } = (this.props.match || {}).params || {};
    const jobOfferId = ((this.props.match || {}).params || {}).offerId;
    if (error) {
      return <h2>Error: {error}</h2>;
    }
    if (currentInput) {
      return (
        <ProfilesPipeManager
          workpipeId={workpipeId}
          jobOfferId={jobOfferId}
          offer={this.state.offer}
          sweetappOffer={this.state.sweetappOffer}
          mergedOffer={this.state.mergedOffer}
          feedbackModeEnabled={feedbackModeEnabled}
          toggleFeedbackMode={this.toggleFeedbackMode}
          sendSearchFeedback={this.sendSearchFeedback}
          fetchItems={this.fetchItems}
          fetchItem={this.fetchItem}
          annotateItem={this.annotateItem}
          annotationActions={(this.state.currentInput || {}).annotationActions}
          currentInput={this.state.currentInput}
          getProgress={this.getProgress}
        />
      );
    }
    return null;
  }
}

export default SweetWork;
