import React, { Component } from 'react';
import _ from 'underscore';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import axios from 'axios';
import {
  Grid,
  List,
  Button,
  Form,
  Statistic,
  Icon,
  Input,
} from 'semantic-ui-react';
import { SweetForm, Select } from 'sweetform';

import baseUrl from '../baseUrl';

const DEFAULT_MAX_RESULTS = 10;

const onLoadStats = async (search, memoize) => {
  if (!(search || {}).user) {
    alert('no user');
    return;
  }
  const { data } = await axios.get(`${baseUrl}/myspace/stats`, {
    params: {
      ...search,
      ...(memoize && { memoize: true }),
    },
  });
  return data.results;
};

const onLoadOffers = async (search, memoize) => {
  if (!(search || {}).user) {
    alert('no user');
    return;
  }
  const { data } = await axios.get(`${baseUrl}/myspace/offers`, {
    params: {
      user: search.user,
      ...(memoize && { memoize: true }),
    },
  });
  return data;
};

const NumberSentEmailChart = ({ stats }) =>
  stats ? (
    <div>
      <h4>Nombre de mails envoyés</h4>
      <BarChart
        width={500}
        height={250}
        data={stats}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="formattedDate"
          angle={45}
          textAnchor="start"
          height={70}
          interval={0}
          style={{ fontSize: '10px' }}
        />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip />
        <Legend />
        <Bar dataKey="#send" name="Send" stackId="a" fill="#8884d8" />
        <Bar
          dataKey="#move-to-contacted"
          name="Move To Contacted"
          stackId="a"
          fill="#82ca9d"
        />
      </BarChart>
    </div>
  ) : null;

const SendRate = ({ stats }) =>
  stats ? (
    <div>
      <h4>{"Taux d'envoi"}</h4>
      <BarChart
        width={500}
        height={250}
        data={stats}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="formattedDate"
          angle={45}
          textAnchor="start"
          height={70}
          interval={0}
          style={{ fontSize: '10px' }}
        />
        <YAxis unit="%" />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip itemStyle={{ color: 'black' }} />
        {/* <Legend /> */}
        <Bar
          dataKey="sendRate"
          name="Taux de send"
          stackId="a"
          fill="#82ca9d"
        />
        <Bar
          dataKey="skipRate"
          name="Taux de skip"
          stackId="a"
          fill="#ffffff00"
        />
      </BarChart>
    </div>
  ) : null;

const AnswerRate = ({ stats }) =>
  stats ? (
    <div>
      <h4>Taux de réponse</h4>
      <BarChart
        width={500}
        height={250}
        data={stats}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="formattedDate"
          angle={45}
          textAnchor="start"
          height={70}
          interval={0}
          style={{ fontSize: '10px' }}
        />
        <YAxis unit="%" />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="answerFirstEmailRate"
          name="Taux de réponse avant relance"
          stackId="a"
          fill="#8884d8"
        />
        <Bar
          dataKey="answerFollowUpRate"
          name="Taux de réponse après relance"
          stackId="a"
          fill="#fa7676"
        />
        <Bar
          dataKey="answerUnknownRate"
          name="Taux de réponse inconnues"
          stackId="a"
          fill="#grey"
        />
        {/* <Bar dataKey="answerRate" fill="#82ca9d" /> */}
      </BarChart>
    </div>
  ) : null;

const PositiveAnswerCount = ({ stats }) =>
  stats ? (
    <div>
      <h4>Nombre de réponse positive</h4>
      <BarChart
        width={500}
        height={250}
        data={stats}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="formattedDate"
          angle={45}
          textAnchor="start"
          height={70}
          interval={0}
          style={{ fontSize: '10px' }}
        />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip />
        {/* <Legend /> */}
        <Bar
          dataKey="#positive"
          name="Nombre de réponse positive"
          stackId="a"
          fill="#8884d8"
        />
        {/* <Bar dataKey="answerFollowUpRate" name="Taux de réponse après relance" stackId="a" fill="#fa7676" />
        <Bar dataKey="answerUnknownRate" name="Taux de réponse inconnues" stackId="a" fill="#grey" /> */}
        {/* <Bar dataKey="answerRate" fill="#82ca9d" /> */}
      </BarChart>
    </div>
  ) : null;

const SweetworkSelectActions = ({ stats }) =>
  stats ? (
    <div>
      <h4>Actions selected</h4>
      <BarChart
        width={500}
        height={250}
        data={stats}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="formattedDate"
          angle={45}
          textAnchor="start"
          height={70}
          interval={0}
          style={{ fontSize: '10px' }}
        />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip />
        <Legend />
        <Bar dataKey="allSelected" name="positive" stackId="a" fill="#8884d8" />
      </BarChart>
    </div>
  ) : null;

const SweetworkActionsRate = ({ stats }) =>
  stats ? (
    <div>
      <h4>{"Type d'actions"}</h4>
      <BarChart
        width={500}
        height={250}
        data={stats}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="formattedDate"
          angle={45}
          textAnchor="start"
          height={70}
          interval={0}
          style={{ fontSize: '10px' }}
        />
        <YAxis unit="%" />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip />
        <Legend />
        <Bar dataKey="selectedRate" name="select" stackId="a" fill="#82ca9d" />
        <Bar dataKey="backlogRate" name="backlog" stackId="a" fill="#FCC35E" />
        <Bar
          dataKey="disqualifiedRate"
          name="disqualify"
          stackId="a"
          fill="#fa7676"
        />
      </BarChart>
    </div>
  ) : null;

const SelectableOffer = ({ offer, onSelectOffer, search }) => {
  const {
    id,
    title,
    companyId,
    state,
    sendStats,
    skipStats,
    //answerStats,
    //annotationStats
  } = offer;
  const send =
    ((sendStats || {}).date &&
      new Date((sendStats || {}).date).getTime() >
        Date.now() - 8 * 24 * 60 * 60 * 1000 &&
      sendStats.count) ||
    0;
  const skip =
    ((skipStats || {}).date &&
      new Date((skipStats || {}).date).getTime() >
        Date.now() - 8 * 24 * 60 * 60 * 1000 &&
      skipStats.count) ||
    0;
  //const answer = ((answerStats || {}).date && new Date((answerStats || {}).date).getTime() > Date.now() - 8 * 24 * 60 * 60 * 1000 && answerStats.count) || 0
  //const select = ((annotationStats || {}).date && new Date((annotationStats || {}).date).getTime() > Date.now() - 8 * 24 * 60 * 60 * 1000 && annotationStats.selected) || 0
  //const backlog = ((annotationStats || {}).date && new Date((annotationStats || {}).date).getTime() > Date.now() - 8 * 24 * 60 * 60 * 1000 && annotationStats.backlog) || 0
  //const disqualify = ((annotationStats || {}).date && new Date((annotationStats || {}).date).getTime() > Date.now() - 8 * 24 * 60 * 60 * 1000 && annotationStats.disqualified) || 0
  const sendRate =
    _.isNumber(send) &&
    _.isNumber(skip) &&
    send + skip > 0 &&
    Math.floor((send * 1000) / (send + skip)) / 10;
  //const selectRate = _.isNumber(select) && _.isNumber(backlog) && _.isNumber(disqualify) && select + backlog + disqualify > 0 && Math.floor(select * 1000 / (select + backlog + disqualify)) / 10
  return (
    <List.Item onClick={() => onSelectOffer(id)} style={{ display: 'flex' }}>
      <List.Icon
        name={state === 'stacked' ? 'battery full' : 'battery quarter'}
        verticalAlign="middle"
        {...search.offer === id && { color: 'blue' }}
      />
      <List.Content>
        <List.Header as="a">{id}</List.Header>
        <List.Description as="a">
          {companyId} / {title}
          <br />
        </List.Description>
      </List.Content>
      <div style={{ width: '100px', margin: '0px 5px', fontSize: '14px' }}>
        {_.isNumber(sendRate) && (
          <span>
            <Icon name="send" />
            {sendRate}% ({send})
          </span>
        )}
      </div>
    </List.Item>
  );
};

const EmailsNbAndRateKPIs = ({ stats }) =>
  stats ? (
    <Statistic.Group widths={2} style={{ width: '100%' }}>
      {stats && stats.sentEmailKPI && (
        <Statistic
          color={
            stats.sentEmailKPI.current >= stats.sentEmailKPI.record
              ? 'yellow'
              : 'black'
          }
        >
          <Statistic.Label>Mails envoyés</Statistic.Label>
          <Statistic.Value>
            {stats &&
              stats.sentEmailKPI &&
              stats.sentEmailKPI.current >= stats.sentEmailKPI.record && (
                <Icon name="winner" />
              )}
            {stats.sentEmailKPI.current}
          </Statistic.Value>
        </Statistic>
      )}
      {stats && stats.sendRateKPIs && (
        <Statistic
          color={
            stats.sendRateKPIs.current >= stats.sendRateKPIs.record
              ? 'yellow'
              : 'black'
          }
        >
          <Statistic.Label>{"Taux d'envoi"}</Statistic.Label>
          <Statistic.Value>
            {stats &&
              stats.sendRateKPIs &&
              stats.sendRateKPIs.current >= stats.sendRateKPIs.record && (
                <Icon name="winner" />
              )}
            {stats.sendRateKPIs.current}%
          </Statistic.Value>
        </Statistic>
      )}
    </Statistic.Group>
  ) : null;

const SelectActionsAndPositiveAnswerCountKPIs = ({ stats }) =>
  stats ? (
    <Statistic.Group widths={2} style={{ width: '100%' }}>
      {stats && stats.selectActionsCountKPIs && (
        <Statistic
          color={
            stats.selectActionsCountKPIs.current >=
            stats.selectActionsCountKPIs.record
              ? 'yellow'
              : 'black'
          }
        >
          <Statistic.Label>Nombre de Select</Statistic.Label>
          <Statistic.Value>
            {stats &&
              stats.selectActionsCountKPIs &&
              stats.selectActionsCountKPIs.current >=
                stats.selectActionsCountKPIs.record && <Icon name="winner" />}
            {stats.selectActionsCountKPIs.current}
          </Statistic.Value>
        </Statistic>
      )}
      {stats && stats.positiveAnswerCountKPIs && (
        <Statistic
          color={
            stats.positiveAnswerCountKPIs.current >=
            stats.positiveAnswerCountKPIs.record
              ? 'yellow'
              : 'black'
          }
        >
          <Statistic.Label>Nombre de réponse positives</Statistic.Label>
          <Statistic.Value>
            {stats &&
              stats.positiveAnswerCountKPIs &&
              stats.positiveAnswerCountKPIs.current >=
                stats.positiveAnswerCountKPIs.record && <Icon name="winner" />}
            {stats.positiveAnswerCountKPIs.current}
          </Statistic.Value>
        </Statistic>
      )}
    </Statistic.Group>
  ) : null;

class StatsFilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxNbResults:
        (this.props.search || {}).maxNbResults || DEFAULT_MAX_RESULTS,
    };
  }
  render() {
    const { search, onChange, periodOptions } = this.props;
    return (
      <Form style={{ width: '100%' }}>
        <SweetForm onChange={onChange}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Nombre de résultats</label>
              <Input
                field="maxNbResults"
                placeholder="Nb résultats"
                defaultValue={
                  (search && search.maxNbResults) || DEFAULT_MAX_RESULTS
                }
              >
                <input
                  onChange={(e) =>
                    this.setState({ maxNbResults: e.target.value })
                  }
                />
                <Button
                  {...this.state.maxNbResults &&
                    this.state.maxNbResults !== search.maxNbResults && {
                      positive: true,
                    }}
                  onClick={() =>
                    this.state.maxNbResults &&
                    this.state.maxNbResults !== search.maxNbResults &&
                    onChange({
                      ...search,
                      maxNbResults: this.state.maxNbResults,
                    })
                  }
                >
                  Go
                </Button>
              </Input>
            </Form.Field>
            <Form.Field>
              <label>Période</label>
              <Select
                field="period"
                placeholder="Période"
                options={periodOptions}
                {...periodOptions &&
                  periodOptions[1] && { defaultValue: periodOptions[1].value }}
              />
            </Form.Field>
          </Form.Group>
        </SweetForm>
      </Form>
    );
  }
}

// Components
class MyStats extends Component {
  constructor(props) {
    super(props);
    const search = {
      user: this.props.username,
      offer: 'all',
      maxNbResults: DEFAULT_MAX_RESULTS,
      period: 'week',
    };
    this.state = { stats: {}, search };
  }

  componentWillMount() {
    this.onLoad(this.state.search);
  }

  onLoad = async () => {
    const { search } = this.state;
    const { memoize } = this.props;
    const offers = await onLoadOffers(search, memoize);
    const stats = await onLoadStats(search, memoize);
    this.setState({ stats, offers });
  };

  onSelectOffer = async (id) => {
    const { memoize } = this.props;
    const search = { ...this.state.search, offer: id };
    const stats = await onLoadStats(search, memoize);
    this.setState({ stats, search });
  };

  onReloadStats = async (s) => {
    const { memoize } = this.props;
    const search = { ...this.state.search, ...s };
    const stats = await onLoadStats(search, memoize);
    this.setState({ stats, search });
  };

  render() {
    const { search, stats, offers } = this.state;
    const periodOptions = [
      { value: 'day', label: 'day' },
      { value: 'week', label: 'week' },
      { value: 'month', label: 'month' },
      { value: 'year', label: 'year' },
    ];
    if (!this.state.search.user) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width="16">
            <StatsFilterForm
              periodOptions={periodOptions}
              onChange={(s) => {
                this.onReloadStats({ ...this.state.search, ...s });
              }}
              search={search}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4">
            <List divided relaxed>
              <List.Item onClick={() => this.onSelectOffer('all')}>
                <List.Icon
                  name={'square'}
                  verticalAlign="middle"
                  {...search.offer === 'all' && { color: 'blue' }}
                />
                <List.Content>
                  <List.Header as="a">{'All Offers'}</List.Header>
                  <List.Description as="a">
                    belonging to {this.state.user}
                  </List.Description>
                </List.Content>
              </List.Item>
              {offers &&
                _.map(
                  _.sortBy(offers, (o) => o.lastProfileUploadDate).reverse(),
                  (offer) => (
                    <SelectableOffer
                      key={offer.id}
                      offer={offer}
                      onSelectOffer={this.onSelectOffer}
                      search={search}
                    />
                  ),
                )}
            </List>
          </Grid.Column>
          <Grid.Column width="6">
            <EmailsNbAndRateKPIs stats={stats} />
            <SweetworkSelectActions stats={(stats || {}).sweetworkActions} />
            <NumberSentEmailChart stats={(stats || {}).nbEmailsSent} />
            <AnswerRate stats={(stats || {}).answerRate} />
          </Grid.Column>
          <Grid.Column width="6">
            <SelectActionsAndPositiveAnswerCountKPIs stats={stats} />
            <SweetworkActionsRate stats={(stats || {}).sweetworkActions} />
            <SendRate stats={(stats || {}).sendRate} />
            <PositiveAnswerCount stats={(stats || {}).answerRate} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default MyStats;
