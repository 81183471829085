import React from 'react';
import {
  Container,
  Card,
  Grid,
  Segment,
  Label,
  Button,
  Modal
} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from './baseUrl';
import { SweetForm, Input, enhance } from 'sweetform';

class LanguagesOverview extends React.Component {
  state = {
    search: '',
  };

  onChangeSearch = ({ search }) => {
    this.setState({ search });
  };

  render() {

    const {
      languages,
      onSelectLanguage,
      selectedLanguage,
    } = this.props;
    const { search } = this.state;
    const filteredLanguages = (search && languages) ?
       _.filter(languages, ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
      : languages
    const sortedLanguages =  _.sortBy(filteredLanguages, ({name}) => name)

    return (
      <Container>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <h3>
                      Language Name
                    </h3>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <SweetForm onChange={this.onChangeSearch}>
                      <Input field="search" />
                    </SweetForm>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
            <Card.Description>
              <Grid>
                {sortedLanguages.map((language) => {
                  return (
                    <Grid.Row key={language.id}>
                      <Grid.Column
                        width={16}
                        onClick={() => onSelectLanguage(language)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          style={
                            selectedLanguage && selectedLanguage.id === language.id
                              ? {
                                  fontWeight: 'bold',
                                  color: 'green',
                                  fontSize: '13px',
                                }
                              : {}
                          }
                        >
                          {language.name}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}


const Language = ({language}) => (
  language ?
    <div>
      <h2>{language.name}</h2>
      <Label>id: {language.id}</Label>
    </div>
  : null
)

const EditTranslations = enhance((props) => (
  <div>
    <label>Default</label>
    <Input field="default" />
    <label>English</label>
    <Input field="en" />
    <label>French</label>
    <Input field="fr" />
  </div>
))

const LanguageEditor = ({language, onSave, saved, onUpdate}) => {
  return (
  <div>
    <Button positive={!saved} onClick={()=>onSave()} disabled={saved}>Save</Button>
    <SweetForm
      key={language.id}
      initialValues={language}
      onChange={onUpdate}
    >
      <label><b>Name :</b></label>
      <Input field='name' />
      <label><b>Code :</b></label>
      <Input field='code' />
      <label><b>Translations :</b></label>
      <EditTranslations field='translations' />
    </SweetForm>
  </div>
)}



class CreateLanguageModal extends React.Component {
  state = {};

  onUpdate = (language) => {
    this.setState({language});
  }

  handleSubmit = async () => {
    console.log('handleSubmit', this.state.language)
    try {
      await this.props.onSubmit(this.state.language)
    } catch (e) {
      alert(e)
    }
  }

  render () {
    const {onClose} = this.props;
    return (

    <Modal onClose={onClose} open closeIcon size="fullscreen">

    <Modal.Content>
      <SweetForm
          onChange={this.onUpdate}
        >
        <label><b>Id :</b></label>
        <Input field='id' />
        <label><b>Name :</b></label>
        <Input field='name' />
      </SweetForm>
    </Modal.Content>

    <Modal.Actions>
      <Button negative onClick={onClose}>
        Cancel
      </Button>
      <Button positive onClick={this.handleSubmit}>
        Submit
      </Button>
    </Modal.Actions>
    </Modal>
    )
  }
}





class Languages extends React.Component {
  state = {
    languages:null,
    selectedLanguage:null,
    saved: true,
    createModalOpen: false
  };

  componentWillMount() {
    this.loadData();
  }

  loadData = async () => {
    const languages = await this.getLanguages();
    this.setState({
      languages,
      selectedLanguage: null,
      saved:true,
      createModalOpen: false
    })
  }


  getLanguages = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/languages`);
      return data
    } catch (e) {
      alert(e);
    }
  };

  onSelectLanguage = (language) => {
    this.setState({
      selectedLanguage: language,
      saved: true
    })
  }

  onUpdate = (language) => {
    this.setState({
      selectedLanguage:language,
      saved:false
    });
  };

  onSave = async () => {

    try {
      const { selectedLanguage, languages } = this.state;
      const { id } = selectedLanguage;
      const { data } = await axios.put(`${baseUrl}/languages/${id}`, _.omit(selectedLanguage, 'id'));
      if (!data.success){
        console.log(data)
        throw Error(data.error)
      }
      const newlanguages = _.map(languages, (otherLanguage)=>(
        otherLanguage.id===id ? selectedLanguage : otherLanguage
      ))
      this.setState({
        saved:true,
        languages:newlanguages
      })
      console.log('saved')
    } catch (e) {
      console.log(e)
      alert(e)
    }
    
  }

  onOpenCreateModal () {
    this.setState({createModalOpen:true})
  }
  onCloseCreateModal () {
    console.log('onCloseCreateModal')
    this.setState({createModalOpen:false})
  }

  onSumitCreateLanguage = async (language) => {
    try {
      const data = (await axios.post(`${baseUrl}/languages`, language)).data;
      if (!data.success) {
        throw Error(data.error)
      }
      this.loadData();
    } catch (e) {
      alert(e);
    }
  }

  render () {
    const { 
      languages, 
      selectedLanguage, 
      saved,
      createModalOpen
    } = this.state;
    return (

      <div>
      {createModalOpen && (
        <CreateLanguageModal
          onSubmit={(language)=>this.onSumitCreateLanguage(language)}
          onClose={()=>this.onCloseCreateModal()}
        />)}
      <Grid columns={2}>
        <Grid.Column width={4}>
          <Button onClick={()=>this.onOpenCreateModal()}>Create</Button>
          <LanguagesOverview
            languages={languages}
            onSelectLanguage={this.onSelectLanguage}
            selectedLanguage={selectedLanguage}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          {selectedLanguage && (
            <div>
              <Segment key={selectedLanguage.id}>
                <Language language={selectedLanguage} />
              </Segment>
            </div>
          )}
          {selectedLanguage && (
            <Segment>
              <LanguageEditor
                language={selectedLanguage}
                onUpdate={this.onUpdate}
                onSave={this.onSave}
                saved={saved}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid>
      </div>


    )

  }


}

export default Languages;
