import _ from 'underscore';
import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import OfferEditor from '../SweetComponents/Offer/Editor';
import exampleOffer from './exampleOffer';

class EditOfferModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.state.data = props.initialValue;
    } else {
      this.state.data = exampleOffer;
    }
  }

  handleUpdate = ({ data }) => {
    // comment
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({ data: this.state.data });
  };

  render() {
    const { onClose } = this.props;
    const { data } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit Offer</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <OfferEditor initialValue={data} onUpdate={this.handleUpdate} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' disabled={_.isEmpty(data)} onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditOfferModal;
