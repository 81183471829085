import { Button, Checkbox, Grid, Icon, Modal, Table } from 'semantic-ui-react';

import React, { Component } from 'react';
import WidgetDeletionModal from './WidgetDeletionModal';
import WidgetEditionModal from './WidgetEditionModal';
import WidgetPreviewModal from './WidgetPreviewModal';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';

const getRandomString = (size) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let text = '';
  for (let i = 0; i < size; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

class SelectTypeModal extends Component {
  componentDidMount() {
    const { isTypeSelected } = this.props;
    this.setState({ isTypeSelected });
  }

  handleSelectType = ({ type }) => {
    this.setState({
      isTypeSelected: {
        ...this.state.isTypeSelected,
        [type]: !this.state.isTypeSelected[type],
      },
    });
  };

  renderCell = (name, key, type) => {
    const { isTypeSelected } = this.state || {};
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Select' ? (
          <Checkbox checked={(isTypeSelected || {})[type]} onChange={() => this.handleSelectType({ type })} />
        ) : name == 'Type' ? (
          <span>{type}</span>
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const { types, onClose, onSubmit } = this.props;
    const { isTypeSelected } = this.state || {};

    const columnNames = ['Select', 'Type'];
    return (
      <Modal open closeIcon open={true} onClose={onClose}>
        <Modal.Content>
          <Table>
            <Table.Body>
              {types.map((type, key) => {
                return (
                  <Table.Row key={key}>
                    {columnNames.map((name, index2) => {
                      return this.renderCell(name, index2 + key, type);
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => onSubmit(isTypeSelected)}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class AssetsExplorerConfigurationWidgets extends Component {
  state = {
    loading: true,
    widgets: undefined,
    types: ['bar-chart', 'dashboard', 'navigation', 'page'],
    isTypeSelected: {
      'bar-chart': true,
      dashboard: true,
      navigation: true,
      page: true,
    },
  };

  componentDidMount() {
    this.handleLoad();
  }

  async loadWidgetIdToWidget() {
    const widgetIdToWidget = {};
    const url = baseUrl + '/assetsExplorer/widgets';
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    _.map(data, function(widget) {
      widgetIdToWidget[widget.id] = widget;
    });
    this.setState({ widgetIdToWidget });
  }

  handleLoad = async () => {
    const { data } = await axios.get(`${baseUrl}/assetsExplorer/widgets`);
    if (data.error) {
      return alert(data.error);
    }
    this.loadWidgetIdToWidget();
    this.setState({
      widgets: data,
    });
  };

  handleOpenWidgetCreationModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-creation',
        widget: {
          ...widget,
          id: getRandomString(6),
          title: 'Untitled',
          // eslint-disable-next-line prettier/prettier
          creationTimestamp: Date.now()
        },
      },
    });
  };

  handleOpenWidgetEditionModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-edition',
        widget: widget,
      },
    });
  };

  handleOpenWidgetPreviewModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-preview',
        widget: widget,
      },
    });
  };

  handleOpenWidgetDeletionModal = ({ widget }) => {
    this.setState({
      modal: {
        type: 'widget-deletion',
        widget: widget,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({
      modal: undefined,
    });
  };

  handleSaveEditedWidget = async ({ widget, initialId }) => {
    try {
      if (!widget.id) {
        throw Error('need widget id');
      }
      const { data } = await axios.put(`${baseUrl}/assetsExplorer/widgets/${initialId}`, { widget });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  };

  handleSaveCreatedWidget = async ({ widget }) => {
    try {
      if (!widget.id) {
        throw Error('need widget id');
      }
      const { data } = await axios.post(`${baseUrl}/assetsExplorer/widgets`, { widget });
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  };

  handleDeleteWidget = async (id) => {
    try {
      if (!id) {
        throw Error('need widget id');
      }
      const { data } = await axios.delete(`${baseUrl}/assetsExplorer/widgets/${id}`);
      if (data.error) {
        throw Error(data.error);
      }
      this.handleCloseModal();
      this.handleLoad();
    } catch (e) {
      return alert(e.message);
    }
  };

  handleOpenSelectTypeModal = () => {
    this.setState({ selectTypeModalVisible: true });
  };

  onSubmitSelectTypeModal = (isTypeSelected) => {
    this.setState({ selectTypeModalVisible: false, isTypeSelected });
  };

  onCloseSelectTypeModal = () => {
    this.setState({ selectTypeModalVisible: false });
  };

  handleClickOnSortAlphabet = (key) => {
    const { widgets } = this.state;
    const sortedWidgets = _.sortBy(widgets, key);
    this.setState({ widgets: sortedWidgets });
  };

  handleClickOnSortReverseAlphabet = (key) => {
    const { widgets } = this.state;
    const sortedWidgets = _.sortBy(widgets, key).reverse();
    this.setState({ widgets: sortedWidgets });
  };

  render() {
    const { widgets, modal, widgetIdToWidget, isTypeSelected, selectTypeModalVisible } = this.state;

    if (widgets === undefined) {
      return <span>Loading...</span>;
    }

    const filteredWidgets = isTypeSelected ? _.filter(widgets, (widget) => isTypeSelected[widget.type]) : widgets;

    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <h3>Widgets</h3>
            </Grid.Column>
            <Grid.Column width={4} textAlign='right'>
              <a style={{ cursor: 'pointer' }} onClick={() => this.handleOpenWidgetCreationModal({ widget: {} })}>
                Add widget
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>
                Type
                <Icon link name='filter' onClick={() => this.handleOpenSelectTypeModal()} />
                <Icon link name='caret down' onClick={() => this.handleClickOnSortAlphabet('type')} />
                <Icon link name='caret up' onClick={() => this.handleClickOnSortReverseAlphabet('type')} />
              </Table.HeaderCell>
              <Table.HeaderCell>
                id
                <Icon link name='caret down' onClick={() => this.handleClickOnSortAlphabet('id')} />
                <Icon link name='caret up' onClick={() => this.handleClickOnSortReverseAlphabet('id')} />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Title
                <Icon link name='caret down' onClick={() => this.handleClickOnSortAlphabet('title')} />
                <Icon link name='caret up' onClick={() => this.handleClickOnSortReverseAlphabet('title')} />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Edit</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Preview</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Fork</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Delete</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(filteredWidgets, (widget, index) => (
              <Table.Row key={index + '_' + widget.id}>
                <Table.Cell>{widget.username}</Table.Cell>
                <Table.Cell>{widget.type}</Table.Cell>
                <Table.Cell>{widget.id}</Table.Cell>
                <Table.Cell>{widget.title}</Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon link name='pencil' onClick={() => this.handleOpenWidgetEditionModal({ widget })} />
                  </center>
                </Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon link name='eye' onClick={() => this.handleOpenWidgetPreviewModal({ widget })} />
                  </center>
                </Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon link name='fork' onClick={() => this.handleOpenWidgetCreationModal({ widget })} />
                  </center>
                </Table.Cell>
                <Table.Cell>
                  <center>
                    <Icon
                      link
                      color={'red'}
                      name='delete'
                      onClick={() => this.handleOpenWidgetDeletionModal({ widget })}
                    />
                  </center>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {(modal || {}).type === 'widget-preview' && (
          <WidgetPreviewModal
            onClose={this.handleCloseModal}
            widget={modal.widget}
            widgetIdToWidget={widgetIdToWidget}
          />
        )}

        {(modal || {}).type === 'widget-edition' && (
          <WidgetEditionModal
            onClose={this.handleCloseModal}
            widget={modal.widget}
            onSave={this.handleSaveEditedWidget}
            widgetIdToWidget={widgetIdToWidget}
          />
        )}

        {(modal || {}).type === 'widget-creation' && (
          <WidgetEditionModal
            onClose={this.handleCloseModal}
            widget={modal.widget}
            onSave={this.handleSaveCreatedWidget}
            widgetIdToWidget={widgetIdToWidget}
          />
        )}

        {(modal || {}).type === 'widget-deletion' && (
          <WidgetDeletionModal
            onCancel={this.handleCloseModal}
            widget={modal.widget}
            onSubmit={this.handleDeleteWidget}
          />
        )}

        {selectTypeModalVisible && (
          <SelectTypeModal
            types={(this.state || {}).types}
            isTypeSelected={(this.state || {}).isTypeSelected}
            onSubmit={this.onSubmitSelectTypeModal}
            onClose={this.onCloseSelectTypeModal}
          />
        )}
      </div>
    );
  }
}

export default AssetsExplorerConfigurationWidgets;
