import axios from 'axios';
import React, { Component } from 'react';
import { Card, Checkbox, Form, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';

import { sweethubToSweetappCriteria } from '../TechAssetSandBox/FunctionResult';
import Offer from '../components/Offer';
import ConfigSearchView from './ConfigSearchView.js';
import CreateClientModal from './CreateClientModal.js';
import CreateMissionModal from './CreateMissionModal.js';
import { locationMapping } from './LocationMapping.js';

const sweetappToSweethubCriteria = (criteria) => {
  const sweetappToSweethubRemoteLabel = {
    no: 'full-time',
    'occasional-remote': 'partial-remote',
    'part-time': 'partial-remote',
    'full-time': 'remote',
  };

  const remoteLabel = ((criteria || {}).remote || {}).identifier || 'occasional-remote';
  const remoteWish = sweetappToSweethubRemoteLabel[remoteLabel];
  const locationSweethubIds = _.map((criteria || {}).locations || [], (item) => locationMapping[item.identifier]);
  const filteredLocationSweethubIds = _.filter(locationSweethubIds, (loc) => !_.isUndefined(loc));
  const locationCriteria = {
    target: _.map(filteredLocationSweethubIds, (identifier) => ({
      remoteWish,
      location: { id: identifier },
    })),
    okWith: [],
  };

  const experienceCriteria = {
    target: {
      min: (((criteria || {}).experience || {}).years || {}).min,
      max: (((criteria || {}).experience || {}).years || {}).max,
    },
    okWith: {
      min: (((criteria || {}).experience || {}).years || {}).min,
      max: (((criteria || {}).experience || {}).years || {}).max,
    },
  };

  const requiredJobPosition = ((criteria || {}).jobPositions || {}).required || [];
  const jobPositionCriteria = !_.isEmpty(requiredJobPosition)
    ? { major: { id: requiredJobPosition[0].identifier } }
    : {};

  const allMainSkills = _.map(((criteria || {}).skills || {}).required || [], (item) => item.identifier);
  const allImportantSkills = _.map(((criteria || {}).skills || {}).important || [], (item) => item.identifier);
  const allBonusSkills = _.map(((criteria || {}).skills || {}).bonus || [], (item) => item.identifier);

  const mainSkillsCriteria = _.map(_.filter(allMainSkills, (s) => !_.isUndefined(s)), (skillId) => ({
    skills: [{ id: skillId }],
  }));
  const importantSkillsCriteria = _.map(_.filter(allImportantSkills, (s) => !_.isUndefined(s)), (skillId) => ({
    skills: [{ id: skillId }],
  }));
  const bonusSkillsCriteria = _.map(_.filter(allBonusSkills, (s) => !_.isUndefined(s)), (skillId) => ({
    skills: [{ id: skillId }],
  }));

  const sweethubCriteria = {
    locations: locationCriteria,
    experience: experienceCriteria,
    jobPosition: jobPositionCriteria,
    skills: {
      main: mainSkillsCriteria,
      important: importantSkillsCriteria,
      bonus: bonusSkillsCriteria,
    },
  };
  return sweethubCriteria;
};

class ManualRecommendations extends Component {
  componentDidMount() {
    this.getMissions();
  }

  getMissions = async () => {
    const { selectedClient } = this.props || {};
    const targetMissions = await this.getTargetMissions(selectedClient.id);
    this.setState({ selectedClient, targetMissions, selectedMission: undefined });
  };

  async getTargetMissions(clientId) {
    const url = baseUrl + '/revealRecommendations/getTargetMissions/' + clientId;
    const targetMissions = (await axios.get(url)).data;
    return targetMissions;
  }

  async getNbActiveRecos(profilesCollectionId, missionId) {
    const url = baseUrl + '/revealRecommendations/getNbActiveRecos/' + profilesCollectionId + '/' + missionId;
    const nbActiveRecos = (await axios.get(url)).data;
    return nbActiveRecos;
  }

  async getLinkedATSJobId(jobsCollectionId, missionId) {
    const url = baseUrl + '/revealRecommendations/getLinkedATSJobId/' + jobsCollectionId + '/' + missionId;
    const linkedATSJobId = (await axios.get(url)).data;
    return linkedATSJobId;
  }

  async getNbSkippedRecos(profilesCollectionId, missionId) {
    const url = baseUrl + '/revealRecommendations/getNbSkippedRecos/' + profilesCollectionId + '/' + missionId;
    const nbSkippedRecos = (await axios.get(url)).data;
    return nbSkippedRecos;
  }

  async getNbAcceptedRecos(profilesCollectionId, missionId) {
    const url = baseUrl + '/revealRecommendations/getNbAcceptedRecos/' + profilesCollectionId + '/' + missionId;
    const nbAcceptedRecos = (await axios.get(url)).data;
    return nbAcceptedRecos;
  }

  async getnbTotalHistoricalRecos(profilesCollectionId, missionId) {
    const url = baseUrl + '/revealRecommendations/getnbTotalHistoricalRecos/' + profilesCollectionId + '/' + missionId;
    const nbTotalHistoricalRecos = (await axios.get(url)).data;
    return nbTotalHistoricalRecos;
  }

  async getNbTotalRecos(profilesCollectionId, missionId) {
    const url = baseUrl + '/revealRecommendations/getNbTotalRecos/' + profilesCollectionId + '/' + missionId;
    const nbTotalRecos = (await axios.get(url)).data;
    return nbTotalRecos;
  }

  async getSweetAppCriteria(selectedMissionId) {
    const { selectedClient } = this.state || {};
    const jobsCollectionId = (selectedClient || {}).jobsCollectionId;
    const sweetappCriteriaUrl =
      baseUrl + '/revealRecommendations/getSweetAppCriteria/' + jobsCollectionId + '/' + selectedMissionId;
    const sweetappCriteria = ((await axios.get(sweetappCriteriaUrl)).data || {}).criteria;

    const lastCriteriaForSearchViewUrl =
      baseUrl + '/revealRecommendations/getLastCriteriaForSearchView/' + jobsCollectionId + '/' + selectedMissionId;
    const resultLastCriteriaForSearchView = ((await axios.get(lastCriteriaForSearchViewUrl)).data || {}).free;
    const lastCriteriaForSearchView = !_.isEmpty(resultLastCriteriaForSearchView)
      ? (JSON.parse(resultLastCriteriaForSearchView) || {}).criteria
      : undefined;

    const mainSweetappCriteria = !_.isEmpty(sweetappCriteria)
      ? sweetappCriteria
      : !_.isEmpty(lastCriteriaForSearchView)
      ? sweethubToSweetappCriteria(lastCriteriaForSearchView)
      : {};

    const mainSweetappCriteriaInSweethubForm = !_.isEmpty(sweetappCriteria)
      ? sweetappToSweethubCriteria(sweetappCriteria)
      : lastCriteriaForSearchView;
    this.setState({
      sweetappCriteria: mainSweetappCriteria,
      sweetappCriteriaInSweethubForm: mainSweetappCriteriaInSweethubForm,
    });
  }

  selectMission = async (missionId) => {
    const { targetMissions } = this.state || {};
    const selectedMission = _.find(targetMissions, (mission) => mission.id === missionId);
    const missionInfos = await this.computeMissionInfos(selectedMission.id);
    await this.checkIfArchived(selectedMission.id);
    await this.getSweetAppCriteria(selectedMission.id);
    await this.getSkipReasons(selectedMission.id);
    this.setState({ selectedMission: selectedMission, missionInfos });
  };

  handleSelectMission = async (e, { value }) => {
    if (value === 'new-mission') {
      this.setState({ createMissionModalVisible: true });
      return;
    }
    await this.selectMission(value);
  };

  getSkipReasons = async (missionId) => {
    const { selectedClient } = this.state || {};
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId,
    };
    const url = baseUrl + '/revealRecommendations/getSkipReasons';
    const { data } = await axios.post(url, params);
    if (data.error) {
      alert(data.error);
      return;
    }
    const skipReasons = data.skipReasons;

    this.setState({ skipReasons });
  };

  createTargetClient = async (params) => {
    const url = baseUrl + '/revealRecommendations/createTargetClient';
    const { data } = await axios.post(url, params);
    return data;
  };

  createTargetMission = async (params) => {
    const url = baseUrl + '/revealRecommendations/createTargetMission';
    const { data } = await axios.post(url, params);
    return data;
  };

  onSubmitCreateMission = async (params) => {
    const { selectedClient } = this.state;
    params.clientId = selectedClient.id;
    params.isStarred = false;
    if (_.isUndefined(params)) {
      this.setState({ createMissionModalVisible: false });
      return;
    }
    if (_.isUndefined(params.id) || _.isEmpty(params.id)) {
      alert('Mission id should be specified.');
      return;
    }
    const data = await this.createTargetMission(params);
    if (data.error) {
      alert(data.error);
      return;
    }
    const targetMissions = await this.getTargetMissions(selectedClient.id);
    this.setState({ targetMissions });
    await this.selectMission(params.id);
    this.setState({ createMissionModalVisible: false });
  };

  onCancelCreateClient = () => {
    this.setState({ createClientModalVisible: false });
  };

  onCancelCreateMission = () => {
    this.setState({ createMissionModalVisible: false });
  };

  getRecommenderSystemsMission = async (selectedClientId, selectedProjectId, selectedMissionId) => {
    const url =
      baseUrl +
      '/RevealRecommendationsRecommenderSystems/getRecommenderSystems/' +
      selectedClientId +
      '/' +
      selectedProjectId +
      '/' +
      selectedMissionId;
    const result = (await axios.get(url)).data;
    if (result.error) {
      alert(result.error);
      return;
    } else {
      const data = result.data || {};
      return data;
    }
  };

  createRecommenderSystem = async (selectedClientId, selectedProjectId, selectedMissionId) => {
    const url =
      baseUrl +
      '/RevealRecommendationsRecommenderSystems/createRecommenderSystem/' +
      selectedClientId +
      '/' +
      selectedProjectId +
      '/' +
      selectedMissionId;
    const result = (await axios.get(url)).data;
    if (result.error) {
      alert(result.error);
      return;
    } else {
      const data = result.data || {};
      return data;
    }
  };

  async computeMissionInfos(selectedMissionId) {
    const { selectedClient } = this.state || {};
    const nbActiveRecos = await this.getNbActiveRecos((selectedClient || {}).profilesCollectionId, selectedMissionId);
    const nbSkippedRecos = await this.getNbSkippedRecos((selectedClient || {}).profilesCollectionId, selectedMissionId);
    const nbAcceptedRecos = await this.getNbAcceptedRecos(
      (selectedClient || {}).profilesCollectionId,
      selectedMissionId,
    );
    const nbTotalHistoricalRecos = await this.getnbTotalHistoricalRecos(
      (selectedClient || {}).profilesCollectionId,
      selectedMissionId,
    );
    const nbTotalRecos = await this.getNbTotalRecos((selectedClient || {}).profilesCollectionId, selectedMissionId);
    const linkedATSJobId = await this.getLinkedATSJobId((selectedClient || {}).jobsCollectionId, selectedMissionId);

    const missionInfos = [
      { title: 'Job id', value: selectedMissionId },
      { title: 'Number of Active Recommendations', value: nbActiveRecos },
      { title: 'Number of Skipped Recommendations', value: nbSkippedRecos },
      { title: 'Number of Accepted Recommendations', value: nbAcceptedRecos },
      { title: 'Total Number of Handled Recommendations', value: nbTotalHistoricalRecos },
      { title: 'Total Number of Recommendations', value: nbTotalRecos },
      { title: 'Linked ATS Job Id', value: linkedATSJobId || 'No linked ATS Job' },
    ];
    return missionInfos;
  }

  async checkIfArchived(selectedMissionId) {
    const { selectedClient } = this.state || {};
    const params = {
      clientId: selectedClient.id,
      projectId: selectedClient.projectId,
      missionId: selectedMissionId,
    };
    const url = baseUrl + '/revealRecommendations/isArchived';
    const { data } = (await axios.post(url, params)).data || {};
    const isArchived = data || false;
    this.setState({ isArchived });
  }

  clickOnToggleSweetappCriteria = () => {
    const { toggleSweetappCriteria } = this.state || {};
    this.setState({ toggleSweetappCriteria: !(toggleSweetappCriteria || false) });
  };

  render() {
    const {
      createClientModalVisible,
      createMissionModalVisible,
      isArchived,
      missionInfos,
      selectedClient,
      selectedMission,
      skipReasons,
      sweetappCriteria,
      sweetappCriteriaInSweethubForm,
      targetMissions,
      toggleSweetappCriteria,
    } = this.state || {};

    const sortedTargetMissions = _.sortBy(targetMissions, (mission) => mission.name.toLowerCase());
    const missionOptions = _.map(sortedTargetMissions || [], (mission, key) => ({
      value: mission.id,
      key: key,
      text: mission.name ? mission.name : mission.title,
    })).concat([
      {
        value: 'new-mission',
        key: 'new-mission',
        text: '+ Create Mission',
      },
    ]);

    const sortedSkipReasons = _.sortBy(skipReasons, (reason) => -reason.date);

    return (
      <div>
        {createClientModalVisible ? (
          <CreateClientModal onSubmit={this.onSubmitCreateClient} onCancel={this.onCancelCreateClient} />
        ) : null}
        {createMissionModalVisible ? (
          <CreateMissionModal
            onSubmit={this.onSubmitCreateMission}
            onCancel={this.onCancelCreateMission}
            selectedClientJobsCollectionId={selectedClient.jobsCollectionId}
          />
        ) : null}
        <Grid>
          <Grid.Row>
            <Form.Select
              search
              placeholder='Missions'
              options={missionOptions}
              onChange={this.handleSelectMission}
              value={(selectedMission || {}).id}
            />
          </Grid.Row>
          <Grid.Row>
            {selectedMission ? (
              <Grid.Column>
                <h1> {(selectedClient || {}).name}</h1>
                <div>
                  <h2>
                    {(selectedMission || {}).name}{' '}
                    {isArchived ? (
                      <Popup trigger={<Icon name='bell slash outline' />} content={'Archived Job'} />
                    ) : null}
                  </h2>
                </div>

                <h3> Job Info </h3>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Table>
                      <Table.Body>
                        {_.map(missionInfos, (stat, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>{stat.title}</Table.Cell>
                            <Table.Cell>{stat.value}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column
                    width={7}
                    style={{
                      marginLeft: '30px',
                    }}
                  >
                    <Card>
                      <Card.Content>
                        <Card.Header>
                          <h4>Skip Reasons</h4>
                          <br />
                        </Card.Header>
                        <div
                          style={{
                            maxHeight: '300px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                          }}
                        >
                          <Card.Description>
                            <Table>
                              <Table.Body>
                                {_.map(sortedSkipReasons, (action, index) => (
                                  <Table.Row key={index}>
                                    <Table.Cell>{action.date && new Date(action.date).toDateString()}</Table.Cell>
                                    <Table.Cell>{action.reason}</Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Body>
                            </Table>
                          </Card.Description>
                        </div>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                <br />
                <Grid.Row>
                  <h3> Current SweetApp Criteria </h3>
                  <br />
                </Grid.Row>
                {_.isUndefined(sweetappCriteriaInSweethubForm) || _.isEmpty(sweetappCriteriaInSweethubForm) ? (
                  'Client has not defined any criteria. '
                ) : (
                  <div>
                    Display Criteria :
                    <Checkbox
                      toggle
                      checked={toggleSweetappCriteria}
                      onChange={() => this.clickOnToggleSweetappCriteria()}
                    />
                    {toggleSweetappCriteria ? <Offer offer={{ criteria: sweetappCriteriaInSweethubForm }} /> : null}
                  </div>
                )}
                <br />
                <br />
                <ConfigSearchView
                  selectedClient={selectedClient}
                  selectedMissionId={selectedMission.id}
                  sweetappCriteria={sweetappCriteria}
                  key={selectedMission.id}
                />
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default ManualRecommendations;
