import { Button, Grid, Icon, Loader, Modal, TextArea } from 'semantic-ui-react';

import GenericWidget from './GenericWidget';
import React from 'react';
import _ from 'underscore';

class WidgetDeletionModal extends React.Component {
  render() {
    const { onCancel, widget, onSubmit } = this.props;

    return (
      <Modal open={true}>
        <Modal.Content>
          <p>{`Are you sure you want to delete this widget ?`}</p>
          <div>
            <div>Id: {widget.id}</div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <Button color='blue' onClick={() => onCancel()}>
              CANCEL
            </Button>
            <Button color='red' onClick={() => onSubmit(widget.id)}>
              <Icon name='trash outline' /> DELETE
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default WidgetDeletionModal;
