import _ from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import { Segment, Icon, List, Card, Grid, Button, Form, Modal, Header, Table } from 'semantic-ui-react';
import CustomModal from '../Modal';
import axios from 'axios';
import ProfileView from '../SweetComponents/Profile/View';
import OfferV5 from '../components/OfferV5';
import { SweetForm, Select } from 'sweetform';
import { Textarea } from '../common';
import { Tooltip } from 'recharts';

class CreateMatchCollectionModal extends Component {
  handleChange(params) {
    this.setState({
      ...params,
    });
  }
  render() {
    const { onCancel, onSubmit, jobCollectionOptions, profileCollectionOptions } = this.props;
    return (
      <Modal open closeIcon open={true} onClose={onCancel}>
        <Modal.Content size='fullscreen'>
          <SweetForm onChange={(params) => this.handleChange(params)}>
            <Form.Field>
              <label>Title</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='title'
                label='title'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Id</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '35px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='id'
                label='id'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '200px',
                  overflow: 'hidden',
                  display: 'table-cell',
                }}
                field='description'
                label='description'
                placeholder=''
              />
            </Form.Field>
            <Form.Field>
              <label>Job Collection</label>
              <Select field='jobCollectionId' options={jobCollectionOptions} />
            </Form.Field>
            <Form.Field>
              <label>Profile Collection</label>
              <Select field='profileCollectionId' options={profileCollectionOptions} />
            </Form.Field>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class MatchCollectionView extends Component {
  onClickOnMatch = async (match) => {
    if (!match.profile_path || !match.job_path) {
      return;
    }
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getProfileV5';
    const payload = {
      profile: {
        mode: 'cloud-storage',
        profilePath: match.profile_path,
      },
      offer: {
        companyId: 'hiresweet',
      },
    };
    const selectedTalent = (await axios.post(url, payload, customHeaders)).data;
    console.log(selectedTalent);
    const fetchUrl = baseUrl + '/sweetchain/getJobV5';
    const selectedJob = (await axios.post(fetchUrl, { jobPath: match.job_path }, customHeaders)).data;
    console.log(selectedJob);

    this.setState({
      selectedTalent: selectedTalent,
      selectedJob: selectedJob,
    });
  };

  onCancel = () => {
    this.setState({
      selectedTalent: null,
      selectedJob: null,
    });
  };
  render() {
    const { matches } = this.props;
    const { selectedTalent, selectedJob } = this.state || {};
    return (
      <div>
        {selectedTalent ? (
          <Modal open closeIcon onClose={this.onCancel}>
            <Modal.Content size='fullscreen'>
              <ProfileView data={selectedTalent.profile} />
              <OfferV5 offer={selectedJob} />
            </Modal.Content>
          </Modal>
        ) : null}

        <Table
          style={{
            width: '100%',
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>Score</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Job</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Job Path</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Talent</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Talent Path</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(matches || [], (match, index) => (
              <Table.Row key={index}>
                <Table.Cell textAlign='center' style={{ cursor: 'pointer' }} onClick={() => this.onClickOnMatch(match)}>
                  {match.score}
                </Table.Cell>
                <Table.Cell textAlign='center' style={{ cursor: 'pointer' }} onClick={() => this.onClickOnMatch(match)}>
                  {match.job_id}
                </Table.Cell>
                <Table.Cell textAlign='center' style={{ cursor: 'pointer' }} onClick={() => this.onClickOnMatch(match)}>
                  {match.job_path}
                </Table.Cell>
                <Table.Cell textAlign='center' style={{ cursor: 'pointer' }} onClick={() => this.onClickOnMatch(match)}>
                  {match.profile_id}
                </Table.Cell>
                <Table.Cell textAlign='center' style={{ cursor: 'pointer' }} onClick={() => this.onClickOnMatch(match)}>
                  {match.profile_path}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

class Matches extends Component {
  handleClickOnCollection = (e, { value }) => {
    this.setState({ selectedMatchCollectionId: value });
    this.loadMatches(value);
  };

  loadMatches = async (selectedMatchCollectionId) => {
    //this.setState({ matches: [{ profile: 'toto', offer: 'tata', score: 'titi' }] });

    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/sweetchain/getMatches/' + selectedMatchCollectionId;
    const result = (await axios.get(url, customHeaders)).data;
    this.setState({ matches: result.results });
  };

  submitMatchCollection = async (params) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const { projectId, userId } = this.props;
    const payload = {
      ...params,
      projectId,
      userId,
    };
    const createUrl = baseUrl + '/sweetchain/createMatchCollection';
    const result = (await axios.post(createUrl, payload, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ selectedJobCollectionId: null });
  };

  handleClickDeleteMatchCollectionOpenModal = async () => {
    this.setState({ onDeleteModalVisible: true });
  };

  handleClickDeleteMatchCollection = async (matchCollectionId) => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const deleteUrl = baseUrl + '/sweetchain/deleteMatchesCollectionAndWorkflow';
    const result = (await axios.post(deleteUrl, { matchCollectionId }, customHeaders)).data;
    if (!result.success) {
      alert('something went wrong');
    }
    this.setState({ onDeleteModalVisible: false });
  };

  cancelCreateMatchCollectionModal = () => {
    this.setState({ selectedMatchCollectionId: null });
  };

  render() {
    const { matchCollections, projectId, profileCollections, jobCollections } = this.props;
    const { matches, selectedMatchCollectionId, onDeleteModalVisible } = this.state || {};
    const matchCollectionOptions = _.map(matchCollections, (matchCollection) => ({
      value: matchCollection.id,
      text: matchCollection.name ? matchCollection.name : matchCollection.title,
      key: matchCollection.id,
    })).concat({
      value: 'new-match-collection',
      key: 'new-match-collection',
      text: '+ New Match Collection',
    });

    const jobCollectionOptions = _.map(jobCollections, (jobCollection) => ({
      value: jobCollection.id,
      text: jobCollection.name ? jobCollection.name : jobCollection.title,
      key: jobCollection.id,
    }));

    const profileCollectionOptions = _.map(profileCollections, (profileCollection) => ({
      value: profileCollection.id,
      text: profileCollection.name ? profileCollection.name : profileCollection.title,
      key: profileCollection.id,
    }));

    const newJobCollectionModalVisible = selectedMatchCollectionId === 'new-match-collection';

    return (
      <div>
        {newJobCollectionModalVisible ? (
          <CreateMatchCollectionModal
            projectId={projectId}
            onCancel={this.cancelCreateMatchCollectionModal}
            onSubmit={this.submitMatchCollection}
            jobCollectionOptions={jobCollectionOptions}
            profileCollectionOptions={profileCollectionOptions}
          />
        ) : (
          <div>
            {onDeleteModalVisible ? (
              <CustomModal
                active={true}
                headerText={`Delete Match Collection ${selectedMatchCollectionId}`}
                submitText='Confirm'
                onCancel={this.cancelCreateMatchCollectionModal}
                onSubmit={() => this.handleClickDeleteMatchCollection(selectedMatchCollectionId)}
              >
                <p>Are you sure? This operation cannot be reverted.</p>
              </CustomModal>
            ) : null}
            <Grid rows={3}>
              <Grid.Row>
                <Form.Select
                  search
                  placeholder='Select Match Collections'
                  options={matchCollectionOptions}
                  onChange={this.handleClickOnCollection}
                  value={selectedMatchCollectionId}
                />
              </Grid.Row>
              {selectedMatchCollectionId ? (
                <div>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Button onClick={() => this.handleClickDeleteMatchCollectionOpenModal()} color='red'>
                        Delete Collection
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </div>
              ) : null}
              <Grid.Row>
                {selectedMatchCollectionId ? (
                  <MatchCollectionView
                    matches={matches}
                    jobCollectionId={selectedMatchCollectionId}
                    key={selectedMatchCollectionId}
                  />
                ) : null}
              </Grid.Row>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default Matches;
