import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { List, Message } from 'semantic-ui-react';

const NoProfilesView = ({ currentSearchStatus, searchStatusEvents, noMoreItems }) => {
  const creationDate =
    (searchStatusEvents || []).length > 0 ? moment(_.first(searchStatusEvents).timestamp, 'x') : moment();
  if (noMoreItems && (currentSearchStatus === 'success' || !currentSearchStatus)) {
    return (
      <div>
        <Message negative>
          <p>No profile available.</p>
        </Message>
      </div>
    );
  }

  if (currentSearchStatus === 'success') {
    return <p>Loading...</p>;
  } else {
    return (
      <div>
        <div>
          <List relaxed>
            {_.map(searchStatusEvents, (event) => {
              return (
                <List.Item>
                  <List.Icon
                    name={
                      (event || {}).type === 'uncatched-error'
                        ? 'bomb'
                        : (event || {}).type === 'filters-too-tight'
                        ? 'delete'
                        : (event || {}).type === 'error'
                        ? 'delete'
                        : 'check'
                    }
                    size='large'
                    verticalAlign='middle'
                    color={
                      (event || {}).type === 'uncatched-error'
                        ? 'black'
                        : (event || {}).type === 'filters-too-tight'
                        ? 'black'
                        : (event || {}).type === 'error'
                        ? 'red'
                        : 'green'
                    }
                  />
                  <List.Content>
                    <List.Header>{(event || {}).type}</List.Header>
                    <List.Description>
                      {(event || {}).message} - {moment((event || {}).timestamp, 'x').fromNow()}
                    </List.Description>
                  </List.Content>
                </List.Item>
              );
            })}
            {currentSearchStatus === 'pending' ? (
              <List.Item>
                <List.Icon
                  name='spinner'
                  size='large'
                  verticalAlign='middle'
                  loading
                  style={{ padding: 0 }}
                  color='grey'
                />
                <List.Content>
                  <List.Header>Search computing...</List.Header>
                  <List.Description>Creation: {creationDate.fromNow()}</List.Description>
                </List.Content>
              </List.Item>
            ) : null}
          </List>
        </div>
      </div>
    );
  }
};

export default NoProfilesView;
