import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import { Input, List, Range, Select, SweetForm, customOperator, enhance } from 'sweetform';
import React, { Component } from 'react';

import { Textarea } from '../common';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import { extraCriteriaFilters } from '../Offers/SearchModal';

export const getSkillIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=skill`);
  const skillList = !result.data.error ? result.data : [];
  return {
    options: skillList,
  };
};

export const getJobPositions = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=job`);
  const jobList = !result.data.error ? result.data : [];
  const { contextualJobs } = (await axios.get(`${baseUrl}/tags/contextualJobs`)).data;
  return {
    options: jobList.concat(_.map(contextualJobs || [], ({ id, name }) => ({ id, name: name + ' [contextual]' }))),
  };
};

export const getEducationFieldIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=education-field`);
  const educationFieldList = !result.data.error ? result.data : [];
  return {
    options: educationFieldList,
  };
};

export const getIndustryIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=industry`);
  const industryList = !result.data.error ? result.data : [];
  return {
    options: industryList,
  };
};

export const getStudyLevelIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=study-level`);
  const studyLevelList = !result.data.error ? result.data : [];
  return {
    options: studyLevelList,
  };
};

export const getJobModalityIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=job-modality`);
  const jobModalityList = !result.data.error ? result.data : [];
  return {
    options: jobModalityList,
  };
};

export const getJobResponsibilityIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=job-responsibility`);
  const jobResponsibilityList = !result.data.error ? result.data : [];
  return {
    options: jobResponsibilityList,
  };
};

export const getHierarchicalRankIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=hierarchical-rank`);
  const hierarchicalRankList = !result.data.error ? result.data : [];
  return {
    options: hierarchicalRankList,
  };
};

export const getJobSeniorityIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=job-seniority`);
  const jobSeniorityList = !result.data.error ? result.data : [];
  return {
    options: jobSeniorityList,
  };
};

export const getEntities = async () => {
  const result = await axios.get(`${baseUrl}/tags/list`);
  const entities = !result.data.error ? result.data : [];
  return {
    options: entities,
  };
};

const deprecatedFilterIdsInExtraCriteria = [
  'targetLocation',
  'targetLocationRelaxed',
  'everBeenToRelaxed',
  'targetJobPosition',
  'targetJobPositionRelaxed',
  'targetResponsibilityRelaxed',
  'targetModality',
  'experienceTechYearRelaxedRange',
  'nbAnswersRange',
  'nbPositiveAnswersRange',
  'nbAnyAnswersRange',
  'schoolCategoryRange',
  'solicitationRange',
  'hasSkill',
  'isFreelance',
  'watchFilter',
  'baseFilter',
  'githubRepository',
  'githubFollowers',
  'matchSheetsSet',
  'schoolNamesScorer',
  'currentLinkedinCompanyScorer',
  'currentCompanyScorer',
  'instantFlowMatchOffer',
  'isInstantFlow',
  'everAnsweredPositively',
  'everPushedToClient',
  'hasEmail',
  'hasGithubEmail',
  'worksForClient',
  'disqualifyRate',
  'profileLastUpdate',
  'companyType',
  'companyIndustry',
  'companyPrestige',
  'jobScoresS6',
  'skillScoresS6',
  'background',
];

export const SelectExtraCriteriaAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            options={_.map(extraCriteriaFilters, (val, key) => ({
              value: key,
              label: key,
            })).concat(
              _.map(deprecatedFilterIdsInExtraCriteria, (item) => ({
                value: item,
                label: '~' + item,
              })),
            )}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectSkillAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getSkillIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectJobAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getJobPositions}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectEducationFieldAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getEducationFieldIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectIndustryAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getIndustryIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectStudyLevelAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getStudyLevelIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectJobModalityAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getJobModalityIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectJobResponsibilityAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getJobResponsibilityIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectHierarchicalRankAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getHierarchicalRankIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectJobSeniorityAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getJobSeniorityIds}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

const MANAGEMENT_OPTIONS = [
  { label: 'Lead', value: 'lead' },
  { label: 'Head Of', value: 'head-of' },
  { label: 'VP', value: 'vp' },
  { label: 'C Level', value: 'c-level' },
  { label: '1-3', value: '1-3' },
  { label: '4-20', value: '4-20' },
  { label: '20+', value: '20-' },
];
export const SelectManagementAndRange = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <span>
          <Select style={{ minWidth: '300' }} field='entity' async options={MANAGEMENT_OPTIONS} multi={false} />
        </span>
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={0} max={100} step={5} />
      </Grid.Row>
    </Grid>
  );
});

const SelectStartupnessRange = enhance((props) => {
  return (
    <div>
      <Select
        style={{ minWidth: '300' }}
        field='entity'
        options={[{ label: 'startupness', value: 'startupness' }]}
        defaultValue={'startupness'}
        multi={false}
        disabled={true}
      />
      <Range field='scoreRange' min={0} max={100} step={5} />
    </div>
  );
});

export const SelectEntities = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <Select
          style={{ minWidth: '300' }}
          field='constraint'
          options={[
            { value: 'should-contain', label: 'Should Contain' },
            { value: 'should-not-contain', label: 'Should Not Contain' },
          ]}
        />
      </Grid.Row>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getEntities}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
    </Grid>
  );
});

export const SelectJobPosition = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <Select
          style={{ minWidth: '300' }}
          field='constraint'
          options={[
            { value: 'should-contain', label: 'Should Contain' },
            { value: 'should-not-contain', label: 'Should Not Contain' },
          ]}
        />
      </Grid.Row>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            async
            loadOptions={getJobPositions}
            labelKey='name'
            valueKey='id'
            multi={false}
          />
        </span>
      </Grid.Row>
    </Grid>
  );
});

export const SelectPositiveOrNegative = enhance((props) => {
  return (
    <Grid rows={2}>
      <Grid.Row>
        <Select
          style={{ minWidth: '300' }}
          field='constraint'
          options={[
            { value: 'should-contain', label: 'Should Contain' },
            { value: 'should-not-contain', label: 'Should Not Contain' },
          ]}
        />
      </Grid.Row>
      <Grid.Row>
        <span>
          <Select
            style={{ minWidth: '300' }}
            field='entity'
            options={[{ value: 'positive', label: 'Positive' }, { value: 'negative', label: 'Negative' }]}
          />
        </span>
      </Grid.Row>
    </Grid>
  );
});

const TYPE_SELECTION_OPTIONS = [
  { type: 'skill', component: SelectSkillAndRange },
  { type: 'job', component: SelectJobAndRange },
  { type: 'industry', component: SelectIndustryAndRange },
  { type: 'modality', component: SelectJobModalityAndRange },
  { type: 'responsibility', component: SelectJobResponsibilityAndRange },
  { type: 'study_level', component: SelectStudyLevelAndRange },
  { type: 'education_field', component: SelectEducationFieldAndRange },
  { type: 'seniority', component: SelectJobSeniorityAndRange },
  { type: 'hierarchical_rank', component: SelectHierarchicalRankAndRange },
  { type: 'startupness', component: SelectStartupnessRange },
];
export const SelectTypeToConstraints = customOperator(
  _.object(
    _.map(TYPE_SELECTION_OPTIONS, (item) => [
      item.type,
      { label: item.type, children: 1, component: item.component, nested: true },
    ]),
  ),
  false,
);

export const SelectBoolean = enhance((props) => {
  return (
    <Grid rows={1}>
      <Grid.Row>
        <Select
          style={{ minWidth: '300' }}
          field='constraint'
          options={[{ value: 'true', label: 'True' }, { value: 'false', label: 'False' }]}
        />
      </Grid.Row>
    </Grid>
  );
});

export const SelectRange = enhance((props) => {
  return (
    <Grid>
      <Range field='scoreRange' min={0} max={100} step={5} />
    </Grid>
  );
});

export const SelectYear = enhance((props) => {
  return (
    <Grid>
      <Range field='scoreRange' min={1930} max={2030} step={1} />
    </Grid>
  );
});

export const SelectXpYearAndJob = enhance((props) => {
  return (
    <Grid>
      <Grid.Row>
        <Select
          style={{ minWidth: '300' }}
          field='entity'
          async
          loadOptions={getJobPositions}
          labelKey='name'
          valueKey='id'
          multi={false}
        />
      </Grid.Row>
      <Grid.Row>
        <Range field='scoreRange' min={-3} max={25} step={1} />
      </Grid.Row>
    </Grid>
  );
});

export const SelectXpYear = enhance((props) => {
  return (
    <Grid>
      <Range field='scoreRange' min={-3} max={25} step={1} />
    </Grid>
  );
});

export const STATUS_OPTIONS = [
  { label: 'pending', value: 'pending' },
  { label: 'postponed', value: 'postponed' },
  { label: 'conflictual', value: 'conflictual' },
];

class TestEditModal extends Component {
  async componentWillMount() {
    const { initialValue } = this.props;
    let currentValue = _.pick(initialValue, 'constraints', 'comment', 'name', 'status', 'techComment', 'tags');
    this.setState({
      currentValue: currentValue,
    });
  }
  handleChangeForm = (value) => {
    this.setState({ currentValue: value });
  };

  render() {
    const { resultType, onClose, onCreate, onEdit, sweetfunctionId, initialValue, creationMode } = this.props;
    const { currentValue } = this.state || {};
    const { constraints, comment, name, techComment, status, tags } = currentValue || {};
    const tagOptions = _.map(this.props.tagIds, (tagId) => ({
      value: tagId,
      label: tagId,
    }));
    return (
      <Modal onClose={onClose} open size='fullscreen' closeIcon>
        <Modal.Content size='fullscreen'>
          <SweetForm initialValues={initialValue} onChange={this.handleChangeForm}>
            <Grid columns={2}>
              <Grid.Column>
                <h3 style={{ display: 'inline' }}> Title : </h3>
                <Input field='name' />
                <h3>Properties : </h3>
                {resultType === 'score' || resultType === 'score-prestige' ? (
                  <List field='constraints' component={SelectRange} />
                ) : resultType === 'score-dict' || resultType === 'normalized-score-dict' ? (
                  (sweetfunctionId || '').includes('skill') ? (
                    <List field='constraints' component={SelectSkillAndRange} />
                  ) : (sweetfunctionId || '').includes('absolute') || sweetfunctionId == 'magic_filter_bigquery' ? (
                    <List field='constraints' component={SelectExtraCriteriaAndRange} />
                  ) : (sweetfunctionId || '').includes('management') ? (
                    <List field='constraints' component={SelectManagementAndRange} />
                  ) : (sweetfunctionId || '').includes('job') ? (
                    <List field='constraints' component={SelectJobAndRange} />
                  ) : sweetfunctionId || '' === 'profile_education_prestige' ? (
                    <List field='constraints' component={SelectJobAndRange} />
                  ) : null
                ) : resultType === 'parsed-entities' ? (
                  (sweetfunctionId || '').includes('propagated') ? (
                    <List field='constraints' component={SelectJobPosition} />
                  ) : (sweetfunctionId || '').includes('signals') ? (
                    <List field='constraints' component={SelectPositiveOrNegative} />
                  ) : (
                    <List field='constraints' component={SelectEntities} />
                  )
                ) : resultType === 'dict-score-dict' ? (
                  <List field='constraints' component={SelectTypeToConstraints} />
                ) : resultType === 'boolean' ? (
                  <List field='constraints' component={SelectBoolean} />
                ) : resultType === 'year' ? (
                  <List field='constraints' component={SelectYear} />
                ) : resultType === 'xp-year' ? (
                  <List field='constraints' component={SelectXpYear} />
                ) : resultType === 'xp-dict' ? (
                  <List field='constraints' component={SelectXpYearAndJob} />
                ) : null}
                <h3>Tags</h3>
                <Select multi search field='tags' options={tagOptions} />
                <h3>Comment (optional) :</h3>
                <Textarea field='comment' placeholder='Comments' rows='10' cols='80' />
              </Grid.Column>
              <Grid.Column>
                {!creationMode && (
                  <div>
                    <h3>Tech Comment</h3>
                    <Textarea field='techComment' placeholder='Tech Comments' rows='10' cols='80' />
                    <h3>Status</h3>
                    <Select field='status' options={STATUS_OPTIONS} />
                  </div>
                )}
              </Grid.Column>
            </Grid>
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          {creationMode ? (
            <Button color='green' onClick={() => onCreate({ constraints, comment, name, tags })}>
              Save Test
            </Button>
          ) : (
            <Button
              color='green'
              onClick={() => onEdit({ testId: initialValue.id, constraints, comment, name, techComment, status, tags })}
            >
              Edit Test
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default TestEditModal;
