import _ from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Table, Button } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

class ThreadsRefreshTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'day',
    };
    this.handleLoadData();
  }
  handleLoadData = async () => {
    const byDays = await this.handleLoadDataByDays();
    const byHours = await this.handleLoadDataByHours();
    this.setState({
      byDays,
      byHours,
    });
  };
  handleLoadDataByDays = async () => {
    const nbDays = 14;
    const timestamp = Date.now();
    const nbMsInADay = 24 * 3600 * 1000;
    const minTimestamp = timestamp - nbDays * nbMsInADay;
    const maxTimestamp = timestamp;
    const timestampSplits = [];
    for (let i = 1; i < nbDays; i++) {
      timestampSplits.push(minTimestamp + i * nbMsInADay);
    }
    const { data } = await axios.post(
      baseUrl + '/station/threads/refreshQueries',
      {
        minTimestamp,
        maxTimestamp,
        timestampSplits,
      },
    );
    return data;
  };
  handleLoadDataByHours = async () => {
    const nbHours = 14;
    const timestamp = Date.now();
    const nbMsInAnHour = 3600 * 1000;
    const minTimestamp = timestamp - nbHours * nbMsInAnHour;
    const maxTimestamp = timestamp;
    const timestampSplits = [];
    for (let i = 1; i < nbHours; i++) {
      timestampSplits.push(minTimestamp + i * nbMsInAnHour);
    }
    const { data } = await axios.post(
      baseUrl + '/station/threads/refreshQueries',
      {
        minTimestamp,
        maxTimestamp,
        timestampSplits,
      },
    );
    return data;
  };
  handleChangeMode = (mode) => {
    this.setState({ mode });
  };
  renderRow = ({ bucket, type, index }) => {
    const stats = ((bucket || {}).stats || {})[type] || {};
    const formatDate = (timestamp) =>
      new Date(timestamp).toISOString().slice(2, this.state.day ? 10 : 16);
    const formatPercent = (value) => {
      return '' + Math.round(value * 10000) / 100 + '%';
    };
    return (
      <Table.Row key={index}>
        <Table.Cell>
          <span>{formatDate(bucket.minTimestamp)}</span>
          <br />
          <span>{formatDate(bucket.maxTimestamp)}</span>
        </Table.Cell>
        <Table.Cell>
          {stats.nbQueries && <span>{stats.nbQueries || 0}</span>}
        </Table.Cell>
        <Table.Cell>
          {stats.nbQueries && <span>{stats.nbCalls || 0}</span>}
        </Table.Cell>
        <Table.Cell>
          {stats.nbQueries && (
            <span>
              {stats.nbRetrieved || 0} (
              {formatPercent((stats.nbRetrieved || 0) / (stats.nbCalls || 1))})
            </span>
          )}
        </Table.Cell>
        <Table.Cell>
          {stats.nbQueries && (
            <div>
              <span>{stats.nbWarnings || 0}</span>
              {stats.warningCounts && (
                <div>
                  {_.map(stats.warningCounts, (count, key) => (
                    <div key={key}>
                      {key}: {count}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          {stats.minCalls && (
            <span>
              {stats.minCalls}-{stats.medianCalls}-{stats.maxCalls}
            </span>
          )}
        </Table.Cell>
        <Table.Cell>
          {stats.minSpanInMs && (
            <span>
              {stats.minSpanInMs}-{stats.medianSpanInMs}-{stats.maxSpanInMs}
            </span>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };
  renderStatsTable = ({ type, buckets }) => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#slot</Table.HeaderCell>
            <Table.HeaderCell>#queries</Table.HeaderCell>
            <Table.HeaderCell>#calls</Table.HeaderCell>
            <Table.HeaderCell>#retrieved</Table.HeaderCell>
            <Table.HeaderCell>#warnings</Table.HeaderCell>
            <Table.HeaderCell>query-calls</Table.HeaderCell>
            <Table.HeaderCell>query-spans (ms)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(_.sortBy(buckets, (x, i) => -i), (bucket, index) =>
            this.renderRow({ bucket, type, index }),
          )}
        </Table.Body>
      </Table>
    );
  };
  renderStatsTables = ({ types, buckets }) => {
    return (
      <div>
        {_.map(types, (type) => (
          <div key={type}>
            <h2>{type}</h2>
            {this.renderStatsTable({ type, buckets })}
            <br />
          </div>
        ))}
      </div>
    );
  };
  renderStats() {
    if (!this.state.byDays || !this.state.byHours) {
      return (
        <div>
          <br />
          <br />
          <center>Loading</center>
        </div>
      );
    }
    console.log(this.state.byDays);
    const allTypes = [];
    _.each(
      [
        ...((this.state.byDays || {}).buckets || []),
        ...((this.state.byHours || {}).buckets || []),
      ],
      (bucket) => {
        _.each(_.keys(bucket.stats || {}), (type) => {
          allTypes.push(type);
        });
      },
    );
    const types = _.sortBy(_.uniq(allTypes));
    console.log(types);
    const buckets =
      this.state.mode === 'day'
        ? (this.state.byDays || {}).buckets || []
        : (this.state.byHours || {}).buckets || [];
    return this.renderStatsTables({ types, buckets });
  }
  render() {
    return (
      <div>
        <h1>Refresh Tasks Overview</h1>
        <div>
          <Button
            color={this.state.mode === 'day' ? 'green' : 'grey'}
            onClick={() => this.handleChangeMode('day')}
          >
            Days
          </Button>
          <Button
            color={this.state.mode === 'hour' ? 'green' : 'grey'}
            onClick={() => this.handleChangeMode('hour')}
          >
            Hours
          </Button>
        </div>
        {this.renderStats()}
      </div>
    );
  }
}

export default ThreadsRefreshTasks;
