import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import React, { Component } from 'react';
import { Grid, Icon, Button, Header, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Input, Select, List, SweetForm, Checkbox, enhance } from 'sweetform';
import { Textarea } from '../../common';
import { GRADES } from './utils';
import ClientProfile from '../../components/ClientProfile';
import { MAHER_CATEGORIES } from '../../Sales/SalesBoard/EditOfferModalComponents';
import Modal from '../../Modal';

const formAdminToSheetAdmin = (admin) => {
  return admin;
};

const sheetAdminToFormAdmin = (admin) => {
  return admin;
};

const loadUsers = async (input) => {
  const users = (await axios.get(`${baseUrl}/users`)).data;
  return { options: users, complete: true };
};

const SelectUsers = (props) => <Select {...props} async={true} loadOptions={loadUsers} labelKey='name' valueKey='id' />;

const CONTACT_TYPE_OPTIONS = [
  { label: 'Phone', value: 'phone' },
  { label: 'Mail', value: 'mail' },
  { label: 'Linkedin', value: 'linkedin' },
];

const SelectContactType = (props) => <Select options={CONTACT_TYPE_OPTIONS} {...props} />;

const getClients = async (input) => {
  const result = await axios.get(`${baseUrl}/station/clients?limit=100&filter=${input}`);
  return { options: result.data.error ? [] : result.data };
};

const SelectForbiddenCompany = enhance((props) => {
  return (
    <Grid columns={2}>
      <Grid.Column width={8}><Select async field='clientId' loadOptions={getClients} labelKey='name' valueKey='id' placeholder={'client id'} /></Grid.Column>
      <Grid.Column width={8}><Input async field='companyName' placeholder={'name'} /></Grid.Column> 
    </Grid>
  );
});

const discoverLinkFromProfileId = (profileId) => {
  const searchPoolId = "watch"
  const spId = encodeURIComponent(btoa(searchPoolId));
  const sppId = encodeURIComponent(btoa(profileId));
  const querystring = `?spId=${spId}&sppId=${sppId}`;
  return `https://app.hiresweet.com/redirect/discover/candidates${querystring}`;
}

class WatchAdmin extends Component {
  state = {
    sources: null,
    watchCollectModalOpen: false,
  };
  componentWillMount() {
    this.load();
  }

  load = async () => {
    try {
      const { sources } = (await axios.get(`${baseUrl}/candidates/sources`)).data;
      const sourcesOptions = _.map(sources, ({ id, internalName }) => ({ label: internalName, value: id }));
      this.setState({ sourcesOptions });
    } catch (e) {
      alert('Failed loading sources.');
    }
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  handleSave = async () => {
    try {
      await this.props.onSubmit(formAdminToSheetAdmin(this.state.value));
      alert('Saved.');
    } catch (e) {
      alert(e.message);
    }
  };


  handleOpenWatchCollectModal = () => {
    this.setState({ watchCollectModalOpen: true });
  };
  handleCloseWatchCollectModal = () => {
    this.setState({ watchCollectModalOpen: false });
  };
  handleUpdateCandidateOnCollect = async () => {
    try {
      const { id } = this.props;
      const data = (await axios.get(`${baseUrl}/candidates/${id}/updateWatchCollect`)).data;
      if (!data.success) {
        throw Error(data.error);
      }

      alert('Candidate updated :' + JSON.stringify(data));
    } catch (e) {
      console.log(e);
      alert('Failed updating watch profile : ' + e.message);
    }
  };

  handleUpdateCandidatePendingsAndCollect = async () => {
    try {
      const { id } = this.props;
      const data = (await axios.get(`${baseUrl}/candidates/${id}/updatePendingsAndCollect`)).data;
      alert('Candidate updated :' + JSON.stringify(data));
    } catch (e) {
      console.log(e);
      alert('Failed updating watch profile : ' + e.message);
    }
  };

  handleUpdateCandidateAllAndCollect = async () => {
    try {
      const { id } = this.props;
      const data = (await axios.get(`${baseUrl}/candidates/${id}/updateAllProfilesAndCollect`)).data;
      alert('Candidate updated :' + JSON.stringify(data));
    } catch (e) {
      console.log(e);
      alert('Failed updating watch profile : ' + e.message);
    }
  };

  render() {
    const { admin, id } = this.props;
    const { sourcesOptions, watchCollectModalOpen } = this.state;
    const gradeOptions = _.map(GRADES, ({ value }) => ({ value, label: value }));
    const formattedAdmin = sheetAdminToFormAdmin(admin || {});
    return (
      <div>
        <Button onClick={() => this.handleSave()} color='green'>
          <Icon name='save' /> Save
        </Button>
        <SweetForm initialValues={formattedAdmin} onChange={this.handleChange}>
          <Grid>
            <Grid.Row columns={5}>
              <Grid.Column width={4}>
                <label>
                  <b>All Candidates</b>
                </label>
              </Grid.Column>
              <Grid.Column width={3}>
                <Popup
                  trigger={
                    <Button 
                      icon
                      onClick={() => {
                        this.handleUpdateCandidateOnCollect();
                      }}
                      color='blue'
                    >
                      <Icon name='refresh' />
                    </Button>
                  }
                  content={<div>
                  <b>Synchronize the candidate in All Candidates</b><br/>
                  If the candidate is Enabled, it will make it visible to clients.<br/>
                  </div>}
                />
                
              </Grid.Column>
              <Grid.Column width={3}>
                <Popup
                  trigger={
                    <Button
                        icon
                        onClick={() => {
                          this.handleOpenWatchCollectModal();
                        }}
                      >
                        <Icon name='eye' />
                      </Button>
                  }
                  content={<div>
                  <b>Display candidate as it is in All Candidates</b>
                  </div>}
                />

              </Grid.Column>
              <Grid.Column width={3}>
                <Link
                  to={discoverLinkFromProfileId(id)}
                  target="__blank"
                >
                  <Icon color="blue" name='external' />
                  Discover View
                </Link>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Update TLC and Pendings</b>
                  <Popup
                    trigger={<Icon name='question circle' color='blue' />}
                    content={<div>Synchronize candidate data on client accounts only for pending profiles and TLC.</div>}
                  />
                </label>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  icon
                  onClick={() => {
                    this.handleUpdateCandidatePendingsAndCollect();
                  }}
                >
                  <Icon name='refresh' />
                </Button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Update TLC and All Profiles</b>
                  <Popup
                    trigger={<Icon name='question circle' color='blue' />}
                    content={<div>Synchronize candidate data everywhere on client accounts. Might take some time.</div>}
                  />
                </label>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  icon
                  onClick={() => {
                    this.handleUpdateCandidateAllAndCollect();
                  }}
                >
                  <Icon name='refresh' />
                </Button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Users</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <List field='users' component={SelectUsers} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Source</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Select options={sourcesOptions} field='source' placeholder='Source' />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Grade</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Select options={gradeOptions} field='grade' placeholder='Grade' />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Category</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Select field='category' labelKey='name' valueKey='id' options={MAHER_CATEGORIES} />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Manual Referred By</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input field='manualOriginReferralToken' placeholder='token' />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <Popup
                    trigger={<Icon name='question circle' color='blue' />}
                    content={<div>Companies where candidate has worked are blacklisted by default.</div>}
                  />
                  <b>Blacklisted Companies</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <List field={'forbiddenCompanies'} component={SelectForbiddenCompany} />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <label>
                  <b>Last contact</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input type='date' field='lastContactDay' />
              </Grid.Column>
              <Grid.Column width={4}>
                <SelectContactType field='lastContactType' />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Next contact</b>
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Input type='date' field='nextContactDay' />
              </Grid.Column>
              <Grid.Column width={6} />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label>
                  <b>Comment</b>
                </label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Textarea field='comment' placeholder='Comments' rows='30' cols='60' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SweetForm>
        {watchCollectModalOpen && <WatchCollectModal id={id} onCancel={this.handleCloseWatchCollectModal} />}
      </div>
    );
  }
}

class WatchCollectModal extends Component {
  state = {
    enrichedProfile: null,
    searchItem: null,
    loaded: false,
  };

  componentWillMount() {
    this.handleLoad();
  }

  handleLoad = async () => {
    try {
      const { id } = this.props;
      const { enrichedProfile, searchItem } = (await axios.get(`${baseUrl}/candidates/${id}/watchCollect`)).data;
      this.setState({
        loaded: true,
        enrichedProfile,
        searchItem,
      });
    } catch (e) {
      console.log(e);
      alert('Failed loading watch collect item');
    }
  };

  render() {
    const { enrichedProfile, searchItem, loaded } = this.state;

    return (
      <Modal
        active={true}
        headerText={'Watch collect profile ' + this.props.id}
        onCancel={this.props.onCancel}
        size='fullscreen'
        closeOnDimmerClick={true}
      >
        {!loaded ? (
          <div>Loading</div>
        ) : _.isEmpty(enrichedProfile) ? (
          <div>Profile not on collect</div>
        ) : (
          <Grid columns={2}>
            <Grid.Column width={8}>
              <Header as='h2'>Enriched Profile</Header>
              <ClientProfile profile={enrichedProfile} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h2'>Search Item</Header>
              <pre>{JSON.stringify(searchItem, null, 4)}</pre>
            </Grid.Column>
          </Grid>
        )}
      </Modal>
    );
  }
}

export default WatchAdmin;
