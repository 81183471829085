import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Label,
  List,
  Loader,
  Modal,
  Search,
  Input as SemanticInput,
} from 'semantic-ui-react';
import { Input, Range, Select, SelectInt, SweetForm, List as SweetList, customOperator, enhance } from 'sweetform';

import CustomFiltersMenu from './CustomFiltersMenu.js';
import React from 'react';
import SearchDocumentation from '../Offers/SearchDocumentation.js';
import { SearchForm } from '../Offers/SearchComponents/SearchForm.js';
import { SelectResponsibilities } from '../Sales/SalesBoard/EditOfferModalComponents';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl';
import { loadLocations, SelectLocation as SelectLocationRemoteWish } from '../Sales/SalesBoard/EditOmegaCriteria';
import moment from 'moment';
const OPERATOR_OPTIONS = [{ label: 'AND', value: '$and' }, { label: 'OR', value: '$or' }];

const getSearchTemplates = async () => {
  const url = `${baseUrl}/searchTemplates`;
  return axios.get(url).then(({ data }) => {
    if (data.error) {
      alert(data.error);
      return [];
    }
    if (!data.searchTemplates) {
      alert('no searchTemplates field in result');
      return [];
    }
    return data.searchTemplates;
  });
  /*
    return [
      {
        "user": "ismael",
        "title": "Template React",
        "filters" : [
          { "selection": "isFreelance" },
          { "selection": "minSkillScore", "skillId": "react.js",  }
        ]
      },
      {
        "user": "ismael",
        "title": "Template Angular",
        "filters" : [
          { "selection": "isFreelance" },
          { "selection": "minSkillScore", "skillId": "angular.js",  }
        ]
      },
      {
        "user": "Florent",
        "title": "Template KM",
        "filters": [
          {
            "selection": "#and",
            "#and": {
              "sons": [
                {
                  "selection": "experienceYearRange",
                  "experienceYearRange": {
                    "min": 2,
                    "max": 3
                  }
                },
                {
                  "selection": "experimentalMainSkill",
                  "skillId": "angular.js"
                }
              ]
            }
          },
          {
            "selection": "matchPipe",
            "matchPipe": {
              "pipeType": "station",
              "profileStatus": "all",
              "pipeId": "keymetrics-developpeur-angularjs"
            }
          }
        ],
        "copyable": true
      }    
    ];
    */
};

export const getSkillIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=skill`);
  return {
    options: !result.data.error ? result.data : [],
  };
};

const POSITIVE_ANSWER_THRESHOLD = _.map([0, 5, 10, 15, 20, 25, 30, 35, 40], (num) => ({
  value: num == 0 ? 0.01 : num,
  label: `Positive Answer Rate >  ${num / 10} %`,
}));

const CREATED_IN_PAST_NB_WEEKS_OPTIONS = [{ label: 'No creation filter', value: 0 }].concat(
  _.map(_.range(1, 100), (num) => ({
    value: num,
    label: `Created in past ${num} weeks`,
  })),
);

const getIndustryIds = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=industry`);
  return {
    options: !result.data.error ? result.data : [],
  };
};

const filterJobPositions = (jobList) => {
  const jobIdsToHide = ['ceo', 'diverse-job-position', 'military', 'technician', 'doctor'];
  return _.filter(jobList, (job) => {
    return !jobIdsToHide.includes(job.id);
  });
};

export const getJobPositions = async () => {
  const result = await axios.get(`${baseUrl}/tags/list?type=job`);
  const jobList = !result.data.error ? result.data : [];
  const jobListFiltered = filterJobPositions(jobList);
  return {
    options: jobListFiltered,
  };
};

const getSheets = async () => {
  const url = `${baseUrl}/sweetsheets`;
  return axios.get(url).then(({ data }) => {
    return {
      options: _.map(data, ({ id }) => ({
        value: id,
        label: id,
      })),
    };
  });
};

const getStationOffers = async () => {
  const url = `${baseUrl}/station/offers`;
  return axios.get(url).then(({ data }) => {
    return {
      options: _.map(data, ({ id, title, clientId }) => ({
        value: id,
        label: '[' + clientId + '] ' + title,
      })),
    };
  });
};

const getClients = async () => {
  const url = `${baseUrl}/station/clients`;
  return axios.get(url).then(({ data }) => {
    return {
      options: _.map(data, ({ id, name }) => ({
        value: id,
        label: '[' + id + '] ' + name,
      })),
    };
  });
};

const getSchoolGroups = async () => {
  const schoolGroups = (await axios.get(`${baseUrl}/workplaces/groups?type=school`)).data;

  return {
    options: _.map(schoolGroups, ({ id, name }) => ({ id, name })),
  };
};

const getCompanyGroups = async () => {
  const companyGroups = (await axios.get(`${baseUrl}/workplaces/groups?type=company`)).data;

  return {
    options: _.map(companyGroups, ({ id, name }) => ({ id, name })),
  };
};

const getSchoolsAndGroups = async () => {
  const schoolsList = (await axios.get(`${baseUrl}/workplaces/listSchools`)).data;
  const schoolGroups = (await axios.get(`${baseUrl}/workplaces/listSchoolGroups`)).data;
  const groupIdToGroup = _.indexBy(schoolGroups, 'id');
  // here groups are disjoint, merge included ones (add 'best' to 'medium', etc..)
  const groupsToAdd = {
    'medium-business-school': ['best-business-school'],
    'all-business-school': ['medium-business-school', 'best-business-school'],
    'medium-engineering-school': ['best-engineering-school'],
    'all-engineering-school': ['medium-engineering-school', 'best-engineering-school'],
  };
  _.each(groupIdToGroup, (group, groupId) => {
    if (groupsToAdd[groupId]) {
      _.each(groupsToAdd[groupId], (groupIdToAdd) => {
        groupIdToGroup[groupId].workplaceIds = groupIdToGroup[groupId].workplaceIds.concat(
          groupIdToGroup[groupIdToAdd].workplaceIds,
        );
      });
    }
  });

  // hack to pass all ids to formScorerToQuery
  const hackedSchoolGroups = _.map(_.values(groupIdToGroup), (group) => ({
    name: group.name,
    id: 'g:' + group.workplaceIds.join('+'),
  }));
  return { options: hackedSchoolGroups.concat(schoolsList) };
};

const getSchools = async () => {
  const schoolsList = (await axios.get(`${baseUrl}/workplaces/listSchools`)).data;
  return { options: schoolsList };
};

const getCompaniesAndGroups = async () => {
  const url = `${baseUrl}/workplaces/listCompanies`;
  const companiesList = (await axios.get(url)).data;
  const companyGroups = (await axios.get(`${baseUrl}/workplaces/listCompanyGroups`)).data;
  const groupIdToGroup = _.indexBy(companyGroups, 'id');

  // hack to pass all ids to formScorerToQuery
  const hackedCompanyGroups = _.map(_.values(groupIdToGroup), (group) => ({
    name: group.name,
    id: 'g:' + group.workplaceIds.join('+'),
  }));
  return { options: hackedCompanyGroups.concat(companiesList) };
};

const getCompanies = async () => {
  const url = `${baseUrl}/workplaces/listCompanies`;
  const companiesList = (await axios.get(url)).data;
  return { options: companiesList };
};

const getJobPositionsAndGlobal = async () => {
  const jobPositions = await getJobPositions();
  return { options: [{ id: 'global', name: 'Global' }].concat(jobPositions.options) };
};
const SelectJobExperienceYear = enhance((p) => (
  <div>
    <Select field='jobPosition' async loadOptions={getJobPositionsAndGlobal} labelKey='name' valueKey='id' />
    <Range field='experience' min={-5} max={50} />
    <Select
      field='fuzziness'
      defaultValue='sharp'
      options={[{ value: 'sharp', label: 'Sharp' }, { value: 'relaxed', label: 'Relaxed' }]}
    />
  </div>
));

const SelectSalary = (p) => <Range {...p} min={0} max={200} />;

const SelectExperience = (p) => <Range {...p} min={-5} max={50} />;

const SelectSchoolCategoryRange = (p) => <Range {...p} min={0} max={5} />;

const MultiSelectLocation = () => (
  <Select field='locations' loadOptions={loadLocations} labelKey='label' valueKey='value' async={true} multi={true} />
);

const SelectLocation = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('mode') : '';
  return (
    <div>
      <Select
        field='mode'
        defaultValue='normal'
        options={[{ value: 'normal', label: 'Normal' }, { value: 'custom', label: 'Custom' }]}
      />
      {mode === 'normal' ? <MultiSelectLocation /> : <Input field='locations' placeholder='location' />}
    </div>
  );
});

const MultiSelectLocationRemote = () => (
  <SweetList field='locationAndRemoteWishes' component={SelectLocationRemoteWish} />
);

const SelectSchool = () => (
  <Select
    field='listOfSchoolIds'
    multi
    async
    loadOptions={getSchoolsAndGroups}
    labelKey='name'
    valueKey='id'
    placeholder='schools'
  />
);

class LinkedinWorkplaceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.initialValue || '',
      loading: false,
    };

    this.handleIDChange = this.handleIDChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  async updateSearch() {
    if (this.state.searchValue === '') {
      this.setState({
        searchResults: [],
        loading: false,
      });
    } else {
      const response = await axios.get(`${baseUrl}/workplaces/linkedinCompanySearch?name=${this.state.searchValue}`);
      this.setState({
        searchResults: _.map(response.data, (c) => ({
          title: c.name,
          description: c.id,
        })),
        loading: false,
      });
    }
  }

  async handleIDChange(e) {
    await this.setState({ id: e.target.value });
    this.setState({ searchValue: '' });
  }

  async handleSearchChange(e) {
    const value = e.target.value;
    await this.setState({ searchValue: value, loading: true });
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    await this.setState({
      searchTimeout: setTimeout(async () => await this.updateSearch(), 400),
    });
  }

  async onCompanySelect(e, result) {
    await this.setState({
      id: result.result.description,
      searchValue: result.result.title,
    });
    this.props.onSelect({ id: result.result.description, name: result.result.title });
  }

  render() {
    const { loading } = this.state;
    return (
      <Form>
        <Form.Field>
          <Search
            selectFirstResult={true}
            onResultSelect={this.onCompanySelect}
            onSearchChange={this.handleSearchChange}
            results={this.state.searchResults}
            value={this.state.searchValue}
            loading={loading}
          />
        </Form.Field>
      </Form>
    );
  }
}

const SelectLinkedinCompany = enhance(({ value, setValue, ...props }) => {
  let name;
  try {
    name = value.get('name');
  } catch (e) {
    name = (value || {}).name;
  }
  return <LinkedinWorkplaceSearch key={name} initialValue={name} onSelect={setValue} />;
});

const SelectLinkedinCompanies = () => <SweetList field='linkedinCompanies' component={SelectLinkedinCompany} />;

const SelectCompanies = () => (
  <Select
    async
    multi
    loadOptions={getCompaniesAndGroups}
    labelKey='name'
    valueKey='id'
    placeholder='Hiresweet Company'
    field='companyIds'
  />
);

const MultiSelectHasSkill = () => (
  <Select field='skillIds' async loadOptions={getSkillIds} labelKey='name' valueKey='id' multi={true} />
);

const MultiSelectSkillsOperator = () => (
  <div>
    <Select field='skills' async loadOptions={getSkillIds} labelKey='name' valueKey='id' multi={true} />
    <Select field='operator' options={OPERATOR_OPTIONS} defaultValue={'$or'} />
  </div>
);
const SelectSkill = () => <Select field='skillId' async loadOptions={getSkillIds} labelKey='name' valueKey='id' />;

const SelectNbAnswersRange = () => (
  <div>
    <SelectInt field='minNbAnswers' placeholder='Min Nb Answers' min={0} max={100} />
    <SelectInt field='maxNbAnswers' placeholder='Max Nb Answers' min={0} max={100} />
    <SelectInt field='nbWeeks' placeholder='Nb Weeks' min={1} max={200} />
    <Select
      field='type'
      options={[
        { value: 'positive', label: 'positive' },
        { value: 'medium', label: 'medium' },
        { value: 'negative', label: 'negative' },
      ]}
    />
  </div>
);

const SelectNbPositiveAnswersRange = () => (
  <div>
    <SelectInt field='minNbAnswers' placeholder='Min Nb Answers' min={0} max={100} />
    <SelectInt field='maxNbAnswers' placeholder='Max Nb Answers' min={0} max={100} />
    <SelectInt field='nbWeeks' placeholder='Nb Weeks' min={1} max={200} />
  </div>
);

const SelectNbAnyAnswersRange = () => (
  <div>
    <SelectInt field='minNbAnswers' placeholder='Min Nb Answers' min={0} max={100} />
    <SelectInt field='maxNbAnswers' placeholder='Max Nb Answers' min={0} max={100} />
    <SelectInt field='nbWeeks' placeholder='Nb Weeks' min={1} max={200} />
  </div>
);

const SelectSolicitationRange = () => (
  <div>
    <SelectInt field='maxNbSolicitations' placeholder='Max Nb Solicitations' min={1} max={100} />
    <SelectInt field='nbWeeks' placeholder='Nb Weeks' min={1} max={100} />
  </div>
);

const SelectDisqualifyRate = () => (
  <div>
    <SelectInt field='minNbActions' placeholder='Min Nb Actions' min={1} max={100} />
    <SelectInt field='maxDisqualifyRate' placeholder='Max Disqualify Rate (out of 100)' min={0} max={100} />
  </div>
);

const SelectResponsibility = (props) => (
  <Select
    field='responsibility'
    options={[
      { value: 'intern', label: 'Low (Intern, Apprentice,...)' },
      { value: 'normal', label: 'Normal' },
      { value: 'lead', label: 'Lead' },
      { value: 'director', label: 'Direction' },
    ]}
    multi={props.multi}
  />
);

const SelectManagementLevel = () => (
  <Select
    field='managementLevel'
    options={[
      { value: 'normal', label: 'Normal' },
      { value: 'lead', label: 'Lead' },
      { value: 'director', label: 'Direction' },
    ]}
  />
);

const SelectModality = (props) => (
  <Select
    field='modality'
    options={[
      { value: 'freelance', label: 'Freelance' },
      { value: 'remote', label: 'Remote' },
      { value: 'research', label: 'Research' },
      { value: 'consulting', label: 'Consulting' },
      { value: 'founder', label: 'Founder' },
      { value: 'charitable', label: 'Charitable' },
      { value: 'study-and-development', label: 'Étude et développement' },
    ]}
    multi={props.multi}
  />
);

const SelectJobPositionAndMode = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('mode') : '';
  return (
    <div>
      <MultiSelectJobPosition />
      <Select
        field='mode'
        defaultValue='currently'
        options={[
          { value: 'currently', label: 'Currently' },
          { value: 'explicitly', label: 'Explicitly' },
          { value: 'during', label: 'During' },
        ]}
      />
      {mode === 'during' ? <Range {...props} field='years' min={-2} max={20} /> : null}
    </div>
  );
});

const SelectMatchSomewhere = enhance(() => {
  // const { value } = props;
  // const mode = value ? value.get('mode') : '';
  return (
    <div>
      <Input field='text' placeholder='text to catch' />
      <Select
        field='mode'
        defaultValue='anywhere'
        options={[
          { value: 'anywhere', label: 'Anywhere' },
          { value: 'anywhere_without_locations_and_github', label: 'Anywhere without Locations and Github' },
          { value: 'headline', label: 'Headline' },
          { value: 'last_experience', label: 'Last Experience' },
          { value: 'last_experience_headline', label: 'Last Experience Headline' },
          { value: 'headline_summary', label: 'Headline and Summary' },
          { value: 'headline_experiences', label: 'Headline Experiences' },
          { value: 'current_location', label: 'Current Location' },
          { value: 'headline_or_experiences_location', label: 'Headline or Experiences Location' },
          { value: 'ever_been_location', label: 'Headline, Experiences & Educations Location (Experimental)' },
          { value: 'github', label: 'GitHub' },
        ]}
      />
    </div>
  );
});

const MultiSelectJobPosition = () => (
  <Select field='jobPositions' async loadOptions={getJobPositions} labelKey='name' valueKey='id' multi={true} />
);

const MultiSelectJobPositionOperator = () => (
  <div>
    <Select field='jobPositions' async loadOptions={getJobPositions} labelKey='name' valueKey='id' multi={true} />
    <Select field='operator' options={OPERATOR_OPTIONS} defaultValue={'$or'} />
  </div>
);

const SelectPipe = enhance((props) => {
  const { value } = props;
  const pipeType = value ? value.get('pipeType') : '';
  return (
    <div>
      <Select field='pipeType' options={[{ value: 'sheet', label: 'Sheet' }, { value: 'station', label: 'Station' }]} />
      <div>
        {pipeType === 'sheet' ? (
          <div key={pipeType || '__'}>
            <Select field='pipeId' async loadOptions={getSheets} labelKey='label' valueKey='value' />
            <SelectLastMonthRange />
          </div>
        ) : pipeType === 'station' ? (
          <div key={pipeType || '__'}>
            <Select
              field='profileStatus'
              options={[
                { value: 'contacted', label: 'Send' },
                { value: 'skipped', label: 'Skip' },
                { value: 'pending', label: 'Pending' },
                { value: 'all', label: 'All' },
              ]}
            />
            <Select field='pipeId' async loadOptions={getStationOffers} labelKey='label' valueKey='value' />
            <SelectLastMonthRange />
          </div>
        ) : null}
      </div>
    </div>
  );
});

const SelectSuperMatchSheets = enhance(() => {
  return (
    <div>
      <Select
        field='minImportance'
        placeholder='Min Importance'
        options={[
          { value: 'required', label: 'Required' },
          { value: 'important', label: 'Important' },
          { value: 'bonus', label: 'Bonus' },
        ]}
      />
      <Select placeholder='Skill' field='skillId' async loadOptions={getSkillIds} labelKey='name' valueKey='id' />
      <SelectInt field='minOccurrence' placeholder='Min Occurrence' min={1} max={10} />
    </div>
  );
});

const SelectClient = () => (
  <Select field='clientPlatformId' async loadOptions={getClients} labelKey='label' valueKey='value' />
);

const getLastMonths = (nLastMonths) => {
  var today = new Date();
  var d;
  var months = [];
  for (var i = 0; i < nLastMonths; i += 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 2);
    months.push({
      date: d.toISOString().slice(2, 10),
    });
  }
  return months;
};

const SelectLastMonthRange = () => {
  const lastMonths = getLastMonths(36);
  const lastMonthsOptions = _.map(lastMonths, (month) => ({
    value: month.date,
    label: month.date,
  }));
  return (
    <Grid columns={4}>
      <Grid.Column width={2}>Between</Grid.Column>
      <Grid.Column width={6}>
        <Select field='minDate' options={lastMonthsOptions} placeholder='Begin' />
      </Grid.Column>
      <Grid.Column width={2}>And</Grid.Column>
      <Grid.Column width={6}>
        <Select field='maxDate' options={lastMonthsOptions} placeholder='Today' />
      </Grid.Column>
    </Grid>
  );
};

const SelectClientPipes = enhance((props) => {
  const { value } = props;
  const pipeType = value ? value.get('pipeType') : '';

  return (
    <div>
      <Select field='pipeType' options={[{ value: 'sheet', label: 'Sheet' }, { value: 'station', label: 'Station' }]} />
      <div>
        {pipeType === 'sheet' ? (
          <div key={pipeType || '__'}>
            <SelectClient />
            <SelectLastMonthRange />
          </div>
        ) : pipeType === 'station' ? (
          <div key={pipeType || '__'}>
            <Select
              field='profileStatus'
              options={[
                { value: 'contacted', label: 'Send' },
                { value: 'skipped', label: 'Skip' },
                { value: 'pending', label: 'Pending' },
                { value: 'all', label: 'All' },
              ]}
            />
            <SelectClient />
            <SelectLastMonthRange />
          </div>
        ) : null}
      </div>
    </div>
  );
});

const LETTERS_OR_RANKING_OPTIONS = [{ value: 'letters', label: 'Letters' }, { value: 'ranking', label: 'Ranking' }];

const LETTERS_OPTIONS = [
  { value: 'A+', label: 'A+' },
  { value: 'A', label: 'A' },
  { value: 'AB', label: 'AB' },
  { value: 'B', label: 'B' },
  { value: 'BC', label: 'BC' },
  { value: 'C', label: 'C' },
  { value: 'CD', label: 'CD' },
  { value: 'D', label: 'D' },
  { value: 'DE', label: 'DE' },
  { value: 'E', label: 'E' },
  { value: 'E-', label: 'E-' },
];

const LetterRange = () => (
  <Grid columns={4}>
    <Grid.Column width={2}>Min</Grid.Column>
    <Grid.Column width={6}>
      <Select field='minLetter' defaultValue='E-' options={LETTERS_OPTIONS} />
    </Grid.Column>
    <Grid.Column width={2}>Max</Grid.Column>
    <Grid.Column width={6}>
      <Select field='maxLetter' defaultValue='A+' options={LETTERS_OPTIONS} />
    </Grid.Column>
  </Grid>
);

const RANKING_OPTIONS = _.map([10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000], (num) => ({
  value: num,
  label: num,
}));

const RankingRange = () => (
  <Grid columns={4}>
    <Grid.Column width={2}>Min</Grid.Column>
    <Grid.Column width={6}>
      <Select field='minRanking' defaultValue={50000} options={RANKING_OPTIONS} />
    </Grid.Column>
    <Grid.Column width={2}>Max</Grid.Column>
    <Grid.Column width={6}>
      <Select field='maxRanking' defaultValue={0} options={RANKING_OPTIONS} />
    </Grid.Column>
  </Grid>
);

const SelectJobPositionRange = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('mode') : '';

  return (
    <div>
      <Select field='jobPositions' async loadOptions={getJobPositions} labelKey='name' valueKey='id' multi={true} />
      <Select field='mode' defaultValue='letters' options={LETTERS_OR_RANKING_OPTIONS} />
      {mode === 'letters' ? <LetterRange /> : <RankingRange />}
    </div>
  );
});

const SelectSkillRange = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('mode') : '';
  return (
    <div>
      <Select field='skills' async loadOptions={getSkillIds} labelKey='name' valueKey='id' multi={true} />
      <Select field='mode' defaultValue='letters' options={LETTERS_OR_RANKING_OPTIONS} />
      {mode === 'letters' ? <LetterRange /> : <RankingRange />}
    </div>
  );
});

const SelectMinNbGithubRepos = () => (
  <Select
    field='min'
    options={_.map([0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 80, 100, 150], (nb) => ({ value: nb, label: nb }))}
  />
);

const SelectMinNbGithubFollowers = () => (
  <Select
    field='min'
    options={_.map([0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 80, 100, 150, 250, 500, 1000], (nb) => ({
      value: nb,
      label: nb,
    }))}
  />
);

const SelectNbMonthsSinceLastHiring = () => (
  <Select
    placeholder='min nb months'
    field='min'
    options={_.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 42, 48, 60], (nb) => ({
      value: nb,
      label: nb,
    }))}
  />
);

const SelectNbMonthsSinceLastUpdate = () => (
  <Select
    placeholder='max nb months'
    field='max'
    options={_.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 42, 48, 60], (nb) => ({
      value: nb,
      label: nb,
    }))}
  />
);

const MultiSelectSource = () => (
  <Select
    field='sourceTypes'
    options={[
      { value: 'linkedin', label: 'linkedin' },
      { value: 'github', label: 'github' },
      { value: 'stackoverflow', label: 'stackoverflow' },
      { value: 'angelList', label: 'angelList' },
      { value: 'behance', label: 'behance' },
      { value: 'codepen', label: 'codepen' },
      { value: 'cv', label: 'cv' },
      { value: 'dribbble', label: 'dribbble' },
      { value: 'githubIo', label: 'githubIo' },
      { value: 'googlePlus', label: 'googlePlus' },
      { value: 'hopWork', label: 'hopWork' },
      { value: 'keyBase', label: 'keyBase' },
      { value: 'medium', label: 'medium' },
      { value: 'meetup', label: 'meetup' },
      { value: 'pinterest', label: 'pinterest' },
      { value: 'twitter', label: 'twitter' },
      { value: 'viadeo', label: 'viadeo' },
      { value: 'website', label: 'website' },
    ]}
    multi={true}
  />
);

const SelectPeriod = () => (
  <Select
    field='period'
    defaultValue='currently'
    options={[{ value: 'currently', label: 'Currently' }, { value: 'ever', label: 'Ever' }]}
  />
);

const SelectCompanyPrestige = (props) => (
  <div>
    {props.period && <SelectPeriod />}
    <SelectInt field='minPrestige' placeholder='Min Prestige' min={0} max={5} />
    <SelectInt field='maxPrestige' placeholder='Max Prestige' min={0} max={5} />
  </div>
);
const COMPANY_TYPE_OPTIONS = [
  { value: 'big', label: 'Big' },
  { value: 'ssii', label: 'SSII' },
  { value: 'startup', label: 'Start-Up' },
];
const SelectCompanyType = (props) => (
  <div>
    {props.period && <SelectPeriod />}
    <Select field='companyType' options={COMPANY_TYPE_OPTIONS} />
  </div>
);

const SelectCompanyIndustry = (props) => (
  <div>
    {props.period && <SelectPeriod />}
    <Select field='industryIds' async loadOptions={getIndustryIds} labelKey='name' valueKey='id' multi={true} />
  </div>
);

const SelectCompanySize = () => (
  <div>
    <SelectInt field='minNbEmployees' min={0} max={1000} />
    <SelectInt field='maxNbEmployees' min={0} max={1000} />
  </div>
);

const getStudyLevels = async () => {
  const { data } = await axios.get(`${baseUrl}/tags/list?type=study-level`);
  const sortStudyLevels = (studyLevels) => {
    const idToIndex = {
      phd: 1,
      master: 2,
      bachelor: 3,
      highschool: 4,
      'engineering-degree': 5,
    };
    return _.sortBy(studyLevels, ({ id }) => idToIndex[id] || 1000);
  };
  return { options: sortStudyLevels(data) };
};
const SelectStudyLevel = (props) => (
  <Select
    field='studyLevel'
    async
    loadOptions={getStudyLevels}
    labelKey='name'
    valueKey='id'
    placeholder='study level'
    {...props}
  />
);

const getEducationFields = async () => {
  const { data } = await axios.get(`${baseUrl}/tags/list?type=education-field`);
  return { options: data };
};
const SelectEducationField = (props) => (
  <div>
    <Select
      field='educationFields'
      multi
      async
      loadOptions={getEducationFields}
      labelKey='name'
      valueKey='id'
      placeholder='education field'
    />
  </div>
);

const SelectYears = () => <SelectInt min={-2} max={50} field='years' />;

const SelectNExperiences = () => <SelectInt min={0} max={20} field='nExperiences' />;

const getWorkplaces = async () => {
  const { data } = await axios.get(`${baseUrl}/salesboard/workplaces`);
  return { options: data.workplaces };
};

const SelectWorkplace = () => (
  <Select
    field='workplaceIds'
    multi
    async
    loadOptions={getWorkplaces}
    labelKey='name'
    valueKey='id'
    placeholder='workplaces'
  />
);

const getSchoolTypes = async () => {
  const { data } = await axios.get(`${baseUrl}/workplaces/listSchoolTypes`);
  const sortSchoolTypes = (schoolTypes) => {
    const idToIndex = {
      'engineering-school': 1,
      'informatics-school': 2,
      'business-school': 3,
      university: 4,
      bootcamp: 5,
    };
    return _.sortBy(schoolTypes, ({ id }) => idToIndex[id] || 1000);
  };
  return { options: sortSchoolTypes(data) };
};
const SelectSchoolType = () => (
  <Select
    field='schoolTypes'
    multi
    async
    loadOptions={getSchoolTypes}
    labelKey='name'
    valueKey='id'
    placeholder='school type'
  />
);
const SelectEducationPrestige = () => {
  const options = _.map([...Array(11).keys()].map((i) => i / 10), (nb) => ({
    value: nb,
    label: nb,
  }));
  return (
    <div>
      <Select defaultValue={0} placeholder='min prestige' field='minPrestige' options={options} />
      <Select defaultValue={1} placeholder='max prestige' field='maxPrestige' options={options} />
    </div>
  );
};

const commonOperators = {
  '#and': {
    label: '#and',
    children: 'n',
  },
  '#or': {
    label: '#or',
    children: 'n',
  },
  linkedinWorkplace: {
    label: 'Workplace',
    children: 1,
    component: SelectLinkedinCompanies,
    nested: true,
  },
  workplace: {
    label: 'Workplace (deprecated)',
    children: 1,
    component: SelectWorkplace,
    nested: true,
  },
  workplacePrestige: {
    label: 'Workplace Prestige',
    children: 1,
    component: SelectCompanyPrestige,
    nested: true,
    props: { period: false },
  },
  skill: {
    label: 'Skills',
    children: 1,
    component: MultiSelectHasSkill,
    nested: true,
  },
};
const experienceOperators = {
  location: {
    label: 'Location',
    children: 1,
    component: SelectLocation,
    nested: true,
  },
  jobPosition: {
    label: 'Job Positions',
    children: 1,
    component: MultiSelectJobPosition,
    nested: true,
  },
  responsibilities: {
    label: 'Responsibilities',
    children: 1,
    component: SelectResponsibility,
    nested: true,
  },
  companyType: {
    label: 'Company Type',
    children: 1,
    component: SelectCompanyType,
    nested: true,
    props: { period: false },
  },
  companyIndustry: {
    label: 'Company Industry',
    children: 1,
    component: SelectCompanyIndustry,
    nested: true,
    props: { period: false },
  },
  companySize: {
    label: 'Company Size',
    children: 1,
    component: SelectCompanySize,
    nested: true,
    props: { period: false },
  },
  modalities: {
    label: 'Modalities',
    children: 1,
    component: SelectModality,
    nested: true,
  },
};

const educationOperators = {
  schoolType: {
    label: 'School Type',
    children: 1,
    component: SelectSchoolType,
    nested: true,
  },
  studyLevel: {
    label: 'Study Level',
    children: 1,
    component: SelectStudyLevel,
    nested: true,
  },
  educationField: {
    label: 'Education Field',
    children: 1,
    component: SelectEducationField,
    nested: true,
  },
  educationPrestige: {
    label: 'Education Prestige',
    children: 1,
    component: SelectEducationPrestige,
    nested: true,
  },
};

const ExperienceSelector = customOperator({ ...commonOperators, ...experienceOperators }, false);

const EducationSelector = customOperator({ ...commonOperators, ...educationOperators }, false);

const ExperienceEducationSelector = customOperator({ ...commonOperators }, false);

const TimeSelector = customOperator(
  {
    during: { label: 'During', children: 1, component: SelectYears },
    currently: { label: 'Currently' },
    recently: { label: 'Recently', children: 1, component: SelectYears },
    ever: { label: 'Ever' },
    never: { label: 'Never' },
    experiencesNumber: { label: 'Experiences Number', children: 1, component: SelectNExperiences },
  },
  false,
);

const SelectEmplacement = (props) => (
  <Select
    defaultValue='experiences'
    options={[
      { value: 'experiences', label: 'Experiences' },
      { value: 'educations', label: 'Educations' },
      { value: 'mainEducation', label: 'Main Education' },
      { value: 'all', label: 'All' },
    ]}
    {...props}
  />
);

const SelectBackground = enhance((props) => {
  const { value } = props;
  const emplacement = value ? value.get('emplacement') : '';
  return (
    <div>
      <SelectEmplacement field='emplacement' />
      {emplacement !== 'mainEducation' ? <TimeSelector field='timeSelector' /> : null}
      {emplacement === 'all' ? (
        <ExperienceEducationSelector field='experienceSelector' />
      ) : emplacement === 'experiences' ? (
        <ExperienceSelector field='experienceSelector' />
      ) : emplacement === 'educations' ? (
        <EducationSelector field='experienceSelector' />
      ) : emplacement === 'mainEducation' ? (
        <EducationSelector field='experienceSelector' />
      ) : null}
    </div>
  );
});

const SelectSchoolIdTypeGroup = (props) => (
  <div>
    <Select
      async
      multi
      loadOptions={getSchoolTypes}
      labelKey='name'
      valueKey='id'
      placeholder='School types'
      field='schoolTypes'
    />
    <Select
      async
      multi
      loadOptions={getSchoolGroups}
      labelKey='name'
      valueKey='id'
      placeholder='School groups'
      field='schoolGroups'
    />
    <Select
      async
      multi
      loadOptions={getSchools}
      labelKey='name'
      valueKey='id'
      placeholder='Specific schools'
      field='schoolIds'
    />
  </div>
);

const SelectCompanyIdTypeGroup = (props) => (
  <div>
    <Select multi options={COMPANY_TYPE_OPTIONS} placeholder='Company types' field='companyTypes' />
    <Select
      async
      multi
      loadOptions={getCompanyGroups}
      labelKey='name'
      valueKey='id'
      placeholder='Company groups'
      field='companyGroups'
    />
    <b>Specific companies :</b>
    <SelectLinkedinCompanies />
  </div>
);

const NUMBER_PEOPLE_MANAGED_OPTIONS = [
  { label: '1-3', value: '1-3' },
  { label: '4-20', value: '4-20' },
  { label: '20+', value: '20+' },
];
const getHierarchicalRanks = async () => {
  const { data } = await axios.get(`${baseUrl}/tags/list?type=hierarchical-rank`);
  return { options: data };
};
const SelectManagement = (props) => (
  <div>
    <Select
      async
      multi
      loadOptions={getHierarchicalRanks}
      labelKey='name'
      valueKey='id'
      placeholder='Hierarchical Rank'
      field='targetHierarchicalRanks'
    />
    <Select
      multi
      options={NUMBER_PEOPLE_MANAGED_OPTIONS}
      placeholder='Number of People Managed'
      field='targetManagementChunks'
    />
  </div>
);

export const SelectGlobalBackground = enhance((props) => {
  const { value } = props;
  const scorerId = value ? value.get('scorerId') : '';
  const scorerOptions = [
    { value: 'schoolBackground', label: 'School' },
    { value: 'companyBackground', label: 'Company' },
    { value: 'industryKnowledge', label: 'Industry' },
    { value: 'studyLevel', label: 'Study Level' },
    { value: 'workStable', label: 'Is Work Stable' },
    { value: 'currentlyFounder', label: 'Currently Founder' },
    { value: 'everFounder', label: 'Ever Founder' },
    { value: 'consultantness', label: "Consultant / Ingénieur d'étude" },
    { value: 'openSourceness', label: 'Open Source' },
    { value: 'educationField', label: 'Education Field' },
    { value: 'mainlyFreelance', label: 'Mainly Freelance' },
    { value: 'management', label: 'Management' },
  ];
  return (
    <Grid>
      <Grid.Column width={8}>
        <Select defaultValue='companyBackground' options={scorerOptions} field='scorerId' />
      </Grid.Column>
      <Grid.Column width={8}>
        {scorerId === 'studyLevel' ? <SelectStudyLevel /> : null}
        {scorerId === 'schoolBackground' ? <SelectSchoolIdTypeGroup /> : null}
        {scorerId === 'educationField' ? <SelectEducationField /> : null}
        {scorerId === 'companyBackground' ? <SelectCompanyIdTypeGroup /> : null}
        {scorerId === 'industryKnowledge' ? <SelectCompanyIndustry /> : null}
        {scorerId === 'management' ? <SelectManagement /> : null}
      </Grid.Column>
    </Grid>
  );
});

const advancedBackgroundCombinaisonOperators = {
  '#and': {
    label: '#and',
    children: 'n',
  },
  '#or': {
    label: '#or',
    children: 'n',
  },
  '#not': {
    label: '#not',
    children: 1,
    component: enhance(({ Op }) => <Op field='son' />),
  },
};

const advancedBackgroundWorkOperators = {
  company: {
    label: 'Company',
    children: 1,
    component: SelectCompanyIdTypeGroup,
    nested: true,
  },
  modality: {
    label: 'Modality (freelance, consultant...)',
    children: 1,
    component: SelectModality,
    nested: true,
    props: { multi: true },
  },
  responsibility: {
    label: 'Responsibility',
    children: 1,
    component: SelectResponsibilities,
    nested: true,
    props: { multi: true },
  },
  industry: {
    label: 'Industry',
    children: 1,
    component: SelectCompanyIndustry,
    nested: true,
  },
  jobPosition: {
    label: 'Job Position',
    children: 1,
    component: MultiSelectJobPosition,
    nested: true,
  },
  skills: {
    label: 'Skills',
    children: 1,
    component: MultiSelectHasSkill,
    nested: true,
  },
  companyPrestige: {
    label: 'Company Prestige',
    children: 1,
    component: SelectCompanyPrestige,
    nested: true,
  },
  management: {
    label: 'Management',
    children: 1,
    component: SelectManagement,
    nested: true,
  },
  companySize: {
    label: 'Company Size',
    children: 1,
    component: SelectCompanySize,
    nested: true,
  },
};

const advancedBackgroundEducationOperators = {
  educationField: {
    label: 'Education Field',
    children: 1,
    component: SelectEducationField,
    nested: true,
  },
  school: {
    label: 'School',
    children: 1,
    component: SelectSchoolIdTypeGroup,
    nested: true,
  },
  studyLevel: {
    label: 'Study Level',
    children: 1,
    component: SelectStudyLevel,
    nested: true,
    props: {
      multi: true,
    },
  },
  schoolPrestige: {
    label: 'School Prestige',
    children: 1,
    component: SelectCompanyPrestige,
    nested: true,
  },
};

const SelectWorkTypology = customOperator(
  {
    ...advancedBackgroundCombinaisonOperators,
    ...advancedBackgroundWorkOperators,
  },
  false,
);

const SelectEducationTypology = customOperator(
  {
    ...advancedBackgroundCombinaisonOperators,
    ...advancedBackgroundEducationOperators,
  },
  false,
);

const SelectAggregation = customOperator(
  {
    during: { label: 'During', children: 1, component: SelectYears },
    currently: { label: 'Currently' },
    ever: { label: 'Ever' },
    experiencesNumber: { label: 'Experiences Number', children: 1, component: SelectNExperiences },
  },
  false,
);

export const SelectAdvancedBackground = enhance((props) => {
  const { value } = props;
  const mode = value ? value.get('mode') : '';
  return (
    <div>
      <Select
        defaultValue='work'
        options={[{ value: 'work', label: 'Work' }, { value: 'education', label: 'Education' }]}
        field='mode'
      />
      {mode === 'work' ? <SelectAggregation field='aggregation' /> : null}
      {mode === 'work' ? (
        <SelectWorkTypology field='typology' />
      ) : mode === 'education' ? (
        <SelectEducationTypology field='typology' />
      ) : null}
    </div>
  );
});

const selectIsFemale = () => (
  <div>
    <Select
      field='relaxed'
      defaultValue={false}
      options={[{ value: true, label: 'Relaxed' }, { value: false, label: 'Normal' }]}
    />
    <Select
      field='country'
      defaultValue={'france'}
      options={[{ value: 'france', label: 'FR' }, { value: 'us', label: 'US' }]}
    />
  </div>
);

const ListOfSons = enhance(({ Op }) => <SweetList field='sons' component={Op} />);

const SelectKAmongN = enhance(({ Op }) => (
  <div>
    <Select
      placeholder='K'
      field='paramK'
      options={_.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (nb) => ({
        value: nb,
        label: nb,
      }))}
    />
    <SweetList field='sons' component={Op} />
  </div>
));

const USE_JOB_EXPERIENCE_OPTIONS = [
  { value: false, label: 'Default' },
  { value: true, label: 'Use Job Experience Year' },
];

const buildBinaryOptions = (text) => [
  {
    value: true,
    label: (
      <div>
        <Icon color='green' name='check' />
        {text}
      </div>
    ),
  },
  {
    value: false,
    label: (
      <div>
        <Icon color='red' name='remove' />
        {text}
      </div>
    ),
  },
];

const SelectRelaxation = enhance(({ Op }) => (
  <div>
    <Select
      field='relaxed'
      defaultValue={false}
      options={[{ value: true, label: 'Relaxed' }, { value: false, label: 'Not Relaxed' }]}
    />
    <Select field='positiveAnswerThreshold' defaultValue={40} options={POSITIVE_ANSWER_THRESHOLD} />
    <Select field='useJobExperience' defaultValue={true} options={USE_JOB_EXPERIENCE_OPTIONS} />
    <h4>Sub-typologies</h4>
    <Select field='useLocation' defaultValue={true} options={buildBinaryOptions('Location')} />
    <Select field='useFreelance' defaultValue={true} options={buildBinaryOptions('No Freelance')} />
    <Select field='useExperienceRange' defaultValue={true} options={buildBinaryOptions('Experience Range')} />
    <Select field='useJobSkills' defaultValue={true} options={buildBinaryOptions('Job Skills')} />
    <Select field='useTimeSinceLastHiring' defaultValue={true} options={buildBinaryOptions('Did Not Start Recently')} />
    <Select
      field='useNotAlreadyContacted'
      defaultValue={true}
      options={buildBinaryOptions('Not Already Selected For Client')}
    />
    <Select field='useFounderFilter' defaultValue={true} options={buildBinaryOptions('No Founders')} />
    <Select field='useDoNotContact' defaultValue={true} options={buildBinaryOptions('Ask Not To Be Contacted')} />
    <Select field='usePrestige' defaultValue={true} options={buildBinaryOptions('Prestige')} />
    {/* <Select field="useDoNotContact" defaultValue={true} options={buildBinaryOptions('Discard Do No Contact')} /> */}
  </div>
));

const SelectMagicFilterWatch = enhance(({ Op }) => (
  <div>
    <Select field='acceptedStatuses' defaultValue={['enabled']} style={{ display: 'none' }} />
    <Select
      field='relaxed'
      defaultValue={true}
      options={[{ value: true, label: 'Relaxed' }, { value: false, label: 'Not Relaxed' }]}
    />
    <Select field='createdInPastNbWeeks' defaultValue={8} options={CREATED_IN_PAST_NB_WEEKS_OPTIONS} />
    <h4>Sub-typologies</h4>
    <Select
      field='useNotAlreadyContacted'
      defaultValue={true}
      options={buildBinaryOptions('Not Already Selected For Client')}
    />
    <Select field='useWatchLocation' defaultValue={true} options={buildBinaryOptions('Watch Location')} />
    <Select field='useWatchJob' defaultValue={true} options={buildBinaryOptions('Watch Job')} />
    <Select field='useWatchSkills' defaultValue={true} options={buildBinaryOptions('Watch Skills')} />
    <Select field='useWatchExperience' defaultValue={true} options={buildBinaryOptions('Watch Experience')} />
    <Select field='useWatchSalary' defaultValue={true} options={buildBinaryOptions('Watch Salary')} />
    <Select field='useJobSkills' defaultValue={false} options={buildBinaryOptions('Linkedin Job Skills')} />
    <Select field='usePrestige' defaultValue={false} options={buildBinaryOptions('Linkedin Prestige')} />
    <Select field='useFounderFilter' defaultValue={false} options={buildBinaryOptions('Linkedin No Founders')} />
    <Select field='useFreelance' defaultValue={false} options={buildBinaryOptions('Linkedin No Freelance')} />
  </div>
));

const SelectSampleSize = () => (
  <div>
    <SelectInt field='sampleSize' placeholder='Min Value' min={1} max={100} />
  </div>
);

export const extraCriteriaFilters = {
  targetLocation: {
    label: 'Target Location',
    children: 1,
    component: SelectLocation,
    nested: true,
  },
  targetLocationRelaxed: {
    label: 'Target Location (Relaxed)',
    children: 1,
    component: SelectLocation,
    nested: true,
  },
  everBeenTo: {
    label: 'Ever been To',
    children: 1,
    component: SelectLocation,
    nested: true,
  },
  everBeenToRelaxed: {
    label: 'Ever been To (Relaxed)',
    children: 1,
    component: SelectLocation,
    nested: true,
  },
  targetJobPosition: {
    label: 'Target Job Position',
    children: 1,
    component: SelectJobPositionAndMode,
    nested: true,
  },
  targetJobPositionRelaxed: {
    label: 'Target Job Position (Relaxed)',
    children: 1,
    component: MultiSelectJobPosition,
    nested: true,
  },
  targetResponsibility: {
    label: 'Target Responsibility',
    children: 1,
    component: SelectResponsibility,
    nested: true,
  },
  targetResponsibilityRelaxed: {
    label: 'Target Responsibility (Relaxed)',
    children: 1,
    component: SelectResponsibility,
    nested: true,
  },
  targetModality: {
    label: 'Target Modality',
    children: 1,
    component: SelectModality,
    nested: true,
  },
  experienceYearRange: {
    label: 'Experience Year',
    children: 1,
    component: SelectExperience,
    nested: true,
  },
  experienceTechYearRange: {
    label: 'Experience Tech Year',
    children: 1,
    component: SelectExperience,
    nested: true,
  },
  experienceTechYearRelaxedRange: {
    label: 'Experience Tech Year (Relaxed)',
    children: 1,
    component: SelectExperience,
    nested: true,
  },
  jobExperienceYearRange: {
    label: 'Job Experience Year',
    children: 1,
    component: SelectJobExperienceYear,
    nested: true,
  },
  techReconversion: {
    label: 'Tech Reconversion',
  },
  hasSkill: {
    label: 'Has Skill',
    children: 1,
    component: MultiSelectHasSkill,
    nested: true,
  },
  hasDirectSkill: {
    label: 'Has Direct Skill',
    children: 1,
    component: MultiSelectHasSkill,
    nested: true,
  },
  isFreelance: { label: 'Freelance' },
  nbAnswersRange: {
    label: 'Expert Answers Range (For Debug)',
    children: 1,
    component: SelectNbAnswersRange,
    nested: true,
  },
  nbPositiveAnswersRange: {
    label: 'Nb Positive Answers Range',
    children: 1,
    component: SelectNbPositiveAnswersRange,
    nested: true,
  },
  nbAnyAnswersRange: {
    label: 'Nb Answers Range',
    children: 1,
    component: SelectNbAnyAnswersRange,
    nested: true,
  },
  schoolCategoryRange: {
    label: 'School Category Range',
    children: 1,
    component: SelectSchoolCategoryRange,
    nested: true,
  },
  hasBeenInPrepa: { label: 'Has Been In Prepa' },
  schoolNamesScorer: {
    label: 'Target Schools',
    children: 1,
    component: SelectSchool,
    nested: true,
  },
  currentLinkedinCompanyScorer: {
    label: 'Current Company',
    children: 1,
    component: SelectLinkedinCompanies,
    nested: true,
  },
  currentCompanyScorer: {
    label: 'Current Company (deprecated)',
    children: 1,
    component: SelectCompanies,
    nested: true,
  },
  isFemale: {
    label: 'Is Female',
    children: 1,
    component: selectIsFemale,
    nested: true,
  },
  everAnsweredPositively: { label: 'Ever Answered Positively' },
  everPushedToClient: { label: 'Ever Pushed To Client' },
  hasEmail: { label: 'Has Email' },
  hasGithubEmail: { label: 'Has Github Email' },
  disqualifyRate: {
    label: 'Disqualify Rate',
    children: 1,
    component: SelectDisqualifyRate,
    nested: true,
  },
  hasSource: {
    label: 'Has Source',
    children: 1,
    component: MultiSelectSource,
    nested: true,
  },
  companyType: {
    label: 'Company Type',
    children: 1,
    component: SelectCompanyType,
    nested: true,
    props: { period: true },
  },
  companyIndustry: {
    label: 'Company Industry',
    children: 1,
    component: SelectCompanyIndustry,
    nested: true,
    props: { period: true },
  },
  companyPrestige: {
    label: 'Company Prestige',
    children: 1,
    component: SelectCompanyPrestige,
    nested: true,
    props: { period: true },
  },
  jobScoresS6: {
    label: 'Job Position Range',
    children: 1,
    component: SelectJobPositionRange,
    nested: true,
  },
  skillScoresS6: {
    label: 'Skill Range',
    children: 1,
    component: SelectSkillRange,
    nested: true,
  },
  profileLastUpdate: {
    label: 'Profile Last Update',
    children: 1,
    component: SelectNbMonthsSinceLastUpdate,
    nested: true,
  },
  sample: {
    label: 'Sample Scorer',
    children: 1,
    component: SelectSampleSize,
    nested: true,
  },
  background: {
    label: 'Old Background',
    children: 1,
    component: SelectBackground,
    nested: true,
  },
  globalBackground: {
    label: 'Background',
    children: 1,
    component: SelectGlobalBackground,
    nested: true,
  },
  advancedBackground: {
    label: 'Advanced Background',
    children: 1,
    component: SelectAdvancedBackground,
    nested: true,
  },
};

const searchFilters = {
  //magicFilterOmega: {
  //  label: 'Magic Filter Omega',
  //  children: 1,
  //  component: SelectRelaxation,
  //  nested: true,
  //},
  magicFilterWatch: {
    label: 'Magic Filter Watch',
    children: 1,
    component: SelectMagicFilterWatch,
    nested: true,
  },
  solicitationRange: {
    label: 'Solicitation Range',
    children: 1,
    component: SelectSolicitationRange,
    nested: true,
  },
  matchPipe: {
    label: 'Match Pipe',
    children: 1,
    component: SelectPipe,
    nested: true,
  },
  matchClientPipes: {
    label: 'Match Client Pipes',
    children: 1,
    component: SelectClientPipes,
    nested: true,
  },
  worksForClient: { label: 'Is Working For Client' },

  // watchFilter: { label: 'Watch Filter' },
};

const commonFilters = {
  '#not': {
    label: '#not',
    children: 1,
    component: enhance(({ Op }) => <Op field='son' />),
  },
  '#and': { label: '#and', children: 1, component: ListOfSons },
  '#or': { label: '#or', children: 1, component: ListOfSons },
  '#kAmongN': { label: '#kAmongN', children: 1, component: SelectKAmongN },
  matchAnywhere: {
    label: 'Match Anywhere',
    children: 1,
    component: SelectMatchSomewhere,
    nested: true,
  },
  githubRepository: {
    label: 'Min #GithubRepos',
    children: 1,
    component: SelectMinNbGithubRepos,
    nested: true,
  },
  githubFollowers: {
    label: 'Min #GithubFollowers',
    children: 1,
    component: SelectMinNbGithubFollowers,
    nested: true,
  },
  timeElapsedSinceLastHiring: {
    label: 'Time Elapsed Since Last Hiring',
    children: 1,
    component: SelectNbMonthsSinceLastHiring,
    nested: true,
  },
  watchExperience: {
    label: 'Watch Experience',
    children: 1,
    component: SelectExperience,
  },
  watchMinSalary: {
    label: 'Watch Min Salary',
    children: 1,
    component: SelectSalary,
  },
  watchJobPosition: {
    label: 'Watch Jobs',
    children: 1,
    component: MultiSelectJobPositionOperator,
  },
  watchSkills: {
    label: 'Watch Skills',
    children: 1,
    component: MultiSelectSkillsOperator,
  },
  watchManagement: {
    label: 'Watch Management',
    children: 1,
    component: SelectManagementLevel,
  },
  watchLocationAndRemoteWish: {
    label: 'Watch Location And Remote',
    children: 1,
    component: MultiSelectLocationRemote,
  },
};

const buildFilter = (inExtraCriteriaMenu, inSearchModal, deprecatedFilterIds) => {
  let operators = inSearchModal
    ? {
        ...commonFilters,
        ...searchFilters,
      }
    : inExtraCriteriaMenu
    ? {
        ...commonFilters,
        ...extraCriteriaFilters,
      }
    : {
        ...commonFilters,
        ...searchFilters,
        ...extraCriteriaFilters,
      };
  if (deprecatedFilterIds) {
    operators = _.mapObject(operators, (value, filterId) => {
      if (deprecatedFilterIds.indexOf(filterId) >= 0) {
        return {
          ...value,
          label: `~ ${value.label}`,
        };
      }
      return value;
    });
  }
  return customOperator(operators, false);
};

const Filter = buildFilter(false, false);

const Scorer = customOperator(
  {
    // s4UnicornScorer: { label: 'Scorer S4 (U1)' },
    //skillScore: {
    //  label: 'Skill Score',
    //  children: 1,
    //  component: SelectSkill,
    //  nested: true,
    //},
    //s5UnicornScorer: { label: 'Scorer S5' },
    //s6UnicornScorer: { label: 'Scorer S6' },
    //s7UnicornScorer: { label: 'Scorer S7' },
    //searchOmegaClusteredScorer: { label: 'Search Omega (test)' },
    //s6CheatedScorer: { label: 'Scorer S6 (U1)' },
    fastSearch: { label: 'Scorer ($latest)' },
    //s8OmegaScorer: { label: 'Scorer ($latest) - Back up' },
    fastSearchPrescoring: { label: 'FastSearch (prescoring only)' },
    WS5Model: { label: 'Scorer Watch ($latest)' },
    WS3V2Model: { label: 'Scorer Watch (old)' },
    watchSearch: { label: 'Search Watch' },
    //fallback: { label: 'InstantFlow' },
  },
  false,
);

const timeSelectorFormToQuery = (timeSelector) => {
  if (!_.isObject(timeSelector)) {
    return timeSelector;
  }
  if (timeSelector.selection === null) {
    return null;
  }
  if (timeSelector.selection === 'during') {
    return {
      id: timeSelector.selection,
      params: {
        years: timeSelector.years,
      },
    };
  }
  if (timeSelector.selection === 'recently') {
    return {
      id: timeSelector.selection,
      params: {
        years: timeSelector.years,
      },
    };
  }
  if (timeSelector.selection === 'currently') {
    return {
      id: timeSelector.selection,
    };
  }
  if (timeSelector.selection === 'ever') {
    return {
      id: timeSelector.selection,
    };
  }
  if (timeSelector.selection === 'never') {
    return {
      id: timeSelector.selection,
    };
  }
  if (timeSelector.selection === 'experiencesNumber') {
    return {
      id: timeSelector.selection,
      params: {
        nExperiences: timeSelector.nExperiences,
      },
    };
  }
};
const experienceSelectorFormToQuery = (experienceSelector) => {
  // utils function
  const splitAsOrIfMultipleValues = (experienceSelector, multiField, singleField) => {
    const multiValues = (experienceSelector[multiField] || '').split(';');
    if (multiValues.length === 1) {
      return {
        id: experienceSelector.selection,
        params: { [singleField]: multiValues[0] },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(multiValues, (singleValue) => ({
          id: experienceSelector.selection,
          params: { [singleField]: singleValue },
        })),
      },
    };
  };

  if (_.isArray(experienceSelector)) {
    return { sons: _.map(experienceSelector, experienceSelectorFormToQuery) };
  }
  if (!_.isObject(experienceSelector)) {
    return experienceSelector;
  }
  if (experienceSelector.selection === null) {
    return null;
  }

  if (!experienceSelector.selection) {
    return _.mapObject(experienceSelector, experienceSelectorFormToQuery);
  }

  if (experienceSelector.selection === '#and') {
    return {
      id: '#and',
      params: experienceSelectorFormToQuery(experienceSelector['#and']),
    };
  }

  if (experienceSelector.selection === '#or') {
    return {
      id: '#or',
      params: experienceSelectorFormToQuery(experienceSelector['#or']),
    };
  }

  if (experienceSelector.selection === 'jobPosition') {
    return splitAsOrIfMultipleValues(experienceSelector, 'jobPositions', 'jobPosition');
  }

  if (experienceSelector.selection === 'skill') {
    return splitAsOrIfMultipleValues(experienceSelector, 'skillIds', 'skillId');
  }

  if (experienceSelector.selection === 'contract') {
    return {
      id: experienceSelector.selection,
      params: {
        contractType: experienceSelector.contractType,
      },
    };
  }

  if (experienceSelector.selection === 'responsibilities') {
    return {
      id: experienceSelector.selection,
      params: {
        responsibility: experienceSelector.responsibility,
      },
    };
  }

  if (experienceSelector.selection === 'modalities') {
    return {
      id: experienceSelector.selection,
      params: {
        modality: experienceSelector.modality,
      },
    };
  }

  if (experienceSelector.selection === 'location') {
    const locations =
      experienceSelector[experienceSelector.selection].mode === 'normal'
        ? (experienceSelector[experienceSelector.selection].locations || '').split(';')
        : [experienceSelector[experienceSelector.selection].location];
    if (locations.length === 1) {
      return {
        id: experienceSelector.selection,
        params: {
          location: locations[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(locations, (location) => ({
          id: experienceSelector.selection,
          params: {
            location,
          },
        })),
      },
    };
  }

  if (experienceSelector.selection === 'companyType') {
    return {
      id: experienceSelector.selection,
      params: {
        companyType: experienceSelector.companyType,
      },
    };
  }

  if (experienceSelector.selection === 'companyIndustry') {
    return splitAsOrIfMultipleValues(experienceSelector, 'industryIds', 'companyIndustry');
  }

  if (experienceSelector.selection === 'companySize') {
    return {
      id: experienceSelector.selection,
      params: {
        minNbEmployees: experienceSelector.minNbEmployees,
        maxNbEmployees: experienceSelector.maxNbEmployees,
      },
    };
  }

  if (experienceSelector.selection === 'workplacePrestige') {
    return {
      id: experienceSelector.selection,
      params: {
        minPrestige: experienceSelector.minPrestige,
        maxPrestige: experienceSelector.maxPrestige,
      },
    };
  }

  if (experienceSelector.selection === 'workplace') {
    return splitAsOrIfMultipleValues(experienceSelector, 'workplaceIds', 'workplaceId');
  }
  if (experienceSelector.selection === 'linkedinWorkplace') {
    const companies = experienceSelector.linkedinCompanies || [];
    const expandedCompanies = _.flatten(
      _.map(companies, (groupedCompany) =>
        _.map(groupedCompany.id.split(';'), (id) => ({
          id,
          name: groupedCompany.name,
        })),
      ),
    );
    if (expandedCompanies.length === 1) {
      return {
        id: experienceSelector.selection,
        params: {
          ...expandedCompanies[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(expandedCompanies, (company) => ({
          id: experienceSelector.selection,
          params: {
            ...company,
          },
        })),
      },
    };
  }
  if (experienceSelector.selection === 'educationField') {
    return splitAsOrIfMultipleValues(experienceSelector, 'educationFields', 'educationField');
  }
  if (experienceSelector.selection === 'studyLevel') {
    return {
      id: experienceSelector.selection,
      params: {
        studyLevel: experienceSelector.studyLevel,
      },
    };
  }
  if (experienceSelector.selection === 'schoolType') {
    return splitAsOrIfMultipleValues(experienceSelector, 'schoolTypes', 'schoolType');
  }
  if (experienceSelector.selection === 'educationPrestige') {
    return {
      id: experienceSelector.selection,
      params: {
        minPrestige: experienceSelector.minPrestige,
        maxPrestige: experienceSelector.maxPrestige,
      },
    };
  }
};

const globalBackgroundFormToQuery = (form) => {
  const scorerId = form.scorerId;
  const query = { scorerId };
  if (scorerId === 'companyBackground') {
    const expandedCompanies = _.flatten(
      _.map(form.linkedinCompanies || [], (groupedCompany) =>
        _.map(groupedCompany.id.split(';'), (id) => ({
          id,
          name: groupedCompany.name,
        })),
      ),
    );
    return {
      ...query,
      ...(expandedCompanies && { linkedinCompanyIds: _.pluck(expandedCompanies, 'id') }),
      ...(form.companyGroups && { companyGroups: (form.companyGroups || '').split(';') }),
      ...(form.companyTypes && { companyTypes: (form.companyTypes || '').split(';') }),
    };
  }
  if (scorerId === 'industryKnowledge') {
    return { ...query, industryIds: (form.industryIds || '').split(';') };
  }
  if (scorerId === 'studyLevel') {
    return { ...query, studyLevel: form.studyLevel };
  }
  if (scorerId === 'schoolBackground') {
    return {
      ...query,
      ...(form.schoolIds && { workplaceIds: (form.schoolIds || '').split(';') }),
      ...(form.schoolGroups && { schoolGroups: (form.schoolGroups || '').split(';') }),
      ...(form.schoolTypes && { schoolTypes: (form.schoolTypes || '').split(';') }),
    };
  }
  if (scorerId === 'educationField') {
    return { ...query, educationFields: (form.educationFields || '').split(';') };
  }
  if (scorerId === 'management') {
    return {
      ...query,
      ...(form.targetHierarchicalRanks && { targetHierarchicalRanks: (form.targetHierarchicalRanks || '').split(';') }),
      ...(form.targetManagementChunks && { targetManagementChunks: (form.targetManagementChunks || '').split(';') }),
    };
  }
  return query;

  //companyBackground
  //industryKnowledge
  //currentlyFounder
  //everFounder
  //mainlyFreelance
  //workStable
  //consultantness
  //studyLevel
  //schoolBackground
  //educationField
  //openSourceness
};

const typologyFormToQuery = (form) => {
  if (_.isArray(form)) {
    return { sons: _.map(form, typologyFormToQuery) };
  }
  if (!_.isObject(form)) {
    return form;
  }
  if (form.selection === null) {
    return null;
  }

  if (!form.selection) {
    return _.mapObject(form, typologyFormToQuery);
  }

  if (form.selection === '#and') {
    return {
      id: '#and',
      params: typologyFormToQuery(form['#and']),
    };
  }

  if (form.selection === '#or') {
    return {
      id: '#or',
      params: typologyFormToQuery(form['#or']),
    };
  }
  if (form.selection === '#not') {
    // TODO
    return {
      id: '#not',
      params: {
        son: typologyFormToQuery((form['#not'] || {}).son),
      },
    };
  }
  if (form.selection === 'company') {
    const expandedCompanies = _.flatten(
      _.map(form.linkedinCompanies || [], (groupedCompany) =>
        _.map(groupedCompany.id.split(';'), (id) => ({
          id,
          name: groupedCompany.name,
        })),
      ),
    );
    return {
      id: form.selection,
      params: {
        ...(expandedCompanies && { linkedinCompanyIds: _.pluck(expandedCompanies, 'id') }),
        ...(form.companyGroups && { companyGroups: (form.companyGroups || '').split(';') }),
        ...(form.companyTypes && { companyTypes: (form.companyTypes || '').split(';') }),
      },
    };
  }
  if (form.selection === 'modality') {
    return {
      id: form.selection,
      params: {
        ...(form.modality && { modalities: (form.modality || '').split(';') }),
      },
    };
  }
  if (form.selection === 'responsibility') {
    return {
      id: form.selection,
      params: {
        ...(form.responsibility && { responsibilities: (form.responsibility || '').split(';') }),
      },
    };
  }
  if (form.selection === 'industry') {
    return {
      id: form.selection,
      params: {
        ...(form.industryIds && { industryIds: (form.industryIds || '').split(';') }),
      },
    };
  }
  if (form.selection === 'jobPosition') {
    return {
      id: form.selection,
      params: {
        ...(form.jobPositions && { jobPositions: (form.jobPositions || '').split(';') }),
      },
    };
  }
  if (form.selection === 'skills') {
    return {
      id: form.selection,
      params: {
        ...(form.skillIds && { skills: (form.skillIds || '').split(';') }),
      },
    };
  }
  if (form.selection === 'companyPrestige') {
    return {
      id: form.selection,
      params: {
        ...(form.minPrestige && { minPrestige: form.minPrestige }),
        ...(form.maxPrestige && { maxPrestige: form.maxPrestige }),
      },
    };
  }
  if (form.selection === 'companySize') {
    return {
      id: form.selection,
      params: {
        ...(form.minNbEmployees && { minSize: form.minNbEmployees }),
        ...(form.maxNbEmployees && { maxSize: form.maxNbEmployees }),
      },
    };
  }
  if (form.selection === 'educationField') {
    return {
      id: form.selection,
      params: {
        ...(form.educationFields && { educationFields: (form.educationFields || '').split(';') }),
      },
    };
  }
  if (form.selection === 'school') {
    return {
      id: form.selection,
      params: {
        ...(form.schoolGroups && { schoolGroups: (form.schoolGroups || '').split(';') }),
        ...(form.schoolTypes && { schoolTypes: (form.schoolTypes || '').split(';') }),
        ...(form.schoolIds && { schoolIds: (form.schoolIds || '').split(';') }),
      },
    };
  }
  if (form.selection === 'schoolPrestige') {
    return {
      id: form.selection,
      params: {
        ...(form.minPrestige && { minPrestige: form.minPrestige }),
        ...(form.maxPrestige && { maxPrestige: form.maxPrestige }),
      },
    };
  }
  if (form.selection === 'studyLevel') {
    return {
      id: form.selection,
      params: {
        ...(form.studyLevel && { studyLevels: (form.studyLevel || '').split(';') }),
      },
    };
  }
  if (form.selection === 'management') {
    return {
      id: form.selection,
      params: {
        ...(form.targetHierarchicalRanks && {
          targetHierarchicalRanks: (form.targetHierarchicalRanks || '').split(';'),
        }),
        ...(form.targetManagementChunks && { targetManagementChunks: (form.targetManagementChunks || '').split(';') }),
      },
    };
  }
};

export const formScorerToQuery = (scorer) => {
  if (_.isArray(scorer)) {
    return _.map(scorer, formScorerToQuery);
  }

  if (!_.isObject(scorer)) {
    return scorer;
  }

  if (scorer.selection === null) {
    return null;
  }

  if (!scorer.selection) {
    return _.mapObject(scorer, formScorerToQuery);
  }

  if (scorer.selection === 'baseFilter') {
    return {
      id: '#and',
      params: {
        sons: [
          {
            id: 'solicitationRange',
            params: {
              maxNbSolicitations: 4,
              nbWeeks: 8,
            },
          },
        ],
      },
    };
  }

  if (scorer.selection === 'nbPositiveAnswersRange') {
    return {
      id: 'nbAnswersRange',
      params: {
        minNbAnswers: scorer.minNbAnswers,
        maxNbAnswers: scorer.maxNbAnswers,
        nbWeeks: scorer.nbWeeks,
        type: 'positive',
      },
    };
  }

  if (scorer.selection === 'nbAnyAnswersRange') {
    return {
      id: 'nbAnswersRange',
      params: {
        minNbAnswers: scorer.minNbAnswers,
        maxNbAnswers: scorer.maxNbAnswers,
        nbWeeks: scorer.nbWeeks,
      },
    };
  }

  if (['targetLocation', 'targetLocationRelaxed', 'everBeenTo', 'everBeenToRelaxed'].indexOf(scorer.selection) >= 0) {
    const locations =
      scorer[scorer.selection].mode === 'normal'
        ? (scorer[scorer.selection].locations || '').split(';')
        : [scorer[scorer.selection].location];
    if (locations.length === 1) {
      return {
        id: scorer.selection,
        params: {
          location: locations[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(locations, (location) => ({
          id: scorer.selection,
          params: {
            location,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'matchPipe') {
    // NB: since SelectPipe is enhanced, it wraps its params in the key {props.field} (here "matchPipe")
    return {
      id: scorer.selection,
      params: scorer.matchPipe,
    };
  }

  if (scorer.selection === 'matchSheetsSet') {
    // NB: as matchPipe
    return {
      id: scorer.selection,
      params: scorer.matchSheetsSet,
    };
  }

  if (scorer.selection === 'matchClientPipes') {
    return {
      id: scorer.selection,
      params: scorer.matchClientPipes,
    };
  }

  if (scorer.selection === 'schoolNamesScorer') {
    const listOfSchoolIds = (scorer.listOfSchoolIds || '').split(';');
    const processedListOfSchoolIds = [];
    _.map(listOfSchoolIds, (schoolId) => {
      if (schoolId.substring(0, 2) === 'g:') {
        _.map(schoolId.substring(2).split('+'), (id) => {
          processedListOfSchoolIds.push(id);
        });
      } else {
        processedListOfSchoolIds.push(schoolId);
      }
    });
    return {
      id: scorer.selection,
      params: {
        listOfSchoolIds: processedListOfSchoolIds,
      },
    };
  }

  if (scorer.selection === 'currentCompanyScorer') {
    const companyIds = (scorer.companyIds || '').split(';');
    const processedListOfCompanyIds = [];
    _.map(companyIds, (companyId) => {
      if (companyId.substring(0, 2) === 'g:') {
        _.map(companyId.substring(2).split('+'), (id) => {
          processedListOfCompanyIds.push(id);
        });
      } else {
        processedListOfCompanyIds.push(companyId);
      }
    });
    if (processedListOfCompanyIds.length === 1) {
      return {
        id: scorer.selection,
        params: {
          companyId: processedListOfCompanyIds[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(processedListOfCompanyIds, (companyId) => ({
          id: scorer.selection,
          params: {
            companyId,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'currentLinkedinCompanyScorer') {
    const companies = scorer.linkedinCompanies || [];
    const expandedCompanies = _.flatten(
      _.map(companies, (groupedCompany) =>
        _.map(groupedCompany.id.split(';'), (id) => ({
          id,
          name: groupedCompany.name,
        })),
      ),
    );
    if (expandedCompanies.length === 1) {
      return {
        id: scorer.selection,
        params: {
          ...expandedCompanies[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(expandedCompanies, (company) => ({
          id: scorer.selection,
          params: {
            ...company,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'matchAnywhere') {
    return {
      id: scorer.selection,
      params: {
        text: scorer.matchAnywhere.text,
        mode: scorer.matchAnywhere.mode,
      },
    };
  }

  if (scorer.selection === 'isFemale') {
    return {
      id: scorer.selection,
      params: {
        relaxed: scorer.relaxed,
        country: scorer.country,
      },
    };
  }

  if (scorer.selection === 'githubRepository') {
    return {
      id: scorer.selection,
      params: {
        min: scorer.min,
      },
    };
  }

  if (scorer.selection === 'githubFollowers') {
    return {
      id: scorer.selection,
      params: {
        min: scorer.min,
      },
    };
  }

  if (scorer.selection === 'timeElapsedSinceLastHiring') {
    return {
      id: scorer.selection,
      params: {
        min: scorer.min,
      },
    };
  }

  if (scorer.selection === 'targetJobPositionRelaxed') {
    const jobPositions = (scorer.jobPositions || '').split(';');
    if (jobPositions.length === 1) {
      return {
        id: scorer.selection,
        params: {
          jobPosition: jobPositions[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(jobPositions, (jobPosition) => ({
          id: scorer.selection,
          params: {
            jobPosition,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'targetJobPosition') {
    const params = scorer.targetJobPosition || {};
    const jobPositions = (params.jobPositions || '').split(';');
    const mode = params.mode;
    const years = params.years || {};
    if (jobPositions.length === 1) {
      return {
        id: scorer.selection,
        params: {
          jobPosition: jobPositions[0],
          mode,
          years,
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(jobPositions, (jobPosition) => ({
          id: scorer.selection,
          params: {
            jobPosition,
            mode,
            years,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'targetResponsibility' || scorer.selection === 'targetResponsibilityRelaxed') {
    return {
      id: scorer.selection,
      params: {
        responsibility: scorer.responsibility,
      },
    };
  }

  if (scorer.selection === 'targetModality') {
    return {
      id: scorer.selection,
      params: {
        modality: scorer.modality,
      },
    };
  }

  if (scorer.selection === 'minSkillScore') {
    return {
      id: scorer.selection,
      params: {
        skillId: scorer.skillId,
        minScore: scorer.minScore,
      },
    };
  }

  if (scorer.selection === 'sample') {
    return {
      id: scorer.selection,
      params: {
        sampleSize: scorer.sampleSize,
      },
    };
  }

  if (scorer.selection === 'nbAnswersRange') {
    return {
      id: scorer.selection,
      params: {
        minNbAnswers: scorer.minNbAnswers,
        maxNbAnswers: scorer.maxNbAnswers,
        type: scorer.type,
        nbWeeks: scorer.nbWeeks,
      },
    };
  }

  if (scorer.selection === 'solicitationRange') {
    return {
      id: scorer.selection,
      params: {
        maxNbSolicitations: scorer.maxNbSolicitations,
        nbWeeks: scorer.nbWeeks,
      },
    };
  }

  if (scorer.selection === 'disqualifyRate') {
    return {
      id: scorer.selection,
      params: {
        minNbActions: scorer.minNbActions,
        maxDisqualifyRate: scorer.maxDisqualifyRate,
      },
    };
  }

  if (scorer.selection === 'skillScore') {
    return {
      id: scorer.selection,
      params: {
        skillId: scorer.skillId,
      },
    };
  }

  if (scorer.selection === 'watchFilter') {
    return {
      id: '#and',
      params: {
        sons: [
          {
            id: 'solicitationRange',
            params: {
              maxNbSolicitations: 5,
              nbWeeks: 50,
            },
          },
          {
            id: 'nbAnswersRange',
            params: {
              minNbAnswers: 0,
              maxNbAnswers: 0,
              type: 'positive',
              nbWeeks: 12,
            },
          },
          {
            id: 'solicitationRange',
            params: {
              maxNbSolicitations: 1,
              nbWeeks: 10,
            },
          },
          {
            id: '#not',
            params: {
              son: {
                id: 'worksForClient',
                params: {},
              },
            },
          },
        ],
      },
    };
  }

  if (['hasDirectSkill', 'hasSkill', 'isOkWith', 'mainSkill', 'mainSkillRelaxed'].indexOf(scorer.selection) >= 0) {
    const skillIds = (scorer.skillIds || '').split(';');
    if (skillIds.length === 1) {
      return {
        id: scorer.selection,
        params: {
          skillId: skillIds[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(skillIds, (skillId) => ({
          id: scorer.selection,
          params: {
            skillId,
          },
        })),
      },
    };
  }

  if (['robustMainSkill'].indexOf(scorer.selection) >= 0) {
    const skillIds = (scorer.skillIds || '').split(';');
    if (skillIds.length === 1) {
      return {
        id: scorer.selection,
        params: {
          skillId: skillIds[0],
          slackness: scorer.slackness,
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(skillIds, (skillId) => ({
          id: scorer.selection,
          params: {
            skillId,
            slackness: scorer.slackness,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'profileLastUpdate') {
    return {
      id: scorer.selection,
      params: {
        max: scorer.max,
      },
    };
  }

  if (scorer.selection === 'hasSource') {
    const sourceTypes = (scorer.sourceTypes || '').split(';');
    if (sourceTypes.length === 1) {
      return {
        id: scorer.selection,
        params: {
          sourceType: sourceTypes[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(sourceTypes, (sourceType) => ({
          id: scorer.selection,
          params: {
            sourceType,
          },
        })),
      },
    };
  }
  if (scorer.selection === 'schoolNamesScorer') {
    const listOfSchoolIds = (scorer.listOfSchoolIds || '').split(';');
    const processedListOfSchoolIds = [];
    _.map(listOfSchoolIds, (schoolId) => {
      if (schoolId.substring(0, 2) === 'g:') {
        _.map(schoolId.substring(2).split('+'), (id) => {
          processedListOfSchoolIds.push(id);
        });
      } else {
        processedListOfSchoolIds.push(schoolId);
      }
    });
    return {
      id: scorer.selection,
      params: {
        listOfSchoolIds: processedListOfSchoolIds,
      },
    };
  }

  if (scorer.selection === 'companyIndustry') {
    const industryIds = (scorer.industryIds || '').split(';');
    if (industryIds.length === 1) {
      return {
        id: scorer.selection,
        params: {
          period: scorer.period,
          industry: industryIds[0],
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(industryIds, (industryId) => ({
          id: scorer.selection,
          params: {
            period: scorer.period,
            industry: industryId,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'companyPrestige') {
    return {
      id: scorer.selection,
      params: {
        period: scorer.period,
        minPrestige: scorer.minPrestige,
        maxPrestige: scorer.maxPrestige,
      },
    };
  }

  if (scorer.selection === 'jobScoresS6') {
    const params = scorer.jobScoresS6 || {};
    const jobPositions = (params.jobPositions || '').split(';');
    const rangeParams = { mode: params.mode };
    if ((rangeParams.mode || 'letters') === 'letters') {
      rangeParams.maxLetter = params.maxLetter;
      rangeParams.minLetter = params.minLetter;
    } else {
      rangeParams.maxRanking = params.maxRanking;
      rangeParams.minRanking = params.minRanking;
    }
    if (jobPositions.length === 1) {
      return {
        id: scorer.selection,
        params: {
          jobPosition: jobPositions[0],
          ...rangeParams,
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(jobPositions, (jobPosition) => ({
          id: scorer.selection,
          params: {
            jobPosition,
            ...rangeParams,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'skillScoresS6') {
    const params = scorer.skillScoresS6 || {};
    const skills = (params.skills || '').split(';');
    const rangeParams = { mode: params.mode };
    if ((rangeParams.mode || 'letters') === 'letters') {
      rangeParams.maxLetter = params.maxLetter;
      rangeParams.minLetter = params.minLetter;
    } else {
      rangeParams.maxRanking = params.maxRanking;
      rangeParams.minRanking = params.minRanking;
    }
    if (skills.length === 1) {
      return {
        id: scorer.selection,
        params: {
          skill: skills[0],
          ...rangeParams,
        },
      };
    }
    return {
      id: '#or',
      params: {
        sons: _.map(skills, (skill) => ({
          id: scorer.selection,
          params: {
            skill,
            ...rangeParams,
          },
        })),
      },
    };
  }

  if (scorer.selection === 'companyType') {
    return {
      id: scorer.selection,
      params: {
        period: scorer.period,
        companyType: scorer.companyType,
      },
    };
  }

  if (scorer.selection === 'background') {
    return {
      id: scorer.selection,
      params: {
        emplacement: (scorer.background || {}).emplacement,
        timeSelector: timeSelectorFormToQuery((scorer.background || {}).timeSelector),
        experienceSelector: experienceSelectorFormToQuery((scorer.background || {}).experienceSelector),
      },
    };
  }

  if (scorer.selection === 'advancedBackground') {
    return {
      id: scorer.selection,
      params: {
        mode: (scorer.advancedBackground || {}).mode,
        ...((scorer.advancedBackground || {}).aggregation && {
          aggregation: timeSelectorFormToQuery(scorer.advancedBackground.aggregation),
        }),
        typology: typologyFormToQuery((scorer.advancedBackground || {}).typology),
      },
    };
  }

  if (scorer.selection === 'globalBackground') {
    return {
      id: scorer.selection,
      params: globalBackgroundFormToQuery(scorer.globalBackground || {}),
    };
  }

  if (scorer.selection == 'watchJobPosition') {
    return {
      id: scorer.selection,
      params: {
        ...(scorer.jobPositions && { jobPositions: (scorer.jobPositions || '').split(';') }),
        operator: scorer.operator || '$or',
      },
    };
  }
  if (scorer.selection == 'watchSkills') {
    return {
      id: scorer.selection,
      params: {
        ...(scorer.skills && { skills: (scorer.skills || '').split(';') }),
        operator: scorer.operator || '$or',
      },
    };
  }
  if (scorer.selection == 'watchManagement') {
    return {
      id: scorer.selection,
      params: {
        managementLevel: scorer.managementLevel,
      },
    };
  }
  if (scorer.selection == 'watchLocationAndRemoteWish') {
    return {
      id: scorer.selection,
      params: {
        locationAndRemoteWishes: scorer.locationAndRemoteWishes || [],
      },
    };
  }

  return {
    id: scorer.selection,
    params: _.mapObject(scorer[scorer.selection], formScorerToQuery),
  };
};

class TemplateSelector extends React.Component {
  state = {
    currentUser: this.props.username,
  };
  componentWillMount() {
    this.loadTemplates();
  }
  loadTemplates = async () => {
    getSearchTemplates().then((templates) => {
      this.setState({ templates });
    });
  };
  onSelectUser = (e, { value }) => {
    this.setState({ currentUser: value });
  };
  onDetails = (template) => {
    this.setState({ detailedTemplate: template });
  };
  onCloseDetails = () => {
    this.setState({ detailedTemplate: null });
  };
  onDeleteTemplate = (template) => {
    if (!template || !template.id) {
      return alert('invalid template: impossible to suppress');
    }
    const url = `${baseUrl}/searchTemplates/${template.id}`;
    return axios.delete(url, template).then(({ data }) => {
      if (data.error) {
        return alert(data.error);
      }
      this.loadTemplates();
    });
  };
  render() {
    const { templates, currentUser, detailedTemplate } = this.state;
    if (!templates) {
      return <Loader size='big'>Loading</Loader>;
    }
    const users = _.map(_.uniq(_.compact(_.pluck(templates, 'user'))), (user) => ({ text: user, value: user }));
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Dropdown
              selection
              placeholder={currentUser || 'Select user...'}
              onChange={this.onSelectUser}
              options={users}
            />
            <List relaxed>
              {_.map(_.filter(templates, ({ user }) => currentUser && user === currentUser), (template, index) => (
                <List.Item key={index}>
                  <List.Content floated='left'>
                    <Button.Group size='mini'>
                      <Button icon='remove' color='red' onClick={() => this.onDeleteTemplate(template)} />
                      <Button icon='code' onClick={() => this.onDetails(template)} />
                    </Button.Group>
                  </List.Content>
                  <List.Content>
                    <Label style={{ cursor: 'pointer' }} onClick={() => this.props.onSelectTemplate(template)}>
                      [{template.user}] {template.title}
                    </Label>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column>
            {detailedTemplate && (
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign='left'>
                    <h2>
                      [{detailedTemplate.user}] {detailedTemplate.title}
                    </h2>
                  </Grid.Column>
                  <Grid.Column textAlign='right'>
                    <Button icon='close' onClick={this.onCloseDetails} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <pre>{JSON.stringify(detailedTemplate, null, 4)}</pre>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export { Filter, buildFilter };
