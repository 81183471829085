import _ from 'underscore';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Container, Grid, Table, Button,
  Dropdown, Select, Segment,
  Popup, Icon, Image
} from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

const INFINITY = 1000 * 1000 * 1000;

const capitalizeFirstLetter = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

const Filters = ({
  users,
  selectedOps, onChangeOps,
  selectedSales, onChangeSales,
  selectedCompletionMode, onChangeCompletionMode
}) => {

  const userOptions = [
    { value: 'none', text: 'All' },
    ..._.map(users, ({ id, name }) => ({ value: id, text: capitalizeFirstLetter(name || '') }))
  ];

  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        <b>Completion: </b>
        <Select
          options={[
            { value: 'hide-completed', text: 'Hide completed' },
            { value: 'show-completed', text: 'Show completed' },
          ]}
          value={selectedCompletionMode || 'hide-completed'}
          onChange={(e, { value }) => {
            onChangeCompletionMode({ completionMode: value })
          }}
        />
      </div>
      <div style={{ display: 'inline-block', marginLeft: 20 }}>
        <b>Owner: </b>
        <Select
          search
          options={userOptions}
          value={selectedOps || 'none'}
          onChange={(e, { value }) => {
            onChangeOps({ user: value === 'none' ? null : value })
          }}
        />
      </div>
      <div style={{ display: 'inline-block', marginLeft: 20 }}>
        <b>Sales: </b>
        <Select
          search
          options={userOptions}
          value={selectedSales || 'none'}
          onChange={(e, { value }) => {
            onChangeSales({ user: value === 'none' ? null : value })
          }}
        />
      </div>
    </div>
  );
}

const BucketSelector = ({ buckets, selectedBucketId, onSelectBucket }) => {
  return (
    <Container style={{ paddingLeft: '5%', paddingRight: '5%' }}>
      <Grid style={{ padding: 0, margin: 0 }}>
        <Grid.Row columns={buckets.length} style={{ padding: 0, margin: 0 }}>
          {_.map(buckets, ({ id, title, jobs }) => (
            <Grid.Column key={id} style={{ padding: 10, margin: 0 }}>
              <Segment
                onClick={() => onSelectBucket({ bucketId: selectedBucketId === id ? null : id })}
                style={{
                  cursor: 'pointer',
                  ...(selectedBucketId === id) && {
                    border: '1px solid black'
                  }
                }}
              >
                <center>
                  <div>
                    <b style={{ color: 'grey' }}>{title}</b>
                  </div>
                  <br />
                  <div>
                    <h1>{jobs.length}</h1>
                  </div>
                </center>
              </Segment>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Container>
  );
}

const WatchBoardTable = ({ targetJobs, markJobCompletedUntil, advancedMode }) => {

  const markButton = (job) => {
    const markForNbWeeks = (nbWeeks) => {
      const timestamp = 1000 * moment().add(nbWeeks - 1, 'weeks').endOf('isoWeek').unix()
      markJobCompletedUntil({
        jobId: job.id,
        timestamp,
      });
    }

    const defaultNbWeeks = job.nbWeeksAgo === undefined ? (
      1
    ) : job.nbWeeksAgo < 3 ? (
      1
    ) : 2;

    return (
      <Button.Group style={{ backgroundColor: 'whitesmoke !important' }}>
        <Button onClick={() => markForNbWeeks(defaultNbWeeks)}>
          {defaultNbWeeks} week{defaultNbWeeks > 1 ? 's' : ''}
        </Button>
        <Dropdown
          className='button icon'
          floating
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => markForNbWeeks(1)}>
              1 week
            </Dropdown.Item>
            <Dropdown.Item onClick={() => markForNbWeeks(2)}>
              2 weeks
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    );
  }

  const getBackgroundColor = ({ job }) => (
    job.state === 'offer_not_active' ? (
      'gainsboro'
    ) : job.creationNbWeeksAgo == 0 ? (
      '#ddf9ff'
    ) : job.nbWeeksAgo === undefined ? (
      'whitesmoke'
    ) : '#ddffdd'
    /*: job.nbWeeksAgo < 3 ? (
      '#ddffdd'
    ) : job.nbWeeksAgo < 5 ? (
      '#fdebd0'
    ) : '#ffdddd'*/
  )

  const jobLink = ({ job }) => (
    <Grid style={{ margin: 0 }}>
      {/*
      <a href={`/offers/${job.id}`} target='_blank' rel='noopener noreferrer'>
          <div>{job.id}</div>
        </a>
      */}

      <Grid.Column width={11} style={{ padding: '5px' }}>
        <a href={`/offers/${job.id}`} target='_blank' rel='noopener noreferrer'>
          {job.markedCompleted ? '!!!' : ''}
          {job.title && job.title.length > 20 ? (
            <div>{job.title.slice(0, 20)}</div>
          ) : (
            <div>{job.title}</div>
          )}
        </a>
      </Grid.Column>
      <Grid.Column width={1} style={{ padding: '5px' }}>
        {job.title && job.title.length > 20 && (
          <Popup wide='very' trigger={<Icon name='add' size='small' />} content={job.title} />
        )}
      </Grid.Column>
      <Grid.Column width={2} style={{ padding: '5px' }} textAlign='right'>
        {job.sweetsheetId && (
          <a href={`/flowboard/sweetsheets/id/${job.sweetsheetId}`} target='_blank' rel='noopener noreferrer'>
            <Image src='/images/google-sheet.png' style={{ width: '15px', height: '15px' }} />
          </a>
        )}
      </Grid.Column>
      <Grid.Column width={2} style={{ padding: '5px' }}>
        {job.platformId && (
          <a
            href={`https://app.hiresweet.com/client/${job.companyId}/jobs/${job.platformId}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image src='/images/sweetapp-logo.png' style={{ width: '15px', height: '15px' }} />
          </a>
        )}
      </Grid.Column>

    </Grid>
  );

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Mark</Table.HeaderCell>
          <Table.HeaderCell>Client</Table.HeaderCell>
          <Table.HeaderCell>Job</Table.HeaderCell>
          <Table.HeaderCell>Sales</Table.HeaderCell>
          <Table.HeaderCell>Ops</Table.HeaderCell>
          <Table.HeaderCell>Creation Weeks</Table.HeaderCell>
          <Table.HeaderCell>Last Action Weeks</Table.HeaderCell>
          {advancedMode && <Table.HeaderCell>Marked At</Table.HeaderCell>}
          {advancedMode && <Table.HeaderCell>Marked Until</Table.HeaderCell>}
          {/*<Table.HeaderCell># to-treat</Table.HeaderCell>*/}
          <Table.HeaderCell># New</Table.HeaderCell>
          {advancedMode && <Table.HeaderCell># WP # AP</Table.HeaderCell>}
          <Table.HeaderCell># Pending</Table.HeaderCell>
          {/*<Table.HeaderCell>#notUploaded</Table.HeaderCell>*/}
          <Table.HeaderCell>#send week(ever)</Table.HeaderCell>
          <Table.HeaderCell>%send week(ever)</Table.HeaderCell>
          {advancedMode && <Table.HeaderCell>Completed ?</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(targetJobs, (job) => (
          <Table.Row
            key={job.id}
            style={{ backgroundColor: getBackgroundColor({ job }) }}
          >
            <Table.Cell>{markButton(job)}</Table.Cell>
            <Table.Cell>{job.companyId}</Table.Cell>
            <Table.Cell>{advancedMode ? job.id : jobLink({ job })}</Table.Cell>
            <Table.Cell>{capitalizeFirstLetter(job.salesPerson || '')}</Table.Cell>
            <Table.Cell>{capitalizeFirstLetter(job.owner || '')}</Table.Cell>
            <Table.Cell>{job.creationNbWeeksAgo}</Table.Cell>
            <Table.Cell>{job.nbWeeksAgo}</Table.Cell>
            {advancedMode && <Table.Cell>{job.markedCompletedFrom && moment(job.markedCompletedFrom).format('YYYY-MM-DD')}</Table.Cell>}
            {advancedMode && <Table.Cell>{job.markedCompletedUntil && moment(job.markedCompletedUntil).format('YYYY-MM-DD')}</Table.Cell>}
            {/*<Table.Cell>{job.nbPendingInExternalFlow}</Table.Cell>*/}
            <Table.Cell>{job.nbPrePendingProfiles}</Table.Cell>
            {advancedMode && <Table.Cell>{job.nbWeeklySweetsheetUploads} - {job.nbWeeklyAutopushUploads}</Table.Cell>}
            <Table.Cell>{job.nbPendingProfiles}</Table.Cell>
            {/*<Table.Cell>{job.notUploaded}</Table.Cell>*/}
            <Table.Cell >{job.weekSend} ({job.globalSend})</Table.Cell>
            <Table.Cell >{job.weekTxSend}% ({job.globalTxSend}%)</Table.Cell>
            {advancedMode && <Table.Cell>{job.completedReason}</Table.Cell>}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

}

const getMarkedCompletedAndReason = ({ job, creationNbWeeksAgo }) => {

  // base reasons
  const nowStr = new Date().toISOString();
  const isMarkedUntilNowAfterLastAction = job.markedCompletedUntil && (job.markedCompletedUntil > nowStr) && (
    !job.lastActivity || !job.markedCompletedFrom || (
      moment(job.lastActivity) < moment(job.markedCompletedFrom).endOf('isoWeek')
    )
  )
  const hasEnoughPendings = job.nbPendingProfiles > 10;
  const noWorkpipeSendsInPastMonth = (job.last30DaysWorkpipeSends || 0) == 0;
  const hasEnoughWeeklyAutopush = job.nbWeeklyAutopushUploads && job.nbWeeklyAutopushUploads >= 10;
  const isNew = creationNbWeeksAgo <= 1;
  const { nbSends: autopushSends, nbSkips: autopushSkips } = (job.currentWeekAutopushStats || {})
  const autopushOnlyHasSkips = (((autopushSends || 0) == 0) && (autopushSkips || 0) > 0)

  if (isMarkedUntilNowAfterLastAction) {
    return { markedCompleted: true, reason: `marked completed` }
  }
  if (!isNew && !autopushOnlyHasSkips && hasEnoughPendings) {
    return { markedCompleted: true, reason: `enough pendings : ${job.nbPendingProfiles}` }
  }

  if (!isNew && !autopushOnlyHasSkips && hasEnoughWeeklyAutopush) {
    return { markedCompleted: true, reason: `enough weekly autopush : ${job.nbWeeklyAutopushUploads}` }
  }
  if (!isNew && noWorkpipeSendsInPastMonth) {
    return { markedCompleted: true, reason: `no workpipe sends in past month` }
  }

  return {
    markedCompleted: false,
    reason: `wpSends ${job.last30DaysWorkpipeSends} apSends ${autopushSends} apSkips ${autopushSkips}`
  }
}

class WatchBoard extends React.Component {
  state = {
    users: null,
    jobs: null,
    selectedOps: null,
    selectedSales: null,
    selectedBucketId: null,
    selectedCompletionMode: 'hide-completed'
  }

  nbWeeksAgo = {};


  loadData = async () => {

    const { data: users } = await axios.get(`${baseUrl}/users`);
    this.setState({ users });

    const { data } = await axios.get(`${baseUrl}/flowboard`, { params: { watchMode: true } });
    let jobs = _.map(data, job => {
      const actionNbWeeksAgo = job.lastActivity ? (
        this.nbWeeksAgo[job.lastActivity.slice(0, 10)] !== undefined ? (
          this.nbWeeksAgo[job.lastActivity.slice(0, 10)]
        ) : INFINITY
      ) : undefined
      const creationNbWeeksAgo = job.creation ? (
        this.nbWeeksAgo[job.creation.slice(0, 10)] !== undefined ? (
          this.nbWeeksAgo[job.creation.slice(0, 10)]
        ) : INFINITY
      ) : undefined
      const nbWeeksAgo = (creationNbWeeksAgo !== undefined && actionNbWeeksAgo !== undefined) ? (
        Math.min(creationNbWeeksAgo, actionNbWeeksAgo)
      ) : (creationNbWeeksAgo !== undefined) ? (
        creationNbWeeksAgo
      ) : (actionNbWeeksAgo !== undefined) ? (
        actionNbWeeksAgo
      ) : undefined

      //const { markedCompleted, reason} = getMarkedCompletedAndReason({job, creationNbWeeksAgo});
      const nbUploads = (job.nbWeeklyAutopushUploads || 0) + (job.nbWeeklySweetsheetUploads || 0)
      const score = ((creationNbWeeksAgo == 0 ? -1 : nbWeeksAgo) + nbUploads / 1000) || 0
      return { ...job, actionNbWeeksAgo, creationNbWeeksAgo, nbWeeksAgo, nbUploads, score, nbWeekActions: job.nbWeekActions }
    })
    jobs = _.sortBy(jobs, 'score')
    setTimeout(() => {
      this.setState({ jobs });
    }, 500);
  }

  componentDidMount() {

    //nbWeekdaysAgo;
    const todayTimestamp = Date.now();
    let nbWeeksAgo = 0;
    let previousDayNumber;
    for (let iDay = 0; iDay <= 800; iDay++) {
      const day = new Date(todayTimestamp - iDay * 24 * 3600 * 1000);
      const dayNumber = day.getUTCDay();
      if (previousDayNumber === 1 && dayNumber === 0) {
        nbWeeksAgo++;
      }
      this.nbWeeksAgo[day.toISOString().slice(0, 10)] = nbWeeksAgo;
      previousDayNumber = dayNumber;
    }
    this.loadData();
  }

  handleChangeSelectedOps = ({ user }) => {
    this.setState({
      selectedOps: user
    });
  }

  handleChangeSelectedSales = ({ user }) => {
    this.setState({
      selectedSales: user
    });
  }

  handleChangeCompletionMode = ({ completionMode }) => {
    this.setState({
      selectedCompletionMode: completionMode
    });
  }

  handleChangeSelectedBucket = ({ bucketId }) => {
    this.setState({
      selectedBucketId: bucketId
    });
  }

  handleMarkJobCompletedUntil = ({ jobId, timestamp }) => {
    axios.post(`${baseUrl}/offers/${jobId}/markAsCompleted`, { timestamp });
    this.setState({
      jobs: _.map(this.state.jobs, (job) => (
        job.id === jobId ? ({
          ...job,
          markedCompleted: true
        }) : (
          job
        )
      ))
    });
  }

  handleToggleAdvancedMode = () => {
    this.setState({ advancedMode: !this.state.advancedMode })
  }

  render() {

    const {
      jobs,
      users,
      selectedOps,
      selectedSales,
      selectedBucketId,
      selectedCompletionMode,
      advancedMode
    } = this.state;

    if (!jobs) {
      return (
        <div>Loading...</div>
      );
    }

    if (!users) {
      return (
        <div>Loading...</div>
      );
    }

    const filteredJobs = _.compact(_.map(jobs, (job) => {
      if (selectedOps && job.owner !== selectedOps) {
        return null;
      }
      if (selectedSales && job.salesPerson !== selectedSales) {
        return null;
      }
      if (selectedCompletionMode !== 'show-completed' && job.markedCompleted) {
        return null;
      }
      return job;
    }));

    const buckets = [
      { id: '0-2', title: '0-2 weeks ago', jobs: [] },
      { id: '3-4', title: '3-4 weeks ago', jobs: [] },
      { id: '4-6', title: '4-6 weeks ago', jobs: [] },
      { id: '6+', title: '6+ weeks ago', jobs: [] },
      { id: '?', title: 'Unclassified', jobs: [] },
    ]

    const bucketsByWeekdaysClass = {};
    _.each(buckets, ({ id, jobs }) => {
      bucketsByWeekdaysClass[id] = jobs;
    });

    _.each(filteredJobs, (job) => {
      const values = []
      if (job.nbWeeksAgo !== undefined) {
        values.push(job.nbWeeksAgo)
      }
      const bucketId = job.nbWeeksAgo === undefined ? (
        '?'
      ) : job.nbWeeksAgo < 3 ? (
        '0-2'
      ) : job.nbWeeksAgo < 5 ? (
        '3-4'
      ) : job.nbWeeksAgo < 7 ? (
        '4-6'
      ) : '6+'
      bucketsByWeekdaysClass[bucketId].push(job)
    });

    const targetJobs = selectedBucketId ? (
      _.findWhere(buckets, { id: selectedBucketId }).jobs
    ) : filteredJobs;

    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <Filters
              users={users}
              selectedOps={selectedOps}
              onChangeOps={this.handleChangeSelectedOps}
              selectedSales={selectedSales}
              onChangeSales={this.handleChangeSelectedSales}
              selectedCompletionMode={selectedCompletionMode}
              onChangeCompletionMode={this.handleChangeCompletionMode}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Button size='tiny' onClick={() => this.handleToggleAdvancedMode()}>
              {advancedMode ? 'normal mode' : 'advanced mode'}
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <br />
          {/*
        <BucketSelector
          buckets={buckets}
          selectedBucketId={selectedBucketId}
          onSelectBucket={this.handleChangeSelectedBucket}
        />
        <br />
        */}
          <b>{`${targetJobs.length} jobs`}</b>
          <br />
          <WatchBoardTable
            targetJobs={targetJobs}
            advancedMode={advancedMode}
            markJobCompletedUntil={this.handleMarkJobCompletedUntil}
          />
        </Grid.Row>
      </Grid>

    );
  }
}

export default WatchBoard;
