import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import baseUrl from '../../baseUrl';
import { Image, Grid, Button, Modal, Table, Icon, Input as SInput, Segment, Header, Label } from 'semantic-ui-react';
import { SweetForm, Input, Select, enhance, SelectInt, Checkbox, List as SweetList } from 'sweetform';
import PhotoLinkModal from '../../components/PhotoLinkModal';
import moment from 'moment';

//const WATCH_SHEET_ID = 'watch-sheet-1';
const loadUsers = async () => {
  const users = (await axios.get(`${baseUrl}/users`)).data;
  return { options: users, complete: true };
};

const loadMissionOptions = async () => {
  const { missions } = (await axios.get(`${baseUrl}/candidates/quentinQaMissions`)).data;
  return {
    options: missions,
    complete: true
  }
}

const profilesPerWeekOptions = _.map(_.range(0, 500, 5), (i) => ({ 'label': `${i} profiles/week`, 'value': i }))

const SelectMissionSettingsLinkedinUrl = enhance((props) => {
  return (
    <Segment>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            CRM Project
          </Grid.Column>
          <Grid.Column width={10}>
            <Select
              async={true}
              loadOptions={loadMissionOptions}
              labelKey='name'
              valueKey='id'
              field="missionId"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            Candidates connected with
          </Grid.Column>
          <Grid.Column width={10}>
            <Input field="linkedinUrl" placeholder="https://linkedin.com/in/xxx" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
})

const SelectMissionSettings = enhance((props) => {
  return (
    <Segment>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            CRM Project
          </Grid.Column>
          <Grid.Column width={10}>
            <Select
              async={true}
              loadOptions={loadMissionOptions}
              labelKey='name'
              valueKey='id'
              field="missionId"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
})


const SelectHuntSettings = enhance((props) => (
  <Grid columns={2}>
    <Grid.Column width={4}>
      <Select
        options={profilesPerWeekOptions}
        field="profilesPerWeek"
        placeholder="Profiles per week"
      />
    </Grid.Column>
    <Grid.Column width={12}>
      {props.selectLinkedinUrl ? (
        <SweetList component={SelectMissionSettingsLinkedinUrl} field="missions" compact />
      ) : (
        <SweetList component={SelectMissionSettings} field="missions" compact />
      )}
    </Grid.Column>
  </Grid>
));

const SelectMultiHuntSettings = enhance(() => (
  <Grid>
    <Grid.Row columns={2}>
      <Grid.Column width={2}>Smart Hunt</Grid.Column>
      <Grid.Column width={14}><SelectHuntSettings field="smartHunt" /></Grid.Column>
    </Grid.Row>
    <Grid.Row columns={2}>
      <Grid.Column width={2}>Targeted Hunt</Grid.Column>
      <Grid.Column width={14}><SelectHuntSettings field="targetedHunt" /></Grid.Column>
    </Grid.Row>
    <Grid.Row columns={2}>
      <Grid.Column width={2}>HPF Past Candidate</Grid.Column>
      <Grid.Column width={14}><SelectHuntSettings field="hpfPastCandidates" /></Grid.Column>
    </Grid.Row>
    <Grid.Row columns={2}>
      <Grid.Column width={2}>HPF Recontact</Grid.Column>
      <Grid.Column width={14}><SelectHuntSettings field="hpfRecontact" selectLinkedinUrl={true}/></Grid.Column>
    </Grid.Row>
    <Grid.Row columns={2}>
      <Grid.Column width={2}>Snap Tasks</Grid.Column>
      <Grid.Column width={14}><SelectHuntSettings field="snapTasks" /></Grid.Column>
    </Grid.Row>
  </Grid>
));


const TalentStrategistEditor = enhance(() => (
  <div>
    <Select
      field='status'
      options={[
        {
          value: "active",
          label: (
            <div>
              <Icon color='green' name='check' />
              Active
            </div>
          ),
        },
        {
          value: "off",
          label: (
            <div>
              <Icon color='orange' name='pause' />
              Off (no smart hunt)
            </div>
          ),
        },
        {
          value: "archived",
          label: (
            <div>
              <Icon color='red' name='remove' />
              Archived
            </div>
          ),
        }
      ]}
      defaultValue={"active"}
    />
    <Select
      field='userId'
      async={true}
      loadOptions={loadUsers}
      labelKey='name'
      valueKey='id'
    />
    <Input placeholder='firstname' field='firstname' />
    <Input placeholder='lastname' field='lastname' />
    <Input placeholder='email' field='email' />
    <Input placeholder='calendlyUrl' field='calendlyUrl' />
    <Input placeholder='phoneNumber' field='phoneNumber' />
    <Input placeholder='slackId' field='slackId' />
    <Input placeholder='<leave blank>' field='notionId' disabled={true}/>
    <SelectInt field='offersAffectationWeight' placeholder="Poids d'affectation des nouvelles offres" min={0} max={5} />
    <SelectInt field='prioOffersAffectationWeight' placeholder="Poids d'affectation des offres prioritaires" min={0} max={5} />
    <Header as="h4">Smart Hunt Settings</Header>
    <SelectMultiHuntSettings field="huntSettings" />
  </div>
));


const CreateTalentStrategistModal = ({ onClose, onChange, onSubmit }) => {
  return (
    <div>
      <Modal
        onClose={() => onClose()}
        open
        closeIcon
        size='fullscreen'
      >
        <Modal.Header>Create Talent Strategist</Modal.Header>
        <Modal.Content style={{ minHeight: 300 }}>
          <SweetForm onChange={onChange}>
            <TalentStrategistEditor field="talentStrategist" />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit()}>
            Create
          </Button>
        </Modal.Actions>
      </Modal >
    </div>

  )
}

const EditTalentStrategistModal = ({ onClose, onChange, onSubmit, onDelete, talentStrategist }) => {
  return (
    <div>
      <Modal
        onClose={() => onClose()}
        open
        closeIcon
        size='fullscreen'
      >
        <Modal.Header>Edit Talent Strategist</Modal.Header>
        <Modal.Content style={{ minHeight: 300 }}>
          <SweetForm initialValues={{ talentStrategist }} onChange={onChange}>
            <TalentStrategistEditor field="talentStrategist" />
          </SweetForm>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => onDelete()}>
            Delete
          </Button>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal >
    </div>

  )
}

const checkOneHuntSetting = ({ profilesPerWeek, missions }) => {
  if (!profilesPerWeek || profilesPerWeek == 0) {
    return;
  }
  if (_.isEmpty(missions)) {
    throw Error("Should define at least one project")
  }
  _.each(missions, ({ missionId, onlyConnectedWith, linkedinUrl }) => {
    if (!missionId) {
      throw Error("Should define CRM project")
    }
    if (onlyConnectedWith && !linkedinUrl) {
      throw Error("Should define Linkedin URL if 'connected with'");
    }
  });
  const missionCount = _.countBy(missions, ({ onlyConnectedWith }) => onlyConnectedWith ? "connected" : "not-connected");
  if ((missionCount["connected"] || 0) > 1) {
    throw Error("Cannot have more than one project 'connected with' ")
  }
  if ((missionCount["not-connected"] || 0) > 1) {
    throw Error("Cannot have more than one project 'not connected with' ");
  }
}
const checkHuntSettings = ({ huntSettings }) => {
  const { smartHunt, recontact } = huntSettings || {};
  checkOneHuntSetting(smartHunt || {});
  checkOneHuntSetting(recontact || {});
}


class TalentStrategists extends React.Component {
  state = {
    talentStrategists: null,
    editedTalentStrategist: null,
    editMode: null,
  };

  componentWillMount() {
    this.load();
  }

  load = async () => {
    try {
      console.log("loadig...")
      const hiresweetEmployees = (await axios.get(`${baseUrl}/hiresweetEmployees`)).data;
      const talentStrategists = _.filter(hiresweetEmployees, ({ isTalentStrategist }) => isTalentStrategist);
      console.log(talentStrategists)

      this.setState({
        talentStrategists,
      });
    } catch (e) {
      alert(e.message)
    }
  };

  onOpenAddTalentStrategist = () => {
    this.setState({
      editMode: 'addTalentStrategist',
      editedTalentStrategist: null,
    });
  };

  onOpenEditTalentStrategist = (talentStrategist) => {
    this.setState({
      editMode: 'editTalentStrategist',
      editedTalentStrategist: talentStrategist,
    });
  };

  onOpenEditPhotoLink = (talentStrategist) => {
    this.setState({
      editMode: 'editPhotoLink',
      editedTalentStrategist: talentStrategist,
    });
  };


  onCloseEdition = () => {
    this.setState({
      editMode: null,
      editedTalentStrategist: null,
    });
  };

  onSubmitAddTalentStrategist = async () => {
    try {
      const { editedTalentStrategist: talentStrategist } = this.state;
      const { userId, huntSettings } = talentStrategist;
      if (!userId) {
        throw Error("should provide userId")
      }
      checkHuntSettings({ huntSettings })
      const { data } = await axios.post(`${baseUrl}/hiresweetEmployees`, { ...talentStrategist, isTalentStrategist: true });
      if (data.error) {
        throw Error(data.error);
      }
      await this.load();
      await this.onCloseEdition();
    } catch (e) {
      alert(e.message)
    }
  };

  onSubmitEditTalentStrategist = async () => {
    try {
      const { editedTalentStrategist: talentStrategist } = this.state;
      const { userId, huntSettings } = talentStrategist;
      if (!userId) {
        throw Error("should provide userId")
      }
      checkHuntSettings({ huntSettings })
      const { data } = await axios.put(`${baseUrl}/hiresweetEmployees/${userId}`, talentStrategist);
      if (data.error) {
        throw Error(data.error);
      }
      await this.load();
      await this.onCloseEdition();
    } catch (e) {
      alert(e.message)
    }
  };

  onSubmitPhotoLink = async ({ userId, photoLink }) => {
    try {
      const { data } = await axios.put(`${baseUrl}/hiresweetEmployees/${userId}`, { photoLink });
      if (data.error) {
        throw Error(data.error);
      }
      await this.load();
    } catch (e) {
      alert(e.message);
    }
  }

  onChangeTalentStrategist = async ({ talentStrategist }) => {
    this.setState({ editedTalentStrategist: talentStrategist });
  };

  onDeleteTalentStrategist = async ({ userId }) => {
    try {
      if (!userId) {
        throw Error("should provide userId")
      }
      const { data } = await axios.delete(`${baseUrl}/hiresweetEmployees/${userId}`);
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
      await this.load();
      await this.onCloseEdition();
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
  };

  onEquilibrateOffersAffectation = async () => {
    this.setState({ equilibratingOffersAffectation: true });
    try {
      const { data } = await axios.get(`${baseUrl}/hiresweetEmployees/equilibrateOffersAffectation`);
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
    this.setState({ equilibratingOffersAffectation: false });
  }

  onChangeTransferValues = ({ sourceUserId, targetUserId, percentage }) => {
    this.setState({ sourceUserId, targetUserId, percentage });
  }
  onTransferCandidates = async () => {
    this.setState({ transferingCandidates: true });
    const { sourceUserId, targetUserId, percentage } = this.state;
    try {
      const { data } = await axios.post(
        `${baseUrl}/hiresweetEmployees/transferCandidates`,
        { sourceUserId, targetUserId, percentage }
      );
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
    this.setState({ transferingCandidates: false });
  }

  onResetTopCandidates = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/candidates/resetTopCandidates`,
      );
      if (data.error) {
        throw Error(data.error);
      }
      alert('done')
    } catch (e) {
      alert(JSON.stringify(e, null, 4))
    }
  }

  render() {
    const { editMode, editedTalentStrategist, talentStrategists } = this.state;
    const getPosition = ({ status }) => {
      if (status == "active") {
        return 0;
      }
      if (status == "off") {
        return 1;
      }
      return 2;
    }
    return (
      <div>

        <Button onClick={() => this.onOpenAddTalentStrategist()}>Add Talent Strategist</Button>

        {!_.isEmpty(talentStrategists) && (
          <Table>
            <Table.Header>
              <Table.HeaderCell>
              </Table.HeaderCell>
              <Table.HeaderCell>
              </Table.HeaderCell>
              <Table.HeaderCell>
                Status
              </Table.HeaderCell>
              <Table.HeaderCell>
                User
              </Table.HeaderCell>
              <Table.HeaderCell>
                Firstname
              </Table.HeaderCell>
              <Table.HeaderCell>
                Lastname
              </Table.HeaderCell>
              <Table.HeaderCell>
                Email
              </Table.HeaderCell>
              <Table.HeaderCell>
                Calendly
              </Table.HeaderCell>
              <Table.HeaderCell>
                Phone
              </Table.HeaderCell>
              <Table.HeaderCell>
                Slack Id
              </Table.HeaderCell>
              <Table.HeaderCell>
                Notion Id
              </Table.HeaderCell>
              <Table.HeaderCell>
                CRM Projects
              </Table.HeaderCell>
              <Table.HeaderCell>
                Poids d'affectation des nouvelles offres
              </Table.HeaderCell>
              <Table.HeaderCell>
                Poids d'affectation des offres prioritaires
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {(_.map(_.sortBy(talentStrategists, (talentStrategist) => getPosition(talentStrategist)), (talentStrategist, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Button icon onClick={() => this.onOpenEditTalentStrategist(talentStrategist)}>
                      <Icon name='pencil' />
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Image
                      src={talentStrategist.photoLink || '/images/default-avatar-1.svg'}
                      circular
                      style={{
                        width: '36px',
                        height: '36px',
                        cursor: 'pointer'
                      }}
                      onError={(e) => {
                        e.target.src = '/images/default-avatar-1.svg';
                      }}
                      onClick={() => this.onOpenEditPhotoLink(talentStrategist)}
                    />
                  </Table.Cell>

                  <Table.Cell>
                    {talentStrategist.status == 'active' ? (
                      <Icon color='green' name='check' />
                    ) : talentStrategist.status == 'off' ? (
                      <Icon color='orange' name='pause' />
                    ) : (
                      <Icon color='red' name='remove' />
                    )}
                  </Table.Cell>

                  <Table.Cell>{talentStrategist.userId}</Table.Cell>
                  <Table.Cell>{talentStrategist.firstname}</Table.Cell>
                  <Table.Cell>{talentStrategist.lastname}</Table.Cell>
                  <Table.Cell>{talentStrategist.email}</Table.Cell>
                  <Table.Cell>{talentStrategist.calendlyUrl}</Table.Cell>
                  <Table.Cell>{talentStrategist.phoneNumber}</Table.Cell>
                  <Table.Cell>{talentStrategist.slackId}</Table.Cell>
                  <Table.Cell>{talentStrategist.notionId}</Table.Cell>
                  <Table.Cell>
                    {_.map(talentStrategist.huntSettings, (missionParams, missionName) => {
                      const { profilesPerWeek } = missionParams || {};
                      if (profilesPerWeek) {
                        return <Label>{`${missionName} ${profilesPerWeek}/w`}</Label>;
                      }
                      return <div></div>
                    })}
                  </Table.Cell>
                  <Table.Cell>{talentStrategist.offersAffectationWeight}</Table.Cell>
                  <Table.Cell>{talentStrategist.prioOffersAffectationWeight}</Table.Cell>
                </Table.Row>
              )))}
            </Table.Body>
          </Table>
        )
        }
        <Segment>
          <Header as='h1'>Transfer Candidates</Header>
          <SweetForm onChange={this.onChangeTransferValues}>
            <b>Source Talent Strategist</b>
            <Select
              field='sourceUserId'
              async={true}
              loadOptions={loadUsers}
              labelKey='name'
              valueKey='id'
            />
            <b>Target Talent Strategist</b>
            <Select
              field='targetUserId'
              async={true}
              loadOptions={loadUsers}
              labelKey='name'
              valueKey='id'
            />
            <b>Percentage</b>
            <SelectInt
              field='percentage'
              min={1}
              max={100}
              defaultValue={100}
            />
          </SweetForm>
          {this.state.transferingCandidates ? (
            <div>Transfering...</div>
          ) : (
            <Button onClick={() => this.onTransferCandidates()}>
              Transfer Candidates
            </Button>
          )
          }
          <Header as='h1'>Equilibrate Offers</Header>
          {this.state.equilibratingOffersAffectation ? (
            <div>Equilibrating...</div>
          ) : (
            <Button onClick={() => this.onEquilibrateOffersAffectation()}>
              Equilibrate Offers
            </Button>
          )
          }
          <Header as='h1'>Reset Top Candidates</Header>
          <Button onClick={() => this.onResetTopCandidates()}>
            Reset Top Candidates
          </Button>
        </Segment>




        {
          editMode === 'addTalentStrategist' ? (
            <CreateTalentStrategistModal
              onSubmit={this.onSubmitAddTalentStrategist}
              onChange={this.onChangeTalentStrategist}
              onClose={this.onCloseEdition}
            />
          ) : null
        }
        {
          editMode === 'editPhotoLink' ? (
            <PhotoLinkModal
              onClose={this.onCloseEdition}
              photoLink={(editedTalentStrategist || {}).photoLink}
              setPhotoLink={({ photoLink }) => this.onSubmitPhotoLink({ userId: editedTalentStrategist.userId, photoLink })}
            />
          ) : null
        }
        {
          editMode === 'editTalentStrategist' ? (
            <EditTalentStrategistModal
              onSubmit={this.onSubmitEditTalentStrategist}
              onChange={this.onChangeTalentStrategist}
              onClose={this.onCloseEdition}
              onDelete={() => this.onDeleteTalentStrategist({ userId: editedTalentStrategist.userId })}
              talentStrategist={editedTalentStrategist}
            />
          ) : null
        }
      </div >
    );
  }
}
export default TalentStrategists;
