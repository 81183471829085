import _ from 'underscore';
import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, Legend } from 'recharts';
import moment from 'moment/moment';

export default class DashBoardRawCountHistory extends Component {
  render() {
    const timeFormatter = (tick) => {
      return moment(new Date('20' + tick)).format('YY-MM-DD');
    };
    const data = _.sortBy(
      _.map(this.props.stats, (stat) => ({
        x: timeFormatter(stat.date),
        nbSelect: stat.nbSelect,
        nbBacklog: stat.nbBacklog,
        nbDisqualify: stat.nbDisqualify,
        nbBacklogMail: stat.nbBacklogMail,
        nbForgotten: stat.nbForgotten,
      })),
      'x',
    );

    return (
      <Segment>
        <Header as='h3' textAlign='center'>
          Raw Count Evolution
        </Header>
        <LineChart width={310} height={150} data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='x' label={{ value: 'Date', position: 'insideBottom', dy: 5 }} />
          <YAxis
            label={{
              value: 'NB profils',
              angle: -90,
              position: 'insideLeft',
              dy: 40,
              dx: 20,
            }}
          />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey='nbSelect' stroke='#8884d8' />
          <Line type='monotone' dataKey='nbBacklog' stroke='#82ca9d' />
          <Line type='monotone' dataKey='nbDisqualify' stroke='red' />
          <Line type='monotone' dataKey='nbBacklogMail' stroke='#ffc658' />
          <Line type='monotone' dataKey='nbForgotten' stroke='brown' />
        </LineChart>
      </Segment>
    );
  }
}
