import _ from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';

import {
  Grid,
  Form,
  Segment,
  Header,
  Button,
  Table,
  Icon,
  Message,
} from 'semantic-ui-react';

import {
  List,
  Input,
  Select,
  Checkbox,
  Clearable,
  SweetForm,
  enhance,
} from 'sweetform';

import baseUrl from './baseUrl';
import Pagination from './Pagination';

const MAX_RESULTS = 100;

const MONTHS_OPTIONS = [
  { value: 1, label: '1 month' },
  { value: 2, label: '2 months' },
  { value: 3, label: '3 months' },
  { value: 4, label: '4 months' },
  { value: 5, label: '5 months' },
  { value: 6, label: '6 months' },
  { value: 12, label: '1 year' },
  { value: 18, label: '1.5 year' },
  { value: 24, label: '2 years' },
  { value: 30, label: '2.5 years' },
  { value: 36, label: '3 years' },
];

// Actions
const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
const setFilterOptions = (options) => ({ type: SET_FILTER_OPTIONS, options });

const SET_SEARCH = 'SET_SEARCH';
const setSearch = (search) => ({ type: SET_SEARCH, search });

const SET_RESULTS = 'SET_RESULTS';
const setResults = (results) => ({ type: SET_RESULTS, results });

// Reducers
const filterOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTER_OPTIONS:
      return action.options;
    default:
      return state;
  }
};

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const results = (state = null, action) => {
  switch (action.type) {
    case SET_RESULTS:
      return action.results;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  filterOptions,
  search,
  results,
});

// Store
const store = createStore(rootReducer);

// Components

const CompactRow = ({ children }) => (
  <Grid.Row style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>
    {children}
  </Grid.Row>
);
const NumberOfMonths = (props) => (
  <Select clearable={true} {...props} options={MONTHS_OPTIONS} />
);
const MovePointSpecification = enhance(({ field }) => (
  <Segment>
    <Grid>
      <CompactRow>
        <Grid.Column width={5} verticalAlign="middle">
          <strong>Workplace ({field})</strong>
        </Grid.Column>
        <Grid.Column width={11}>
          <Input field="workplace_id" size="mini" />
        </Grid.Column>
      </CompactRow>
      <CompactRow>
        <Grid.Column width={5} verticalAlign="middle">
          <strong>Is client</strong>
        </Grid.Column>
        <Grid.Column width={11}>
          <Checkbox field="is_client" />
        </Grid.Column>
      </CompactRow>
      <CompactRow>
        <Grid.Column
          width={5}
          verticalAlign="middle"
          style={{ paddingBottom: '1.2rem' }}
        >
          <strong>Pushed before</strong>
        </Grid.Column>
        <Grid.Column width={11}>
          <Clearable
            compact
            field="profile_pushed_delay_in_months"
            component={NumberOfMonths}
          />
        </Grid.Column>
      </CompactRow>
    </Grid>
  </Segment>
));

const TimeSpecification = enhance(() => (
  <Grid columns={2}>
    <Grid.Column>
      <Input type="date" field="min_date" placeholder="From" />
    </Grid.Column>
    <Grid.Column>
      <Input type="date" field="max_date" placeholder="To" />
    </Grid.Column>
  </Grid>
));

const MoveSpecification = enhance(() => (
  <Segment secondary>
    <MovePointSpecification field="from" />
    <MovePointSpecification field="to" />
    <TimeSpecification field="time" />
  </Segment>
));

const ProfileFilters = enhance(({ filterOptions, selectedClients }) => (
  <Form>
    <Form.Field>
      <label>Pushed to clients</label>
      <Select
        field="push_station_clients"
        placeholder="Clients"
        options={filterOptions.clientsList}
        multi={true}
      />
    </Form.Field>
    <Form.Field>
      <label>Pushed to offers</label>
      <Select
        field="push_station_offers"
        placeholder="Offers"
        multi={true}
        options={
          selectedClients
            ? _.filter(filterOptions.offersList, (o) =>
                _.contains(selectedClients, o.clientId),
              )
            : filterOptions.offersList
        }
      />
    </Form.Field>
    <Form.Field>
      <label>Pushed betwen</label>
      <Grid.Column>
        <Input type="date" field="push_min_date" />
      </Grid.Column>
    </Form.Field>
    <Form.Field>
      <label>And</label>
      <Grid.Column>
        <Input type="date" field="push_max_date" />
      </Grid.Column>
    </Form.Field>
  </Form>
));

const SearchForm = ({ filterOptions, selectedClients, onSearch, ...props }) => (
  <SweetForm {...props}>
    <Header as="h2">Move Sequence</Header>
    <List component={MoveSpecification} field="moveSequence" compact />

    <Header as="h2">Profile Filters</Header>
    <ProfileFilters
      field="profileQuery"
      filterOptions={filterOptions}
      selectedClients={selectedClients}
    />

    <Button color="teal" fluid onClick={() => onSearch(search)}>
      Search
    </Button>
  </SweetForm>
);
const formatMonth = (month) => (month < 10 ? `0${month}` : `${month}`);

const formatMoveDate = ({ start_month, end_month, start_year, end_year }) => (
  <span>
    {start_year}-{formatMonth(start_month)}
    {end_year ? `, ${end_year}-${formatMonth(end_month)}` : ``}
  </span>
);
class Moves extends Component {
  state = {};
  componentWillMount() {
    axios
      .get(`${baseUrl}/moveDetection/poolLastBuildTimestamp`)
      .then(({ data }) => {
        const ts = data.timestamp || null;
        this.setState({ poolCreationTimestamp: ts });
      });
    this.loadAnnotations();
    this.loadWorkplacesWithoutPatterns();
  }
  componentDidMount() {
    this.props.onLoadFilterOptions();
  }
  loadWorkplacesWithoutPatterns = () => {
    axios
      .get(`${baseUrl}/workplaces/listHiresweetWorkplacesWithoutPatterns`)
      .then(({ data }) => {
        const warning =
          data.length > 0
            ? data.length +
              ' workplaces without patterns, examples: ' +
              _.pluck(_.shuffle(data), 'id')
                .slice(0, 20)
                .join(', ')
            : '';
        this.setState({ warning });
      });
  };
  loadAnnotations = () => {
    axios.get(`${baseUrl}/moveDetection/annotations`).then(({ data }) => {
      this.setState({ annotations: data });
    });
  };
  loadFilter = (filter) => {
    const { currentFilter } = this.state;
    if (filter !== currentFilter) {
      this.setState({ currentFilter: filter });
    } else {
      this.setState({ currentFilter: null });
    }
  };
  annotateProfile = async (profile, move, label) => {
    if (!profile.linkedin_identifier) {
      return alert('linkedin needed');
    }
    if (!move || !move.to || !move.to.workplace) {
      return alert('dest company needed');
    }
    const annotation = label ? { label } : null;
    const query = {
      linkedin: profile.linkedin_identifier,
      companyId: move.to.workplace,
      annotation,
    };
    await axios.put(`${baseUrl}/moveDetection/annotateMove`, query);
    this.loadAnnotations();
  };
  renderProfileRow(profile, move, index, annotationFromLinkedinAndCompanyId) {
    const companyId = move && move.to && move.to.workplace;
    const annotation =
      companyId &&
      (annotationFromLinkedinAndCompanyId[profile.linkedin_identifier] || {})[
        companyId
      ];
    const label = (annotation || {}).label;
    return (
      <Table.Row key={index}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>
          <a
            href={`https://linkedin.com/in/${profile.linkedin_identifier}`}
            target="_blank"
            rel='noopener noreferrer'
          >
            {profile.fullname}
          </a>
        </Table.Cell>
        <Table.Cell>
          {(move.from ? move.from.workplace : '').slice(0, 20)}
        </Table.Cell>
        <Table.Cell>{move.from ? formatMoveDate(move.from) : ''}</Table.Cell>
        <Table.Cell>
          {(move.to ? move.to.workplace : '').slice(0, 20)}
        </Table.Cell>
        <Table.Cell>{move.to ? formatMoveDate(move.to) : ''}</Table.Cell>
        <Table.Cell>
          <div>
            <Icon
              link
              color={label === 'hiresweet' ? 'green' : 'black'}
              name="thumbs up"
              onClick={() => this.annotateProfile(profile, move, 'hiresweet')}
            />
            <Icon
              link
              color={label === 'not-hiresweet' ? 'green' : 'black'}
              name="thumbs down"
              onClick={() =>
                this.annotateProfile(profile, move, 'not-hiresweet')
              }
            />
            <Icon
              link
              color={label === 'suspicious' ? 'green' : 'black'}
              name="question circle"
              onClick={() => this.annotateProfile(profile, move, 'suspicious')}
            />
            <Icon
              link
              color={label === 'seen' ? 'green' : 'black'}
              name="eye"
              onClick={() => this.annotateProfile(profile, move, 'seen')}
            />
            <Icon
              link
              color="black"
              name="eraser"
              onClick={() => this.annotateProfile(profile, move, null)}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
  render() {
    const {
      filterOptions,
      search,
      results,
      onEditSearch,
      onSubmitSearch,
      onDownload,
    } = this.props;

    const { poolCreationTimestamp, annotations, currentFilter } = this.state;
    const dateStr =
      poolCreationTimestamp === null
        ? 'unknown'
        : poolCreationTimestamp
        ? moment(new Date(poolCreationTimestamp)).format('YY-MM-DD')
        : '';
    const nbDays = poolCreationTimestamp
      ? Math.floor((Date.now() - poolCreationTimestamp) / (24 * 3600 * 1000))
      : 1000;
    const dateColor =
      nbDays <= 1
        ? 'green'
        : nbDays <= 2
        ? 'olive'
        : nbDays <= 3
        ? 'orange'
        : 'red';

    let annotationFromLinkedinAndCompanyId = {};
    _.each(annotations, ({ linkedin, companyId, annotation }) => {
      if (!annotationFromLinkedinAndCompanyId[linkedin]) {
        annotationFromLinkedinAndCompanyId[linkedin] = {};
      }
      annotationFromLinkedinAndCompanyId[linkedin][companyId] = annotation;
    });

    const filteredMoves = _.filter(
      results && results.moves,
      ({ profile, move }) => {
        const companyId = move && move.to && move.to.workplace;
        const annotation =
          companyId &&
          (annotationFromLinkedinAndCompanyId[profile.linkedin_identifier] ||
            {})[companyId];
        const label = (annotation || {}).label;
        return (
          !currentFilter ||
          (currentFilter === 'no-annotations' && !label) ||
          label === currentFilter
        );
      },
    );

    return (
      <Grid>
        <Grid.Column computer={5} widescreen={4}>
          {dateStr && (
            <h1 style={{ color: dateColor }}>Last build: {dateStr}</h1>
          )}
          <SearchForm
            filterOptions={filterOptions}
            onChange={onEditSearch}
            initialValues={{ moveSequence: [{}] }}
            onSearch={() => onSubmitSearch(search)}
            selectedClients={
              search && search.profileQuery
                ? search.profileQuery.push_station_clients
                : []
            }
          />
          <Button color="orange" fluid onClick={() => onDownload(search)}>
            Download as CSV
          </Button>
        </Grid.Column>
        <Grid.Column
          computer={11}
          widescreen={12}
          style={{ overflow: 'auto', height: '90vh' }}
        >
          {this.state.warning && (
            <Message error>
              <Message.Header>Missing patterns</Message.Header>
              {this.state.warning}
            </Message>
          )}
          {results && (
            <div>
              <b>Filters: </b>
              <Icon
                link
                color={currentFilter === 'no-annotations' ? 'green' : 'black'}
                name="sticky note outline"
                onClick={() => this.loadFilter('no-annotations')}
              />
              <Icon
                link
                color={currentFilter === 'hiresweet' ? 'green' : 'black'}
                name="thumbs up"
                onClick={() => this.loadFilter('hiresweet')}
              />
              <Icon
                link
                color={currentFilter === 'not-hiresweet' ? 'green' : 'black'}
                name="thumbs down"
                onClick={() => this.loadFilter('not-hiresweet')}
              />
              <Icon
                link
                color={currentFilter === 'suspicious' ? 'green' : 'black'}
                name="question circle"
                onClick={() => this.loadFilter('suspicious')}
              />
              <Icon
                link
                color={currentFilter === 'seen' ? 'green' : 'black'}
                name="eye"
                onClick={() => this.loadFilter('seen')}
              />
            </div>
          )}
          {results ? (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ith</Table.HeaderCell>
                  <Table.HeaderCell>Profile</Table.HeaderCell>
                  <Table.HeaderCell>From Company</Table.HeaderCell>
                  <Table.HeaderCell>From Dates</Table.HeaderCell>
                  <Table.HeaderCell>To Company</Table.HeaderCell>
                  <Table.HeaderCell>To Dates</Table.HeaderCell>
                  <Table.HeaderCell>Annotation</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(filteredMoves, ({ profile, move }, index) =>
                  this.renderProfileRow(
                    profile,
                    move,
                    index,
                    annotationFromLinkedinAndCompanyId,
                  ),
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={5}>
                    <Pagination
                      current={results.page}
                      total={Math.ceil(results.totalCount / MAX_RESULTS)}
                      onPage={(p) => onSubmitSearch(search, p)}
                      floated="right"
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          ) : null}
        </Grid.Column>
      </Grid>
    );
  }
}

// Containers

const mapSMoves = (state) => ({
  filterOptions: state.filterOptions,
  search: state.search,
  results: state.results,
});

const ppdm = 'profile_pushed_delay_in_months';
const ppb = 'profile_pushed_before';
const psc = 'push_station_clients';
const pso = 'push_station_offers';

const convertDate = (text, name) => {
  if (!text) {
    return {};
  }

  const date = moment(text, 'YYYY-MM-DD');
  return {
    [`${name}_month`]: date.month() + 1,
    [`${name}_year`]: date.year(),
  };
};

const flattenMoveDetectionResults = (results) => {
  if (!results || results.length === 0){
    return []
  }
  const flattenedResults = []
  _.each(results, ({moves, profile}) => {
    const oneResult = {...profile}
    _.each(moves, (move, index)=>{
      _.each(['from', 'to'], (target) => {
        const prefix = 'move_' + index.toString() + '_' + target;
        _.each(move[target], (value, key) => {
          oneResult[prefix + '_' + key] = value;
        })
      })
    })
    flattenedResults.push(oneResult)
  })
  return flattenedResults
}

const mapDMoves = (dispatch) => ({
  onEditSearch: (search) => dispatch(setSearch(search)),
  onDownload: async (rawSearch) => {
    const search = {
      moveSequence: _.map(rawSearch.moveSequence, (m) => ({
        from:
          m && m.from
            ? {
                ...m.from,
                [ppb]: m.from[ppdm] !== undefined,
              }
            : {},
        to:
          m && m.to
            ? {
                ...m.to,
                [ppb]: m.to[ppdm] !== undefined,
              }
            : {},
        time:
          m && m.time
            ? {
                ...convertDate(m.time.min_date, 'min'),
                ...convertDate(m.time.max_date, 'max'),
              }
            : {},
      })),
      profileQuery: rawSearch.profileQuery
        ? {
            ...convertDate(rawSearch.profileQuery.push_min_date, 'push_min'),
            ...convertDate(rawSearch.profileQuery.push_max_date, 'push_max'),
            [psc]: (rawSearch.profileQuery[psc] || '').split(';'),
            [pso]: (rawSearch.profileQuery[pso] || '').split(';'),
          }
        : {},
    };
    const resultJson = (await axios.post(`${baseUrl}/moveDetection`, search)).data;

    console.log(resultJson)

    // build csv
    const items = resultJson.results;
    console.log('items', items)
    const flattenedItems = flattenMoveDetectionResults(items);
    console.log('flattenedItems', flattenedItems)
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(flattenedItems[0]);
    let csv = flattenedItems.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    );
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');

    // Download csv
    var csvData = new Blob([csv], { type: 'text/csv' });
    var csvUrl = URL.createObjectURL(csvData);
    var now = moment().format('YYMMDDHHmmss');
    var hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.download = 'moves_extracted_' + now + '.csv';
    hiddenElement.click();
  },
  onSubmitSearch: async (rawSearch, page = 0) => {
    const search = {
      moveSequence: _.map(rawSearch.moveSequence, (m) => ({
        from:
          m && m.from
            ? {
                ...m.from,
                [ppb]: m.from[ppdm] !== undefined,
              }
            : {},
        to:
          m && m.to
            ? {
                ...m.to,
                [ppb]: m.to[ppdm] !== undefined,
              }
            : {},
        time:
          m && m.time
            ? {
                ...convertDate(m.time.min_date, 'min'),
                ...convertDate(m.time.max_date, 'max'),
              }
            : {},
      })),
      profileQuery: rawSearch.profileQuery
        ? {
            ...convertDate(rawSearch.profileQuery.push_min_date, 'push_min'),
            ...convertDate(rawSearch.profileQuery.push_max_date, 'push_max'),
            [psc]: (rawSearch.profileQuery[psc] || '').split(';'),
            [pso]: (rawSearch.profileQuery[pso] || '').split(';'),
          }
        : {},
      offset: page * MAX_RESULTS,
      maxNbResults: MAX_RESULTS,
    };

    const results = (await axios.post(`${baseUrl}/moveDetection`, search)).data;
    dispatch(
      setResults({
        moves: _.flatten(
          _.map(results.results, ({ profile, moves }) =>
            _.map(moves, (move) => ({ profile, move })),
          ),
        ),
        totalCount: results.totalCount,
        page,
      }),
    );
  },
  onLoadFilterOptions: async () => {
    const clients = (await axios.get(`${baseUrl}/station/clients`)).data;
    const clientsList = _.map(clients, ({ id, name }) => ({
      value: id,
      label: `${id} (${name})`,
    }));
    const offers = (await axios.get(`${baseUrl}/station/clients/all/offers`))
      .data;
    const offersList = _.map(offers, ({ id, title, clientId }) => ({
      value: id,
      label: `${id} (${title})`,
      clientId,
    }));
    dispatch(setFilterOptions({ clientsList, offersList }));
  },
});

const MovesContainer = connect(
  mapSMoves,
  mapDMoves,
)(Moves);

export default () => (
  <Provider store={store}>
    <MovesContainer />
  </Provider>
);
