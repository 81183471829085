import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../baseUrl.js';
import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import EducationFunctionInput from './EducationFunctionInput';
import EntityFunctionInput from './EntityFunctionInput';
import ExperienceFunctionInput from './ExperienceFunctionInput';
import JobFunctionInput from './JobFunctionInput';
import OfferFunctionInput from './OfferFunctionInput';
import ProfileFunctionInput from './ProfileFunctionInput';
import ProfilesFunctionInput from './ProfilesFunctionInput';
import TextFunctionInput from './TextFunctionInput';

class FunctionParametersSelector extends Component {
  constructor(props) {
    super(props);
  }

  getEnrichedProfile = async (profileJSON) => {
    const url = baseUrl + '/techAsset/getEnrichedProfile';
    const payload = { profile: profileJSON };
    const enrichedEntity = (await axios.post(url, payload)).data.enrichedEntity;
    return enrichedEntity;
  };

  handleChangeShowInput = (arg, key) => {
    this.setState({ selectedArg: arg, selectedKey: key });
  };

  typeToEditableComponent = (
    argname,
    type,
    key,
    enrichedEntity,
    rawEntity,
    propertyTest,
    samplingBase,
    missionId,
    missionPath,
    profileCollectionId,
  ) => {
    if (type === 'text') {
      return (
        <TextFunctionInput
          key={key}
          onSubmit={(e) => this.props.onSubmit(argname, e)}
          enrichedEntity={enrichedEntity}
          value={rawEntity}
          handleTextChange={(value) => this.props.handleTextChange(argname, value)}
          propertyTest={propertyTest}
          samplingBase={samplingBase}
        />
      );
    }
    if (type === 'entity_item') {
      return <EntityFunctionInput key={key} onSubmit={(e) => this.props.onSubmit(argname, e)} value={rawEntity} />;
    }
    if (type == 'job_item') {
      return <JobFunctionInput key={key} onSubmit={(e) => this.props.onSubmit(argname, e)} value={rawEntity} />;
    }
    if (type === 'experience') {
      return (
        <ExperienceFunctionInput
          key={key}
          onSubmit={(e) => this.props.onSubmit(argname, e)}
          enrichedEntity={enrichedEntity}
          rawEntity={rawEntity}
          propertyTest={propertyTest}
          samplingBase={samplingBase}
        />
      );
    }
    if (type === 'education') {
      return (
        <EducationFunctionInput
          key={key}
          onSubmit={(e) => this.props.onSubmit(argname, e)}
          enrichedEntity={enrichedEntity}
          rawEntity={rawEntity}
          propertyTest={propertyTest}
          samplingBase={samplingBase}
        />
      );
    }
    if (type === 'profile') {
      return (
        <ProfileFunctionInput
          key={key}
          onSubmit={(e) => this.props.onSubmit(argname, e)}
          enrichedEntity={enrichedEntity}
          rawEntity={rawEntity}
          propertyTest={propertyTest}
          samplingBase={samplingBase}
          getEnrichedProfile={this.getEnrichedProfile}
        />
      );
    }
    if (type === 'offer') {
      return (
        <OfferFunctionInput
          key={key}
          onSubmit={(e) => this.props.onSubmit(argname, e)}
          enrichedEntity={enrichedEntity}
          rawEntity={rawEntity}
          propertyTest={propertyTest}
          samplingBase={samplingBase}
          missionId={missionId}
          missionPath={missionPath}
        />
      );
    }
    if (type === 'profiles') {
      return (
        <ProfilesFunctionInput
          key={key}
          argname={argname}
          onSubmit={(e) => this.props.onSubmit(argname, e)}
          enrichedEntity={enrichedEntity}
          rawEntity={rawEntity}
          propertyTest={propertyTest}
          samplingBase={samplingBase}
          getEnrichedProfile={this.getEnrichedProfile}
          missionId={missionId}
          profileCollectionId={profileCollectionId}
        />
      );
    }
  };

  render() {
    const {
      args,
      enrichedEntities,
      inputData,
      missionId,
      missionPath,
      profileCollectionId,
      propertyTest,
      samplingBase,
    } = this.props;
    const { selectedArg, selectedKey } = this.state || {};

    return args.length > 1 ? (
      <div>
        <Menu pointing secondary>
          {_.map(args, (arg, key) => (
            <Menu.Item
              key={key}
              name={arg.argname}
              active={arg === selectedArg}
              onClick={() => {
                this.handleChangeShowInput(arg, key);
              }}
            />
          ))}
        </Menu>
        {selectedArg &&
          this.typeToEditableComponent(
            selectedArg.argname,
            selectedArg.type,
            selectedKey,
            (_.find(enrichedEntities, (elt) => elt.name === selectedArg.argname) || {}).entity,
            (_.find(inputData, (elt) => elt.argname === selectedArg.argname) || {}).value,
            propertyTest,
            samplingBase,
            missionId,
            missionPath,
            profileCollectionId,
          )}
      </div>
    ) : (
      <div>
        {_.map(args, ({ argname, type }, key) =>
          this.typeToEditableComponent(
            argname,
            type,
            key,
            (_.find(enrichedEntities, (elt) => elt.name === argname) || {}).entity,
            (_.find(inputData, (elt) => elt.argname === argname) || {}).value,
            propertyTest,
            samplingBase,
            missionId,
            missionPath,
            profileCollectionId,
          ),
        )}
      </div>
    );
  }
}

export default FunctionParametersSelector;
