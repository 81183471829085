import _ from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import axios from 'axios';
import { Container, Loader, Popup, Image, Icon, Card, List, Tab, Button, Table, Grid } from 'semantic-ui-react';
import SearchDistribution from './SearchDistribution';
import DisplaySearchResult from './SearchResultView';
import Metasearch from './Metasearch.js';
import baseUrl from './baseUrl.js';

class SearchOverview extends Component {
  state = {};
  handleChangeAdminState = (adminState) => {
    const { search } = this.props;
    axios
      .put(baseUrl + '/sweetsearch/searches/id/' + search.id + '/adminState', {
        adminState,
      })
      .then(() => {
        this.props.handleUpdateSearch();
      });
  };
  handleRefresh = (port) => {
    const { search } = this.props;
    const id = search.id || search.searchId;
    const url = baseUrl + '/sweetsearch/searches/id/' + id + '/refresh' + (port ? '?port=' + port : '');
    axios.get(url).then(async ({ data }) => {
      if (data.error) {
        alert(data.error);
      } else {
        alert('refresh asked');
      }
    });
  };
  handleOpenSeachModal = () => {
    this.setState({ open: true });
  };
  render() {
    const { search, postponedProfiles } = this.props || {};
    const searchId = search.id || search.searchId;

    return (
      <Container>
        <Grid fluid>
          <Grid.Row style={{ marginBottom: '5px' }}>
            <Grid.Column width={4}>
              <strong>id</strong>
            </Grid.Column>
            <Grid.Column width={12}>{searchId}</Grid.Column>
          </Grid.Row>
          {search.searchParams.offer ? (
            <Grid.Row style={{ marginBottom: '5px' }}>
              <Grid.Column width={4}>
                <strong>offerId</strong>
              </Grid.Column>
              <Grid.Column width={12}>
                <a href={`/offers/${search.searchParams.offer.offerId}`}>{search.searchParams.offer.offerId}</a>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          <Grid.Row style={{ marginBottom: '5px' }}>
            <Grid.Column width={4}>
              <strong>pipeDescriptor</strong>
            </Grid.Column>
            <Grid.Column width={12}>
              {search.pipeDescriptor && search.pipeDescriptor.type === 'raw-pipe' ? (
                <Grid padded>
                  <Grid.Row style={{ padding: 3 }}>
                    <Grid.Column width={4}>
                      <strong>type</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>{search.pipeDescriptor.type}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ padding: 3 }}>
                    <Grid.Column width={4}>
                      <strong>db</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>{search.pipeDescriptor.db}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ padding: 3 }}>
                    <Grid.Column width={4}>
                      <strong>collection</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>{search.pipeDescriptor.collection}</Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <span>{JSON.stringify(search.pipeDescriptor)}</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: '5px' }}>
            <Grid.Column width={4}>
              <strong>status</strong>
            </Grid.Column>
            <Grid.Column width={12}>
              <span>{search.status && search.status.type}</span>
              <br />
              <span>{search.status && search.status.reason}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: '5px' }}>
            <Grid.Column width={4}>
              <strong>adminState</strong>
            </Grid.Column>
            <Grid.Column width={12}>{search.adminState}</Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: '5px' }}>
            <Grid.Column width={4}>
              <strong>lastAction</strong>
            </Grid.Column>
            <Grid.Column width={12}>
              {search.lastActionTimestamp && new Date(search.lastActionTimestamp).toLocaleString()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: '5px' }}>
            <Grid.Column width={4}>
              <strong>Postponed profiles</strong>
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid padded>
                {_.map(_.sortBy(postponedProfiles, 'queryNbProfiles'), (group) => (
                  <Grid.Row>
                    <Grid.Column width={2}>
                      <strong>{group.queryNbProfiles}</strong>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <Grid>
                        <Grid.Column width={10}>
                          <Grid.Row>nbPostponedProfiles</Grid.Row>
                          <Grid.Row>nbProfilesInSearch</Grid.Row>
                          <Grid.Row>postponedProfilesRatio</Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <Grid.Row>{group.nbPostponedProfiles}</Grid.Row>
                          <Grid.Row>{group.nbProfilesInSearch}</Grid.Row>
                          <Grid.Row>{Math.round(group.postponedProfilesRatio * 100, 2)}%</Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <center>
          <br />
          <Button onClick={() => this.handleRefresh()}>Refresh 8010</Button>
          <Button color='grey' onClick={() => this.handleRefresh(8011)}>
            8011
          </Button>
          <Button color='grey' onClick={() => this.handleRefresh(8012)}>
            8012
          </Button>
          <Button color='grey' onClick={() => this.handleRefresh(8013)}>
            8013
          </Button>
          <Button color='grey' onClick={() => this.handleRefresh(8014)}>
            8014
          </Button>
          {search.adminState === 'backlog' ? (
            <Button
              onClick={() => {
                this.handleChangeAdminState('active');
              }}
            >
              Unbacklog
            </Button>
          ) : (
            <Button
              onClick={() => {
                this.handleChangeAdminState('backlog');
              }}
            >
              Backlog
            </Button>
          )}
        </center>
        {search.scorerStatistics && (
          <DisplaySearchResult searchResult={{ searchParamsWithScore: search.searchParams }} />
        )}
      </Container>
    );
  }
}

class SearchOutlier extends Component {
  getTitles = () => {
    const { outlier } = this.props;
    const date = new Date(outlier.timestamp);
    const offerTitle = outlier.offer && outlier.offer.title ? outlier.offer.title : '';
    const title =
      '[' +
      (outlier.label === 'positive' ? 'To Accept' : 'To Reject') +
      '] ' +
      '[' +
      offerTitle +
      '] ' +
      outlier.reason;
    const subTitle =
      (outlier.username ? outlier.username + ' ' : '') +
      ' le ' +
      date.toLocaleDateString() +
      ' à ' +
      date.toLocaleTimeString();
    return { offerTitle, title, subTitle };
  };
  renderContacts() {
    const { idFields } = this.props.outlier;
    return (
      <span>
        {_.map(idFields, (value, key) => {
          const link =
            key === 'linkedin'
              ? 'https://linkedin.com/in/' + value
              : key === 'github'
              ? 'https://github.com/' + value
              : key === 'stackOverflow'
              ? 'https://stackoverflow.com/users/' + (value.indexOf('/') >= 0 ? value : value + '/osef')
              : key === 'twitter'
              ? 'https://twitter.com/' + value
              : '';
          const style = { marginRight: '10px' };
          return link ? (
            <a key={key} href={link} target='_blank' style={style} rel='noopener noreferrer'>
              {key}
            </a>
          ) : (
            ''
          );
        })}
      </span>
    );
  }
  renderDescription() {
    const { outlier } = this.props;
    const offer = outlier.offer || {};
    const sweetappStr = offer.platformId ? '(sweetapp: ' + offer.platformId + ')' : '';
    const searchId =
      outlier.pipeDescriptor && outlier.pipeDescriptor.searchId ? outlier.pipeDescriptor.searchId : 'not found';
    const { offerTitle } = this.getTitles();
    return (
      <List bulleted>
        <List.Item>
          <strong>Sources</strong>: {this.renderContacts(outlier)}
        </List.Item>
        <List.Item>
          <strong>Offer</strong>: <a href={'/offers/' + offer.id}>{offerTitle}</a> {sweetappStr}
        </List.Item>
        <List.Item>
          <strong>Search Identifier</strong>: <code>{searchId}</code>
        </List.Item>
        <List.Item>
          <strong>Outlier Identifier</strong>: <code>{outlier.id}</code>
        </List.Item>
      </List>
    );
  }

  render() {
    const { outlier } = this.props;
    const { title, subTitle } = this.getTitles();
    const image = outlier.status === 'resolved' ? '/images/done.png' : '/images/wrench.png';
    return (
      <div>
        <Card fluid>
          <Image floated='right' size='mini' src={image} />
          <Card.Header>
            {title} <Icon name={outlier.watched ? 'eye' : 'low vision'} />
          </Card.Header>
          <Card.Meta>{subTitle}</Card.Meta>
          <Card.Description>{this.renderDescription()}</Card.Description>
        </Card>
      </div>
    );
  }
}

class SearchOutliers extends Component {
  componentWillMount() {
    this.setState({
      loading: true,
      outliers: [],
    });
    axios
      .get(baseUrl + '/sweetwork/outliers')
      .then(({ data }) => {
        if (!data) {
          throw Error('no data for /outliers');
        }
        if (data.error) {
          throw Error(data.error);
        }
        if (!data.outliers) {
          throw Error('need a field outliers');
        }
        const outliers = _.filter(data.outliers, (outlier) => {
          if (!outlier.pipeDescriptor) {
            return false;
          }
          return outlier.pipeDescriptor.searchId === this.props.searchId;
        });
        this.setState({
          outliers,
          loading: false,
        });
      })
      .catch((e) => {
        alert(e.message);
      });
  }
  render() {
    const { loading, outliers } = this.state;
    if (loading) {
      return <Loader active inline='centered' />;
    }
    if (_.isEmpty(outliers)) {
      return (
        <div>
          <center>No Outliers</center>
        </div>
      );
    }
    return (
      <div>
        <h1>Outliers: {outliers.length}</h1>
        {_.map(outliers, (outlier, index) => (
          <SearchOutlier key={index} outlier={outlier} />
        ))}
      </div>
    );
  }
}

class SearchHistory extends Component {
  componentWillMount() {
    const search = this.props.search || {};
    const events = search.history || [];
    const baseLimit = 50;
    const nbVerbose0 = _.where(events, { verbose: 0 }).length + _.where(events, { verbose: undefined }).length;
    const nbVerbose1 = _.where(events, { verbose: 1 }).length;
    const nbVerbose2 = _.where(events, { verbose: 2 }).length;

    const verbose =
      nbVerbose0 + nbVerbose1 + nbVerbose2 <= baseLimit ? 2 : nbVerbose0 + nbVerbose1 <= baseLimit ? 1 : 0;

    this.setState({
      verbose,
    });
  }
  handleChangeVerbose = (verbose) => {
    this.setState({ verbose });
  };
  renderVerboseSelector() {
    const selectedVerbose = this.state.verbose;
    return (
      <div>
        {_.map([0, 1, 2], (verbose, index) => (
          <Button
            key={index}
            color={verbose === selectedVerbose ? 'green' : 'silver'}
            onClick={() => this.handleChangeVerbose(verbose)}
          >
            Verbose {verbose}
          </Button>
        ))}
      </div>
    );
  }
  render() {
    const search = this.props.search || {};
    const events = search.events || [];
    const { verbose } = this.state;

    if (_.isEmpty(events)) {
      return <h1>No History</h1>;
    }

    const targetEvents = _.filter(
      _.sortBy(events, ({ timestamp }) => -timestamp),
      (event) => (event.verbose || 0) <= verbose,
    );

    return (
      <div>
        {this.renderVerboseSelector()}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Date</Table.HeaderCell>
              <Table.HeaderCell width={3}>Type</Table.HeaderCell>
              <Table.HeaderCell width={2}>Verbose</Table.HeaderCell>
              <Table.HeaderCell width={10}>Message</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(targetEvents, (event, index) => {
              const dateStr = event.timestamp ? moment(event.timestamp).format('YY-MM-DD HH:mm:ss') : '';
              const style = {
                cursor: 'pointer',
                backgroundColor:
                  event.type.indexOf('error') >= 0
                    ? '#ffe6e6'
                    : event.type.indexOf('success') >= 0
                    ? '#e6ffe6'
                    : '#ffebcc',
              };
              return (
                <Table.Row key={index} style={style}>
                  <Table.Cell>{dateStr}</Table.Cell>
                  <Table.Cell>{event.type}</Table.Cell>
                  <Table.Cell>{event.verbose}</Table.Cell>
                  <Table.Cell>{event.message}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const sleep = async (milis = 1000) => new Promise((resolve) => setTimeout(resolve, milis));

class SearchesMonitoring extends Component {
  state = { activeIndex: 0 };
  componentWillMount() {
    if (this.props.defaultSelectedSearchId) {
      this.loadSearch(this.props.defaultSelectedSearchId);
    }
  }

  handleEnableMetasearch = () => {
    console.log('metasearchEnabled');
    this.setState({
      metasearchEnabled: true,
      noRefresh: true,
    });
  };
  handleDisableMetasearch = () => {
    this.setState({ metasearchEnabled: false });
  };

  loadSearches() {
    this.setState(
      {
        searches: [],
      },
      async () => {
        let maxNbRequests = 40;
        let nbRequests = 0;
        while (nbRequests < maxNbRequests) {
          nbRequests++;
          if (this.state.noRefresh) {
            break;
          }
          await axios
            .get(baseUrl + '/sweetsearch/searches')
            .then(({ data }) => {
              if (!data) {
                throw Error('no data for /searches');
              }
              if (data.error) {
                throw Error(data.error);
              }
              if (!data.searches) {
                throw Error('need a field searches');
              }
              this.setState({
                searches: data.searches,
              });
            })
            .catch((e) => {
              alert(e.message);
            });
          await sleep(30000);
        }
      },
    );
  }
  loadMetasearchResults(metasearchParams) {
    this.setState(
      {
        searches: [],
      },
      async () => {
        const data = (await axios.post(baseUrl + '/sweetsearch/metasearch', {
          metasearch: metasearchParams,
        })).data;
        if (data.error) {
          return alert(data.error);
        }
        if (!data.searches) {
          return alert('no results returned');
        }
        this.setState({
          searches: data.searches,
        });
      },
    );
  }
  handleTabChange = (e, data) => {
    this.setState({ activeIndex: data.activeIndex });
  };
  loadSearch(id) {
    this.setState(
      {
        selectedSearch: null,
      },
      () => {
        axios
          .get(baseUrl + '/sweetsearch/searches/id/' + id)
          .then(async ({ data }) => {
            if (!data) {
              throw Error('no data for /searches/id/:id');
            }
            if (!data.search) {
              throw Error('need a field search');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.setState({
              selectedSearch: data.search,
            });
          })
          .catch((e) => {
            alert(e.message);
          });
      },
    );
    this.loadPostponedProfiles(id);
  }
  loadPostponedProfiles(searchId) {
    this.setState({ postponedProfiles: null }, () =>
      _.map([100, 200, 500], (nbProfiles) =>
        axios
          .get(baseUrl + '/sweetsearch/searches/id/' + searchId + '/postPonedProfiles/nbProfiles/' + nbProfiles, {
            timeout: 2000,
          })
          .then(async ({ data }) => {
            if (!data) {
              throw Error('no data for /searches/id/:id/postPonedProfiles/nbProfiles/:nbProfiles');
            }
            if (data.error) {
              throw Error(data.error);
            }
            this.setState({
              postponedProfiles: [
                ...(this.state.postponedProfiles || []),
                {
                  ...data,
                  queryNbProfiles: nbProfiles,
                },
              ],
            });
          })
          .catch((e) => {
            console.log(e.message);
          }),
      ),
    );
  }
  componentDidMount() {
    this.loadSearches();
  }
  handleUpdateSearch = () => {
    const search = this.state.selectedSearch;
    if (search) {
      this.loadSearch(search.id);
    }
  };
  getFilter = () => {
    const { selectedAdminState, selectedStatus, selectedUser } = this.state;
    const filters = [
      (search) => !selectedAdminState || search.adminState === selectedAdminState,
      (search) => !selectedStatus || (search.status && search.status.type === selectedStatus),
      (search) => !selectedUser || _.contains(search.offer && search.offer.users, selectedUser),
    ];
    return (search) => _.every(_.map(filters, (filter) => filter(search)));
  };
  renderFilters() {
    const searches = this.state.searches || [];

    const users = _.uniq(_.compact(_.flatten(_.map(searches, (search) => search.offer && search.offer.users))));

    const statuses = _.uniq(_.compact(_.map(searches, (search) => search.status && search.status.type)));

    return (
      <div>
        <div style={{ marginBottom: '5px' }}>
          <Button
            onClick={() => {
              this.setState({ selectedAdminState: null });
            }}
            color={!this.state.selectedAdminState ? 'green' : 'silver'}
          >
            all
          </Button>
          <Button
            onClick={() => {
              this.setState({ selectedAdminState: 'active' });
            }}
            color={this.state.selectedAdminState === 'active' ? 'green' : 'silver'}
          >
            active
          </Button>
          <Button
            onClick={() => {
              this.setState({ selectedAdminState: 'backlog' });
            }}
            color={this.state.selectedAdminState === 'backlog' ? 'green' : 'silver'}
          >
            backlog
          </Button>
        </div>
        <div style={{ marginBottom: '5px' }}>
          <Button
            onClick={() => {
              this.setState({ selectedStatus: null });
            }}
            color={this.state.selectedStatus ? 'silver' : 'green'}
          >
            all
          </Button>
          {_.map(statuses, (status, index) => (
            <Button
              key={index}
              onClick={() => this.setState({ selectedStatus: status })}
              color={this.state.selectedStatus === status ? 'green' : 'silver'}
            >
              {status}
            </Button>
          ))}
        </div>
        <div style={{ marginBottom: '5px' }}>
          <Button
            onClick={() => {
              this.setState({ selectedUser: null });
            }}
            color={this.state.selectedUser ? 'silver' : 'green'}
          >
            all
          </Button>
          {_.map(users, (user, index) =>
            _.isString(user) && user !== 'ops' ? (
              <Button
                key={index}
                onClick={() => this.setState({ selectedUser: user })}
                color={this.state.selectedUser === user ? 'green' : 'silver'}
              >
                {user}
              </Button>
            ) : null,
          )}
        </div>
      </div>
    );
  }
  renderMetasearch() {
    if (!this.state.metasearchEnabled) {
      return (
        <center>
          {/* eslint-disable-next-line */}
          <a style={{ cursor: 'pointer' }} onClick={this.handleEnableMetasearch}>
            Open Metasearch
          </a>
        </center>
      );
    }
    const onSubmit = (metasearchParams) => {
      this.loadMetasearchResults(metasearchParams);
      this.handleDisableMetasearch();
    };
    return (
      <div>
        <Metasearch onCancel={this.handleDisableMetasearch} onSubmit={onSubmit} />
      </div>
    );
  }
  renderSearches() {
    const searches = this.state.searches;
    const filter = this.getFilter();
    const filteredSearches = _.filter(searches, filter);
    return (
      <div>
        {this.renderFilters()}
        {this.renderMetasearch()}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Last Action Date</Table.HeaderCell>
              <Table.HeaderCell>Users</Table.HeaderCell>
              <Table.HeaderCell>SearchId</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(filteredSearches, (search, index) => {
              const statusType = search.status ? search.status.type : null;
              const style = {
                cursor: 'pointer',
                backgroundColor:
                  search.searchId === ((this.state || {}).selectedSearch || {}).id
                    ? '#cccccc'
                    : statusType === 'error'
                    ? '#ffe6e6'
                    : statusType === 'success'
                    ? '#e6ffe6'
                    : '#ffebcc',
              };
              const timestamp = search.lastActionTimestamp;
              const dateStr = timestamp ? moment(timestamp).format('YY-MM-DD HH:mm') : 'unknown';

              const title1 =
                '[' +
                (search.offer && search.offer.companyId ? search.offer.companyId : 'Unknown Company') +
                '] ' +
                '[' +
                (search.offer && search.offer.title ? search.offer.title : '#' + search.offerId) +
                '] ';
              const title2 =
                '[' + (search.workPipe && search.workPipe.title ? search.workPipe.title : 'Unknown Workpipe') + '] ';
              const title3 = search.input && search.input.title ? search.input.title : 'Unknown Input';
              const shortTitle3 = title3.substring(0, 50);

              const users = search.offer && search.offer.users ? search.offer.users : [];

              return (
                <Table.Row key={index} style={style} onClick={() => this.loadSearch(search.searchId)}>
                  <Table.Cell>{dateStr}</Table.Cell>
                  <Table.Cell>
                    {_.map(users, (user, index) =>
                      _.isString(user) && user !== 'ops' ? <div key={index}>{user}</div> : null,
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      trigger={
                        <div>
                          <strong>{title1}</strong>
                          <br />
                          <span>{title2}</span>
                          <br />
                          <span>{shortTitle3}</span>
                        </div>
                      }
                      content={search.searchId}
                    />
                  </Table.Cell>
                  <Table.Cell>{statusType}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
  renderSelectedSearch() {
    const search = this.state.selectedSearch;
    const postponedProfiles = this.state.postponedProfiles;
    if (!search) {
      return null;
    }

    const panes = [
      {
        menuItem: 'Overview',
        render: () => (
          <Tab.Pane>
            <SearchOverview
              search={search}
              postponedProfiles={postponedProfiles}
              handleUpdateSearch={this.handleUpdateSearch}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'History',
        render: () => (
          <Tab.Pane>
            <SearchHistory search={search} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Scoring',
        render: () => (
          <Tab.Pane>
            <SearchDistribution scorerStatistics={search.scorerStatistics} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Outliers',
        render: () => (
          <Tab.Pane>
            <SearchOutliers searchId={search.id} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Raw',
        render: () => (
          <Tab.Pane>
            <pre>{JSON.stringify(this.state.selectedSearch, null, 4)}</pre>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Container>
        <div style={{ position: 'absolute', top: 0, right: 10 }}>
          <Icon
            name='undo'
            color='blue'
            size='large'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.loadSearch(search.id);
            }}
          />
        </div>
        <Tab
          key={search.id}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
        />
      </Container>
    );
  }
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>{this.renderSearches()}</Grid.Column>
          <Grid.Column width={8}>{this.renderSelectedSearch()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default SearchesMonitoring;
