import React, { Component } from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import { Icon, Tab, Table } from 'semantic-ui-react';

const scoreToColor = (score) => {
  if (score > 0.9) {
    return '#3EF005';
  } else if (score > 0.8) {
    return '#4ED804';
  } else if (score > 0.7) {
    return '#5FC104';
  } else if (score > 0.6) {
    return '#70AA03';
  } else if (score > 0.5) {
    return '#809303';
  } else if (score > 0.4) {
    return '#917C02';
  } else if (score > 0.3) {
    return '#B24D01';
  } else if (score > 0.2) {
    return '#C33601';
  } else if (score > 0.1) {
    return '#D41F00';
  } else {
    return '#E50800';
  }
};

class DiffusionTable extends Component {
  renderCell = (name, key, tagScore) => {
    const implicationScore = Math.round(tagScore.implicationScore * 100) / 100;
    const sourceScore = Math.round(tagScore.sourceScore * 100) / 100;
    return (
      <Table.Cell key={key} textAlign='center'>
        {name == 'Name' ? (
          <span> {tagScore.name} </span>
        ) : name == 'Implication' ? (
          <span style={{ color: scoreToColor(implicationScore / 100) }}> {implicationScore} </span>
        ) : name == 'Source' ? (
          <span style={{ color: scoreToColor(sourceScore / 100) }}> {sourceScore} </span>
        ) : null}
      </Table.Cell>
    );
  };

  render() {
    const { tagScores } = this.props;
    const columnNames = ['Name', 'Implication', 'Source'];
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            {columnNames.map((name, index) => {
              return (
                <Table.HeaderCell key={index} textAlign='center'>
                  {name}
                  {name === 'Implication' ? (
                    <Icon link name='sort' onClick={() => this.handleClickOnSortImplications('type')} />
                  ) : null}
                  {name === 'Source' ? (
                    <Icon link name='sort' onClick={() => this.handleClickOnSortSources('type')} />
                  ) : null}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(tagScores || []).map((tagScore, key) => {
            return (
              <Table.Row key={key}>
                {columnNames.map((name, index2) => {
                  return this.renderCell(name, index2 + key, tagScore);
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

class DiffusionTables extends Component {
  componentDidMount() {
    this.getJobImplicationsAndSources();
  }

  async getJobImplicationsAndSources() {
    const { jobId } = this.props;
    const url = baseUrl + '/techAsset/getJobImplicationsAndSources/' + jobId;
    const data = (await axios.get(url)).data;
    const sortedTagScores = _.sortBy(data, 'implicationScore').reverse();
    this.setState({ tagScores: sortedTagScores });

    this.getSameLevel2Category(sortedTagScores);
    this.getSameLevel1DifferentLevel2Category(sortedTagScores);
    this.getDifferentLevel1Category(sortedTagScores);
  }

  async getSameLevel2Category(tagScores) {
    const { jobsInSameLevel2Category } = this.props;
    const tagsScoresSameLevel2Category = _.map(jobsInSameLevel2Category, (job) => {
      return (
        _.find(tagScores, (tagScore) => tagScore.id === job.id) || {
          id: job.id,
          name: job.name,
          implicationScore: 0,
          sourceScore: 0,
        }
      );
    });
    const sortedTagsScoresSameLevel2Category = _.sortBy(tagsScoresSameLevel2Category, 'implicationScore').reverse();
    this.setState({ tagsScoresSameLevel2Category: sortedTagsScoresSameLevel2Category });
  }

  async getSameLevel1DifferentLevel2Category(tagScores) {
    const { jobsInSameLevel1Category, jobsInSameLevel2Category } = this.props;
    const jobIdInLevel2Category = _.map(jobsInSameLevel2Category, (job) => job.id);
    const jobsSameLevel1DifferentLevel2Category = _.filter(
      jobsInSameLevel1Category,
      (job) => !jobIdInLevel2Category.includes(job.id),
    );
    const tagsScoresSameLevel1DifferentLevel2Category = _.map(jobsSameLevel1DifferentLevel2Category, (job) => {
      return (
        _.find(tagScores, (tagScore) => tagScore.id === job.id) || {
          id: job.id,
          name: job.name,
          implicationScore: 0,
          sourceScore: 0,
        }
      );
    });
    const sortedTagsScoresSameLevel1DifferentLevel2Category = _.sortBy(
      tagsScoresSameLevel1DifferentLevel2Category,
      'implicationScore',
    ).reverse();
    this.setState({ tagsScoresSameLevel1DifferentLevel2Category: sortedTagsScoresSameLevel1DifferentLevel2Category });
  }

  async getDifferentLevel1Category(tagScores) {
    const { jobsInSameLevel1Category } = this.props;
    const jobIdInLevel1Category = _.map(jobsInSameLevel1Category, (job) => job.id);

    const tagsScoresDifferentLevel1Category = _.filter(tagScores, (tag) => !jobIdInLevel1Category.includes(tag.id));
    const sortedTagsScoresDifferentLevel1Category = _.sortBy(
      tagsScoresDifferentLevel1Category,
      'implicationScore',
    ).reverse();
    this.setState({ tagsScoresDifferentLevel1Category: sortedTagsScoresDifferentLevel1Category });
  }

  render() {
    const {
      tagScores,
      tagsScoresSameLevel2Category,
      tagsScoresSameLevel1DifferentLevel2Category,
      tagsScoresDifferentLevel1Category,
    } = this.state || {};

    return (
      <Tab
        panes={[
          {
            menuItem: 'All',
            render: () => (
              <Tab.Pane>
                <DiffusionTable tagScores={tagScores} />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Same Level 2',
            render: () => (
              <Tab.Pane>
                <DiffusionTable tagScores={tagsScoresSameLevel2Category} />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Same Level 1 - Different Level 2',
            render: () => (
              <Tab.Pane>
                <DiffusionTable tagScores={tagsScoresSameLevel1DifferentLevel2Category} />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Different Level 1',
            render: () => (
              <Tab.Pane>
                <DiffusionTable tagScores={tagsScoresDifferentLevel1Category} />
              </Tab.Pane>
            ),
          },
        ]}
      />
    );
  }
}

export default DiffusionTables;
