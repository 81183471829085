import axios from 'axios';
import _ from 'underscore';
import React, { Component } from 'react';
import { Header, Grid, Table } from 'semantic-ui-react';
import { Input, Range, Select, SelectInt, SweetForm, List as SweetList, customOperator, enhance } from 'sweetform';
import {
  TypologyFilter,
  ActivityFilter,
  ActionFilter,
  SelectSegmentationFields,
  wrapActionFilterToIdParams,
  wrapFilterToIdParams
} from './common';
import moment from 'moment';
import baseUrl from '../../baseUrl';

const getCounts = (segmentation) => {
  let itemsCount = {}
  _.each(segmentation, ({ items }) => {
    _.each(items, ({ id, count }) => {
      itemsCount[id] = count;
    })
  })
  const counts = _.values(itemsCount);
  return {
    nbDistinctItems: counts.length,
    nbDistinctActions: counts.reduce((partialSum, a) => partialSum + a, 0),
  }
}

const getPercent = (num, denum) => Math.round(num / (denum || 1) * 100) + '%'

const SegmentationDisplayer = ({ segmentation, onSelectItems, title, actionsName }) => {
  const sortedSegmentation = _.sortBy(segmentation, ({ count }) => -(count || 0));
  const { nbDistinctItems, nbDistinctActions } = getCounts(segmentation);
  return (
    <div>
      <Header as="h3">{title}</Header>
      <span>{nbDistinctItems} items - {nbDistinctActions} {actionsName || 'actions'}</span>
      <Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>Segment</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'># Items</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>{actionsName || '# Actions'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedSegmentation, (segment, i) => {
            const { segment: name, count, countItems, items } = segment;
            return (
              <Table.Row key={i}>
                <Table.Cell textAlign='center'>
                  <span onClick={() => onSelectItems(items)} style={{ cursor: "pointer" }}>{name}</span>
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {countItems || 0}
                  {` (${getPercent(countItems, nbDistinctItems)})`}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {count || 0}
                  {` (${getPercent(count, nbDistinctActions)})`}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>

  )
}

export default SegmentationDisplayer;