import moment from 'moment';
import _ from 'underscore';

export const isRecentlyInState = (sweetappProfile, state, daysAgo) => {
    if (!sweetappProfile){
        return false;
    }
    if (state == "answered" && sweetappProfile.answer && sweetappProfile.answer.date){
        const actionDaysFromNow = moment().diff(moment(sweetappProfile.answer.date), 'days');
        return actionDaysFromNow < daysAgo;
    } else if (state == "contacted" && sweetappProfile.sentDate){
        const actionDaysFromNow = moment().diff(moment(sweetappProfile.sentDate), 'days');
        return actionDaysFromNow < daysAgo;
    }
    return false;
    
}

const computeSheetItemsStats = ({sheetItems}) => {
    let nbCandidates = 0
    let nbRecentContactCandidates = 0
    let nbRecentAnswerCandidates = 0
    let nbRecentProcessCandidates = 0
    let nbOtherCandidates = 0

    const getMostAdvancedOneMonthState = ({matches}) => {
        for (let iMatch=0; iMatch < matches.length; iMatch++){
            let match = matches[iMatch];
            if (match.state === 'in-process' && match.oneMonthInState){
                return 'in-process'
            }
            if (match.state === 'answered' && match.oneMonthInState){
                return 'answered'
            }
            if (match.state === 'contacted' && match.oneMonthInState){
                return 'contacted'
            }
        }
        return null
    }
    _.each(sheetItems, ({matches})=> {
        nbCandidates += 1;
        const mostAdvancedOneMonthState = getMostAdvancedOneMonthState({matches});
        if (mostAdvancedOneMonthState === 'in-process'){
            nbRecentProcessCandidates += 1
            return;
        }
        if (mostAdvancedOneMonthState === 'answered'){
            nbRecentAnswerCandidates += 1
            return;
        }
        if (mostAdvancedOneMonthState === 'contacted'){
            nbRecentContactCandidates += 1
            return;
        }
        nbOtherCandidates += 1;
    })
    return {
        nbCandidates,
        nbRecentContactCandidates,
        nbRecentAnswerCandidates,
        nbRecentProcessCandidates,
        nbOtherCandidates
    }
}

export default computeSheetItemsStats;