import React from 'react';


const ScrappedTextWrapper = ({ text, children }) => {
  if (
    text &&
    (text.toLowerCase().indexOf('see more') === text.length - 8 ||
      text.toLowerCase().indexOf('voir plus') === text.length - 9)
  ) {
    return <div style={{ backgroundColor: 'orange' }}>{children}</div>;
  }
  return <div>{children}</div>;
};

export default ScrappedTextWrapper;