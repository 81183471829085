import React, { Component } from 'react';
import { formScorerToQuery, SearchModal } from './Offers/SearchModal';

const initialValues = {
  filters: [],
  scorer: {},
  currentSearch: {},
  mode: 'search'
};


class Metasearch extends Component {
  state = {
    search: initialValues
  }
  handleChange = (value) => {
    this.setState({ search: value });
  }
  render() {
    return (
      <SearchModal
        metasearch={true}
        initialValues={initialValues} 
        offer={{}}
        onCancel={this.props.onCancel} 
        onChangeEdit={this.handleChange} 
        username='metasearch'
        onSubmit={() => this.props.onSubmit(formScorerToQuery(this.state.search))}
      />
    )
  }
}

export default Metasearch;
