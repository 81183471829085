import _ from 'underscore';
import React, { Component } from 'react';
import { Table, Segment, Header } from 'semantic-ui-react';

export default class DashBoardSearchLeastEfficient extends Component {
  render() {
    const leastPerformantOffers = _.map(this.props.stats, (stat) => ({
      ...stat,
      colour: stat.selectRate * 100 >= 20 ? '#ffffff' : '#ffdddd',
    }));
    return (
      <Segment>
        <Header as="h3" textAlign="center">
          Pires offres
        </Header>
        <Table celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nom de l'offre</Table.HeaderCell>
              <Table.HeaderCell>Catégorie Maher</Table.HeaderCell>
              <Table.HeaderCell>Taux de select</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(leastPerformantOffers, (rate) => (
              <Table.Row style={{ backgroundColor: rate.colour }}>
                <Table.Cell>{rate.offerName}</Table.Cell>
                <Table.Cell>{rate.maherCategory}</Table.Cell>
                <Table.Cell>
                  {Math.round(rate.selectRate * 100) + " %"}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
