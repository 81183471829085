import MatchingView from './MatchingView';

import axios from 'axios';
import React from 'react';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';

class MaxMatching extends MatchingView {
  constructor(props) {
    super(props);
    const buttonsList = [
      { type: 'selectNext', indexIncrease: -1, icon: 'arrow left' },
      { type: 'noReason', grade: 'A', color: 'green', hoverText: ' Très bien' },
      { type: 'reason', grade: 'B', color: 'olive', hoverText: ' Assez bien' },
      { type: 'reason', grade: 'C', color: 'yellow', hoverText: ' Moyen' },
      { type: 'reason', grade: 'D', color: 'orange', hoverText: ' Mauvais' },
      { type: 'reason', grade: 'E', color: 'red', hoverText: ' Très mauvais' },
      { type: 'selectNext', indexIncrease: 1, icon: 'arrow right' },
    ];
    this.state = {
      matchingInfo: null,
      selectedMatchingId: null,
      matchingOptions: null,
      matches: [],
      sortType: 'offer',
      detailsMode: 'raw',
      enableUpload: false,
      displayAnnotation: false,
      enableAnnotations: false,
      buttonsList,
    };
  }
  async componentWillMount() {
    const matchingOptions = await this.getMatchingOptions();
    this.setState({ matchingOptions });
  }

  getOfferData = async ({ offerId }) => {
    const offer = (await axios.get(`${baseUrl}/offers/${offerId}`)).data;
    if (!offer) {
      console.log('Offer not found');
    } else {
      return offer;
    }
  };

  getLinkedinData = async ({ linkedinId }) => {
    if (!linkedinId) {
      return null;
    } else {
      try {
        const profile = (await axios.get(baseUrl + '/sourceData/linkedin/' + linkedinId)).data;
        return profile;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  };

  getGithubData = async ({ githubId }) => {
    if (!githubId) {
      return null;
    } else {
      try {
        const profile = (await axios.get(baseUrl + '/sourceData/github/' + githubId)).data;
        return profile;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  };

  getStackoverflowData = async ({ stackoverflowId }) => {
    if (!stackoverflowId) {
      return null;
    } else {
      try {
        const profile = (await axios.get(baseUrl + '/sourceData/stackOverflow/' + stackoverflowId)).data;
        return profile;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  };

  getMatchingOptions = async () => {
    console.log('getMatchingOptions');
    const { results } = (await axios.post(`${baseUrl}/maxMatching/matchings`, { type: 'max-matching' })).data;

    console.log(results);
    return _.sortBy(results, ({ timestamp }) => -timestamp);
  };

  renderMatchingInfo({ matchingInfo }) {
    //const {date, total_score, matches, profiles, offers} = matchingInfo;
    //return (
    //    <Statistic.Group size='mini'>
    //      <Statistic>
    //        <Statistic.Value>{date}</Statistic.Value>
    //        <Statistic.Label>date</Statistic.Label>
    //      </Statistic>
    //      <Statistic>
    //        <Statistic.Value>{Math.round(total_score)}</Statistic.Value>
    //        <Statistic.Label>score</Statistic.Label>
    //      </Statistic>
    //      <Statistic>
    //        <Statistic.Value>{matches}</Statistic.Value>
    //        <Statistic.Label>#matches</Statistic.Label>
    //      </Statistic>
    //      <Statistic>
    //        <Statistic.Value>{profiles}</Statistic.Value>
    //        <Statistic.Label>#profiles</Statistic.Label>
    //      </Statistic>
    //      <Statistic>
    //        <Statistic.Value>{offers}</Statistic.Value>
    //        <Statistic.Label>#offers</Statistic.Label>
    //      </Statistic>
    //    </Statistic.Group>
    //)
    return <pre>{JSON.stringify(matchingInfo, null, '  ')}</pre>;
  }
}

export default MaxMatching;
