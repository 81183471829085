import _ from 'underscore';
import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import DashBoardSearchHeader from './DashBoardSearchHeader';
import DashBoardSearchMaher from './DashBoardSearchMaher';
import DashBoardSearchLeastEfficient from './DashBoardSearchLeastEfficient';
import DashBoardSearchMostEfficient from './DashBoardSearchMostEfficient';
import DashBoardSearchLinkedinSize from './DashBoardSearchLinkedinSize';
import DashBoardSearchSelectionRateHistory from './DashBoardSearchSelectionRateHistory';
import DashBoardSearchTop100SelectRate from './DashBoardSearchTop100SelectRate';
import DashBoardRawCountHistory from './DashBoardRawCountHistory';
import DashBoardCategoryMaherHistory from './DashBoardCategoryMaherHistory';
import DashBoardExternalFlow from './DashBoardExternalFlow';
import axios from 'axios';
import baseUrl from '../baseUrl.js';

class DashBoardSearch extends Component {
  constructor(props) {
    super(props);
    this.loadData();
  }

  loadData = async () => {
    axios.get(`${baseUrl}/searchStats/dailySearchStats`).then(({ data }) => {
      this.setState({ searchStats: data });
    });
  };

  render() {
    console.log(this.state);
    if (!this.state || !this.state.searchStats) {
      return <div />;
    }
    const searchStats = this.state.searchStats === null ? [] : this.state.searchStats;
    const sortedSelectionRateHistory = _.sortBy(searchStats.selectionRateHistory, function(rate) {
      return rate.date;
    }).reverse();

    return (
      <div>
        <Grid>
          <Grid.Row>
            <DashBoardSearchHeader stats='DashBoard search' />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <b>Taux de select</b>
              </div>
              <div
                style={{
                  margin: 'auto',
                  padding: 0,
                  display: 'center',
                  backgroundColor: '#8884d8',
                  borderRadius: '50%',
                  width: 50,
                  height: 50,
                  fill: 'Hello',
                  textAlign: 'center',
                  color: 'white',
                  fontSize: 20,
                }}
              >
                <br />
                {Math.round(sortedSelectionRateHistory[0].selected * 100) + ' %'}
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                }}
              >
                {sortedSelectionRateHistory[0].selected - sortedSelectionRateHistory[1].selected <= 0
                  ? Math.round(
                      (sortedSelectionRateHistory[0].selected - sortedSelectionRateHistory[1].selected) * 100,
                    ) + ' %'
                  : '+' +
                    Math.round(
                      (sortedSelectionRateHistory[0].selected - sortedSelectionRateHistory[1].selected) * 100,
                    ) +
                    ' %'}
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <b>Offres rouges</b>
              </div>
              <div
                style={{
                  margin: 'auto',
                  padding: 0,
                  display: 'center',
                  backgroundColor: '#8884d8',
                  borderRadius: '50%',
                  width: 50,
                  height: 50,
                  fill: 'Hello',
                  textAlign: 'center',
                  color: 'white',
                  fontSize: 20,
                }}
              >
                <br />
                {Math.round(searchStats.percentageOfRedOffers.current * 100) + ' %'}
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                }}
              >
                {searchStats.percentageOfRedOffers.progression <= 0
                  ? Math.round(searchStats.percentageOfRedOffers.progression * 100) + ' %'
                  : '+' + Math.round(searchStats.percentageOfRedOffers.progression * 100) + ' %'}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Grid.Row>
                <DashBoardSearchSelectionRateHistory stats={searchStats.selectionRateHistory} />
              </Grid.Row>
              <Grid.Row>
                <DashBoardRawCountHistory stats={searchStats.rawCountHistory} />
              </Grid.Row>
              <Grid.Row>
                <DashBoardExternalFlow stats={searchStats.externalFlowPerformance} />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row>
                <DashBoardSearchLinkedinSize stats={searchStats.linkedinProfilesSizeHistory} />
              </Grid.Row>
              <Grid.Row>
                <DashBoardSearchTop100SelectRate stats={searchStats.selectionRateTop100History} />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row>
                <DashBoardSearchMaher
                  stats={_.sortBy(searchStats.categoryMaherPerformanceRate, function(rate) {
                    return -rate.selectRate;
                  })}
                />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row>
                <DashBoardSearchMostEfficient
                  stats={_.sortBy(searchStats.bestSearches, function(rate) {
                    return -rate.selectRate;
                  })}
                />
              </Grid.Row>
              <Grid.Row>
                <DashBoardSearchLeastEfficient
                  stats={_.sortBy(searchStats.worstSearches, function(rate) {
                    return rate.selectRate;
                  })}
                />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <DashBoardCategoryMaherHistory stats={searchStats.selectionRateHistoryPerMaherCategory} />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
export default DashBoardSearch;
