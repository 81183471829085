import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'semantic-ui-react';

const defaultStyle = {
  fontSize: '.4rem',
  marginLeft: '.4rem',
  borderRadius: '1em',
  verticalAlign: 'top',
};

const Copy = ({ text, style }) => (
  <CopyToClipboard text={text}>
    <Button
      circular
      compact
      icon="copy"
      color="blue"
      style={style ? { ...defaultStyle, ...style } : defaultStyle}
      onClick={(e) => e.stopPropagation()}
    />
  </CopyToClipboard>
);
export default Copy;
