import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import {
  Input,
  Container,
  Table,
  Label,
  Grid,
  Popup,
  Icon,
  Header,
  Segment,
} from 'semantic-ui-react';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';
import { MiscProfile } from './MiscProfile';
import { GithubProfile } from './GithubProfile';
import { StackOverflowProfile } from './StackoverflowProfile';
import baseUrl from '../../baseUrl.js';
import Modal from '../../Modal';

const typeStyles = {
  positive: {
    color: 'green',
    fontWeight: 'bold',
  },
  medium: {
    color: 'orange',
    fontWeight: 'bold',
  },
  negative: {
    color: 'red',
    fontWeight: 'bold',
  },
};

// const RadarChartSearch = ({ skills }) => {
//   if (!skills) { return null; }
//   const data = _.map(skills, (value, key) => ({
//     skillName: key,
//     skillValue: value * 100,
//   }))
//   return (
//     <RadarChart outerRadius={75} width={250} height={200} data={data}>
//       <PolarGrid />
//       <PolarAngleAxis dataKey="skillName" style={{ fontSize: '10px' }} width={200} />
//       <PolarRadiusAxis angle={30} domain={[0, 100]} />
//       <Radar name="skill" dataKey="skillValue" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
//       <Tooltip />
//     </RadarChart>
//   )
// };

// const ProgressChart = ({ label, current, min, max }) => {
//   const percent = Math.round(100 * (current - min) / (max - min));
//   const color = percent < 25 ? 'red' : percent < 75 ? 'yellow' : 'green';
//   return <Grid.Column textAlign='center'>
//     <Progress percent={percent} color={color}></Progress>
//     <div style={{ marginTop: -30, fontSize: 10 }}>{label}</div>
//   </Grid.Column>;
// };

export const PlatformDataPane = ({ data }) => {
  return data ? (
    <Container style={{ maxHeight: '250px', overflowY: 'auto' }}>
      <Table compact="very" style={{ border: 'none' }}>
        <Table.Body>
          {_.map(
            _.sortBy(data, ({ date }) => -new Date(date).getTime()),
            ({ date, title, label, reason, color }, index) => (
              <Table.Row
                key={index}
                {...label && { style: typeStyles[label] }}
                {...(color
                  ? color === 'green'
                    ? { positive: true }
                    : color === 'red'
                    ? { negative: true }
                    : {}
                  : {})}
              >
                <Table.Cell>{moment(date).fromNow()}</Table.Cell>
                <Table.Cell>
                  {title}
                  {reason ? (
                    <Popup trigger={<Icon name="add" />} content={reason} />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            ),
          )}
        </Table.Body>
      </Table>
    </Container>
  ) : null;
};

const ItemComments = ({ comments, deleteItemComment }) => (
  <Segment inverted color="blue">
    {/* <Label as='div' color='black' ribbon>
      Comments
    </Label> */}
    {_.map(comments, ({ username, date, message }, index) => (
      <div style={{ margin: '5px 0px', display: 'flex' }} key={index}>
        <div style={{ width: '90%' }}>
          <Header
            inverted
            as="h4"
            style={{ margin: '2px 0px' }}
          >{`${username} - ${(date || '').slice(0, 10)}`}</Header>
          <p>{message}</p>
        </div>
        <div style={{ width: '10%', float: 'right' }}>
          <Popup
            trigger={
              <Icon name="trash" onClick={() => deleteItemComment(date)} />
            }
            content={'delete permanently this comment'}
          />
        </div>
      </div>
    ))}
  </Segment>
);

const WarningsPanel = ({ warnings }) => (
  <Segment inverted color="red" secondary>
    {/* <Label as='div' color='black' ribbon>
      Warnings
    </Label> */}
    {_.map(warnings, ({ title, text }, index) => (
      <div style={{ margin: '5px 0px' }} key={index}>
        {title && (
          <Header inverted as="h4" style={{ margin: '2px 0px' }}>
            {(title || '').indexOf('ready worked at') >= 0 && (
              <Icon name="warning sign" />
            )}
            {(title || '').indexOf('have a look') >= 0 && <Icon name="eye" />}
            {title}
          </Header>
        )}
        {text && <p>{text}</p>}
      </div>
    ))}
  </Segment>
);

const ScoresBarChart = ({ data, marginLeft }) => (
  <BarChart
    width={230}
    height={80}
    data={data}
    layout="vertical"
    margin={{ left: marginLeft || 0 }}
  >
    <XAxis type="number" domain={[0, 1]} />
    <YAxis type="category" dataKey="name" interval={0} />
    {/* <CartesianGrid stroke="#ebf3f0" horizontal={false} /> */}
    <Tooltip />
    {/* <Legend /> */}
    <Bar dataKey="score">
      {data.map((entry, index) => {
        const color =
          entry.score > 0.5
            ? '#82ca9d'
            : entry.score > 0.25
            ? '#c7a432'
            : entry.score > 0
            ? '#c73232'
            : '#000000';
        return <Cell key={index} fill={color} />;
      })}
    </Bar>
  </BarChart>
);

const getAnswerCategory = (answerData, answerRate) => {
  if (!answerData) {
    return null;
  }
  if (answerData.length === 0) {
    return 'never';
  }
  const lastPositiveAnswerDate = (
    _.last(_.filter(answerData, ({ label }) => label === 'positive')) || {}
  ).date;
  if (lastPositiveAnswerDate) {
    const daysAgo =
      (Date.now() - new Date(lastPositiveAnswerDate).getTime()) /
      (1000 * 60 * 60 * 24);
    if (daysAgo <= 3 * 30) {
      return 'active';
    }
    if (daysAgo <= 12 * 30) {
      return 'good';
    }
  }
  if (answerRate >= 50) {
    return 'good';
  }
  if (answerRate >= 25) {
    return 'calm';
  }
  return 'answered';
};

class ProfileSearchGrades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleCloseModal = () => {
    this.setState({ annotation: null });
  };
  handleOpenModal = ({ type, skillId, jobId }) => {
    this.setState({
      annotation: {
        type,
        skillId,
        jobId,
      },
    });
  };
  handleChangeValue = ({ key, value }) => {
    const { annotation } = this.state;
    if (!annotation) {
      return alert('error in react state');
    }
    console.log(key, value);
    this.setState({
      annotation: {
        ...annotation,
        [key]: value,
      },
    });
  };
  handleSendAnnotation = async ({
    type,
    skillId,
    jobId,
    idFields,
    minGrade,
    maxGrade,
    targetGrade,
  }) => {
    try {
      if (['job', 'skill'].indexOf(type) < 0) {
        return alert('type should be job or skill');
      }
      if (type === 'job' && !jobId) {
        return alert('jobId should be defined');
      }
      if (type === 'skill' && !skillId) {
        return alert('skillId should be defined');
      }
      if (
        !idFields ||
        (!idFields.linkedin && !idFields.github && !idFields.stackoverflow)
      ) {
        return alert('need at least one of linkedin, github, stackoverflow');
      }
      if (!minGrade && !maxGrade && !targetGrade) {
        return alert(
          'at least one of minGrade, maxGrade, targetGrade should be defined',
        );
      }
      const possibleValues = [
        'A+',
        'A',
        'AB',
        'B',
        'BC',
        'C',
        'CD',
        'D',
        'DE',
        'E',
        'E-',
      ];
      const values = [minGrade, maxGrade, targetGrade];
      for (let iValue = 0; iValue < values.length; iValue++) {
        if (values[iValue] && possibleValues.indexOf(values[iValue]) < 0) {
          return alert('possible values : ' + possibleValues.join(', '));
        }
      }
      if (minGrade && maxGrade) {
        if (
          possibleValues.indexOf(maxGrade) > possibleValues.indexOf(minGrade)
        ) {
          return alert('max is lower than min');
        }
      }
      if (minGrade && maxGrade && targetGrade) {
        if (
          possibleValues.indexOf(minGrade) < possibleValues.indexOf(targetGrade)
        ) {
          return alert('target is lower than min');
        }
        if (
          possibleValues.indexOf(maxGrade) > possibleValues.indexOf(targetGrade)
        ) {
          return alert('target is greater than max');
        }
      }
      const { data } = await axios.post(
        baseUrl + '/sweetwork/profileGradeAnnotations',
        {
          idFields: {
            ...(idFields.linkedin && { linkedin: idFields.linkedin }),
            ...(idFields.github && { github: idFields.github }),
            ...(idFields.stackoverflow && {
              stackoverflow: idFields.stackoverflow,
            }),
          },
          type,
          ...(skillId && { skillId }),
          ...(jobId && { jobId }),
          ...(minGrade && { minGrade }),
          ...(maxGrade && { maxGrade }),
          ...(targetGrade && { targetGrade }),
        },
      );
      if (data.error) {
        return alert(data.error);
      }
      alert(
        'Annotation saved !\n\n' +
          (type + ' ' + { job: jobId, skill: skillId }[type] + '\n') +
          (minGrade ? 'min: ' + minGrade + '\n' : '') +
          (maxGrade ? 'max: ' + maxGrade + '\n' : '') +
          (targetGrade ? 'target: ' + targetGrade + '\n' : ''),
      );
      this.handleCloseModal();
    } catch (e) {
      return alert(e.message);
    }
  };
  handleSubmit = () => {
    const { idFields } = this.props;
    const { type, skillId, jobId, minGrade, maxGrade, targetGrade } =
      this.state.annotation || {};
    this.handleSendAnnotation({
      type,
      skillId,
      jobId,
      idFields,
      minGrade,
      maxGrade,
      targetGrade,
    });
  };
  renderModal() {
    const { annotation } = this.state;
    if (!annotation) {
      return;
    }
    // const options = _.map(
    //   ['A+', 'A', 'AB', 'B', 'BC', 'C', 'CD', 'D', 'DE', 'E', 'E-'],
    //   (str) => ({ text: str, value: str }),
    // );
    return (
      <Modal
        active={true}
        headerText={
          'Edit ' +
          {
            job: 'job position',
            skill: 'skill',
          }[annotation.type] +
          ' ' +
          {
            job: annotation.jobId,
            skill: annotation.skillId,
          }[annotation.type]
        }
        onCancel={this.handleCloseModal}
        onSubmit={this.handleSubmit}
      >
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <center>
                <h4>Min</h4>
                <Input
                  style={{ width: 50 }}
                  onChange={(e, { value }) => {
                    this.handleChangeValue({ key: 'minGrade', value });
                  }}
                />
              </center>
            </Grid.Column>
            <Grid.Column>
              <center>
                <h4>Target</h4>
                <Input
                  style={{ width: 50 }}
                  onChange={(e, { value }) => {
                    this.handleChangeValue({ key: 'targetGrade', value });
                  }}
                />
              </center>
            </Grid.Column>
            <Grid.Column>
              <center>
                <h4>Max</h4>
                <Input
                  style={{ width: 50 }}
                  onChange={(e, { value }) => {
                    this.handleChangeValue({ key: 'maxGrade', value });
                  }}
                />
              </center>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal>
    );
  }
  render() {
    const { grades } = this.props;
    if (_.isEmpty(grades)) {
      return <span />;
    }

    const pretty = (str) => {
      const text = _.map(str.split('-'), (part) =>
        part.length > 0 ? part.charAt(0).toUpperCase() + part.slice(1) : '',
      ).join(' ');
      return text.length > 15 ? text.slice(0, 12) + '.' : text;
    };
    return (
      <div>
        {this.renderModal()}
        <Grid style={{ paddingTop: 8, paddingBottom: 15 }}>
          <Grid.Row>
            <Grid.Column width={5}>
              {_.map(grades.targetJobPositions, (grade, jobId) => (
                <Grid key={'target_job_position' + jobId}>
                  <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column
                      width={10}
                      textAlign="right"
                      verticalAlign="middle"
                      style={{ padding: 0 }}
                    >
                      {pretty(jobId)}
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      verticalAlign="middle"
                      textAlign="right"
                      style={{ paddingRight: 0 }}
                    >
                      {grade}
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      verticalAlign="middle"
                      style={{ paddingLeft: 3 }}
                    >
                      <Icon
                        link
                        name="circle"
                        color="yellow"
                        onClick={() =>
                          this.handleOpenModal({ type: 'job', jobId })
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ))}
              {_.map(grades.requiredSkills, (grade, skillId) => (
                <Grid key={'required_skills' + skillId}>
                  <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column
                      width={10}
                      textAlign="right"
                      verticalAlign="middle"
                      style={{ padding: 0 }}
                    >
                      {pretty(skillId)}
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      verticalAlign="middle"
                      textAlign="right"
                      style={{ paddingRight: 0 }}
                    >
                      {grade}
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      verticalAlign="middle"
                      style={{ paddingLeft: 3 }}
                    >
                      <Icon
                        link
                        name="circle"
                        color="orange"
                        onClick={() =>
                          this.handleOpenModal({ type: 'skill', skillId })
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ))}
            </Grid.Column>
            <Grid.Column width={5}>
              {_.map(grades.importantSkills, (grade, skillId) => (
                <Grid key={'required_skills' + skillId}>
                  <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column
                      width={10}
                      textAlign="right"
                      verticalAlign="middle"
                      style={{ padding: 0 }}
                    >
                      {pretty(skillId)}
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      verticalAlign="middle"
                      textAlign="right"
                      style={{ paddingRight: 0 }}
                    >
                      {grade}
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      verticalAlign="middle"
                      style={{ paddingLeft: 3 }}
                    >
                      <Icon
                        link
                        name="circle"
                        color="orange"
                        onClick={() =>
                          this.handleOpenModal({ type: 'skill', skillId })
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ))}
            </Grid.Column>
            <Grid.Column width={5}>
              {_.map(
                grades.allJobPositions,
                (grade, jobId) =>
                  ['A+', 'A', 'AB', 'B'].indexOf(grade) >= 0 &&
                  !(grades.targetJobPositions || {})[jobId] && (
                    <Grid key={'target_job_position' + jobId}>
                      <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column
                          width={10}
                          textAlign="right"
                          verticalAlign="middle"
                          style={{ padding: 0 }}
                        >
                          {pretty(jobId)}
                        </Grid.Column>
                        <Grid.Column
                          width={3}
                          verticalAlign="middle"
                          textAlign="right"
                          style={{ paddingRight: 0 }}
                        >
                          {grade}
                        </Grid.Column>
                        <Grid.Column width={3} style={{ paddingLeft: 3 }}>
                          <Icon
                            link
                            name="circle"
                            color="yellow"
                            onClick={() =>
                              this.handleOpenModal({ type: 'job', jobId })
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ),
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const ProfileSearchScores = ({ innerData, platformData, sheetProfiles }) => {
  const { rank, pipeLength, intermediateScoresAndRanks, offerSkillsAndScores } =
    innerData || {};
  const scorerRanking = {
    skill: 0,
    jobPosition: 1,
    prestige: 2,
    location: 3,
  };
  const scorerData = _.sortBy(
    _.map(intermediateScoresAndRanks, ({ rank, score }, scorer) => ({
      name: scorer,
      score: Math.floor(score * 100) / 100,
    })),
    ({ scorer }) => scorerRanking[scorer],
  );
  //name === 'top-engineering-school' ? 'top-eng-sc' :
  const skillsData =
    (offerSkillsAndScores || {}).requiredSkills ||
    (offerSkillsAndScores || {}).importantSkills
      ? _.map(
          {
            ...(offerSkillsAndScores || {}).requiredSkills,
            '----------------': 0,
            ...(offerSkillsAndScores || {}).importantSkills,
          },
          (score, name) => ({
            name: name.slice(0, 10),
            score,
          }),
        )
      : null;
  const sendData = _.compact(
    _.flatten(
      _.map(platformData, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date }) =>
          type === 'send' ? { date, title: jobOfferId } : null,
        ),
      ),
    ),
  );
  const skipData = _.compact(
    _.flatten(
      _.map(platformData, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date, reason }) =>
          type === 'skipProfile' ? { date, title: jobOfferId, reason } : null,
        ),
      ),
    ),
  );
  const answerData =
    platformData &&
    _.sortBy(
      _.compact(
        _.flatten(
          _.map(platformData, ({ actions, jobOfferId }) =>
            _.map(actions, ({ type, date, label, labels }) => {
              return type === 'answer-detected'
                ? {
                    date,
                    title: jobOfferId,
                    label,
                    ...(labels &&
                      labels.length > 0 && { reason: labels.join('; ') }),
                  }
                : null;
            }),
          ),
        ),
      ),
      'date',
    );
  const answerRate =
    answerData && sendData.length > 0
      ? Math.floor((answerData.length * 100) / sendData.length)
      : 0;
  const nbPushed = sendData.length + skipData.length;
  const sendRate =
    nbPushed > 0 ? Math.floor((sendData.length * 100) / nbPushed) : null;
  const answerCategory = getAnswerCategory(answerData, answerRate);
  return (
    <Table style={{ margin: '0' }} basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell
            rowSpan="2"
            textAlign="center"
            verticalAlign="middle"
            style={{ padding: 0 }}
          >
            {_.isEmpty(scorerData) && <div>No scores</div>}
            {!_.isEmpty(scorerData) && (
              <ScoresBarChart
                data={scorerData}
                marginLeft={10}
                key={'scorer'}
              />
            )}
          </Table.Cell>
          <Table.Cell
            rowSpan="2"
            textAlign="center"
            verticalAlign="middle"
            style={{ padding: 0 }}
          >
            {_.isEmpty(skillsData) && <div>No scores</div>}
            {!_.isEmpty(skillsData) && (
              <ScoresBarChart
                data={skillsData}
                marginLeft={10}
                key={'skills'}
              />
            )}
          </Table.Cell>
          <Table.Cell
            colSpan="2"
            textAlign="center"
            verticalAlign="middle"
            style={{ padding: 0 }}
          >
            {_.isNumber(rank) && pipeLength && (
              <div>
                <div>{`${rank} / ${pipeLength} Ranking`}</div>
                {/* <div>Ranking</div> */}
              </div>
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell
            textAlign="center"
            verticalAlign="middle"
            style={{ padding: 0 }}
          >
            {_.isNumber(sendRate) && nbPushed && (
              <div
                style={{
                  color:
                    sendRate >= 70
                      ? 'green'
                      : sendRate >= 50
                      ? 'orange'
                      : 'red',
                }}
              >
                <div>{`${sendRate}% | `}{nbPushed}</div>
              </div>
            )}
          </Table.Cell>
          <Table.Cell
            textAlign="center"
            verticalAlign="middle"
            style={{ padding: 0 }}
          >
            {answerCategory && (
              <div>
                <div style={{ fontSize: '30px', marginBottom: '5px' }}>
                  {/* ❄️ */}
                  {answerCategory === 'never' ? (
                    <Popup
                      trigger={
                        <span role="img" aria-label="never">
                          🤔
                        </span>
                      }
                      header={'low'}
                    />
                  ) : answerCategory === 'active' ? (
                    <Popup
                      trigger={
                        <span role="img" aria-label="active">
                          🔥
                        </span>
                      }
                      header={'ok'}
                    />
                  ) : answerCategory === 'good' ? (
                    <Popup
                      trigger={
                        <span role="img" aria-label="good">
                          👌
                        </span>
                      }
                      header={'good'}
                    />
                  ) : answerCategory === 'calm' ? (
                    <Popup
                      trigger={
                        <span role="img" aria-label="calm">
                          ⛱️
                        </span>

                      }
                    />
                  ) : answerCategory === 'answered' ? (
                    <Popup
                      trigger={
                        <span role="img" aria-label="answered">
                          ⛄️
                        </span>
                      }

                    />
                  ) : null}
                </div>
              </div>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}; /**
 * 
 * <Grid style={{ margin: '0' }}>
      <Grid.Row>
        <Grid.Column width='6' textAlign='center' verticalAlign='middle' style={{ padding: 0 }}>
          {_.isEmpty(scorerData) && <div>No scores</div>}
          {!_.isEmpty(scorerData) && (
            <ScoresBarChart data={scorerData} marginLeft={10} key={'scorer'} />
          )}
        </Grid.Column>
        <Grid.Column width='6' textAlign='center' verticalAlign='middle' style={{ padding: 0 }}>
          {_.isEmpty(skillsData) && <div>No scores</div>}
          {!_.isEmpty(skillsData) && (
            <ScoresBarChart data={skillsData} marginLeft={60} key={'skills'} />
          )}
        </Grid.Column>
        <Grid.Column width='2' textAlign='center' verticalAlign='middle' style={{ padding: 0 }}>
          {_.isNumber(sendRate) && nbPushed &&
            <div style={{ color: sendRate >= 70 ? 'green' : sendRate >= 50 ? 'orange' : 'red' }}>
              <div>
                {`${sendRate}% send on ${nbPushed} action${nbPushed === 1 ? '' : 's'}`}
              </div>
              <div></div>
            </div>
          }
        </Grid.Column>
        <Grid.Column width='1' textAlign='center' verticalAlign='middle' style={{ padding: 0 }}>
          {answerCategory &&
            <div>
              <div style={{ fontSize: '30px', marginBottom: '5px' }}>
                {answerCategory === 'never' ? (
                  <Popup trigger={<span role="img" aria-label="never">🤔</span>} header={'never'} content={'never answered'} />
                ) : answerCategory === 'active' ? (
                  <Popup trigger={<span role="img" aria-label="active">🔥</span>} header={'active'} content={'answered in the last 3 months'} />
                ) : answerCategory === 'good' ? (
                  <Popup trigger={<span role="img" aria-label="good">👌</span>} header={'good'} content={'last answer between 3 mounths and 1 year ago or answer rate >= 50%'} />
                ) : answerCategory === 'calm' ? (
                  <Popup trigger={<span role="img" aria-label="calm">⛱️</span>} header={'calm'} content={'answer rate >= 25% but last answer older than 1 year'} />
                ) : answerCategory === 'answered' ? (
                  <Popup trigger={<span role="img" aria-label="answered">⛄️</span>} header={'answered'} content={'already answered but answer rate < 25%'} />
                ) : null}
              </div>
              <div>Interest</div>
            </div>
          }
        </Grid.Column>
        <Grid.Column width='1' textAlign='center' verticalAlign='middle' style={{ padding: 0 }}>
          {_.isNumber(rank) && pipeLength &&
            <div>
              <div>
                {`${rank} / ${pipeLength}`}
              </div>
              <div>Ranking</div>
            </div>
          }
        </Grid.Column>
      </Grid.Row>
    </Grid> 
 */
// }; // const CustomizedLabel = ({ x, y, fill, value }) => { //   console.log({ x, y, fill, value }) //   return ( //     <text //       x={x} //       y={y} //       dy={-4} //       fontSize='16' //       fontFamily='sans-serif' //       fill={fill} //       textAnchor="middle">{value}</text> //   )
const PlatformDataAnalysisPane = ({ profile, canShowHisto }) => {
  if (!canShowHisto) {
    return null;
  }
  const sendData = _.compact(
    _.flatten(
      _.map(profile.platformData, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date }) =>
          type === 'send' ? { date, title: jobOfferId } : null,
        ),
      ),
    ),
  );
  const skipData = _.compact(
    _.flatten(
      _.map(profile.platformData, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date, reason }) =>
          type === 'skipProfile' ? { date, title: jobOfferId, reason } : null,
        ),
      ),
    ),
  );
  const answerData = _.compact(
    _.flatten(
      _.map(profile.platformData, ({ actions, jobOfferId }) =>
        _.map(actions, ({ type, date, label, labels }) => {
          return type === 'answer-detected'
            ? {
                date,
                title: jobOfferId,
                label,
                ...(labels &&
                  labels.length > 0 && { reason: labels.join('; ') }),
              }
            : null;
        }),
      ),
    ),
  );
  const sheetData = _.compact(
    _.flatten(
      _.map(profile.sheetProfiles, ({ sheetName, timestamp, jobOfferId }) => ({
        date: timestamp,
        title: sheetName,
        color: _.find(sendData, (s) => s.title === jobOfferId)
          ? 'green'
          : _.find(skipData, (s) => s.title === jobOfferId)
          ? 'red'
          : null,
        reason: (_.find(skipData, (s) => s.title === jobOfferId) || {}).reason,
      })),
    ),
  );
  const sendRate = Math.floor(
    (sendData.length * 100) / (sendData.length + skipData.length),
  );
  const skipRate = Math.floor(
    (skipData.length * 100) / (sendData.length + skipData.length),
  );
  const answerRate = Math.floor((answerData.length * 100) / sendData.length);
  const currentDate = Date.now();
  const nbSendLast90Days = _.reduce(
    sendData,
    (memo, send) =>
      currentDate - new Date(send.date).getTime() < 90 * 24 * 60 * 60 * 1000
        ? memo + 1
        : memo,
    0,
  );
  return (
    <Grid columns={2}>
      <Grid.Column>
        <Tabs>
          <TabList>
            <Tab>{`Sheets (${sheetData ? sheetData.length : '0'})`}</Tab>
            <Tab>
              <span>
                {`Send (${sendData.length} : ${sendRate ? sendRate : '0'}%) `}
                <Label
                  color={
                    nbSendLast90Days < 2
                      ? 'green'
                      : nbSendLast90Days > 3
                      ? 'red'
                      : 'orange'
                  }
                >
                  {`90 days: ${nbSendLast90Days}`}
                </Label>
              </span>
            </Tab>
            <Tab>
              {`Skip (${skipData.length} : ${skipRate ? skipRate : '0'}%)`}
            </Tab>
          </TabList>
          <TabPanel>
            <PlatformDataPane data={sheetData} />
          </TabPanel>
          <TabPanel>
            <PlatformDataPane data={sendData} />
          </TabPanel>
          <TabPanel>
            <PlatformDataPane data={skipData} />
          </TabPanel>
        </Tabs>
      </Grid.Column>
      <Grid.Column>
        <Tabs>
          <TabList>
            <Tab>
              {`Answers (${answerData.length} : ${
                answerRate ? answerRate : '0'
              }%)`}
            </Tab>
          </TabList>
          <TabPanel>
            <PlatformDataPane data={answerData} />
          </TabPanel>
        </Tabs>
      </Grid.Column>
    </Grid>
  );
};
export const Analysis = ({
  workPipe,
  profile,
  onTabChange,
  actionsPane,
  sweetynote,
  onChangeExperienceHint,
  globalHint,
  onMore,
  more,
  markAsMatch,
  deleteItemComment,
  canShowHisto
}) => {
  const { github, stackOverflow } = (profile || {}).sourceProfiles || {};
  return (
    <div style={{ width: '100%' }}>
      {!_.isEmpty((profile || {}).warnings) && (
        <WarningsPanel warnings={(profile || {}).warnings} />
      )}
      {!_.isEmpty((sweetynote || {}).comments) && (
        <ItemComments
          comments={(sweetynote || {}).comments}
          deleteItemComment={deleteItemComment}
        />
      )}
      {/* <div style={{ fontSize: '30px', marginBottom: '5px' }}>🤔 🔥 👌 ⛱️ ⛄️</div> */}
      <ProfileSearchScores
        innerData={(profile || {}).innerData || {}}
        platformData={(profile || {}).platformData}
        sheetProfiles={(profile || {}).sheetProfiles}
      />
      <ProfileSearchGrades
        idFields={profile.idFields}
        grades={((profile || {}).innerData || {}).offerCriteriaGrades}
      />
      <MiscProfile
        idFields={profile.idFields}
        expanded={true}
        sources={(profile || {}).sourceProfiles}
      />
      {github && (
        <GithubProfile
          source={github}
          more={more}
          globalHint={globalHint}
          sweetynote={sweetynote}
          onChangeExperienceHint={onChangeExperienceHint}
          onMore={onMore}
          markAsMatch={markAsMatch}
        />
      )}
      {stackOverflow && (
        <StackOverflowProfile
          source={stackOverflow}
          more={more}
          globalHint={globalHint}
          onChangeExperienceHint={onChangeExperienceHint}
          onMore={onMore}
        />
      )}
      {workPipe.id !== 'lab-lisation-main-skillscfa04f' && (
        <PlatformDataAnalysisPane profile={profile} canShowHisto={canShowHisto} />
      )}
    </div>
  );
};
