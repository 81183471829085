import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl.js';
import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FilteredDashboardContainer from "../Dashboards/filteredDashboard";



class ASDashboard extends React.Component {
  state = {
    filters: [],
    dashboardId: "R5JWZO",
    clientIdsByOwner: null,
    selectedUserId: null
  }
  componentWillMount = () => {
    this.handleLoad()
  }
  handleLoad = async () => {
    try {
      const { clientIdsByOwner } = (await axios.get(`${baseUrl}/salesboard/clientIdsByOwner`)).data || {};
      this.setState({ clientIdsByOwner })
    } catch (e) {
      alert(e)
    }
  }
  handleSelectOwner = ({ userId }) => {
    if (userId == "all") {
      this.setState({ filters: [], selectedUserId: userId });
      return;
    }
    const clientIds = (this.state.clientIdsByOwner || {})[userId] || [];
    this.setState({ filters: [{ field: 'clientId', values: clientIds }], selectedUserId: userId });
  }
  render = () => {
    const { dashboardId, filters, selectedUserId, clientIdsByOwner } = this.state;
    const userOptions = _.filter(_.union(["all"], _.keys(clientIdsByOwner || {})), (uid)=>{
      if (!_.isString(uid)){
        return false;
      }
      if (["undefined", "[object Object]"].indexOf(uid)>=0){
        return false;
      }
      return true;
    });

    return (
      <div>
          {_.map(userOptions, (userId, i) => (
            <Button primary={userId == selectedUserId} key={i} onClick={()=>this.handleSelectOwner({ userId })}>{userId}</Button>
          ))}
          {selectedUserId && <FilteredDashboardContainer key={selectedUserId} dashboardId={dashboardId} filters={filters} />}
      </div>
    );
  }
}

export default ASDashboard;
