import React from 'react';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import { enhance } from 'sweetform';
import i18n from 'i18next';
import _ from 'underscore';

const textToId = (baseText) =>
  baseText
    .toLowerCase()
    .replace(/[^a-z0-9- ]/g, '_')
    .replace(/ /g, '-');

const Textarea = enhance(
  ({ placeholder, setValue, value, field, ...props }) => (
    <textarea
      {...props}
      placeholder={placeholder}
      value={value || ''}
      onChange={(event) => setValue(event.target.value)}
    />
  ),
);

const getColorFromScrapDate = (date) =>
  !date
    ? null
    : moment()
        .subtract(3, 'days')
        .isSameOrBefore(moment(date, 'YY-MM-DD'))
    ? 'green'
    : moment()
        .subtract(7, 'days')
        .isSameOrBefore(moment(date, 'YY-MM-DD'))
    ? 'olive'
    : moment()
        .subtract(15, 'days')
        .isSameOrBefore(moment(date, 'YY-MM-DD'))
    ? 'yellow'
    : moment()
        .subtract(30, 'days')
        .isSameOrBefore(moment(date, 'YY-MM-DD'))
    ? 'orange'
    : 'red';

const getFullUrlWithHttp = (url) =>
  url.match(/^(http|https):\/\//i) ? url : `http://${url}`;

const removeSpacesAndDash = (str) =>
  str
    ? str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace('-', '')
        .replace(' ', '')
    : null;

const getTagColor = (hint, enrichedText) =>
  enrichedText
    ? !hint ||
      (hint &&
        _.some(enrichedText.entities, ({ hints }) =>
          _.findWhere(hints, { id: hint }),
        ))
      ? getSkillsColor(getEntitiesIds(enrichedText))
      : undefined
    : undefined;

const getEntitiesIds = ({ content, entities }) => {
  const directSkillIds = _.compact(
    _.map(entities, ({ relevance, id }) => (relevance > 20 ? id : null)),
  );
  const childSkillIds = _.isArray(content)
    ? _.reduce(content, (memo, part) => _.union(getEntitiesIds(part), memo))
    : [];
  return _.union(directSkillIds, childSkillIds);
};

const getSkillsColor = (skills) => {
  if (!_.isArray(skills)) {
    return null;
  }
  return '#6bffb6';
  // console.log('skills', skills)
  // for (let i = 0; i < skills.length; i++) {
  //   if (isSkillInColorsList(skills[i])) {
  //     return getJobPositionColor(skillsToJobPosition[skills[i]]);
  //   }
  // }
  // if (!_.isEmpty(skills)) {
  //   // console.log('skills without color', skills)
  // }
  // return '#e8e8e8'
};

const getGradColor = (val) => {
  // if (!val || val < 20) {
  //   return '#e8e8e8';
  // } else if (val < 40) {
  //   return '#8ef0e7';
  // } else if (val < 60) {
  //   return '#0efde4';
  // } else if (val < 80) {
  //   return '#15ff8c';
  // }
  // return '#00ff00';
};

// const isSkillInColorsList = skill => (
//   !!skillsToJobPosition[skill]
// )

const getGradSkillColor = (skillId, val) => {
  if (val === 0) {
    return '#e8e8e8';
  } else {
    return val < 30 ? '#b3fbd8' : val < 60 ? '#8bfbc4' : '#6bffb6';
  }
  // else if (isSkillInColorsList(skillId)) {
  //   return getJobPositionColor(skillsToJobPosition[skillId]);
  // } else {
  //   // console.log('skill not found in skills list', skillId)
  //   return '#15ff8c';
  // }
};

const isSummarySuspicious = (summary) => {
  if (!summary || !_.isString(summary)) {
    return false;
  }
  const normalizedSummary = summary.trim();
  const lines = normalizedSummary.split('\n');
  let isSuspicious = false;
  _.each(lines, (line) => {
    if (line.indexOf('      ') === 0) {
      isSuspicious = true;
    }
  });
  if (summary.length > 3 && summary.substr(summary.length - 3) === '...') {
    isSuspicious = true;
  }
  const trimmedSummary = summary.trim().toLowerCase();
  const antiPatterns = [
    'see more',
    'see more.',
    'see less',
    'see less.',
    'voir plus',
    'voir plus.',
    'voir moins',
    'voir moins.',
  ];
  _.each(antiPatterns, (pattern) => {
    if (trimmedSummary.length > pattern.length && trimmedSummary.substr(trimmedSummary.length - pattern.length) === pattern) {
      isSuspicious = true;
    }
  });

  return isSuspicious;
};

const permissionsDependencies = {
  admin: ['user-management', 'advanced-tech', 'advanced-ops'],
  'advanced-ops': [
    'basic-ops',
    'actions',
    'workplaces',
    'flowboard',
    'autoselect',
    'sheetsboard',
    'sweetsheets',
    'flowboard-dashboard',
    'actions',
    'sweetapp',
    'sweetapp-stats',
    'sweetapp-actions',
    'sweetapp-external-actions',
    'sweetapp-dashboard',
    // 'sweetapp-clients'
  ],
  ops: ['basic-ops', 'sweetapp', 'sweetapp-actions'],
  'basic-ops': ['offers', 'sweetsheets'],
  'advanced-tech': ['basic-tech', 'knowledge', 'scrap', 'codingame'],
  'basic-tech': [
    'monitoring',
    'sweetsearch',
    'outliers',
    'workplaces',
    'profiles',
    'actions',
  ],
};

const getAllPermissions = (permissions) =>
  _.uniq(
    _.flatten(
      _.map(
        _.isArray(permissions) ? permissions : [permissions],
        (permission) =>
          permissionsDependencies[permission]
            ? [
                permission,
                getAllPermissions(permissionsDependencies[permission]),
              ]
            : permission,
      ),
    ),
  );

const allPermissions = getAllPermissions(_.keys(permissionsDependencies));

const getRawMarkup = (content) => ({
  __html: (content || '').replace(/\n/g, '<br>'),
});

class SingleClickButton extends React.Component {
  state = { enabled: true };
  enabled = true;
  render() {
    const props = {
      ...this.props,
      ...(!this.state.enabled) && { disabled: true },
      onClick: () => {
        if (!this.enabled) {
          return;
        }
        this.enabled = false;
        this.setState({ enabled: false });
        this.props.onClick();
      },
    }
    return (
      <Button {...props} />
    );
  }
}

class DoubleClickButton extends React.Component {
  state = { enabled: true, firstClick:false };
  enabled = true;
  render() {
    const props = {
      ...this.props,
      ...(!this.state.enabled) && { disabled: true },
      onClick: () => {
        if (!this.enabled || !this.state.firstClick) {
          this.setState({ firstClick: true });
          return;
        }
        this.enabled = false;
        this.setState({ enabled: false });
        this.props.onClick();
      },
    }
    return (
      <Button {...props} />
    );
  }
}

class DoubleClickIcon extends React.Component {
  state = { enabled: true, firstClick:false };
  enabled = true;
  render() {
    const props = {
      ...this.props,
      ...(!this.state.enabled) && { disabled: true },
      onClick: () => {
        if (!this.enabled || !this.state.firstClick) {
          this.setState({ firstClick: true });
          return;
        }
        this.enabled = false;
        this.setState({ enabled: false });
        this.props.onClick();
      },
    }
    return (
      <Icon {...props} />
    );
  }
}

const getTranslatedText = (translatableText) => {
  const { language } = i18n;
  const lang = language ? language.split('-')[0] : null;
  const text = translatableText || {};
  return (lang && text[lang]) || text.default || '';
};

export default {};
export {
  textToId,
  Textarea,
  SingleClickButton,
  DoubleClickButton,
  DoubleClickIcon,
  getColorFromScrapDate,
  getFullUrlWithHttp,
  removeSpacesAndDash,
  getTagColor,
  getGradColor,
  isSummarySuspicious,
  permissionsDependencies,
  getAllPermissions,
  allPermissions,
  getGradSkillColor,
  getRawMarkup,
  getTranslatedText,
};
