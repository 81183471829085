import React from 'react';
import _ from 'underscore';
import { Form, Dropdown } from 'semantic-ui-react';


class SimilarProfilesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addedOptions: [], availableSkills: [] };
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSkillChange = this.handleSkillChange.bind(this);
    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleMatchAnywhereChange = this.handleMatchAnywhereChange.bind(this);
  }

  handleLocationChange(e, data) {
    this.props.onSettingsChange({
      locations: data.value,
      target_skills: this.props.settings.target_skills,
      target_jobs: this.props.settings.target_jobs,
      match_anywhere: this.props.settings.match_anywhere,
    });
  }

  handleSkillChange(e, data) {
    this.props.onSettingsChange({
      locations: this.props.settings.locations,
      target_skills: data.value,
      target_jobs: this.props.settings.target_jobs,
      match_anywhere: this.props.settings.match_anywhere,
    });
  }

  handleJobChange(e, data) {
    this.props.onSettingsChange({
      locations: this.props.settings.locations,
      target_skills: this.props.settings.target_skills,
      target_jobs: data.value,
      match_anywhere: this.props.settings.match_anywhere,
    });
  }

  handleMatchAnywhereChange(e, data) {
    this.props.onSettingsChange({
      locations: this.props.settings.locations,
      target_skills: this.props.settings.target_skills,
      target_jobs: this.props.settings.target_jobs,
      match_anywhere: data.value,
    });
  }

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      addedOptions: [{ text: value, value }, ...prevState.addedOptions],
    }));
  };

  render() {
    const settings = this.props.settings || { locations: [], target_skills: [], target_jobs: [] };
    const options_locations = this.state.addedOptions.concat(
      _.map(settings.locations, (loc) => ({
        key: loc,
        value: loc,
        text: loc,
      })),
    );

    const options_patterns = this.state.addedOptions.concat(
      _.map(settings.match_anywhere, (pattern) => ({
        key: pattern,
        value: pattern,
        text: pattern,
      })),
    );
    return (
      <Form>
        <Form.Field>
          <label>Locations</label>
          <Dropdown
            fluid
            search
            selection
            multiple
            allowAdditions
            placeholder="Select locations"
            value={settings.locations || []}
            onChange={this.handleLocationChange}
            onAddItem={this.handleAddition}
            options={options_locations}
          />
        </Form.Field>

        <Form.Field>
          <label>Target skills</label>
          <Dropdown
            fluid
            search
            selection
            multiple
            placeholder="Select skills"
            value={settings.target_skills || []}
            onChange={this.handleSkillChange}
            options={this.props.options.skills || []}
          />
        </Form.Field>

        <Form.Field>
          <label>Target jobs</label>
          <Dropdown
            fluid
            search
            selection
            multiple
            placeholder="Select jobs"
            value={settings.target_jobs || []}
            onChange={this.handleJobChange}
            options={this.props.options.jobs || []}
          />
        </Form.Field>

        <Form.Field>
          <label>Match anywhere</label>
          <Dropdown
            fluid
            search
            selection
            multiple
            allowAdditions
            placeholder="Select pattern"
            value={settings.match_anywhere || []}
            onChange={this.handleMatchAnywhereChange}
            onAddItem={this.handleAddition}
            options={options_patterns}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default SimilarProfilesSettings;
