import { Breadcrumb, Card, Grid, Tab } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';

import GenericMarkdownView from '../GenericViews/GenericMarkdownView';
import GenericSweethubView from '../GenericViews/GenericSweethubView';
import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import baseUrl from '../../baseUrl';

class ExplorerRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/assets-explorer/explorer'>
          {({ location, history }) => (
            <AssetsExplorerDashboard
              pathname={(location || {}).pathname + ((location || {}).search || '?path=main')}
              history={history}
            />
          )}
        </Route>
      </Switch>
    );
  }
}

class AssetsExplorerDashboard extends React.Component {
  state = {};

  componentDidMount() {
    const { pathname } = this.props;
    if (pathname.indexOf('&pageId=') >= 0) {
      this.setState({
        pathname: pathname.split('&pageId=')[0],
        pageId: pathname.split('&pageId=').slice(-1)[0],
      });
    }
    const widgetId =
      pathname.indexOf(';') >= 0
        ? pathname.indexOf('&') >= 0
          ? pathname
              .split('&')[0]
              .split(';')
              .slice(-1)[0]
          : pathname.split(';').slice(-1)[0]
        : 'main';
    this.setState({ widgetId, pathname });
    this.loadWidgetIdToWidget();
  }

  async loadWidgetIdToWidget() {
    const loadedwidgetIdToWidget = {};
    const url = baseUrl + '/assetsExplorer/widgets';
    const { data } = await axios.get(url);
    if (data.error) {
      return alert(data.error);
    }
    _.map(data, function(widget) {
      loadedwidgetIdToWidget[widget.id] = widget;
    });
    this.setState({ widgetIdToWidget: loadedwidgetIdToWidget });
  }

  handleCardClick(childId) {
    const pathname = `${this.state.pathname};${childId}`;
    this.props.history.push(pathname);
    this.setState({ widgetId: childId, pathname });
  }

  handleBreadcrumbClick(idx) {
    const pathname = this.state.pathname
      .split(';')
      .slice(0, idx + 1)
      .join(';');
    const widgetId = pathname.indexOf(';') >= 0 ? pathname.split(';').slice(-1)[0] : 'main';
    this.props.history.push(pathname);
    this.setState({ pathname, widgetId });
  }

  render() {
    const widget = (this.state.widgetIdToWidget || {})[this.state.widgetId] || {};
    const pathLength = (this.state.pathname || '').indexOf(';') >= 0 ? this.state.pathname.split(';').length : 1;
    const defaultActiveIndex =
      _.map(widget.panes, (pane) => pane.pageId).indexOf(this.state.pageId) >= 0
        ? _.map(widget.panes, (pane) => pane.pageId).indexOf(this.state.pageId)
        : 0;

    return (
      <Grid>
        {this.state.pathname && !_.isEmpty(this.state.widgetIdToWidget || {}) && (
          <Grid.Row>
            <Breadcrumb size='big'>
              {_.map(
                this.state.pathname.indexOf(';') >= 0 ? this.state.pathname.split('?path=')[1].split(';') : ['main'],
                (parentId, idx) =>
                  idx === pathLength - 1 ? (
                    <Breadcrumb.Section active>{widget.title}</Breadcrumb.Section>
                  ) : (
                    <span key={idx}>
                      <Breadcrumb.Section
                        link
                        onClick={() => {
                          this.handleBreadcrumbClick(idx);
                        }}
                      >
                        {this.state.widgetIdToWidget[parentId].title}
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon='right chevron' />
                    </span>
                  ),
              )}
            </Breadcrumb>
          </Grid.Row>
        )}
        <Grid.Column width={16}>
          {widget.type === 'navigation' &&
            _.map(widget.childrenIds, (childId) => (
              <Card fluid onClick={() => this.handleCardClick(childId)}>
                <Card.Content>
                  <Card.Header>
                    {(this.state.widgetIdToWidget[childId] || {}).title || 'Child does not exist'}
                  </Card.Header>
                  <Card.Meta>
                    <span>{childId}</span>
                  </Card.Meta>
                </Card.Content>
              </Card>
            ))}
          {widget.type === 'dashboard' && (
            <Tab
              panes={_.map(widget.panes, (pane) => ({
                menuItem: pane.title,
                render: () => (
                  <Tab.Pane>
                    <AssetsExplorerPage page={this.state.widgetIdToWidget[pane.pageId]} />
                  </Tab.Pane>
                ),
              }))}
              defaultActiveIndex={defaultActiveIndex}
              onTabChange={(e, data) => {
                const pane = widget.panes[data.activeIndex];
                this.props.history.push(`${this.state.pathname.split('&pageId=')[0]}&pageId=${pane.pageId}`);
              }}
              menu={{ fluid: true, vertical: false }}
            />
          )}
          {!(widget || {}).type && <div>Loading...</div>}
        </Grid.Column>
      </Grid>
    );
  }
}

class AssetsExplorerPage extends React.Component {
  render() {
    const page = this.props.page;
    if (!page || !page.content || !page.content.type) {
      return <div>Error: no page content</div>;
    }
    if (page.content.type !== 'view') {
      return <div>Error: only type view is supported</div>;
    }
    return <MetaView viewId={page.content.viewId} params={page.content.params} />;
  }
}

class MetaView extends React.Component {
  render() {
    const { viewId, params } = this.props;
    if (viewId === 'markdown') {
      return <GenericMarkdownView {...params} />;
    }
    if (viewId === 'sweethubView') {
      return <GenericSweethubView {...params} />;
    }
    return <div>No view with id {viewId}</div>;
  }
}

export default ExplorerRouter;
