import _, { extend } from 'underscore';
import React, { Component } from 'react';
import baseUrl from '../baseUrl.js';
import axios from 'axios';
import { SweetForm, Select, Input, Checkbox } from 'sweetform';

import { Label, LabelGroup, Button, Form, Icon, Modal } from 'semantic-ui-react';
import { Grid } from 'semantic-ui-react';

const mapImportanceToColor = (importance) => {
  const dict_ = {
    strong: '#3EF005',
    'exact-match': '#3EF005',
    'similar-safe': '#67B603',
    'similar-ambiguous': '#917C02',
    medium: '#67B603',
    weak: '#917C02',
    opposed: 'red',
    different: 'red',
  };
  return dict_[importance] || 'grey';
};

const flipImportance = (importance) => {
  const dict_ = {
    strong: 'medium',
    medium: 'weak',
    weak: 'opposed',
    opposed: 'strong',
  };
  return dict_[importance] || 'strong';
};

const flipRelation = (relation) => {
  const dict_ = {
    'exact-match': 'similar-safe',
    'similar-safe': 'similar-ambiguous',
    'similar-ambiguous': 'different',
    different: 'exact-match',
  };
  return dict_[relation] || 'exact-match';
};

const relationOptions = [
  {
    value: 'exact-match',
    label: 'Exact Match',
  },
  {
    value: 'similar-safe',
    label: 'Similar Safe',
  },
  {
    value: 'similar-ambiguous',
    label: 'Similar Ambiguous',
  },
  {
    value: 'different',
    label: 'Different',
  },
];

const importanceOptions = [
  {
    value: 'strong',
    label: 'Strong',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'weak',
    label: 'Weak',
  },
];

const scoreOptions = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

const clusterOptions = [
  { label: 'Emergency and Fire Management Services', value: 'Emergency-and-Fire-Management-Services' },
  { label: 'Marketing Communications', value: 'Marketing-Communications' },
  { label: 'Revenue and Taxation', value: 'Revenue-and-Taxation' },
  { label: 'Food Products and Processing Systems', value: 'Food-Products-and-Processing-Systems' },
  { label: 'Family & Community Services', value: 'Family-and-Community-Services' },
  { label: 'Agribusiness Systems', value: 'Agribusiness-Systems' },
  { label: 'Power, Structural & Technical Systems', value: 'Power,-Structural-and-Technical-Systems' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Web and Digital Communications', value: 'Web-and-Digital-Communications' },
  { label: 'Legal Services', value: 'Legal-Services' },
  { label: 'Environmental Service Systems', value: 'Environmental-Service-Systems' },
  { label: 'Law Enforcement Services', value: 'Law-Enforcement-Services' },
  { label: 'Securities & Investments', value: 'Securities-and-Investments' },
  { label: 'Animal Services', value: 'Animal-Services' },
  { label: 'Professional Support Services', value: 'Professional-Support-Services' },
  { label: 'Public Management and Administration', value: 'Public-Management-and-Administration' },
  { label: 'Physics', value: 'Physics' },
  { label: 'Marketing Management', value: 'Marketing-Management' },
  { label: 'Information Support and Services', value: 'Information-Support-and-Services' },
  { label: 'Maintenance/Operations', value: 'Maintenance/Operations' },
  { label: 'Facility and Mobile Equipment Maintenance', value: 'Facility-and-Mobile-Equipment-Maintenance' },
  { label: 'Banking Services', value: 'Banking-Services' },
  { label: 'Restaurants and Food/Beverage Services', value: 'Restaurants-and-Food/Beverage-Services' },
  { label: 'Support Services', value: 'Support-Services' },
  { label: 'Architecture', value: 'Architecture' },
  { label: 'Therapeutic Services', value: 'Therapeutic-Services' },
  { label: 'Audit', value: 'Audit' },
  { label: 'Creative Content Edition', value: 'Creative-Content-Edition' },
  { label: 'Administrative Support', value: 'Administrative-Support' },
  { label: 'Plant Systems', value: 'Plant-Systems' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Engineering and Technology', value: 'Engineering-and-Technology' },
  { label: 'Professional Sales', value: 'Professional-Sales' },
  { label: 'Programming and Software Development', value: 'Programming-and-Software-Development' },
  { label: 'Recreation, Amusements & Attractions', value: 'Recreation,-Amusements-and-Attractions' },
  { label: 'Lodging', value: 'Lodging' },
  { label: 'Travel & Tourism', value: 'Travel-and-Tourism' },
  { label: 'Electrical Engineering', value: 'Electrical-Engineering' },
  { label: 'Business Finance', value: 'Business-Finance' },
  { label: 'Information Technology', value: 'Information-Technology' },
  { label: 'Accounting', value: 'Accounting' },
  { label: 'Mechanical Engineering', value: 'Mechanical-Engineering' },
  { label: 'Sales and Service', value: 'Sales-and-Service' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Operations Management', value: 'Operations-Management' },
  { label: 'Network Systems', value: 'Network-Systems' },
  { label: 'Personal Care Services', value: 'Personal-Care-Services' },
  { label: 'General Management', value: 'General-Management' },
  { label: 'Business Development', value: 'Business-Development' },
  { label: 'Merchandising', value: 'Merchandising' },
  { label: 'Production', value: 'Production' },
  { label: 'Quality Assurance', value: 'Quality-Assurance' },
  { label: 'Natural Resources Systems', value: 'Natural-Resources-Systems' },
  { label: 'Maintenance, Installation & Repair', value: 'Maintenance,-Installation-and-Repair' },
  { label: 'Chemistry', value: 'Chemistry' },
  { label: 'Diagnostic Services', value: 'Diagnostic-Services' },
  { label: 'Human Resources', value: 'Human-Resources' },
  { label: 'Logistics Planning and Management Services', value: 'Logistics-Planning-and-Management-Services' },
  { label: 'Security & Protective Services', value: 'Security-and-Protective-Services' },
  { label: 'Planning', value: 'Planning' },
  { label: 'Actuary', value: 'Actuary' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Teaching/Training', value: 'Teaching/Training' },
  { label: 'Biotechnology', value: 'Biotechnology' },
  { label: 'Scientific Research', value: 'Scientific-Research' },
  { label: 'National Security', value: 'National-Security' },
  { label: 'Journalism and Broadcasting', value: 'Journalism-and-Broadcasting' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Governance', value: 'Governance' },
  { label: 'Regulation', value: 'Regulation' },
  { label: 'Visual Arts', value: 'Visual-Arts' },
  { label: 'Consumer Services', value: 'Consumer-Services' },
  { label: 'Performing Arts', value: 'Performing-Arts' },
];

const typesOptions = [
  { value: 'skill', label: 'Skill' },
  { value: 'education-field', label: 'Education Field' },
  { value: 'industry', label: 'Industry' },
  { value: 'job', label: 'Job' },
  { value: 'study-level', label: 'Study Level' },
  { value: 'job-modality', label: 'Job Modality' },
  { value: 'job-responsibility', label: 'Job Responsibility' },
  { value: 'hierarchical-rank', label: 'Hierarchical Rank' },
  { value: 'job-seniority', label: 'Job Seniority' },
  { value: 'keyword', label: 'Key Word' },
];

const tagsOptions = [
  {
    value: 'python',
    label: 'Python',
  },
  {
    value: 'javascript',
    label: 'Javascript',
  },
];

const generateId = () => {
  var letters = '0123456789AZERTYUIOPQSDFGHJKLMWXCVBN';
  var id = '';
  for (var i = 0; i < 15; i++) {
    id += letters[Math.floor(Math.random() * 30)];
  }
  return id;
};

class AddTagModal extends Component {
  onChange = (params) => {
    this.setState({ ...params });
  };

  render() {
    const { onClose, onSubmit } = this.props;
    return (
      <Modal onClose={onClose} open size='small'>
        <Modal.Content>
          <SweetForm onChange={this.onChange}>
            <Select field='id' options={tagsOptions} />
            <Select field='importance' options={importanceOptions} />
          </SweetForm>
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={() => onSubmit(this.state)}>
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class AddPatternModal extends Component {
  onChange = (params) => {
    this.setState({ ...params });
  };

  render() {
    const { onClose, onSubmit } = this.props;
    return (
      <Modal onClose={onClose} open size='small'>
        <Modal.Content>
          <SweetForm onChange={this.onChange} initialValues={{ ...(this.props.selectedPattern || {}) }}>
            <Input field='text' autoFocus />
            <Select field='relation' options={relationOptions} />
          </SweetForm>
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() =>
              onSubmit({ ...(this.state || {}), id: (this.props.selectedPattern || {}).id || generateId() })
            }
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class ItemLabelizationModal extends Component {
  componentDidMount() {
    const { text } = this.props;
    this.loadData(text);
  }

  loadData = async (text) => {
    const { isLoading } = this.state || {};
    if (isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/knowledgeIngestion/getSuggestions';
    const payload = {
      text: text,
    };
    const data = (await axios.post(url, payload, customHeaders)).data;
    this.setState({ ...data, isLoading: false });
  };

  handleChange = async (params) => {
    this.setState({ ...params });
  };

  deleteEntityThatImply = (relatedEntity) => {
    const { entitiesThatImply } = this.state || {};
    const filteredEntities = _.filter(entitiesThatImply, (entity) => {
      return entity !== relatedEntity;
    });
    this.setState({
      entitiesThatImply: filteredEntities,
    });
  };

  onClickOnEntityThatImply = (relatedEntity) => {
    const { entitiesThatImply } = this.state || {};
    const newEntities = _.map(entitiesThatImply, (entity) => {
      return entity !== relatedEntity ? entity : { ...entity, importance: flipImportance(entity.importance) };
    });
    this.setState({
      entitiesThatImply: newEntities,
    });
  };

  deleteImpliedEntity = (relatedEntity) => {
    const { impliedEntities } = this.state || {};
    const filteredEntities = _.filter(impliedEntities, (entity) => {
      return entity !== relatedEntity;
    });
    this.setState({
      impliedEntities: filteredEntities,
    });
  };

  onClickOnImpliedEntity = (relatedEntity) => {
    const { impliedEntities } = this.state || {};
    const newEntities = _.map(impliedEntities, (entity) => {
      return entity !== relatedEntity ? entity : { ...entity, importance: flipImportance(entity.importance) };
    });
    this.setState({
      impliedEntities: newEntities,
    });
  };

  onChangePatternRelation = (targetPattern) => {
    const { extendedPatterns } = this.state || {};
    const newPatterns = _.map(extendedPatterns, (pattern) => {
      return pattern !== targetPattern ? pattern : { ...pattern, relation: flipRelation(pattern.relation) };
    });
    this.setState({
      extendedPatterns: newPatterns,
    });
  };

  deleteExtendedPattern = (pattern) => {
    const { extendedPatterns } = this.state || {};
    const filteredRelatedPatterns = _.filter(extendedPatterns, (entity) => {
      return entity !== pattern;
    });
    this.setState({
      extendedPatterns: filteredRelatedPatterns,
    });
  };

  onClickOnPattern = (targetPattern) => {
    this.setState({
      selectedPattern: targetPattern,
      AddPatternModalVisible: true,
    });
  };

  onSubmitAddPattern = (newPattern) => {
    const { extendedPatterns } = this.state || {};
    const newExtendedPatterns = _.map(extendedPatterns, (pattern) => {
      return pattern.id !== newPattern.id ? pattern : newPattern;
    });
    if (!_.find(extendedPatterns, (pattern) => pattern.id == newPattern.id)) {
      newExtendedPatterns.push(newPattern);
    }
    this.setState({
      extendedPatterns: newExtendedPatterns,
      selectedPattern: null,
      AddPatternModalVisible: false,
    });
  };

  onSubmitAddTag = (params) => {
    const { entityThatImplyModalVisible } = this.state || {};
    if (!(params || {}).id || !(params || {}).importance) {
      return;
    }
    if (entityThatImplyModalVisible) {
      const newEntities = this.state.entitiesThatImply || [];
      newEntities.push(params);
      this.setState({
        entityThatImplyModalVisible: false,
        impliedEntityModalVisible: false,
        entitiesThatImply: newEntities,
      });
    } else {
      const newEntities = this.state.impliedEntities || [];
      newEntities.push(params);
      this.setState({
        entityThatImplyModalVisible: false,
        impliedEntityModalVisible: false,
        impliedEntities: newEntities,
      });
    }
  };

  render() {
    const { text, onSubmit, onSkip } = this.props;
    const { impliedEntities, entitiesThatImply, extendedPatterns, selectedPattern, isLoading } = this.state || {};
    const { entityThatImplyModalVisible, impliedEntityModalVisible, AddPatternModalVisible } = this.state || {};
    const onClose = () => {
      this.setState({
        entityThatImplyModalVisible: false,
        impliedEntityModalVisible: false,
        AddPatternModalVisible: false,
        selectedPattern: null,
      });
    };
    return (
      <div>
        {entityThatImplyModalVisible || impliedEntityModalVisible ? (
          <AddTagModal onClose={onClose} onSubmit={this.onSubmitAddTag} />
        ) : null}
        {AddPatternModalVisible ? (
          <AddPatternModal onClose={onClose} onSubmit={this.onSubmitAddPattern} selectedPattern={selectedPattern} />
        ) : null}
        <SweetForm
          key={Math.random()}
          initialValues={{ text: text, ...(this.state || {}) }}
          onChange={(params) => this.handleChange(params)}
        >
          <Form.Field style={{ width: '50%', height: '100%' }}>
            <h3>New Pattern :</h3>
            <Grid columns={3}>
              <Grid.Column>
                <Input field='text' autoFocus />
              </Grid.Column>
              <Grid.Column>
                <a
                  style={{ cursor: 'pointer', color: isLoading ? 'grey' : 'orange' }}
                  onClick={() => this.loadData((this.state || {}).text || this.props.text)}
                >
                  <b>
                    <Icon name='undo' /> Refresh Suggestions
                  </b>
                </a>
              </Grid.Column>
              <Grid.Column>
                <Checkbox slider field='isAmbiguous' label='Is Ambiguous' />
              </Grid.Column>
            </Grid>
          </Form.Field>
          <Form.Field style={{ width: '50%', height: '100%' }}>
            {' '}
            <h3>Recommended Pattern Extension :</h3>
            <LabelGroup>
              {_.map(extendedPatterns, (pattern, index) => {
                return (
                  <Label
                    key={index}
                    style={{
                      backgroundColor: mapImportanceToColor(pattern.relation),
                    }}
                  >
                    <a
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => this.deleteExtendedPattern(pattern)}
                    >
                      <Icon name='close' />
                    </a>{' '}
                    <a
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => this.onChangePatternRelation(pattern)}
                    >
                      {pattern.text}
                    </a>
                    <a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.onClickOnPattern(pattern)}>
                      {'  '}
                      <Icon name='edit' />
                    </a>
                  </Label>
                );
              })}
              <a
                style={{ cursor: 'pointer', color: 'black' }}
                onClick={() => this.setState({ AddPatternModalVisible: true })}
              >
                <Label> + Add </Label>
              </a>
            </LabelGroup>
          </Form.Field>
          <Grid columns={3} style={{ width: '50%' }}>
            <Grid.Column>
              <Form.Field style={{ width: '100%', height: '100%' }}>
                <h3>Clusters :</h3>

                <Select multi fluid field='cluster' label='Cluster' options={clusterOptions} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field style={{ width: '100%', height: '100%' }}>
                <h3>HR Importance :</h3>

                <Select fluid field='hrScore' label='HRScore' options={scoreOptions} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field style={{ width: '100%', height: '100%' }}>
                <h3>Types :</h3>

                <Select multi fluid field='types' label='Types' options={typesOptions} />
              </Form.Field>
            </Grid.Column>
          </Grid>
          <Form.Field>
            <h3>Entities implied by {text}:</h3>

            <LabelGroup>
              {_.map(impliedEntities, (relatedEntity, index) => {
                return (
                  <Label
                    key={index}
                    style={{
                      backgroundColor: mapImportanceToColor(relatedEntity.importance),
                    }}
                  >
                    <a
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => this.deleteImpliedEntity(relatedEntity)}
                    >
                      <Icon name='close' />
                    </a>{' '}
                    <a
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => this.onClickOnImpliedEntity(relatedEntity)}
                    >
                      {relatedEntity.id}
                    </a>
                  </Label>
                );
              })}
              <a
                style={{ cursor: 'pointer', color: 'black' }}
                onClick={() => this.setState({ impliedEntityModalVisible: true })}
              >
                <Label> + Add </Label>
              </a>
            </LabelGroup>
          </Form.Field>
          <Form.Field>
            <h3>Entities that imply {text}:</h3>

            <LabelGroup>
              {_.map(entitiesThatImply, (relatedEntity, index) => {
                return (
                  <Label
                    key={index}
                    style={{
                      backgroundColor: mapImportanceToColor(relatedEntity.importance),
                    }}
                  >
                    <a
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => this.deleteEntityThatImply(relatedEntity)}
                    >
                      <Icon name='close' />
                    </a>{' '}
                    <a
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => this.onClickOnEntityThatImply(relatedEntity)}
                    >
                      {relatedEntity.id}
                    </a>
                  </Label>
                );
              })}
              <a
                style={{ cursor: 'pointer', color: 'black' }}
                onClick={() => this.setState({ entityThatImplyModalVisible: true })}
              >
                <Label> + Add </Label>
              </a>
            </LabelGroup>
          </Form.Field>

          <Button color='green' onClick={() => onSubmit({ id: this.props.id, ...(this.state || {}) })}>
            Submit
          </Button>
          <Button color='orange' onClick={() => onSkip({ id: this.props.id })}>
            Skip
          </Button>
        </SweetForm>
      </div>
    );
  }
}

export default ItemLabelizationModal;

/*

          */
