import React from 'react';
import { Grid } from 'semantic-ui-react';
import enhance from './enhance';
import Select from './Select';
import SelectInt from './SelectInt';
import Options from './Options';
var longMonths = [0, 2, 4, 6, 7, 9, 11];

var numberOfDays = function numberOfDays(date) {
  var year = date.get('year');
  var month = date.get('month');

  if (month === 1) {
    var leap = year % 400 === 0 || year % 4 === 0 && year % 100 > 0;
    return leap ? 29 : 28;
  }

  return longMonths.indexOf(month) > -1 ? 31 : 30;
};

var style = {
  marginRight: -30
};
var SelectDate = enhance(function (_ref) {
  var value = _ref.value;
  return React.createElement(Grid, {
    columns: 'equal',
    style: {
      paddingRight: 30
    }
  }, React.createElement(Grid.Column, {
    style: style
  }, React.createElement(SelectInt, {
    min: 1970,
    max: 2100,
    field: 'year'
  })), value && value.get('year') ? React.createElement(Grid.Column, {
    style: style
  }, React.createElement(Select, {
    options: Options.Months,
    field: 'month'
  })) : null, value && value.get('month') !== undefined && value.get('month') !== null ? React.createElement(Grid.Column, {
    style: style
  }, React.createElement(SelectInt, {
    min: 1,
    max: numberOfDays(value),
    clearable: true,
    field: 'day'
  })) : null);
});
export default SelectDate;