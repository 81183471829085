import React from 'react';
import {
  Statistic,
} from 'semantic-ui-react';
import moment from 'moment';

const ContactsTable = ({ lastContactDay, nextContactDay, lastUploadCompletionTimestamp, displayMode}) => (
  <Statistic.Group size="mini" style={{ paddingLeft: 10, paddingRight: 0 }}>
    <Statistic
      color={'green'}
      style={{
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 5,
        marginTop: 5,
      }}
    >
      <Statistic.Label>Last contact</Statistic.Label>
      { lastContactDay ? 
        <Statistic.Value>{moment(lastContactDay, 'YYYY-MM-DD').format('DD-MM-YY')}</Statistic.Value>
        :null}
    </Statistic>
    <Statistic
      color={'blue'}
      style={{
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 5,
        marginTop: 5,
      }}
    >
      <Statistic.Label>Next contact</Statistic.Label>
      {nextContactDay ? 
       <Statistic.Value>{moment(nextContactDay, 'YYYY-MM-DD').format('DD-MM-YY')}</Statistic.Value>
      :null}
    </Statistic>
      {displayMode=="upload" && (
        <Statistic
        color={'teal'}
        style={{
          marginLeft: 5,
          marginRight: 5,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <Statistic.Label>Uploads</Statistic.Label>
        {lastUploadCompletionTimestamp ? 
          <Statistic.Value>{moment(lastUploadCompletionTimestamp).format('DD-MM-YY')}</Statistic.Value>
        :null}
      </Statistic>
    )}
  </Statistic.Group>
);

export default ContactsTable;

