import _ from 'underscore';
import axios from 'axios';
import React, { Component } from 'react';
import { Dropdown, Table, Card } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

const getDays = () => {
  const date = new Date();
  const lastMidnightTimestamp = date.setHours(3, 0, 0, 0);
  let days = [];
  let nbDays = 200;
  const iWeekDay = date.getDay();
  for (let iDay = 0; iDay < nbDays; iDay++) {
    const timestamp = lastMidnightTimestamp - iDay * 24 * 3600 * 1000;
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    days.push({
      timestamp,
      date: new Date(timestamp).toISOString().slice(2, 10),
      weekDay: weekDays[(iWeekDay - (iDay % 7) + 7) % 7],
    });
  }
  return days;
};

class SyntheticFlow extends Component {
  days = getDays(200);
  state = {
    minDay: this.days[6],
    maxDay: this.days[0],
  };
  componentWillMount() {
    this.handleReloadQuery();
  }
  handleReloadQuery = async () => {
    this.setState({
      totalFlow: null,
      categoryFlows: null,
      offerFlows: null,
    });
    const query = {
      target: {
        type: 'sweetapp-send',
        minTimestamp: this.state.minDay.timestamp,
        maxTimestamp: this.state.maxDay.timestamp + 24 * 3600 * 1000,
      },
    };
    const { data } = await axios.post(
      `${baseUrl}/strat/synthetic/cashflow`,
      query,
    );
    this.setState(data);
  };
  renderDaySelector(key) {
    const options = _.map(this.days, (day, index) => ({
      key: index,
      text: day.weekDay + '. ' + day.date,
      value: day.date,
    }));

    const handleChange = (e, { value }) => {
      const day = _.findWhere(this.days, { date: value });
      this.setState(
        {
          [key]: day,
        },
        () => {
          this.handleReloadQuery();
        },
      );
    };

    return (
      <Dropdown
        onChange={handleChange}
        options={options}
        placeholder="Choose an option"
        selection
        value={this.state[key].date}
      />
    );
  }
  renderDaysSelector() {
    return (
      <div>
        <span>
          <b>Start</b>: {this.renderDaySelector('minDay')}{' '}
        </span>
        <span style={{ marginLeft: 20 }}>
          <b>End</b>: {this.renderDaySelector('maxDay')}
        </span>
      </div>
    );
  }
  renderTotalFlow() {
    const { minDay, maxDay, totalFlow } = this.state;
    if (!totalFlow) {
      return;
    }
    const { count, value } = totalFlow;
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Total Flow (from {minDay.date} morning to {maxDay.date} evening)
          </Card.Header>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Count</Table.HeaderCell>
              <Table.HeaderCell>MeanValue</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{count || 0}</Table.Cell>
                <Table.Cell>
                  {Math.round(((value || 0) / Math.max(count || 0, 1)) * 100) /
                    100}{' '}
                  €
                </Table.Cell>
                <Table.Cell>{Math.round(value || 0)} €</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  renderCategoryFlows() {
    const { categoryFlows } = this.state;
    if (!categoryFlows) {
      return;
    }
    const items = _.sortBy(categoryFlows, 'value').reverse();
    const totalCount = _.reduce(
      categoryFlows,
      (memo, { count }) => memo + (count || 0),
      0,
    );
    const totalValue = _.reduce(
      categoryFlows,
      (memo, { value }) => memo + (value || 0),
      0,
    );
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Category Flows</Card.Header>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Count</Table.HeaderCell>
              <Table.HeaderCell>BaseValue</Table.HeaderCell>
              <Table.HeaderCell>Percent Count</Table.HeaderCell>
              <Table.HeaderCell>Percent Value</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(
                items,
                ({ categoryId, id, title, count, baseValue, value }, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{categoryId}</Table.Cell>
                    <Table.Cell>{count || 0}</Table.Cell>
                    <Table.Cell>
                      {Math.round((baseValue || 0) * 100) / 100} €
                    </Table.Cell>
                    <Table.Cell>
                      {Math.round(
                        ((count || 0) / Math.max(totalCount, 1)) * 1000,
                      ) / 10}{' '}
                      %
                    </Table.Cell>
                    <Table.Cell>
                      {Math.round(
                        ((value || 0) / Math.max(totalValue, 1)) * 1000,
                      ) / 10}{' '}
                      %
                    </Table.Cell>
                    <Table.Cell>
                      {Math.round((value || 0) * 100) / 100} €
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  renderOfferFlows() {
    const { offerFlows } = this.state;
    if (!offerFlows) {
      return;
    }
    const items = _.sortBy(offerFlows, 'value').reverse();

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>Offer Flows</Card.Header>

          <Table>
            <Table.Header>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Sweethub Id</Table.HeaderCell>
              <Table.HeaderCell>Sweetapp App</Table.HeaderCell>
              <Table.HeaderCell>Count</Table.HeaderCell>
              <Table.HeaderCell>BaseValue</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_.map(
                items,
                (
                  {
                    categoryId,
                    offerId,
                    title,
                    sweetappOfferId,
                    count,
                    baseValue,
                    value,
                  },
                  index,
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>{categoryId}</Table.Cell>
                    <Table.Cell>
                      <a target="_blank" href={'/offers/' + offerId} rel='noopener noreferrer'>
                        {offerId}
                      </a>
                    </Table.Cell>
                    <Table.Cell>
                      <span> {sweetappOfferId}</span>
                    </Table.Cell>
                    <Table.Cell>{count || 0}</Table.Cell>
                    <Table.Cell>
                      {Math.round((baseValue || 0) * 100) / 100} €
                    </Table.Cell>
                    <Table.Cell>
                      {Math.round((value || 0) * 100) / 100} €
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }
  render() {
    return (
      <div>
        {this.renderDaysSelector()}
        {this.renderTotalFlow()}
        {this.renderCategoryFlows()}
        {this.renderOfferFlows()}
      </div>
    );
  }
}

export default SyntheticFlow;
