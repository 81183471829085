import React from 'react';
import _ from 'underscore';
import axios from 'axios';
import { Form, Search } from 'semantic-ui-react';

import baseUrl from '../../baseUrl.js';


export class WorkplaceSearch extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      sourceId: 'linkedin',
      id: '',
      searchLoading: false
    }

    this.handleIDChange = this.handleIDChange.bind(this);
    this.handleSourceIDChange = this.handleSourceIDChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.updateSearch = this.updateSearch.bind(this);

  }

  async updateSearch() {
    const response = await axios.get(
      `${baseUrl}/workplacePatterns/companies?sourceId=${
      this.state.sourceId
      }&name=${this.state.searchValue}`,
    );
    this.setState({
      searchResults: _.map(response.data, (c) => ({
        title: c.name,
        description: c.id,
      })),
    });
  }

  async handleIDChange(e) {
    await this.setState({ id: e.target.value });
    this.setState({ searchValue: "" });
    this.props.onClear();
  }

  async handleSourceIDChange(e, { value }) {
    await this.setState({ sourceId: value });
    this.setState({ searchValue: "" });
    this.props.onClear();
  }

  async handleSearchChange(e) {
    const value = e.target.value;
    this.props.onClear();
    await this.setState({ searchValue: value });
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    await this.setState({
      searchTimeout: setTimeout(async () => await this.updateSearch(), 100),
    });
  }

  async onCompanySelect(e, result) {
    await this.setState({
      id: result.result.description,
      searchValue: result.result.title,
    });
    this.props.onSelect({
      sourceId: this.state.sourceId,
      id: this.state.id
    });
  }

  render() {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Source</label>
            <Form.Select
              placeholder="Source ID"
              options={[
                { key: 'linkedin', text: 'LinkedIn', value: 'linkedin' },
                { key: 'hiresweet', text: 'Hiresweet', value: 'hiresweet' },
              ]}
              onChange={this.handleSourceIDChange}
              value={this.state.sourceId}
            />
          </Form.Field>
          <Form.Field>
            <label>Search for a company name</label>
            <Search
              selectFirstResult={true}
              onResultSelect={this.onCompanySelect}

              onSearchChange={this.handleSearchChange}
              results={this.state.searchResults}
              value={this.state.searchValue}
            />
          </Form.Field>
          <Form.Field>
            <label>Company ID</label>
            <Form.Input
              disabled
              onChange={this.handleIDChange}
              placeholder="Company ID"
              value={this.state.id}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
} 