import _ from 'underscore';
import React, { Component } from 'react';
import { Icon, Table, Loader } from 'semantic-ui-react';

const FormatValue = ({ value, path, color }) => {
  if (_.isArray(value)) {
    return (
      <span>
        {_.map(value, (subVal, index) => (
          <FormatValue key={index} path={path} color={color} value={subVal} />
        ))}
      </span>
    );
  }
  if (_.isString(value) || _.isNumber(value)) {
    return <span style={{ color: color || 'black' }}>{value} </span>;
  }
  if (_.isBoolean(value)) {
    return <span style={{ color: color || 'black' }}>{value ? 'True' : 'False'}</span>;
  }
  if (_.isObject(value) && (value.min || value.max)) {
    return (
      <span>
        <FormatValue path={path} color={color} value={value.min} />-
        <FormatValue path={path} color={color} value={value.max} />
      </span>
    );
  }
  if (_.isObject(value) && value.identifier) {
    return (
      <div
        style={{
          display: 'inline-block',
          marginRight: 3,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 3,
          background: color || 'silver',
          fontWeight: 'bold',
        }}
      >
        <FormatValue value={value.identifier} path={path} color='white' />
      </div>
    );
  }
  return <Icon color={color} name='close' />;
};

class KeyDiffRow extends Component {
  render() {
    const getValue = (obj, path) => {
      const parts = path.split('.');
      let result = obj;
      for (let iPart = 0; iPart < parts.length; iPart++) {
        result = (result || {})[parts[iPart]];
      }
      return result;
    };

    const formatPath = (path) => {
      if (path === 'skills.required') {
        return 'required skills';
      } else if (path === 'skills.important') {
        return 'important skills';
      } else if (path === 'skills.bonus') {
        return 'bonus skills';
      } else if (path === 'jobPositions.required') {
        return 'job position';
      } else if (path === 'education.important') {
        return 'education';
      } else if (path === 'experience.years') {
        return 'xp';
      } else if (path === 'languages.important') {
        return 'languages';
      } else {
        return path;
      }
    };

    const { path, oldCriteria1, oldCriteria2, newCriteria } = this.props;
    const oldValue1 = getValue(oldCriteria1, path);
    const oldValue2 = getValue(oldCriteria2, path);
    const newValue = getValue(newCriteria, path);

    return (
      <Table.Row>
        <Table.Cell>
          <span style={{ whiteSpace: 'no-wrap' }}>{formatPath(path)}</span>
        </Table.Cell>
        <Table.Cell>
          <FormatValue value={newValue} path={path} />
        </Table.Cell>
        <Table.Cell>
          <FormatValue value={oldValue1} path={path} />
        </Table.Cell>
        <Table.Cell>
          <FormatValue value={oldValue2} path={path} />
        </Table.Cell>
      </Table.Row>
    );
  }
}

class OfferThreeCriteria extends Component {
  render() {
    const { jobOfferId, oldCriteria1, oldCriteria2, newCriteria } = this.props;

    if (!oldCriteria1 && !oldCriteria2 && !newCriteria) {
      return (
        <center>
          <Loader />
        </center>
      );
    }

    const paths = [
      'jobPositions.required',
      'locations',
      'remote',
      'freelance',
      'education.important',
      'experience.years',
      'skills.required',
      'skills.important',
      'skills.bonus',
    ];

    const allKeys = _.difference(_.union(_.keys(oldCriteria1), _.keys(newCriteria)), [
      'skills',
      'jobPositions',
      'experience',
      'education',
      'languages',
    ]);

    const diffKeys = _.difference(allKeys, paths);

    return (
      <div style={{ position: 'relative' }}>
        <h1>{jobOfferId}</h1>
        {diffKeys.length > 0 && (
          <h2 style={{ color: 'orange' }}>Warning key(s) {diffKeys.join(', ')} not handled : contact Ismael</h2>
        )}
        <Table definition>
          <Table.Header>
            <Table.HeaderCell />
            <Table.HeaderCell>New</Table.HeaderCell>
            <Table.HeaderCell>Old 1</Table.HeaderCell>
            <Table.HeaderCell>Old 2</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {_.map(paths, (path) => (
              <KeyDiffRow
                key={path}
                path={path}
                oldCriteria1={oldCriteria1}
                oldCriteria2={oldCriteria2}
                newCriteria={newCriteria}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default OfferThreeCriteria;
