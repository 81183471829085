import React from 'react';
import axios from 'axios';
import _ from 'underscore';
import { Grid, Segment, Icon, Card, Header } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import profileDataManager from '../../SweetWork/profileData';
import { Experience as Experience_ } from '../../SweetWork/profileData';
import { Experience } from '../../SweetWork/containers/ProfileExperiences';
import Offer from '../../components/Offer';
import { LabelingButtons } from './../../Offers/WorkPipeInputComponents/LabelingButtons';
import { CriterionForm } from '../../DataPointSandbox/EditCriterionModal';
import EnrichedProfileView from './EnrichedProfileView';

const buildExperience = (experience, good) => {
  const xp = Experience_(experience);
  return (
    <Card>
      <Card.Content>
        <Icon name={good ? 'check circle' : 'stop circle'} color={good ? 'green' : 'red'} size='large' />
        <Experience
          isFirst={true}
          elementId={xp.getElementId()}
          title={xp.getTitle()}
          location={xp.getLocation()}
          description={xp.getDescription()}
          companyName={xp.getCompanyName()}
          companyWebsite={xp.getCompanyWebsite()}
          startDate={xp.getStartDate()}
          endDate={xp.getEndDate()}
          photoLink={xp.getPhotoLink()}
          stack={xp.getStack()}
          workplace={xp.getWorkplace()}
          onRemove={() => null}
        />
      </Card.Content>
    </Card>
  );
};

class DataPointExperiencesView extends React.Component {
  render() {
    const { dataExperience1a, dataExperience1b, dataExperience2, job } = this.props;
    return (
      <div>
        <Header>{job}</Header>
        <Grid style={{ padding: 0, margin: 0 }}>
          <Grid.Column width={8}>
            {buildExperience(dataExperience1a, true)}
            {buildExperience(dataExperience1b, false)}
          </Grid.Column>
          <Grid.Column width={8}>{buildExperience(dataExperience2, true)}</Grid.Column>
        </Grid>
      </div>
    );
  }
}

class DataPointProfileOfferView extends React.Component {
  annotate = (type) => async () => {
    try {
      await axios.put(
        `${baseUrl}/dataPointsSets/${this.props.dataPointsSetId}/dataPoint/${this.props.dataPointId}/label/${type}`,
      );
      alert('ok');
    } catch (e) {
      alert(e.message);
    }
  };
  render() {
    const { dataPointsSetId, dataPoint, offer, criterion, sourceProfiles, dataPointId, noLabel, toAnnotate, enrichedProfile } = this.props;
    const actionsSetDescriptorDefault = {
      type: 'labeling',
      actions: [
        {
          backgroundColor: 'green',
          name: 'A',
          targetState: 'A',
          icon: 'check',
        },
        {
          backgroundColor: 'olive',
          name: 'B',
          targetState: 'B',
        },
        {
          backgroundColor: 'yellow',
          name: 'C',
          targetState: 'C',
        },
        {
          backgroundColor: 'orange',
          name: 'D',
          targetState: 'D',
        },
        {
          backgroundColor: 'red',
          name: 'E',
          targetState: 'E',
        },
      ],
    };
    const actionsSetDescriptorElevenLevels = {
      type: 'labeling',
      actions: [
        {
          backgroundColor: 'green',
          name: 'A+',
          targetState: 'A+',
        },
        {
          backgroundColor: 'green',
          name: 'A',
          targetState: 'A',
        },
        {
          backgroundColor: 'olive',
          name: 'AB',
          targetState: 'AB',
        },
        {
          backgroundColor: 'olive',
          name: 'B',
          targetState: 'B',
        },
        {
          backgroundColor: 'yellow',
          name: 'BC',
          targetState: 'BC',
        },
        {
          backgroundColor: 'yellow',
          name: 'C',
          targetState: 'C',
        },
        {
          backgroundColor: 'orange',
          name: 'CD',
          targetState: 'CD',
        },
        {
          backgroundColor: 'orange',
          name: 'D',
          targetState: 'D',
        },
        {
          backgroundColor: 'red',
          name: 'DE',
          targetState: 'DE',
        },
        {
          backgroundColor: 'red',
          name: 'E',
          targetState: 'E',
        },
        {
          backgroundColor: 'red',
          name: 'E-',
          targetState: 'E-',
        },
      ],
    };

    return (
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: -15, right: 20 }}>
          <a target='__blank' href={`/ml/xg1poc?dataPointsSet=${dataPointsSetId}&dataPoint=${dataPointId}`}>
            <Icon.Group size='large'>
              <Icon inverted color='purple' name='gamepad' />
              <Icon corner name='close' />

            </Icon.Group>
          </a>
          </div>
          <div style={{ position: 'absolute', top: -15, right: -5 }}>

          <a href={`/ml/sandbox?label=${dataPoint.label}&dataPointsSet=${dataPointsSetId}&dataPoint=${dataPointId}`}>
            <Icon link size='large' inverted name='gamepad' color='blue' />
          </a>
        </div>
        <Grid style={{ padding: 0, margin: 0 }}>
          <Grid.Row>
            <Grid.Column width={9}>
              {!noLabel && (
                <Segment color='blue'>
                  <div>Label: {dataPoint.label}</div>
                  <div>Category: {dataPoint.category}</div>
                  <div>
                    Date:{' '}
                    {new Date(dataPoint.statificationTimestamp).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </div>
                </Segment>
              )}
              <EnrichedProfileView enrichedProfile={enrichedProfile} sourceProfiles={sourceProfiles}/>
              
            </Grid.Column>
            <Grid.Column width={7}>
              {criterion ? (
                <Segment color='blue'>
                  <CriterionForm onUpdate={() => {}} initialValues={(criterion || {}).formParams} />
                </Segment>
              ) : (
                <Offer offer={offer} />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {toAnnotate ? (
          <LabelingButtons
            disabled={false}
            annotate={this.annotate}
            actions={
              toAnnotate === 'elevenLevels'
                ? actionsSetDescriptorElevenLevels.actions
                : actionsSetDescriptorDefault.actions
            }
          />
        ) : null}
      </div>
    );
  }
}

class DataPointView extends React.Component {
  componentDidMount = async () => {
    const { dataPointsSetId, dataPointId } = this.props;
    const { data } = await axios(`${baseUrl}/dataPointsSets/${dataPointsSetId}/dataPoint/${dataPointId}`);
    const { displayMode } = data;
    if (displayMode == 'experiencesDisplay') {
      const { dataPoint, dataExperience1a, dataExperience1b, dataExperience2 } = data; // TODO
      this.setState({
        displayMode,
        dataPoint,
        dataExperience1a,
        dataExperience1b,
        dataExperience2,
      });
    } else {
      const { dataPoint, offer, criterion, sourceProfiles, enrichedProfile } = data;
      this.setState({
        //displayMode,
        dataPoint,
        offer,
        criterion,
        sourceProfiles,
        enrichedProfile,
      });
    }
  };

  render() {
    const { dataPointsSetId, dataPointId, noLabel, toAnnotate } = this.props;
    if (!this.state) {
      return (
        <div>
          <h1>Loading...</h1>
          <h2>dataPointsSetId: {dataPointsSetId}</h2>
          <h3>dataPointId: {dataPointId}</h3>
        </div>
      );
    }
    const { displayMode } = this.state;
    if (displayMode == 'experiencesDisplay') {
      const { dataExperience1a, dataExperience1b, dataExperience2, dataPoint } = this.state;
      return (
        <DataPointExperiencesView
          dataExperience1a={dataExperience1a}
          dataExperience1b={dataExperience1b}
          dataExperience2={dataExperience2}
          job={(dataPoint || {}).job}
        />
      );
    }
    const { enrichedProfile, dataPoint, offer, criterion, sourceProfiles } = this.state;
    return (
      <DataPointProfileOfferView
        dataPointsSetId={dataPointsSetId}
        dataPointId={dataPointId}
        noLabel={noLabel}
        toAnnotate={toAnnotate}
        enrichedProfile={enrichedProfile}
        dataPoint={dataPoint}
        offer={offer}
        criterion={criterion}
        sourceProfiles={sourceProfiles}
      />
    );
  }
}

export default DataPointView;
