import _, { each } from 'underscore';
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Icon, Button } from 'semantic-ui-react';
import baseUrl from '../../baseUrl.js';
import moment from 'moment';



const getRecruitmentsExport = async () => {
  const response = (await axios.post(`${baseUrl}/candidates/recruitmentsExportCsv`)).data;
  if (!response.success) {
    throw Error(JSON.stringify(response.error));
  }
  return response.results;
};

const downloadAsCsv = async ({ items, prefix }) => {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here

  let header = []
  _.each(items, (item) => {
    header = _.uniq(_.union(header, _.keys(item)))
  })
  let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  // Download csv
  var csvData = new Blob([csv], { type: 'text/csv' });
  var csvUrl = URL.createObjectURL(csvData);
  var now = moment().format('YYMMDDHHmmss');
  var hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.download = `${prefix}_${now}.csv`;
  hiddenElement.click();
}

class WatchRecruitments extends React.Component {
  state = {};


  handleDownloadRecruitments = async () => {
    try {
      this.setState({ computing: true });
      const results = await getRecruitmentsExport();

      await downloadAsCsv({
        items: results,
        prefix: "recruitments_export"
      })
      this.setState({
        computing: false,
      });
    } catch (e) {
      alert('Error getting query results : ' + JSON.stringify(e.message));
      this.setState({
        computing: false,
      });
    }
  };

  render() {
    const { computing } = this.state;
    return (
      <Grid columns={2}>
        {computing ? (
          <Icon name='circle notched' loading />
        ) : (
          <Button color='green' onClick={() => this.handleDownloadRecruitments()}>
            Download Recruitments CSV
          </Button>
        )}
      </Grid>
    );
  }
}

export default WatchRecruitments;
