import _ from 'underscore';
import React, { Component } from 'react';
import moment from 'moment';
import {
  Segment,
  Icon,
  Button,
  Modal,
  Input
} from 'semantic-ui-react';
import { SweetForm, Input as SInput } from 'sweetform'


const Push = ({push}) => {
  const { user, timestamp, sweetsheetId} = push;
  return (
    <div style={{color:'blue'}}>
      < Icon name='external' />Pushed by <b>{user}</b> on <b>{moment(timestamp).format('DD-MM-YYYY')}</b><br/>
      Sweetsheet Id : <b>{sweetsheetId}</b>
    </div>
  )
}
const Disqualify = ({disqualify}) => {
  const { user, timestamp, reason} = disqualify;
  return (
    <div style={{color:'red'}}>
      < Icon name='close' />Disqulified by <b>{user}</b> on <b>{moment(timestamp).format('DD-MM-YYYY')}</b><br/>
      Message : <b>{(reason || {}).message}</b>
    </div>
  )
}
const ChooseEmail = ({changeEmail, emailHints, selectedEmail}) => (
  <div>
  <span><b>email: </b> {selectedEmail || ''}</span><br/>
    <div>
      {_.map(emailHints || [], ({email, sources, bouncing}) => (
        <span>
          { bouncing ?
            <b>(bounce) </b>
          : (
            <Icon 
            name='check' 
            onClick={()=>changeEmail(email)} 
            style={{cursor:'pointer'}}
          />
          )
          }
            {email} ({sources.join(', ')})
          <br/>
        </span>
      ))}
    </div>
    <Input value={selectedEmail || ''} onChange={(e, { value }) => (changeEmail(value))} /> <br/>
  </div>
)
const ActionButtons = ({handlePushProfileToSweetSheet, handleOpenDisqualifyModal, validEmailSelected}) => (
  <div>
    { validEmailSelected && (
      <Button icon color='green' onClick={() => handlePushProfileToSweetSheet()}><Icon name='external'/>Push</Button>
    )}
    <Button icon color='red' onClick={() => handleOpenDisqualifyModal()}><Icon name='close'/>Disqualify</Button>
  </div>
)

class DisqualifyModal extends React.Component {
  state = {};

  onUpdate = (reason) => {
    this.setState({reason});
  }

  handleSubmit = async () => {
    const reason = this.state.reason
    if (!reason || !reason.message){
      return alert('Please enter a message')
    }
    try {
      await this.props.handleDisqualifySelectedMatch({reason})
    } catch (e) {
      alert(e)
    }
  }

  render () {
    const {onClose} = this.props;
    return (

    <Modal onClose={onClose} open closeIcon>

    <Modal.Content>
      <SweetForm
          onChange={this.onUpdate}
        >
        <label><b>Message :</b></label>
        <SInput field='message' />
      </SweetForm>
    </Modal.Content>

    <Modal.Actions>
      <Button negative onClick={onClose}>
        Cancel
      </Button>
      <Button positive onClick={this.handleSubmit}>
        Submit
      </Button>
    </Modal.Actions>
    </Modal>
    )
  }
}

class Actions extends Component {
  state = {
    disqualifyModalOpen: false
  }
  handleOpenDisqualifyModal (){
    this.setState({disqualifyModalOpen:true})
  }
  handleCloseDisqualifyModal (){
    this.setState({disqualifyModalOpen:false})
  }
  getSelectedEmail = ({email, emailHints}) => {
    if (email) {
      return email
    }
    let notBounceEmailHints = _.filter(emailHints, (hint)=> {
      return !hint.bouncing
    })
    if (notBounceEmailHints.length === 0){
      return null
    }
    _.each(notBounceEmailHints, (hint)=>{
      if (hint.email && hint.email.indexOf('gmail')>=0){
        return hint.email
      }
    })
    return notBounceEmailHints[0].email
  }
  render () {
    const { 
      enrichedProfile, 
      changeEmail, 
      handlePushProfileToSweetSheet, 
      handleDisqualifySelectedMatch,
      push,
      disqualify
    } = this.props;
    const {
      disqualifyModalOpen
    } = this.state;
    if (!enrichedProfile) {
      return null;
    }
    if (disqualify) {
      return <Segment><Disqualify disqualify={disqualify}/></Segment>
    }
    if (push) {
      return <Segment><Push push={push}/></Segment>
    }
    const selectedEmail = this.getSelectedEmail(enrichedProfile);
    const emailHints = enrichedProfile.emailHints || [];
    const validEmailSelected = ((selectedEmail || '').indexOf('.') >= 0 && (selectedEmail || '').indexOf('@') >= 0)
    return (
      <Segment>
        <ChooseEmail changeEmail={changeEmail} emailHints={emailHints} selectedEmail={selectedEmail}/>
        <ActionButtons 
          handlePushProfileToSweetSheet={()=>handlePushProfileToSweetSheet()}
          handleOpenDisqualifyModal={()=>this.handleOpenDisqualifyModal()}
          validEmailSelected={validEmailSelected}
        />
        { disqualifyModalOpen && (
          <DisqualifyModal 
            onClose={()=>this.handleCloseDisqualifyModal()}
            handleDisqualifySelectedMatch={(reason)=>handleDisqualifySelectedMatch(reason)} 
          />
        )}
      </Segment>
    );
  }
}

export default Actions;
