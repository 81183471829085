import React, { Component } from 'react';
import { Arrow, Group, Layer, Line, Rect, Stage, Text } from 'react-konva';
import { Provider, connect } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { Button, Divider, Form, Grid, Header, List, Loader } from 'semantic-ui-react';
import { Clearable, Input, Select, SweetForm, List as SweetList, enhance } from 'sweetform';
import {
  getLevel2CategoryIds,
  getMainJobsCategories,
} from './AssetsExplorer/JobsCategories/JobsCategoriesDashboard.js';
import { updateJob, updateJobDependencies } from './Monitoring/Cerejobsv2/JobCard.js';

import axios from 'axios';
import _ from 'underscore';
import Modal from './Modal';
import EditJobModal, { EditTranslations, hasEmptyPattern, ensureDefaultTranslation } from './Monitoring/Cerejobsv2/EditJobModal';
import TagSort from './TagSort.js';
import baseUrl from './baseUrl.js';
import { textToId } from './common';

const tagSort = new TagSort();

const TYPES = [
  { value: 'job', label: 'Job' },
  { value: 'job-modality', label: 'Job Modality' },
  { value: 'job-responsibility', label: 'Job Responsibility' },
  { value: 'job-seniority', label: 'Job Seniority' },
  { value: 'education-field', label: 'Education Field' },
  { value: 'skill', label: 'Skill' },
  { value: 'industry', label: 'Industry' },
  { value: 'interest', label: 'Interest' },
  { value: 'study-level', label: 'Study Level' },
  { value: 'hierarchical-rank', label: 'Hierarchical Rank' },
];

// Actions
const SET_SKILLS = 'SET_SKILLS';
const setTags = (tags) => ({ type: SET_SKILLS, tags });

const SET_SKILL = 'SET_SKILL';
const setTag = (tag) => ({ type: SET_SKILL, tag });

const SET_EDIT_MODE = 'SET_EDIT_MODE';
const setEditMode = (mode) => ({ type: SET_EDIT_MODE, mode });

const SET_PENDING_SKILL = 'SET_PENDING_SKILL';
const setPendingTag = (tag) => ({ type: SET_PENDING_SKILL, tag });

const SET_PENDING_JOB = 'SET_PENDING_JOB';
const setPendingJob = (job) => ({ type: SET_PENDING_JOB, job });

const SET_JOBS = 'SET_JOBS';
const setJobs = (jobs) => ({ type: SET_JOBS, jobs });

const SET_TAG_IDS = 'SET_TAG_IDS';
const setTagIds = (tagIds) => ({ type: SET_TAG_IDS, tagIds });

const SET_LEVEL_2_CATEGORY_IDS = 'SET_LEVEL_2_CATEGORY_IDS';
const setLevel2CategoryIds = (level2CategoryIds) => ({ type: SET_LEVEL_2_CATEGORY_IDS, level2CategoryIds });

const SET_MAIN_JOBS_CATEGORY_IDS = 'SET_MAIN_JOBS_CATEGORY_IDS';
const setMainJobsCategoryIds = (mainJobsCategoryIds) => ({ type: SET_MAIN_JOBS_CATEGORY_IDS, mainJobsCategoryIds });

const SET_EDIT = 'SET_EDIT';
const setEdit = (edit) => ({ type: SET_EDIT, edit });

const SET_COLLECTION_NAMES = 'SET_COLLECTION_NAMES';
const setCollectionNames = (collectionNames) => ({ type: SET_COLLECTION_NAMES, collectionNames });

const SET_COLLECTION_NAME = 'SET_COLLECTION_NAME';
const setCollectionName = (collectionName) => ({ type: SET_COLLECTION_NAME, collectionName });

// Reducers
const tags = (state = [], action) => {
  switch (action.type) {
    case SET_SKILLS:
      tagSort.updateTags(action.tags);
      return _.sortBy(action.tags, 'name');
    default:
      return state;
  }
};

const tag = (state = {}, action) => {
  switch (action.type) {
    case SET_SKILL:
      return action.tag;
    default:
      return state;
  }
};

const jobs = (state = [], action) => {
  switch (action.type) {
    case SET_JOBS:
      return action.jobs;
    default:
      return state;
  }
};

const tagIds = (state = [], action) => {
  switch (action.type) {
    case SET_TAG_IDS:
      return action.tagIds;
    default:
      return state;
  }
};

const level2CategoryIds = (state = [], action) => {
  switch (action.type) {
    case SET_LEVEL_2_CATEGORY_IDS:
      return action.level2CategoryIds;
    default:
      return state;
  }
};

const mainJobsCategoryIds = (state = [], action) => {
  switch (action.type) {
    case SET_MAIN_JOBS_CATEGORY_IDS:
      return action.mainJobsCategoryIds;
    default:
      return state;
  }
};

const editMode = (state = 'none', action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return action.mode;
    default:
      return state;
  }
};

const pendingTag = (state = {}, action) => {
  switch (action.type) {
    case SET_PENDING_SKILL:
      const tag = action.tag;
      const pendingTag = {
        ..._.omit(tag, 'up', 'down'),
        // Filter and clean data for Redux-Form input
        patterns: _.map(tag.patterns, (p) => p.join(';')),
        types: (tag.types || []).join(';'),
        up_strong: _.pluck(_.where(tag.up, { type: 'strong' }), 'id'),
        up_medium: _.pluck(_.where(tag.up, { type: 'medium' }), 'id'),
        up_weak: _.pluck(_.where(tag.up, { type: 'weak' }), 'id'),
        down_strong: _.pluck(_.where(tag.down, { type: 'strong' }), 'id'),
        down_medium: _.pluck(_.where(tag.down, { type: 'medium' }), 'id'),
        down_weak: _.pluck(_.where(tag.down, { type: 'weak' }), 'id'),
      };
      tagSort.updateRelatives(pendingTag['up_strong'], pendingTag['down_strong']);
      return pendingTag;
    default:
      return state;
  }
};

const pendingJob = (state = {}, action) => {
  switch (action.type) {
    case SET_PENDING_JOB:
      return action.job;
    default:
      return state;
  }
};

const edit = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return {};
    case SET_EDIT:
      return action.edit;
    default:
      return state;
  }
};

const collectionNames = (state = [], action) => {
  switch (action.type) {
    case SET_COLLECTION_NAMES:
      return action.collectionNames;
    default:
      return state;
  }
};

const collectionName = (state = [], action) => {
  switch (action.type) {
    case SET_COLLECTION_NAME:
      return action.collectionName;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  tags,
  tag,
  jobs,
  tagIds,
  level2CategoryIds,
  mainJobsCategoryIds,
  pendingTag,
  pendingJob,
  editMode,
  edit,
  collectionName,
  collectionNames,
});

// Store
const store = createStore(rootReducer);

// Components
const SKILL_WIDTH = 150;
const SKILL_HEIGHT = 30;
const SKILL_MARGIN_X = 20;
const SKILL_MARGIN_Y = 100;
const SKILL_COLORS = {
  weak: '#D7DF01',
  medium: '#3ADF00',
  strong: '#013ADF',
};

class Tag extends Component {
  render() {
    const { x, y, name, type, onClick } = this.props;
    return (
      <Group x={x} y={y} onClick={onClick}>
        <Rect width={SKILL_WIDTH} height={SKILL_HEIGHT} stroke={SKILL_COLORS[type] || 'black'} />
        <Text width={SKILL_WIDTH - 10} x={5} y={2} align='center' fontSize={14} text={name} />
      </Group>
    );
  }
}

class TagsTree extends Component {
  render() {
    const data = this.props.tag;
    const onSearch = this.props.onSearch;

    const sUp = data.up.length * (SKILL_WIDTH + SKILL_MARGIN_X) - SKILL_MARGIN_X;
    const sDown = data.down.length * (SKILL_WIDTH + SKILL_MARGIN_X) - SKILL_MARGIN_X;

    const centerX = Math.max(1, (Math.max(sUp, sDown) - SKILL_WIDTH) / 2);
    const offUp = sUp >= sDown ? 0 : (sDown - sUp) / 2;
    const offDown = sDown >= sUp ? 0 : (sUp - sDown) / 2;
    const canvasW = Math.max(sUp, sDown, SKILL_WIDTH + 1) + 2;

    return (
      <div style={{ overflow: 'auto', width: '100%' }}>
        <Stage width={canvasW} height={SKILL_HEIGHT * 3 + SKILL_MARGIN_Y * 2 + 2}>
          <Layer x={1} y={1}>
            <Tag x={centerX} y={SKILL_HEIGHT + SKILL_MARGIN_Y} name={data.name} />
            {data.up.map((s, i) => (
              <Tag
                {...s}
                key={s.id}
                x={offUp + i * (SKILL_WIDTH + SKILL_MARGIN_X)}
                y={0}
                onClick={() => onSearch(s.id)}
              />
            ))}
            {data.up.map((s, i) => (
              <Line
                key={`_u_${i}`}
                stroke={SKILL_COLORS[s.type]}
                points={[
                  SKILL_WIDTH / 2 + (SKILL_WIDTH + SKILL_MARGIN_X) * i + offUp,
                  SKILL_HEIGHT,
                  SKILL_WIDTH / 2 + centerX,
                  SKILL_HEIGHT + SKILL_MARGIN_Y - 10,
                ]}
              />
            ))}
            <Arrow
              stroke='black'
              fill='white'
              points={[
                SKILL_WIDTH / 2 + centerX,
                SKILL_HEIGHT + SKILL_MARGIN_Y - 10,
                SKILL_WIDTH / 2 + centerX,
                SKILL_HEIGHT + SKILL_MARGIN_Y,
              ]}
            />
            {data.down.map((s, i) => (
              <Tag
                {...s}
                key={s.id}
                x={offDown + i * (SKILL_WIDTH + SKILL_MARGIN_X)}
                y={(SKILL_HEIGHT + SKILL_MARGIN_Y) * 2}
                onClick={() => onSearch(s.id)}
              />
            ))}
            {data.down.map((s, i) => (
              <Arrow
                key={`_d_${i}`}
                stroke={SKILL_COLORS[s.type]}
                fill='white'
                points={[
                  SKILL_WIDTH / 2 + centerX,
                  SKILL_HEIGHT * 2 + SKILL_MARGIN_Y,
                  SKILL_WIDTH / 2 + (SKILL_WIDTH + SKILL_MARGIN_X) * i + offDown,
                  (SKILL_HEIGHT + SKILL_MARGIN_Y) * 2,
                ]}
              />
            ))}
          </Layer>
        </Stage>
      </div>
    );
  }
}

const TagSearch = ({ tags, mode, ...props }) => (
  <Select
    {...props}
    labelKey='name'
    valueKey='id'
    clearable={false}
    options={tags}
    filterOptions={tagSort.getSort(mode)}
  />
);

const AddTagModal = ({ onCancel, onEdit, onSubmit, change }) => (
  <Modal active={true} headerText='Add a new tag' submitText='Next' onSubmit={onSubmit} onCancel={onCancel}>
    <Form onSubmit={onSubmit}>
      <SweetForm onChange={onEdit}>
        <Form.Field>
          <label>Identifier</label>
          <Clearable component={Input} field='id' nullLabel='Auto' />
        </Form.Field>
        <Form.Field>
          <label>Name</label>
          <Input autoFocus field='name' />
        </Form.Field>
        <Form.Field>
          <label>Types</label>
          <Select field='types' multi options={TYPES} />
        </Form.Field>
        <input type='submit' style={{ visibility: 'hidden' }} />
      </SweetForm>
    </Form>
  </Modal>
);
const EditDependencies = ({ name, tags }) => (
  <Grid>
    <Grid.Column width={2} />
    <Grid.Column width={14}>
      <Form.Field>
        <label>Strong</label>
        <SweetList component={TagSearch} field={`${name}_strong`} mode={name} tags={tags} />
      </Form.Field>
      <Form.Field>
        <label>Medium</label>
        <SweetList component={TagSearch} field={`${name}_medium`} mode={name} tags={tags} />
      </Form.Field>
      <Form.Field>
        <label>Weak</label>
        <SweetList component={TagSearch} field={`${name}_weak`} tags={tags} />
      </Form.Field>
    </Grid.Column>
  </Grid>
);

const EditTagModal = ({ tag, tags, onCancel, onEdit, onSubmit }) => (
  <Modal active={true} headerText={`Edit tag ${tag.name}`} submitText='Submit' onSubmit={onSubmit} onCancel={onCancel}>
    <Form>
      <SweetForm initialValues={tag} onChange={onEdit}>
        <Grid columns={2}>
          <Grid.Column>
            <Form.Field>
              <label>Patterns</label>
              <SweetList field='patterns' component={Input} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label>Types</label>
              <Select field='types' multi options={TYPES} />
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Divider />
        <Form.Field>
          <label>Parent dependencies</label>
          <EditDependencies name='up' tags={tags} />
        </Form.Field>
        <Divider />
        <Form.Field>
          <label>Son dependants</label>
          <EditDependencies name='down' tags={tags} />
        </Form.Field>
        <Divider />
        <Form.Field>
          <label>Translations</label>
          <EditTranslations field='translations' />
        </Form.Field>
      </SweetForm>
    </Form>
  </Modal>
);
const TagsList = ({ tags, onSearch }) => {
  const perCol = Math.ceil(tags.length / 4);
  const cols = [
    tags.slice(0, perCol),
    tags.slice(perCol, perCol * 2),
    tags.slice(perCol * 2, perCol * 3),
    tags.slice(perCol * 3),
  ];

  return (
    <Grid columns={4}>
      {cols.map((c, i) => (
        <Grid.Column key={`col${i}`}>
          {c.map((s, j) => {
            const last = tags[i * perCol + j - 1] || { name: '_' };
            const newLetter = last.name[0] !== s.name[0];
            return (
              <div key={s.id}>
                {newLetter ? <br /> : null}
                <Button
                  size='small'
                  compact
                  onClick={(e) => {
                    e.preventDefault();
                    onSearch(s.id);
                  }}
                >
                  {s.name}
                </Button>
              </div>
            );
          })}
        </Grid.Column>
      ))}
    </Grid>
  );
};

class Tags extends Component {
  componentDidMount() {
    this.props.onLoad();
    if (this.props.match && this.props.match.params.id) {
      this.props.onSearch(this.props.match.params.id);
    } else {
      this.props.onSearch('');
    }
  }

  reloadTagCheckers = async () => {
    const customHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    const url = baseUrl + '/tags/reloadTagCheckers';
    const data = (await axios.get(url, customHeaders)).data;
    alert('Tag Checkers Reloaded');
  };

  render() {
    const {
      history,
      tags,
      tag,
      jobs,
      tagIds,
      level2CategoryIds,
      mainJobsCategoryIds,
      pendingTag,
      pendingJob,
      edit,
      editMode,
      onSearch,
      onAdd,
      onAddSubmit,
      onEdit,
      onSubmit,
      onSubmitJob,
      onDelete,
      onDeleteConfirm,
      onCancel,
      collectionName,
      collectionNames,
      onSetCollectionName,
    } = this.props;

    const onSearchHistory = async (search) => {
      await onSearch(search, collectionName);
      history.push(`/monitoring/knowledge/tags/${search}`);
    };
    return (
      <div>
        {editMode === 'add' ? (
          <AddTagModal onCancel={onCancel} onEdit={onEdit} onSubmit={() => onAddSubmit(edit)} />
        ) : null}
        {editMode === 'edit' ? (
          (pendingTag.types || '').split(';').includes('job') && collectionName === 'tagsV2' ? (
            <EditJobModal
              onSubmit={onSubmitJob}
              onCancel={onCancel}
              jobs={jobs}
              job={pendingJob}
              collectionName={collectionName}
              tagIds={tagIds}
              mainJobsCategoryIds={mainJobsCategoryIds}
              level2CategoryIds={level2CategoryIds}
            />
          ) : (
            <EditTagModal
              tag={pendingTag}
              tags={tags}
              onCancel={onCancel}
              onEdit={(edit) => {
                onEdit(edit);
                tagSort.updateRelatives(edit.up_strong, edit.down_strong);
              }}
              onSubmit={() => onSubmit(edit, collectionName)}
            />
          )
        ) : null}
        {editMode === 'delete' ? (
          <Modal
            active={true}
            headerText={`Delete tag ${tag.name}`}
            submitText='Confirm'
            onCancel={onCancel}
            onSubmit={() => onDeleteConfirm(tag.id, collectionName)}
          >
            <p>Are you sure? This operation cannot be reverted.</p>
          </Modal>
        ) : null}
        <Grid>
          <Grid.Column width={2}>
            <SweetForm
              onChange={({ collectionName }) => {
                if (collectionName) {
                  onSetCollectionName(collectionName);
                }
              }}
            >
              <Select
                options={_.map(collectionNames, (name) => ({ label: name, value: name }))}
                defaultValue='tagsV2'
                field='collectionName'
              />
            </SweetForm>
          </Grid.Column>
          <Grid.Column width={10}>
            <SweetForm
              onChange={({ search }) => {
                if (search) {
                  onSearchHistory(search);
                }
              }}
            >
              <TagSearch autoFocus tags={tags} field='search' />
            </SweetForm>
          </Grid.Column>
          <Grid.Column>
            <Button.Group>
              <Button onClick={() => this.reloadTagCheckers()}>Reload Tag Checkers</Button>
              <Button color='green' onClick={onAdd}>
                Add
              </Button>
              {tag.id
                ? [
                    <Button color='yellow' onClick={() => onAddSubmit(tag)}>
                      Edit
                    </Button>,
                    <Button color='red' onClick={onDelete}>
                      Delete
                    </Button>,
                  ]
                : null}
              {tags.length === 0 ? <Loader inline active /> : null}
            </Button.Group>
          </Grid.Column>
        </Grid>

        <Divider />

        {tag.id ? (
          <Grid>
            <Grid.Column width={12}>
              <TagsTree tag={tag} onSearch={onSearchHistory} />
            </Grid.Column>
            <Grid.Column>
              <Header as='h3'>Patterns</Header>
              <List bulleted>
                {_.map(tag.patterns, (p, i) => (
                  <List.Item key={i}>{p.join(';')}</List.Item>
                ))}
              </List>
              <Header as='h3'>Types</Header>
              <List bulleted>
                {_.map(tag.types, (t, i) => (
                  <List.Item key={i}>{t}</List.Item>
                ))}
              </List>
            </Grid.Column>
          </Grid>
        ) : null}

        <Divider />

        {tags.length ? <TagsList tags={tags} onSearch={onSearchHistory} /> : null}
      </div>
    );
  }
}

// Containers
const mapSTags = (state) => ({
  tags: state.tags,
  tag: state.tag,
  jobs: state.jobs,
  tagIds: state.tagIds,
  level2CategoryIds: state.level2CategoryIds,
  mainJobsCategoryIds: state.mainJobsCategoryIds,
  pendingTag: state.pendingTag,
  pendingJob: state.pendingJob,
  edit: state.edit,
  editMode: state.editMode,
  collectionNames: state.collectionNames,
  collectionName: state.collectionName,
});

const mapDTags = (dispatch) => ({
  onLoad: async () => {
    const tags = (await axios.get(baseUrl + '/tags/list?collectionName=tagsV2')).data;
    const jobs = (await axios.get(baseUrl + '/cerejobsV2/getJobs')).data;
    const collectionNames = (await axios.get(baseUrl + '/tags/collectionNames')).data;
    const tagIds = (await axios.get(baseUrl + '/cerejobsV2/getTagIds')).data;

    const mainJobsCategories = await getMainJobsCategories();
    const mainJobsCategoryIds = _.map(mainJobsCategories, (jobsCategory) => jobsCategory.id);
    const level2CategoryIds = getLevel2CategoryIds(mainJobsCategoryIds, jobs);

    dispatch(setTags(tags));
    dispatch(setJobs(jobs));
    dispatch(setTagIds(tagIds));
    dispatch(setCollectionNames(collectionNames));
    dispatch(setLevel2CategoryIds(level2CategoryIds));
    dispatch(setMainJobsCategoryIds(mainJobsCategoryIds));
  },
  onEdit: (e) => dispatch(setEdit(e)),
  onAdd: () => dispatch(setEditMode('add')),
  onAddSubmit: async (value) => {
    if (!value.name) {
      return;
    }
    value.id = value.id || textToId(value.name);
    if (typeof value.types === 'string' || value.types instanceof String) {
      value.types = (value.types || '').split(';');
    }
    dispatch(setPendingTag(value));

    const existingJob = (await axios.get(baseUrl + '/cerejobsV2/getJob/' + value.id)).data;
    const job = existingJob ? existingJob : value;

    dispatch(setPendingJob(job));
    dispatch(setEditMode('edit'));
  },
  onDelete: () => {
    dispatch(setEditMode('delete'));
  },
  onCancel: () => {
    dispatch(setEditMode('none'));
  },
  onSearch: async (id, collectionName) => {
    const tag = (await axios.get(`${baseUrl}/tags/${id}?collectionName=${collectionName || 'tagsV2'}`)).data;
    dispatch(setTag(tag));
  },
  onSubmit: async (pendingTag, collectionName) => {
    if (hasEmptyPattern({ patterns: pendingTag.patterns })) {
      return alert('Contains empty pattern');
    }
    const tag = {
      ...pendingTag,
      patterns: _.map(pendingTag.patterns, (p) => p.split(';')),
      types: (pendingTag.types || '').split(';'),
      translations: ensureDefaultTranslation({ translations: pendingTag.translations }),
    };
    const checkResult = (await axios.post(`${baseUrl}/tags/${tag.id}?collectionName=${collectionName}`, tag)).data;
    if (!checkResult.success) {
      alert(JSON.stringify(checkResult));
    } else {
      const newTag = (await axios.get(`${baseUrl}/tags/${tag.id}?collectionName=${collectionName}`)).data;
      dispatch(setEditMode('none'));
      dispatch(setTag(newTag));
    }
  },
  onSubmitJob: async (
    params,
    jobs,
    groupIdToDiffusionToGroup,
    groupIdToDiffusionToElement,
    fullJobsInGroup,
    collectionName,
  ) => {
    if (_.isUndefined(params)) {
      dispatch(setEditMode('none'));
      return;
    }
    if (_.isEmpty(params.id) || _.isUndefined(params.id)) {
      alert('id field should not be changed.');
      return;
    }
    if (_.isUndefined(params.types) || _.isEmpty(params.types)) {
      alert('Types should not be empty.');
      return;
    }
    if (!(params.types || '').split(';').includes('job') && isGroup) {
      alert('Groups should be of type job.');
      return;
    }
    if (
      !(params.types || '').split(';').includes('job') &&
      (!_.isUndefined(params.groups) || _.isEmpty(params.groups))
    ) {
      alert('Groups should only contain items of type job.');
      return;
    }
    if (hasEmptyPattern({ patterns: params.patterns })) {
      return alert('Contains empty pattern');
    }
    if (params.translations) {
      params.translations = ensureDefaultTranslation({ translations: params.translations });
    }

    const isGroup = params.isGroup;
    const jobsInGroup = isGroup ? (params.jobsInGroup || '').split(';') || [] : [];
    const jobIdToFullJobInGroup = {};
    _.each(jobsInGroup, (jobInGroup) => {
      jobIdToFullJobInGroup[jobInGroup] = { id: jobInGroup, groups: [{ id: params.id }] };
    });
    _.each(fullJobsInGroup, (fullJobInGroup) => {
      jobIdToFullJobInGroup[fullJobInGroup.id] = fullJobInGroup;
    });
    const data = await updateJob(params, isGroup, groupIdToDiffusionToGroup, jobIdToFullJobInGroup);
    if (data.error) {
      alert(data.error);
      return;
    }
    const neighboursData = await updateJobDependencies(
      params,
      isGroup,
      jobs,
      groupIdToDiffusionToElement,
      jobIdToFullJobInGroup,
      jobsInGroup,
    );
    if (neighboursData.error) {
      alert(neighboursData.error);
      return;
    }
    const updatedJobs = (await axios.get(baseUrl + '/cerejobsV2/getJobs')).data;
    dispatch(setJobs(updatedJobs));
    const updatedTagIds = (await axios.get(baseUrl + '/cerejobsV2/getTagIds')).data;
    dispatch(setTagIds(updatedTagIds));
    const id = params.id;
    const newTag = (await axios.get(`${baseUrl}/tags/${id}?collectionName=${collectionName}`)).data;
    dispatch(setEditMode('none'));
    dispatch(setTag(newTag));
  },
  onDeleteConfirm: async (id, collectionName) => {
    const checkResult = (await axios.delete(`${baseUrl}/tags/${id}?collectionName=${collectionName}`)).data;
    if (!checkResult.success) {
      alert(JSON.stringify(checkResult));
    } else {
      const tag = (await axios.get(`${baseUrl}/tags?collectionName=${collectionName}`)).data;
      dispatch(setEditMode('none'));
      dispatch(setTag(tag));
    }
  },
  onSetCollectionName: async (collectionName) => {
    const tags = (await axios.get(baseUrl + '/tags/list?collectionName=' + collectionName)).data;
    dispatch(setTags(tags));
    const tagIds = (await axios.get(baseUrl + '/cerejobsV2/getTagIds')).data;
    dispatch(setTagIds(tagIds));
    dispatch(setCollectionName(collectionName));
    const tag = (await axios.get(`${baseUrl}/tags?collectionName=${collectionName}`)).data;
    dispatch(setTag(tag));
  },
});

const TagsContainer = connect(
  mapSTags,
  mapDTags,
)(Tags);

export default ({ match, history }) => (
  <Provider store={store}>
    <TagsContainer match={match} history={history} />
  </Provider>
);
