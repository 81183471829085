import axios from 'axios';
import React from 'react';
import _ from 'underscore';
import baseUrl from '../../baseUrl.js';

import Location from './Location';
import LocationSelector from './LocationSelector.js';
import Search from './Search';

export default class LocationsViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: 0, patterns: [] };
  }
  async componentDidMount() {
    const locationId = this.props.match.params.locationId;
    if (locationId) {
      const response = await axios.get(`${baseUrl}/locationsV2/location/${locationId}`);
      const locations = [response.data.location];
      this.setState({ locations: locations, patterns: [], index: 0 });
    }
  }
  handleSearchSubmit = async (search) => {
    const response = await axios.post(`${baseUrl}/locationsV2/safelyExtractLocations`, { text: search });
    this.setState({
      search: search,
      locations: response.data.locations,
      patterns: [],
      index: 0,
    });
  };
  handleLocationSelectorClick = (index) => {
    if (this.state.locations) {
      this.setState({ index });
    }
  };
  handleChangeCountryMembers = (e, v) => {
    let newLocations = Object.assign([], this.state.locations);
    newLocations[this.state.index].countryMembers = _.pluck(e, 'value');
    this.setState({ locations: newLocations });
  };

  handleInputChange = (e, { name, value }) => {
    let newLocations = Object.assign([], this.state.locations);
    newLocations[this.state.index][name] = value;
    this.setState({ locations: newLocations });
  };

  handleCheckboxChange = (e, { name, checked }) => {
    let newLocations = Object.assign([], this.state.locations);
    newLocations[this.state.index][name] = checked;
    this.setState({ locations: newLocations });
  };

  handleBoundingBoxChange = (e, { name, value }) => {
    let newLocations = Object.assign([], this.state.locations);
    newLocations[this.state.index].shape.boxes[0][name] = parseFloat(value);
    this.setState({ locations: newLocations });
  };

  handleLocationSubmit = async () => {
    alert('Use the /location/updateField route of the API to update a location.');
    return;

    //const location = this.state.locations[this.state.index];
    //if (!_.isEmpty(location.countryMembers) && location.type !== 'country-group'){
    //  return alert('Only locations of type "country-group" can have country members');
    //}
    //await axios
    //  .post(`${baseUrl}/locationsV2/location/update`, { ...location })
    //  .then(
    //    axios.post(`${baseUrl}/locationsV2/monoEntityUnambiguousMappings/update`, {
    //      locationId: location.id,
    //      patterns: [...this.state.patterns, ...location.patterns],
    //    }),
    //  )
    //  .catch((error) => console.log(error));
  };

  handlePatterns = (e, v) => {
    this.setState({ patterns: v.value });
  };

  render() {
    return (
      <div>
        <div>
          <Search handleSubmit={this.handleSearchSubmit} placeholder='Search location...' />
        </div>
        <div>
          {this.state.locations ? (
            <LocationSelector locations={this.state.locations} handleClick={this.handleLocationSelectorClick} />
          ) : null}
        </div>
        <div>
          {this.state.locations && this.state.locations.length > 0 ? (
            <Location
              location={this.state.locations[this.state.index]}
              patternValue={[...this.state.locations[this.state.index].patterns, ...this.state.patterns]}
              patternOptions={_.map(
                [...this.state.locations[this.state.index].patterns, ...this.state.patterns],
                (pattern) => ({
                  key: pattern,
                  value: pattern,
                  text: pattern,
                }),
              )}
              editable
              handleSubmit={this.handleLocationSubmit}
              handleInputChange={this.handleInputChange}
              handleBoundingBoxChange={this.handleBoundingBoxChange}
              handleCheckboxChange={this.handleCheckboxChange}
              handlePatterns={this.handlePatterns}
              handleChangeCountryMembers={this.handleChangeCountryMembers}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
