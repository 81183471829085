import React from 'react';
import _ from 'underscore';
import { Container, Label } from 'semantic-ui-react';

export const Endorsments = ({ endorsements }) => (
  <Container fluid textAlign="center">
    <Label.Group>
      {_.map(endorsements, (skill, i) => (
        <Label key={i}>
          {skill.identifier}
          <Label.Detail>{skill.num}</Label.Detail>
        </Label>
      ))}
    </Label.Group>
  </Container>
);
