import { Grid, Icon, Loader, Modal, Table } from 'semantic-ui-react';

import GenericWidget from './GenericWidget';
import React from 'react';
import _ from 'underscore';

const WidgetPreviewModal = ({ onClose, widget, widgetIdToWidget }) => (
  <Modal open={true} onClose={onClose} closeIcon closeOnDimmerClick={false}>
    <Modal.Header>
      {(widget || {}).title} ({(widget || {}).id})
    </Modal.Header>
    <Modal.Content>
      <center>
        <GenericWidget widget={widget} widgetIdToWidget={widgetIdToWidget} />
      </center>
    </Modal.Content>
  </Modal>
);

export default WidgetPreviewModal;
