import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import { Table, Button } from 'semantic-ui-react';
import withThreadContentModal from '../hocs/withThreadContentModal';
import baseUrl from '../baseUrl.js';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

// Reducers
const instanceData = (state = [], action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  instanceData,
});

// Store
const store = createStore(rootReducer);

const SuspiciousThreadRow = ({
  profile,
  thread,
  nbThreads,
  index,
  showThreadContent,
  profileIndex,
  onRepairProfileThreads,
  onMarkThreadsAsNotSuspicious,
}) => {
  return (
    <Table.Row {...(thread.notFound ? { negative: true } : {})}>
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>{profileIndex + 1}</Table.Cell>
      )}
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>{profile.clientId}</Table.Cell>
      )}
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>{profile.jobOfferId}</Table.Cell>
      )}
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>{profile.profileId}</Table.Cell>
      )}
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>
          {(profile.sentDate || '').slice(2, 16)}
        </Table.Cell>
      )}
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>{profile.senderAddress}</Table.Cell>
      )}
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>{profile.error}</Table.Cell>
      )}

      <Table.Cell
        style={{ cursor: 'pointer' }}
        onClick={() => showThreadContent(thread.id)}
      >
        {thread.id}
      </Table.Cell>
      <Table.Cell>{thread.mailAccountId}</Table.Cell>
      <Table.Cell>{thread.notFound ? 'Missing' : ''}</Table.Cell>
      {index === 0 && (
        <Table.Cell rowSpan={nbThreads}>
          <Button onClick={() => onRepairProfileThreads(profile.profileId)}>
            Repair
          </Button>
          <Button
            onClick={() => onMarkThreadsAsNotSuspicious(profile.profileId)}
          >
            Not suspicious
          </Button>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

class SuspiciousProfilesThreads extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const {
      instanceData,
      showThreadContent,
      onRepairProfileThreads,
      onMarkThreadsAsNotSuspicious,
    } = this.props;

    return (
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Index</Table.HeaderCell>
            <Table.HeaderCell>ClientId</Table.HeaderCell>
            <Table.HeaderCell>jobOfferId</Table.HeaderCell>
            <Table.HeaderCell>ProfileId</Table.HeaderCell>
            <Table.HeaderCell>SentDate</Table.HeaderCell>
            <Table.HeaderCell>SenderEmailAddress</Table.HeaderCell>
            <Table.HeaderCell>Error</Table.HeaderCell>
            <Table.HeaderCell>ThreadId</Table.HeaderCell>
            <Table.HeaderCell>MailAccountId</Table.HeaderCell>
            <Table.HeaderCell>ThreadsInDB</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.flatten(
            _.map(instanceData, (profile, profileIndex) =>
              _.map(profile.threads, (thread, index) => (
                <SuspiciousThreadRow
                  key={`${profile.profileId}-${thread.id}-${
                    thread.mailAccountId
                  }`}
                  profile={profile}
                  thread={thread}
                  index={index}
                  profileIndex={profileIndex}
                  nbThreads={profile.threads.length}
                  showThreadContent={showThreadContent}
                  onRepairProfileThreads={onRepairProfileThreads}
                  onMarkThreadsAsNotSuspicious={onMarkThreadsAsNotSuspicious}
                />
              )),
            ),
          )}
        </Table.Body>
      </Table>
    );
  }
}

const mapSSuspiciousProfilesThreads = (state) => ({
  instanceData: state.instanceData,
});

const loadSuspiciousProfilesThreads = async (dispatch) => {
  const { data } = await axios.get(
    `${baseUrl}/monitoring/suspiciousprofilesthreads`,
  );
  // const data = [{
  //   profileId: 'ben-gi-123456',
  //   jobOfferId: 'devops',
  //   clientId: 'hiresweet',
  //   error: 'mailAccountId_mismatch',
  //   senderAddress: 'benedicte@hiresweet.com',
  //   sentDate: 1234567890123,
  //   threads: [{
  //     id: '1234567890',
  //     mailAccountId: 'ismael_hiresweet_com',
  //     notFound: true,
  //   }, {
  //     id: '1234567390',
  //     mailAccountId: 'benedicte_hiresweet_com',
  //     notFound: true,
  //   }]
  // }]
  const instances = _.sortBy(data, (profile) => profile.sentDate).reverse();
  dispatch(setInstanceData(instances));
};

const mapDSuspiciousProfilesThreads = (dispatch) => ({
  onLoad: async () => {
    await loadSuspiciousProfilesThreads(dispatch);
  },
  onRepairProfileThreads: async (profileId) => {
    // const { data } = await axios.get(
    //   `${baseUrl}/monitoring/profile/${profileId}/threads/repair`,
    // );
    // await loadSuspiciousProfilesThreads(dispatch);
    alert('repair is deprecated')
  },
  onMarkThreadsAsNotSuspicious: async (profileId) => {
    await axios.get(
      `${baseUrl}/monitoring/profile/${profileId}/threads/notSuspicious`,
    );
    await loadSuspiciousProfilesThreads(dispatch);
  },
});

const SuspiciousProfilesThreadsContainer = connect(
  mapSSuspiciousProfilesThreads,
  mapDSuspiciousProfilesThreads,
)(withThreadContentModal(SuspiciousProfilesThreads));

export default () => (
  <Provider store={store}>
    <SuspiciousProfilesThreadsContainer />
  </Provider>
);
