import _ from 'underscore';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { Table, Button, Icon } from 'semantic-ui-react';
import baseUrl from '../baseUrl.js';

// Actions
const SET_INSTANCE_DATA = 'SET_INSTANCE_DATA';
const setInstanceData = (data) => ({ type: SET_INSTANCE_DATA, data });

// Reducers
const instanceData = (state = [], action) => {
  switch (action.type) {
    case SET_INSTANCE_DATA:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  instanceData,
});

// Store
const store = createStore(rootReducer);

/**
 * gmail_error__invalid_credentials : 2
 * other error : 1
 * no error : 0
 */
const getMailAccountStatusCode = (mailAccount) => {
  const error = mailAccount.sanityCheck && mailAccount.sanityCheck.error;
  return (error || '').indexOf('gmail_error__incompatible_email') >= 0
    ? 3
    : error === 'gmail_error__invalid_credentials'
    ? 2
    : mailAccount.sanityCheck && mailAccount.sanityCheck.error
    ? 1
    : 0;
};

const MailAccountRow = ({ mailAccount, onRemoveMailAccountTokens, index }) => {
  const mailAccountStatusCode = getMailAccountStatusCode(mailAccount);
  return (
    <Table.Row
      {...(mailAccountStatusCode >= 2
        ? { negative: true }
        : mailAccountStatusCode === 1
        ? { warning: true }
        : {})}
    >
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>
        {mailAccountStatusCode >= 2 && (
          <Button
            negative
            onClick={() =>
              onRemoveMailAccountTokens({ mailAccountId: mailAccount.id })
            }
          >
            Remove Tokens
          </Button>
        )}
      </Table.Cell>
      <Table.Cell>
        {mailAccount.id}{' '}
        {mailAccountStatusCode >= 3 && <Icon name="bullhorn" />}{' '}
      </Table.Cell>
      <Table.Cell>
        {(mailAccount.sanityCheck || {}).error ||
          (mailAccount.sanityCheck || {}).status ||
          ''}
      </Table.Cell>
      <Table.Cell
        {...(!(mailAccount.sanityCheck || {}).lastCheckTimestamp ||
          Date.now() - (mailAccount.sanityCheck || {}).lastCheckTimestamp >
            1000 * 60 * 60 * 24 * 10) && { style: { backgroundColor: 'red' } }}
      >
        {(mailAccount.sanityCheck || {}).lastCheckTimestamp
          ? moment((mailAccount.sanityCheck || {}).lastCheckTimestamp).fromNow()
          : 'jamais'}
      </Table.Cell>
      <Table.Cell>
        {(_.pluck(mailAccount.clients, 'id') || []).join(', ')}
      </Table.Cell>
    </Table.Row>
  );
};

const TableMailAccounts = ({
  clientsMailAccounts,
  onRemoveMailAccountTokens,
}) => (
  <Table compact celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>N°</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
        <Table.HeaderCell>Id</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Last Sanity Check Date</Table.HeaderCell>
        <Table.HeaderCell>Clients</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(clientsMailAccounts, (mailAccount, index) => (
        <MailAccountRow
          key={mailAccount.id}
          mailAccount={mailAccount}
          onRemoveMailAccountTokens={onRemoveMailAccountTokens}
          index={index}
        />
      ))}
    </Table.Body>
  </Table>
);

class MailAccounts extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { instanceData, onRemoveMailAccountTokens } = this.props;

    return (
      <div>
        <h2>MailAccounts avec clients</h2>
        <TableMailAccounts
          clientsMailAccounts={instanceData.mailAccountsWithClients}
          onRemoveMailAccountTokens={onRemoveMailAccountTokens}
        />
        <h2>MailAccounts sans clients</h2>
        <TableMailAccounts
          clientsMailAccounts={instanceData.mailAccountsWithoutClients}
          onRemoveMailAccountTokens={onRemoveMailAccountTokens}
        />
      </div>
    );
  }
}

const mapSMailAccounts = (state) => ({
  instanceData: state.instanceData,
});

const loadMailAccounts = async (dispatch) => {
  const { data } = await axios.get(`${baseUrl}/monitoring/mailaccounts`);
  const { mailAccountsWithClients, mailAccountsWithoutClients } = _.reduce(
    data,
    (memo, mailAccount) =>
      mailAccount.clients && mailAccount.clients[0]
        ? {
            ...memo,
            mailAccountsWithClients: [
              mailAccount,
              ...memo.mailAccountsWithClients,
            ],
          }
        : {
            ...memo,
            mailAccountsWithoutClients: [
              mailAccount,
              ...memo.mailAccountsWithoutClients,
            ],
          },
    { mailAccountsWithClients: [], mailAccountsWithoutClients: [] },
  );
  const sortedMailAccountsWithClients = _.sortBy(
    mailAccountsWithClients,
    (mailAccount) =>
      2 - getMailAccountStatusCode(mailAccount) + mailAccount.clients[0].id,
  );
  const sortedMailAccountsWithoutClients = _.sortBy(
    mailAccountsWithoutClients,
    (mailAccount) => 2 - getMailAccountStatusCode(mailAccount),
  );
  const instances = {
    mailAccountsWithClients: sortedMailAccountsWithClients,
    mailAccountsWithoutClients: sortedMailAccountsWithoutClients,
  };
  dispatch(setInstanceData(instances));
};

const mapDMailAccounts = (dispatch) => ({
  onLoad: async () => {
    await loadMailAccounts(dispatch);
  },
  onRemoveMailAccountTokens: async ({ mailAccountId }) => {
    await axios.delete(
      `${baseUrl}/monitoring/mailaccounts/${mailAccountId}/tokens`,
    );
    await loadMailAccounts(dispatch);
  },
});

const MailAccountsContainer = connect(
  mapSMailAccounts,
  mapDMailAccounts,
)(MailAccounts);

export default () => (
  <Provider store={store}>
    <MailAccountsContainer />
  </Provider>
);
