import { stringify } from 'query-string';
import React, { Component } from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import JsonEditor from './jsonEditor';
import XG1FormattedProfile from './XG1FormattedProfile';

const stringifyFormattedProfile = (formattedProfile) => {
  const {id, meta, experiences, education, skills} = formattedProfile
  return JSON.stringify({
    ...id && { id },
    ...meta && { meta },
    ...experiences && { experiences },
    ...education && { education },
    ...skills && { skills },
  }, null, 4);
}

class EditFormattedProfileModal extends Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    console.log("props", props)
    this.state.data = props.initialValue;
  }

  handleUpdateData = ({ data }) => {
    this.setState({ data });
  };

  handleSave = () => {
    this.props.onSave({
      data: this.state.data,
    });
  };

  render() {
    const { initialValue, onClose } = this.props;
    return (
      <Modal onClose={onClose} open closeIcon size='fullscreen'>
        <Modal.Header>Edit FormattedProfile</Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Grid>
            <Grid.Column width={8}>
              <JsonEditor initialValue={initialValue} stringify={stringifyFormattedProfile} onUpdate={this.handleUpdateData}/>
            </Grid.Column>
            <Grid.Column width={8}>
              <XG1FormattedProfile formattedProfile={this.state.data}/>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => this.handleSave()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditFormattedProfileModal;
