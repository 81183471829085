import React, { Component } from 'react';
import _ from 'underscore';
import moment from 'moment';
import { Input, Range, Select, SelectInt, SweetForm, List as SweetList, customOperator, enhance } from 'sweetform';
import { Grid } from "semantic-ui-react";
import { loadLocations } from '../../Sales/SalesBoard/EditOmegaCriteria';
import { MAHER_CATEGORIES } from '../../Sales/SalesBoard/EditOfferModalComponents';
import { getSkillIds, getJobPositions } from '../../Offers/SearchModal';

const SENIORITY_OPTIONS = [
  { label: "junior", value: "junior" },
  { label: "medium", value: "medium" },
  { label: "senior", value: "senior" },
]
const SelectLocations = () => (
  <Select field='locations' loadOptions={loadLocations} labelKey='label' valueKey='value' async={true} multi={true} />
);

const SelectSkills = () => (
  <Select field='skills' async loadOptions={getSkillIds} labelKey='name' valueKey='id' multi={true} />
);

const SelectJobs = () => (
  <Select field='jobs' async loadOptions={getJobPositions} labelKey='name' valueKey='id' multi={true} />
);

const SelectSeniorities = () => (
  <Select field='seniorities' options={SENIORITY_OPTIONS} multi={true} />
);

const SelectCategories = () => (
  <Select field='categories' labelKey='name' valueKey='id' options={MAHER_CATEGORIES} multi={true} />
)

const SEGMENTATION_FIELD_OPTIONS = [
  { label: "categories", value: "categories" },
  { label: "jobs", value: "job_ids" },
  { label: "skills", value: "skill_ids" },
  { label: "seniorities", value: "seniority_ids" },
  { label: "locations", value: "location_ids" },
]

export const SelectSegmentationFields = () => {
  return <Select field='segmentationFields' options={SEGMENTATION_FIELD_OPTIONS} multi={true} />
}

export const ItemFilter = customOperator({
  categories: { label: 'Category', children: 1, component: SelectCategories },
  jobs: { label: 'Job', children: 1, component: SelectJobs },
  skills: { label: 'Skill', children: 1, component: SelectSkills },
  seniorities: { label: 'Seniority', children: 1, component: SelectSeniorities },
  locations: { label: 'Location', children: 1, component: SelectLocations },
  isActive: { label: 'isActive' }
}, false)

export const TypologyFilter = customOperator({
  categories: { label: 'Category', children: 1, component: SelectCategories },
  jobs: { label: 'Job', children: 1, component: SelectJobs },
  skills: { label: 'Skill', children: 1, component: SelectSkills },
  seniorities: { label: 'Seniority', children: 1, component: SelectSeniorities },
  locations: { label: 'Location', children: 1, component: SelectLocations },
}, false)




const getLastWeeks = (nLastWeeks) => {
  const currentWeek = moment().startOf('isoWeek');
  var weeks = [];
  for (var i = 0; i < nLastWeeks; i += 1) {
    weeks.push(currentWeek.subtract(7, 'days').format('YYYY-MM-DD'));
  }
  return weeks;
};

export const SelectLastWeekRange = enhance(() => {
  const lastWeeks = getLastWeeks(48);
  const lastWeeksOptions = _.map(lastWeeks, (week) => ({
    value: week,
    label: week,
  }));
  return (
    <Grid columns={4}>
      <Grid.Column width={2}>Between</Grid.Column>
      <Grid.Column width={6}>
        <Select field='minDate' options={lastWeeksOptions} placeholder='Begin' />
      </Grid.Column>
      <Grid.Column width={2}>And</Grid.Column>
      <Grid.Column width={6}>
        <Select field='maxDate' options={lastWeeksOptions} placeholder='Today' />
      </Grid.Column>
    </Grid>
  );
});

export const ACTION_TYPE_OPTIONS = [
  { value: "sends", label: 'Sends' },
  { value: "answers", label: 'Answers' },
  { value: "positiveAnswers", label: 'Positive Answers' },
  { value: "skips", label: 'Skips' },
  { value: "sendSkips", label: 'Sends + Skips' },
  { value: "recruitmentsNumber", label: 'Recruitments Number'},
  { value: "recruitmentsWage", label: 'Recruitments Wage'},
  { value: "recruitmentsRevenue", label: 'Recruitments Revenue'},
]


export const ActionFilter = enhance(() => {
  return (
    <div>
      <Select field="actionType" options={ACTION_TYPE_OPTIONS} />
      <SelectLastWeekRange field="dates" />
    </div>
  );
});


const getTypesLabel = (actionType) => {
  if (actionType == "sends") {
    return {
      types: ["send", "move-to-contacted"]
    }
  }
  if (actionType == "answers") {
    return {
      types: ["answer-detected"]
    }
  }
  if (actionType == "positiveAnswers") {
    return {
      types: ["answer-detected"],
      labels: ["positive"]
    }
  }
  if (actionType == "skips") {
    return {
      types: ["skipProfile"],
    }
  }
  if (actionType == "sendSkips") {
    return {
      types: ["skipProfile", "send", "move-to-contacted"]
    }
  }
  if (actionType == "recruitmentsNumber") {
    return {
      types: ["recruitment"],
      countMode: "number"
    }
  }
  if (actionType == "recruitmentsWage") {
    return {
      types: ["recruitment"],
      countMode: "wage"

    }
  }
  if (actionType == "recruitmentsRevenue") {
    return {
      types: ["recruitment"],
      countMode: "revenue"
    }
  }
  return {
    types: ["send", "move-to-contacted"]
  }}

export const wrapActionFilterToIdParams = (filter) => {
  const typesLabels = getTypesLabel(filter.actionType);
  const actionTypeToName = _.object(_.pluck(ACTION_TYPE_OPTIONS, 'value'), _.pluck(ACTION_TYPE_OPTIONS, 'label'))
  return {
    ...typesLabels,
    ...filter.dates,
    name: actionTypeToName[filter.actionType],
  }
}

export const ActivityFilter = customOperator({
  isActive: { label: 'Active' },
  createdBetween: { label: 'Created Between', children: 1, component: SelectLastWeekRange },
  actionBetween: { label: 'Has Action Between', children: 1, component: ActionFilter },
  //activeBetween: { label: 'Active Between', children: 1, component: SelectLastWeekRange },
}, false)


export const wrapFilterToIdParams = (filter) => {
  if ((filter || {}).selection == "actionBetween") {
    return {
      id: "actionBetween",
      params: wrapActionFilterToIdParams(filter.actionBetween || {}),
    }
  }
  let params = _.omit(filter, "selection")
  
  if ((filter || {}).activeBetween) {
    params = (filter || {}).activeBetween;
  }

  if ((filter || {}).createdBetween) {
    params = (filter || {}).createdBetween;
  }
  const splitFields = ["jobs", "seniorities", "locations", "skills", "categories"]
  _.each(splitFields, (field) => {
    if (params[field]) {
      params[field] = params[field].split(';')
    }
  })
  
  return {
    id: filter.selection,
    ...params && { params },
  }
}





const COLORS = [
  "#ff0000",
  "#ff8000",
  "#ffff00",
  "#80ff00",
  "#00ff00",
  "#00ff80",
  "#00ffff",
  "#0080ff",
  "#0000ff",
  "#8000ff",
  "#ff00ff",
  "#ff0080",
]
export const colorFromName = (name) => {
  const hashCode = Math.abs(name.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0));
  console.log(name, hashCode)
  return COLORS[hashCode % COLORS.length];
}