import React from 'react';
import _ from 'underscore';
import { Divider, Segment } from 'semantic-ui-react';

import profileDataManager from '../../SweetWork/profileData';
import ProfileHeader from '../../SweetWork/containers/ProfileHeader';
import ProfileExperiences from '../../SweetWork/containers/ProfileExperiences';
import ProfileEducations from '../../SweetWork/containers/ProfileEducations';
import ProfileSkills from '../../SweetWork/containers/ProfileSkills';
import GithubProfile from '../../SweetWork/containers/GithubProfile';
import SmartSummary from './TranslatedSmartSummary';
import { getSortedSkillsWishes } from '../../components/ClientProfile/SmartSummary';

import StackoverflowProfile from '../../SweetWork/containers/StackoverflowProfile';

const EnrichedProfileView = ({ enrichedProfile, sourceProfiles }) => {
  const profileData = profileDataManager(enrichedProfile, () => null);
  const githubSourceData = profileData.getSourceData().getGithub();
  const stackoverflowSourceData = profileData.getSourceData().getStackoverflow();
  const smartSummaryArgumentation = ((enrichedProfile || {}).smartSummary || {}).arguments;
  const shouldDisplaySmartSummary = _.isArray(smartSummaryArgumentation) && !_.isEmpty(smartSummaryArgumentation);
  const isWatchProfile = !_.isEmpty((sourceProfiles || {}).hiresweet);
  return (
    <div>
      <Segment color='blue'>
        <ProfileHeader
          firstname={profileData.getFirstname()}
          lastname={profileData.getLastname()}
          headline={profileData.getHeadline()}
          location={profileData.getLocation()}
          photoLink={profileData.getPhotoLink()}
          summary={profileData.getSummary()}
          sources={profileData.getSources()}
          relevantTags={profileData.getRelevantTags()}
          education={profileData.getEducation()}
          lastScrapDay={profileData
            .getSourceData()
            .getLinkedin()
            .getLastScrapDay()}
          //onToggleClientView={this.handleToggleClientModal}
          summaryLinkedRessources={((enrichedProfile || {}).tmp || {}).summaryLinkedRessources}
        />
        {shouldDisplaySmartSummary && (
          <SmartSummary
            isWatchProfile={isWatchProfile}
            argumentation={smartSummaryArgumentation}
            skills={getSortedSkillsWishes({ hiresweetData: profileData.getSourceData().getHiresweet() })}
          />
        )}
        <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
          Experiences
        </Divider>
        <ProfileExperiences experiences={profileData.getExperiences()} />
        <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
          Education
        </Divider>
        <ProfileEducations education={profileData.getEducation()} />
        <Divider horizontal style={{ fontFamily: 'SourceSansProSemiBold', fontSize: 12 }}>
          Skills
        </Divider>
        <ProfileSkills
          skills={profileData
            .getSourceData()
            .getLinkedin()
            .getSkills()}
        />
      </Segment>
      {((enrichedProfile || {}).sourceData || {}).github && (
        <div>
          <Divider horizontal>Github</Divider>
          <GithubProfile profile={githubSourceData} />
          {/*criterion ? (
              <OldGithubProfile source={(sourceProfiles || {}).githubProfile} more={{ "githubRepos": true }} />
            ) : (
              <GithubProfile profile={githubSourceData} />
            )*/}
        </div>
      )}

      {((enrichedProfile || {}).sourceData || {}).stackoverflow && (
        <div>
          <Divider horizontal>Stackoverflow</Divider>
          <StackoverflowProfile stackoverflowData={stackoverflowSourceData} />
        </div>
      )}
    </div>
  );
};

export default EnrichedProfileView;
