import React from 'react';
import _ from 'underscore';
import { Segment, Header } from 'semantic-ui-react';
import { Grid, Card } from 'semantic-ui-react';

export default class WorkplaceFlow extends React.Component {
  render() {
    const workplace = this.props.workplace;
    const nextPlaces = _.sortBy(workplace.flow.next_places, (item) => -item['count']).slice(0, 10);
    const previousPlaces = _.sortBy(workplace.flow.previous_places, (item) => -item['count']).slice(0, 10);

    return (
      <div>
        <Segment>
          <Header as='h3'>Average Contract Time : {workplace.flow.average_contract_duration} days</Header>
        </Segment>
        <Segment>
          <Grid>
            <Grid.Column width={8}>
              <Header as='h4' textAlign='center'>
                Next Workplaces
              </Header>
              <Card.Group>
                {_.map(nextPlaces, (nextWorkplace) => (
                  <Card>
                    <Card.Content>
                      <Card.Header>
                        <a
                          target='_blank'
                          href={'/monitoring/enrichedWorkplace/' + nextWorkplace.workplaceId}
                          rel='noopener noreferrer'
                        >
                          {' '}
                          {nextWorkplace.workplaceName}
                        </a>
                      </Card.Header>
                      <Card.Meta>{'Count : ' + Math.round(nextWorkplace.count * 100) / 100}</Card.Meta>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h4' textAlign='center'>
                Previous Workplaces
              </Header>
              <Card.Group>
                {_.map(previousPlaces, (previousWorkplace) => (
                  <Card>
                    <Card.Content>
                      <Card.Header>
                        <a
                          target='_blank'
                          href={'/monitoring/enrichedWorkplace/' + previousWorkplace.workplaceId}
                          rel='noopener noreferrer'
                        >
                          {' '}
                          {previousWorkplace.workplaceName}
                        </a>
                      </Card.Header>
                      <Card.Meta>{'Count : ' + Math.round(previousWorkplace.count * 100) / 100}</Card.Meta>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}
