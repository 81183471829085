import _ from 'underscore';
import React, { Component } from 'react';
import { Container, Button, Form, Modal } from 'semantic-ui-react';
import { SweetForm, Input, TextArea } from 'sweetform';

class ItemEditModal extends Component {
  componentWillMount() {
    const initialValues = _.object(
      _.map(this.props.editFields, ({ key, initialValue }) => [
        key,
        initialValue,
      ]),
    );
    this.setState({
      currentValue: initialValues,
      initialValues,
    });
  }
  handleChangeEdit = (newValue) => {
    this.setState({ currentValue: newValue });
  };
  handleDelete = () => {
    const { onDelete, sheetId, itemId } = this.props;
    onDelete({ sheetId, itemId });
  };
  handleSubmit = () => {
    const { onSubmit, sheetId, itemId } = this.props;
    const { initialValues, currentValue } = this.state;
    const diffObject = _.object(
      _.compact(
        _.map(_.keys(currentValue), (key) =>
          initialValues[key] !== currentValue[key]
            ? [
                key,
                currentValue[key] === '' || currentValue[key] === null
                  ? '__REMOVE'
                  : currentValue[key],
              ]
            : null,
        ),
      ),
    );

    onSubmit({
      sheetId,
      itemId,
      diffObject,
    });
  };

  render() {
    const { editFields, onClose, sheetId, itemId, withDeleteMode } = this.props;
    const { initialValues, currentValue } = this.state;

    return (
      <Modal onClose={onClose} open closeIcon>
        <Modal.Header>
          Edit Item ({sheetId}) ({itemId})
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: 300 }}>
          <Container>
            <SweetForm
              initialValues={initialValues}
              onChange={this.handleChangeEdit}
            >
              <Form>
                {_.map(
                  editFields,
                  ({ key, label, type, initialValues }, index) => (
                    <Form.Field key={index}>
                      <label>{label}</label>
                      {!type || type === 'input' ? (
                        <Input field={key} />
                      ) : type === 'textarea' ? (
                        <TextArea field={key} style={{ minHeight: 300 }} />
                      ) : null}
                    </Form.Field>
                  ),
                )}
                {withDeleteMode && (
                  <Form.Field>
                    <label>Write "DELETE" to enable the delete button</label>
                    <Input field="deleteField" />
                  </Form.Field>
                )}
              </Form>
            </SweetForm>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          {currentValue && currentValue.deleteField === 'DELETE' && (
            <Button negative onClick={this.handleDelete}>
              DELETE
            </Button>
          )}
          <Button negative onClick={onClose}>
            Cancel
          </Button>
          <Button positive onClick={this.handleSubmit}>
            Edit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ItemEditModal;
