import _ from 'underscore';
import React from 'react';
import { Modal, Grid, Button, Popup, Icon } from 'semantic-ui-react';
import { Input, SweetForm, Select } from 'sweetform';

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

const DEFAULT_WATCHER = {
  schedule: {
    type: 'interval',
    interval: 1 * minute,
    timeout: 5 * second,
    dayIntervalStr: '0-6',
    hourIntervalStr: '0-23',
  },
  checker: {
    type: 'manual-check-delay',
    url: 'http://sweethub-back.service/sentry/manualChecks',
    path: 'timestamp',
    maxDelay: 7 * day,
    headers: {
      authorization: '{secrets.sweetHubHeader}',
    },
  },
  aggregationPolicyId: 'default',
  messageDispatcherIds: ['routines'],
};

const getFormValue = ({ watcher }) => ({
  username: watcher.username,
  id: watcher.id,
  name: watcher.name,
  category: watcher.category,
  service: watcher.service,
  messageDispatcherIds: (watcher.messageDispatcherIds || []).join(';'),
  scheduleInterval: (watcher.schedule || {}).interval || 1 * minute,
  scheduleDayIntervalStr: (watcher.schedule || {}).dayIntervalStr,
  scheduleHourIntervalStr: (watcher.schedule || {}).hourIntervalStr,
  scheduleTimeout: (watcher.schedule || {}).timeout || 5 * second,
  manualCheckId: (watcher.checker || {}).manualCheckId,
  manualCheckDelay: (watcher.checker || {}).maxDelay || 7 * day,
});

const getWatcher = ({ formValue, watcher }) => ({
  ...watcher, // for creationTimestamp
  username: formValue.username,
  id: formValue.id,
  name: formValue.name,
  category: formValue.category,
  service: formValue.service,
  messageDispatcherIds: (formValue.messageDispatcherIds || '').split(';'),
  aggregationPolicyId: watcher.aggregationPolicyId,
  schedule: {
    ...watcher.schedule,
    ...(formValue.scheduleInterval && { interval: formValue.scheduleInterval }),
    ...(formValue.scheduleTimeout && { timeout: formValue.scheduleTimeout }),
    ...(formValue.scheduleDayIntervalStr && { dayIntervalStr: formValue.scheduleDayIntervalStr }),
    ...(formValue.scheduleHourIntervalStr && { hourIntervalStr: formValue.scheduleHourIntervalStr }),
  },
  checker: {
    ...watcher.checker,
    ...(formValue.manualCheckDelay && { maxDelay: formValue.manualCheckDelay }),
    ...(formValue.manualCheckId && { manualCheckId: formValue.manualCheckId }),
  },
});

const makeTimeOptions = ({ values, prefix, suffix, unit }) =>
  _.map(values, (value) => ({ value: value * unit, label: `${prefix || ''}${value}${suffix || ''}` }));

const makeOptions = ({ values }) => _.map(values, (value) => ({ value, label: value }));
class WatcherEditionModal extends React.Component {
  state = {
    formValue: {},
  };

  handleChangeForm = (value) => {
    console.log(JSON.stringify(getWatcher({ formValue: value, watcher: { ...DEFAULT_WATCHER, ...this.props.watcher } })));

    this.setState({ formValue: value });
  };

  handleSubmit = () => {
    const { formValue } = this.state;
    let newWatcher = {};
    try {
      const initialId = (this.props.watcher || {}).id;
      if (initialId && (formValue || {}).id != initialId) {
        console.log({ formValue, initialId });
        throw Error(`Cannot change id, should be ${initialId}`);
      }
      newWatcher = getWatcher({ formValue, watcher: { ...DEFAULT_WATCHER, ...this.props.watcher } });
    } catch (e) {
      return alert(e.message);
    }
    this.props.onSave({ watcher: newWatcher });
  };

  render() {
    const { onClose, watcher, existingCategories, existingServices, existingDispatchers } = this.props;
    const initialFormValue = getFormValue({ watcher: { ...DEFAULT_WATCHER, ...this.props.watcher } });

    const scheduleOptions = _.union(
      makeTimeOptions({ values: _.range(1, 60), unit: second, prefix: 'Run every ', suffix: ' seconds' }),
      makeTimeOptions({ values: _.range(1, 60), unit: minute, prefix: 'Run every ', suffix: ' minutes' }),
      makeTimeOptions({ values: _.range(1, 24), unit: hour, prefix: 'Run every ', suffix: ' hours' }),
    );

    const timeoutOptions = makeTimeOptions({
      values: _.range(1, 60),
      unit: second,
      prefix: 'Timeout after ',
      suffix: ' seconds',
    });

    const maxDelayOptions = _.union(
      makeTimeOptions({ values: _.range(1, 24), unit: hour, prefix: 'Less than ', suffix: ' hours' }),
      makeTimeOptions({ values: _.range(1, 364), unit: day, prefix: 'Less than ', suffix: ' days' }),
    );

    const LEFT_WIDTH = 2;
    const RIGHT_WIDTH = 12;
    return (
      <Modal open={true} onClose={onClose} closeIcon closeOnDimmerClick={false}>
        <Modal.Header>
          {(watcher || {}).name} ({(watcher || {}).id})
        </Modal.Header>
        <Modal.Content style={{ minHeight: 500 }}>
          <div>
            <SweetForm initialValues={initialFormValue} onChange={this.handleChangeForm}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>ID</label>
                  </Grid.Column>
                  <Grid.Column width={RIGHT_WIDTH}>
                    <Input field='id' disabled={!!initialFormValue.id} />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Username</label>
                  </Grid.Column>
                  <Grid.Column width={RIGHT_WIDTH}>
                    <Input field='username' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Name</label>
                  </Grid.Column>
                  <Grid.Column width={RIGHT_WIDTH}>
                    <Input field='name' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Category</label>
                  </Grid.Column>
                  <Grid.Column width={RIGHT_WIDTH}>
                    <Select creatable options={makeOptions({ values: existingCategories })} field='category' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Service</label>
                  </Grid.Column>
                  <Grid.Column width={RIGHT_WIDTH}>
                    <Select creatable options={makeOptions({ values: existingServices })} field='service' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Dispatchers</label>
                  </Grid.Column>
                  <Grid.Column width={RIGHT_WIDTH}>
                    <Select
                      multi
                      creatable
                      options={makeOptions({ values: existingDispatchers })}
                      field='messageDispatcherIds'
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Manual check</label>
                  </Grid.Column>
                  <Grid.Column width={Math.floor(RIGHT_WIDTH / 2)}>
                    <Select field='manualCheckDelay' options={maxDelayOptions} />
                  </Grid.Column>
                  <Grid.Column width={Math.floor(RIGHT_WIDTH / 2)}>
                    <Input field='manualCheckId' placeholder='manualCheckId' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                  <Grid.Column width={LEFT_WIDTH}>
                    <label>Schedule</label>
                  </Grid.Column>
                  <Grid.Column width={Math.floor(RIGHT_WIDTH / 4)}>
                    <Select field='scheduleInterval' options={scheduleOptions} />
                  </Grid.Column>
                  <Grid.Column width={Math.floor(RIGHT_WIDTH / 4)}>
                    <Input field='scheduleDayIntervalStr' placeholder='Days 0-6' />
                    <Popup
                      content={<div>0-6 = from sunday to saturday (included)</div>}
                      trigger={<Icon name='question' />}
                    />
                  </Grid.Column>
                  <Grid.Column width={Math.floor(RIGHT_WIDTH / 4)}>
                    <Input field='scheduleHourIntervalStr' placeholder='0-23' />
                    <Popup
                      content={<div>Ex: 9-19 = from 9h00 to 19h59 (paris time)</div>}
                      trigger={<Icon name='question' />}
                    />
                  </Grid.Column>
                  <Grid.Column width={Math.floor(RIGHT_WIDTH / 4)}>
                    <Select field='scheduleTimeout' options={timeoutOptions} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SweetForm>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleSubmit}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default WatcherEditionModal;
