import _ from 'underscore';

/**********
 ** UTILS **
 **********/

const getYearMonthFromRawDate = (raw) => {
  if (!raw) {
    return []
  } else if (_.isString(raw)) {
    return (raw.split(' ')).reverse();
  } else if (_.isNumber(raw)) {
    return [raw, null];
  } else {
    return [];
  }
}

const getMonthFromString = (raw) => {
  const monthToId = {
    'jan': 0,
    'fev': 1,
    'fév': 1,
    'feb': 1,
    'mar': 2,
    'avr': 3,
    'apr': 3,
    'mai': 4,
    'may': 4,
    'juin': 5,
    'jun': 5,
    'juil': 6,
    'jul': 6,
    'ao': 7,
    'aug': 7,
    'sep': 8,
    'oct': 9,
    'nov': 10,
    'dec': 11,
    'déc': 11,
  };
  let month = null
  _.each(monthToId, (value, key) => {
    if (('' + raw).toLowerCase().indexOf(key) >= 0) {
      month = value;
    }
  })
  return month;
}

const getDateObjFromRaw = (raw) => {
  const [yearString, monthString] = getYearMonthFromRawDate(raw);
  const month = monthString && getMonthFromString(monthString);
  const year = yearString && parseInt(yearString, 10) || null;
  return {
    raw,
    month: _.isNumber(month) ? month : null,
    year
  }
}

const getDatesFromRawDates = (rawDates) => {
  const start = rawDates && rawDates.length > 0 && rawDates[0];
  const end = rawDates && rawDates.length > 1 && rawDates[1];
  const startDate = start && getDateObjFromRaw(start);
  const endDate = end && getDateObjFromRaw(end);
  return { startDate, endDate };
}


const getTextFromHTML = html => {
  const formattedText = html && html.replace(/<br\s?\/?>/, '\n');
  return formattedText;
}


/*********
 ** WRAP **
 *********/

const getTextWithEnrichments = (x) => {
  if (!x) {
    return null;
  }
  if (_.isObject(x) && x.text) {
    return x;
  }
  if (_.isString(x)) {
    return { text: x }
  }
  return null;
}

const getLocation = (location) => {
  if (!location) {
    return null;
  }
  if (_.isObject(location) && location.name) {
    return location;
  }
  if (_.isString(location)) {
    return { name: { default: location } };
  }
  return null;
}

const getExperiencesFromLinkedinData = (linkedinData) => {
  if (!linkedinData) return [];
  return _.map(linkedinData.experiences, ({ location, companyName, experienceName, descriptionHTML, companyWebsite, rawDates }) => {
    const { startDate, endDate } = getDatesFromRawDates(rawDates);
    return {
      location: getLocation(location),
      companyName,
      title: getTextWithEnrichments(experienceName),
      description: getTextWithEnrichments(getTextFromHTML(descriptionHTML)),
      startDate,
      ...endDate && { endDate },
      photoLink: null,
      companyWebsite,
    }
  })
}

const getEducationFromLinkedinData = (linkedinData) => {
  if (!linkedinData) return []
  return _.map(linkedinData.education, ({ schoolName, degree, description, rawDates }) => {
    const { startDate, endDate } = getDatesFromRawDates(rawDates);
    return {
      schoolName,
      degree: getTextWithEnrichments(degree),
      description: getTextWithEnrichments(description),
      ...startDate && { startDate },
      ...endDate && { endDate },
      photoLink: null,
      schoolWebsite: null,
    }
  })
}

const getSkillsFromLinkedinData = (linkedinData) => {
  return _.map(linkedinData.skills, ({ tag, num }) => ({
    label: { default: tag },
    endorsementsCount: num
  }));
}

const getProfileV5FromLinkedinV4 = ({ linkedinId, linkedinData }) => {
  return {
    firstname: linkedinData.firstname,
    lastname: linkedinData.lastname,
    headline: _.isString(linkedinData.headline) ? (
      { content: { text: linkedinData.headline } }
    ) : _.isObject((linkedinData.headline || {}).content) ? (
      linkedinData.headline
    ) : null,
    summary: _.isString(linkedinData.summary) ? (
      { content: { text: linkedinData.summary } }
    ) : _.isObject((linkedinData.summary || {}).content) ? (
      linkedinData.summary
    ) : null,
    photoLink: linkedinData.photoLink,
    sources: {
      linkedin: 'https://linkedin.com/in/' + linkedinId
    },
    experiences: getExperiencesFromLinkedinData(linkedinData),
    education: getEducationFromLinkedinData(linkedinData),
    sourceData: {
      linkedin: {
        skills: getSkillsFromLinkedinData(linkedinData)
      }
    }
  }; 
}

export default getProfileV5FromLinkedinV4;
