import React from 'react';
import { Table, Form, Button, Icon } from 'semantic-ui-react';
import _ from 'underscore';
import SearchWithOptions from './SearchWithOptions';

import MiniLocation from './MiniLocation';

export default class WeightedLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleChange = (e, { name, value }, match, weightedLocation) => {
    this.setState({ [name]: value });
    this.props.onSubmitProba(match, weightedLocation, value);
  };

  render() {
    return (
      <div>
        {this.props.weightedLocations ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>Proba</Table.Cell>
                <Table.Cell>Location</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(this.props.weightedLocations, (weightedLocation) => (
                <Table.Row key={weightedLocation.key}>
                  <Table.Cell>
                    {this.props.editable ? (
                      <Button
                        icon
                        onClick={() => this.props.deleteWeightedLocation(this.props.match, weightedLocation)}
                        negative
                      >
                        <Icon name="close" />
                      </Button>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>
                    {this.props.editable ? (
                      <div>
                        <Form
                          onSubmit={() =>
                            this.props.onSubmitProba(this.props.match, weightedLocation, this.state.proba)
                          }
                        >
                          <Form.Input
                            name="proba"
                            onChange={(e, v) => this.handleChange(e, v, this.props.match, weightedLocation)}
                            defaultValue={weightedLocation.proba}
                          />
                        </Form>
                        {weightedLocation.proba}
                      </div>
                    ) : (
                      weightedLocation.proba
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {this.props.editable ? (
                      <SearchWithOptions
                        handleSubmit={(search) =>
                          this.props.handleLocationSearchSubmit(this.props.match, weightedLocation, search)
                        }
                        placeholder={'Search location...'}
                        loadOptions={this.props.loadOptions}
                      />
                    ) : null}
                    <MiniLocation location={weightedLocation.location} />
                  </Table.Cell>
                </Table.Row>
              ))}
              {this.props.editable ? (
                <Table.Row>
                  <Table.Cell>
                    <Button icon onClick={() => this.props.addNewWeightedLocation(this.props.match)}>
                      <Icon name="plus" />
                    </Button>
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              ) : null}
            </Table.Body>
          </Table>
        ) : null}
      </div>
    );
  }
}
