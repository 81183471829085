import _ from 'underscore';

import { formatEntities, formatDisciplines } from './utils';

const formatDates = (duration, current, startDate, endDate) => {
  return ({
    duration,
    current,
    startDate,
    endDate
  })
}


const formatProfile = ({id, data}) => {
  const experiences = []
  _.each(_.range(data["CV_Jobtitle_Raw"].length), (i)=>{
    experiences.push({
      title: {
        text: data["CV_Jobtitle_Raw"][i],
        entities: formatEntities({
          norms: data["CV_Jobtitle_Norm"][i],
          conceptIds: data["CV_Jobtitle_Concept ID"][i],
          lemmaIds: data["CV_Jobtitle_Lemma ID"][i],
        })
      },
      disciplines: formatDisciplines({
        norms: data["CV_Disc_Norm"][i],
        ids: data["CV_Disc_ID"][i],
      }),
      subDisciplines: formatDisciplines({
        norms: data["CV_Subdisc_Norm"][i],
        ids: data["CV_Subdisc_ID"][i],
      }),
      description: data["CV_Full_Experience"][i],
      dates: formatDates(
        data["CV_Jobtitle_Duration"][i], 
        data["CV_Jobtitle_Current"][i], 
        data["CV_Jobtitle_Startdate"][i], 
        data["CV_Jobtitle_Enddate"][i],
      ),
      rawDates : formatDates(
        null, 
        data["CV_Jobtitle_Raw_Current"][i], 
        data["CV_Jobtitle_Raw_Startdate"][i], 
        data["CV_Jobtitle_Raw_Enddate"][i],
      ),
    })
  })

  const education = []
  _.each(_.range(data["CV_Education_Subject"].length), (i)=>{
    education.push({
      subject: data["CV_Education_Subject"][i],
      schoolName: data["CV_School_Name"][i],
      year: data["CV_Education_Year"][i],
      level: data["CV_Education_Level"][i],
      item: data["CV_Education_Item"][i],
    })
  })

  const meta = {
    nbExperiences: data["Number_Experiences"],
    nbSkills: data["Number_Skills_CV"],
    nbEducations: data["Number_Educations"],
    monthsOfExperience: data["Months_Of_Experience"],
    averageDurationPerJob: data["Avg_Duration_Per_Job"],
    monthsUnemployed: data["Months_Unemployed"],
    longitude: data["Candidate_Longitude"],
    latitude: data["Candidate_Latitude"],
    dateOfBirth: data["Candidate_Date_Of_Birth"],
    gender: data["Candidate_Gender"],
    race: data["Candidate_Race"],
    disability: data["Candidate_Disability"],
    educationLevel: data["Education_Level"],
    language: data["Candidate_Language"],
    category: data["CV_Category"],
    subcategory: data["CV_Subcategory"],
    sector: data["CV_Sector"],
  }
  
  const skills = {
    texts: data["CV_Skills_Raw"],
    entities: formatEntities({
      norms: _.flatten(data["CV_Skills_Norm"]),
      conceptIds: _.flatten(data["CV_Skills_Concept_ID"]),
      lemmaIds: null,
    })
  }

  return {
    id,
    meta,
    experiences,
    education,
    skills
  }
};

export default formatProfile;