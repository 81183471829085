const baseUrl = 'https://assets.hiresweet.com/technos/';

export default {
  'html': {
    icon: baseUrl + 'html5__icon.svg',
    name: { default: 'HTML' }
  },
  'html5': {
    icon: baseUrl + 'html5__icon.svg',
    name: { default: 'HTML5' }
  },
  'css': {
    icon: baseUrl + 'css3__icon.svg',
    name: { default: 'CSS' }
  },
  'css3': {
    icon: baseUrl + 'css3__icon.svg',
    name: { default: 'CSS3' }
  },
  'sass': {
    icon: baseUrl + 'sass__icon.svg',
    name: { default: 'SASS' }
  },
  'bootstrap': {
    icon: baseUrl + 'bootstrap__icon.svg',
    name: { default: 'Bootstrap' },
    detailsURL: baseUrl + 'bootstrap__details.json',
  },
  'javascript': {
    icon: baseUrl + 'javascript__icon.svg',
    name: { default: 'JavaScript' },
    detailsURL: baseUrl + 'javascript__details.json',
  },
  'typescript': {
    icon: baseUrl + 'typescript__icon.svg',
    name: { default: 'TypeScript' },
    detailsURL: baseUrl + 'typescript__details.json',
  },
  'react.js': {
    icon: baseUrl + 'react_js__icon.svg',
    name: { default: 'React' },
    detailsURL: baseUrl + 'react_js__details.json',
  },
  'react-native': {
    icon: baseUrl + 'react_js__icon.svg',
    name: { default: 'React Native' },
    detailsURL: baseUrl + 'react_native__details.json',
  },
  'angular.js': {
    icon: baseUrl + 'angular_js__icon.svg',
    name: { default: 'Angular' },
    detailsURL: baseUrl + 'angular_js__details.json',
  },
  'angular2.js': {
    icon: baseUrl + 'angular_js__icon.svg',
    name: { default: 'Angular 2' },
    detailsURL: baseUrl + 'angular_js__details.json',
  },
  'angular-4': {
    icon: baseUrl + 'angular_js__icon.svg',
    name: { default: 'Angular 4' },
    detailsURL: baseUrl + 'angular_js__details.json',
  },
  'angular-5': {
    icon: baseUrl + 'angular_js__icon.svg',
    name: { default: 'Angular 5' },
    detailsURL: baseUrl + 'angular_js__details.json',
  },
  'angular-6': {
    icon: baseUrl + 'angular_js__icon.svg',
    name: { default: 'Angular 6' },
    detailsURL: baseUrl + 'angular_js__details.json',
  },
  'angular-7': {
    icon: baseUrl + 'angular_js__icon.svg',
    name: { default: 'Angular 7' },
    detailsURL: baseUrl + 'angular_js__details.json',
  },
  'node.js': {
    icon: baseUrl + 'node_js__icon.svg',
    name: { default: 'Node' },
    detailsURL: baseUrl + 'node_js__details.json',
  },
  'redux.js': {
    icon: baseUrl + 'redux_js__icon.svg',
    name: { default: 'Redux' },
    detailsURL: baseUrl + 'redux_js__details.json',
  },
  'jquery.js': {
    icon: baseUrl + 'jquery_js__icon.svg',
    name: { default: 'jQuery' }
  },
  'express.js': {
    icon: baseUrl + 'express_js__icon.svg',
    name: { default: 'Express' }
  },
  'backbone.js': {
    icon: baseUrl + 'backbone_js__icon.svg',
    name: { default: 'Backbone' }
  },
  'knockout.js': {
    icon: baseUrl + 'knockout_js__icon.png',
    name: { default: 'Knockout' }
  },
  'meteor.js': {
    icon: baseUrl + 'meteor_js__icon.svg',
    name: { default: 'Meteor' },
    detailsURL: baseUrl + 'meteor_js__details.json',
  },
  'vue.js': {
    icon: baseUrl + 'vue_js__icon.svg',
    name: { default: 'Vue' },
    detailsURL: baseUrl + 'vue_js__details.json',
  },
  'marionette.js': {
    icon: baseUrl + 'marionette_js__icon.svg',
    name: { default: 'Marionette' }
  },
  'ember.js': {
    icon: baseUrl + 'ember_js__icon.svg',
    name: { default: 'Ember' }
  },
  'aurelia.js': {
    icon: baseUrl + 'aurelia_js__icon.svg',
    name: { default: 'Aurelia' }
  },
  'd3.js': {
    icon: baseUrl + 'd3_js__icon.svg',
    name: { default: 'D3' }
  },
  'raphael.js': {
    icon: baseUrl + 'raphael_js__icon.svg',
    name: { default: 'Raphaël' }
  },
  'twilio.js': {
    icon: baseUrl + 'twilio_js__icon.svg',
    name: { default: 'Twilio' }
  },
  'chai.js': {
    icon: baseUrl + 'chai_js__icon.svg',
    name: { default: 'Chai' }
  },
  'gulp.js': {
    icon: baseUrl + 'gulp_js__icon.svg',
    name: { default: 'Gulp' }
  },
  'sail.js': {
    icon: baseUrl + 'sails_js__icon.svg',
    name: { default: 'Sails' }
  },
  'sails.js': {
    icon: baseUrl + 'sails_js__icon.svg',
    name: { default: 'Sails' }
  },
  'feathers': {
    icon: baseUrl + 'feathers__icon.svg',
    name: { default: 'Feathers' }
  },
  'python': {
    icon: baseUrl + 'python__icon.svg',
    name: { default: 'Python' },
    detailsURL: baseUrl + 'python__details.json',
  },
  'django': {
    icon: baseUrl + 'django__icon.svg',
    name: { default: 'Django' },
    detailsURL: baseUrl + 'django__details.json',
  },
  'flask': {
    icon: baseUrl + 'flask__icon.svg',
    name: { default: 'Flask' },
    detailsURL: baseUrl + 'flask__details.json',
  },
  'ruby': {
    icon: baseUrl + 'ruby__icon.svg',
    name: { default: 'Ruby' },
    detailsURL: baseUrl + 'ruby__details.json',
  },
  'ruby-on-rails': {
    icon: baseUrl + 'ruby-on-rails__icon.svg',
    name: { default: 'Ruby on Rails' },
    detailsURL: baseUrl + 'ruby-on-rails__details.json',
  },
  'rust': {
    icon: baseUrl + 'rust__icon.svg',
    name: { default: 'Rust' }
  },
  "c": {
    icon: baseUrl + 'c__icon.svg',
    name: { default: 'C' }
  },
  "cpp": {
    icon: baseUrl + 'cpp__icon.svg',
    name: { default: 'C++' },
    detailsURL: baseUrl + 'cpp__details.json',
  },
  "cpp11": {
    icon: baseUrl + 'cpp__icon.svg',
    name: { default: 'C++11' },
    detailsURL: baseUrl + 'cpp__details.json',
  },
  "cpp14": {
    icon: baseUrl + 'cpp__icon.svg',
    name: { default: 'C++14' },
    detailsURL: baseUrl + 'cpp__details.json',
  },
  "cpp17": {
    icon: baseUrl + 'cpp__icon.svg',
    name: { default: 'C++17' },
    detailsURL: baseUrl + 'cpp__details.json',
  },
  'apache': {
    icon: baseUrl + 'apache__icon.svg',
    name: { default: 'Apache' }
  },
  'caffe': {
    icon: baseUrl + 'caffe__icon.svg',
    name: { default: 'Caffe' }
  },
  'php': {
    icon: baseUrl + 'php__icon.svg',
    name: { default: 'PHP' },
    detailsURL: baseUrl + 'php__details.json',
  },
  'php4': {
    icon: baseUrl + 'php__icon.svg',
    name: { default: 'PHP4' },
    detailsURL: baseUrl + 'php__details.json',
  },
  'php5': {
    icon: baseUrl + 'php__icon.svg',
    name: { default: 'PHP5' },
    detailsURL: baseUrl + 'php__details.json',
  },
  'cake-php': {
    icon: baseUrl + 'cake-php__icon.svg',
    name: { default: 'CakePHP' }
  },
  'code-igniter': {
    icon: baseUrl + 'code-igniter__icon.svg',
    name: { default: 'CodeIgniter' }
  },
  'doctrine': {
    icon: baseUrl + 'doctrine__icon.svg',
    name: { default: 'Doctrine' }
  },
  'fsharp': {
    icon: baseUrl + 'fsharp__icon.svg',
    name: { default: 'F#' }
  },
  'lucene': {
    icon: baseUrl + 'lucene__icon.svg',
    name: { default: 'Lucene' }
  },
  'capistrano': {
    icon: baseUrl + 'capistrano__icon.svg',
    name: { default: 'Capistrano' }
  },
  'android-development': {
    icon: baseUrl + 'android-development__icon.svg',
    name: { default: 'Android' },
    detailsURL: baseUrl + 'android-development__details.json',
  },
  'ios-development': {
    icon: baseUrl + 'ios-development__icon.svg',
    name: { default: 'iOS' },
    detailsURL: baseUrl + 'ios-development__details.json',
  },
  'swift': {
    icon: baseUrl + 'swift__icon.svg',
    name: { default: 'Swift' },
    detailsURL: baseUrl + 'swift__details.json',
  },
  'ionic': {
    icon: baseUrl + 'ionic__icon.svg',
    name: { default: 'Ionic' }
  },
  'cordova': {
    icon: baseUrl + 'cordova__icon.svg',
    name: { default: 'Cordova' },
    detailsURL: baseUrl + 'cordova__details.json',
  },
  'bower': {
    icon: baseUrl + 'bower__icon.svg',
    name: { default: 'Bower' }
  },
  'invision': {
    icon: baseUrl + 'invision__icon.svg',
    name: { default: 'InVision' }
  },
  'influxdb': {
    icon: baseUrl + 'influxdb__icon.svg',
    name: { default: 'InfluxDB' }
  },
  'mariadb': {
    icon: baseUrl + 'mariadb__icon.svg',
    name: { default: 'MariaDB' }
  },
  'dynamodb': {
    icon: baseUrl + 'dynamodb__icon.svg',
    name: { default: 'DynamoDB' }
  },
  'mongodb': {
    icon: baseUrl + 'mongodb__icon.png',
    name: { default: 'MongoDB' },
    detailsURL: baseUrl + 'mongodb__details.json',
  },
  'cassandra': {
    icon: baseUrl + 'cassandra__icon.svg',
    name: { default: 'Cassandra' }
  },
  'couchdb': {
    icon: baseUrl + 'couchdb__icon.svg',
    name: { default: 'CouchDB' }
  },
  'memcached': {
    icon: baseUrl + 'memcached__icon.svg',
    name: { default: 'Memcached' }
  },
  'redis': {
    icon: baseUrl + 'redis__icon.svg',
    name: { default: 'Redis' }
  },

  'mysql': {
    icon: baseUrl + 'mysql__icon.svg',
    name: { default: 'MySQL' },
    detailsURL: baseUrl + 'mysql__details.json',
  },
  'elastic-search': {
    icon: baseUrl + 'elastic-search__icon.svg',
    name: { default: 'Elasticsearch' }
  },
  'hbase': {
    icon: baseUrl + 'hbase__icon.svg',
    name: { default: 'HBase' }
  },
  'windows-azure': {
    icon: baseUrl + 'windows-azure__icon.svg',
    name: { default: 'Microsoft Azure' },
    detailsURL: baseUrl + 'windows-azure__details.json',
  },
  'amazon-web-services': {
    icon: baseUrl + 'amazon-web-services__icon.svg',
    name: { default: 'Amazon Web Services' },
    detailsURL: baseUrl + 'amazon-web-services__details.json',
  },
  'docker': {
    icon: baseUrl + 'docker__icon.svg',
    name: { default: 'Docker' },
    detailsURL: baseUrl + 'docker__details.json',
  },
  'airflow': {
    icon: baseUrl + 'airflow__icon.svg',
    name: { default: 'Airflow' }
  },
  'ansible': {
    icon: baseUrl + 'ansible__icon.svg',
    name: { default: 'Ansible' },
    detailsURL: baseUrl + 'ansible__details.json',
  },
  'chef': {
    icon: baseUrl + 'chef__icon.svg',
    name: { default: 'Chef' }
  },
  'circleci': {
    icon: baseUrl + 'circleci__icon.svg',
    name: { default: 'CircleCI' },
    detailsURL: baseUrl + 'circleci__details.json',
  },
  'clojure': {
    icon: baseUrl + 'clojure__icon.svg',
    name: { default: 'Clojure' }
  },
  'centos': {
    icon: baseUrl + 'centos__icon.svg',
    name: { default: 'CentOS' }
  },
  'debian': {
    icon: baseUrl + 'debian__icon.svg',
    name: { default: 'Debian' }
  },
  'redhat': {
    icon: baseUrl + 'redhat__icon.svg',
    name: { default: 'Red Hat' }
  },
  'fedora': {
    icon: baseUrl + 'fedora__icon.svg',
    name: { default: 'Fedora' }
  },
  'freebsd': {
    icon: baseUrl + 'freebsd__icon.svg',
    name: { default: 'FreeBSD' }
  },

  'bash': {
    icon: baseUrl + 'bash__icon.svg',
    name: { default: 'Bash' }
  },

  'arduino': {
    icon: baseUrl + 'arduino__icon.svg',
    name: { default: 'Arduino' }
  },
  
  'mocha': {
    icon: baseUrl + 'mocha__icon.svg',
    name: { default: 'Mocha' }
  },
  'compass': {
    icon: baseUrl + 'compass__icon.svg',
    name: { default: 'Compass' }
  },
  'cucumber': {
    icon: baseUrl + 'cucumber__icon.svg',
    name: { default: 'Cucumber' }
  },
  'dart': {
    icon: baseUrl + 'cucumber__icon.svg',
    name: { default: 'Dart' }
  },
  'coffeescript': {
    icon: baseUrl + 'coffeescript__icon.svg',
    name: { default: 'CoffeeScript' }
  },
  'dot-net': {
    icon: baseUrl + 'dot-net__icon.svg',
    name: { default: '.NET' },
    detailsURL: baseUrl + 'dot-net__details.json',
  },
  'electron': {
    icon: baseUrl + 'electron__icon.svg',
    name: { default: 'Electron' }
  },
  'drupal': {
    icon: baseUrl + 'drupal__icon.svg',
    name: { default: 'Drupal' }
  },
  'magento': {
    icon: baseUrl + 'magento__icon.svg',
    name: { default: 'Magento' }
  },
  'wordpress': {
    icon: baseUrl + 'wordpress__icon.svg',
    name: { default: 'WordPress' }
  },
  'erlang': {
    icon: baseUrl + 'erlang__icon.svg',
    name: { default: 'Erlang' }
  },
  'ecmascript6': {
    icon: baseUrl + 'ecmascript6__icon.svg',
    name: { default: 'ES6' }
  },
  'eslint': {
    icon: baseUrl + 'eslint__icon.svg',
    name: { default: 'ESLint' }
  },
  'firebase': {
    icon: baseUrl + 'firebase__icon.svg',
    name: { default: 'Firebase' }
  },

  'mercurial': {
    icon: baseUrl + 'mercurial__icon.svg',
    name: { default: 'Mercurial' }
  },
  'svn': {
    icon: baseUrl + 'svn__icon.svg',
    name: { default: 'SVN' }
  },
  'git': {
    icon: baseUrl + 'git__icon.svg',
    name: { default: 'Git' }
  },
  'github': {
    icon: baseUrl + 'github__icon.svg',
    name: { default: 'GitHub' }
  },
  'gitlab': {
    icon: baseUrl + 'gitlab__icon.svg',
    name: { default: 'GitLab' }
  },
  'gradle': {
    icon: baseUrl + 'gradle__icon.svg',
    name: { default: 'Gradle' }
  },
  'grails': {
    icon: baseUrl + 'grails__icon.svg',
    name: { default: 'Grails' }
  },
  'graphql': {
    icon: baseUrl + 'graphql__icon.svg',
    name: { default: 'GraphQL' },
    detailsURL: baseUrl + 'graphql__details.json',
  },
  'grunt': {
    icon: baseUrl + 'grunt__icon.svg',
    name: { default: 'Grunt' }
  },
  'gwt': {
    icon: baseUrl + 'gwt__icon.svg',
    name: { default: 'GWT' }
  },
  'hadoop': {
    icon: baseUrl + 'hadoop__icon.svg',
    name: { default: 'Hadoop' }
  },
  'hapi': {
    icon: baseUrl + 'hapi__icon.svg',
    name: { default: 'Hapi' }
  },
  'haskell': {
    icon: baseUrl + 'haskell__icon.svg',
    name: { default: 'Haskell' }
  },
  'haxe': {
    icon: baseUrl + 'haxe__icon.svg',
    name: { default: 'Haxe' }
  },
  'heroku': {
    icon: baseUrl + 'heroku__icon.svg',
    name: { default: 'Heroku' }
  },
  'hibernate': {
    icon: baseUrl + 'hibernate__icon.svg',
    name: { default: 'Hibernate' }
  },
  'jasmine': {
    icon: baseUrl + 'jasmine__icon.svg',
    name: { default: 'Jasmine' }
  },
  'java': {
    icon: baseUrl + 'java__icon.svg',
    name: { default: 'Java' },
    detailsURL: baseUrl + 'java__details.json',
  },
  'jenkins': {
    icon: baseUrl + 'jenkins__icon.svg',
    name: { default: 'Jenkins' }
  },
  'jest': {
    icon: baseUrl + 'jest__icon.svg',
    name: { default: 'Jest' }
  },
  'jira': {
    icon: baseUrl + 'jira__icon.svg',
    name: { default: 'Jira' }
  },
  'joomla': {
    icon: baseUrl + 'joomla__icon.svg',
    name: { default: 'Joomla!' }
  },
  'json': {
    icon: baseUrl + 'json__icon.svg',
    name: { default: 'JSON' }
  },
  'kafka': {
    icon: baseUrl + 'kafka__icon.svg',
    name: { default: 'Kafka' }
  },
  'kibana': {
    icon: baseUrl + 'kibana__icon.svg',
    name: { default: 'Kibana' }
  },
  'kotlin': {
    icon: baseUrl + 'kotlin__icon.svg',
    name: { default: 'Kotlin' },
    detailsURL: baseUrl + 'kotlin__details.json',
  },
  'kubernetes': {
    icon: baseUrl + 'kubernetes__icon.svg',
    name: { default: 'Kubernetes' },
    detailsURL: baseUrl + 'kubernetes__details.json',
  },
  'laravel': {
    icon: baseUrl + 'laravel__icon.svg',
    name: { default: 'Laravel' },
    detailsURL: baseUrl + 'laravel__details.json',
  },
  'symfony': {
    icon: baseUrl + 'symfony__icon.svg',
    name: { default: 'Symfony' },
    detailsURL: baseUrl + 'symfony__details.json',
  },
  'symfony2': {
    icon: baseUrl + 'symfony__icon.svg',
    name: { default: 'Symfony 2' },
    detailsURL: baseUrl + 'symfony__details.json',
  },
  'symfony3': {
    icon: baseUrl + 'symfony__icon.svg',
    name: { default: 'Symfony 3' },
    detailsURL: baseUrl + 'symfony__details.json',
  },
  'symfony4': {
    icon: baseUrl + 'symfony__icon.svg',
    name: { default: 'Symfony 4' },
    detailsURL: baseUrl + 'symfony__details.json',
  },

  'less': {
    icon: baseUrl + 'less__icon.svg',
    name: { default: 'LESS' }
  },
  'leveldb': {
    icon: baseUrl + 'leveldb__icon.svg',
    name: { default: 'LevelDB' }
  },
  'lodash': {
    icon: baseUrl + 'lodash__icon.svg',
    name: { default: 'Lodash' }
  },
  'lua': {
    icon: baseUrl + 'lua__icon.svg',
    name: { default: 'Lua' }
  },
  'neo4j': {
    icon: baseUrl + 'neo4j__icon.svg',
    name: { default: 'Neo4j' }
  },
  'netbeans': {
    icon: baseUrl + 'netbeans__icon.svg',
    name: { default: 'NetBeans' }
  },
  'nginx': {
    icon: baseUrl + 'nginx__icon.svg',
    name: { default: 'NGINX' }
  },
  'oauth': {
    icon: baseUrl + 'oauth__icon.svg',
    name: { default: 'OAuth' }
  },
  'opencv': {
    icon: baseUrl + 'opencv__icon.svg',
    name: { default: 'OpenCV' }
  },
  'opengl': {
    icon: baseUrl + 'opengl__icon.svg',
    name: { default: 'OpenGL' }
  },
  'perl': {
    icon: baseUrl + 'perl__icon.svg',
    name: { default: 'Perl' }
  },
  'phoenix': {
    icon: baseUrl + 'phoenix__icon.svg',
    name: { default: 'Phoenix' }
  },
  'presto': {
    icon: baseUrl + 'presto__icon.svg',
    name: { default: 'Presto' }
  },
  'puppet': {
    icon: baseUrl + 'puppet__icon.svg',
    name: { default: 'Puppet' }
  },
  'qt': {
    icon: baseUrl + 'qt__icon.svg',
    name: { default: 'Qt' }
  },
  'rabbitmq': {
    icon: baseUrl + 'rabbitmq__icon.svg',
    name: { default: 'RabbitMQ' }
  },
  'realm': {
    icon: baseUrl + 'realm__icon.svg',
    name: { default: 'Realm' }
  },
  'salesforce': {
    icon: baseUrl + 'salesforce__icon.svg',
    name: { default: 'Salesforce' }
  },
  'salesforce-developer': {
    icon: baseUrl + 'salesforce__icon.svg',
    name: { default: 'Salesforce' }
  },
  'salt': {
    icon: baseUrl + 'salt__icon.svg',
    name: { default: 'Salt' }
  },
  'scala': {
    icon: baseUrl + 'scala__icon.svg',
    name: { default: 'Scala' },
    detailsURL: baseUrl + 'scala__details.json',
  },
  'selenium': {
    icon: baseUrl + 'selenium__icon.svg',
    name: { default: 'Selenium' }
  },
  'semaphore': {
    icon: baseUrl + 'semaphore__icon.svg',
    name: { default: 'Semaphore' }
  },
  'sinatra': {
    icon: baseUrl + 'sinatra__icon.svg',
    name: { default: 'Sinatra' }
  },
  'sketch': {
    icon: baseUrl + 'sketch__icon.svg',
    name: { default: 'Sketch' }
  },
  'socket.io': {
    icon: baseUrl + 'socket_io__icon.svg',
    name: { default: 'Socket.IO' }
  },
  'solr': {
    icon: baseUrl + 'solr__icon.svg',
    name: { default: 'Solr' }
  },
  'spark': {
    icon: baseUrl + 'spark__icon.svg',
    name: { default: 'Spark' }
  },
  'spring': {
    icon: baseUrl + 'spring__icon.svg',
    name: { default: 'Spring' }
  },
  'struts': {
    icon: baseUrl + 'struts__icon.svg',
    name: { default: 'Struts' }
  },
  'terraform': {
    icon: baseUrl + 'terraform__icon.svg',
    name: { default: 'Terraform' },
    detailsURL: baseUrl + 'terraform__details.json',
  },
  'tomcat': {
    icon: baseUrl + 'tomcat__icon.svg',
    name: { default: 'Tomcat' }
  },
  'travis': {
    icon: baseUrl + 'travis__icon.svg',
    name: { default: 'Travis CI' }
  },
  'unity': {
    icon: baseUrl + 'unity__icon.svg',
    name: { default: 'Unity' }
  },
  'unity3d': {
    icon: baseUrl + 'unity__icon.svg',
    name: { default: 'Unity 3D' }
  },
  'vagrant': {
    icon: baseUrl + 'vagrant__icon.svg',
    name: { default: 'Vagrant' }
  },
  'webpack': {
    icon: baseUrl + 'webpack__icon.svg',
    name: { default: 'webpack' }
  },
  'webrtc': {
    icon: baseUrl + 'webrtc__icon.svg',
    name: { default: 'WebRTC' },
    detailsURL: baseUrl + 'webrtc__details.json',
  },
  'xamarin': {
    icon: baseUrl + 'xamarin__icon.svg',
    name: { default: 'Xamarin' }
  },
  'yarn': {
    icon: baseUrl + 'yarn__icon.svg',
    name: { default: 'Yarn' }
  },
  'zend': {
    icon: baseUrl + 'zend__icon.svg',
    name: { default: 'Zend' },
    detailsURL: baseUrl + 'zend__details.json',
  },
  'amazon-aurora': {
    icon: baseUrl + 'amazon-aurora__icon.svg',
    name: { default: 'Amazon Aurora' }
  },
  'amazon-rds': {
    icon: baseUrl + 'amazon-rds__icon.svg',
    name: { default: 'Amazon RDS' }
  },
  'android-sdk': {
    icon: baseUrl + 'android-development__icon.svg',
    name: { default: 'Android SDK' }
  },
  'android-studio': {
    icon: baseUrl + 'android-development__icon.svg',
    name: { default: 'Android Studio' }
  },
  'asp.net': {
    icon: baseUrl + 'dot-net__icon.svg',
    name: { default: 'ASP.NET' }
  },
  'batch-file': {
    icon: baseUrl + 'bash__icon.svg',
    name: { default: 'Batch File' }
  },
  'csharp': {
    icon: baseUrl + 'csharp__icon.svg',
    name: { default: 'C#' },
    detailsURL: baseUrl + 'csharp__details.json',
  },
  'cuda': {
    icon: baseUrl + 'cuda__icon.svg',
    name: { default: 'CUDA' }
  },
  'go': {
    icon: baseUrl + 'go__icon.svg',
    name: { default: 'Go' },
    detailsURL: baseUrl + 'go__details.json',
  },
  'numpy': {
    icon: baseUrl + 'numpy__icon.svg',
    name: { default: 'NumPy' }
  },
  'pytorch': {
    icon: baseUrl + 'pytorch__icon.svg',
    name: { default: 'PyTorch' }
  },
  'linux': {
    icon: baseUrl + 'linux__icon.svg',
    name: { default: 'Linux' }
  },
  'theano': {
    icon: baseUrl + 'theano__icon.svg',
    name: { default: 'Theano' }
  },
  'scikit-learn': {
    icon: baseUrl + 'scikit-learn__icon.svg',
    name: { default: 'Scikit Learn' }
  },
  'twig': {
    icon: baseUrl + 'twig__icon.svg',
    name: { default: 'Twig' }
  },
  'ajax': {
    icon: baseUrl + 'ajax__icon.svg',
    name: { default: 'Ajax' }
  },
  'hive': {
    icon: baseUrl + 'hive__icon.svg',
    name: { default: 'Hive' }
  },
  'after-effects': {
    icon: baseUrl + 'after-effects__icon.svg',
    name: { default: 'Adobe After Effects' }
  },
  'php-unit': {
    icon: baseUrl + 'php-unit__icon.svg',
    name: { default: 'PHP Unit' }
  },
  'objective-c': {
    icon: baseUrl + 'objective-c__icon.svg',
    name: { default: 'Objective C' },
    detailsURL: baseUrl + 'objective-c__details.json',
  },
  'behat': {
    icon: baseUrl + 'behat__icon.svg',
    name: { default: 'Behat' }
  },
  'sql': {
    icon: baseUrl + 'sql__icon.svg',
    name: { default: 'SQL' }
  },
  'vba': {
    icon: baseUrl + 'vba__icon.svg',
    name: { default: 'VBA' }
  },
  'flash': {
    icon: baseUrl + 'flash-player__icon.svg',
    name: { default: 'Adobe Flash Player' }
  },
  'amazon-redshift': {
    icon: baseUrl + 'amazon-redshift__icon.svg',
    name: { default: 'Amazon Redshift' }
  },
  'blockchain': {
    icon: baseUrl + 'blockchain__icon.svg',
    name: { default: 'Blockchain' }
  },
  'elixir': {
    icon: baseUrl + 'elixir__icon.svg',
    name: { default: 'Elixir' }
  },
  'elm': {
    icon: baseUrl + 'elm__icon.svg',
    name: { default: 'ELM' }
  },
  'google-adwords': {
    icon: baseUrl + 'google-adwords__icon.svg',
    name: { default: 'Google Adwords' }
  },
  'google-analytics': {
    icon: baseUrl + 'google-analytics__icon.svg',
    name: { default: 'Google Analytics' }
  },
  'google-cloud': {
    icon: baseUrl + 'google-cloud__icon.svg',
    name: { default: 'Google Cloud' }
  },
  'indesign': {
    icon: baseUrl + 'indesign__icon.svg',
    name: { default: 'Adobe InDesign' }
  },
  'tensorflow': {
    icon: baseUrl + 'tensorflow__icon.svg',
    name: { default: 'Tensorflow' },
    detailsURL: baseUrl + 'tensorflow__details.json',
  },
  'keras': {
    icon: baseUrl + 'keras__icon.svg',
    name: { default: 'Keras' },
    detailsURL: baseUrl + 'keras__details.json',
  },
  'lamp': {
    icon: baseUrl + 'lamp__icon.svg',
    name: { default: 'LAMP' }
  },
  'macos-development': {
    icon: baseUrl + 'macos-development__icon.svg',
    name: { default: 'Mac OS' }
  },
  'microsoft-office	': {
    icon: baseUrl + 'microsoft-office	__icon.svg',
    name: { default: 'Microsoft Office' }
  },
  'photoshop': {
    icon: baseUrl + 'photoshop__icon.svg',
    name: { default: 'Adobe Photoshop' }
  },
  'play-framework': {
    icon: baseUrl + 'play-framework__icon.svg',
    name: { default: 'Play Framework' }
  },
  'postgresql': {
    icon: baseUrl + 'postgresql__icon.svg',
    name: { default: 'PostgreSQL' }
  },
  'prestashop': {
    icon: baseUrl + 'prestashop__icon.svg',
    name: { default: 'Prestashop' }
  },
  'r': {
    icon: baseUrl + 'r__icon.svg',
    name: { default: 'R' }
  },
  'redmine': {
    icon: baseUrl + 'redmine__icon.svg',
    name: { default: 'Redmine' }
  },
  'scrapy': {
    icon: baseUrl + 'scrapy__icon.svg',
    name: { default: 'Scrapy' }
  },
  'solidity': {
    icon: baseUrl + 'solidity__icon.svg',
    name: { default: 'Solidity' }
  },
  'windows-development': {
    icon: baseUrl + 'windows-development__icon.svg',
    name: { default: 'Windows Development' }
  },
  'java-enterprise-edition': {
    icon: baseUrl + 'java__icon.svg',
    name: { default: 'J2EE' }
  },
  'j2ee-web-services': {
    icon: baseUrl + 'java__icon.svg',
    name: { default: 'J2EE Web Services' }
  },
  'scilab': {
    icon: baseUrl + 'scilab__icon.png',
    name: { default: 'Scilab' }
  }
}

