import React from 'react';
import { TranslatableText } from '../components/TranslatableText';
import SkillsHighligthToggle from '../components/SkillsHighlightToggle';

const Skill = ({ elementId, label, endorsementCount, relevance, onRemove }) => {
  return (
    <SkillsHighligthToggle
      onClick={() => (relevance.get() === 0 ? relevance.set(10) : relevance.set(0))}
      isHighlighted={relevance.get() > 0}
      text={<TranslatableText translatableText={label} />}
      count={endorsementCount.get()}
    />
  );
};

const renderSkills = (skills) => {
  let skillsElements = [];
  for (let i = 0; i < skills.length; i++) {
    skillsElements.push(
      <Skill
        key={i}
        elementId={skills.at(i).getElementId()}
        label={skills.at(i).getLabel()}
        endorsementCount={skills.at(i).getEndorsementsCount()}
        relevance={skills.at(i).getRelevance()}
        onRemove={() => skills.removeAt(i)}
      />,
    );
  }
  return skillsElements;
};

const ProfileSkills = ({ skills }) => (
  <div style={{textAlign: 'center'}}>
    {renderSkills(skills)}
    {/* <pre>
      <code>Skills linkedin : map sur le SkillsHighlightToggle avec la bonne fonction de toggle sur chaque skill</code>
    </pre> */}
  </div>
);

export default ProfileSkills;
