import React from 'react';
import _ from 'underscore';
import { Segment, Button, Grid, Modal, Header, Icon } from 'semantic-ui-react';

export class Link extends React.Component {
  constructor(props) {
    super(props);

    this.state = { modalOpen: false };
  }
  render() {
    return (
      <Segment>
        <Grid>
          <Grid.Column width={13}>
            <div>
              {_.map(this.props.link, (id, source) => (
                <div key={id}>
                  <strong>{source}</strong> {id}
                </div>
              ))}
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <Modal
              open={this.state.modalOpen}
              trigger={
                <Button onClick={() => this.setState({ modalOpen: true })} color="red">
                  Remove
                </Button>
              }
              basic
              size="small"
            >
              <Header icon="archive" content="Are you sure you want to remove this link ?" />
              <Modal.Actions>
                <Button
                  basic
                  color="blue"
                  inverted
                  onClick={() => {
                    this.setState({ modalOpen: false });
                  }}
                >
                  <Icon name="remove" /> No
                </Button>
                <Button
                  color="red"
                  onClick={() => {
                    this.setState({ modalOpen: false });
                    this.props.onDelete();
                  }}
                  inverted
                >
                  <Icon name="checkmark" /> Yes
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}
